import { Injectable } from "@angular/core";
import Swal from "sweetalert2";

type opcSweet = "success" | "error" | "warning" | "info" | "question";

@Injectable({
    providedIn: "root",
})
export class SweelService {
    constructor() {}

    //SA normal
    normal(title: string, text: string, type: opcSweet) {
        Swal.fire(title.toUpperCase(), text.toUpperCase(), type);
    }

    //Mensaje con confirmacion
    confirm(title: string, html: string, type: opcSweet): Promise<boolean> {
        return new Promise((resolve, reject) => {
            Swal.fire({
                title: title,
                html: html,
                type: type,
                showCancelButton: true,
                confirmButtonColor: 'var(--success)',
                cancelButtonColor: 'var(--danger)',
                confirmButtonText: "Sí, confirmar",
                cancelButtonText: "No",
                focusCancel: true,
            }).then((result) => resolve(!!result.value));
        });
    }

    //Mensaje con input
    input(title: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            Swal.fire({
                title: title,
                input: "text",
                inputAttributes: {
                    autocapitalize: "off",
                },

                showCancelButton: true,
                confirmButtonColor: 'var(--success)',
                cancelButtonColor: 'var(--danger)',
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar",

                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading(),
            }).then((result) => {
                if (!!result) {
                }
            });
        });
    }
}
