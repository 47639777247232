import { Component } from '@angular/core';
import { InstitutionsService } from '../institutions.service';

@Component({
  selector: 'app-institutions_update_institutions',
  templateUrl: './update_institutions.component.html',
  styleUrls: ['./update_institutions.component.scss']
})
export class UpdateInstitutionsComponent {

  constructor(private moduleService: InstitutionsService) {
  }

  close() {
    this.moduleService.cancel();
  }

}
