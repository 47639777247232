import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import { AppService } from './services/app.service';
import { AccountService } from './services/account.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(public endpoint: AppService, private toastr: ToastrService, private accountService: AccountService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let cloned = req.clone({
			responseType: req.responseType == "blob" ? "blob" : "json"
		});

		let session;

		let auth_token = "";
		if (session = localStorage.getItem("doctor-unimed-user-session")) {
			auth_token = JSON.parse(session).token;
		}

		if (auth_token) {
			cloned = cloned.clone({
				headers: cloned.headers.set("Authorization", "Bearer " + auth_token)
			});
		}

		return next.handle(cloned).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.status == 403) {
					if (error.error.code == "auth") {
						this.endpoint.reset_session();
						this.accountService.reset();
						this.endpoint.redirect_to("/conectarse");
					}
					if (error.error.code == "disabled") {
						this.toastr.error(
							error.error.message ? error.error.message : error.message,
							error.error.title ? error.error.title : error.status + ''
						);
						this.endpoint.reset_session();
						this.accountService.reset();
						this.endpoint.redirect_to("/conectarse");
					}
					return throwError(error);
				}
				else if (error.status == 0) {
					error.error.title = "Problemas de conexión";
					error.error.message = "Revise su conexión de internet o inténtelo más tarde.";
					return throwError(error);
				}
				else {
					return throwError(error);
				}
			})
		);
	}
}