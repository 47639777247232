import * as i0 from "@angular/core";
export class PipesService {
    constructor() {
        this.months = [
            { value: "01", label: "Enero" },
            { value: "02", label: "Febrero" },
            { value: "03", label: "Marzo" },
            { value: "04", label: "Abril" },
            { value: "05", label: "Mayo" },
            { value: "06", label: "Junio" },
            { value: "07", label: "Julio" },
            { value: "08", label: "Agosto" },
            { value: "09", label: "Septiembre" },
            { value: "10", label: "Octubre" },
            { value: "11", label: "Noviembre" },
            { value: "12", label: "Diciembre" },
        ];
    }
    micro_date(str) {
        if (str) {
            let raw = new Date(str).toLocaleDateString("es-HN", { year: "numeric", month: "2-digit", day: "2-digit" });
            if (raw) {
                let raw_array = raw.split("/");
                let month = this.months.find((val) => val.value == raw_array[1]);
                return `${raw_array[0]} / ${month ? month.label.substring(0, 3) : ""} / ${raw_array[2]}`;
            }
        }
        else {
            return "";
        }
    }
    long_date(str) {
        if (str) {
            return new Date(str).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true, formatMatcher: "basic" }).replace(/00:/g, "12:");
        }
        else {
            return "";
        }
    }
    number(num, min = 2, max = 2) {
        return num.toLocaleString("es-HN", {
            minimumFractionDigits: min,
            maximumFractionDigits: max,
        });
    }
    phone(num, id_country) {
        return num;
    }
    monthYear(str) {
        let date = new Date(str).toLocaleDateString('es-HN', {
            year: 'numeric',
            month: 'long',
        });
        let fragments = date.split(' ');
        return `${fragments[0].charAt(0).toUpperCase() + fragments[0].slice(1)} ${fragments.slice(2).join(' ')}`;
    }
    year(str) {
        let date = new Date(str).toLocaleDateString('es-HN', {
            year: 'numeric'
        });
        let fragments = date.split(' ');
        return date;
    }
    time_tostring(str) {
        let str_f = str.split(":");
        let _date = new Date();
        _date.setHours(+str_f[0]);
        _date.setMinutes(+str_f[1]);
        _date.setMilliseconds(+str_f[2]);
        let date = _date.toLocaleDateString('es-HN', { hour: '2-digit', minute: '2-digit', hour12: true });
        let fragments = date.split(" ").slice(1).join(" ").split(":");
        return `${fragments[0].replace("00", "12")}:${fragments[1]}`;
    }
    names(str) {
        return str.replace(/\b[a-zA-Z\u00C0-\u00ff']+/g, function (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
    }
    dateDif(date1, date2, lapse) {
        const msPerDay = 1000 * 60 * 60 * 24;
        const msPerMonth = 1000 * 60 * 60 * 24 * (30.4375);
        const msPerYear = 1000 * 60 * 60 * 24 * (30.4375 * 12);
        const d1 = date1 instanceof Date ? date1 : new Date(date1);
        const d2 = date2 instanceof Date ? date2 : new Date(date2);
        const diffInMs = Math.abs(d2.getTime() - d1.getTime());
        switch (lapse) {
            case "days":
                return Math.floor(diffInMs / msPerDay);
            case "months":
                return Math.floor(diffInMs / msPerMonth);
            case "years":
                return Math.floor(diffInMs / msPerYear);
            default:
                throw new Error("The lapse argument must be 'days', 'months', or 'years'");
        }
    }
}
PipesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PipesService_Factory() { return new PipesService(); }, token: PipesService, providedIn: "root" });
