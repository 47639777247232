export const data_billing_info: data[] = [];
export interface data {
    create_at?: string,
    name_client?: string,
    rtn_client?: string,
    address?: string,
    phone?: string,
    email_client?: string,
    email_doctor?: string,
    price?: string,
    amount?: string,
    description?: string,
    letter?: string
}