import Swal from "sweetalert2";
import * as i0 from "@angular/core";
export class SweelService {
    constructor() { }
    //SA normal
    normal(title, text, type) {
        Swal.fire(title.toUpperCase(), text.toUpperCase(), type);
    }
    //Mensaje con confirmacion
    confirm(title, html, type) {
        return new Promise((resolve, reject) => {
            Swal.fire({
                title: title,
                html: html,
                type: type,
                showCancelButton: true,
                confirmButtonColor: 'var(--success)',
                cancelButtonColor: 'var(--danger)',
                confirmButtonText: "Sí, confirmar",
                cancelButtonText: "No",
                focusCancel: true,
            }).then((result) => resolve(!!result.value));
        });
    }
    //Mensaje con input
    input(title) {
        return new Promise((resolve, reject) => {
            Swal.fire({
                title: title,
                input: "text",
                inputAttributes: {
                    autocapitalize: "off",
                },
                showCancelButton: true,
                confirmButtonColor: 'var(--success)',
                cancelButtonColor: 'var(--danger)',
                confirmButtonText: "Aceptar",
                cancelButtonText: "Cancelar",
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading(),
            }).then((result) => {
                if (!!result) {
                }
            });
        });
    }
}
SweelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SweelService_Factory() { return new SweelService(); }, token: SweelService, providedIn: "root" });
