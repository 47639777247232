import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ConsultClass } from 'src/app/classes/consult-class';
import { AccountService } from 'src/app/services/account.service';
import { AppService } from 'src/app/services/app.service';
import { CatalogsService } from 'src/app/services/catalogs.service';
import { PageService } from 'src/app/services/page.service';
import { PipesService } from 'src/app/services/pipes.service';

@Component({
    selector: 'app-show-medical-consult',
    templateUrl: './show-consult.component.html',
    styleUrls: ['./show-consult.component.scss', '../../../../shared/ui/datatable/datatable.component.scss']
})
export class ShowConsultMedicalComponent implements OnInit, OnDestroy {
    @Input() data: any = {
        created_at: new Date()
    };
    @Output("close") close_event: EventEmitter<any> = new EventEmitter();

    public avatar_configs = {
        size: "6rem",
        font_size: "4.3rem",
      };
      
    constructor(private pageService: PageService, public endpoint: AppService, private accountService: AccountService, private catalogsService: CatalogsService, private pipesService: PipesService) {

    }

    ngOnInit() {
        this.pageService.set_sidebar_3_state({
            expand: true,
            scroll: false,
            show: true
        });
        this.get_data();
    }

    ngOnDestroy() {
        this.pageService.set_sidebar_3_state({
            expand: true,
            scroll: true,
            show: true
        });
    }

    get_data() {
        this.endpoint.expedient_consult_general_get_consult({ id: this.data.id }).subscribe(
            data => {
                this.data.consult = new ConsultClass(this.catalogsService, this.pipesService, data);
                this.data.consult_l = this.data.consult.to_string();
            },
            error => this.close()
        );
    }

    close() {
        this.close_event.emit("");
    }

    get_file_endpoint(path: string, name: string): string {
        return this.endpoint.get_file_endpoint(path, name);
    }

    is_author(): boolean {
        return this.data.consult.id_doctor == this.accountService.get_current_state().id;
    }

    error_image() {
        if (this.data.patient_gender == "1") this.data.patient_image = 'User-Female.svg';
        if (this.data.patient_gender == "2") this.data.patient_image = 'User-Male.svg';
    }
}
