import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { PatientService, patient_state } from 'src/app/services/patient-service.service';
import { PageService, PageState } from '../../../../services/page.service';

@Component({
  selector: 'app-contact_emergency_brief',
  templateUrl: './contact_emergency_brief.component.html',
  styleUrls: ['./contact_emergency_brief.component.scss']
})
export class ContactEmergencyBriefComponent implements OnInit, OnDestroy {
  private patient_subscription: Subscription;
  public patient_state: patient_state;
  public show: boolean = false;

  constructor(private endpoint: AppService, private patientService: PatientService, private pageService: PageService) {
  }

  ngOnInit() {
    this.patient_subscription = this.patientService.patient_data_subscription.subscribe(
      data => this.patient_state = data
    );
  }

  ngOnDestroy() {
    this.patient_subscription.unsubscribe();
  }

}
