import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { PageService, PageState } from 'src/app/services/page.service';
import { SweelService } from 'src/app/shared/services/sweel.service';
import { BoldListComponent, bold_list_type } from 'src/app/shared/ui/bold_list/bold_list.component';
import { dynamic_url_tabs_type } from 'src/app/shared/ui/dynamic_tabs/dynamic_tabs.component';
import { SearchButtonComponent } from 'src/app/shared/ui/search_button/search_button.component';

@Component({
  selector: 'app-consults-medical',
  templateUrl: './consults-medical.component.html',
  styleUrls: ['./consults-medical.component.scss']
})
export class ConsultsMedicalComponent implements OnInit, OnDestroy {
  private page_subscription: Subscription;
  public page_state: PageState;

  @ViewChild('search_button_ref', { static: false }) search_button_ref: SearchButtonComponent;
  @ViewChild('bold_list_ref', { static: false }) bold_list_ref: BoldListComponent;

  public data: any = {
    list: [],
    count: 0,
    skip: 0,
    loaded: false
  };

  public dynamic_tabs: dynamic_url_tabs_type;
  public bold_list: bold_list_type;
  public patient_data: any;
  public show_consult: boolean;
  public selected_item: any;
  constructor(private pageService: PageService, private endpoint: AppService, public sweel: SweelService) {
    this.bold_list_config();
  }

  ngOnInit() {
    this.page_subscription = this.pageService.current.subscribe(
      state => {
        this.page_state = state;
      }
    );
  }

  ngOnDestroy() {
    this.page_subscription.unsubscribe();
  }

  bold_list_config() {
    this.bold_list = {
      configs: {
        title: "Consultas",
        //icon: "fa-duotone fa-calendar-check",
        paginate: true,
        search: true,
        open: true,
      },
      filters: {
        limit: 50,
        skip: 0,
        order_asc: false,
        order_by: "created_at"
      },
      headers: [
        {
          label: "Diagnóstico",
          name: "diagnosis",
          order_by: "diagnosis"
        },
        {
          label: "Fecha",
          name: "created_at",
          order_by: "created_at",
          type: "date",
          class: "secondary"
        },
      ],
      contents: [

        (item) => item.patient_name
      ]
    };
  }

  on_update() {
    if (this.bold_list_ref) this.bold_list_ref.update();
  }

  update(data) {
    if (true) {
      if (this.search_button_ref) data.search_word = this.search_button_ref.data;
      var load = {
        ...data,
      };
      this.endpoint.get_my_consults(load).subscribe(
        data => this.data = data,
        err => console.error(err)
      );
    }
  }

  events(data) {
    this.selected_item = data.item;
    if (data.name == "Detalles" || data.name == "row_click") {
      this.show_consult = true;
    }
  }

}
