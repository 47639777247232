import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shortDate' })
export class shortDate implements PipeTransform {

    private months = [{ value: '01', label: 'Enero', code:"Ene" }, { value: '02', label: 'Febrero',  }, { value: '03', label: 'Marzo' }, { value: '04', label: 'Abril' }, { value: '05', label: 'Mayo' }, { value: '06', label: 'Junio' }, { value: '07', label: 'Julio' }, { value: '08', label: 'Agosto' }, { value: '09', label: 'Septiembre' }, { value: '10', label: 'Octubre' }, { value: '11', label: 'Noviembre' }, { value: '12', label: 'Diciembre' }];

    transform(str: string): string {
        if(str) {

            let raw = new Date(str).toLocaleDateString('es-HN', { year: 'numeric', month: '2-digit', day: '2-digit' });
        let raw_array = raw.split("/");
        return `${raw_array[0]} / ${this.months.find(val => val.value == raw_array[1]).label} / ${raw_array[2]}`
    }
    return "";
    }
}