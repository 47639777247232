<div class="bg-masterhead d-flex align-items-center" style="height: 100vh">
	<div class="account-pages w-100">
		<div class="container against-footer">
			<div class="row justify-content-center">
				<div class="col-lg-10">
					<div class="card shadow" style="border-radius: 25px;">

						<div class="card-body py-md-4 pl-md-2 pr-md-4 p-2">
							<div class="container">
								<div class="row">

									<div class="col-md-1 d-flex justify-content-center mb-4 mb-md-0">
										<div
											class="col-md-1 progresbar-local text-transparent d-local-lg-up"
											[class.progress-0]="view == 0"
											[class.progress-1]="view == 1"
											[class.progress-2]="view == 2"
											[class.progress-3]="view == 3"
											style="max-width: 10px;">.</div>
										<div
											class="progresbar-local text-transparent d-local-md-down h-100"
											[class.d-none]="view == 0"
											[class.col-4]="view == 1"
											[class.col-8]="view == 2"
											[class.col-12]="view == 3">.</div>
									</div>

									<div class="col-md-11" *ngIf="view == 1">
										<div class="row p-0">

											<!-- Mid -->
											<div class="col-12 text-center">
												<a class="d-local-md-down">
													<img src="assets/images/favicon.ico" alt="UNIMED" style="height: 8rem;">
												</a>
												<div class="text-center d-local-md-down">
													<h3>Configuración de cuenta</h3>
													<p class="text-muted">Ingrese su información personal.</p>
												</div>
											</div>

											<!-- Large -->
											<div class="col-12 d-flex justify-content-center align-items-center pb-md-3">
												<a class="d-local-lg-up">
													<img src="assets/images/favicon.ico" alt="UNIMED" style="height: 8rem;">
												</a>
												<div class="headerDivider mx-3 d-local-lg-up"></div>
												<div class="text-center d-local-lg-up">
													<h3>Configuración de cuenta</h3>
													<p class="text-muted">Ingrese su información personal.</p>
												</div>
											</div>

											<div class="col-sm-4 col-md-4 col-sm-offset-1 d-flex align-items-center justify-content-center mb-2">
												<div class="text-center profile-image-chooser">
													<file-upload
														class="dropzone d-none"
														[ngModelOptions]="{standalone: true}"
														[(ngModel)]="uploadedFiles"
														[control]="fileUploadControl"
														(change)="fileProgress($event)"
														name="imagen"
														accept="image/x-png,image/gif,image/jpeg"
														#profile_image="ngModel">
													</file-upload>

													<i *ngIf='!image_src' (click)="open_file_chooser()" class="fa-duotone fa-portrait image-local-change mb-2 w-100" style="font-size: 1400%;"></i>
													<img *ngIf='image_src' (click)="open_file_chooser()" [src]="image_src" class="img-fluid image-chooser" alt="Imagen de Perfil" style="object-fit: cover; height: 14rem; border-radius: 25px;">

													<label class="text-center title-image">Imagen de perfil</label>
												</div>
											</div>

											<div class="col-sm-8 col-md-8">
												<app-form_render #form_general_ref [config]="form_general_config" [data]="data.general"></app-form_render>
											</div>

										</div>
									</div>
									<div class="col-md-11" *ngIf="view == 2">
										<div class="row p-0 pl-md-2">

											<!-- Mid -->
											<div class="col-12 text-center">

												<a class="d-local-md-down">
													<img src="assets/images/favicon.ico" alt="UNIMED" style="height: 8rem;">
												</a>

												<div class="text-center d-local-md-down">
													<h3>Configuración de cuenta</h3>
													<p class="text-muted">Ingrese una nueva contraseña.</p>
												</div>

											</div>

											<!-- Large -->
											<div class="col-12 d-flex justify-content-center align-items-center pb-md-1">

												<a class="d-local-lg-up">
													<img src="assets/images/favicon.ico" alt="UNIMED" style="height: 8rem;">
												</a>

												<div class="headerDivider mx-3 d-local-lg-up"></div>

												<div class="text-center d-local-lg-up">
													<h3>Configuración de cuenta</h3>
													<p class="text-muted">Ingrese una nueva contraseña.</p>
												</div>

											</div>


											<div class="col-md-8">
												<p class="pt-3">Nota: La contraseña debe contener al menos 8 caracteres, incluir mayúsculas, minúsculas y números.</p>
												<app-form_render #form_change_password_ref [config]="form_change_password_config" [data]="data.change_password"></app-form_render>
											</div>
											<div class="col-md-4 d-local-md-up">
												<div class="row">
													<div class="col-sm-12 col-md-12">
														<img class="img-fluid" src="/assets/images/4860636.svg" alt="Mis tickets" style="object-fit: cover; height: 15rem;">
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-11" *ngIf="view == 3">
										<div class="row p-0">

											<!-- Mid -->
											<div class="col-12 text-center">

												<a class="d-local-md-down">
													<img src="assets/images/favicon.ico" alt="UNIMED" style="height: 8rem;">
												</a>

												<div class="text-center d-local-md-down">
													<h3>Configuración de cuenta</h3>
													<p class="text-muted">Información actualizada. </p>
												</div>

											</div>

											<!-- Large -->
											<div class="col-12 d-flex justify-content-center align-items-center pb-md-3">

												<a class="d-local-lg-up">
													<img src="assets/images/favicon.ico" alt="UNIMED" style="height: 8rem;">
												</a>

												<div class="headerDivider mx-3 d-local-lg-up"></div>

												<div class="text-center d-local-lg-up">
													<h3>Configuración de cuenta</h3>
													<p class="text-muted">Información actualizada. </p>
												</div>

											</div>

											<div class="col-12">
												<div class="text-center">
													<img class="img-fluid" src="/assets/images/3594471.svg" alt="Mis tickets" style="object-fit: cover; height: 14rem;">

													<h2 class="mt-2">¡Excelente!</h2>

													<p class="w-75 mb-2 mx-auto">Tu información ha sido actualizada exitosamente.</p>

												</div>
											</div>

											<div class="col-12">
												<div class="d-flex justify-content-end">
													<input type="button" class="btn btn-finish btn-primary text-center" name="finish" value="Finalizar" (click)='finish()'>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
	<footer class="footer footer-alt">
		<div class="row">
			<div class="col-md-6 text-left text-muted ">
				{{release_year == year ? year : (release_year + " - " + year)}} © UNIMED by <a class="text-primary" target="_blank" href="https://cjalvarez.com">CJAlvarez </a>
			</div>
			<div class="col-md-6">
				<div class="text-md-right footer-links d-none d-sm-block">
					<a class="text-muted" target="_blank" href="https://github.com/CJAlvarez"><i class="fab fa-github"></i> GitHub</a>
				</div>
			</div>
		</div>
	</footer>
</div>