import { PipesModule } from 'src/app/shared/ui/pipes/pipes.module';
import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormRenderComponent } from './form_render.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl } from 'ng-pick-datetime';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { NgxFileDropModule } from 'ngx-file-drop';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { AuthInterceptor } from 'src/app/app.httpinterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
  cancelBtnLabel = 'Cancelar';
  setBtnLabel = 'Aceptar';
};

@NgModule({
  declarations: [
    FormRenderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    PipesModule,
    ReactiveFormsModule,
    TextMaskModule,
    NgbTooltipModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AmazingTimePickerModule,
    NgxFileDropModule
  ],
  exports: [
    FormRenderComponent,
  ],
  providers: [
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'es-HN' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class FormRenderModule { }
