<div class="unimed-left-sidebar" [class.no-scroll]="!page_state.sidebar_3.scroll" >
    <div class="container-fluid px-0">
        <div class="row">
            <div class="col-12">
                <app-hovered_sidebar [config]="hovered_sidebar"></app-hovered_sidebar>
                <div class="hovered_sidebar-content" [class.no-scroll]="!page_state.sidebar_3.scroll">
                    <router-outlet></router-outlet>
                </div>
                <app-consult-minimized-header></app-consult-minimized-header>
            </div>
        </div>
    </div>
</div>
