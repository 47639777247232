<div class="d-flex flex-column py-2 h-100">
    <div class="container-fluid pl-3 pr-3 bg-white">
        <div class="row">
            <div class="col-12 mb-2">
                <div class="d-flex">
                    <div class="d-flex flex-column my-auto">
                        <div class="title mb-auto d-flex">
                            nuevo paciente
                        </div>
                    </div>

                    <div class="my-auto ml-auto">
                        <div class="d-flex text-right d-local-lg-up">
                            <a class="my-auto mx-2 text-danger no-wrap pointer" style="font-weight: 500;" (click)="cancel()"><i class="fa-duotone fa-times"></i> CANCELAR </a>
                            <button class="ml-2 btn radius btn-success no-wrap" style="font-weight: 500;" (click)="submit()"><i class="fa-duotone fa-check"></i> FINALIZAR </button>
                        </div>

                        <div class="text-right d-local-md-down no-wrap" ngbDropdown>
                            <button
                                class="ml-2 btn radius btn-secondary no-wrap"
                                ngbDropdownToggle
                                id="datatable_tools_dropdown">
                                <i class="fa-duotone fa-ellipsis-v mr-1"></i>                                
                            </button>

                            <div class="dropdown-menu dropdown-menu-left" aria-labelledby="datatable_tools_dropdown" ngbDropdownMenu>
                                <button ngbDropdownItem class="no-wrap" style="font-weight: 500;" (click)="cancel()"><i class="fa-duotone fa-times text-danger"></i> CANCELAR </button>
                                <button ngbDropdownItem class="no-wrap" style="font-weight: 500;" (click)="submit()"><i class="fa-duotone fa-check text-success"></i> FINALIZAR </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 edge-shadow">
                <app-dynamic_tabs [config]="dynamic_tabs"></app-dynamic_tabs>
            </div>
        </div>
    </div>
    <div class="flex-grow-1 mx-1 bg-light radius">
        <div class="local-internal-scroll">
            <div class="scroll-content py-2 pl-2 pr-1" #scroll_ref (scroll)=isScrolling()>
                <div id="general_ref" class="mb-3 np-item" [class.active-item]="dynamic_tabs.selected==0" #general_ref>
                    <app-expedient-general></app-expedient-general>
                </div>
                <div id="contacts_ref" class="mb-3 pl-3 np-item" [class.active-item]="dynamic_tabs.selected==1" #contacts_ref>
                    <app-expedient-contacts></app-expedient-contacts>
                </div>
                <div id="background_ref" class="mb-3 pl-3 np-item" [class.active-item]="dynamic_tabs.selected==2" #background_ref>
                    <app-expedient-background></app-expedient-background>
                </div>
                <!-- <div id="insurance_ref" class="mb-3 pl-3 np-item" [class.active-item]="dynamic_tabs.selected==3" #insurance_ref>
                    <app-expedient-insurances></app-expedient-insurances>
                </div> -->
                <div id="relations_ref" class="mb-3 pl-3 np-item" [class.active-item]="dynamic_tabs.selected==4" #relations_ref>
                    <app-expedient-relations></app-expedient-relations>
                </div>
                <!-- <div id="institution_ref" class="mb-3 pl-3 np-item" [class.active-item]="dynamic_tabs.selected==5" #institution_ref>
                    <app-expedient-institutions></app-expedient-institutions>
                </div> -->
            </div>
        </div>
    </div>
</div>

<app-overlay [title]="'Verificando la Información del Paciente'" [message]="'Por favor, espere...'" [icon]="'fa-duotone fa-circle-notch text-primary'" [show]="expedient_state.loading"></app-overlay>