import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/services/app.service';
import { CatalogsService } from 'src/app/services/catalogs.service';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { FormRenderComponent, form_type } from 'src/app/shared/ui/form_render/form_render.component';

@Component({
	selector: 'app-show-medicine',
	templateUrl: './show-medicine.component.html',
	styleUrls: ['./show-medicine.component.scss']
})
export class ShowMedicineComponent implements OnInit {
	@Input() data = {
		id_patient: "",
		name: "",
		amount: "",
		id_presentation: "",
		id_concentration: "",
		concentration: "",
		id_way: "",
		id_frecuency: "",
		frecuency: "",
		id_days: "",
		days: ""
	};

	@ViewChild('form_ref', { static: false }) form_ref: FormRenderComponent;
	public form_config: form_type;

	constructor(private endpoint: AppService, private toastr: ToastrService, private patientServiceService: PatientService, private pageService: PageService, private catalogsService: CatalogsService) { }

	ngOnInit() {
		this.form_configs();
	}
	form_configs() {
		this.form_config = {
			pretty_view: () => true,
			disabled: () => true,
			submit: () => { },
			rows: [
				{
					class: "row",
					cols: [
						{
							name: "name",
							type: "name",
							label: "Nombre",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
								maxlength: {
									value: () => 45
								}
							},
							disabled: () => false,
						},
						{
							name: "amount",
							type: "number",
							label: "Cantidad",
							placeholder: () => "- Seleccione -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							disabled: () => false,
						},
						{
							name: "id_presentation",
							type: "select",
							label: "Presentación",
							placeholder: () => "- Seleccione -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							catalog: {
								list: () => this.catalogsService.catalogs.medicines_presentations,
								value: "id",
								text: "name"
							},
							disabled: () => false,
						},
						{
							name: "concentration",
							type: "text",
							label: "Concentración",
							small: "",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
								maxlength: {
									value: () => 45
								},
							},
							disabled: () => false,
						},
						{
							name: "id_way",
							type: "select",
							label: "Vía",
							placeholder: () => "- Seleccione -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							catalog: {
								list: () => this.catalogsService.catalogs.medicines_ways,
								value: "id",
								text: "name"
							},
							disabled: () => false,
						},
						{
							name: "frecuency_parsed",
							type: "text",
							label: "Frecuencia",
							col_class: "col-md-6",
							disabled: () => false,
						},
						{
							name: "days_parsed",
							type: "text",
							label: "Duración",
							placeholder: () => "..",
							col_class: "col-md-6",
							disabled: () => false,
						},
					]
				},
				{
					class: "row",
					cols: [
						{
							type: "buttons",
							col_class: "col-12",
							buttons: [
								{
									class: "btn btn-sm btn-danger ml-auto",
									label: "Cerrar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'right',
									},
									disabled: () => false,
									click: () => this.close()
								},
							]
						},
					],
				},
			]
		}
	}

	close() {
		this.pageService.closeModal("");
	}
}
