import { ConnectionsClass, ConnectionsInterface } from "./connections-class";
import {
    PeopleMayKnowClass,
    PeopleMayKnowInterface,
} from "./people_may_know-class";
import { ExperiencesClass, ExperiencesInterface } from "./experiences-class";
import { EducationsClass, EducationsInterface } from "./educations-class";
import { InsurancesClass, InsurancesInterface } from "./insurances-class";
import { MembershipsClass, MembershipsInterface } from "./memberships-class";
import { CatalogsService } from "src/app/services/catalogs.service";
import { PipesService } from "../../services/pipes.service";
import { CustomListClass } from "../custom-list-class";
import { LocationsClass, LocationsInterface } from "./locations-class";
import { CustomClass } from "../custom-class";

export interface ProfileBasicInterface {
    // PROFILE
    id: any;
    id_user: any;
    firstname: string;
    lastname: string;
    username: string;
    id_gender: any;
    birthdate: Date;
    birthplace: string;
    id_country: any;
    id_department: any;
    id_town: any;
    address: string;
    id_education: any;
    identification: string;
    id_blood: any;
    organ_donor: any;
    glasses: any;
    connnections: any;
    thanks: any;
    patients: any;
    my_focus: string;
    specialties_areas: string[];

    // PHONE VERIFY
    phone: string;
    v_phone?: string;
    verified_phone: number;

    // EMAIL VERIFY
    email: string;
    v_email?: string;
    verified_email: number;

    // IMAGE UPLOAD
    image: string | ArrayBuffer;
    image_upload?: any;

    // DOCTORS
    id_doctors_specialty: any;
    registration_number: string;
    job_place: string;
    disabled_at: Date;
    disabled_by: any;
    deleted_at: Date;
    deleted_by: any;
    online_at: Date;
    offline_at: Date;
    common: number;

    is_account: boolean;
}

export interface RawProfileInterface extends ProfileBasicInterface {
    // SEGMENTS
    experiences: ExperiencesInterface[];
    educations: EducationsInterface[];
    insurances: InsurancesInterface[];
    memberships: MembershipsInterface[];
    people_may_know: PeopleMayKnowInterface[];
    locations: LocationsInterface[];
    connection: ConnectionsInterface;
    connections: ConnectionsInterface[];
    search_connections: ConnectionsInterface[];
}

export interface ProfileInterface extends ProfileBasicInterface {
    // SEGMENTS
    experiences: CustomListClass<ExperiencesClass>;
    educations: CustomListClass<EducationsClass>;
    insurances: CustomListClass<InsurancesClass>;
    memberships: CustomListClass<MembershipsClass>;
    people_may_know: CustomListClass<PeopleMayKnowClass>;
    locations: CustomListClass<LocationsClass>;
    connections: CustomListClass<ConnectionsClass>;
    search_connections: CustomListClass<ConnectionsClass>;
    connection: ConnectionsClass;
}

export interface ToStringInterface extends ProfileBasicInterface {
    doctors_specialty: any;
    specialties_areas: any;
    country: string;
    department: string;
    town: string;
}
export class ProfileClass extends CustomClass implements ProfileInterface {
    // PROFILE
    public id: any;
    public id_user: any;
    public firstname: string;
    public lastname: string;
    public username: string;
    public id_gender: any;
    public birthdate: Date;
    public birthplace: string;
    public id_country: any;
    public id_department: any;
    public id_town: any;
    public address: string;
    public id_education: any;
    public identification: string;
    public id_blood: any;
    public organ_donor: any;
    public glasses: any;
    public connnections: any;
    public thanks: any;
    public patients: any;
    public my_focus: string;
    public specialties_areas: string[];

    // PHONE VERIFY
    public phone: string;
    public v_phone: string;
    public verified_phone: number;

    // EMAIL VERIFY
    public email: string;
    public v_email: string;
    public verified_email: number;

    // IMAGE UPLOAD
    public image: string | ArrayBuffer;
    public image_upload: any;

    // DOCTORS
    public id_doctors_specialty: any;
    public registration_number: string;
    public job_place: string;
    public disabled_at: Date;
    public disabled_by: any;
    public deleted_at: Date;
    public deleted_by: any;
    public online_at: Date;
    public offline_at: Date;
    public common: number;
    public is_account: boolean;

    // SEGMENTS
    public experiences: CustomListClass<ExperiencesClass>;
    public educations: CustomListClass<EducationsClass>;
    public insurances: CustomListClass<InsurancesClass>;
    public memberships: CustomListClass<MembershipsClass>;
    public people_may_know: CustomListClass<PeopleMayKnowClass>;
    public locations: CustomListClass<LocationsClass>;
    public connections: CustomListClass<ConnectionsClass>;
    public search_connections: CustomListClass<ConnectionsClass>;
    public connection: ConnectionsClass;

    public static_to_string: ToStringInterface;

    constructor(
        private catalogsService: CatalogsService,
        private pipesService: PipesService,
        profile?: RawProfileInterface
    ) {
        super();
        if (profile) this.overwrite(profile);
        else this.reset();
    }

    reset(): void {
        super.reset();

        this.id = null;
        this.id_user = null;
        this.firstname = "";
        this.lastname = "";
        this.username = "";
        this.id_gender = null;
        this.birthdate = new Date();
        this.birthplace = "";
        this.id_country = null;
        this.id_department = null;
        this.id_town = null;
        this.address = "";
        this.phone = "";
        this.v_phone = "";
        this.verified_phone = 2;
        this.email = "";
        this.v_email = "";
        this.verified_email = 2;
        this.id_education = null;
        this.identification = "";
        this.image = "";
        this.id_blood = null;
        this.organ_donor = null;
        this.glasses = null;
        this.connnections = null;
        this.thanks = null;
        this.patients = null;
        this.my_focus = "";
        this.specialties_areas = [];

        this.id_doctors_specialty = null;
        this.registration_number = "";
        this.job_place = "";
        this.disabled_at = new Date();
        this.disabled_by = null;
        this.deleted_at = new Date();
        this.deleted_by = null;
        this.online_at = new Date();
        this.offline_at = new Date();
        this.common = 0;
        this.is_account = false;

        this.experiences = new CustomListClass<ExperiencesClass>();
        this.educations = new CustomListClass<EducationsClass>();
        this.insurances = new CustomListClass<InsurancesClass>();
        this.memberships = new CustomListClass<MembershipsClass>();
        this.people_may_know = new CustomListClass<PeopleMayKnowClass>();
        this.locations = new CustomListClass<LocationsClass>();
        this.connection = new ConnectionsClass(this.catalogsService);
        this.connections = new CustomListClass<ConnectionsClass>();
        this.search_connections = new CustomListClass<ConnectionsClass>();
    }

    overwrite(profile: RawProfileInterface) {
        super.overwrite(profile);

        this.id = profile.id;
        this.id_user = profile.id_user;
        this.firstname = profile.firstname;
        this.lastname = profile.lastname;
        this.username = profile.username;
        this.id_gender = profile.id_gender;
        this.birthdate = profile.birthdate;
        this.birthplace = profile.birthplace;
        this.id_country = profile.id_country;
        this.id_department = profile.id_department;
        this.id_town = profile.id_town;
        this.address = profile.address;
        this.phone = profile.phone;
        this.v_phone = profile.phone;
        this.verified_phone = profile.verified_phone;
        this.email = profile.email;
        this.v_email = profile.email;
        this.verified_email = profile.verified_email;
        this.id_education = profile.id_education;
        this.identification = profile.identification;
        this.image = profile.image;
        this.id_blood = profile.id_blood;
        this.organ_donor = profile.organ_donor;
        this.glasses = profile.glasses;
        this.connnections = profile.connnections;
        this.thanks = profile.thanks;
        this.patients = profile.patients;
        this.my_focus = profile.my_focus;
        this.specialties_areas = profile.specialties_areas;

        this.id_doctors_specialty = profile.id_doctors_specialty;
        this.registration_number = profile.registration_number;
        this.job_place = profile.job_place;
        this.disabled_at = profile.disabled_at;
        this.disabled_by = profile.disabled_by;
        this.deleted_at = profile.deleted_at;
        this.deleted_by = profile.deleted_by;
        this.online_at = profile.online_at;
        this.offline_at = profile.offline_at;
        this.common = profile.common;
        this.is_account = profile.is_account;

        if (profile.experiences)
            this.experiences = new CustomListClass<ExperiencesClass>(
                profile.experiences.map(
                    (experiences) =>
                        new ExperiencesClass(this.catalogsService, experiences)
                )
            );
        if (profile.educations)
            this.educations = new CustomListClass<EducationsClass>(
                profile.educations.map(
                    (educations) =>
                        new EducationsClass(this.catalogsService, educations)
                )
            );
        if (profile.insurances)
            this.insurances = new CustomListClass<InsurancesClass>(
                profile.insurances.map(
                    (insurances) =>
                        new InsurancesClass(this.catalogsService, insurances)
                )
            );
        if (profile.memberships)
            this.memberships = new CustomListClass<MembershipsClass>(
                profile.memberships.map(
                    (memberships) =>
                        new MembershipsClass(this.catalogsService, memberships)
                )
            );
        if (profile.people_may_know)
            this.people_may_know = new CustomListClass<PeopleMayKnowClass>(
                profile.people_may_know.map(
                    (people_may_know) =>
                        new PeopleMayKnowClass(
                            this.catalogsService,
                            people_may_know
                        )
                )
            );
        if (profile.locations)
            this.locations = new CustomListClass<LocationsClass>(
                profile.locations.map(
                    (locations) =>
                        new LocationsClass(this.catalogsService, locations)
                )
            );
        if (profile.connections)
            this.connections = new CustomListClass<ConnectionsClass>(
                profile.connections.map(
                    (connections) =>
                        new ConnectionsClass(this.catalogsService, connections)
                )
            );
        if (profile.search_connections)
            this.search_connections = new CustomListClass<ConnectionsClass>(
                profile.search_connections.map(
                    (search_connections) =>
                        new ConnectionsClass(this.catalogsService, search_connections)
                )
            );
        if (profile.connection)
            this.connection = new ConnectionsClass(
                this.catalogsService,
                profile.connection
            );
    }

    raw(): RawProfileInterface {
        let data: any = {};
        data.id = this.id;
        data.id_user = this.id_user;
        data.firstname = this.firstname;
        data.lastname = this.lastname;
        data.username = this.username;
        data.id_gender = this.id_gender;
        data.birthdate = this.birthdate;
        data.birthplace = this.birthplace;
        data.id_country = this.id_country;
        data.id_department = this.id_department;
        data.id_town = this.id_town;
        data.address = this.address;
        data.phone = this.phone;
        data.v_phone = this.v_phone;
        data.verified_phone = this.verified_phone;
        data.email = this.email;
        data.v_email = this.v_email;
        data.verified_email = this.verified_email;
        data.id_education = this.id_education;
        data.identification = this.identification;
        data.image = this.image;
        data.id_blood = this.id_blood;
        data.organ_donor = this.organ_donor;
        data.glasses = this.glasses;
        data.connections = this.connnections;
        data.thanks = this.thanks;
        data.patients = this.patients;
        data.my_focus = this.my_focus;
        data.specialties_areas = this.specialties_areas;

        data.id_doctors_specialty = this.id_doctors_specialty;
        data.registration_number = this.registration_number;
        data.job_place = this.job_place;
        data.disabled_at = this.disabled_at;
        data.disabled_by = this.disabled_by;
        data.deleted_at = this.deleted_at;
        data.deleted_by = this.deleted_by;
        data.online_at = this.online_at;
        data.offline_at = this.offline_at;
        data.common = this.common;
        data.is_account = this.is_account;

        data.experiences = this.experiences.raw();
        data.educations = this.educations.raw();
        data.insurances = this.insurances.raw();
        data.memberships = this.memberships.raw();
        data.people_may_know = this.people_may_know.raw();
        data.locations = this.locations.raw();
        data.connection = this.connection.raw();
        data.connections = this.connections.raw();
        data.search_connections = this.search_connections.raw();

        return data;
    }

    to_string(): ToStringInterface {
        let department = this.catalogsService.catalogs.departments.find(
            (v) => v.id == this.id_department
        );
        let town = this.catalogsService.catalogs.towns.find(
            (v) => v.id == this.id_town
        );
        let country = this.catalogsService.catalogs.countries.find(
            (v) => v.id == this.id_country
        );
        let doctors_specialty =
            this.catalogsService.catalogs.doctors_specialties.find(
                (v) => v.id == this.id_doctors_specialty
            );
        let specialties_areas = [];
        if (this.specialties_areas) {
            specialties_areas = this.specialties_areas
                .map((v) => {
                    let item =
                        this.catalogsService.catalogs.specialties_areas.find(
                            (c) => c.id == v
                        );
                    return item ? item : null;
                })
                .filter((v) => v);
        }

        this.static_to_string = {
            id: this.id,
            id_user: this.id_user,
            firstname: this.firstname,
            lastname: this.lastname,
            username: this.username,
            id_gender: this.id_gender,
            birthdate: this.birthdate,
            birthplace: this.birthplace,
            id_country: this.id_country,
            country: country ? country.name : "",
            id_department: this.id_department,
            department: department ? department.name : "",
            id_town: this.id_town,
            town: town ? town.name : "",
            address: this.address,
            phone: this.phone,
            verified_phone: this.verified_phone,
            v_phone: this.v_phone,
            email: this.email,
            verified_email: this.verified_email,
            v_email: this.v_email,
            id_education: this.id_education,
            identification: this.identification,
            image: this.image,
            id_blood: this.id_blood,
            organ_donor: this.organ_donor,
            glasses: this.glasses,
            my_focus: this.my_focus,
            specialties_areas: specialties_areas,
            connnections: this.connnections
                ? this.pipesService.number(
                      parseFloat((this.connnections + "").replace(/,/g, "")),
                      0,
                      0
                  )
                : "0",
            thanks: this.thanks
                ? this.pipesService.number(
                      parseFloat((this.thanks + "").replace(/,/g, "")),
                      0,
                      0
                  )
                : "0",
            patients: this.patients
                ? this.pipesService.number(
                      parseFloat((this.patients + "").replace(/,/g, "")),
                      0,
                      0
                  )
                : "0",
            id_doctors_specialty: this.id_doctors_specialty,
            doctors_specialty: doctors_specialty ? doctors_specialty.name : "",
            registration_number: this.registration_number,
            job_place: this.job_place,
            disabled_at: this.disabled_at,
            disabled_by: this.disabled_by,
            deleted_at: this.deleted_at,
            deleted_by: this.deleted_by,
            online_at: this.online_at,
            offline_at: this.offline_at,
            common: this.common,
            is_account: this.is_account,
        };

        this.experiences.to_string();
        this.educations.to_string();
        this.insurances.to_string();
        this.memberships.to_string();
        this.people_may_know.to_string();
        this.locations.to_string();
        this.connection.to_string();
        this.connections.to_string();
        this.search_connections.to_string();

        return this.static_to_string;
    }

    clone(): ProfileClass {
        return new ProfileClass(
            this.catalogsService,
            this.pipesService,
            this.raw()
        );
    }
}
