import { NgModule } from '@angular/core';

import { PipesModule } from 'src/app/shared/ui/pipes/pipes.module';
import { FormRenderModule } from 'src/app/shared/ui/form_render/form_render.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DatatableModule } from 'src/app/shared/ui/datatable/datatable.module';

import { RouterModule, Routes } from '@angular/router';
import { NgbAccordionModule, NgbAlertModule, NgbCollapseModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule, NgbModule, NgbPaginationModule, NgbPopoverModule, NgbProgressbarModule, NgbTabsetModule, NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';
import { UIModule } from 'src/app/shared/ui/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ToastrModule } from 'ngx-toastr';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { TextMaskModule } from 'angular2-text-mask';
import { InstitutionsComponent } from './institutions.component';
import { FormComponent } from './form/form.component';
import { InstitutionsService } from './institutions.service';
import { UpdateInstitutionsComponent } from './update_institutions/update_institutions.component';
import { CreateInstitutionsComponent } from './create_institutions/create_institutions.component';
import { ShowInstitutionsComponent } from './show_institutions/show_institutions.component';

const routes: Routes = [
  {
    pathMatch: 'prefix',
    path: '',
    component: InstitutionsComponent
  }
];

@NgModule({
  declarations: [
    InstitutionsComponent,
    FormComponent,
    CreateInstitutionsComponent,
    UpdateInstitutionsComponent,
    ShowInstitutionsComponent
  ],
  exports: [
    InstitutionsComponent
  ],
  imports: [
    PipesModule,
    FormRenderModule,
    DatatableModule,
    SharedModule,
    ClickOutsideModule,
    UIModule,
    FormsModule,
    NgbModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
    }),
    FileUploadModule,
    NgbDropdownModule,
    NgbTabsetModule,
    NgbAccordionModule,
    NgbCollapseModule,
    NgbModalModule,
    NgbProgressbarModule,
    NgbAlertModule,
    NgbToastModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbPaginationModule,
    TextMaskModule,
    RouterModule.forChild(routes)
  ],
  providers: [
    InstitutionsService
  ]
})
export class InstitutionsModule { }