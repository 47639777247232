/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./doctor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../shared/ui/search_button/search_button.component.ngfactory";
import * as i4 from "../../../../shared/ui/search_button/search_button.component";
import * as i5 from "../../../../shared/ui/datatable/datatable.component.ngfactory";
import * as i6 from "../../../../shared/ui/datatable/datatable.component";
import * as i7 from "./show_doctor/show_doctor.component.ngfactory";
import * as i8 from "./show_doctor/show_doctor.component";
import * as i9 from "../../../../services/page.service";
import * as i10 from "./create_doctor/create_doctor.component.ngfactory";
import * as i11 from "./create_doctor/create_doctor.component";
import * as i12 from "./doctor.service";
import * as i13 from "../../../../services/expedient.service";
import * as i14 from "./update_doctor/update_doctor.component.ngfactory";
import * as i15 from "./update_doctor/update_doctor.component";
import * as i16 from "../../../../shared/ui/dynamic_tabs/dynamic_tabs.component.ngfactory";
import * as i17 from "../../../../shared/ui/dynamic_tabs/dynamic_tabs.component";
import * as i18 from "./doctor.component";
import * as i19 from "../../../../services/app.service";
import * as i20 from "../../../../services/catalogs.service";
import * as i21 from "../../../../shared/services/sweel.service";
import * as i22 from "ngx-toastr";
var styles_ContactsDoctorComponent = [i0.styles];
var RenderType_ContactsDoctorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactsDoctorComponent, data: {} });
export { RenderType_ContactsDoctorComponent as RenderType_ContactsDoctorComponent };
function View_ContactsDoctorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "title-last mt-auto"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" expediente "]))], null, null); }
function View_ContactsDoctorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsDoctorComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.page_state; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ContactsDoctorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-search_button", [], null, [[null, "change"], [null, "submit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.on_update() !== false);
        ad = (pd_0 && ad);
    } if (("submit" === en)) {
        var pd_1 = (_co.on_update() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_SearchButtonComponent_0, i3.RenderType_SearchButtonComponent)), i1.ɵdid(2, 114688, [[1, 4], ["search_button_ref", 4]], 0, i4.SearchButtonComponent, [], null, { submit: "submit", change: "change" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ContactsDoctorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "flex-grow-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "local-internal-scroll"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "scroll-content pr-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-datatable", [], null, [[null, "update_datatable"], [null, "events_datatable"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("update_datatable" === en)) {
        var pd_0 = (_co.update($event) !== false);
        ad = (pd_0 && ad);
    } if (("events_datatable" === en)) {
        var pd_1 = (_co.events($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_DatatableComponent_0, i5.RenderType_DatatableComponent)), i1.ɵdid(5, 114688, [[2, 4], ["datatable_ref", 4]], 0, i6.DatatableComponent, [], { in_headers: [0, "in_headers"], in_events: [1, "in_events"], in_data: [2, "in_data"], in_filters: [3, "in_filters"], in_configs: [4, "in_configs"] }, { update_datatable: "update_datatable", events_datatable: "events_datatable" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datatable.headers; var currVal_1 = _co.datatable.events; var currVal_2 = _co.list_state; var currVal_3 = _co.datatable.filters; var currVal_4 = _co.datatable.configs; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ContactsDoctorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-datatable", [], null, [[null, "update_datatable"], [null, "events_datatable"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("update_datatable" === en)) {
        var pd_0 = (_co.update($event) !== false);
        ad = (pd_0 && ad);
    } if (("events_datatable" === en)) {
        var pd_1 = (_co.events($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_DatatableComponent_0, i5.RenderType_DatatableComponent)), i1.ɵdid(2, 114688, [[2, 4], ["datatable_ref", 4]], 0, i6.DatatableComponent, [], { in_headers: [0, "in_headers"], in_events: [1, "in_events"], in_data: [2, "in_data"], in_filters: [3, "in_filters"], in_configs: [4, "in_configs"] }, { update_datatable: "update_datatable", events_datatable: "events_datatable" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datatable.headers; var currVal_1 = _co.datatable.events; var currVal_2 = _co.list_state; var currVal_3 = _co.datatable.filters; var currVal_4 = _co.datatable.configs; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ContactsDoctorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contacts_show_doctor", [], null, null, null, i7.View_ShowContactsDoctorComponent_0, i7.RenderType_ShowContactsDoctorComponent)), i1.ɵdid(1, 49152, null, 0, i8.ShowContactsDoctorComponent, [i9.PageService], null, null)], null, null); }
function View_ContactsDoctorComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contacts_create_doctor", [], null, null, null, i10.View_CreateContactsDoctorComponent_0, i10.RenderType_CreateContactsDoctorComponent)), i1.ɵdid(1, 49152, null, 0, i11.CreateContactsDoctorComponent, [i12.DoctorService, i13.ExpedientService], null, null)], null, null); }
function View_ContactsDoctorComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contacts_update_doctor", [], null, null, null, i14.View_UpdateContactsDoctorComponent_0, i14.RenderType_UpdateContactsDoctorComponent)), i1.ɵdid(1, 49152, null, 0, i15.UpdateContactsDoctorComponent, [i12.DoctorService], null, null)], null, null); }
export function View_ContactsDoctorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { search_button_ref: 0 }), i1.ɵqud(671088640, 2, { datatable_ref: 0 }), i1.ɵqud(671088640, 3, { createModal: 0 }), i1.ɵqud(671088640, 4, { updateModal: 0 }), i1.ɵqud(671088640, 5, { showModal: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 23, "div", [["class", "d-flex flex-column pr-3 py-2 h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 18, "div", [["class", "container-fluid px-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 17, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 13, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 12, "div", [["class", "d-flex justify-content-between mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "d-flex flex-column my-auto"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsDoctorComponent_1)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "title mb-auto"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" contactos "])), (_l()(), i1.ɵeld(15, 0, null, null, 6, "div", [["class", "my-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 5, "div", [["class", "d-flex text-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsDoctorComponent_3)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["class", "ml-2 btn radius btn-primary no-wrap"], ["style", "font-weight: 500;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.create() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "i", [["class", "fa-duotone fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" CONTACTO "])), (_l()(), i1.ɵeld(22, 0, null, null, 2, "div", [["class", "col-12 mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "app-dynamic_tabs", [], null, null, null, i16.View_DynamicTabsComponent_0, i16.RenderType_DynamicTabsComponent)), i1.ɵdid(24, 49152, null, 0, i17.DynamicTabsComponent, [], { config: [0, "config"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsDoctorComponent_4)), i1.ɵdid(26, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsDoctorComponent_5)), i1.ɵdid(28, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[5, 2], ["showModal", 2]], null, 0, null, View_ContactsDoctorComponent_6)), (_l()(), i1.ɵand(0, [[3, 2], ["createModal", 2]], null, 0, null, View_ContactsDoctorComponent_7)), (_l()(), i1.ɵand(0, [[4, 2], ["updateModal", 2]], null, 0, null, View_ContactsDoctorComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.expedient_state.version == "expedient"); _ck(_v, 12, 0, currVal_0); var currVal_1 = (_co.expedient_state.version == "expedient"); _ck(_v, 18, 0, currVal_1); var currVal_2 = _co.dynamic_tabs; _ck(_v, 24, 0, currVal_2); var currVal_3 = (_co.expedient_state.version == "expedient"); _ck(_v, 26, 0, currVal_3); var currVal_4 = (_co.expedient_state.version == "new_patient"); _ck(_v, 28, 0, currVal_4); }, null); }
export function View_ContactsDoctorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-expedient-contacts-doctor", [], null, null, null, View_ContactsDoctorComponent_0, RenderType_ContactsDoctorComponent)), i1.ɵdid(1, 245760, null, 0, i18.ContactsDoctorComponent, [i19.AppService, i13.ExpedientService, i9.PageService, i12.DoctorService, i20.CatalogsService, i21.SweelService, i22.ToastrService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactsDoctorComponentNgFactory = i1.ɵccf("app-expedient-contacts-doctor", i18.ContactsDoctorComponent, View_ContactsDoctorComponent_Host_0, {}, { _change_view_event: "change_view" }, []);
export { ContactsDoctorComponentNgFactory as ContactsDoctorComponentNgFactory };
