import { AccountService } from '../services/account.service';
import { AppService } from '../services/app.service';
import { PageService } from '../services/page.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/page.service";
import * as i2 from "../services/app.service";
import * as i3 from "../services/account.service";
export class FirstLoginGuard {
    constructor(pageService, endpoint, accountService) {
        this.pageService = pageService;
        this.endpoint = endpoint;
        this.accountService = accountService;
    }
    canActivate() {
        let session = this.endpoint.get_session();
        if (session && session.token) {
            if (session.first_login) {
                this.accountService.init();
                return true;
            }
            else {
                this.endpoint.redirect_to('');
            }
        }
        else {
            this.accountService.reset();
            this.endpoint.redirect_to('/conectarse');
        }
    }
}
FirstLoginGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FirstLoginGuard_Factory() { return new FirstLoginGuard(i0.ɵɵinject(i1.PageService), i0.ɵɵinject(i2.AppService), i0.ɵɵinject(i3.AccountService)); }, token: FirstLoginGuard, providedIn: "root" });
