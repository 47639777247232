<ng-container *ngIf="expedient_state.version=='expedient'">
    <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="expedient_state.version=='new_patient'">
    <ng-container *ngIf="view==0">
        <app-expedient-relations-tutors (change_view)="view=1"></app-expedient-relations-tutors>
    </ng-container>
    <ng-container *ngIf="view==1">
        <app-expedient-relations-dependants (change_view)="view=0"></app-expedient-relations-dependants>
    </ng-container>
</ng-container>