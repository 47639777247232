/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./general.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./form/form.component.ngfactory";
import * as i4 from "./form/form.component";
import * as i5 from "./general.service";
import * as i6 from "../../../services/app.service";
import * as i7 from "../../../services/expedient.service";
import * as i8 from "../../../services/catalogs.service";
import * as i9 from "./general.component";
import * as i10 from "../../../services/page.service";
var styles_GeneralComponent = [i0.styles];
var RenderType_GeneralComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GeneralComponent, data: {} });
export { RenderType_GeneralComponent as RenderType_GeneralComponent };
function View_GeneralComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "d-flex flex-column my-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "title-last mt-auto"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "title mb-auto"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" perfil "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.page_state.profile ? "perfil" : ""); var currVal_1 = (_co.page_state.expedient ? "expediente" : ""); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_GeneralComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "p-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GeneralComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "brief-items container-fluid px-2"], ["style", "width: 800px; margin-left: 0;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-module-form", [], null, null, null, i3.View_FormComponent_0, i3.RenderType_FormComponent)), i1.ɵdid(5, 4440064, null, 0, i4.FormComponent, [i5.GeneralService, i6.AppService, i7.ExpedientService, i8.CatalogsService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.expedient_state.version == "expedient"); _ck(_v, 2, 0, currVal_0); _ck(_v, 5, 0); }, null); }
export function View_GeneralComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-expedient-general", [], null, null, null, View_GeneralComponent_0, RenderType_GeneralComponent)), i1.ɵdid(1, 245760, null, 0, i9.GeneralComponent, [i10.PageService, i7.ExpedientService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GeneralComponentNgFactory = i1.ɵccf("app-expedient-general", i9.GeneralComponent, View_GeneralComponent_Host_0, {}, {}, []);
export { GeneralComponentNgFactory as GeneralComponentNgFactory };
