import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ExpedientMetadata, ExpedientService } from 'src/app/services/expedient.service';
import { PageService, PageState } from 'src/app/services/page.service';

@Component({
	selector: 'app-expedient-general',
	templateUrl: './general.component.html',
	styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit, OnDestroy {
	private page_subscription: Subscription;
	public page_state: PageState;
	private expedient_subscription: Subscription;
	public expedient_state: ExpedientMetadata;

	constructor(private pageService: PageService, private expedientService: ExpedientService) {
	}

	ngOnInit() {
		this.page_subscription = this.pageService.current.subscribe(
			state => this.page_state = state
		);
		this.expedient_subscription = this.expedientService.current.subscribe(
			state => this.expedient_state = state
		);
	}

	ngOnDestroy() {
		this.page_subscription.unsubscribe();
		this.expedient_subscription.unsubscribe();
	}
}