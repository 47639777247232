import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConsultMinimizedHeaderComponent } from './consult-minimized-header/consult-minimized-header.component';
import { GeneralModule } from './general/general.module';
import { RoutingModule } from './routing.module';

@NgModule({
  declarations:[
    ConsultMinimizedHeaderComponent
  ],
  imports: [
    CommonModule,
    GeneralModule,
    RoutingModule,
  ],
  exports: [
    ConsultMinimizedHeaderComponent
  ]
})
export class NewConsultModule { }
