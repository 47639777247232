import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Input,
    OnDestroy,
    AfterViewInit,
} from "@angular/core";
import { Router } from "@angular/router";

import { Subscription } from "rxjs";
import { PageService, PageState } from "../../services/page.service";

import { PatientService } from "src/app/services/patient-service.service";
import { AppService } from "src/app/services/app.service";
import { AccountService, AccountState } from "src/app/services/account.service";

@Component({
    selector: "app-topbar",
    templateUrl: "./topbar.component.html",
    styleUrls: ["./topbar.component.scss"],
})
export class TopbarComponent implements OnInit, OnDestroy, AfterViewInit {
    private account_subscription: Subscription;
    public account_state: AccountState;
    private page_subscription: Subscription;
    public page_state: PageState;
    private patient_subscription: Subscription;
    public patient_data: any;

    public avatar_configs = {
        size: "2.5rem",
        font_size: "1.5rem",
    };

    constructor(
        private router: Router,
        private patientService: PatientService,
        private pageService: PageService,
        private accountService: AccountService,
        private endpoint: AppService
    ) {}

    ngOnInit() {
        this.account_subscription = this.accountService.current.subscribe(
            (data) => (this.account_state = data)
        );
        this.patient_subscription =
            this.patientService.patient_data_subscription.subscribe(
                (data) => (this.patient_data = data)
            );
        this.page_subscription = this.pageService.current.subscribe(
            (state) => (this.page_state = state)
        );
    }

    ngAfterViewInit() {}

    ngOnDestroy() {
        this.account_subscription.unsubscribe();
        this.patient_subscription.unsubscribe();
        this.page_subscription.unsubscribe();
    }

    sidebar_1_toggle() {
        this.pageService.set_sidebar_1_state({
            expand: !this.page_state.sidebar_1.expand,
            scroll: true,
            show: this.page_state.sidebar_1.show,
        });
    }

    click_search() {
        this.endpoint.redirect_to("/buscar");
    }

    logout() {
        this.endpoint.logout().subscribe(() => {
            this.endpoint.reset_session();
            this.router.navigate(["/conectarse"]);
        });
    }

    new_consult() {
        this.pageService.set_consult(true);
    }
}
