import { Pipe, PipeTransform } from "@angular/core";

type catalog_type = {
    list(): any[];
    value: string;
    text: string;
};

@Pipe({ name: "catalog" })
export class CatalogPipe implements PipeTransform {
    transform(id: any, catalog: catalog_type): string {
        if (catalog) {
            let item = catalog.list().find(v => v[catalog.value] == id);
            return item ? item[catalog.text] : "—";
        } else {
            return "—";
        }
    }
}
