import { OwlDateTimeIntl } from 'ng-pick-datetime';
export class DefaultIntl extends OwlDateTimeIntl {
    constructor() {
        super(...arguments);
        this.cancelBtnLabel = 'Cancelar';
        this.setBtnLabel = 'Aceptar';
    }
}
;
export class FormRenderModule {
}
