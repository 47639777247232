/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./avatar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./avatar.component";
import * as i4 from "../../../services/app.service";
var styles_AvatarComponent = [i0.styles];
var RenderType_AvatarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AvatarComponent, data: {} });
export { RenderType_AvatarComponent as RenderType_AvatarComponent };
function View_AvatarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0], [4, "font-size", null], [4, "line-height", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("fa-duotone fa-circle-" + _co.letter) + " avatar"); var currVal_1 = ((_co.configs == null) ? null : _co.configs.size); var currVal_2 = ((_co.configs == null) ? null : _co.configs.size); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_AvatarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "img-fluid"]], [[8, "src", 4], [8, "alt", 0]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.error_image() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image; var currVal_1 = _co.name; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_AvatarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mx-auto"], ["style", "overflow: hidden"]], [[4, "height", null], [4, "width", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvatarComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.image; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.configs == null) ? null : _co.configs.size); var currVal_1 = ((_co.configs == null) ? null : _co.configs.size); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_AvatarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvatarComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AvatarComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.image; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.image; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AvatarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "avatar", [], null, null, null, View_AvatarComponent_0, RenderType_AvatarComponent)), i1.ɵdid(1, 49152, null, 0, i3.AvatarComponent, [i4.AppService], null, null)], null, null); }
var AvatarComponentNgFactory = i1.ɵccf("avatar", i3.AvatarComponent, View_AvatarComponent_Host_0, { _name: "name", _image: "image", configs: "configs" }, {}, []);
export { AvatarComponentNgFactory as AvatarComponentNgFactory };
