import { Routes } from "@angular/router";
import { LayoutComponent } from "./layout.component";
const ɵ0 = () => import("./pages/general/general.module.ngfactory").then((m) => m.ConsultGeneralModuleNgFactory), ɵ1 = () => import("./pages/anthropometries/anthropometries.module.ngfactory").then((m) => m.ConsultAnthropometriesModuleNgFactory), ɵ2 = () => import("./pages/vitals/vitals.module.ngfactory").then((m) => m.ConsultVitalsModuleNgFactory), ɵ3 = () => import("./pages/growth_curve/growth_curve.module.ngfactory").then((m) => m.ConsultGrowthCurveModuleNgFactory), ɵ4 = () => import("./pages/exams_physical/exams_physical.module.ngfactory").then((m) => m.ConsultExamsPhysicalModuleNgFactory), ɵ5 = () => import("./pages/diagnosis/diagnosis.module.ngfactory").then((m) => m.ConsultDiagnosisModuleNgFactory), ɵ6 = () => import("./pages/prescription/prescription.module.ngfactory").then((m) => m.ConsultPrescriptionModuleNgFactory), ɵ7 = () => import("./pages/recommendation/recommendation.module.ngfactory").then((m) => m.ConsultRecommendationModuleNgFactory), ɵ8 = () => import("./pages/procedures/procedures.module.ngfactory").then((m) => m.ConsultProceduresModuleNgFactory), ɵ9 = () => import("./pages/references/references.module.ngfactory").then((m) => m.ConsultReferencesModuleNgFactory), ɵ10 = () => import("./pages/exams_laboratory/exams_laboratory.module.ngfactory").then((m) => m.ConsultExamsLaboratoriesModuleNgFactory);
const routes = [
    {
        path: "",
        component: LayoutComponent,
        children: [
            { path: "", redirectTo: "/expediente/nueva-consulta/general/general", pathMatch: "full" },
            {
                path: "general",
                loadChildren: ɵ0,
            },
            {
                path: "antropometria",
                outlet: "secondary",
                loadChildren: ɵ1,
            },
            {
                path: "vitales",
                loadChildren: ɵ2,
            },
            {
                path: "curva_crecimiento",
                outlet: "secondary",
                loadChildren: ɵ3,
            },
            {
                path: "examen_fisico",
                loadChildren: ɵ4,
            },
            {
                path: "diagnostico",
                loadChildren: ɵ5,
            },
            {
                path: "receta",
                loadChildren: ɵ6,
            },
            {
                path: "recomendaciones_notas",
                loadChildren: ɵ7,
            },
            {
                path: "procedimientos",
                loadChildren: ɵ8,
            },
            {
                path: "referencias",
                loadChildren: ɵ9,
            },
            {
                path: "examenes",
                loadChildren: ɵ10,
            },
        ],
    },
];
export class RoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
