import { CatalogsService } from "src/app/services/catalogs.service";
import { CustomClass } from "../custom-class";

export interface MembershipsInterface {
    id: any;
    id_user: any;
    place: string;
    name: string;
    year: string;
}

interface ToStringInterface {
    place: string;
    name: string;
    year: string;
}

export class MembershipsClass extends CustomClass implements MembershipsInterface {
    public id: any;
    public id_user: any;
    public place: string;
    public name: string;
    public year: string;

    constructor(private catalogsService: CatalogsService, data?: MembershipsInterface) {
        super();
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: MembershipsInterface): void {
        super.overwrite(data);
        this.id = data.id;
        this.id_user = data.id_user;
        this.place = data.place;
        this.name = data.name;
        this.year = data.year;
    }

    reset(): void {
        super.reset();
        this.id = null;
        this.id_user = null;
        this.place = "";
        this.name = "";
        this.year = "";
    }

    format(): MembershipsInterface {
        return this as MembershipsInterface;
    }

    valid(): boolean {
        return true;
    }

    to_string(): ToStringInterface {
        let data: ToStringInterface = {
            place: this.place,
            name: this.name,
            year: this.year,
        };
        return data as ToStringInterface;
    }

    raw(): MembershipsInterface {
        return {
            id: this.id,
            id_user: this.id_user,
            place: this.place,
            name: this.name,
            year: this.year,
        };
    }
}
