<div class="d-flex flex-column h-100" style="position: relative">
    <div class="consult-layout d-flex flex-column" [class.blur]="consult_state.loading">
        <div class="container-fluid px-3 pt-1 c-header">
            <div class="row">
                <div class="col-12 mb-1">
                    <div class="d-flex">
                        <div class="d-flex flex-column my-auto">
                            <div class="title mb-auto d-flex no-wrap">
                                <div>nueva consulta</div>
                                <!-- {{consult_state.consult.id }} -->
                                <div class="ml-2 mt-auto title-last">
                                    {{ consult_state.consult.created_at | longDate }}
                                </div>
                            </div>
                        </div>
                        <div class="d-flex ml-auto flex-column">
                            <div class="ml-auto">
                                <a class="h-icon h-icon-sm my-auto" title="Minimizar" (click)="save_minimize_consult()">
                                    <i class="fa-duotone fa-window-minimize"></i>
                                </a>
                                <a class="h-icon h-icon-sm my-auto" title="Guardar" (click)="save_consult()">
                                    <i class="fa-duotone fa-save"></i>
                                </a>
                                <a class="h-icon h-icon-sm my-auto warning" title="Cancelar" (click)="cancel()">
                                    <i class="fa-duotone fa-ban"></i>
                                </a>
                                <a class="h-icon h-icon-sm my-auto danger" title="Cerrar y guardar" (click)="save_close_consult()">
                                    <i class="fa-duotone fa-times"></i>
                                </a>
                            </div>
                            <div class="mx-auto c-color-1" style="font-size: 0.6rem">Guardado: {{ consult_state.consult.updated_at | longOnlyTime }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid edge-shadow-bottom">
            <div class="row">
                <div class="col-12 mb-0 px-0">
                    <app-dynamic_tabs [config]="dynamic_tabs"></app-dynamic_tabs>
                </div>
            </div>
        </div>
        <div class="flex-grow-1 px-3 py-2" style="background: rgba(0, 0, 0, 0.01)">
            <div class="local-internal-scroll">
                <div class="scroll-content pr-2 pl-1">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
        <div class="d-flex bg-white py-1 px-2 edge-shadow-top">
            <!-- <div class="btn-group" ngbDropdown>
                    <button type="button" style="font-weight: 500" class="btn btn-primary no-radius-right" [disabled]="!valid()" (click)="submit()">FINALIZAR</button>

                    <button id="consult_tools_dropdown" type="button" class="btn btn-primary no-radius-left" ngbDropdownToggle>
                        <i class="fa-duotone fa-caret-down"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-left" aria-labelledby="consult_tools_dropdown" ngbDropdownMenu>
                        <button ngbDropdownItem class="no-wrap" style="font-weight: 500" (click)="cancel()"><i class="fa-duotone fa-times text-danger mr-1"></i> CANCELAR</button> -->
            <!-- <button ngbDropdownItem class="no-wrap" style="font-weight: 500;" (click)="print()"><i class="fa-duotone fa-print mr-1"></i> IMPRIMIR </button> -->
            <!-- </div>
            </div> -->

            <!-- Tags -->
            <a class="icon-secondary ml-2" title="Exámenes" routerLink="examenes" [routerLinkActive]="['active-page']">
                <i class="fa-duotone fa-microscope"></i>
                <div class="text">exámenes</div>
            </a>
            <a class="icon-secondary" title="Procedimientos" routerLink="procedimientos" [routerLinkActive]="['active-page']">
                <i class="fa-duotone fa-procedures"></i>
                <div class="text">procedimientos</div>
            </a>
            <a class="icon-secondary" title="Referencias" routerLink="referencias" [routerLinkActive]="['active-page']">
                <i class="fa-duotone fa-users-line"></i>
                <div class="text">referencias</div>
            </a>
            <a class="icon-secondary" title="Recomendaciones y Notas" routerLink="recomendaciones_notas" [routerLinkActive]="['active-page']">
                <i class="fa-duotone fa-notes"></i>
                <div class="text">recomendaciones y notas</div>
            </a>
           

            <!-- Buttons -->
            <button title="Anterior" style="font-weight: 500" class="ml-auto btn btn-secondary radius" [disabled]="!get_back()" [routerLink]="get_back()">
                <i class="fa-duotone fa-chevron-left"></i>
            </button>
            <button title="Siguiente" style="font-weight: 500" class="ml-1 btn btn-primary radius" [disabled]="!get_next()" [routerLink]="get_next()">
                <i class="fa-duotone fa-chevron-right"></i>
            </button>
            <button title="Finalizar" type="button" style="font-weight: 500" class="ml-1 btn btn-primary radius" [disabled]="!valid()" (click)="submit()">FINALIZAR</button>
        </div>
    </div>

    <div class="overlay-internal" *ngIf="consult_state.loading">
        <div class="content-internal">
            <div class="d-flex flex-column h-100">
                <div class="spinner-border text-white m-auto" style="width: 5rem; height: 5rem; border-width: 1rem"></div>
            </div>
        </div>
    </div>

    <div class="overlay-internal" *ngIf="consult_state.overlay">
        <!-- <div class="content-internal"> -->
            <!-- <router-outlet name="overlay" (activate)="onActivate($event)" (deactivate)="onDeactivate($event)" (attach)="onAttach($event)" (detach)="onDetach($event)"></router-outlet> -->
        <!-- </div> -->
    </div>
</div>
