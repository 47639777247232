import { CustomClass } from "./custom-class";

export interface GeneralInterface {
    id: any;
    reason: string;
    description: string;
};

interface ToStringInterface {
    reason: string;
    description: string;
};

export class GeneralClass extends CustomClass implements GeneralInterface {
    public id: any;
    public reason: string;
    public description: string;

    constructor(data?: GeneralInterface) {
        super();
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: GeneralInterface): void {
        super.overwrite(data);
        this.id = data.id;
        this.reason = data.reason;
        this.description = data.description;
    }

    reset(): void {
        super.reset();
        this.id = null;
        this.reason = "";
        this.description = "";
    }

    format(): GeneralInterface {
        if (this.reason) this.reason = this.reason.trim();
        if (this.description) this.description = this.description.trim();
        return this as GeneralInterface;
    }

    valid(): boolean {
        if (this.reason.trim().length > 0) {
            return true;
        }
        return false;
    }

    to_string(): ToStringInterface {
        let data = this.format();

        return data as ToStringInterface;
    }

    raw(): GeneralInterface {
        return {
            id: this.id,
            reason: this.reason,
            description: this.description,
        };
    }
}
