import { AvatarComponent } from "./ui/avatar/avatar.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { UIModule } from "./ui/ui.module";
import { CustomHolderComponent } from "./ui/request_holders/custom-holder/custom-holder.component";
import { ErrorBigComponent } from "./ui/request_holders/errors/big/error.component";
import { ErrorSmallComponent } from "./ui/request_holders/errors/small/error.component";
import { SpinnerBigComponent } from "./ui/request_holders/spinners/big/spinner.component";
import { SpinnerSmallComponent } from "./ui/request_holders/spinners/small/spinner.component";
import { OverlayComponent } from "./ui/request_holders/overlay/overlay.component";
import { SearchButtonComponent } from "./ui/search_button/search_button.component";
import { DynamicTabsComponent } from "./ui/dynamic_tabs/dynamic_tabs.component";
import { RouterModule } from "@angular/router";
import { HoveredSidebarComponent } from "./ui/hovered_sidebar/hovered_sidebar.component";
import { AvatarRawComponent } from "./ui/avatar_r/avatar.component";

@NgModule({
    declarations: [
        CustomHolderComponent,
        ErrorBigComponent,
        ErrorSmallComponent,
        SpinnerBigComponent,
        SpinnerSmallComponent,
        OverlayComponent,
        SearchButtonComponent,
        DynamicTabsComponent,
        HoveredSidebarComponent,
        AvatarComponent,
        AvatarRawComponent
    ],
    imports: [CommonModule, UIModule, FormsModule, RouterModule],
    exports: [
        CommonModule,
        UIModule,
        CustomHolderComponent,
        ErrorBigComponent,
        ErrorSmallComponent,
        SpinnerBigComponent,
        SpinnerSmallComponent,
        OverlayComponent,
        SearchButtonComponent,
        DynamicTabsComponent,
        HoveredSidebarComponent,
        AvatarComponent,
        AvatarRawComponent
    ],
})
export class SharedModule {}
