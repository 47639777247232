import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LayoutComponent } from "./layout.component";

const routes: Routes = [
    {
        path: "",
        component: LayoutComponent,
        children: [
            { path: "", redirectTo: "/expediente/nueva-consulta/general/general", pathMatch: "full" },
            {
                path: "general",
                loadChildren: () => import("./pages/general/general.module").then((m) => m.ConsultGeneralModule),
            },
            {
                path: "antropometria",
                outlet: "secondary",
                loadChildren: () => import("./pages/anthropometries/anthropometries.module").then((m) => m.ConsultAnthropometriesModule),
            },
            {
                path: "vitales",
                loadChildren: () => import("./pages/vitals/vitals.module").then((m) => m.ConsultVitalsModule),
            },
            {
                path: "curva_crecimiento",
                outlet: "secondary",
                loadChildren: () => import("./pages/growth_curve/growth_curve.module").then((m) => m.ConsultGrowthCurveModule),
            },
            {
                path: "examen_fisico",
                loadChildren: () => import("./pages/exams_physical/exams_physical.module").then((m) => m.ConsultExamsPhysicalModule),
            },
            {
                path: "diagnostico",
                loadChildren: () => import("./pages/diagnosis/diagnosis.module").then((m) => m.ConsultDiagnosisModule),
            },
            {
                path: "receta",
                loadChildren: () => import("./pages/prescription/prescription.module").then((m) => m.ConsultPrescriptionModule),
            },
            {
                path: "recomendaciones_notas",
                loadChildren: () => import("./pages/recommendation/recommendation.module").then((m) => m.ConsultRecommendationModule),
            },
            {
                path: "procedimientos",
                loadChildren: () => import("./pages/procedures/procedures.module").then((m) => m.ConsultProceduresModule),
            },
            {
                path: "referencias",
                loadChildren: () => import("./pages/references/references.module").then((m) => m.ConsultReferencesModule),
            },
            {
                path: "examenes",
                loadChildren: () => import("./pages/exams_laboratory/exams_laboratory.module").then((m) => m.ConsultExamsLaboratoriesModule),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class RoutingModule {}
