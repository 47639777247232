import { Component } from '@angular/core';
import { LaboratoryService } from '../laboratory.service';

@Component({
  selector: 'app-exams_update_laboratory',
  templateUrl: './update_laboratory.component.html',
  styleUrls: ['./update_laboratory.component.scss']
})
export class UpdateExamsLaboratoryComponent {

  constructor(private moduleService: LaboratoryService) {
  }

  close() {
    this.moduleService.cancel();
  }

}
