<div class="overlay-internal p-3">
    <div class="content-internal mx-3">
        <div class="d-flex flex-column pl-5 pr-4 py-4 h-100">
            <div class="container-fluid px-0">
                <div class="row">
                    <div class="col-12 mb-2">
                        <div class="d-flex">
                            <div class="d-flex flex-column my-auto pr-1">
                                <div class="title-last mt-auto">
                                    consulta médica
                                </div>
                                <div class="title mb-auto d-flex no-wrap mt-1">
                                    <ng-container *ngIf="data.created_at">
                                        {{
                                            data.created_at
                                                | shortDate
                                                | uppercase
                                        }}
                                    </ng-container>
                                </div>
                            </div>

                            <a
                                class="mb-auto ml-auto text-secondary pointer"
                                style="font-weight: 500"
                                (click)="close()"
                                ><i class="fa-duotone fa-times fa-lg"></i
                            ></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex">
                <div class="flex-grow-1">
                    <div class="container-fluid">
                        <div class="row" *ngIf="data.consult_l">
                            <div
                                class="col-12 mt-2"
                                *ngIf="data.consult_l.general.reason"
                            >
                                <div class="title-2 mb-1">
                                    MOTIVO DE CONSULTA
                                </div>
                                <div>
                                    {{ data.consult_l.general.reason }}
                                </div>
                            </div>
                            <div
                                class="col-12 mt-2"
                                *ngIf="data.consult_l.general.description"
                            >
                                <div class="title-2 mb-1">
                                    HISTORIA DE ENFERMEDAD ACTUAL
                                </div>
                                <div>
                                    {{ data.consult_l.general.description }}
                                </div>
                            </div>

                            <ng-container
                                *ngIf="data.consult_l.anthropometries"
                            >
                                <div
                                    class="col-12 my-2"
                                    *ngIf="
                                        data.consult_l.anthropometries &&
                                        (data.consult_l.anthropometries
                                            .height ||
                                            data.consult_l.anthropometries
                                                .weight)
                                    "
                                >
                                    <div class="title-2">ANTROPOMETRÍA</div>
                                </div>

                                <div
                                    class="col-md-4"
                                    *ngIf="
                                        data.consult_l.anthropometries.height
                                    "
                                >
                                    <div class="title-3 mb-1">Talla</div>
                                    <div>
                                        {{
                                            data.consult_l.anthropometries
                                                .height
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-4"
                                    *ngIf="
                                        data.consult_l.anthropometries.weight
                                    "
                                >
                                    <div class="title-3 mb-1">Peso</div>
                                    <div>
                                        {{
                                            data.consult_l.anthropometries
                                                .weight
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-4"
                                    *ngIf="data.consult_l.anthropometries.IMC"
                                >
                                    <div class="title-3 mb-1">IMC</div>
                                    <div>
                                        {{ data.consult_l.anthropometries.IMC }}
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="data.consult_l.vitals">
                                <div
                                    class="col-12 my-2"
                                    *ngIf="
                                        data.consult_l.vitals &&
                                        (data.consult_l.vitals.heart_rate ||
                                            data.consult_l.vitals.pulse ||
                                            data.consult_l.vitals
                                                .breathing_rate ||
                                            data.consult_l.vitals.sat02 ||
                                            data.consult_l.vitals.temp ||
                                            data.consult_l.vitals
                                                .sistolic_pressure ||
                                                data.consult_l.vitals
                                                    .diastolic_pressure ||
                                            data.consult_l.vitals
                                                .sistolic_pressure ||
                                            data.consult_l.vitals
                                                .diastolic_pressure ||
                                            data.consult_l.vitals.PAM ||
                                            data.consult_l.vitals
                                                .pressure_pulse ||
                                            data.consult_l.vitals
                                                .glasgow_total ||
                                            data.consult_l.vitals.glasgow_eye ||
                                            data.consult_l.vitals
                                                .glasgow_talk ||
                                            data.consult_l.vitals
                                                .glasgow_move ||
                                            data.consult_l.vitals
                                                .glasgow_total ||
                                            data.consult_l.vitals
                                                .eye_vision_right ||
                                                data.consult_l.vitals
                                                    .eye_vision_left ||
                                            data.consult_l.vitals
                                                .eye_vision_left ||
                                            data.consult_l.vitals
                                                .eye_vision_right)
                                    "
                                >
                                    <div class="title-2">VITALES</div>
                                </div>

                                <div
                                    class="col-md-6"
                                    *ngIf="data.consult_l.vitals.heart_rate"
                                >
                                    <div class="title-3 mb-1">
                                        Frecuencia Cardíaca
                                    </div>
                                    <div>
                                        {{ data.consult_l.vitals.heart_rate }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="data.consult_l.vitals.pulse"
                                >
                                    <div class="title-3 mb-1">Pulso</div>
                                    <div>
                                        {{ data.consult_l.vitals.pulse }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="data.consult_l.vitals.breathing_rate"
                                >
                                    <div class="title-3 mb-1">
                                        Frecuencia Respiratoria
                                    </div>
                                    <div>
                                        {{
                                            data.consult_l.vitals.breathing_rate
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="data.consult_l.vitals.sat02"
                                >
                                    <div class="title-3 mb-1">Sat O2</div>
                                    <div>
                                        {{ data.consult_l.vitals.sat02 }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="data.consult_l.vitals.temp"
                                >
                                    <div class="title-3 mb-1">Temperatura</div>
                                    <div>
                                        {{ data.consult_l.vitals.temp }}
                                    </div>
                                </div>

                                <div
                                    class="col-12"
                                    *ngIf="
                                        data.consult_l.vitals
                                            .sistolic_pressure ||
                                        data.consult_l.vitals.diastolic_pressure
                                    "
                                >
                                    <div class="title-3b mb-1">
                                        Presión Arterial
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="
                                        data.consult_l.vitals.sistolic_pressure
                                    "
                                >
                                    <div class="title-3 mb-1">
                                        Presión Sistólica
                                    </div>
                                    <div>
                                        {{
                                            data.consult_l.vitals
                                                .sistolic_pressure
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="
                                        data.consult_l.vitals.diastolic_pressure
                                    "
                                >
                                    <div class="title-3 mb-1">
                                        Presión Diastólica
                                    </div>
                                    <div>
                                        {{
                                            data.consult_l.vitals
                                                .diastolic_pressure
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="data.consult_l.vitals.PAM"
                                >
                                    <div class="title-3 mb-1">
                                        Presión Arterial Media (PAM)
                                    </div>
                                    <div>
                                        {{ data.consult_l.vitals.PAM }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="data.consult_l.vitals.pressure_pulse"
                                >
                                    <div class="title-3 mb-1">
                                        Presión de Pulso
                                    </div>
                                    <div>
                                        {{
                                            data.consult_l.vitals.pressure_pulse
                                        }}
                                    </div>
                                </div>

                                <div
                                    class="col-12"
                                    *ngIf="data.consult_l.vitals.glasgow_total"
                                >
                                    <div class="title-3b mb-1">Glasgow</div>
                                </div>
                                <div
                                    class="col-md-3"
                                    *ngIf="data.consult_l.vitals.glasgow_eye"
                                >
                                    <div class="title-3 mb-1">
                                        Respuesta Ocular
                                    </div>
                                    <div>
                                        {{ data.consult_l.vitals.glasgow_eye }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-3"
                                    *ngIf="data.consult_l.vitals.glasgow_talk"
                                >
                                    <div class="title-3 mb-1">
                                        Respuesta Verbal
                                    </div>
                                    <div>
                                        {{ data.consult_l.vitals.glasgow_talk }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-3"
                                    *ngIf="data.consult_l.vitals.glasgow_move"
                                >
                                    <div class="title-3 mb-1">
                                        Respuesta Motora
                                    </div>
                                    <div>
                                        {{ data.consult_l.vitals.glasgow_move }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-3"
                                    *ngIf="data.consult_l.vitals.glasgow_total"
                                >
                                    <div class="title-3 mb-1">Total</div>
                                    <div>
                                        {{
                                            data.consult_l.vitals.glasgow_total
                                        }}
                                    </div>
                                </div>

                                <div
                                    class="col-12"
                                    *ngIf="
                                        data.consult_l.vitals
                                            .eye_vision_right ||
                                        data.consult_l.vitals.eye_vision_left
                                    "
                                >
                                    <div class="title-3b mb-1">
                                        Visión Ocular
                                    </div>
                                </div>
                                <div
                                    class="col-md-3"
                                    *ngIf="
                                        data.consult_l.vitals.eye_vision_left
                                    "
                                >
                                    <div class="title-3 mb-1">
                                        Ojo Izquierdo
                                    </div>
                                    <div>
                                        {{
                                            data.consult_l.vitals
                                                .eye_vision_left
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-3"
                                    *ngIf="
                                        data.consult_l.vitals.eye_vision_right
                                    "
                                >
                                    <div class="title-3 mb-1">Ojo Derecho</div>
                                    <div>
                                        {{
                                            data.consult_l.vitals
                                                .eye_vision_right
                                        }}
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container
                                *ngIf="
                                    data.consult_l.exams_physical
                                        .neurological &&
                                    data.consult_l.exams_physical.eyes &&
                                    data.consult_l.exams_physical.ears &&
                                    data.consult_l.exams_physical.nose &&
                                    data.consult_l.exams_physical.mouth &&
                                    data.consult_l.exams_physical.neck &&
                                    data.consult_l.exams_physical.cardiac &&
                                    data.consult_l.exams_physical.pulmonar &&
                                    data.consult_l.exams_physical.abdomen &&
                                    data.consult_l.exams_physical.genitals &&
                                    data.consult_l.exams_physical.extremities &&
                                    data.consult_l.exams_physical.others
                                "
                            >
                                <div class="col-12 my-2">
                                    <div class="title-2">EXAMEN FÍSICO</div>
                                </div>

                                <div
                                    class="col-md-6"
                                    *ngIf="
                                        data.consult_l.exams_physical
                                            .neurological
                                    "
                                >
                                    <div class="title-3 mb-1">Neurológico</div>
                                    <div>
                                        {{
                                            data.consult_l.exams_physical
                                                .neurological
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="data.consult_l.exams_physical.eyes"
                                >
                                    <div class="title-3 mb-1">Ojos</div>
                                    <div>
                                        {{ data.consult_l.exams_physical.eyes }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="data.consult_l.exams_physical.ears"
                                >
                                    <div class="title-3 mb-1">Oidos</div>
                                    <div>
                                        {{ data.consult_l.exams_physical.ears }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="data.consult_l.exams_physical.nose"
                                >
                                    <div class="title-3 mb-1">Nariz</div>
                                    <div>
                                        {{ data.consult_l.exams_physical.nose }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="data.consult_l.exams_physical.mouth"
                                >
                                    <div class="title-3 mb-1">Boca</div>
                                    <div>
                                        {{
                                            data.consult_l.exams_physical.mouth
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="data.consult_l.exams_physical.neck"
                                >
                                    <div class="title-3 mb-1">Cuello</div>
                                    <div>
                                        {{ data.consult_l.exams_physical.neck }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="
                                        data.consult_l.exams_physical.cardiac
                                    "
                                >
                                    <div class="title-3 mb-1">Cardíaco</div>
                                    <div>
                                        {{
                                            data.consult_l.exams_physical
                                                .cardiac
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="
                                        data.consult_l.exams_physical.pulmonar
                                    "
                                >
                                    <div class="title-3 mb-1">Pulmonar</div>
                                    <div>
                                        {{
                                            data.consult_l.exams_physical
                                                .pulmonar
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="
                                        data.consult_l.exams_physical.abdomen
                                    "
                                >
                                    <div class="title-3 mb-1">Abdomen</div>
                                    <div>
                                        {{
                                            data.consult_l.exams_physical
                                                .abdomen
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="
                                        data.consult_l.exams_physical.genitals
                                    "
                                >
                                    <div class="title-3 mb-1">Genitales</div>
                                    <div>
                                        {{
                                            data.consult_l.exams_physical
                                                .genitals
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="
                                        data.consult_l.exams_physical
                                            .extremities
                                    "
                                >
                                    <div class="title-3 mb-1">Extremidades</div>
                                    <div>
                                        {{
                                            data.consult_l.exams_physical
                                                .extremities
                                        }}
                                    </div>
                                </div>
                                <div
                                    class="col-md-6"
                                    *ngIf="data.consult_l.exams_physical.others"
                                >
                                    <div class="title-3 mb-1">
                                        Otros hallazgos
                                    </div>
                                    <div>
                                        {{
                                            data.consult_l.exams_physical.others
                                        }}
                                    </div>
                                </div>
                            </ng-container>

                            <div
                                class="col-12 mt-2"
                                *ngIf="
                                    data.consult_l.diagnosis_list &&
                                    data.consult_l.diagnosis_list.list.length >
                                        0
                                "
                            >
                                <div class="title-2 mb-1">DIAGNÓSTICO</div>

                                <ol>
                                    <li
                                        *ngFor="
                                            let item of data.consult_l
                                                .diagnosis_list.list
                                        "
                                    >
                                        {{ item.name }}
                                    </li>
                                </ol>
                            </div>

                            <ng-container
                                *ngIf="
                                    data.consult_l.treatment_list &&
                                    data.consult_l.treatment_list.list.length >
                                        0
                                "
                            >
                                <div class="col-12 mt-2">
                                    <div class="title-2">TRATAMIENTO</div>
                                </div>

                                <hr class="my-1 col-11 text-center" />
                                <div class="col-md-12">
                                    <div class="h-100">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="table-responsive">
                                                    <table
                                                        cellspacing="0"
                                                        class="table table-hover table-centered table-borderless datatable-local mb-0"
                                                        id="data-table"
                                                        role="grid"
                                                    >
                                                        <thead>
                                                            <tr role="row">
                                                                <th colspan="1">
                                                                    Nombre
                                                                </th>
                                                                <th colspan="1">
                                                                    Cantidad
                                                                </th>
                                                                <th colspan="1">
                                                                    Presentación
                                                                </th>
                                                                <th colspan="1">
                                                                    Concentración
                                                                </th>
                                                                <th colspan="1">
                                                                    Vía
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="
                                                                    let item of data
                                                                        .consult_l
                                                                        .treatment_list
                                                                        .list
                                                                "
                                                                class="pointer edge-shadow-bottom"
                                                                role="row"
                                                            >
                                                                <td
                                                                    class="first"
                                                                >
                                                                    {{
                                                                        item.name
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item.amount
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item.presentation
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item.concentration
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item.way
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container
                                *ngIf="
                                    data.consult_l.prescription_list &&
                                    data.consult_l.prescription_list.list
                                        .length > 0
                                "
                            >
                                <div class="col-12 mt-2">
                                    <div class="title-2">RECETA</div>
                                </div>

                                <hr class="my-1 col-11 text-center" />
                                <div class="col-md-12">
                                    <div class="h-100">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="table-responsive">
                                                    <table
                                                        cellspacing="0"
                                                        class="table table-hover table-centered table-borderless datatable-local mb-0"
                                                        id="data-table"
                                                        role="grid"
                                                    >
                                                        <thead>
                                                            <tr role="row">
                                                                <th colspan="1">
                                                                    Nombre
                                                                </th>
                                                                <th colspan="1">
                                                                    Cantidad
                                                                </th>
                                                                <th colspan="1">
                                                                    Presentación
                                                                </th>
                                                                <th colspan="1">
                                                                    Concentración
                                                                </th>
                                                                <th colspan="1">
                                                                    Vía
                                                                </th>
                                                                <th colspan="1">
                                                                    Frecuencia
                                                                </th>
                                                                <th colspan="1">
                                                                    Duración
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="
                                                                    let item of data
                                                                        .consult_l
                                                                        .prescription_list
                                                                        .list
                                                                "
                                                                class="pointer edge-shadow-bottom"
                                                                role="row"
                                                            >
                                                                <td
                                                                    class="first"
                                                                >
                                                                    {{
                                                                        item.name
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item.amount
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item.presentation
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item.concentration
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item.way
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item.frecuency
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    {{
                                                                        item.days
                                                                    }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div
                                class="col-12 mt-2"
                                *ngIf="
                                    data.consult_l.recommendation
                                        .recomendations ||
                                    data.consult_l.recommendation.hidden_notes
                                "
                            >
                                <div class="title-2 mb-1">RECOMENDACIONES</div>
                                <div
                                    *ngIf="
                                        data.consult_l.recommendation
                                            .recomendations
                                    "
                                >
                                    {{
                                        data.consult_l.recommendation
                                            .recomendations
                                    }}
                                </div>
                                <div
                                    *ngIf="
                                        data.consult_l.recommendation
                                            .hidden_notes && is_author()
                                    "
                                    class="text-muted"
                                >
                                    <strong>*Notas Ocultas:</strong>
                                    {{
                                        data.consult_l.recommendation
                                            .hidden_notes
                                    }}
                                </div>
                            </div>

                            <ng-container
                                *ngIf="
                                    data.consult_l.exams_requests_list &&
                                    data.consult_l.exams_requests_list.list
                                        .length > 0
                                "
                            >
                                <div class="col-12 mt-2">
                                    <div class="title-2">
                                        SOLICITUD DE EXÁMENES
                                    </div>
                                </div>

                                <hr class="my-1 col-11 text-center" />
                                <div class="col-md-12">
                                    <div class="h-100">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="table-responsive">
                                                    <table
                                                        cellspacing="0"
                                                        class="table table-hover table-centered table-borderless datatable-local mb-0"
                                                        id="data-table"
                                                        role="grid"
                                                    >
                                                        <thead>
                                                            <tr role="row">
                                                                <th colspan="1">
                                                                    Tipo
                                                                </th>
                                                                <th colspan="1">
                                                                    Nombre
                                                                </th>
                                                                <th colspan="1">
                                                                    Nombre
                                                                    Secundario
                                                                </th>
                                                                <th colspan="1">
                                                                    Lugar
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container
                                                                *ngFor="
                                                                    let item of data
                                                                        .consult_l
                                                                        .exams_requests_list
                                                                        .list
                                                                "
                                                            >
                                                                <tr
                                                                    class="pointer edge-shadow-bottom"
                                                                    role="row"
                                                                >
                                                                    <td
                                                                        class="first"
                                                                    >
                                                                        {{
                                                                            item.exams_type
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            item.exams_name
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            item.exams_name_2
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            item.place
                                                                        }}
                                                                    </td>
                                                                </tr>

                                                                <tr
                                                                    role="row"
                                                                    *ngIf="
                                                                        item.observations
                                                                    "
                                                                >
                                                                    <td
                                                                        class="content"
                                                                        colspan="4"
                                                                    >
                                                                        {{
                                                                            item.observations
                                                                        }}
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container
                                *ngIf="
                                    data.consult_l.exams_results_list &&
                                    data.consult_l.exams_results_list.list
                                        .length > 0
                                "
                            >
                                <div class="col-12 mt-2">
                                    <div class="title-2">
                                        RESULTADOS DE EXÁMENES
                                    </div>
                                </div>

                                <hr class="my-1 col-11 text-center" />
                                <div class="col-md-12">
                                    <div class="h-100">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="table-responsive">
                                                    <table
                                                        cellspacing="0"
                                                        class="table table-hover table-centered table-borderless datatable-local mb-0"
                                                        id="data-table"
                                                        role="grid"
                                                    >
                                                        <thead>
                                                            <tr role="row">
                                                                <th colspan="1">
                                                                    Tipo
                                                                </th>
                                                                <th colspan="1">
                                                                    Nombre
                                                                </th>
                                                                <th colspan="1">
                                                                    Nombre
                                                                    Secundario
                                                                </th>
                                                                <th colspan="1">
                                                                    Fecha
                                                                </th>
                                                                <th colspan="1">
                                                                    Lugar
                                                                </th>
                                                                <th colspan="1">
                                                                    Archivo
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container
                                                                *ngFor="
                                                                    let item of data
                                                                        .consult_l
                                                                        .exams_results_list
                                                                        .list
                                                                "
                                                            >
                                                                <tr
                                                                    class="pointer edge-shadow-bottom"
                                                                    role="row"
                                                                >
                                                                    <td
                                                                        class="first"
                                                                    >
                                                                        {{
                                                                            item.exams_type
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            item.exams_name
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            item.exams_name_2
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            item.date
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            item.place
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            *ngIf="
                                                                                item.file
                                                                            "
                                                                            [href]="
                                                                                get_file_endpoint(
                                                                                    'exams_results',
                                                                                    item.file
                                                                                )
                                                                            "
                                                                            target="_blank"
                                                                            >Abrir</a
                                                                        >
                                                                    </td>
                                                                </tr>

                                                                <tr
                                                                    role="row"
                                                                    *ngIf="
                                                                        item.observations
                                                                    "
                                                                >
                                                                    <td
                                                                        class="content"
                                                                        colspan="6"
                                                                    >
                                                                        {{
                                                                            item.observations
                                                                        }}
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container
                                *ngIf="
                                    data.consult_l.procedures_requests_list &&
                                    data.consult_l.procedures_requests_list.list
                                        .length > 0
                                "
                            >
                                <div class="col-12 mt-2">
                                    <div class="title-2">
                                        SOLICITUD DE PROCEDIMIENTOS
                                    </div>
                                </div>

                                <hr class="my-1 col-11 text-center" />
                                <div class="col-md-12">
                                    <div class="h-100">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="table-responsive">
                                                    <table
                                                        cellspacing="0"
                                                        class="table table-hover table-centered table-borderless datatable-local mb-0"
                                                        id="data-table"
                                                        role="grid"
                                                    >
                                                        <thead>
                                                            <tr role="row">
                                                                <th colspan="1">
                                                                    Procedimiento
                                                                </th>
                                                                <th colspan="1">
                                                                    Lugar
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container
                                                                *ngFor="
                                                                    let item of data
                                                                        .consult_l
                                                                        .procedures_requests_list
                                                                        .list
                                                                "
                                                            >
                                                                <tr
                                                                    class="pointer edge-shadow-bottom"
                                                                    role="row"
                                                                >
                                                                    <td
                                                                        class="first"
                                                                    >
                                                                        {{
                                                                            item.procedures_type
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            item.place
                                                                        }}
                                                                    </td>
                                                                </tr>

                                                                <tr
                                                                    role="row"
                                                                    *ngIf="
                                                                        item.observations
                                                                    "
                                                                >
                                                                    <td
                                                                        class="content"
                                                                        colspan="2"
                                                                    >
                                                                        {{
                                                                            item.observations
                                                                        }}
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container
                                *ngIf="
                                    data.consult_l.procedures_results_list &&
                                    data.consult_l.procedures_results_list.list
                                        .length > 0
                                "
                            >
                                <div class="col-12 mt-2">
                                    <div class="title-2">
                                        RESULTADOS DE PROCEDIMIENTOS
                                    </div>
                                </div>

                                <hr class="my-1 col-11 text-center" />
                                <div class="col-md-12">
                                    <div class="h-100">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="table-responsive">
                                                    <table
                                                        cellspacing="0"
                                                        class="table table-hover table-centered table-borderless datatable-local mb-0"
                                                        id="data-table"
                                                        role="grid"
                                                    >
                                                        <thead>
                                                            <tr role="row">
                                                                <th colspan="1">
                                                                    Procedimiento
                                                                </th>
                                                                <th colspan="1">
                                                                    Fecha
                                                                </th>
                                                                <th colspan="1">
                                                                    Médico
                                                                </th>
                                                                <th colspan="1">
                                                                    Lugar
                                                                </th>
                                                                <th colspan="1">
                                                                    Archivo
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container
                                                                *ngFor="
                                                                    let item of data
                                                                        .consult_l
                                                                        .procedures_results_list
                                                                        .list
                                                                "
                                                            >
                                                                <tr
                                                                    class="pointer edge-shadow-bottom"
                                                                    role="row"
                                                                >
                                                                    <td
                                                                        class="first"
                                                                    >
                                                                        {{
                                                                            item.procedures_type
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            item.date
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            item.doctor_name
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            item.place
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            *ngIf="
                                                                                item.file
                                                                            "
                                                                            [href]="
                                                                                get_file_endpoint(
                                                                                    'procedures_results',
                                                                                    item.file
                                                                                )
                                                                            "
                                                                            target="_blank"
                                                                            >Abrir</a
                                                                        >
                                                                    </td>
                                                                </tr>

                                                                <tr
                                                                    role="row"
                                                                    *ngIf="
                                                                        item.observations
                                                                    "
                                                                >
                                                                    <td
                                                                        class="content"
                                                                        colspan="5"
                                                                    >
                                                                        {{
                                                                            item.observations
                                                                        }}
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container
                                *ngIf="
                                    data.consult_l.references_list &&
                                    data.consult_l.references_list.list.length >
                                        0
                                "
                            >
                                <div class="col-12 mt-2">
                                    <div class="title-2">REFERENCIAS</div>
                                </div>

                                <hr class="my-1 col-11 text-center" />
                                <div class="col-md-12">
                                    <div class="h-100">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="table-responsive">
                                                    <table
                                                        cellspacing="0"
                                                        class="table table-hover table-centered table-borderless datatable-local mb-0"
                                                        id="data-table"
                                                        role="grid"
                                                    >
                                                        <thead>
                                                            <tr role="row">
                                                                <th colspan="1">
                                                                    Lugar
                                                                </th>
                                                                <th colspan="1">
                                                                    Médico
                                                                </th>
                                                                <th colspan="1">
                                                                    Especialidad
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <ng-container
                                                                *ngFor="
                                                                    let item of data
                                                                        .consult_l
                                                                        .references_list
                                                                        .list
                                                                "
                                                            >
                                                                <tr
                                                                    class="pointer edge-shadow-bottom"
                                                                    role="row"
                                                                >
                                                                    <td
                                                                        class="first"
                                                                    >
                                                                        {{
                                                                            item.place
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            item.doctor
                                                                        }}
                                                                    </td>
                                                                    <td>
                                                                        {{
                                                                            item.doctors_specialties
                                                                        }}
                                                                    </td>
                                                                </tr>

                                                                <tr
                                                                    role="row"
                                                                    *ngIf="
                                                                        item.cause
                                                                    "
                                                                >
                                                                    <td
                                                                        class="content"
                                                                        colspan="3"
                                                                    >
                                                                        {{
                                                                            item.cause
                                                                        }}
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="divider mx-4"></div>

                <div class="d-flex flex-column patient">
                    <div class="d-flex mt-4 mx-auto">
                        <avatar
                            [name]="data?.patient_name"
                            [image]="data?.patient_image"
                            [configs]="avatar_configs"
                        ></avatar>
                    </div>

                    <div class="name mt-2 mx-auto">
                        {{ data.patient_name | uppercase }}
                    </div>
                    <div class="specialty mx-auto">Paciente</div>
                </div>
            </div>
        </div>
    </div>
</div>
