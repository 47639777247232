import { Component } from '@angular/core';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-background_show_pathologies',
  templateUrl: './show_pathologies.component.html',
  styleUrls: ['./show_pathologies.component.scss']
})
export class ShowBackgroundPathologiesComponent {

  constructor(private pageService: PageService) {
  }

  close() {
    this.pageService.closeModal("");
  }

}
