/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./consult-minimized-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./consult-minimized-header.component";
import * as i4 from "../../../../services/app.service";
import * as i5 from "../general/consult.service";
var styles_ConsultMinimizedHeaderComponent = [i0.styles];
var RenderType_ConsultMinimizedHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConsultMinimizedHeaderComponent, data: {} });
export { RenderType_ConsultMinimizedHeaderComponent as RenderType_ConsultMinimizedHeaderComponent };
function View_ConsultMinimizedHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "consult-minimized-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.maximize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Consulta "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "ml-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["class", "icon-secondary icon-secondary-sm my-auto"], ["title", "Expandir"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.maximize() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa-duotone fa-window-maximize"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["class", "icon-secondary icon-secondary-sm my-auto danger"], ["title", "Cerrar"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fa-duotone fa-times"]], null, null, null, null, null))], null, null); }
export function View_ConsultMinimizedHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConsultMinimizedHeaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.has_consult(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ConsultMinimizedHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-consult-minimized-header", [], null, null, null, View_ConsultMinimizedHeaderComponent_0, RenderType_ConsultMinimizedHeaderComponent)), i1.ɵdid(1, 245760, null, 0, i3.ConsultMinimizedHeaderComponent, [i4.AppService, i5.ConsultGeneralService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConsultMinimizedHeaderComponentNgFactory = i1.ɵccf("app-consult-minimized-header", i3.ConsultMinimizedHeaderComponent, View_ConsultMinimizedHeaderComponent_Host_0, {}, {}, []);
export { ConsultMinimizedHeaderComponentNgFactory as ConsultMinimizedHeaderComponentNgFactory };
