<div class="modal-header ribbon-box d-flex align-items-center">
    <h4 class="text-center w-100 modal-title text-dark"><i class="fa-duotone fa-plus mr-1 text-primary"></i> Crear Cita </h4>
    <button type="button" class="close text-outline-danger" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<!-- 
<app-form_render #form_ref2 [config]="form_config2" [data]="patients"></app-form_render>

<div class="d-flex w-100">
    <div class="px-2 py-1 bg-light d-flex mr-1" style="white-space: nowrap; border-radius: 15px;" *ngFor="let item of patients; let i = index;">
        <div class="my-auto">
            {{item.firstname}}
        </div>
        <a class="pointer ml-2 my-auto" ><i class="fa-duotone fa-times text-dark"></i></a>
    </div>
</div> -->

<div class="modal-body p-2">
    <div class="text-left">
        <app-form_render #form_ref [config]="form_config" [data]="data"></app-form_render>
    </div>
</div>