import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs/internal/Subscription';
import { AppService } from 'src/app/services/app.service';
import { ExpedientMetadata, ExpedientService } from 'src/app/services/expedient.service';
import { PageService, PageState } from 'src/app/services/page.service';
import { SweelService } from 'src/app/shared/services/sweel.service';
import { DatatableComponent, datatable_type } from 'src/app/shared/ui/datatable/datatable.component';
import { SearchButtonComponent } from 'src/app/shared/ui/search_button/search_button.component';
import { InstitutionsService, InstitutionsState, ListState } from './institutions.service';

@Component({
  selector: 'app-expedient-institutions',
  templateUrl: './institutions.component.html',
  styleUrls: ['./institutions.component.scss']
})
export class InstitutionsComponent implements OnInit, OnDestroy {
  @Output('change_view') _change_view_event: EventEmitter<any> = new EventEmitter<any>();

  private page_subscription: Subscription;
  public page_state: PageState;

  private list_subscription: Subscription;
  public list_state: ListState;

  private expedient_subscription: Subscription;
  public expedient_state: ExpedientMetadata;

  @ViewChild('search_button_ref', { static: false }) search_button_ref: SearchButtonComponent;
  @ViewChild('datatable_ref', { static: false }) datatable_ref: DatatableComponent;
  public datatable: datatable_type;

  @ViewChild('createModal', { static: false }) createModal: NgbModalRef;
  @ViewChild('updateModal', { static: false }) updateModal: NgbModalRef;
  @ViewChild('showModal', { static: false }) showModal: NgbModalRef;
  public selected_item: InstitutionsState;

  constructor(private endpoint: AppService, private expedientService: ExpedientService, private pageService: PageService, private moduleService: InstitutionsService, public sweel: SweelService, private toastr: ToastrService) {
    this.moduleService.init_list();
    this.datatable_config();
  }

  ngOnInit() {
    this.expedient_subscription = this.expedientService.current.subscribe(
      state => {
        this.expedient_state = state;
        this.moduleService.reload_list(this.datatable.filters);
      }
    );
    this.list_subscription = this.moduleService.obv_list.subscribe(
      state => this.list_state = state
    );
    this.page_subscription = this.pageService.current.subscribe(
      state => this.page_state = state
    );
  }

  ngOnDestroy() {
    this.expedient_subscription.unsubscribe();
    this.list_subscription.unsubscribe();
    this.page_subscription.unsubscribe();
  }

  datatable_config() {
    this.datatable = {
      configs: {
        title: "Instituciones",
        //icon: "fa-duotone fa-address-book",
        paginate: false,
        search: false
      },
      filters: {
        limit: 20,
        skip: 0
      },
      headers: [
        {
          label: "Nombre",
          name: "institution_name",
          order_by: "institution_name"
        },
        {
          label: "Cargo",
          name: "institutions_role_name",
          order_by: "institutions_role_name"
        },
        {
          label: "Área",
          name: "institutions_area_name",
          order_by: "institutions_area_name"
        },
        {
          label: "Grado",
          name: "institutions_grade_name",
          order_by: "institutions_grade_name"
        },
        {
          label: "Sección",
          name: "institutions_grades_section_name",
          order_by: "institutions_grades_section_name"
        },
      ],
      events:
        [
          {
            name: "Detalles",
            icon: "fa-duotone fa-file-circle-info text-info"
          },
          {
            name: "Modificar",
            icon: "fa-duotone fa-edit text-warning"
          },
          {
            name: "Eliminar",
            icon: "fa-duotone fa-trash-alt text-danger",
          },
        ]
    };
  }

  on_update() {
    if (this.datatable_ref) this.datatable_ref.update();
  }

  update(data) {
    if (this.search_button_ref) data.search_word = this.search_button_ref.data;
    this.moduleService.reload_list(data);
  }

  events(data) {
    this.selected_item = data.item as InstitutionsState;
    if (data.name == "Detalles" || data.name == "row_click") {
      this.expedientService.change_method("show");
      this.moduleService.init(this.selected_item);
      this.pageService.openModal(this.showModal, "lg");
    }
    if (data.name == "Eliminar") {
      this.moduleService.init(this.selected_item);
      this.sweel.confirm("Eliminar Institución", "Por favor confirme que desea eliminar la institución.", "warning").then(
        res => {
          if (res) {
            this.moduleService.delete();
          }
        }
      )
    }
    if (data.name == "Modificar") {
      this.expedientService.change_method("update");
      this.moduleService.init(this.selected_item);
      this.pageService.openModal(this.updateModal, "lg");
    }
  }

  create() {
    this.pageService.openModal(this.createModal, "lg");
  }
}