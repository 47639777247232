<div class="h-100">
  <div class="row">

    <div class="col-12 mt-3" *ngIf="loading || error">
      <div class="card-box text-center radius" style="border: none !important;">
        <div class="d-flex justify-content-center align-iteml-center">
          <div *ngIf='loading || error; then requestholderbig'></div>
        </div>
      </div>
    </div>

    <div class="col-12" *ngIf="!loading && !error && data && data.list && data.list.length > 0">
      <div
           class="bold-list"
           [class.open]="configs.open">

        <!-- Headers -->
        <div class="blhead">
          <div class="blr">
            <div
                 class="blh"
                 *ngFor="let header of headers; let i = index;"
                 (click)='orderBy(header.order_by)'
                 [class.selected]='filters.order_by == header.order_by'
                 [ngStyle]="{'flex': '0 0 ' + calculate_cols() + '%', 'max-width': calculate_cols() + '%'}">
              {{header.label}}
              <i
                 *ngIf='filters.order_by == header.order_by'
                 [class.fa-chevron-up]="filters.order_asc"
                 [class.fa-chevron-down]="!filters.order_asc"
                 class="fas">
              </i>
              <i
                 *ngIf='filters.order_by != header.order_by'
                 class="fas fa-chevron-down">
              </i>
            </div>

            <div class="blh open" *ngIf="configs.open"></div>
          </div>
        </div>

        <!-- Body -->
        <div class="blbody">
          <ng-container *ngFor='let item of data.list; let i = index'>
            <div class="blc" [class.selected]="configs.selected ? configs.selected(item) : false">
              <div class="big_icon" [title]="configs.big_icon(item).title" *ngIf="configs.big_icon">
                <i class="{{configs.big_icon(item).icon}}"></i>
              </div>
              <div class="w-100">
                <div class="blr">
                  <div class="blrcontent" (click)="event('row_click', item)">
                    <div
                         [class]="'bld ' + (header.class ? header.class : '')"
                         *ngFor="let header of headers; let i_h = index;"
                         [ngStyle]="{'flex': '0 0 ' + calculate_cols() + '%', 'max-width': calculate_cols() + '%'}">
                      <ng-container *ngIf="header.type != 'date' && header.type != 'disabled' && header.type != 'icon'">
                        {{item[header.name]}}
                      </ng-container>
                      <ng-container *ngIf="header.type == 'disabled'">
                        {{item[header.name] ? 'Deshabilitado' : 'Habilitado'}}
                      </ng-container>
                      <ng-container *ngIf="header.type == 'date'">
                        {{item[header.name] | shortDate}}
                      </ng-container>
                      <ng-container *ngIf="header.type == 'icon'">
                        <i [class]="item[header.name]"></i>
                      </ng-container>
                    </div>


                    <div class="bld content">
                      <div *ngFor="let content of contents;">{{content(item)}}</div>
                    </div>
                  </div>

                  <!-- EVENTS -->
                  <div class="bld open" *ngIf="events && events.length > 0">
                    <ng-container *ngFor="let e of events; let i_e = index">
                      <div *ngIf="!(e.show ? e.show(item) : true)"></div>
                      <ng-container *ngIf="e.show ? e.show(item) : true" [class.last]="i_e == events.length - 1 && i == data.list.length - 1">
                        <a *ngIf="!e.disabled || !(e.disabled && e.disabled(item))" (click)="event(e.name, item)" [title]="e.name" class="ml-2">
                          <i class="{{ e.icon }}"></i>
                        </a>
                        <a *ngIf="e.disabled && e.disabled(item)" [title]="e.name" class="ml-2"> <i class="fa-duotone fa-minus text-muted"></i> </a>
                      </ng-container>
                    </ng-container>
                  </div>
                  <!-- / EVENTS -->

                  <!-- <div class="bld open" *ngIf="events && events.length > 0" ngbDropdown>
                    <button class="btn btn-link caret-none dropdown-toggle px-1 mr-2" type="button" id="moredd1" ngbDropdownToggle aria-expanded="false">
                      <i class="fa-duotone fa-ellipsis-v fs-2"></i>
                    </button>

                    <ul class="dropdown-menu" aria-labelledby="moredd1" ngbDropdownMenu>
                      <ng-container *ngFor="let e of events; let i_e = index;">
                        <ng-container *ngIf="e.show ? e.show(item) : true">
                          <li>
                            <a ngbDropdownItem *ngIf="!e.disabled || !(e.disabled && e.disabled(item))" (click)="event(e.name, item)" [title]="e.name">
                              <i class="{{e.icon}} mr-1"></i>
                              {{e.name}}
                            </a>
                            <a *ngIf="e.disabled && e.disabled(item)" [title]="e.name" class="dropdown-item mx-2"> <i class="fa-duotone fa-dash-lg text-muted"></i> </a>
                          </li>
                        </ng-container>
                      </ng-container>
                    </ul>
                  </div> -->
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="col-12 mt-3" *ngIf="!loading && !error && (data.list && data.list.length <= 0) || !data.list">
      <div class="text-center radius">
        <div class="d-flex justify-content-center align-iteml-center">
          <app-custom-holder
                             [icon]='"fa-duotone fa-light-emergency-on fa-2x text-warning"'
                             [icon_show]='true'
                             [title]='"h5"'
                             [title_text]='"No hay " + configs.title'
                             [title_show]='true'
                             [inline]="true">
          </app-custom-holder>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #requestholdersmall>
  <app-spinner-small [loading]='loading' [class]='"h1"'></app-spinner-small>
  <app-error-small [error]='error' [class]='"h1"'></app-error-small>
</ng-template>

<ng-template #requestholderbig>
  <app-spinner-big [loading]='loading' [title]='"Cargando..."' [inline]="true"></app-spinner-big>
  <app-error-big [error]='error' [title]='error_desc' [inline]="true"></app-error-big>
</ng-template>