import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-bold_list',
  templateUrl: './bold_list.component.html',
  styleUrls: ['./bold_list.component.scss']
})
export class BoldListComponent implements OnInit {

  @Output() update_bold_list: EventEmitter<any> = new EventEmitter<any>();
  @Output() events_bold_list: EventEmitter<any> = new EventEmitter<any>();

  @Input() set in_headers(headers: headers_type[]) {
    this.headers = headers;
  };

  @Input() set in_events(events: events_type[]) {
    this.events = events;//.filter(value => value.show ? value.show(value) : true);
  };

  @Input() set in_contents(contents: contents_type[]) {
    this.contents = contents;
  };

  @Input() set in_data(data: data_type) {
    this.data = data;
    if (data.skip) this.filters.skip = data.skip;
    this.calculate_pagination();
    this.loading = false;
  };

  @Input() set in_filters(filters: filters_type) {
    this.filters = filters;
  };

  @Input() set in_configs(configs: configs_type) {
    this.configs = configs;
  };

  public events: events_type[];
  public contents: contents_type[];
  public headers: headers_type[];
  public data: data_type;
  public filters: filters_type;
  public configs: configs_type;
  public loading: boolean;
  public error: boolean;
  public error_desc: string;

  public possible_indexes = new Array(7).fill(-1);
  public divider: number;

  constructor() {
    this.contents = [];
    this.headers = [];
    this.data = {
      skip: 0,
      count: 0,
      list: []
    };
    this.filters = {
      search_word: "",
      skip: 0,
      limit: 10
    };
    this.events = [];
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.update();
  }

  update() {
    this.loading = true;
    this.update_bold_list.emit(this.filters);
  }

  orderBy(label: string) {
    this.filters.order_asc = this.filters.order_by == label ? !this.filters.order_asc : true;
    this.filters.order_by = label;
    this.update();
  }

  goLast() {
    this.filters.last = true;
    this.update();
  }

  goFirst() {
    this.filters.last = false;
    this.filters.skip = 0;
    this.update();
  }

  go(step: number) {
    this.filters.last = false;
    this.filters.skip += step;
    this.update();
  }

  filter() {
    this.filters.skip = 0;
    this.update();
  }

  cleanFilters() {
    this.filters.search_word = null;
    this.update();
  }

  event(name: string, item: any) {
    if (this.configs.open) this.events_bold_list.emit({ name: name, item: item });
  }

  calculate_pagination() {
    this.divider = Math.floor(this.data.count / this.filters.limit);

    this.filters.skip = parseInt(this.data.skip + '');

    this.possible_indexes = [
      this.filters.skip - 1,
      this.filters.skip - 2,
      this.filters.skip - 3,
      this.filters.skip,
      this.filters.skip + 1,
      this.filters.skip + 2,
      this.filters.skip + 3,
    ].map(value => {
      if (value < 0) value += 7;

      if (value > this.divider || (this.data.count % this.filters.limit == 0 && value >= this.divider)) value -= 7

      return value;
    }).sort((a, b) => a - b);
  }

  calculate_cols() {
    return 100 / this.headers.length;
  }

}

export type headers_type = {
  name: string,
  label: string,
  order_by: string,
  sort?: boolean,
  type?: 'date' | 'disabled' | 'icon',
  class?: "secondary"
};

export type configs_type = {
  big_icon?(item: any): { icon: string, title: string },
  selected?(...data: any): boolean,
  icon?: string,
  title?: string,
  search?: boolean,
  paginate?: boolean
  options?: boolean
  open?: boolean
};

export type filters_type = {
  search_word?: string,
  skip?: number,
  limit?: number,
  last?: boolean,
  order_by?: string,
  order_asc?: boolean
};

export type data_type = {
  skip?: number,
  count: number,
  list: any[]
};

export type events_type = {
  icon?: string,
  name?: string,
  disabled?: Function,
  show?: Function
};

export type contents_type = Function;

export type bold_list_type = {
  headers: headers_type[],
  contents: contents_type[],
  events?: events_type[],
  filters: filters_type,
  configs: configs_type,
};
