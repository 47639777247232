import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppService } from './app.service';
import { PageService } from './page.service';

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  private patient_id: string;
  public patient_data: patient_state;
  private patient_data_observable = new BehaviorSubject(null);
  public patient_data_subscription = this.patient_data_observable.asObservable();

  constructor(private endpoint: AppService, private pageService: PageService) {
  }

  init(callback = (data) => { }) {
    var stored = this.endpoint.get_patient_id();

    if (stored) {
      this.change_patient_id(stored);
    } else {
      this.clean_patient();
    }
    this.load_patient_data(callback);
  }

  clean_patient() {
    this.patient_data = {
      general: {
        id: "",
        id_user: "",
        firstname: "",
        lastname: "",
        id_gender: "",
        birthdate: "",
        age: "",
        age_month: "",
        age_day: "",
        birthplace: "",
        id_department: "",
        id_town: "",
        address: "",
        phone: "",
        id_education: "",
        identification: "",
        image: "",
        email: ""
      },
      background: {
        allergy_total: 0,
        allergy: [],
        pathology_total: 0,
        pathology: []
      },
      contacts: {
        doctor_total: 0,
        doctor: [],
        emergency_total: 0,
        emergency: []
      },
      loaded: false
    };
    this.patient_data_observable.next(this.patient_data);
  }

  reset_patient() {
    this.patient_id = null;
    this.patient_data = null;
    this.endpoint.set_patient_id(this.patient_id);
    this.patient_data_observable.next(this.patient_data);
  }

  reset_superficial_patient() {
    this.patient_id = null;
    this.patient_data = null;
    this.patient_data_observable.next(this.patient_data);
  }

  change_patient_id(id: string) {
    this.reset_patient();

    this.patient_id = id;
    this.endpoint.set_patient_id(this.patient_id);
    this.load_patient_data();
  }

  change_superficial_patient_id(id: string) {
    this.reset_superficial_patient();

    this.patient_id = id;
    this.load_patient_data();
  }

  load_patient_data(callback = (data) => { }) {
    var load = {
      id: this.patient_id
    };

    var response;
    this.endpoint.get_patient_detail(load).subscribe(
      data => response = data,
      err => err,
      () => {
        response.loaded = true;
        this.patient_data = response;
        callback(response);
        this.patient_data_observable.next(this.patient_data);
        this.pageService.reload();
      }
    );
  }

  get_patient_id() {
    return this.patient_id;
  }

  get_patient_data() {
    return this.patient_data;
  }

  update_patient_detail() {
    this.endpoint.update_patient_detail(this.patient_data).subscribe(
      data => this.load_patient_data(),
      err => err
    );
  }

  set_growth_curve(growth_curve) {
    if (this.patient_data) {
      this.patient_data.growth_curve = growth_curve;
      this.patient_data_observable.next(this.patient_data);
    }
  }
}

export type patient_state = {
  general: {
    id: string,
    id_user: string,
    firstname: string,
    lastname: string,
    id_gender: string,
    birthdate: string,
    age: string,
    age_month: string,
    age_day: string,
    birthplace: string,
    id_department: string,
    id_town: string,
    address: string,
    phone: string,
    id_education: string,
    identification: string,
    image: string,
    email: string,
  },
  background: {
    allergy_total: number | string,
    allergy: {
      id: string,
      allergen: string,
      id_allergies_reactions_level: string,
      allergies_reactions_level_name: string,
      id_allergies_reactions_type: string,
      allergies_reactions_type_name: string,
    }[],
    pathology_total: number | string,
    pathology: {
      id: string,
      id_pathology: string,
      pathology_name: string,
      treatment: string,
    }[],
  },
  contacts: {
    doctor_total: number | string,
    doctor: {
      firstname: string,
      lastname: string,
      doctors_specialty_name: string,
      phone: string,
      email: string,
    }[],
    emergency_total: number | string,
    emergency: {
      firstname: string,
      lastname: string,
      relationship_name: string,
      phone: string,
      email: string,
    }[]
  },
  growth_curve?: any;
  loaded: boolean
};