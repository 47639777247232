import { CatalogsService } from "src/app/services/catalogs.service";
import { CustomClass } from "./custom-class";

export interface ProceduresRequestsInterface {
    id: any;
    id_patient: any;
    id_consult: any;
    id_procedures_type: any;
    observations: string;
    place: string;
};

interface ToStringInterface {
    procedures_type: string;
    observations: string;
    place: string;
};

export class ProceduresRequestsClass extends CustomClass implements ProceduresRequestsInterface {
    public id: any;
    public id_patient: any;
    public id_consult: any;
    public id_procedures_type: any;
    public observations: string;
    public place: string;

    constructor(private catalogsService: CatalogsService, data?: ProceduresRequestsInterface) {
        super();
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: ProceduresRequestsInterface): void {
        super.overwrite(data);
        this.id = data.id;
        this.id_patient = data.id_patient;
        this.id_consult = data.id_consult;
        this.id_procedures_type = data.id_procedures_type;
        this.observations = data.observations;
        this.place = data.place;
    }

    reset(): void {
        super.reset();
        this.id = null;
        this.id_patient = null;
        this.id_consult = null;
        this.id_procedures_type = null;
        this.observations = "";
        this.place = "";
    }

    format(): ProceduresRequestsInterface {
        return this as ProceduresRequestsInterface;
    }

    valid(): boolean {
        return true;
    }

    to_string(): ToStringInterface {
        let procedures_type = this.catalogsService.catalogs.procedures_types.find(v => v.id == this.id_procedures_type);

        let data: ToStringInterface = {
            procedures_type: procedures_type ? procedures_type.name : "",
            observations: this.observations,
            place: this.place
        };
        return data;
    }

    raw(): ProceduresRequestsInterface {
        return {
            id: this.id,
            id_patient: this.id_patient,
            id_consult: this.id_consult,
            id_procedures_type: this.id_procedures_type,
            observations: this.observations,
            place: this.place,
        };
    }
}
