<div class="unimed-left-sidebar bg-white px-3 py-2">
    <div class="container-fluid px-0">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-between mb-2">
                    <div class="title">
                        mis pacientes
                    </div>

                    <div class="my-auto">
                        <div class="d-flex text-right">
                            <app-search_button (change)="on_update()" (submit)="on_update()" #search_button_ref></app-search_button>
                            <button class="ml-2 btn radius btn-primary no-wrap" style="font-weight: 500;" routerLink="/nuevo_paciente/"><i class="fa-duotone fa-plus"></i> PACIENTE </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <app-datatable
                    #datatable_ref
                    (update_datatable)="update($event)"
                    (events_datatable)="events($event)"
                    [in_headers]="datatable.headers"
                    [in_events]="datatable.events"
                    [in_data]="data"
                    [in_filters]="datatable.filters"
                    [in_configs]="datatable.configs">
                </app-datatable>
            </div>
        </div>
    </div>
</div>