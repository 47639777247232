import { Component, Input } from "@angular/core";

@Component({
    selector: "app-dynamic_tabs",
    templateUrl: "./dynamic_tabs.component.html",
    styleUrls: ["./dynamic_tabs.component.scss"],
})
export class DynamicTabsComponent {
    @Input("config") config: dynamic_url_tabs_type | dynamic_click_tabs_type;

    constructor() {}
}

export type dynamic_url_tabs_type = {
    no_border?: boolean;
    list: tab_url_type[];
};

export type dynamic_click_tabs_type = {
    no_border?: boolean;
    selected: number;
    list: tab_click_type[];
};

export type tab_url_type = {
    name: string;
    icon?: string;
    class?: string;
    url?: string | string[];
    exact?: boolean;
    disabled?(): Boolean;
    show?(): Boolean;
    font_size?: string;
    active_stroke?: string;
};

export type tab_click_type = {
    name: string;
    icon?: string;
    class?: string;
    disabled?(): Boolean;
    show?(): Boolean;
    click?(): void;
    font_size?: string;
    active_stroke?: string;
};
