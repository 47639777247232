import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { AccountService } from "src/app/services/account.service";
import { AppService } from "src/app/services/app.service";
import { CatalogsService } from "src/app/services/catalogs.service";
import { PageService } from "src/app/services/page.service";
import { PipesService } from "src/app/services/pipes.service";
import { ConsultClass } from "../../../../../classes/consult-class";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-preview_prescription",
  templateUrl: "./preview_prescription.component.html",
  styleUrls: [
    "./preview_prescription.component.scss",
    "../../../../../shared/ui/datatable/datatable.component.scss",
  ],
})
export class PreviewPrescriptionComponent implements OnInit, OnDestroy {

  public data: any & {
    consult: ConsultClass;
  } = {
      id: 1,
      consult: new ConsultClass(this.catalogsService, this.pipesService)
    };

  public carrousel: any;

  public avatar_configs = { size: "67.8106pt" };

  constructor(
    private pageService: PageService,
    public endpoint: AppService,
    private accountService: AccountService,
    private activeRoute: ActivatedRoute,
    private catalogsService: CatalogsService,
    private pipesService: PipesService
  ) {
    this.data.id = activeRoute.snapshot.paramMap.get("id");
    this.carrousel = {
      1: true,
      2: true,
      3: true,
      4: true,
    };
  }


  ngOnInit() {
    this.pageService.set_sidebar_3_state({
      expand: true,
      scroll: false,
      show: true,
    });
    this.get_data();
  }

  ngOnDestroy() {
    this.pageService.set_sidebar_3_state({
      expand: true,
      scroll: true,
      show: true,
    });
  }

  get_data() {
    this.endpoint
      .expedient_consult_general_get_consult({ id: this.data.id })
      .subscribe(
        (data) => {
          this.data.consult = new ConsultClass(
            this.catalogsService,
            this.pipesService,
            data
          );
          console.log(this.data.consult)
        },
        (error) => this.close()
      );
  }

  close() {
  }

  get_catalogs(name, value = "id", text = "name") {
    return {
      list: () => this.catalogsService.catalogs[name],
      value: value,
      text: text
    };
  }

  get_specialty(id: any) {
    let specialty = this.catalogsService.catalogs.doctors_specialties.find(
      (v) => v.id == id
    );
    return specialty ? specialty.name : "";
  }

  get_file_endpoint(path: string, name: string): string {
    return this.endpoint.get_file_endpoint(path, name);
  }

  is_author(): boolean {
    return (
      this.data.consult.id_doctor == this.accountService.get_current_state().id
    );
  }

  has_prescriptions_notes(): boolean {
    let count = 0;
    this.data.consult.prescription_list.list.forEach(v => {
      if (!!v.instructions) count++;
    });
    return count > 0;
  }

  get_prescriptions_notes(): any {
    return this.data.consult.prescription_list.list.filter(v => !!v.instructions).map(v => v.instructions);
  }

  get_current_prescription_note(prescription: any): any {
    return this.data.consult.prescription_list.list.filter(v => !!v.instructions).findIndex(v => v.id === prescription.id);
  }


}
