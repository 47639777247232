import { Injectable, OnInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  public showAlert = false;
  public socketStatus = false;
  public timeout: any;

  constructor(private socket: Socket) {
  }

  init() {
    this.listen();
  }

  listen() {
    this.socket.on('connect', () => {
      console.info("Conectado al Servidor");
      this.socketStatus = true;
      this.showAlert = false;
    });

    this.socket.on('error', () => {
      console.info("Sin Conexión con el Servidor");
      this.socketStatus = false;
    });

    this.socket.on('disconnect', () => {
      console.info("Desconectado del Servidor");
      this.socketStatus = false;
      this.showAlert = true;
    });

    this.socket.on('notification', data => {
      console.info(data)
    });
  }

  // AUTH
  login(id: any) {
    this.socket.emit('login', { id });
  }

  logout() {
    this.socket.emit('logout');
  }

  // AUTH
  sendMessage(msg: string) {
    this.socket.emit('message', msg);
  }

  getMessage() {
    return this.socket.fromEvent('message');
  }

  getTutorPhoneVerify() {
    return this.socket.fromEvent('TutorPhoneVerify');
  }

  getUserPhoneVerify() {
    return this.socket.fromEvent('UserPhoneVerify');
  }

  getUserEmailVerify() {
    return this.socket.fromEvent('UserEmailVerify');
  }

  getDoctorPatientPhoneVerify() {
    return this.socket.fromEvent('DoctorPatientPhoneVerify');
  }

}