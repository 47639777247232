/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./spinner.component";
var styles_SpinnerSmallComponent = [];
var RenderType_SpinnerSmallComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SpinnerSmallComponent, data: {} });
export { RenderType_SpinnerSmallComponent as RenderType_SpinnerSmallComponent };
function View_SpinnerSmallComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["role", "status"], ["style", "color: #66c8c8;"]], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "fa-duotone fa-spinner-third fa-spin-pulse ", _co.class, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SpinnerSmallComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h5", [["class", "ml-2"]], [[2, "ml-2", null]], null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inline; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 1, 0, currVal_1); }); }
export function View_SpinnerSmallComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "d-flex justify-content-center align-items-center"]], [[2, "flex-column", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SpinnerSmallComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SpinnerSmallComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.loading && _co.title); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.inline; _ck(_v, 0, 0, currVal_0); }); }
export function View_SpinnerSmallComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-spinner-small", [], null, null, null, View_SpinnerSmallComponent_0, RenderType_SpinnerSmallComponent)), i0.ɵdid(1, 573440, null, 0, i2.SpinnerSmallComponent, [], null, null)], null, null); }
var SpinnerSmallComponentNgFactory = i0.ɵccf("app-spinner-small", i2.SpinnerSmallComponent, View_SpinnerSmallComponent_Host_0, { loading: "loading", class: "class", title: "title", inline: "inline" }, {}, []);
export { SpinnerSmallComponentNgFactory as SpinnerSmallComponentNgFactory };
