import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Toast, ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { SweelService } from 'src/app/shared/services/sweel.service';
import { DatatableComponent, datatable_type } from 'src/app/shared/ui/datatable/datatable.component';
import { SearchButtonComponent } from 'src/app/shared/ui/search_button/search_button.component';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {
  private doctor_subscription: Subscription;
  public doctor_id: any;
  public patient_data: any;


  @ViewChild('search_button_ref', { static: false }) search_button_ref: SearchButtonComponent;
  @ViewChild('datatable_ref', { static: false }) datatable_ref: DatatableComponent;
  public datatable: datatable_type;
  public data: any = {
    list: [],
    count: 0,
    skip: 0,
    loaded: false
  };

  @ViewChild('createModal', { static: false }) createModal: NgbModalRef;
  @ViewChild('updateModal', { static: false }) updateModal: NgbModalRef;
  @ViewChild('showModal', { static: false }) showModal: NgbModalRef;
  public selected_item: any;

  constructor(private endpoint: AppService, private pageService: PageService, public sweel: SweelService,
    private toast: ToastrService, private acountservice: AccountService, private patientService: PatientService) { }

  ngOnInit() {
    this.doctor_subscription = this.patientService.patient_data_subscription.subscribe(
      data => this.patient_data = data
    );
    this.datatable_config();
    this.doctor_subscription = this.endpoint.refresh$.subscribe(
      data => {
        this.update(this.data);
      }
    )
  }

  datatable_config() {
    this.datatable = {
      configs: {
        title: "Citas",
        //icon: "fa-duotone fa-address-book",
        paginate: false,
        search: false
      },
      filters: {
        limit: 20,
        skip: 0
      },
      headers: [
        {
          label: "Descripción",
          name: "description",
          order_by: "description"
        },
        {
          label: "Fecha",
          name: "fecha_date",
          order_by: "fecha_date",
          type: "date"
        },
        {
          label: "Hora",
          name: "hour_date",
          order_by: "hour_date",

        },
        {
          label: "duración",
          name: "duration",
          order_by: "duration",
        },
      ],
      events:
        [
          {
            name: "Detalles",
            icon: "fa-duotone fa-file-circle-info text-info"
          },
          {
            name: "Modificar",
            icon: "fa-duotone fa-edit text-warning"
          },
          {
            name: "Eliminar",
            icon: "fa-duotone fa-trash-alt text-danger",
          },
        ]
    };
  }

  on_update() {
    if (this.datatable_ref) this.datatable_ref.update();
  }

  update(data) {
    if (this.patient_data.general.id_user) {
      if (this.search_button_ref) data.search_word = this.search_button_ref.data;
      var load = {
        id: this.patient_data.general.id_user,
        ...data,
      };
      this.endpoint.get_dates_of_patients(load).subscribe(
        data => this.data = data,
        err => console.error(err)
      );
    }
  }

  events(data) {
    if (data.name == "Detalles" || data.name == "row_click") {
      this.selected_item = data.item;
      this.pageService.openModal(this.showModal, "lg");
    }
    if (data.name == "Eliminar") {
      this.sweel.confirm("Eliminar Cita", "Por favor confirme que desea eliminar la cita programada", "warning").then(
        res => {
          if (res) {
            this.delete(data.item.id_date);
          }
        }
      )
    }

    if (data.name == "Modificar") {
      this.selected_item = data.item;
      this.pageService.openModal(this.updateModal, "lg");
    }
  }

  delete(id: number) {
    let params = {
      id: id
    }
    this.endpoint.delete_dates(params).subscribe(
      res => {
        if (res) {
          this.toast.info("Operación Exitosa", "Cita eliminada correctamente");
        }
      }
    )
  }
  create() {
    this.pageService.openModal(this.createModal, "lg");
  }

}
