<ul *ngIf="config" class="nav nav-sm dynamic_tabs" [class.with-border]="!config.no_border">
    <li *ngFor="let item of config.list; let i = index" class="nav-item">
        <ng-container *ngIf="!item.show || (item.show && item.show())">
            <a
                *ngIf="item.click"
                [style.font-size]="item.font_size"
                [style.border-bottom-width]="item.active_stroke"
                class="nav-link pointer"
                (click)="item.click($event)"
                [class.active]="config.selected == i"
            >
                <div [class]="item.class">
                    <i *ngIf="item.icon" [class]="'mr-1 fa-duotone fa-' + item.icon"></i>
                    <span [style.font-size]="item.font_size">
                        {{ item.name | uppercase }}
                    </span>
                </div>
            </a>
            <a
                *ngIf="item.url"
                class="nav-link pointer"
                [style.font-size]="item.font_size"
                [style.border-bottom-width]="item.active_stroke"
                [routerLink]="item.url"
                [routerLinkActiveOptions]="{ exact: item.exact && item.exact }"
                [routerLinkActive]="['active']"
            >
                <div [class]="item.class">
                    <i *ngIf="item.icon" [class]="'mr-1 fa-duotone fa-' + item.icon"></i>
                    <span [style.font-size]="item.font_size">
                        {{ item.name | uppercase }}
                    </span>
                </div>
            </a>
        </ng-container>
    </li>
</ul>
