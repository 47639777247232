import { CatalogsService } from "src/app/services/catalogs.service";
import { PipesService } from "../services/pipes.service";
import { AnthropometriesClass, AnthropometriesInterface } from "./anthropometries-class";
import { CustomListClass } from "./custom-list-class";
import { DiagnosisClass, DiagnosisInterface, RawDiagnosisInterface } from "./diagnosis-class";
import { ExamsPhysicalClass, ExamsPhysicalInterface } from "./exams-physical-class";
import { ExamsRequestsClass, ExamsRequestsInterface } from "./exams-requests-class";
import { ExamsResultsClass, ExamsResultsInterface } from "./exams-results-class";
import { GeneralClass, GeneralInterface } from "./general-class";
import { PrescriptionClass, PrescriptionInterface } from "./prescription-class";
import { ProceduresRequestsClass, ProceduresRequestsInterface } from "./procedures-requests-class";
import { ProceduresResultsClass, ProceduresResultsInterface } from "./procedures-results-class";
import { RecommendationClass, RecommendationInterface } from "./recommendation-class";
import { ReferencesClass, ReferencesInterface } from "./references-class";
import { TreatmentClass, TreatmentInterface } from "./treatment-class";
import { VitalsClass, VitalsInterface } from "./vitals-class";
import { GrowthCurveClass, GrowthCurveInterface } from "./growth_curve/growth_curve-class";

export interface RawConsultInterface {
    id: any;
    id_doctor: any;
    id_patient: any;
    id_consults_type: any;
    id_consults_status: any;
    created_at: Date;
    created_by: any;
    updated_at: Date;
    doctor_name: any;
    doctor_username: any;
    doctor_gender: any;
    doctor_specialty: any;
    doctor_image: any;
    patient_name: any;
    own?: any;

    patient_gender?: any;
    age?: any;
    age_month?: any;
    age_day?: any;

    // SEGMENTS
    anthropometries: AnthropometriesInterface;
    diagnosis_list: RawDiagnosisInterface[];
    exams_physical: ExamsPhysicalInterface;
    exams_requests_list: ExamsRequestsInterface[];
    exams_results_list: ExamsResultsInterface[];
    general: GeneralInterface;
    prescription_list: PrescriptionInterface[];
    treatment_list: TreatmentInterface[];
    procedures_requests_list: ProceduresRequestsInterface[];
    procedures_results_list: ProceduresResultsInterface[];
    recommendation: RecommendationInterface;
    references_list: ReferencesInterface[];
    vitals: VitalsInterface;
    growth_curve: GrowthCurveInterface;
    growth_curve_list: GrowthCurveInterface[];
}

export interface ConsultInterface {
    id: any;
    id_doctor: any;
    id_patient: any;
    id_consults_type: any;
    id_consults_status: any;
    created_at: Date;
    created_by: any;
    updated_at: Date;
    doctor_name: any;
    doctor_username: any;
    doctor_gender: any;
    doctor_specialty: any;
    doctor_image: any;
    patient_name: any;
    own: any;

    patient_gender?: any;
    age?: any;
    age_month?: any;
    age_day?: any;

    // SEGMENTS
    anthropometries: AnthropometriesClass;
    diagnosis_list: CustomListClass<DiagnosisClass>;
    exams_physical: ExamsPhysicalClass;
    exams_requests_list: CustomListClass<ExamsRequestsClass>;
    exams_results_list: CustomListClass<ExamsResultsClass>;
    general: GeneralClass;
    prescription_list: CustomListClass<PrescriptionClass>;
    treatment_list: CustomListClass<TreatmentClass>;
    procedures_requests_list: CustomListClass<ProceduresRequestsClass>;
    procedures_results_list: CustomListClass<ProceduresResultsClass>;
    recommendation: RecommendationClass;
    references_list: CustomListClass<ReferencesClass>;
    vitals: VitalsClass;
    growth_curve: GrowthCurveClass;
    growth_curve_list: CustomListClass<GrowthCurveClass>;
}

export class ConsultClass implements ConsultInterface {
    public id: any;
    public id_doctor: any;
    public id_patient: any;
    public id_consults_type: any;
    public id_consults_status: any;
    public created_at: Date;
    public created_by: any;
    public updated_at: Date;
    public doctor_name: any;
    public doctor_username: any;
    public doctor_gender: any;
    public doctor_specialty: any;
    public doctor_image: any;
    public patient_name: any;
    public own: any;

    public patient_gender?: any;
    public age?: any;
    public age_month?: any;
    public age_day?: any;

    // SEGMENTS
    public anthropometries: AnthropometriesClass;
    public diagnosis_list: CustomListClass<DiagnosisClass>;
    public exams_physical: ExamsPhysicalClass;
    public exams_requests_list: CustomListClass<ExamsRequestsClass>;
    public exams_results_list: CustomListClass<ExamsResultsClass>;
    public general: GeneralClass;
    public prescription_list: CustomListClass<PrescriptionClass>;
    public procedures_requests_list: CustomListClass<ProceduresRequestsClass>;
    public procedures_results_list: CustomListClass<ProceduresResultsClass>;
    public recommendation: RecommendationClass;
    public references_list: CustomListClass<ReferencesClass>;
    public treatment_list: CustomListClass<TreatmentClass>;
    public vitals: VitalsClass;
    public growth_curve: GrowthCurveClass;
    public growth_curve_list: CustomListClass<GrowthCurveClass>;

    constructor(private catalogsService: CatalogsService, private pipesService: PipesService, consult?: RawConsultInterface) {
        if (consult) this.init(consult);
        else this.reset();
    }

    reset(): void {
        this.id = null;
        this.id_doctor = null;
        this.id_patient = null;
        this.id_consults_type = null;
        this.doctor_name = "";
        this.doctor_username = "";
        this.doctor_gender = "";
        this.doctor_specialty = "";
        this.doctor_image = "";
        this.patient_name = "";
        this.patient_gender = "1";
        this.own = "0";
        this.age = null;
        this.age_month = null;
        this.age_day = null;
        this.id_consults_status = null;
        this.created_at = new Date();
        this.created_by = null;
        this.updated_at = new Date();
        this.anthropometries = new AnthropometriesClass(this.catalogsService);
        this.diagnosis_list = new CustomListClass<DiagnosisClass>();
        this.exams_physical = new ExamsPhysicalClass();
        this.exams_requests_list = new CustomListClass<ExamsRequestsClass>();
        this.exams_results_list = new CustomListClass<ExamsResultsClass>();
        this.general = new GeneralClass();
        this.prescription_list = new CustomListClass<PrescriptionClass>();
        this.treatment_list = new CustomListClass<TreatmentClass>();
        this.procedures_requests_list = new CustomListClass<ProceduresRequestsClass>();
        this.procedures_results_list = new CustomListClass<ProceduresResultsClass>();
        this.recommendation = new RecommendationClass();
        this.references_list = new CustomListClass<ReferencesClass>();
        this.vitals = new VitalsClass(this.catalogsService);
        this.growth_curve = new GrowthCurveClass(this.catalogsService);
        this.growth_curve_list = new CustomListClass<GrowthCurveClass>();
    }

    init(consult: RawConsultInterface) {
        this.id = consult.id ? consult.id : null;
        this.id_doctor = consult.id_doctor ? consult.id_doctor : null;
        this.id_patient = consult.id_patient ? consult.id_patient : null;
        this.id_consults_type = consult.id_consults_type ? consult.id_consults_type : null;
        this.id_consults_status = consult.id_consults_status ? consult.id_consults_status : null;
        this.created_at = consult.created_at ? consult.created_at : new Date();
        this.created_by = consult.created_by ? consult.created_by : null;
        this.updated_at = consult.updated_at ? consult.updated_at : new Date();
        this.doctor_name = consult.doctor_name ? consult.doctor_name : "";
        this.doctor_username = consult.doctor_username ? consult.doctor_username : "";
        this.doctor_gender = consult.doctor_gender ? consult.doctor_gender : "";
        this.doctor_specialty = consult.doctor_specialty ? consult.doctor_specialty : "";
        this.doctor_image = consult.doctor_image ? consult.doctor_image : "";
        this.patient_name = consult.patient_name ? consult.patient_name : "";
        this.own = consult.own ? consult.own : "";
        this.patient_gender = consult.patient_gender ? consult.patient_gender : "";
        this.age = consult.age ? consult.age : "";
        this.age_month = consult.age_month ? consult.age_month : "";
        this.age_day = consult.age_day ? consult.age_day : "";
        if (consult.anthropometries) this.anthropometries = new AnthropometriesClass(this.catalogsService, consult.anthropometries);
        if (consult.diagnosis_list) this.diagnosis_list = new CustomListClass<DiagnosisClass>(consult.diagnosis_list.map((diagnosis) => new DiagnosisClass(this.catalogsService, diagnosis)));
        if (consult.exams_physical) this.exams_physical = new ExamsPhysicalClass(consult.exams_physical);
        if (consult.exams_requests_list)
            this.exams_requests_list = new CustomListClass<ExamsRequestsClass>(consult.exams_requests_list.map((exams_requests) => new ExamsRequestsClass(this.catalogsService, exams_requests)));
        if (consult.exams_results_list)
            this.exams_results_list = new CustomListClass<ExamsResultsClass>(
                consult.exams_results_list.map((exams_results) => new ExamsResultsClass(this.catalogsService, this.pipesService, exams_results))
            );
        if (consult.general) this.general = new GeneralClass(consult.general);
        if (consult.prescription_list)
            this.prescription_list = new CustomListClass<PrescriptionClass>(consult.prescription_list.map((prescription) => new PrescriptionClass(this.catalogsService, prescription)));
        if (consult.treatment_list) this.treatment_list = new CustomListClass<TreatmentClass>(consult.treatment_list.map((treatment) => new TreatmentClass(this.catalogsService, treatment)));
        if (consult.procedures_requests_list)
            this.procedures_requests_list = new CustomListClass<ProceduresRequestsClass>(
                consult.procedures_requests_list.map((procedures_requests) => new ProceduresRequestsClass(this.catalogsService, procedures_requests))
            );
        if (consult.procedures_results_list)
            this.procedures_results_list = new CustomListClass<ProceduresResultsClass>(
                consult.procedures_results_list.map((procedures_results) => new ProceduresResultsClass(this.catalogsService, this.pipesService, procedures_results))
            );
        if (consult.recommendation) this.recommendation = new RecommendationClass(consult.recommendation);
        if (consult.references_list) this.references_list = new CustomListClass<ReferencesClass>(consult.references_list.map((references) => new ReferencesClass(this.catalogsService, references)));
        if (consult.vitals) this.vitals = new VitalsClass(this.catalogsService, consult.vitals);
        if (consult.growth_curve) this.growth_curve = new GrowthCurveClass(this.catalogsService, consult.growth_curve); else this.growth_curve = new GrowthCurveClass(this.catalogsService);
        if (consult.growth_curve_list)
            this.growth_curve_list = new CustomListClass<GrowthCurveClass>(
                consult.growth_curve_list.map((growth_curve) => new GrowthCurveClass(this.catalogsService, growth_curve))
            );
    }

    raw(): RawConsultInterface {
        let data: any = {};
        data.id = this.id;
        data.id_doctor = this.id_doctor;
        data.doctor_name = this.doctor_name;
        data.doctor_username = this.doctor_username;
        data.doctor_gender = this.doctor_gender;
        data.doctor_specialty = this.doctor_specialty;
        data.doctor_image = this.doctor_image;
        data.patient_name = this.patient_name;
        data.id_patient = this.id_patient;
        data.id_consults_type = this.id_consults_type;
        data.id_consults_status = this.id_consults_status;
        data.created_at = this.created_at;
        data.created_by = this.created_by;
        data.updated_at = this.updated_at;
        data.own = this.own;
        data.patient_gender = this.patient_gender;
        data.age = this.age;
        data.age_month = this.age_month;
        data.age_day = this.age_day;
        data.anthropometries = this.anthropometries.raw();
        data.diagnosis_list = this.diagnosis_list.raw();
        data.exams_physical = this.exams_physical.raw();
        data.exams_requests_list = this.exams_requests_list.raw();
        data.exams_results_list = this.exams_results_list.raw();
        data.general = this.general.raw();
        data.prescription_list = this.prescription_list.raw();
        data.procedures_requests_list = this.procedures_requests_list.raw();
        data.procedures_results_list = this.procedures_results_list.raw();
        data.recommendation = this.recommendation.raw();
        data.references_list = this.references_list.raw();
        data.treatment_list = this.treatment_list.raw();
        data.vitals = this.vitals.raw();
        data.growth_curve = this.growth_curve.raw();
        data.growth_curve_list = this.growth_curve_list.raw();
        return data;
    }

    to_string(): RawConsultInterface {
        let data: any = {};
        data.id = this.id;
        data.id_doctor = this.id_doctor;
        data.id_patient = this.id_patient;
        data.id_consults_type = this.id_consults_type;
        data.id_consults_status = this.id_consults_status;
        data.created_at = this.created_at;
        data.created_by = this.created_by;
        data.updated_at = this.updated_at;
        data.doctor_name = this.doctor_name;
        data.doctor_username = this.doctor_username;
        data.doctor_gender = this.doctor_gender;
        data.doctor_specialty = this.doctor_specialty;
        data.doctor_image = this.doctor_image;
        data.patient_name = this.patient_name;
        data.own = this.own;
        data.patient_gender = this.patient_gender;
        data.age = this.age;
        data.age_month = this.age_month;
        data.age_day = this.age_day;
        data.anthropometries = this.anthropometries.to_string();
        data.diagnosis_list = this.diagnosis_list.to_string();
        data.exams_physical = this.exams_physical.to_string();
        data.exams_requests_list = this.exams_requests_list.to_string();
        data.exams_results_list = this.exams_results_list.to_string();
        data.general = this.general.to_string();
        data.prescription_list = this.prescription_list.to_string();
        data.procedures_requests_list = this.procedures_requests_list.to_string();
        data.procedures_results_list = this.procedures_results_list.to_string();
        data.recommendation = this.recommendation.to_string();
        data.references_list = this.references_list.to_string();
        data.treatment_list = this.treatment_list.to_string();
        data.vitals = this.vitals.to_string();
        data.growth_curve = this.growth_curve.to_string();
        data.growth_curve_list = this.growth_curve_list.to_string();
        return data;
    }

    pretty(): void { }

    valid(): boolean {
        if (this.general.reason && this.general.reason.trim().length > 0) {
            return true;
        }
        return false;
    }
}
