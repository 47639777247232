import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from '../services/app.service';
import { PageService, PageState } from '../services/page.service';
import { SocketService } from '../services/socket.service';
// import { SocketService } from '../socket.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  private page_subscription: Subscription;
  public page_state: PageState;

  public patient_id: any;

  constructor(private endpoint: AppService, private activeRoute: ActivatedRoute, private pageService: PageService, private socketService: SocketService) {
  }

  ngOnInit() {
    this.socketService.init();
    this.page_subscription = this.pageService.current.subscribe(
      state => {
        this.page_state = state;

        if (!this.page_state.sidebar_1.expand && (!document.body.classList.contains("sidebar-enable") || !document.body.classList.contains("enlarged"))) {
          document.body.classList.add("sidebar-enable");
          document.body.classList.add("enlarged");
        }
        else if (this.page_state.sidebar_1.expand && (document.body.classList.contains("sidebar-enable") || document.body.classList.contains("enlarged"))) {
          document.body.classList.remove("sidebar-enable");
          document.body.classList.remove("enlarged");
        }
      }
    );
  }

  ngOnDestroy() {
    this.page_subscription.unsubscribe();
  }

  url_has(word) {
    return this.activeRoute.snapshot["_urlSegment"].segments.map(value => value.path).find(value => value == word);
  }


  show_alert() {
    return this.socketService.showAlert;
  }
}