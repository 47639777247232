<div class="bg-white pr-3 py-2">
    <div class="container-fluid px-0">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-between mb-2">
                    <div class="d-flex flex-column my-auto">
                        <div class="title-last mt-auto">
                            {{page_state.profile ? 'perfil' : "" }}
                            {{page_state.expedient ? 'expediente' : "" }}
                        </div>
                        <div class="title mb-auto"> historial de consultas </div>
                    </div>

                    <div *ngIf="page_state.expedient" class="my-auto">
                        <div class="d-flex text-right">
                            <app-search_button (change)="on_update()" (submit)="on_update()" #search_button_ref></app-search_button>
                            <button class="ml-2 btn radius btn-primary no-wrap" style="font-weight: 500;" (click)="reset_consult()"><i class="fa-duotone fa-plus"></i> CONSULTA </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="d-flex">
                    <a class="ml-auto checkbox-item d-flex my-auto" (click)="checkbox_check()">
                        <i class="fa-duotone fa-square mr-2 my-auto" *ngIf="only_mine == false"></i>
                        <i class="fa-duotone fa-check-square fa-swap-opacity mr-2 my-auto" *ngIf="only_mine == true"></i>
                        <div>
                            Ver mis consultas
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <app-bold_list
                               #bold_list_ref
                               (update_bold_list)="update($event)"
                               (events_bold_list)="events($event)"
                               [in_headers]="bold_list.headers"
                               [in_contents]="bold_list.contents"
                               [in_events]="bold_list.events"
                               [in_data]="data"
                               [in_filters]="bold_list.filters"
                               [in_configs]="bold_list.configs">
                </app-bold_list>
            </div>
        </div>
    </div>

    <ng-container *ngIf="show_consult" [ngTemplateOutlet]="showModal"></ng-container>

</div>

<ng-template #showModal>
    <app-show_consult [data]="selected_item" (close)="show_consult=false"></app-show_consult>
</ng-template>