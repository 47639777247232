<div class="consult-minimized-header" *ngIf="has_consult()">
    <div class="d-flex">
        <div class="text" (click)="maximize()">
            Consulta
            <!-- {{consult_state.consult.id}} -->
        </div>
        <div class="ml-auto">
            <a class="icon-secondary icon-secondary-sm my-auto" title="Expandir" (click)="maximize()">
                <i class="fa-duotone fa-window-maximize"></i>
            </a>
            <!-- <a class="icon-secondary icon-secondary-sm my-auto" title="Guardar" (click)="save_consult()">
                <i class="fa-duotone fa-save"></i>
            </a> -->
            <a class="icon-secondary icon-secondary-sm my-auto danger" title="Cerrar" (click)="close()">
                <i class="fa-duotone fa-times"></i>
            </a>
        </div>
    </div>
</div>