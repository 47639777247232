import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { AppService } from 'src/app/services/app.service';
import { Subscription } from 'rxjs';
import { PageService, PageState } from 'src/app/services/page.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-second-sidebar',
  templateUrl: './second-sidebar.component.html',
  styleUrls: ['./second-sidebar.component.scss']
})
export class SecondSidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  private page_subscription: Subscription;
  public page_state: PageState;
  showGrowthCurves: boolean;

  constructor(private endpoint: AppService, private pageService: PageService, private location: Location, private router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.showGrowthCurves = this.location.path() === '/expediente/curvas_crecimiento';
    });
  }

  ngOnInit() {
    this.page_subscription = this.pageService.current.subscribe(
      state => this.page_state = state
    );
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.page_subscription.unsubscribe();
  }

}
