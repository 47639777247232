<div class="modal-header ribbon-box d-flex align-items-center">

    <div class="row">
        <div class="col-md-4 ">
    
            <i  class="fa-lg pointer orden fa-duotone fa-play mr-1 text-success position"   (click)="change_page()"   title="Iniciar"></i>
            <i  class="fa-lg pointer      fa-duotone fa-trash-alt mr-1 text-danger position"(click)="delete_date()"   title="Eliminar "></i>
            
        </div>


       <div class="col-md-4 " >
        <h4 class="text-center w-100 modal-title text-dark"><i class="text-right fa-lg fa-duotone fa-calendar-day mr-1 text-primary"></i> Detalle de Cita</h4>
        
    </div>
    <div class="col-md-4">
        <button type="button" class="close text-outline-danger" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    </div>
    
</div>

<div class="modal-body p-2">
    <div class="text-left">
        <app-form_render #form_ref [config]="form_config" [data]="data"></app-form_render>
    </div>
</div>
