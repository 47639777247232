import { CatalogsService } from "src/app/services/catalogs.service";
import { CustomClass } from "./custom-class";

export interface ExamsRequestsInterface {
    id: any;
    id_patient: any;
    id_consult: any;
    id_exams_type: any;
    id_exams_name: any;
    id_exams_name_2: any;
    observations: string;
    place: string;
};

interface ToStringInterface {
    exams_type: string;
    exams_name: string;
    exams_name_2: string;
    observations: string;
    place: string;
};

export class ExamsRequestsClass extends CustomClass implements ExamsRequestsInterface {
    public id: any;
    public id_patient: any;
    public id_consult: any;
    public id_exams_type: any;
    public id_exams_name: any;
    public id_exams_name_2: any;
    public observations: string;
    public place: string;

    constructor(private catalogsService: CatalogsService, data?: ExamsRequestsInterface) {
        super();
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: ExamsRequestsInterface): void {
        super.overwrite(data);
        this.id = data.id;
        this.id_patient = data.id_patient;
        this.id_consult = data.id_consult;
        this.id_exams_type = data.id_exams_type;
        this.id_exams_name = data.id_exams_name;
        this.id_exams_name_2 = data.id_exams_name_2;
        this.observations = data.observations;
        this.place = data.place;
    }

    reset(): void {
        super.reset();
        this.id = null;
        this.id_patient = null;
        this.id_consult = null;
        this.id_exams_type = null;
        this.id_exams_name = null;
        this.id_exams_name_2 = null;
        this.observations = "";
        this.place = "";
    }

    format(): ExamsRequestsInterface {
        return this as ExamsRequestsInterface;
    }

    valid(): boolean {
        return true;
    }

    to_string(): ToStringInterface {
        let exams_type = this.catalogsService.catalogs.exams_types.find(v => v.id == this.id_exams_type);
        let exams_name = this.catalogsService.catalogs.exams_names.find(v => v.id == this.id_exams_name);
        let exams_name_2 = this.catalogsService.catalogs.exams_names_2.find(v => v.id == this.id_exams_name_2);

        let data: ToStringInterface = {
            exams_type: exams_type ? exams_type.name : "",
            exams_name: exams_name ? exams_name.name : "",
            exams_name_2: exams_name_2 ? exams_name_2.name : "",
            observations: this.observations,
            place: this.place
        };
        return data;
    }

    raw(): ExamsRequestsInterface {
        return {
            id: this.id,
            id_patient: this.id_patient,
            id_consult: this.id_consult,
            id_exams_type: this.id_exams_type,
            id_exams_name: this.id_exams_name,
            id_exams_name_2: this.id_exams_name_2,
            observations: this.observations,
            place: this.place,
        };
    }

}
