<div class="d-flex flex-column pr-3 py-2 h-100">

    <div class="container-fluid px-0">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-between mb-2">
                    <div class="d-flex flex-column my-auto">
                        <div class="title-last mt-auto"> expediente </div>
                        <div class="title mb-auto"> citas </div>
                    </div>

                    <div class="my-auto">
                        <div class="d-flex text-right">
                            <app-search_button (change)="on_update()" (submit)="on_update()" #search_button_ref></app-search_button>
                            <button class="ml-2 btn radius btn-primary no-wrap" style="font-weight: 500;" (click)="create()"><i class="fa-duotone fa-plus"></i> CITA </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-grow-1">
        <div class="local-internal-scroll">
            <div class="scroll-content pr-2">

                <app-datatable
                    #datatable_ref
                    (update_datatable)="update($event)"
                    (events_datatable)="events($event)"
                    [in_headers]="datatable.headers"
                    [in_events]="datatable.events"
                    [in_data]="data"
                    [in_filters]="datatable.filters"
                    [in_configs]="datatable.configs">
                </app-datatable>

            </div>
        </div>
    </div>
</div>

<ng-template #showModal role="document">
    <app-show-date [data]="selected_item" ></app-show-date>
</ng-template>

<ng-template #createModal role="document">
    <app-create-date></app-create-date>
</ng-template>

<ng-template #updateModal role="document">
    <app-update-date [data]="selected_item"></app-update-date>
    </ng-template>