import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { PageService } from '../services/page.service';

@Injectable({
  providedIn: 'root'
})
export class MyPatientsGuard implements CanActivate {

  constructor(private pageService: PageService) { }

  canActivate() {
    this.pageService.set_sidebar_2_state({
      expand: false,
      scroll: true,
      show: false
    });
    this.pageService.set_sidebar_3_state({
      expand: false,
      scroll: true,
      show: true
    });
    this.pageService.set_consult(false);
    this.pageService.set_profile(false);
    this.pageService.set_expedient(false);
    return true;
  }

}
