import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormRenderComponent, form_type } from 'src/app/shared/ui/form_render/form_render.component';
import { AllergiesService, AllergiesState } from '../allergies.service';
import { ExpedientMetadata, ExpedientService } from 'src/app/services/expedient.service';

@Component({
	selector: 'app-module-form',
	templateUrl: './form.component.html'
})
export class FormComponent implements OnInit, OnDestroy, AfterViewInit {
	private expedient_subscription: Subscription;
	public expedient_state: ExpedientMetadata;

	private allergies_subscription: Subscription;
	public allergies_state: AllergiesState;

	@ViewChild('form_ref', { static: false }) form_ref: FormRenderComponent;
	public form_config: form_type;

	constructor(private moduleService: AllergiesService, private expedientService: ExpedientService) {
	}

	ngOnInit() {
		this.expedient_subscription = this.expedientService.current.subscribe(
			state => {
				this.expedient_state = state;
				// this.moduleService.reload();
				this.form_configs();
			}
		);
		this.allergies_subscription = this.moduleService.current.subscribe(
			state => this.allergies_state = state
		);
		this.form_configs();
	}

	ngAfterViewInit() {
		if (this.allergies_state) {
			this.moduleService.forms.allergies = this.form_ref;
		}
	}

	ngOnDestroy() {
		this.expedient_subscription.unsubscribe();
		this.allergies_subscription.unsubscribe();
	}

	form_configs() {
		this.form_config = {
			pretty_view: () => this.expedient_state.method == "show",
			disabled: () => this.expedient_state.method == "show",
			submit: (callback) => this.moduleService.submit(callback),
			rows: [
				{
					class: "row",
					cols: [
						{
							name: "allergen",
							type: "text",
							label: "Alérgeno",
							placeholder: () => "Ej: La piña, polvo, huevo, maní",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
								maxlength: {
									value: () => 45
								}
							},
							disabled: () => false,
							tooltip: {
								text: "Es una sustancia que puede provocar una reacción alérgica.\n(Ej: La piña, polvo, huevo, maní)",
								icon: "fa-duotone fa-question pointer text-info ml-1",
								class: ""
							}
						},
						{
							name: "id_allergies_reactions_type",
							type: "select",
							label: "Tipo de Reacción",
							placeholder: () => "- Seleccione -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							catalog: {
								list: () => this.moduleService.catalogs.allergies_reactions_types,
								value: "id",
								text: "name"
							},
							disabled: () => false,
							tooltip: {
								text: "Rash = Sarpullido/Erupción.\n" +
									"Dermatitis atópica = Enrojecimiento de piel y picazón.\n" +
									"Anafilaxis: molestia u opresión en el pecho, dificultad para respirar, tos, dificultad para tragar.\n" +
									"Conjuntivitis alérgica: ojo rojo.\n" +
									"Rinitis alérgica: estornudos",
								icon: "fa-duotone fa-question pointer text-info ml-1",
								class: "pre"
							}
						},
						{
							name: "id_allergies_reactions_level",
							type: "select",
							label: "Nivel de Reacción",
							placeholder: () => "- Seleccione -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							catalog: {
								list: () => this.moduleService.catalogs.allergies_reactions_levels,
								value: "id",
								text: "name"
							},
							disabled: () => false,
							tooltip: {
								text: "Gravedad del tipo de reacción",
								icon: "fa-duotone fa-question pointer text-info ml-1",
								class: ""
							}

						},
					]
				},
				{
					class: "row",
					cols: [
						{
							type: "buttons",
							col_class: "col-12",
							buttons: [
								{
									class: "btn btn-sm btn-outline-danger",
									label: "Cancelar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									show: () => {
										return this.expedient_state.method == "update" || this.expedient_state.method == "insert";
									},
									click: () => {
										this.moduleService.cancel();
									},
								},
								{
									class: "btn btn-sm btn-outline-danger",
									label: "Cerrar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									show: () => {
										return this.expedient_state.method == "show";
									},
									click: () => {
										this.moduleService.close();
									},
								},
								{
									class: "btn btn-sm btn-success ml-auto",
									label: "Guardar",
									type: "submit",
									icon: {
										class: "fa-duotone fa-check",
										position: 'right',
									},
									show: () => {
										return this.expedient_state.method == "update" || this.expedient_state.method == "insert";
									},
									disabled: () => (this.form_ref ? !this.form_ref.valid() : false) || this.allergies_state.loading,
								},
							]
						},
					],
				},
			]
		};
	}
}