import { CatalogsService } from "src/app/services/catalogs.service";
import { CustomClass } from "../custom-class";

export interface LocationsInterface {
    id: any;
    id_user: any;
    name: string;
    hours: string;
    att_public: any
    att_type: any
    days: {
        id: any,
        init: Date,
        finish: Date
    }[]
}

interface ToStringInterface {
    days: {
        id: any,
        init: Date,
        finish: Date
    }[];
    name: string;
    hours: string;
    att_public: any
    att_type: any
}

export class LocationsClass extends CustomClass implements LocationsInterface {
    public id: any;
    public id_user: any;
    public days: {
        id: any,
        init: Date,
        finish: Date
    }[]
    public name: string;
    public hours: string;
    public att_public: any
    public att_type: any

    constructor(private catalogsService: CatalogsService, data?: LocationsInterface) {
        super();
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: LocationsInterface): void {
        super.overwrite(data);
        this.id = data.id;
        this.id_user = data.id_user;
        this.days = data.days;
        this.name = data.name;
        this.hours = data.hours;
        this.att_public = data.att_public;
        this.att_type = data.att_type;
    }

    reset(): void {
        super.reset();
        this.id = null;
        this.id_user = null;
        this.days = [
            { id: 0, init: null, finish: null },
            { id: 1, init: null, finish: null },
            { id: 2, init: null, finish: null },
            { id: 3, init: null, finish: null },
            { id: 4, init: null, finish: null },
            { id: 5, init: null, finish: null },
            { id: 6, init: null, finish: null },
        ];
        this.name = "";
        this.hours = "";
        this.att_public = 1;
        this.att_type = 1;
    }

    format(): LocationsInterface {
        return this as LocationsInterface;
    }

    valid(): boolean {
        return true;
    }

    to_string(): ToStringInterface {
        let data: ToStringInterface = {
            days: this.days,
            name: this.name,
            hours: this.hours,
            att_public: this.att_public,
            att_type: this.att_type,
        };
        return data as ToStringInterface;
    }

    raw(): LocationsInterface {
        return {
            id: this.id,
            id_user: this.id_user,
            days: this.days,
            name: this.name,
            hours: this.hours,
            att_public: this.att_public,
            att_type: this.att_type,
        };
    }
}
