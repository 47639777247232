import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from "rxjs";
import { AppService } from "src/app/services/app.service";
import { PageService, PageState } from "src/app/services/page.service";
import { PatientService, patient_state } from "src/app/services/patient-service.service";
import { hovered_sidebar_type } from "src/app/shared/ui/hovered_sidebar/hovered_sidebar.component";

@Component({
    selector: "app-expedient",
    templateUrl: "./expedient.component.html",
    styleUrls: ["./expedient.component.scss"],
})
export class ExpedientComponent implements OnInit, OnDestroy {
    private patient_subscription: Subscription;
    public patient_state: patient_state;
    private page_subscription: Subscription;
    public page_state: PageState;

    public hovered_sidebar: hovered_sidebar_type;

    @ViewChild("chatModal", { static: false }) chatModal: NgbModalRef;

    constructor(
        private endpoint: AppService,
        private patientService: PatientService,
        private pageService: PageService
    ) {}

    ngOnInit() {
        this.patient_subscription = this.patientService.patient_data_subscription.subscribe(
            state => (this.patient_state = state)
        );
        this.page_subscription = this.pageService.current.subscribe(state => {
            this.page_state = state;
            this.hovered_sidebar_config();
        });
    }

    ngOnDestroy() {
        if (this.page_subscription) this.page_subscription.unsubscribe();
        if (this.patient_subscription) this.patient_subscription.unsubscribe();
    }

    hovered_sidebar_config() {
        this.hovered_sidebar = {
            style:"shadowbox",
            list: [
                // {
                //   name: () => "Chat",
                //   click: () => {
                //     this.open_chat()
                //   },
                //   icon: () => "fa-duotone fa-comment",
                // },
                ...(this.page_state.expedient
                    ? [
                          {
                              name: () => "Perfil",
                              url: () => ["./perfil"],
                              icon: () => "fa-duotone fa-user",
                          },
                          {
                              name: () => "Consultas",
                              url: () => ["./consultas"],
                              icon: () => "fa-duotone fa-calendar-check",
                          },
                      ]
                    : []),
                {
                    name: () => "Contactos",
                    url: () => ["./contactos"],
                    icon: () => "fa-duotone fa-address-book",
                },
                {
                    name: () => "Antecedentes",
                    url: () => ["./antecedentes"],
                    icon: () => "fa-duotone fa-book-medical",
                },
                // {
                //   name: () => "Procedimientos",
                //   url: () => ["./procedimientos"],
                //   icon: () => "fa-duotone fa-procedures"
                // },
                // {
                //   name: () => "Referencias",
                //   url: () => ["./referencias"],
                //   icon: () => "fa-duotone fa-asterisk"
                // },
                // {
                //   name: () => "Exámenes",
                //   url: () => ["./examenes"],
                //   icon: () => "fa-duotone fa-vial"
                // },
                // {
                //   name: () => "Medicinas",
                //   url: () => ["./medicinas"],
                //   icon: () => "fa-duotone fa-capsules"
                // },
                // {
                //     name: () => "Seguros",
                //     url: () => ["./seguros"],
                //     icon: () => "fa-duotone fa-shield-alt",
                // },
                {
                    name: () => "Vacunas",
                    url: () => ["./vacunas"],
                    icon: () => "fa-duotone fa-syringe",
                },
                {
                    name: () => "Curvas de crecimiento",
                    url: () => ["./curvas_crecimiento"],
                    icon: () => "fa-duotone fa-chart-line-up",
                },
                {
                    name: () => "Relaciones",
                    url: () => ["./relaciones"],
                    icon: () => "fa-duotone fa-users",
                },
                // {
                //     name: () => "Instituciones",
                //     url: () => ["./instituciones"],
                //     icon: () => "fa-duotone fa-building",
                // },
                // {
                //   name:  ()  =>  "Citas",
                //   url :  ()  =>  "../citas",
                //   icon : ()  => "fa-duotone fa-calendar-week"
                // },
                // {
                //   name:  ()  =>  "Facturas",
                //   url :  ()  =>  "../facturas",
                //   icon : ()  => "fa-duotone fa-money-check-alt"
                // }
            ],
        };
    }

    open_chat() {
        this.pageService.openModal(this.chatModal);
    }
}
