import { CustomClass } from "./custom-class";

export interface ExamsPhysicalInterface {
    id_consult: any;
    neurological: string;
    eyes: string;
    ears: string;
    nose: string;
    mouth: string;
    neck: string;
    cardiac: string;
    pulmonar: string;
    abdomen: string;
    genitals: string;
    extremities: string;
    others: string;
};

interface ToStringInterface {
    neurological: string;
    eyes: string;
    ears: string;
    nose: string;
    mouth: string;
    neck: string;
    cardiac: string;
    pulmonar: string;
    abdomen: string;
    genitals: string;
    extremities: string;
    others: string;
};

export class ExamsPhysicalClass extends CustomClass implements ExamsPhysicalInterface {
    public id_consult: any;
    public neurological: string;
    public eyes: string;
    public ears: string;
    public nose: string;
    public mouth: string;
    public neck: string;
    public cardiac: string;
    public pulmonar: string;
    public abdomen: string;
    public genitals: string;
    public extremities: string;
    public others: string;

    constructor(data?: ExamsPhysicalInterface) {
        super();
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: ExamsPhysicalInterface): void {
        super.overwrite(data);
        this.id_consult = data.id_consult;
        this.neurological = data.neurological;
        this.eyes = data.eyes;
        this.ears = data.ears;
        this.nose = data.nose;
        this.mouth = data.mouth;
        this.neck = data.neck;
        this.cardiac = data.cardiac;
        this.pulmonar = data.pulmonar;
        this.abdomen = data.abdomen;
        this.genitals = data.genitals;
        this.extremities = data.extremities;
        this.others = data.others;
    }

    reset(): void {
        super.reset();
        this.id_consult = null;
        this.neurological = "";
        this.eyes = "";
        this.ears = "";
        this.nose = "";
        this.mouth = "";
        this.neck = "";
        this.cardiac = "";
        this.pulmonar = "";
        this.abdomen = "";
        this.genitals = "";
        this.extremities = "";
        this.others = "";
    }

    format(): ExamsPhysicalInterface {
        return this as ExamsPhysicalInterface;
    }

    valid(): boolean {
        return true;
    }

    to_string(): ToStringInterface {
        let data: ToStringInterface;
        data = {
            neurological: this.neurological,
            eyes: this.eyes,
            ears: this.ears,
            nose: this.nose,
            mouth: this.mouth,
            neck: this.neck,
            cardiac: this.cardiac,
            pulmonar: this.pulmonar,
            abdomen: this.abdomen,
            genitals: this.genitals,
            extremities: this.extremities,
            others: this.others,
        };
        return data;
    }

    raw(): ExamsPhysicalInterface {
        return {
            id_consult: this.id_consult,
            neurological: this.neurological,
            eyes: this.eyes,
            ears: this.ears,
            nose: this.nose,
            mouth: this.mouth,
            neck: this.neck,
            cardiac: this.cardiac,
            pulmonar: this.pulmonar,
            abdomen: this.abdomen,
            genitals: this.genitals,
            extremities: this.extremities,
            others: this.others,
        };
    }
}
