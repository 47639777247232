import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search_button',
  templateUrl: './search_button.component.html',
  styleUrls: ['./search_button.component.scss']
})
export class SearchButtonComponent implements OnInit {
  @ViewChild('input', { static: false }) input: any;
  @Output("submit") submit: EventEmitter<any> = new EventEmitter;
  @Output("change") change: EventEmitter<any> = new EventEmitter;

  public data = "";
  public display = false;
  private timeout: any;

  constructor() {
    this.timeout = null;
  }

  ngOnInit() {
  }

  show() {
    return this.display || this.data;
  }

  on_submit() {
    this.submit.emit();
  }

  on_input() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.on_change();
    }, 1000);
  }

  on_change() {
    this.change.emit();
  }

}
