<div class="bg-masterhead d-flex align-items-center" style="height: 100vh">
	<div class="account-pages w-100">
		<div class="container against-footer">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6 col-xl-5">
					<div class="card shadow" style="border-radius: 25px;">

						<div class="card-body p-4">
							<div class="text-center w-100 m-auto">
								<a routerLink="/">
									<img src="assets/images/favicon.ico" alt="UNIMED" style="height: 8rem;">
								</a>
								<p class="text-muted mb-2 mt-2">Ingrese su nueva contraseña y verifique su correo.
								</p>
							</div>

							<form class="needs-validation" name="resetForm" [formGroup]="resetForm" (ngSubmit)="onSubmit()" novalidate>

								<app-ui-preloader [display]="loading"></app-ui-preloader>

								<ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
								<ngb-alert type="success" *ngIf="success" [dismissible]="false">{{ success }}</ngb-alert>

								<div class="form-group mb-3" *ngIf="!success">
									<label for="email">Correo</label>

									<input type="email" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="email" placeholder="Correo" name="email" required />

									<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
										<div *ngIf="f.email.errors.required">Se requiere correo.</div>
										<div *ngIf="f.email.errors.email">Se requiere dirección de correo válida.</div>
									</div>
								</div>

								<div class="form-group mb-3" *ngIf="!success">
									<label for="password">Nueva Contraseña</label>

									<input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" id="password" placeholder="Contraseña" name="password" required />

									<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
										<div *ngIf="f.password.errors.required">Se requiere contraseña.</div>
										<div *ngIf="f.password.errors.pattern && !f.password.errors.required && !f.password.errors.minlength">La contraseña debe contener números</div>
										<div *ngIf="f.password.errors.pattern && !f.password.errors.required && f.password.errors.minlength">La contraseña debe contener al menos 8 caracteres y números</div>
									</div>
								</div>

								<div class="form-group mb-3" *ngIf="!success">
									<label for="confirm_password">Confirmar Contraseña</label>

									<input type="password" formControlName="confirm_password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirm_password.errors }" id="confirm_password" placeholder="Confirmar Contraseña" name="confirm_password" required />

									<div *ngIf="submitted && f.confirm_password.errors" class="invalid-feedback">
										<div *ngIf="f.confirm_password.errors.required">Se requiere confirmar contraseña.</div>
										<div *ngIf="passwordMatch() && !f.confirm_password.errors.required ">Las contraseñas no coinciden.</div>
									</div>
								</div>

								<div class="form-group mb-0 text-center" *ngIf="!success">
									<button class="btn btn-primary btn-block radius" type="submit">Aceptar</button>
								</div>

								<div class="form-group mb-0 text-center" *ngIf="success">
									<button class="btn btn-success btn-block" type="button" routerLink="/">Regresar</button>
								</div>
							</form>
						</div> <!-- end card-body -->
					</div>
					<!-- end card -->

				</div>
			</div>
		</div>
	</div>
	<footer class="footer footer-alt">
		<div class="row">
			<div class="col-md-6 text-left text-muted ">
				{{release_year == year ? year : (release_year + " - " + year)}} © UNIMED by <a class="text-primary" target="_blank" href="https://cjalvarez.com">CJAlvarez </a>
			</div>
			<div class="col-md-6">
				<div class="text-md-right footer-links d-none d-sm-block">
					<a class="text-muted" target="_blank" href="https://github.com/CJAlvarez"><i class="fab fa-github"></i> GitHub</a>
				</div>
			</div>
		</div>
	</footer>
</div>