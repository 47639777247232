/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./expedient.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/ui/hovered_sidebar/hovered_sidebar.component.ngfactory";
import * as i3 from "../../../shared/ui/hovered_sidebar/hovered_sidebar.component";
import * as i4 from "@angular/router";
import * as i5 from "../new_consult/consult-minimized-header/consult-minimized-header.component.ngfactory";
import * as i6 from "../new_consult/consult-minimized-header/consult-minimized-header.component";
import * as i7 from "../../../services/app.service";
import * as i8 from "../new_consult/general/consult.service";
import * as i9 from "./expedient.component";
import * as i10 from "../../../services/patient-service.service";
import * as i11 from "../../../services/page.service";
var styles_ExpedientComponent = [i0.styles];
var RenderType_ExpedientComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExpedientComponent, data: {} });
export { RenderType_ExpedientComponent as RenderType_ExpedientComponent };
export function View_ExpedientComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { chatModal: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "unimed-left-sidebar"]], [[2, "no-scroll", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "container-fluid px-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-hovered_sidebar", [], null, null, null, i2.View_HoveredSidebarComponent_0, i2.RenderType_HoveredSidebarComponent)), i1.ɵdid(6, 49152, null, 0, i3.HoveredSidebarComponent, [], { config: [0, "config"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "hovered_sidebar-content"]], [[2, "no-scroll", null]], null, null, null, null)), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(9, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-consult-minimized-header", [], null, null, null, i5.View_ConsultMinimizedHeaderComponent_0, i5.RenderType_ConsultMinimizedHeaderComponent)), i1.ɵdid(11, 245760, null, 0, i6.ConsultMinimizedHeaderComponent, [i7.AppService, i8.ConsultGeneralService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.hovered_sidebar; _ck(_v, 6, 0, currVal_1); _ck(_v, 9, 0); _ck(_v, 11, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.page_state.sidebar_3.scroll; _ck(_v, 1, 0, currVal_0); var currVal_2 = !_co.page_state.sidebar_3.scroll; _ck(_v, 7, 0, currVal_2); }); }
export function View_ExpedientComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-expedient", [], null, null, null, View_ExpedientComponent_0, RenderType_ExpedientComponent)), i1.ɵdid(1, 245760, null, 0, i9.ExpedientComponent, [i7.AppService, i10.PatientService, i11.PageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExpedientComponentNgFactory = i1.ɵccf("app-expedient", i9.ExpedientComponent, View_ExpedientComponent_Host_0, {}, {}, []);
export { ExpedientComponentNgFactory as ExpedientComponentNgFactory };
