import { Routes } from '@angular/router';
const ɵ0 = () => import("./tutors/tutors.module.ngfactory").then(m => m.RelationsTutorsModuleNgFactory), ɵ1 = () => import("./dependants/dependants.module.ngfactory").then(m => m.RelationsDependantsModuleNgFactory);
const routes = [
    {
        path: '',
        redirectTo: 'tutores',
        pathMatch: 'prefix'
    },
    {
        path: 'tutores',
        loadChildren: ɵ0
    },
    {
        path: 'dependientes',
        loadChildren: ɵ1
    },
];
export class RelationsModule {
}
export { ɵ0, ɵ1 };
