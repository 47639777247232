import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { SweelService } from 'src/app/shared/services/sweel.service';
import { FormRenderComponent, form_type } from 'src/app/shared/ui/form_render/form_render.component';
import { AES } from 'crypto-ts';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shedule-modal-date',
  templateUrl: './shedule-modal-date.component.html',
  styleUrls: ['./shedule-modal-date.component.scss']
})
export class SheduleModalDateComponent implements OnInit {
  @Input() data = {
    id_patient  : "",
    description : "", 
    fecha_date  : "", 
    hour_date   : "",
    duration    : "",
    id_date     : "",
    paciente    : "",
  };

  
  @ViewChild('form_ref', { static: false }) form_ref: FormRenderComponent;
  public form_config: form_type;
  constructor( private endpoint: AppService, private toastr: ToastrService, private pageService: PageService,
     private patientService: PatientService, private sweel : SweelService ) { }

  ngOnInit() {
    this.form_configs();
  }

  form_configs() {
    this.form_config = {
      submit: () => this.submit(),
      rows: [
        {
          class: "row",
          cols: [
            {
              name: "description",
              type: "textarea",
              label: "Descripción",
              placeholder: () => "",
              col_class: "col-md-12",
              rows: 2.5,
              validators: {
                required: {
                  value: () => true
                },
                maxlength: {
                  value: () => 100
                }
              },
              disabled: () => false,
            },
            {
              name: "fecha_date",
              type: "calendar",
              label: "Fecha",
              placeholder: () => "#######",
              col_class: "col-md-6",
              validators: {
                required: {
                  value: () => true
                },
              },
              disabled: () => false,
            },
            {
              name: "hour_date",
              type: "text",
              label: "Hora",
              placeholder: () => "",
              col_class: "col-md-6",
              validators: {
                required: {
                  value: () => true
                },
                maxlength: {
                  value: () => 500
                }
              },
              disabled: () => false,
            },
            {
              name: "duration",
              type: "text",
              label: "Duración",
              placeholder: () => "",
              col_class: "col-md-6",
              validators: {
                required: {
                  value: () => true
                },
                maxlength: {
                  value: () => 500
                }
              },
              disabled: () => false,
            },
            
          ],
        },
        {
          class: "row",
          cols: [
            {
              type: "buttons",
              col_class: "col-12",
              buttons: [
                {
                  class: "btn btn-sm btn-outline-danger",
                  label: "Cancelar",
                  type: "button",
                  icon: {
                    class: "fa-duotone fa-times",
                    position: 'right',
                  },
                  disabled: () => false,
                  click: () => this.close()
                },
                {
                  class: "btn btn-sm btn-primary ml-auto",
                  label: "Actualizar Cita",
                  type: "submit",
                  icon: {
                    class: "fa-duotone fa-check",
                    position: 'right',
                  },
                  disabled: () => this.form_ref ? !this.form_ref.valid() : false,
                },
              ]
            },
          ],
        },
      ]
    };
  }

  submit() {
    this.endpoint.update_dates(this.data).subscribe(
      data => {
        this.toastr.success(data["message"], data["title"]);
        this.close();
      },
      error => {
        this.toastr.error(
          error.error.message ? error.error.message : error.message,
          error.error.title ? error.error.title : error.status + ''
        );
      }
    );
  }

  delete_date(){
    this.sweel.confirm("Eliminar Cita", "Por favor confirme que desea eliminar la cita de  <code>" + this.data.paciente + "</code>", "warning").then(
      res => {
        if (res) {
          this.delete(this.data.id_date);
        }
      }
    )
  }

  change_page() {
    this.patientService.change_patient_id(this.data.id_patient);
    localStorage.setItem("id_date",this.data.id_date);
    localStorage.setItem("detail", AES.encrypt(this.data.description, environment.secure_key).toString());
    this.pageService.closeModal("");
    this.endpoint.redirect_to("/expediente/nueva-consulta/general/general")
  }

  delete(id ) {
    let params = {
      id : id
    }
    this.endpoint.delete_dates( params ).subscribe(
      res =>{
        if ( res ){
          this.toastr.info("Operación Exitosa", "Cita eliminada correctamente");
          this.pageService.closeModal("");
        }
      }
    )
  }
  close() {
    this.pageService.closeModal("");
  }
}
