<div
     class="hovered_sidebar-menu"
     [class.lock_expanded]="config.lock_expanded"
     [class.style_shadowbox]="config.style == 'shadowbox'"
     [class.style_border]="config.style == 'border'"
     [class.style_noborder]="config.style == 'no-border'"
     *ngIf="!!config">
    <div class="hovered_sidebar-menu-container">
        <div class="hovered_sidebar-menu-navigation">
            <ul>
                <ng-container *ngFor="let item of config.list">
                    <ng-container *ngIf="item.show ? item.show() : true">
                        <li
                            *ngIf="!item.click"
                            [routerLinkActive]="['active']"
                            [routerLink]="item.url()"
                            [routerLinkActiveOptions]="{
                            exact: item.exact && item.exact() ? true : false
                        }">
                            <a>
                                <span class="icon"><i [class]="item?.icon()"></i></span>
                                <span class="label">{{ item?.name() }}</span>
                            </a>
                        </li>
                        <li *ngIf="item.click" (click)="item.click()" [class.active]="item.active ? item.active() : false">
                            <a>
                                <span class="icon"><i [class]="item?.icon()"></i></span>
                                <span class="label">{{ item?.name() }}</span>
                            </a>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </div>
    </div>
</div>

<app-overlay></app-overlay>