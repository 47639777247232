<ng-template #contentTemplate>
    <div id="sidebar-menu" class="unimed-left-sidebar mb-2">
        <ul class="metismenu" id="side-menu" #sideMenu>

            <!-- <li>
                    <a class="waves-effect menu-item" routerLink="/inicio" [routerLinkActive]="['active']" title="Inicio">
                        <h4 style="font-variant: small-caps; white-space: nowrap;" class="my-0">
                            <i class="fa-duotone fa-home my-auto" [class.ml-1]="page_state.sidebar_1.expand"></i>
                            <span class="mr-auto my-auto">inicio</span>
                        </h4>
                    </a>
                </li> -->
            <!-- <li *ngIf="!(patient_data && patient_data.loaded) || !stored_patient_id" [class.disabled]="!(patient_data && patient_data.loaded) || !stored_patient_id">
                <a class="waves-effect menu-item" title="Expediente">
                    <h4 style="font-variant: small-caps; white-space: nowrap;" class="my-0">
                        <i class="fa-duotone fa-id-card-alt my-auto" [class.ml-1]="page_state.sidebar_1.expand"></i>
                        <span class="mr-auto my-auto">expediente</span>
                    </h4>
                </a>
            </li> -->
            <li *ngIf="patient_data && patient_data.loaded && stored_patient_id">
                <a (click)="validate()" class="waves-effect menu-item" [routerLink]="'/expediente'" [routerLinkActive]="['active']" title="Expediente" [title]="patient_data?.general?.firstname+' '+patient_data?.general?.lastname">
                    <h4 style="font-variant: small-caps; white-space: nowrap;" class="my-0">
                        <!-- <i class="fa-duotone fa-id-card-alt my-auto" [class.ml-1]="page_state.sidebar_1.expand"></i> -->
                        <avatar [class.ml-1]="page_state.sidebar_1.expand" [name]="patient_data?.general?.firstname" [image]="patient_data?.general?.image" [configs]="avatar_configs"></avatar>
                        <span class="mr-auto my-auto">expediente</span>
                    </h4>
                </a>
            </li>
            <li>
                <a (click)="validate()" class="waves-effect menu-item" routerLink="/mis_pacientes" [routerLinkActive]="['active']" title="Mis pacientes">
                    <h4 style="font-variant: small-caps; white-space: nowrap;" class="my-0">
                        <i class="fa-duotone fa-user-friends my-auto" [class.ml-1]="page_state.sidebar_1.expand"></i>
                        <span class="mr-auto my-auto">mis pacientes</span>
                    </h4>
                </a>
            </li>
            <li>
                <a class="waves-effect menu-item" routerLink="/mis_consultas" [routerLinkActive]="['active']" title="Mis consultas">
                    <h4 style="font-variant: small-caps; white-space: nowrap;" class="my-0">
                        <i class="fa-duotone fa-calendar-check my-auto" [class.ml-1]="page_state.sidebar_1.expand"></i>
                        <span class="mr-auto my-auto">mis consultas</span>
                    </h4>
                </a>
            </li>
            <!-- <li>
                <a class="waves-effect menu-item" routerLink="/agenda" [routerLinkActive]="['active']" title="Agenda">
                    <h4 style="font-variant: small-caps; white-space: nowrap;" class="my-0">
                        <i class="fa-duotone fa-calendar my-auto" [class.ml-1]="page_state.sidebar_1.expand"></i>
                        <span class="mr-auto my-auto">agenda</span>
                    </h4>
                </a>
            </li> -->
        </ul>
    </div>

    <div class="clearfix"></div>
</ng-template>

<ng-template #accountTemplate>
    <div id="sidebar-menu" class="unimed-left-sidebar my-2">
        <ul class="metismenu" id="side-menu" #sideMenu>
            <li>
                <a class="waves-effect menu-item" routerLink="/perfil/" [routerLinkActive]="['active']" title="Perfil">
                    <h4 style="font-variant: small-caps; white-space: nowrap;" class="my-0">
                        <i class="fa-duotone fa-user my-auto" [class.ml-1]="page_state.sidebar_1.expand"></i>
                        <span class="mr-auto my-auto">perfil</span>
                    </h4>
                </a>
            </li>
            <li>
                <a class="waves-effect menu-item" routerLink="/configuraciones/" [routerLinkActive]="['active']" title="Configuraciones">
                    <h4 style="font-variant: small-caps; white-space: nowrap;" class="my-0">
                        <i class="fa-duotone fa-gear my-auto" [class.ml-1]="page_state.sidebar_1.expand"></i>
                        <span class="mr-auto my-auto">configuraciones</span>
                    </h4>
                </a>
            </li>
        </ul>
    </div>

    <div class="clearfix"></div>
</ng-template>

<div *ngIf="page_state.sidebar_1.show" class="left-side-menu" style="position: fixed; overflow-y: auto; overflow-x: hidden;" [class.searching]="page_state.searching">

    <div class="slimscroll-menu" *ngIf="page_state.sidebar_1.expand">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        <ng-container *ngTemplateOutlet="accountTemplate"></ng-container>
    </div>

    <div class="slimscroll-menu" *ngIf="!page_state.sidebar_1.expand">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        <ng-container *ngTemplateOutlet="accountTemplate"></ng-container>
    </div>

</div>