<div class="brief-items container px-2" *ngIf="patient_state.contacts">
    <div class="row">
        <div class="col-12 header py-2" (click)="show = !show">
            <div class="d-flex">
                <span class="d-flex title my-auto">
                    contactos de emergencia
                    <div routerLink="/expediente/contactos/emergencia" *ngIf="patient_state.contacts.emergency && patient_state.contacts.emergency.length > 0" class="noti my-auto ml-1">{{patient_state.contacts.emergency_total}}</div>
                </span>
                <i class="fa-duotone fa-angle-up fa-lg my-auto ml-auto icon" [class.fa-angle-up]="show" [class.fa-angle-down]="!show"></i>
            </div>
        </div>
    </div>
    <div class="row" [class.d-local-none]="!show">
        <ng-container *ngIf="patient_state.contacts.emergency && patient_state.contacts.emergency.length > 0">
            <div class="col-12" *ngFor="let item of patient_state.contacts.emergency">
                <div class="d-flex flex-column">
                    <span class="level-1">{{item.firstname | names}} {{item.lastname | names}}</span>

                    <div class="level-2" style="font-variant: small-caps;">
                        {{item.relationship_name | lowercase}}
                    </div>
                    <a class="level-2" [href]="'tel:' + item.phone" target="_blank">
                        {{item.phone}}
                    </a>
                    <a class="level-2" [href]="'mailto:' + item.email" target="_blank">
                        {{item.email | lowercase}}
                    </a>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex my-1 ">
                    <a *ngIf="!show_more" class="more" routerLink="/expediente/contactos/emergencia">ver más...</a>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="patient_state.contacts.emergency && patient_state.contacts.emergency.length < 1">
            <div class="col-12">
                <h6>No hay contactos de emergencia</h6>
            </div>
        </ng-container>
    </div>
    <div class="row">
        <hr class="my-0 col-md-11 text-center">
    </div>
</div>