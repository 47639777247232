import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
import { PageService } from 'src/app/services/page.service';
import { AppService } from 'src/app/services/app.service';
import { ExpedientService } from 'src/app/services/expedient.service';
import Swal from 'sweetalert2';
import { SocketService } from 'src/app/services/socket.service';
import { AlertService } from 'src/app/shared/ui/alert_service/alert.service';
import { CatalogsService } from 'src/app/services/catalogs.service';
import { NewPatientService } from 'src/app/services/new_patient.service';
import { PatientService } from 'src/app/services/patient-service.service';

export interface TutorsCatalogs {
  countries: {
    id: any,
    name: string
  }[]
};

export interface TutorsForms {
  tutors: FormRenderComponent
};

export interface TutorsState {
  id: any,
  id_user: any,
  id_dependant: any,
  id_country: any,
  email: string,

  identification: string,
  status?: string,
  accepted: any,

  // PHONE VERIFY
  phone: string;
  v_phone: string;
  verified_phone: number;
  phoneMessageSid: string;

  firstname: any,
  lastname: any,
  id_relationship: any,
  id_gender: any,

  loading: boolean,
  submitted: boolean,
  timestamp: Date,
};

export interface ListState {
  list: TutorsState[],
  count: number,
  skip: number,
  loaded: boolean
};

@Injectable({ providedIn: 'root' })
export class TutorsService {

  // FORMS
  public forms: TutorsForms;
  private forms_state: BehaviorSubject<TutorsForms>;
  public obv_forms: Observable<TutorsForms>;

  // TUTORS
  private current_state: TutorsState;
  private module_state: BehaviorSubject<TutorsState>;
  public current: Observable<TutorsState>;

  // LIST
  private list: ListState;
  private list_state: BehaviorSubject<ListState>;
  public obv_list: Observable<ListState>;

  private store_list_name = "unimed-expedient-relations-tutors-list";
  private store_name = "unimed-expedient-relations-tutors";
  public current_filters: any;
  public catalogs: TutorsCatalogs;

  constructor(
    private endpoint: AppService,
    private toastr: ToastrService,
    private pageService: PageService,
    private socketService: SocketService,
    private expedientService: ExpedientService,
    private patientService: PatientService,
    private alertService: AlertService,
    private catalogsService: CatalogsService,
    private newPatientService: NewPatientService
  ) {
    this.init_socket();
  }

  // LIST FUNCTIONS
  init_list() {
    this.expedientService.set_expedient_service({ store_url: this.store_list_name, submit: (callback) => this.submit_stored(callback), validate: (callback) => this.validate_stored(callback) }, "relations", "tutors");
    this.clean_list();
    this.list_next();
  }

  reload_list(data: any) {
    if (this.expedientService.state.id_user) {
      this.get_list(data);
    } else {
      this.get_stored_list();
    }
  }

  get_list(data: any) {
    this.current_filters = data;
    let load = {
      id_user: this.expedientService.state.id_user,
      ...data
    };
    this.endpoint.expedient_relations_tutors_get_tutors(load).subscribe(
      (data: ListState) => this.list = data,
      error => console.error(error),
      () => {
        this.list_next();
      }
    );
  }

  get_stored_list() {
    setTimeout(() => {
      let stored = localStorage.getItem(this.store_list_name);

      if (stored) {
        this.list = JSON.parse(stored) as ListState;
      } else {
        this.clean_list();
        this.list.loaded = true;
        this.save_list();
      }

      this.list_next();
    }, 1);
  }

  list_next() {
    if (this.list_state)
      this.list_state.next(this.list);
    else {
      this.list_state = new BehaviorSubject(this.list);
      this.obv_list = this.list_state.asObservable();
    }
  }

  clean_list() {
    return this.list = {
      list: [],
      count: 0,
      skip: 0,
      loaded: false
    };
  }

  save_list() {
    localStorage.setItem(this.store_list_name, JSON.stringify(this.list));
  }
  // EVENT FUNCTIONS

  init(tutors: TutorsState = null) {
    if (tutors) {
      this.current_state = tutors;
      this.current_state.loading = false;
      this.current_state.submitted = false;
      this.current_state.timestamp = new Date;
    }
    else this.internal_clean();
    this.next();

    this.forms = {
      tutors: null
    };

    this.forms_state = new BehaviorSubject(this.forms);
    this.obv_forms = this.forms_state.asObservable();

    this.catalogs = {
      countries: []
    };
    this.reload_catalogs();
  }

  init_socket() {
    this.socketService.getTutorPhoneVerify().subscribe(
      (data: any) => {
        if (this.current_state.phoneMessageSid === data.sid) {
          let relationship = this.catalogsService.catalogs.relationships.find(v => v.id == this.current_state.id_relationship);
          if (data.message === "Sí, confirmo") {
            this.current_state.verified_phone = 1;
            this.current_state.v_phone = this.current_state.phone;
            if (!this.expedientService.state.id_user) {
              this.alertService.alert_success("¡Listo!", `Has añadido a ${this.current_state.firstname} ${this.current_state.lastname} como ${relationship ? relationship.name : ""} de ${this.newPatientService.current_state.general.firstname}.`);
            } else {
              this.alertService.alert_success("¡Listo!", `Has añadido a ${this.current_state.firstname} ${this.current_state.lastname} como ${relationship ? relationship.name : ""} de ${this.patientService.patient_data.general.firstname}.`);
            }
            this.submit();
          } else if (data.message === "No confirmo") {
            this.current_state.verified_phone = 2;
            if (!this.expedientService.state.id_user) {
              this.alertService.alert_error("¡Oh no!", `Has rechadado a ${this.current_state.firstname} ${this.current_state.lastname} como ${relationship ? relationship.name : ""} de ${this.newPatientService.current_state.general.firstname}.`);
            } else {
              this.alertService.alert_error("¡Oh no!", `Has rechadado a ${this.current_state.firstname} ${this.current_state.lastname} como ${relationship ? relationship.name : ""} de ${this.patientService.patient_data.general.firstname}.`);
            }
          }
        }
      },
      error => {
        console.error(error)
      }
    );
    // this.socketService.getUserEmailVerify().subscribe(
    //     (data: any) => {
    //         if (this.current_state.emailToken === data.token) {
    //             this.current_state.verified_email = 1;
    //             this.current_state.v_email = this.current_state.email;
    //             this.alertService.alert_success("¡Listo!", "Has verificado tu correo electrónico.");
    //         }
    //     },
    //     error => {
    //         console.error(error)
    //     }
    // );
  }

  reload_catalogs() {
    this.endpoint.new_patient_catalogs().subscribe(
      data => this.catalogs = data,
      error => console.error(error)
    );
  }

  get_current_state() {
    return this.current_state;
  }

  refresh_forms() {
    this.forms_state.next(this.forms);
  }

  next() {
    if (this.module_state)
      this.module_state.next(this.current_state);
    else {
      this.module_state = new BehaviorSubject(this.current_state);
      this.current = this.module_state.asObservable();
    }
  }

  valid() {
    return !this.current_state.loading &&
      this.forms && this.forms.tutors && this.forms.tutors.valid ? this.forms.tutors.valid() : false
  }

  set_form(form: { name: string, module: FormRenderComponent }) {
    this.forms[form.name] = form.module;
  }

  save() {
    localStorage.setItem(this.store_name, JSON.stringify(this.current_state));
  }

  submit(callback = (callback_data?: any) => { }) {
    // if (!(this.forms && this.forms.tutors && this.forms.tutors.valid()) || this.current_state.loading) { callback(); return false; }

    this.current_state.id_dependant = this.expedientService.state.id_user;
    this.current_state.submitted = true;
    this.current_state.loading = true;
    this.module_state.next(this.current_state);

    if (this.expedientService.state.id_user) {
      if (this.expedientService.state.method == "insert") {
        this.insert(callback);
      } else {
        this.update(callback);
      }
    } else {
      if (this.expedientService.state.method == "insert") {
        this.insert_store(callback);
      } else {
        this.update_store(callback);
      }
    }
  }

  insert(callback = (callback_data?: any) => { }) {
    this.endpoint.expedient_relations_tutors_insert_tutors(this.current_state).subscribe(
      data => {
        if (this.expedientService.state.version == "expedient") {
          localStorage.removeItem("inExpedientRelationsTutors");
          this.toastr.success(data["message"], data["title"])

          this.current_state.loading = false;
          this.module_state.next(this.current_state);
          if (this.forms.tutors) this.forms.tutors.unsubmit();

          this.expedientService.update_global();
          this.reload_list(this.current_filters);
          this.close();
          this.clean();
          callback(data);
        }
        else if (this.expedientService.state.version == "new_patient") {
          callback(data);
        }
      },
      error => {
        console.error(error)
        this.toastr.error(
          error.error && error.error.message ? error.error.message : error.message,
          error.error && error.error.title ? error.error.title : error.status + ''
        );
        this.current_state.loading = false;
        this.module_state.next(this.current_state);
      }
    )
  }

  insert_bulk(callback = (callback_data?: any) => { }) {
    let load = {
      tutors: this.list.list,
      id_patient: this.expedientService.state.id_user
    };
    this.endpoint.expedient_relations_tutors_insert_tutors_bulk(load).subscribe(
      data => {
        if (this.expedientService.state.version == "expedient") {
          localStorage.removeItem("inExpedientRelationsTutors");
          this.toastr.success(data["message"], data["title"])

          this.current_state.loading = false;
          this.module_state.next(this.current_state);
          if (this.forms.tutors) this.forms.tutors.unsubmit();

          this.expedientService.update_global();
          this.reload_list(this.current_filters);
          this.close();
          this.clean();
          callback(data);
        }
        else if (this.expedientService.state.version == "new_patient") {
          callback({ list: this.list });
        }
      },
      error => {
        console.error(error)
        this.toastr.error(
          error.error && error.error.message ? error.error.message : error.message,
          error.error && error.error.title ? error.error.title : error.status + ''
        );
        this.current_state.loading = false;
        this.module_state.next(this.current_state);
      }
    )
  }

  insert_store(callback = (callback_data?: any) => { }) {
    this.current_state.id = new Date().getTime();
    this.current_state.status = "Verificado";
    this.list.list.push(this.current_state);
    callback();
    this.save_list();
    this.list_next();
    this.close();
    this.clean();
  }

  update(callback = (callback_data?: any) => { }) {
    this.endpoint.expedient_relations_tutors_update_tutors(this.current_state).subscribe(
      data => {
        localStorage.removeItem("inExpedientRelationsTutors");
        this.toastr.success(data["message"], data["title"]);

        this.current_state.loading = false;
        this.module_state.next(this.current_state);
        if (this.forms.tutors) this.forms.tutors.unsubmit();

        this.expedientService.update_global();
        this.reload_list(this.current_filters);
        this.close();
        this.clean();

        callback();
      },
      error => {
        console.error(error)
        this.toastr.error(
          error.error && error.error.message ? error.error.message : error.message,
          error.error && error.error.title ? error.error.title : error.status + ''
        );
        this.current_state.loading = false;
        this.module_state.next(this.current_state);
      }
    );
  }

  update_store(callback = (callback_data?: any) => { }) {
    let index = this.list.list.findIndex(value => value.id == this.current_state.id);
    this.list.list[index] = this.current_state;
    callback();
    this.save_list();
    this.list_next();
    this.close();
    this.clean();
  }

  delete() {
    if (this.expedientService.state.id_user) {
      this._delete();
    } else {
      this._delete_store();
    }
  }

  private _delete() {
    let load = {
      id: this.current_state.id,
      id_user: this.current_state.id_user,
      id_dependant: this.current_state.id_dependant,
      accepted: this.current_state.accepted,
    };
    this.endpoint.expedient_relations_tutors_delete_tutors(load).subscribe(
      data => {
        this.toastr.success(data["message"], data["title"]);

        this.expedientService.update_global();
        this.reload_list(this.current_filters);
        this.close();
      },
      error => {
        this.toastr.error(
          error.error.message ? error.error.message : error.message,
          error.error.title ? error.error.title : error.status + ''
        );
      }
    );
  }

  private _delete_store() {
    let index = this.list.list.findIndex(value => value.id == this.current_state.id);
    this.list.list.splice(index, 1);
    this.save_list();
    this.list_next();
  }

  confirm() {
    let load = {
      id: this.current_state.id
    };
    this.endpoint.expedient_relations_dependants_confirm_dependants(load).subscribe(
      data => {
        this.toastr.success(data["message"], data["title"]);

        this.expedientService.update_global();
        this.reload_list(this.current_filters);
        this.close();
      },
      error => {
        this.toastr.error(
          error.error.message ? error.error.message : error.message,
          error.error.title ? error.error.title : error.status + ''
        );
      }
    );
  }

  cancel(callback = (callback_data?: any) => { }) {
    Swal.fire({
      title: "Cancelar Formulario",
      html: "¿Está seguro de cancelar el llenado del formulario actual?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FDB915',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, cancelar',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value == true) {
        localStorage.removeItem("inExpedientGeneral");
        this.close();
        callback();
      }
    });
  }

  close() {
    this.pageService.closeModal(true);
  }

  clean() {
    this.internal_clean();
    this.module_state.next(this.current_state);
    this.save();
  }

  internal_clean() {
    this.current_state = {
      id: null,
      id_user: null,
      id_dependant: null,
      id_country: "97",
      email: "",
      identification: "",
      accepted: 0,

      firstname: "",
      lastname: "",
      id_relationship: null,
      id_gender: 1,

      phone: "",
      v_phone: "",
      phoneMessageSid: "",
      verified_phone: 2,

      loading: false,
      submitted: false,
      timestamp: new Date(),
    };
  }

  submit_stored(callback = (callback_data?: any) => { }, step: number = 0) {
    if (this.list.list.length > 0) {
      this.insert_bulk(callback);
    } else {
      callback({ list: this.list });
    }
  }

  validate_stored(callback = (callback_data?: any) => { }) {
    callback({ list: this.list });
  }

  validate_tutor_phone(phone, callback) {
    let load = {
      phone: phone
    };
    this.endpoint.expedient_relations_tutors_validate_phone(load).subscribe(
      data => {
        callback(data);
      },
      error => {
        this.toastr.error(
          error.error.message ? error.error.message : error.message,
          error.error.title ? error.error.title : error.status + ''
        );
      }
    );
  }

  verify_whatsapp(load, callback?) {
    Swal.fire({
      title: "Verificación vía Whatsapp",
      html: `La verificación del tutor fue enviada por Whatsapp al <strong>${this.current_state.phone}</strong>`,
      showCloseButton: true,
      onBeforeOpen: () => {
        Swal.showLoading();

        this.endpoint.whatsapp_send_send_new_tutor_request(load).subscribe(
          data => {
            this.current_state.phoneMessageSid = data;
          },
          error => {
            console.error(error);
            this.toastr.error(
              error.error && error.error.message ? error.error.message : error.message,
              error.error && error.error.title ? error.error.title : error.status + ""
            );
          }
        );
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
    return;
  }

}