import { BehaviorSubject } from 'rxjs';
import { AppService } from './app.service';
import { PageService } from './page.service';
import * as i0 from "@angular/core";
import * as i1 from "./app.service";
import * as i2 from "./page.service";
export class PatientService {
    constructor(endpoint, pageService) {
        this.endpoint = endpoint;
        this.pageService = pageService;
        this.patient_data_observable = new BehaviorSubject(null);
        this.patient_data_subscription = this.patient_data_observable.asObservable();
    }
    init(callback = (data) => { }) {
        var stored = this.endpoint.get_patient_id();
        if (stored) {
            this.change_patient_id(stored);
        }
        else {
            this.clean_patient();
        }
        this.load_patient_data(callback);
    }
    clean_patient() {
        this.patient_data = {
            general: {
                id: "",
                id_user: "",
                firstname: "",
                lastname: "",
                id_gender: "",
                birthdate: "",
                age: "",
                age_month: "",
                age_day: "",
                birthplace: "",
                id_department: "",
                id_town: "",
                address: "",
                phone: "",
                id_education: "",
                identification: "",
                image: "",
                email: ""
            },
            background: {
                allergy_total: 0,
                allergy: [],
                pathology_total: 0,
                pathology: []
            },
            contacts: {
                doctor_total: 0,
                doctor: [],
                emergency_total: 0,
                emergency: []
            },
            loaded: false
        };
        this.patient_data_observable.next(this.patient_data);
    }
    reset_patient() {
        this.patient_id = null;
        this.patient_data = null;
        this.endpoint.set_patient_id(this.patient_id);
        this.patient_data_observable.next(this.patient_data);
    }
    reset_superficial_patient() {
        this.patient_id = null;
        this.patient_data = null;
        this.patient_data_observable.next(this.patient_data);
    }
    change_patient_id(id) {
        this.reset_patient();
        this.patient_id = id;
        this.endpoint.set_patient_id(this.patient_id);
        this.load_patient_data();
    }
    change_superficial_patient_id(id) {
        this.reset_superficial_patient();
        this.patient_id = id;
        this.load_patient_data();
    }
    load_patient_data(callback = (data) => { }) {
        var load = {
            id: this.patient_id
        };
        var response;
        this.endpoint.get_patient_detail(load).subscribe(data => response = data, err => err, () => {
            response.loaded = true;
            this.patient_data = response;
            callback(response);
            this.patient_data_observable.next(this.patient_data);
            this.pageService.reload();
        });
    }
    get_patient_id() {
        return this.patient_id;
    }
    get_patient_data() {
        return this.patient_data;
    }
    update_patient_detail() {
        this.endpoint.update_patient_detail(this.patient_data).subscribe(data => this.load_patient_data(), err => err);
    }
    set_growth_curve(growth_curve) {
        if (this.patient_data) {
            this.patient_data.growth_curve = growth_curve;
            this.patient_data_observable.next(this.patient_data);
        }
    }
}
PatientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PatientService_Factory() { return new PatientService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.PageService)); }, token: PatientService, providedIn: "root" });
