import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormRenderComponent, form_type } from 'src/app/shared/ui/form_render/form_render.component';
import { LaboratoryService, LaboratoryState } from '../laboratory.service';
import { ExpedientMetadata, ExpedientService } from 'src/app/services/expedient.service';

@Component({
	selector: 'app-module-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy, AfterViewInit {
	private expedient_subscription: Subscription;
	public expedient_state: ExpedientMetadata;

	private laboratory_subscription: Subscription;
	public laboratory_state: LaboratoryState;

	@ViewChild('form_ref', { static: false }) form_ref: FormRenderComponent;
	public form_config: form_type;

	constructor(private moduleService: LaboratoryService, private expedientService: ExpedientService) {
	}

	ngOnInit() {
		this.expedient_subscription = this.expedientService.current.subscribe(
			state => {
				this.expedient_state = state;
				// this.moduleService.reload();
				this.form_configs();
			}
		);
		this.laboratory_subscription = this.moduleService.current.subscribe(
			state => this.laboratory_state = state
		);
		this.form_configs();
	}

	ngAfterViewInit() {
		if (this.laboratory_state) {
			this.moduleService.forms.laboratory = this.form_ref;
		}
	}

	ngOnDestroy() {
		this.expedient_subscription.unsubscribe();
		this.laboratory_subscription.unsubscribe();
	}

	form_configs() {
		this.form_config = {
			pretty_view: () => this.expedient_state.method == "show",
			disabled: () => this.expedient_state.method == "show",
			submit: (callback) => this.moduleService.submit(callback),
			rows: [
				{
					class: "row",
					cols: [
						{
							name: "id_exams_type",
							type: "select",
							label: "Tipo de Examen",
							placeholder: () => "- Seleccione -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							catalog: {
								list: () => this.moduleService.catalogs.exams_types,
								value: "id",
								text: "name"
							},
							disabled: () => false,
							change: () => {
								this.laboratory_state.id_exams_name = "";
								this.laboratory_state.id_exams_name_2 = "";
							}
						},
						{
							name: "id_exams_name",
							type: "select",
							label: "Nombre de Examen",
							placeholder: () => this.laboratory_state.id_exams_type ? "- Seleccione -": "- Seleccione un Tipo de Examen -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							catalog: {
								list: () => this.moduleService.catalogs.exams_names.filter(value => value.id_exams_type == this.laboratory_state.id_exams_type),
								value: "id",
								text: "name"
							},
							disabled: () => !this.laboratory_state.id_exams_type,
							change: () => {
								this.laboratory_state.id_exams_name_2 = "";
							}
						},
						{
							name: "id_exams_name_2",
							type: "select",
							label: "Nombre Secundario",
							placeholder: () => this.laboratory_state.id_exams_name ? "- Seleccione -" : "- Seleccione un Nombre de Examen -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							catalog: {
								list: () => this.moduleService.catalogs.exams_names_2.filter(value => value.id_exams_name == this.laboratory_state.id_exams_name),
								value: "id",
								text: "name"
							},
							disabled: () => !this.laboratory_state.id_exams_name,
							show: () => this.laboratory_state.id_exams_type == 2
						},
						{
							name: "observations",
							type: "textarea",
							label: "Observaciones",
							rows: 2.5,
							col_class: "col-12",
							validators: {
								required: {
									value: () => false
								},
								maxlength: {
									value: () => 500
								}
							},
							disabled: () => false,
						},
						{
							name: "realization_date",
							type: "calendar",
							label: "Fecha de Realización",
							placeholder: () => "##/##/####",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							disabled: () => false,
						}
					]
				},
				{
					class: "row",
					cols: [
						{
							type: "buttons",
							col_class: "col-12",
							buttons: [
								{
									class: "btn btn-sm btn-outline-danger",
									label: "Cancelar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									show: () => {
										return this.expedient_state.method == "update" || this.expedient_state.method == "insert";
									},
									click: () => {
										this.moduleService.cancel();
									},
								},
								{
									class: "btn btn-sm btn-outline-danger",
									label: "Cerrar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									show: () => {
										return this.expedient_state.method == "show";
									},
									click: () => {
										this.moduleService.close();
									},
								},
								{
									class: "btn btn-sm btn-success ml-auto",
									label: "Guardar",
									type: "submit",
									icon: {
										class: "fa-duotone fa-check",
										position: 'right',
									},
									show: () => {
										return this.expedient_state.method == "update" || this.expedient_state.method == "insert";
									},
									disabled: () => (this.form_ref ? !this.form_ref.valid() : false) || this.laboratory_state.loading,
								},
							]
						},
					],
				},
			]
		};
	}
}