<div class="brief-items container px-2">
    <div class="row">
        <div class="col-12 header py-2" (click)="show = !show">
            <div class="d-flex">
                <span class="d-flex title my-auto">
                    alergias
                    <div
                         routerLink="/expediente/antecedentes/patologicos"
                         *ngIf="patient_state.background.allergy && patient_state.background.allergy.length > 0"
                         class="noti my-auto ml-1">
                        {{patient_state.background.allergy_total}}
                    </div>
                </span>
                <i class="fa-duotone fa-angle-up fa-lg my-auto ml-auto icon" [class.fa-angle-up]="show" [class.fa-angle-down]="!show"></i>
            </div>
        </div>
    </div>
    <div class="row" [class.d-local-none]="!show">
        <ng-container *ngIf="patient_state.background.allergy && patient_state.background.allergy.length > 0">
            <div class="col-12" *ngFor="let item of patient_state.background.allergy">
                <div class="d-flex flex-column">
                    <span class="level-1">{{item.allergen | names}}</span>

                    <div class="d-flex level-2">
                        Nivel de reacción:
                        <div class="d-flex level-2-5 ml-auto">

                            <div class="allergy my-auto mx-1" [class.a-1]="item.id_allergies_reactions_level == 1" [class.a-2]="item.id_allergies_reactions_level == 2" [class.a-3]="item.id_allergies_reactions_level == 3" [class.a-4]="item.id_allergies_reactions_level == 4">
                                <i class="fa-solid fa-circle my-auto"></i>
                                <i class="fa-solid fa-circle my-auto"></i>
                                <i class="fa-solid fa-circle my-auto"></i>
                                <i class="fa-solid fa-circle my-auto"></i>
                                <div class="my-auto ml-1">{{ item.id_allergies_reactions_level | catalog: catalog() | lowercase}}</div>
                            </div>

                        </div>
                    </div>
                    <span class="d-flex level-2">
                        <div class="my-auto">Tipo de reacción:</div>
                        <div class="my-auto ml-auto"> {{item.allergies_reactions_type_name}}</div>
                    </span>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex my-1 ">
                    <a *ngIf="!show_more" class="more" routerLink="/expediente/antecedentes/alergicos">ver más...</a>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="patient_state.background.allergy && patient_state.background.allergy.length < 1">
            <div class="col-12">
                <h6>No hay antecedentes alérgicos</h6>
            </div>
        </ng-container>
    </div>
    <div class="row">
        <hr class="my-0 col-md-11 text-center">
    </div>
</div>