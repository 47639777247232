<div class="bg-light search_page">
    <div class="container-fluid">
        <form class="row" (submit)="get_patients()">
            <div class="col-12 mt-3 px-3">
                <div class="d-flex">
                    <div class="my-auto mr-3">
                        <a class="pointer" (click)="backClicked()"><i class="fa-duotone fa-arrow-left text-secondary" style="font-size: 2.5rem"></i></a>
                    </div>
                    <div class="d-flex search_bar_input px-2">
                        <div class="my-auto flex-grow-1" style="position: relative">
                            <div class="input-group-append" id="search-bar-div">
                                <span class="search-toogle py-3 pl-2">
                                    <i class="fa-duotone fa-search"></i>
                                    <input
                                           [(ngModel)]="filters.search_word"
                                           id="search_word"
                                           name="search_word"
                                           type="search"
                                           class="form-control-sm"
                                           (change)="change()"
                                           (input)="change()"
                                           (paste)="change()"
                                           (submit)="get_patients()"
                                           placeholder="Buscar por nombre o número de identificación"
                                           autocomplete="off" />
                                </span>
                            </div>
                        </div>

                        <div class="mx-2 my-auto">
                            <button role="submit" class="btn btn-primary btn-block btn-lg px-3">BUSCAR</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="row px-5 mt-3 mx-4 animated" *ngIf="loading">
            <div class="col-12 mb-2">
                <div class="d-flex py-5">
                    <div class="d-flex mx-auto">
                        <div class="spinner-grow my-auto mr-2" role="status"></div>
                        <h3 class="my-auto">Cargando...</h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="row my-3" *ngIf="!(data.length > 0) && !loading">
            <div class="col-12 px-4">
                <div class="data-row">
                    <div class="container-fluid ml-2 my-auto" style="height: fit-content; padding-left: 2.5rem;">
                        <div class="row my-auto">
                            <div class="col-12 text-center">
                                <span>No pudimos encontrar el paciente. <br>
                                    Por favor, verifica los datos o </span>
                                <button
                                        class="btn btn-dark-primary ml-1"
                                        routerLink="/nuevo_paciente">
                                    Crea un Paciente
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row my-3" *ngIf="data.length > 0 && !loading">
            <div class="col-12 px-4">
                <div class="data-row">
                    <div class="container-fluid ml-2 my-auto" style="height: fit-content; padding-left: 2.5rem;">
                        <div class="row my-auto">
                            <div class="col-3">Nombre</div>

                            <div class="col-2">Identidad</div>

                            <div class="col-2">Cuidad</div>

                            <div class="col-2">Edad</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row my-3" *ngIf="data.length > 0 && !loading">
            <div class="col-12 px-4" *ngFor="let item of data; let i = index">
                <!-- <div class="data-row" (click)="goto(item)"> -->
                <div class="data-row">
                    <avatar [name]="item?.firstname" [image]="item?.image" [configs]="avatar_configs"></avatar>

                    <div class="container-fluid ml-2 my-auto" style="height: fit-content">
                        <div class="row my-auto">
                            <div class="col-3">{{ item?.firstname }} {{ item?.lastname }}</div>

                            <div class="col-2">{{ censored_id(item?.identification, item.id_doctors_patients_level) }}</div>

                            <div class="col-2">{{ item?.town }}</div>

                            <div class="col-2">
                                {{
                                    item?.age > 0
                                        ? item?.age + " años"
                                        : item?.age_month > 0
                                        ? item?.age_month +
                                          " meses" +
                                          (item?.age_day > 0 ? ", " + item?.age_day + " días" : "")
                                        : item?.age_day > 0
                                        ? item?.age_day + " días"
                                        : "Recién nacido"
                                }}
                            </div>
                            <div class="col-3">
                                <div class="d-flex w-100">
                                    <div class="ml-auto"></div>
                                    <button
                                            class="btn btn-outline-info ml-1"
                                            *ngIf="item.id_doctors_patients_level"
                                            (click)="goto(item)">
                                        VER
                                    </button>
                                    <!-- <button
                                            class="btn btn-outline-primary ml-1"
                                            *ngIf="item.id_doctors_patients_level"
                                            (click)="new_consult(item)">
                                        NUEVA CONSULTA
                                    </button> -->
                                    <button
                                            class="btn btn-outline-dark-primary ml-1"
                                            *ngIf="!item.id_doctors_patients_level"
                                            (click)="pre_add_patient(item)">
                                        AÑADIR
                                    </button>
                                    <!-- <button class="btn btn-outline-danger ml-1" *ngIf="item.id_doctors_patients_level">CANCELAR SOLICITUD</button> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>