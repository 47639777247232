import { Component } from '@angular/core';
import { ExpedientService } from 'src/app/services/expedient.service';
import { LaboratoryService } from '../laboratory.service';

@Component({
  selector: 'app-exams_create_laboratory',
  templateUrl: './create_laboratory.component.html',
  styleUrls: ['./create_laboratory.component.scss']
})
export class CreateExamsLaboratoryComponent {

  constructor(private moduleService: LaboratoryService, private expedientService: ExpedientService) {
    this.expedientService.change_method("insert");
    this.moduleService.init();
  }

  close() {
    this.moduleService.cancel();
  }

}
