import { Pipe, PipeTransform } from '@angular/core';
import { PipesService } from 'src/app/services/pipes.service';

@Pipe({ name: 'names' })
export class Names implements PipeTransform {
    constructor(private pipesService: PipesService) { }

    transform(str: string): string {
        return this.pipesService.names(str);
    }
}