import { NgModule } from '@angular/core';

import { PipesModule } from 'src/app/shared/ui/pipes/pipes.module';
import { FormRenderModule } from 'src/app/shared/ui/form_render/form_render.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DatatableModule } from 'src/app/shared/ui/datatable/datatable.module';

import { RouterModule, Routes } from '@angular/router';
import { NgbAccordionModule, NgbAlertModule, NgbCollapseModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule, NgbModule, NgbPaginationModule, NgbPopoverModule, NgbProgressbarModule, NgbTabsetModule, NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';
import { UIModule } from 'src/app/shared/ui/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ToastrModule } from 'ngx-toastr';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { TextMaskModule } from 'angular2-text-mask';
import { ExamsLaboratoryComponent } from './laboratory.component';
import { FormComponent } from './form/form.component';
import { LaboratoryService } from './laboratory.service';
import { UpdateExamsLaboratoryComponent } from './update_laboratory/update_laboratory.component';
import { CreateExamsLaboratoryComponent } from './create_laboratory/create_laboratory.component';
import { ShowExamsLaboratoryComponent } from './show_laboratory/show_laboratory.component';

const routes: Routes = [
  {
    pathMatch: 'prefix',
    path: 'laboratorios',
    component: ExamsLaboratoryComponent
  }
];

@NgModule({
  declarations: [
    ExamsLaboratoryComponent,
    FormComponent,
    CreateExamsLaboratoryComponent,
    UpdateExamsLaboratoryComponent,
    ShowExamsLaboratoryComponent
  ],
  exports: [
    ExamsLaboratoryComponent
  ],
  imports: [
    PipesModule,
    FormRenderModule,
    DatatableModule,
    SharedModule,
    ClickOutsideModule,
    UIModule,
    FormsModule,
    NgbModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
    }),
    FileUploadModule,
    NgbDropdownModule,
    NgbTabsetModule,
    NgbAccordionModule,
    NgbCollapseModule,
    NgbModalModule,
    NgbProgressbarModule,
    NgbAlertModule,
    NgbToastModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbPaginationModule,
    TextMaskModule,
    RouterModule.forChild(routes)
  ],
  providers: [
    LaboratoryService
  ]
})
export class ExamsLaboratoryModule { }