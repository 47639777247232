import { CatalogsService } from "src/app/services/catalogs.service";
import { CustomClass } from "../custom-class";

export interface EducationsInterface {
    id: any;
    id_user: any;
    id_type: any;
    id_study_type: any;
    id_mode: any;
    id_honor: any;
    id_country: any;
    name: string;
    place: string;
    year: Date;
    description: string;
}

interface ToStringInterface {
    type: any;
    study_type: any;
    mode: any;
    honor: any;
    name: string;
    place: string;
    year: Date;
    description: string;
}

export class EducationsClass extends CustomClass implements EducationsInterface {
    public id: any;
    public id_user: any;
    public id_type: any;
    public id_study_type: any;
    public id_mode: any;
    public id_honor: any;
    public id_country: any;
    public name: string;
    public place: string;
    public year: Date;
    public description: string;

    constructor(private catalogsService: CatalogsService, data?: EducationsInterface) {
        super();
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: EducationsInterface): void {
        super.overwrite(data);
        this.id = data.id;
        this.id_user = data.id_user;
        this.id_type = data.id_type;
        this.id_study_type = data.id_study_type;
        this.id_mode = data.id_mode;
        this.id_honor = data.id_honor;
        this.id_country = data.id_country;
        this.name = data.name;
        this.place = data.place;
        this.year = data.year;
        this.description = data.description;
    }

    reset(): void {
        super.reset();
        this.id = null;
        this.id_user = null;
        this.id_type = 1;
        this.id_study_type = 1;
        this.id_mode = 1;
        this.id_honor = null;
        this.id_country = 97;
        this.name = "";
        this.place = "";
        this.year = new Date();
        this.description = "";
    }

    format(): EducationsInterface {
        return this as EducationsInterface;
    }

    valid(): boolean {
        return true;
    }

    to_string(): ToStringInterface {
        let type = this.catalogsService.catalogs.profiles_educations_types.find((v) => v.id == this.id);
        let study_type = this.catalogsService.catalogs.profiles_educations_study_types.find((v) => v.id == this.id);
        let mode = this.catalogsService.catalogs.profiles_educations_modes.find((v) => v.id == this.id);
        let honor = this.catalogsService.catalogs.profiles_educations_honors.find((v) => v.id == this.id);

        let data: ToStringInterface = {
            type: type ? type.name : "",
            study_type: study_type ? study_type.name : "",
            mode: mode ? mode.name : "",
            honor: honor ? honor.name : "",
            name: this.name,
            place: this.place,
            year: this.year,
            description: this.description,
        };
        return data as ToStringInterface;
    }

    raw(): EducationsInterface {
        return {
            id: this.id,
            id_user: this.id_user,
            id_type: this.id_type,
            id_study_type: this.id_study_type,
            id_mode: this.id_mode,
            id_honor: this.id_honor,
            id_country: this.id_country,
            name: this.name,
            place: this.place,
            year: this.year,
            description: this.description,
        };
    }
}
