import { AppService } from "src/app/services/app.service";
import { Component, Input } from "@angular/core";

@Component({
    selector: "avatar",
    templateUrl: "./avatar.component.html",
    styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent {
    public letter: string;
    public name: string;
    public image: string;

    constructor(private endpoint: AppService) {}

    @Input("name") set _name(name: string) {
        if(name) {
            this.name = name;
            this.letter = name.charAt(0).toLowerCase();
        }else {
            this.name = name;
            this.letter = "n";
        }
    }

    @Input("image") set _image(image: any) {
        if (image) {
            if (image.includes("data:image")) {
                this.image = image;
            } else {
                this.image = this.endpoint.get_file_endpoint(
                    "profile_images",
                    image
                );
            }
        }
    }

    @Input("configs") configs: {
        size?: string;
    };

    error_image() {
        this.image = null;
    }
}
