import { CatalogsService } from "src/app/services/catalogs.service";
import { CustomClass } from "../custom-class";

export interface ConnectionsInterface {
  id: any;
  id_user: any;
  id_join: any;
  username: any;
  id_gender: any;
  id_town: any;
  specialties: any;
  common: any;
  request: boolean;
  created_by: any;
  created_at: Date;
  is_sender: boolean;
  is_me: boolean;
  is_connection: boolean;
  
  firstname: string;
  lastname: string;
  image: any;
}

interface ToStringInterface extends ConnectionsInterface {}

export class ConnectionsClass
  extends CustomClass
  implements ConnectionsInterface
{
  public id: any;
  public id_user: any;
  public id_join: any;
  public username: any;
  public id_gender: any;
  public id_town: any;
  public specialties: any;
  public common: any;
  public request: boolean;
  public created_by: any;
  public created_at: Date;
  public is_sender: boolean;
  public is_me: boolean;
  public is_connection: boolean;
  public firstname: string;
  public lastname: string;
  public image: any;

  constructor(
    private catalogsService: CatalogsService,
    data?: ConnectionsInterface
  ) {
    super();
    if (data) this.overwrite(data);
    else this.reset();
  }

  overwrite(data: ConnectionsInterface): void {
    super.overwrite(data);
    this.id = data.id;
    this.id_user = data.id_user;
    this.id_join = data.id_join;
    this.username = data.username;
    this.id_gender = data.id_gender;
    this.id_town = data.id_town;
    this.specialties = data.specialties;
    this.common = data.common;
    this.request = data.request;
    this.created_by = data.created_by;
    this.created_at = data.created_at;
    this.is_sender = data.is_sender;
    this.is_me = data.is_me;
    this.is_connection = data.is_connection;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.image = data.image;
  }

  reset(): void {
    super.reset();
    this.id = null;
    this.id_user = null;
    this.id_join = null;
    this.username = "";
    this.id_gender = 1;
    this.id_town = null;
    this.specialties = null;
    this.common = 0;
    this.request = true;
    this.created_by = null;
    this.created_at = new Date();
    this.is_sender = false;
    this.is_me = false;
    this.is_connection = false;
    this.firstname = "";
    this.lastname = "";
    this.image = null;
  }

  format(): ConnectionsInterface {
    return this as ConnectionsInterface;
  }

  valid(): boolean {
    return true;
  }

  to_string(): ToStringInterface {
    let data: ToStringInterface = {
      id: this.id,
      id_user: this.id_user,
      id_join: this.id_join,
      username: this.username,
      id_gender: this.id_gender,
      id_town: this.id_town,
      specialties: this.specialties,
      common: this.common,
      request: this.request,
      created_by: this.created_by,
      created_at: this.created_at,
      is_sender: this.is_sender,
      is_me: this.is_me,
      is_connection: this.is_connection,
      firstname: this.firstname,
      lastname: this.lastname,
      image: this.image,
    };
    return data as ToStringInterface;
  }

  raw(): ConnectionsInterface {
    return {
      id: this.id,
      id_user: this.id_user,
      id_join: this.id_join,
      username: this.username,
      id_gender: this.id_gender,
      id_town: this.id_town,      
      specialties: this.specialties,
      common: this.common,
      request: this.request,
      created_by: this.created_by,
      created_at: this.created_at,
      is_sender: this.is_sender,
      is_me: this.is_me,
      is_connection: this.is_connection,
      firstname: this.firstname,
      lastname: this.lastname,
      image: this.image,
    };
  }
}
