import { Component } from '@angular/core';
import { TutorsService } from '../tutors.service';

@Component({
  selector: 'app-relations_update_tutors',
  templateUrl: './update_tutors.component.html',
  styleUrls: ['./update_tutors.component.scss']
})
export class UpdateRelationsTutorsComponent {

  constructor(private moduleService: TutorsService) {
  }

  close() {
    this.moduleService.cancel();
  }

}
