import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { ConsultGeneralService, ConsultMetadata } from '../general/consult.service';

@Component({
  selector: 'app-consult-minimized-header',
  templateUrl: './consult-minimized-header.component.html',
  styleUrls: ['./consult-minimized-header.component.scss']
})
export class ConsultMinimizedHeaderComponent implements OnInit, OnDestroy {
  private consult_subscription: Subscription;
  public consult_state: ConsultMetadata;

  constructor(private endpoint: AppService, private consultService: ConsultGeneralService) {
  }

  ngOnInit() {
    this.consult_subscription = this.consultService.current.subscribe(
      state => {
        this.consult_state = state;
      }
    );
  }

  ngOnDestroy() {
    this.consult_subscription.unsubscribe();
  }

  maximize() {
    this.endpoint.redirect_to("/expediente/nueva-consulta");
  }

  close() {
    this.consultService.remove_storage();
  }

  has_consult() {
    return this.consultService.get_storage();
  }
}