import { CustomInterface } from "./custom-class";

interface ToStringInterface {
    list: string[];
}

export class CustomListClass<T extends CustomInterface> {
    public list: T[];
    public loaded: boolean;
    public loading: boolean;
    public submitted: boolean;

    constructor(data?: T[]) {
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: T[]): void {
        this.list = data;
        this.loaded = true;
        this.loading = false;
        this.submitted = false;
    }

    get(i: number): T {
        return this.list[i];
    }

    add(item: T): void {
        this.list.push(item);
    }

    remove(i: number): void {
        this.list.splice(i, 1);
    }

    reset(): void {
        this.list = [];
        this.loaded = false;
        this.loading = true;
        this.submitted = false;
    }

    valid(): boolean {
        return true;
    }

    empty() {
        return this.list.length == 0;
    }

    to_string(): ToStringInterface {
        let data: ToStringInterface = { list: [] };
        data.list = this.list.map((v) => v.to_string());
        return data;
    }

    raw(): any[] {
        return this.list.map((t) => t.raw());
    }
}
