import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { NewPatientState } from 'src/app/services/new_patient.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { FormRenderComponent, form_type } from 'src/app/shared/ui/form_render/form_render.component';
import { CatalogsService } from 'src/app/services/catalogs.service';

@Component({
	selector: 'app-update-medicine',
	templateUrl: './update-medicine.component.html',
	styleUrls: ['./update-medicine.component.scss']
})
export class UpdateMedicineComponent implements OnInit {
	public new_patient_state: NewPatientState;
	public view: number;

	@Output("update") update: EventEmitter<any> = new EventEmitter;

	@Input() readonly: boolean = false;
	@Input() data = {
		id_patient: "",
		name: "",
		amount: "",
		id_presentation: "",
		concentration: "",
		id_concentration: "",
		id_way: "",
		id_frecuency: "",
		frecuency: "",
		id_days: "",
		days: ""
	};;

	@ViewChild('form_accident_ref', { static: false }) form_ref: FormRenderComponent;
	public form_config: form_type;

	constructor(private endpoint: AppService, private toastr: ToastrService, private patientServiceService: PatientService, private pageService: PageService, private catalogsService: CatalogsService) { }

	ngOnInit() {
		this.form_configs();
	}

	form_configs() {
		this.form_config = {
			submit: () => this.submit(),
			rows: [
				{
					class: "row",
					cols: [
						{
							name: "name",
							type: "name",
							label: "Nombre",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
								maxlength: {
									value: () => 45
								}
							},
							disabled: () => false,
						},
						{
							name: "amount",
							type: "number",
							label: "Cantidad",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							disabled: () => false,
						},
						{
							name: "id_presentation",
							type: "select",
							label: "Presentación",
							placeholder: () => "- Seleccione -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							catalog: {
								list: () => this.catalogsService.catalogs.medicines_presentations,
								value: "id",
								text: "name"
							},
							disabled: () => false,
						},
						{
							name: "concentration",
							type: "text",
							label: "Concentración",
							small: "",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
								maxlength: {
									value: () => 45
								},
							},
							disabled: () => false,
						},
						{
							name: "id_way",
							type: "select",
							label: "Vía",
							placeholder: () => "- Seleccione -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							catalog: {
								list: () => this.catalogsService.catalogs.medicines_ways,
								value: "id",
								text: "name"
							},
							disabled: () => false,
						},

						{
							name: "frecuency",
							type: "decimal",
							label: "Frecuencia",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
								maxlength: {
									value: () => 45
								}
							},
							filter: {
								name: "id_frecuency",
								list: () => this.catalogsService.catalogs.medicines_frecuencies,
								value: "id",
								text: "name",
								tooltip: "name"
							},
							disabled: () => false,
						},
						{
							name: "days",
							type: "number",
							label: "Duración",
							placeholder: () => this.data.id_days == "1" ? "1" : this.data.id_days == "4" ? "∞" : "##",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => !(this.data.id_days == "1" || this.data.id_days == "4")
								},
								maxlength: {
									value: () => 45
								}
							},
							filter: {
								name: "id_days",
								list: () => this.catalogsService.catalogs.medicines_days,
								value: "id",
								text: "name",
								tooltip: "name",
								change: () => {
									if (this.data.id_days == "1" || this.data.id_days == "4")
										this.data.days = "";
								}
							},
							disabled: () => this.data.id_days == "1" || this.data.id_days == "4",
						},
					]
				},
				{
					class: "row",
					cols: [
						{
							type: "buttons",
							col_class: "col-12",
							buttons: [
								{
									class: "btn btn-sm btn-danger",
									label: "Cancelar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'right',
									},
									disabled: () => false,
									click: () => this.close()
								},
								{
									class: "btn btn-sm btn-success ml-auto",
									label: "Agregar Medicina",
									type: "submit",
									icon: {
										class: "fa-duotone fa-check",
										position: 'right',
									},
									disabled: () => this.form_ref ? !this.form_ref.valid() : false,
								},
							]
						},
					],
				},
			]
		};
	}

	submit() {
		this.endpoint.update_medicines_prescription(this.data).subscribe(
			data => {
				this.toastr.success(data["message"], data["title"])
				this.update.emit();
				this.close();
			},
			error => {
				this.toastr.error(
					error.error.message ? error.error.message : error.message,
					error.error.title ? error.error.title : error.status + ''
				);
			}
		);
	}

	close() {
		this.pageService.closeModal("");
	}
}
