<div class="pc-layout">
    <div class="pc-header">
        <div class="pch-icons">
            <a class="fa-duotone fa-times fa-xl text-muted ml-3" title="Cerrar consulta" routerLink="/expediente/consultas"></a>
        </div>
    </div>
    <div class="pc-body">
        <div class="d-flex">
            <div class="d-flex flex-column flex-grow-1 mx-3">
                <div class="pcb-header">
                    <div class="pch-title">consulta médica</div>
                    <div class="pch-date"> {{ data?.consult?.created_at | shortDate }}</div>
                </div>

                <div class="pcb-patient">{{ data?.consult?.patient_name | names }}</div>
                <div class="pcb-box-trans pt-1 pl-0" style="white-space: pre-line;">
                    {{
                        data?.consult?.age > 0
                        ? data?.consult?.age + (data?.consult?.age > 1 ? " años" : " año")
                        : data?.consult?.age_month > 0
                        ? data?.consult?.age_month + (data?.consult?.age_month > 1 ? " meses" : " mes") + 
                        (data?.consult?.age_day > 0
                        ? ", " + data?.consult?.age_day + (data?.consult?.age_day > 1 ? " días" : " día") : "")
                        : data?.consult?.age_day > 0
                        ? data?.consult?.age_day + (data?.consult?.age_day > 1 ? " días" : " día")
                        : "Recién nacido"
                        }}
                </div>

                <div class="pcb-title-2 mt-3">MOTIVO DE CONSULTA</div>
                <div class="pcb-box-trans pt-1 pl-0" style="white-space: pre-line;">
                    {{data?.consult?.general?.reason}}
                    <ng-container *ngIf="!data?.consult?.general?.reason">
                        No hay motivo de consulta
                    </ng-container>
                </div>

            </div>

            <div class="pcb-doctor">
                <div class="d-flex flex-column my-auto mx-auto">
                    <a [routerLink]="'/perfil/' + data?.consult?.doctor_username" target="_blank" class="mx-auto">
                        <avatar [name]="data?.consult?.doctor_name" [image]="data?.consult?.doctor_image" [configs]="avatar_configs"></avatar>
                    </a>

                    <a [routerLink]="'/perfil/' + data?.consult?.doctor_username" target="_blank" class="pcb-name mt-2"> {{ data?.consult?.doctor_gender == 1 ? "DRA." : "DR." }} {{ data?.consult?.doctor_name | uppercase }}</a>
                    <a [routerLink]="'/perfil/' + data?.consult?.doctor_username" target="_blank" class="pcb-specialty">
                        {{ data?.consult?.doctor_specialty }}
                    </a>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-4">
                <div class="pcb-carrousel pcbc-show">
                    <div class="pcbch-bar"></div>
                    <div class="pcbc-header">
                        <div class="pcb-title">MEDIDAS</div>
                    </div>
                    <div class="pcbc-body">
                        <div class="row px-2">
                            <div class="col-6">
                                <div class="d-flex">
                                    <div class="pcb-indicator mx-auto">
                                        <div class="pcbi-icon">
                                            <i class="fa-duotone fa-weight-hanging text-warning"></i>
                                        </div>
                                        <div class="pcbi-data">
                                            <div class="pcbid-title">peso</div>
                                            <div class="pcbid-value">
                                                {{data?.consult?.growth_curve?.weight}}
                                                <ng-container *ngIf="!data?.consult?.growth_curve?.weight">
                                                    --
                                                </ng-container>
                                                <div class="pcbidv-unit">
                                                    {{data?.consult?.growth_curve?.id_weight_unit | catalog: get_catalogs("units_weight", "id", "code") }}
                                                </div>
                                            </div>
                                            <div class="pcbid-value-2">
                                                <div class="pcbidv2-unit" style="font-variant: small-caps;">
                                                    percentil
                                                </div>
                                                {{data?.consult?.growth_curve?.p_weight}}
                                                <ng-container *ngIf="!data?.consult?.growth_curve?.p_weight">
                                                    --
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="d-flex">
                                    <div class="pcb-indicator mx-auto">
                                        <div class="pcbi-icon">
                                            <i class="fa-duotone fa-ruler-combined text-info"></i>
                                        </div>
                                        <div class="pcbi-data">
                                            <div class="pcbid-title">talla</div>
                                            <div class="pcbid-value">
                                                {{data?.consult?.growth_curve?.height}}
                                                <ng-container *ngIf="!data?.consult?.growth_curve?.height">
                                                    --
                                                </ng-container>
                                                <div class="pcbidv-unit">
                                                    {{data?.consult?.growth_curve?.id_height_unit | catalog: get_catalogs("units_height", "id", "code") }}
                                                </div>
                                            </div>
                                            <div class="pcbid-value-2">
                                                <div class="pcbidv2-unit" style="font-variant: small-caps;">
                                                    percentil
                                                </div>
                                                {{data?.consult?.growth_curve?.p_height}}
                                                <ng-container *ngIf="!data?.consult?.growth_curve?.p_height">
                                                    --
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 mt-2">
                                <div class="d-flex">
                                    <div class="pcb-indicator mx-auto">
                                        <div class="pcbi-icon">
                                            <i class="fa-duotone fa-weight-scale text-success"></i>
                                        </div>
                                        <div class="pcbi-data">
                                            <div class="pcbid-title">imc</div>
                                            <div class="pcbid-value">
                                                {{data?.consult?.growth_curve?.IMC}}
                                                <ng-container *ngIf="!data?.consult?.growth_curve?.IMC">
                                                    --
                                                </ng-container>
                                                <div class="pcbidv-unit">
                                                    {{data?.consult?.growth_curve?.id_height_unit | catalog: get_catalogs("units_IMC", "id", "code") }}
                                                </div>
                                            </div>
                                            <div class="pcbid-value-2">
                                                <div class="pcbidv2-unit" style="font-variant: small-caps;">
                                                    percentil
                                                </div>
                                                {{data?.consult?.growth_curve?.p_IMC}}
                                                <ng-container *ngIf="!data?.consult?.growth_curve?.p_IMC">
                                                    --
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 mt-2">
                                <div class="d-flex">
                                    <div class="pcb-indicator mx-auto">
                                        <div class="pcbi-icon">
                                            <i class="fa-duotone fa-head-side text-purple"></i>
                                        </div>
                                        <div class="pcbi-data">
                                            <div class="pcbid-title">c. cefálica</div>
                                            <div class="pcbid-value">
                                                {{data?.consult?.growth_curve?.head}}
                                                <ng-container *ngIf="!data?.consult?.growth_curve?.head">
                                                    --
                                                </ng-container>
                                                <div class="pcbidv-unit">
                                                    {{data?.consult?.growth_curve?.id_height_unit | catalog: get_catalogs("units_height", "id", "code") }}
                                                </div>
                                            </div>
                                            <div class="pcbid-value-2">
                                                <div class="pcbidv2-unit" style="font-variant: small-caps;">
                                                    percentil
                                                </div>
                                                {{data?.consult?.growth_curve?.p_head}}
                                                <ng-container *ngIf="!data?.consult?.growth_curve?.p_head">
                                                    --
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="pcb-carrousel h-100 pcbc-show">
                    <div class="pcbch-bar"></div>
                    <div class="d-flex flex-column pt-3 pb-2">
                        <div class="chart-container mx-auto" style="position: relative; width: 100%; overflow-x: hidden;">
                            <canvas #graphCanvasWFH id="chartIMC" height="100vh"></canvas>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="pcb-carrousel h-100 pcbc-show">
                    <div class="pcbch-bar"></div>
                    <div class="pcbc-header">
                        <div class="pcb-title">VITALES</div>
                    </div>
                    <div class="pcbc-body">
                        <div class="row px-2">
                            <div class="col-6">
                                <div class="d-flex">
                                    <div class="pcb-indicator mx-auto">
                                        <div class="pcbi-icon">
                                            <i class="fa-duotone fa-heart-pulse text-warning"></i>
                                        </div>
                                        <div class="pcbi-data">
                                            <div class="pcbid-title">frec. cardíaca</div>
                                            <div class="pcbid-value">
                                                {{data?.consult?.vitals?.heart_rate}}
                                                <ng-container *ngIf="!data?.consult?.vitals?.heart_rate">
                                                    --
                                                </ng-container>
                                                <div class="pcbidv-unit">
                                                    lpm
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="d-flex">
                                    <div class="pcb-indicator mx-auto">
                                        <div class="pcbi-icon">
                                            <i class="fa-duotone fa-wave-pulse text-info"></i>
                                        </div>
                                        <div class="pcbi-data">
                                            <div class="pcbid-title">pulso</div>
                                            <div class="pcbid-value">
                                                {{data?.consult?.vitals?.pulse}}
                                                <ng-container *ngIf="!data?.consult?.vitals?.pulse">
                                                    --
                                                </ng-container>
                                                <div class="pcbidv-unit">
                                                    kg/m²
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 mt-3">
                                <div class="d-flex">
                                    <div class="pcb-indicator mx-auto">
                                        <div class="pcbi-icon">
                                            <i class="fa-duotone fa-lungs text-success"></i>
                                        </div>
                                        <div class="pcbi-data">
                                            <div class="pcbid-title">fr.respiratoria</div>
                                            <div class="pcbid-value">
                                                {{data?.consult?.vitals?.breathing_rate}}
                                                <ng-container *ngIf="!data?.consult?.vitals?.breathing_rate">
                                                    --
                                                </ng-container>
                                                <div class="pcbidv-unit">
                                                    rpm
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 mt-3">
                                <div class="d-flex">
                                    <div class="pcb-indicator mx-auto">
                                        <div class="pcbi-icon">
                                            <i class="fa-duotone fa-temperature-low text-primary"></i>
                                        </div>
                                        <div class="pcbi-data">
                                            <div class="pcbid-title">temperatura</div>
                                            <div class="pcbid-value">
                                                {{data?.consult?.vitals?.temp}}
                                                <ng-container *ngIf="!data?.consult?.vitals?.temp">
                                                    --
                                                </ng-container>
                                                <div class="pcbidv-unit">
                                                    °C
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex w-100 mt-4">
            <div class="pcb-carrousel pcb-show mr-3" style="width: 40%;">
                <div class="pcbch-bar"></div>
                <div class="pcbc-header">
                    <div class="pcb-title mb-2">DIAGNÓSTICO</div>
                </div>
                <div class="pcb-box-trans" style="font-size: 10pt; line-height: 12pt; padding-left: 0;">
                    <ng-container *ngIf="data?.consult?.diagnosis_list?.list?.length > 0">
                        <ul>
                            <li *ngFor="let item of data?.consult?.diagnosis_list?.list">{{item.name}}</li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="data?.consult?.diagnosis_list?.list?.length == 0">
                        <div class="pl-2">No hay diagnóstico</div>
                    </ng-container>
                </div>
            </div>
            <div class="pcb-carrousel pcb-show" style="width: 60%;">
                <div class="pcbch-bar"></div>
                <div class="pcbc-header">
                    <div class="pcb-title mb-2">RECOMENDACIONES Y NOTAS</div>
                </div>
                <div class="pcb-box-trans" style="font-size: 10pt; line-height: 12pt; white-space: pre-line;">
                    {{data?.consult?.recommendation?.recomendations}}
                    <ng-container *ngIf="!data?.consult?.recommendation?.hidden_notes">
                        No hay recomendaciones
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="pcb-carrousel pcb-show mt-3 px-2">
            <div class="pcbch-bar"></div>
            <div class="pcbc-header">
                <div class="pcb-title">
                    RECETA
                    <button *ngIf="data?.consult?.prescription_list?.list.length > 0" class="btn btn-outline-primary ml-2" [routerLink]="'/expediente/receta/' + data?.consult?.id">ver receta</button>
                </div>
            </div>
            <ng-container *ngIf="data?.consult?.prescription_list?.list?.length > 0">
                <div class="pcb-medicines">
                    <div class="pcbm-medicines-list mt-2">
                        <ng-container *ngFor="let item of data?.consult?.prescription_list?.list">
                            <li class="pcbmml-item">
                                <div class="m-name">
                                    <ng-container *ngIf="!!item.brand">
                                        {{item.brand}}
                                        <div class="m-tag">
                                            - {{item.presentation }} ({{item.principle }})
                                            <ng-container *ngIf="['Crema', 'Jarabe'].includes(item.presentation)">({{item.concentration}})</ng-container>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!item.brand">
                                        {{item.principle }}
                                        <div class="m-tag">
                                            - {{item.presentation }}
                                            <ng-container *ngIf="['Crema', 'Jarabe'].includes(item.presentation)">({{item.concentration}})</ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="m-instructions">
                                    <ng-container *ngIf="item.presentation == 'Crema'">
                                        {{item.dosage }} aplicaciones
                                    </ng-container>
                                    <ng-container *ngIf="item.presentation == 'Suspensión'">
                                        {{item.dosage }} {{item.dosage_unit | lowercase }}
                                    </ng-container>
                                    <ng-container *ngIf="item.presentation != 'Crema' && item.presentation != 'Suspensión'">
                                        Tomar {{item.dosage }} {{item.dosage_unit | lowercase }}
                                    </ng-container>
                                    vía {{item.way | lowercase }} cada {{item.frecuency | lowercase }} {{item.frecuency_unit | lowercase }}<ng-container *ngIf="!item.continues">
                                        por {{item.period | lowercase }} {{item.period_unit | lowercase }}</ng-container><ng-container *ngIf="!!item.continues">, uso continuo</ng-container>
                                    <sup class="ml-1" *ngIf="item.instructions">{{get_current_prescription_note(item) + 1}}</sup>
                                </div>
                            </li>
                        </ng-container>
                    </div>
                    <div class="pcbm-notes-list">
                        <ng-container *ngIf="has_prescriptions_notes()">
                            <div class="pcbmnl-title">NOTAS:</div>

                            <ng-container *ngFor="let item of get_prescriptions_notes(); let pli = index">
                                <div class="pcbmnl-item">
                                    <sup>{{pli + 1}}</sup>
                                    {{item}}
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="data?.consult?.prescription_list?.list?.length == 0">
                <div class="pcb-list">
                    <div class="pcbl-item">
                        No hay receta
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="d-flex w-100 mt-4">
            <div class="pcb-carrousel pcb-show w-50 mr-3">
                <div class="pcbch-bar"></div>
                <div class="pcbc-header">
                    <div class="pcb-title">REFERENCIAS</div>
                </div>
                <div class="pcb-list mt-2 px-2">
                    <ng-container *ngFor="let item of data?.consult?.references_list?.list">
                        <div class="pcbl-item">
                            <div style="display: inline-flex;">
                                {{ item.doctor }}
                                <div style="font-style: italic; color: #6D6E71;" class="mx-1">{{ item.id_doctors_specialty | catalog: get_catalogs("doctors_specialties") }}</div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="data?.consult?.references_list?.list?.length == 0">
                        <div class="pcbl-item">
                            No hay referencias
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="pcb-carrousel pcb-show w-50">
                <div class="pcbch-bar"></div>
                <div class="pcbc-header">
                    <div class="pcb-title">PROCEDIMIENTOS</div>
                </div>
                <div class="pcb-list mt-2 px-2">
                    <ng-container *ngFor="let item of data?.consult?.procedures_requests_list?.list">
                        <div class="pcbl-item"> Se solicita {{ item.id_procedures_type | catalog: get_catalogs("procedures_types") }}</div>
                    </ng-container>
                    <ng-container *ngIf="data?.consult?.procedures_requests_list?.list?.length == 0">
                        <div class="pcbl-item">
                            No hay procedimientos solicitados
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="pcb-carrousel pcb-show mt-3 px-2">
            <div class="pcbch-bar"></div>
            <div class="pcbc-header">
                <div class="pcb-title">EXÁMENES</div>
            </div>
            <ng-container *ngIf="data?.consult?.exams_requests_list?.list?.length > 0">
                <div class="pcb-medicines">
                    <div class="pcbm-medicines-list mt-2">
                        <ng-container *ngFor="let item of data?.consult?.exams_requests_list?.list">
                            <div class="pcbmml-item">
                                <div class="m-name">
                                    {{ item.id_exams_type | catalog: get_catalogs("exams_types") }}, {{ item.id_exams_name | catalog: get_catalogs("exams_names") }}
                                    <ng-container *ngIf="item.id_exams_name_2">
                                        - {{ item.id_exams_name_2 | catalog: get_catalogs("exams_names_2") }}
                                    </ng-container>
                                    <sup class="ml-1" *ngIf="item.observations">{{get_current_exams_note(item) + 1}}</sup>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="pcbm-notes-list">
                        <ng-container *ngIf="has_exams_notes()">
                            <div class="pcbmnl-title">NOTAS:</div>

                            <ng-container *ngFor="let item of get_exams_notes(); let pli = index">
                                <div class="pcbmnl-item">
                                    <sup>{{pli + 1}}</sup>
                                    {{item}}
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="data?.consult?.exams_requests_list?.list?.length == 0">
                <div class="pcb-list">
                    <div class="pcbl-item">
                        No hay exámenes solicitados
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

</div>