import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/services/account.service';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { FormRenderComponent, form_type } from 'src/app/shared/ui/form_render/form_render.component';
import { ConsultMetadata } from '../../new_consult/general/consult.service';

@Component({
  selector: 'app-shedule-date',
  templateUrl: './shedule-date.component.html',
  styleUrls: ['./shedule-date.component.scss']
})
export class SheduleDateComponent implements OnInit {

  private patient_data       : any;
  private doctor_data        : any;
  private doctor_subscription: Subscription;

  @Input() data = {

    id_patient  : "", 
    id_doctor   : "",
    description : "", 
    fecha_date  : "" , 
    hour_date   : "",
    duration    : ""

  };

    @ViewChild('form_ref', { static: false }) form_ref: FormRenderComponent;
    public form_config: form_type;
  
  /* Búsqueda de Pacientes  */

  private  consult_subscription: Subscription;
  public readonly: boolean = false;
  public patients: any;
  public patients2: any;
  public   consult_state: ConsultMetadata;
  @ViewChild('form_ref2', { static: false }) form_ref2: FormRenderComponent;
  public form_config2: form_type;
  public loading: boolean;
  public pathology_timeout: any;
  public search_word: string;
  constructor( private endpoint: AppService, private toastr: ToastrService, private pageService: PageService, private patientServiceService: PatientService,
    private  accountService: AccountService) { 
      this.loading = true;
      this.pathology_timeout = null;
      this.search_word = "";
      
    }

  ngOnInit() {
    this.doctor_data = this.accountService.get_current_state();
    this.form_configs();
    this.get_patients();
    this.form_configs2();
  }

  form_configs() {
    this.form_config = {
      submit: () => this.submit(),
      rows: [
        {
          class: "row",
          cols: [
            {
              name: "id_patient",
              type: "select",
              label: "Paciente",
              placeholder: () => "",
              col_class: "col-md-12",
              catalog: {
                list: () => this.patients,
                value: "id_user",
                text: "firstname"
              },
              validators: {
                required: {
                  value: () => true
                },
              },
              disabled: () => false,
            },
            {
              name: "description",
              type: "textarea",
              label: "Descripción",
              placeholder: () => "",
              col_class: "col-md-12",
              rows: 2.5,
              validators: {
                required: {
                  value: () => true
                },
                maxlength: {
                  value: () => 100
                }
              },
              disabled: () => false,
            },
            {
              name: "fecha_date",
              type: "calendar",
              label: "Fecha",
              placeholder: () => "#######",
              col_class: "col-md-6",
              validators: {
                required: {
                  value: () => true
                },
              },
              disabled: () => false,
            },
            {
              name: "hour_date",
              type: "text",
              label: "Hora",
              placeholder: () => "",
              col_class: "col-md-6",
              validators: {
                required: {
                  value: () => true
                },
                maxlength: {
                  value: () => 500
                }
              },
              disabled: () => false,
            },
            {
              name: "duration",
              type: "text",
              label: "duración",
              placeholder: () => "",
              col_class: "col-md-6",
              validators: {
                required: {
                  value: () => true
                },
                maxlength: {
                  value: () => 500
                }
              },
              disabled: () => false,
            },
            
          ],
        },
        {
          class: "row",
          cols: [
            {
              type: "buttons",
              col_class: "col-12",
              buttons: [
                {
                  class: "btn btn-sm btn-danger",
                  label: "Cancelar",
                  type: "button",
                  icon: {
                    class: "fa-duotone fa-times",
                    position: 'right',
                  },
                  disabled: () => false,
                  click: () => this.close()
                },
                {
                  class: "btn btn-sm btn-success ml-auto",
                  label: "Agregar Cita",
                  type: "submit",
                  icon: {
                    class: "fa-duotone fa-check",
                    position: 'right',
                  },
                  disabled: () => this.form_ref ? !this.form_ref.valid() : false,
                },
              ]
            },
          ],
        },
      ]
    };
  }

  submit() {
    this.data.id_doctor = this.doctor_data.id;
    this.endpoint.insert_dates(this.data).subscribe(
      data => {
        this.toastr.success(data["message"], data["title"]);
        this.close();
      },
      error => {
        this.toastr.error(
          error.error.message ? error.error.message : error.message,
          error.error.title ? error.error.title : error.status + ''
        );
      }
    );
  }

  close() {
    this.pageService.closeModal("");
  }
  

  
  form_configs2() {
    this.form_config2 = {
      pretty_view: () => this.readonly,
      disabled: () => this.readonly,
      submit: () => () => { },
      rows: [
        {
          class: "row",
          cols: [
            {
              name: "id",
              type: "select-search",
              label: "Paciente",
              small: "",
              placeholder: () => "- Seleccione -",
              col_class: "col-md-6",
              validators: {
                required: {
                  value: () => false
                },
              },
              catalog: {
                list: () => this.patients,
                value: "title",
                text: "title"
              },
              loading: () => {
                return this.loading;
              },
              disabled: () => false,
              change: (event) => {
                this.search_word = event.target.value;
                // clearTimeout(this.pathology_timeout);
                // this.get_icd_pathologies();
              },
              input: (event) => {
                this.search_word = event.target.value;
                clearTimeout(this.pathology_timeout);

                this.pathology_timeout = setTimeout(() => {
                  this.get_patients();
                }, 1000);
              },
              select: (item) => {
                this.patients.push(item);
                this.loading = true;
              }
            },
          ],
        },
      ]
    };
  }

  get_patients() {
    this.endpoint.get_my_patients("").subscribe(
      data => this.patients = data.list,
      err => console.error(err)
    );
  }

}
