import { Component } from '@angular/core';
import { DependantsService } from '../dependants.service';

@Component({
  selector: 'app-relations_update_dependants',
  templateUrl: './update_dependants.component.html',
  styleUrls: ['./update_dependants.component.scss']
})
export class UpdateRelationsDependantsComponent {

  constructor(private moduleService: DependantsService) {
  }

  close() {
    this.moduleService.cancel();
  }

}
