import { Pipe, PipeTransform } from '@angular/core';
import { PipesService } from 'src/app/services/pipes.service';

@Pipe({ name: 'microDate' })
export class microDate implements PipeTransform {

    constructor(private pipesService: PipesService) { }

    transform(str: string): string {
        return this.pipesService.micro_date(str);
    }
}