<ng-container *ngIf="expedient_state.version=='expedient'">
    <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="expedient_state.version=='new_patient'">
    <ng-container *ngIf="view==0">
        <app-expedient-contacts-emergency (change_view)="view=1"></app-expedient-contacts-emergency>
    </ng-container>
    <ng-container *ngIf="view==1">
        <app-expedient-contacts-doctor (change_view)="view=0"></app-expedient-contacts-doctor>
    </ng-container>
</ng-container>