import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PageService } from 'src/app/services/page.service';
import { AppService } from 'src/app/services/app.service';
import { ExpedientService } from 'src/app/services/expedient.service';
import Swal from 'sweetalert2';
import * as i0 from "@angular/core";
import * as i1 from "../../../../services/app.service";
import * as i2 from "ngx-toastr";
import * as i3 from "../../../../services/page.service";
import * as i4 from "../../../../services/expedient.service";
;
;
;
;
export class DoctorService {
    constructor(endpoint, toastr, pageService, expedientService) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.pageService = pageService;
        this.expedientService = expedientService;
        this.store_list_name = "unimed-expedient-contacts-doctor-list";
        this.store_name = "unimed-expedient-contacts-doctor";
    }
    // LIST FUNCTIONS
    init_list() {
        this.expedientService.set_expedient_service({ store_url: this.store_list_name, submit: (callback) => this.submit_stored(callback) }, "contacts", "doctor");
        this.clean_list();
        this.list_next();
    }
    reload_list(data) {
        if (this.expedientService.state.id_user) {
            this.get_list(data);
        }
        else {
            this.get_stored_list();
        }
    }
    get_list(data) {
        this.current_filters = data;
        let load = Object.assign({ id_user: this.expedientService.state.id_user }, data);
        this.endpoint.expedient_contacts_doctor_get_doctor(load).subscribe((data) => this.list = data, error => console.error(error), () => {
            this.list_next();
        });
    }
    get_stored_list() {
        setTimeout(() => {
            let stored = localStorage.getItem(this.store_list_name);
            if (stored) {
                this.list = JSON.parse(stored);
            }
            else {
                this.clean_list();
                this.list.loaded = true;
                this.save_list();
            }
            this.list_next();
        }, 1);
    }
    list_next() {
        if (this.list_state)
            this.list_state.next(this.list);
        else {
            this.list_state = new BehaviorSubject(this.list);
            this.obv_list = this.list_state.asObservable();
        }
    }
    clean_list() {
        return this.list = {
            list: [],
            count: 0,
            skip: 0,
            loaded: false
        };
    }
    save_list() {
        localStorage.setItem(this.store_list_name, JSON.stringify(this.list));
    }
    // EVENT FUNCTIONS
    init(doctor = null) {
        if (doctor) {
            this.current_state = doctor;
            this.current_state.loading = false;
            this.current_state.submitted = false;
            this.current_state.timestamp = new Date;
        }
        else
            this.internal_clean();
        this.next();
        this.forms = {
            doctor: null
        };
        this.forms_state = new BehaviorSubject(this.forms);
        this.obv_forms = this.forms_state.asObservable();
        this.catalogs = {
            doctors_specialties: [],
            departments: [],
            towns: []
        };
        this.reload_catalogs();
    }
    reload_catalogs() {
        this.endpoint.new_patient_catalogs().subscribe(data => this.catalogs = data, error => console.error(error));
    }
    get_current_state() {
        return this.current_state;
    }
    refresh_forms() {
        this.forms_state.next(this.forms);
    }
    next() {
        if (this.module_state)
            this.module_state.next(this.current_state);
        else {
            this.module_state = new BehaviorSubject(this.current_state);
            this.current = this.module_state.asObservable();
        }
    }
    valid() {
        return !this.current_state.loading &&
            this.forms && this.forms.doctor && this.forms.doctor.valid ? this.forms.doctor.valid() : false;
    }
    set_form(form) {
        this.forms[form.name] = form.module;
    }
    save() {
        localStorage.setItem(this.store_name, JSON.stringify(this.current_state));
    }
    submit(callback = (callback_data) => { }) {
        if (!(this.forms && this.forms.doctor && this.forms.doctor.valid()) || this.current_state.loading) {
            callback();
            return false;
        }
        this.current_state.id_user = this.expedientService.state.id_user;
        this.current_state.submitted = true;
        this.current_state.loading = true;
        this.module_state.next(this.current_state);
        if (this.expedientService.state.id_user) {
            if (this.expedientService.state.method == "insert") {
                this.insert(callback);
            }
            else {
                this.update(callback);
            }
        }
        else {
            if (this.expedientService.state.method == "insert") {
                this.insert_store(callback);
            }
            else {
                this.update_store(callback);
            }
        }
    }
    insert(callback = (callback_data) => { }) {
        this.endpoint.expedient_contacts_doctor_insert_doctor(this.current_state).subscribe(data => {
            if (this.expedientService.state.version == "expedient") {
                localStorage.removeItem("inExpedientContactsDoctor");
                this.toastr.success(data["message"], data["title"]);
                this.current_state.loading = false;
                this.module_state.next(this.current_state);
                if (this.forms.doctor)
                    this.forms.doctor.unsubmit();
                this.expedientService.update_global();
                this.reload_list(this.current_filters);
                this.close();
                this.clean();
                callback(data);
            }
            else if (this.expedientService.state.version == "new_patient") {
                callback(data);
            }
        }, error => {
            console.error(error);
            this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + '');
            this.current_state.loading = false;
            this.module_state.next(this.current_state);
        });
    }
    insert_store(callback = (callback_data) => { }) {
        this.current_state.id = new Date().getTime();
        this.current_state.created_at = new Date().toISOString();
        this.list.list.push(this.current_state);
        callback();
        this.save_list();
        this.list_next();
        this.close();
        this.clean();
    }
    update(callback = (callback_data) => { }) {
        this.endpoint.expedient_contacts_doctor_update_doctor(this.current_state).subscribe(data => {
            localStorage.removeItem("inExpedientContactsDoctor");
            this.toastr.success(data["message"], data["title"]);
            this.current_state.loading = false;
            this.module_state.next(this.current_state);
            if (this.forms.doctor)
                this.forms.doctor.unsubmit();
            this.expedientService.update_global();
            this.reload_list(this.current_filters);
            this.close();
            this.clean();
            callback(data);
        }, error => {
            console.error(error);
            this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + '');
            this.current_state.loading = false;
            this.module_state.next(this.current_state);
        });
    }
    update_store(callback = (callback_data) => { }) {
        let index = this.list.list.findIndex(value => value.id == this.current_state.id);
        this.list.list[index] = this.current_state;
        callback();
        this.save_list();
        this.list_next();
        this.close();
        this.clean();
    }
    delete() {
        if (this.expedientService.state.id_user) {
            this._delete();
        }
        else {
            this._delete_store();
        }
    }
    _delete() {
        let load = {
            id: this.current_state.id
        };
        this.endpoint.expedient_contacts_doctor_delete_doctor(load).subscribe(data => {
            this.toastr.success(data["message"], data["title"]);
            this.expedientService.update_global();
            this.reload_list(this.current_filters);
            this.close();
        }, error => {
            this.toastr.error(error.error.message ? error.error.message : error.message, error.error.title ? error.error.title : error.status + '');
        });
    }
    _delete_store() {
        let index = this.list.list.findIndex(value => value.id == this.current_state.id);
        this.list.list.splice(index, 1);
        this.save_list();
        this.list_next();
    }
    cancel(callback = (callback_data) => { }) {
        Swal.fire({
            title: "Cancelar Formulario",
            html: "¿Está seguro de cancelar el llenado del formulario actual?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FDB915',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cancelar',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value == true) {
                localStorage.removeItem("inExpedientGeneral");
                this.close();
                callback();
            }
        });
    }
    close() {
        this.pageService.closeModal(true);
    }
    clean() {
        this.internal_clean();
        this.module_state.next(this.current_state);
        this.save();
    }
    internal_clean() {
        this.current_state = {
            id: null,
            id_user: null,
            id_doctor: null,
            id_doctors_specialty: null,
            id_department: null,
            id_town: null,
            firstname: "",
            lastname: "",
            phone: "",
            cellphone: "",
            email: "",
            address: "",
            created_at: "",
            created_by: null,
            loading: false,
            submitted: false,
            timestamp: new Date(),
        };
    }
    submit_stored(callback = (callback_data) => { }, step = 0) {
        if (this.list.list.length > 0 && step < this.list.list.length) {
            this.current_state = this.list.list[step];
            this.current_state.id_user = this.expedientService.state.id_user;
            this.insert(() => {
                this.submit_stored(callback, step + 1);
            });
        }
        else {
            callback();
        }
    }
    get_doctors(callback = (data) => { }) {
        let load = {
            catalogs: ["CAT_DOCTORS", "tf"],
        };
        this.endpoint.get_catalogs(load).subscribe((data) => {
            callback(data.CAT_DOCTORS);
            // this.catalogsService.set_catalog("doctors", data);
        }, (error) => console.error(error));
    }
}
DoctorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DoctorService_Factory() { return new DoctorService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.PageService), i0.ɵɵinject(i4.ExpedientService)); }, token: DoctorService, providedIn: "root" });
