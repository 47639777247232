<div class="insurance-card">
    <div class="ilustration">
        <svg
            [class.t-blue-1]="(i+1)%3==1"
            [class.t-red-1]="(i+1)%3==2"
            [class.t-green-1]="(i+1)%3==0"
            style="width: 100%; height: auto;"
            width="169.33333mm"
            height="94.544617mm"
            viewBox="0 0 169.33333 94.544617"
            version="1.1"
            id="svg5"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:svg="http://www.w3.org/2000/svg">
            <defs
                id="defs2">
                <clipPath
                    clipPathUnits="userSpaceOnUse"
                    id="clipEmfPath3">
                    <path
                        d="M 21.333984,-0.33398438 C 9.6673185,-0.33398436 0,9 0,21 V 335.66602 C 0,347.33268 9.6673185,357 21.333984,357 H 618.66602 C 630.66602,357 640,347.33268 640,335.66602 V 21 C 640,9 630.66602,-0.33398438 618.66602,-0.33398438 Z"
                        id="path830" />
                </clipPath>
            </defs>
            <g
                id="layer1"
                transform="translate(4.9434389,-97.716565)">
                <path
                    style="fill-opacity:1;fill-rule:nonzero;stroke:none"
                    clip-path="url(#clipEmfPath3)"
                    d="m 381.66667,310.66667 c -15.33334,-24.66667 -23.33334,-60 -25.33334,-115 -2,-44 -11.33333,-71 -31.66666,-91 C 303.66667,84.333333 270.33333,71 213,59.666667 c -56.33333,-11 -86.33333,-28.333334 -101.66667,-41 C 99.666667,9.3333333 95,1 93,-3 90.333333,-3 90.333333,-3 90.333333,-3 92,1 96.666667,10 109.66667,20.333333 125,33.333333 155.33333,50.666667 212.66667,62 269.33333,73.333333 302.33333,86.333333 323,106.66667 c 20,19 29,45.66666 30.66667,89 C 356,251 364,287 379.66667,312 c 14.66666,23.33333 36.66666,37.66667 68.66666,45 13,0 13,0 13,0 -38,-6 -63.33333,-20.66667 -79.66666,-46.33333 z"
                    id="line2"
                    transform="matrix(0.26458333,0,0,0.26458333,-4.9434389,97.804932)" />
                <path
                    style="fill-opacity:1;fill-rule:nonzero;stroke:none"
                    clip-path="url(#clipEmfPath3)"
                    d="m 76.666667,113.66667 c 22,-1 39.000003,7.33333 57.000003,16.33333 19.33333,9.33333 39,19 67,19 8,0 16.66666,-0.66667 26,-2.66667 42,-8 67.33333,-49.666663 89.66666,-86.666663 C 333.33333,31 349.66667,4 371.33333,-3 371,-3 371,-3 371,-3 c -5.66667,0 -5.66667,0 -5.66667,0 C 345.66667,6.3333333 330.33333,31.666667 314,58.333333 292,95 267,136.33333 226,144 184.33333,152 159,139.66667 134.66667,127.66667 116.33333,118.66667 99,110 76.333333,111.33333 49.333333,112.66667 23,128.66667 6,141.33333 c -4.3333333,3 -8,6 -11.3333333,9 0,3 0,3 0,3 4,-3 8,-6.66666 12.6666666,-10 C 24.333333,130.66667 50,115.33333 76.666667,113.66667 Z"
                    id="line3"
                    transform="matrix(0.26458333,0,0,0.26458333,-4.9434389,97.804932)" />
                <path
                    style="fill-opacity:1;fill-rule:nonzero;stroke:none"
                    clip-path="url(#clipEmfPath3)"
                    d="m 609.66667,96.666667 c -3,46.666663 -11,78.666663 -25.33334,100.000003 C 571,217 551.66667,228.66667 522,235.33333 c -11.33333,2.33334 -23.33333,3 -36.33333,4 -25,1.66667 -53.33334,3.66667 -79,19 C 377.33333,275.66667 356,308 341.33333,357 344,357 344,357 344,357 376.66667,249.33333 434.66667,245.33333 486,242 c 13,-1 25,-2 36.33333,-4.33333 30.66667,-6.33334 50.33334,-18.66667 64,-39.33334 14.66667,-22 23,-54.33333 26,-101.666663 3,-48.333334 16.33334,-76.666667 27,-91.666667 1,-1.6666667 2.33334,-3.3333333 3.33334,-4.66666667 C 642.66667,-3 642.66667,-3 642.66667,-3 642,-3 642,-3 642,-3 640.66667,-1.3333333 639,1 637.33333,3.3333333 626.33333,18.666667 613,47.333333 609.66667,96.666667 Z"
                    id="line1"
                    transform="matrix(0.26458333,0,0,0.26458333,-4.9434389,97.804932)" />
                <text
                    xml:space="preserve"
                    style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:10.5833px;line-height:1.25;font-family:Montserrat;-inkscape-font-specification:'Montserrat Bold';fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.264583"
                    x="0.36777741"
                    y="113.14128"
                    id="text16877">
                    <tspan
                        id="tspan16875"
                        style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-family:Montserrat;-inkscape-font-specification:'Montserrat Bold';fill:#ffffff;stroke-width:0.264583"
                        x="0.36777741"
                        y="113.14128">{{item.carrier}}</tspan>
                </text>
                <text
                    xml:space="preserve"
                    style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:4.58348px;line-height:1.25;font-family:Montserrat;-inkscape-font-specification:'Montserrat Bold';fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.114587"
                    x="0.7649259"
                    y="174.44867"
                    id="text16877-4">
                    <tspan
                        id="tspan16875-6"
                        style="font-style:normal;font-variant:normal;font-weight:500;font-stretch:normal;font-family:Montserrat;-inkscape-font-specification:'Montserrat Medium';fill:#ffffff;stroke-width:0.114587"
                        x="0.7649259"
                        y="174.44867">NOMBRE</tspan>
                </text>
                <text
                    xml:space="preserve"
                    style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:8.1845px;line-height:1.25;font-family:Montserrat;-inkscape-font-specification:'Montserrat Bold';fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.204613"
                    x="0.47684833"
                    y="183.89487"
                    id="text16877-7">
                    <tspan
                        id="tspan16875-29"
                        style="font-style:normal;font-variant:normal;font-weight:600;font-stretch:normal;font-family:Montserrat;-inkscape-font-specification:'Montserrat Semi-Bold';fill:#ffffff;stroke-width:0.204613"
                        x="0.47684833"
                        y="183.89487">{{item.user_name}}</tspan>
                </text>
                <text
                    xml:space="preserve"
                    style="font-style:normal;font-variant:normal;font-weight:500;font-stretch:normal;font-size:4.58348px;line-height:1.25;font-family:Montserrat;-inkscape-font-specification:'Montserrat Medium';fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.114587"
                    x="125.20255"
                    y="108.05733"
                    id="text16877-4-2">
                    <tspan
                        id="tspan16875-6-0"
                        style="font-style:normal;font-variant:normal;font-weight:500;font-stretch:normal;font-family:Montserrat;-inkscape-font-specification:'Montserrat Medium';fill:#ffffff;stroke-width:0.114587"
                        x="125.20255"
                        y="108.05733"># DE SEGURO</tspan>
                </text>
                <text
                    xml:space="preserve"
                    style="font-style:normal;font-variant:normal;font-weight:bold;font-stretch:normal;font-size:8.1845px;line-height:1.25;font-family:Montserrat;-inkscape-font-specification:'Montserrat Bold';direction:rtl;fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.204613"
                    x="158.46582"
                    y="116.88391"
                    id="text16877-7-0">
                    <tspan
                        id="tspan16875-29-4"
                        style="font-style:normal;font-variant:normal;font-weight:600;font-stretch:normal;font-family:Montserrat;-inkscape-font-specification:'Montserrat Semi-Bold';direction:rtl;fill:#ffffff;stroke-width:0.204613"
                        x="158.46582"
                        y="116.88391">{{item.number}}</tspan>
                </text>
                <rect
                    style="fill:#000000;fill-opacity:0;stroke-width:0.294013"
                    id="rect64237"
                    width="169.33333"
                    height="94.544617"
                    x="-4.943439"
                    y="97.716568" />
            </g>
        </svg>
    </div>
<!-- 
    <div class="description">
        {{item.description}}
    </div> -->

    <ng-container *ngIf="events && events.length > 0">
        <div class="events justify-content-center">
            <ng-container *ngFor="let e of events">
                <a *ngIf="!e.disabled || !(e.disabled && e.disabled(item)) " (click)="_event(e.name, item)" [title]="e.name" class="waves-effect mx-2"> <i class="{{e.icon}}"></i> </a>
                <a *ngIf="e.disabled && e.disabled(item)" [title]="e.name" class="waves-effect mx-2"> <i class="fa-duotone fa-minus text-muted"></i> </a>
            </ng-container>
        </div>
    </ng-container>
</div>