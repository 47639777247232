import { Component } from '@angular/core';
import { DoctorService } from '../doctor.service';

@Component({
  selector: 'app-contacts_update_doctor',
  templateUrl: './update_doctor.component.html',
  styleUrls: ['./update_doctor.component.scss']
})
export class UpdateContactsDoctorComponent {

  constructor(private moduleService: DoctorService) {
  }

  close() {
    this.moduleService.cancel();
  }

}
