export const environment = {
  production: false,
  test: true,
  dev: false,
  admin_server: "https://zg9jdg9ylnrlc3quc2vydmvy.unimedapp.com",
  doctor_server: "https://zg9jdg9ylnrlc3quc2vydmvy.unimedapp.com",
  patient_server: "https://zg9jdg9ylnrlc3quc2vydmvy.unimedapp.com",
  institution_server: "https://zg9jdg9ylnrlc3quc2vydmvy.unimedapp.com",
  insurance_server: "https://zg9jdg9ylnrlc3quc2vydmvy.unimedapp.com",
  file_server: "https://zg9jdg9ylnrlc3quc2vydmvy.unimedapp.com",
  secure_key: "b52ae51bda6097924ea91cae7dcebc1b",
  socket: {
    transport: [
      "polling"
    ]
  },
};