import { Pipe, PipeTransform } from '@angular/core';
import { PipesService } from 'src/app/services/pipes.service';

@Pipe({ name: 'number' })
export class NumberPipe implements PipeTransform {
    constructor(private pipesService: PipesService) { }

    transform(num: number, min: number = 2, max: number = 2): string {
        return this.pipesService.number(+num, min, max);
    }
}