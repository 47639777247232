import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import Swal from 'sweetalert2'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-steps_2',
	templateUrl: './steps_2.component.html',
	styleUrls: ['./steps_2.component.scss']
})
export class Steps2Component {

	release_year: number = 2020;
	year: number = new Date().getFullYear();
	steps_2Form: FormGroup;
	submitted: boolean;
	error: string;
	loading: boolean;
	public code_mask = [/[A-Z]|[a-z]|[0-9]/, '-', /[A-Z]|[a-z]|[0-9]/, '-', /[A-Z]|[a-z]|[0-9]/, '-', /[A-Z]|[a-z]|[0-9]/, '-', /[A-Z]|[a-z]|[0-9]/, '-', /[A-Z]|[a-z]|[0-9]/];


	constructor(private formBuilder: FormBuilder, private endpoint: AppService, private modalInj: NgbModal) {
		this.endpoint.pageTitle = 'Verificación de Dos Pasos';
		this.submitted = false;
		this.error = null;
		this.loading = false
	}

	ngOnInit() {
		this.steps_2Form = this.formBuilder.group({
			code: [
				'',
				[
					Validators.required
				]
			],
		});
	}

	get f() { return this.steps_2Form.controls; }

	steps_2() {
		this.submitted = true;

		if (this.steps_2Form.invalid) {
			return;
		}

		var params = {
			code: this.f.code.value
		};

		this.loading = true;
		var response;
		this.endpoint.steps_2(params)
			.subscribe(
				data => response = data,
				err => {
					if (err.error.code == "steps-2") {
						this.endpoint.redirect_to('/');
					}
					
					Swal.fire({
						title: err.error.title,
						text: err.error.message,
						type: 'error',
						timer: 10000,
						confirmButtonText: 'Listo',
						confirmButtonColor: '#66c8c8',
					}).then((result) => {
					});

					this.error = err.error.error;
					this.loading = false;
				},
				() => {
					this.endpoint.set_session({ token: response.token });

					if (response.first_login == true) {
						this.endpoint.redirect_to('/primer_inicio');
					} else {
						this.endpoint.redirect_to('');
					}
				}
			);
	};


	send_steps_2() {
		this.loading = true;
		var response;
		this.endpoint.send_steps_2()
			.subscribe(
				data => response = data,
				err => {
					if (err.error.code == "steps-2") {
						this.endpoint.redirect_to('/');
					}
					Swal.fire({
						title: err.error.title,
						text: err.error.message,
						type: 'error',
						timer: 10000,
						confirmButtonText: 'Listo',
						confirmButtonColor: '#66c8c8',
					}).then((result) => {
					});
					this.error = err.error.error;
					this.loading = false;
				},
				() => {
					Swal.fire({
						title: response.title,
						html: response.message,
						type: 'info',
						confirmButtonText: 'Listo',
						confirmButtonColor: '#66c8c8',
					}).then((result) => {
					});
					this.submitted = false;
					this.loading = false;
				}
			);
	}
}
