<div class="pc-layout">
    <div class="pc-header">
        <div class="pch-icons">
            <a class="fa-duotone fa-share-from-square" title="Compartir"></a>
            <i class="fa-duotone fa-print ml-2" title="Imprimir receta"></i>
            <a class="fa-duotone fa-times fa-xl ml-3" title="Cerrar consulta" routerLink="/expediente/consultas"></a>
        </div>

        <a class="pch-title-1" [routerLink]="'/perfil/' + data?.consult?.doctor_username" target="_blank" role="button">{{ data?.consult?.doctor_gender == 1 ? "DRA." : "DR." }}</a>
        <a class="pch-title-2" [routerLink]="'/perfil/' + data?.consult?.doctor_username" target="_blank" role="button">{{ data?.consult?.doctor_name | uppercase }}</a>
        <a class="pch-title-3" [routerLink]="'/perfil/' + data?.consult?.doctor_username" target="_blank" role="button">{{ data?.consult?.doctor_specialty }}</a>
        <div class="pch-date"> {{ data?.consult?.created_at | shortDate }}</div>

        <div class="pch-bar"></div>
    </div>
    <div class="pc-body">
        <div class="pcb-patient">
            <div class="d-flex">
                <div class="pcbp-label">
                    PACIENTE:
                </div>
                <div class="pcbp-name">
                    {{ data?.consult?.patient_name | names | uppercase }}
                </div>
            </div>
            <div class="d-flex">
                <div class="pcbp-gender">
                     {{ data?.consult?.patient_gender | catalog: get_catalogs("genders") }}
                </div>
                <div class="pcbp-dot">•</div>
                <div class="pcbp-age">
                    {{
                        data?.consult?.age > 0
                        ? data?.consult?.age + (data?.consult?.age > 1 ? " años" : " año")
                        : data?.consult?.age_month > 0
                        ? data?.consult?.age_month + (data?.consult?.age_month > 1 ? " meses" : " mes") + 
                        (data?.consult?.age_day > 0
                        ? ", " + data?.consult?.age_day + (data?.consult?.age_day > 1 ? " días" : " día") : "")
                        : data?.consult?.age_day > 0
                        ? data?.consult?.age_day + (data?.consult?.age_day > 1 ? " días" : " día")
                        : "Recién nacido"
                        }}
                </div>
            </div>
        </div>

        <ng-container *ngIf="data?.consult?.prescription_list?.list?.length > 0">
            <div class="pcb-medicines mt-3">
                <div class="pcbm-medicines-list mt-2">
                    <ng-container *ngFor="let item of data?.consult?.prescription_list?.list">
                        <li class="pcbmml-item">
                            <div class="m-name">
                                <ng-container *ngIf="!!item.brand">
                                    {{item.brand}}
                                    <div class="m-tag">
                                        - {{item.presentation }} ({{item.principle }})
                                        <ng-container *ngIf="['Crema', 'Jarabe'].includes(item.presentation)">({{item.concentration}})</ng-container>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!item.brand">
                                    {{item.principle }}
                                    <div class="m-tag">
                                        - {{item.presentation }}
                                        <ng-container *ngIf="['Crema', 'Jarabe'].includes(item.presentation)">({{item.concentration}})</ng-container>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="m-instructions">
                                <ng-container *ngIf="item.presentation == 'Crema'">
                                    {{item.dosage }} aplicaciones
                                </ng-container>
                                <ng-container *ngIf="item.presentation == 'Suspensión'">
                                    {{item.dosage }} {{item.dosage_unit | lowercase }}
                                </ng-container>
                                <ng-container *ngIf="item.presentation != 'Crema' && item.presentation != 'Suspensión'">
                                    Tomar {{item.dosage }} {{item.dosage_unit | lowercase }}
                                </ng-container>
                                vía {{item.way | lowercase }} cada {{item.frecuency | lowercase }} {{item.frecuency_unit | lowercase }}<ng-container *ngIf="!item.continues">
                                    por {{item.period | lowercase }} {{item.period_unit | lowercase }}</ng-container><ng-container *ngIf="!!item.continues">, uso continuo</ng-container>
                            </div>
                            <div class="m-comments" *ngIf="!!item.instructions">
                                <div class="m-c-t">Instrucciones:</div>
                                {{item.instructions }}
                            </div>
                            <div class="m-comments" style="vertical-align: top;" *ngIf="!!item.comments">
                                <div class="m-c-t">Comentarios:</div>
                                {{item.comments }}
                            </div>
                        </li>
                    </ng-container>
                </div>
            </div>
        </ng-container>

    </div>


    <div class="pc-footer">
        <div class="pcf-logo">
            <div class="pcfl-isologo">
                <img src="assets/images/isologo-blue.svg" alt="Unimed">
            </div>

            <a class="pcfl-text" href="https://unimedapp.com" target="_blank" role="button">unimedapp.com</a>
        </div>
        <div class="pcf-band"></div>
    </div>

</div>