import { AppService } from './app.service';
import * as i0 from "@angular/core";
import * as i1 from "./app.service";
export class PdfService {
    constructor(endpoint) {
        this.endpoint = endpoint;
    }
    create(load, endpoint) {
        this.fetch(load, endpoint, data => {
            window.open(URL.createObjectURL(data), '_blank');
        });
    }
    fetch(load, endpoint, success = (data) => console.info(data), error = (err) => console.error(err)) {
        this.endpoint[endpoint](load).subscribe(data => success(data), err => error(err));
    }
    jwt(load, success = (data) => console.info(data), error = (err) => console.error(err)) {
        this.endpoint.jwt_parser(load).subscribe(data => success(data), err => error(err));
    }
    print(pdf) {
        if (pdf) {
            pdf.print();
        }
    }
    download(pdf, name) {
        if (pdf) {
            pdf.download(name);
        }
    }
    open(pdf) {
        if (pdf) {
            pdf.open();
        }
    }
}
PdfService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PdfService_Factory() { return new PdfService(i0.ɵɵinject(i1.AppService)); }, token: PdfService, providedIn: "root" });
