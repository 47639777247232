import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  constructor(private endpoint: AppService, private pageService: PageService) {
    this.pageService.set_sidebar_2_state({
      show: false,
      scroll: true, expand: false
    })
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

}
