import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { SweelService } from 'src/app/shared/services/sweel.service';
import { DatatableComponent, datatable_type } from 'src/app/shared/ui/datatable/datatable.component';
import { SearchButtonComponent } from 'src/app/shared/ui/search_button/search_button.component';

@Component({
  selector: 'app-medicines',
  templateUrl: './medicines.component.html',
  styleUrls: ['./medicines.component.scss']
})
export class MedicinesComponent implements OnInit {
  private patient_subscription: Subscription;
  public patient_id: any;
  public patient_data: any;

  private page_subscription: Subscription;
  public page_state: any;

  @ViewChild('search_button_ref', { static: false }) search_button_ref: SearchButtonComponent;
  @ViewChild('datatable_ref', { static: false }) datatable_ref: DatatableComponent;
  public datatable: datatable_type;
  public data: any = {
    list: [],
    count: 0,
    skip: 0,
    loaded: false
  };

  @ViewChild('createModal', { static: false }) createModal: NgbModalRef;
  @ViewChild('updateModal', { static: false }) updateModal: NgbModalRef;
  @ViewChild('showModal', { static: false }) showModal: NgbModalRef;
  public selected_item: any;

  constructor(private endpoint: AppService, private patientService: PatientService, private pageService: PageService, private toast: ToastrService,
    private sweel: SweelService) {
    this.datatable_config();
  }

  ngOnInit() {
    this.patient_subscription = this.patientService.patient_data_subscription.subscribe(
      data => this.patient_data = data
    );
    this.page_subscription = this.pageService.current.subscribe(
      state => this.page_state = state
    );
  }

  datatable_config() {
    this.datatable = {
      configs: {
        title: "Medicinas",
        //icon: "fa-duotone fa-address-book",
        paginate: false,
        search: false
      },
      filters: {
        limit: 20,
        skip: 0
      },
      headers: [
        {
          label: "Nombre",
          name: "name",
          order_by: "name"
        },
        {
          label: "Cantidad",
          name: "amount",
          order_by: "amount"
        },
        {
          label: "Presentación",
          name: "presentation",
          order_by: "presentation",
        },
        {
          label: "Concentración",
          name: "concentration",
          order_by: "concentration",
        },
        {
          label: "Vía",
          name: "way",
          order_by: "way",
        },
        {
          label: "Frecuencia",
          name: "frecuency_parsed",
          order_by: "frecuency",
        },
        {
          label: "Duración",
          name: "days_parsed",
          order_by: "days",
        },
        {
          label: "Estado",
          name: "verification",
          order_by: "verification",
        }
      ],
      events:
        [
          {
            name: "Detalles",
            icon: "fa-duotone fa-file-circle-info text-info"
          },
          {
            name: "Modificar",
            icon: "fa-duotone fa-edit text-warning"
          },
          {
            name: "Eliminar",
            icon: "fa-duotone fa-trash-alt text-danger",
          },
        ]
    };
  }

  on_update() {
    if (this.datatable_ref) this.datatable_ref.update();
  }

  update(data) {
    if (this.patient_data.general.id_user) {
      if (this.search_button_ref) data.search_word = this.search_button_ref.data;
      var load = {
        id_patient: this.patient_data.general.id_user,
        ...data,
      };
      this.endpoint.get_medicines_prescription(load).subscribe(
        data => this.data = data,
        err => console.error(err)
      );
    }
  }

  events(data) {
    this.selected_item = data.item;
    if (data.name == "Detalles" || data.name == "row_click") {
      this.pageService.openModal(this.showModal, "lg");
    }
    if (data.name == "Eliminar") {
      this.sweel.confirm("Eliminar Medicina", "Por favor confirme que desea eliminar la medicina seleccionada?", "warning").then(
        res => {
          if (res) {
            this.delete(data.item.id);
          }
        }
      )
    }

    if (data.name == "Modificar") {
      this.selected_item = data.item;
      this.pageService.openModal(this.updateModal, "lg");
    }
  }

  delete(id: number) {
    let params = {
      id: id
    }
    this.endpoint.delete_medicines_prescription(params).subscribe(
      res => {
        if (res) {
          this.toast.success("Operación Exitosa", "Medicina eliminada correctamente.");
          this.on_update();
        }
      }
    )
  }

  create() {
    this.pageService.openModal(this.createModal, "lg");
  }
}
