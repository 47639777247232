<ng-container *ngIf="patient_state && patient_state.loaded">
    <div class="brief-user">
        <div class="d-flex p-3" *ngIf="patient_state.general">
            <div class="d-flex my-auto">
                <avatar [name]="patient_state?.general?.firstname" [image]="patient_state?.general?.image"
                        [configs]="avatar_configs"></avatar>
            </div>
            <div class="d-flex flex-column ml-2">
                <div class="mt-auto mb-0 firstname">
                    {{ patient_state.general.firstname | names }}
                </div>
                <div class="mt-auto lastname">
                    {{ patient_state.general.lastname | lowercase }}
                </div>

                <div class="mt-1 text-muted">
                    <div class="years">
                        {{
                        patient_state.general.age > 0
                        ? patient_state.general.age + (patient_state.general.age > 1 ? " años" : " año")
                        : patient_state.general.age_month > 0
                        ? patient_state.general.age_month + (patient_state.general.age_month > 1 ? " meses" : " mes") + 
                        (patient_state.general.age_day > 0
                        ? ", " + patient_state.general.age_day + (patient_state.general.age_day > 1 ? " días" : " día") : "")
                        : patient_state.general.age_day > 0
                        ? patient_state.general.age_day + (patient_state.general.age_day > 1 ? " días" : " día")
                        : "Recién nacido"
                        }}
                    </div>
                </div>
                <div class="mt-1 mb-auto text-muted">
                    <div class="date">
                        {{ patient_state.general.birthdate | microDate }}
                    </div>
                </div>
            </div>
        </div>

        <hr class="mt-2 mb-0 text-center" />
    </div>
    <app-allergies_brief></app-allergies_brief>
    <app-pathologies_brief></app-pathologies_brief>
    <app-contact_doctor_brief></app-contact_doctor_brief>
    <app-contact_emergency_brief></app-contact_emergency_brief>
</ng-container>