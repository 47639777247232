<div
     *ngIf="page_state.sidebar_2.show"
     [class.expand]="page_state.sidebar_2.expand"
     class="second-side-menu"
     style="position: fixed;"
     [class.searching]="page_state.searching">

    <div class="slimscroll-menu">
        <div class="unimed-left-sidebar p-2 bg-white">
            <ng-container class="mb-2">
                <app-brief></app-brief>
            </ng-container>
        </div>

        <div class="unimed-left-sidebar p-2 bg-white" *ngIf="showGrowthCurves">
            <app-growth_curves_brief *ngIf="showGrowthCurves"></app-growth_curves_brief>
        </div>
    </div>

</div>