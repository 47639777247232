<div class="h-100">
    <div class="row">
        <div class="col-12">
            <div class="card-box text-center radius" style="border: none !important" *ngIf="loading || error">
                <div class="d-flex justify-content-center align-items-center">
                    <div *ngIf="loading || error; then requestholderbig"></div>
                </div>
            </div>
            <div class="table-responsive">
                <!-- TABLE -->
                <table
                       *ngIf="!loading && !error && data.list && data.list.length > 0"
                       class="table table-hover table-centered table-borderless datatable-local mb-0"
                       [class.no_background]="configs.no_background"
                       cellspacing="0"
                       id="data-table"
                       role="grid">
                    <!-- HEAD -->
                    <thead *ngIf="configs.headers == undefined ? true : configs.headers">
                        <tr role="row">
                            <th
                                *ngFor="let header of headers; let i = index"
                                (click)="orderBy(header.order_by)"
                                [class.first]="i == 0"
                                [class.last]="events.length == 0 && i == headers.length - 1"
                                [class.selected]="filters.order_by == header.order_by"
                                colspan="1">
                                {{ header.label }}
                                <i *ngIf="filters.order_by == header.order_by" [class.fa-sort-alpha-up]="!filters.order_asc" [class.fa-sort-alpha-down]="filters.order_asc" class="fas"></i>
                            </th>
                            <th style="width: 1%" nowrap class="text-center no-select last options" colspan="999" *ngIf="events.length > 0">Opciones</th>
                        </tr>
                    </thead>
                    <!-- / HEAD -->

                    <!-- BODY -->
                    <tbody class="font-14">
                        <ng-container *ngFor="let item of data.list; let i = index">
                            <tr role="row" class="pointer" [class.edge-shadow-bottom]="content && item.show_content">
                                <ng-container *ngFor="let header of headers; let i_h = index">
                                    <td
                                        class=""
                                        [class.first]="i == data.list.length - 1 && i_h == 0"
                                        [class.last]="events.length < 1 && i_h == headers.length - 1 && i == data.list.length - 1"
                                        (click)="row_click(item)">
                                        <ng-container *ngIf="header.type != 'date' && header.type != 'disabled' && header.type != 'boolean' && header.type != 'catalog' && header.type != 'names' && header.type != 'allergy'">
                                            {{ item[header.name] }}
                                        </ng-container>
                                        <ng-container *ngIf="header.type == 'disabled'">
                                            {{ item[header.name] ? "Deshabilitado" : "Habilitado" }}
                                        </ng-container>
                                        <ng-container *ngIf="header.type == 'date'">
                                            {{ item[header.name] | microDate }}
                                        </ng-container>
                                        <ng-container *ngIf="header.type == 'boolean'">
                                            {{ item[header.name] | boolean }}
                                        </ng-container>
                                        <ng-container *ngIf="header.type == 'catalog'">
                                            {{ item[header.name] | catalog: header.catalog }}
                                        </ng-container>
                                        <ng-container *ngIf="header.type == 'names'">
                                            {{ item[header.name] | names }}
                                        </ng-container>
                                        <ng-container *ngIf="header.type == 'allergy'">
                                            <div class="allergy" [class.a-1]="item[header.name] == 1" [class.a-2]="item[header.name] == 2" [class.a-3]="item[header.name] == 3" [class.a-4]="item[header.name] == 4">
                                                <i class="fa-solid fa-circle my-auto"></i>
                                                <i class="fa-solid fa-circle my-auto"></i>
                                                <i class="fa-solid fa-circle my-auto"></i>
                                                <i class="fa-solid fa-circle my-auto"></i>
                                                <div class="my-auto ml-1">{{ item[header.name] | catalog: header.catalog }}</div>
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                <!-- EVENTS -->
                                <ng-container *ngFor="let e of events; let i_e = index">
                                    <td *ngIf="!(e.show ? e.show(item) : true)"></td>
                                    <td *ngIf="e.show ? e.show(item) : true" [class.last]="i_e == events.length - 1 && i == data.list.length - 1">
                                        <div class="d-flex justify-content-end row">
                                            <a *ngIf="!e.disabled || !(e.disabled && e.disabled(item))" (click)="event(e.name, item)" [title]="e.name" class="waves-effect mx-2">
                                                <i class="{{ e.icon }}"></i>
                                            </a>
                                            <a *ngIf="e.disabled && e.disabled(item)" [title]="e.name" class="waves-effect mx-2"> <i class="fa-duotone fa-minus text-muted"></i> </a>
                                        </div>
                                    </td>
                                </ng-container>
                                <!-- / EVENTS -->
                            </tr>
                            <tr role="row" *ngIf="content && item.show_content">
                                <td class="content" [attr.colspan]="headers.length + events.length">
                                    {{ content(item) }}
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                    <!-- / BODY -->
                </table>

                <!-- CUSTOM HOLDER -->
                <div class="text-center radius" *ngIf="(!loading && !error && data.list && data.list.length <= 0) || !data.list">
                    <div class="d-flex justify-content-center align-items-center">
                        <app-custom-holder [icon]="'fa-duotone fa-light-emergency-on fa-2x text-warning'" [icon_show]="true" [title]="'h5'" [title_text]="'No hay ' + configs.title" [title_show]="true" [inline]="true">
                        </app-custom-holder>
                    </div>
                </div>
                <!-- / CUSTOM HOLDER -->
            </div>
            <!-- / TABLE -->
        </div>
    </div>

    <!-- PAGINATION -->
    <div class="row mt-2" *ngIf="!loading && !error && data.count && data.count > 0 && configs.paginate">
        <!--
    <div class="col-sm-12 col-md-5">
      <div class="dataTables_info pl-2" id="data-table_info" role="status" aria-live="polite">Mostrando
        {{(filters.limit * filters.skip) + 1}} a
        {{(filters.limit * filters.skip) + filters.limit > data.count ? data.count : (filters.limit * filters.skip) + filters.limit}} de {{data.count}}
        {{configs.title}}
      </div>
    </div>
  -->
        <div class="col-sm-12 col-md-12">
            <div class="dataTables_paginate paging_simple_numbers d-flex" id="data-table_paginate">
                <ul class="pagination pagination-rounded mx-auto">
                    <li (click)="go(-1)" class="paginate_button pointer page-item previous" *ngIf="filters.skip > 0" id="data-table_previous">
                        <a tabindex="0" class="page-link"><i class="fa-duotone fa-chevron-left"></i></a>
                    </li>
                    <!-- <li (click)='goFirst()' class="paginate_button pointer page-item first" *ngIf='filters.skip > 0' id="data-table_previous"><a tabindex="1" class="page-link">Inicio</a></li> -->
                    <li
                        *ngIf="possible_indexes[0] >= 0 && possible_indexes[0] <= divider"
                        (click)="go(possible_indexes[0] - filters.skip)"
                        class="paginate_button pointer page-item"
                        [class.active]="filters.skip == possible_indexes[0]">
                        <a tabindex="2" class="page-link">{{ possible_indexes[0] + 1 }}</a>
                    </li>
                    <li
                        *ngIf="possible_indexes[1] >= 0 && possible_indexes[1] <= divider"
                        (click)="go(possible_indexes[1] - filters.skip)"
                        class="paginate_button pointer page-item"
                        [class.active]="filters.skip == possible_indexes[1]">
                        <a tabindex="3" class="page-link">{{ possible_indexes[1] + 1 }}</a>
                    </li>
                    <li
                        *ngIf="possible_indexes[2] >= 0 && possible_indexes[2] <= divider"
                        (click)="go(possible_indexes[2] - filters.skip)"
                        class="paginate_button pointer page-item"
                        [class.active]="filters.skip == possible_indexes[2]">
                        <a tabindex="4" class="page-link">{{ possible_indexes[2] + 1 }}</a>
                    </li>
                    <li
                        *ngIf="possible_indexes[3] >= 0 && possible_indexes[3] <= divider"
                        (click)="go(possible_indexes[3] - filters.skip)"
                        class="paginate_button pointer page-item"
                        [class.active]="filters.skip == possible_indexes[3]">
                        <a tabindex="5" class="page-link">{{ possible_indexes[3] + 1 }}</a>
                    </li>
                    <li
                        *ngIf="possible_indexes[4] >= 0 && possible_indexes[4] <= divider"
                        (click)="go(possible_indexes[4] - filters.skip)"
                        class="paginate_button pointer page-item"
                        [class.active]="filters.skip == possible_indexes[4]">
                        <a tabindex="6" class="page-link">{{ possible_indexes[4] + 1 }}</a>
                    </li>
                    <li
                        *ngIf="possible_indexes[5] >= 0 && possible_indexes[5] <= divider"
                        (click)="go(possible_indexes[5] - filters.skip)"
                        class="paginate_button pointer page-item"
                        [class.active]="filters.skip == possible_indexes[5]">
                        <a tabindex="7" class="page-link">{{ possible_indexes[5] + 1 }}</a>
                    </li>
                    <li
                        *ngIf="possible_indexes[6] >= 0 && possible_indexes[6] <= divider"
                        (click)="go(possible_indexes[6] - filters.skip)"
                        class="paginate_button pointer page-item"
                        [class.active]="filters.skip == possible_indexes[6]">
                        <a tabindex="8" class="page-link">{{ possible_indexes[6] + 1 }}</a>
                    </li>
                    <!-- <li (click)='goLast()' class="paginate_button pointer page-item last" *ngIf='(filters.limit * filters.skip) + filters.limit < data.count' id="data-table_previous"><a tabindex="7" class="page-link">Final</a></li> -->
                    <li (click)="go(1)" class="paginate_button pointer page-item next" *ngIf="filters.limit * filters.skip + filters.limit < data.count" id="data-table_next">
                        <a tabindex="8" class="page-link"><i class="fa-duotone fa-chevron-right"></i></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- / PAGINATION -->
</div>

<ng-template #requestholdersmall>
    <app-spinner-small [loading]="loading" [class]="'h1'"></app-spinner-small>
    <app-error-small [error]="error" [class]="'h1'"></app-error-small>
</ng-template>
<ng-template #requestholderbig>
    <app-spinner-big [loading]="loading" [title]="'Cargando...'" [inline]="true"></app-spinner-big>
    <app-error-big [error]="error" [title]="error_desc" [inline]="true"></app-error-big>
</ng-template>