import { CatalogsService } from "src/app/services/catalogs.service";
import { CustomClass } from "./custom-class";

export interface VitalsInterface {
    id_consult: any;
    glasgow_total: string;
    glasgow_eye: number;
    glasgow_move: number;
    glasgow_talk: number;
    pulse: string;
    id_pulse_unit: string;
    sat02: string;
    temp: string;
    sistolic_pressure: number | string;
    diastolic_pressure: number | string;
    PAM: number | string;
    id_pam_unit?: string;
    id_sistolic_pressure_unit: string;
    id_diastolic_pressure_unit: string;
    id_pressure_pulse_unit: string;
    id_temp_unit: string;
    id_heart_rate_unit: string;
    id_breathing_rate_unit: string;
    pressure_pulse: number | string;
    heart_rate: string;
    breathing_rate: string;
    eye_vision_right: string;
    eye_vision_left: string;
    toogle_pressure: any;
};

interface ToStringInterface {
    glasgow_total: string;
    glasgow_eye: string;
    glasgow_move: string;
    glasgow_talk: string;
    pulse: string;
    sat02: string;
    temp: string;
    sistolic_pressure: string;
    diastolic_pressure: string;
    PAM: string;
    pressure_pulse: string;
    heart_rate: string;
    breathing_rate: string;
    eye_vision_right: string;
    eye_vision_left: string;
};

export class VitalsClass extends CustomClass implements VitalsInterface {
    public id_consult: any;
    public glasgow_total: string;
    public glasgow_eye: number;
    public glasgow_move: number;
    public glasgow_talk: number;
    public pulse: string;
    public id_pulse_unit: string;
    public sat02: string;
    public temp: string;
    public sistolic_pressure: number | string;
    public diastolic_pressure: number | string;
    public PAM: number | string;
    public id_pam_unit?: string;
    public id_sistolic_pressure_unit: string;
    public id_diastolic_pressure_unit: string;
    public id_pressure_pulse_unit: string;
    public id_temp_unit: string;
    public id_heart_rate_unit: string;
    public id_breathing_rate_unit: string;
    public pressure_pulse: number | string;
    public heart_rate: string;
    public breathing_rate: string;
    public eye_vision_right: string;
    public eye_vision_left: string;
    public toogle_pressure: any;

    constructor(private catalogsService: CatalogsService, data?: VitalsInterface) {
        super();
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: VitalsInterface): void {
        super.overwrite(data);
        this.id_consult = data.id_consult;
        this.glasgow_total = data.glasgow_total;
        this.glasgow_eye = data.glasgow_eye;
        this.glasgow_move = data.glasgow_move;
        this.glasgow_talk = data.glasgow_talk;
        this.pulse = data.pulse;
        this.id_pulse_unit = data.id_pulse_unit ? data.id_pulse_unit : "1";
        this.sat02 = data.sat02;
        this.temp = data.temp ? data.temp : "";
        this.sistolic_pressure = data.sistolic_pressure;
        this.diastolic_pressure = data.diastolic_pressure;
        this.PAM = data.PAM;
        this.id_pam_unit = "1";
        this.id_sistolic_pressure_unit = data.id_sistolic_pressure_unit ? data.id_sistolic_pressure_unit : "1";
        this.id_diastolic_pressure_unit = data.id_diastolic_pressure_unit ? data.id_diastolic_pressure_unit : "1";
        this.id_pressure_pulse_unit = data.id_pressure_pulse_unit ? data.id_pressure_pulse_unit : "1";
        this.id_temp_unit = data.id_temp_unit ? data.id_temp_unit : "1";
        this.id_heart_rate_unit = data.id_heart_rate_unit ? data.id_heart_rate_unit : "1";
        this.id_breathing_rate_unit = data.id_breathing_rate_unit ? data.id_breathing_rate_unit : "1";
        this.pressure_pulse = data.pressure_pulse;
        this.heart_rate = data.heart_rate;
        this.breathing_rate = data.breathing_rate;
        this.eye_vision_right = data.eye_vision_right;
        this.eye_vision_left = data.eye_vision_left;
        this.toogle_pressure = true;

        this.glasgow_calc();
        this.pressure_calc();
    }

    reset(): void {
        super.reset();
        this.id_consult = null;
        this.glasgow_total = "";
        this.glasgow_eye = 0;
        this.glasgow_move = 0;
        this.glasgow_talk = 0;
        this.pulse = "";
        this.id_pulse_unit = "1";
        this.sat02 = "";
        this.temp = "";
        this.sistolic_pressure = "";
        this.diastolic_pressure = "";
        this.PAM = "";
        this.id_pam_unit = "1";
        this.id_sistolic_pressure_unit = "1";
        this.id_diastolic_pressure_unit = "1";
        this.id_pressure_pulse_unit = "1";
        this.id_temp_unit = "1";
        this.id_heart_rate_unit = "1";
        this.id_breathing_rate_unit = "1";
        this.pressure_pulse = "";
        this.heart_rate = "";
        this.breathing_rate = "";
        this.eye_vision_right = "";
        this.eye_vision_left = "";
        this.toogle_pressure = true;
    }

    format(): VitalsInterface {
        return this as VitalsInterface;
    }

    valid(): boolean {
        return true;
    }

    to_string(): ToStringInterface {
        this.glasgow_calc();
        this.pressure_calc();

        let heart_rate = this.catalogsService.catalogs.units_heart_rate.find(v => v.id == this.id_heart_rate_unit);
        let breathing_rate = this.catalogsService.catalogs.units_breathing_rate.find(v => v.id == this.id_breathing_rate_unit);
        let pulse = this.catalogsService.catalogs.units_pulse.find(v => v.id == this.id_pulse_unit);
        let temp = this.catalogsService.catalogs.units_temp.find(v => v.id == this.id_temp_unit);
        let sistolic_pressure = this.catalogsService.catalogs.units_sistolic_pressure.find(v => v.id == this.id_sistolic_pressure_unit);
        let diastolic_pressure = this.catalogsService.catalogs.units_diastolic_pressure.find(v => v.id == this.id_diastolic_pressure_unit);
        let pressure_pulse = this.catalogsService.catalogs.units_sistolic_pressure.find(v => v.id == this.id_pressure_pulse_unit);
        let glasgow_eye = this.catalogsService.catalogs.units_glasgow_eye.find(v => v.id == this.glasgow_eye);
        let glasgow_move = this.catalogsService.catalogs.units_glasgow_move.find(v => v.id == this.glasgow_move);
        let glasgow_talk = this.catalogsService.catalogs.units_glasgow_talk.find(v => v.id == this.glasgow_talk);

        let data: ToStringInterface = {
            glasgow_total: this.glasgow_total && this.glasgow_total != "0/0" ? (this.glasgow_total + "") : null,
            glasgow_eye: this.glasgow_eye ? (glasgow_eye ? glasgow_eye.name + " " + glasgow_eye.code : "") : null,
            glasgow_move: this.glasgow_move ? (glasgow_move ? glasgow_move.name + " " + glasgow_move.code : "") : null,
            glasgow_talk: this.glasgow_talk ? (glasgow_talk ? glasgow_talk.name + " " + glasgow_talk.code : "") : null,
            pulse: this.pulse ? (this.pulse + (pulse ? " " + pulse.code : "")) : null,
            sat02: this.sat02 ? (this.sat02 + (this.sat02 ? "%" : "")) : null,
            temp: this.temp ? (this.temp + (temp ? " " + temp.code : "")) : null,
            sistolic_pressure: this.sistolic_pressure ? (this.sistolic_pressure + (sistolic_pressure ? " " + sistolic_pressure.code : "")) : null,
            diastolic_pressure: this.diastolic_pressure ? (this.diastolic_pressure + (diastolic_pressure ? " " + diastolic_pressure.code : "")) : null,
            PAM: this.PAM ? (this.PAM + "") : null,
            pressure_pulse: this.pressure_pulse ? (this.pressure_pulse + (pressure_pulse ? " " + pressure_pulse.code : "")) : null,
            heart_rate: this.heart_rate ? (this.heart_rate + (heart_rate ? " " + heart_rate.code : "")) : null,
            breathing_rate: this.breathing_rate ? (this.breathing_rate + (breathing_rate ? " " + breathing_rate.code : "")) : null,
            eye_vision_right: this.eye_vision_right ? (this.eye_vision_right + "") : null,
            eye_vision_left: this.eye_vision_left ? (this.eye_vision_left + "") : null,
        };

        return data;
    }

    raw(): VitalsInterface {
        return {
            id_consult: this.id_consult,
            glasgow_total: this.glasgow_total,
            glasgow_eye: this.glasgow_eye,
            glasgow_move: this.glasgow_move,
            glasgow_talk: this.glasgow_talk,
            pulse: this.pulse,
            id_pulse_unit: this.id_pulse_unit,
            sat02: this.sat02,
            temp: this.temp,
            sistolic_pressure: this.sistolic_pressure,
            diastolic_pressure: this.diastolic_pressure,
            PAM: this.PAM,
            id_sistolic_pressure_unit: this.id_sistolic_pressure_unit,
            id_diastolic_pressure_unit: this.id_diastolic_pressure_unit,
            id_pressure_pulse_unit: this.id_pressure_pulse_unit,
            id_temp_unit: this.id_temp_unit,
            id_heart_rate_unit: this.id_heart_rate_unit,
            id_breathing_rate_unit: this.id_breathing_rate_unit,
            pressure_pulse: this.pressure_pulse,
            heart_rate: this.heart_rate,
            breathing_rate: this.breathing_rate,
            eye_vision_right: this.eye_vision_right,
            eye_vision_left: this.eye_vision_left,
            toogle_pressure: this.toogle_pressure,
        };
    }

    glasgow_calc() {
        let glasgow_amount =
            (this.glasgow_eye > 0 ? 4 : 0) +
            (this.glasgow_move > 0 ? 6 : 0) +
            (this.glasgow_talk > 0 ? 5 : 0)
            ;

        this.glasgow_total =
            (+this.glasgow_eye + +this.glasgow_move + +this.glasgow_talk) +
            "/" +
            glasgow_amount;
    }

    pressure_calc() {
        if (this.sistolic_pressure && this.diastolic_pressure) {
            this.PAM = parseFloat(((+parseFloat((this.sistolic_pressure + "").replace(/,/g, "")) + (2 * +parseFloat((this.diastolic_pressure + "").replace(/,/g, "")))) / 3).toFixed(2));
            this.pressure_pulse = parseFloat((parseFloat((this.sistolic_pressure + "").replace(/,/g, "")) - parseFloat((this.diastolic_pressure + "").replace(/,/g, ""))).toFixed(2));
        }
    }

    temp_calc() {
        if (this.temp) {
            let unit = this.catalogsService.catalogs.units_temp.find(value => value.id == this.id_temp_unit);
            this.temp = this.eval(unit.conv.replace("U", this.temp));
        }
    }

    eval(fn) {
        return new Function('return ' + fn)();
    }
}
