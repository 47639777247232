import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ConsultGeneralService } from 'src/app/pages/expedient/new_consult/general/consult.service';
import { ExpedientService } from 'src/app/services/expedient.service';
import { PageService } from '../../services/page.service';
import { PatientService } from '../../services/patient-service.service';

@Injectable({
  providedIn: 'root'
})
export class PatientInternalGuard implements CanActivate {

  constructor(private pageService: PageService, private patientService: PatientService, private expedientService: ExpedientService, private consultService: ConsultGeneralService) { }

  canActivate() {
    this.pageService.set_consult(false);
    this.pageService.set_profile(false);
    this.pageService.set_expedient(true);
    this.patientService.init((data) => {
      this.expedientService.init({
        version: "expedient",
        method: "show",
        id_user: data.general.id_user
      });
      // this.consultService.new_consult(null, false);
    });
    return true;
  }


}
