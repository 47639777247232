import { CatalogsService } from "src/app/services/catalogs.service";
import { CustomClass } from "../custom-class";

export interface GrowthCurveInterface {
    id: any,
    id_user: any,
    id_consult: any,
    weight: string,
    id_weight_unit?: any,
    height: string,
    id_height_unit?: any,
    head: string,
    id_head_unit?: any,
    IMC: string,
    WFH: string,
    ind?: any,
    date: Date,
    created_at: Date,
    created_by: any
}

interface ToStringInterface extends GrowthCurveInterface { }

export class GrowthCurveClass extends CustomClass implements GrowthCurveInterface {
    public id: any;
    public id_user: any;
    public id_consult: any;
    public weight: string;
    public id_weight_unit?: string;
    public height: string;
    public id_height_unit?: string;
    public head: string;
    public id_head_unit?: string;
    public IMC: string;
    public ind ?: any;
    public WFH: string;
    public date: Date;
    public created_at: Date;
    public created_by: any;
    public units: any;

    constructor(private catalogsService: CatalogsService, data?: GrowthCurveInterface) {
        super();
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: GrowthCurveInterface): void {
        super.overwrite(data);
        this.id = data.id;
        this.id_user = data.id_user;
        this.id_consult = data.id_consult;
        this.weight = data.weight;
        this.id_weight_unit = data.id_weight_unit ? data.id_weight_unit : "4";
        this.height = data.height;
        this.id_height_unit = data.id_height_unit ? data.id_height_unit : "2";
        this.head = data.head;
        this.id_head_unit = data.id_head_unit ? data.id_head_unit : "2";
        this.IMC = (+data.IMC).toFixed(2) + "";
        this.WFH = (+data.WFH).toFixed(2) + "";
        this.ind = data.ind;
        this.date = data.date;
        this.created_at = data.created_at;
        this.created_by = data.created_by;
        this.units = 2;
    }

    reset(): void {
        super.reset();
        this.id = null;
        this.id_user = null;
        this.id_consult = null;
        this.weight = "";
        this.id_weight_unit = "1";
        this.height = "";
        this.id_height_unit = "3";
        this.head = "";
        this.id_head_unit = "3";
        this.IMC = "";
        this.WFH = "";
        this.ind = "";
        this.date = new Date();
        this.created_at = new Date();
        this.created_by = null;
        this.units = 2;
    }

    format(): GrowthCurveInterface {
        this.weight = this.weight.replace(/,/g, "");
        this.height = this.height.replace(/,/g, "");
        this.head = this.head.replace(/,/g, "");
        this.IMC = this.IMC.replace(/,/g, "");
        this.WFH = this.WFH.replace(/,/g, "");
        return this as GrowthCurveInterface;
    }

    valid(): boolean {
        return true;
    }

    to_string(): ToStringInterface {
        let data = this as GrowthCurveInterface;

        if (data.weight) data.weight += ` ${this.catalogsService.catalogs.units_weight.find(v => v.id == this.id_weight_unit).code}`;
        if (data.height) data.height += ` ${this.catalogsService.catalogs.units_height.find(v => v.id == this.id_height_unit).code}`;
        if (data.head) data.head += ` ${this.catalogsService.catalogs.units_height.find(v => v.id == this.id_head_unit).code}`;

        return data as ToStringInterface;
    }

    get_ideal_weight(value: number, id: string): number {
        let element = this.catalogsService.catalogs.units_weight.find(v => v.id == id);
        return element ? value * element.kg_conv : null;
    }

    get_ideal_height(value: number, id: string): number {
        let element = this.catalogsService.catalogs.units_height.find(v => v.id == id);
        return element ? value * element.m_conv : null;
    }

    get_ideal_head(value: number, id: string): number {
        let element = this.catalogsService.catalogs.units_height.find(v => v.id == id);
        return element ? value * element.m_conv : null;
    }

    raw(): GrowthCurveInterface {
        return {
            id: this.id,
            id_user: this.id_user,
            id_consult: this.id_consult,
            weight: this.weight,
            id_weight_unit: this.id_weight_unit,
            height: this.height,
            id_height_unit: this.id_height_unit,
            head: this.head,
            id_head_unit: this.id_head_unit,
            IMC: this.IMC,
            WFH: this.WFH,
            date: this.date,
            ind: this.ind,
            created_at: this.created_at,
            created_by: this.created_by,
        };
    }

    calculate_IMC(): void {
        if (this.height && this.weight) {
            let height = this.get_ideal_height(parseFloat(((this.height || 0) + "").replace(/,/g, "")), this.id_height_unit);
            let weight = this.get_ideal_weight(parseFloat(((this.weight || 0) + "").replace(/,/g, "")), this.id_weight_unit);
            this.IMC = (weight / (height * height)).toFixed(2) + "";
        } else {
            this.IMC = "";
        }
    }
}
