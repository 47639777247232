import { CatalogsService } from "src/app/services/catalogs.service";
import { CustomClass } from "../custom-class";

export interface ExperiencesInterface {
    id: any;
    id_user: any;
    id_type: any;
    name: string;
    company: string;
    place: string;
    current: any[];
    date_init: Date;
    date_finish: Date;
    description: string;
}

interface ToStringInterface {
    type: string;
    name: string;
    company: string;
    place: string;
    current: any;
    date_init: string | Date;
    date_finish: string | Date;
    description: string;
}

export class ExperiencesClass extends CustomClass implements ExperiencesInterface {
    public id: any;
    public id_user: any;
    public id_type: any;
    public name: string;
    public company: string;
    public place: string;
    public current: any;
    public date_init: Date;
    public date_finish: Date;
    public description: string;

    constructor(private catalogsService: CatalogsService, data?: ExperiencesInterface) {
        super();
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: ExperiencesInterface): void {
        super.overwrite(data);
        this.id = data.id;
        this.id_user = data.id_user;
        this.id_type = data.id_type;
        this.name = data.name;
        this.company = data.company;
        this.place = data.place;
        this.current = data.current;
        this.date_init = data.date_init;
        this.date_finish = data.date_finish;
        this.description = data.description;
    }

    reset(): void {
        super.reset();
        this.id = null;
        this.id_user = null;
        this.id_type = null;
        this.place = "";
        this.company = "";
        this.name = "";
        this.current = [];
        this.date_init = new Date();
        this.date_finish = new Date();
        this.description = "";

    }

    format(): ExperiencesInterface {
        return this as ExperiencesInterface;
    }

    valid(): boolean {
        return true;
    }

    to_string(): ToStringInterface {
        let type = this.catalogsService.catalogs.profiles_experiences_types.find((v) => v.id == this.id);

        let data: ToStringInterface = {
            type: type ? type.name : "",
            name: this.name,
            company: this.company,
            place: this.place,
            current: this.current,
            date_init: this.date_init,
            date_finish: this.date_finish,
            description: this.description,
        };
        return data as ToStringInterface;
    }

    raw(): ExperiencesInterface {
        return {
            id: this.id,
            id_user: this.id_user,
            id_type: this.id_type,
            company: this.company,
            name: this.name,
            place: this.place,
            current: this.current,
            date_init: this.date_init,
            date_finish: this.date_finish,
            description: this.description,
        };
    }
}
