import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormRenderComponent, form_type } from 'src/app/shared/ui/form_render/form_render.component';
import { DoctorService, DoctorState } from '../doctor.service';
import { ExpedientMetadata, ExpedientService } from 'src/app/services/expedient.service';
import { CatalogsService } from 'src/app/services/catalogs.service';

@Component({
	selector: 'app-module-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy, AfterViewInit {
	private expedient_subscription: Subscription;
	public expedient_state: ExpedientMetadata;

	private doctor_subscription: Subscription;
	public doctor_state: DoctorState;

	@ViewChild('form_ref', { static: false }) form_ref: FormRenderComponent;
	public form_config: form_type;
	public doctors: any[];
	public doctors_loading: boolean;
	public doctors_timeout: any;

	constructor(private moduleService: DoctorService, private expedientService: ExpedientService, private catalogsService: CatalogsService) {
		this.doctors = [];
		this.doctors_loading = true;
		this.doctors_timeout = null;
	}

	ngOnInit() {
		this.expedient_subscription = this.expedientService.current.subscribe(
			state => {
				this.expedient_state = state;
				// this.moduleService.reload();
				this.form_configs();
			}
		);
		this.doctor_subscription = this.moduleService.current.subscribe(
			state => this.doctor_state = state
		);
		this.form_configs();
	}

	ngAfterViewInit() {
		if (this.doctor_state) {
			this.moduleService.forms.doctor = this.form_ref;
		}
	}

	ngOnDestroy() {
		this.expedient_subscription.unsubscribe();
		this.doctor_subscription.unsubscribe();
	}

	form_configs() {
		this.form_config = {
			pretty_view: () => this.expedient_state.method == "show",
			disabled: () => this.expedient_state.method == "show",
			submit: (callback) => this.moduleService.submit(callback),
			rows: [
				{
					class: "row",
					cols: [
						{
							name: "doctor_search",
							type: "select-search",
							label: "Doctor",
							col_class: "col-md-12",
							version: "v1",
							placeholder: () => "Buscar doctor..",
							validators: {
								required: {
									value: () => true,
								},
							},
							catalog: {
								list: () => this.doctors,
								value: "name",
								text: "name",
							},
							loading: () => {
								return this.doctors_loading;
							},
							change: (event) => {
								// if (!input_ref.s_value) {
								//     this.doctor_state.doctor_search = "";
								// }
							},
							input: (event) => {
								this.doctor_state.id_doctor = null;
								this.doctor_state.doctor_search = "";
								this.doctors_loading = true;
								clearTimeout(this.doctors_timeout);

								this.doctors_timeout = setTimeout(() => {
									this.moduleService.get_doctors((doctors => {
										this.doctors = this.catalogsService.selectWhereLike(doctors, { name: (event.target.value + "").trim() });
										this.doctors_loading = false;
									}))
								}, 1000);
							},
							select: (item: any, input_ref: any) => {
								input_ref.s_value = item.name;
								this.doctor_state.firstname = item.firstname;
								this.doctor_state.lastname = item.lastname;
								this.doctor_state.id_doctors_specialty = item.id_doctors_specialty;
								this.doctor_state.id_doctor = item.id_user;
							},
							disabled: () => false,
							start_value: (input_ref) => {
								setTimeout(v => {
									input_ref.value_started = true;
									input_ref.s_value = this.doctor_state.doctor_search || "";
								}, 1);
							},
						},
					]
				},
				{
					class: "row",
					cols: [
						{
							type: "buttons",
							col_class: "col-12",
							buttons: [
								{
									class: "btn btn-sm btn-outline-danger",
									label: "Cancelar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									show: () => {
										return this.expedient_state.method == "update" || this.expedient_state.method == "insert";
									},
									click: () => {
										this.moduleService.cancel();
									},
								},
								{
									class: "btn btn-sm btn-outline-danger",
									label: "Cerrar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									show: () => {
										return this.expedient_state.method == "show";
									},
									click: () => {
										this.moduleService.close();
									},
								},
								{
									class: "btn btn-sm btn-success ml-auto",
									label: "Guardar",
									type: "submit",
									icon: {
										class: "fa-duotone fa-check",
										position: 'right',
									},
									show: () => {
										return this.expedient_state.method == "update" || this.expedient_state.method == "insert";
									},
									disabled: () => !this.doctor_state.id_doctor || this.doctor_state.loading,
								},
							]
						},
					],
				},
			]
		};
	}
}