/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../shared/ui/form_render/form_render.component.ngfactory";
import * as i3 from "../../../../../shared/ui/form_render/form_render.component";
import * as i4 from "../../../../../services/app.service";
import * as i5 from "../../../../../services/pipes.service";
import * as i6 from "./form.component";
import * as i7 from "../pathologies.service";
import * as i8 from "../../../../../services/expedient.service";
var styles_FormComponent = [i0.styles];
var RenderType_FormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormComponent, data: {} });
export { RenderType_FormComponent as RenderType_FormComponent };
export function View_FormComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { form_ref: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-form_render", [], null, null, null, i2.View_FormRenderComponent_0, i2.RenderType_FormRenderComponent)), i1.ɵdid(2, 114688, [[1, 4], ["form_ref", 4]], 0, i3.FormRenderComponent, [i4.AppService, i5.PipesService], { config: [0, "config"], data: [1, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form_config; var currVal_1 = _co.pathologies_state; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_FormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-module-form", [], null, null, null, View_FormComponent_0, RenderType_FormComponent)), i1.ɵdid(1, 4440064, null, 0, i6.FormComponent, [i7.PathologiesService, i8.ExpedientService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormComponentNgFactory = i1.ɵccf("app-module-form", i6.FormComponent, View_FormComponent_Host_0, {}, {}, []);
export { FormComponentNgFactory as FormComponentNgFactory };
