<div class="d-flex flex-column pr-3 py-2 h-100">
    <div class="container-fluid px-0">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-between mb-2">
                    <div class="d-flex flex-column my-auto">
                        <ng-container *ngIf="expedient_state.version=='expedient'">
                            <div *ngIf="page_state" class="title-last mt-auto">
                                expediente
                            </div>
                        </ng-container>
                        <div class="title mb-auto"> antecedentes </div>
                    </div>

                    <div class="my-auto">
                        <div class="d-flex text-right">
                            <ng-container *ngIf="expedient_state.version=='expedient'">
                                <app-search_button (change)="on_update()" (submit)="on_update()" #search_button_ref></app-search_button>
                            </ng-container>
                            <button class="ml-2 btn radius btn-primary no-wrap" style="font-weight: 500;" (click)="create()"><i class="fa-duotone fa-plus"></i> ANTECEDENTE </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 mb-2">
                <app-dynamic_tabs [config]="dynamic_tabs"> </app-dynamic_tabs>
            </div>
        </div>
    </div>

    <ng-container *ngIf="expedient_state.version=='expedient'">
        <div class="flex-grow-1">
            <div class="local-internal-scroll">
                <div class="scroll-content pr-2">
                    <app-datatable
                        #datatable_ref
                        (update_datatable)="update($event)"
                        (events_datatable)="events($event)"
                        [in_headers]="datatable.headers"
                        [in_events]="datatable.events"
                        [in_data]="list_state"
                        [in_filters]="datatable.filters"
                        [in_configs]="datatable.configs">
                    </app-datatable>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="expedient_state.version=='new_patient'">
        <app-datatable
            #datatable_ref
            (update_datatable)="update($event)"
            (events_datatable)="events($event)"
            [in_headers]="datatable.headers"
            [in_events]="datatable.events"
            [in_data]="list_state"
            [in_filters]="datatable.filters"
            [in_configs]="datatable.configs">
        </app-datatable>
    </ng-container>
</div>

<ng-template #showModal role="document">
    <app-background_show_allergies></app-background_show_allergies>
</ng-template>

<ng-template #createModal role="document">
    <app-background_create_allergies></app-background_create_allergies>
</ng-template>

<ng-template #updateModal role="document">
    <app-background_update_allergies></app-background_update_allergies>
</ng-template>