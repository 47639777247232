import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { AppService } from './app.service';
import { PatientService } from './patient-service.service';
import { ToastrService } from 'ngx-toastr';

export interface ExpedientMetadata {
  version: "expedient" | "new_patient" | "first_fill",
  method: "insert" | "update" | "show",
  id_user?: any,
  loading?: boolean
};

export interface ExpedientServiceRef {
  validate?(data?: any): any,
  submit?(data?: any): any,
  store_url?: string
};

export interface ExpedientServiceModules {
  general: ExpedientServiceRef,
  contacts: {
    doctor: ExpedientServiceRef,
    emergency: ExpedientServiceRef
  },
  procedures: {
    doctor: ExpedientServiceRef
  },
  references: {
    doctor: ExpedientServiceRef
  },
  background: {
    pathologies: ExpedientServiceRef,
    allergies: ExpedientServiceRef
  },
  insurances: {
    accident: ExpedientServiceRef
  },
  exams: {
    laboratory: ExpedientServiceRef
  },
  relations: {
    dependants: ExpedientServiceRef,
    tutors: ExpedientServiceRef
  },
  institutions: ExpedientServiceRef,
};

@Injectable({
  providedIn: 'root'
})
export class ExpedientService {
  private current_state: ExpedientMetadata;
  private expedient_state: BehaviorSubject<ExpedientMetadata>;
  public current: Observable<ExpedientMetadata>;
  private expedient_services: ExpedientServiceModules;

  public expedient_session = "ExpedientMetadata";
  public file_list: {
    insurances: {
      accident: any[]
    }
  };

  constructor(private endpoint: AppService, private patientService: PatientService, private toastr: ToastrService) {
    if (!this.current_state) {
      this.clean();
      this.next();
    }
  }

  init(expedient_metadata: ExpedientMetadata) {
    this.file_list = {
      insurances: {
        accident: []
      }
    };
    this.init_expedient_services();
    this.get_stored(expedient_metadata);
    this.save();
    this.next();
  }

  get state() {
    return this.current_state;
  }

  get_stored(expedient_metadata: ExpedientMetadata) {
    this.current_state = expedient_metadata;
  }

  next() {
    if (this.expedient_state) {
      this.expedient_state.next(this.current_state);
    } else {
      this.expedient_state = new BehaviorSubject(this.current_state);
      this.current = this.expedient_state.asObservable();
    }
  }

  clean() {
    this.current_state = {
      id_user: null,
      method: "show",
      version: null
    };
  }

  save() {
    localStorage.setItem(this.expedient_session, JSON.stringify(this.current_state));
  }

  change_version(version: "expedient" | "new_patient" | "first_fill") {
    this.current_state.version = version;
    this.save();
    this.next();
  }

  change_method(method: "insert" | "update" | "show") {
    this.current_state.method = method;
    this.save();
    this.next();
  }

  change_id_user(id_user: string) {
    this.current_state.id_user = id_user;
    this.save();
    this.next();
  }

  cancel(callback = (callback_data?: any) => { }) {
    Swal.fire({
      title: "Cancelar Actualización de Paciente",
      html: "¿Está seguro de cancelar la actualización del paciente actual?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FDB915',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, cancelar',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value == true) {
        localStorage.removeItem("inExpedientGeneral");
        callback();
      }
    });
  }

  // MODULES
  init_expedient_services() {
    this.expedient_services = {
      general: null,
      contacts: {
        doctor: null,
        emergency: null
      },
      procedures: {
        doctor: null
      },
      references: {
        doctor: null
      },
      background: {
        allergies: null,
        pathologies: null
      },
      insurances: {
        accident: null
      },
      exams: {
        laboratory: null
      },
      relations: {
        dependants: null,
        tutors: null
      },
      institutions: null,
    }
  }

  set_expedient_service(ref: ExpedientServiceRef, att1: string, att2?: string) {
    if (this.expedient_services) {
      if (att2) {
        this.expedient_services[att1][att2] = ref;
      } else {
        this.expedient_services[att1] = ref;
      }
    }
  }

  submit_expedient_services() {
    this.current_state.loading = true;
    this.next();
    this._submit_expedient_services();
  }

  end_submit_expedient_services() {
    this.current_state.loading = false;

    localStorage.removeItem("inNewPatient");
    this.patientService.change_patient_id(this.current_state.id_user);
    this.endpoint.redirect_to("/expediente/consultas");
    this.next();
  }

  async _submit_expedient_services() {
    try {

      // GENERAL
      this.current_state.method = "insert";
      let minor = false;

      if (this.expedient_services.general) {
        let general_async = new Promise((resolve, reject) => {
          this.expedient_services.general.validate((data) => {
            if (data && data.minor) {
              minor = data.minor;
            }
            resolve(true);
          });
        });
        await general_async;
      }

      if (this.expedient_services.relations.tutors && minor) {
        let relations_tutors_async = new Promise((resolve, reject) => {
          this.expedient_services.relations.tutors.validate((data) => {
            if (data.list.list.length > 0) {
              resolve(true);
            } else {
              this.toastr.error("El paciente es menor de edad, por lo que es obligatorio que se asigne un tutor.", "Paciente Menor de Edad");
              reject(false);
            }
          });
        });
        await relations_tutors_async;
      }

      if (this.expedient_services.general) {
        let general_async = new Promise((resolve, reject) => {
          this.expedient_services.general.submit((data) => {
            if (data && data.id_user) {
              this.current_state.id_user = data.id_user;
              resolve(data.id_user);
            } else {
              reject(data);
            }
          });
        });
        await general_async;
      }
      if (this.current_state.id_user) {

        // RELATIONS
        // if (this.expedient_services.relations.dependants) {
        //   let relations_dependants_async = new Promise((resolve, reject) => {
        //     this.expedient_services.relations.dependants.submit(resolve);
        //   });
        //   await relations_dependants_async;
        // }
        if (this.expedient_services.relations.tutors) {
          let relations_tutors_async = new Promise((resolve, reject) => {
            this.expedient_services.relations.tutors.submit(resolve);
          });
          await relations_tutors_async;
        }

        // CONTACTS
        if (this.expedient_services.contacts.doctor) {
          let contacts_doctor_async = new Promise((resolve, reject) => {
            this.expedient_services.contacts.doctor.submit(resolve);
          });
          await contacts_doctor_async;
        }
        if (this.expedient_services.contacts.emergency) {
          let contacts_emergency_async = new Promise((resolve, reject) => {
            this.expedient_services.contacts.emergency.submit(resolve);
          });
          await contacts_emergency_async;
        }

        // // PROCEDURES
        // if (this.expedient_services.procedures.doctor) {
        //   let procedures_doctor_async = new Promise((resolve, reject) => {
        //     this.expedient_services.procedures.doctor.submit(resolve);
        //   });
        //   await procedures_doctor_async;
        // }

        // // EXAMS
        // if (this.expedient_services.exams.laboratory) {
        //   let exams_laboratory_async = new Promise((resolve, reject) => {
        //     this.expedient_services.exams.laboratory.submit(resolve);
        //   });
        //   await exams_laboratory_async;
        // }

        // // REFERENCES
        // if (this.expedient_services.references.doctor) {
        //   let references_doctor_async = new Promise((resolve, reject) => {
        //     this.expedient_services.references.doctor.submit(resolve);
        //   });
        //   await references_doctor_async;
        // }

        // BACKGROUND
        if (this.expedient_services.background.pathologies) {
          let background_pathologies_async = new Promise((resolve, reject) => {
            this.expedient_services.background.pathologies.submit(resolve);
          });
          await background_pathologies_async;
        }

        if (this.expedient_services.background.allergies) {
          let background_allergies_async = new Promise((resolve, reject) => {
            this.expedient_services.background.allergies.submit(resolve);
          });
          await background_allergies_async;
        }

        // // INSURANCES
        // if (this.expedient_services.insurances.accident) {
        //   let insurances_accident_async = new Promise((resolve, reject) => {
        //     this.expedient_services.insurances.accident.submit(resolve);
        //   });
        //   await insurances_accident_async;
        // }

        // // RELATIONS
        // if (this.expedient_services.institutions) {
        //   let institutions_async = new Promise((resolve, reject) => {
        //     this.expedient_services.institutions.submit(resolve);
        //   });
        //   await institutions_async;
        // }
        this.end_submit_expedient_services();
      }

    } catch (error) {
      this.current_state.loading = false;
      console.error(error);
    }
  }

  update_global() {
    this.patientService.load_patient_data();
  }

}