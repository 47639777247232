import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AppService } from "src/app/services/app.service";
import { dynamic_url_tabs_type } from "src/app/shared/ui/dynamic_tabs/dynamic_tabs.component";
import { ConsultGeneralService, ConsultMetadata } from "./consult.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-layout",
    templateUrl: "./layout.component.html",
    styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit, OnDestroy {
    private consult_subscription: Subscription;
    public consult_state: ConsultMetadata;
    public dynamic_tabs: dynamic_url_tabs_type;

    constructor(private endpoint: AppService, private activeRoute: ActivatedRoute, private consultService: ConsultGeneralService, private toast: ToastrService) {
        this.dynamic_tabs_config();
    }

    ngOnInit() {
        this.consult_subscription = this.consultService.current.subscribe((state) => {
            this.consult_state = state;
            this.validate_direct();
            this.dynamic_tabs_config();
        });
    }

    ngOnDestroy() {
        this.consultService.set_loading(true, 0);
        this.consultService.clean();
        this.consultService.next();
        this.consult_subscription.unsubscribe();
    }

    dynamic_tabs_config() {
        this.dynamic_tabs = {
            no_border: true,
            list: [
                {
                    name: "general",
                    icon: "id-card-clip",
                    class: "mx-xl-5 mx-md-3 mx-2",
                    font_size: "0.9rem",
                    url: "/expediente/nueva-consulta/general/general",
                    show: () => true,
                },
                {
                    name: "vitales",
                    icon: "monitor-waveform",
                    class: "mx-xl-5 mx-md-3 mx-2",
                    font_size: "0.9rem",
                    url: "/expediente/nueva-consulta/general/vitales",
                    show: () => true,
                },
                // {
                //     name: "curva crecimiento",
                //     icon: "monitor-waveform",
                //     class: "mx-xl-5 mx-md-3 mx-2",
                //     font_size: "0.9rem",
                //     url: "/expediente/nueva-consulta/general/curva_crecimiento",
                //     show: () => true,
                // },
                {
                    name: "examen físico",
                    icon: "person-half-dress",
                    class: "mx-xl-5 mx-md-3 mx-2",
                    font_size: "0.9rem",
                    url: "/expediente/nueva-consulta/general/examen_fisico",
                    show: () => true,
                },
                {
                    name: "diagnóstico",
                    icon: "bacteria",
                    class: "mx-xl-5 mx-md-3 mx-2",
                    font_size: "0.9rem",
                    url: "/expediente/nueva-consulta/general/diagnostico",
                    show: () => true,
                },
                {
                    name: "exámenes",
                    url: "/expediente/nueva-consulta/general/examenes",
                    show: () => false,
                },
                {
                    name: "procedimientos",
                    url: "/expediente/nueva-consulta/general/procedimientos",
                    show: () => false,
                },
                {
                    name: "referencias",
                    url: "/expediente/nueva-consulta/general/referencias",
                    show: () => false,
                },
                {
                    name: "recomendaciones y notas",
                    url: "/expediente/nueva-consulta/general/recomendaciones_notas",
                    show: () => false,
                },
            ],
        };
    }

    print() {
        this.consultService.set_loading(true);
        setTimeout(() => {
            this.consultService.create_pdf();
            this.consultService.set_loading(false);
        }, 3000);
    }

    cancel() {
        this.consultService.cancel();
    }

    valid() {
        return this.consultService.valid();
    }

    submit() {
        this.consultService.finish_consult();
    }

    get_next() {
        let index = this.dynamic_tabs.list.findIndex((value) => "/" + this.activeRoute.snapshot["_urlSegment"].segments.map((value) => value.path).join("/") == value.url);
        if (index + 1 < this.dynamic_tabs.list.length) {
            return this.dynamic_tabs.list[index + 1].url;
        } else {
            return false;
        }
    }

    get_back(): any {
        let index = this.dynamic_tabs.list.findIndex((value) => "/" + this.activeRoute.snapshot["_urlSegment"].segments.map((value) => value.path).join("/") == value.url);
        if (index > 0) {
            return this.dynamic_tabs.list[index - 1].url;
        } else {
            return false;
        }
    }

    save_consult() {
        this.consultService.save_consult();
    }

    save_minimize_consult() {
        this.consultService.save_minimize_consult();
    }

    save_close_consult() {
        this.consultService.save_close_consult();
    }

    add_extra(name: string) {
        let extra = this.has_extra(name);
        if (extra) {
            if (extra == 1) {
                if (this.activeRoute.snapshot["_urlSegment"].segments.map((value) => value.path).pop() == name) {
                    let back = this.get_back();
                    if (back) this.endpoint.redirect_to(back);

                    let i = this.consult_state.extras.findIndex((value) => value == name);
                    this.consult_state.extras.splice(i, 1);
                    this.dynamic_tabs_config();
                } else {
                    let i = this.consult_state.extras.findIndex((value) => value == name);
                    this.consult_state.extras.splice(i, 1);
                    this.dynamic_tabs_config();

                    let back = this.get_back();
                    if (back) this.endpoint.redirect_to(back);
                }
            } else {
                this.endpoint.redirect_to(`/expediente/nueva-consulta/general/${name}`);
            }
        } else {
            this.consult_state.extras.push(name);
            this.endpoint.redirect_to(`/expediente/nueva-consulta/general/${name}`);
        }
        this.dynamic_tabs_config();
    }

    has_extra(name: string): number {
        if (this.consult_state) {
            let exist = this.consult_state.extras && this.consult_state.extras.find((value) => value == name) ? 1 : 0;
            if (name == "examenes") {
                return exist + (this.consult_state.consult.exams_requests_list.list.length > 0 || this.consult_state.consult.exams_results_list.list.length > 0 ? 2 : 0);
            } else if (name == "procedimientos") {
                return exist + (this.consult_state.consult.procedures_requests_list.list.length > 0 || this.consult_state.consult.procedures_results_list.list.length > 0 ? 2 : 0);
            } else if (name == "referencias") {
                return exist + (this.consult_state.consult.references_list.list.length > 0 ? 2 : 0);
            }
        }
        return 0;
    }

    validate_direct() {
        var name = this.activeRoute.snapshot["_urlSegment"].segments.map((value) => value.path).pop();
        if (["examenes", "procedimientos", "referencias"].includes(name) && !this.has_extra(name)) {
            this.consult_state.extras.push(name);
        }
    }

    onActivate(e) {
    }
    onDeactivate(e) {
    }
    onAttach(e) {
    }
    onDetach(e) {
    }
}
