import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { ExpedientService } from 'src/app/services/expedient.service';
import { PageService } from '../../services/page.service';

@Injectable({
  providedIn: 'root'
})
export class NewPatientGuard implements CanActivate {

  constructor(private pageService: PageService, private expedientService: ExpedientService) { }

  canActivate() {
    localStorage.setItem("inNewPatient", "true");

    this.pageService.set_consult(false);
    this.pageService.set_profile(false);
    this.pageService.set_expedient(true);

    this.expedientService.init({
      version: "new_patient",
      method: "insert"
    });

    this.pageService.set_sidebar_2_state({
      expand: false,
      scroll: true,
      show: false
    });
    this.pageService.set_sidebar_3_state({
      expand: false,
      scroll: true,
      show: true
    });
    return true;
  }

}
