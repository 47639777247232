import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoldListComponent } from './bold_list.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../../shared.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { UIModule } from '../ui.module';
import { NgbAccordionModule, NgbAlertModule, NgbCollapseModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule, NgbModule, NgbPaginationModule, NgbPopoverModule, NgbProgressbarModule, NgbTabsetModule, NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    BoldListComponent,
  ],
  imports: [
    ClickOutsideModule,
    UIModule,
    NgbModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbTabsetModule,
    NgbAccordionModule,
    NgbCollapseModule,
    NgbModalModule,
    NgbProgressbarModule,
    NgbAlertModule,
    NgbToastModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbPaginationModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    SharedModule
  ],
  exports: [
    BoldListComponent,
  ]
})
export class BoldListModule { }
