<div class="brief-items container px-2">
    <div class="row">
        <div class="col-12 header py-2" (click)="show = !show">
            <div class="d-flex">
                <span class="d-flex title my-auto">
                    patologías
                    <div
                         routerLink="/expediente/antecedentes/patologicos"
                         *ngIf="patient_state.background.pathology && patient_state.background.pathology.length > 0"
                         class="noti my-auto ml-1">
                        {{patient_state.background.pathology_total}}
                    </div>
                </span>
                <i class="fa-duotone fa-angle-up fa-lg my-auto ml-auto icon"
                   [class.fa-angle-up]="show"
                   [class.fa-angle-down]="!show">
                </i>
            </div>
        </div>
    </div>
    <div class="row" [class.d-local-none]="!show">
        <ng-container *ngIf="patient_state.background.pathology && patient_state.background.pathology.length > 0">
            <div class="col-12" *ngFor="let item of patient_state.background.pathology">
                <div class="d-flex flex-column">
                    <span class="level-1">{{item.name}}</span>
                    <span class="level-2">{{item.treatment}}</span>
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex">
                    <a *ngIf="!show_more" class="more" routerLink="/expediente/antecedentes/patologicos">ver más...</a>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="patient_state.background.pathology && patient_state.background.pathology.length < 1">
            <div class="col-12">
                <h6>No hay antecedentes patológicos</h6>
            </div>
        </ng-container>
    </div>
    <div class="row">
        <hr class="my-0 col-md-11 text-center">
    </div>
</div>