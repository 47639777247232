/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./update-date.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/ui/form_render/form_render.component.ngfactory";
import * as i3 from "../../../../shared/ui/form_render/form_render.component";
import * as i4 from "../../../../services/app.service";
import * as i5 from "../../../../services/pipes.service";
import * as i6 from "./update-date.component";
import * as i7 from "ngx-toastr";
import * as i8 from "../../../../services/page.service";
import * as i9 from "../../../../services/patient-service.service";
import * as i10 from "../../../../services/account.service";
var styles_UpdateDateComponent = [i0.styles];
var RenderType_UpdateDateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UpdateDateComponent, data: {} });
export { RenderType_UpdateDateComponent as RenderType_UpdateDateComponent };
export function View_UpdateDateComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { form_ref: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-header ribbon-box d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h4", [["class", "text-center w-100 modal-title text-dark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa-duotone fa-plus mr-1 text-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Editar Cita"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "close text-outline-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "modal-body p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-form_render", [], null, null, null, i2.View_FormRenderComponent_0, i2.RenderType_FormRenderComponent)), i1.ɵdid(11, 114688, [[1, 4], ["form_ref", 4]], 0, i3.FormRenderComponent, [i4.AppService, i5.PipesService], { config: [0, "config"], data: [1, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form_config; var currVal_1 = _co.data; _ck(_v, 11, 0, currVal_0, currVal_1); }, null); }
export function View_UpdateDateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-update-date", [], null, null, null, View_UpdateDateComponent_0, RenderType_UpdateDateComponent)), i1.ɵdid(1, 114688, null, 0, i6.UpdateDateComponent, [i4.AppService, i7.ToastrService, i8.PageService, i9.PatientService, i10.AccountService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UpdateDateComponentNgFactory = i1.ɵccf("app-update-date", i6.UpdateDateComponent, View_UpdateDateComponent_Host_0, { readonly: "readonly", data: "data" }, {}, []);
export { UpdateDateComponentNgFactory as UpdateDateComponentNgFactory };
