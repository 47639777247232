import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { AppService } from 'src/app/services/app.service';
import { PageService } from '../../services/page.service';

@Injectable({
	providedIn: 'root'
})
export class OutExpedientGuard implements CanDeactivate<any> {

	constructor(private pageService: PageService, private endpoint: AppService, private accountService: AccountService) { }

	canDeactivate(a, b, c, d) {
		let inExpedient = localStorage.getItem("inExpedient");

		if (inExpedient) {
			this.pageService.cancel_expedient(() => {
				this.endpoint.redirect_to(d.url);
			});
			return false;
		}
		return true;
	}

}
