import { monthYear } from './monthYear';
import { NumberPipe } from "./number";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { ModalPipe } from "./modal";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PhonePipe } from "./phonePipe";
import { shortDate } from "./shortDate";
import { LongDate } from "./longDate.component";
import { Names } from "./names";
import { OrdenPipe } from "./orden.pipe";
import { microDate } from "./microDate";
import { longOnlyDate } from "./longOnlyDate.component";
import { longOnlyTime } from "./longTime.component";
import { identificationPipe } from "./identification.component";
import { BooleanPipe } from "./boolean";
import { CatalogPipe } from "./catalog";
import { SafePipe } from "./safe";
import { FileEndpointPipe } from "./file_endpoint";
import { year } from './year';
import { timeToString } from './longTime.component copy';
import { dateDif } from './dateDif';
@NgModule({
    declarations: [
        PhonePipe,
        shortDate,
        microDate,
        monthYear,
        year,
        LongDate,
        OrdenPipe,
        longOnlyDate,
        longOnlyTime,
        identificationPipe,
        BooleanPipe,
        CatalogPipe,
        SafePipe,
        Names,
        ModalPipe,
        NumberPipe,
        FileEndpointPipe,
        dateDif,
        timeToString,
    ],
    imports: [CommonModule, NgbModalModule],
    exports: [PhonePipe, shortDate, microDate, LongDate, OrdenPipe, longOnlyDate, longOnlyTime, identificationPipe, BooleanPipe, CatalogPipe, SafePipe, Names, ModalPipe, NumberPipe, FileEndpointPipe, monthYear, year, dateDif, timeToString],
})
export class PipesModule { }
