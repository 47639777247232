<div class="bg-masterhead d-flex align-items-center" style="height: 100vh">
	<div class="account-pages w-100">
		<div class="container against-footer">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <div class="card shadow" style="border-radius: 25px;">
      
            <div class="card-body px-4 pt-4 pb-2">
              <div class="text-center w-100 m-auto">
                <a routerLink="/">
                  <img src="assets/images/favicon.ico" alt="UNIMED" style="height: 8rem;">
                </a>
                <p class="text-muted mb-2 mt-2">Ingrese sus credenciales para acceder al sistema.
                </p>
              </div>
      
              <form class="needs-validation" name="loginForm" [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
      
                <app-ui-preloader [display]="loading"></app-ui-preloader>
      
                <ngb-alert type="danger" *ngIf="error" [dismissible]="false" style="border-radius: 25px !important;">{{ error }}</ngb-alert>
      
                <div class="form-group mb-3">
                  <label for="email">Usuario</label>
      
                  <input type="email" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="email" placeholder="Usuario" name="email" required />
                </div>
      
                <div class="form-group mb-3">
                  <label for="password">Contraseña</label>
                  <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" id="password" placeholder="Contraseña" name="password" required />
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Se requiere contraseña.</div>
                    <div *ngIf="f.password.errors.minlength">La contraseña debe contener al menos 8 caracteres.</div>
                  </div>
                </div>
      
                <div class="form-group mb-0 text-center">
                  <button class="btn btn-block btn-primary" type="submit" style="border-radius: 25px;">Iniciar Sesión</button>
                </div>

                <div class="row mt-2">
                  <div class="col-12 text-center">
                    <p class="m-0">
                      <a *ngIf="!loading" routerLink="/recuperar-contraseña" class="ml-1 text-primary">¿Olvidó su contraseña?</a>
                      <a *ngIf="loading" class="ml-1 text-primary pointer" style="opacity: 0.7;">¿Olvidó su contraseña?</a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
      
        </div>
      </div>
		</div>
	</div>
	<footer class="footer footer-alt">
    <div class="row">
      <div class="col-md-6 text-left text-muted ">
        {{release_year == year ? year : (release_year + " - " + year)}} © UNIMED Plataforma by <a class="text-primary" target="_blank" href="https://cjalvarez.com">CJAlvarez </a>
      </div>
      <div class="col-md-6">
        <div class="text-md-right footer-links d-none d-sm-block">
          <a class="text-muted" target="_blank" href="https://github.com/CJAlvarez"><i class="fab fa-github"></i> GitHub</a>
        </div>
      </div>
    </div>
  </footer>
</div>