/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./custom-holder.component";
var styles_CustomHolderComponent = [];
var RenderType_CustomHolderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomHolderComponent, data: {} });
export { RenderType_CustomHolderComponent as RenderType_CustomHolderComponent };
function View_CustomHolderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_CustomHolderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], [[8, "className", 0], [2, "ml-2", null]], null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.title, ""); var currVal_1 = _co.inline; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.title_text; _ck(_v, 1, 0, currVal_2); }); }
function View_CustomHolderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "", _co.message, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.current_message; _ck(_v, 1, 0, currVal_1); }); }
export function View_CustomHolderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "d-flex justify-content-center align-items-center"]], [[2, "flex-column", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomHolderComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomHolderComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomHolderComponent_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.icon_show == true); _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.title_show == true); _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.message_show == true); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.inline; _ck(_v, 0, 0, currVal_0); }); }
export function View_CustomHolderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-holder", [], null, null, null, View_CustomHolderComponent_0, RenderType_CustomHolderComponent)), i0.ɵdid(1, 573440, null, 0, i2.CustomHolderComponent, [], null, null)], null, null); }
var CustomHolderComponentNgFactory = i0.ɵccf("app-custom-holder", i2.CustomHolderComponent, View_CustomHolderComponent_Host_0, { icon: "icon", icon_show: "icon_show", title: "title", title_text: "title_text", title_show: "title_show", message: "message", message_show: "message_show", message_texts: "message_texts", message_interval: "message_interval", inline: "inline" }, {}, []);
export { CustomHolderComponentNgFactory as CustomHolderComponentNgFactory };
