<div class="bg-masterhead d-flex align-items-center" style="height: 100vh">
	<div class="account-pages w-100">
		<div class="container against-footer">
			<div class="row justify-content-center">
				<div class="col-lg-5">
					<div class="card shadow" style="border-radius: 25px;">

						<div class="card-body p-4">
							<div class="text-center w-75 m-auto">
								<a routerLink="/">
									<img src="assets/images/favicon.ico" alt="UNIMED" height="150">
								</a>
								<p class="text-muted mb-2 mt-2">Ingrese el código de verificación.
								</p>
							</div>

							<form class="needs-validation" name="steps_2Form" [formGroup]="steps_2Form" (ngSubmit)="steps_2()" novalidate autocomplete="off">

								<app-ui-preloader [display]="loading"></app-ui-preloader>

								<ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

								<div class="form-group mb-3">
									<label for="code">Código de verificación</label>

									<input
										type="text"
										class="form-control"
										formControlName="code"
										[ngClass]="{ 'is-invalid': submitted && f.code.errors }"
										id="code"
										placeholder="#-#-#-#-#-#"
										mask="A-A-A-A-A-A"
										minlength="6"
										maxlength="12"
										name="code"
										spellcheck="false"
										required />

									<div *ngIf="submitted && f.code.errors" class="invalid-feedback">
										<div *ngIf="f.code.errors.required">Se requiere código de verificación.</div>
									</div>
								</div>

								<div class="form-group mb-0 text-center">
									<button class="btn btn-block btn-primary" type="submit" style="border-radius: 25px;">Verificar código</button>
								</div>
							</form>
						</div>
					</div>

					<div class="row mt-0">
						<div class="col-12 text-center">
							<p>
								<a *ngIf="!loading" (click)='send_steps_2()' class="ml-1 text-local-green pointer">Enviar de nuevo</a>
								<a *ngIf="loading" class="ml-1 text-local-green pointer" style="opacity: 0.7;">Enviar de nuevo</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<footer class="footer footer-alt">
		<div class="row">
			<div class="col-md-6 text-left text-muted ">
				{{release_year == year ? year : (release_year + " - " + year)}} © UNIMED by <a class="text-primary" target="_blank" href="https://cjalvarez.com">CJAlvarez </a>
			</div>
			<div class="col-md-6">
				<div class="text-md-right footer-links d-none d-sm-block">
					<a class="text-muted" target="_blank" href="https://github.com/CJAlvarez"><i class="fab fa-github"></i> GitHub</a>
				</div>
			</div>
		</div>
	</footer>
</div>