import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormRenderComponent, form_type } from 'src/app/shared/ui/form_render/form_render.component';
import { AccidentService, AccidentState } from '../accident.service';
import { ExpedientMetadata, ExpedientService } from 'src/app/services/expedient.service';

@Component({
	selector: 'app-module-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy, AfterViewInit {
	private expedient_subscription: Subscription;
	public expedient_state: ExpedientMetadata;

	private accident_subscription: Subscription;
	public accident_state: AccidentState;

	@ViewChild('form_ref', { static: false }) form_ref: FormRenderComponent;
	public form_config: form_type;

	constructor(private moduleService: AccidentService, private expedientService: ExpedientService) {
	}

	ngOnInit() {
		this.expedient_subscription = this.expedientService.current.subscribe(
			state => {
				this.expedient_state = state;
				// this.moduleService.reload();
				this.form_configs();
			}
		);
		this.accident_subscription = this.moduleService.current.subscribe(
			state => this.accident_state = state
		);
		this.form_configs();
	}

	ngAfterViewInit() {
		if (this.accident_state) {
			this.moduleService.forms.accident = this.form_ref;
		}
	}

	ngOnDestroy() {
		this.expedient_subscription.unsubscribe();
		this.accident_subscription.unsubscribe();
	}

	form_configs() {
		this.form_config = {
			pretty_view: () => this.expedient_state.method == "show",
			disabled: () => this.expedient_state.method == "show",
			submit: (callback) => this.moduleService.submit(callback),
			rows: [
				{
					class: "row",
					cols: [
						{
							name: "carrier",
							type: "name",
							label: "Aseguradora",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
								maxlength: {
									value: () => 100
								}
							},
							disabled: () => false,
						},
						{
							name: "number",
							type: "text",
							label: "Número de Póliza",
							placeholder: () => "#######",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
								maxlength: {
									value: () => 100
								}
							},
							disabled: () => false,
						},
						{
							name: "description",
							type: "textarea",
							label: "Descripción de Póliza",
							rows: 2.5,
							col_class: "col-12",
							validators: {
								required: {
									value: () => false
								},
								maxlength: {
									value: () => 500
								}
							},
							disabled: () => false,
						},
						{
							name: "roof",
							type: "decimal",
							label: "Techo de Monto Asegurado (L)",
							placeholder: () => "#,###,###.##",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => false
								},
								maxlength: {
									value: () => 100
								}
							},
							disabled: () => false,
						},
						{
							name: "hospital",
							type: "text",
							label: "Hospital de Preferencia",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => false
								},
								maxlength: {
									value: () => 100
								}
							},
							disabled: () => false,
						},
						{
							name: "date_init",
							type: "calendar",
							label: "Fecha de Inicio",
							placeholder: () => "##/##/####",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							disabled: () => false,
						},
						{
							name: "date_finish",
							type: "calendar",
							label: "Fecha de Vencimiento",
							placeholder: () => "##/##/####",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							disabled: () => false,
						},
						{
							name: "phone",
							type: "number",
							label: "Teléfono de Emergencia",
							placeholder: () => "#########",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => false
								},
								maxlength: {
									value: () => 30
								}
							},
							disabled: () => false,
						},
						{
							name: "ambulance",
							type: "select",
							label: "¿Incluye Traslado en Ambulancia?",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => false
								},
							},
							disabled: () => false,
							catalog: {
								list: () => [{ id: 2, name: "Sí" }, { id: 1, name: "No" }],
								value: "id",
								text: "name"
							}
						},
						{
							name: "img_front",
							type: "upload",
							path: "insurances",
							label: "Carnet Frontal",
							col_class: "col-md-6",
							amount: 1,
							validators: {
								required: {
									value: () => true
								},
							},
							disabled: () => false,
						},
						{
							name: "img_back",
							type: "upload",
							path: "insurances",
							label: "Carnet Trasero",
							col_class: "col-md-6",
							amount: 1,
							validators: {
								required: {
									value: () => true
								},
							},
							disabled: () => false,
						},
					],
				},
				{
					class: "row",
					cols: [
						{
							type: "divider",
							label: "Datos del Corredor",
							col_class: "col-12",
							bar: true,
						},
						{
							name: "runner_name",
							type: "name",
							label: "Nombre",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => false
								},
								maxlength: {
									value: () => 100
								}
							},
							disabled: () => false,
						},
						{
							name: "runner_phone",
							type: "number",
							label: "Teléfono",
							placeholder: () => "#########",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => false
								},
								maxlength: {
									value: () => 30
								}
							},
							disabled: () => false,
						},
						{
							name: "runner_email",
							type: "email",
							label: "Correo Electrónico",
							placeholder: () => "alguien@email.com",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => false
								},
							},
							disabled: () => false,
						},
					],
				},
				{
					class: "row",
					cols: [
						{
							type: "buttons",
							col_class: "col-12",
							buttons: [
								{
									class: "btn btn-sm btn-outline-danger",
									label: "Cancelar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									show: () => {
										return this.expedient_state.method == "update" || this.expedient_state.method == "insert";
									},
									click: () => {
										this.moduleService.cancel();
									},
								},
								{
									class: "btn btn-sm btn-outline-danger",
									label: "Cerrar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									show: () => {
										return this.expedient_state.method == "show";
									},
									click: () => {
										this.moduleService.close();
									},
								},
								{
									class: "btn btn-sm btn-success ml-auto",
									label: "Guardar",
									type: "submit",
									icon: {
										class: "fa-duotone fa-check",
										position: 'right',
									},
									show: () => {
										return this.expedient_state.method == "update" || this.expedient_state.method == "insert";
									},
									disabled: () => (this.form_ref ? !this.form_ref.valid() : false) || this.accident_state.loading,
								},
							]
						},
					],
				},
			]
		};
	}
}