import { CatalogsService } from "../services/catalogs.service";
import { CustomClass } from "./custom-class";

export interface PrescriptionInterface {
    id: string;
    id_patient: string;
    id_consult: string;
    id_diagnosis: string;
    name: string;
    amount: string;
    id_presentation: string;
    id_concentration: string;
    id_way: string;

    way: string;
    presentation: string;
    concentration: string;
    frecuency: string;

    image: string;
    brand: string;
    principle: string;
    dosage: string;
    dosage_unit: string;

    frecuency_unit: string;
    period: string;
    period_unit: string;
    instructions: string;
    comments: string;
    continues: any;
};

interface ToStringInterface {
    name: string;
    amount: string;
    presentation: string;
    concentration: string;
    way: string;
};

export class PrescriptionClass extends CustomClass implements PrescriptionInterface {
    public id: string;
    public id_patient: string;
    public id_consult: string;
    public id_diagnosis: string;
    public name: string;
    public amount: string;
    public id_presentation: string;
    public id_concentration: string;
    public id_way: string;
    public presentation: string;
    public concentration: string;
    public way: string;
    public image: string;
    public brand: string;
    public principle: string;
    public principles: any[];
    public dosage: string;
    public dosage_unit: string;
    public frecuency: string;
    public frecuency_unit: string;
    public period: string;
    public period_unit: string;
    public instructions: string;
    public comments: string;
    public continues: any;

    public medicine_search_brand: string;
    public medicine_search_principle: string;
    public medicine_search_presentation: string;

    constructor(private catalogsService: CatalogsService, data?: PrescriptionInterface) {
        super();
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: PrescriptionInterface): void {
        super.overwrite(data);
        this.id = data.id;
        this.id_patient = data.id_patient;
        this.id_consult = data.id_consult;
        this.id_diagnosis = data.id_diagnosis;
        this.name = data.name;
        this.amount = data.amount;
        this.id_presentation = data.id_presentation;
        this.id_concentration = data.id_concentration;
        this.id_way = data.id_way;
        this.presentation = data.presentation;
        this.concentration = data.concentration;
        this.way = data.way;
        this.image = data.image;
        this.brand = data.brand;
        this.principle = data.principle;
        this.dosage = data.dosage;
        this.dosage_unit = data.dosage_unit;
        this.frecuency = data.frecuency;
        this.frecuency_unit = data.frecuency_unit;
        this.period = data.period;
        this.period_unit = data.period_unit;
        this.instructions = data.instructions;
        this.comments = data.comments;
        this.continues = data.continues;
    }

    reset(): void {
        super.reset();
        this.id = null;
        this.id_patient = null;
        this.id_consult = null;
        this.name = "";
        this.amount = "";
        this.id_presentation = null;
        this.id_concentration = null;
        this.id_way = null;
        this.presentation = "";
        this.concentration = "";
        this.way = "";
        this.image = "";
        this.brand = "";
        this.principle = "";
        this.dosage = "";
        this.dosage_unit = "mg";
        this.frecuency = "";
        this.frecuency_unit = "Horas";
        this.period = "";
        this.period_unit = "Días";
        this.instructions = "";
        this.comments = "";
        this.continues = null;
    }

    format(): PrescriptionInterface {
        return this as PrescriptionInterface;
    }

    valid(): boolean {
        return true;
    }

    to_string(): ToStringInterface {
        let presentation = this.catalogsService.catalogs.medicines_presentations.find(v => v.id == this.id_presentation);
        let way = this.catalogsService.catalogs.medicines_ways.find(v => v.id == this.id_way);

        let data: ToStringInterface = {
            name: this.name,
            amount: this.amount,
            presentation: presentation ? presentation.name : "",
            concentration: this.concentration,
            way: way ? way.name : "",
        };
        return data;
    }

    raw(): PrescriptionInterface {
        return {
            id: this.id,
            id_patient: this.id_patient,
            id_consult: this.id_consult,
            id_diagnosis: this.id_diagnosis,
            name: this.name,
            amount: this.amount,
            id_presentation: this.id_presentation,
            id_concentration: this.id_concentration,
            id_way: this.id_way,
            presentation: this.presentation,
            concentration: this.concentration,
            way: this.way,
            image: this.image,
            brand: this.brand,
            principle: this.principle,
            dosage: this.dosage,
            dosage_unit: this.dosage_unit,
            frecuency: this.frecuency,
            frecuency_unit: this.frecuency_unit,
            period: this.period,
            period_unit: this.period_unit,
            instructions: this.instructions,
            comments: this.comments,
            continues: this.continues,
        };
    }
}
