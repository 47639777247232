<div class="p-2">
    <div class="d-flex flex-column my-auto" *ngIf="expedient_state.version=='expedient'">
        <div class="title-last mt-auto">
            {{page_state.profile ? 'perfil' : "" }}
            {{page_state.expedient ? 'expediente' : "" }}
        </div>
        <div class="title mb-auto"> perfil </div>
    </div>

    <div class="brief-items container-fluid px-2" style="width: 800px; margin-left: 0;">
        <app-module-form></app-module-form>
    </div>
</div>