import { Component, OnInit, LOCALE_ID, ViewChild } from '@angular/core';
import * as moment from 'moment'
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { AppService } from 'src/app/services/app.service';
import { Subscription } from 'rxjs';
import { PageService } from 'src/app/services/page.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PatientService } from 'src/app/services/patient-service.service';
import { AccountService } from 'src/app/services/account.service';


registerLocaleData(localeEs, 'es');

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  providers : [{ provide: LOCALE_ID,useValue : 'es'}],
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

  public show_consults = false ;
  year   =  new Date().getFullYear();
  month  = (new Date().getMonth()+1);
  dia    =  new Date().getDate();
  year_select : any;
  year_select2 : any;
  week: any = [
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
    "Domingo"
  ];
  public data:any;
  public data_consult: any;
  public data_date: any;
  public fecha_date: any;
  public month_select_principal: any[];
  public date_select: any;
  public date_value: any;

  public month_select: string;
  public month_select_month: string;
  public month_present : any;

  public  patient_data   : any;
  public  patient_data1  : dates[];
  public  consults_data  : any;
  public  consults_data1 : consults[];
  private doctor_data    : any;
  public  doctor_data_specific: any;
  private doctor_subscription : Subscription;



  @ViewChild('createModal', { static: false }) createModal: NgbModalRef;
  @ViewChild('updateModal', { static: false }) updateModal: NgbModalRef;

  constructor( private account: AccountService, private endpoint : AppService, private pageService: PageService,
     ) { 
    this.get_days_from_date((this.month), this.year);
   this.show_consults = false;
  }

  ngOnInit() {
    this.doctor_data = this.account.get_current_state();
    this.doctor_subscription = this.endpoint.refresh$.subscribe(
      res=>{
        this.get_patient_dates( this.doctor_data.id );
        this.get_consults_data( this.doctor_data.id );
      }
    )
    this.get_patient_dates( this.doctor_data.id );
    this.get_consults_data( this.doctor_data.id );
    this.get_data_doctor();
  }

  get_days_from_date(month, year) {

    const startDate = moment.utc(`${year}/${month}/01`)
    const endDate = startDate.clone().endOf('month')
    this.date_select = startDate;


    this.year_select  = this.date_select._i;
    this.year_select2 =this.year_select.substr(0,4);



    const diffDays = endDate.diff(startDate, 'days', true)
    const numberDays = Math.round(diffDays);

    const array_days = Object.keys([...Array(numberDays)]).map((a: any) => {
      a = parseInt(a) + 1;
      const dayObject = moment(`${year}-${month}-${a}`);
      return {
        name: dayObject.format("dddd"),
        value: a,
        indexWeek: dayObject.isoWeekday()
      };
    });

  this.month_select_principal = array_days;
  this.month_select           = this.date_select._i;
  this.month_select_month     =this.month_select.substr(5,2); 
  this.month_present          = this.month_select.substr(5,2);
  
}

  change_month(flag) {
    if (flag < 0) {
      const prevDate = this.date_select.clone().subtract(1, "month");
      this.get_days_from_date(prevDate.format("MM"), prevDate.format("YYYY"));   
    } else {
      const nextDate = this.date_select.clone().add(1, "month");
      this.get_days_from_date(nextDate.format("MM"), nextDate.format("YYYY"));
    }
    
  }

  get_patient_dates( id ){  
    
    let params = {
      id_doctor : id
    }
    this.doctor_subscription = this.endpoint.get_dates_specific( params ).subscribe(
      data =>{
        if ( data ){
          this.patient_data = data;
          this.patient_data1 = this.patient_data.list;
        }
      }
    )
  }

  get_consults_data( id ){
    let params = {
      id_doctor : id
    }
this.doctor_subscription = this.endpoint.get_consults_by_doctor( params ).subscribe(
  data =>{
    if(data){
      this.consults_data = data;
      this.consults_data1 = this.consults_data.list;
    }
  }
)
  }


  get_data_doctor(){
    this.doctor_subscription = this.endpoint.get_data_doctor().subscribe(
      data=>{
        if(data){
          this.doctor_data_specific = data;
        } 
      }
    )
  }

  click_day(day) {
    const month_year = this.date_select.format('YYYY-MM')
    const parse = `${month_year}-${day.value}`
    const object_date = moment(parse)
    this.date_value = object_date;  
    this.fecha_date = this.date_value._d;
     this.data = {
        id_patient  : "", 
        id_doctor   : "",
        description : "", 
        fecha_date  : this.fecha_date, 
        hour_date   : "",
        duration    : ""
      };
    
    this.pageService.openModal(this.createModal, "lg");

  }

  show_consult(data){
    this.data_consult = {
      id_patient  : data.id_patient, 
      id_doctor   : data.id_doctor, 
      description : data.description,
      reason      : data.reason,
      doctor_gender  : this.doctor_data_specific.list[0].doctor_gender,
      doctor_specialty  : this.doctor_data_specific.list[0].doctor_specialty,
      doctor_name  : this.doctor_data_specific.list[0].doctor_name,
      recomendations  : data.recomendations,
      title  :       data.reason,
      doctor_image  :  this.doctor_data_specific.list[0].doctor_image,
      created_at    : data.created_at
    };
    this.show_consults = true;
  }

 

  show_modal_date(data){
    this.data_date = {
      id_patient   : data.id_patient, 
      description  : data.description,
      fecha_date   : data.date_complete,
      hour_date  :   data.hora,
      duration   :   data.duration,
      id_date   :    data.id_date,
      paciente  : data.paciente
    }
  this.pageService.openModal(this.updateModal,"lg");
  }
}



export interface dates{
    paciente : string,
    anio     : number,
    mes      : number,
    dia      : number,
    hora     : string,
    id_date  : number,
    id_patient   : number,
    date_complete : string
}[]

export interface consults{
  anio     : number,
  dia      : number,
  hora     : string,
  mes      : number,
  paciente : string,
  id       : number,
  id_doctor : number,
  id_patient : number,
  reason     : string,
  created_at : string
}