/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./background.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./pathologies/pathologies.component.ngfactory";
import * as i4 from "./pathologies/pathologies.component";
import * as i5 from "../../../services/expedient.service";
import * as i6 from "../../../services/page.service";
import * as i7 from "./pathologies/pathologies.service";
import * as i8 from "../../../shared/services/sweel.service";
import * as i9 from "./allergies/allergies.component.ngfactory";
import * as i10 from "./allergies/allergies.component";
import * as i11 from "../../../services/app.service";
import * as i12 from "./allergies/allergies.service";
import * as i13 from "../../../services/catalogs.service";
import * as i14 from "ngx-toastr";
import * as i15 from "@angular/common";
import * as i16 from "./background.component";
var styles_BackgroundComponent = [i0.styles];
var RenderType_BackgroundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BackgroundComponent, data: {} });
export { RenderType_BackgroundComponent as RenderType_BackgroundComponent };
function View_BackgroundComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_BackgroundComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-expedient-background-pathologies", [], null, [[null, "change_view"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change_view" === en)) {
        var pd_0 = ((_co.view = 1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_BackgroundPathologiesComponent_0, i3.RenderType_BackgroundPathologiesComponent)), i1.ɵdid(2, 245760, null, 0, i4.BackgroundPathologiesComponent, [i5.ExpedientService, i6.PageService, i7.PathologiesService, i8.SweelService], null, { _change_view_event: "change_view" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_BackgroundComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-expedient-background-allergies", [], null, [[null, "change_view"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change_view" === en)) {
        var pd_0 = ((_co.view = 0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_BackgroundAllergiesComponent_0, i9.RenderType_BackgroundAllergiesComponent)), i1.ɵdid(2, 245760, null, 0, i10.BackgroundAllergiesComponent, [i11.AppService, i5.ExpedientService, i6.PageService, i12.AllergiesService, i8.SweelService, i13.CatalogsService, i14.ToastrService], null, { _change_view_event: "change_view" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_BackgroundComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BackgroundComponent_3)), i1.ɵdid(2, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BackgroundComponent_4)), i1.ɵdid(4, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.view == 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.view == 1); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_BackgroundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BackgroundComponent_1)), i1.ɵdid(1, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BackgroundComponent_2)), i1.ɵdid(3, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.expedient_state.version == "expedient"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.expedient_state.version == "new_patient"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_BackgroundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-expedient-background", [], null, null, null, View_BackgroundComponent_0, RenderType_BackgroundComponent)), i1.ɵdid(1, 245760, null, 0, i16.BackgroundComponent, [i5.ExpedientService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BackgroundComponentNgFactory = i1.ɵccf("app-expedient-background", i16.BackgroundComponent, View_BackgroundComponent_Host_0, {}, {}, []);
export { BackgroundComponentNgFactory as BackgroundComponentNgFactory };
