import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParameterCodec } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";

import "rxjs/add/observable/of";
import "rxjs/add/operator/do";
import "rxjs/add/operator/delay";
import "rxjs/add/operator/catch";
import "rxjs/add/operator/map";
import "rxjs/add/operator/toPromise";
import "rxjs/add/observable/throw";
import { Observer } from "rxjs/Observer";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

export interface data_api {
    count: any;
    list: any;
    skip: any;
}

export class FormQueryEncoder implements HttpParameterCodec {
    encodeKey(k: string): string {
        return encodeURIComponent(k);
    }
    encodeValue(v: string): string {
        return encodeURIComponent(v);
    }
    decodeKey(k: string): string {
        return encodeURIComponent(k);
    }
    decodeValue(v: string): string {
        return encodeURIComponent(v);
    }
}

@Injectable()
export class AppService {
    private endpoint: string;
    public host: string;
    private patient_id: string;
    private user_session_name: string;

    private _refresh$ = new Subject<void>();

    observer: Observer<any>;
    set pageTitle(value: string) {
        this.titleService.setTitle(`UNIMED Plataforma`);
    }

    constructor(
        private httpClient: HttpClient,
        public router: Router,
        public activeRoute: ActivatedRoute,
        private titleService: Title
    ) {
        this.host = environment.doctor_server;
        console.info(this.host);
        this.endpoint = this.host + "/api";
        this.user_session_name = "doctor-unimed-user-session";
        this.patient_id = "unimed-patient-id";
    }

    get refresh$() {
        return this._refresh$;
    }

    // _______________________________________________________________________________________
    // 									   	  UTIL

    redirect_to = (url: string): Promise<boolean> => this.router.navigateByUrl(url);

    get_file_endpoint(path: string, name: string): string {
        return `${this.host}/files_storage${path ? "/" + path : ""}/${name}`;
    }

    // 									   	  UTIL
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 									   	  SESSION

    set_patient_id = (patient_id) => {
        localStorage.setItem(this.patient_id, patient_id);
    };

    get_patient_id = (patient_id = localStorage.getItem(this.patient_id)) => (patient_id ? patient_id : null);

    get_session = (session = localStorage.getItem(this.user_session_name)) => (session ? JSON.parse(session) : null);

    set_session = (session) => {
        localStorage.setItem(this.user_session_name, JSON.stringify(session));
    };

    reset_session = () => {
        localStorage.removeItem(this.user_session_name);
    };

    verify_session = (): Observable<any> => this.httpClient.get(this.endpoint + "/verify_session");

    decode_session = (): Observable<any> => this.httpClient.get(this.endpoint + "/decode_session");

    // 									   	  SESSION
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 									   	  CATALOGS

    get_catalogs = (payload: any): Observable<any> => this.httpClient.get(this.endpoint + "/get_catalogs", { params: payload });

    get_catalogs_medicines = (payload: any): Observable<any> => this.httpClient.get(this.endpoint + "/get_catalogs_medicines", { params: payload });

    get_catalogs_doctors = (payload: any): Observable<any> => this.httpClient.get(this.endpoint + "/get_catalogs_doctors", { params: payload });

    gender_catalogue = (): Observable<any> => this.httpClient.get(this.endpoint + "/gender_catalogue");

    new_patient_catalogs = (): Observable<any> => this.httpClient.get(this.endpoint + "/new_patient_catalogs");

    new_consult_general_catalogs = (): Observable<any> => this.httpClient.get(this.endpoint + "/new_consult_general_catalogs");

    diseases_catalogs = (): Observable<any> => this.httpClient.get(this.endpoint + "/diseases_catalogs");

    new_vaccine_catalogs = (): Observable<any> => this.httpClient.get(this.endpoint + "/new_vaccine_catalogs");

    icd_pathologies = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/icd_pathologies", {
            params: payload,
        });

    new_procedure_catalogs = (): Observable<any> => this.httpClient.get(this.endpoint + "/new_procedure_catalogs");

    new_exam_catalogs = (): Observable<any> => this.httpClient.get(this.endpoint + "/new_exam_catalogs");

    // OBTENER DISEASES TEMPORAL
    get_diseases() {
        return this.httpClient.get(this.endpoint + "/diseases_catalogs");
    }

    new_anthropometries_catalogs = (): Observable<any> => this.httpClient.get(this.endpoint + "/new_anthropometries_catalogs");

    new_vitals_catalogs = (): Observable<any> => this.httpClient.get(this.endpoint + "/new_vitals_catalogs");

    new_treatment_catalogs = (): Observable<any> => this.httpClient.get(this.endpoint + "/new_treatment_catalogs");

    new_prescription_catalogs = (): Observable<any> => this.httpClient.get(this.endpoint + "/new_prescription_catalogs");

    new_procedures_catalogs = (): Observable<any> => this.httpClient.get(this.endpoint + "/new_procedures_catalogs");

    new_references_catalogs = (): Observable<any> => this.httpClient.get(this.endpoint + "/new_references_catalogs");

    new_exams_laboratory_catalogs = (): Observable<any> => this.httpClient.get(this.endpoint + "/new_exams_laboratory_catalogs");

    // 									   	  CATALOGS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 									   	  USER

    get_user(payload: any): Observable<any> {
        return this.httpClient.get(this.endpoint + "/get_user", {
            params: payload,
        });
    }

    get_basic_user(payload: any): Observable<any> {
        return this.httpClient.get(this.endpoint + "/get_basic_user", {
            params: payload,
        });
    }

    get_user_info(payload: any): Observable<any> {
        return this.httpClient.get(this.endpoint + "/get_user_info", {
            params: payload,
        });
    }

    validate_insert_user(payload: any): Observable<any> {
        return this.httpClient.get(this.endpoint + "/validate_insert_user", {
            params: payload,
        });
    }

    validate_edit_user(payload: any): Observable<any> {
        return this.httpClient.get(this.endpoint + "/validate_edit_user", {
            params: payload,
        });
    }

    insert_user(payload: any): Observable<any> {
        return this.httpClient.post(this.endpoint + "/insert_user", payload);
    }

    get_consults(payload: any): Observable<any> {
        return this.httpClient.get(this.endpoint + "/get_consults", {
            params: payload,
        });
    }

    get_my_consults(payload: any): Observable<any> {
        return this.httpClient.get(this.endpoint + "/get_my_consults", {
            params: payload,
        });
    }

    get_my_patients(payload: any): Observable<any> {
        return this.httpClient.get(this.endpoint + "/get_my_patients", {
            params: payload,
        });
    }

    get_consult_details(payload: any): Observable<any> {
        return this.httpClient.get(this.endpoint + "/get_consult_details", {
            params: payload,
        });
    }

    get_patient_indicators(payload: any): Observable<any> {
        return this.httpClient.get(this.endpoint + "/get_patient_indicators", {
            params: payload,
        });
    }

    insert_consult(payload: any): Observable<any> {
        return this.httpClient.post(this.endpoint + "/insert_consult", payload);
    }

    edit_user(payload: any): Observable<any> {
        return this.httpClient.put(this.endpoint + "/edit_user", payload);
    }

    delete_user(payload: any) {
        return this.httpClient.delete(this.endpoint + "/delete_user", {
            params: payload,
        });
    }

    delete_user_undo(payload: any) {
        return this.httpClient.delete(this.endpoint + "/delete_user_undo", {
            params: payload,
        });
    }

    change_password(payload: any): Observable<any> {
        return this.httpClient.put(this.endpoint + "/change_password", payload);
    }

    // 									   	  USER
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 									   	  AUTH

    login = (payload: any): Observable<any> => this.httpClient.post(this.endpoint + "/login", payload);

    get_user_data = (): Observable<any> => this.httpClient.get(this.endpoint + "/get_user_data");

    steps_2 = (payload: any): Observable<any> => this.httpClient.post(this.endpoint + "/steps_2", payload);

    send_steps_2 = (): Observable<any> => this.httpClient.post(this.endpoint + "/send_steps_2", {});

    first_login = (payload: any): Observable<any> => this.httpClient.post(this.endpoint + "/first_login", payload);

    recover_password = (payload: any): Observable<any> => this.httpClient.post(this.endpoint + "/recover_password", payload);

    reset_password = (payload, token): Observable<any> =>
        this.httpClient.post(this.endpoint + "/reset_password", payload, {
            headers: new HttpHeaders({ authorization: token }),
        });

    logout = (): Observable<any> => {
        localStorage.clear();
        return this.httpClient.post(this.endpoint + "/logout", {});
    };

    // 									   	  AUTH
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 									   	  PATIENTS

    get_patients(payload?: any) {
        return this.httpClient.get(this.endpoint + "/get_patients", {
            params: payload,
        });
    }

    get_patient_detail(payload: any) {
        return this.httpClient.get(this.endpoint + "/get_patient_detail", {
            params: payload,
        });
    }

    insert_patient_by_doctor(payload: any) {
        return this.httpClient.post(this.endpoint + "/insert_patient_by_doctor", payload);
    }

    update_patient_detail(payload: any) {
        return this.httpClient.put(this.endpoint + "/update_patient_detail", payload);
    }

    update_doctor_profile(payload: any) {
        return this.httpClient.put(this.endpoint + "/update_doctor_profile", payload);
    }

    update_specific_profile(payload?: any) {
        return this.httpClient.put(this.endpoint + "/update_specific_profile", payload);
    }

    update_patient_profile(payload: any) {
        return this.httpClient.put(this.endpoint + "/update_patient_profile", payload);
    }

    // 									   	  PATIENTS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 									  EXPEDIENT GENERAL

    expedient_general_get_general = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/general/get_general", {
            params: payload,
        });

    expedient_general_insert_general = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/general/insert_general", payload);

    expedient_general_update_general = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/expedient/general/update_general", payload);

    // 									  EXPEDIENT GENERAL
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT INSURANCES ACCIDENT

    expedient_insurances_accident_get_accidents = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/insurances/accident/get_accidents", { params: payload });

    expedient_insurances_accident_insert_accident = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/insurances/accident/insert_accident", payload);

    expedient_insurances_accident_update_accident = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/expedient/insurances/accident/update_accident", payload);

    expedient_insurances_accident_delete_accident = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/insurances/accident/delete_accident", { params: payload });

    // 							     EXPEDIENT INSURANCES ACCIDENT
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT EXAMS LABORATORY

    expedient_exams_laboratory_get_laboratories = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/exams/laboratory/get_laboratories", { params: payload });

    expedient_exams_laboratory_insert_laboratory = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/exams/laboratory/insert_laboratory", payload);

    expedient_exams_laboratory_update_laboratory = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/expedient/exams/laboratory/update_laboratory", payload);

    expedient_exams_laboratory_delete_laboratory = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/exams/laboratory/delete_laboratory", { params: payload });

    // 							     EXPEDIENT EXAMS LABORATORY
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT BACKGROUND PATHOLOGIES

    expedient_background_pathologies_get_pathologies = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/background/pathologies/get_pathologies", { params: payload });

    expedient_background_pathologies_insert_pathology = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/background/pathologies/insert_pathology", payload);

    expedient_background_pathologies_update_pathology = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/expedient/background/pathologies/update_pathology", payload);

    expedient_background_pathologies_delete_pathology = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/background/pathologies/delete_pathology", {
            params: payload,
        });

    // 							     EXPEDIENT BACKGROUND PATHOLOGIES
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT BACKGROUND ALLERGIES

    expedient_background_allergies_get_allergies = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/background/allergies/get_allergies", { params: payload });

    expedient_background_allergies_insert_allergy = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/background/allergies/insert_allergy", payload);

    expedient_background_allergies_update_allergy = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/expedient/background/allergies/update_allergy", payload);

    expedient_background_allergies_delete_allergy = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/background/allergies/delete_allergy", { params: payload });

    // 							     EXPEDIENT BACKGROUND ALLERGIES
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT CONTACTS EMERGENCY

    expedient_contacts_emergency_get_emergency = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/contacts/emergency/get_emergency", { params: payload });

    expedient_contacts_emergency_insert_emergency = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/contacts/emergency/insert_emergency", payload);

    expedient_contacts_emergency_update_emergency = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/expedient/contacts/emergency/update_emergency", payload);

    expedient_contacts_emergency_delete_emergency = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/contacts/emergency/delete_emergency", { params: payload });

    // 							     EXPEDIENT CONTACTS EMERGENCY
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT CONTACTS DOCTOR

    expedient_contacts_doctor_get_doctor = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/contacts/doctor/get_doctor", { params: payload });

    expedient_contacts_doctor_insert_doctor = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/contacts/doctor/insert_doctor", payload);

    expedient_contacts_doctor_update_doctor = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/expedient/contacts/doctor/update_doctor", payload);

    expedient_contacts_doctor_delete_doctor = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/contacts/doctor/delete_doctor", { params: payload });

    // 							     EXPEDIENT CONTACTS DOCTOR
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT PROCEDURES DOCTOR

    expedient_procedures_doctor_get_doctors = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/procedures/doctor/get_doctors", { params: payload });

    expedient_procedures_doctor_insert_doctor = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/procedures/doctor/insert_doctor", payload);

    expedient_procedures_doctor_update_doctor = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/expedient/procedures/doctor/update_doctor", payload);

    expedient_procedures_doctor_delete_doctor = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/procedures/doctor/delete_doctor", { params: payload });

    // 							     EXPEDIENT PROCEDURES DOCTOR
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT REFERENCES DOCTOR

    expedient_references_doctor_get_doctors = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/references/doctor/get_doctors", { params: payload });

    expedient_references_doctor_insert_doctor = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/references/doctor/insert_doctor", payload);

    expedient_references_doctor_update_doctor = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/expedient/references/doctor/update_doctor", payload);

    expedient_references_doctor_delete_doctor = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/references/doctor/delete_doctor", { params: payload });

    // 							     EXPEDIENT REFERENCES DOCTOR
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT RELATIONS DEPENDANTS

    expedient_relations_dependants_get_dependants = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/relations/dependants/get_dependants", { params: payload });

    expedient_relations_dependants_insert_dependants = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/relations/dependants/insert_dependants", payload);

    expedient_relations_dependants_update_dependants = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/expedient/relations/dependants/update_dependants", payload);

    expedient_relations_dependants_confirm_dependants = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/expedient/relations/dependants/confirm_dependants", payload);

    expedient_relations_dependants_delete_dependants = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/relations/dependants/delete_dependants", {
            params: payload,
        });

    // 							     EXPEDIENT RELATIONS DEPENDANTS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT RELATIONS TUTORS

    expedient_relations_tutors_get_tutors = (payload: any): Observable<any> => this.httpClient.get(this.endpoint + "/expedient/relations/tutors/get_tutors", { params: payload });

    expedient_relations_tutors_validate_phone = (payload: any): Observable<any> => this.httpClient.get(this.endpoint + "/expedient/relations/tutors/validate_phone", { params: payload });

    expedient_relations_tutors_insert_tutors = (payload: any): Observable<any> => this.httpClient.post(this.endpoint + "/expedient/relations/tutors/insert_tutors", payload);

    expedient_relations_tutors_insert_tutors_bulk = (payload: any): Observable<any> => this.httpClient.post(this.endpoint + "/expedient/relations/tutors/insert_tutors_bulk", payload);

    expedient_relations_tutors_verify_whatsapp = (payload: any): Observable<any> => this.httpClient.post(this.endpoint + "/expedient/relations/tutors/verify_whatsapp", payload);

    expedient_relations_tutors_update_tutors = (payload: any): Observable<any> => this.httpClient.put(this.endpoint + "/expedient/relations/tutors/update_tutors", payload);

    expedient_relations_tutors_delete_tutors = (payload: any): Observable<any> => this.httpClient.delete(this.endpoint + "/expedient/relations/tutors/delete_tutors", { params: payload });

    // 							     EXPEDIENT RELATIONS TUTORS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT INSTITUTIONS

    expedient_institutions_get_institutions = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/institutions/get_institutions", { params: payload });

    expedient_institutions_insert_institutions = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/institutions/insert_institutions", payload);

    expedient_institutions_update_institutions = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/expedient/institutions/update_institutions", payload);

    expedient_institutions_delete_institutions = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/institutions/delete_institutions", { params: payload });

    // 							     EXPEDIENT INSTITUTIONS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT VACCINES

    expedient_vaccines_get_vaccines = (payload: any): Observable<any> => this.httpClient.get(this.endpoint + "/expedient/vaccines/get_vaccines", { params: payload });

    expedient_vaccines_insert_vaccines = (payload: any): Observable<any> => this.httpClient.post(this.endpoint + "/expedient/vaccines/insert_vaccines", payload);

    expedient_vaccines_insert_vaccines_custom = (payload: any): Observable<any> => this.httpClient.post(this.endpoint + "/expedient/vaccines/insert_vaccines_custom", payload);

    expedient_vaccines_insert_vaccines_custom_dose = (payload: any): Observable<any> => this.httpClient.post(this.endpoint + "/expedient/vaccines/insert_vaccines_custom_dose", payload);

    expedient_vaccines_update_vaccines = (payload: any): Observable<any> => this.httpClient.put(this.endpoint + "/expedient/vaccines/update_vaccines", payload);

    expedient_vaccines_delete_vaccines = (payload: any): Observable<any> => this.httpClient.delete(this.endpoint + "/expedient/vaccines/delete_vaccines", { params: payload });

    // 							     EXPEDIENT VACCINES
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT GROWTH CURVE

    expedient_growth_curve_get_growth_curve = (payload: any): Observable<any> => this.httpClient.get(this.endpoint + "/expedient/growth_curve/get_growth_curve", { params: payload });

    expedient_growth_curve_insert_growth_curve = (payload: any): Observable<any> => this.httpClient.post(this.endpoint + "/expedient/growth_curve/insert_growth_curve", payload);

    expedient_growth_curve_update_growth_curve = (payload: any): Observable<any> => this.httpClient.put(this.endpoint + "/expedient/growth_curve/update_growth_curve", payload);

    expedient_growth_curve_delete_growth_curve = (payload: any): Observable<any> => this.httpClient.delete(this.endpoint + "/expedient/growth_curve/delete_growth_curve", { params: payload });

    // 							     EXPEDIENT GROWTH CURVE
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT CONSULT GENERAL

    expedient_consult_general_get_consult = (payload: any): Observable<any> => this.httpClient.get(this.endpoint + "/expedient/consult/general/get_consult", { params: payload });

    expedient_consult_general_init_consult = (payload: any): Observable<any> => this.httpClient.post(this.endpoint + "/expedient/consult/general/init_consult", payload);

    expedient_consult_general_save_consult = (payload: any): Observable<any> => this.httpClient.put(this.endpoint + "/expedient/consult/general/save_consult", payload);

    expedient_consult_general_auto_save_consult = (payload: any): Observable<any> => this.httpClient.put(this.endpoint + "/expedient/consult/general/auto_save_consult", payload);

    expedient_consult_general_cancel_consult = (payload: any): Observable<any> => this.httpClient.put(this.endpoint + "/expedient/consult/general/cancel_consult", payload);

    expedient_consult_general_finish_consult = (payload: any): Observable<any> => this.httpClient.put(this.endpoint + "/expedient/consult/general/finish_consult", payload);

    // 							     EXPEDIENT CONSULT GENERAL
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT CONSULT GENERAL GENERAL

    expedient_consult_general_general_get_general = (payload: any): Observable<any> => this.httpClient.get(this.endpoint + "/expedient/consult/general/general/get_general", { params: payload });

    expedient_consult_general_general_update_general = (payload: any): Observable<any> => this.httpClient.put(this.endpoint + "/expedient/consult/general/general/update_general", payload);

    // 							     EXPEDIENT CONSULT GENERAL GENERAL
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT CONSULT GENERAL ANTHROPOMETRIES

    expedient_consult_general_anthropometries_get_anthropometries = (payload: any): Observable<any> => this.httpClient.get(this.endpoint + "/expedient/consult/general/anthropometries/get_anthropometries", {
        params: payload,
    });

    expedient_consult_general_anthropometries_update_anthropometries = (payload: any): Observable<any> => this.httpClient.put(this.endpoint + "/expedient/consult/general/anthropometries/update_anthropometries", payload);

    // 							     EXPEDIENT CONSULT GENERAL ANTHROPOMETRIES
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT CONSULT GENERAL VITALS

    expedient_consult_general_vitals_get_vitals = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/consult/general/vitals/get_vitals", { params: payload });

    expedient_consult_general_vitals_update_vitals = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/expedient/consult/general/vitals/update_vitals", payload);

    // 							     EXPEDIENT CONSULT GENERAL VITALS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT CONSULT GENERAL EXAMS PHYSICAL

    expedient_consult_general_exams_physical_get_exams_physical = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/consult/general/exams_physical/get_exams_physical", {
            params: payload,
        });

    expedient_consult_general_exams_physical_update_exams_physical = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/expedient/consult/general/exams_physical/update_exams_physical", payload);

    // 							     EXPEDIENT CONSULT GENERAL EXAMS PHYSICAL
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT CONSULT GENERAL DIAGNOSIS

    expedient_consult_general_diagnosis_get_diagnosis = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/consult/general/diagnosis/get_diagnosis", { params: payload });

    expedient_consult_general_diagnosis_insert_diagnosis = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/consult/general/diagnosis/insert_diagnosis", payload);

    expedient_consult_general_diagnosis_update_diagnosis = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/expedient/consult/general/diagnosis/update_diagnosis", payload);

    expedient_consult_general_diagnosis_delete_diagnosis = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/consult/general/diagnosis/delete_diagnosis", {
            params: payload,
        });

    // 							     EXPEDIENT CONSULT GENERAL DIAGNOSIS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT CONSULT GENERAL TREATMENT

    expedient_consult_general_treatment_get_treatment = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/consult/general/treatment/get_treatment", { params: payload });

    expedient_consult_general_treatment_insert_treatment = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/consult/general/treatment/insert_treatment", payload);

    expedient_consult_general_treatment_delete_treatment = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/consult/general/treatment/delete_treatment", {
            params: payload,
        });

    // 							     EXPEDIENT CONSULT GENERAL TREATMENT
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT CONSULT GENERAL PRESCRIPTION

    expedient_consult_general_prescription_get_prescription = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/consult/general/prescription/get_prescription", {
            params: payload,
        });

    expedient_consult_general_prescription_insert_prescription = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/consult/general/prescription/insert_prescription", payload);

    expedient_consult_general_prescription_delete_prescription = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/consult/general/prescription/delete_prescription", {
            params: payload,
        });

    // 							     EXPEDIENT CONSULT GENERAL PRESCRIPTION
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT CONSULT GENERAL RECOMMENDATION

    expedient_consult_general_recommendation_get_recommendation = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/consult/general/recommendation/get_recommendation", {
            params: payload,
        });

    expedient_consult_general_recommendation_update_recommendation = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/expedient/consult/general/recommendation/update_recommendation", payload);

    // 							     EXPEDIENT CONSULT GENERAL RECOMMENDATION
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT CONSULT GENERAL PROCEDURES_REQUESTS

    expedient_consult_general_procedures_requests_get_procedures_requests = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/consult/general/procedures_requests/get_procedures_requests", {
            params: payload,
        });

    expedient_consult_general_procedures_requests_insert_procedures_requests = (payload: any): Observable<any> =>
        this.httpClient.post(
            this.endpoint + "/expedient/consult/general/procedures_requests/insert_procedures_requests",
            payload
        );

    expedient_consult_general_procedures_requests_delete_procedures_requests = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/consult/general/procedures_requests/delete_procedures_requests", {
            params: payload,
        });

    expedient_consult_general_procedures_requests_pdf_procedures_requests_list = (payload: any): Observable<any> =>
        this.httpClient.post(
            this.endpoint + "/expedient/consult/general/procedures_requests/pdf_procedures_requests_list",
            payload,
            { responseType: "blob" }
        );

    expedient_consult_general_procedures_requests_pdf_procedures_requests = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/consult/general/procedures_requests/pdf_procedures_requests", payload, {
            responseType: "blob",
        });

    // 							     EXPEDIENT CONSULT GENERAL PROCEDURES_REQUESTS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT CONSULT GENERAL PROCEDURES_RESULTS

    expedient_consult_general_procedures_results_get_procedures_results = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/consult/general/procedures_results/get_procedures_results", {
            params: payload,
        });

    expedient_consult_general_procedures_results_insert_procedures_results = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/consult/general/procedures_results/insert_procedures_results", payload);

    expedient_consult_general_procedures_results_delete_procedures_results = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/consult/general/procedures_results/delete_procedures_results", {
            params: payload,
        });

    // 							     EXPEDIENT CONSULT GENERAL PROCEDURES_RESULTS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT CONSULT GENERAL REFERENCES

    expedient_consult_general_references_get_references = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/consult/general/references/get_references", {
            params: payload,
        });

    expedient_consult_general_references_insert_references = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/consult/general/references/insert_references", payload);

    expedient_consult_general_references_delete_references = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/consult/general/references/delete_references", {
            params: payload,
        });

    expedient_consult_general_references_pdf_references = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/consult/general/references/pdf_references", payload, {
            responseType: "blob",
        });

    // 							     EXPEDIENT CONSULT GENERAL REFERENCES
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT CONSULT GENERAL EXAMS_RESULTS

    expedient_consult_general_exams_results_get_exams_results = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/consult/general/exams_results/get_exams_results", {
            params: payload,
        });

    expedient_consult_general_exams_results_insert_exams_results = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/consult/general/exams_results/insert_exams_results", payload);

    expedient_consult_general_exams_results_delete_exams_results = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/consult/general/exams_results/delete_exams_results", {
            params: payload,
        });

    // 							     EXPEDIENT CONSULT GENERAL EXAMS_RESULTS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     EXPEDIENT CONSULT GENERAL EXAMS_REQUESTS

    expedient_consult_general_exams_requests_get_exams_requests = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/expedient/consult/general/exams_requests/get_exams_requests", {
            params: payload,
        });

    expedient_consult_general_exams_requests_insert_exams_requests = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/consult/general/exams_requests/insert_exams_requests", payload);

    expedient_consult_general_exams_requests_delete_exams_requests = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/consult/general/exams_requests/delete_exams_requests", {
            params: payload,
        });

    expedient_consult_general_exams_requests_pdf_exams_requests_list = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/consult/general/exams_requests/pdf_exams_requests_list", payload, {
            responseType: "blob",
        });

    expedient_consult_general_exams_requests_pdf_exams_requests = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/consult/general/exams_requests/pdf_exams_requests", payload, {
            responseType: "blob",
        });

    // 							     EXPEDIENT CONSULT GENERAL EXAMS_REQUESTS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     PROFILE

    profile_get_profile = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/profile/get_profile", {
            params: payload,
        });

    profile_upload_profile_image = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/profile/upload_profile_image", payload);

    profile_remove_profile_image = (): Observable<any> =>
        this.httpClient.put(this.endpoint + "/profile/remove_profile_image", {});

    // 							     PROFILE
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     PROFILE EXPERIENCES

    profile_experiences_get_experiences = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/profile/experiences/get_experiences", { params: payload });

    profile_experiences_insert_experiences = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/profile/experiences/insert_experiences", payload);

    profile_experiences_update_experiences = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/profile/experiences/update_experiences", payload);

    profile_experiences_delete_experiences = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/profile/experiences/delete_experiences", { params: payload });

    // 							     PROFILE EXPERIENCES
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     PROFILE EDUCATIONS

    profile_educations_get_educations = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/profile/educations/get_educations", { params: payload });

    profile_educations_insert_educations = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/profile/educations/insert_educations", payload);

    profile_educations_update_educations = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/profile/educations/update_educations", payload);

    profile_educations_delete_educations = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/profile/educations/delete_educations", { params: payload });

    // 							     PROFILE EDUCATIONS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     PROFILE INSURANCES

    profile_insurances_get_insurances = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/profile/insurances/get_insurances", { params: payload });

    profile_insurances_insert_insurances = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/profile/insurances/insert_insurances", payload);

    profile_insurances_update_insurances = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/profile/insurances/update_insurances", payload);

    profile_insurances_delete_insurances = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/profile/insurances/delete_insurances", { params: payload });

    // 							     PROFILE INSURANCES
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     PROFILE MEMBERSHIPS

    profile_memberships_get_memberships = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/profile/memberships/get_memberships", { params: payload });

    profile_memberships_insert_memberships = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/profile/memberships/insert_memberships", payload);

    profile_memberships_update_memberships = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/profile/memberships/update_memberships", payload);

    profile_memberships_delete_memberships = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/profile/memberships/delete_memberships", { params: payload });

    // 							     PROFILE MEMBERSHIPS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     PROFILE SPECIALTIES AREAS

    profile_specialties_areas_get_specialties_areas = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/profile/specialties_areas/get_specialties_areas", { params: payload });

    profile_specialties_areas_insert_specialties_areas = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/profile/specialties_areas/insert_specialties_areas", payload);

    profile_specialties_areas_update_specialties_areas = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/profile/specialties_areas/update_specialties_areas", payload);

    profile_specialties_areas_delete_specialties_areas = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/profile/specialties_areas/delete_specialties_areas", {
            params: payload,
        });

    // 							     PROFILE SPECIALTIES AREAS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     PROFILE MY FOCUS

    profile_my_focus_get_my_focus = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/profile/my_focus/get_my_focus", {
            params: payload,
        });

    profile_my_focus_update_my_focus = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/profile/my_focus/update_my_focus", payload);

    // 							     PROFILE MY FOCUS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     PROFILE PEOPLE MAY KNOW

    profile_people_may_know_get_people_may_know = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/profile/people_may_know/get_people_may_know", { params: payload });

    profile_people_may_know_insert_people_may_know = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/profile/people_may_know/insert_people_may_know", payload);

    profile_people_may_know_update_people_may_know = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/profile/people_may_know/update_people_may_know", payload);

    profile_people_may_know_delete_people_may_know = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/profile/people_may_know/delete_people_may_know", { params: payload });

    // 							     PROFILE PEOPLE MAY KNOW
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     PROFILE LOCATIONS

    profile_locations_get_locations = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/profile/locations/get_locations", { params: payload });

    profile_locations_insert_locations = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/profile/locations/insert_locations", payload);

    profile_locations_update_locations = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/profile/locations/update_locations", payload);

    profile_locations_delete_locations = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/profile/locations/delete_locations", { params: payload });

    // 							     PROFILE LOCATIONS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     PROFILE CONENCTIONS

    profile_connections_get_connections = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/profile/connections/get_connections", { params: payload });

    profile_connections_insert_connections = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/profile/connections/insert_connections", payload);

    profile_connections_delete_connections = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/profile/connections/delete_connections", { params: payload });

    profile_connections_get_connection = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/profile/connections/get_connection", { params: payload });

    profile_connections_get_search_connections = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/profile/connections/get_search_connections", { params: payload });

    profile_connections_accept_connection = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/profile/connections/accept_connection", payload);

    profile_connections_reject_connection = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/profile/connections/reject_connection", payload);

    // 							     PROFILE CONENCTIONS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     SETTINGS PROFILE

    settings_profile_get_profile = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/settings/profile/get_profile", {
            params: payload,
        });

    settings_profile_update_profile = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/settings/profile/update_profile", payload);

    // 							     SETTINGS PROFILE
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 							     SETTINGS SECURITY

    settings_security_get_security = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/settings/security/get_security", {
            params: payload,
        });

    settings_security_update_security = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/settings/security/update_security", payload);

    settings_security_verify_phone = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/settings/security/verify_phone", payload);

    settings_security_verify_email = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/settings/security/verify_email", payload);

    settings_security_send_verify_email = (payload: any): Observable<any> =>
        this.httpClient.put(this.endpoint + "/settings/security/send_verify_email", payload);

    // 							     SETTINGS SECURITY
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 		          					     CHAT

    chat_get_messages = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/chat/get_messages", {
            params: payload,
        });

    chat_send_message = (payload: any): Observable<any> => this.httpClient.post(this.endpoint + "/chat/send_message", payload);

    // 		          					     CHAT
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 		          					     WHATSAPP

    whatsapp_send_send_verify_code = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/whatsapp/send/send_verify_code", payload);

    whatsapp_send_send_doctor_patient_request = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/whatsapp/send/send_doctor_patient_request", payload);

    whatsapp_send_send_user_verify_phone = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/whatsapp/send/send_user_verify_phone", payload);

    whatsapp_send_send_new_tutor_request = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/whatsapp/send/send_new_tutor_request", payload);

    // 		          					     WHATSAPP
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 		          					     DOCTORS PATIENTS REQUESTS

    expedient_doctors_patients_requests_insert_doctors_patients_request = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/doctors_patients_requests/insert_doctors_patients_request", payload);

    expedient_doctors_patients_requests_insert_doctors_patients = (payload: any): Observable<any> =>
        this.httpClient.post(this.endpoint + "/expedient/doctors_patients_requests/insert_doctors_patients", payload);

    expedient_doctors_patients_requests_delete_doctors_patients_request = (payload: any): Observable<any> =>
        this.httpClient.delete(this.endpoint + "/expedient/doctors_patients_requests/delete_doctors_patients_request", {
            params: payload,
        });

    // 		          					     DOCTORS PATIENTS REQUESTS
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 									   	  MEDICINES

    get_chemical_names() {
        return this.httpClient.get(this.endpoint + "/get_chemical_names");
    }

    get_medicine_type() {
        return this.httpClient.get(this.endpoint + "/get_medicine_type");
    }

    get_medicines_details_catalogue() {
        return this.httpClient.get(this.endpoint + "/get_medicines_details_catalogue");
    }

    get_medicine(payload: any) {
        return this.httpClient.get(this.endpoint + "/get_medicine", {
            params: payload,
        });
    }

    get_medicine_details(payload: any) {
        return this.httpClient.get(this.endpoint + "/get_medicine_details", {
            params: payload,
        });
    }

    get_medicine_instructions(payload: any) {
        return this.httpClient.get(this.endpoint + "/get_medicine_instructions", { params: payload });
    }

    insert_medicines(payload: any) {
        return this.httpClient.post(this.endpoint + "/insert_medicines", payload);
    }

    insert_medicine_instructions(payload: any) {
        return this.httpClient.post(this.endpoint + "/insert_medicine_instructions", payload);
    }

    insert_patients_other_medicines(payload: any) {
        return this.httpClient.post(this.endpoint + "/insert_patients_other_medicines", payload);
    }

    update_medicines(payload: any) {
        return this.httpClient.put(this.endpoint + "/update_medicines", payload);
    }

    update_medicine_instructions(payload: any) {
        return this.httpClient.put(this.endpoint + "/update_medicine_instructions", payload);
    }

    // 									   	  MEDICINES
    // _______________________________________________________________________________________

    // _______________________________________________________________________________________
    // 									   	  VITALS

    get_patients_vital_detail(payload: any) {
        return this.httpClient.get(this.endpoint + "/get_patients_vital_detail", { params: payload });
    }

    get_patients_vitals(payload: any) {
        return this.httpClient.get(this.endpoint + "/get_patients_vitals", {
            params: payload,
        });
    }

    insert_patient_vitals(payload: any) {
        return this.httpClient.post(this.endpoint + "/insert_patient_vitals", payload);
    }

    update_patient_vitals(payload: any) {
        return this.httpClient.put(this.endpoint + "/update_patient_vitals", payload);
    }

    // 									   	  VITALS
    // _______________________________________________________________________________________

    //##################################################################################
    //CURRENT TIME #####################################################################

    get_current_date = (): Observable<any> => this.httpClient.get(this.endpoint + "/get_current_date");

    //CURRENT TIME ######################################################################
    //###################################################################################

    //##########################################################################
    //NOTIFICACIONES ###########################################################

    get_notifications = (payload: any): Observable<any> =>
        this.httpClient.get(this.endpoint + "/get_notifications", {
            params: payload,
        });

    read_notifications(payload: any) {
        return this.httpClient.put(this.endpoint + "/read_notifications", payload);
    }

    read_all_notifications(payload: any) {
        return this.httpClient.put(this.endpoint + "/read_all_notifications", payload);
    }

    createObservable(): Observable<any> {
        return new Observable((observer) => {
            this.observer = observer;
        });
    }

    // 											MEDICINES
    // _______________________________________________________________________________________

    get_medicines_prescription(payload?: any) {
        return this.httpClient.get(this.endpoint + "/get_medicines_prescription", { params: payload });
    }

    insert_medicines_prescription(payload?: any) {
        return this.httpClient.post(this.endpoint + "/insert_medicine_prescription", payload);
    }

    update_medicines_prescription(payload?: any) {
        return this.httpClient.put(this.endpoint + "/update_medicine_prescription", payload);
    }

    delete_medicines_prescription(payload?: any) {
        return this.httpClient.delete(this.endpoint + "/delete_medicine_prescription", { params: payload });
    }

    // 											MEDICINES
    // _______________________________________________________________________________________

    // 											Dates
    // _______________________________________________________________________________________

    get_dates_by_doctor(payload?: any) {
        return this.httpClient.get(this.endpoint + "/get_dates_by_doctor", {
            params: payload,
        });
    }

    get_dates_of_patients(payload?: any) {
        return this.httpClient.get(this.endpoint + "/get_dates_of_patient", {
            params: payload,
        });
    }
    get_consults_by_doctor(payload?: any) {
        return this.httpClient.get(this.endpoint + "/get_consults_doctor", {
            params: payload,
        });
    }

    insert_dates(payload?: any) {
        return this.httpClient.post(this.endpoint + "/insert_dates", payload).pipe(
            tap(() => {
                this.refresh$.next();
            })
        );
    }

    update_dates(payload?: any) {
        return this.httpClient.put(this.endpoint + "/update_dates", payload).pipe(
            tap(() => {
                this.refresh$.next();
            })
        );
    }

    update_state_date(payload?: any) {
        return this.httpClient.put(this.endpoint + "/update_state", payload).pipe(
            tap(() => {
                this.refresh$.next();
            })
        );
    }

    delete_dates(payload?: any) {
        return this.httpClient.delete(this.endpoint + "/delete_dates", { params: payload }).pipe(
            tap(() => {
                this.refresh$.next();
            })
        );
    }

    get_dates_specific(payload?: any) {
        return this.httpClient.get(this.endpoint + "/get_dates_specific", {
            params: payload,
        });
    }

    get_data_doctor(payload?: any) {
        return this.httpClient.get(this.endpoint + "/get_data_doctor", {
            params: payload,
        });
    }

    // 											Dates
    // _______________________________________________________________________________________

    jwt_parser = (payload: any): Observable<any> => this.httpClient.post(this.endpoint + "/jwt_parser", payload);
}
