/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./consults.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/ui/search_button/search_button.component.ngfactory";
import * as i3 from "../../../shared/ui/search_button/search_button.component";
import * as i4 from "@angular/common";
import * as i5 from "./show_consult/show_consult.component.ngfactory";
import * as i6 from "./show_consult/show_consult.component";
import * as i7 from "../../../services/page.service";
import * as i8 from "../../../services/app.service";
import * as i9 from "../../../services/account.service";
import * as i10 from "../../../services/catalogs.service";
import * as i11 from "../../../services/pipes.service";
import * as i12 from "../../../shared/ui/bold_list/bold_list.component.ngfactory";
import * as i13 from "../../../shared/ui/bold_list/bold_list.component";
import * as i14 from "./consults.component";
import * as i15 from "../../../services/patient-service.service";
import * as i16 from "../new_consult/general/consult.service";
var styles_ConsultsComponent = [i0.styles];
var RenderType_ConsultsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConsultsComponent, data: {} });
export { RenderType_ConsultsComponent as RenderType_ConsultsComponent };
function View_ConsultsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "my-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "d-flex text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-search_button", [], null, [[null, "change"], [null, "submit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.on_update() !== false);
        ad = (pd_0 && ad);
    } if (("submit" === en)) {
        var pd_1 = (_co.on_update() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SearchButtonComponent_0, i2.RenderType_SearchButtonComponent)), i1.ɵdid(3, 114688, [[1, 4], ["search_button_ref", 4]], 0, i3.SearchButtonComponent, [], null, { submit: "submit", change: "change" }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "ml-2 btn radius btn-primary no-wrap"], ["style", "font-weight: 500;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reset_consult() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "fa-duotone fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" CONSULTA "]))], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
function View_ConsultsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa-duotone fa-square mr-2 my-auto"]], null, null, null, null, null))], null, null); }
function View_ConsultsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fa-duotone fa-check-square fa-swap-opacity mr-2 my-auto"]], null, null, null, null, null))], null, null); }
function View_ConsultsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, null, null, null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 30); _ck(_v, 1, 0, currVal_0); }, null); }
function View_ConsultsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-show_consult", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = ((_co.show_consult = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ShowConsultComponent_0, i5.RenderType_ShowConsultComponent)), i1.ɵdid(1, 245760, null, 0, i6.ShowConsultComponent, [i7.PageService, i8.AppService, i9.AccountService, i10.CatalogsService, i11.PipesService], { data: [0, "data"] }, { close_event: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selected_item; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ConsultsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { search_button_ref: 0 }), i1.ɵqud(671088640, 2, { bold_list_ref: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 27, "div", [["class", "bg-white pr-3 py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 24, "div", [["class", "container-fluid px-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "d-flex justify-content-between mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "d-flex flex-column my-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "title-last mt-auto"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " ", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "title mb-auto"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" historial de consultas "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConsultsComponent_1)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 8, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 7, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 6, "a", [["class", "ml-auto checkbox-item d-flex my-auto"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.checkbox_check() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConsultsComponent_2)), i1.ɵdid(19, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConsultsComponent_3)), i1.ɵdid(21, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Ver mis consultas "])), (_l()(), i1.ɵeld(24, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "app-bold_list", [], null, [[null, "update_bold_list"], [null, "events_bold_list"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("update_bold_list" === en)) {
        var pd_0 = (_co.update($event) !== false);
        ad = (pd_0 && ad);
    } if (("events_bold_list" === en)) {
        var pd_1 = (_co.events($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_BoldListComponent_0, i12.RenderType_BoldListComponent)), i1.ɵdid(27, 114688, [[2, 4], ["bold_list_ref", 4]], 0, i13.BoldListComponent, [], { in_headers: [0, "in_headers"], in_events: [1, "in_events"], in_contents: [2, "in_contents"], in_data: [3, "in_data"], in_filters: [4, "in_filters"], in_configs: [5, "in_configs"] }, { update_bold_list: "update_bold_list", events_bold_list: "events_bold_list" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConsultsComponent_4)), i1.ɵdid(29, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["showModal", 2]], null, 0, null, View_ConsultsComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.page_state.expedient; _ck(_v, 13, 0, currVal_2); var currVal_3 = (_co.only_mine == false); _ck(_v, 19, 0, currVal_3); var currVal_4 = (_co.only_mine == true); _ck(_v, 21, 0, currVal_4); var currVal_5 = _co.bold_list.headers; var currVal_6 = _co.bold_list.events; var currVal_7 = _co.bold_list.contents; var currVal_8 = _co.data; var currVal_9 = _co.bold_list.filters; var currVal_10 = _co.bold_list.configs; _ck(_v, 27, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.show_consult; _ck(_v, 29, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.page_state.profile ? "perfil" : ""); var currVal_1 = (_co.page_state.expedient ? "expediente" : ""); _ck(_v, 9, 0, currVal_0, currVal_1); }); }
export function View_ConsultsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-consults", [], null, null, null, View_ConsultsComponent_0, RenderType_ConsultsComponent)), i1.ɵdid(1, 245760, null, 0, i14.ConsultsComponent, [i8.AppService, i15.PatientService, i7.PageService, i16.ConsultGeneralService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConsultsComponentNgFactory = i1.ɵccf("app-consults", i14.ConsultsComponent, View_ConsultsComponent_Host_0, {}, {}, []);
export { ConsultsComponentNgFactory as ConsultsComponentNgFactory };
