import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { AccountService } from "src/app/services/account.service";
import { AppService } from "src/app/services/app.service";
import { CatalogsService } from "src/app/services/catalogs.service";
import { PageService } from "src/app/services/page.service";
import { PipesService } from "src/app/services/pipes.service";
import { ConsultClass } from "../../../../classes/consult-class";

@Component({
  selector: "app-show_consult",
  templateUrl: "./show_consult.component.html",
  styleUrls: [
    "./show_consult.component.scss",
    "../../../../shared/ui/datatable/datatable.component.scss",
  ],
})
export class ShowConsultComponent implements OnInit, OnDestroy {
  @Input() data: any & {
    consult: ConsultClass;
  };

  @Output("close") close_event: EventEmitter<any> = new EventEmitter();

  public avatar_configs = {
    size: "6rem",
    font_size: "4.3rem",
  };

  constructor(
    private pageService: PageService,
    public endpoint: AppService,
    private accountService: AccountService,
    private catalogsService: CatalogsService,
    private pipesService: PipesService
  ) {}

  ngOnInit() {
    this.pageService.set_sidebar_3_state({
      expand: true,
      scroll: false,
      show: true,
    });
    this.get_data();
  }

  ngOnDestroy() {
    this.pageService.set_sidebar_3_state({
      expand: true,
      scroll: true,
      show: true,
    });
  }

  get_data() {
    this.endpoint
      .expedient_consult_general_get_consult({ id: this.data.id })
      .subscribe(
        (data) => {
          this.data.consult = new ConsultClass(
            this.catalogsService,
            this.pipesService,
            data
          );
          this.data.consult_l = this.data.consult.to_string();
        },
        (error) => this.close()
      );
  }

  close() {
    this.close_event.emit("");
  }

  get_specialty(id: any) {
    let specialty = this.catalogsService.catalogs.doctors_specialties.find(
      (v) => v.id == id
    );
    return specialty ? specialty.name : "";
  }

  get_file_endpoint(path: string, name: string): string {
    return this.endpoint.get_file_endpoint(path, name);
  }

  is_author(): boolean {
    return (
      this.data.consult.id_doctor == this.accountService.get_current_state().id
    );
  }
}
