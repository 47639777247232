import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { AccountService } from "src/app/services/account.service";
import { AppService } from "src/app/services/app.service";
import { PageService } from "../../services/page.service";

@Injectable({
    providedIn: "root",
})
export class OutConsultGuard implements CanDeactivate<any> {
    constructor(private pageService: PageService, private endpoint: AppService, private accountService: AccountService) {}

    canDeactivate(a, b, c, d) {
        let inConsult = localStorage.getItem("inConsult");

        if (inConsult) {
            this.pageService.cancel_consult(() => {
                this.endpoint.redirect_to(d.url);
            });
            return false;
        } else {
            this.pageService.sidebar_3((data) => {
                data.shadow = false;
            });
        }
        return true;
    }
}
