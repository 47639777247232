import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormRenderComponent, form_type } from 'src/app/shared/ui/form_render/form_render.component';
import { TutorsService, TutorsState } from '../tutors.service';
import { ExpedientMetadata, ExpedientService } from 'src/app/services/expedient.service';
import { CatalogsService } from 'src/app/services/catalogs.service';
import { PatientService } from 'src/app/services/patient-service.service';
import Swal from "sweetalert2";
import { NewPatientService } from 'src/app/services/new_patient.service';

@Component({
	selector: 'app-module-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy, AfterViewInit {
	private expedient_subscription: Subscription;
	public expedient_state: ExpedientMetadata;

	private tutors_subscription: Subscription;
	public tutors_state: TutorsState;

	@ViewChild('form_ref', { static: false }) form_ref: FormRenderComponent;
	public form_config: form_type;

	public loading: boolean;
	public tutors_timeout: any;
	public search_word: string;
	public view: number = 1
	public current_date = new Date();

	constructor(private moduleService: TutorsService, private expedientService: ExpedientService, private catalogsService: CatalogsService, private newPatientService: NewPatientService, private patientService: PatientService) {
	}

	ngOnInit() {
		this.expedient_subscription = this.expedientService.current.subscribe(
			state => {
				this.expedient_state = state;
				// this.moduleService.reload();
				this.form_configs();
			}
		);
		this.tutors_subscription = this.moduleService.current.subscribe(
			state => this.tutors_state = state
		);
		this.form_configs();
	}

	ngAfterViewInit() {
		if (this.tutors_state) {
			this.moduleService.forms.tutors = this.form_ref;
		}
	}

	ngOnDestroy() {
		this.expedient_subscription.unsubscribe();
		this.tutors_subscription.unsubscribe();
	}

	form_configs() {
		this.form_config = {
			pretty_view: () => this.view === 4,
			disabled: () => this.view === 4,
			submit: (callback) => this.moduleService.submit(callback),
			rows: [
				{
					class: "row",
					cols: [
						{
							name: "phone",
							type: "phone",
							hn: () => true,
							label: "Ingrese número de teléfono del tutor:",
							placeholder: () => "####-####",
							col_class: "col-md-12",
							validators: {
								required: {
									value: () => true
								},
							},
							disabled: () => false,
							show: () => this.view === 1,
						},
						{
							name: "firstname",
							type: "name",
							label: "Nombres",
							small: "(primero, segundo, ...)",
							placeholder: () => "Primer, Segundo... Nombre",
							col_class: "col-md-6",
							class: "unstyle no-border",
							validators: {
								required: {
									value: () => false
								}
							},
							show: () => this.view === 3,
							disabled: () => true,
						},
						{
							name: "lastname",
							type: "name",
							label: "Apellidos",
							small: "(primero, segundo, ...)",
							placeholder: () => "Primer, Segundo... Apellido",
							col_class: "col-md-6",
							class: "unstyle no-border",
							validators: {
								required: {
									value: () => false
								}
							},
							show: () => this.view === 3,
							disabled: () => true,
						},
						{
							name: "email",
							type: "email",
							label: "Correo Electrónico",
							placeholder: () => "alguien@email.com",
							col_class: "col-md-6",
							class: "unstyle no-border",
							validators: {
								required: {
									value: () => true
								},
							},
							show: () => this.view === 3,
							disabled: () => true,
						},
						{
							name: "identification",
							type: "identification",
							label: "Identificación",
							hn: () => this.tutors_state.id_country == 97,
							placeholder: () => "####-####-#####",
							col_class: "col-md-6",
							class: "unstyle no-border",
							validators: {
								required: {
									value: () => false
								},
							},
							show: () => this.view === 3,
							disabled: () => true,
						},
						{
							name: "id_relationship",
							type: "select",
							label: "Relación",
							placeholder: () => "- Seleccione -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							catalog: {
								list: () => this.catalogsService.catalogs.relationships,
								text: "name",
								value: "id"
							},
							show: () => this.view === 3,
							disabled: () => false,
						},

						{
							name: "firstname",
							type: "name",
							label: "Nombres",
							small: "(primero, segundo, ...)",
							placeholder: () => "Primer, Segundo... Nombre",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true,
								},
								maxlength: {
									value: () => 100,
								},
							},
							disabled: () => false,
							change: () => {
								this.tutors_state.firstname = this.tutors_state.firstname.trim();
							},
							show: () => this.view === 2,
							input: () => (this.tutors_state.firstname = this.tutors_state.firstname.trim()),
						},
						{
							name: "lastname",
							type: "name",
							label: "Apellidos",
							small: "(primero, segundo, ...)",
							placeholder: () => "Primer, Segundo... Apellido",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true,
								},
								maxlength: {
									value: () => 100,
								},
							},
							disabled: () => false,
							change: () => {
								this.tutors_state.lastname = this.tutors_state.lastname.trim();
							},
							show: () => this.view === 2,
							input: () => (this.tutors_state.lastname = this.tutors_state.lastname.trim()),
						},

						{
							name: "birthdate",
							type: "calendar",
							label: "Fecha de Nacimiento",
							placeholder: () => "##/##/####",
							col_class: "col-md-4",
							validators: {
								required: {
									value: () => true,
								},
								max: {
									value: () => this.current_date,
								},
							},
							show: () => this.view === 2,
							disabled: () => false,
						},
						{
							name: "identification",
							type: "identification",
							label: "Identificación",
							hn: () => this.tutors_state.id_country == 97,
							placeholder: () => "####-####-#####",
							col_class: "col-md-4",
							validators: {
								required: {
									value: () => true,
								},
							},
							show: () => this.view === 2,
							disabled: () => false,
						},
						{
							name: "id_country",
							type: "select",
							label: "Nacionalidad",
							placeholder: () => "- Seleccione -",
							col_class: "col-md-4",
							validators: {
								required: {
									value: () => true,
								},
							},
							catalog: {
								list: () => this.catalogsService.catalogs.countries,
								value: "id",
								text: "name",
							},
							show: () => this.view === 2,
							disabled: () => false,
						},
						{
							name: "id_relationship",
							type: "select",
							label: "Relación",
							placeholder: () => "- Seleccione -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							catalog: {
								list: () => this.catalogsService.catalogs.relationships,
								text: "name",
								value: "id"
							},
							show: () => this.view === 2,
							disabled: () => false,
						},
						{
							name: "phone",
							type: "phone",
							hn: () => this.tutors_state.id_country == "97",
							label: "Teléfono",
							small: "",
							placeholder: () => "#########",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true,
								},
								maxlength: {
									value: () => 30,
								},
							},
							show: () => this.view === 2,
							disabled: () => false,
						},
						{
							name: "email",
							type: "email",
							label: "Correo Electrónico",
							small: "",
							placeholder: () => "alguien@email.com",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true,
								},
							},
							show: () => this.view === 2,
							disabled: () => false,
						},
						{
							name: "id_gender",
							type: "radio",
							label: "Género",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true,
								},
							},
							catalog: {
								list: () => this.catalogsService.catalogs.genders,
								value: "id",
								text: "name",
							},
							disabled: () => false,
							show: () => this.view === 2,
							change: () => {
								this.moduleService.save();
							},
						},

					]
				},
				{
					class: "row",
					cols: [
						{
							type: "buttons",
							col_class: "col-12 mt-2",
							buttons: [
								{
									class: "btn btn-sm btn-outline-danger",
									label: "Cancelar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									show: () => {
										return this.expedient_state.method == "update" || this.expedient_state.method == "insert";
									},
									click: () => {
										this.moduleService.cancel();
									},
								},
								{
									class: "btn btn-sm btn-outline-danger",
									label: "Cerrar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									show: () => {
										return this.expedient_state.method == "show";
									},
									click: () => {
										this.moduleService.close();
									},
								},
								{
									class: "btn btn-sm btn-warning",
									label: "Forzar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									click: () => {
										this.moduleService.submit();
									},
								},
								{
									class: "btn btn-sm btn-success ml-auto",
									label: "Verificar via whatsapp",
									type: "button",
									icon: {
										class: "fa-duotone fa-check",
										position: 'right',
									},
									click: () => {
										// this.patientService
										let load = {};
										if (!this.expedientService.state.id_user) {
											load = {
												phone: this.tutors_state.phone,
												tutor: this.tutors_state.firstname + " " + this.tutors_state.lastname,
												tutor_gender: this.tutors_state.id_gender,
												patient: this.newPatientService.current_state.general.firstname + " " + this.newPatientService.current_state.general.lastname,
												relationship: this.catalogsService.catalogs.relationships.find(v => v.id == this.tutors_state.id_relationship).name,
											};
										} else {
											load = {
												phone: this.tutors_state.phone,
												tutor: this.tutors_state.firstname + " " + this.tutors_state.lastname,
												tutor_gender: this.tutors_state.id_gender,
												patient: this.patientService.patient_data.general.firstname + " " + this.patientService.patient_data.general.lastname,
												relationship: this.catalogsService.catalogs.relationships.find(v => v.id == this.tutors_state.id_relationship).name,
											};
										}

										this.moduleService.verify_whatsapp(load);
									},
									show: () => this.view === 3,
									disabled: () => !this.tutors_state.id_relationship || this.tutors_state.loading,
								},
								{
									class: "btn btn-sm btn-success ml-auto",
									label: "Verificar via whatsapp",
									type: "button",
									icon: {
										class: "fa-duotone fa-check",
										position: 'right',
									},
									click: () => {
										let load = {};
										if (!this.expedientService.state.id_user) {
											load = {
												phone: this.tutors_state.phone,
												tutor: this.tutors_state.firstname + " " + this.tutors_state.lastname,
												tutor_gender: this.tutors_state.id_gender,
												patient: this.newPatientService.current_state.general.firstname + " " + this.newPatientService.current_state.general.lastname,
												relationship: this.catalogsService.catalogs.relationships.find(v => v.id == this.tutors_state.id_relationship).name,
											};
										} else {
											load = {
												phone: this.tutors_state.phone,
												tutor: this.tutors_state.firstname + " " + this.tutors_state.lastname,
												tutor_gender: this.tutors_state.id_gender,
												patient: this.patientService.patient_data.general.firstname + " " + this.patientService.patient_data.general.lastname,
												relationship: this.catalogsService.catalogs.relationships.find(v => v.id == this.tutors_state.id_relationship).name,
											};
										}

										this.moduleService.verify_whatsapp(load);
									},
									show: () => this.view === 2,
									disabled: () => !this.tutors_state.id_relationship || this.tutors_state.loading,
								},
								{
									class: "btn btn-sm btn-success ml-auto",
									label: "Aceptar",
									type: "button",
									icon: {
										class: "fa-duotone fa-check",
										position: 'right',
									},
									click: () => {
										this.moduleService.validate_tutor_phone(this.tutors_state.phone, (data) => {
											if (data) {
												this.tutors_state.id_user = data.id_user;
												this.tutors_state.firstname = data.firstname;
												this.tutors_state.lastname = data.lastname;
												this.tutors_state.identification = data.identification;
												this.tutors_state.id_gender = data.id_gender;
												this.tutors_state.email = data.email;
												this.view = 3;
											} else {
												this.view = 2;
											}
										});
									},
									show: () => this.view === 1,
									disabled: () => !this.tutors_state.phone || this.tutors_state.loading,
								},
							]
						},
					],
				},
			]
		};
	}
}