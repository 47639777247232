import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AccountService } from '../services/account.service';
import { AppService } from '../services/app.service';
import { PageService } from '../services/page.service';

@Injectable({
	providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {

	constructor(private pageService: PageService, private endpoint: AppService, private accountService: AccountService) { }

	canActivate() {
		if (this.endpoint.get_session() && this.endpoint.get_session().token) {
			this.endpoint.redirect_to('');
		} else {
			this.accountService.reset();
			return true;
		}
	}

}
