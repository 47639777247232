import { ConsultGeneralService } from "src/app/pages/expedient/new_consult/general/consult.service";
import { PatientService } from "src/app/services/patient-service.service";
import { PageService } from "../../services/page.service";
import * as i0 from "@angular/core";
import * as i1 from "../../services/page.service";
import * as i2 from "../../pages/expedient/new_consult/general/consult.service";
import * as i3 from "../../services/patient-service.service";
export class InConsultGuard {
    constructor(pageService, consultService, patientService) {
        this.pageService = pageService;
        this.consultService = consultService;
        this.patientService = patientService;
    }
    canActivate() {
        localStorage.setItem("inConsult", "true");
        this.pageService.set_consult(false);
        this.pageService.set_profile(false);
        this.pageService.set_expedient(true);
        this.pageService.sidebar_3((data) => {
            data.background = false;
            data.shadow = true;
        });
        this.consultService.load_storage();
        return true;
    }
}
InConsultGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InConsultGuard_Factory() { return new InConsultGuard(i0.ɵɵinject(i1.PageService), i0.ɵɵinject(i2.ConsultGeneralService), i0.ɵɵinject(i3.PatientService)); }, token: InConsultGuard, providedIn: "root" });
