import { Component } from '@angular/core';
import { ExpedientService } from 'src/app/services/expedient.service';
import { DependantsService } from '../dependants.service';

@Component({
  selector: 'app-relations_create_dependants',
  templateUrl: './create_dependants.component.html',
  styleUrls: ['./create_dependants.component.scss']
})
export class CreateRelationsDependantsComponent {

  constructor(private moduleService: DependantsService, private expedientService: ExpedientService) {
    this.expedientService.change_method("insert");
    this.moduleService.init();
  }

  close() {
    this.moduleService.cancel();
  }

}
