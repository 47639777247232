import { Chart } from 'chart.js';
import { PipesService } from './pipes.service';
import * as i0 from "@angular/core";
import * as i1 from "./pipes.service";
export class ChartjsService {
    constructor(pipesService) {
        this.pipesService = pipesService;
        // Chart.register(...registerables);
    }
    /**
    Genera un gráfico utilizando la librería Chart.js.
    @param ctx Referencia del elemento canvas donde se dibujará el gráfico.
    @param chartData Arreglo con los datos a mostrar en el gráfico.
    @param chartLabels Arreglo con las etiquetas para cada punto de datos.
    @param chartType Tipo de gráfico a generar. Los tipos soportados son 'bar', 'line' y 'pie'.
    @param options Objeto con opciones adicionales para personalizar el gráfico.
    @returns Referencia al gráfico generado.
  */
    generateChart(ctx, datasets, chartLabels, chartType, //keyof ChartTypeRegistry,
    options) {
        const chart = new Chart(ctx, {
            type: chartType,
            data: {
                labels: chartLabels,
                datasets: datasets,
            },
            options: options,
        });
        return chart;
    }
    /**
    Genera un gráfico de barras utilizando la librería Chart.js.
    @param ctx Referencia del elemento canvas donde se dibujará el gráfico.
    @param chartData Arreglo con los datos a mostrar en el gráfico.
    @param chartLabels Arreglo con las etiquetas para cada punto de datos.
    @returns Referencia al gráfico generado.
  */
    generateBarChart(ctx, datasets, chartLabels) {
        // const options = {
        //   scales: {
        //     y: {
        //       beginAtZero: true,
        //     },
        //   },
        // };
        return this.generateChart(ctx, datasets, chartLabels, 'bar', null);
    }
    /**
    Genera un gráfico de líneas utilizando la librería Chart.js.
    @param ctx Referencia del elemento canvas donde se dibujará el gráfico.
    @param chartData Arreglo con los datos a mostrar en el gráfico.
    @param chartLabels Arreglo con las etiquetas para cada punto de datos.
    @returns Referencia al gráfico generado.
  */
    generateLineChart(ctx, datasets, chartLabels, _options) {
        let options;
        if (_options) {
            options = _options;
        }
        else {
            options = {
                responsive: true,
                fill: true,
                elements: {
                    line: {
                        tension: 0,
                    },
                },
            };
        }
        return this.generateChart(ctx, datasets, chartLabels, 'line', options);
    }
    /**
    Genera un gráfico de pastel utilizando la librería Chart.js.
    @param ctx Referencia del elemento canvas donde se dibujará el gráfico.
    @param chartData Arreglo con los datos a mostrar en el gráfico.
    @param chartLabels Arreglo con las etiquetas para cada punto de datos.
    @returns Referencia al gráfico generado.
  */
    generatePieChart(ctx, datasets, chartLabels) {
        // const options = {
        //   legend: {
        //     position: 'right',
        //   },
        // };
        return this.generateChart(ctx, datasets, chartLabels, 'pie', null);
    }
    updateDataset(chart, datasetIndex, label, data) {
        chart.data.datasets[datasetIndex].label = label;
        chart.data.datasets[datasetIndex].data = data;
        chart.update();
    }
    addDataset(chart, dataset) {
        chart.data.datasets.push(dataset);
        chart.update();
    }
    removeDataset(chart, datasetIndex) {
        chart.data.datasets.splice(datasetIndex, 1);
        chart.update();
    }
}
ChartjsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChartjsService_Factory() { return new ChartjsService(i0.ɵɵinject(i1.PipesService)); }, token: ChartjsService, providedIn: "root" });
