import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.scss']
})
export class PasswordresetComponent implements OnInit, AfterViewInit {

  release_year: number = 2020;
	year: number = new Date().getFullYear();
  recoverForm: FormGroup;
  submitted = false;
  error = '';
  success = '';
  loading = false;

  constructor(private formBuilder: FormBuilder, private endpoint: AppService) {
    this.endpoint.pageTitle = 'Recuperar Contraseña';
  }

  ngOnInit() {

    this.recoverForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
  }

  get f() { return this.recoverForm.controls; }

  onSubmit() {
    this.success = '';
    this.submitted = true;

    if (this.recoverForm.invalid) {
      return;
    }
    var params = {
      email: this.f.email.value,
      host: "restaurar_contrasena/"
    };

    this.loading = true;
    var response;
    this.endpoint.recover_password(params)
      .subscribe(
        data => response = data,
        err => {
          this.error = err.error.error;
          this.loading = false;
          Swal.fire({
            title: err.error.title,
            html: err.error.message,
            type: 'error',
            confirmButtonText: 'Listo',
            confirmButtonColor: '#66c8c8',
          }).then((result) => {
          });
        },
        () => {
          this.loading = false;
          Swal.fire({
            title: response.title,
            html: response.message,
            type: 'info',
            confirmButtonText: 'Listo',
            confirmButtonColor: '#66c8c8',
          }).then((result) => {
          });
          this.endpoint.redirect_to('/conectarse');
        }
      )
  };
}

