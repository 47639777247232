import { CatalogsService } from "src/app/services/catalogs.service";
import { CustomListClass } from "./custom-list-class";
import { TreatmentInterface, TreatmentClass } from "./treatment-class";
import { PrescriptionInterface, PrescriptionClass } from "./prescription-class";
import { CustomClass } from "./custom-class";

export interface RawDiagnosisInterface {
    id?: any;
    id_consult?: any;
    id_patient?: any;
    name: string;
    icd: string;
    date: Date;
    secure: boolean;
    treatment: string;
    chronic: any;

    prescription_list: PrescriptionInterface[];
    treatment_list: TreatmentInterface[];
}

export interface DiagnosisInterface {
    id?: any;
    id_consult?: any;
    id_patient?: any;
    name: string;
    icd: string;
    date: Date;
    secure: boolean;
    treatment: string;
    chronic: any;

    prescription_list: CustomListClass<PrescriptionClass>;
    treatment_list: CustomListClass<TreatmentClass>;
}

interface ToStringInterface {
    name: string;
    icd: string;
    date: Date;
    secure: boolean;
    treatment: string;
    chronic: any;
    prescription_list: string[];
    treatment_list: string[];
}

export class DiagnosisClass extends CustomClass implements DiagnosisInterface {
    public id: any;
    public id_consult: any;
    public id_patient: any;
    public name: string;
    public icd: string;
    public date: Date;
    public secure: boolean;
    public treatment: string;
    public chronic: any;

    public prescription_list: CustomListClass<PrescriptionClass>;
    public treatment_list: CustomListClass<TreatmentClass>;

    constructor(private catalogsService: CatalogsService, data?: RawDiagnosisInterface) {
        super();
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: RawDiagnosisInterface): void {
        super.overwrite(data);
        this.id = data.id;
        this.id_consult = data.id_consult;
        this.id_patient = data.id_patient;
        this.name = data.name;
        this.icd = data.icd;
        this.date = data.date;
        this.secure = data.secure;
        this.treatment = data.treatment;
        this.chronic = data.chronic == 1 ? [1] : [];
        if (data.prescription_list)
            this.prescription_list = new CustomListClass<PrescriptionClass>(data.prescription_list.map((prescription) => new PrescriptionClass(this.catalogsService, prescription)));
        else this.prescription_list = new CustomListClass<PrescriptionClass>();
        if (data.treatment_list) this.treatment_list = new CustomListClass<TreatmentClass>(data.treatment_list.map((treatment) => new TreatmentClass(this.catalogsService, treatment)));
        else this.treatment_list = new CustomListClass<TreatmentClass>();
    }

    reset(): void {
        super.reset();
        this.id = null;
        this.id_consult = null;
        this.id_patient = null;
        this.name = "";
        this.icd = "";
        this.date = new Date();
        this.secure = true;
        this.treatment = "";
        this.chronic = [];
        this.prescription_list = new CustomListClass<PrescriptionClass>();
        this.treatment_list = new CustomListClass<TreatmentClass>();
    }

    format(): DiagnosisInterface {
        return this as DiagnosisInterface;
    }

    valid(): boolean {
        return true;
    }

    to_string(): ToStringInterface {
        let data = {} as ToStringInterface;

        return data as ToStringInterface;
    }

    raw(): RawDiagnosisInterface {
        return {
            id: this.id,
            id_consult: this.id_consult,
            id_patient: this.id_patient,
            name: this.name,
            icd: this.icd,
            date: this.date,
            secure: this.secure,
            treatment: this.treatment,
            chronic: this.chronic,
            prescription_list: this.prescription_list.raw(),
            treatment_list: this.treatment_list.raw(),
        };
    }
}
