import * as tslib_1 from "tslib";
import { NgxIndexedDBService } from 'ngx-indexed-db';
import * as i0 from "@angular/core";
import * as i1 from "ngx-indexed-db";
;
export class WebDBService {
    constructor(dbService) {
        this.dbService = dbService;
        this.add = (table, payload) => this.dbService.add(table, payload);
        this.bulkAdd = (table, payload) => this.dbService.bulkAdd(table, payload);
        this.update = (table, payload) => this.dbService.update(table, payload);
        this.getAll = (table, ids) => !ids ? this.dbService.getAll(table) : this.dbService.bulkGet(table, ids);
        this.get = (table, id) => this.dbService.getByKey(table, id);
        this.getByIndex = (table, indexName, key) => this.dbService.getByIndex(table, indexName, key);
        this.delete = (table, id) => this.dbService.delete(table, id);
        this.bulkDelete = (table, ids) => this.dbService.bulkDelete(table, ids);
    }
    overwrite(json, callback) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (const table in json) {
                yield new Promise((resolve) => {
                    this.dbService.clear(table).subscribe(() => resolve(true));
                });
            }
            for (const table in json) {
                for (let index = 0; index < json[table].length; index++) {
                    const obj = json[table][index];
                    yield new Promise((resolve) => {
                        this.add(table, obj).subscribe(() => resolve(true));
                    });
                }
            }
            callback();
        });
    }
    /**
     * Selects items from a list that match certain criteria
     * @param {Array<any>} list - List to filter
     * @param {Object} columns - Object containing the search criteria
     * @param {Function} [callback] - Optional callback function to be called with the filtered result
     * @returns {Array<any>} - Filtered list
     *
     * @example
     *
     * const list = [
     *   {name: 'John', age: 20, date: '2022-01-01'},
     *   {name: 'Mike', age: 25, date: '2022-02-01'},
     *   {name: 'Jane', age: 30, date: '2022-03-01'},
     * ]
     *
     * const searchCriteria = {
     *   name: 'John',
     *   age: {
     *     logic: 'or',
     *     values: [20, 25]
     *   },
     *   date: {
     *     key: 'date',
     *     logic: 'or',
     *     from: '2022-02-01',
     *     to: '2022-03-01'
     *   }
     * }
     *
     * const result = selectWhereLike(list, searchCriteria)
     * // result will be [{name: 'John', age: 20, date: '2022-01-01'}, {name: 'Mike', age: 25, date: '2022-02-01'}]
     *
     */
    selectWhereLike(list, columns, callback) {
        const result = list.filter((item) => {
            let match = true;
            for (const key in columns) {
                if (key === 'date') {
                    const dateColumns = Array.isArray(columns[key]) ? columns[key] : [columns[key]];
                    for (const dateColumn of dateColumns) {
                        const date = new Date(item[dateColumn.key]);
                        if (dateColumn.logic === 'or') {
                            if ((dateColumn.from && date < dateColumn.from) || (dateColumn.to && date > dateColumn.to)) {
                                match = false;
                                break;
                            }
                        }
                        else {
                            if ((dateColumn.from && date < dateColumn.from) && (dateColumn.to && date > dateColumn.to)) {
                                match = false;
                                break;
                            }
                        }
                    }
                }
                else {
                    let columnMatch = false;
                    const columnValues = Array.isArray(columns[key]) ? columns[key] : [columns[key]];
                    for (const columnValue of columnValues) {
                        const regex = new RegExp(columnValue
                            .replace(/á|ä/g, "a")
                            .replace(/é|ë/g, "e")
                            .replace(/í|ï/g, "i")
                            .replace(/ó|ö/g, "o")
                            .replace(/ú|ü/g, "u"), 'i');
                        if (regex.test(item[key]
                            .replace(/á|ä/g, "a")
                            .replace(/é|ë/g, "e")
                            .replace(/í|ï/g, "i")
                            .replace(/ó|ö/g, "o")
                            .replace(/ú|ü/g, "u"))) {
                            columnMatch = true;
                            break;
                        }
                    }
                    if (!columnMatch) {
                        match = false;
                        break;
                    }
                }
            }
            return match;
        });
        if (callback)
            callback(result);
        return result;
    }
    /**
   * sort
   * @param list any[] - Lista de elementos a ordenar
   * @param filter {sort_by?: string, sort_asc?: boolean} - Filtro de ordenamiento
   * @return any[] - Lista de elementos ordenada
   *
   * Esta función permite ordenar una lista de elementos a partir de un criterio específico.
   * El parametro "sort_by" especifica el nombre de la columna por la cual se desea ordenar,
   * y el parametro "sort_asc" especifica si el orden es ascendente (true) o descendente (false).
   */
    sort(list, filter) {
        if (filter.sort_by) {
            list.sort((a, b) => {
                const valueA = a[filter.sort_by];
                const valueB = b[filter.sort_by];
                if (valueA < valueB) {
                    return filter.sort_asc ? -1 : 1;
                }
                if (valueA > valueB) {
                    return filter.sort_asc ? 1 : -1;
                }
                return 0;
            });
        }
        return list;
    }
    sumTotalByDay(arr, sumAttribute, dateAttribute) {
        const totalsByDay = new Map();
        arr.forEach(obj => {
            let date = new Date(obj[dateAttribute]);
            date.setHours(0, 0, 0, 0);
            const dateKey = +date + "";
            if (!totalsByDay.has(dateKey)) {
                totalsByDay.set(dateKey, obj[sumAttribute]);
            }
            else {
                totalsByDay.set(dateKey, totalsByDay.get(dateKey) + obj[sumAttribute]);
            }
        });
        return Array.from(totalsByDay).map(([date, value]) => ({
            date,
            value
        }));
    }
    sumTotalByMonth(arr, sumAttribute, dateAttribute) {
        const totalsByMonth = new Map();
        arr.forEach(obj => {
            let date = new Date(obj[dateAttribute]);
            date.setDate(1);
            date.setHours(0, 0, 0, 0);
            const dateKey = +date + "";
            if (!totalsByMonth.has(dateKey)) {
                totalsByMonth.set(dateKey, obj[sumAttribute]);
            }
            else {
                totalsByMonth.set(dateKey, totalsByMonth.get(dateKey) + obj[sumAttribute]);
            }
        });
        return Array.from(totalsByMonth).map(([date, value]) => ({
            date,
            value
        }));
    }
}
WebDBService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WebDBService_Factory() { return new WebDBService(i0.ɵɵinject(i1.NgxIndexedDBService)); }, token: WebDBService, providedIn: "root" });
