import { AppWebDBModule } from './app-webdb.module';
import { ProfileService } from "./pages/profile/profile.service";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { environment } from "src/environments/environment";
import { ClickOutsideModule } from "ng-click-outside";

import { UIModule } from "./shared/ui/ui.module";
import { LayoutComponent } from "./layouts/layout.component";
import { SidebarComponent } from "./layouts/sidebar/sidebar.component";
import { TopbarComponent } from "./layouts/topbar/topbar.component";
import { FooterComponent } from "./layouts/footer/footer.component";
import { RightsidebarComponent } from "./layouts/rightsidebar/rightsidebar.component";
import { SecondSidebarComponent } from "./layouts/second-sidebar/second-sidebar.component";
import { ThirdSidebarComponent } from "./layouts/third-sidebar/third-sidebar.component";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { SearchBarComponent } from "./layouts/search_bar/search_bar.component";

import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FileUploadModule } from "@iplab/ngx-file-upload";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import { MyPatientsComponent } from "./pages/my_patients/my_patients.component";
import { PatientService } from "./services/patient-service.service";
import { PageService } from "./services/page.service";

import { AuthInterceptor } from "./app.httpinterceptor";
import { SharedModule } from "./shared/shared.module";
import { FormRenderModule } from "./shared/ui/form_render/form_render.module";
import { DatatableModule } from "./shared/ui/datatable/datatable.module";
import { BriefComponent } from "./pages/components/brief/brief.component";
import { MyPatientsGuard } from "./guards/my-patients.guard";
import { NewPatientGuard } from "./guards/new_patient/new-patient.guard";
import { PatientInternalGuard } from "./guards/expedient/patient-internal.guard";
import { PipesModule } from "./shared/ui/pipes/pipes.module";
import { AllergiesBriefComponent } from "./pages/components/brief/allergies_brief/allergies_brief.component";
import { PathologiesBriefComponent } from "./pages/components/brief/pathologies_brief/pathologies_brief.component";
import { ProfileBriefComponent } from "./pages/components/profile_brief/profile_brief.component";
import { AlertService } from "./shared/ui/alert_service/alert.service";
import { HomeComponent } from "./pages/home/home.component";
import { BoldListModule } from "./shared/ui/bold_list/bold_list.module";
import { NoAuthGuard } from "./guards/no-auth.guard";
import { FirstLoginGuard } from "./guards/first-login.guard";
import { LoginComponent } from "./pages/login/login.component";
import { FirstLoginComponent } from "./pages/first_login/first_login.component";
import { ResetPasswordComponent } from "./pages/reset_password/reset_password.component";
import { PasswordresetComponent } from "./pages/passwordreset/passwordreset.component";
import { Steps2Component } from "./pages/steps_2/steps_2.component";
import { SearchComponent } from "./pages/search/search.component";
import { ContactDoctorBriefComponent } from "./pages/components/brief/contact_doctor_brief/contact_doctor_brief.component";
import { ContactEmergencyBriefComponent } from "./pages/components/brief/contact_emergency_brief/contact_emergency_brief.component";
import { ScheduleComponent } from "./pages/expedient/schedule/schedule.component";
import { SheduleDateComponent } from "./pages/expedient/schedule/shedule-date/shedule-date.component";
import { SheduleModalDateComponent } from "./pages/expedient/schedule/shedule-modal-date/shedule-modal-date.component";

import { GenericExpedientModule } from "./pages/generic-expedient/generic-expedient.module";
import { OutConsultGuard } from "./guards/consult/outconsult.guard";
import { InConsultGuard } from "./guards/consult/inconsult.guard";
import { ConsultsMedicalComponent } from "./pages/medical_file/consults-medical/consults-medical.component";
import { ShowConsultMedicalComponent } from "./pages/medical_file/consults-medical/show-consult/show-consult.component";
import { OutNewPatientGuard } from "./guards/new_patient/out-new-patient.guard";
import { OutExpedientGuard } from "./guards/expedient/outexpedient.guard";
import { AppService } from "./services/app.service";
import { AccountService } from "./services/account.service";
import { ExpedientService } from "./services/expedient.service";
import { ConsultGeneralService } from "./pages/expedient/new_consult/general/consult.service";
import { PdfService } from "./services/pdf.service";
import { WebDBService } from "./services/webdb.service";
import { CatalogsService } from "./services/catalogs.service";
import { PipesService } from "./services/pipes.service";
import { SettingsPageComponent } from './pages/settings/settings.component';
import { GrowthCurvesBriefComponent } from './pages/components/growth_curves/growth_curves.component';

const config: SocketIoConfig = {
  url: environment.doctor_server,
  options: {
    transports: environment.socket.transport,
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    SidebarComponent,
    TopbarComponent,
    FooterComponent,
    SearchBarComponent,
    RightsidebarComponent,
    SecondSidebarComponent,
    ThirdSidebarComponent,

    LoginComponent,
    FirstLoginComponent,
    ResetPasswordComponent,
    PasswordresetComponent,
    Steps2Component,

    HomeComponent,
    MyPatientsComponent,
    BriefComponent,
    GrowthCurvesBriefComponent,
    AllergiesBriefComponent,
    PathologiesBriefComponent,
    ContactDoctorBriefComponent,
    ContactEmergencyBriefComponent,

    // Profile
    ConsultsMedicalComponent,
    ShowConsultMedicalComponent,
    ProfileBriefComponent,
    

    SearchComponent,

    ScheduleComponent,
    SheduleDateComponent,
    SheduleModalDateComponent,
  ],
  imports: [
    AppWebDBModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ClickOutsideModule,
    UIModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-left",
      preventDuplicates: true,
    }),
    FileUploadModule,
    SocketIoModule.forRoot(config),

    AppRoutingModule,
    FormRenderModule,
    DatatableModule,
    BoldListModule,
    SharedModule,
    PipesModule,

    GenericExpedientModule,
  ],
  providers: [
    // Guards
    NoAuthGuard,
    FirstLoginGuard,
    MyPatientsGuard,
    NewPatientGuard,
    OutNewPatientGuard,
    InConsultGuard,
    OutConsultGuard,
    OutExpedientGuard,
    PatientInternalGuard,

    // Services
    AppService,
    CatalogsService,
    WebDBService,
    AlertService,
    PatientService,
    PageService,
    AccountService,
    ExpedientService,
    ConsultGeneralService,
    PdfService,
    PipesService,
    ProfileService,

    // Interceptors
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
