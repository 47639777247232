import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { NewPatientState } from 'src/app/services/new_patient.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { FormRenderComponent, form_type } from 'src/app/shared/ui/form_render/form_render.component';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-update-date',
  templateUrl: './update-date.component.html',
  styleUrls: ['./update-date.component.scss']
})
export class UpdateDateComponent implements OnInit {

  public new_patient_state: NewPatientState;


  @Input() readonly: boolean = false;
  @Input() data = {
    description: "",
    fecha_date: "",
    hour_date: "",
    duration: "",
    id_date: "",
  };

  @ViewChild('form_ref', { static: false }) form_ref: FormRenderComponent;
  public form_config: form_type;

  constructor(private endpoint: AppService, private toastr: ToastrService, private pageService: PageService, private patientServiceService: PatientService,
    private accountService: AccountService) { }

  ngOnInit() {
    this.form_configs();
  }

  form_configs() {
    this.form_config = {
      submit: () => this.submit(),
      rows: [
        {
          class: "row",
          cols: [
            {
              name: "description",
              type: "textarea",
              label: "Descripción",
              placeholder: () => "",
              col_class: "col-md-12",
              rows: 2.5,
              validators: {
                required: {
                  value: () => true
                },
                maxlength: {
                  value: () => 100
                }
              },
              disabled: () => false,
            },
            {
              name: "fecha_date",
              type: "calendar",
              label: "Fecha",
              placeholder: () => "#######",
              col_class: "col-md-6",
              validators: {
                required: {
                  value: () => true
                },
              },
              disabled: () => false,
            },
            {
              name: "hour_date",
              type: "text",
              label: "Hora",
              placeholder: () => "",
              col_class: "col-md-6",
              validators: {
                required: {
                  value: () => true
                },
                maxlength: {
                  value: () => 500
                }
              },
              disabled: () => false,
            },
            {
              name: "duration",
              type: "text",
              label: "duración",
              placeholder: () => "",
              col_class: "col-md-6",
              validators: {
                required: {
                  value: () => true
                },
                maxlength: {
                  value: () => 500
                }
              },
              disabled: () => false,
            },

          ],
        },
        {
          class: "row",
          cols: [
            {
              type: "buttons",
              col_class: "col-12",
              buttons: [
                {
                  class: "btn btn-sm btn-danger",
                  label: "Cancelar",
                  type: "button",
                  icon: {
                    class: "fa-duotone fa-times",
                    position: 'right',
                  },
                  disabled: () => false,
                  click: () => this.close()
                },
                {
                  class: "btn btn-sm btn-success ml-auto",
                  label: "Agregar Cita",
                  type: "submit",
                  icon: {
                    class: "fa-duotone fa-check",
                    position: 'right',
                  },
                  disabled: () => this.form_ref ? !this.form_ref.valid() : false,
                },
              ]
            },
          ],
        },
      ]
    };
  }

  submit() {
    this.endpoint.update_dates(this.data).subscribe(
      data => {
        this.toastr.success(data["message"], data["title"]);
        this.close();
      },
      error => {
        this.toastr.error(
          error.error.message ? error.error.message : error.message,
          error.error.title ? error.error.title : error.status + ''
        );
      }
    );
  }

  close() {
    this.pageService.closeModal("");
  }


}
