import { NgModule } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

import { PipesModule } from 'src/app/shared/ui/pipes/pipes.module';
import { FormRenderModule } from 'src/app/shared/ui/form_render/form_render.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DatatableModule } from 'src/app/shared/ui/datatable/datatable.module';
import { RoutingModule } from './routing.module';

import { ConsultsComponent } from '../expedient/consults/consults.component';
import { NgbAccordionModule, NgbAlertModule, NgbCollapseModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule, NgbModule, NgbPaginationModule, NgbPopoverModule, NgbProgressbarModule, NgbTabsetModule, NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';
import { UIModule } from 'src/app/shared/ui/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ToastrModule } from 'ngx-toastr';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { TextMaskModule } from 'angular2-text-mask';
import { NewConsultModule } from '../expedient/new_consult/new_consult.module';
import { BoldListModule } from '../../shared/ui/bold_list/bold_list.module';
import { ShowConsultComponent } from '../expedient/consults/show_consult/show_consult.component';
import { ListBillingComponent } from '../expedient/list-billing/list-billing.component';
import { GeneralModule } from '../expedient/new_consult/general/general.module';
import { MedicinesComponent } from '../expedient/medicines/medicines.component';
import { CreateMedicineComponent } from '../expedient/medicines/create-medicine/create-medicine.component';
import { ShowMedicineComponent } from '../expedient/medicines/show-medicine/show-medicine.component';
import { UpdateMedicineComponent } from '../expedient/medicines/update-medicine/update-medicine.component';
import { DateComponent } from '../expedient/date/date.component';
import { CreateDateComponent } from '../expedient/date/create-date/create-date.component';
import { UpdateDateComponent } from '../expedient/date/update-date/update-date.component';
import { ShowDateComponent } from '../expedient/date/show-date/show-date.component';
import { ConfigurationsComponent } from '../expedient/configurations/configurations.component';
import { ExpedientGeneralModule } from '../expedient/general/general.module';
import { InsurancesModule } from '../expedient/insurances/insurances.module';
import { BackgroundModule } from '../expedient/background/background.module';
import { ContactsModule } from '../expedient/contacts/contacts.module';
import { RelationsModule } from '../expedient/relations/relations.module';
import { ExamsModule } from '../expedient/exams/exams.module';
import { ExpedientComponent } from '../expedient/expedient/expedient.component';
import { NewPatientComponent } from '../expedient/new_patient/new_patient.component';
import { InstitutionsModule } from '../expedient/institutions/institutions.module';
import { ChatComponent } from '../chat/chat.component';
import { PreviewConsultComponent } from '../expedient/consults/pages/preview_consult/preview_consult.component';
import { PreviewConsultPatientComponent } from '../expedient/consults/pages/preview_consult_patient/preview_consult_patient.component';
import { PreviewPrescriptionComponent } from '../expedient/consults/pages/preview_prescription/preview_prescription.component';


@NgModule({
  declarations: [
    // Expedient
    ExpedientComponent,
    NewPatientComponent,

    ConsultsComponent,

    ShowConsultComponent,
    PreviewConsultComponent,
    PreviewConsultPatientComponent,
    PreviewPrescriptionComponent,

    ListBillingComponent,
    MedicinesComponent,
    CreateMedicineComponent,
    ShowMedicineComponent,
    UpdateMedicineComponent,
    DateComponent,
    CreateDateComponent,
    UpdateDateComponent,
    ShowDateComponent,
    ConfigurationsComponent,
    ChatComponent
  ],
  imports: [
    PipesModule,
    FormRenderModule,
    DatatableModule,
    SharedModule,
    RoutingModule,
    ClickOutsideModule,
    UIModule,
    FormsModule,
    NgbModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
    }),
    FileUploadModule,
    NgbDropdownModule,
    NgbTabsetModule,
    NgbAccordionModule,
    NgbCollapseModule,
    NgbModalModule,
    NgbProgressbarModule,
    NgbAlertModule,
    NgbToastModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbPaginationModule,
    TextMaskModule,

    BoldListModule,
    SharedModule,
    PipesModule,
    NewConsultModule,
    GeneralModule,
    ExpedientGeneralModule,
    InsurancesModule,
    BackgroundModule,
    ContactsModule,
    ExamsModule,
    RelationsModule,
    InstitutionsModule
  ],
  exports: [
    // Expedient
    ExpedientComponent,
    
    ConsultsComponent,
    ShowConsultComponent,
    PreviewConsultComponent,
    PreviewConsultPatientComponent,
    PreviewPrescriptionComponent,

    ListBillingComponent,

    MedicinesComponent,
    CreateMedicineComponent,
    ShowMedicineComponent,
    UpdateMedicineComponent,

    DateComponent,
    CreateDateComponent,
    UpdateDateComponent,
    ShowDateComponent,

    ConfigurationsComponent
  ],
  providers: [
  ]
})
export class GenericExpedientModule { }
