/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./relations.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./tutors/tutors.component.ngfactory";
import * as i4 from "./tutors/tutors.component";
import * as i5 from "../../../services/app.service";
import * as i6 from "../../../services/expedient.service";
import * as i7 from "../../../services/page.service";
import * as i8 from "./tutors/tutors.service";
import * as i9 from "../../../shared/services/sweel.service";
import * as i10 from "ngx-toastr";
import * as i11 from "./dependants/dependants.component.ngfactory";
import * as i12 from "./dependants/dependants.component";
import * as i13 from "./dependants/dependants.service";
import * as i14 from "@angular/common";
import * as i15 from "./relations.component";
var styles_RelationsComponent = [i0.styles];
var RenderType_RelationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RelationsComponent, data: {} });
export { RenderType_RelationsComponent as RenderType_RelationsComponent };
function View_RelationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_RelationsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-expedient-relations-tutors", [], null, [[null, "change_view"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change_view" === en)) {
        var pd_0 = ((_co.view = 1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_RelationsTutorsComponent_0, i3.RenderType_RelationsTutorsComponent)), i1.ɵdid(2, 245760, null, 0, i4.RelationsTutorsComponent, [i5.AppService, i6.ExpedientService, i7.PageService, i8.TutorsService, i9.SweelService, i10.ToastrService], null, { _change_view_event: "change_view" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_RelationsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-expedient-relations-dependants", [], null, [[null, "change_view"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change_view" === en)) {
        var pd_0 = ((_co.view = 0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_RelationsDependantsComponent_0, i11.RenderType_RelationsDependantsComponent)), i1.ɵdid(2, 245760, null, 0, i12.RelationsDependantsComponent, [i5.AppService, i6.ExpedientService, i7.PageService, i13.DependantsService, i9.SweelService, i10.ToastrService], null, { _change_view_event: "change_view" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_RelationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelationsComponent_3)), i1.ɵdid(2, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelationsComponent_4)), i1.ɵdid(4, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.view == 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.view == 1); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_RelationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelationsComponent_1)), i1.ɵdid(1, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RelationsComponent_2)), i1.ɵdid(3, 16384, null, 0, i14.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.expedient_state.version == "expedient"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.expedient_state.version == "new_patient"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_RelationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-expedient-relations", [], null, null, null, View_RelationsComponent_0, RenderType_RelationsComponent)), i1.ɵdid(1, 245760, null, 0, i15.RelationsComponent, [i6.ExpedientService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RelationsComponentNgFactory = i1.ɵccf("app-expedient-relations", i15.RelationsComponent, View_RelationsComponent_Host_0, {}, {}, []);
export { RelationsComponentNgFactory as RelationsComponentNgFactory };
