import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from 'src/app/shared/ui/alert_service/alert.service';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
import Swal from 'sweetalert2';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { AppService } from 'src/app/services/app.service';

export interface NewPatientForms {
  general: FormRenderComponent
};

export interface NewPatientState {
  general: {
    id: any,
    firstname: string,
    lastname: string,
    id_gender: any,
    birthdate: any,
    birthplace: string,
    id_country: any,
    id_department: any,
    id_town: any,
    address: string,
    email: string,
    phone: string,
    tutor_email: string,
    id_education: any,
    identification: any,
    id_blood: any,
    organ_donor: any,
  },
  contacts: {
    emergency: {
      id: string,
      firstname: string,
      lastname: string,
      phone: string,
      email: string,
      id_relationship: string,
    }[],
    doctor: {
      id: string,
      firstname: string,
      lastname: string,
      phone: string,
      email: string,
      id_doctors_specialty: string,
      id_department: string,
      id_town: string,
      address: string
    }[],
  },
  procedures: {
    doctor: {
      id: string,
      observations: string,
      id_procedures_type: string
    }[],
  },
  background: {
    pathology: {
      id: string,
      id_pathology: string,
      pathology_name: string,
      treatment: string,
    }[],
    allergy: {
      id: string,
      allergen: string,
      id_allergies_reactions_level: string,
      id_allergies_reactions_type: string,
    }[],
  },
  insurance: {
    accident: {
      id: string,
      carrier: string,
      number: string,
      description: string,
      roof: string,
      date_init: string,
      date_finish: string,
      phone: string,
      runner_name: string,
      runner_phone: string,
      email: string,
    }[],
  },
  exam: {
    laboratory: {
      id: string,
      id_exams_type: string,
      id_exams_name: string,
      id_exams_name_2: string,
      observations: string,
      realization_date: string
    }[],
  },
  institutions: {
    id: string,
    name: string,
    id_institution: string,
    id_institutions_grade: string,
    id_institutions_grades_section: string,
    id_institutions_role: string,
    id_institutions_area: string,
  }[],
  url: {
    name: string,
    link: string
  },
  loading: boolean,
  submitted: boolean,
  timestamp: Date,
};

@Injectable({ providedIn: 'root' })
export class NewPatientService {
  public current_state: NewPatientState;
  public forms: NewPatientForms;
  private patient_state;
  public current: Observable<any>;
  private forms_state;
  public obv_forms: Observable<any>;
  private store_name = "unimed-new-patient-data";
  public catalogs: {
    genders: {
      id: any,
      name: string,
      active: string
    }[],
    countries: {
      id: any,
      name: string,
      code: string,
      active: string
    }[],
    departments: {
      id: any,
      name: string,
      number: string
    }[],
    towns: {
      id: any,
      name: string,
      id_department: string,
      number: string
    }[],
    exams_types: {
      id: any,
      name: string
    }[],
    exams_names: {
      id: any,
      id_exams_type: string,
      name: string
    }[],
    exams_names_2: {
      id: any,
      id_exams_name: string,
      name: string
    }[],
    educations: {
      id: any,
      name: string,
      active: string
    }[],
    relationships: {
      id: any,
      name: string,
      active: string
    }[],
    doctors_specialties: {
      id: any,
      name: string,
      active: string
    }[],
    procedures_types: {
      id: any,
      name: string,
      active: string
    }[],
    allergies_reactions_levels: {
      id: any,
      name: string,
      active: string
    }[],
    allergies_reactions_types: {
      id: any,
      name: string,
      active: string
    }[],
    pathologies: {
      id: any,
      name: string,
      active: string
    }[],
    institutions: {
      id: any,
      name: string,
      active: string
    }[],
    institutions_grades: {
      id: any,
      id_institution: any,
      name: string,
      active: string
    }[],
    institutions_grades_sections: {
      id: any,
      id_institutions_grade: any,
      name: string,
      active: string
    }[],
    institutions_roles: {
      id: any,
      name: string,
      active: string
    }[],
    institutions_areas: {
      id: any,
      name: string,
      active: string
    }[],
    diseases: {
      id: number,
      disease_name: string,
    }[],
    bloods: {
      id: any,
      name: string,
    }[],
    tf: {
      id: any,
      name: string,
    }[],
  };
  public loading: boolean;
  public error: boolean;
  public submitted: boolean;

  constructor(private endpoint: AppService, private toastr: ToastrService, private alertService: AlertService, private pageService: PageService, private patientService: PatientService) {
    var outmessage = 'Está creando un paciente, ¿está seguro de salir y cancelar la creación del paciente actual?.';


    this.error = false;
    this.loading = false;
    this.submitted = false;
    var stored = localStorage.getItem(this.store_name);
    if (stored) stored = JSON.parse(stored);

    this.forms = {
      general: null
    };

    this.current_state = {
      general: {
        id: stored && stored["general"] && stored["general"]["id"]
          ? stored["general"]["id"] :
          "",
        firstname: stored && stored["general"] && stored["general"]["firstname"]
          ? stored["general"]["firstname"] :
          "",
        lastname: stored && stored["general"] && stored["general"]["lastname"]
          ? stored["general"]["lastname"] :
          "",
        id_gender: stored && stored["general"] && stored["general"]["id_gender"]
          ? stored["general"]["id_gender"] :
          "1",
        birthdate: stored && stored["general"] && stored["general"]["birthdate"]
          ? stored["general"]["birthdate"] :
          "",
        birthplace: stored && stored["general"] && stored["general"]["birthplace"]
          ? stored["general"]["birthplace"] :
          "",
        id_country: stored && stored["general"] && stored["general"]["id_country"]
          ? stored["general"]["id_country"] :
          "97",
        id_department: stored && stored["general"] && stored["general"]["id_department"]
          ? stored["general"]["id_department"] :
          "",
        id_town: stored && stored["general"] && stored["general"]["id_town"]
          ? stored["general"]["id_town"] :
          "",
        address: stored && stored["general"] && stored["general"]["address"]
          ? stored["general"]["address"] :
          "",
        phone: stored && stored["general"] && stored["general"]["phone"]
          ? stored["general"]["phone"] :
          "",
        email: stored && stored["general"] && stored["general"]["email"]
          ? stored["general"]["email"] :
          "",
        tutor_email: stored && stored["general"] && stored["general"]["tutor_email"]
          ? stored["general"]["tutor_email"] :
          "",
        id_education: stored && stored["general"] && stored["general"]["id_education"]
          ? stored["general"]["id_education"] :
          "",
        identification: stored && stored["general"] && stored["general"]["identification"]
          ? stored["general"]["identification"] :
          "",
        id_blood: stored && stored["general"] && stored["general"]["id_blood"]
          ? stored["general"]["id_blood"] :
          "",
        organ_donor: stored && stored["general"] && stored["general"]["organ_donor"]
          ? stored["general"]["organ_donor"] :
          "",
      },
      contacts: {
        emergency: stored && stored["contacts"] && stored["contacts"]["emergency"]
          ? stored["contacts"]["emergency"] :
          [],
        doctor: stored && stored["contacts"] && stored["contacts"]["doctor"]
          ? stored["contacts"]["doctor"] :
          [],
      },
      procedures: {
        doctor: stored && stored["procedures"] && stored["procedures"]["doctor"]
          ? stored["procedures"]["doctor"] :
          [],
      },
      background: {
        pathology: stored && stored["background"] && stored["background"]["pathology"]
          ? stored["background"]["pathology"] :
          [],
        allergy: stored && stored["background"] && stored["background"]["allergy"]
          ? stored["background"]["allergy"] :
          [],
      },
      insurance: {
        accident: stored && stored["insurance"] && stored["insurance"]["accident"]
          ? stored["insurance"]["accident"] :
          [],
      },
      exam: {
        laboratory: stored && stored["exam"] && stored["exam"]["laboratory"]
          ? stored["exam"]["laboratory"] :
          [],
      },
      institutions: stored && stored["institutions"]
        ? stored["institutions"] :
        [],
      url: {
        name: "general",
        link: "/nuevo_paciente/general"
      },
      loading: false,
      submitted: false,
      timestamp: stored ? stored["timestamp"] : new Date,
    };
    this.patient_state = new BehaviorSubject(this.current_state);
    this.current = this.patient_state.asObservable();
    this.forms_state == new BehaviorSubject(this.forms);
    this.obv_forms = this.patient_state.asObservable();
    this.catalogs = {
      genders: [],
      countries: [],
      departments: [],
      towns: [],
      educations: [],
      relationships: [],
      doctors_specialties: [],
      procedures_types: [],
      exams_types: [],
      exams_names: [],
      exams_names_2: [],
      allergies_reactions_levels: [],
      allergies_reactions_types: [],
      pathologies: [],
      institutions: [],
      institutions_roles: [],
      institutions_grades: [],
      institutions_grades_sections: [],
      institutions_areas: [],
      diseases: [],
      bloods: [],
      tf: []
    };
    this.reload_catalogs();
  }

  reload() {
    this.get_patient_data();
  }

  get_current_state() {
    return this.current_state;
  }

  get_patient_data() {
    this.endpoint.get_user_data().subscribe(
      data => this.current_state = {
        general: {
          id: data["id"],
          firstname: data["firstname"],
          lastname: data["lastname"],
          id_gender: data["id_gender"],
          birthdate: data["birthdate"],
          birthplace: data["birthplace"],
          id_country: data["id_country"],
          id_department: data["id_department"],
          id_town: data["id_town"],
          address: data["address"],
          phone: data["phone"],
          id_education: data["id_education"],
          identification: data["identification"],
          diseases: data["diseases"]
        },
        timestamp: new Date(),
        ...this.current_state
      },
      error => console.error(error),
      () => {
        this.save();
        this.patient_state.next(this.current_state);
      }
    );
  }

  refresh_forms() {
    this.forms_state.next(this.forms);
  }

  valid() {
    return !this.current_state.loading &&
      this.forms && this.forms.general && this.forms.general.valid ? this.forms.general.valid() : false
  }

  set_url(url: { name: string, link: string }) {
    this.current_state.url = url;
    this.patient_state.next(this.current_state);
  }

  set_form(form: { name: string, module: FormRenderComponent }) {
    this.forms[from.name] = form.module;
  }

  save() {
    let store = {};
    for (const item in this.current_state) {
      if (item != "form") {
        store[item] = this.current_state[item];
      }
    }

    localStorage.setItem(this.store_name, JSON.stringify(store));
  }

  reload_catalogs() {
    this.endpoint.new_patient_catalogs().subscribe(
      data => this.catalogs = {
        genders: data["genders"],
        countries: data["countries"],
        departments: data["departments"],
        towns: data["towns"],
        educations: data["educations"],
        relationships: data["relationships"],
        doctors_specialties: data["doctors_specialties"],
        procedures_types: data["procedures_types"],
        exams_types: data["exams_types"],
        exams_names: data["exams_names"],
        exams_names_2: data["exams_names_2"],
        allergies_reactions_levels: data["allergies_reactions_levels"],
        allergies_reactions_types: data["allergies_reactions_types"],
        pathologies: data["pathologies"],
        institutions: data["institutions"],
        institutions_grades: data["institutions_grades"],
        institutions_grades_sections: data["institutions_grades_sections"],
        institutions_roles: data["institutions_roles"],
        institutions_areas: data["institutions_areas"],
        diseases: data["diseases"],
        bloods: data["bloods"],
        tf: data["tf"],
      },
      error => console.error(error)
    );
  }

  submit() {
    this.forms.general.submit(() => { })

    this.current_state.submitted = true;
    this.patient_state.next(this.current_state);

    if (!this.current_state.loading &&
      this.forms.general.valid()
    ) {
      let store = {};
      for (const item in this.current_state) {
        if (item != "form") {
          store[item] = this.current_state[item];
        }
      }

      this.current_state.loading = true;
      this.endpoint.insert_patient_by_doctor(store).subscribe(
        data => {
          localStorage.removeItem("inNewPatient");
          this.toastr.success(data["message"], data["title"])
          this.current_state.loading = false;
          this.patient_state.next(this.current_state);
          this.clean();

          this.patientService.change_patient_id(data["id_user"]);
          this.endpoint.redirect_to("/expediente/consultas");
        },
        error => {
          console.error(error)
          this.toastr.error(
            error.error && error.error.message ? error.error.message : error.message,
            error.error && error.error.title ? error.error.title : error.status + ''
          );
          this.current_state.loading = false;
          this.patient_state.next(this.current_state);
        }
      )
    }
  }

  cancel() {
    this.pageService.cancel_new_patient(() => {
      this.clean();
      this.endpoint.redirect_to("/mis_pacientes");
    });
  }

  clean() {
    this.current_state = {
      general: {
        id: "",
        firstname: "",
        lastname: "",
        id_gender: "1",
        birthdate: "",
        birthplace: "",
        id_country: "97",
        id_department: "",
        id_town: "",
        address: "",
        phone: "",
        email: "",
        tutor_email: "",
        id_education: "",
        identification: "",
        id_blood: "",
        organ_donor: "1",
      },
      contacts: {
        emergency: [],
        doctor: [],
      },
      procedures: {
        doctor: []
      },
      background: {
        pathology: [],
        allergy: [],
      },
      insurance: {
        accident: [],
      },
      exam: {
        laboratory: [],
      },
      institutions: [],
      url: {
        name: "general",
        link: "/nuevo_paciente/general"
      },
      loading: false,
      submitted: false,
      timestamp: new Date(),
    };
    this.patient_state.next(this.current_state);
    this.save();
  }


  set_attribute(data, name) {
    this.current_state[name] = data;
  }
}