import { Socket } from 'ngx-socket-io';
import * as i0 from "@angular/core";
import * as i1 from "ngx-socket-io";
export class SocketService {
    constructor(socket) {
        this.socket = socket;
        this.showAlert = false;
        this.socketStatus = false;
    }
    init() {
        this.listen();
    }
    listen() {
        this.socket.on('connect', () => {
            console.info("Conectado al Servidor");
            this.socketStatus = true;
            this.showAlert = false;
        });
        this.socket.on('error', () => {
            console.info("Sin Conexión con el Servidor");
            this.socketStatus = false;
        });
        this.socket.on('disconnect', () => {
            console.info("Desconectado del Servidor");
            this.socketStatus = false;
            this.showAlert = true;
        });
        this.socket.on('notification', data => {
            console.info(data);
        });
    }
    // AUTH
    login(id) {
        this.socket.emit('login', { id });
    }
    logout() {
        this.socket.emit('logout');
    }
    // AUTH
    sendMessage(msg) {
        this.socket.emit('message', msg);
    }
    getMessage() {
        return this.socket.fromEvent('message');
    }
    getTutorPhoneVerify() {
        return this.socket.fromEvent('TutorPhoneVerify');
    }
    getUserPhoneVerify() {
        return this.socket.fromEvent('UserPhoneVerify');
    }
    getUserEmailVerify() {
        return this.socket.fromEvent('UserEmailVerify');
    }
    getDoctorPatientPhoneVerify() {
        return this.socket.fromEvent('DoctorPatientPhoneVerify');
    }
}
SocketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SocketService_Factory() { return new SocketService(i0.ɵɵinject(i1.Socket)); }, token: SocketService, providedIn: "root" });
