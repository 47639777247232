<div class="pc-layout">
    <div class="pc-header">
        <div class="pch-title">consulta médica</div>
        <div class="pch-date"> {{ data?.consult?.created_at | shortDate }}</div>
        <div class="pch-icons">
            <a class="fa-duotone fa-share-from-square" title="Compartir" [routerLink]="'/expediente/consulta_paciente/' + data?.consult?.id"></a>
            <i class="fa-duotone fa-print ml-2" title="Imprimir consulta"></i>
            <a class="fa-duotone fa-times fa-xl text-white ml-3" title="Cerrar consulta" routerLink="/expediente/consultas"></a>
        </div>
    </div>
    <div class="pc-body">
        <div style="display: flex; align-items: baseline;">
            <div class="pcb-patient">{{ data?.consult?.patient_name | names }}</div>
            <div class="pcb-box-trans pt-1 pl-1" style="white-space: pre-line;">
                -
                {{
                data?.consult?.age > 0
                ? data?.consult?.age + (data?.consult?.age > 1 ? " años" : " año")
                : data?.consult?.age_month > 0
                ? data?.consult?.age_month + (data?.consult?.age_month > 1 ? " meses" : " mes") + 
                (data?.consult?.age_day > 0
                ? ", " + data?.consult?.age_day + (data?.consult?.age_day > 1 ? " días" : " día") : "")
                : data?.consult?.age_day > 0
                ? data?.consult?.age_day + (data?.consult?.age_day > 1 ? " días" : " día")
                : "Recién nacido"
                }}
            </div>
        </div>

        <div class="d-flex">
            <div class="d-flex flex-column flex-grow-1 mx-3">
                <div class="pcb-title-2 mt-3">MOTIVO DE CONSULTA</div>
                <div class="pcb-box-grey mt-1" style="white-space: pre-line;">
                    {{data?.consult?.general?.reason}}
                    <ng-container *ngIf="!data?.consult?.general?.reason">
                        No hay motivo de consulta
                    </ng-container>
                </div>

                <div class="pcb-title-2 mt-3">HISTORIA DE ENFERMEDAD ACTUAL</div>
                <div class="pcb-box-trans" style="white-space: pre-line;">
                    {{data?.consult?.general?.description}}
                    <ng-container *ngIf="!data?.consult?.general?.description">
                        No hay historia de enfermedad actual
                    </ng-container>
                </div>
            </div>

            <div class="pcb-doctor" *ngIf="!data?.consult?.own">
                <div class="d-flex mt-4 mx-auto">
                    <a [routerLink]="'/perfil/' + data?.consult?.doctor_username" target="_blank">
                        <avatar [name]="data?.consult?.doctor_name" [image]="data?.consult?.doctor_image" [configs]="avatar_configs"></avatar>
                    </a>
                </div>

                <a [routerLink]="'/perfil/' + data?.consult?.doctor_username" target="_blank" class="pcb-name mt-2"> {{ data?.consult?.doctor_gender == 1 ? "DRA." : "DR." }} {{ data?.consult?.doctor_name | uppercase }}</a>
                <a [routerLink]="'/perfil/' + data?.consult?.doctor_username" target="_blank" class="pcb-specialty">
                    {{ data?.consult?.doctor_specialty }}
                </a>
            </div>
        </div>

        <div class="pcb-carrousel mt-2" [class.pcbc-show]="carrousel[0]==true">
            <div class="pcbc-header" (click)="carrousel[0]=!carrousel[0]">
                <div class="pcbch-bar"></div>
                <div class="pcb-title">MEDIDAS Y VITALES</div>
                <div class="pcbch-icon">
                    <i class="fa-solid fa-angle-down"></i>
                    <i class="fa-solid fa-angle-up"></i>
                </div>
            </div>
            <div class="pcbc-body">
                <div class="pcb-indicator mx-3">
                    <div class="pcbi-icon">
                        <i class="fa-duotone fa-weight-hanging text-warning"></i>
                    </div>
                    <div class="pcbi-data">
                        <div class="pcbid-title">peso</div>
                        <div class="pcbid-value">
                            {{data?.consult?.growth_curve?.weight}}
                            <ng-container *ngIf="!data?.consult?.growth_curve?.weight">
                                --
                            </ng-container>
                            <div class="pcbidv-unit">
                                {{data?.consult?.growth_curve?.id_weight_unit | catalog: get_catalogs("units_weight", "id", "code") }}
                            </div>
                        </div>
                        <div class="pcbid-value-2">
                            <div class="pcbidv2-unit" style="font-variant: small-caps;">
                                percentil
                            </div>
                            {{data?.consult?.growth_curve?.p_weight}}
                            <ng-container *ngIf="!data?.consult?.growth_curve?.p_weight">
                                --
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="pcb-indicator mx-3">
                    <div class="pcbi-icon">
                        <i class="fa-duotone fa-ruler-combined text-info"></i>
                    </div>
                    <div class="pcbi-data">
                        <div class="pcbid-title">talla</div>
                        <div class="pcbid-value">
                            {{data?.consult?.growth_curve?.height}}
                            <ng-container *ngIf="!data?.consult?.growth_curve?.height">
                                --
                            </ng-container>
                            <div class="pcbidv-unit">
                                {{data?.consult?.growth_curve?.id_height_unit | catalog: get_catalogs("units_height", "id", "code") }}
                            </div>
                        </div>
                        <div class="pcbid-value-2">
                            <div class="pcbidv2-unit" style="font-variant: small-caps;">
                                percentil
                            </div>
                            {{data?.consult?.growth_curve?.p_height}}
                            <ng-container *ngIf="!data?.consult?.growth_curve?.p_height">
                                --
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="pcb-indicator mx-3">
                    <div class="pcbi-icon">
                        <i class="fa-duotone fa-weight-scale text-success"></i>
                    </div>
                    <div class="pcbi-data">
                        <div class="pcbid-title">imc</div>
                        <div class="pcbid-value">
                            {{data?.consult?.growth_curve?.IMC}}
                            <ng-container *ngIf="!data?.consult?.growth_curve?.IMC">
                                --
                            </ng-container>
                            <div class="pcbidv-unit">
                                {{data?.consult?.growth_curve?.id_height_unit | catalog: get_catalogs("units_IMC", "id", "code") }}
                            </div>
                        </div>
                        <div class="pcbid-value-2">
                            <div class="pcbidv2-unit" style="font-variant: small-caps;">
                                percentil
                            </div>
                            {{data?.consult?.growth_curve?.p_IMC}}
                            <ng-container *ngIf="!data?.consult?.growth_curve?.p_IMC">
                                --
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="pcb-indicator mx-3">
                    <div class="pcbi-icon">
                        <i class="fa-duotone fa-head-side text-purple"></i>
                    </div>
                    <div class="pcbi-data">
                        <div class="pcbid-title">c. cefálica</div>
                        <div class="pcbid-value">
                            {{data?.consult?.growth_curve?.head}}
                            <ng-container *ngIf="!data?.consult?.growth_curve?.head">
                                --
                            </ng-container>
                            <div class="pcbidv-unit">
                                {{data?.consult?.growth_curve?.id_height_unit | catalog: get_catalogs("units_height", "id", "code") }}
                            </div>
                        </div>
                        <div class="pcbid-value-2">
                            <div class="pcbidv2-unit" style="font-variant: small-caps;">
                                percentil
                            </div>
                            {{data?.consult?.growth_curve?.p_head}}
                            <ng-container *ngIf="!data?.consult?.growth_curve?.p_head">
                                --
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pcb-carrousel mt-3" [class.pcbc-show]="carrousel[1]==true">
                <div class="pcbc-header" (click)="carrousel[1]=!carrousel[1]">
                    <div class="pcbch-bar"></div>
                    <div class="pcb-title">VITALES</div>
                    <div class="pcbch-icon">
                        <i class="fa-solid fa-angle-down"></i>
                        <i class="fa-solid fa-angle-up"></i>
                    </div>
                </div>
                <div class="pcbc-body">
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-icon">
                            <i class="fa-duotone fa-heart-pulse text-warning"></i>
                        </div>
                        <div class="pcbi-data">
                            <div class="pcbid-title">frec. cardíaca</div>
                            <div class="pcbid-value">
                                {{data?.consult?.vitals?.heart_rate}}
                                <ng-container *ngIf="!data?.consult?.vitals?.heart_rate">
                                    --
                                </ng-container>
                                <div class="pcbidv-unit">
                                    lpm
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-icon">
                            <i class="fa-duotone fa-wave-pulse text-info"></i>
                        </div>
                        <div class="pcbi-data">
                            <div class="pcbid-title">pulso</div>
                            <div class="pcbid-value">
                                {{data?.consult?.vitals?.pulse}}
                                <ng-container *ngIf="!data?.consult?.vitals?.pulse">
                                    --
                                </ng-container>
                                <div class="pcbidv-unit">
                                    kg/m²
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-icon">
                            <i class="fa-duotone fa-lungs text-success"></i>
                        </div>
                        <div class="pcbi-data">
                            <div class="pcbid-title">fr.respiratoria</div>
                            <div class="pcbid-value">
                                {{data?.consult?.vitals?.breathing_rate}}
                                <ng-container *ngIf="!data?.consult?.vitals?.breathing_rate">
                                    --
                                </ng-container>
                                <div class="pcbidv-unit">
                                    rpm
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-icon">
                            <i class="fa-duotone fa-monitor-waveform text-purple"></i>
                        </div>
                        <div class="pcbi-data">
                            <div class="pcbid-title">sat o2</div>
                            <div class="pcbid-value">
                                {{data?.consult?.vitals?.sat02}}
                                <ng-container *ngIf="!data?.consult?.vitals?.sat02">
                                    --
                                </ng-container>
                                <div class="pcbidv-unit">
                                    %
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-icon">
                            <i class="fa-duotone fa-temperature-low text-primary"></i>
                        </div>
                        <div class="pcbi-data">
                            <div class="pcbid-title">temperatura</div>
                            <div class="pcbid-value">
                                {{data?.consult?.vitals?.temp}}
                                <ng-container *ngIf="!data?.consult?.vitals?.temp">
                                    --
                                </ng-container>
                                <div class="pcbidv-unit">
                                    °C
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pcb-carrousel mt-3" [class.pcbc-show]="carrousel[2]==true">
                <div class="pcbc-header" (click)="carrousel[2]=!carrousel[2]">
                    <div class="pcbch-bar"></div>
                    <div class="pcb-title">PRESIÓN ARTERIAL</div>
                    <!-- <div class="pcbch-icon">
                    <i class="fa-solid fa-angle-up"></i>
                    <i class="fa-solid fa-angle-down"></i>
                </div> -->
                </div>
                <div class="pcbc-body pl-2">
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-data">
                            <div class="pcbid-title">presión sistólica</div>
                            <div class="pcbid-value">
                                {{data?.consult?.vitals?.sistolic_pressure}}
                                <ng-container *ngIf="!data?.consult?.vitals?.sistolic_pressure">
                                    --
                                </ng-container>
                                <div class="pcbidv-unit">
                                    mmHg
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-data">
                            <div class="pcbid-title">presión diastólica</div>
                            <div class="pcbid-value">
                                {{data?.consult?.vitals?.diastolic_pressure}}
                                <ng-container *ngIf="!data?.consult?.vitals?.diastolic_pressure">
                                    --
                                </ng-container>
                                <div class="pcbidv-unit">
                                    mmHg
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-data">
                            <div class="pcbid-title">pam</div>
                            <div class="pcbid-value">
                                {{data?.consult?.vitals?.PAM}}
                                <ng-container *ngIf="!data?.consult?.vitals?.PAM">
                                    --
                                </ng-container>
                                <div class="pcbidv-unit">
                                    mmHg
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-data">
                            <div class="pcbid-title">presión de pulso</div>
                            <div class="pcbid-value">
                                {{data?.consult?.vitals?.pressure_pulse}}
                                <ng-container *ngIf="!data?.consult?.vitals?.pressure_pulse">
                                    --
                                </ng-container>
                                <div class="pcbidv-unit">
                                    mmHg
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex w-100">
                <div class="pcb-carrousel mt-3" style="width: 65%;" [class.pcbc-show]="carrousel[3]==true">
                    <div class="pcbc-header" (click)="carrousel[3]=!carrousel[3]">
                        <div class="pcbch-bar"></div>
                        <div class="pcb-title">GLASGOW</div>
                        <!-- <div class="pcbch-icon">
                        <i class="fa-solid fa-angle-down"></i>
                        <i class="fa-solid fa-angle-up"></i>
                    </div> -->
                    </div>
                    <div class="pcbc-body pl-2">
                        <div class="pcb-indicator mx-3">
                            <div class="pcbi-data">
                                <div class="pcbid-title">respuesta ocular</div>
                                <div class="pcbid-value-2">
                                    <div class="pcbidv2-unit">
                                        <ng-container *ngIf="!!data?.consult?.vitals?.glasgow_eye">
                                            {{data?.consult?.vitals?.glasgow_eye | catalog: get_catalogs("units_glasgow_eye", "code") }}
                                        </ng-container>
                                        <ng-container *ngIf="!data?.consult?.vitals?.glasgow_eye">
                                            --
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pcb-indicator mx-3">
                            <div class="pcbi-data">
                                <div class="pcbid-title">respuesta verbal</div>
                                <div class="pcbid-value-2">
                                    <div class="pcbidv2-unit">
                                        <ng-container *ngIf="!!data?.consult?.vitals?.glasgow_talk">
                                            {{data?.consult?.vitals?.glasgow_talk | catalog: get_catalogs("units_glasgow_talk", "code") }}
                                        </ng-container>
                                        <ng-container *ngIf="!data?.consult?.vitals?.glasgow_talk">
                                            --
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pcb-indicator mx-3">
                            <div class="pcbi-data">
                                <div class="pcbid-title">respuesta motora</div>
                                <div class="pcbid-value-2">
                                    <div class="pcbidv2-unit">
                                        <ng-container *ngIf="!!data?.consult?.vitals?.glasgow_move">
                                            {{data?.consult?.vitals?.glasgow_move | catalog: get_catalogs("units_glasgow_move", "code") }}
                                        </ng-container>
                                        <ng-container *ngIf="!data?.consult?.vitals?.glasgow_move">
                                            --
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pcb-indicator mx-3">
                            <div class="pcbi-data">
                                <div class="pcbid-title">total</div>
                                <div class="pcbid-value">
                                    <ng-container *ngIf="!!data?.consult?.vitals?.glasgow_total">
                                        {{data?.consult?.vitals?.glasgow_total}}
                                    </ng-container>
                                    <ng-container *ngIf="!data?.consult?.vitals?.glasgow_total">
                                        --
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pcb-carrousel mt-3" style="width: 35%;" [class.pcbc-show]="carrousel[4]==true">
                    <div class="pcbc-header" (click)="carrousel[4]=!carrousel[4]">
                        <div class="pcbch-bar"></div>
                        <div class="pcb-title">VISIÓN OCULAR</div>
                        <!-- <div class="pcbch-icon">
                        <i class="fa-solid fa-angle-down"></i>
                        <i class="fa-solid fa-angle-up"></i>
                    </div> -->
                    </div>
                    <div class="pcbc-body">
                        <div class="pcb-indicator mx-3">
                            <div class="pcbi-data">
                                <div class="pcbid-title">ojo izquierdo</div>
                                <div class="pcbid-value-2">
                                    <div class="pcbidv2-unit">
                                        {{data?.consult?.vitals?.eye_vision_left}}
                                        <ng-container *ngIf="!data?.consult?.vitals?.eye_vision_left">
                                            --
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pcb-indicator mx-3">
                            <div class="pcbi-data">
                                <div class="pcbid-title">ojo derecho</div>
                                <div class="pcbid-value-2">
                                    <div class="pcbidv2-unit">
                                        {{data?.consult?.vitals?.eye_vision_right}}
                                        <ng-container *ngIf="!data?.consult?.vitals?.eye_vision_right">
                                            --
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="pcb-carrousel mt-3" [class.pcbc-show]="carrousel[5]==true">
            <div class="pcbc-header" (click)="carrousel[5]=!carrousel[5]">
                <div class="pcbch-bar"></div>
                <div class="pcb-title">EXAMEN FÍSICO</div>
                <div class="pcbch-icon">
                    <i class="fa-solid fa-angle-down"></i>
                    <i class="fa-solid fa-angle-up"></i>
                </div>
            </div>
            <div class="pcbc-body pl-3" style="flex-direction: column;">
                <div class="pcb-indicator w-100 mb-2">
                    <div class="pcbi-data">
                        <div class="pcbid-title">neurológico</div>
                        <div class="pcbid-value-2">
                            <div class="pcbidv2-unit" style="white-space: pre-line;">
                                {{data?.consult?.exams_physical?.neurological}}
                                <ng-container *ngIf="!data?.consult?.exams_physical?.neurological">
                                    No hay hallazgos neurológicos
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pcb-indicator w-100 mb-2">
                    <div class="pcbi-data">
                        <div class="pcbid-title">ojos</div>
                        <div class="pcbid-value-2">
                            <div class="pcbidv2-unit" style="white-space: pre-line;">
                                {{data?.consult?.exams_physical?.eyes}}
                                <ng-container *ngIf="!data?.consult?.exams_physical?.eyes">
                                    No hay hallazgos de los ojos
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pcb-indicator w-100 mb-2">
                    <div class="pcbi-data">
                        <div class="pcbid-title">oidos</div>
                        <div class="pcbid-value-2">
                            <div class="pcbidv2-unit" style="white-space: pre-line;">
                                {{data?.consult?.exams_physical?.ears}}
                                <ng-container *ngIf="!data?.consult?.exams_physical?.ears">
                                    No hay hallazgos de los oidos
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pcb-indicator w-100 mb-2">
                    <div class="pcbi-data">
                        <div class="pcbid-title">nariz</div>
                        <div class="pcbid-value-2">
                            <div class="pcbidv2-unit" style="white-space: pre-line;">
                                {{data?.consult?.exams_physical?.nose}}
                                <ng-container *ngIf="!data?.consult?.exams_physical?.nose">
                                    No hay hallazgos de la nariz
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pcb-indicator w-100 mb-2">
                    <div class="pcbi-data">
                        <div class="pcbid-title">boca</div>
                        <div class="pcbid-value-2">
                            <div class="pcbidv2-unit" style="white-space: pre-line;">
                                {{data?.consult?.exams_physical?.mouth}}
                                <ng-container *ngIf="!data?.consult?.exams_physical?.mouth">
                                    No hay hallazgos de la boca
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pcb-indicator w-100 mb-2">
                    <div class="pcbi-data">
                        <div class="pcbid-title">cuello</div>
                        <div class="pcbid-value-2">
                            <div class="pcbidv2-unit" style="white-space: pre-line;">
                                {{data?.consult?.exams_physical?.neck}}
                                <ng-container *ngIf="!data?.consult?.exams_physical?.neck">
                                    No hay hallazgos del cuello
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pcb-indicator w-100 mb-2">
                    <div class="pcbi-data">
                        <div class="pcbid-title">cardíaco</div>
                        <div class="pcbid-value-2">
                            <div class="pcbidv2-unit" style="white-space: pre-line;">
                                {{data?.consult?.exams_physical?.cardiac}}
                                <ng-container *ngIf="!data?.consult?.exams_physical?.cardiac">
                                    No hay hallazgos cardíacos
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pcb-indicator w-100 mb-2">
                    <div class="pcbi-data">
                        <div class="pcbid-title">pulmonar</div>
                        <div class="pcbid-value-2">
                            <div class="pcbidv2-unit" style="white-space: pre-line;">
                                {{data?.consult?.exams_physical?.pulmonar}}
                                <ng-container *ngIf="!data?.consult?.exams_physical?.pulmonar">
                                    No hay hallazgos pulmonares
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pcb-indicator w-100 mb-2">
                    <div class="pcbi-data">
                        <div class="pcbid-title">abdomen</div>
                        <div class="pcbid-value-2">
                            <div class="pcbidv2-unit" style="white-space: pre-line;">
                                {{data?.consult?.exams_physical?.abdomen}}
                                <ng-container *ngIf="!data?.consult?.exams_physical?.abdomen">
                                    No hay hallazgos del abdomen
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pcb-indicator w-100 mb-2">
                    <div class="pcbi-data">
                        <div class="pcbid-title">genitales</div>
                        <div class="pcbid-value-2">
                            <div class="pcbidv2-unit" style="white-space: pre-line;">
                                {{data?.consult?.exams_physical?.genitals}}
                                <ng-container *ngIf="!data?.consult?.exams_physical?.genitals">
                                    No hay hallazgos de los genitales
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pcb-indicator w-100 mb-2">
                    <div class="pcbi-data">
                        <div class="pcbid-title">extremidades</div>
                        <div class="pcbid-value-2">
                            <div class="pcbidv2-unit" style="white-space: pre-line;">
                                {{data?.consult?.exams_physical?.extremities}}
                                <ng-container *ngIf="!data?.consult?.exams_physical?.extremities">
                                    No hay hallazgos de las extremidades
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pcb-indicator w-100 mb-2">
                    <div class="pcbi-data">
                        <div class="pcbid-title">otros hallazgos</div>
                        <div class="pcbid-value-2">
                            <div class="pcbidv2-unit" style="white-space: pre-line;">
                                {{data?.consult?.exams_physical?.others}}
                                <ng-container *ngIf="!data?.consult?.exams_physical?.others">
                                    No hay otros hallazgos
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pcb-carrousel mt-3" [class.pcbc-show]="carrousel[6]==true">
            <div class="pcbc-header" (click)="carrousel[6]=!carrousel[6]">
                <div class="pcbch-bar"></div>
                <div class="pcb-title">PROCEDIMIENTOS REALIZADOS</div>
                <div class="pcbch-icon">
                    <i class="fa-solid fa-angle-down"></i>
                    <i class="fa-solid fa-angle-up"></i>
                </div>
            </div>
            <ng-container *ngFor="let item of data?.consult?.procedures_results_list?.list">
                <div class="pcbc-body">
                    <div class="pcb-indicator mx-3 mt-2">
                        <div class="pcbi-data">
                            <div class="pcbid-title-2">procedimiento</div>
                            <div class="pcbid-value-2">
                                <div class="pcbidv2-unit">
                                    {{ item.id_procedures_type | catalog: get_catalogs("procedures_types") }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-data">
                            <div class="pcbid-title-2">fecha</div>
                            <div class="pcbid-value-2">
                                <div class="pcbidv2-unit" style="white-space: nowrap;">
                                    {{ item.date | microDate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pcb-indicator">
                        <div class="pcbi-data">
                            <div class="pcbid-title-2">observaciones</div>
                            <div class="pcbid-value-2">
                                <div class="pcbidv2-unit">
                                    {{ item.observations }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-data">
                            <div class="pcbid-title-2">médico</div>
                            <div class="pcbid-value-2">
                                <div class="pcbidv2-unit">
                                    {{ item.doctor_name }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-data">
                            <div class="pcbid-title-2">lugar</div>
                            <div class="pcbid-value-2">
                                <div class="pcbidv2-unit">
                                    {{ item.place }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="data?.consult?.procedures_results_list?.list?.length == 0">
                <div class="pcbc-body">
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-data">
                            <div class="pcbid-value-2">
                                <div class="pcbidv2-unit">
                                    No hay procedimientos realizados
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="pcb-carrousel mt-3" [class.pcbc-show]="carrousel[7]==true">
            <div class="pcbc-header" (click)="carrousel[7]=!carrousel[7]">
                <div class="pcbch-bar"></div>
                <div class="pcb-title">RESULTADOS DE EXÁMENES</div>
                <div class="pcbch-icon">
                    <i class="fa-solid fa-angle-down"></i>
                    <i class="fa-solid fa-angle-up"></i>
                </div>
            </div>
            <ng-container *ngFor="let item of data?.consult?.exams_results_list?.list">
                <div class="pcbc-body">
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-data">
                            <div class="pcbid-title-2">tipo de examen</div>
                            <div class="pcbid-value-2">
                                <div class="pcbidv2-unit">
                                    {{ item.id_exams_type | catalog: get_catalogs("exams_types") }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-data">
                            <div class="pcbid-title-2">nombre del examen</div>
                            <div class="pcbid-value-2">
                                <div class="pcbidv2-unit">
                                    {{ item.id_exams_name | catalog: get_catalogs("exams_names") }}
                                    <ng-container *ngIf="item.id_exams_name_2">- {{ item.id_exams_name_2 | catalog: get_catalogs("exams_names_2") }}</ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pcb-indicator">
                        <div class="pcbi-data">
                            <div class="pcbid-title-2">observaciones</div>
                            <div class="pcbid-value-2">
                                <div class="pcbidv2-unit">
                                    {{ item.observations }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-data">
                            <div class="pcbid-title-2">lugar</div>
                            <div class="pcbid-value-2">
                                <div class="pcbidv2-unit">
                                    {{ item.place }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="data?.consult?.exams_results_list?.list?.length == 0">
                <div class="pcbc-body">
                    <div class="pcb-indicator mx-3">
                        <div class="pcbi-data">
                            <div class="pcbid-value-2">
                                <div class="pcbidv2-unit">
                                    No hay resultados de exámenes
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <div class="d-flex w-100 mt-4">
            <div class="w-50 mr-3">
                <div class="pcb-title mb-2">DIAGNÓSTICO</div>
                <div class="pcb-box-grey" style="font-size: 10pt; line-height: 12pt; padding-left: 0;">
                    <ng-container *ngIf="data?.consult?.diagnosis_list?.list?.length > 0">
                        <ul>
                            <li *ngFor="let item of data?.consult?.diagnosis_list?.list">{{item.name}}</li>
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="data?.consult?.diagnosis_list?.list?.length == 0">
                        <div class="pl-2">No hay diagnóstico</div>
                    </ng-container>
                </div>
            </div>
            <div class="w-50">
                <div class="pcb-title mb-2">RECOMENDACIONES Y NOTAS</div>
                <div class="pcb-box-trans" style="font-size: 10pt; line-height: 12pt; white-space: pre-line;">
                    {{data?.consult?.recommendation?.recomendations}}
                    <ng-container *ngIf="!data?.consult?.recommendation?.hidden_notes">
                        No hay recomendaciones
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="pcb-title mt-3">
            RECETA
            <button *ngIf="data?.consult?.prescription_list?.list.length > 0" class="btn btn-outline-primary ml-2 my-auto" style="height: 21.0125pt;" [routerLink]="'/expediente/receta/' + data?.consult?.id">ver receta</button>
        </div>
        <div class="pcb-list mt-2">
            <ng-container *ngFor="let item of data?.consult?.prescription_list?.list">
                <div class="pcbl-item mb-1">
                    <div style="display: inline;">
                        <ng-container *ngIf="!!item.brand">
                            {{item.brand}}
                            <div style="display: inline;">
                                - {{item.presentation }} ({{item.principle }})
                                <ng-container *ngIf="['Crema', 'Jarabe'].includes(item.presentation)">({{item.concentration}})</ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!item.brand">
                            {{item.principle }}
                            <div style="display: inline;">
                                - {{item.presentation }}
                                <ng-container *ngIf="['Crema', 'Jarabe'].includes(item.presentation)">({{item.concentration}})</ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <div style="display: inline;">
                        <ng-container *ngIf="item.presentation == 'Crema'">
                            {{item.dosage }} aplicaciones
                        </ng-container>
                        <ng-container *ngIf="item.presentation == 'Suspensión'">
                            {{item.dosage }} {{item.dosage_unit | lowercase }}
                        </ng-container>
                        <ng-container *ngIf="item.presentation != 'Crema' && item.presentation != 'Suspensión'">
                            Tomar {{item.dosage }} {{item.dosage_unit | lowercase }}
                        </ng-container>
                        vía {{item.way | lowercase }} cada {{item.frecuency | lowercase }} {{item.frecuency_unit | lowercase }}<ng-container *ngIf="!item.continues">
                            por {{item.period | lowercase }} {{item.period_unit | lowercase }}</ng-container><ng-container *ngIf="!!item.continues">, uso continuo</ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="data?.consult?.prescription_list?.list?.length == 0">
                <div class="pcbl-item">
                    No hay receta
                </div>
            </ng-container>
        </div>

        <div class="d-flex w-100 mt-4">
            <div style="width: 50%;">
                <div class="pcb-title">REFERENCIAS</div>
                <div class="pcb-list mt-2">
                    <ng-container *ngFor="let item of data?.consult?.references_list?.list">
                        <div class="pcbl-item">
                            <div style="display: inline-flex;">
                                {{ item.doctor }}
                                <div style="font-style: italic; color: #6D6E71;" class="mx-1">{{ item.id_doctors_specialty | catalog: get_catalogs("doctors_specialties") }}</div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="data?.consult?.references_list?.list?.length == 0">
                        <div class="pcbl-item">
                            No hay referencias
                        </div>
                    </ng-container>
                </div>
            </div>
            <div style="width: 50%;">
                <div class="pcb-title">PROCEDIMIENTOS</div>
                <div class="pcb-list mt-2">
                    <ng-container *ngFor="let item of data?.consult?.procedures_requests_list?.list">
                        <div class="pcbl-item"> Se solicita {{ item.id_procedures_type | catalog: get_catalogs("procedures_types") }}</div>
                    </ng-container>
                    <ng-container *ngIf="data?.consult?.procedures_requests_list?.list?.length == 0">
                        <div class="pcbl-item">
                            No hay procedimientos solicitados
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="pcb-title mt-3">EXÁMENES</div>
        <div class="pcb-list mt-2">
            <ng-container *ngFor="let item of data?.consult?.exams_requests_list?.list">
                <div class="pcbl-item" style="font-size: 13pt; line-height: 18pt;"> {{ item.id_exams_type | catalog: get_catalogs("exams_types") }}, {{ item.id_exams_name | catalog: get_catalogs("exams_names") }}
                    <ng-container *ngIf="item.id_exams_name_2">
                        - {{ item.id_exams_name_2 | catalog: get_catalogs("exams_names_2") }}
                    </ng-container>
                </div>
            </ng-container>
            <ng-container *ngIf="data?.consult?.exams_requests_list?.list?.length == 0">
                <div class="pcbl-item">
                    No hay exámenes solicitados
                </div>
            </ng-container>
        </div>

        <div class="pcb-notes mt-4" *ngIf="!!data?.consult?.own">
            <div class="pcbn-title">MIS NOTAS:</div>
            <div class="pcbn-text mt-1" style="white-space: pre-line;" *ngIf="!!data?.consult?.recommendation?.hidden_notes">{{data?.consult?.recommendation?.hidden_notes}}</div>
            <ng-container *ngIf="!data?.consult?.recommendation?.hidden_notes">
                <div class="pcbn-text mt-1">
                    No hay notas
                </div>
            </ng-container>
        </div>
    </div>

</div>