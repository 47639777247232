<div class = "calendar">
	<div class="wrapper-calendar ">
		<div class="header-calendar ">
			<div class="body">
				<button (click)="change_month(-1)" class="letras btn-prev">Mes Anterior</button>
			</div>
			<h1 class="letras Cap">{{date_select._i | date:'MMMM, yyyy'}}</h1>
	
			<div class="body">
				<button (click)="change_month(1)" class="letras btn-next">Mes Siguiente</button>
			</div>
			
		</div>

		<ol class="list1">
			<li *ngFor="let day of week" class="day-name">{{day | slice:0:3}}</li>
	
			<li   [style.gridColumnStart] ="first ? day?.indexWeek : 'auto'"
			 *ngFor="let day of month_select_principal; let first = first">
			 <div (click)="click_day(day)"  class="number">
				<span  class="text-center  ">{{day?.value}}</span>
			 </div>
			 <div class="dates">


				<!-- Día igual  -->
				<div  *ngIf="dia != day?.value  " class="box body">

					<ul class="list3">
						<li *ngFor="let cita of consults_data1 " class="position list2" > 
							<span class="badge badge-pill badge-warning  spann" (click)="show_consult(cita)"
							*ngIf=" (cita.dia == day?.value) && (cita.mes == month_present) && (cita.anio == year_select2)"> {{ cita.paciente | uppercase  }} {{ cita.hora }} </span>
						</li>
					</ul>
				
					<ul class="list3">
						<li *ngFor="let cita of patient_data1 " class="position list2" > 
							<span class="badge badge-pill badge-success spann2" (click)="show_modal_date(cita)"
							*ngIf=" (cita.dia == day?.value) && (cita.mes == month_present) && (cita.anio == year_select2)"> {{ cita.paciente | uppercase  }} {{ cita.hora }} </span>
						</li>
					</ul>
				</div>

				<!-- Día igual y Mes Igual -->
				<div *ngIf="(dia == day?.value) && (month == month_present)" class="boxday body">
			
				
					<ul class="list3">
						<li *ngFor="let cita of consults_data1" class="position list2"  >
							<span class="badge badge-pill badge-warning  spann" (click)="show_consult(cita)"
						   *ngIf=" (cita.dia == day?.value) && (cita.mes == month_present ) && (cita.anio == year_select2)"> {{ cita.paciente | uppercase  }} {{ cita.hora }} </span>
					   
					   </li>
					</ul>	

						<ul class="list3">
							<li *ngFor="let cita of patient_data1" class="position list2"  >
								 <span class="badge badge-pill badge-success spann2" (click)="show_modal_date(cita)"
								*ngIf=" (cita.dia == day?.value) && (cita.mes == month_present ) && (cita.anio == year_select2)"> {{ cita.paciente | uppercase  }} {{ cita.hora }} </span>
							
							</li>
						</ul>
				</div>

				<!-- Día igual y Mes Igual -->
				<div *ngIf="(dia == day?.value) &&  (month != month_present )" class="box body">
					<ul class="list3">
						<li *ngFor="let cita of consults_data1" class="position list2"   > 
							<span class="badge badge-pill badge-warning  spann "(click)="show_consult(cita)"
							*ngIf=" (cita.dia == day?.value) && (cita.mes == month_present ) && (cita.anio == year_select2)"> {{ cita.paciente | uppercase  }} {{ cita.hora }} </span>
							
						</li>
					</ul>
					
					<ul class="list3">
						<li *ngFor="let cita of patient_data1" class="position list2"   > 
							<span class="badge badge-pill badge-success spann2" (click)="show_modal_date(cita)"
							*ngIf=" (cita.dia == day?.value) && (cita.mes == month_present ) && (cita.anio == year_select2)"> {{ cita.paciente | uppercase  }} {{ cita.hora }} </span>
					
						</li>
					</ul>
				</div>
				
			 </div>
			 <hr>
			</li>
			<ng-container *ngIf="show_consults" [ngTemplateOutlet]="showModal"></ng-container>
		</ol>
		
		
	</div>

</div>

<div >
	Fecha Seleccionada: {{date_value | date}}
</div>

<ng-template #createModal role="document">
	<app-shedule-date [data]="data"></app-shedule-date>
    </ng-template>

	<ng-template #showModal>
		<app-show_consult [data]="data_consult" (close)="show_consults=false"></app-show_consult>
	</ng-template>


<ng-template #updateModal>
	<app-shedule-modal-date [data]="data_date" ></app-shedule-modal-date>
</ng-template>
