/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create_accident.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../form/form.component.ngfactory";
import * as i3 from "../form/form.component";
import * as i4 from "../accident.service";
import * as i5 from "../../../../../services/expedient.service";
import * as i6 from "./create_accident.component";
var styles_CreateInsurancesAccidentComponent = [i0.styles];
var RenderType_CreateInsurancesAccidentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateInsurancesAccidentComponent, data: {} });
export { RenderType_CreateInsurancesAccidentComponent as RenderType_CreateInsurancesAccidentComponent };
export function View_CreateInsurancesAccidentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-header ribbon-box d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [["class", "text-center w-100 modal-title text-dark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa-duotone fa-plus mr-1 text-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Crear Seguro "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "close text-outline-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "modal-body p-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "text-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-module-form", [], null, null, null, i2.View_FormComponent_0, i2.RenderType_FormComponent)), i1.ɵdid(10, 4440064, null, 0, i3.FormComponent, [i4.AccidentService, i5.ExpedientService], null, null)], function (_ck, _v) { _ck(_v, 10, 0); }, null); }
export function View_CreateInsurancesAccidentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-insurances_create_accident", [], null, null, null, View_CreateInsurancesAccidentComponent_0, RenderType_CreateInsurancesAccidentComponent)), i1.ɵdid(1, 49152, null, 0, i6.CreateInsurancesAccidentComponent, [i4.AccidentService, i5.ExpedientService], null, null)], null, null); }
var CreateInsurancesAccidentComponentNgFactory = i1.ɵccf("app-insurances_create_accident", i6.CreateInsurancesAccidentComponent, View_CreateInsurancesAccidentComponent_Host_0, {}, {}, []);
export { CreateInsurancesAccidentComponentNgFactory as CreateInsurancesAccidentComponentNgFactory };
