import { CatalogsService } from "../services/catalogs.service";
import { PipesService } from "../services/pipes.service";
import { CustomClass } from "./custom-class";

export interface ProceduresResultsInterface {
    id: any;
    id_patient: any;
    id_consult: any;
    id_procedures_type: any;
    observations: string;
    date: Date;
    doctor_name: string;
    place: string;
    file: string;
    file_upload: any;
};

interface ToStringInterface {
    procedures_type: string;
    observations: string;
    date: string;
    doctor_name: string;
    place: string;
    file: string;
};

export class ProceduresResultsClass extends CustomClass implements ProceduresResultsInterface {
    public id: any;
    public id_patient: any;
    public id_consult: any;
    public id_procedures_type: any;
    public observations: string;
    public date: Date;
    public doctor_name: string;
    public place: string;
    public file: string;
    public file_upload: any;

    constructor(private catalogsService: CatalogsService, private pipesService: PipesService, data?: ProceduresResultsInterface) {
        super();
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: ProceduresResultsInterface): void {
        super.overwrite(data);
        this.id = data.id;
        this.id_patient = data.id_patient;
        this.id_consult = data.id_consult;
        this.id_procedures_type = data.id_procedures_type;
        this.observations = data.observations;
        this.date = data.date;
        this.doctor_name = data.doctor_name;
        this.place = data.place;
        this.file = data.file;
        this.file_upload = data.file_upload;
    }

    reset(): void {
        super.reset();
        this.id = null;
        this.id_patient = null;
        this.id_consult = null;
        this.id_procedures_type = null;
        this.observations = "";
        this.date = new Date();
        this.doctor_name = "";
        this.place = "";
        this.file = "";
        this.file_upload = null;
    }

    format(): ProceduresResultsInterface {
        return this as ProceduresResultsInterface;
    }

    valid(): boolean {
        return true;
    }

    to_string(): ToStringInterface {
        let procedures_type = this.catalogsService.catalogs.procedures_types.find(v => v.id == this.id_procedures_type);

        let data: ToStringInterface = {
            procedures_type: procedures_type ? procedures_type.name : "",
            observations: this.observations,
            place: this.place,
            date: this.pipesService.micro_date(this.date.toString()),
            doctor_name: this.doctor_name,
            file: this.file,
        };
        return data;
    }

    raw(): ProceduresResultsInterface {
        return {
            id: this.id,
            id_patient: this.id_patient,
            id_consult: this.id_consult,
            id_procedures_type: this.id_procedures_type,
            observations: this.observations,
            date: this.date,
            doctor_name: this.doctor_name,
            place: this.place,
            file: this.file,
            file_upload: this.file_upload,
        };
    }
}
