<div class="modal-header ribbon-box d-flex align-items-center">
    <h4 class="text-center w-100 modal-title text-dark"><i class="fa-duotone fa-file-circle-info mr-1 text-primary"></i> Detalles de Medicina</h4>
    <button type="button" class="close text-outline-danger" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-2">
    <div class="text-left">
        <app-form_render #form_ref [config]="form_config" [data]="data"></app-form_render>
    </div>
</div>