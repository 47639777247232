<div class="container growth_curves_brief" *ngIf="patient_state?.growth_curve">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-6 headers">
                    <div class="height">
                        <i class="fa-duotone fa-ruler"></i>
                        <div class="d-flex flex-column">
                            <div class="gc_header">altura</div>
                            <div class="date">{{patient_state?.growth_curve.date | microDate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-6 stats">
                    <div class="height">
                        <div class="value">{{patient_state?.growth_curve.height_2 | number}} <div class="unit">cm </div></div> |
                        <div class="percentil">{{patient_state?.growth_curve.p_height}}%</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6 headers">
                    <div class="weight">
                        <i class="fa-duotone fa-weight-hanging"></i>
                        <div class="d-flex flex-column">
                            <div class="gc_header">peso</div>
                            <div class="date">{{patient_state?.growth_curve.date | microDate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-6 stats">
                    <div class="weight">
                        <div class="value">{{patient_state?.growth_curve.weight_2 | number}} <div class="unit">kg </div></div> |
                        <div class="percentil">{{patient_state?.growth_curve.p_weight}}%</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6 headers">
                    <div class="head">
                        <i class="fa-duotone fa-head-side"></i>
                        <div class="d-flex flex-column">
                            <div class="gc_header">cabeza</div>
                            <div class="date">{{patient_state?.growth_curve.date | microDate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-6 stats">
                    <div class="head">
                        <div class="value">{{patient_state?.growth_curve.head_2 | number}} <div class="unit">cm </div></div> |
                        <div class="percentil">{{patient_state?.growth_curve.p_head}}%</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-6 headers">
                    <div class="imc">
                        <i class="fa-duotone fa-weight-scale"></i>
                        <div class="d-flex flex-column">
                            <div class="gc_header">imc</div>
                            <div class="date">{{patient_state?.growth_curve.date | microDate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-6 stats">
                    <div class="imc">
                        <div class="value">{{patient_state?.growth_curve.IMC_2 | number}} <div class="unit">kg/m² </div></div> |
                        <div class="percentil">{{patient_state?.growth_curve.p_IMC}}%</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-12">
            .
        </div>
    </div> -->
</div>