<div class="bg-masterhead d-flex align-items-center" style="height: 100vh">
  <div class="account-pages w-100">
    <div class="container against-footer">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <div class="card shadow" style="border-radius: 25px;">

            <div class="card-body p-4">
              <div class="text-center w-100 m-auto">
                <a routerLink="/">
                  <img src="assets/images/favicon.ico" alt="UNIMED" style="height: 8rem;">
                </a>
                <p class="text-muted mb-2 mt-2">Ingrese su dirección de correo electrónico y le enviaremos las instrucciones para restablecer su contraseña.
                </p>
              </div>

              <form class="needs-validation" name="recoverForm" [formGroup]="recoverForm" (ngSubmit)="onSubmit()" novalidate>

                <app-ui-preloader [display]="loading"></app-ui-preloader>

                <ngb-alert class="radius" type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                <ngb-alert class="radius" type="success" *ngIf="success" [dismissible]="false">{{ success }}</ngb-alert>

                <div class="form-group mb-3" *ngIf="!success">
                  <label for="email">Correo</label>

                  <input type="email" formControlName="email" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="email" placeholder="Correo" />

                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Se requiere correo.</div>
                    <div *ngIf="f.email.errors.email">Se requiere dirección de correo válida.</div>
                  </div>
                </div>

                <div class="form-group mb-0 text-center" *ngIf="!success">
                  <button class="btn btn-primary btn-block radius" type="submit">Enviar</button>
                </div>
              </form>

            </div> <!-- end card-body-->

          </div>

          <div class="row mt-0">
            <div class="col-12 text-center">
              <p class="text-muted">
                Volver a
                <a *ngIf="!loading" routerLink="/conectarse" class="text-primary ml-1"><b>Iniciar Sesión</b></a>
                <a *ngIf="loading" class="text-primary ml-1 pointer" style="opacity: 0.7;"><b>Iniciar Sesión</b></a>
              </p>
            </div> <!-- end col -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer footer-alt">
    <div class="row">
      <div class="col-md-6 text-left text-muted ">
        {{release_year == year ? year : (release_year + " - " + year)}} © UNIMED by <a class="text-primary" target="_blank" href="https://cjalvarez.com">CJAlvarez </a>
      </div>
      <div class="col-md-6">
        <div class="text-md-right footer-links d-none d-sm-block">
          <a class="text-muted" target="_blank" href="https://github.com/CJAlvarez"><i class="fab fa-github"></i> GitHub</a>
        </div>
      </div>
    </div>
  </footer>
</div>