import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2'
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { FormRenderComponent, form_type } from 'src/app/shared/ui/form_render/form_render.component';
import { Subscription } from 'rxjs';
import { PatientService } from 'src/app/services/patient-service.service';
import { AppService } from 'src/app/services/app.service';
import { AccountService } from 'src/app/services/account.service';

@Component({
	selector: 'app-first_login',
	templateUrl: './first_login.component.html',
	styleUrls: ['./first_login.component.scss']
})
export class FirstLoginComponent implements OnInit, OnDestroy {
	private account_subscription: Subscription;

	private password_regex = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/g;
	first_login: boolean;
	view: number;
	completedView: number;
	release_year: number = 2020;
	year: number = new Date().getFullYear();

	public fileUploadControl = new FileUploadControl(FileUploadValidators.filesLimit(1));
	submittedUpload: boolean
	public uploadedFiles: File;
	public image_src: any;
	fileData: File = null;
	fileUploadProgress: string = null;
	uploadedFilePath: string = null;

	@ViewChild('form_general_ref', { static: false }) form_general_ref: FormRenderComponent;
	public form_general_config: form_type;
	@ViewChild('form_change_password_ref', { static: false }) form_change_password_ref: FormRenderComponent;
	public form_change_password_config: form_type;

	public data = {
		general: {
			firstname: "",
			lastname: "",
			gender: "",
			phone: "",
			email: ""
		},
		change_password: {
			password: "",
			confirm_password: "",
		}
	}

	constructor(private endpoint: AppService, private accountService: AccountService, private patientService: PatientService) {
		this.endpoint.pageTitle = 'Configuración de Cuenta';
		this.view = 1;
		this.completedView = 0;
		this.submittedUpload = false;
		this.form_configs()
		this.patientService.clean_patient();
	}

	ngOnInit() {
		this.account_subscription = this.accountService.current.subscribe(
			state => {
				this.data.general = state;
				this.data.general.gender = state.id_gender;
			}
		);
	}
	ngOnDestroy() {
		this.account_subscription.unsubscribe();
	}

	form_configs() {
		this.form_general_config = {
			submit: () => this.next(),
			rows: [
				{
					class: "row",
					cols: [

						{
							name: "firstname",
							type: "name",
							label: "Nombres",
							small: "(primero, segundo, ...)",
							placeholder: () => "Primer, Segundo... Nombre",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
								maxlength: {
									value: () => 100
								}
							},
							disabled: () => false,
						},
						{
							name: "lastname",
							type: "name",
							label: "Apellidos",
							small: "(primero, segundo, ...)",
							placeholder: () => "Primer, Segundo... Apellido",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
								maxlength: {
									value: () => 100
								}
							},
							disabled: () => false,
						},
						{
							name: "email",
							type: "email",
							label: "Correo Electrónico",
							placeholder: () => "alguien@email.com",
							col_class: "col-md-12",
							class: "unstyle no-border",
							validators: {
								required: {
									value: () => false
								},
							},
							disabled: () => true,
						},
						{
							name: "phone",
							type: "phone",
							hn: () => true,
							label: "Teléfono",
							small: "",
							placeholder: () => "#########",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
								maxlength: {
									value: () => 30
								}
							},
							disabled: () => false,
						},
						{
							name: "gender",
							type: "select",
							label: "Género",
							placeholder: () => "- Seleccione -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							catalog: {
								list: () => [{ id: 1, name: "Femenino" }, { id: 2, name: "Maculino" }],
								value: "id",
								text: "name"
							},
							disabled: () => false,
						}
					]
				},
				{
					class: "row",
					cols: [
						{
							type: "buttons",
							col_class: "col-12",
							buttons: [
								{
									class: "btn btn-primary ml-auto",
									label: "Siguiente",
									type: "submit",
									icon: {
										class: "fa-duotone fa-arrow-right",
										position: 'right',
									},
									disabled: () => false,
								},
							]
						},
					],
				},
			]
		}
		this.form_change_password_config = {
			submit: () => this.next(),
			rows: [
				{
					class: "row",
					cols: [
						{
							name: "password",
							type: "password",
							label: "Nueva Contraseña",
							small: "",
							col_class: "col-md-12",
							validators: {
								required: {
									value: () => true
								},
								custom: {
									value: () => this.password_regex.test(this.data.change_password.password),
									error: "Las contraseñas no coinciden"
								}
							},
							disabled: () => false,
						},
						{
							name: "confirm_password",
							type: "password",
							label: "Confirmar Contraseña",
							small: "",
							col_class: "col-md-12",
							validators: {
								required: {
									value: () => true
								},
								custom: {
									value: () => this.data.change_password.password == this.data.change_password.confirm_password,
									error: "Las contraseñas no coinciden"
								}
							},
							disabled: () => false,
						},
					]
				},
				{
					class: "row mt-3",
					cols: [
						{
							type: "buttons",
							col_class: "col-12",
							buttons: [
								{
									class: "btn btn-outline-danger",
									label: "Anterior",
									type: "button",
									icon: {
										class: "fa-duotone fa-arrow-left",
										position: 'left',
									},
									disabled: () => false,
									click: () => this.previous()
								},
								{
									class: "btn btn-primary ml-auto",
									label: "Aceptar",
									type: "submit",
									icon: {
										class: "fa-duotone fa-check",
										position: 'right',
									},
									disabled: () => this.form_change_password_ref ? !this.form_change_password_ref.valid() : false,
								},
							]
						},
					],
				},
			]
		}
	}

	next() {
		if (this.view == 1) {
			if (!this.form_general_ref.valid()) return false;
			this.view = 2;
		} else if (this.view == 2) {
			if (!this.form_change_password_ref.valid()) return false;

			var { firstname, lastname, gender, phone, email } = this.data.general;
			var { password, confirm_password } = this.data.change_password;

			const formData = new FormData();
			if (this.uploadedFiles && this.uploadedFiles[0]) {
				formData.append("file", this.uploadedFiles[0], this.uploadedFiles[0]['name']);
			}
			formData.append("src", this.image_src);
			formData.append("firstname", firstname);
			formData.append("lastname", lastname);
			formData.append("gender", gender);
			formData.append("password", password);
			formData.append("confirm", confirm_password);
			formData.append("phone", phone);
			formData.append("email", email);

			this.endpoint.first_login(formData).subscribe(
				data => {
					this.view = 3;
					this.endpoint.set_session(data);
				},
				err => {
					Swal.fire({
						title: err.error.title,
						text: err.error.message,
						type: 'error',
						timer: 10000,
						confirmButtonText: 'Listo',
						confirmButtonColor: '#66c8c8',
					});
				},
			)
		}
	}

	previous() {
		this.view -= 1;
	}

	finish() {
		this.endpoint.redirect_to('');
	}

	open_file_chooser() {
		this.fileUploadControl.clear();
		this.fileUploadControl.click();
	}

	fileProgress() {
		this.fileData = <File>this.uploadedFiles[0];
		this.preview();
	}

	preview() {
		var mimeType = this.fileData.type;
		if (mimeType.match(/image\/*/) == null) {
			return;
		}

		var reader = new FileReader();
		reader.readAsDataURL(this.fileData);
		reader.onload = (_event) => {
			this.image_src = reader.result;
		}
	}

}
