import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PatientService } from 'src/app/services/patient-service.service';
import { DatatableComponent, datatable_type } from 'src/app/shared/ui/datatable/datatable.component';
import { SearchButtonComponent } from 'src/app/shared/ui/search_button/search_button.component';
import { PageService, PageState } from '../../services/page.service';
import { data_billing_info } from 'src/app/data/billing';
import { AppService } from 'src/app/services/app.service';
import { ConsultGeneralService } from '../expedient/new_consult/general/consult.service';
@Component({
  selector: 'app-my_patients',
  templateUrl: './my_patients.component.html',
  styleUrls: ['./my_patients.component.scss']
})
export class MyPatientsComponent implements OnInit, OnDestroy {
  private page_subscription: Subscription;
  public page_state: PageState;

  public patient_id: any;

  @ViewChild('search_button_ref', { static: false }) search_button_ref: SearchButtonComponent;

  @ViewChild('datatable_ref', { static: false }) datatable_ref: DatatableComponent;
  public datatable: datatable_type;
  public data: any = {
    list: [],
    count: 0,
    skip: 0,
    loaded: false
  };

  constructor(private endpoint: AppService, private patientService: PatientService, private pageService: PageService, private consultService: ConsultGeneralService) {
    this.datatable_config();
  }

  ngOnInit() {
    this.page_subscription = this.pageService.current.subscribe(
      state => this.page_state = state
    );
  }

  ngOnDestroy() {
    this.page_subscription.unsubscribe();
  }

  datatable_config() {
    this.datatable = {
      configs: {
        title: "Pacientes",
        icon: "fa-duotone fa-users",
        paginate: true,
        search: true
      },
      filters: {
        limit: 50,
        skip: 0
      },
      headers: [
        {
          label: "Nombres",
          name: "firstname",
          order_by: "firstname"
        },
        {
          label: "Apellidos",
          name: "lastname",
          order_by: "lastname"
        },
        {
          label: "Identificación",
          name: "identification",
          order_by: "identification",
        },
      ],
      events: [
        {
          name: "Detalles",
          icon: "fa-duotone fa-file-circle-info text-info"
        },
      ]
    };
  }

  update(data) {
    if (this.search_button_ref) data.search_word = this.search_button_ref.data;
    this.endpoint.get_my_patients(data).subscribe(
      data => this.data = data,
      err => console.error(err)
    );
  }

  events(data) {
    if (data.name == "Detalles" || data.name == "row_click") {
      this.consultService.remove_storage();
      this.consultService.full_clean();
      this.patientService.change_patient_id(data.item.id_user);
      data_billing_info.splice(0,data_billing_info.length);
      this.endpoint.redirect_to("/expediente/consultas")
    }
    else if (data.name == "Editar"){
      
    }
    else if (data.name == "Deshabilitar") {
    }
  }

  on_update() {
    if (this.datatable_ref) this.datatable_ref.update();
  }

}
