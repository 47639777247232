import { CatalogsService } from "src/app/services/catalogs.service";
import { CustomClass } from "./custom-class";

export interface ReferencesInterface {
    id: any;
    id_patient: any;
    id_consult: any;
    id_doctors_specialty: any;
    place: string;
    doctor: string;
    doctor_phone?: string;
    cause: string;
    created_at: string;
    created_by: any;
};

interface ToStringInterface {
    place: string;
    doctor: string;
    doctor_phone?: string;
    doctors_specialties: string;
    cause: string;
};

export class ReferencesClass extends CustomClass implements ReferencesInterface {
    public id: any;
    public id_patient: any;
    public id_consult: any;
    public id_doctors_specialty: any;
    public place: string;
    public doctor: string;
    public doctor_phone?: string;
    public cause: string;
    public created_at: string;
    public created_by: any;
    public doctor_search: any;

    constructor(private catalogsService: CatalogsService, data?: ReferencesInterface) {
        super();
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: ReferencesInterface): void {
        super.overwrite(data);
        this.id = data.id;
        this.id_patient = data.id_patient;
        this.id_consult = data.id_consult;
        this.id_doctors_specialty = data.id_doctors_specialty;
        this.place = data.place;
        this.doctor = data.doctor;
        this.doctor_phone = data.doctor_phone;
        this.cause = data.cause;
        this.created_at = data.created_at;
        this.created_by = data.created_by;
    }

    reset(): void {
        super.reset();
        this.id = null;
        this.id_patient = null;
        this.id_consult = null;
        this.id_doctors_specialty = null;
        this.place = "";
        this.doctor = "";
        this.doctor_phone = "";
        this.cause = "";
        this.created_at = null;
        this.created_by = null;
    }

    format(): ReferencesInterface {
        return this as ReferencesInterface;
    }

    valid(): boolean {
        return true;
    }

    to_string(): ToStringInterface {
        let doctors_specialties = this.catalogsService.catalogs.doctors_specialties.find(v => v.id == this.id_doctors_specialty);

        let data: ToStringInterface = {
            doctors_specialties: doctors_specialties ? doctors_specialties.name : "",
            place: this.place,
            doctor: this.doctor,
            doctor_phone: this.doctor_phone,
            cause: this.cause,
        };
        return data as ToStringInterface;
    }

    raw(): ReferencesInterface {
        return {
            id: this.id,
            id_patient: this.id_patient,
            id_consult: this.id_consult,
            id_doctors_specialty: this.id_doctors_specialty,
            place: this.place,
            doctor: this.doctor,
            doctor_phone: this.doctor_phone,
            cause: this.cause,
            created_at: this.created_at,
            created_by: this.created_by,
        };
    }
}
