import { Component } from '@angular/core';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-relations_show_tutors',
  templateUrl: './show_tutors.component.html',
  styleUrls: ['./show_tutors.component.scss']
})
export class ShowRelationsTutorsComponent {

  constructor(private pageService: PageService) {
  }

  close() {
    this.pageService.closeModal("");
  }

}
