import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { SocketService } from 'src/app/services/socket.service';

export type message = {
  id?: any,
  subject?: "from" | "to",
  status?: any,
  id_type?: any,
  content: string,
  id_conversation?: any,
  created_by?: any,

  created_at?: string | Date,
  sent_at?: string,
  delivered_at?: string,
  read_at?: string
};

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
  public message: message;

  public messages: message[];

  @Input() data: any;
  
  public avatar_configs = {
      size: "2.5rem",
      font_size: "1.5rem",
  };

  constructor(private endpoint: AppService, private toastr: ToastrService, private pageService: PageService, private socketService: SocketService) {
    this.clean();
    this.messages = [];
  }

  ngOnInit() {
    this.get_messages();
    this.init_socket();
  }

  ngOnDestroy() {
  }

  init_socket() {
    this.socketService.getMessage().subscribe(
      _ => {
        this.get_messages();
      },
      error => {
        console.error(error)
      }
    );
  }

  get_messages() {
    let load = {
      id_user: this.data.id_user
    };
    this.endpoint.chat_get_messages(load).subscribe(
      data => {
        this.messages = data;
      },
      error => {
        console.error(error)
        this.toastr.error(
          error.error && error.error.message ? error.error.message : error.message,
          error.error && error.error.title ? error.error.title : error.status + ''
        );
      }
    );
  }

  send_message() {
    let load = {
      id_user: this.data.id_user,
      id_type: this.message.id_type,
      content: this.message.content.trim(),
      created_at: this.message.created_at,
    };
    this.endpoint.chat_send_message(load).subscribe(
      _ => {
        this.clean();
      },
      error => {
        console.error(error)
        this.toastr.error(
          error.error && error.error.message ? error.error.message : error.message,
          error.error && error.error.title ? error.error.title : error.status + ''
        );
      }
    );
  }

  load_image(url: string) {
    return this.endpoint.get_file_endpoint("profile_images", url);
  }

  close() {
    this.pageService.closeModal(true);
  }

  clean() {
    this.message = {
      content: ""
    };
  }

  submit() {
    if (!(this.message.content.trim().length > 0)) return;
    (new Date()).toLocaleTimeString()

    this.message.created_at = new Date().toISOString().slice(0, 19) + "-06:00";
    console.log("FIX THIS CJ")
    console.log(this.message.created_at)
    this.message.subject = "from";
    this.message.status = 1;
    this.message.id_type = 1;
    this.messages.push(this.message);
    this.send_message();
  }

}
