import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { PatientService, patient_state } from 'src/app/services/patient-service.service';
import { PageService, PageState } from '../../../../services/page.service';
import { CatalogsService } from 'src/app/services/catalogs.service';

@Component({
  selector: 'app-allergies_brief',
  templateUrl: './allergies_brief.component.html',
  styleUrls: ['./allergies_brief.component.scss']
})
export class AllergiesBriefComponent implements OnInit, OnDestroy {
  private patient_subscription: Subscription;
  public patient_state: patient_state;
  public show: boolean = false;

  constructor(private endpoint: AppService, private patientService: PatientService, private pageService: PageService, private catalogsService: CatalogsService) {
  }

  ngOnInit() {
    this.patient_subscription = this.patientService.patient_data_subscription.subscribe(
      data => this.patient_state = data
    );
  }

  ngOnDestroy() {
    this.patient_subscription.unsubscribe();
  }

  catalog() {
    return {
      list: () => this.catalogsService.catalogs.allergies_reactions_levels,
      text: "name",
      value: "id"
    }
  }

}
