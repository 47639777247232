/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./preloader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./preloader.component";
var styles_PreloaderComponent = [i0.styles];
var RenderType_PreloaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreloaderComponent, data: {} });
export { RenderType_PreloaderComponent as RenderType_PreloaderComponent };
export function View_PreloaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "preloader"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "status"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "spinner-border avatar-sm text-primary m-2"], ["role", "status"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.display; _ck(_v, 0, 0, currVal_0); }); }
export function View_PreloaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ui-preloader", [], null, null, null, View_PreloaderComponent_0, RenderType_PreloaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.PreloaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreloaderComponentNgFactory = i1.ɵccf("app-ui-preloader", i2.PreloaderComponent, View_PreloaderComponent_Host_0, { display: "display" }, {}, []);
export { PreloaderComponentNgFactory as PreloaderComponentNgFactory };
