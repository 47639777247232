import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { ConsultGeneralService } from "src/app/pages/expedient/new_consult/general/consult.service";
import { PatientService } from "src/app/services/patient-service.service";
import { PageService } from "../../services/page.service";

@Injectable({
    providedIn: "root",
})
export class InConsultGuard implements CanActivate {
    constructor(private pageService: PageService, private consultService: ConsultGeneralService, private patientService: PatientService) {}

    canActivate() {
        localStorage.setItem("inConsult", "true");

        this.pageService.set_consult(false);
        this.pageService.set_profile(false);
        this.pageService.set_expedient(true);
        this.pageService.sidebar_3((data) => {
            data.background = false;
            data.shadow = true;
        });
        this.consultService.load_storage();
        return true;
    }
}
