<span
  [class.show]="show()"
  (mouseenter)="display = true"
  (mouseleave)="display = false"
  class="search-button">
  <i class="fa-duotone fa-search"></i>
  <input
    autocomplete="off"
    #input
    (input)="on_input()"
    (submit)="on_submit()"
    (change)="on_change()"
    name="search_word"
    id="search_word"
    placeholder="Buscar "
    [(ngModel)]="data"
    type="search"
    class="form-control">
</span>