import { Component } from '@angular/core';
import { ExpedientService } from 'src/app/services/expedient.service';
import { AllergiesService } from '../allergies.service';

@Component({
  selector: 'app-background_create_allergies',
  templateUrl: './create_allergies.component.html',
  styleUrls: ['./create_allergies.component.scss']
})
export class CreateBackgroundAllergiesComponent {

  constructor(private moduleService: AllergiesService, private expedientService: ExpedientService) {
    this.expedientService.change_method("insert");
    this.moduleService.init();
  }

  close() {
    this.moduleService.cancel();
  }

}
