import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { FormRenderComponent } from "src/app/shared/ui/form_render/form_render.component";
import { PageService } from "src/app/services/page.service";
import { AppService } from "src/app/services/app.service";
import { ExpedientService } from "src/app/services/expedient.service";
import Swal from "sweetalert2";
import { SocketService } from "src/app/services/socket.service";
import { AlertService } from "src/app/shared/ui/alert_service/alert.service";
import { NewPatientService } from "src/app/services/new_patient.service";

export interface GeneralForms {
    general: FormRenderComponent;
}

export interface GeneralState {
    id_user: any;
    firstname: string;
    lastname: string;
    id_gender: any;
    birthdate: any;
    birthplace: string;
    id_country: any;
    id_department: any;
    id_town: any;
    address: string;
    tutor_email: string;
    id_education: any;
    identification: string;
    id_blood: any;
    organ_donor: any;
    glasses: any;
    minor: any;

    // PHONE VERIFY
    phone: string;
    v_phone: string;
    verified_phone: number;
    phoneMessageSid: string;

    // EMAIL VERIFY
    email: string;
    v_email: string;
    emailToken: string;
    verified_email: number;

    // IMAGE UPLOAD
    image: string | ArrayBuffer;
    image_upload?: any;

    loading: boolean;
    submitted: boolean;
    timestamp: Date;
}

@Injectable({ providedIn: "root" })
export class GeneralService {
    // FORMS
    public forms: GeneralForms;
    private forms_state: BehaviorSubject<GeneralForms>;
    public obv_forms: Observable<GeneralForms>;

    // GENERAL
    private current_state: GeneralState;
    private module_state: BehaviorSubject<GeneralState>;
    public current: Observable<GeneralState>;

    private store_name = "unimed-expedient-general";

    constructor(
        private alertService: AlertService,
        private endpoint: AppService,
        private toastr: ToastrService,
        private pageService: PageService,
        private socketService: SocketService,
        private patientService: NewPatientService,
        private expedientService: ExpedientService
    ) {
        this.init();
        this.init_socket();
    }

    init() {
        this.expedientService.set_expedient_service({ submit: callback => this.submit(callback), validate: (callback) => this.validate(callback) }, "general");
        let storage = null; //localStorage.getItem(this.store_name);
        if (storage) {
            this.current_state = JSON.parse(storage) as GeneralState;
            this.current_state.loading = false;
            this.current_state.submitted = false;
            this.current_state.timestamp = new Date();
        } else this.internal_clean();
        this.next();

        this.forms = {
            general: null,
        };

        this.forms_state = new BehaviorSubject(this.forms);
        this.obv_forms = this.forms_state.asObservable();

        if (this.expedientService.state.version == "expedient") {
            this.expedientService.change_method("show");
        }

        if (this.expedientService.state.method != "insert") {
            this.reload();
        }
    }

    init_socket() {
        this.socketService.getUserPhoneVerify().subscribe(
            (data: any) => {
                if (this.current_state.phoneMessageSid === data.sid) {
                    if (data.message === "Sí, confirmo") {
                        this.current_state.verified_phone = 1;
                        this.current_state.v_phone = this.current_state.phone;
                        this.alertService.alert_success("¡Listo!", "Has verificado tu teléfono.");
                    } else if (data.message === "No confirmo") {
                        this.current_state.verified_phone = 2;
                        this.alertService.alert_error("¡Oh no!", "Has rechazado la verificación de tu teléfono.");
                    }
                }
            },
            error => {
                console.error(error)
            }
        );
        this.socketService.getUserEmailVerify().subscribe(
            (data: any) => {
                if (this.current_state.emailToken === data.token) {
                    this.current_state.verified_email = 1;
                    this.current_state.v_email = this.current_state.email;
                    this.alertService.alert_success("¡Listo!", "Has verificado tu correo electrónico.");
                }
            },
            error => {
                console.error(error)
            }
        );
    }

    reload() {
        if (this.expedientService.state.id_user) {
            this.get_patient_data();
        }
    }

    get_current_state() {
        return this.current_state;
    }

    get_patient_data() {
        let load = {
            id_user: this.expedientService.state.id_user,
        };
        this.endpoint.expedient_general_get_general(load).subscribe(
            (data: GeneralState) => {
                // data.image = this.endpoint.get_file_endpoint(
                //     "profile_images",
                //     data.image
                // );
                this.current_state = data;
            },
            error => console.error(error),
            () => {
                this.save();
                this.next();
            }
        );
    }

    refresh_forms() {
        this.forms_state.next(this.forms);
    }

    next() {
        if (this.module_state) this.module_state.next(this.current_state);
        else {
            this.module_state = new BehaviorSubject(this.current_state);
            this.current = this.module_state.asObservable();
        }
    }

    valid() {
        return !this.current_state.loading && this.forms && this.forms.general && this.forms.general.valid
            ? this.forms.general.valid()
            : false;
    }

    set_form(form: { name: string; module: FormRenderComponent }) {
        this.forms[form.name] = form.module;
    }

    save() {
        localStorage.setItem(this.store_name, JSON.stringify(this.current_state));
    }

    submit(callback = (callback_data?: any) => { }) {
        if (!(this.forms && this.forms.general && this.forms.general.valid()) || this.current_state.loading) {
            callback();
            return false;
        }

        this.current_state.submitted = true;
        this.current_state.loading = true;
        this.module_state.next(this.current_state);

        const formData = new FormData();

        if (this.current_state.image_upload) {
            formData.append("image_upload", this.current_state.image_upload, this.current_state.image_upload.name);
        }

        for (const item in this.current_state) {
            if (item != "image_upload" && this.current_state[item]) {
                formData.append(item, this.current_state[item]);
            }
        }

        if (this.expedientService.state.method == "insert") {
            this.insert(formData, callback);
        } else {
            this.update(formData, callback);
        }
    }

    insert(formData: FormData, callback = (callback_data?: any) => { }) {
        this.endpoint.expedient_general_insert_general(formData).subscribe(
            data => {
                if (this.expedientService.state.version == "expedient") {
                    localStorage.removeItem("inExpedientGeneral");
                    this.toastr.success(data["message"], data["title"]);

                    this.current_state.loading = false;

                    this.expedientService.update_global();
                    this.module_state.next(this.current_state);
                    if (this.forms.general) this.forms.general.unsubmit();
                    this.clean();
                    callback(data);
                } else if (this.expedientService.state.version == "new_patient") {
                    callback(data);
                }
            },
            error => {
                console.error(error);
                this.toastr.error(
                    error.error && error.error.message ? error.error.message : error.message,
                    error.error && error.error.title ? error.error.title : error.status + ""
                );
                this.current_state.loading = false;
                this.module_state.next(this.current_state);
                callback(error);
            }
        );
    }

    update(formData: FormData, callback = (callback_data?: any) => { }) {
        this.endpoint.expedient_general_update_general(formData).subscribe(
            data => {
                localStorage.removeItem("inExpedientGeneral");
                this.toastr.success(data["message"], data["title"]);
                this.current_state.loading = false;

                this.expedientService.update_global();
                this.module_state.next(this.current_state);
                if (this.forms.general) this.forms.general.unsubmit();
                this.expedientService.change_method("show");

                this.reload();
                callback();
            },
            error => {
                console.error(error);
                this.toastr.error(
                    error.error && error.error.message ? error.error.message : error.message,
                    error.error && error.error.title ? error.error.title : error.status + ""
                );
                this.current_state.loading = false;
                this.module_state.next(this.current_state);
            }
        );
    }

    cancel() {
        this.pageService.cancel_new_patient(() => {
            this.clean();
            this.endpoint.redirect_to("/mis_pacientes");
        });
    }

    clean() {
        this.internal_clean();
        this.module_state.next(this.current_state);
        this.save();
    }


    validate(callback = (callback_data?: any) => { }) {
        let birthdate = this.current_state.birthdate;
        birthdate.setHours(0, 0, 0, 1);
        let date = new Date();
        date.setHours(23, 59, 59, 999);

        this.current_state.minor = this.diferenciaDeAniosMenorQue(birthdate, date, 18);
        callback(this.current_state);
    }

    internal_clean() {
        this.current_state = {
            id_user: "",
            id_country: "97",
            id_department: "",
            id_town: "",
            id_education: "",
            id_gender: "1",
            minor: false,

            firstname: "",
            lastname: "",
            birthdate: "",
            birthplace: "",
            address: "",
            image: null,
            image_upload: "",
            tutor_email: "",
            identification: "",
            id_blood: "2",
            organ_donor: [],
            glasses: [],

            phone: "",
            v_phone: "",
            phoneMessageSid: "",
            verified_phone: 2,

            email: "",
            v_email: "",
            emailToken: "",
            verified_email: 2,

            loading: false,
            submitted: false,
            timestamp: new Date(),
        };

        this.patientService.set_attribute(this.current_state, "general")
    }

    verify_phone() {
        Swal.fire({
            title: "Verificar Teléfono",
            html: `La verificación del teléfono fue enviada por Whatsapp al <strong>${this.current_state.phone}</strong>`,
            showCloseButton: true,
            onBeforeOpen: () => {
                Swal.showLoading();

                let load = {
                    phone: this.current_state.phone,
                    name: this.current_state.firstname + " " + this.current_state.lastname
                };
                this.endpoint.whatsapp_send_send_user_verify_phone(load).subscribe(
                    data => {
                        this.current_state.phoneMessageSid = data;
                    },
                    error => {
                        console.error(error);
                        this.toastr.error(
                            error.error && error.error.message ? error.error.message : error.message,
                            error.error && error.error.title ? error.error.title : error.status + ""
                        );
                    }
                );
            },
            allowOutsideClick: () => !Swal.isLoading(),
        });
        return;
    }

    verify_email() {
        Swal.fire({
            title: "Verificar Correo Electrónico",
            html: `La verificación del correo electrónico fue enviada al <strong>${this.current_state.email}</strong>`,
            showCloseButton: true,
            onBeforeOpen: () => {
                Swal.showLoading();

                let load = {
                    email: this.current_state.email,
                };
                this.endpoint.settings_security_send_verify_email(load).subscribe(
                    data => {
                        this.current_state.emailToken = data;
                    },
                    error => {
                        console.error(error);
                        this.toastr.error(
                            error.error && error.error.message ? error.error.message : error.message,
                            error.error && error.error.title ? error.error.title : error.status + ""
                        );
                    }
                );
            },
            allowOutsideClick: () => !Swal.isLoading(),
        });
        return;
    }

    diferenciaDeAniosMenorQue(fecha1, fecha2, numero) {
        const fechaInicio = +new Date(fecha1);
        const fechaFin = +new Date(fecha2);

        const diferenciaEnMilisegundos = fechaFin - fechaInicio;
        const milisegundosEnUnAnio = 1000 * 60 * 60 * 24 * 365.25;

        const diferenciaEnAnios = diferenciaEnMilisegundos / milisegundosEnUnAnio;

        return diferenciaEnAnios < numero;
    }
}
