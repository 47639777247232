<div class="navbar-custom d-flex" [class.searching]="page_state.searching">
    <ul class="list-unstyled topnav-menu m-0">
        <li>
            <button
                class="button-menu-mobile waves-effect waves-light"
                (click)="sidebar_1_toggle()"
            >
                <i class="fa-duotone fa-bars"></i>
            </button>
        </li>
    </ul>

    <div class="logo-box ml-1">
        <a class="logo">
            <span class="logo-lg">
                <img
                    src="assets/images/long-logo.svg"
                    alt=""
                    height="30"
                />
            </span>
            <span class="logo-sm">
                <img
                    src="assets/images/long-logo.svg"
                    alt=""
                    height="30"
                />
                <!-- <img src="assets/images/favicon.ico" alt="" height="42"> -->
            </span>
        </a>
    </div>

    <ul class="list-unstyled topnav-menu mx-auto m-0">
        <li class="d-none d-sm-block">
            <form class="app-search">
                <div class="app-search-box">
                    <div class="input-group input-group-sm">
                        <div class="input-group-append mx-auto">
                            <input
                                readonly
                                type="text"
                                class="form-control form-control-sm"
                                (click)="click_search()"
                                placeholder="Buscar paciente Unimed"
                                style="cursor: pointer; height: 100%; min-width: 12rem;"
                            />
                            <button
                                class="btn"
                                type="button"
                                (click)="click_search()"
                            >
                                <i class="fa-duotone fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </li>
    </ul>

    <ul class="list-unstyled topnav-menu ml-auto mb-0" *ngIf="account_state">
        <li class="dropdown" ngbDropdown>
            <a
                class="nav-link nav-user mr-0 waves-effect waves-light d-flex"
                ngbDropdownToggle
                id="profileDropdown"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
            >
                <span class="pro-user-name mr-1">
                    {{ account_state.firstname }}
                </span>

                <div
                    class="d-flex my-auto"
                    style="
                        height: 2.5rem;
                        width: 2.5rem;
                        border-radius: 50%;
                        overflow: hidden;
                    "
                >
                    <avatar
                        [name]="account_state?.firstname"
                        [image]="account_state?.image"
                        [configs]="avatar_configs"
                    ></avatar>
                </div>
            </a>
            <div
                class="dropdown-menu dropdown-menu-right profile-dropdown"
                aria-labelledby="profileDropdown"
                ngbDropdownMenu
            >
                <!-- item-->
                <a
                    href="javascript:void(0);"
                    class="dropdown-item dropdown-topbar-item"
                    routerLink="/perfil"
                >
                    <i class="fa-duotone fa-user"></i>
                    <span> Perfil </span>
                </a>

                <div class="dropdown-divider"></div>

                <!-- item-->
                <a
                    class="dropdown-item dropdown-topbar-item pointer"
                    (click)="logout()"
                >
                    <i class="fa-duotone fa-power-off text-danger"></i>
                    <span> Cerrar Sesión </span>
                </a>
            </div>
        </li>
    </ul>
</div>
