import { Component, Input } from "@angular/core";

@Component({
    selector: "app-hovered_sidebar",
    templateUrl: "./hovered_sidebar.component.html",
    styleUrls: ["./hovered_sidebar.component.scss"],
})
export class HoveredSidebarComponent {
    @Input("config") config: hovered_sidebar_type;

    constructor() { }
}

export type hovered_sidebar_type = {
    lock_expanded?: boolean;
    list: tab_type[];
    style: "shadowbox" | "border" |"no-border"
};

export type tab_type = {
    name: Function;
    url?: Function;
    icon: Function;
    exact?: Function;
    disabled?(): void;
    click?(): void;
    active?(): boolean;
    show?(): boolean;
};
