import { Component } from '@angular/core';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-background_show_allergies',
  templateUrl: './show_allergies.component.html',
  styleUrls: ['./show_allergies.component.scss']
})
export class ShowBackgroundAllergiesComponent {

  constructor(private pageService: PageService) {
  }

  close() {
    this.pageService.closeModal("");
  }

}
