import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { FormRenderComponent, form_type } from "src/app/shared/ui/form_render/form_render.component";
import { FileUploadControl, FileUploadValidators } from "@iplab/ngx-file-upload";
import { GeneralService, GeneralState } from "../general.service";
import { AppService } from "src/app/services/app.service";
import { ExpedientMetadata, ExpedientService } from "src/app/services/expedient.service";
import { CatalogsService } from "src/app/services/catalogs.service";

@Component({
    selector: "app-module-form",
    templateUrl: "./form.component.html",
    styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit, OnDestroy, AfterViewInit {
    private expedient_subscription: Subscription;
    public expedient_state: ExpedientMetadata;

    private general_subscription: Subscription;
    public general_state: GeneralState;

    public avatar_configs = { size: "8rem" };

    @ViewChild("form_ref", { static: false }) form_ref: FormRenderComponent;
    public form_config: form_type;

    public fileUploadControl = new FileUploadControl(FileUploadValidators.filesLimit(1));
    public submittedUpload: boolean;
    public uploadedFiles: File;
    public fileUploadProgress: string = null;
    public uploadedFilePath: string = null;

    public current_date = new Date();

    constructor(
        private moduleService: GeneralService,
        private endpoint: AppService,
        private expedientService: ExpedientService,
        private catalogsService: CatalogsService
    ) { }

    ngOnInit() {
        this.expedient_subscription = this.expedientService.current.subscribe(state => {
            this.expedient_state = state;
            this.moduleService.reload();
            this.form_configs();
        });
        this.general_subscription = this.moduleService.current.subscribe(state => (this.general_state = state));
        this.form_configs();
    }

    ngAfterViewInit() {
        if (this.general_state) {
            this.moduleService.forms.general = this.form_ref;
        }
    }

    ngOnDestroy() {
        this.expedient_subscription.unsubscribe();
        this.general_subscription.unsubscribe();
    }

    public cat_organ_donor = [{ id: 1, name: "Donador de Órganos" }];
    public cat_glasses = [{ id: 1, name: "Uso de lentes" }];

    form_configs() {
        this.form_config = {
            pretty_view: () => this.expedient_state.version == "expedient" && this.expedient_state.method == "show",
            disabled: () => this.expedient_state.version == "expedient" && this.expedient_state.method == "show",
            submit: callback => this.moduleService.submit(callback),
            version: 2,
            rows: [
                {
                    class: "row",
                    cols: [
                        {
                            type: "divider",
                            label: "Demográficos",
                            col_class: "col-12 mb-2",
                        },
                        {
                            name: "firstname",
                            type: "name",
                            label: "Nombres",
                            small: "(primero, segundo, ...)",
                            placeholder: () => "Primer, Segundo... Nombre",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: () => true,
                                },
                                maxlength: {
                                    value: () => 100,
                                },
                            },
                            disabled: () => false,
                            change: () => {
                                this.general_state.firstname = this.general_state.firstname.trim();
                            },
                            input: () => (this.general_state.firstname = this.general_state.firstname.trim()),
                        },
                        {
                            name: "lastname",
                            type: "name",
                            label: "Apellidos",
                            small: "(primero, segundo, ...)",
                            placeholder: () => "Primer, Segundo... Apellido",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: () => true,
                                },
                                maxlength: {
                                    value: () => 100,
                                },
                            },
                            disabled: () => false,
                            change: () => {
                                this.general_state.lastname = this.general_state.lastname.trim();
                            },
                            input: () => (this.general_state.lastname = this.general_state.lastname.trim()),
                        },

                        {
                            name: "birthdate",
                            type: "calendar",
                            label: "Fecha de Nacimiento",
                            placeholder: () => "##/##/####",
                            col_class: "col-md-4",
                            validators: {
                                required: {
                                    value: () => true,
                                },
                                max: {
                                    value: () => this.current_date,
                                },
                            },
                            disabled: () => false,
                        },
                        {
                            name: "identification",
                            type: "identification",
                            label: "Identificación",
                            hn: () => this.general_state.id_country == 97,
                            placeholder: () => "####-####-#####",
                            col_class: "col-md-4",
                            class: this.expedient_state.version != "new_patient" ? "unstyle no-border" : "",
                            validators: {
                                required: {
                                    value: () => true,
                                },
                            },
                            disabled: () => this.expedient_state.version != "new_patient",
                        },
                        {
                            name: "id_country",
                            type: "select",
                            label: "Nacionalidad",
                            placeholder: () => "- Seleccione -",
                            col_class: "col-md-4",
                            validators: {
                                required: {
                                    value: () => true,
                                },
                            },
                            catalog: {
                                list: () => this.catalogsService.catalogs.countries,
                                value: "id",
                                text: "name",
                            },
                            disabled: () => false,
                        }, {
                            name: "id_gender",
                            type: "radio",
                            label: "Género",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: () => true,
                                },
                            },
                            catalog: {
                                list: () => this.catalogsService.catalogs.genders,
                                value: "id",
                                text: "name",
                            },
                            disabled: () => false,
                            change: () => {
                                this.moduleService.save();
                            },
                        },
                        {
                            type: "divider",
                            label: "",
                            bar: true,
                            col_class: "col-12 mb-3",
                        },

                        {
                            name: "phone",
                            type: "phone",
                            hn: () => this.general_state.id_country == "97",
                            label: "Teléfono",
                            small: "",
                            placeholder: () => "#########",
                            col_class: "col-md-5",
                            validators: {
                                required: {
                                    value: () => false,
                                },
                                maxlength: {
                                    value: () => 30,
                                },
                            },
                            input: () => {
                                this.general_state.verified_phone =
                                    !this.general_state.phone || this.general_state.v_phone != this.general_state.phone
                                        ? 2
                                        : 1;
                            },
                            change: () => {
                                this.general_state.verified_phone =
                                    !this.general_state.phone || this.general_state.v_phone != this.general_state.phone
                                        ? 2
                                        : 1;
                            },
                            disabled: () => false,
                        },
                        {
                            name: "verified_phone",
                            type: "select",
                            label: "Verificado",
                            small: "",
                            placeholder: () => "#########",
                            col_class: "col-md-5",
                            class: "unstyle no-border",
                            validators: {
                                required: {
                                    value: () => false,
                                },
                            },
                            catalog: {
                                list: () => this.catalogsService.catalogs.tf,
                                text: "name",
                                value: "id",
                            },
                            disabled: () => true,
                        },
                        {
                            type: "buttons",
                            col_class: "col-md-2",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-primary mx-auto",
                                    label: "Verificar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: "right",
                                    },
                                    show: () => this.expedient_state.method != "show",
                                    click: () => {
                                        this.moduleService.verify_phone();
                                    },
                                    disabled: () =>
                                        !this.general_state.phone ||
                                        this.general_state.verified_phone == 1 ||
                                        this.general_state.loading,
                                },
                            ],
                        },
                        {
                            name: "email",
                            type: "email",
                            label: "Correo Electrónico",
                            small: "",
                            placeholder: () => "alguien@email.com",
                            col_class: "col-md-5",
                            validators: {
                                required: {
                                    value: () => false,
                                },
                            },
                            input: () => {
                                this.general_state.verified_email =
                                    !this.general_state.email || this.general_state.v_email != this.general_state.email
                                        ? 2
                                        : 1;
                            },
                            change: () => {
                                this.general_state.verified_email =
                                    !this.general_state.email || this.general_state.v_email != this.general_state.email
                                        ? 2
                                        : 1;
                            },
                            disabled: () => false,
                        },
                        {
                            name: "verified_email",
                            type: "select",
                            label: "Verificado",
                            small: "",
                            placeholder: () => "#########",
                            col_class: "col-md-5",
                            class: "unstyle no-border",
                            validators: {
                                required: {
                                    value: () => false,
                                },
                            },
                            catalog: {
                                list: () => this.catalogsService.catalogs.tf,
                                text: "name",
                                value: "id",
                            },
                            disabled: () => true,
                        },
                        {
                            type: "buttons",
                            col_class: "col-md-2",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-primary mx-auto",
                                    label: "Verificar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: "right",
                                    },
                                    show: () => this.expedient_state.method != "show",
                                    click: () => {
                                        this.moduleService.verify_email();
                                    },
                                    disabled: () =>
                                        !this.general_state.email ||
                                        this.general_state.verified_email == 1 ||
                                        this.general_state.loading,
                                },
                            ],
                        },
                        {
                            type: "divider",
                            label: "",
                            bar: true,
                            col_class: "col-12 mb-3",
                        },

                        {
                            name: "address",
                            type: "textarea",
                            label: "Dirección",
                            small: "",
                            rows: 2.5,
                            placeholder: () => "...",
                            col_class: "col-12",
                            validators: {
                                required: {
                                    value: () => false,
                                },
                                maxlength: {
                                    value: () => 500,
                                },
                            },
                            disabled: () => false,
                        },
                        {
                            name: "id_department",
                            type: "select",
                            label: "Departamento",
                            placeholder: () => "- Seleccione -",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: () => false,
                                },
                            },
                            catalog: {
                                list: () => this.catalogsService.catalogs.departments,
                                value: "id",
                                text: "name",
                            },
                            disabled: () => false,
                            change: () => {
                                this.general_state.id_town = "";
                            },
                        },
                        {
                            name: "id_town",
                            type: "select",
                            label: "Municipio",
                            placeholder: () =>
                                this.general_state.id_department ? "- Seleccione -" : "- Seleccione un Departamento -",
                            col_class: "col-md-6",
                            validators: {
                                required: {
                                    value: () => false,
                                },
                            },
                            catalog: {
                                list: () =>
                                    this.catalogsService.catalogs.towns.filter(
                                        value => value.id_department == this.general_state.id_department
                                    ),
                                value: "id",
                                text: "name",
                            },
                            disabled: () => !this.general_state.id_department,
                        },
                        {
                            type: "divider",
                            label: "",
                            bar: true,
                            col_class: "col-12 mb-3",
                        },


                        {
                            name: "id_blood",
                            type: "select",
                            label: "Tipo de Sangre",
                            col_class: "col-md-2",
                            placeholder: () => "--",
                            validators: {
                                required: {
                                    value: () => false,
                                },
                            },
                            catalog: {
                                list: () => this.catalogsService.catalogs.bloods,
                                value: "id",
                                text: "name",
                            },
                            disabled: () => false,
                        },
                        {
                            name: "organ_donor",
                            type: "checkbox",
                            label: "",
                            col_class: "col-md-5",
                            validators: {
                                required: {
                                    value: () => false,
                                },
                            },
                            catalog: {
                                list: () => this.cat_organ_donor,
                                value: "id",
                                text: "name",
                            },
                            disabled: () => false,
                        },
                        {
                            name: "glasses",
                            type: "checkbox",
                            label: "",
                            col_class: "col-md-5",
                            validators: {
                                required: {
                                    value: () => false,
                                },
                            },
                            catalog: {
                                list: () => this.cat_glasses,
                                value: "id",
                                text: "name",
                            },
                            disabled: () => false,
                        },
                        // {
                        //     name: "id_education",
                        //     type: "select",
                        //     label: "Escolaridad",
                        //     placeholder: () => "- Seleccione -",
                        //     col_class: "col-md-6",
                        //     validators: {
                        //         required: {
                        //             value: () => false,
                        //         },
                        //     },
                        //     catalog: {
                        //         list: () => this.catalogsService.catalogs.educations,
                        //         value: "id",
                        //         text: "name",
                        //     },
                        //     disabled: () => false,
                        // },

                    ],
                },
                {
                    class: "row mt-2",
                    cols: [
                        {
                            type: "buttons",
                            col_class: "col-12",
                            buttons: [
                                {
                                    class: "btn btn-sm btn-warning ml-auto",
                                    label: "Editar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-edit",
                                        position: "right",
                                    },
                                    show: () => {
                                        return (
                                            this.expedient_state.method == "show" &&
                                            this.expedient_state.version != "new_patient"
                                        );
                                    },
                                    click: () => {
                                        this.expedientService.change_method("update");
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-outline-danger",
                                    label: "Cancelar",
                                    type: "button",
                                    icon: {
                                        class: "fa-duotone fa-times",
                                        position: "left",
                                    },
                                    show: () => {
                                        return (
                                            this.expedient_state.method == "update" &&
                                            this.expedient_state.version != "new_patient"
                                        );
                                    },
                                    click: () => {
                                        this.expedientService.change_method("show");
                                    },
                                },
                                {
                                    class: "btn btn-sm btn-success ml-auto",
                                    label: "Guardar",
                                    type: "submit",
                                    icon: {
                                        class: "fa-duotone fa-check",
                                        position: "right",
                                    },
                                    show: () => {
                                        return (
                                            (this.expedient_state.method == "update" ||
                                                this.expedient_state.method == "insert") &&
                                            this.expedient_state.version != "new_patient"
                                        );
                                    },
                                    disabled: () =>
                                        (this.form_ref ? !this.form_ref.valid() : false) || this.general_state.loading,
                                },
                            ],
                        },
                    ],
                },
            ],
        };
    }

    open_file_chooser() {
        if (this.expedient_state.method != "show") {
            this.fileUploadControl.clear();
            this.fileUploadControl.click();
        }
    }

    fileProgress(event) {
        this.general_state.image_upload = <File>this.uploadedFiles[0];
        this.preview();
    }

    preview() {
        var mimeType = this.general_state.image_upload.type;
        if (mimeType.match(/image\/*/) == null) {
            return;
        }

        var reader = new FileReader();
        reader.readAsDataURL(this.general_state.image_upload);
        reader.onload = _event => {
            this.general_state.image = reader.result;
        };
    }
}
