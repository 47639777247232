import { Component } from '@angular/core';
import { EmergencyService } from '../emergency.service';

@Component({
  selector: 'app-contacts_update_emergency',
  templateUrl: './update_emergency.component.html',
  styleUrls: ['./update_emergency.component.scss']
})
export class UpdateContactsEmergencyComponent {

  constructor(private moduleService: EmergencyService) {
  }

  close() {
    this.moduleService.cancel();
  }

}
