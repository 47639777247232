import { Routes } from '@angular/router';
const ɵ0 = () => import("./pathologies/pathologies.module.ngfactory").then(m => m.BackgroundPathologiesModuleNgFactory), ɵ1 = () => import("./allergies/allergies.module.ngfactory").then(m => m.BackgroundAllergiesModuleNgFactory);
const routes = [
    {
        path: '',
        redirectTo: 'patologicos',
        pathMatch: 'prefix'
    },
    {
        path: 'patologicos',
        loadChildren: ɵ0
    },
    {
        path: 'alergicos',
        loadChildren: ɵ1
    },
];
export class BackgroundModule {
}
export { ɵ0, ɵ1 };
