<div id="wrapper">
    <app-topbar></app-topbar>
    <app-sidebar></app-sidebar>
    <app-second-sidebar></app-second-sidebar>
    <div
         class="content-page"
         [class.show]="page_state.sidebar_2.show"
         [class.expand]="page_state.sidebar_2.expand"
         [class.searching]="page_state.searching">
        <div class="content">
            <app-third-sidebar></app-third-sidebar>
        </div>
    </div>
</div>

<div class="overlay-internal" *ngIf="show_alert()">.</div>

<div *ngIf="show_alert()" class="foot-alert bg-danger">
    <div class="f-a-icon">
        <i class="fa-duotone fa-siren-on fa-bounce"></i>
    </div>
    <div class="f-a-title">¡Alerta!</div>
    <div class="f-a-text">Parece que no tienes internet.</div>
</div>