<div class="chat">
    <div class="header">
        <avatar
            [name]="data.firstname"
            [image]="data.image"
            [configs]="avatar_configs"
        ></avatar>
        <div class="name">{{ data.firstname }} {{ data.lastname }}</div>
        <div class="exit" (click)="close()">
            <i class="fa-duotone fa-times"></i>
        </div>
    </div>
    <div class="body">
        <div class="messages">
            <div class="local-internal-scroll">
                <div class="scroll-content py-2 pl-2 pr-1">
                    <div
                        *ngFor="let item of messages"
                        [class.to]="item.subject == 'to'"
                        [class.from]="item.subject == 'from'"
                    >
                        {{ item.content }}
                        <div class="stats">
                            <div class="date">
                                {{ item.created_at | longOnlyTime }}
                            </div>
                            <i
                                [class.fa-clock]="item.status == 1"
                                [class.fa-check]="item.status == 2"
                                [class.fa-check-double]="
                                    item.status == 3 || item.status == 4
                                "
                                [class.text-primary]="
                                    item.status == 4 && item.subject == 'to'
                                "
                                [class.text-dark-primary]="
                                    item.status == 4 && item.subject == 'from'
                                "
                                class="fas"
                            ></i>
                        </div>
                    </div>

                    <div class="deep-bottom">.</div>
                </div>
            </div>
        </div>
    </div>
    <div class="entry">
        <div class="text">
            <textarea
                [(ngModel)]="message.content"
                class="form-control"
                name="message"
                id="message"
                rows="2.5"
            ></textarea>
        </div>
        <div class="send" (click)="submit()">
            <i class="fa-duotone fa-paper-plane"></i>
        </div>
    </div>
</div>
