import * as tslib_1 from "tslib";
import { CatalogsService } from "src/app/services/catalogs.service";
import { OnInit, OnDestroy } from "@angular/core";
import { AppService } from "src/app/services/app.service";
import { PageService } from "src/app/services/page.service";
import { PatientService } from "src/app/services/patient-service.service";
import { Location } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import { ConsultGeneralService } from "src/app/pages/expedient/new_consult/general/consult.service";
import { AccountService } from "src/app/services/account.service";
import { SocketService } from "src/app/services/socket.service";
import { AlertService } from "src/app/shared/ui/alert_service/alert.service";
export class SearchBarComponent {
    constructor(endpoint, patientService, accountService, pageService, consultService, _location, toastr, alertService, socketService, catalogsService) {
        this.endpoint = endpoint;
        this.patientService = patientService;
        this.accountService = accountService;
        this.pageService = pageService;
        this.consultService = consultService;
        this._location = _location;
        this.toastr = toastr;
        this.alertService = alertService;
        this.socketService = socketService;
        this.catalogsService = catalogsService;
        this.orders = [
            {
                id: true,
                text: "Ascendente",
            },
            {
                id: false,
                text: "Descendente",
            },
        ];
        this.filters = {
            limit: 10,
            skip: 0,
            search_word: "",
            order_asc: true,
        };
        this.avatar_configs = { size: "2.5rem" };
        this.loading = false;
        this.data = [];
    }
    ngOnInit() {
        this.init_socket();
        this.page_subscription = this.pageService.current.subscribe(state => {
            this.page_state = state;
        });
        this.get_patients();
    }
    init_socket() {
        this.socketService.getDoctorPatientPhoneVerify().subscribe((data) => {
            if (this.phoneMessageSid === data.sid) {
                if (data.message === "Sí, dar acceso") {
                    this.insert_relation(this.selected_patient, () => {
                        this.alertService.alert_success("¡Listo!", "El paciente ha aceptado la solicitud de acceso a su expediente medico.");
                        this.selected_patient.id_doctors_patients_level = 1;
                        this.goto(this.selected_patient);
                    });
                }
                else if (data.message === "No, denegar acceso") {
                    this.alertService.alert_error("¡Oh no!", "El paciente ha rechazado la solicitud de acceso a su expediente medico.");
                }
            }
        }, error => {
            console.error(error);
        });
    }
    ngOnDestroy() {
        this.page_subscription.unsubscribe();
    }
    backClicked() {
        this._location.back();
    }
    get_patients(loading = true) {
        this.loading = loading;
        var load = Object.assign({}, this.filters, { order_by: "firstname", limit: 10 });
        var response;
        this.endpoint.get_patients(load).subscribe(data => (response = data), error => {
            console.error(error);
            this.loading = false;
        }, () => {
            response.list.forEach(user => {
                let town = this.catalogsService.catalogs.towns.find(v => v.id == user.id_town);
                user.town = town ? town.name : "";
            });
            this.data = response.list;
            this.loading = false;
        });
    }
    goto(item) {
        if (item.id_doctors_patients_level) {
            this.patientService.change_patient_id(item.id_user);
            this.pageService.set_searching(false);
            this.filters.search_word = "";
            this.data = [];
            this.endpoint.redirect_to("/expediente/consultas");
        }
    }
    new_consult(item) {
        if (item.id_doctors_patients_level) {
            this.patientService.change_patient_id(item.id_user);
            this.pageService.set_searching(false);
            this.filters.search_word = "";
            this.data = [];
            this.endpoint.redirect_to("/expediente/nueva-consulta/general/general");
        }
    }
    close() {
        this.filters.search_word = "";
        this.data = [];
        this.pageService.set_searching(false);
    }
    change() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.get_patients();
        }, 1000);
    }
    insert_relation(patient, callback) {
        let load = {
            id_user: patient.id_user,
        };
        this.endpoint.expedient_doctors_patients_requests_insert_doctors_patients(load).subscribe(data => {
            callback(data);
        }, error => {
            console.error(error);
            this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + "");
        });
    }
    pre_add_patient(patient) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (patient.age < 18) {
                let tutors = {};
                patient.tutors.forEach(v => {
                    tutors[v.id_user] = `${v.firstname} ${v.lastname}`;
                });
                const { value: tutor } = yield Swal.fire({
                    title: 'Añadir paciente',
                    html: "El paciente es menor de edad, se debe contar con el permiso de un tutor.",
                    input: 'select',
                    inputOptions: tutors,
                    inputPlaceholder: '- Seleccione un tutor -',
                    showCancelButton: true,
                    confirmButtonText: "Solicitar permiso",
                    inputValidator: (value) => {
                        return new Promise((resolve) => {
                            if (!!value) {
                                return resolve(null);
                            }
                            else {
                                resolve('Es necesario que seleccione un tutor');
                            }
                        });
                    }
                });
                if (tutor) {
                    this.verify_phone(patient, patient.tutors.findIndex(v => v.id_user == tutor));
                }
            }
            else {
                this.add_patient(patient);
            }
        });
    }
    add_patient(patient, tutor_index = null) {
        Swal.fire({
            title: "Añadir paciente",
            html: "Enviar mensaje vía whatsapp para verificar paciente",
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#FDB915",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, enviar",
            cancelButtonText: "No",
        }).then((result) => {
            if (result.value == true) {
                this.verify_phone(patient);
            }
        });
    }
    verify_phone(patient, tutor_index = null) {
        let phone = tutor_index != null ? patient.tutors[tutor_index].phone : patient.phone;
        Swal.fire({
            title: "Añadir paciente",
            html: `La solicitud de acceso al expediente médico, fue enviada por Whatsapp al <strong>${phone ? ("*".repeat((phone + "").length - 4) + (phone + "").substring((phone + "").length - 4)) : "********"}</strong>`,
            showCloseButton: true,
            onBeforeOpen: () => {
                Swal.showLoading();
                let load = {
                    phone: phone,
                    id_user: patient.id_user,
                    patient: patient.firstname + " " + patient.lastname,
                    doctor: (this.accountService.current_state.id_gender == 1 ? "Dra. " : "Dr. ") + this.accountService.current_state.firstname + " " + this.accountService.current_state.lastname,
                };
                this.endpoint.whatsapp_send_send_doctor_patient_request(load).subscribe(data => {
                    this.selected_patient = patient;
                    this.phoneMessageSid = data;
                }, error => {
                    console.error(error);
                    this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + "");
                });
            },
            allowOutsideClick: () => !Swal.isLoading(),
        });
        return;
    }
    censored_id(id, access) {
        if (access) {
            return id;
        }
        else {
            let r_arg = (id + "").length - 5;
            return "*".repeat(r_arg > 0 ? r_arg : 0) + (id + "").substring((id + "").length - 5);
        }
    }
}
