import { Component } from '@angular/core';
import { AccidentService } from '../accident.service';

@Component({
  selector: 'app-insurances_update_accident',
  templateUrl: './update_accident.component.html',
  styleUrls: ['./update_accident.component.scss']
})
export class UpdateInsurancesAccidentComponent {

  constructor(private moduleService: AccidentService) {
  }

  close() {
    this.moduleService.cancel();
  }

}
