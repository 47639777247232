import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/services/app.service';
import { PageService } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';
import { FormRenderComponent, form_type } from 'src/app/shared/ui/form_render/form_render.component';
import { ConsultGeneralService } from '../../new_consult/general/consult.service';

@Component({
  selector: 'app-show-date',
  templateUrl: './show-date.component.html',
  styleUrls: ['./show-date.component.scss']
})
export class ShowDateComponent implements OnInit {
  @Input() data = {
    description : "", 
    fecha_date : "" , 
    hour_date  : "",
    duration : "",
    id_date :  "",
  };
  @ViewChild('form_ref', { static: false }) form_ref: FormRenderComponent;
	public form_config: form_type;
  constructor( private endpoint: AppService, private toastr: ToastrService, private patientServiceService: PatientService, private pageService: PageService,
    private consult : ConsultGeneralService ) { }

  ngOnInit() {
    this.form_configs();
  }

  form_configs() {
    this.form_config = {
      pretty_view: () => true,
			disabled: () => true,
			submit: () => { },
      rows: [
        {
          class: "row",
          cols: [
            {
              name: "description",
              type: "textarea",
              label: "Descripción",
              placeholder: () => "",
              col_class: "col-md-12",
              rows: 2.5,
              validators: {
                required: {
                  value: () => true
                },
                maxlength: {
                  value: () => 100
                }
              },
              disabled: () => false,
            },
            {
              name: "fecha_date",
              type: "calendar",
              label: "Fecha",
              placeholder: () => "#######",
              col_class: "col-md-6",
              validators: {
                required: {
                  value: () => true
                },
              },
              disabled: () => false,
            },
            {
              name: "hour_date",
              type: "text",
              label: "Hora",
              placeholder: () => "",
              col_class: "col-md-6",
              validators: {
                required: {
                  value: () => true
                },
                maxlength: {
                  value: () => 500
                }
              },
              disabled: () => false,
            },
            {
              name: "duration",
              type: "text",
              label: "duración",
              placeholder: () => "",
              col_class: "col-md-6",
              validators: {
                required: {
                  value: () => true
                },
                maxlength: {
                  value: () => 500
                }
              },
              disabled: () => false,
            },
            
          ],
        },
      ]
    };
  }

  close() {
    this.pageService.closeModal("");
  }
}
