import { NgModule } from '@angular/core';

import { PipesModule } from 'src/app/shared/ui/pipes/pipes.module';
import { FormRenderModule } from 'src/app/shared/ui/form_render/form_render.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { DatatableModule } from 'src/app/shared/ui/datatable/datatable.module';

import { LayoutComponent } from './layout.component';
import { RoutingModule } from './routing.module';
import { NgbAccordionModule, NgbAlertModule, NgbCollapseModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule, NgbModule, NgbPaginationModule, NgbPopoverModule, NgbProgressbarModule, NgbTabsetModule, NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ClickOutsideModule } from 'ng-click-outside';
import { UIModule } from 'src/app/shared/ui/ui.module';
@NgModule({
  declarations: [
    LayoutComponent,
  ],
  imports: [
    ClickOutsideModule,
    UIModule,
    NgbModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbTabsetModule,
    NgbAccordionModule,
    NgbCollapseModule,
    NgbModalModule,
    NgbProgressbarModule,
    NgbAlertModule,
    NgbToastModule,
    NgbPopoverModule,
    NgbTooltipModule,
    NgbPaginationModule,
    PipesModule,
    FormRenderModule,
    DatatableModule,
    SharedModule,
    RoutingModule,
  ],
})
export class GeneralModule { }
