import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { Subscription } from 'rxjs';
import { PageService, PageState } from 'src/app/services/page.service';

@Component({
  selector: 'app-third-sidebar',
  templateUrl: './third-sidebar.component.html',
  styleUrls: ['./third-sidebar.component.scss']
})
export class ThirdSidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  private page_subscription: Subscription;
  public page_state: PageState;

  constructor(private endpoint: AppService, private pageService: PageService) {
  }

  ngOnInit() {
    this.page_subscription = this.pageService.current.subscribe(
      state => this.page_state = state
    );
  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.page_subscription.unsubscribe();
  }

}
