import { Percentiles, Percentiles_X } from 'src/assets/json/GrowthCurvesPercentiles';
import { PipesService } from './pipes.service';
import { ChartDataset } from '../classes/chart-dataset';
import { PatientService } from './patient-service.service';
import { ChartjsService } from './chartjs.service';
import { Chart } from 'chart.js';
import * as i0 from "@angular/core";
import * as i1 from "./pipes.service";
import * as i2 from "./patient-service.service";
import * as i3 from "./chartjs.service";
export class GrowthCurveService {
    constructor(pipesService, patientService, chartjsService) {
        this.pipesService = pipesService;
        this.patientService = patientService;
        this.chartjsService = chartjsService;
        this.dataset_refs = [];
    }
    load_graficsdata(response, name) {
        this.datasets[name] = [];
        this.labels[name] = [];
        if (response && response.user) {
            if (name == "wfh") {
                this.labels[name] = Percentiles_X.wfh.map(v => v + "");
            }
            else {
                this.labels[name] = new Array(+response.user.ind + 30)
                    .fill("")
                    .map((v, i) => (i + ""));
            }
            const percentiles = response.user.id_gender == 1 ? Percentiles[name].girls : Percentiles[name].boys;
            let colors = [
                '#e51836ff',
                '#f47321ff',
                '#00843dff',
                '#f47321ff',
                '#e51836ff',
                '#70ad47ff',
                '#264478ff',
                '#9e480eff',
                '#43682bff',
                '#4472c4ff',
                '#ed7d31ff',
                '#a5a5a5ff',
                '#ffc000ff',
                '#5b9bd5ff',
                '#70ad47ff',
                '#264478ff',
                '#9e480eff',
                '#43682bff'
            ];
            let percentiles_headers = [
                {
                    name: "P01",
                    show: false
                },
                {
                    name: "P1",
                    show: false
                },
                {
                    name: "P3",
                    show: true
                },
                {
                    name: "P5",
                    show: false
                },
                {
                    name: "P10",
                    show: false
                },
                {
                    name: "P15",
                    show: true
                },
                {
                    name: "P25",
                    show: false
                },
                {
                    name: "P50",
                    show: true
                },
                {
                    name: "P75",
                    show: false
                },
                {
                    name: "P85",
                    show: true
                },
                {
                    name: "P90",
                    show: false
                },
                {
                    name: "P95",
                    show: false
                },
                {
                    name: "P97",
                    show: true
                },
                {
                    name: "P99",
                    show: false
                },
                {
                    name: "P999",
                    show: false
                },
            ];
            let color_index = 0;
            if (response && response.data && response.user) {
                let data = [];
                if (name == "wfh") {
                    this.labels[name] = [
                        ...this.labels[name],
                    ]
                        .filter((value, index, self) => self.indexOf(value) === index)
                        .sort(((a, b) => +a - +b));
                    data = new Array(Math.floor(this.labels[name].length)).fill(null);
                    response.data.forEach((reg, reg_i) => {
                        let r = this.labels[name].indexOf(this.pipesService.number(reg.height * (reg.id_height_unit == 2 ? 2.54 : 1), 0, 1));
                        data[r] = reg.weight * (reg.id_weight_unit == 4 ? 0.453592 : 1);
                        this.dataset_refs.push({ i: reg_i, r: r, n: name });
                    });
                }
                else {
                    data = new Array(Math.floor((+response.user.ind + 30) / 30.4375)).fill(null);
                    response.data.forEach((reg, reg_i) => {
                        if (name == "weight") {
                            data[Math.floor(reg.ind / 30.4375)] = reg.weight * (reg.id_weight_unit == 4 ? 0.453592 : 1);
                        }
                        if (name == "height") {
                            data[Math.floor(reg.ind / 30.4375)] = reg.height * (reg.id_height_unit == 2 ? 2.54 : 1);
                        }
                        if (name == "head") {
                            data[Math.floor(reg.ind / 30.4375)] = reg.head * (reg.id_height_unit == 2 ? 2.54 : 1);
                        }
                        if (name == "imc") {
                            data[Math.floor(reg.ind / 30.4375)] = reg.IMC * (reg.id_height_unit == 2 ? 4.8824 : 1);
                        }
                        this.dataset_refs.push({ i: reg_i, r: Math.floor(reg.ind / 30.4375), n: name });
                    });
                }
                this.datasets[name].push(new ChartDataset({
                    label: this.pipesService.names(response.user.firstname),
                    data: data,
                    fill: false,
                    backgroundColor: "#66c8c8ff",
                    borderColor: "#66c8c8ff",
                    borderWidth: 1,
                    tension: 0.1,
                    borderJoinStyle: "round",
                    pointBackgroundColor: '#66c8c8ff',
                    pointBorderColor: '#66c8c8ff',
                    pointRadius: 6,
                    pointHoverRadius: 10,
                    pointStyle: "triangle"
                }));
            }
            this.datasets[name].push(...percentiles_headers.filter(p => p.show === true).map(p => {
                return new ChartDataset({
                    label: p.name.replace(/P/g, "") + "%",
                    data: percentiles[p.name].filter((v, i) => name == "wfh" ? true : (i <= (+response.user.ind + 30) && ((i % Math.floor(30.4375 * 12) == 0) || (Math.floor(i % 30.4375) == 0 && Math.floor(i % (30.4375 * 12) / 30.4375) != 0)))),
                    fill: false,
                    borderColor: colors[color_index++],
                    borderWidth: 1,
                    tension: 0.1,
                    cubicInterpolationMode: 'monotone',
                    borderJoinStyle: "round",
                    pointBackgroundColor: '#00000000',
                    pointBorderColor: '#00000000',
                });
            }));
        }
        this.generate_graphics(name);
    }
    generate_graphics(name) {
        if (this.chart[name])
            this.chart[name].destroy();
        this.chart[name] = this.chartjsService.generateLineChart(this.canvas[name], this.datasets[name], this.labels[name].map((day, index) => {
            if (name == "wfh") {
                return day;
            }
            else {
                const year = Math.floor(index / (30.4375 * 12)) + 1;
                const month = Math.floor(index % (30.4375 * 12) / 30.4375);
                if (day == 0) {
                    return " ";
                }
                else if (index % Math.floor(30.4375 * 12) == 0) {
                    return `Año ${year}`;
                }
                else if (Math.floor(index % 30.4375) == 0 && month != 0) {
                    return `${Math.floor(index / 30.4375)}`;
                }
                else {
                    return ".";
                }
            }
        }).filter(v => v != "."), {
            responsive: true,
            elements: {
                line: {
                    tension: 0,
                },
            },
            scales: {
                display: true,
                xAxes: [
                    ...name == "wfh" ? [] :
                        [
                            {
                                display: true,
                                ticks: {
                                    display: true,
                                    callback: (value) => {
                                        return !(value + "").includes("Año") ? value : null;
                                    },
                                },
                            }
                        ],
                    {
                        display: true,
                        scaleLabel: {
                            labelString: name == "wfh" ? "Altura (cm)" : "Edad (meses y años cumplidos)",
                            display: true,
                        },
                        ticks: {
                            display: true,
                            callback: (value, index) => {
                                if (name == "wfh") {
                                    return value;
                                }
                                else {
                                    return (value + "").includes("Año") ? value : index == 0 ? "Nace" : null;
                                }
                            },
                        },
                    }
                ],
                yAxes: [{
                        display: true,
                        scaleLabel: {
                            labelString: name == "weight" ? "Peso (kg)" : name == "height" ? "Altura (cm)" : name == "head" ? "Cabeza (cm)" : name == "imc" ? "IMC (kg/m2)" : name == "wfh" ? "Peso (kg)" : "",
                            display: true,
                        }
                    }]
            },
            legend: {
                display: true,
                position: 'right',
                labels: {
                    generateLabels: function (chart) {
                        const originalLabels = Chart.defaults.global.legend.labels.generateLabels(chart);
                        const modifiedLabels = originalLabels.map((label, index) => {
                            if (index == 0) {
                                return null;
                            }
                            else {
                                return Object.assign({}, label, { text: label.text });
                            }
                        })
                            .reverse()
                            .filter(v => v);
                        return modifiedLabels;
                    }
                }
            },
            tooltips: {
                enabled: false,
                custom: (tm) => {
                    // Tooltip Element
                    let tooltipEl = document.getElementById('custom-tooltip');
                    // Create Tooltip Element if it doesn't exist
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'custom-tooltip';
                        tooltipEl.classList.add('custom-tooltip');
                        document.body.appendChild(tooltipEl);
                    }
                    // Hide if no tooltip
                    if (tm.opacity === 0) {
                        tooltipEl.style.display = 'none';
                        return;
                    }
                    const dataIndex = tm.dataPoints[0].index;
                    let index_data = this.dataset_refs.find(v => v.r == dataIndex && v.n == name);
                    if (!index_data) {
                        tooltipEl.style.display = 'none';
                        return;
                    }
                    let value = this.list.data.data[index_data.i];
                    let patient = this.patientService.patient_data;
                    if (name == "wfh") {
                        tooltipEl.innerHTML = `
<div class="ct-header">${value["weight"]} kg</div>
<div class="ct-date">el <span>${this.pipesService.micro_date(value.date)}</span></div>
<div class="ct-age">altura: <span>${value["height"]} cm</span></div>
<div class="ct-age">edad: <span>${+patient.general.age > 0
                            ? patient.general.age + (+patient.general.age > 1 ? " años" : " año")
                            : +patient.general.age_month > 0
                                ? patient.general.age_month + (+patient.general.age_month > 1 ? " meses" : " mes") +
                                    (+patient.general.age_day > 0
                                        ? ", " + patient.general.age_day + (+patient.general.age_day > 1 ? " días" : " día") : "")
                                : +patient.general.age_day > 0
                                    ? patient.general.age_day + (+patient.general.age_day > 1 ? " días" : " día")
                                    : "Recién nacido"}</span></div>
<div class="ct-percentil">percentil: <span>${value["p_WFH"]}%</span></div>
`;
                    }
                    else {
                        tooltipEl.innerHTML = `
<div class="ct-header">${value[name == "imc" ? "IMC" : name]} ${name == "weight" ? " kg" : name == "height" ? " cm" : name == "head" ? " cm" : name == "imc" ? " kg/m2" : ""}</div>
<div class="ct-date">el <span>${this.pipesService.micro_date(value.date)}</span></div>
<div class="ct-age">edad: <span>${+patient.general.age > 0
                            ? +patient.general.age + " años"
                            : +patient.general.age_month > 0
                                ? +patient.general.age_month +
                                    " meses" +
                                    (+patient.general.age_day > 0
                                        ? ", " +
                                            +patient.general.age_day +
                                            " días"
                                        : "")
                                : +patient.general.age_day > 0
                                    ? +patient.general.age_day + " días"
                                    : "Recién nacido"}</span></div>
<div class="ct-percentil">percentil: <span>${value["p_" + (name == "imc" ? "IMC" : name)]}%</span></div>
`;
                    }
                    // Calculate position
                    const position = this.chart[name].canvas.getBoundingClientRect();
                    // Display tooltip
                    tooltipEl.style.display = 'block';
                    tooltipEl.style.position = 'fixed';
                    tooltipEl.style.left = (position.left + window.pageXOffset + tm.caretX - 100) + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tm.caretY + 'px';
                    tooltipEl.style.fontFamily = tm._bodyFontFamily;
                    tooltipEl.style.fontSize = tm.bodyFontSize + 'px';
                    tooltipEl.style.fontStyle = tm._bodyFontStyle;
                    tooltipEl.style.padding = tm.yPadding + 'px ' + tm.xPadding + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                },
            },
        });
    }
    search_percentil(data, id_gender) {
        let searched = this._search_percentil(data, id_gender);
        // y = y1 + ((x - x1) / (x2 - x1)) * (y2 - y1)
        data.p_weight = this.pipesService.number(searched.weight.x < searched.weight.x1 ? searched.weight.y1 :
            searched.weight.x > searched.weight.x3 ? searched.weight.y3 :
                searched.weight.y1 + ((searched.weight.x - searched.weight.x1) / (searched.weight.x2 - searched.weight.x1)) * (searched.weight.y2 - searched.weight.y1), 1, 1);
        data.p_height = this.pipesService.number(searched.height.x < searched.height.x1 ? searched.height.y1 :
            searched.height.x > searched.height.x3 ? searched.height.y3 :
                searched.height.y1 + ((searched.height.x - searched.height.x1) / (searched.height.x2 - searched.height.x1)) * (searched.height.y2 - searched.height.y1), 1, 1);
        data.p_head = this.pipesService.number(searched.head.x < searched.head.x1 ? searched.head.y1 :
            searched.head.x > searched.head.x3 ? searched.head.y3 :
                searched.head.y1 + ((searched.head.x - searched.head.x1) / (searched.head.x2 - searched.head.x1)) * (searched.head.y2 - searched.head.y1), 1, 1);
        data.p_IMC = this.pipesService.number(searched.IMC.x < searched.IMC.x1 ? searched.IMC.y1 :
            searched.IMC.x > searched.IMC.x3 ? searched.IMC.y3 :
                searched.IMC.y1 + ((searched.IMC.x - searched.IMC.x1) / (searched.IMC.x2 - searched.IMC.x1)) * (searched.IMC.y2 - searched.IMC.y1), 1, 1);
        data.p_WFH = this.pipesService.number(searched.WFH.x < searched.WFH.x1 ? searched.WFH.y1 :
            searched.WFH.x > searched.WFH.x3 ? searched.WFH.y3 :
                searched.WFH.y1 + ((searched.WFH.x - searched.WFH.x1) / (searched.WFH.x2 - searched.WFH.x1)) * (searched.WFH.y2 - searched.WFH.y1), 1, 1);
        data.weight_1 = data.weight * (data.id_weight_unit == 4 ? 1 : 2.204623);
        data.weight_2 = data.weight * (data.id_weight_unit == 4 ? 0.453592 : 1);
        data.height_1 = data.height * (data.id_height_unit == 2 ? 1 : 0.3937008);
        data.height_2 = data.height * (data.id_height_unit == 2 ? 2.54 : 1);
        data.head_1 = data.head * (data.id_height_unit == 2 ? 1 : 0.3937008);
        data.head_2 = data.head * (data.id_height_unit == 2 ? 2.54 : 1);
        data.IMC_1 = data.IMC * (data.id_height_unit == 2 ? 1 : 0.20462);
        data.IMC_2 = data.IMC * (data.id_height_unit == 2 ? 4.8824 : 1);
        data.WFH_1 = data.WFH * (data.id_weight_unit == 4 ? 1 : 2.204623);
        data.WFH_2 = data.WFH * (data.id_weight_unit == 4 ? 0.453592 : 1);
        return data;
    }
    _search_percentil(data, id_gender) {
        let percentiles = {
            weight: id_gender == 1 ? Percentiles["weight"].girls : Percentiles["weight"].boys,
            height: id_gender == 1 ? Percentiles["height"].girls : Percentiles["height"].boys,
            head: id_gender == 1 ? Percentiles["head"].girls : Percentiles["head"].boys,
            IMC: id_gender == 1 ? Percentiles["imc"].girls : Percentiles["imc"].boys,
            WFH: id_gender == 1 ? Percentiles["wfh"].girls : Percentiles["wfh"].boys,
        };
        let ranges = {
            weight: [],
            height: [],
            head: [],
            IMC: [],
            WFH: [],
        };
        let ranges_names = {
            weight: [],
            height: [],
            head: [],
            IMC: [],
            WFH: [],
        };
        for (const key in percentiles.weight) {
            ranges.weight.push(percentiles.weight[key][data.ind]);
            ranges_names.weight.push(key.replace(/P/g, ""));
        }
        for (const key in percentiles.height) {
            ranges.height.push(percentiles.height[key][data.ind]);
            ranges_names.height.push(key.replace(/P/g, ""));
        }
        for (const key in percentiles.head) {
            ranges.head.push(percentiles.head[key][data.ind]);
            ranges_names.head.push(key.replace(/P/g, ""));
        }
        for (const key in percentiles.IMC) {
            ranges.IMC.push(percentiles.IMC[key][data.ind]);
            ranges_names.IMC.push(key.replace(/P/g, ""));
        }
        for (const key in percentiles.WFH) {
            let i_wfh = Percentiles_X.wfh.find(v => v == +this.pipesService.number(+data.height * (data.id_height_unit == 2 ? 2.54 : 1), 0, 1));
            ranges.WFH.push(percentiles.WFH[key][i_wfh]);
            ranges_names.WFH.push(key.replace(/P/g, ""));
        }
        let parsed_data = {
            weight: +data.weight * (data.id_weight_unit == 4 ? 0.453592 : 1),
            height: +data.height * (data.id_height_unit == 2 ? 2.54 : 1),
            head: +data.head * (data.id_height_unit == 2 ? 2.54 : 1),
            IMC: +data.IMC * (data.id_height_unit == 2 ? 4.8824 : 1),
            WFH: +data.WFH * (data.id_weight_unit == 4 ? 0.453592 : 1),
        };
        let index = {
            weight: ranges.weight.findIndex(v => +v > parsed_data.weight),
            height: ranges.height.findIndex(v => +v > parsed_data.height),
            head: ranges.head.findIndex(v => +v > parsed_data.head),
            IMC: ranges.IMC.findIndex(v => +v > parsed_data.IMC),
            WFH: ranges.WFH.findIndex(v => +v > parsed_data.WFH),
        };
        index.weight = index.weight >= 0 ? index.weight : ranges.weight.length - 1;
        index._weight = index.weight == 0 ? index.weight : index.weight - 1;
        index.weight_ = index.weight == ranges.weight.length - 1 ? index.weight : index.weight + 1;
        index.height = index.height >= 0 ? index.height : ranges.height.length - 1;
        index._height = index.height == 0 ? index.height : index.height - 1;
        index.height_ = index.height == ranges.height.length - 1 ? index.height : index.height + 1;
        index.head = index.head >= 0 ? index.head : ranges.head.length - 1;
        index._head = index.head == 0 ? index.head : index.head - 1;
        index.head_ = index.head == ranges.head.length - 1 ? index.head : index.head + 1;
        index.IMC = index.IMC >= 0 ? index.IMC : ranges.IMC.length - 1;
        index._IMC = index.IMC == 0 ? index.IMC : index.IMC - 1;
        index.IMC_ = index.IMC == ranges.IMC.length - 1 ? index.IMC : index.IMC + 1;
        index.WFH = index.WFH >= 0 ? index.WFH : ranges.WFH.length - 1;
        index._WFH = index.WFH == 0 ? index.WFH : index.WFH - 1;
        index.WFH_ = index.WFH == ranges.WFH.length - 1 ? index.WFH : index.WFH + 1;
        let result = {
            weight: ranges_names.weight[index.weight],
            height: ranges_names.height[index.height],
            head: ranges_names.head[index.head],
            IMC: ranges_names.IMC[index.IMC],
            WFH: ranges_names.WFH[index.WFH],
            _weight: ranges_names.weight[index._weight],
            _height: ranges_names.height[index._height],
            _head: ranges_names.head[index._head],
            _IMC: ranges_names.IMC[index._IMC],
            _WFH: ranges_names.WFH[index._WFH],
            weight_: ranges_names.weight[index.weight_],
            height_: ranges_names.height[index.height_],
            head_: ranges_names.head[index.head_],
            IMC_: ranges_names.IMC[index.IMC_],
            WFH_: ranges_names.WFH[index.WFH_],
        };
        result.weight = +(result.weight == "01" ? "0.1" : (result.weight == "999" ? "99.9" : result.weight));
        result.height = +(result.height == "01" ? "0.1" : (result.height == "999" ? "99.9" : result.height));
        result.head = +(result.head == "01" ? "0.1" : (result.head == "999" ? "99.9" : result.head));
        result.IMC = +(result.IMC == "01" ? "0.1" : (result.IMC == "999" ? "99.9" : result.IMC));
        result.WFH = +(result.WFH == "01" ? "0.1" : (result.WFH == "999" ? "99.9" : result.WFH));
        result._weight = +(result._weight == "01" ? "0.1" : (result._weight == "999" ? "99.9" : result._weight));
        result._height = +(result._height == "01" ? "0.1" : (result._height == "999" ? "99.9" : result._height));
        result._head = +(result._head == "01" ? "0.1" : (result._head == "999" ? "99.9" : result._head));
        result._IMC = +(result._IMC == "01" ? "0.1" : (result._IMC == "999" ? "99.9" : result._IMC));
        result._WFH = +(result._WFH == "01" ? "0.1" : (result._WFH == "999" ? "99.9" : result._WFH));
        result.weight_ = +(result.weight_ == "01" ? "0.1" : (result.weight_ == "999" ? "99.9" : result.weight_));
        result.height_ = +(result.height_ == "01" ? "0.1" : (result.height_ == "999" ? "99.9" : result.height_));
        result.head_ = +(result.head_ == "01" ? "0.1" : (result.head_ == "999" ? "99.9" : result.head_));
        result.IMC_ = +(result.IMC_ == "01" ? "0.1" : (result.IMC_ == "999" ? "99.9" : result.IMC_));
        result.WFH_ = +(result.WFH_ == "01" ? "0.1" : (result.WFH_ == "999" ? "99.9" : result.WFH_));
        let r = {
            weight: {
                y1: +result._weight,
                y2: +result.weight,
                y3: +result.weight_,
                x: +parsed_data.weight,
                x1: +ranges.weight[index._weight],
                x2: +ranges.weight[index.weight],
                x3: +ranges.weight[index.weight_],
            },
            height: {
                y1: +result._height,
                y2: +result.height,
                y3: +result.height_,
                x: +parsed_data.height,
                x1: +ranges.height[index._height],
                x2: +ranges.height[index.height],
                x3: +ranges.height[index.height_],
            },
            head: {
                y1: +result._head,
                y2: +result.head,
                y3: +result.head_,
                x: +parsed_data.head,
                x1: +ranges.head[index._head],
                x2: +ranges.head[index.head],
                x3: +ranges.head[index.head_],
            },
            IMC: {
                y1: +result._IMC,
                y2: +result.IMC,
                y3: +result.IMC_,
                x: +parsed_data.IMC,
                x1: +ranges.IMC[index._IMC],
                x2: +ranges.IMC[index.IMC],
                x3: +ranges.IMC[index.IMC_],
            },
            WFH: {
                y1: +result._WFH,
                y2: +result.WFH,
                y3: +result.WFH_,
                x: +parsed_data.WFH,
                x1: +ranges.WFH[index._WFH],
                x2: +ranges.WFH[index.WFH],
                x3: +ranges.WFH[index.WFH_],
            },
        };
        return r;
    }
}
GrowthCurveService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GrowthCurveService_Factory() { return new GrowthCurveService(i0.ɵɵinject(i1.PipesService), i0.ɵɵinject(i2.PatientService), i0.ɵɵinject(i3.ChartjsService)); }, token: GrowthCurveService, providedIn: "root" });
