import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AppService } from "src/app/services/app.service";
import {
    PatientService,
    patient_state,
} from "src/app/services/patient-service.service";
import { PageService, PageState } from "../../../services/page.service";

@Component({
    selector: "app-brief",
    templateUrl: "./brief.component.html",
    styleUrls: ["./brief.component.scss"],
})
export class BriefComponent implements OnInit, OnDestroy {
    private patient_subscription: Subscription;
    public patient_state: patient_state;
    private page_subscription: Subscription;
    public page_state: PageState;

    public show: boolean;

    public avatar_configs = {
        size: "5rem",
    };

    constructor(
        private endpoint: AppService,
        private patientService: PatientService,
        private pageService: PageService
    ) {
        this.show = true;
    }

    ngOnInit() {
        this.patient_subscription =
            this.patientService.patient_data_subscription.subscribe(
                (data) => (this.patient_state = data)
            );
        this.page_subscription = this.pageService.current.subscribe(
            (state) => (this.page_state = state)
        );
    }

    ngOnDestroy() {
        this.patient_subscription.unsubscribe();
        this.page_subscription.unsubscribe();
    }
}
