import { PipesService } from 'src/app/services/pipes.service';
import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { FormControlDirective } from "@angular/forms";
import { NgxFileDropEntry } from "ngx-file-drop";
import { AppService } from "src/app/services/app.service";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import emailMask from "text-mask-addons/dist/emailMask";

@Component({
    selector: "app-form_render",
    templateUrl: "./form_render.component.html",
    styleUrls: ["./form_render.component.scss"],
})
export class FormRenderComponent implements OnInit {
    @ViewChild("form_ref", { static: false }) form_ref: FormControlDirective;

    @Input("config") config: form_type;

    @Input() data: any;

    // REGEX
    public name_regex = /^[\p{L}\s']*$/u;
    public number_regex = /^[0-9]+$/;
    public email_regex =
        /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/;
    public submitted: boolean;
    // MASK
    public hn_identification_mask = [
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        "-",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        "-",
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
    ];
    public hn_phone_mask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, "-", /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
    public number_mask = createNumberMask({
        allowNegative: false,
        allowDecimal: false,
        prefix: "",
        includeThousandsSeparator: false,
        allowLeadingZeroes: true,
    });
    public email_mask = emailMask;
    public integer_mask = createNumberMask({
        allowNegative: false,
        allowDecimal: false,
        integerLimit: 25,
        prefix: "",
        includeThousandsSeparator: true,
    });
    public decimal_mask = createNumberMask({
        allowNegative: false,
        allowDecimal: true,
        integerLimit: 25,
        decimalLimit: 25,
        prefix: "",
        includeThousandsSeparator: true,
    });

    public days = [
        { id: 0, name: "Lunes" },
        { id: 1, name: "Martes" },
        { id: 2, name: "Miércoles" },
        { id: 3, name: "Jueves" },
        { id: 4, name: "Viernes" },
        { id: 5, name: "Sábado" },
        { id: 6, name: "Domingo" }
    ];

    constructor(private endpoint: AppService, private pipesService: PipesService) {
        this.submitted = false;
    }

    ngOnInit() { }

    public readonly input_name_stuff = {
        paste: (event, callback) => {
            let clipboardData = event.clipboardData || window["clipboardData"];
            let pastedText = clipboardData.getData("text");
            if (callback) callback(pastedText);
            if (this.name_regex.test(pastedText)) return true;
            event.preventDefault();
            return false;
        },
        keypress: (event, callback) => {
            let inp = String.fromCharCode(event.keyCode);
            if (callback) callback(inp);
            if (this.name_regex.test(inp)) return true;
            event.preventDefault();
            return false;
        },
    };

    valid_email(email) {
        return this.email_regex.test(email);
    }

    valid() {
        let valid = true;
        this.config.rows.forEach((row) => {
            row.cols.forEach((col) => {
                if (
                    col.type != "divider" &&
                    col.type != "toogle-carousel" &&
                    col.type != "buttons" &&
                    col.type != "tags" &&
                    col.validators
                ) {
                    if (col.validators.custom && !col.validators.custom.value(this.data[col.name])) {
                        valid = false;
                    }

                    if (col.type == "email") {
                        if (this.data[col.name] && col.validators.required.value() == true) {
                            if (!this.email_regex.test(this.data[col.name])) {
                                valid = false;
                            }
                        }
                        if (this.data[col.name] && !this.email_regex.test(this.data[col.name])) {
                            valid = false;
                        } else if (!this.data[col.name] && col.validators.required.value() == true) {
                            valid = false;
                        }
                    }
                }
            });
        });
        return this.form_ref ? !this.form_ref.invalid && valid : false;
    }

    submit(callback) {
        this.submitted = true;
        this.form_ref["submitted"] = true;
        callback();
    }

    submit_event(callback = (callback_data?: any) => { }) {
        this.config.submit(callback);
    }

    unsubmit() {
        this.submitted = false;
        this.form_ref["submitted"] = false;
    }

    checkbox_check(list: any[], id: string): any[] {
        if (!Array.isArray(list)) {
            list = new Array();
        }
        if (list.find((value) => value == id)) {
            list = list.filter((value) => value != id);
        } else {
            list.push(id);
        }
        return list;
    }

    checkbox_is_checked(list: any[], id: string): boolean {
        if (!Array.isArray(list)) {
            list = new Array();
        }
        return !!list.find((value) => value == id);
    }

    dropped(files: NgxFileDropEntry[]) {
        return files;
    }

    fileOver(event) { }

    fileLeave(event) { }

    get_upload_url(path: string, name: string) {
        return this.endpoint.get_file_endpoint(path, name);
    }

    delete_upload(list: any[], index: number): any[] {
        if (!Array.isArray(list)) {
            list = new Array();
        }
        if (list[index]) {
            list = list.filter((v, i) => i != index);
        }
        return list;
    }

    toogle_dropdown(dropdown, input) {
        dropdown.classList.toggle("show");
        input.focus();
    }

    clean_fields() {
        this.config.rows.forEach((row) => {
            row.cols.forEach((col) => {
                if (col.type != "divider" && col.type != "toogle-carousel" && col.type != "buttons" && col.type != "tags") {
                    this.data[col.name] = "";
                }
            });
        });
        this.unsubmit();
    }

    upload_amount(list, amount) {
        return list && amount ? list.slice(0, amount) : list;
    }

    month_year(input_ref, event) {
        input_ref.value = this.pipesService.monthYear(event);
        return true;
    }

    monthSelected(month_picker, event, col) {
        let flag = false;
        if (col.validators) {
            if (col.validators.max && event > col.validators.max.value()) {
                flag = true;
            }
            if (col.validators.min && event < col.validators.min.value()) {
                flag = true;
            }
        }

        if (!flag) {
            month_picker.close();
            this.data[col.name] = event;
        } else {
            month_picker.close();
        }
    }

    yearSelected(year_picker, event, col) {
        let flag = false;
        if (col.validators) {
            if (col.validators.max && event > col.validators.max.value()) {
                flag = true;
            }
            if (col.validators.min && event < col.validators.min.value()) {
                flag = true;
            }
        }

        if (!flag) {
            year_picker.close();
            this.data[col.name] = event;
        } else {
            year_picker.close();
        }
    }

    just_year(input_ref, event) {
        input_ref.value = this.pipesService.year(event);
        return true;
    }

    parse_month_year(str: string): string {
        let _str = this.pipesService.monthYear(str);
        return _str;
    }

    push(list: any[], item: any) {
        if (item) {
            list.push(item.trim());
        }
    }

    find(list: any[], item: any) {
        return list.find(v => v.localeCompare(item, 'es', { sensitivity: 'base' }) === 0);
    }
}

type common_type = {
    name: string;
    label?: string;
    small?: string;
    placeholder?: Function;
    description?: string;
    col_class: string;
    class?: string;

    tooltip?: {
        class: string;
        icon: string;
        text: string;
        show?(): boolean;
    };
    toogle?: string;
    start_value?(data: any): any;
};

export type form_type = {
    name?: string;
    description?: string;
    show?(): Boolean;
    disabled?(): Boolean;
    autocomplete?: Function;
    pretty_view?: Function;
    submit: Function;
    rows: row_type[];
    version?: number; // 1:normal, 2:floating
};

export type row_type = {
    class?: string;
    cols: (
        | divider_type
        | toogle_carousel_type
        | buttons_type
        | input_password_type
        | input_text_type
        | input_name_type
        | input_select_days_type
        | input_select_type
        | input_select_search_type
        | input_dynamic_options_type
        | input_phone_type
        | input_identification_type
        | input_calendar_type
        | input_calendar_time_type
        | input_textarea_type
        | input_email_type
        | input_number_type
        | input_radio_type
        | input_checkbox_type
        | input_switch_type
        | input_upload_type
        | tags_type
    )[];
};

export type filter_type = {
    name: string;
    list: Function;
    show?(): Boolean;
    disabled?(): Boolean;
    value: string;
    pretty_view?: boolean;
    no_border?: boolean;
    text: string;
    tooltip?: string;
    change?: Function;
};

export type tags_type = {
    label?: string;
    small?: string;
    type: "tags";
    list?(): any[];
    col_class: string;
};

export type toogle_carousel_type = {
    col_class: string;
    class?: string;
    name: string;
    type: "toogle-carousel";
    label: string;
};

export type divider_type = {
    col_class: string;
    class?: string;
    bar?: boolean;
    type: "divider";
    label: string;
    toogle?: string;
};

export type buttons_type = {
    col_class: string;
    class?: string;
    type: "buttons";
    toogle?: string;
    buttons: {
        class?: string;
        label: string;
        type: "submit" | "button" | "link";
        link?: string;
        icon?: {
            class?: string;
            position: "left" | "right";
        };
        show?(): Boolean;
        disabled?(): Boolean;
        click?: Function;
    }[];
};

export type input_type = common_type & {
    type: string;

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
        maxlength?: {
            error?: string;
            value: Function;
        };
        minlength?: {
            error?: string;
            value: Function;
        };
        pattern?: {
            error?: string;
            value: Function;
        };
    };

    catalog?: {
        list: Function;
        value: string;
        text: string;
    };

    mask?: Function;
    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
    paste?: Function;
    keypress?: Function;
    input?: Function;
};

export type input_text_type = common_type & {
    type: "text";

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
        maxlength?: {
            error?: string;
            value: Function;
        };
        minlength?: {
            error?: string;
            value: Function;
        };
        pattern?: {
            error?: string;
            value: Function;
        };
    };

    filter?: filter_type;

    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
    paste?: Function;
    keypress?: Function;
    input?: Function;
};

export type input_password_type = common_type & {
    type: "password";

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
        maxlength?: {
            error?: string;
            value: Function;
        };
        minlength?: {
            error?: string;
            value: Function;
        };
        pattern?: {
            error?: string;
            value: Function;
        };
    };

    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
    paste?: Function;
    keypress?: Function;
    input?: Function;
};

export type input_name_type = common_type & {
    type: "name";

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
        maxlength?: {
            error?: string;
            value: Function;
        };
        minlength?: {
            error?: string;
            value: Function;
        };
    };

    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
    paste?: Function;
    keypress?: Function;
    input?: Function;
};

export type input_select_type = common_type & {
    type: "select";

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
    };

    catalog: {
        list: Function;
        value: string;
        text: string;
    };

    show?(): Boolean;
    disabled?(): Boolean;
    allow_null?: Boolean;
    change?: Function;
};

export type input_select_search_type = common_type & {
    type: "select-search";
    version?: "v1" | "v2";

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
    };

    catalog: {
        list: Function;
        value: string;
        text: string;
    };
    loading?: Function;

    show?(): Boolean;
    disabled?(): Boolean;
    change: Function;
    input?: Function;
    select: Function;
    paste?: Function;
    keypress?: Function;
};

export type input_dynamic_options_type = common_type & {
    type: "dynamic-options";

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
    };

    loading?: Function;

    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
    input?: Function;
    push?: Function;
    paste?: Function;
    keypress?: Function;
};

export type input_phone_type = common_type & {
    type: "phone";
    hn: Function;

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
        maxlength?: {
            error?: string;
            value: Function;
        };
    };

    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
    paste?: Function;
    keypress?: Function;
    input?: Function;
};

export type input_identification_type = common_type & {
    type: "identification";
    hn: Function;

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
        maxlength?: {
            error?: string;
            value: Function;
        };
    };

    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
    paste?: Function;
    keypress?: Function;
    input?: Function;
};

export type input_calendar_type = common_type & {
    type: "calendar" | 'calendar-month' | 'calendar-year';

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        min?: {
            error?: string;
            value: Function;
        };
        max?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
    };

    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
    input?: Function;
};

export type input_calendar_time_type = common_type & {
    type: "calendar-time";

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        min?: {
            error?: string;
            value: Function;
        };
        max?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
    };

    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
    input?: Function;
};

export type input_textarea_type = common_type & {
    type: "textarea";

    rows: number;

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
        maxlength?: {
            error?: string;
            value: Function;
        };
        minlength?: {
            error?: string;
            value: Function;
        };
        pattern?: {
            error?: string;
            value: Function;
        };
    };

    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
    paste?: Function;
    keypress?: Function;
    input?: Function;
};

export type input_email_type = common_type & {
    type: "email";

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
    };

    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
    paste?: Function;
    keypress?: Function;
    input?: Function;
};

export type input_number_type = common_type & {
    type: "number" | "integer" | "decimal";

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
        min?: {
            error?: string;
            value: Function;
        };
        max?: {
            error?: string;
            value: Function;
        };
        maxlength?: {
            error?: string;
            value: Function;
        };
        minlength?: {
            error?: string;
            value: Function;
        };
    };

    filter?: filter_type;

    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
    paste?: Function;
    keypress?: Function;
    input?: Function;
};

export type input_select_days_type = common_type & {
    type: "select-days";

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
    };

    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
    click?: Function;
};

export type input_radio_type = common_type & {
    type: "radio";

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
    };

    catalog: {
        list: Function;
        value: string;
        text: string;
    };

    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
};

export type input_checkbox_type = common_type & {
    type: "checkbox";

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
    };

    catalog: {
        list: Function;
        value: string;
        text: string;
    };

    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
};

export type input_switch_type = common_type & {
    type: "switch";
    options: string[];

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
    };

    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
};

export type input_upload_type = common_type & {
    type: "upload";
    path: string;

    amount?: number;

    validators?: {
        custom?: {
            error?: string;
            value: Function;
        };
        required?: {
            error?: string;
            value: Function;
        };
    };

    show?(): Boolean;
    disabled?(): Boolean;
    change?: Function;
};
