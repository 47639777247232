import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { AppService } from "src/app/services/app.service";
import { PageService } from "src/app/services/page.service";
import {
    PatientService,
    patient_state,
} from "src/app/services/patient-service.service";
import {
    BoldListComponent,
    bold_list_type,
} from "src/app/shared/ui/bold_list/bold_list.component";
import { SearchButtonComponent } from "src/app/shared/ui/search_button/search_button.component";
import { ConsultGeneralService } from "../new_consult/general/consult.service";

@Component({
    selector: "app-consults",
    templateUrl: "./consults.component.html",
    styleUrls: ["./consults.component.scss"],
})
export class ConsultsComponent implements OnInit, OnDestroy {
    private patient_subscription: Subscription;
    public patient_state: patient_state;

    private page_subscription: Subscription;
    public page_state: any;

    @ViewChild("search_button_ref", { static: false })
    search_button_ref: SearchButtonComponent;
    @ViewChild("bold_list_ref", { static: false })
    bold_list_ref: BoldListComponent;

    public bold_list: bold_list_type;
    public data: any = {
        list: [],
        count: 0,
        skip: 0,
        loaded: false,
    };
    public show_consult: boolean;
    public only_mine: boolean;
    public selected_item: any;

    constructor(
        private endpoint: AppService,
        private patientService: PatientService,
        private pageService: PageService,
        private consultService: ConsultGeneralService
    ) {
        this.bold_list_config();
        this.only_mine = false;
    }

    ngOnInit() {
        this.patient_subscription =
            this.patientService.patient_data_subscription.subscribe((data) => {
                this.patient_state = data;
                if (this.bold_list_ref) this.bold_list_ref.update();
            });
        this.page_subscription = this.pageService.current.subscribe(
            (state) => (this.page_state = state)
        );
    }

    ngOnDestroy() {
        this.patient_subscription.unsubscribe();
        this.page_subscription.unsubscribe();
    }

    bold_list_config() {
        this.bold_list = {
            configs: {
                title: "Consultas",
                //icon: "fa-duotone fa- bi bi-calendar-check",
                paginate: true,
                search: true,
                open: true,
                selected: (item) => {
                    return item.isMine == 1;
                },
                big_icon: (item) => ({
                    icon: (
                        item.id_consults_type == "1"
                            ? "fa-duotone fa-stethoscope text-primary"
                            : item.id_consults_type == "2"
                                ? "fa-duotone fa-syringe text-primary"
                                : item.id_consults_type == "3"
                                    ? "fa-duotone fa-file-medical text-primary"
                                    : item.id_consults_type == "4"
                                        ? "fa-duotone fa-light-emergency-on text-primary"
                                        : "fa-duotone fa-stethoscope text-secondary"
                    ) +
                        " fa-lg pr-3",
                    title:
                        item.id == this.consultService.get_storage()
                            ? "Actual"
                            : item.consults_status_name,
                }),
            },
            filters: {
                limit: 50,
                skip: 0,
                order_asc: false,
                order_by: "created_at",
            },
            headers: [
                {
                    label: "Diagnóstico",
                    name: "diagnosis",
                    order_by: "diagnosis",
                },
                {
                    label: "Médico",
                    name: "name_doctor_educations",
                    order_by: "doctor_name",
                },
                {
                    label: "Fecha",
                    name: "created_at",
                    order_by: "created_at",
                    type: "date",
                    class: "secondary",
                },
                {
                    label: "Estado",
                    name: "icon_status",
                    order_by: "id_consults_status",
                    type: "icon",
                },
            ],
            contents: [
                // (item: any) =>
                //     "Dr. " +
                //     item.doctor_name +
                //     (item.doctor_job_place ? "," + item.doctor_job_place : ""),
            ],
            events: [
                // {
                //     name: "Detalles",
                //     icon: "fa-duotone fa-file-circle-info text-info mr-1",
                // },
                {
                    name: "Editar",
                    icon: "fa-duotone fa-edit text-warning",
                    disabled: (item) => !(
                        item.isMine == 1 &&
                        new Date() < new Date(item.expiresAt) &&
                        (
                            item.id_consults_status == 1 ||
                            item.id_consults_status == 2 ||
                            item.id_consults_status == 3
                        )),
                },
                {
                    name: "Cancelar",
                    icon: "fa-duotone fa-ban text-danger",
                    disabled: (item) => !(
                        item.isMine == 1 &&
                        new Date() < new Date(item.expiresAt) &&
                        (
                            item.id_consults_status == 1 ||
                            item.id_consults_status == 2 ||
                            item.id_consults_status == 3
                        )),
                }
            ],
        };
    }

    update(data: any) {
        if (this.patient_state && this.patient_state.general.id_user) {
            if (this.search_button_ref)
                data.search_word = this.search_button_ref.data;
            var load = {
                id: this.patient_state.general.id_user,
                ...data,
                only_mine: this.only_mine,
            };

            this.endpoint.get_consults(load).subscribe(
                (data) => {
                    data.icon = "fa-duotone fa-times";
                    this.data = data;
                    this.data.list.forEach(item => {
                        item.icon_status = (item.id == this.consultService.get_storage()
                            ? "fa-duotone fa-play text-primary"
                            : item.id_consults_status == "1"
                                ? "fa-duotone fa-clock text-warning"
                                : item.id_consults_status == "2"
                                    ? "fa-duotone fa-clock text-warning"
                                    : item.id_consults_status == "3"
                                        ? "fa-duotone fa-check text-success"
                                        : item.id_consults_status == "4"
                                            ? "fa-duotone fa-ban text-danger"
                                            : "fa-duotone fa-times text-secondary") +
                            " fa-lg pl-4";
                    });
                },
                (err) => console.error(err)
            );
        }
    }

    events(data: any) {
        this.selected_item = data.item;
        if (data.name == "Detalles" || data.name == "row_click") {
            // this.show_consult = true;            
            this.endpoint.redirect_to("/expediente/consulta/" + this.selected_item.id);
        } else if (data.name == "Editar") {
            this.bold_list_ref.loading = true;
            this.consultService.save_storage(this.selected_item.id);
            this.endpoint.redirect_to("/expediente/nueva-consulta");
        } else if (data.name == "Cancelar") {
            this.consultService.cancel(this.selected_item.id, () => {
                this.on_update();
            });
        }
    }

    on_update() {
        if (this.bold_list_ref) this.bold_list_ref.update();
    }

    reset_consult() {
        this.consultService.remove_storage();
        this.consultService.init_consult();
        this.endpoint.redirect_to("/expediente/nueva-consulta/general/general");
    }

    checkbox_check() {
        this.only_mine = !this.only_mine;
        this.on_update();
    }
}
