import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-insurance-card',
  templateUrl: './insurance-card.component.html',
  styleUrls: ['./insurance-card.component.scss']
})
export class InsuranceCardComponent {

  @Output("event") event: EventEmitter<{ name: string, item: any }> = new EventEmitter();
  @Input("events") events: any[];
  @Input("item") item: any;
  @Input("i") i: any;

  constructor() {
  }

  _event(name: string, item: any) {
    this.event.emit({ name, item });
  }

}
