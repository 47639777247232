import { Component } from '@angular/core';
import { ExpedientService } from 'src/app/services/expedient.service';
import { AccidentService } from '../accident.service';

@Component({
  selector: 'app-insurances_create_accident',
  templateUrl: './create_accident.component.html',
  styleUrls: ['./create_accident.component.scss']
})
export class CreateInsurancesAccidentComponent {

  constructor(private moduleService: AccidentService, private expedientService: ExpedientService) {
    this.expedientService.change_method("insert");
    this.moduleService.init();
  }

  close() {
    this.moduleService.cancel();
  }

}
