/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../shared/ui/form_render/form_render.component.ngfactory";
import * as i2 from "../../../../../shared/ui/form_render/form_render.component";
import * as i3 from "../../../../../services/app.service";
import * as i4 from "../../../../../services/pipes.service";
import * as i5 from "./form.component";
import * as i6 from "../allergies.service";
import * as i7 from "../../../../../services/expedient.service";
var styles_FormComponent = [];
var RenderType_FormComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FormComponent, data: {} });
export { RenderType_FormComponent as RenderType_FormComponent };
export function View_FormComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { form_ref: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-form_render", [], null, null, null, i1.View_FormRenderComponent_0, i1.RenderType_FormRenderComponent)), i0.ɵdid(2, 114688, [[1, 4], ["form_ref", 4]], 0, i2.FormRenderComponent, [i3.AppService, i4.PipesService], { config: [0, "config"], data: [1, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form_config; var currVal_1 = _co.allergies_state; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_FormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-module-form", [], null, null, null, View_FormComponent_0, RenderType_FormComponent)), i0.ɵdid(1, 4440064, null, 0, i5.FormComponent, [i6.AllergiesService, i7.ExpedientService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormComponentNgFactory = i0.ɵccf("app-module-form", i5.FormComponent, View_FormComponent_Host_0, {}, {}, []);
export { FormComponentNgFactory as FormComponentNgFactory };
