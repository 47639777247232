/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./second-sidebar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pages/components/growth_curves/growth_curves.component.ngfactory";
import * as i3 from "../../pages/components/growth_curves/growth_curves.component";
import * as i4 from "../../services/app.service";
import * as i5 from "../../services/patient-service.service";
import * as i6 from "../../services/page.service";
import * as i7 from "@angular/common";
import * as i8 from "../../pages/components/brief/brief.component.ngfactory";
import * as i9 from "../../pages/components/brief/brief.component";
import * as i10 from "./second-sidebar.component";
import * as i11 from "@angular/router";
var styles_SecondSidebarComponent = [i0.styles];
var RenderType_SecondSidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SecondSidebarComponent, data: {} });
export { RenderType_SecondSidebarComponent as RenderType_SecondSidebarComponent };
function View_SecondSidebarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-growth_curves_brief", [], null, null, null, i2.View_GrowthCurvesBriefComponent_0, i2.RenderType_GrowthCurvesBriefComponent)), i1.ɵdid(1, 245760, null, 0, i3.GrowthCurvesBriefComponent, [i4.AppService, i5.PatientService, i6.PageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_SecondSidebarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "unimed-left-sidebar p-2 bg-white"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SecondSidebarComponent_3)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showGrowthCurves; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SecondSidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "second-side-menu"], ["style", "position: fixed;"]], [[2, "expand", null], [2, "searching", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "slimscroll-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "unimed-left-sidebar p-2 bg-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-brief", [], null, null, null, i8.View_BriefComponent_0, i8.RenderType_BriefComponent)), i1.ɵdid(5, 245760, null, 0, i9.BriefComponent, [i4.AppService, i5.PatientService, i6.PageService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SecondSidebarComponent_2)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 5, 0); var currVal_2 = _co.showGrowthCurves; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.page_state.sidebar_2.expand; var currVal_1 = _co.page_state.searching; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_SecondSidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SecondSidebarComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.page_state.sidebar_2.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SecondSidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-second-sidebar", [], null, null, null, View_SecondSidebarComponent_0, RenderType_SecondSidebarComponent)), i1.ɵdid(1, 4440064, null, 0, i10.SecondSidebarComponent, [i4.AppService, i6.PageService, i7.Location, i11.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SecondSidebarComponentNgFactory = i1.ɵccf("app-second-sidebar", i10.SecondSidebarComponent, View_SecondSidebarComponent_Host_0, {}, {}, []);
export { SecondSidebarComponentNgFactory as SecondSidebarComponentNgFactory };
