import { Component } from '@angular/core';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-institutions_show_institutions',
  templateUrl: './show_institutions.component.html',
  styleUrls: ['./show_institutions.component.scss']
})
export class ShowInstitutionsComponent {

  constructor(private pageService: PageService) {
  }

  close() {
    this.pageService.closeModal("");
  }

}
