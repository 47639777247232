/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@iplab/ngx-file-upload/iplab-ngx-file-upload.ngfactory";
import * as i3 from "@iplab/ngx-file-upload";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/common";
import * as i6 from "../../../../shared/ui/avatar/avatar.component.ngfactory";
import * as i7 from "../../../../shared/ui/avatar/avatar.component";
import * as i8 from "../../../../services/app.service";
import * as i9 from "../../../../shared/ui/form_render/form_render.component.ngfactory";
import * as i10 from "../../../../shared/ui/form_render/form_render.component";
import * as i11 from "../../../../services/pipes.service";
import * as i12 from "./form.component";
import * as i13 from "../general.service";
import * as i14 from "../../../../services/expedient.service";
import * as i15 from "../../../../services/catalogs.service";
var styles_FormComponent = [i0.styles];
var RenderType_FormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormComponent, data: {} });
export { RenderType_FormComponent as RenderType_FormComponent };
function View_FormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "col-12 d-flex align-items-center justify-content-center my-2 flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "text-center profile-image-chooser"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "file-upload", [["accept", "image/x-png,image/gif,image/jpeg"], ["class", "dropzone d-none"], ["name", "imagen"]], [[1, "accept", 0], [2, "has-files", null], [2, "ng-invalid", null], [40, "@.disabled", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [1, "accept", 0]], [[null, "ngModelChange"], [null, "change"], [null, "drop"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("drop" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onDrop($event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.uploadedFiles = $event) !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.fileProgress($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_FileUploadComponent_0, i2.RenderType_FileUploadComponent)), i1.ɵdid(3, 540672, null, 0, i3.FilesAcceptValidator, [], { accept: [0, "accept"] }, null), i1.ɵprd(1024, null, i4.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.FilesAcceptValidator]), i1.ɵprd(512, null, i3.FileUploadService, i3.FileUploadService, [i1.Renderer2]), i1.ɵdid(6, 245760, null, 2, i3.FileUploadComponent, [i3.FileUploadService, i1.ElementRef, i1.Renderer2, i5.DOCUMENT, i1.ChangeDetectorRef], { control: [0, "control"] }, null), i1.ɵqud(603979776, 2, { templateRef: 0 }), i1.ɵqud(603979776, 3, { listItem: 0 }), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.FileUploadComponent]), i1.ɵdid(10, 671744, [["profile_image", 4]], 0, i4.NgModel, [[8, null], [6, i4.NG_VALIDATORS], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"], options: [2, "options"] }, { update: "ngModelChange" }), i1.ɵpod(11, { standalone: 0 }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(13, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), i1.ɵdid(14, 4734976, null, 0, i3.FilesAcceptDirective, [[6, i3.FileUploadComponent], [8, null]], { accept: [0, "accept"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["style", "position: relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "avatar", [], null, null, null, i6.View_AvatarComponent_0, i6.RenderType_AvatarComponent)), i1.ɵdid(17, 49152, null, 0, i7.AvatarComponent, [i8.AppService], { _name: [0, "_name"], _image: [1, "_image"], configs: [2, "configs"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 0, "a", [["class", "image_hover"]], [[2, "must_hover", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.open_file_chooser() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "label", [["class", "text-center title-image"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Imagen de perfil"]))], function (_ck, _v) { var _co = _v.component; var currVal_12 = "image/x-png,image/gif,image/jpeg"; _ck(_v, 3, 0, currVal_12); var currVal_13 = _co.fileUploadControl; _ck(_v, 6, 0, currVal_13); var currVal_14 = "imagen"; var currVal_15 = _co.uploadedFiles; var currVal_16 = _ck(_v, 11, 0, true); _ck(_v, 10, 0, currVal_14, currVal_15, currVal_16); var currVal_17 = "image/x-png,image/gif,image/jpeg"; _ck(_v, 14, 0, currVal_17); var currVal_18 = (((_co.general_state == null) ? null : _co.general_state.firstname) || ((_co.general_state == null) ? null : _co.general_state.lastname)); var currVal_19 = ((_co.general_state == null) ? null : _co.general_state.image); var currVal_20 = _co.avatar_configs; _ck(_v, 17, 0, currVal_18, currVal_19, currVal_20); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 3).accept ? i1.ɵnov(_v, 3).accept : null); var currVal_1 = i1.ɵnov(_v, 6).hasFiles; var currVal_2 = i1.ɵnov(_v, 6).isInvalid; var currVal_3 = i1.ɵnov(_v, 6).isAnimationDisabled; var currVal_4 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 13).ngClassValid; var currVal_9 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 13).ngClassPending; var currVal_11 = (i1.ɵnov(_v, 14).accept ? i1.ɵnov(_v, 14).accept : null); _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); var currVal_21 = (_co.expedient_state.method != "show"); _ck(_v, 18, 0, currVal_21); }); }
export function View_FormComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { form_ref: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-form_render", [], null, null, null, i9.View_FormRenderComponent_0, i9.RenderType_FormRenderComponent)), i1.ɵdid(6, 114688, [[1, 4], ["form_ref", 4]], 0, i10.FormRenderComponent, [i8.AppService, i11.PipesService], { config: [0, "config"], data: [1, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.expedient_state.version == "expedient"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.form_config; var currVal_2 = _co.general_state; _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_FormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-module-form", [], null, null, null, View_FormComponent_0, RenderType_FormComponent)), i1.ɵdid(1, 4440064, null, 0, i12.FormComponent, [i13.GeneralService, i8.AppService, i14.ExpedientService, i15.CatalogsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormComponentNgFactory = i1.ɵccf("app-module-form", i12.FormComponent, View_FormComponent_Host_0, {}, {}, []);
export { FormComponentNgFactory as FormComponentNgFactory };
