import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';
import { AppService } from './app.service';
import { PatientService } from './patient-service.service';
import { ToastrService } from 'ngx-toastr';
import * as i0 from "@angular/core";
import * as i1 from "./app.service";
import * as i2 from "./patient-service.service";
import * as i3 from "ngx-toastr";
;
;
;
export class ExpedientService {
    constructor(endpoint, patientService, toastr) {
        this.endpoint = endpoint;
        this.patientService = patientService;
        this.toastr = toastr;
        this.expedient_session = "ExpedientMetadata";
        if (!this.current_state) {
            this.clean();
            this.next();
        }
    }
    init(expedient_metadata) {
        this.file_list = {
            insurances: {
                accident: []
            }
        };
        this.init_expedient_services();
        this.get_stored(expedient_metadata);
        this.save();
        this.next();
    }
    get state() {
        return this.current_state;
    }
    get_stored(expedient_metadata) {
        this.current_state = expedient_metadata;
    }
    next() {
        if (this.expedient_state) {
            this.expedient_state.next(this.current_state);
        }
        else {
            this.expedient_state = new BehaviorSubject(this.current_state);
            this.current = this.expedient_state.asObservable();
        }
    }
    clean() {
        this.current_state = {
            id_user: null,
            method: "show",
            version: null
        };
    }
    save() {
        localStorage.setItem(this.expedient_session, JSON.stringify(this.current_state));
    }
    change_version(version) {
        this.current_state.version = version;
        this.save();
        this.next();
    }
    change_method(method) {
        this.current_state.method = method;
        this.save();
        this.next();
    }
    change_id_user(id_user) {
        this.current_state.id_user = id_user;
        this.save();
        this.next();
    }
    cancel(callback = (callback_data) => { }) {
        Swal.fire({
            title: "Cancelar Actualización de Paciente",
            html: "¿Está seguro de cancelar la actualización del paciente actual?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FDB915',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cancelar',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value == true) {
                localStorage.removeItem("inExpedientGeneral");
                callback();
            }
        });
    }
    // MODULES
    init_expedient_services() {
        this.expedient_services = {
            general: null,
            contacts: {
                doctor: null,
                emergency: null
            },
            procedures: {
                doctor: null
            },
            references: {
                doctor: null
            },
            background: {
                allergies: null,
                pathologies: null
            },
            insurances: {
                accident: null
            },
            exams: {
                laboratory: null
            },
            relations: {
                dependants: null,
                tutors: null
            },
            institutions: null,
        };
    }
    set_expedient_service(ref, att1, att2) {
        if (this.expedient_services) {
            if (att2) {
                this.expedient_services[att1][att2] = ref;
            }
            else {
                this.expedient_services[att1] = ref;
            }
        }
    }
    submit_expedient_services() {
        this.current_state.loading = true;
        this.next();
        this._submit_expedient_services();
    }
    end_submit_expedient_services() {
        this.current_state.loading = false;
        localStorage.removeItem("inNewPatient");
        this.patientService.change_patient_id(this.current_state.id_user);
        this.endpoint.redirect_to("/expediente/consultas");
        this.next();
    }
    _submit_expedient_services() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // GENERAL
                this.current_state.method = "insert";
                let minor = false;
                if (this.expedient_services.general) {
                    let general_async = new Promise((resolve, reject) => {
                        this.expedient_services.general.validate((data) => {
                            if (data && data.minor) {
                                minor = data.minor;
                            }
                            resolve(true);
                        });
                    });
                    yield general_async;
                }
                if (this.expedient_services.relations.tutors && minor) {
                    let relations_tutors_async = new Promise((resolve, reject) => {
                        this.expedient_services.relations.tutors.validate((data) => {
                            if (data.list.list.length > 0) {
                                resolve(true);
                            }
                            else {
                                this.toastr.error("El paciente es menor de edad, por lo que es obligatorio que se asigne un tutor.", "Paciente Menor de Edad");
                                reject(false);
                            }
                        });
                    });
                    yield relations_tutors_async;
                }
                if (this.expedient_services.general) {
                    let general_async = new Promise((resolve, reject) => {
                        this.expedient_services.general.submit((data) => {
                            if (data && data.id_user) {
                                this.current_state.id_user = data.id_user;
                                resolve(data.id_user);
                            }
                            else {
                                reject(data);
                            }
                        });
                    });
                    yield general_async;
                }
                if (this.current_state.id_user) {
                    // RELATIONS
                    // if (this.expedient_services.relations.dependants) {
                    //   let relations_dependants_async = new Promise((resolve, reject) => {
                    //     this.expedient_services.relations.dependants.submit(resolve);
                    //   });
                    //   await relations_dependants_async;
                    // }
                    if (this.expedient_services.relations.tutors) {
                        let relations_tutors_async = new Promise((resolve, reject) => {
                            this.expedient_services.relations.tutors.submit(resolve);
                        });
                        yield relations_tutors_async;
                    }
                    // CONTACTS
                    if (this.expedient_services.contacts.doctor) {
                        let contacts_doctor_async = new Promise((resolve, reject) => {
                            this.expedient_services.contacts.doctor.submit(resolve);
                        });
                        yield contacts_doctor_async;
                    }
                    if (this.expedient_services.contacts.emergency) {
                        let contacts_emergency_async = new Promise((resolve, reject) => {
                            this.expedient_services.contacts.emergency.submit(resolve);
                        });
                        yield contacts_emergency_async;
                    }
                    // // PROCEDURES
                    // if (this.expedient_services.procedures.doctor) {
                    //   let procedures_doctor_async = new Promise((resolve, reject) => {
                    //     this.expedient_services.procedures.doctor.submit(resolve);
                    //   });
                    //   await procedures_doctor_async;
                    // }
                    // // EXAMS
                    // if (this.expedient_services.exams.laboratory) {
                    //   let exams_laboratory_async = new Promise((resolve, reject) => {
                    //     this.expedient_services.exams.laboratory.submit(resolve);
                    //   });
                    //   await exams_laboratory_async;
                    // }
                    // // REFERENCES
                    // if (this.expedient_services.references.doctor) {
                    //   let references_doctor_async = new Promise((resolve, reject) => {
                    //     this.expedient_services.references.doctor.submit(resolve);
                    //   });
                    //   await references_doctor_async;
                    // }
                    // BACKGROUND
                    if (this.expedient_services.background.pathologies) {
                        let background_pathologies_async = new Promise((resolve, reject) => {
                            this.expedient_services.background.pathologies.submit(resolve);
                        });
                        yield background_pathologies_async;
                    }
                    if (this.expedient_services.background.allergies) {
                        let background_allergies_async = new Promise((resolve, reject) => {
                            this.expedient_services.background.allergies.submit(resolve);
                        });
                        yield background_allergies_async;
                    }
                    // // INSURANCES
                    // if (this.expedient_services.insurances.accident) {
                    //   let insurances_accident_async = new Promise((resolve, reject) => {
                    //     this.expedient_services.insurances.accident.submit(resolve);
                    //   });
                    //   await insurances_accident_async;
                    // }
                    // // RELATIONS
                    // if (this.expedient_services.institutions) {
                    //   let institutions_async = new Promise((resolve, reject) => {
                    //     this.expedient_services.institutions.submit(resolve);
                    //   });
                    //   await institutions_async;
                    // }
                    this.end_submit_expedient_services();
                }
            }
            catch (error) {
                this.current_state.loading = false;
                console.error(error);
            }
        });
    }
    update_global() {
        this.patientService.load_patient_data();
    }
}
ExpedientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExpedientService_Factory() { return new ExpedientService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.PatientService), i0.ɵɵinject(i3.ToastrService)); }, token: ExpedientService, providedIn: "root" });
