/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./third-sidebar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./third-sidebar.component";
import * as i5 from "../../services/app.service";
import * as i6 from "../../services/page.service";
var styles_ThirdSidebarComponent = [i0.styles];
var RenderType_ThirdSidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ThirdSidebarComponent, data: {} });
export { RenderType_ThirdSidebarComponent as RenderType_ThirdSidebarComponent };
function View_ThirdSidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "height: 100%"]], [[2, "expand", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "unimed-left-sidebar"]], [[2, "no-background", null], [2, "shadow", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.page_state.sidebar_3.expand; _ck(_v, 0, 0, currVal_0); var currVal_1 = !_co.page_state.sidebar_3.background; var currVal_2 = _co.page_state.sidebar_3.shadow; _ck(_v, 1, 0, currVal_1, currVal_2); }); }
export function View_ThirdSidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ThirdSidebarComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.page_state.sidebar_3.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ThirdSidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-third-sidebar", [], null, null, null, View_ThirdSidebarComponent_0, RenderType_ThirdSidebarComponent)), i1.ɵdid(1, 4440064, null, 0, i4.ThirdSidebarComponent, [i5.AppService, i6.PageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ThirdSidebarComponentNgFactory = i1.ɵccf("app-third-sidebar", i4.ThirdSidebarComponent, View_ThirdSidebarComponent_Host_0, {}, {}, []);
export { ThirdSidebarComponentNgFactory as ThirdSidebarComponentNgFactory };
