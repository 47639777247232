<div class="d-flex flex-column pr-3 py-2 h-100">
    <div class="container-fluid px-0">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-between mb-2">
                    <div class="d-flex flex-column my-auto">
                        <ng-container *ngIf="expedient_state.version=='expedient'">
                            <div *ngIf="page_state" class="title-last mt-auto">
                                expediente
                            </div>
                        </ng-container>
                        <div class="title mb-auto"> seguros </div>
                    </div>

                    <div class="my-auto">
                        <div class="d-flex text-right">
                            <ng-container *ngIf="expedient_state.version=='expedient'">
                                <app-search_button (change)="on_update()" (submit)="on_update()" #search_button_ref></app-search_button>
                            </ng-container>
                            <button class="ml-2 btn radius btn-primary no-wrap" style="font-weight: 500;" (click)="create()"><i class="fa-duotone fa-plus"></i> SEGURO </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-12 mb-2">
                <app-dynamic_tabs [config]="dynamic_tabs"> </app-dynamic_tabs>
            </div> -->
        </div>
    </div>
    <div class="row">
        <ng-container *ngIf="list_state.list && list_state.list.length > 0">
            <div *ngFor="let item of list_state.list; let i = index" class="col-md-6 col-lg-6 col-xl-4 mb-2">
                <app-insurance-card [item]="item" [i]="i" [events]="datatable.events" (event)="events($event)"></app-insurance-card>
            </div>
        </ng-container>
        <ng-container *ngIf="!(list_state.list && list_state.list.length > 0)">
            <div class="col-12">
                <div class="d-flex justify-content-center align-items-center">
                    <app-custom-holder
                        [icon]='"fa-duotone fa-light-emergency-on fa-2x text-warning"'
                        [icon_show]='true'
                        [title]='"h5"'
                        [title_text]='"No hay " + datatable.configs.title'
                        [title_show]='true'
                        [inline]="true">
                    </app-custom-holder>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #showModal role="document">
    <app-insurances_show_accident></app-insurances_show_accident>
</ng-template>

<ng-template #createModal role="document">
    <app-insurances_create_accident></app-insurances_create_accident>
</ng-template>

<ng-template #updateModal role="document">
    <app-insurances_update_accident></app-insurances_update_accident>
</ng-template>