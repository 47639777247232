import { Component } from '@angular/core';
import { PathologiesService } from '../pathologies.service';

@Component({
  selector: 'app-background_update_pathologies',
  templateUrl: './update_pathologies.component.html',
  styleUrls: ['./update_pathologies.component.scss']
})
export class UpdateBackgroundPathologiesComponent {

  constructor(private moduleService: PathologiesService) {
  }

  close() {
    this.moduleService.cancel();
  }

}
