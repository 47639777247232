import { AccountService } from 'src/app/services/account.service';
import { PageService } from "src/app/services/page.service";
import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ProfileClass, RawProfileInterface } from "src/app/classes/profile/profile-class";
import { AppService } from "src/app/services/app.service";
import { CatalogsService } from "src/app/services/catalogs.service";
import { PipesService } from "src/app/services/pipes.service";

@Injectable({ providedIn: "root" })
export class ProfileService {
    public current_state: ProfileClass;
    private subject: BehaviorSubject<ProfileClass>;
    public observer: Observable<ProfileClass>;

    constructor(private endpoint: AppService, private toastr: ToastrService, private pipesService: PipesService, private catalogsService: CatalogsService, private pageService: PageService, public accountService: AccountService) {
        this.current_state = new ProfileClass(this.catalogsService, this.pipesService);
        this.next();
    }

    next() {
        if (this.subject) {
            this.subject.next(this.current_state);
        } else {
            this.subject = new BehaviorSubject(this.current_state);
            this.observer = this.subject.asObservable();
        }
    }

    clean() {
        this.current_state.reset();
    }

    init(id_user: any) {
        this.load_profile(id_user);
    }

    load_profile(username: any = "") {
        this.endpoint.profile_get_profile({ username: username }).subscribe(
            (data: RawProfileInterface) => {
                this.current_state.overwrite(data);
                this.current_state.to_string();
            },
            (error) => {
                console.error(error);
                this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + "");
            }
        );
    }

    openModal(content, size = "lg", callback = (callback_data?: any) => { }) {
        this.pageService.openModal(content, size, callback);
    }

    closeModal(data = "") {
        this.pageService.closeModal(data);
    }

    remove_profile_image() {
         this.endpoint.profile_remove_profile_image().subscribe(
            (data: any) => {
                this.toastr.success(data["message"], data["title"]);
                this.load_profile(this.current_state.username);
                this.accountService.reload();
                location.reload();
            },
            (error) => {
                console.error(error);
                this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + "");
            }
        );
    }

    upload_profile_image(uploadedFiles) {
        const formData = new FormData();
        if (uploadedFiles && uploadedFiles[0]) {
            formData.append("profile_image", uploadedFiles[0], uploadedFiles[0]["name"]);
        }
        this.endpoint.profile_upload_profile_image(formData).subscribe(
            (data: any) => {
                this.toastr.success(data["message"], data["title"]);
                uploadedFiles.splice(0);
                this.load_profile(this.current_state.username);
                this.accountService.reload();
                location.reload();
            },
            (error) => {
                console.error(error);
                this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + "");
            }
        );
    }
}
