/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bold_list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../request_holders/custom-holder/custom-holder.component.ngfactory";
import * as i4 from "../request_holders/custom-holder/custom-holder.component";
import * as i5 from "../request_holders/spinners/small/spinner.component.ngfactory";
import * as i6 from "../request_holders/spinners/small/spinner.component";
import * as i7 from "../request_holders/errors/small/error.component.ngfactory";
import * as i8 from "../request_holders/errors/small/error.component";
import * as i9 from "../request_holders/spinners/big/spinner.component.ngfactory";
import * as i10 from "../request_holders/spinners/big/spinner.component";
import * as i11 from "../request_holders/errors/big/error.component.ngfactory";
import * as i12 from "../request_holders/errors/big/error.component";
import * as i13 from "../pipes/shortDate";
import * as i14 from "./bold_list.component";
var styles_BoldListComponent = [i0.styles];
var RenderType_BoldListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BoldListComponent, data: {} });
export { RenderType_BoldListComponent as RenderType_BoldListComponent };
function View_BoldListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_BoldListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col-12 mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "card-box text-center radius"], ["style", "border: none !important;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "d-flex justify-content-center align-iteml-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loading || _co.error); var currVal_1 = i1.ɵnov(_v.parent, 10); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
function View_BoldListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas"]], [[2, "fa-chevron-up", null], [2, "fa-chevron-down", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filters.order_asc; var currVal_1 = !_co.filters.order_asc; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_BoldListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-chevron-down"]], null, null, null, null, null))], null, null); }
function View_BoldListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "blh"]], [[2, "selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.orderBy(_v.context.$implicit.order_by) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "flex": 0, "max-width": 1 }), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_6)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, (("0 0 " + _co.calculate_cols()) + "%"), (_co.calculate_cols() + "%")); _ck(_v, 2, 0, currVal_1); var currVal_3 = (_co.filters.order_by == _v.context.$implicit.order_by); _ck(_v, 6, 0, currVal_3); var currVal_4 = (_co.filters.order_by != _v.context.$implicit.order_by); _ck(_v, 8, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.filters.order_by == _v.context.$implicit.order_by); _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.context.$implicit.label; _ck(_v, 4, 0, currVal_2); }); }
function View_BoldListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "blh open"]], null, null, null, null, null))], null, null); }
function View_BoldListComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "big_icon"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.configs.big_icon(_v.parent.context.$implicit).title; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.configs.big_icon(_v.parent.context.$implicit).icon, ""); _ck(_v, 1, 0, currVal_1); }); }
function View_BoldListComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit[_v.parent.context.$implicit.name]; _ck(_v, 1, 0, currVal_0); }); }
function View_BoldListComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.parent.context.$implicit[_v.parent.context.$implicit.name] ? "Deshabilitado" : "Habilitado"); _ck(_v, 1, 0, currVal_0); }); }
function View_BoldListComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.parent.parent.context.$implicit[_v.parent.context.$implicit.name])); _ck(_v, 1, 0, currVal_0); }); }
function View_BoldListComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit[_v.parent.context.$implicit.name]; _ck(_v, 1, 0, currVal_0); }); }
function View_BoldListComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "flex": 0, "max-width": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_11)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_12)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_13)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_14)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, (("0 0 " + _co.calculate_cols()) + "%"), (_co.calculate_cols() + "%")); _ck(_v, 2, 0, currVal_1); var currVal_2 = (((_v.context.$implicit.type != "date") && (_v.context.$implicit.type != "disabled")) && (_v.context.$implicit.type != "icon")); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_v.context.$implicit.type == "disabled"); _ck(_v, 7, 0, currVal_3); var currVal_4 = (_v.context.$implicit.type == "date"); _ck(_v, 9, 0, currVal_4); var currVal_5 = (_v.context.$implicit.type == "icon"); _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = ("bld " + (_v.context.$implicit.class ? _v.context.$implicit.class : "")); _ck(_v, 0, 0, currVal_0); }); }
function View_BoldListComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_BoldListComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_BoldListComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "ml-2"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.event(_v.parent.parent.context.$implicit.name, _v.parent.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.name; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.icon, ""); _ck(_v, 1, 0, currVal_1); }); }
function View_BoldListComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "ml-2"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa-duotone fa-minus text-muted"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.name; _ck(_v, 0, 0, currVal_0); }); }
function View_BoldListComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_20)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_21)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (!_v.parent.context.$implicit.disabled || !(_v.parent.context.$implicit.disabled && _v.parent.context.$implicit.disabled(_v.parent.parent.parent.context.$implicit))); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit.disabled && _v.parent.context.$implicit.disabled(_v.parent.parent.parent.context.$implicit)); _ck(_v, 4, 0, currVal_1); }, null); }
function View_BoldListComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_18)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_19)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !(_v.context.$implicit.show ? _v.context.$implicit.show(_v.parent.parent.context.$implicit) : true); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.show ? _v.context.$implicit.show(_v.parent.parent.context.$implicit) : true); _ck(_v, 4, 0, currVal_1); }, null); }
function View_BoldListComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "bld open"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_17)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.events; _ck(_v, 2, 0, currVal_0); }, null); }
function View_BoldListComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "blc"]], [[2, "selected", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_9)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "blr"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "blrcontent"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.event("row_click", _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_10)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "bld content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_15)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_16)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.configs.big_icon; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.headers; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.contents; _ck(_v, 11, 0, currVal_3); var currVal_4 = (_co.events && (_co.events.length > 0)); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.configs.selected ? _co.configs.selected(_v.context.$implicit) : false); _ck(_v, 1, 0, currVal_0); }); }
function View_BoldListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "bold-list"]], [[2, "open", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "blhead"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "blr"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_4)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_7)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "blbody"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_8)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.headers; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.configs.open; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.data.list; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.configs.open; _ck(_v, 1, 0, currVal_0); }); }
function View_BoldListComponent_22(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col-12 mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "text-center radius"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "d-flex justify-content-center align-iteml-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-custom-holder", [], null, null, null, i3.View_CustomHolderComponent_0, i3.RenderType_CustomHolderComponent)), i1.ɵdid(4, 573440, null, 0, i4.CustomHolderComponent, [], { icon: [0, "icon"], icon_show: [1, "icon_show"], title: [2, "title"], title_text: [3, "title_text"], title_show: [4, "title_show"], inline: [5, "inline"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "fa-duotone fa-light-emergency-on fa-2x text-warning"; var currVal_1 = true; var currVal_2 = "h5"; var currVal_3 = ("No hay " + _co.configs.title); var currVal_4 = true; var currVal_5 = true; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_BoldListComponent_23(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner-small", [], null, null, null, i5.View_SpinnerSmallComponent_0, i5.RenderType_SpinnerSmallComponent)), i1.ɵdid(1, 573440, null, 0, i6.SpinnerSmallComponent, [], { loading: [0, "loading"], class: [1, "class"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-error-small", [], null, null, null, i7.View_ErrorSmallComponent_0, i7.RenderType_ErrorSmallComponent)), i1.ɵdid(3, 573440, null, 0, i8.ErrorSmallComponent, [], { error: [0, "error"], class: [1, "class"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; var currVal_1 = "h1"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.error; var currVal_3 = "h1"; _ck(_v, 3, 0, currVal_2, currVal_3); }, null); }
function View_BoldListComponent_24(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-spinner-big", [], null, null, null, i9.View_SpinnerBigComponent_0, i9.RenderType_SpinnerBigComponent)), i1.ɵdid(1, 573440, null, 0, i10.SpinnerBigComponent, [], { loading: [0, "loading"], title: [1, "title"], inline: [2, "inline"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-error-big", [], null, null, null, i11.View_ErrorBigComponent_0, i11.RenderType_ErrorBigComponent)), i1.ɵdid(3, 573440, null, 0, i12.ErrorBigComponent, [], { error: [0, "error"], title: [1, "title"], inline: [2, "inline"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; var currVal_1 = "Cargando..."; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.error; var currVal_4 = _co.error_desc; var currVal_5 = true; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5); }, null); }
export function View_BoldListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i13.shortDate, []), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoldListComponent_22)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["requestholdersmall", 2]], null, 0, null, View_BoldListComponent_23)), (_l()(), i1.ɵand(0, [["requestholderbig", 2]], null, 0, null, View_BoldListComponent_24))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loading || _co.error); _ck(_v, 4, 0, currVal_0); var currVal_1 = ((((!_co.loading && !_co.error) && _co.data) && _co.data.list) && (_co.data.list.length > 0)); _ck(_v, 6, 0, currVal_1); var currVal_2 = (((!_co.loading && !_co.error) && (_co.data.list && (_co.data.list.length <= 0))) || !_co.data.list); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_BoldListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bold_list", [], null, null, null, View_BoldListComponent_0, RenderType_BoldListComponent)), i1.ɵdid(1, 114688, null, 0, i14.BoldListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BoldListComponentNgFactory = i1.ɵccf("app-bold_list", i14.BoldListComponent, View_BoldListComponent_Host_0, { in_headers: "in_headers", in_events: "in_events", in_contents: "in_contents", in_data: "in_data", in_filters: "in_filters", in_configs: "in_configs" }, { update_bold_list: "update_bold_list", events_bold_list: "events_bold_list" }, []);
export { BoldListComponentNgFactory as BoldListComponentNgFactory };
