import { Component } from '@angular/core';
import { AllergiesService } from '../allergies.service';

@Component({
  selector: 'app-background_update_allergies',
  templateUrl: './update_allergies.component.html',
  styleUrls: ['./update_allergies.component.scss']
})
export class UpdateBackgroundAllergiesComponent {

  constructor(private moduleService: AllergiesService) {
  }

  close() {
    this.moduleService.cancel();
  }

}
