import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges, OnDestroy } from '@angular/core';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { Subscription } from 'rxjs';
import { AccountService, AccountState } from 'src/app/services/account.service';
import { AppService } from 'src/app/services/app.service';
import { PageService, PageState } from 'src/app/services/page.service';
import { PatientService } from 'src/app/services/patient-service.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  private page_subscription: Subscription;
  public page_state: PageState;
  private account_subscription: Subscription;
  public account_state: AccountState;
  private patient_subscription: Subscription;
  public patient_data: any;

  public stored_patient_id: any;

  menu: any;

  public avatar_configs = { size: "1.5rem" }; //1.125rem

  @ViewChild('sideMenu', { static: false }) sideMenu: ElementRef;

  constructor(private pageService: PageService, private patientService: PatientService, private accountService: AccountService, private endpoint: AppService) { }

  ngOnInit() {
    this.patient_subscription = this.patientService.patient_data_subscription.subscribe(
      data => {
        this.patient_data = data;
        this.stored_patient_id = this.endpoint.get_patient_id();
      }
    );
    this.page_subscription = this.pageService.current.subscribe(
      state => {
        this.page_state = state;
      }
    );
    this.account_subscription = this.accountService.current.subscribe(
      state => {
        this.account_state = state;
        this.stored_patient_id = this.endpoint.get_patient_id();
      }
    );
  }

  ngOnDestroy() {
    this.patient_subscription.unsubscribe();
    this.page_subscription.unsubscribe();
    this.account_subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this._activateMenuDropdown();
  }

  _activateMenuDropdown() {
    const links = document.getElementsByClassName('side-nav-link-ref');
    let menuItemEl = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (window.location.pathname === links[i]['pathname']) {
        menuItemEl = links[i];
        break;
      }
    }

    if (menuItemEl) {
      menuItemEl.classList.add('active');

      const parentEl = menuItemEl.parentElement;
      if (parentEl) {
        parentEl.classList.add('active');

        const parent2El = parentEl.parentElement;
        if (parent2El) {
          parent2El.classList.add('in');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.add('active');
          parent3El.firstChild.classList.add('active');
        }
      }
    }
  }

  set_logout() {
    this.endpoint.logout().subscribe(
      () => {
        this.endpoint.reset_session();
        this.endpoint.redirect_to('/conectarse');
      }
    );
  }

  validate() {
    if (localStorage.getItem("detail")) {
      localStorage.removeItem("detail")
    }
  }
}
