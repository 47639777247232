import { AppService } from "src/app/services/app.service";
import { WebDBService } from "./webdb.service";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "./webdb.service";
import * as i2 from "./app.service";
export class CatalogsService {
    constructor(dbService, endpoint) {
        this.dbService = dbService;
        this.endpoint = endpoint;
        this.jsons = {
            vaccines: ["descriptions", "dose"],
        };
        this.init();
    }
    init() {
        this.clean();
        this.next();
        if (environment.dev) {
            this.fill();
        }
        else {
            let date = new Date();
            date.setHours(0, 0, 0, 0);
            let s = this.get_session();
            if (+date + "" != s) {
                this.fill();
            }
            else {
                this.fill();
                // this.bulk();
            }
        }
    }
    fill(callback = () => { }) {
        this.endpoint.get_catalogs({ catalogs: this.get_keys() }).subscribe((catalogs) => {
            this.dbService.overwrite(catalogs, () => {
                this.get_keys().forEach(value => {
                    let catalog = value ? catalogs[value] : [];
                    if (this.jsons[value]) {
                        this.jsons[value].forEach(j => {
                            catalog.forEach(c => {
                                if (c[j])
                                    c[j] = JSON.parse(c[j]);
                            });
                        });
                    }
                    if (value)
                        this.catalogs[value] = catalog;
                });
                let date = new Date();
                localStorage.setItem("fdbv", +date + "");
                date.setHours(0, 0, 0, 0);
                localStorage.setItem("dbv", +date + "");
                console.warn("UPDATE:fill: IndexedDB");
                callback();
            });
        }, error => {
            console.error(error);
        });
    }
    bulk(callback = () => { }) {
        this.get_keys().forEach(value => {
            this.dbService.getAll(value).subscribe(data => {
                this.get_keys().forEach(value => {
                    let catalog = value ? data : [];
                    if (this.jsons[value]) {
                        this.jsons[value].forEach(j => {
                            catalog.forEach(c => {
                                if (c[j]) {
                                    c[j] = JSON.parse(c[j]);
                                }
                            });
                        });
                    }
                    if (value)
                        this.catalogs[value] = catalog;
                });
            });
        });
        console.warn("UPDATE:bulk: IndexedDB");
        callback();
    }
    load_catalog(catalog) {
        this.dbService.getAll(catalog).subscribe(data => {
            this.catalogs[catalog] = data;
        });
    }
    set_catalog(catalog, value) {
        this.catalogs[catalog] = value;
    }
    next() {
        if (this.behavior_catalogs) {
            this.behavior_catalogs.next(this.catalogs);
        }
        else {
            this.behavior_catalogs = new BehaviorSubject(this.catalogs);
            this.observable_catalogs = this.behavior_catalogs.asObservable();
        }
    }
    clean() {
        this.catalogs = {
            pathologies: [],
            allergies_reactions_levels: [],
            allergies_reactions_types: [],
            bloods: [],
            chat_conversation_end_types: [],
            chat_message_types: [],
            civil_statuses: [],
            consults_statuses: [],
            consults_types: [],
            countries: [],
            departments: [],
            doctors_patients_levels: [],
            doctors_specialties: [],
            educations: [],
            exams_names: [],
            exams_names_2: [],
            exams_types: [],
            file_types: [],
            genders: [],
            institutions_areas: [],
            institutions_roles: [],
            institutions_types: [],
            institutions_users_roles: [],
            medicines_concentrations: [],
            medicines_days: [],
            medicines_frecuencies: [],
            medicines_presentations: [],
            medicines_ways: [],
            procedures_types: [],
            towns: [],
            units_breathing_rate: [],
            units_diastolic_pressure: [],
            units_eye_vision: [],
            units_glasgow_eye: [],
            units_glasgow_move: [],
            units_glasgow_talk: [],
            units_heart_rate: [],
            units_height: [],
            units_IMC: [],
            units_pulse: [],
            units_sistolic_pressure: [],
            units_temp: [],
            units_weight: [],
            units_medicine_dosage: [],
            users_roles: [],
            users_types: [],
            tf: [],
            profiles_educations_types: [],
            profiles_educations_study_types: [],
            profiles_educations_modes: [],
            profiles_educations_honors: [],
            profiles_educations_places: [],
            profiles_educations_specialties: [],
            profiles_educations_subspecialties: [],
            profiles_experiences_types: [],
            specialties_areas: [],
            vaccines: [],
            vaccines_dosis_statuses: [],
            vaccines_people_groups: [],
            relationships: [],
        };
    }
    get_keys() {
        if (!this.catalogs)
            this.clean();
        let keys = [];
        for (const key in this.catalogs) {
            keys.push(key);
        }
        return keys;
    }
    generate() {
        this.clean();
        let result = [];
        for (const attr in this.catalogs) {
            const obj = {};
            obj.store = attr;
            obj.storeConfig = { keyPath: "id", autoIncrement: false };
            obj.storeSchema = [];
            result.push(obj);
        }
        return result;
    }
    get_session() {
        return localStorage.getItem("dbv");
    }
    get_full_session() {
        return localStorage.getItem("fdbv");
    }
    selectWhereLike(list, columns) {
        return this.dbService.selectWhereLike(list, columns);
    }
}
CatalogsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CatalogsService_Factory() { return new CatalogsService(i0.ɵɵinject(i1.WebDBService), i0.ɵɵinject(i2.AppService)); }, token: CatalogsService, providedIn: "root" });
