import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { Subscription } from "rxjs";
import { AppService } from "src/app/services/app.service";
import { ExpedientMetadata, ExpedientService } from "src/app/services/expedient.service";
import { PageService, PageState } from "src/app/services/page.service";
import { dynamic_click_tabs_type } from "src/app/shared/ui/dynamic_tabs/dynamic_tabs.component";

@Component({
    selector: "app-new_patient-v2",
    templateUrl: "./new_patient.component.html",
    styleUrls: ["./new_patient.component.scss"],
})
export class NewPatientComponent implements OnInit, OnDestroy {
    private page_subscription: Subscription;
    public page_state: PageState;
    private expedient_subscription: Subscription;
    public expedient_state: ExpedientMetadata;

    public current_element: any;

    public dynamic_tabs: dynamic_click_tabs_type;

    @ViewChild("scroll_ref", { static: true }) scroll_ref: ElementRef;

    @ViewChild("general_ref", { static: true }) general_ref: ElementRef;
    @ViewChild("contacts_ref", { static: true }) contacts_ref: ElementRef;
    @ViewChild("background_ref", { static: true }) background_ref: ElementRef;
    @ViewChild("insurance_ref", { static: true }) insurance_ref: ElementRef;
    @ViewChild("relations_ref", { static: true }) relations_ref: ElementRef;
    @ViewChild("institution_ref", { static: true }) institution_ref: ElementRef;

    constructor(private endpoint: AppService, private pageService: PageService, private expedientService: ExpedientService) {
        localStorage.removeItem("unimed-expedient-general");
        localStorage.removeItem("unimed-expedient-contacts-emergency");
        localStorage.removeItem("unimed-expedient-contacts-emergency-list");
        localStorage.removeItem("unimed-expedient-contacts-doctor");
        localStorage.removeItem("unimed-expedient-contacts-doctor-list");
        localStorage.removeItem("unimed-expedient-background-pathologies");
        localStorage.removeItem("unimed-expedient-background-pathologies-list");
        localStorage.removeItem("unimed-expedient-background-allergies");
        localStorage.removeItem("unimed-expedient-background-allergies-list");
        localStorage.removeItem("unimed-expedient-insurances-accident");
        localStorage.removeItem("unimed-expedient-insurances-accident-list");
        localStorage.removeItem("unimed-expedient-relations-dependants");
        localStorage.removeItem("unimed-expedient-relations-dependants-list");
        localStorage.removeItem("unimed-expedient-relations-tutors");
        localStorage.removeItem("unimed-expedient-relations-tutors-list");
        localStorage.removeItem("unimed-expedient-institutions");
        localStorage.removeItem("unimed-expedient-institutions-list");
    }

    ngOnInit() {
        this.page_subscription = this.pageService.current.subscribe((state) => {
            this.page_state = state;
            this.dynamic_tabs_config();
        });
        this.expedient_subscription = this.expedientService.current.subscribe((state) => (this.expedient_state = state));
    }

    ngOnDestroy() {
        this.page_subscription.unsubscribe();
        this.expedient_subscription.unsubscribe();
    }

    dynamic_tabs_config() {
        this.dynamic_tabs = {
            selected: 0,
            list: [
                {
                    class: "mx-2",
                    name: "general",
                    active_stroke: "4px",
                    font_size: "0.9rem",
                    click: () => {
                        this.general_ref.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
                        this.dynamic_tabs.selected = 0;
                    },
                },
                {
                    class: "mx-2",
                    name: "contactos",
                    active_stroke: "4px",
                    font_size: "0.9rem",
                    click: () => {
                        this.contacts_ref.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
                        this.dynamic_tabs.selected = 1;
                    },
                },
                {
                    class: "mx-2",
                    name: "antecedentes",
                    active_stroke: "4px",
                    font_size: "0.9rem",
                    click: () => {
                        this.background_ref.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
                        this.dynamic_tabs.selected = 2;
                    },
                },
                // {
                //     class: "mx-2",
                //     name: "seguros",
                //     active_stroke: "4px",
                //     font_size: "0.9rem",
                //     click: () => {
                //         this.insurance_ref.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
                //         this.dynamic_tabs.selected = 3;
                //     },
                // },
                {
                    class: "mx-2",
                    name: "relaciones",
                    active_stroke: "4px",
                    font_size: "0.9rem",
                    click: () => {
                        this.relations_ref.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
                        this.dynamic_tabs.selected = 4;
                    },
                },
                // {
                //     class: "mx-2",
                //     name: "instituciones",
                //     active_stroke: "4px",
                //     font_size: "0.9rem",
                //     click: () => {
                //         this.institution_ref.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
                //         this.dynamic_tabs.selected = 5;
                //     },
                // },
            ],
        };
    }

    isInViewport(element) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            //rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    isScrolling() {
        if (this.isInViewport(this.general_ref.nativeElement)) {
            if (this.dynamic_tabs.selected != 0) {
                this.dynamic_tabs.selected = 0;
            }
        } else if (this.isInViewport(this.contacts_ref.nativeElement)) {
            if (this.dynamic_tabs.selected != 1) {
                this.dynamic_tabs.selected = 1;
            }
        } else if (this.isInViewport(this.background_ref.nativeElement)) {
            if (this.dynamic_tabs.selected != 2) {
                this.dynamic_tabs.selected = 2;
            }
        } else if (this.isInViewport(this.insurance_ref.nativeElement)) {
            if (this.dynamic_tabs.selected != 3) {
                this.dynamic_tabs.selected = 3;
            }
        } else if (this.isInViewport(this.insurance_ref.nativeElement)) {
            if (this.dynamic_tabs.selected != 4) {
                this.dynamic_tabs.selected = 4;
            }
        } else if (this.isInViewport(this.institution_ref.nativeElement)) {
            if (this.dynamic_tabs.selected != 5) {
                this.dynamic_tabs.selected = 5;
            }
        }
    }

    cancel() {
        this.endpoint.redirect_to("/mis_pacientes");
    }

    submit() {
        this.expedientService.submit_expedient_services();
    }
}
