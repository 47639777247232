import { Component } from '@angular/core';
import { ExpedientService } from 'src/app/services/expedient.service';
import { PathologiesService } from '../pathologies.service';

@Component({
  selector: 'app-background_create_pathologies',
  templateUrl: './create_pathologies.component.html',
  styleUrls: ['./create_pathologies.component.scss']
})
export class CreateBackgroundPathologiesComponent {

  constructor(private moduleService: PathologiesService, private expedientService: ExpedientService) {
    this.expedientService.change_method("insert");
    this.moduleService.init();
  }

  close() {
    this.moduleService.cancel();
  }

}
