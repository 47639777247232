export let Percentiles = {
    weight: {
        boys: {
            P01: [
                2.048,
                2.033,
                2.049,
                2.068,
                2.09,
                2.114,
                2.138,
                2.163,
                2.189,
                2.215,
                2.242,
                2.271,
                2.3,
                2.33,
                2.36,
                2.392,
                2.424,
                2.456,
                2.489,
                2.522,
                2.555,
                2.587,
                2.62,
                2.653,
                2.686,
                2.719,
                2.751,
                2.784,
                2.816,
                2.848,
                2.88,
                2.912,
                2.943,
                2.974,
                3.005,
                3.036,
                3.066,
                3.097,
                3.126,
                3.156,
                3.185,
                3.215,
                3.243,
                3.272,
                3.3,
                3.328,
                3.356,
                3.383,
                3.411,
                3.437,
                3.464,
                3.491,
                3.517,
                3.543,
                3.569,
                3.594,
                3.62,
                3.645,
                3.67,
                3.694,
                3.719,
                3.743,
                3.767,
                3.791,
                3.814,
                3.837,
                3.861,
                3.884,
                3.906,
                3.929,
                3.951,
                3.974,
                3.996,
                4.018,
                4.039,
                4.06,
                4.082,
                4.103,
                4.124,
                4.144,
                4.165,
                4.185,
                4.205,
                4.225,
                4.245,
                4.265,
                4.284,
                4.304,
                4.323,
                4.342,
                4.361,
                4.38,
                4.398,
                4.417,
                4.435,
                4.453,
                4.471,
                4.489,
                4.506,
                4.524,
                4.541,
                4.558,
                4.576,
                4.592,
                4.609,
                4.626,
                4.643,
                4.659,
                4.675,
                4.692,
                4.708,
                4.724,
                4.739,
                4.755,
                4.771,
                4.786,
                4.802,
                4.817,
                4.832,
                4.847,
                4.862,
                4.877,
                4.891,
                4.906,
                4.92,
                4.935,
                4.949,
                4.963,
                4.977,
                4.991,
                5.005,
                5.019,
                5.032,
                5.046,
                5.059,
                5.073,
                5.086,
                5.099,
                5.112,
                5.125,
                5.138,
                5.151,
                5.164,
                5.176,
                5.189,
                5.201,
                5.214,
                5.226,
                5.238,
                5.25,
                5.262,
                5.274,
                5.286,
                5.298,
                5.309,
                5.321,
                5.333,
                5.344,
                5.355,
                5.367,
                5.378,
                5.389,
                5.4,
                5.411,
                5.422,
                5.433,
                5.444,
                5.454,
                5.465,
                5.475,
                5.486,
                5.496,
                5.507,
                5.517,
                5.527,
                5.538,
                5.548,
                5.558,
                5.568,
                5.578,
                5.588,
                5.597,
                5.607,
                5.617,
                5.627,
                5.636,
                5.646,
                5.655,
                5.665,
                5.674,
                5.683,
                5.693,
                5.702,
                5.711,
                5.72,
                5.729,
                5.738,
                5.747,
                5.756,
                5.765,
                5.774,
                5.782,
                5.791,
                5.8,
                5.809,
                5.817,
                5.826,
                5.834,
                5.843,
                5.851,
                5.859,
                5.868,
                5.876,
                5.884,
                5.893,
                5.901,
                5.909,
                5.917,
                5.925,
                5.933,
                5.941,
                5.949,
                5.957,
                5.965,
                5.973,
                5.98,
                5.988,
                5.996,
                6.004,
                6.011,
                6.019,
                6.026,
                6.034,
                6.042,
                6.049,
                6.056,
                6.064,
                6.071,
                6.079,
                6.086,
                6.093,
                6.1,
                6.108,
                6.115,
                6.122,
                6.129,
                6.137,
                6.144,
                6.151,
                6.158,
                6.165,
                6.172,
                6.179,
                6.186,
                6.193,
                6.199,
                6.206,
                6.213,
                6.22,
                6.227,
                6.234,
                6.24,
                6.247,
                6.254,
                6.261,
                6.267,
                6.274,
                6.281,
                6.287,
                6.294,
                6.3,
                6.307,
                6.313,
                6.32,
                6.326,
                6.333,
                6.339,
                6.346,
                6.352,
                6.359,
                6.365,
                6.371,
                6.378,
                6.384,
                6.39,
                6.396,
                6.403,
                6.409,
                6.415,
                6.421,
                6.428,
                6.434,
                6.44,
                6.446,
                6.452,
                6.458,
                6.465,
                6.471,
                6.477,
                6.483,
                6.489,
                6.495,
                6.501,
                6.507,
                6.513,
                6.519,
                6.525,
                6.531,
                6.537,
                6.542,
                6.548,
                6.554,
                6.56,
                6.566,
                6.572,
                6.578,
                6.584,
                6.589,
                6.595,
                6.601,
                6.607,
                6.613,
                6.618,
                6.624,
                6.63,
                6.635,
                6.641,
                6.647,
                6.653,
                6.658,
                6.664,
                6.67,
                6.675,
                6.681,
                6.687,
                6.692,
                6.698,
                6.703,
                6.709,
                6.715,
                6.72,
                6.726,
                6.731,
                6.737,
                6.742,
                6.748,
                6.753,
                6.759,
                6.764,
                6.77,
                6.775,
                6.781,
                6.786,
                6.792,
                6.797,
                6.803,
                6.808,
                6.813,
                6.819,
                6.824,
                6.83,
                6.835,
                6.84,
                6.846,
                6.851,
                6.857,
                6.862,
                6.867,
                6.873,
                6.878,
                6.883,
                6.888,
                6.894,
                6.899,
                6.904,
                6.91,
                6.915,
                6.92,
                6.925,
                6.931,
                6.936,
                6.941,
                6.946,
                6.952,
                6.957,
                6.962,
                6.967,
                6.972,
                6.978,
                6.983,
                6.988,
                6.993,
                6.998,
                7.003,
                7.009,
                7.014,
                7.019,
                7.024,
                7.029,
                7.034,
                7.039,
                7.044,
                7.05,
                7.055,
                7.06,
                7.065,
                7.07,
                7.075,
                7.08,
                7.085,
                7.09,
                7.095,
                7.1,
                7.105,
                7.11,
                7.115,
                7.12,
                7.125,
                7.13,
                7.135,
                7.14,
                7.146,
                7.151,
                7.156,
                7.161,
                7.165,
                7.17,
                7.175,
                7.18,
                7.185,
                7.19,
                7.195,
                7.2,
                7.205,
                7.21,
                7.215,
                7.22,
                7.225,
                7.23,
                7.235,
                7.24,
                7.244,
                7.249,
                7.254,
                7.259,
                7.264,
                7.269,
                7.274,
                7.279,
                7.283,
                7.288,
                7.293,
                7.298,
                7.303,
                7.308,
                7.312,
                7.317,
                7.322,
                7.327,
                7.332,
                7.337,
                7.342,
                7.346,
                7.351,
                7.356,
                7.361,
                7.366,
                7.37,
                7.375,
                7.38,
                7.385,
                7.39,
                7.394,
                7.399,
                7.404,
                7.409,
                7.413,
                7.418,
                7.423,
                7.428,
                7.433,
                7.437,
                7.442,
                7.447,
                7.451,
                7.456,
                7.461,
                7.466,
                7.471,
                7.475,
                7.48,
                7.485,
                7.489,
                7.494,
                7.499,
                7.503,
                7.508,
                7.513,
                7.518,
                7.522,
                7.527,
                7.532,
                7.536,
                7.541,
                7.546,
                7.55,
                7.555,
                7.56,
                7.564,
                7.569,
                7.574,
                7.579,
                7.583,
                7.588,
                7.592,
                7.597,
                7.602,
                7.606,
                7.611,
                7.615,
                7.62,
                7.625,
                7.63,
                7.634,
                7.639,
                7.643,
                7.648,
                7.653,
                7.657,
                7.662,
                7.666,
                7.671,
                7.675,
                7.68,
                7.685,
                7.689,
                7.694,
                7.698,
                7.703,
                7.708,
                7.712,
                7.717,
                7.721,
                7.726,
                7.73,
                7.735,
                7.739,
                7.744,
                7.748,
                7.753,
                7.758,
                7.762,
                7.767,
                7.771,
                7.776,
                7.78,
                7.785,
                7.789,
                7.794,
                7.798,
                7.803,
                7.807,
                7.812,
                7.816,
                7.821,
                7.825,
                7.83,
                7.834,
                7.839,
                7.843,
                7.848,
                7.852,
                7.857,
                7.861,
                7.866,
                7.87,
                7.875,
                7.879,
                7.883,
                7.888,
                7.892,
                7.897,
                7.901,
                7.906,
                7.91,
                7.915,
                7.919,
                7.924,
                7.928,
                7.932,
                7.937,
                7.941,
                7.946,
                7.95,
                7.955,
                7.959,
                7.963,
                7.968,
                7.972,
                7.977,
                7.981,
                7.985,
                7.99,
                7.994,
                7.999,
                8.003,
                8.008,
                8.012,
                8.016,
                8.021,
                8.025,
                8.03,
                8.034,
                8.038,
                8.043,
                8.047,
                8.052,
                8.056,
                8.06,
                8.065,
                8.069,
                8.074,
                8.078,
                8.082,
                8.087,
                8.091,
                8.096,
                8.1,
                8.104,
                8.109,
                8.113,
                8.117,
                8.122,
                8.126,
                8.13,
                8.135,
                8.139,
                8.143,
                8.148,
                8.152,
                8.157,
                8.161,
                8.165,
                8.17,
                8.174,
                8.178,
                8.183,
                8.187,
                8.191,
                8.196,
                8.2,
                8.204,
                8.209,
                8.213,
                8.217,
                8.222,
                8.226,
                8.23,
                8.235,
                8.239,
                8.243,
                8.248,
                8.252,
                8.256,
                8.261,
                8.265,
                8.269,
                8.273,
                8.278,
                8.282,
                8.286,
                8.291,
                8.295,
                8.299,
                8.304,
                8.308,
                8.312,
                8.317,
                8.321,
                8.325,
                8.329,
                8.334,
                8.338,
                8.342,
                8.346,
                8.351,
                8.355,
                8.359,
                8.364,
                8.368,
                8.372,
                8.376,
                8.381,
                8.385,
                8.389,
                8.393,
                8.398,
                8.402,
                8.406,
                8.411,
                8.415,
                8.419,
                8.423,
                8.427,
                8.432,
                8.436,
                8.44,
                8.445,
                8.449,
                8.453,
                8.457,
                8.462,
                8.466,
                8.47,
                8.474,
                8.478,
                8.483,
                8.487,
                8.491,
                8.495,
                8.5,
                8.504,
                8.508,
                8.512,
                8.516,
                8.521,
                8.525,
                8.529,
                8.533,
                8.538,
                8.542,
                8.546,
                8.55,
                8.554,
                8.558,
                8.563,
                8.567,
                8.571,
                8.575,
                8.58,
                8.584,
                8.588,
                8.592,
                8.596,
                8.6,
                8.605,
                8.609,
                8.613,
                8.617,
                8.621,
                8.625,
                8.63,
                8.634,
                8.638,
                8.642,
                8.646,
                8.65,
                8.654,
                8.659,
                8.663,
                8.667,
                8.671,
                8.675,
                8.679,
                8.683,
                8.687,
                8.691,
                8.696,
                8.7,
                8.704,
                8.708,
                8.712,
                8.716,
                8.72,
                8.725,
                8.729,
                8.733,
                8.737,
                8.741,
                8.745,
                8.749,
                8.753,
                8.757,
                8.761,
                8.765,
                8.769,
                8.773,
                8.778,
                8.782,
                8.786,
                8.79,
                8.794,
                8.798,
                8.802,
                8.806,
                8.81,
                8.814,
                8.818,
                8.822,
                8.826,
                8.83,
                8.834,
                8.838,
                8.842,
                8.846,
                8.85,
                8.854,
                8.858,
                8.862,
                8.866,
                8.87,
                8.874,
                8.878,
                8.882,
                8.886,
                8.89,
                8.894,
                8.898,
                8.902,
                8.906,
                8.91,
                8.914,
                8.918,
                8.922,
                8.926,
                8.93,
                8.934,
                8.938,
                8.942,
                8.946,
                8.95,
                8.954,
                8.958,
                8.962,
                8.966,
                8.97,
                8.973,
                8.977,
                8.981,
                8.985,
                8.989,
                8.993,
                8.997,
                9.001,
                9.005,
                9.009,
                9.013,
                9.017,
                9.021,
                9.024,
                9.028,
                9.032,
                9.036,
                9.04,
                9.044,
                9.048,
                9.051,
                9.055,
                9.059,
                9.063,
                9.067,
                9.071,
                9.074,
                9.079,
                9.082,
                9.086,
                9.09,
                9.094,
                9.098,
                9.101,
                9.105,
                9.109,
                9.113,
                9.117,
                9.12,
                9.124,
                9.128,
                9.132,
                9.136,
                9.14,
                9.144,
                9.147,
                9.151,
                9.155,
                9.159,
                9.162,
                9.166,
                9.17,
                9.174,
                9.177,
                9.181,
                9.185,
                9.189,
                9.193,
                9.196,
                9.2,
                9.204,
                9.208,
                9.211,
                9.215,
                9.219,
                9.223,
                9.226,
                9.23,
                9.234,
                9.238,
                9.241,
                9.245,
                9.249,
                9.252,
                9.256,
                9.26,
                9.263,
                9.267,
                9.271,
                9.275,
                9.278,
                9.282,
                9.286,
                9.289,
                9.293,
                9.297,
                9.3,
                9.304,
                9.308,
                9.311,
                9.315,
                9.319,
                9.323,
                9.326,
                9.33,
                9.334,
                9.337,
                9.341,
                9.344,
                9.348,
                9.352,
                9.355,
                9.359,
                9.363,
                9.366,
                9.37,
                9.374,
                9.377,
                9.381,
                9.384,
                9.388,
                9.392,
                9.395,
                9.399,
                9.403,
                9.406,
                9.41,
                9.413,
                9.417,
                9.421,
                9.424,
                9.428,
                9.431,
                9.435,
                9.438,
                9.442,
                9.446,
                9.449,
                9.453,
                9.456,
                9.46,
                9.464,
                9.467,
                9.471,
                9.474,
                9.478,
                9.482,
                9.485,
                9.489,
                9.492,
                9.496,
                9.499,
                9.503,
                9.506,
                9.51,
                9.513,
                9.517,
                9.52,
                9.524,
                9.527,
                9.531,
                9.535,
                9.538,
                9.542,
                9.545,
                9.549,
                9.552,
                9.556,
                9.559,
                9.563,
                9.566,
                9.57,
                9.573,
                9.577,
                9.58,
                9.584,
                9.587,
                9.591,
                9.594,
                9.598,
                9.601,
                9.605,
                9.608,
                9.612,
                9.615,
                9.619,
                9.622,
                9.626,
                9.629,
                9.633,
                9.636,
                9.64,
                9.643,
                9.647,
                9.65,
                9.654,
                9.657,
                9.661,
                9.664,
                9.668,
                9.671,
                9.674,
                9.678,
                9.681,
                9.685,
                9.688,
                9.692,
                9.695,
                9.699,
                9.702,
                9.706,
                9.709,
                9.713,
                9.716,
                9.72,
                9.723,
                9.726,
                9.73,
                9.733,
                9.737,
                9.74,
                9.744,
                9.747,
                9.751,
                9.754,
                9.757,
                9.761,
                9.764,
                9.768,
                9.771,
                9.775,
                9.778,
                9.781,
                9.785,
                9.788,
                9.792,
                9.795,
                9.799,
                9.802,
                9.806,
                9.809,
                9.812,
                9.816,
                9.819,
                9.823,
                9.826,
                9.83,
                9.833,
                9.836,
                9.84,
                9.843,
                9.847,
                9.85,
                9.853,
                9.857,
                9.86,
                9.864,
                9.867,
                9.871,
                9.874,
                9.877,
                9.881,
                9.884,
                9.888,
                9.891,
                9.894,
                9.898,
                9.901,
                9.905,
                9.908,
                9.911,
                9.915,
                9.918,
                9.922,
                9.925,
                9.928,
                9.932,
                9.935,
                9.939,
                9.942,
                9.945,
                9.949,
                9.952,
                9.955,
                9.959,
                9.962,
                9.966,
                9.969,
                9.973,
                9.976,
                9.979,
                9.982,
                9.986,
                9.989,
                9.993,
                9.996,
                10,
                10.003,
                10.006,
                10.01,
                10.013,
                10.016,
                10.02,
                10.023,
                10.027,
                10.03,
                10.033,
                10.037,
                10.04,
                10.044,
                10.047,
                10.05,
                10.054,
                10.057,
                10.06,
                10.064,
                10.067,
                10.071,
                10.074,
                10.077,
                10.081,
                10.084,
                10.087,
                10.091,
                10.094,
                10.097,
                10.101,
                10.104,
                10.108,
                10.111,
                10.114,
                10.118,
                10.121,
                10.125,
                10.128,
                10.131,
                10.135,
                10.138,
                10.141,
                10.145,
                10.148,
                10.151,
                10.155,
                10.158,
                10.161,
                10.165,
                10.168,
                10.172,
                10.175,
                10.178,
                10.182,
                10.185,
                10.189,
                10.192,
                10.195,
                10.199,
                10.202,
                10.205,
                10.209,
                10.212,
                10.215,
                10.219,
                10.222,
                10.225,
                10.229,
                10.232,
                10.235,
                10.239,
                10.242,
                10.246,
                10.249,
                10.252,
                10.256,
                10.259,
                10.262,
                10.266,
                10.269,
                10.273,
                10.276,
                10.279,
                10.283,
                10.286,
                10.289,
                10.293,
                10.296,
                10.3,
                10.303,
                10.306,
                10.31,
                10.313,
                10.316,
                10.32,
                10.323,
                10.326,
                10.33,
                10.333,
                10.336,
                10.34,
                10.343,
                10.346,
                10.35,
                10.353,
                10.356,
                10.36,
                10.363,
                10.366,
                10.37,
                10.373,
                10.376,
                10.38,
                10.383,
                10.386,
                10.39,
                10.393,
                10.397,
                10.4,
                10.403,
                10.407,
                10.41,
                10.413,
                10.417,
                10.42,
                10.423,
                10.427,
                10.43,
                10.433,
                10.437,
                10.44,
                10.443,
                10.447,
                10.45,
                10.453,
                10.457,
                10.46,
                10.463,
                10.467,
                10.47,
                10.473,
                10.476,
                10.48,
                10.483,
                10.486,
                10.49,
                10.493,
                10.496,
                10.5,
                10.503,
                10.506,
                10.51,
                10.513,
                10.516,
                10.52,
                10.523,
                10.526,
                10.53,
                10.533,
                10.536,
                10.54,
                10.543,
                10.546,
                10.55,
                10.553,
                10.556,
                10.56,
                10.563,
                10.566,
                10.57,
                10.573,
                10.576,
                10.579,
                10.583,
                10.586,
                10.589,
                10.592,
                10.596,
                10.599,
                10.603,
                10.606,
                10.609,
                10.612,
                10.616,
                10.619,
                10.622,
                10.626,
                10.629,
                10.632,
                10.636,
                10.639,
                10.642,
                10.646,
                10.649,
                10.652,
                10.655,
                10.659,
                10.662,
                10.665,
                10.668,
                10.672,
                10.675,
                10.678,
                10.681,
                10.685,
                10.688,
                10.691,
                10.695,
                10.698,
                10.701,
                10.705,
                10.708,
                10.711,
                10.714,
                10.718,
                10.721,
                10.724,
                10.727,
                10.731,
                10.734,
                10.737,
                10.74,
                10.744,
                10.747,
                10.75,
                10.754,
                10.757,
                10.76,
                10.763,
                10.767,
                10.77,
                10.773,
                10.776,
                10.78,
                10.783,
                10.786,
                10.789,
                10.793,
                10.796,
                10.799,
                10.802,
                10.806,
                10.809,
                10.812,
                10.816,
                10.819,
                10.822,
                10.825,
                10.828,
                10.831,
                10.835,
                10.838,
                10.841,
                10.844,
                10.848,
                10.851,
                10.854,
                10.858,
                10.861,
                10.864,
                10.867,
                10.87,
                10.874,
                10.877,
                10.88,
                10.883,
                10.887,
                10.89,
                10.893,
                10.896,
                10.9,
                10.903,
                10.906,
                10.909,
                10.912,
                10.916,
                10.919,
                10.922,
                10.925,
                10.928,
                10.932,
                10.935,
                10.938,
                10.941,
                10.944,
                10.948,
                10.951,
                10.954,
                10.957,
                10.96,
                10.964,
                10.967,
                10.97,
                10.973,
                10.977,
                10.98,
                10.983,
                10.986,
                10.989,
                10.992,
                10.996,
                10.999,
                11.002,
                11.005,
                11.009,
                11.012,
                11.015,
                11.018,
                11.021,
                11.024,
                11.027,
                11.031,
                11.034,
                11.037,
                11.04,
                11.043,
                11.046,
                11.05,
                11.053,
                11.056,
                11.059,
                11.063,
                11.066,
                11.069,
                11.072,
                11.075,
                11.078,
                11.081,
                11.085,
                11.088,
                11.091,
                11.094,
                11.098,
                11.101,
                11.104,
                11.107,
                11.11,
                11.113,
                11.116,
                11.12,
                11.123,
                11.126,
                11.129,
                11.132,
                11.135,
                11.138,
                11.142,
                11.145,
                11.148,
                11.151,
                11.154,
                11.157,
                11.16,
                11.164,
                11.167,
                11.17,
                11.173,
                11.176,
                11.179,
                11.182,
                11.185,
                11.189,
                11.192,
                11.195,
                11.198,
                11.201,
                11.205,
                11.208,
                11.211,
                11.214,
                11.217,
                11.22,
                11.223,
                11.227,
                11.23,
                11.233,
                11.236,
                11.239,
                11.242,
                11.245,
                11.248,
                11.252,
                11.255,
                11.258,
                11.261,
                11.264,
                11.267,
                11.27,
                11.273,
                11.277,
                11.279,
                11.282,
                11.286,
                11.289,
                11.292,
                11.295,
                11.298,
                11.301,
                11.304,
                11.307,
                11.311,
                11.314,
                11.317,
                11.32,
                11.323,
                11.326,
                11.329,
                11.332,
                11.336,
                11.339,
                11.342,
                11.345,
                11.348,
                11.351,
                11.354,
                11.357,
                11.36,
                11.364,
                11.367,
                11.37,
                11.373,
                11.376,
                11.379,
                11.382,
                11.385,
                11.388,
                11.391,
                11.394,
                11.398,
                11.401,
                11.404,
                11.407,
                11.41,
                11.413,
                11.416,
                11.419,
                11.422,
                11.425,
                11.429,
                11.432,
                11.434,
                11.438,
                11.441,
                11.444,
                11.447,
                11.45,
                11.453,
                11.456,
                11.459,
                11.462,
                11.466,
                11.469,
                11.472,
                11.475,
                11.478,
                11.481,
                11.484,
                11.487,
                11.49,
                11.493,
                11.496,
                11.499,
                11.502,
                11.506,
                11.509,
                11.512,
                11.515,
                11.518,
                11.521,
                11.524,
                11.527,
                11.53,
                11.533,
                11.536,
                11.539,
                11.542,
                11.545,
                11.549,
                11.552,
                11.555,
                11.558,
                11.561,
                11.564,
                11.567,
                11.57,
                11.573,
                11.576,
                11.579,
                11.582,
                11.585,
                11.588,
                11.592,
                11.594,
                11.597,
                11.6,
                11.604,
                11.607,
                11.61,
                11.613,
                11.616,
                11.619,
                11.622,
                11.625,
                11.628,
                11.631,
                11.634,
                11.637,
                11.64,
                11.643,
                11.646,
                11.65,
                11.653,
                11.656,
                11.658,
                11.661,
                11.665,
                11.668,
                11.671,
                11.674,
                11.677,
                11.68,
                11.683,
                11.686,
                11.689,
                11.692,
                11.695,
                11.698,
                11.701,
                11.704,
                11.707,
                11.71,
                11.713,
                11.716,
                11.719,
                11.722,
                11.725,
                11.728,
                11.732,
                11.735,
                11.738,
                11.741,
                11.744,
                11.747,
                11.75,
                11.753,
                11.756,
                11.759,
                11.762,
                11.765,
                11.768,
                11.771,
                11.774,
                11.777,
                11.78,
                11.783,
                11.786,
                11.789,
                11.792,
                11.795,
                11.798,
                11.801,
                11.804,
                11.807,
                11.81,
                11.813,
                11.816,
                11.819,
                11.822,
                11.826,
                11.829,
                11.831,
                11.834,
                11.837,
                11.84,
                11.844,
                11.847,
                11.85,
                11.853,
                11.856,
                11.858,
                11.861,
                11.865,
                11.868,
                11.871,
                11.874,
                11.877,
                11.88,
                11.883,
                11.886,
                11.889,
                11.892,
                11.895,
                11.898,
                11.901,
                11.904,
                11.907,
                11.91,
                11.913,
                11.916,
                11.919,
                11.922,
                11.925,
                11.928,
                11.931,
                11.934,
                11.937,
                11.939,
                11.943,
                11.946,
                11.949,
                11.952,
                11.955,
                11.958,
                11.961,
                11.964,
                11.966,
                11.969,
                11.973,
                11.976,
                11.979,
                11.982,
                11.985,
                11.988,
                11.991,
                11.993,
                11.996,
                11.999,
                12.003,
                12.006,
                12.009,
                12.012,
                12.015,
                12.018,
                12.02,
                12.023,
                12.026,
                12.029,
                12.032,
                12.035,
                12.038,
                12.041,
                12.044,
                12.047,
                12.05,
                12.053,
                12.056,
                12.059,
                12.062,
                12.065,
                12.068,
                12.071,
                12.074,
                12.077,
                12.08,
                12.083,
                12.086,
                12.089,
                12.092,
                12.095,
                12.098,
                12.101,
                12.104,
                12.107,
                12.11,
                12.113,
                12.116,
                12.119,
                12.122,
                12.125,
                12.128,
                12.13,
                12.133,
                12.136,
                12.139,
                12.142,
                12.145,
                12.148,
                12.151,
                12.154,
                12.157,
                12.16,
                12.163,
                12.166,
                12.169,
                12.172,
                12.175,
                12.178,
                12.181,
                12.184,
                12.187,
                12.19,
                12.193,
                12.196,
                12.199,
                12.202,
                12.204,
                12.207,
                12.21,
                12.213,
                12.216,
                12.219,
                12.222,
                12.225,
                12.228,
                12.231,
                12.234,
                12.237,
                12.24,
                12.243,
                12.246,
                12.249,
                12.252,
                12.254,
                12.257,
                12.26,
                12.263,
                12.266,
                12.269,
                12.272,
                12.275,
                12.278,
                12.281,
                12.284,
                12.287,
                12.29,
                12.293,
                12.296,
                12.299,
                12.302,
                12.305,
                12.308,
                12.31,
                12.313,
                12.316

            ],
            P1: [
                2.331,
                2.311,
                2.327,
                2.348,
                2.371,
                2.396,
                2.422,
                2.449,
                2.476,
                2.505,
                2.534,
                2.564,
                2.596,
                2.628,
                2.661,
                2.695,
                2.73,
                2.764,
                2.8,
                2.835,
                2.87,
                2.906,
                2.941,
                2.977,
                3.012,
                3.047,
                3.082,
                3.117,
                3.152,
                3.187,
                3.221,
                3.255,
                3.289,
                3.322,
                3.355,
                3.388,
                3.421,
                3.453,
                3.485,
                3.517,
                3.548,
                3.579,
                3.61,
                3.64,
                3.671,
                3.7,
                3.73,
                3.759,
                3.788,
                3.817,
                3.846,
                3.874,
                3.902,
                3.929,
                3.957,
                3.984,
                4.011,
                4.038,
                4.064,
                4.09,
                4.116,
                4.142,
                4.167,
                4.192,
                4.217,
                4.242,
                4.267,
                4.291,
                4.315,
                4.339,
                4.363,
                4.386,
                4.41,
                4.433,
                4.456,
                4.478,
                4.501,
                4.523,
                4.545,
                4.567,
                4.589,
                4.61,
                4.631,
                4.653,
                4.674,
                4.694,
                4.715,
                4.735,
                4.756,
                4.776,
                4.796,
                4.815,
                4.835,
                4.854,
                4.874,
                4.893,
                4.912,
                4.93,
                4.949,
                4.968,
                4.986,
                5.004,
                5.022,
                5.04,
                5.058,
                5.076,
                5.093,
                5.11,
                5.128,
                5.145,
                5.162,
                5.179,
                5.195,
                5.212,
                5.228,
                5.245,
                5.261,
                5.277,
                5.293,
                5.309,
                5.325,
                5.34,
                5.356,
                5.371,
                5.387,
                5.402,
                5.417,
                5.432,
                5.447,
                5.461,
                5.476,
                5.491,
                5.505,
                5.52,
                5.534,
                5.548,
                5.562,
                5.576,
                5.59,
                5.604,
                5.617,
                5.631,
                5.644,
                5.658,
                5.671,
                5.684,
                5.697,
                5.71,
                5.723,
                5.736,
                5.749,
                5.762,
                5.774,
                5.787,
                5.799,
                5.811,
                5.824,
                5.836,
                5.848,
                5.86,
                5.872,
                5.884,
                5.896,
                5.907,
                5.919,
                5.93,
                5.942,
                5.953,
                5.965,
                5.976,
                5.987,
                5.998,
                6.009,
                6.02,
                6.031,
                6.042,
                6.053,
                6.064,
                6.075,
                6.085,
                6.096,
                6.106,
                6.117,
                6.127,
                6.137,
                6.148,
                6.158,
                6.168,
                6.178,
                6.188,
                6.198,
                6.208,
                6.218,
                6.228,
                6.238,
                6.247,
                6.257,
                6.267,
                6.276,
                6.286,
                6.295,
                6.304,
                6.314,
                6.323,
                6.332,
                6.342,
                6.351,
                6.36,
                6.369,
                6.378,
                6.387,
                6.396,
                6.405,
                6.414,
                6.423,
                6.431,
                6.44,
                6.449,
                6.458,
                6.466,
                6.475,
                6.483,
                6.492,
                6.5,
                6.509,
                6.517,
                6.525,
                6.534,
                6.542,
                6.55,
                6.559,
                6.567,
                6.575,
                6.583,
                6.591,
                6.599,
                6.607,
                6.615,
                6.623,
                6.631,
                6.639,
                6.647,
                6.655,
                6.662,
                6.67,
                6.678,
                6.685,
                6.693,
                6.701,
                6.708,
                6.716,
                6.724,
                6.731,
                6.739,
                6.746,
                6.753,
                6.761,
                6.768,
                6.776,
                6.783,
                6.79,
                6.798,
                6.805,
                6.812,
                6.819,
                6.827,
                6.834,
                6.841,
                6.848,
                6.855,
                6.862,
                6.87,
                6.876,
                6.884,
                6.891,
                6.898,
                6.905,
                6.911,
                6.918,
                6.925,
                6.932,
                6.939,
                6.946,
                6.953,
                6.96,
                6.966,
                6.973,
                6.98,
                6.987,
                6.993,
                7,
                7.007,
                7.014,
                7.02,
                7.027,
                7.033,
                7.04,
                7.047,
                7.053,
                7.06,
                7.066,
                7.073,
                7.079,
                7.086,
                7.092,
                7.099,
                7.105,
                7.112,
                7.118,
                7.125,
                7.131,
                7.137,
                7.144,
                7.15,
                7.156,
                7.163,
                7.169,
                7.175,
                7.182,
                7.188,
                7.194,
                7.201,
                7.207,
                7.213,
                7.219,
                7.225,
                7.232,
                7.238,
                7.244,
                7.25,
                7.256,
                7.262,
                7.269,
                7.275,
                7.281,
                7.287,
                7.293,
                7.299,
                7.305,
                7.311,
                7.317,
                7.323,
                7.329,
                7.335,
                7.341,
                7.347,
                7.353,
                7.359,
                7.365,
                7.371,
                7.377,
                7.383,
                7.389,
                7.395,
                7.401,
                7.407,
                7.413,
                7.418,
                7.425,
                7.43,
                7.436,
                7.442,
                7.448,
                7.454,
                7.459,
                7.465,
                7.471,
                7.477,
                7.483,
                7.489,
                7.494,
                7.5,
                7.506,
                7.512,
                7.517,
                7.523,
                7.529,
                7.535,
                7.54,
                7.546,
                7.552,
                7.557,
                7.563,
                7.569,
                7.575,
                7.58,
                7.586,
                7.591,
                7.597,
                7.603,
                7.608,
                7.614,
                7.62,
                7.625,
                7.631,
                7.637,
                7.642,
                7.648,
                7.653,
                7.659,
                7.664,
                7.67,
                7.676,
                7.681,
                7.687,
                7.692,
                7.698,
                7.703,
                7.709,
                7.714,
                7.72,
                7.725,
                7.731,
                7.736,
                7.742,
                7.747,
                7.753,
                7.758,
                7.764,
                7.769,
                7.774,
                7.78,
                7.785,
                7.791,
                7.796,
                7.802,
                7.807,
                7.813,
                7.818,
                7.823,
                7.829,
                7.834,
                7.84,
                7.845,
                7.85,
                7.856,
                7.861,
                7.866,
                7.872,
                7.877,
                7.883,
                7.888,
                7.893,
                7.899,
                7.904,
                7.909,
                7.915,
                7.92,
                7.925,
                7.931,
                7.936,
                7.941,
                7.947,
                7.952,
                7.957,
                7.962,
                7.968,
                7.973,
                7.978,
                7.983,
                7.989,
                7.994,
                7.999,
                8.005,
                8.01,
                8.015,
                8.02,
                8.026,
                8.031,
                8.036,
                8.041,
                8.047,
                8.052,
                8.057,
                8.062,
                8.068,
                8.073,
                8.078,
                8.083,
                8.088,
                8.094,
                8.099,
                8.104,
                8.109,
                8.114,
                8.12,
                8.125,
                8.13,
                8.135,
                8.14,
                8.145,
                8.151,
                8.156,
                8.161,
                8.166,
                8.171,
                8.176,
                8.182,
                8.187,
                8.192,
                8.197,
                8.202,
                8.207,
                8.212,
                8.217,
                8.223,
                8.228,
                8.233,
                8.238,
                8.243,
                8.248,
                8.253,
                8.258,
                8.263,
                8.269,
                8.274,
                8.279,
                8.284,
                8.289,
                8.294,
                8.299,
                8.304,
                8.309,
                8.314,
                8.319,
                8.325,
                8.329,
                8.335,
                8.34,
                8.345,
                8.35,
                8.355,
                8.36,
                8.365,
                8.37,
                8.375,
                8.38,
                8.385,
                8.39,
                8.395,
                8.4,
                8.405,
                8.41,
                8.415,
                8.42,
                8.425,
                8.43,
                8.435,
                8.44,
                8.445,
                8.45,
                8.455,
                8.46,
                8.465,
                8.47,
                8.475,
                8.48,
                8.485,
                8.49,
                8.495,
                8.5,
                8.505,
                8.51,
                8.515,
                8.52,
                8.524,
                8.529,
                8.534,
                8.539,
                8.544,
                8.549,
                8.554,
                8.559,
                8.564,
                8.569,
                8.574,
                8.579,
                8.584,
                8.589,
                8.593,
                8.598,
                8.603,
                8.608,
                8.613,
                8.618,
                8.623,
                8.628,
                8.633,
                8.638,
                8.642,
                8.647,
                8.652,
                8.657,
                8.662,
                8.667,
                8.672,
                8.677,
                8.682,
                8.686,
                8.691,
                8.696,
                8.701,
                8.706,
                8.711,
                8.716,
                8.721,
                8.725,
                8.73,
                8.735,
                8.74,
                8.745,
                8.75,
                8.755,
                8.759,
                8.764,
                8.769,
                8.774,
                8.779,
                8.784,
                8.789,
                8.793,
                8.798,
                8.803,
                8.808,
                8.813,
                8.818,
                8.823,
                8.827,
                8.832,
                8.837,
                8.842,
                8.847,
                8.852,
                8.856,
                8.861,
                8.866,
                8.871,
                8.875,
                8.88,
                8.885,
                8.89,
                8.895,
                8.9,
                8.904,
                8.909,
                8.914,
                8.919,
                8.924,
                8.929,
                8.933,
                8.938,
                8.943,
                8.948,
                8.953,
                8.957,
                8.962,
                8.967,
                8.972,
                8.976,
                8.981,
                8.986,
                8.991,
                8.996,
                9,
                9.005,
                9.01,
                9.015,
                9.019,
                9.024,
                9.029,
                9.034,
                9.038,
                9.043,
                9.048,
                9.053,
                9.058,
                9.062,
                9.067,
                9.072,
                9.077,
                9.081,
                9.086,
                9.091,
                9.096,
                9.1,
                9.105,
                9.11,
                9.115,
                9.119,
                9.124,
                9.129,
                9.134,
                9.138,
                9.143,
                9.148,
                9.153,
                9.157,
                9.162,
                9.167,
                9.172,
                9.176,
                9.181,
                9.186,
                9.19,
                9.195,
                9.2,
                9.205,
                9.209,
                9.214,
                9.219,
                9.223,
                9.228,
                9.233,
                9.238,
                9.242,
                9.247,
                9.252,
                9.256,
                9.261,
                9.266,
                9.271,
                9.275,
                9.28,
                9.285,
                9.289,
                9.294,
                9.299,
                9.303,
                9.308,
                9.313,
                9.317,
                9.322,
                9.327,
                9.331,
                9.336,
                9.341,
                9.345,
                9.35,
                9.355,
                9.359,
                9.364,
                9.369,
                9.373,
                9.378,
                9.382,
                9.387,
                9.392,
                9.396,
                9.401,
                9.406,
                9.41,
                9.415,
                9.42,
                9.424,
                9.429,
                9.434,
                9.438,
                9.443,
                9.447,
                9.452,
                9.457,
                9.461,
                9.466,
                9.47,
                9.475,
                9.48,
                9.484,
                9.489,
                9.493,
                9.498,
                9.503,
                9.507,
                9.512,
                9.516,
                9.521,
                9.525,
                9.53,
                9.535,
                9.539,
                9.544,
                9.548,
                9.553,
                9.557,
                9.562,
                9.566,
                9.571,
                9.575,
                9.58,
                9.585,
                9.589,
                9.594,
                9.598,
                9.603,
                9.607,
                9.612,
                9.616,
                9.621,
                9.625,
                9.63,
                9.634,
                9.639,
                9.643,
                9.648,
                9.652,
                9.657,
                9.661,
                9.666,
                9.67,
                9.675,
                9.679,
                9.684,
                9.688,
                9.693,
                9.697,
                9.701,
                9.706,
                9.71,
                9.715,
                9.719,
                9.724,
                9.728,
                9.733,
                9.737,
                9.742,
                9.746,
                9.75,
                9.755,
                9.759,
                9.764,
                9.768,
                9.772,
                9.777,
                9.781,
                9.786,
                9.79,
                9.794,
                9.799,
                9.803,
                9.808,
                9.812,
                9.816,
                9.821,
                9.825,
                9.829,
                9.834,
                9.838,
                9.843,
                9.847,
                9.851,
                9.856,
                9.86,
                9.864,
                9.869,
                9.873,
                9.877,
                9.882,
                9.886,
                9.89,
                9.895,
                9.899,
                9.903,
                9.907,
                9.912,
                9.916,
                9.921,
                9.925,
                9.929,
                9.933,
                9.938,
                9.942,
                9.946,
                9.951,
                9.955,
                9.959,
                9.963,
                9.968,
                9.972,
                9.976,
                9.981,
                9.985,
                9.989,
                9.993,
                9.997,
                10.002,
                10.006,
                10.01,
                10.014,
                10.019,
                10.023,
                10.027,
                10.031,
                10.035,
                10.04,
                10.044,
                10.048,
                10.052,
                10.057,
                10.061,
                10.065,
                10.069,
                10.073,
                10.077,
                10.082,
                10.086,
                10.09,
                10.094,
                10.098,
                10.103,
                10.107,
                10.111,
                10.115,
                10.119,
                10.123,
                10.127,
                10.132,
                10.136,
                10.14,
                10.144,
                10.148,
                10.152,
                10.156,
                10.161,
                10.165,
                10.169,
                10.173,
                10.177,
                10.181,
                10.185,
                10.19,
                10.194,
                10.198,
                10.202,
                10.206,
                10.21,
                10.214,
                10.218,
                10.222,
                10.226,
                10.23,
                10.234,
                10.239,
                10.243,
                10.247,
                10.251,
                10.255,
                10.259,
                10.263,
                10.267,
                10.271,
                10.275,
                10.279,
                10.283,
                10.287,
                10.291,
                10.295,
                10.299,
                10.303,
                10.308,
                10.311,
                10.316,
                10.319,
                10.323,
                10.327,
                10.331,
                10.336,
                10.34,
                10.344,
                10.348,
                10.352,
                10.356,
                10.36,
                10.364,
                10.368,
                10.372,
                10.376,
                10.38,
                10.384,
                10.388,
                10.391,
                10.395,
                10.4,
                10.404,
                10.407,
                10.411,
                10.415,
                10.419,
                10.423,
                10.427,
                10.431,
                10.435,
                10.439,
                10.443,
                10.447,
                10.451,
                10.455,
                10.459,
                10.463,
                10.467,
                10.471,
                10.475,
                10.479,
                10.483,
                10.487,
                10.491,
                10.494,
                10.498,
                10.502,
                10.506,
                10.51,
                10.514,
                10.518,
                10.522,
                10.526,
                10.53,
                10.534,
                10.538,
                10.541,
                10.545,
                10.549,
                10.553,
                10.557,
                10.561,
                10.565,
                10.569,
                10.573,
                10.577,
                10.58,
                10.584,
                10.588,
                10.592,
                10.596,
                10.6,
                10.604,
                10.608,
                10.611,
                10.616,
                10.619,
                10.623,
                10.627,
                10.631,
                10.635,
                10.639,
                10.643,
                10.646,
                10.65,
                10.654,
                10.658,
                10.662,
                10.666,
                10.67,
                10.674,
                10.677,
                10.681,
                10.685,
                10.689,
                10.693,
                10.697,
                10.701,
                10.704,
                10.708,
                10.712,
                10.716,
                10.72,
                10.724,
                10.728,
                10.731,
                10.735,
                10.739,
                10.743,
                10.747,
                10.751,
                10.754,
                10.758,
                10.762,
                10.766,
                10.77,
                10.774,
                10.778,
                10.781,
                10.785,
                10.789,
                10.793,
                10.797,
                10.8,
                10.804,
                10.808,
                10.812,
                10.816,
                10.82,
                10.824,
                10.827,
                10.831,
                10.835,
                10.839,
                10.843,
                10.846,
                10.85,
                10.854,
                10.858,
                10.862,
                10.866,
                10.869,
                10.873,
                10.877,
                10.881,
                10.885,
                10.888,
                10.892,
                10.896,
                10.9,
                10.904,
                10.908,
                10.911,
                10.915,
                10.919,
                10.923,
                10.926,
                10.93,
                10.934,
                10.938,
                10.942,
                10.946,
                10.949,
                10.953,
                10.957,
                10.961,
                10.965,
                10.968,
                10.972,
                10.976,
                10.98,
                10.983,
                10.987,
                10.991,
                10.995,
                10.999,
                11.003,
                11.006,
                11.01,
                11.014,
                11.018,
                11.021,
                11.025,
                11.029,
                11.033,
                11.037,
                11.04,
                11.044,
                11.048,
                11.052,
                11.056,
                11.059,
                11.063,
                11.067,
                11.071,
                11.075,
                11.078,
                11.082,
                11.086,
                11.09,
                11.093,
                11.097,
                11.101,
                11.105,
                11.108,
                11.112,
                11.116,
                11.12,
                11.124,
                11.128,
                11.131,
                11.135,
                11.139,
                11.143,
                11.147,
                11.15,
                11.154,
                11.158,
                11.162,
                11.165,
                11.169,
                11.173,
                11.177,
                11.18,
                11.184,
                11.188,
                11.192,
                11.196,
                11.199,
                11.203,
                11.207,
                11.211,
                11.214,
                11.218,
                11.222,
                11.226,
                11.23,
                11.233,
                11.237,
                11.241,
                11.245,
                11.248,
                11.252,
                11.256,
                11.26,
                11.264,
                11.267,
                11.271,
                11.275,
                11.279,
                11.282,
                11.286,
                11.29,
                11.294,
                11.297,
                11.301,
                11.305,
                11.309,
                11.312,
                11.316,
                11.32,
                11.324,
                11.327,
                11.331,
                11.335,
                11.339,
                11.343,
                11.346,
                11.35,
                11.354,
                11.358,
                11.361,
                11.365,
                11.369,
                11.373,
                11.376,
                11.38,
                11.384,
                11.388,
                11.392,
                11.395,
                11.399,
                11.403,
                11.407,
                11.41,
                11.414,
                11.418,
                11.422,
                11.425,
                11.429,
                11.433,
                11.436,
                11.44,
                11.444,
                11.448,
                11.452,
                11.455,
                11.459,
                11.463,
                11.467,
                11.47,
                11.474,
                11.478,
                11.482,
                11.485,
                11.489,
                11.493,
                11.497,
                11.501,
                11.504,
                11.508,
                11.512,
                11.515,
                11.519,
                11.523,
                11.527,
                11.53,
                11.534,
                11.538,
                11.542,
                11.545,
                11.549,
                11.553,
                11.557,
                11.56,
                11.564,
                11.568,
                11.571,
                11.575,
                11.579,
                11.583,
                11.586,
                11.59,
                11.594,
                11.598,
                11.601,
                11.605,
                11.609,
                11.612,
                11.616,
                11.62,
                11.624,
                11.627,
                11.631,
                11.635,
                11.639,
                11.642,
                11.646,
                11.65,
                11.653,
                11.657,
                11.661,
                11.665,
                11.668,
                11.672,
                11.676,
                11.679,
                11.683,
                11.687,
                11.691,
                11.694,
                11.698,
                11.702,
                11.706,
                11.709,
                11.713,
                11.716,
                11.72,
                11.724,
                11.728,
                11.731,
                11.735,
                11.739,
                11.743,
                11.746,
                11.75,
                11.753,
                11.757,
                11.761,
                11.765,
                11.768,
                11.772,
                11.776,
                11.779,
                11.783,
                11.787,
                11.791,
                11.794,
                11.798,
                11.802,
                11.805,
                11.809,
                11.813,
                11.816,
                11.82,
                11.824,
                11.828,
                11.831,
                11.835,
                11.838,
                11.842,
                11.846,
                11.85,
                11.853,
                11.857,
                11.861,
                11.864,
                11.868,
                11.872,
                11.875,
                11.879,
                11.883,
                11.886,
                11.89,
                11.894,
                11.897,
                11.901,
                11.905,
                11.908,
                11.912,
                11.916,
                11.919,
                11.923,
                11.926,
                11.93,
                11.934,
                11.938,
                11.941,
                11.945,
                11.948,
                11.952,
                11.956,
                11.96,
                11.963,
                11.967,
                11.97,
                11.974,
                11.978,
                11.981,
                11.985,
                11.989,
                11.992,
                11.996,
                12,
                12.003,
                12.007,
                12.01,
                12.014,
                12.018,
                12.021,
                12.025,
                12.029,
                12.032,
                12.036,
                12.04,
                12.043,
                12.047,
                12.051,
                12.054,
                12.058,
                12.061,
                12.065,
                12.069,
                12.072,
                12.076,
                12.079,
                12.083,
                12.087,
                12.09,
                12.094,
                12.098,
                12.101,
                12.105,
                12.108,
                12.112,
                12.116,
                12.119,
                12.123,
                12.126,
                12.13,
                12.134,
                12.137,
                12.141,
                12.145,
                12.148,
                12.152,
                12.155,
                12.159,
                12.163,
                12.166,
                12.17,
                12.173,
                12.177,
                12.181,
                12.184,
                12.188,
                12.192,
                12.195,
                12.199,
                12.202,
                12.206,
                12.21,
                12.213,
                12.217,
                12.22,
                12.224,
                12.227,
                12.231,
                12.235,
                12.238,
                12.242,
                12.245,
                12.249,
                12.252,
                12.256,
                12.26,
                12.263,
                12.267,
                12.27,
                12.274,
                12.278,
                12.281,
                12.285,
                12.288,
                12.292,
                12.296,
                12.299,
                12.303,
                12.306,
                12.31,
                12.314,
                12.317,
                12.321,
                12.324,
                12.328,
                12.331,
                12.335,
                12.339,
                12.342,
                12.346,
                12.349,
                12.353,
                12.356,
                12.36,
                12.364,
                12.367,
                12.371,
                12.374,
                12.378,
                12.381,
                12.385,
                12.389,
                12.392,
                12.395,
                12.399,
                12.403,
                12.406,
                12.41,
                12.413,
                12.417,
                12.42,
                12.424,
                12.428,
                12.431,
                12.435,
                12.438,
                12.442,
                12.445,
                12.449,
                12.452,
                12.456,
                12.46,
                12.463,
                12.467,
                12.47,
                12.474,
                12.477,
                12.481,
                12.484,
                12.488,
                12.492,
                12.495,
                12.499,
                12.502,
                12.506,
                12.509,
                12.513,
                12.516,
                12.52,
                12.523,
                12.527,
                12.53,
                12.534,
                12.538,
                12.541,
                12.545,
                12.548,
                12.552,
                12.555,
                12.559,
                12.562,
                12.566,
                12.569,
                12.573,
                12.576,
                12.58,
                12.583,
                12.587,
                12.591,
                12.594,
                12.598,
                12.601,
                12.605,
                12.608,
                12.612,
                12.615,
                12.619,
                12.622,
                12.626,
                12.629,
                12.633,
                12.636,
                12.64,
                12.643,
                12.647,
                12.651,
                12.654,
                12.658,
                12.661,
                12.665,
                12.668,
                12.672,
                12.675,
                12.679,
                12.682,
                12.686,
                12.689,
                12.693,
                12.696,
                12.7,
                12.703,
                12.707,
                12.71,
                12.714,
                12.717,
                12.721,
                12.724,
                12.728,
                12.731,
                12.735,
                12.738,
                12.742,
                12.746,
                12.749,
                12.753,
                12.756,
                12.759,
                12.763,
                12.767,
                12.77,
                12.774,
                12.777,
                12.781,
                12.784,
                12.788,
                12.791,
                12.794,
                12.798,
                12.801,
                12.805,
                12.809,
                12.812,
                12.816,
                12.819,
                12.823,
                12.826,
                12.83,
                12.833,
                12.837,
                12.84,
                12.844,
                12.847,
                12.851,
                12.854,
                12.858,
                12.861,
                12.865,
                12.868,
                12.872,
                12.875,
                12.879,
                12.882,
                12.886,
                12.889,
                12.893,
                12.896,
                12.899,
                12.903,
                12.906,
                12.91,
                12.913,
                12.917,
                12.92,
                12.924,
                12.928,
                12.931,
                12.934,
                12.938,
                12.941,
                12.945,
                12.948,
                12.952,
                12.955,
                12.959,
                12.962,
                12.966,
                12.969,
                12.973,
                12.976,
                12.98,
                12.983,
                12.987,
                12.99,
                12.994,
                12.997,
                13,
                13.004,
                13.007,
                13.011,
                13.014,
                13.018,
                13.021,
                13.025,
                13.028,
                13.032,
                13.035,
                13.039,
                13.042,
                13.046,
                13.049,
                13.053,
                13.056,
                13.059,
                13.063,
                13.066,
                13.07,
                13.073,
                13.077,
                13.08,
                13.084,
                13.087,
                13.091,
                13.094,
                13.098,
                13.101,
                13.105,
                13.108,
                13.111,
                13.115,
                13.118,
                13.122,
                13.125,
                13.129,
                13.132,
                13.136,
                13.139,
                13.142,
                13.146,
                13.149,
                13.153,
                13.156,
                13.16,
                13.163,
                13.167,
                13.17,
                13.174,
                13.177,
                13.181,
                13.184,
                13.187,
                13.191,
                13.194,
                13.198,
                13.201,
                13.205,
                13.208,
                13.212,
                13.215,
                13.218,
                13.222,
                13.225,
                13.229,
                13.232,
                13.236,
                13.239,
                13.243,
                13.246,
                13.249,
                13.253,
                13.256,
                13.26,
                13.263,
                13.267,
                13.27,
                13.273,
                13.277,
                13.28,
                13.284,
                13.287,
                13.291,
                13.294,
                13.297,
                13.301,
                13.304,
                13.308,
                13.311,
                13.314,
                13.318,
                13.321,
                13.325,
                13.328,
                13.332,
                13.335,
                13.339,
                13.342,
                13.345,
                13.349,
                13.352,
                13.356,
                13.359,
                13.362,
                13.366,
                13.369,
                13.373,
                13.376,
                13.379,
                13.383,
                13.386,
                13.39,
                13.393,
                13.397,
                13.4,
                13.404,
                13.407,
                13.41,
                13.414,
                13.417,
                13.421,
                13.424,
                13.427,
                13.431,
                13.434,
                13.438,
                13.441,
                13.444,
                13.448,
                13.451,
                13.455,
                13.458,
                13.461,
                13.465,
                13.468,
                13.472,
                13.475,
                13.478,
                13.482,
                13.485,
                13.489,
                13.492,
                13.495,
                13.499,
                13.502,
                13.506,
                13.509,
                13.512,
                13.516,
                13.519,
                13.522,
                13.526,
                13.529,
                13.533,
                13.536,
                13.54,
                13.543,
                13.546,
                13.55,
                13.553,
                13.556,
                13.56,
                13.563,
                13.567,
                13.57,
                13.573,
                13.577,
                13.58,
                13.584,
                13.587

            ],
            P3: [
                2.507,
                2.485,
                2.501,
                2.523,
                2.547,
                2.573,
                2.6,
                2.628,
                2.656,
                2.686,
                2.717,
                2.748,
                2.781,
                2.814,
                2.849,
                2.885,
                2.921,
                2.957,
                2.994,
                3.031,
                3.068,
                3.105,
                3.142,
                3.179,
                3.216,
                3.252,
                3.289,
                3.325,
                3.362,
                3.398,
                3.433,
                3.469,
                3.504,
                3.539,
                3.574,
                3.608,
                3.642,
                3.675,
                3.708,
                3.741,
                3.774,
                3.806,
                3.838,
                3.87,
                3.901,
                3.932,
                3.963,
                3.993,
                4.024,
                4.053,
                4.083,
                4.112,
                4.141,
                4.17,
                4.198,
                4.226,
                4.254,
                4.282,
                4.309,
                4.336,
                4.363,
                4.39,
                4.416,
                4.442,
                4.468,
                4.494,
                4.519,
                4.544,
                4.569,
                4.594,
                4.618,
                4.643,
                4.667,
                4.691,
                4.714,
                4.738,
                4.761,
                4.784,
                4.807,
                4.829,
                4.852,
                4.874,
                4.896,
                4.918,
                4.939,
                4.961,
                4.982,
                5.003,
                5.024,
                5.045,
                5.065,
                5.086,
                5.106,
                5.126,
                5.146,
                5.165,
                5.185,
                5.204,
                5.224,
                5.243,
                5.262,
                5.281,
                5.299,
                5.318,
                5.336,
                5.354,
                5.372,
                5.39,
                5.408,
                5.426,
                5.443,
                5.461,
                5.478,
                5.495,
                5.512,
                5.529,
                5.546,
                5.562,
                5.579,
                5.595,
                5.611,
                5.628,
                5.644,
                5.66,
                5.675,
                5.691,
                5.707,
                5.722,
                5.738,
                5.753,
                5.768,
                5.783,
                5.798,
                5.813,
                5.828,
                5.842,
                5.857,
                5.871,
                5.886,
                5.9,
                5.914,
                5.928,
                5.942,
                5.956,
                5.97,
                5.983,
                5.997,
                6.01,
                6.024,
                6.037,
                6.05,
                6.063,
                6.076,
                6.089,
                6.102,
                6.115,
                6.128,
                6.14,
                6.153,
                6.166,
                6.178,
                6.19,
                6.202,
                6.215,
                6.227,
                6.239,
                6.251,
                6.262,
                6.274,
                6.286,
                6.298,
                6.309,
                6.321,
                6.332,
                6.343,
                6.355,
                6.366,
                6.377,
                6.388,
                6.399,
                6.41,
                6.421,
                6.432,
                6.443,
                6.454,
                6.464,
                6.475,
                6.486,
                6.496,
                6.507,
                6.517,
                6.527,
                6.538,
                6.548,
                6.558,
                6.568,
                6.578,
                6.588,
                6.598,
                6.608,
                6.618,
                6.628,
                6.638,
                6.647,
                6.657,
                6.667,
                6.676,
                6.686,
                6.695,
                6.705,
                6.714,
                6.723,
                6.733,
                6.742,
                6.751,
                6.76,
                6.769,
                6.779,
                6.788,
                6.797,
                6.805,
                6.814,
                6.823,
                6.832,
                6.841,
                6.85,
                6.858,
                6.867,
                6.876,
                6.884,
                6.893,
                6.902,
                6.91,
                6.919,
                6.927,
                6.935,
                6.944,
                6.952,
                6.96,
                6.969,
                6.977,
                6.985,
                6.993,
                7.001,
                7.009,
                7.018,
                7.026,
                7.034,
                7.042,
                7.05,
                7.057,
                7.066,
                7.073,
                7.081,
                7.089,
                7.097,
                7.105,
                7.112,
                7.12,
                7.128,
                7.135,
                7.143,
                7.151,
                7.158,
                7.166,
                7.173,
                7.181,
                7.188,
                7.196,
                7.203,
                7.211,
                7.218,
                7.226,
                7.233,
                7.24,
                7.248,
                7.255,
                7.262,
                7.27,
                7.277,
                7.284,
                7.291,
                7.298,
                7.306,
                7.313,
                7.32,
                7.327,
                7.334,
                7.341,
                7.348,
                7.355,
                7.362,
                7.369,
                7.376,
                7.383,
                7.39,
                7.397,
                7.404,
                7.411,
                7.418,
                7.425,
                7.431,
                7.438,
                7.445,
                7.452,
                7.459,
                7.466,
                7.472,
                7.479,
                7.486,
                7.492,
                7.499,
                7.506,
                7.513,
                7.519,
                7.526,
                7.532,
                7.539,
                7.546,
                7.552,
                7.559,
                7.565,
                7.572,
                7.579,
                7.585,
                7.591,
                7.598,
                7.604,
                7.611,
                7.617,
                7.624,
                7.63,
                7.637,
                7.643,
                7.65,
                7.656,
                7.662,
                7.669,
                7.675,
                7.682,
                7.688,
                7.694,
                7.701,
                7.707,
                7.713,
                7.719,
                7.726,
                7.732,
                7.738,
                7.744,
                7.751,
                7.757,
                7.763,
                7.77,
                7.776,
                7.782,
                7.788,
                7.794,
                7.801,
                7.807,
                7.813,
                7.819,
                7.825,
                7.831,
                7.837,
                7.844,
                7.85,
                7.856,
                7.862,
                7.868,
                7.874,
                7.88,
                7.886,
                7.892,
                7.898,
                7.904,
                7.91,
                7.916,
                7.922,
                7.928,
                7.934,
                7.94,
                7.946,
                7.952,
                7.958,
                7.964,
                7.97,
                7.976,
                7.982,
                7.988,
                7.994,
                8,
                8.006,
                8.012,
                8.017,
                8.023,
                8.029,
                8.035,
                8.041,
                8.047,
                8.053,
                8.059,
                8.065,
                8.07,
                8.076,
                8.082,
                8.088,
                8.094,
                8.099,
                8.105,
                8.111,
                8.117,
                8.123,
                8.128,
                8.134,
                8.14,
                8.146,
                8.151,
                8.157,
                8.163,
                8.169,
                8.174,
                8.18,
                8.186,
                8.192,
                8.197,
                8.203,
                8.209,
                8.214,
                8.22,
                8.226,
                8.231,
                8.237,
                8.243,
                8.249,
                8.254,
                8.26,
                8.265,
                8.271,
                8.277,
                8.282,
                8.288,
                8.294,
                8.299,
                8.305,
                8.311,
                8.316,
                8.322,
                8.327,
                8.333,
                8.339,
                8.344,
                8.35,
                8.355,
                8.361,
                8.366,
                8.372,
                8.378,
                8.383,
                8.389,
                8.394,
                8.4,
                8.405,
                8.411,
                8.417,
                8.422,
                8.428,
                8.433,
                8.439,
                8.444,
                8.45,
                8.455,
                8.461,
                8.466,
                8.472,
                8.477,
                8.483,
                8.488,
                8.494,
                8.499,
                8.505,
                8.51,
                8.516,
                8.521,
                8.527,
                8.532,
                8.538,
                8.543,
                8.549,
                8.554,
                8.56,
                8.565,
                8.571,
                8.576,
                8.581,
                8.587,
                8.592,
                8.598,
                8.603,
                8.609,
                8.614,
                8.619,
                8.625,
                8.63,
                8.636,
                8.641,
                8.647,
                8.652,
                8.657,
                8.663,
                8.668,
                8.674,
                8.679,
                8.684,
                8.69,
                8.695,
                8.7,
                8.706,
                8.711,
                8.716,
                8.722,
                8.727,
                8.733,
                8.738,
                8.743,
                8.749,
                8.754,
                8.759,
                8.765,
                8.77,
                8.775,
                8.781,
                8.786,
                8.791,
                8.797,
                8.802,
                8.807,
                8.813,
                8.818,
                8.823,
                8.829,
                8.834,
                8.839,
                8.844,
                8.85,
                8.855,
                8.86,
                8.866,
                8.871,
                8.876,
                8.881,
                8.887,
                8.892,
                8.897,
                8.902,
                8.908,
                8.913,
                8.918,
                8.923,
                8.929,
                8.934,
                8.939,
                8.945,
                8.95,
                8.955,
                8.96,
                8.966,
                8.971,
                8.976,
                8.981,
                8.986,
                8.992,
                8.997,
                9.002,
                9.007,
                9.012,
                9.018,
                9.023,
                9.028,
                9.033,
                9.039,
                9.044,
                9.049,
                9.054,
                9.059,
                9.064,
                9.07,
                9.075,
                9.08,
                9.085,
                9.09,
                9.096,
                9.101,
                9.106,
                9.111,
                9.116,
                9.122,
                9.127,
                9.132,
                9.137,
                9.142,
                9.147,
                9.153,
                9.158,
                9.163,
                9.168,
                9.173,
                9.178,
                9.183,
                9.189,
                9.194,
                9.199,
                9.204,
                9.209,
                9.214,
                9.22,
                9.225,
                9.23,
                9.235,
                9.24,
                9.245,
                9.25,
                9.256,
                9.261,
                9.266,
                9.271,
                9.276,
                9.281,
                9.286,
                9.292,
                9.297,
                9.302,
                9.307,
                9.312,
                9.317,
                9.322,
                9.327,
                9.332,
                9.338,
                9.343,
                9.348,
                9.353,
                9.358,
                9.363,
                9.368,
                9.373,
                9.378,
                9.384,
                9.389,
                9.394,
                9.399,
                9.404,
                9.409,
                9.414,
                9.419,
                9.424,
                9.429,
                9.435,
                9.44,
                9.445,
                9.45,
                9.455,
                9.46,
                9.465,
                9.47,
                9.475,
                9.48,
                9.485,
                9.49,
                9.495,
                9.501,
                9.506,
                9.511,
                9.516,
                9.521,
                9.526,
                9.531,
                9.536,
                9.541,
                9.546,
                9.551,
                9.556,
                9.561,
                9.566,
                9.571,
                9.576,
                9.581,
                9.587,
                9.592,
                9.597,
                9.602,
                9.607,
                9.612,
                9.617,
                9.622,
                9.627,
                9.632,
                9.637,
                9.642,
                9.647,
                9.652,
                9.657,
                9.662,
                9.667,
                9.672,
                9.677,
                9.682,
                9.687,
                9.692,
                9.697,
                9.702,
                9.707,
                9.712,
                9.717,
                9.722,
                9.727,
                9.732,
                9.737,
                9.742,
                9.747,
                9.752,
                9.757,
                9.762,
                9.767,
                9.772,
                9.777,
                9.782,
                9.787,
                9.792,
                9.797,
                9.802,
                9.807,
                9.812,
                9.817,
                9.822,
                9.827,
                9.832,
                9.837,
                9.842,
                9.847,
                9.852,
                9.857,
                9.862,
                9.867,
                9.872,
                9.877,
                9.882,
                9.887,
                9.892,
                9.896,
                9.901,
                9.906,
                9.911,
                9.916,
                9.921,
                9.926,
                9.931,
                9.936,
                9.941,
                9.946,
                9.951,
                9.956,
                9.961,
                9.965,
                9.97,
                9.975,
                9.98,
                9.985,
                9.99,
                9.995,
                10,
                10.005,
                10.01,
                10.014,
                10.019,
                10.024,
                10.029,
                10.034,
                10.039,
                10.044,
                10.048,
                10.053,
                10.058,
                10.063,
                10.068,
                10.073,
                10.078,
                10.082,
                10.087,
                10.092,
                10.097,
                10.102,
                10.107,
                10.111,
                10.116,
                10.121,
                10.126,
                10.131,
                10.136,
                10.14,
                10.145,
                10.15,
                10.155,
                10.16,
                10.164,
                10.169,
                10.174,
                10.179,
                10.183,
                10.188,
                10.193,
                10.198,
                10.202,
                10.207,
                10.212,
                10.217,
                10.221,
                10.226,
                10.231,
                10.236,
                10.24,
                10.245,
                10.25,
                10.255,
                10.26,
                10.264,
                10.269,
                10.274,
                10.278,
                10.283,
                10.288,
                10.293,
                10.297,
                10.302,
                10.307,
                10.311,
                10.316,
                10.321,
                10.325,
                10.33,
                10.335,
                10.339,
                10.344,
                10.349,
                10.353,
                10.358,
                10.363,
                10.367,
                10.372,
                10.377,
                10.381,
                10.386,
                10.391,
                10.395,
                10.4,
                10.404,
                10.409,
                10.414,
                10.418,
                10.423,
                10.428,
                10.432,
                10.437,
                10.441,
                10.446,
                10.451,
                10.455,
                10.46,
                10.464,
                10.469,
                10.474,
                10.478,
                10.483,
                10.487,
                10.492,
                10.496,
                10.501,
                10.505,
                10.51,
                10.515,
                10.519,
                10.524,
                10.528,
                10.533,
                10.537,
                10.542,
                10.546,
                10.551,
                10.555,
                10.56,
                10.564,
                10.569,
                10.574,
                10.578,
                10.583,
                10.587,
                10.591,
                10.596,
                10.6,
                10.605,
                10.609,
                10.614,
                10.618,
                10.623,
                10.627,
                10.632,
                10.636,
                10.641,
                10.645,
                10.65,
                10.654,
                10.658,
                10.663,
                10.667,
                10.672,
                10.676,
                10.681,
                10.685,
                10.69,
                10.694,
                10.698,
                10.703,
                10.707,
                10.712,
                10.716,
                10.72,
                10.725,
                10.729,
                10.734,
                10.738,
                10.742,
                10.747,
                10.751,
                10.756,
                10.76,
                10.764,
                10.769,
                10.773,
                10.778,
                10.782,
                10.786,
                10.791,
                10.795,
                10.799,
                10.804,
                10.808,
                10.812,
                10.817,
                10.821,
                10.825,
                10.83,
                10.834,
                10.838,
                10.843,
                10.847,
                10.851,
                10.856,
                10.86,
                10.864,
                10.869,
                10.873,
                10.877,
                10.881,
                10.886,
                10.89,
                10.894,
                10.899,
                10.903,
                10.907,
                10.911,
                10.916,
                10.92,
                10.925,
                10.929,
                10.933,
                10.937,
                10.941,
                10.946,
                10.95,
                10.954,
                10.959,
                10.963,
                10.967,
                10.971,
                10.976,
                10.98,
                10.984,
                10.988,
                10.993,
                10.997,
                11.001,
                11.005,
                11.009,
                11.014,
                11.018,
                11.022,
                11.026,
                11.031,
                11.035,
                11.039,
                11.043,
                11.048,
                11.052,
                11.056,
                11.06,
                11.064,
                11.068,
                11.073,
                11.077,
                11.081,
                11.085,
                11.09,
                11.094,
                11.098,
                11.102,
                11.106,
                11.111,
                11.115,
                11.119,
                11.123,
                11.127,
                11.131,
                11.136,
                11.14,
                11.144,
                11.148,
                11.152,
                11.156,
                11.161,
                11.165,
                11.169,
                11.173,
                11.177,
                11.181,
                11.186,
                11.19,
                11.194,
                11.198,
                11.202,
                11.206,
                11.211,
                11.215,
                11.219,
                11.223,
                11.227,
                11.231,
                11.236,
                11.24,
                11.244,
                11.248,
                11.252,
                11.256,
                11.26,
                11.264,
                11.269,
                11.273,
                11.277,
                11.281,
                11.285,
                11.289,
                11.294,
                11.298,
                11.302,
                11.306,
                11.31,
                11.314,
                11.318,
                11.322,
                11.327,
                11.331,
                11.335,
                11.339,
                11.343,
                11.347,
                11.351,
                11.355,
                11.359,
                11.364,
                11.368,
                11.372,
                11.376,
                11.38,
                11.384,
                11.388,
                11.392,
                11.396,
                11.4,
                11.405,
                11.409,
                11.413,
                11.417,
                11.421,
                11.425,
                11.429,
                11.433,
                11.437,
                11.441,
                11.446,
                11.45,
                11.454,
                11.458,
                11.462,
                11.466,
                11.47,
                11.474,
                11.478,
                11.482,
                11.486,
                11.491,
                11.495,
                11.499,
                11.503,
                11.507,
                11.511,
                11.515,
                11.519,
                11.523,
                11.527,
                11.531,
                11.535,
                11.539,
                11.544,
                11.548,
                11.552,
                11.556,
                11.56,
                11.564,
                11.568,
                11.572,
                11.576,
                11.58,
                11.584,
                11.588,
                11.592,
                11.596,
                11.601,
                11.605,
                11.609,
                11.613,
                11.617,
                11.621,
                11.625,
                11.629,
                11.633,
                11.637,
                11.641,
                11.645,
                11.649,
                11.653,
                11.657,
                11.661,
                11.665,
                11.67,
                11.674,
                11.678,
                11.682,
                11.686,
                11.69,
                11.694,
                11.698,
                11.702,
                11.706,
                11.71,
                11.714,
                11.718,
                11.722,
                11.726,
                11.73,
                11.735,
                11.739,
                11.743,
                11.747,
                11.751,
                11.755,
                11.759,
                11.763,
                11.767,
                11.771,
                11.775,
                11.779,
                11.783,
                11.787,
                11.791,
                11.795,
                11.799,
                11.803,
                11.807,
                11.812,
                11.816,
                11.819,
                11.824,
                11.828,
                11.832,
                11.836,
                11.84,
                11.844,
                11.848,
                11.852,
                11.856,
                11.86,
                11.864,
                11.868,
                11.872,
                11.876,
                11.88,
                11.884,
                11.888,
                11.892,
                11.897,
                11.901,
                11.904,
                11.909,
                11.913,
                11.917,
                11.921,
                11.925,
                11.929,
                11.933,
                11.937,
                11.941,
                11.945,
                11.949,
                11.953,
                11.957,
                11.961,
                11.965,
                11.969,
                11.973,
                11.977,
                11.981,
                11.985,
                11.989,
                11.993,
                11.998,
                12.002,
                12.006,
                12.01,
                12.014,
                12.018,
                12.022,
                12.026,
                12.03,
                12.034,
                12.038,
                12.042,
                12.046,
                12.05,
                12.054,
                12.058,
                12.062,
                12.066,
                12.07,
                12.074,
                12.078,
                12.082,
                12.086,
                12.09,
                12.094,
                12.098,
                12.102,
                12.106,
                12.11,
                12.114,
                12.118,
                12.122,
                12.127,
                12.13,
                12.134,
                12.139,
                12.143,
                12.147,
                12.151,
                12.155,
                12.159,
                12.162,
                12.167,
                12.171,
                12.175,
                12.179,
                12.183,
                12.187,
                12.191,
                12.195,
                12.199,
                12.203,
                12.207,
                12.211,
                12.215,
                12.219,
                12.223,
                12.227,
                12.231,
                12.235,
                12.239,
                12.243,
                12.247,
                12.251,
                12.255,
                12.259,
                12.263,
                12.267,
                12.271,
                12.275,
                12.279,
                12.283,
                12.287,
                12.291,
                12.295,
                12.299,
                12.303,
                12.307,
                12.311,
                12.315,
                12.319,
                12.323,
                12.327,
                12.331,
                12.335,
                12.339,
                12.343,
                12.347,
                12.351,
                12.355,
                12.359,
                12.363,
                12.367,
                12.371,
                12.375,
                12.379,
                12.383,
                12.387,
                12.391,
                12.395,
                12.399,
                12.403,
                12.407,
                12.411,
                12.415,
                12.419,
                12.423,
                12.427,
                12.431,
                12.435,
                12.438,
                12.442,
                12.446,
                12.451,
                12.454,
                12.458,
                12.462,
                12.466,
                12.47,
                12.474,
                12.478,
                12.482,
                12.486,
                12.49,
                12.494,
                12.498,
                12.502,
                12.506,
                12.51,
                12.514,
                12.518,
                12.522,
                12.526,
                12.53,
                12.534,
                12.538,
                12.542,
                12.545,
                12.549,
                12.553,
                12.557,
                12.561,
                12.565,
                12.569,
                12.573,
                12.577,
                12.581,
                12.585,
                12.589,
                12.593,
                12.597,
                12.601,
                12.605,
                12.609,
                12.613,
                12.616,
                12.62,
                12.624,
                12.628,
                12.632,
                12.636,
                12.64,
                12.644,
                12.648,
                12.652,
                12.656,
                12.66,
                12.664,
                12.668,
                12.672,
                12.675,
                12.679,
                12.683,
                12.687,
                12.691,
                12.695,
                12.699,
                12.703,
                12.707,
                12.711,
                12.715,
                12.719,
                12.722,
                12.726,
                12.731,
                12.734,
                12.738,
                12.742,
                12.746,
                12.75,
                12.754,
                12.758,
                12.762,
                12.766,
                12.77,
                12.774,
                12.777,
                12.781,
                12.785,
                12.789,
                12.793,
                12.797,
                12.801,
                12.805,
                12.809,
                12.812,
                12.816,
                12.82,
                12.824,
                12.828,
                12.832,
                12.836,
                12.84,
                12.844,
                12.848,
                12.851,
                12.855,
                12.859,
                12.863,
                12.867,
                12.871,
                12.875,
                12.879,
                12.883,
                12.887,
                12.89,
                12.894,
                12.898,
                12.902,
                12.906,
                12.91,
                12.914,
                12.918,
                12.921,
                12.925,
                12.929,
                12.933,
                12.937,
                12.941,
                12.945,
                12.949,
                12.952,
                12.956,
                12.96,
                12.964,
                12.968,
                12.972,
                12.976,
                12.98,
                12.983,
                12.987,
                12.991,
                12.995,
                12.999,
                13.003,
                13.007,
                13.011,
                13.015,
                13.018,
                13.022,
                13.026,
                13.03,
                13.034,
                13.038,
                13.042,
                13.045,
                13.049,
                13.053,
                13.057,
                13.061,
                13.065,
                13.069,
                13.072,
                13.076,
                13.08,
                13.084,
                13.088,
                13.092,
                13.096,
                13.1,
                13.103,
                13.107,
                13.111,
                13.115,
                13.119,
                13.122,
                13.126,
                13.13,
                13.134,
                13.138,
                13.142,
                13.146,
                13.149,
                13.153,
                13.157,
                13.161,
                13.165,
                13.169,
                13.173,
                13.176,
                13.18,
                13.184,
                13.188,
                13.192,
                13.196,
                13.2,
                13.203,
                13.207,
                13.211,
                13.215,
                13.219,
                13.223,
                13.226,
                13.23,
                13.234,
                13.238,
                13.242,
                13.246,
                13.249,
                13.253,
                13.257,
                13.261,
                13.265,
                13.269,
                13.272,
                13.276,
                13.28,
                13.284,
                13.288,
                13.292,
                13.295,
                13.299,
                13.303,
                13.307,
                13.311,
                13.314,
                13.318,
                13.322,
                13.326,
                13.33,
                13.334,
                13.338,
                13.341,
                13.345,
                13.349,
                13.353,
                13.356,
                13.36,
                13.364,
                13.368,
                13.372,
                13.376,
                13.38,
                13.383,
                13.387,
                13.391,
                13.395,
                13.399,
                13.402,
                13.406,
                13.41,
                13.414,
                13.418,
                13.422,
                13.425,
                13.429,
                13.433,
                13.437,
                13.441,
                13.445,
                13.448,
                13.452,
                13.456,
                13.46,
                13.464,
                13.467,
                13.471,
                13.475,
                13.479,
                13.483,
                13.486,
                13.49,
                13.494,
                13.498,
                13.502,
                13.505,
                13.509,
                13.513,
                13.517,
                13.521,
                13.525,
                13.528,
                13.532,
                13.536,
                13.54,
                13.543,
                13.547,
                13.551,
                13.555,
                13.559,
                13.563,
                13.566,
                13.57,
                13.574,
                13.578,
                13.581,
                13.585,
                13.589,
                13.593,
                13.597,
                13.601,
                13.604,
                13.608,
                13.612,
                13.616,
                13.619,
                13.623,
                13.627,
                13.631,
                13.635,
                13.638,
                13.642,
                13.646,
                13.65,
                13.653,
                13.657,
                13.661,
                13.665,
                13.669,
                13.673,
                13.676,
                13.68,
                13.684,
                13.688,
                13.691,
                13.695,
                13.699,
                13.703,
                13.707,
                13.71,
                13.714,
                13.718,
                13.722,
                13.725,
                13.729,
                13.733,
                13.737,
                13.741,
                13.744,
                13.748,
                13.752,
                13.756,
                13.759,
                13.763,
                13.767,
                13.771,
                13.775,
                13.778,
                13.782,
                13.786,
                13.79,
                13.793,
                13.797,
                13.801,
                13.805,
                13.809,
                13.812,
                13.816,
                13.82,
                13.824,
                13.827,
                13.831,
                13.835,
                13.839,
                13.843,
                13.846,
                13.85,
                13.854,
                13.857,
                13.861,
                13.865,
                13.869,
                13.873,
                13.876,
                13.88,
                13.884,
                13.888,
                13.891,
                13.895,
                13.899,
                13.903,
                13.906,
                13.91,
                13.914,
                13.918,
                13.921,
                13.925,
                13.929,
                13.933,
                13.936,
                13.94,
                13.944,
                13.948,
                13.952,
                13.955,
                13.959,
                13.963,
                13.966,
                13.97,
                13.974,
                13.978,
                13.981,
                13.985,
                13.989,
                13.993,
                13.996,
                14,
                14.004,
                14.008,
                14.011,
                14.015,
                14.019,
                14.023,
                14.026,
                14.03,
                14.034,
                14.038,
                14.041,
                14.045,
                14.049,
                14.053,
                14.056,
                14.06,
                14.064,
                14.067,
                14.071,
                14.075,
                14.079,
                14.082,
                14.086,
                14.09,
                14.094,
                14.097,
                14.101,
                14.105,
                14.109,
                14.112,
                14.116,
                14.12,
                14.124,
                14.127,
                14.131,
                14.135,
                14.138,
                14.142,
                14.146,
                14.15,
                14.153,
                14.157,
                14.161,
                14.164,
                14.168,
                14.172,
                14.176,
                14.179,
                14.183,
                14.187,
                14.191,
                14.194,
                14.198,
                14.201,
                14.205,
                14.209,
                14.213,
                14.217,
                14.22,
                14.224,
                14.228,
                14.231,
                14.235,
                14.239,
                14.242,
                14.246,
                14.25,
                14.254,
                14.257,
                14.261,
                14.265,
                14.269,
                14.272,
                14.276,
                14.279,
                14.283,
                14.287,
                14.291,
                14.294,
                14.298,
                14.302,
                14.306,
                14.309,
                14.313,
                14.316,
                14.32,
                14.324,
                14.328,
                14.331,
                14.335,
                14.339,
                14.342,
                14.346,
                14.35,
                14.354,
                14.357,
                14.361,
                14.365,
                14.368,
                14.372,
                14.376,
                14.379,
                14.383,
                14.387,
                14.39,
                14.394,
                14.398

            ],
            P5: [
                2.604,
                2.58,
                2.597,
                2.619,
                2.644,
                2.67,
                2.697,
                2.726,
                2.755,
                2.786,
                2.817,
                2.849,
                2.883,
                2.917,
                2.952,
                2.989,
                3.026,
                3.063,
                3.101,
                3.139,
                3.176,
                3.214,
                3.252,
                3.29,
                3.328,
                3.365,
                3.403,
                3.44,
                3.477,
                3.514,
                3.55,
                3.587,
                3.622,
                3.658,
                3.693,
                3.728,
                3.763,
                3.797,
                3.831,
                3.865,
                3.898,
                3.931,
                3.964,
                3.996,
                4.028,
                4.06,
                4.091,
                4.122,
                4.153,
                4.183,
                4.213,
                4.243,
                4.273,
                4.302,
                4.331,
                4.359,
                4.388,
                4.416,
                4.444,
                4.471,
                4.499,
                4.526,
                4.553,
                4.579,
                4.606,
                4.632,
                4.658,
                4.683,
                4.709,
                4.734,
                4.759,
                4.783,
                4.808,
                4.832,
                4.856,
                4.88,
                4.904,
                4.927,
                4.95,
                4.973,
                4.996,
                5.019,
                5.041,
                5.063,
                5.085,
                5.107,
                5.128,
                5.15,
                5.171,
                5.192,
                5.213,
                5.234,
                5.254,
                5.275,
                5.295,
                5.315,
                5.335,
                5.355,
                5.374,
                5.394,
                5.413,
                5.432,
                5.451,
                5.47,
                5.488,
                5.507,
                5.525,
                5.543,
                5.562,
                5.58,
                5.597,
                5.615,
                5.633,
                5.65,
                5.667,
                5.684,
                5.702,
                5.718,
                5.735,
                5.752,
                5.768,
                5.785,
                5.801,
                5.817,
                5.834,
                5.85,
                5.865,
                5.881,
                5.897,
                5.912,
                5.928,
                5.943,
                5.958,
                5.973,
                5.988,
                6.003,
                6.018,
                6.033,
                6.047,
                6.062,
                6.076,
                6.091,
                6.105,
                6.119,
                6.133,
                6.147,
                6.161,
                6.175,
                6.188,
                6.202,
                6.215,
                6.229,
                6.242,
                6.255,
                6.268,
                6.281,
                6.294,
                6.307,
                6.32,
                6.333,
                6.345,
                6.358,
                6.37,
                6.383,
                6.395,
                6.407,
                6.42,
                6.432,
                6.444,
                6.456,
                6.467,
                6.479,
                6.491,
                6.503,
                6.514,
                6.526,
                6.537,
                6.549,
                6.56,
                6.571,
                6.583,
                6.594,
                6.605,
                6.616,
                6.627,
                6.638,
                6.649,
                6.659,
                6.67,
                6.681,
                6.691,
                6.702,
                6.713,
                6.723,
                6.734,
                6.744,
                6.754,
                6.764,
                6.775,
                6.785,
                6.795,
                6.805,
                6.815,
                6.825,
                6.835,
                6.844,
                6.854,
                6.864,
                6.874,
                6.883,
                6.893,
                6.902,
                6.912,
                6.922,
                6.931,
                6.94,
                6.95,
                6.959,
                6.968,
                6.977,
                6.987,
                6.996,
                7.005,
                7.014,
                7.023,
                7.032,
                7.041,
                7.05,
                7.058,
                7.067,
                7.076,
                7.085,
                7.094,
                7.102,
                7.111,
                7.12,
                7.128,
                7.137,
                7.145,
                7.154,
                7.162,
                7.17,
                7.179,
                7.187,
                7.195,
                7.204,
                7.212,
                7.22,
                7.228,
                7.237,
                7.245,
                7.253,
                7.261,
                7.269,
                7.277,
                7.285,
                7.293,
                7.301,
                7.309,
                7.317,
                7.324,
                7.332,
                7.34,
                7.348,
                7.356,
                7.363,
                7.371,
                7.379,
                7.387,
                7.394,
                7.402,
                7.409,
                7.417,
                7.425,
                7.432,
                7.44,
                7.447,
                7.455,
                7.462,
                7.469,
                7.477,
                7.484,
                7.492,
                7.499,
                7.506,
                7.514,
                7.521,
                7.528,
                7.536,
                7.543,
                7.55,
                7.557,
                7.564,
                7.571,
                7.578,
                7.586,
                7.593,
                7.6,
                7.607,
                7.614,
                7.621,
                7.628,
                7.635,
                7.642,
                7.649,
                7.656,
                7.663,
                7.67,
                7.677,
                7.684,
                7.691,
                7.697,
                7.704,
                7.711,
                7.718,
                7.725,
                7.732,
                7.738,
                7.745,
                7.752,
                7.759,
                7.765,
                7.772,
                7.779,
                7.785,
                7.792,
                7.799,
                7.805,
                7.812,
                7.819,
                7.825,
                7.832,
                7.839,
                7.845,
                7.852,
                7.858,
                7.865,
                7.872,
                7.878,
                7.885,
                7.891,
                7.898,
                7.904,
                7.911,
                7.917,
                7.924,
                7.93,
                7.936,
                7.943,
                7.949,
                7.956,
                7.962,
                7.969,
                7.975,
                7.981,
                7.988,
                7.994,
                8,
                8.007,
                8.013,
                8.019,
                8.026,
                8.032,
                8.038,
                8.045,
                8.051,
                8.057,
                8.063,
                8.07,
                8.076,
                8.082,
                8.088,
                8.095,
                8.101,
                8.107,
                8.113,
                8.119,
                8.126,
                8.132,
                8.138,
                8.144,
                8.15,
                8.156,
                8.163,
                8.169,
                8.175,
                8.181,
                8.187,
                8.193,
                8.199,
                8.205,
                8.211,
                8.218,
                8.224,
                8.23,
                8.236,
                8.242,
                8.248,
                8.254,
                8.26,
                8.266,
                8.272,
                8.278,
                8.284,
                8.29,
                8.296,
                8.302,
                8.308,
                8.314,
                8.32,
                8.326,
                8.332,
                8.338,
                8.344,
                8.349,
                8.355,
                8.361,
                8.367,
                8.373,
                8.379,
                8.385,
                8.391,
                8.397,
                8.403,
                8.409,
                8.414,
                8.42,
                8.426,
                8.432,
                8.438,
                8.444,
                8.45,
                8.455,
                8.461,
                8.467,
                8.473,
                8.479,
                8.485,
                8.49,
                8.496,
                8.502,
                8.508,
                8.513,
                8.519,
                8.525,
                8.531,
                8.537,
                8.542,
                8.548,
                8.554,
                8.56,
                8.565,
                8.571,
                8.577,
                8.583,
                8.588,
                8.594,
                8.6,
                8.606,
                8.611,
                8.617,
                8.623,
                8.629,
                8.634,
                8.64,
                8.646,
                8.651,
                8.657,
                8.663,
                8.668,
                8.674,
                8.68,
                8.685,
                8.691,
                8.697,
                8.703,
                8.708,
                8.714,
                8.719,
                8.725,
                8.731,
                8.736,
                8.742,
                8.748,
                8.753,
                8.759,
                8.765,
                8.77,
                8.776,
                8.781,
                8.787,
                8.793,
                8.798,
                8.804,
                8.81,
                8.815,
                8.821,
                8.826,
                8.832,
                8.837,
                8.843,
                8.849,
                8.854,
                8.86,
                8.865,
                8.871,
                8.877,
                8.882,
                8.888,
                8.893,
                8.899,
                8.904,
                8.91,
                8.915,
                8.921,
                8.927,
                8.932,
                8.938,
                8.943,
                8.949,
                8.954,
                8.96,
                8.965,
                8.971,
                8.976,
                8.982,
                8.987,
                8.993,
                8.998,
                9.004,
                9.009,
                9.015,
                9.02,
                9.026,
                9.031,
                9.037,
                9.042,
                9.048,
                9.053,
                9.059,
                9.064,
                9.069,
                9.075,
                9.08,
                9.086,
                9.091,
                9.097,
                9.102,
                9.108,
                9.113,
                9.119,
                9.124,
                9.129,
                9.135,
                9.14,
                9.146,
                9.151,
                9.157,
                9.162,
                9.167,
                9.173,
                9.178,
                9.184,
                9.189,
                9.194,
                9.2,
                9.205,
                9.211,
                9.216,
                9.221,
                9.227,
                9.232,
                9.237,
                9.243,
                9.248,
                9.254,
                9.259,
                9.264,
                9.27,
                9.275,
                9.281,
                9.286,
                9.291,
                9.296,
                9.302,
                9.307,
                9.313,
                9.318,
                9.323,
                9.329,
                9.334,
                9.339,
                9.345,
                9.35,
                9.355,
                9.361,
                9.366,
                9.371,
                9.377,
                9.382,
                9.387,
                9.393,
                9.398,
                9.403,
                9.409,
                9.414,
                9.419,
                9.425,
                9.43,
                9.435,
                9.441,
                9.446,
                9.451,
                9.457,
                9.462,
                9.467,
                9.473,
                9.478,
                9.483,
                9.488,
                9.494,
                9.499,
                9.504,
                9.51,
                9.515,
                9.52,
                9.526,
                9.531,
                9.536,
                9.542,
                9.547,
                9.552,
                9.557,
                9.563,
                9.568,
                9.573,
                9.579,
                9.584,
                9.589,
                9.594,
                9.6,
                9.605,
                9.61,
                9.615,
                9.621,
                9.626,
                9.631,
                9.637,
                9.642,
                9.647,
                9.652,
                9.658,
                9.663,
                9.668,
                9.673,
                9.679,
                9.684,
                9.689,
                9.694,
                9.7,
                9.705,
                9.71,
                9.715,
                9.721,
                9.726,
                9.731,
                9.736,
                9.742,
                9.747,
                9.752,
                9.757,
                9.763,
                9.768,
                9.773,
                9.778,
                9.784,
                9.789,
                9.794,
                9.799,
                9.805,
                9.81,
                9.815,
                9.82,
                9.825,
                9.831,
                9.836,
                9.841,
                9.846,
                9.852,
                9.857,
                9.862,
                9.867,
                9.872,
                9.878,
                9.883,
                9.888,
                9.893,
                9.898,
                9.904,
                9.909,
                9.914,
                9.919,
                9.924,
                9.93,
                9.935,
                9.94,
                9.945,
                9.95,
                9.956,
                9.961,
                9.966,
                9.971,
                9.976,
                9.982,
                9.987,
                9.992,
                9.997,
                10.002,
                10.007,
                10.013,
                10.018,
                10.023,
                10.028,
                10.033,
                10.038,
                10.043,
                10.049,
                10.054,
                10.059,
                10.064,
                10.069,
                10.075,
                10.08,
                10.085,
                10.09,
                10.095,
                10.1,
                10.105,
                10.111,
                10.116,
                10.121,
                10.126,
                10.131,
                10.136,
                10.141,
                10.146,
                10.151,
                10.157,
                10.162,
                10.167,
                10.172,
                10.177,
                10.182,
                10.187,
                10.192,
                10.198,
                10.203,
                10.208,
                10.213,
                10.218,
                10.223,
                10.228,
                10.233,
                10.238,
                10.243,
                10.248,
                10.253,
                10.258,
                10.264,
                10.269,
                10.274,
                10.279,
                10.284,
                10.289,
                10.294,
                10.299,
                10.304,
                10.309,
                10.314,
                10.319,
                10.324,
                10.329,
                10.334,
                10.339,
                10.344,
                10.349,
                10.354,
                10.359,
                10.364,
                10.369,
                10.374,
                10.379,
                10.384,
                10.389,
                10.394,
                10.399,
                10.404,
                10.409,
                10.414,
                10.419,
                10.424,
                10.429,
                10.434,
                10.439,
                10.444,
                10.449,
                10.454,
                10.459,
                10.464,
                10.469,
                10.474,
                10.479,
                10.484,
                10.489,
                10.494,
                10.498,
                10.503,
                10.508,
                10.513,
                10.518,
                10.523,
                10.528,
                10.533,
                10.538,
                10.543,
                10.548,
                10.552,
                10.557,
                10.562,
                10.567,
                10.572,
                10.577,
                10.582,
                10.587,
                10.591,
                10.596,
                10.601,
                10.606,
                10.611,
                10.616,
                10.621,
                10.625,
                10.63,
                10.635,
                10.64,
                10.645,
                10.65,
                10.655,
                10.659,
                10.664,
                10.669,
                10.674,
                10.679,
                10.683,
                10.688,
                10.693,
                10.698,
                10.702,
                10.707,
                10.712,
                10.717,
                10.721,
                10.726,
                10.731,
                10.736,
                10.741,
                10.746,
                10.75,
                10.755,
                10.76,
                10.764,
                10.769,
                10.774,
                10.779,
                10.783,
                10.788,
                10.793,
                10.797,
                10.802,
                10.807,
                10.812,
                10.816,
                10.821,
                10.826,
                10.831,
                10.835,
                10.84,
                10.845,
                10.849,
                10.854,
                10.859,
                10.863,
                10.868,
                10.873,
                10.877,
                10.882,
                10.887,
                10.891,
                10.896,
                10.901,
                10.905,
                10.91,
                10.914,
                10.919,
                10.924,
                10.929,
                10.933,
                10.938,
                10.942,
                10.947,
                10.952,
                10.956,
                10.961,
                10.965,
                10.97,
                10.974,
                10.979,
                10.984,
                10.988,
                10.993,
                10.997,
                11.002,
                11.007,
                11.011,
                11.016,
                11.02,
                11.025,
                11.03,
                11.034,
                11.039,
                11.043,
                11.048,
                11.052,
                11.057,
                11.061,
                11.066,
                11.07,
                11.075,
                11.08,
                11.084,
                11.088,
                11.093,
                11.098,
                11.102,
                11.107,
                11.111,
                11.116,
                11.12,
                11.125,
                11.129,
                11.134,
                11.138,
                11.143,
                11.147,
                11.151,
                11.156,
                11.161,
                11.165,
                11.17,
                11.174,
                11.178,
                11.183,
                11.187,
                11.192,
                11.196,
                11.201,
                11.205,
                11.21,
                11.214,
                11.218,
                11.223,
                11.227,
                11.232,
                11.236,
                11.241,
                11.245,
                11.25,
                11.254,
                11.258,
                11.263,
                11.267,
                11.272,
                11.276,
                11.281,
                11.285,
                11.289,
                11.294,
                11.298,
                11.303,
                11.307,
                11.311,
                11.316,
                11.32,
                11.324,
                11.329,
                11.333,
                11.338,
                11.342,
                11.346,
                11.351,
                11.355,
                11.36,
                11.364,
                11.368,
                11.373,
                11.377,
                11.381,
                11.386,
                11.39,
                11.395,
                11.399,
                11.403,
                11.408,
                11.412,
                11.416,
                11.421,
                11.425,
                11.429,
                11.434,
                11.438,
                11.442,
                11.447,
                11.451,
                11.455,
                11.46,
                11.464,
                11.468,
                11.473,
                11.477,
                11.481,
                11.486,
                11.49,
                11.494,
                11.499,
                11.503,
                11.507,
                11.512,
                11.516,
                11.52,
                11.524,
                11.529,
                11.533,
                11.537,
                11.542,
                11.546,
                11.55,
                11.555,
                11.559,
                11.563,
                11.568,
                11.572,
                11.576,
                11.58,
                11.585,
                11.589,
                11.593,
                11.598,
                11.602,
                11.606,
                11.611,
                11.615,
                11.619,
                11.623,
                11.628,
                11.632,
                11.636,
                11.64,
                11.645,
                11.649,
                11.653,
                11.658,
                11.662,
                11.666,
                11.67,
                11.675,
                11.679,
                11.683,
                11.688,
                11.692,
                11.696,
                11.7,
                11.705,
                11.709,
                11.713,
                11.717,
                11.722,
                11.726,
                11.73,
                11.734,
                11.739,
                11.743,
                11.747,
                11.751,
                11.756,
                11.76,
                11.764,
                11.768,
                11.773,
                11.777,
                11.781,
                11.785,
                11.79,
                11.794,
                11.798,
                11.802,
                11.807,
                11.811,
                11.815,
                11.819,
                11.823,
                11.828,
                11.832,
                11.836,
                11.84,
                11.845,
                11.849,
                11.853,
                11.857,
                11.862,
                11.866,
                11.87,
                11.874,
                11.879,
                11.883,
                11.887,
                11.891,
                11.895,
                11.9,
                11.904,
                11.908,
                11.912,
                11.916,
                11.921,
                11.925,
                11.929,
                11.934,
                11.938,
                11.942,
                11.946,
                11.95,
                11.955,
                11.959,
                11.963,
                11.967,
                11.971,
                11.976,
                11.98,
                11.984,
                11.988,
                11.993,
                11.997,
                12.001,
                12.005,
                12.009,
                12.013,
                12.018,
                12.022,
                12.026,
                12.03,
                12.035,
                12.039,
                12.043,
                12.047,
                12.051,
                12.056,
                12.06,
                12.064,
                12.068,
                12.073,
                12.077,
                12.081,
                12.085,
                12.089,
                12.094,
                12.098,
                12.102,
                12.106,
                12.11,
                12.115,
                12.119,
                12.123,
                12.127,
                12.131,
                12.135,
                12.14,
                12.144,
                12.148,
                12.152,
                12.157,
                12.161,
                12.165,
                12.169,
                12.173,
                12.178,
                12.182,
                12.186,
                12.19,
                12.194,
                12.199,
                12.203,
                12.207,
                12.211,
                12.215,
                12.22,
                12.224,
                12.228,
                12.232,
                12.236,
                12.241,
                12.245,
                12.249,
                12.253,
                12.257,
                12.262,
                12.266,
                12.27,
                12.274,
                12.279,
                12.283,
                12.287,
                12.291,
                12.295,
                12.299,
                12.304,
                12.308,
                12.312,
                12.316,
                12.32,
                12.325,
                12.329,
                12.333,
                12.337,
                12.341,
                12.346,
                12.35,
                12.354,
                12.358,
                12.362,
                12.366,
                12.371,
                12.375,
                12.379,
                12.383,
                12.387,
                12.392,
                12.396,
                12.4,
                12.404,
                12.408,
                12.413,
                12.417,
                12.421,
                12.425,
                12.429,
                12.433,
                12.438,
                12.442,
                12.446,
                12.45,
                12.454,
                12.458,
                12.463,
                12.467,
                12.471,
                12.475,
                12.48,
                12.484,
                12.488,
                12.492,
                12.496,
                12.5,
                12.505,
                12.509,
                12.513,
                12.517,
                12.521,
                12.525,
                12.529,
                12.534,
                12.538,
                12.542,
                12.546,
                12.55,
                12.555,
                12.559,
                12.563,
                12.567,
                12.571,
                12.575,
                12.58,
                12.584,
                12.588,
                12.592,
                12.596,
                12.6,
                12.604,
                12.609,
                12.613,
                12.617,
                12.621,
                12.625,
                12.63,
                12.634,
                12.638,
                12.642,
                12.646,
                12.65,
                12.655,
                12.659,
                12.663,
                12.667,
                12.671,
                12.675,
                12.679,
                12.684,
                12.688,
                12.692,
                12.696,
                12.7,
                12.704,
                12.709,
                12.713,
                12.717,
                12.721,
                12.725,
                12.729,
                12.733,
                12.737,
                12.742,
                12.746,
                12.75,
                12.754,
                12.758,
                12.762,
                12.766,
                12.771,
                12.775,
                12.779,
                12.783,
                12.787,
                12.791,
                12.796,
                12.8,
                12.804,
                12.808,
                12.812,
                12.816,
                12.82,
                12.824,
                12.828,
                12.833,
                12.837,
                12.841,
                12.845,
                12.849,
                12.853,
                12.858,
                12.862,
                12.866,
                12.87,
                12.874,
                12.878,
                12.882,
                12.886,
                12.89,
                12.894,
                12.899,
                12.903,
                12.907,
                12.911,
                12.915,
                12.919,
                12.923,
                12.927,
                12.932,
                12.936,
                12.94,
                12.944,
                12.948,
                12.952,
                12.956,
                12.96,
                12.964,
                12.968,
                12.972,
                12.977,
                12.981,
                12.985,
                12.989,
                12.993,
                12.997,
                13.001,
                13.005,
                13.01,
                13.014,
                13.018,
                13.022,
                13.026,
                13.03,
                13.034,
                13.038,
                13.042,
                13.046,
                13.05,
                13.054,
                13.059,
                13.063,
                13.067,
                13.071,
                13.075,
                13.079,
                13.083,
                13.087,
                13.091,
                13.095,
                13.099,
                13.103,
                13.108,
                13.112,
                13.116,
                13.12,
                13.124,
                13.128,
                13.132,
                13.136,
                13.14,
                13.144,
                13.148,
                13.153,
                13.157,
                13.161,
                13.165,
                13.169,
                13.173,
                13.177,
                13.181,
                13.185,
                13.189,
                13.193,
                13.197,
                13.201,
                13.205,
                13.209,
                13.213,
                13.218,
                13.222,
                13.226,
                13.23,
                13.234,
                13.238,
                13.242,
                13.246,
                13.25,
                13.254,
                13.258,
                13.262,
                13.266,
                13.27,
                13.274,
                13.278,
                13.282,
                13.286,
                13.291,
                13.295,
                13.299,
                13.303,
                13.307,
                13.311,
                13.315,
                13.319,
                13.323,
                13.327,
                13.331,
                13.335,
                13.339,
                13.343,
                13.347,
                13.351,
                13.355,
                13.359,
                13.363,
                13.367,
                13.371,
                13.375,
                13.38,
                13.384,
                13.388,
                13.392,
                13.396,
                13.4,
                13.404,
                13.408,
                13.412,
                13.416,
                13.42,
                13.424,
                13.428,
                13.432,
                13.436,
                13.44,
                13.444,
                13.448,
                13.452,
                13.456,
                13.46,
                13.464,
                13.468,
                13.472,
                13.476,
                13.48,
                13.484,
                13.488,
                13.492,
                13.496,
                13.5,
                13.504,
                13.508,
                13.512,
                13.516,
                13.52,
                13.524,
                13.528,
                13.532,
                13.536,
                13.54,
                13.544,
                13.548,
                13.552,
                13.557,
                13.561,
                13.565,
                13.569,
                13.573,
                13.577,
                13.581,
                13.585,
                13.589,
                13.593,
                13.597,
                13.601,
                13.605,
                13.609,
                13.613,
                13.617,
                13.621,
                13.625,
                13.629,
                13.633,
                13.637,
                13.641,
                13.645,
                13.649,
                13.653,
                13.657,
                13.661,
                13.665,
                13.669,
                13.673,
                13.677,
                13.681,
                13.685,
                13.689,
                13.693,
                13.697,
                13.701,
                13.705,
                13.709,
                13.713,
                13.717,
                13.721,
                13.725,
                13.729,
                13.733,
                13.737,
                13.741,
                13.745,
                13.749,
                13.753,
                13.757,
                13.761,
                13.765,
                13.769,
                13.773,
                13.777,
                13.781,
                13.785,
                13.789,
                13.793,
                13.797,
                13.801,
                13.805,
                13.809,
                13.812,
                13.817,
                13.821,
                13.825,
                13.829,
                13.833,
                13.837,
                13.841,
                13.845,
                13.849,
                13.853,
                13.857,
                13.86,
                13.864,
                13.868,
                13.872,
                13.876,
                13.88,
                13.884,
                13.888,
                13.892,
                13.896,
                13.9,
                13.904,
                13.908,
                13.912,
                13.916,
                13.92,
                13.924,
                13.928,
                13.932,
                13.936,
                13.94,
                13.944,
                13.948,
                13.952,
                13.956,
                13.96,
                13.964,
                13.968,
                13.972,
                13.976,
                13.98,
                13.984,
                13.988,
                13.992,
                13.996,
                14,
                14.004,
                14.008,
                14.012,
                14.016,
                14.02,
                14.024,
                14.028,
                14.031,
                14.035,
                14.039,
                14.043,
                14.047,
                14.051,
                14.055,
                14.059,
                14.063,
                14.067,
                14.071,
                14.075,
                14.079,
                14.083,
                14.087,
                14.091,
                14.095,
                14.099,
                14.103,
                14.107,
                14.111,
                14.115,
                14.118,
                14.122,
                14.126,
                14.13,
                14.134,
                14.138,
                14.142,
                14.146,
                14.15,
                14.154,
                14.158,
                14.162,
                14.166,
                14.17,
                14.174,
                14.178,
                14.182,
                14.186,
                14.19,
                14.194,
                14.198,
                14.202,
                14.206,
                14.209,
                14.213,
                14.217,
                14.221,
                14.225,
                14.229,
                14.233,
                14.237,
                14.241,
                14.245,
                14.249,
                14.253,
                14.257,
                14.261,
                14.265,
                14.269,
                14.273,
                14.276,
                14.28,
                14.284,
                14.288,
                14.292,
                14.296,
                14.3,
                14.304,
                14.308,
                14.312,
                14.316,
                14.32,
                14.324,
                14.327,
                14.331,
                14.335,
                14.339,
                14.343,
                14.347,
                14.351,
                14.355,
                14.359,
                14.363,
                14.367,
                14.371,
                14.375,
                14.379,
                14.383,
                14.387,
                14.39,
                14.394,
                14.398,
                14.402,
                14.406,
                14.41,
                14.414,
                14.418,
                14.422,
                14.426,
                14.429,
                14.433,
                14.437,
                14.441,
                14.445,
                14.449,
                14.453,
                14.457,
                14.461,
                14.465,
                14.469,
                14.473,
                14.477,
                14.48,
                14.484,
                14.488,
                14.492,
                14.496,
                14.5,
                14.504,
                14.508,
                14.512,
                14.516,
                14.52,
                14.524,
                14.528,
                14.531,
                14.535,
                14.539,
                14.543,
                14.547,
                14.551,
                14.555,
                14.559,
                14.563,
                14.566,
                14.57,
                14.574,
                14.578,
                14.582,
                14.586,
                14.59,
                14.594,
                14.598,
                14.602,
                14.605,
                14.609,
                14.613,
                14.617,
                14.621,
                14.625,
                14.629,
                14.633,
                14.637,
                14.641,
                14.644,
                14.648,
                14.652,
                14.656,
                14.66,
                14.664,
                14.668,
                14.672,
                14.676,
                14.679,
                14.683,
                14.687,
                14.691,
                14.695,
                14.699,
                14.703,
                14.707,
                14.71,
                14.714,
                14.718,
                14.722,
                14.726,
                14.73,
                14.734,
                14.737,
                14.741,
                14.745,
                14.749,
                14.753,
                14.757,
                14.761,
                14.765,
                14.768,
                14.772,
                14.776,
                14.78,
                14.784,
                14.788,
                14.792,
                14.796,
                14.8,
                14.803,
                14.807,
                14.811,
                14.815,
                14.819,
                14.823,
                14.826,
                14.83,
                14.834,
                14.838,
                14.842,
                14.846,
                14.85

            ],
            P10: [
                2.758,
                2.732,
                2.75,
                2.772,
                2.798,
                2.825,
                2.853,
                2.883,
                2.913,
                2.944,
                2.977,
                3.01,
                3.045,
                3.08,
                3.117,
                3.155,
                3.193,
                3.232,
                3.271,
                3.31,
                3.349,
                3.388,
                3.428,
                3.467,
                3.506,
                3.545,
                3.584,
                3.622,
                3.66,
                3.698,
                3.736,
                3.774,
                3.811,
                3.848,
                3.884,
                3.92,
                3.956,
                3.991,
                4.026,
                4.061,
                4.096,
                4.13,
                4.163,
                4.196,
                4.229,
                4.262,
                4.294,
                4.326,
                4.358,
                4.389,
                4.42,
                4.451,
                4.481,
                4.512,
                4.541,
                4.571,
                4.6,
                4.629,
                4.658,
                4.686,
                4.714,
                4.742,
                4.769,
                4.797,
                4.824,
                4.851,
                4.877,
                4.904,
                4.93,
                4.956,
                4.981,
                5.007,
                5.032,
                5.057,
                5.081,
                5.106,
                5.13,
                5.154,
                5.178,
                5.201,
                5.225,
                5.248,
                5.271,
                5.294,
                5.316,
                5.339,
                5.361,
                5.383,
                5.405,
                5.426,
                5.448,
                5.469,
                5.49,
                5.511,
                5.532,
                5.552,
                5.573,
                5.593,
                5.613,
                5.633,
                5.653,
                5.672,
                5.692,
                5.711,
                5.73,
                5.749,
                5.768,
                5.787,
                5.805,
                5.824,
                5.842,
                5.86,
                5.878,
                5.896,
                5.914,
                5.931,
                5.949,
                5.966,
                5.983,
                6.001,
                6.018,
                6.034,
                6.051,
                6.068,
                6.084,
                6.101,
                6.117,
                6.133,
                6.149,
                6.165,
                6.181,
                6.197,
                6.213,
                6.228,
                6.244,
                6.259,
                6.274,
                6.289,
                6.304,
                6.319,
                6.334,
                6.349,
                6.363,
                6.378,
                6.392,
                6.407,
                6.421,
                6.435,
                6.449,
                6.463,
                6.477,
                6.491,
                6.504,
                6.518,
                6.532,
                6.545,
                6.558,
                6.572,
                6.585,
                6.598,
                6.611,
                6.624,
                6.637,
                6.65,
                6.662,
                6.675,
                6.687,
                6.7,
                6.712,
                6.725,
                6.737,
                6.749,
                6.761,
                6.773,
                6.785,
                6.797,
                6.809,
                6.821,
                6.833,
                6.844,
                6.856,
                6.867,
                6.879,
                6.89,
                6.902,
                6.913,
                6.924,
                6.935,
                6.946,
                6.957,
                6.968,
                6.979,
                6.99,
                7.001,
                7.012,
                7.022,
                7.033,
                7.044,
                7.054,
                7.065,
                7.075,
                7.086,
                7.096,
                7.106,
                7.116,
                7.127,
                7.137,
                7.147,
                7.157,
                7.167,
                7.177,
                7.187,
                7.197,
                7.206,
                7.216,
                7.226,
                7.236,
                7.245,
                7.255,
                7.264,
                7.274,
                7.283,
                7.293,
                7.302,
                7.311,
                7.321,
                7.33,
                7.339,
                7.348,
                7.358,
                7.367,
                7.376,
                7.385,
                7.394,
                7.403,
                7.412,
                7.42,
                7.429,
                7.438,
                7.447,
                7.456,
                7.464,
                7.473,
                7.482,
                7.49,
                7.499,
                7.508,
                7.516,
                7.525,
                7.533,
                7.542,
                7.55,
                7.558,
                7.567,
                7.575,
                7.583,
                7.592,
                7.6,
                7.608,
                7.616,
                7.624,
                7.633,
                7.641,
                7.649,
                7.657,
                7.665,
                7.673,
                7.681,
                7.689,
                7.697,
                7.705,
                7.713,
                7.721,
                7.728,
                7.736,
                7.744,
                7.752,
                7.76,
                7.767,
                7.775,
                7.783,
                7.791,
                7.798,
                7.806,
                7.813,
                7.821,
                7.829,
                7.836,
                7.844,
                7.851,
                7.859,
                7.866,
                7.874,
                7.881,
                7.889,
                7.896,
                7.903,
                7.911,
                7.918,
                7.926,
                7.933,
                7.94,
                7.947,
                7.955,
                7.962,
                7.969,
                7.976,
                7.984,
                7.991,
                7.998,
                8.005,
                8.012,
                8.019,
                8.027,
                8.034,
                8.041,
                8.048,
                8.055,
                8.062,
                8.069,
                8.076,
                8.083,
                8.09,
                8.097,
                8.104,
                8.111,
                8.118,
                8.125,
                8.132,
                8.139,
                8.146,
                8.152,
                8.159,
                8.166,
                8.173,
                8.18,
                8.187,
                8.194,
                8.2,
                8.207,
                8.214,
                8.221,
                8.227,
                8.234,
                8.241,
                8.248,
                8.254,
                8.261,
                8.268,
                8.274,
                8.281,
                8.288,
                8.295,
                8.301,
                8.308,
                8.315,
                8.321,
                8.328,
                8.334,
                8.341,
                8.348,
                8.354,
                8.361,
                8.367,
                8.374,
                8.38,
                8.387,
                8.393,
                8.4,
                8.407,
                8.413,
                8.419,
                8.426,
                8.432,
                8.439,
                8.445,
                8.452,
                8.458,
                8.465,
                8.471,
                8.478,
                8.484,
                8.49,
                8.497,
                8.503,
                8.51,
                8.516,
                8.522,
                8.529,
                8.535,
                8.541,
                8.548,
                8.554,
                8.56,
                8.567,
                8.573,
                8.579,
                8.586,
                8.592,
                8.598,
                8.604,
                8.611,
                8.617,
                8.623,
                8.63,
                8.636,
                8.642,
                8.648,
                8.654,
                8.661,
                8.667,
                8.673,
                8.679,
                8.685,
                8.692,
                8.698,
                8.704,
                8.71,
                8.716,
                8.723,
                8.729,
                8.735,
                8.741,
                8.747,
                8.753,
                8.759,
                8.766,
                8.772,
                8.778,
                8.784,
                8.79,
                8.796,
                8.802,
                8.808,
                8.814,
                8.821,
                8.827,
                8.833,
                8.839,
                8.845,
                8.851,
                8.857,
                8.863,
                8.869,
                8.875,
                8.881,
                8.887,
                8.893,
                8.899,
                8.905,
                8.911,
                8.917,
                8.923,
                8.929,
                8.935,
                8.941,
                8.947,
                8.953,
                8.959,
                8.965,
                8.971,
                8.977,
                8.983,
                8.989,
                8.995,
                9.001,
                9.007,
                9.013,
                9.019,
                9.025,
                9.031,
                9.037,
                9.043,
                9.049,
                9.054,
                9.06,
                9.066,
                9.072,
                9.078,
                9.084,
                9.09,
                9.096,
                9.102,
                9.108,
                9.113,
                9.119,
                9.125,
                9.131,
                9.137,
                9.143,
                9.149,
                9.155,
                9.161,
                9.167,
                9.172,
                9.178,
                9.184,
                9.19,
                9.196,
                9.202,
                9.207,
                9.213,
                9.219,
                9.225,
                9.231,
                9.237,
                9.243,
                9.248,
                9.254,
                9.26,
                9.266,
                9.272,
                9.277,
                9.283,
                9.289,
                9.295,
                9.3,
                9.306,
                9.312,
                9.318,
                9.324,
                9.329,
                9.335,
                9.341,
                9.347,
                9.353,
                9.358,
                9.364,
                9.37,
                9.376,
                9.381,
                9.387,
                9.393,
                9.399,
                9.404,
                9.41,
                9.416,
                9.422,
                9.427,
                9.433,
                9.439,
                9.444,
                9.45,
                9.456,
                9.462,
                9.467,
                9.473,
                9.479,
                9.484,
                9.49,
                9.496,
                9.501,
                9.507,
                9.513,
                9.519,
                9.524,
                9.53,
                9.536,
                9.541,
                9.547,
                9.553,
                9.558,
                9.564,
                9.57,
                9.575,
                9.581,
                9.587,
                9.592,
                9.598,
                9.604,
                9.609,
                9.615,
                9.621,
                9.626,
                9.632,
                9.637,
                9.643,
                9.649,
                9.654,
                9.66,
                9.666,
                9.671,
                9.677,
                9.682,
                9.688,
                9.694,
                9.699,
                9.705,
                9.711,
                9.716,
                9.722,
                9.727,
                9.733,
                9.739,
                9.744,
                9.75,
                9.755,
                9.761,
                9.767,
                9.772,
                9.778,
                9.783,
                9.789,
                9.795,
                9.8,
                9.806,
                9.811,
                9.817,
                9.823,
                9.828,
                9.834,
                9.839,
                9.845,
                9.851,
                9.856,
                9.862,
                9.867,
                9.873,
                9.878,
                9.884,
                9.89,
                9.895,
                9.901,
                9.906,
                9.912,
                9.917,
                9.923,
                9.929,
                9.934,
                9.94,
                9.945,
                9.951,
                9.956,
                9.962,
                9.967,
                9.973,
                9.979,
                9.984,
                9.99,
                9.995,
                10.001,
                10.006,
                10.012,
                10.017,
                10.023,
                10.029,
                10.034,
                10.04,
                10.045,
                10.051,
                10.056,
                10.062,
                10.067,
                10.073,
                10.078,
                10.084,
                10.089,
                10.095,
                10.1,
                10.106,
                10.112,
                10.117,
                10.123,
                10.128,
                10.134,
                10.139,
                10.145,
                10.15,
                10.156,
                10.161,
                10.167,
                10.172,
                10.178,
                10.183,
                10.189,
                10.194,
                10.2,
                10.205,
                10.211,
                10.216,
                10.222,
                10.227,
                10.233,
                10.238,
                10.244,
                10.249,
                10.255,
                10.26,
                10.266,
                10.271,
                10.277,
                10.282,
                10.288,
                10.293,
                10.299,
                10.304,
                10.31,
                10.315,
                10.321,
                10.326,
                10.332,
                10.337,
                10.342,
                10.348,
                10.353,
                10.359,
                10.364,
                10.37,
                10.375,
                10.381,
                10.386,
                10.392,
                10.397,
                10.403,
                10.408,
                10.413,
                10.419,
                10.424,
                10.43,
                10.435,
                10.441,
                10.446,
                10.452,
                10.457,
                10.462,
                10.468,
                10.473,
                10.479,
                10.484,
                10.49,
                10.495,
                10.501,
                10.506,
                10.511,
                10.517,
                10.522,
                10.528,
                10.533,
                10.539,
                10.544,
                10.549,
                10.555,
                10.56,
                10.565,
                10.571,
                10.576,
                10.582,
                10.587,
                10.592,
                10.598,
                10.603,
                10.609,
                10.614,
                10.62,
                10.625,
                10.63,
                10.636,
                10.641,
                10.646,
                10.652,
                10.657,
                10.662,
                10.668,
                10.673,
                10.678,
                10.684,
                10.689,
                10.694,
                10.7,
                10.705,
                10.711,
                10.716,
                10.721,
                10.727,
                10.732,
                10.737,
                10.743,
                10.748,
                10.753,
                10.759,
                10.764,
                10.769,
                10.774,
                10.78,
                10.785,
                10.79,
                10.796,
                10.801,
                10.806,
                10.811,
                10.817,
                10.822,
                10.827,
                10.833,
                10.838,
                10.843,
                10.848,
                10.854,
                10.859,
                10.864,
                10.869,
                10.875,
                10.88,
                10.885,
                10.89,
                10.896,
                10.901,
                10.906,
                10.911,
                10.917,
                10.922,
                10.927,
                10.932,
                10.937,
                10.943,
                10.948,
                10.953,
                10.958,
                10.963,
                10.969,
                10.974,
                10.979,
                10.984,
                10.989,
                10.995,
                11,
                11.005,
                11.01,
                11.015,
                11.02,
                11.025,
                11.031,
                11.036,
                11.041,
                11.046,
                11.051,
                11.056,
                11.061,
                11.067,
                11.072,
                11.077,
                11.082,
                11.087,
                11.092,
                11.097,
                11.102,
                11.107,
                11.113,
                11.118,
                11.123,
                11.128,
                11.133,
                11.138,
                11.143,
                11.148,
                11.153,
                11.158,
                11.163,
                11.168,
                11.173,
                11.178,
                11.183,
                11.189,
                11.194,
                11.199,
                11.204,
                11.209,
                11.214,
                11.219,
                11.224,
                11.229,
                11.234,
                11.239,
                11.244,
                11.249,
                11.254,
                11.259,
                11.264,
                11.269,
                11.274,
                11.279,
                11.284,
                11.289,
                11.294,
                11.299,
                11.304,
                11.309,
                11.314,
                11.318,
                11.323,
                11.328,
                11.333,
                11.338,
                11.343,
                11.348,
                11.353,
                11.358,
                11.363,
                11.368,
                11.373,
                11.378,
                11.383,
                11.387,
                11.392,
                11.397,
                11.402,
                11.407,
                11.412,
                11.417,
                11.422,
                11.427,
                11.431,
                11.436,
                11.441,
                11.446,
                11.451,
                11.456,
                11.461,
                11.466,
                11.47,
                11.475,
                11.48,
                11.485,
                11.49,
                11.495,
                11.499,
                11.504,
                11.509,
                11.514,
                11.519,
                11.524,
                11.528,
                11.533,
                11.538,
                11.543,
                11.548,
                11.552,
                11.557,
                11.562,
                11.567,
                11.572,
                11.576,
                11.581,
                11.586,
                11.591,
                11.595,
                11.6,
                11.605,
                11.61,
                11.614,
                11.619,
                11.624,
                11.629,
                11.633,
                11.638,
                11.643,
                11.648,
                11.653,
                11.657,
                11.662,
                11.667,
                11.671,
                11.676,
                11.681,
                11.686,
                11.69,
                11.695,
                11.7,
                11.704,
                11.709,
                11.714,
                11.719,
                11.723,
                11.728,
                11.733,
                11.737,
                11.742,
                11.747,
                11.751,
                11.756,
                11.761,
                11.765,
                11.77,
                11.775,
                11.779,
                11.784,
                11.789,
                11.794,
                11.798,
                11.803,
                11.807,
                11.812,
                11.817,
                11.821,
                11.826,
                11.831,
                11.835,
                11.84,
                11.845,
                11.849,
                11.854,
                11.859,
                11.863,
                11.868,
                11.872,
                11.877,
                11.882,
                11.886,
                11.891,
                11.896,
                11.9,
                11.905,
                11.909,
                11.914,
                11.919,
                11.923,
                11.928,
                11.932,
                11.937,
                11.942,
                11.946,
                11.951,
                11.955,
                11.96,
                11.965,
                11.969,
                11.974,
                11.978,
                11.983,
                11.987,
                11.992,
                11.997,
                12.001,
                12.006,
                12.01,
                12.015,
                12.019,
                12.024,
                12.029,
                12.033,
                12.038,
                12.042,
                12.047,
                12.051,
                12.056,
                12.061,
                12.065,
                12.07,
                12.074,
                12.079,
                12.083,
                12.088,
                12.092,
                12.097,
                12.102,
                12.106,
                12.111,
                12.115,
                12.12,
                12.124,
                12.129,
                12.133,
                12.138,
                12.142,
                12.147,
                12.151,
                12.156,
                12.161,
                12.165,
                12.17,
                12.174,
                12.179,
                12.183,
                12.188,
                12.192,
                12.197,
                12.201,
                12.206,
                12.21,
                12.215,
                12.219,
                12.224,
                12.228,
                12.233,
                12.237,
                12.242,
                12.246,
                12.251,
                12.255,
                12.26,
                12.264,
                12.269,
                12.273,
                12.278,
                12.282,
                12.287,
                12.291,
                12.296,
                12.3,
                12.305,
                12.309,
                12.314,
                12.318,
                12.323,
                12.327,
                12.332,
                12.336,
                12.341,
                12.345,
                12.35,
                12.354,
                12.359,
                12.363,
                12.368,
                12.372,
                12.377,
                12.381,
                12.386,
                12.39,
                12.395,
                12.399,
                12.404,
                12.408,
                12.413,
                12.417,
                12.422,
                12.426,
                12.431,
                12.435,
                12.44,
                12.444,
                12.448,
                12.453,
                12.457,
                12.462,
                12.466,
                12.471,
                12.475,
                12.48,
                12.484,
                12.489,
                12.493,
                12.498,
                12.502,
                12.506,
                12.511,
                12.515,
                12.52,
                12.524,
                12.529,
                12.533,
                12.538,
                12.542,
                12.547,
                12.551,
                12.556,
                12.56,
                12.565,
                12.569,
                12.574,
                12.578,
                12.582,
                12.587,
                12.591,
                12.596,
                12.6,
                12.605,
                12.609,
                12.614,
                12.618,
                12.623,
                12.627,
                12.632,
                12.636,
                12.64,
                12.645,
                12.649,
                12.654,
                12.658,
                12.663,
                12.667,
                12.672,
                12.676,
                12.68,
                12.685,
                12.689,
                12.694,
                12.698,
                12.703,
                12.707,
                12.712,
                12.716,
                12.721,
                12.725,
                12.73,
                12.734,
                12.738,
                12.743,
                12.747,
                12.752,
                12.756,
                12.761,
                12.765,
                12.77,
                12.774,
                12.779,
                12.783,
                12.787,
                12.792,
                12.796,
                12.801,
                12.805,
                12.81,
                12.814,
                12.819,
                12.823,
                12.827,
                12.832,
                12.836,
                12.841,
                12.845,
                12.85,
                12.854,
                12.859,
                12.863,
                12.867,
                12.872,
                12.876,
                12.881,
                12.885,
                12.89,
                12.894,
                12.899,
                12.903,
                12.908,
                12.912,
                12.916,
                12.921,
                12.925,
                12.93,
                12.934,
                12.939,
                12.943,
                12.948,
                12.952,
                12.956,
                12.961,
                12.965,
                12.97,
                12.974,
                12.979,
                12.983,
                12.987,
                12.992,
                12.996,
                13.001,
                13.005,
                13.01,
                13.014,
                13.019,
                13.023,
                13.027,
                13.032,
                13.036,
                13.041,
                13.045,
                13.05,
                13.054,
                13.058,
                13.063,
                13.067,
                13.072,
                13.076,
                13.08,
                13.085,
                13.089,
                13.094,
                13.098,
                13.103,
                13.107,
                13.112,
                13.116,
                13.12,
                13.125,
                13.129,
                13.134,
                13.138,
                13.143,
                13.147,
                13.151,
                13.156,
                13.16,
                13.165,
                13.169,
                13.173,
                13.178,
                13.182,
                13.187,
                13.191,
                13.196,
                13.2,
                13.204,
                13.209,
                13.213,
                13.218,
                13.222,
                13.226,
                13.231,
                13.235,
                13.24,
                13.244,
                13.249,
                13.253,
                13.257,
                13.262,
                13.266,
                13.271,
                13.275,
                13.279,
                13.284,
                13.288,
                13.293,
                13.297,
                13.302,
                13.306,
                13.31,
                13.315,
                13.319,
                13.324,
                13.328,
                13.332,
                13.337,
                13.341,
                13.346,
                13.35,
                13.354,
                13.359,
                13.363,
                13.368,
                13.372,
                13.376,
                13.381,
                13.385,
                13.389,
                13.394,
                13.398,
                13.403,
                13.407,
                13.411,
                13.416,
                13.42,
                13.425,
                13.429,
                13.433,
                13.438,
                13.442,
                13.447,
                13.451,
                13.455,
                13.46,
                13.464,
                13.469,
                13.473,
                13.477,
                13.482,
                13.486,
                13.49,
                13.495,
                13.499,
                13.503,
                13.508,
                13.512,
                13.517,
                13.521,
                13.525,
                13.53,
                13.534,
                13.538,
                13.543,
                13.547,
                13.552,
                13.556,
                13.56,
                13.565,
                13.569,
                13.573,
                13.578,
                13.582,
                13.587,
                13.591,
                13.595,
                13.6,
                13.604,
                13.608,
                13.613,
                13.617,
                13.621,
                13.626,
                13.63,
                13.634,
                13.639,
                13.643,
                13.648,
                13.652,
                13.656,
                13.661,
                13.665,
                13.669,
                13.674,
                13.678,
                13.682,
                13.687,
                13.691,
                13.695,
                13.7,
                13.704,
                13.708,
                13.713,
                13.717,
                13.722,
                13.726,
                13.73,
                13.735,
                13.739,
                13.743,
                13.748,
                13.752,
                13.756,
                13.761,
                13.765,
                13.769,
                13.774,
                13.778,
                13.782,
                13.787,
                13.791,
                13.795,
                13.8,
                13.804,
                13.808,
                13.813,
                13.817,
                13.821,
                13.826,
                13.83,
                13.834,
                13.839,
                13.843,
                13.847,
                13.852,
                13.856,
                13.86,
                13.865,
                13.869,
                13.873,
                13.878,
                13.882,
                13.886,
                13.891,
                13.895,
                13.899,
                13.904,
                13.908,
                13.912,
                13.916,
                13.921,
                13.925,
                13.929,
                13.934,
                13.938,
                13.942,
                13.947,
                13.951,
                13.955,
                13.96,
                13.964,
                13.968,
                13.973,
                13.977,
                13.981,
                13.985,
                13.99,
                13.994,
                13.998,
                14.003,
                14.007,
                14.011,
                14.016,
                14.02,
                14.024,
                14.029,
                14.033,
                14.037,
                14.042,
                14.046,
                14.05,
                14.054,
                14.059,
                14.063,
                14.067,
                14.072,
                14.076,
                14.08,
                14.085,
                14.089,
                14.093,
                14.097,
                14.102,
                14.106,
                14.11,
                14.115,
                14.119,
                14.123,
                14.127,
                14.132,
                14.136,
                14.14,
                14.145,
                14.149,
                14.153,
                14.158,
                14.162,
                14.166,
                14.17,
                14.175,
                14.179,
                14.183,
                14.188,
                14.192,
                14.196,
                14.2,
                14.205,
                14.209,
                14.213,
                14.218,
                14.222,
                14.226,
                14.231,
                14.235,
                14.239,
                14.243,
                14.248,
                14.252,
                14.256,
                14.261,
                14.265,
                14.269,
                14.273,
                14.278,
                14.282,
                14.286,
                14.29,
                14.295,
                14.299,
                14.303,
                14.308,
                14.312,
                14.316,
                14.32,
                14.325,
                14.329,
                14.333,
                14.338,
                14.342,
                14.346,
                14.35,
                14.355,
                14.359,
                14.363,
                14.367,
                14.372,
                14.376,
                14.38,
                14.385,
                14.389,
                14.393,
                14.397,
                14.402,
                14.406,
                14.41,
                14.414,
                14.419,
                14.423,
                14.427,
                14.432,
                14.436,
                14.44,
                14.444,
                14.449,
                14.453,
                14.457,
                14.461,
                14.466,
                14.47,
                14.474,
                14.478,
                14.483,
                14.487,
                14.491,
                14.496,
                14.5,
                14.504,
                14.508,
                14.513,
                14.517,
                14.521,
                14.525,
                14.53,
                14.534,
                14.538,
                14.542,
                14.547,
                14.551,
                14.555,
                14.56,
                14.564,
                14.568,
                14.572,
                14.577,
                14.581,
                14.585,
                14.589,
                14.594,
                14.598,
                14.602,
                14.606,
                14.611,
                14.615,
                14.619,
                14.623,
                14.628,
                14.632,
                14.636,
                14.64,
                14.645,
                14.649,
                14.653,
                14.657,
                14.662,
                14.666,
                14.67,
                14.674,
                14.679,
                14.683,
                14.687,
                14.691,
                14.696,
                14.7,
                14.704,
                14.708,
                14.713,
                14.717,
                14.721,
                14.725,
                14.73,
                14.734,
                14.738,
                14.742,
                14.747,
                14.751,
                14.755,
                14.759,
                14.764,
                14.768,
                14.772,
                14.776,
                14.781,
                14.785,
                14.789,
                14.793,
                14.798,
                14.802,
                14.806,
                14.81,
                14.815,
                14.819,
                14.823,
                14.827,
                14.831,
                14.836,
                14.84,
                14.844,
                14.848,
                14.853,
                14.857,
                14.861,
                14.865,
                14.869,
                14.874,
                14.878,
                14.882,
                14.887,
                14.891,
                14.895,
                14.899,
                14.903,
                14.908,
                14.912,
                14.916,
                14.92,
                14.925,
                14.929,
                14.933,
                14.937,
                14.941,
                14.946,
                14.95,
                14.954,
                14.958,
                14.963,
                14.967,
                14.971,
                14.975,
                14.979,
                14.984,
                14.988,
                14.992,
                14.996,
                15.001,
                15.005,
                15.009,
                15.013,
                15.017,
                15.022,
                15.026,
                15.03,
                15.034,
                15.039,
                15.043,
                15.047,
                15.051,
                15.055,
                15.059,
                15.064,
                15.068,
                15.072,
                15.076,
                15.081,
                15.085,
                15.089,
                15.093,
                15.097,
                15.102,
                15.106,
                15.11,
                15.114,
                15.118,
                15.123,
                15.127,
                15.131,
                15.135,
                15.139,
                15.144,
                15.148,
                15.152,
                15.156,
                15.16,
                15.165,
                15.169,
                15.173,
                15.177,
                15.181,
                15.186,
                15.19,
                15.194,
                15.198,
                15.202,
                15.207,
                15.211,
                15.215,
                15.219,
                15.223,
                15.228,
                15.232,
                15.236,
                15.24,
                15.244,
                15.249,
                15.253,
                15.257,
                15.261,
                15.265,
                15.27,
                15.274,
                15.278,
                15.282,
                15.286,
                15.29,
                15.295,
                15.299,
                15.303,
                15.307,
                15.311,
                15.316,
                15.32,
                15.324,
                15.328,
                15.332,
                15.336,
                15.341,
                15.345,
                15.349,
                15.353,
                15.357,
                15.361,
                15.366,
                15.37,
                15.374,
                15.378,
                15.382,
                15.387,
                15.391,
                15.395,
                15.399,
                15.403,
                15.407,
                15.412,
                15.416,
                15.42,
                15.424,
                15.428,
                15.433,
                15.437,
                15.441,
                15.445,
                15.449,
                15.453,
                15.457,
                15.462,
                15.466,
                15.47,
                15.474,
                15.478,
                15.483,
                15.487,
                15.491,
                15.495,
                15.499,
                15.503,
                15.507,
                15.512,
                15.516,
                15.52,
                15.524,
                15.528,
                15.532,
                15.536,
                15.541,
                15.545,
                15.549,
                15.553,
                15.557,
                15.561,
                15.566,
                15.57,
                15.574,
                15.578

            ],
            P15: [
                2.865,
                2.838,
                2.856,
                2.879,
                2.905,
                2.933,
                2.962,
                2.992,
                3.023,
                3.055,
                3.088,
                3.123,
                3.158,
                3.194,
                3.232,
                3.271,
                3.31,
                3.349,
                3.389,
                3.43,
                3.47,
                3.51,
                3.55,
                3.59,
                3.63,
                3.67,
                3.71,
                3.749,
                3.788,
                3.827,
                3.866,
                3.904,
                3.942,
                3.98,
                4.017,
                4.054,
                4.09,
                4.127,
                4.163,
                4.198,
                4.233,
                4.268,
                4.302,
                4.336,
                4.37,
                4.403,
                4.436,
                4.469,
                4.501,
                4.533,
                4.565,
                4.596,
                4.627,
                4.658,
                4.688,
                4.718,
                4.748,
                4.777,
                4.806,
                4.835,
                4.864,
                4.892,
                4.92,
                4.948,
                4.976,
                5.003,
                5.03,
                5.057,
                5.084,
                5.11,
                5.136,
                5.162,
                5.187,
                5.213,
                5.238,
                5.263,
                5.288,
                5.312,
                5.336,
                5.36,
                5.384,
                5.408,
                5.431,
                5.454,
                5.477,
                5.5,
                5.522,
                5.545,
                5.567,
                5.589,
                5.611,
                5.632,
                5.654,
                5.675,
                5.696,
                5.717,
                5.738,
                5.759,
                5.779,
                5.799,
                5.819,
                5.839,
                5.859,
                5.879,
                5.898,
                5.917,
                5.937,
                5.956,
                5.975,
                5.993,
                6.012,
                6.03,
                6.049,
                6.067,
                6.085,
                6.103,
                6.121,
                6.138,
                6.156,
                6.173,
                6.191,
                6.208,
                6.225,
                6.242,
                6.259,
                6.276,
                6.292,
                6.308,
                6.325,
                6.341,
                6.357,
                6.373,
                6.389,
                6.405,
                6.421,
                6.436,
                6.452,
                6.467,
                6.483,
                6.498,
                6.513,
                6.528,
                6.543,
                6.558,
                6.573,
                6.587,
                6.602,
                6.616,
                6.631,
                6.645,
                6.659,
                6.673,
                6.687,
                6.701,
                6.715,
                6.728,
                6.742,
                6.755,
                6.769,
                6.782,
                6.796,
                6.809,
                6.822,
                6.835,
                6.848,
                6.861,
                6.874,
                6.886,
                6.899,
                6.912,
                6.924,
                6.937,
                6.949,
                6.961,
                6.974,
                6.986,
                6.998,
                7.01,
                7.022,
                7.034,
                7.046,
                7.057,
                7.069,
                7.081,
                7.092,
                7.104,
                7.115,
                7.127,
                7.138,
                7.15,
                7.161,
                7.172,
                7.183,
                7.194,
                7.205,
                7.216,
                7.227,
                7.238,
                7.249,
                7.259,
                7.27,
                7.281,
                7.291,
                7.302,
                7.312,
                7.323,
                7.333,
                7.343,
                7.354,
                7.364,
                7.374,
                7.384,
                7.394,
                7.404,
                7.415,
                7.424,
                7.434,
                7.444,
                7.454,
                7.464,
                7.474,
                7.483,
                7.493,
                7.502,
                7.512,
                7.522,
                7.531,
                7.541,
                7.55,
                7.559,
                7.569,
                7.578,
                7.587,
                7.597,
                7.606,
                7.615,
                7.624,
                7.633,
                7.642,
                7.651,
                7.66,
                7.669,
                7.678,
                7.687,
                7.696,
                7.704,
                7.713,
                7.722,
                7.731,
                7.739,
                7.748,
                7.757,
                7.765,
                7.774,
                7.782,
                7.791,
                7.799,
                7.808,
                7.816,
                7.825,
                7.833,
                7.841,
                7.85,
                7.858,
                7.866,
                7.875,
                7.883,
                7.891,
                7.899,
                7.908,
                7.916,
                7.924,
                7.932,
                7.94,
                7.948,
                7.956,
                7.964,
                7.972,
                7.98,
                7.988,
                7.996,
                8.004,
                8.011,
                8.019,
                8.027,
                8.035,
                8.043,
                8.051,
                8.058,
                8.066,
                8.074,
                8.081,
                8.089,
                8.097,
                8.104,
                8.112,
                8.12,
                8.127,
                8.135,
                8.142,
                8.15,
                8.157,
                8.165,
                8.172,
                8.18,
                8.187,
                8.195,
                8.202,
                8.209,
                8.217,
                8.224,
                8.231,
                8.239,
                8.246,
                8.253,
                8.261,
                8.268,
                8.275,
                8.282,
                8.29,
                8.297,
                8.304,
                8.311,
                8.318,
                8.326,
                8.333,
                8.34,
                8.347,
                8.354,
                8.361,
                8.368,
                8.375,
                8.382,
                8.389,
                8.397,
                8.404,
                8.411,
                8.418,
                8.425,
                8.432,
                8.439,
                8.446,
                8.452,
                8.459,
                8.466,
                8.473,
                8.48,
                8.487,
                8.494,
                8.501,
                8.508,
                8.515,
                8.521,
                8.528,
                8.535,
                8.542,
                8.549,
                8.556,
                8.562,
                8.569,
                8.576,
                8.583,
                8.589,
                8.596,
                8.603,
                8.61,
                8.616,
                8.623,
                8.63,
                8.637,
                8.643,
                8.65,
                8.657,
                8.663,
                8.67,
                8.676,
                8.683,
                8.69,
                8.696,
                8.703,
                8.71,
                8.716,
                8.723,
                8.729,
                8.736,
                8.743,
                8.749,
                8.756,
                8.762,
                8.769,
                8.775,
                8.782,
                8.788,
                8.795,
                8.801,
                8.808,
                8.814,
                8.821,
                8.827,
                8.834,
                8.84,
                8.847,
                8.853,
                8.86,
                8.866,
                8.872,
                8.879,
                8.885,
                8.892,
                8.898,
                8.905,
                8.911,
                8.917,
                8.924,
                8.93,
                8.936,
                8.943,
                8.949,
                8.955,
                8.962,
                8.968,
                8.975,
                8.981,
                8.987,
                8.994,
                9,
                9.006,
                9.012,
                9.019,
                9.025,
                9.031,
                9.038,
                9.044,
                9.05,
                9.056,
                9.063,
                9.069,
                9.075,
                9.081,
                9.088,
                9.094,
                9.1,
                9.106,
                9.113,
                9.119,
                9.125,
                9.131,
                9.137,
                9.144,
                9.15,
                9.156,
                9.162,
                9.169,
                9.175,
                9.181,
                9.187,
                9.193,
                9.199,
                9.206,
                9.212,
                9.218,
                9.224,
                9.23,
                9.236,
                9.242,
                9.249,
                9.255,
                9.261,
                9.267,
                9.273,
                9.279,
                9.285,
                9.291,
                9.298,
                9.304,
                9.31,
                9.316,
                9.322,
                9.328,
                9.334,
                9.34,
                9.346,
                9.353,
                9.359,
                9.365,
                9.371,
                9.377,
                9.383,
                9.389,
                9.395,
                9.401,
                9.407,
                9.413,
                9.419,
                9.425,
                9.431,
                9.437,
                9.443,
                9.449,
                9.455,
                9.461,
                9.467,
                9.474,
                9.48,
                9.485,
                9.492,
                9.498,
                9.504,
                9.51,
                9.516,
                9.522,
                9.528,
                9.534,
                9.539,
                9.545,
                9.552,
                9.557,
                9.563,
                9.569,
                9.575,
                9.581,
                9.587,
                9.593,
                9.599,
                9.605,
                9.611,
                9.617,
                9.623,
                9.629,
                9.635,
                9.641,
                9.647,
                9.653,
                9.659,
                9.665,
                9.67,
                9.676,
                9.682,
                9.688,
                9.694,
                9.7,
                9.706,
                9.712,
                9.718,
                9.724,
                9.73,
                9.735,
                9.741,
                9.747,
                9.753,
                9.759,
                9.765,
                9.771,
                9.776,
                9.782,
                9.788,
                9.794,
                9.8,
                9.806,
                9.812,
                9.818,
                9.824,
                9.829,
                9.835,
                9.841,
                9.847,
                9.853,
                9.859,
                9.864,
                9.87,
                9.876,
                9.882,
                9.888,
                9.894,
                9.899,
                9.905,
                9.911,
                9.917,
                9.923,
                9.928,
                9.934,
                9.94,
                9.946,
                9.952,
                9.958,
                9.963,
                9.969,
                9.975,
                9.981,
                9.987,
                9.992,
                9.998,
                10.004,
                10.01,
                10.016,
                10.021,
                10.027,
                10.033,
                10.039,
                10.045,
                10.05,
                10.056,
                10.062,
                10.068,
                10.073,
                10.079,
                10.085,
                10.091,
                10.097,
                10.102,
                10.108,
                10.114,
                10.12,
                10.125,
                10.131,
                10.137,
                10.143,
                10.149,
                10.154,
                10.16,
                10.166,
                10.172,
                10.177,
                10.183,
                10.189,
                10.195,
                10.2,
                10.206,
                10.212,
                10.218,
                10.223,
                10.229,
                10.235,
                10.241,
                10.246,
                10.252,
                10.258,
                10.264,
                10.269,
                10.275,
                10.281,
                10.286,
                10.292,
                10.298,
                10.304,
                10.31,
                10.315,
                10.321,
                10.327,
                10.332,
                10.338,
                10.344,
                10.35,
                10.355,
                10.361,
                10.367,
                10.373,
                10.378,
                10.384,
                10.39,
                10.395,
                10.401,
                10.407,
                10.413,
                10.418,
                10.424,
                10.43,
                10.435,
                10.441,
                10.447,
                10.452,
                10.458,
                10.464,
                10.47,
                10.475,
                10.481,
                10.487,
                10.492,
                10.498,
                10.504,
                10.51,
                10.515,
                10.521,
                10.527,
                10.532,
                10.538,
                10.544,
                10.549,
                10.555,
                10.561,
                10.566,
                10.572,
                10.578,
                10.583,
                10.589,
                10.595,
                10.6,
                10.606,
                10.612,
                10.618,
                10.623,
                10.629,
                10.635,
                10.64,
                10.646,
                10.652,
                10.657,
                10.663,
                10.668,
                10.674,
                10.68,
                10.685,
                10.691,
                10.697,
                10.702,
                10.708,
                10.714,
                10.719,
                10.725,
                10.731,
                10.736,
                10.742,
                10.748,
                10.753,
                10.759,
                10.764,
                10.77,
                10.776,
                10.781,
                10.787,
                10.793,
                10.798,
                10.804,
                10.81,
                10.815,
                10.821,
                10.826,
                10.832,
                10.838,
                10.843,
                10.849,
                10.854,
                10.86,
                10.866,
                10.871,
                10.877,
                10.882,
                10.888,
                10.894,
                10.899,
                10.905,
                10.91,
                10.916,
                10.922,
                10.927,
                10.933,
                10.938,
                10.944,
                10.949,
                10.955,
                10.96,
                10.966,
                10.972,
                10.977,
                10.983,
                10.988,
                10.994,
                10.999,
                11.005,
                11.011,
                11.016,
                11.022,
                11.027,
                11.033,
                11.038,
                11.044,
                11.049,
                11.055,
                11.06,
                11.066,
                11.071,
                11.077,
                11.082,
                11.088,
                11.093,
                11.099,
                11.104,
                11.11,
                11.115,
                11.121,
                11.126,
                11.132,
                11.137,
                11.143,
                11.148,
                11.154,
                11.159,
                11.164,
                11.17,
                11.175,
                11.181,
                11.186,
                11.192,
                11.197,
                11.203,
                11.208,
                11.213,
                11.219,
                11.224,
                11.23,
                11.235,
                11.241,
                11.246,
                11.251,
                11.257,
                11.262,
                11.267,
                11.273,
                11.278,
                11.284,
                11.289,
                11.294,
                11.3,
                11.305,
                11.311,
                11.316,
                11.321,
                11.327,
                11.332,
                11.337,
                11.343,
                11.348,
                11.353,
                11.359,
                11.364,
                11.369,
                11.375,
                11.38,
                11.385,
                11.391,
                11.396,
                11.401,
                11.407,
                11.412,
                11.417,
                11.423,
                11.428,
                11.433,
                11.438,
                11.444,
                11.449,
                11.454,
                11.46,
                11.465,
                11.47,
                11.475,
                11.481,
                11.486,
                11.491,
                11.496,
                11.502,
                11.507,
                11.512,
                11.517,
                11.523,
                11.528,
                11.533,
                11.538,
                11.543,
                11.549,
                11.554,
                11.559,
                11.564,
                11.569,
                11.575,
                11.58,
                11.585,
                11.59,
                11.595,
                11.601,
                11.606,
                11.611,
                11.616,
                11.621,
                11.626,
                11.632,
                11.637,
                11.642,
                11.647,
                11.652,
                11.657,
                11.662,
                11.668,
                11.673,
                11.678,
                11.683,
                11.688,
                11.693,
                11.698,
                11.703,
                11.708,
                11.713,
                11.719,
                11.724,
                11.729,
                11.734,
                11.739,
                11.744,
                11.749,
                11.754,
                11.759,
                11.764,
                11.769,
                11.774,
                11.779,
                11.784,
                11.79,
                11.795,
                11.8,
                11.805,
                11.81,
                11.815,
                11.82,
                11.825,
                11.83,
                11.835,
                11.84,
                11.845,
                11.85,
                11.855,
                11.86,
                11.865,
                11.87,
                11.875,
                11.88,
                11.885,
                11.89,
                11.895,
                11.9,
                11.905,
                11.91,
                11.915,
                11.92,
                11.925,
                11.93,
                11.935,
                11.94,
                11.945,
                11.95,
                11.954,
                11.959,
                11.964,
                11.969,
                11.974,
                11.979,
                11.984,
                11.989,
                11.994,
                11.999,
                12.004,
                12.009,
                12.014,
                12.019,
                12.023,
                12.028,
                12.033,
                12.038,
                12.043,
                12.048,
                12.053,
                12.058,
                12.063,
                12.068,
                12.072,
                12.077,
                12.082,
                12.087,
                12.092,
                12.097,
                12.102,
                12.107,
                12.111,
                12.116,
                12.121,
                12.126,
                12.131,
                12.136,
                12.141,
                12.145,
                12.15,
                12.155,
                12.16,
                12.165,
                12.169,
                12.174,
                12.179,
                12.184,
                12.189,
                12.194,
                12.198,
                12.203,
                12.208,
                12.213,
                12.218,
                12.223,
                12.227,
                12.232,
                12.237,
                12.242,
                12.247,
                12.251,
                12.256,
                12.261,
                12.266,
                12.271,
                12.275,
                12.28,
                12.285,
                12.29,
                12.295,
                12.299,
                12.304,
                12.309,
                12.314,
                12.318,
                12.323,
                12.328,
                12.333,
                12.337,
                12.342,
                12.347,
                12.352,
                12.356,
                12.361,
                12.366,
                12.371,
                12.376,
                12.38,
                12.385,
                12.39,
                12.395,
                12.399,
                12.404,
                12.409,
                12.413,
                12.418,
                12.423,
                12.428,
                12.432,
                12.437,
                12.442,
                12.447,
                12.451,
                12.456,
                12.461,
                12.466,
                12.47,
                12.475,
                12.48,
                12.485,
                12.489,
                12.494,
                12.499,
                12.503,
                12.508,
                12.513,
                12.517,
                12.522,
                12.527,
                12.532,
                12.536,
                12.541,
                12.546,
                12.55,
                12.555,
                12.56,
                12.565,
                12.569,
                12.574,
                12.579,
                12.583,
                12.588,
                12.593,
                12.597,
                12.602,
                12.607,
                12.611,
                12.616,
                12.621,
                12.626,
                12.63,
                12.635,
                12.64,
                12.644,
                12.649,
                12.654,
                12.658,
                12.663,
                12.668,
                12.672,
                12.677,
                12.682,
                12.686,
                12.691,
                12.696,
                12.7,
                12.705,
                12.71,
                12.714,
                12.719,
                12.724,
                12.728,
                12.733,
                12.738,
                12.742,
                12.747,
                12.752,
                12.756,
                12.761,
                12.766,
                12.77,
                12.775,
                12.78,
                12.784,
                12.789,
                12.794,
                12.798,
                12.803,
                12.808,
                12.812,
                12.817,
                12.822,
                12.826,
                12.831,
                12.836,
                12.84,
                12.845,
                12.85,
                12.854,
                12.859,
                12.864,
                12.868,
                12.873,
                12.877,
                12.882,
                12.887,
                12.891,
                12.896,
                12.901,
                12.905,
                12.91,
                12.915,
                12.919,
                12.924,
                12.929,
                12.933,
                12.938,
                12.942,
                12.947,
                12.952,
                12.956,
                12.961,
                12.966,
                12.97,
                12.975,
                12.98,
                12.984,
                12.989,
                12.994,
                12.998,
                13.003,
                13.008,
                13.012,
                13.017,
                13.021,
                13.026,
                13.031,
                13.035,
                13.04,
                13.045,
                13.049,
                13.054,
                13.058,
                13.063,
                13.068,
                13.072,
                13.077,
                13.082,
                13.086,
                13.091,
                13.096,
                13.1,
                13.105,
                13.109,
                13.114,
                13.119,
                13.123,
                13.128,
                13.133,
                13.137,
                13.142,
                13.146,
                13.151,
                13.156,
                13.16,
                13.165,
                13.17,
                13.174,
                13.179,
                13.184,
                13.188,
                13.193,
                13.197,
                13.202,
                13.207,
                13.211,
                13.216,
                13.221,
                13.225,
                13.23,
                13.235,
                13.239,
                13.244,
                13.248,
                13.253,
                13.258,
                13.262,
                13.267,
                13.272,
                13.276,
                13.281,
                13.285,
                13.29,
                13.295,
                13.299,
                13.304,
                13.309,
                13.313,
                13.318,
                13.322,
                13.327,
                13.332,
                13.336,
                13.341,
                13.345,
                13.35,
                13.355,
                13.359,
                13.364,
                13.369,
                13.373,
                13.378,
                13.382,
                13.387,
                13.392,
                13.396,
                13.401,
                13.406,
                13.41,
                13.415,
                13.419,
                13.424,
                13.429,
                13.433,
                13.438,
                13.443,
                13.447,
                13.452,
                13.456,
                13.461,
                13.466,
                13.47,
                13.475,
                13.479,
                13.484,
                13.489,
                13.493,
                13.498,
                13.503,
                13.507,
                13.512,
                13.516,
                13.521,
                13.526,
                13.53,
                13.535,
                13.539,
                13.544,
                13.549,
                13.553,
                13.558,
                13.562,
                13.567,
                13.572,
                13.576,
                13.581,
                13.585,
                13.59,
                13.595,
                13.599,
                13.604,
                13.608,
                13.613,
                13.618,
                13.622,
                13.627,
                13.631,
                13.636,
                13.641,
                13.645,
                13.65,
                13.655,
                13.659,
                13.664,
                13.668,
                13.673,
                13.677,
                13.682,
                13.687,
                13.691,
                13.696,
                13.7,
                13.705,
                13.71,
                13.714,
                13.719,
                13.723,
                13.728,
                13.733,
                13.737,
                13.742,
                13.746,
                13.751,
                13.756,
                13.76,
                13.765,
                13.769,
                13.774,
                13.778,
                13.783,
                13.788,
                13.792,
                13.797,
                13.801,
                13.806,
                13.81,
                13.815,
                13.82,
                13.824,
                13.829,
                13.833,
                13.838,
                13.843,
                13.847,
                13.852,
                13.856,
                13.861,
                13.865,
                13.87,
                13.874,
                13.879,
                13.884,
                13.888,
                13.893,
                13.897,
                13.902,
                13.906,
                13.911,
                13.916,
                13.92,
                13.925,
                13.929,
                13.934,
                13.939,
                13.943,
                13.948,
                13.952,
                13.957,
                13.961,
                13.966,
                13.97,
                13.975,
                13.979,
                13.984,
                13.989,
                13.993,
                13.998,
                14.002,
                14.007,
                14.011,
                14.016,
                14.02,
                14.025,
                14.03,
                14.034,
                14.039,
                14.043,
                14.048,
                14.052,
                14.057,
                14.062,
                14.066,
                14.071,
                14.075,
                14.08,
                14.084,
                14.089,
                14.093,
                14.098,
                14.102,
                14.107,
                14.111,
                14.116,
                14.121,
                14.125,
                14.13,
                14.134,
                14.139,
                14.143,
                14.148,
                14.152,
                14.157,
                14.161,
                14.166,
                14.17,
                14.175,
                14.179,
                14.184,
                14.189,
                14.193,
                14.198,
                14.202,
                14.207,
                14.211,
                14.216,
                14.22,
                14.225,
                14.229,
                14.234,
                14.238,
                14.243,
                14.247,
                14.252,
                14.256,
                14.261,
                14.266,
                14.27,
                14.275,
                14.279,
                14.284,
                14.288,
                14.293,
                14.297,
                14.302,
                14.306,
                14.311,
                14.315,
                14.32,
                14.324,
                14.329,
                14.333,
                14.338,
                14.342,
                14.347,
                14.351,
                14.356,
                14.36,
                14.365,
                14.369,
                14.374,
                14.378,
                14.383,
                14.387,
                14.392,
                14.396,
                14.401,
                14.405,
                14.41,
                14.414,
                14.419,
                14.423,
                14.428,
                14.432,
                14.437,
                14.442,
                14.446,
                14.451,
                14.455,
                14.46,
                14.464,
                14.469,
                14.473,
                14.478,
                14.482,
                14.487,
                14.491,
                14.496,
                14.5,
                14.505,
                14.509,
                14.514,
                14.518,
                14.523,
                14.527,
                14.532,
                14.536,
                14.541,
                14.545,
                14.55,
                14.554,
                14.559,
                14.563,
                14.568,
                14.572,
                14.576,
                14.581,
                14.585,
                14.59,
                14.594,
                14.599,
                14.603,
                14.608,
                14.612,
                14.617,
                14.621,
                14.626,
                14.63,
                14.635,
                14.639,
                14.644,
                14.648,
                14.653,
                14.657,
                14.662,
                14.666,
                14.671,
                14.675,
                14.68,
                14.684,
                14.689,
                14.693,
                14.698,
                14.702,
                14.707,
                14.711,
                14.716,
                14.72,
                14.724,
                14.729,
                14.733,
                14.738,
                14.743,
                14.747,
                14.751,
                14.756,
                14.76,
                14.765,
                14.769,
                14.774,
                14.778,
                14.783,
                14.787,
                14.792,
                14.796,
                14.801,
                14.805,
                14.81,
                14.814,
                14.819,
                14.823,
                14.828,
                14.832,
                14.837,
                14.841,
                14.846,
                14.85,
                14.854,
                14.859,
                14.863,
                14.868,
                14.872,
                14.877,
                14.881,
                14.886,
                14.89,
                14.895,
                14.899,
                14.904,
                14.908,
                14.913,
                14.917,
                14.921,
                14.926,
                14.93,
                14.935,
                14.939,
                14.944,
                14.948,
                14.953,
                14.957,
                14.962,
                14.966,
                14.971,
                14.975,
                14.98,
                14.984,
                14.989,
                14.993,
                14.997,
                15.002,
                15.006,
                15.011,
                15.015,
                15.02,
                15.024,
                15.029,
                15.033,
                15.038,
                15.042,
                15.047,
                15.051,
                15.055,
                15.06,
                15.065,
                15.069,
                15.073,
                15.078,
                15.082,
                15.087,
                15.091,
                15.096,
                15.1,
                15.105,
                15.109,
                15.113,
                15.118,
                15.122,
                15.127,
                15.131,
                15.136,
                15.14,
                15.145,
                15.149,
                15.154,
                15.158,
                15.163,
                15.167,
                15.171,
                15.176,
                15.18,
                15.185,
                15.189,
                15.194,
                15.198,
                15.203,
                15.207,
                15.211,
                15.216,
                15.22,
                15.225,
                15.229,
                15.234,
                15.238,
                15.243,
                15.247,
                15.252,
                15.256,
                15.26,
                15.265,
                15.269,
                15.274,
                15.278,
                15.283,
                15.287,
                15.292,
                15.296,
                15.3,
                15.305,
                15.309,
                15.314,
                15.318,
                15.323,
                15.327,
                15.332,
                15.336,
                15.34,
                15.345,
                15.349,
                15.354,
                15.358,
                15.363,
                15.367,
                15.371,
                15.376,
                15.38,
                15.385,
                15.389,
                15.394,
                15.398,
                15.402,
                15.407,
                15.411,
                15.416,
                15.42,
                15.424,
                15.429,
                15.433,
                15.438,
                15.442,
                15.447,
                15.451,
                15.455,
                15.46,
                15.464,
                15.469,
                15.473,
                15.478,
                15.482,
                15.487,
                15.491,
                15.495,
                15.5,
                15.504,
                15.509,
                15.513,
                15.518,
                15.522,
                15.526,
                15.531,
                15.535,
                15.54,
                15.544,
                15.548,
                15.553,
                15.557,
                15.562,
                15.566,
                15.571,
                15.575,
                15.579,
                15.584,
                15.588,
                15.593,
                15.597,
                15.601,
                15.606,
                15.61,
                15.615,
                15.619,
                15.623,
                15.628,
                15.632,
                15.637,
                15.641,
                15.646,
                15.65,
                15.654,
                15.659,
                15.663,
                15.668,
                15.672,
                15.676,
                15.681,
                15.685,
                15.69,
                15.694,
                15.698,
                15.703,
                15.707,
                15.712,
                15.716,
                15.72,
                15.725,
                15.729,
                15.734,
                15.738,
                15.742,
                15.747,
                15.751,
                15.756,
                15.76,
                15.764,
                15.769,
                15.773,
                15.777,
                15.782,
                15.786,
                15.791,
                15.795,
                15.8,
                15.804,
                15.808,
                15.813,
                15.817,
                15.821,
                15.826,
                15.83,
                15.835,
                15.839,
                15.843,
                15.848,
                15.852,
                15.857,
                15.861,
                15.865,
                15.87,
                15.874,
                15.879,
                15.883,
                15.887,
                15.892,
                15.896,
                15.9,
                15.905,
                15.909,
                15.914,
                15.918,
                15.922,
                15.927,
                15.931,
                15.935,
                15.94,
                15.944,
                15.948,
                15.953,
                15.957,
                15.962,
                15.966,
                15.97,
                15.975,
                15.979,
                15.984,
                15.988,
                15.992,
                15.997,
                16.001,
                16.005,
                16.01,
                16.014,
                16.018,
                16.023,
                16.027,
                16.032,
                16.036,
                16.04,
                16.044,
                16.049,
                16.053,
                16.058,
                16.062,
                16.066,
                16.071,
                16.075,
                16.08,
                16.084,
                16.088,
                16.092

            ],
            P25: [
                3.027,
                3,
                3.018,
                3.042,
                3.069,
                3.098,
                3.128,
                3.159,
                3.191,
                3.224,
                3.259,
                3.294,
                3.331,
                3.368,
                3.407,
                3.447,
                3.488,
                3.529,
                3.57,
                3.612,
                3.654,
                3.695,
                3.737,
                3.778,
                3.82,
                3.861,
                3.902,
                3.943,
                3.983,
                4.024,
                4.063,
                4.103,
                4.142,
                4.181,
                4.22,
                4.258,
                4.296,
                4.333,
                4.37,
                4.406,
                4.443,
                4.478,
                4.514,
                4.549,
                4.584,
                4.618,
                4.652,
                4.686,
                4.719,
                4.752,
                4.784,
                4.817,
                4.848,
                4.88,
                4.911,
                4.942,
                4.973,
                5.003,
                5.033,
                5.063,
                5.092,
                5.121,
                5.15,
                5.179,
                5.207,
                5.235,
                5.263,
                5.291,
                5.318,
                5.345,
                5.372,
                5.398,
                5.425,
                5.451,
                5.476,
                5.502,
                5.527,
                5.552,
                5.577,
                5.602,
                5.626,
                5.651,
                5.674,
                5.698,
                5.722,
                5.745,
                5.768,
                5.791,
                5.814,
                5.837,
                5.859,
                5.881,
                5.903,
                5.925,
                5.947,
                5.968,
                5.989,
                6.011,
                6.031,
                6.052,
                6.073,
                6.093,
                6.114,
                6.134,
                6.154,
                6.174,
                6.193,
                6.213,
                6.232,
                6.251,
                6.27,
                6.289,
                6.308,
                6.327,
                6.345,
                6.364,
                6.382,
                6.4,
                6.418,
                6.436,
                6.454,
                6.472,
                6.489,
                6.507,
                6.524,
                6.541,
                6.558,
                6.575,
                6.592,
                6.609,
                6.625,
                6.642,
                6.658,
                6.674,
                6.691,
                6.707,
                6.723,
                6.738,
                6.754,
                6.77,
                6.785,
                6.801,
                6.816,
                6.831,
                6.847,
                6.862,
                6.877,
                6.891,
                6.906,
                6.921,
                6.935,
                6.95,
                6.964,
                6.979,
                6.993,
                7.007,
                7.021,
                7.035,
                7.049,
                7.063,
                7.076,
                7.09,
                7.103,
                7.117,
                7.13,
                7.144,
                7.157,
                7.17,
                7.183,
                7.196,
                7.209,
                7.222,
                7.235,
                7.247,
                7.26,
                7.273,
                7.285,
                7.297,
                7.31,
                7.322,
                7.334,
                7.347,
                7.359,
                7.371,
                7.383,
                7.395,
                7.407,
                7.418,
                7.43,
                7.442,
                7.453,
                7.465,
                7.477,
                7.488,
                7.499,
                7.511,
                7.522,
                7.533,
                7.544,
                7.555,
                7.567,
                7.578,
                7.589,
                7.599,
                7.61,
                7.621,
                7.632,
                7.643,
                7.653,
                7.664,
                7.674,
                7.685,
                7.695,
                7.706,
                7.716,
                7.726,
                7.737,
                7.747,
                7.757,
                7.767,
                7.777,
                7.787,
                7.797,
                7.807,
                7.817,
                7.827,
                7.837,
                7.847,
                7.857,
                7.866,
                7.876,
                7.886,
                7.895,
                7.905,
                7.914,
                7.924,
                7.933,
                7.943,
                7.952,
                7.962,
                7.971,
                7.98,
                7.989,
                7.999,
                8.008,
                8.017,
                8.026,
                8.035,
                8.044,
                8.053,
                8.062,
                8.071,
                8.08,
                8.089,
                8.098,
                8.107,
                8.116,
                8.124,
                8.133,
                8.142,
                8.151,
                8.159,
                8.168,
                8.177,
                8.185,
                8.194,
                8.202,
                8.211,
                8.219,
                8.228,
                8.236,
                8.245,
                8.253,
                8.262,
                8.27,
                8.278,
                8.287,
                8.295,
                8.303,
                8.311,
                8.32,
                8.328,
                8.336,
                8.344,
                8.352,
                8.361,
                8.369,
                8.377,
                8.385,
                8.393,
                8.401,
                8.409,
                8.417,
                8.425,
                8.433,
                8.441,
                8.448,
                8.456,
                8.464,
                8.472,
                8.48,
                8.488,
                8.495,
                8.503,
                8.511,
                8.519,
                8.527,
                8.534,
                8.542,
                8.55,
                8.557,
                8.565,
                8.573,
                8.58,
                8.588,
                8.595,
                8.603,
                8.61,
                8.618,
                8.626,
                8.633,
                8.641,
                8.648,
                8.656,
                8.663,
                8.67,
                8.678,
                8.685,
                8.693,
                8.7,
                8.708,
                8.715,
                8.722,
                8.73,
                8.737,
                8.744,
                8.752,
                8.759,
                8.766,
                8.774,
                8.781,
                8.788,
                8.795,
                8.802,
                8.81,
                8.817,
                8.824,
                8.831,
                8.839,
                8.846,
                8.853,
                8.86,
                8.867,
                8.874,
                8.881,
                8.889,
                8.896,
                8.903,
                8.91,
                8.917,
                8.924,
                8.931,
                8.938,
                8.945,
                8.952,
                8.959,
                8.966,
                8.973,
                8.98,
                8.987,
                8.994,
                9.001,
                9.008,
                9.015,
                9.022,
                9.029,
                9.036,
                9.043,
                9.05,
                9.057,
                9.063,
                9.07,
                9.077,
                9.084,
                9.091,
                9.098,
                9.105,
                9.111,
                9.118,
                9.125,
                9.132,
                9.139,
                9.145,
                9.152,
                9.159,
                9.166,
                9.173,
                9.179,
                9.186,
                9.193,
                9.2,
                9.206,
                9.213,
                9.22,
                9.227,
                9.233,
                9.24,
                9.247,
                9.253,
                9.26,
                9.267,
                9.273,
                9.28,
                9.287,
                9.293,
                9.3,
                9.307,
                9.313,
                9.32,
                9.327,
                9.333,
                9.34,
                9.346,
                9.353,
                9.36,
                9.366,
                9.373,
                9.379,
                9.386,
                9.393,
                9.399,
                9.406,
                9.412,
                9.419,
                9.425,
                9.432,
                9.438,
                9.445,
                9.452,
                9.458,
                9.465,
                9.471,
                9.478,
                9.484,
                9.491,
                9.497,
                9.504,
                9.51,
                9.517,
                9.523,
                9.53,
                9.536,
                9.543,
                9.549,
                9.555,
                9.562,
                9.568,
                9.575,
                9.581,
                9.588,
                9.594,
                9.601,
                9.607,
                9.613,
                9.62,
                9.626,
                9.633,
                9.639,
                9.645,
                9.652,
                9.658,
                9.665,
                9.671,
                9.678,
                9.684,
                9.69,
                9.697,
                9.703,
                9.709,
                9.716,
                9.722,
                9.729,
                9.735,
                9.741,
                9.748,
                9.754,
                9.76,
                9.767,
                9.773,
                9.779,
                9.786,
                9.792,
                9.798,
                9.805,
                9.811,
                9.817,
                9.824,
                9.83,
                9.836,
                9.843,
                9.849,
                9.855,
                9.862,
                9.868,
                9.874,
                9.88,
                9.887,
                9.893,
                9.899,
                9.906,
                9.912,
                9.918,
                9.924,
                9.931,
                9.937,
                9.943,
                9.949,
                9.956,
                9.962,
                9.968,
                9.974,
                9.981,
                9.987,
                9.993,
                9.999,
                10.006,
                10.012,
                10.018,
                10.024,
                10.031,
                10.037,
                10.043,
                10.049,
                10.055,
                10.062,
                10.068,
                10.074,
                10.08,
                10.086,
                10.093,
                10.099,
                10.105,
                10.111,
                10.117,
                10.124,
                10.13,
                10.136,
                10.142,
                10.148,
                10.154,
                10.161,
                10.167,
                10.173,
                10.179,
                10.185,
                10.191,
                10.198,
                10.204,
                10.21,
                10.216,
                10.222,
                10.228,
                10.234,
                10.241,
                10.247,
                10.253,
                10.259,
                10.265,
                10.271,
                10.277,
                10.284,
                10.29,
                10.296,
                10.302,
                10.308,
                10.314,
                10.32,
                10.326,
                10.332,
                10.339,
                10.345,
                10.351,
                10.357,
                10.363,
                10.369,
                10.375,
                10.381,
                10.387,
                10.393,
                10.399,
                10.406,
                10.412,
                10.418,
                10.424,
                10.43,
                10.436,
                10.442,
                10.448,
                10.454,
                10.46,
                10.466,
                10.472,
                10.479,
                10.485,
                10.491,
                10.497,
                10.503,
                10.509,
                10.515,
                10.521,
                10.527,
                10.533,
                10.539,
                10.545,
                10.551,
                10.558,
                10.564,
                10.57,
                10.576,
                10.582,
                10.588,
                10.594,
                10.6,
                10.606,
                10.612,
                10.618,
                10.624,
                10.63,
                10.636,
                10.642,
                10.648,
                10.654,
                10.66,
                10.666,
                10.672,
                10.679,
                10.685,
                10.691,
                10.697,
                10.703,
                10.709,
                10.715,
                10.721,
                10.727,
                10.733,
                10.739,
                10.745,
                10.751,
                10.757,
                10.763,
                10.769,
                10.775,
                10.781,
                10.787,
                10.793,
                10.799,
                10.805,
                10.811,
                10.817,
                10.823,
                10.829,
                10.835,
                10.841,
                10.847,
                10.853,
                10.859,
                10.865,
                10.871,
                10.877,
                10.883,
                10.889,
                10.895,
                10.901,
                10.907,
                10.913,
                10.919,
                10.925,
                10.931,
                10.937,
                10.943,
                10.949,
                10.955,
                10.961,
                10.967,
                10.973,
                10.979,
                10.985,
                10.991,
                10.997,
                11.003,
                11.009,
                11.015,
                11.021,
                11.027,
                11.033,
                11.039,
                11.045,
                11.051,
                11.057,
                11.063,
                11.069,
                11.075,
                11.081,
                11.087,
                11.093,
                11.099,
                11.105,
                11.111,
                11.117,
                11.123,
                11.129,
                11.135,
                11.141,
                11.147,
                11.153,
                11.158,
                11.164,
                11.17,
                11.176,
                11.182,
                11.188,
                11.194,
                11.2,
                11.206,
                11.212,
                11.218,
                11.224,
                11.23,
                11.236,
                11.242,
                11.248,
                11.254,
                11.26,
                11.265,
                11.271,
                11.277,
                11.283,
                11.289,
                11.295,
                11.301,
                11.307,
                11.313,
                11.319,
                11.325,
                11.33,
                11.336,
                11.342,
                11.348,
                11.354,
                11.36,
                11.366,
                11.372,
                11.378,
                11.384,
                11.39,
                11.395,
                11.401,
                11.407,
                11.413,
                11.419,
                11.425,
                11.431,
                11.436,
                11.442,
                11.448,
                11.454,
                11.46,
                11.466,
                11.472,
                11.478,
                11.483,
                11.489,
                11.495,
                11.501,
                11.507,
                11.513,
                11.518,
                11.524,
                11.53,
                11.536,
                11.542,
                11.547,
                11.553,
                11.559,
                11.565,
                11.571,
                11.576,
                11.582,
                11.588,
                11.594,
                11.6,
                11.605,
                11.611,
                11.617,
                11.623,
                11.629,
                11.634,
                11.64,
                11.646,
                11.652,
                11.657,
                11.663,
                11.669,
                11.675,
                11.68,
                11.686,
                11.692,
                11.698,
                11.703,
                11.709,
                11.715,
                11.72,
                11.726,
                11.732,
                11.738,
                11.743,
                11.749,
                11.755,
                11.76,
                11.766,
                11.772,
                11.777,
                11.783,
                11.789,
                11.794,
                11.8,
                11.806,
                11.812,
                11.817,
                11.823,
                11.828,
                11.834,
                11.84,
                11.845,
                11.851,
                11.857,
                11.862,
                11.868,
                11.874,
                11.879,
                11.885,
                11.89,
                11.896,
                11.902,
                11.907,
                11.913,
                11.918,
                11.924,
                11.93,
                11.935,
                11.941,
                11.946,
                11.952,
                11.958,
                11.963,
                11.969,
                11.974,
                11.98,
                11.985,
                11.991,
                11.996,
                12.002,
                12.007,
                12.013,
                12.019,
                12.024,
                12.03,
                12.035,
                12.041,
                12.046,
                12.052,
                12.057,
                12.063,
                12.068,
                12.074,
                12.079,
                12.085,
                12.09,
                12.096,
                12.101,
                12.107,
                12.112,
                12.117,
                12.123,
                12.128,
                12.134,
                12.139,
                12.145,
                12.15,
                12.156,
                12.161,
                12.166,
                12.172,
                12.177,
                12.183,
                12.188,
                12.194,
                12.199,
                12.204,
                12.21,
                12.215,
                12.221,
                12.226,
                12.231,
                12.237,
                12.242,
                12.248,
                12.253,
                12.258,
                12.264,
                12.269,
                12.274,
                12.28,
                12.285,
                12.29,
                12.296,
                12.301,
                12.307,
                12.312,
                12.317,
                12.322,
                12.328,
                12.333,
                12.338,
                12.344,
                12.349,
                12.354,
                12.36,
                12.365,
                12.37,
                12.376,
                12.381,
                12.386,
                12.391,
                12.397,
                12.402,
                12.407,
                12.413,
                12.418,
                12.423,
                12.428,
                12.434,
                12.439,
                12.444,
                12.449,
                12.455,
                12.46,
                12.465,
                12.471,
                12.476,
                12.481,
                12.486,
                12.491,
                12.497,
                12.502,
                12.507,
                12.512,
                12.518,
                12.523,
                12.528,
                12.533,
                12.538,
                12.544,
                12.549,
                12.554,
                12.559,
                12.564,
                12.57,
                12.575,
                12.58,
                12.585,
                12.59,
                12.596,
                12.601,
                12.606,
                12.611,
                12.616,
                12.621,
                12.626,
                12.632,
                12.637,
                12.642,
                12.647,
                12.652,
                12.657,
                12.662,
                12.668,
                12.673,
                12.678,
                12.683,
                12.688,
                12.693,
                12.698,
                12.703,
                12.709,
                12.714,
                12.719,
                12.724,
                12.729,
                12.734,
                12.739,
                12.745,
                12.75,
                12.755,
                12.76,
                12.765,
                12.77,
                12.775,
                12.78,
                12.785,
                12.79,
                12.795,
                12.8,
                12.806,
                12.811,
                12.816,
                12.821,
                12.826,
                12.831,
                12.836,
                12.841,
                12.846,
                12.851,
                12.856,
                12.861,
                12.866,
                12.871,
                12.876,
                12.882,
                12.887,
                12.892,
                12.897,
                12.902,
                12.907,
                12.912,
                12.917,
                12.922,
                12.927,
                12.932,
                12.937,
                12.942,
                12.947,
                12.952,
                12.957,
                12.962,
                12.967,
                12.972,
                12.977,
                12.982,
                12.987,
                12.992,
                12.997,
                13.002,
                13.007,
                13.012,
                13.017,
                13.022,
                13.027,
                13.032,
                13.037,
                13.042,
                13.047,
                13.052,
                13.057,
                13.062,
                13.067,
                13.072,
                13.077,
                13.082,
                13.087,
                13.092,
                13.097,
                13.102,
                13.107,
                13.112,
                13.117,
                13.122,
                13.127,
                13.132,
                13.137,
                13.142,
                13.147,
                13.152,
                13.157,
                13.162,
                13.167,
                13.172,
                13.177,
                13.182,
                13.187,
                13.192,
                13.197,
                13.202,
                13.207,
                13.212,
                13.217,
                13.222,
                13.227,
                13.232,
                13.237,
                13.242,
                13.246,
                13.251,
                13.256,
                13.261,
                13.266,
                13.271,
                13.276,
                13.281,
                13.286,
                13.291,
                13.296,
                13.301,
                13.306,
                13.311,
                13.316,
                13.321,
                13.326,
                13.331,
                13.336,
                13.341,
                13.345,
                13.35,
                13.355,
                13.36,
                13.365,
                13.37,
                13.375,
                13.38,
                13.385,
                13.39,
                13.395,
                13.4,
                13.405,
                13.41,
                13.415,
                13.42,
                13.424,
                13.429,
                13.434,
                13.439,
                13.444,
                13.449,
                13.454,
                13.459,
                13.464,
                13.469,
                13.474,
                13.479,
                13.484,
                13.489,
                13.493,
                13.498,
                13.503,
                13.508,
                13.513,
                13.518,
                13.523,
                13.528,
                13.533,
                13.538,
                13.543,
                13.548,
                13.553,
                13.558,
                13.562,
                13.567,
                13.572,
                13.577,
                13.582,
                13.587,
                13.592,
                13.597,
                13.602,
                13.607,
                13.612,
                13.617,
                13.622,
                13.626,
                13.631,
                13.636,
                13.641,
                13.646,
                13.651,
                13.656,
                13.661,
                13.666,
                13.671,
                13.676,
                13.68,
                13.685,
                13.69,
                13.695,
                13.7,
                13.705,
                13.71,
                13.715,
                13.72,
                13.725,
                13.73,
                13.735,
                13.739,
                13.744,
                13.749,
                13.754,
                13.759,
                13.764,
                13.769,
                13.774,
                13.779,
                13.784,
                13.789,
                13.794,
                13.798,
                13.803,
                13.808,
                13.813,
                13.818,
                13.823,
                13.828,
                13.833,
                13.838,
                13.843,
                13.848,
                13.853,
                13.857,
                13.862,
                13.867,
                13.872,
                13.877,
                13.882,
                13.887,
                13.892,
                13.897,
                13.902,
                13.907,
                13.911,
                13.916,
                13.921,
                13.926,
                13.931,
                13.936,
                13.941,
                13.946,
                13.951,
                13.956,
                13.96,
                13.965,
                13.97,
                13.975,
                13.98,
                13.985,
                13.99,
                13.995,
                14,
                14.005,
                14.01,
                14.014,
                14.019,
                14.024,
                14.029,
                14.034,
                14.039,
                14.044,
                14.049,
                14.054,
                14.059,
                14.063,
                14.068,
                14.073,
                14.078,
                14.083,
                14.088,
                14.093,
                14.098,
                14.103,
                14.108,
                14.112,
                14.117,
                14.122,
                14.127,
                14.132,
                14.137,
                14.142,
                14.147,
                14.152,
                14.157,
                14.162,
                14.166,
                14.171,
                14.176,
                14.181,
                14.186,
                14.191,
                14.196,
                14.201,
                14.206,
                14.21,
                14.215,
                14.22,
                14.225,
                14.23,
                14.235,
                14.24,
                14.245,
                14.25,
                14.254,
                14.259,
                14.264,
                14.269,
                14.274,
                14.279,
                14.284,
                14.289,
                14.294,
                14.299,
                14.303,
                14.308,
                14.313,
                14.318,
                14.323,
                14.328,
                14.333,
                14.338,
                14.342,
                14.347,
                14.352,
                14.357,
                14.362,
                14.367,
                14.372,
                14.377,
                14.382,
                14.386,
                14.391,
                14.396,
                14.401,
                14.406,
                14.411,
                14.416,
                14.421,
                14.425,
                14.43,
                14.435,
                14.44,
                14.445,
                14.45,
                14.455,
                14.459,
                14.464,
                14.469,
                14.474,
                14.479,
                14.484,
                14.489,
                14.494,
                14.499,
                14.503,
                14.508,
                14.513,
                14.518,
                14.523,
                14.528,
                14.532,
                14.537,
                14.542,
                14.547,
                14.552,
                14.557,
                14.562,
                14.567,
                14.571,
                14.576,
                14.581,
                14.586,
                14.591,
                14.596,
                14.601,
                14.605,
                14.61,
                14.615,
                14.62,
                14.625,
                14.63,
                14.634,
                14.639,
                14.644,
                14.649,
                14.654,
                14.659,
                14.664,
                14.669,
                14.673,
                14.678,
                14.683,
                14.688,
                14.693,
                14.698,
                14.702,
                14.707,
                14.712,
                14.717,
                14.722,
                14.727,
                14.732,
                14.736,
                14.741,
                14.746,
                14.751,
                14.756,
                14.761,
                14.766,
                14.77,
                14.775,
                14.78,
                14.785,
                14.79,
                14.795,
                14.799,
                14.804,
                14.809,
                14.814,
                14.819,
                14.824,
                14.828,
                14.833,
                14.838,
                14.843,
                14.848,
                14.853,
                14.857,
                14.862,
                14.867,
                14.872,
                14.877,
                14.881,
                14.886,
                14.891,
                14.896,
                14.901,
                14.906,
                14.911,
                14.915,
                14.92,
                14.925,
                14.93,
                14.935,
                14.94,
                14.944,
                14.949,
                14.954,
                14.959,
                14.964,
                14.968,
                14.973,
                14.978,
                14.983,
                14.988,
                14.993,
                14.997,
                15.002,
                15.007,
                15.012,
                15.017,
                15.021,
                15.026,
                15.031,
                15.036,
                15.041,
                15.046,
                15.05,
                15.055,
                15.06,
                15.065,
                15.07,
                15.074,
                15.079,
                15.084,
                15.089,
                15.094,
                15.098,
                15.103,
                15.108,
                15.113,
                15.118,
                15.123,
                15.128,
                15.132,
                15.137,
                15.142,
                15.147,
                15.152,
                15.156,
                15.161,
                15.166,
                15.171,
                15.176,
                15.18,
                15.185,
                15.19,
                15.195,
                15.2,
                15.204,
                15.209,
                15.214,
                15.219,
                15.224,
                15.228,
                15.233,
                15.238,
                15.243,
                15.248,
                15.253,
                15.257,
                15.262,
                15.267,
                15.272,
                15.276,
                15.281,
                15.286,
                15.291,
                15.296,
                15.301,
                15.305,
                15.31,
                15.315,
                15.32,
                15.324,
                15.329,
                15.334,
                15.339,
                15.344,
                15.349,
                15.353,
                15.358,
                15.363,
                15.368,
                15.373,
                15.377,
                15.382,
                15.387,
                15.392,
                15.397,
                15.401,
                15.406,
                15.411,
                15.416,
                15.42,
                15.425,
                15.43,
                15.435,
                15.44,
                15.444,
                15.449,
                15.454,
                15.459,
                15.464,
                15.468,
                15.473,
                15.478,
                15.483,
                15.488,
                15.493,
                15.497,
                15.502,
                15.507,
                15.512,
                15.516,
                15.521,
                15.526,
                15.531,
                15.536,
                15.54,
                15.545,
                15.55,
                15.555,
                15.56,
                15.564,
                15.569,
                15.574,
                15.579,
                15.583,
                15.588,
                15.593,
                15.598,
                15.603,
                15.607,
                15.612,
                15.617,
                15.622,
                15.627,
                15.631,
                15.636,
                15.641,
                15.646,
                15.651,
                15.655,
                15.66,
                15.665,
                15.67,
                15.675,
                15.679,
                15.684,
                15.689,
                15.694,
                15.698,
                15.703,
                15.708,
                15.713,
                15.718,
                15.722,
                15.727,
                15.732,
                15.737,
                15.742,
                15.746,
                15.751,
                15.756,
                15.761,
                15.765,
                15.77,
                15.775,
                15.78,
                15.785,
                15.789,
                15.794,
                15.799,
                15.804,
                15.808,
                15.813,
                15.818,
                15.823,
                15.828,
                15.832,
                15.837,
                15.842,
                15.847,
                15.851,
                15.856,
                15.861,
                15.866,
                15.871,
                15.875,
                15.88,
                15.885,
                15.89,
                15.895,
                15.899,
                15.904,
                15.909,
                15.914,
                15.918,
                15.923,
                15.928,
                15.933,
                15.937,
                15.942,
                15.947,
                15.952,
                15.956,
                15.961,
                15.966,
                15.971,
                15.976,
                15.98,
                15.985,
                15.99,
                15.995,
                15.999,
                16.004,
                16.009,
                16.014,
                16.019,
                16.023,
                16.028,
                16.033,
                16.037,
                16.042,
                16.047,
                16.052,
                16.057,
                16.061,
                16.066,
                16.071,
                16.076,
                16.08,
                16.085,
                16.09,
                16.095,
                16.099,
                16.104,
                16.109,
                16.114,
                16.118,
                16.123,
                16.128,
                16.133,
                16.138,
                16.142,
                16.147,
                16.152,
                16.157,
                16.161,
                16.166,
                16.171,
                16.176,
                16.18,
                16.185,
                16.19,
                16.195,
                16.199,
                16.204,
                16.209,
                16.214,
                16.218,
                16.223,
                16.228,
                16.233,
                16.237,
                16.242,
                16.247,
                16.252,
                16.256,
                16.261,
                16.266,
                16.271,
                16.275,
                16.28,
                16.285,
                16.29,
                16.294,
                16.299,
                16.304,
                16.309,
                16.313,
                16.318,
                16.323,
                16.328,
                16.332,
                16.337,
                16.342,
                16.347,
                16.351,
                16.356,
                16.361,
                16.366,
                16.37,
                16.375,
                16.38,
                16.384,
                16.389,
                16.394,
                16.399,
                16.403,
                16.408,
                16.413,
                16.418,
                16.422,
                16.427,
                16.432,
                16.437,
                16.441,
                16.446,
                16.451,
                16.456,
                16.46,
                16.465,
                16.47,
                16.474,
                16.479,
                16.484,
                16.489,
                16.493,
                16.498,
                16.503,
                16.507,
                16.512,
                16.517,
                16.522,
                16.526,
                16.531,
                16.536,
                16.541,
                16.545,
                16.55,
                16.555,
                16.559,
                16.564,
                16.569,
                16.574,
                16.578,
                16.583,
                16.588,
                16.593,
                16.597,
                16.602,
                16.607,
                16.611,
                16.616,
                16.621,
                16.626,
                16.63,
                16.635,
                16.64,
                16.644,
                16.649,
                16.654,
                16.659,
                16.663,
                16.668,
                16.673,
                16.677,
                16.682,
                16.687,
                16.691,
                16.696,
                16.701,
                16.706,
                16.71,
                16.715,
                16.72,
                16.725,
                16.729,
                16.734,
                16.738,
                16.743,
                16.748,
                16.753,
                16.757,
                16.762,
                16.767,
                16.771,
                16.776,
                16.781,
                16.786,
                16.79,
                16.795,
                16.8,
                16.804,
                16.809,
                16.814,
                16.818,
                16.823,
                16.828,
                16.832,
                16.837,
                16.842,
                16.846,
                16.851,
                16.856,
                16.861,
                16.865,
                16.87,
                16.875,
                16.879,
                16.884,
                16.889

            ],
            P50: [
                3.346,
                3.317,
                3.337,
                3.363,
                3.392,
                3.422,
                3.455,
                3.488,
                3.522,
                3.558,
                3.594,
                3.632,
                3.671,
                3.711,
                3.753,
                3.796,
                3.839,
                3.883,
                3.927,
                3.971,
                4.016,
                4.06,
                4.105,
                4.149,
                4.193,
                4.237,
                4.281,
                4.324,
                4.367,
                4.41,
                4.453,
                4.495,
                4.536,
                4.578,
                4.619,
                4.659,
                4.699,
                4.739,
                4.778,
                4.817,
                4.855,
                4.893,
                4.93,
                4.967,
                5.004,
                5.04,
                5.076,
                5.112,
                5.147,
                5.182,
                5.216,
                5.25,
                5.284,
                5.317,
                5.35,
                5.383,
                5.415,
                5.447,
                5.478,
                5.51,
                5.541,
                5.571,
                5.602,
                5.632,
                5.662,
                5.691,
                5.721,
                5.749,
                5.778,
                5.807,
                5.835,
                5.863,
                5.89,
                5.917,
                5.945,
                5.971,
                5.998,
                6.024,
                6.05,
                6.076,
                6.102,
                6.127,
                6.152,
                6.177,
                6.202,
                6.226,
                6.251,
                6.275,
                6.299,
                6.322,
                6.346,
                6.369,
                6.392,
                6.415,
                6.438,
                6.46,
                6.482,
                6.505,
                6.527,
                6.548,
                6.57,
                6.591,
                6.613,
                6.634,
                6.655,
                6.676,
                6.696,
                6.717,
                6.737,
                6.757,
                6.777,
                6.797,
                6.817,
                6.837,
                6.856,
                6.875,
                6.895,
                6.914,
                6.933,
                6.951,
                6.97,
                6.989,
                7.007,
                7.025,
                7.043,
                7.062,
                7.079,
                7.097,
                7.115,
                7.133,
                7.15,
                7.167,
                7.185,
                7.202,
                7.219,
                7.236,
                7.253,
                7.269,
                7.286,
                7.302,
                7.319,
                7.335,
                7.351,
                7.367,
                7.383,
                7.399,
                7.415,
                7.431,
                7.446,
                7.462,
                7.477,
                7.493,
                7.508,
                7.523,
                7.538,
                7.553,
                7.568,
                7.582,
                7.597,
                7.612,
                7.626,
                7.641,
                7.655,
                7.669,
                7.683,
                7.698,
                7.712,
                7.726,
                7.739,
                7.753,
                7.767,
                7.781,
                7.794,
                7.808,
                7.821,
                7.834,
                7.848,
                7.861,
                7.874,
                7.887,
                7.9,
                7.913,
                7.926,
                7.939,
                7.952,
                7.964,
                7.977,
                7.99,
                8.002,
                8.015,
                8.027,
                8.039,
                8.052,
                8.064,
                8.076,
                8.088,
                8.1,
                8.112,
                8.124,
                8.136,
                8.148,
                8.159,
                8.171,
                8.183,
                8.194,
                8.206,
                8.217,
                8.229,
                8.24,
                8.251,
                8.263,
                8.274,
                8.285,
                8.296,
                8.307,
                8.318,
                8.329,
                8.34,
                8.351,
                8.362,
                8.373,
                8.384,
                8.394,
                8.405,
                8.416,
                8.426,
                8.437,
                8.447,
                8.458,
                8.468,
                8.479,
                8.489,
                8.499,
                8.51,
                8.52,
                8.53,
                8.54,
                8.55,
                8.56,
                8.57,
                8.58,
                8.59,
                8.6,
                8.61,
                8.62,
                8.63,
                8.64,
                8.649,
                8.659,
                8.669,
                8.679,
                8.688,
                8.698,
                8.707,
                8.717,
                8.726,
                8.736,
                8.745,
                8.755,
                8.764,
                8.774,
                8.783,
                8.792,
                8.802,
                8.811,
                8.82,
                8.829,
                8.838,
                8.848,
                8.857,
                8.866,
                8.875,
                8.884,
                8.893,
                8.902,
                8.911,
                8.92,
                8.929,
                8.938,
                8.947,
                8.956,
                8.964,
                8.973,
                8.982,
                8.991,
                9,
                9.008,
                9.017,
                9.026,
                9.034,
                9.043,
                9.052,
                9.06,
                9.069,
                9.077,
                9.086,
                9.094,
                9.103,
                9.111,
                9.12,
                9.128,
                9.137,
                9.145,
                9.153,
                9.162,
                9.17,
                9.179,
                9.187,
                9.195,
                9.203,
                9.212,
                9.22,
                9.228,
                9.236,
                9.245,
                9.253,
                9.261,
                9.269,
                9.277,
                9.285,
                9.294,
                9.302,
                9.31,
                9.318,
                9.326,
                9.334,
                9.342,
                9.35,
                9.358,
                9.366,
                9.374,
                9.382,
                9.39,
                9.398,
                9.406,
                9.414,
                9.422,
                9.429,
                9.437,
                9.445,
                9.453,
                9.461,
                9.469,
                9.477,
                9.484,
                9.492,
                9.5,
                9.508,
                9.516,
                9.523,
                9.531,
                9.539,
                9.546,
                9.554,
                9.562,
                9.57,
                9.577,
                9.585,
                9.593,
                9.6,
                9.608,
                9.616,
                9.623,
                9.631,
                9.638,
                9.646,
                9.654,
                9.661,
                9.669,
                9.676,
                9.684,
                9.691,
                9.699,
                9.706,
                9.714,
                9.721,
                9.729,
                9.736,
                9.744,
                9.751,
                9.759,
                9.766,
                9.774,
                9.781,
                9.789,
                9.796,
                9.804,
                9.811,
                9.818,
                9.826,
                9.833,
                9.84,
                9.848,
                9.855,
                9.863,
                9.87,
                9.877,
                9.885,
                9.892,
                9.899,
                9.907,
                9.914,
                9.921,
                9.928,
                9.936,
                9.943,
                9.95,
                9.958,
                9.965,
                9.972,
                9.979,
                9.987,
                9.994,
                10.001,
                10.008,
                10.015,
                10.023,
                10.03,
                10.037,
                10.044,
                10.051,
                10.059,
                10.066,
                10.073,
                10.08,
                10.087,
                10.094,
                10.102,
                10.109,
                10.116,
                10.123,
                10.13,
                10.137,
                10.144,
                10.152,
                10.159,
                10.166,
                10.173,
                10.18,
                10.187,
                10.194,
                10.201,
                10.208,
                10.215,
                10.222,
                10.229,
                10.237,
                10.244,
                10.251,
                10.258,
                10.265,
                10.272,
                10.279,
                10.286,
                10.293,
                10.3,
                10.307,
                10.314,
                10.321,
                10.328,
                10.335,
                10.342,
                10.349,
                10.356,
                10.363,
                10.37,
                10.377,
                10.384,
                10.391,
                10.398,
                10.405,
                10.412,
                10.419,
                10.426,
                10.433,
                10.44,
                10.447,
                10.454,
                10.46,
                10.467,
                10.474,
                10.481,
                10.488,
                10.495,
                10.502,
                10.509,
                10.516,
                10.523,
                10.53,
                10.537,
                10.544,
                10.551,
                10.557,
                10.564,
                10.571,
                10.578,
                10.585,
                10.592,
                10.599,
                10.606,
                10.613,
                10.619,
                10.626,
                10.633,
                10.64,
                10.647,
                10.654,
                10.661,
                10.667,
                10.674,
                10.681,
                10.688,
                10.695,
                10.702,
                10.708,
                10.715,
                10.722,
                10.729,
                10.736,
                10.743,
                10.749,
                10.756,
                10.763,
                10.77,
                10.777,
                10.784,
                10.79,
                10.797,
                10.804,
                10.811,
                10.817,
                10.824,
                10.831,
                10.838,
                10.845,
                10.851,
                10.858,
                10.865,
                10.872,
                10.879,
                10.885,
                10.892,
                10.899,
                10.906,
                10.912,
                10.919,
                10.926,
                10.933,
                10.939,
                10.946,
                10.953,
                10.96,
                10.966,
                10.973,
                10.98,
                10.987,
                10.993,
                11,
                11.007,
                11.013,
                11.02,
                11.027,
                11.034,
                11.04,
                11.047,
                11.054,
                11.061,
                11.067,
                11.074,
                11.081,
                11.087,
                11.094,
                11.101,
                11.107,
                11.114,
                11.121,
                11.128,
                11.134,
                11.141,
                11.148,
                11.154,
                11.161,
                11.168,
                11.174,
                11.181,
                11.188,
                11.194,
                11.201,
                11.208,
                11.214,
                11.221,
                11.228,
                11.235,
                11.241,
                11.248,
                11.255,
                11.261,
                11.268,
                11.275,
                11.281,
                11.288,
                11.295,
                11.301,
                11.308,
                11.315,
                11.321,
                11.328,
                11.335,
                11.341,
                11.348,
                11.355,
                11.361,
                11.368,
                11.375,
                11.381,
                11.388,
                11.395,
                11.401,
                11.408,
                11.414,
                11.421,
                11.428,
                11.434,
                11.441,
                11.448,
                11.454,
                11.461,
                11.468,
                11.474,
                11.481,
                11.488,
                11.494,
                11.501,
                11.508,
                11.514,
                11.521,
                11.527,
                11.534,
                11.541,
                11.547,
                11.554,
                11.561,
                11.567,
                11.574,
                11.581,
                11.587,
                11.594,
                11.601,
                11.607,
                11.614,
                11.62,
                11.627,
                11.634,
                11.64,
                11.647,
                11.654,
                11.66,
                11.667,
                11.673,
                11.68,
                11.687,
                11.693,
                11.7,
                11.707,
                11.713,
                11.72,
                11.726,
                11.733,
                11.74,
                11.746,
                11.753,
                11.76,
                11.766,
                11.773,
                11.779,
                11.786,
                11.793,
                11.799,
                11.806,
                11.812,
                11.819,
                11.826,
                11.832,
                11.839,
                11.845,
                11.852,
                11.859,
                11.865,
                11.872,
                11.878,
                11.885,
                11.892,
                11.898,
                11.905,
                11.911,
                11.918,
                11.925,
                11.931,
                11.938,
                11.944,
                11.951,
                11.958,
                11.964,
                11.971,
                11.977,
                11.984,
                11.991,
                11.997,
                12.004,
                12.01,
                12.017,
                12.023,
                12.03,
                12.037,
                12.043,
                12.05,
                12.056,
                12.063,
                12.069,
                12.076,
                12.083,
                12.089,
                12.096,
                12.102,
                12.109,
                12.115,
                12.122,
                12.129,
                12.135,
                12.142,
                12.148,
                12.155,
                12.161,
                12.168,
                12.174,
                12.181,
                12.188,
                12.194,
                12.201,
                12.207,
                12.214,
                12.22,
                12.227,
                12.233,
                12.24,
                12.246,
                12.253,
                12.259,
                12.266,
                12.273,
                12.279,
                12.286,
                12.292,
                12.299,
                12.305,
                12.312,
                12.318,
                12.325,
                12.331,
                12.338,
                12.344,
                12.351,
                12.357,
                12.364,
                12.37,
                12.377,
                12.383,
                12.39,
                12.396,
                12.403,
                12.409,
                12.415,
                12.422,
                12.428,
                12.435,
                12.441,
                12.448,
                12.454,
                12.461,
                12.467,
                12.474,
                12.48,
                12.486,
                12.493,
                12.499,
                12.506,
                12.512,
                12.519,
                12.525,
                12.531,
                12.538,
                12.544,
                12.551,
                12.557,
                12.563,
                12.57,
                12.576,
                12.583,
                12.589,
                12.595,
                12.602,
                12.608,
                12.615,
                12.621,
                12.627,
                12.634,
                12.64,
                12.646,
                12.653,
                12.659,
                12.665,
                12.672,
                12.678,
                12.684,
                12.691,
                12.697,
                12.703,
                12.71,
                12.716,
                12.722,
                12.729,
                12.735,
                12.741,
                12.748,
                12.754,
                12.76,
                12.767,
                12.773,
                12.779,
                12.785,
                12.792,
                12.798,
                12.804,
                12.81,
                12.817,
                12.823,
                12.829,
                12.836,
                12.842,
                12.848,
                12.854,
                12.86,
                12.867,
                12.873,
                12.879,
                12.885,
                12.892,
                12.898,
                12.904,
                12.91,
                12.916,
                12.923,
                12.929,
                12.935,
                12.941,
                12.947,
                12.954,
                12.96,
                12.966,
                12.972,
                12.978,
                12.984,
                12.991,
                12.997,
                13.003,
                13.009,
                13.015,
                13.021,
                13.027,
                13.033,
                13.04,
                13.046,
                13.052,
                13.058,
                13.064,
                13.07,
                13.076,
                13.082,
                13.088,
                13.095,
                13.101,
                13.107,
                13.113,
                13.119,
                13.125,
                13.131,
                13.137,
                13.143,
                13.149,
                13.155,
                13.161,
                13.167,
                13.173,
                13.179,
                13.185,
                13.191,
                13.197,
                13.203,
                13.21,
                13.216,
                13.222,
                13.228,
                13.234,
                13.24,
                13.246,
                13.252,
                13.258,
                13.263,
                13.269,
                13.275,
                13.281,
                13.287,
                13.293,
                13.299,
                13.305,
                13.311,
                13.317,
                13.323,
                13.329,
                13.335,
                13.341,
                13.347,
                13.353,
                13.359,
                13.365,
                13.371,
                13.377,
                13.382,
                13.388,
                13.394,
                13.4,
                13.406,
                13.412,
                13.418,
                13.424,
                13.43,
                13.435,
                13.441,
                13.447,
                13.453,
                13.459,
                13.465,
                13.471,
                13.477,
                13.482,
                13.488,
                13.494,
                13.5,
                13.506,
                13.512,
                13.518,
                13.523,
                13.529,
                13.535,
                13.541,
                13.547,
                13.552,
                13.558,
                13.564,
                13.57,
                13.576,
                13.582,
                13.587,
                13.593,
                13.599,
                13.605,
                13.611,
                13.616,
                13.622,
                13.628,
                13.634,
                13.639,
                13.645,
                13.651,
                13.657,
                13.663,
                13.668,
                13.674,
                13.68,
                13.686,
                13.691,
                13.697,
                13.703,
                13.709,
                13.714,
                13.72,
                13.726,
                13.731,
                13.737,
                13.743,
                13.749,
                13.754,
                13.76,
                13.766,
                13.772,
                13.777,
                13.783,
                13.789,
                13.794,
                13.8,
                13.806,
                13.811,
                13.817,
                13.823,
                13.829,
                13.834,
                13.84,
                13.846,
                13.851,
                13.857,
                13.863,
                13.868,
                13.874,
                13.88,
                13.885,
                13.891,
                13.897,
                13.902,
                13.908,
                13.914,
                13.919,
                13.925,
                13.931,
                13.936,
                13.942,
                13.947,
                13.953,
                13.959,
                13.964,
                13.97,
                13.976,
                13.981,
                13.987,
                13.993,
                13.998,
                14.004,
                14.009,
                14.015,
                14.021,
                14.026,
                14.032,
                14.038,
                14.043,
                14.049,
                14.054,
                14.06,
                14.066,
                14.071,
                14.077,
                14.082,
                14.088,
                14.094,
                14.099,
                14.105,
                14.11,
                14.116,
                14.122,
                14.127,
                14.133,
                14.138,
                14.144,
                14.15,
                14.155,
                14.161,
                14.166,
                14.172,
                14.178,
                14.183,
                14.189,
                14.194,
                14.2,
                14.205,
                14.211,
                14.217,
                14.222,
                14.228,
                14.233,
                14.239,
                14.244,
                14.25,
                14.255,
                14.261,
                14.267,
                14.272,
                14.278,
                14.283,
                14.289,
                14.294,
                14.3,
                14.306,
                14.311,
                14.317,
                14.322,
                14.328,
                14.333,
                14.339,
                14.344,
                14.35,
                14.355,
                14.361,
                14.367,
                14.372,
                14.378,
                14.383,
                14.389,
                14.394,
                14.4,
                14.405,
                14.411,
                14.416,
                14.422,
                14.427,
                14.433,
                14.438,
                14.444,
                14.45,
                14.455,
                14.461,
                14.466,
                14.472,
                14.477,
                14.483,
                14.488,
                14.494,
                14.499,
                14.505,
                14.51,
                14.516,
                14.521,
                14.527,
                14.532,
                14.538,
                14.543,
                14.549,
                14.554,
                14.56,
                14.565,
                14.571,
                14.576,
                14.582,
                14.588,
                14.593,
                14.599,
                14.604,
                14.61,
                14.615,
                14.621,
                14.626,
                14.632,
                14.637,
                14.643,
                14.648,
                14.654,
                14.659,
                14.665,
                14.67,
                14.676,
                14.681,
                14.687,
                14.692,
                14.698,
                14.703,
                14.709,
                14.714,
                14.72,
                14.725,
                14.731,
                14.736,
                14.742,
                14.747,
                14.753,
                14.758,
                14.764,
                14.769,
                14.775,
                14.78,
                14.786,
                14.791,
                14.797,
                14.802,
                14.808,
                14.813,
                14.819,
                14.824,
                14.83,
                14.835,
                14.841,
                14.846,
                14.852,
                14.857,
                14.863,
                14.868,
                14.874,
                14.879,
                14.885,
                14.89,
                14.896,
                14.901,
                14.907,
                14.912,
                14.918,
                14.923,
                14.929,
                14.934,
                14.94,
                14.945,
                14.951,
                14.956,
                14.962,
                14.967,
                14.973,
                14.978,
                14.984,
                14.989,
                14.995,
                15,
                15.006,
                15.011,
                15.017,
                15.022,
                15.028,
                15.033,
                15.039,
                15.044,
                15.05,
                15.055,
                15.061,
                15.066,
                15.072,
                15.077,
                15.083,
                15.088,
                15.094,
                15.099,
                15.105,
                15.11,
                15.116,
                15.121,
                15.127,
                15.132,
                15.138,
                15.143,
                15.149,
                15.154,
                15.16,
                15.165,
                15.171,
                15.176,
                15.182,
                15.187,
                15.193,
                15.198,
                15.204,
                15.209,
                15.215,
                15.22,
                15.226,
                15.231,
                15.237,
                15.242,
                15.248,
                15.253,
                15.259,
                15.264,
                15.27,
                15.275,
                15.281,
                15.286,
                15.292,
                15.297,
                15.303,
                15.308,
                15.314,
                15.319,
                15.325,
                15.33,
                15.336,
                15.341,
                15.347,
                15.352,
                15.358,
                15.363,
                15.369,
                15.374,
                15.38,
                15.385,
                15.391,
                15.396,
                15.402,
                15.407,
                15.413,
                15.418,
                15.423,
                15.429,
                15.434,
                15.44,
                15.445,
                15.451,
                15.456,
                15.462,
                15.467,
                15.473,
                15.478,
                15.484,
                15.489,
                15.495,
                15.5,
                15.506,
                15.511,
                15.517,
                15.522,
                15.528,
                15.533,
                15.539,
                15.544,
                15.55,
                15.555,
                15.561,
                15.566,
                15.572,
                15.577,
                15.583,
                15.588,
                15.594,
                15.599,
                15.605,
                15.61,
                15.616,
                15.621,
                15.627,
                15.632,
                15.638,
                15.643,
                15.649,
                15.654,
                15.66,
                15.665,
                15.671,
                15.676,
                15.682,
                15.687,
                15.692,
                15.698,
                15.703,
                15.709,
                15.714,
                15.72,
                15.725,
                15.731,
                15.736,
                15.742,
                15.747,
                15.753,
                15.758,
                15.764,
                15.769,
                15.775,
                15.78,
                15.786,
                15.791,
                15.797,
                15.802,
                15.808,
                15.813,
                15.819,
                15.824,
                15.83,
                15.835,
                15.84,
                15.846,
                15.851,
                15.857,
                15.862,
                15.868,
                15.873,
                15.879,
                15.884,
                15.89,
                15.895,
                15.901,
                15.906,
                15.912,
                15.917,
                15.923,
                15.928,
                15.934,
                15.939,
                15.945,
                15.95,
                15.955,
                15.961,
                15.966,
                15.972,
                15.977,
                15.983,
                15.988,
                15.994,
                15.999,
                16.005,
                16.01,
                16.016,
                16.021,
                16.026,
                16.032,
                16.038,
                16.043,
                16.048,
                16.054,
                16.059,
                16.065,
                16.07,
                16.076,
                16.081,
                16.087,
                16.092,
                16.098,
                16.103,
                16.109,
                16.114,
                16.119,
                16.125,
                16.13,
                16.136,
                16.141,
                16.147,
                16.152,
                16.158,
                16.163,
                16.169,
                16.174,
                16.18,
                16.185,
                16.191,
                16.196,
                16.202,
                16.207,
                16.212,
                16.218,
                16.223,
                16.229,
                16.234,
                16.24,
                16.245,
                16.251,
                16.256,
                16.262,
                16.267,
                16.273,
                16.278,
                16.283,
                16.289,
                16.294,
                16.3,
                16.305,
                16.311,
                16.316,
                16.322,
                16.327,
                16.333,
                16.338,
                16.343,
                16.349,
                16.354,
                16.36,
                16.365,
                16.371,
                16.376,
                16.382,
                16.387,
                16.393,
                16.398,
                16.404,
                16.409,
                16.414,
                16.42,
                16.425,
                16.431,
                16.436,
                16.442,
                16.447,
                16.453,
                16.458,
                16.464,
                16.469,
                16.475,
                16.48,
                16.485,
                16.491,
                16.496,
                16.502,
                16.507,
                16.513,
                16.518,
                16.524,
                16.529,
                16.535,
                16.54,
                16.545,
                16.551,
                16.556,
                16.562,
                16.567,
                16.573,
                16.578,
                16.584,
                16.589,
                16.595,
                16.6,
                16.605,
                16.611,
                16.616,
                16.622,
                16.627,
                16.633,
                16.638,
                16.644,
                16.649,
                16.655,
                16.66,
                16.665,
                16.671,
                16.676,
                16.682,
                16.687,
                16.693,
                16.698,
                16.704,
                16.709,
                16.715,
                16.72,
                16.725,
                16.731,
                16.736,
                16.742,
                16.747,
                16.753,
                16.758,
                16.764,
                16.769,
                16.775,
                16.78,
                16.785,
                16.791,
                16.796,
                16.802,
                16.807,
                16.813,
                16.818,
                16.824,
                16.829,
                16.835,
                16.84,
                16.845,
                16.851,
                16.856,
                16.862,
                16.867,
                16.873,
                16.878,
                16.884,
                16.889,
                16.895,
                16.9,
                16.905,
                16.911,
                16.916,
                16.922,
                16.927,
                16.933,
                16.938,
                16.944,
                16.949,
                16.955,
                16.96,
                16.965,
                16.971,
                16.976,
                16.982,
                16.987,
                16.993,
                16.998,
                17.004,
                17.009,
                17.015,
                17.02,
                17.026,
                17.031,
                17.036,
                17.042,
                17.047,
                17.053,
                17.058,
                17.064,
                17.069,
                17.075,
                17.08,
                17.086,
                17.091,
                17.096,
                17.102,
                17.107,
                17.113,
                17.118,
                17.124,
                17.129,
                17.135,
                17.14,
                17.146,
                17.151,
                17.156,
                17.162,
                17.167,
                17.173,
                17.178,
                17.184,
                17.189,
                17.195,
                17.2,
                17.206,
                17.211,
                17.216,
                17.222,
                17.227,
                17.233,
                17.238,
                17.244,
                17.249,
                17.255,
                17.26,
                17.266,
                17.271,
                17.276,
                17.282,
                17.287,
                17.293,
                17.298,
                17.304,
                17.309,
                17.315,
                17.32,
                17.326,
                17.331,
                17.336,
                17.342,
                17.347,
                17.353,
                17.358,
                17.364,
                17.369,
                17.375,
                17.38,
                17.385,
                17.391,
                17.396,
                17.402,
                17.407,
                17.413,
                17.418,
                17.424,
                17.429,
                17.435,
                17.44,
                17.445,
                17.451,
                17.456,
                17.462,
                17.467,
                17.473,
                17.478,
                17.484,
                17.489,
                17.494,
                17.5,
                17.505,
                17.511,
                17.516,
                17.522,
                17.527,
                17.533,
                17.538,
                17.543,
                17.549,
                17.554,
                17.56,
                17.565,
                17.571,
                17.576,
                17.582,
                17.587,
                17.592,
                17.598,
                17.603,
                17.609,
                17.614,
                17.62,
                17.625,
                17.631,
                17.636,
                17.641,
                17.647,
                17.652,
                17.658,
                17.663,
                17.669,
                17.674,
                17.68,
                17.685,
                17.69,
                17.696,
                17.701,
                17.707,
                17.712,
                17.718,
                17.723,
                17.728,
                17.734,
                17.739,
                17.745,
                17.75,
                17.756,
                17.761,
                17.767,
                17.772,
                17.777,
                17.783,
                17.788,
                17.794,
                17.799,
                17.805,
                17.81,
                17.815,
                17.821,
                17.826,
                17.832,
                17.837,
                17.843,
                17.848,
                17.853,
                17.859,
                17.864,
                17.87,
                17.875,
                17.881,
                17.886,
                17.891,
                17.897,
                17.902,
                17.908,
                17.913,
                17.919,
                17.924,
                17.929,
                17.935,
                17.94,
                17.946,
                17.951,
                17.957,
                17.962,
                17.967,
                17.973,
                17.978,
                17.984,
                17.989,
                17.995,
                18,
                18.005,
                18.011,
                18.016,
                18.022,
                18.027,
                18.032,
                18.038,
                18.043,
                18.049,
                18.054,
                18.06,
                18.065,
                18.07,
                18.076,
                18.081,
                18.087,
                18.092,
                18.097,
                18.103,
                18.108,
                18.114,
                18.119,
                18.125,
                18.13,
                18.135,
                18.141,
                18.146,
                18.152,
                18.157,
                18.162,
                18.168,
                18.173,
                18.179,
                18.184,
                18.189,
                18.195,
                18.2,
                18.206,
                18.211,
                18.216,
                18.222,
                18.227,
                18.233,
                18.238,
                18.244,
                18.249,
                18.254,
                18.26,
                18.265,
                18.271,
                18.276,
                18.281,
                18.287,
                18.292,
                18.298,
                18.303,
                18.308,
                18.314,
                18.319,
                18.324,
                18.33,
                18.335,
                18.341,
                18.346,
                18.351,
                18.357,
                18.362,
                18.368,
                18.373,
                18.378,
                18.384,
                18.389,
                18.395,
                18.4,
                18.405,
                18.411,
                18.416,
                18.422,
                18.427,
                18.432,
                18.438,
                18.443,
                18.448,
                18.454,
                18.459,
                18.465,
                18.47,
                18.475,
                18.481,
                18.486,
                18.491,
                18.497

            ],
            P75: [
                3.687,
                3.657,
                3.679,
                3.707,
                3.738,
                3.771,
                3.805,
                3.841,
                3.877,
                3.915,
                3.954,
                3.995,
                4.036,
                4.079,
                4.124,
                4.169,
                4.216,
                4.262,
                4.31,
                4.357,
                4.404,
                4.452,
                4.499,
                4.546,
                4.593,
                4.64,
                4.687,
                4.733,
                4.779,
                4.824,
                4.87,
                4.914,
                4.959,
                5.002,
                5.046,
                5.089,
                5.131,
                5.173,
                5.215,
                5.256,
                5.296,
                5.337,
                5.376,
                5.416,
                5.454,
                5.493,
                5.531,
                5.568,
                5.605,
                5.642,
                5.678,
                5.714,
                5.75,
                5.785,
                5.819,
                5.854,
                5.888,
                5.921,
                5.955,
                5.988,
                6.02,
                6.053,
                6.085,
                6.116,
                6.148,
                6.179,
                6.209,
                6.24,
                6.27,
                6.3,
                6.329,
                6.359,
                6.387,
                6.416,
                6.445,
                6.473,
                6.501,
                6.528,
                6.556,
                6.583,
                6.61,
                6.636,
                6.663,
                6.689,
                6.715,
                6.74,
                6.766,
                6.791,
                6.816,
                6.841,
                6.865,
                6.89,
                6.914,
                6.938,
                6.962,
                6.985,
                7.009,
                7.032,
                7.055,
                7.078,
                7.1,
                7.123,
                7.145,
                7.167,
                7.189,
                7.211,
                7.233,
                7.254,
                7.276,
                7.297,
                7.318,
                7.339,
                7.359,
                7.38,
                7.4,
                7.421,
                7.441,
                7.461,
                7.481,
                7.501,
                7.52,
                7.54,
                7.559,
                7.578,
                7.597,
                7.617,
                7.635,
                7.654,
                7.673,
                7.691,
                7.71,
                7.728,
                7.746,
                7.764,
                7.782,
                7.8,
                7.818,
                7.835,
                7.853,
                7.87,
                7.888,
                7.905,
                7.922,
                7.939,
                7.956,
                7.972,
                7.989,
                8.006,
                8.022,
                8.039,
                8.055,
                8.071,
                8.087,
                8.103,
                8.119,
                8.135,
                8.151,
                8.166,
                8.182,
                8.197,
                8.213,
                8.228,
                8.243,
                8.258,
                8.273,
                8.288,
                8.303,
                8.318,
                8.333,
                8.347,
                8.362,
                8.376,
                8.391,
                8.405,
                8.419,
                8.434,
                8.448,
                8.462,
                8.476,
                8.49,
                8.504,
                8.517,
                8.531,
                8.545,
                8.558,
                8.572,
                8.586,
                8.599,
                8.612,
                8.625,
                8.639,
                8.652,
                8.665,
                8.678,
                8.691,
                8.704,
                8.717,
                8.73,
                8.742,
                8.755,
                8.767,
                8.78,
                8.793,
                8.805,
                8.817,
                8.83,
                8.842,
                8.854,
                8.866,
                8.879,
                8.891,
                8.903,
                8.915,
                8.927,
                8.939,
                8.95,
                8.962,
                8.974,
                8.986,
                8.997,
                9.009,
                9.02,
                9.032,
                9.043,
                9.055,
                9.066,
                9.077,
                9.089,
                9.1,
                9.111,
                9.122,
                9.133,
                9.144,
                9.155,
                9.166,
                9.177,
                9.188,
                9.199,
                9.21,
                9.221,
                9.231,
                9.242,
                9.253,
                9.263,
                9.274,
                9.285,
                9.295,
                9.306,
                9.316,
                9.327,
                9.337,
                9.347,
                9.358,
                9.368,
                9.378,
                9.388,
                9.399,
                9.409,
                9.419,
                9.429,
                9.439,
                9.449,
                9.459,
                9.469,
                9.479,
                9.489,
                9.499,
                9.509,
                9.519,
                9.529,
                9.539,
                9.548,
                9.558,
                9.568,
                9.577,
                9.587,
                9.597,
                9.606,
                9.616,
                9.626,
                9.635,
                9.645,
                9.654,
                9.664,
                9.673,
                9.683,
                9.692,
                9.701,
                9.711,
                9.72,
                9.73,
                9.739,
                9.748,
                9.757,
                9.767,
                9.776,
                9.785,
                9.794,
                9.803,
                9.813,
                9.822,
                9.831,
                9.84,
                9.849,
                9.858,
                9.867,
                9.876,
                9.885,
                9.894,
                9.903,
                9.912,
                9.921,
                9.93,
                9.939,
                9.947,
                9.956,
                9.965,
                9.974,
                9.983,
                9.991,
                10,
                10.009,
                10.018,
                10.027,
                10.035,
                10.044,
                10.053,
                10.061,
                10.07,
                10.079,
                10.087,
                10.096,
                10.104,
                10.113,
                10.122,
                10.13,
                10.139,
                10.147,
                10.156,
                10.164,
                10.173,
                10.181,
                10.19,
                10.198,
                10.207,
                10.215,
                10.224,
                10.232,
                10.24,
                10.249,
                10.257,
                10.266,
                10.274,
                10.282,
                10.291,
                10.299,
                10.307,
                10.316,
                10.324,
                10.332,
                10.341,
                10.349,
                10.357,
                10.365,
                10.374,
                10.382,
                10.39,
                10.398,
                10.406,
                10.415,
                10.423,
                10.431,
                10.439,
                10.447,
                10.455,
                10.464,
                10.472,
                10.48,
                10.488,
                10.496,
                10.504,
                10.512,
                10.52,
                10.528,
                10.536,
                10.544,
                10.553,
                10.561,
                10.569,
                10.577,
                10.585,
                10.593,
                10.601,
                10.609,
                10.617,
                10.625,
                10.633,
                10.641,
                10.648,
                10.657,
                10.664,
                10.672,
                10.68,
                10.688,
                10.696,
                10.704,
                10.712,
                10.72,
                10.728,
                10.736,
                10.743,
                10.751,
                10.759,
                10.767,
                10.775,
                10.783,
                10.791,
                10.798,
                10.806,
                10.814,
                10.822,
                10.83,
                10.837,
                10.845,
                10.853,
                10.861,
                10.869,
                10.876,
                10.884,
                10.892,
                10.9,
                10.907,
                10.915,
                10.923,
                10.931,
                10.938,
                10.946,
                10.954,
                10.962,
                10.969,
                10.977,
                10.985,
                10.992,
                11,
                11.008,
                11.016,
                11.023,
                11.031,
                11.039,
                11.046,
                11.054,
                11.062,
                11.069,
                11.077,
                11.085,
                11.092,
                11.1,
                11.108,
                11.115,
                11.123,
                11.13,
                11.138,
                11.146,
                11.153,
                11.161,
                11.169,
                11.176,
                11.184,
                11.191,
                11.199,
                11.207,
                11.214,
                11.222,
                11.229,
                11.237,
                11.245,
                11.252,
                11.26,
                11.267,
                11.275,
                11.282,
                11.29,
                11.298,
                11.305,
                11.313,
                11.32,
                11.328,
                11.335,
                11.343,
                11.35,
                11.358,
                11.366,
                11.373,
                11.381,
                11.388,
                11.396,
                11.403,
                11.411,
                11.418,
                11.426,
                11.433,
                11.441,
                11.448,
                11.456,
                11.463,
                11.471,
                11.478,
                11.486,
                11.493,
                11.501,
                11.508,
                11.516,
                11.523,
                11.531,
                11.538,
                11.545,
                11.553,
                11.56,
                11.568,
                11.575,
                11.583,
                11.59,
                11.598,
                11.605,
                11.613,
                11.62,
                11.628,
                11.635,
                11.642,
                11.65,
                11.657,
                11.665,
                11.672,
                11.679,
                11.687,
                11.694,
                11.702,
                11.709,
                11.717,
                11.724,
                11.731,
                11.739,
                11.746,
                11.754,
                11.761,
                11.768,
                11.776,
                11.783,
                11.791,
                11.798,
                11.805,
                11.813,
                11.82,
                11.828,
                11.835,
                11.842,
                11.85,
                11.857,
                11.864,
                11.872,
                11.879,
                11.887,
                11.894,
                11.901,
                11.909,
                11.916,
                11.923,
                11.931,
                11.938,
                11.945,
                11.953,
                11.96,
                11.968,
                11.975,
                11.982,
                11.99,
                11.997,
                12.004,
                12.012,
                12.019,
                12.026,
                12.034,
                12.041,
                12.048,
                12.056,
                12.063,
                12.07,
                12.078,
                12.085,
                12.092,
                12.1,
                12.107,
                12.114,
                12.122,
                12.129,
                12.136,
                12.144,
                12.151,
                12.158,
                12.166,
                12.173,
                12.18,
                12.188,
                12.195,
                12.202,
                12.21,
                12.217,
                12.224,
                12.232,
                12.239,
                12.246,
                12.254,
                12.261,
                12.268,
                12.276,
                12.283,
                12.29,
                12.297,
                12.305,
                12.312,
                12.319,
                12.327,
                12.334,
                12.341,
                12.349,
                12.356,
                12.363,
                12.371,
                12.378,
                12.385,
                12.393,
                12.4,
                12.407,
                12.415,
                12.422,
                12.429,
                12.437,
                12.444,
                12.451,
                12.459,
                12.466,
                12.473,
                12.48,
                12.488,
                12.495,
                12.502,
                12.51,
                12.517,
                12.524,
                12.532,
                12.539,
                12.546,
                12.553,
                12.561,
                12.568,
                12.575,
                12.583,
                12.59,
                12.597,
                12.605,
                12.612,
                12.619,
                12.627,
                12.634,
                12.641,
                12.649,
                12.656,
                12.663,
                12.67,
                12.678,
                12.685,
                12.692,
                12.7,
                12.707,
                12.714,
                12.722,
                12.729,
                12.736,
                12.743,
                12.751,
                12.758,
                12.765,
                12.773,
                12.78,
                12.787,
                12.794,
                12.802,
                12.809,
                12.816,
                12.824,
                12.831,
                12.838,
                12.845,
                12.853,
                12.86,
                12.867,
                12.875,
                12.882,
                12.889,
                12.896,
                12.904,
                12.911,
                12.918,
                12.926,
                12.933,
                12.94,
                12.947,
                12.955,
                12.962,
                12.969,
                12.977,
                12.984,
                12.991,
                12.998,
                13.006,
                13.013,
                13.02,
                13.028,
                13.035,
                13.042,
                13.049,
                13.056,
                13.064,
                13.071,
                13.078,
                13.086,
                13.093,
                13.1,
                13.107,
                13.115,
                13.122,
                13.129,
                13.136,
                13.144,
                13.151,
                13.158,
                13.165,
                13.173,
                13.18,
                13.187,
                13.194,
                13.202,
                13.209,
                13.216,
                13.223,
                13.231,
                13.238,
                13.245,
                13.252,
                13.26,
                13.267,
                13.274,
                13.281,
                13.288,
                13.296,
                13.303,
                13.31,
                13.317,
                13.324,
                13.332,
                13.339,
                13.346,
                13.353,
                13.361,
                13.368,
                13.375,
                13.382,
                13.389,
                13.396,
                13.404,
                13.411,
                13.418,
                13.425,
                13.432,
                13.439,
                13.447,
                13.454,
                13.461,
                13.468,
                13.475,
                13.482,
                13.49,
                13.497,
                13.504,
                13.511,
                13.518,
                13.525,
                13.533,
                13.54,
                13.547,
                13.554,
                13.561,
                13.568,
                13.575,
                13.582,
                13.589,
                13.596,
                13.604,
                13.611,
                13.618,
                13.625,
                13.632,
                13.639,
                13.646,
                13.653,
                13.66,
                13.667,
                13.674,
                13.681,
                13.689,
                13.696,
                13.703,
                13.71,
                13.717,
                13.724,
                13.731,
                13.738,
                13.745,
                13.752,
                13.759,
                13.766,
                13.773,
                13.78,
                13.787,
                13.794,
                13.801,
                13.808,
                13.815,
                13.822,
                13.829,
                13.836,
                13.843,
                13.85,
                13.857,
                13.864,
                13.871,
                13.878,
                13.885,
                13.892,
                13.899,
                13.905,
                13.912,
                13.919,
                13.926,
                13.933,
                13.94,
                13.947,
                13.954,
                13.961,
                13.968,
                13.975,
                13.981,
                13.988,
                13.995,
                14.002,
                14.009,
                14.016,
                14.023,
                14.03,
                14.037,
                14.043,
                14.05,
                14.057,
                14.064,
                14.071,
                14.078,
                14.084,
                14.091,
                14.098,
                14.105,
                14.112,
                14.119,
                14.125,
                14.132,
                14.139,
                14.146,
                14.153,
                14.159,
                14.166,
                14.173,
                14.18,
                14.186,
                14.193,
                14.2,
                14.207,
                14.214,
                14.22,
                14.227,
                14.234,
                14.241,
                14.247,
                14.254,
                14.261,
                14.267,
                14.274,
                14.281,
                14.288,
                14.294,
                14.301,
                14.308,
                14.314,
                14.321,
                14.328,
                14.335,
                14.341,
                14.348,
                14.354,
                14.361,
                14.368,
                14.374,
                14.381,
                14.388,
                14.394,
                14.401,
                14.408,
                14.414,
                14.421,
                14.428,
                14.434,
                14.441,
                14.448,
                14.454,
                14.461,
                14.467,
                14.474,
                14.481,
                14.487,
                14.494,
                14.5,
                14.507,
                14.514,
                14.52,
                14.527,
                14.533,
                14.54,
                14.547,
                14.553,
                14.56,
                14.566,
                14.573,
                14.579,
                14.586,
                14.592,
                14.599,
                14.605,
                14.612,
                14.618,
                14.625,
                14.632,
                14.638,
                14.645,
                14.651,
                14.658,
                14.664,
                14.671,
                14.677,
                14.684,
                14.69,
                14.697,
                14.703,
                14.71,
                14.716,
                14.723,
                14.729,
                14.736,
                14.742,
                14.749,
                14.755,
                14.761,
                14.768,
                14.774,
                14.781,
                14.787,
                14.794,
                14.8,
                14.807,
                14.813,
                14.819,
                14.826,
                14.832,
                14.839,
                14.845,
                14.852,
                14.858,
                14.864,
                14.871,
                14.877,
                14.884,
                14.89,
                14.896,
                14.903,
                14.909,
                14.916,
                14.922,
                14.928,
                14.935,
                14.941,
                14.948,
                14.954,
                14.96,
                14.967,
                14.973,
                14.979,
                14.986,
                14.992,
                14.998,
                15.005,
                15.011,
                15.018,
                15.024,
                15.03,
                15.037,
                15.043,
                15.049,
                15.056,
                15.062,
                15.068,
                15.075,
                15.081,
                15.087,
                15.094,
                15.1,
                15.106,
                15.113,
                15.119,
                15.125,
                15.132,
                15.138,
                15.144,
                15.151,
                15.157,
                15.163,
                15.169,
                15.176,
                15.182,
                15.188,
                15.195,
                15.201,
                15.207,
                15.213,
                15.22,
                15.226,
                15.232,
                15.239,
                15.245,
                15.251,
                15.257,
                15.264,
                15.27,
                15.276,
                15.282,
                15.289,
                15.295,
                15.301,
                15.308,
                15.314,
                15.32,
                15.326,
                15.333,
                15.339,
                15.345,
                15.351,
                15.358,
                15.364,
                15.37,
                15.376,
                15.383,
                15.389,
                15.395,
                15.401,
                15.408,
                15.414,
                15.42,
                15.426,
                15.433,
                15.439,
                15.445,
                15.451,
                15.457,
                15.464,
                15.47,
                15.476,
                15.482,
                15.489,
                15.495,
                15.501,
                15.507,
                15.514,
                15.52,
                15.526,
                15.532,
                15.538,
                15.544,
                15.551,
                15.557,
                15.563,
                15.569,
                15.576,
                15.582,
                15.588,
                15.594,
                15.6,
                15.607,
                15.613,
                15.619,
                15.625,
                15.631,
                15.638,
                15.644,
                15.65,
                15.656,
                15.662,
                15.669,
                15.675,
                15.681,
                15.687,
                15.693,
                15.7,
                15.706,
                15.712,
                15.718,
                15.724,
                15.73,
                15.737,
                15.743,
                15.749,
                15.755,
                15.761,
                15.768,
                15.774,
                15.78,
                15.786,
                15.792,
                15.799,
                15.805,
                15.811,
                15.817,
                15.823,
                15.829,
                15.836,
                15.842,
                15.848,
                15.854,
                15.86,
                15.866,
                15.873,
                15.879,
                15.885,
                15.891,
                15.897,
                15.904,
                15.91,
                15.916,
                15.922,
                15.928,
                15.934,
                15.941,
                15.947,
                15.953,
                15.959,
                15.965,
                15.972,
                15.978,
                15.984,
                15.99,
                15.996,
                16.002,
                16.008,
                16.015,
                16.021,
                16.027,
                16.033,
                16.039,
                16.045,
                16.052,
                16.058,
                16.064,
                16.07,
                16.076,
                16.082,
                16.089,
                16.095,
                16.101,
                16.107,
                16.113,
                16.119,
                16.126,
                16.132,
                16.138,
                16.144,
                16.15,
                16.156,
                16.163,
                16.169,
                16.175,
                16.181,
                16.187,
                16.193,
                16.2,
                16.206,
                16.212,
                16.218,
                16.224,
                16.23,
                16.237,
                16.243,
                16.249,
                16.255,
                16.261,
                16.267,
                16.274,
                16.28,
                16.286,
                16.292,
                16.298,
                16.304,
                16.311,
                16.317,
                16.323,
                16.329,
                16.335,
                16.341,
                16.348,
                16.354,
                16.36,
                16.366,
                16.372,
                16.379,
                16.385,
                16.391,
                16.397,
                16.403,
                16.409,
                16.416,
                16.422,
                16.428,
                16.434,
                16.44,
                16.446,
                16.453,
                16.459,
                16.465,
                16.471,
                16.477,
                16.483,
                16.49,
                16.496,
                16.502,
                16.508,
                16.514,
                16.52,
                16.527,
                16.533,
                16.539,
                16.545,
                16.551,
                16.558,
                16.564,
                16.57,
                16.576,
                16.582,
                16.588,
                16.595,
                16.601,
                16.607,
                16.613,
                16.619,
                16.626,
                16.632,
                16.638,
                16.644,
                16.65,
                16.656,
                16.662,
                16.669,
                16.675,
                16.681,
                16.687,
                16.693,
                16.7,
                16.706,
                16.712,
                16.718,
                16.724,
                16.731,
                16.737,
                16.743,
                16.749,
                16.755,
                16.761,
                16.768,
                16.774,
                16.78,
                16.786,
                16.792,
                16.798,
                16.805,
                16.811,
                16.817,
                16.823,
                16.829,
                16.836,
                16.842,
                16.848,
                16.854,
                16.86,
                16.866,
                16.873,
                16.879,
                16.885,
                16.891,
                16.897,
                16.904,
                16.91,
                16.916,
                16.922,
                16.928,
                16.934,
                16.941,
                16.947,
                16.953,
                16.959,
                16.965,
                16.972,
                16.978,
                16.984,
                16.99,
                16.996,
                17.002,
                17.009,
                17.015,
                17.021,
                17.027,
                17.033,
                17.04,
                17.046,
                17.052,
                17.058,
                17.064,
                17.071,
                17.077,
                17.083,
                17.089,
                17.095,
                17.102,
                17.108,
                17.114,
                17.12,
                17.126,
                17.132,
                17.139,
                17.145,
                17.151,
                17.157,
                17.163,
                17.17,
                17.176,
                17.182,
                17.188,
                17.194,
                17.201,
                17.207,
                17.213,
                17.219,
                17.225,
                17.231,
                17.238,
                17.244,
                17.25,
                17.256,
                17.262,
                17.269,
                17.275,
                17.281,
                17.287,
                17.293,
                17.299,
                17.306,
                17.312,
                17.318,
                17.324,
                17.33,
                17.337,
                17.343,
                17.349,
                17.355,
                17.361,
                17.368,
                17.374,
                17.38,
                17.386,
                17.392,
                17.398,
                17.405,
                17.411,
                17.417,
                17.423,
                17.429,
                17.436,
                17.442,
                17.448,
                17.454,
                17.46,
                17.467,
                17.473,
                17.479,
                17.485,
                17.491,
                17.497,
                17.504,
                17.51,
                17.516,
                17.522,
                17.528,
                17.535,
                17.541,
                17.547,
                17.553,
                17.559,
                17.566,
                17.572,
                17.578,
                17.584,
                17.59,
                17.597,
                17.603,
                17.609,
                17.615,
                17.621,
                17.628,
                17.634,
                17.64,
                17.646,
                17.652,
                17.659,
                17.665,
                17.671,
                17.677,
                17.683,
                17.69,
                17.696,
                17.702,
                17.708,
                17.714,
                17.72,
                17.727,
                17.733,
                17.739,
                17.745,
                17.751,
                17.758,
                17.764,
                17.77,
                17.776,
                17.782,
                17.789,
                17.795,
                17.801,
                17.807,
                17.813,
                17.82,
                17.826,
                17.832,
                17.838,
                17.844,
                17.851,
                17.857,
                17.863,
                17.869,
                17.875,
                17.882,
                17.888,
                17.894,
                17.9,
                17.906,
                17.913,
                17.919,
                17.925,
                17.931,
                17.937,
                17.944,
                17.95,
                17.956,
                17.962,
                17.968,
                17.974,
                17.981,
                17.987,
                17.993,
                17.999,
                18.005,
                18.012,
                18.018,
                18.024,
                18.03,
                18.037,
                18.043,
                18.049,
                18.055,
                18.061,
                18.068,
                18.074,
                18.08,
                18.086,
                18.092,
                18.099,
                18.105,
                18.111,
                18.117,
                18.123,
                18.13,
                18.136,
                18.142,
                18.148,
                18.155,
                18.161,
                18.167,
                18.173,
                18.179,
                18.186,
                18.192,
                18.198,
                18.204,
                18.21,
                18.217,
                18.223,
                18.229,
                18.235,
                18.241,
                18.248,
                18.254,
                18.26,
                18.266,
                18.273,
                18.279,
                18.285,
                18.291,
                18.297,
                18.304,
                18.31,
                18.316,
                18.322,
                18.328,
                18.335,
                18.341,
                18.347,
                18.353,
                18.36,
                18.366,
                18.372,
                18.378,
                18.384,
                18.391,
                18.397,
                18.403,
                18.409,
                18.415,
                18.422,
                18.428,
                18.434,
                18.44,
                18.447,
                18.453,
                18.459,
                18.465,
                18.472,
                18.478,
                18.484,
                18.49,
                18.497,
                18.503,
                18.509,
                18.515,
                18.521,
                18.528,
                18.534,
                18.54,
                18.546,
                18.553,
                18.559,
                18.565,
                18.571,
                18.577,
                18.584,
                18.59,
                18.596,
                18.602,
                18.609,
                18.615,
                18.621,
                18.627,
                18.634,
                18.64,
                18.646,
                18.652,
                18.658,
                18.665,
                18.671,
                18.677,
                18.683,
                18.69,
                18.696,
                18.702,
                18.708,
                18.715,
                18.721,
                18.727,
                18.733,
                18.739,
                18.746,
                18.752,
                18.758,
                18.764,
                18.771,
                18.777,
                18.783,
                18.789,
                18.796,
                18.802,
                18.808,
                18.814,
                18.82,
                18.827,
                18.833,
                18.839,
                18.845,
                18.852,
                18.858,
                18.864,
                18.87,
                18.877,
                18.883,
                18.889,
                18.895,
                18.902,
                18.908,
                18.914,
                18.92,
                18.927,
                18.933,
                18.939,
                18.945,
                18.952,
                18.958,
                18.964,
                18.97,
                18.976,
                18.983,
                18.989,
                18.995,
                19.001,
                19.008,
                19.014,
                19.02,
                19.026,
                19.033,
                19.039,
                19.045,
                19.051,
                19.058,
                19.064,
                19.07,
                19.076,
                19.082,
                19.089,
                19.095,
                19.101,
                19.108,
                19.114,
                19.12,
                19.126,
                19.132,
                19.139,
                19.145,
                19.151,
                19.157,
                19.164,
                19.17,
                19.176,
                19.182,
                19.189,
                19.195,
                19.201,
                19.207,
                19.214,
                19.22,
                19.226,
                19.232,
                19.239,
                19.245,
                19.251,
                19.257,
                19.263,
                19.27,
                19.276,
                19.282,
                19.288,
                19.295,
                19.301,
                19.307,
                19.313,
                19.32,
                19.326,
                19.332,
                19.338,
                19.345,
                19.351,
                19.357,
                19.363,
                19.369,
                19.376,
                19.382,
                19.388,
                19.395,
                19.401,
                19.407,
                19.413,
                19.419,
                19.426,
                19.432,
                19.438,
                19.444,
                19.451,
                19.457,
                19.463,
                19.469,
                19.476,
                19.482,
                19.488,
                19.494,
                19.501,
                19.507,
                19.513,
                19.519,
                19.525,
                19.532,
                19.538,
                19.544,
                19.55,
                19.557,
                19.563,
                19.569,
                19.575,
                19.582,
                19.588,
                19.594,
                19.6,
                19.607,
                19.613,
                19.619,
                19.625,
                19.631,
                19.638,
                19.644,
                19.65,
                19.656,
                19.663,
                19.669,
                19.675,
                19.681,
                19.688,
                19.694,
                19.7,
                19.706,
                19.712,
                19.719,
                19.725,
                19.731,
                19.737,
                19.744,
                19.75,
                19.756,
                19.762,
                19.768,
                19.775,
                19.781,
                19.787,
                19.793,
                19.8,
                19.806,
                19.812,
                19.818,
                19.824,
                19.831,
                19.837,
                19.843,
                19.849,
                19.856,
                19.862,
                19.868,
                19.874,
                19.88,
                19.887,
                19.893,
                19.899,
                19.906,
                19.912,
                19.918,
                19.924,
                19.93,
                19.936,
                19.943,
                19.949,
                19.955,
                19.961,
                19.968,
                19.974,
                19.98,
                19.986,
                19.993,
                19.999,
                20.005,
                20.011,
                20.017,
                20.024,
                20.03,
                20.036,
                20.042,
                20.048,
                20.055,
                20.061,
                20.067,
                20.073,
                20.079,
                20.086,
                20.092,
                20.098,
                20.104,
                20.111,
                20.117,
                20.123,
                20.129,
                20.135,
                20.142,
                20.148,
                20.154,
                20.16,
                20.166,
                20.173,
                20.179,
                20.185,
                20.191,
                20.197,
                20.203,
                20.21,
                20.216,
                20.222,
                20.228,
                20.235,
                20.241,
                20.247,
                20.253,
                20.259,
                20.266,
                20.272,
                20.278,
                20.284

            ],
            P85: [
                3.878,
                3.85,
                3.872,
                3.901,
                3.933,
                3.968,
                4.003,
                4.04,
                4.078,
                4.118,
                4.158,
                4.2,
                4.243,
                4.288,
                4.334,
                4.381,
                4.429,
                4.477,
                4.526,
                4.575,
                4.624,
                4.674,
                4.722,
                4.771,
                4.82,
                4.868,
                4.916,
                4.964,
                5.012,
                5.059,
                5.105,
                5.152,
                5.197,
                5.243,
                5.288,
                5.332,
                5.376,
                5.419,
                5.462,
                5.504,
                5.546,
                5.587,
                5.628,
                5.669,
                5.709,
                5.748,
                5.787,
                5.826,
                5.864,
                5.902,
                5.939,
                5.976,
                6.013,
                6.049,
                6.085,
                6.12,
                6.155,
                6.189,
                6.224,
                6.257,
                6.291,
                6.324,
                6.357,
                6.39,
                6.422,
                6.454,
                6.485,
                6.516,
                6.547,
                6.578,
                6.608,
                6.638,
                6.668,
                6.698,
                6.727,
                6.756,
                6.784,
                6.813,
                6.841,
                6.868,
                6.896,
                6.923,
                6.95,
                6.977,
                7.004,
                7.03,
                7.056,
                7.082,
                7.108,
                7.133,
                7.158,
                7.183,
                7.208,
                7.233,
                7.257,
                7.281,
                7.305,
                7.329,
                7.353,
                7.376,
                7.399,
                7.423,
                7.445,
                7.468,
                7.491,
                7.513,
                7.535,
                7.557,
                7.579,
                7.601,
                7.623,
                7.644,
                7.665,
                7.686,
                7.707,
                7.728,
                7.749,
                7.769,
                7.79,
                7.81,
                7.83,
                7.85,
                7.87,
                7.89,
                7.91,
                7.929,
                7.949,
                7.968,
                7.987,
                8.006,
                8.025,
                8.044,
                8.063,
                8.081,
                8.1,
                8.118,
                8.136,
                8.154,
                8.172,
                8.19,
                8.208,
                8.226,
                8.243,
                8.261,
                8.278,
                8.296,
                8.313,
                8.33,
                8.347,
                8.364,
                8.38,
                8.397,
                8.414,
                8.43,
                8.447,
                8.463,
                8.479,
                8.495,
                8.511,
                8.527,
                8.543,
                8.559,
                8.575,
                8.59,
                8.606,
                8.621,
                8.636,
                8.652,
                8.667,
                8.682,
                8.697,
                8.712,
                8.727,
                8.742,
                8.757,
                8.771,
                8.786,
                8.801,
                8.815,
                8.829,
                8.844,
                8.858,
                8.872,
                8.887,
                8.9,
                8.914,
                8.929,
                8.942,
                8.956,
                8.97,
                8.984,
                8.997,
                9.011,
                9.024,
                9.038,
                9.051,
                9.064,
                9.078,
                9.091,
                9.104,
                9.117,
                9.13,
                9.143,
                9.156,
                9.169,
                9.182,
                9.194,
                9.207,
                9.22,
                9.232,
                9.245,
                9.257,
                9.27,
                9.282,
                9.294,
                9.307,
                9.319,
                9.331,
                9.343,
                9.355,
                9.367,
                9.379,
                9.391,
                9.403,
                9.415,
                9.427,
                9.439,
                9.45,
                9.462,
                9.474,
                9.485,
                9.497,
                9.508,
                9.52,
                9.531,
                9.542,
                9.554,
                9.565,
                9.576,
                9.588,
                9.599,
                9.61,
                9.621,
                9.632,
                9.643,
                9.654,
                9.665,
                9.676,
                9.687,
                9.698,
                9.708,
                9.719,
                9.73,
                9.741,
                9.751,
                9.762,
                9.773,
                9.783,
                9.794,
                9.804,
                9.815,
                9.825,
                9.836,
                9.846,
                9.856,
                9.867,
                9.877,
                9.888,
                9.898,
                9.908,
                9.918,
                9.928,
                9.939,
                9.949,
                9.959,
                9.969,
                9.979,
                9.989,
                9.999,
                10.009,
                10.019,
                10.029,
                10.039,
                10.049,
                10.059,
                10.069,
                10.078,
                10.088,
                10.098,
                10.108,
                10.117,
                10.127,
                10.137,
                10.146,
                10.156,
                10.166,
                10.175,
                10.185,
                10.194,
                10.204,
                10.213,
                10.223,
                10.232,
                10.242,
                10.251,
                10.261,
                10.27,
                10.279,
                10.289,
                10.298,
                10.307,
                10.317,
                10.326,
                10.335,
                10.345,
                10.354,
                10.363,
                10.372,
                10.381,
                10.39,
                10.4,
                10.409,
                10.418,
                10.427,
                10.436,
                10.445,
                10.454,
                10.463,
                10.472,
                10.481,
                10.49,
                10.499,
                10.508,
                10.517,
                10.526,
                10.535,
                10.544,
                10.553,
                10.562,
                10.571,
                10.58,
                10.589,
                10.597,
                10.606,
                10.615,
                10.624,
                10.633,
                10.642,
                10.65,
                10.659,
                10.668,
                10.677,
                10.685,
                10.694,
                10.703,
                10.712,
                10.72,
                10.729,
                10.738,
                10.746,
                10.755,
                10.763,
                10.772,
                10.781,
                10.789,
                10.798,
                10.807,
                10.815,
                10.824,
                10.832,
                10.841,
                10.85,
                10.858,
                10.866,
                10.875,
                10.884,
                10.892,
                10.9,
                10.909,
                10.918,
                10.926,
                10.934,
                10.943,
                10.951,
                10.96,
                10.968,
                10.977,
                10.985,
                10.993,
                11.002,
                11.01,
                11.018,
                11.027,
                11.035,
                11.044,
                11.052,
                11.06,
                11.068,
                11.077,
                11.085,
                11.093,
                11.102,
                11.11,
                11.118,
                11.127,
                11.135,
                11.143,
                11.151,
                11.16,
                11.168,
                11.176,
                11.184,
                11.192,
                11.201,
                11.209,
                11.217,
                11.225,
                11.234,
                11.242,
                11.25,
                11.258,
                11.266,
                11.274,
                11.283,
                11.291,
                11.299,
                11.307,
                11.315,
                11.323,
                11.331,
                11.34,
                11.348,
                11.356,
                11.364,
                11.372,
                11.38,
                11.388,
                11.396,
                11.404,
                11.413,
                11.421,
                11.429,
                11.437,
                11.445,
                11.453,
                11.461,
                11.469,
                11.477,
                11.485,
                11.493,
                11.501,
                11.509,
                11.517,
                11.525,
                11.533,
                11.541,
                11.549,
                11.557,
                11.565,
                11.573,
                11.581,
                11.589,
                11.597,
                11.605,
                11.613,
                11.621,
                11.629,
                11.637,
                11.645,
                11.653,
                11.661,
                11.669,
                11.677,
                11.685,
                11.693,
                11.701,
                11.709,
                11.717,
                11.725,
                11.733,
                11.74,
                11.748,
                11.756,
                11.764,
                11.772,
                11.78,
                11.788,
                11.796,
                11.804,
                11.812,
                11.82,
                11.828,
                11.836,
                11.843,
                11.851,
                11.859,
                11.867,
                11.875,
                11.883,
                11.891,
                11.899,
                11.906,
                11.914,
                11.922,
                11.93,
                11.938,
                11.946,
                11.954,
                11.962,
                11.969,
                11.977,
                11.985,
                11.993,
                12.001,
                12.009,
                12.016,
                12.024,
                12.032,
                12.04,
                12.048,
                12.056,
                12.063,
                12.071,
                12.079,
                12.087,
                12.095,
                12.103,
                12.11,
                12.118,
                12.126,
                12.134,
                12.141,
                12.149,
                12.157,
                12.165,
                12.173,
                12.181,
                12.188,
                12.196,
                12.204,
                12.212,
                12.219,
                12.227,
                12.235,
                12.243,
                12.251,
                12.258,
                12.266,
                12.274,
                12.282,
                12.289,
                12.297,
                12.305,
                12.313,
                12.32,
                12.328,
                12.336,
                12.344,
                12.351,
                12.359,
                12.367,
                12.375,
                12.382,
                12.39,
                12.398,
                12.406,
                12.413,
                12.421,
                12.429,
                12.437,
                12.444,
                12.452,
                12.46,
                12.468,
                12.475,
                12.483,
                12.491,
                12.498,
                12.506,
                12.514,
                12.522,
                12.529,
                12.537,
                12.545,
                12.552,
                12.56,
                12.568,
                12.576,
                12.583,
                12.591,
                12.599,
                12.607,
                12.614,
                12.622,
                12.63,
                12.637,
                12.645,
                12.653,
                12.661,
                12.668,
                12.676,
                12.684,
                12.691,
                12.699,
                12.707,
                12.715,
                12.722,
                12.73,
                12.738,
                12.745,
                12.753,
                12.761,
                12.768,
                12.776,
                12.784,
                12.792,
                12.799,
                12.807,
                12.815,
                12.822,
                12.83,
                12.838,
                12.846,
                12.853,
                12.861,
                12.869,
                12.876,
                12.884,
                12.892,
                12.9,
                12.907,
                12.915,
                12.923,
                12.93,
                12.938,
                12.946,
                12.953,
                12.961,
                12.969,
                12.977,
                12.984,
                12.992,
                13,
                13.007,
                13.015,
                13.023,
                13.031,
                13.038,
                13.046,
                13.054,
                13.061,
                13.069,
                13.077,
                13.085,
                13.092,
                13.1,
                13.108,
                13.115,
                13.123,
                13.131,
                13.138,
                13.146,
                13.154,
                13.162,
                13.169,
                13.177,
                13.185,
                13.192,
                13.2,
                13.208,
                13.215,
                13.223,
                13.231,
                13.239,
                13.246,
                13.254,
                13.262,
                13.269,
                13.277,
                13.285,
                13.292,
                13.3,
                13.308,
                13.316,
                13.323,
                13.331,
                13.339,
                13.346,
                13.354,
                13.362,
                13.369,
                13.377,
                13.385,
                13.392,
                13.4,
                13.408,
                13.416,
                13.423,
                13.431,
                13.439,
                13.446,
                13.454,
                13.462,
                13.469,
                13.477,
                13.485,
                13.492,
                13.5,
                13.508,
                13.516,
                13.523,
                13.531,
                13.538,
                13.546,
                13.554,
                13.562,
                13.569,
                13.577,
                13.585,
                13.592,
                13.6,
                13.608,
                13.615,
                13.623,
                13.631,
                13.638,
                13.646,
                13.654,
                13.661,
                13.669,
                13.677,
                13.684,
                13.692,
                13.7,
                13.707,
                13.715,
                13.723,
                13.73,
                13.738,
                13.746,
                13.753,
                13.761,
                13.769,
                13.776,
                13.784,
                13.792,
                13.799,
                13.807,
                13.815,
                13.822,
                13.83,
                13.837,
                13.845,
                13.853,
                13.86,
                13.868,
                13.876,
                13.883,
                13.891,
                13.898,
                13.906,
                13.914,
                13.921,
                13.929,
                13.937,
                13.944,
                13.952,
                13.959,
                13.967,
                13.974,
                13.982,
                13.99,
                13.997,
                14.005,
                14.012,
                14.02,
                14.028,
                14.035,
                14.043,
                14.05,
                14.058,
                14.065,
                14.073,
                14.081,
                14.088,
                14.096,
                14.103,
                14.111,
                14.118,
                14.126,
                14.133,
                14.141,
                14.148,
                14.156,
                14.163,
                14.171,
                14.178,
                14.186,
                14.193,
                14.201,
                14.209,
                14.216,
                14.223,
                14.231,
                14.239,
                14.246,
                14.254,
                14.261,
                14.268,
                14.276,
                14.283,
                14.291,
                14.298,
                14.306,
                14.313,
                14.321,
                14.328,
                14.336,
                14.343,
                14.351,
                14.358,
                14.365,
                14.373,
                14.38,
                14.387,
                14.395,
                14.402,
                14.41,
                14.417,
                14.425,
                14.432,
                14.439,
                14.447,
                14.454,
                14.462,
                14.469,
                14.476,
                14.484,
                14.491,
                14.498,
                14.506,
                14.513,
                14.52,
                14.528,
                14.535,
                14.542,
                14.55,
                14.557,
                14.564,
                14.572,
                14.579,
                14.586,
                14.594,
                14.601,
                14.608,
                14.615,
                14.623,
                14.63,
                14.637,
                14.645,
                14.652,
                14.659,
                14.666,
                14.674,
                14.681,
                14.688,
                14.695,
                14.703,
                14.71,
                14.717,
                14.724,
                14.732,
                14.739,
                14.746,
                14.753,
                14.76,
                14.768,
                14.775,
                14.782,
                14.789,
                14.796,
                14.804,
                14.811,
                14.818,
                14.825,
                14.832,
                14.839,
                14.847,
                14.854,
                14.861,
                14.868,
                14.875,
                14.882,
                14.889,
                14.897,
                14.904,
                14.911,
                14.918,
                14.925,
                14.932,
                14.939,
                14.946,
                14.953,
                14.961,
                14.968,
                14.975,
                14.982,
                14.989,
                14.996,
                15.003,
                15.01,
                15.017,
                15.024,
                15.031,
                15.038,
                15.045,
                15.052,
                15.059,
                15.066,
                15.073,
                15.08,
                15.088,
                15.095,
                15.102,
                15.109,
                15.116,
                15.123,
                15.13,
                15.137,
                15.144,
                15.151,
                15.158,
                15.165,
                15.172,
                15.179,
                15.186,
                15.192,
                15.199,
                15.206,
                15.213,
                15.22,
                15.227,
                15.234,
                15.241,
                15.248,
                15.255,
                15.262,
                15.269,
                15.276,
                15.283,
                15.29,
                15.297,
                15.304,
                15.311,
                15.317,
                15.324,
                15.331,
                15.338,
                15.345,
                15.352,
                15.359,
                15.366,
                15.373,
                15.379,
                15.386,
                15.393,
                15.4,
                15.407,
                15.414,
                15.421,
                15.427,
                15.434,
                15.441,
                15.448,
                15.455,
                15.462,
                15.469,
                15.475,
                15.482,
                15.489,
                15.496,
                15.503,
                15.509,
                15.516,
                15.523,
                15.53,
                15.537,
                15.543,
                15.55,
                15.557,
                15.564,
                15.571,
                15.578,
                15.584,
                15.591,
                15.598,
                15.605,
                15.611,
                15.618,
                15.625,
                15.632,
                15.639,
                15.645,
                15.652,
                15.659,
                15.666,
                15.672,
                15.679,
                15.686,
                15.693,
                15.699,
                15.706,
                15.713,
                15.72,
                15.726,
                15.733,
                15.74,
                15.747,
                15.753,
                15.76,
                15.767,
                15.773,
                15.78,
                15.787,
                15.793,
                15.8,
                15.807,
                15.814,
                15.82,
                15.827,
                15.834,
                15.841,
                15.847,
                15.854,
                15.861,
                15.867,
                15.874,
                15.881,
                15.887,
                15.894,
                15.901,
                15.907,
                15.914,
                15.921,
                15.928,
                15.934,
                15.941,
                15.947,
                15.954,
                15.961,
                15.967,
                15.974,
                15.981,
                15.987,
                15.994,
                16.001,
                16.007,
                16.014,
                16.021,
                16.027,
                16.034,
                16.041,
                16.047,
                16.054,
                16.061,
                16.067,
                16.074,
                16.08,
                16.087,
                16.094,
                16.101,
                16.107,
                16.114,
                16.12,
                16.127,
                16.134,
                16.14,
                16.147,
                16.153,
                16.16,
                16.167,
                16.173,
                16.18,
                16.187,
                16.193,
                16.2,
                16.206,
                16.213,
                16.22,
                16.226,
                16.233,
                16.24,
                16.246,
                16.253,
                16.259,
                16.266,
                16.272,
                16.279,
                16.286,
                16.292,
                16.299,
                16.305,
                16.312,
                16.319,
                16.325,
                16.332,
                16.339,
                16.345,
                16.352,
                16.358,
                16.365,
                16.371,
                16.378,
                16.385,
                16.391,
                16.398,
                16.404,
                16.411,
                16.418,
                16.424,
                16.431,
                16.437,
                16.444,
                16.45,
                16.457,
                16.464,
                16.47,
                16.477,
                16.483,
                16.49,
                16.496,
                16.503,
                16.51,
                16.516,
                16.523,
                16.529,
                16.536,
                16.543,
                16.549,
                16.556,
                16.562,
                16.569,
                16.575,
                16.582,
                16.589,
                16.595,
                16.602,
                16.608,
                16.615,
                16.621,
                16.628,
                16.635,
                16.641,
                16.648,
                16.654,
                16.661,
                16.667,
                16.674,
                16.68,
                16.687,
                16.694,
                16.7,
                16.707,
                16.713,
                16.72,
                16.726,
                16.733,
                16.74,
                16.746,
                16.753,
                16.759,
                16.766,
                16.772,
                16.779,
                16.785,
                16.792,
                16.799,
                16.805,
                16.812,
                16.818,
                16.825,
                16.831,
                16.838,
                16.845,
                16.851,
                16.858,
                16.864,
                16.871,
                16.877,
                16.884,
                16.89,
                16.897,
                16.904,
                16.91,
                16.917,
                16.923,
                16.93,
                16.936,
                16.943,
                16.95,
                16.956,
                16.963,
                16.969,
                16.976,
                16.982,
                16.989,
                16.996,
                17.002,
                17.009,
                17.015,
                17.022,
                17.028,
                17.035,
                17.042,
                17.048,
                17.055,
                17.061,
                17.068,
                17.074,
                17.081,
                17.087,
                17.094,
                17.101,
                17.107,
                17.114,
                17.12,
                17.127,
                17.134,
                17.14,
                17.147,
                17.153,
                17.16,
                17.166,
                17.173,
                17.18,
                17.186,
                17.193,
                17.199,
                17.206,
                17.212,
                17.219,
                17.226,
                17.232,
                17.239,
                17.245,
                17.252,
                17.258,
                17.265,
                17.271,
                17.278,
                17.285,
                17.291,
                17.298,
                17.304,
                17.311,
                17.318,
                17.324,
                17.331,
                17.337,
                17.344,
                17.351,
                17.357,
                17.364,
                17.37,
                17.377,
                17.383,
                17.39,
                17.397,
                17.403,
                17.41,
                17.416,
                17.423,
                17.429,
                17.436,
                17.443,
                17.449,
                17.456,
                17.462,
                17.469,
                17.476,
                17.482,
                17.489,
                17.495,
                17.502,
                17.509,
                17.515,
                17.522,
                17.528,
                17.535,
                17.541,
                17.548,
                17.555,
                17.561,
                17.568,
                17.574,
                17.581,
                17.588,
                17.594,
                17.601,
                17.607,
                17.614,
                17.621,
                17.627,
                17.634,
                17.64,
                17.647,
                17.654,
                17.66,
                17.667,
                17.673,
                17.68,
                17.686,
                17.693,
                17.7,
                17.706,
                17.713,
                17.719,
                17.726,
                17.733,
                17.739,
                17.746,
                17.753,
                17.759,
                17.766,
                17.772,
                17.779,
                17.785,
                17.792,
                17.799,
                17.805,
                17.812,
                17.819,
                17.825,
                17.832,
                17.838,
                17.845,
                17.852,
                17.858,
                17.865,
                17.871,
                17.878,
                17.884,
                17.891,
                17.898,
                17.904,
                17.911,
                17.918,
                17.924,
                17.931,
                17.937,
                17.944,
                17.951,
                17.957,
                17.964,
                17.97,
                17.977,
                17.984,
                17.99,
                17.997,
                18.003,
                18.01,
                18.017,
                18.023,
                18.03,
                18.037,
                18.043,
                18.05,
                18.056,
                18.063,
                18.07,
                18.076,
                18.083,
                18.089,
                18.096,
                18.103,
                18.109,
                18.116,
                18.122,
                18.129,
                18.136,
                18.142,
                18.149,
                18.156,
                18.162,
                18.169,
                18.175,
                18.182,
                18.189,
                18.195,
                18.202,
                18.209,
                18.215,
                18.222,
                18.228,
                18.235,
                18.241,
                18.248,
                18.255,
                18.262,
                18.268,
                18.275,
                18.281,
                18.288,
                18.294,
                18.301,
                18.308,
                18.314,
                18.321,
                18.328,
                18.334,
                18.341,
                18.347,
                18.354,
                18.361,
                18.367,
                18.374,
                18.381,
                18.387,
                18.394,
                18.4,
                18.407,
                18.414,
                18.42,
                18.427,
                18.434,
                18.44,
                18.447,
                18.453,
                18.46,
                18.467,
                18.473,
                18.48,
                18.487,
                18.493,
                18.5,
                18.507,
                18.513,
                18.52,
                18.526,
                18.533,
                18.54,
                18.546,
                18.553,
                18.559,
                18.566,
                18.573,
                18.579,
                18.586,
                18.593,
                18.599,
                18.606,
                18.613,
                18.619,
                18.626,
                18.633,
                18.639,
                18.646,
                18.652,
                18.659,
                18.666,
                18.672,
                18.679,
                18.686,
                18.692,
                18.699,
                18.706,
                18.712,
                18.719,
                18.726,
                18.732,
                18.739,
                18.745,
                18.752,
                18.759,
                18.765,
                18.772,
                18.779,
                18.785,
                18.792,
                18.799,
                18.805,
                18.812,
                18.819,
                18.825,
                18.832,
                18.838,
                18.845,
                18.852,
                18.858,
                18.865,
                18.872,
                18.878,
                18.885,
                18.892,
                18.898,
                18.905,
                18.912,
                18.918,
                18.925,
                18.932,
                18.938,
                18.945,
                18.952,
                18.958,
                18.965,
                18.971,
                18.978,
                18.985,
                18.991,
                18.998,
                19.005,
                19.011,
                19.018,
                19.025,
                19.032,
                19.038,
                19.045,
                19.052,
                19.058,
                19.065,
                19.072,
                19.078,
                19.085,
                19.092,
                19.098,
                19.105,
                19.112,
                19.118,
                19.125,
                19.132,
                19.138,
                19.145,
                19.152,
                19.158,
                19.165,
                19.172,
                19.178,
                19.185,
                19.191,
                19.198,
                19.205,
                19.212,
                19.218,
                19.225,
                19.232,
                19.238,
                19.245,
                19.252,
                19.258,
                19.265,
                19.272,
                19.278,
                19.285,
                19.292,
                19.298,
                19.305,
                19.312,
                19.318,
                19.325,
                19.332,
                19.339,
                19.345,
                19.352,
                19.359,
                19.365,
                19.372,
                19.379,
                19.385,
                19.392,
                19.399,
                19.405,
                19.412,
                19.419,
                19.425,
                19.432,
                19.439,
                19.445,
                19.452,
                19.459,
                19.466,
                19.472,
                19.479,
                19.486,
                19.492,
                19.499,
                19.506,
                19.512,
                19.519,
                19.526,
                19.533,
                19.539,
                19.546,
                19.553,
                19.559,
                19.566,
                19.573,
                19.58,
                19.586,
                19.593,
                19.6,
                19.606,
                19.613,
                19.62,
                19.626,
                19.633,
                19.64,
                19.647,
                19.653,
                19.66,
                19.667,
                19.673,
                19.68,
                19.687,
                19.693,
                19.7,
                19.707,
                19.714,
                19.72,
                19.727,
                19.734,
                19.74,
                19.747,
                19.754,
                19.761,
                19.767,
                19.774,
                19.781,
                19.788,
                19.794,
                19.801,
                19.808,
                19.814,
                19.821,
                19.828,
                19.834,
                19.841,
                19.848,
                19.855,
                19.861,
                19.868,
                19.875,
                19.881,
                19.888,
                19.895,
                19.901,
                19.908,
                19.915,
                19.922,
                19.928,
                19.935,
                19.942,
                19.948,
                19.955,
                19.962,
                19.969,
                19.975,
                19.982,
                19.989,
                19.996,
                20.002,
                20.009,
                20.016,
                20.022,
                20.029,
                20.036,
                20.042,
                20.049,
                20.056,
                20.063,
                20.069,
                20.076,
                20.083,
                20.09,
                20.096,
                20.103,
                20.11,
                20.117,
                20.123,
                20.13,
                20.137,
                20.143,
                20.15,
                20.157,
                20.163,
                20.17,
                20.177,
                20.184,
                20.19,
                20.197,
                20.204,
                20.211,
                20.217,
                20.224,
                20.231,
                20.238,
                20.244,
                20.251,
                20.258,
                20.264,
                20.271,
                20.278,
                20.284,
                20.291,
                20.298,
                20.305,
                20.311,
                20.318,
                20.325,
                20.332,
                20.338,
                20.345,
                20.352,
                20.358,
                20.365,
                20.372,
                20.379,
                20.385,
                20.392,
                20.399,
                20.405,
                20.412,
                20.419,
                20.426,
                20.432,
                20.439,
                20.446,
                20.452,
                20.459,
                20.466,
                20.473,
                20.48,
                20.486,
                20.493,
                20.5,
                20.506,
                20.513,
                20.52,
                20.526,
                20.533,
                20.54,
                20.547,
                20.553,
                20.56,
                20.567,
                20.573,
                20.58,
                20.587,
                20.594,
                20.6,
                20.607,
                20.614,
                20.621,
                20.627,
                20.634,
                20.641,
                20.647,
                20.654,
                20.661,
                20.668,
                20.674,
                20.681,
                20.688,
                20.694,
                20.701,
                20.708,
                20.715,
                20.721,
                20.728,
                20.735,
                20.742,
                20.748,
                20.755,
                20.762,
                20.768,
                20.775,
                20.782,
                20.789,
                20.795,
                20.802,
                20.809,
                20.815,
                20.822,
                20.829,
                20.835,
                20.842,
                20.849,
                20.856,
                20.862,
                20.869,
                20.876,
                20.883,
                20.889,
                20.896,
                20.903,
                20.91,
                20.916,
                20.923,
                20.93,
                20.936,
                20.943,
                20.95,
                20.956,
                20.963,
                20.97,
                20.977,
                20.983,
                20.99,
                20.997,
                21.003,
                21.01,
                21.017,
                21.023,
                21.03,
                21.037,
                21.044,
                21.05,
                21.057,
                21.064,
                21.071,
                21.077,
                21.084,
                21.091,
                21.097,
                21.104,
                21.111,
                21.117,
                21.124,
                21.131,
                21.138,
                21.144,
                21.151,
                21.158,
                21.164,
                21.171,
                21.178,
                21.185,
                21.191,
                21.198,
                21.205,
                21.211,
                21.218,
                21.225,
                21.231,
                21.238,
                21.245,
                21.252,
                21.258,
                21.265,
                21.272,
                21.278,
                21.285,
                21.292,
                21.298,
                21.305,
                21.312,
                21.318,
                21.325

            ],
            P90: [
                4.011,
                3.983,
                4.007,
                4.037,
                4.07,
                4.105,
                4.142,
                4.18,
                4.219,
                4.259,
                4.3,
                4.343,
                4.387,
                4.433,
                4.48,
                4.529,
                4.578,
                4.627,
                4.677,
                4.728,
                4.778,
                4.828,
                4.878,
                4.928,
                4.978,
                5.028,
                5.077,
                5.126,
                5.174,
                5.222,
                5.27,
                5.317,
                5.364,
                5.41,
                5.456,
                5.502,
                5.546,
                5.59,
                5.634,
                5.678,
                5.72,
                5.762,
                5.804,
                5.845,
                5.886,
                5.927,
                5.966,
                6.006,
                6.045,
                6.083,
                6.121,
                6.159,
                6.196,
                6.233,
                6.269,
                6.305,
                6.341,
                6.376,
                6.411,
                6.446,
                6.48,
                6.514,
                6.547,
                6.58,
                6.613,
                6.645,
                6.678,
                6.709,
                6.741,
                6.772,
                6.803,
                6.834,
                6.864,
                6.894,
                6.923,
                6.953,
                6.982,
                7.011,
                7.039,
                7.068,
                7.096,
                7.124,
                7.151,
                7.178,
                7.205,
                7.232,
                7.259,
                7.285,
                7.311,
                7.337,
                7.363,
                7.388,
                7.413,
                7.438,
                7.463,
                7.488,
                7.512,
                7.536,
                7.56,
                7.584,
                7.608,
                7.631,
                7.655,
                7.678,
                7.701,
                7.723,
                7.746,
                7.768,
                7.791,
                7.813,
                7.835,
                7.857,
                7.878,
                7.9,
                7.921,
                7.943,
                7.964,
                7.984,
                8.005,
                8.026,
                8.046,
                8.067,
                8.087,
                8.107,
                8.127,
                8.147,
                8.167,
                8.187,
                8.206,
                8.225,
                8.245,
                8.264,
                8.283,
                8.302,
                8.321,
                8.34,
                8.358,
                8.377,
                8.395,
                8.413,
                8.431,
                8.449,
                8.467,
                8.485,
                8.503,
                8.521,
                8.538,
                8.556,
                8.573,
                8.59,
                8.607,
                8.624,
                8.641,
                8.658,
                8.675,
                8.692,
                8.708,
                8.724,
                8.741,
                8.757,
                8.773,
                8.79,
                8.806,
                8.821,
                8.837,
                8.853,
                8.869,
                8.885,
                8.9,
                8.916,
                8.931,
                8.946,
                8.962,
                8.977,
                8.992,
                9.007,
                9.022,
                9.037,
                9.052,
                9.066,
                9.081,
                9.095,
                9.11,
                9.125,
                9.139,
                9.153,
                9.168,
                9.182,
                9.196,
                9.21,
                9.224,
                9.238,
                9.252,
                9.266,
                9.279,
                9.293,
                9.307,
                9.32,
                9.334,
                9.347,
                9.361,
                9.374,
                9.387,
                9.4,
                9.414,
                9.427,
                9.44,
                9.453,
                9.466,
                9.479,
                9.492,
                9.504,
                9.517,
                9.53,
                9.543,
                9.555,
                9.568,
                9.58,
                9.593,
                9.605,
                9.617,
                9.63,
                9.642,
                9.654,
                9.666,
                9.678,
                9.69,
                9.702,
                9.714,
                9.726,
                9.738,
                9.75,
                9.762,
                9.774,
                9.785,
                9.797,
                9.809,
                9.82,
                9.832,
                9.843,
                9.855,
                9.866,
                9.878,
                9.889,
                9.9,
                9.912,
                9.923,
                9.934,
                9.945,
                9.956,
                9.968,
                9.979,
                9.99,
                10.001,
                10.012,
                10.023,
                10.034,
                10.044,
                10.055,
                10.066,
                10.077,
                10.088,
                10.098,
                10.109,
                10.12,
                10.13,
                10.141,
                10.152,
                10.162,
                10.173,
                10.183,
                10.194,
                10.204,
                10.215,
                10.225,
                10.235,
                10.246,
                10.256,
                10.266,
                10.277,
                10.287,
                10.297,
                10.307,
                10.317,
                10.327,
                10.338,
                10.348,
                10.358,
                10.368,
                10.378,
                10.388,
                10.398,
                10.408,
                10.418,
                10.428,
                10.438,
                10.447,
                10.457,
                10.467,
                10.477,
                10.487,
                10.496,
                10.506,
                10.516,
                10.526,
                10.535,
                10.545,
                10.555,
                10.564,
                10.574,
                10.584,
                10.593,
                10.603,
                10.612,
                10.622,
                10.631,
                10.641,
                10.65,
                10.66,
                10.669,
                10.678,
                10.688,
                10.697,
                10.707,
                10.716,
                10.725,
                10.735,
                10.744,
                10.753,
                10.763,
                10.772,
                10.781,
                10.79,
                10.8,
                10.809,
                10.818,
                10.827,
                10.836,
                10.846,
                10.855,
                10.864,
                10.873,
                10.882,
                10.891,
                10.9,
                10.909,
                10.919,
                10.927,
                10.937,
                10.946,
                10.955,
                10.964,
                10.973,
                10.982,
                10.991,
                11,
                11.008,
                11.018,
                11.026,
                11.035,
                11.044,
                11.053,
                11.062,
                11.071,
                11.08,
                11.089,
                11.097,
                11.106,
                11.115,
                11.124,
                11.133,
                11.142,
                11.15,
                11.159,
                11.168,
                11.177,
                11.185,
                11.194,
                11.203,
                11.212,
                11.22,
                11.229,
                11.238,
                11.247,
                11.255,
                11.264,
                11.273,
                11.281,
                11.29,
                11.299,
                11.307,
                11.316,
                11.324,
                11.333,
                11.342,
                11.35,
                11.359,
                11.367,
                11.376,
                11.385,
                11.393,
                11.402,
                11.41,
                11.419,
                11.427,
                11.436,
                11.444,
                11.453,
                11.461,
                11.47,
                11.478,
                11.487,
                11.495,
                11.504,
                11.512,
                11.521,
                11.529,
                11.538,
                11.546,
                11.555,
                11.563,
                11.571,
                11.58,
                11.588,
                11.597,
                11.605,
                11.613,
                11.622,
                11.63,
                11.639,
                11.647,
                11.655,
                11.664,
                11.672,
                11.681,
                11.689,
                11.697,
                11.706,
                11.714,
                11.722,
                11.731,
                11.739,
                11.747,
                11.756,
                11.764,
                11.772,
                11.781,
                11.789,
                11.797,
                11.805,
                11.814,
                11.822,
                11.83,
                11.838,
                11.847,
                11.855,
                11.863,
                11.872,
                11.88,
                11.888,
                11.896,
                11.905,
                11.913,
                11.921,
                11.929,
                11.938,
                11.946,
                11.954,
                11.962,
                11.971,
                11.979,
                11.987,
                11.995,
                12.003,
                12.012,
                12.02,
                12.028,
                12.036,
                12.044,
                12.053,
                12.061,
                12.069,
                12.077,
                12.085,
                12.094,
                12.102,
                12.11,
                12.118,
                12.126,
                12.134,
                12.143,
                12.151,
                12.159,
                12.167,
                12.175,
                12.183,
                12.192,
                12.2,
                12.208,
                12.216,
                12.224,
                12.232,
                12.24,
                12.249,
                12.257,
                12.265,
                12.273,
                12.281,
                12.289,
                12.297,
                12.305,
                12.313,
                12.322,
                12.33,
                12.338,
                12.346,
                12.354,
                12.362,
                12.37,
                12.378,
                12.386,
                12.394,
                12.402,
                12.411,
                12.419,
                12.427,
                12.435,
                12.443,
                12.451,
                12.459,
                12.467,
                12.475,
                12.483,
                12.491,
                12.499,
                12.508,
                12.516,
                12.523,
                12.532,
                12.54,
                12.548,
                12.556,
                12.564,
                12.572,
                12.58,
                12.588,
                12.596,
                12.604,
                12.612,
                12.62,
                12.628,
                12.636,
                12.644,
                12.652,
                12.66,
                12.668,
                12.676,
                12.684,
                12.692,
                12.7,
                12.708,
                12.716,
                12.724,
                12.732,
                12.74,
                12.748,
                12.756,
                12.764,
                12.772,
                12.78,
                12.788,
                12.796,
                12.804,
                12.812,
                12.82,
                12.828,
                12.836,
                12.844,
                12.852,
                12.86,
                12.868,
                12.877,
                12.884,
                12.892,
                12.9,
                12.908,
                12.916,
                12.924,
                12.932,
                12.94,
                12.948,
                12.956,
                12.964,
                12.972,
                12.98,
                12.988,
                12.996,
                13.004,
                13.012,
                13.02,
                13.028,
                13.036,
                13.044,
                13.052,
                13.06,
                13.068,
                13.076,
                13.084,
                13.092,
                13.1,
                13.108,
                13.116,
                13.124,
                13.132,
                13.14,
                13.148,
                13.156,
                13.164,
                13.172,
                13.18,
                13.188,
                13.196,
                13.204,
                13.212,
                13.22,
                13.228,
                13.236,
                13.244,
                13.252,
                13.26,
                13.268,
                13.276,
                13.284,
                13.292,
                13.3,
                13.308,
                13.316,
                13.324,
                13.332,
                13.34,
                13.348,
                13.356,
                13.364,
                13.372,
                13.38,
                13.388,
                13.396,
                13.404,
                13.411,
                13.419,
                13.428,
                13.436,
                13.443,
                13.452,
                13.46,
                13.468,
                13.475,
                13.483,
                13.491,
                13.499,
                13.507,
                13.515,
                13.523,
                13.531,
                13.539,
                13.547,
                13.555,
                13.563,
                13.571,
                13.579,
                13.587,
                13.595,
                13.603,
                13.611,
                13.619,
                13.627,
                13.635,
                13.643,
                13.651,
                13.659,
                13.667,
                13.675,
                13.683,
                13.691,
                13.699,
                13.707,
                13.715,
                13.723,
                13.731,
                13.739,
                13.747,
                13.755,
                13.763,
                13.771,
                13.779,
                13.787,
                13.795,
                13.803,
                13.811,
                13.819,
                13.827,
                13.835,
                13.843,
                13.851,
                13.858,
                13.867,
                13.875,
                13.883,
                13.891,
                13.899,
                13.907,
                13.914,
                13.922,
                13.93,
                13.938,
                13.946,
                13.954,
                13.962,
                13.97,
                13.978,
                13.986,
                13.994,
                14.002,
                14.01,
                14.018,
                14.026,
                14.034,
                14.042,
                14.05,
                14.058,
                14.066,
                14.074,
                14.082,
                14.09,
                14.098,
                14.106,
                14.114,
                14.122,
                14.129,
                14.137,
                14.145,
                14.153,
                14.161,
                14.169,
                14.177,
                14.185,
                14.193,
                14.201,
                14.209,
                14.217,
                14.225,
                14.233,
                14.241,
                14.249,
                14.256,
                14.264,
                14.272,
                14.28,
                14.288,
                14.296,
                14.304,
                14.312,
                14.32,
                14.328,
                14.336,
                14.344,
                14.351,
                14.359,
                14.367,
                14.375,
                14.383,
                14.391,
                14.399,
                14.407,
                14.415,
                14.422,
                14.43,
                14.438,
                14.446,
                14.454,
                14.462,
                14.47,
                14.478,
                14.485,
                14.493,
                14.501,
                14.509,
                14.517,
                14.525,
                14.532,
                14.54,
                14.548,
                14.556,
                14.564,
                14.571,
                14.579,
                14.587,
                14.595,
                14.603,
                14.611,
                14.618,
                14.626,
                14.634,
                14.642,
                14.65,
                14.657,
                14.665,
                14.673,
                14.681,
                14.688,
                14.696,
                14.704,
                14.712,
                14.719,
                14.727,
                14.735,
                14.743,
                14.751,
                14.758,
                14.766,
                14.774,
                14.781,
                14.789,
                14.797,
                14.804,
                14.812,
                14.82,
                14.828,
                14.835,
                14.843,
                14.851,
                14.858,
                14.866,
                14.874,
                14.881,
                14.889,
                14.897,
                14.904,
                14.912,
                14.92,
                14.927,
                14.935,
                14.943,
                14.95,
                14.958,
                14.965,
                14.973,
                14.981,
                14.988,
                14.996,
                15.004,
                15.011,
                15.019,
                15.026,
                15.034,
                15.042,
                15.049,
                15.057,
                15.064,
                15.072,
                15.08,
                15.087,
                15.095,
                15.102,
                15.11,
                15.117,
                15.125,
                15.132,
                15.14,
                15.147,
                15.155,
                15.162,
                15.17,
                15.177,
                15.185,
                15.192,
                15.2,
                15.207,
                15.215,
                15.222,
                15.23,
                15.237,
                15.245,
                15.252,
                15.26,
                15.267,
                15.275,
                15.282,
                15.289,
                15.297,
                15.304,
                15.312,
                15.319,
                15.326,
                15.334,
                15.341,
                15.349,
                15.356,
                15.364,
                15.371,
                15.379,
                15.386,
                15.393,
                15.401,
                15.408,
                15.415,
                15.423,
                15.43,
                15.437,
                15.445,
                15.452,
                15.46,
                15.467,
                15.474,
                15.482,
                15.489,
                15.496,
                15.504,
                15.511,
                15.518,
                15.526,
                15.533,
                15.54,
                15.548,
                15.555,
                15.562,
                15.569,
                15.577,
                15.584,
                15.591,
                15.599,
                15.606,
                15.613,
                15.62,
                15.628,
                15.635,
                15.642,
                15.649,
                15.657,
                15.664,
                15.671,
                15.678,
                15.686,
                15.693,
                15.7,
                15.707,
                15.714,
                15.722,
                15.729,
                15.736,
                15.743,
                15.751,
                15.758,
                15.765,
                15.772,
                15.779,
                15.786,
                15.794,
                15.801,
                15.808,
                15.815,
                15.823,
                15.83,
                15.837,
                15.844,
                15.851,
                15.858,
                15.866,
                15.873,
                15.88,
                15.887,
                15.894,
                15.901,
                15.908,
                15.915,
                15.923,
                15.93,
                15.937,
                15.944,
                15.951,
                15.958,
                15.965,
                15.972,
                15.98,
                15.987,
                15.994,
                16.001,
                16.008,
                16.015,
                16.022,
                16.029,
                16.036,
                16.043,
                16.05,
                16.057,
                16.065,
                16.072,
                16.079,
                16.086,
                16.093,
                16.1,
                16.107,
                16.114,
                16.121,
                16.128,
                16.135,
                16.142,
                16.149,
                16.156,
                16.163,
                16.17,
                16.177,
                16.184,
                16.191,
                16.198,
                16.205,
                16.213,
                16.219,
                16.227,
                16.233,
                16.24,
                16.247,
                16.255,
                16.262,
                16.268,
                16.275,
                16.283,
                16.289,
                16.297,
                16.304,
                16.31,
                16.317,
                16.324,
                16.331,
                16.339,
                16.345,
                16.352,
                16.359,
                16.366,
                16.373,
                16.38,
                16.387,
                16.394,
                16.401,
                16.408,
                16.415,
                16.422,
                16.429,
                16.436,
                16.443,
                16.45,
                16.457,
                16.464,
                16.471,
                16.477,
                16.484,
                16.491,
                16.498,
                16.505,
                16.512,
                16.519,
                16.526,
                16.533,
                16.54,
                16.547,
                16.554,
                16.561,
                16.568,
                16.575,
                16.582,
                16.589,
                16.595,
                16.602,
                16.609,
                16.616,
                16.623,
                16.63,
                16.637,
                16.644,
                16.651,
                16.657,
                16.664,
                16.671,
                16.678,
                16.685,
                16.692,
                16.699,
                16.706,
                16.713,
                16.72,
                16.727,
                16.734,
                16.74,
                16.747,
                16.754,
                16.761,
                16.768,
                16.775,
                16.782,
                16.788,
                16.795,
                16.802,
                16.809,
                16.816,
                16.823,
                16.83,
                16.837,
                16.843,
                16.85,
                16.857,
                16.864,
                16.871,
                16.878,
                16.885,
                16.892,
                16.899,
                16.905,
                16.912,
                16.919,
                16.926,
                16.933,
                16.94,
                16.947,
                16.954,
                16.96,
                16.967,
                16.974,
                16.981,
                16.988,
                16.995,
                17.002,
                17.008,
                17.015,
                17.022,
                17.029,
                17.036,
                17.043,
                17.05,
                17.056,
                17.063,
                17.07,
                17.077,
                17.084,
                17.091,
                17.098,
                17.104,
                17.111,
                17.118,
                17.125,
                17.132,
                17.139,
                17.146,
                17.152,
                17.159,
                17.166,
                17.173,
                17.18,
                17.187,
                17.193,
                17.2,
                17.207,
                17.214,
                17.221,
                17.228,
                17.235,
                17.241,
                17.248,
                17.255,
                17.262,
                17.269,
                17.276,
                17.282,
                17.289,
                17.296,
                17.303,
                17.31,
                17.317,
                17.323,
                17.33,
                17.337,
                17.344,
                17.351,
                17.358,
                17.365,
                17.371,
                17.378,
                17.385,
                17.392,
                17.399,
                17.406,
                17.413,
                17.419,
                17.426,
                17.433,
                17.44,
                17.447,
                17.454,
                17.461,
                17.467,
                17.474,
                17.481,
                17.488,
                17.495,
                17.502,
                17.509,
                17.515,
                17.522,
                17.529,
                17.536,
                17.543,
                17.55,
                17.556,
                17.563,
                17.57,
                17.577,
                17.584,
                17.591,
                17.598,
                17.604,
                17.611,
                17.618,
                17.625,
                17.632,
                17.639,
                17.646,
                17.653,
                17.659,
                17.666,
                17.673,
                17.68,
                17.687,
                17.694,
                17.701,
                17.707,
                17.714,
                17.721,
                17.728,
                17.735,
                17.742,
                17.749,
                17.755,
                17.762,
                17.769,
                17.776,
                17.783,
                17.79,
                17.797,
                17.803,
                17.81,
                17.817,
                17.824,
                17.831,
                17.838,
                17.845,
                17.852,
                17.858,
                17.865,
                17.872,
                17.879,
                17.886,
                17.893,
                17.9,
                17.907,
                17.913,
                17.92,
                17.927,
                17.934,
                17.941,
                17.948,
                17.955,
                17.962,
                17.968,
                17.975,
                17.982,
                17.989,
                17.996,
                18.003,
                18.01,
                18.016,
                18.023,
                18.03,
                18.037,
                18.044,
                18.051,
                18.058,
                18.065,
                18.072,
                18.079,
                18.086,
                18.092,
                18.099,
                18.106,
                18.113,
                18.12,
                18.127,
                18.134,
                18.14,
                18.147,
                18.154,
                18.161,
                18.168,
                18.175,
                18.182,
                18.189,
                18.196,
                18.202,
                18.209,
                18.216,
                18.223,
                18.23,
                18.237,
                18.244,
                18.251,
                18.258,
                18.265,
                18.271,
                18.278,
                18.285,
                18.292,
                18.299,
                18.306,
                18.313,
                18.32,
                18.327,
                18.333,
                18.34,
                18.347,
                18.354,
                18.361,
                18.368,
                18.375,
                18.382,
                18.389,
                18.396,
                18.403,
                18.409,
                18.416,
                18.423,
                18.43,
                18.437,
                18.444,
                18.451,
                18.458,
                18.465,
                18.472,
                18.479,
                18.485,
                18.492,
                18.499,
                18.506,
                18.513,
                18.52,
                18.527,
                18.534,
                18.541,
                18.548,
                18.554,
                18.561,
                18.568,
                18.575,
                18.582,
                18.589,
                18.596,
                18.603,
                18.61,
                18.617,
                18.624,
                18.631,
                18.637,
                18.644,
                18.651,
                18.658,
                18.665,
                18.672,
                18.679,
                18.686,
                18.693,
                18.7,
                18.707,
                18.714,
                18.72,
                18.727,
                18.734,
                18.741,
                18.748,
                18.755,
                18.762,
                18.769,
                18.776,
                18.783,
                18.79,
                18.797,
                18.803,
                18.81,
                18.817,
                18.824,
                18.831,
                18.838,
                18.845,
                18.852,
                18.859,
                18.866,
                18.873,
                18.88,
                18.887,
                18.894,
                18.901,
                18.908,
                18.914,
                18.921,
                18.928,
                18.935,
                18.942,
                18.949,
                18.956,
                18.963,
                18.97,
                18.977,
                18.984,
                18.991,
                18.998,
                19.005,
                19.012,
                19.019,
                19.026,
                19.033,
                19.04,
                19.046,
                19.053,
                19.06,
                19.067,
                19.074,
                19.081,
                19.088,
                19.095,
                19.102,
                19.109,
                19.116,
                19.123,
                19.13,
                19.137,
                19.144,
                19.151,
                19.157,
                19.164,
                19.171,
                19.178,
                19.185,
                19.192,
                19.199,
                19.206,
                19.213,
                19.22,
                19.227,
                19.234,
                19.241,
                19.248,
                19.255,
                19.262,
                19.269,
                19.276,
                19.283,
                19.29,
                19.297,
                19.304,
                19.311,
                19.318,
                19.325,
                19.331,
                19.338,
                19.345,
                19.352,
                19.359,
                19.366,
                19.373,
                19.38,
                19.387,
                19.394,
                19.401,
                19.408,
                19.415,
                19.422,
                19.429,
                19.436,
                19.443,
                19.45,
                19.457,
                19.464,
                19.471,
                19.478,
                19.485,
                19.492,
                19.499,
                19.506,
                19.513,
                19.52,
                19.527,
                19.534,
                19.54,
                19.548,
                19.555,
                19.561,
                19.568,
                19.575,
                19.582,
                19.589,
                19.596,
                19.603,
                19.61,
                19.617,
                19.624,
                19.631,
                19.638,
                19.646,
                19.652,
                19.66,
                19.666,
                19.673,
                19.68,
                19.687,
                19.695,
                19.701,
                19.708,
                19.715,
                19.722,
                19.729,
                19.736,
                19.743,
                19.75,
                19.757,
                19.764,
                19.771,
                19.778,
                19.785,
                19.792,
                19.799,
                19.806,
                19.813,
                19.82,
                19.827,
                19.834,
                19.841,
                19.848,
                19.855,
                19.862,
                19.87,
                19.877,
                19.884,
                19.891,
                19.898,
                19.905,
                19.912,
                19.919,
                19.926,
                19.933,
                19.94,
                19.947,
                19.954,
                19.961,
                19.968,
                19.975,
                19.982,
                19.989,
                19.996,
                20.003,
                20.01,
                20.017,
                20.024,
                20.031,
                20.038,
                20.045,
                20.052,
                20.059,
                20.066,
                20.073,
                20.08,
                20.087,
                20.094,
                20.101,
                20.108,
                20.115,
                20.122,
                20.129,
                20.136,
                20.143,
                20.15,
                20.157,
                20.164,
                20.171,
                20.178,
                20.185,
                20.193,
                20.2,
                20.207,
                20.214,
                20.221,
                20.228,
                20.235,
                20.242,
                20.249,
                20.256,
                20.263,
                20.27,
                20.277,
                20.284,
                20.291,
                20.298,
                20.305,
                20.312,
                20.319,
                20.326,
                20.333,
                20.34,
                20.347,
                20.355,
                20.362,
                20.369,
                20.376,
                20.383,
                20.39,
                20.397,
                20.404,
                20.411,
                20.418,
                20.425,
                20.432,
                20.439,
                20.446,
                20.453,
                20.46,
                20.467,
                20.474,
                20.481,
                20.489,
                20.496,
                20.503,
                20.51,
                20.517,
                20.524,
                20.531,
                20.538,
                20.545,
                20.552,
                20.559,
                20.566,
                20.573,
                20.58,
                20.588,
                20.595,
                20.602,
                20.609,
                20.616,
                20.623,
                20.63,
                20.637,
                20.644,
                20.651,
                20.658,
                20.665,
                20.672,
                20.679,
                20.686,
                20.693,
                20.7,
                20.707,
                20.714,
                20.722,
                20.729,
                20.736,
                20.743,
                20.75,
                20.757,
                20.764,
                20.771,
                20.778,
                20.785,
                20.792,
                20.799,
                20.806,
                20.814,
                20.821,
                20.828,
                20.835,
                20.842,
                20.849,
                20.856,
                20.863,
                20.87,
                20.877,
                20.884,
                20.891,
                20.898,
                20.905,
                20.913,
                20.92,
                20.927,
                20.934,
                20.941,
                20.948,
                20.955,
                20.962,
                20.969,
                20.976,
                20.983,
                20.99,
                20.997,
                21.004,
                21.012,
                21.019,
                21.026,
                21.033,
                21.04,
                21.047,
                21.054,
                21.061,
                21.068,
                21.075,
                21.082,
                21.089,
                21.096,
                21.104,
                21.111,
                21.118,
                21.125,
                21.132,
                21.139,
                21.146,
                21.153,
                21.16,
                21.167,
                21.174,
                21.181,
                21.188,
                21.196,
                21.203,
                21.21,
                21.217,
                21.224,
                21.231,
                21.238,
                21.245,
                21.252,
                21.259,
                21.266,
                21.273,
                21.28,
                21.287,
                21.295,
                21.302,
                21.309,
                21.316,
                21.323,
                21.33,
                21.337,
                21.344,
                21.351,
                21.358,
                21.365,
                21.372,
                21.38,
                21.387,
                21.394,
                21.401,
                21.408,
                21.415,
                21.422,
                21.429,
                21.436,
                21.443,
                21.45,
                21.457,
                21.464,
                21.471,
                21.478,
                21.486,
                21.493,
                21.5,
                21.507,
                21.514,
                21.521,
                21.528,
                21.535,
                21.542,
                21.549,
                21.556,
                21.564,
                21.571,
                21.578,
                21.585,
                21.592,
                21.599,
                21.606,
                21.613,
                21.62,
                21.627,
                21.634,
                21.641,
                21.648,
                21.655,
                21.662,
                21.669,
                21.676,
                21.683,
                21.691,
                21.698,
                21.705,
                21.712,
                21.719,
                21.726,
                21.733,
                21.74,
                21.747,
                21.754,
                21.762,
                21.769,
                21.776,
                21.783,
                21.79,
                21.797,
                21.804,
                21.811,
                21.818,
                21.825,
                21.832,
                21.839,
                21.846,
                21.853,
                21.86,
                21.867,
                21.874,
                21.881,
                21.888,
                21.896,
                21.903,
                21.91,
                21.917,
                21.924,
                21.931,
                21.938,
                21.945,
                21.952,
                21.959,
                21.966,
                21.973,
                21.98,
                21.987,
                21.995,
                22.002,
                22.009,
                22.016,
                22.023,
                22.03,
                22.037,
                22.044,
                22.051,
                22.058,
                22.065

            ],
            P95: [
                4.215,
                4.188,
                4.212,
                4.244,
                4.278,
                4.315,
                4.353,
                4.393,
                4.433,
                4.475,
                4.518,
                4.562,
                4.608,
                4.655,
                4.704,
                4.754,
                4.805,
                4.857,
                4.909,
                4.961,
                5.013,
                5.065,
                5.116,
                5.168,
                5.22,
                5.271,
                5.322,
                5.372,
                5.422,
                5.472,
                5.522,
                5.57,
                5.619,
                5.667,
                5.714,
                5.761,
                5.807,
                5.852,
                5.898,
                5.942,
                5.986,
                6.03,
                6.073,
                6.115,
                6.157,
                6.199,
                6.24,
                6.28,
                6.321,
                6.36,
                6.4,
                6.438,
                6.476,
                6.514,
                6.552,
                6.589,
                6.626,
                6.662,
                6.697,
                6.733,
                6.768,
                6.803,
                6.837,
                6.871,
                6.905,
                6.938,
                6.971,
                7.004,
                7.036,
                7.068,
                7.1,
                7.131,
                7.162,
                7.193,
                7.224,
                7.254,
                7.284,
                7.313,
                7.343,
                7.372,
                7.4,
                7.429,
                7.457,
                7.485,
                7.513,
                7.54,
                7.568,
                7.595,
                7.621,
                7.648,
                7.674,
                7.7,
                7.726,
                7.752,
                7.777,
                7.803,
                7.827,
                7.852,
                7.877,
                7.901,
                7.926,
                7.95,
                7.974,
                7.997,
                8.021,
                8.044,
                8.068,
                8.091,
                8.113,
                8.136,
                8.159,
                8.181,
                8.203,
                8.225,
                8.247,
                8.269,
                8.291,
                8.312,
                8.334,
                8.355,
                8.376,
                8.397,
                8.418,
                8.438,
                8.459,
                8.48,
                8.5,
                8.52,
                8.54,
                8.56,
                8.58,
                8.6,
                8.619,
                8.639,
                8.658,
                8.677,
                8.696,
                8.715,
                8.734,
                8.753,
                8.772,
                8.79,
                8.809,
                8.827,
                8.846,
                8.864,
                8.882,
                8.9,
                8.918,
                8.935,
                8.953,
                8.971,
                8.988,
                9.006,
                9.023,
                9.04,
                9.057,
                9.074,
                9.091,
                9.108,
                9.125,
                9.141,
                9.158,
                9.174,
                9.191,
                9.207,
                9.223,
                9.239,
                9.255,
                9.272,
                9.287,
                9.303,
                9.319,
                9.335,
                9.35,
                9.366,
                9.381,
                9.397,
                9.412,
                9.427,
                9.442,
                9.457,
                9.472,
                9.488,
                9.502,
                9.517,
                9.532,
                9.547,
                9.562,
                9.576,
                9.591,
                9.605,
                9.619,
                9.634,
                9.648,
                9.662,
                9.676,
                9.69,
                9.705,
                9.718,
                9.732,
                9.746,
                9.76,
                9.774,
                9.787,
                9.801,
                9.815,
                9.828,
                9.841,
                9.855,
                9.868,
                9.882,
                9.895,
                9.908,
                9.921,
                9.934,
                9.947,
                9.96,
                9.973,
                9.986,
                9.999,
                10.011,
                10.024,
                10.037,
                10.05,
                10.062,
                10.075,
                10.087,
                10.1,
                10.112,
                10.124,
                10.137,
                10.149,
                10.161,
                10.173,
                10.186,
                10.198,
                10.21,
                10.222,
                10.234,
                10.246,
                10.258,
                10.269,
                10.281,
                10.293,
                10.305,
                10.316,
                10.328,
                10.34,
                10.352,
                10.363,
                10.375,
                10.386,
                10.397,
                10.409,
                10.42,
                10.432,
                10.443,
                10.454,
                10.466,
                10.477,
                10.488,
                10.499,
                10.51,
                10.522,
                10.533,
                10.544,
                10.555,
                10.566,
                10.577,
                10.588,
                10.598,
                10.61,
                10.62,
                10.631,
                10.642,
                10.653,
                10.664,
                10.674,
                10.685,
                10.696,
                10.706,
                10.717,
                10.728,
                10.738,
                10.749,
                10.759,
                10.77,
                10.78,
                10.791,
                10.801,
                10.811,
                10.822,
                10.832,
                10.843,
                10.853,
                10.863,
                10.874,
                10.884,
                10.894,
                10.904,
                10.914,
                10.925,
                10.935,
                10.945,
                10.955,
                10.965,
                10.975,
                10.985,
                10.995,
                11.005,
                11.015,
                11.025,
                11.035,
                11.045,
                11.055,
                11.065,
                11.075,
                11.085,
                11.094,
                11.104,
                11.114,
                11.124,
                11.134,
                11.144,
                11.153,
                11.163,
                11.173,
                11.182,
                11.192,
                11.202,
                11.212,
                11.221,
                11.231,
                11.24,
                11.25,
                11.26,
                11.269,
                11.279,
                11.288,
                11.298,
                11.308,
                11.317,
                11.327,
                11.336,
                11.346,
                11.355,
                11.364,
                11.374,
                11.384,
                11.393,
                11.402,
                11.412,
                11.421,
                11.431,
                11.44,
                11.449,
                11.459,
                11.468,
                11.477,
                11.487,
                11.496,
                11.505,
                11.515,
                11.524,
                11.533,
                11.542,
                11.551,
                11.561,
                11.57,
                11.579,
                11.588,
                11.598,
                11.607,
                11.616,
                11.625,
                11.634,
                11.643,
                11.653,
                11.662,
                11.671,
                11.68,
                11.689,
                11.698,
                11.707,
                11.716,
                11.725,
                11.734,
                11.744,
                11.752,
                11.761,
                11.771,
                11.78,
                11.789,
                11.798,
                11.807,
                11.816,
                11.825,
                11.834,
                11.843,
                11.852,
                11.861,
                11.869,
                11.878,
                11.887,
                11.896,
                11.905,
                11.914,
                11.923,
                11.932,
                11.941,
                11.95,
                11.958,
                11.967,
                11.976,
                11.985,
                11.994,
                12.003,
                12.012,
                12.021,
                12.029,
                12.038,
                12.047,
                12.056,
                12.065,
                12.073,
                12.082,
                12.091,
                12.1,
                12.109,
                12.117,
                12.126,
                12.135,
                12.144,
                12.152,
                12.161,
                12.17,
                12.179,
                12.187,
                12.196,
                12.205,
                12.214,
                12.222,
                12.231,
                12.24,
                12.248,
                12.257,
                12.266,
                12.275,
                12.283,
                12.292,
                12.301,
                12.309,
                12.318,
                12.327,
                12.335,
                12.344,
                12.353,
                12.361,
                12.37,
                12.379,
                12.387,
                12.396,
                12.405,
                12.413,
                12.422,
                12.431,
                12.439,
                12.448,
                12.457,
                12.465,
                12.474,
                12.482,
                12.491,
                12.499,
                12.508,
                12.517,
                12.525,
                12.534,
                12.543,
                12.551,
                12.56,
                12.568,
                12.577,
                12.586,
                12.594,
                12.603,
                12.611,
                12.62,
                12.629,
                12.637,
                12.646,
                12.654,
                12.663,
                12.671,
                12.68,
                12.688,
                12.697,
                12.705,
                12.714,
                12.723,
                12.731,
                12.74,
                12.748,
                12.757,
                12.765,
                12.774,
                12.782,
                12.791,
                12.799,
                12.808,
                12.816,
                12.825,
                12.833,
                12.842,
                12.85,
                12.859,
                12.867,
                12.876,
                12.885,
                12.893,
                12.901,
                12.91,
                12.918,
                12.927,
                12.935,
                12.944,
                12.952,
                12.961,
                12.97,
                12.978,
                12.986,
                12.995,
                13.003,
                13.012,
                13.02,
                13.029,
                13.037,
                13.046,
                13.054,
                13.062,
                13.071,
                13.079,
                13.088,
                13.096,
                13.105,
                13.113,
                13.122,
                13.13,
                13.139,
                13.147,
                13.156,
                13.164,
                13.172,
                13.181,
                13.189,
                13.198,
                13.206,
                13.215,
                13.223,
                13.232,
                13.24,
                13.248,
                13.257,
                13.265,
                13.274,
                13.282,
                13.291,
                13.299,
                13.307,
                13.316,
                13.324,
                13.333,
                13.341,
                13.349,
                13.358,
                13.366,
                13.375,
                13.383,
                13.392,
                13.4,
                13.408,
                13.417,
                13.425,
                13.433,
                13.442,
                13.45,
                13.459,
                13.467,
                13.476,
                13.484,
                13.492,
                13.501,
                13.509,
                13.518,
                13.526,
                13.535,
                13.543,
                13.551,
                13.56,
                13.568,
                13.577,
                13.585,
                13.593,
                13.602,
                13.61,
                13.619,
                13.627,
                13.636,
                13.644,
                13.652,
                13.661,
                13.669,
                13.678,
                13.686,
                13.694,
                13.703,
                13.711,
                13.72,
                13.728,
                13.736,
                13.745,
                13.753,
                13.762,
                13.77,
                13.779,
                13.787,
                13.795,
                13.804,
                13.812,
                13.821,
                13.829,
                13.837,
                13.846,
                13.854,
                13.863,
                13.871,
                13.88,
                13.888,
                13.897,
                13.905,
                13.913,
                13.922,
                13.93,
                13.939,
                13.947,
                13.955,
                13.964,
                13.972,
                13.981,
                13.989,
                13.998,
                14.006,
                14.014,
                14.023,
                14.031,
                14.04,
                14.048,
                14.057,
                14.065,
                14.073,
                14.082,
                14.09,
                14.099,
                14.107,
                14.116,
                14.124,
                14.133,
                14.141,
                14.149,
                14.158,
                14.166,
                14.174,
                14.183,
                14.191,
                14.2,
                14.208,
                14.217,
                14.225,
                14.234,
                14.242,
                14.251,
                14.259,
                14.267,
                14.276,
                14.284,
                14.293,
                14.301,
                14.309,
                14.318,
                14.326,
                14.335,
                14.343,
                14.351,
                14.36,
                14.368,
                14.377,
                14.385,
                14.394,
                14.402,
                14.411,
                14.419,
                14.428,
                14.436,
                14.444,
                14.453,
                14.461,
                14.47,
                14.478,
                14.487,
                14.495,
                14.503,
                14.512,
                14.52,
                14.529,
                14.537,
                14.545,
                14.554,
                14.562,
                14.571,
                14.579,
                14.587,
                14.596,
                14.604,
                14.613,
                14.621,
                14.63,
                14.638,
                14.646,
                14.655,
                14.663,
                14.672,
                14.68,
                14.689,
                14.697,
                14.705,
                14.714,
                14.722,
                14.731,
                14.739,
                14.747,
                14.756,
                14.764,
                14.773,
                14.781,
                14.79,
                14.798,
                14.806,
                14.815,
                14.823,
                14.832,
                14.84,
                14.848,
                14.857,
                14.865,
                14.873,
                14.882,
                14.89,
                14.899,
                14.907,
                14.915,
                14.924,
                14.932,
                14.941,
                14.949,
                14.957,
                14.966,
                14.974,
                14.982,
                14.991,
                14.999,
                15.007,
                15.016,
                15.024,
                15.032,
                15.041,
                15.049,
                15.057,
                15.066,
                15.074,
                15.082,
                15.091,
                15.099,
                15.107,
                15.116,
                15.124,
                15.132,
                15.141,
                15.149,
                15.157,
                15.166,
                15.174,
                15.182,
                15.19,
                15.198,
                15.207,
                15.215,
                15.223,
                15.232,
                15.24,
                15.248,
                15.256,
                15.265,
                15.273,
                15.281,
                15.289,
                15.298,
                15.306,
                15.314,
                15.322,
                15.331,
                15.339,
                15.347,
                15.355,
                15.364,
                15.372,
                15.38,
                15.388,
                15.396,
                15.405,
                15.413,
                15.421,
                15.429,
                15.437,
                15.445,
                15.454,
                15.462,
                15.47,
                15.478,
                15.486,
                15.494,
                15.503,
                15.511,
                15.519,
                15.527,
                15.535,
                15.543,
                15.551,
                15.56,
                15.568,
                15.576,
                15.584,
                15.592,
                15.6,
                15.608,
                15.616,
                15.624,
                15.632,
                15.64,
                15.648,
                15.657,
                15.665,
                15.673,
                15.681,
                15.689,
                15.697,
                15.705,
                15.713,
                15.721,
                15.729,
                15.737,
                15.745,
                15.753,
                15.761,
                15.769,
                15.777,
                15.785,
                15.793,
                15.801,
                15.809,
                15.817,
                15.825,
                15.833,
                15.841,
                15.849,
                15.857,
                15.865,
                15.873,
                15.881,
                15.888,
                15.896,
                15.904,
                15.912,
                15.92,
                15.928,
                15.936,
                15.944,
                15.952,
                15.96,
                15.968,
                15.976,
                15.984,
                15.991,
                15.999,
                16.007,
                16.015,
                16.023,
                16.031,
                16.039,
                16.047,
                16.054,
                16.062,
                16.07,
                16.078,
                16.086,
                16.094,
                16.101,
                16.109,
                16.117,
                16.125,
                16.133,
                16.14,
                16.148,
                16.156,
                16.164,
                16.172,
                16.179,
                16.187,
                16.195,
                16.203,
                16.211,
                16.218,
                16.226,
                16.234,
                16.242,
                16.249,
                16.257,
                16.265,
                16.273,
                16.28,
                16.288,
                16.296,
                16.304,
                16.311,
                16.319,
                16.327,
                16.335,
                16.342,
                16.35,
                16.358,
                16.365,
                16.373,
                16.381,
                16.388,
                16.396,
                16.404,
                16.411,
                16.419,
                16.427,
                16.434,
                16.442,
                16.45,
                16.457,
                16.465,
                16.473,
                16.48,
                16.488,
                16.496,
                16.503,
                16.511,
                16.519,
                16.526,
                16.534,
                16.542,
                16.549,
                16.557,
                16.564,
                16.572,
                16.579,
                16.587,
                16.595,
                16.602,
                16.61,
                16.617,
                16.625,
                16.633,
                16.64,
                16.648,
                16.655,
                16.663,
                16.67,
                16.678,
                16.685,
                16.693,
                16.701,
                16.708,
                16.716,
                16.723,
                16.731,
                16.738,
                16.746,
                16.753,
                16.761,
                16.769,
                16.776,
                16.783,
                16.791,
                16.799,
                16.806,
                16.814,
                16.821,
                16.829,
                16.836,
                16.844,
                16.851,
                16.859,
                16.866,
                16.873,
                16.881,
                16.889,
                16.896,
                16.904,
                16.911,
                16.918,
                16.926,
                16.933,
                16.941,
                16.948,
                16.956,
                16.963,
                16.971,
                16.978,
                16.986,
                16.993,
                17,
                17.008,
                17.015,
                17.023,
                17.03,
                17.038,
                17.045,
                17.053,
                17.06,
                17.067,
                17.075,
                17.082,
                17.09,
                17.097,
                17.105,
                17.112,
                17.119,
                17.127,
                17.134,
                17.142,
                17.149,
                17.156,
                17.164,
                17.171,
                17.179,
                17.186,
                17.193,
                17.201,
                17.208,
                17.216,
                17.223,
                17.23,
                17.238,
                17.245,
                17.253,
                17.26,
                17.267,
                17.275,
                17.282,
                17.289,
                17.297,
                17.304,
                17.312,
                17.319,
                17.326,
                17.334,
                17.341,
                17.348,
                17.356,
                17.363,
                17.371,
                17.378,
                17.385,
                17.393,
                17.4,
                17.407,
                17.414,
                17.422,
                17.429,
                17.437,
                17.444,
                17.451,
                17.459,
                17.466,
                17.473,
                17.481,
                17.488,
                17.496,
                17.503,
                17.51,
                17.517,
                17.525,
                17.532,
                17.539,
                17.547,
                17.554,
                17.561,
                17.569,
                17.576,
                17.583,
                17.591,
                17.598,
                17.605,
                17.612,
                17.62,
                17.627,
                17.635,
                17.642,
                17.649,
                17.657,
                17.664,
                17.671,
                17.679,
                17.686,
                17.693,
                17.7,
                17.708,
                17.715,
                17.722,
                17.73,
                17.737,
                17.744,
                17.752,
                17.759,
                17.766,
                17.773,
                17.781,
                17.788,
                17.795,
                17.803,
                17.81,
                17.817,
                17.825,
                17.832,
                17.839,
                17.846,
                17.854,
                17.861,
                17.868,
                17.876,
                17.883,
                17.89,
                17.898,
                17.905,
                17.912,
                17.92,
                17.927,
                17.934,
                17.941,
                17.949,
                17.956,
                17.963,
                17.971,
                17.978,
                17.985,
                17.992,
                18,
                18.007,
                18.014,
                18.022,
                18.029,
                18.036,
                18.044,
                18.051,
                18.058,
                18.066,
                18.073,
                18.08,
                18.087,
                18.095,
                18.102,
                18.109,
                18.117,
                18.124,
                18.131,
                18.139,
                18.146,
                18.153,
                18.16,
                18.168,
                18.175,
                18.182,
                18.19,
                18.197,
                18.204,
                18.212,
                18.219,
                18.226,
                18.234,
                18.241,
                18.248,
                18.255,
                18.263,
                18.27,
                18.277,
                18.284,
                18.292,
                18.299,
                18.306,
                18.314,
                18.321,
                18.328,
                18.336,
                18.343,
                18.35,
                18.358,
                18.365,
                18.372,
                18.379,
                18.387,
                18.394,
                18.401,
                18.409,
                18.416,
                18.423,
                18.431,
                18.438,
                18.445,
                18.453,
                18.46,
                18.467,
                18.475,
                18.482,
                18.489,
                18.497,
                18.504,
                18.511,
                18.519,
                18.526,
                18.533,
                18.54,
                18.548,
                18.555,
                18.562,
                18.57,
                18.577,
                18.584,
                18.592,
                18.599,
                18.606,
                18.614,
                18.621,
                18.628,
                18.636,
                18.643,
                18.65,
                18.658,
                18.665,
                18.672,
                18.68,
                18.687,
                18.694,
                18.701,
                18.709,
                18.716,
                18.723,
                18.731,
                18.738,
                18.745,
                18.753,
                18.76,
                18.767,
                18.775,
                18.782,
                18.789,
                18.797,
                18.804,
                18.811,
                18.819,
                18.826,
                18.834,
                18.841,
                18.848,
                18.856,
                18.863,
                18.87,
                18.878,
                18.885,
                18.892,
                18.9,
                18.907,
                18.914,
                18.922,
                18.929,
                18.937,
                18.944,
                18.951,
                18.958,
                18.966,
                18.973,
                18.98,
                18.988,
                18.995,
                19.003,
                19.01,
                19.017,
                19.025,
                19.032,
                19.039,
                19.047,
                19.054,
                19.061,
                19.069,
                19.076,
                19.083,
                19.091,
                19.098,
                19.105,
                19.113,
                19.12,
                19.128,
                19.135,
                19.142,
                19.15,
                19.157,
                19.164,
                19.172,
                19.179,
                19.187,
                19.194,
                19.201,
                19.209,
                19.216,
                19.224,
                19.231,
                19.238,
                19.246,
                19.253,
                19.26,
                19.268,
                19.275,
                19.283,
                19.29,
                19.297,
                19.305,
                19.312,
                19.32,
                19.327,
                19.334,
                19.342,
                19.349,
                19.357,
                19.364,
                19.371,
                19.379,
                19.386,
                19.393,
                19.401,
                19.408,
                19.415,
                19.423,
                19.43,
                19.438,
                19.445,
                19.452,
                19.46,
                19.467,
                19.475,
                19.482,
                19.489,
                19.497,
                19.504,
                19.512,
                19.519,
                19.526,
                19.534,
                19.541,
                19.549,
                19.556,
                19.563,
                19.571,
                19.578,
                19.586,
                19.593,
                19.601,
                19.608,
                19.615,
                19.623,
                19.63,
                19.638,
                19.645,
                19.653,
                19.66,
                19.667,
                19.675,
                19.682,
                19.69,
                19.697,
                19.704,
                19.712,
                19.719,
                19.726,
                19.734,
                19.741,
                19.749,
                19.756,
                19.764,
                19.771,
                19.778,
                19.786,
                19.793,
                19.801,
                19.808,
                19.816,
                19.823,
                19.83,
                19.838,
                19.845,
                19.853,
                19.86,
                19.868,
                19.875,
                19.883,
                19.89,
                19.897,
                19.905,
                19.912,
                19.92,
                19.927,
                19.935,
                19.942,
                19.95,
                19.957,
                19.964,
                19.972,
                19.979,
                19.987,
                19.994,
                20.002,
                20.009,
                20.017,
                20.024,
                20.031,
                20.039,
                20.046,
                20.054,
                20.061,
                20.068,
                20.076,
                20.083,
                20.091,
                20.098,
                20.106,
                20.113,
                20.121,
                20.128,
                20.136,
                20.143,
                20.151,
                20.158,
                20.166,
                20.173,
                20.18,
                20.188,
                20.195,
                20.203,
                20.21,
                20.218,
                20.225,
                20.233,
                20.24,
                20.248,
                20.255,
                20.263,
                20.27,
                20.278,
                20.285,
                20.293,
                20.3,
                20.308,
                20.315,
                20.323,
                20.33,
                20.338,
                20.345,
                20.353,
                20.36,
                20.367,
                20.375,
                20.382,
                20.39,
                20.397,
                20.405,
                20.412,
                20.42,
                20.427,
                20.435,
                20.442,
                20.45,
                20.457,
                20.465,
                20.472,
                20.48,
                20.487,
                20.494,
                20.502,
                20.51,
                20.517,
                20.525,
                20.532,
                20.54,
                20.547,
                20.555,
                20.562,
                20.57,
                20.577,
                20.585,
                20.592,
                20.6,
                20.607,
                20.615,
                20.622,
                20.63,
                20.637,
                20.645,
                20.652,
                20.66,
                20.667,
                20.675,
                20.682,
                20.69,
                20.697,
                20.705,
                20.712,
                20.72,
                20.728,
                20.735,
                20.743,
                20.75,
                20.758,
                20.765,
                20.773,
                20.78,
                20.788,
                20.795,
                20.803,
                20.81,
                20.818,
                20.825,
                20.833,
                20.84,
                20.848,
                20.856,
                20.863,
                20.871,
                20.878,
                20.886,
                20.893,
                20.901,
                20.908,
                20.916,
                20.923,
                20.931,
                20.939,
                20.946,
                20.954,
                20.961,
                20.969,
                20.976,
                20.984,
                20.992,
                20.999,
                21.007,
                21.014,
                21.022,
                21.029,
                21.037,
                21.044,
                21.052,
                21.059,
                21.067,
                21.075,
                21.082,
                21.09,
                21.098,
                21.105,
                21.113,
                21.12,
                21.128,
                21.135,
                21.143,
                21.15,
                21.158,
                21.165,
                21.173,
                21.181,
                21.188,
                21.196,
                21.203,
                21.211,
                21.219,
                21.226,
                21.234,
                21.241,
                21.249,
                21.256,
                21.264,
                21.271,
                21.279,
                21.287,
                21.294,
                21.302,
                21.31,
                21.317,
                21.325,
                21.332,
                21.34,
                21.347,
                21.355,
                21.362,
                21.37,
                21.378,
                21.386,
                21.393,
                21.401,
                21.408,
                21.416,
                21.423,
                21.431,
                21.439,
                21.446,
                21.454,
                21.461,
                21.469,
                21.477,
                21.484,
                21.492,
                21.499,
                21.507,
                21.515,
                21.522,
                21.53,
                21.538,
                21.545,
                21.553,
                21.56,
                21.568,
                21.575,
                21.583,
                21.591,
                21.598,
                21.606,
                21.613,
                21.621,
                21.629,
                21.636,
                21.644,
                21.651,
                21.659,
                21.667,
                21.674,
                21.682,
                21.69,
                21.697,
                21.705,
                21.712,
                21.72,
                21.728,
                21.735,
                21.743,
                21.75,
                21.758,
                21.766,
                21.773,
                21.781,
                21.789,
                21.796,
                21.804,
                21.811,
                21.819,
                21.827,
                21.835,
                21.842,
                21.85,
                21.857,
                21.865,
                21.872,
                21.88,
                21.888,
                21.895,
                21.903,
                21.911,
                21.918,
                21.926,
                21.934,
                21.941,
                21.949,
                21.956,
                21.964,
                21.972,
                21.979,
                21.987,
                21.995,
                22.002,
                22.01,
                22.017,
                22.025,
                22.033,
                22.041,
                22.048,
                22.056,
                22.063,
                22.071,
                22.079,
                22.086,
                22.094,
                22.101,
                22.109,
                22.117,
                22.124,
                22.132,
                22.14,
                22.147,
                22.155,
                22.162,
                22.17,
                22.178,
                22.186,
                22.193,
                22.201,
                22.208,
                22.216,
                22.223,
                22.231,
                22.239,
                22.247,
                22.254,
                22.262,
                22.269,
                22.277,
                22.285,
                22.292,
                22.3,
                22.307,
                22.315,
                22.323,
                22.331,
                22.338,
                22.346,
                22.353,
                22.361,
                22.369,
                22.376,
                22.384,
                22.392,
                22.399,
                22.407,
                22.415,
                22.422,
                22.43,
                22.437,
                22.445,
                22.453,
                22.461,
                22.468,
                22.476,
                22.483,
                22.491,
                22.499,
                22.506,
                22.514,
                22.522,
                22.529,
                22.537,
                22.545,
                22.552,
                22.56,
                22.567,
                22.575,
                22.583,
                22.591,
                22.598,
                22.606,
                22.613,
                22.621,
                22.629,
                22.636,
                22.644,
                22.651,
                22.659,
                22.667,
                22.675,
                22.682,
                22.69,
                22.697,
                22.705,
                22.713,
                22.72,
                22.728,
                22.736,
                22.743,
                22.751,
                22.759,
                22.766,
                22.774,
                22.781,
                22.789,
                22.797,
                22.804,
                22.812,
                22.82,
                22.827,
                22.835,
                22.843,
                22.85,
                22.858,
                22.866,
                22.873,
                22.881,
                22.889,
                22.896,
                22.904,
                22.911,
                22.919,
                22.927,
                22.934,
                22.942,
                22.95,
                22.957,
                22.965,
                22.973,
                22.98,
                22.988,
                22.995,
                23.003,
                23.011,
                23.018,
                23.026,
                23.034,
                23.041,
                23.049,
                23.057,
                23.064,
                23.072,
                23.079,
                23.087,
                23.095,
                23.102,
                23.11,
                23.117,
                23.125,
                23.133,
                23.141,
                23.148,
                23.156,
                23.163,
                23.171,
                23.179,
                23.186,
                23.194,
                23.201,
                23.209,
                23.217

            ],
            P97: [
                4.35,
                4.324,
                4.35,
                4.382,
                4.418,
                4.456,
                4.495,
                4.535,
                4.577,
                4.619,
                4.663,
                4.708,
                4.755,
                4.804,
                4.854,
                4.905,
                4.957,
                5.01,
                5.063,
                5.116,
                5.169,
                5.223,
                5.276,
                5.329,
                5.381,
                5.434,
                5.486,
                5.537,
                5.589,
                5.639,
                5.69,
                5.74,
                5.789,
                5.838,
                5.886,
                5.934,
                5.981,
                6.028,
                6.074,
                6.119,
                6.164,
                6.208,
                6.252,
                6.296,
                6.339,
                6.381,
                6.423,
                6.464,
                6.505,
                6.545,
                6.585,
                6.625,
                6.664,
                6.702,
                6.741,
                6.778,
                6.816,
                6.852,
                6.889,
                6.925,
                6.961,
                6.996,
                7.031,
                7.066,
                7.1,
                7.134,
                7.168,
                7.201,
                7.234,
                7.266,
                7.298,
                7.33,
                7.362,
                7.393,
                7.424,
                7.455,
                7.485,
                7.515,
                7.545,
                7.575,
                7.604,
                7.633,
                7.662,
                7.69,
                7.718,
                7.746,
                7.774,
                7.801,
                7.828,
                7.855,
                7.882,
                7.909,
                7.935,
                7.961,
                7.987,
                8.013,
                8.038,
                8.063,
                8.088,
                8.113,
                8.138,
                8.163,
                8.187,
                8.211,
                8.235,
                8.259,
                8.282,
                8.306,
                8.329,
                8.352,
                8.375,
                8.398,
                8.42,
                8.443,
                8.465,
                8.487,
                8.509,
                8.531,
                8.553,
                8.575,
                8.596,
                8.617,
                8.639,
                8.66,
                8.681,
                8.701,
                8.722,
                8.743,
                8.763,
                8.783,
                8.804,
                8.824,
                8.844,
                8.864,
                8.883,
                8.903,
                8.922,
                8.942,
                8.961,
                8.98,
                8.999,
                9.018,
                9.037,
                9.056,
                9.075,
                9.093,
                9.111,
                9.13,
                9.148,
                9.166,
                9.184,
                9.202,
                9.22,
                9.238,
                9.255,
                9.273,
                9.29,
                9.308,
                9.325,
                9.342,
                9.359,
                9.376,
                9.393,
                9.41,
                9.427,
                9.443,
                9.46,
                9.476,
                9.493,
                9.509,
                9.525,
                9.541,
                9.558,
                9.574,
                9.59,
                9.605,
                9.621,
                9.637,
                9.653,
                9.668,
                9.684,
                9.699,
                9.715,
                9.73,
                9.745,
                9.76,
                9.776,
                9.791,
                9.806,
                9.821,
                9.836,
                9.85,
                9.865,
                9.88,
                9.894,
                9.909,
                9.923,
                9.938,
                9.952,
                9.966,
                9.981,
                9.995,
                10.009,
                10.023,
                10.037,
                10.051,
                10.065,
                10.079,
                10.092,
                10.106,
                10.12,
                10.133,
                10.147,
                10.16,
                10.174,
                10.187,
                10.201,
                10.214,
                10.227,
                10.24,
                10.254,
                10.267,
                10.28,
                10.293,
                10.306,
                10.319,
                10.332,
                10.344,
                10.357,
                10.37,
                10.383,
                10.395,
                10.408,
                10.42,
                10.433,
                10.445,
                10.458,
                10.47,
                10.482,
                10.495,
                10.507,
                10.519,
                10.531,
                10.543,
                10.556,
                10.568,
                10.58,
                10.592,
                10.604,
                10.616,
                10.627,
                10.639,
                10.651,
                10.663,
                10.675,
                10.686,
                10.698,
                10.71,
                10.721,
                10.733,
                10.744,
                10.756,
                10.767,
                10.779,
                10.79,
                10.802,
                10.813,
                10.824,
                10.836,
                10.847,
                10.858,
                10.869,
                10.881,
                10.892,
                10.903,
                10.914,
                10.925,
                10.936,
                10.947,
                10.958,
                10.969,
                10.98,
                10.991,
                11.002,
                11.013,
                11.024,
                11.034,
                11.045,
                11.056,
                11.067,
                11.077,
                11.088,
                11.099,
                11.109,
                11.12,
                11.131,
                11.141,
                11.152,
                11.162,
                11.173,
                11.183,
                11.194,
                11.204,
                11.215,
                11.225,
                11.236,
                11.246,
                11.256,
                11.267,
                11.277,
                11.287,
                11.298,
                11.308,
                11.318,
                11.328,
                11.338,
                11.349,
                11.359,
                11.369,
                11.379,
                11.389,
                11.399,
                11.41,
                11.42,
                11.43,
                11.44,
                11.45,
                11.46,
                11.47,
                11.48,
                11.49,
                11.5,
                11.509,
                11.52,
                11.529,
                11.539,
                11.549,
                11.559,
                11.569,
                11.579,
                11.589,
                11.599,
                11.608,
                11.618,
                11.628,
                11.638,
                11.647,
                11.657,
                11.667,
                11.677,
                11.686,
                11.696,
                11.705,
                11.715,
                11.725,
                11.734,
                11.744,
                11.754,
                11.763,
                11.773,
                11.783,
                11.792,
                11.802,
                11.811,
                11.821,
                11.83,
                11.84,
                11.849,
                11.859,
                11.868,
                11.878,
                11.888,
                11.897,
                11.906,
                11.916,
                11.925,
                11.935,
                11.944,
                11.954,
                11.963,
                11.972,
                11.982,
                11.991,
                12.001,
                12.01,
                12.019,
                12.029,
                12.038,
                12.047,
                12.056,
                12.066,
                12.075,
                12.084,
                12.094,
                12.103,
                12.112,
                12.121,
                12.131,
                12.14,
                12.149,
                12.159,
                12.168,
                12.177,
                12.186,
                12.195,
                12.204,
                12.214,
                12.223,
                12.232,
                12.241,
                12.25,
                12.259,
                12.269,
                12.278,
                12.287,
                12.296,
                12.305,
                12.314,
                12.324,
                12.333,
                12.342,
                12.351,
                12.36,
                12.369,
                12.378,
                12.387,
                12.396,
                12.405,
                12.414,
                12.423,
                12.432,
                12.441,
                12.45,
                12.459,
                12.469,
                12.478,
                12.487,
                12.496,
                12.505,
                12.514,
                12.523,
                12.532,
                12.541,
                12.55,
                12.559,
                12.568,
                12.577,
                12.586,
                12.595,
                12.603,
                12.612,
                12.621,
                12.63,
                12.639,
                12.648,
                12.657,
                12.666,
                12.675,
                12.684,
                12.693,
                12.702,
                12.711,
                12.72,
                12.729,
                12.738,
                12.746,
                12.755,
                12.765,
                12.773,
                12.782,
                12.791,
                12.8,
                12.809,
                12.818,
                12.827,
                12.835,
                12.844,
                12.853,
                12.862,
                12.871,
                12.88,
                12.889,
                12.898,
                12.907,
                12.915,
                12.924,
                12.933,
                12.942,
                12.951,
                12.96,
                12.968,
                12.977,
                12.986,
                12.995,
                13.004,
                13.013,
                13.021,
                13.03,
                13.039,
                13.048,
                13.057,
                13.066,
                13.074,
                13.083,
                13.092,
                13.101,
                13.11,
                13.118,
                13.127,
                13.136,
                13.145,
                13.154,
                13.163,
                13.171,
                13.18,
                13.189,
                13.198,
                13.206,
                13.215,
                13.224,
                13.233,
                13.241,
                13.25,
                13.259,
                13.268,
                13.277,
                13.285,
                13.294,
                13.303,
                13.312,
                13.32,
                13.329,
                13.338,
                13.347,
                13.355,
                13.364,
                13.373,
                13.382,
                13.39,
                13.399,
                13.408,
                13.417,
                13.425,
                13.434,
                13.443,
                13.452,
                13.46,
                13.469,
                13.478,
                13.487,
                13.495,
                13.504,
                13.513,
                13.521,
                13.53,
                13.539,
                13.548,
                13.556,
                13.565,
                13.574,
                13.583,
                13.591,
                13.6,
                13.609,
                13.617,
                13.626,
                13.635,
                13.643,
                13.652,
                13.661,
                13.669,
                13.678,
                13.687,
                13.696,
                13.704,
                13.713,
                13.722,
                13.731,
                13.739,
                13.748,
                13.757,
                13.765,
                13.774,
                13.783,
                13.791,
                13.8,
                13.809,
                13.818,
                13.826,
                13.835,
                13.844,
                13.852,
                13.861,
                13.87,
                13.878,
                13.887,
                13.896,
                13.904,
                13.913,
                13.922,
                13.931,
                13.939,
                13.948,
                13.957,
                13.966,
                13.974,
                13.983,
                13.991,
                14,
                14.009,
                14.018,
                14.027,
                14.035,
                14.044,
                14.053,
                14.061,
                14.07,
                14.079,
                14.087,
                14.096,
                14.105,
                14.113,
                14.122,
                14.131,
                14.14,
                14.148,
                14.157,
                14.166,
                14.175,
                14.183,
                14.192,
                14.201,
                14.209,
                14.218,
                14.227,
                14.235,
                14.244,
                14.253,
                14.262,
                14.27,
                14.279,
                14.288,
                14.297,
                14.305,
                14.314,
                14.323,
                14.331,
                14.34,
                14.349,
                14.358,
                14.366,
                14.375,
                14.384,
                14.393,
                14.401,
                14.41,
                14.419,
                14.428,
                14.436,
                14.445,
                14.454,
                14.462,
                14.471,
                14.48,
                14.488,
                14.497,
                14.506,
                14.515,
                14.523,
                14.532,
                14.541,
                14.55,
                14.558,
                14.567,
                14.576,
                14.585,
                14.593,
                14.602,
                14.611,
                14.62,
                14.628,
                14.637,
                14.646,
                14.654,
                14.663,
                14.672,
                14.681,
                14.689,
                14.698,
                14.707,
                14.716,
                14.724,
                14.733,
                14.742,
                14.751,
                14.759,
                14.768,
                14.777,
                14.785,
                14.794,
                14.803,
                14.812,
                14.821,
                14.829,
                14.838,
                14.847,
                14.855,
                14.864,
                14.873,
                14.882,
                14.89,
                14.899,
                14.908,
                14.917,
                14.925,
                14.934,
                14.943,
                14.952,
                14.96,
                14.969,
                14.978,
                14.986,
                14.995,
                15.004,
                15.013,
                15.021,
                15.03,
                15.039,
                15.048,
                15.056,
                15.065,
                15.074,
                15.083,
                15.091,
                15.1,
                15.109,
                15.118,
                15.126,
                15.135,
                15.144,
                15.152,
                15.161,
                15.17,
                15.178,
                15.187,
                15.196,
                15.205,
                15.213,
                15.222,
                15.231,
                15.24,
                15.248,
                15.257,
                15.266,
                15.274,
                15.283,
                15.292,
                15.3,
                15.309,
                15.318,
                15.327,
                15.335,
                15.344,
                15.353,
                15.361,
                15.37,
                15.379,
                15.387,
                15.396,
                15.405,
                15.413,
                15.422,
                15.431,
                15.439,
                15.448,
                15.457,
                15.465,
                15.474,
                15.483,
                15.491,
                15.5,
                15.509,
                15.517,
                15.526,
                15.535,
                15.543,
                15.552,
                15.56,
                15.569,
                15.578,
                15.586,
                15.595,
                15.603,
                15.612,
                15.62,
                15.629,
                15.638,
                15.646,
                15.655,
                15.663,
                15.672,
                15.681,
                15.689,
                15.698,
                15.706,
                15.715,
                15.724,
                15.732,
                15.741,
                15.749,
                15.758,
                15.766,
                15.775,
                15.783,
                15.792,
                15.8,
                15.809,
                15.818,
                15.826,
                15.835,
                15.843,
                15.851,
                15.86,
                15.868,
                15.877,
                15.885,
                15.894,
                15.902,
                15.911,
                15.919,
                15.928,
                15.936,
                15.945,
                15.953,
                15.962,
                15.97,
                15.979,
                15.987,
                15.995,
                16.004,
                16.012,
                16.021,
                16.029,
                16.038,
                16.046,
                16.054,
                16.063,
                16.071,
                16.079,
                16.088,
                16.096,
                16.105,
                16.113,
                16.121,
                16.13,
                16.138,
                16.147,
                16.155,
                16.163,
                16.172,
                16.18,
                16.188,
                16.197,
                16.205,
                16.213,
                16.221,
                16.23,
                16.238,
                16.246,
                16.255,
                16.263,
                16.271,
                16.28,
                16.288,
                16.296,
                16.304,
                16.313,
                16.321,
                16.329,
                16.337,
                16.346,
                16.354,
                16.362,
                16.37,
                16.379,
                16.387,
                16.395,
                16.403,
                16.412,
                16.42,
                16.428,
                16.436,
                16.444,
                16.452,
                16.461,
                16.469,
                16.477,
                16.485,
                16.494,
                16.502,
                16.51,
                16.518,
                16.526,
                16.534,
                16.542,
                16.551,
                16.559,
                16.567,
                16.575,
                16.583,
                16.591,
                16.599,
                16.608,
                16.616,
                16.624,
                16.632,
                16.64,
                16.648,
                16.656,
                16.664,
                16.672,
                16.68,
                16.689,
                16.697,
                16.705,
                16.713,
                16.721,
                16.729,
                16.737,
                16.745,
                16.753,
                16.761,
                16.769,
                16.777,
                16.785,
                16.793,
                16.801,
                16.809,
                16.817,
                16.825,
                16.833,
                16.841,
                16.849,
                16.857,
                16.865,
                16.873,
                16.881,
                16.889,
                16.897,
                16.905,
                16.913,
                16.921,
                16.929,
                16.937,
                16.945,
                16.953,
                16.961,
                16.969,
                16.977,
                16.985,
                16.993,
                17.001,
                17.009,
                17.017,
                17.024,
                17.032,
                17.04,
                17.048,
                17.056,
                17.064,
                17.072,
                17.08,
                17.088,
                17.096,
                17.103,
                17.111,
                17.119,
                17.127,
                17.135,
                17.143,
                17.151,
                17.159,
                17.167,
                17.174,
                17.182,
                17.19,
                17.198,
                17.206,
                17.214,
                17.222,
                17.229,
                17.237,
                17.245,
                17.253,
                17.261,
                17.269,
                17.276,
                17.284,
                17.292,
                17.3,
                17.308,
                17.315,
                17.323,
                17.331,
                17.339,
                17.347,
                17.355,
                17.362,
                17.37,
                17.378,
                17.386,
                17.394,
                17.401,
                17.409,
                17.417,
                17.425,
                17.432,
                17.44,
                17.448,
                17.456,
                17.463,
                17.471,
                17.479,
                17.487,
                17.494,
                17.502,
                17.51,
                17.518,
                17.526,
                17.533,
                17.541,
                17.549,
                17.556,
                17.564,
                17.572,
                17.58,
                17.587,
                17.595,
                17.603,
                17.611,
                17.618,
                17.626,
                17.634,
                17.642,
                17.649,
                17.657,
                17.665,
                17.672,
                17.68,
                17.688,
                17.695,
                17.703,
                17.711,
                17.719,
                17.726,
                17.734,
                17.742,
                17.749,
                17.757,
                17.765,
                17.772,
                17.78,
                17.788,
                17.795,
                17.803,
                17.811,
                17.819,
                17.826,
                17.834,
                17.841,
                17.849,
                17.857,
                17.864,
                17.872,
                17.88,
                17.888,
                17.895,
                17.903,
                17.91,
                17.918,
                17.926,
                17.933,
                17.941,
                17.949,
                17.956,
                17.964,
                17.972,
                17.98,
                17.987,
                17.995,
                18.002,
                18.01,
                18.017,
                18.025,
                18.033,
                18.041,
                18.048,
                18.056,
                18.064,
                18.071,
                18.079,
                18.086,
                18.094,
                18.101,
                18.109,
                18.117,
                18.125,
                18.132,
                18.14,
                18.147,
                18.155,
                18.163,
                18.17,
                18.178,
                18.185,
                18.193,
                18.201,
                18.208,
                18.216,
                18.224,
                18.231,
                18.239,
                18.246,
                18.254,
                18.262,
                18.269,
                18.277,
                18.285,
                18.292,
                18.3,
                18.307,
                18.315,
                18.322,
                18.33,
                18.338,
                18.345,
                18.353,
                18.361,
                18.368,
                18.376,
                18.384,
                18.391,
                18.399,
                18.406,
                18.414,
                18.421,
                18.429,
                18.437,
                18.445,
                18.452,
                18.46,
                18.467,
                18.475,
                18.482,
                18.49,
                18.498,
                18.505,
                18.513,
                18.52,
                18.528,
                18.536,
                18.544,
                18.551,
                18.559,
                18.566,
                18.574,
                18.581,
                18.589,
                18.596,
                18.604,
                18.612,
                18.619,
                18.627,
                18.635,
                18.642,
                18.65,
                18.658,
                18.665,
                18.673,
                18.68,
                18.688,
                18.695,
                18.703,
                18.711,
                18.718,
                18.726,
                18.734,
                18.741,
                18.749,
                18.756,
                18.764,
                18.772,
                18.779,
                18.787,
                18.794,
                18.802,
                18.809,
                18.817,
                18.825,
                18.832,
                18.84,
                18.848,
                18.855,
                18.863,
                18.871,
                18.878,
                18.886,
                18.893,
                18.901,
                18.909,
                18.916,
                18.924,
                18.931,
                18.939,
                18.947,
                18.954,
                18.962,
                18.969,
                18.977,
                18.985,
                18.992,
                19,
                19.008,
                19.015,
                19.023,
                19.031,
                19.038,
                19.046,
                19.054,
                19.061,
                19.069,
                19.076,
                19.084,
                19.092,
                19.099,
                19.107,
                19.114,
                19.122,
                19.13,
                19.137,
                19.145,
                19.153,
                19.16,
                19.168,
                19.176,
                19.183,
                19.191,
                19.199,
                19.206,
                19.214,
                19.221,
                19.229,
                19.237,
                19.244,
                19.252,
                19.26,
                19.267,
                19.275,
                19.282,
                19.29,
                19.298,
                19.305,
                19.313,
                19.321,
                19.329,
                19.336,
                19.344,
                19.352,
                19.359,
                19.367,
                19.375,
                19.382,
                19.39,
                19.397,
                19.405,
                19.413,
                19.42,
                19.428,
                19.436,
                19.443,
                19.451,
                19.459,
                19.466,
                19.474,
                19.482,
                19.489,
                19.497,
                19.505,
                19.513,
                19.52,
                19.528,
                19.536,
                19.543,
                19.551,
                19.558,
                19.566,
                19.574,
                19.581,
                19.589,
                19.597,
                19.604,
                19.612,
                19.62,
                19.627,
                19.635,
                19.643,
                19.651,
                19.658,
                19.666,
                19.674,
                19.682,
                19.689,
                19.697,
                19.705,
                19.712,
                19.72,
                19.727,
                19.735,
                19.743,
                19.75,
                19.758,
                19.766,
                19.774,
                19.781,
                19.789,
                19.797,
                19.805,
                19.812,
                19.82,
                19.828,
                19.835,
                19.843,
                19.851,
                19.858,
                19.866,
                19.874,
                19.882,
                19.889,
                19.897,
                19.905,
                19.913,
                19.92,
                19.928,
                19.936,
                19.943,
                19.951,
                19.959,
                19.966,
                19.974,
                19.982,
                19.99,
                19.997,
                20.005,
                20.013,
                20.021,
                20.028,
                20.036,
                20.044,
                20.051,
                20.059,
                20.067,
                20.074,
                20.082,
                20.09,
                20.098,
                20.106,
                20.114,
                20.121,
                20.129,
                20.136,
                20.144,
                20.152,
                20.16,
                20.167,
                20.175,
                20.183,
                20.191,
                20.199,
                20.206,
                20.214,
                20.222,
                20.229,
                20.237,
                20.245,
                20.253,
                20.261,
                20.268,
                20.276,
                20.284,
                20.291,
                20.299,
                20.307,
                20.315,
                20.322,
                20.33,
                20.338,
                20.346,
                20.354,
                20.361,
                20.369,
                20.377,
                20.384,
                20.392,
                20.4,
                20.408,
                20.416,
                20.424,
                20.431,
                20.439,
                20.447,
                20.454,
                20.462,
                20.47,
                20.478,
                20.486,
                20.493,
                20.501,
                20.509,
                20.517,
                20.524,
                20.532,
                20.54,
                20.548,
                20.556,
                20.563,
                20.571,
                20.579,
                20.587,
                20.594,
                20.602,
                20.61,
                20.618,
                20.626,
                20.633,
                20.641,
                20.649,
                20.657,
                20.665,
                20.673,
                20.681,
                20.688,
                20.696,
                20.704,
                20.711,
                20.719,
                20.727,
                20.735,
                20.743,
                20.751,
                20.758,
                20.766,
                20.774,
                20.782,
                20.79,
                20.798,
                20.805,
                20.813,
                20.821,
                20.828,
                20.836,
                20.844,
                20.852,
                20.86,
                20.868,
                20.875,
                20.883,
                20.891,
                20.899,
                20.907,
                20.915,
                20.923,
                20.93,
                20.938,
                20.946,
                20.954,
                20.962,
                20.97,
                20.978,
                20.985,
                20.993,
                21.001,
                21.009,
                21.017,
                21.025,
                21.032,
                21.04,
                21.048,
                21.056,
                21.063,
                21.071,
                21.079,
                21.087,
                21.095,
                21.103,
                21.11,
                21.118,
                21.126,
                21.134,
                21.142,
                21.15,
                21.158,
                21.166,
                21.173,
                21.181,
                21.189,
                21.197,
                21.205,
                21.213,
                21.221,
                21.228,
                21.236,
                21.244,
                21.252,
                21.26,
                21.268,
                21.276,
                21.284,
                21.292,
                21.3,
                21.308,
                21.315,
                21.323,
                21.331,
                21.339,
                21.347,
                21.355,
                21.363,
                21.37,
                21.378,
                21.386,
                21.394,
                21.402,
                21.41,
                21.418,
                21.426,
                21.434,
                21.441,
                21.449,
                21.457,
                21.465,
                21.473,
                21.481,
                21.489,
                21.496,
                21.504,
                21.513,
                21.521,
                21.528,
                21.536,
                21.544,
                21.552,
                21.56,
                21.568,
                21.576,
                21.584,
                21.592,
                21.599,
                21.607,
                21.615,
                21.623,
                21.631,
                21.639,
                21.647,
                21.655,
                21.663,
                21.671,
                21.679,
                21.687,
                21.695,
                21.703,
                21.711,
                21.718,
                21.726,
                21.734,
                21.742,
                21.75,
                21.758,
                21.766,
                21.774,
                21.782,
                21.79,
                21.798,
                21.806,
                21.814,
                21.822,
                21.83,
                21.838,
                21.845,
                21.853,
                21.861,
                21.869,
                21.878,
                21.885,
                21.893,
                21.901,
                21.909,
                21.917,
                21.925,
                21.933,
                21.941,
                21.949,
                21.957,
                21.965,
                21.973,
                21.981,
                21.989,
                21.997,
                22.005,
                22.013,
                22.021,
                22.028,
                22.036,
                22.044,
                22.052,
                22.06,
                22.069,
                22.077,
                22.084,
                22.092,
                22.1,
                22.108,
                22.116,
                22.124,
                22.132,
                22.14,
                22.148,
                22.156,
                22.164,
                22.172,
                22.18,
                22.188,
                22.196,
                22.204,
                22.212,
                22.22,
                22.228,
                22.236,
                22.244,
                22.252,
                22.26,
                22.268,
                22.276,
                22.284,
                22.292,
                22.3,
                22.308,
                22.316,
                22.324,
                22.332,
                22.34,
                22.348,
                22.356,
                22.364,
                22.372,
                22.38,
                22.388,
                22.396,
                22.404,
                22.412,
                22.42,
                22.428,
                22.436,
                22.444,
                22.452,
                22.46,
                22.468,
                22.476,
                22.484,
                22.492,
                22.5,
                22.508,
                22.516,
                22.524,
                22.532,
                22.54,
                22.548,
                22.556,
                22.564,
                22.572,
                22.58,
                22.588,
                22.596,
                22.604,
                22.612,
                22.62,
                22.628,
                22.636,
                22.644,
                22.652,
                22.66,
                22.668,
                22.676,
                22.684,
                22.692,
                22.7,
                22.708,
                22.716,
                22.724,
                22.732,
                22.74,
                22.748,
                22.756,
                22.765,
                22.773,
                22.78,
                22.789,
                22.796,
                22.804,
                22.812,
                22.82,
                22.828,
                22.837,
                22.845,
                22.853,
                22.861,
                22.869,
                22.877,
                22.885,
                22.893,
                22.901,
                22.909,
                22.917,
                22.925,
                22.933,
                22.941,
                22.949,
                22.957,
                22.965,
                22.973,
                22.981,
                22.989,
                22.997,
                23.005,
                23.013,
                23.021,
                23.029,
                23.037,
                23.045,
                23.053,
                23.061,
                23.07,
                23.077,
                23.085,
                23.093,
                23.101,
                23.109,
                23.117,
                23.126,
                23.134,
                23.142,
                23.15,
                23.158,
                23.166,
                23.174,
                23.182,
                23.19,
                23.198,
                23.206,
                23.214,
                23.222,
                23.23,
                23.238,
                23.246,
                23.254,
                23.262,
                23.271,
                23.279,
                23.287,
                23.295,
                23.303,
                23.311,
                23.318,
                23.326,
                23.335,
                23.343,
                23.351,
                23.359,
                23.367,
                23.375,
                23.383,
                23.391,
                23.399,
                23.407,
                23.415,
                23.423,
                23.431,
                23.439,
                23.447,
                23.455,
                23.463,
                23.471,
                23.479,
                23.487,
                23.496,
                23.504,
                23.512,
                23.52,
                23.528,
                23.536,
                23.544,
                23.552,
                23.56,
                23.568,
                23.576,
                23.584,
                23.592,
                23.6,
                23.608,
                23.616,
                23.624,
                23.632,
                23.64,
                23.648,
                23.657,
                23.665,
                23.673,
                23.681,
                23.689,
                23.697,
                23.705,
                23.713,
                23.721,
                23.729,
                23.737,
                23.745,
                23.753,
                23.761,
                23.769,
                23.777,
                23.785,
                23.793,
                23.801,
                23.809,
                23.817,
                23.826,
                23.833,
                23.841,
                23.849,
                23.857,
                23.865,
                23.873,
                23.881,
                23.889,
                23.897,
                23.905,
                23.914,
                23.922,
                23.93,
                23.938,
                23.946,
                23.954,
                23.962,
                23.97,
                23.978,
                23.986,
                23.994,
                24.002

            ],
            P99: [
                4.613,
                4.59,
                4.618,
                4.652,
                4.69,
                4.73,
                4.771,
                4.813,
                4.857,
                4.901,
                4.947,
                4.994,
                5.044,
                5.094,
                5.146,
                5.2,
                5.254,
                5.309,
                5.365,
                5.42,
                5.476,
                5.532,
                5.587,
                5.642,
                5.697,
                5.751,
                5.806,
                5.859,
                5.913,
                5.966,
                6.018,
                6.07,
                6.121,
                6.172,
                6.222,
                6.272,
                6.321,
                6.369,
                6.417,
                6.465,
                6.511,
                6.557,
                6.603,
                6.648,
                6.692,
                6.736,
                6.78,
                6.822,
                6.865,
                6.907,
                6.948,
                6.989,
                7.029,
                7.069,
                7.109,
                7.148,
                7.186,
                7.224,
                7.262,
                7.299,
                7.336,
                7.373,
                7.409,
                7.445,
                7.48,
                7.515,
                7.55,
                7.584,
                7.618,
                7.652,
                7.685,
                7.718,
                7.751,
                7.783,
                7.815,
                7.847,
                7.878,
                7.909,
                7.94,
                7.971,
                8.001,
                8.031,
                8.06,
                8.09,
                8.119,
                8.148,
                8.176,
                8.204,
                8.232,
                8.26,
                8.288,
                8.315,
                8.342,
                8.369,
                8.396,
                8.423,
                8.449,
                8.475,
                8.501,
                8.526,
                8.552,
                8.577,
                8.602,
                8.627,
                8.652,
                8.676,
                8.701,
                8.725,
                8.749,
                8.773,
                8.796,
                8.82,
                8.843,
                8.867,
                8.89,
                8.913,
                8.935,
                8.958,
                8.981,
                9.003,
                9.025,
                9.047,
                9.069,
                9.091,
                9.112,
                9.134,
                9.155,
                9.177,
                9.198,
                9.219,
                9.24,
                9.261,
                9.281,
                9.302,
                9.322,
                9.343,
                9.363,
                9.383,
                9.403,
                9.423,
                9.443,
                9.462,
                9.482,
                9.501,
                9.52,
                9.54,
                9.559,
                9.578,
                9.597,
                9.616,
                9.634,
                9.653,
                9.671,
                9.69,
                9.708,
                9.727,
                9.745,
                9.763,
                9.78,
                9.798,
                9.816,
                9.834,
                9.852,
                9.869,
                9.886,
                9.904,
                9.921,
                9.938,
                9.955,
                9.973,
                9.989,
                10.006,
                10.023,
                10.04,
                10.056,
                10.073,
                10.089,
                10.106,
                10.122,
                10.138,
                10.155,
                10.171,
                10.187,
                10.203,
                10.219,
                10.235,
                10.25,
                10.266,
                10.282,
                10.297,
                10.313,
                10.328,
                10.344,
                10.359,
                10.374,
                10.39,
                10.405,
                10.42,
                10.435,
                10.45,
                10.465,
                10.48,
                10.494,
                10.509,
                10.524,
                10.538,
                10.553,
                10.567,
                10.582,
                10.596,
                10.611,
                10.625,
                10.639,
                10.653,
                10.667,
                10.681,
                10.695,
                10.709,
                10.723,
                10.737,
                10.751,
                10.764,
                10.778,
                10.792,
                10.805,
                10.819,
                10.833,
                10.846,
                10.859,
                10.873,
                10.886,
                10.899,
                10.912,
                10.926,
                10.939,
                10.952,
                10.965,
                10.978,
                10.991,
                11.004,
                11.017,
                11.029,
                11.042,
                11.055,
                11.068,
                11.081,
                11.093,
                11.106,
                11.118,
                11.131,
                11.143,
                11.156,
                11.168,
                11.18,
                11.193,
                11.205,
                11.217,
                11.23,
                11.242,
                11.254,
                11.266,
                11.278,
                11.29,
                11.303,
                11.314,
                11.326,
                11.338,
                11.35,
                11.362,
                11.374,
                11.386,
                11.398,
                11.41,
                11.421,
                11.433,
                11.445,
                11.456,
                11.468,
                11.48,
                11.491,
                11.503,
                11.514,
                11.526,
                11.537,
                11.549,
                11.56,
                11.572,
                11.583,
                11.594,
                11.606,
                11.617,
                11.628,
                11.64,
                11.651,
                11.662,
                11.673,
                11.684,
                11.696,
                11.707,
                11.718,
                11.729,
                11.74,
                11.751,
                11.762,
                11.773,
                11.784,
                11.795,
                11.806,
                11.816,
                11.828,
                11.838,
                11.849,
                11.86,
                11.871,
                11.882,
                11.892,
                11.903,
                11.914,
                11.925,
                11.935,
                11.946,
                11.956,
                11.967,
                11.978,
                11.988,
                11.999,
                12.01,
                12.02,
                12.031,
                12.041,
                12.052,
                12.062,
                12.073,
                12.083,
                12.094,
                12.104,
                12.115,
                12.125,
                12.136,
                12.146,
                12.156,
                12.167,
                12.177,
                12.187,
                12.198,
                12.208,
                12.218,
                12.228,
                12.239,
                12.249,
                12.259,
                12.27,
                12.28,
                12.29,
                12.3,
                12.31,
                12.32,
                12.331,
                12.341,
                12.351,
                12.361,
                12.371,
                12.381,
                12.392,
                12.402,
                12.412,
                12.422,
                12.432,
                12.442,
                12.452,
                12.462,
                12.472,
                12.482,
                12.492,
                12.502,
                12.512,
                12.522,
                12.532,
                12.542,
                12.552,
                12.562,
                12.572,
                12.582,
                12.592,
                12.601,
                12.611,
                12.621,
                12.631,
                12.641,
                12.651,
                12.66,
                12.67,
                12.68,
                12.69,
                12.7,
                12.71,
                12.719,
                12.729,
                12.739,
                12.749,
                12.759,
                12.769,
                12.778,
                12.788,
                12.798,
                12.807,
                12.817,
                12.827,
                12.837,
                12.846,
                12.856,
                12.866,
                12.875,
                12.885,
                12.895,
                12.904,
                12.914,
                12.924,
                12.933,
                12.943,
                12.953,
                12.962,
                12.972,
                12.981,
                12.991,
                13.001,
                13.01,
                13.02,
                13.03,
                13.039,
                13.049,
                13.058,
                13.068,
                13.077,
                13.087,
                13.097,
                13.106,
                13.116,
                13.125,
                13.135,
                13.145,
                13.154,
                13.164,
                13.173,
                13.183,
                13.192,
                13.202,
                13.211,
                13.221,
                13.23,
                13.24,
                13.249,
                13.259,
                13.268,
                13.278,
                13.287,
                13.297,
                13.306,
                13.316,
                13.325,
                13.335,
                13.344,
                13.353,
                13.363,
                13.372,
                13.382,
                13.391,
                13.401,
                13.41,
                13.42,
                13.429,
                13.439,
                13.448,
                13.457,
                13.467,
                13.476,
                13.486,
                13.495,
                13.505,
                13.514,
                13.523,
                13.533,
                13.542,
                13.552,
                13.561,
                13.571,
                13.58,
                13.589,
                13.598,
                13.608,
                13.617,
                13.627,
                13.636,
                13.646,
                13.655,
                13.664,
                13.674,
                13.683,
                13.692,
                13.702,
                13.711,
                13.721,
                13.73,
                13.739,
                13.749,
                13.758,
                13.767,
                13.777,
                13.786,
                13.795,
                13.805,
                13.814,
                13.824,
                13.833,
                13.842,
                13.851,
                13.861,
                13.87,
                13.879,
                13.889,
                13.898,
                13.907,
                13.917,
                13.926,
                13.935,
                13.945,
                13.954,
                13.964,
                13.973,
                13.982,
                13.991,
                14.001,
                14.01,
                14.019,
                14.029,
                14.038,
                14.047,
                14.056,
                14.066,
                14.075,
                14.084,
                14.094,
                14.103,
                14.112,
                14.122,
                14.131,
                14.14,
                14.15,
                14.159,
                14.168,
                14.177,
                14.187,
                14.196,
                14.205,
                14.215,
                14.224,
                14.233,
                14.242,
                14.252,
                14.261,
                14.271,
                14.28,
                14.289,
                14.298,
                14.307,
                14.317,
                14.326,
                14.335,
                14.344,
                14.354,
                14.363,
                14.372,
                14.382,
                14.391,
                14.4,
                14.41,
                14.419,
                14.428,
                14.437,
                14.447,
                14.456,
                14.465,
                14.474,
                14.484,
                14.493,
                14.502,
                14.511,
                14.521,
                14.53,
                14.539,
                14.549,
                14.558,
                14.567,
                14.576,
                14.586,
                14.595,
                14.604,
                14.613,
                14.623,
                14.632,
                14.641,
                14.651,
                14.66,
                14.669,
                14.679,
                14.688,
                14.697,
                14.706,
                14.716,
                14.725,
                14.734,
                14.744,
                14.753,
                14.762,
                14.772,
                14.781,
                14.79,
                14.799,
                14.809,
                14.818,
                14.827,
                14.837,
                14.846,
                14.855,
                14.865,
                14.874,
                14.883,
                14.892,
                14.902,
                14.911,
                14.92,
                14.93,
                14.939,
                14.948,
                14.958,
                14.967,
                14.976,
                14.986,
                14.995,
                15.004,
                15.014,
                15.023,
                15.032,
                15.041,
                15.051,
                15.06,
                15.069,
                15.079,
                15.088,
                15.097,
                15.107,
                15.116,
                15.125,
                15.135,
                15.144,
                15.153,
                15.163,
                15.172,
                15.181,
                15.191,
                15.2,
                15.209,
                15.219,
                15.228,
                15.237,
                15.247,
                15.256,
                15.265,
                15.275,
                15.284,
                15.293,
                15.303,
                15.312,
                15.321,
                15.331,
                15.34,
                15.35,
                15.359,
                15.368,
                15.377,
                15.387,
                15.396,
                15.405,
                15.415,
                15.424,
                15.434,
                15.443,
                15.452,
                15.461,
                15.471,
                15.48,
                15.49,
                15.499,
                15.508,
                15.518,
                15.527,
                15.536,
                15.546,
                15.555,
                15.564,
                15.574,
                15.583,
                15.593,
                15.602,
                15.611,
                15.62,
                15.63,
                15.639,
                15.649,
                15.658,
                15.667,
                15.676,
                15.686,
                15.695,
                15.705,
                15.714,
                15.724,
                15.733,
                15.742,
                15.751,
                15.761,
                15.77,
                15.78,
                15.789,
                15.798,
                15.808,
                15.817,
                15.827,
                15.836,
                15.845,
                15.854,
                15.864,
                15.873,
                15.883,
                15.892,
                15.901,
                15.911,
                15.92,
                15.929,
                15.939,
                15.948,
                15.957,
                15.967,
                15.976,
                15.985,
                15.995,
                16.004,
                16.014,
                16.023,
                16.032,
                16.042,
                16.051,
                16.06,
                16.069,
                16.079,
                16.088,
                16.097,
                16.107,
                16.116,
                16.125,
                16.135,
                16.144,
                16.154,
                16.163,
                16.172,
                16.182,
                16.191,
                16.2,
                16.209,
                16.219,
                16.228,
                16.237,
                16.246,
                16.256,
                16.265,
                16.274,
                16.284,
                16.293,
                16.302,
                16.312,
                16.321,
                16.33,
                16.339,
                16.349,
                16.358,
                16.367,
                16.377,
                16.386,
                16.395,
                16.404,
                16.414,
                16.423,
                16.432,
                16.441,
                16.45,
                16.46,
                16.469,
                16.478,
                16.487,
                16.497,
                16.506,
                16.515,
                16.524,
                16.533,
                16.543,
                16.552,
                16.561,
                16.57,
                16.579,
                16.589,
                16.598,
                16.607,
                16.616,
                16.625,
                16.635,
                16.644,
                16.653,
                16.662,
                16.671,
                16.68,
                16.69,
                16.698,
                16.707,
                16.717,
                16.726,
                16.735,
                16.744,
                16.753,
                16.762,
                16.772,
                16.781,
                16.79,
                16.799,
                16.808,
                16.817,
                16.826,
                16.835,
                16.844,
                16.853,
                16.863,
                16.872,
                16.881,
                16.89,
                16.899,
                16.907,
                16.916,
                16.926,
                16.935,
                16.944,
                16.953,
                16.962,
                16.971,
                16.98,
                16.989,
                16.998,
                17.007,
                17.016,
                17.025,
                17.034,
                17.043,
                17.052,
                17.061,
                17.07,
                17.078,
                17.087,
                17.096,
                17.105,
                17.114,
                17.123,
                17.132,
                17.141,
                17.15,
                17.159,
                17.168,
                17.177,
                17.186,
                17.195,
                17.204,
                17.212,
                17.221,
                17.23,
                17.239,
                17.248,
                17.257,
                17.266,
                17.275,
                17.283,
                17.292,
                17.301,
                17.31,
                17.319,
                17.328,
                17.336,
                17.345,
                17.354,
                17.363,
                17.372,
                17.381,
                17.389,
                17.398,
                17.407,
                17.416,
                17.425,
                17.434,
                17.442,
                17.451,
                17.46,
                17.468,
                17.477,
                17.486,
                17.495,
                17.504,
                17.512,
                17.521,
                17.53,
                17.539,
                17.547,
                17.556,
                17.564,
                17.573,
                17.582,
                17.591,
                17.6,
                17.608,
                17.617,
                17.626,
                17.634,
                17.643,
                17.652,
                17.66,
                17.669,
                17.678,
                17.686,
                17.695,
                17.704,
                17.713,
                17.721,
                17.729,
                17.738,
                17.747,
                17.755,
                17.764,
                17.773,
                17.781,
                17.79,
                17.799,
                17.807,
                17.816,
                17.824,
                17.833,
                17.842,
                17.85,
                17.859,
                17.868,
                17.876,
                17.884,
                17.893,
                17.902,
                17.91,
                17.919,
                17.928,
                17.936,
                17.945,
                17.953,
                17.962,
                17.97,
                17.979,
                17.987,
                17.996,
                18.005,
                18.013,
                18.022,
                18.03,
                18.038,
                18.047,
                18.056,
                18.064,
                18.073,
                18.081,
                18.09,
                18.098,
                18.107,
                18.115,
                18.124,
                18.132,
                18.141,
                18.149,
                18.157,
                18.166,
                18.175,
                18.183,
                18.192,
                18.2,
                18.209,
                18.217,
                18.225,
                18.234,
                18.242,
                18.251,
                18.259,
                18.268,
                18.276,
                18.284,
                18.293,
                18.301,
                18.31,
                18.318,
                18.326,
                18.335,
                18.343,
                18.352,
                18.36,
                18.369,
                18.377,
                18.385,
                18.394,
                18.402,
                18.411,
                18.419,
                18.427,
                18.436,
                18.444,
                18.453,
                18.461,
                18.469,
                18.478,
                18.486,
                18.494,
                18.503,
                18.511,
                18.519,
                18.528,
                18.536,
                18.545,
                18.553,
                18.561,
                18.57,
                18.578,
                18.587,
                18.595,
                18.603,
                18.611,
                18.62,
                18.628,
                18.637,
                18.645,
                18.653,
                18.661,
                18.67,
                18.678,
                18.686,
                18.695,
                18.703,
                18.711,
                18.719,
                18.728,
                18.736,
                18.745,
                18.753,
                18.761,
                18.77,
                18.778,
                18.786,
                18.794,
                18.803,
                18.811,
                18.82,
                18.827,
                18.836,
                18.844,
                18.853,
                18.861,
                18.869,
                18.877,
                18.886,
                18.894,
                18.902,
                18.911,
                18.919,
                18.927,
                18.935,
                18.944,
                18.952,
                18.96,
                18.968,
                18.977,
                18.985,
                18.993,
                19.001,
                19.01,
                19.018,
                19.027,
                19.034,
                19.043,
                19.051,
                19.06,
                19.068,
                19.076,
                19.084,
                19.092,
                19.101,
                19.109,
                19.117,
                19.125,
                19.134,
                19.142,
                19.15,
                19.158,
                19.167,
                19.175,
                19.183,
                19.191,
                19.2,
                19.208,
                19.216,
                19.224,
                19.233,
                19.241,
                19.249,
                19.257,
                19.266,
                19.274,
                19.282,
                19.29,
                19.299,
                19.307,
                19.315,
                19.323,
                19.331,
                19.34,
                19.348,
                19.356,
                19.364,
                19.373,
                19.381,
                19.389,
                19.397,
                19.406,
                19.414,
                19.422,
                19.43,
                19.439,
                19.447,
                19.455,
                19.463,
                19.471,
                19.48,
                19.488,
                19.496,
                19.504,
                19.513,
                19.521,
                19.529,
                19.537,
                19.546,
                19.554,
                19.562,
                19.57,
                19.578,
                19.587,
                19.595,
                19.604,
                19.611,
                19.62,
                19.628,
                19.636,
                19.645,
                19.653,
                19.661,
                19.669,
                19.678,
                19.685,
                19.694,
                19.702,
                19.71,
                19.718,
                19.727,
                19.735,
                19.743,
                19.752,
                19.759,
                19.768,
                19.776,
                19.785,
                19.793,
                19.801,
                19.809,
                19.817,
                19.826,
                19.834,
                19.842,
                19.85,
                19.859,
                19.867,
                19.875,
                19.883,
                19.891,
                19.9,
                19.908,
                19.916,
                19.924,
                19.933,
                19.941,
                19.949,
                19.958,
                19.966,
                19.974,
                19.982,
                19.99,
                19.999,
                20.007,
                20.015,
                20.023,
                20.032,
                20.04,
                20.048,
                20.057,
                20.065,
                20.073,
                20.081,
                20.089,
                20.098,
                20.106,
                20.114,
                20.123,
                20.131,
                20.139,
                20.147,
                20.156,
                20.164,
                20.172,
                20.181,
                20.189,
                20.197,
                20.205,
                20.214,
                20.222,
                20.23,
                20.239,
                20.246,
                20.255,
                20.263,
                20.271,
                20.28,
                20.288,
                20.296,
                20.304,
                20.313,
                20.321,
                20.329,
                20.338,
                20.346,
                20.354,
                20.363,
                20.371,
                20.379,
                20.387,
                20.396,
                20.404,
                20.412,
                20.421,
                20.429,
                20.437,
                20.446,
                20.454,
                20.462,
                20.47,
                20.479,
                20.487,
                20.495,
                20.504,
                20.512,
                20.52,
                20.529,
                20.537,
                20.545,
                20.553,
                20.562,
                20.57,
                20.578,
                20.587,
                20.595,
                20.603,
                20.612,
                20.62,
                20.628,
                20.637,
                20.645,
                20.654,
                20.661,
                20.67,
                20.678,
                20.686,
                20.695,
                20.703,
                20.712,
                20.72,
                20.728,
                20.737,
                20.745,
                20.753,
                20.762,
                20.77,
                20.778,
                20.787,
                20.795,
                20.803,
                20.811,
                20.82,
                20.828,
                20.837,
                20.845,
                20.853,
                20.862,
                20.87,
                20.878,
                20.887,
                20.895,
                20.904,
                20.912,
                20.92,
                20.929,
                20.937,
                20.945,
                20.954,
                20.962,
                20.97,
                20.979,
                20.987,
                20.995,
                21.004,
                21.012,
                21.02,
                21.029,
                21.037,
                21.046,
                21.054,
                21.062,
                21.071,
                21.079,
                21.088,
                21.096,
                21.104,
                21.113,
                21.121,
                21.13,
                21.138,
                21.146,
                21.155,
                21.163,
                21.172,
                21.18,
                21.188,
                21.197,
                21.205,
                21.214,
                21.222,
                21.23,
                21.239,
                21.247,
                21.256,
                21.264,
                21.272,
                21.281,
                21.289,
                21.298,
                21.306,
                21.314,
                21.323,
                21.331,
                21.34,
                21.348,
                21.357,
                21.365,
                21.373,
                21.382,
                21.39,
                21.399,
                21.407,
                21.415,
                21.424,
                21.432,
                21.441,
                21.449,
                21.458,
                21.466,
                21.474,
                21.483,
                21.491,
                21.5,
                21.508,
                21.517,
                21.525,
                21.534,
                21.542,
                21.551,
                21.559,
                21.568,
                21.576,
                21.585,
                21.593,
                21.601,
                21.61,
                21.618,
                21.627,
                21.635,
                21.644,
                21.652,
                21.66,
                21.669,
                21.677,
                21.686,
                21.694,
                21.703,
                21.712,
                21.72,
                21.729,
                21.737,
                21.745,
                21.754,
                21.762,
                21.771,
                21.779,
                21.788,
                21.796,
                21.805,
                21.813,
                21.821,
                21.83,
                21.839,
                21.847,
                21.856,
                21.864,
                21.873,
                21.881,
                21.89,
                21.898,
                21.907,
                21.915,
                21.924,
                21.932,
                21.941,
                21.949,
                21.958,
                21.966,
                21.975,
                21.983,
                21.992,
                22,
                22.009,
                22.018,
                22.026,
                22.035,
                22.043,
                22.052,
                22.06,
                22.068,
                22.077,
                22.085,
                22.094,
                22.103,
                22.111,
                22.12,
                22.128,
                22.137,
                22.145,
                22.154,
                22.162,
                22.171,
                22.18,
                22.188,
                22.197,
                22.205,
                22.214,
                22.222,
                22.231,
                22.24,
                22.248,
                22.257,
                22.265,
                22.274,
                22.282,
                22.291,
                22.3,
                22.308,
                22.316,
                22.325,
                22.333,
                22.342,
                22.35,
                22.359,
                22.368,
                22.376,
                22.385,
                22.393,
                22.402,
                22.411,
                22.419,
                22.428,
                22.436,
                22.445,
                22.454,
                22.462,
                22.471,
                22.479,
                22.488,
                22.496,
                22.505,
                22.514,
                22.522,
                22.531,
                22.54,
                22.548,
                22.557,
                22.566,
                22.574,
                22.583,
                22.592,
                22.6,
                22.609,
                22.618,
                22.626,
                22.635,
                22.643,
                22.652,
                22.66,
                22.669,
                22.678,
                22.686,
                22.695,
                22.704,
                22.712,
                22.721,
                22.73,
                22.738,
                22.747,
                22.755,
                22.764,
                22.772,
                22.781,
                22.79,
                22.798,
                22.807,
                22.816,
                22.824,
                22.833,
                22.842,
                22.851,
                22.859,
                22.868,
                22.877,
                22.885,
                22.894,
                22.903,
                22.911,
                22.92,
                22.928,
                22.937,
                22.946,
                22.954,
                22.963,
                22.972,
                22.98,
                22.99,
                22.998,
                23.007,
                23.015,
                23.024,
                23.033,
                23.041,
                23.05,
                23.059,
                23.067,
                23.076,
                23.085,
                23.093,
                23.102,
                23.111,
                23.12,
                23.128,
                23.137,
                23.146,
                23.155,
                23.163,
                23.172,
                23.181,
                23.189,
                23.198,
                23.207,
                23.215,
                23.224,
                23.233,
                23.242,
                23.25,
                23.259,
                23.268,
                23.276,
                23.285,
                23.294,
                23.302,
                23.311,
                23.32,
                23.328,
                23.337,
                23.347,
                23.355,
                23.364,
                23.373,
                23.381,
                23.39,
                23.399,
                23.407,
                23.416,
                23.425,
                23.433,
                23.443,
                23.451,
                23.46,
                23.469,
                23.477,
                23.486,
                23.495,
                23.503,
                23.512,
                23.521,
                23.53,
                23.539,
                23.548,
                23.556,
                23.565,
                23.574,
                23.582,
                23.591,
                23.6,
                23.608,
                23.617,
                23.627,
                23.635,
                23.644,
                23.653,
                23.661,
                23.67,
                23.679,
                23.687,
                23.696,
                23.705,
                23.714,
                23.723,
                23.732,
                23.74,
                23.749,
                23.758,
                23.767,
                23.775,
                23.784,
                23.793,
                23.802,
                23.811,
                23.819,
                23.828,
                23.837,
                23.846,
                23.854,
                23.863,
                23.872,
                23.88,
                23.89,
                23.899,
                23.907,
                23.916,
                23.925,
                23.933,
                23.942,
                23.951,
                23.96,
                23.969,
                23.978,
                23.986,
                23.995,
                24.004,
                24.013,
                24.021,
                24.03,
                24.039,
                24.048,
                24.057,
                24.066,
                24.074,
                24.083,
                24.092,
                24.101,
                24.11,
                24.118,
                24.128,
                24.136,
                24.145,
                24.154,
                24.163,
                24.171,
                24.18,
                24.189,
                24.198,
                24.207,
                24.216,
                24.224,
                24.233,
                24.242,
                24.251,
                24.26,
                24.268,
                24.277,
                24.286,
                24.295,
                24.304,
                24.313,
                24.321,
                24.33,
                24.339,
                24.348,
                24.356,
                24.366,
                24.375,
                24.383,
                24.392,
                24.401,
                24.41,
                24.418,
                24.427,
                24.436,
                24.445,
                24.454,
                24.463,
                24.471,
                24.48,
                24.489,
                24.498,
                24.507,
                24.515,
                24.525,
                24.533,
                24.542,
                24.551,
                24.56,
                24.569,
                24.577,
                24.586,
                24.595,
                24.604,
                24.613,
                24.622,
                24.631,
                24.64,
                24.648,
                24.657,
                24.666,
                24.675,
                24.684,
                24.693,
                24.702,
                24.71,
                24.719,
                24.728,
                24.737,
                24.746,
                24.754,
                24.764,
                24.772,
                24.781,
                24.79,
                24.799,
                24.808,
                24.816,
                24.825,
                24.834,
                24.843,
                24.852,
                24.861,
                24.87,
                24.879,
                24.887,
                24.896,
                24.905,
                24.914,
                24.922,
                24.932,
                24.941,
                24.95,
                24.958,
                24.967,
                24.976,
                24.985,
                24.993,
                25.002,
                25.012,
                25.021,
                25.029,
                25.038,
                25.047,
                25.056,
                25.065,
                25.073,
                25.082,
                25.091,
                25.1,
                25.109,
                25.118,
                25.127,
                25.136,
                25.144,
                25.153,
                25.162,
                25.171,
                25.18,
                25.189,
                25.198,
                25.207,
                25.215,
                25.224,
                25.233,
                25.242,
                25.251,
                25.259,
                25.268,
                25.278,
                25.286,
                25.295,
                25.304,
                25.313,
                25.322,
                25.331,
                25.339,
                25.348,
                25.357,
                25.366,
                25.375,
                25.384,
                25.393,
                25.401,
                25.41,
                25.419,
                25.428,
                25.437,
                25.446,
                25.454,
                25.463,
                25.473,
                25.482,
                25.49,
                25.499,
                25.508,
                25.517,
                25.526,
                25.534,
                25.543,
                25.552,
                25.561,
                25.57

            ],
            P999: [
                5.088,
                5.072,
                5.104,
                5.142,
                5.184,
                5.227,
                5.272,
                5.318,
                5.365,
                5.413,
                5.463,
                5.514,
                5.567,
                5.622,
                5.678,
                5.736,
                5.794,
                5.854,
                5.914,
                5.973,
                6.033,
                6.093,
                6.152,
                6.212,
                6.27,
                6.329,
                6.387,
                6.445,
                6.502,
                6.559,
                6.615,
                6.671,
                6.726,
                6.78,
                6.833,
                6.887,
                6.939,
                6.99,
                7.042,
                7.092,
                7.142,
                7.191,
                7.239,
                7.287,
                7.335,
                7.381,
                7.427,
                7.473,
                7.518,
                7.563,
                7.607,
                7.65,
                7.693,
                7.735,
                7.777,
                7.818,
                7.859,
                7.9,
                7.94,
                7.979,
                8.018,
                8.057,
                8.095,
                8.133,
                8.171,
                8.208,
                8.245,
                8.281,
                8.317,
                8.352,
                8.387,
                8.422,
                8.457,
                8.491,
                8.525,
                8.558,
                8.591,
                8.624,
                8.656,
                8.689,
                8.721,
                8.752,
                8.783,
                8.814,
                8.845,
                8.875,
                8.905,
                8.935,
                8.965,
                8.994,
                9.023,
                9.052,
                9.081,
                9.109,
                9.138,
                9.166,
                9.193,
                9.221,
                9.248,
                9.275,
                9.302,
                9.329,
                9.355,
                9.381,
                9.407,
                9.433,
                9.459,
                9.485,
                9.51,
                9.535,
                9.56,
                9.585,
                9.61,
                9.635,
                9.659,
                9.683,
                9.707,
                9.731,
                9.755,
                9.779,
                9.802,
                9.826,
                9.849,
                9.872,
                9.895,
                9.918,
                9.941,
                9.963,
                9.985,
                10.008,
                10.03,
                10.052,
                10.074,
                10.096,
                10.118,
                10.139,
                10.161,
                10.182,
                10.203,
                10.225,
                10.246,
                10.266,
                10.287,
                10.308,
                10.329,
                10.349,
                10.37,
                10.39,
                10.41,
                10.43,
                10.45,
                10.47,
                10.49,
                10.51,
                10.529,
                10.549,
                10.568,
                10.587,
                10.607,
                10.626,
                10.645,
                10.664,
                10.683,
                10.701,
                10.72,
                10.739,
                10.757,
                10.776,
                10.794,
                10.812,
                10.831,
                10.849,
                10.867,
                10.885,
                10.903,
                10.92,
                10.938,
                10.956,
                10.973,
                10.991,
                11.008,
                11.026,
                11.043,
                11.06,
                11.077,
                11.094,
                11.112,
                11.128,
                11.145,
                11.162,
                11.179,
                11.196,
                11.212,
                11.229,
                11.245,
                11.262,
                11.278,
                11.294,
                11.311,
                11.327,
                11.343,
                11.359,
                11.375,
                11.391,
                11.407,
                11.423,
                11.438,
                11.454,
                11.47,
                11.485,
                11.501,
                11.516,
                11.532,
                11.547,
                11.562,
                11.578,
                11.593,
                11.608,
                11.623,
                11.638,
                11.653,
                11.668,
                11.683,
                11.698,
                11.712,
                11.727,
                11.742,
                11.757,
                11.771,
                11.786,
                11.8,
                11.814,
                11.829,
                11.843,
                11.858,
                11.872,
                11.886,
                11.9,
                11.914,
                11.928,
                11.942,
                11.956,
                11.97,
                11.984,
                11.998,
                12.012,
                12.025,
                12.039,
                12.053,
                12.067,
                12.08,
                12.094,
                12.107,
                12.121,
                12.134,
                12.148,
                12.161,
                12.175,
                12.188,
                12.201,
                12.215,
                12.228,
                12.241,
                12.254,
                12.267,
                12.28,
                12.293,
                12.306,
                12.319,
                12.332,
                12.345,
                12.358,
                12.371,
                12.384,
                12.397,
                12.41,
                12.422,
                12.435,
                12.448,
                12.461,
                12.473,
                12.486,
                12.498,
                12.511,
                12.523,
                12.536,
                12.549,
                12.561,
                12.573,
                12.586,
                12.598,
                12.61,
                12.623,
                12.635,
                12.648,
                12.66,
                12.672,
                12.684,
                12.696,
                12.708,
                12.721,
                12.733,
                12.745,
                12.757,
                12.769,
                12.781,
                12.793,
                12.805,
                12.817,
                12.829,
                12.841,
                12.853,
                12.865,
                12.876,
                12.888,
                12.9,
                12.912,
                12.923,
                12.935,
                12.947,
                12.959,
                12.97,
                12.982,
                12.994,
                13.006,
                13.017,
                13.029,
                13.041,
                13.052,
                13.064,
                13.075,
                13.087,
                13.098,
                13.11,
                13.121,
                13.133,
                13.144,
                13.156,
                13.167,
                13.178,
                13.19,
                13.201,
                13.213,
                13.224,
                13.235,
                13.247,
                13.258,
                13.269,
                13.281,
                13.292,
                13.303,
                13.315,
                13.325,
                13.337,
                13.348,
                13.359,
                13.37,
                13.382,
                13.393,
                13.404,
                13.415,
                13.426,
                13.437,
                13.449,
                13.46,
                13.471,
                13.482,
                13.493,
                13.504,
                13.515,
                13.526,
                13.537,
                13.548,
                13.559,
                13.57,
                13.581,
                13.592,
                13.603,
                13.614,
                13.625,
                13.636,
                13.647,
                13.658,
                13.669,
                13.68,
                13.691,
                13.702,
                13.713,
                13.724,
                13.734,
                13.745,
                13.756,
                13.767,
                13.778,
                13.789,
                13.799,
                13.81,
                13.821,
                13.832,
                13.843,
                13.854,
                13.864,
                13.875,
                13.886,
                13.896,
                13.907,
                13.918,
                13.929,
                13.939,
                13.95,
                13.961,
                13.971,
                13.982,
                13.993,
                14.004,
                14.014,
                14.025,
                14.036,
                14.046,
                14.057,
                14.068,
                14.078,
                14.089,
                14.099,
                14.11,
                14.121,
                14.131,
                14.142,
                14.153,
                14.163,
                14.174,
                14.184,
                14.195,
                14.206,
                14.216,
                14.227,
                14.237,
                14.248,
                14.259,
                14.269,
                14.28,
                14.29,
                14.301,
                14.311,
                14.322,
                14.332,
                14.343,
                14.353,
                14.364,
                14.374,
                14.385,
                14.395,
                14.406,
                14.416,
                14.427,
                14.438,
                14.448,
                14.459,
                14.469,
                14.48,
                14.49,
                14.501,
                14.511,
                14.521,
                14.532,
                14.542,
                14.553,
                14.564,
                14.574,
                14.585,
                14.595,
                14.605,
                14.616,
                14.626,
                14.636,
                14.647,
                14.658,
                14.668,
                14.679,
                14.689,
                14.699,
                14.71,
                14.72,
                14.731,
                14.741,
                14.752,
                14.762,
                14.772,
                14.783,
                14.793,
                14.804,
                14.814,
                14.825,
                14.835,
                14.846,
                14.856,
                14.866,
                14.877,
                14.887,
                14.898,
                14.908,
                14.918,
                14.929,
                14.939,
                14.95,
                14.96,
                14.97,
                14.98,
                14.991,
                15.001,
                15.012,
                15.022,
                15.033,
                15.043,
                15.053,
                15.064,
                15.074,
                15.085,
                15.095,
                15.106,
                15.116,
                15.126,
                15.136,
                15.147,
                15.157,
                15.167,
                15.178,
                15.188,
                15.199,
                15.209,
                15.219,
                15.23,
                15.24,
                15.25,
                15.261,
                15.271,
                15.282,
                15.292,
                15.302,
                15.313,
                15.323,
                15.333,
                15.344,
                15.354,
                15.364,
                15.375,
                15.385,
                15.396,
                15.406,
                15.416,
                15.426,
                15.437,
                15.447,
                15.458,
                15.468,
                15.478,
                15.489,
                15.499,
                15.51,
                15.52,
                15.53,
                15.54,
                15.551,
                15.561,
                15.571,
                15.582,
                15.592,
                15.603,
                15.613,
                15.623,
                15.634,
                15.644,
                15.654,
                15.665,
                15.675,
                15.686,
                15.696,
                15.706,
                15.716,
                15.727,
                15.737,
                15.748,
                15.758,
                15.768,
                15.779,
                15.789,
                15.799,
                15.81,
                15.82,
                15.83,
                15.841,
                15.851,
                15.862,
                15.872,
                15.882,
                15.893,
                15.903,
                15.913,
                15.924,
                15.934,
                15.944,
                15.955,
                15.965,
                15.976,
                15.986,
                15.996,
                16.007,
                16.017,
                16.028,
                16.038,
                16.048,
                16.059,
                16.069,
                16.08,
                16.09,
                16.1,
                16.111,
                16.121,
                16.132,
                16.142,
                16.152,
                16.163,
                16.174,
                16.184,
                16.194,
                16.205,
                16.215,
                16.226,
                16.236,
                16.246,
                16.257,
                16.267,
                16.278,
                16.288,
                16.299,
                16.309,
                16.319,
                16.33,
                16.34,
                16.351,
                16.361,
                16.372,
                16.382,
                16.393,
                16.403,
                16.413,
                16.424,
                16.435,
                16.445,
                16.455,
                16.466,
                16.476,
                16.486,
                16.497,
                16.508,
                16.518,
                16.528,
                16.539,
                16.55,
                16.56,
                16.57,
                16.581,
                16.592,
                16.602,
                16.612,
                16.623,
                16.633,
                16.644,
                16.654,
                16.665,
                16.675,
                16.686,
                16.696,
                16.707,
                16.717,
                16.728,
                16.738,
                16.748,
                16.759,
                16.77,
                16.78,
                16.791,
                16.801,
                16.812,
                16.822,
                16.833,
                16.844,
                16.854,
                16.864,
                16.875,
                16.885,
                16.896,
                16.906,
                16.917,
                16.927,
                16.938,
                16.949,
                16.959,
                16.969,
                16.98,
                16.991,
                17.001,
                17.012,
                17.022,
                17.032,
                17.043,
                17.054,
                17.064,
                17.075,
                17.086,
                17.096,
                17.106,
                17.117,
                17.127,
                17.138,
                17.149,
                17.159,
                17.17,
                17.18,
                17.191,
                17.201,
                17.212,
                17.222,
                17.233,
                17.243,
                17.254,
                17.265,
                17.275,
                17.285,
                17.296,
                17.307,
                17.317,
                17.328,
                17.338,
                17.349,
                17.36,
                17.37,
                17.381,
                17.391,
                17.402,
                17.412,
                17.423,
                17.433,
                17.444,
                17.454,
                17.465,
                17.476,
                17.486,
                17.497,
                17.507,
                17.518,
                17.528,
                17.539,
                17.549,
                17.56,
                17.57,
                17.581,
                17.591,
                17.602,
                17.613,
                17.623,
                17.634,
                17.644,
                17.655,
                17.665,
                17.676,
                17.686,
                17.697,
                17.707,
                17.718,
                17.728,
                17.739,
                17.749,
                17.76,
                17.77,
                17.781,
                17.791,
                17.802,
                17.812,
                17.823,
                17.833,
                17.844,
                17.854,
                17.865,
                17.875,
                17.886,
                17.896,
                17.907,
                17.917,
                17.928,
                17.938,
                17.949,
                17.959,
                17.969,
                17.98,
                17.99,
                18.001,
                18.011,
                18.021,
                18.032,
                18.043,
                18.053,
                18.063,
                18.074,
                18.084,
                18.095,
                18.105,
                18.116,
                18.126,
                18.136,
                18.147,
                18.157,
                18.168,
                18.178,
                18.189,
                18.199,
                18.209,
                18.22,
                18.23,
                18.24,
                18.251,
                18.261,
                18.271,
                18.281,
                18.292,
                18.302,
                18.312,
                18.323,
                18.333,
                18.344,
                18.354,
                18.364,
                18.375,
                18.385,
                18.395,
                18.406,
                18.416,
                18.426,
                18.436,
                18.447,
                18.457,
                18.467,
                18.478,
                18.488,
                18.498,
                18.508,
                18.518,
                18.529,
                18.539,
                18.549,
                18.559,
                18.57,
                18.58,
                18.59,
                18.6,
                18.611,
                18.621,
                18.631,
                18.641,
                18.651,
                18.662,
                18.672,
                18.682,
                18.692,
                18.702,
                18.712,
                18.722,
                18.733,
                18.743,
                18.753,
                18.763,
                18.773,
                18.783,
                18.794,
                18.804,
                18.814,
                18.824,
                18.834,
                18.844,
                18.854,
                18.864,
                18.874,
                18.884,
                18.894,
                18.905,
                18.915,
                18.925,
                18.935,
                18.945,
                18.955,
                18.965,
                18.975,
                18.985,
                18.995,
                19.005,
                19.015,
                19.025,
                19.035,
                19.045,
                19.055,
                19.065,
                19.075,
                19.085,
                19.096,
                19.105,
                19.115,
                19.125,
                19.135,
                19.145,
                19.155,
                19.165,
                19.175,
                19.185,
                19.195,
                19.205,
                19.215,
                19.224,
                19.235,
                19.244,
                19.255,
                19.264,
                19.274,
                19.284,
                19.294,
                19.304,
                19.314,
                19.324,
                19.334,
                19.344,
                19.354,
                19.363,
                19.373,
                19.383,
                19.393,
                19.403,
                19.413,
                19.423,
                19.432,
                19.442,
                19.452,
                19.462,
                19.472,
                19.482,
                19.492,
                19.502,
                19.512,
                19.521,
                19.531,
                19.541,
                19.55,
                19.56,
                19.57,
                19.58,
                19.59,
                19.6,
                19.609,
                19.619,
                19.629,
                19.639,
                19.648,
                19.658,
                19.668,
                19.678,
                19.688,
                19.697,
                19.707,
                19.717,
                19.726,
                19.736,
                19.746,
                19.756,
                19.766,
                19.775,
                19.785,
                19.794,
                19.804,
                19.814,
                19.824,
                19.834,
                19.844,
                19.853,
                19.862,
                19.872,
                19.882,
                19.892,
                19.901,
                19.911,
                19.92,
                19.93,
                19.94,
                19.95,
                19.96,
                19.969,
                19.979,
                19.988,
                19.998,
                20.008,
                20.017,
                20.027,
                20.037,
                20.047,
                20.056,
                20.065,
                20.075,
                20.085,
                20.095,
                20.104,
                20.113,
                20.123,
                20.133,
                20.143,
                20.152,
                20.162,
                20.171,
                20.181,
                20.191,
                20.2,
                20.21,
                20.22,
                20.229,
                20.238,
                20.248,
                20.258,
                20.267,
                20.277,
                20.286,
                20.296,
                20.306,
                20.315,
                20.325,
                20.334,
                20.344,
                20.353,
                20.363,
                20.373,
                20.382,
                20.391,
                20.401,
                20.411,
                20.421,
                20.429,
                20.439,
                20.449,
                20.459,
                20.468,
                20.477,
                20.487,
                20.497,
                20.506,
                20.515,
                20.525,
                20.535,
                20.544,
                20.554,
                20.563,
                20.573,
                20.582,
                20.592,
                20.602,
                20.611,
                20.62,
                20.63,
                20.64,
                20.648,
                20.658,
                20.668,
                20.677,
                20.686,
                20.696,
                20.706,
                20.715,
                20.725,
                20.734,
                20.744,
                20.753,
                20.763,
                20.772,
                20.782,
                20.791,
                20.801,
                20.81,
                20.819,
                20.829,
                20.839,
                20.848,
                20.857,
                20.867,
                20.877,
                20.886,
                20.895,
                20.905,
                20.915,
                20.923,
                20.933,
                20.943,
                20.952,
                20.961,
                20.971,
                20.981,
                20.99,
                20.999,
                21.009,
                21.018,
                21.028,
                21.037,
                21.047,
                21.056,
                21.065,
                21.075,
                21.085,
                21.094,
                21.103,
                21.113,
                21.122,
                21.131,
                21.141,
                21.151,
                21.16,
                21.169,
                21.179,
                21.189,
                21.197,
                21.207,
                21.217,
                21.226,
                21.235,
                21.245,
                21.255,
                21.264,
                21.273,
                21.283,
                21.293,
                21.301,
                21.311,
                21.321,
                21.33,
                21.339,
                21.349,
                21.359,
                21.368,
                21.377,
                21.387,
                21.396,
                21.406,
                21.415,
                21.424,
                21.434,
                21.443,
                21.453,
                21.462,
                21.472,
                21.481,
                21.49,
                21.5,
                21.51,
                21.519,
                21.528,
                21.538,
                21.547,
                21.557,
                21.566,
                21.576,
                21.585,
                21.595,
                21.604,
                21.613,
                21.623,
                21.632,
                21.641,
                21.651,
                21.661,
                21.67,
                21.679,
                21.689,
                21.698,
                21.708,
                21.717,
                21.726,
                21.736,
                21.746,
                21.755,
                21.764,
                21.774,
                21.784,
                21.793,
                21.802,
                21.812,
                21.821,
                21.831,
                21.84,
                21.849,
                21.859,
                21.869,
                21.878,
                21.887,
                21.897,
                21.906,
                21.916,
                21.925,
                21.935,
                21.944,
                21.954,
                21.963,
                21.973,
                21.982,
                21.991,
                22.001,
                22.011,
                22.02,
                22.029,
                22.039,
                22.048,
                22.058,
                22.068,
                22.077,
                22.086,
                22.096,
                22.105,
                22.115,
                22.125,
                22.133,
                22.143,
                22.153,
                22.162,
                22.172,
                22.181,
                22.19,
                22.2,
                22.21,
                22.22,
                22.229,
                22.238,
                22.248,
                22.257,
                22.267,
                22.277,
                22.286,
                22.295,
                22.305,
                22.314,
                22.324,
                22.334,
                22.343,
                22.352,
                22.362,
                22.371,
                22.381,
                22.391,
                22.4,
                22.409,
                22.419,
                22.429,
                22.438,
                22.448,
                22.458,
                22.467,
                22.476,
                22.486,
                22.495,
                22.505,
                22.515,
                22.524,
                22.534,
                22.543,
                22.553,
                22.562,
                22.572,
                22.582,
                22.591,
                22.601,
                22.61,
                22.619,
                22.629,
                22.639,
                22.649,
                22.658,
                22.668,
                22.677,
                22.686,
                22.696,
                22.706,
                22.716,
                22.725,
                22.735,
                22.744,
                22.753,
                22.763,
                22.773,
                22.783,
                22.792,
                22.802,
                22.812,
                22.821,
                22.83,
                22.84,
                22.85,
                22.859,
                22.869,
                22.879,
                22.888,
                22.898,
                22.908,
                22.917,
                22.926,
                22.936,
                22.946,
                22.956,
                22.965,
                22.975,
                22.984,
                22.994,
                23.003,
                23.013,
                23.023,
                23.033,
                23.042,
                23.052,
                23.062,
                23.071,
                23.081,
                23.09,
                23.1,
                23.11,
                23.119,
                23.129,
                23.139,
                23.149,
                23.158,
                23.168,
                23.177,
                23.187,
                23.197,
                23.206,
                23.216,
                23.226,
                23.236,
                23.246,
                23.255,
                23.265,
                23.274,
                23.284,
                23.293,
                23.303,
                23.313,
                23.323,
                23.333,
                23.342,
                23.352,
                23.362,
                23.371,
                23.381,
                23.39,
                23.4,
                23.41,
                23.42,
                23.43,
                23.439,
                23.449,
                23.459,
                23.469,
                23.479,
                23.488,
                23.498,
                23.507,
                23.517,
                23.527,
                23.537,
                23.546,
                23.556,
                23.566,
                23.576,
                23.586,
                23.595,
                23.605,
                23.615,
                23.624,
                23.634,
                23.644,
                23.654,
                23.663,
                23.673,
                23.683,
                23.693,
                23.703,
                23.713,
                23.723,
                23.732,
                23.742,
                23.751,
                23.761,
                23.771,
                23.781,
                23.79,
                23.8,
                23.81,
                23.82,
                23.83,
                23.84,
                23.85,
                23.86,
                23.869,
                23.879,
                23.889,
                23.898,
                23.908,
                23.918,
                23.928,
                23.938,
                23.947,
                23.957,
                23.967,
                23.977,
                23.987,
                23.997,
                24.007,
                24.017,
                24.027,
                24.036,
                24.046,
                24.056,
                24.066,
                24.076,
                24.086,
                24.096,
                24.105,
                24.115,
                24.125,
                24.134,
                24.144,
                24.154,
                24.164,
                24.174,
                24.184,
                24.194,
                24.204,
                24.214,
                24.224,
                24.234,
                24.244,
                24.254,
                24.263,
                24.273,
                24.283,
                24.293,
                24.303,
                24.313,
                24.323,
                24.333,
                24.343,
                24.353,
                24.363,
                24.372,
                24.382,
                24.392,
                24.402,
                24.412,
                24.422,
                24.432,
                24.442,
                24.452,
                24.462,
                24.471,
                24.481,
                24.491,
                24.501,
                24.512,
                24.521,
                24.531,
                24.541,
                24.551,
                24.561,
                24.571,
                24.581,
                24.591,
                24.601,
                24.611,
                24.621,
                24.631,
                24.641,
                24.651,
                24.661,
                24.671,
                24.681,
                24.691,
                24.7,
                24.711,
                24.721,
                24.731,
                24.741,
                24.75,
                24.761,
                24.77,
                24.781,
                24.791,
                24.801,
                24.811,
                24.821,
                24.831,
                24.841,
                24.851,
                24.861,
                24.871,
                24.881,
                24.891,
                24.901,
                24.911,
                24.921,
                24.931,
                24.941,
                24.951,
                24.961,
                24.971,
                24.981,
                24.992,
                25.002,
                25.012,
                25.022,
                25.032,
                25.042,
                25.052,
                25.063,
                25.073,
                25.083,
                25.093,
                25.103,
                25.113,
                25.123,
                25.133,
                25.143,
                25.153,
                25.163,
                25.173,
                25.183,
                25.193,
                25.203,
                25.213,
                25.223,
                25.234,
                25.244,
                25.254,
                25.264,
                25.274,
                25.284,
                25.294,
                25.304,
                25.315,
                25.325,
                25.335,
                25.345,
                25.355,
                25.366,
                25.376,
                25.386,
                25.396,
                25.406,
                25.416,
                25.426,
                25.436,
                25.446,
                25.456,
                25.467,
                25.477,
                25.487,
                25.498,
                25.508,
                25.518,
                25.528,
                25.538,
                25.548,
                25.559,
                25.569,
                25.579,
                25.589,
                25.599,
                25.609,
                25.619,
                25.629,
                25.639,
                25.651,
                25.661,
                25.671,
                25.681,
                25.691,
                25.701,
                25.712,
                25.722,
                25.732,
                25.742,
                25.752,
                25.762,
                25.772,
                25.783,
                25.793,
                25.804,
                25.814,
                25.824,
                25.834,
                25.844,
                25.854,
                25.865,
                25.875,
                25.885,
                25.895,
                25.905,
                25.916,
                25.927,
                25.937,
                25.947,
                25.957,
                25.967,
                25.977,
                25.988,
                25.998,
                26.008,
                26.018,
                26.029,
                26.04,
                26.05,
                26.06,
                26.07,
                26.08,
                26.09,
                26.101,
                26.111,
                26.121,
                26.131,
                26.142,
                26.153,
                26.163,
                26.173,
                26.183,
                26.193,
                26.204,
                26.214,
                26.224,
                26.234,
                26.246,
                26.256,
                26.266,
                26.276,
                26.286,
                26.297,
                26.307,
                26.317,
                26.327,
                26.337,
                26.348,
                26.359,
                26.369,
                26.379,
                26.39,
                26.4,
                26.41,
                26.42,
                26.43,
                26.441,
                26.452,
                26.462,
                26.472,
                26.482,
                26.493,
                26.503,
                26.513,
                26.523,
                26.534,
                26.544,
                26.555,
                26.565,
                26.576,
                26.586,
                26.596,
                26.606,
                26.617,
                26.627,
                26.637,
                26.648,
                26.659,
                26.669,
                26.679,
                26.689,
                26.7,
                26.71,
                26.72,
                26.731,
                26.742,
                26.752,
                26.762,
                26.773,
                26.783,
                26.793,
                26.804,
                26.814,
                26.824,
                26.835,
                26.846,
                26.856,
                26.866,
                26.877,
                26.887,
                26.897,
                26.907,
                26.918,
                26.929,
                26.939,
                26.949,
                26.96,
                26.97,
                26.981,
                26.991,
                27.001,
                27.011,
                27.023,
                27.033,
                27.043,
                27.054,
                27.064,
                27.074,
                27.084,
                27.095,
                27.105,
                27.116,
                27.127,
                27.137,
                27.147,
                27.157,
                27.168,
                27.178,
                27.188,
                27.199,
                27.21,
                27.22,
                27.231,
                27.241,
                27.251,
                27.262,
                27.272,
                27.282,
                27.293,
                27.304,
                27.314,
                27.325,
                27.335,
                27.345,
                27.356,
                27.366,
                27.376,
                27.387,
                27.398,
                27.408,
                27.419,
                27.429,
                27.439,
                27.45,
                27.46,
                27.471,
                27.481,
                27.492,
                27.502,
                27.513,
                27.523,
                27.534,
                27.544,
                27.554,
                27.565,
                27.575,
                27.586,
                27.597,
                27.607,
                27.618,
                27.628,
                27.638,
                27.649,
                27.659,
                27.669,
                27.681,
                27.691,
                27.701,
                27.712,
                27.722,
                27.733,
                27.743,
                27.753,
                27.764,
                27.774,
                27.785,
                27.796,
                27.806,
                27.816,
                27.827,
                27.837,
                27.848,
                27.858,
                27.868,
                27.88,
                27.89,
                27.901,
                27.911,
                27.921,
                27.932,
                27.942,
                27.953,
                27.963,
                27.973,
                27.985,
                27.995,
                28.006,
                28.016,
                28.027,
                28.037,
                28.047,
                28.058,
                28.068,
                28.078,
                28.09,
                28.1,
                28.111,
                28.121,
                28.131,
                28.142,
                28.152,
                28.163,
                28.173,
                28.184,
                28.195,
                28.205,
                28.216,
                28.226,
                28.237,
                28.247,
                28.257,
                28.268,
                28.278,
                28.289,
                28.299,
                28.311,
                28.321,
                28.331,
                28.342,
                28.352,
                28.362,
                28.373,
                28.383,
                28.394,
                28.404,
                28.415,
                28.426,
                28.437,
                28.447,
                28.458,
                28.468,
                28.478,
                28.489,
                28.499,
                28.51,
                28.52,
                28.53,
                28.542
            ]
        },
        girls: {
            P01: [
                2.002,
                1.963,
                1.972,
                1.986,
                2.004,
                2.023,
                2.043,
                2.064,
                2.087,
                2.11,
                2.134,
                2.158,
                2.184,
                2.21,
                2.237,
                2.264,
                2.292,
                2.32,
                2.348,
                2.377,
                2.405,
                2.434,
                2.462,
                2.49,
                2.519,
                2.547,
                2.575,
                2.602,
                2.63,
                2.657,
                2.684,
                2.711,
                2.738,
                2.764,
                2.79,
                2.816,
                2.842,
                2.867,
                2.892,
                2.917,
                2.941,
                2.966,
                2.99,
                3.014,
                3.038,
                3.061,
                3.084,
                3.107,
                3.13,
                3.153,
                3.175,
                3.197,
                3.219,
                3.241,
                3.262,
                3.283,
                3.304,
                3.325,
                3.346,
                3.366,
                3.387,
                3.407,
                3.427,
                3.447,
                3.466,
                3.486,
                3.505,
                3.524,
                3.543,
                3.562,
                3.581,
                3.599,
                3.618,
                3.636,
                3.654,
                3.672,
                3.69,
                3.707,
                3.725,
                3.742,
                3.76,
                3.777,
                3.794,
                3.811,
                3.827,
                3.844,
                3.86,
                3.877,
                3.893,
                3.909,
                3.925,
                3.941,
                3.957,
                3.972,
                3.988,
                4.004,
                4.019,
                4.034,
                4.049,
                4.064,
                4.079,
                4.094,
                4.109,
                4.123,
                4.138,
                4.152,
                4.167,
                4.181,
                4.195,
                4.209,
                4.223,
                4.237,
                4.251,
                4.264,
                4.278,
                4.291,
                4.305,
                4.318,
                4.331,
                4.345,
                4.358,
                4.371,
                4.383,
                4.396,
                4.409,
                4.422,
                4.434,
                4.447,
                4.459,
                4.471,
                4.484,
                4.496,
                4.508,
                4.52,
                4.532,
                4.544,
                4.555,
                4.567,
                4.579,
                4.59,
                4.602,
                4.613,
                4.624,
                4.636,
                4.647,
                4.658,
                4.669,
                4.68,
                4.691,
                4.701,
                4.712,
                4.723,
                4.734,
                4.744,
                4.755,
                4.765,
                4.775,
                4.786,
                4.796,
                4.806,
                4.816,
                4.826,
                4.836,
                4.846,
                4.856,
                4.866,
                4.876,
                4.885,
                4.895,
                4.905,
                4.914,
                4.924,
                4.933,
                4.943,
                4.952,
                4.961,
                4.971,
                4.98,
                4.989,
                4.998,
                5.007,
                5.016,
                5.025,
                5.034,
                5.043,
                5.052,
                5.061,
                5.07,
                5.078,
                5.087,
                5.095,
                5.104,
                5.113,
                5.121,
                5.13,
                5.138,
                5.146,
                5.155,
                5.163,
                5.171,
                5.179,
                5.188,
                5.196,
                5.204,
                5.212,
                5.22,
                5.228,
                5.236,
                5.244,
                5.251,
                5.259,
                5.267,
                5.275,
                5.283,
                5.29,
                5.298,
                5.306,
                5.313,
                5.321,
                5.328,
                5.336,
                5.343,
                5.351,
                5.358,
                5.366,
                5.373,
                5.38,
                5.387,
                5.395,
                5.402,
                5.409,
                5.416,
                5.424,
                5.431,
                5.438,
                5.445,
                5.452,
                5.459,
                5.466,
                5.473,
                5.48,
                5.487,
                5.493,
                5.5,
                5.507,
                5.514,
                5.521,
                5.528,
                5.534,
                5.541,
                5.548,
                5.554,
                5.561,
                5.567,
                5.574,
                5.581,
                5.587,
                5.594,
                5.6,
                5.606,
                5.613,
                5.619,
                5.626,
                5.632,
                5.638,
                5.645,
                5.651,
                5.657,
                5.664,
                5.67,
                5.676,
                5.682,
                5.689,
                5.695,
                5.701,
                5.707,
                5.713,
                5.719,
                5.725,
                5.732,
                5.738,
                5.744,
                5.75,
                5.756,
                5.762,
                5.768,
                5.774,
                5.779,
                5.785,
                5.791,
                5.797,
                5.803,
                5.809,
                5.815,
                5.821,
                5.827,
                5.832,
                5.838,
                5.844,
                5.85,
                5.855,
                5.861,
                5.867,
                5.872,
                5.878,
                5.884,
                5.89,
                5.895,
                5.901,
                5.907,
                5.912,
                5.918,
                5.923,
                5.929,
                5.935,
                5.94,
                5.946,
                5.951,
                5.957,
                5.962,
                5.968,
                5.974,
                5.979,
                5.985,
                5.99,
                5.996,
                6.001,
                6.007,
                6.012,
                6.017,
                6.023,
                6.028,
                6.034,
                6.039,
                6.045,
                6.05,
                6.055,
                6.061,
                6.066,
                6.071,
                6.077,
                6.082,
                6.087,
                6.093,
                6.098,
                6.104,
                6.109,
                6.114,
                6.12,
                6.125,
                6.13,
                6.135,
                6.141,
                6.146,
                6.151,
                6.156,
                6.162,
                6.167,
                6.172,
                6.177,
                6.183,
                6.188,
                6.193,
                6.198,
                6.204,
                6.209,
                6.214,
                6.219,
                6.224,
                6.23,
                6.235,
                6.24,
                6.245,
                6.25,
                6.255,
                6.261,
                6.266,
                6.271,
                6.276,
                6.281,
                6.286,
                6.292,
                6.297,
                6.302,
                6.307,
                6.312,
                6.317,
                6.322,
                6.328,
                6.333,
                6.338,
                6.343,
                6.348,
                6.353,
                6.358,
                6.363,
                6.368,
                6.373,
                6.379,
                6.384,
                6.389,
                6.394,
                6.399,
                6.404,
                6.409,
                6.414,
                6.419,
                6.424,
                6.429,
                6.434,
                6.439,
                6.444,
                6.45,
                6.455,
                6.46,
                6.465,
                6.47,
                6.475,
                6.48,
                6.485,
                6.49,
                6.495,
                6.5,
                6.505,
                6.51,
                6.515,
                6.52,
                6.525,
                6.53,
                6.535,
                6.54,
                6.545,
                6.55,
                6.555,
                6.56,
                6.565,
                6.57,
                6.575,
                6.58,
                6.585,
                6.59,
                6.595,
                6.6,
                6.605,
                6.61,
                6.615,
                6.62,
                6.625,
                6.63,
                6.635,
                6.64,
                6.645,
                6.65,
                6.655,
                6.66,
                6.665,
                6.67,
                6.675,
                6.68,
                6.685,
                6.69,
                6.695,
                6.7,
                6.705,
                6.71,
                6.715,
                6.719,
                6.724,
                6.729,
                6.734,
                6.739,
                6.744,
                6.749,
                6.754,
                6.759,
                6.764,
                6.769,
                6.774,
                6.779,
                6.784,
                6.789,
                6.794,
                6.799,
                6.803,
                6.808,
                6.813,
                6.818,
                6.823,
                6.828,
                6.833,
                6.838,
                6.843,
                6.848,
                6.853,
                6.858,
                6.863,
                6.867,
                6.872,
                6.877,
                6.882,
                6.887,
                6.892,
                6.897,
                6.902,
                6.907,
                6.912,
                6.917,
                6.921,
                6.926,
                6.931,
                6.936,
                6.941,
                6.946,
                6.951,
                6.956,
                6.961,
                6.966,
                6.97,
                6.975,
                6.98,
                6.985,
                6.99,
                6.995,
                7,
                7.005,
                7.009,
                7.014,
                7.019,
                7.024,
                7.029,
                7.034,
                7.039,
                7.044,
                7.048,
                7.053,
                7.058,
                7.063,
                7.068,
                7.073,
                7.077,
                7.082,
                7.087,
                7.092,
                7.097,
                7.102,
                7.107,
                7.111,
                7.116,
                7.121,
                7.126,
                7.131,
                7.136,
                7.14,
                7.145,
                7.15,
                7.155,
                7.16,
                7.165,
                7.169,
                7.174,
                7.179,
                7.184,
                7.189,
                7.193,
                7.198,
                7.203,
                7.208,
                7.213,
                7.217,
                7.222,
                7.227,
                7.232,
                7.237,
                7.241,
                7.246,
                7.251,
                7.256,
                7.261,
                7.265,
                7.27,
                7.275,
                7.28,
                7.285,
                7.29,
                7.294,
                7.299,
                7.304,
                7.309,
                7.313,
                7.318,
                7.323,
                7.328,
                7.332,
                7.337,
                7.342,
                7.347,
                7.351,
                7.356,
                7.361,
                7.366,
                7.371,
                7.375,
                7.38,
                7.385,
                7.39,
                7.394,
                7.399,
                7.404,
                7.409,
                7.413,
                7.418,
                7.423,
                7.428,
                7.432,
                7.437,
                7.442,
                7.447,
                7.451,
                7.456,
                7.461,
                7.466,
                7.47,
                7.475,
                7.48,
                7.484,
                7.489,
                7.494,
                7.499,
                7.504,
                7.508,
                7.513,
                7.518,
                7.522,
                7.527,
                7.532,
                7.537,
                7.541,
                7.546,
                7.551,
                7.555,
                7.56,
                7.565,
                7.57,
                7.574,
                7.579,
                7.584,
                7.589,
                7.593,
                7.598,
                7.603,
                7.607,
                7.612,
                7.617,
                7.621,
                7.626,
                7.631,
                7.636,
                7.64,
                7.645,
                7.65,
                7.654,
                7.659,
                7.664,
                7.669,
                7.673,
                7.678,
                7.683,
                7.687,
                7.692,
                7.697,
                7.701,
                7.706,
                7.711,
                7.716,
                7.72,
                7.725,
                7.73,
                7.734,
                7.739,
                7.744,
                7.749,
                7.753,
                7.758,
                7.763,
                7.767,
                7.772,
                7.777,
                7.781,
                7.786,
                7.791,
                7.796,
                7.8,
                7.805,
                7.81,
                7.814,
                7.819,
                7.824,
                7.828,
                7.833,
                7.838,
                7.842,
                7.847,
                7.852,
                7.856,
                7.861,
                7.866,
                7.87,
                7.875,
                7.88,
                7.884,
                7.889,
                7.894,
                7.899,
                7.903,
                7.908,
                7.913,
                7.917,
                7.922,
                7.927,
                7.931,
                7.936,
                7.941,
                7.945,
                7.95,
                7.955,
                7.959,
                7.964,
                7.969,
                7.973,
                7.978,
                7.983,
                7.987,
                7.992,
                7.997,
                8.002,
                8.006,
                8.011,
                8.015,
                8.02,
                8.025,
                8.029,
                8.034,
                8.039,
                8.043,
                8.048,
                8.053,
                8.057,
                8.062,
                8.067,
                8.071,
                8.076,
                8.081,
                8.085,
                8.09,
                8.095,
                8.099,
                8.104,
                8.109,
                8.113,
                8.118,
                8.122,
                8.127,
                8.132,
                8.136,
                8.141,
                8.146,
                8.15,
                8.155,
                8.159,
                8.164,
                8.169,
                8.173,
                8.178,
                8.183,
                8.187,
                8.192,
                8.196,
                8.201,
                8.206,
                8.21,
                8.215,
                8.219,
                8.224,
                8.229,
                8.233,
                8.238,
                8.242,
                8.247,
                8.252,
                8.256,
                8.261,
                8.265,
                8.27,
                8.275,
                8.279,
                8.284,
                8.288,
                8.293,
                8.297,
                8.302,
                8.306,
                8.311,
                8.316,
                8.32,
                8.325,
                8.329,
                8.334,
                8.338,
                8.343,
                8.347,
                8.352,
                8.356,
                8.361,
                8.365,
                8.37,
                8.374,
                8.379,
                8.383,
                8.388,
                8.392,
                8.397,
                8.401,
                8.406,
                8.41,
                8.415,
                8.419,
                8.424,
                8.428,
                8.433,
                8.437,
                8.442,
                8.446,
                8.451,
                8.455,
                8.46,
                8.464,
                8.468,
                8.473,
                8.477,
                8.482,
                8.486,
                8.491,
                8.495,
                8.5,
                8.504,
                8.508,
                8.513,
                8.517,
                8.522,
                8.526,
                8.53,
                8.535,
                8.539,
                8.544,
                8.548,
                8.552,
                8.557,
                8.561,
                8.565,
                8.57,
                8.574,
                8.578,
                8.583,
                8.587,
                8.591,
                8.596,
                8.6,
                8.604,
                8.609,
                8.613,
                8.618,
                8.622,
                8.626,
                8.63,
                8.635,
                8.639,
                8.643,
                8.648,
                8.652,
                8.656,
                8.661,
                8.665,
                8.669,
                8.673,
                8.678,
                8.682,
                8.686,
                8.69,
                8.695,
                8.699,
                8.703,
                8.707,
                8.712,
                8.716,
                8.72,
                8.725,
                8.729,
                8.733,
                8.737,
                8.741,
                8.746,
                8.75,
                8.754,
                8.758,
                8.762,
                8.767,
                8.771,
                8.775,
                8.779,
                8.783,
                8.787,
                8.792,
                8.796,
                8.8,
                8.804,
                8.808,
                8.812,
                8.816,
                8.821,
                8.825,
                8.829,
                8.833,
                8.837,
                8.841,
                8.845,
                8.849,
                8.854,
                8.858,
                8.862,
                8.866,
                8.87,
                8.874,
                8.878,
                8.882,
                8.886,
                8.89,
                8.895,
                8.899,
                8.903,
                8.907,
                8.911,
                8.915,
                8.919,
                8.923,
                8.927,
                8.931,
                8.935,
                8.939,
                8.943,
                8.947,
                8.951,
                8.955,
                8.959,
                8.963,
                8.967,
                8.971,
                8.975,
                8.979,
                8.983,
                8.987,
                8.991,
                8.995,
                8.999,
                9.003,
                9.007,
                9.011,
                9.015,
                9.019,
                9.023,
                9.027,
                9.031,
                9.035,
                9.039,
                9.043,
                9.047,
                9.051,
                9.055,
                9.059,
                9.063,
                9.067,
                9.071,
                9.075,
                9.079,
                9.082,
                9.087,
                9.09,
                9.094,
                9.098,
                9.102,
                9.106,
                9.11,
                9.114,
                9.118,
                9.122,
                9.126,
                9.129,
                9.134,
                9.137,
                9.141,
                9.145,
                9.149,
                9.153,
                9.157,
                9.161,
                9.165,
                9.168,
                9.172,
                9.176,
                9.18,
                9.184,
                9.188,
                9.192,
                9.196,
                9.199,
                9.203,
                9.207,
                9.211,
                9.215,
                9.219,
                9.223,
                9.226,
                9.23,
                9.234,
                9.238,
                9.242,
                9.246,
                9.249,
                9.253,
                9.257,
                9.261,
                9.265,
                9.269,
                9.272,
                9.276,
                9.28,
                9.284,
                9.288,
                9.291,
                9.295,
                9.299,
                9.303,
                9.307,
                9.31,
                9.314,
                9.318,
                9.322,
                9.326,
                9.329,
                9.333,
                9.337,
                9.341,
                9.345,
                9.349,
                9.352,
                9.356,
                9.36,
                9.363,
                9.367,
                9.371,
                9.375,
                9.379,
                9.382,
                9.386,
                9.39,
                9.394,
                9.397,
                9.401,
                9.405,
                9.409,
                9.413,
                9.416,
                9.42,
                9.424,
                9.427,
                9.431,
                9.435,
                9.439,
                9.443,
                9.446,
                9.45,
                9.454,
                9.458,
                9.461,
                9.465,
                9.469,
                9.472,
                9.476,
                9.48,
                9.484,
                9.487,
                9.491,
                9.495,
                9.499,
                9.502,
                9.506,
                9.51,
                9.513,
                9.517,
                9.521,
                9.525,
                9.528,
                9.532,
                9.536,
                9.539,
                9.543,
                9.547,
                9.55,
                9.554,
                9.558,
                9.562,
                9.565,
                9.569,
                9.573,
                9.576,
                9.58,
                9.584,
                9.587,
                9.591,
                9.595,
                9.598,
                9.602,
                9.605,
                9.609,
                9.613,
                9.617,
                9.62,
                9.624,
                9.628,
                9.631,
                9.635,
                9.639,
                9.642,
                9.646,
                9.65,
                9.653,
                9.657,
                9.661,
                9.664,
                9.668,
                9.672,
                9.675,
                9.679,
                9.682,
                9.686,
                9.69,
                9.693,
                9.697,
                9.701,
                9.704,
                9.708,
                9.711,
                9.715,
                9.719,
                9.722,
                9.726,
                9.73,
                9.733,
                9.737,
                9.74,
                9.744,
                9.747,
                9.751,
                9.755,
                9.758,
                9.762,
                9.765,
                9.769,
                9.772,
                9.776,
                9.78,
                9.783,
                9.787,
                9.791,
                9.794,
                9.798,
                9.801,
                9.805,
                9.809,
                9.812,
                9.816,
                9.819,
                9.823,
                9.826,
                9.83,
                9.833,
                9.837,
                9.841,
                9.844,
                9.848,
                9.851,
                9.855,
                9.858,
                9.862,
                9.865,
                9.869,
                9.872,
                9.876,
                9.879,
                9.883,
                9.887,
                9.89,
                9.894,
                9.897,
                9.901,
                9.904,
                9.908,
                9.911,
                9.915,
                9.918,
                9.922,
                9.925,
                9.929,
                9.933,
                9.936,
                9.939,
                9.943,
                9.946,
                9.95,
                9.953,
                9.957,
                9.961,
                9.964,
                9.967,
                9.971,
                9.974,
                9.978,
                9.981,
                9.985,
                9.988,
                9.992,
                9.995,
                9.999,
                10.002,
                10.006,
                10.009,
                10.013,
                10.016,
                10.02,
                10.023,
                10.027,
                10.03,
                10.033,
                10.037,
                10.04,
                10.044,
                10.047,
                10.051,
                10.054,
                10.058,
                10.061,
                10.065,
                10.068,
                10.072,
                10.075,
                10.078,
                10.082,
                10.085,
                10.089,
                10.092,
                10.096,
                10.099,
                10.102,
                10.106,
                10.109,
                10.113,
                10.116,
                10.12,
                10.123,
                10.126,
                10.13,
                10.133,
                10.137,
                10.14,
                10.143,
                10.147,
                10.15,
                10.154,
                10.157,
                10.16,
                10.164,
                10.167,
                10.171,
                10.174,
                10.177,
                10.181,
                10.184,
                10.188,
                10.191,
                10.194,
                10.198,
                10.201,
                10.204,
                10.208,
                10.211,
                10.215,
                10.218,
                10.221,
                10.225,
                10.228,
                10.231,
                10.235,
                10.238,
                10.242,
                10.245,
                10.248,
                10.252,
                10.255,
                10.258,
                10.262,
                10.265,
                10.269,
                10.272,
                10.275,
                10.278,
                10.282,
                10.285,
                10.289,
                10.292,
                10.295,
                10.299,
                10.302,
                10.305,
                10.308,
                10.312,
                10.315,
                10.319,
                10.322,
                10.325,
                10.328,
                10.332,
                10.335,
                10.339,
                10.342,
                10.345,
                10.349,
                10.352,
                10.355,
                10.358,
                10.362,
                10.365,
                10.368,
                10.372,
                10.375,
                10.378,
                10.382,
                10.385,
                10.388,
                10.392,
                10.395,
                10.398,
                10.401,
                10.405,
                10.408,
                10.411,
                10.415,
                10.418,
                10.421,
                10.424,
                10.428,
                10.431,
                10.434,
                10.438,
                10.441,
                10.444,
                10.448,
                10.451,
                10.454,
                10.457,
                10.461,
                10.464,
                10.467,
                10.471,
                10.474,
                10.477,
                10.481,
                10.484,
                10.487,
                10.49,
                10.493,
                10.497,
                10.5,
                10.503,
                10.507,
                10.51,
                10.513,
                10.517,
                10.52,
                10.523,
                10.526,
                10.53,
                10.533,
                10.536,
                10.539,
                10.542,
                10.546,
                10.549,
                10.552,
                10.556,
                10.559,
                10.562,
                10.565,
                10.569,
                10.572,
                10.575,
                10.579,
                10.582,
                10.585,
                10.588,
                10.592,
                10.595,
                10.598,
                10.601,
                10.604,
                10.608,
                10.611,
                10.614,
                10.617,
                10.621,
                10.624,
                10.627,
                10.631,
                10.634,
                10.637,
                10.64,
                10.643,
                10.647,
                10.65,
                10.653,
                10.656,
                10.66,
                10.663,
                10.666,
                10.67,
                10.673,
                10.676,
                10.679,
                10.683,
                10.686,
                10.689,
                10.692,
                10.695,
                10.699,
                10.702,
                10.705,
                10.708,
                10.712,
                10.715,
                10.718,
                10.721,
                10.725,
                10.728,
                10.731,
                10.734,
                10.737,
                10.741,
                10.744,
                10.747,
                10.75,
                10.754,
                10.757,
                10.76,
                10.763,
                10.766,
                10.77,
                10.773,
                10.776,
                10.779,
                10.783,
                10.786,
                10.789,
                10.792,
                10.795,
                10.799,
                10.802,
                10.805,
                10.808,
                10.811,
                10.815,
                10.818,
                10.821,
                10.824,
                10.828,
                10.831,
                10.834,
                10.837,
                10.841,
                10.844,
                10.847,
                10.85,
                10.853,
                10.857,
                10.86,
                10.863,
                10.866,
                10.869,
                10.873,
                10.876,
                10.879,
                10.882,
                10.885,
                10.889,
                10.892,
                10.895,
                10.898,
                10.902,
                10.905,
                10.908,
                10.911,
                10.914,
                10.918,
                10.921,
                10.924,
                10.927,
                10.93,
                10.934,
                10.937,
                10.94,
                10.943,
                10.947,
                10.95,
                10.953,
                10.956,
                10.959,
                10.962,
                10.966,
                10.969,
                10.972,
                10.975,
                10.979,
                10.982,
                10.985,
                10.988,
                10.991,
                10.994,
                10.998,
                11.001,
                11.004,
                11.008,
                11.011,
                11.014,
                11.017,
                11.02,
                11.023,
                11.026,
                11.03,
                11.033,
                11.036,
                11.04,
                11.043,
                11.046,
                11.049,
                11.052,
                11.055,
                11.059,
                11.062,
                11.065,
                11.068,
                11.071,
                11.075,
                11.078,
                11.081,
                11.084,
                11.088,
                11.091,
                11.094,
                11.097,
                11.1,
                11.103,
                11.107,
                11.11,
                11.113,
                11.116,
                11.119,
                11.123,
                11.126,
                11.129,
                11.132,
                11.135,
                11.139,
                11.142,
                11.145,
                11.148,
                11.151,
                11.155,
                11.158,
                11.161,
                11.164,
                11.167,
                11.171,
                11.174,
                11.177,
                11.18,
                11.183,
                11.187,
                11.19,
                11.193,
                11.196,
                11.199,
                11.202,
                11.206,
                11.209,
                11.212,
                11.215,
                11.218,
                11.222,
                11.225,
                11.228,
                11.231,
                11.234,
                11.238,
                11.241,
                11.244,
                11.247,
                11.25,
                11.253,
                11.257,
                11.26,
                11.263,
                11.266,
                11.269,
                11.273,
                11.276,
                11.279,
                11.282,
                11.285,
                11.289,
                11.292,
                11.295,
                11.298,
                11.301,
                11.304,
                11.307,
                11.311,
                11.314,
                11.317,
                11.32,
                11.323,
                11.327,
                11.33,
                11.333,
                11.336,
                11.339,
                11.343,
                11.346,
                11.349,
                11.352,
                11.355,
                11.358,
                11.362,
                11.365,
                11.368,
                11.371,
                11.374,
                11.377,
                11.38,
                11.384,
                11.387,
                11.39,
                11.393,
                11.397,
                11.4,
                11.403,
                11.406,
                11.409,
                11.412,
                11.415,
                11.419,
                11.422,
                11.425,
                11.428,
                11.431,
                11.434,
                11.438,
                11.441,
                11.444,
                11.447,
                11.45,
                11.453,
                11.457,
                11.46,
                11.463,
                11.466,
                11.469,
                11.472,
                11.475,
                11.479,
                11.481,
                11.485,
                11.488,
                11.491,
                11.494,
                11.497,
                11.5,
                11.504,
                11.507,
                11.51,
                11.513,
                11.516,
                11.519,
                11.523,
                11.525,
                11.529,
                11.532,
                11.535,
                11.538,
                11.541,
                11.544,
                11.548,
                11.551,
                11.554,
                11.557,
                11.56,
                11.563,
                11.566,
                11.569,
                11.573,
                11.576,
                11.579,
                11.582,
                11.585,
                11.588,
                11.591,
                11.595,
                11.598,
                11.601,
                11.604,
                11.607,
                11.61,
                11.613,
                11.616,
                11.619,
                11.622,
                11.626,
                11.629,
                11.632,
                11.635,
                11.638,
                11.641,
                11.644,
                11.648,
                11.651,
                11.654,
                11.657,
                11.66,
                11.663,
                11.666,
                11.669,
                11.672,
                11.676,
                11.678,
                11.682,
                11.685,
                11.688,
                11.691,
                11.694,
                11.697,
                11.7,
                11.703,
                11.706,
                11.71,
                11.712,
                11.716,
                11.719,
                11.722,
                11.725,
                11.728,
                11.731,
                11.734,
                11.737,
                11.74,
                11.743,
                11.746,
                11.75,
                11.753,
                11.756,
                11.759,
                11.762,
                11.765,
                11.768,
                11.771,
                11.774,
                11.777,
                11.78,
                11.783,
                11.786,
                11.79,
                11.793,
                11.796,
                11.799,
                11.802,
                11.805,
                11.808,
                11.811,
                11.814,
                11.817,
                11.82,
                11.823,
                11.826,
                11.829,
                11.833,
                11.835,
                11.838,
                11.842,
                11.845,
                11.848,
                11.851,
                11.854,
                11.857,
                11.86,
                11.863,
                11.866,
                11.869,
                11.872,
                11.875,
                11.878,
                11.881,
                11.884,
                11.887,
                11.89,
                11.893,
                11.896,
                11.899,
                11.903,
                11.905,
                11.909,
                11.912,
                11.915,
                11.918,
                11.921,
                11.924,
                11.927,
                11.93,
                11.933,
                11.936,
                11.939,
                11.942,
                11.945,
                11.948,
                11.951,
                11.954,
                11.957,
                11.96,
                11.963,
                11.966,
                11.969,
                11.972,
                11.975,
                11.978,
                11.981,
                11.984,
                11.987,
                11.99,
                11.993,
                11.996,
                11.999,
                12.002,
                12.005

            ],
            P1: [
                2.273,
                2.231,
                2.24,
                2.255,
                2.274,
                2.294,
                2.316,
                2.339,
                2.362,
                2.387,
                2.412,
                2.439,
                2.466,
                2.494,
                2.523,
                2.552,
                2.582,
                2.612,
                2.643,
                2.673,
                2.704,
                2.734,
                2.765,
                2.795,
                2.825,
                2.855,
                2.885,
                2.915,
                2.944,
                2.974,
                3.003,
                3.032,
                3.06,
                3.088,
                3.116,
                3.144,
                3.171,
                3.198,
                3.225,
                3.252,
                3.278,
                3.304,
                3.33,
                3.356,
                3.381,
                3.406,
                3.431,
                3.456,
                3.48,
                3.504,
                3.528,
                3.551,
                3.575,
                3.598,
                3.621,
                3.644,
                3.666,
                3.688,
                3.711,
                3.732,
                3.754,
                3.776,
                3.797,
                3.818,
                3.839,
                3.86,
                3.88,
                3.901,
                3.921,
                3.941,
                3.961,
                3.981,
                4.001,
                4.02,
                4.039,
                4.059,
                4.078,
                4.096,
                4.115,
                4.134,
                4.152,
                4.17,
                4.188,
                4.207,
                4.224,
                4.242,
                4.26,
                4.277,
                4.294,
                4.312,
                4.329,
                4.346,
                4.363,
                4.379,
                4.396,
                4.413,
                4.429,
                4.445,
                4.461,
                4.477,
                4.493,
                4.509,
                4.525,
                4.541,
                4.556,
                4.571,
                4.587,
                4.602,
                4.617,
                4.632,
                4.647,
                4.662,
                4.677,
                4.691,
                4.706,
                4.72,
                4.734,
                4.749,
                4.763,
                4.777,
                4.791,
                4.804,
                4.818,
                4.832,
                4.845,
                4.859,
                4.872,
                4.886,
                4.899,
                4.912,
                4.925,
                4.938,
                4.951,
                4.964,
                4.976,
                4.989,
                5.002,
                5.014,
                5.026,
                5.039,
                5.051,
                5.063,
                5.075,
                5.087,
                5.099,
                5.111,
                5.123,
                5.135,
                5.146,
                5.158,
                5.169,
                5.181,
                5.192,
                5.203,
                5.214,
                5.226,
                5.237,
                5.248,
                5.259,
                5.27,
                5.28,
                5.291,
                5.302,
                5.312,
                5.323,
                5.334,
                5.344,
                5.354,
                5.365,
                5.375,
                5.385,
                5.395,
                5.406,
                5.416,
                5.426,
                5.436,
                5.446,
                5.455,
                5.465,
                5.475,
                5.485,
                5.494,
                5.504,
                5.514,
                5.523,
                5.532,
                5.542,
                5.551,
                5.561,
                5.57,
                5.579,
                5.588,
                5.597,
                5.607,
                5.616,
                5.625,
                5.634,
                5.642,
                5.651,
                5.66,
                5.669,
                5.678,
                5.686,
                5.695,
                5.704,
                5.712,
                5.721,
                5.729,
                5.738,
                5.746,
                5.755,
                5.763,
                5.772,
                5.78,
                5.788,
                5.796,
                5.805,
                5.813,
                5.821,
                5.829,
                5.837,
                5.845,
                5.853,
                5.861,
                5.869,
                5.877,
                5.885,
                5.893,
                5.9,
                5.908,
                5.916,
                5.924,
                5.931,
                5.939,
                5.947,
                5.954,
                5.962,
                5.969,
                5.977,
                5.984,
                5.992,
                5.999,
                6.007,
                6.014,
                6.021,
                6.029,
                6.036,
                6.043,
                6.05,
                6.058,
                6.065,
                6.072,
                6.079,
                6.086,
                6.093,
                6.1,
                6.107,
                6.114,
                6.121,
                6.128,
                6.135,
                6.142,
                6.149,
                6.156,
                6.163,
                6.17,
                6.176,
                6.183,
                6.19,
                6.197,
                6.203,
                6.21,
                6.217,
                6.224,
                6.23,
                6.237,
                6.243,
                6.25,
                6.257,
                6.263,
                6.27,
                6.276,
                6.283,
                6.289,
                6.296,
                6.302,
                6.308,
                6.315,
                6.321,
                6.328,
                6.334,
                6.34,
                6.347,
                6.353,
                6.359,
                6.366,
                6.372,
                6.378,
                6.384,
                6.391,
                6.397,
                6.403,
                6.409,
                6.415,
                6.422,
                6.428,
                6.434,
                6.44,
                6.446,
                6.452,
                6.458,
                6.464,
                6.47,
                6.476,
                6.482,
                6.489,
                6.495,
                6.501,
                6.507,
                6.512,
                6.519,
                6.525,
                6.53,
                6.536,
                6.542,
                6.548,
                6.554,
                6.56,
                6.566,
                6.572,
                6.578,
                6.584,
                6.589,
                6.595,
                6.601,
                6.607,
                6.613,
                6.619,
                6.624,
                6.63,
                6.636,
                6.642,
                6.648,
                6.653,
                6.659,
                6.665,
                6.671,
                6.676,
                6.682,
                6.688,
                6.693,
                6.699,
                6.705,
                6.711,
                6.716,
                6.722,
                6.728,
                6.733,
                6.739,
                6.745,
                6.751,
                6.756,
                6.762,
                6.767,
                6.773,
                6.779,
                6.784,
                6.79,
                6.796,
                6.801,
                6.807,
                6.812,
                6.818,
                6.824,
                6.829,
                6.835,
                6.84,
                6.846,
                6.851,
                6.857,
                6.863,
                6.868,
                6.874,
                6.879,
                6.885,
                6.89,
                6.896,
                6.901,
                6.907,
                6.913,
                6.918,
                6.924,
                6.929,
                6.935,
                6.94,
                6.946,
                6.951,
                6.957,
                6.962,
                6.968,
                6.973,
                6.979,
                6.984,
                6.99,
                6.995,
                7,
                7.006,
                7.011,
                7.017,
                7.022,
                7.028,
                7.033,
                7.039,
                7.044,
                7.05,
                7.055,
                7.06,
                7.066,
                7.071,
                7.077,
                7.082,
                7.088,
                7.093,
                7.098,
                7.104,
                7.109,
                7.115,
                7.12,
                7.126,
                7.131,
                7.136,
                7.142,
                7.147,
                7.153,
                7.158,
                7.163,
                7.169,
                7.174,
                7.18,
                7.185,
                7.19,
                7.196,
                7.201,
                7.207,
                7.212,
                7.217,
                7.223,
                7.228,
                7.233,
                7.239,
                7.244,
                7.25,
                7.255,
                7.26,
                7.266,
                7.271,
                7.276,
                7.282,
                7.287,
                7.292,
                7.298,
                7.303,
                7.309,
                7.314,
                7.319,
                7.325,
                7.33,
                7.335,
                7.341,
                7.346,
                7.351,
                7.357,
                7.362,
                7.367,
                7.373,
                7.378,
                7.383,
                7.389,
                7.394,
                7.399,
                7.405,
                7.41,
                7.415,
                7.421,
                7.426,
                7.431,
                7.437,
                7.442,
                7.447,
                7.453,
                7.458,
                7.463,
                7.469,
                7.474,
                7.479,
                7.485,
                7.49,
                7.495,
                7.5,
                7.506,
                7.511,
                7.516,
                7.522,
                7.527,
                7.532,
                7.538,
                7.543,
                7.548,
                7.553,
                7.559,
                7.564,
                7.569,
                7.575,
                7.58,
                7.585,
                7.59,
                7.596,
                7.601,
                7.606,
                7.612,
                7.617,
                7.622,
                7.627,
                7.633,
                7.638,
                7.643,
                7.649,
                7.654,
                7.659,
                7.664,
                7.67,
                7.675,
                7.68,
                7.685,
                7.691,
                7.696,
                7.701,
                7.706,
                7.712,
                7.717,
                7.722,
                7.727,
                7.732,
                7.738,
                7.743,
                7.748,
                7.754,
                7.759,
                7.764,
                7.769,
                7.774,
                7.78,
                7.785,
                7.79,
                7.795,
                7.801,
                7.806,
                7.811,
                7.816,
                7.822,
                7.827,
                7.832,
                7.837,
                7.842,
                7.848,
                7.853,
                7.858,
                7.863,
                7.868,
                7.874,
                7.879,
                7.884,
                7.889,
                7.894,
                7.9,
                7.905,
                7.91,
                7.915,
                7.92,
                7.926,
                7.931,
                7.936,
                7.941,
                7.946,
                7.952,
                7.957,
                7.962,
                7.967,
                7.972,
                7.977,
                7.983,
                7.988,
                7.993,
                7.998,
                8.003,
                8.008,
                8.014,
                8.019,
                8.024,
                8.029,
                8.034,
                8.039,
                8.045,
                8.05,
                8.055,
                8.06,
                8.065,
                8.07,
                8.076,
                8.081,
                8.086,
                8.091,
                8.096,
                8.101,
                8.107,
                8.112,
                8.117,
                8.122,
                8.127,
                8.132,
                8.137,
                8.143,
                8.148,
                8.153,
                8.158,
                8.163,
                8.168,
                8.173,
                8.179,
                8.184,
                8.189,
                8.194,
                8.199,
                8.204,
                8.209,
                8.215,
                8.22,
                8.225,
                8.23,
                8.235,
                8.24,
                8.245,
                8.251,
                8.256,
                8.261,
                8.266,
                8.271,
                8.276,
                8.281,
                8.286,
                8.292,
                8.297,
                8.302,
                8.307,
                8.312,
                8.317,
                8.322,
                8.327,
                8.333,
                8.338,
                8.343,
                8.348,
                8.353,
                8.358,
                8.363,
                8.368,
                8.374,
                8.379,
                8.384,
                8.389,
                8.394,
                8.399,
                8.404,
                8.409,
                8.414,
                8.42,
                8.425,
                8.43,
                8.435,
                8.44,
                8.445,
                8.45,
                8.455,
                8.461,
                8.466,
                8.471,
                8.476,
                8.481,
                8.486,
                8.491,
                8.497,
                8.502,
                8.507,
                8.512,
                8.517,
                8.522,
                8.527,
                8.532,
                8.537,
                8.543,
                8.548,
                8.553,
                8.558,
                8.563,
                8.568,
                8.573,
                8.578,
                8.583,
                8.589,
                8.594,
                8.599,
                8.604,
                8.609,
                8.614,
                8.619,
                8.624,
                8.63,
                8.635,
                8.64,
                8.645,
                8.65,
                8.655,
                8.66,
                8.665,
                8.67,
                8.675,
                8.68,
                8.686,
                8.691,
                8.696,
                8.701,
                8.706,
                8.711,
                8.716,
                8.721,
                8.727,
                8.732,
                8.737,
                8.742,
                8.747,
                8.752,
                8.757,
                8.762,
                8.767,
                8.772,
                8.777,
                8.782,
                8.788,
                8.793,
                8.798,
                8.803,
                8.808,
                8.813,
                8.818,
                8.823,
                8.828,
                8.833,
                8.839,
                8.844,
                8.849,
                8.854,
                8.859,
                8.864,
                8.869,
                8.874,
                8.879,
                8.884,
                8.889,
                8.894,
                8.899,
                8.904,
                8.909,
                8.915,
                8.92,
                8.925,
                8.93,
                8.935,
                8.94,
                8.945,
                8.95,
                8.955,
                8.96,
                8.965,
                8.97,
                8.975,
                8.98,
                8.985,
                8.99,
                8.995,
                9,
                9.005,
                9.01,
                9.015,
                9.02,
                9.025,
                9.03,
                9.035,
                9.04,
                9.045,
                9.05,
                9.055,
                9.06,
                9.065,
                9.07,
                9.075,
                9.08,
                9.085,
                9.09,
                9.095,
                9.1,
                9.105,
                9.11,
                9.115,
                9.12,
                9.125,
                9.13,
                9.135,
                9.14,
                9.145,
                9.15,
                9.155,
                9.16,
                9.165,
                9.17,
                9.175,
                9.18,
                9.184,
                9.189,
                9.194,
                9.199,
                9.204,
                9.209,
                9.214,
                9.219,
                9.224,
                9.229,
                9.234,
                9.238,
                9.243,
                9.248,
                9.253,
                9.258,
                9.263,
                9.268,
                9.272,
                9.277,
                9.282,
                9.287,
                9.292,
                9.297,
                9.302,
                9.306,
                9.311,
                9.316,
                9.321,
                9.326,
                9.331,
                9.335,
                9.34,
                9.345,
                9.35,
                9.355,
                9.359,
                9.364,
                9.369,
                9.374,
                9.379,
                9.383,
                9.388,
                9.393,
                9.398,
                9.402,
                9.407,
                9.412,
                9.417,
                9.421,
                9.426,
                9.431,
                9.436,
                9.441,
                9.445,
                9.45,
                9.455,
                9.459,
                9.464,
                9.469,
                9.474,
                9.478,
                9.483,
                9.488,
                9.492,
                9.497,
                9.502,
                9.506,
                9.511,
                9.516,
                9.52,
                9.525,
                9.53,
                9.534,
                9.539,
                9.544,
                9.548,
                9.553,
                9.558,
                9.562,
                9.567,
                9.572,
                9.576,
                9.581,
                9.585,
                9.59,
                9.595,
                9.599,
                9.604,
                9.609,
                9.613,
                9.618,
                9.622,
                9.627,
                9.632,
                9.636,
                9.641,
                9.645,
                9.65,
                9.654,
                9.659,
                9.663,
                9.668,
                9.673,
                9.677,
                9.682,
                9.686,
                9.691,
                9.695,
                9.7,
                9.704,
                9.709,
                9.713,
                9.718,
                9.722,
                9.727,
                9.731,
                9.736,
                9.74,
                9.745,
                9.749,
                9.754,
                9.758,
                9.763,
                9.767,
                9.772,
                9.776,
                9.781,
                9.785,
                9.79,
                9.794,
                9.799,
                9.803,
                9.808,
                9.812,
                9.816,
                9.821,
                9.825,
                9.83,
                9.834,
                9.839,
                9.843,
                9.847,
                9.852,
                9.856,
                9.861,
                9.865,
                9.87,
                9.874,
                9.878,
                9.883,
                9.887,
                9.892,
                9.896,
                9.9,
                9.905,
                9.909,
                9.913,
                9.918,
                9.922,
                9.927,
                9.931,
                9.935,
                9.94,
                9.944,
                9.948,
                9.953,
                9.957,
                9.962,
                9.966,
                9.97,
                9.975,
                9.979,
                9.983,
                9.988,
                9.992,
                9.996,
                10.001,
                10.005,
                10.009,
                10.014,
                10.018,
                10.022,
                10.027,
                10.031,
                10.035,
                10.04,
                10.044,
                10.048,
                10.053,
                10.057,
                10.061,
                10.065,
                10.07,
                10.074,
                10.078,
                10.083,
                10.087,
                10.091,
                10.096,
                10.1,
                10.104,
                10.108,
                10.113,
                10.117,
                10.121,
                10.126,
                10.13,
                10.134,
                10.139,
                10.143,
                10.147,
                10.151,
                10.156,
                10.16,
                10.164,
                10.168,
                10.173,
                10.177,
                10.181,
                10.185,
                10.19,
                10.194,
                10.198,
                10.203,
                10.207,
                10.211,
                10.215,
                10.219,
                10.224,
                10.228,
                10.232,
                10.236,
                10.241,
                10.245,
                10.249,
                10.253,
                10.257,
                10.262,
                10.266,
                10.27,
                10.274,
                10.279,
                10.283,
                10.287,
                10.291,
                10.296,
                10.3,
                10.304,
                10.308,
                10.312,
                10.317,
                10.321,
                10.325,
                10.329,
                10.334,
                10.338,
                10.342,
                10.346,
                10.35,
                10.354,
                10.359,
                10.363,
                10.367,
                10.371,
                10.375,
                10.38,
                10.384,
                10.388,
                10.392,
                10.396,
                10.4,
                10.405,
                10.409,
                10.413,
                10.417,
                10.421,
                10.426,
                10.43,
                10.434,
                10.438,
                10.442,
                10.446,
                10.451,
                10.455,
                10.459,
                10.463,
                10.467,
                10.471,
                10.475,
                10.48,
                10.484,
                10.488,
                10.492,
                10.496,
                10.5,
                10.505,
                10.509,
                10.513,
                10.517,
                10.521,
                10.525,
                10.529,
                10.534,
                10.538,
                10.542,
                10.546,
                10.55,
                10.554,
                10.558,
                10.562,
                10.566,
                10.571,
                10.575,
                10.579,
                10.583,
                10.587,
                10.591,
                10.595,
                10.599,
                10.604,
                10.608,
                10.612,
                10.616,
                10.62,
                10.624,
                10.628,
                10.632,
                10.636,
                10.641,
                10.645,
                10.649,
                10.653,
                10.657,
                10.661,
                10.665,
                10.669,
                10.673,
                10.677,
                10.681,
                10.686,
                10.689,
                10.694,
                10.698,
                10.702,
                10.706,
                10.71,
                10.714,
                10.718,
                10.722,
                10.726,
                10.73,
                10.734,
                10.738,
                10.742,
                10.747,
                10.75,
                10.755,
                10.759,
                10.763,
                10.767,
                10.771,
                10.775,
                10.779,
                10.783,
                10.787,
                10.791,
                10.795,
                10.799,
                10.803,
                10.807,
                10.811,
                10.815,
                10.819,
                10.823,
                10.827,
                10.831,
                10.835,
                10.839,
                10.843,
                10.847,
                10.851,
                10.855,
                10.859,
                10.863,
                10.867,
                10.871,
                10.875,
                10.879,
                10.883,
                10.887,
                10.891,
                10.895,
                10.899,
                10.903,
                10.907,
                10.911,
                10.915,
                10.919,
                10.923,
                10.927,
                10.931,
                10.935,
                10.939,
                10.943,
                10.947,
                10.951,
                10.955,
                10.959,
                10.963,
                10.967,
                10.971,
                10.975,
                10.979,
                10.983,
                10.987,
                10.991,
                10.995,
                10.998,
                11.002,
                11.006,
                11.01,
                11.014,
                11.018,
                11.022,
                11.026,
                11.03,
                11.034,
                11.038,
                11.042,
                11.046,
                11.05,
                11.054,
                11.058,
                11.062,
                11.065,
                11.069,
                11.073,
                11.077,
                11.081,
                11.085,
                11.089,
                11.093,
                11.097,
                11.101,
                11.105,
                11.108,
                11.112,
                11.116,
                11.12,
                11.124,
                11.128,
                11.132,
                11.136,
                11.14,
                11.144,
                11.148,
                11.151,
                11.155,
                11.159,
                11.163,
                11.167,
                11.171,
                11.175,
                11.179,
                11.182,
                11.186,
                11.19,
                11.194,
                11.198,
                11.202,
                11.206,
                11.21,
                11.213,
                11.217,
                11.221,
                11.225,
                11.229,
                11.233,
                11.237,
                11.241,
                11.244,
                11.248,
                11.252,
                11.256,
                11.26,
                11.264,
                11.267,
                11.271,
                11.275,
                11.279,
                11.283,
                11.286,
                11.291,
                11.294,
                11.298,
                11.302,
                11.306,
                11.31,
                11.313,
                11.317,
                11.321,
                11.325,
                11.329,
                11.333,
                11.336,
                11.34,
                11.344,
                11.348,
                11.352,
                11.355,
                11.359,
                11.363,
                11.367,
                11.371,
                11.375,
                11.378,
                11.382,
                11.386,
                11.39,
                11.394,
                11.397,
                11.401,
                11.405,
                11.409,
                11.413,
                11.416,
                11.42,
                11.424,
                11.428,
                11.432,
                11.435,
                11.439,
                11.443,
                11.447,
                11.451,
                11.454,
                11.458,
                11.462,
                11.466,
                11.47,
                11.473,
                11.477,
                11.481,
                11.485,
                11.489,
                11.492,
                11.496,
                11.5,
                11.504,
                11.507,
                11.511,
                11.515,
                11.519,
                11.523,
                11.526,
                11.53,
                11.534,
                11.538,
                11.541,
                11.545,
                11.549,
                11.553,
                11.557,
                11.56,
                11.564,
                11.568,
                11.571,
                11.575,
                11.579,
                11.583,
                11.587,
                11.59,
                11.594,
                11.598,
                11.602,
                11.605,
                11.609,
                11.613,
                11.617,
                11.62,
                11.624,
                11.628,
                11.632,
                11.635,
                11.639,
                11.643,
                11.647,
                11.65,
                11.654,
                11.658,
                11.661,
                11.665,
                11.669,
                11.673,
                11.676,
                11.68,
                11.684,
                11.688,
                11.692,
                11.695,
                11.699,
                11.703,
                11.707,
                11.71,
                11.714,
                11.718,
                11.722,
                11.725,
                11.729,
                11.733,
                11.736,
                11.74,
                11.744,
                11.748,
                11.751,
                11.755,
                11.759,
                11.762,
                11.766,
                11.77,
                11.774,
                11.777,
                11.781,
                11.785,
                11.789,
                11.792,
                11.796,
                11.8,
                11.804,
                11.807,
                11.811,
                11.815,
                11.818,
                11.822,
                11.826,
                11.83,
                11.833,
                11.837,
                11.841,
                11.845,
                11.848,
                11.852,
                11.856,
                11.86,
                11.863,
                11.867,
                11.871,
                11.874,
                11.878,
                11.882,
                11.886,
                11.889,
                11.893,
                11.897,
                11.901,
                11.904,
                11.908,
                11.911,
                11.915,
                11.919,
                11.923,
                11.926,
                11.93,
                11.934,
                11.938,
                11.941,
                11.945,
                11.949,
                11.952,
                11.956,
                11.96,
                11.963,
                11.967,
                11.971,
                11.975,
                11.978,
                11.982,
                11.986,
                11.99,
                11.993,
                11.997,
                12.001,
                12.004,
                12.008,
                12.012,
                12.015,
                12.019,
                12.023,
                12.027,
                12.03,
                12.034,
                12.038,
                12.041,
                12.045,
                12.049,
                12.053,
                12.056,
                12.06,
                12.064,
                12.067,
                12.071,
                12.075,
                12.078,
                12.082,
                12.086,
                12.09,
                12.093,
                12.097,
                12.101,
                12.104,
                12.108,
                12.112,
                12.115,
                12.119,
                12.123,
                12.127,
                12.13,
                12.134,
                12.138,
                12.141,
                12.145,
                12.149,
                12.152,
                12.156,
                12.16,
                12.164,
                12.167,
                12.171,
                12.175,
                12.178,
                12.182,
                12.186,
                12.19,
                12.193,
                12.197,
                12.201,
                12.204,
                12.208,
                12.212,
                12.215,
                12.219,
                12.223,
                12.226,
                12.23,
                12.234,
                12.238,
                12.241,
                12.245,
                12.249,
                12.252,
                12.256,
                12.26,
                12.263,
                12.267,
                12.271,
                12.275,
                12.278,
                12.282,
                12.286,
                12.289,
                12.293,
                12.297,
                12.3,
                12.304,
                12.308,
                12.312,
                12.315,
                12.319,
                12.323,
                12.326,
                12.33,
                12.334,
                12.337,
                12.341,
                12.345,
                12.348,
                12.352,
                12.356,
                12.359,
                12.363,
                12.367,
                12.371,
                12.374,
                12.378,
                12.381,
                12.385,
                12.389,
                12.392,
                12.396,
                12.4,
                12.403,
                12.407,
                12.411,
                12.415,
                12.418,
                12.422,
                12.426,
                12.429,
                12.433,
                12.437,
                12.44,
                12.444,
                12.448,
                12.451,
                12.455,
                12.459,
                12.462,
                12.466,
                12.47,
                12.473,
                12.477,
                12.481,
                12.484,
                12.488,
                12.492,
                12.495,
                12.499,
                12.503,
                12.506,
                12.51,
                12.514,
                12.517,
                12.521,
                12.525,
                12.528,
                12.532,
                12.536,
                12.539,
                12.543,
                12.547,
                12.55,
                12.554,
                12.558,
                12.561,
                12.565,
                12.569,
                12.572,
                12.576,
                12.58,
                12.583,
                12.587,
                12.591,
                12.594,
                12.598,
                12.602,
                12.605,
                12.609,
                12.613,
                12.616,
                12.62,
                12.623,
                12.627,
                12.631,
                12.635,
                12.638,
                12.642,
                12.645,
                12.649,
                12.653,
                12.656,
                12.66,
                12.663,
                12.667,
                12.671,
                12.674,
                12.678,
                12.682,
                12.685,
                12.689,
                12.692,
                12.696,
                12.7,
                12.704,
                12.707,
                12.711,
                12.714,
                12.718,
                12.722,
                12.725,
                12.729,
                12.733,
                12.736,
                12.74,
                12.743,
                12.747,
                12.751,
                12.754,
                12.758,
                12.761,
                12.765,
                12.769,
                12.772,
                12.776,
                12.78,
                12.783,
                12.787,
                12.79,
                12.794,
                12.798,
                12.801,
                12.805,
                12.808,
                12.812,
                12.816,
                12.819,
                12.823,
                12.826,
                12.83,
                12.834,
                12.837,
                12.841,
                12.844,
                12.848,
                12.852,
                12.855,
                12.859,
                12.862,
                12.866,
                12.87,
                12.873,
                12.877,
                12.88,
                12.884,
                12.888,
                12.891,
                12.895,
                12.898,
                12.902,
                12.905,
                12.909,
                12.913,
                12.916,
                12.92,
                12.923,
                12.927,
                12.93,
                12.934,
                12.938,
                12.941,
                12.945,
                12.948,
                12.952,
                12.955,
                12.959,
                12.963,
                12.966,
                12.97,
                12.973,
                12.977,
                12.98,
                12.984,
                12.987,
                12.991,
                12.995,
                12.998,
                13.002,
                13.005,
                13.009,
                13.013,
                13.016,
                13.02,
                13.023,
                13.027,
                13.03,
                13.034,
                13.037,
                13.041,
                13.045,
                13.048,
                13.051,
                13.055,
                13.059,
                13.062,
                13.066,
                13.069,
                13.073,
                13.076,
                13.08,
                13.083,
                13.087,
                13.091,
                13.094,
                13.098,
                13.101,
                13.105,
                13.108,
                13.112,
                13.115,
                13.119,
                13.122,
                13.126,
                13.129,
                13.133,
                13.136,
                13.14,
                13.143,
                13.147,
                13.15,
                13.154,
                13.157,
                13.161,
                13.165,
                13.168,
                13.172,
                13.175,
                13.178,
                13.182,
                13.185,
                13.189,
                13.193,
                13.196,
                13.2,
                13.203,
                13.206,
                13.21,
                13.213,
                13.217,
                13.221,
                13.224,
                13.227,
                13.231,
                13.234,
                13.238,
                13.241,
                13.245,
                13.248,
                13.252,
                13.255,
                13.259,
                13.262

            ],
            P3: [
                2.44,
                2.398,
                2.408,
                2.424,
                2.443,
                2.464,
                2.487,
                2.51,
                2.535,
                2.561,
                2.587,
                2.615,
                2.643,
                2.672,
                2.702,
                2.733,
                2.764,
                2.795,
                2.827,
                2.859,
                2.891,
                2.923,
                2.954,
                2.986,
                3.018,
                3.049,
                3.08,
                3.111,
                3.142,
                3.172,
                3.203,
                3.233,
                3.262,
                3.292,
                3.321,
                3.35,
                3.378,
                3.406,
                3.434,
                3.462,
                3.49,
                3.517,
                3.544,
                3.57,
                3.597,
                3.623,
                3.648,
                3.674,
                3.699,
                3.724,
                3.749,
                3.774,
                3.798,
                3.822,
                3.846,
                3.87,
                3.893,
                3.916,
                3.939,
                3.962,
                3.985,
                4.007,
                4.029,
                4.051,
                4.073,
                4.095,
                4.116,
                4.137,
                4.158,
                4.179,
                4.2,
                4.221,
                4.241,
                4.261,
                4.281,
                4.301,
                4.321,
                4.341,
                4.36,
                4.379,
                4.399,
                4.418,
                4.436,
                4.455,
                4.474,
                4.492,
                4.51,
                4.529,
                4.547,
                4.565,
                4.582,
                4.6,
                4.617,
                4.635,
                4.652,
                4.669,
                4.687,
                4.703,
                4.72,
                4.737,
                4.754,
                4.77,
                4.786,
                4.803,
                4.819,
                4.835,
                4.851,
                4.867,
                4.882,
                4.898,
                4.913,
                4.929,
                4.944,
                4.959,
                4.974,
                4.989,
                5.004,
                5.019,
                5.034,
                5.048,
                5.063,
                5.077,
                5.091,
                5.106,
                5.12,
                5.134,
                5.148,
                5.162,
                5.175,
                5.189,
                5.203,
                5.216,
                5.23,
                5.243,
                5.256,
                5.269,
                5.282,
                5.295,
                5.308,
                5.321,
                5.334,
                5.346,
                5.359,
                5.372,
                5.384,
                5.396,
                5.409,
                5.421,
                5.433,
                5.445,
                5.457,
                5.469,
                5.481,
                5.492,
                5.504,
                5.516,
                5.527,
                5.539,
                5.55,
                5.561,
                5.573,
                5.584,
                5.595,
                5.606,
                5.617,
                5.628,
                5.639,
                5.65,
                5.661,
                5.671,
                5.682,
                5.692,
                5.703,
                5.714,
                5.724,
                5.734,
                5.745,
                5.755,
                5.765,
                5.775,
                5.786,
                5.796,
                5.806,
                5.816,
                5.825,
                5.835,
                5.845,
                5.855,
                5.865,
                5.874,
                5.884,
                5.893,
                5.903,
                5.913,
                5.922,
                5.932,
                5.941,
                5.95,
                5.959,
                5.969,
                5.978,
                5.987,
                5.996,
                6.005,
                6.014,
                6.023,
                6.032,
                6.041,
                6.05,
                6.059,
                6.067,
                6.076,
                6.085,
                6.094,
                6.102,
                6.111,
                6.119,
                6.128,
                6.136,
                6.145,
                6.153,
                6.162,
                6.17,
                6.178,
                6.187,
                6.195,
                6.203,
                6.211,
                6.22,
                6.228,
                6.236,
                6.244,
                6.252,
                6.26,
                6.268,
                6.276,
                6.284,
                6.292,
                6.299,
                6.307,
                6.315,
                6.323,
                6.331,
                6.338,
                6.346,
                6.354,
                6.361,
                6.369,
                6.377,
                6.384,
                6.392,
                6.399,
                6.407,
                6.414,
                6.421,
                6.429,
                6.436,
                6.444,
                6.451,
                6.458,
                6.465,
                6.473,
                6.48,
                6.487,
                6.494,
                6.502,
                6.509,
                6.516,
                6.523,
                6.53,
                6.537,
                6.544,
                6.551,
                6.558,
                6.565,
                6.572,
                6.579,
                6.586,
                6.593,
                6.599,
                6.606,
                6.613,
                6.62,
                6.627,
                6.634,
                6.64,
                6.647,
                6.654,
                6.66,
                6.667,
                6.674,
                6.68,
                6.687,
                6.694,
                6.7,
                6.707,
                6.713,
                6.72,
                6.727,
                6.733,
                6.74,
                6.746,
                6.753,
                6.759,
                6.766,
                6.772,
                6.778,
                6.785,
                6.791,
                6.798,
                6.804,
                6.81,
                6.817,
                6.823,
                6.829,
                6.836,
                6.842,
                6.848,
                6.855,
                6.861,
                6.867,
                6.874,
                6.88,
                6.886,
                6.892,
                6.899,
                6.905,
                6.911,
                6.917,
                6.923,
                6.929,
                6.935,
                6.942,
                6.948,
                6.954,
                6.96,
                6.966,
                6.972,
                6.978,
                6.984,
                6.99,
                6.996,
                7.003,
                7.009,
                7.015,
                7.021,
                7.027,
                7.033,
                7.039,
                7.045,
                7.051,
                7.057,
                7.063,
                7.069,
                7.075,
                7.081,
                7.087,
                7.093,
                7.099,
                7.105,
                7.111,
                7.116,
                7.122,
                7.128,
                7.134,
                7.14,
                7.146,
                7.152,
                7.158,
                7.164,
                7.17,
                7.175,
                7.181,
                7.187,
                7.193,
                7.199,
                7.205,
                7.211,
                7.216,
                7.222,
                7.228,
                7.234,
                7.24,
                7.246,
                7.251,
                7.257,
                7.263,
                7.269,
                7.275,
                7.28,
                7.286,
                7.292,
                7.298,
                7.304,
                7.309,
                7.315,
                7.321,
                7.327,
                7.332,
                7.338,
                7.344,
                7.35,
                7.355,
                7.361,
                7.367,
                7.373,
                7.378,
                7.384,
                7.39,
                7.396,
                7.401,
                7.407,
                7.413,
                7.418,
                7.424,
                7.43,
                7.435,
                7.441,
                7.447,
                7.453,
                7.458,
                7.464,
                7.47,
                7.475,
                7.481,
                7.487,
                7.492,
                7.498,
                7.504,
                7.51,
                7.515,
                7.521,
                7.527,
                7.532,
                7.538,
                7.543,
                7.549,
                7.555,
                7.56,
                7.566,
                7.572,
                7.577,
                7.583,
                7.589,
                7.594,
                7.6,
                7.606,
                7.611,
                7.617,
                7.623,
                7.628,
                7.634,
                7.639,
                7.645,
                7.651,
                7.656,
                7.662,
                7.668,
                7.673,
                7.679,
                7.684,
                7.69,
                7.696,
                7.701,
                7.707,
                7.712,
                7.718,
                7.724,
                7.729,
                7.735,
                7.74,
                7.746,
                7.752,
                7.757,
                7.763,
                7.768,
                7.774,
                7.779,
                7.785,
                7.791,
                7.796,
                7.802,
                7.807,
                7.813,
                7.819,
                7.824,
                7.83,
                7.835,
                7.841,
                7.846,
                7.852,
                7.858,
                7.863,
                7.869,
                7.874,
                7.88,
                7.885,
                7.891,
                7.897,
                7.902,
                7.908,
                7.913,
                7.919,
                7.924,
                7.93,
                7.936,
                7.941,
                7.947,
                7.952,
                7.958,
                7.963,
                7.969,
                7.974,
                7.98,
                7.985,
                7.991,
                7.997,
                8.002,
                8.008,
                8.013,
                8.019,
                8.024,
                8.03,
                8.035,
                8.041,
                8.046,
                8.052,
                8.057,
                8.063,
                8.068,
                8.074,
                8.079,
                8.085,
                8.091,
                8.096,
                8.101,
                8.107,
                8.112,
                8.118,
                8.124,
                8.129,
                8.135,
                8.14,
                8.146,
                8.151,
                8.157,
                8.162,
                8.168,
                8.173,
                8.178,
                8.184,
                8.189,
                8.195,
                8.2,
                8.206,
                8.211,
                8.217,
                8.222,
                8.228,
                8.233,
                8.239,
                8.244,
                8.25,
                8.255,
                8.261,
                8.266,
                8.272,
                8.277,
                8.283,
                8.288,
                8.294,
                8.299,
                8.304,
                8.31,
                8.315,
                8.321,
                8.326,
                8.332,
                8.337,
                8.343,
                8.348,
                8.353,
                8.359,
                8.364,
                8.37,
                8.375,
                8.381,
                8.386,
                8.392,
                8.397,
                8.402,
                8.408,
                8.413,
                8.419,
                8.424,
                8.43,
                8.435,
                8.44,
                8.446,
                8.451,
                8.457,
                8.462,
                8.468,
                8.473,
                8.478,
                8.484,
                8.489,
                8.495,
                8.5,
                8.505,
                8.511,
                8.516,
                8.522,
                8.527,
                8.533,
                8.538,
                8.543,
                8.549,
                8.554,
                8.56,
                8.565,
                8.571,
                8.576,
                8.581,
                8.587,
                8.592,
                8.598,
                8.603,
                8.608,
                8.614,
                8.619,
                8.625,
                8.63,
                8.636,
                8.641,
                8.646,
                8.652,
                8.657,
                8.662,
                8.668,
                8.673,
                8.679,
                8.684,
                8.689,
                8.695,
                8.7,
                8.706,
                8.711,
                8.716,
                8.722,
                8.727,
                8.733,
                8.738,
                8.743,
                8.749,
                8.754,
                8.76,
                8.765,
                8.77,
                8.776,
                8.781,
                8.787,
                8.792,
                8.797,
                8.803,
                8.808,
                8.814,
                8.819,
                8.824,
                8.83,
                8.835,
                8.841,
                8.846,
                8.851,
                8.857,
                8.862,
                8.868,
                8.873,
                8.878,
                8.884,
                8.889,
                8.894,
                8.9,
                8.905,
                8.911,
                8.916,
                8.921,
                8.927,
                8.932,
                8.938,
                8.943,
                8.948,
                8.954,
                8.959,
                8.965,
                8.97,
                8.975,
                8.981,
                8.986,
                8.992,
                8.997,
                9.003,
                9.008,
                9.013,
                9.019,
                9.024,
                9.029,
                9.035,
                9.04,
                9.045,
                9.051,
                9.056,
                9.062,
                9.067,
                9.072,
                9.078,
                9.083,
                9.089,
                9.094,
                9.099,
                9.105,
                9.11,
                9.116,
                9.121,
                9.126,
                9.132,
                9.137,
                9.143,
                9.148,
                9.153,
                9.159,
                9.164,
                9.169,
                9.175,
                9.18,
                9.186,
                9.191,
                9.196,
                9.202,
                9.207,
                9.213,
                9.218,
                9.223,
                9.229,
                9.234,
                9.239,
                9.245,
                9.25,
                9.256,
                9.261,
                9.266,
                9.272,
                9.277,
                9.282,
                9.288,
                9.293,
                9.298,
                9.304,
                9.309,
                9.315,
                9.32,
                9.325,
                9.331,
                9.336,
                9.341,
                9.347,
                9.352,
                9.357,
                9.363,
                9.368,
                9.374,
                9.379,
                9.384,
                9.39,
                9.395,
                9.4,
                9.406,
                9.411,
                9.416,
                9.422,
                9.427,
                9.432,
                9.438,
                9.443,
                9.448,
                9.454,
                9.459,
                9.464,
                9.469,
                9.475,
                9.48,
                9.485,
                9.491,
                9.496,
                9.501,
                9.507,
                9.512,
                9.517,
                9.523,
                9.528,
                9.533,
                9.538,
                9.544,
                9.549,
                9.554,
                9.56,
                9.565,
                9.57,
                9.575,
                9.581,
                9.586,
                9.591,
                9.596,
                9.602,
                9.607,
                9.612,
                9.617,
                9.623,
                9.628,
                9.633,
                9.638,
                9.644,
                9.649,
                9.654,
                9.659,
                9.665,
                9.67,
                9.675,
                9.68,
                9.685,
                9.69,
                9.696,
                9.701,
                9.706,
                9.711,
                9.717,
                9.722,
                9.727,
                9.732,
                9.737,
                9.742,
                9.748,
                9.753,
                9.758,
                9.763,
                9.768,
                9.773,
                9.778,
                9.784,
                9.789,
                9.794,
                9.799,
                9.804,
                9.809,
                9.814,
                9.819,
                9.825,
                9.83,
                9.835,
                9.84,
                9.845,
                9.85,
                9.855,
                9.86,
                9.865,
                9.87,
                9.876,
                9.881,
                9.886,
                9.891,
                9.896,
                9.901,
                9.906,
                9.911,
                9.916,
                9.921,
                9.926,
                9.931,
                9.936,
                9.941,
                9.946,
                9.951,
                9.956,
                9.961,
                9.966,
                9.971,
                9.976,
                9.981,
                9.986,
                9.991,
                9.996,
                10.001,
                10.006,
                10.011,
                10.016,
                10.021,
                10.026,
                10.031,
                10.036,
                10.041,
                10.046,
                10.051,
                10.056,
                10.061,
                10.066,
                10.071,
                10.076,
                10.081,
                10.085,
                10.09,
                10.095,
                10.1,
                10.105,
                10.11,
                10.115,
                10.12,
                10.125,
                10.129,
                10.134,
                10.139,
                10.144,
                10.149,
                10.154,
                10.159,
                10.164,
                10.168,
                10.173,
                10.178,
                10.183,
                10.188,
                10.193,
                10.198,
                10.202,
                10.207,
                10.212,
                10.217,
                10.222,
                10.227,
                10.231,
                10.236,
                10.241,
                10.246,
                10.25,
                10.255,
                10.26,
                10.265,
                10.27,
                10.274,
                10.279,
                10.284,
                10.289,
                10.294,
                10.298,
                10.303,
                10.308,
                10.313,
                10.317,
                10.322,
                10.327,
                10.332,
                10.336,
                10.341,
                10.346,
                10.351,
                10.355,
                10.36,
                10.365,
                10.37,
                10.374,
                10.379,
                10.384,
                10.388,
                10.393,
                10.398,
                10.402,
                10.407,
                10.412,
                10.417,
                10.421,
                10.426,
                10.431,
                10.435,
                10.44,
                10.445,
                10.449,
                10.454,
                10.459,
                10.463,
                10.468,
                10.473,
                10.477,
                10.482,
                10.487,
                10.491,
                10.496,
                10.501,
                10.505,
                10.51,
                10.515,
                10.519,
                10.524,
                10.529,
                10.533,
                10.538,
                10.542,
                10.547,
                10.552,
                10.556,
                10.561,
                10.566,
                10.57,
                10.575,
                10.58,
                10.584,
                10.589,
                10.593,
                10.598,
                10.602,
                10.607,
                10.612,
                10.616,
                10.621,
                10.626,
                10.63,
                10.635,
                10.639,
                10.644,
                10.649,
                10.653,
                10.658,
                10.662,
                10.667,
                10.672,
                10.676,
                10.681,
                10.685,
                10.69,
                10.694,
                10.699,
                10.703,
                10.708,
                10.713,
                10.717,
                10.722,
                10.726,
                10.731,
                10.735,
                10.74,
                10.745,
                10.749,
                10.754,
                10.758,
                10.763,
                10.767,
                10.772,
                10.776,
                10.781,
                10.785,
                10.79,
                10.795,
                10.799,
                10.804,
                10.808,
                10.813,
                10.817,
                10.822,
                10.826,
                10.831,
                10.835,
                10.84,
                10.845,
                10.849,
                10.854,
                10.858,
                10.863,
                10.867,
                10.872,
                10.876,
                10.881,
                10.885,
                10.89,
                10.894,
                10.899,
                10.903,
                10.908,
                10.912,
                10.917,
                10.921,
                10.926,
                10.93,
                10.935,
                10.939,
                10.944,
                10.948,
                10.953,
                10.957,
                10.962,
                10.966,
                10.971,
                10.975,
                10.98,
                10.984,
                10.989,
                10.993,
                10.998,
                11.002,
                11.007,
                11.011,
                11.016,
                11.02,
                11.024,
                11.029,
                11.034,
                11.038,
                11.042,
                11.047,
                11.051,
                11.056,
                11.06,
                11.065,
                11.069,
                11.074,
                11.078,
                11.083,
                11.087,
                11.091,
                11.096,
                11.1,
                11.105,
                11.109,
                11.114,
                11.118,
                11.123,
                11.127,
                11.131,
                11.136,
                11.14,
                11.145,
                11.149,
                11.154,
                11.158,
                11.163,
                11.167,
                11.171,
                11.176,
                11.18,
                11.185,
                11.189,
                11.194,
                11.198,
                11.203,
                11.207,
                11.211,
                11.216,
                11.22,
                11.225,
                11.229,
                11.233,
                11.238,
                11.242,
                11.246,
                11.251,
                11.255,
                11.26,
                11.264,
                11.269,
                11.273,
                11.277,
                11.282,
                11.286,
                11.291,
                11.295,
                11.299,
                11.304,
                11.308,
                11.313,
                11.317,
                11.321,
                11.326,
                11.33,
                11.334,
                11.339,
                11.343,
                11.348,
                11.352,
                11.356,
                11.361,
                11.365,
                11.369,
                11.374,
                11.378,
                11.382,
                11.387,
                11.391,
                11.396,
                11.4,
                11.404,
                11.409,
                11.413,
                11.417,
                11.422,
                11.426,
                11.43,
                11.435,
                11.439,
                11.443,
                11.448,
                11.452,
                11.456,
                11.461,
                11.465,
                11.469,
                11.474,
                11.478,
                11.482,
                11.487,
                11.491,
                11.495,
                11.5,
                11.504,
                11.508,
                11.513,
                11.517,
                11.521,
                11.525,
                11.53,
                11.534,
                11.538,
                11.543,
                11.547,
                11.551,
                11.556,
                11.56,
                11.564,
                11.569,
                11.573,
                11.577,
                11.581,
                11.586,
                11.59,
                11.594,
                11.599,
                11.603,
                11.607,
                11.611,
                11.616,
                11.62,
                11.624,
                11.629,
                11.633,
                11.637,
                11.641,
                11.646,
                11.65,
                11.654,
                11.659,
                11.663,
                11.667,
                11.671,
                11.676,
                11.68,
                11.684,
                11.688,
                11.693,
                11.697,
                11.701,
                11.705,
                11.709,
                11.714,
                11.718,
                11.722,
                11.726,
                11.731,
                11.735,
                11.739,
                11.743,
                11.748,
                11.752,
                11.756,
                11.76,
                11.765,
                11.769,
                11.773,
                11.777,
                11.782,
                11.786,
                11.79,
                11.794,
                11.798,
                11.803,
                11.807,
                11.811,
                11.815,
                11.819,
                11.824,
                11.828,
                11.832,
                11.836,
                11.84,
                11.845,
                11.849,
                11.853,
                11.857,
                11.861,
                11.865,
                11.87,
                11.874,
                11.878,
                11.882,
                11.886,
                11.891,
                11.895,
                11.899,
                11.903,
                11.908,
                11.912,
                11.916,
                11.92,
                11.924,
                11.928,
                11.932,
                11.937,
                11.941,
                11.945,
                11.949,
                11.953,
                11.957,
                11.962,
                11.966,
                11.97,
                11.974,
                11.978,
                11.982,
                11.987,
                11.991,
                11.995,
                11.999,
                12.003,
                12.007,
                12.012,
                12.016,
                12.02,
                12.024,
                12.028,
                12.032,
                12.036,
                12.04,
                12.045,
                12.049,
                12.053,
                12.057,
                12.061,
                12.065,
                12.069,
                12.074,
                12.078,
                12.082,
                12.086,
                12.09,
                12.094,
                12.098,
                12.103,
                12.107,
                12.111,
                12.115,
                12.119,
                12.123,
                12.127,
                12.131,
                12.136,
                12.14,
                12.144,
                12.148,
                12.152,
                12.156,
                12.16,
                12.164,
                12.168,
                12.172,
                12.177,
                12.181,
                12.185,
                12.189,
                12.193,
                12.197,
                12.201,
                12.205,
                12.209,
                12.213,
                12.218,
                12.222,
                12.226,
                12.23,
                12.234,
                12.238,
                12.242,
                12.246,
                12.25,
                12.254,
                12.259,
                12.263,
                12.267,
                12.271,
                12.275,
                12.279,
                12.283,
                12.287,
                12.291,
                12.296,
                12.3,
                12.304,
                12.308,
                12.312,
                12.316,
                12.32,
                12.324,
                12.328,
                12.332,
                12.336,
                12.34,
                12.344,
                12.348,
                12.353,
                12.357,
                12.361,
                12.365,
                12.369,
                12.373,
                12.377,
                12.381,
                12.385,
                12.389,
                12.393,
                12.397,
                12.401,
                12.405,
                12.41,
                12.414,
                12.418,
                12.422,
                12.426,
                12.43,
                12.434,
                12.438,
                12.442,
                12.446,
                12.45,
                12.454,
                12.458,
                12.463,
                12.467,
                12.471,
                12.475,
                12.479,
                12.483,
                12.487,
                12.491,
                12.495,
                12.499,
                12.503,
                12.507,
                12.511,
                12.515,
                12.519,
                12.523,
                12.528,
                12.532,
                12.536,
                12.54,
                12.544,
                12.548,
                12.552,
                12.556,
                12.56,
                12.564,
                12.568,
                12.572,
                12.576,
                12.58,
                12.584,
                12.588,
                12.593,
                12.597,
                12.601,
                12.605,
                12.609,
                12.613,
                12.617,
                12.621,
                12.625,
                12.629,
                12.633,
                12.637,
                12.641,
                12.645,
                12.649,
                12.653,
                12.657,
                12.661,
                12.665,
                12.669,
                12.673,
                12.677,
                12.681,
                12.686,
                12.69,
                12.694,
                12.698,
                12.702,
                12.706,
                12.71,
                12.714,
                12.718,
                12.722,
                12.726,
                12.73,
                12.734,
                12.738,
                12.742,
                12.746,
                12.75,
                12.754,
                12.758,
                12.762,
                12.767,
                12.77,
                12.774,
                12.778,
                12.783,
                12.787,
                12.791,
                12.795,
                12.799,
                12.803,
                12.807,
                12.811,
                12.815,
                12.819,
                12.823,
                12.827,
                12.831,
                12.835,
                12.839,
                12.843,
                12.847,
                12.851,
                12.855,
                12.859,
                12.863,
                12.867,
                12.871,
                12.875,
                12.879,
                12.884,
                12.888,
                12.892,
                12.896,
                12.9,
                12.904,
                12.908,
                12.912,
                12.916,
                12.92,
                12.924,
                12.928,
                12.932,
                12.936,
                12.94,
                12.944,
                12.948,
                12.952,
                12.956,
                12.96,
                12.964,
                12.968,
                12.972,
                12.976,
                12.98,
                12.984,
                12.988,
                12.992,
                12.996,
                13,
                13.004,
                13.009,
                13.013,
                13.016,
                13.021,
                13.025,
                13.029,
                13.033,
                13.037,
                13.041,
                13.045,
                13.049,
                13.053,
                13.057,
                13.061,
                13.065,
                13.069,
                13.073,
                13.077,
                13.081,
                13.085,
                13.089,
                13.093,
                13.097,
                13.101,
                13.105,
                13.109,
                13.113,
                13.117,
                13.121,
                13.125,
                13.129,
                13.133,
                13.137,
                13.141,
                13.145,
                13.149,
                13.153,
                13.157,
                13.161,
                13.165,
                13.169,
                13.173,
                13.177,
                13.181,
                13.185,
                13.189,
                13.193,
                13.197,
                13.201,
                13.205,
                13.209,
                13.213,
                13.217,
                13.221,
                13.225,
                13.229,
                13.233,
                13.237,
                13.241,
                13.246,
                13.249,
                13.253,
                13.257,
                13.261,
                13.265,
                13.269,
                13.273,
                13.277,
                13.282,
                13.285,
                13.289,
                13.293,
                13.297,
                13.301,
                13.305,
                13.309,
                13.313,
                13.317,
                13.321,
                13.325,
                13.329,
                13.333,
                13.337,
                13.341,
                13.345,
                13.349,
                13.353,
                13.357,
                13.361,
                13.365,
                13.369,
                13.373,
                13.377,
                13.381,
                13.385,
                13.389,
                13.393,
                13.397,
                13.401,
                13.405,
                13.409,
                13.413,
                13.417,
                13.421,
                13.425,
                13.429,
                13.433,
                13.437,
                13.441,
                13.445,
                13.449,
                13.453,
                13.456,
                13.461,
                13.464,
                13.469,
                13.472,
                13.476,
                13.48,
                13.484,
                13.488,
                13.492,
                13.496,
                13.5,
                13.504,
                13.508,
                13.512,
                13.516,
                13.52,
                13.524,
                13.528,
                13.532,
                13.536,
                13.54,
                13.544,
                13.548,
                13.552,
                13.555,
                13.559,
                13.563,
                13.567,
                13.571,
                13.575,
                13.579,
                13.583,
                13.587,
                13.591,
                13.595,
                13.599,
                13.603,
                13.607,
                13.611,
                13.614,
                13.618,
                13.622,
                13.626,
                13.63,
                13.634,
                13.638,
                13.642,
                13.646,
                13.65,
                13.654,
                13.658,
                13.662,
                13.665,
                13.669,
                13.673,
                13.677,
                13.681,
                13.685,
                13.689,
                13.693,
                13.697,
                13.701,
                13.705,
                13.708,
                13.712,
                13.716,
                13.72,
                13.724,
                13.728,
                13.732,
                13.736,
                13.74,
                13.744,
                13.748,
                13.751,
                13.755,
                13.759,
                13.763,
                13.767,
                13.771,
                13.775,
                13.779,
                13.783,
                13.787,
                13.79,
                13.794,
                13.798,
                13.802,
                13.806,
                13.81,
                13.814,
                13.817,
                13.821,
                13.825,
                13.829,
                13.833,
                13.837,
                13.841,
                13.844,
                13.848,
                13.852,
                13.856,
                13.86,
                13.864,
                13.868,
                13.872,
                13.875,
                13.879,
                13.883,
                13.887,
                13.891,
                13.895,
                13.899,
                13.902,
                13.906,
                13.91,
                13.914,
                13.918,
                13.922,
                13.926,
                13.93,
                13.933,
                13.937,
                13.941,
                13.945,
                13.949,
                13.953,
                13.956,
                13.96,
                13.964,
                13.968,
                13.972,
                13.976,
                13.98,
                13.983,
                13.987,
                13.991,
                13.995,
                13.999,
                14.003,
                14.006,
                14.01,
                14.014,
                14.018,
                14.022,
                14.025,
                14.029,
                14.033,
                14.037,
                14.041,
                14.044,
                14.048,
                14.052,
                14.056,
                14.06,
                14.064,
                14.067,
                14.071,
                14.075,
                14.079

            ],
            P5: [
                2.532,
                2.49,
                2.5,
                2.516,
                2.536,
                2.558,
                2.581,
                2.605,
                2.63,
                2.656,
                2.683,
                2.711,
                2.74,
                2.77,
                2.801,
                2.832,
                2.864,
                2.896,
                2.929,
                2.961,
                2.994,
                3.026,
                3.059,
                3.091,
                3.124,
                3.156,
                3.188,
                3.219,
                3.251,
                3.282,
                3.313,
                3.343,
                3.374,
                3.404,
                3.434,
                3.463,
                3.492,
                3.521,
                3.55,
                3.578,
                3.606,
                3.634,
                3.661,
                3.688,
                3.715,
                3.742,
                3.768,
                3.795,
                3.82,
                3.846,
                3.871,
                3.896,
                3.921,
                3.946,
                3.97,
                3.994,
                4.018,
                4.042,
                4.065,
                4.089,
                4.112,
                4.135,
                4.157,
                4.18,
                4.202,
                4.224,
                4.246,
                4.268,
                4.289,
                4.311,
                4.332,
                4.353,
                4.374,
                4.394,
                4.415,
                4.435,
                4.455,
                4.475,
                4.495,
                4.515,
                4.535,
                4.554,
                4.573,
                4.592,
                4.611,
                4.63,
                4.649,
                4.667,
                4.686,
                4.704,
                4.722,
                4.74,
                4.758,
                4.776,
                4.794,
                4.811,
                4.829,
                4.846,
                4.863,
                4.88,
                4.897,
                4.914,
                4.931,
                4.947,
                4.964,
                4.98,
                4.996,
                5.013,
                5.029,
                5.045,
                5.061,
                5.076,
                5.092,
                5.107,
                5.123,
                5.138,
                5.153,
                5.168,
                5.183,
                5.198,
                5.213,
                5.228,
                5.242,
                5.257,
                5.271,
                5.286,
                5.3,
                5.314,
                5.328,
                5.342,
                5.356,
                5.37,
                5.384,
                5.397,
                5.411,
                5.424,
                5.438,
                5.451,
                5.464,
                5.477,
                5.49,
                5.503,
                5.516,
                5.529,
                5.541,
                5.554,
                5.567,
                5.579,
                5.591,
                5.604,
                5.616,
                5.628,
                5.64,
                5.652,
                5.664,
                5.676,
                5.688,
                5.699,
                5.711,
                5.723,
                5.734,
                5.746,
                5.757,
                5.768,
                5.78,
                5.791,
                5.802,
                5.813,
                5.824,
                5.835,
                5.846,
                5.857,
                5.868,
                5.878,
                5.889,
                5.9,
                5.91,
                5.921,
                5.931,
                5.942,
                5.952,
                5.962,
                5.972,
                5.983,
                5.993,
                6.003,
                6.013,
                6.023,
                6.033,
                6.043,
                6.053,
                6.062,
                6.072,
                6.082,
                6.092,
                6.101,
                6.111,
                6.12,
                6.13,
                6.139,
                6.149,
                6.158,
                6.167,
                6.177,
                6.186,
                6.195,
                6.204,
                6.213,
                6.222,
                6.231,
                6.241,
                6.249,
                6.258,
                6.267,
                6.276,
                6.285,
                6.294,
                6.302,
                6.311,
                6.32,
                6.328,
                6.337,
                6.346,
                6.354,
                6.363,
                6.371,
                6.38,
                6.388,
                6.396,
                6.405,
                6.413,
                6.421,
                6.43,
                6.438,
                6.446,
                6.454,
                6.462,
                6.47,
                6.478,
                6.486,
                6.494,
                6.502,
                6.51,
                6.518,
                6.526,
                6.534,
                6.542,
                6.55,
                6.557,
                6.565,
                6.573,
                6.58,
                6.588,
                6.596,
                6.603,
                6.611,
                6.618,
                6.626,
                6.633,
                6.641,
                6.648,
                6.656,
                6.663,
                6.671,
                6.678,
                6.685,
                6.693,
                6.7,
                6.707,
                6.715,
                6.722,
                6.729,
                6.736,
                6.743,
                6.75,
                6.758,
                6.765,
                6.772,
                6.779,
                6.786,
                6.793,
                6.8,
                6.807,
                6.814,
                6.821,
                6.828,
                6.835,
                6.842,
                6.849,
                6.855,
                6.862,
                6.869,
                6.876,
                6.883,
                6.889,
                6.896,
                6.903,
                6.91,
                6.916,
                6.923,
                6.93,
                6.936,
                6.943,
                6.95,
                6.956,
                6.963,
                6.97,
                6.976,
                6.983,
                6.989,
                6.996,
                7.003,
                7.009,
                7.016,
                7.022,
                7.029,
                7.035,
                7.041,
                7.048,
                7.054,
                7.061,
                7.067,
                7.074,
                7.08,
                7.086,
                7.093,
                7.099,
                7.106,
                7.112,
                7.118,
                7.125,
                7.131,
                7.137,
                7.143,
                7.15,
                7.156,
                7.162,
                7.169,
                7.175,
                7.181,
                7.187,
                7.193,
                7.2,
                7.206,
                7.212,
                7.218,
                7.225,
                7.231,
                7.237,
                7.243,
                7.249,
                7.255,
                7.262,
                7.268,
                7.274,
                7.28,
                7.286,
                7.292,
                7.298,
                7.304,
                7.311,
                7.317,
                7.323,
                7.329,
                7.335,
                7.341,
                7.347,
                7.353,
                7.359,
                7.365,
                7.371,
                7.377,
                7.383,
                7.389,
                7.395,
                7.401,
                7.407,
                7.413,
                7.419,
                7.425,
                7.431,
                7.437,
                7.443,
                7.449,
                7.455,
                7.461,
                7.467,
                7.473,
                7.479,
                7.485,
                7.491,
                7.497,
                7.503,
                7.509,
                7.515,
                7.52,
                7.526,
                7.532,
                7.538,
                7.544,
                7.55,
                7.556,
                7.562,
                7.568,
                7.574,
                7.58,
                7.585,
                7.591,
                7.597,
                7.603,
                7.609,
                7.615,
                7.621,
                7.626,
                7.632,
                7.638,
                7.644,
                7.65,
                7.656,
                7.662,
                7.667,
                7.673,
                7.679,
                7.685,
                7.691,
                7.697,
                7.702,
                7.708,
                7.714,
                7.72,
                7.726,
                7.732,
                7.737,
                7.743,
                7.749,
                7.755,
                7.761,
                7.766,
                7.772,
                7.778,
                7.784,
                7.79,
                7.795,
                7.801,
                7.807,
                7.813,
                7.819,
                7.824,
                7.83,
                7.836,
                7.842,
                7.847,
                7.853,
                7.859,
                7.865,
                7.87,
                7.876,
                7.882,
                7.888,
                7.894,
                7.899,
                7.905,
                7.911,
                7.917,
                7.922,
                7.928,
                7.934,
                7.94,
                7.945,
                7.951,
                7.957,
                7.963,
                7.968,
                7.974,
                7.98,
                7.985,
                7.991,
                7.997,
                8.003,
                8.008,
                8.014,
                8.02,
                8.026,
                8.031,
                8.037,
                8.043,
                8.048,
                8.054,
                8.06,
                8.066,
                8.071,
                8.077,
                8.083,
                8.089,
                8.094,
                8.1,
                8.106,
                8.111,
                8.117,
                8.123,
                8.128,
                8.134,
                8.14,
                8.146,
                8.151,
                8.157,
                8.163,
                8.168,
                8.174,
                8.18,
                8.185,
                8.191,
                8.197,
                8.202,
                8.208,
                8.214,
                8.22,
                8.225,
                8.231,
                8.237,
                8.242,
                8.248,
                8.254,
                8.259,
                8.265,
                8.271,
                8.276,
                8.282,
                8.288,
                8.293,
                8.299,
                8.305,
                8.31,
                8.316,
                8.322,
                8.327,
                8.333,
                8.339,
                8.344,
                8.35,
                8.356,
                8.361,
                8.367,
                8.372,
                8.378,
                8.384,
                8.389,
                8.395,
                8.401,
                8.406,
                8.412,
                8.418,
                8.423,
                8.429,
                8.434,
                8.44,
                8.446,
                8.451,
                8.457,
                8.463,
                8.468,
                8.474,
                8.479,
                8.485,
                8.491,
                8.496,
                8.502,
                8.507,
                8.513,
                8.519,
                8.524,
                8.53,
                8.535,
                8.541,
                8.547,
                8.552,
                8.558,
                8.563,
                8.569,
                8.575,
                8.58,
                8.586,
                8.592,
                8.597,
                8.603,
                8.608,
                8.614,
                8.619,
                8.625,
                8.631,
                8.636,
                8.642,
                8.647,
                8.653,
                8.658,
                8.664,
                8.67,
                8.675,
                8.681,
                8.686,
                8.692,
                8.698,
                8.703,
                8.709,
                8.714,
                8.72,
                8.725,
                8.731,
                8.737,
                8.742,
                8.748,
                8.753,
                8.759,
                8.764,
                8.77,
                8.775,
                8.781,
                8.787,
                8.792,
                8.798,
                8.803,
                8.809,
                8.814,
                8.82,
                8.826,
                8.831,
                8.837,
                8.842,
                8.848,
                8.853,
                8.859,
                8.864,
                8.87,
                8.876,
                8.881,
                8.887,
                8.892,
                8.898,
                8.903,
                8.909,
                8.914,
                8.92,
                8.926,
                8.931,
                8.937,
                8.942,
                8.948,
                8.953,
                8.959,
                8.964,
                8.97,
                8.975,
                8.981,
                8.987,
                8.992,
                8.998,
                9.003,
                9.009,
                9.014,
                9.02,
                9.025,
                9.031,
                9.037,
                9.042,
                9.048,
                9.053,
                9.059,
                9.064,
                9.07,
                9.075,
                9.081,
                9.087,
                9.092,
                9.098,
                9.103,
                9.109,
                9.114,
                9.12,
                9.125,
                9.131,
                9.136,
                9.142,
                9.147,
                9.153,
                9.158,
                9.164,
                9.17,
                9.175,
                9.181,
                9.186,
                9.192,
                9.197,
                9.203,
                9.209,
                9.214,
                9.22,
                9.225,
                9.231,
                9.236,
                9.242,
                9.247,
                9.253,
                9.258,
                9.264,
                9.27,
                9.275,
                9.281,
                9.286,
                9.292,
                9.297,
                9.303,
                9.308,
                9.314,
                9.32,
                9.325,
                9.331,
                9.336,
                9.342,
                9.347,
                9.353,
                9.358,
                9.364,
                9.369,
                9.375,
                9.381,
                9.386,
                9.392,
                9.397,
                9.403,
                9.408,
                9.414,
                9.419,
                9.425,
                9.43,
                9.436,
                9.442,
                9.447,
                9.453,
                9.458,
                9.464,
                9.469,
                9.475,
                9.48,
                9.486,
                9.491,
                9.497,
                9.502,
                9.508,
                9.513,
                9.519,
                9.524,
                9.53,
                9.536,
                9.541,
                9.547,
                9.552,
                9.558,
                9.563,
                9.569,
                9.574,
                9.58,
                9.585,
                9.591,
                9.596,
                9.602,
                9.607,
                9.613,
                9.618,
                9.624,
                9.629,
                9.635,
                9.64,
                9.646,
                9.651,
                9.657,
                9.662,
                9.668,
                9.673,
                9.679,
                9.685,
                9.69,
                9.695,
                9.701,
                9.706,
                9.712,
                9.717,
                9.723,
                9.728,
                9.734,
                9.739,
                9.745,
                9.75,
                9.756,
                9.761,
                9.767,
                9.772,
                9.778,
                9.783,
                9.789,
                9.794,
                9.799,
                9.805,
                9.81,
                9.816,
                9.821,
                9.827,
                9.832,
                9.838,
                9.843,
                9.848,
                9.854,
                9.859,
                9.865,
                9.87,
                9.876,
                9.881,
                9.886,
                9.892,
                9.897,
                9.902,
                9.908,
                9.913,
                9.919,
                9.924,
                9.929,
                9.935,
                9.94,
                9.946,
                9.951,
                9.956,
                9.962,
                9.967,
                9.972,
                9.978,
                9.983,
                9.989,
                9.994,
                9.999,
                10.004,
                10.01,
                10.015,
                10.021,
                10.026,
                10.031,
                10.036,
                10.042,
                10.047,
                10.052,
                10.058,
                10.063,
                10.068,
                10.074,
                10.079,
                10.084,
                10.089,
                10.095,
                10.1,
                10.105,
                10.111,
                10.116,
                10.121,
                10.126,
                10.132,
                10.137,
                10.142,
                10.147,
                10.153,
                10.158,
                10.163,
                10.168,
                10.174,
                10.179,
                10.184,
                10.189,
                10.194,
                10.2,
                10.205,
                10.21,
                10.215,
                10.22,
                10.226,
                10.231,
                10.236,
                10.241,
                10.246,
                10.252,
                10.257,
                10.262,
                10.267,
                10.272,
                10.277,
                10.282,
                10.288,
                10.293,
                10.298,
                10.303,
                10.308,
                10.313,
                10.318,
                10.324,
                10.329,
                10.334,
                10.339,
                10.344,
                10.349,
                10.354,
                10.359,
                10.364,
                10.369,
                10.375,
                10.38,
                10.385,
                10.39,
                10.395,
                10.4,
                10.405,
                10.41,
                10.415,
                10.42,
                10.425,
                10.43,
                10.435,
                10.44,
                10.445,
                10.45,
                10.455,
                10.46,
                10.465,
                10.47,
                10.475,
                10.48,
                10.485,
                10.49,
                10.495,
                10.5,
                10.505,
                10.51,
                10.515,
                10.52,
                10.525,
                10.53,
                10.535,
                10.54,
                10.545,
                10.55,
                10.555,
                10.56,
                10.565,
                10.57,
                10.575,
                10.58,
                10.585,
                10.59,
                10.595,
                10.599,
                10.604,
                10.609,
                10.614,
                10.619,
                10.624,
                10.629,
                10.634,
                10.639,
                10.644,
                10.649,
                10.653,
                10.658,
                10.663,
                10.668,
                10.673,
                10.678,
                10.683,
                10.688,
                10.693,
                10.697,
                10.702,
                10.707,
                10.712,
                10.717,
                10.722,
                10.727,
                10.731,
                10.736,
                10.741,
                10.746,
                10.751,
                10.756,
                10.76,
                10.765,
                10.77,
                10.775,
                10.78,
                10.785,
                10.79,
                10.794,
                10.799,
                10.804,
                10.809,
                10.814,
                10.818,
                10.823,
                10.828,
                10.833,
                10.838,
                10.842,
                10.847,
                10.852,
                10.857,
                10.862,
                10.866,
                10.871,
                10.876,
                10.881,
                10.886,
                10.89,
                10.895,
                10.9,
                10.905,
                10.91,
                10.914,
                10.919,
                10.924,
                10.929,
                10.933,
                10.938,
                10.943,
                10.948,
                10.952,
                10.957,
                10.962,
                10.967,
                10.972,
                10.976,
                10.981,
                10.986,
                10.991,
                10.995,
                11,
                11.005,
                11.009,
                11.014,
                11.019,
                11.024,
                11.028,
                11.033,
                11.038,
                11.043,
                11.047,
                11.052,
                11.057,
                11.062,
                11.066,
                11.071,
                11.076,
                11.08,
                11.085,
                11.09,
                11.095,
                11.099,
                11.104,
                11.109,
                11.113,
                11.118,
                11.123,
                11.128,
                11.132,
                11.137,
                11.142,
                11.146,
                11.151,
                11.156,
                11.161,
                11.165,
                11.17,
                11.175,
                11.179,
                11.184,
                11.189,
                11.193,
                11.198,
                11.203,
                11.208,
                11.212,
                11.217,
                11.222,
                11.226,
                11.231,
                11.236,
                11.24,
                11.245,
                11.25,
                11.254,
                11.259,
                11.264,
                11.268,
                11.273,
                11.278,
                11.282,
                11.287,
                11.292,
                11.296,
                11.301,
                11.306,
                11.31,
                11.315,
                11.32,
                11.324,
                11.329,
                11.334,
                11.338,
                11.343,
                11.348,
                11.352,
                11.357,
                11.361,
                11.366,
                11.371,
                11.375,
                11.38,
                11.385,
                11.389,
                11.394,
                11.399,
                11.403,
                11.408,
                11.413,
                11.417,
                11.422,
                11.426,
                11.431,
                11.436,
                11.44,
                11.445,
                11.45,
                11.454,
                11.459,
                11.463,
                11.468,
                11.473,
                11.477,
                11.482,
                11.487,
                11.491,
                11.496,
                11.5,
                11.505,
                11.51,
                11.514,
                11.519,
                11.523,
                11.528,
                11.533,
                11.537,
                11.542,
                11.546,
                11.551,
                11.556,
                11.56,
                11.565,
                11.569,
                11.574,
                11.579,
                11.583,
                11.588,
                11.592,
                11.597,
                11.602,
                11.606,
                11.611,
                11.615,
                11.62,
                11.624,
                11.629,
                11.634,
                11.638,
                11.643,
                11.647,
                11.652,
                11.656,
                11.661,
                11.665,
                11.67,
                11.675,
                11.679,
                11.684,
                11.688,
                11.693,
                11.697,
                11.702,
                11.706,
                11.711,
                11.715,
                11.72,
                11.725,
                11.729,
                11.734,
                11.738,
                11.743,
                11.747,
                11.752,
                11.757,
                11.761,
                11.765,
                11.77,
                11.774,
                11.779,
                11.784,
                11.788,
                11.793,
                11.797,
                11.802,
                11.806,
                11.811,
                11.815,
                11.82,
                11.824,
                11.829,
                11.833,
                11.838,
                11.842,
                11.847,
                11.851,
                11.856,
                11.86,
                11.865,
                11.869,
                11.874,
                11.878,
                11.883,
                11.887,
                11.892,
                11.896,
                11.901,
                11.905,
                11.91,
                11.914,
                11.919,
                11.923,
                11.928,
                11.932,
                11.937,
                11.941,
                11.945,
                11.95,
                11.954,
                11.959,
                11.963,
                11.968,
                11.972,
                11.977,
                11.981,
                11.986,
                11.99,
                11.995,
                11.999,
                12.004,
                12.008,
                12.012,
                12.017,
                12.021,
                12.026,
                12.03,
                12.035,
                12.039,
                12.044,
                12.048,
                12.052,
                12.057,
                12.061,
                12.066,
                12.07,
                12.075,
                12.079,
                12.083,
                12.088,
                12.092,
                12.097,
                12.101,
                12.105,
                12.11,
                12.114,
                12.119,
                12.123,
                12.128,
                12.132,
                12.136,
                12.141,
                12.145,
                12.15,
                12.154,
                12.158,
                12.163,
                12.167,
                12.172,
                12.176,
                12.18,
                12.185,
                12.189,
                12.194,
                12.198,
                12.202,
                12.207,
                12.211,
                12.216,
                12.22,
                12.224,
                12.229,
                12.233,
                12.237,
                12.242,
                12.246,
                12.251,
                12.255,
                12.259,
                12.264,
                12.268,
                12.272,
                12.277,
                12.281,
                12.286,
                12.29,
                12.294,
                12.299,
                12.303,
                12.307,
                12.312,
                12.316,
                12.32,
                12.325,
                12.329,
                12.333,
                12.338,
                12.342,
                12.347,
                12.351,
                12.355,
                12.359,
                12.364,
                12.368,
                12.373,
                12.377,
                12.381,
                12.386,
                12.39,
                12.394,
                12.399,
                12.403,
                12.407,
                12.412,
                12.416,
                12.42,
                12.424,
                12.429,
                12.433,
                12.438,
                12.442,
                12.446,
                12.45,
                12.455,
                12.459,
                12.463,
                12.468,
                12.472,
                12.476,
                12.481,
                12.485,
                12.489,
                12.494,
                12.498,
                12.502,
                12.507,
                12.511,
                12.515,
                12.519,
                12.524,
                12.528,
                12.532,
                12.537,
                12.541,
                12.545,
                12.55,
                12.554,
                12.558,
                12.562,
                12.567,
                12.571,
                12.575,
                12.58,
                12.584,
                12.588,
                12.593,
                12.597,
                12.601,
                12.605,
                12.61,
                12.614,
                12.618,
                12.623,
                12.627,
                12.631,
                12.635,
                12.64,
                12.644,
                12.648,
                12.653,
                12.657,
                12.661,
                12.665,
                12.67,
                12.674,
                12.678,
                12.683,
                12.687,
                12.691,
                12.695,
                12.7,
                12.704,
                12.708,
                12.713,
                12.717,
                12.721,
                12.725,
                12.73,
                12.734,
                12.738,
                12.742,
                12.747,
                12.751,
                12.755,
                12.759,
                12.764,
                12.768,
                12.772,
                12.777,
                12.781,
                12.785,
                12.789,
                12.794,
                12.798,
                12.802,
                12.806,
                12.811,
                12.815,
                12.819,
                12.823,
                12.828,
                12.832,
                12.836,
                12.84,
                12.845,
                12.849,
                12.853,
                12.857,
                12.862,
                12.866,
                12.87,
                12.874,
                12.879,
                12.883,
                12.887,
                12.891,
                12.896,
                12.9,
                12.904,
                12.908,
                12.913,
                12.917,
                12.921,
                12.926,
                12.93,
                12.934,
                12.938,
                12.943,
                12.947,
                12.951,
                12.955,
                12.959,
                12.964,
                12.968,
                12.972,
                12.976,
                12.981,
                12.985,
                12.989,
                12.993,
                12.998,
                13.002,
                13.006,
                13.01,
                13.015,
                13.019,
                13.023,
                13.027,
                13.032,
                13.036,
                13.04,
                13.044,
                13.049,
                13.053,
                13.057,
                13.061,
                13.066,
                13.07,
                13.074,
                13.078,
                13.083,
                13.087,
                13.091,
                13.095,
                13.099,
                13.104,
                13.108,
                13.112,
                13.116,
                13.121,
                13.125,
                13.129,
                13.133,
                13.138,
                13.142,
                13.146,
                13.15,
                13.155,
                13.159,
                13.163,
                13.167,
                13.172,
                13.176,
                13.18,
                13.184,
                13.188,
                13.193,
                13.197,
                13.201,
                13.205,
                13.21,
                13.214,
                13.218,
                13.222,
                13.227,
                13.231,
                13.235,
                13.239,
                13.243,
                13.248,
                13.252,
                13.256,
                13.26,
                13.265,
                13.269,
                13.273,
                13.277,
                13.281,
                13.286,
                13.29,
                13.294,
                13.299,
                13.303,
                13.307,
                13.311,
                13.315,
                13.319,
                13.324,
                13.328,
                13.332,
                13.337,
                13.341,
                13.345,
                13.349,
                13.353,
                13.358,
                13.362,
                13.366,
                13.37,
                13.375,
                13.379,
                13.383,
                13.387,
                13.391,
                13.396,
                13.4,
                13.404,
                13.408,
                13.412,
                13.417,
                13.421,
                13.425,
                13.429,
                13.434,
                13.438,
                13.442,
                13.446,
                13.451,
                13.455,
                13.459,
                13.463,
                13.467,
                13.471,
                13.476,
                13.48,
                13.484,
                13.488,
                13.493,
                13.497,
                13.501,
                13.505,
                13.509,
                13.514,
                13.518,
                13.522,
                13.526,
                13.531,
                13.535,
                13.539,
                13.543,
                13.547,
                13.552,
                13.556,
                13.56,
                13.564,
                13.568,
                13.573,
                13.577,
                13.581,
                13.585,
                13.589,
                13.594,
                13.598,
                13.602,
                13.606,
                13.61,
                13.615,
                13.619,
                13.623,
                13.627,
                13.631,
                13.636,
                13.64,
                13.644,
                13.648,
                13.652,
                13.657,
                13.661,
                13.665,
                13.669,
                13.673,
                13.678,
                13.682,
                13.686,
                13.69,
                13.694,
                13.699,
                13.703,
                13.707,
                13.711,
                13.715,
                13.719,
                13.724,
                13.728,
                13.732,
                13.736,
                13.74,
                13.745,
                13.749,
                13.753,
                13.757,
                13.761,
                13.765,
                13.77,
                13.774,
                13.778,
                13.782,
                13.786,
                13.791,
                13.795,
                13.799,
                13.803,
                13.807,
                13.811,
                13.816,
                13.82,
                13.824,
                13.828,
                13.832,
                13.836,
                13.841,
                13.845,
                13.849,
                13.853,
                13.857,
                13.861,
                13.866,
                13.87,
                13.874,
                13.878,
                13.882,
                13.886,
                13.891,
                13.895,
                13.899,
                13.903,
                13.907,
                13.911,
                13.915,
                13.919,
                13.924,
                13.928,
                13.932,
                13.936,
                13.94,
                13.944,
                13.949,
                13.953,
                13.957,
                13.961,
                13.965,
                13.969,
                13.973,
                13.978,
                13.982,
                13.986,
                13.99,
                13.994,
                13.998,
                14.002,
                14.006,
                14.011,
                14.015,
                14.019,
                14.023,
                14.027,
                14.031,
                14.035,
                14.039,
                14.044,
                14.048,
                14.052,
                14.056,
                14.06,
                14.064,
                14.068,
                14.072,
                14.077,
                14.081,
                14.085,
                14.089,
                14.093,
                14.097,
                14.101,
                14.105,
                14.11,
                14.113,
                14.118,
                14.122,
                14.126,
                14.13,
                14.134,
                14.138,
                14.142,
                14.146,
                14.15,
                14.155,
                14.159,
                14.163,
                14.167,
                14.171,
                14.175,
                14.179,
                14.183,
                14.187,
                14.191,
                14.195,
                14.2,
                14.204,
                14.208,
                14.212,
                14.216,
                14.22,
                14.224,
                14.228,
                14.232,
                14.236,
                14.24,
                14.244,
                14.248,
                14.253,
                14.257,
                14.261,
                14.265,
                14.269,
                14.273,
                14.277,
                14.281,
                14.285,
                14.289,
                14.293,
                14.297,
                14.301,
                14.305,
                14.31,
                14.313,
                14.318,
                14.322,
                14.326,
                14.33,
                14.334,
                14.338,
                14.342,
                14.346,
                14.35,
                14.354,
                14.358,
                14.362,
                14.366,
                14.37,
                14.374,
                14.378,
                14.382,
                14.386,
                14.39,
                14.394,
                14.398,
                14.402,
                14.406,
                14.41,
                14.415,
                14.419,
                14.423,
                14.427,
                14.431,
                14.435,
                14.439,
                14.443,
                14.447,
                14.451,
                14.455,
                14.459,
                14.463,
                14.467,
                14.471,
                14.475,
                14.479,
                14.483,
                14.487,
                14.491,
                14.495,
                14.499,
                14.503,
                14.507,
                14.511,
                14.515,
                14.519,
                14.523,
                14.527,
                14.531,
                14.535,
                14.539

            ],
            P10: [
                2.678,
                2.635,
                2.646,
                2.663,
                2.684,
                2.707,
                2.731,
                2.756,
                2.782,
                2.809,
                2.837,
                2.866,
                2.896,
                2.927,
                2.958,
                2.991,
                3.024,
                3.057,
                3.091,
                3.124,
                3.158,
                3.192,
                3.226,
                3.259,
                3.293,
                3.326,
                3.359,
                3.392,
                3.424,
                3.457,
                3.489,
                3.52,
                3.552,
                3.583,
                3.614,
                3.644,
                3.674,
                3.704,
                3.734,
                3.763,
                3.792,
                3.821,
                3.849,
                3.877,
                3.905,
                3.933,
                3.96,
                3.987,
                4.014,
                4.04,
                4.066,
                4.092,
                4.118,
                4.143,
                4.168,
                4.193,
                4.218,
                4.243,
                4.267,
                4.291,
                4.315,
                4.338,
                4.362,
                4.385,
                4.408,
                4.431,
                4.453,
                4.476,
                4.498,
                4.52,
                4.542,
                4.564,
                4.585,
                4.607,
                4.628,
                4.649,
                4.67,
                4.691,
                4.711,
                4.731,
                4.752,
                4.772,
                4.792,
                4.812,
                4.831,
                4.851,
                4.87,
                4.889,
                4.908,
                4.927,
                4.946,
                4.965,
                4.983,
                5.001,
                5.02,
                5.038,
                5.056,
                5.074,
                5.092,
                5.109,
                5.127,
                5.144,
                5.161,
                5.179,
                5.196,
                5.213,
                5.229,
                5.246,
                5.263,
                5.279,
                5.296,
                5.312,
                5.328,
                5.344,
                5.36,
                5.376,
                5.392,
                5.407,
                5.423,
                5.438,
                5.453,
                5.469,
                5.484,
                5.499,
                5.514,
                5.529,
                5.543,
                5.558,
                5.573,
                5.587,
                5.601,
                5.616,
                5.63,
                5.644,
                5.658,
                5.672,
                5.686,
                5.699,
                5.713,
                5.727,
                5.74,
                5.753,
                5.767,
                5.78,
                5.793,
                5.806,
                5.819,
                5.832,
                5.845,
                5.858,
                5.87,
                5.883,
                5.895,
                5.908,
                5.92,
                5.932,
                5.945,
                5.957,
                5.969,
                5.981,
                5.993,
                6.005,
                6.016,
                6.028,
                6.04,
                6.051,
                6.063,
                6.074,
                6.086,
                6.097,
                6.109,
                6.12,
                6.131,
                6.142,
                6.153,
                6.164,
                6.175,
                6.186,
                6.197,
                6.208,
                6.218,
                6.229,
                6.24,
                6.25,
                6.261,
                6.271,
                6.282,
                6.292,
                6.302,
                6.313,
                6.323,
                6.333,
                6.343,
                6.353,
                6.363,
                6.373,
                6.383,
                6.393,
                6.403,
                6.413,
                6.422,
                6.432,
                6.442,
                6.451,
                6.461,
                6.471,
                6.48,
                6.49,
                6.499,
                6.508,
                6.518,
                6.527,
                6.536,
                6.546,
                6.555,
                6.564,
                6.573,
                6.582,
                6.591,
                6.6,
                6.609,
                6.618,
                6.627,
                6.636,
                6.645,
                6.653,
                6.662,
                6.671,
                6.68,
                6.688,
                6.697,
                6.706,
                6.714,
                6.723,
                6.731,
                6.74,
                6.748,
                6.757,
                6.765,
                6.773,
                6.782,
                6.79,
                6.798,
                6.806,
                6.814,
                6.823,
                6.831,
                6.839,
                6.847,
                6.855,
                6.863,
                6.871,
                6.879,
                6.887,
                6.895,
                6.903,
                6.911,
                6.919,
                6.926,
                6.934,
                6.942,
                6.95,
                6.957,
                6.965,
                6.973,
                6.98,
                6.988,
                6.996,
                7.003,
                7.011,
                7.018,
                7.026,
                7.033,
                7.041,
                7.048,
                7.056,
                7.063,
                7.07,
                7.078,
                7.085,
                7.092,
                7.1,
                7.107,
                7.114,
                7.122,
                7.129,
                7.136,
                7.143,
                7.15,
                7.157,
                7.165,
                7.172,
                7.179,
                7.186,
                7.193,
                7.2,
                7.207,
                7.214,
                7.221,
                7.228,
                7.235,
                7.242,
                7.249,
                7.256,
                7.263,
                7.27,
                7.277,
                7.283,
                7.29,
                7.297,
                7.304,
                7.311,
                7.318,
                7.324,
                7.331,
                7.338,
                7.345,
                7.351,
                7.358,
                7.365,
                7.372,
                7.378,
                7.385,
                7.392,
                7.398,
                7.405,
                7.412,
                7.418,
                7.425,
                7.431,
                7.438,
                7.445,
                7.451,
                7.458,
                7.464,
                7.471,
                7.477,
                7.484,
                7.49,
                7.497,
                7.503,
                7.51,
                7.516,
                7.523,
                7.529,
                7.536,
                7.542,
                7.549,
                7.555,
                7.562,
                7.568,
                7.574,
                7.581,
                7.587,
                7.594,
                7.6,
                7.606,
                7.613,
                7.619,
                7.625,
                7.632,
                7.638,
                7.644,
                7.651,
                7.657,
                7.663,
                7.67,
                7.676,
                7.682,
                7.689,
                7.695,
                7.701,
                7.708,
                7.714,
                7.72,
                7.726,
                7.733,
                7.739,
                7.745,
                7.751,
                7.758,
                7.764,
                7.77,
                7.776,
                7.782,
                7.789,
                7.795,
                7.801,
                7.807,
                7.814,
                7.82,
                7.826,
                7.832,
                7.838,
                7.844,
                7.851,
                7.857,
                7.863,
                7.869,
                7.875,
                7.881,
                7.888,
                7.894,
                7.9,
                7.906,
                7.912,
                7.918,
                7.924,
                7.93,
                7.937,
                7.943,
                7.949,
                7.955,
                7.961,
                7.967,
                7.973,
                7.979,
                7.985,
                7.992,
                7.998,
                8.004,
                8.01,
                8.016,
                8.022,
                8.028,
                8.034,
                8.04,
                8.046,
                8.052,
                8.058,
                8.064,
                8.07,
                8.076,
                8.083,
                8.089,
                8.095,
                8.101,
                8.107,
                8.113,
                8.119,
                8.125,
                8.131,
                8.137,
                8.143,
                8.149,
                8.155,
                8.161,
                8.167,
                8.173,
                8.179,
                8.185,
                8.191,
                8.197,
                8.203,
                8.209,
                8.215,
                8.221,
                8.227,
                8.233,
                8.239,
                8.245,
                8.251,
                8.257,
                8.263,
                8.269,
                8.275,
                8.281,
                8.287,
                8.293,
                8.299,
                8.305,
                8.311,
                8.317,
                8.323,
                8.329,
                8.335,
                8.341,
                8.347,
                8.353,
                8.359,
                8.365,
                8.371,
                8.377,
                8.382,
                8.388,
                8.394,
                8.4,
                8.406,
                8.412,
                8.418,
                8.424,
                8.43,
                8.436,
                8.442,
                8.448,
                8.454,
                8.46,
                8.466,
                8.472,
                8.478,
                8.484,
                8.49,
                8.495,
                8.501,
                8.507,
                8.513,
                8.519,
                8.525,
                8.531,
                8.537,
                8.543,
                8.549,
                8.555,
                8.561,
                8.567,
                8.573,
                8.578,
                8.584,
                8.59,
                8.596,
                8.602,
                8.608,
                8.614,
                8.62,
                8.626,
                8.632,
                8.638,
                8.643,
                8.649,
                8.655,
                8.661,
                8.667,
                8.673,
                8.679,
                8.685,
                8.691,
                8.696,
                8.702,
                8.708,
                8.714,
                8.72,
                8.726,
                8.732,
                8.738,
                8.744,
                8.749,
                8.755,
                8.761,
                8.767,
                8.773,
                8.779,
                8.785,
                8.79,
                8.796,
                8.802,
                8.808,
                8.814,
                8.82,
                8.826,
                8.832,
                8.837,
                8.843,
                8.849,
                8.855,
                8.861,
                8.867,
                8.872,
                8.878,
                8.884,
                8.89,
                8.896,
                8.902,
                8.908,
                8.913,
                8.919,
                8.925,
                8.931,
                8.937,
                8.943,
                8.948,
                8.954,
                8.96,
                8.966,
                8.972,
                8.978,
                8.983,
                8.989,
                8.995,
                9.001,
                9.007,
                9.013,
                9.018,
                9.024,
                9.03,
                9.036,
                9.042,
                9.047,
                9.053,
                9.059,
                9.065,
                9.071,
                9.077,
                9.082,
                9.088,
                9.094,
                9.1,
                9.106,
                9.111,
                9.117,
                9.123,
                9.129,
                9.135,
                9.14,
                9.146,
                9.152,
                9.158,
                9.164,
                9.169,
                9.175,
                9.181,
                9.187,
                9.193,
                9.198,
                9.204,
                9.21,
                9.216,
                9.222,
                9.227,
                9.233,
                9.239,
                9.245,
                9.251,
                9.256,
                9.262,
                9.268,
                9.274,
                9.28,
                9.285,
                9.291,
                9.297,
                9.303,
                9.309,
                9.314,
                9.32,
                9.326,
                9.332,
                9.338,
                9.343,
                9.349,
                9.355,
                9.361,
                9.367,
                9.372,
                9.378,
                9.384,
                9.39,
                9.396,
                9.401,
                9.407,
                9.413,
                9.419,
                9.424,
                9.43,
                9.436,
                9.442,
                9.448,
                9.454,
                9.459,
                9.465,
                9.471,
                9.477,
                9.483,
                9.488,
                9.494,
                9.5,
                9.506,
                9.512,
                9.517,
                9.523,
                9.529,
                9.535,
                9.54,
                9.546,
                9.552,
                9.558,
                9.564,
                9.569,
                9.575,
                9.581,
                9.587,
                9.593,
                9.598,
                9.604,
                9.61,
                9.616,
                9.622,
                9.627,
                9.633,
                9.639,
                9.645,
                9.651,
                9.657,
                9.662,
                9.668,
                9.674,
                9.68,
                9.685,
                9.691,
                9.697,
                9.703,
                9.709,
                9.715,
                9.72,
                9.726,
                9.732,
                9.738,
                9.744,
                9.749,
                9.755,
                9.761,
                9.767,
                9.773,
                9.778,
                9.784,
                9.79,
                9.796,
                9.801,
                9.807,
                9.813,
                9.819,
                9.825,
                9.831,
                9.836,
                9.842,
                9.848,
                9.854,
                9.86,
                9.865,
                9.871,
                9.877,
                9.883,
                9.889,
                9.894,
                9.9,
                9.906,
                9.912,
                9.918,
                9.923,
                9.929,
                9.935,
                9.941,
                9.946,
                9.952,
                9.958,
                9.964,
                9.97,
                9.975,
                9.981,
                9.987,
                9.993,
                9.999,
                10.004,
                10.01,
                10.016,
                10.022,
                10.027,
                10.033,
                10.039,
                10.045,
                10.05,
                10.056,
                10.062,
                10.068,
                10.074,
                10.079,
                10.085,
                10.091,
                10.097,
                10.102,
                10.108,
                10.114,
                10.12,
                10.126,
                10.131,
                10.137,
                10.143,
                10.148,
                10.154,
                10.16,
                10.166,
                10.171,
                10.177,
                10.183,
                10.189,
                10.194,
                10.2,
                10.206,
                10.212,
                10.217,
                10.223,
                10.229,
                10.234,
                10.24,
                10.246,
                10.252,
                10.257,
                10.263,
                10.269,
                10.274,
                10.28,
                10.286,
                10.291,
                10.297,
                10.303,
                10.309,
                10.314,
                10.32,
                10.325,
                10.331,
                10.337,
                10.342,
                10.348,
                10.354,
                10.36,
                10.365,
                10.371,
                10.376,
                10.382,
                10.388,
                10.393,
                10.399,
                10.405,
                10.41,
                10.416,
                10.421,
                10.427,
                10.433,
                10.438,
                10.444,
                10.45,
                10.455,
                10.461,
                10.466,
                10.472,
                10.477,
                10.483,
                10.489,
                10.494,
                10.5,
                10.505,
                10.511,
                10.517,
                10.522,
                10.528,
                10.533,
                10.539,
                10.544,
                10.55,
                10.555,
                10.561,
                10.566,
                10.572,
                10.577,
                10.583,
                10.588,
                10.594,
                10.6,
                10.605,
                10.611,
                10.616,
                10.622,
                10.627,
                10.632,
                10.638,
                10.644,
                10.649,
                10.654,
                10.66,
                10.665,
                10.671,
                10.676,
                10.682,
                10.687,
                10.693,
                10.698,
                10.704,
                10.709,
                10.714,
                10.72,
                10.725,
                10.731,
                10.736,
                10.742,
                10.747,
                10.752,
                10.758,
                10.763,
                10.769,
                10.774,
                10.779,
                10.785,
                10.79,
                10.795,
                10.801,
                10.806,
                10.812,
                10.817,
                10.822,
                10.828,
                10.833,
                10.838,
                10.844,
                10.849,
                10.854,
                10.86,
                10.865,
                10.87,
                10.876,
                10.881,
                10.886,
                10.892,
                10.897,
                10.902,
                10.907,
                10.913,
                10.918,
                10.923,
                10.929,
                10.934,
                10.939,
                10.945,
                10.95,
                10.955,
                10.96,
                10.966,
                10.971,
                10.976,
                10.981,
                10.987,
                10.992,
                10.997,
                11.002,
                11.008,
                11.013,
                11.018,
                11.023,
                11.029,
                11.034,
                11.039,
                11.044,
                11.049,
                11.055,
                11.06,
                11.065,
                11.07,
                11.075,
                11.081,
                11.086,
                11.091,
                11.096,
                11.101,
                11.107,
                11.112,
                11.117,
                11.122,
                11.127,
                11.132,
                11.138,
                11.143,
                11.148,
                11.153,
                11.158,
                11.163,
                11.168,
                11.174,
                11.179,
                11.184,
                11.189,
                11.194,
                11.199,
                11.204,
                11.209,
                11.215,
                11.22,
                11.225,
                11.23,
                11.235,
                11.24,
                11.245,
                11.25,
                11.255,
                11.261,
                11.266,
                11.271,
                11.276,
                11.281,
                11.286,
                11.291,
                11.296,
                11.301,
                11.307,
                11.312,
                11.317,
                11.322,
                11.327,
                11.332,
                11.337,
                11.342,
                11.347,
                11.352,
                11.357,
                11.362,
                11.367,
                11.373,
                11.378,
                11.383,
                11.388,
                11.393,
                11.398,
                11.403,
                11.408,
                11.413,
                11.418,
                11.423,
                11.428,
                11.433,
                11.438,
                11.443,
                11.448,
                11.453,
                11.458,
                11.463,
                11.468,
                11.473,
                11.479,
                11.484,
                11.489,
                11.494,
                11.499,
                11.504,
                11.509,
                11.514,
                11.519,
                11.524,
                11.529,
                11.534,
                11.539,
                11.544,
                11.549,
                11.554,
                11.559,
                11.564,
                11.569,
                11.574,
                11.579,
                11.584,
                11.589,
                11.594,
                11.599,
                11.604,
                11.609,
                11.614,
                11.619,
                11.624,
                11.629,
                11.634,
                11.639,
                11.644,
                11.649,
                11.654,
                11.659,
                11.664,
                11.669,
                11.674,
                11.679,
                11.684,
                11.689,
                11.694,
                11.699,
                11.704,
                11.709,
                11.714,
                11.718,
                11.723,
                11.728,
                11.733,
                11.738,
                11.743,
                11.748,
                11.753,
                11.758,
                11.763,
                11.768,
                11.773,
                11.778,
                11.783,
                11.788,
                11.793,
                11.798,
                11.803,
                11.808,
                11.813,
                11.818,
                11.823,
                11.828,
                11.833,
                11.838,
                11.843,
                11.848,
                11.852,
                11.857,
                11.862,
                11.867,
                11.872,
                11.877,
                11.882,
                11.887,
                11.892,
                11.897,
                11.902,
                11.907,
                11.912,
                11.917,
                11.922,
                11.927,
                11.931,
                11.936,
                11.941,
                11.946,
                11.951,
                11.956,
                11.961,
                11.966,
                11.971,
                11.976,
                11.981,
                11.986,
                11.99,
                11.995,
                12,
                12.005,
                12.01,
                12.015,
                12.02,
                12.025,
                12.03,
                12.035,
                12.04,
                12.044,
                12.049,
                12.054,
                12.059,
                12.064,
                12.069,
                12.074,
                12.079,
                12.084,
                12.089,
                12.093,
                12.098,
                12.103,
                12.108,
                12.113,
                12.118,
                12.123,
                12.128,
                12.133,
                12.137,
                12.142,
                12.147,
                12.152,
                12.157,
                12.162,
                12.167,
                12.172,
                12.176,
                12.181,
                12.186,
                12.191,
                12.196,
                12.201,
                12.206,
                12.211,
                12.215,
                12.22,
                12.225,
                12.23,
                12.235,
                12.24,
                12.244,
                12.249,
                12.254,
                12.259,
                12.264,
                12.269,
                12.274,
                12.278,
                12.283,
                12.288,
                12.293,
                12.298,
                12.303,
                12.307,
                12.312,
                12.317,
                12.322,
                12.327,
                12.332,
                12.336,
                12.341,
                12.346,
                12.351,
                12.356,
                12.361,
                12.365,
                12.37,
                12.375,
                12.38,
                12.385,
                12.389,
                12.394,
                12.399,
                12.404,
                12.409,
                12.413,
                12.418,
                12.423,
                12.428,
                12.433,
                12.438,
                12.442,
                12.447,
                12.452,
                12.457,
                12.462,
                12.466,
                12.471,
                12.476,
                12.481,
                12.486,
                12.49,
                12.495,
                12.5,
                12.505,
                12.509,
                12.514,
                12.519,
                12.524,
                12.528,
                12.533,
                12.538,
                12.543,
                12.548,
                12.552,
                12.557,
                12.562,
                12.567,
                12.571,
                12.576,
                12.581,
                12.586,
                12.59,
                12.595,
                12.6,
                12.605,
                12.609,
                12.614,
                12.619,
                12.624,
                12.628,
                12.633,
                12.638,
                12.643,
                12.647,
                12.652,
                12.657,
                12.662,
                12.666,
                12.671,
                12.676,
                12.681,
                12.685,
                12.69,
                12.695,
                12.699,
                12.704,
                12.709,
                12.714,
                12.718,
                12.723,
                12.728,
                12.732,
                12.737,
                12.742,
                12.747,
                12.751,
                12.756,
                12.761,
                12.765,
                12.77,
                12.775,
                12.78,
                12.784,
                12.789,
                12.794,
                12.798,
                12.803,
                12.808,
                12.812,
                12.817,
                12.822,
                12.826,
                12.831,
                12.836,
                12.841,
                12.845,
                12.85,
                12.855,
                12.859,
                12.864,
                12.869,
                12.873,
                12.878,
                12.883,
                12.887,
                12.892,
                12.897,
                12.901,
                12.906,
                12.911,
                12.915,
                12.92,
                12.925,
                12.929,
                12.934,
                12.939,
                12.943,
                12.948,
                12.953,
                12.957,
                12.962,
                12.967,
                12.971,
                12.976,
                12.981,
                12.985,
                12.99,
                12.994,
                12.999,
                13.004,
                13.008,
                13.013,
                13.018,
                13.022,
                13.027,
                13.032,
                13.036,
                13.041,
                13.045,
                13.05,
                13.055,
                13.06,
                13.064,
                13.069,
                13.073,
                13.078,
                13.083,
                13.087,
                13.092,
                13.097,
                13.101,
                13.106,
                13.11,
                13.115,
                13.12,
                13.124,
                13.129,
                13.133,
                13.138,
                13.143,
                13.147,
                13.152,
                13.157,
                13.161,
                13.166,
                13.17,
                13.175,
                13.18,
                13.184,
                13.189,
                13.194,
                13.198,
                13.203,
                13.207,
                13.212,
                13.217,
                13.221,
                13.226,
                13.23,
                13.235,
                13.24,
                13.244,
                13.249,
                13.253,
                13.258,
                13.263,
                13.267,
                13.272,
                13.276,
                13.281,
                13.286,
                13.29,
                13.295,
                13.299,
                13.304,
                13.309,
                13.313,
                13.318,
                13.322,
                13.327,
                13.332,
                13.336,
                13.341,
                13.345,
                13.35,
                13.354,
                13.359,
                13.364,
                13.368,
                13.373,
                13.377,
                13.382,
                13.387,
                13.391,
                13.396,
                13.4,
                13.405,
                13.409,
                13.414,
                13.419,
                13.423,
                13.428,
                13.432,
                13.437,
                13.441,
                13.446,
                13.451,
                13.455,
                13.46,
                13.464,
                13.469,
                13.474,
                13.478,
                13.483,
                13.487,
                13.492,
                13.496,
                13.501,
                13.506,
                13.51,
                13.515,
                13.519,
                13.524,
                13.528,
                13.533,
                13.538,
                13.542,
                13.547,
                13.551,
                13.556,
                13.561,
                13.565,
                13.57,
                13.574,
                13.579,
                13.583,
                13.588,
                13.593,
                13.597,
                13.602,
                13.606,
                13.611,
                13.615,
                13.62,
                13.624,
                13.629,
                13.634,
                13.638,
                13.643,
                13.647,
                13.652,
                13.656,
                13.661,
                13.666,
                13.67,
                13.675,
                13.679,
                13.684,
                13.688,
                13.693,
                13.697,
                13.702,
                13.707,
                13.711,
                13.716,
                13.72,
                13.725,
                13.729,
                13.734,
                13.738,
                13.743,
                13.748,
                13.752,
                13.757,
                13.761,
                13.766,
                13.77,
                13.775,
                13.779,
                13.784,
                13.788,
                13.793,
                13.798,
                13.802,
                13.807,
                13.811,
                13.816,
                13.82,
                13.825,
                13.829,
                13.834,
                13.839,
                13.843,
                13.848,
                13.852,
                13.857,
                13.861,
                13.866,
                13.871,
                13.875,
                13.88,
                13.884,
                13.889,
                13.893,
                13.898,
                13.902,
                13.907,
                13.911,
                13.916,
                13.92,
                13.925,
                13.93,
                13.934,
                13.939,
                13.943,
                13.948,
                13.952,
                13.957,
                13.961,
                13.966,
                13.971,
                13.975,
                13.98,
                13.984,
                13.989,
                13.993,
                13.998,
                14.002,
                14.007,
                14.011,
                14.016,
                14.021,
                14.025,
                14.03,
                14.034,
                14.039,
                14.043,
                14.048,
                14.052,
                14.057,
                14.061,
                14.066,
                14.07,
                14.075,
                14.08,
                14.084,
                14.089,
                14.093,
                14.098,
                14.102,
                14.107,
                14.111,
                14.116,
                14.12,
                14.125,
                14.13,
                14.134,
                14.138,
                14.143,
                14.148,
                14.152,
                14.157,
                14.161,
                14.166,
                14.17,
                14.175,
                14.179,
                14.184,
                14.189,
                14.193,
                14.198,
                14.202,
                14.206,
                14.211,
                14.216,
                14.22,
                14.225,
                14.229,
                14.234,
                14.238,
                14.243,
                14.247,
                14.252,
                14.256,
                14.261,
                14.265,
                14.27,
                14.275,
                14.279,
                14.283,
                14.288,
                14.293,
                14.297,
                14.302,
                14.306,
                14.311,
                14.315,
                14.32,
                14.324,
                14.329,
                14.333,
                14.338,
                14.342,
                14.347,
                14.351,
                14.356,
                14.36,
                14.365,
                14.369,
                14.374,
                14.378,
                14.383,
                14.387,
                14.392,
                14.396,
                14.401,
                14.405,
                14.41,
                14.414,
                14.419,
                14.423,
                14.428,
                14.432,
                14.437,
                14.441,
                14.446,
                14.45,
                14.455,
                14.459,
                14.464,
                14.468,
                14.473,
                14.477,
                14.482,
                14.486,
                14.491,
                14.495,
                14.5,
                14.504,
                14.509,
                14.513,
                14.518,
                14.522,
                14.527,
                14.531,
                14.536,
                14.54,
                14.545,
                14.549,
                14.554,
                14.558,
                14.563,
                14.567,
                14.572,
                14.576,
                14.581,
                14.585,
                14.589,
                14.594,
                14.598,
                14.603,
                14.607,
                14.612,
                14.616,
                14.621,
                14.625,
                14.63,
                14.634,
                14.639,
                14.643,
                14.647,
                14.652,
                14.656,
                14.661,
                14.665,
                14.67,
                14.674,
                14.679,
                14.683,
                14.688,
                14.692,
                14.696,
                14.701,
                14.705,
                14.71,
                14.714,
                14.719,
                14.723,
                14.728,
                14.732,
                14.737,
                14.741,
                14.745,
                14.75,
                14.754,
                14.759,
                14.763,
                14.768,
                14.772,
                14.777,
                14.781,
                14.785,
                14.79,
                14.794,
                14.799,
                14.803,
                14.807,
                14.812,
                14.816,
                14.821,
                14.825,
                14.83,
                14.834,
                14.838,
                14.843,
                14.847,
                14.852,
                14.856,
                14.861,
                14.865,
                14.869,
                14.874,
                14.878,
                14.883,
                14.887,
                14.891,
                14.896,
                14.9,
                14.905,
                14.909,
                14.913,
                14.918,
                14.922,
                14.927,
                14.931,
                14.935,
                14.94,
                14.944,
                14.949,
                14.953,
                14.958,
                14.962,
                14.966,
                14.971,
                14.975,
                14.979,
                14.984,
                14.988,
                14.992,
                14.997,
                15.001,
                15.006,
                15.01,
                15.014,
                15.019,
                15.023,
                15.028,
                15.032,
                15.036,
                15.041,
                15.045,
                15.049,
                15.054,
                15.058,
                15.062,
                15.067,
                15.071,
                15.076,
                15.08,
                15.084,
                15.089,
                15.093,
                15.097,
                15.102,
                15.106,
                15.11,
                15.115,
                15.119,
                15.123,
                15.128,
                15.132,
                15.136,
                15.141,
                15.145,
                15.15,
                15.154,
                15.158,
                15.163,
                15.167,
                15.171,
                15.176,
                15.18,
                15.184,
                15.189,
                15.193,
                15.197,
                15.202,
                15.206,
                15.21,
                15.214,
                15.219,
                15.223,
                15.227,
                15.232,
                15.236,
                15.24,
                15.245,
                15.249,
                15.253,
                15.258,
                15.262,
                15.266,
                15.271,
                15.275,
                15.279,
                15.284,
                15.288

            ],
            P15: [
                2.779,
                2.737,
                2.748,
                2.766,
                2.787,
                2.81,
                2.835,
                2.861,
                2.888,
                2.915,
                2.944,
                2.974,
                3.004,
                3.036,
                3.068,
                3.102,
                3.135,
                3.17,
                3.204,
                3.239,
                3.273,
                3.308,
                3.342,
                3.377,
                3.411,
                3.445,
                3.479,
                3.512,
                3.546,
                3.579,
                3.612,
                3.644,
                3.676,
                3.708,
                3.739,
                3.771,
                3.802,
                3.832,
                3.862,
                3.892,
                3.922,
                3.951,
                3.98,
                4.009,
                4.038,
                4.066,
                4.094,
                4.121,
                4.149,
                4.176,
                4.203,
                4.229,
                4.255,
                4.281,
                4.307,
                4.333,
                4.358,
                4.383,
                4.408,
                4.432,
                4.457,
                4.481,
                4.505,
                4.529,
                4.552,
                4.575,
                4.599,
                4.622,
                4.644,
                4.667,
                4.689,
                4.712,
                4.734,
                4.755,
                4.777,
                4.799,
                4.82,
                4.841,
                4.862,
                4.883,
                4.904,
                4.924,
                4.945,
                4.965,
                4.985,
                5.005,
                5.025,
                5.044,
                5.064,
                5.083,
                5.102,
                5.121,
                5.14,
                5.159,
                5.178,
                5.197,
                5.215,
                5.233,
                5.251,
                5.27,
                5.287,
                5.305,
                5.323,
                5.34,
                5.358,
                5.375,
                5.393,
                5.41,
                5.427,
                5.444,
                5.46,
                5.477,
                5.493,
                5.51,
                5.526,
                5.542,
                5.558,
                5.575,
                5.59,
                5.606,
                5.622,
                5.637,
                5.653,
                5.668,
                5.684,
                5.699,
                5.714,
                5.729,
                5.744,
                5.759,
                5.773,
                5.788,
                5.802,
                5.817,
                5.831,
                5.845,
                5.86,
                5.874,
                5.888,
                5.902,
                5.915,
                5.929,
                5.943,
                5.956,
                5.97,
                5.983,
                5.996,
                6.009,
                6.023,
                6.036,
                6.049,
                6.061,
                6.074,
                6.087,
                6.1,
                6.112,
                6.125,
                6.137,
                6.15,
                6.162,
                6.174,
                6.186,
                6.198,
                6.21,
                6.222,
                6.234,
                6.246,
                6.258,
                6.269,
                6.281,
                6.293,
                6.304,
                6.316,
                6.327,
                6.338,
                6.35,
                6.361,
                6.372,
                6.383,
                6.394,
                6.405,
                6.416,
                6.427,
                6.438,
                6.449,
                6.459,
                6.47,
                6.481,
                6.491,
                6.502,
                6.512,
                6.523,
                6.533,
                6.544,
                6.554,
                6.564,
                6.574,
                6.584,
                6.595,
                6.605,
                6.615,
                6.625,
                6.635,
                6.644,
                6.654,
                6.664,
                6.674,
                6.683,
                6.693,
                6.703,
                6.712,
                6.722,
                6.731,
                6.741,
                6.75,
                6.76,
                6.769,
                6.778,
                6.788,
                6.797,
                6.806,
                6.815,
                6.825,
                6.834,
                6.843,
                6.852,
                6.861,
                6.87,
                6.879,
                6.888,
                6.896,
                6.905,
                6.914,
                6.923,
                6.932,
                6.94,
                6.949,
                6.958,
                6.966,
                6.975,
                6.983,
                6.992,
                7,
                7.009,
                7.017,
                7.026,
                7.034,
                7.042,
                7.051,
                7.059,
                7.067,
                7.075,
                7.084,
                7.092,
                7.1,
                7.108,
                7.116,
                7.124,
                7.132,
                7.14,
                7.148,
                7.156,
                7.164,
                7.172,
                7.18,
                7.188,
                7.196,
                7.203,
                7.211,
                7.219,
                7.227,
                7.235,
                7.242,
                7.25,
                7.258,
                7.265,
                7.273,
                7.28,
                7.288,
                7.296,
                7.303,
                7.311,
                7.318,
                7.325,
                7.333,
                7.34,
                7.348,
                7.355,
                7.363,
                7.37,
                7.377,
                7.385,
                7.392,
                7.399,
                7.407,
                7.414,
                7.421,
                7.428,
                7.435,
                7.443,
                7.45,
                7.457,
                7.464,
                7.471,
                7.478,
                7.485,
                7.493,
                7.5,
                7.507,
                7.514,
                7.521,
                7.528,
                7.535,
                7.542,
                7.549,
                7.556,
                7.563,
                7.57,
                7.577,
                7.583,
                7.59,
                7.597,
                7.604,
                7.611,
                7.618,
                7.625,
                7.631,
                7.638,
                7.645,
                7.652,
                7.659,
                7.665,
                7.672,
                7.679,
                7.686,
                7.693,
                7.699,
                7.706,
                7.713,
                7.719,
                7.726,
                7.733,
                7.739,
                7.746,
                7.753,
                7.759,
                7.766,
                7.773,
                7.779,
                7.786,
                7.792,
                7.799,
                7.806,
                7.812,
                7.819,
                7.825,
                7.832,
                7.839,
                7.845,
                7.852,
                7.858,
                7.865,
                7.871,
                7.878,
                7.884,
                7.891,
                7.897,
                7.904,
                7.91,
                7.917,
                7.923,
                7.93,
                7.936,
                7.943,
                7.949,
                7.956,
                7.962,
                7.968,
                7.975,
                7.981,
                7.988,
                7.994,
                8,
                8.007,
                8.013,
                8.02,
                8.026,
                8.032,
                8.039,
                8.045,
                8.052,
                8.058,
                8.064,
                8.071,
                8.077,
                8.083,
                8.09,
                8.096,
                8.102,
                8.109,
                8.115,
                8.121,
                8.128,
                8.134,
                8.14,
                8.147,
                8.153,
                8.159,
                8.165,
                8.172,
                8.178,
                8.184,
                8.191,
                8.197,
                8.203,
                8.209,
                8.216,
                8.222,
                8.228,
                8.234,
                8.241,
                8.247,
                8.253,
                8.26,
                8.266,
                8.272,
                8.278,
                8.284,
                8.291,
                8.297,
                8.303,
                8.309,
                8.316,
                8.322,
                8.328,
                8.334,
                8.341,
                8.347,
                8.353,
                8.359,
                8.365,
                8.372,
                8.378,
                8.384,
                8.39,
                8.396,
                8.403,
                8.409,
                8.415,
                8.421,
                8.427,
                8.433,
                8.44,
                8.446,
                8.452,
                8.458,
                8.464,
                8.47,
                8.477,
                8.483,
                8.489,
                8.495,
                8.501,
                8.507,
                8.514,
                8.52,
                8.526,
                8.532,
                8.538,
                8.544,
                8.55,
                8.557,
                8.563,
                8.569,
                8.575,
                8.581,
                8.587,
                8.593,
                8.6,
                8.606,
                8.612,
                8.618,
                8.624,
                8.63,
                8.636,
                8.642,
                8.649,
                8.655,
                8.661,
                8.667,
                8.673,
                8.679,
                8.685,
                8.691,
                8.698,
                8.704,
                8.71,
                8.716,
                8.722,
                8.728,
                8.734,
                8.74,
                8.746,
                8.753,
                8.759,
                8.765,
                8.771,
                8.777,
                8.783,
                8.789,
                8.795,
                8.801,
                8.807,
                8.813,
                8.82,
                8.826,
                8.832,
                8.838,
                8.844,
                8.85,
                8.856,
                8.862,
                8.868,
                8.874,
                8.88,
                8.886,
                8.893,
                8.899,
                8.905,
                8.911,
                8.917,
                8.923,
                8.929,
                8.935,
                8.941,
                8.947,
                8.953,
                8.959,
                8.965,
                8.971,
                8.977,
                8.983,
                8.989,
                8.996,
                9.002,
                9.008,
                9.014,
                9.02,
                9.026,
                9.032,
                9.038,
                9.044,
                9.05,
                9.056,
                9.062,
                9.068,
                9.074,
                9.08,
                9.086,
                9.092,
                9.098,
                9.104,
                9.11,
                9.116,
                9.122,
                9.128,
                9.134,
                9.14,
                9.146,
                9.152,
                9.158,
                9.164,
                9.17,
                9.176,
                9.182,
                9.188,
                9.194,
                9.2,
                9.206,
                9.212,
                9.218,
                9.224,
                9.23,
                9.236,
                9.242,
                9.248,
                9.254,
                9.26,
                9.266,
                9.272,
                9.278,
                9.284,
                9.29,
                9.296,
                9.302,
                9.308,
                9.314,
                9.32,
                9.326,
                9.332,
                9.338,
                9.344,
                9.35,
                9.356,
                9.362,
                9.368,
                9.374,
                9.38,
                9.386,
                9.392,
                9.398,
                9.404,
                9.41,
                9.416,
                9.422,
                9.428,
                9.434,
                9.44,
                9.446,
                9.452,
                9.458,
                9.464,
                9.47,
                9.476,
                9.482,
                9.488,
                9.494,
                9.5,
                9.506,
                9.512,
                9.518,
                9.523,
                9.529,
                9.535,
                9.541,
                9.547,
                9.553,
                9.559,
                9.565,
                9.571,
                9.577,
                9.583,
                9.589,
                9.595,
                9.601,
                9.607,
                9.613,
                9.619,
                9.625,
                9.631,
                9.637,
                9.643,
                9.649,
                9.655,
                9.661,
                9.667,
                9.673,
                9.679,
                9.685,
                9.691,
                9.697,
                9.703,
                9.709,
                9.715,
                9.721,
                9.726,
                9.732,
                9.738,
                9.744,
                9.75,
                9.756,
                9.762,
                9.768,
                9.774,
                9.78,
                9.786,
                9.792,
                9.798,
                9.804,
                9.81,
                9.816,
                9.822,
                9.828,
                9.834,
                9.84,
                9.846,
                9.852,
                9.858,
                9.864,
                9.87,
                9.876,
                9.882,
                9.888,
                9.894,
                9.9,
                9.906,
                9.912,
                9.918,
                9.924,
                9.93,
                9.936,
                9.942,
                9.948,
                9.954,
                9.96,
                9.966,
                9.972,
                9.978,
                9.984,
                9.99,
                9.996,
                10.002,
                10.007,
                10.014,
                10.019,
                10.025,
                10.031,
                10.037,
                10.043,
                10.049,
                10.055,
                10.061,
                10.067,
                10.073,
                10.079,
                10.085,
                10.091,
                10.097,
                10.103,
                10.109,
                10.115,
                10.121,
                10.127,
                10.133,
                10.139,
                10.145,
                10.151,
                10.157,
                10.163,
                10.169,
                10.175,
                10.181,
                10.187,
                10.193,
                10.199,
                10.205,
                10.211,
                10.217,
                10.223,
                10.229,
                10.235,
                10.241,
                10.247,
                10.253,
                10.259,
                10.265,
                10.271,
                10.277,
                10.283,
                10.289,
                10.295,
                10.301,
                10.307,
                10.313,
                10.319,
                10.324,
                10.33,
                10.336,
                10.342,
                10.348,
                10.354,
                10.36,
                10.366,
                10.372,
                10.378,
                10.384,
                10.39,
                10.396,
                10.402,
                10.408,
                10.414,
                10.42,
                10.426,
                10.432,
                10.437,
                10.443,
                10.449,
                10.455,
                10.461,
                10.467,
                10.473,
                10.479,
                10.485,
                10.491,
                10.497,
                10.503,
                10.509,
                10.515,
                10.52,
                10.526,
                10.532,
                10.538,
                10.544,
                10.55,
                10.556,
                10.562,
                10.568,
                10.574,
                10.579,
                10.585,
                10.591,
                10.597,
                10.603,
                10.609,
                10.615,
                10.62,
                10.626,
                10.632,
                10.638,
                10.644,
                10.65,
                10.656,
                10.662,
                10.667,
                10.673,
                10.679,
                10.685,
                10.691,
                10.697,
                10.702,
                10.708,
                10.714,
                10.72,
                10.726,
                10.731,
                10.737,
                10.743,
                10.749,
                10.755,
                10.76,
                10.766,
                10.772,
                10.778,
                10.784,
                10.789,
                10.795,
                10.801,
                10.807,
                10.812,
                10.818,
                10.824,
                10.83,
                10.835,
                10.841,
                10.847,
                10.853,
                10.858,
                10.864,
                10.87,
                10.876,
                10.881,
                10.887,
                10.893,
                10.899,
                10.904,
                10.91,
                10.916,
                10.921,
                10.927,
                10.933,
                10.938,
                10.944,
                10.95,
                10.955,
                10.961,
                10.967,
                10.972,
                10.978,
                10.984,
                10.989,
                10.995,
                11.001,
                11.006,
                11.012,
                11.018,
                11.023,
                11.029,
                11.035,
                11.04,
                11.046,
                11.051,
                11.057,
                11.063,
                11.068,
                11.074,
                11.08,
                11.085,
                11.091,
                11.096,
                11.102,
                11.107,
                11.113,
                11.119,
                11.124,
                11.13,
                11.135,
                11.141,
                11.146,
                11.152,
                11.157,
                11.163,
                11.169,
                11.174,
                11.18,
                11.185,
                11.191,
                11.196,
                11.202,
                11.207,
                11.213,
                11.218,
                11.224,
                11.229,
                11.235,
                11.24,
                11.246,
                11.251,
                11.257,
                11.262,
                11.267,
                11.273,
                11.278,
                11.284,
                11.289,
                11.295,
                11.3,
                11.306,
                11.311,
                11.317,
                11.322,
                11.327,
                11.333,
                11.338,
                11.344,
                11.349,
                11.355,
                11.36,
                11.365,
                11.371,
                11.376,
                11.382,
                11.387,
                11.392,
                11.398,
                11.403,
                11.409,
                11.414,
                11.419,
                11.425,
                11.43,
                11.436,
                11.441,
                11.446,
                11.452,
                11.457,
                11.462,
                11.468,
                11.473,
                11.479,
                11.484,
                11.489,
                11.495,
                11.5,
                11.505,
                11.511,
                11.516,
                11.521,
                11.527,
                11.532,
                11.537,
                11.543,
                11.548,
                11.553,
                11.559,
                11.564,
                11.569,
                11.575,
                11.58,
                11.585,
                11.59,
                11.596,
                11.601,
                11.606,
                11.612,
                11.617,
                11.622,
                11.628,
                11.633,
                11.638,
                11.643,
                11.649,
                11.654,
                11.659,
                11.665,
                11.67,
                11.675,
                11.68,
                11.686,
                11.691,
                11.696,
                11.701,
                11.707,
                11.712,
                11.717,
                11.723,
                11.728,
                11.733,
                11.738,
                11.744,
                11.749,
                11.754,
                11.759,
                11.765,
                11.77,
                11.775,
                11.78,
                11.785,
                11.791,
                11.796,
                11.801,
                11.807,
                11.812,
                11.817,
                11.822,
                11.827,
                11.833,
                11.838,
                11.843,
                11.848,
                11.854,
                11.859,
                11.864,
                11.869,
                11.874,
                11.88,
                11.885,
                11.89,
                11.895,
                11.901,
                11.906,
                11.911,
                11.916,
                11.921,
                11.927,
                11.932,
                11.937,
                11.942,
                11.947,
                11.953,
                11.958,
                11.963,
                11.968,
                11.973,
                11.979,
                11.984,
                11.989,
                11.994,
                11.999,
                12.005,
                12.01,
                12.015,
                12.02,
                12.025,
                12.031,
                12.036,
                12.041,
                12.046,
                12.051,
                12.057,
                12.062,
                12.067,
                12.072,
                12.077,
                12.082,
                12.088,
                12.093,
                12.098,
                12.103,
                12.108,
                12.113,
                12.119,
                12.124,
                12.129,
                12.134,
                12.139,
                12.144,
                12.15,
                12.155,
                12.16,
                12.165,
                12.17,
                12.176,
                12.181,
                12.186,
                12.191,
                12.196,
                12.201,
                12.207,
                12.212,
                12.217,
                12.222,
                12.227,
                12.232,
                12.237,
                12.243,
                12.248,
                12.253,
                12.258,
                12.263,
                12.268,
                12.274,
                12.279,
                12.284,
                12.289,
                12.294,
                12.299,
                12.304,
                12.309,
                12.315,
                12.32,
                12.325,
                12.33,
                12.335,
                12.34,
                12.345,
                12.351,
                12.356,
                12.361,
                12.366,
                12.371,
                12.376,
                12.381,
                12.387,
                12.392,
                12.397,
                12.402,
                12.407,
                12.412,
                12.417,
                12.422,
                12.427,
                12.432,
                12.438,
                12.443,
                12.448,
                12.453,
                12.458,
                12.463,
                12.468,
                12.473,
                12.478,
                12.484,
                12.489,
                12.494,
                12.499,
                12.504,
                12.509,
                12.514,
                12.519,
                12.524,
                12.53,
                12.535,
                12.54,
                12.545,
                12.55,
                12.555,
                12.56,
                12.565,
                12.57,
                12.575,
                12.58,
                12.586,
                12.591,
                12.596,
                12.601,
                12.606,
                12.611,
                12.616,
                12.621,
                12.626,
                12.631,
                12.636,
                12.641,
                12.646,
                12.652,
                12.656,
                12.662,
                12.667,
                12.672,
                12.677,
                12.682,
                12.687,
                12.692,
                12.697,
                12.702,
                12.707,
                12.712,
                12.717,
                12.722,
                12.727,
                12.732,
                12.737,
                12.743,
                12.748,
                12.752,
                12.758,
                12.763,
                12.768,
                12.773,
                12.778,
                12.783,
                12.788,
                12.793,
                12.798,
                12.803,
                12.808,
                12.813,
                12.818,
                12.823,
                12.828,
                12.833,
                12.838,
                12.843,
                12.848,
                12.853,
                12.858,
                12.863,
                12.868,
                12.873,
                12.878,
                12.883,
                12.888,
                12.893,
                12.898,
                12.903,
                12.908,
                12.913,
                12.918,
                12.923,
                12.928,
                12.933,
                12.938,
                12.943,
                12.948,
                12.953,
                12.958,
                12.963,
                12.968,
                12.973,
                12.978,
                12.983,
                12.988,
                12.993,
                12.998,
                13.003,
                13.008,
                13.013,
                13.018,
                13.023,
                13.028,
                13.033,
                13.038,
                13.043,
                13.048,
                13.053,
                13.058,
                13.063,
                13.068,
                13.073,
                13.077,
                13.083,
                13.087,
                13.092,
                13.097,
                13.102,
                13.107,
                13.112,
                13.117,
                13.122,
                13.127,
                13.132,
                13.137,
                13.142,
                13.147,
                13.152,
                13.157,
                13.162,
                13.167,
                13.171,
                13.176,
                13.181,
                13.186,
                13.191,
                13.196,
                13.201,
                13.206,
                13.211,
                13.216,
                13.221,
                13.226,
                13.231,
                13.235,
                13.24,
                13.245,
                13.25,
                13.255,
                13.26,
                13.265,
                13.27,
                13.275,
                13.28,
                13.285,
                13.289,
                13.294,
                13.299,
                13.304,
                13.309,
                13.314,
                13.319,
                13.324,
                13.329,
                13.334,
                13.338,
                13.344,
                13.348,
                13.353,
                13.358,
                13.363,
                13.368,
                13.373,
                13.378,
                13.383,
                13.387,
                13.392,
                13.397,
                13.402,
                13.407,
                13.412,
                13.417,
                13.422,
                13.426,
                13.431,
                13.436,
                13.441,
                13.446,
                13.451,
                13.456,
                13.46,
                13.465,
                13.47,
                13.475,
                13.48,
                13.485,
                13.49,
                13.495,
                13.499,
                13.504,
                13.509,
                13.514,
                13.519,
                13.524,
                13.529,
                13.533,
                13.538,
                13.543,
                13.548,
                13.553,
                13.558,
                13.563,
                13.567,
                13.572,
                13.577,
                13.582,
                13.587,
                13.592,
                13.597,
                13.601,
                13.606,
                13.611,
                13.616,
                13.621,
                13.625,
                13.63,
                13.635,
                13.64,
                13.645,
                13.65,
                13.655,
                13.659,
                13.664,
                13.669,
                13.674,
                13.679,
                13.684,
                13.688,
                13.693,
                13.698,
                13.703,
                13.708,
                13.713,
                13.717,
                13.722,
                13.727,
                13.732,
                13.737,
                13.742,
                13.746,
                13.751,
                13.756,
                13.761,
                13.766,
                13.77,
                13.775,
                13.78,
                13.785,
                13.79,
                13.795,
                13.799,
                13.804,
                13.809,
                13.814,
                13.819,
                13.824,
                13.828,
                13.833,
                13.838,
                13.843,
                13.848,
                13.852,
                13.857,
                13.862,
                13.867,
                13.872,
                13.876,
                13.881,
                13.886,
                13.891,
                13.896,
                13.9,
                13.905,
                13.91,
                13.915,
                13.92,
                13.925,
                13.929,
                13.934,
                13.939,
                13.944,
                13.949,
                13.953,
                13.958,
                13.963,
                13.968,
                13.973,
                13.977,
                13.982,
                13.987,
                13.992,
                13.997,
                14.001,
                14.006,
                14.011,
                14.016,
                14.021,
                14.025,
                14.03,
                14.035,
                14.04,
                14.045,
                14.049,
                14.054,
                14.059,
                14.064,
                14.069,
                14.073,
                14.078,
                14.083,
                14.088,
                14.092,
                14.097,
                14.102,
                14.107,
                14.112,
                14.117,
                14.121,
                14.126,
                14.131,
                14.136,
                14.14,
                14.145,
                14.15,
                14.155,
                14.16,
                14.164,
                14.169,
                14.174,
                14.179,
                14.184,
                14.188,
                14.193,
                14.198,
                14.203,
                14.208,
                14.212,
                14.217,
                14.222,
                14.227,
                14.231,
                14.236,
                14.241,
                14.246,
                14.251,
                14.255,
                14.26,
                14.265,
                14.27,
                14.275,
                14.279,
                14.284,
                14.289,
                14.294,
                14.299,
                14.303,
                14.308,
                14.313,
                14.318,
                14.322,
                14.327,
                14.332,
                14.337,
                14.342,
                14.346,
                14.351,
                14.356,
                14.361,
                14.365,
                14.37,
                14.375,
                14.38,
                14.385,
                14.389,
                14.394,
                14.399,
                14.404,
                14.409,
                14.413,
                14.418,
                14.423,
                14.428,
                14.432,
                14.437,
                14.442,
                14.447,
                14.452,
                14.456,
                14.461,
                14.466,
                14.471,
                14.475,
                14.48,
                14.485,
                14.49,
                14.494,
                14.499,
                14.504,
                14.509,
                14.514,
                14.518,
                14.523,
                14.528,
                14.533,
                14.537,
                14.542,
                14.547,
                14.552,
                14.557,
                14.561,
                14.566,
                14.571,
                14.576,
                14.58,
                14.585,
                14.59,
                14.595,
                14.6,
                14.604,
                14.609,
                14.614,
                14.619,
                14.623,
                14.628,
                14.633,
                14.638,
                14.642,
                14.647,
                14.652,
                14.657,
                14.661,
                14.666,
                14.671,
                14.676,
                14.681,
                14.685,
                14.69,
                14.695,
                14.7,
                14.704,
                14.709,
                14.714,
                14.719,
                14.723,
                14.728,
                14.733,
                14.738,
                14.742,
                14.747,
                14.752,
                14.757,
                14.761,
                14.766,
                14.771,
                14.776,
                14.781,
                14.785,
                14.79,
                14.795,
                14.799,
                14.804,
                14.809,
                14.814,
                14.818,
                14.823,
                14.828,
                14.833,
                14.838,
                14.842,
                14.847,
                14.852,
                14.856,
                14.861,
                14.866,
                14.871,
                14.876,
                14.88,
                14.885,
                14.89,
                14.894,
                14.899,
                14.904,
                14.909,
                14.913,
                14.918,
                14.923,
                14.928,
                14.932,
                14.937,
                14.942,
                14.946,
                14.951,
                14.956,
                14.961,
                14.965,
                14.97,
                14.975,
                14.98,
                14.984,
                14.989,
                14.994,
                14.999,
                15.003,
                15.008,
                15.013,
                15.017,
                15.022,
                15.027,
                15.031,
                15.036,
                15.041,
                15.046,
                15.05,
                15.055,
                15.06,
                15.064,
                15.069,
                15.074,
                15.079,
                15.083,
                15.088,
                15.093,
                15.097,
                15.102,
                15.107,
                15.111,
                15.116,
                15.121,
                15.126,
                15.13,
                15.135,
                15.14,
                15.144,
                15.149,
                15.154,
                15.158,
                15.163,
                15.168,
                15.173,
                15.177,
                15.182,
                15.187,
                15.191,
                15.196,
                15.201,
                15.205,
                15.21,
                15.215,
                15.219,
                15.224,
                15.229,
                15.233,
                15.238,
                15.243,
                15.247,
                15.252,
                15.257,
                15.261,
                15.266,
                15.271,
                15.275,
                15.28,
                15.285,
                15.289,
                15.294,
                15.299,
                15.303,
                15.308,
                15.313,
                15.317,
                15.322,
                15.327,
                15.331,
                15.336,
                15.341,
                15.345,
                15.35,
                15.355,
                15.359,
                15.364,
                15.369,
                15.373,
                15.378,
                15.382,
                15.387,
                15.392,
                15.396,
                15.401,
                15.406,
                15.41,
                15.415,
                15.42,
                15.424,
                15.429,
                15.433,
                15.438,
                15.443,
                15.447,
                15.452,
                15.457,
                15.461,
                15.466,
                15.471,
                15.475,
                15.48,
                15.484,
                15.489,
                15.494,
                15.498,
                15.503,
                15.508,
                15.512,
                15.517,
                15.521,
                15.526,
                15.531,
                15.535,
                15.54,
                15.544,
                15.549,
                15.554,
                15.558,
                15.563,
                15.567,
                15.572,
                15.577,
                15.581,
                15.586,
                15.59,
                15.595,
                15.6,
                15.604,
                15.609,
                15.613,
                15.618,
                15.622,
                15.627,
                15.632,
                15.636,
                15.641,
                15.645,
                15.65,
                15.655,
                15.659,
                15.664,
                15.668,
                15.673,
                15.677,
                15.682,
                15.687,
                15.691,
                15.696,
                15.7,
                15.705,
                15.709,
                15.714,
                15.719,
                15.723,
                15.728,
                15.732,
                15.737,
                15.741,
                15.746,
                15.75,
                15.755,
                15.759,
                15.764,
                15.769,
                15.773,
                15.778,
                15.782,
                15.787,
                15.791,
                15.796,
                15.8,
                15.805,
                15.809,
                15.814,
                15.819,
                15.823

            ],
            P25: [
                2.932,
                2.892,
                2.904,
                2.923,
                2.945,
                2.969,
                2.995,
                3.022,
                3.05,
                3.078,
                3.108,
                3.139,
                3.17,
                3.203,
                3.237,
                3.271,
                3.306,
                3.342,
                3.378,
                3.413,
                3.449,
                3.485,
                3.521,
                3.556,
                3.592,
                3.627,
                3.662,
                3.697,
                3.731,
                3.766,
                3.8,
                3.833,
                3.866,
                3.899,
                3.932,
                3.964,
                3.996,
                4.028,
                4.059,
                4.09,
                4.121,
                4.151,
                4.181,
                4.211,
                4.241,
                4.27,
                4.299,
                4.327,
                4.356,
                4.384,
                4.411,
                4.439,
                4.466,
                4.493,
                4.519,
                4.546,
                4.572,
                4.598,
                4.623,
                4.649,
                4.674,
                4.699,
                4.724,
                4.748,
                4.773,
                4.797,
                4.821,
                4.845,
                4.868,
                4.892,
                4.915,
                4.938,
                4.961,
                4.983,
                5.006,
                5.028,
                5.05,
                5.072,
                5.094,
                5.115,
                5.137,
                5.158,
                5.179,
                5.2,
                5.221,
                5.241,
                5.262,
                5.282,
                5.302,
                5.322,
                5.342,
                5.362,
                5.382,
                5.401,
                5.42,
                5.44,
                5.459,
                5.478,
                5.496,
                5.515,
                5.534,
                5.552,
                5.57,
                5.589,
                5.607,
                5.625,
                5.643,
                5.66,
                5.678,
                5.695,
                5.713,
                5.73,
                5.747,
                5.764,
                5.781,
                5.798,
                5.814,
                5.831,
                5.848,
                5.864,
                5.88,
                5.896,
                5.912,
                5.928,
                5.944,
                5.96,
                5.975,
                5.991,
                6.006,
                6.022,
                6.037,
                6.052,
                6.067,
                6.082,
                6.097,
                6.112,
                6.126,
                6.141,
                6.155,
                6.17,
                6.184,
                6.198,
                6.212,
                6.227,
                6.24,
                6.254,
                6.268,
                6.282,
                6.295,
                6.309,
                6.322,
                6.336,
                6.349,
                6.362,
                6.375,
                6.388,
                6.401,
                6.414,
                6.427,
                6.44,
                6.452,
                6.465,
                6.478,
                6.49,
                6.502,
                6.515,
                6.527,
                6.539,
                6.551,
                6.563,
                6.576,
                6.587,
                6.599,
                6.611,
                6.623,
                6.635,
                6.646,
                6.658,
                6.669,
                6.681,
                6.692,
                6.704,
                6.715,
                6.726,
                6.738,
                6.749,
                6.76,
                6.771,
                6.782,
                6.793,
                6.804,
                6.815,
                6.825,
                6.836,
                6.847,
                6.857,
                6.868,
                6.879,
                6.889,
                6.9,
                6.91,
                6.92,
                6.931,
                6.941,
                6.951,
                6.961,
                6.972,
                6.982,
                6.992,
                7.002,
                7.012,
                7.022,
                7.032,
                7.041,
                7.051,
                7.061,
                7.071,
                7.08,
                7.09,
                7.1,
                7.109,
                7.119,
                7.128,
                7.138,
                7.147,
                7.157,
                7.166,
                7.175,
                7.185,
                7.194,
                7.203,
                7.213,
                7.222,
                7.231,
                7.24,
                7.249,
                7.258,
                7.267,
                7.276,
                7.285,
                7.294,
                7.303,
                7.312,
                7.321,
                7.329,
                7.338,
                7.347,
                7.356,
                7.364,
                7.373,
                7.381,
                7.39,
                7.398,
                7.407,
                7.416,
                7.424,
                7.432,
                7.441,
                7.449,
                7.458,
                7.466,
                7.474,
                7.483,
                7.491,
                7.499,
                7.507,
                7.515,
                7.524,
                7.532,
                7.54,
                7.548,
                7.556,
                7.564,
                7.572,
                7.58,
                7.588,
                7.596,
                7.604,
                7.612,
                7.62,
                7.628,
                7.635,
                7.643,
                7.651,
                7.659,
                7.667,
                7.674,
                7.682,
                7.69,
                7.697,
                7.705,
                7.713,
                7.72,
                7.728,
                7.736,
                7.743,
                7.751,
                7.758,
                7.766,
                7.773,
                7.781,
                7.788,
                7.796,
                7.803,
                7.811,
                7.818,
                7.825,
                7.833,
                7.84,
                7.848,
                7.855,
                7.862,
                7.869,
                7.877,
                7.884,
                7.891,
                7.899,
                7.906,
                7.913,
                7.92,
                7.928,
                7.935,
                7.942,
                7.949,
                7.956,
                7.964,
                7.971,
                7.978,
                7.985,
                7.992,
                7.999,
                8.006,
                8.013,
                8.02,
                8.027,
                8.034,
                8.041,
                8.048,
                8.055,
                8.062,
                8.069,
                8.076,
                8.083,
                8.09,
                8.097,
                8.104,
                8.111,
                8.118,
                8.125,
                8.132,
                8.139,
                8.146,
                8.153,
                8.159,
                8.166,
                8.173,
                8.18,
                8.187,
                8.194,
                8.201,
                8.207,
                8.214,
                8.221,
                8.228,
                8.235,
                8.241,
                8.248,
                8.255,
                8.262,
                8.268,
                8.275,
                8.282,
                8.289,
                8.296,
                8.302,
                8.309,
                8.316,
                8.322,
                8.329,
                8.336,
                8.343,
                8.349,
                8.356,
                8.363,
                8.369,
                8.376,
                8.383,
                8.389,
                8.396,
                8.403,
                8.409,
                8.416,
                8.423,
                8.429,
                8.436,
                8.442,
                8.449,
                8.456,
                8.462,
                8.469,
                8.475,
                8.482,
                8.489,
                8.495,
                8.502,
                8.509,
                8.515,
                8.522,
                8.528,
                8.535,
                8.541,
                8.548,
                8.554,
                8.561,
                8.568,
                8.574,
                8.581,
                8.587,
                8.594,
                8.6,
                8.607,
                8.613,
                8.62,
                8.626,
                8.633,
                8.639,
                8.646,
                8.652,
                8.659,
                8.665,
                8.672,
                8.678,
                8.685,
                8.691,
                8.698,
                8.704,
                8.711,
                8.717,
                8.724,
                8.73,
                8.737,
                8.743,
                8.75,
                8.756,
                8.763,
                8.769,
                8.776,
                8.782,
                8.788,
                8.795,
                8.801,
                8.808,
                8.814,
                8.821,
                8.827,
                8.834,
                8.84,
                8.846,
                8.853,
                8.859,
                8.866,
                8.872,
                8.879,
                8.885,
                8.891,
                8.898,
                8.904,
                8.911,
                8.917,
                8.923,
                8.93,
                8.936,
                8.943,
                8.949,
                8.956,
                8.962,
                8.968,
                8.975,
                8.981,
                8.987,
                8.994,
                9,
                9.007,
                9.013,
                9.019,
                9.026,
                9.032,
                9.039,
                9.045,
                9.051,
                9.058,
                9.064,
                9.071,
                9.077,
                9.083,
                9.09,
                9.096,
                9.102,
                9.109,
                9.115,
                9.121,
                9.128,
                9.134,
                9.141,
                9.147,
                9.153,
                9.16,
                9.166,
                9.172,
                9.179,
                9.185,
                9.191,
                9.198,
                9.204,
                9.211,
                9.217,
                9.223,
                9.23,
                9.236,
                9.242,
                9.249,
                9.255,
                9.261,
                9.268,
                9.274,
                9.28,
                9.287,
                9.293,
                9.299,
                9.306,
                9.312,
                9.318,
                9.325,
                9.331,
                9.337,
                9.344,
                9.35,
                9.356,
                9.363,
                9.369,
                9.375,
                9.382,
                9.388,
                9.394,
                9.4,
                9.407,
                9.413,
                9.419,
                9.426,
                9.432,
                9.438,
                9.444,
                9.451,
                9.457,
                9.463,
                9.47,
                9.476,
                9.482,
                9.489,
                9.495,
                9.501,
                9.507,
                9.514,
                9.52,
                9.526,
                9.533,
                9.539,
                9.545,
                9.551,
                9.558,
                9.564,
                9.57,
                9.576,
                9.583,
                9.589,
                9.595,
                9.602,
                9.608,
                9.614,
                9.62,
                9.627,
                9.633,
                9.639,
                9.645,
                9.652,
                9.658,
                9.664,
                9.671,
                9.677,
                9.683,
                9.689,
                9.696,
                9.702,
                9.708,
                9.714,
                9.721,
                9.727,
                9.733,
                9.739,
                9.746,
                9.752,
                9.758,
                9.764,
                9.771,
                9.777,
                9.783,
                9.789,
                9.796,
                9.802,
                9.808,
                9.814,
                9.821,
                9.827,
                9.833,
                9.839,
                9.845,
                9.852,
                9.858,
                9.864,
                9.87,
                9.877,
                9.883,
                9.889,
                9.895,
                9.902,
                9.908,
                9.914,
                9.92,
                9.927,
                9.933,
                9.939,
                9.945,
                9.952,
                9.958,
                9.964,
                9.97,
                9.977,
                9.983,
                9.989,
                9.995,
                10.002,
                10.008,
                10.014,
                10.02,
                10.027,
                10.033,
                10.039,
                10.045,
                10.051,
                10.058,
                10.064,
                10.07,
                10.077,
                10.083,
                10.089,
                10.095,
                10.102,
                10.108,
                10.114,
                10.12,
                10.126,
                10.133,
                10.139,
                10.145,
                10.152,
                10.158,
                10.164,
                10.17,
                10.176,
                10.183,
                10.189,
                10.195,
                10.202,
                10.208,
                10.214,
                10.22,
                10.227,
                10.233,
                10.239,
                10.245,
                10.252,
                10.258,
                10.264,
                10.27,
                10.277,
                10.283,
                10.289,
                10.295,
                10.302,
                10.308,
                10.314,
                10.32,
                10.327,
                10.333,
                10.339,
                10.345,
                10.352,
                10.358,
                10.364,
                10.371,
                10.377,
                10.383,
                10.389,
                10.396,
                10.402,
                10.408,
                10.414,
                10.421,
                10.427,
                10.433,
                10.439,
                10.446,
                10.452,
                10.458,
                10.465,
                10.471,
                10.477,
                10.483,
                10.49,
                10.496,
                10.502,
                10.508,
                10.515,
                10.521,
                10.527,
                10.533,
                10.54,
                10.546,
                10.552,
                10.559,
                10.565,
                10.571,
                10.577,
                10.584,
                10.59,
                10.596,
                10.602,
                10.609,
                10.615,
                10.621,
                10.628,
                10.634,
                10.64,
                10.646,
                10.653,
                10.659,
                10.665,
                10.671,
                10.678,
                10.684,
                10.69,
                10.696,
                10.703,
                10.709,
                10.715,
                10.722,
                10.728,
                10.734,
                10.74,
                10.747,
                10.753,
                10.759,
                10.765,
                10.772,
                10.778,
                10.784,
                10.79,
                10.797,
                10.803,
                10.809,
                10.815,
                10.822,
                10.828,
                10.834,
                10.84,
                10.847,
                10.853,
                10.859,
                10.865,
                10.872,
                10.878,
                10.884,
                10.89,
                10.897,
                10.903,
                10.909,
                10.915,
                10.921,
                10.928,
                10.934,
                10.94,
                10.946,
                10.953,
                10.959,
                10.965,
                10.971,
                10.978,
                10.984,
                10.99,
                10.996,
                11.002,
                11.008,
                11.015,
                11.021,
                11.027,
                11.033,
                11.04,
                11.046,
                11.052,
                11.058,
                11.064,
                11.07,
                11.077,
                11.083,
                11.089,
                11.095,
                11.101,
                11.107,
                11.114,
                11.12,
                11.126,
                11.132,
                11.138,
                11.144,
                11.15,
                11.157,
                11.163,
                11.169,
                11.175,
                11.181,
                11.187,
                11.193,
                11.199,
                11.206,
                11.212,
                11.218,
                11.224,
                11.23,
                11.236,
                11.242,
                11.248,
                11.254,
                11.26,
                11.266,
                11.272,
                11.279,
                11.285,
                11.291,
                11.297,
                11.303,
                11.309,
                11.315,
                11.321,
                11.327,
                11.333,
                11.339,
                11.345,
                11.351,
                11.357,
                11.363,
                11.369,
                11.375,
                11.381,
                11.387,
                11.393,
                11.399,
                11.405,
                11.411,
                11.417,
                11.423,
                11.429,
                11.435,
                11.441,
                11.447,
                11.453,
                11.459,
                11.465,
                11.471,
                11.477,
                11.483,
                11.489,
                11.495,
                11.501,
                11.507,
                11.513,
                11.519,
                11.525,
                11.531,
                11.536,
                11.542,
                11.548,
                11.554,
                11.56,
                11.566,
                11.572,
                11.578,
                11.584,
                11.59,
                11.596,
                11.601,
                11.607,
                11.613,
                11.619,
                11.625,
                11.631,
                11.637,
                11.643,
                11.648,
                11.654,
                11.66,
                11.666,
                11.672,
                11.678,
                11.683,
                11.689,
                11.695,
                11.701,
                11.707,
                11.713,
                11.718,
                11.724,
                11.73,
                11.736,
                11.742,
                11.747,
                11.753,
                11.759,
                11.765,
                11.771,
                11.776,
                11.782,
                11.788,
                11.794,
                11.799,
                11.805,
                11.811,
                11.817,
                11.822,
                11.828,
                11.834,
                11.84,
                11.845,
                11.851,
                11.857,
                11.862,
                11.868,
                11.874,
                11.88,
                11.885,
                11.891,
                11.897,
                11.903,
                11.908,
                11.914,
                11.92,
                11.925,
                11.931,
                11.937,
                11.942,
                11.948,
                11.954,
                11.96,
                11.965,
                11.971,
                11.977,
                11.982,
                11.988,
                11.994,
                11.999,
                12.005,
                12.011,
                12.016,
                12.022,
                12.027,
                12.033,
                12.039,
                12.044,
                12.05,
                12.056,
                12.061,
                12.067,
                12.073,
                12.078,
                12.084,
                12.089,
                12.095,
                12.101,
                12.106,
                12.112,
                12.118,
                12.123,
                12.129,
                12.134,
                12.14,
                12.146,
                12.151,
                12.157,
                12.162,
                12.168,
                12.174,
                12.179,
                12.185,
                12.19,
                12.196,
                12.202,
                12.207,
                12.213,
                12.218,
                12.224,
                12.23,
                12.235,
                12.241,
                12.246,
                12.252,
                12.258,
                12.263,
                12.269,
                12.274,
                12.28,
                12.285,
                12.291,
                12.296,
                12.302,
                12.308,
                12.313,
                12.319,
                12.324,
                12.33,
                12.335,
                12.341,
                12.347,
                12.352,
                12.358,
                12.363,
                12.369,
                12.374,
                12.38,
                12.385,
                12.391,
                12.396,
                12.402,
                12.407,
                12.413,
                12.419,
                12.424,
                12.43,
                12.435,
                12.441,
                12.446,
                12.452,
                12.457,
                12.463,
                12.468,
                12.474,
                12.479,
                12.485,
                12.49,
                12.496,
                12.502,
                12.507,
                12.513,
                12.518,
                12.524,
                12.529,
                12.535,
                12.54,
                12.546,
                12.551,
                12.557,
                12.562,
                12.568,
                12.573,
                12.579,
                12.584,
                12.59,
                12.595,
                12.601,
                12.606,
                12.612,
                12.617,
                12.623,
                12.629,
                12.634,
                12.639,
                12.645,
                12.651,
                12.656,
                12.661,
                12.667,
                12.672,
                12.678,
                12.684,
                12.689,
                12.695,
                12.7,
                12.705,
                12.711,
                12.716,
                12.722,
                12.728,
                12.733,
                12.738,
                12.744,
                12.749,
                12.755,
                12.76,
                12.766,
                12.771,
                12.777,
                12.782,
                12.788,
                12.793,
                12.799,
                12.804,
                12.81,
                12.815,
                12.821,
                12.826,
                12.832,
                12.837,
                12.843,
                12.848,
                12.854,
                12.859,
                12.865,
                12.87,
                12.876,
                12.881,
                12.887,
                12.892,
                12.898,
                12.903,
                12.908,
                12.914,
                12.919,
                12.925,
                12.93,
                12.936,
                12.941,
                12.947,
                12.952,
                12.958,
                12.963,
                12.969,
                12.974,
                12.979,
                12.985,
                12.991,
                12.996,
                13.001,
                13.007,
                13.012,
                13.018,
                13.023,
                13.029,
                13.034,
                13.04,
                13.045,
                13.05,
                13.056,
                13.061,
                13.067,
                13.072,
                13.078,
                13.083,
                13.089,
                13.094,
                13.099,
                13.105,
                13.11,
                13.116,
                13.121,
                13.127,
                13.132,
                13.138,
                13.143,
                13.148,
                13.154,
                13.159,
                13.165,
                13.17,
                13.176,
                13.181,
                13.186,
                13.192,
                13.197,
                13.203,
                13.208,
                13.214,
                13.219,
                13.224,
                13.23,
                13.235,
                13.241,
                13.246,
                13.251,
                13.257,
                13.262,
                13.268,
                13.273,
                13.279,
                13.284,
                13.289,
                13.295,
                13.3,
                13.306,
                13.311,
                13.316,
                13.322,
                13.327,
                13.333,
                13.338,
                13.343,
                13.349,
                13.354,
                13.36,
                13.365,
                13.37,
                13.376,
                13.381,
                13.387,
                13.392,
                13.397,
                13.403,
                13.408,
                13.413,
                13.419,
                13.424,
                13.43,
                13.435,
                13.44,
                13.446,
                13.451,
                13.456,
                13.462,
                13.467,
                13.472,
                13.478,
                13.483,
                13.489,
                13.494,
                13.499,
                13.505,
                13.51,
                13.515,
                13.521,
                13.526,
                13.532,
                13.537,
                13.542,
                13.548,
                13.553,
                13.558,
                13.564,
                13.569,
                13.574,
                13.58,
                13.585,
                13.59,
                13.596,
                13.601,
                13.606,
                13.612,
                13.617,
                13.622,
                13.628,
                13.633,
                13.638,
                13.644,
                13.649,
                13.654,
                13.66,
                13.665,
                13.67,
                13.676,
                13.681,
                13.686,
                13.692,
                13.697,
                13.702,
                13.708,
                13.713,
                13.718,
                13.724,
                13.729,
                13.734,
                13.739,
                13.745,
                13.75,
                13.755,
                13.761,
                13.766,
                13.771,
                13.777,
                13.782,
                13.787,
                13.792,
                13.798,
                13.803,
                13.808,
                13.814,
                13.819,
                13.824,
                13.83,
                13.835,
                13.84,
                13.845,
                13.851,
                13.856,
                13.861,
                13.867,
                13.872,
                13.877,
                13.882,
                13.888,
                13.893,
                13.898,
                13.903,
                13.909,
                13.914,
                13.919,
                13.925,
                13.93,
                13.935,
                13.94,
                13.946,
                13.951,
                13.956,
                13.961,
                13.967,
                13.972,
                13.977,
                13.982,
                13.988,
                13.993,
                13.998,
                14.003,
                14.009,
                14.014,
                14.019,
                14.024,
                14.03,
                14.035,
                14.04,
                14.045,
                14.051,
                14.056,
                14.061,
                14.067,
                14.072,
                14.077,
                14.082,
                14.087,
                14.093,
                14.098,
                14.103,
                14.108,
                14.114,
                14.119,
                14.124,
                14.129,
                14.135,
                14.14,
                14.145,
                14.15,
                14.155,
                14.161,
                14.166,
                14.171,
                14.176,
                14.182,
                14.187,
                14.192,
                14.197,
                14.203,
                14.208,
                14.213,
                14.218,
                14.223,
                14.229,
                14.234,
                14.239,
                14.244,
                14.249,
                14.255,
                14.26,
                14.265,
                14.27,
                14.275,
                14.281,
                14.286,
                14.291,
                14.296,
                14.301,
                14.307,
                14.312,
                14.317,
                14.322,
                14.327,
                14.333,
                14.338,
                14.343,
                14.348,
                14.354,
                14.359,
                14.364,
                14.369,
                14.374,
                14.38,
                14.385,
                14.39,
                14.395,
                14.4,
                14.406,
                14.411,
                14.416,
                14.421,
                14.426,
                14.431,
                14.437,
                14.442,
                14.447,
                14.452,
                14.457,
                14.463,
                14.468,
                14.473,
                14.478,
                14.483,
                14.489,
                14.494,
                14.499,
                14.504,
                14.509,
                14.514,
                14.52,
                14.525,
                14.53,
                14.535,
                14.54,
                14.546,
                14.551,
                14.556,
                14.561,
                14.566,
                14.572,
                14.577,
                14.582,
                14.587,
                14.592,
                14.597,
                14.602,
                14.608,
                14.613,
                14.618,
                14.623,
                14.628,
                14.634,
                14.639,
                14.644,
                14.649,
                14.654,
                14.659,
                14.665,
                14.67,
                14.675,
                14.68,
                14.685,
                14.691,
                14.696,
                14.701,
                14.706,
                14.711,
                14.716,
                14.722,
                14.727,
                14.732,
                14.737,
                14.742,
                14.747,
                14.752,
                14.758,
                14.763,
                14.768,
                14.773,
                14.778,
                14.784,
                14.789,
                14.794,
                14.799,
                14.804,
                14.809,
                14.814,
                14.82,
                14.825,
                14.83,
                14.835,
                14.84,
                14.845,
                14.851,
                14.856,
                14.861,
                14.866,
                14.871,
                14.877,
                14.882,
                14.887,
                14.892,
                14.897,
                14.902,
                14.907,
                14.913,
                14.918,
                14.923,
                14.928,
                14.933,
                14.938,
                14.944,
                14.949,
                14.954,
                14.959,
                14.964,
                14.969,
                14.974,
                14.98,
                14.985,
                14.99,
                14.995,
                15,
                15.005,
                15.011,
                15.016,
                15.021,
                15.026,
                15.031,
                15.036,
                15.042,
                15.047,
                15.052,
                15.057,
                15.062,
                15.067,
                15.073,
                15.078,
                15.083,
                15.088,
                15.093,
                15.098,
                15.103,
                15.109,
                15.114,
                15.119,
                15.124,
                15.129,
                15.134,
                15.14,
                15.145,
                15.15,
                15.155,
                15.16,
                15.165,
                15.17,
                15.176,
                15.181,
                15.186,
                15.191,
                15.196,
                15.201,
                15.206,
                15.212,
                15.217,
                15.222,
                15.227,
                15.232,
                15.237,
                15.242,
                15.248,
                15.253,
                15.258,
                15.263,
                15.268,
                15.273,
                15.279,
                15.284,
                15.289,
                15.294,
                15.299,
                15.304,
                15.309,
                15.315,
                15.32,
                15.325,
                15.33,
                15.335,
                15.34,
                15.345,
                15.351,
                15.356,
                15.361,
                15.366,
                15.371,
                15.376,
                15.381,
                15.387,
                15.392,
                15.397,
                15.402,
                15.407,
                15.412,
                15.417,
                15.422,
                15.428,
                15.433,
                15.438,
                15.443,
                15.448,
                15.453,
                15.458,
                15.464,
                15.469,
                15.474,
                15.479,
                15.484,
                15.489,
                15.494,
                15.499,
                15.505,
                15.51,
                15.515,
                15.52,
                15.525,
                15.53,
                15.535,
                15.54,
                15.546,
                15.551,
                15.556,
                15.561,
                15.566,
                15.571,
                15.576,
                15.581,
                15.587,
                15.592,
                15.597,
                15.602,
                15.607,
                15.612,
                15.617,
                15.622,
                15.627,
                15.633,
                15.638,
                15.643,
                15.648,
                15.653,
                15.658,
                15.663,
                15.668,
                15.674,
                15.679,
                15.684,
                15.689,
                15.694,
                15.699,
                15.704,
                15.709,
                15.714,
                15.719,
                15.725,
                15.73,
                15.735,
                15.74,
                15.745,
                15.75,
                15.755,
                15.76,
                15.765,
                15.77,
                15.775,
                15.781,
                15.786,
                15.791,
                15.796,
                15.801,
                15.806,
                15.811,
                15.816,
                15.821,
                15.826,
                15.831,
                15.837,
                15.842,
                15.847,
                15.852,
                15.857,
                15.862,
                15.867,
                15.872,
                15.877,
                15.882,
                15.887,
                15.892,
                15.897,
                15.902,
                15.908,
                15.913,
                15.918,
                15.923,
                15.928,
                15.933,
                15.938,
                15.943,
                15.948,
                15.953,
                15.958,
                15.963,
                15.968,
                15.973,
                15.978,
                15.984,
                15.989,
                15.994,
                15.999,
                16.004,
                16.009,
                16.014,
                16.019,
                16.024,
                16.029,
                16.034,
                16.039,
                16.044,
                16.049,
                16.054,
                16.059,
                16.064,
                16.069,
                16.074,
                16.079,
                16.084,
                16.089,
                16.094,
                16.1,
                16.104,
                16.11,
                16.115,
                16.12,
                16.125,
                16.13,
                16.135,
                16.14,
                16.145,
                16.15,
                16.155,
                16.16,
                16.165,
                16.17,
                16.175,
                16.18,
                16.185,
                16.19,
                16.195,
                16.2,
                16.205,
                16.21,
                16.215,
                16.22,
                16.225,
                16.23,
                16.235,
                16.24,
                16.245,
                16.25,
                16.255,
                16.26,
                16.265,
                16.27,
                16.275,
                16.28,
                16.285,
                16.29,
                16.295,
                16.3,
                16.305,
                16.31,
                16.315,
                16.32,
                16.325,
                16.33,
                16.335,
                16.34,
                16.345,
                16.35,
                16.355,
                16.359,
                16.365,
                16.369,
                16.374,
                16.379,
                16.384,
                16.389,
                16.394,
                16.399,
                16.404,
                16.409,
                16.414,
                16.419,
                16.424,
                16.429,
                16.434,
                16.439,
                16.444,
                16.449,
                16.454,
                16.459,
                16.464,
                16.469,
                16.474,
                16.479,
                16.484,
                16.488,
                16.493,
                16.498,
                16.503,
                16.508,
                16.513,
                16.518,
                16.523,
                16.528,
                16.533,
                16.538,
                16.543,
                16.548,
                16.553,
                16.557,
                16.563,
                16.567,
                16.572,
                16.577,
                16.582,
                16.587,
                16.592,
                16.597,
                16.602,
                16.607,
                16.612,
                16.616,
                16.621,
                16.626,
                16.631,
                16.636,
                16.641,
                16.646,
                16.651,
                16.656,
                16.661

            ],
            P50: [
                3.232,
                3.196,
                3.21,
                3.232,
                3.256,
                3.282,
                3.31,
                3.339,
                3.369,
                3.4,
                3.431,
                3.464,
                3.498,
                3.533,
                3.569,
                3.606,
                3.644,
                3.682,
                3.72,
                3.758,
                3.797,
                3.835,
                3.874,
                3.912,
                3.95,
                3.987,
                4.025,
                4.062,
                4.099,
                4.135,
                4.172,
                4.208,
                4.243,
                4.278,
                4.313,
                4.348,
                4.382,
                4.416,
                4.449,
                4.482,
                4.515,
                4.547,
                4.579,
                4.611,
                4.643,
                4.674,
                4.704,
                4.735,
                4.765,
                4.795,
                4.825,
                4.854,
                4.883,
                4.912,
                4.94,
                4.968,
                4.996,
                5.024,
                5.051,
                5.078,
                5.105,
                5.132,
                5.158,
                5.184,
                5.21,
                5.236,
                5.262,
                5.287,
                5.312,
                5.337,
                5.362,
                5.386,
                5.411,
                5.435,
                5.459,
                5.483,
                5.506,
                5.53,
                5.553,
                5.576,
                5.599,
                5.621,
                5.644,
                5.666,
                5.688,
                5.71,
                5.732,
                5.754,
                5.776,
                5.797,
                5.818,
                5.839,
                5.86,
                5.881,
                5.902,
                5.922,
                5.943,
                5.963,
                5.983,
                6.003,
                6.023,
                6.043,
                6.062,
                6.082,
                6.101,
                6.12,
                6.139,
                6.158,
                6.177,
                6.196,
                6.214,
                6.233,
                6.251,
                6.269,
                6.287,
                6.305,
                6.323,
                6.341,
                6.359,
                6.376,
                6.394,
                6.411,
                6.428,
                6.445,
                6.462,
                6.479,
                6.496,
                6.512,
                6.529,
                6.545,
                6.562,
                6.578,
                6.594,
                6.61,
                6.626,
                6.642,
                6.657,
                6.673,
                6.688,
                6.704,
                6.719,
                6.734,
                6.75,
                6.765,
                6.78,
                6.794,
                6.809,
                6.824,
                6.838,
                6.853,
                6.867,
                6.882,
                6.896,
                6.91,
                6.924,
                6.938,
                6.952,
                6.966,
                6.98,
                6.993,
                7.007,
                7.021,
                7.034,
                7.047,
                7.061,
                7.074,
                7.087,
                7.1,
                7.113,
                7.126,
                7.139,
                7.152,
                7.165,
                7.178,
                7.19,
                7.203,
                7.215,
                7.228,
                7.24,
                7.253,
                7.265,
                7.277,
                7.289,
                7.302,
                7.314,
                7.326,
                7.338,
                7.35,
                7.361,
                7.373,
                7.385,
                7.397,
                7.408,
                7.42,
                7.431,
                7.443,
                7.454,
                7.466,
                7.477,
                7.488,
                7.5,
                7.511,
                7.522,
                7.533,
                7.544,
                7.555,
                7.566,
                7.577,
                7.588,
                7.599,
                7.609,
                7.62,
                7.631,
                7.642,
                7.652,
                7.663,
                7.673,
                7.684,
                7.694,
                7.705,
                7.715,
                7.725,
                7.736,
                7.746,
                7.756,
                7.766,
                7.777,
                7.787,
                7.797,
                7.807,
                7.817,
                7.827,
                7.837,
                7.847,
                7.857,
                7.866,
                7.876,
                7.886,
                7.896,
                7.905,
                7.915,
                7.925,
                7.934,
                7.944,
                7.953,
                7.963,
                7.972,
                7.982,
                7.991,
                8.001,
                8.01,
                8.019,
                8.029,
                8.038,
                8.047,
                8.056,
                8.066,
                8.075,
                8.084,
                8.093,
                8.102,
                8.111,
                8.12,
                8.129,
                8.138,
                8.147,
                8.156,
                8.165,
                8.173,
                8.182,
                8.191,
                8.2,
                8.209,
                8.217,
                8.226,
                8.235,
                8.243,
                8.252,
                8.261,
                8.269,
                8.278,
                8.286,
                8.295,
                8.303,
                8.312,
                8.32,
                8.329,
                8.337,
                8.345,
                8.354,
                8.362,
                8.37,
                8.379,
                8.387,
                8.395,
                8.404,
                8.412,
                8.42,
                8.428,
                8.436,
                8.445,
                8.453,
                8.461,
                8.469,
                8.477,
                8.485,
                8.493,
                8.501,
                8.509,
                8.517,
                8.525,
                8.533,
                8.541,
                8.549,
                8.557,
                8.565,
                8.573,
                8.581,
                8.589,
                8.597,
                8.604,
                8.612,
                8.62,
                8.628,
                8.636,
                8.644,
                8.651,
                8.659,
                8.667,
                8.675,
                8.682,
                8.69,
                8.698,
                8.705,
                8.713,
                8.721,
                8.728,
                8.736,
                8.744,
                8.751,
                8.759,
                8.766,
                8.774,
                8.782,
                8.789,
                8.797,
                8.804,
                8.812,
                8.819,
                8.827,
                8.834,
                8.842,
                8.85,
                8.857,
                8.864,
                8.872,
                8.879,
                8.887,
                8.894,
                8.902,
                8.909,
                8.917,
                8.924,
                8.931,
                8.939,
                8.946,
                8.954,
                8.961,
                8.968,
                8.976,
                8.983,
                8.99,
                8.998,
                9.005,
                9.013,
                9.02,
                9.027,
                9.034,
                9.042,
                9.049,
                9.056,
                9.064,
                9.071,
                9.078,
                9.085,
                9.093,
                9.1,
                9.107,
                9.114,
                9.122,
                9.129,
                9.136,
                9.143,
                9.151,
                9.158,
                9.165,
                9.172,
                9.179,
                9.187,
                9.194,
                9.201,
                9.208,
                9.215,
                9.223,
                9.23,
                9.237,
                9.244,
                9.251,
                9.258,
                9.265,
                9.273,
                9.28,
                9.287,
                9.294,
                9.301,
                9.308,
                9.315,
                9.322,
                9.329,
                9.337,
                9.344,
                9.351,
                9.358,
                9.365,
                9.372,
                9.379,
                9.386,
                9.393,
                9.4,
                9.407,
                9.414,
                9.421,
                9.429,
                9.436,
                9.443,
                9.45,
                9.457,
                9.464,
                9.471,
                9.478,
                9.485,
                9.492,
                9.499,
                9.506,
                9.513,
                9.52,
                9.527,
                9.534,
                9.541,
                9.548,
                9.555,
                9.562,
                9.569,
                9.576,
                9.583,
                9.59,
                9.597,
                9.604,
                9.611,
                9.618,
                9.625,
                9.632,
                9.639,
                9.646,
                9.653,
                9.66,
                9.667,
                9.674,
                9.681,
                9.687,
                9.694,
                9.701,
                9.708,
                9.715,
                9.722,
                9.729,
                9.736,
                9.743,
                9.75,
                9.757,
                9.764,
                9.771,
                9.778,
                9.785,
                9.792,
                9.799,
                9.805,
                9.812,
                9.819,
                9.826,
                9.833,
                9.84,
                9.847,
                9.854,
                9.861,
                9.868,
                9.875,
                9.882,
                9.889,
                9.895,
                9.902,
                9.909,
                9.916,
                9.923,
                9.93,
                9.937,
                9.944,
                9.951,
                9.958,
                9.964,
                9.971,
                9.978,
                9.985,
                9.992,
                9.999,
                10.006,
                10.013,
                10.02,
                10.027,
                10.033,
                10.04,
                10.047,
                10.054,
                10.061,
                10.068,
                10.075,
                10.082,
                10.088,
                10.095,
                10.102,
                10.109,
                10.116,
                10.123,
                10.13,
                10.137,
                10.143,
                10.15,
                10.157,
                10.164,
                10.171,
                10.178,
                10.185,
                10.191,
                10.198,
                10.205,
                10.212,
                10.219,
                10.226,
                10.232,
                10.239,
                10.246,
                10.253,
                10.26,
                10.267,
                10.273,
                10.28,
                10.287,
                10.294,
                10.301,
                10.308,
                10.314,
                10.321,
                10.328,
                10.335,
                10.342,
                10.349,
                10.355,
                10.362,
                10.369,
                10.376,
                10.383,
                10.39,
                10.396,
                10.403,
                10.41,
                10.417,
                10.424,
                10.43,
                10.437,
                10.444,
                10.451,
                10.458,
                10.465,
                10.471,
                10.478,
                10.485,
                10.492,
                10.499,
                10.505,
                10.512,
                10.519,
                10.526,
                10.533,
                10.539,
                10.546,
                10.553,
                10.56,
                10.567,
                10.573,
                10.58,
                10.587,
                10.594,
                10.601,
                10.607,
                10.614,
                10.621,
                10.628,
                10.635,
                10.641,
                10.648,
                10.655,
                10.662,
                10.669,
                10.675,
                10.682,
                10.689,
                10.696,
                10.703,
                10.709,
                10.716,
                10.723,
                10.73,
                10.737,
                10.743,
                10.75,
                10.757,
                10.764,
                10.771,
                10.777,
                10.784,
                10.791,
                10.798,
                10.805,
                10.811,
                10.818,
                10.825,
                10.832,
                10.839,
                10.845,
                10.852,
                10.859,
                10.866,
                10.873,
                10.879,
                10.886,
                10.893,
                10.9,
                10.907,
                10.913,
                10.92,
                10.927,
                10.934,
                10.941,
                10.947,
                10.954,
                10.961,
                10.968,
                10.975,
                10.982,
                10.988,
                10.995,
                11.002,
                11.009,
                11.016,
                11.022,
                11.029,
                11.036,
                11.043,
                11.05,
                11.057,
                11.063,
                11.07,
                11.077,
                11.084,
                11.091,
                11.098,
                11.104,
                11.111,
                11.118,
                11.125,
                11.132,
                11.138,
                11.145,
                11.152,
                11.159,
                11.166,
                11.173,
                11.18,
                11.186,
                11.193,
                11.2,
                11.207,
                11.214,
                11.221,
                11.227,
                11.234,
                11.241,
                11.248,
                11.255,
                11.262,
                11.268,
                11.275,
                11.282,
                11.289,
                11.296,
                11.303,
                11.31,
                11.316,
                11.323,
                11.33,
                11.337,
                11.344,
                11.351,
                11.358,
                11.364,
                11.371,
                11.378,
                11.385,
                11.392,
                11.399,
                11.406,
                11.412,
                11.419,
                11.426,
                11.433,
                11.44,
                11.447,
                11.454,
                11.46,
                11.467,
                11.474,
                11.481,
                11.488,
                11.495,
                11.502,
                11.508,
                11.515,
                11.522,
                11.529,
                11.536,
                11.543,
                11.55,
                11.556,
                11.563,
                11.57,
                11.577,
                11.584,
                11.591,
                11.598,
                11.605,
                11.611,
                11.618,
                11.625,
                11.632,
                11.639,
                11.646,
                11.653,
                11.659,
                11.666,
                11.673,
                11.68,
                11.687,
                11.694,
                11.701,
                11.707,
                11.714,
                11.721,
                11.728,
                11.735,
                11.742,
                11.749,
                11.755,
                11.762,
                11.769,
                11.776,
                11.783,
                11.79,
                11.796,
                11.803,
                11.81,
                11.817,
                11.824,
                11.831,
                11.838,
                11.844,
                11.851,
                11.858,
                11.865,
                11.872,
                11.879,
                11.885,
                11.892,
                11.899,
                11.906,
                11.913,
                11.919,
                11.926,
                11.933,
                11.94,
                11.947,
                11.954,
                11.96,
                11.967,
                11.974,
                11.981,
                11.988,
                11.994,
                12.001,
                12.008,
                12.015,
                12.022,
                12.028,
                12.035,
                12.042,
                12.049,
                12.055,
                12.062,
                12.069,
                12.076,
                12.083,
                12.089,
                12.096,
                12.103,
                12.11,
                12.116,
                12.123,
                12.13,
                12.137,
                12.143,
                12.15,
                12.157,
                12.164,
                12.17,
                12.177,
                12.184,
                12.19,
                12.197,
                12.204,
                12.211,
                12.217,
                12.224,
                12.231,
                12.237,
                12.244,
                12.251,
                12.258,
                12.264,
                12.271,
                12.278,
                12.284,
                12.291,
                12.298,
                12.304,
                12.311,
                12.318,
                12.324,
                12.331,
                12.338,
                12.344,
                12.351,
                12.358,
                12.364,
                12.371,
                12.377,
                12.384,
                12.391,
                12.397,
                12.404,
                12.411,
                12.417,
                12.424,
                12.43,
                12.437,
                12.444,
                12.45,
                12.457,
                12.463,
                12.47,
                12.477,
                12.483,
                12.49,
                12.496,
                12.503,
                12.509,
                12.516,
                12.523,
                12.529,
                12.536,
                12.542,
                12.549,
                12.555,
                12.562,
                12.568,
                12.575,
                12.581,
                12.588,
                12.594,
                12.601,
                12.607,
                12.614,
                12.62,
                12.627,
                12.633,
                12.64,
                12.646,
                12.653,
                12.659,
                12.666,
                12.672,
                12.679,
                12.685,
                12.692,
                12.698,
                12.705,
                12.711,
                12.718,
                12.724,
                12.731,
                12.737,
                12.743,
                12.75,
                12.756,
                12.763,
                12.769,
                12.776,
                12.782,
                12.788,
                12.795,
                12.801,
                12.808,
                12.814,
                12.82,
                12.827,
                12.833,
                12.84,
                12.846,
                12.852,
                12.859,
                12.865,
                12.872,
                12.878,
                12.884,
                12.891,
                12.897,
                12.903,
                12.91,
                12.916,
                12.922,
                12.929,
                12.935,
                12.942,
                12.948,
                12.954,
                12.961,
                12.967,
                12.973,
                12.98,
                12.986,
                12.992,
                12.999,
                13.005,
                13.011,
                13.017,
                13.024,
                13.03,
                13.036,
                13.043,
                13.049,
                13.055,
                13.062,
                13.068,
                13.074,
                13.08,
                13.087,
                13.093,
                13.099,
                13.106,
                13.112,
                13.118,
                13.125,
                13.131,
                13.137,
                13.143,
                13.15,
                13.156,
                13.162,
                13.168,
                13.175,
                13.181,
                13.187,
                13.193,
                13.2,
                13.206,
                13.212,
                13.219,
                13.225,
                13.231,
                13.237,
                13.244,
                13.25,
                13.256,
                13.262,
                13.268,
                13.275,
                13.281,
                13.287,
                13.293,
                13.3,
                13.306,
                13.312,
                13.318,
                13.325,
                13.331,
                13.337,
                13.343,
                13.35,
                13.356,
                13.362,
                13.368,
                13.374,
                13.381,
                13.387,
                13.393,
                13.399,
                13.406,
                13.412,
                13.418,
                13.424,
                13.43,
                13.437,
                13.443,
                13.449,
                13.455,
                13.461,
                13.468,
                13.474,
                13.48,
                13.486,
                13.493,
                13.499,
                13.505,
                13.511,
                13.517,
                13.524,
                13.53,
                13.536,
                13.542,
                13.548,
                13.555,
                13.561,
                13.567,
                13.573,
                13.579,
                13.586,
                13.592,
                13.598,
                13.604,
                13.61,
                13.617,
                13.623,
                13.629,
                13.635,
                13.641,
                13.648,
                13.654,
                13.66,
                13.666,
                13.672,
                13.679,
                13.685,
                13.691,
                13.697,
                13.703,
                13.71,
                13.716,
                13.722,
                13.728,
                13.734,
                13.74,
                13.747,
                13.753,
                13.759,
                13.765,
                13.771,
                13.778,
                13.784,
                13.79,
                13.796,
                13.802,
                13.809,
                13.815,
                13.821,
                13.827,
                13.833,
                13.84,
                13.846,
                13.852,
                13.858,
                13.864,
                13.87,
                13.877,
                13.883,
                13.889,
                13.895,
                13.901,
                13.908,
                13.914,
                13.92,
                13.926,
                13.932,
                13.938,
                13.945,
                13.951,
                13.957,
                13.963,
                13.969,
                13.976,
                13.982,
                13.988,
                13.994,
                14,
                14.006,
                14.013,
                14.019,
                14.025,
                14.031,
                14.037,
                14.044,
                14.05,
                14.056,
                14.062,
                14.068,
                14.074,
                14.081,
                14.087,
                14.093,
                14.099,
                14.105,
                14.112,
                14.118,
                14.124,
                14.13,
                14.136,
                14.142,
                14.149,
                14.155,
                14.161,
                14.167,
                14.173,
                14.179,
                14.186,
                14.192,
                14.198,
                14.204,
                14.21,
                14.216,
                14.223,
                14.229,
                14.235,
                14.241,
                14.247,
                14.253,
                14.26,
                14.266,
                14.272,
                14.278,
                14.284,
                14.29,
                14.297,
                14.303,
                14.309,
                14.315,
                14.321,
                14.327,
                14.334,
                14.34,
                14.346,
                14.352,
                14.358,
                14.364,
                14.371,
                14.377,
                14.383,
                14.389,
                14.395,
                14.401,
                14.408,
                14.414,
                14.42,
                14.426,
                14.432,
                14.438,
                14.444,
                14.451,
                14.457,
                14.463,
                14.469,
                14.475,
                14.481,
                14.487,
                14.494,
                14.5,
                14.506,
                14.512,
                14.518,
                14.524,
                14.53,
                14.537,
                14.543,
                14.549,
                14.555,
                14.561,
                14.567,
                14.573,
                14.58,
                14.586,
                14.592,
                14.598,
                14.604,
                14.61,
                14.616,
                14.623,
                14.629,
                14.635,
                14.641,
                14.647,
                14.653,
                14.659,
                14.665,
                14.672,
                14.678,
                14.684,
                14.69,
                14.696,
                14.702,
                14.708,
                14.714,
                14.72,
                14.727,
                14.733,
                14.739,
                14.745,
                14.751,
                14.757,
                14.763,
                14.769,
                14.775,
                14.782,
                14.788,
                14.794,
                14.8,
                14.806,
                14.812,
                14.818,
                14.824,
                14.83,
                14.837,
                14.843,
                14.849,
                14.855,
                14.861,
                14.867,
                14.873,
                14.879,
                14.885,
                14.891,
                14.897,
                14.904,
                14.91,
                14.916,
                14.922,
                14.928,
                14.934,
                14.94,
                14.946,
                14.952,
                14.958,
                14.964,
                14.97,
                14.977,
                14.983,
                14.989,
                14.995,
                15.001,
                15.007,
                15.013,
                15.019,
                15.025,
                15.031,
                15.037,
                15.043,
                15.049,
                15.056,
                15.062,
                15.068,
                15.074,
                15.08,
                15.086,
                15.092,
                15.098,
                15.104,
                15.11,
                15.116,
                15.122,
                15.128,
                15.134,
                15.14,
                15.146,
                15.152,
                15.158,
                15.165,
                15.171,
                15.177,
                15.183,
                15.189,
                15.195,
                15.201,
                15.207,
                15.213,
                15.219,
                15.225,
                15.231,
                15.237,
                15.243,
                15.249,
                15.255,
                15.261,
                15.267,
                15.273,
                15.279,
                15.285,
                15.291,
                15.297,
                15.303,
                15.309,
                15.315,
                15.321,
                15.328,
                15.334,
                15.34,
                15.346,
                15.352,
                15.358,
                15.364,
                15.37,
                15.376,
                15.382,
                15.388,
                15.394,
                15.4,
                15.406,
                15.412,
                15.418,
                15.424,
                15.43,
                15.436,
                15.442,
                15.448,
                15.454,
                15.46,
                15.466,
                15.472,
                15.478,
                15.484,
                15.49,
                15.496,
                15.502,
                15.508,
                15.514,
                15.52,
                15.526,
                15.532,
                15.538,
                15.544,
                15.55,
                15.556,
                15.562,
                15.568,
                15.574,
                15.58,
                15.586,
                15.592,
                15.598,
                15.604,
                15.61,
                15.616,
                15.622,
                15.628,
                15.634,
                15.64,
                15.646,
                15.652,
                15.658,
                15.664,
                15.67,
                15.676,
                15.682,
                15.688,
                15.694,
                15.7,
                15.706,
                15.712,
                15.718,
                15.724,
                15.73,
                15.736,
                15.742,
                15.748,
                15.753,
                15.759,
                15.765,
                15.771,
                15.777,
                15.783,
                15.789,
                15.795,
                15.801,
                15.807,
                15.813,
                15.819,
                15.825,
                15.831,
                15.837,
                15.843,
                15.849,
                15.855,
                15.861,
                15.867,
                15.873,
                15.879,
                15.885,
                15.891,
                15.897,
                15.903,
                15.909,
                15.915,
                15.921,
                15.927,
                15.933,
                15.939,
                15.945,
                15.951,
                15.957,
                15.962,
                15.968,
                15.974,
                15.98,
                15.986,
                15.992,
                15.998,
                16.004,
                16.01,
                16.016,
                16.022,
                16.028,
                16.034,
                16.04,
                16.046,
                16.052,
                16.058,
                16.064,
                16.07,
                16.076,
                16.082,
                16.088,
                16.094,
                16.099,
                16.106,
                16.112,
                16.117,
                16.123,
                16.129,
                16.135,
                16.141,
                16.147,
                16.153,
                16.159,
                16.165,
                16.171,
                16.177,
                16.183,
                16.189,
                16.195,
                16.201,
                16.207,
                16.213,
                16.219,
                16.225,
                16.231,
                16.237,
                16.243,
                16.249,
                16.254,
                16.26,
                16.266,
                16.272,
                16.278,
                16.284,
                16.29,
                16.296,
                16.302,
                16.308,
                16.314,
                16.32,
                16.326,
                16.332,
                16.338,
                16.344,
                16.35,
                16.356,
                16.362,
                16.368,
                16.374,
                16.379,
                16.385,
                16.391,
                16.397,
                16.403,
                16.409,
                16.415,
                16.421,
                16.427,
                16.433,
                16.439,
                16.445,
                16.451,
                16.457,
                16.463,
                16.469,
                16.475,
                16.481,
                16.487,
                16.493,
                16.498,
                16.504,
                16.51,
                16.516,
                16.522,
                16.528,
                16.534,
                16.54,
                16.546,
                16.552,
                16.558,
                16.564,
                16.57,
                16.576,
                16.582,
                16.588,
                16.594,
                16.6,
                16.606,
                16.611,
                16.617,
                16.623,
                16.629,
                16.635,
                16.641,
                16.647,
                16.653,
                16.659,
                16.665,
                16.671,
                16.677,
                16.683,
                16.689,
                16.695,
                16.701,
                16.707,
                16.713,
                16.718,
                16.724,
                16.73,
                16.736,
                16.742,
                16.748,
                16.754,
                16.76,
                16.766,
                16.772,
                16.778,
                16.784,
                16.79,
                16.796,
                16.802,
                16.808,
                16.814,
                16.819,
                16.825,
                16.831,
                16.837,
                16.843,
                16.849,
                16.855,
                16.861,
                16.867,
                16.873,
                16.879,
                16.885,
                16.891,
                16.897,
                16.903,
                16.909,
                16.914,
                16.92,
                16.926,
                16.932,
                16.938,
                16.944,
                16.95,
                16.956,
                16.962,
                16.968,
                16.974,
                16.98,
                16.986,
                16.992,
                16.997,
                17.003,
                17.009,
                17.015,
                17.021,
                17.027,
                17.033,
                17.039,
                17.045,
                17.051,
                17.057,
                17.063,
                17.069,
                17.074,
                17.08,
                17.086,
                17.092,
                17.098,
                17.104,
                17.11,
                17.116,
                17.122,
                17.128,
                17.134,
                17.14,
                17.146,
                17.151,
                17.157,
                17.163,
                17.169,
                17.175,
                17.181,
                17.187,
                17.193,
                17.199,
                17.205,
                17.211,
                17.216,
                17.222,
                17.228,
                17.234,
                17.24,
                17.246,
                17.252,
                17.258,
                17.264,
                17.27,
                17.275,
                17.281,
                17.287,
                17.293,
                17.299,
                17.305,
                17.311,
                17.317,
                17.323,
                17.329,
                17.334,
                17.34,
                17.346,
                17.352,
                17.358,
                17.364,
                17.37,
                17.376,
                17.382,
                17.387,
                17.393,
                17.399,
                17.405,
                17.411,
                17.417,
                17.423,
                17.429,
                17.434,
                17.44,
                17.446,
                17.452,
                17.458,
                17.464,
                17.47,
                17.476,
                17.481,
                17.487,
                17.493,
                17.499,
                17.505,
                17.511,
                17.517,
                17.522,
                17.528,
                17.534,
                17.54,
                17.546,
                17.552,
                17.558,
                17.563,
                17.569,
                17.575,
                17.581,
                17.587,
                17.593,
                17.599,
                17.604,
                17.61,
                17.616,
                17.622,
                17.628,
                17.634,
                17.639,
                17.645,
                17.651,
                17.657,
                17.663,
                17.669,
                17.675,
                17.68,
                17.686,
                17.692,
                17.698,
                17.704,
                17.71,
                17.715,
                17.721,
                17.727,
                17.733,
                17.739,
                17.744,
                17.75,
                17.756,
                17.762,
                17.768,
                17.774,
                17.779,
                17.785,
                17.791,
                17.797,
                17.803,
                17.808,
                17.814,
                17.82,
                17.826,
                17.832,
                17.837,
                17.843,
                17.849,
                17.855,
                17.861,
                17.866,
                17.872,
                17.878,
                17.884,
                17.89,
                17.895,
                17.901,
                17.907,
                17.913,
                17.919,
                17.924,
                17.93,
                17.936,
                17.942,
                17.948,
                17.953,
                17.959,
                17.965,
                17.971,
                17.976,
                17.982,
                17.988,
                17.994,
                18,
                18.005,
                18.011,
                18.017,
                18.023,
                18.028,
                18.034,
                18.04,
                18.046,
                18.051,
                18.057,
                18.063,
                18.069,
                18.074,
                18.08,
                18.086,
                18.092,
                18.097,
                18.103,
                18.109,
                18.115,
                18.12,
                18.126,
                18.132,
                18.138,
                18.143,
                18.149,
                18.155,
                18.161,
                18.166,
                18.172,
                18.178,
                18.184,
                18.189,
                18.195,
                18.201,
                18.206,
                18.212,
                18.218,
                18.224,
                18.229,
                18.235,
                18.241,
                18.247,
                18.252,
                18.258,
                18.264,
                18.269,
                18.275,
                18.281,
                18.287,
                18.292,
                18.298,
                18.304,
                18.309,
                18.315,
                18.321,
                18.326,
                18.332,
                18.338,
                18.344,
                18.349,
                18.355,
                18.361,
                18.366,
                18.372,
                18.378,
                18.383,
                18.389

            ],
            P75: [
                3.55,
                3.52,
                3.538,
                3.562,
                3.589,
                3.618,
                3.648,
                3.68,
                3.712,
                3.745,
                3.779,
                3.815,
                3.851,
                3.889,
                3.928,
                3.967,
                4.008,
                4.048,
                4.089,
                4.131,
                4.172,
                4.213,
                4.254,
                4.295,
                4.335,
                4.376,
                4.416,
                4.456,
                4.495,
                4.534,
                4.573,
                4.612,
                4.65,
                4.687,
                4.725,
                4.762,
                4.798,
                4.834,
                4.87,
                4.905,
                4.94,
                4.975,
                5.009,
                5.043,
                5.077,
                5.11,
                5.143,
                5.176,
                5.208,
                5.24,
                5.271,
                5.303,
                5.334,
                5.364,
                5.395,
                5.425,
                5.455,
                5.484,
                5.513,
                5.542,
                5.571,
                5.6,
                5.628,
                5.656,
                5.684,
                5.711,
                5.739,
                5.766,
                5.793,
                5.819,
                5.846,
                5.872,
                5.898,
                5.924,
                5.95,
                5.975,
                6,
                6.025,
                6.05,
                6.075,
                6.099,
                6.124,
                6.148,
                6.172,
                6.195,
                6.219,
                6.243,
                6.266,
                6.289,
                6.312,
                6.335,
                6.357,
                6.38,
                6.402,
                6.424,
                6.446,
                6.468,
                6.49,
                6.511,
                6.533,
                6.554,
                6.575,
                6.596,
                6.617,
                6.638,
                6.658,
                6.679,
                6.699,
                6.72,
                6.74,
                6.759,
                6.779,
                6.799,
                6.818,
                6.838,
                6.857,
                6.876,
                6.895,
                6.914,
                6.933,
                6.952,
                6.97,
                6.989,
                7.007,
                7.025,
                7.043,
                7.062,
                7.079,
                7.097,
                7.115,
                7.132,
                7.15,
                7.167,
                7.184,
                7.202,
                7.218,
                7.235,
                7.252,
                7.269,
                7.286,
                7.302,
                7.318,
                7.335,
                7.351,
                7.367,
                7.383,
                7.399,
                7.415,
                7.43,
                7.446,
                7.461,
                7.477,
                7.492,
                7.507,
                7.523,
                7.538,
                7.553,
                7.568,
                7.582,
                7.597,
                7.612,
                7.626,
                7.641,
                7.656,
                7.67,
                7.684,
                7.698,
                7.713,
                7.727,
                7.741,
                7.755,
                7.769,
                7.782,
                7.796,
                7.81,
                7.823,
                7.837,
                7.85,
                7.864,
                7.877,
                7.89,
                7.904,
                7.917,
                7.93,
                7.943,
                7.956,
                7.969,
                7.982,
                7.995,
                8.008,
                8.02,
                8.033,
                8.045,
                8.058,
                8.071,
                8.083,
                8.095,
                8.108,
                8.12,
                8.132,
                8.144,
                8.157,
                8.169,
                8.181,
                8.193,
                8.205,
                8.217,
                8.228,
                8.24,
                8.252,
                8.264,
                8.275,
                8.287,
                8.299,
                8.31,
                8.322,
                8.333,
                8.345,
                8.356,
                8.367,
                8.379,
                8.39,
                8.401,
                8.412,
                8.423,
                8.434,
                8.446,
                8.457,
                8.468,
                8.478,
                8.489,
                8.5,
                8.511,
                8.522,
                8.533,
                8.543,
                8.554,
                8.565,
                8.575,
                8.586,
                8.596,
                8.607,
                8.617,
                8.628,
                8.638,
                8.649,
                8.659,
                8.669,
                8.68,
                8.69,
                8.7,
                8.71,
                8.72,
                8.73,
                8.741,
                8.751,
                8.761,
                8.771,
                8.78,
                8.79,
                8.8,
                8.81,
                8.82,
                8.83,
                8.84,
                8.849,
                8.859,
                8.869,
                8.878,
                8.888,
                8.898,
                8.907,
                8.917,
                8.926,
                8.936,
                8.945,
                8.955,
                8.964,
                8.974,
                8.983,
                8.992,
                9.002,
                9.011,
                9.02,
                9.03,
                9.039,
                9.048,
                9.057,
                9.067,
                9.076,
                9.085,
                9.094,
                9.103,
                9.112,
                9.121,
                9.13,
                9.139,
                9.148,
                9.157,
                9.166,
                9.175,
                9.184,
                9.193,
                9.202,
                9.211,
                9.22,
                9.228,
                9.237,
                9.246,
                9.255,
                9.264,
                9.272,
                9.281,
                9.29,
                9.298,
                9.307,
                9.316,
                9.325,
                9.333,
                9.342,
                9.35,
                9.359,
                9.368,
                9.376,
                9.385,
                9.393,
                9.402,
                9.41,
                9.419,
                9.427,
                9.436,
                9.444,
                9.452,
                9.461,
                9.469,
                9.478,
                9.486,
                9.495,
                9.503,
                9.511,
                9.52,
                9.528,
                9.536,
                9.544,
                9.553,
                9.561,
                9.569,
                9.578,
                9.586,
                9.594,
                9.602,
                9.611,
                9.619,
                9.627,
                9.635,
                9.643,
                9.652,
                9.66,
                9.668,
                9.676,
                9.684,
                9.692,
                9.7,
                9.709,
                9.717,
                9.725,
                9.733,
                9.741,
                9.749,
                9.757,
                9.765,
                9.773,
                9.781,
                9.789,
                9.797,
                9.805,
                9.813,
                9.821,
                9.829,
                9.837,
                9.845,
                9.853,
                9.861,
                9.869,
                9.877,
                9.885,
                9.893,
                9.901,
                9.909,
                9.917,
                9.925,
                9.932,
                9.94,
                9.948,
                9.956,
                9.964,
                9.972,
                9.98,
                9.988,
                9.996,
                10.003,
                10.011,
                10.019,
                10.027,
                10.035,
                10.043,
                10.05,
                10.058,
                10.066,
                10.074,
                10.082,
                10.089,
                10.097,
                10.105,
                10.113,
                10.12,
                10.128,
                10.136,
                10.144,
                10.152,
                10.159,
                10.167,
                10.175,
                10.183,
                10.19,
                10.198,
                10.206,
                10.213,
                10.221,
                10.229,
                10.237,
                10.244,
                10.252,
                10.26,
                10.267,
                10.275,
                10.283,
                10.29,
                10.298,
                10.306,
                10.313,
                10.321,
                10.329,
                10.336,
                10.344,
                10.352,
                10.359,
                10.367,
                10.375,
                10.382,
                10.39,
                10.398,
                10.405,
                10.413,
                10.42,
                10.428,
                10.436,
                10.443,
                10.451,
                10.458,
                10.466,
                10.474,
                10.481,
                10.489,
                10.496,
                10.504,
                10.512,
                10.519,
                10.527,
                10.534,
                10.542,
                10.549,
                10.557,
                10.565,
                10.572,
                10.58,
                10.587,
                10.595,
                10.603,
                10.61,
                10.618,
                10.625,
                10.633,
                10.64,
                10.648,
                10.655,
                10.663,
                10.671,
                10.678,
                10.686,
                10.693,
                10.701,
                10.708,
                10.716,
                10.723,
                10.731,
                10.738,
                10.746,
                10.754,
                10.761,
                10.769,
                10.776,
                10.784,
                10.791,
                10.799,
                10.806,
                10.814,
                10.821,
                10.829,
                10.836,
                10.844,
                10.851,
                10.859,
                10.866,
                10.874,
                10.881,
                10.889,
                10.896,
                10.904,
                10.912,
                10.919,
                10.926,
                10.934,
                10.941,
                10.949,
                10.956,
                10.964,
                10.971,
                10.979,
                10.986,
                10.994,
                11.001,
                11.009,
                11.016,
                11.024,
                11.031,
                11.039,
                11.046,
                11.054,
                11.061,
                11.069,
                11.076,
                11.084,
                11.091,
                11.099,
                11.106,
                11.114,
                11.121,
                11.128,
                11.136,
                11.143,
                11.151,
                11.158,
                11.166,
                11.173,
                11.181,
                11.188,
                11.196,
                11.203,
                11.21,
                11.218,
                11.225,
                11.233,
                11.24,
                11.248,
                11.255,
                11.263,
                11.27,
                11.277,
                11.285,
                11.292,
                11.3,
                11.307,
                11.315,
                11.322,
                11.33,
                11.337,
                11.345,
                11.352,
                11.359,
                11.367,
                11.374,
                11.382,
                11.389,
                11.397,
                11.404,
                11.411,
                11.419,
                11.426,
                11.434,
                11.441,
                11.449,
                11.456,
                11.463,
                11.471,
                11.478,
                11.486,
                11.493,
                11.5,
                11.508,
                11.515,
                11.523,
                11.53,
                11.538,
                11.545,
                11.552,
                11.56,
                11.567,
                11.575,
                11.582,
                11.59,
                11.597,
                11.605,
                11.612,
                11.619,
                11.627,
                11.634,
                11.642,
                11.649,
                11.657,
                11.664,
                11.671,
                11.679,
                11.686,
                11.694,
                11.701,
                11.709,
                11.716,
                11.723,
                11.731,
                11.738,
                11.746,
                11.753,
                11.761,
                11.768,
                11.775,
                11.783,
                11.79,
                11.798,
                11.805,
                11.813,
                11.82,
                11.828,
                11.835,
                11.842,
                11.85,
                11.857,
                11.865,
                11.872,
                11.88,
                11.887,
                11.895,
                11.902,
                11.91,
                11.917,
                11.925,
                11.932,
                11.94,
                11.947,
                11.954,
                11.962,
                11.969,
                11.977,
                11.984,
                11.992,
                11.999,
                12.007,
                12.014,
                12.022,
                12.029,
                12.037,
                12.044,
                12.052,
                12.059,
                12.066,
                12.074,
                12.081,
                12.089,
                12.096,
                12.104,
                12.111,
                12.119,
                12.126,
                12.134,
                12.141,
                12.149,
                12.156,
                12.164,
                12.171,
                12.179,
                12.186,
                12.194,
                12.201,
                12.209,
                12.216,
                12.224,
                12.231,
                12.239,
                12.246,
                12.254,
                12.261,
                12.269,
                12.276,
                12.284,
                12.291,
                12.299,
                12.307,
                12.314,
                12.322,
                12.329,
                12.337,
                12.344,
                12.352,
                12.359,
                12.367,
                12.374,
                12.382,
                12.389,
                12.397,
                12.404,
                12.412,
                12.419,
                12.427,
                12.434,
                12.442,
                12.449,
                12.457,
                12.465,
                12.472,
                12.48,
                12.487,
                12.495,
                12.502,
                12.51,
                12.517,
                12.525,
                12.532,
                12.54,
                12.547,
                12.555,
                12.563,
                12.57,
                12.578,
                12.585,
                12.593,
                12.6,
                12.608,
                12.615,
                12.623,
                12.63,
                12.638,
                12.646,
                12.653,
                12.661,
                12.668,
                12.676,
                12.683,
                12.691,
                12.698,
                12.706,
                12.713,
                12.721,
                12.729,
                12.736,
                12.744,
                12.751,
                12.759,
                12.766,
                12.774,
                12.781,
                12.789,
                12.796,
                12.804,
                12.811,
                12.819,
                12.826,
                12.834,
                12.842,
                12.849,
                12.857,
                12.864,
                12.872,
                12.879,
                12.887,
                12.894,
                12.902,
                12.909,
                12.917,
                12.925,
                12.932,
                12.939,
                12.947,
                12.955,
                12.962,
                12.97,
                12.977,
                12.985,
                12.992,
                13,
                13.007,
                13.015,
                13.022,
                13.03,
                13.037,
                13.045,
                13.052,
                13.06,
                13.067,
                13.075,
                13.082,
                13.09,
                13.097,
                13.105,
                13.112,
                13.12,
                13.127,
                13.135,
                13.142,
                13.15,
                13.157,
                13.164,
                13.172,
                13.179,
                13.187,
                13.194,
                13.202,
                13.209,
                13.217,
                13.224,
                13.232,
                13.239,
                13.246,
                13.254,
                13.261,
                13.269,
                13.276,
                13.283,
                13.291,
                13.298,
                13.306,
                13.313,
                13.321,
                13.328,
                13.335,
                13.343,
                13.35,
                13.358,
                13.365,
                13.372,
                13.38,
                13.387,
                13.395,
                13.402,
                13.409,
                13.417,
                13.424,
                13.431,
                13.439,
                13.446,
                13.454,
                13.461,
                13.468,
                13.476,
                13.483,
                13.49,
                13.498,
                13.505,
                13.512,
                13.52,
                13.527,
                13.534,
                13.542,
                13.549,
                13.556,
                13.563,
                13.571,
                13.578,
                13.585,
                13.593,
                13.6,
                13.607,
                13.615,
                13.622,
                13.629,
                13.637,
                13.644,
                13.651,
                13.658,
                13.666,
                13.673,
                13.68,
                13.687,
                13.695,
                13.702,
                13.709,
                13.716,
                13.724,
                13.731,
                13.738,
                13.745,
                13.752,
                13.76,
                13.767,
                13.774,
                13.781,
                13.789,
                13.796,
                13.803,
                13.81,
                13.817,
                13.825,
                13.832,
                13.839,
                13.846,
                13.853,
                13.861,
                13.868,
                13.875,
                13.882,
                13.889,
                13.896,
                13.904,
                13.911,
                13.918,
                13.925,
                13.932,
                13.939,
                13.946,
                13.953,
                13.961,
                13.968,
                13.975,
                13.982,
                13.989,
                13.996,
                14.003,
                14.011,
                14.018,
                14.025,
                14.032,
                14.039,
                14.046,
                14.053,
                14.06,
                14.067,
                14.074,
                14.082,
                14.089,
                14.096,
                14.103,
                14.11,
                14.117,
                14.124,
                14.131,
                14.138,
                14.145,
                14.152,
                14.159,
                14.166,
                14.173,
                14.181,
                14.188,
                14.195,
                14.202,
                14.209,
                14.216,
                14.223,
                14.23,
                14.237,
                14.244,
                14.251,
                14.258,
                14.265,
                14.272,
                14.279,
                14.286,
                14.293,
                14.3,
                14.307,
                14.314,
                14.321,
                14.328,
                14.335,
                14.342,
                14.349,
                14.356,
                14.363,
                14.37,
                14.377,
                14.384,
                14.391,
                14.398,
                14.405,
                14.412,
                14.419,
                14.426,
                14.433,
                14.44,
                14.447,
                14.454,
                14.461,
                14.468,
                14.475,
                14.482,
                14.489,
                14.496,
                14.503,
                14.51,
                14.517,
                14.524,
                14.531,
                14.538,
                14.545,
                14.552,
                14.559,
                14.566,
                14.573,
                14.58,
                14.587,
                14.594,
                14.601,
                14.608,
                14.615,
                14.622,
                14.629,
                14.636,
                14.643,
                14.65,
                14.657,
                14.664,
                14.671,
                14.678,
                14.685,
                14.692,
                14.699,
                14.706,
                14.713,
                14.72,
                14.727,
                14.734,
                14.741,
                14.748,
                14.755,
                14.762,
                14.769,
                14.776,
                14.783,
                14.79,
                14.797,
                14.803,
                14.81,
                14.817,
                14.824,
                14.831,
                14.838,
                14.845,
                14.852,
                14.859,
                14.866,
                14.873,
                14.88,
                14.887,
                14.894,
                14.901,
                14.908,
                14.915,
                14.922,
                14.929,
                14.936,
                14.943,
                14.95,
                14.957,
                14.964,
                14.971,
                14.978,
                14.985,
                14.992,
                14.999,
                15.006,
                15.013,
                15.02,
                15.027,
                15.034,
                15.041,
                15.048,
                15.055,
                15.062,
                15.069,
                15.076,
                15.083,
                15.09,
                15.097,
                15.104,
                15.111,
                15.118,
                15.125,
                15.132,
                15.139,
                15.146,
                15.153,
                15.16,
                15.167,
                15.174,
                15.181,
                15.188,
                15.195,
                15.202,
                15.209,
                15.216,
                15.223,
                15.23,
                15.237,
                15.244,
                15.251,
                15.258,
                15.265,
                15.272,
                15.279,
                15.286,
                15.293,
                15.3,
                15.307,
                15.314,
                15.321,
                15.328,
                15.335,
                15.342,
                15.349,
                15.356,
                15.363,
                15.37,
                15.377,
                15.384,
                15.391,
                15.398,
                15.405,
                15.412,
                15.419,
                15.426,
                15.433,
                15.44,
                15.447,
                15.454,
                15.461,
                15.468,
                15.475,
                15.482,
                15.489,
                15.496,
                15.503,
                15.51,
                15.517,
                15.524,
                15.531,
                15.538,
                15.545,
                15.552,
                15.559,
                15.566,
                15.573,
                15.58,
                15.587,
                15.594,
                15.601,
                15.608,
                15.615,
                15.622,
                15.629,
                15.636,
                15.643,
                15.65,
                15.657,
                15.664,
                15.671,
                15.678,
                15.685,
                15.692,
                15.699,
                15.706,
                15.713,
                15.72,
                15.727,
                15.734,
                15.741,
                15.748,
                15.755,
                15.762,
                15.769,
                15.776,
                15.783,
                15.79,
                15.797,
                15.804,
                15.811,
                15.818,
                15.825,
                15.832,
                15.839,
                15.846,
                15.853,
                15.86,
                15.867,
                15.875,
                15.881,
                15.888,
                15.895,
                15.902,
                15.909,
                15.916,
                15.923,
                15.93,
                15.937,
                15.944,
                15.952,
                15.959,
                15.966,
                15.973,
                15.979,
                15.987,
                15.994,
                16,
                16.008,
                16.015,
                16.022,
                16.029,
                16.036,
                16.043,
                16.05,
                16.056,
                16.064,
                16.071,
                16.078,
                16.085,
                16.092,
                16.099,
                16.106,
                16.113,
                16.12,
                16.127,
                16.134,
                16.141,
                16.148,
                16.155,
                16.162,
                16.169,
                16.176,
                16.182,
                16.19,
                16.197,
                16.204,
                16.211,
                16.218,
                16.224,
                16.232,
                16.239,
                16.245,
                16.252,
                16.26,
                16.267,
                16.273,
                16.28,
                16.287,
                16.294,
                16.301,
                16.308,
                16.315,
                16.322,
                16.329,
                16.336,
                16.343,
                16.35,
                16.357,
                16.364,
                16.371,
                16.378,
                16.385,
                16.392,
                16.399,
                16.406,
                16.413,
                16.42,
                16.427,
                16.434,
                16.441,
                16.448,
                16.455,
                16.462,
                16.469,
                16.476,
                16.483,
                16.49,
                16.497,
                16.504,
                16.511,
                16.518,
                16.525,
                16.532,
                16.539,
                16.545,
                16.553,
                16.559,
                16.566,
                16.573,
                16.58,
                16.587,
                16.594,
                16.601,
                16.608,
                16.615,
                16.622,
                16.629,
                16.636,
                16.643,
                16.65,
                16.657,
                16.664,
                16.671,
                16.678,
                16.685,
                16.692,
                16.699,
                16.706,
                16.713,
                16.719,
                16.726,
                16.733,
                16.74,
                16.747,
                16.754,
                16.761,
                16.768,
                16.775,
                16.782,
                16.789,
                16.796,
                16.803,
                16.81,
                16.817,
                16.824,
                16.831,
                16.837,
                16.844,
                16.851,
                16.858,
                16.865,
                16.872,
                16.879,
                16.886,
                16.893,
                16.9,
                16.907,
                16.914,
                16.921,
                16.928,
                16.935,
                16.942,
                16.948,
                16.955,
                16.962,
                16.969,
                16.976,
                16.983,
                16.99,
                16.997,
                17.004,
                17.011,
                17.018,
                17.025,
                17.032,
                17.038,
                17.045,
                17.052,
                17.059,
                17.066,
                17.073,
                17.08,
                17.087,
                17.094,
                17.101,
                17.108,
                17.115,
                17.122,
                17.128,
                17.135,
                17.142,
                17.149,
                17.156,
                17.163,
                17.17,
                17.177,
                17.184,
                17.191,
                17.198,
                17.205,
                17.211,
                17.218,
                17.225,
                17.232,
                17.239,
                17.246,
                17.253,
                17.26,
                17.267,
                17.274,
                17.28,
                17.287,
                17.294,
                17.301,
                17.308,
                17.315,
                17.322,
                17.329,
                17.336,
                17.343,
                17.35,
                17.357,
                17.364,
                17.37,
                17.377,
                17.384,
                17.391,
                17.398,
                17.405,
                17.412,
                17.419,
                17.426,
                17.433,
                17.439,
                17.446,
                17.453,
                17.46,
                17.467,
                17.474,
                17.481,
                17.488,
                17.495,
                17.502,
                17.508,
                17.515,
                17.522,
                17.529,
                17.536,
                17.543,
                17.55,
                17.557,
                17.564,
                17.57,
                17.577,
                17.584,
                17.591,
                17.598,
                17.605,
                17.612,
                17.619,
                17.626,
                17.633,
                17.639,
                17.646,
                17.653,
                17.66,
                17.667,
                17.674,
                17.681,
                17.688,
                17.695,
                17.702,
                17.708,
                17.715,
                17.722,
                17.729,
                17.736,
                17.743,
                17.75,
                17.757,
                17.764,
                17.771,
                17.777,
                17.784,
                17.791,
                17.798,
                17.805,
                17.812,
                17.819,
                17.826,
                17.833,
                17.839,
                17.846,
                17.853,
                17.86,
                17.867,
                17.874,
                17.881,
                17.888,
                17.895,
                17.902,
                17.909,
                17.915,
                17.922,
                17.929,
                17.936,
                17.943,
                17.95,
                17.957,
                17.964,
                17.971,
                17.977,
                17.984,
                17.991,
                17.998,
                18.005,
                18.012,
                18.019,
                18.026,
                18.033,
                18.039,
                18.046,
                18.053,
                18.06,
                18.067,
                18.074,
                18.081,
                18.088,
                18.095,
                18.102,
                18.108,
                18.115,
                18.122,
                18.129,
                18.136,
                18.143,
                18.15,
                18.157,
                18.164,
                18.171,
                18.177,
                18.184,
                18.191,
                18.198,
                18.205,
                18.212,
                18.219,
                18.226,
                18.233,
                18.24,
                18.246,
                18.253,
                18.26,
                18.267,
                18.274,
                18.281,
                18.288,
                18.295,
                18.302,
                18.308,
                18.315,
                18.322,
                18.329,
                18.336,
                18.343,
                18.35,
                18.357,
                18.364,
                18.37,
                18.377,
                18.384,
                18.391,
                18.398,
                18.405,
                18.412,
                18.419,
                18.426,
                18.433,
                18.439,
                18.446,
                18.453,
                18.46,
                18.467,
                18.474,
                18.481,
                18.488,
                18.495,
                18.502,
                18.508,
                18.515,
                18.522,
                18.529,
                18.536,
                18.543,
                18.55,
                18.557,
                18.563,
                18.57,
                18.577,
                18.584,
                18.591,
                18.598,
                18.605,
                18.612,
                18.619,
                18.626,
                18.632,
                18.639,
                18.646,
                18.653,
                18.66,
                18.667,
                18.674,
                18.681,
                18.687,
                18.694,
                18.701,
                18.708,
                18.715,
                18.722,
                18.729,
                18.736,
                18.743,
                18.749,
                18.756,
                18.763,
                18.77,
                18.777,
                18.784,
                18.791,
                18.798,
                18.804,
                18.811,
                18.818,
                18.825,
                18.832,
                18.839,
                18.846,
                18.853,
                18.859,
                18.866,
                18.873,
                18.88,
                18.887,
                18.894,
                18.901,
                18.908,
                18.914,
                18.921,
                18.928,
                18.935,
                18.942,
                18.949,
                18.956,
                18.962,
                18.969,
                18.976,
                18.983,
                18.99,
                18.997,
                19.004,
                19.011,
                19.017,
                19.024,
                19.031,
                19.038,
                19.045,
                19.052,
                19.059,
                19.065,
                19.072,
                19.079,
                19.086,
                19.093,
                19.1,
                19.107,
                19.113,
                19.12,
                19.127,
                19.134,
                19.141,
                19.148,
                19.154,
                19.161,
                19.168,
                19.175,
                19.182,
                19.189,
                19.195,
                19.202,
                19.209,
                19.216,
                19.223,
                19.23,
                19.237,
                19.243,
                19.25,
                19.257,
                19.264,
                19.271,
                19.278,
                19.284,
                19.291,
                19.298,
                19.305,
                19.312,
                19.318,
                19.325,
                19.332,
                19.339,
                19.346,
                19.353,
                19.359,
                19.366,
                19.373,
                19.38,
                19.387,
                19.393,
                19.4,
                19.407,
                19.414,
                19.421,
                19.427,
                19.434,
                19.441,
                19.448,
                19.455,
                19.462,
                19.468,
                19.475,
                19.482,
                19.489,
                19.495,
                19.502,
                19.509,
                19.516,
                19.523,
                19.53,
                19.536,
                19.543,
                19.55,
                19.557,
                19.563,
                19.57,
                19.577,
                19.584,
                19.591,
                19.597,
                19.604,
                19.611,
                19.618,
                19.624,
                19.631,
                19.638,
                19.645,
                19.652,
                19.658,
                19.665,
                19.672,
                19.679,
                19.685,
                19.692,
                19.699,
                19.706,
                19.712,
                19.719,
                19.726,
                19.733,
                19.74,
                19.746,
                19.753,
                19.76,
                19.766,
                19.773,
                19.78,
                19.787,
                19.794,
                19.8,
                19.807,
                19.814,
                19.821,
                19.827,
                19.834,
                19.841,
                19.848,
                19.854,
                19.861,
                19.868,
                19.874,
                19.881,
                19.888,
                19.894,
                19.901,
                19.908,
                19.915,
                19.921,
                19.928,
                19.935,
                19.942,
                19.948,
                19.955,
                19.962,
                19.969,
                19.975,
                19.982,
                19.989,
                19.995,
                20.002,
                20.009,
                20.016,
                20.022,
                20.029,
                20.036,
                20.042,
                20.049,
                20.056,
                20.062,
                20.069,
                20.076,
                20.083,
                20.089,
                20.096,
                20.103,
                20.109,
                20.116,
                20.123,
                20.129,
                20.136,
                20.143,
                20.149,
                20.156,
                20.163,
                20.169,
                20.176,
                20.183,
                20.189,
                20.196,
                20.203,
                20.209,
                20.216,
                20.223,
                20.229,
                20.236,
                20.243,
                20.249,
                20.256,
                20.263,
                20.269,
                20.276,
                20.283,
                20.289,
                20.296,
                20.303,
                20.309,
                20.316,
                20.323,
                20.329,
                20.336,
                20.343,
                20.349,
                20.356,
                20.363,
                20.369

            ],
            P85: [
                3.729,
                3.704,
                3.723,
                3.749,
                3.778,
                3.808,
                3.84,
                3.873,
                3.906,
                3.941,
                3.977,
                4.014,
                4.052,
                4.091,
                4.131,
                4.172,
                4.214,
                4.257,
                4.299,
                4.342,
                4.385,
                4.428,
                4.47,
                4.513,
                4.555,
                4.597,
                4.638,
                4.68,
                4.721,
                4.761,
                4.802,
                4.842,
                4.881,
                4.92,
                4.959,
                4.997,
                5.035,
                5.072,
                5.11,
                5.146,
                5.183,
                5.219,
                5.254,
                5.289,
                5.324,
                5.359,
                5.393,
                5.427,
                5.46,
                5.493,
                5.526,
                5.559,
                5.591,
                5.623,
                5.654,
                5.685,
                5.716,
                5.747,
                5.777,
                5.807,
                5.837,
                5.866,
                5.896,
                5.925,
                5.954,
                5.982,
                6.011,
                6.039,
                6.067,
                6.095,
                6.122,
                6.149,
                6.176,
                6.203,
                6.23,
                6.256,
                6.282,
                6.308,
                6.334,
                6.36,
                6.385,
                6.411,
                6.436,
                6.46,
                6.485,
                6.51,
                6.534,
                6.558,
                6.582,
                6.606,
                6.629,
                6.653,
                6.676,
                6.7,
                6.723,
                6.746,
                6.768,
                6.791,
                6.813,
                6.836,
                6.858,
                6.88,
                6.901,
                6.923,
                6.945,
                6.966,
                6.987,
                7.009,
                7.03,
                7.051,
                7.071,
                7.092,
                7.112,
                7.133,
                7.153,
                7.173,
                7.193,
                7.213,
                7.232,
                7.252,
                7.271,
                7.291,
                7.31,
                7.329,
                7.348,
                7.367,
                7.386,
                7.404,
                7.423,
                7.441,
                7.459,
                7.477,
                7.496,
                7.513,
                7.531,
                7.549,
                7.566,
                7.584,
                7.601,
                7.619,
                7.636,
                7.653,
                7.67,
                7.687,
                7.703,
                7.72,
                7.737,
                7.753,
                7.77,
                7.786,
                7.802,
                7.818,
                7.834,
                7.85,
                7.866,
                7.882,
                7.897,
                7.913,
                7.928,
                7.944,
                7.959,
                7.974,
                7.989,
                8.004,
                8.019,
                8.034,
                8.049,
                8.064,
                8.079,
                8.093,
                8.108,
                8.122,
                8.137,
                8.151,
                8.165,
                8.18,
                8.194,
                8.208,
                8.222,
                8.236,
                8.25,
                8.264,
                8.277,
                8.291,
                8.305,
                8.318,
                8.332,
                8.345,
                8.359,
                8.372,
                8.385,
                8.399,
                8.412,
                8.425,
                8.438,
                8.451,
                8.464,
                8.477,
                8.49,
                8.503,
                8.515,
                8.528,
                8.541,
                8.553,
                8.566,
                8.578,
                8.591,
                8.603,
                8.616,
                8.628,
                8.64,
                8.653,
                8.665,
                8.677,
                8.689,
                8.701,
                8.713,
                8.725,
                8.737,
                8.749,
                8.761,
                8.772,
                8.784,
                8.796,
                8.807,
                8.819,
                8.831,
                8.842,
                8.854,
                8.865,
                8.877,
                8.888,
                8.9,
                8.911,
                8.922,
                8.933,
                8.945,
                8.956,
                8.967,
                8.978,
                8.989,
                9,
                9.011,
                9.022,
                9.033,
                9.044,
                9.055,
                9.066,
                9.076,
                9.087,
                9.098,
                9.109,
                9.119,
                9.13,
                9.14,
                9.151,
                9.162,
                9.172,
                9.182,
                9.193,
                9.203,
                9.214,
                9.224,
                9.234,
                9.245,
                9.255,
                9.265,
                9.275,
                9.285,
                9.296,
                9.306,
                9.316,
                9.326,
                9.336,
                9.346,
                9.356,
                9.366,
                9.376,
                9.386,
                9.395,
                9.405,
                9.415,
                9.425,
                9.435,
                9.445,
                9.454,
                9.464,
                9.474,
                9.483,
                9.493,
                9.503,
                9.512,
                9.522,
                9.531,
                9.541,
                9.55,
                9.56,
                9.569,
                9.579,
                9.588,
                9.598,
                9.607,
                9.616,
                9.626,
                9.635,
                9.644,
                9.654,
                9.663,
                9.672,
                9.682,
                9.691,
                9.7,
                9.709,
                9.718,
                9.727,
                9.737,
                9.746,
                9.755,
                9.764,
                9.773,
                9.782,
                9.791,
                9.8,
                9.809,
                9.818,
                9.827,
                9.836,
                9.845,
                9.854,
                9.863,
                9.872,
                9.881,
                9.89,
                9.898,
                9.907,
                9.916,
                9.925,
                9.934,
                9.943,
                9.951,
                9.96,
                9.969,
                9.978,
                9.986,
                9.995,
                10.004,
                10.013,
                10.021,
                10.03,
                10.039,
                10.047,
                10.056,
                10.065,
                10.073,
                10.082,
                10.09,
                10.099,
                10.108,
                10.116,
                10.125,
                10.133,
                10.142,
                10.151,
                10.159,
                10.168,
                10.176,
                10.185,
                10.193,
                10.202,
                10.21,
                10.218,
                10.227,
                10.236,
                10.244,
                10.252,
                10.261,
                10.269,
                10.278,
                10.286,
                10.294,
                10.303,
                10.311,
                10.32,
                10.328,
                10.336,
                10.345,
                10.353,
                10.361,
                10.37,
                10.378,
                10.386,
                10.395,
                10.403,
                10.411,
                10.419,
                10.428,
                10.436,
                10.444,
                10.453,
                10.461,
                10.469,
                10.477,
                10.486,
                10.494,
                10.502,
                10.51,
                10.518,
                10.527,
                10.535,
                10.543,
                10.551,
                10.559,
                10.568,
                10.576,
                10.584,
                10.592,
                10.6,
                10.608,
                10.617,
                10.625,
                10.633,
                10.641,
                10.649,
                10.657,
                10.665,
                10.674,
                10.682,
                10.69,
                10.698,
                10.706,
                10.714,
                10.722,
                10.73,
                10.738,
                10.746,
                10.755,
                10.763,
                10.771,
                10.779,
                10.787,
                10.795,
                10.803,
                10.811,
                10.819,
                10.827,
                10.835,
                10.843,
                10.851,
                10.859,
                10.867,
                10.875,
                10.883,
                10.891,
                10.899,
                10.907,
                10.915,
                10.923,
                10.931,
                10.939,
                10.947,
                10.955,
                10.963,
                10.971,
                10.979,
                10.987,
                10.995,
                11.003,
                11.011,
                11.019,
                11.027,
                11.035,
                11.043,
                11.051,
                11.059,
                11.067,
                11.075,
                11.083,
                11.09,
                11.098,
                11.106,
                11.114,
                11.122,
                11.13,
                11.138,
                11.146,
                11.154,
                11.162,
                11.17,
                11.178,
                11.186,
                11.193,
                11.201,
                11.209,
                11.217,
                11.225,
                11.233,
                11.241,
                11.249,
                11.257,
                11.265,
                11.273,
                11.281,
                11.288,
                11.296,
                11.304,
                11.312,
                11.32,
                11.328,
                11.336,
                11.344,
                11.352,
                11.359,
                11.367,
                11.375,
                11.383,
                11.391,
                11.399,
                11.407,
                11.415,
                11.423,
                11.43,
                11.438,
                11.446,
                11.454,
                11.462,
                11.47,
                11.478,
                11.486,
                11.493,
                11.501,
                11.509,
                11.517,
                11.525,
                11.533,
                11.541,
                11.548,
                11.556,
                11.564,
                11.572,
                11.58,
                11.588,
                11.595,
                11.603,
                11.611,
                11.619,
                11.627,
                11.635,
                11.642,
                11.65,
                11.658,
                11.666,
                11.674,
                11.682,
                11.689,
                11.697,
                11.705,
                11.713,
                11.721,
                11.729,
                11.736,
                11.744,
                11.752,
                11.76,
                11.768,
                11.775,
                11.783,
                11.791,
                11.799,
                11.807,
                11.815,
                11.822,
                11.83,
                11.838,
                11.846,
                11.854,
                11.861,
                11.869,
                11.877,
                11.885,
                11.893,
                11.901,
                11.908,
                11.916,
                11.924,
                11.932,
                11.94,
                11.947,
                11.955,
                11.963,
                11.971,
                11.979,
                11.986,
                11.994,
                12.002,
                12.01,
                12.017,
                12.025,
                12.033,
                12.041,
                12.049,
                12.057,
                12.064,
                12.072,
                12.08,
                12.088,
                12.096,
                12.103,
                12.111,
                12.119,
                12.127,
                12.135,
                12.142,
                12.15,
                12.158,
                12.166,
                12.174,
                12.181,
                12.189,
                12.197,
                12.205,
                12.213,
                12.221,
                12.228,
                12.236,
                12.244,
                12.252,
                12.26,
                12.267,
                12.275,
                12.283,
                12.291,
                12.299,
                12.306,
                12.314,
                12.322,
                12.33,
                12.338,
                12.346,
                12.353,
                12.361,
                12.369,
                12.377,
                12.385,
                12.392,
                12.4,
                12.408,
                12.416,
                12.424,
                12.432,
                12.44,
                12.447,
                12.455,
                12.463,
                12.471,
                12.479,
                12.487,
                12.494,
                12.502,
                12.51,
                12.518,
                12.526,
                12.534,
                12.542,
                12.549,
                12.557,
                12.565,
                12.573,
                12.581,
                12.589,
                12.597,
                12.604,
                12.612,
                12.62,
                12.628,
                12.636,
                12.644,
                12.652,
                12.66,
                12.667,
                12.675,
                12.683,
                12.691,
                12.699,
                12.707,
                12.715,
                12.723,
                12.73,
                12.738,
                12.746,
                12.754,
                12.762,
                12.77,
                12.778,
                12.786,
                12.794,
                12.802,
                12.809,
                12.817,
                12.825,
                12.833,
                12.841,
                12.849,
                12.857,
                12.865,
                12.873,
                12.88,
                12.889,
                12.896,
                12.904,
                12.912,
                12.92,
                12.928,
                12.936,
                12.944,
                12.952,
                12.96,
                12.968,
                12.976,
                12.983,
                12.991,
                12.999,
                13.007,
                13.015,
                13.023,
                13.031,
                13.039,
                13.047,
                13.055,
                13.063,
                13.071,
                13.079,
                13.087,
                13.095,
                13.103,
                13.11,
                13.118,
                13.126,
                13.134,
                13.142,
                13.15,
                13.158,
                13.166,
                13.174,
                13.182,
                13.19,
                13.198,
                13.206,
                13.214,
                13.222,
                13.23,
                13.238,
                13.246,
                13.253,
                13.261,
                13.269,
                13.277,
                13.285,
                13.293,
                13.301,
                13.309,
                13.317,
                13.325,
                13.333,
                13.341,
                13.349,
                13.357,
                13.365,
                13.373,
                13.381,
                13.389,
                13.397,
                13.404,
                13.413,
                13.42,
                13.428,
                13.436,
                13.444,
                13.452,
                13.46,
                13.468,
                13.476,
                13.484,
                13.492,
                13.5,
                13.508,
                13.516,
                13.524,
                13.532,
                13.54,
                13.548,
                13.556,
                13.564,
                13.571,
                13.579,
                13.587,
                13.595,
                13.603,
                13.611,
                13.619,
                13.627,
                13.635,
                13.643,
                13.651,
                13.659,
                13.667,
                13.674,
                13.682,
                13.69,
                13.698,
                13.706,
                13.714,
                13.722,
                13.73,
                13.738,
                13.746,
                13.754,
                13.761,
                13.769,
                13.777,
                13.785,
                13.793,
                13.801,
                13.809,
                13.817,
                13.824,
                13.832,
                13.84,
                13.848,
                13.856,
                13.864,
                13.872,
                13.88,
                13.887,
                13.895,
                13.903,
                13.911,
                13.919,
                13.927,
                13.935,
                13.942,
                13.95,
                13.958,
                13.966,
                13.974,
                13.982,
                13.989,
                13.997,
                14.005,
                14.013,
                14.021,
                14.028,
                14.036,
                14.044,
                14.052,
                14.06,
                14.067,
                14.075,
                14.083,
                14.091,
                14.099,
                14.106,
                14.114,
                14.122,
                14.13,
                14.137,
                14.145,
                14.153,
                14.161,
                14.169,
                14.176,
                14.184,
                14.192,
                14.199,
                14.207,
                14.215,
                14.223,
                14.23,
                14.238,
                14.246,
                14.254,
                14.261,
                14.269,
                14.277,
                14.284,
                14.292,
                14.3,
                14.308,
                14.315,
                14.323,
                14.331,
                14.338,
                14.346,
                14.354,
                14.361,
                14.369,
                14.377,
                14.384,
                14.392,
                14.4,
                14.407,
                14.415,
                14.423,
                14.43,
                14.438,
                14.446,
                14.453,
                14.461,
                14.469,
                14.476,
                14.484,
                14.492,
                14.499,
                14.507,
                14.514,
                14.522,
                14.53,
                14.537,
                14.545,
                14.552,
                14.56,
                14.568,
                14.575,
                14.583,
                14.59,
                14.598,
                14.606,
                14.613,
                14.621,
                14.628,
                14.636,
                14.643,
                14.651,
                14.658,
                14.666,
                14.674,
                14.681,
                14.689,
                14.696,
                14.704,
                14.711,
                14.719,
                14.726,
                14.734,
                14.741,
                14.749,
                14.757,
                14.764,
                14.772,
                14.779,
                14.787,
                14.794,
                14.802,
                14.809,
                14.817,
                14.824,
                14.832,
                14.839,
                14.847,
                14.854,
                14.862,
                14.869,
                14.877,
                14.884,
                14.892,
                14.899,
                14.907,
                14.914,
                14.922,
                14.929,
                14.937,
                14.944,
                14.952,
                14.959,
                14.966,
                14.974,
                14.982,
                14.989,
                14.996,
                15.004,
                15.012,
                15.019,
                15.026,
                15.034,
                15.041,
                15.049,
                15.056,
                15.064,
                15.071,
                15.079,
                15.086,
                15.094,
                15.101,
                15.108,
                15.116,
                15.123,
                15.131,
                15.138,
                15.146,
                15.153,
                15.161,
                15.168,
                15.175,
                15.183,
                15.19,
                15.198,
                15.205,
                15.213,
                15.22,
                15.228,
                15.235,
                15.243,
                15.25,
                15.257,
                15.265,
                15.272,
                15.28,
                15.287,
                15.295,
                15.302,
                15.309,
                15.317,
                15.324,
                15.332,
                15.339,
                15.347,
                15.354,
                15.362,
                15.369,
                15.377,
                15.384,
                15.391,
                15.399,
                15.406,
                15.414,
                15.421,
                15.429,
                15.436,
                15.444,
                15.451,
                15.458,
                15.466,
                15.473,
                15.481,
                15.488,
                15.496,
                15.503,
                15.51,
                15.518,
                15.525,
                15.533,
                15.54,
                15.548,
                15.555,
                15.562,
                15.57,
                15.577,
                15.585,
                15.592,
                15.6,
                15.607,
                15.615,
                15.622,
                15.63,
                15.637,
                15.645,
                15.652,
                15.659,
                15.667,
                15.674,
                15.682,
                15.689,
                15.697,
                15.704,
                15.712,
                15.719,
                15.727,
                15.734,
                15.741,
                15.749,
                15.756,
                15.764,
                15.771,
                15.779,
                15.786,
                15.794,
                15.801,
                15.809,
                15.816,
                15.824,
                15.831,
                15.839,
                15.846,
                15.854,
                15.861,
                15.868,
                15.876,
                15.883,
                15.891,
                15.898,
                15.906,
                15.913,
                15.921,
                15.928,
                15.936,
                15.943,
                15.951,
                15.958,
                15.966,
                15.973,
                15.981,
                15.988,
                15.996,
                16.003,
                16.011,
                16.018,
                16.026,
                16.033,
                16.041,
                16.048,
                16.056,
                16.063,
                16.071,
                16.078,
                16.086,
                16.093,
                16.101,
                16.108,
                16.116,
                16.123,
                16.131,
                16.138,
                16.146,
                16.153,
                16.161,
                16.168,
                16.176,
                16.183,
                16.191,
                16.198,
                16.206,
                16.213,
                16.221,
                16.228,
                16.236,
                16.243,
                16.251,
                16.258,
                16.266,
                16.273,
                16.281,
                16.288,
                16.296,
                16.303,
                16.311,
                16.318,
                16.326,
                16.333,
                16.341,
                16.348,
                16.356,
                16.364,
                16.371,
                16.378,
                16.386,
                16.394,
                16.401,
                16.409,
                16.416,
                16.424,
                16.431,
                16.439,
                16.446,
                16.454,
                16.461,
                16.469,
                16.476,
                16.484,
                16.491,
                16.499,
                16.507,
                16.514,
                16.522,
                16.529,
                16.537,
                16.544,
                16.552,
                16.559,
                16.567,
                16.574,
                16.582,
                16.589,
                16.597,
                16.604,
                16.612,
                16.62,
                16.627,
                16.635,
                16.642,
                16.65,
                16.657,
                16.665,
                16.672,
                16.68,
                16.687,
                16.695,
                16.702,
                16.71,
                16.717,
                16.725,
                16.733,
                16.74,
                16.748,
                16.755,
                16.763,
                16.77,
                16.778,
                16.785,
                16.793,
                16.8,
                16.808,
                16.815,
                16.823,
                16.831,
                16.838,
                16.846,
                16.853,
                16.861,
                16.868,
                16.876,
                16.883,
                16.891,
                16.898,
                16.906,
                16.914,
                16.921,
                16.929,
                16.936,
                16.944,
                16.951,
                16.959,
                16.966,
                16.974,
                16.981,
                16.989,
                16.996,
                17.004,
                17.011,
                17.019,
                17.027,
                17.034,
                17.042,
                17.049,
                17.057,
                17.064,
                17.072,
                17.079,
                17.087,
                17.094,
                17.102,
                17.109,
                17.117,
                17.125,
                17.132,
                17.14,
                17.147,
                17.154,
                17.162,
                17.17,
                17.177,
                17.185,
                17.192,
                17.2,
                17.207,
                17.215,
                17.222,
                17.23,
                17.237,
                17.245,
                17.252,
                17.26,
                17.267,
                17.275,
                17.283,
                17.29,
                17.298,
                17.305,
                17.313,
                17.32,
                17.328,
                17.335,
                17.343,
                17.35,
                17.358,
                17.365,
                17.373,
                17.38,
                17.388,
                17.395,
                17.403,
                17.41,
                17.418,
                17.425,
                17.433,
                17.44,
                17.448,
                17.455,
                17.463,
                17.471,
                17.478,
                17.486,
                17.493,
                17.501,
                17.508,
                17.516,
                17.523,
                17.531,
                17.538,
                17.546,
                17.553,
                17.561,
                17.568,
                17.576,
                17.583,
                17.591,
                17.598,
                17.606,
                17.613,
                17.621,
                17.628,
                17.636,
                17.643,
                17.651,
                17.658,
                17.666,
                17.673,
                17.681,
                17.688,
                17.696,
                17.703,
                17.711,
                17.718,
                17.726,
                17.733,
                17.741,
                17.748,
                17.756,
                17.763,
                17.771,
                17.778,
                17.786,
                17.793,
                17.801,
                17.808,
                17.816,
                17.823,
                17.831,
                17.838,
                17.846,
                17.853,
                17.861,
                17.868,
                17.876,
                17.883,
                17.891,
                17.898,
                17.906,
                17.913,
                17.921,
                17.928,
                17.936,
                17.943,
                17.951,
                17.958,
                17.966,
                17.973,
                17.981,
                17.988,
                17.996,
                18.003,
                18.011,
                18.018,
                18.025,
                18.033,
                18.041,
                18.048,
                18.055,
                18.063,
                18.071,
                18.078,
                18.085,
                18.093,
                18.1,
                18.108,
                18.115,
                18.123,
                18.13,
                18.138,
                18.145,
                18.153,
                18.16,
                18.168,
                18.175,
                18.183,
                18.19,
                18.198,
                18.205,
                18.213,
                18.22,
                18.227,
                18.235,
                18.242,
                18.25,
                18.258,
                18.265,
                18.272,
                18.28,
                18.287,
                18.295,
                18.302,
                18.31,
                18.317,
                18.325,
                18.332,
                18.34,
                18.347,
                18.355,
                18.362,
                18.369,
                18.377,
                18.385,
                18.392,
                18.399,
                18.407,
                18.414,
                18.422,
                18.429,
                18.437,
                18.444,
                18.452,
                18.459,
                18.467,
                18.474,
                18.482,
                18.489,
                18.497,
                18.504,
                18.512,
                18.519,
                18.527,
                18.534,
                18.542,
                18.549,
                18.557,
                18.564,
                18.571,
                18.579,
                18.586,
                18.594,
                18.601,
                18.609,
                18.616,
                18.624,
                18.631,
                18.639,
                18.646,
                18.654,
                18.661,
                18.669,
                18.676,
                18.684,
                18.691,
                18.698,
                18.706,
                18.714,
                18.721,
                18.728,
                18.736,
                18.743,
                18.751,
                18.758,
                18.766,
                18.773,
                18.781,
                18.788,
                18.796,
                18.803,
                18.811,
                18.818,
                18.826,
                18.833,
                18.841,
                18.848,
                18.856,
                18.863,
                18.87,
                18.878,
                18.885,
                18.893,
                18.9,
                18.908,
                18.915,
                18.923,
                18.93,
                18.938,
                18.945,
                18.953,
                18.96,
                18.968,
                18.975,
                18.983,
                18.99,
                18.998,
                19.005,
                19.013,
                19.02,
                19.027,
                19.035,
                19.043,
                19.05,
                19.057,
                19.065,
                19.072,
                19.08,
                19.087,
                19.095,
                19.102,
                19.11,
                19.117,
                19.125,
                19.132,
                19.14,
                19.147,
                19.155,
                19.162,
                19.17,
                19.177,
                19.185,
                19.192,
                19.2,
                19.207,
                19.215,
                19.222,
                19.23,
                19.237,
                19.244,
                19.252,
                19.26,
                19.267,
                19.274,
                19.282,
                19.289,
                19.297,
                19.304,
                19.312,
                19.319,
                19.327,
                19.334,
                19.342,
                19.349,
                19.357,
                19.364,
                19.372,
                19.379,
                19.387,
                19.394,
                19.402,
                19.409,
                19.417,
                19.424,
                19.432,
                19.439,
                19.446,
                19.454,
                19.461,
                19.469,
                19.476,
                19.484,
                19.491,
                19.499,
                19.506,
                19.514,
                19.521,
                19.529,
                19.536,
                19.544,
                19.551,
                19.559,
                19.566,
                19.574,
                19.581,
                19.589,
                19.596,
                19.603,
                19.611,
                19.619,
                19.626,
                19.634,
                19.641,
                19.648,
                19.656,
                19.663,
                19.671,
                19.678,
                19.686,
                19.693,
                19.701,
                19.708,
                19.716,
                19.723,
                19.731,
                19.738,
                19.746,
                19.753,
                19.76,
                19.768,
                19.775,
                19.783,
                19.79,
                19.798,
                19.805,
                19.813,
                19.82,
                19.828,
                19.835,
                19.843,
                19.85,
                19.858,
                19.865,
                19.873,
                19.88,
                19.887,
                19.895,
                19.902,
                19.91,
                19.917,
                19.925,
                19.932,
                19.94,
                19.947,
                19.955,
                19.962,
                19.969,
                19.977,
                19.984,
                19.992,
                20,
                20.007,
                20.014,
                20.022,
                20.029,
                20.037,
                20.044,
                20.052,
                20.059,
                20.067,
                20.074,
                20.082,
                20.089,
                20.096,
                20.104,
                20.111,
                20.119,
                20.126,
                20.133,
                20.141,
                20.149,
                20.156,
                20.163,
                20.171,
                20.178,
                20.186,
                20.193,
                20.201,
                20.208,
                20.215,
                20.223,
                20.23,
                20.238,
                20.245,
                20.253,
                20.26,
                20.268,
                20.275,
                20.282,
                20.29,
                20.297,
                20.305,
                20.312,
                20.32,
                20.327,
                20.334,
                20.342,
                20.349,
                20.357,
                20.364,
                20.371,
                20.379,
                20.386,
                20.394,
                20.401,
                20.409,
                20.416,
                20.424,
                20.431,
                20.438,
                20.446,
                20.453,
                20.46,
                20.468,
                20.475,
                20.483,
                20.49,
                20.498,
                20.505,
                20.512,
                20.52,
                20.527,
                20.535,
                20.542,
                20.549,
                20.557,
                20.564,
                20.571,
                20.579,
                20.586,
                20.594,
                20.601,
                20.609,
                20.616,
                20.623,
                20.631,
                20.638,
                20.645,
                20.653,
                20.66,
                20.667,
                20.675,
                20.682,
                20.69,
                20.697,
                20.704,
                20.712,
                20.719,
                20.727,
                20.734,
                20.741,
                20.749,
                20.756,
                20.763,
                20.771,
                20.778,
                20.785,
                20.793,
                20.8,
                20.808,
                20.815,
                20.822,
                20.83,
                20.837,
                20.844,
                20.852,
                20.859,
                20.866,
                20.874,
                20.881,
                20.888,
                20.896,
                20.903,
                20.91,
                20.918,
                20.925,
                20.933,
                20.94,
                20.947,
                20.955,
                20.962,
                20.969,
                20.976,
                20.984,
                20.991,
                20.998,
                21.006,
                21.013,
                21.021,
                21.028,
                21.035,
                21.042,
                21.05,
                21.057,
                21.064,
                21.072,
                21.079,
                21.086,
                21.094,
                21.101,
                21.108,
                21.116,
                21.123,
                21.13,
                21.138,
                21.145,
                21.152,
                21.159,
                21.167,
                21.174,
                21.181,
                21.189,
                21.196,
                21.203,
                21.211,
                21.218,
                21.225,
                21.232,
                21.24,
                21.247,
                21.254,
                21.262,
                21.269,
                21.276,
                21.283,
                21.291,
                21.298,
                21.305,
                21.313,
                21.32,
                21.327,
                21.334,
                21.342,
                21.349,
                21.356,
                21.363,
                21.371,
                21.378,
                21.385,
                21.392,
                21.4,
                21.407,
                21.414,
                21.421,
                21.429,
                21.436,
                21.443,
                21.451,
                21.458,
                21.465,
                21.472,
                21.479,
                21.487,
                21.494,
                21.501,
                21.509,
                21.516,
                21.523,
                21.53,
                21.537,
                21.545,
                21.552

            ],
            P90: [
                3.853,
                3.831,
                3.853,
                3.879,
                3.909,
                3.941,
                3.974,
                4.008,
                4.042,
                4.078,
                4.115,
                4.153,
                4.192,
                4.232,
                4.273,
                4.316,
                4.359,
                4.402,
                4.446,
                4.49,
                4.534,
                4.578,
                4.621,
                4.665,
                4.708,
                4.751,
                4.794,
                4.836,
                4.879,
                4.92,
                4.962,
                5.003,
                5.043,
                5.083,
                5.123,
                5.162,
                5.201,
                5.239,
                5.278,
                5.315,
                5.352,
                5.389,
                5.426,
                5.462,
                5.498,
                5.533,
                5.568,
                5.603,
                5.637,
                5.671,
                5.704,
                5.738,
                5.771,
                5.803,
                5.836,
                5.868,
                5.899,
                5.931,
                5.962,
                5.993,
                6.023,
                6.054,
                6.084,
                6.114,
                6.143,
                6.173,
                6.202,
                6.231,
                6.259,
                6.288,
                6.316,
                6.344,
                6.372,
                6.399,
                6.426,
                6.453,
                6.48,
                6.507,
                6.534,
                6.56,
                6.586,
                6.612,
                6.638,
                6.663,
                6.688,
                6.714,
                6.739,
                6.763,
                6.788,
                6.812,
                6.837,
                6.861,
                6.885,
                6.909,
                6.932,
                6.956,
                6.979,
                7.002,
                7.025,
                7.048,
                7.071,
                7.094,
                7.116,
                7.138,
                7.16,
                7.182,
                7.204,
                7.226,
                7.248,
                7.269,
                7.29,
                7.312,
                7.332,
                7.353,
                7.374,
                7.395,
                7.415,
                7.436,
                7.456,
                7.476,
                7.496,
                7.516,
                7.536,
                7.555,
                7.575,
                7.594,
                7.613,
                7.633,
                7.652,
                7.67,
                7.689,
                7.708,
                7.727,
                7.745,
                7.763,
                7.781,
                7.8,
                7.818,
                7.835,
                7.853,
                7.871,
                7.888,
                7.906,
                7.923,
                7.94,
                7.958,
                7.975,
                7.992,
                8.008,
                8.025,
                8.042,
                8.058,
                8.075,
                8.091,
                8.107,
                8.124,
                8.14,
                8.156,
                8.172,
                8.188,
                8.203,
                8.219,
                8.235,
                8.25,
                8.266,
                8.281,
                8.296,
                8.312,
                8.327,
                8.342,
                8.357,
                8.372,
                8.387,
                8.401,
                8.416,
                8.431,
                8.445,
                8.46,
                8.474,
                8.489,
                8.503,
                8.517,
                8.531,
                8.546,
                8.56,
                8.574,
                8.588,
                8.602,
                8.615,
                8.629,
                8.643,
                8.657,
                8.67,
                8.684,
                8.697,
                8.711,
                8.724,
                8.737,
                8.751,
                8.764,
                8.777,
                8.79,
                8.803,
                8.816,
                8.829,
                8.842,
                8.855,
                8.868,
                8.881,
                8.893,
                8.906,
                8.919,
                8.931,
                8.944,
                8.956,
                8.969,
                8.981,
                8.993,
                9.006,
                9.018,
                9.03,
                9.042,
                9.055,
                9.067,
                9.079,
                9.091,
                9.103,
                9.115,
                9.127,
                9.138,
                9.15,
                9.162,
                9.174,
                9.186,
                9.197,
                9.209,
                9.22,
                9.232,
                9.244,
                9.255,
                9.267,
                9.278,
                9.289,
                9.301,
                9.312,
                9.323,
                9.335,
                9.346,
                9.357,
                9.368,
                9.379,
                9.39,
                9.401,
                9.412,
                9.423,
                9.434,
                9.445,
                9.456,
                9.467,
                9.477,
                9.488,
                9.499,
                9.51,
                9.52,
                9.531,
                9.542,
                9.552,
                9.563,
                9.573,
                9.584,
                9.594,
                9.605,
                9.615,
                9.626,
                9.636,
                9.646,
                9.656,
                9.667,
                9.677,
                9.687,
                9.697,
                9.708,
                9.718,
                9.728,
                9.738,
                9.748,
                9.758,
                9.768,
                9.778,
                9.788,
                9.798,
                9.808,
                9.818,
                9.828,
                9.838,
                9.848,
                9.858,
                9.867,
                9.877,
                9.887,
                9.897,
                9.906,
                9.916,
                9.926,
                9.935,
                9.945,
                9.955,
                9.964,
                9.974,
                9.984,
                9.993,
                10.003,
                10.012,
                10.022,
                10.031,
                10.041,
                10.05,
                10.06,
                10.069,
                10.078,
                10.088,
                10.097,
                10.106,
                10.116,
                10.125,
                10.134,
                10.144,
                10.153,
                10.162,
                10.172,
                10.181,
                10.19,
                10.199,
                10.208,
                10.217,
                10.227,
                10.236,
                10.245,
                10.254,
                10.263,
                10.272,
                10.281,
                10.29,
                10.299,
                10.309,
                10.318,
                10.327,
                10.336,
                10.345,
                10.354,
                10.363,
                10.372,
                10.381,
                10.389,
                10.398,
                10.407,
                10.416,
                10.425,
                10.434,
                10.443,
                10.452,
                10.461,
                10.47,
                10.478,
                10.487,
                10.496,
                10.505,
                10.514,
                10.522,
                10.531,
                10.54,
                10.549,
                10.558,
                10.566,
                10.575,
                10.584,
                10.593,
                10.601,
                10.61,
                10.619,
                10.627,
                10.636,
                10.645,
                10.653,
                10.662,
                10.671,
                10.679,
                10.688,
                10.697,
                10.705,
                10.714,
                10.722,
                10.731,
                10.74,
                10.748,
                10.757,
                10.765,
                10.774,
                10.782,
                10.791,
                10.8,
                10.808,
                10.817,
                10.825,
                10.834,
                10.842,
                10.851,
                10.859,
                10.868,
                10.876,
                10.885,
                10.893,
                10.901,
                10.91,
                10.919,
                10.927,
                10.935,
                10.944,
                10.952,
                10.961,
                10.969,
                10.978,
                10.986,
                10.994,
                11.003,
                11.011,
                11.02,
                11.028,
                11.036,
                11.045,
                11.053,
                11.062,
                11.07,
                11.078,
                11.087,
                11.095,
                11.103,
                11.112,
                11.12,
                11.128,
                11.137,
                11.145,
                11.153,
                11.162,
                11.17,
                11.178,
                11.187,
                11.195,
                11.203,
                11.211,
                11.22,
                11.228,
                11.236,
                11.245,
                11.253,
                11.261,
                11.269,
                11.278,
                11.286,
                11.294,
                11.302,
                11.311,
                11.319,
                11.327,
                11.335,
                11.344,
                11.352,
                11.36,
                11.368,
                11.377,
                11.385,
                11.393,
                11.401,
                11.409,
                11.418,
                11.426,
                11.434,
                11.442,
                11.451,
                11.459,
                11.467,
                11.475,
                11.483,
                11.492,
                11.5,
                11.508,
                11.516,
                11.525,
                11.533,
                11.541,
                11.549,
                11.557,
                11.565,
                11.574,
                11.582,
                11.59,
                11.598,
                11.606,
                11.615,
                11.623,
                11.631,
                11.639,
                11.647,
                11.655,
                11.663,
                11.672,
                11.68,
                11.688,
                11.696,
                11.704,
                11.712,
                11.721,
                11.729,
                11.737,
                11.745,
                11.753,
                11.761,
                11.769,
                11.778,
                11.786,
                11.794,
                11.802,
                11.81,
                11.818,
                11.827,
                11.835,
                11.843,
                11.851,
                11.859,
                11.867,
                11.875,
                11.883,
                11.892,
                11.9,
                11.908,
                11.916,
                11.924,
                11.932,
                11.94,
                11.948,
                11.956,
                11.965,
                11.973,
                11.981,
                11.989,
                11.997,
                12.005,
                12.013,
                12.021,
                12.029,
                12.038,
                12.046,
                12.054,
                12.062,
                12.07,
                12.078,
                12.086,
                12.094,
                12.102,
                12.11,
                12.118,
                12.127,
                12.135,
                12.143,
                12.151,
                12.159,
                12.167,
                12.175,
                12.183,
                12.191,
                12.199,
                12.208,
                12.216,
                12.224,
                12.232,
                12.24,
                12.248,
                12.256,
                12.264,
                12.272,
                12.28,
                12.288,
                12.296,
                12.304,
                12.312,
                12.321,
                12.329,
                12.337,
                12.345,
                12.353,
                12.361,
                12.369,
                12.377,
                12.385,
                12.393,
                12.401,
                12.409,
                12.417,
                12.425,
                12.433,
                12.442,
                12.45,
                12.458,
                12.466,
                12.474,
                12.482,
                12.49,
                12.498,
                12.506,
                12.514,
                12.522,
                12.53,
                12.538,
                12.547,
                12.555,
                12.563,
                12.571,
                12.579,
                12.587,
                12.595,
                12.603,
                12.611,
                12.619,
                12.627,
                12.635,
                12.643,
                12.652,
                12.66,
                12.668,
                12.676,
                12.684,
                12.692,
                12.7,
                12.708,
                12.716,
                12.724,
                12.733,
                12.741,
                12.749,
                12.757,
                12.765,
                12.773,
                12.781,
                12.789,
                12.797,
                12.805,
                12.813,
                12.822,
                12.83,
                12.838,
                12.846,
                12.854,
                12.862,
                12.87,
                12.878,
                12.887,
                12.895,
                12.903,
                12.911,
                12.919,
                12.927,
                12.935,
                12.943,
                12.952,
                12.96,
                12.968,
                12.976,
                12.984,
                12.992,
                13,
                13.009,
                13.017,
                13.025,
                13.033,
                13.041,
                13.049,
                13.058,
                13.066,
                13.074,
                13.082,
                13.09,
                13.098,
                13.107,
                13.115,
                13.123,
                13.131,
                13.139,
                13.147,
                13.155,
                13.164,
                13.172,
                13.18,
                13.188,
                13.196,
                13.205,
                13.213,
                13.221,
                13.229,
                13.237,
                13.246,
                13.254,
                13.262,
                13.27,
                13.278,
                13.286,
                13.295,
                13.303,
                13.311,
                13.319,
                13.327,
                13.336,
                13.344,
                13.352,
                13.36,
                13.368,
                13.377,
                13.385,
                13.393,
                13.401,
                13.41,
                13.418,
                13.426,
                13.434,
                13.442,
                13.451,
                13.459,
                13.467,
                13.475,
                13.484,
                13.492,
                13.5,
                13.508,
                13.517,
                13.525,
                13.533,
                13.541,
                13.549,
                13.558,
                13.566,
                13.574,
                13.582,
                13.591,
                13.599,
                13.607,
                13.615,
                13.624,
                13.632,
                13.64,
                13.648,
                13.657,
                13.665,
                13.673,
                13.681,
                13.689,
                13.698,
                13.706,
                13.714,
                13.722,
                13.731,
                13.739,
                13.747,
                13.756,
                13.764,
                13.772,
                13.78,
                13.788,
                13.797,
                13.805,
                13.813,
                13.821,
                13.83,
                13.838,
                13.846,
                13.854,
                13.863,
                13.871,
                13.879,
                13.887,
                13.896,
                13.904,
                13.912,
                13.92,
                13.929,
                13.937,
                13.945,
                13.953,
                13.962,
                13.97,
                13.978,
                13.986,
                13.995,
                14.003,
                14.011,
                14.019,
                14.027,
                14.036,
                14.044,
                14.052,
                14.06,
                14.069,
                14.077,
                14.085,
                14.093,
                14.101,
                14.11,
                14.118,
                14.126,
                14.134,
                14.142,
                14.151,
                14.159,
                14.167,
                14.175,
                14.184,
                14.192,
                14.2,
                14.208,
                14.217,
                14.225,
                14.233,
                14.241,
                14.249,
                14.257,
                14.266,
                14.274,
                14.282,
                14.29,
                14.298,
                14.307,
                14.315,
                14.323,
                14.331,
                14.339,
                14.347,
                14.355,
                14.364,
                14.372,
                14.38,
                14.388,
                14.396,
                14.404,
                14.412,
                14.421,
                14.429,
                14.437,
                14.445,
                14.453,
                14.461,
                14.469,
                14.478,
                14.486,
                14.494,
                14.502,
                14.51,
                14.518,
                14.526,
                14.534,
                14.542,
                14.55,
                14.559,
                14.567,
                14.575,
                14.583,
                14.591,
                14.599,
                14.607,
                14.615,
                14.623,
                14.631,
                14.639,
                14.647,
                14.655,
                14.663,
                14.671,
                14.679,
                14.688,
                14.696,
                14.704,
                14.712,
                14.72,
                14.728,
                14.736,
                14.744,
                14.752,
                14.76,
                14.768,
                14.776,
                14.784,
                14.792,
                14.8,
                14.808,
                14.816,
                14.824,
                14.832,
                14.84,
                14.848,
                14.856,
                14.864,
                14.872,
                14.88,
                14.888,
                14.896,
                14.904,
                14.912,
                14.92,
                14.927,
                14.936,
                14.943,
                14.951,
                14.959,
                14.967,
                14.975,
                14.983,
                14.991,
                14.999,
                15.007,
                15.015,
                15.023,
                15.031,
                15.039,
                15.046,
                15.055,
                15.062,
                15.07,
                15.078,
                15.086,
                15.094,
                15.102,
                15.11,
                15.118,
                15.125,
                15.133,
                15.141,
                15.149,
                15.157,
                15.165,
                15.173,
                15.181,
                15.188,
                15.196,
                15.204,
                15.212,
                15.22,
                15.228,
                15.235,
                15.243,
                15.251,
                15.259,
                15.267,
                15.275,
                15.283,
                15.29,
                15.298,
                15.306,
                15.314,
                15.322,
                15.33,
                15.337,
                15.345,
                15.353,
                15.361,
                15.369,
                15.376,
                15.384,
                15.392,
                15.4,
                15.408,
                15.416,
                15.423,
                15.431,
                15.439,
                15.447,
                15.455,
                15.462,
                15.47,
                15.478,
                15.486,
                15.494,
                15.501,
                15.509,
                15.517,
                15.525,
                15.533,
                15.54,
                15.548,
                15.556,
                15.564,
                15.571,
                15.579,
                15.587,
                15.595,
                15.603,
                15.61,
                15.618,
                15.626,
                15.634,
                15.641,
                15.649,
                15.657,
                15.665,
                15.673,
                15.68,
                15.688,
                15.696,
                15.704,
                15.712,
                15.719,
                15.727,
                15.735,
                15.743,
                15.75,
                15.758,
                15.766,
                15.774,
                15.781,
                15.789,
                15.797,
                15.805,
                15.813,
                15.82,
                15.828,
                15.836,
                15.844,
                15.851,
                15.859,
                15.867,
                15.875,
                15.883,
                15.89,
                15.898,
                15.906,
                15.914,
                15.921,
                15.929,
                15.937,
                15.945,
                15.953,
                15.96,
                15.968,
                15.976,
                15.984,
                15.991,
                15.999,
                16.007,
                16.015,
                16.023,
                16.03,
                16.038,
                16.046,
                16.054,
                16.061,
                16.069,
                16.077,
                16.085,
                16.093,
                16.1,
                16.108,
                16.116,
                16.124,
                16.132,
                16.139,
                16.147,
                16.155,
                16.163,
                16.171,
                16.178,
                16.186,
                16.194,
                16.202,
                16.21,
                16.217,
                16.225,
                16.233,
                16.241,
                16.249,
                16.256,
                16.264,
                16.272,
                16.28,
                16.288,
                16.295,
                16.303,
                16.311,
                16.319,
                16.327,
                16.335,
                16.342,
                16.35,
                16.358,
                16.366,
                16.374,
                16.382,
                16.389,
                16.397,
                16.405,
                16.413,
                16.421,
                16.428,
                16.436,
                16.444,
                16.452,
                16.46,
                16.468,
                16.475,
                16.483,
                16.491,
                16.499,
                16.507,
                16.515,
                16.522,
                16.53,
                16.538,
                16.546,
                16.554,
                16.562,
                16.57,
                16.577,
                16.585,
                16.593,
                16.601,
                16.609,
                16.617,
                16.625,
                16.632,
                16.64,
                16.648,
                16.656,
                16.664,
                16.672,
                16.68,
                16.688,
                16.695,
                16.703,
                16.711,
                16.719,
                16.727,
                16.735,
                16.743,
                16.751,
                16.758,
                16.766,
                16.774,
                16.782,
                16.79,
                16.798,
                16.806,
                16.814,
                16.821,
                16.829,
                16.837,
                16.845,
                16.853,
                16.861,
                16.869,
                16.877,
                16.884,
                16.892,
                16.9,
                16.908,
                16.916,
                16.924,
                16.932,
                16.94,
                16.948,
                16.955,
                16.964,
                16.971,
                16.979,
                16.987,
                16.995,
                17.003,
                17.011,
                17.019,
                17.027,
                17.035,
                17.042,
                17.05,
                17.058,
                17.066,
                17.074,
                17.082,
                17.09,
                17.098,
                17.106,
                17.114,
                17.122,
                17.13,
                17.137,
                17.145,
                17.153,
                17.161,
                17.169,
                17.177,
                17.185,
                17.193,
                17.201,
                17.209,
                17.217,
                17.224,
                17.232,
                17.24,
                17.248,
                17.256,
                17.264,
                17.272,
                17.28,
                17.288,
                17.296,
                17.304,
                17.312,
                17.32,
                17.328,
                17.335,
                17.343,
                17.351,
                17.359,
                17.367,
                17.375,
                17.383,
                17.391,
                17.399,
                17.407,
                17.415,
                17.423,
                17.431,
                17.438,
                17.446,
                17.454,
                17.462,
                17.47,
                17.478,
                17.486,
                17.494,
                17.502,
                17.51,
                17.518,
                17.526,
                17.534,
                17.542,
                17.549,
                17.557,
                17.565,
                17.573,
                17.581,
                17.589,
                17.597,
                17.605,
                17.613,
                17.621,
                17.629,
                17.637,
                17.645,
                17.653,
                17.661,
                17.669,
                17.676,
                17.684,
                17.692,
                17.7,
                17.708,
                17.716,
                17.724,
                17.732,
                17.74,
                17.748,
                17.756,
                17.764,
                17.772,
                17.78,
                17.787,
                17.795,
                17.803,
                17.811,
                17.819,
                17.827,
                17.835,
                17.843,
                17.851,
                17.859,
                17.867,
                17.875,
                17.883,
                17.891,
                17.898,
                17.906,
                17.914,
                17.922,
                17.93,
                17.938,
                17.946,
                17.954,
                17.962,
                17.97,
                17.978,
                17.986,
                17.994,
                18.002,
                18.009,
                18.017,
                18.025,
                18.033,
                18.041,
                18.049,
                18.057,
                18.065,
                18.073,
                18.081,
                18.089,
                18.097,
                18.105,
                18.112,
                18.12,
                18.128,
                18.136,
                18.144,
                18.152,
                18.16,
                18.168,
                18.176,
                18.184,
                18.192,
                18.2,
                18.208,
                18.215,
                18.223,
                18.231,
                18.239,
                18.247,
                18.255,
                18.263,
                18.271,
                18.279,
                18.287,
                18.294,
                18.302,
                18.31,
                18.318,
                18.326,
                18.334,
                18.342,
                18.35,
                18.358,
                18.366,
                18.374,
                18.381,
                18.389,
                18.397,
                18.405,
                18.413,
                18.421,
                18.429,
                18.437,
                18.445,
                18.453,
                18.461,
                18.469,
                18.477,
                18.484,
                18.492,
                18.5,
                18.508,
                18.516,
                18.524,
                18.532,
                18.54,
                18.548,
                18.556,
                18.564,
                18.572,
                18.579,
                18.587,
                18.595,
                18.603,
                18.611,
                18.619,
                18.627,
                18.635,
                18.643,
                18.651,
                18.658,
                18.666,
                18.674,
                18.682,
                18.69,
                18.698,
                18.706,
                18.714,
                18.722,
                18.73,
                18.737,
                18.745,
                18.753,
                18.761,
                18.769,
                18.777,
                18.785,
                18.793,
                18.801,
                18.809,
                18.816,
                18.824,
                18.832,
                18.84,
                18.848,
                18.856,
                18.864,
                18.872,
                18.88,
                18.887,
                18.895,
                18.903,
                18.911,
                18.919,
                18.927,
                18.935,
                18.943,
                18.951,
                18.959,
                18.966,
                18.974,
                18.982,
                18.99,
                18.998,
                19.006,
                19.014,
                19.022,
                19.03,
                19.037,
                19.045,
                19.053,
                19.061,
                19.069,
                19.077,
                19.085,
                19.093,
                19.101,
                19.109,
                19.116,
                19.124,
                19.132,
                19.14,
                19.148,
                19.156,
                19.164,
                19.172,
                19.18,
                19.188,
                19.196,
                19.203,
                19.211,
                19.219,
                19.227,
                19.235,
                19.243,
                19.251,
                19.259,
                19.267,
                19.274,
                19.282,
                19.29,
                19.298,
                19.306,
                19.314,
                19.322,
                19.33,
                19.338,
                19.345,
                19.353,
                19.361,
                19.369,
                19.377,
                19.385,
                19.393,
                19.401,
                19.409,
                19.417,
                19.425,
                19.432,
                19.44,
                19.448,
                19.456,
                19.464,
                19.472,
                19.48,
                19.488,
                19.496,
                19.504,
                19.511,
                19.52,
                19.527,
                19.535,
                19.543,
                19.551,
                19.559,
                19.567,
                19.575,
                19.582,
                19.591,
                19.598,
                19.606,
                19.614,
                19.622,
                19.63,
                19.638,
                19.646,
                19.654,
                19.662,
                19.669,
                19.678,
                19.685,
                19.693,
                19.701,
                19.709,
                19.717,
                19.725,
                19.733,
                19.741,
                19.749,
                19.757,
                19.764,
                19.773,
                19.78,
                19.788,
                19.796,
                19.804,
                19.812,
                19.82,
                19.828,
                19.836,
                19.844,
                19.852,
                19.859,
                19.867,
                19.875,
                19.883,
                19.891,
                19.899,
                19.907,
                19.915,
                19.923,
                19.931,
                19.939,
                19.947,
                19.954,
                19.962,
                19.97,
                19.978,
                19.986,
                19.994,
                20.002,
                20.01,
                20.018,
                20.026,
                20.033,
                20.041,
                20.049,
                20.057,
                20.065,
                20.073,
                20.081,
                20.089,
                20.097,
                20.105,
                20.113,
                20.121,
                20.128,
                20.136,
                20.144,
                20.152,
                20.16,
                20.168,
                20.176,
                20.184,
                20.192,
                20.2,
                20.208,
                20.215,
                20.223,
                20.232,
                20.239,
                20.247,
                20.255,
                20.263,
                20.271,
                20.279,
                20.287,
                20.295,
                20.302,
                20.311,
                20.319,
                20.326,
                20.334,
                20.342,
                20.35,
                20.358,
                20.366,
                20.374,
                20.382,
                20.39,
                20.398,
                20.405,
                20.413,
                20.421,
                20.429,
                20.437,
                20.445,
                20.453,
                20.461,
                20.469,
                20.477,
                20.484,
                20.493,
                20.5,
                20.508,
                20.516,
                20.524,
                20.532,
                20.54,
                20.548,
                20.556,
                20.564,
                20.571,
                20.579,
                20.587,
                20.595,
                20.603,
                20.611,
                20.619,
                20.627,
                20.635,
                20.643,
                20.651,
                20.658,
                20.666,
                20.674,
                20.682,
                20.69,
                20.698,
                20.706,
                20.714,
                20.722,
                20.729,
                20.738,
                20.745,
                20.753,
                20.761,
                20.769,
                20.777,
                20.785,
                20.793,
                20.8,
                20.809,
                20.816,
                20.824,
                20.832,
                20.84,
                20.848,
                20.856,
                20.864,
                20.872,
                20.88,
                20.887,
                20.895,
                20.903,
                20.911,
                20.919,
                20.927,
                20.935,
                20.943,
                20.951,
                20.958,
                20.966,
                20.974,
                20.982,
                20.99,
                20.998,
                21.005,
                21.013,
                21.021,
                21.029,
                21.037,
                21.045,
                21.053,
                21.061,
                21.068,
                21.076,
                21.084,
                21.092,
                21.1,
                21.108,
                21.116,
                21.124,
                21.132,
                21.139,
                21.147,
                21.155,
                21.163,
                21.171,
                21.179,
                21.186,
                21.194,
                21.202,
                21.21,
                21.218,
                21.226,
                21.234,
                21.242,
                21.249,
                21.257,
                21.265,
                21.273,
                21.281,
                21.289,
                21.296,
                21.304,
                21.312,
                21.32,
                21.328,
                21.335,
                21.343,
                21.351,
                21.359,
                21.367,
                21.375,
                21.383,
                21.39,
                21.398,
                21.406,
                21.414,
                21.422,
                21.429,
                21.437,
                21.445,
                21.453,
                21.461,
                21.469,
                21.476,
                21.484,
                21.492,
                21.5,
                21.508,
                21.515,
                21.523,
                21.531,
                21.539,
                21.547,
                21.555,
                21.562,
                21.57,
                21.578,
                21.586,
                21.594,
                21.601,
                21.609,
                21.617,
                21.625,
                21.633,
                21.64,
                21.648,
                21.656,
                21.664,
                21.671,
                21.679,
                21.687,
                21.695,
                21.703,
                21.71,
                21.718,
                21.726,
                21.734,
                21.742,
                21.75,
                21.757,
                21.765,
                21.773,
                21.78,
                21.788,
                21.796,
                21.804,
                21.812,
                21.819,
                21.827,
                21.835,
                21.843,
                21.85,
                21.858,
                21.866,
                21.874,
                21.882,
                21.889,
                21.897,
                21.905,
                21.912,
                21.92,
                21.928,
                21.936,
                21.943,
                21.951,
                21.959,
                21.967,
                21.975,
                21.982,
                21.99,
                21.998,
                22.005,
                22.013,
                22.021,
                22.029,
                22.036,
                22.044,
                22.052,
                22.06,
                22.067,
                22.075,
                22.083,
                22.09,
                22.098,
                22.106,
                22.114,
                22.121,
                22.129,
                22.137,
                22.144,
                22.152,
                22.16,
                22.168,
                22.175,
                22.183,
                22.191,
                22.198,
                22.206,
                22.214,
                22.222,
                22.229,
                22.237,
                22.245,
                22.252,
                22.26,
                22.268,
                22.276,
                22.283,
                22.291,
                22.299,
                22.306,
                22.314,
                22.322,
                22.329,
                22.337,
                22.345,
                22.352,
                22.36,
                22.368,
                22.375,
                22.383,
                22.391,
                22.398,
                22.406

            ],
            P95: [
                4.041,
                4.026,
                4.049,
                4.079,
                4.11,
                4.144,
                4.178,
                4.214,
                4.25,
                4.287,
                4.326,
                4.365,
                4.406,
                4.448,
                4.491,
                4.535,
                4.58,
                4.625,
                4.671,
                4.716,
                4.762,
                4.808,
                4.853,
                4.899,
                4.944,
                4.988,
                5.033,
                5.077,
                5.12,
                5.164,
                5.207,
                5.249,
                5.291,
                5.333,
                5.374,
                5.415,
                5.456,
                5.495,
                5.535,
                5.574,
                5.613,
                5.651,
                5.689,
                5.726,
                5.763,
                5.8,
                5.837,
                5.873,
                5.908,
                5.944,
                5.978,
                6.013,
                6.047,
                6.081,
                6.115,
                6.148,
                6.181,
                6.213,
                6.246,
                6.278,
                6.31,
                6.341,
                6.372,
                6.403,
                6.434,
                6.465,
                6.495,
                6.525,
                6.555,
                6.584,
                6.614,
                6.643,
                6.672,
                6.7,
                6.728,
                6.757,
                6.785,
                6.812,
                6.84,
                6.867,
                6.894,
                6.921,
                6.948,
                6.975,
                7.001,
                7.027,
                7.053,
                7.079,
                7.105,
                7.13,
                7.155,
                7.18,
                7.205,
                7.23,
                7.255,
                7.279,
                7.303,
                7.328,
                7.352,
                7.375,
                7.399,
                7.423,
                7.446,
                7.469,
                7.492,
                7.515,
                7.538,
                7.561,
                7.583,
                7.605,
                7.627,
                7.65,
                7.671,
                7.693,
                7.715,
                7.737,
                7.758,
                7.779,
                7.8,
                7.821,
                7.842,
                7.863,
                7.883,
                7.904,
                7.924,
                7.944,
                7.964,
                7.984,
                8.004,
                8.024,
                8.043,
                8.063,
                8.082,
                8.102,
                8.121,
                8.14,
                8.158,
                8.177,
                8.196,
                8.215,
                8.233,
                8.251,
                8.269,
                8.288,
                8.306,
                8.324,
                8.341,
                8.359,
                8.377,
                8.394,
                8.412,
                8.429,
                8.446,
                8.463,
                8.48,
                8.497,
                8.514,
                8.531,
                8.547,
                8.564,
                8.58,
                8.597,
                8.613,
                8.629,
                8.645,
                8.662,
                8.678,
                8.694,
                8.709,
                8.725,
                8.741,
                8.756,
                8.772,
                8.787,
                8.803,
                8.818,
                8.833,
                8.849,
                8.864,
                8.879,
                8.894,
                8.909,
                8.924,
                8.939,
                8.953,
                8.968,
                8.983,
                8.997,
                9.012,
                9.026,
                9.04,
                9.055,
                9.069,
                9.083,
                9.097,
                9.111,
                9.125,
                9.14,
                9.153,
                9.167,
                9.181,
                9.195,
                9.209,
                9.222,
                9.236,
                9.249,
                9.263,
                9.276,
                9.29,
                9.303,
                9.316,
                9.33,
                9.343,
                9.356,
                9.369,
                9.382,
                9.395,
                9.408,
                9.421,
                9.434,
                9.447,
                9.46,
                9.473,
                9.485,
                9.498,
                9.511,
                9.523,
                9.536,
                9.548,
                9.561,
                9.573,
                9.586,
                9.598,
                9.61,
                9.623,
                9.635,
                9.647,
                9.659,
                9.672,
                9.684,
                9.696,
                9.708,
                9.72,
                9.732,
                9.743,
                9.755,
                9.767,
                9.779,
                9.791,
                9.803,
                9.814,
                9.826,
                9.837,
                9.849,
                9.861,
                9.872,
                9.884,
                9.895,
                9.906,
                9.918,
                9.929,
                9.941,
                9.952,
                9.963,
                9.974,
                9.985,
                9.997,
                10.008,
                10.019,
                10.03,
                10.041,
                10.052,
                10.063,
                10.074,
                10.085,
                10.096,
                10.107,
                10.118,
                10.128,
                10.139,
                10.15,
                10.161,
                10.171,
                10.182,
                10.193,
                10.203,
                10.214,
                10.225,
                10.235,
                10.246,
                10.256,
                10.267,
                10.277,
                10.287,
                10.298,
                10.308,
                10.319,
                10.329,
                10.34,
                10.35,
                10.36,
                10.37,
                10.381,
                10.391,
                10.401,
                10.411,
                10.421,
                10.432,
                10.442,
                10.452,
                10.462,
                10.472,
                10.482,
                10.492,
                10.502,
                10.512,
                10.522,
                10.532,
                10.542,
                10.552,
                10.562,
                10.572,
                10.582,
                10.592,
                10.601,
                10.611,
                10.621,
                10.631,
                10.64,
                10.65,
                10.66,
                10.67,
                10.679,
                10.689,
                10.699,
                10.709,
                10.718,
                10.728,
                10.737,
                10.747,
                10.757,
                10.766,
                10.776,
                10.785,
                10.795,
                10.805,
                10.814,
                10.824,
                10.833,
                10.842,
                10.852,
                10.862,
                10.871,
                10.88,
                10.89,
                10.899,
                10.909,
                10.918,
                10.927,
                10.937,
                10.946,
                10.956,
                10.965,
                10.974,
                10.984,
                10.993,
                11.002,
                11.012,
                11.021,
                11.03,
                11.039,
                11.048,
                11.058,
                11.067,
                11.076,
                11.085,
                11.095,
                11.104,
                11.113,
                11.122,
                11.131,
                11.14,
                11.15,
                11.159,
                11.168,
                11.177,
                11.186,
                11.195,
                11.204,
                11.213,
                11.223,
                11.232,
                11.241,
                11.25,
                11.259,
                11.268,
                11.277,
                11.286,
                11.295,
                11.304,
                11.313,
                11.322,
                11.331,
                11.34,
                11.349,
                11.358,
                11.367,
                11.376,
                11.385,
                11.394,
                11.403,
                11.412,
                11.42,
                11.43,
                11.438,
                11.447,
                11.456,
                11.465,
                11.474,
                11.483,
                11.492,
                11.501,
                11.509,
                11.518,
                11.527,
                11.536,
                11.545,
                11.554,
                11.563,
                11.571,
                11.58,
                11.589,
                11.598,
                11.607,
                11.615,
                11.624,
                11.633,
                11.642,
                11.651,
                11.659,
                11.668,
                11.677,
                11.686,
                11.694,
                11.703,
                11.712,
                11.721,
                11.73,
                11.738,
                11.747,
                11.756,
                11.765,
                11.773,
                11.782,
                11.791,
                11.799,
                11.808,
                11.817,
                11.825,
                11.834,
                11.843,
                11.851,
                11.86,
                11.869,
                11.877,
                11.886,
                11.895,
                11.904,
                11.912,
                11.921,
                11.93,
                11.938,
                11.947,
                11.955,
                11.964,
                11.973,
                11.982,
                11.99,
                11.999,
                12.007,
                12.016,
                12.025,
                12.033,
                12.042,
                12.051,
                12.059,
                12.068,
                12.077,
                12.085,
                12.094,
                12.102,
                12.111,
                12.12,
                12.128,
                12.137,
                12.145,
                12.154,
                12.163,
                12.171,
                12.18,
                12.188,
                12.197,
                12.206,
                12.214,
                12.223,
                12.231,
                12.24,
                12.249,
                12.257,
                12.266,
                12.274,
                12.283,
                12.291,
                12.3,
                12.309,
                12.317,
                12.326,
                12.334,
                12.343,
                12.352,
                12.36,
                12.369,
                12.377,
                12.386,
                12.394,
                12.403,
                12.411,
                12.42,
                12.429,
                12.437,
                12.446,
                12.454,
                12.463,
                12.471,
                12.48,
                12.488,
                12.497,
                12.505,
                12.514,
                12.522,
                12.531,
                12.54,
                12.548,
                12.557,
                12.565,
                12.574,
                12.582,
                12.591,
                12.599,
                12.608,
                12.616,
                12.625,
                12.633,
                12.642,
                12.65,
                12.659,
                12.667,
                12.676,
                12.684,
                12.693,
                12.701,
                12.71,
                12.719,
                12.727,
                12.735,
                12.744,
                12.753,
                12.761,
                12.77,
                12.778,
                12.786,
                12.795,
                12.804,
                12.812,
                12.821,
                12.829,
                12.838,
                12.846,
                12.855,
                12.863,
                12.872,
                12.88,
                12.889,
                12.897,
                12.905,
                12.914,
                12.923,
                12.931,
                12.939,
                12.948,
                12.957,
                12.965,
                12.974,
                12.982,
                12.99,
                12.999,
                13.008,
                13.016,
                13.024,
                13.033,
                13.042,
                13.05,
                13.058,
                13.067,
                13.075,
                13.084,
                13.093,
                13.101,
                13.109,
                13.118,
                13.126,
                13.135,
                13.144,
                13.152,
                13.16,
                13.169,
                13.178,
                13.186,
                13.195,
                13.203,
                13.211,
                13.22,
                13.229,
                13.237,
                13.246,
                13.254,
                13.263,
                13.271,
                13.28,
                13.288,
                13.297,
                13.305,
                13.314,
                13.322,
                13.331,
                13.339,
                13.348,
                13.356,
                13.365,
                13.373,
                13.382,
                13.391,
                13.399,
                13.408,
                13.416,
                13.425,
                13.433,
                13.442,
                13.45,
                13.459,
                13.467,
                13.476,
                13.485,
                13.493,
                13.502,
                13.51,
                13.519,
                13.528,
                13.536,
                13.545,
                13.553,
                13.562,
                13.57,
                13.579,
                13.587,
                13.596,
                13.604,
                13.613,
                13.622,
                13.63,
                13.639,
                13.648,
                13.656,
                13.665,
                13.673,
                13.682,
                13.691,
                13.699,
                13.708,
                13.716,
                13.725,
                13.734,
                13.742,
                13.751,
                13.759,
                13.768,
                13.777,
                13.785,
                13.794,
                13.803,
                13.811,
                13.82,
                13.829,
                13.837,
                13.846,
                13.854,
                13.863,
                13.872,
                13.88,
                13.889,
                13.898,
                13.906,
                13.915,
                13.923,
                13.932,
                13.941,
                13.949,
                13.958,
                13.967,
                13.975,
                13.984,
                13.993,
                14.001,
                14.01,
                14.019,
                14.027,
                14.036,
                14.045,
                14.053,
                14.062,
                14.071,
                14.079,
                14.088,
                14.097,
                14.105,
                14.114,
                14.123,
                14.131,
                14.14,
                14.149,
                14.157,
                14.166,
                14.175,
                14.184,
                14.192,
                14.201,
                14.21,
                14.218,
                14.227,
                14.236,
                14.244,
                14.253,
                14.262,
                14.27,
                14.279,
                14.288,
                14.297,
                14.305,
                14.314,
                14.323,
                14.331,
                14.34,
                14.349,
                14.357,
                14.366,
                14.375,
                14.384,
                14.392,
                14.401,
                14.41,
                14.418,
                14.427,
                14.436,
                14.444,
                14.453,
                14.462,
                14.471,
                14.479,
                14.488,
                14.497,
                14.505,
                14.514,
                14.523,
                14.532,
                14.54,
                14.549,
                14.558,
                14.567,
                14.575,
                14.584,
                14.593,
                14.601,
                14.61,
                14.619,
                14.628,
                14.636,
                14.645,
                14.654,
                14.663,
                14.671,
                14.68,
                14.689,
                14.697,
                14.706,
                14.715,
                14.723,
                14.732,
                14.741,
                14.749,
                14.758,
                14.767,
                14.775,
                14.784,
                14.793,
                14.802,
                14.81,
                14.819,
                14.828,
                14.836,
                14.845,
                14.854,
                14.862,
                14.871,
                14.88,
                14.888,
                14.897,
                14.906,
                14.915,
                14.923,
                14.932,
                14.941,
                14.949,
                14.958,
                14.967,
                14.975,
                14.984,
                14.992,
                15.001,
                15.01,
                15.019,
                15.027,
                15.036,
                15.044,
                15.053,
                15.062,
                15.07,
                15.079,
                15.088,
                15.096,
                15.105,
                15.114,
                15.122,
                15.131,
                15.139,
                15.148,
                15.157,
                15.165,
                15.174,
                15.182,
                15.191,
                15.2,
                15.208,
                15.217,
                15.225,
                15.234,
                15.243,
                15.251,
                15.26,
                15.268,
                15.277,
                15.286,
                15.294,
                15.303,
                15.311,
                15.32,
                15.328,
                15.337,
                15.346,
                15.354,
                15.363,
                15.371,
                15.38,
                15.388,
                15.397,
                15.405,
                15.414,
                15.422,
                15.431,
                15.44,
                15.448,
                15.456,
                15.465,
                15.474,
                15.482,
                15.491,
                15.499,
                15.507,
                15.516,
                15.524,
                15.533,
                15.542,
                15.55,
                15.558,
                15.567,
                15.575,
                15.584,
                15.592,
                15.601,
                15.609,
                15.618,
                15.626,
                15.635,
                15.643,
                15.652,
                15.66,
                15.668,
                15.677,
                15.685,
                15.694,
                15.702,
                15.711,
                15.719,
                15.727,
                15.736,
                15.744,
                15.753,
                15.761,
                15.77,
                15.778,
                15.787,
                15.795,
                15.803,
                15.812,
                15.82,
                15.828,
                15.837,
                15.845,
                15.854,
                15.862,
                15.871,
                15.879,
                15.887,
                15.896,
                15.904,
                15.912,
                15.921,
                15.929,
                15.938,
                15.946,
                15.954,
                15.963,
                15.971,
                15.979,
                15.988,
                15.996,
                16.005,
                16.013,
                16.021,
                16.029,
                16.038,
                16.046,
                16.054,
                16.063,
                16.071,
                16.079,
                16.088,
                16.096,
                16.104,
                16.113,
                16.121,
                16.13,
                16.138,
                16.146,
                16.154,
                16.163,
                16.171,
                16.18,
                16.188,
                16.196,
                16.204,
                16.213,
                16.221,
                16.229,
                16.237,
                16.246,
                16.254,
                16.263,
                16.271,
                16.279,
                16.288,
                16.296,
                16.304,
                16.312,
                16.321,
                16.329,
                16.337,
                16.346,
                16.354,
                16.362,
                16.371,
                16.379,
                16.387,
                16.395,
                16.404,
                16.412,
                16.42,
                16.429,
                16.437,
                16.445,
                16.454,
                16.462,
                16.47,
                16.478,
                16.487,
                16.495,
                16.503,
                16.511,
                16.52,
                16.528,
                16.537,
                16.545,
                16.553,
                16.561,
                16.57,
                16.578,
                16.586,
                16.595,
                16.603,
                16.611,
                16.619,
                16.628,
                16.636,
                16.644,
                16.653,
                16.661,
                16.669,
                16.678,
                16.686,
                16.694,
                16.702,
                16.711,
                16.719,
                16.727,
                16.736,
                16.744,
                16.752,
                16.761,
                16.769,
                16.777,
                16.786,
                16.794,
                16.802,
                16.811,
                16.819,
                16.827,
                16.836,
                16.844,
                16.852,
                16.86,
                16.869,
                16.877,
                16.885,
                16.894,
                16.902,
                16.911,
                16.919,
                16.927,
                16.936,
                16.944,
                16.952,
                16.961,
                16.969,
                16.977,
                16.986,
                16.994,
                17.002,
                17.011,
                17.019,
                17.027,
                17.036,
                17.044,
                17.052,
                17.061,
                17.069,
                17.078,
                17.086,
                17.094,
                17.103,
                17.111,
                17.119,
                17.128,
                17.136,
                17.145,
                17.153,
                17.161,
                17.17,
                17.178,
                17.186,
                17.195,
                17.203,
                17.212,
                17.22,
                17.228,
                17.237,
                17.245,
                17.254,
                17.262,
                17.27,
                17.279,
                17.287,
                17.296,
                17.304,
                17.312,
                17.321,
                17.329,
                17.338,
                17.346,
                17.354,
                17.363,
                17.371,
                17.38,
                17.388,
                17.396,
                17.405,
                17.413,
                17.422,
                17.43,
                17.439,
                17.447,
                17.455,
                17.464,
                17.472,
                17.481,
                17.489,
                17.498,
                17.506,
                17.514,
                17.523,
                17.531,
                17.54,
                17.548,
                17.557,
                17.565,
                17.574,
                17.582,
                17.591,
                17.599,
                17.608,
                17.616,
                17.624,
                17.633,
                17.641,
                17.65,
                17.658,
                17.667,
                17.675,
                17.684,
                17.692,
                17.701,
                17.709,
                17.718,
                17.726,
                17.734,
                17.743,
                17.752,
                17.76,
                17.769,
                17.777,
                17.786,
                17.794,
                17.803,
                17.811,
                17.819,
                17.828,
                17.836,
                17.845,
                17.853,
                17.862,
                17.87,
                17.879,
                17.887,
                17.896,
                17.905,
                17.913,
                17.921,
                17.93,
                17.939,
                17.947,
                17.956,
                17.964,
                17.973,
                17.981,
                17.99,
                17.998,
                18.007,
                18.015,
                18.024,
                18.032,
                18.041,
                18.049,
                18.058,
                18.066,
                18.075,
                18.083,
                18.092,
                18.1,
                18.109,
                18.118,
                18.126,
                18.135,
                18.143,
                18.152,
                18.16,
                18.169,
                18.177,
                18.186,
                18.194,
                18.203,
                18.211,
                18.22,
                18.228,
                18.237,
                18.245,
                18.254,
                18.263,
                18.271,
                18.28,
                18.288,
                18.297,
                18.305,
                18.314,
                18.323,
                18.331,
                18.34,
                18.348,
                18.357,
                18.365,
                18.374,
                18.382,
                18.391,
                18.4,
                18.408,
                18.417,
                18.425,
                18.434,
                18.442,
                18.451,
                18.459,
                18.468,
                18.476,
                18.485,
                18.494,
                18.502,
                18.511,
                18.519,
                18.528,
                18.536,
                18.545,
                18.554,
                18.562,
                18.571,
                18.579,
                18.588,
                18.596,
                18.605,
                18.613,
                18.622,
                18.631,
                18.639,
                18.648,
                18.656,
                18.665,
                18.673,
                18.682,
                18.691,
                18.699,
                18.708,
                18.716,
                18.725,
                18.733,
                18.742,
                18.751,
                18.759,
                18.768,
                18.776,
                18.785,
                18.793,
                18.802,
                18.811,
                18.819,
                18.828,
                18.836,
                18.845,
                18.854,
                18.862,
                18.871,
                18.879,
                18.888,
                18.896,
                18.905,
                18.914,
                18.922,
                18.931,
                18.939,
                18.948,
                18.956,
                18.965,
                18.974,
                18.982,
                18.991,
                18.999,
                19.008,
                19.016,
                19.025,
                19.034,
                19.042,
                19.051,
                19.059,
                19.068,
                19.077,
                19.085,
                19.094,
                19.102,
                19.111,
                19.119,
                19.128,
                19.137,
                19.145,
                19.154,
                19.162,
                19.171,
                19.18,
                19.188,
                19.197,
                19.205,
                19.214,
                19.222,
                19.231,
                19.24,
                19.248,
                19.257,
                19.265,
                19.274,
                19.282,
                19.291,
                19.299,
                19.308,
                19.317,
                19.325,
                19.334,
                19.342,
                19.351,
                19.36,
                19.368,
                19.377,
                19.385,
                19.394,
                19.403,
                19.411,
                19.419,
                19.428,
                19.437,
                19.445,
                19.454,
                19.462,
                19.471,
                19.48,
                19.488,
                19.497,
                19.505,
                19.514,
                19.523,
                19.531,
                19.54,
                19.548,
                19.557,
                19.565,
                19.574,
                19.583,
                19.591,
                19.6,
                19.608,
                19.617,
                19.626,
                19.634,
                19.643,
                19.651,
                19.66,
                19.668,
                19.677,
                19.685,
                19.694,
                19.703,
                19.711,
                19.72,
                19.728,
                19.737,
                19.746,
                19.754,
                19.762,
                19.771,
                19.78,
                19.788,
                19.797,
                19.805,
                19.814,
                19.823,
                19.831,
                19.84,
                19.848,
                19.857,
                19.865,
                19.874,
                19.883,
                19.891,
                19.9,
                19.908,
                19.917,
                19.925,
                19.934,
                19.943,
                19.951,
                19.96,
                19.968,
                19.977,
                19.986,
                19.994,
                20.003,
                20.011,
                20.02,
                20.028,
                20.037,
                20.045,
                20.054,
                20.063,
                20.071,
                20.08,
                20.088,
                20.097,
                20.106,
                20.114,
                20.122,
                20.131,
                20.14,
                20.148,
                20.157,
                20.166,
                20.174,
                20.183,
                20.191,
                20.2,
                20.208,
                20.217,
                20.226,
                20.234,
                20.243,
                20.251,
                20.26,
                20.269,
                20.277,
                20.286,
                20.294,
                20.303,
                20.311,
                20.32,
                20.329,
                20.337,
                20.346,
                20.354,
                20.363,
                20.372,
                20.38,
                20.389,
                20.397,
                20.406,
                20.414,
                20.423,
                20.432,
                20.44,
                20.449,
                20.457,
                20.466,
                20.475,
                20.483,
                20.492,
                20.5,
                20.509,
                20.517,
                20.526,
                20.535,
                20.543,
                20.552,
                20.56,
                20.569,
                20.578,
                20.586,
                20.595,
                20.603,
                20.612,
                20.62,
                20.629,
                20.638,
                20.647,
                20.655,
                20.664,
                20.672,
                20.681,
                20.689,
                20.698,
                20.707,
                20.715,
                20.724,
                20.732,
                20.741,
                20.75,
                20.758,
                20.767,
                20.775,
                20.784,
                20.793,
                20.801,
                20.81,
                20.818,
                20.827,
                20.835,
                20.844,
                20.853,
                20.861,
                20.87,
                20.879,
                20.887,
                20.896,
                20.904,
                20.913,
                20.921,
                20.93,
                20.939,
                20.947,
                20.956,
                20.965,
                20.973,
                20.982,
                20.99,
                20.999,
                21.007,
                21.016,
                21.025,
                21.033,
                21.042,
                21.051,
                21.06,
                21.068,
                21.077,
                21.085,
                21.094,
                21.102,
                21.111,
                21.119,
                21.128,
                21.137,
                21.145,
                21.154,
                21.163,
                21.171,
                21.18,
                21.189,
                21.197,
                21.206,
                21.214,
                21.223,
                21.232,
                21.24,
                21.249,
                21.257,
                21.266,
                21.274,
                21.283,
                21.292,
                21.3,
                21.309,
                21.317,
                21.326,
                21.335,
                21.343,
                21.352,
                21.361,
                21.369,
                21.378,
                21.387,
                21.395,
                21.404,
                21.413,
                21.421,
                21.43,
                21.438,
                21.447,
                21.456,
                21.464,
                21.473,
                21.481,
                21.49,
                21.499,
                21.507,
                21.516,
                21.525,
                21.533,
                21.542,
                21.55,
                21.559,
                21.568,
                21.576,
                21.585,
                21.594,
                21.602,
                21.611,
                21.619,
                21.628,
                21.636,
                21.645,
                21.654,
                21.662,
                21.671,
                21.68,
                21.688,
                21.697,
                21.705,
                21.714,
                21.723,
                21.731,
                21.74,
                21.748,
                21.757,
                21.765,
                21.774,
                21.783,
                21.791,
                21.8,
                21.808,
                21.817,
                21.826,
                21.834,
                21.843,
                21.852,
                21.86,
                21.869,
                21.877,
                21.886,
                21.895,
                21.903,
                21.912,
                21.92,
                21.929,
                21.938,
                21.946,
                21.955,
                21.964,
                21.972,
                21.981,
                21.989,
                21.998,
                22.006,
                22.015,
                22.024,
                22.032,
                22.041,
                22.049,
                22.058,
                22.067,
                22.075,
                22.084,
                22.093,
                22.101,
                22.11,
                22.118,
                22.127,
                22.136,
                22.144,
                22.153,
                22.161,
                22.17,
                22.178,
                22.187,
                22.195,
                22.204,
                22.213,
                22.221,
                22.23,
                22.238,
                22.247,
                22.256,
                22.264,
                22.273,
                22.281,
                22.29,
                22.298,
                22.307,
                22.316,
                22.324,
                22.333,
                22.341,
                22.35,
                22.358,
                22.367,
                22.376,
                22.384,
                22.393,
                22.401,
                22.41,
                22.419,
                22.427,
                22.436,
                22.444,
                22.453,
                22.461,
                22.47,
                22.479,
                22.487,
                22.496,
                22.504,
                22.513,
                22.522,
                22.53,
                22.539,
                22.547,
                22.556,
                22.564,
                22.572,
                22.581,
                22.589,
                22.598,
                22.607,
                22.615,
                22.624,
                22.632,
                22.641,
                22.65,
                22.658,
                22.667,
                22.675,
                22.684,
                22.692,
                22.701,
                22.709,
                22.718,
                22.726,
                22.735,
                22.743,
                22.752,
                22.761,
                22.769,
                22.778,
                22.786,
                22.795,
                22.803,
                22.812,
                22.82,
                22.829,
                22.837,
                22.845,
                22.854,
                22.863,
                22.871,
                22.88,
                22.888,
                22.897,
                22.905,
                22.914,
                22.922,
                22.931,
                22.939,
                22.948,
                22.956,
                22.965,
                22.973,
                22.982,
                22.99,
                22.999,
                23.007,
                23.016,
                23.024,
                23.033,
                23.041,
                23.05,
                23.058,
                23.067,
                23.075,
                23.084,
                23.092,
                23.101,
                23.109,
                23.118,
                23.126,
                23.134,
                23.143,
                23.152,
                23.16,
                23.168,
                23.177,
                23.186,
                23.194,
                23.203,
                23.211,
                23.219,
                23.228,
                23.236,
                23.245,
                23.253,
                23.261,
                23.27,
                23.279,
                23.287,
                23.295,
                23.304,
                23.312,
                23.321,
                23.329,
                23.338,
                23.346,
                23.355,
                23.363,
                23.372,
                23.38,
                23.388,
                23.397,
                23.405,
                23.413,
                23.422,
                23.43,
                23.439,
                23.447,
                23.456,
                23.464,
                23.473,
                23.481,
                23.49,
                23.498,
                23.507,
                23.515,
                23.523,
                23.532,
                23.54,
                23.549,
                23.557,
                23.565,
                23.574,
                23.582,
                23.59,
                23.599,
                23.607,
                23.616,
                23.624,
                23.633,
                23.641,
                23.649,
                23.658,
                23.666,
                23.675,
                23.683,
                23.692,
                23.7,
                23.708,
                23.717,
                23.725,
                23.734,
                23.742,
                23.75,
                23.759

            ],
            P97: [
                4.166,
                4.155,
                4.181,
                4.212,
                4.245,
                4.279,
                4.315,
                4.352,
                4.389,
                4.428,
                4.467,
                4.508,
                4.55,
                4.593,
                4.637,
                4.682,
                4.728,
                4.775,
                4.821,
                4.868,
                4.915,
                4.962,
                5.009,
                5.055,
                5.101,
                5.147,
                5.193,
                5.238,
                5.283,
                5.327,
                5.371,
                5.415,
                5.458,
                5.501,
                5.543,
                5.585,
                5.626,
                5.667,
                5.708,
                5.748,
                5.788,
                5.827,
                5.866,
                5.904,
                5.942,
                5.98,
                6.017,
                6.054,
                6.09,
                6.127,
                6.162,
                6.198,
                6.233,
                6.268,
                6.302,
                6.336,
                6.37,
                6.403,
                6.436,
                6.469,
                6.502,
                6.534,
                6.566,
                6.598,
                6.63,
                6.661,
                6.692,
                6.723,
                6.753,
                6.784,
                6.814,
                6.843,
                6.873,
                6.903,
                6.932,
                6.96,
                6.989,
                7.018,
                7.046,
                7.074,
                7.102,
                7.129,
                7.157,
                7.184,
                7.211,
                7.238,
                7.265,
                7.291,
                7.318,
                7.344,
                7.369,
                7.395,
                7.421,
                7.446,
                7.472,
                7.497,
                7.522,
                7.547,
                7.571,
                7.596,
                7.62,
                7.644,
                7.668,
                7.692,
                7.716,
                7.739,
                7.763,
                7.786,
                7.809,
                7.832,
                7.855,
                7.878,
                7.9,
                7.922,
                7.945,
                7.967,
                7.989,
                8.011,
                8.032,
                8.054,
                8.075,
                8.096,
                8.118,
                8.139,
                8.16,
                8.18,
                8.201,
                8.221,
                8.242,
                8.262,
                8.282,
                8.302,
                8.322,
                8.342,
                8.362,
                8.381,
                8.401,
                8.42,
                8.439,
                8.458,
                8.477,
                8.496,
                8.515,
                8.533,
                8.552,
                8.571,
                8.589,
                8.607,
                8.625,
                8.643,
                8.661,
                8.679,
                8.697,
                8.714,
                8.732,
                8.749,
                8.766,
                8.784,
                8.801,
                8.818,
                8.835,
                8.852,
                8.869,
                8.885,
                8.902,
                8.919,
                8.935,
                8.951,
                8.968,
                8.984,
                9,
                9.016,
                9.032,
                9.048,
                9.064,
                9.08,
                9.096,
                9.111,
                9.127,
                9.143,
                9.158,
                9.173,
                9.189,
                9.204,
                9.219,
                9.234,
                9.25,
                9.264,
                9.279,
                9.294,
                9.309,
                9.324,
                9.339,
                9.353,
                9.368,
                9.382,
                9.397,
                9.411,
                9.426,
                9.44,
                9.454,
                9.468,
                9.483,
                9.497,
                9.511,
                9.525,
                9.539,
                9.553,
                9.567,
                9.58,
                9.594,
                9.608,
                9.622,
                9.635,
                9.649,
                9.662,
                9.676,
                9.689,
                9.703,
                9.716,
                9.729,
                9.742,
                9.756,
                9.769,
                9.782,
                9.795,
                9.808,
                9.821,
                9.834,
                9.847,
                9.86,
                9.873,
                9.885,
                9.898,
                9.911,
                9.924,
                9.936,
                9.949,
                9.961,
                9.974,
                9.986,
                9.999,
                10.011,
                10.023,
                10.036,
                10.048,
                10.06,
                10.073,
                10.085,
                10.097,
                10.109,
                10.121,
                10.133,
                10.145,
                10.157,
                10.169,
                10.181,
                10.193,
                10.204,
                10.216,
                10.228,
                10.24,
                10.251,
                10.263,
                10.275,
                10.286,
                10.298,
                10.309,
                10.321,
                10.332,
                10.344,
                10.355,
                10.367,
                10.378,
                10.389,
                10.401,
                10.412,
                10.423,
                10.434,
                10.445,
                10.457,
                10.468,
                10.479,
                10.49,
                10.501,
                10.512,
                10.523,
                10.534,
                10.545,
                10.556,
                10.567,
                10.578,
                10.589,
                10.599,
                10.61,
                10.621,
                10.632,
                10.643,
                10.653,
                10.664,
                10.675,
                10.685,
                10.696,
                10.706,
                10.717,
                10.728,
                10.738,
                10.749,
                10.759,
                10.77,
                10.78,
                10.791,
                10.801,
                10.811,
                10.822,
                10.832,
                10.843,
                10.853,
                10.863,
                10.874,
                10.884,
                10.894,
                10.904,
                10.915,
                10.925,
                10.935,
                10.945,
                10.955,
                10.965,
                10.976,
                10.986,
                10.996,
                11.006,
                11.016,
                11.026,
                11.036,
                11.046,
                11.056,
                11.066,
                11.076,
                11.086,
                11.096,
                11.106,
                11.116,
                11.126,
                11.135,
                11.145,
                11.155,
                11.165,
                11.175,
                11.185,
                11.195,
                11.204,
                11.214,
                11.224,
                11.234,
                11.243,
                11.253,
                11.263,
                11.273,
                11.282,
                11.292,
                11.302,
                11.311,
                11.321,
                11.331,
                11.34,
                11.35,
                11.36,
                11.369,
                11.379,
                11.388,
                11.398,
                11.407,
                11.417,
                11.427,
                11.436,
                11.446,
                11.455,
                11.465,
                11.474,
                11.484,
                11.493,
                11.503,
                11.512,
                11.522,
                11.531,
                11.54,
                11.55,
                11.559,
                11.569,
                11.578,
                11.588,
                11.597,
                11.606,
                11.616,
                11.625,
                11.634,
                11.644,
                11.653,
                11.662,
                11.672,
                11.681,
                11.69,
                11.7,
                11.709,
                11.718,
                11.728,
                11.737,
                11.746,
                11.755,
                11.765,
                11.774,
                11.783,
                11.792,
                11.801,
                11.811,
                11.82,
                11.829,
                11.838,
                11.848,
                11.857,
                11.866,
                11.875,
                11.884,
                11.893,
                11.903,
                11.912,
                11.921,
                11.93,
                11.939,
                11.948,
                11.957,
                11.966,
                11.976,
                11.985,
                11.994,
                12.003,
                12.012,
                12.021,
                12.03,
                12.039,
                12.048,
                12.057,
                12.066,
                12.076,
                12.085,
                12.094,
                12.103,
                12.112,
                12.121,
                12.13,
                12.139,
                12.148,
                12.157,
                12.166,
                12.175,
                12.184,
                12.193,
                12.202,
                12.211,
                12.22,
                12.229,
                12.238,
                12.247,
                12.256,
                12.265,
                12.274,
                12.283,
                12.292,
                12.301,
                12.31,
                12.319,
                12.327,
                12.336,
                12.345,
                12.355,
                12.363,
                12.372,
                12.381,
                12.39,
                12.399,
                12.408,
                12.417,
                12.426,
                12.435,
                12.444,
                12.453,
                12.462,
                12.47,
                12.479,
                12.488,
                12.497,
                12.506,
                12.515,
                12.524,
                12.533,
                12.542,
                12.551,
                12.56,
                12.568,
                12.577,
                12.586,
                12.595,
                12.604,
                12.613,
                12.622,
                12.631,
                12.64,
                12.648,
                12.657,
                12.666,
                12.675,
                12.684,
                12.693,
                12.702,
                12.71,
                12.719,
                12.728,
                12.737,
                12.746,
                12.755,
                12.764,
                12.773,
                12.781,
                12.79,
                12.799,
                12.808,
                12.817,
                12.826,
                12.834,
                12.843,
                12.852,
                12.861,
                12.87,
                12.879,
                12.888,
                12.896,
                12.905,
                12.914,
                12.923,
                12.932,
                12.941,
                12.949,
                12.958,
                12.967,
                12.976,
                12.984,
                12.993,
                13.002,
                13.011,
                13.02,
                13.029,
                13.037,
                13.046,
                13.055,
                13.064,
                13.073,
                13.082,
                13.09,
                13.099,
                13.108,
                13.117,
                13.126,
                13.134,
                13.143,
                13.152,
                13.161,
                13.17,
                13.178,
                13.187,
                13.196,
                13.205,
                13.214,
                13.222,
                13.231,
                13.24,
                13.249,
                13.257,
                13.266,
                13.275,
                13.284,
                13.293,
                13.301,
                13.31,
                13.319,
                13.328,
                13.337,
                13.345,
                13.354,
                13.363,
                13.372,
                13.381,
                13.389,
                13.398,
                13.407,
                13.416,
                13.425,
                13.433,
                13.442,
                13.451,
                13.46,
                13.468,
                13.477,
                13.486,
                13.495,
                13.504,
                13.513,
                13.521,
                13.53,
                13.539,
                13.548,
                13.557,
                13.565,
                13.574,
                13.583,
                13.592,
                13.6,
                13.609,
                13.618,
                13.627,
                13.636,
                13.645,
                13.653,
                13.662,
                13.671,
                13.68,
                13.689,
                13.697,
                13.706,
                13.715,
                13.724,
                13.733,
                13.741,
                13.75,
                13.759,
                13.768,
                13.777,
                13.786,
                13.795,
                13.804,
                13.812,
                13.821,
                13.83,
                13.839,
                13.848,
                13.856,
                13.865,
                13.874,
                13.883,
                13.892,
                13.901,
                13.91,
                13.919,
                13.927,
                13.936,
                13.945,
                13.954,
                13.963,
                13.972,
                13.981,
                13.99,
                13.998,
                14.007,
                14.016,
                14.025,
                14.034,
                14.043,
                14.052,
                14.061,
                14.07,
                14.078,
                14.087,
                14.096,
                14.105,
                14.114,
                14.123,
                14.132,
                14.141,
                14.15,
                14.158,
                14.167,
                14.177,
                14.185,
                14.194,
                14.203,
                14.212,
                14.221,
                14.23,
                14.239,
                14.248,
                14.257,
                14.266,
                14.275,
                14.284,
                14.293,
                14.301,
                14.31,
                14.319,
                14.328,
                14.337,
                14.346,
                14.355,
                14.364,
                14.373,
                14.382,
                14.391,
                14.4,
                14.409,
                14.418,
                14.427,
                14.436,
                14.445,
                14.454,
                14.463,
                14.472,
                14.481,
                14.49,
                14.499,
                14.508,
                14.517,
                14.526,
                14.535,
                14.543,
                14.553,
                14.562,
                14.571,
                14.58,
                14.589,
                14.598,
                14.607,
                14.616,
                14.625,
                14.634,
                14.643,
                14.652,
                14.661,
                14.67,
                14.679,
                14.688,
                14.697,
                14.706,
                14.715,
                14.724,
                14.733,
                14.742,
                14.751,
                14.76,
                14.769,
                14.778,
                14.787,
                14.796,
                14.805,
                14.814,
                14.823,
                14.832,
                14.841,
                14.85,
                14.859,
                14.868,
                14.877,
                14.886,
                14.896,
                14.904,
                14.913,
                14.922,
                14.932,
                14.941,
                14.95,
                14.959,
                14.967,
                14.977,
                14.986,
                14.995,
                15.004,
                15.013,
                15.022,
                15.031,
                15.04,
                15.049,
                15.058,
                15.067,
                15.076,
                15.085,
                15.094,
                15.103,
                15.112,
                15.121,
                15.13,
                15.139,
                15.149,
                15.158,
                15.166,
                15.175,
                15.184,
                15.193,
                15.203,
                15.212,
                15.221,
                15.23,
                15.239,
                15.248,
                15.257,
                15.266,
                15.275,
                15.284,
                15.293,
                15.302,
                15.311,
                15.32,
                15.329,
                15.338,
                15.347,
                15.356,
                15.365,
                15.374,
                15.383,
                15.392,
                15.401,
                15.41,
                15.419,
                15.428,
                15.437,
                15.446,
                15.455,
                15.464,
                15.473,
                15.482,
                15.491,
                15.5,
                15.509,
                15.518,
                15.527,
                15.536,
                15.545,
                15.554,
                15.563,
                15.572,
                15.581,
                15.59,
                15.599,
                15.608,
                15.616,
                15.625,
                15.635,
                15.644,
                15.652,
                15.661,
                15.67,
                15.679,
                15.688,
                15.697,
                15.706,
                15.715,
                15.724,
                15.733,
                15.742,
                15.751,
                15.759,
                15.769,
                15.777,
                15.786,
                15.795,
                15.804,
                15.813,
                15.822,
                15.831,
                15.84,
                15.849,
                15.857,
                15.866,
                15.875,
                15.884,
                15.893,
                15.902,
                15.911,
                15.92,
                15.928,
                15.937,
                15.946,
                15.955,
                15.964,
                15.973,
                15.981,
                15.991,
                15.999,
                16.008,
                16.017,
                16.026,
                16.035,
                16.044,
                16.052,
                16.061,
                16.07,
                16.079,
                16.088,
                16.097,
                16.105,
                16.114,
                16.123,
                16.132,
                16.141,
                16.149,
                16.158,
                16.167,
                16.176,
                16.185,
                16.193,
                16.202,
                16.211,
                16.22,
                16.228,
                16.237,
                16.246,
                16.255,
                16.264,
                16.272,
                16.281,
                16.29,
                16.299,
                16.307,
                16.316,
                16.325,
                16.334,
                16.342,
                16.351,
                16.36,
                16.369,
                16.377,
                16.386,
                16.395,
                16.404,
                16.412,
                16.421,
                16.43,
                16.438,
                16.447,
                16.456,
                16.465,
                16.473,
                16.482,
                16.491,
                16.499,
                16.508,
                16.517,
                16.526,
                16.534,
                16.543,
                16.552,
                16.56,
                16.569,
                16.578,
                16.586,
                16.595,
                16.604,
                16.613,
                16.621,
                16.63,
                16.638,
                16.647,
                16.656,
                16.664,
                16.673,
                16.682,
                16.69,
                16.699,
                16.708,
                16.716,
                16.725,
                16.734,
                16.743,
                16.751,
                16.76,
                16.768,
                16.777,
                16.786,
                16.795,
                16.803,
                16.812,
                16.821,
                16.829,
                16.838,
                16.847,
                16.855,
                16.864,
                16.873,
                16.881,
                16.89,
                16.898,
                16.907,
                16.916,
                16.925,
                16.933,
                16.942,
                16.951,
                16.959,
                16.968,
                16.976,
                16.985,
                16.994,
                17.003,
                17.011,
                17.02,
                17.029,
                17.037,
                17.046,
                17.054,
                17.063,
                17.072,
                17.081,
                17.089,
                17.098,
                17.106,
                17.115,
                17.124,
                17.133,
                17.141,
                17.15,
                17.159,
                17.167,
                17.176,
                17.184,
                17.193,
                17.202,
                17.211,
                17.219,
                17.228,
                17.237,
                17.245,
                17.254,
                17.263,
                17.271,
                17.28,
                17.289,
                17.297,
                17.306,
                17.315,
                17.323,
                17.332,
                17.341,
                17.35,
                17.358,
                17.367,
                17.376,
                17.384,
                17.393,
                17.402,
                17.411,
                17.419,
                17.428,
                17.436,
                17.445,
                17.454,
                17.462,
                17.471,
                17.48,
                17.489,
                17.498,
                17.506,
                17.515,
                17.524,
                17.532,
                17.541,
                17.55,
                17.559,
                17.567,
                17.576,
                17.585,
                17.593,
                17.602,
                17.611,
                17.62,
                17.628,
                17.637,
                17.646,
                17.655,
                17.664,
                17.672,
                17.681,
                17.69,
                17.698,
                17.707,
                17.716,
                17.725,
                17.734,
                17.742,
                17.751,
                17.76,
                17.769,
                17.778,
                17.786,
                17.795,
                17.804,
                17.813,
                17.821,
                17.83,
                17.839,
                17.848,
                17.856,
                17.865,
                17.874,
                17.883,
                17.892,
                17.9,
                17.909,
                17.918,
                17.927,
                17.936,
                17.945,
                17.953,
                17.962,
                17.971,
                17.98,
                17.988,
                17.998,
                18.006,
                18.015,
                18.024,
                18.033,
                18.042,
                18.05,
                18.059,
                18.068,
                18.077,
                18.086,
                18.095,
                18.103,
                18.112,
                18.121,
                18.13,
                18.139,
                18.148,
                18.157,
                18.165,
                18.175,
                18.183,
                18.192,
                18.201,
                18.21,
                18.219,
                18.228,
                18.236,
                18.245,
                18.254,
                18.263,
                18.272,
                18.281,
                18.29,
                18.299,
                18.308,
                18.316,
                18.325,
                18.334,
                18.343,
                18.352,
                18.361,
                18.37,
                18.379,
                18.388,
                18.397,
                18.406,
                18.414,
                18.423,
                18.432,
                18.441,
                18.45,
                18.459,
                18.468,
                18.477,
                18.486,
                18.495,
                18.504,
                18.513,
                18.521,
                18.53,
                18.539,
                18.548,
                18.557,
                18.566,
                18.575,
                18.584,
                18.593,
                18.602,
                18.611,
                18.62,
                18.629,
                18.638,
                18.646,
                18.656,
                18.665,
                18.674,
                18.683,
                18.691,
                18.7,
                18.709,
                18.718,
                18.727,
                18.736,
                18.745,
                18.754,
                18.763,
                18.772,
                18.781,
                18.79,
                18.799,
                18.808,
                18.817,
                18.826,
                18.835,
                18.844,
                18.853,
                18.862,
                18.871,
                18.88,
                18.889,
                18.898,
                18.907,
                18.915,
                18.925,
                18.934,
                18.942,
                18.952,
                18.961,
                18.97,
                18.979,
                18.987,
                18.997,
                19.006,
                19.014,
                19.024,
                19.033,
                19.041,
                19.051,
                19.06,
                19.069,
                19.078,
                19.087,
                19.095,
                19.105,
                19.113,
                19.123,
                19.132,
                19.141,
                19.15,
                19.159,
                19.168,
                19.176,
                19.186,
                19.195,
                19.204,
                19.213,
                19.222,
                19.231,
                19.24,
                19.249,
                19.258,
                19.267,
                19.276,
                19.285,
                19.294,
                19.303,
                19.312,
                19.321,
                19.33,
                19.339,
                19.348,
                19.357,
                19.366,
                19.375,
                19.384,
                19.393,
                19.402,
                19.411,
                19.42,
                19.429,
                19.438,
                19.447,
                19.456,
                19.465,
                19.474,
                19.483,
                19.492,
                19.501,
                19.51,
                19.519,
                19.528,
                19.537,
                19.547,
                19.555,
                19.565,
                19.574,
                19.583,
                19.592,
                19.601,
                19.61,
                19.619,
                19.628,
                19.637,
                19.646,
                19.655,
                19.664,
                19.673,
                19.682,
                19.691,
                19.7,
                19.709,
                19.718,
                19.727,
                19.736,
                19.745,
                19.754,
                19.763,
                19.772,
                19.781,
                19.79,
                19.799,
                19.809,
                19.818,
                19.827,
                19.836,
                19.845,
                19.854,
                19.863,
                19.872,
                19.881,
                19.89,
                19.899,
                19.908,
                19.917,
                19.926,
                19.935,
                19.944,
                19.953,
                19.962,
                19.971,
                19.98,
                19.989,
                19.998,
                20.007,
                20.016,
                20.025,
                20.035,
                20.044,
                20.052,
                20.061,
                20.071,
                20.08,
                20.089,
                20.098,
                20.107,
                20.116,
                20.125,
                20.134,
                20.143,
                20.152,
                20.161,
                20.17,
                20.179,
                20.188,
                20.197,
                20.206,
                20.216,
                20.225,
                20.234,
                20.242,
                20.252,
                20.261,
                20.27,
                20.279,
                20.288,
                20.297,
                20.306,
                20.315,
                20.324,
                20.333,
                20.342,
                20.351,
                20.36,
                20.369,
                20.378,
                20.387,
                20.396,
                20.405,
                20.415,
                20.423,
                20.432,
                20.442,
                20.451,
                20.46,
                20.469,
                20.478,
                20.487,
                20.496,
                20.505,
                20.514,
                20.523,
                20.532,
                20.541,
                20.55,
                20.559,
                20.568,
                20.577,
                20.586,
                20.596,
                20.604,
                20.613,
                20.623,
                20.632,
                20.64,
                20.65,
                20.659,
                20.668,
                20.677,
                20.686,
                20.695,
                20.704,
                20.713,
                20.722,
                20.731,
                20.74,
                20.749,
                20.758,
                20.767,
                20.776,
                20.785,
                20.795,
                20.804,
                20.812,
                20.822,
                20.831,
                20.84,
                20.849,
                20.858,
                20.867,
                20.876,
                20.885,
                20.894,
                20.903,
                20.912,
                20.922,
                20.93,
                20.939,
                20.949,
                20.958,
                20.967,
                20.976,
                20.985,
                20.994,
                21.003,
                21.012,
                21.021,
                21.03,
                21.039,
                21.048,
                21.057,
                21.066,
                21.075,
                21.084,
                21.093,
                21.103,
                21.112,
                21.12,
                21.13,
                21.139,
                21.148,
                21.157,
                21.166,
                21.175,
                21.184,
                21.194,
                21.202,
                21.211,
                21.221,
                21.23,
                21.239,
                21.248,
                21.257,
                21.266,
                21.275,
                21.284,
                21.293,
                21.302,
                21.311,
                21.32,
                21.33,
                21.338,
                21.348,
                21.357,
                21.366,
                21.375,
                21.384,
                21.393,
                21.402,
                21.411,
                21.42,
                21.429,
                21.438,
                21.447,
                21.457,
                21.466,
                21.475,
                21.484,
                21.493,
                21.502,
                21.511,
                21.52,
                21.529,
                21.538,
                21.547,
                21.557,
                21.566,
                21.575,
                21.584,
                21.593,
                21.602,
                21.611,
                21.62,
                21.629,
                21.638,
                21.647,
                21.657,
                21.665,
                21.675,
                21.684,
                21.693,
                21.702,
                21.711,
                21.72,
                21.729,
                21.738,
                21.748,
                21.756,
                21.766,
                21.775,
                21.784,
                21.793,
                21.802,
                21.811,
                21.82,
                21.829,
                21.839,
                21.848,
                21.857,
                21.866,
                21.875,
                21.884,
                21.893,
                21.902,
                21.911,
                21.921,
                21.929,
                21.939,
                21.948,
                21.957,
                21.966,
                21.975,
                21.984,
                21.993,
                22.003,
                22.011,
                22.021,
                22.03,
                22.039,
                22.048,
                22.057,
                22.066,
                22.075,
                22.085,
                22.093,
                22.103,
                22.111,
                22.121,
                22.13,
                22.139,
                22.148,
                22.157,
                22.166,
                22.175,
                22.185,
                22.194,
                22.203,
                22.212,
                22.221,
                22.23,
                22.239,
                22.248,
                22.258,
                22.266,
                22.276,
                22.285,
                22.294,
                22.303,
                22.312,
                22.321,
                22.33,
                22.339,
                22.349,
                22.358,
                22.367,
                22.376,
                22.385,
                22.394,
                22.403,
                22.412,
                22.422,
                22.43,
                22.44,
                22.449,
                22.458,
                22.467,
                22.476,
                22.485,
                22.495,
                22.503,
                22.513,
                22.522,
                22.531,
                22.54,
                22.549,
                22.558,
                22.567,
                22.576,
                22.585,
                22.595,
                22.604,
                22.613,
                22.622,
                22.631,
                22.64,
                22.649,
                22.658,
                22.668,
                22.676,
                22.686,
                22.695,
                22.704,
                22.713,
                22.722,
                22.731,
                22.74,
                22.749,
                22.758,
                22.768,
                22.777,
                22.786,
                22.795,
                22.804,
                22.813,
                22.822,
                22.831,
                22.84,
                22.85,
                22.858,
                22.868,
                22.877,
                22.886,
                22.895,
                22.904,
                22.913,
                22.922,
                22.931,
                22.94,
                22.95,
                22.958,
                22.968,
                22.977,
                22.986,
                22.995,
                23.004,
                23.013,
                23.022,
                23.032,
                23.04,
                23.05,
                23.059,
                23.068,
                23.077,
                23.086,
                23.095,
                23.104,
                23.113,
                23.122,
                23.131,
                23.14,
                23.149,
                23.159,
                23.168,
                23.177,
                23.186,
                23.195,
                23.204,
                23.213,
                23.222,
                23.231,
                23.24,
                23.249,
                23.259,
                23.267,
                23.277,
                23.286,
                23.295,
                23.304,
                23.313,
                23.322,
                23.331,
                23.34,
                23.349,
                23.358,
                23.367,
                23.376,
                23.386,
                23.394,
                23.404,
                23.412,
                23.422,
                23.431,
                23.439,
                23.449,
                23.458,
                23.467,
                23.476,
                23.485,
                23.494,
                23.503,
                23.512,
                23.521,
                23.53,
                23.539,
                23.548,
                23.558,
                23.566,
                23.576,
                23.584,
                23.594,
                23.602,
                23.611,
                23.621,
                23.629,
                23.639,
                23.648,
                23.657,
                23.666,
                23.675,
                23.684,
                23.693,
                23.702,
                23.711,
                23.72,
                23.729,
                23.738,
                23.747,
                23.756,
                23.765,
                23.774,
                23.783,
                23.792,
                23.801,
                23.81,
                23.819,
                23.828,
                23.837,
                23.846,
                23.855,
                23.864,
                23.873,
                23.882,
                23.891,
                23.9,
                23.909,
                23.918,
                23.927,
                23.936,
                23.945,
                23.954,
                23.963,
                23.972,
                23.981,
                23.99,
                23.999,
                24.008,
                24.017,
                24.026,
                24.035,
                24.044,
                24.053,
                24.062,
                24.071,
                24.08,
                24.089,
                24.098,
                24.107,
                24.116,
                24.125,
                24.134,
                24.143,
                24.152,
                24.161,
                24.169,
                24.179,
                24.188,
                24.197,
                24.206,
                24.214,
                24.224,
                24.232,
                24.242,
                24.25,
                24.259,
                24.268,
                24.277,
                24.286,
                24.295,
                24.304,
                24.313,
                24.322,
                24.331,
                24.34,
                24.349,
                24.358,
                24.367,
                24.375,
                24.385,
                24.393,
                24.403,
                24.411,
                24.42,
                24.429,
                24.438,
                24.447,
                24.456,
                24.465,
                24.474,
                24.483,
                24.492,
                24.501,
                24.51,
                24.518,
                24.528,
                24.536,
                24.546,
                24.554,
                24.563,
                24.572,
                24.581,
                24.59,
                24.599,
                24.608,
                24.617,
                24.626,
                24.634,
                24.644,
                24.652,
                24.661,
                24.67,
                24.679,
                24.688,
                24.697

            ],
            P99: [
                4.409,
                4.408,
                4.438,
                4.471,
                4.507,
                4.544,
                4.583,
                4.622,
                4.661,
                4.702,
                4.744,
                4.786,
                4.83,
                4.876,
                4.922,
                4.97,
                5.018,
                5.067,
                5.116,
                5.166,
                5.215,
                5.264,
                5.313,
                5.362,
                5.411,
                5.459,
                5.507,
                5.554,
                5.601,
                5.648,
                5.694,
                5.74,
                5.785,
                5.83,
                5.874,
                5.918,
                5.961,
                6.004,
                6.047,
                6.089,
                6.13,
                6.171,
                6.212,
                6.252,
                6.292,
                6.332,
                6.371,
                6.41,
                6.448,
                6.486,
                6.523,
                6.561,
                6.598,
                6.634,
                6.67,
                6.706,
                6.741,
                6.776,
                6.811,
                6.846,
                6.88,
                6.913,
                6.947,
                6.981,
                7.014,
                7.047,
                7.079,
                7.112,
                7.144,
                7.175,
                7.207,
                7.238,
                7.269,
                7.3,
                7.331,
                7.361,
                7.391,
                7.421,
                7.451,
                7.48,
                7.51,
                7.539,
                7.568,
                7.596,
                7.625,
                7.653,
                7.681,
                7.709,
                7.737,
                7.764,
                7.791,
                7.819,
                7.845,
                7.872,
                7.899,
                7.925,
                7.951,
                7.978,
                8.004,
                8.029,
                8.055,
                8.08,
                8.106,
                8.131,
                8.156,
                8.181,
                8.205,
                8.23,
                8.254,
                8.278,
                8.302,
                8.326,
                8.35,
                8.373,
                8.397,
                8.42,
                8.443,
                8.466,
                8.489,
                8.512,
                8.535,
                8.557,
                8.579,
                8.601,
                8.624,
                8.646,
                8.667,
                8.689,
                8.711,
                8.732,
                8.753,
                8.774,
                8.795,
                8.816,
                8.837,
                8.858,
                8.878,
                8.899,
                8.919,
                8.939,
                8.959,
                8.979,
                8.999,
                9.019,
                9.038,
                9.058,
                9.077,
                9.096,
                9.115,
                9.134,
                9.153,
                9.172,
                9.191,
                9.21,
                9.228,
                9.247,
                9.265,
                9.283,
                9.301,
                9.32,
                9.337,
                9.355,
                9.373,
                9.391,
                9.409,
                9.426,
                9.444,
                9.461,
                9.478,
                9.495,
                9.513,
                9.53,
                9.547,
                9.564,
                9.58,
                9.597,
                9.614,
                9.631,
                9.647,
                9.664,
                9.68,
                9.696,
                9.713,
                9.729,
                9.745,
                9.761,
                9.777,
                9.793,
                9.809,
                9.825,
                9.841,
                9.856,
                9.872,
                9.887,
                9.903,
                9.918,
                9.934,
                9.949,
                9.965,
                9.98,
                9.995,
                10.01,
                10.025,
                10.04,
                10.055,
                10.07,
                10.085,
                10.1,
                10.114,
                10.129,
                10.144,
                10.158,
                10.173,
                10.188,
                10.202,
                10.217,
                10.231,
                10.245,
                10.259,
                10.274,
                10.288,
                10.302,
                10.316,
                10.33,
                10.344,
                10.358,
                10.372,
                10.386,
                10.4,
                10.413,
                10.427,
                10.441,
                10.454,
                10.468,
                10.482,
                10.495,
                10.509,
                10.522,
                10.536,
                10.549,
                10.562,
                10.576,
                10.589,
                10.602,
                10.615,
                10.628,
                10.642,
                10.655,
                10.667,
                10.681,
                10.693,
                10.706,
                10.719,
                10.732,
                10.745,
                10.758,
                10.77,
                10.783,
                10.796,
                10.808,
                10.821,
                10.834,
                10.846,
                10.858,
                10.871,
                10.883,
                10.896,
                10.908,
                10.92,
                10.933,
                10.945,
                10.957,
                10.969,
                10.982,
                10.994,
                11.006,
                11.018,
                11.03,
                11.042,
                11.054,
                11.066,
                11.078,
                11.09,
                11.102,
                11.113,
                11.125,
                11.137,
                11.149,
                11.16,
                11.172,
                11.184,
                11.196,
                11.207,
                11.219,
                11.23,
                11.242,
                11.254,
                11.265,
                11.277,
                11.288,
                11.299,
                11.311,
                11.322,
                11.334,
                11.345,
                11.356,
                11.368,
                11.379,
                11.39,
                11.402,
                11.413,
                11.424,
                11.435,
                11.446,
                11.457,
                11.469,
                11.48,
                11.491,
                11.502,
                11.513,
                11.524,
                11.535,
                11.546,
                11.557,
                11.568,
                11.579,
                11.59,
                11.601,
                11.611,
                11.622,
                11.633,
                11.644,
                11.655,
                11.666,
                11.676,
                11.687,
                11.698,
                11.709,
                11.719,
                11.73,
                11.741,
                11.751,
                11.762,
                11.773,
                11.783,
                11.794,
                11.804,
                11.815,
                11.826,
                11.836,
                11.847,
                11.857,
                11.868,
                11.878,
                11.889,
                11.899,
                11.91,
                11.92,
                11.931,
                11.941,
                11.951,
                11.962,
                11.972,
                11.982,
                11.993,
                12.003,
                12.014,
                12.024,
                12.034,
                12.045,
                12.055,
                12.065,
                12.076,
                12.086,
                12.096,
                12.106,
                12.116,
                12.126,
                12.137,
                12.147,
                12.157,
                12.167,
                12.178,
                12.188,
                12.198,
                12.208,
                12.218,
                12.228,
                12.238,
                12.248,
                12.259,
                12.268,
                12.279,
                12.289,
                12.299,
                12.309,
                12.319,
                12.329,
                12.339,
                12.349,
                12.359,
                12.369,
                12.379,
                12.389,
                12.399,
                12.409,
                12.418,
                12.429,
                12.438,
                12.448,
                12.458,
                12.468,
                12.478,
                12.488,
                12.498,
                12.508,
                12.518,
                12.527,
                12.537,
                12.547,
                12.557,
                12.567,
                12.577,
                12.587,
                12.596,
                12.606,
                12.616,
                12.626,
                12.635,
                12.645,
                12.655,
                12.665,
                12.675,
                12.684,
                12.694,
                12.704,
                12.714,
                12.723,
                12.733,
                12.743,
                12.753,
                12.762,
                12.772,
                12.781,
                12.791,
                12.801,
                12.811,
                12.82,
                12.83,
                12.84,
                12.849,
                12.859,
                12.869,
                12.878,
                12.888,
                12.897,
                12.907,
                12.917,
                12.926,
                12.936,
                12.946,
                12.955,
                12.965,
                12.974,
                12.984,
                12.993,
                13.003,
                13.013,
                13.022,
                13.032,
                13.041,
                13.051,
                13.061,
                13.07,
                13.08,
                13.089,
                13.099,
                13.108,
                13.118,
                13.128,
                13.137,
                13.147,
                13.156,
                13.166,
                13.175,
                13.185,
                13.194,
                13.204,
                13.213,
                13.223,
                13.232,
                13.242,
                13.251,
                13.261,
                13.27,
                13.28,
                13.29,
                13.299,
                13.309,
                13.318,
                13.327,
                13.337,
                13.346,
                13.356,
                13.365,
                13.375,
                13.384,
                13.394,
                13.403,
                13.413,
                13.422,
                13.432,
                13.441,
                13.451,
                13.46,
                13.47,
                13.479,
                13.488,
                13.498,
                13.508,
                13.517,
                13.526,
                13.536,
                13.545,
                13.555,
                13.564,
                13.574,
                13.583,
                13.593,
                13.602,
                13.612,
                13.621,
                13.63,
                13.64,
                13.649,
                13.659,
                13.668,
                13.678,
                13.687,
                13.696,
                13.706,
                13.715,
                13.725,
                13.734,
                13.744,
                13.753,
                13.762,
                13.772,
                13.781,
                13.791,
                13.8,
                13.809,
                13.819,
                13.828,
                13.838,
                13.847,
                13.857,
                13.866,
                13.875,
                13.885,
                13.894,
                13.904,
                13.913,
                13.922,
                13.932,
                13.941,
                13.951,
                13.96,
                13.969,
                13.979,
                13.988,
                13.998,
                14.007,
                14.016,
                14.026,
                14.035,
                14.045,
                14.054,
                14.063,
                14.073,
                14.082,
                14.092,
                14.101,
                14.11,
                14.12,
                14.129,
                14.138,
                14.148,
                14.157,
                14.167,
                14.176,
                14.185,
                14.195,
                14.204,
                14.213,
                14.223,
                14.232,
                14.242,
                14.251,
                14.26,
                14.27,
                14.279,
                14.289,
                14.298,
                14.307,
                14.317,
                14.326,
                14.336,
                14.345,
                14.355,
                14.364,
                14.373,
                14.383,
                14.392,
                14.401,
                14.411,
                14.42,
                14.43,
                14.439,
                14.449,
                14.458,
                14.468,
                14.477,
                14.486,
                14.496,
                14.505,
                14.515,
                14.524,
                14.533,
                14.543,
                14.552,
                14.562,
                14.571,
                14.58,
                14.59,
                14.599,
                14.609,
                14.618,
                14.628,
                14.637,
                14.647,
                14.656,
                14.665,
                14.675,
                14.684,
                14.694,
                14.703,
                14.713,
                14.722,
                14.732,
                14.741,
                14.751,
                14.76,
                14.77,
                14.779,
                14.789,
                14.798,
                14.808,
                14.817,
                14.827,
                14.836,
                14.846,
                14.855,
                14.865,
                14.874,
                14.884,
                14.893,
                14.903,
                14.912,
                14.922,
                14.931,
                14.941,
                14.95,
                14.96,
                14.969,
                14.979,
                14.988,
                14.998,
                15.008,
                15.017,
                15.026,
                15.036,
                15.046,
                15.055,
                15.065,
                15.074,
                15.084,
                15.094,
                15.103,
                15.113,
                15.122,
                15.132,
                15.142,
                15.151,
                15.16,
                15.17,
                15.18,
                15.189,
                15.199,
                15.208,
                15.218,
                15.227,
                15.237,
                15.247,
                15.256,
                15.266,
                15.276,
                15.285,
                15.295,
                15.304,
                15.314,
                15.324,
                15.333,
                15.343,
                15.352,
                15.362,
                15.371,
                15.381,
                15.391,
                15.401,
                15.41,
                15.42,
                15.43,
                15.439,
                15.449,
                15.458,
                15.468,
                15.478,
                15.487,
                15.497,
                15.506,
                15.516,
                15.526,
                15.536,
                15.545,
                15.555,
                15.564,
                15.574,
                15.584,
                15.593,
                15.603,
                15.613,
                15.623,
                15.632,
                15.642,
                15.651,
                15.661,
                15.671,
                15.68,
                15.69,
                15.7,
                15.71,
                15.719,
                15.729,
                15.739,
                15.748,
                15.758,
                15.767,
                15.777,
                15.787,
                15.797,
                15.806,
                15.816,
                15.826,
                15.835,
                15.845,
                15.855,
                15.865,
                15.874,
                15.884,
                15.893,
                15.903,
                15.913,
                15.923,
                15.932,
                15.942,
                15.952,
                15.962,
                15.971,
                15.981,
                15.99,
                16,
                16.01,
                16.02,
                16.029,
                16.039,
                16.049,
                16.059,
                16.068,
                16.078,
                16.088,
                16.098,
                16.107,
                16.117,
                16.126,
                16.136,
                16.146,
                16.155,
                16.165,
                16.175,
                16.184,
                16.194,
                16.204,
                16.214,
                16.223,
                16.233,
                16.243,
                16.252,
                16.262,
                16.272,
                16.281,
                16.291,
                16.301,
                16.311,
                16.32,
                16.33,
                16.34,
                16.35,
                16.359,
                16.369,
                16.379,
                16.388,
                16.398,
                16.407,
                16.417,
                16.427,
                16.436,
                16.446,
                16.456,
                16.466,
                16.475,
                16.485,
                16.494,
                16.504,
                16.514,
                16.523,
                16.533,
                16.543,
                16.552,
                16.562,
                16.572,
                16.581,
                16.591,
                16.6,
                16.61,
                16.62,
                16.629,
                16.639,
                16.648,
                16.658,
                16.668,
                16.678,
                16.687,
                16.697,
                16.707,
                16.716,
                16.726,
                16.735,
                16.745,
                16.755,
                16.764,
                16.774,
                16.783,
                16.793,
                16.802,
                16.812,
                16.822,
                16.831,
                16.841,
                16.85,
                16.86,
                16.87,
                16.879,
                16.888,
                16.898,
                16.908,
                16.917,
                16.927,
                16.937,
                16.946,
                16.955,
                16.965,
                16.975,
                16.984,
                16.994,
                17.003,
                17.013,
                17.023,
                17.032,
                17.041,
                17.051,
                17.06,
                17.07,
                17.08,
                17.089,
                17.098,
                17.108,
                17.118,
                17.127,
                17.137,
                17.146,
                17.156,
                17.165,
                17.175,
                17.184,
                17.193,
                17.203,
                17.213,
                17.222,
                17.231,
                17.241,
                17.251,
                17.26,
                17.27,
                17.279,
                17.289,
                17.298,
                17.307,
                17.317,
                17.327,
                17.336,
                17.345,
                17.355,
                17.364,
                17.374,
                17.383,
                17.393,
                17.402,
                17.411,
                17.421,
                17.43,
                17.44,
                17.449,
                17.459,
                17.468,
                17.478,
                17.487,
                17.496,
                17.506,
                17.515,
                17.525,
                17.534,
                17.544,
                17.553,
                17.562,
                17.572,
                17.581,
                17.591,
                17.6,
                17.609,
                17.619,
                17.628,
                17.638,
                17.647,
                17.656,
                17.666,
                17.675,
                17.685,
                17.694,
                17.704,
                17.713,
                17.722,
                17.732,
                17.741,
                17.75,
                17.76,
                17.77,
                17.779,
                17.788,
                17.798,
                17.807,
                17.816,
                17.826,
                17.835,
                17.844,
                17.854,
                17.864,
                17.873,
                17.882,
                17.892,
                17.901,
                17.91,
                17.92,
                17.929,
                17.938,
                17.948,
                17.958,
                17.967,
                17.976,
                17.986,
                17.995,
                18.005,
                18.014,
                18.023,
                18.033,
                18.042,
                18.051,
                18.061,
                18.07,
                18.08,
                18.089,
                18.098,
                18.108,
                18.117,
                18.127,
                18.136,
                18.145,
                18.155,
                18.164,
                18.174,
                18.183,
                18.193,
                18.202,
                18.211,
                18.221,
                18.23,
                18.24,
                18.249,
                18.259,
                18.268,
                18.277,
                18.287,
                18.296,
                18.306,
                18.315,
                18.325,
                18.334,
                18.344,
                18.353,
                18.362,
                18.372,
                18.381,
                18.391,
                18.4,
                18.41,
                18.419,
                18.429,
                18.438,
                18.447,
                18.457,
                18.466,
                18.476,
                18.485,
                18.495,
                18.504,
                18.514,
                18.523,
                18.533,
                18.542,
                18.551,
                18.561,
                18.57,
                18.58,
                18.589,
                18.599,
                18.609,
                18.618,
                18.628,
                18.637,
                18.647,
                18.656,
                18.666,
                18.675,
                18.685,
                18.694,
                18.704,
                18.713,
                18.723,
                18.732,
                18.742,
                18.751,
                18.761,
                18.77,
                18.78,
                18.79,
                18.799,
                18.808,
                18.818,
                18.827,
                18.837,
                18.847,
                18.856,
                18.866,
                18.876,
                18.885,
                18.895,
                18.904,
                18.913,
                18.923,
                18.933,
                18.942,
                18.952,
                18.962,
                18.971,
                18.981,
                18.99,
                19,
                19.01,
                19.019,
                19.029,
                19.039,
                19.048,
                19.058,
                19.067,
                19.077,
                19.087,
                19.096,
                19.106,
                19.115,
                19.125,
                19.135,
                19.144,
                19.154,
                19.163,
                19.173,
                19.183,
                19.192,
                19.202,
                19.212,
                19.221,
                19.231,
                19.241,
                19.251,
                19.26,
                19.27,
                19.28,
                19.289,
                19.299,
                19.309,
                19.318,
                19.328,
                19.338,
                19.347,
                19.357,
                19.367,
                19.377,
                19.386,
                19.396,
                19.406,
                19.415,
                19.425,
                19.435,
                19.444,
                19.454,
                19.464,
                19.474,
                19.484,
                19.493,
                19.503,
                19.513,
                19.522,
                19.532,
                19.542,
                19.552,
                19.561,
                19.571,
                19.581,
                19.591,
                19.601,
                19.61,
                19.62,
                19.63,
                19.639,
                19.649,
                19.659,
                19.669,
                19.679,
                19.689,
                19.698,
                19.708,
                19.718,
                19.728,
                19.738,
                19.747,
                19.757,
                19.767,
                19.777,
                19.786,
                19.796,
                19.806,
                19.816,
                19.826,
                19.836,
                19.845,
                19.855,
                19.865,
                19.875,
                19.884,
                19.895,
                19.905,
                19.914,
                19.924,
                19.934,
                19.944,
                19.953,
                19.963,
                19.973,
                19.983,
                19.993,
                20.003,
                20.012,
                20.022,
                20.033,
                20.042,
                20.052,
                20.062,
                20.072,
                20.081,
                20.092,
                20.102,
                20.111,
                20.121,
                20.131,
                20.141,
                20.151,
                20.161,
                20.171,
                20.18,
                20.19,
                20.2,
                20.21,
                20.22,
                20.23,
                20.24,
                20.25,
                20.259,
                20.27,
                20.28,
                20.289,
                20.299,
                20.309,
                20.319,
                20.329,
                20.339,
                20.349,
                20.359,
                20.369,
                20.378,
                20.389,
                20.398,
                20.408,
                20.418,
                20.428,
                20.438,
                20.448,
                20.458,
                20.468,
                20.478,
                20.488,
                20.498,
                20.508,
                20.518,
                20.527,
                20.537,
                20.548,
                20.557,
                20.567,
                20.577,
                20.587,
                20.597,
                20.607,
                20.617,
                20.627,
                20.637,
                20.647,
                20.657,
                20.667,
                20.677,
                20.687,
                20.696,
                20.707,
                20.717,
                20.727,
                20.736,
                20.746,
                20.756,
                20.766,
                20.776,
                20.786,
                20.796,
                20.806,
                20.816,
                20.826,
                20.836,
                20.846,
                20.856,
                20.866,
                20.876,
                20.886,
                20.896,
                20.906,
                20.916,
                20.926,
                20.936,
                20.946,
                20.956,
                20.966,
                20.976,
                20.986,
                20.996,
                21.006,
                21.015,
                21.026,
                21.036,
                21.046,
                21.055,
                21.066,
                21.076,
                21.086,
                21.096,
                21.105,
                21.115,
                21.126,
                21.136,
                21.145,
                21.155,
                21.166,
                21.176,
                21.186,
                21.195,
                21.205,
                21.216,
                21.226,
                21.236,
                21.245,
                21.255,
                21.266,
                21.276,
                21.286,
                21.295,
                21.305,
                21.316,
                21.326,
                21.335,
                21.345,
                21.356,
                21.366,
                21.376,
                21.385,
                21.395,
                21.406,
                21.416,
                21.426,
                21.435,
                21.445,
                21.456,
                21.466,
                21.476,
                21.485,
                21.496,
                21.506,
                21.516,
                21.526,
                21.536,
                21.546,
                21.556,
                21.566,
                21.576,
                21.586,
                21.596,
                21.606,
                21.616,
                21.626,
                21.636,
                21.646,
                21.656,
                21.666,
                21.676,
                21.686,
                21.696,
                21.706,
                21.716,
                21.726,
                21.737,
                21.746,
                21.756,
                21.766,
                21.776,
                21.787,
                21.796,
                21.806,
                21.816,
                21.827,
                21.837,
                21.846,
                21.856,
                21.867,
                21.877,
                21.887,
                21.897,
                21.907,
                21.917,
                21.927,
                21.937,
                21.947,
                21.957,
                21.967,
                21.977,
                21.987,
                21.997,
                22.007,
                22.017,
                22.027,
                22.038,
                22.047,
                22.057,
                22.067,
                22.078,
                22.087,
                22.097,
                22.107,
                22.118,
                22.128,
                22.137,
                22.148,
                22.158,
                22.168,
                22.178,
                22.188,
                22.198,
                22.208,
                22.218,
                22.228,
                22.238,
                22.248,
                22.258,
                22.268,
                22.278,
                22.288,
                22.299,
                22.309,
                22.318,
                22.328,
                22.339,
                22.349,
                22.359,
                22.369,
                22.379,
                22.389,
                22.399,
                22.409,
                22.419,
                22.429,
                22.439,
                22.45,
                22.459,
                22.469,
                22.48,
                22.49,
                22.499,
                22.51,
                22.52,
                22.53,
                22.54,
                22.55,
                22.56,
                22.57,
                22.58,
                22.59,
                22.6,
                22.611,
                22.62,
                22.63,
                22.641,
                22.651,
                22.661,
                22.671,
                22.681,
                22.691,
                22.701,
                22.711,
                22.721,
                22.732,
                22.742,
                22.751,
                22.762,
                22.772,
                22.782,
                22.792,
                22.802,
                22.812,
                22.822,
                22.832,
                22.842,
                22.853,
                22.862,
                22.873,
                22.883,
                22.893,
                22.903,
                22.913,
                22.923,
                22.933,
                22.943,
                22.953,
                22.964,
                22.973,
                22.984,
                22.994,
                23.004,
                23.014,
                23.024,
                23.034,
                23.045,
                23.054,
                23.065,
                23.075,
                23.084,
                23.095,
                23.105,
                23.115,
                23.125,
                23.135,
                23.145,
                23.155,
                23.165,
                23.176,
                23.186,
                23.196,
                23.206,
                23.216,
                23.226,
                23.236,
                23.247,
                23.256,
                23.267,
                23.277,
                23.287,
                23.297,
                23.307,
                23.318,
                23.327,
                23.338,
                23.348,
                23.358,
                23.368,
                23.378,
                23.388,
                23.398,
                23.409,
                23.419,
                23.429,
                23.439,
                23.449,
                23.459,
                23.469,
                23.48,
                23.489,
                23.5,
                23.51,
                23.52,
                23.53,
                23.54,
                23.55,
                23.56,
                23.571,
                23.581,
                23.591,
                23.601,
                23.611,
                23.621,
                23.632,
                23.642,
                23.652,
                23.662,
                23.672,
                23.682,
                23.693,
                23.702,
                23.713,
                23.722,
                23.733,
                23.743,
                23.753,
                23.763,
                23.774,
                23.784,
                23.794,
                23.804,
                23.814,
                23.824,
                23.835,
                23.844,
                23.855,
                23.865,
                23.875,
                23.885,
                23.895,
                23.906,
                23.916,
                23.926,
                23.936,
                23.946,
                23.956,
                23.966,
                23.976,
                23.987,
                23.997,
                24.007,
                24.017,
                24.027,
                24.037,
                24.048,
                24.058,
                24.068,
                24.078,
                24.088,
                24.099,
                24.108,
                24.119,
                24.128,
                24.139,
                24.149,
                24.159,
                24.169,
                24.179,
                24.19,
                24.2,
                24.21,
                24.22,
                24.23,
                24.24,
                24.25,
                24.261,
                24.271,
                24.281,
                24.291,
                24.301,
                24.312,
                24.322,
                24.332,
                24.342,
                24.352,
                24.362,
                24.372,
                24.383,
                24.393,
                24.403,
                24.413,
                24.423,
                24.433,
                24.443,
                24.454,
                24.464,
                24.474,
                24.484,
                24.494,
                24.504,
                24.514,
                24.525,
                24.535,
                24.545,
                24.555,
                24.565,
                24.576,
                24.586,
                24.596,
                24.606,
                24.616,
                24.626,
                24.636,
                24.646,
                24.657,
                24.666,
                24.677,
                24.687,
                24.697,
                24.708,
                24.717,
                24.728,
                24.738,
                24.748,
                24.758,
                24.768,
                24.778,
                24.789,
                24.798,
                24.809,
                24.819,
                24.829,
                24.839,
                24.849,
                24.859,
                24.869,
                24.879,
                24.89,
                24.9,
                24.91,
                24.92,
                24.93,
                24.941,
                24.95,
                24.961,
                24.971,
                24.981,
                24.991,
                25.001,
                25.011,
                25.022,
                25.032,
                25.042,
                25.052,
                25.062,
                25.072,
                25.082,
                25.092,
                25.103,
                25.112,
                25.123,
                25.133,
                25.143,
                25.153,
                25.164,
                25.173,
                25.184,
                25.193,
                25.203,
                25.214,
                25.224,
                25.234,
                25.244,
                25.254,
                25.264,
                25.274,
                25.284,
                25.295,
                25.305,
                25.315,
                25.325,
                25.335,
                25.345,
                25.355,
                25.365,
                25.376,
                25.385,
                25.395,
                25.406,
                25.415,
                25.426,
                25.436,
                25.446,
                25.456,
                25.466,
                25.476,
                25.487,
                25.496,
                25.507,
                25.517,
                25.526,
                25.537,
                25.547,
                25.557,
                25.567,
                25.577,
                25.587,
                25.598,
                25.607,
                25.617,
                25.628,
                25.637,
                25.648,
                25.658,
                25.668,
                25.678,
                25.688,
                25.698,
                25.708,
                25.718,
                25.728,
                25.739,
                25.748,
                25.759,
                25.769,
                25.778,
                25.789,
                25.798,
                25.809,
                25.819,
                25.829,
                25.839,
                25.849,
                25.859,
                25.869,
                25.879,
                25.889,
                25.899,
                25.909,
                25.919,
                25.93,
                25.939,
                25.95,
                25.959,
                25.969,
                25.98,
                25.99,
                26,
                26.01,
                26.019,
                26.03,
                26.04,
                26.05,
                26.06,
                26.07,
                26.08,
                26.09,
                26.1,
                26.11,
                26.12,
                26.13,
                26.14,
                26.15,
                26.16,
                26.17,
                26.18,
                26.19,
                26.2,
                26.21,
                26.22,
                26.23,
                26.24,
                26.25,
                26.26,
                26.27,
                26.281,
                26.29,
                26.3,
                26.31,
                26.32,
                26.33,
                26.34,
                26.35,
                26.36,
                26.37,
                26.38,
                26.39,
                26.4,
                26.41,
                26.42,
                26.43,
                26.44,
                26.45,
                26.46,
                26.47,
                26.48,
                26.49,
                26.5,
                26.51,
                26.52,
                26.53,
                26.54,
                26.55,
                26.56,
                26.57,
                26.58,
                26.59,
                26.6,
                26.61

            ],
            P999: [
                4.846,
                4.865,
                4.902,
                4.942,
                4.983,
                5.026,
                5.069,
                5.112,
                5.156,
                5.201,
                5.247,
                5.294,
                5.342,
                5.392,
                5.443,
                5.495,
                5.548,
                5.601,
                5.655,
                5.709,
                5.762,
                5.816,
                5.87,
                5.923,
                5.976,
                6.028,
                6.08,
                6.132,
                6.183,
                6.234,
                6.284,
                6.334,
                6.383,
                6.432,
                6.48,
                6.527,
                6.575,
                6.621,
                6.668,
                6.713,
                6.759,
                6.803,
                6.848,
                6.891,
                6.935,
                6.978,
                7.02,
                7.062,
                7.104,
                7.145,
                7.186,
                7.226,
                7.267,
                7.306,
                7.345,
                7.384,
                7.423,
                7.461,
                7.499,
                7.537,
                7.574,
                7.61,
                7.647,
                7.684,
                7.72,
                7.756,
                7.791,
                7.826,
                7.861,
                7.896,
                7.93,
                7.964,
                7.999,
                8.032,
                8.065,
                8.099,
                8.132,
                8.164,
                8.197,
                8.229,
                8.261,
                8.293,
                8.324,
                8.356,
                8.386,
                8.417,
                8.448,
                8.478,
                8.509,
                8.539,
                8.569,
                8.599,
                8.628,
                8.657,
                8.686,
                8.715,
                8.744,
                8.773,
                8.801,
                8.83,
                8.858,
                8.885,
                8.913,
                8.941,
                8.968,
                8.995,
                9.022,
                9.049,
                9.076,
                9.102,
                9.129,
                9.155,
                9.181,
                9.207,
                9.233,
                9.259,
                9.284,
                9.309,
                9.334,
                9.359,
                9.384,
                9.409,
                9.434,
                9.458,
                9.482,
                9.506,
                9.53,
                9.554,
                9.578,
                9.601,
                9.625,
                9.648,
                9.671,
                9.694,
                9.717,
                9.74,
                9.763,
                9.785,
                9.807,
                9.83,
                9.852,
                9.874,
                9.896,
                9.917,
                9.939,
                9.961,
                9.982,
                10.003,
                10.024,
                10.046,
                10.067,
                10.087,
                10.108,
                10.129,
                10.149,
                10.17,
                10.19,
                10.21,
                10.23,
                10.25,
                10.27,
                10.29,
                10.31,
                10.329,
                10.349,
                10.368,
                10.388,
                10.407,
                10.426,
                10.445,
                10.465,
                10.484,
                10.502,
                10.521,
                10.54,
                10.559,
                10.577,
                10.596,
                10.614,
                10.632,
                10.651,
                10.669,
                10.687,
                10.705,
                10.723,
                10.741,
                10.759,
                10.777,
                10.794,
                10.812,
                10.83,
                10.847,
                10.864,
                10.882,
                10.899,
                10.917,
                10.934,
                10.951,
                10.968,
                10.985,
                11.002,
                11.019,
                11.036,
                11.053,
                11.069,
                11.086,
                11.103,
                11.119,
                11.136,
                11.152,
                11.169,
                11.185,
                11.202,
                11.218,
                11.234,
                11.25,
                11.266,
                11.282,
                11.298,
                11.314,
                11.33,
                11.346,
                11.362,
                11.377,
                11.393,
                11.409,
                11.425,
                11.44,
                11.456,
                11.471,
                11.487,
                11.502,
                11.518,
                11.533,
                11.548,
                11.564,
                11.579,
                11.594,
                11.609,
                11.624,
                11.639,
                11.654,
                11.669,
                11.684,
                11.699,
                11.713,
                11.728,
                11.743,
                11.758,
                11.772,
                11.787,
                11.801,
                11.816,
                11.831,
                11.845,
                11.859,
                11.874,
                11.888,
                11.903,
                11.917,
                11.931,
                11.945,
                11.959,
                11.973,
                11.988,
                12.001,
                12.016,
                12.03,
                12.043,
                12.057,
                12.071,
                12.085,
                12.099,
                12.113,
                12.127,
                12.14,
                12.154,
                12.168,
                12.181,
                12.194,
                12.208,
                12.222,
                12.235,
                12.249,
                12.262,
                12.276,
                12.289,
                12.302,
                12.315,
                12.329,
                12.342,
                12.355,
                12.369,
                12.381,
                12.395,
                12.408,
                12.421,
                12.434,
                12.447,
                12.46,
                12.473,
                12.486,
                12.499,
                12.512,
                12.525,
                12.537,
                12.55,
                12.563,
                12.576,
                12.589,
                12.601,
                12.614,
                12.627,
                12.64,
                12.652,
                12.665,
                12.677,
                12.69,
                12.703,
                12.715,
                12.728,
                12.74,
                12.753,
                12.765,
                12.778,
                12.79,
                12.802,
                12.815,
                12.827,
                12.84,
                12.852,
                12.864,
                12.876,
                12.889,
                12.901,
                12.913,
                12.925,
                12.938,
                12.95,
                12.962,
                12.974,
                12.986,
                12.998,
                13.01,
                13.022,
                13.034,
                13.046,
                13.058,
                13.07,
                13.082,
                13.094,
                13.106,
                13.118,
                13.13,
                13.142,
                13.154,
                13.166,
                13.177,
                13.189,
                13.201,
                13.213,
                13.225,
                13.237,
                13.248,
                13.26,
                13.272,
                13.284,
                13.295,
                13.307,
                13.319,
                13.33,
                13.342,
                13.354,
                13.365,
                13.377,
                13.388,
                13.4,
                13.411,
                13.423,
                13.435,
                13.446,
                13.458,
                13.469,
                13.481,
                13.492,
                13.504,
                13.515,
                13.527,
                13.538,
                13.55,
                13.561,
                13.572,
                13.584,
                13.595,
                13.606,
                13.618,
                13.629,
                13.641,
                13.652,
                13.664,
                13.675,
                13.686,
                13.697,
                13.708,
                13.72,
                13.731,
                13.742,
                13.754,
                13.765,
                13.776,
                13.787,
                13.798,
                13.809,
                13.821,
                13.832,
                13.843,
                13.854,
                13.865,
                13.876,
                13.888,
                13.899,
                13.91,
                13.921,
                13.932,
                13.943,
                13.954,
                13.965,
                13.977,
                13.987,
                13.998,
                14.01,
                14.021,
                14.032,
                14.042,
                14.053,
                14.065,
                14.076,
                14.086,
                14.097,
                14.108,
                14.12,
                14.13,
                14.141,
                14.152,
                14.163,
                14.174,
                14.185,
                14.196,
                14.207,
                14.218,
                14.228,
                14.24,
                14.251,
                14.261,
                14.272,
                14.283,
                14.294,
                14.304,
                14.316,
                14.327,
                14.337,
                14.348,
                14.359,
                14.37,
                14.38,
                14.391,
                14.402,
                14.413,
                14.424,
                14.435,
                14.445,
                14.456,
                14.467,
                14.477,
                14.488,
                14.499,
                14.51,
                14.521,
                14.532,
                14.542,
                14.553,
                14.564,
                14.574,
                14.585,
                14.596,
                14.607,
                14.618,
                14.628,
                14.639,
                14.65,
                14.66,
                14.671,
                14.682,
                14.692,
                14.703,
                14.714,
                14.725,
                14.735,
                14.746,
                14.757,
                14.767,
                14.778,
                14.789,
                14.799,
                14.81,
                14.821,
                14.831,
                14.842,
                14.853,
                14.864,
                14.874,
                14.885,
                14.895,
                14.906,
                14.917,
                14.927,
                14.938,
                14.948,
                14.96,
                14.97,
                14.981,
                14.991,
                15.002,
                15.013,
                15.023,
                15.034,
                15.044,
                15.055,
                15.066,
                15.076,
                15.087,
                15.098,
                15.108,
                15.119,
                15.129,
                15.14,
                15.151,
                15.161,
                15.172,
                15.182,
                15.193,
                15.204,
                15.215,
                15.225,
                15.236,
                15.246,
                15.257,
                15.267,
                15.278,
                15.289,
                15.299,
                15.31,
                15.32,
                15.331,
                15.341,
                15.352,
                15.363,
                15.373,
                15.384,
                15.394,
                15.405,
                15.416,
                15.426,
                15.437,
                15.447,
                15.458,
                15.469,
                15.479,
                15.49,
                15.5,
                15.511,
                15.522,
                15.532,
                15.542,
                15.553,
                15.563,
                15.574,
                15.585,
                15.595,
                15.606,
                15.617,
                15.627,
                15.638,
                15.648,
                15.658,
                15.669,
                15.68,
                15.69,
                15.701,
                15.712,
                15.722,
                15.733,
                15.743,
                15.754,
                15.764,
                15.775,
                15.786,
                15.796,
                15.807,
                15.817,
                15.828,
                15.838,
                15.849,
                15.86,
                15.87,
                15.88,
                15.891,
                15.902,
                15.912,
                15.923,
                15.934,
                15.944,
                15.955,
                15.966,
                15.976,
                15.987,
                15.997,
                16.008,
                16.019,
                16.029,
                16.039,
                16.05,
                16.061,
                16.071,
                16.082,
                16.093,
                16.104,
                16.114,
                16.124,
                16.135,
                16.146,
                16.157,
                16.167,
                16.177,
                16.188,
                16.199,
                16.21,
                16.22,
                16.231,
                16.242,
                16.253,
                16.263,
                16.273,
                16.284,
                16.295,
                16.306,
                16.316,
                16.327,
                16.337,
                16.349,
                16.359,
                16.37,
                16.38,
                16.391,
                16.402,
                16.413,
                16.423,
                16.434,
                16.445,
                16.456,
                16.466,
                16.477,
                16.487,
                16.498,
                16.509,
                16.52,
                16.53,
                16.541,
                16.552,
                16.563,
                16.574,
                16.584,
                16.595,
                16.605,
                16.616,
                16.627,
                16.638,
                16.649,
                16.66,
                16.67,
                16.681,
                16.692,
                16.703,
                16.713,
                16.724,
                16.735,
                16.746,
                16.757,
                16.767,
                16.778,
                16.789,
                16.8,
                16.811,
                16.821,
                16.832,
                16.843,
                16.854,
                16.865,
                16.875,
                16.886,
                16.897,
                16.908,
                16.919,
                16.93,
                16.94,
                16.951,
                16.962,
                16.973,
                16.984,
                16.995,
                17.005,
                17.017,
                17.028,
                17.038,
                17.049,
                17.06,
                17.071,
                17.082,
                17.093,
                17.104,
                17.114,
                17.125,
                17.136,
                17.147,
                17.158,
                17.169,
                17.18,
                17.191,
                17.202,
                17.213,
                17.223,
                17.234,
                17.245,
                17.256,
                17.267,
                17.278,
                17.289,
                17.3,
                17.311,
                17.322,
                17.333,
                17.344,
                17.355,
                17.365,
                17.376,
                17.387,
                17.399,
                17.409,
                17.42,
                17.431,
                17.442,
                17.453,
                17.464,
                17.475,
                17.486,
                17.497,
                17.508,
                17.519,
                17.53,
                17.541,
                17.552,
                17.563,
                17.574,
                17.585,
                17.596,
                17.607,
                17.618,
                17.629,
                17.64,
                17.651,
                17.661,
                17.672,
                17.684,
                17.695,
                17.706,
                17.717,
                17.728,
                17.739,
                17.75,
                17.76,
                17.772,
                17.783,
                17.794,
                17.805,
                17.816,
                17.827,
                17.838,
                17.849,
                17.86,
                17.871,
                17.882,
                17.893,
                17.904,
                17.915,
                17.925,
                17.936,
                17.948,
                17.959,
                17.97,
                17.981,
                17.992,
                18.003,
                18.014,
                18.025,
                18.036,
                18.047,
                18.058,
                18.069,
                18.08,
                18.091,
                18.102,
                18.113,
                18.124,
                18.135,
                18.146,
                18.157,
                18.168,
                18.179,
                18.19,
                18.201,
                18.212,
                18.223,
                18.234,
                18.245,
                18.256,
                18.267,
                18.278,
                18.289,
                18.301,
                18.311,
                18.322,
                18.333,
                18.344,
                18.355,
                18.366,
                18.377,
                18.388,
                18.399,
                18.41,
                18.421,
                18.432,
                18.443,
                18.454,
                18.465,
                18.476,
                18.487,
                18.498,
                18.509,
                18.52,
                18.531,
                18.542,
                18.553,
                18.564,
                18.575,
                18.586,
                18.597,
                18.608,
                18.619,
                18.629,
                18.641,
                18.652,
                18.662,
                18.673,
                18.685,
                18.695,
                18.706,
                18.717,
                18.729,
                18.739,
                18.75,
                18.761,
                18.772,
                18.783,
                18.794,
                18.804,
                18.816,
                18.827,
                18.837,
                18.849,
                18.859,
                18.87,
                18.882,
                18.892,
                18.903,
                18.914,
                18.925,
                18.936,
                18.947,
                18.958,
                18.969,
                18.98,
                18.99,
                19.001,
                19.012,
                19.023,
                19.034,
                19.045,
                19.056,
                19.067,
                19.077,
                19.088,
                19.099,
                19.11,
                19.121,
                19.132,
                19.143,
                19.154,
                19.165,
                19.176,
                19.186,
                19.198,
                19.208,
                19.219,
                19.23,
                19.24,
                19.252,
                19.262,
                19.273,
                19.284,
                19.295,
                19.306,
                19.317,
                19.328,
                19.339,
                19.349,
                19.361,
                19.371,
                19.382,
                19.393,
                19.404,
                19.414,
                19.426,
                19.436,
                19.447,
                19.458,
                19.469,
                19.479,
                19.491,
                19.502,
                19.512,
                19.523,
                19.534,
                19.545,
                19.555,
                19.566,
                19.578,
                19.588,
                19.599,
                19.61,
                19.621,
                19.632,
                19.642,
                19.653,
                19.664,
                19.675,
                19.686,
                19.696,
                19.707,
                19.718,
                19.729,
                19.74,
                19.75,
                19.761,
                19.773,
                19.783,
                19.794,
                19.805,
                19.816,
                19.827,
                19.838,
                19.848,
                19.859,
                19.87,
                19.881,
                19.891,
                19.903,
                19.914,
                19.924,
                19.935,
                19.946,
                19.957,
                19.968,
                19.979,
                19.99,
                20,
                20.011,
                20.022,
                20.033,
                20.043,
                20.055,
                20.065,
                20.077,
                20.087,
                20.098,
                20.109,
                20.12,
                20.13,
                20.141,
                20.153,
                20.163,
                20.175,
                20.185,
                20.196,
                20.207,
                20.218,
                20.229,
                20.239,
                20.25,
                20.261,
                20.272,
                20.283,
                20.294,
                20.305,
                20.316,
                20.327,
                20.338,
                20.349,
                20.36,
                20.371,
                20.381,
                20.392,
                20.403,
                20.414,
                20.425,
                20.436,
                20.447,
                20.458,
                20.469,
                20.48,
                20.491,
                20.502,
                20.513,
                20.524,
                20.535,
                20.546,
                20.557,
                20.568,
                20.579,
                20.59,
                20.601,
                20.612,
                20.623,
                20.634,
                20.645,
                20.656,
                20.667,
                20.678,
                20.689,
                20.699,
                20.71,
                20.721,
                20.732,
                20.744,
                20.754,
                20.766,
                20.777,
                20.788,
                20.799,
                20.81,
                20.821,
                20.833,
                20.844,
                20.855,
                20.866,
                20.877,
                20.888,
                20.899,
                20.91,
                20.921,
                20.932,
                20.943,
                20.954,
                20.965,
                20.977,
                20.988,
                20.999,
                21.01,
                21.021,
                21.032,
                21.043,
                21.054,
                21.065,
                21.077,
                21.088,
                21.1,
                21.111,
                21.122,
                21.133,
                21.144,
                21.156,
                21.167,
                21.178,
                21.189,
                21.2,
                21.211,
                21.223,
                21.234,
                21.245,
                21.256,
                21.267,
                21.279,
                21.29,
                21.301,
                21.312,
                21.323,
                21.335,
                21.346,
                21.358,
                21.369,
                21.38,
                21.391,
                21.402,
                21.414,
                21.425,
                21.437,
                21.448,
                21.459,
                21.471,
                21.482,
                21.493,
                21.504,
                21.516,
                21.527,
                21.538,
                21.549,
                21.561,
                21.572,
                21.583,
                21.595,
                21.607,
                21.618,
                21.63,
                21.641,
                21.652,
                21.664,
                21.675,
                21.686,
                21.698,
                21.709,
                21.72,
                21.732,
                21.743,
                21.754,
                21.766,
                21.777,
                21.789,
                21.801,
                21.812,
                21.824,
                21.835,
                21.846,
                21.858,
                21.869,
                21.881,
                21.892,
                21.903,
                21.915,
                21.926,
                21.938,
                21.95,
                21.961,
                21.973,
                21.984,
                21.996,
                22.007,
                22.019,
                22.03,
                22.042,
                22.053,
                22.065,
                22.076,
                22.088,
                22.1,
                22.111,
                22.122,
                22.134,
                22.146,
                22.157,
                22.169,
                22.18,
                22.192,
                22.203,
                22.215,
                22.226,
                22.238,
                22.249,
                22.262,
                22.273,
                22.285,
                22.297,
                22.308,
                22.32,
                22.331,
                22.343,
                22.354,
                22.366,
                22.377,
                22.389,
                22.401,
                22.413,
                22.424,
                22.436,
                22.448,
                22.459,
                22.471,
                22.482,
                22.494,
                22.506,
                22.518,
                22.529,
                22.541,
                22.553,
                22.564,
                22.576,
                22.588,
                22.599,
                22.611,
                22.622,
                22.635,
                22.646,
                22.658,
                22.67,
                22.682,
                22.693,
                22.705,
                22.716,
                22.728,
                22.74,
                22.751,
                22.764,
                22.776,
                22.787,
                22.799,
                22.811,
                22.822,
                22.834,
                22.846,
                22.857,
                22.869,
                22.881,
                22.893,
                22.905,
                22.917,
                22.928,
                22.94,
                22.952,
                22.963,
                22.976,
                22.988,
                22.999,
                23.011,
                23.023,
                23.034,
                23.046,
                23.058,
                23.07,
                23.082,
                23.094,
                23.106,
                23.117,
                23.129,
                23.141,
                23.152,
                23.165,
                23.177,
                23.188,
                23.2,
                23.212,
                23.224,
                23.235,
                23.247,
                23.259,
                23.272,
                23.283,
                23.295,
                23.307,
                23.318,
                23.33,
                23.342,
                23.354,
                23.366,
                23.378,
                23.39,
                23.402,
                23.413,
                23.425,
                23.437,
                23.449,
                23.461,
                23.473,
                23.485,
                23.497,
                23.508,
                23.52,
                23.532,
                23.544,
                23.557,
                23.568,
                23.58,
                23.592,
                23.604,
                23.615,
                23.628,
                23.64,
                23.652,
                23.663,
                23.675,
                23.687,
                23.699,
                23.711,
                23.723,
                23.735,
                23.747,
                23.759,
                23.771,
                23.783,
                23.795,
                23.807,
                23.819,
                23.83,
                23.842,
                23.855,
                23.867,
                23.879,
                23.89,
                23.902,
                23.914,
                23.926,
                23.939,
                23.95,
                23.962,
                23.974,
                23.986,
                23.998,
                24.01,
                24.022,
                24.034,
                24.046,
                24.057,
                24.069,
                24.082,
                24.094,
                24.106,
                24.117,
                24.129,
                24.142,
                24.154,
                24.166,
                24.177,
                24.19,
                24.202,
                24.214,
                24.226,
                24.238,
                24.249,
                24.262,
                24.274,
                24.286,
                24.298,
                24.309,
                24.321,
                24.334,
                24.346,
                24.358,
                24.369,
                24.382,
                24.394,
                24.406,
                24.418,
                24.429,
                24.442,
                24.454,
                24.466,
                24.478,
                24.49,
                24.502,
                24.514,
                24.526,
                24.538,
                24.55,
                24.562,
                24.574,
                24.586,
                24.599,
                24.61,
                24.622,
                24.634,
                24.647,
                24.659,
                24.67,
                24.682,
                24.694,
                24.707,
                24.719,
                24.73,
                24.743,
                24.755,
                24.767,
                24.779,
                24.791,
                24.803,
                24.815,
                24.827,
                24.84,
                24.851,
                24.863,
                24.876,
                24.888,
                24.899,
                24.911,
                24.923,
                24.936,
                24.948,
                24.959,
                24.972,
                24.984,
                24.996,
                25.008,
                25.02,
                25.032,
                25.045,
                25.057,
                25.068,
                25.08,
                25.092,
                25.105,
                25.117,
                25.129,
                25.141,
                25.153,
                25.166,
                25.177,
                25.189,
                25.201,
                25.214,
                25.226,
                25.238,
                25.25,
                25.262,
                25.275,
                25.286,
                25.298,
                25.311,
                25.323,
                25.335,
                25.347,
                25.359,
                25.371,
                25.384,
                25.395,
                25.407,
                25.42,
                25.432,
                25.444,
                25.456,
                25.468,
                25.48,
                25.492,
                25.504,
                25.516,
                25.529,
                25.54,
                25.553,
                25.565,
                25.577,
                25.589,
                25.601,
                25.614,
                25.626,
                25.639,
                25.65,
                25.662,
                25.675,
                25.687,
                25.699,
                25.711,
                25.723,
                25.735,
                25.747,
                25.76,
                25.772,
                25.784,
                25.796,
                25.808,
                25.821,
                25.832,
                25.845,
                25.857,
                25.87,
                25.881,
                25.893,
                25.906,
                25.918,
                25.93,
                25.942,
                25.955,
                25.966,
                25.979,
                25.991,
                26.003,
                26.015,
                26.027,
                26.04,
                26.052,
                26.064,
                26.076,
                26.089,
                26.1,
                26.113,
                26.125,
                26.138,
                26.149,
                26.162,
                26.174,
                26.186,
                26.198,
                26.211,
                26.223,
                26.235,
                26.247,
                26.26,
                26.271,
                26.284,
                26.296,
                26.308,
                26.32,
                26.333,
                26.345,
                26.357,
                26.369,
                26.382,
                26.393,
                26.406,
                26.419,
                26.43,
                26.443,
                26.455,
                26.467,
                26.479,
                26.492,
                26.505,
                26.516,
                26.529,
                26.541,
                26.553,
                26.565,
                26.578,
                26.59,
                26.602,
                26.615,
                26.626,
                26.639,
                26.651,
                26.663,
                26.676,
                26.688,
                26.7,
                26.712,
                26.725,
                26.736,
                26.749,
                26.762,
                26.774,
                26.786,
                26.799,
                26.81,
                26.823,
                26.836,
                26.847,
                26.86,
                26.871,
                26.884,
                26.897,
                26.908,
                26.921,
                26.933,
                26.945,
                26.958,
                26.971,
                26.982,
                26.995,
                27.007,
                27.019,
                27.032,
                27.043,
                27.056,
                27.069,
                27.08,
                27.093,
                27.106,
                27.117,
                27.13,
                27.142,
                27.154,
                27.167,
                27.179,
                27.191,
                27.204,
                27.216,
                27.229,
                27.24,
                27.253,
                27.266,
                27.277,
                27.29,
                27.302,
                27.314,
                27.327,
                27.339,
                27.351,
                27.363,
                27.376,
                27.388,
                27.4,
                27.413,
                27.425,
                27.437,
                27.45,
                27.462,
                27.474,
                27.486,
                27.499,
                27.511,
                27.523,
                27.536,
                27.548,
                27.561,
                27.572,
                27.585,
                27.598,
                27.609,
                27.622,
                27.634,
                27.647,
                27.658,
                27.671,
                27.684,
                27.696,
                27.709,
                27.72,
                27.733,
                27.745,
                27.757,
                27.769,
                27.782,
                27.795,
                27.806,
                27.819,
                27.831,
                27.844,
                27.856,
                27.868,
                27.88,
                27.893,
                27.905,
                27.917,
                27.93,
                27.942,
                27.955,
                27.967,
                27.979,
                27.991,
                28.004,
                28.015,
                28.028,
                28.041,
                28.053,
                28.066,
                28.078,
                28.09,
                28.102,
                28.115,
                28.127,
                28.14,
                28.151,
                28.164,
                28.176,
                28.188,
                28.2,
                28.213,
                28.225,
                28.238,
                28.25,
                28.262,
                28.274,
                28.287,
                28.3,
                28.312,
                28.324,
                28.336,
                28.349,
                28.361,
                28.374,
                28.385,
                28.398,
                28.41,
                28.423,
                28.435,
                28.447,
                28.459,
                28.472,
                28.484,
                28.497,
                28.509,
                28.521,
                28.533,
                28.546,
                28.558,
                28.571,
                28.582,
                28.595,
                28.607,
                28.62,
                28.632,
                28.644,
                28.656,
                28.668,
                28.681,
                28.693,
                28.706,
                28.717,
                28.73,
                28.742,
                28.755,
                28.767,
                28.779,
                28.791,
                28.804,
                28.816,
                28.829,
                28.841,
                28.854,
                28.865,
                28.878,
                28.89,
                28.902,
                28.915,
                28.926,
                28.939,
                28.951,
                28.964,
                28.976,
                28.989,
                29.001,
                29.013,
                29.025,
                29.038,
                29.05,
                29.062,
                29.074,
                29.086,
                29.099,
                29.111,
                29.124,
                29.136,
                29.149,
                29.16,
                29.172,
                29.185,
                29.197,
                29.21,
                29.222,
                29.234,
                29.246,
                29.259,
                29.271,
                29.283,
                29.296,
                29.307,
                29.32,
                29.332,
                29.345,
                29.357,
                29.369,
                29.382,
                29.393,
                29.406,
                29.418,
                29.431,
                29.443,
                29.455,
                29.467,
                29.479,
                29.492,
                29.504,
                29.516,
                29.529,
                29.541,
                29.553,
                29.565,
                29.578,
                29.59,
                29.602,
                29.615,
                29.627,
                29.639,
                29.651,
                29.663,
                29.676,
                29.688,
                29.7,
                29.712,
                29.724,
                29.737,
                29.749,
                29.762,
                29.774,
                29.785,
                29.798,
                29.81,
                29.823,
                29.835,
                29.847,
                29.86,
                29.871,
                29.883,
                29.896,
                29.908,
                29.921,
                29.933,
                29.944,
                29.957,
                29.969,
                29.982,
                29.994,
                30.006,
                30.019,
                30.03,
                30.042,
                30.055,
                30.067,
                30.08,
                30.092,
                30.104,
                30.116,
                30.128,
                30.14,
                30.153,
                30.165,
                30.178,
                30.19,
                30.201,
                30.214,
                30.226,
                30.238,
                30.251,
                30.262,
                30.276,
                30.287,
                30.299,
                30.312,
                30.324,
                30.336,
                30.349,
                30.361,
                30.372,
                30.385
            ]
        }
    },
    height: {
        boys: {
            P01: [
                44.034,
                44.205,
                44.376,
                44.548,
                44.719,
                44.89,
                45.061,
                45.234,
                45.406,
                45.577,
                45.749,
                45.923,
                46.094,
                46.266,
                46.439,
                46.585,
                46.728,
                46.871,
                47.014,
                47.155,
                47.296,
                47.436,
                47.575,
                47.714,
                47.849,
                47.986,
                48.12,
                48.255,
                48.388,
                48.52,
                48.652,
                48.783,
                48.912,
                49.04,
                49.168,
                49.294,
                49.42,
                49.544,
                49.667,
                49.79,
                49.912,
                50.031,
                50.152,
                50.271,
                50.388,
                50.504,
                50.621,
                50.736,
                50.849,
                50.964,
                51.076,
                51.187,
                51.297,
                51.407,
                51.516,
                51.624,
                51.732,
                51.838,
                51.944,
                52.048,
                52.153,
                52.257,
                52.359,
                52.462,
                52.565,
                52.665,
                52.767,
                52.866,
                52.966,
                53.065,
                53.162,
                53.261,
                53.358,
                53.453,
                53.549,
                53.646,
                53.74,
                53.833,
                53.927,
                54.019,
                54.111,
                54.203,
                54.294,
                54.384,
                54.474,
                54.564,
                54.652,
                54.739,
                54.826,
                54.914,
                55,
                55.085,
                55.17,
                55.256,
                55.338,
                55.422,
                55.504,
                55.587,
                55.668,
                55.75,
                55.83,
                55.911,
                55.99,
                56.07,
                56.148,
                56.225,
                56.303,
                56.38,
                56.456,
                56.533,
                56.608,
                56.682,
                56.756,
                56.829,
                56.904,
                56.977,
                57.049,
                57.121,
                57.192,
                57.263,
                57.333,
                57.403,
                57.472,
                57.541,
                57.61,
                57.678,
                57.746,
                57.813,
                57.88,
                57.947,
                58.013,
                58.078,
                58.142,
                58.207,
                58.272,
                58.336,
                58.4,
                58.462,
                58.525,
                58.588,
                58.65,
                58.711,
                58.773,
                58.834,
                58.894,
                58.955,
                59.013,
                59.074,
                59.134,
                59.192,
                59.251,
                59.308,
                59.367,
                59.424,
                59.482,
                59.538,
                59.596,
                59.652,
                59.707,
                59.764,
                59.819,
                59.875,
                59.93,
                59.984,
                60.039,
                60.093,
                60.146,
                60.202,
                60.254,
                60.307,
                60.359,
                60.412,
                60.466,
                60.518,
                60.569,
                60.621,
                60.672,
                60.725,
                60.776,
                60.827,
                60.877,
                60.927,
                60.978,
                61.028,
                61.077,
                61.127,
                61.177,
                61.226,
                61.275,
                61.324,
                61.373,
                61.422,
                61.47,
                61.519,
                61.567,
                61.615,
                61.663,
                61.709,
                61.757,
                61.805,
                61.852,
                61.899,
                61.947,
                61.992,
                62.039,
                62.086,
                62.132,
                62.177,
                62.223,
                62.27,
                62.316,
                62.36,
                62.407,
                62.453,
                62.496,
                62.542,
                62.588,
                62.632,
                62.677,
                62.721,
                62.766,
                62.811,
                62.854,
                62.9,
                62.942,
                62.987,
                63.03,
                63.075,
                63.118,
                63.162,
                63.205,
                63.249,
                63.292,
                63.336,
                63.378,
                63.422,
                63.464,
                63.508,
                63.55,
                63.592,
                63.636,
                63.678,
                63.719,
                63.763,
                63.805,
                63.848,
                63.89,
                63.931,
                63.974,
                64.016,
                64.057,
                64.098,
                64.141,
                64.182,
                64.223,
                64.264,
                64.307,
                64.348,
                64.388,
                64.429,
                64.472,
                64.512,
                64.553,
                64.593,
                64.634,
                64.676,
                64.717,
                64.757,
                64.797,
                64.837,
                64.877,
                64.917,
                64.957,
                64.999,
                65.039,
                65.079,
                65.118,
                65.158,
                65.198,
                65.237,
                65.277,
                65.316,
                65.356,
                65.395,
                65.434,
                65.474,
                65.513,
                65.552,
                65.591,
                65.63,
                65.669,
                65.708,
                65.747,
                65.785,
                65.824,
                65.863,
                65.901,
                65.94,
                65.978,
                66.015,
                66.053,
                66.091,
                66.13,
                66.168,
                66.206,
                66.244,
                66.282,
                66.318,
                66.356,
                66.394,
                66.432,
                66.469,
                66.507,
                66.543,
                66.58,
                66.618,
                66.655,
                66.693,
                66.728,
                66.765,
                66.803,
                66.84,
                66.875,
                66.912,
                66.949,
                66.986,
                67.021,
                67.058,
                67.095,
                67.13,
                67.166,
                67.203,
                67.24,
                67.274,
                67.311,
                67.347,
                67.382,
                67.418,
                67.455,
                67.489,
                67.525,
                67.561,
                67.595,
                67.632,
                67.668,
                67.702,
                67.738,
                67.771,
                67.807,
                67.843,
                67.877,
                67.913,
                67.946,
                67.982,
                68.018,
                68.051,
                68.087,
                68.12,
                68.156,
                68.189,
                68.225,
                68.258,
                68.293,
                68.329,
                68.362,
                68.397,
                68.43,
                68.465,
                68.498,
                68.533,
                68.566,
                68.601,
                68.634,
                68.669,
                68.701,
                68.736,
                68.769,
                68.804,
                68.836,
                68.871,
                68.903,
                68.936,
                68.97,
                69.002,
                69.037,
                69.069,
                69.104,
                69.136,
                69.17,
                69.202,
                69.234,
                69.268,
                69.3,
                69.335,
                69.366,
                69.398,
                69.432,
                69.464,
                69.498,
                69.53,
                69.561,
                69.595,
                69.627,
                69.658,
                69.692,
                69.723,
                69.757,
                69.788,
                69.82,
                69.853,
                69.884,
                69.915,
                69.949,
                69.98,
                70.011,
                70.044,
                70.075,
                70.106,
                70.14,
                70.171,
                70.201,
                70.234,
                70.265,
                70.296,
                70.329,
                70.36,
                70.39,
                70.421,
                70.454,
                70.484,
                70.515,
                70.547,
                70.578,
                70.608,
                70.638,
                70.671,
                70.701,
                70.731,
                70.762,
                70.794,
                70.824,
                70.854,
                70.884,
                70.917,
                70.947,
                70.977,
                71.007,
                71.039,
                71.069,
                71.098,
                71.128,
                71.16,
                71.19,
                71.22,
                71.249,
                71.279,
                71.311,
                71.341,
                71.37,
                71.4,
                71.429,
                71.461,
                71.49,
                71.52,
                71.549,
                71.578,
                71.61,
                71.639,
                71.668,
                71.698,
                71.727,
                71.756,
                71.787,
                71.816,
                71.845,
                71.874,
                71.903,
                71.932,
                71.964,
                71.993,
                72.021,
                72.05,
                72.079,
                72.108,
                72.136,
                72.168,
                72.196,
                72.225,
                72.254,
                72.282,
                72.311,
                72.339,
                72.37,
                72.399,
                72.427,
                72.455,
                72.484,
                72.512,
                72.541,
                72.569,
                72.597,
                72.628,
                72.656,
                72.684,
                72.712,
                72.74,
                72.768,
                72.796,
                72.825,
                72.853,
                72.881,
                72.909,
                72.936,
                72.967,
                72.995,
                73.022,
                73.05,
                73.078,
                73.106,
                73.133,
                73.161,
                73.189,
                73.216,
                73.244,
                73.272,
                73.299,
                73.327,
                73.354,
                73.382,
                73.409,
                73.439,
                73.466,
                73.494,
                73.521,
                73.548,
                73.575,
                73.603,
                73.63,
                73.657,
                73.684,
                73.711,
                73.738,
                73.765,
                73.792,
                73.819,
                73.846,
                73.873,
                73.9,
                73.927,
                73.954,
                73.981,
                74.008,
                74.034,
                74.061,
                74.088,
                74.115,
                74.141,
                74.168,
                74.195,
                74.221,
                74.248,
                74.274,
                74.301,
                74.327,
                74.354,
                74.38,
                74.406,
                74.433,
                74.459,
                74.485,
                74.512,
                74.538,
                74.564,
                74.59,
                74.616,
                74.643,
                74.666,
                74.692,
                74.718,
                74.744,
                74.77,
                74.796,
                74.822,
                74.848,
                74.874,
                74.9,
                74.926,
                74.952,
                74.977,
                75.003,
                75.029,
                75.055,
                75.08,
                75.106,
                75.132,
                75.157,
                75.18,
                75.206,
                75.231,
                75.257,
                75.282,
                75.308,
                75.333,
                75.359,
                75.384,
                75.41,
                75.435,
                75.46,
                75.486,
                75.511,
                75.534,
                75.559,
                75.584,
                75.609,
                75.634,
                75.659,
                75.685,
                75.71,
                75.735,
                75.76,
                75.785,
                75.807,
                75.832,
                75.857,
                75.882,
                75.907,
                75.932,
                75.957,
                75.982,
                76.006,
                76.031,
                76.053,
                76.078,
                76.103,
                76.127,
                76.152,
                76.177,
                76.201,
                76.226,
                76.251,
                76.272,
                76.297,
                76.321,
                76.346,
                76.37,
                76.395,
                76.419,
                76.444,
                76.468,
                76.49,
                76.514,
                76.538,
                76.563,
                76.587,
                76.611,
                76.635,
                76.659,
                76.681,
                76.705,
                76.729,
                76.753,
                76.777,
                76.801,
                76.825,
                76.847,
                76.871,
                76.895,
                76.919,
                76.942,
                76.966,
                76.99,
                77.014,
                77.035,
                77.059,
                77.083,
                77.107,
                77.13,
                77.154,
                77.178,
                77.199,
                77.223,
                77.246,
                77.27,
                77.293,
                77.317,
                77.341,
                77.361,
                77.385,
                77.409,
                77.432,
                77.455,
                77.479,
                77.502,
                77.523,
                77.546,
                77.57,
                77.593,
                77.617,
                77.64,
                77.663,
                77.684,
                77.707,
                77.73,
                77.753,
                77.777,
                77.8,
                77.823,
                77.843,
                77.867,
                77.89,
                77.913,
                77.936,
                77.959,
                77.982,
                78.002,
                78.025,
                78.048,
                78.071,
                78.094,
                78.117,
                78.14,
                78.16,
                78.183,
                78.206,
                78.229,
                78.251,
                78.274,
                78.297,
                78.317,
                78.34,
                78.362,
                77.685,
                77.708,
                77.73,
                77.753,
                77.773,
                77.795,
                77.818,
                77.84,
                77.863,
                77.885,
                77.908,
                77.928,
                77.95,
                77.972,
                77.995,
                78.017,
                78.039,
                78.062,
                78.084,
                78.104,
                78.126,
                78.148,
                78.17,
                78.193,
                78.215,
                78.237,
                78.256,
                78.278,
                78.3,
                78.322,
                78.344,
                78.366,
                78.388,
                78.41,
                78.43,
                78.452,
                78.473,
                78.495,
                78.517,
                78.539,
                78.561,
                78.58,
                78.602,
                78.623,
                78.645,
                78.667,
                78.688,
                78.71,
                78.732,
                78.75,
                78.772,
                78.794,
                78.815,
                78.837,
                78.858,
                78.88,
                78.901,
                78.922,
                78.941,
                78.962,
                78.984,
                79.005,
                79.026,
                79.048,
                79.069,
                79.09,
                79.111,
                79.13,
                79.151,
                79.172,
                79.193,
                79.214,
                79.235,
                79.256,
                79.278,
                79.299,
                79.32,
                79.338,
                79.359,
                79.38,
                79.401,
                79.421,
                79.442,
                79.463,
                79.484,
                79.505,
                79.526,
                79.546,
                79.564,
                79.585,
                79.606,
                79.626,
                79.647,
                79.668,
                79.688,
                79.709,
                79.729,
                79.75,
                79.77,
                79.791,
                79.809,
                79.829,
                79.85,
                79.87,
                79.89,
                79.911,
                79.931,
                79.951,
                79.972,
                79.992,
                80.012,
                80.032,
                80.053,
                80.073,
                80.093,
                80.11,
                80.131,
                80.151,
                80.171,
                80.191,
                80.211,
                80.231,
                80.251,
                80.271,
                80.291,
                80.311,
                80.33,
                80.35,
                80.37,
                80.39,
                80.41,
                80.43,
                80.449,
                80.469,
                80.489,
                80.509,
                80.528,
                80.545,
                80.565,
                80.584,
                80.604,
                80.624,
                80.643,
                80.663,
                80.682,
                80.702,
                80.721,
                80.74,
                80.76,
                80.779,
                80.799,
                80.818,
                80.837,
                80.857,
                80.876,
                80.895,
                80.914,
                80.934,
                80.953,
                80.972,
                80.991,
                81.01,
                81.029,
                81.048,
                81.068,
                81.087,
                81.106,
                81.125,
                81.144,
                81.163,
                81.181,
                81.2,
                81.219,
                81.238,
                81.257,
                81.276,
                81.295,
                81.313,
                81.332,
                81.351,
                81.37,
                81.388,
                81.407,
                81.426,
                81.445,
                81.463,
                81.482,
                81.5,
                81.519,
                81.537,
                81.559,
                81.577,
                81.596,
                81.614,
                81.633,
                81.651,
                81.67,
                81.688,
                81.707,
                81.725,
                81.743,
                81.762,
                81.78,
                81.798,
                81.816,
                81.835,
                81.853,
                81.871,
                81.889,
                81.907,
                81.928,
                81.947,
                81.965,
                81.983,
                82.001,
                82.019,
                82.037,
                82.055,
                82.073,
                82.091,
                82.109,
                82.127,
                82.145,
                82.163,
                82.184,
                82.201,
                82.219,
                82.237,
                82.255,
                82.273,
                82.29,
                82.308,
                82.326,
                82.344,
                82.361,
                82.382,
                82.4,
                82.417,
                82.435,
                82.453,
                82.47,
                82.488,
                82.505,
                82.523,
                82.54,
                82.561,
                82.578,
                82.596,
                82.613,
                82.631,
                82.648,
                82.666,
                82.683,
                82.703,
                82.721,
                82.738,
                82.755,
                82.773,
                82.79,
                82.807,
                82.825,
                82.845,
                82.862,
                82.879,
                82.896,
                82.914,
                82.931,
                82.948,
                82.968,
                82.985,
                83.002,
                83.019,
                83.036,
                83.053,
                83.071,
                83.09,
                83.108,
                83.125,
                83.142,
                83.159,
                83.176,
                83.195,
                83.212,
                83.229,
                83.246,
                83.263,
                83.28,
                83.3,
                83.317,
                83.334,
                83.35,
                83.367,
                83.387,
                83.404,
                83.421,
                83.437,
                83.454,
                83.471,
                83.491,
                83.507,
                83.524,
                83.541,
                83.557,
                83.577,
                83.594,
                83.61,
                83.627,
                83.644,
                83.663,
                83.68,
                83.696,
                83.713,
                83.73,
                83.749,
                83.766,
                83.782,
                83.799,
                83.818,
                83.835,
                83.851,
                83.868,
                83.884,
                83.903,
                83.92,
                83.936,
                83.953,
                83.972,
                83.989,
                84.005,
                84.021,
                84.038,
                84.057,
                84.073,
                84.09,
                84.106,
                84.125,
                84.142,
                84.158,
                84.174,
                84.193,
                84.21,
                84.226,
                84.242,
                84.261,
                84.277,
                84.294,
                84.31,
                84.329,
                84.345,
                84.361,
                84.377,
                84.396,
                84.413,
                84.429,
                84.448,
                84.464,
                84.48,
                84.496,
                84.515,
                84.531,
                84.547,
                84.563,
                84.582,
                84.598,
                84.614,
                84.633,
                84.649,
                84.665,
                84.681,
                84.7,
                84.716,
                84.732,
                84.751,
                84.767,
                84.782,
                84.798,
                84.817,
                84.833,
                84.849,
                84.868,
                84.884,
                84.899,
                84.918,
                84.934,
                84.95,
                84.966,
                84.984,
                85,
                85.016,
                85.035,
                85.05,
                85.066,
                85.085,
                85.1,
                85.116,
                85.135,
                85.15,
                85.166,
                85.185,
                85.2,
                85.216,
                85.235,
                85.25,
                85.266,
                85.284,
                85.3,
                85.316,
                85.334,
                85.35,
                85.365,
                85.384,
                85.399,
                85.415,
                85.433,
                85.449,
                85.464,
                85.483,
                85.498,
                85.514,
                85.532,
                85.548,
                85.563,
                85.582,
                85.597,
                85.612,
                85.631,
                85.646,
                85.662,
                85.68,
                85.695,
                85.714,
                85.729,
                85.744,
                85.763,
                85.778,
                85.793,
                85.812,
                85.827,
                85.842,
                85.861,
                85.876,
                85.894,
                85.909,
                85.925,
                85.943,
                85.958,
                85.973,
                85.991,
                86.007,
                86.025,
                86.04,
                86.055,
                86.073,
                86.089,
                86.107,
                86.122,
                86.137,
                86.155,
                86.17,
                86.188,
                86.203,
                86.218,
                86.236,
                86.252,
                86.267,
                86.285,
                86.3,
                86.318,
                86.333,
                86.348,
                86.366,
                86.381,
                86.399,
                86.414,
                86.432,
                86.447,
                86.462,
                86.479,
                86.494,
                86.512,
                86.527,
                86.542,
                86.56,
                86.575,
                86.593,
                86.608,
                86.622,
                86.64,
                86.655,
                86.673,
                86.688,
                86.706,
                86.72,
                86.735,
                86.753,
                86.768,
                86.785,
                86.8,
                86.818,
                86.833,
                86.847,
                86.865,
                86.88,
                86.898,
                86.912,
                86.93,
                86.945,
                86.959,
                86.977,
                86.992,
                87.009,
                87.024,
                87.041,
                87.056,
                87.074,
                87.088,
                87.103,
                87.12,
                87.135,
                87.152,
                87.167,
                87.184,
                87.199,
                87.217,
                87.231,
                87.249,
                87.263,
                87.277,
                87.295,
                87.309,
                87.327,
                87.341,
                87.359,
                87.373,
                87.391,
                87.405,
                87.422,
                87.437,
                87.451,
                87.468,
                87.483,
                87.5,
                87.514,
                87.532,
                87.546,
                87.563,
                87.578,
                87.595,
                87.609,
                87.627,
                87.641,
                87.658,
                87.672,
                87.69,
                87.704,
                87.718,
                87.735,
                87.749,
                87.767,
                87.781,
                87.798,
                87.812,
                87.829,
                87.844,
                87.861,
                87.875,
                87.892,
                87.906,
                87.923,
                87.937,
                87.954,
                87.968,
                87.986,
                88,
                88.017,
                88.031,
                88.048,
                88.062,
                88.079,
                88.093,
                88.11,
                88.124,
                88.141,
                88.155,
                88.169,
                88.186,
                88.2,
                88.217,
                88.231,
                88.248,
                88.261,
                88.278,
                88.292,
                88.309,
                88.323,
                88.34,
                88.354,
                88.371,
                88.385,
                88.402,
                88.415,
                88.432,
                88.446,
                88.463,
                88.477,
                88.493,
                88.507,
                88.524,
                88.538,
                88.555,
                88.568,
                88.585,
                88.599,
                88.616,
                88.632,
                88.646,
                88.663,
                88.677,
                88.693,
                88.707,
                88.724,
                88.737,
                88.754,
                88.768,
                88.784,
                88.798,
                88.815,
                88.828,
                88.845,
                88.859,
                88.875,
                88.889,
                88.905,
                88.919,
                88.936,
                88.949,
                88.966,
                88.979,
                88.996,
                89.009,
                89.026,
                89.04,
                89.056,
                89.07,
                89.086,
                89.1,
                89.116,
                89.133,
                89.146,
                89.163,
                89.176,
                89.193,
                89.206,
                89.223,
                89.236,
                89.253,
                89.266,
                89.282,
                89.296,
                89.312,
                89.326,
                89.342,
                89.356,
                89.372,
                89.385,
                89.402,
                89.415,
                89.432,
                89.448,
                89.461,
                89.478,
                89.491,
                89.507,
                89.521,
                89.537,
                89.55,
                89.567,
                89.58,
                89.596,
                89.61,
                89.626,
                89.639,
                89.656,
                89.672,
                89.685,
                89.702,
                89.715,
                89.731,
                89.744,
                89.761,
                89.774,
                89.79,
                89.803,
                89.82,
                89.833,
                89.849,
                89.862,
                89.879,
                89.895,
                89.908,
                89.924,
                89.937,
                89.954,
                89.967,
                89.983,
                89.996,
                90.012,
                90.026,
                90.042,
                90.055,
                90.071,
                90.087,
                90.1,
                90.117,
                90.13,
                90.146,
                90.159,
                90.175,
                90.188,
                90.204,
                90.217,
                90.234,
                90.25,
                90.263,
                90.279,
                90.292,
                90.308,
                90.321,
                90.338,
                90.351,
                90.367,
                90.38,
                90.396,
                90.412,
                90.425,
                90.441,
                90.454,
                90.47,
                90.483,
                90.499,
                90.512,
                90.528,
                90.541,
                90.557,
                90.574,
                90.587,
                90.603,
                90.616,
                90.632,
                90.645,
                90.661,
                90.674,
                90.69,
                90.706,
                90.719,
                90.735,
                90.748,
                90.764,
                90.777,
                90.793,
                90.806,
                90.822,
                90.838,
                90.851,
                90.867,
                90.88,
                90.896,
                90.909,
                90.925,
                90.937,
                90.953,
                90.966,
                90.982,
                90.998,
                91.011,
                91.027,
                91.04,
                91.056,
                91.069,
                91.085,
                91.098,
                91.114,
                91.13,
                91.143,
                91.159,
                91.171,
                91.187,
                91.2,
                91.216,
                91.229,
                91.245,
                91.261,
                91.274,
                91.29,
                91.303,
                91.319,
                91.331,
                91.347,
                91.363,
                91.376,
                91.392,
                91.405,
                91.421,
                91.434,
                91.45,
                91.462,
                91.478,
                91.494,
                91.507,
                91.523,
                91.536,
                91.552,
                91.564,
                91.58,
                91.593,
                91.609,
                91.625,
                91.638,
                91.654,
                91.667,
                91.683,
                91.695,
                91.711,
                91.727,
                91.74,
                91.756,
                91.768,
                91.784,
                91.797,
                91.813,
                91.826,
                91.842,
                91.858,
                91.87,
                91.886,
                91.899,
                91.915,
                91.928,
                91.943,
                91.959,
                91.972,
                91.988,
                92.001,
                92.017,
                92.029,
                92.045,
                92.058,
                92.074,
                92.09,
                92.102,
                92.118,
                92.131,
                92.147,
                92.159,
                92.175,
                92.191,
                92.204,
                92.22,
                92.232,
                92.248,
                92.261,
                92.277,
                92.293,
                92.305,
                92.321,
                92.334,
                92.35,
                92.362,
                92.378,
                92.394,
                92.407,
                92.423,
                92.435,
                92.451,
                92.464,
                92.48,
                92.496,
                92.508,
                92.524,
                92.537,
                92.553,
                92.565,
                92.581,
                92.594,
                92.609,
                92.625,
                92.638,
                92.654,
                92.666,
                92.682,
                92.695,
                92.711,
                92.726,
                92.739,
                92.755,
                92.767,
                92.783,
                92.799,
                92.812,
                92.828,
                92.84,
                92.856,
                92.869,
                92.884,
                92.9,
                92.913,
                92.929,
                92.941,
                92.957,
                92.97,
                92.985,
                93.001,
                93.014,
                93.03,
                93.042,
                93.058,
                93.07,
                93.086,
                93.102,
                93.115,
                93.13,
                93.143,
                93.159,
                93.171,
                93.187,
                93.203,
                93.215,
                93.231,
                93.244,
                93.26,
                93.272,
                93.288,
                93.304,
                93.316,
                93.332,
                93.345,
                93.36,
                93.376,
                93.389,
                93.404,
                93.417,
                93.433,
                93.445,
                93.461,
                93.477,
                93.489,
                93.505,
                93.517,
                93.533,
                93.549,
                93.561,
                93.577,
                93.59,
                93.606,
                93.618,
                93.634,
                93.65,
                93.662,
                93.678,
                93.69,
                93.706,
                93.718,
                93.734,
                93.75,
                93.762,
                93.778,
                93.791,
                93.806,
                93.822,
                93.835,
                93.85,
                93.863,
                93.879,
                93.891,
                93.907,
                93.923,
                93.935,
                93.951,
                93.963,
                93.979,
                93.995,
                94.007,
                94.023,
                94.035,
                94.051,
                94.067,
                94.079,
                94.095,
                94.107,
                94.123,
                94.135,
                94.151,
                94.167,
                94.179,
                94.195,
                94.207,
                94.223,
                94.239,
                94.251,
                94.267,
                94.279,
                94.295,
                94.311,
                94.323,
                94.339,
                94.351,
                94.367,
                94.379,
                94.395,
                94.411,
                94.423,
                94.439,
                94.451,
                94.467,
                94.483,
                94.495,
                94.511,
                94.523,
                94.539,
                94.555,
                94.567,
                94.583,
                94.595,
                94.611,
                94.626,
                94.639,
                94.654,
                94.667,
                94.682,
                94.695,
                94.711,
                94.726,
                94.739,
                94.754,
                94.767,
                94.782,
                94.798,
                94.81,
                94.826,
                94.838,
                94.854,
                94.87,
                94.882,
                94.898,
                94.91,
                94.926,
                94.941,
                94.954,
                94.969,
                94.982,
                94.997,
                95.013,
                95.025,
                95.041,
                95.053,
                95.069,
                95.085,
                95.097,
                95.112,
                95.125,
                95.14,
                95.156,
                95.168,
                95.184,
                95.196,
                95.212,
                95.228,
                95.24,
                95.256,
                95.268,
                95.283,
                95.299,
                95.311,
                95.327,
                95.339,
                95.355,
                95.37,
                95.383,
                95.398,
                95.411,
                95.426,
                95.442,
                95.454,
                95.47,
                95.482,
                95.498,
                95.513,
                95.525,
                95.541,
                95.553,
                95.569,
                95.584,
                95.597,
                95.612,
                95.625,
                95.64,
                95.656,
                95.668,
                95.684,
                95.696,
                95.711,
                95.727,
                95.739,
                95.755,
                95.767,
                95.783,
                95.798,
                95.81,
                95.826,
                95.838,
                95.854,
                95.869,
                95.881,
                95.897,
                95.909,
                95.925,
                95.94,
                95.952,
                95.968,
                95.984,
                95.996,
                96.011,
                96.023,
                96.039,
                96.055,
                96.067
            ],
            P1: [
                45.48,
                45.652,
                45.824,
                45.998,
                46.17,
                46.342,
                46.514,
                46.688,
                46.861,
                47.033,
                47.206,
                47.38,
                47.553,
                47.726,
                47.899,
                48.046,
                48.192,
                48.337,
                48.481,
                48.624,
                48.766,
                48.908,
                49.049,
                49.189,
                49.326,
                49.464,
                49.6,
                49.737,
                49.871,
                50.005,
                50.139,
                50.27,
                50.401,
                50.531,
                50.66,
                50.788,
                50.915,
                51.041,
                51.165,
                51.289,
                51.413,
                51.534,
                51.656,
                51.776,
                51.895,
                52.012,
                52.131,
                52.246,
                52.361,
                52.477,
                52.59,
                52.703,
                52.815,
                52.925,
                53.036,
                53.145,
                53.254,
                53.361,
                53.469,
                53.574,
                53.68,
                53.785,
                53.888,
                53.993,
                54.096,
                54.198,
                54.301,
                54.401,
                54.503,
                54.602,
                54.701,
                54.801,
                54.898,
                54.996,
                55.092,
                55.19,
                55.285,
                55.38,
                55.474,
                55.568,
                55.661,
                55.754,
                55.846,
                55.937,
                56.028,
                56.118,
                56.208,
                56.296,
                56.385,
                56.473,
                56.56,
                56.646,
                56.733,
                56.819,
                56.903,
                56.988,
                57.071,
                57.155,
                57.236,
                57.319,
                57.4,
                57.482,
                57.562,
                57.643,
                57.721,
                57.8,
                57.879,
                57.957,
                58.033,
                58.111,
                58.187,
                58.262,
                58.337,
                58.412,
                58.487,
                58.561,
                58.634,
                58.706,
                58.778,
                58.85,
                58.921,
                58.992,
                59.062,
                59.132,
                59.201,
                59.27,
                59.339,
                59.407,
                59.475,
                59.542,
                59.609,
                59.675,
                59.74,
                59.806,
                59.871,
                59.936,
                60.001,
                60.063,
                60.127,
                60.191,
                60.254,
                60.315,
                60.378,
                60.44,
                60.501,
                60.562,
                60.622,
                60.683,
                60.743,
                60.802,
                60.862,
                60.92,
                60.98,
                61.038,
                61.096,
                61.153,
                61.212,
                61.268,
                61.324,
                61.382,
                61.438,
                61.495,
                61.55,
                61.605,
                61.661,
                61.715,
                61.769,
                61.825,
                61.879,
                61.932,
                61.985,
                62.039,
                62.093,
                62.146,
                62.198,
                62.25,
                62.302,
                62.356,
                62.407,
                62.459,
                62.51,
                62.561,
                62.612,
                62.663,
                62.714,
                62.764,
                62.814,
                62.864,
                62.914,
                62.964,
                63.014,
                63.063,
                63.112,
                63.162,
                63.211,
                63.26,
                63.308,
                63.355,
                63.404,
                63.452,
                63.5,
                63.548,
                63.596,
                63.642,
                63.69,
                63.738,
                63.785,
                63.831,
                63.878,
                63.925,
                63.972,
                64.018,
                64.064,
                64.111,
                64.156,
                64.202,
                64.249,
                64.294,
                64.34,
                64.384,
                64.43,
                64.476,
                64.521,
                64.566,
                64.61,
                64.656,
                64.7,
                64.745,
                64.789,
                64.834,
                64.878,
                64.923,
                64.966,
                65.011,
                65.054,
                65.099,
                65.142,
                65.187,
                65.23,
                65.273,
                65.317,
                65.36,
                65.403,
                65.447,
                65.49,
                65.534,
                65.577,
                65.619,
                65.663,
                65.705,
                65.748,
                65.79,
                65.834,
                65.876,
                65.918,
                65.96,
                66.003,
                66.045,
                66.087,
                66.129,
                66.172,
                66.213,
                66.255,
                66.296,
                66.338,
                66.381,
                66.422,
                66.464,
                66.505,
                66.546,
                66.587,
                66.628,
                66.669,
                66.712,
                66.753,
                66.793,
                66.834,
                66.875,
                66.916,
                66.956,
                66.997,
                67.037,
                67.078,
                67.118,
                67.158,
                67.199,
                67.239,
                67.279,
                67.319,
                67.359,
                67.399,
                67.439,
                67.479,
                67.519,
                67.558,
                67.598,
                67.638,
                67.677,
                67.717,
                67.755,
                67.794,
                67.833,
                67.873,
                67.912,
                67.951,
                67.99,
                68.029,
                68.066,
                68.105,
                68.144,
                68.183,
                68.222,
                68.261,
                68.298,
                68.336,
                68.375,
                68.413,
                68.452,
                68.489,
                68.527,
                68.565,
                68.604,
                68.64,
                68.678,
                68.716,
                68.754,
                68.791,
                68.829,
                68.867,
                68.903,
                68.94,
                68.978,
                69.016,
                69.052,
                69.089,
                69.127,
                69.163,
                69.2,
                69.238,
                69.273,
                69.311,
                69.348,
                69.383,
                69.421,
                69.458,
                69.493,
                69.53,
                69.565,
                69.602,
                69.639,
                69.674,
                69.711,
                69.746,
                69.783,
                69.82,
                69.854,
                69.891,
                69.926,
                69.962,
                69.997,
                70.034,
                70.068,
                70.105,
                70.141,
                70.176,
                70.212,
                70.246,
                70.282,
                70.317,
                70.353,
                70.387,
                70.423,
                70.457,
                70.493,
                70.528,
                70.563,
                70.597,
                70.633,
                70.667,
                70.703,
                70.737,
                70.771,
                70.806,
                70.84,
                70.875,
                70.909,
                70.945,
                70.978,
                71.013,
                71.047,
                71.08,
                71.116,
                71.149,
                71.184,
                71.218,
                71.251,
                71.286,
                71.319,
                71.354,
                71.387,
                71.42,
                71.455,
                71.488,
                71.521,
                71.556,
                71.588,
                71.623,
                71.656,
                71.689,
                71.723,
                71.756,
                71.788,
                71.823,
                71.855,
                71.888,
                71.922,
                71.955,
                71.987,
                72.021,
                72.054,
                72.086,
                72.12,
                72.152,
                72.184,
                72.218,
                72.25,
                72.282,
                72.314,
                72.348,
                72.38,
                72.412,
                72.446,
                72.478,
                72.509,
                72.541,
                72.575,
                72.607,
                72.638,
                72.67,
                72.703,
                72.735,
                72.766,
                72.798,
                72.831,
                72.863,
                72.894,
                72.925,
                72.959,
                72.99,
                73.021,
                73.052,
                73.085,
                73.117,
                73.148,
                73.179,
                73.21,
                73.243,
                73.274,
                73.305,
                73.336,
                73.367,
                73.399,
                73.43,
                73.461,
                73.492,
                73.523,
                73.555,
                73.586,
                73.617,
                73.647,
                73.678,
                73.708,
                73.741,
                73.771,
                73.802,
                73.832,
                73.863,
                73.893,
                73.925,
                73.956,
                73.986,
                74.016,
                74.046,
                74.077,
                74.107,
                74.139,
                74.169,
                74.199,
                74.229,
                74.259,
                74.289,
                74.319,
                74.351,
                74.381,
                74.411,
                74.441,
                74.471,
                74.5,
                74.53,
                74.56,
                74.59,
                74.621,
                74.651,
                74.681,
                74.71,
                74.74,
                74.769,
                74.799,
                74.828,
                74.858,
                74.887,
                74.917,
                74.946,
                74.977,
                75.006,
                75.036,
                75.065,
                75.094,
                75.123,
                75.153,
                75.182,
                75.211,
                75.24,
                75.269,
                75.298,
                75.327,
                75.356,
                75.385,
                75.414,
                75.443,
                75.473,
                75.502,
                75.531,
                75.56,
                75.588,
                75.617,
                75.646,
                75.674,
                75.703,
                75.732,
                75.76,
                75.789,
                75.817,
                75.846,
                75.874,
                75.903,
                75.931,
                75.959,
                75.988,
                76.016,
                76.044,
                76.073,
                76.101,
                76.129,
                76.157,
                76.185,
                76.213,
                76.241,
                76.27,
                76.298,
                76.326,
                76.354,
                76.381,
                76.409,
                76.437,
                76.465,
                76.493,
                76.521,
                76.549,
                76.576,
                76.604,
                76.632,
                76.659,
                76.687,
                76.715,
                76.742,
                76.768,
                76.795,
                76.823,
                76.85,
                76.878,
                76.905,
                76.933,
                76.96,
                76.987,
                77.015,
                77.042,
                77.069,
                77.096,
                77.124,
                77.151,
                77.178,
                77.205,
                77.232,
                77.259,
                77.286,
                77.311,
                77.338,
                77.365,
                77.392,
                77.419,
                77.446,
                77.473,
                77.5,
                77.527,
                77.554,
                77.58,
                77.607,
                77.634,
                77.661,
                77.685,
                77.712,
                77.739,
                77.765,
                77.792,
                77.818,
                77.845,
                77.872,
                77.898,
                77.925,
                77.951,
                77.975,
                78.002,
                78.028,
                78.055,
                78.081,
                78.107,
                78.134,
                78.16,
                78.186,
                78.212,
                78.237,
                78.263,
                78.289,
                78.315,
                78.341,
                78.367,
                78.393,
                78.419,
                78.445,
                78.469,
                78.495,
                78.521,
                78.547,
                78.573,
                78.599,
                78.625,
                78.65,
                78.676,
                78.7,
                78.726,
                78.751,
                78.777,
                78.803,
                78.828,
                78.854,
                78.88,
                78.903,
                78.929,
                78.954,
                78.98,
                79.005,
                79.031,
                79.056,
                79.08,
                79.105,
                79.131,
                79.156,
                79.181,
                79.207,
                79.232,
                79.257,
                79.28,
                79.306,
                79.331,
                79.356,
                79.381,
                79.407,
                79.432,
                79.455,
                79.48,
                79.505,
                79.53,
                79.555,
                79.58,
                79.605,
                79.628,
                79.653,
                79.678,
                79.703,
                79.728,
                79.753,
                79.777,
                79.8,
                79.825,
                79.85,
                79.875,
                79.899,
                79.924,
                79.949,
                79.972,
                79.996,
                80.021,
                80.046,
                80.07,
                80.095,
                80.119,
                80.142,
                80.167,
                80.191,
                80.216,
                80.24,
                80.265,
                80.289,
                80.311,
                80.336,
                80.36,
                80.385,
                80.409,
                80.433,
                80.458,
                80.48,
                80.504,
                80.528,
                80.553,
                80.577,
                80.601,
                80.625,
                80.647,
                80.672,
                80.696,
                80.02,
                80.044,
                80.068,
                80.092,
                80.114,
                80.138,
                80.162,
                80.186,
                80.21,
                80.234,
                80.258,
                80.279,
                80.303,
                80.327,
                80.351,
                80.375,
                80.398,
                80.422,
                80.446,
                80.468,
                80.491,
                80.515,
                80.539,
                80.562,
                80.586,
                80.609,
                80.631,
                80.654,
                80.678,
                80.701,
                80.725,
                80.748,
                80.772,
                80.795,
                80.816,
                80.84,
                80.863,
                80.886,
                80.91,
                80.933,
                80.956,
                80.977,
                81,
                81.023,
                81.047,
                81.07,
                81.093,
                81.116,
                81.139,
                81.16,
                81.183,
                81.206,
                81.229,
                81.252,
                81.274,
                81.297,
                81.32,
                81.343,
                81.364,
                81.387,
                81.409,
                81.432,
                81.455,
                81.478,
                81.5,
                81.523,
                81.546,
                81.566,
                81.589,
                81.611,
                81.634,
                81.656,
                81.679,
                81.701,
                81.724,
                81.746,
                81.769,
                81.789,
                81.811,
                81.834,
                81.856,
                81.878,
                81.9,
                81.923,
                81.945,
                81.967,
                81.989,
                82.012,
                82.032,
                82.054,
                82.076,
                82.098,
                82.12,
                82.142,
                82.164,
                82.186,
                82.208,
                82.23,
                82.252,
                82.274,
                82.294,
                82.315,
                82.337,
                82.359,
                82.381,
                82.403,
                82.424,
                82.446,
                82.468,
                82.49,
                82.511,
                82.533,
                82.554,
                82.576,
                82.598,
                82.617,
                82.639,
                82.66,
                82.682,
                82.703,
                82.725,
                82.746,
                82.767,
                82.789,
                82.81,
                82.831,
                82.853,
                82.874,
                82.895,
                82.916,
                82.938,
                82.959,
                82.98,
                83.001,
                83.022,
                83.043,
                83.064,
                83.083,
                83.104,
                83.125,
                83.146,
                83.167,
                83.188,
                83.209,
                83.23,
                83.251,
                83.272,
                83.293,
                83.313,
                83.334,
                83.355,
                83.376,
                83.397,
                83.417,
                83.438,
                83.459,
                83.479,
                83.5,
                83.52,
                83.541,
                83.562,
                83.582,
                83.603,
                83.623,
                83.644,
                83.664,
                83.684,
                83.705,
                83.725,
                83.746,
                83.766,
                83.786,
                83.806,
                83.827,
                83.847,
                83.867,
                83.887,
                83.908,
                83.928,
                83.948,
                83.968,
                83.988,
                84.008,
                84.028,
                84.048,
                84.068,
                84.088,
                84.108,
                84.128,
                84.148,
                84.17,
                84.19,
                84.21,
                84.23,
                84.25,
                84.27,
                84.29,
                84.309,
                84.329,
                84.349,
                84.369,
                84.388,
                84.408,
                84.428,
                84.447,
                84.467,
                84.487,
                84.506,
                84.526,
                84.545,
                84.567,
                84.587,
                84.606,
                84.625,
                84.645,
                84.664,
                84.684,
                84.703,
                84.723,
                84.742,
                84.761,
                84.781,
                84.8,
                84.819,
                84.841,
                84.86,
                84.879,
                84.898,
                84.918,
                84.937,
                84.956,
                84.975,
                84.994,
                85.013,
                85.032,
                85.054,
                85.073,
                85.092,
                85.111,
                85.13,
                85.149,
                85.168,
                85.187,
                85.206,
                85.224,
                85.246,
                85.264,
                85.283,
                85.302,
                85.321,
                85.34,
                85.358,
                85.377,
                85.398,
                85.417,
                85.436,
                85.454,
                85.473,
                85.492,
                85.51,
                85.529,
                85.55,
                85.569,
                85.587,
                85.606,
                85.624,
                85.643,
                85.661,
                85.682,
                85.701,
                85.719,
                85.738,
                85.756,
                85.775,
                85.793,
                85.814,
                85.832,
                85.85,
                85.869,
                85.887,
                85.905,
                85.926,
                85.944,
                85.963,
                85.981,
                85.999,
                86.017,
                86.038,
                86.056,
                86.074,
                86.093,
                86.111,
                86.131,
                86.149,
                86.168,
                86.186,
                86.204,
                86.222,
                86.242,
                86.26,
                86.279,
                86.297,
                86.315,
                86.335,
                86.353,
                86.371,
                86.389,
                86.407,
                86.427,
                86.445,
                86.463,
                86.481,
                86.499,
                86.519,
                86.537,
                86.555,
                86.573,
                86.593,
                86.611,
                86.629,
                86.647,
                86.664,
                86.684,
                86.702,
                86.72,
                86.738,
                86.758,
                86.776,
                86.793,
                86.811,
                86.829,
                86.849,
                86.867,
                86.884,
                86.902,
                86.922,
                86.94,
                86.957,
                86.975,
                86.995,
                87.012,
                87.03,
                87.048,
                87.067,
                87.085,
                87.102,
                87.12,
                87.14,
                87.157,
                87.175,
                87.192,
                87.212,
                87.23,
                87.247,
                87.267,
                87.284,
                87.302,
                87.319,
                87.339,
                87.356,
                87.374,
                87.391,
                87.411,
                87.428,
                87.445,
                87.465,
                87.482,
                87.5,
                87.517,
                87.536,
                87.554,
                87.571,
                87.591,
                87.608,
                87.625,
                87.642,
                87.662,
                87.679,
                87.696,
                87.716,
                87.733,
                87.75,
                87.77,
                87.787,
                87.804,
                87.821,
                87.84,
                87.858,
                87.875,
                87.894,
                87.911,
                87.928,
                87.948,
                87.965,
                87.982,
                88.001,
                88.018,
                88.035,
                88.054,
                88.071,
                88.088,
                88.108,
                88.125,
                88.141,
                88.161,
                88.178,
                88.195,
                88.214,
                88.231,
                88.248,
                88.267,
                88.284,
                88.301,
                88.32,
                88.337,
                88.354,
                88.373,
                88.389,
                88.406,
                88.425,
                88.442,
                88.459,
                88.478,
                88.495,
                88.512,
                88.531,
                88.547,
                88.564,
                88.583,
                88.6,
                88.619,
                88.636,
                88.652,
                88.671,
                88.688,
                88.705,
                88.724,
                88.74,
                88.757,
                88.776,
                88.792,
                88.811,
                88.828,
                88.845,
                88.863,
                88.88,
                88.897,
                88.915,
                88.932,
                88.951,
                88.967,
                88.984,
                89.003,
                89.019,
                89.038,
                89.054,
                89.071,
                89.09,
                89.106,
                89.125,
                89.141,
                89.158,
                89.176,
                89.193,
                89.209,
                89.228,
                89.244,
                89.263,
                89.279,
                89.296,
                89.314,
                89.331,
                89.349,
                89.366,
                89.384,
                89.4,
                89.417,
                89.435,
                89.452,
                89.47,
                89.486,
                89.503,
                89.521,
                89.537,
                89.556,
                89.572,
                89.588,
                89.607,
                89.623,
                89.641,
                89.658,
                89.676,
                89.692,
                89.708,
                89.727,
                89.743,
                89.761,
                89.777,
                89.796,
                89.812,
                89.828,
                89.846,
                89.862,
                89.881,
                89.897,
                89.915,
                89.931,
                89.947,
                89.965,
                89.981,
                90,
                90.016,
                90.034,
                90.05,
                90.068,
                90.084,
                90.1,
                90.118,
                90.134,
                90.152,
                90.168,
                90.186,
                90.202,
                90.22,
                90.236,
                90.254,
                90.27,
                90.286,
                90.304,
                90.32,
                90.338,
                90.353,
                90.371,
                90.387,
                90.405,
                90.421,
                90.439,
                90.455,
                90.471,
                90.488,
                90.504,
                90.522,
                90.538,
                90.556,
                90.571,
                90.589,
                90.605,
                90.623,
                90.639,
                90.656,
                90.672,
                90.69,
                90.706,
                90.723,
                90.739,
                90.754,
                90.772,
                90.788,
                90.806,
                90.821,
                90.839,
                90.855,
                90.872,
                90.888,
                90.906,
                90.921,
                90.939,
                90.954,
                90.972,
                90.987,
                91.005,
                91.021,
                91.038,
                91.054,
                91.071,
                91.087,
                91.104,
                91.12,
                91.137,
                91.153,
                91.17,
                91.186,
                91.203,
                91.219,
                91.234,
                91.252,
                91.267,
                91.284,
                91.3,
                91.317,
                91.333,
                91.35,
                91.365,
                91.383,
                91.398,
                91.416,
                91.431,
                91.448,
                91.463,
                91.481,
                91.496,
                91.514,
                91.529,
                91.546,
                91.561,
                91.579,
                91.594,
                91.611,
                91.626,
                91.644,
                91.659,
                91.676,
                91.691,
                91.709,
                91.726,
                91.741,
                91.759,
                91.774,
                91.791,
                91.806,
                91.823,
                91.838,
                91.856,
                91.871,
                91.888,
                91.903,
                91.92,
                91.935,
                91.952,
                91.967,
                91.985,
                91.999,
                92.017,
                92.032,
                92.049,
                92.064,
                92.081,
                92.096,
                92.113,
                92.128,
                92.145,
                92.16,
                92.177,
                92.192,
                92.209,
                92.224,
                92.241,
                92.258,
                92.273,
                92.29,
                92.305,
                92.322,
                92.337,
                92.354,
                92.369,
                92.386,
                92.4,
                92.418,
                92.432,
                92.449,
                92.464,
                92.481,
                92.496,
                92.513,
                92.528,
                92.545,
                92.559,
                92.576,
                92.593,
                92.608,
                92.625,
                92.64,
                92.657,
                92.671,
                92.688,
                92.703,
                92.72,
                92.735,
                92.751,
                92.766,
                92.783,
                92.798,
                92.815,
                92.832,
                92.846,
                92.863,
                92.878,
                92.895,
                92.909,
                92.926,
                92.94,
                92.957,
                92.972,
                92.989,
                93.003,
                93.02,
                93.035,
                93.052,
                93.068,
                93.083,
                93.1,
                93.114,
                93.131,
                93.146,
                93.163,
                93.177,
                93.194,
                93.208,
                93.225,
                93.239,
                93.256,
                93.273,
                93.288,
                93.304,
                93.319,
                93.336,
                93.35,
                93.367,
                93.381,
                93.398,
                93.412,
                93.429,
                93.446,
                93.46,
                93.477,
                93.492,
                93.508,
                93.523,
                93.539,
                93.554,
                93.57,
                93.585,
                93.602,
                93.618,
                93.633,
                93.649,
                93.664,
                93.68,
                93.695,
                93.711,
                93.726,
                93.742,
                93.757,
                93.773,
                93.79,
                93.804,
                93.821,
                93.835,
                93.852,
                93.866,
                93.883,
                93.897,
                93.914,
                93.931,
                93.945,
                93.962,
                93.976,
                93.993,
                94.007,
                94.023,
                94.038,
                94.054,
                94.071,
                94.085,
                94.102,
                94.116,
                94.133,
                94.147,
                94.164,
                94.178,
                94.194,
                94.209,
                94.225,
                94.242,
                94.256,
                94.273,
                94.287,
                94.303,
                94.318,
                94.334,
                94.348,
                94.365,
                94.382,
                94.396,
                94.412,
                94.427,
                94.443,
                94.457,
                94.474,
                94.488,
                94.505,
                94.521,
                94.535,
                94.552,
                94.566,
                94.583,
                94.597,
                94.613,
                94.63,
                94.644,
                94.661,
                94.675,
                94.691,
                94.705,
                94.722,
                94.736,
                94.753,
                94.769,
                94.783,
                94.8,
                94.814,
                94.831,
                94.845,
                94.861,
                94.875,
                94.892,
                94.908,
                94.922,
                94.939,
                94.953,
                94.97,
                94.984,
                95,
                95.017,
                95.031,
                95.047,
                95.061,
                95.078,
                95.092,
                95.108,
                95.123,
                95.139,
                95.156,
                95.17,
                95.186,
                95.2,
                95.217,
                95.231,
                95.247,
                95.264,
                95.278,
                95.294,
                95.308,
                95.325,
                95.339,
                95.355,
                95.369,
                95.386,
                95.402,
                95.416,
                95.433,
                95.447,
                95.463,
                95.478,
                95.494,
                95.51,
                95.524,
                95.541,
                95.555,
                95.571,
                95.585,
                95.602,
                95.618,
                95.632,
                95.649,
                95.663,
                95.679,
                95.693,
                95.71,
                95.726,
                95.74,
                95.757,
                95.771,
                95.787,
                95.801,
                95.818,
                95.834,
                95.848,
                95.865,
                95.878,
                95.895,
                95.909,
                95.925,
                95.939,
                95.956,
                95.972,
                95.986,
                96.003,
                96.017,
                96.033,
                96.047,
                96.063,
                96.08,
                96.094,
                96.11,
                96.124,
                96.141,
                96.157,
                96.171,
                96.187,
                96.201,
                96.218,
                96.232,
                96.248,
                96.265,
                96.278,
                96.295,
                96.309,
                96.325,
                96.339,
                96.356,
                96.372,
                96.386,
                96.402,
                96.416,
                96.433,
                96.447,
                96.463,
                96.479,
                96.493,
                96.51,
                96.524,
                96.54,
                96.554,
                96.57,
                96.587,
                96.601,
                96.617,
                96.631,
                96.647,
                96.661,
                96.677,
                96.694,
                96.708,
                96.724,
                96.738,
                96.754,
                96.771,
                96.785,
                96.801,
                96.815,
                96.831,
                96.845,
                96.861,
                96.878,
                96.892,
                96.908,
                96.922,
                96.938,
                96.955,
                96.969,
                96.985,
                96.999,
                97.015,
                97.029,
                97.045,
                97.062,
                97.076,
                97.092,
                97.106,
                97.122,
                97.136,
                97.152,
                97.169,
                97.182,
                97.199,
                97.213,
                97.229,
                97.245,
                97.259,
                97.275,
                97.289,
                97.306,
                97.32,
                97.336,
                97.352,
                97.366,
                97.382,
                97.396,
                97.412,
                97.429,
                97.443,
                97.459,
                97.473,
                97.489,
                97.505,
                97.519,
                97.536,
                97.549,
                97.566,
                97.579,
                97.596,
                97.612,
                97.626,
                97.642,
                97.656,
                97.672,
                97.689,
                97.702,
                97.719,
                97.733,
                97.749,
                97.765,
                97.779,
                97.795,
                97.809,
                97.825,
                97.839,
                97.855,
                97.872,
                97.885,
                97.902,
                97.915,
                97.932,
                97.948,
                97.962,
                97.978,
                97.992,
                98.008,
                98.024,
                98.038,
                98.054,
                98.068,
                98.085,
                98.101,
                98.115,
                98.131,
                98.145,
                98.161,
                98.175,
                98.191,
                98.207,
                98.221,
                98.237,
                98.251,
                98.267,
                98.283,
                98.297,
                98.313,
                98.327,
                98.343,
                98.36,
                98.373,
                98.39,
                98.403,
                98.42,
                98.436,
                98.45,
                98.466,
                98.48,
                98.496,
                98.512,
                98.526,
                98.542,
                98.556,
                98.572,
                98.588,
                98.602,
                98.618,
                98.632,
                98.648,
                98.664,
                98.678,
                98.694,
                98.708,
                98.724,
                98.74,
                98.754,
                98.77,
                98.784,
                98.8,
                98.816,
                98.83,
                98.846,
                98.86,
                98.876,
                98.892,
                98.906,
                98.922,
                98.936,
                98.952,
                98.968,
                98.982,
                98.998,
                99.012,
                99.028,
                99.044,
                99.058,
                99.074,
                99.088,
                99.104,
                99.12,
                99.134,
                99.15,
                99.164,
                99.18,
                99.196,
                99.21,
                99.226,
                99.239,
                99.256,
                99.272,
                99.285,
                99.301,
                99.315,
                99.331,
                99.347,
                99.361,
                99.377,
                99.391,
                99.407,
                99.423,
                99.437,
                99.453,
                99.467,
                99.483,
                99.499,
                99.512,
                99.529,
                99.545,
                99.558,
                99.574,
                99.588,
                99.604,
                99.62,
                99.634
            ],
            P3: [
                46.324,
                46.496,
                46.669,
                46.843,
                47.016,
                47.189,
                47.362,
                47.536,
                47.709,
                47.882,
                48.056,
                48.23,
                48.404,
                48.577,
                48.751,
                48.899,
                49.045,
                49.191,
                49.336,
                49.481,
                49.624,
                49.766,
                49.908,
                50.049,
                50.188,
                50.327,
                50.464,
                50.601,
                50.736,
                50.871,
                51.005,
                51.138,
                51.27,
                51.401,
                51.53,
                51.659,
                51.787,
                51.914,
                52.039,
                52.164,
                52.288,
                52.41,
                52.533,
                52.654,
                52.774,
                52.892,
                53.011,
                53.128,
                53.243,
                53.36,
                53.474,
                53.587,
                53.699,
                53.811,
                53.922,
                54.032,
                54.141,
                54.25,
                54.358,
                54.464,
                54.57,
                54.676,
                54.78,
                54.885,
                54.989,
                55.092,
                55.195,
                55.297,
                55.399,
                55.499,
                55.599,
                55.699,
                55.797,
                55.895,
                55.992,
                56.09,
                56.186,
                56.282,
                56.377,
                56.471,
                56.565,
                56.658,
                56.751,
                56.843,
                56.934,
                57.025,
                57.116,
                57.204,
                57.294,
                57.382,
                57.47,
                57.557,
                57.644,
                57.73,
                57.815,
                57.901,
                57.984,
                58.069,
                58.151,
                58.235,
                58.316,
                58.398,
                58.479,
                58.56,
                58.64,
                58.719,
                58.798,
                58.876,
                58.954,
                59.032,
                59.108,
                59.184,
                59.26,
                59.335,
                59.41,
                59.484,
                59.558,
                59.631,
                59.704,
                59.776,
                59.848,
                59.919,
                59.989,
                60.06,
                60.13,
                60.199,
                60.268,
                60.337,
                60.405,
                60.473,
                60.54,
                60.607,
                60.672,
                60.738,
                60.804,
                60.869,
                60.934,
                60.998,
                61.062,
                61.126,
                61.189,
                61.251,
                61.314,
                61.377,
                61.438,
                61.5,
                61.56,
                61.621,
                61.682,
                61.742,
                61.802,
                61.861,
                61.921,
                61.979,
                62.038,
                62.096,
                62.154,
                62.211,
                62.268,
                62.326,
                62.382,
                62.439,
                62.495,
                62.55,
                62.607,
                62.661,
                62.716,
                62.772,
                62.826,
                62.88,
                62.934,
                62.987,
                63.042,
                63.095,
                63.148,
                63.201,
                63.253,
                63.307,
                63.359,
                63.411,
                63.463,
                63.514,
                63.566,
                63.617,
                63.668,
                63.719,
                63.769,
                63.82,
                63.87,
                63.921,
                63.971,
                64.021,
                64.07,
                64.12,
                64.169,
                64.219,
                64.268,
                64.315,
                64.364,
                64.413,
                64.462,
                64.51,
                64.558,
                64.605,
                64.653,
                64.701,
                64.749,
                64.796,
                64.843,
                64.891,
                64.938,
                64.984,
                65.031,
                65.078,
                65.124,
                65.171,
                65.218,
                65.263,
                65.31,
                65.355,
                65.401,
                65.447,
                65.492,
                65.538,
                65.583,
                65.629,
                65.674,
                65.719,
                65.764,
                65.809,
                65.854,
                65.899,
                65.943,
                65.988,
                66.032,
                66.077,
                66.121,
                66.166,
                66.21,
                66.253,
                66.298,
                66.342,
                66.385,
                66.43,
                66.473,
                66.517,
                66.561,
                66.604,
                66.648,
                66.691,
                66.734,
                66.777,
                66.821,
                66.863,
                66.906,
                66.949,
                66.992,
                67.035,
                67.077,
                67.12,
                67.163,
                67.206,
                67.248,
                67.29,
                67.332,
                67.375,
                67.417,
                67.459,
                67.501,
                67.543,
                67.584,
                67.626,
                67.668,
                67.711,
                67.752,
                67.794,
                67.835,
                67.876,
                67.918,
                67.959,
                68,
                68.041,
                68.082,
                68.123,
                68.164,
                68.205,
                68.246,
                68.286,
                68.327,
                68.368,
                68.408,
                68.449,
                68.489,
                68.53,
                68.57,
                68.61,
                68.65,
                68.691,
                68.731,
                68.769,
                68.809,
                68.849,
                68.889,
                68.929,
                68.969,
                69.009,
                69.048,
                69.086,
                69.126,
                69.165,
                69.205,
                69.244,
                69.284,
                69.321,
                69.361,
                69.4,
                69.439,
                69.478,
                69.516,
                69.554,
                69.593,
                69.632,
                69.67,
                69.708,
                69.747,
                69.786,
                69.823,
                69.861,
                69.9,
                69.937,
                69.975,
                70.014,
                70.052,
                70.089,
                70.127,
                70.165,
                70.202,
                70.24,
                70.278,
                70.314,
                70.352,
                70.39,
                70.426,
                70.464,
                70.502,
                70.538,
                70.576,
                70.612,
                70.649,
                70.687,
                70.723,
                70.76,
                70.796,
                70.833,
                70.87,
                70.906,
                70.943,
                70.979,
                71.016,
                71.052,
                71.089,
                71.124,
                71.161,
                71.198,
                71.234,
                71.27,
                71.306,
                71.343,
                71.378,
                71.414,
                71.45,
                71.486,
                71.521,
                71.558,
                71.593,
                71.629,
                71.664,
                71.7,
                71.735,
                71.771,
                71.806,
                71.841,
                71.877,
                71.912,
                71.948,
                71.982,
                72.018,
                72.053,
                72.089,
                72.123,
                72.157,
                72.193,
                72.227,
                72.263,
                72.297,
                72.331,
                72.367,
                72.401,
                72.436,
                72.47,
                72.504,
                72.54,
                72.574,
                72.607,
                72.643,
                72.676,
                72.711,
                72.745,
                72.779,
                72.814,
                72.847,
                72.881,
                72.916,
                72.949,
                72.983,
                73.017,
                73.051,
                73.084,
                73.119,
                73.152,
                73.185,
                73.22,
                73.253,
                73.286,
                73.32,
                73.353,
                73.386,
                73.419,
                73.453,
                73.486,
                73.519,
                73.553,
                73.586,
                73.619,
                73.651,
                73.685,
                73.718,
                73.75,
                73.783,
                73.817,
                73.849,
                73.882,
                73.914,
                73.948,
                73.98,
                74.012,
                74.045,
                74.078,
                74.11,
                74.143,
                74.175,
                74.208,
                74.24,
                74.272,
                74.304,
                74.336,
                74.37,
                74.401,
                74.433,
                74.465,
                74.497,
                74.53,
                74.562,
                74.593,
                74.625,
                74.657,
                74.69,
                74.721,
                74.753,
                74.784,
                74.816,
                74.847,
                74.88,
                74.912,
                74.943,
                74.974,
                75.006,
                75.037,
                75.069,
                75.101,
                75.132,
                75.163,
                75.194,
                75.225,
                75.256,
                75.289,
                75.32,
                75.351,
                75.382,
                75.412,
                75.443,
                75.474,
                75.506,
                75.537,
                75.568,
                75.599,
                75.629,
                75.66,
                75.691,
                75.721,
                75.752,
                75.784,
                75.814,
                75.845,
                75.875,
                75.906,
                75.936,
                75.966,
                75.997,
                76.027,
                76.057,
                76.088,
                76.118,
                76.15,
                76.18,
                76.21,
                76.24,
                76.27,
                76.3,
                76.33,
                76.36,
                76.39,
                76.42,
                76.45,
                76.48,
                76.51,
                76.539,
                76.569,
                76.599,
                76.629,
                76.66,
                76.69,
                76.719,
                76.749,
                76.778,
                76.808,
                76.837,
                76.867,
                76.896,
                76.926,
                76.955,
                76.985,
                77.014,
                77.043,
                77.073,
                77.102,
                77.131,
                77.16,
                77.19,
                77.219,
                77.248,
                77.277,
                77.306,
                77.335,
                77.364,
                77.393,
                77.422,
                77.451,
                77.48,
                77.509,
                77.538,
                77.566,
                77.595,
                77.624,
                77.653,
                77.681,
                77.71,
                77.739,
                77.767,
                77.796,
                77.824,
                77.853,
                77.881,
                77.91,
                77.938,
                77.967,
                77.994,
                78.022,
                78.05,
                78.079,
                78.107,
                78.135,
                78.163,
                78.192,
                78.22,
                78.248,
                78.276,
                78.304,
                78.332,
                78.36,
                78.388,
                78.416,
                78.444,
                78.472,
                78.5,
                78.528,
                78.554,
                78.582,
                78.61,
                78.638,
                78.666,
                78.693,
                78.721,
                78.749,
                78.776,
                78.804,
                78.832,
                78.859,
                78.887,
                78.914,
                78.94,
                78.968,
                78.995,
                79.023,
                79.05,
                79.078,
                79.105,
                79.133,
                79.16,
                79.187,
                79.214,
                79.24,
                79.267,
                79.295,
                79.322,
                79.349,
                79.376,
                79.403,
                79.43,
                79.457,
                79.485,
                79.51,
                79.537,
                79.564,
                79.591,
                79.618,
                79.645,
                79.672,
                79.699,
                79.725,
                79.751,
                79.777,
                79.804,
                79.831,
                79.858,
                79.884,
                79.911,
                79.938,
                79.964,
                79.989,
                80.016,
                80.042,
                80.069,
                80.095,
                80.122,
                80.148,
                80.175,
                80.2,
                80.226,
                80.252,
                80.279,
                80.305,
                80.331,
                80.358,
                80.382,
                80.409,
                80.435,
                80.461,
                80.487,
                80.513,
                80.54,
                80.566,
                80.59,
                80.616,
                80.642,
                80.668,
                80.694,
                80.72,
                80.746,
                80.77,
                80.796,
                80.822,
                80.848,
                80.874,
                80.9,
                80.926,
                80.95,
                80.976,
                81.002,
                81.027,
                81.053,
                81.079,
                81.104,
                81.128,
                81.154,
                81.18,
                81.205,
                81.231,
                81.257,
                81.282,
                81.306,
                81.332,
                81.357,
                81.383,
                81.408,
                81.434,
                81.459,
                81.483,
                81.508,
                81.533,
                81.559,
                81.584,
                81.609,
                81.635,
                81.658,
                81.684,
                81.709,
                81.734,
                81.759,
                81.784,
                81.81,
                81.833,
                81.858,
                81.883,
                81.908,
                81.933,
                81.958,
                81.983,
                82.007,
                82.032,
                82.057,
                81.382,
                81.406,
                81.431,
                81.456,
                81.479,
                81.504,
                81.529,
                81.554,
                81.579,
                81.603,
                81.628,
                81.651,
                81.676,
                81.701,
                81.725,
                81.75,
                81.774,
                81.799,
                81.824,
                81.846,
                81.871,
                81.895,
                81.92,
                81.944,
                81.969,
                81.993,
                82.016,
                82.04,
                82.065,
                82.089,
                82.113,
                82.137,
                82.162,
                82.186,
                82.208,
                82.233,
                82.257,
                82.281,
                82.305,
                82.329,
                82.353,
                82.375,
                82.399,
                82.423,
                82.447,
                82.471,
                82.495,
                82.519,
                82.543,
                82.565,
                82.589,
                82.613,
                82.637,
                82.66,
                82.684,
                82.708,
                82.731,
                82.755,
                82.777,
                82.801,
                82.824,
                82.848,
                82.871,
                82.895,
                82.918,
                82.942,
                82.965,
                82.987,
                83.01,
                83.034,
                83.057,
                83.08,
                83.104,
                83.127,
                83.15,
                83.174,
                83.197,
                83.218,
                83.242,
                83.265,
                83.288,
                83.311,
                83.334,
                83.357,
                83.38,
                83.403,
                83.426,
                83.449,
                83.471,
                83.494,
                83.517,
                83.539,
                83.562,
                83.585,
                83.608,
                83.631,
                83.654,
                83.676,
                83.699,
                83.722,
                83.743,
                83.766,
                83.788,
                83.811,
                83.834,
                83.856,
                83.879,
                83.901,
                83.924,
                83.946,
                83.969,
                83.991,
                84.014,
                84.036,
                84.058,
                84.079,
                84.102,
                84.124,
                84.146,
                84.168,
                84.191,
                84.213,
                84.235,
                84.257,
                84.279,
                84.302,
                84.324,
                84.346,
                84.368,
                84.39,
                84.412,
                84.434,
                84.456,
                84.478,
                84.5,
                84.522,
                84.544,
                84.564,
                84.586,
                84.608,
                84.629,
                84.651,
                84.673,
                84.695,
                84.716,
                84.738,
                84.76,
                84.781,
                84.803,
                84.825,
                84.846,
                84.868,
                84.889,
                84.911,
                84.932,
                84.954,
                84.975,
                84.997,
                85.018,
                85.039,
                85.061,
                85.082,
                85.103,
                85.125,
                85.146,
                85.167,
                85.188,
                85.21,
                85.231,
                85.252,
                85.273,
                85.294,
                85.316,
                85.337,
                85.358,
                85.379,
                85.4,
                85.421,
                85.442,
                85.463,
                85.484,
                85.504,
                85.525,
                85.546,
                85.567,
                85.588,
                85.609,
                85.63,
                85.65,
                85.671,
                85.694,
                85.714,
                85.735,
                85.756,
                85.776,
                85.797,
                85.818,
                85.838,
                85.859,
                85.879,
                85.9,
                85.92,
                85.941,
                85.961,
                85.982,
                86.002,
                86.023,
                86.043,
                86.063,
                86.084,
                86.106,
                86.126,
                86.147,
                86.167,
                86.187,
                86.207,
                86.228,
                86.248,
                86.268,
                86.288,
                86.308,
                86.328,
                86.349,
                86.369,
                86.391,
                86.411,
                86.431,
                86.451,
                86.471,
                86.491,
                86.511,
                86.531,
                86.55,
                86.57,
                86.59,
                86.612,
                86.632,
                86.652,
                86.671,
                86.691,
                86.711,
                86.731,
                86.751,
                86.77,
                86.79,
                86.811,
                86.831,
                86.851,
                86.87,
                86.89,
                86.91,
                86.929,
                86.949,
                86.97,
                86.99,
                87.009,
                87.029,
                87.048,
                87.068,
                87.087,
                87.107,
                87.128,
                87.147,
                87.167,
                87.186,
                87.205,
                87.225,
                87.244,
                87.265,
                87.285,
                87.304,
                87.323,
                87.342,
                87.362,
                87.381,
                87.402,
                87.421,
                87.44,
                87.459,
                87.479,
                87.498,
                87.519,
                87.538,
                87.557,
                87.576,
                87.595,
                87.614,
                87.635,
                87.654,
                87.673,
                87.692,
                87.711,
                87.732,
                87.751,
                87.77,
                87.789,
                87.808,
                87.827,
                87.847,
                87.866,
                87.885,
                87.904,
                87.923,
                87.943,
                87.962,
                87.981,
                88,
                88.019,
                88.039,
                88.058,
                88.077,
                88.096,
                88.114,
                88.135,
                88.154,
                88.172,
                88.191,
                88.211,
                88.23,
                88.249,
                88.267,
                88.286,
                88.307,
                88.325,
                88.344,
                88.362,
                88.383,
                88.401,
                88.42,
                88.438,
                88.457,
                88.477,
                88.496,
                88.514,
                88.533,
                88.553,
                88.571,
                88.59,
                88.608,
                88.629,
                88.647,
                88.665,
                88.684,
                88.704,
                88.722,
                88.741,
                88.759,
                88.779,
                88.798,
                88.816,
                88.834,
                88.854,
                88.873,
                88.891,
                88.911,
                88.929,
                88.947,
                88.966,
                88.986,
                89.004,
                89.022,
                89.04,
                89.06,
                89.079,
                89.097,
                89.117,
                89.135,
                89.153,
                89.171,
                89.191,
                89.209,
                89.227,
                89.247,
                89.265,
                89.283,
                89.301,
                89.321,
                89.339,
                89.357,
                89.377,
                89.395,
                89.413,
                89.433,
                89.451,
                89.469,
                89.487,
                89.506,
                89.524,
                89.542,
                89.562,
                89.58,
                89.598,
                89.617,
                89.635,
                89.653,
                89.673,
                89.691,
                89.708,
                89.728,
                89.746,
                89.764,
                89.783,
                89.801,
                89.819,
                89.838,
                89.856,
                89.874,
                89.893,
                89.911,
                89.929,
                89.948,
                89.966,
                89.984,
                90.003,
                90.021,
                90.039,
                90.058,
                90.076,
                90.093,
                90.113,
                90.13,
                90.148,
                90.168,
                90.185,
                90.203,
                90.222,
                90.24,
                90.257,
                90.276,
                90.294,
                90.313,
                90.331,
                90.348,
                90.368,
                90.385,
                90.403,
                90.422,
                90.439,
                90.457,
                90.476,
                90.494,
                90.513,
                90.53,
                90.548,
                90.567,
                90.584,
                90.602,
                90.621,
                90.638,
                90.657,
                90.675,
                90.692,
                90.711,
                90.728,
                90.748,
                90.765,
                90.782,
                90.801,
                90.819,
                90.838,
                90.855,
                90.872,
                90.891,
                90.908,
                90.926,
                90.945,
                90.962,
                90.981,
                90.998,
                91.015,
                91.034,
                91.051,
                91.07,
                91.087,
                91.106,
                91.123,
                91.141,
                91.159,
                91.176,
                91.195,
                91.212,
                91.23,
                91.248,
                91.265,
                91.284,
                91.301,
                91.318,
                91.337,
                91.354,
                91.373,
                91.39,
                91.409,
                91.426,
                91.443,
                91.461,
                91.478,
                91.497,
                91.514,
                91.533,
                91.549,
                91.566,
                91.585,
                91.602,
                91.621,
                91.638,
                91.656,
                91.673,
                91.69,
                91.708,
                91.725,
                91.744,
                91.761,
                91.779,
                91.796,
                91.814,
                91.831,
                91.848,
                91.866,
                91.883,
                91.902,
                91.918,
                91.937,
                91.953,
                91.972,
                91.989,
                92.007,
                92.024,
                92.04,
                92.059,
                92.075,
                92.094,
                92.11,
                92.129,
                92.145,
                92.164,
                92.18,
                92.199,
                92.215,
                92.232,
                92.25,
                92.266,
                92.285,
                92.301,
                92.32,
                92.336,
                92.354,
                92.371,
                92.389,
                92.405,
                92.424,
                92.44,
                92.458,
                92.475,
                92.493,
                92.509,
                92.526,
                92.544,
                92.56,
                92.578,
                92.595,
                92.613,
                92.629,
                92.647,
                92.664,
                92.682,
                92.698,
                92.716,
                92.732,
                92.75,
                92.766,
                92.785,
                92.801,
                92.819,
                92.835,
                92.853,
                92.869,
                92.887,
                92.903,
                92.921,
                92.938,
                92.956,
                92.972,
                92.99,
                93.006,
                93.022,
                93.04,
                93.056,
                93.074,
                93.09,
                93.108,
                93.124,
                93.142,
                93.158,
                93.176,
                93.192,
                93.209,
                93.225,
                93.243,
                93.259,
                93.277,
                93.293,
                93.311,
                93.327,
                93.345,
                93.361,
                93.378,
                93.394,
                93.412,
                93.428,
                93.446,
                93.462,
                93.479,
                93.495,
                93.513,
                93.531,
                93.546,
                93.564,
                93.58,
                93.598,
                93.613,
                93.631,
                93.647,
                93.665,
                93.68,
                93.698,
                93.714,
                93.731,
                93.747,
                93.765,
                93.78,
                93.798,
                93.814,
                93.831,
                93.847,
                93.865,
                93.88,
                93.898,
                93.913,
                93.931,
                93.947,
                93.964,
                93.98,
                93.997,
                94.013,
                94.031,
                94.046,
                94.064,
                94.081,
                94.097,
                94.114,
                94.13,
                94.147,
                94.163,
                94.18,
                94.196,
                94.213,
                94.229,
                94.246,
                94.262,
                94.279,
                94.295,
                94.312,
                94.327,
                94.345,
                94.36,
                94.378,
                94.393,
                94.411,
                94.428,
                94.443,
                94.461,
                94.476,
                94.494,
                94.509,
                94.526,
                94.542,
                94.559,
                94.574,
                94.592,
                94.607,
                94.624,
                94.64,
                94.657,
                94.674,
                94.69,
                94.707,
                94.722,
                94.74,
                94.755,
                94.772,
                94.788,
                94.805,
                94.82,
                94.837,
                94.853,
                94.87,
                94.885,
                94.902,
                94.92,
                94.935,
                94.952,
                94.967,
                94.985,
                95,
                95.017,
                95.032,
                95.049,
                95.065,
                95.082,
                95.097,
                95.114,
                95.131,
                95.147,
                95.164,
                95.179,
                95.196,
                95.211,
                95.228,
                95.244,
                95.261,
                95.276,
                95.293,
                95.31,
                95.325,
                95.342,
                95.358,
                95.375,
                95.39,
                95.407,
                95.422,
                95.439,
                95.454,
                95.471,
                95.488,
                95.504,
                95.521,
                95.536,
                95.553,
                95.568,
                95.585,
                95.6,
                95.617,
                95.632,
                95.649,
                95.666,
                95.681,
                95.698,
                95.713,
                95.73,
                95.746,
                95.763,
                95.778,
                95.795,
                95.812,
                95.827,
                95.844,
                95.859,
                95.876,
                95.891,
                95.908,
                95.923,
                95.94,
                95.957,
                95.972,
                95.989,
                96.004,
                96.021,
                96.036,
                96.053,
                96.068,
                96.085,
                96.1,
                96.117,
                96.134,
                96.149,
                96.166,
                96.181,
                96.198,
                96.213,
                96.229,
                96.244,
                96.261,
                96.278,
                96.293,
                96.31,
                96.325,
                96.342,
                96.357,
                96.374,
                96.389,
                96.406,
                96.423,
                96.438,
                96.455,
                96.47,
                96.486,
                96.501,
                96.518,
                96.535,
                96.55,
                96.567,
                96.582,
                96.599,
                96.614,
                96.631,
                96.646,
                96.662,
                96.679,
                96.694,
                96.711,
                96.726,
                96.743,
                96.758,
                96.775,
                96.79,
                96.806,
                96.823,
                96.838,
                96.855,
                96.87,
                96.887,
                96.902,
                96.919,
                96.935,
                96.95,
                96.967,
                96.982,
                96.999,
                97.014,
                97.031,
                97.046,
                97.062,
                97.079,
                97.094,
                97.111,
                97.126,
                97.143,
                97.158,
                97.174,
                97.191,
                97.206,
                97.223,
                97.238,
                97.254,
                97.269,
                97.286,
                97.301,
                97.318,
                97.335,
                97.349,
                97.366,
                97.381,
                97.398,
                97.413,
                97.43,
                97.446,
                97.461,
                97.478,
                97.493,
                97.51,
                97.525,
                97.541,
                97.558,
                97.573,
                97.59,
                97.605,
                97.621,
                97.636,
                97.653,
                97.67,
                97.685,
                97.701,
                97.716,
                97.733,
                97.748,
                97.765,
                97.781,
                97.796,
                97.813,
                97.828,
                97.844,
                97.859,
                97.876,
                97.891,
                97.908,
                97.924,
                97.939,
                97.956,
                97.971,
                97.987,
                98.002,
                98.019,
                98.036,
                98.051,
                98.067,
                98.082,
                98.099,
                98.116,
                98.13,
                98.147,
                98.162,
                98.179,
                98.193,
                98.21,
                98.227,
                98.242,
                98.258,
                98.273,
                98.29,
                98.305,
                98.321,
                98.338,
                98.353,
                98.369,
                98.384,
                98.401,
                98.416,
                98.432,
                98.449,
                98.464,
                98.481,
                98.495,
                98.512,
                98.527,
                98.543,
                98.56,
                98.575,
                98.592,
                98.606,
                98.623,
                98.638,
                98.654,
                98.671,
                98.686,
                98.703,
                98.717,
                98.734,
                98.751,
                98.765,
                98.782,
                98.797,
                98.814,
                98.828,
                98.845,
                98.862,
                98.876,
                98.893,
                98.908,
                98.924,
                98.941,
                98.956,
                98.972,
                98.987,
                99.004,
                99.019,
                99.035,
                99.052,
                99.067,
                99.083,
                99.098,
                99.115,
                99.129,
                99.146,
                99.163,
                99.177,
                99.194,
                99.209,
                99.225,
                99.242,
                99.257,
                99.273,
                99.288,
                99.305,
                99.319,
                99.336,
                99.353,
                99.367,
                99.384,
                99.399,
                99.415,
                99.432,
                99.446,
                99.463,
                99.478,
                99.494,
                99.511,
                99.526,
                99.542,
                99.557,
                99.574,
                99.588,
                99.605,
                99.622,
                99.636,
                99.653,
                99.667,
                99.684,
                99.701,
                99.715,
                99.732,
                99.747,
                99.763,
                99.78,
                99.794,
                99.811,
                99.826,
                99.842,
                99.857,
                99.874,
                99.89,
                99.905,
                99.921,
                99.936,
                99.953,
                99.969,
                99.984,
                100.001,
                100.015,
                100.032,
                100.048,
                100.063,
                100.08,
                100.094,
                100.111,
                100.127,
                100.142,
                100.159,
                100.173,
                100.19,
                100.204,
                100.221,
                100.237,
                100.252,
                100.269,
                100.283,
                100.3,
                100.316,
                100.331,
                100.348,
                100.362,
                100.379,
                100.395,
                100.41,
                100.426,
                100.441,
                100.458,
                100.474,
                100.489,
                100.505,
                100.52,
                100.536,
                100.553,
                100.568,
                100.584,
                100.599,
                100.615,
                100.632,
                100.646,
                100.663,
                100.677,
                100.694,
                100.711,
                100.725,
                100.742,
                100.756,
                100.773,
                100.789,
                100.804,
                100.82,
                100.835,
                100.851,
                100.868,
                100.882,
                100.899,
                100.914,
                100.93,
                100.947,
                100.961,
                100.978,
                100.992,
                101.009,
                101.025,
                101.04,
                101.056,
                101.071,
                101.087,
                101.104,
                101.118,
                101.135,
                101.149,
                101.166,
                101.182,
                101.197,
                101.213,
                101.228,
                101.244,
                101.261,
                101.275,
                101.292,
                101.306,
                101.323,
                101.339,
                101.354,
                101.37,
                101.385,
                101.401,
                101.418,
                101.432,
                101.449,
                101.463,
                101.48,
                101.496,
                101.51,
                101.527,
                101.541,
                101.558,
                101.574,
                101.589,
                101.605,
                101.622,
                101.636,
                101.653,
                101.667,
                101.683,
                101.7,
                101.714
            ],
            P5: [
                46.77,
                46.943,
                47.117,
                47.291,
                47.464,
                47.637,
                47.811,
                47.985,
                48.158,
                48.332,
                48.506,
                48.68,
                48.854,
                49.028,
                49.202,
                49.35,
                49.497,
                49.644,
                49.789,
                49.934,
                50.078,
                50.221,
                50.363,
                50.504,
                50.644,
                50.783,
                50.921,
                51.058,
                51.194,
                51.329,
                51.464,
                51.598,
                51.73,
                51.861,
                51.991,
                52.121,
                52.249,
                52.376,
                52.502,
                52.627,
                52.752,
                52.875,
                52.997,
                53.119,
                53.239,
                53.358,
                53.477,
                53.594,
                53.71,
                53.827,
                53.941,
                54.055,
                54.168,
                54.28,
                54.391,
                54.502,
                54.611,
                54.72,
                54.829,
                54.935,
                55.042,
                55.148,
                55.253,
                55.358,
                55.463,
                55.566,
                55.669,
                55.771,
                55.873,
                55.974,
                56.074,
                56.174,
                56.273,
                56.371,
                56.469,
                56.567,
                56.664,
                56.76,
                56.855,
                56.95,
                57.044,
                57.137,
                57.23,
                57.323,
                57.414,
                57.506,
                57.596,
                57.685,
                57.775,
                57.864,
                57.952,
                58.039,
                58.126,
                58.213,
                58.298,
                58.384,
                58.468,
                58.553,
                58.636,
                58.719,
                58.801,
                58.884,
                58.965,
                59.046,
                59.126,
                59.205,
                59.285,
                59.363,
                59.441,
                59.519,
                59.596,
                59.672,
                59.748,
                59.823,
                59.899,
                59.974,
                60.048,
                60.121,
                60.194,
                60.266,
                60.338,
                60.41,
                60.481,
                60.551,
                60.621,
                60.691,
                60.76,
                60.829,
                60.897,
                60.965,
                61.033,
                61.1,
                61.166,
                61.232,
                61.298,
                61.363,
                61.429,
                61.492,
                61.557,
                61.621,
                61.685,
                61.747,
                61.81,
                61.873,
                61.934,
                61.996,
                62.057,
                62.118,
                62.18,
                62.239,
                62.3,
                62.359,
                62.419,
                62.477,
                62.536,
                62.594,
                62.653,
                62.71,
                62.767,
                62.825,
                62.882,
                62.939,
                62.995,
                63.051,
                63.107,
                63.163,
                63.218,
                63.273,
                63.328,
                63.382,
                63.436,
                63.49,
                63.545,
                63.598,
                63.651,
                63.704,
                63.757,
                63.811,
                63.863,
                63.915,
                63.967,
                64.019,
                64.071,
                64.122,
                64.173,
                64.224,
                64.275,
                64.326,
                64.377,
                64.427,
                64.477,
                64.527,
                64.577,
                64.627,
                64.677,
                64.726,
                64.776,
                64.824,
                64.873,
                64.922,
                64.971,
                65.019,
                65.068,
                65.115,
                65.163,
                65.212,
                65.26,
                65.306,
                65.354,
                65.402,
                65.45,
                65.496,
                65.543,
                65.591,
                65.637,
                65.684,
                65.731,
                65.776,
                65.823,
                65.869,
                65.915,
                65.962,
                66.007,
                66.053,
                66.098,
                66.144,
                66.189,
                66.235,
                66.28,
                66.326,
                66.37,
                66.416,
                66.46,
                66.506,
                66.55,
                66.595,
                66.639,
                66.685,
                66.729,
                66.773,
                66.818,
                66.861,
                66.905,
                66.95,
                66.993,
                67.038,
                67.082,
                67.125,
                67.169,
                67.213,
                67.256,
                67.299,
                67.343,
                67.386,
                67.429,
                67.472,
                67.516,
                67.559,
                67.602,
                67.645,
                67.688,
                67.731,
                67.773,
                67.816,
                67.858,
                67.902,
                67.944,
                67.986,
                68.028,
                68.071,
                68.113,
                68.155,
                68.197,
                68.24,
                68.281,
                68.323,
                68.365,
                68.407,
                68.448,
                68.49,
                68.531,
                68.573,
                68.614,
                68.655,
                68.696,
                68.738,
                68.779,
                68.82,
                68.861,
                68.902,
                68.943,
                68.984,
                69.024,
                69.065,
                69.106,
                69.146,
                69.187,
                69.227,
                69.268,
                69.307,
                69.347,
                69.387,
                69.428,
                69.468,
                69.508,
                69.548,
                69.588,
                69.626,
                69.666,
                69.706,
                69.746,
                69.786,
                69.825,
                69.864,
                69.903,
                69.943,
                69.982,
                70.021,
                70.059,
                70.099,
                70.138,
                70.177,
                70.215,
                70.254,
                70.293,
                70.332,
                70.369,
                70.408,
                70.447,
                70.485,
                70.523,
                70.562,
                70.6,
                70.638,
                70.676,
                70.715,
                70.752,
                70.79,
                70.828,
                70.865,
                70.903,
                70.942,
                70.978,
                71.017,
                71.054,
                71.091,
                71.129,
                71.166,
                71.203,
                71.241,
                71.278,
                71.315,
                71.352,
                71.389,
                71.427,
                71.463,
                71.501,
                71.537,
                71.574,
                71.61,
                71.648,
                71.684,
                71.721,
                71.758,
                71.794,
                71.831,
                71.867,
                71.904,
                71.94,
                71.976,
                72.012,
                72.049,
                72.085,
                72.121,
                72.157,
                72.194,
                72.229,
                72.265,
                72.301,
                72.337,
                72.373,
                72.408,
                72.444,
                72.479,
                72.516,
                72.551,
                72.587,
                72.622,
                72.658,
                72.693,
                72.728,
                72.764,
                72.798,
                72.834,
                72.869,
                72.904,
                72.939,
                72.974,
                73.01,
                73.044,
                73.079,
                73.114,
                73.148,
                73.183,
                73.218,
                73.252,
                73.288,
                73.322,
                73.356,
                73.391,
                73.425,
                73.459,
                73.495,
                73.528,
                73.562,
                73.597,
                73.631,
                73.665,
                73.7,
                73.734,
                73.767,
                73.802,
                73.836,
                73.869,
                73.904,
                73.937,
                73.971,
                74.004,
                74.039,
                74.072,
                74.105,
                74.14,
                74.173,
                74.206,
                74.239,
                74.273,
                74.306,
                74.339,
                74.372,
                74.406,
                74.439,
                74.472,
                74.505,
                74.539,
                74.572,
                74.605,
                74.637,
                74.671,
                74.704,
                74.736,
                74.769,
                74.803,
                74.835,
                74.868,
                74.9,
                74.933,
                74.966,
                74.999,
                75.031,
                75.063,
                75.095,
                75.129,
                75.161,
                75.193,
                75.225,
                75.257,
                75.291,
                75.323,
                75.355,
                75.387,
                75.419,
                75.45,
                75.484,
                75.515,
                75.547,
                75.579,
                75.611,
                75.642,
                75.675,
                75.707,
                75.739,
                75.77,
                75.802,
                75.833,
                75.865,
                75.898,
                75.929,
                75.96,
                75.992,
                76.023,
                76.054,
                76.086,
                76.118,
                76.15,
                76.181,
                76.212,
                76.243,
                76.274,
                76.305,
                76.336,
                76.367,
                76.4,
                76.431,
                76.462,
                76.492,
                76.523,
                76.554,
                76.585,
                76.616,
                76.647,
                76.677,
                76.708,
                76.739,
                76.77,
                76.801,
                76.832,
                76.862,
                76.893,
                76.923,
                76.954,
                76.984,
                77.015,
                77.045,
                77.075,
                77.106,
                77.136,
                77.166,
                77.196,
                77.227,
                77.257,
                77.288,
                77.318,
                77.348,
                77.379,
                77.409,
                77.439,
                77.469,
                77.498,
                77.528,
                77.558,
                77.588,
                77.618,
                77.648,
                77.677,
                77.707,
                77.737,
                77.767,
                77.796,
                77.826,
                77.856,
                77.885,
                77.915,
                77.944,
                77.974,
                78.003,
                78.033,
                78.062,
                78.091,
                78.121,
                78.15,
                78.179,
                78.209,
                78.238,
                78.267,
                78.296,
                78.325,
                78.354,
                78.384,
                78.413,
                78.442,
                78.471,
                78.5,
                78.528,
                78.557,
                78.586,
                78.615,
                78.643,
                78.671,
                78.7,
                78.729,
                78.758,
                78.786,
                78.815,
                78.844,
                78.872,
                78.901,
                78.93,
                78.958,
                78.987,
                79.015,
                79.044,
                79.072,
                79.1,
                79.129,
                79.157,
                79.186,
                79.212,
                79.241,
                79.269,
                79.297,
                79.326,
                79.354,
                79.382,
                79.41,
                79.438,
                79.466,
                79.494,
                79.522,
                79.55,
                79.578,
                79.605,
                79.633,
                79.661,
                79.689,
                79.717,
                79.745,
                79.772,
                79.8,
                79.828,
                79.856,
                79.884,
                79.91,
                79.938,
                79.965,
                79.993,
                80.02,
                80.048,
                80.076,
                80.103,
                80.131,
                80.158,
                80.184,
                80.212,
                80.239,
                80.267,
                80.294,
                80.321,
                80.349,
                80.376,
                80.403,
                80.429,
                80.456,
                80.484,
                80.511,
                80.538,
                80.565,
                80.592,
                80.619,
                80.646,
                80.672,
                80.699,
                80.726,
                80.753,
                80.78,
                80.807,
                80.834,
                80.861,
                80.886,
                80.913,
                80.94,
                80.967,
                80.993,
                81.02,
                81.047,
                81.072,
                81.099,
                81.125,
                81.152,
                81.179,
                81.205,
                81.232,
                81.258,
                81.284,
                81.31,
                81.337,
                81.363,
                81.389,
                81.416,
                81.442,
                81.467,
                81.494,
                81.52,
                81.546,
                81.573,
                81.599,
                81.625,
                81.65,
                81.676,
                81.703,
                81.729,
                81.755,
                81.781,
                81.807,
                81.832,
                81.858,
                81.884,
                81.91,
                81.936,
                81.962,
                81.988,
                82.013,
                82.039,
                82.065,
                82.091,
                82.116,
                82.142,
                82.168,
                82.193,
                82.218,
                82.244,
                82.27,
                82.296,
                82.322,
                82.347,
                82.372,
                82.397,
                82.423,
                82.449,
                82.474,
                82.5,
                82.525,
                82.55,
                82.575,
                82.601,
                82.626,
                82.652,
                82.677,
                82.703,
                82.727,
                82.752,
                82.777,
                82.103,
                82.128,
                82.153,
                82.179,
                82.203,
                82.228,
                82.253,
                82.278,
                82.304,
                82.329,
                82.354,
                82.378,
                82.403,
                82.428,
                82.453,
                82.478,
                82.503,
                82.528,
                82.553,
                82.577,
                82.602,
                82.626,
                82.651,
                82.676,
                82.701,
                82.726,
                82.749,
                82.774,
                82.799,
                82.824,
                82.848,
                82.873,
                82.898,
                82.922,
                82.945,
                82.97,
                82.995,
                83.019,
                83.044,
                83.068,
                83.093,
                83.116,
                83.14,
                83.165,
                83.189,
                83.213,
                83.238,
                83.262,
                83.286,
                83.309,
                83.334,
                83.358,
                83.382,
                83.406,
                83.43,
                83.454,
                83.479,
                83.503,
                83.525,
                83.549,
                83.573,
                83.597,
                83.621,
                83.645,
                83.669,
                83.693,
                83.717,
                83.739,
                83.763,
                83.787,
                83.811,
                83.835,
                83.858,
                83.882,
                83.906,
                83.93,
                83.953,
                83.976,
                83.999,
                84.023,
                84.046,
                84.07,
                84.093,
                84.117,
                84.14,
                84.164,
                84.187,
                84.211,
                84.233,
                84.256,
                84.279,
                84.303,
                84.326,
                84.349,
                84.373,
                84.396,
                84.419,
                84.442,
                84.466,
                84.489,
                84.51,
                84.534,
                84.557,
                84.58,
                84.603,
                84.626,
                84.649,
                84.672,
                84.695,
                84.718,
                84.741,
                84.764,
                84.786,
                84.809,
                84.832,
                84.853,
                84.876,
                84.899,
                84.922,
                84.944,
                84.967,
                84.99,
                85.012,
                85.035,
                85.058,
                85.08,
                85.103,
                85.125,
                85.148,
                85.17,
                85.193,
                85.215,
                85.238,
                85.26,
                85.282,
                85.305,
                85.327,
                85.348,
                85.37,
                85.392,
                85.415,
                85.437,
                85.459,
                85.481,
                85.503,
                85.526,
                85.548,
                85.57,
                85.592,
                85.614,
                85.636,
                85.658,
                85.68,
                85.702,
                85.724,
                85.746,
                85.767,
                85.789,
                85.811,
                85.833,
                85.855,
                85.877,
                85.898,
                85.92,
                85.942,
                85.963,
                85.985,
                86.007,
                86.028,
                86.05,
                86.072,
                86.093,
                86.115,
                86.136,
                86.158,
                86.179,
                86.201,
                86.222,
                86.243,
                86.265,
                86.286,
                86.307,
                86.329,
                86.35,
                86.371,
                86.393,
                86.414,
                86.435,
                86.456,
                86.478,
                86.5,
                86.521,
                86.542,
                86.564,
                86.585,
                86.606,
                86.627,
                86.648,
                86.669,
                86.69,
                86.711,
                86.732,
                86.753,
                86.774,
                86.794,
                86.815,
                86.836,
                86.857,
                86.878,
                86.899,
                86.921,
                86.942,
                86.962,
                86.983,
                87.004,
                87.024,
                87.045,
                87.066,
                87.086,
                87.107,
                87.128,
                87.148,
                87.169,
                87.189,
                87.211,
                87.232,
                87.252,
                87.273,
                87.293,
                87.313,
                87.334,
                87.354,
                87.375,
                87.395,
                87.415,
                87.437,
                87.457,
                87.478,
                87.498,
                87.518,
                87.538,
                87.558,
                87.579,
                87.599,
                87.619,
                87.641,
                87.661,
                87.681,
                87.701,
                87.721,
                87.741,
                87.761,
                87.781,
                87.803,
                87.823,
                87.842,
                87.862,
                87.882,
                87.902,
                87.922,
                87.942,
                87.963,
                87.983,
                88.003,
                88.023,
                88.043,
                88.062,
                88.082,
                88.104,
                88.123,
                88.143,
                88.163,
                88.182,
                88.202,
                88.222,
                88.243,
                88.263,
                88.282,
                88.302,
                88.321,
                88.341,
                88.362,
                88.382,
                88.401,
                88.421,
                88.44,
                88.46,
                88.481,
                88.5,
                88.52,
                88.539,
                88.559,
                88.579,
                88.599,
                88.618,
                88.638,
                88.657,
                88.676,
                88.697,
                88.717,
                88.736,
                88.755,
                88.774,
                88.795,
                88.815,
                88.834,
                88.853,
                88.872,
                88.893,
                88.912,
                88.931,
                88.951,
                88.97,
                88.99,
                89.01,
                89.029,
                89.048,
                89.069,
                89.088,
                89.107,
                89.126,
                89.145,
                89.165,
                89.185,
                89.204,
                89.223,
                89.243,
                89.262,
                89.281,
                89.3,
                89.319,
                89.34,
                89.359,
                89.377,
                89.396,
                89.417,
                89.436,
                89.455,
                89.473,
                89.494,
                89.513,
                89.532,
                89.55,
                89.571,
                89.59,
                89.608,
                89.627,
                89.647,
                89.666,
                89.685,
                89.704,
                89.724,
                89.743,
                89.761,
                89.782,
                89.8,
                89.819,
                89.838,
                89.858,
                89.876,
                89.895,
                89.914,
                89.934,
                89.953,
                89.971,
                89.991,
                90.01,
                90.028,
                90.047,
                90.067,
                90.086,
                90.104,
                90.124,
                90.143,
                90.161,
                90.18,
                90.2,
                90.218,
                90.237,
                90.257,
                90.275,
                90.293,
                90.313,
                90.332,
                90.35,
                90.369,
                90.388,
                90.407,
                90.425,
                90.445,
                90.463,
                90.482,
                90.502,
                90.52,
                90.538,
                90.558,
                90.576,
                90.595,
                90.614,
                90.633,
                90.651,
                90.671,
                90.689,
                90.707,
                90.727,
                90.745,
                90.763,
                90.783,
                90.801,
                90.819,
                90.839,
                90.857,
                90.875,
                90.895,
                90.913,
                90.931,
                90.951,
                90.969,
                90.987,
                91.006,
                91.024,
                91.042,
                91.062,
                91.08,
                91.098,
                91.118,
                91.136,
                91.154,
                91.173,
                91.191,
                91.211,
                91.229,
                91.247,
                91.266,
                91.284,
                91.302,
                91.321,
                91.339,
                91.357,
                91.377,
                91.394,
                91.414,
                91.432,
                91.45,
                91.469,
                91.487,
                91.505,
                91.524,
                91.542,
                91.561,
                91.579,
                91.597,
                91.616,
                91.634,
                91.653,
                91.671,
                91.688,
                91.708,
                91.725,
                91.745,
                91.762,
                91.78,
                91.799,
                91.817,
                91.834,
                91.854,
                91.871,
                91.891,
                91.908,
                91.926,
                91.945,
                91.962,
                91.982,
                91.999,
                92.018,
                92.036,
                92.053,
                92.072,
                92.09,
                92.109,
                92.127,
                92.144,
                92.163,
                92.181,
                92.2,
                92.217,
                92.234,
                92.253,
                92.271,
                92.29,
                92.307,
                92.326,
                92.344,
                92.361,
                92.38,
                92.397,
                92.416,
                92.434,
                92.452,
                92.47,
                92.487,
                92.506,
                92.523,
                92.542,
                92.559,
                92.578,
                92.595,
                92.613,
                92.631,
                92.649,
                92.667,
                92.685,
                92.703,
                92.721,
                92.739,
                92.756,
                92.774,
                92.792,
                92.809,
                92.828,
                92.845,
                92.864,
                92.881,
                92.9,
                92.917,
                92.935,
                92.953,
                92.969,
                92.988,
                93.005,
                93.024,
                93.041,
                93.059,
                93.076,
                93.095,
                93.112,
                93.13,
                93.147,
                93.164,
                93.183,
                93.2,
                93.218,
                93.235,
                93.254,
                93.27,
                93.289,
                93.306,
                93.324,
                93.341,
                93.359,
                93.376,
                93.395,
                93.412,
                93.43,
                93.447,
                93.463,
                93.482,
                93.499,
                93.517,
                93.534,
                93.552,
                93.569,
                93.587,
                93.604,
                93.622,
                93.639,
                93.657,
                93.674,
                93.692,
                93.709,
                93.727,
                93.743,
                93.762,
                93.778,
                93.797,
                93.813,
                93.831,
                93.848,
                93.866,
                93.883,
                93.901,
                93.917,
                93.936,
                93.952,
                93.969,
                93.987,
                94.003,
                94.021,
                94.038,
                94.056,
                94.072,
                94.09,
                94.107,
                94.125,
                94.141,
                94.159,
                94.176,
                94.194,
                94.21,
                94.228,
                94.245,
                94.263,
                94.279,
                94.297,
                94.313,
                94.331,
                94.348,
                94.366,
                94.382,
                94.4,
                94.416,
                94.434,
                94.45,
                94.468,
                94.486,
                94.502,
                94.52,
                94.537,
                94.554,
                94.571,
                94.588,
                94.605,
                94.622,
                94.639,
                94.656,
                94.673,
                94.69,
                94.707,
                94.724,
                94.741,
                94.758,
                94.775,
                94.792,
                94.808,
                94.826,
                94.842,
                94.86,
                94.876,
                94.894,
                94.91,
                94.928,
                94.944,
                94.961,
                94.977,
                94.995,
                95.011,
                95.029,
                95.046,
                95.062,
                95.08,
                95.096,
                95.114,
                95.13,
                95.147,
                95.163,
                95.181,
                95.197,
                95.214,
                95.23,
                95.248,
                95.264,
                95.282,
                95.297,
                95.315,
                95.331,
                95.348,
                95.364,
                95.382,
                95.399,
                95.415,
                95.433,
                95.449,
                95.466,
                95.482,
                95.5,
                95.515,
                95.533,
                95.549,
                95.566,
                95.582,
                95.599,
                95.615,
                95.633,
                95.65,
                95.666,
                95.683,
                95.699,
                95.717,
                95.732,
                95.75,
                95.766,
                95.783,
                95.799,
                95.816,
                95.832,
                95.849,
                95.865,
                95.882,
                95.9,
                95.915,
                95.933,
                95.949,
                95.966,
                95.982,
                95.999,
                96.015,
                96.032,
                96.048,
                96.065,
                96.081,
                96.098,
                96.115,
                96.131,
                96.148,
                96.164,
                96.181,
                96.197,
                96.214,
                96.23,
                96.247,
                96.263,
                96.28,
                96.297,
                96.313,
                96.33,
                96.346,
                96.363,
                96.379,
                96.396,
                96.411,
                96.429,
                96.444,
                96.462,
                96.479,
                96.494,
                96.512,
                96.527,
                96.544,
                96.56,
                96.577,
                96.593,
                96.61,
                96.625,
                96.643,
                96.66,
                96.675,
                96.692,
                96.708,
                96.725,
                96.741,
                96.758,
                96.773,
                96.791,
                96.808,
                96.823,
                96.84,
                96.856,
                96.873,
                96.889,
                96.906,
                96.921,
                96.938,
                96.955,
                96.971,
                96.988,
                97.003,
                97.021,
                97.036,
                97.053,
                97.069,
                97.086,
                97.101,
                97.118,
                97.135,
                97.151,
                97.168,
                97.183,
                97.201,
                97.216,
                97.233,
                97.248,
                97.266,
                97.283,
                97.298,
                97.315,
                97.331,
                97.348,
                97.363,
                97.38,
                97.396,
                97.413,
                97.43,
                97.445,
                97.462,
                97.478,
                97.495,
                97.51,
                97.527,
                97.544,
                97.559,
                97.577,
                97.592,
                97.609,
                97.624,
                97.641,
                97.657,
                97.674,
                97.691,
                97.706,
                97.723,
                97.739,
                97.756,
                97.771,
                97.788,
                97.803,
                97.82,
                97.837,
                97.853,
                97.87,
                97.885,
                97.902,
                97.917,
                97.934,
                97.952,
                97.967,
                97.984,
                97.999,
                98.016,
                98.032,
                98.048,
                98.064,
                98.081,
                98.098,
                98.113,
                98.13,
                98.145,
                98.162,
                98.178,
                98.195,
                98.212,
                98.227,
                98.244,
                98.259,
                98.276,
                98.292,
                98.309,
                98.324,
                98.341,
                98.358,
                98.373,
                98.39,
                98.405,
                98.422,
                98.438,
                98.455,
                98.472,
                98.487,
                98.504,
                98.519,
                98.536,
                98.551,
                98.568,
                98.585,
                98.601,
                98.618,
                98.633,
                98.65,
                98.665,
                98.682,
                98.699,
                98.714,
                98.731,
                98.746,
                98.763,
                98.779,
                98.796,
                98.812,
                98.828,
                98.845,
                98.86,
                98.877,
                98.892,
                98.909,
                98.924,
                98.941,
                98.958,
                98.973,
                98.99,
                99.005,
                99.022,
                99.038,
                99.055,
                99.072,
                99.087,
                99.104,
                99.119,
                99.136,
                99.153,
                99.168,
                99.185,
                99.2,
                99.217,
                99.232,
                99.249,
                99.266,
                99.281,
                99.298,
                99.313,
                99.33,
                99.345,
                99.362,
                99.379,
                99.394,
                99.411,
                99.426,
                99.443,
                99.458,
                99.475,
                99.492,
                99.507,
                99.524,
                99.539,
                99.556,
                99.572,
                99.588,
                99.605,
                99.62,
                99.637,
                99.652,
                99.669,
                99.685,
                99.701,
                99.718,
                99.733,
                99.75,
                99.766,
                99.782,
                99.799,
                99.814,
                99.831,
                99.846,
                99.863,
                99.878,
                99.895,
                99.912,
                99.927,
                99.944,
                99.959,
                99.976,
                99.993,
                100.008,
                100.025,
                100.04,
                100.057,
                100.072,
                100.089,
                100.106,
                100.121,
                100.138,
                100.153,
                100.17,
                100.185,
                100.202,
                100.219,
                100.234,
                100.251,
                100.266,
                100.282,
                100.299,
                100.314,
                100.331,
                100.346,
                100.363,
                100.378,
                100.395,
                100.412,
                100.427,
                100.444,
                100.459,
                100.476,
                100.493,
                100.508,
                100.524,
                100.54,
                100.556,
                100.573,
                100.588,
                100.605,
                100.62,
                100.637,
                100.652,
                100.669,
                100.686,
                100.701,
                100.718,
                100.733,
                100.749,
                100.766,
                100.781,
                100.798,
                100.813,
                100.83,
                100.847,
                100.862,
                100.879,
                100.894,
                100.91,
                100.925,
                100.942,
                100.959,
                100.974,
                100.991,
                101.006,
                101.023,
                101.04,
                101.055,
                101.071,
                101.086,
                101.103,
                101.12,
                101.135,
                101.152,
                101.167,
                101.184,
                101.2,
                101.215,
                101.232,
                101.247,
                101.264,
                101.279,
                101.296,
                101.313,
                101.328,
                101.344,
                101.359,
                101.376,
                101.393,
                101.408,
                101.425,
                101.44,
                101.456,
                101.473,
                101.488,
                101.505,
                101.52,
                101.537,
                101.554,
                101.568,
                101.585,
                101.6,
                101.617,
                101.634,
                101.649,
                101.666,
                101.68,
                101.697,
                101.714,
                101.729,
                101.746,
                101.761,
                101.777,
                101.794,
                101.809,
                101.826,
                101.841,
                101.858,
                101.874,
                101.889,
                101.906,
                101.921,
                101.938,
                101.954,
                101.969,
                101.986,
                102.001,
                102.018,
                102.034,
                102.049,
                102.066,
                102.081,
                102.098,
                102.114,
                102.129,
                102.146,
                102.161,
                102.178,
                102.194,
                102.209,
                102.226,
                102.241,
                102.258,
                102.274,
                102.289,
                102.306,
                102.321,
                102.338,
                102.354,
                102.369,
                102.386,
                102.401,
                102.417,
                102.434,
                102.449,
                102.466,
                102.481,
                102.497,
                102.514,
                102.529,
                102.545,
                102.56,
                102.577,
                102.594,
                102.609,
                102.625,
                102.64,
                102.657,
                102.673,
                102.688,
                102.705,
                102.722,
                102.736,
                102.753,
                102.768,
                102.785,
                102.801,
                102.816
            ],
            P10: [
                47.458,
                47.632,
                47.806,
                47.98,
                48.154,
                48.328,
                48.502,
                48.676,
                48.85,
                49.024,
                49.199,
                49.373,
                49.548,
                49.722,
                49.896,
                50.045,
                50.193,
                50.341,
                50.487,
                50.633,
                50.777,
                50.921,
                51.064,
                51.206,
                51.346,
                51.486,
                51.625,
                51.763,
                51.9,
                52.036,
                52.171,
                52.305,
                52.438,
                52.57,
                52.701,
                52.831,
                52.96,
                53.088,
                53.215,
                53.341,
                53.466,
                53.589,
                53.713,
                53.835,
                53.956,
                54.075,
                54.195,
                54.313,
                54.43,
                54.547,
                54.662,
                54.776,
                54.89,
                55.002,
                55.114,
                55.225,
                55.335,
                55.445,
                55.554,
                55.661,
                55.768,
                55.875,
                55.98,
                56.086,
                56.191,
                56.295,
                56.398,
                56.501,
                56.604,
                56.705,
                56.806,
                56.906,
                57.006,
                57.105,
                57.203,
                57.302,
                57.399,
                57.495,
                57.591,
                57.686,
                57.781,
                57.875,
                57.968,
                58.061,
                58.153,
                58.245,
                58.336,
                58.426,
                58.516,
                58.605,
                58.694,
                58.782,
                58.869,
                58.957,
                59.042,
                59.128,
                59.213,
                59.298,
                59.382,
                59.466,
                59.548,
                59.631,
                59.712,
                59.794,
                59.874,
                59.954,
                60.034,
                60.113,
                60.191,
                60.27,
                60.347,
                60.424,
                60.5,
                60.576,
                60.652,
                60.727,
                60.801,
                60.875,
                60.948,
                61.021,
                61.093,
                61.165,
                61.237,
                61.308,
                61.378,
                61.448,
                61.518,
                61.587,
                61.656,
                61.724,
                61.792,
                61.859,
                61.926,
                61.992,
                62.059,
                62.124,
                62.19,
                62.254,
                62.319,
                62.384,
                62.448,
                62.51,
                62.574,
                62.637,
                62.699,
                62.761,
                62.822,
                62.884,
                62.945,
                63.005,
                63.066,
                63.126,
                63.186,
                63.245,
                63.304,
                63.363,
                63.421,
                63.479,
                63.537,
                63.595,
                63.652,
                63.709,
                63.766,
                63.822,
                63.878,
                63.934,
                63.989,
                64.045,
                64.1,
                64.155,
                64.209,
                64.264,
                64.318,
                64.372,
                64.426,
                64.479,
                64.532,
                64.586,
                64.639,
                64.691,
                64.744,
                64.796,
                64.848,
                64.9,
                64.951,
                65.003,
                65.054,
                65.105,
                65.156,
                65.207,
                65.258,
                65.308,
                65.358,
                65.409,
                65.459,
                65.508,
                65.558,
                65.607,
                65.656,
                65.705,
                65.755,
                65.804,
                65.852,
                65.9,
                65.949,
                65.997,
                66.046,
                66.093,
                66.141,
                66.189,
                66.237,
                66.284,
                66.332,
                66.379,
                66.426,
                66.473,
                66.52,
                66.567,
                66.614,
                66.66,
                66.707,
                66.754,
                66.799,
                66.846,
                66.891,
                66.938,
                66.983,
                67.03,
                67.075,
                67.121,
                67.166,
                67.212,
                67.257,
                67.303,
                67.347,
                67.393,
                67.438,
                67.483,
                67.528,
                67.572,
                67.617,
                67.662,
                67.706,
                67.751,
                67.795,
                67.84,
                67.884,
                67.928,
                67.973,
                68.016,
                68.06,
                68.104,
                68.148,
                68.192,
                68.235,
                68.279,
                68.323,
                68.366,
                68.41,
                68.453,
                68.497,
                68.54,
                68.583,
                68.626,
                68.669,
                68.713,
                68.755,
                68.798,
                68.841,
                68.883,
                68.926,
                68.968,
                69.011,
                69.054,
                69.096,
                69.139,
                69.181,
                69.223,
                69.265,
                69.307,
                69.349,
                69.391,
                69.433,
                69.475,
                69.516,
                69.558,
                69.6,
                69.641,
                69.683,
                69.724,
                69.766,
                69.807,
                69.848,
                69.889,
                69.931,
                69.972,
                70.013,
                70.054,
                70.095,
                70.134,
                70.175,
                70.216,
                70.257,
                70.297,
                70.338,
                70.378,
                70.419,
                70.458,
                70.498,
                70.539,
                70.579,
                70.619,
                70.659,
                70.698,
                70.738,
                70.778,
                70.818,
                70.858,
                70.897,
                70.936,
                70.976,
                71.016,
                71.054,
                71.094,
                71.133,
                71.173,
                71.211,
                71.25,
                71.29,
                71.328,
                71.367,
                71.406,
                71.445,
                71.483,
                71.522,
                71.561,
                71.599,
                71.638,
                71.676,
                71.714,
                71.753,
                71.791,
                71.829,
                71.867,
                71.906,
                71.943,
                71.982,
                72.019,
                72.057,
                72.095,
                72.133,
                72.171,
                72.208,
                72.246,
                72.284,
                72.321,
                72.359,
                72.396,
                72.433,
                72.47,
                72.508,
                72.545,
                72.582,
                72.62,
                72.657,
                72.694,
                72.731,
                72.768,
                72.805,
                72.842,
                72.878,
                72.916,
                72.952,
                72.989,
                73.025,
                73.063,
                73.099,
                73.136,
                73.172,
                73.209,
                73.245,
                73.28,
                73.317,
                73.353,
                73.39,
                73.426,
                73.462,
                73.498,
                73.535,
                73.57,
                73.606,
                73.642,
                73.678,
                73.714,
                73.749,
                73.785,
                73.821,
                73.856,
                73.892,
                73.927,
                73.963,
                73.999,
                74.034,
                74.069,
                74.105,
                74.14,
                74.175,
                74.21,
                74.245,
                74.281,
                74.315,
                74.35,
                74.386,
                74.42,
                74.455,
                74.49,
                74.525,
                74.559,
                74.595,
                74.629,
                74.663,
                74.699,
                74.733,
                74.767,
                74.802,
                74.836,
                74.871,
                74.905,
                74.94,
                74.974,
                75.008,
                75.042,
                75.076,
                75.11,
                75.144,
                75.179,
                75.212,
                75.246,
                75.28,
                75.314,
                75.348,
                75.382,
                75.415,
                75.45,
                75.483,
                75.516,
                75.55,
                75.584,
                75.618,
                75.651,
                75.684,
                75.718,
                75.751,
                75.785,
                75.818,
                75.851,
                75.885,
                75.918,
                75.951,
                75.984,
                76.017,
                76.051,
                76.084,
                76.116,
                76.149,
                76.182,
                76.216,
                76.249,
                76.281,
                76.314,
                76.346,
                76.379,
                76.413,
                76.445,
                76.478,
                76.51,
                76.543,
                76.575,
                76.608,
                76.641,
                76.673,
                76.705,
                76.737,
                76.77,
                76.802,
                76.835,
                76.867,
                76.899,
                76.931,
                76.963,
                76.995,
                77.027,
                77.06,
                77.092,
                77.124,
                77.156,
                77.188,
                77.22,
                77.252,
                77.283,
                77.315,
                77.348,
                77.379,
                77.411,
                77.443,
                77.474,
                77.506,
                77.537,
                77.569,
                77.6,
                77.631,
                77.663,
                77.694,
                77.727,
                77.758,
                77.789,
                77.82,
                77.852,
                77.883,
                77.914,
                77.945,
                77.976,
                78.007,
                78.038,
                78.069,
                78.1,
                78.131,
                78.162,
                78.193,
                78.224,
                78.256,
                78.287,
                78.317,
                78.348,
                78.379,
                78.41,
                78.44,
                78.471,
                78.501,
                78.532,
                78.563,
                78.593,
                78.624,
                78.654,
                78.684,
                78.715,
                78.745,
                78.776,
                78.806,
                78.836,
                78.866,
                78.897,
                78.927,
                78.957,
                78.987,
                79.017,
                79.047,
                79.077,
                79.108,
                79.138,
                79.168,
                79.197,
                79.227,
                79.257,
                79.287,
                79.317,
                79.347,
                79.377,
                79.406,
                79.436,
                79.466,
                79.495,
                79.525,
                79.555,
                79.584,
                79.614,
                79.642,
                79.672,
                79.701,
                79.731,
                79.76,
                79.789,
                79.819,
                79.848,
                79.877,
                79.907,
                79.936,
                79.965,
                79.994,
                80.024,
                80.053,
                80.082,
                80.111,
                80.14,
                80.169,
                80.198,
                80.226,
                80.255,
                80.284,
                80.313,
                80.342,
                80.371,
                80.4,
                80.428,
                80.457,
                80.486,
                80.515,
                80.543,
                80.572,
                80.601,
                80.628,
                80.657,
                80.686,
                80.714,
                80.743,
                80.771,
                80.8,
                80.828,
                80.857,
                80.885,
                80.914,
                80.941,
                80.969,
                80.998,
                81.026,
                81.054,
                81.083,
                81.111,
                81.139,
                81.167,
                81.196,
                81.223,
                81.251,
                81.279,
                81.307,
                81.335,
                81.363,
                81.391,
                81.419,
                81.447,
                81.474,
                81.502,
                81.53,
                81.558,
                81.585,
                81.613,
                81.641,
                81.669,
                81.697,
                81.723,
                81.751,
                81.779,
                81.806,
                81.834,
                81.861,
                81.889,
                81.917,
                81.943,
                81.971,
                81.998,
                82.026,
                82.053,
                82.08,
                82.108,
                82.134,
                82.162,
                82.189,
                82.216,
                82.243,
                82.271,
                82.298,
                82.325,
                82.351,
                82.379,
                82.406,
                82.433,
                82.46,
                82.487,
                82.514,
                82.54,
                82.567,
                82.594,
                82.621,
                82.648,
                82.675,
                82.702,
                82.728,
                82.755,
                82.782,
                82.809,
                82.836,
                82.862,
                82.889,
                82.915,
                82.942,
                82.968,
                82.995,
                83.022,
                83.049,
                83.075,
                83.101,
                83.127,
                83.154,
                83.181,
                83.207,
                83.234,
                83.26,
                83.286,
                83.312,
                83.339,
                83.365,
                83.392,
                83.418,
                83.445,
                83.47,
                83.496,
                83.523,
                83.549,
                83.575,
                83.602,
                83.628,
                83.653,
                83.679,
                83.705,
                83.732,
                83.758,
                83.784,
                83.81,
                83.835,
                83.861,
                83.887,
                83.213,
                83.239,
                83.265,
                83.291,
                83.316,
                83.342,
                83.368,
                83.394,
                83.42,
                83.446,
                83.471,
                83.496,
                83.522,
                83.548,
                83.574,
                83.599,
                83.625,
                83.651,
                83.676,
                83.701,
                83.726,
                83.752,
                83.778,
                83.803,
                83.829,
                83.854,
                83.879,
                83.904,
                83.93,
                83.955,
                83.98,
                84.006,
                84.031,
                84.056,
                84.081,
                84.106,
                84.131,
                84.156,
                84.182,
                84.207,
                84.232,
                84.256,
                84.281,
                84.306,
                84.331,
                84.356,
                84.381,
                84.406,
                84.431,
                84.455,
                84.48,
                84.505,
                84.53,
                84.555,
                84.58,
                84.604,
                84.629,
                84.654,
                84.678,
                84.702,
                84.727,
                84.752,
                84.776,
                84.801,
                84.826,
                84.85,
                84.875,
                84.898,
                84.923,
                84.947,
                84.972,
                84.996,
                85.02,
                85.045,
                85.069,
                85.094,
                85.118,
                85.141,
                85.165,
                85.19,
                85.214,
                85.238,
                85.262,
                85.287,
                85.311,
                85.335,
                85.359,
                85.383,
                85.406,
                85.43,
                85.454,
                85.478,
                85.502,
                85.526,
                85.55,
                85.574,
                85.598,
                85.622,
                85.646,
                85.67,
                85.692,
                85.716,
                85.74,
                85.764,
                85.787,
                85.811,
                85.835,
                85.858,
                85.882,
                85.906,
                85.929,
                85.953,
                85.976,
                86,
                86.023,
                86.046,
                86.069,
                86.092,
                86.116,
                86.139,
                86.163,
                86.186,
                86.209,
                86.233,
                86.256,
                86.279,
                86.302,
                86.325,
                86.349,
                86.372,
                86.395,
                86.418,
                86.441,
                86.464,
                86.487,
                86.51,
                86.533,
                86.555,
                86.578,
                86.601,
                86.624,
                86.647,
                86.67,
                86.692,
                86.715,
                86.738,
                86.761,
                86.784,
                86.806,
                86.829,
                86.852,
                86.874,
                86.897,
                86.92,
                86.942,
                86.965,
                86.987,
                87.01,
                87.032,
                87.055,
                87.077,
                87.1,
                87.122,
                87.145,
                87.167,
                87.189,
                87.211,
                87.234,
                87.256,
                87.278,
                87.301,
                87.323,
                87.345,
                87.367,
                87.389,
                87.412,
                87.434,
                87.456,
                87.478,
                87.5,
                87.522,
                87.544,
                87.566,
                87.588,
                87.61,
                87.632,
                87.654,
                87.676,
                87.697,
                87.719,
                87.742,
                87.764,
                87.786,
                87.808,
                87.829,
                87.851,
                87.873,
                87.894,
                87.916,
                87.938,
                87.959,
                87.981,
                88.003,
                88.024,
                88.046,
                88.067,
                88.089,
                88.11,
                88.132,
                88.153,
                88.176,
                88.197,
                88.219,
                88.24,
                88.261,
                88.283,
                88.304,
                88.325,
                88.346,
                88.368,
                88.389,
                88.41,
                88.431,
                88.453,
                88.475,
                88.496,
                88.517,
                88.538,
                88.559,
                88.58,
                88.601,
                88.623,
                88.644,
                88.665,
                88.686,
                88.708,
                88.729,
                88.75,
                88.77,
                88.791,
                88.812,
                88.833,
                88.854,
                88.875,
                88.895,
                88.917,
                88.938,
                88.959,
                88.98,
                89,
                89.021,
                89.042,
                89.062,
                89.084,
                89.105,
                89.125,
                89.146,
                89.167,
                89.187,
                89.208,
                89.228,
                89.25,
                89.271,
                89.291,
                89.311,
                89.332,
                89.352,
                89.373,
                89.394,
                89.415,
                89.435,
                89.455,
                89.476,
                89.496,
                89.516,
                89.538,
                89.558,
                89.579,
                89.599,
                89.619,
                89.639,
                89.661,
                89.681,
                89.701,
                89.721,
                89.741,
                89.762,
                89.783,
                89.803,
                89.823,
                89.843,
                89.863,
                89.885,
                89.905,
                89.925,
                89.945,
                89.965,
                89.985,
                90.006,
                90.026,
                90.046,
                90.066,
                90.086,
                90.107,
                90.127,
                90.147,
                90.167,
                90.187,
                90.208,
                90.227,
                90.247,
                90.267,
                90.287,
                90.308,
                90.328,
                90.348,
                90.367,
                90.388,
                90.408,
                90.428,
                90.447,
                90.467,
                90.488,
                90.508,
                90.528,
                90.547,
                90.568,
                90.588,
                90.607,
                90.627,
                90.647,
                90.667,
                90.687,
                90.706,
                90.726,
                90.747,
                90.766,
                90.786,
                90.805,
                90.826,
                90.846,
                90.865,
                90.885,
                90.905,
                90.925,
                90.944,
                90.964,
                90.984,
                91.004,
                91.023,
                91.042,
                91.063,
                91.082,
                91.102,
                91.122,
                91.142,
                91.161,
                91.18,
                91.201,
                91.22,
                91.239,
                91.259,
                91.279,
                91.298,
                91.318,
                91.338,
                91.357,
                91.376,
                91.396,
                91.416,
                91.435,
                91.454,
                91.475,
                91.494,
                91.513,
                91.532,
                91.553,
                91.572,
                91.591,
                91.611,
                91.63,
                91.649,
                91.67,
                91.689,
                91.708,
                91.727,
                91.747,
                91.766,
                91.785,
                91.805,
                91.824,
                91.843,
                91.863,
                91.882,
                91.901,
                91.921,
                91.94,
                91.959,
                91.979,
                91.998,
                92.017,
                92.037,
                92.056,
                92.075,
                92.095,
                92.114,
                92.132,
                92.152,
                92.171,
                92.19,
                92.21,
                92.229,
                92.248,
                92.268,
                92.286,
                92.305,
                92.325,
                92.344,
                92.362,
                92.382,
                92.401,
                92.42,
                92.44,
                92.458,
                92.477,
                92.497,
                92.515,
                92.534,
                92.554,
                92.573,
                92.592,
                92.611,
                92.63,
                92.649,
                92.668,
                92.686,
                92.706,
                92.725,
                92.743,
                92.763,
                92.782,
                92.801,
                92.82,
                92.838,
                92.858,
                92.876,
                92.895,
                92.915,
                92.933,
                92.953,
                92.971,
                92.989,
                93.009,
                93.027,
                93.047,
                93.065,
                93.084,
                93.103,
                93.122,
                93.141,
                93.16,
                93.178,
                93.197,
                93.216,
                93.234,
                93.254,
                93.272,
                93.291,
                93.31,
                93.328,
                93.347,
                93.365,
                93.385,
                93.403,
                93.422,
                93.441,
                93.459,
                93.478,
                93.496,
                93.516,
                93.534,
                93.552,
                93.571,
                93.59,
                93.609,
                93.627,
                93.645,
                93.664,
                93.682,
                93.702,
                93.72,
                93.739,
                93.757,
                93.775,
                93.794,
                93.812,
                93.831,
                93.849,
                93.869,
                93.887,
                93.905,
                93.924,
                93.942,
                93.961,
                93.979,
                93.998,
                94.016,
                94.034,
                94.053,
                94.071,
                94.09,
                94.108,
                94.127,
                94.144,
                94.163,
                94.181,
                94.199,
                94.218,
                94.236,
                94.255,
                94.273,
                94.291,
                94.309,
                94.328,
                94.346,
                94.365,
                94.383,
                94.4,
                94.419,
                94.437,
                94.456,
                94.473,
                94.492,
                94.51,
                94.529,
                94.546,
                94.565,
                94.583,
                94.6,
                94.619,
                94.637,
                94.655,
                94.673,
                94.692,
                94.709,
                94.728,
                94.746,
                94.764,
                94.782,
                94.8,
                94.818,
                94.837,
                94.854,
                94.873,
                94.89,
                94.908,
                94.926,
                94.944,
                94.962,
                94.98,
                94.998,
                95.016,
                95.034,
                95.052,
                95.07,
                95.088,
                95.106,
                95.123,
                95.142,
                95.159,
                95.178,
                95.195,
                95.214,
                95.231,
                95.249,
                95.267,
                95.285,
                95.302,
                95.321,
                95.338,
                95.356,
                95.374,
                95.392,
                95.409,
                95.426,
                95.445,
                95.462,
                95.48,
                95.497,
                95.516,
                95.533,
                95.551,
                95.568,
                95.587,
                95.604,
                95.622,
                95.639,
                95.658,
                95.675,
                95.693,
                95.71,
                95.728,
                95.745,
                95.763,
                95.78,
                95.799,
                95.816,
                95.834,
                95.851,
                95.869,
                95.886,
                95.904,
                95.921,
                95.939,
                95.957,
                95.974,
                95.993,
                96.009,
                96.028,
                96.044,
                96.063,
                96.079,
                96.098,
                96.114,
                96.132,
                96.149,
                96.167,
                96.184,
                96.202,
                96.219,
                96.237,
                96.254,
                96.272,
                96.289,
                96.307,
                96.323,
                96.342,
                96.358,
                96.376,
                96.393,
                96.411,
                96.428,
                96.446,
                96.462,
                96.48,
                96.497,
                96.515,
                96.533,
                96.549,
                96.567,
                96.584,
                96.602,
                96.619,
                96.636,
                96.653,
                96.671,
                96.688,
                96.705,
                96.722,
                96.74,
                96.756,
                96.774,
                96.791,
                96.809,
                96.825,
                96.843,
                96.86,
                96.878,
                96.895,
                96.912,
                96.93,
                96.946,
                96.964,
                96.98,
                96.998,
                97.015,
                97.032,
                97.049,
                97.067,
                97.083,
                97.101,
                97.117,
                97.135,
                97.153,
                97.169,
                97.187,
                97.203,
                97.221,
                97.238,
                97.255,
                97.272,
                97.289,
                97.306,
                97.323,
                97.34,
                97.357,
                97.374,
                97.392,
                97.409,
                97.425,
                97.443,
                97.46,
                97.477,
                97.493,
                97.511,
                97.527,
                97.545,
                97.561,
                97.579,
                97.595,
                97.613,
                97.63,
                97.647,
                97.664,
                97.681,
                97.698,
                97.715,
                97.732,
                97.748,
                97.766,
                97.782,
                97.8,
                97.817,
                97.834,
                97.851,
                97.867,
                97.885,
                97.901,
                97.919,
                97.935,
                97.952,
                97.969,
                97.986,
                98.004,
                98.02,
                98.037,
                98.054,
                98.071,
                98.087,
                98.105,
                98.121,
                98.138,
                98.155,
                98.172,
                98.189,
                98.206,
                98.223,
                98.239,
                98.257,
                98.273,
                98.29,
                98.307,
                98.324,
                98.341,
                98.358,
                98.375,
                98.391,
                98.409,
                98.425,
                98.442,
                98.458,
                98.476,
                98.493,
                98.509,
                98.527,
                98.543,
                98.56,
                98.576,
                98.594,
                98.61,
                98.627,
                98.643,
                98.661,
                98.678,
                98.694,
                98.711,
                98.728,
                98.745,
                98.761,
                98.778,
                98.794,
                98.812,
                98.829,
                98.845,
                98.863,
                98.879,
                98.896,
                98.912,
                98.929,
                98.945,
                98.963,
                98.98,
                98.996,
                99.014,
                99.03,
                99.047,
                99.063,
                99.08,
                99.098,
                99.114,
                99.131,
                99.147,
                99.164,
                99.18,
                99.198,
                99.214,
                99.231,
                99.248,
                99.264,
                99.282,
                99.298,
                99.315,
                99.331,
                99.348,
                99.364,
                99.382,
                99.399,
                99.415,
                99.432,
                99.448,
                99.466,
                99.481,
                99.499,
                99.516,
                99.532,
                99.549,
                99.565,
                99.583,
                99.599,
                99.616,
                99.632,
                99.649,
                99.666,
                99.682,
                99.7,
                99.716,
                99.733,
                99.749,
                99.766,
                99.783,
                99.799,
                99.817,
                99.833,
                99.85,
                99.866,
                99.883,
                99.899,
                99.916,
                99.933,
                99.949,
                99.967,
                99.983,
                100,
                100.016,
                100.033,
                100.05,
                100.066,
                100.083,
                100.099,
                100.117,
                100.132,
                100.15,
                100.167,
                100.183,
                100.2,
                100.216,
                100.233,
                100.249,
                100.266,
                100.284,
                100.3,
                100.317,
                100.333,
                100.35,
                100.366,
                100.383,
                100.4,
                100.416,
                100.433,
                100.449,
                100.467,
                100.482,
                100.5,
                100.515,
                100.533,
                100.55,
                100.566,
                100.583,
                100.599,
                100.616,
                100.632,
                100.649,
                100.666,
                100.682,
                100.699,
                100.715,
                100.732,
                100.75,
                100.765,
                100.783,
                100.799,
                100.816,
                100.832,
                100.849,
                100.866,
                100.882,
                100.899,
                100.915,
                100.932,
                100.948,
                100.965,
                100.982,
                100.998,
                101.015,
                101.031,
                101.048,
                101.064,
                101.081,
                101.099,
                101.114,
                101.131,
                101.147,
                101.164,
                101.18,
                101.197,
                101.215,
                101.23,
                101.248,
                101.263,
                101.281,
                101.296,
                101.313,
                101.331,
                101.346,
                101.364,
                101.379,
                101.397,
                101.414,
                101.43,
                101.447,
                101.463,
                101.48,
                101.495,
                101.513,
                101.53,
                101.546,
                101.563,
                101.578,
                101.596,
                101.613,
                101.628,
                101.646,
                101.661,
                101.679,
                101.694,
                101.711,
                101.729,
                101.744,
                101.762,
                101.777,
                101.794,
                101.81,
                101.827,
                101.844,
                101.86,
                101.877,
                101.893,
                101.91,
                101.927,
                101.943,
                101.96,
                101.976,
                101.993,
                102.009,
                102.026,
                102.043,
                102.059,
                102.076,
                102.092,
                102.109,
                102.126,
                102.142,
                102.159,
                102.174,
                102.192,
                102.209,
                102.224,
                102.241,
                102.257,
                102.274,
                102.29,
                102.307,
                102.324,
                102.34,
                102.357,
                102.373,
                102.39,
                102.407,
                102.423,
                102.44,
                102.455,
                102.473,
                102.49,
                102.505,
                102.522,
                102.538,
                102.555,
                102.571,
                102.588,
                102.605,
                102.621,
                102.638,
                102.654,
                102.671,
                102.688,
                102.703,
                102.72,
                102.736,
                102.753,
                102.77,
                102.786,
                102.803,
                102.819,
                102.836,
                102.853,
                102.869,
                102.886,
                102.901,
                102.918,
                102.934,
                102.951,
                102.968,
                102.984,
                103.001,
                103.017,
                103.034,
                103.051,
                103.066,
                103.083,
                103.099,
                103.116,
                103.133,
                103.149,
                103.166,
                103.181,
                103.198,
                103.216,
                103.231,
                103.248,
                103.264,
                103.281,
                103.298,
                103.314,
                103.331,
                103.346,
                103.363,
                103.38,
                103.396,
                103.413,
                103.429,
                103.446,
                103.463,
                103.478,
                103.495,
                103.511,
                103.528,
                103.545,
                103.561,
                103.578,
                103.593,
                103.61,
                103.627,
                103.643,
                103.66,
                103.675,
                103.692,
                103.709,
                103.725,
                103.742,
                103.758,
                103.775,
                103.792,
                103.807,
                103.824,
                103.84,
                103.857,
                103.874,
                103.889,
                103.906,
                103.922,
                103.939,
                103.956,
                103.972,
                103.988,
                104.004,
                104.021,
                104.038,
                104.054,
                104.07,
                104.086,
                104.103,
                104.12,
                104.136,
                104.153,
                104.168,
                104.185,
                104.202,
                104.218,
                104.234,
                104.25,
                104.267,
                104.284,
                104.299,
                104.316,
                104.332,
                104.349,
                104.366,
                104.381,
                104.398,
                104.415,
                104.431,
                104.448,
                104.463,
                104.48,
                104.497,
                104.513
            ],
            P15: [
                47.922,
                48.096,
                48.27,
                48.445,
                48.619,
                48.794,
                48.968,
                49.143,
                49.317,
                49.492,
                49.666,
                49.841,
                50.016,
                50.19,
                50.365,
                50.515,
                50.663,
                50.811,
                50.958,
                51.104,
                51.249,
                51.393,
                51.537,
                51.679,
                51.82,
                51.961,
                52.1,
                52.238,
                52.376,
                52.512,
                52.648,
                52.783,
                52.916,
                53.049,
                53.18,
                53.311,
                53.44,
                53.568,
                53.695,
                53.822,
                53.947,
                54.071,
                54.195,
                54.318,
                54.439,
                54.559,
                54.679,
                54.797,
                54.915,
                55.032,
                55.148,
                55.263,
                55.376,
                55.49,
                55.602,
                55.713,
                55.824,
                55.934,
                56.043,
                56.15,
                56.258,
                56.365,
                56.471,
                56.577,
                56.682,
                56.787,
                56.891,
                56.994,
                57.097,
                57.198,
                57.299,
                57.4,
                57.5,
                57.6,
                57.698,
                57.797,
                57.894,
                57.991,
                58.087,
                58.183,
                58.278,
                58.373,
                58.466,
                58.559,
                58.652,
                58.744,
                58.836,
                58.926,
                59.016,
                59.106,
                59.195,
                59.283,
                59.371,
                59.458,
                59.544,
                59.631,
                59.716,
                59.801,
                59.885,
                59.969,
                60.052,
                60.135,
                60.217,
                60.299,
                60.379,
                60.46,
                60.54,
                60.619,
                60.698,
                60.776,
                60.854,
                60.931,
                61.008,
                61.084,
                61.16,
                61.235,
                61.31,
                61.384,
                61.457,
                61.53,
                61.603,
                61.675,
                61.747,
                61.818,
                61.889,
                61.959,
                62.029,
                62.098,
                62.167,
                62.236,
                62.304,
                62.372,
                62.438,
                62.505,
                62.572,
                62.638,
                62.704,
                62.768,
                62.833,
                62.898,
                62.962,
                63.025,
                63.089,
                63.152,
                63.214,
                63.277,
                63.338,
                63.4,
                63.462,
                63.522,
                63.583,
                63.643,
                63.703,
                63.762,
                63.822,
                63.881,
                63.94,
                63.998,
                64.056,
                64.114,
                64.171,
                64.229,
                64.285,
                64.342,
                64.399,
                64.455,
                64.51,
                64.566,
                64.622,
                64.676,
                64.731,
                64.786,
                64.841,
                64.895,
                64.948,
                65.002,
                65.056,
                65.109,
                65.162,
                65.215,
                65.268,
                65.32,
                65.373,
                65.425,
                65.476,
                65.528,
                65.58,
                65.631,
                65.682,
                65.733,
                65.784,
                65.835,
                65.885,
                65.936,
                65.986,
                66.036,
                66.086,
                66.135,
                66.185,
                66.234,
                66.283,
                66.333,
                66.382,
                66.43,
                66.479,
                66.528,
                66.576,
                66.624,
                66.672,
                66.72,
                66.768,
                66.816,
                66.864,
                66.911,
                66.958,
                67.006,
                67.053,
                67.1,
                67.147,
                67.194,
                67.241,
                67.288,
                67.334,
                67.381,
                67.427,
                67.473,
                67.519,
                67.566,
                67.611,
                67.657,
                67.703,
                67.749,
                67.794,
                67.84,
                67.885,
                67.931,
                67.976,
                68.022,
                68.067,
                68.111,
                68.157,
                68.201,
                68.246,
                68.291,
                68.336,
                68.381,
                68.425,
                68.469,
                68.514,
                68.559,
                68.603,
                68.647,
                68.691,
                68.735,
                68.779,
                68.823,
                68.867,
                68.911,
                68.955,
                68.998,
                69.042,
                69.086,
                69.129,
                69.172,
                69.216,
                69.26,
                69.303,
                69.346,
                69.389,
                69.432,
                69.475,
                69.517,
                69.56,
                69.604,
                69.646,
                69.689,
                69.731,
                69.774,
                69.816,
                69.859,
                69.901,
                69.943,
                69.985,
                70.028,
                70.07,
                70.112,
                70.154,
                70.196,
                70.237,
                70.279,
                70.321,
                70.363,
                70.404,
                70.446,
                70.487,
                70.528,
                70.57,
                70.611,
                70.652,
                70.693,
                70.734,
                70.775,
                70.816,
                70.857,
                70.898,
                70.938,
                70.979,
                71.019,
                71.06,
                71.1,
                71.141,
                71.181,
                71.222,
                71.262,
                71.302,
                71.342,
                71.382,
                71.422,
                71.462,
                71.502,
                71.542,
                71.582,
                71.621,
                71.661,
                71.7,
                71.74,
                71.779,
                71.819,
                71.858,
                71.897,
                71.936,
                71.976,
                72.015,
                72.053,
                72.093,
                72.132,
                72.17,
                72.209,
                72.248,
                72.287,
                72.326,
                72.364,
                72.403,
                72.441,
                72.48,
                72.518,
                72.557,
                72.595,
                72.633,
                72.672,
                72.709,
                72.748,
                72.785,
                72.824,
                72.862,
                72.899,
                72.938,
                72.975,
                73.013,
                73.05,
                73.089,
                73.126,
                73.164,
                73.202,
                73.239,
                73.277,
                73.314,
                73.351,
                73.388,
                73.426,
                73.463,
                73.5,
                73.537,
                73.575,
                73.611,
                73.649,
                73.685,
                73.723,
                73.759,
                73.797,
                73.833,
                73.869,
                73.906,
                73.943,
                73.98,
                74.016,
                74.053,
                74.089,
                74.126,
                74.162,
                74.198,
                74.235,
                74.271,
                74.307,
                74.343,
                74.379,
                74.416,
                74.451,
                74.488,
                74.523,
                74.559,
                74.595,
                74.631,
                74.666,
                74.703,
                74.738,
                74.774,
                74.81,
                74.845,
                74.881,
                74.916,
                74.951,
                74.987,
                75.022,
                75.057,
                75.093,
                75.128,
                75.163,
                75.198,
                75.233,
                75.268,
                75.304,
                75.338,
                75.373,
                75.409,
                75.443,
                75.478,
                75.512,
                75.548,
                75.582,
                75.616,
                75.652,
                75.686,
                75.72,
                75.755,
                75.79,
                75.824,
                75.858,
                75.892,
                75.927,
                75.961,
                75.995,
                76.029,
                76.064,
                76.098,
                76.132,
                76.166,
                76.2,
                76.234,
                76.268,
                76.302,
                76.336,
                76.37,
                76.403,
                76.437,
                76.47,
                76.505,
                76.538,
                76.572,
                76.605,
                76.639,
                76.673,
                76.706,
                76.739,
                76.773,
                76.806,
                76.84,
                76.873,
                76.906,
                76.939,
                76.973,
                77.006,
                77.039,
                77.072,
                77.105,
                77.138,
                77.171,
                77.204,
                77.238,
                77.271,
                77.303,
                77.336,
                77.369,
                77.401,
                77.434,
                77.468,
                77.5,
                77.533,
                77.565,
                77.598,
                77.63,
                77.663,
                77.696,
                77.728,
                77.761,
                77.793,
                77.825,
                77.858,
                77.89,
                77.922,
                77.954,
                77.987,
                78.019,
                78.052,
                78.084,
                78.116,
                78.148,
                78.18,
                78.212,
                78.244,
                78.275,
                78.307,
                78.339,
                78.372,
                78.403,
                78.435,
                78.467,
                78.499,
                78.53,
                78.562,
                78.593,
                78.625,
                78.657,
                78.688,
                78.72,
                78.751,
                78.782,
                78.814,
                78.845,
                78.876,
                78.909,
                78.94,
                78.971,
                79.002,
                79.034,
                79.065,
                79.096,
                79.127,
                79.158,
                79.189,
                79.22,
                79.251,
                79.282,
                79.313,
                79.344,
                79.375,
                79.406,
                79.436,
                79.467,
                79.498,
                79.529,
                79.559,
                79.59,
                79.621,
                79.651,
                79.682,
                79.712,
                79.743,
                79.773,
                79.804,
                79.834,
                79.865,
                79.895,
                79.925,
                79.956,
                79.986,
                80.016,
                80.047,
                80.077,
                80.107,
                80.137,
                80.167,
                80.197,
                80.227,
                80.257,
                80.287,
                80.317,
                80.347,
                80.376,
                80.406,
                80.436,
                80.466,
                80.496,
                80.526,
                80.556,
                80.585,
                80.615,
                80.645,
                80.674,
                80.704,
                80.734,
                80.763,
                80.793,
                80.822,
                80.852,
                80.881,
                80.91,
                80.939,
                80.969,
                80.998,
                81.028,
                81.057,
                81.086,
                81.115,
                81.145,
                81.174,
                81.203,
                81.232,
                81.262,
                81.291,
                81.319,
                81.348,
                81.377,
                81.406,
                81.435,
                81.464,
                81.493,
                81.522,
                81.551,
                81.58,
                81.609,
                81.637,
                81.666,
                81.695,
                81.723,
                81.752,
                81.781,
                81.81,
                81.838,
                81.867,
                81.896,
                81.923,
                81.952,
                81.98,
                82.009,
                82.037,
                82.066,
                82.094,
                82.123,
                82.151,
                82.179,
                82.207,
                82.236,
                82.264,
                82.292,
                82.32,
                82.349,
                82.377,
                82.405,
                82.432,
                82.461,
                82.489,
                82.517,
                82.545,
                82.573,
                82.601,
                82.629,
                82.656,
                82.684,
                82.712,
                82.74,
                82.768,
                82.796,
                82.824,
                82.851,
                82.879,
                82.906,
                82.934,
                82.962,
                82.99,
                83.017,
                83.045,
                83.072,
                83.099,
                83.127,
                83.155,
                83.182,
                83.21,
                83.237,
                83.264,
                83.292,
                83.319,
                83.347,
                83.374,
                83.401,
                83.429,
                83.455,
                83.483,
                83.51,
                83.537,
                83.565,
                83.592,
                83.619,
                83.646,
                83.673,
                83.7,
                83.727,
                83.754,
                83.782,
                83.809,
                83.835,
                83.862,
                83.889,
                83.916,
                83.943,
                83.97,
                83.997,
                84.023,
                84.05,
                84.077,
                84.104,
                84.131,
                84.158,
                84.185,
                84.211,
                84.238,
                84.264,
                84.291,
                84.318,
                84.345,
                84.371,
                84.397,
                84.424,
                84.451,
                84.477,
                84.504,
                84.531,
                84.557,
                84.583,
                84.609,
                84.636,
                83.962,
                83.989,
                84.015,
                84.042,
                84.067,
                84.094,
                84.12,
                84.147,
                84.173,
                84.199,
                84.225,
                84.251,
                84.277,
                84.303,
                84.33,
                84.356,
                84.382,
                84.408,
                84.434,
                84.459,
                84.485,
                84.512,
                84.538,
                84.564,
                84.59,
                84.616,
                84.641,
                84.667,
                84.692,
                84.718,
                84.744,
                84.77,
                84.796,
                84.822,
                84.846,
                84.872,
                84.898,
                84.924,
                84.949,
                84.975,
                85.001,
                85.025,
                85.051,
                85.076,
                85.102,
                85.127,
                85.153,
                85.178,
                85.204,
                85.228,
                85.254,
                85.279,
                85.304,
                85.33,
                85.355,
                85.38,
                85.405,
                85.431,
                85.455,
                85.48,
                85.505,
                85.53,
                85.556,
                85.581,
                85.606,
                85.631,
                85.656,
                85.68,
                85.705,
                85.73,
                85.755,
                85.78,
                85.804,
                85.829,
                85.854,
                85.879,
                85.904,
                85.928,
                85.952,
                85.977,
                86.002,
                86.027,
                86.051,
                86.076,
                86.101,
                86.125,
                86.15,
                86.174,
                86.198,
                86.222,
                86.247,
                86.271,
                86.296,
                86.32,
                86.345,
                86.369,
                86.393,
                86.418,
                86.442,
                86.466,
                86.49,
                86.514,
                86.538,
                86.562,
                86.586,
                86.611,
                86.635,
                86.659,
                86.683,
                86.707,
                86.731,
                86.755,
                86.779,
                86.803,
                86.827,
                86.85,
                86.874,
                86.898,
                86.922,
                86.945,
                86.969,
                86.993,
                87.017,
                87.041,
                87.064,
                87.088,
                87.112,
                87.135,
                87.159,
                87.183,
                87.206,
                87.23,
                87.253,
                87.277,
                87.3,
                87.324,
                87.347,
                87.37,
                87.393,
                87.416,
                87.44,
                87.463,
                87.486,
                87.51,
                87.533,
                87.556,
                87.579,
                87.603,
                87.626,
                87.649,
                87.672,
                87.695,
                87.718,
                87.741,
                87.764,
                87.787,
                87.81,
                87.833,
                87.856,
                87.879,
                87.902,
                87.925,
                87.948,
                87.971,
                87.993,
                88.016,
                88.039,
                88.062,
                88.085,
                88.107,
                88.13,
                88.153,
                88.175,
                88.198,
                88.22,
                88.243,
                88.266,
                88.288,
                88.311,
                88.333,
                88.356,
                88.378,
                88.401,
                88.423,
                88.445,
                88.468,
                88.49,
                88.512,
                88.535,
                88.557,
                88.58,
                88.602,
                88.625,
                88.647,
                88.669,
                88.691,
                88.713,
                88.736,
                88.758,
                88.78,
                88.802,
                88.824,
                88.846,
                88.868,
                88.89,
                88.912,
                88.934,
                88.956,
                88.978,
                89,
                89.022,
                89.044,
                89.066,
                89.088,
                89.11,
                89.132,
                89.153,
                89.175,
                89.197,
                89.218,
                89.24,
                89.262,
                89.283,
                89.305,
                89.328,
                89.349,
                89.371,
                89.392,
                89.414,
                89.435,
                89.457,
                89.478,
                89.5,
                89.521,
                89.543,
                89.565,
                89.586,
                89.608,
                89.629,
                89.65,
                89.672,
                89.693,
                89.714,
                89.736,
                89.757,
                89.779,
                89.8,
                89.821,
                89.842,
                89.864,
                89.885,
                89.906,
                89.927,
                89.949,
                89.97,
                89.991,
                90.012,
                90.033,
                90.054,
                90.075,
                90.096,
                90.118,
                90.139,
                90.16,
                90.181,
                90.202,
                90.223,
                90.244,
                90.265,
                90.286,
                90.307,
                90.328,
                90.348,
                90.369,
                90.39,
                90.412,
                90.433,
                90.453,
                90.474,
                90.495,
                90.515,
                90.537,
                90.558,
                90.578,
                90.599,
                90.619,
                90.64,
                90.661,
                90.682,
                90.703,
                90.723,
                90.744,
                90.765,
                90.786,
                90.806,
                90.827,
                90.847,
                90.868,
                90.889,
                90.909,
                90.93,
                90.95,
                90.971,
                90.992,
                91.012,
                91.033,
                91.053,
                91.073,
                91.095,
                91.115,
                91.135,
                91.155,
                91.176,
                91.197,
                91.217,
                91.237,
                91.258,
                91.279,
                91.299,
                91.319,
                91.339,
                91.359,
                91.38,
                91.401,
                91.421,
                91.441,
                91.462,
                91.482,
                91.502,
                91.522,
                91.542,
                91.563,
                91.583,
                91.603,
                91.623,
                91.644,
                91.664,
                91.684,
                91.704,
                91.725,
                91.745,
                91.765,
                91.785,
                91.806,
                91.826,
                91.845,
                91.865,
                91.886,
                91.906,
                91.926,
                91.946,
                91.967,
                91.986,
                92.006,
                92.027,
                92.047,
                92.066,
                92.086,
                92.107,
                92.127,
                92.146,
                92.166,
                92.187,
                92.206,
                92.226,
                92.247,
                92.266,
                92.286,
                92.306,
                92.326,
                92.346,
                92.365,
                92.386,
                92.406,
                92.425,
                92.445,
                92.465,
                92.485,
                92.504,
                92.525,
                92.544,
                92.564,
                92.584,
                92.604,
                92.623,
                92.643,
                92.663,
                92.683,
                92.702,
                92.723,
                92.742,
                92.761,
                92.782,
                92.801,
                92.821,
                92.841,
                92.86,
                92.88,
                92.9,
                92.919,
                92.939,
                92.959,
                92.978,
                92.997,
                93.018,
                93.037,
                93.056,
                93.077,
                93.096,
                93.115,
                93.135,
                93.154,
                93.174,
                93.194,
                93.213,
                93.232,
                93.252,
                93.271,
                93.291,
                93.311,
                93.33,
                93.349,
                93.369,
                93.388,
                93.407,
                93.427,
                93.446,
                93.465,
                93.486,
                93.505,
                93.525,
                93.544,
                93.563,
                93.583,
                93.602,
                93.621,
                93.641,
                93.66,
                93.679,
                93.699,
                93.717,
                93.737,
                93.756,
                93.775,
                93.795,
                93.814,
                93.833,
                93.853,
                93.872,
                93.892,
                93.91,
                93.929,
                93.949,
                93.968,
                93.988,
                94.006,
                94.025,
                94.045,
                94.064,
                94.084,
                94.102,
                94.121,
                94.141,
                94.16,
                94.178,
                94.198,
                94.217,
                94.236,
                94.255,
                94.274,
                94.293,
                94.312,
                94.332,
                94.35,
                94.37,
                94.389,
                94.407,
                94.427,
                94.445,
                94.465,
                94.484,
                94.502,
                94.522,
                94.54,
                94.56,
                94.578,
                94.597,
                94.616,
                94.635,
                94.654,
                94.673,
                94.692,
                94.711,
                94.729,
                94.749,
                94.767,
                94.786,
                94.805,
                94.824,
                94.843,
                94.861,
                94.88,
                94.899,
                94.918,
                94.936,
                94.956,
                94.974,
                94.992,
                95.012,
                95.03,
                95.049,
                95.068,
                95.087,
                95.105,
                95.124,
                95.142,
                95.161,
                95.18,
                95.198,
                95.217,
                95.235,
                95.255,
                95.273,
                95.292,
                95.31,
                95.329,
                95.347,
                95.366,
                95.385,
                95.403,
                95.422,
                95.44,
                95.459,
                95.477,
                95.496,
                95.514,
                95.533,
                95.551,
                95.569,
                95.588,
                95.606,
                95.625,
                95.643,
                95.662,
                95.68,
                95.699,
                95.717,
                95.736,
                95.754,
                95.773,
                95.791,
                95.81,
                95.827,
                95.846,
                95.864,
                95.882,
                95.901,
                95.919,
                95.938,
                95.955,
                95.974,
                95.992,
                96.011,
                96.029,
                96.047,
                96.065,
                96.084,
                96.102,
                96.12,
                96.138,
                96.157,
                96.174,
                96.193,
                96.211,
                96.23,
                96.247,
                96.266,
                96.284,
                96.302,
                96.32,
                96.339,
                96.356,
                96.375,
                96.392,
                96.41,
                96.429,
                96.446,
                96.465,
                96.482,
                96.501,
                96.518,
                96.537,
                96.555,
                96.573,
                96.591,
                96.609,
                96.627,
                96.645,
                96.663,
                96.681,
                96.699,
                96.717,
                96.734,
                96.753,
                96.77,
                96.789,
                96.806,
                96.825,
                96.842,
                96.86,
                96.878,
                96.896,
                96.913,
                96.932,
                96.95,
                96.968,
                96.986,
                97.003,
                97.022,
                97.039,
                97.057,
                97.074,
                97.093,
                97.11,
                97.128,
                97.146,
                97.164,
                97.181,
                97.199,
                97.217,
                97.235,
                97.252,
                97.27,
                97.288,
                97.306,
                97.323,
                97.341,
                97.358,
                97.376,
                97.394,
                97.412,
                97.429,
                97.447,
                97.464,
                97.482,
                97.499,
                97.518,
                97.536,
                97.553,
                97.571,
                97.588,
                97.606,
                97.623,
                97.641,
                97.658,
                97.676,
                97.693,
                97.711,
                97.728,
                97.747,
                97.764,
                97.782,
                97.799,
                97.817,
                97.834,
                97.852,
                97.869,
                97.887,
                97.905,
                97.922,
                97.94,
                97.956,
                97.974,
                97.991,
                98.009,
                98.026,
                98.044,
                98.061,
                98.079,
                98.096,
                98.114,
                98.131,
                98.149,
                98.167,
                98.184,
                98.201,
                98.218,
                98.236,
                98.253,
                98.271,
                98.288,
                98.306,
                98.322,
                98.34,
                98.357,
                98.375,
                98.392,
                98.41,
                98.427,
                98.444,
                98.462,
                98.479,
                98.497,
                98.513,
                98.531,
                98.548,
                98.566,
                98.583,
                98.6,
                98.617,
                98.635,
                98.653,
                98.67,
                98.687,
                98.704,
                98.722,
                98.738,
                98.756,
                98.773,
                98.791,
                98.807,
                98.825,
                98.843,
                98.86,
                98.877,
                98.894,
                98.912,
                98.928,
                98.946,
                98.963,
                98.98,
                98.997,
                99.015,
                99.032,
                99.049,
                99.067,
                99.083,
                99.101,
                99.118,
                99.135,
                99.152,
                99.17,
                99.186,
                99.204,
                99.222,
                99.238,
                99.256,
                99.273,
                99.29,
                99.307,
                99.324,
                99.341,
                99.359,
                99.376,
                99.393,
                99.41,
                99.427,
                99.445,
                99.461,
                99.479,
                99.495,
                99.513,
                99.531,
                99.547,
                99.565,
                99.581,
                99.599,
                99.615,
                99.633,
                99.649,
                99.667,
                99.684,
                99.701,
                99.719,
                99.735,
                99.753,
                99.769,
                99.787,
                99.803,
                99.821,
                99.838,
                99.855,
                99.873,
                99.889,
                99.907,
                99.923,
                99.941,
                99.957,
                99.975,
                99.991,
                100.009,
                100.026,
                100.043,
                100.06,
                100.077,
                100.094,
                100.111,
                100.128,
                100.146,
                100.162,
                100.18,
                100.196,
                100.214,
                100.23,
                100.248,
                100.264,
                100.282,
                100.299,
                100.316,
                100.333,
                100.35,
                100.367,
                100.383,
                100.401,
                100.417,
                100.435,
                100.452,
                100.469,
                100.486,
                100.503,
                100.52,
                100.537,
                100.554,
                100.572,
                100.588,
                100.606,
                100.622,
                100.639,
                100.656,
                100.673,
                100.69,
                100.707,
                100.725,
                100.741,
                100.758,
                100.775,
                100.792,
                100.809,
                100.826,
                100.844,
                100.86,
                100.877,
                100.894,
                100.911,
                100.928,
                100.945,
                100.962,
                100.979,
                100.996,
                101.013,
                101.03,
                101.047,
                101.064,
                101.08,
                101.098,
                101.115,
                101.132,
                101.149,
                101.166,
                101.183,
                101.199,
                101.217,
                101.234,
                101.251,
                101.268,
                101.284,
                101.302,
                101.318,
                101.335,
                101.353,
                101.369,
                101.387,
                101.403,
                101.42,
                101.437,
                101.454,
                101.472,
                101.488,
                101.505,
                101.522,
                101.539,
                101.555,
                101.573,
                101.589,
                101.606,
                101.624,
                101.64,
                101.658,
                101.674,
                101.691,
                101.708,
                101.725,
                101.742,
                101.759,
                101.776,
                101.792,
                101.81,
                101.827,
                101.843,
                101.861,
                101.877,
                101.894,
                101.911,
                101.928,
                101.946,
                101.962,
                101.979,
                101.995,
                102.013,
                102.029,
                102.047,
                102.064,
                102.08,
                102.098,
                102.114,
                102.131,
                102.147,
                102.165,
                102.182,
                102.198,
                102.216,
                102.232,
                102.249,
                102.266,
                102.283,
                102.3,
                102.317,
                102.334,
                102.35,
                102.368,
                102.384,
                102.401,
                102.419,
                102.435,
                102.452,
                102.468,
                102.486,
                102.503,
                102.519,
                102.537,
                102.553,
                102.57,
                102.586,
                102.604,
                102.621,
                102.637,
                102.655,
                102.671,
                102.688,
                102.706,
                102.722,
                102.739,
                102.755,
                102.773,
                102.789,
                102.806,
                102.824,
                102.84,
                102.857,
                102.873,
                102.891,
                102.907,
                102.924,
                102.941,
                102.958,
                102.975,
                102.991,
                103.008,
                103.026,
                103.042,
                103.059,
                103.075,
                103.093,
                103.109,
                103.126,
                103.144,
                103.16,
                103.177,
                103.193,
                103.211,
                103.228,
                103.244,
                103.261,
                103.277,
                103.295,
                103.312,
                103.328,
                103.346,
                103.362,
                103.379,
                103.395,
                103.412,
                103.43,
                103.446,
                103.463,
                103.479,
                103.497,
                103.514,
                103.53,
                103.547,
                103.564,
                103.581,
                103.598,
                103.614,
                103.631,
                103.648,
                103.665,
                103.681,
                103.698,
                103.716,
                103.732,
                103.749,
                103.765,
                103.782,
                103.8,
                103.816,
                103.833,
                103.849,
                103.866,
                103.884,
                103.9,
                103.917,
                103.933,
                103.951,
                103.968,
                103.984,
                104.001,
                104.017,
                104.034,
                104.051,
                104.068,
                104.085,
                104.101,
                104.119,
                104.135,
                104.152,
                104.169,
                104.185,
                104.202,
                104.219,
                104.236,
                104.253,
                104.269,
                104.286,
                104.302,
                104.32,
                104.337,
                104.353,
                104.37,
                104.386,
                104.404,
                104.421,
                104.437,
                104.454,
                104.47,
                104.487,
                104.505,
                104.521,
                104.538,
                104.554,
                104.571,
                104.588,
                104.605,
                104.622,
                104.638,
                104.655,
                104.672,
                104.688,
                104.705,
                104.722,
                104.739,
                104.756,
                104.772,
                104.789,
                104.805,
                104.822,
                104.84,
                104.856,
                104.873,
                104.889,
                104.906,
                104.923,
                104.939,
                104.956,
                104.972,
                104.99,
                105.007,
                105.023,
                105.04,
                105.056,
                105.073,
                105.09,
                105.106,
                105.124,
                105.14,
                105.157,
                105.174,
                105.19,
                105.207,
                105.223,
                105.24,
                105.257,
                105.273,
                105.291,
                105.307,
                105.324,
                105.341,
                105.357,
                105.374,
                105.39,
                105.407,
                105.424,
                105.44,
                105.457,
                105.473,
                105.491,
                105.508,
                105.524,
                105.541,
                105.558,
                105.574,
                105.591,
                105.607,
                105.624,
                105.641,
                105.657
            ],
            P25: [
                48.607,
                48.782,
                48.957,
                49.132,
                49.307,
                49.482,
                49.656,
                49.832,
                50.006,
                50.181,
                50.356,
                50.532,
                50.707,
                50.882,
                51.057,
                51.207,
                51.357,
                51.505,
                51.653,
                51.8,
                51.946,
                52.091,
                52.235,
                52.378,
                52.52,
                52.661,
                52.801,
                52.94,
                53.078,
                53.216,
                53.352,
                53.488,
                53.622,
                53.755,
                53.887,
                54.018,
                54.148,
                54.278,
                54.405,
                54.533,
                54.659,
                54.784,
                54.908,
                55.031,
                55.153,
                55.274,
                55.394,
                55.513,
                55.631,
                55.749,
                55.865,
                55.981,
                56.095,
                56.209,
                56.322,
                56.434,
                56.545,
                56.655,
                56.765,
                56.873,
                56.982,
                57.089,
                57.196,
                57.302,
                57.408,
                57.513,
                57.617,
                57.721,
                57.824,
                57.927,
                58.028,
                58.13,
                58.23,
                58.33,
                58.43,
                58.529,
                58.627,
                58.724,
                58.821,
                58.917,
                59.012,
                59.107,
                59.202,
                59.295,
                59.388,
                59.481,
                59.573,
                59.664,
                59.754,
                59.844,
                59.934,
                60.023,
                60.111,
                60.199,
                60.286,
                60.372,
                60.458,
                60.544,
                60.628,
                60.713,
                60.796,
                60.879,
                60.962,
                61.044,
                61.125,
                61.206,
                61.287,
                61.366,
                61.445,
                61.524,
                61.602,
                61.68,
                61.757,
                61.834,
                61.91,
                61.985,
                62.061,
                62.135,
                62.209,
                62.283,
                62.356,
                62.428,
                62.5,
                62.572,
                62.643,
                62.714,
                62.784,
                62.854,
                62.923,
                62.992,
                63.06,
                63.128,
                63.196,
                63.263,
                63.33,
                63.396,
                63.462,
                63.527,
                63.593,
                63.658,
                63.722,
                63.786,
                63.85,
                63.913,
                63.976,
                64.038,
                64.1,
                64.163,
                64.224,
                64.285,
                64.346,
                64.407,
                64.467,
                64.527,
                64.587,
                64.646,
                64.705,
                64.764,
                64.822,
                64.88,
                64.938,
                64.996,
                65.053,
                65.11,
                65.167,
                65.223,
                65.279,
                65.336,
                65.391,
                65.447,
                65.502,
                65.557,
                65.612,
                65.666,
                65.72,
                65.774,
                65.828,
                65.882,
                65.935,
                65.989,
                66.042,
                66.094,
                66.147,
                66.199,
                66.252,
                66.304,
                66.356,
                66.407,
                66.459,
                66.51,
                66.561,
                66.613,
                66.663,
                66.714,
                66.765,
                66.815,
                66.865,
                66.915,
                66.965,
                67.015,
                67.064,
                67.114,
                67.163,
                67.212,
                67.261,
                67.31,
                67.359,
                67.407,
                67.456,
                67.505,
                67.553,
                67.601,
                67.649,
                67.697,
                67.745,
                67.793,
                67.84,
                67.887,
                67.935,
                67.982,
                68.029,
                68.077,
                68.123,
                68.17,
                68.217,
                68.264,
                68.31,
                68.357,
                68.403,
                68.45,
                68.496,
                68.542,
                68.588,
                68.634,
                68.68,
                68.726,
                68.771,
                68.817,
                68.862,
                68.908,
                68.954,
                68.999,
                69.044,
                69.089,
                69.134,
                69.18,
                69.225,
                69.269,
                69.315,
                69.359,
                69.404,
                69.448,
                69.493,
                69.538,
                69.582,
                69.626,
                69.671,
                69.715,
                69.759,
                69.803,
                69.848,
                69.892,
                69.936,
                69.979,
                70.023,
                70.067,
                70.111,
                70.155,
                70.198,
                70.241,
                70.285,
                70.328,
                70.371,
                70.415,
                70.458,
                70.501,
                70.544,
                70.587,
                70.63,
                70.673,
                70.716,
                70.759,
                70.801,
                70.844,
                70.887,
                70.929,
                70.972,
                71.014,
                71.056,
                71.098,
                71.141,
                71.183,
                71.225,
                71.267,
                71.309,
                71.351,
                71.393,
                71.434,
                71.476,
                71.517,
                71.559,
                71.6,
                71.642,
                71.683,
                71.724,
                71.766,
                71.807,
                71.848,
                71.889,
                71.93,
                71.971,
                72.012,
                72.053,
                72.093,
                72.134,
                72.175,
                72.215,
                72.256,
                72.296,
                72.336,
                72.377,
                72.417,
                72.457,
                72.497,
                72.538,
                72.578,
                72.617,
                72.657,
                72.698,
                72.737,
                72.777,
                72.817,
                72.856,
                72.896,
                72.935,
                72.975,
                73.014,
                73.054,
                73.093,
                73.132,
                73.172,
                73.211,
                73.25,
                73.289,
                73.328,
                73.367,
                73.406,
                73.445,
                73.483,
                73.522,
                73.561,
                73.6,
                73.638,
                73.677,
                73.716,
                73.754,
                73.792,
                73.831,
                73.869,
                73.907,
                73.946,
                73.984,
                74.022,
                74.06,
                74.098,
                74.137,
                74.174,
                74.212,
                74.25,
                74.288,
                74.326,
                74.364,
                74.401,
                74.439,
                74.477,
                74.515,
                74.552,
                74.59,
                74.627,
                74.665,
                74.702,
                74.739,
                74.776,
                74.813,
                74.851,
                74.888,
                74.925,
                74.962,
                74.999,
                75.036,
                75.073,
                75.11,
                75.147,
                75.184,
                75.22,
                75.257,
                75.294,
                75.33,
                75.367,
                75.403,
                75.44,
                75.477,
                75.513,
                75.549,
                75.586,
                75.622,
                75.658,
                75.694,
                75.73,
                75.767,
                75.803,
                75.839,
                75.875,
                75.911,
                75.946,
                75.983,
                76.018,
                76.054,
                76.09,
                76.126,
                76.161,
                76.197,
                76.232,
                76.268,
                76.304,
                76.339,
                76.374,
                76.41,
                76.445,
                76.48,
                76.516,
                76.551,
                76.586,
                76.621,
                76.656,
                76.692,
                76.727,
                76.761,
                76.796,
                76.832,
                76.866,
                76.901,
                76.936,
                76.971,
                77.006,
                77.04,
                77.075,
                77.11,
                77.144,
                77.179,
                77.213,
                77.248,
                77.282,
                77.317,
                77.351,
                77.385,
                77.42,
                77.454,
                77.489,
                77.523,
                77.557,
                77.591,
                77.625,
                77.659,
                77.693,
                77.727,
                77.762,
                77.796,
                77.829,
                77.863,
                77.897,
                77.931,
                77.965,
                77.999,
                78.032,
                78.066,
                78.1,
                78.133,
                78.167,
                78.201,
                78.234,
                78.268,
                78.301,
                78.334,
                78.368,
                78.402,
                78.435,
                78.468,
                78.501,
                78.535,
                78.568,
                78.601,
                78.635,
                78.668,
                78.701,
                78.734,
                78.767,
                78.8,
                78.833,
                78.866,
                78.898,
                78.932,
                78.965,
                78.997,
                79.03,
                79.063,
                79.096,
                79.128,
                79.161,
                79.194,
                79.226,
                79.259,
                79.291,
                79.324,
                79.357,
                79.389,
                79.422,
                79.454,
                79.486,
                79.519,
                79.551,
                79.583,
                79.615,
                79.647,
                79.68,
                79.712,
                79.744,
                79.776,
                79.808,
                79.84,
                79.873,
                79.905,
                79.936,
                79.968,
                80,
                80.032,
                80.064,
                80.096,
                80.127,
                80.159,
                80.191,
                80.222,
                80.254,
                80.286,
                80.317,
                80.349,
                80.38,
                80.412,
                80.443,
                80.475,
                80.506,
                80.538,
                80.569,
                80.6,
                80.632,
                80.663,
                80.694,
                80.725,
                80.757,
                80.788,
                80.819,
                80.85,
                80.881,
                80.912,
                80.943,
                80.974,
                81.005,
                81.036,
                81.067,
                81.098,
                81.128,
                81.159,
                81.19,
                81.221,
                81.252,
                81.282,
                81.312,
                81.343,
                81.374,
                81.404,
                81.435,
                81.465,
                81.496,
                81.526,
                81.557,
                81.587,
                81.618,
                81.648,
                81.678,
                81.709,
                81.739,
                81.769,
                81.799,
                81.83,
                81.86,
                81.89,
                81.92,
                81.95,
                81.98,
                82.01,
                82.04,
                82.07,
                82.1,
                82.13,
                82.16,
                82.19,
                82.22,
                82.25,
                82.279,
                82.309,
                82.339,
                82.368,
                82.398,
                82.428,
                82.458,
                82.487,
                82.517,
                82.547,
                82.576,
                82.606,
                82.635,
                82.664,
                82.694,
                82.723,
                82.753,
                82.782,
                82.812,
                82.841,
                82.87,
                82.9,
                82.929,
                82.958,
                82.987,
                83.016,
                83.045,
                83.075,
                83.104,
                83.133,
                83.162,
                83.191,
                83.22,
                83.249,
                83.278,
                83.307,
                83.336,
                83.365,
                83.394,
                83.423,
                83.451,
                83.48,
                83.509,
                83.537,
                83.566,
                83.595,
                83.624,
                83.652,
                83.681,
                83.709,
                83.738,
                83.767,
                83.795,
                83.824,
                83.852,
                83.881,
                83.909,
                83.937,
                83.966,
                83.994,
                84.023,
                84.051,
                84.08,
                84.108,
                84.136,
                84.164,
                84.192,
                84.221,
                84.249,
                84.277,
                84.305,
                84.333,
                84.361,
                84.389,
                84.417,
                84.446,
                84.474,
                84.502,
                84.529,
                84.557,
                84.585,
                84.613,
                84.641,
                84.669,
                84.697,
                84.725,
                84.753,
                84.78,
                84.808,
                84.836,
                84.864,
                84.892,
                84.919,
                84.947,
                84.975,
                85.002,
                85.03,
                85.058,
                85.085,
                85.112,
                85.14,
                85.168,
                85.195,
                85.223,
                85.25,
                85.278,
                85.305,
                85.332,
                85.36,
                85.387,
                85.415,
                85.442,
                85.47,
                85.497,
                85.524,
                85.551,
                85.579,
                85.606,
                85.633,
                85.66,
                85.687,
                85.714,
                85.741,
                85.069,
                85.096,
                85.123,
                85.15,
                85.177,
                85.204,
                85.231,
                85.258,
                85.285,
                85.312,
                85.339,
                85.365,
                85.392,
                85.419,
                85.446,
                85.473,
                85.5,
                85.527,
                85.553,
                85.579,
                85.606,
                85.633,
                85.66,
                85.686,
                85.713,
                85.74,
                85.766,
                85.792,
                85.819,
                85.846,
                85.872,
                85.899,
                85.925,
                85.951,
                85.977,
                86.004,
                86.03,
                86.056,
                86.083,
                86.109,
                86.135,
                86.161,
                86.187,
                86.214,
                86.24,
                86.266,
                86.292,
                86.318,
                86.344,
                86.37,
                86.396,
                86.422,
                86.448,
                86.474,
                86.5,
                86.526,
                86.552,
                86.578,
                86.603,
                86.629,
                86.655,
                86.68,
                86.706,
                86.732,
                86.758,
                86.783,
                86.809,
                86.834,
                86.86,
                86.885,
                86.911,
                86.937,
                86.962,
                86.988,
                87.013,
                87.039,
                87.064,
                87.089,
                87.114,
                87.14,
                87.165,
                87.191,
                87.216,
                87.241,
                87.267,
                87.292,
                87.317,
                87.342,
                87.367,
                87.392,
                87.417,
                87.442,
                87.467,
                87.493,
                87.518,
                87.543,
                87.568,
                87.593,
                87.618,
                87.643,
                87.667,
                87.692,
                87.717,
                87.742,
                87.767,
                87.791,
                87.816,
                87.841,
                87.866,
                87.89,
                87.915,
                87.94,
                87.964,
                87.989,
                88.014,
                88.038,
                88.062,
                88.087,
                88.111,
                88.136,
                88.16,
                88.185,
                88.209,
                88.234,
                88.258,
                88.282,
                88.307,
                88.331,
                88.355,
                88.38,
                88.404,
                88.428,
                88.452,
                88.476,
                88.501,
                88.525,
                88.549,
                88.572,
                88.596,
                88.62,
                88.644,
                88.668,
                88.692,
                88.716,
                88.74,
                88.764,
                88.788,
                88.812,
                88.836,
                88.86,
                88.883,
                88.907,
                88.931,
                88.954,
                88.978,
                89.002,
                89.026,
                89.049,
                89.073,
                89.096,
                89.12,
                89.144,
                89.167,
                89.191,
                89.214,
                89.238,
                89.261,
                89.284,
                89.308,
                89.331,
                89.354,
                89.378,
                89.401,
                89.424,
                89.448,
                89.471,
                89.494,
                89.517,
                89.541,
                89.564,
                89.587,
                89.61,
                89.633,
                89.656,
                89.679,
                89.702,
                89.725,
                89.748,
                89.771,
                89.794,
                89.818,
                89.84,
                89.863,
                89.886,
                89.909,
                89.932,
                89.955,
                89.978,
                90,
                90.023,
                90.046,
                90.069,
                90.091,
                90.114,
                90.136,
                90.159,
                90.182,
                90.204,
                90.227,
                90.249,
                90.273,
                90.295,
                90.318,
                90.34,
                90.362,
                90.385,
                90.407,
                90.43,
                90.452,
                90.474,
                90.497,
                90.519,
                90.541,
                90.564,
                90.587,
                90.609,
                90.631,
                90.653,
                90.675,
                90.698,
                90.72,
                90.742,
                90.764,
                90.786,
                90.808,
                90.831,
                90.853,
                90.875,
                90.897,
                90.919,
                90.941,
                90.963,
                90.985,
                91.007,
                91.028,
                91.051,
                91.073,
                91.095,
                91.116,
                91.138,
                91.16,
                91.182,
                91.204,
                91.226,
                91.248,
                91.269,
                91.291,
                91.313,
                91.334,
                91.356,
                91.378,
                91.4,
                91.422,
                91.443,
                91.465,
                91.486,
                91.508,
                91.529,
                91.551,
                91.573,
                91.594,
                91.616,
                91.637,
                91.659,
                91.68,
                91.702,
                91.723,
                91.745,
                91.766,
                91.787,
                91.809,
                91.831,
                91.852,
                91.873,
                91.895,
                91.916,
                91.937,
                91.959,
                91.98,
                92.001,
                92.023,
                92.044,
                92.065,
                92.087,
                92.108,
                92.129,
                92.15,
                92.171,
                92.193,
                92.214,
                92.235,
                92.256,
                92.277,
                92.299,
                92.32,
                92.341,
                92.362,
                92.383,
                92.404,
                92.425,
                92.446,
                92.467,
                92.488,
                92.509,
                92.53,
                92.551,
                92.572,
                92.593,
                92.614,
                92.635,
                92.656,
                92.677,
                92.698,
                92.719,
                92.74,
                92.761,
                92.782,
                92.803,
                92.823,
                92.844,
                92.865,
                92.886,
                92.907,
                92.927,
                92.948,
                92.969,
                92.99,
                93.011,
                93.031,
                93.052,
                93.073,
                93.094,
                93.114,
                93.135,
                93.156,
                93.176,
                93.197,
                93.218,
                93.238,
                93.259,
                93.279,
                93.301,
                93.321,
                93.342,
                93.363,
                93.383,
                93.403,
                93.424,
                93.445,
                93.465,
                93.486,
                93.506,
                93.527,
                93.547,
                93.568,
                93.588,
                93.609,
                93.629,
                93.649,
                93.67,
                93.691,
                93.711,
                93.732,
                93.752,
                93.772,
                93.792,
                93.813,
                93.833,
                93.854,
                93.874,
                93.895,
                93.915,
                93.936,
                93.956,
                93.976,
                93.996,
                94.017,
                94.037,
                94.057,
                94.077,
                94.098,
                94.118,
                94.138,
                94.158,
                94.178,
                94.199,
                94.219,
                94.239,
                94.26,
                94.28,
                94.3,
                94.32,
                94.34,
                94.36,
                94.381,
                94.401,
                94.42,
                94.441,
                94.461,
                94.481,
                94.501,
                94.521,
                94.541,
                94.561,
                94.581,
                94.601,
                94.622,
                94.641,
                94.661,
                94.682,
                94.701,
                94.721,
                94.742,
                94.761,
                94.781,
                94.801,
                94.821,
                94.841,
                94.861,
                94.881,
                94.901,
                94.921,
                94.941,
                94.961,
                94.98,
                95,
                95.02,
                95.04,
                95.06,
                95.08,
                95.099,
                95.12,
                95.139,
                95.159,
                95.179,
                95.199,
                95.218,
                95.238,
                95.258,
                95.278,
                95.297,
                95.317,
                95.337,
                95.356,
                95.376,
                95.396,
                95.415,
                95.436,
                95.455,
                95.475,
                95.494,
                95.514,
                95.534,
                95.553,
                95.573,
                95.593,
                95.612,
                95.632,
                95.651,
                95.671,
                95.69,
                95.71,
                95.73,
                95.749,
                95.769,
                95.788,
                95.807,
                95.827,
                95.847,
                95.866,
                95.886,
                95.905,
                95.925,
                95.944,
                95.964,
                95.983,
                96.002,
                96.022,
                96.041,
                96.061,
                96.08,
                96.1,
                96.119,
                96.138,
                96.158,
                96.177,
                96.196,
                96.215,
                96.235,
                96.254,
                96.273,
                96.293,
                96.312,
                96.331,
                96.35,
                96.37,
                96.389,
                96.408,
                96.428,
                96.447,
                96.466,
                96.485,
                96.505,
                96.524,
                96.543,
                96.562,
                96.581,
                96.6,
                96.619,
                96.639,
                96.657,
                96.677,
                96.696,
                96.715,
                96.734,
                96.753,
                96.772,
                96.791,
                96.81,
                96.829,
                96.848,
                96.867,
                96.886,
                96.905,
                96.925,
                96.943,
                96.963,
                96.981,
                97,
                97.019,
                97.038,
                97.057,
                97.076,
                97.095,
                97.114,
                97.133,
                97.151,
                97.171,
                97.189,
                97.208,
                97.227,
                97.246,
                97.265,
                97.284,
                97.302,
                97.321,
                97.34,
                97.358,
                97.377,
                97.396,
                97.415,
                97.433,
                97.453,
                97.471,
                97.49,
                97.509,
                97.527,
                97.546,
                97.565,
                97.583,
                97.602,
                97.621,
                97.64,
                97.658,
                97.677,
                97.695,
                97.714,
                97.733,
                97.752,
                97.77,
                97.789,
                97.807,
                97.826,
                97.844,
                97.862,
                97.881,
                97.899,
                97.918,
                97.937,
                97.955,
                97.974,
                97.992,
                98.011,
                98.029,
                98.048,
                98.066,
                98.084,
                98.103,
                98.121,
                98.14,
                98.158,
                98.177,
                98.195,
                98.214,
                98.232,
                98.251,
                98.269,
                98.287,
                98.305,
                98.324,
                98.342,
                98.361,
                98.379,
                98.397,
                98.416,
                98.434,
                98.453,
                98.471,
                98.489,
                98.507,
                98.526,
                98.544,
                98.562,
                98.58,
                98.599,
                98.617,
                98.635,
                98.653,
                98.672,
                98.69,
                98.708,
                98.726,
                98.744,
                98.762,
                98.781,
                98.799,
                98.817,
                98.835,
                98.853,
                98.871,
                98.89,
                98.907,
                98.926,
                98.944,
                98.962,
                98.98,
                98.998,
                99.017,
                99.034,
                99.053,
                99.07,
                99.089,
                99.106,
                99.125,
                99.143,
                99.161,
                99.179,
                99.197,
                99.215,
                99.233,
                99.251,
                99.269,
                99.287,
                99.305,
                99.322,
                99.341,
                99.358,
                99.377,
                99.395,
                99.413,
                99.431,
                99.448,
                99.467,
                99.484,
                99.502,
                99.52,
                99.538,
                99.556,
                99.574,
                99.592,
                99.61,
                99.627,
                99.645,
                99.664,
                99.681,
                99.699,
                99.717,
                99.735,
                99.753,
                99.771,
                99.788,
                99.806,
                99.824,
                99.842,
                99.859,
                99.878,
                99.895,
                99.913,
                99.931,
                99.949,
                99.967,
                99.984,
                100.002,
                100.02,
                100.038,
                100.055,
                100.073,
                100.091,
                100.109,
                100.126,
                100.144,
                100.162,
                100.18,
                100.198,
                100.215,
                100.233,
                100.25,
                100.268,
                100.286,
                100.304,
                100.321,
                100.339,
                100.357,
                100.374,
                100.392,
                100.41,
                100.428,
                100.445,
                100.463,
                100.48,
                100.498,
                100.516,
                100.534,
                100.552,
                100.569,
                100.587,
                100.604,
                100.622,
                100.639,
                100.657,
                100.675,
                100.693,
                100.71,
                100.728,
                100.746,
                100.763,
                100.781,
                100.798,
                100.816,
                100.833,
                100.851,
                100.868,
                100.886,
                100.904,
                100.921,
                100.939,
                100.957,
                100.975,
                100.992,
                101.01,
                101.027,
                101.045,
                101.063,
                101.08,
                101.098,
                101.115,
                101.133,
                101.15,
                101.168,
                101.185,
                101.203,
                101.22,
                101.238,
                101.256,
                101.273,
                101.291,
                101.308,
                101.326,
                101.343,
                101.361,
                101.378,
                101.396,
                101.413,
                101.431,
                101.448,
                101.466,
                101.483,
                101.5,
                101.518,
                101.535,
                101.553,
                101.571,
                101.588,
                101.606,
                101.623,
                101.641,
                101.658,
                101.676,
                101.694,
                101.711,
                101.728,
                101.746,
                101.763,
                101.78,
                101.798,
                101.815,
                101.833,
                101.851,
                101.868,
                101.886,
                101.903,
                101.921,
                101.938,
                101.955,
                101.973,
                101.99,
                102.008,
                102.025,
                102.043,
                102.06,
                102.078,
                102.095,
                102.113,
                102.13,
                102.147,
                102.165,
                102.182,
                102.2,
                102.217,
                102.235,
                102.252,
                102.27,
                102.287,
                102.304,
                102.322,
                102.339,
                102.357,
                102.374,
                102.392,
                102.409,
                102.426,
                102.444,
                102.461,
                102.479,
                102.496,
                102.514,
                102.531,
                102.548,
                102.566,
                102.583,
                102.601,
                102.618,
                102.636,
                102.653,
                102.67,
                102.688,
                102.705,
                102.723,
                102.74,
                102.757,
                102.774,
                102.792,
                102.81,
                102.827,
                102.845,
                102.862,
                102.879,
                102.896,
                102.914,
                102.932,
                102.949,
                102.966,
                102.983,
                103.001,
                103.018,
                103.036,
                103.053,
                103.07,
                103.088,
                103.105,
                103.123,
                103.14,
                103.157,
                103.174,
                103.192,
                103.21,
                103.227,
                103.244,
                103.261,
                103.279,
                103.296,
                103.314,
                103.331,
                103.348,
                103.366,
                103.383,
                103.401,
                103.418,
                103.435,
                103.453,
                103.47,
                103.487,
                103.504,
                103.522,
                103.54,
                103.557,
                103.574,
                103.591,
                103.609,
                103.626,
                103.643,
                103.661,
                103.678,
                103.696,
                103.713,
                103.73,
                103.747,
                103.765,
                103.782,
                103.799,
                103.817,
                103.834,
                103.851,
                103.868,
                103.886,
                103.904,
                103.921,
                103.938,
                103.955,
                103.973,
                103.99,
                104.007,
                104.025,
                104.042,
                104.059,
                104.076,
                104.094,
                104.112,
                104.128,
                104.146,
                104.163,
                104.181,
                104.197,
                104.215,
                104.233,
                104.25,
                104.267,
                104.284,
                104.302,
                104.319,
                104.336,
                104.354,
                104.371,
                104.388,
                104.405,
                104.423,
                104.44,
                104.457,
                104.475,
                104.492,
                104.509,
                104.526,
                104.544,
                104.561,
                104.578,
                104.596,
                104.613,
                104.63,
                104.648,
                104.665,
                104.682,
                104.699,
                104.717,
                104.733,
                104.751,
                104.769,
                104.785,
                104.803,
                104.82,
                104.837,
                104.855,
                104.872,
                104.889,
                104.906,
                104.924,
                104.941,
                104.958,
                104.976,
                104.993,
                105.01,
                105.027,
                105.045,
                105.062,
                105.079,
                105.097,
                105.113,
                105.131,
                105.148,
                105.165,
                105.183,
                105.2,
                105.217,
                105.235,
                105.252,
                105.269,
                105.286,
                105.303,
                105.32,
                105.338,
                105.355,
                105.372,
                105.39,
                105.407,
                105.424,
                105.442,
                105.458,
                105.476,
                105.493,
                105.51,
                105.528,
                105.545,
                105.562,
                105.579,
                105.597,
                105.614,
                105.631,
                105.648,
                105.665,
                105.683,
                105.699,
                105.717,
                105.734,
                105.751,
                105.769,
                105.786,
                105.803,
                105.821,
                105.837,
                105.855,
                105.872,
                105.889,
                105.907,
                105.923,
                105.941,
                105.958,
                105.975,
                105.993,
                106.009,
                106.027,
                106.044,
                106.061,
                106.079,
                106.096,
                106.113,
                106.13,
                106.147,
                106.165,
                106.181,
                106.199,
                106.216,
                106.233,
                106.251,
                106.267,
                106.285,
                106.302,
                106.319,
                106.337,
                106.353,
                106.371,
                106.388,
                106.405,
                106.422,
                106.439,
                106.457,
                106.473,
                106.491,
                106.508,
                106.525,
                106.542,
                106.559,
                106.577,
                106.594,
                106.611,
                106.628,
                106.645,
                106.662,
                106.68,
                106.697,
                106.714,
                106.731,
                106.748,
                106.766,
                106.782,
                106.8,
                106.817,
                106.834,
                106.851,
                106.868,
                106.885,
                106.902,
                106.92,
                106.937,
                106.954,
                106.971,
                106.988,
                107.005,
                107.023,
                107.039,
                107.057,
                107.073,
                107.091,
                107.108,
                107.125,
                107.142,
                107.159,
                107.176,
                107.194,
                107.21,
                107.228,
                107.245,
                107.262,
                107.279,
                107.296,
                107.313,
                107.331,
                107.347
            ],
            P50: [
                49.884,
                50.06,
                50.236,
                50.412,
                50.588,
                50.764,
                50.939,
                51.115,
                51.291,
                51.467,
                51.643,
                51.819,
                51.994,
                52.17,
                52.346,
                52.498,
                52.649,
                52.799,
                52.948,
                53.097,
                53.244,
                53.391,
                53.536,
                53.681,
                53.824,
                53.966,
                54.108,
                54.249,
                54.388,
                54.527,
                54.665,
                54.801,
                54.937,
                55.071,
                55.205,
                55.337,
                55.469,
                55.599,
                55.729,
                55.857,
                55.984,
                56.11,
                56.236,
                56.36,
                56.483,
                56.606,
                56.727,
                56.847,
                56.967,
                57.085,
                57.203,
                57.319,
                57.435,
                57.55,
                57.664,
                57.777,
                57.889,
                58,
                58.111,
                58.221,
                58.33,
                58.438,
                58.546,
                58.654,
                58.76,
                58.866,
                58.972,
                59.077,
                59.181,
                59.284,
                59.387,
                59.489,
                59.591,
                59.692,
                59.792,
                59.892,
                59.991,
                60.089,
                60.187,
                60.284,
                60.381,
                60.477,
                60.572,
                60.667,
                60.761,
                60.854,
                60.947,
                61.039,
                61.13,
                61.221,
                61.312,
                61.401,
                61.49,
                61.579,
                61.667,
                61.754,
                61.841,
                61.927,
                62.013,
                62.098,
                62.183,
                62.267,
                62.35,
                62.433,
                62.515,
                62.597,
                62.678,
                62.758,
                62.838,
                62.918,
                62.997,
                63.075,
                63.153,
                63.231,
                63.308,
                63.384,
                63.46,
                63.535,
                63.61,
                63.684,
                63.758,
                63.831,
                63.904,
                63.977,
                64.048,
                64.12,
                64.191,
                64.261,
                64.331,
                64.401,
                64.47,
                64.538,
                64.607,
                64.674,
                64.742,
                64.809,
                64.876,
                64.942,
                65.007,
                65.073,
                65.138,
                65.203,
                65.267,
                65.331,
                65.395,
                65.458,
                65.521,
                65.583,
                65.646,
                65.708,
                65.769,
                65.83,
                65.891,
                65.952,
                66.012,
                66.072,
                66.132,
                66.191,
                66.25,
                66.309,
                66.367,
                66.426,
                66.484,
                66.541,
                66.599,
                66.656,
                66.713,
                66.769,
                66.825,
                66.882,
                66.937,
                66.993,
                67.048,
                67.104,
                67.158,
                67.213,
                67.268,
                67.322,
                67.376,
                67.43,
                67.484,
                67.537,
                67.59,
                67.644,
                67.696,
                67.749,
                67.802,
                67.854,
                67.906,
                67.958,
                68.01,
                68.062,
                68.113,
                68.165,
                68.216,
                68.267,
                68.318,
                68.369,
                68.419,
                68.47,
                68.52,
                68.57,
                68.62,
                68.67,
                68.72,
                68.769,
                68.819,
                68.868,
                68.917,
                68.966,
                69.015,
                69.064,
                69.113,
                69.162,
                69.21,
                69.258,
                69.307,
                69.355,
                69.403,
                69.451,
                69.499,
                69.547,
                69.595,
                69.642,
                69.69,
                69.737,
                69.784,
                69.832,
                69.879,
                69.926,
                69.973,
                70.02,
                70.067,
                70.113,
                70.16,
                70.206,
                70.253,
                70.299,
                70.346,
                70.392,
                70.438,
                70.484,
                70.53,
                70.576,
                70.622,
                70.668,
                70.714,
                70.76,
                70.806,
                70.851,
                70.897,
                70.942,
                70.988,
                71.033,
                71.078,
                71.124,
                71.169,
                71.214,
                71.259,
                71.304,
                71.349,
                71.394,
                71.439,
                71.483,
                71.528,
                71.573,
                71.617,
                71.662,
                71.706,
                71.75,
                71.795,
                71.839,
                71.883,
                71.927,
                71.971,
                72.015,
                72.059,
                72.103,
                72.147,
                72.191,
                72.235,
                72.278,
                72.322,
                72.366,
                72.409,
                72.452,
                72.496,
                72.539,
                72.582,
                72.625,
                72.668,
                72.712,
                72.754,
                72.797,
                72.84,
                72.883,
                72.926,
                72.968,
                73.011,
                73.054,
                73.096,
                73.138,
                73.181,
                73.223,
                73.265,
                73.308,
                73.35,
                73.392,
                73.434,
                73.476,
                73.518,
                73.559,
                73.601,
                73.643,
                73.685,
                73.726,
                73.768,
                73.809,
                73.851,
                73.892,
                73.933,
                73.975,
                74.016,
                74.057,
                74.098,
                74.139,
                74.18,
                74.221,
                74.262,
                74.303,
                74.343,
                74.384,
                74.425,
                74.465,
                74.506,
                74.546,
                74.587,
                74.627,
                74.668,
                74.708,
                74.748,
                74.788,
                74.829,
                74.869,
                74.909,
                74.949,
                74.989,
                75.029,
                75.068,
                75.108,
                75.148,
                75.188,
                75.227,
                75.267,
                75.307,
                75.346,
                75.386,
                75.425,
                75.464,
                75.504,
                75.543,
                75.582,
                75.622,
                75.661,
                75.7,
                75.739,
                75.778,
                75.817,
                75.856,
                75.895,
                75.934,
                75.973,
                76.012,
                76.051,
                76.089,
                76.128,
                76.167,
                76.205,
                76.244,
                76.282,
                76.321,
                76.359,
                76.398,
                76.436,
                76.474,
                76.512,
                76.551,
                76.589,
                76.627,
                76.665,
                76.703,
                76.741,
                76.779,
                76.817,
                76.855,
                76.893,
                76.93,
                76.968,
                77.006,
                77.044,
                77.081,
                77.119,
                77.156,
                77.194,
                77.231,
                77.269,
                77.306,
                77.343,
                77.381,
                77.418,
                77.455,
                77.492,
                77.53,
                77.567,
                77.604,
                77.641,
                77.678,
                77.715,
                77.751,
                77.788,
                77.825,
                77.862,
                77.899,
                77.935,
                77.972,
                78.009,
                78.045,
                78.082,
                78.118,
                78.155,
                78.191,
                78.228,
                78.264,
                78.3,
                78.336,
                78.373,
                78.409,
                78.445,
                78.481,
                78.517,
                78.553,
                78.589,
                78.625,
                78.661,
                78.697,
                78.733,
                78.769,
                78.805,
                78.841,
                78.876,
                78.912,
                78.948,
                78.984,
                79.019,
                79.055,
                79.09,
                79.126,
                79.161,
                79.197,
                79.232,
                79.268,
                79.303,
                79.338,
                79.374,
                79.409,
                79.444,
                79.479,
                79.515,
                79.55,
                79.585,
                79.62,
                79.655,
                79.69,
                79.725,
                79.76,
                79.795,
                79.83,
                79.864,
                79.899,
                79.934,
                79.969,
                80.004,
                80.038,
                80.073,
                80.108,
                80.142,
                80.177,
                80.211,
                80.246,
                80.28,
                80.315,
                80.349,
                80.384,
                80.418,
                80.452,
                80.487,
                80.521,
                80.555,
                80.59,
                80.624,
                80.658,
                80.692,
                80.726,
                80.76,
                80.794,
                80.828,
                80.862,
                80.896,
                80.93,
                80.964,
                80.998,
                81.032,
                81.066,
                81.099,
                81.133,
                81.167,
                81.2,
                81.234,
                81.268,
                81.301,
                81.335,
                81.368,
                81.402,
                81.435,
                81.469,
                81.502,
                81.536,
                81.569,
                81.602,
                81.636,
                81.669,
                81.702,
                81.735,
                81.769,
                81.802,
                81.835,
                81.868,
                81.901,
                81.934,
                81.967,
                82,
                82.033,
                82.066,
                82.099,
                82.132,
                82.164,
                82.197,
                82.23,
                82.263,
                82.296,
                82.328,
                82.361,
                82.394,
                82.426,
                82.459,
                82.491,
                82.524,
                82.556,
                82.589,
                82.621,
                82.654,
                82.686,
                82.718,
                82.751,
                82.783,
                82.815,
                82.847,
                82.88,
                82.912,
                82.944,
                82.976,
                83.008,
                83.04,
                83.072,
                83.104,
                83.136,
                83.168,
                83.2,
                83.232,
                83.264,
                83.296,
                83.327,
                83.359,
                83.391,
                83.423,
                83.454,
                83.486,
                83.518,
                83.549,
                83.581,
                83.612,
                83.644,
                83.675,
                83.707,
                83.738,
                83.77,
                83.801,
                83.833,
                83.864,
                83.895,
                83.927,
                83.958,
                83.989,
                84.021,
                84.052,
                84.083,
                84.114,
                84.145,
                84.176,
                84.207,
                84.239,
                84.27,
                84.301,
                84.332,
                84.363,
                84.394,
                84.425,
                84.455,
                84.486,
                84.517,
                84.548,
                84.579,
                84.61,
                84.64,
                84.671,
                84.702,
                84.732,
                84.763,
                84.794,
                84.824,
                84.855,
                84.886,
                84.916,
                84.947,
                84.977,
                85.008,
                85.038,
                85.068,
                85.099,
                85.129,
                85.159,
                85.19,
                85.22,
                85.25,
                85.281,
                85.311,
                85.341,
                85.371,
                85.401,
                85.431,
                85.462,
                85.492,
                85.522,
                85.552,
                85.582,
                85.612,
                85.642,
                85.672,
                85.702,
                85.731,
                85.761,
                85.791,
                85.821,
                85.851,
                85.881,
                85.91,
                85.94,
                85.97,
                86,
                86.029,
                86.059,
                86.089,
                86.118,
                86.148,
                86.177,
                86.207,
                86.236,
                86.266,
                86.295,
                86.325,
                86.354,
                86.384,
                86.413,
                86.443,
                86.472,
                86.501,
                86.531,
                86.56,
                86.589,
                86.618,
                86.648,
                86.677,
                86.706,
                86.735,
                86.765,
                86.794,
                86.823,
                86.852,
                86.881,
                86.91,
                86.939,
                86.968,
                86.997,
                87.026,
                87.055,
                87.084,
                87.113,
                87.142,
                87.171,
                87.2,
                87.229,
                87.258,
                87.286,
                87.315,
                87.344,
                87.373,
                87.401,
                87.43,
                87.459,
                87.488,
                87.516,
                87.545,
                87.574,
                87.602,
                87.631,
                87.659,
                87.688,
                87.716,
                87.745,
                87.773,
                87.802,
                87.13,
                87.159,
                87.187,
                87.216,
                87.244,
                87.272,
                87.301,
                87.329,
                87.357,
                87.385,
                87.414,
                87.442,
                87.47,
                87.498,
                87.526,
                87.555,
                87.583,
                87.611,
                87.639,
                87.667,
                87.695,
                87.723,
                87.751,
                87.779,
                87.807,
                87.835,
                87.863,
                87.89,
                87.918,
                87.946,
                87.974,
                88.002,
                88.029,
                88.057,
                88.085,
                88.112,
                88.14,
                88.168,
                88.195,
                88.223,
                88.25,
                88.278,
                88.305,
                88.333,
                88.36,
                88.388,
                88.415,
                88.443,
                88.47,
                88.497,
                88.525,
                88.552,
                88.579,
                88.606,
                88.634,
                88.661,
                88.688,
                88.715,
                88.742,
                88.769,
                88.796,
                88.823,
                88.85,
                88.878,
                88.904,
                88.931,
                88.958,
                88.985,
                89.012,
                89.039,
                89.066,
                89.093,
                89.12,
                89.146,
                89.173,
                89.2,
                89.227,
                89.253,
                89.28,
                89.307,
                89.333,
                89.36,
                89.386,
                89.413,
                89.44,
                89.466,
                89.493,
                89.519,
                89.546,
                89.572,
                89.598,
                89.625,
                89.651,
                89.677,
                89.704,
                89.73,
                89.756,
                89.783,
                89.809,
                89.835,
                89.861,
                89.887,
                89.913,
                89.94,
                89.966,
                89.992,
                90.018,
                90.044,
                90.07,
                90.096,
                90.122,
                90.148,
                90.174,
                90.199,
                90.225,
                90.251,
                90.277,
                90.303,
                90.328,
                90.354,
                90.38,
                90.406,
                90.431,
                90.457,
                90.483,
                90.508,
                90.534,
                90.559,
                90.585,
                90.61,
                90.636,
                90.661,
                90.687,
                90.712,
                90.738,
                90.763,
                90.788,
                90.814,
                90.839,
                90.864,
                90.889,
                90.915,
                90.94,
                90.965,
                90.99,
                91.015,
                91.04,
                91.066,
                91.091,
                91.116,
                91.141,
                91.166,
                91.191,
                91.216,
                91.241,
                91.265,
                91.29,
                91.315,
                91.34,
                91.365,
                91.39,
                91.414,
                91.439,
                91.464,
                91.489,
                91.513,
                91.538,
                91.563,
                91.587,
                91.612,
                91.636,
                91.661,
                91.686,
                91.71,
                91.735,
                91.759,
                91.783,
                91.808,
                91.832,
                91.857,
                91.881,
                91.905,
                91.93,
                91.954,
                91.978,
                92.003,
                92.027,
                92.051,
                92.075,
                92.099,
                92.124,
                92.148,
                92.172,
                92.196,
                92.22,
                92.244,
                92.268,
                92.292,
                92.316,
                92.34,
                92.364,
                92.388,
                92.412,
                92.436,
                92.46,
                92.483,
                92.507,
                92.531,
                92.555,
                92.579,
                92.602,
                92.626,
                92.65,
                92.674,
                92.697,
                92.721,
                92.744,
                92.768,
                92.792,
                92.815,
                92.839,
                92.862,
                92.886,
                92.909,
                92.933,
                92.956,
                92.98,
                93.003,
                93.027,
                93.05,
                93.073,
                93.097,
                93.12,
                93.143,
                93.167,
                93.19,
                93.213,
                93.236,
                93.26,
                93.283,
                93.306,
                93.329,
                93.352,
                93.375,
                93.398,
                93.422,
                93.445,
                93.468,
                93.491,
                93.514,
                93.537,
                93.56,
                93.583,
                93.606,
                93.629,
                93.651,
                93.674,
                93.697,
                93.72,
                93.743,
                93.766,
                93.789,
                93.811,
                93.834,
                93.857,
                93.88,
                93.902,
                93.925,
                93.948,
                93.971,
                93.993,
                94.016,
                94.039,
                94.061,
                94.084,
                94.106,
                94.129,
                94.152,
                94.174,
                94.197,
                94.219,
                94.242,
                94.264,
                94.287,
                94.309,
                94.332,
                94.354,
                94.377,
                94.399,
                94.421,
                94.444,
                94.466,
                94.489,
                94.511,
                94.533,
                94.556,
                94.578,
                94.6,
                94.623,
                94.645,
                94.667,
                94.689,
                94.712,
                94.734,
                94.756,
                94.778,
                94.8,
                94.823,
                94.845,
                94.867,
                94.889,
                94.911,
                94.933,
                94.955,
                94.977,
                95,
                95.022,
                95.044,
                95.066,
                95.088,
                95.11,
                95.132,
                95.154,
                95.176,
                95.198,
                95.22,
                95.242,
                95.264,
                95.286,
                95.307,
                95.329,
                95.351,
                95.373,
                95.395,
                95.417,
                95.439,
                95.461,
                95.482,
                95.504,
                95.526,
                95.548,
                95.57,
                95.591,
                95.613,
                95.635,
                95.657,
                95.678,
                95.7,
                95.722,
                95.743,
                95.765,
                95.787,
                95.808,
                95.83,
                95.852,
                95.873,
                95.895,
                95.917,
                95.938,
                95.96,
                95.981,
                96.003,
                96.024,
                96.046,
                96.067,
                96.089,
                96.11,
                96.132,
                96.153,
                96.175,
                96.196,
                96.218,
                96.239,
                96.261,
                96.282,
                96.304,
                96.325,
                96.346,
                96.368,
                96.389,
                96.411,
                96.432,
                96.453,
                96.475,
                96.496,
                96.517,
                96.539,
                96.56,
                96.581,
                96.602,
                96.624,
                96.645,
                96.666,
                96.687,
                96.709,
                96.73,
                96.751,
                96.772,
                96.793,
                96.815,
                96.836,
                96.857,
                96.878,
                96.899,
                96.92,
                96.941,
                96.963,
                96.984,
                97.005,
                97.026,
                97.047,
                97.068,
                97.089,
                97.11,
                97.131,
                97.152,
                97.173,
                97.194,
                97.215,
                97.236,
                97.257,
                97.278,
                97.299,
                97.32,
                97.341,
                97.362,
                97.383,
                97.404,
                97.425,
                97.445,
                97.466,
                97.487,
                97.508,
                97.529,
                97.55,
                97.571,
                97.591,
                97.612,
                97.633,
                97.654,
                97.675,
                97.695,
                97.716,
                97.737,
                97.758,
                97.779,
                97.799,
                97.82,
                97.841,
                97.861,
                97.882,
                97.903,
                97.923,
                97.944,
                97.965,
                97.985,
                98.006,
                98.027,
                98.047,
                98.068,
                98.089,
                98.109,
                98.13,
                98.15,
                98.171,
                98.191,
                98.212,
                98.233,
                98.253,
                98.274,
                98.294,
                98.315,
                98.335,
                98.356,
                98.376,
                98.396,
                98.417,
                98.437,
                98.458,
                98.478,
                98.499,
                98.519,
                98.539,
                98.56,
                98.58,
                98.601,
                98.621,
                98.641,
                98.662,
                98.682,
                98.702,
                98.723,
                98.743,
                98.763,
                98.783,
                98.804,
                98.824,
                98.844,
                98.864,
                98.885,
                98.905,
                98.925,
                98.945,
                98.966,
                98.986,
                99.006,
                99.026,
                99.046,
                99.066,
                99.086,
                99.107,
                99.127,
                99.147,
                99.167,
                99.187,
                99.207,
                99.227,
                99.247,
                99.267,
                99.287,
                99.307,
                99.327,
                99.347,
                99.367,
                99.387,
                99.407,
                99.427,
                99.447,
                99.467,
                99.487,
                99.507,
                99.527,
                99.547,
                99.567,
                99.587,
                99.606,
                99.626,
                99.646,
                99.666,
                99.686,
                99.706,
                99.725,
                99.745,
                99.765,
                99.785,
                99.805,
                99.824,
                99.844,
                99.864,
                99.884,
                99.903,
                99.923,
                99.943,
                99.963,
                99.982,
                100.002,
                100.022,
                100.041,
                100.061,
                100.081,
                100.1,
                100.12,
                100.139,
                100.159,
                100.179,
                100.198,
                100.218,
                100.237,
                100.257,
                100.277,
                100.296,
                100.316,
                100.335,
                100.355,
                100.374,
                100.394,
                100.413,
                100.433,
                100.452,
                100.472,
                100.491,
                100.511,
                100.53,
                100.55,
                100.569,
                100.588,
                100.608,
                100.627,
                100.647,
                100.666,
                100.685,
                100.705,
                100.724,
                100.743,
                100.763,
                100.782,
                100.801,
                100.821,
                100.84,
                100.859,
                100.879,
                100.898,
                100.917,
                100.936,
                100.956,
                100.975,
                100.994,
                101.013,
                101.033,
                101.052,
                101.071,
                101.09,
                101.109,
                101.129,
                101.148,
                101.167,
                101.186,
                101.205,
                101.224,
                101.244,
                101.263,
                101.282,
                101.301,
                101.32,
                101.339,
                101.358,
                101.377,
                101.396,
                101.415,
                101.434,
                101.454,
                101.473,
                101.492,
                101.511,
                101.53,
                101.549,
                101.568,
                101.587,
                101.606,
                101.625,
                101.644,
                101.663,
                101.682,
                101.7,
                101.719,
                101.738,
                101.757,
                101.776,
                101.795,
                101.814,
                101.833,
                101.852,
                101.871,
                101.89,
                101.909,
                101.927,
                101.946,
                101.965,
                101.984,
                102.003,
                102.022,
                102.041,
                102.059,
                102.078,
                102.097,
                102.116,
                102.135,
                102.153,
                102.172,
                102.191,
                102.21,
                102.229,
                102.247,
                102.266,
                102.285,
                102.304,
                102.322,
                102.341,
                102.36,
                102.379,
                102.397,
                102.416,
                102.435,
                102.453,
                102.472,
                102.491,
                102.51,
                102.528,
                102.547,
                102.566,
                102.584,
                102.603,
                102.622,
                102.64,
                102.659,
                102.678,
                102.696,
                102.715,
                102.733,
                102.752,
                102.771,
                102.789,
                102.808,
                102.827,
                102.845,
                102.864,
                102.882,
                102.901,
                102.92,
                102.938,
                102.957,
                102.975,
                102.994,
                103.012,
                103.031,
                103.049,
                103.068,
                103.087,
                103.105,
                103.124,
                103.142,
                103.161,
                103.179,
                103.198,
                103.216,
                103.235,
                103.253,
                103.272,
                103.29,
                103.309,
                103.327,
                103.346,
                103.364,
                103.383,
                103.401,
                103.42,
                103.438,
                103.457,
                103.475,
                103.494,
                103.512,
                103.531,
                103.549,
                103.567,
                103.586,
                103.604,
                103.623,
                103.641,
                103.66,
                103.678,
                103.697,
                103.715,
                103.733,
                103.752,
                103.77,
                103.789,
                103.807,
                103.825,
                103.844,
                103.862,
                103.881,
                103.899,
                103.917,
                103.936,
                103.954,
                103.973,
                103.991,
                104.009,
                104.028,
                104.046,
                104.064,
                104.083,
                104.101,
                104.12,
                104.138,
                104.156,
                104.175,
                104.193,
                104.211,
                104.23,
                104.248,
                104.266,
                104.285,
                104.303,
                104.321,
                104.34,
                104.358,
                104.376,
                104.395,
                104.413,
                104.431,
                104.45,
                104.468,
                104.486,
                104.505,
                104.523,
                104.541,
                104.56,
                104.578,
                104.596,
                104.614,
                104.633,
                104.651,
                104.669,
                104.688,
                104.706,
                104.724,
                104.743,
                104.761,
                104.779,
                104.797,
                104.816,
                104.834,
                104.852,
                104.871,
                104.889,
                104.907,
                104.925,
                104.944,
                104.962,
                104.98,
                104.998,
                105.017,
                105.035,
                105.053,
                105.072,
                105.09,
                105.108,
                105.126,
                105.145,
                105.163,
                105.181,
                105.199,
                105.218,
                105.236,
                105.254,
                105.272,
                105.291,
                105.309,
                105.327,
                105.345,
                105.364,
                105.382,
                105.4,
                105.418,
                105.437,
                105.455,
                105.473,
                105.491,
                105.509,
                105.528,
                105.546,
                105.564,
                105.582,
                105.601,
                105.619,
                105.637,
                105.655,
                105.674,
                105.692,
                105.71,
                105.728,
                105.746,
                105.765,
                105.783,
                105.801,
                105.819,
                105.837,
                105.856,
                105.874,
                105.892,
                105.91,
                105.928,
                105.947,
                105.965,
                105.983,
                106.001,
                106.019,
                106.038,
                106.056,
                106.074,
                106.092,
                106.11,
                106.129,
                106.147,
                106.165,
                106.183,
                106.201,
                106.22,
                106.238,
                106.256,
                106.274,
                106.292,
                106.31,
                106.329,
                106.347,
                106.365,
                106.383,
                106.401,
                106.419,
                106.438,
                106.456,
                106.474,
                106.492,
                106.51,
                106.528,
                106.547,
                106.565,
                106.583,
                106.601,
                106.619,
                106.637,
                106.656,
                106.674,
                106.692,
                106.71,
                106.728,
                106.746,
                106.764,
                106.783,
                106.801,
                106.819,
                106.837,
                106.855,
                106.873,
                106.891,
                106.91,
                106.928,
                106.946,
                106.964,
                106.982,
                107,
                107.018,
                107.036,
                107.055,
                107.073,
                107.091,
                107.109,
                107.127,
                107.145,
                107.163,
                107.181,
                107.2,
                107.218,
                107.236,
                107.254,
                107.272,
                107.29,
                107.308,
                107.326,
                107.344,
                107.363,
                107.381,
                107.399,
                107.417,
                107.435,
                107.453,
                107.471,
                107.489,
                107.507,
                107.526,
                107.544,
                107.562,
                107.58,
                107.598,
                107.616,
                107.634,
                107.652,
                107.67,
                107.688,
                107.706,
                107.725,
                107.743,
                107.761,
                107.779,
                107.797,
                107.815,
                107.833,
                107.851,
                107.869,
                107.887,
                107.905,
                107.923,
                107.942,
                107.96,
                107.978,
                107.996,
                108.014,
                108.032,
                108.05,
                108.068,
                108.086,
                108.104,
                108.122,
                108.14,
                108.158,
                108.176,
                108.195,
                108.213,
                108.231,
                108.249,
                108.267,
                108.285,
                108.303,
                108.321,
                108.339,
                108.357,
                108.375,
                108.393,
                108.411,
                108.429,
                108.447,
                108.465,
                108.483,
                108.501,
                108.519,
                108.538,
                108.556,
                108.574,
                108.592,
                108.61,
                108.628,
                108.646,
                108.664,
                108.682,
                108.7,
                108.718,
                108.736,
                108.754,
                108.772,
                108.79,
                108.808,
                108.826,
                108.844,
                108.862,
                108.88,
                108.898,
                108.916,
                108.934,
                108.952,
                108.97,
                108.988,
                109.006,
                109.024,
                109.042,
                109.06,
                109.078,
                109.096,
                109.114,
                109.132,
                109.15,
                109.168,
                109.186,
                109.204,
                109.222,
                109.24,
                109.258,
                109.276,
                109.294,
                109.312,
                109.33,
                109.348,
                109.366,
                109.384,
                109.402,
                109.42,
                109.438,
                109.456,
                109.474,
                109.492,
                109.51,
                109.528,
                109.546,
                109.564,
                109.582,
                109.6,
                109.618,
                109.636,
                109.654,
                109.672,
                109.69,
                109.708,
                109.726,
                109.744,
                109.762,
                109.78,
                109.798,
                109.816,
                109.834,
                109.852,
                109.87,
                109.888,
                109.906,
                109.923,
                109.941,
                109.959,
                109.977,
                109.995,
                110.013,
                110.031,
                110.049,
                110.067,
                110.085,
                110.103,
                110.121,
                110.139,
                110.157,
                110.175,
                110.192,
                110.21,
                110.228,
                110.246,
                110.264,
                110.282,
                110.3,
                110.318,
                110.336,
                110.354,
                110.372,
                110.39,
                110.407,
                110.425,
                110.443,
                110.461,
                110.479,
                110.497
            ],
            P75: [
                51.161,
                51.338,
                51.515,
                51.692,
                51.868,
                52.045,
                52.222,
                52.399,
                52.576,
                52.752,
                52.929,
                53.106,
                53.282,
                53.459,
                53.636,
                53.788,
                53.941,
                54.093,
                54.244,
                54.393,
                54.542,
                54.69,
                54.837,
                54.983,
                55.128,
                55.272,
                55.415,
                55.557,
                55.698,
                55.838,
                55.977,
                56.115,
                56.252,
                56.388,
                56.523,
                56.656,
                56.789,
                56.921,
                57.052,
                57.181,
                57.31,
                57.437,
                57.564,
                57.689,
                57.814,
                57.937,
                58.06,
                58.181,
                58.302,
                58.421,
                58.54,
                58.658,
                58.775,
                58.89,
                59.006,
                59.12,
                59.233,
                59.345,
                59.457,
                59.568,
                59.678,
                59.788,
                59.897,
                60.005,
                60.113,
                60.22,
                60.326,
                60.432,
                60.537,
                60.642,
                60.746,
                60.849,
                60.952,
                61.054,
                61.155,
                61.255,
                61.355,
                61.455,
                61.554,
                61.652,
                61.749,
                61.846,
                61.942,
                62.038,
                62.133,
                62.227,
                62.32,
                62.414,
                62.506,
                62.598,
                62.689,
                62.78,
                62.87,
                62.959,
                63.048,
                63.136,
                63.224,
                63.311,
                63.398,
                63.484,
                63.569,
                63.654,
                63.738,
                63.821,
                63.905,
                63.987,
                64.069,
                64.151,
                64.232,
                64.312,
                64.391,
                64.471,
                64.55,
                64.628,
                64.705,
                64.782,
                64.859,
                64.935,
                65.011,
                65.086,
                65.16,
                65.234,
                65.308,
                65.381,
                65.454,
                65.526,
                65.597,
                65.668,
                65.739,
                65.809,
                65.879,
                65.948,
                66.018,
                66.086,
                66.154,
                66.222,
                66.289,
                66.356,
                66.422,
                66.488,
                66.554,
                66.62,
                66.685,
                66.749,
                66.814,
                66.877,
                66.941,
                67.004,
                67.067,
                67.13,
                67.192,
                67.254,
                67.315,
                67.377,
                67.437,
                67.498,
                67.558,
                67.618,
                67.678,
                67.737,
                67.797,
                67.855,
                67.914,
                67.972,
                68.03,
                68.088,
                68.146,
                68.203,
                68.26,
                68.316,
                68.373,
                68.429,
                68.485,
                68.541,
                68.597,
                68.652,
                68.707,
                68.762,
                68.817,
                68.871,
                68.926,
                68.98,
                69.034,
                69.088,
                69.141,
                69.194,
                69.248,
                69.301,
                69.354,
                69.406,
                69.459,
                69.511,
                69.563,
                69.615,
                69.667,
                69.719,
                69.77,
                69.822,
                69.873,
                69.924,
                69.975,
                70.026,
                70.076,
                70.127,
                70.178,
                70.228,
                70.278,
                70.328,
                70.378,
                70.428,
                70.477,
                70.527,
                70.577,
                70.626,
                70.675,
                70.724,
                70.773,
                70.822,
                70.871,
                70.92,
                70.969,
                71.017,
                71.066,
                71.114,
                71.162,
                71.21,
                71.259,
                71.306,
                71.354,
                71.402,
                71.45,
                71.497,
                71.545,
                71.592,
                71.64,
                71.687,
                71.735,
                71.782,
                71.829,
                71.876,
                71.923,
                71.97,
                72.017,
                72.064,
                72.11,
                72.157,
                72.204,
                72.25,
                72.296,
                72.343,
                72.39,
                72.436,
                72.482,
                72.528,
                72.575,
                72.621,
                72.666,
                72.712,
                72.758,
                72.804,
                72.85,
                72.896,
                72.941,
                72.987,
                73.033,
                73.078,
                73.123,
                73.169,
                73.214,
                73.259,
                73.305,
                73.35,
                73.395,
                73.439,
                73.485,
                73.529,
                73.574,
                73.619,
                73.664,
                73.709,
                73.753,
                73.798,
                73.842,
                73.887,
                73.931,
                73.976,
                74.02,
                74.064,
                74.108,
                74.152,
                74.196,
                74.24,
                74.284,
                74.328,
                74.372,
                74.415,
                74.459,
                74.502,
                74.546,
                74.59,
                74.633,
                74.677,
                74.72,
                74.763,
                74.806,
                74.849,
                74.892,
                74.936,
                74.979,
                75.021,
                75.064,
                75.107,
                75.15,
                75.193,
                75.235,
                75.278,
                75.32,
                75.362,
                75.405,
                75.447,
                75.49,
                75.532,
                75.574,
                75.616,
                75.658,
                75.7,
                75.743,
                75.784,
                75.826,
                75.868,
                75.91,
                75.951,
                75.993,
                76.035,
                76.076,
                76.118,
                76.159,
                76.201,
                76.242,
                76.284,
                76.325,
                76.366,
                76.407,
                76.448,
                76.489,
                76.53,
                76.571,
                76.612,
                76.653,
                76.694,
                76.735,
                76.775,
                76.816,
                76.857,
                76.897,
                76.938,
                76.979,
                77.019,
                77.06,
                77.1,
                77.14,
                77.181,
                77.221,
                77.261,
                77.302,
                77.342,
                77.382,
                77.422,
                77.462,
                77.502,
                77.542,
                77.582,
                77.622,
                77.662,
                77.702,
                77.741,
                77.781,
                77.821,
                77.861,
                77.9,
                77.94,
                77.979,
                78.019,
                78.058,
                78.097,
                78.137,
                78.176,
                78.216,
                78.254,
                78.294,
                78.333,
                78.372,
                78.411,
                78.45,
                78.489,
                78.528,
                78.567,
                78.606,
                78.645,
                78.684,
                78.723,
                78.761,
                78.8,
                78.839,
                78.877,
                78.916,
                78.954,
                78.992,
                79.031,
                79.069,
                79.108,
                79.146,
                79.184,
                79.222,
                79.261,
                79.299,
                79.337,
                79.375,
                79.413,
                79.451,
                79.489,
                79.527,
                79.565,
                79.603,
                79.64,
                79.678,
                79.716,
                79.754,
                79.791,
                79.829,
                79.867,
                79.904,
                79.941,
                79.979,
                80.017,
                80.054,
                80.091,
                80.129,
                80.166,
                80.203,
                80.24,
                80.278,
                80.315,
                80.352,
                80.389,
                80.426,
                80.463,
                80.5,
                80.537,
                80.574,
                80.611,
                80.648,
                80.685,
                80.721,
                80.758,
                80.795,
                80.831,
                80.868,
                80.905,
                80.942,
                80.978,
                81.015,
                81.051,
                81.088,
                81.124,
                81.161,
                81.197,
                81.233,
                81.27,
                81.306,
                81.343,
                81.379,
                81.415,
                81.451,
                81.487,
                81.523,
                81.56,
                81.596,
                81.631,
                81.667,
                81.703,
                81.74,
                81.776,
                81.811,
                81.847,
                81.883,
                81.919,
                81.954,
                81.99,
                82.026,
                82.062,
                82.098,
                82.133,
                82.168,
                82.204,
                82.24,
                82.275,
                82.311,
                82.346,
                82.382,
                82.417,
                82.452,
                82.488,
                82.523,
                82.558,
                82.593,
                82.629,
                82.664,
                82.699,
                82.734,
                82.77,
                82.805,
                82.84,
                82.874,
                82.909,
                82.944,
                82.979,
                83.014,
                83.049,
                83.084,
                83.119,
                83.154,
                83.189,
                83.223,
                83.258,
                83.293,
                83.327,
                83.362,
                83.397,
                83.431,
                83.465,
                83.5,
                83.534,
                83.569,
                83.603,
                83.638,
                83.672,
                83.706,
                83.741,
                83.775,
                83.809,
                83.843,
                83.878,
                83.912,
                83.946,
                83.98,
                84.014,
                84.048,
                84.082,
                84.116,
                84.15,
                84.184,
                84.218,
                84.252,
                84.286,
                84.32,
                84.353,
                84.387,
                84.421,
                84.455,
                84.488,
                84.522,
                84.555,
                84.589,
                84.623,
                84.656,
                84.69,
                84.723,
                84.757,
                84.79,
                84.823,
                84.857,
                84.89,
                84.923,
                84.957,
                84.99,
                85.024,
                85.057,
                85.09,
                85.123,
                85.156,
                85.189,
                85.222,
                85.255,
                85.288,
                85.321,
                85.354,
                85.387,
                85.42,
                85.453,
                85.486,
                85.519,
                85.551,
                85.584,
                85.617,
                85.65,
                85.683,
                85.716,
                85.748,
                85.781,
                85.813,
                85.846,
                85.878,
                85.911,
                85.944,
                85.976,
                86.008,
                86.041,
                86.073,
                86.106,
                86.138,
                86.171,
                86.203,
                86.235,
                86.268,
                86.3,
                86.332,
                86.364,
                86.396,
                86.429,
                86.461,
                86.493,
                86.525,
                86.557,
                86.589,
                86.621,
                86.653,
                86.685,
                86.717,
                86.749,
                86.781,
                86.813,
                86.845,
                86.877,
                86.909,
                86.94,
                86.972,
                87.004,
                87.035,
                87.067,
                87.099,
                87.131,
                87.162,
                87.194,
                87.225,
                87.257,
                87.288,
                87.32,
                87.351,
                87.383,
                87.414,
                87.446,
                87.477,
                87.508,
                87.54,
                87.571,
                87.602,
                87.634,
                87.665,
                87.696,
                87.727,
                87.759,
                87.79,
                87.821,
                87.852,
                87.883,
                87.914,
                87.945,
                87.976,
                88.007,
                88.038,
                88.069,
                88.101,
                88.131,
                88.162,
                88.193,
                88.224,
                88.255,
                88.285,
                88.317,
                88.347,
                88.378,
                88.409,
                88.439,
                88.47,
                88.501,
                88.532,
                88.562,
                88.593,
                88.623,
                88.654,
                88.685,
                88.715,
                88.746,
                88.776,
                88.807,
                88.837,
                88.868,
                88.898,
                88.928,
                88.959,
                88.99,
                89.02,
                89.05,
                89.08,
                89.111,
                89.141,
                89.172,
                89.202,
                89.232,
                89.262,
                89.292,
                89.322,
                89.352,
                89.383,
                89.413,
                89.443,
                89.473,
                89.503,
                89.533,
                89.563,
                89.593,
                89.623,
                89.653,
                89.683,
                89.713,
                89.742,
                89.772,
                89.803,
                89.832,
                89.862,
                89.192,
                89.222,
                89.251,
                89.281,
                89.311,
                89.341,
                89.37,
                89.4,
                89.429,
                89.459,
                89.488,
                89.519,
                89.548,
                89.577,
                89.607,
                89.636,
                89.666,
                89.695,
                89.724,
                89.754,
                89.783,
                89.813,
                89.842,
                89.871,
                89.9,
                89.929,
                89.959,
                89.988,
                90.017,
                90.046,
                90.075,
                90.104,
                90.133,
                90.163,
                90.192,
                90.221,
                90.25,
                90.279,
                90.308,
                90.336,
                90.365,
                90.395,
                90.423,
                90.452,
                90.481,
                90.509,
                90.538,
                90.567,
                90.595,
                90.625,
                90.653,
                90.682,
                90.71,
                90.739,
                90.767,
                90.796,
                90.824,
                90.852,
                90.881,
                90.91,
                90.938,
                90.966,
                90.995,
                91.023,
                91.051,
                91.079,
                91.108,
                91.136,
                91.165,
                91.193,
                91.221,
                91.249,
                91.277,
                91.305,
                91.333,
                91.361,
                91.389,
                91.418,
                91.445,
                91.473,
                91.501,
                91.529,
                91.557,
                91.585,
                91.612,
                91.64,
                91.668,
                91.696,
                91.724,
                91.752,
                91.779,
                91.807,
                91.835,
                91.862,
                91.89,
                91.917,
                91.945,
                91.972,
                92,
                92.027,
                92.055,
                92.083,
                92.11,
                92.137,
                92.165,
                92.192,
                92.219,
                92.247,
                92.274,
                92.301,
                92.328,
                92.355,
                92.383,
                92.41,
                92.437,
                92.464,
                92.491,
                92.518,
                92.545,
                92.572,
                92.599,
                92.626,
                92.653,
                92.68,
                92.707,
                92.734,
                92.761,
                92.787,
                92.814,
                92.841,
                92.868,
                92.894,
                92.921,
                92.948,
                92.974,
                93.001,
                93.028,
                93.055,
                93.081,
                93.108,
                93.134,
                93.161,
                93.187,
                93.214,
                93.24,
                93.266,
                93.293,
                93.319,
                93.345,
                93.372,
                93.398,
                93.424,
                93.45,
                93.477,
                93.503,
                93.529,
                93.555,
                93.581,
                93.607,
                93.633,
                93.659,
                93.685,
                93.711,
                93.737,
                93.763,
                93.789,
                93.815,
                93.841,
                93.867,
                93.893,
                93.918,
                93.944,
                93.97,
                93.996,
                94.021,
                94.047,
                94.073,
                94.098,
                94.124,
                94.15,
                94.175,
                94.201,
                94.226,
                94.252,
                94.277,
                94.303,
                94.328,
                94.354,
                94.379,
                94.405,
                94.429,
                94.455,
                94.48,
                94.505,
                94.531,
                94.556,
                94.581,
                94.606,
                94.632,
                94.657,
                94.682,
                94.707,
                94.732,
                94.757,
                94.783,
                94.808,
                94.833,
                94.858,
                94.883,
                94.908,
                94.932,
                94.957,
                94.982,
                95.007,
                95.032,
                95.057,
                95.081,
                95.106,
                95.131,
                95.156,
                95.181,
                95.205,
                95.23,
                95.255,
                95.279,
                95.304,
                95.328,
                95.353,
                95.378,
                95.402,
                95.427,
                95.451,
                95.476,
                95.5,
                95.525,
                95.549,
                95.573,
                95.598,
                95.622,
                95.647,
                95.671,
                95.695,
                95.72,
                95.744,
                95.768,
                95.792,
                95.816,
                95.841,
                95.865,
                95.889,
                95.913,
                95.937,
                95.962,
                95.985,
                96.009,
                96.033,
                96.058,
                96.082,
                96.106,
                96.13,
                96.154,
                96.177,
                96.201,
                96.225,
                96.249,
                96.273,
                96.297,
                96.321,
                96.344,
                96.368,
                96.392,
                96.416,
                96.44,
                96.464,
                96.488,
                96.511,
                96.535,
                96.558,
                96.582,
                96.606,
                96.63,
                96.653,
                96.676,
                96.7,
                96.724,
                96.748,
                96.771,
                96.794,
                96.818,
                96.841,
                96.865,
                96.889,
                96.912,
                96.935,
                96.959,
                96.982,
                97.006,
                97.029,
                97.052,
                97.076,
                97.099,
                97.123,
                97.146,
                97.169,
                97.192,
                97.216,
                97.239,
                97.262,
                97.285,
                97.309,
                97.332,
                97.355,
                97.379,
                97.401,
                97.425,
                97.448,
                97.471,
                97.494,
                97.517,
                97.54,
                97.563,
                97.587,
                97.609,
                97.632,
                97.656,
                97.679,
                97.701,
                97.725,
                97.748,
                97.771,
                97.794,
                97.816,
                97.839,
                97.862,
                97.886,
                97.908,
                97.931,
                97.954,
                97.977,
                97.999,
                98.022,
                98.045,
                98.068,
                98.091,
                98.114,
                98.137,
                98.16,
                98.182,
                98.205,
                98.228,
                98.251,
                98.273,
                98.296,
                98.318,
                98.341,
                98.363,
                98.386,
                98.409,
                98.431,
                98.454,
                98.477,
                98.5,
                98.522,
                98.545,
                98.567,
                98.589,
                98.612,
                98.635,
                98.657,
                98.679,
                98.702,
                98.725,
                98.747,
                98.769,
                98.792,
                98.815,
                98.837,
                98.859,
                98.882,
                98.904,
                98.926,
                98.949,
                98.971,
                98.993,
                99.016,
                99.039,
                99.06,
                99.083,
                99.105,
                99.127,
                99.15,
                99.172,
                99.194,
                99.216,
                99.239,
                99.261,
                99.283,
                99.305,
                99.327,
                99.349,
                99.372,
                99.394,
                99.416,
                99.438,
                99.46,
                99.482,
                99.505,
                99.526,
                99.549,
                99.571,
                99.592,
                99.615,
                99.637,
                99.659,
                99.681,
                99.703,
                99.725,
                99.747,
                99.769,
                99.791,
                99.813,
                99.835,
                99.856,
                99.879,
                99.901,
                99.922,
                99.944,
                99.966,
                99.988,
                100.01,
                100.031,
                100.054,
                100.076,
                100.097,
                100.119,
                100.141,
                100.163,
                100.185,
                100.207,
                100.228,
                100.25,
                100.271,
                100.293,
                100.315,
                100.336,
                100.358,
                100.38,
                100.402,
                100.424,
                100.445,
                100.467,
                100.489,
                100.51,
                100.532,
                100.553,
                100.575,
                100.597,
                100.618,
                100.64,
                100.661,
                100.683,
                100.704,
                100.726,
                100.747,
                100.769,
                100.79,
                100.812,
                100.833,
                100.855,
                100.876,
                100.898,
                100.919,
                100.94,
                100.962,
                100.983,
                101.005,
                101.026,
                101.047,
                101.069,
                101.09,
                101.112,
                101.133,
                101.154,
                101.176,
                101.197,
                101.218,
                101.24,
                101.261,
                101.282,
                101.303,
                101.325,
                101.345,
                101.367,
                101.388,
                101.409,
                101.431,
                101.451,
                101.473,
                101.494,
                101.515,
                101.537,
                101.557,
                101.579,
                101.6,
                101.621,
                101.642,
                101.663,
                101.684,
                101.705,
                101.726,
                101.747,
                101.768,
                101.789,
                101.81,
                101.831,
                101.852,
                101.873,
                101.894,
                101.915,
                101.936,
                101.957,
                101.978,
                101.999,
                102.019,
                102.04,
                102.061,
                102.082,
                102.103,
                102.124,
                102.145,
                102.165,
                102.186,
                102.207,
                102.228,
                102.248,
                102.27,
                102.29,
                102.311,
                102.332,
                102.352,
                102.373,
                102.394,
                102.415,
                102.435,
                102.456,
                102.476,
                102.497,
                102.518,
                102.539,
                102.559,
                102.58,
                102.6,
                102.621,
                102.641,
                102.662,
                102.683,
                102.703,
                102.724,
                102.744,
                102.765,
                102.785,
                102.806,
                102.826,
                102.847,
                102.867,
                102.888,
                102.908,
                102.929,
                102.949,
                102.97,
                102.99,
                103.011,
                103.031,
                103.051,
                103.071,
                103.092,
                103.112,
                103.133,
                103.153,
                103.174,
                103.193,
                103.214,
                103.234,
                103.255,
                103.275,
                103.295,
                103.316,
                103.336,
                103.356,
                103.376,
                103.397,
                103.417,
                103.437,
                103.457,
                103.478,
                103.498,
                103.518,
                103.538,
                103.559,
                103.578,
                103.599,
                103.619,
                103.639,
                103.659,
                103.679,
                103.699,
                103.72,
                103.739,
                103.76,
                103.779,
                103.8,
                103.82,
                103.84,
                103.86,
                103.879,
                103.9,
                103.919,
                103.94,
                103.959,
                103.98,
                103.999,
                104.02,
                104.039,
                104.06,
                104.079,
                104.1,
                104.119,
                104.139,
                104.159,
                104.179,
                104.199,
                104.219,
                104.239,
                104.259,
                104.278,
                104.299,
                104.318,
                104.338,
                104.358,
                104.378,
                104.397,
                104.418,
                104.437,
                104.457,
                104.477,
                104.497,
                104.516,
                104.536,
                104.556,
                104.575,
                104.595,
                104.615,
                104.635,
                104.654,
                104.674,
                104.694,
                104.714,
                104.733,
                104.753,
                104.773,
                104.793,
                104.812,
                104.832,
                104.851,
                104.872,
                104.891,
                104.911,
                104.93,
                104.949,
                104.969,
                104.989,
                105.009,
                105.028,
                105.048,
                105.067,
                105.087,
                105.106,
                105.126,
                105.146,
                105.165,
                105.185,
                105.205,
                105.224,
                105.243,
                105.263,
                105.282,
                105.302,
                105.321,
                105.341,
                105.36,
                105.38,
                105.399,
                105.419,
                105.438,
                105.458,
                105.477,
                105.497,
                105.516,
                105.536,
                105.555,
                105.575,
                105.594,
                105.614,
                105.633,
                105.652,
                105.672,
                105.691,
                105.711,
                105.73,
                105.75,
                105.769,
                105.788,
                105.808,
                105.827,
                105.847,
                105.866,
                105.886,
                105.905,
                105.924,
                105.943,
                105.963,
                105.982,
                106.001,
                106.021,
                106.04,
                106.06,
                106.079,
                106.098,
                106.117,
                106.137,
                106.156,
                106.176,
                106.195,
                106.214,
                106.233,
                106.252,
                106.272,
                106.291,
                106.311,
                106.33,
                106.349,
                106.368,
                106.388,
                106.407,
                106.426,
                106.446,
                106.465,
                106.484,
                106.503,
                106.523,
                106.542,
                106.561,
                106.58,
                106.599,
                106.619,
                106.638,
                106.657,
                106.676,
                106.696,
                106.715,
                106.734,
                106.753,
                106.772,
                106.792,
                106.811,
                106.83,
                106.849,
                106.869,
                106.888,
                106.907,
                106.926,
                106.946,
                106.964,
                106.983,
                107.003,
                107.022,
                107.041,
                107.06,
                107.08,
                107.099,
                107.118,
                107.137,
                107.156,
                107.175,
                107.194,
                107.214,
                107.233,
                107.252,
                107.271,
                107.291,
                107.309,
                107.328,
                107.348,
                107.367,
                107.386,
                107.405,
                107.424,
                107.443,
                107.462,
                107.482,
                107.5,
                107.52,
                107.539,
                107.558,
                107.577,
                107.597,
                107.615,
                107.634,
                107.654,
                107.672,
                107.692,
                107.711,
                107.73,
                107.749,
                107.768,
                107.787,
                107.806,
                107.825,
                107.844,
                107.864,
                107.883,
                107.902,
                107.921,
                107.94,
                107.959,
                107.978,
                107.997,
                108.016,
                108.036,
                108.054,
                108.074,
                108.093,
                108.111,
                108.131,
                108.15,
                108.169,
                108.188,
                108.207,
                108.226,
                108.245,
                108.264,
                108.283,
                108.302,
                108.321,
                108.341,
                108.359,
                108.379,
                108.398,
                108.416,
                108.436,
                108.455,
                108.474,
                108.493,
                108.512,
                108.531,
                108.55,
                108.569,
                108.588,
                108.607,
                108.626,
                108.645,
                108.664,
                108.683,
                108.702,
                108.721,
                108.74,
                108.759,
                108.779,
                108.797,
                108.816,
                108.835,
                108.854,
                108.873,
                108.892,
                108.912,
                108.93,
                108.949,
                108.968,
                108.987,
                109.007,
                109.025,
                109.045,
                109.063,
                109.083,
                109.101,
                109.12,
                109.14,
                109.158,
                109.178,
                109.196,
                109.216,
                109.234,
                109.253,
                109.273,
                109.291,
                109.311,
                109.329,
                109.348,
                109.367,
                109.386,
                109.405,
                109.424,
                109.443,
                109.462,
                109.481,
                109.5,
                109.519,
                109.538,
                109.557,
                109.576,
                109.595,
                109.614,
                109.633,
                109.652,
                109.671,
                109.69,
                109.709,
                109.728,
                109.746,
                109.766,
                109.784,
                109.804,
                109.822,
                109.842,
                109.86,
                109.879,
                109.898,
                109.917,
                109.936,
                109.955,
                109.974,
                109.993,
                110.012,
                110.031,
                110.05,
                110.069,
                110.088,
                110.106,
                110.126,
                110.144,
                110.164,
                110.182,
                110.202,
                110.22,
                110.239,
                110.258,
                110.277,
                110.296,
                110.315,
                110.333,
                110.353,
                110.371,
                110.391,
                110.409,
                110.429,
                110.447,
                110.466,
                110.485,
                110.504,
                110.523,
                110.542,
                110.561,
                110.58,
                110.598,
                110.618,
                110.636,
                110.656,
                110.674,
                110.693,
                110.712,
                110.731,
                110.75,
                110.769,
                110.788,
                110.807,
                110.825,
                110.844,
                110.863,
                110.882,
                110.901,
                110.92,
                110.939,
                110.957,
                110.977,
                110.995,
                111.014,
                111.033,
                111.052,
                111.071,
                111.09,
                111.109,
                111.128,
                111.146,
                111.165,
                111.184,
                111.203,
                111.222,
                111.241,
                111.26,
                111.278,
                111.298,
                111.316,
                111.335,
                111.354,
                111.373,
                111.392,
                111.411,
                111.43,
                111.448,
                111.467,
                111.486,
                111.505,
                111.524,
                111.543,
                111.561,
                111.58,
                111.599,
                111.618,
                111.637,
                111.655,
                111.675,
                111.693,
                111.712,
                111.731,
                111.75,
                111.769,
                111.787,
                111.807,
                111.825,
                111.845,
                111.863,
                111.882,
                111.901,
                111.919,
                111.939,
                111.957,
                111.976,
                111.995,
                112.014,
                112.033,
                112.051,
                112.07,
                112.089,
                112.108,
                112.127,
                112.145,
                112.164,
                112.183,
                112.202,
                112.221,
                112.24,
                112.258,
                112.277,
                112.296,
                112.315,
                112.334,
                112.352,
                112.371,
                112.39,
                112.409,
                112.428,
                112.446,
                112.465,
                112.484,
                112.503,
                112.521,
                112.54,
                112.559,
                112.578,
                112.597,
                112.615,
                112.634,
                112.653,
                112.672,
                112.691,
                112.709,
                112.728,
                112.747,
                112.766,
                112.785,
                112.803,
                112.822,
                112.841,
                112.859,
                112.879,
                112.897,
                112.916,
                112.935,
                112.953,
                112.972,
                112.991,
                113.009,
                113.029,
                113.047,
                113.066,
                113.085,
                113.103,
                113.122,
                113.141,
                113.16,
                113.178,
                113.197,
                113.216,
                113.234,
                113.254,
                113.272,
                113.291,
                113.31,
                113.328,
                113.347,
                113.366,
                113.384,
                113.403,
                113.422,
                113.441,
                113.459,
                113.478,
                113.497,
                113.515,
                113.534,
                113.553,
                113.571,
                113.591,
                113.609,
                113.627,
                113.646
            ],
            P85: [
                51.846,
                52.024,
                52.201,
                52.378,
                52.556,
                52.733,
                52.911,
                53.088,
                53.265,
                53.442,
                53.619,
                53.796,
                53.973,
                54.15,
                54.327,
                54.481,
                54.634,
                54.787,
                54.939,
                55.089,
                55.239,
                55.388,
                55.535,
                55.682,
                55.828,
                55.972,
                56.116,
                56.259,
                56.4,
                56.542,
                56.681,
                56.82,
                56.958,
                57.094,
                57.23,
                57.364,
                57.498,
                57.63,
                57.762,
                57.892,
                58.021,
                58.149,
                58.276,
                58.402,
                58.528,
                58.652,
                58.775,
                58.897,
                59.018,
                59.138,
                59.257,
                59.376,
                59.493,
                59.61,
                59.726,
                59.84,
                59.954,
                60.067,
                60.179,
                60.291,
                60.402,
                60.512,
                60.622,
                60.73,
                60.838,
                60.946,
                61.053,
                61.16,
                61.265,
                61.37,
                61.475,
                61.578,
                61.682,
                61.784,
                61.886,
                61.987,
                62.088,
                62.188,
                62.287,
                62.386,
                62.484,
                62.581,
                62.677,
                62.774,
                62.869,
                62.964,
                63.058,
                63.152,
                63.245,
                63.337,
                63.428,
                63.52,
                63.61,
                63.7,
                63.79,
                63.878,
                63.966,
                64.054,
                64.141,
                64.227,
                64.313,
                64.398,
                64.483,
                64.567,
                64.65,
                64.734,
                64.816,
                64.898,
                64.979,
                65.06,
                65.14,
                65.22,
                65.299,
                65.378,
                65.455,
                65.533,
                65.61,
                65.686,
                65.762,
                65.838,
                65.913,
                65.987,
                66.061,
                66.135,
                66.208,
                66.28,
                66.352,
                66.424,
                66.495,
                66.565,
                66.635,
                66.705,
                66.775,
                66.844,
                66.912,
                66.98,
                67.047,
                67.115,
                67.182,
                67.248,
                67.314,
                67.38,
                67.445,
                67.51,
                67.575,
                67.639,
                67.703,
                67.767,
                67.83,
                67.893,
                67.955,
                68.018,
                68.079,
                68.141,
                68.202,
                68.263,
                68.324,
                68.384,
                68.445,
                68.504,
                68.564,
                68.622,
                68.682,
                68.741,
                68.798,
                68.857,
                68.915,
                68.972,
                69.029,
                69.087,
                69.143,
                69.2,
                69.256,
                69.312,
                69.368,
                69.424,
                69.48,
                69.534,
                69.59,
                69.645,
                69.699,
                69.754,
                69.808,
                69.862,
                69.916,
                69.97,
                70.024,
                70.077,
                70.13,
                70.183,
                70.236,
                70.289,
                70.341,
                70.394,
                70.446,
                70.498,
                70.55,
                70.602,
                70.653,
                70.705,
                70.756,
                70.807,
                70.858,
                70.91,
                70.96,
                71.011,
                71.061,
                71.112,
                71.162,
                71.212,
                71.262,
                71.312,
                71.362,
                71.412,
                71.462,
                71.511,
                71.56,
                71.61,
                71.659,
                71.708,
                71.757,
                71.806,
                71.855,
                71.903,
                71.953,
                72.001,
                72.05,
                72.098,
                72.146,
                72.194,
                72.243,
                72.29,
                72.339,
                72.386,
                72.434,
                72.482,
                72.53,
                72.577,
                72.625,
                72.673,
                72.72,
                72.767,
                72.815,
                72.862,
                72.909,
                72.956,
                73.003,
                73.05,
                73.097,
                73.144,
                73.191,
                73.238,
                73.284,
                73.331,
                73.377,
                73.424,
                73.47,
                73.517,
                73.563,
                73.61,
                73.655,
                73.702,
                73.748,
                73.794,
                73.84,
                73.885,
                73.931,
                73.977,
                74.023,
                74.069,
                74.115,
                74.161,
                74.206,
                74.251,
                74.296,
                74.342,
                74.387,
                74.433,
                74.478,
                74.523,
                74.568,
                74.613,
                74.658,
                74.703,
                74.748,
                74.793,
                74.838,
                74.882,
                74.927,
                74.972,
                75.016,
                75.06,
                75.105,
                75.149,
                75.193,
                75.238,
                75.282,
                75.326,
                75.37,
                75.414,
                75.458,
                75.502,
                75.546,
                75.589,
                75.633,
                75.677,
                75.72,
                75.764,
                75.808,
                75.851,
                75.894,
                75.937,
                75.98,
                76.024,
                76.067,
                76.11,
                76.153,
                76.196,
                76.239,
                76.282,
                76.325,
                76.367,
                76.411,
                76.453,
                76.496,
                76.538,
                76.581,
                76.623,
                76.665,
                76.708,
                76.75,
                76.793,
                76.834,
                76.877,
                76.919,
                76.961,
                77.003,
                77.045,
                77.087,
                77.129,
                77.171,
                77.212,
                77.254,
                77.296,
                77.337,
                77.379,
                77.42,
                77.462,
                77.504,
                77.545,
                77.587,
                77.627,
                77.669,
                77.71,
                77.751,
                77.793,
                77.833,
                77.875,
                77.916,
                77.957,
                77.998,
                78.039,
                78.079,
                78.12,
                78.161,
                78.202,
                78.243,
                78.283,
                78.324,
                78.364,
                78.405,
                78.445,
                78.486,
                78.526,
                78.567,
                78.607,
                78.648,
                78.687,
                78.728,
                78.768,
                78.808,
                78.849,
                78.889,
                78.929,
                78.968,
                79.009,
                79.048,
                79.088,
                79.128,
                79.168,
                79.208,
                79.247,
                79.287,
                79.326,
                79.366,
                79.406,
                79.445,
                79.485,
                79.524,
                79.564,
                79.603,
                79.642,
                79.682,
                79.721,
                79.76,
                79.799,
                79.838,
                79.877,
                79.917,
                79.955,
                79.994,
                80.033,
                80.072,
                80.111,
                80.15,
                80.188,
                80.227,
                80.266,
                80.304,
                80.343,
                80.382,
                80.42,
                80.459,
                80.497,
                80.535,
                80.574,
                80.613,
                80.651,
                80.689,
                80.727,
                80.766,
                80.803,
                80.842,
                80.88,
                80.918,
                80.956,
                80.994,
                81.032,
                81.07,
                81.108,
                81.146,
                81.184,
                81.222,
                81.259,
                81.297,
                81.335,
                81.373,
                81.41,
                81.447,
                81.485,
                81.523,
                81.56,
                81.597,
                81.635,
                81.673,
                81.71,
                81.747,
                81.784,
                81.822,
                81.859,
                81.897,
                81.933,
                81.97,
                82.008,
                82.045,
                82.082,
                82.119,
                82.156,
                82.193,
                82.23,
                82.267,
                82.304,
                82.34,
                82.377,
                82.414,
                82.451,
                82.488,
                82.525,
                82.561,
                82.598,
                82.634,
                82.671,
                82.708,
                82.744,
                82.781,
                82.817,
                82.853,
                82.89,
                82.926,
                82.963,
                82.999,
                83.036,
                83.071,
                83.108,
                83.144,
                83.18,
                83.217,
                83.253,
                83.289,
                83.325,
                83.361,
                83.397,
                83.433,
                83.469,
                83.505,
                83.541,
                83.577,
                83.613,
                83.649,
                83.684,
                83.72,
                83.756,
                83.792,
                83.827,
                83.863,
                83.898,
                83.934,
                83.969,
                84.005,
                84.041,
                84.076,
                84.112,
                84.147,
                84.183,
                84.218,
                84.253,
                84.289,
                84.324,
                84.359,
                84.395,
                84.429,
                84.464,
                84.499,
                84.535,
                84.57,
                84.605,
                84.64,
                84.675,
                84.71,
                84.745,
                84.78,
                84.815,
                84.85,
                84.885,
                84.919,
                84.954,
                84.989,
                85.024,
                85.058,
                85.093,
                85.128,
                85.162,
                85.197,
                85.232,
                85.266,
                85.301,
                85.335,
                85.37,
                85.404,
                85.438,
                85.473,
                85.507,
                85.541,
                85.576,
                85.61,
                85.644,
                85.678,
                85.712,
                85.747,
                85.781,
                85.815,
                85.849,
                85.883,
                85.917,
                85.951,
                85.985,
                86.019,
                86.053,
                86.087,
                86.121,
                86.155,
                86.189,
                86.222,
                86.256,
                86.29,
                86.323,
                86.357,
                86.391,
                86.424,
                86.458,
                86.491,
                86.525,
                86.558,
                86.592,
                86.625,
                86.658,
                86.693,
                86.726,
                86.759,
                86.793,
                86.826,
                86.859,
                86.892,
                86.926,
                86.959,
                86.992,
                87.025,
                87.058,
                87.091,
                87.124,
                87.158,
                87.191,
                87.224,
                87.257,
                87.29,
                87.323,
                87.356,
                87.389,
                87.421,
                87.454,
                87.487,
                87.521,
                87.553,
                87.586,
                87.619,
                87.651,
                87.684,
                87.717,
                87.749,
                87.782,
                87.814,
                87.848,
                87.88,
                87.913,
                87.945,
                87.978,
                88.01,
                88.042,
                88.075,
                88.107,
                88.14,
                88.172,
                88.204,
                88.237,
                88.269,
                88.301,
                88.333,
                88.365,
                88.397,
                88.43,
                88.462,
                88.494,
                88.527,
                88.558,
                88.59,
                88.622,
                88.654,
                88.687,
                88.719,
                88.751,
                88.783,
                88.814,
                88.846,
                88.878,
                88.91,
                88.942,
                88.974,
                89.006,
                89.037,
                89.069,
                89.1,
                89.132,
                89.164,
                89.196,
                89.227,
                89.259,
                89.29,
                89.322,
                89.353,
                89.386,
                89.417,
                89.448,
                89.48,
                89.511,
                89.542,
                89.574,
                89.606,
                89.637,
                89.668,
                89.699,
                89.731,
                89.762,
                89.793,
                89.825,
                89.856,
                89.887,
                89.918,
                89.949,
                89.98,
                90.011,
                90.043,
                90.074,
                90.105,
                90.136,
                90.167,
                90.198,
                90.229,
                90.261,
                90.291,
                90.322,
                90.353,
                90.384,
                90.415,
                90.445,
                90.477,
                90.508,
                90.538,
                90.569,
                90.6,
                90.63,
                90.661,
                90.692,
                90.723,
                90.754,
                90.784,
                90.815,
                90.845,
                90.876,
                90.907,
                90.937,
                90.968,
                90.298,
                90.329,
                90.359,
                90.389,
                90.42,
                90.451,
                90.481,
                90.511,
                90.541,
                90.572,
                90.602,
                90.633,
                90.663,
                90.693,
                90.723,
                90.753,
                90.783,
                90.813,
                90.843,
                90.874,
                90.904,
                90.934,
                90.964,
                90.994,
                91.024,
                91.054,
                91.084,
                91.114,
                91.144,
                91.174,
                91.203,
                91.233,
                91.263,
                91.292,
                91.323,
                91.352,
                91.382,
                91.412,
                91.441,
                91.471,
                91.5,
                91.53,
                91.56,
                91.589,
                91.619,
                91.648,
                91.677,
                91.707,
                91.736,
                91.766,
                91.795,
                91.825,
                91.854,
                91.883,
                91.912,
                91.941,
                91.97,
                91.999,
                92.029,
                92.058,
                92.087,
                92.116,
                92.145,
                92.174,
                92.203,
                92.232,
                92.261,
                92.291,
                92.32,
                92.348,
                92.377,
                92.406,
                92.435,
                92.463,
                92.492,
                92.521,
                92.549,
                92.579,
                92.607,
                92.636,
                92.665,
                92.693,
                92.722,
                92.75,
                92.778,
                92.807,
                92.835,
                92.864,
                92.893,
                92.921,
                92.95,
                92.978,
                93.006,
                93.035,
                93.063,
                93.091,
                93.119,
                93.147,
                93.175,
                93.204,
                93.233,
                93.261,
                93.289,
                93.317,
                93.345,
                93.373,
                93.401,
                93.429,
                93.456,
                93.484,
                93.512,
                93.54,
                93.568,
                93.596,
                93.623,
                93.652,
                93.68,
                93.707,
                93.735,
                93.763,
                93.791,
                93.818,
                93.846,
                93.873,
                93.901,
                93.928,
                93.956,
                93.983,
                94.011,
                94.038,
                94.065,
                94.093,
                94.12,
                94.147,
                94.175,
                94.202,
                94.229,
                94.257,
                94.285,
                94.312,
                94.339,
                94.366,
                94.393,
                94.42,
                94.447,
                94.474,
                94.501,
                94.528,
                94.555,
                94.582,
                94.609,
                94.636,
                94.663,
                94.69,
                94.717,
                94.743,
                94.77,
                94.797,
                94.824,
                94.85,
                94.877,
                94.904,
                94.93,
                94.957,
                94.984,
                95.01,
                95.037,
                95.063,
                95.09,
                95.116,
                95.143,
                95.169,
                95.196,
                95.222,
                95.249,
                95.275,
                95.301,
                95.327,
                95.354,
                95.38,
                95.406,
                95.433,
                95.459,
                95.485,
                95.511,
                95.537,
                95.563,
                95.59,
                95.616,
                95.642,
                95.667,
                95.693,
                95.719,
                95.745,
                95.771,
                95.797,
                95.823,
                95.848,
                95.874,
                95.9,
                95.926,
                95.952,
                95.978,
                96.003,
                96.029,
                96.055,
                96.081,
                96.106,
                96.132,
                96.158,
                96.182,
                96.208,
                96.233,
                96.259,
                96.285,
                96.31,
                96.336,
                96.361,
                96.387,
                96.412,
                96.437,
                96.463,
                96.488,
                96.514,
                96.538,
                96.563,
                96.589,
                96.614,
                96.639,
                96.664,
                96.69,
                96.715,
                96.74,
                96.765,
                96.79,
                96.815,
                96.84,
                96.865,
                96.89,
                96.915,
                96.94,
                96.965,
                96.99,
                97.015,
                97.04,
                97.064,
                97.089,
                97.114,
                97.139,
                97.164,
                97.189,
                97.213,
                97.238,
                97.262,
                97.287,
                97.312,
                97.336,
                97.361,
                97.386,
                97.411,
                97.435,
                97.459,
                97.484,
                97.508,
                97.533,
                97.558,
                97.582,
                97.607,
                97.63,
                97.655,
                97.679,
                97.704,
                97.729,
                97.753,
                97.778,
                97.801,
                97.825,
                97.85,
                97.874,
                97.899,
                97.923,
                97.947,
                97.971,
                97.995,
                98.02,
                98.044,
                98.068,
                98.092,
                98.116,
                98.14,
                98.164,
                98.189,
                98.212,
                98.236,
                98.26,
                98.285,
                98.309,
                98.333,
                98.356,
                98.38,
                98.404,
                98.428,
                98.452,
                98.476,
                98.5,
                98.524,
                98.548,
                98.572,
                98.595,
                98.619,
                98.643,
                98.667,
                98.691,
                98.714,
                98.738,
                98.762,
                98.786,
                98.809,
                98.832,
                98.856,
                98.88,
                98.904,
                98.927,
                98.951,
                98.975,
                98.998,
                99.021,
                99.045,
                99.069,
                99.093,
                99.116,
                99.139,
                99.163,
                99.187,
                99.21,
                99.233,
                99.257,
                99.28,
                99.304,
                99.327,
                99.35,
                99.374,
                99.398,
                99.42,
                99.444,
                99.468,
                99.491,
                99.514,
                99.537,
                99.561,
                99.584,
                99.607,
                99.63,
                99.654,
                99.676,
                99.7,
                99.723,
                99.747,
                99.769,
                99.793,
                99.816,
                99.84,
                99.862,
                99.885,
                99.909,
                99.931,
                99.954,
                99.978,
                100.001,
                100.024,
                100.047,
                100.07,
                100.092,
                100.116,
                100.139,
                100.162,
                100.185,
                100.208,
                100.231,
                100.253,
                100.277,
                100.3,
                100.322,
                100.345,
                100.368,
                100.392,
                100.414,
                100.437,
                100.46,
                100.482,
                100.505,
                100.528,
                100.55,
                100.573,
                100.596,
                100.619,
                100.642,
                100.665,
                100.687,
                100.71,
                100.733,
                100.755,
                100.778,
                100.801,
                100.823,
                100.846,
                100.869,
                100.891,
                100.914,
                100.937,
                100.958,
                100.981,
                101.004,
                101.026,
                101.049,
                101.072,
                101.094,
                101.117,
                101.14,
                101.161,
                101.184,
                101.207,
                101.229,
                101.252,
                101.274,
                101.296,
                101.319,
                101.342,
                101.363,
                101.386,
                101.408,
                101.431,
                101.454,
                101.475,
                101.498,
                101.521,
                101.542,
                101.565,
                101.588,
                101.609,
                101.632,
                101.653,
                101.676,
                101.699,
                101.72,
                101.743,
                101.766,
                101.787,
                101.81,
                101.831,
                101.854,
                101.876,
                101.898,
                101.92,
                101.942,
                101.964,
                101.987,
                102.008,
                102.031,
                102.052,
                102.075,
                102.097,
                102.119,
                102.141,
                102.163,
                102.185,
                102.207,
                102.229,
                102.251,
                102.273,
                102.295,
                102.317,
                102.338,
                102.361,
                102.382,
                102.404,
                102.427,
                102.448,
                102.47,
                102.491,
                102.514,
                102.536,
                102.557,
                102.579,
                102.601,
                102.623,
                102.645,
                102.666,
                102.688,
                102.71,
                102.732,
                102.753,
                102.775,
                102.797,
                102.818,
                102.84,
                102.861,
                102.884,
                102.905,
                102.927,
                102.949,
                102.97,
                102.992,
                103.013,
                103.035,
                103.056,
                103.078,
                103.1,
                103.121,
                103.143,
                103.164,
                103.186,
                103.207,
                103.229,
                103.25,
                103.272,
                103.293,
                103.314,
                103.336,
                103.357,
                103.379,
                103.4,
                103.422,
                103.442,
                103.464,
                103.485,
                103.507,
                103.529,
                103.55,
                103.571,
                103.592,
                103.614,
                103.634,
                103.656,
                103.677,
                103.699,
                103.719,
                103.741,
                103.763,
                103.783,
                103.805,
                103.826,
                103.847,
                103.868,
                103.89,
                103.91,
                103.932,
                103.952,
                103.974,
                103.994,
                104.016,
                104.037,
                104.058,
                104.079,
                104.1,
                104.122,
                104.142,
                104.164,
                104.184,
                104.206,
                104.226,
                104.248,
                104.268,
                104.29,
                104.31,
                104.331,
                104.352,
                104.373,
                104.394,
                104.415,
                104.435,
                104.457,
                104.477,
                104.498,
                104.519,
                104.54,
                104.56,
                104.582,
                104.602,
                104.623,
                104.644,
                104.665,
                104.685,
                104.707,
                104.728,
                104.748,
                104.769,
                104.789,
                104.811,
                104.831,
                104.852,
                104.872,
                104.893,
                104.914,
                104.935,
                104.955,
                104.976,
                104.996,
                105.017,
                105.038,
                105.059,
                105.079,
                105.1,
                105.12,
                105.141,
                105.161,
                105.182,
                105.202,
                105.223,
                105.243,
                105.264,
                105.284,
                105.305,
                105.325,
                105.345,
                105.366,
                105.386,
                105.407,
                105.427,
                105.448,
                105.468,
                105.489,
                105.509,
                105.53,
                105.55,
                105.571,
                105.591,
                105.611,
                105.631,
                105.652,
                105.672,
                105.693,
                105.713,
                105.734,
                105.753,
                105.774,
                105.794,
                105.815,
                105.835,
                105.856,
                105.875,
                105.896,
                105.916,
                105.937,
                105.956,
                105.977,
                105.997,
                106.017,
                106.037,
                106.057,
                106.078,
                106.098,
                106.118,
                106.138,
                106.159,
                106.178,
                106.199,
                106.219,
                106.239,
                106.259,
                106.28,
                106.299,
                106.32,
                106.34,
                106.36,
                106.38,
                106.401,
                106.42,
                106.44,
                106.46,
                106.48,
                106.501,
                106.52,
                106.541,
                106.56,
                106.581,
                106.6,
                106.621,
                106.64,
                106.661,
                106.681,
                106.701,
                106.721,
                106.74,
                106.761,
                106.78,
                106.801,
                106.82,
                106.841,
                106.86,
                106.881,
                106.9,
                106.921,
                106.94,
                106.961,
                106.98,
                107,
                107.02,
                107.039,
                107.06,
                107.079,
                107.1,
                107.119,
                107.14,
                107.159,
                107.179,
                107.199,
                107.219,
                107.239,
                107.259,
                107.278,
                107.298,
                107.318,
                107.337,
                107.358,
                107.377,
                107.398,
                107.417,
                107.437,
                107.457,
                107.477,
                107.496,
                107.516,
                107.536,
                107.555,
                107.576,
                107.595,
                107.615,
                107.635,
                107.655,
                107.674,
                107.695,
                107.714,
                107.733,
                107.753,
                107.773,
                107.793,
                107.812,
                107.832,
                107.852,
                107.872,
                107.891,
                107.912,
                107.931,
                107.95,
                107.97,
                107.99,
                108.01,
                108.029,
                108.049,
                108.069,
                108.089,
                108.108,
                108.127,
                108.147,
                108.167,
                108.187,
                108.206,
                108.226,
                108.246,
                108.266,
                108.285,
                108.304,
                108.324,
                108.343,
                108.364,
                108.383,
                108.403,
                108.422,
                108.443,
                108.462,
                108.482,
                108.501,
                108.52,
                108.54,
                108.56,
                108.58,
                108.599,
                108.619,
                108.638,
                108.658,
                108.678,
                108.697,
                108.717,
                108.736,
                108.756,
                108.775,
                108.795,
                108.815,
                108.835,
                108.854,
                108.873,
                108.893,
                108.912,
                108.932,
                108.951,
                108.972,
                108.991,
                109.01,
                109.03,
                109.049,
                109.069,
                109.088,
                109.108,
                109.127,
                109.148,
                109.167,
                109.186,
                109.206,
                109.225,
                109.245,
                109.264,
                109.284,
                109.303,
                109.324,
                109.343,
                109.362,
                109.382,
                109.401,
                109.421,
                109.44,
                109.46,
                109.479,
                109.498,
                109.518,
                109.537,
                109.558,
                109.577,
                109.597,
                109.616,
                109.636,
                109.655,
                109.674,
                109.694,
                109.713,
                109.733,
                109.752,
                109.772,
                109.791,
                109.81,
                109.831,
                109.85,
                109.87,
                109.889,
                109.909,
                109.928,
                109.948,
                109.967,
                109.986,
                110.006,
                110.025,
                110.045,
                110.064,
                110.084,
                110.103,
                110.122,
                110.142,
                110.161,
                110.181,
                110.2,
                110.221,
                110.24,
                110.258,
                110.279,
                110.298,
                110.318,
                110.337,
                110.357,
                110.376,
                110.395,
                110.415,
                110.434,
                110.454,
                110.473,
                110.493,
                110.512,
                110.531,
                110.551,
                110.57,
                110.59,
                110.609,
                110.629,
                110.648,
                110.668,
                110.687,
                110.706,
                110.726,
                110.745,
                110.765,
                110.784,
                110.804,
                110.823,
                110.842,
                110.862,
                110.881,
                110.901,
                110.92,
                110.939,
                110.959,
                110.978,
                110.998,
                111.017,
                111.037,
                111.056,
                111.075,
                111.095,
                111.114,
                111.134,
                111.153,
                111.173,
                111.192,
                111.211,
                111.231,
                111.25,
                111.27,
                111.289,
                111.309,
                111.328,
                111.347,
                111.367,
                111.385,
                111.406,
                111.424,
                111.445,
                111.463,
                111.482,
                111.502,
                111.521,
                111.541,
                111.56,
                111.58,
                111.599,
                111.618,
                111.638,
                111.657,
                111.677,
                111.696,
                111.715,
                111.735,
                111.754,
                111.774,
                111.793,
                111.813,
                111.831,
                111.85,
                111.87,
                111.889,
                111.909,
                111.928,
                111.947,
                111.967,
                111.986,
                112.006,
                112.025,
                112.045,
                112.064,
                112.083,
                112.103,
                112.122,
                112.142,
                112.16,
                112.18,
                112.199,
                112.218,
                112.238,
                112.257,
                112.277,
                112.296,
                112.315,
                112.335,
                112.354,
                112.374,
                112.392,
                112.412,
                112.431,
                112.45,
                112.47,
                112.489,
                112.509,
                112.528,
                112.547,
                112.567,
                112.586,
                112.606,
                112.624,
                112.643,
                112.663,
                112.682,
                112.702,
                112.721,
                112.741,
                112.76,
                112.779,
                112.799,
                112.817,
                112.837,
                112.856,
                112.875,
                112.895,
                112.914,
                112.934,
                112.953,
                112.972,
                112.991,
                113.01,
                113.03,
                113.049,
                113.069,
                113.088,
                113.107,
                113.127,
                113.146,
                113.165,
                113.184,
                113.203,
                113.223,
                113.242,
                113.262,
                113.281,
                113.299,
                113.319,
                113.338,
                113.358,
                113.377,
                113.396,
                113.416,
                113.435,
                113.455,
                113.473,
                113.493,
                113.512,
                113.531,
                113.551,
                113.57,
                113.59,
                113.608,
                113.627,
                113.647,
                113.666,
                113.686,
                113.705,
                113.724,
                113.743,
                113.762,
                113.782,
                113.801,
                113.82,
                113.84,
                113.859,
                113.878,
                113.897,
                113.916,
                113.936,
                113.955,
                113.975,
                113.993,
                114.012,
                114.032,
                114.051,
                114.071,
                114.09,
                114.108,
                114.128,
                114.147,
                114.167,
                114.186,
                114.204,
                114.224,
                114.243,
                114.263,
                114.282,
                114.301,
                114.32,
                114.339,
                114.359,
                114.378,
                114.397,
                114.417,
                114.435,
                114.455,
                114.474,
                114.493,
                114.513,
                114.531,
                114.551,
                114.57,
                114.589,
                114.609,
                114.627,
                114.647,
                114.666,
                114.685,
                114.705,
                114.723,
                114.743,
                114.762,
                114.781,
                114.8,
                114.819,
                114.839,
                114.858,
                114.876,
                114.896,
                114.915,
                114.935,
                114.954,
                114.972,
                114.992,
                115.011,
                115.031,
                115.049,
                115.068,
                115.088,
                115.107,
                115.127,
                115.145,
                115.164,
                115.184,
                115.202,
                115.221,
                115.241,
                115.26,
                115.279,
                115.298,
                115.317,
                115.337
            ],
            P90: [
                52.31,
                52.488,
                52.666,
                52.844,
                53.021,
                53.199,
                53.377,
                53.554,
                53.732,
                53.909,
                54.087,
                54.264,
                54.441,
                54.619,
                54.796,
                54.95,
                55.104,
                55.257,
                55.409,
                55.561,
                55.711,
                55.86,
                56.008,
                56.155,
                56.302,
                56.447,
                56.591,
                56.734,
                56.876,
                57.018,
                57.158,
                57.297,
                57.435,
                57.573,
                57.709,
                57.844,
                57.977,
                58.11,
                58.242,
                58.373,
                58.502,
                58.632,
                58.759,
                58.885,
                59.011,
                59.136,
                59.259,
                59.382,
                59.504,
                59.624,
                59.743,
                59.862,
                59.98,
                60.097,
                60.213,
                60.328,
                60.442,
                60.556,
                60.668,
                60.781,
                60.892,
                61.002,
                61.112,
                61.221,
                61.33,
                61.438,
                61.545,
                61.652,
                61.758,
                61.864,
                61.969,
                62.072,
                62.176,
                62.279,
                62.382,
                62.482,
                62.583,
                62.684,
                62.783,
                62.882,
                62.981,
                63.079,
                63.175,
                63.272,
                63.368,
                63.463,
                63.557,
                63.651,
                63.745,
                63.837,
                63.929,
                64.021,
                64.111,
                64.201,
                64.292,
                64.38,
                64.469,
                64.557,
                64.644,
                64.731,
                64.817,
                64.902,
                64.988,
                65.071,
                65.156,
                65.239,
                65.321,
                65.404,
                65.485,
                65.566,
                65.647,
                65.727,
                65.806,
                65.885,
                65.963,
                66.041,
                66.118,
                66.195,
                66.272,
                66.347,
                66.423,
                66.497,
                66.571,
                66.645,
                66.718,
                66.791,
                66.863,
                66.935,
                67.006,
                67.077,
                67.147,
                67.217,
                67.288,
                67.357,
                67.425,
                67.493,
                67.561,
                67.629,
                67.696,
                67.762,
                67.828,
                67.895,
                67.96,
                68.025,
                68.091,
                68.155,
                68.22,
                68.283,
                68.346,
                68.41,
                68.472,
                68.535,
                68.597,
                68.659,
                68.72,
                68.782,
                68.842,
                68.903,
                68.964,
                69.023,
                69.083,
                69.142,
                69.201,
                69.261,
                69.319,
                69.377,
                69.436,
                69.493,
                69.55,
                69.608,
                69.665,
                69.722,
                69.778,
                69.835,
                69.891,
                69.947,
                70.003,
                70.058,
                70.113,
                70.168,
                70.223,
                70.278,
                70.333,
                70.387,
                70.441,
                70.495,
                70.549,
                70.603,
                70.656,
                70.71,
                70.763,
                70.815,
                70.868,
                70.921,
                70.973,
                71.025,
                71.077,
                71.13,
                71.182,
                71.234,
                71.285,
                71.336,
                71.387,
                71.439,
                71.49,
                71.541,
                71.591,
                71.643,
                71.693,
                71.743,
                71.793,
                71.844,
                71.894,
                71.944,
                71.994,
                72.044,
                72.093,
                72.143,
                72.192,
                72.242,
                72.291,
                72.34,
                72.39,
                72.438,
                72.488,
                72.536,
                72.585,
                72.634,
                72.683,
                72.731,
                72.78,
                72.827,
                72.876,
                72.924,
                72.972,
                73.02,
                73.068,
                73.116,
                73.164,
                73.212,
                73.259,
                73.307,
                73.355,
                73.402,
                73.45,
                73.497,
                73.544,
                73.592,
                73.638,
                73.686,
                73.733,
                73.781,
                73.827,
                73.874,
                73.921,
                73.968,
                74.014,
                74.061,
                74.108,
                74.155,
                74.201,
                74.247,
                74.294,
                74.34,
                74.387,
                74.432,
                74.479,
                74.525,
                74.571,
                74.617,
                74.664,
                74.71,
                74.756,
                74.801,
                74.846,
                74.892,
                74.938,
                74.984,
                75.029,
                75.075,
                75.12,
                75.166,
                75.211,
                75.256,
                75.301,
                75.346,
                75.392,
                75.437,
                75.482,
                75.526,
                75.571,
                75.616,
                75.661,
                75.705,
                75.75,
                75.794,
                75.839,
                75.883,
                75.927,
                75.973,
                76.017,
                76.061,
                76.105,
                76.149,
                76.193,
                76.237,
                76.281,
                76.325,
                76.369,
                76.413,
                76.456,
                76.5,
                76.543,
                76.587,
                76.631,
                76.674,
                76.717,
                76.76,
                76.804,
                76.847,
                76.89,
                76.933,
                76.977,
                77.02,
                77.063,
                77.105,
                77.149,
                77.191,
                77.234,
                77.277,
                77.32,
                77.362,
                77.404,
                77.448,
                77.49,
                77.532,
                77.575,
                77.617,
                77.659,
                77.702,
                77.744,
                77.785,
                77.828,
                77.87,
                77.911,
                77.954,
                77.996,
                78.038,
                78.079,
                78.121,
                78.163,
                78.205,
                78.247,
                78.288,
                78.329,
                78.371,
                78.412,
                78.454,
                78.495,
                78.537,
                78.578,
                78.62,
                78.661,
                78.702,
                78.743,
                78.784,
                78.826,
                78.866,
                78.908,
                78.948,
                78.99,
                79.03,
                79.071,
                79.112,
                79.153,
                79.193,
                79.234,
                79.275,
                79.316,
                79.356,
                79.397,
                79.438,
                79.478,
                79.518,
                79.558,
                79.599,
                79.639,
                79.68,
                79.719,
                79.76,
                79.8,
                79.84,
                79.88,
                79.92,
                79.96,
                80.001,
                80.04,
                80.08,
                80.119,
                80.16,
                80.2,
                80.239,
                80.279,
                80.319,
                80.358,
                80.398,
                80.437,
                80.477,
                80.516,
                80.555,
                80.595,
                80.634,
                80.673,
                80.713,
                80.752,
                80.791,
                80.83,
                80.87,
                80.908,
                80.947,
                80.987,
                81.025,
                81.064,
                81.103,
                81.142,
                81.181,
                81.22,
                81.259,
                81.297,
                81.336,
                81.375,
                81.413,
                81.451,
                81.49,
                81.529,
                81.567,
                81.605,
                81.644,
                81.683,
                81.72,
                81.759,
                81.797,
                81.836,
                81.873,
                81.912,
                81.95,
                81.988,
                82.026,
                82.064,
                82.102,
                82.14,
                82.177,
                82.216,
                82.254,
                82.292,
                82.33,
                82.367,
                82.405,
                82.443,
                82.48,
                82.518,
                82.555,
                82.593,
                82.631,
                82.668,
                82.706,
                82.743,
                82.78,
                82.818,
                82.856,
                82.893,
                82.931,
                82.967,
                83.005,
                83.042,
                83.079,
                83.117,
                83.154,
                83.19,
                83.228,
                83.265,
                83.302,
                83.339,
                83.376,
                83.413,
                83.449,
                83.486,
                83.523,
                83.56,
                83.597,
                83.634,
                83.671,
                83.707,
                83.744,
                83.781,
                83.817,
                83.854,
                83.891,
                83.927,
                83.964,
                84.001,
                84.036,
                84.073,
                84.109,
                84.146,
                84.182,
                84.219,
                84.255,
                84.292,
                84.328,
                84.364,
                84.4,
                84.437,
                84.472,
                84.508,
                84.544,
                84.58,
                84.616,
                84.653,
                84.689,
                84.725,
                84.761,
                84.796,
                84.832,
                84.868,
                84.904,
                84.94,
                84.976,
                85.012,
                85.047,
                85.082,
                85.118,
                85.153,
                85.189,
                85.225,
                85.26,
                85.296,
                85.331,
                85.367,
                85.402,
                85.437,
                85.473,
                85.508,
                85.543,
                85.579,
                85.614,
                85.649,
                85.684,
                85.72,
                85.755,
                85.79,
                85.825,
                85.86,
                85.895,
                85.93,
                85.965,
                86,
                86.035,
                86.07,
                86.105,
                86.139,
                86.174,
                86.209,
                86.244,
                86.278,
                86.313,
                86.348,
                86.382,
                86.417,
                86.452,
                86.486,
                86.521,
                86.555,
                86.59,
                86.624,
                86.658,
                86.694,
                86.728,
                86.762,
                86.797,
                86.831,
                86.865,
                86.899,
                86.934,
                86.968,
                87.002,
                87.036,
                87.07,
                87.104,
                87.138,
                87.172,
                87.206,
                87.24,
                87.274,
                87.308,
                87.342,
                87.376,
                87.41,
                87.444,
                87.478,
                87.512,
                87.545,
                87.579,
                87.613,
                87.646,
                87.68,
                87.713,
                87.747,
                87.78,
                87.814,
                87.849,
                87.882,
                87.915,
                87.949,
                87.982,
                88.016,
                88.049,
                88.082,
                88.116,
                88.149,
                88.182,
                88.216,
                88.25,
                88.283,
                88.316,
                88.349,
                88.382,
                88.415,
                88.448,
                88.481,
                88.514,
                88.548,
                88.581,
                88.614,
                88.647,
                88.68,
                88.713,
                88.746,
                88.778,
                88.811,
                88.845,
                88.878,
                88.91,
                88.943,
                88.976,
                89.008,
                89.041,
                89.073,
                89.106,
                89.14,
                89.172,
                89.205,
                89.237,
                89.27,
                89.302,
                89.334,
                89.367,
                89.4,
                89.432,
                89.465,
                89.497,
                89.529,
                89.562,
                89.594,
                89.627,
                89.659,
                89.691,
                89.723,
                89.756,
                89.788,
                89.82,
                89.852,
                89.885,
                89.917,
                89.949,
                89.981,
                90.013,
                90.045,
                90.077,
                90.109,
                90.141,
                90.173,
                90.205,
                90.237,
                90.269,
                90.3,
                90.333,
                90.365,
                90.397,
                90.428,
                90.46,
                90.491,
                90.523,
                90.556,
                90.587,
                90.619,
                90.65,
                90.682,
                90.713,
                90.745,
                90.778,
                90.809,
                90.84,
                90.872,
                90.903,
                90.934,
                90.966,
                90.998,
                91.03,
                91.061,
                91.092,
                91.123,
                91.154,
                91.186,
                91.218,
                91.249,
                91.28,
                91.311,
                91.343,
                91.374,
                91.405,
                91.437,
                91.468,
                91.499,
                91.53,
                91.561,
                91.592,
                91.623,
                91.655,
                91.686,
                91.716,
                91.047,
                91.078,
                91.109,
                91.14,
                91.172,
                91.202,
                91.233,
                91.264,
                91.294,
                91.325,
                91.356,
                91.388,
                91.418,
                91.449,
                91.479,
                91.51,
                91.54,
                91.571,
                91.601,
                91.633,
                91.663,
                91.694,
                91.724,
                91.754,
                91.785,
                91.815,
                91.846,
                91.876,
                91.907,
                91.937,
                91.967,
                91.997,
                92.027,
                92.058,
                92.089,
                92.119,
                92.149,
                92.179,
                92.209,
                92.239,
                92.269,
                92.3,
                92.33,
                92.359,
                92.389,
                92.419,
                92.449,
                92.479,
                92.508,
                92.539,
                92.569,
                92.599,
                92.628,
                92.658,
                92.687,
                92.717,
                92.747,
                92.776,
                92.807,
                92.836,
                92.866,
                92.895,
                92.925,
                92.954,
                92.983,
                93.013,
                93.042,
                93.072,
                93.102,
                93.131,
                93.16,
                93.189,
                93.219,
                93.248,
                93.277,
                93.306,
                93.335,
                93.365,
                93.394,
                93.423,
                93.452,
                93.481,
                93.51,
                93.539,
                93.568,
                93.597,
                93.626,
                93.655,
                93.685,
                93.714,
                93.742,
                93.771,
                93.8,
                93.829,
                93.857,
                93.886,
                93.915,
                93.943,
                93.972,
                94,
                94.03,
                94.059,
                94.087,
                94.115,
                94.144,
                94.172,
                94.201,
                94.229,
                94.257,
                94.286,
                94.314,
                94.342,
                94.371,
                94.399,
                94.427,
                94.456,
                94.485,
                94.513,
                94.541,
                94.569,
                94.597,
                94.625,
                94.653,
                94.681,
                94.709,
                94.737,
                94.765,
                94.793,
                94.821,
                94.849,
                94.877,
                94.904,
                94.932,
                94.96,
                94.988,
                95.015,
                95.043,
                95.072,
                95.1,
                95.127,
                95.155,
                95.182,
                95.21,
                95.238,
                95.265,
                95.293,
                95.32,
                95.347,
                95.375,
                95.402,
                95.43,
                95.457,
                95.484,
                95.511,
                95.539,
                95.566,
                95.593,
                95.62,
                95.648,
                95.675,
                95.702,
                95.729,
                95.756,
                95.783,
                95.81,
                95.837,
                95.864,
                95.891,
                95.918,
                95.945,
                95.972,
                95.999,
                96.026,
                96.053,
                96.08,
                96.106,
                96.133,
                96.16,
                96.187,
                96.213,
                96.24,
                96.267,
                96.293,
                96.32,
                96.347,
                96.373,
                96.4,
                96.426,
                96.453,
                96.479,
                96.505,
                96.531,
                96.558,
                96.584,
                96.61,
                96.637,
                96.663,
                96.69,
                96.716,
                96.742,
                96.768,
                96.795,
                96.821,
                96.847,
                96.873,
                96.9,
                96.926,
                96.952,
                96.978,
                97.004,
                97.029,
                97.055,
                97.081,
                97.107,
                97.133,
                97.159,
                97.185,
                97.211,
                97.237,
                97.263,
                97.289,
                97.314,
                97.34,
                97.366,
                97.391,
                97.416,
                97.442,
                97.468,
                97.494,
                97.519,
                97.545,
                97.571,
                97.596,
                97.622,
                97.647,
                97.672,
                97.697,
                97.723,
                97.749,
                97.774,
                97.8,
                97.825,
                97.851,
                97.876,
                97.901,
                97.926,
                97.951,
                97.976,
                98.002,
                98.027,
                98.052,
                98.077,
                98.103,
                98.127,
                98.152,
                98.177,
                98.203,
                98.228,
                98.253,
                98.278,
                98.303,
                98.327,
                98.352,
                98.377,
                98.402,
                98.427,
                98.452,
                98.478,
                98.501,
                98.526,
                98.551,
                98.576,
                98.601,
                98.626,
                98.651,
                98.675,
                98.7,
                98.725,
                98.749,
                98.774,
                98.799,
                98.823,
                98.848,
                98.872,
                98.897,
                98.922,
                98.947,
                98.97,
                98.995,
                99.02,
                99.044,
                99.069,
                99.092,
                99.117,
                99.142,
                99.166,
                99.191,
                99.216,
                99.239,
                99.264,
                99.288,
                99.313,
                99.337,
                99.36,
                99.385,
                99.41,
                99.434,
                99.458,
                99.482,
                99.506,
                99.531,
                99.555,
                99.579,
                99.603,
                99.627,
                99.651,
                99.676,
                99.699,
                99.723,
                99.748,
                99.772,
                99.796,
                99.819,
                99.844,
                99.868,
                99.892,
                99.915,
                99.939,
                99.964,
                99.988,
                100.012,
                100.035,
                100.059,
                100.083,
                100.108,
                100.13,
                100.155,
                100.179,
                100.203,
                100.226,
                100.25,
                100.274,
                100.298,
                100.321,
                100.345,
                100.369,
                100.393,
                100.416,
                100.44,
                100.464,
                100.488,
                100.51,
                100.534,
                100.558,
                100.581,
                100.605,
                100.629,
                100.653,
                100.675,
                100.699,
                100.723,
                100.747,
                100.769,
                100.793,
                100.817,
                100.84,
                100.864,
                100.887,
                100.911,
                100.934,
                100.957,
                100.981,
                101.004,
                101.027,
                101.051,
                101.075,
                101.097,
                101.121,
                101.145,
                101.167,
                101.191,
                101.214,
                101.237,
                101.26,
                101.284,
                101.308,
                101.33,
                101.354,
                101.377,
                101.4,
                101.423,
                101.447,
                101.469,
                101.493,
                101.516,
                101.538,
                101.562,
                101.585,
                101.608,
                101.631,
                101.655,
                101.677,
                101.7,
                101.724,
                101.746,
                101.769,
                101.793,
                101.815,
                101.838,
                101.862,
                101.884,
                101.907,
                101.93,
                101.952,
                101.976,
                101.999,
                102.021,
                102.044,
                102.068,
                102.09,
                102.113,
                102.136,
                102.158,
                102.182,
                102.205,
                102.227,
                102.25,
                102.273,
                102.295,
                102.318,
                102.34,
                102.363,
                102.387,
                102.408,
                102.432,
                102.455,
                102.477,
                102.5,
                102.523,
                102.545,
                102.568,
                102.59,
                102.613,
                102.636,
                102.657,
                102.681,
                102.704,
                102.725,
                102.748,
                102.77,
                102.793,
                102.816,
                102.838,
                102.861,
                102.882,
                102.905,
                102.928,
                102.95,
                102.973,
                102.995,
                103.017,
                103.04,
                103.062,
                103.085,
                103.108,
                103.129,
                103.152,
                103.174,
                103.196,
                103.219,
                103.241,
                103.264,
                103.285,
                103.308,
                103.329,
                103.352,
                103.375,
                103.396,
                103.419,
                103.441,
                103.463,
                103.486,
                103.507,
                103.53,
                103.551,
                103.574,
                103.597,
                103.618,
                103.641,
                103.662,
                103.685,
                103.706,
                103.729,
                103.751,
                103.773,
                103.795,
                103.816,
                103.839,
                103.86,
                103.883,
                103.905,
                103.926,
                103.949,
                103.97,
                103.993,
                104.014,
                104.036,
                104.059,
                104.08,
                104.102,
                104.123,
                104.146,
                104.167,
                104.189,
                104.21,
                104.233,
                104.255,
                104.276,
                104.299,
                104.32,
                104.342,
                104.363,
                104.385,
                104.406,
                104.428,
                104.45,
                104.472,
                104.494,
                104.515,
                104.537,
                104.558,
                104.58,
                104.601,
                104.623,
                104.644,
                104.666,
                104.687,
                104.709,
                104.732,
                104.752,
                104.775,
                104.795,
                104.817,
                104.838,
                104.86,
                104.881,
                104.903,
                104.924,
                104.946,
                104.967,
                104.989,
                105.01,
                105.031,
                105.052,
                105.074,
                105.096,
                105.117,
                105.139,
                105.159,
                105.181,
                105.202,
                105.224,
                105.245,
                105.267,
                105.287,
                105.309,
                105.329,
                105.351,
                105.372,
                105.394,
                105.414,
                105.436,
                105.457,
                105.479,
                105.499,
                105.521,
                105.541,
                105.563,
                105.584,
                105.605,
                105.626,
                105.648,
                105.668,
                105.69,
                105.711,
                105.732,
                105.753,
                105.774,
                105.796,
                105.816,
                105.838,
                105.858,
                105.88,
                105.9,
                105.922,
                105.942,
                105.963,
                105.984,
                106.005,
                106.026,
                106.047,
                106.067,
                106.089,
                106.109,
                106.131,
                106.151,
                106.173,
                106.193,
                106.214,
                106.235,
                106.256,
                106.276,
                106.298,
                106.318,
                106.338,
                106.359,
                106.38,
                106.401,
                106.421,
                106.443,
                106.463,
                106.484,
                106.504,
                106.526,
                106.546,
                106.567,
                106.587,
                106.608,
                106.628,
                106.65,
                106.67,
                106.691,
                106.711,
                106.732,
                106.752,
                106.774,
                106.794,
                106.815,
                106.835,
                106.856,
                106.876,
                106.897,
                106.917,
                106.939,
                106.959,
                106.98,
                107,
                107.02,
                107.041,
                107.061,
                107.082,
                107.102,
                107.123,
                107.143,
                107.164,
                107.184,
                107.205,
                107.225,
                107.246,
                107.266,
                107.287,
                107.307,
                107.328,
                107.347,
                107.369,
                107.388,
                107.409,
                107.429,
                107.449,
                107.47,
                107.49,
                107.511,
                107.531,
                107.552,
                107.571,
                107.592,
                107.612,
                107.633,
                107.653,
                107.674,
                107.694,
                107.715,
                107.734,
                107.754,
                107.775,
                107.795,
                107.816,
                107.835,
                107.856,
                107.876,
                107.897,
                107.916,
                107.937,
                107.957,
                107.978,
                107.998,
                108.018,
                108.038,
                108.058,
                108.079,
                108.098,
                108.119,
                108.139,
                108.16,
                108.179,
                108.2,
                108.22,
                108.24,
                108.26,
                108.281,
                108.3,
                108.32,
                108.341,
                108.36,
                108.381,
                108.401,
                108.421,
                108.441,
                108.462,
                108.481,
                108.502,
                108.522,
                108.541,
                108.562,
                108.581,
                108.602,
                108.622,
                108.642,
                108.662,
                108.683,
                108.702,
                108.723,
                108.742,
                108.762,
                108.783,
                108.802,
                108.823,
                108.842,
                108.863,
                108.882,
                108.903,
                108.923,
                108.943,
                108.963,
                108.982,
                109.003,
                109.022,
                109.043,
                109.062,
                109.083,
                109.103,
                109.123,
                109.143,
                109.162,
                109.183,
                109.202,
                109.223,
                109.242,
                109.263,
                109.282,
                109.303,
                109.322,
                109.342,
                109.362,
                109.382,
                109.402,
                109.422,
                109.442,
                109.462,
                109.482,
                109.502,
                109.522,
                109.542,
                109.561,
                109.582,
                109.601,
                109.622,
                109.641,
                109.662,
                109.681,
                109.702,
                109.721,
                109.74,
                109.761,
                109.78,
                109.801,
                109.82,
                109.841,
                109.86,
                109.881,
                109.9,
                109.919,
                109.94,
                109.959,
                109.98,
                109.999,
                110.019,
                110.039,
                110.058,
                110.079,
                110.098,
                110.118,
                110.138,
                110.158,
                110.178,
                110.198,
                110.217,
                110.237,
                110.257,
                110.277,
                110.297,
                110.316,
                110.337,
                110.356,
                110.377,
                110.396,
                110.415,
                110.436,
                110.455,
                110.476,
                110.495,
                110.515,
                110.535,
                110.554,
                110.574,
                110.594,
                110.614,
                110.633,
                110.654,
                110.673,
                110.694,
                110.713,
                110.732,
                110.753,
                110.772,
                110.793,
                110.812,
                110.832,
                110.852,
                110.871,
                110.891,
                110.91,
                110.931,
                110.95,
                110.971,
                110.99,
                111.011,
                111.03,
                111.049,
                111.069,
                111.089,
                111.109,
                111.128,
                111.149,
                111.168,
                111.187,
                111.208,
                111.227,
                111.248,
                111.267,
                111.287,
                111.306,
                111.326,
                111.346,
                111.365,
                111.386,
                111.405,
                111.426,
                111.445,
                111.464,
                111.484,
                111.504,
                111.524,
                111.543,
                111.564,
                111.583,
                111.602,
                111.623,
                111.642,
                111.662,
                111.681,
                111.702,
                111.721,
                111.742,
                111.761,
                111.78,
                111.8,
                111.82,
                111.84,
                111.859,
                111.88,
                111.899,
                111.918,
                111.939,
                111.958,
                111.978,
                111.997,
                112.016,
                112.037,
                112.056,
                112.077,
                112.096,
                112.116,
                112.135,
                112.154,
                112.175,
                112.194,
                112.215,
                112.234,
                112.254,
                112.273,
                112.292,
                112.313,
                112.332,
                112.352,
                112.372,
                112.392,
                112.411,
                112.43,
                112.451,
                112.47,
                112.49,
                112.509,
                112.53,
                112.549,
                112.568,
                112.589,
                112.608,
                112.628,
                112.647,
                112.668,
                112.687,
                112.706,
                112.726,
                112.745,
                112.766,
                112.785,
                112.804,
                112.824,
                112.844,
                112.864,
                112.883,
                112.904,
                112.923,
                112.942,
                112.962,
                112.981,
                113.002,
                113.021,
                113.04,
                113.06,
                113.079,
                113.1,
                113.119,
                113.139,
                113.159,
                113.178,
                113.198,
                113.217,
                113.238,
                113.257,
                113.277,
                113.296,
                113.315,
                113.336,
                113.355,
                113.375,
                113.394,
                113.413,
                113.434,
                113.453,
                113.473,
                113.492,
                113.513,
                113.532,
                113.551,
                113.571,
                113.59,
                113.611,
                113.63,
                113.649,
                113.669,
                113.688,
                113.709,
                113.728,
                113.747,
                113.767,
                113.786,
                113.807,
                113.826,
                113.846,
                113.865,
                113.884,
                113.904,
                113.924,
                113.944,
                113.963,
                113.982,
                114.002,
                114.021,
                114.042,
                114.061,
                114.08,
                114.1,
                114.119,
                114.14,
                114.159,
                114.179,
                114.198,
                114.217,
                114.238,
                114.257,
                114.277,
                114.296,
                114.315,
                114.335,
                114.355,
                114.375,
                114.394,
                114.413,
                114.433,
                114.452,
                114.473,
                114.492,
                114.511,
                114.531,
                114.55,
                114.571,
                114.589,
                114.61,
                114.629,
                114.648,
                114.668,
                114.687,
                114.708,
                114.727,
                114.746,
                114.766,
                114.785,
                114.805,
                114.824,
                114.843,
                114.864,
                114.883,
                114.903,
                114.922,
                114.941,
                114.961,
                114.981,
                115.001,
                115.02,
                115.039,
                115.059,
                115.078,
                115.099,
                115.117,
                115.136,
                115.157,
                115.176,
                115.196,
                115.215,
                115.234,
                115.254,
                115.273,
                115.294,
                115.313,
                115.332,
                115.352,
                115.371,
                115.391,
                115.41,
                115.429,
                115.45,
                115.469,
                115.489,
                115.508,
                115.527,
                115.547,
                115.566,
                115.586,
                115.605,
                115.624,
                115.645,
                115.664,
                115.684,
                115.703,
                115.722,
                115.742,
                115.761,
                115.781,
                115.8,
                115.819,
                115.839,
                115.858,
                115.879,
                115.898,
                115.917,
                115.937,
                115.956,
                115.976,
                115.995,
                116.014,
                116.034,
                116.053,
                116.073,
                116.092,
                116.111,
                116.131,
                116.15,
                116.171,
                116.19,
                116.208,
                116.229,
                116.248,
                116.268,
                116.287,
                116.306,
                116.326,
                116.345,
                116.364,
                116.384,
                116.403,
                116.423,
                116.442,
                116.461,
                116.481
            ],
            P95: [
                52.998,
                53.177,
                53.355,
                53.533,
                53.711,
                53.89,
                54.068,
                54.245,
                54.424,
                54.602,
                54.78,
                54.957,
                55.135,
                55.313,
                55.491,
                55.645,
                55.8,
                55.954,
                56.107,
                56.259,
                56.41,
                56.56,
                56.709,
                56.857,
                57.004,
                57.15,
                57.295,
                57.439,
                57.582,
                57.724,
                57.865,
                58.005,
                58.144,
                58.282,
                58.418,
                58.554,
                58.689,
                58.822,
                58.955,
                59.086,
                59.216,
                59.346,
                59.474,
                59.601,
                59.728,
                59.853,
                59.977,
                60.1,
                60.223,
                60.343,
                60.464,
                60.583,
                60.702,
                60.819,
                60.936,
                61.052,
                61.166,
                61.28,
                61.393,
                61.506,
                61.618,
                61.729,
                61.84,
                61.949,
                62.058,
                62.167,
                62.275,
                62.382,
                62.489,
                62.595,
                62.701,
                62.805,
                62.909,
                63.013,
                63.116,
                63.217,
                63.318,
                63.419,
                63.52,
                63.619,
                63.718,
                63.816,
                63.914,
                64.01,
                64.107,
                64.202,
                64.297,
                64.392,
                64.486,
                64.579,
                64.671,
                64.763,
                64.854,
                64.945,
                65.036,
                65.125,
                65.214,
                65.302,
                65.39,
                65.477,
                65.564,
                65.649,
                65.735,
                65.82,
                65.904,
                65.988,
                66.071,
                66.154,
                66.236,
                66.317,
                66.398,
                66.478,
                66.558,
                66.638,
                66.716,
                66.794,
                66.872,
                66.949,
                67.026,
                67.102,
                67.178,
                67.253,
                67.328,
                67.402,
                67.475,
                67.548,
                67.621,
                67.693,
                67.765,
                67.836,
                67.907,
                67.977,
                68.048,
                68.117,
                68.186,
                68.254,
                68.322,
                68.391,
                68.458,
                68.525,
                68.591,
                68.658,
                68.724,
                68.789,
                68.855,
                68.919,
                68.985,
                69.048,
                69.112,
                69.176,
                69.238,
                69.302,
                69.364,
                69.426,
                69.488,
                69.55,
                69.61,
                69.672,
                69.733,
                69.793,
                69.853,
                69.912,
                69.972,
                70.032,
                70.09,
                70.149,
                70.207,
                70.265,
                70.323,
                70.381,
                70.439,
                70.496,
                70.552,
                70.609,
                70.666,
                70.722,
                70.778,
                70.833,
                70.889,
                70.945,
                71,
                71.055,
                71.11,
                71.165,
                71.22,
                71.274,
                71.328,
                71.382,
                71.436,
                71.489,
                71.543,
                71.596,
                71.649,
                71.702,
                71.755,
                71.807,
                71.86,
                71.913,
                71.965,
                72.017,
                72.069,
                72.12,
                72.172,
                72.224,
                72.276,
                72.327,
                72.377,
                72.429,
                72.48,
                72.53,
                72.581,
                72.632,
                72.682,
                72.732,
                72.783,
                72.833,
                72.883,
                72.934,
                72.983,
                73.034,
                73.083,
                73.132,
                73.182,
                73.231,
                73.281,
                73.33,
                73.379,
                73.428,
                73.477,
                73.526,
                73.575,
                73.623,
                73.673,
                73.721,
                73.77,
                73.817,
                73.866,
                73.914,
                73.963,
                74.011,
                74.059,
                74.107,
                74.156,
                74.203,
                74.251,
                74.298,
                74.347,
                74.395,
                74.442,
                74.489,
                74.537,
                74.585,
                74.632,
                74.68,
                74.727,
                74.775,
                74.821,
                74.868,
                74.916,
                74.963,
                75.009,
                75.056,
                75.104,
                75.151,
                75.197,
                75.243,
                75.29,
                75.337,
                75.384,
                75.43,
                75.477,
                75.523,
                75.57,
                75.615,
                75.661,
                75.708,
                75.754,
                75.8,
                75.846,
                75.892,
                75.938,
                75.984,
                76.03,
                76.076,
                76.121,
                76.167,
                76.213,
                76.258,
                76.303,
                76.349,
                76.394,
                76.439,
                76.485,
                76.53,
                76.575,
                76.62,
                76.665,
                76.709,
                76.754,
                76.8,
                76.845,
                76.889,
                76.934,
                76.978,
                77.023,
                77.067,
                77.112,
                77.157,
                77.201,
                77.245,
                77.289,
                77.333,
                77.377,
                77.422,
                77.466,
                77.51,
                77.553,
                77.597,
                77.642,
                77.685,
                77.729,
                77.772,
                77.817,
                77.86,
                77.903,
                77.946,
                77.991,
                78.034,
                78.077,
                78.121,
                78.163,
                78.206,
                78.249,
                78.293,
                78.336,
                78.378,
                78.422,
                78.464,
                78.507,
                78.55,
                78.593,
                78.635,
                78.679,
                78.721,
                78.763,
                78.806,
                78.848,
                78.891,
                78.933,
                78.975,
                79.018,
                79.06,
                79.103,
                79.144,
                79.186,
                79.229,
                79.27,
                79.313,
                79.355,
                79.397,
                79.439,
                79.481,
                79.522,
                79.564,
                79.606,
                79.647,
                79.689,
                79.731,
                79.773,
                79.814,
                79.856,
                79.897,
                79.939,
                79.98,
                80.022,
                80.062,
                80.104,
                80.145,
                80.187,
                80.227,
                80.269,
                80.31,
                80.351,
                80.392,
                80.433,
                80.474,
                80.514,
                80.556,
                80.596,
                80.637,
                80.678,
                80.718,
                80.76,
                80.8,
                80.841,
                80.882,
                80.921,
                80.962,
                81.002,
                81.043,
                81.084,
                81.123,
                81.164,
                81.205,
                81.244,
                81.285,
                81.324,
                81.365,
                81.405,
                81.444,
                81.485,
                81.525,
                81.564,
                81.605,
                81.645,
                81.684,
                81.724,
                81.764,
                81.803,
                81.843,
                81.883,
                81.922,
                81.962,
                82.002,
                82.04,
                82.08,
                82.12,
                82.159,
                82.198,
                82.237,
                82.277,
                82.315,
                82.355,
                82.394,
                82.434,
                82.472,
                82.511,
                82.551,
                82.59,
                82.628,
                82.667,
                82.707,
                82.746,
                82.784,
                82.823,
                82.862,
                82.901,
                82.939,
                82.978,
                83.016,
                83.055,
                83.093,
                83.132,
                83.17,
                83.209,
                83.248,
                83.285,
                83.324,
                83.363,
                83.401,
                83.44,
                83.477,
                83.516,
                83.554,
                83.593,
                83.631,
                83.668,
                83.706,
                83.745,
                83.783,
                83.821,
                83.859,
                83.896,
                83.934,
                83.972,
                84.01,
                84.049,
                84.086,
                84.123,
                84.161,
                84.199,
                84.237,
                84.275,
                84.313,
                84.35,
                84.387,
                84.425,
                84.462,
                84.5,
                84.538,
                84.575,
                84.613,
                84.649,
                84.687,
                84.724,
                84.762,
                84.799,
                84.836,
                84.874,
                84.911,
                84.948,
                84.984,
                85.022,
                85.059,
                85.096,
                85.133,
                85.17,
                85.207,
                85.244,
                85.281,
                85.318,
                85.355,
                85.392,
                85.428,
                85.465,
                85.502,
                85.539,
                85.575,
                85.612,
                85.649,
                85.685,
                85.722,
                85.759,
                85.795,
                85.832,
                85.869,
                85.905,
                85.942,
                85.978,
                86.014,
                86.05,
                86.086,
                86.122,
                86.158,
                86.195,
                86.231,
                86.267,
                86.303,
                86.34,
                86.376,
                86.412,
                86.448,
                86.484,
                86.52,
                86.556,
                86.592,
                86.628,
                86.664,
                86.7,
                86.735,
                86.771,
                86.807,
                86.843,
                86.879,
                86.914,
                86.95,
                86.985,
                87.021,
                87.057,
                87.092,
                87.128,
                87.163,
                87.199,
                87.234,
                87.269,
                87.305,
                87.34,
                87.375,
                87.411,
                87.446,
                87.481,
                87.516,
                87.552,
                87.587,
                87.622,
                87.657,
                87.693,
                87.728,
                87.763,
                87.798,
                87.833,
                87.868,
                87.903,
                87.938,
                87.973,
                88.008,
                88.042,
                88.077,
                88.112,
                88.146,
                88.181,
                88.216,
                88.25,
                88.285,
                88.32,
                88.354,
                88.39,
                88.424,
                88.459,
                88.493,
                88.528,
                88.562,
                88.597,
                88.631,
                88.665,
                88.699,
                88.734,
                88.768,
                88.802,
                88.836,
                88.872,
                88.906,
                88.94,
                88.974,
                89.008,
                89.042,
                89.077,
                89.111,
                89.144,
                89.178,
                89.212,
                89.248,
                89.281,
                89.315,
                89.349,
                89.383,
                89.417,
                89.45,
                89.484,
                89.518,
                89.552,
                89.587,
                89.62,
                89.654,
                89.687,
                89.721,
                89.754,
                89.788,
                89.821,
                89.855,
                89.89,
                89.923,
                89.956,
                89.99,
                90.023,
                90.057,
                90.09,
                90.123,
                90.156,
                90.191,
                90.224,
                90.257,
                90.29,
                90.324,
                90.357,
                90.39,
                90.423,
                90.457,
                90.49,
                90.523,
                90.556,
                90.589,
                90.622,
                90.655,
                90.689,
                90.722,
                90.755,
                90.788,
                90.82,
                90.853,
                90.886,
                90.919,
                90.953,
                90.985,
                91.018,
                91.051,
                91.083,
                91.116,
                91.148,
                91.182,
                91.215,
                91.247,
                91.28,
                91.312,
                91.345,
                91.377,
                91.411,
                91.443,
                91.476,
                91.508,
                91.541,
                91.573,
                91.605,
                91.639,
                91.671,
                91.703,
                91.735,
                91.768,
                91.8,
                91.832,
                91.866,
                91.898,
                91.93,
                91.962,
                91.994,
                92.026,
                92.058,
                92.091,
                92.123,
                92.155,
                92.187,
                92.219,
                92.251,
                92.283,
                92.316,
                92.348,
                92.38,
                92.412,
                92.444,
                92.475,
                92.507,
                92.54,
                92.572,
                92.604,
                92.635,
                92.667,
                92.698,
                92.73,
                92.763,
                92.795,
                92.826,
                92.158,
                92.189,
                92.221,
                92.252,
                92.285,
                92.317,
                92.348,
                92.379,
                92.411,
                92.442,
                92.473,
                92.506,
                92.537,
                92.569,
                92.6,
                92.631,
                92.662,
                92.693,
                92.725,
                92.757,
                92.788,
                92.819,
                92.85,
                92.881,
                92.912,
                92.943,
                92.976,
                93.007,
                93.037,
                93.068,
                93.099,
                93.13,
                93.161,
                93.192,
                93.224,
                93.254,
                93.285,
                93.316,
                93.347,
                93.377,
                93.408,
                93.44,
                93.47,
                93.501,
                93.531,
                93.562,
                93.592,
                93.623,
                93.653,
                93.685,
                93.715,
                93.746,
                93.776,
                93.806,
                93.837,
                93.867,
                93.897,
                93.927,
                93.959,
                93.989,
                94.019,
                94.049,
                94.08,
                94.11,
                94.14,
                94.17,
                94.2,
                94.231,
                94.261,
                94.291,
                94.321,
                94.351,
                94.381,
                94.41,
                94.44,
                94.47,
                94.5,
                94.531,
                94.561,
                94.59,
                94.62,
                94.65,
                94.679,
                94.709,
                94.739,
                94.768,
                94.798,
                94.827,
                94.858,
                94.888,
                94.917,
                94.947,
                94.976,
                95.005,
                95.035,
                95.064,
                95.093,
                95.123,
                95.152,
                95.181,
                95.212,
                95.241,
                95.27,
                95.299,
                95.328,
                95.358,
                95.387,
                95.416,
                95.445,
                95.474,
                95.503,
                95.532,
                95.561,
                95.589,
                95.618,
                95.649,
                95.678,
                95.706,
                95.735,
                95.764,
                95.793,
                95.821,
                95.85,
                95.879,
                95.907,
                95.936,
                95.965,
                95.993,
                96.022,
                96.05,
                96.079,
                96.107,
                96.136,
                96.164,
                96.193,
                96.221,
                96.249,
                96.279,
                96.307,
                96.336,
                96.364,
                96.392,
                96.421,
                96.449,
                96.477,
                96.505,
                96.533,
                96.561,
                96.589,
                96.617,
                96.645,
                96.673,
                96.701,
                96.729,
                96.757,
                96.785,
                96.813,
                96.841,
                96.869,
                96.897,
                96.924,
                96.952,
                96.98,
                97.008,
                97.035,
                97.063,
                97.091,
                97.118,
                97.146,
                97.174,
                97.201,
                97.229,
                97.256,
                97.284,
                97.311,
                97.339,
                97.366,
                97.394,
                97.421,
                97.448,
                97.476,
                97.503,
                97.531,
                97.558,
                97.585,
                97.612,
                97.64,
                97.667,
                97.694,
                97.721,
                97.747,
                97.774,
                97.801,
                97.828,
                97.855,
                97.882,
                97.909,
                97.936,
                97.963,
                97.99,
                98.017,
                98.044,
                98.071,
                98.098,
                98.125,
                98.151,
                98.178,
                98.205,
                98.232,
                98.259,
                98.284,
                98.311,
                98.337,
                98.364,
                98.39,
                98.417,
                98.444,
                98.47,
                98.497,
                98.523,
                98.55,
                98.576,
                98.603,
                98.629,
                98.654,
                98.681,
                98.707,
                98.734,
                98.76,
                98.786,
                98.813,
                98.839,
                98.865,
                98.892,
                98.918,
                98.943,
                98.969,
                98.995,
                99.021,
                99.047,
                99.073,
                99.1,
                99.126,
                99.152,
                99.178,
                99.202,
                99.228,
                99.254,
                99.28,
                99.306,
                99.332,
                99.358,
                99.384,
                99.409,
                99.434,
                99.46,
                99.486,
                99.512,
                99.538,
                99.564,
                99.59,
                99.614,
                99.64,
                99.665,
                99.691,
                99.717,
                99.742,
                99.768,
                99.792,
                99.818,
                99.843,
                99.869,
                99.895,
                99.92,
                99.946,
                99.97,
                99.995,
                100.021,
                100.046,
                100.072,
                100.097,
                100.121,
                100.147,
                100.172,
                100.198,
                100.223,
                100.249,
                100.272,
                100.298,
                100.323,
                100.349,
                100.374,
                100.398,
                100.423,
                100.448,
                100.474,
                100.499,
                100.524,
                100.548,
                100.573,
                100.598,
                100.623,
                100.649,
                100.672,
                100.697,
                100.723,
                100.748,
                100.773,
                100.796,
                100.821,
                100.847,
                100.872,
                100.897,
                100.92,
                100.945,
                100.97,
                100.995,
                101.019,
                101.044,
                101.069,
                101.094,
                101.119,
                101.142,
                101.167,
                101.192,
                101.217,
                101.24,
                101.265,
                101.29,
                101.315,
                101.34,
                101.363,
                101.388,
                101.412,
                101.437,
                101.46,
                101.485,
                101.51,
                101.535,
                101.558,
                101.583,
                101.607,
                101.632,
                101.655,
                101.68,
                101.705,
                101.729,
                101.752,
                101.777,
                101.802,
                101.826,
                101.849,
                101.874,
                101.899,
                101.922,
                101.946,
                101.971,
                101.995,
                102.018,
                102.043,
                102.067,
                102.092,
                102.115,
                102.139,
                102.164,
                102.187,
                102.211,
                102.235,
                102.26,
                102.283,
                102.307,
                102.332,
                102.354,
                102.379,
                102.403,
                102.427,
                102.45,
                102.475,
                102.499,
                102.522,
                102.546,
                102.57,
                102.593,
                102.617,
                102.641,
                102.666,
                102.689,
                102.713,
                102.737,
                102.76,
                102.784,
                102.808,
                102.831,
                102.855,
                102.879,
                102.902,
                102.926,
                102.95,
                102.972,
                102.996,
                103.021,
                103.043,
                103.067,
                103.091,
                103.114,
                103.138,
                103.162,
                103.184,
                103.208,
                103.232,
                103.255,
                103.279,
                103.303,
                103.325,
                103.349,
                103.373,
                103.395,
                103.419,
                103.443,
                103.466,
                103.49,
                103.514,
                103.536,
                103.56,
                103.584,
                103.606,
                103.63,
                103.654,
                103.676,
                103.7,
                103.722,
                103.746,
                103.77,
                103.792,
                103.816,
                103.839,
                103.861,
                103.885,
                103.909,
                103.931,
                103.955,
                103.977,
                104.001,
                104.024,
                104.046,
                104.07,
                104.094,
                104.116,
                104.14,
                104.162,
                104.185,
                104.209,
                104.231,
                104.255,
                104.276,
                104.3,
                104.324,
                104.346,
                104.369,
                104.391,
                104.415,
                104.438,
                104.46,
                104.484,
                104.507,
                104.529,
                104.553,
                104.574,
                104.598,
                104.621,
                104.643,
                104.667,
                104.688,
                104.712,
                104.734,
                104.757,
                104.78,
                104.802,
                104.826,
                104.847,
                104.871,
                104.894,
                104.916,
                104.939,
                104.961,
                104.984,
                105.007,
                105.029,
                105.052,
                105.074,
                105.097,
                105.119,
                105.142,
                105.165,
                105.187,
                105.21,
                105.232,
                105.255,
                105.276,
                105.3,
                105.323,
                105.344,
                105.367,
                105.389,
                105.412,
                105.433,
                105.457,
                105.48,
                105.501,
                105.524,
                105.546,
                105.569,
                105.59,
                105.613,
                105.635,
                105.658,
                105.681,
                105.702,
                105.725,
                105.746,
                105.769,
                105.79,
                105.813,
                105.835,
                105.858,
                105.879,
                105.902,
                105.925,
                105.946,
                105.969,
                105.99,
                106.013,
                106.034,
                106.057,
                106.078,
                106.101,
                106.122,
                106.145,
                106.168,
                106.189,
                106.212,
                106.233,
                106.255,
                106.276,
                106.299,
                106.32,
                106.343,
                106.364,
                106.387,
                106.408,
                106.43,
                106.451,
                106.474,
                106.495,
                106.518,
                106.54,
                106.561,
                106.584,
                106.605,
                106.627,
                106.648,
                106.671,
                106.692,
                106.714,
                106.735,
                106.758,
                106.779,
                106.801,
                106.822,
                106.844,
                106.865,
                106.888,
                106.909,
                106.931,
                106.952,
                106.974,
                106.995,
                107.017,
                107.038,
                107.061,
                107.081,
                107.104,
                107.124,
                107.147,
                107.169,
                107.19,
                107.212,
                107.233,
                107.255,
                107.276,
                107.298,
                107.319,
                107.341,
                107.362,
                107.384,
                107.405,
                107.427,
                107.447,
                107.47,
                107.49,
                107.513,
                107.533,
                107.555,
                107.576,
                107.598,
                107.618,
                107.641,
                107.661,
                107.683,
                107.704,
                107.726,
                107.746,
                107.768,
                107.789,
                107.809,
                107.831,
                107.852,
                107.874,
                107.894,
                107.916,
                107.937,
                107.959,
                107.979,
                108.001,
                108.022,
                108.044,
                108.064,
                108.086,
                108.106,
                108.128,
                108.149,
                108.17,
                108.191,
                108.213,
                108.233,
                108.255,
                108.275,
                108.297,
                108.317,
                108.339,
                108.359,
                108.381,
                108.402,
                108.423,
                108.444,
                108.466,
                108.486,
                108.506,
                108.528,
                108.548,
                108.57,
                108.59,
                108.612,
                108.632,
                108.654,
                108.674,
                108.696,
                108.716,
                108.737,
                108.758,
                108.779,
                108.799,
                108.821,
                108.841,
                108.863,
                108.883,
                108.905,
                108.925,
                108.945,
                108.967,
                108.987,
                109.008,
                109.028,
                109.05,
                109.07,
                109.092,
                109.112,
                109.133,
                109.153,
                109.175,
                109.195,
                109.217,
                109.237,
                109.257,
                109.278,
                109.298,
                109.32,
                109.34,
                109.361,
                109.381,
                109.403,
                109.423,
                109.444,
                109.464,
                109.486,
                109.506,
                109.527,
                109.547,
                109.567,
                109.589,
                109.608,
                109.63,
                109.65,
                109.671,
                109.691,
                109.713,
                109.733,
                109.754,
                109.774,
                109.795,
                109.815,
                109.835,
                109.857,
                109.876,
                109.898,
                109.918,
                109.939,
                109.959,
                109.98,
                110,
                110.022,
                110.041,
                110.061,
                110.083,
                110.102,
                110.124,
                110.144,
                110.165,
                110.185,
                110.206,
                110.226,
                110.247,
                110.267,
                110.287,
                110.308,
                110.328,
                110.349,
                110.369,
                110.39,
                110.41,
                110.431,
                110.451,
                110.473,
                110.492,
                110.512,
                110.533,
                110.553,
                110.574,
                110.594,
                110.615,
                110.635,
                110.656,
                110.676,
                110.696,
                110.717,
                110.737,
                110.758,
                110.778,
                110.799,
                110.819,
                110.84,
                110.86,
                110.879,
                110.901,
                110.92,
                110.942,
                110.961,
                110.983,
                111.002,
                111.023,
                111.043,
                111.064,
                111.084,
                111.104,
                111.125,
                111.144,
                111.166,
                111.185,
                111.207,
                111.226,
                111.248,
                111.267,
                111.287,
                111.308,
                111.327,
                111.349,
                111.368,
                111.39,
                111.409,
                111.43,
                111.45,
                111.47,
                111.491,
                111.51,
                111.532,
                111.551,
                111.572,
                111.592,
                111.612,
                111.633,
                111.652,
                111.674,
                111.693,
                111.714,
                111.734,
                111.755,
                111.775,
                111.794,
                111.815,
                111.835,
                111.856,
                111.876,
                111.897,
                111.916,
                111.938,
                111.957,
                111.977,
                111.998,
                112.017,
                112.039,
                112.058,
                112.079,
                112.099,
                112.118,
                112.14,
                112.159,
                112.18,
                112.2,
                112.221,
                112.241,
                112.262,
                112.281,
                112.301,
                112.322,
                112.341,
                112.363,
                112.382,
                112.403,
                112.423,
                112.442,
                112.464,
                112.483,
                112.504,
                112.524,
                112.545,
                112.564,
                112.586,
                112.605,
                112.625,
                112.646,
                112.665,
                112.686,
                112.706,
                112.727,
                112.747,
                112.766,
                112.787,
                112.807,
                112.828,
                112.847,
                112.868,
                112.888,
                112.907,
                112.929,
                112.948,
                112.969,
                112.989,
                113.01,
                113.029,
                113.049,
                113.07,
                113.089,
                113.11,
                113.13,
                113.151,
                113.17,
                113.19,
                113.211,
                113.231,
                113.252,
                113.271,
                113.292,
                113.312,
                113.333,
                113.352,
                113.372,
                113.393,
                113.412,
                113.434,
                113.453,
                113.474,
                113.493,
                113.513,
                113.534,
                113.553,
                113.575,
                113.594,
                113.613,
                113.635,
                113.654,
                113.675,
                113.695,
                113.716,
                113.735,
                113.754,
                113.776,
                113.795,
                113.816,
                113.836,
                113.857,
                113.876,
                113.895,
                113.917,
                113.936,
                113.957,
                113.977,
                113.998,
                114.017,
                114.036,
                114.058,
                114.077,
                114.098,
                114.117,
                114.139,
                114.158,
                114.177,
                114.199,
                114.218,
                114.239,
                114.258,
                114.279,
                114.299,
                114.318,
                114.339,
                114.359,
                114.38,
                114.399,
                114.419,
                114.44,
                114.459,
                114.48,
                114.5,
                114.521,
                114.54,
                114.559,
                114.58,
                114.6,
                114.621,
                114.64,
                114.66,
                114.681,
                114.7,
                114.721,
                114.741,
                114.762,
                114.781,
                114.8,
                114.821,
                114.841,
                114.862,
                114.881,
                114.902,
                114.922,
                114.941,
                114.962,
                114.981,
                115.003,
                115.022,
                115.041,
                115.062,
                115.082,
                115.103,
                115.122,
                115.143,
                115.163,
                115.182,
                115.203,
                115.222,
                115.243,
                115.263,
                115.282,
                115.303,
                115.322,
                115.343,
                115.363,
                115.382,
                115.403,
                115.423,
                115.444,
                115.463,
                115.484,
                115.503,
                115.523,
                115.544,
                115.563,
                115.584,
                115.603,
                115.623,
                115.644,
                115.663,
                115.684,
                115.703,
                115.723,
                115.744,
                115.763,
                115.784,
                115.804,
                115.825,
                115.844,
                115.863,
                115.884,
                115.904,
                115.925,
                115.944,
                115.963,
                115.984,
                116.004,
                116.025,
                116.044,
                116.063,
                116.084,
                116.104,
                116.125,
                116.144,
                116.163,
                116.184,
                116.204,
                116.225,
                116.244,
                116.265,
                116.284,
                116.303,
                116.325,
                116.344,
                116.365,
                116.384,
                116.403,
                116.424,
                116.444,
                116.465,
                116.484,
                116.503,
                116.524,
                116.544,
                116.565,
                116.584,
                116.603,
                116.624,
                116.643,
                116.665,
                116.684,
                116.703,
                116.724,
                116.743,
                116.764,
                116.784,
                116.803,
                116.824,
                116.843,
                116.864,
                116.883,
                116.903,
                116.924,
                116.943,
                116.964,
                116.983,
                117.002,
                117.023,
                117.043,
                117.064,
                117.083,
                117.102,
                117.123,
                117.142,
                117.163,
                117.183,
                117.202,
                117.223,
                117.242,
                117.263,
                117.282,
                117.301,
                117.322,
                117.342,
                117.363,
                117.382,
                117.401,
                117.422,
                117.441,
                117.462,
                117.482,
                117.501,
                117.522,
                117.541,
                117.562,
                117.581,
                117.6,
                117.621,
                117.64,
                117.661,
                117.681,
                117.7,
                117.721,
                117.74,
                117.761,
                117.78,
                117.799,
                117.82,
                117.839,
                117.86,
                117.88,
                117.899,
                117.92,
                117.939,
                117.96,
                117.979,
                117.998,
                118.019,
                118.038,
                118.057,
                118.078,
                118.097,
                118.118,
                118.138,
                118.157,
                118.178
            ],
            P97: [
                53.445,
                53.624,
                53.803,
                53.981,
                54.159,
                54.338,
                54.517,
                54.694,
                54.873,
                55.051,
                55.23,
                55.407,
                55.585,
                55.763,
                55.942,
                56.097,
                56.252,
                56.407,
                56.56,
                56.713,
                56.864,
                57.015,
                57.164,
                57.312,
                57.46,
                57.606,
                57.752,
                57.896,
                58.04,
                58.183,
                58.324,
                58.464,
                58.604,
                58.742,
                58.879,
                59.016,
                59.15,
                59.284,
                59.418,
                59.549,
                59.68,
                59.81,
                59.939,
                60.066,
                60.193,
                60.319,
                60.443,
                60.567,
                60.69,
                60.811,
                60.932,
                61.051,
                61.17,
                61.288,
                61.405,
                61.521,
                61.636,
                61.751,
                61.864,
                61.978,
                62.09,
                62.201,
                62.312,
                62.422,
                62.531,
                62.641,
                62.748,
                62.857,
                62.963,
                63.07,
                63.176,
                63.28,
                63.385,
                63.489,
                63.592,
                63.694,
                63.796,
                63.897,
                63.998,
                64.097,
                64.197,
                64.295,
                64.393,
                64.49,
                64.587,
                64.682,
                64.777,
                64.873,
                64.967,
                65.06,
                65.153,
                65.246,
                65.337,
                65.428,
                65.519,
                65.608,
                65.698,
                65.786,
                65.875,
                65.962,
                66.049,
                66.135,
                66.221,
                66.305,
                66.39,
                66.475,
                66.557,
                66.641,
                66.723,
                66.804,
                66.886,
                66.967,
                67.047,
                67.127,
                67.205,
                67.283,
                67.362,
                67.439,
                67.516,
                67.593,
                67.668,
                67.744,
                67.819,
                67.893,
                67.967,
                68.04,
                68.113,
                68.185,
                68.257,
                68.329,
                68.4,
                68.47,
                68.541,
                68.611,
                68.68,
                68.748,
                68.817,
                68.886,
                68.953,
                69.02,
                69.087,
                69.154,
                69.22,
                69.285,
                69.351,
                69.416,
                69.481,
                69.545,
                69.609,
                69.673,
                69.736,
                69.8,
                69.862,
                69.925,
                69.986,
                70.049,
                70.109,
                70.171,
                70.233,
                70.292,
                70.353,
                70.412,
                70.472,
                70.532,
                70.59,
                70.65,
                70.709,
                70.766,
                70.825,
                70.883,
                70.941,
                70.999,
                71.055,
                71.112,
                71.169,
                71.226,
                71.282,
                71.337,
                71.393,
                71.449,
                71.505,
                71.56,
                71.615,
                71.67,
                71.725,
                71.779,
                71.834,
                71.888,
                71.942,
                71.996,
                72.05,
                72.103,
                72.156,
                72.21,
                72.262,
                72.315,
                72.368,
                72.422,
                72.474,
                72.526,
                72.578,
                72.63,
                72.681,
                72.734,
                72.786,
                72.837,
                72.888,
                72.94,
                72.991,
                73.042,
                73.092,
                73.144,
                73.194,
                73.245,
                73.296,
                73.346,
                73.396,
                73.447,
                73.497,
                73.547,
                73.597,
                73.646,
                73.697,
                73.746,
                73.796,
                73.845,
                73.895,
                73.944,
                73.994,
                74.042,
                74.092,
                74.14,
                74.19,
                74.238,
                74.287,
                74.335,
                74.385,
                74.433,
                74.482,
                74.531,
                74.578,
                74.627,
                74.676,
                74.723,
                74.772,
                74.819,
                74.868,
                74.916,
                74.963,
                75.011,
                75.06,
                75.108,
                75.155,
                75.203,
                75.251,
                75.298,
                75.345,
                75.393,
                75.44,
                75.488,
                75.534,
                75.582,
                75.629,
                75.677,
                75.724,
                75.77,
                75.817,
                75.864,
                75.911,
                75.958,
                76.005,
                76.052,
                76.099,
                76.144,
                76.191,
                76.237,
                76.284,
                76.33,
                76.377,
                76.423,
                76.469,
                76.516,
                76.562,
                76.608,
                76.654,
                76.7,
                76.746,
                76.792,
                76.837,
                76.883,
                76.929,
                76.974,
                77.02,
                77.065,
                77.11,
                77.156,
                77.201,
                77.246,
                77.291,
                77.338,
                77.383,
                77.427,
                77.472,
                77.517,
                77.562,
                77.606,
                77.651,
                77.697,
                77.741,
                77.786,
                77.83,
                77.875,
                77.919,
                77.964,
                78.008,
                78.052,
                78.096,
                78.14,
                78.186,
                78.229,
                78.273,
                78.317,
                78.362,
                78.405,
                78.449,
                78.492,
                78.537,
                78.58,
                78.624,
                78.668,
                78.711,
                78.755,
                78.798,
                78.842,
                78.885,
                78.928,
                78.972,
                79.015,
                79.058,
                79.102,
                79.144,
                79.187,
                79.231,
                79.273,
                79.316,
                79.359,
                79.402,
                79.445,
                79.487,
                79.53,
                79.573,
                79.615,
                79.659,
                79.701,
                79.743,
                79.786,
                79.828,
                79.871,
                79.913,
                79.956,
                79.997,
                80.04,
                80.082,
                80.123,
                80.166,
                80.208,
                80.25,
                80.292,
                80.335,
                80.376,
                80.418,
                80.46,
                80.502,
                80.543,
                80.586,
                80.627,
                80.669,
                80.71,
                80.752,
                80.793,
                80.835,
                80.877,
                80.918,
                80.96,
                81,
                81.042,
                81.083,
                81.125,
                81.165,
                81.207,
                81.249,
                81.289,
                81.331,
                81.371,
                81.412,
                81.454,
                81.494,
                81.535,
                81.575,
                81.617,
                81.658,
                81.698,
                81.739,
                81.78,
                81.82,
                81.861,
                81.901,
                81.942,
                81.983,
                82.022,
                82.063,
                82.104,
                82.143,
                82.184,
                82.225,
                82.264,
                82.304,
                82.345,
                82.384,
                82.425,
                82.465,
                82.504,
                82.544,
                82.585,
                82.624,
                82.664,
                82.704,
                82.744,
                82.783,
                82.823,
                82.863,
                82.902,
                82.942,
                82.982,
                83.022,
                83.06,
                83.1,
                83.14,
                83.18,
                83.218,
                83.258,
                83.297,
                83.337,
                83.375,
                83.414,
                83.454,
                83.494,
                83.532,
                83.571,
                83.61,
                83.65,
                83.688,
                83.727,
                83.766,
                83.805,
                83.844,
                83.882,
                83.921,
                83.96,
                83.999,
                84.038,
                84.076,
                84.115,
                84.154,
                84.193,
                84.231,
                84.269,
                84.308,
                84.346,
                84.385,
                84.424,
                84.462,
                84.499,
                84.538,
                84.577,
                84.615,
                84.654,
                84.692,
                84.729,
                84.768,
                84.806,
                84.844,
                84.883,
                84.921,
                84.959,
                84.996,
                85.034,
                85.072,
                85.11,
                85.148,
                85.186,
                85.224,
                85.261,
                85.299,
                85.337,
                85.375,
                85.413,
                85.451,
                85.488,
                85.526,
                85.564,
                85.6,
                85.638,
                85.675,
                85.713,
                85.751,
                85.788,
                85.826,
                85.863,
                85.901,
                85.938,
                85.976,
                86.013,
                86.049,
                86.086,
                86.123,
                86.161,
                86.198,
                86.235,
                86.272,
                86.31,
                86.347,
                86.384,
                86.421,
                86.458,
                86.495,
                86.532,
                86.569,
                86.606,
                86.643,
                86.678,
                86.715,
                86.751,
                86.788,
                86.825,
                86.862,
                86.898,
                86.935,
                86.972,
                87.008,
                87.045,
                87.081,
                87.118,
                87.154,
                87.191,
                87.227,
                87.263,
                87.3,
                87.336,
                87.372,
                87.409,
                87.445,
                87.481,
                87.517,
                87.553,
                87.589,
                87.625,
                87.662,
                87.698,
                87.734,
                87.769,
                87.805,
                87.841,
                87.877,
                87.913,
                87.949,
                87.985,
                88.02,
                88.056,
                88.092,
                88.127,
                88.163,
                88.199,
                88.234,
                88.27,
                88.305,
                88.342,
                88.378,
                88.413,
                88.449,
                88.484,
                88.519,
                88.555,
                88.59,
                88.625,
                88.661,
                88.696,
                88.731,
                88.766,
                88.801,
                88.836,
                88.872,
                88.907,
                88.942,
                88.977,
                89.012,
                89.048,
                89.083,
                89.118,
                89.153,
                89.188,
                89.223,
                89.257,
                89.292,
                89.327,
                89.362,
                89.396,
                89.431,
                89.466,
                89.5,
                89.537,
                89.571,
                89.606,
                89.64,
                89.675,
                89.709,
                89.744,
                89.778,
                89.813,
                89.847,
                89.881,
                89.917,
                89.952,
                89.986,
                90.02,
                90.054,
                90.089,
                90.123,
                90.157,
                90.191,
                90.225,
                90.261,
                90.295,
                90.329,
                90.363,
                90.397,
                90.431,
                90.465,
                90.499,
                90.533,
                90.568,
                90.602,
                90.636,
                90.67,
                90.703,
                90.737,
                90.771,
                90.805,
                90.838,
                90.874,
                90.907,
                90.941,
                90.974,
                91.008,
                91.042,
                91.075,
                91.109,
                91.144,
                91.177,
                91.21,
                91.244,
                91.277,
                91.311,
                91.344,
                91.379,
                91.412,
                91.445,
                91.479,
                91.512,
                91.545,
                91.578,
                91.611,
                91.646,
                91.679,
                91.712,
                91.745,
                91.778,
                91.812,
                91.845,
                91.879,
                91.912,
                91.945,
                91.978,
                92.011,
                92.044,
                92.077,
                92.111,
                92.144,
                92.177,
                92.21,
                92.242,
                92.275,
                92.308,
                92.342,
                92.375,
                92.408,
                92.44,
                92.473,
                92.505,
                92.538,
                92.572,
                92.605,
                92.637,
                92.67,
                92.702,
                92.735,
                92.767,
                92.801,
                92.834,
                92.866,
                92.899,
                92.931,
                92.963,
                92.996,
                93.029,
                93.062,
                93.094,
                93.126,
                93.159,
                93.191,
                93.223,
                93.257,
                93.289,
                93.321,
                93.353,
                93.385,
                93.417,
                93.449,
                93.483,
                93.515,
                93.547,
                92.879,
                92.911,
                92.943,
                92.975,
                93.008,
                93.04,
                93.072,
                93.104,
                93.136,
                93.167,
                93.199,
                93.233,
                93.264,
                93.296,
                93.328,
                93.359,
                93.391,
                93.422,
                93.454,
                93.487,
                93.519,
                93.55,
                93.582,
                93.613,
                93.645,
                93.676,
                93.709,
                93.74,
                93.772,
                93.803,
                93.834,
                93.866,
                93.897,
                93.928,
                93.961,
                93.992,
                94.023,
                94.054,
                94.085,
                94.117,
                94.148,
                94.18,
                94.211,
                94.242,
                94.273,
                94.304,
                94.335,
                94.366,
                94.397,
                94.429,
                94.46,
                94.491,
                94.522,
                94.552,
                94.583,
                94.614,
                94.644,
                94.675,
                94.707,
                94.738,
                94.769,
                94.799,
                94.83,
                94.86,
                94.891,
                94.921,
                94.952,
                94.984,
                95.014,
                95.044,
                95.075,
                95.105,
                95.135,
                95.166,
                95.196,
                95.226,
                95.256,
                95.288,
                95.318,
                95.348,
                95.378,
                95.409,
                95.439,
                95.469,
                95.499,
                95.529,
                95.559,
                95.589,
                95.62,
                95.65,
                95.68,
                95.71,
                95.74,
                95.77,
                95.799,
                95.829,
                95.859,
                95.889,
                95.918,
                95.948,
                95.979,
                96.009,
                96.039,
                96.068,
                96.098,
                96.127,
                96.157,
                96.186,
                96.216,
                96.245,
                96.274,
                96.304,
                96.333,
                96.363,
                96.392,
                96.423,
                96.452,
                96.481,
                96.511,
                96.54,
                96.569,
                96.598,
                96.627,
                96.656,
                96.686,
                96.715,
                96.744,
                96.773,
                96.802,
                96.831,
                96.86,
                96.888,
                96.917,
                96.946,
                96.975,
                97.004,
                97.033,
                97.063,
                97.092,
                97.121,
                97.149,
                97.178,
                97.207,
                97.235,
                97.264,
                97.293,
                97.321,
                97.35,
                97.378,
                97.407,
                97.435,
                97.463,
                97.492,
                97.52,
                97.549,
                97.577,
                97.605,
                97.634,
                97.662,
                97.69,
                97.718,
                97.747,
                97.775,
                97.803,
                97.831,
                97.859,
                97.887,
                97.915,
                97.943,
                97.971,
                98,
                98.027,
                98.055,
                98.083,
                98.111,
                98.139,
                98.167,
                98.195,
                98.223,
                98.251,
                98.278,
                98.306,
                98.334,
                98.362,
                98.389,
                98.417,
                98.445,
                98.472,
                98.5,
                98.527,
                98.553,
                98.581,
                98.608,
                98.636,
                98.663,
                98.691,
                98.718,
                98.746,
                98.773,
                98.801,
                98.828,
                98.855,
                98.883,
                98.91,
                98.937,
                98.964,
                98.992,
                99.019,
                99.046,
                99.073,
                99.099,
                99.126,
                99.153,
                99.18,
                99.207,
                99.234,
                99.261,
                99.288,
                99.315,
                99.342,
                99.369,
                99.396,
                99.423,
                99.45,
                99.475,
                99.502,
                99.529,
                99.556,
                99.582,
                99.609,
                99.636,
                99.663,
                99.689,
                99.716,
                99.743,
                99.768,
                99.794,
                99.821,
                99.848,
                99.874,
                99.901,
                99.927,
                99.954,
                99.98,
                100.007,
                100.032,
                100.058,
                100.084,
                100.111,
                100.137,
                100.164,
                100.19,
                100.216,
                100.241,
                100.267,
                100.294,
                100.32,
                100.346,
                100.372,
                100.399,
                100.425,
                100.449,
                100.476,
                100.502,
                100.528,
                100.554,
                100.58,
                100.606,
                100.631,
                100.657,
                100.683,
                100.709,
                100.735,
                100.761,
                100.787,
                100.811,
                100.837,
                100.863,
                100.889,
                100.915,
                100.941,
                100.965,
                100.991,
                101.017,
                101.042,
                101.068,
                101.094,
                101.118,
                101.144,
                101.17,
                101.195,
                101.221,
                101.245,
                101.271,
                101.297,
                101.322,
                101.348,
                101.374,
                101.398,
                101.423,
                101.449,
                101.475,
                101.5,
                101.524,
                101.55,
                101.575,
                101.601,
                101.626,
                101.65,
                101.675,
                101.701,
                101.727,
                101.752,
                101.776,
                101.801,
                101.827,
                101.852,
                101.876,
                101.901,
                101.927,
                101.952,
                101.977,
                102.001,
                102.026,
                102.052,
                102.077,
                102.1,
                102.126,
                102.151,
                102.176,
                102.202,
                102.225,
                102.25,
                102.276,
                102.301,
                102.324,
                102.35,
                102.375,
                102.4,
                102.423,
                102.448,
                102.474,
                102.499,
                102.522,
                102.547,
                102.572,
                102.597,
                102.621,
                102.646,
                102.671,
                102.696,
                102.719,
                102.744,
                102.769,
                102.792,
                102.817,
                102.842,
                102.867,
                102.89,
                102.915,
                102.94,
                102.965,
                102.988,
                103.013,
                103.038,
                103.061,
                103.086,
                103.111,
                103.136,
                103.159,
                103.184,
                103.209,
                103.232,
                103.256,
                103.281,
                103.306,
                103.329,
                103.354,
                103.378,
                103.401,
                103.426,
                103.451,
                103.474,
                103.498,
                103.523,
                103.548,
                103.571,
                103.595,
                103.62,
                103.643,
                103.667,
                103.692,
                103.715,
                103.739,
                103.764,
                103.787,
                103.811,
                103.836,
                103.859,
                103.883,
                103.908,
                103.93,
                103.955,
                103.979,
                104.002,
                104.027,
                104.051,
                104.074,
                104.098,
                104.123,
                104.145,
                104.17,
                104.194,
                104.217,
                104.241,
                104.266,
                104.288,
                104.312,
                104.337,
                104.359,
                104.384,
                104.408,
                104.43,
                104.455,
                104.479,
                104.502,
                104.526,
                104.55,
                104.573,
                104.597,
                104.619,
                104.643,
                104.668,
                104.69,
                104.714,
                104.739,
                104.761,
                104.785,
                104.809,
                104.832,
                104.856,
                104.878,
                104.902,
                104.926,
                104.949,
                104.973,
                104.997,
                105.019,
                105.043,
                105.065,
                105.089,
                105.113,
                105.136,
                105.16,
                105.182,
                105.206,
                105.23,
                105.252,
                105.276,
                105.298,
                105.322,
                105.346,
                105.368,
                105.392,
                105.416,
                105.438,
                105.462,
                105.484,
                105.508,
                105.532,
                105.554,
                105.578,
                105.6,
                105.624,
                105.646,
                105.669,
                105.693,
                105.715,
                105.739,
                105.761,
                105.785,
                105.808,
                105.83,
                105.854,
                105.876,
                105.9,
                105.923,
                105.945,
                105.969,
                105.991,
                106.014,
                106.036,
                106.06,
                106.084,
                106.105,
                106.129,
                106.151,
                106.174,
                106.196,
                106.22,
                106.243,
                106.265,
                106.289,
                106.31,
                106.334,
                106.355,
                106.379,
                106.403,
                106.424,
                106.448,
                106.469,
                106.493,
                106.514,
                106.538,
                106.559,
                106.583,
                106.606,
                106.628,
                106.651,
                106.673,
                106.696,
                106.718,
                106.741,
                106.762,
                106.786,
                106.807,
                106.831,
                106.854,
                106.875,
                106.899,
                106.92,
                106.943,
                106.965,
                106.988,
                107.009,
                107.033,
                107.054,
                107.077,
                107.1,
                107.122,
                107.145,
                107.166,
                107.189,
                107.21,
                107.234,
                107.255,
                107.278,
                107.299,
                107.322,
                107.344,
                107.367,
                107.388,
                107.411,
                107.432,
                107.455,
                107.478,
                107.499,
                107.522,
                107.543,
                107.566,
                107.588,
                107.611,
                107.632,
                107.655,
                107.676,
                107.699,
                107.72,
                107.743,
                107.764,
                107.787,
                107.808,
                107.83,
                107.852,
                107.874,
                107.895,
                107.918,
                107.939,
                107.962,
                107.983,
                108.006,
                108.027,
                108.05,
                108.07,
                108.093,
                108.116,
                108.137,
                108.16,
                108.18,
                108.203,
                108.224,
                108.247,
                108.268,
                108.29,
                108.311,
                108.334,
                108.355,
                108.377,
                108.398,
                108.421,
                108.441,
                108.464,
                108.485,
                108.507,
                108.528,
                108.551,
                108.571,
                108.594,
                108.615,
                108.637,
                108.658,
                108.68,
                108.701,
                108.724,
                108.744,
                108.765,
                108.787,
                108.808,
                108.83,
                108.851,
                108.874,
                108.894,
                108.917,
                108.937,
                108.96,
                108.98,
                109.003,
                109.023,
                109.045,
                109.066,
                109.088,
                109.109,
                109.131,
                109.152,
                109.174,
                109.195,
                109.217,
                109.237,
                109.26,
                109.28,
                109.302,
                109.323,
                109.345,
                109.366,
                109.388,
                109.408,
                109.431,
                109.451,
                109.471,
                109.494,
                109.514,
                109.536,
                109.556,
                109.579,
                109.599,
                109.621,
                109.642,
                109.664,
                109.684,
                109.706,
                109.727,
                109.749,
                109.769,
                109.791,
                109.811,
                109.834,
                109.854,
                109.876,
                109.896,
                109.916,
                109.939,
                109.959,
                109.981,
                110.001,
                110.023,
                110.043,
                110.065,
                110.086,
                110.108,
                110.128,
                110.15,
                110.17,
                110.192,
                110.212,
                110.232,
                110.254,
                110.275,
                110.297,
                110.317,
                110.339,
                110.359,
                110.381,
                110.401,
                110.423,
                110.443,
                110.465,
                110.485,
                110.507,
                110.527,
                110.547,
                110.569,
                110.589,
                110.611,
                110.631,
                110.653,
                110.673,
                110.695,
                110.715,
                110.737,
                110.757,
                110.779,
                110.799,
                110.819,
                110.841,
                110.861,
                110.883,
                110.903,
                110.925,
                110.945,
                110.967,
                110.987,
                111.008,
                111.028,
                111.048,
                111.07,
                111.09,
                111.112,
                111.132,
                111.154,
                111.174,
                111.196,
                111.215,
                111.237,
                111.257,
                111.277,
                111.299,
                111.319,
                111.341,
                111.36,
                111.382,
                111.402,
                111.424,
                111.444,
                111.466,
                111.486,
                111.505,
                111.527,
                111.547,
                111.569,
                111.589,
                111.61,
                111.63,
                111.652,
                111.672,
                111.692,
                111.714,
                111.733,
                111.755,
                111.775,
                111.797,
                111.817,
                111.838,
                111.858,
                111.878,
                111.9,
                111.919,
                111.941,
                111.961,
                111.983,
                112.003,
                112.024,
                112.044,
                112.066,
                112.086,
                112.105,
                112.127,
                112.147,
                112.169,
                112.188,
                112.21,
                112.23,
                112.252,
                112.271,
                112.291,
                112.313,
                112.332,
                112.354,
                112.374,
                112.396,
                112.415,
                112.437,
                112.457,
                112.476,
                112.498,
                112.518,
                112.54,
                112.559,
                112.581,
                112.601,
                112.62,
                112.642,
                112.662,
                112.683,
                112.703,
                112.725,
                112.745,
                112.766,
                112.786,
                112.806,
                112.827,
                112.847,
                112.869,
                112.888,
                112.91,
                112.93,
                112.951,
                112.971,
                112.991,
                113.012,
                113.032,
                113.054,
                113.074,
                113.095,
                113.115,
                113.135,
                113.156,
                113.176,
                113.197,
                113.217,
                113.239,
                113.258,
                113.28,
                113.3,
                113.319,
                113.341,
                113.361,
                113.382,
                113.402,
                113.424,
                113.443,
                113.463,
                113.485,
                113.504,
                113.526,
                113.546,
                113.567,
                113.587,
                113.608,
                113.628,
                113.648,
                113.669,
                113.689,
                113.711,
                113.73,
                113.752,
                113.772,
                113.791,
                113.813,
                113.832,
                113.854,
                113.874,
                113.895,
                113.915,
                113.934,
                113.956,
                113.976,
                113.997,
                114.017,
                114.039,
                114.058,
                114.078,
                114.099,
                114.119,
                114.141,
                114.16,
                114.182,
                114.201,
                114.221,
                114.243,
                114.262,
                114.284,
                114.304,
                114.325,
                114.345,
                114.366,
                114.386,
                114.405,
                114.427,
                114.447,
                114.468,
                114.488,
                114.509,
                114.529,
                114.549,
                114.57,
                114.59,
                114.611,
                114.631,
                114.651,
                114.672,
                114.692,
                114.713,
                114.733,
                114.754,
                114.774,
                114.794,
                114.815,
                114.835,
                114.856,
                114.876,
                114.897,
                114.917,
                114.937,
                114.958,
                114.978,
                114.999,
                115.019,
                115.04,
                115.06,
                115.08,
                115.101,
                115.121,
                115.142,
                115.162,
                115.183,
                115.203,
                115.222,
                115.244,
                115.264,
                115.285,
                115.305,
                115.326,
                115.346,
                115.365,
                115.387,
                115.406,
                115.428,
                115.448,
                115.467,
                115.489,
                115.508,
                115.53,
                115.549,
                115.571,
                115.59,
                115.61,
                115.631,
                115.651,
                115.672,
                115.692,
                115.712,
                115.733,
                115.753,
                115.774,
                115.794,
                115.815,
                115.835,
                115.854,
                115.876,
                115.895,
                115.917,
                115.936,
                115.958,
                115.977,
                115.997,
                116.018,
                116.038,
                116.06,
                116.079,
                116.098,
                116.12,
                116.14,
                116.161,
                116.181,
                116.202,
                116.222,
                116.241,
                116.263,
                116.282,
                116.304,
                116.323,
                116.343,
                116.364,
                116.384,
                116.405,
                116.425,
                116.444,
                116.466,
                116.485,
                116.507,
                116.526,
                116.548,
                116.567,
                116.587,
                116.608,
                116.628,
                116.649,
                116.669,
                116.688,
                116.71,
                116.729,
                116.751,
                116.77,
                116.79,
                116.811,
                116.831,
                116.852,
                116.872,
                116.893,
                116.913,
                116.932,
                116.954,
                116.973,
                116.995,
                117.014,
                117.034,
                117.055,
                117.074,
                117.096,
                117.115,
                117.135,
                117.156,
                117.176,
                117.197,
                117.217,
                117.236,
                117.258,
                117.277,
                117.299,
                117.318,
                117.34,
                117.359,
                117.379,
                117.4,
                117.42,
                117.441,
                117.46,
                117.48,
                117.501,
                117.521,
                117.542,
                117.562,
                117.581,
                117.603,
                117.622,
                117.644,
                117.663,
                117.682,
                117.704,
                117.723,
                117.745,
                117.764,
                117.784,
                117.805,
                117.825,
                117.846,
                117.866,
                117.885,
                117.906,
                117.926,
                117.947,
                117.967,
                117.986,
                118.008,
                118.027,
                118.048,
                118.068,
                118.087,
                118.109,
                118.128,
                118.15,
                118.169,
                118.188,
                118.21,
                118.229,
                118.251,
                118.27,
                118.29,
                118.311,
                118.33,
                118.352,
                118.371,
                118.391,
                118.412,
                118.432,
                118.453,
                118.472,
                118.492,
                118.513,
                118.533,
                118.554,
                118.573,
                118.593,
                118.614,
                118.633,
                118.655,
                118.674,
                118.694,
                118.715,
                118.734,
                118.756,
                118.775,
                118.795,
                118.816,
                118.835,
                118.857,
                118.876,
                118.896,
                118.917,
                118.936,
                118.958,
                118.977,
                118.996,
                119.018,
                119.037,
                119.059,
                119.078,
                119.097,
                119.119,
                119.138,
                119.157,
                119.179,
                119.198,
                119.219,
                119.239,
                119.258,
                119.279
            ],
            P99: [
                54.288,
                54.468,
                54.648,
                54.826,
                55.005,
                55.185,
                55.364,
                55.542,
                55.721,
                55.9,
                56.079,
                56.257,
                56.436,
                56.615,
                56.793,
                56.949,
                57.106,
                57.261,
                57.416,
                57.569,
                57.722,
                57.873,
                58.023,
                58.172,
                58.322,
                58.468,
                58.615,
                58.76,
                58.905,
                59.049,
                59.19,
                59.332,
                59.472,
                59.612,
                59.75,
                59.887,
                60.023,
                60.157,
                60.292,
                60.424,
                60.555,
                60.687,
                60.816,
                60.944,
                61.072,
                61.199,
                61.323,
                61.448,
                61.572,
                61.693,
                61.815,
                61.936,
                62.055,
                62.174,
                62.292,
                62.408,
                62.524,
                62.639,
                62.753,
                62.868,
                62.98,
                63.092,
                63.204,
                63.315,
                63.424,
                63.535,
                63.643,
                63.752,
                63.859,
                63.967,
                64.073,
                64.178,
                64.284,
                64.388,
                64.492,
                64.594,
                64.697,
                64.799,
                64.9,
                65.001,
                65.1,
                65.2,
                65.298,
                65.396,
                65.493,
                65.589,
                65.685,
                65.782,
                65.876,
                65.97,
                66.063,
                66.156,
                66.248,
                66.339,
                66.431,
                66.521,
                66.612,
                66.7,
                66.79,
                66.877,
                66.965,
                67.051,
                67.138,
                67.223,
                67.308,
                67.393,
                67.476,
                67.56,
                67.643,
                67.725,
                67.807,
                67.888,
                67.969,
                68.05,
                68.128,
                68.207,
                68.286,
                68.364,
                68.441,
                68.518,
                68.595,
                68.671,
                68.746,
                68.821,
                68.895,
                68.969,
                69.042,
                69.115,
                69.187,
                69.259,
                69.331,
                69.401,
                69.473,
                69.543,
                69.613,
                69.682,
                69.75,
                69.82,
                69.888,
                69.955,
                70.022,
                70.09,
                70.156,
                70.222,
                70.289,
                70.354,
                70.42,
                70.484,
                70.548,
                70.613,
                70.676,
                70.74,
                70.802,
                70.866,
                70.928,
                70.991,
                71.052,
                71.114,
                71.176,
                71.236,
                71.297,
                71.357,
                71.417,
                71.478,
                71.536,
                71.596,
                71.656,
                71.713,
                71.772,
                71.831,
                71.889,
                71.947,
                72.004,
                72.061,
                72.119,
                72.176,
                72.233,
                72.288,
                72.345,
                72.401,
                72.457,
                72.513,
                72.569,
                72.624,
                72.679,
                72.734,
                72.789,
                72.844,
                72.898,
                72.953,
                73.007,
                73.06,
                73.114,
                73.168,
                73.221,
                73.274,
                73.327,
                73.382,
                73.434,
                73.487,
                73.539,
                73.592,
                73.644,
                73.697,
                73.749,
                73.8,
                73.852,
                73.905,
                73.956,
                74.007,
                74.058,
                74.111,
                74.161,
                74.212,
                74.264,
                74.314,
                74.365,
                74.416,
                74.466,
                74.518,
                74.568,
                74.617,
                74.668,
                74.718,
                74.769,
                74.818,
                74.869,
                74.918,
                74.969,
                75.017,
                75.068,
                75.117,
                75.167,
                75.215,
                75.265,
                75.314,
                75.363,
                75.412,
                75.461,
                75.511,
                75.559,
                75.608,
                75.658,
                75.706,
                75.755,
                75.802,
                75.852,
                75.901,
                75.948,
                75.997,
                76.046,
                76.095,
                76.142,
                76.19,
                76.239,
                76.287,
                76.334,
                76.383,
                76.431,
                76.479,
                76.526,
                76.574,
                76.622,
                76.67,
                76.718,
                76.764,
                76.812,
                76.86,
                76.907,
                76.955,
                77.002,
                77.05,
                77.097,
                77.143,
                77.19,
                77.237,
                77.285,
                77.332,
                77.379,
                77.426,
                77.473,
                77.519,
                77.566,
                77.613,
                77.659,
                77.706,
                77.753,
                77.799,
                77.845,
                77.892,
                77.938,
                77.984,
                78.03,
                78.076,
                78.122,
                78.168,
                78.214,
                78.26,
                78.305,
                78.352,
                78.398,
                78.444,
                78.489,
                78.534,
                78.58,
                78.625,
                78.67,
                78.717,
                78.762,
                78.807,
                78.852,
                78.897,
                78.942,
                78.988,
                79.033,
                79.077,
                79.122,
                79.166,
                79.213,
                79.257,
                79.301,
                79.345,
                79.391,
                79.436,
                79.48,
                79.524,
                79.569,
                79.613,
                79.657,
                79.703,
                79.746,
                79.79,
                79.834,
                79.879,
                79.922,
                79.966,
                80.011,
                80.054,
                80.098,
                80.143,
                80.186,
                80.229,
                80.274,
                80.317,
                80.36,
                80.404,
                80.447,
                80.492,
                80.534,
                80.577,
                80.622,
                80.664,
                80.708,
                80.751,
                80.793,
                80.838,
                80.88,
                80.924,
                80.966,
                81.01,
                81.053,
                81.096,
                81.139,
                81.181,
                81.224,
                81.266,
                81.31,
                81.352,
                81.396,
                81.437,
                81.481,
                81.523,
                81.566,
                81.608,
                81.651,
                81.692,
                81.736,
                81.777,
                81.82,
                81.862,
                81.905,
                81.948,
                81.989,
                82.032,
                82.073,
                82.116,
                82.157,
                82.199,
                82.24,
                82.283,
                82.326,
                82.366,
                82.409,
                82.45,
                82.492,
                82.534,
                82.575,
                82.617,
                82.658,
                82.7,
                82.742,
                82.783,
                82.825,
                82.867,
                82.907,
                82.949,
                82.989,
                83.031,
                83.073,
                83.113,
                83.154,
                83.196,
                83.236,
                83.278,
                83.319,
                83.359,
                83.401,
                83.442,
                83.482,
                83.523,
                83.564,
                83.604,
                83.645,
                83.686,
                83.726,
                83.767,
                83.808,
                83.849,
                83.888,
                83.929,
                83.97,
                84.009,
                84.05,
                84.091,
                84.132,
                84.171,
                84.211,
                84.252,
                84.293,
                84.331,
                84.372,
                84.412,
                84.453,
                84.492,
                84.532,
                84.572,
                84.613,
                84.651,
                84.692,
                84.732,
                84.772,
                84.81,
                84.85,
                84.89,
                84.931,
                84.971,
                85.009,
                85.049,
                85.089,
                85.129,
                85.169,
                85.207,
                85.246,
                85.286,
                85.326,
                85.366,
                85.403,
                85.443,
                85.483,
                85.522,
                85.562,
                85.601,
                85.639,
                85.678,
                85.718,
                85.757,
                85.797,
                85.836,
                85.873,
                85.913,
                85.952,
                85.991,
                86.03,
                86.069,
                86.108,
                86.146,
                86.185,
                86.224,
                86.263,
                86.302,
                86.34,
                86.379,
                86.416,
                86.455,
                86.494,
                86.533,
                86.571,
                86.61,
                86.649,
                86.687,
                86.726,
                86.763,
                86.801,
                86.84,
                86.878,
                86.917,
                86.955,
                86.994,
                87.032,
                87.07,
                87.109,
                87.147,
                87.185,
                87.221,
                87.26,
                87.298,
                87.336,
                87.374,
                87.412,
                87.45,
                87.488,
                87.526,
                87.564,
                87.602,
                87.64,
                87.678,
                87.715,
                87.753,
                87.791,
                87.829,
                87.864,
                87.902,
                87.94,
                87.977,
                88.015,
                88.052,
                88.09,
                88.127,
                88.165,
                88.202,
                88.24,
                88.277,
                88.315,
                88.352,
                88.389,
                88.426,
                88.464,
                88.501,
                88.538,
                88.575,
                88.612,
                88.649,
                88.686,
                88.723,
                88.76,
                88.797,
                88.834,
                88.871,
                88.908,
                88.945,
                88.981,
                89.018,
                89.055,
                89.092,
                89.128,
                89.165,
                89.202,
                89.238,
                89.275,
                89.311,
                89.348,
                89.384,
                89.421,
                89.457,
                89.494,
                89.53,
                89.568,
                89.605,
                89.641,
                89.677,
                89.713,
                89.749,
                89.786,
                89.822,
                89.858,
                89.894,
                89.93,
                89.966,
                90.002,
                90.038,
                90.074,
                90.11,
                90.146,
                90.182,
                90.218,
                90.253,
                90.291,
                90.327,
                90.363,
                90.398,
                90.434,
                90.47,
                90.505,
                90.541,
                90.577,
                90.612,
                90.648,
                90.683,
                90.719,
                90.754,
                90.792,
                90.827,
                90.863,
                90.898,
                90.933,
                90.969,
                91.004,
                91.039,
                91.074,
                91.11,
                91.145,
                91.182,
                91.217,
                91.252,
                91.287,
                91.322,
                91.358,
                91.393,
                91.428,
                91.463,
                91.497,
                91.534,
                91.569,
                91.604,
                91.639,
                91.674,
                91.709,
                91.743,
                91.778,
                91.813,
                91.85,
                91.884,
                91.919,
                91.954,
                91.988,
                92.023,
                92.057,
                92.092,
                92.126,
                92.163,
                92.197,
                92.232,
                92.266,
                92.301,
                92.335,
                92.369,
                92.404,
                92.44,
                92.474,
                92.508,
                92.543,
                92.577,
                92.611,
                92.645,
                92.681,
                92.716,
                92.75,
                92.784,
                92.818,
                92.852,
                92.886,
                92.92,
                92.956,
                92.99,
                93.024,
                93.057,
                93.091,
                93.125,
                93.159,
                93.195,
                93.229,
                93.263,
                93.296,
                93.33,
                93.364,
                93.397,
                93.433,
                93.467,
                93.5,
                93.534,
                93.568,
                93.601,
                93.635,
                93.67,
                93.704,
                93.737,
                93.771,
                93.804,
                93.838,
                93.871,
                93.907,
                93.94,
                93.973,
                94.007,
                94.04,
                94.073,
                94.107,
                94.142,
                94.175,
                94.209,
                94.242,
                94.275,
                94.308,
                94.341,
                94.376,
                94.41,
                94.443,
                94.476,
                94.509,
                94.542,
                94.575,
                94.61,
                94.643,
                94.676,
                94.709,
                94.742,
                94.774,
                94.807,
                94.842,
                94.875,
                94.908,
                94.241,
                94.274,
                94.306,
                94.339,
                94.374,
                94.407,
                94.439,
                94.472,
                94.504,
                94.537,
                94.57,
                94.604,
                94.637,
                94.669,
                94.702,
                94.734,
                94.767,
                94.799,
                94.832,
                94.866,
                94.898,
                94.931,
                94.963,
                94.995,
                95.028,
                95.06,
                95.094,
                95.126,
                95.158,
                95.191,
                95.223,
                95.255,
                95.287,
                95.319,
                95.353,
                95.385,
                95.417,
                95.449,
                95.481,
                95.513,
                95.545,
                95.579,
                95.61,
                95.642,
                95.674,
                95.706,
                95.737,
                95.769,
                95.801,
                95.835,
                95.866,
                95.898,
                95.929,
                95.961,
                95.993,
                96.024,
                96.056,
                96.087,
                96.121,
                96.152,
                96.183,
                96.215,
                96.246,
                96.277,
                96.309,
                96.34,
                96.371,
                96.405,
                96.436,
                96.467,
                96.498,
                96.529,
                96.56,
                96.591,
                96.623,
                96.654,
                96.685,
                96.718,
                96.749,
                96.78,
                96.811,
                96.841,
                96.872,
                96.903,
                96.934,
                96.965,
                96.996,
                97.026,
                97.059,
                97.09,
                97.121,
                97.151,
                97.182,
                97.213,
                97.243,
                97.274,
                97.305,
                97.335,
                97.366,
                97.396,
                97.429,
                97.459,
                97.49,
                97.52,
                97.55,
                97.581,
                97.611,
                97.641,
                97.672,
                97.702,
                97.732,
                97.762,
                97.793,
                97.823,
                97.853,
                97.885,
                97.915,
                97.945,
                97.975,
                98.005,
                98.035,
                98.065,
                98.095,
                98.125,
                98.155,
                98.185,
                98.215,
                98.245,
                98.274,
                98.304,
                98.334,
                98.364,
                98.393,
                98.423,
                98.453,
                98.482,
                98.512,
                98.544,
                98.573,
                98.603,
                98.632,
                98.662,
                98.691,
                98.721,
                98.75,
                98.78,
                98.809,
                98.838,
                98.868,
                98.897,
                98.926,
                98.955,
                98.985,
                99.014,
                99.043,
                99.072,
                99.101,
                99.13,
                99.16,
                99.189,
                99.218,
                99.247,
                99.276,
                99.305,
                99.334,
                99.363,
                99.391,
                99.42,
                99.449,
                99.478,
                99.507,
                99.536,
                99.565,
                99.593,
                99.622,
                99.651,
                99.679,
                99.708,
                99.737,
                99.765,
                99.794,
                99.822,
                99.851,
                99.88,
                99.908,
                99.937,
                99.965,
                99.994,
                100.022,
                100.05,
                100.077,
                100.105,
                100.133,
                100.162,
                100.19,
                100.218,
                100.246,
                100.275,
                100.303,
                100.331,
                100.359,
                100.387,
                100.416,
                100.444,
                100.472,
                100.5,
                100.528,
                100.556,
                100.584,
                100.612,
                100.638,
                100.666,
                100.694,
                100.722,
                100.749,
                100.777,
                100.805,
                100.833,
                100.861,
                100.888,
                100.916,
                100.944,
                100.972,
                100.999,
                101.025,
                101.053,
                101.08,
                101.108,
                101.135,
                101.163,
                101.191,
                101.218,
                101.246,
                101.273,
                101.301,
                101.326,
                101.353,
                101.381,
                101.408,
                101.436,
                101.463,
                101.49,
                101.518,
                101.545,
                101.572,
                101.597,
                101.625,
                101.652,
                101.679,
                101.706,
                101.734,
                101.761,
                101.788,
                101.813,
                101.84,
                101.867,
                101.894,
                101.921,
                101.948,
                101.975,
                102.002,
                102.027,
                102.054,
                102.081,
                102.108,
                102.135,
                102.162,
                102.189,
                102.214,
                102.241,
                102.267,
                102.294,
                102.321,
                102.348,
                102.375,
                102.399,
                102.426,
                102.453,
                102.479,
                102.506,
                102.533,
                102.557,
                102.584,
                102.611,
                102.637,
                102.664,
                102.691,
                102.715,
                102.742,
                102.768,
                102.795,
                102.822,
                102.846,
                102.872,
                102.899,
                102.925,
                102.952,
                102.978,
                103.003,
                103.029,
                103.055,
                103.082,
                103.108,
                103.133,
                103.159,
                103.185,
                103.212,
                103.238,
                103.262,
                103.288,
                103.315,
                103.341,
                103.367,
                103.391,
                103.418,
                103.444,
                103.47,
                103.494,
                103.52,
                103.547,
                103.573,
                103.599,
                103.623,
                103.649,
                103.675,
                103.701,
                103.725,
                103.752,
                103.778,
                103.804,
                103.83,
                103.854,
                103.88,
                103.906,
                103.932,
                103.955,
                103.981,
                104.007,
                104.033,
                104.057,
                104.083,
                104.109,
                104.135,
                104.159,
                104.185,
                104.211,
                104.236,
                104.26,
                104.286,
                104.312,
                104.338,
                104.361,
                104.387,
                104.413,
                104.436,
                104.462,
                104.488,
                104.514,
                104.537,
                104.563,
                104.589,
                104.615,
                104.638,
                104.664,
                104.689,
                104.713,
                104.739,
                104.764,
                104.79,
                104.813,
                104.839,
                104.865,
                104.888,
                104.914,
                104.939,
                104.965,
                104.988,
                105.014,
                105.039,
                105.062,
                105.088,
                105.114,
                105.137,
                105.162,
                105.188,
                105.213,
                105.237,
                105.262,
                105.287,
                105.311,
                105.336,
                105.361,
                105.385,
                105.41,
                105.435,
                105.459,
                105.484,
                105.509,
                105.532,
                105.558,
                105.583,
                105.606,
                105.631,
                105.657,
                105.68,
                105.705,
                105.73,
                105.753,
                105.779,
                105.804,
                105.827,
                105.852,
                105.877,
                105.9,
                105.925,
                105.951,
                105.974,
                105.999,
                106.024,
                106.047,
                106.072,
                106.097,
                106.12,
                106.145,
                106.17,
                106.193,
                106.218,
                106.243,
                106.266,
                106.291,
                106.314,
                106.339,
                106.364,
                106.387,
                106.412,
                106.437,
                106.459,
                106.484,
                106.509,
                106.532,
                106.557,
                106.58,
                106.604,
                106.629,
                106.652,
                106.677,
                106.702,
                106.724,
                106.749,
                106.772,
                106.797,
                106.822,
                106.844,
                106.869,
                106.892,
                106.916,
                106.941,
                106.964,
                106.989,
                107.011,
                107.036,
                107.061,
                107.083,
                107.108,
                107.132,
                107.155,
                107.18,
                107.202,
                107.227,
                107.251,
                107.274,
                107.298,
                107.321,
                107.345,
                107.368,
                107.392,
                107.417,
                107.439,
                107.464,
                107.486,
                107.511,
                107.535,
                107.558,
                107.582,
                107.604,
                107.629,
                107.653,
                107.676,
                107.7,
                107.722,
                107.747,
                107.769,
                107.793,
                107.818,
                107.84,
                107.864,
                107.887,
                107.911,
                107.933,
                107.957,
                107.982,
                108.004,
                108.028,
                108.05,
                108.075,
                108.097,
                108.121,
                108.145,
                108.167,
                108.192,
                108.213,
                108.238,
                108.26,
                108.284,
                108.306,
                108.33,
                108.354,
                108.376,
                108.4,
                108.422,
                108.446,
                108.468,
                108.492,
                108.514,
                108.538,
                108.56,
                108.584,
                108.609,
                108.63,
                108.654,
                108.676,
                108.7,
                108.722,
                108.746,
                108.768,
                108.792,
                108.813,
                108.837,
                108.861,
                108.883,
                108.907,
                108.929,
                108.953,
                108.974,
                108.998,
                109.02,
                109.044,
                109.065,
                109.089,
                109.111,
                109.135,
                109.156,
                109.18,
                109.202,
                109.225,
                109.249,
                109.271,
                109.295,
                109.316,
                109.34,
                109.361,
                109.385,
                109.407,
                109.43,
                109.452,
                109.476,
                109.497,
                109.521,
                109.542,
                109.566,
                109.587,
                109.611,
                109.632,
                109.656,
                109.677,
                109.701,
                109.722,
                109.746,
                109.767,
                109.791,
                109.812,
                109.835,
                109.857,
                109.88,
                109.904,
                109.925,
                109.949,
                109.97,
                109.993,
                110.015,
                110.038,
                110.059,
                110.083,
                110.104,
                110.127,
                110.148,
                110.172,
                110.193,
                110.217,
                110.238,
                110.261,
                110.282,
                110.305,
                110.327,
                110.35,
                110.371,
                110.394,
                110.415,
                110.439,
                110.46,
                110.483,
                110.504,
                110.527,
                110.548,
                110.569,
                110.593,
                110.614,
                110.637,
                110.658,
                110.681,
                110.702,
                110.725,
                110.746,
                110.769,
                110.79,
                110.813,
                110.834,
                110.858,
                110.878,
                110.902,
                110.922,
                110.946,
                110.966,
                110.99,
                111.01,
                111.034,
                111.054,
                111.077,
                111.098,
                111.121,
                111.142,
                111.165,
                111.186,
                111.209,
                111.23,
                111.253,
                111.273,
                111.294,
                111.317,
                111.338,
                111.361,
                111.382,
                111.405,
                111.425,
                111.448,
                111.469,
                111.492,
                111.513,
                111.536,
                111.556,
                111.579,
                111.6,
                111.623,
                111.643,
                111.666,
                111.687,
                111.71,
                111.73,
                111.751,
                111.774,
                111.794,
                111.817,
                111.838,
                111.861,
                111.881,
                111.904,
                111.925,
                111.948,
                111.968,
                111.991,
                112.011,
                112.034,
                112.055,
                112.075,
                112.098,
                112.119,
                112.141,
                112.162,
                112.185,
                112.205,
                112.228,
                112.248,
                112.271,
                112.292,
                112.314,
                112.335,
                112.357,
                112.378,
                112.398,
                112.421,
                112.441,
                112.464,
                112.485,
                112.507,
                112.528,
                112.55,
                112.571,
                112.594,
                112.614,
                112.637,
                112.657,
                112.677,
                112.7,
                112.72,
                112.743,
                112.763,
                112.786,
                112.806,
                112.829,
                112.849,
                112.872,
                112.892,
                112.912,
                112.935,
                112.955,
                112.978,
                112.998,
                113.021,
                113.041,
                113.064,
                113.084,
                113.107,
                113.127,
                113.147,
                113.17,
                113.19,
                113.213,
                113.233,
                113.256,
                113.276,
                113.298,
                113.319,
                113.341,
                113.361,
                113.381,
                113.404,
                113.424,
                113.447,
                113.467,
                113.49,
                113.51,
                113.532,
                113.553,
                113.573,
                113.595,
                113.615,
                113.638,
                113.658,
                113.681,
                113.701,
                113.724,
                113.744,
                113.764,
                113.786,
                113.806,
                113.829,
                113.849,
                113.872,
                113.892,
                113.914,
                113.934,
                113.957,
                113.977,
                113.997,
                114.02,
                114.04,
                114.062,
                114.082,
                114.105,
                114.125,
                114.148,
                114.168,
                114.188,
                114.21,
                114.23,
                114.253,
                114.273,
                114.295,
                114.315,
                114.338,
                114.358,
                114.378,
                114.4,
                114.421,
                114.443,
                114.463,
                114.486,
                114.506,
                114.526,
                114.548,
                114.568,
                114.591,
                114.611,
                114.633,
                114.653,
                114.676,
                114.696,
                114.716,
                114.738,
                114.758,
                114.781,
                114.801,
                114.823,
                114.843,
                114.866,
                114.886,
                114.906,
                114.928,
                114.948,
                114.971,
                114.991,
                115.013,
                115.033,
                115.053,
                115.076,
                115.096,
                115.118,
                115.138,
                115.161,
                115.181,
                115.203,
                115.223,
                115.243,
                115.266,
                115.285,
                115.308,
                115.328,
                115.35,
                115.37,
                115.39,
                115.413,
                115.433,
                115.455,
                115.475,
                115.498,
                115.518,
                115.54,
                115.56,
                115.58,
                115.602,
                115.622,
                115.645,
                115.665,
                115.687,
                115.707,
                115.727,
                115.75,
                115.77,
                115.792,
                115.812,
                115.834,
                115.854,
                115.874,
                115.897,
                115.917,
                115.939,
                115.959,
                115.981,
                116.001,
                116.021,
                116.044,
                116.064,
                116.086,
                116.106,
                116.128,
                116.148,
                116.168,
                116.191,
                116.211,
                116.233,
                116.253,
                116.275,
                116.295,
                116.318,
                116.338,
                116.358,
                116.38,
                116.4,
                116.422,
                116.442,
                116.465,
                116.485,
                116.505,
                116.527,
                116.547,
                116.569,
                116.589,
                116.609,
                116.631,
                116.651,
                116.674,
                116.694,
                116.716,
                116.736,
                116.756,
                116.778,
                116.798,
                116.821,
                116.841,
                116.863,
                116.883,
                116.903,
                116.925,
                116.945,
                116.967,
                116.987,
                117.01,
                117.029,
                117.049,
                117.072,
                117.092,
                117.114,
                117.134,
                117.156,
                117.176,
                117.196,
                117.218,
                117.238,
                117.261,
                117.281,
                117.303,
                117.323,
                117.343,
                117.365,
                117.385,
                117.407,
                117.427,
                117.447,
                117.469,
                117.489,
                117.512,
                117.532,
                117.554,
                117.574,
                117.594,
                117.616,
                117.636,
                117.658,
                117.678,
                117.698,
                117.72,
                117.74,
                117.762,
                117.782,
                117.805,
                117.825,
                117.844,
                117.867,
                117.887,
                117.909,
                117.929,
                117.951,
                117.971,
                117.991,
                118.013,
                118.033,
                118.056,
                118.075,
                118.095,
                118.117,
                118.137,
                118.16,
                118.18,
                118.202,
                118.222,
                118.242,
                118.264,
                118.284,
                118.306,
                118.326,
                118.346,
                118.368,
                118.388,
                118.41,
                118.43,
                118.45,
                118.472,
                118.492,
                118.514,
                118.534,
                118.557,
                118.576,
                118.596,
                118.619,
                118.638,
                118.661,
                118.681,
                118.7,
                118.723,
                118.742,
                118.765,
                118.785,
                118.804,
                118.827,
                118.847,
                118.869,
                118.889,
                118.911,
                118.931,
                118.951,
                118.973,
                118.993,
                119.015,
                119.035,
                119.055,
                119.077,
                119.097,
                119.119,
                119.139,
                119.159,
                119.181,
                119.201,
                119.223,
                119.243,
                119.263,
                119.285,
                119.305,
                119.327,
                119.347,
                119.369,
                119.389,
                119.409,
                119.431,
                119.451,
                119.473,
                119.493,
                119.513,
                119.535,
                119.555,
                119.577,
                119.597,
                119.617,
                119.639,
                119.659,
                119.681,
                119.701,
                119.721,
                119.743,
                119.763,
                119.785,
                119.805,
                119.825,
                119.847,
                119.867,
                119.889,
                119.909,
                119.929,
                119.951,
                119.971,
                119.993,
                120.013,
                120.032,
                120.055,
                120.074,
                120.097,
                120.116,
                120.136,
                120.159,
                120.178,
                120.201,
                120.22,
                120.24,
                120.262,
                120.282,
                120.304,
                120.324,
                120.344,
                120.366,
                120.386,
                120.408,
                120.428,
                120.448,
                120.47,
                120.49,
                120.512,
                120.532,
                120.551,
                120.574,
                120.593,
                120.615,
                120.635,
                120.655,
                120.677,
                120.697,
                120.719,
                120.739,
                120.759,
                120.781,
                120.8,
                120.823,
                120.842,
                120.862,
                120.884,
                120.904,
                120.926,
                120.946,
                120.966,
                120.988,
                121.008,
                121.03,
                121.05,
                121.069,
                121.091,
                121.111,
                121.133,
                121.153,
                121.173,
                121.195,
                121.215,
                121.234,
                121.257,
                121.276,
                121.298,
                121.318,
                121.338,
                121.36
            ],
            P999: [
                55.734,
                55.915,
                56.096,
                56.276,
                56.456,
                56.637,
                56.817,
                56.996,
                57.176,
                57.356,
                57.536,
                57.715,
                57.894,
                58.074,
                58.254,
                58.411,
                58.569,
                58.727,
                58.883,
                59.038,
                59.192,
                59.345,
                59.497,
                59.647,
                59.798,
                59.947,
                60.096,
                60.242,
                60.388,
                60.534,
                60.677,
                60.82,
                60.962,
                61.103,
                61.242,
                61.381,
                61.518,
                61.654,
                61.79,
                61.924,
                62.057,
                62.19,
                62.32,
                62.449,
                62.578,
                62.707,
                62.833,
                62.959,
                63.084,
                63.206,
                63.329,
                63.451,
                63.572,
                63.692,
                63.811,
                63.929,
                64.046,
                64.162,
                64.278,
                64.394,
                64.507,
                64.62,
                64.734,
                64.845,
                64.956,
                65.068,
                65.177,
                65.287,
                65.395,
                65.504,
                65.612,
                65.718,
                65.824,
                65.931,
                66.036,
                66.138,
                66.242,
                66.345,
                66.448,
                66.549,
                66.65,
                66.75,
                66.85,
                66.949,
                67.047,
                67.144,
                67.241,
                67.339,
                67.434,
                67.529,
                67.623,
                67.718,
                67.81,
                67.902,
                67.996,
                68.086,
                68.178,
                68.267,
                68.358,
                68.446,
                68.535,
                68.622,
                68.71,
                68.796,
                68.882,
                68.968,
                69.052,
                69.137,
                69.221,
                69.303,
                69.386,
                69.469,
                69.551,
                69.632,
                69.711,
                69.791,
                69.87,
                69.949,
                70.028,
                70.106,
                70.183,
                70.26,
                70.336,
                70.412,
                70.487,
                70.561,
                70.635,
                70.709,
                70.782,
                70.855,
                70.927,
                70.998,
                71.071,
                71.142,
                71.212,
                71.282,
                71.351,
                71.422,
                71.49,
                71.558,
                71.626,
                71.695,
                71.762,
                71.828,
                71.896,
                71.961,
                72.028,
                72.093,
                72.157,
                72.223,
                72.287,
                72.352,
                72.415,
                72.48,
                72.542,
                72.606,
                72.667,
                72.73,
                72.793,
                72.854,
                72.916,
                72.976,
                73.037,
                73.099,
                73.158,
                73.218,
                73.279,
                73.337,
                73.396,
                73.456,
                73.515,
                73.574,
                73.631,
                73.689,
                73.748,
                73.806,
                73.864,
                73.919,
                73.976,
                74.033,
                74.09,
                74.147,
                74.203,
                74.259,
                74.315,
                74.371,
                74.427,
                74.482,
                74.537,
                74.592,
                74.647,
                74.702,
                74.756,
                74.811,
                74.865,
                74.918,
                74.972,
                75.028,
                75.081,
                75.134,
                75.188,
                75.24,
                75.293,
                75.348,
                75.4,
                75.453,
                75.505,
                75.559,
                75.611,
                75.662,
                75.714,
                75.768,
                75.819,
                75.87,
                75.924,
                75.974,
                76.025,
                76.078,
                76.129,
                76.182,
                76.232,
                76.282,
                76.335,
                76.385,
                76.437,
                76.487,
                76.539,
                76.588,
                76.64,
                76.689,
                76.741,
                76.79,
                76.841,
                76.891,
                76.942,
                76.991,
                77.042,
                77.09,
                77.141,
                77.192,
                77.24,
                77.291,
                77.342,
                77.39,
                77.44,
                77.488,
                77.539,
                77.589,
                77.637,
                77.687,
                77.737,
                77.786,
                77.834,
                77.884,
                77.934,
                77.983,
                78.03,
                78.08,
                78.129,
                78.179,
                78.226,
                78.275,
                78.324,
                78.373,
                78.422,
                78.469,
                78.518,
                78.566,
                78.615,
                78.664,
                78.712,
                78.761,
                78.809,
                78.856,
                78.904,
                78.952,
                79,
                79.048,
                79.097,
                79.145,
                79.193,
                79.24,
                79.288,
                79.336,
                79.384,
                79.431,
                79.479,
                79.526,
                79.574,
                79.621,
                79.668,
                79.715,
                79.762,
                79.809,
                79.856,
                79.903,
                79.95,
                79.997,
                80.044,
                80.092,
                80.139,
                80.186,
                80.232,
                80.278,
                80.325,
                80.371,
                80.417,
                80.466,
                80.512,
                80.558,
                80.604,
                80.649,
                80.695,
                80.743,
                80.789,
                80.834,
                80.88,
                80.925,
                80.973,
                81.018,
                81.064,
                81.109,
                81.157,
                81.202,
                81.247,
                81.292,
                81.339,
                81.384,
                81.429,
                81.476,
                81.52,
                81.565,
                81.61,
                81.656,
                81.701,
                81.745,
                81.792,
                81.836,
                81.881,
                81.927,
                81.971,
                82.015,
                82.062,
                82.106,
                82.149,
                82.196,
                82.24,
                82.286,
                82.329,
                82.373,
                82.419,
                82.462,
                82.508,
                82.552,
                82.595,
                82.641,
                82.684,
                82.73,
                82.773,
                82.818,
                82.862,
                82.907,
                82.95,
                82.993,
                83.038,
                83.081,
                83.126,
                83.169,
                83.214,
                83.257,
                83.302,
                83.345,
                83.39,
                83.432,
                83.477,
                83.52,
                83.564,
                83.607,
                83.651,
                83.694,
                83.738,
                83.783,
                83.825,
                83.869,
                83.911,
                83.956,
                83.997,
                84.042,
                84.084,
                84.128,
                84.172,
                84.214,
                84.258,
                84.299,
                84.343,
                84.387,
                84.428,
                84.472,
                84.514,
                84.557,
                84.601,
                84.642,
                84.686,
                84.729,
                84.771,
                84.814,
                84.855,
                84.898,
                84.942,
                84.983,
                85.026,
                85.069,
                85.11,
                85.153,
                85.196,
                85.237,
                85.28,
                85.323,
                85.363,
                85.406,
                85.449,
                85.489,
                85.532,
                85.575,
                85.615,
                85.657,
                85.7,
                85.743,
                85.783,
                85.825,
                85.868,
                85.908,
                85.95,
                85.992,
                86.034,
                86.074,
                86.117,
                86.159,
                86.201,
                86.24,
                86.283,
                86.324,
                86.366,
                86.406,
                86.448,
                86.49,
                86.532,
                86.571,
                86.613,
                86.654,
                86.696,
                86.735,
                86.777,
                86.818,
                86.86,
                86.902,
                86.941,
                86.982,
                87.023,
                87.065,
                87.106,
                87.145,
                87.186,
                87.228,
                87.269,
                87.31,
                87.349,
                87.39,
                87.431,
                87.472,
                87.513,
                87.554,
                87.592,
                87.633,
                87.674,
                87.715,
                87.756,
                87.797,
                87.835,
                87.876,
                87.916,
                87.957,
                87.998,
                88.038,
                88.079,
                88.117,
                88.157,
                88.198,
                88.238,
                88.279,
                88.319,
                88.359,
                88.397,
                88.438,
                88.478,
                88.518,
                88.558,
                88.598,
                88.638,
                88.679,
                88.719,
                88.756,
                88.796,
                88.836,
                88.876,
                88.916,
                88.956,
                88.996,
                89.036,
                89.075,
                89.115,
                89.155,
                89.195,
                89.232,
                89.271,
                89.311,
                89.351,
                89.39,
                89.43,
                89.469,
                89.508,
                89.548,
                89.587,
                89.627,
                89.666,
                89.705,
                89.745,
                89.784,
                89.823,
                89.862,
                89.899,
                89.938,
                89.977,
                90.016,
                90.055,
                90.094,
                90.133,
                90.172,
                90.211,
                90.25,
                90.289,
                90.327,
                90.366,
                90.405,
                90.444,
                90.482,
                90.521,
                90.56,
                90.598,
                90.637,
                90.675,
                90.714,
                90.753,
                90.791,
                90.829,
                90.868,
                90.906,
                90.944,
                90.983,
                91.021,
                91.059,
                91.098,
                91.136,
                91.174,
                91.212,
                91.25,
                91.288,
                91.326,
                91.364,
                91.402,
                91.44,
                91.478,
                91.516,
                91.554,
                91.592,
                91.629,
                91.67,
                91.708,
                91.745,
                91.783,
                91.821,
                91.858,
                91.896,
                91.933,
                91.971,
                92.009,
                92.046,
                92.084,
                92.121,
                92.158,
                92.196,
                92.233,
                92.27,
                92.308,
                92.345,
                92.382,
                92.422,
                92.459,
                92.497,
                92.534,
                92.571,
                92.608,
                92.645,
                92.682,
                92.719,
                92.756,
                92.793,
                92.83,
                92.867,
                92.904,
                92.943,
                92.98,
                93.017,
                93.054,
                93.091,
                93.128,
                93.164,
                93.201,
                93.238,
                93.274,
                93.311,
                93.35,
                93.387,
                93.423,
                93.46,
                93.496,
                93.533,
                93.569,
                93.606,
                93.642,
                93.679,
                93.718,
                93.754,
                93.79,
                93.827,
                93.863,
                93.899,
                93.935,
                93.971,
                94.008,
                94.046,
                94.082,
                94.119,
                94.155,
                94.191,
                94.227,
                94.263,
                94.299,
                94.335,
                94.373,
                94.409,
                94.445,
                94.481,
                94.517,
                94.552,
                94.588,
                94.624,
                94.662,
                94.698,
                94.734,
                94.769,
                94.805,
                94.841,
                94.876,
                94.915,
                94.95,
                94.986,
                95.021,
                95.057,
                95.092,
                95.128,
                95.163,
                95.201,
                95.236,
                95.272,
                95.307,
                95.342,
                95.378,
                95.413,
                95.451,
                95.486,
                95.521,
                95.556,
                95.592,
                95.627,
                95.662,
                95.7,
                95.735,
                95.77,
                95.805,
                95.84,
                95.875,
                95.91,
                95.948,
                95.983,
                96.018,
                96.052,
                96.087,
                96.122,
                96.157,
                96.195,
                96.229,
                96.264,
                96.299,
                96.334,
                96.369,
                96.403,
                96.441,
                96.475,
                96.51,
                96.545,
                96.579,
                96.614,
                96.648,
                96.686,
                96.72,
                96.755,
                96.789,
                96.824,
                96.858,
                96.893,
                96.93,
                96.964,
                96.998,
                97.033,
                97.067,
                97.101,
                97.136,
                97.173,
                97.207,
                97.241,
                96.576,
                96.61,
                96.644,
                96.678,
                96.715,
                96.749,
                96.783,
                96.817,
                96.851,
                96.885,
                96.919,
                96.956,
                96.99,
                97.024,
                97.058,
                97.092,
                97.126,
                97.16,
                97.193,
                97.23,
                97.264,
                97.297,
                97.331,
                97.365,
                97.399,
                97.432,
                97.469,
                97.502,
                97.536,
                97.57,
                97.603,
                97.637,
                97.67,
                97.704,
                97.74,
                97.773,
                97.807,
                97.84,
                97.873,
                97.907,
                97.94,
                97.976,
                98.009,
                98.042,
                98.076,
                98.109,
                98.142,
                98.175,
                98.208,
                98.244,
                98.277,
                98.31,
                98.343,
                98.376,
                98.409,
                98.442,
                98.475,
                98.508,
                98.543,
                98.576,
                98.609,
                98.642,
                98.674,
                98.707,
                98.74,
                98.773,
                98.805,
                98.841,
                98.873,
                98.906,
                98.939,
                98.971,
                99.004,
                99.036,
                99.069,
                99.101,
                99.134,
                99.169,
                99.201,
                99.234,
                99.266,
                99.298,
                99.33,
                99.363,
                99.395,
                99.427,
                99.459,
                99.492,
                99.527,
                99.559,
                99.591,
                99.623,
                99.655,
                99.687,
                99.719,
                99.751,
                99.783,
                99.815,
                99.847,
                99.879,
                99.914,
                99.945,
                99.977,
                100.009,
                100.041,
                100.073,
                100.104,
                100.136,
                100.168,
                100.199,
                100.231,
                100.263,
                100.294,
                100.326,
                100.357,
                100.392,
                100.423,
                100.455,
                100.486,
                100.518,
                100.549,
                100.58,
                100.612,
                100.643,
                100.674,
                100.706,
                100.737,
                100.768,
                100.799,
                100.831,
                100.862,
                100.893,
                100.924,
                100.955,
                100.986,
                101.017,
                101.048,
                101.082,
                101.113,
                101.144,
                101.175,
                101.206,
                101.237,
                101.267,
                101.298,
                101.329,
                101.36,
                101.391,
                101.421,
                101.452,
                101.483,
                101.513,
                101.544,
                101.574,
                101.605,
                101.636,
                101.666,
                101.697,
                101.727,
                101.758,
                101.788,
                101.819,
                101.849,
                101.879,
                101.91,
                101.94,
                101.97,
                102.001,
                102.031,
                102.061,
                102.091,
                102.121,
                102.152,
                102.182,
                102.212,
                102.242,
                102.272,
                102.302,
                102.332,
                102.362,
                102.392,
                102.422,
                102.452,
                102.482,
                102.512,
                102.542,
                102.572,
                102.602,
                102.631,
                102.661,
                102.688,
                102.718,
                102.748,
                102.777,
                102.807,
                102.837,
                102.866,
                102.896,
                102.925,
                102.955,
                102.985,
                103.014,
                103.044,
                103.073,
                103.103,
                103.132,
                103.162,
                103.191,
                103.22,
                103.25,
                103.276,
                103.306,
                103.335,
                103.364,
                103.393,
                103.423,
                103.452,
                103.481,
                103.51,
                103.539,
                103.569,
                103.598,
                103.627,
                103.656,
                103.682,
                103.711,
                103.74,
                103.769,
                103.798,
                103.827,
                103.856,
                103.885,
                103.914,
                103.943,
                103.972,
                103.998,
                104.026,
                104.055,
                104.084,
                104.113,
                104.142,
                104.17,
                104.199,
                104.228,
                104.256,
                104.282,
                104.311,
                104.339,
                104.368,
                104.397,
                104.425,
                104.454,
                104.482,
                104.508,
                104.536,
                104.565,
                104.593,
                104.622,
                104.65,
                104.679,
                104.707,
                104.733,
                104.761,
                104.789,
                104.817,
                104.846,
                104.874,
                104.902,
                104.928,
                104.956,
                104.984,
                105.012,
                105.041,
                105.069,
                105.097,
                105.122,
                105.15,
                105.179,
                105.207,
                105.235,
                105.263,
                105.288,
                105.316,
                105.344,
                105.372,
                105.4,
                105.428,
                105.453,
                105.481,
                105.509,
                105.537,
                105.565,
                105.59,
                105.618,
                105.646,
                105.674,
                105.702,
                105.729,
                105.754,
                105.782,
                105.81,
                105.838,
                105.866,
                105.89,
                105.918,
                105.946,
                105.974,
                106.001,
                106.026,
                106.054,
                106.082,
                106.109,
                106.137,
                106.162,
                106.189,
                106.217,
                106.244,
                106.269,
                106.297,
                106.324,
                106.352,
                106.379,
                106.404,
                106.431,
                106.459,
                106.487,
                106.511,
                106.539,
                106.566,
                106.593,
                106.621,
                106.645,
                106.673,
                106.7,
                106.728,
                106.752,
                106.779,
                106.807,
                106.834,
                106.859,
                106.886,
                106.913,
                106.941,
                106.965,
                106.992,
                107.019,
                107.047,
                107.071,
                107.098,
                107.125,
                107.153,
                107.177,
                107.204,
                107.231,
                107.255,
                107.283,
                107.31,
                107.337,
                107.361,
                107.388,
                107.415,
                107.442,
                107.466,
                107.494,
                107.521,
                107.545,
                107.572,
                107.599,
                107.626,
                107.65,
                107.677,
                107.704,
                107.728,
                107.755,
                107.782,
                107.809,
                107.833,
                107.86,
                107.887,
                107.911,
                107.937,
                107.964,
                107.988,
                108.015,
                108.042,
                108.069,
                108.093,
                108.12,
                108.146,
                108.17,
                108.197,
                108.224,
                108.248,
                108.274,
                108.301,
                108.325,
                108.352,
                108.378,
                108.402,
                108.429,
                108.455,
                108.479,
                108.506,
                108.532,
                108.556,
                108.583,
                108.609,
                108.633,
                108.66,
                108.686,
                108.71,
                108.736,
                108.763,
                108.787,
                108.813,
                108.84,
                108.863,
                108.89,
                108.916,
                108.94,
                108.966,
                108.993,
                109.016,
                109.043,
                109.069,
                109.093,
                109.119,
                109.146,
                109.169,
                109.195,
                109.219,
                109.245,
                109.272,
                109.295,
                109.321,
                109.348,
                109.371,
                109.398,
                109.424,
                109.447,
                109.474,
                109.497,
                109.523,
                109.549,
                109.573,
                109.599,
                109.625,
                109.648,
                109.675,
                109.698,
                109.724,
                109.75,
                109.773,
                109.8,
                109.823,
                109.849,
                109.875,
                109.898,
                109.924,
                109.948,
                109.974,
                110,
                110.023,
                110.049,
                110.075,
                110.098,
                110.124,
                110.147,
                110.173,
                110.199,
                110.222,
                110.248,
                110.271,
                110.297,
                110.32,
                110.346,
                110.372,
                110.395,
                110.421,
                110.444,
                110.47,
                110.496,
                110.519,
                110.545,
                110.568,
                110.593,
                110.619,
                110.642,
                110.668,
                110.691,
                110.717,
                110.739,
                110.765,
                110.791,
                110.814,
                110.84,
                110.862,
                110.888,
                110.911,
                110.937,
                110.962,
                110.985,
                111.011,
                111.033,
                111.059,
                111.082,
                111.107,
                111.133,
                111.156,
                111.181,
                111.204,
                111.23,
                111.252,
                111.278,
                111.3,
                111.326,
                111.351,
                111.374,
                111.4,
                111.422,
                111.447,
                111.47,
                111.495,
                111.518,
                111.543,
                111.566,
                111.591,
                111.617,
                111.639,
                111.665,
                111.687,
                111.712,
                111.735,
                111.76,
                111.782,
                111.808,
                111.83,
                111.856,
                111.881,
                111.903,
                111.928,
                111.951,
                111.976,
                111.998,
                112.024,
                112.046,
                112.071,
                112.093,
                112.119,
                112.141,
                112.166,
                112.188,
                112.213,
                112.235,
                112.261,
                112.286,
                112.308,
                112.333,
                112.355,
                112.38,
                112.402,
                112.427,
                112.449,
                112.475,
                112.497,
                112.522,
                112.544,
                112.569,
                112.591,
                112.616,
                112.638,
                112.663,
                112.685,
                112.71,
                112.732,
                112.757,
                112.779,
                112.804,
                112.826,
                112.851,
                112.872,
                112.897,
                112.919,
                112.944,
                112.969,
                112.991,
                113.016,
                113.038,
                113.062,
                113.084,
                113.109,
                113.131,
                113.156,
                113.178,
                113.202,
                113.224,
                113.249,
                113.271,
                113.295,
                113.317,
                113.342,
                113.363,
                113.388,
                113.41,
                113.435,
                113.456,
                113.481,
                113.503,
                113.527,
                113.549,
                113.574,
                113.595,
                113.62,
                113.642,
                113.663,
                113.688,
                113.709,
                113.734,
                113.755,
                113.78,
                113.802,
                113.826,
                113.848,
                113.872,
                113.894,
                113.918,
                113.94,
                113.964,
                113.986,
                114.01,
                114.032,
                114.056,
                114.078,
                114.102,
                114.124,
                114.148,
                114.169,
                114.194,
                114.215,
                114.24,
                114.261,
                114.285,
                114.307,
                114.331,
                114.353,
                114.377,
                114.398,
                114.42,
                114.444,
                114.465,
                114.49,
                114.511,
                114.535,
                114.557,
                114.581,
                114.602,
                114.626,
                114.648,
                114.672,
                114.693,
                114.718,
                114.739,
                114.763,
                114.784,
                114.809,
                114.83,
                114.854,
                114.875,
                114.896,
                114.921,
                114.942,
                114.966,
                114.987,
                115.011,
                115.032,
                115.057,
                115.078,
                115.102,
                115.123,
                115.147,
                115.168,
                115.193,
                115.214,
                115.235,
                115.259,
                115.28,
                115.304,
                115.325,
                115.349,
                115.37,
                115.395,
                115.416,
                115.44,
                115.461,
                115.485,
                115.506,
                115.53,
                115.551,
                115.572,
                115.596,
                115.617,
                115.641,
                115.662,
                115.686,
                115.707,
                115.731,
                115.752,
                115.776,
                115.797,
                115.821,
                115.842,
                115.863,
                115.887,
                115.908,
                115.932,
                115.953,
                115.977,
                115.998,
                116.022,
                116.043,
                116.067,
                116.088,
                116.108,
                116.132,
                116.153,
                116.177,
                116.198,
                116.222,
                116.243,
                116.267,
                116.288,
                116.312,
                116.333,
                116.353,
                116.377,
                116.398,
                116.422,
                116.443,
                116.467,
                116.488,
                116.512,
                116.533,
                116.556,
                116.577,
                116.598,
                116.622,
                116.643,
                116.667,
                116.688,
                116.711,
                116.732,
                116.756,
                116.777,
                116.798,
                116.822,
                116.842,
                116.866,
                116.887,
                116.911,
                116.932,
                116.956,
                116.976,
                116.997,
                117.021,
                117.042,
                117.065,
                117.086,
                117.11,
                117.131,
                117.155,
                117.175,
                117.199,
                117.22,
                117.241,
                117.265,
                117.285,
                117.309,
                117.33,
                117.354,
                117.374,
                117.398,
                117.419,
                117.44,
                117.463,
                117.484,
                117.508,
                117.529,
                117.552,
                117.573,
                117.597,
                117.618,
                117.638,
                117.662,
                117.683,
                117.707,
                117.727,
                117.751,
                117.772,
                117.792,
                117.816,
                117.837,
                117.861,
                117.881,
                117.905,
                117.926,
                117.949,
                117.97,
                117.991,
                118.015,
                118.035,
                118.059,
                118.08,
                118.103,
                118.124,
                118.148,
                118.168,
                118.189,
                118.213,
                118.233,
                118.257,
                118.278,
                118.302,
                118.322,
                118.343,
                118.367,
                118.387,
                118.411,
                118.432,
                118.455,
                118.476,
                118.5,
                118.52,
                118.541,
                118.565,
                118.585,
                118.609,
                118.63,
                118.654,
                118.674,
                118.695,
                118.719,
                118.739,
                118.763,
                118.783,
                118.807,
                118.828,
                118.852,
                118.872,
                118.893,
                118.917,
                118.937,
                118.961,
                118.981,
                119.005,
                119.026,
                119.046,
                119.07,
                119.091,
                119.115,
                119.135,
                119.159,
                119.179,
                119.2,
                119.224,
                119.244,
                119.268,
                119.289,
                119.312,
                119.333,
                119.353,
                119.377,
                119.398,
                119.421,
                119.442,
                119.466,
                119.486,
                119.507,
                119.531,
                119.551,
                119.575,
                119.595,
                119.619,
                119.64,
                119.664,
                119.684,
                119.705,
                119.728,
                119.749,
                119.773,
                119.793,
                119.817,
                119.837,
                119.858,
                119.882,
                119.902,
                119.926,
                119.946,
                119.967,
                119.991,
                120.011,
                120.035,
                120.055,
                120.079,
                120.1,
                120.12,
                120.144,
                120.164,
                120.188,
                120.209,
                120.232,
                120.253,
                120.273,
                120.297,
                120.318,
                120.341,
                120.362,
                120.386,
                120.406,
                120.427,
                120.45,
                120.471,
                120.495,
                120.515,
                120.539,
                120.559,
                120.58,
                120.604,
                120.624,
                120.648,
                120.668,
                120.692,
                120.712,
                120.733,
                120.757,
                120.777,
                120.801,
                120.821,
                120.842,
                120.865,
                120.886,
                120.91,
                120.93,
                120.954,
                120.974,
                120.995,
                121.019,
                121.039,
                121.063,
                121.083,
                121.104,
                121.127,
                121.148,
                121.171,
                121.192,
                121.216,
                121.236,
                121.257,
                121.28,
                121.301,
                121.325,
                121.345,
                121.369,
                121.389,
                121.41,
                121.433,
                121.454,
                121.478,
                121.498,
                121.518,
                121.542,
                121.562,
                121.586,
                121.607,
                121.63,
                121.651,
                121.671,
                121.695,
                121.715,
                121.739,
                121.76,
                121.78,
                121.804,
                121.824,
                121.848,
                121.868,
                121.889,
                121.912,
                121.933,
                121.957,
                121.977,
                122.001,
                122.021,
                122.041,
                122.065,
                122.086,
                122.109,
                122.13,
                122.15,
                122.174,
                122.194,
                122.218,
                122.238,
                122.259,
                122.282,
                122.303,
                122.327,
                122.347,
                122.371,
                122.391,
                122.412,
                122.435,
                122.456,
                122.479,
                122.5,
                122.52,
                122.544,
                122.564,
                122.588,
                122.608,
                122.629,
                122.652,
                122.673,
                122.696,
                122.717,
                122.737,
                122.761,
                122.781,
                122.805,
                122.825,
                122.849,
                122.869,
                122.89,
                122.914,
                122.934,
                122.958,
                122.978,
                122.998,
                123.022,
                123.042,
                123.066,
                123.086,
                123.107,
                123.131,
                123.151,
                123.175,
                123.195,
                123.215,
                123.239,
                123.259,
                123.283,
                123.304,
                123.324,
                123.348,
                123.368,
                123.392,
                123.412,
                123.432,
                123.456,
                123.476,
                123.5,
                123.52,
                123.541,
                123.564,
                123.585,
                123.608,
                123.629,
                123.649,
                123.673,
                123.693,
                123.717,
                123.737,
                123.757,
                123.781,
                123.801,
                123.825,
                123.845,
                123.866,
                123.889,
                123.91,
                123.933,
                123.954,
                123.974,
                123.998,
                124.018,
                124.042,
                124.062,
                124.082,
                124.106,
                124.126,
                124.15,
                124.17,
                124.191,
                124.214,
                124.234,
                124.258,
                124.278,
                124.299,
                124.322,
                124.343,
                124.366,
                124.387,
                124.407,
                124.431,
                124.451,
                124.474,
                124.495,
                124.515,
                124.539,
                124.559,
                124.583,
                124.603,
                124.623,
                124.647,
                124.667,
                124.691,
                124.711,
                124.731,
                124.755,
                124.775,
                124.795,
                124.819,
                124.839,
                124.863,
                124.883,
                124.903,
                124.927
            ]
        },
        girls: {
            P01: [
                43.392,
                43.551,
                43.711,
                43.869,
                44.029,
                44.19,
                44.35,
                44.51,
                44.67,
                44.83,
                44.989,
                45.15,
                45.31,
                45.471,
                45.632,
                45.761,
                45.889,
                46.017,
                46.143,
                46.27,
                46.395,
                46.52,
                46.643,
                46.766,
                46.887,
                47.009,
                47.128,
                47.247,
                47.365,
                47.482,
                47.598,
                47.714,
                47.827,
                47.94,
                48.053,
                48.165,
                48.275,
                48.386,
                48.495,
                48.603,
                48.711,
                48.816,
                48.923,
                49.027,
                49.133,
                49.235,
                49.337,
                49.44,
                49.541,
                49.641,
                49.74,
                49.84,
                49.937,
                50.034,
                50.131,
                50.226,
                50.321,
                50.415,
                50.509,
                50.602,
                50.694,
                50.786,
                50.877,
                50.968,
                51.058,
                51.146,
                51.235,
                51.323,
                51.411,
                51.498,
                51.583,
                51.67,
                51.755,
                51.839,
                51.924,
                52.008,
                52.09,
                52.173,
                52.254,
                52.336,
                52.416,
                52.498,
                52.577,
                52.657,
                52.735,
                52.815,
                52.892,
                52.969,
                53.047,
                53.123,
                53.2,
                53.275,
                53.35,
                53.426,
                53.499,
                53.573,
                53.646,
                53.72,
                53.793,
                53.864,
                53.936,
                54.007,
                54.079,
                54.15,
                54.22,
                54.289,
                54.358,
                54.427,
                54.496,
                54.564,
                54.633,
                54.701,
                54.768,
                54.834,
                54.901,
                54.967,
                55.032,
                55.097,
                55.162,
                55.227,
                55.291,
                55.353,
                55.417,
                55.48,
                55.543,
                55.605,
                55.667,
                55.729,
                55.791,
                55.852,
                55.911,
                55.972,
                56.032,
                56.092,
                56.152,
                56.209,
                56.268,
                56.327,
                56.386,
                56.444,
                56.5,
                56.558,
                56.615,
                56.67,
                56.727,
                56.784,
                56.839,
                56.895,
                56.951,
                57.004,
                57.06,
                57.115,
                57.168,
                57.223,
                57.277,
                57.33,
                57.384,
                57.436,
                57.489,
                57.541,
                57.594,
                57.647,
                57.698,
                57.751,
                57.801,
                57.854,
                57.904,
                57.956,
                58.005,
                58.057,
                58.106,
                58.156,
                58.207,
                58.256,
                58.307,
                58.355,
                58.406,
                58.454,
                58.502,
                58.552,
                58.6,
                58.648,
                58.698,
                58.745,
                58.795,
                58.842,
                58.889,
                58.936,
                58.985,
                59.031,
                59.078,
                59.126,
                59.173,
                59.219,
                59.267,
                59.313,
                59.359,
                59.405,
                59.451,
                59.498,
                59.544,
                59.589,
                59.634,
                59.681,
                59.726,
                59.771,
                59.816,
                59.861,
                59.906,
                59.952,
                59.997,
                60.041,
                60.085,
                60.13,
                60.174,
                60.218,
                60.262,
                60.308,
                60.351,
                60.395,
                60.439,
                60.482,
                60.526,
                60.569,
                60.612,
                60.655,
                60.698,
                60.742,
                60.785,
                60.827,
                60.87,
                60.913,
                60.956,
                60.998,
                61.041,
                61.083,
                61.126,
                61.168,
                61.21,
                61.252,
                61.295,
                61.337,
                61.379,
                61.421,
                61.462,
                61.504,
                61.546,
                61.588,
                61.629,
                61.671,
                61.712,
                61.751,
                61.793,
                61.834,
                61.875,
                61.916,
                61.957,
                61.998,
                62.039,
                62.08,
                62.119,
                62.16,
                62.201,
                62.241,
                62.282,
                62.322,
                62.361,
                62.401,
                62.442,
                62.482,
                62.522,
                62.56,
                62.601,
                62.641,
                62.681,
                62.721,
                62.759,
                62.799,
                62.838,
                62.878,
                62.918,
                62.956,
                62.995,
                63.035,
                63.074,
                63.112,
                63.151,
                63.191,
                63.228,
                63.267,
                63.306,
                63.346,
                63.383,
                63.422,
                63.461,
                63.497,
                63.537,
                63.575,
                63.614,
                63.651,
                63.69,
                63.728,
                63.765,
                63.803,
                63.842,
                63.878,
                63.917,
                63.955,
                63.991,
                64.03,
                64.068,
                64.104,
                64.142,
                64.178,
                64.216,
                64.254,
                64.29,
                64.328,
                64.366,
                64.402,
                64.44,
                64.475,
                64.513,
                64.551,
                64.586,
                64.624,
                64.659,
                64.696,
                64.734,
                64.769,
                64.806,
                64.841,
                64.879,
                64.914,
                64.951,
                64.988,
                65.023,
                65.06,
                65.095,
                65.132,
                65.166,
                65.203,
                65.238,
                65.275,
                65.309,
                65.346,
                65.383,
                65.417,
                65.454,
                65.488,
                65.524,
                65.559,
                65.595,
                65.629,
                65.665,
                65.699,
                65.736,
                65.77,
                65.806,
                65.84,
                65.876,
                65.909,
                65.946,
                65.979,
                66.015,
                66.049,
                66.085,
                66.118,
                66.154,
                66.187,
                66.221,
                66.256,
                66.29,
                66.325,
                66.358,
                66.394,
                66.427,
                66.462,
                66.496,
                66.531,
                66.564,
                66.597,
                66.632,
                66.665,
                66.7,
                66.733,
                66.768,
                66.801,
                66.835,
                66.868,
                66.901,
                66.935,
                66.968,
                67.003,
                67.035,
                67.07,
                67.102,
                67.135,
                67.169,
                67.201,
                67.236,
                67.268,
                67.3,
                67.335,
                67.367,
                67.401,
                67.433,
                67.465,
                67.499,
                67.531,
                67.565,
                67.597,
                67.629,
                67.663,
                67.694,
                67.728,
                67.76,
                67.791,
                67.825,
                67.857,
                67.888,
                67.922,
                67.953,
                67.987,
                68.018,
                68.05,
                68.083,
                68.114,
                68.145,
                68.179,
                68.21,
                68.241,
                68.274,
                68.305,
                68.339,
                68.37,
                68.4,
                68.434,
                68.464,
                68.495,
                68.528,
                68.559,
                68.59,
                68.622,
                68.653,
                68.684,
                68.716,
                68.747,
                68.777,
                68.81,
                68.841,
                68.873,
                68.904,
                68.934,
                68.966,
                68.997,
                69.027,
                69.059,
                69.089,
                69.12,
                69.152,
                69.182,
                69.212,
                69.244,
                69.274,
                69.304,
                69.336,
                69.366,
                69.396,
                69.426,
                69.458,
                69.487,
                69.517,
                69.549,
                69.579,
                69.608,
                69.64,
                69.67,
                69.699,
                69.731,
                69.76,
                69.79,
                69.821,
                69.851,
                69.88,
                69.912,
                69.941,
                69.97,
                69.999,
                70.031,
                70.06,
                70.089,
                70.12,
                70.149,
                70.178,
                70.21,
                70.239,
                70.267,
                70.299,
                70.328,
                70.356,
                70.385,
                70.416,
                70.445,
                70.474,
                70.505,
                70.533,
                70.562,
                70.593,
                70.621,
                70.65,
                70.678,
                70.709,
                70.738,
                70.766,
                70.797,
                70.825,
                70.853,
                70.882,
                70.912,
                70.941,
                70.969,
                70.999,
                71.027,
                71.055,
                71.083,
                71.114,
                71.142,
                71.17,
                71.2,
                71.228,
                71.256,
                71.284,
                71.314,
                71.342,
                71.37,
                71.4,
                71.428,
                71.455,
                71.483,
                71.513,
                71.541,
                71.568,
                71.598,
                71.626,
                71.653,
                71.681,
                71.711,
                71.738,
                71.765,
                71.793,
                71.823,
                71.85,
                71.877,
                71.907,
                71.934,
                71.961,
                71.988,
                72.018,
                72.045,
                72.072,
                72.099,
                72.129,
                72.156,
                72.183,
                72.21,
                72.239,
                72.266,
                72.293,
                72.322,
                72.349,
                72.376,
                72.403,
                72.432,
                72.458,
                72.485,
                72.512,
                72.541,
                72.568,
                72.594,
                72.621,
                72.65,
                72.676,
                72.703,
                72.732,
                72.758,
                72.785,
                72.811,
                72.84,
                72.866,
                72.892,
                72.919,
                72.947,
                72.974,
                73,
                73.026,
                73.055,
                73.081,
                73.107,
                73.133,
                73.162,
                73.188,
                73.214,
                73.24,
                73.268,
                73.294,
                73.32,
                73.346,
                73.374,
                73.4,
                73.426,
                73.452,
                73.48,
                73.506,
                73.532,
                73.557,
                73.585,
                73.611,
                73.637,
                73.665,
                73.69,
                73.716,
                73.741,
                73.77,
                73.795,
                73.82,
                73.846,
                73.874,
                73.899,
                73.925,
                73.95,
                73.978,
                74.003,
                74.028,
                74.054,
                74.081,
                74.106,
                74.132,
                74.157,
                74.184,
                74.21,
                74.235,
                74.26,
                74.287,
                74.312,
                74.337,
                74.362,
                74.39,
                74.415,
                74.439,
                74.464,
                74.492,
                74.517,
                74.541,
                74.566,
                74.593,
                74.618,
                74.643,
                74.667,
                74.695,
                74.719,
                74.744,
                74.768,
                74.796,
                74.82,
                74.845,
                74.869,
                74.896,
                74.921,
                74.945,
                74.969,
                74.996,
                75.021,
                75.045,
                75.069,
                75.096,
                75.12,
                75.145,
                75.169,
                75.196,
                75.22,
                75.244,
                75.268,
                75.295,
                75.319,
                75.343,
                75.367,
                75.393,
                75.417,
                75.441,
                75.465,
                75.492,
                75.516,
                75.54,
                75.566,
                75.59,
                75.614,
                75.637,
                75.664,
                75.688,
                75.711,
                75.735,
                75.761,
                75.785,
                75.809,
                75.832,
                75.858,
                75.882,
                75.905,
                75.929,
                75.955,
                75.978,
                76.002,
                76.025,
                76.051,
                76.075,
                76.098,
                76.122,
                76.148,
                76.171,
                76.194,
                76.217,
                76.243,
                76.267,
                76.29,
                76.316,
                76.339,
                76.362,
                76.385,
                76.411,
                76.434,
                75.758,
                75.781,
                75.804,
                75.827,
                75.853,
                75.876,
                75.899,
                75.921,
                75.947,
                75.97,
                75.993,
                76.018,
                76.041,
                76.064,
                76.087,
                76.112,
                76.135,
                76.157,
                76.18,
                76.205,
                76.228,
                76.251,
                76.276,
                76.298,
                76.321,
                76.344,
                76.369,
                76.391,
                76.414,
                76.436,
                76.461,
                76.484,
                76.506,
                76.531,
                76.553,
                76.576,
                76.598,
                76.623,
                76.645,
                76.668,
                76.69,
                76.715,
                76.737,
                76.759,
                76.784,
                76.806,
                76.828,
                76.85,
                76.875,
                76.897,
                76.919,
                76.944,
                76.966,
                76.988,
                77.01,
                77.035,
                77.057,
                77.078,
                77.103,
                77.125,
                77.147,
                77.169,
                77.193,
                77.215,
                77.237,
                77.258,
                77.283,
                77.305,
                77.326,
                77.351,
                77.372,
                77.394,
                77.415,
                77.44,
                77.461,
                77.483,
                77.507,
                77.529,
                77.55,
                77.574,
                77.596,
                77.617,
                77.639,
                77.663,
                77.684,
                77.706,
                77.729,
                77.751,
                77.772,
                77.793,
                77.817,
                77.839,
                77.86,
                77.884,
                77.905,
                77.926,
                77.947,
                77.971,
                77.992,
                78.013,
                78.037,
                78.058,
                78.079,
                78.103,
                78.124,
                78.145,
                78.166,
                78.189,
                78.21,
                78.231,
                78.255,
                78.276,
                78.296,
                78.32,
                78.341,
                78.362,
                78.382,
                78.406,
                78.426,
                78.447,
                78.471,
                78.491,
                78.512,
                78.535,
                78.556,
                78.576,
                78.597,
                78.62,
                78.641,
                78.661,
                78.684,
                78.705,
                78.725,
                78.748,
                78.769,
                78.789,
                78.812,
                78.833,
                78.853,
                78.873,
                78.896,
                78.917,
                78.937,
                78.96,
                78.98,
                79,
                79.023,
                79.043,
                79.064,
                79.086,
                79.107,
                79.127,
                79.149,
                79.169,
                79.19,
                79.212,
                79.232,
                79.252,
                79.272,
                79.295,
                79.315,
                79.335,
                79.357,
                79.377,
                79.397,
                79.42,
                79.44,
                79.459,
                79.482,
                79.502,
                79.521,
                79.544,
                79.564,
                79.583,
                79.606,
                79.625,
                79.645,
                79.667,
                79.687,
                79.707,
                79.729,
                79.748,
                79.768,
                79.79,
                79.81,
                79.829,
                79.851,
                79.871,
                79.89,
                79.912,
                79.932,
                79.951,
                79.973,
                79.993,
                80.012,
                80.034,
                80.053,
                80.073,
                80.095,
                80.114,
                80.133,
                80.155,
                80.174,
                80.193,
                80.215,
                80.234,
                80.254,
                80.275,
                80.295,
                80.313,
                80.335,
                80.354,
                80.373,
                80.395,
                80.414,
                80.433,
                80.455,
                80.474,
                80.493,
                80.514,
                80.533,
                80.555,
                80.574,
                80.593,
                80.614,
                80.633,
                80.652,
                80.674,
                80.692,
                80.711,
                80.733,
                80.751,
                80.77,
                80.791,
                80.81,
                80.829,
                80.85,
                80.869,
                80.89,
                80.909,
                80.927,
                80.949,
                80.967,
                80.986,
                81.007,
                81.026,
                81.044,
                81.065,
                81.084,
                81.105,
                81.124,
                81.142,
                81.163,
                81.182,
                81.2,
                81.221,
                81.24,
                81.258,
                81.279,
                81.297,
                81.318,
                81.337,
                81.355,
                81.376,
                81.394,
                81.413,
                81.434,
                81.452,
                81.473,
                81.491,
                81.509,
                81.53,
                81.548,
                81.566,
                81.587,
                81.605,
                81.626,
                81.644,
                81.662,
                81.683,
                81.701,
                81.719,
                81.74,
                81.758,
                81.779,
                81.797,
                81.815,
                81.836,
                81.854,
                81.874,
                81.892,
                81.91,
                81.931,
                81.949,
                81.969,
                81.987,
                82.005,
                82.026,
                82.043,
                82.061,
                82.082,
                82.1,
                82.12,
                82.138,
                82.156,
                82.176,
                82.194,
                82.214,
                82.232,
                82.25,
                82.27,
                82.288,
                82.308,
                82.326,
                82.344,
                82.364,
                82.382,
                82.402,
                82.42,
                82.437,
                82.458,
                82.475,
                82.495,
                82.513,
                82.53,
                82.551,
                82.568,
                82.589,
                82.606,
                82.623,
                82.644,
                82.661,
                82.681,
                82.699,
                82.716,
                82.736,
                82.754,
                82.774,
                82.791,
                82.811,
                82.829,
                82.846,
                82.866,
                82.883,
                82.904,
                82.921,
                82.938,
                82.958,
                82.975,
                82.995,
                83.013,
                83.03,
                83.05,
                83.067,
                83.087,
                83.104,
                83.124,
                83.141,
                83.158,
                83.178,
                83.196,
                83.216,
                83.233,
                83.253,
                83.27,
                83.287,
                83.307,
                83.324,
                83.343,
                83.361,
                83.38,
                83.397,
                83.414,
                83.434,
                83.451,
                83.471,
                83.488,
                83.508,
                83.525,
                83.542,
                83.561,
                83.578,
                83.598,
                83.615,
                83.635,
                83.651,
                83.668,
                83.688,
                83.705,
                83.724,
                83.741,
                83.761,
                83.778,
                83.794,
                83.814,
                83.831,
                83.85,
                83.867,
                83.887,
                83.903,
                83.923,
                83.94,
                83.956,
                83.976,
                83.993,
                84.012,
                84.029,
                84.048,
                84.065,
                84.082,
                84.101,
                84.118,
                84.137,
                84.154,
                84.173,
                84.19,
                84.209,
                84.226,
                84.245,
                84.262,
                84.278,
                84.298,
                84.314,
                84.333,
                84.35,
                84.369,
                84.386,
                84.405,
                84.421,
                84.438,
                84.457,
                84.473,
                84.493,
                84.509,
                84.528,
                84.545,
                84.564,
                84.58,
                84.6,
                84.616,
                84.632,
                84.651,
                84.668,
                84.687,
                84.703,
                84.722,
                84.739,
                84.758,
                84.774,
                84.793,
                84.809,
                84.829,
                84.845,
                84.861,
                84.88,
                84.896,
                84.915,
                84.931,
                84.95,
                84.967,
                84.986,
                85.002,
                85.021,
                85.037,
                85.056,
                85.072,
                85.088,
                85.107,
                85.123,
                85.142,
                85.158,
                85.177,
                85.193,
                85.212,
                85.228,
                85.247,
                85.263,
                85.282,
                85.298,
                85.317,
                85.332,
                85.351,
                85.367,
                85.383,
                85.402,
                85.418,
                85.437,
                85.452,
                85.471,
                85.487,
                85.506,
                85.522,
                85.541,
                85.556,
                85.575,
                85.591,
                85.61,
                85.625,
                85.644,
                85.66,
                85.679,
                85.694,
                85.713,
                85.729,
                85.747,
                85.763,
                85.779,
                85.797,
                85.813,
                85.832,
                85.847,
                85.866,
                85.882,
                85.9,
                85.916,
                85.934,
                85.95,
                85.969,
                85.984,
                86.003,
                86.018,
                86.037,
                86.052,
                86.071,
                86.086,
                86.105,
                86.12,
                86.139,
                86.154,
                86.173,
                86.188,
                86.207,
                86.222,
                86.241,
                86.256,
                86.274,
                86.29,
                86.308,
                86.324,
                86.342,
                86.357,
                86.376,
                86.391,
                86.406,
                86.425,
                86.44,
                86.458,
                86.474,
                86.492,
                86.507,
                86.526,
                86.541,
                86.559,
                86.574,
                86.593,
                86.608,
                86.626,
                86.641,
                86.66,
                86.675,
                86.693,
                86.708,
                86.726,
                86.741,
                86.76,
                86.775,
                86.793,
                86.808,
                86.826,
                86.841,
                86.859,
                86.874,
                86.893,
                86.908,
                86.926,
                86.941,
                86.959,
                86.974,
                86.992,
                87.007,
                87.025,
                87.04,
                87.058,
                87.073,
                87.091,
                87.106,
                87.124,
                87.139,
                87.157,
                87.172,
                87.19,
                87.208,
                87.223,
                87.241,
                87.255,
                87.273,
                87.288,
                87.306,
                87.321,
                87.339,
                87.354,
                87.372,
                87.386,
                87.404,
                87.419,
                87.437,
                87.452,
                87.469,
                87.484,
                87.502,
                87.517,
                87.535,
                87.549,
                87.567,
                87.582,
                87.599,
                87.614,
                87.632,
                87.647,
                87.664,
                87.679,
                87.697,
                87.711,
                87.729,
                87.744,
                87.761,
                87.776,
                87.794,
                87.808,
                87.826,
                87.84,
                87.858,
                87.876,
                87.89,
                87.908,
                87.922,
                87.94,
                87.954,
                87.972,
                87.986,
                88.004,
                88.018,
                88.036,
                88.05,
                88.068,
                88.082,
                88.1,
                88.114,
                88.132,
                88.146,
                88.164,
                88.178,
                88.195,
                88.21,
                88.227,
                88.242,
                88.259,
                88.277,
                88.291,
                88.308,
                88.323,
                88.34,
                88.354,
                88.372,
                88.386,
                88.403,
                88.417,
                88.435,
                88.449,
                88.466,
                88.481,
                88.498,
                88.512,
                88.53,
                88.544,
                88.561,
                88.578,
                88.592,
                88.61,
                88.624,
                88.641,
                88.655,
                88.672,
                88.687,
                88.704,
                88.718,
                88.735,
                88.749,
                88.766,
                88.781,
                88.798,
                88.812,
                88.829,
                88.846,
                88.86,
                88.877,
                88.891,
                88.908,
                88.922,
                88.94,
                88.954,
                88.971,
                88.985,
                89.002,
                89.016,
                89.033,
                89.05,
                89.064,
                89.081,
                89.095,
                89.112,
                89.126,
                89.143,
                89.157,
                89.174,
                89.188,
                89.205,
                89.218,
                89.235,
                89.252,
                89.266,
                89.283,
                89.297,
                89.314,
                89.328,
                89.345,
                89.359,
                89.375,
                89.389,
                89.406,
                89.42,
                89.437,
                89.454,
                89.468,
                89.484,
                89.498,
                89.515,
                89.529,
                89.546,
                89.559,
                89.576,
                89.59,
                89.607,
                89.623,
                89.637,
                89.654,
                89.668,
                89.684,
                89.698,
                89.715,
                89.728,
                89.745,
                89.759,
                89.776,
                89.792,
                89.806,
                89.823,
                89.836,
                89.853,
                89.867,
                89.883,
                89.897,
                89.914,
                89.927,
                89.944,
                89.961,
                89.974,
                89.991,
                90.004,
                90.021,
                90.035,
                90.051,
                90.065,
                90.081,
                90.098,
                90.111,
                90.128,
                90.141,
                90.158,
                90.172,
                90.188,
                90.202,
                90.218,
                90.235,
                90.248,
                90.265,
                90.278,
                90.295,
                90.308,
                90.325,
                90.338,
                90.355,
                90.371,
                90.384,
                90.401,
                90.414,
                90.431,
                90.444,
                90.461,
                90.474,
                90.49,
                90.507,
                90.52,
                90.537,
                90.55,
                90.566,
                90.58,
                90.596,
                90.613,
                90.626,
                90.642,
                90.655,
                90.672,
                90.685,
                90.702,
                90.715,
                90.731,
                90.748,
                90.761,
                90.777,
                90.79,
                90.807,
                90.82,
                90.836,
                90.853,
                90.866,
                90.882,
                90.895,
                90.912,
                90.925,
                90.941,
                90.954,
                90.97,
                90.987,
                91,
                91.016,
                91.029,
                91.045,
                91.058,
                91.075,
                91.091,
                91.104,
                91.12,
                91.133,
                91.15,
                91.163,
                91.179,
                91.195,
                91.208,
                91.224,
                91.237,
                91.254,
                91.267,
                91.283,
                91.299,
                91.312,
                91.328,
                91.341,
                91.357,
                91.37,
                91.386,
                91.403,
                91.415,
                91.432,
                91.444,
                91.461,
                91.474,
                91.49,
                91.506,
                91.519,
                91.535,
                91.548,
                91.564,
                91.577,
                91.593,
                91.609,
                91.622,
                91.638,
                91.651,
                91.667,
                91.679,
                91.696,
                91.712,
                91.724,
                91.74,
                91.753,
                91.769,
                91.782,
                91.798,
                91.814,
                91.827,
                91.843,
                91.856,
                91.872,
                91.887,
                91.9,
                91.916,
                91.929,
                91.945,
                91.958,
                91.974,
                91.989,
                92.002,
                92.018,
                92.031,
                92.047,
                92.059,
                92.075,
                92.091,
                92.104,
                92.12,
                92.132,
                92.148,
                92.164,
                92.177,
                92.193,
                92.205,
                92.221,
                92.234,
                92.25,
                92.265,
                92.278,
                92.294,
                92.306,
                92.322,
                92.338,
                92.351,
                92.366,
                92.379,
                92.395,
                92.407,
                92.423,
                92.439,
                92.451,
                92.467,
                92.48,
                92.495,
                92.511,
                92.524,
                92.539,
                92.552,
                92.568,
                92.58,
                92.596,
                92.612,
                92.624,
                92.64,
                92.652,
                92.668,
                92.684,
                92.696,
                92.712,
                92.724,
                92.74,
                92.755,
                92.768,
                92.783,
                92.796,
                92.811,
                92.824,
                92.839,
                92.855,
                92.867,
                92.883,
                92.895,
                92.911,
                92.927,
                92.939,
                92.954,
                92.967,
                92.982,
                92.998,
                93.01,
                93.026,
                93.038,
                93.054,
                93.066,
                93.081,
                93.097,
                93.109,
                93.125,
                93.137,
                93.152,
                93.168,
                93.18,
                93.196,
                93.208,
                93.223,
                93.239,
                93.251,
                93.267,
                93.279,
                93.294,
                93.31,
                93.322,
                93.337,
                93.349,
                93.365,
                93.377,
                93.392,
                93.408,
                93.42,
                93.435,
                93.447,
                93.463,
                93.478,
                93.49,
                93.506,
                93.518,
                93.533,
                93.549,
                93.561,
                93.576,
                93.588,
                93.603,
                93.619,
                93.631,
                93.646,
                93.658,
                93.673,
                93.689,
                93.701,
                93.716,
                93.728,
                93.743,
                93.759,
                93.771,
                93.786,
                93.798,
                93.813,
                93.825,
                93.84,
                93.856,
                93.868,
                93.883,
                93.895,
                93.91,
                93.925,
                93.937,
                93.952,
                93.964,
                93.98,
                93.995,
                94.007,
                94.022,
                94.034,
                94.049,
                94.064,
                94.076,
                94.091,
                94.103,
                94.118,
                94.133,
                94.145,
                94.16,
                94.172,
                94.187,
                94.202,
                94.214,
                94.229,
                94.241,
                94.256,
                94.271,
                94.283,
                94.298,
                94.31,
                94.325,
                94.34,
                94.352,
                94.367,
                94.379,
                94.394,
                94.409,
                94.42,
                94.436,
                94.447,
                94.462,
                94.477,
                94.489,
                94.504,
                94.516,
                94.531,
                94.546,
                94.557,
                94.572,
                94.584,
                94.599,
                94.614,
                94.626,
                94.641,
                94.652,
                94.667,
                94.682,
                94.694,
                94.709,
                94.724,
                94.735,
                94.75,
                94.762,
                94.777,
                94.792,
                94.803,
                94.818,
                94.83,
                94.845,
                94.86,
                94.871,
                94.886,
                94.898,
                94.913,
                94.927,
                94.939,
                94.954,
                94.965,
                94.98,
                94.995,
                95.007,
                95.021,
                95.033,
                95.048,
                95.063,
                95.074,
                95.089,
                95.1,
                95.115,
                95.13
            ],
            P1: [
                44.814,
                44.976,
                45.138,
                45.299,
                45.462,
                45.624,
                45.786,
                45.948,
                46.111,
                46.273,
                46.435,
                46.597,
                46.76,
                46.923,
                47.085,
                47.217,
                47.347,
                47.478,
                47.607,
                47.736,
                47.863,
                47.991,
                48.117,
                48.241,
                48.365,
                48.489,
                48.611,
                48.732,
                48.852,
                48.971,
                49.09,
                49.208,
                49.323,
                49.439,
                49.554,
                49.668,
                49.78,
                49.893,
                50.005,
                50.114,
                50.225,
                50.333,
                50.441,
                50.548,
                50.655,
                50.76,
                50.864,
                50.969,
                51.071,
                51.173,
                51.274,
                51.376,
                51.476,
                51.575,
                51.673,
                51.771,
                51.867,
                51.964,
                52.059,
                52.154,
                52.248,
                52.342,
                52.435,
                52.527,
                52.619,
                52.709,
                52.8,
                52.89,
                52.979,
                53.068,
                53.156,
                53.244,
                53.331,
                53.416,
                53.503,
                53.589,
                53.672,
                53.757,
                53.84,
                53.924,
                54.006,
                54.089,
                54.17,
                54.251,
                54.331,
                54.412,
                54.491,
                54.57,
                54.649,
                54.727,
                54.806,
                54.882,
                54.959,
                55.036,
                55.112,
                55.187,
                55.261,
                55.337,
                55.411,
                55.484,
                55.557,
                55.63,
                55.704,
                55.776,
                55.847,
                55.918,
                55.989,
                56.059,
                56.129,
                56.199,
                56.27,
                56.339,
                56.407,
                56.475,
                56.543,
                56.61,
                56.677,
                56.744,
                56.81,
                56.876,
                56.942,
                57.006,
                57.07,
                57.135,
                57.199,
                57.263,
                57.327,
                57.39,
                57.453,
                57.515,
                57.576,
                57.638,
                57.699,
                57.761,
                57.822,
                57.881,
                57.941,
                58.001,
                58.061,
                58.12,
                58.178,
                58.237,
                58.296,
                58.353,
                58.411,
                58.468,
                58.524,
                58.582,
                58.639,
                58.694,
                58.751,
                58.807,
                58.862,
                58.917,
                58.973,
                59.027,
                59.082,
                59.135,
                59.19,
                59.243,
                59.297,
                59.352,
                59.404,
                59.458,
                59.51,
                59.563,
                59.615,
                59.667,
                59.719,
                59.771,
                59.822,
                59.873,
                59.925,
                59.975,
                60.027,
                60.077,
                60.129,
                60.178,
                60.228,
                60.279,
                60.328,
                60.377,
                60.428,
                60.477,
                60.527,
                60.576,
                60.624,
                60.673,
                60.722,
                60.77,
                60.818,
                60.868,
                60.916,
                60.963,
                61.012,
                61.059,
                61.107,
                61.154,
                61.201,
                61.249,
                61.296,
                61.343,
                61.389,
                61.437,
                61.484,
                61.53,
                61.576,
                61.622,
                61.668,
                61.716,
                61.761,
                61.807,
                61.853,
                61.898,
                61.943,
                61.989,
                62.034,
                62.081,
                62.126,
                62.171,
                62.216,
                62.261,
                62.305,
                62.35,
                62.394,
                62.439,
                62.483,
                62.528,
                62.572,
                62.616,
                62.66,
                62.704,
                62.748,
                62.792,
                62.836,
                62.879,
                62.923,
                62.967,
                63.01,
                63.053,
                63.097,
                63.14,
                63.183,
                63.226,
                63.27,
                63.313,
                63.355,
                63.398,
                63.441,
                63.484,
                63.527,
                63.568,
                63.61,
                63.653,
                63.695,
                63.737,
                63.78,
                63.822,
                63.864,
                63.906,
                63.947,
                63.989,
                64.031,
                64.073,
                64.114,
                64.156,
                64.196,
                64.238,
                64.279,
                64.321,
                64.363,
                64.402,
                64.444,
                64.485,
                64.526,
                64.567,
                64.607,
                64.648,
                64.689,
                64.73,
                64.771,
                64.81,
                64.851,
                64.892,
                64.933,
                64.972,
                65.012,
                65.053,
                65.092,
                65.132,
                65.173,
                65.213,
                65.252,
                65.292,
                65.332,
                65.371,
                65.411,
                65.451,
                65.491,
                65.529,
                65.569,
                65.609,
                65.647,
                65.687,
                65.727,
                65.765,
                65.804,
                65.844,
                65.882,
                65.921,
                65.96,
                65.998,
                66.038,
                66.075,
                66.114,
                66.154,
                66.191,
                66.23,
                66.269,
                66.306,
                66.345,
                66.383,
                66.421,
                66.46,
                66.497,
                66.536,
                66.573,
                66.612,
                66.65,
                66.687,
                66.726,
                66.762,
                66.801,
                66.837,
                66.876,
                66.914,
                66.95,
                66.989,
                67.025,
                67.063,
                67.099,
                67.137,
                67.174,
                67.212,
                67.248,
                67.286,
                67.323,
                67.359,
                67.397,
                67.433,
                67.47,
                67.506,
                67.544,
                67.58,
                67.617,
                67.653,
                67.69,
                67.726,
                67.763,
                67.798,
                67.835,
                67.871,
                67.908,
                67.943,
                67.98,
                68.015,
                68.052,
                68.087,
                68.124,
                68.159,
                68.194,
                68.231,
                68.266,
                68.303,
                68.338,
                68.374,
                68.409,
                68.445,
                68.48,
                68.516,
                68.551,
                68.586,
                68.622,
                68.656,
                68.692,
                68.727,
                68.763,
                68.797,
                68.833,
                68.868,
                68.902,
                68.938,
                68.972,
                69.008,
                69.042,
                69.077,
                69.111,
                69.145,
                69.181,
                69.215,
                69.25,
                69.284,
                69.318,
                69.353,
                69.387,
                69.422,
                69.456,
                69.489,
                69.525,
                69.558,
                69.593,
                69.627,
                69.66,
                69.695,
                69.728,
                69.763,
                69.796,
                69.83,
                69.865,
                69.898,
                69.931,
                69.965,
                69.998,
                70.033,
                70.066,
                70.099,
                70.133,
                70.166,
                70.199,
                70.233,
                70.266,
                70.299,
                70.333,
                70.365,
                70.4,
                70.432,
                70.465,
                70.499,
                70.531,
                70.564,
                70.598,
                70.63,
                70.662,
                70.696,
                70.728,
                70.76,
                70.794,
                70.826,
                70.858,
                70.892,
                70.924,
                70.958,
                70.99,
                71.022,
                71.055,
                71.087,
                71.119,
                71.152,
                71.184,
                71.216,
                71.249,
                71.281,
                71.312,
                71.345,
                71.377,
                71.408,
                71.442,
                71.473,
                71.504,
                71.536,
                71.569,
                71.6,
                71.631,
                71.664,
                71.695,
                71.726,
                71.759,
                71.79,
                71.821,
                71.854,
                71.885,
                71.916,
                71.949,
                71.98,
                72.011,
                72.043,
                72.074,
                72.105,
                72.135,
                72.168,
                72.199,
                72.229,
                72.262,
                72.292,
                72.323,
                72.355,
                72.385,
                72.416,
                72.448,
                72.479,
                72.509,
                72.539,
                72.571,
                72.602,
                72.632,
                72.664,
                72.694,
                72.724,
                72.756,
                72.786,
                72.816,
                72.846,
                72.878,
                72.908,
                72.938,
                72.97,
                72.999,
                73.029,
                73.059,
                73.091,
                73.12,
                73.15,
                73.182,
                73.211,
                73.241,
                73.271,
                73.302,
                73.332,
                73.361,
                73.392,
                73.422,
                73.451,
                73.481,
                73.512,
                73.541,
                73.571,
                73.602,
                73.631,
                73.66,
                73.689,
                73.72,
                73.749,
                73.779,
                73.81,
                73.839,
                73.868,
                73.897,
                73.927,
                73.956,
                73.985,
                74.014,
                74.045,
                74.074,
                74.102,
                74.133,
                74.162,
                74.191,
                74.219,
                74.25,
                74.279,
                74.307,
                74.336,
                74.366,
                74.395,
                74.423,
                74.452,
                74.482,
                74.51,
                74.539,
                74.569,
                74.597,
                74.626,
                74.654,
                74.684,
                74.712,
                74.741,
                74.769,
                74.799,
                74.827,
                74.855,
                74.883,
                74.913,
                74.941,
                74.969,
                74.999,
                75.027,
                75.055,
                75.083,
                75.113,
                75.141,
                75.168,
                75.196,
                75.226,
                75.254,
                75.281,
                75.309,
                75.339,
                75.366,
                75.394,
                75.422,
                75.451,
                75.479,
                75.506,
                75.534,
                75.563,
                75.59,
                75.618,
                75.645,
                75.675,
                75.702,
                75.729,
                75.757,
                75.786,
                75.813,
                75.84,
                75.867,
                75.897,
                75.924,
                75.951,
                75.98,
                76.007,
                76.034,
                76.061,
                76.09,
                76.117,
                76.144,
                76.171,
                76.2,
                76.227,
                76.254,
                76.28,
                76.309,
                76.336,
                76.363,
                76.389,
                76.418,
                76.445,
                76.471,
                76.498,
                76.527,
                76.553,
                76.58,
                76.606,
                76.635,
                76.661,
                76.688,
                76.714,
                76.743,
                76.769,
                76.795,
                76.822,
                76.85,
                76.876,
                76.903,
                76.929,
                76.957,
                76.983,
                77.01,
                77.036,
                77.064,
                77.09,
                77.116,
                77.142,
                77.17,
                77.196,
                77.222,
                77.248,
                77.276,
                77.302,
                77.328,
                77.354,
                77.382,
                77.407,
                77.433,
                77.459,
                77.487,
                77.513,
                77.538,
                77.564,
                77.592,
                77.617,
                77.643,
                77.668,
                77.696,
                77.722,
                77.747,
                77.773,
                77.8,
                77.826,
                77.851,
                77.876,
                77.904,
                77.929,
                77.955,
                77.982,
                78.007,
                78.033,
                78.058,
                78.085,
                78.11,
                78.136,
                78.161,
                78.188,
                78.213,
                78.238,
                78.263,
                78.29,
                78.315,
                78.34,
                78.365,
                78.392,
                78.417,
                78.442,
                78.467,
                78.494,
                78.519,
                78.544,
                78.569,
                78.595,
                78.62,
                78.645,
                78.67,
                78.696,
                78.721,
                78.746,
                78.773,
                78.797,
                78.822,
                78.846,
                78.873,
                78.898,
                78.223,
                78.248,
                78.272,
                78.297,
                78.323,
                78.347,
                78.372,
                78.396,
                78.423,
                78.447,
                78.471,
                78.498,
                78.522,
                78.546,
                78.57,
                78.597,
                78.621,
                78.645,
                78.669,
                78.695,
                78.719,
                78.744,
                78.77,
                78.794,
                78.818,
                78.842,
                78.868,
                78.892,
                78.916,
                78.94,
                78.966,
                78.989,
                79.013,
                79.039,
                79.063,
                79.087,
                79.111,
                79.136,
                79.16,
                79.184,
                79.208,
                79.233,
                79.257,
                79.281,
                79.306,
                79.33,
                79.354,
                79.377,
                79.403,
                79.426,
                79.45,
                79.475,
                79.499,
                79.522,
                79.546,
                79.571,
                79.595,
                79.618,
                79.643,
                79.667,
                79.69,
                79.713,
                79.739,
                79.762,
                79.785,
                79.808,
                79.834,
                79.857,
                79.88,
                79.905,
                79.928,
                79.951,
                79.975,
                80,
                80.023,
                80.046,
                80.071,
                80.094,
                80.117,
                80.142,
                80.165,
                80.188,
                80.21,
                80.235,
                80.258,
                80.281,
                80.306,
                80.329,
                80.351,
                80.374,
                80.399,
                80.422,
                80.444,
                80.469,
                80.492,
                80.514,
                80.537,
                80.562,
                80.584,
                80.607,
                80.631,
                80.654,
                80.676,
                80.701,
                80.723,
                80.746,
                80.768,
                80.793,
                80.815,
                80.837,
                80.862,
                80.884,
                80.906,
                80.93,
                80.953,
                80.975,
                80.997,
                81.021,
                81.044,
                81.066,
                81.09,
                81.112,
                81.134,
                81.158,
                81.18,
                81.202,
                81.224,
                81.248,
                81.27,
                81.292,
                81.316,
                81.338,
                81.36,
                81.384,
                81.406,
                81.428,
                81.452,
                81.474,
                81.495,
                81.517,
                81.541,
                81.563,
                81.584,
                81.608,
                81.63,
                81.651,
                81.675,
                81.697,
                81.718,
                81.742,
                81.764,
                81.785,
                81.809,
                81.83,
                81.852,
                81.875,
                81.897,
                81.918,
                81.94,
                81.963,
                81.984,
                82.006,
                82.029,
                82.05,
                82.072,
                82.095,
                82.116,
                82.138,
                82.161,
                82.182,
                82.203,
                82.227,
                82.248,
                82.269,
                82.292,
                82.313,
                82.334,
                82.357,
                82.378,
                82.399,
                82.423,
                82.444,
                82.464,
                82.488,
                82.508,
                82.529,
                82.552,
                82.573,
                82.594,
                82.617,
                82.638,
                82.659,
                82.682,
                82.702,
                82.723,
                82.746,
                82.767,
                82.787,
                82.81,
                82.831,
                82.851,
                82.874,
                82.895,
                82.915,
                82.938,
                82.959,
                82.979,
                83.002,
                83.022,
                83.043,
                83.065,
                83.086,
                83.106,
                83.129,
                83.149,
                83.17,
                83.192,
                83.213,
                83.233,
                83.255,
                83.276,
                83.298,
                83.318,
                83.339,
                83.361,
                83.381,
                83.401,
                83.424,
                83.444,
                83.464,
                83.486,
                83.507,
                83.527,
                83.549,
                83.569,
                83.589,
                83.611,
                83.631,
                83.654,
                83.674,
                83.693,
                83.716,
                83.736,
                83.756,
                83.778,
                83.798,
                83.818,
                83.84,
                83.859,
                83.881,
                83.901,
                83.921,
                83.943,
                83.963,
                83.983,
                84.005,
                84.024,
                84.044,
                84.066,
                84.086,
                84.108,
                84.127,
                84.147,
                84.169,
                84.189,
                84.208,
                84.23,
                84.25,
                84.271,
                84.291,
                84.31,
                84.332,
                84.352,
                84.371,
                84.393,
                84.412,
                84.434,
                84.454,
                84.473,
                84.495,
                84.514,
                84.534,
                84.555,
                84.574,
                84.596,
                84.615,
                84.635,
                84.656,
                84.676,
                84.697,
                84.716,
                84.736,
                84.757,
                84.776,
                84.798,
                84.817,
                84.836,
                84.858,
                84.877,
                84.896,
                84.918,
                84.937,
                84.958,
                84.977,
                84.996,
                85.018,
                85.037,
                85.058,
                85.077,
                85.096,
                85.117,
                85.136,
                85.158,
                85.177,
                85.196,
                85.217,
                85.236,
                85.257,
                85.276,
                85.295,
                85.316,
                85.335,
                85.356,
                85.375,
                85.394,
                85.415,
                85.434,
                85.455,
                85.474,
                85.492,
                85.514,
                85.532,
                85.553,
                85.572,
                85.591,
                85.612,
                85.631,
                85.652,
                85.67,
                85.691,
                85.71,
                85.729,
                85.75,
                85.768,
                85.789,
                85.808,
                85.826,
                85.847,
                85.866,
                85.887,
                85.905,
                85.924,
                85.945,
                85.963,
                85.984,
                86.002,
                86.023,
                86.042,
                86.06,
                86.081,
                86.1,
                86.12,
                86.139,
                86.159,
                86.178,
                86.196,
                86.217,
                86.235,
                86.256,
                86.274,
                86.295,
                86.313,
                86.332,
                86.352,
                86.371,
                86.391,
                86.409,
                86.43,
                86.448,
                86.467,
                86.487,
                86.505,
                86.526,
                86.544,
                86.565,
                86.583,
                86.601,
                86.622,
                86.64,
                86.66,
                86.678,
                86.699,
                86.717,
                86.735,
                86.755,
                86.774,
                86.794,
                86.812,
                86.832,
                86.851,
                86.871,
                86.889,
                86.907,
                86.927,
                86.945,
                86.966,
                86.984,
                87.004,
                87.022,
                87.04,
                87.06,
                87.078,
                87.098,
                87.116,
                87.136,
                87.154,
                87.175,
                87.192,
                87.212,
                87.23,
                87.248,
                87.268,
                87.286,
                87.306,
                87.324,
                87.344,
                87.362,
                87.382,
                87.4,
                87.418,
                87.438,
                87.456,
                87.476,
                87.493,
                87.513,
                87.531,
                87.551,
                87.569,
                87.589,
                87.606,
                87.624,
                87.644,
                87.662,
                87.682,
                87.699,
                87.719,
                87.737,
                87.757,
                87.774,
                87.794,
                87.812,
                87.832,
                87.849,
                87.867,
                87.887,
                87.904,
                87.924,
                87.942,
                87.961,
                87.979,
                87.999,
                88.016,
                88.036,
                88.053,
                88.073,
                88.09,
                88.108,
                88.128,
                88.145,
                88.165,
                88.182,
                88.202,
                88.219,
                88.239,
                88.256,
                88.276,
                88.293,
                88.313,
                88.33,
                88.35,
                88.367,
                88.387,
                88.404,
                88.421,
                88.441,
                88.458,
                88.478,
                88.495,
                88.514,
                88.532,
                88.551,
                88.568,
                88.588,
                88.605,
                88.624,
                88.642,
                88.661,
                88.678,
                88.698,
                88.715,
                88.734,
                88.751,
                88.771,
                88.788,
                88.807,
                88.824,
                88.841,
                88.861,
                88.878,
                88.897,
                88.914,
                88.933,
                88.951,
                88.97,
                88.987,
                89.006,
                89.023,
                89.042,
                89.059,
                89.078,
                89.095,
                89.115,
                89.132,
                89.151,
                89.168,
                89.187,
                89.204,
                89.223,
                89.24,
                89.259,
                89.276,
                89.295,
                89.312,
                89.331,
                89.348,
                89.367,
                89.384,
                89.403,
                89.419,
                89.439,
                89.455,
                89.474,
                89.491,
                89.508,
                89.527,
                89.544,
                89.563,
                89.579,
                89.598,
                89.615,
                89.634,
                89.65,
                89.669,
                89.686,
                89.705,
                89.722,
                89.741,
                89.757,
                89.776,
                89.793,
                89.812,
                89.828,
                89.847,
                89.864,
                89.882,
                89.899,
                89.918,
                89.934,
                89.953,
                89.97,
                89.988,
                90.005,
                90.024,
                90.04,
                90.059,
                90.075,
                90.094,
                90.11,
                90.129,
                90.146,
                90.164,
                90.181,
                90.199,
                90.216,
                90.234,
                90.251,
                90.269,
                90.286,
                90.304,
                90.321,
                90.339,
                90.358,
                90.374,
                90.393,
                90.409,
                90.428,
                90.444,
                90.463,
                90.479,
                90.497,
                90.514,
                90.532,
                90.548,
                90.567,
                90.583,
                90.602,
                90.618,
                90.636,
                90.652,
                90.671,
                90.687,
                90.705,
                90.721,
                90.74,
                90.756,
                90.774,
                90.791,
                90.809,
                90.825,
                90.843,
                90.859,
                90.878,
                90.894,
                90.912,
                90.928,
                90.946,
                90.962,
                90.981,
                90.997,
                91.015,
                91.031,
                91.049,
                91.068,
                91.083,
                91.102,
                91.118,
                91.136,
                91.152,
                91.17,
                91.186,
                91.204,
                91.22,
                91.238,
                91.254,
                91.272,
                91.288,
                91.306,
                91.322,
                91.34,
                91.356,
                91.374,
                91.39,
                91.408,
                91.424,
                91.442,
                91.458,
                91.476,
                91.494,
                91.51,
                91.528,
                91.543,
                91.561,
                91.577,
                91.595,
                91.611,
                91.629,
                91.644,
                91.662,
                91.678,
                91.696,
                91.712,
                91.73,
                91.745,
                91.763,
                91.779,
                91.797,
                91.815,
                91.83,
                91.848,
                91.864,
                91.881,
                91.897,
                91.915,
                91.93,
                91.948,
                91.964,
                91.982,
                91.997,
                92.015,
                92.03,
                92.048,
                92.064,
                92.082,
                92.099,
                92.115,
                92.132,
                92.148,
                92.166,
                92.181,
                92.199,
                92.214,
                92.232,
                92.247,
                92.265,
                92.281,
                92.298,
                92.316,
                92.331,
                92.349,
                92.364,
                92.382,
                92.397,
                92.415,
                92.43,
                92.448,
                92.463,
                92.481,
                92.496,
                92.514,
                92.531,
                92.547,
                92.564,
                92.579,
                92.597,
                92.612,
                92.63,
                92.645,
                92.662,
                92.678,
                92.695,
                92.71,
                92.728,
                92.745,
                92.761,
                92.778,
                92.793,
                92.811,
                92.826,
                92.843,
                92.858,
                92.876,
                92.891,
                92.909,
                92.926,
                92.941,
                92.958,
                92.974,
                92.991,
                93.006,
                93.023,
                93.038,
                93.056,
                93.071,
                93.088,
                93.106,
                93.121,
                93.138,
                93.153,
                93.17,
                93.185,
                93.203,
                93.218,
                93.235,
                93.25,
                93.267,
                93.285,
                93.299,
                93.317,
                93.332,
                93.349,
                93.364,
                93.381,
                93.396,
                93.413,
                93.431,
                93.445,
                93.463,
                93.478,
                93.495,
                93.51,
                93.527,
                93.542,
                93.559,
                93.576,
                93.591,
                93.608,
                93.623,
                93.64,
                93.655,
                93.672,
                93.687,
                93.704,
                93.721,
                93.736,
                93.753,
                93.768,
                93.785,
                93.8,
                93.817,
                93.831,
                93.849,
                93.866,
                93.88,
                93.897,
                93.912,
                93.929,
                93.944,
                93.961,
                93.978,
                93.993,
                94.01,
                94.024,
                94.041,
                94.056,
                94.073,
                94.088,
                94.105,
                94.122,
                94.136,
                94.153,
                94.168,
                94.185,
                94.199,
                94.216,
                94.233,
                94.248,
                94.265,
                94.279,
                94.296,
                94.311,
                94.328,
                94.342,
                94.359,
                94.376,
                94.391,
                94.408,
                94.422,
                94.439,
                94.453,
                94.47,
                94.487,
                94.502,
                94.519,
                94.533,
                94.55,
                94.564,
                94.581,
                94.598,
                94.612,
                94.629,
                94.643,
                94.66,
                94.675,
                94.692,
                94.708,
                94.723,
                94.74,
                94.754,
                94.771,
                94.785,
                94.802,
                94.819,
                94.833,
                94.85,
                94.864,
                94.881,
                94.895,
                94.912,
                94.929,
                94.943,
                94.959,
                94.974,
                94.99,
                95.005,
                95.021,
                95.038,
                95.052,
                95.069,
                95.083,
                95.1,
                95.114,
                95.131,
                95.148,
                95.162,
                95.178,
                95.193,
                95.209,
                95.223,
                95.24,
                95.257,
                95.271,
                95.287,
                95.302,
                95.318,
                95.335,
                95.349,
                95.365,
                95.379,
                95.396,
                95.41,
                95.427,
                95.443,
                95.457,
                95.474,
                95.488,
                95.505,
                95.519,
                95.535,
                95.552,
                95.566,
                95.582,
                95.596,
                95.613,
                95.629,
                95.643,
                95.66,
                95.674,
                95.69,
                95.704,
                95.721,
                95.737,
                95.751,
                95.768,
                95.781,
                95.798,
                95.814,
                95.828,
                95.845,
                95.859,
                95.875,
                95.889,
                95.905,
                95.922,
                95.936,
                95.952,
                95.966,
                95.982,
                95.999,
                96.012,
                96.029,
                96.043,
                96.059,
                96.073,
                96.089,
                96.106,
                96.119,
                96.136,
                96.15,
                96.166,
                96.182,
                96.196,
                96.212,
                96.226,
                96.242,
                96.259,
                96.272,
                96.289,
                96.302,
                96.319,
                96.332,
                96.349,
                96.365,
                96.379,
                96.395,
                96.409,
                96.425,
                96.441,
                96.455,
                96.471,
                96.485,
                96.501,
                96.517,
                96.531,
                96.547,
                96.56,
                96.577,
                96.59,
                96.606,
                96.623,
                96.636,
                96.652,
                96.666,
                96.682,
                96.698,
                96.712,
                96.728,
                96.741,
                96.758,
                96.774,
                96.787,
                96.803,
                96.817,
                96.833,
                96.849,
                96.863,
                96.879,
                96.892,
                96.908,
                96.922,
                96.938,
                96.954,
                96.967,
                96.983,
                96.997,
                97.013,
                97.029,
                97.042,
                97.058,
                97.072,
                97.088,
                97.104,
                97.117,
                97.133,
                97.147,
                97.163,
                97.178,
                97.192,
                97.208,
                97.221,
                97.237,
                97.253,
                97.266,
                97.282,
                97.296,
                97.312,
                97.328,
                97.341,
                97.357,
                97.37,
                97.386,
                97.399,
                97.415,
                97.431,
                97.444,
                97.46,
                97.474,
                97.489,
                97.505,
                97.518,
                97.534,
                97.548,
                97.563,
                97.579,
                97.592,
                97.608,
                97.622,
                97.637,
                97.653,
                97.666,
                97.682,
                97.695,
                97.711,
                97.727,
                97.74,
                97.756,
                97.769,
                97.785,
                97.8,
                97.814,
                97.829,
                97.843,
                97.858,
                97.874,
                97.887,
                97.903,
                97.916,
                97.932,
                97.947,
                97.961,
                97.976,
                97.989,
                98.005,
                98.021,
                98.034,
                98.049,
                98.062,
                98.078,
                98.094,
                98.107,
                98.122,
                98.135,
                98.151,
                98.167,
                98.18,
                98.195,
                98.208,
                98.224,
                98.24,
                98.253,
                98.268,
                98.281,
                98.297,
                98.312,
                98.325,
                98.341,
                98.356,
                98.369,
                98.385,
                98.398,
                98.413,
                98.429,
                98.442,
                98.457,
                98.47,
                98.486,
                98.501,
                98.514,
                98.53,
                98.543,
                98.558,
                98.574,
                98.586,
                98.602,
                98.615,
                98.63,
                98.646,
                98.659,
                98.674,
                98.687,
                98.702,
                98.718,
                98.731,
                98.746,
                98.759,
                98.774,
                98.79
            ],
            P3: [
                45.644,
                45.808,
                45.971,
                46.134,
                46.297,
                46.461,
                46.624,
                46.788,
                46.951,
                47.115,
                47.278,
                47.441,
                47.605,
                47.769,
                47.933,
                48.066,
                48.198,
                48.33,
                48.461,
                48.591,
                48.72,
                48.849,
                48.976,
                49.102,
                49.227,
                49.352,
                49.475,
                49.598,
                49.719,
                49.84,
                49.96,
                50.079,
                50.196,
                50.313,
                50.429,
                50.545,
                50.659,
                50.772,
                50.885,
                50.996,
                51.108,
                51.217,
                51.327,
                51.434,
                51.543,
                51.649,
                51.754,
                51.86,
                51.964,
                52.067,
                52.169,
                52.272,
                52.373,
                52.473,
                52.573,
                52.671,
                52.769,
                52.867,
                52.963,
                53.059,
                53.155,
                53.249,
                53.343,
                53.437,
                53.53,
                53.621,
                53.713,
                53.804,
                53.894,
                53.984,
                54.073,
                54.162,
                54.25,
                54.337,
                54.424,
                54.51,
                54.596,
                54.681,
                54.765,
                54.85,
                54.933,
                55.017,
                55.099,
                55.181,
                55.262,
                55.344,
                55.424,
                55.504,
                55.584,
                55.663,
                55.742,
                55.82,
                55.897,
                55.975,
                56.052,
                56.128,
                56.204,
                56.28,
                56.355,
                56.429,
                56.503,
                56.577,
                56.651,
                56.724,
                56.796,
                56.869,
                56.94,
                57.011,
                57.082,
                57.153,
                57.224,
                57.294,
                57.363,
                57.432,
                57.501,
                57.569,
                57.637,
                57.704,
                57.771,
                57.838,
                57.904,
                57.969,
                58.035,
                58.1,
                58.165,
                58.23,
                58.294,
                58.358,
                58.422,
                58.485,
                58.547,
                58.61,
                58.672,
                58.734,
                58.796,
                58.856,
                58.917,
                58.977,
                59.038,
                59.098,
                59.157,
                59.216,
                59.276,
                59.334,
                59.392,
                59.451,
                59.508,
                59.566,
                59.623,
                59.68,
                59.737,
                59.794,
                59.849,
                59.906,
                59.962,
                60.017,
                60.073,
                60.127,
                60.182,
                60.236,
                60.291,
                60.346,
                60.399,
                60.453,
                60.506,
                60.56,
                60.612,
                60.666,
                60.718,
                60.771,
                60.823,
                60.875,
                60.927,
                60.978,
                61.031,
                61.082,
                61.134,
                61.184,
                61.235,
                61.286,
                61.336,
                61.386,
                61.437,
                61.487,
                61.538,
                61.587,
                61.637,
                61.686,
                61.736,
                61.785,
                61.834,
                61.883,
                61.932,
                61.98,
                62.03,
                62.078,
                62.126,
                62.174,
                62.222,
                62.271,
                62.318,
                62.366,
                62.413,
                62.462,
                62.509,
                62.556,
                62.603,
                62.649,
                62.696,
                62.744,
                62.791,
                62.837,
                62.883,
                62.93,
                62.976,
                63.022,
                63.068,
                63.115,
                63.161,
                63.207,
                63.252,
                63.298,
                63.343,
                63.389,
                63.434,
                63.479,
                63.524,
                63.569,
                63.614,
                63.659,
                63.704,
                63.749,
                63.793,
                63.838,
                63.883,
                63.927,
                63.971,
                64.016,
                64.06,
                64.104,
                64.148,
                64.192,
                64.236,
                64.28,
                64.324,
                64.367,
                64.411,
                64.455,
                64.498,
                64.542,
                64.585,
                64.627,
                64.67,
                64.713,
                64.757,
                64.8,
                64.843,
                64.886,
                64.929,
                64.971,
                65.013,
                65.055,
                65.098,
                65.141,
                65.183,
                65.226,
                65.267,
                65.309,
                65.351,
                65.394,
                65.436,
                65.477,
                65.519,
                65.561,
                65.603,
                65.645,
                65.685,
                65.727,
                65.769,
                65.81,
                65.852,
                65.892,
                65.934,
                65.975,
                66.017,
                66.057,
                66.098,
                66.139,
                66.179,
                66.22,
                66.261,
                66.302,
                66.342,
                66.383,
                66.424,
                66.463,
                66.504,
                66.545,
                66.585,
                66.625,
                66.665,
                66.706,
                66.745,
                66.785,
                66.826,
                66.865,
                66.905,
                66.945,
                66.984,
                67.024,
                67.064,
                67.103,
                67.143,
                67.182,
                67.221,
                67.261,
                67.3,
                67.339,
                67.379,
                67.417,
                67.457,
                67.495,
                67.535,
                67.574,
                67.612,
                67.652,
                67.69,
                67.729,
                67.768,
                67.806,
                67.845,
                67.883,
                67.922,
                67.959,
                67.998,
                68.037,
                68.075,
                68.113,
                68.151,
                68.19,
                68.227,
                68.265,
                68.303,
                68.341,
                68.378,
                68.417,
                68.455,
                68.492,
                68.53,
                68.567,
                68.606,
                68.642,
                68.68,
                68.717,
                68.755,
                68.792,
                68.83,
                68.866,
                68.904,
                68.941,
                68.978,
                69.015,
                69.053,
                69.089,
                69.126,
                69.163,
                69.2,
                69.236,
                69.274,
                69.31,
                69.346,
                69.383,
                69.419,
                69.456,
                69.492,
                69.529,
                69.565,
                69.602,
                69.638,
                69.675,
                69.71,
                69.746,
                69.782,
                69.818,
                69.855,
                69.89,
                69.927,
                69.962,
                69.999,
                70.034,
                70.069,
                70.105,
                70.141,
                70.177,
                70.212,
                70.248,
                70.283,
                70.318,
                70.354,
                70.389,
                70.425,
                70.46,
                70.495,
                70.531,
                70.565,
                70.601,
                70.636,
                70.67,
                70.706,
                70.74,
                70.776,
                70.811,
                70.845,
                70.88,
                70.915,
                70.95,
                70.984,
                71.018,
                71.054,
                71.088,
                71.122,
                71.157,
                71.191,
                71.226,
                71.26,
                71.294,
                71.329,
                71.363,
                71.397,
                71.432,
                71.465,
                71.499,
                71.534,
                71.567,
                71.602,
                71.635,
                71.669,
                71.704,
                71.737,
                71.77,
                71.805,
                71.838,
                71.871,
                71.906,
                71.939,
                71.972,
                72.006,
                72.039,
                72.072,
                72.107,
                72.139,
                72.174,
                72.206,
                72.239,
                72.273,
                72.306,
                72.339,
                72.373,
                72.405,
                72.438,
                72.472,
                72.505,
                72.537,
                72.571,
                72.603,
                72.636,
                72.669,
                72.702,
                72.734,
                72.766,
                72.8,
                72.832,
                72.864,
                72.898,
                72.93,
                72.962,
                72.995,
                73.027,
                73.059,
                73.093,
                73.125,
                73.156,
                73.19,
                73.221,
                73.253,
                73.286,
                73.318,
                73.35,
                73.381,
                73.414,
                73.446,
                73.478,
                73.511,
                73.542,
                73.573,
                73.606,
                73.638,
                73.669,
                73.702,
                73.733,
                73.764,
                73.796,
                73.828,
                73.859,
                73.891,
                73.923,
                73.954,
                73.985,
                74.018,
                74.049,
                74.08,
                74.111,
                74.143,
                74.174,
                74.205,
                74.237,
                74.268,
                74.298,
                74.329,
                74.361,
                74.392,
                74.423,
                74.455,
                74.485,
                74.516,
                74.546,
                74.578,
                74.609,
                74.639,
                74.671,
                74.701,
                74.732,
                74.762,
                74.794,
                74.824,
                74.854,
                74.886,
                74.916,
                74.946,
                74.976,
                75.008,
                75.038,
                75.068,
                75.099,
                75.129,
                75.159,
                75.189,
                75.22,
                75.25,
                75.28,
                75.31,
                75.341,
                75.371,
                75.401,
                75.432,
                75.461,
                75.491,
                75.521,
                75.552,
                75.581,
                75.611,
                75.64,
                75.671,
                75.701,
                75.73,
                75.759,
                75.79,
                75.82,
                75.849,
                75.88,
                75.909,
                75.938,
                75.967,
                75.998,
                76.027,
                76.056,
                76.085,
                76.116,
                76.145,
                76.174,
                76.203,
                76.233,
                76.262,
                76.291,
                76.322,
                76.35,
                76.379,
                76.408,
                76.438,
                76.467,
                76.496,
                76.525,
                76.555,
                76.583,
                76.612,
                76.641,
                76.671,
                76.699,
                76.728,
                76.756,
                76.786,
                76.815,
                76.843,
                76.872,
                76.901,
                76.93,
                76.958,
                76.986,
                77.016,
                77.044,
                77.073,
                77.101,
                77.131,
                77.159,
                77.187,
                77.215,
                77.245,
                77.273,
                77.301,
                77.33,
                77.358,
                77.386,
                77.414,
                77.443,
                77.471,
                77.499,
                77.527,
                77.556,
                77.584,
                77.612,
                77.64,
                77.669,
                77.697,
                77.724,
                77.752,
                77.781,
                77.809,
                77.836,
                77.864,
                77.893,
                77.92,
                77.948,
                77.975,
                78.004,
                78.032,
                78.059,
                78.086,
                78.115,
                78.142,
                78.17,
                78.197,
                78.226,
                78.253,
                78.28,
                78.307,
                78.336,
                78.363,
                78.39,
                78.417,
                78.446,
                78.473,
                78.5,
                78.527,
                78.555,
                78.582,
                78.609,
                78.636,
                78.664,
                78.691,
                78.718,
                78.745,
                78.773,
                78.8,
                78.826,
                78.853,
                78.881,
                78.908,
                78.934,
                78.961,
                78.989,
                79.016,
                79.042,
                79.069,
                79.097,
                79.123,
                79.15,
                79.176,
                79.204,
                79.23,
                79.257,
                79.283,
                79.311,
                79.337,
                79.363,
                79.391,
                79.417,
                79.443,
                79.47,
                79.497,
                79.523,
                79.55,
                79.576,
                79.603,
                79.629,
                79.655,
                79.681,
                79.709,
                79.735,
                79.761,
                79.786,
                79.814,
                79.84,
                79.866,
                79.891,
                79.919,
                79.944,
                79.97,
                79.996,
                80.023,
                80.049,
                80.075,
                80.1,
                80.127,
                80.153,
                80.178,
                80.206,
                80.231,
                80.257,
                80.282,
                80.309,
                80.335,
                79.661,
                79.686,
                79.712,
                79.737,
                79.764,
                79.789,
                79.814,
                79.84,
                79.867,
                79.892,
                79.917,
                79.944,
                79.969,
                79.994,
                80.019,
                80.046,
                80.071,
                80.096,
                80.121,
                80.148,
                80.173,
                80.198,
                80.224,
                80.249,
                80.274,
                80.299,
                80.325,
                80.35,
                80.375,
                80.4,
                80.426,
                80.451,
                80.476,
                80.502,
                80.527,
                80.552,
                80.576,
                80.602,
                80.627,
                80.652,
                80.676,
                80.702,
                80.727,
                80.751,
                80.778,
                80.802,
                80.827,
                80.851,
                80.877,
                80.902,
                80.926,
                80.952,
                80.976,
                81.001,
                81.025,
                81.051,
                81.075,
                81.099,
                81.125,
                81.149,
                81.174,
                81.198,
                81.224,
                81.248,
                81.272,
                81.296,
                81.322,
                81.346,
                81.37,
                81.395,
                81.419,
                81.443,
                81.467,
                81.493,
                81.517,
                81.541,
                81.566,
                81.59,
                81.614,
                81.639,
                81.663,
                81.687,
                81.711,
                81.736,
                81.76,
                81.783,
                81.809,
                81.832,
                81.856,
                81.879,
                81.905,
                81.928,
                81.952,
                81.977,
                82.001,
                82.024,
                82.047,
                82.073,
                82.096,
                82.119,
                82.144,
                82.168,
                82.191,
                82.216,
                82.239,
                82.263,
                82.286,
                82.311,
                82.334,
                82.357,
                82.382,
                82.405,
                82.428,
                82.453,
                82.476,
                82.499,
                82.522,
                82.547,
                82.57,
                82.593,
                82.618,
                82.641,
                82.664,
                82.688,
                82.711,
                82.734,
                82.757,
                82.781,
                82.804,
                82.827,
                82.852,
                82.874,
                82.897,
                82.921,
                82.944,
                82.967,
                82.991,
                83.014,
                83.037,
                83.059,
                83.083,
                83.106,
                83.129,
                83.153,
                83.175,
                83.198,
                83.222,
                83.244,
                83.267,
                83.291,
                83.313,
                83.336,
                83.36,
                83.382,
                83.405,
                83.428,
                83.451,
                83.473,
                83.495,
                83.519,
                83.541,
                83.564,
                83.587,
                83.61,
                83.632,
                83.656,
                83.678,
                83.7,
                83.724,
                83.746,
                83.768,
                83.791,
                83.813,
                83.835,
                83.859,
                83.881,
                83.903,
                83.926,
                83.948,
                83.97,
                83.994,
                84.015,
                84.037,
                84.061,
                84.083,
                84.104,
                84.128,
                84.149,
                84.171,
                84.195,
                84.216,
                84.238,
                84.261,
                84.283,
                84.304,
                84.328,
                84.349,
                84.371,
                84.394,
                84.416,
                84.437,
                84.46,
                84.482,
                84.503,
                84.526,
                84.548,
                84.569,
                84.592,
                84.613,
                84.635,
                84.658,
                84.679,
                84.7,
                84.723,
                84.745,
                84.766,
                84.789,
                84.81,
                84.831,
                84.854,
                84.875,
                84.898,
                84.919,
                84.94,
                84.963,
                84.984,
                85.005,
                85.028,
                85.049,
                85.07,
                85.093,
                85.114,
                85.135,
                85.157,
                85.178,
                85.199,
                85.222,
                85.243,
                85.265,
                85.286,
                85.307,
                85.329,
                85.35,
                85.371,
                85.394,
                85.414,
                85.435,
                85.458,
                85.478,
                85.501,
                85.521,
                85.542,
                85.564,
                85.585,
                85.606,
                85.628,
                85.649,
                85.669,
                85.692,
                85.712,
                85.734,
                85.755,
                85.776,
                85.798,
                85.818,
                85.839,
                85.861,
                85.881,
                85.904,
                85.924,
                85.944,
                85.967,
                85.987,
                86.007,
                86.029,
                86.05,
                86.072,
                86.092,
                86.112,
                86.134,
                86.155,
                86.175,
                86.197,
                86.217,
                86.239,
                86.259,
                86.28,
                86.302,
                86.322,
                86.344,
                86.364,
                86.384,
                86.406,
                86.426,
                86.448,
                86.468,
                86.488,
                86.51,
                86.53,
                86.55,
                86.572,
                86.592,
                86.613,
                86.633,
                86.653,
                86.675,
                86.695,
                86.717,
                86.736,
                86.756,
                86.778,
                86.798,
                86.819,
                86.839,
                86.859,
                86.881,
                86.901,
                86.922,
                86.942,
                86.962,
                86.983,
                87.003,
                87.025,
                87.044,
                87.064,
                87.086,
                87.105,
                87.127,
                87.146,
                87.166,
                87.187,
                87.207,
                87.229,
                87.248,
                87.268,
                87.289,
                87.309,
                87.33,
                87.35,
                87.371,
                87.39,
                87.41,
                87.431,
                87.451,
                87.472,
                87.492,
                87.511,
                87.532,
                87.552,
                87.573,
                87.592,
                87.612,
                87.633,
                87.652,
                87.674,
                87.693,
                87.714,
                87.734,
                87.753,
                87.774,
                87.793,
                87.814,
                87.834,
                87.855,
                87.874,
                87.893,
                87.914,
                87.934,
                87.955,
                87.974,
                87.995,
                88.014,
                88.033,
                88.054,
                88.073,
                88.094,
                88.114,
                88.135,
                88.154,
                88.173,
                88.194,
                88.213,
                88.234,
                88.253,
                88.274,
                88.293,
                88.312,
                88.333,
                88.352,
                88.373,
                88.392,
                88.412,
                88.431,
                88.45,
                88.471,
                88.49,
                88.511,
                88.53,
                88.551,
                88.569,
                88.59,
                88.609,
                88.628,
                88.649,
                88.668,
                88.688,
                88.707,
                88.728,
                88.747,
                88.765,
                88.786,
                88.805,
                88.825,
                88.844,
                88.865,
                88.883,
                88.904,
                88.923,
                88.943,
                88.962,
                88.981,
                89.001,
                89.02,
                89.041,
                89.059,
                89.08,
                89.098,
                89.119,
                89.137,
                89.156,
                89.177,
                89.195,
                89.216,
                89.234,
                89.254,
                89.273,
                89.293,
                89.312,
                89.332,
                89.351,
                89.369,
                89.39,
                89.408,
                89.428,
                89.447,
                89.467,
                89.486,
                89.506,
                89.524,
                89.545,
                89.563,
                89.583,
                89.602,
                89.62,
                89.64,
                89.659,
                89.679,
                89.697,
                89.718,
                89.736,
                89.756,
                89.774,
                89.795,
                89.813,
                89.833,
                89.851,
                89.869,
                89.89,
                89.908,
                89.928,
                89.946,
                89.966,
                89.984,
                90.004,
                90.023,
                90.043,
                90.061,
                90.081,
                90.099,
                90.119,
                90.137,
                90.157,
                90.175,
                90.193,
                90.213,
                90.231,
                90.251,
                90.269,
                90.289,
                90.307,
                90.327,
                90.345,
                90.365,
                90.383,
                90.403,
                90.421,
                90.441,
                90.459,
                90.479,
                90.497,
                90.516,
                90.534,
                90.554,
                90.572,
                90.592,
                90.61,
                90.628,
                90.647,
                90.665,
                90.685,
                90.703,
                90.723,
                90.74,
                90.76,
                90.778,
                90.798,
                90.815,
                90.835,
                90.853,
                90.872,
                90.89,
                90.91,
                90.928,
                90.947,
                90.965,
                90.984,
                91.002,
                91.022,
                91.039,
                91.059,
                91.077,
                91.096,
                91.114,
                91.133,
                91.151,
                91.171,
                91.188,
                91.208,
                91.225,
                91.245,
                91.262,
                91.282,
                91.299,
                91.317,
                91.336,
                91.354,
                91.373,
                91.391,
                91.41,
                91.427,
                91.447,
                91.464,
                91.484,
                91.501,
                91.52,
                91.538,
                91.557,
                91.575,
                91.594,
                91.611,
                91.631,
                91.648,
                91.667,
                91.685,
                91.704,
                91.721,
                91.74,
                91.758,
                91.777,
                91.794,
                91.813,
                91.831,
                91.85,
                91.867,
                91.886,
                91.904,
                91.923,
                91.94,
                91.959,
                91.976,
                91.995,
                92.013,
                92.032,
                92.049,
                92.068,
                92.085,
                92.104,
                92.121,
                92.14,
                92.157,
                92.176,
                92.195,
                92.213,
                92.232,
                92.249,
                92.268,
                92.285,
                92.304,
                92.321,
                92.34,
                92.357,
                92.376,
                92.393,
                92.412,
                92.429,
                92.447,
                92.464,
                92.483,
                92.5,
                92.519,
                92.536,
                92.555,
                92.572,
                92.591,
                92.608,
                92.626,
                92.643,
                92.662,
                92.679,
                92.698,
                92.715,
                92.733,
                92.75,
                92.769,
                92.786,
                92.804,
                92.821,
                92.84,
                92.857,
                92.875,
                92.892,
                92.911,
                92.929,
                92.946,
                92.965,
                92.982,
                93,
                93.017,
                93.035,
                93.052,
                93.071,
                93.087,
                93.106,
                93.123,
                93.141,
                93.158,
                93.176,
                93.193,
                93.212,
                93.228,
                93.247,
                93.263,
                93.282,
                93.298,
                93.317,
                93.333,
                93.352,
                93.37,
                93.387,
                93.405,
                93.422,
                93.44,
                93.457,
                93.475,
                93.492,
                93.51,
                93.526,
                93.545,
                93.561,
                93.58,
                93.596,
                93.614,
                93.631,
                93.649,
                93.666,
                93.684,
                93.702,
                93.719,
                93.737,
                93.753,
                93.771,
                93.788,
                93.806,
                93.822,
                93.841,
                93.857,
                93.875,
                93.892,
                93.91,
                93.926,
                93.944,
                93.961,
                93.979,
                93.997,
                94.013,
                94.031,
                94.047,
                94.066,
                94.082,
                94.1,
                94.116,
                94.134,
                94.15,
                94.169,
                94.185,
                94.203,
                94.221,
                94.237,
                94.255,
                94.271,
                94.289,
                94.305,
                94.323,
                94.34,
                94.358,
                94.374,
                94.392,
                94.408,
                94.426,
                94.444,
                94.46,
                94.478,
                94.494,
                94.512,
                94.528,
                94.546,
                94.562,
                94.58,
                94.596,
                94.614,
                94.63,
                94.648,
                94.665,
                94.681,
                94.699,
                94.715,
                94.733,
                94.749,
                94.767,
                94.783,
                94.801,
                94.817,
                94.834,
                94.852,
                94.868,
                94.886,
                94.902,
                94.92,
                94.935,
                94.953,
                94.969,
                94.987,
                95.003,
                95.02,
                95.038,
                95.054,
                95.072,
                95.088,
                95.105,
                95.121,
                95.139,
                95.155,
                95.172,
                95.188,
                95.206,
                95.223,
                95.239,
                95.257,
                95.272,
                95.29,
                95.306,
                95.323,
                95.339,
                95.357,
                95.374,
                95.39,
                95.408,
                95.423,
                95.441,
                95.457,
                95.474,
                95.49,
                95.507,
                95.525,
                95.541,
                95.558,
                95.574,
                95.591,
                95.607,
                95.625,
                95.64,
                95.658,
                95.675,
                95.691,
                95.708,
                95.724,
                95.741,
                95.757,
                95.774,
                95.79,
                95.807,
                95.825,
                95.84,
                95.858,
                95.873,
                95.891,
                95.906,
                95.924,
                95.941,
                95.956,
                95.974,
                95.989,
                96.007,
                96.022,
                96.04,
                96.055,
                96.072,
                96.09,
                96.105,
                96.122,
                96.138,
                96.155,
                96.171,
                96.188,
                96.205,
                96.221,
                96.238,
                96.253,
                96.271,
                96.286,
                96.303,
                96.318,
                96.336,
                96.353,
                96.368,
                96.386,
                96.401,
                96.418,
                96.434,
                96.451,
                96.468,
                96.483,
                96.501,
                96.516,
                96.533,
                96.548,
                96.565,
                96.583,
                96.598,
                96.615,
                96.63,
                96.647,
                96.663,
                96.68,
                96.697,
                96.712,
                96.729,
                96.744,
                96.762,
                96.777,
                96.794,
                96.811,
                96.826,
                96.843,
                96.858,
                96.876,
                96.891,
                96.908,
                96.925,
                96.94,
                96.957,
                96.972,
                96.989,
                97.004,
                97.021,
                97.038,
                97.053,
                97.07,
                97.086,
                97.103,
                97.118,
                97.135,
                97.152,
                97.167,
                97.184,
                97.199,
                97.216,
                97.231,
                97.248,
                97.264,
                97.28,
                97.296,
                97.311,
                97.328,
                97.345,
                97.36,
                97.377,
                97.392,
                97.409,
                97.424,
                97.441,
                97.458,
                97.473,
                97.49,
                97.505,
                97.521,
                97.536,
                97.553,
                97.57,
                97.585,
                97.602,
                97.617,
                97.633,
                97.65,
                97.665,
                97.682,
                97.697,
                97.714,
                97.728,
                97.745,
                97.762,
                97.777,
                97.794,
                97.808,
                97.825,
                97.842,
                97.857,
                97.874,
                97.888,
                97.905,
                97.92,
                97.936,
                97.953,
                97.968,
                97.985,
                97.999,
                98.016,
                98.033,
                98.047,
                98.064,
                98.079,
                98.096,
                98.11,
                98.127,
                98.144,
                98.158,
                98.175,
                98.19,
                98.206,
                98.223,
                98.237,
                98.254,
                98.269,
                98.285,
                98.302,
                98.317,
                98.333,
                98.348,
                98.364,
                98.379,
                98.395,
                98.412,
                98.427,
                98.443,
                98.458,
                98.474,
                98.491,
                98.505,
                98.522,
                98.536,
                98.553,
                98.57,
                98.584,
                98.601,
                98.615,
                98.631,
                98.646,
                98.662,
                98.679,
                98.693,
                98.71,
                98.724,
                98.741,
                98.757,
                98.772,
                98.788,
                98.803,
                98.819,
                98.835,
                98.85,
                98.866,
                98.881,
                98.897,
                98.913,
                98.928,
                98.944,
                98.959,
                98.975,
                98.989,
                99.006,
                99.022,
                99.036,
                99.053,
                99.067,
                99.083,
                99.1,
                99.114,
                99.13,
                99.145,
                99.161,
                99.177,
                99.192,
                99.208,
                99.222,
                99.238,
                99.255,
                99.269,
                99.285,
                99.299,
                99.316,
                99.332,
                99.346,
                99.362,
                99.377,
                99.393,
                99.409,
                99.423,
                99.44,
                99.454,
                99.47,
                99.484,
                99.5,
                99.516,
                99.531,
                99.547,
                99.561,
                99.577,
                99.593,
                99.607,
                99.624,
                99.638,
                99.654,
                99.67,
                99.684,
                99.7,
                99.714,
                99.73,
                99.746,
                99.761,
                99.777,
                99.791,
                99.807,
                99.823,
                99.837,
                99.853,
                99.867,
                99.883,
                99.899,
                99.913,
                99.929,
                99.943,
                99.959,
                99.975,
                99.989,
                100.005,
                100.019,
                100.035,
                100.051,
                100.065,
                100.081,
                100.095,
                100.111,
                100.127,
                100.141,
                100.157,
                100.171,
                100.187,
                100.203,
                100.217,
                100.233,
                100.247,
                100.263,
                100.279,
                100.293,
                100.308,
                100.322,
                100.338,
                100.354,
                100.368,
                100.384,
                100.398,
                100.414,
                100.43,
                100.443,
                100.459,
                100.475,
                100.489,
                100.505,
                100.519,
                100.534,
                100.55,
                100.564,
                100.58,
                100.594,
                100.61,
                100.625,
                100.639,
                100.655,
                100.669,
                100.684,
                100.7,
                100.714,
                100.73,
                100.744,
                100.759,
                100.775,
                100.789,
                100.805,
                100.818,
                100.834,
                100.85,
                100.863,
                100.879,
                100.893,
                100.909,
                100.924
            ],
            P5: [
                46.084,
                46.248,
                46.412,
                46.575,
                46.739,
                46.904,
                47.068,
                47.232,
                47.396,
                47.561,
                47.724,
                47.889,
                48.053,
                48.218,
                48.382,
                48.516,
                48.649,
                48.781,
                48.913,
                49.044,
                49.173,
                49.303,
                49.431,
                49.558,
                49.683,
                49.809,
                49.933,
                50.056,
                50.179,
                50.3,
                50.421,
                50.54,
                50.658,
                50.776,
                50.893,
                51.009,
                51.124,
                51.238,
                51.352,
                51.463,
                51.575,
                51.685,
                51.795,
                51.904,
                52.013,
                52.12,
                52.226,
                52.332,
                52.437,
                52.54,
                52.644,
                52.747,
                52.848,
                52.949,
                53.049,
                53.148,
                53.247,
                53.345,
                53.442,
                53.539,
                53.635,
                53.73,
                53.824,
                53.918,
                54.012,
                54.104,
                54.196,
                54.288,
                54.379,
                54.469,
                54.558,
                54.648,
                54.736,
                54.824,
                54.912,
                54.999,
                55.084,
                55.171,
                55.255,
                55.34,
                55.424,
                55.508,
                55.591,
                55.674,
                55.755,
                55.837,
                55.918,
                55.998,
                56.079,
                56.158,
                56.238,
                56.316,
                56.394,
                56.473,
                56.55,
                56.626,
                56.703,
                56.779,
                56.855,
                56.93,
                57.004,
                57.078,
                57.153,
                57.226,
                57.299,
                57.372,
                57.444,
                57.516,
                57.587,
                57.658,
                57.729,
                57.8,
                57.869,
                57.939,
                58.008,
                58.077,
                58.145,
                58.213,
                58.28,
                58.348,
                58.414,
                58.48,
                58.546,
                58.612,
                58.677,
                58.742,
                58.807,
                58.871,
                58.935,
                58.999,
                59.061,
                59.124,
                59.187,
                59.249,
                59.311,
                59.372,
                59.433,
                59.495,
                59.555,
                59.616,
                59.675,
                59.735,
                59.795,
                59.853,
                59.912,
                59.971,
                60.029,
                60.087,
                60.145,
                60.202,
                60.259,
                60.316,
                60.372,
                60.429,
                60.486,
                60.541,
                60.597,
                60.652,
                60.708,
                60.762,
                60.817,
                60.872,
                60.926,
                60.98,
                61.034,
                61.088,
                61.141,
                61.195,
                61.247,
                61.301,
                61.353,
                61.405,
                61.458,
                61.51,
                61.562,
                61.614,
                61.666,
                61.717,
                61.768,
                61.819,
                61.87,
                61.92,
                61.972,
                62.022,
                62.073,
                62.123,
                62.173,
                62.222,
                62.273,
                62.322,
                62.371,
                62.421,
                62.47,
                62.519,
                62.569,
                62.617,
                62.666,
                62.714,
                62.762,
                62.812,
                62.86,
                62.907,
                62.955,
                63.004,
                63.051,
                63.099,
                63.146,
                63.193,
                63.241,
                63.289,
                63.336,
                63.383,
                63.429,
                63.476,
                63.522,
                63.569,
                63.615,
                63.663,
                63.709,
                63.755,
                63.801,
                63.847,
                63.893,
                63.939,
                63.984,
                64.03,
                64.075,
                64.121,
                64.166,
                64.212,
                64.257,
                64.302,
                64.347,
                64.392,
                64.437,
                64.482,
                64.526,
                64.571,
                64.616,
                64.66,
                64.705,
                64.749,
                64.793,
                64.838,
                64.882,
                64.926,
                64.97,
                65.014,
                65.058,
                65.102,
                65.145,
                65.188,
                65.232,
                65.275,
                65.319,
                65.362,
                65.406,
                65.449,
                65.492,
                65.535,
                65.577,
                65.62,
                65.663,
                65.706,
                65.749,
                65.792,
                65.834,
                65.876,
                65.919,
                65.962,
                66.004,
                66.046,
                66.088,
                66.13,
                66.173,
                66.215,
                66.256,
                66.298,
                66.34,
                66.382,
                66.424,
                66.465,
                66.507,
                66.549,
                66.59,
                66.631,
                66.673,
                66.714,
                66.755,
                66.796,
                66.838,
                66.879,
                66.919,
                66.96,
                67.002,
                67.042,
                67.083,
                67.124,
                67.165,
                67.205,
                67.246,
                67.287,
                67.326,
                67.367,
                67.408,
                67.447,
                67.488,
                67.529,
                67.568,
                67.608,
                67.649,
                67.688,
                67.728,
                67.767,
                67.808,
                67.848,
                67.887,
                67.927,
                67.967,
                68.006,
                68.046,
                68.084,
                68.124,
                68.164,
                68.203,
                68.242,
                68.281,
                68.32,
                68.36,
                68.398,
                68.438,
                68.476,
                68.515,
                68.553,
                68.593,
                68.632,
                68.67,
                68.709,
                68.747,
                68.786,
                68.824,
                68.863,
                68.901,
                68.939,
                68.977,
                69.016,
                69.055,
                69.092,
                69.131,
                69.168,
                69.207,
                69.244,
                69.282,
                69.32,
                69.358,
                69.395,
                69.433,
                69.47,
                69.509,
                69.546,
                69.584,
                69.621,
                69.659,
                69.695,
                69.733,
                69.77,
                69.808,
                69.844,
                69.882,
                69.919,
                69.955,
                69.993,
                70.029,
                70.067,
                70.103,
                70.141,
                70.177,
                70.214,
                70.25,
                70.288,
                70.324,
                70.36,
                70.397,
                70.433,
                70.47,
                70.506,
                70.543,
                70.579,
                70.616,
                70.651,
                70.687,
                70.724,
                70.759,
                70.796,
                70.832,
                70.868,
                70.904,
                70.939,
                70.976,
                71.011,
                71.047,
                71.083,
                71.118,
                71.154,
                71.189,
                71.225,
                71.26,
                71.295,
                71.332,
                71.366,
                71.403,
                71.437,
                71.472,
                71.508,
                71.543,
                71.579,
                71.613,
                71.648,
                71.684,
                71.718,
                71.753,
                71.788,
                71.823,
                71.858,
                71.893,
                71.927,
                71.962,
                71.997,
                72.031,
                72.066,
                72.1,
                72.134,
                72.169,
                72.203,
                72.239,
                72.273,
                72.306,
                72.341,
                72.375,
                72.409,
                72.444,
                72.478,
                72.511,
                72.546,
                72.58,
                72.613,
                72.648,
                72.682,
                72.715,
                72.75,
                72.783,
                72.818,
                72.851,
                72.884,
                72.919,
                72.952,
                72.985,
                73.019,
                73.052,
                73.085,
                73.12,
                73.153,
                73.186,
                73.22,
                73.253,
                73.286,
                73.32,
                73.353,
                73.385,
                73.418,
                73.452,
                73.485,
                73.517,
                73.551,
                73.584,
                73.616,
                73.65,
                73.682,
                73.715,
                73.748,
                73.781,
                73.813,
                73.847,
                73.879,
                73.911,
                73.945,
                73.977,
                74.009,
                74.041,
                74.075,
                74.107,
                74.139,
                74.172,
                74.204,
                74.236,
                74.269,
                74.301,
                74.333,
                74.366,
                74.398,
                74.429,
                74.461,
                74.494,
                74.526,
                74.557,
                74.59,
                74.622,
                74.653,
                74.686,
                74.717,
                74.749,
                74.78,
                74.813,
                74.844,
                74.875,
                74.908,
                74.939,
                74.971,
                75.002,
                75.034,
                75.065,
                75.096,
                75.129,
                75.16,
                75.191,
                75.222,
                75.254,
                75.285,
                75.316,
                75.348,
                75.379,
                75.41,
                75.44,
                75.472,
                75.503,
                75.534,
                75.566,
                75.596,
                75.627,
                75.658,
                75.69,
                75.72,
                75.75,
                75.782,
                75.813,
                75.843,
                75.873,
                75.905,
                75.935,
                75.966,
                75.996,
                76.027,
                76.058,
                76.088,
                76.119,
                76.149,
                76.18,
                76.21,
                76.241,
                76.271,
                76.301,
                76.331,
                76.362,
                76.392,
                76.422,
                76.452,
                76.483,
                76.513,
                76.543,
                76.574,
                76.603,
                76.633,
                76.663,
                76.694,
                76.723,
                76.753,
                76.782,
                76.813,
                76.843,
                76.872,
                76.902,
                76.932,
                76.962,
                76.991,
                77.022,
                77.051,
                77.081,
                77.11,
                77.14,
                77.17,
                77.199,
                77.228,
                77.258,
                77.288,
                77.317,
                77.346,
                77.376,
                77.405,
                77.434,
                77.463,
                77.493,
                77.522,
                77.551,
                77.58,
                77.61,
                77.639,
                77.668,
                77.697,
                77.727,
                77.755,
                77.784,
                77.813,
                77.843,
                77.871,
                77.9,
                77.928,
                77.958,
                77.987,
                78.015,
                78.045,
                78.074,
                78.102,
                78.13,
                78.16,
                78.189,
                78.217,
                78.245,
                78.275,
                78.303,
                78.331,
                78.359,
                78.389,
                78.417,
                78.445,
                78.473,
                78.503,
                78.531,
                78.559,
                78.587,
                78.616,
                78.644,
                78.672,
                78.7,
                78.729,
                78.757,
                78.785,
                78.813,
                78.842,
                78.87,
                78.897,
                78.925,
                78.954,
                78.982,
                79.009,
                79.037,
                79.066,
                79.094,
                79.121,
                79.149,
                79.177,
                79.205,
                79.232,
                79.26,
                79.289,
                79.316,
                79.343,
                79.371,
                79.399,
                79.427,
                79.454,
                79.481,
                79.51,
                79.537,
                79.564,
                79.591,
                79.62,
                79.647,
                79.674,
                79.701,
                79.729,
                79.756,
                79.783,
                79.81,
                79.838,
                79.865,
                79.892,
                79.919,
                79.947,
                79.974,
                80.001,
                80.028,
                80.056,
                80.083,
                80.109,
                80.137,
                80.164,
                80.191,
                80.217,
                80.245,
                80.272,
                80.298,
                80.325,
                80.353,
                80.379,
                80.406,
                80.432,
                80.46,
                80.486,
                80.513,
                80.539,
                80.567,
                80.593,
                80.619,
                80.645,
                80.673,
                80.699,
                80.726,
                80.752,
                80.779,
                80.805,
                80.832,
                80.858,
                80.885,
                80.911,
                80.937,
                80.965,
                80.99,
                81.016,
                81.042,
                81.07,
                81.096,
                80.422,
                80.448,
                80.474,
                80.5,
                80.527,
                80.553,
                80.578,
                80.604,
                80.631,
                80.657,
                80.682,
                80.71,
                80.735,
                80.761,
                80.786,
                80.813,
                80.839,
                80.864,
                80.89,
                80.917,
                80.942,
                80.968,
                80.994,
                81.02,
                81.045,
                81.07,
                81.097,
                81.123,
                81.148,
                81.173,
                81.2,
                81.225,
                81.25,
                81.277,
                81.302,
                81.327,
                81.352,
                81.379,
                81.404,
                81.429,
                81.454,
                81.48,
                81.505,
                81.53,
                81.557,
                81.582,
                81.607,
                81.632,
                81.658,
                81.683,
                81.708,
                81.734,
                81.759,
                81.783,
                81.808,
                81.834,
                81.859,
                81.884,
                81.91,
                81.935,
                81.959,
                81.984,
                82.01,
                82.034,
                82.059,
                82.084,
                82.109,
                82.134,
                82.159,
                82.184,
                82.209,
                82.233,
                82.258,
                82.283,
                82.308,
                82.332,
                82.358,
                82.382,
                82.407,
                82.432,
                82.456,
                82.481,
                82.505,
                82.53,
                82.555,
                82.579,
                82.604,
                82.628,
                82.653,
                82.677,
                82.702,
                82.726,
                82.75,
                82.775,
                82.8,
                82.823,
                82.847,
                82.873,
                82.897,
                82.92,
                82.946,
                82.969,
                82.993,
                83.019,
                83.042,
                83.066,
                83.09,
                83.115,
                83.139,
                83.162,
                83.187,
                83.211,
                83.234,
                83.259,
                83.283,
                83.307,
                83.33,
                83.355,
                83.378,
                83.402,
                83.427,
                83.45,
                83.474,
                83.498,
                83.522,
                83.545,
                83.569,
                83.593,
                83.617,
                83.64,
                83.665,
                83.688,
                83.711,
                83.736,
                83.759,
                83.782,
                83.806,
                83.83,
                83.853,
                83.876,
                83.9,
                83.923,
                83.946,
                83.971,
                83.994,
                84.017,
                84.041,
                84.064,
                84.087,
                84.111,
                84.134,
                84.157,
                84.181,
                84.204,
                84.227,
                84.251,
                84.274,
                84.296,
                84.319,
                84.343,
                84.366,
                84.389,
                84.413,
                84.435,
                84.458,
                84.482,
                84.504,
                84.527,
                84.551,
                84.573,
                84.596,
                84.62,
                84.642,
                84.665,
                84.689,
                84.711,
                84.733,
                84.757,
                84.78,
                84.802,
                84.826,
                84.848,
                84.87,
                84.894,
                84.916,
                84.938,
                84.962,
                84.984,
                85.006,
                85.03,
                85.052,
                85.074,
                85.098,
                85.12,
                85.142,
                85.165,
                85.187,
                85.209,
                85.233,
                85.255,
                85.277,
                85.3,
                85.322,
                85.344,
                85.367,
                85.389,
                85.411,
                85.434,
                85.456,
                85.478,
                85.501,
                85.523,
                85.544,
                85.568,
                85.589,
                85.611,
                85.634,
                85.656,
                85.677,
                85.701,
                85.722,
                85.745,
                85.767,
                85.788,
                85.811,
                85.833,
                85.854,
                85.877,
                85.899,
                85.92,
                85.943,
                85.965,
                85.986,
                86.009,
                86.03,
                86.052,
                86.075,
                86.096,
                86.119,
                86.14,
                86.161,
                86.184,
                86.205,
                86.227,
                86.249,
                86.271,
                86.292,
                86.314,
                86.336,
                86.358,
                86.379,
                86.4,
                86.423,
                86.444,
                86.465,
                86.488,
                86.509,
                86.53,
                86.552,
                86.573,
                86.596,
                86.617,
                86.638,
                86.66,
                86.681,
                86.702,
                86.725,
                86.746,
                86.768,
                86.789,
                86.81,
                86.832,
                86.853,
                86.874,
                86.896,
                86.917,
                86.939,
                86.96,
                86.981,
                87.003,
                87.024,
                87.044,
                87.066,
                87.087,
                87.109,
                87.13,
                87.151,
                87.173,
                87.193,
                87.215,
                87.236,
                87.257,
                87.279,
                87.299,
                87.321,
                87.342,
                87.362,
                87.384,
                87.405,
                87.425,
                87.447,
                87.468,
                87.49,
                87.51,
                87.531,
                87.552,
                87.573,
                87.595,
                87.615,
                87.636,
                87.657,
                87.678,
                87.7,
                87.72,
                87.74,
                87.762,
                87.782,
                87.804,
                87.824,
                87.844,
                87.866,
                87.886,
                87.908,
                87.928,
                87.948,
                87.97,
                87.99,
                88.012,
                88.032,
                88.052,
                88.074,
                88.094,
                88.116,
                88.136,
                88.156,
                88.177,
                88.197,
                88.219,
                88.239,
                88.26,
                88.28,
                88.3,
                88.322,
                88.342,
                88.363,
                88.383,
                88.403,
                88.425,
                88.445,
                88.466,
                88.486,
                88.506,
                88.527,
                88.547,
                88.568,
                88.588,
                88.61,
                88.629,
                88.649,
                88.67,
                88.69,
                88.712,
                88.731,
                88.753,
                88.772,
                88.792,
                88.813,
                88.833,
                88.854,
                88.874,
                88.895,
                88.915,
                88.934,
                88.956,
                88.975,
                88.996,
                89.016,
                89.037,
                89.057,
                89.076,
                89.097,
                89.117,
                89.138,
                89.158,
                89.179,
                89.198,
                89.218,
                89.239,
                89.258,
                89.279,
                89.299,
                89.32,
                89.339,
                89.359,
                89.38,
                89.399,
                89.42,
                89.439,
                89.46,
                89.48,
                89.501,
                89.52,
                89.539,
                89.56,
                89.58,
                89.6,
                89.62,
                89.64,
                89.66,
                89.679,
                89.7,
                89.719,
                89.74,
                89.759,
                89.78,
                89.799,
                89.82,
                89.839,
                89.86,
                89.879,
                89.898,
                89.919,
                89.938,
                89.959,
                89.978,
                89.999,
                90.018,
                90.038,
                90.057,
                90.076,
                90.097,
                90.116,
                90.137,
                90.156,
                90.176,
                90.195,
                90.216,
                90.235,
                90.256,
                90.275,
                90.294,
                90.314,
                90.333,
                90.353,
                90.372,
                90.393,
                90.412,
                90.432,
                90.451,
                90.472,
                90.491,
                90.511,
                90.53,
                90.549,
                90.569,
                90.588,
                90.608,
                90.627,
                90.647,
                90.666,
                90.687,
                90.705,
                90.726,
                90.745,
                90.765,
                90.784,
                90.802,
                90.823,
                90.841,
                90.861,
                90.88,
                90.9,
                90.919,
                90.939,
                90.958,
                90.978,
                90.997,
                91.017,
                91.036,
                91.056,
                91.074,
                91.095,
                91.113,
                91.132,
                91.152,
                91.17,
                91.191,
                91.209,
                91.229,
                91.248,
                91.268,
                91.286,
                91.306,
                91.325,
                91.345,
                91.363,
                91.383,
                91.402,
                91.422,
                91.44,
                91.46,
                91.479,
                91.499,
                91.517,
                91.537,
                91.555,
                91.574,
                91.594,
                91.612,
                91.632,
                91.65,
                91.67,
                91.688,
                91.708,
                91.726,
                91.746,
                91.765,
                91.784,
                91.803,
                91.822,
                91.841,
                91.86,
                91.879,
                91.898,
                91.917,
                91.936,
                91.955,
                91.974,
                91.992,
                92.012,
                92.03,
                92.05,
                92.068,
                92.088,
                92.106,
                92.126,
                92.144,
                92.163,
                92.181,
                92.201,
                92.219,
                92.239,
                92.257,
                92.275,
                92.294,
                92.312,
                92.332,
                92.35,
                92.369,
                92.387,
                92.407,
                92.425,
                92.444,
                92.462,
                92.482,
                92.5,
                92.519,
                92.537,
                92.556,
                92.574,
                92.594,
                92.612,
                92.631,
                92.649,
                92.668,
                92.686,
                92.706,
                92.723,
                92.743,
                92.76,
                92.78,
                92.798,
                92.817,
                92.835,
                92.854,
                92.872,
                92.891,
                92.909,
                92.928,
                92.946,
                92.965,
                92.983,
                93.002,
                93.02,
                93.039,
                93.056,
                93.076,
                93.093,
                93.113,
                93.13,
                93.149,
                93.168,
                93.186,
                93.205,
                93.223,
                93.242,
                93.259,
                93.279,
                93.296,
                93.315,
                93.333,
                93.352,
                93.369,
                93.388,
                93.406,
                93.425,
                93.442,
                93.461,
                93.479,
                93.498,
                93.515,
                93.534,
                93.552,
                93.571,
                93.588,
                93.607,
                93.624,
                93.643,
                93.661,
                93.68,
                93.697,
                93.716,
                93.733,
                93.752,
                93.769,
                93.788,
                93.805,
                93.824,
                93.842,
                93.86,
                93.878,
                93.897,
                93.915,
                93.933,
                93.951,
                93.969,
                93.987,
                94.004,
                94.023,
                94.04,
                94.059,
                94.076,
                94.095,
                94.112,
                94.131,
                94.148,
                94.167,
                94.184,
                94.203,
                94.22,
                94.238,
                94.255,
                94.274,
                94.291,
                94.31,
                94.327,
                94.345,
                94.364,
                94.381,
                94.4,
                94.417,
                94.435,
                94.452,
                94.471,
                94.488,
                94.506,
                94.523,
                94.542,
                94.559,
                94.577,
                94.594,
                94.613,
                94.629,
                94.648,
                94.665,
                94.683,
                94.702,
                94.719,
                94.737,
                94.754,
                94.772,
                94.789,
                94.808,
                94.824,
                94.843,
                94.86,
                94.878,
                94.895,
                94.913,
                94.93,
                94.948,
                94.965,
                94.983,
                95.002,
                95.018,
                95.037,
                95.053,
                95.072,
                95.088,
                95.107,
                95.123,
                95.142,
                95.158,
                95.177,
                95.193,
                95.211,
                95.23,
                95.246,
                95.265,
                95.281,
                95.299,
                95.316,
                95.334,
                95.351,
                95.369,
                95.385,
                95.404,
                95.42,
                95.438,
                95.456,
                95.473,
                95.491,
                95.508,
                95.526,
                95.542,
                95.56,
                95.577,
                95.595,
                95.611,
                95.629,
                95.646,
                95.664,
                95.682,
                95.699,
                95.717,
                95.733,
                95.751,
                95.767,
                95.785,
                95.802,
                95.82,
                95.836,
                95.854,
                95.872,
                95.889,
                95.907,
                95.923,
                95.941,
                95.957,
                95.975,
                95.991,
                96.009,
                96.026,
                96.044,
                96.062,
                96.078,
                96.096,
                96.112,
                96.13,
                96.146,
                96.164,
                96.18,
                96.198,
                96.214,
                96.232,
                96.25,
                96.266,
                96.284,
                96.3,
                96.318,
                96.334,
                96.352,
                96.368,
                96.386,
                96.404,
                96.42,
                96.438,
                96.454,
                96.472,
                96.488,
                96.505,
                96.522,
                96.539,
                96.557,
                96.573,
                96.591,
                96.607,
                96.625,
                96.641,
                96.658,
                96.674,
                96.692,
                96.71,
                96.726,
                96.744,
                96.76,
                96.777,
                96.793,
                96.811,
                96.827,
                96.844,
                96.862,
                96.878,
                96.896,
                96.912,
                96.929,
                96.945,
                96.963,
                96.98,
                96.996,
                97.014,
                97.03,
                97.047,
                97.063,
                97.081,
                97.097,
                97.114,
                97.132,
                97.148,
                97.165,
                97.181,
                97.199,
                97.214,
                97.232,
                97.249,
                97.265,
                97.283,
                97.298,
                97.316,
                97.332,
                97.349,
                97.365,
                97.383,
                97.4,
                97.416,
                97.433,
                97.449,
                97.466,
                97.482,
                97.5,
                97.517,
                97.533,
                97.55,
                97.566,
                97.583,
                97.599,
                97.616,
                97.634,
                97.649,
                97.667,
                97.682,
                97.7,
                97.715,
                97.733,
                97.75,
                97.766,
                97.783,
                97.799,
                97.816,
                97.832,
                97.849,
                97.866,
                97.882,
                97.899,
                97.915,
                97.932,
                97.947,
                97.965,
                97.982,
                97.998,
                98.015,
                98.03,
                98.048,
                98.063,
                98.08,
                98.098,
                98.113,
                98.13,
                98.146,
                98.163,
                98.178,
                98.196,
                98.213,
                98.228,
                98.245,
                98.261,
                98.278,
                98.293,
                98.311,
                98.328,
                98.343,
                98.36,
                98.376,
                98.393,
                98.41,
                98.425,
                98.443,
                98.458,
                98.475,
                98.49,
                98.507,
                98.525,
                98.54,
                98.557,
                98.572,
                98.589,
                98.605,
                98.622,
                98.639,
                98.654,
                98.671,
                98.686,
                98.703,
                98.721,
                98.736,
                98.753,
                98.768,
                98.785,
                98.8,
                98.817,
                98.834,
                98.85,
                98.867,
                98.882,
                98.899,
                98.916,
                98.931,
                98.948,
                98.963,
                98.98,
                98.995,
                99.012,
                99.029,
                99.044,
                99.061,
                99.076,
                99.093,
                99.11,
                99.125,
                99.142,
                99.157,
                99.174,
                99.189,
                99.206,
                99.223,
                99.238,
                99.255,
                99.27,
                99.287,
                99.303,
                99.319,
                99.335,
                99.35,
                99.367,
                99.384,
                99.399,
                99.416,
                99.431,
                99.448,
                99.463,
                99.479,
                99.496,
                99.511,
                99.528,
                99.543,
                99.56,
                99.576,
                99.591,
                99.608,
                99.623,
                99.64,
                99.656,
                99.671,
                99.688,
                99.703,
                99.72,
                99.735,
                99.751,
                99.768,
                99.783,
                99.799,
                99.814,
                99.831,
                99.848,
                99.863,
                99.879,
                99.894,
                99.911,
                99.927,
                99.942,
                99.959,
                99.973,
                99.99,
                100.007,
                100.021,
                100.038,
                100.053,
                100.069,
                100.084,
                100.101,
                100.117,
                100.132,
                100.149,
                100.163,
                100.18,
                100.196,
                100.211,
                100.228,
                100.242,
                100.259,
                100.275,
                100.29,
                100.307,
                100.321,
                100.338,
                100.354,
                100.369,
                100.385,
                100.4,
                100.416,
                100.433,
                100.448,
                100.464,
                100.479,
                100.495,
                100.511,
                100.526,
                100.542,
                100.557,
                100.573,
                100.588,
                100.604,
                100.621,
                100.635,
                100.652,
                100.666,
                100.683,
                100.699,
                100.714,
                100.73,
                100.744,
                100.761,
                100.777,
                100.792,
                100.808,
                100.822,
                100.839,
                100.855,
                100.87,
                100.886,
                100.9,
                100.917,
                100.933,
                100.947,
                100.964,
                100.978,
                100.994,
                101.011,
                101.025,
                101.041,
                101.056,
                101.072,
                101.088,
                101.103,
                101.119,
                101.133,
                101.149,
                101.166,
                101.18,
                101.196,
                101.21,
                101.227,
                101.243,
                101.257,
                101.273,
                101.288,
                101.304,
                101.32,
                101.334,
                101.35,
                101.365,
                101.381,
                101.397,
                101.411,
                101.427,
                101.442,
                101.458,
                101.474,
                101.488,
                101.504,
                101.519,
                101.535,
                101.551,
                101.565,
                101.581,
                101.597,
                101.611,
                101.627,
                101.642,
                101.658,
                101.674,
                101.688,
                101.704,
                101.718,
                101.734,
                101.75,
                101.764,
                101.78,
                101.794,
                101.81,
                101.826,
                101.841,
                101.857,
                101.871,
                101.887,
                101.903,
                101.917,
                101.933,
                101.947,
                101.963,
                101.979,
                101.993,
                102.009,
                102.023,
                102.039,
                102.055
            ],
            P10: [
                46.761,
                46.926,
                47.091,
                47.255,
                47.42,
                47.586,
                47.751,
                47.916,
                48.082,
                48.247,
                48.412,
                48.577,
                48.742,
                48.908,
                49.073,
                49.208,
                49.342,
                49.476,
                49.609,
                49.741,
                49.872,
                50.002,
                50.131,
                50.259,
                50.386,
                50.513,
                50.638,
                50.763,
                50.886,
                51.008,
                51.13,
                51.251,
                51.37,
                51.489,
                51.607,
                51.724,
                51.84,
                51.955,
                52.069,
                52.182,
                52.295,
                52.406,
                52.517,
                52.627,
                52.737,
                52.845,
                52.952,
                53.059,
                53.165,
                53.269,
                53.373,
                53.477,
                53.58,
                53.682,
                53.783,
                53.883,
                53.982,
                54.081,
                54.179,
                54.277,
                54.374,
                54.47,
                54.565,
                54.66,
                54.754,
                54.847,
                54.94,
                55.033,
                55.125,
                55.216,
                55.306,
                55.396,
                55.486,
                55.574,
                55.663,
                55.75,
                55.837,
                55.924,
                56.01,
                56.095,
                56.18,
                56.265,
                56.348,
                56.432,
                56.514,
                56.597,
                56.679,
                56.76,
                56.841,
                56.921,
                57.002,
                57.081,
                57.159,
                57.238,
                57.316,
                57.394,
                57.471,
                57.548,
                57.624,
                57.7,
                57.775,
                57.85,
                57.925,
                58,
                58.073,
                58.146,
                58.219,
                58.292,
                58.364,
                58.436,
                58.508,
                58.579,
                58.649,
                58.719,
                58.789,
                58.858,
                58.927,
                58.996,
                59.064,
                59.132,
                59.199,
                59.266,
                59.332,
                59.399,
                59.465,
                59.531,
                59.596,
                59.661,
                59.726,
                59.79,
                59.853,
                59.917,
                59.98,
                60.043,
                60.106,
                60.167,
                60.229,
                60.291,
                60.352,
                60.413,
                60.473,
                60.534,
                60.594,
                60.653,
                60.713,
                60.772,
                60.83,
                60.889,
                60.948,
                61.005,
                61.063,
                61.121,
                61.178,
                61.235,
                61.292,
                61.348,
                61.405,
                61.46,
                61.516,
                61.572,
                61.627,
                61.683,
                61.737,
                61.792,
                61.846,
                61.901,
                61.955,
                62.009,
                62.062,
                62.116,
                62.169,
                62.222,
                62.275,
                62.327,
                62.38,
                62.432,
                62.485,
                62.537,
                62.588,
                62.641,
                62.692,
                62.743,
                62.795,
                62.846,
                62.897,
                62.948,
                62.998,
                63.048,
                63.099,
                63.149,
                63.199,
                63.249,
                63.299,
                63.349,
                63.399,
                63.448,
                63.497,
                63.546,
                63.595,
                63.644,
                63.693,
                63.742,
                63.79,
                63.839,
                63.887,
                63.935,
                63.983,
                64.031,
                64.079,
                64.127,
                64.175,
                64.222,
                64.27,
                64.317,
                64.364,
                64.411,
                64.458,
                64.506,
                64.553,
                64.6,
                64.646,
                64.693,
                64.739,
                64.786,
                64.832,
                64.878,
                64.924,
                64.97,
                65.016,
                65.062,
                65.108,
                65.154,
                65.199,
                65.245,
                65.29,
                65.336,
                65.381,
                65.427,
                65.472,
                65.517,
                65.562,
                65.607,
                65.652,
                65.697,
                65.741,
                65.786,
                65.831,
                65.875,
                65.92,
                65.964,
                66.008,
                66.052,
                66.096,
                66.14,
                66.184,
                66.228,
                66.272,
                66.316,
                66.36,
                66.404,
                66.447,
                66.49,
                66.534,
                66.577,
                66.621,
                66.664,
                66.707,
                66.75,
                66.793,
                66.836,
                66.879,
                66.922,
                66.965,
                67.007,
                67.05,
                67.093,
                67.135,
                67.178,
                67.22,
                67.263,
                67.305,
                67.347,
                67.389,
                67.432,
                67.474,
                67.516,
                67.558,
                67.6,
                67.641,
                67.683,
                67.725,
                67.767,
                67.808,
                67.85,
                67.892,
                67.933,
                67.974,
                68.016,
                68.057,
                68.098,
                68.14,
                68.181,
                68.221,
                68.263,
                68.304,
                68.344,
                68.385,
                68.427,
                68.467,
                68.508,
                68.549,
                68.589,
                68.63,
                68.67,
                68.71,
                68.751,
                68.791,
                68.831,
                68.872,
                68.912,
                68.952,
                68.992,
                69.032,
                69.072,
                69.112,
                69.152,
                69.191,
                69.231,
                69.271,
                69.311,
                69.35,
                69.389,
                69.429,
                69.468,
                69.508,
                69.548,
                69.587,
                69.626,
                69.665,
                69.705,
                69.743,
                69.783,
                69.821,
                69.861,
                69.899,
                69.938,
                69.977,
                70.016,
                70.055,
                70.093,
                70.132,
                70.17,
                70.209,
                70.247,
                70.286,
                70.324,
                70.363,
                70.401,
                70.439,
                70.477,
                70.516,
                70.553,
                70.592,
                70.63,
                70.668,
                70.705,
                70.744,
                70.781,
                70.819,
                70.857,
                70.894,
                70.932,
                70.969,
                71.007,
                71.044,
                71.082,
                71.119,
                71.157,
                71.194,
                71.232,
                71.269,
                71.306,
                71.343,
                71.38,
                71.418,
                71.454,
                71.492,
                71.528,
                71.566,
                71.602,
                71.639,
                71.676,
                71.712,
                71.75,
                71.786,
                71.823,
                71.859,
                71.895,
                71.932,
                71.968,
                72.005,
                72.041,
                72.077,
                72.114,
                72.15,
                72.187,
                72.223,
                72.258,
                72.295,
                72.331,
                72.367,
                72.403,
                72.438,
                72.475,
                72.51,
                72.547,
                72.582,
                72.617,
                72.654,
                72.689,
                72.724,
                72.76,
                72.795,
                72.831,
                72.866,
                72.901,
                72.937,
                72.972,
                73.007,
                73.043,
                73.078,
                73.113,
                73.148,
                73.183,
                73.219,
                73.254,
                73.288,
                73.324,
                73.358,
                73.393,
                73.428,
                73.463,
                73.497,
                73.532,
                73.567,
                73.601,
                73.636,
                73.67,
                73.705,
                73.74,
                73.774,
                73.809,
                73.843,
                73.877,
                73.912,
                73.946,
                73.98,
                74.015,
                74.049,
                74.082,
                74.117,
                74.151,
                74.185,
                74.219,
                74.253,
                74.286,
                74.321,
                74.355,
                74.388,
                74.421,
                74.456,
                74.489,
                74.523,
                74.557,
                74.59,
                74.624,
                74.658,
                74.691,
                74.724,
                74.758,
                74.791,
                74.825,
                74.859,
                74.892,
                74.925,
                74.959,
                74.991,
                75.024,
                75.057,
                75.091,
                75.124,
                75.157,
                75.19,
                75.223,
                75.256,
                75.289,
                75.322,
                75.354,
                75.388,
                75.42,
                75.453,
                75.485,
                75.519,
                75.551,
                75.584,
                75.617,
                75.649,
                75.681,
                75.715,
                75.747,
                75.779,
                75.811,
                75.844,
                75.876,
                75.908,
                75.942,
                75.973,
                76.005,
                76.037,
                76.07,
                76.102,
                76.134,
                76.167,
                76.199,
                76.23,
                76.262,
                76.295,
                76.326,
                76.358,
                76.391,
                76.422,
                76.454,
                76.485,
                76.518,
                76.549,
                76.581,
                76.613,
                76.644,
                76.676,
                76.707,
                76.739,
                76.77,
                76.802,
                76.834,
                76.865,
                76.896,
                76.927,
                76.959,
                76.99,
                77.021,
                77.052,
                77.084,
                77.115,
                77.146,
                77.178,
                77.209,
                77.24,
                77.271,
                77.302,
                77.333,
                77.364,
                77.395,
                77.426,
                77.457,
                77.488,
                77.518,
                77.55,
                77.58,
                77.611,
                77.642,
                77.673,
                77.703,
                77.734,
                77.765,
                77.795,
                77.826,
                77.856,
                77.887,
                77.917,
                77.948,
                77.978,
                78.009,
                78.039,
                78.069,
                78.1,
                78.13,
                78.16,
                78.19,
                78.221,
                78.251,
                78.281,
                78.311,
                78.342,
                78.372,
                78.402,
                78.431,
                78.462,
                78.492,
                78.522,
                78.551,
                78.582,
                78.612,
                78.641,
                78.671,
                78.702,
                78.731,
                78.761,
                78.79,
                78.821,
                78.85,
                78.879,
                78.909,
                78.939,
                78.969,
                78.998,
                79.027,
                79.058,
                79.087,
                79.116,
                79.146,
                79.175,
                79.205,
                79.234,
                79.264,
                79.293,
                79.322,
                79.351,
                79.381,
                79.41,
                79.439,
                79.468,
                79.498,
                79.527,
                79.555,
                79.584,
                79.614,
                79.643,
                79.672,
                79.7,
                79.73,
                79.759,
                79.787,
                79.816,
                79.846,
                79.874,
                79.903,
                79.931,
                79.961,
                79.989,
                80.018,
                80.046,
                80.076,
                80.104,
                80.132,
                80.161,
                80.19,
                80.219,
                80.247,
                80.275,
                80.304,
                80.332,
                80.361,
                80.389,
                80.418,
                80.446,
                80.474,
                80.502,
                80.531,
                80.559,
                80.587,
                80.615,
                80.644,
                80.672,
                80.7,
                80.728,
                80.757,
                80.784,
                80.812,
                80.84,
                80.869,
                80.897,
                80.924,
                80.952,
                80.981,
                81.008,
                81.036,
                81.063,
                81.092,
                81.119,
                81.147,
                81.174,
                81.203,
                81.23,
                81.258,
                81.286,
                81.314,
                81.341,
                81.368,
                81.397,
                81.424,
                81.451,
                81.478,
                81.507,
                81.534,
                81.561,
                81.588,
                81.616,
                81.644,
                81.671,
                81.698,
                81.726,
                81.753,
                81.78,
                81.807,
                81.835,
                81.862,
                81.889,
                81.915,
                81.943,
                81.97,
                81.997,
                82.024,
                82.052,
                82.079,
                82.105,
                82.133,
                82.16,
                82.186,
                82.213,
                82.241,
                82.267,
                81.594,
                81.621,
                81.648,
                81.674,
                81.702,
                81.728,
                81.755,
                81.781,
                81.809,
                81.835,
                81.861,
                81.889,
                81.915,
                81.941,
                81.968,
                81.995,
                82.021,
                82.047,
                82.074,
                82.101,
                82.127,
                82.153,
                82.18,
                82.207,
                82.233,
                82.259,
                82.286,
                82.312,
                82.338,
                82.364,
                82.391,
                82.417,
                82.443,
                82.47,
                82.495,
                82.521,
                82.547,
                82.574,
                82.6,
                82.626,
                82.651,
                82.678,
                82.704,
                82.73,
                82.756,
                82.782,
                82.808,
                82.833,
                82.86,
                82.886,
                82.911,
                82.938,
                82.963,
                82.989,
                83.014,
                83.041,
                83.066,
                83.092,
                83.118,
                83.143,
                83.169,
                83.194,
                83.221,
                83.246,
                83.271,
                83.296,
                83.323,
                83.348,
                83.373,
                83.399,
                83.425,
                83.45,
                83.475,
                83.501,
                83.526,
                83.551,
                83.577,
                83.602,
                83.627,
                83.653,
                83.678,
                83.703,
                83.728,
                83.754,
                83.779,
                83.804,
                83.83,
                83.854,
                83.879,
                83.904,
                83.93,
                83.955,
                83.979,
                84.005,
                84.03,
                84.054,
                84.079,
                84.105,
                84.129,
                84.154,
                84.179,
                84.204,
                84.228,
                84.254,
                84.279,
                84.303,
                84.327,
                84.353,
                84.377,
                84.402,
                84.427,
                84.451,
                84.476,
                84.501,
                84.525,
                84.55,
                84.574,
                84.599,
                84.623,
                84.647,
                84.673,
                84.697,
                84.721,
                84.746,
                84.77,
                84.794,
                84.818,
                84.843,
                84.867,
                84.891,
                84.916,
                84.94,
                84.964,
                84.989,
                85.013,
                85.037,
                85.062,
                85.086,
                85.109,
                85.133,
                85.158,
                85.182,
                85.205,
                85.23,
                85.254,
                85.277,
                85.302,
                85.326,
                85.349,
                85.374,
                85.398,
                85.421,
                85.446,
                85.469,
                85.493,
                85.517,
                85.541,
                85.564,
                85.588,
                85.612,
                85.636,
                85.659,
                85.683,
                85.707,
                85.73,
                85.754,
                85.778,
                85.801,
                85.825,
                85.848,
                85.871,
                85.896,
                85.919,
                85.942,
                85.966,
                85.989,
                86.012,
                86.037,
                86.06,
                86.083,
                86.107,
                86.13,
                86.153,
                86.177,
                86.2,
                86.222,
                86.246,
                86.269,
                86.292,
                86.316,
                86.339,
                86.362,
                86.386,
                86.408,
                86.431,
                86.455,
                86.478,
                86.5,
                86.524,
                86.547,
                86.569,
                86.593,
                86.616,
                86.638,
                86.662,
                86.685,
                86.707,
                86.731,
                86.753,
                86.776,
                86.799,
                86.822,
                86.844,
                86.868,
                86.89,
                86.913,
                86.936,
                86.958,
                86.981,
                87.004,
                87.026,
                87.05,
                87.072,
                87.094,
                87.118,
                87.14,
                87.162,
                87.185,
                87.208,
                87.23,
                87.253,
                87.275,
                87.297,
                87.32,
                87.343,
                87.365,
                87.388,
                87.41,
                87.433,
                87.455,
                87.477,
                87.5,
                87.522,
                87.544,
                87.567,
                87.589,
                87.611,
                87.634,
                87.656,
                87.679,
                87.7,
                87.722,
                87.745,
                87.767,
                87.789,
                87.812,
                87.833,
                87.855,
                87.878,
                87.9,
                87.922,
                87.944,
                87.966,
                87.989,
                88.01,
                88.032,
                88.055,
                88.076,
                88.099,
                88.12,
                88.142,
                88.165,
                88.186,
                88.208,
                88.23,
                88.252,
                88.274,
                88.296,
                88.317,
                88.34,
                88.361,
                88.383,
                88.405,
                88.427,
                88.449,
                88.47,
                88.492,
                88.514,
                88.536,
                88.558,
                88.579,
                88.601,
                88.623,
                88.644,
                88.667,
                88.688,
                88.709,
                88.731,
                88.753,
                88.774,
                88.796,
                88.817,
                88.839,
                88.861,
                88.882,
                88.904,
                88.925,
                88.947,
                88.968,
                88.989,
                89.011,
                89.032,
                89.055,
                89.076,
                89.097,
                89.119,
                89.14,
                89.162,
                89.183,
                89.204,
                89.226,
                89.247,
                89.269,
                89.29,
                89.31,
                89.332,
                89.353,
                89.375,
                89.396,
                89.417,
                89.439,
                89.459,
                89.482,
                89.502,
                89.523,
                89.545,
                89.566,
                89.587,
                89.608,
                89.63,
                89.651,
                89.671,
                89.693,
                89.714,
                89.736,
                89.756,
                89.777,
                89.799,
                89.819,
                89.841,
                89.862,
                89.882,
                89.904,
                89.924,
                89.946,
                89.967,
                89.988,
                90.009,
                90.029,
                90.051,
                90.071,
                90.093,
                90.113,
                90.135,
                90.155,
                90.176,
                90.197,
                90.218,
                90.239,
                90.26,
                90.281,
                90.302,
                90.322,
                90.343,
                90.364,
                90.385,
                90.405,
                90.427,
                90.447,
                90.468,
                90.489,
                90.509,
                90.531,
                90.551,
                90.572,
                90.592,
                90.613,
                90.634,
                90.654,
                90.676,
                90.696,
                90.717,
                90.737,
                90.757,
                90.779,
                90.799,
                90.82,
                90.84,
                90.861,
                90.881,
                90.903,
                90.923,
                90.943,
                90.964,
                90.984,
                91.005,
                91.025,
                91.046,
                91.066,
                91.086,
                91.107,
                91.127,
                91.148,
                91.168,
                91.189,
                91.209,
                91.23,
                91.25,
                91.271,
                91.291,
                91.311,
                91.332,
                91.352,
                91.373,
                91.393,
                91.414,
                91.433,
                91.454,
                91.474,
                91.494,
                91.515,
                91.535,
                91.555,
                91.575,
                91.596,
                91.616,
                91.637,
                91.656,
                91.677,
                91.697,
                91.717,
                91.737,
                91.757,
                91.778,
                91.797,
                91.818,
                91.838,
                91.859,
                91.878,
                91.899,
                91.919,
                91.939,
                91.959,
                91.978,
                91.999,
                92.019,
                92.039,
                92.059,
                92.079,
                92.099,
                92.12,
                92.139,
                92.16,
                92.179,
                92.2,
                92.219,
                92.239,
                92.259,
                92.279,
                92.299,
                92.319,
                92.339,
                92.358,
                92.379,
                92.398,
                92.419,
                92.438,
                92.459,
                92.478,
                92.498,
                92.518,
                92.538,
                92.557,
                92.577,
                92.597,
                92.616,
                92.637,
                92.656,
                92.676,
                92.696,
                92.716,
                92.735,
                92.756,
                92.775,
                92.795,
                92.814,
                92.835,
                92.854,
                92.874,
                92.893,
                92.913,
                92.932,
                92.953,
                92.972,
                92.992,
                93.011,
                93.03,
                93.05,
                93.069,
                93.09,
                93.109,
                93.129,
                93.148,
                93.168,
                93.187,
                93.207,
                93.226,
                93.246,
                93.265,
                93.285,
                93.304,
                93.324,
                93.343,
                93.363,
                93.382,
                93.402,
                93.421,
                93.441,
                93.46,
                93.48,
                93.499,
                93.519,
                93.538,
                93.558,
                93.576,
                93.596,
                93.615,
                93.635,
                93.654,
                93.674,
                93.692,
                93.712,
                93.731,
                93.75,
                93.77,
                93.788,
                93.808,
                93.827,
                93.847,
                93.865,
                93.885,
                93.904,
                93.924,
                93.942,
                93.962,
                93.981,
                94,
                94.019,
                94.039,
                94.057,
                94.077,
                94.096,
                94.115,
                94.134,
                94.154,
                94.172,
                94.192,
                94.21,
                94.23,
                94.248,
                94.268,
                94.286,
                94.306,
                94.325,
                94.344,
                94.362,
                94.382,
                94.401,
                94.42,
                94.438,
                94.458,
                94.476,
                94.496,
                94.514,
                94.534,
                94.552,
                94.572,
                94.59,
                94.61,
                94.628,
                94.647,
                94.667,
                94.685,
                94.705,
                94.723,
                94.742,
                94.76,
                94.78,
                94.798,
                94.817,
                94.836,
                94.855,
                94.873,
                94.893,
                94.911,
                94.93,
                94.948,
                94.968,
                94.986,
                95.005,
                95.023,
                95.042,
                95.06,
                95.08,
                95.098,
                95.117,
                95.135,
                95.154,
                95.172,
                95.192,
                95.21,
                95.229,
                95.247,
                95.266,
                95.284,
                95.303,
                95.321,
                95.34,
                95.358,
                95.377,
                95.395,
                95.414,
                95.433,
                95.451,
                95.47,
                95.488,
                95.507,
                95.525,
                95.544,
                95.562,
                95.581,
                95.599,
                95.618,
                95.636,
                95.655,
                95.673,
                95.692,
                95.709,
                95.728,
                95.746,
                95.765,
                95.783,
                95.802,
                95.82,
                95.839,
                95.856,
                95.875,
                95.894,
                95.912,
                95.931,
                95.948,
                95.967,
                95.985,
                96.004,
                96.021,
                96.04,
                96.058,
                96.077,
                96.094,
                96.113,
                96.131,
                96.149,
                96.167,
                96.186,
                96.203,
                96.222,
                96.241,
                96.258,
                96.277,
                96.295,
                96.313,
                96.331,
                96.35,
                96.367,
                96.386,
                96.403,
                96.422,
                96.439,
                96.458,
                96.475,
                96.494,
                96.512,
                96.53,
                96.549,
                96.566,
                96.585,
                96.602,
                96.621,
                96.638,
                96.657,
                96.674,
                96.693,
                96.71,
                96.729,
                96.746,
                96.764,
                96.783,
                96.8,
                96.819,
                96.836,
                96.855,
                96.872,
                96.89,
                96.908,
                96.926,
                96.943,
                96.962,
                96.979,
                96.997,
                97.016,
                97.033,
                97.052,
                97.069,
                97.087,
                97.104,
                97.123,
                97.14,
                97.158,
                97.175,
                97.194,
                97.211,
                97.229,
                97.248,
                97.265,
                97.283,
                97.3,
                97.318,
                97.336,
                97.354,
                97.371,
                97.389,
                97.406,
                97.425,
                97.443,
                97.46,
                97.478,
                97.495,
                97.513,
                97.53,
                97.549,
                97.566,
                97.584,
                97.601,
                97.619,
                97.637,
                97.654,
                97.672,
                97.689,
                97.708,
                97.724,
                97.743,
                97.76,
                97.778,
                97.795,
                97.813,
                97.831,
                97.848,
                97.866,
                97.883,
                97.901,
                97.918,
                97.936,
                97.953,
                97.971,
                97.989,
                98.006,
                98.024,
                98.041,
                98.059,
                98.075,
                98.093,
                98.11,
                98.128,
                98.146,
                98.163,
                98.181,
                98.198,
                98.216,
                98.232,
                98.25,
                98.267,
                98.285,
                98.303,
                98.32,
                98.338,
                98.355,
                98.372,
                98.389,
                98.407,
                98.424,
                98.442,
                98.46,
                98.476,
                98.494,
                98.511,
                98.529,
                98.545,
                98.563,
                98.581,
                98.598,
                98.616,
                98.632,
                98.65,
                98.666,
                98.684,
                98.701,
                98.719,
                98.737,
                98.753,
                98.771,
                98.787,
                98.805,
                98.822,
                98.84,
                98.857,
                98.874,
                98.892,
                98.908,
                98.926,
                98.942,
                98.96,
                98.976,
                98.994,
                99.012,
                99.028,
                99.046,
                99.063,
                99.08,
                99.097,
                99.114,
                99.132,
                99.148,
                99.166,
                99.183,
                99.2,
                99.217,
                99.234,
                99.252,
                99.268,
                99.286,
                99.302,
                99.32,
                99.336,
                99.354,
                99.372,
                99.388,
                99.405,
                99.422,
                99.439,
                99.456,
                99.473,
                99.491,
                99.507,
                99.525,
                99.541,
                99.558,
                99.575,
                99.592,
                99.61,
                99.626,
                99.644,
                99.66,
                99.677,
                99.693,
                99.711,
                99.729,
                99.745,
                99.762,
                99.778,
                99.796,
                99.812,
                99.83,
                99.847,
                99.863,
                99.881,
                99.897,
                99.914,
                99.93,
                99.948,
                99.965,
                99.981,
                99.999,
                100.015,
                100.032,
                100.05,
                100.066,
                100.083,
                100.099,
                100.116,
                100.132,
                100.15,
                100.167,
                100.183,
                100.201,
                100.217,
                100.234,
                100.25,
                100.267,
                100.285,
                100.301,
                100.318,
                100.334,
                100.351,
                100.368,
                100.384,
                100.402,
                100.418,
                100.435,
                100.451,
                100.468,
                100.485,
                100.501,
                100.519,
                100.534,
                100.552,
                100.569,
                100.585,
                100.602,
                100.618,
                100.635,
                100.651,
                100.668,
                100.685,
                100.701,
                100.718,
                100.734,
                100.751,
                100.769,
                100.784,
                100.802,
                100.817,
                100.835,
                100.85,
                100.867,
                100.885,
                100.9,
                100.918,
                100.933,
                100.95,
                100.967,
                100.983,
                101,
                101.016,
                101.033,
                101.05,
                101.066,
                101.083,
                101.099,
                101.116,
                101.131,
                101.148,
                101.165,
                101.181,
                101.198,
                101.214,
                101.231,
                101.248,
                101.264,
                101.28,
                101.296,
                101.313,
                101.33,
                101.346,
                101.363,
                101.378,
                101.395,
                101.411,
                101.428,
                101.445,
                101.46,
                101.477,
                101.493,
                101.51,
                101.527,
                101.542,
                101.559,
                101.575,
                101.591,
                101.608,
                101.624,
                101.641,
                101.656,
                101.673,
                101.69,
                101.705,
                101.722,
                101.738,
                101.755,
                101.77,
                101.787,
                101.804,
                101.819,
                101.836,
                101.851,
                101.868,
                101.885,
                101.9,
                101.917,
                101.933,
                101.949,
                101.966,
                101.982,
                101.998,
                102.014,
                102.03,
                102.047,
                102.062,
                102.079,
                102.095,
                102.111,
                102.128,
                102.143,
                102.16,
                102.175,
                102.192,
                102.209,
                102.224,
                102.241,
                102.256,
                102.273,
                102.288,
                102.305,
                102.321,
                102.336,
                102.353,
                102.368,
                102.385,
                102.402,
                102.417,
                102.433,
                102.449,
                102.465,
                102.482,
                102.497,
                102.514,
                102.529,
                102.545,
                102.562,
                102.577,
                102.594,
                102.609,
                102.625,
                102.642,
                102.657,
                102.674,
                102.689,
                102.705,
                102.722,
                102.737,
                102.753,
                102.769,
                102.785,
                102.802,
                102.817,
                102.833,
                102.848,
                102.865,
                102.881,
                102.896,
                102.913,
                102.928,
                102.944,
                102.961,
                102.976,
                102.992,
                103.007,
                103.024,
                103.04,
                103.055,
                103.071,
                103.086,
                103.103,
                103.119,
                103.134,
                103.15,
                103.165,
                103.182,
                103.198,
                103.213,
                103.229,
                103.244,
                103.261,
                103.277,
                103.292,
                103.308,
                103.325,
                103.34,
                103.356,
                103.371,
                103.387,
                103.403,
                103.418,
                103.435,
                103.449,
                103.466,
                103.482,
                103.497,
                103.513,
                103.528,
                103.544,
                103.561,
                103.575,
                103.592,
                103.606,
                103.623,
                103.639,
                103.654,
                103.67,
                103.685,
                103.701,
                103.717,
                103.732,
                103.748,
                103.763,
                103.779,
                103.795
            ],
            P15: [
                47.217,
                47.383,
                47.549,
                47.714,
                47.88,
                48.046,
                48.212,
                48.378,
                48.544,
                48.71,
                48.875,
                49.041,
                49.208,
                49.374,
                49.54,
                49.676,
                49.81,
                49.945,
                50.078,
                50.211,
                50.343,
                50.474,
                50.604,
                50.733,
                50.861,
                50.988,
                51.114,
                51.239,
                51.363,
                51.486,
                51.609,
                51.73,
                51.85,
                51.97,
                52.088,
                52.206,
                52.323,
                52.439,
                52.554,
                52.667,
                52.781,
                52.893,
                53.005,
                53.115,
                53.225,
                53.334,
                53.442,
                53.549,
                53.656,
                53.761,
                53.866,
                53.97,
                54.074,
                54.176,
                54.278,
                54.378,
                54.479,
                54.578,
                54.677,
                54.775,
                54.872,
                54.969,
                55.065,
                55.16,
                55.255,
                55.349,
                55.442,
                55.536,
                55.628,
                55.72,
                55.81,
                55.901,
                55.991,
                56.08,
                56.169,
                56.258,
                56.345,
                56.432,
                56.519,
                56.605,
                56.69,
                56.775,
                56.859,
                56.944,
                57.027,
                57.11,
                57.192,
                57.273,
                57.355,
                57.436,
                57.517,
                57.596,
                57.676,
                57.755,
                57.834,
                57.912,
                57.989,
                58.067,
                58.144,
                58.22,
                58.296,
                58.371,
                58.447,
                58.521,
                58.595,
                58.669,
                58.743,
                58.816,
                58.888,
                58.96,
                59.033,
                59.104,
                59.175,
                59.246,
                59.316,
                59.386,
                59.455,
                59.524,
                59.593,
                59.661,
                59.729,
                59.796,
                59.863,
                59.93,
                59.997,
                60.063,
                60.128,
                60.194,
                60.259,
                60.324,
                60.387,
                60.451,
                60.515,
                60.578,
                60.641,
                60.703,
                60.766,
                60.828,
                60.89,
                60.951,
                61.012,
                61.073,
                61.133,
                61.193,
                61.253,
                61.313,
                61.371,
                61.431,
                61.49,
                61.548,
                61.606,
                61.664,
                61.721,
                61.779,
                61.836,
                61.893,
                61.95,
                62.006,
                62.062,
                62.118,
                62.174,
                62.23,
                62.285,
                62.34,
                62.394,
                62.45,
                62.504,
                62.558,
                62.612,
                62.666,
                62.719,
                62.773,
                62.826,
                62.879,
                62.933,
                62.985,
                63.038,
                63.09,
                63.142,
                63.195,
                63.246,
                63.298,
                63.35,
                63.401,
                63.453,
                63.504,
                63.555,
                63.606,
                63.657,
                63.707,
                63.757,
                63.808,
                63.858,
                63.908,
                63.959,
                64.008,
                64.058,
                64.107,
                64.156,
                64.206,
                64.255,
                64.304,
                64.353,
                64.403,
                64.451,
                64.5,
                64.548,
                64.596,
                64.644,
                64.693,
                64.741,
                64.789,
                64.837,
                64.885,
                64.932,
                64.98,
                65.027,
                65.075,
                65.122,
                65.169,
                65.217,
                65.264,
                65.31,
                65.357,
                65.404,
                65.45,
                65.497,
                65.543,
                65.59,
                65.636,
                65.682,
                65.728,
                65.775,
                65.821,
                65.866,
                65.912,
                65.958,
                66.004,
                66.049,
                66.095,
                66.14,
                66.186,
                66.231,
                66.276,
                66.321,
                66.366,
                66.411,
                66.456,
                66.501,
                66.546,
                66.591,
                66.635,
                66.679,
                66.724,
                66.768,
                66.813,
                66.857,
                66.901,
                66.946,
                66.99,
                67.033,
                67.077,
                67.121,
                67.165,
                67.209,
                67.253,
                67.296,
                67.339,
                67.383,
                67.426,
                67.47,
                67.513,
                67.556,
                67.599,
                67.642,
                67.686,
                67.728,
                67.771,
                67.814,
                67.857,
                67.9,
                67.942,
                67.985,
                68.028,
                68.07,
                68.112,
                68.155,
                68.197,
                68.239,
                68.282,
                68.324,
                68.366,
                68.408,
                68.45,
                68.492,
                68.534,
                68.576,
                68.618,
                68.66,
                68.701,
                68.743,
                68.784,
                68.825,
                68.867,
                68.909,
                68.95,
                68.991,
                69.033,
                69.073,
                69.115,
                69.156,
                69.197,
                69.238,
                69.278,
                69.319,
                69.36,
                69.401,
                69.442,
                69.483,
                69.523,
                69.564,
                69.604,
                69.644,
                69.685,
                69.725,
                69.766,
                69.805,
                69.846,
                69.886,
                69.926,
                69.966,
                70.006,
                70.046,
                70.086,
                70.126,
                70.166,
                70.205,
                70.245,
                70.284,
                70.324,
                70.363,
                70.403,
                70.442,
                70.482,
                70.521,
                70.561,
                70.6,
                70.639,
                70.678,
                70.717,
                70.757,
                70.795,
                70.835,
                70.873,
                70.912,
                70.951,
                70.99,
                71.028,
                71.067,
                71.106,
                71.145,
                71.183,
                71.222,
                71.26,
                71.298,
                71.336,
                71.375,
                71.413,
                71.452,
                71.49,
                71.527,
                71.566,
                71.603,
                71.642,
                71.68,
                71.718,
                71.755,
                71.794,
                71.831,
                71.869,
                71.907,
                71.944,
                71.982,
                72.019,
                72.057,
                72.094,
                72.132,
                72.169,
                72.207,
                72.244,
                72.281,
                72.318,
                72.355,
                72.393,
                72.43,
                72.467,
                72.504,
                72.541,
                72.578,
                72.614,
                72.652,
                72.688,
                72.725,
                72.762,
                72.798,
                72.835,
                72.872,
                72.908,
                72.945,
                72.981,
                73.018,
                73.054,
                73.09,
                73.127,
                73.163,
                73.2,
                73.235,
                73.271,
                73.308,
                73.344,
                73.379,
                73.416,
                73.452,
                73.488,
                73.523,
                73.559,
                73.595,
                73.631,
                73.666,
                73.702,
                73.738,
                73.773,
                73.809,
                73.844,
                73.88,
                73.915,
                73.951,
                73.986,
                74.022,
                74.057,
                74.092,
                74.127,
                74.162,
                74.198,
                74.233,
                74.267,
                74.303,
                74.338,
                74.372,
                74.408,
                74.443,
                74.478,
                74.512,
                74.547,
                74.582,
                74.617,
                74.651,
                74.686,
                74.721,
                74.755,
                74.79,
                74.824,
                74.858,
                74.893,
                74.928,
                74.962,
                74.996,
                75.031,
                75.065,
                75.098,
                75.133,
                75.167,
                75.201,
                75.236,
                75.269,
                75.303,
                75.338,
                75.372,
                75.405,
                75.44,
                75.473,
                75.507,
                75.541,
                75.575,
                75.608,
                75.643,
                75.676,
                75.709,
                75.743,
                75.777,
                75.81,
                75.843,
                75.877,
                75.911,
                75.944,
                75.978,
                76.011,
                76.044,
                76.078,
                76.111,
                76.144,
                76.177,
                76.21,
                76.243,
                76.276,
                76.31,
                76.343,
                76.375,
                76.409,
                76.442,
                76.474,
                76.507,
                76.54,
                76.573,
                76.605,
                76.639,
                76.671,
                76.704,
                76.736,
                76.769,
                76.802,
                76.834,
                76.867,
                76.899,
                76.932,
                76.964,
                76.997,
                77.029,
                77.061,
                77.094,
                77.126,
                77.158,
                77.19,
                77.223,
                77.255,
                77.287,
                77.319,
                77.351,
                77.383,
                77.415,
                77.448,
                77.479,
                77.511,
                77.543,
                77.575,
                77.607,
                77.638,
                77.671,
                77.702,
                77.734,
                77.765,
                77.798,
                77.829,
                77.86,
                77.893,
                77.924,
                77.955,
                77.987,
                78.019,
                78.05,
                78.081,
                78.112,
                78.144,
                78.175,
                78.206,
                78.238,
                78.269,
                78.3,
                78.331,
                78.363,
                78.394,
                78.425,
                78.456,
                78.488,
                78.518,
                78.549,
                78.58,
                78.612,
                78.642,
                78.673,
                78.704,
                78.735,
                78.766,
                78.796,
                78.828,
                78.858,
                78.889,
                78.919,
                78.951,
                78.981,
                79.011,
                79.042,
                79.073,
                79.103,
                79.134,
                79.164,
                79.195,
                79.225,
                79.256,
                79.286,
                79.317,
                79.347,
                79.377,
                79.407,
                79.438,
                79.468,
                79.498,
                79.528,
                79.559,
                79.589,
                79.619,
                79.648,
                79.679,
                79.709,
                79.739,
                79.769,
                79.799,
                79.829,
                79.859,
                79.889,
                79.919,
                79.948,
                79.978,
                80.008,
                80.038,
                80.067,
                80.097,
                80.127,
                80.157,
                80.186,
                80.216,
                80.246,
                80.275,
                80.304,
                80.334,
                80.364,
                80.393,
                80.422,
                80.452,
                80.482,
                80.511,
                80.54,
                80.569,
                80.599,
                80.628,
                80.657,
                80.686,
                80.716,
                80.745,
                80.774,
                80.803,
                80.833,
                80.861,
                80.89,
                80.919,
                80.949,
                80.978,
                81.006,
                81.035,
                81.064,
                81.093,
                81.122,
                81.15,
                81.18,
                81.208,
                81.237,
                81.266,
                81.295,
                81.323,
                81.352,
                81.38,
                81.41,
                81.438,
                81.466,
                81.495,
                81.524,
                81.552,
                81.58,
                81.609,
                81.638,
                81.666,
                81.694,
                81.722,
                81.751,
                81.779,
                81.807,
                81.835,
                81.864,
                81.892,
                81.92,
                81.948,
                81.977,
                82.005,
                82.033,
                82.062,
                82.089,
                82.117,
                82.145,
                82.174,
                82.201,
                82.229,
                82.257,
                82.285,
                82.313,
                82.341,
                82.368,
                82.397,
                82.424,
                82.452,
                82.48,
                82.508,
                82.535,
                82.563,
                82.59,
                82.619,
                82.646,
                82.673,
                82.701,
                82.729,
                82.756,
                82.784,
                82.811,
                82.839,
                82.866,
                82.893,
                82.921,
                82.949,
                82.976,
                83.003,
                83.031,
                83.058,
                82.385,
                82.412,
                82.44,
                82.466,
                82.494,
                82.521,
                82.548,
                82.575,
                82.603,
                82.63,
                82.657,
                82.684,
                82.711,
                82.738,
                82.765,
                82.792,
                82.819,
                82.846,
                82.872,
                82.9,
                82.927,
                82.953,
                82.981,
                83.007,
                83.034,
                83.06,
                83.088,
                83.114,
                83.141,
                83.167,
                83.194,
                83.221,
                83.247,
                83.274,
                83.301,
                83.327,
                83.353,
                83.381,
                83.407,
                83.433,
                83.459,
                83.486,
                83.513,
                83.539,
                83.566,
                83.592,
                83.618,
                83.644,
                83.671,
                83.697,
                83.723,
                83.75,
                83.776,
                83.802,
                83.828,
                83.855,
                83.881,
                83.907,
                83.933,
                83.959,
                83.985,
                84.011,
                84.037,
                84.063,
                84.089,
                84.115,
                84.141,
                84.167,
                84.193,
                84.219,
                84.245,
                84.27,
                84.296,
                84.322,
                84.348,
                84.373,
                84.4,
                84.425,
                84.451,
                84.477,
                84.502,
                84.528,
                84.553,
                84.58,
                84.605,
                84.63,
                84.656,
                84.682,
                84.707,
                84.732,
                84.758,
                84.783,
                84.809,
                84.835,
                84.86,
                84.885,
                84.91,
                84.936,
                84.961,
                84.986,
                85.012,
                85.037,
                85.062,
                85.088,
                85.113,
                85.138,
                85.162,
                85.188,
                85.213,
                85.238,
                85.264,
                85.288,
                85.313,
                85.339,
                85.363,
                85.388,
                85.413,
                85.438,
                85.463,
                85.488,
                85.513,
                85.538,
                85.562,
                85.588,
                85.612,
                85.637,
                85.661,
                85.687,
                85.711,
                85.735,
                85.761,
                85.785,
                85.81,
                85.835,
                85.859,
                85.883,
                85.909,
                85.933,
                85.957,
                85.981,
                86.007,
                86.031,
                86.055,
                86.08,
                86.104,
                86.128,
                86.153,
                86.177,
                86.201,
                86.226,
                86.25,
                86.274,
                86.299,
                86.323,
                86.347,
                86.372,
                86.396,
                86.42,
                86.444,
                86.468,
                86.492,
                86.516,
                86.541,
                86.564,
                86.588,
                86.613,
                86.637,
                86.66,
                86.685,
                86.708,
                86.732,
                86.757,
                86.78,
                86.804,
                86.828,
                86.852,
                86.875,
                86.9,
                86.923,
                86.947,
                86.971,
                86.995,
                87.018,
                87.042,
                87.066,
                87.089,
                87.113,
                87.137,
                87.16,
                87.184,
                87.207,
                87.231,
                87.255,
                87.278,
                87.301,
                87.325,
                87.348,
                87.371,
                87.396,
                87.419,
                87.442,
                87.466,
                87.489,
                87.512,
                87.536,
                87.559,
                87.582,
                87.606,
                87.629,
                87.651,
                87.675,
                87.698,
                87.721,
                87.745,
                87.768,
                87.791,
                87.814,
                87.837,
                87.86,
                87.884,
                87.906,
                87.93,
                87.953,
                87.975,
                87.999,
                88.022,
                88.044,
                88.068,
                88.091,
                88.113,
                88.137,
                88.159,
                88.182,
                88.205,
                88.228,
                88.25,
                88.274,
                88.296,
                88.32,
                88.342,
                88.364,
                88.388,
                88.41,
                88.433,
                88.456,
                88.478,
                88.501,
                88.524,
                88.546,
                88.569,
                88.592,
                88.614,
                88.637,
                88.659,
                88.682,
                88.705,
                88.727,
                88.749,
                88.772,
                88.794,
                88.817,
                88.84,
                88.862,
                88.885,
                88.907,
                88.929,
                88.952,
                88.974,
                88.997,
                89.019,
                89.041,
                89.064,
                89.086,
                89.108,
                89.131,
                89.153,
                89.175,
                89.197,
                89.219,
                89.242,
                89.264,
                89.286,
                89.308,
                89.33,
                89.353,
                89.375,
                89.397,
                89.419,
                89.441,
                89.464,
                89.486,
                89.507,
                89.53,
                89.552,
                89.574,
                89.596,
                89.618,
                89.64,
                89.662,
                89.683,
                89.706,
                89.728,
                89.75,
                89.772,
                89.793,
                89.816,
                89.837,
                89.86,
                89.881,
                89.903,
                89.925,
                89.946,
                89.969,
                89.99,
                90.012,
                90.034,
                90.056,
                90.078,
                90.099,
                90.121,
                90.143,
                90.164,
                90.187,
                90.208,
                90.229,
                90.251,
                90.273,
                90.295,
                90.316,
                90.337,
                90.36,
                90.381,
                90.403,
                90.424,
                90.445,
                90.468,
                90.489,
                90.511,
                90.532,
                90.554,
                90.575,
                90.596,
                90.618,
                90.64,
                90.662,
                90.683,
                90.704,
                90.726,
                90.747,
                90.769,
                90.79,
                90.811,
                90.833,
                90.854,
                90.876,
                90.897,
                90.919,
                90.939,
                90.96,
                90.982,
                91.003,
                91.025,
                91.046,
                91.068,
                91.089,
                91.109,
                91.131,
                91.152,
                91.174,
                91.195,
                91.216,
                91.237,
                91.258,
                91.28,
                91.301,
                91.322,
                91.343,
                91.365,
                91.385,
                91.406,
                91.428,
                91.449,
                91.47,
                91.491,
                91.512,
                91.533,
                91.554,
                91.575,
                91.596,
                91.618,
                91.638,
                91.66,
                91.68,
                91.701,
                91.722,
                91.743,
                91.764,
                91.785,
                91.806,
                91.827,
                91.848,
                91.869,
                91.889,
                91.911,
                91.931,
                91.953,
                91.973,
                91.994,
                92.015,
                92.035,
                92.057,
                92.077,
                92.098,
                92.119,
                92.14,
                92.16,
                92.182,
                92.202,
                92.223,
                92.244,
                92.264,
                92.285,
                92.306,
                92.327,
                92.347,
                92.368,
                92.388,
                92.41,
                92.43,
                92.45,
                92.471,
                92.491,
                92.513,
                92.533,
                92.554,
                92.574,
                92.595,
                92.615,
                92.636,
                92.656,
                92.677,
                92.698,
                92.718,
                92.739,
                92.759,
                92.78,
                92.8,
                92.821,
                92.841,
                92.862,
                92.882,
                92.903,
                92.923,
                92.943,
                92.964,
                92.984,
                93.005,
                93.025,
                93.046,
                93.066,
                93.086,
                93.106,
                93.127,
                93.147,
                93.168,
                93.188,
                93.208,
                93.228,
                93.248,
                93.269,
                93.289,
                93.31,
                93.33,
                93.35,
                93.37,
                93.391,
                93.411,
                93.431,
                93.451,
                93.472,
                93.491,
                93.512,
                93.532,
                93.552,
                93.572,
                93.592,
                93.613,
                93.632,
                93.653,
                93.673,
                93.693,
                93.713,
                93.733,
                93.753,
                93.774,
                93.793,
                93.814,
                93.833,
                93.854,
                93.873,
                93.894,
                93.913,
                93.934,
                93.953,
                93.974,
                93.993,
                94.013,
                94.033,
                94.053,
                94.073,
                94.093,
                94.113,
                94.133,
                94.153,
                94.172,
                94.193,
                94.212,
                94.233,
                94.252,
                94.272,
                94.292,
                94.312,
                94.331,
                94.352,
                94.371,
                94.391,
                94.41,
                94.431,
                94.45,
                94.47,
                94.489,
                94.51,
                94.529,
                94.549,
                94.568,
                94.589,
                94.608,
                94.628,
                94.647,
                94.667,
                94.686,
                94.707,
                94.726,
                94.745,
                94.765,
                94.784,
                94.804,
                94.823,
                94.843,
                94.863,
                94.883,
                94.902,
                94.922,
                94.941,
                94.961,
                94.98,
                95,
                95.019,
                95.039,
                95.058,
                95.078,
                95.097,
                95.117,
                95.136,
                95.156,
                95.175,
                95.194,
                95.213,
                95.233,
                95.252,
                95.272,
                95.291,
                95.311,
                95.33,
                95.35,
                95.368,
                95.388,
                95.407,
                95.427,
                95.446,
                95.465,
                95.484,
                95.504,
                95.523,
                95.543,
                95.561,
                95.581,
                95.6,
                95.62,
                95.638,
                95.658,
                95.678,
                95.696,
                95.716,
                95.735,
                95.754,
                95.773,
                95.793,
                95.811,
                95.831,
                95.85,
                95.869,
                95.888,
                95.907,
                95.926,
                95.946,
                95.964,
                95.984,
                96.002,
                96.022,
                96.04,
                96.06,
                96.078,
                96.098,
                96.116,
                96.136,
                96.154,
                96.174,
                96.192,
                96.212,
                96.23,
                96.25,
                96.268,
                96.287,
                96.306,
                96.325,
                96.344,
                96.363,
                96.381,
                96.401,
                96.419,
                96.438,
                96.458,
                96.476,
                96.495,
                96.514,
                96.533,
                96.551,
                96.571,
                96.589,
                96.608,
                96.626,
                96.646,
                96.664,
                96.683,
                96.701,
                96.721,
                96.739,
                96.758,
                96.776,
                96.795,
                96.814,
                96.833,
                96.851,
                96.87,
                96.888,
                96.907,
                96.927,
                96.945,
                96.964,
                96.982,
                97.001,
                97.019,
                97.038,
                97.056,
                97.075,
                97.093,
                97.112,
                97.13,
                97.149,
                97.167,
                97.186,
                97.204,
                97.223,
                97.241,
                97.26,
                97.279,
                97.297,
                97.316,
                97.334,
                97.353,
                97.371,
                97.39,
                97.408,
                97.427,
                97.445,
                97.464,
                97.482,
                97.5,
                97.518,
                97.537,
                97.555,
                97.574,
                97.593,
                97.611,
                97.629,
                97.647,
                97.666,
                97.684,
                97.703,
                97.72,
                97.739,
                97.757,
                97.776,
                97.794,
                97.812,
                97.831,
                97.849,
                97.867,
                97.885,
                97.904,
                97.922,
                97.94,
                97.958,
                97.977,
                97.994,
                98.013,
                98.031,
                98.049,
                98.068,
                98.086,
                98.104,
                98.122,
                98.141,
                98.158,
                98.177,
                98.194,
                98.213,
                98.231,
                98.249,
                98.267,
                98.285,
                98.304,
                98.321,
                98.34,
                98.357,
                98.376,
                98.394,
                98.412,
                98.43,
                98.448,
                98.466,
                98.484,
                98.503,
                98.52,
                98.538,
                98.556,
                98.574,
                98.592,
                98.61,
                98.628,
                98.646,
                98.664,
                98.682,
                98.7,
                98.718,
                98.736,
                98.754,
                98.772,
                98.789,
                98.808,
                98.825,
                98.843,
                98.861,
                98.879,
                98.897,
                98.915,
                98.933,
                98.95,
                98.969,
                98.986,
                99.004,
                99.022,
                99.04,
                99.058,
                99.075,
                99.094,
                99.111,
                99.129,
                99.146,
                99.165,
                99.182,
                99.2,
                99.218,
                99.236,
                99.254,
                99.271,
                99.289,
                99.306,
                99.325,
                99.342,
                99.36,
                99.378,
                99.395,
                99.414,
                99.431,
                99.449,
                99.466,
                99.484,
                99.501,
                99.519,
                99.537,
                99.554,
                99.573,
                99.59,
                99.608,
                99.625,
                99.643,
                99.661,
                99.678,
                99.696,
                99.713,
                99.731,
                99.748,
                99.766,
                99.783,
                99.801,
                99.819,
                99.836,
                99.854,
                99.871,
                99.889,
                99.906,
                99.924,
                99.942,
                99.959,
                99.977,
                99.994,
                100.012,
                100.029,
                100.047,
                100.064,
                100.082,
                100.1,
                100.117,
                100.134,
                100.151,
                100.169,
                100.186,
                100.204,
                100.222,
                100.239,
                100.257,
                100.273,
                100.291,
                100.308,
                100.326,
                100.344,
                100.361,
                100.379,
                100.395,
                100.413,
                100.43,
                100.448,
                100.466,
                100.482,
                100.5,
                100.517,
                100.535,
                100.551,
                100.569,
                100.587,
                100.604,
                100.621,
                100.638,
                100.656,
                100.673,
                100.69,
                100.708,
                100.725,
                100.743,
                100.759,
                100.777,
                100.793,
                100.811,
                100.829,
                100.846,
                100.863,
                100.88,
                100.898,
                100.914,
                100.932,
                100.95,
                100.966,
                100.984,
                101,
                101.018,
                101.034,
                101.052,
                101.07,
                101.086,
                101.104,
                101.121,
                101.138,
                101.156,
                101.172,
                101.19,
                101.206,
                101.224,
                101.24,
                101.258,
                101.275,
                101.292,
                101.309,
                101.326,
                101.344,
                101.36,
                101.378,
                101.395,
                101.411,
                101.429,
                101.445,
                101.463,
                101.48,
                101.497,
                101.514,
                101.531,
                101.548,
                101.564,
                101.582,
                101.599,
                101.616,
                101.633,
                101.65,
                101.667,
                101.684,
                101.701,
                101.718,
                101.734,
                101.752,
                101.768,
                101.786,
                101.803,
                101.819,
                101.837,
                101.853,
                101.87,
                101.888,
                101.904,
                101.921,
                101.937,
                101.955,
                101.971,
                101.988,
                102.006,
                102.022,
                102.039,
                102.056,
                102.073,
                102.09,
                102.106,
                102.124,
                102.14,
                102.157,
                102.174,
                102.19,
                102.208,
                102.224,
                102.241,
                102.257,
                102.274,
                102.292,
                102.308,
                102.325,
                102.341,
                102.358,
                102.376,
                102.392,
                102.409,
                102.425,
                102.442,
                102.459,
                102.475,
                102.492,
                102.509,
                102.526,
                102.542,
                102.559,
                102.576,
                102.592,
                102.609,
                102.625,
                102.642,
                102.659,
                102.675,
                102.692,
                102.708,
                102.726,
                102.743,
                102.759,
                102.776,
                102.792,
                102.809,
                102.826,
                102.842,
                102.859,
                102.875,
                102.892,
                102.907,
                102.925,
                102.942,
                102.957,
                102.974,
                102.99,
                103.007,
                103.024,
                103.04,
                103.057,
                103.073,
                103.09,
                103.107,
                103.123,
                103.14,
                103.155,
                103.172,
                103.189,
                103.205,
                103.222,
                103.238,
                103.255,
                103.272,
                103.287,
                103.304,
                103.32,
                103.337,
                103.354,
                103.37,
                103.386,
                103.402,
                103.419,
                103.435,
                103.452,
                103.468,
                103.484,
                103.501,
                103.517,
                103.533,
                103.55,
                103.566,
                103.583,
                103.598,
                103.615,
                103.632,
                103.648,
                103.665,
                103.68,
                103.697,
                103.714,
                103.729,
                103.746,
                103.762,
                103.778,
                103.795,
                103.811,
                103.827,
                103.843,
                103.86,
                103.876,
                103.892,
                103.909,
                103.924,
                103.941,
                103.958,
                103.973,
                103.99,
                104.005,
                104.022,
                104.039,
                104.054,
                104.071,
                104.086,
                104.103,
                104.12,
                104.135,
                104.152,
                104.167,
                104.184,
                104.2,
                104.216,
                104.232,
                104.248,
                104.264,
                104.281,
                104.296,
                104.313,
                104.328,
                104.345,
                104.362,
                104.377,
                104.393,
                104.409,
                104.425,
                104.442,
                104.457,
                104.474,
                104.49,
                104.506,
                104.522,
                104.538,
                104.554,
                104.571,
                104.586,
                104.602,
                104.618,
                104.634,
                104.651,
                104.666,
                104.682,
                104.698,
                104.714,
                104.731,
                104.746,
                104.762,
                104.778,
                104.794,
                104.81,
                104.826,
                104.842,
                104.857,
                104.874,
                104.89,
                104.905,
                104.922,
                104.937,
                104.953,
                104.97
            ],
            P25: [
                47.891,
                48.058,
                48.225,
                48.392,
                48.559,
                48.726,
                48.893,
                49.06,
                49.227,
                49.394,
                49.56,
                49.727,
                49.894,
                50.061,
                50.229,
                50.365,
                50.502,
                50.637,
                50.772,
                50.906,
                51.039,
                51.171,
                51.302,
                51.432,
                51.561,
                51.689,
                51.817,
                51.943,
                52.068,
                52.192,
                52.315,
                52.438,
                52.559,
                52.68,
                52.8,
                52.918,
                53.036,
                53.153,
                53.269,
                53.384,
                53.498,
                53.611,
                53.724,
                53.835,
                53.946,
                54.056,
                54.165,
                54.274,
                54.381,
                54.487,
                54.593,
                54.698,
                54.803,
                54.906,
                55.008,
                55.11,
                55.211,
                55.312,
                55.411,
                55.51,
                55.608,
                55.706,
                55.803,
                55.899,
                55.995,
                56.09,
                56.184,
                56.278,
                56.371,
                56.464,
                56.555,
                56.647,
                56.738,
                56.828,
                56.917,
                57.007,
                57.095,
                57.183,
                57.27,
                57.357,
                57.443,
                57.529,
                57.614,
                57.699,
                57.783,
                57.867,
                57.95,
                58.032,
                58.115,
                58.196,
                58.278,
                58.358,
                58.438,
                58.518,
                58.598,
                58.676,
                58.755,
                58.833,
                58.91,
                58.987,
                59.064,
                59.14,
                59.216,
                59.292,
                59.367,
                59.441,
                59.515,
                59.589,
                59.662,
                59.735,
                59.808,
                59.88,
                59.952,
                60.023,
                60.094,
                60.164,
                60.234,
                60.304,
                60.374,
                60.443,
                60.511,
                60.579,
                60.647,
                60.714,
                60.781,
                60.848,
                60.914,
                60.981,
                61.046,
                61.112,
                61.176,
                61.241,
                61.305,
                61.369,
                61.433,
                61.495,
                61.558,
                61.621,
                61.683,
                61.745,
                61.807,
                61.868,
                61.929,
                61.99,
                62.05,
                62.111,
                62.17,
                62.23,
                62.289,
                62.348,
                62.407,
                62.466,
                62.524,
                62.582,
                62.64,
                62.697,
                62.754,
                62.811,
                62.868,
                62.924,
                62.981,
                63.037,
                63.093,
                63.149,
                63.204,
                63.259,
                63.314,
                63.369,
                63.424,
                63.478,
                63.532,
                63.586,
                63.641,
                63.694,
                63.748,
                63.801,
                63.854,
                63.907,
                63.96,
                64.013,
                64.065,
                64.117,
                64.17,
                64.222,
                64.274,
                64.326,
                64.377,
                64.429,
                64.48,
                64.531,
                64.582,
                64.633,
                64.684,
                64.735,
                64.785,
                64.836,
                64.886,
                64.936,
                64.986,
                65.036,
                65.086,
                65.135,
                65.185,
                65.235,
                65.284,
                65.333,
                65.382,
                65.431,
                65.48,
                65.529,
                65.577,
                65.626,
                65.674,
                65.723,
                65.771,
                65.819,
                65.867,
                65.915,
                65.963,
                66.011,
                66.059,
                66.106,
                66.154,
                66.201,
                66.248,
                66.296,
                66.343,
                66.39,
                66.437,
                66.484,
                66.53,
                66.577,
                66.624,
                66.67,
                66.717,
                66.763,
                66.81,
                66.856,
                66.902,
                66.948,
                66.994,
                67.04,
                67.086,
                67.132,
                67.177,
                67.223,
                67.269,
                67.314,
                67.36,
                67.405,
                67.451,
                67.495,
                67.54,
                67.586,
                67.631,
                67.676,
                67.721,
                67.765,
                67.81,
                67.855,
                67.899,
                67.944,
                67.988,
                68.033,
                68.077,
                68.121,
                68.165,
                68.209,
                68.254,
                68.298,
                68.342,
                68.385,
                68.429,
                68.473,
                68.517,
                68.561,
                68.604,
                68.647,
                68.691,
                68.735,
                68.778,
                68.821,
                68.864,
                68.908,
                68.951,
                68.994,
                69.037,
                69.08,
                69.122,
                69.165,
                69.208,
                69.251,
                69.294,
                69.336,
                69.379,
                69.421,
                69.464,
                69.506,
                69.549,
                69.591,
                69.633,
                69.675,
                69.717,
                69.759,
                69.802,
                69.843,
                69.885,
                69.927,
                69.969,
                70.011,
                70.053,
                70.094,
                70.136,
                70.177,
                70.219,
                70.26,
                70.301,
                70.343,
                70.384,
                70.425,
                70.467,
                70.508,
                70.549,
                70.59,
                70.631,
                70.672,
                70.712,
                70.753,
                70.794,
                70.835,
                70.876,
                70.916,
                70.957,
                70.997,
                71.038,
                71.078,
                71.118,
                71.159,
                71.199,
                71.239,
                71.279,
                71.32,
                71.359,
                71.4,
                71.439,
                71.48,
                71.52,
                71.559,
                71.599,
                71.639,
                71.679,
                71.718,
                71.758,
                71.797,
                71.837,
                71.876,
                71.916,
                71.955,
                71.995,
                72.034,
                72.073,
                72.112,
                72.151,
                72.19,
                72.23,
                72.268,
                72.308,
                72.346,
                72.385,
                72.424,
                72.463,
                72.501,
                72.54,
                72.579,
                72.617,
                72.656,
                72.694,
                72.733,
                72.771,
                72.81,
                72.848,
                72.886,
                72.925,
                72.963,
                73.001,
                73.039,
                73.077,
                73.115,
                73.154,
                73.191,
                73.229,
                73.267,
                73.305,
                73.343,
                73.38,
                73.418,
                73.456,
                73.493,
                73.531,
                73.568,
                73.606,
                73.643,
                73.681,
                73.718,
                73.755,
                73.793,
                73.83,
                73.867,
                73.905,
                73.941,
                73.979,
                74.016,
                74.053,
                74.09,
                74.127,
                74.164,
                74.2,
                74.237,
                74.274,
                74.311,
                74.347,
                74.384,
                74.42,
                74.457,
                74.494,
                74.53,
                74.567,
                74.603,
                74.639,
                74.676,
                74.712,
                74.748,
                74.784,
                74.82,
                74.857,
                74.893,
                74.929,
                74.965,
                75.001,
                75.037,
                75.073,
                75.109,
                75.144,
                75.18,
                75.216,
                75.251,
                75.288,
                75.323,
                75.358,
                75.394,
                75.43,
                75.466,
                75.501,
                75.536,
                75.572,
                75.607,
                75.642,
                75.678,
                75.713,
                75.748,
                75.784,
                75.819,
                75.854,
                75.889,
                75.924,
                75.959,
                75.994,
                76.029,
                76.064,
                76.098,
                76.133,
                76.168,
                76.203,
                76.238,
                76.272,
                76.307,
                76.342,
                76.376,
                76.411,
                76.446,
                76.48,
                76.514,
                76.549,
                76.583,
                76.618,
                76.652,
                76.687,
                76.721,
                76.755,
                76.789,
                76.823,
                76.857,
                76.892,
                76.926,
                76.96,
                76.994,
                77.028,
                77.062,
                77.096,
                77.13,
                77.164,
                77.197,
                77.231,
                77.265,
                77.299,
                77.333,
                77.366,
                77.4,
                77.434,
                77.467,
                77.501,
                77.534,
                77.568,
                77.601,
                77.634,
                77.668,
                77.701,
                77.735,
                77.768,
                77.801,
                77.835,
                77.868,
                77.901,
                77.934,
                77.967,
                78,
                78.034,
                78.066,
                78.099,
                78.133,
                78.165,
                78.198,
                78.231,
                78.264,
                78.297,
                78.33,
                78.363,
                78.395,
                78.428,
                78.46,
                78.493,
                78.526,
                78.558,
                78.591,
                78.624,
                78.656,
                78.688,
                78.721,
                78.753,
                78.786,
                78.818,
                78.851,
                78.883,
                78.915,
                78.948,
                78.98,
                79.012,
                79.044,
                79.076,
                79.108,
                79.14,
                79.172,
                79.204,
                79.236,
                79.268,
                79.3,
                79.332,
                79.364,
                79.396,
                79.428,
                79.459,
                79.491,
                79.523,
                79.555,
                79.586,
                79.618,
                79.649,
                79.682,
                79.713,
                79.744,
                79.776,
                79.808,
                79.839,
                79.87,
                79.902,
                79.933,
                79.965,
                79.996,
                80.028,
                80.059,
                80.09,
                80.121,
                80.153,
                80.184,
                80.215,
                80.246,
                80.277,
                80.308,
                80.339,
                80.37,
                80.401,
                80.432,
                80.463,
                80.494,
                80.525,
                80.556,
                80.587,
                80.617,
                80.649,
                80.679,
                80.71,
                80.74,
                80.772,
                80.802,
                80.833,
                80.863,
                80.894,
                80.925,
                80.955,
                80.986,
                81.016,
                81.047,
                81.077,
                81.108,
                81.138,
                81.168,
                81.199,
                81.229,
                81.26,
                81.29,
                81.32,
                81.35,
                81.38,
                81.411,
                81.441,
                81.471,
                81.501,
                81.531,
                81.561,
                81.591,
                81.621,
                81.651,
                81.681,
                81.711,
                81.741,
                81.771,
                81.801,
                81.831,
                81.861,
                81.89,
                81.92,
                81.95,
                81.98,
                82.009,
                82.039,
                82.069,
                82.098,
                82.128,
                82.157,
                82.187,
                82.216,
                82.246,
                82.275,
                82.305,
                82.334,
                82.363,
                82.393,
                82.422,
                82.452,
                82.481,
                82.51,
                82.54,
                82.569,
                82.598,
                82.627,
                82.657,
                82.685,
                82.714,
                82.743,
                82.773,
                82.802,
                82.831,
                82.859,
                82.889,
                82.918,
                82.946,
                82.975,
                83.005,
                83.033,
                83.062,
                83.091,
                83.12,
                83.148,
                83.177,
                83.206,
                83.235,
                83.263,
                83.292,
                83.321,
                83.349,
                83.378,
                83.406,
                83.435,
                83.464,
                83.492,
                83.52,
                83.549,
                83.577,
                83.606,
                83.634,
                83.663,
                83.691,
                83.719,
                83.747,
                83.776,
                83.804,
                83.832,
                83.86,
                83.889,
                83.917,
                83.945,
                83.973,
                84.001,
                84.029,
                84.057,
                84.086,
                84.114,
                84.141,
                84.169,
                84.198,
                84.225,
                83.553,
                83.581,
                83.609,
                83.637,
                83.665,
                83.692,
                83.72,
                83.748,
                83.776,
                83.803,
                83.831,
                83.859,
                83.887,
                83.914,
                83.942,
                83.97,
                83.997,
                84.024,
                84.052,
                84.08,
                84.107,
                84.134,
                84.162,
                84.189,
                84.217,
                84.244,
                84.272,
                84.299,
                84.326,
                84.353,
                84.381,
                84.408,
                84.435,
                84.463,
                84.49,
                84.517,
                84.544,
                84.571,
                84.598,
                84.625,
                84.652,
                84.68,
                84.707,
                84.734,
                84.761,
                84.788,
                84.815,
                84.841,
                84.869,
                84.895,
                84.922,
                84.95,
                84.976,
                85.003,
                85.029,
                85.057,
                85.083,
                85.11,
                85.137,
                85.164,
                85.19,
                85.217,
                85.244,
                85.27,
                85.297,
                85.323,
                85.35,
                85.376,
                85.403,
                85.43,
                85.456,
                85.482,
                85.508,
                85.535,
                85.562,
                85.588,
                85.615,
                85.641,
                85.667,
                85.694,
                85.72,
                85.746,
                85.772,
                85.798,
                85.825,
                85.851,
                85.877,
                85.903,
                85.929,
                85.955,
                85.981,
                86.007,
                86.033,
                86.06,
                86.085,
                86.111,
                86.137,
                86.163,
                86.189,
                86.215,
                86.241,
                86.267,
                86.292,
                86.319,
                86.344,
                86.37,
                86.395,
                86.422,
                86.447,
                86.473,
                86.499,
                86.524,
                86.55,
                86.576,
                86.601,
                86.626,
                86.652,
                86.678,
                86.703,
                86.728,
                86.754,
                86.78,
                86.805,
                86.831,
                86.856,
                86.881,
                86.906,
                86.932,
                86.957,
                86.982,
                87.008,
                87.033,
                87.058,
                87.084,
                87.109,
                87.134,
                87.159,
                87.184,
                87.209,
                87.234,
                87.26,
                87.284,
                87.309,
                87.335,
                87.36,
                87.384,
                87.41,
                87.434,
                87.459,
                87.485,
                87.509,
                87.534,
                87.559,
                87.584,
                87.608,
                87.634,
                87.658,
                87.683,
                87.707,
                87.732,
                87.757,
                87.781,
                87.807,
                87.831,
                87.855,
                87.88,
                87.905,
                87.929,
                87.954,
                87.979,
                88.003,
                88.028,
                88.052,
                88.076,
                88.101,
                88.125,
                88.149,
                88.174,
                88.199,
                88.223,
                88.247,
                88.272,
                88.296,
                88.32,
                88.344,
                88.368,
                88.393,
                88.417,
                88.441,
                88.466,
                88.489,
                88.513,
                88.538,
                88.562,
                88.586,
                88.61,
                88.634,
                88.658,
                88.682,
                88.706,
                88.73,
                88.754,
                88.778,
                88.802,
                88.826,
                88.85,
                88.873,
                88.897,
                88.921,
                88.945,
                88.969,
                88.993,
                89.016,
                89.04,
                89.064,
                89.087,
                89.111,
                89.135,
                89.158,
                89.182,
                89.206,
                89.23,
                89.253,
                89.277,
                89.3,
                89.324,
                89.347,
                89.371,
                89.394,
                89.418,
                89.441,
                89.465,
                89.488,
                89.512,
                89.535,
                89.558,
                89.582,
                89.605,
                89.629,
                89.652,
                89.675,
                89.699,
                89.722,
                89.745,
                89.769,
                89.792,
                89.815,
                89.838,
                89.861,
                89.885,
                89.908,
                89.931,
                89.954,
                89.977,
                90,
                90.024,
                90.046,
                90.069,
                90.093,
                90.116,
                90.139,
                90.162,
                90.185,
                90.208,
                90.231,
                90.254,
                90.277,
                90.3,
                90.323,
                90.346,
                90.368,
                90.392,
                90.414,
                90.437,
                90.46,
                90.483,
                90.506,
                90.528,
                90.551,
                90.574,
                90.597,
                90.619,
                90.642,
                90.665,
                90.688,
                90.71,
                90.733,
                90.756,
                90.778,
                90.801,
                90.824,
                90.846,
                90.869,
                90.891,
                90.914,
                90.937,
                90.959,
                90.982,
                91.004,
                91.027,
                91.05,
                91.072,
                91.095,
                91.117,
                91.139,
                91.162,
                91.184,
                91.207,
                91.229,
                91.251,
                91.274,
                91.296,
                91.319,
                91.341,
                91.363,
                91.386,
                91.408,
                91.431,
                91.453,
                91.475,
                91.497,
                91.519,
                91.542,
                91.564,
                91.586,
                91.609,
                91.631,
                91.653,
                91.675,
                91.697,
                91.719,
                91.741,
                91.764,
                91.786,
                91.808,
                91.83,
                91.852,
                91.874,
                91.896,
                91.919,
                91.94,
                91.962,
                91.985,
                92.006,
                92.029,
                92.051,
                92.072,
                92.095,
                92.116,
                92.139,
                92.16,
                92.182,
                92.204,
                92.226,
                92.248,
                92.27,
                92.292,
                92.314,
                92.335,
                92.357,
                92.379,
                92.401,
                92.423,
                92.445,
                92.467,
                92.488,
                92.51,
                92.532,
                92.554,
                92.575,
                92.597,
                92.619,
                92.64,
                92.662,
                92.684,
                92.706,
                92.727,
                92.749,
                92.771,
                92.792,
                92.814,
                92.836,
                92.857,
                92.879,
                92.901,
                92.922,
                92.943,
                92.965,
                92.987,
                93.009,
                93.03,
                93.052,
                93.073,
                93.094,
                93.116,
                93.137,
                93.159,
                93.18,
                93.202,
                93.223,
                93.245,
                93.266,
                93.287,
                93.309,
                93.33,
                93.352,
                93.373,
                93.395,
                93.416,
                93.437,
                93.459,
                93.48,
                93.501,
                93.522,
                93.544,
                93.565,
                93.587,
                93.608,
                93.629,
                93.65,
                93.671,
                93.693,
                93.714,
                93.735,
                93.756,
                93.778,
                93.799,
                93.82,
                93.841,
                93.862,
                93.884,
                93.905,
                93.926,
                93.947,
                93.968,
                93.989,
                94.01,
                94.031,
                94.053,
                94.073,
                94.094,
                94.116,
                94.136,
                94.158,
                94.179,
                94.2,
                94.221,
                94.242,
                94.263,
                94.284,
                94.305,
                94.326,
                94.347,
                94.367,
                94.389,
                94.409,
                94.43,
                94.451,
                94.472,
                94.493,
                94.514,
                94.535,
                94.556,
                94.576,
                94.598,
                94.618,
                94.639,
                94.66,
                94.68,
                94.701,
                94.722,
                94.743,
                94.763,
                94.785,
                94.805,
                94.826,
                94.846,
                94.868,
                94.888,
                94.909,
                94.929,
                94.95,
                94.971,
                94.991,
                95.012,
                95.033,
                95.053,
                95.074,
                95.095,
                95.115,
                95.136,
                95.156,
                95.177,
                95.197,
                95.218,
                95.239,
                95.26,
                95.28,
                95.301,
                95.321,
                95.342,
                95.362,
                95.383,
                95.403,
                95.424,
                95.444,
                95.464,
                95.485,
                95.505,
                95.526,
                95.546,
                95.566,
                95.587,
                95.607,
                95.627,
                95.648,
                95.668,
                95.689,
                95.709,
                95.73,
                95.75,
                95.77,
                95.79,
                95.811,
                95.831,
                95.851,
                95.871,
                95.892,
                95.912,
                95.933,
                95.952,
                95.973,
                95.993,
                96.013,
                96.033,
                96.054,
                96.074,
                96.094,
                96.114,
                96.135,
                96.154,
                96.175,
                96.195,
                96.214,
                96.235,
                96.255,
                96.275,
                96.295,
                96.315,
                96.335,
                96.355,
                96.375,
                96.395,
                96.415,
                96.436,
                96.455,
                96.475,
                96.495,
                96.515,
                96.535,
                96.555,
                96.575,
                96.595,
                96.615,
                96.635,
                96.655,
                96.675,
                96.695,
                96.715,
                96.734,
                96.755,
                96.774,
                96.794,
                96.814,
                96.834,
                96.854,
                96.874,
                96.893,
                96.913,
                96.933,
                96.953,
                96.972,
                96.992,
                97.012,
                97.032,
                97.051,
                97.071,
                97.091,
                97.111,
                97.13,
                97.15,
                97.17,
                97.19,
                97.21,
                97.229,
                97.249,
                97.268,
                97.288,
                97.308,
                97.328,
                97.347,
                97.367,
                97.386,
                97.406,
                97.425,
                97.445,
                97.464,
                97.484,
                97.503,
                97.523,
                97.542,
                97.562,
                97.581,
                97.601,
                97.62,
                97.64,
                97.659,
                97.679,
                97.698,
                97.718,
                97.737,
                97.757,
                97.776,
                97.796,
                97.815,
                97.834,
                97.853,
                97.873,
                97.892,
                97.912,
                97.931,
                97.951,
                97.97,
                97.989,
                98.009,
                98.028,
                98.047,
                98.066,
                98.086,
                98.105,
                98.124,
                98.143,
                98.163,
                98.182,
                98.201,
                98.22,
                98.24,
                98.259,
                98.278,
                98.297,
                98.317,
                98.335,
                98.355,
                98.374,
                98.393,
                98.412,
                98.431,
                98.451,
                98.47,
                98.489,
                98.508,
                98.527,
                98.546,
                98.565,
                98.584,
                98.604,
                98.622,
                98.642,
                98.66,
                98.68,
                98.698,
                98.718,
                98.736,
                98.756,
                98.774,
                98.794,
                98.813,
                98.831,
                98.851,
                98.869,
                98.889,
                98.907,
                98.926,
                98.945,
                98.964,
                98.983,
                99.002,
                99.021,
                99.04,
                99.058,
                99.077,
                99.096,
                99.115,
                99.134,
                99.153,
                99.172,
                99.19,
                99.209,
                99.228,
                99.247,
                99.265,
                99.285,
                99.303,
                99.322,
                99.34,
                99.36,
                99.379,
                99.397,
                99.416,
                99.434,
                99.453,
                99.472,
                99.491,
                99.509,
                99.528,
                99.546,
                99.565,
                99.584,
                99.603,
                99.622,
                99.64,
                99.659,
                99.677,
                99.696,
                99.714,
                99.733,
                99.752,
                99.77,
                99.789,
                99.808,
                99.826,
                99.845,
                99.864,
                99.882,
                99.901,
                99.919,
                99.938,
                99.956,
                99.975,
                99.993,
                100.012,
                100.03,
                100.049,
                100.067,
                100.085,
                100.104,
                100.122,
                100.141,
                100.159,
                100.178,
                100.196,
                100.215,
                100.233,
                100.252,
                100.27,
                100.288,
                100.307,
                100.325,
                100.344,
                100.362,
                100.38,
                100.399,
                100.417,
                100.435,
                100.454,
                100.472,
                100.49,
                100.509,
                100.527,
                100.546,
                100.564,
                100.582,
                100.6,
                100.619,
                100.637,
                100.655,
                100.674,
                100.692,
                100.71,
                100.728,
                100.747,
                100.765,
                100.783,
                100.802,
                100.82,
                100.838,
                100.856,
                100.874,
                100.892,
                100.911,
                100.928,
                100.947,
                100.965,
                100.983,
                101.002,
                101.02,
                101.038,
                101.056,
                101.074,
                101.092,
                101.11,
                101.129,
                101.147,
                101.165,
                101.183,
                101.201,
                101.219,
                101.237,
                101.256,
                101.273,
                101.292,
                101.309,
                101.328,
                101.345,
                101.364,
                101.381,
                101.4,
                101.418,
                101.436,
                101.454,
                101.472,
                101.49,
                101.507,
                101.526,
                101.544,
                101.562,
                101.58,
                101.597,
                101.616,
                101.633,
                101.652,
                101.669,
                101.687,
                101.706,
                101.723,
                101.741,
                101.759,
                101.777,
                101.795,
                101.813,
                101.831,
                101.848,
                101.867,
                101.884,
                101.902,
                101.92,
                101.938,
                101.956,
                101.974,
                101.992,
                102.009,
                102.027,
                102.045,
                102.063,
                102.081,
                102.098,
                102.117,
                102.134,
                102.152,
                102.169,
                102.187,
                102.206,
                102.223,
                102.241,
                102.258,
                102.276,
                102.294,
                102.312,
                102.33,
                102.347,
                102.365,
                102.382,
                102.401,
                102.418,
                102.436,
                102.454,
                102.471,
                102.489,
                102.506,
                102.524,
                102.542,
                102.56,
                102.578,
                102.595,
                102.613,
                102.63,
                102.648,
                102.665,
                102.683,
                102.701,
                102.718,
                102.736,
                102.753,
                102.771,
                102.789,
                102.806,
                102.824,
                102.841,
                102.859,
                102.876,
                102.894,
                102.912,
                102.929,
                102.947,
                102.964,
                102.982,
                102.999,
                103.017,
                103.035,
                103.052,
                103.07,
                103.087,
                103.104,
                103.122,
                103.139,
                103.157,
                103.174,
                103.192,
                103.209,
                103.227,
                103.244,
                103.261,
                103.279,
                103.296,
                103.314,
                103.332,
                103.349,
                103.366,
                103.383,
                103.401,
                103.418,
                103.436,
                103.453,
                103.47,
                103.488,
                103.505,
                103.522,
                103.54,
                103.557,
                103.575,
                103.592,
                103.609,
                103.626,
                103.644,
                103.661,
                103.678,
                103.696,
                103.713,
                103.73,
                103.748,
                103.765,
                103.782,
                103.799,
                103.817,
                103.834,
                103.851,
                103.869,
                103.885,
                103.903,
                103.92,
                103.937,
                103.955,
                103.972,
                103.989,
                104.006,
                104.023,
                104.041,
                104.058,
                104.075,
                104.092,
                104.109,
                104.127,
                104.143,
                104.161,
                104.178,
                104.195,
                104.212,
                104.229,
                104.247,
                104.263,
                104.281,
                104.297,
                104.315,
                104.332,
                104.349,
                104.366,
                104.383,
                104.4,
                104.417,
                104.434,
                104.451,
                104.468,
                104.485,
                104.503,
                104.519,
                104.537,
                104.553,
                104.571,
                104.587,
                104.604,
                104.622,
                104.638,
                104.656,
                104.672,
                104.689,
                104.707,
                104.723,
                104.74,
                104.757,
                104.774,
                104.791,
                104.808,
                104.825,
                104.842,
                104.859,
                104.876,
                104.892,
                104.91,
                104.926,
                104.943,
                104.96,
                104.977,
                104.994,
                105.011,
                105.028,
                105.045,
                105.061,
                105.078,
                105.095,
                105.112,
                105.128,
                105.145,
                105.163,
                105.179,
                105.196,
                105.212,
                105.229,
                105.247,
                105.263,
                105.28,
                105.296,
                105.313,
                105.33,
                105.347,
                105.364,
                105.38,
                105.397,
                105.414,
                105.431,
                105.448,
                105.464,
                105.481,
                105.498,
                105.514,
                105.531,
                105.547,
                105.564,
                105.581,
                105.597,
                105.614,
                105.631,
                105.648,
                105.665,
                105.681,
                105.698,
                105.714,
                105.731,
                105.748,
                105.764,
                105.781,
                105.797,
                105.814,
                105.831,
                105.847,
                105.864,
                105.88,
                105.897,
                105.914,
                105.93,
                105.947,
                105.963,
                105.98,
                105.997,
                106.013,
                106.03,
                106.046,
                106.063,
                106.079,
                106.095,
                106.112,
                106.128,
                106.145,
                106.162,
                106.178,
                106.195,
                106.211,
                106.228,
                106.244,
                106.26,
                106.277,
                106.294,
                106.31,
                106.327,
                106.343,
                106.359,
                106.376,
                106.392,
                106.409,
                106.425,
                106.441,
                106.458,
                106.474,
                106.491,
                106.507,
                106.523,
                106.54,
                106.556,
                106.573,
                106.589,
                106.605,
                106.622,
                106.638,
                106.654,
                106.67,
                106.687,
                106.704
            ],
            P50: [
                49.148,
                49.317,
                49.485,
                49.654,
                49.823,
                49.992,
                50.161,
                50.33,
                50.499,
                50.668,
                50.837,
                51.005,
                51.174,
                51.343,
                51.512,
                51.651,
                51.79,
                51.927,
                52.064,
                52.2,
                52.335,
                52.47,
                52.603,
                52.735,
                52.866,
                52.996,
                53.126,
                53.254,
                53.381,
                53.507,
                53.633,
                53.757,
                53.881,
                54.003,
                54.125,
                54.245,
                54.365,
                54.484,
                54.602,
                54.719,
                54.835,
                54.95,
                55.064,
                55.178,
                55.29,
                55.402,
                55.513,
                55.623,
                55.732,
                55.841,
                55.948,
                56.055,
                56.161,
                56.266,
                56.37,
                56.474,
                56.577,
                56.679,
                56.78,
                56.881,
                56.981,
                57.08,
                57.178,
                57.276,
                57.373,
                57.47,
                57.566,
                57.661,
                57.756,
                57.85,
                57.944,
                58.037,
                58.129,
                58.221,
                58.312,
                58.402,
                58.492,
                58.582,
                58.671,
                58.759,
                58.847,
                58.934,
                59.02,
                59.107,
                59.192,
                59.277,
                59.362,
                59.446,
                59.53,
                59.613,
                59.695,
                59.777,
                59.859,
                59.94,
                60.021,
                60.101,
                60.181,
                60.26,
                60.339,
                60.418,
                60.496,
                60.573,
                60.651,
                60.727,
                60.804,
                60.88,
                60.955,
                61.03,
                61.105,
                61.179,
                61.253,
                61.326,
                61.399,
                61.472,
                61.544,
                61.616,
                61.687,
                61.758,
                61.829,
                61.899,
                61.969,
                62.038,
                62.107,
                62.176,
                62.244,
                62.312,
                62.38,
                62.447,
                62.514,
                62.58,
                62.646,
                62.712,
                62.777,
                62.842,
                62.907,
                62.971,
                63.035,
                63.099,
                63.163,
                63.226,
                63.288,
                63.351,
                63.413,
                63.475,
                63.537,
                63.598,
                63.659,
                63.72,
                63.78,
                63.84,
                63.9,
                63.96,
                64.019,
                64.078,
                64.137,
                64.196,
                64.254,
                64.312,
                64.37,
                64.428,
                64.485,
                64.542,
                64.599,
                64.656,
                64.712,
                64.769,
                64.825,
                64.881,
                64.937,
                64.992,
                65.047,
                65.103,
                65.158,
                65.212,
                65.267,
                65.321,
                65.376,
                65.43,
                65.484,
                65.538,
                65.591,
                65.645,
                65.698,
                65.751,
                65.804,
                65.857,
                65.91,
                65.962,
                66.015,
                66.067,
                66.119,
                66.171,
                66.223,
                66.275,
                66.326,
                66.378,
                66.429,
                66.48,
                66.531,
                66.582,
                66.633,
                66.684,
                66.735,
                66.785,
                66.835,
                66.886,
                66.936,
                66.986,
                67.036,
                67.086,
                67.136,
                67.185,
                67.235,
                67.284,
                67.334,
                67.383,
                67.432,
                67.481,
                67.53,
                67.579,
                67.628,
                67.676,
                67.725,
                67.774,
                67.822,
                67.87,
                67.919,
                67.967,
                68.015,
                68.063,
                68.111,
                68.159,
                68.206,
                68.254,
                68.302,
                68.349,
                68.397,
                68.444,
                68.491,
                68.538,
                68.586,
                68.633,
                68.68,
                68.726,
                68.773,
                68.82,
                68.867,
                68.913,
                68.96,
                69.006,
                69.053,
                69.099,
                69.145,
                69.191,
                69.238,
                69.284,
                69.33,
                69.376,
                69.421,
                69.467,
                69.513,
                69.559,
                69.604,
                69.65,
                69.695,
                69.741,
                69.786,
                69.831,
                69.876,
                69.922,
                69.967,
                70.012,
                70.057,
                70.102,
                70.146,
                70.191,
                70.236,
                70.281,
                70.325,
                70.37,
                70.414,
                70.459,
                70.503,
                70.547,
                70.592,
                70.636,
                70.68,
                70.724,
                70.768,
                70.812,
                70.856,
                70.9,
                70.944,
                70.988,
                71.031,
                71.075,
                71.119,
                71.162,
                71.206,
                71.249,
                71.293,
                71.336,
                71.379,
                71.422,
                71.466,
                71.509,
                71.552,
                71.595,
                71.638,
                71.681,
                71.724,
                71.766,
                71.809,
                71.852,
                71.895,
                71.937,
                71.98,
                72.022,
                72.065,
                72.107,
                72.15,
                72.192,
                72.234,
                72.276,
                72.318,
                72.361,
                72.403,
                72.445,
                72.487,
                72.528,
                72.57,
                72.612,
                72.654,
                72.696,
                72.737,
                72.779,
                72.82,
                72.862,
                72.903,
                72.945,
                72.986,
                73.027,
                73.069,
                73.11,
                73.151,
                73.192,
                73.233,
                73.274,
                73.315,
                73.356,
                73.397,
                73.438,
                73.479,
                73.52,
                73.56,
                73.601,
                73.641,
                73.682,
                73.723,
                73.763,
                73.803,
                73.844,
                73.884,
                73.925,
                73.965,
                74.005,
                74.045,
                74.085,
                74.125,
                74.165,
                74.205,
                74.245,
                74.285,
                74.325,
                74.365,
                74.405,
                74.444,
                74.484,
                74.524,
                74.563,
                74.603,
                74.642,
                74.682,
                74.721,
                74.761,
                74.8,
                74.839,
                74.878,
                74.918,
                74.957,
                74.996,
                75.035,
                75.074,
                75.113,
                75.152,
                75.191,
                75.23,
                75.269,
                75.307,
                75.346,
                75.385,
                75.424,
                75.462,
                75.501,
                75.539,
                75.578,
                75.616,
                75.655,
                75.693,
                75.731,
                75.77,
                75.808,
                75.846,
                75.884,
                75.922,
                75.961,
                75.999,
                76.037,
                76.075,
                76.113,
                76.15,
                76.188,
                76.226,
                76.264,
                76.302,
                76.339,
                76.377,
                76.415,
                76.452,
                76.49,
                76.527,
                76.565,
                76.602,
                76.64,
                76.677,
                76.714,
                76.752,
                76.789,
                76.826,
                76.863,
                76.9,
                76.937,
                76.974,
                77.011,
                77.048,
                77.085,
                77.122,
                77.159,
                77.196,
                77.233,
                77.27,
                77.306,
                77.343,
                77.38,
                77.416,
                77.453,
                77.489,
                77.526,
                77.562,
                77.599,
                77.635,
                77.672,
                77.708,
                77.744,
                77.781,
                77.817,
                77.853,
                77.889,
                77.925,
                77.961,
                77.997,
                78.033,
                78.069,
                78.105,
                78.141,
                78.177,
                78.213,
                78.249,
                78.285,
                78.321,
                78.356,
                78.392,
                78.428,
                78.463,
                78.499,
                78.535,
                78.57,
                78.606,
                78.641,
                78.676,
                78.712,
                78.747,
                78.783,
                78.818,
                78.853,
                78.888,
                78.924,
                78.959,
                78.994,
                79.029,
                79.064,
                79.099,
                79.134,
                79.169,
                79.204,
                79.239,
                79.274,
                79.309,
                79.344,
                79.379,
                79.413,
                79.448,
                79.483,
                79.518,
                79.552,
                79.587,
                79.621,
                79.656,
                79.69,
                79.725,
                79.759,
                79.794,
                79.828,
                79.863,
                79.897,
                79.931,
                79.966,
                80,
                80.034,
                80.068,
                80.102,
                80.137,
                80.171,
                80.205,
                80.239,
                80.273,
                80.307,
                80.341,
                80.375,
                80.408,
                80.442,
                80.476,
                80.51,
                80.544,
                80.577,
                80.611,
                80.645,
                80.679,
                80.712,
                80.746,
                80.779,
                80.813,
                80.846,
                80.88,
                80.913,
                80.947,
                80.98,
                81.014,
                81.047,
                81.08,
                81.113,
                81.147,
                81.18,
                81.213,
                81.246,
                81.28,
                81.313,
                81.346,
                81.379,
                81.412,
                81.445,
                81.478,
                81.511,
                81.544,
                81.577,
                81.61,
                81.642,
                81.675,
                81.708,
                81.741,
                81.774,
                81.806,
                81.839,
                81.872,
                81.904,
                81.937,
                81.969,
                82.002,
                82.035,
                82.067,
                82.099,
                82.132,
                82.164,
                82.197,
                82.229,
                82.261,
                82.294,
                82.326,
                82.358,
                82.391,
                82.423,
                82.455,
                82.487,
                82.519,
                82.551,
                82.584,
                82.616,
                82.648,
                82.68,
                82.712,
                82.744,
                82.776,
                82.807,
                82.839,
                82.871,
                82.903,
                82.935,
                82.967,
                82.998,
                83.03,
                83.062,
                83.094,
                83.125,
                83.157,
                83.188,
                83.22,
                83.252,
                83.283,
                83.315,
                83.346,
                83.378,
                83.409,
                83.44,
                83.472,
                83.503,
                83.534,
                83.566,
                83.597,
                83.628,
                83.66,
                83.691,
                83.722,
                83.753,
                83.784,
                83.815,
                83.846,
                83.877,
                83.909,
                83.94,
                83.971,
                84.001,
                84.032,
                84.063,
                84.094,
                84.125,
                84.156,
                84.187,
                84.217,
                84.248,
                84.279,
                84.31,
                84.34,
                84.371,
                84.402,
                84.432,
                84.463,
                84.493,
                84.524,
                84.555,
                84.585,
                84.615,
                84.646,
                84.676,
                84.707,
                84.737,
                84.767,
                84.798,
                84.828,
                84.858,
                84.889,
                84.919,
                84.949,
                84.979,
                85.009,
                85.039,
                85.07,
                85.1,
                85.13,
                85.16,
                85.19,
                85.22,
                85.25,
                85.28,
                85.31,
                85.34,
                85.369,
                85.399,
                85.429,
                85.459,
                85.489,
                85.518,
                85.548,
                85.578,
                85.608,
                85.637,
                85.667,
                85.696,
                85.726,
                85.756,
                85.785,
                85.815,
                85.844,
                85.874,
                85.903,
                85.933,
                85.962,
                85.992,
                86.021,
                86.05,
                86.08,
                86.109,
                86.138,
                86.167,
                86.197,
                86.226,
                86.255,
                86.284,
                86.313,
                86.343,
                86.372,
                86.401,
                85.73,
                85.759,
                85.788,
                85.817,
                85.846,
                85.875,
                85.904,
                85.933,
                85.962,
                85.991,
                86.019,
                86.048,
                86.077,
                86.106,
                86.135,
                86.163,
                86.192,
                86.221,
                86.25,
                86.278,
                86.307,
                86.336,
                86.364,
                86.393,
                86.421,
                86.45,
                86.478,
                86.507,
                86.535,
                86.564,
                86.592,
                86.621,
                86.649,
                86.677,
                86.706,
                86.734,
                86.762,
                86.791,
                86.819,
                86.847,
                86.875,
                86.904,
                86.932,
                86.96,
                86.988,
                87.016,
                87.044,
                87.073,
                87.101,
                87.129,
                87.157,
                87.185,
                87.213,
                87.241,
                87.269,
                87.297,
                87.324,
                87.352,
                87.38,
                87.408,
                87.436,
                87.464,
                87.491,
                87.519,
                87.547,
                87.575,
                87.602,
                87.63,
                87.658,
                87.685,
                87.713,
                87.741,
                87.768,
                87.796,
                87.823,
                87.851,
                87.878,
                87.906,
                87.933,
                87.961,
                87.988,
                88.015,
                88.043,
                88.07,
                88.097,
                88.125,
                88.152,
                88.179,
                88.207,
                88.234,
                88.261,
                88.288,
                88.315,
                88.342,
                88.37,
                88.397,
                88.424,
                88.451,
                88.478,
                88.505,
                88.532,
                88.559,
                88.586,
                88.613,
                88.64,
                88.666,
                88.693,
                88.72,
                88.747,
                88.774,
                88.801,
                88.827,
                88.854,
                88.881,
                88.907,
                88.934,
                88.961,
                88.987,
                89.014,
                89.041,
                89.067,
                89.094,
                89.12,
                89.147,
                89.173,
                89.2,
                89.226,
                89.253,
                89.279,
                89.306,
                89.332,
                89.358,
                89.385,
                89.411,
                89.437,
                89.464,
                89.49,
                89.516,
                89.542,
                89.568,
                89.595,
                89.621,
                89.647,
                89.673,
                89.699,
                89.725,
                89.751,
                89.777,
                89.803,
                89.829,
                89.855,
                89.881,
                89.907,
                89.933,
                89.959,
                89.985,
                90.011,
                90.037,
                90.063,
                90.088,
                90.114,
                90.14,
                90.166,
                90.191,
                90.217,
                90.243,
                90.268,
                90.294,
                90.32,
                90.345,
                90.371,
                90.397,
                90.422,
                90.448,
                90.473,
                90.499,
                90.524,
                90.55,
                90.575,
                90.6,
                90.626,
                90.651,
                90.677,
                90.702,
                90.727,
                90.753,
                90.778,
                90.803,
                90.828,
                90.854,
                90.879,
                90.904,
                90.929,
                90.954,
                90.98,
                91.005,
                91.03,
                91.055,
                91.08,
                91.105,
                91.13,
                91.155,
                91.18,
                91.205,
                91.23,
                91.255,
                91.28,
                91.305,
                91.33,
                91.355,
                91.379,
                91.404,
                91.429,
                91.454,
                91.479,
                91.504,
                91.528,
                91.553,
                91.578,
                91.602,
                91.627,
                91.652,
                91.676,
                91.701,
                91.726,
                91.75,
                91.775,
                91.8,
                91.824,
                91.849,
                91.873,
                91.898,
                91.922,
                91.947,
                91.971,
                91.996,
                92.02,
                92.044,
                92.069,
                92.093,
                92.118,
                92.142,
                92.166,
                92.191,
                92.215,
                92.239,
                92.264,
                92.288,
                92.312,
                92.336,
                92.36,
                92.385,
                92.409,
                92.433,
                92.457,
                92.481,
                92.505,
                92.53,
                92.554,
                92.578,
                92.602,
                92.626,
                92.65,
                92.674,
                92.698,
                92.722,
                92.746,
                92.77,
                92.794,
                92.818,
                92.842,
                92.866,
                92.89,
                92.914,
                92.937,
                92.961,
                92.985,
                93.009,
                93.033,
                93.057,
                93.08,
                93.104,
                93.128,
                93.152,
                93.175,
                93.199,
                93.223,
                93.247,
                93.27,
                93.294,
                93.318,
                93.341,
                93.365,
                93.388,
                93.412,
                93.436,
                93.459,
                93.483,
                93.506,
                93.53,
                93.553,
                93.577,
                93.6,
                93.624,
                93.647,
                93.671,
                93.694,
                93.718,
                93.741,
                93.765,
                93.788,
                93.811,
                93.835,
                93.858,
                93.881,
                93.905,
                93.928,
                93.951,
                93.975,
                93.998,
                94.021,
                94.045,
                94.068,
                94.091,
                94.114,
                94.138,
                94.161,
                94.184,
                94.207,
                94.23,
                94.254,
                94.277,
                94.3,
                94.323,
                94.346,
                94.369,
                94.392,
                94.415,
                94.438,
                94.462,
                94.485,
                94.508,
                94.531,
                94.554,
                94.577,
                94.6,
                94.623,
                94.646,
                94.669,
                94.691,
                94.714,
                94.737,
                94.76,
                94.783,
                94.806,
                94.829,
                94.852,
                94.875,
                94.898,
                94.92,
                94.943,
                94.966,
                94.989,
                95.012,
                95.034,
                95.057,
                95.08,
                95.103,
                95.125,
                95.148,
                95.171,
                95.194,
                95.216,
                95.239,
                95.262,
                95.284,
                95.307,
                95.33,
                95.352,
                95.375,
                95.398,
                95.42,
                95.443,
                95.465,
                95.488,
                95.511,
                95.533,
                95.556,
                95.578,
                95.601,
                95.623,
                95.646,
                95.668,
                95.691,
                95.713,
                95.736,
                95.758,
                95.781,
                95.803,
                95.825,
                95.848,
                95.87,
                95.893,
                95.915,
                95.937,
                95.96,
                95.982,
                96.004,
                96.027,
                96.049,
                96.071,
                96.094,
                96.116,
                96.138,
                96.161,
                96.183,
                96.205,
                96.227,
                96.25,
                96.272,
                96.294,
                96.316,
                96.338,
                96.361,
                96.383,
                96.405,
                96.427,
                96.449,
                96.471,
                96.493,
                96.516,
                96.538,
                96.56,
                96.582,
                96.604,
                96.626,
                96.648,
                96.67,
                96.692,
                96.714,
                96.736,
                96.758,
                96.78,
                96.802,
                96.824,
                96.846,
                96.868,
                96.89,
                96.912,
                96.934,
                96.956,
                96.978,
                97,
                97.022,
                97.043,
                97.065,
                97.087,
                97.109,
                97.131,
                97.153,
                97.174,
                97.196,
                97.218,
                97.24,
                97.262,
                97.283,
                97.305,
                97.327,
                97.349,
                97.37,
                97.392,
                97.414,
                97.436,
                97.457,
                97.479,
                97.501,
                97.522,
                97.544,
                97.566,
                97.587,
                97.609,
                97.631,
                97.652,
                97.674,
                97.695,
                97.717,
                97.739,
                97.76,
                97.782,
                97.803,
                97.825,
                97.846,
                97.868,
                97.889,
                97.911,
                97.932,
                97.954,
                97.975,
                97.997,
                98.018,
                98.04,
                98.061,
                98.083,
                98.104,
                98.125,
                98.147,
                98.168,
                98.19,
                98.211,
                98.232,
                98.254,
                98.275,
                98.296,
                98.318,
                98.339,
                98.36,
                98.382,
                98.403,
                98.424,
                98.445,
                98.467,
                98.488,
                98.509,
                98.53,
                98.552,
                98.573,
                98.594,
                98.615,
                98.636,
                98.658,
                98.679,
                98.7,
                98.721,
                98.742,
                98.763,
                98.784,
                98.805,
                98.827,
                98.848,
                98.869,
                98.89,
                98.911,
                98.932,
                98.953,
                98.974,
                98.995,
                99.016,
                99.037,
                99.058,
                99.079,
                99.1,
                99.121,
                99.142,
                99.163,
                99.184,
                99.205,
                99.226,
                99.246,
                99.267,
                99.288,
                99.309,
                99.33,
                99.351,
                99.372,
                99.393,
                99.413,
                99.434,
                99.455,
                99.476,
                99.497,
                99.517,
                99.538,
                99.559,
                99.58,
                99.601,
                99.621,
                99.642,
                99.663,
                99.683,
                99.704,
                99.725,
                99.746,
                99.766,
                99.787,
                99.808,
                99.828,
                99.849,
                99.87,
                99.89,
                99.911,
                99.931,
                99.952,
                99.973,
                99.993,
                100.014,
                100.034,
                100.055,
                100.075,
                100.096,
                100.116,
                100.137,
                100.157,
                100.178,
                100.198,
                100.219,
                100.239,
                100.26,
                100.28,
                100.301,
                100.321,
                100.342,
                100.362,
                100.382,
                100.403,
                100.423,
                100.444,
                100.464,
                100.484,
                100.505,
                100.525,
                100.545,
                100.566,
                100.586,
                100.606,
                100.627,
                100.647,
                100.667,
                100.688,
                100.708,
                100.728,
                100.748,
                100.769,
                100.789,
                100.809,
                100.829,
                100.849,
                100.87,
                100.89,
                100.91,
                100.93,
                100.95,
                100.97,
                100.991,
                101.011,
                101.031,
                101.051,
                101.071,
                101.091,
                101.111,
                101.131,
                101.151,
                101.171,
                101.192,
                101.212,
                101.232,
                101.252,
                101.272,
                101.292,
                101.312,
                101.332,
                101.352,
                101.372,
                101.392,
                101.412,
                101.432,
                101.452,
                101.471,
                101.491,
                101.511,
                101.531,
                101.551,
                101.571,
                101.591,
                101.611,
                101.631,
                101.651,
                101.67,
                101.69,
                101.71,
                101.73,
                101.75,
                101.77,
                101.789,
                101.809,
                101.829,
                101.849,
                101.869,
                101.888,
                101.908,
                101.928,
                101.948,
                101.967,
                101.987,
                102.007,
                102.026,
                102.046,
                102.066,
                102.086,
                102.105,
                102.125,
                102.145,
                102.164,
                102.184,
                102.204,
                102.223,
                102.243,
                102.262,
                102.282,
                102.302,
                102.321,
                102.341,
                102.36,
                102.38,
                102.4,
                102.419,
                102.439,
                102.458,
                102.478,
                102.497,
                102.517,
                102.536,
                102.556,
                102.575,
                102.595,
                102.614,
                102.634,
                102.653,
                102.673,
                102.692,
                102.712,
                102.731,
                102.751,
                102.77,
                102.79,
                102.809,
                102.828,
                102.848,
                102.867,
                102.887,
                102.906,
                102.925,
                102.945,
                102.964,
                102.983,
                103.003,
                103.022,
                103.041,
                103.061,
                103.08,
                103.099,
                103.119,
                103.138,
                103.157,
                103.177,
                103.196,
                103.215,
                103.234,
                103.254,
                103.273,
                103.292,
                103.311,
                103.331,
                103.35,
                103.369,
                103.388,
                103.407,
                103.427,
                103.446,
                103.465,
                103.484,
                103.503,
                103.523,
                103.542,
                103.561,
                103.58,
                103.599,
                103.618,
                103.637,
                103.657,
                103.676,
                103.695,
                103.714,
                103.733,
                103.752,
                103.771,
                103.79,
                103.809,
                103.828,
                103.847,
                103.866,
                103.885,
                103.905,
                103.924,
                103.943,
                103.962,
                103.981,
                104,
                104.019,
                104.038,
                104.057,
                104.076,
                104.095,
                104.114,
                104.132,
                104.151,
                104.17,
                104.189,
                104.208,
                104.227,
                104.246,
                104.265,
                104.284,
                104.303,
                104.322,
                104.341,
                104.36,
                104.378,
                104.397,
                104.416,
                104.435,
                104.454,
                104.473,
                104.492,
                104.51,
                104.529,
                104.548,
                104.567,
                104.586,
                104.605,
                104.623,
                104.642,
                104.661,
                104.68,
                104.698,
                104.717,
                104.736,
                104.755,
                104.774,
                104.792,
                104.811,
                104.83,
                104.848,
                104.867,
                104.886,
                104.905,
                104.923,
                104.942,
                104.961,
                104.979,
                104.998,
                105.017,
                105.035,
                105.054,
                105.073,
                105.091,
                105.11,
                105.129,
                105.147,
                105.166,
                105.185,
                105.203,
                105.222,
                105.24,
                105.259,
                105.278,
                105.296,
                105.315,
                105.333,
                105.352,
                105.371,
                105.389,
                105.408,
                105.426,
                105.445,
                105.463,
                105.482,
                105.5,
                105.519,
                105.537,
                105.556,
                105.574,
                105.593,
                105.611,
                105.63,
                105.648,
                105.667,
                105.685,
                105.704,
                105.722,
                105.741,
                105.759,
                105.778,
                105.796,
                105.814,
                105.833,
                105.851,
                105.87,
                105.888,
                105.906,
                105.925,
                105.943,
                105.962,
                105.98,
                105.998,
                106.017,
                106.035,
                106.053,
                106.072,
                106.09,
                106.108,
                106.127,
                106.145,
                106.163,
                106.182,
                106.2,
                106.218,
                106.237,
                106.255,
                106.273,
                106.292,
                106.31,
                106.328,
                106.346,
                106.365,
                106.383,
                106.401,
                106.419,
                106.438,
                106.456,
                106.474,
                106.492,
                106.51,
                106.529,
                106.547,
                106.565,
                106.583,
                106.601,
                106.62,
                106.638,
                106.656,
                106.674,
                106.692,
                106.71,
                106.728,
                106.747,
                106.765,
                106.783,
                106.801,
                106.819,
                106.837,
                106.855,
                106.873,
                106.891,
                106.909,
                106.928,
                106.946,
                106.964,
                106.982,
                107,
                107.018,
                107.036,
                107.054,
                107.072,
                107.09,
                107.108,
                107.126,
                107.144,
                107.162,
                107.18,
                107.198,
                107.216,
                107.234,
                107.252,
                107.27,
                107.288,
                107.306,
                107.324,
                107.342,
                107.36,
                107.378,
                107.395,
                107.413,
                107.431,
                107.449,
                107.467,
                107.485,
                107.503,
                107.521,
                107.539,
                107.557,
                107.574,
                107.592,
                107.61,
                107.628,
                107.646,
                107.664,
                107.681,
                107.699,
                107.717,
                107.735,
                107.753,
                107.771,
                107.788,
                107.806,
                107.824,
                107.842,
                107.859,
                107.877,
                107.895,
                107.913,
                107.93,
                107.948,
                107.966,
                107.984,
                108.001,
                108.019,
                108.037,
                108.055,
                108.072,
                108.09,
                108.108,
                108.125,
                108.143,
                108.161,
                108.178,
                108.196,
                108.214,
                108.231,
                108.249,
                108.267,
                108.284,
                108.302,
                108.32,
                108.337,
                108.355,
                108.372,
                108.39,
                108.408,
                108.425,
                108.443,
                108.46,
                108.478,
                108.495,
                108.513,
                108.531,
                108.548,
                108.566,
                108.583,
                108.601,
                108.618,
                108.636,
                108.653,
                108.671,
                108.688,
                108.706,
                108.723,
                108.741,
                108.758,
                108.776,
                108.793,
                108.811,
                108.828,
                108.846,
                108.863,
                108.881,
                108.898,
                108.916,
                108.933,
                108.95,
                108.968,
                108.985,
                109.003,
                109.02,
                109.037,
                109.055,
                109.072,
                109.09,
                109.107,
                109.124,
                109.142,
                109.159,
                109.176,
                109.194,
                109.211,
                109.229,
                109.246,
                109.263,
                109.281,
                109.298,
                109.315,
                109.332,
                109.35,
                109.367,
                109.384,
                109.402,
                109.419,
                109.436,
                109.454,
                109.471,
                109.488,
                109.505,
                109.523,
                109.54,
                109.557,
                109.574,
                109.592,
                109.609,
                109.626,
                109.643,
                109.66,
                109.678,
                109.695,
                109.712,
                109.729,
                109.746,
                109.764,
                109.781,
                109.798,
                109.815,
                109.832,
                109.849,
                109.867,
                109.884,
                109.901,
                109.918,
                109.935
            ],
            P75: [
                50.404,
                50.575,
                50.746,
                50.917,
                51.088,
                51.259,
                51.429,
                51.6,
                51.771,
                51.942,
                52.113,
                52.283,
                52.454,
                52.625,
                52.795,
                52.937,
                53.077,
                53.217,
                53.356,
                53.494,
                53.632,
                53.768,
                53.903,
                54.038,
                54.171,
                54.303,
                54.434,
                54.565,
                54.694,
                54.822,
                54.95,
                55.076,
                55.202,
                55.326,
                55.45,
                55.572,
                55.694,
                55.815,
                55.935,
                56.054,
                56.171,
                56.289,
                56.405,
                56.52,
                56.634,
                56.748,
                56.861,
                56.972,
                57.084,
                57.194,
                57.303,
                57.411,
                57.519,
                57.626,
                57.732,
                57.838,
                57.942,
                58.046,
                58.149,
                58.251,
                58.353,
                58.453,
                58.553,
                58.653,
                58.752,
                58.85,
                58.948,
                59.045,
                59.141,
                59.236,
                59.332,
                59.426,
                59.52,
                59.613,
                59.706,
                59.798,
                59.89,
                59.98,
                60.071,
                60.161,
                60.25,
                60.338,
                60.427,
                60.514,
                60.602,
                60.688,
                60.774,
                60.86,
                60.944,
                61.029,
                61.113,
                61.197,
                61.28,
                61.362,
                61.444,
                61.526,
                61.607,
                61.688,
                61.768,
                61.848,
                61.928,
                62.007,
                62.085,
                62.163,
                62.241,
                62.318,
                62.395,
                62.471,
                62.547,
                62.623,
                62.697,
                62.772,
                62.846,
                62.92,
                62.994,
                63.067,
                63.14,
                63.212,
                63.284,
                63.355,
                63.426,
                63.497,
                63.567,
                63.637,
                63.707,
                63.776,
                63.845,
                63.913,
                63.981,
                64.048,
                64.116,
                64.183,
                64.249,
                64.316,
                64.381,
                64.447,
                64.512,
                64.577,
                64.642,
                64.706,
                64.77,
                64.834,
                64.897,
                64.96,
                65.023,
                65.085,
                65.147,
                65.209,
                65.271,
                65.332,
                65.393,
                65.454,
                65.514,
                65.574,
                65.634,
                65.694,
                65.753,
                65.813,
                65.872,
                65.931,
                65.989,
                66.047,
                66.105,
                66.163,
                66.221,
                66.278,
                66.336,
                66.392,
                66.449,
                66.506,
                66.562,
                66.619,
                66.675,
                66.731,
                66.786,
                66.842,
                66.897,
                66.952,
                67.007,
                67.062,
                67.117,
                67.172,
                67.226,
                67.28,
                67.334,
                67.388,
                67.442,
                67.496,
                67.549,
                67.602,
                67.656,
                67.708,
                67.762,
                67.814,
                67.867,
                67.92,
                67.972,
                68.024,
                68.077,
                68.129,
                68.18,
                68.232,
                68.284,
                68.335,
                68.387,
                68.439,
                68.49,
                68.541,
                68.592,
                68.643,
                68.694,
                68.745,
                68.795,
                68.846,
                68.896,
                68.947,
                68.997,
                69.047,
                69.097,
                69.147,
                69.197,
                69.247,
                69.296,
                69.346,
                69.396,
                69.445,
                69.494,
                69.544,
                69.593,
                69.642,
                69.691,
                69.74,
                69.789,
                69.838,
                69.886,
                69.935,
                69.983,
                70.032,
                70.08,
                70.129,
                70.177,
                70.225,
                70.273,
                70.321,
                70.369,
                70.417,
                70.464,
                70.512,
                70.56,
                70.607,
                70.655,
                70.703,
                70.75,
                70.797,
                70.845,
                70.892,
                70.939,
                70.986,
                71.033,
                71.079,
                71.127,
                71.173,
                71.22,
                71.267,
                71.313,
                71.36,
                71.406,
                71.453,
                71.499,
                71.545,
                71.591,
                71.638,
                71.684,
                71.73,
                71.776,
                71.822,
                71.868,
                71.914,
                71.959,
                72.005,
                72.05,
                72.096,
                72.142,
                72.187,
                72.232,
                72.278,
                72.323,
                72.368,
                72.414,
                72.459,
                72.504,
                72.549,
                72.594,
                72.639,
                72.684,
                72.729,
                72.774,
                72.818,
                72.863,
                72.908,
                72.952,
                72.996,
                73.041,
                73.085,
                73.13,
                73.174,
                73.218,
                73.262,
                73.307,
                73.351,
                73.395,
                73.439,
                73.483,
                73.527,
                73.57,
                73.614,
                73.658,
                73.702,
                73.745,
                73.789,
                73.832,
                73.876,
                73.919,
                73.962,
                74.006,
                74.049,
                74.093,
                74.136,
                74.179,
                74.222,
                74.265,
                74.308,
                74.351,
                74.394,
                74.437,
                74.479,
                74.522,
                74.565,
                74.608,
                74.65,
                74.693,
                74.735,
                74.778,
                74.82,
                74.863,
                74.905,
                74.947,
                74.989,
                75.031,
                75.074,
                75.115,
                75.158,
                75.199,
                75.242,
                75.283,
                75.325,
                75.367,
                75.409,
                75.45,
                75.492,
                75.534,
                75.576,
                75.617,
                75.659,
                75.7,
                75.741,
                75.783,
                75.824,
                75.865,
                75.907,
                75.948,
                75.989,
                76.03,
                76.071,
                76.112,
                76.153,
                76.194,
                76.235,
                76.276,
                76.316,
                76.357,
                76.398,
                76.439,
                76.48,
                76.52,
                76.561,
                76.601,
                76.642,
                76.682,
                76.722,
                76.763,
                76.803,
                76.843,
                76.883,
                76.923,
                76.963,
                77.004,
                77.044,
                77.084,
                77.124,
                77.163,
                77.204,
                77.244,
                77.283,
                77.323,
                77.362,
                77.402,
                77.442,
                77.481,
                77.521,
                77.56,
                77.6,
                77.64,
                77.679,
                77.718,
                77.757,
                77.797,
                77.836,
                77.875,
                77.914,
                77.954,
                77.993,
                78.032,
                78.07,
                78.11,
                78.149,
                78.187,
                78.226,
                78.265,
                78.304,
                78.343,
                78.381,
                78.42,
                78.459,
                78.497,
                78.536,
                78.574,
                78.612,
                78.651,
                78.69,
                78.728,
                78.766,
                78.805,
                78.842,
                78.881,
                78.919,
                78.957,
                78.995,
                79.033,
                79.071,
                79.109,
                79.147,
                79.185,
                79.223,
                79.26,
                79.298,
                79.336,
                79.374,
                79.412,
                79.45,
                79.487,
                79.525,
                79.562,
                79.599,
                79.637,
                79.675,
                79.712,
                79.749,
                79.787,
                79.824,
                79.861,
                79.899,
                79.936,
                79.973,
                80.01,
                80.048,
                80.084,
                80.122,
                80.159,
                80.195,
                80.232,
                80.27,
                80.306,
                80.343,
                80.38,
                80.417,
                80.453,
                80.49,
                80.527,
                80.563,
                80.6,
                80.637,
                80.673,
                80.71,
                80.747,
                80.783,
                80.819,
                80.856,
                80.892,
                80.928,
                80.965,
                81.001,
                81.037,
                81.074,
                81.11,
                81.146,
                81.182,
                81.218,
                81.254,
                81.29,
                81.326,
                81.362,
                81.398,
                81.434,
                81.47,
                81.505,
                81.541,
                81.577,
                81.613,
                81.648,
                81.684,
                81.72,
                81.755,
                81.791,
                81.827,
                81.862,
                81.897,
                81.933,
                81.969,
                82.004,
                82.039,
                82.075,
                82.11,
                82.145,
                82.181,
                82.216,
                82.251,
                82.286,
                82.321,
                82.357,
                82.391,
                82.426,
                82.462,
                82.496,
                82.531,
                82.566,
                82.601,
                82.636,
                82.671,
                82.706,
                82.741,
                82.775,
                82.81,
                82.845,
                82.879,
                82.914,
                82.949,
                82.983,
                83.017,
                83.052,
                83.087,
                83.121,
                83.155,
                83.19,
                83.225,
                83.259,
                83.293,
                83.327,
                83.362,
                83.396,
                83.43,
                83.464,
                83.499,
                83.533,
                83.567,
                83.601,
                83.635,
                83.669,
                83.703,
                83.737,
                83.771,
                83.805,
                83.839,
                83.873,
                83.906,
                83.94,
                83.974,
                84.008,
                84.041,
                84.075,
                84.109,
                84.143,
                84.176,
                84.21,
                84.244,
                84.277,
                84.31,
                84.344,
                84.378,
                84.411,
                84.444,
                84.478,
                84.511,
                84.545,
                84.578,
                84.611,
                84.644,
                84.678,
                84.711,
                84.744,
                84.777,
                84.811,
                84.843,
                84.876,
                84.91,
                84.943,
                84.975,
                85.009,
                85.042,
                85.074,
                85.107,
                85.14,
                85.173,
                85.206,
                85.239,
                85.272,
                85.304,
                85.337,
                85.37,
                85.402,
                85.435,
                85.467,
                85.5,
                85.533,
                85.566,
                85.598,
                85.63,
                85.663,
                85.696,
                85.728,
                85.76,
                85.793,
                85.825,
                85.857,
                85.889,
                85.922,
                85.954,
                85.986,
                86.018,
                86.051,
                86.083,
                86.115,
                86.147,
                86.179,
                86.211,
                86.243,
                86.275,
                86.307,
                86.339,
                86.371,
                86.403,
                86.435,
                86.467,
                86.498,
                86.53,
                86.562,
                86.594,
                86.625,
                86.657,
                86.689,
                86.721,
                86.752,
                86.784,
                86.816,
                86.847,
                86.878,
                86.91,
                86.942,
                86.973,
                87.004,
                87.036,
                87.067,
                87.099,
                87.13,
                87.161,
                87.193,
                87.224,
                87.255,
                87.286,
                87.317,
                87.349,
                87.38,
                87.411,
                87.442,
                87.473,
                87.504,
                87.535,
                87.566,
                87.597,
                87.628,
                87.659,
                87.69,
                87.72,
                87.751,
                87.782,
                87.813,
                87.844,
                87.875,
                87.906,
                87.936,
                87.967,
                87.998,
                88.028,
                88.059,
                88.089,
                88.12,
                88.151,
                88.181,
                88.212,
                88.242,
                88.273,
                88.303,
                88.333,
                88.364,
                88.395,
                88.425,
                88.455,
                88.485,
                88.516,
                88.546,
                88.576,
                87.906,
                87.937,
                87.967,
                87.997,
                88.027,
                88.057,
                88.088,
                88.118,
                88.148,
                88.178,
                88.208,
                88.238,
                88.268,
                88.298,
                88.328,
                88.357,
                88.387,
                88.417,
                88.447,
                88.477,
                88.507,
                88.537,
                88.566,
                88.596,
                88.626,
                88.656,
                88.685,
                88.715,
                88.744,
                88.774,
                88.803,
                88.833,
                88.863,
                88.892,
                88.922,
                88.951,
                88.981,
                89.01,
                89.04,
                89.069,
                89.099,
                89.128,
                89.157,
                89.186,
                89.215,
                89.245,
                89.274,
                89.304,
                89.332,
                89.362,
                89.391,
                89.42,
                89.449,
                89.478,
                89.508,
                89.536,
                89.566,
                89.595,
                89.623,
                89.652,
                89.682,
                89.711,
                89.739,
                89.768,
                89.797,
                89.826,
                89.855,
                89.884,
                89.913,
                89.941,
                89.97,
                89.999,
                90.028,
                90.056,
                90.085,
                90.114,
                90.142,
                90.171,
                90.199,
                90.227,
                90.256,
                90.285,
                90.314,
                90.342,
                90.37,
                90.399,
                90.427,
                90.455,
                90.484,
                90.512,
                90.54,
                90.569,
                90.597,
                90.625,
                90.654,
                90.682,
                90.71,
                90.738,
                90.766,
                90.795,
                90.822,
                90.851,
                90.879,
                90.907,
                90.935,
                90.963,
                90.991,
                91.019,
                91.047,
                91.075,
                91.102,
                91.13,
                91.158,
                91.186,
                91.214,
                91.242,
                91.27,
                91.297,
                91.325,
                91.353,
                91.38,
                91.408,
                91.436,
                91.463,
                91.491,
                91.519,
                91.546,
                91.573,
                91.601,
                91.629,
                91.656,
                91.684,
                91.711,
                91.738,
                91.766,
                91.793,
                91.82,
                91.848,
                91.875,
                91.903,
                91.93,
                91.957,
                91.985,
                92.011,
                92.039,
                92.066,
                92.093,
                92.12,
                92.147,
                92.174,
                92.201,
                92.229,
                92.255,
                92.282,
                92.31,
                92.336,
                92.363,
                92.39,
                92.418,
                92.444,
                92.471,
                92.498,
                92.525,
                92.552,
                92.579,
                92.605,
                92.632,
                92.659,
                92.685,
                92.712,
                92.739,
                92.765,
                92.792,
                92.819,
                92.845,
                92.872,
                92.899,
                92.925,
                92.951,
                92.978,
                93.004,
                93.031,
                93.057,
                93.084,
                93.11,
                93.137,
                93.163,
                93.189,
                93.216,
                93.242,
                93.268,
                93.295,
                93.32,
                93.347,
                93.373,
                93.399,
                93.425,
                93.452,
                93.478,
                93.504,
                93.53,
                93.556,
                93.582,
                93.608,
                93.634,
                93.66,
                93.687,
                93.712,
                93.738,
                93.764,
                93.79,
                93.816,
                93.842,
                93.868,
                93.894,
                93.92,
                93.945,
                93.971,
                93.997,
                94.023,
                94.048,
                94.074,
                94.1,
                94.126,
                94.151,
                94.177,
                94.203,
                94.228,
                94.254,
                94.28,
                94.305,
                94.33,
                94.356,
                94.381,
                94.407,
                94.433,
                94.458,
                94.484,
                94.509,
                94.534,
                94.56,
                94.585,
                94.611,
                94.636,
                94.661,
                94.687,
                94.712,
                94.737,
                94.763,
                94.788,
                94.813,
                94.838,
                94.863,
                94.889,
                94.914,
                94.939,
                94.964,
                94.99,
                95.014,
                95.04,
                95.065,
                95.09,
                95.115,
                95.14,
                95.165,
                95.19,
                95.215,
                95.24,
                95.265,
                95.29,
                95.315,
                95.34,
                95.365,
                95.39,
                95.415,
                95.44,
                95.464,
                95.489,
                95.514,
                95.539,
                95.564,
                95.589,
                95.613,
                95.638,
                95.663,
                95.688,
                95.713,
                95.737,
                95.762,
                95.786,
                95.811,
                95.836,
                95.86,
                95.885,
                95.91,
                95.934,
                95.959,
                95.983,
                96.008,
                96.033,
                96.057,
                96.082,
                96.106,
                96.131,
                96.156,
                96.18,
                96.204,
                96.228,
                96.253,
                96.278,
                96.302,
                96.326,
                96.35,
                96.375,
                96.4,
                96.424,
                96.448,
                96.472,
                96.497,
                96.521,
                96.545,
                96.57,
                96.594,
                96.618,
                96.643,
                96.667,
                96.691,
                96.715,
                96.739,
                96.764,
                96.788,
                96.812,
                96.836,
                96.86,
                96.884,
                96.908,
                96.932,
                96.956,
                96.981,
                97.004,
                97.029,
                97.053,
                97.077,
                97.101,
                97.124,
                97.149,
                97.173,
                97.196,
                97.221,
                97.244,
                97.268,
                97.293,
                97.316,
                97.34,
                97.364,
                97.388,
                97.412,
                97.436,
                97.46,
                97.483,
                97.507,
                97.531,
                97.555,
                97.578,
                97.602,
                97.626,
                97.65,
                97.674,
                97.697,
                97.721,
                97.744,
                97.768,
                97.792,
                97.816,
                97.84,
                97.863,
                97.887,
                97.91,
                97.934,
                97.958,
                97.981,
                98.005,
                98.028,
                98.052,
                98.075,
                98.099,
                98.123,
                98.146,
                98.17,
                98.193,
                98.217,
                98.24,
                98.263,
                98.287,
                98.31,
                98.334,
                98.357,
                98.381,
                98.404,
                98.428,
                98.451,
                98.474,
                98.498,
                98.521,
                98.545,
                98.567,
                98.591,
                98.614,
                98.638,
                98.661,
                98.684,
                98.708,
                98.731,
                98.754,
                98.777,
                98.8,
                98.823,
                98.847,
                98.87,
                98.893,
                98.917,
                98.939,
                98.963,
                98.986,
                99.009,
                99.032,
                99.055,
                99.078,
                99.101,
                99.125,
                99.148,
                99.171,
                99.194,
                99.217,
                99.24,
                99.263,
                99.286,
                99.309,
                99.331,
                99.355,
                99.378,
                99.401,
                99.424,
                99.446,
                99.47,
                99.492,
                99.515,
                99.538,
                99.561,
                99.584,
                99.607,
                99.629,
                99.653,
                99.676,
                99.698,
                99.721,
                99.744,
                99.767,
                99.789,
                99.812,
                99.835,
                99.858,
                99.88,
                99.903,
                99.926,
                99.949,
                99.972,
                99.994,
                100.017,
                100.039,
                100.063,
                100.085,
                100.108,
                100.13,
                100.153,
                100.175,
                100.198,
                100.22,
                100.243,
                100.266,
                100.288,
                100.311,
                100.334,
                100.356,
                100.379,
                100.401,
                100.424,
                100.446,
                100.469,
                100.491,
                100.514,
                100.536,
                100.558,
                100.581,
                100.603,
                100.626,
                100.648,
                100.671,
                100.693,
                100.716,
                100.738,
                100.76,
                100.782,
                100.805,
                100.827,
                100.85,
                100.872,
                100.894,
                100.917,
                100.939,
                100.961,
                100.983,
                101.006,
                101.028,
                101.05,
                101.072,
                101.095,
                101.117,
                101.139,
                101.161,
                101.184,
                101.205,
                101.228,
                101.25,
                101.272,
                101.294,
                101.316,
                101.338,
                101.361,
                101.382,
                101.405,
                101.427,
                101.449,
                101.471,
                101.493,
                101.515,
                101.537,
                101.559,
                101.581,
                101.603,
                101.625,
                101.647,
                101.669,
                101.691,
                101.713,
                101.735,
                101.757,
                101.779,
                101.801,
                101.823,
                101.844,
                101.867,
                101.888,
                101.91,
                101.932,
                101.954,
                101.976,
                101.998,
                102.019,
                102.041,
                102.063,
                102.085,
                102.106,
                102.128,
                102.15,
                102.172,
                102.193,
                102.215,
                102.237,
                102.259,
                102.28,
                102.302,
                102.324,
                102.346,
                102.367,
                102.389,
                102.41,
                102.432,
                102.453,
                102.475,
                102.497,
                102.519,
                102.54,
                102.562,
                102.583,
                102.605,
                102.626,
                102.648,
                102.669,
                102.691,
                102.712,
                102.734,
                102.755,
                102.777,
                102.798,
                102.819,
                102.841,
                102.862,
                102.884,
                102.905,
                102.927,
                102.948,
                102.97,
                102.991,
                103.013,
                103.034,
                103.055,
                103.076,
                103.098,
                103.119,
                103.141,
                103.162,
                103.183,
                103.204,
                103.226,
                103.247,
                103.269,
                103.29,
                103.311,
                103.332,
                103.354,
                103.375,
                103.396,
                103.417,
                103.439,
                103.459,
                103.481,
                103.502,
                103.523,
                103.544,
                103.566,
                103.586,
                103.607,
                103.629,
                103.649,
                103.671,
                103.692,
                103.713,
                103.734,
                103.755,
                103.776,
                103.797,
                103.818,
                103.839,
                103.86,
                103.881,
                103.902,
                103.924,
                103.944,
                103.966,
                103.986,
                104.007,
                104.028,
                104.049,
                104.07,
                104.091,
                104.112,
                104.132,
                104.154,
                104.174,
                104.196,
                104.216,
                104.237,
                104.258,
                104.279,
                104.299,
                104.321,
                104.341,
                104.362,
                104.383,
                104.404,
                104.424,
                104.446,
                104.466,
                104.487,
                104.507,
                104.528,
                104.549,
                104.569,
                104.59,
                104.611,
                104.632,
                104.652,
                104.673,
                104.694,
                104.715,
                104.735,
                104.756,
                104.776,
                104.797,
                104.818,
                104.839,
                104.859,
                104.879,
                104.9,
                104.92,
                104.941,
                104.962,
                104.983,
                105.003,
                105.024,
                105.044,
                105.065,
                105.085,
                105.106,
                105.126,
                105.146,
                105.167,
                105.187,
                105.208,
                105.228,
                105.249,
                105.269,
                105.29,
                105.31,
                105.331,
                105.351,
                105.372,
                105.392,
                105.412,
                105.433,
                105.453,
                105.474,
                105.494,
                105.514,
                105.534,
                105.555,
                105.575,
                105.596,
                105.616,
                105.637,
                105.657,
                105.676,
                105.697,
                105.717,
                105.738,
                105.758,
                105.778,
                105.798,
                105.819,
                105.839,
                105.859,
                105.879,
                105.899,
                105.92,
                105.94,
                105.96,
                105.98,
                106.001,
                106.021,
                106.041,
                106.061,
                106.082,
                106.101,
                106.121,
                106.142,
                106.162,
                106.182,
                106.202,
                106.222,
                106.242,
                106.263,
                106.282,
                106.303,
                106.323,
                106.342,
                106.363,
                106.383,
                106.403,
                106.423,
                106.443,
                106.463,
                106.483,
                106.503,
                106.523,
                106.543,
                106.563,
                106.583,
                106.603,
                106.623,
                106.643,
                106.663,
                106.683,
                106.702,
                106.723,
                106.742,
                106.763,
                106.782,
                106.802,
                106.822,
                106.842,
                106.862,
                106.882,
                106.902,
                106.921,
                106.942,
                106.961,
                106.981,
                107.001,
                107.021,
                107.041,
                107.06,
                107.08,
                107.1,
                107.12,
                107.14,
                107.16,
                107.179,
                107.199,
                107.219,
                107.238,
                107.259,
                107.278,
                107.298,
                107.318,
                107.338,
                107.357,
                107.376,
                107.397,
                107.416,
                107.436,
                107.456,
                107.476,
                107.495,
                107.514,
                107.534,
                107.554,
                107.574,
                107.593,
                107.613,
                107.633,
                107.653,
                107.672,
                107.691,
                107.711,
                107.731,
                107.751,
                107.77,
                107.79,
                107.809,
                107.828,
                107.848,
                107.868,
                107.888,
                107.907,
                107.927,
                107.946,
                107.965,
                107.985,
                108.004,
                108.024,
                108.043,
                108.063,
                108.083,
                108.102,
                108.122,
                108.141,
                108.161,
                108.18,
                108.2,
                108.219,
                108.238,
                108.258,
                108.277,
                108.297,
                108.316,
                108.336,
                108.355,
                108.374,
                108.394,
                108.413,
                108.433,
                108.452,
                108.472,
                108.491,
                108.51,
                108.53,
                108.549,
                108.568,
                108.587,
                108.607,
                108.626,
                108.645,
                108.665,
                108.684,
                108.704,
                108.723,
                108.742,
                108.761,
                108.78,
                108.8,
                108.819,
                108.839,
                108.858,
                108.877,
                108.896,
                108.915,
                108.935,
                108.954,
                108.973,
                108.992,
                109.011,
                109.031,
                109.05,
                109.069,
                109.088,
                109.108,
                109.127,
                109.146,
                109.165,
                109.184,
                109.204,
                109.222,
                109.241,
                109.261,
                109.28,
                109.299,
                109.318,
                109.338,
                109.356,
                109.375,
                109.395,
                109.413,
                109.433,
                109.452,
                109.47,
                109.49,
                109.509,
                109.528,
                109.547,
                109.566,
                109.585,
                109.604,
                109.623,
                109.642,
                109.661,
                109.68,
                109.699,
                109.718,
                109.737,
                109.756,
                109.775,
                109.794,
                109.813,
                109.832,
                109.851,
                109.87,
                109.889,
                109.908,
                109.926,
                109.946,
                109.964,
                109.984,
                110.002,
                110.021,
                110.04,
                110.059,
                110.078,
                110.097,
                110.116,
                110.134,
                110.153,
                110.172,
                110.191,
                110.21,
                110.229,
                110.247,
                110.266,
                110.285,
                110.304,
                110.323,
                110.341,
                110.36,
                110.379,
                110.398,
                110.416,
                110.436,
                110.454,
                110.473,
                110.492,
                110.51,
                110.529,
                110.548,
                110.566,
                110.585,
                110.604,
                110.623,
                110.641,
                110.66,
                110.679,
                110.697,
                110.716,
                110.735,
                110.753,
                110.772,
                110.791,
                110.81,
                110.828,
                110.847,
                110.865,
                110.884,
                110.903,
                110.921,
                110.94,
                110.958,
                110.977,
                110.996,
                111.014,
                111.033,
                111.051,
                111.069,
                111.088,
                111.107,
                111.126,
                111.144,
                111.162,
                111.181,
                111.199,
                111.218,
                111.237,
                111.255,
                111.274,
                111.292,
                111.311,
                111.329,
                111.347,
                111.366,
                111.384,
                111.403,
                111.421,
                111.44,
                111.458,
                111.476,
                111.495,
                111.513,
                111.532,
                111.55,
                111.568,
                111.587,
                111.605,
                111.624,
                111.642,
                111.66,
                111.679,
                111.697,
                111.716,
                111.734,
                111.752,
                111.771,
                111.789,
                111.808,
                111.826,
                111.844,
                111.863,
                111.881,
                111.899,
                111.917,
                111.935,
                111.954,
                111.972,
                111.991,
                112.009,
                112.027,
                112.046,
                112.063,
                112.082,
                112.1,
                112.118,
                112.137,
                112.155,
                112.173,
                112.191,
                112.209,
                112.228,
                112.246,
                112.264,
                112.282,
                112.3,
                112.319,
                112.337,
                112.355,
                112.373,
                112.391,
                112.41,
                112.427,
                112.446,
                112.464,
                112.482,
                112.5,
                112.518,
                112.537,
                112.554,
                112.572,
                112.591,
                112.609,
                112.626,
                112.645,
                112.663,
                112.681,
                112.699,
                112.717,
                112.735,
                112.753,
                112.771,
                112.789,
                112.807,
                112.825,
                112.843,
                112.862,
                112.879,
                112.897,
                112.915,
                112.933,
                112.952,
                112.969,
                112.987,
                113.006,
                113.023,
                113.042,
                113.059,
                113.077,
                113.095,
                113.113,
                113.131,
                113.149,
                113.167
            ],
            P85: [
                51.078,
                51.25,
                51.422,
                51.594,
                51.766,
                51.938,
                52.11,
                52.282,
                52.454,
                52.625,
                52.798,
                52.969,
                53.141,
                53.313,
                53.484,
                53.626,
                53.769,
                53.909,
                54.05,
                54.189,
                54.328,
                54.465,
                54.601,
                54.737,
                54.871,
                55.004,
                55.137,
                55.268,
                55.399,
                55.528,
                55.657,
                55.784,
                55.911,
                56.037,
                56.161,
                56.285,
                56.408,
                56.529,
                56.65,
                56.77,
                56.889,
                57.007,
                57.124,
                57.24,
                57.356,
                57.47,
                57.584,
                57.697,
                57.809,
                57.92,
                58.03,
                58.139,
                58.248,
                58.356,
                58.463,
                58.569,
                58.675,
                58.779,
                58.883,
                58.986,
                59.089,
                59.19,
                59.291,
                59.392,
                59.491,
                59.591,
                59.689,
                59.787,
                59.884,
                59.98,
                60.077,
                60.172,
                60.266,
                60.361,
                60.454,
                60.547,
                60.639,
                60.731,
                60.822,
                60.913,
                61.003,
                61.092,
                61.182,
                61.27,
                61.358,
                61.445,
                61.532,
                61.618,
                61.704,
                61.789,
                61.874,
                61.958,
                62.042,
                62.125,
                62.208,
                62.291,
                62.373,
                62.454,
                62.535,
                62.616,
                62.696,
                62.776,
                62.854,
                62.933,
                63.012,
                63.09,
                63.167,
                63.245,
                63.321,
                63.398,
                63.473,
                63.548,
                63.623,
                63.698,
                63.772,
                63.846,
                63.919,
                63.992,
                64.064,
                64.136,
                64.208,
                64.28,
                64.351,
                64.422,
                64.492,
                64.561,
                64.631,
                64.7,
                64.768,
                64.836,
                64.905,
                64.972,
                65.039,
                65.106,
                65.173,
                65.239,
                65.305,
                65.37,
                65.436,
                65.5,
                65.565,
                65.629,
                65.693,
                65.757,
                65.82,
                65.883,
                65.946,
                66.009,
                66.07,
                66.133,
                66.194,
                66.255,
                66.317,
                66.377,
                66.438,
                66.498,
                66.558,
                66.618,
                66.678,
                66.737,
                66.796,
                66.855,
                66.914,
                66.972,
                67.03,
                67.088,
                67.146,
                67.203,
                67.261,
                67.318,
                67.375,
                67.432,
                67.489,
                67.545,
                67.601,
                67.658,
                67.713,
                67.769,
                67.825,
                67.88,
                67.936,
                67.991,
                68.046,
                68.101,
                68.155,
                68.21,
                68.264,
                68.319,
                68.372,
                68.426,
                68.48,
                68.534,
                68.587,
                68.641,
                68.694,
                68.747,
                68.8,
                68.853,
                68.906,
                68.958,
                69.011,
                69.063,
                69.116,
                69.167,
                69.22,
                69.272,
                69.324,
                69.376,
                69.427,
                69.478,
                69.53,
                69.581,
                69.633,
                69.684,
                69.735,
                69.786,
                69.837,
                69.887,
                69.938,
                69.988,
                70.039,
                70.089,
                70.14,
                70.19,
                70.24,
                70.29,
                70.34,
                70.39,
                70.44,
                70.489,
                70.539,
                70.589,
                70.638,
                70.687,
                70.737,
                70.786,
                70.835,
                70.884,
                70.933,
                70.982,
                71.031,
                71.079,
                71.128,
                71.177,
                71.225,
                71.274,
                71.322,
                71.37,
                71.418,
                71.467,
                71.515,
                71.563,
                71.611,
                71.659,
                71.707,
                71.755,
                71.802,
                71.85,
                71.897,
                71.945,
                71.993,
                72.04,
                72.087,
                72.134,
                72.181,
                72.228,
                72.276,
                72.323,
                72.37,
                72.417,
                72.463,
                72.511,
                72.557,
                72.604,
                72.65,
                72.697,
                72.744,
                72.79,
                72.836,
                72.882,
                72.928,
                72.975,
                73.021,
                73.067,
                73.113,
                73.159,
                73.205,
                73.251,
                73.297,
                73.343,
                73.388,
                73.434,
                73.48,
                73.525,
                73.571,
                73.616,
                73.662,
                73.707,
                73.752,
                73.798,
                73.843,
                73.887,
                73.933,
                73.978,
                74.022,
                74.068,
                74.112,
                74.157,
                74.202,
                74.247,
                74.291,
                74.336,
                74.381,
                74.426,
                74.47,
                74.514,
                74.559,
                74.603,
                74.647,
                74.691,
                74.735,
                74.78,
                74.824,
                74.867,
                74.912,
                74.955,
                75,
                75.043,
                75.087,
                75.131,
                75.174,
                75.218,
                75.261,
                75.305,
                75.349,
                75.392,
                75.435,
                75.478,
                75.522,
                75.565,
                75.609,
                75.652,
                75.695,
                75.738,
                75.781,
                75.824,
                75.866,
                75.91,
                75.952,
                75.995,
                76.038,
                76.081,
                76.123,
                76.166,
                76.208,
                76.251,
                76.293,
                76.336,
                76.378,
                76.42,
                76.462,
                76.505,
                76.547,
                76.589,
                76.631,
                76.673,
                76.715,
                76.757,
                76.799,
                76.841,
                76.883,
                76.925,
                76.967,
                77.008,
                77.05,
                77.091,
                77.133,
                77.174,
                77.216,
                77.257,
                77.299,
                77.341,
                77.382,
                77.423,
                77.464,
                77.505,
                77.546,
                77.588,
                77.628,
                77.67,
                77.711,
                77.751,
                77.793,
                77.833,
                77.874,
                77.914,
                77.956,
                77.997,
                78.037,
                78.078,
                78.118,
                78.159,
                78.2,
                78.24,
                78.28,
                78.32,
                78.361,
                78.401,
                78.441,
                78.482,
                78.521,
                78.562,
                78.602,
                78.642,
                78.682,
                78.721,
                78.762,
                78.802,
                78.841,
                78.881,
                78.921,
                78.961,
                79.001,
                79.04,
                79.08,
                79.12,
                79.159,
                79.198,
                79.238,
                79.277,
                79.317,
                79.356,
                79.395,
                79.435,
                79.473,
                79.513,
                79.552,
                79.591,
                79.63,
                79.67,
                79.708,
                79.747,
                79.787,
                79.825,
                79.864,
                79.903,
                79.941,
                79.98,
                80.019,
                80.058,
                80.096,
                80.135,
                80.173,
                80.212,
                80.25,
                80.289,
                80.328,
                80.365,
                80.404,
                80.443,
                80.48,
                80.519,
                80.557,
                80.595,
                80.633,
                80.672,
                80.709,
                80.748,
                80.786,
                80.824,
                80.862,
                80.9,
                80.938,
                80.975,
                81.013,
                81.051,
                81.088,
                81.126,
                81.164,
                81.201,
                81.239,
                81.277,
                81.314,
                81.352,
                81.39,
                81.426,
                81.464,
                81.502,
                81.539,
                81.576,
                81.614,
                81.651,
                81.688,
                81.725,
                81.763,
                81.799,
                81.836,
                81.874,
                81.91,
                81.947,
                81.985,
                82.022,
                82.058,
                82.095,
                82.132,
                82.169,
                82.206,
                82.243,
                82.279,
                82.316,
                82.353,
                82.389,
                82.425,
                82.462,
                82.499,
                82.535,
                82.571,
                82.608,
                82.645,
                82.681,
                82.717,
                82.754,
                82.789,
                82.826,
                82.862,
                82.899,
                82.934,
                82.971,
                83.007,
                83.042,
                83.079,
                83.115,
                83.151,
                83.186,
                83.223,
                83.259,
                83.294,
                83.33,
                83.366,
                83.402,
                83.437,
                83.473,
                83.509,
                83.544,
                83.58,
                83.616,
                83.651,
                83.686,
                83.722,
                83.758,
                83.793,
                83.828,
                83.864,
                83.899,
                83.934,
                83.969,
                84.005,
                84.04,
                84.075,
                84.11,
                84.146,
                84.181,
                84.216,
                84.251,
                84.286,
                84.321,
                84.356,
                84.391,
                84.426,
                84.46,
                84.495,
                84.531,
                84.566,
                84.6,
                84.635,
                84.67,
                84.705,
                84.739,
                84.774,
                84.808,
                84.843,
                84.877,
                84.912,
                84.947,
                84.981,
                85.015,
                85.05,
                85.084,
                85.118,
                85.153,
                85.187,
                85.222,
                85.256,
                85.29,
                85.324,
                85.359,
                85.392,
                85.427,
                85.461,
                85.495,
                85.529,
                85.563,
                85.597,
                85.632,
                85.665,
                85.699,
                85.733,
                85.767,
                85.8,
                85.835,
                85.869,
                85.903,
                85.936,
                85.97,
                86.004,
                86.037,
                86.07,
                86.104,
                86.138,
                86.171,
                86.205,
                86.239,
                86.272,
                86.305,
                86.339,
                86.373,
                86.406,
                86.439,
                86.472,
                86.506,
                86.539,
                86.572,
                86.605,
                86.639,
                86.672,
                86.705,
                86.738,
                86.772,
                86.805,
                86.837,
                86.871,
                86.904,
                86.937,
                86.969,
                87.003,
                87.036,
                87.069,
                87.101,
                87.134,
                87.167,
                87.2,
                87.232,
                87.265,
                87.298,
                87.331,
                87.363,
                87.396,
                87.429,
                87.461,
                87.493,
                87.526,
                87.559,
                87.592,
                87.623,
                87.656,
                87.689,
                87.721,
                87.753,
                87.786,
                87.818,
                87.851,
                87.882,
                87.915,
                87.947,
                87.979,
                88.011,
                88.043,
                88.076,
                88.108,
                88.139,
                88.172,
                88.204,
                88.236,
                88.267,
                88.3,
                88.332,
                88.364,
                88.395,
                88.427,
                88.459,
                88.491,
                88.522,
                88.554,
                88.586,
                88.617,
                88.649,
                88.681,
                88.713,
                88.744,
                88.776,
                88.808,
                88.839,
                88.87,
                88.902,
                88.934,
                88.965,
                88.996,
                89.028,
                89.059,
                89.091,
                89.122,
                89.153,
                89.185,
                89.216,
                89.247,
                89.278,
                89.31,
                89.341,
                89.371,
                89.403,
                89.434,
                89.465,
                89.496,
                89.527,
                89.558,
                89.589,
                89.62,
                89.651,
                89.682,
                89.713,
                89.744,
                89.074,
                89.105,
                89.136,
                89.168,
                89.198,
                89.229,
                89.26,
                89.29,
                89.321,
                89.351,
                89.382,
                89.412,
                89.443,
                89.474,
                89.505,
                89.535,
                89.565,
                89.596,
                89.627,
                89.657,
                89.687,
                89.718,
                89.748,
                89.778,
                89.809,
                89.839,
                89.869,
                89.899,
                89.93,
                89.961,
                89.99,
                90.02,
                90.051,
                90.08,
                90.111,
                90.141,
                90.171,
                90.201,
                90.231,
                90.261,
                90.292,
                90.321,
                90.351,
                90.381,
                90.411,
                90.441,
                90.471,
                90.501,
                90.53,
                90.56,
                90.59,
                90.619,
                90.649,
                90.679,
                90.709,
                90.738,
                90.768,
                90.798,
                90.827,
                90.857,
                90.887,
                90.916,
                90.945,
                90.975,
                91.005,
                91.035,
                91.063,
                91.093,
                91.123,
                91.151,
                91.181,
                91.211,
                91.24,
                91.269,
                91.298,
                91.328,
                91.357,
                91.386,
                91.415,
                91.444,
                91.473,
                91.503,
                91.532,
                91.56,
                91.59,
                91.619,
                91.647,
                91.677,
                91.706,
                91.735,
                91.764,
                91.793,
                91.822,
                91.85,
                91.879,
                91.908,
                91.937,
                91.965,
                91.994,
                92.023,
                92.052,
                92.08,
                92.109,
                92.137,
                92.166,
                92.195,
                92.224,
                92.252,
                92.281,
                92.31,
                92.337,
                92.366,
                92.395,
                92.423,
                92.451,
                92.48,
                92.509,
                92.536,
                92.565,
                92.594,
                92.621,
                92.65,
                92.678,
                92.706,
                92.734,
                92.763,
                92.791,
                92.819,
                92.847,
                92.876,
                92.903,
                92.931,
                92.96,
                92.987,
                93.015,
                93.044,
                93.071,
                93.099,
                93.127,
                93.155,
                93.183,
                93.211,
                93.239,
                93.266,
                93.294,
                93.322,
                93.349,
                93.377,
                93.405,
                93.432,
                93.46,
                93.488,
                93.515,
                93.543,
                93.571,
                93.598,
                93.626,
                93.654,
                93.681,
                93.708,
                93.736,
                93.764,
                93.791,
                93.818,
                93.846,
                93.873,
                93.9,
                93.928,
                93.955,
                93.982,
                94.01,
                94.036,
                94.064,
                94.091,
                94.118,
                94.145,
                94.173,
                94.199,
                94.227,
                94.254,
                94.281,
                94.308,
                94.335,
                94.362,
                94.389,
                94.416,
                94.442,
                94.47,
                94.497,
                94.523,
                94.55,
                94.577,
                94.604,
                94.631,
                94.658,
                94.684,
                94.711,
                94.738,
                94.764,
                94.791,
                94.818,
                94.844,
                94.871,
                94.898,
                94.924,
                94.951,
                94.978,
                95.004,
                95.031,
                95.058,
                95.083,
                95.11,
                95.137,
                95.163,
                95.19,
                95.216,
                95.242,
                95.269,
                95.295,
                95.321,
                95.348,
                95.374,
                95.4,
                95.427,
                95.452,
                95.479,
                95.505,
                95.531,
                95.558,
                95.584,
                95.61,
                95.636,
                95.662,
                95.688,
                95.714,
                95.741,
                95.766,
                95.793,
                95.818,
                95.844,
                95.871,
                95.896,
                95.922,
                95.949,
                95.974,
                96,
                96.026,
                96.052,
                96.078,
                96.103,
                96.129,
                96.155,
                96.18,
                96.207,
                96.233,
                96.258,
                96.284,
                96.31,
                96.335,
                96.361,
                96.386,
                96.412,
                96.438,
                96.463,
                96.489,
                96.515,
                96.54,
                96.566,
                96.591,
                96.617,
                96.643,
                96.668,
                96.693,
                96.719,
                96.744,
                96.77,
                96.795,
                96.82,
                96.846,
                96.871,
                96.897,
                96.922,
                96.947,
                96.973,
                96.998,
                97.023,
                97.049,
                97.074,
                97.099,
                97.124,
                97.149,
                97.175,
                97.2,
                97.225,
                97.25,
                97.275,
                97.301,
                97.325,
                97.351,
                97.376,
                97.401,
                97.426,
                97.451,
                97.476,
                97.502,
                97.526,
                97.551,
                97.576,
                97.601,
                97.627,
                97.651,
                97.676,
                97.701,
                97.726,
                97.751,
                97.775,
                97.801,
                97.825,
                97.85,
                97.875,
                97.9,
                97.925,
                97.949,
                97.974,
                97.999,
                98.024,
                98.049,
                98.073,
                98.098,
                98.123,
                98.147,
                98.172,
                98.197,
                98.222,
                98.247,
                98.271,
                98.296,
                98.32,
                98.345,
                98.369,
                98.394,
                98.419,
                98.443,
                98.468,
                98.492,
                98.517,
                98.541,
                98.565,
                98.59,
                98.614,
                98.639,
                98.664,
                98.688,
                98.713,
                98.736,
                98.761,
                98.785,
                98.81,
                98.835,
                98.858,
                98.883,
                98.907,
                98.932,
                98.955,
                98.98,
                99.005,
                99.029,
                99.053,
                99.077,
                99.102,
                99.125,
                99.15,
                99.175,
                99.198,
                99.223,
                99.246,
                99.271,
                99.295,
                99.319,
                99.344,
                99.367,
                99.392,
                99.415,
                99.44,
                99.464,
                99.488,
                99.512,
                99.536,
                99.56,
                99.584,
                99.608,
                99.632,
                99.656,
                99.681,
                99.704,
                99.728,
                99.752,
                99.776,
                99.8,
                99.824,
                99.847,
                99.872,
                99.896,
                99.919,
                99.944,
                99.967,
                99.991,
                100.014,
                100.039,
                100.063,
                100.086,
                100.11,
                100.134,
                100.158,
                100.181,
                100.205,
                100.228,
                100.253,
                100.276,
                100.3,
                100.324,
                100.347,
                100.371,
                100.394,
                100.418,
                100.442,
                100.466,
                100.489,
                100.513,
                100.537,
                100.56,
                100.584,
                100.607,
                100.631,
                100.654,
                100.678,
                100.701,
                100.725,
                100.748,
                100.772,
                100.796,
                100.819,
                100.843,
                100.865,
                100.889,
                100.912,
                100.936,
                100.959,
                100.983,
                101.006,
                101.03,
                101.052,
                101.076,
                101.1,
                101.123,
                101.147,
                101.169,
                101.193,
                101.216,
                101.24,
                101.262,
                101.286,
                101.309,
                101.333,
                101.355,
                101.379,
                101.403,
                101.425,
                101.449,
                101.472,
                101.495,
                101.518,
                101.542,
                101.564,
                101.588,
                101.611,
                101.634,
                101.657,
                101.68,
                101.703,
                101.726,
                101.749,
                101.772,
                101.796,
                101.818,
                101.842,
                101.864,
                101.888,
                101.91,
                101.934,
                101.956,
                101.98,
                102.002,
                102.026,
                102.048,
                102.071,
                102.094,
                102.117,
                102.14,
                102.163,
                102.185,
                102.209,
                102.231,
                102.254,
                102.277,
                102.3,
                102.323,
                102.346,
                102.369,
                102.391,
                102.415,
                102.437,
                102.46,
                102.482,
                102.505,
                102.528,
                102.551,
                102.573,
                102.596,
                102.618,
                102.642,
                102.664,
                102.687,
                102.709,
                102.732,
                102.754,
                102.777,
                102.799,
                102.823,
                102.845,
                102.868,
                102.89,
                102.913,
                102.935,
                102.958,
                102.98,
                103.003,
                103.025,
                103.048,
                103.07,
                103.093,
                103.115,
                103.138,
                103.161,
                103.183,
                103.206,
                103.228,
                103.25,
                103.272,
                103.295,
                103.317,
                103.34,
                103.362,
                103.385,
                103.407,
                103.429,
                103.451,
                103.474,
                103.496,
                103.519,
                103.54,
                103.563,
                103.585,
                103.608,
                103.63,
                103.652,
                103.674,
                103.697,
                103.718,
                103.741,
                103.763,
                103.785,
                103.807,
                103.83,
                103.851,
                103.874,
                103.896,
                103.918,
                103.94,
                103.963,
                103.984,
                104.007,
                104.028,
                104.051,
                104.072,
                104.095,
                104.117,
                104.139,
                104.161,
                104.183,
                104.205,
                104.226,
                104.249,
                104.27,
                104.293,
                104.314,
                104.337,
                104.358,
                104.38,
                104.402,
                104.424,
                104.446,
                104.468,
                104.489,
                104.512,
                104.533,
                104.556,
                104.577,
                104.599,
                104.621,
                104.643,
                104.664,
                104.687,
                104.708,
                104.73,
                104.751,
                104.774,
                104.795,
                104.817,
                104.838,
                104.861,
                104.882,
                104.904,
                104.925,
                104.947,
                104.969,
                104.991,
                105.012,
                105.034,
                105.055,
                105.077,
                105.099,
                105.12,
                105.142,
                105.163,
                105.185,
                105.206,
                105.228,
                105.249,
                105.271,
                105.292,
                105.314,
                105.335,
                105.357,
                105.378,
                105.4,
                105.421,
                105.443,
                105.464,
                105.486,
                105.507,
                105.529,
                105.55,
                105.572,
                105.593,
                105.615,
                105.636,
                105.657,
                105.679,
                105.7,
                105.722,
                105.742,
                105.764,
                105.785,
                105.807,
                105.828,
                105.85,
                105.87,
                105.892,
                105.913,
                105.935,
                105.956,
                105.977,
                105.998,
                106.02,
                106.041,
                106.061,
                106.083,
                106.104,
                106.126,
                106.146,
                106.168,
                106.189,
                106.21,
                106.231,
                106.253,
                106.273,
                106.295,
                106.316,
                106.337,
                106.358,
                106.38,
                106.4,
                106.421,
                106.442,
                106.463,
                106.484,
                106.505,
                106.527,
                106.547,
                106.569,
                106.589,
                106.611,
                106.631,
                106.653,
                106.673,
                106.694,
                106.715,
                106.736,
                106.757,
                106.778,
                106.799,
                106.82,
                106.841,
                106.862,
                106.883,
                106.903,
                106.925,
                106.945,
                106.966,
                106.987,
                107.007,
                107.029,
                107.049,
                107.071,
                107.091,
                107.112,
                107.133,
                107.154,
                107.174,
                107.196,
                107.216,
                107.236,
                107.258,
                107.278,
                107.299,
                107.319,
                107.341,
                107.361,
                107.382,
                107.402,
                107.424,
                107.444,
                107.464,
                107.485,
                107.506,
                107.527,
                107.547,
                107.568,
                107.588,
                107.61,
                107.63,
                107.651,
                107.671,
                107.691,
                107.712,
                107.732,
                107.754,
                107.774,
                107.795,
                107.815,
                107.836,
                107.856,
                107.877,
                107.897,
                107.917,
                107.938,
                107.958,
                107.98,
                108,
                108.021,
                108.041,
                108.062,
                108.082,
                108.102,
                108.123,
                108.143,
                108.164,
                108.184,
                108.205,
                108.225,
                108.246,
                108.266,
                108.286,
                108.307,
                108.326,
                108.347,
                108.367,
                108.388,
                108.408,
                108.429,
                108.449,
                108.469,
                108.49,
                108.51,
                108.531,
                108.55,
                108.571,
                108.591,
                108.612,
                108.632,
                108.652,
                108.673,
                108.692,
                108.713,
                108.733,
                108.754,
                108.774,
                108.793,
                108.814,
                108.834,
                108.855,
                108.874,
                108.895,
                108.915,
                108.936,
                108.956,
                108.975,
                108.996,
                109.016,
                109.036,
                109.056,
                109.077,
                109.097,
                109.116,
                109.137,
                109.157,
                109.177,
                109.197,
                109.218,
                109.237,
                109.258,
                109.278,
                109.297,
                109.318,
                109.338,
                109.358,
                109.378,
                109.398,
                109.418,
                109.438,
                109.458,
                109.478,
                109.498,
                109.518,
                109.538,
                109.558,
                109.578,
                109.598,
                109.618,
                109.638,
                109.658,
                109.678,
                109.698,
                109.717,
                109.738,
                109.757,
                109.778,
                109.797,
                109.818,
                109.837,
                109.857,
                109.877,
                109.897,
                109.917,
                109.937,
                109.957,
                109.976,
                109.996,
                110.016,
                110.036,
                110.056,
                110.076,
                110.096,
                110.115,
                110.135,
                110.155,
                110.174,
                110.195,
                110.214,
                110.235,
                110.254,
                110.273,
                110.294,
                110.313,
                110.333,
                110.353,
                110.373,
                110.392,
                110.411,
                110.432,
                110.451,
                110.471,
                110.491,
                110.51,
                110.53,
                110.549,
                110.57,
                110.589,
                110.609,
                110.628,
                110.648,
                110.668,
                110.687,
                110.707,
                110.727,
                110.747,
                110.766,
                110.785,
                110.805,
                110.825,
                110.845,
                110.864,
                110.883,
                110.903,
                110.922,
                110.943,
                110.962,
                110.982,
                111.001,
                111.02,
                111.04,
                111.059,
                111.079,
                111.099,
                111.118,
                111.138,
                111.157,
                111.177,
                111.196,
                111.216,
                111.235,
                111.254,
                111.274,
                111.293,
                111.313,
                111.332,
                111.351,
                111.371,
                111.39,
                111.41,
                111.429,
                111.449,
                111.468,
                111.487,
                111.507,
                111.526,
                111.546,
                111.565,
                111.584,
                111.604,
                111.623,
                111.643,
                111.662,
                111.681,
                111.701,
                111.72,
                111.74,
                111.758,
                111.778,
                111.797,
                111.816,
                111.836,
                111.855,
                111.875,
                111.894,
                111.912,
                111.932,
                111.951,
                111.971,
                111.99,
                112.009,
                112.028,
                112.047,
                112.067,
                112.086,
                112.106,
                112.124,
                112.143,
                112.163,
                112.182,
                112.201,
                112.22,
                112.239,
                112.259,
                112.277,
                112.297,
                112.316,
                112.335,
                112.354,
                112.373,
                112.393,
                112.411,
                112.43,
                112.45,
                112.468,
                112.488,
                112.507,
                112.527,
                112.545,
                112.564,
                112.584,
                112.602,
                112.622,
                112.64,
                112.659,
                112.679,
                112.697,
                112.717,
                112.735,
                112.754,
                112.774,
                112.792,
                112.812,
                112.83,
                112.849,
                112.868,
                112.887,
                112.907,
                112.925,
                112.944,
                112.963,
                112.982,
                113.001,
                113.02,
                113.038,
                113.058,
                113.076,
                113.096,
                113.114,
                113.134,
                113.152,
                113.171,
                113.19,
                113.208,
                113.228,
                113.246,
                113.265,
                113.284,
                113.303,
                113.322,
                113.341,
                113.359,
                113.378,
                113.397,
                113.416,
                113.434,
                113.453,
                113.472,
                113.491,
                113.51,
                113.528,
                113.547,
                113.566,
                113.584,
                113.604,
                113.622,
                113.64,
                113.66,
                113.678,
                113.697,
                113.716,
                113.734,
                113.753,
                113.771,
                113.791,
                113.809,
                113.827,
                113.847,
                113.865,
                113.884,
                113.902,
                113.92,
                113.94,
                113.958,
                113.977,
                113.995,
                114.014,
                114.033,
                114.051,
                114.07,
                114.088,
                114.107,
                114.126,
                114.144,
                114.163,
                114.181,
                114.199,
                114.219,
                114.237,
                114.256,
                114.274,
                114.292,
                114.311,
                114.329,
                114.347,
                114.367,
                114.385,
                114.404,
                114.422,
                114.44,
                114.459,
                114.477,
                114.496,
                114.514,
                114.532,
                114.552,
                114.57,
                114.589,
                114.607,
                114.625,
                114.644,
                114.662,
                114.681,
                114.699,
                114.717,
                114.736,
                114.754,
                114.773,
                114.791,
                114.809,
                114.828,
                114.846,
                114.865,
                114.883,
                114.901
            ],
            P90: [
                51.535,
                51.708,
                51.88,
                52.053,
                52.226,
                52.398,
                52.571,
                52.743,
                52.916,
                53.088,
                53.261,
                53.434,
                53.606,
                53.778,
                53.951,
                54.094,
                54.237,
                54.378,
                54.52,
                54.659,
                54.799,
                54.937,
                55.074,
                55.21,
                55.346,
                55.479,
                55.613,
                55.745,
                55.876,
                56.006,
                56.135,
                56.263,
                56.391,
                56.517,
                56.643,
                56.767,
                56.891,
                57.013,
                57.134,
                57.255,
                57.374,
                57.494,
                57.611,
                57.728,
                57.844,
                57.96,
                58.074,
                58.187,
                58.3,
                58.412,
                58.523,
                58.632,
                58.742,
                58.85,
                58.958,
                59.065,
                59.171,
                59.276,
                59.381,
                59.484,
                59.587,
                59.69,
                59.791,
                59.892,
                59.992,
                60.093,
                60.192,
                60.29,
                60.387,
                60.484,
                60.581,
                60.677,
                60.772,
                60.867,
                60.961,
                61.054,
                61.147,
                61.239,
                61.331,
                61.422,
                61.513,
                61.603,
                61.693,
                61.781,
                61.87,
                61.957,
                62.045,
                62.132,
                62.218,
                62.304,
                62.389,
                62.474,
                62.558,
                62.642,
                62.725,
                62.808,
                62.891,
                62.973,
                63.054,
                63.136,
                63.216,
                63.297,
                63.376,
                63.455,
                63.534,
                63.613,
                63.691,
                63.768,
                63.846,
                63.922,
                63.998,
                64.074,
                64.149,
                64.224,
                64.299,
                64.373,
                64.447,
                64.52,
                64.593,
                64.666,
                64.738,
                64.81,
                64.882,
                64.953,
                65.023,
                65.093,
                65.163,
                65.232,
                65.301,
                65.37,
                65.439,
                65.507,
                65.575,
                65.642,
                65.708,
                65.776,
                65.842,
                65.908,
                65.973,
                66.038,
                66.104,
                66.168,
                66.232,
                66.297,
                66.36,
                66.424,
                66.487,
                66.55,
                66.612,
                66.675,
                66.737,
                66.798,
                66.86,
                66.921,
                66.982,
                67.043,
                67.103,
                67.164,
                67.223,
                67.284,
                67.343,
                67.402,
                67.461,
                67.52,
                67.579,
                67.637,
                67.695,
                67.753,
                67.811,
                67.868,
                67.926,
                67.984,
                68.04,
                68.097,
                68.154,
                68.21,
                68.266,
                68.323,
                68.379,
                68.434,
                68.49,
                68.546,
                68.601,
                68.656,
                68.711,
                68.766,
                68.821,
                68.876,
                68.93,
                68.984,
                69.039,
                69.092,
                69.147,
                69.2,
                69.254,
                69.307,
                69.361,
                69.414,
                69.468,
                69.52,
                69.573,
                69.626,
                69.679,
                69.731,
                69.784,
                69.836,
                69.889,
                69.941,
                69.993,
                70.044,
                70.096,
                70.148,
                70.2,
                70.251,
                70.303,
                70.354,
                70.406,
                70.456,
                70.507,
                70.558,
                70.609,
                70.66,
                70.711,
                70.761,
                70.812,
                70.862,
                70.913,
                70.963,
                71.013,
                71.063,
                71.113,
                71.163,
                71.213,
                71.263,
                71.313,
                71.362,
                71.412,
                71.461,
                71.511,
                71.56,
                71.609,
                71.658,
                71.707,
                71.756,
                71.805,
                71.854,
                71.903,
                71.951,
                72,
                72.048,
                72.097,
                72.146,
                72.194,
                72.243,
                72.291,
                72.339,
                72.387,
                72.435,
                72.483,
                72.531,
                72.579,
                72.627,
                72.674,
                72.722,
                72.769,
                72.817,
                72.865,
                72.912,
                72.96,
                73.007,
                73.054,
                73.102,
                73.149,
                73.196,
                73.242,
                73.289,
                73.337,
                73.383,
                73.43,
                73.476,
                73.523,
                73.57,
                73.617,
                73.663,
                73.709,
                73.756,
                73.802,
                73.848,
                73.895,
                73.941,
                73.987,
                74.033,
                74.08,
                74.125,
                74.171,
                74.217,
                74.263,
                74.309,
                74.354,
                74.4,
                74.446,
                74.491,
                74.537,
                74.582,
                74.627,
                74.673,
                74.718,
                74.763,
                74.809,
                74.854,
                74.898,
                74.944,
                74.989,
                75.034,
                75.079,
                75.123,
                75.169,
                75.213,
                75.257,
                75.303,
                75.347,
                75.392,
                75.436,
                75.48,
                75.525,
                75.569,
                75.614,
                75.658,
                75.702,
                75.746,
                75.79,
                75.835,
                75.878,
                75.923,
                75.966,
                76.01,
                76.054,
                76.097,
                76.142,
                76.185,
                76.229,
                76.272,
                76.316,
                76.359,
                76.403,
                76.446,
                76.489,
                76.533,
                76.576,
                76.619,
                76.662,
                76.706,
                76.748,
                76.792,
                76.834,
                76.878,
                76.92,
                76.963,
                77.006,
                77.049,
                77.091,
                77.134,
                77.176,
                77.219,
                77.261,
                77.304,
                77.346,
                77.389,
                77.431,
                77.474,
                77.517,
                77.558,
                77.601,
                77.643,
                77.685,
                77.727,
                77.769,
                77.811,
                77.853,
                77.894,
                77.937,
                77.979,
                78.02,
                78.062,
                78.103,
                78.145,
                78.186,
                78.228,
                78.269,
                78.311,
                78.353,
                78.394,
                78.436,
                78.476,
                78.518,
                78.559,
                78.6,
                78.642,
                78.682,
                78.724,
                78.764,
                78.806,
                78.847,
                78.887,
                78.928,
                78.969,
                79.01,
                79.051,
                79.091,
                79.132,
                79.172,
                79.213,
                79.254,
                79.294,
                79.335,
                79.374,
                79.415,
                79.456,
                79.496,
                79.536,
                79.577,
                79.616,
                79.657,
                79.696,
                79.737,
                79.777,
                79.817,
                79.857,
                79.897,
                79.936,
                79.976,
                80.017,
                80.056,
                80.096,
                80.135,
                80.175,
                80.215,
                80.254,
                80.294,
                80.333,
                80.372,
                80.412,
                80.452,
                80.49,
                80.53,
                80.57,
                80.608,
                80.648,
                80.687,
                80.726,
                80.765,
                80.803,
                80.843,
                80.882,
                80.92,
                80.96,
                80.999,
                81.037,
                81.076,
                81.115,
                81.153,
                81.192,
                81.231,
                81.269,
                81.308,
                81.347,
                81.385,
                81.424,
                81.462,
                81.501,
                81.539,
                81.578,
                81.616,
                81.654,
                81.692,
                81.731,
                81.768,
                81.807,
                81.845,
                81.883,
                81.921,
                81.959,
                81.997,
                82.035,
                82.073,
                82.11,
                82.149,
                82.187,
                82.225,
                82.262,
                82.3,
                82.338,
                82.375,
                82.413,
                82.451,
                82.488,
                82.525,
                82.563,
                82.6,
                82.638,
                82.675,
                82.713,
                82.75,
                82.787,
                82.825,
                82.861,
                82.899,
                82.936,
                82.973,
                83.01,
                83.048,
                83.085,
                83.121,
                83.159,
                83.196,
                83.232,
                83.269,
                83.306,
                83.343,
                83.38,
                83.417,
                83.454,
                83.49,
                83.527,
                83.564,
                83.6,
                83.636,
                83.673,
                83.71,
                83.746,
                83.782,
                83.819,
                83.856,
                83.892,
                83.928,
                83.965,
                84,
                84.037,
                84.073,
                84.11,
                84.145,
                84.182,
                84.218,
                84.253,
                84.29,
                84.326,
                84.362,
                84.398,
                84.434,
                84.47,
                84.506,
                84.541,
                84.577,
                84.613,
                84.648,
                84.684,
                84.72,
                84.756,
                84.791,
                84.827,
                84.863,
                84.899,
                84.934,
                84.969,
                85.005,
                85.041,
                85.075,
                85.111,
                85.147,
                85.181,
                85.217,
                85.253,
                85.288,
                85.323,
                85.358,
                85.393,
                85.429,
                85.463,
                85.499,
                85.534,
                85.569,
                85.603,
                85.639,
                85.674,
                85.708,
                85.743,
                85.778,
                85.814,
                85.848,
                85.883,
                85.918,
                85.953,
                85.987,
                86.022,
                86.057,
                86.091,
                86.125,
                86.16,
                86.195,
                86.23,
                86.263,
                86.298,
                86.333,
                86.368,
                86.401,
                86.436,
                86.471,
                86.505,
                86.539,
                86.573,
                86.608,
                86.642,
                86.676,
                86.71,
                86.744,
                86.779,
                86.812,
                86.846,
                86.881,
                86.914,
                86.948,
                86.982,
                87.017,
                87.05,
                87.084,
                87.118,
                87.152,
                87.185,
                87.219,
                87.253,
                87.287,
                87.32,
                87.354,
                87.388,
                87.422,
                87.455,
                87.489,
                87.522,
                87.556,
                87.589,
                87.623,
                87.656,
                87.69,
                87.723,
                87.756,
                87.79,
                87.823,
                87.856,
                87.89,
                87.923,
                87.957,
                87.989,
                88.022,
                88.056,
                88.089,
                88.121,
                88.155,
                88.188,
                88.221,
                88.254,
                88.287,
                88.32,
                88.353,
                88.385,
                88.419,
                88.452,
                88.485,
                88.517,
                88.55,
                88.583,
                88.616,
                88.648,
                88.681,
                88.714,
                88.746,
                88.778,
                88.811,
                88.844,
                88.877,
                88.908,
                88.941,
                88.974,
                89.006,
                89.038,
                89.071,
                89.103,
                89.136,
                89.167,
                89.2,
                89.232,
                89.265,
                89.296,
                89.329,
                89.361,
                89.393,
                89.425,
                89.457,
                89.49,
                89.521,
                89.553,
                89.586,
                89.618,
                89.649,
                89.681,
                89.713,
                89.745,
                89.776,
                89.808,
                89.841,
                89.873,
                89.904,
                89.936,
                89.968,
                90,
                90.03,
                90.062,
                90.094,
                90.126,
                90.157,
                90.189,
                90.221,
                90.252,
                90.283,
                90.315,
                90.347,
                90.377,
                90.409,
                90.44,
                90.472,
                90.503,
                90.534,
                89.865,
                89.897,
                89.928,
                89.96,
                89.99,
                90.022,
                90.053,
                90.085,
                90.115,
                90.146,
                90.178,
                90.208,
                90.239,
                90.27,
                90.302,
                90.332,
                90.363,
                90.394,
                90.425,
                90.456,
                90.487,
                90.518,
                90.548,
                90.579,
                90.61,
                90.641,
                90.671,
                90.702,
                90.733,
                90.764,
                90.794,
                90.825,
                90.855,
                90.885,
                90.916,
                90.947,
                90.978,
                91.007,
                91.038,
                91.069,
                91.099,
                91.129,
                91.16,
                91.19,
                91.22,
                91.251,
                91.281,
                91.312,
                91.341,
                91.372,
                91.402,
                91.432,
                91.462,
                91.492,
                91.523,
                91.552,
                91.583,
                91.613,
                91.642,
                91.673,
                91.703,
                91.733,
                91.762,
                91.793,
                91.823,
                91.853,
                91.882,
                91.912,
                91.942,
                91.971,
                92.001,
                92.031,
                92.061,
                92.09,
                92.12,
                92.15,
                92.179,
                92.209,
                92.239,
                92.268,
                92.298,
                92.327,
                92.357,
                92.386,
                92.416,
                92.446,
                92.474,
                92.504,
                92.534,
                92.563,
                92.592,
                92.622,
                92.651,
                92.68,
                92.709,
                92.739,
                92.768,
                92.797,
                92.826,
                92.856,
                92.884,
                92.913,
                92.943,
                92.971,
                93,
                93.03,
                93.059,
                93.087,
                93.117,
                93.146,
                93.174,
                93.203,
                93.232,
                93.26,
                93.29,
                93.319,
                93.348,
                93.376,
                93.405,
                93.434,
                93.462,
                93.491,
                93.52,
                93.548,
                93.577,
                93.605,
                93.634,
                93.662,
                93.691,
                93.72,
                93.748,
                93.776,
                93.805,
                93.833,
                93.861,
                93.89,
                93.918,
                93.946,
                93.975,
                94.004,
                94.031,
                94.06,
                94.088,
                94.116,
                94.144,
                94.173,
                94.2,
                94.229,
                94.257,
                94.284,
                94.313,
                94.341,
                94.369,
                94.397,
                94.425,
                94.452,
                94.481,
                94.509,
                94.537,
                94.564,
                94.593,
                94.621,
                94.648,
                94.676,
                94.704,
                94.731,
                94.759,
                94.787,
                94.814,
                94.842,
                94.87,
                94.897,
                94.925,
                94.953,
                94.98,
                95.008,
                95.036,
                95.062,
                95.09,
                95.118,
                95.145,
                95.173,
                95.2,
                95.227,
                95.255,
                95.283,
                95.309,
                95.337,
                95.364,
                95.391,
                95.419,
                95.446,
                95.473,
                95.5,
                95.528,
                95.554,
                95.582,
                95.609,
                95.636,
                95.663,
                95.691,
                95.717,
                95.744,
                95.772,
                95.798,
                95.825,
                95.853,
                95.879,
                95.906,
                95.933,
                95.959,
                95.987,
                96.014,
                96.04,
                96.067,
                96.094,
                96.12,
                96.148,
                96.175,
                96.201,
                96.228,
                96.254,
                96.281,
                96.308,
                96.334,
                96.361,
                96.388,
                96.414,
                96.441,
                96.467,
                96.493,
                96.52,
                96.547,
                96.573,
                96.6,
                96.626,
                96.652,
                96.679,
                96.705,
                96.731,
                96.758,
                96.784,
                96.811,
                96.837,
                96.863,
                96.89,
                96.916,
                96.942,
                96.968,
                96.994,
                97.02,
                97.047,
                97.072,
                97.099,
                97.126,
                97.151,
                97.177,
                97.204,
                97.229,
                97.256,
                97.281,
                97.308,
                97.334,
                97.359,
                97.386,
                97.412,
                97.437,
                97.464,
                97.489,
                97.515,
                97.542,
                97.567,
                97.593,
                97.619,
                97.644,
                97.671,
                97.696,
                97.722,
                97.748,
                97.773,
                97.799,
                97.826,
                97.85,
                97.877,
                97.902,
                97.928,
                97.954,
                97.979,
                98.005,
                98.03,
                98.056,
                98.082,
                98.107,
                98.133,
                98.157,
                98.183,
                98.209,
                98.234,
                98.26,
                98.286,
                98.311,
                98.337,
                98.361,
                98.387,
                98.413,
                98.438,
                98.464,
                98.488,
                98.514,
                98.54,
                98.565,
                98.59,
                98.615,
                98.641,
                98.666,
                98.691,
                98.717,
                98.741,
                98.767,
                98.792,
                98.817,
                98.843,
                98.867,
                98.893,
                98.918,
                98.943,
                98.968,
                98.993,
                99.018,
                99.044,
                99.068,
                99.094,
                99.118,
                99.144,
                99.169,
                99.193,
                99.219,
                99.243,
                99.269,
                99.293,
                99.318,
                99.344,
                99.368,
                99.393,
                99.418,
                99.443,
                99.468,
                99.492,
                99.518,
                99.542,
                99.567,
                99.593,
                99.617,
                99.642,
                99.666,
                99.691,
                99.715,
                99.741,
                99.766,
                99.79,
                99.815,
                99.839,
                99.864,
                99.888,
                99.913,
                99.939,
                99.962,
                99.988,
                100.011,
                100.037,
                100.061,
                100.086,
                100.111,
                100.135,
                100.16,
                100.184,
                100.209,
                100.232,
                100.257,
                100.282,
                100.306,
                100.331,
                100.355,
                100.38,
                100.404,
                100.429,
                100.454,
                100.477,
                100.502,
                100.526,
                100.551,
                100.574,
                100.599,
                100.624,
                100.648,
                100.673,
                100.696,
                100.721,
                100.745,
                100.77,
                100.793,
                100.818,
                100.843,
                100.866,
                100.891,
                100.914,
                100.939,
                100.963,
                100.987,
                101.012,
                101.036,
                101.06,
                101.084,
                101.108,
                101.132,
                101.156,
                101.18,
                101.205,
                101.228,
                101.253,
                101.277,
                101.3,
                101.325,
                101.348,
                101.373,
                101.396,
                101.421,
                101.444,
                101.469,
                101.493,
                101.516,
                101.541,
                101.564,
                101.588,
                101.612,
                101.636,
                101.659,
                101.684,
                101.707,
                101.731,
                101.756,
                101.779,
                101.803,
                101.826,
                101.851,
                101.874,
                101.898,
                101.921,
                101.946,
                101.969,
                101.993,
                102.016,
                102.04,
                102.065,
                102.088,
                102.112,
                102.135,
                102.159,
                102.182,
                102.206,
                102.229,
                102.254,
                102.276,
                102.301,
                102.323,
                102.348,
                102.372,
                102.395,
                102.419,
                102.442,
                102.466,
                102.489,
                102.513,
                102.536,
                102.56,
                102.583,
                102.606,
                102.629,
                102.653,
                102.676,
                102.7,
                102.723,
                102.747,
                102.771,
                102.794,
                102.818,
                102.84,
                102.864,
                102.887,
                102.911,
                102.934,
                102.957,
                102.98,
                103.004,
                103.027,
                103.05,
                103.073,
                103.097,
                103.12,
                103.143,
                103.166,
                103.19,
                103.212,
                103.236,
                103.259,
                103.282,
                103.306,
                103.329,
                103.352,
                103.375,
                103.399,
                103.421,
                103.445,
                103.467,
                103.491,
                103.513,
                103.537,
                103.559,
                103.583,
                103.605,
                103.629,
                103.651,
                103.675,
                103.697,
                103.721,
                103.743,
                103.767,
                103.789,
                103.813,
                103.835,
                103.858,
                103.881,
                103.904,
                103.927,
                103.95,
                103.972,
                103.996,
                104.018,
                104.041,
                104.064,
                104.087,
                104.109,
                104.133,
                104.156,
                104.178,
                104.202,
                104.224,
                104.247,
                104.269,
                104.292,
                104.315,
                104.338,
                104.36,
                104.383,
                104.405,
                104.429,
                104.451,
                104.474,
                104.496,
                104.519,
                104.541,
                104.564,
                104.586,
                104.61,
                104.632,
                104.655,
                104.677,
                104.7,
                104.722,
                104.745,
                104.767,
                104.79,
                104.812,
                104.835,
                104.857,
                104.88,
                104.902,
                104.925,
                104.947,
                104.97,
                104.992,
                105.015,
                105.036,
                105.059,
                105.081,
                105.104,
                105.126,
                105.149,
                105.171,
                105.194,
                105.215,
                105.237,
                105.26,
                105.282,
                105.305,
                105.326,
                105.349,
                105.371,
                105.394,
                105.415,
                105.438,
                105.46,
                105.483,
                105.504,
                105.527,
                105.549,
                105.571,
                105.593,
                105.616,
                105.637,
                105.66,
                105.682,
                105.704,
                105.726,
                105.749,
                105.77,
                105.793,
                105.814,
                105.837,
                105.858,
                105.881,
                105.903,
                105.925,
                105.947,
                105.969,
                105.991,
                106.013,
                106.035,
                106.057,
                106.079,
                106.101,
                106.123,
                106.144,
                106.166,
                106.188,
                106.21,
                106.232,
                106.254,
                106.275,
                106.298,
                106.319,
                106.342,
                106.363,
                106.385,
                106.407,
                106.429,
                106.45,
                106.473,
                106.494,
                106.516,
                106.538,
                106.56,
                106.581,
                106.603,
                106.625,
                106.647,
                106.668,
                106.689,
                106.712,
                106.733,
                106.755,
                106.776,
                106.798,
                106.819,
                106.842,
                106.863,
                106.885,
                106.906,
                106.928,
                106.949,
                106.972,
                106.993,
                107.015,
                107.036,
                107.058,
                107.079,
                107.1,
                107.122,
                107.143,
                107.165,
                107.186,
                107.208,
                107.229,
                107.251,
                107.272,
                107.294,
                107.315,
                107.337,
                107.358,
                107.38,
                107.401,
                107.423,
                107.444,
                107.465,
                107.487,
                107.507,
                107.529,
                107.55,
                107.572,
                107.593,
                107.615,
                107.636,
                107.658,
                107.678,
                107.7,
                107.721,
                107.742,
                107.764,
                107.784,
                107.806,
                107.827,
                107.849,
                107.87,
                107.892,
                107.912,
                107.934,
                107.955,
                107.977,
                107.997,
                108.018,
                108.04,
                108.06,
                108.082,
                108.103,
                108.124,
                108.145,
                108.167,
                108.187,
                108.209,
                108.23,
                108.252,
                108.272,
                108.292,
                108.314,
                108.335,
                108.356,
                108.377,
                108.399,
                108.419,
                108.441,
                108.461,
                108.483,
                108.503,
                108.524,
                108.546,
                108.566,
                108.588,
                108.608,
                108.63,
                108.65,
                108.672,
                108.692,
                108.714,
                108.734,
                108.754,
                108.776,
                108.796,
                108.818,
                108.838,
                108.86,
                108.88,
                108.902,
                108.922,
                108.943,
                108.964,
                108.984,
                109.005,
                109.026,
                109.047,
                109.068,
                109.089,
                109.109,
                109.131,
                109.151,
                109.171,
                109.193,
                109.213,
                109.234,
                109.254,
                109.276,
                109.296,
                109.317,
                109.338,
                109.358,
                109.379,
                109.399,
                109.421,
                109.441,
                109.462,
                109.482,
                109.504,
                109.524,
                109.544,
                109.565,
                109.585,
                109.607,
                109.627,
                109.648,
                109.668,
                109.689,
                109.709,
                109.729,
                109.751,
                109.771,
                109.792,
                109.812,
                109.833,
                109.853,
                109.873,
                109.895,
                109.914,
                109.936,
                109.956,
                109.977,
                109.997,
                110.018,
                110.038,
                110.058,
                110.079,
                110.099,
                110.12,
                110.14,
                110.161,
                110.181,
                110.201,
                110.222,
                110.242,
                110.263,
                110.283,
                110.304,
                110.324,
                110.345,
                110.365,
                110.385,
                110.406,
                110.426,
                110.447,
                110.467,
                110.488,
                110.508,
                110.527,
                110.548,
                110.568,
                110.589,
                110.609,
                110.63,
                110.65,
                110.669,
                110.69,
                110.71,
                110.731,
                110.751,
                110.772,
                110.792,
                110.811,
                110.832,
                110.852,
                110.873,
                110.893,
                110.914,
                110.933,
                110.953,
                110.974,
                110.993,
                111.014,
                111.034,
                111.055,
                111.075,
                111.094,
                111.115,
                111.135,
                111.155,
                111.175,
                111.196,
                111.216,
                111.235,
                111.256,
                111.276,
                111.296,
                111.316,
                111.337,
                111.356,
                111.376,
                111.397,
                111.416,
                111.437,
                111.456,
                111.477,
                111.497,
                111.516,
                111.537,
                111.556,
                111.577,
                111.597,
                111.616,
                111.637,
                111.656,
                111.677,
                111.696,
                111.717,
                111.737,
                111.756,
                111.777,
                111.796,
                111.817,
                111.836,
                111.857,
                111.876,
                111.896,
                111.916,
                111.936,
                111.956,
                111.976,
                111.995,
                112.016,
                112.035,
                112.056,
                112.075,
                112.096,
                112.115,
                112.134,
                112.155,
                112.174,
                112.195,
                112.214,
                112.233,
                112.254,
                112.273,
                112.293,
                112.313,
                112.333,
                112.352,
                112.372,
                112.392,
                112.411,
                112.432,
                112.451,
                112.47,
                112.491,
                112.51,
                112.53,
                112.55,
                112.57,
                112.589,
                112.608,
                112.629,
                112.648,
                112.669,
                112.688,
                112.707,
                112.727,
                112.746,
                112.767,
                112.786,
                112.805,
                112.825,
                112.844,
                112.865,
                112.884,
                112.904,
                112.923,
                112.942,
                112.963,
                112.982,
                113.002,
                113.021,
                113.04,
                113.06,
                113.079,
                113.1,
                113.119,
                113.138,
                113.158,
                113.177,
                113.197,
                113.216,
                113.237,
                113.255,
                113.274,
                113.295,
                113.314,
                113.334,
                113.353,
                113.372,
                113.392,
                113.411,
                113.431,
                113.45,
                113.469,
                113.489,
                113.508,
                113.528,
                113.547,
                113.566,
                113.586,
                113.605,
                113.625,
                113.644,
                113.664,
                113.683,
                113.702,
                113.722,
                113.741,
                113.761,
                113.779,
                113.798,
                113.818,
                113.837,
                113.857,
                113.876,
                113.895,
                113.915,
                113.934,
                113.954,
                113.972,
                113.991,
                114.011,
                114.03,
                114.05,
                114.069,
                114.087,
                114.107,
                114.126,
                114.146,
                114.165,
                114.183,
                114.203,
                114.222,
                114.242,
                114.261,
                114.281,
                114.299,
                114.318,
                114.338,
                114.356,
                114.376,
                114.395,
                114.413,
                114.433,
                114.452,
                114.472,
                114.491,
                114.509,
                114.529,
                114.548,
                114.567,
                114.586,
                114.604,
                114.624,
                114.643,
                114.663,
                114.681,
                114.7,
                114.72,
                114.738,
                114.758,
                114.776,
                114.795,
                114.815,
                114.833,
                114.853,
                114.871,
                114.89,
                114.91,
                114.928,
                114.948,
                114.966,
                114.985,
                115.005,
                115.023,
                115.043,
                115.061,
                115.079,
                115.099,
                115.118,
                115.137,
                115.156,
                115.174,
                115.194,
                115.212,
                115.232,
                115.25,
                115.268,
                115.288,
                115.307,
                115.326,
                115.345,
                115.363,
                115.382,
                115.401,
                115.42,
                115.439,
                115.457,
                115.477,
                115.495,
                115.513,
                115.533,
                115.551,
                115.571,
                115.589,
                115.607,
                115.627,
                115.645,
                115.665,
                115.683,
                115.701,
                115.721,
                115.739,
                115.758,
                115.777,
                115.795,
                115.814,
                115.832,
                115.852,
                115.87,
                115.888,
                115.908,
                115.926,
                115.945,
                115.964,
                115.982,
                116.001,
                116.019,
                116.039,
                116.057,
                116.075
            ],
            P95: [
                52.212,
                52.385,
                52.559,
                52.733,
                52.907,
                53.081,
                53.254,
                53.428,
                53.601,
                53.775,
                53.949,
                54.122,
                54.295,
                54.469,
                54.642,
                54.786,
                54.93,
                55.073,
                55.216,
                55.356,
                55.497,
                55.636,
                55.775,
                55.912,
                56.049,
                56.183,
                56.318,
                56.451,
                56.583,
                56.714,
                56.845,
                56.974,
                57.103,
                57.23,
                57.356,
                57.482,
                57.607,
                57.73,
                57.852,
                57.974,
                58.094,
                58.215,
                58.333,
                58.451,
                58.568,
                58.685,
                58.8,
                58.914,
                59.028,
                59.141,
                59.253,
                59.363,
                59.474,
                59.583,
                59.692,
                59.799,
                59.906,
                60.013,
                60.118,
                60.223,
                60.326,
                60.43,
                60.532,
                60.634,
                60.735,
                60.836,
                60.936,
                61.035,
                61.133,
                61.231,
                61.329,
                61.425,
                61.521,
                61.617,
                61.712,
                61.806,
                61.9,
                61.993,
                62.086,
                62.177,
                62.269,
                62.359,
                62.45,
                62.539,
                62.629,
                62.717,
                62.806,
                62.894,
                62.98,
                63.067,
                63.152,
                63.238,
                63.324,
                63.408,
                63.492,
                63.576,
                63.659,
                63.741,
                63.824,
                63.906,
                63.987,
                64.069,
                64.148,
                64.228,
                64.308,
                64.387,
                64.466,
                64.545,
                64.622,
                64.7,
                64.776,
                64.853,
                64.929,
                65.005,
                65.08,
                65.155,
                65.229,
                65.303,
                65.377,
                65.45,
                65.523,
                65.596,
                65.668,
                65.74,
                65.811,
                65.882,
                65.952,
                66.022,
                66.092,
                66.161,
                66.231,
                66.299,
                66.368,
                66.435,
                66.503,
                66.571,
                66.637,
                66.704,
                66.77,
                66.835,
                66.902,
                66.967,
                67.031,
                67.097,
                67.161,
                67.225,
                67.289,
                67.352,
                67.415,
                67.479,
                67.541,
                67.603,
                67.666,
                67.727,
                67.788,
                67.85,
                67.911,
                67.972,
                68.032,
                68.093,
                68.153,
                68.212,
                68.272,
                68.331,
                68.391,
                68.45,
                68.509,
                68.567,
                68.626,
                68.683,
                68.742,
                68.8,
                68.857,
                68.915,
                68.972,
                69.029,
                69.086,
                69.143,
                69.2,
                69.256,
                69.312,
                69.369,
                69.424,
                69.48,
                69.535,
                69.591,
                69.646,
                69.702,
                69.756,
                69.812,
                69.867,
                69.92,
                69.975,
                70.03,
                70.083,
                70.138,
                70.192,
                70.246,
                70.3,
                70.353,
                70.407,
                70.46,
                70.514,
                70.566,
                70.619,
                70.672,
                70.726,
                70.778,
                70.831,
                70.883,
                70.935,
                70.988,
                71.04,
                71.092,
                71.145,
                71.197,
                71.249,
                71.299,
                71.351,
                71.403,
                71.454,
                71.506,
                71.557,
                71.608,
                71.659,
                71.711,
                71.762,
                71.812,
                71.863,
                71.914,
                71.965,
                72.015,
                72.066,
                72.116,
                72.166,
                72.216,
                72.267,
                72.317,
                72.367,
                72.416,
                72.466,
                72.516,
                72.566,
                72.615,
                72.665,
                72.714,
                72.763,
                72.813,
                72.862,
                72.911,
                72.96,
                73.01,
                73.059,
                73.108,
                73.156,
                73.205,
                73.254,
                73.302,
                73.351,
                73.399,
                73.448,
                73.497,
                73.545,
                73.593,
                73.641,
                73.689,
                73.738,
                73.786,
                73.834,
                73.881,
                73.929,
                73.978,
                74.025,
                74.073,
                74.12,
                74.167,
                74.216,
                74.263,
                74.31,
                74.357,
                74.404,
                74.452,
                74.499,
                74.546,
                74.593,
                74.641,
                74.687,
                74.734,
                74.782,
                74.828,
                74.875,
                74.921,
                74.969,
                75.015,
                75.061,
                75.108,
                75.155,
                75.2,
                75.246,
                75.294,
                75.339,
                75.385,
                75.432,
                75.478,
                75.523,
                75.57,
                75.616,
                75.661,
                75.708,
                75.753,
                75.798,
                75.845,
                75.89,
                75.936,
                75.982,
                76.027,
                76.073,
                76.118,
                76.162,
                76.208,
                76.253,
                76.299,
                76.344,
                76.388,
                76.434,
                76.479,
                76.524,
                76.569,
                76.613,
                76.659,
                76.703,
                76.748,
                76.792,
                76.838,
                76.882,
                76.926,
                76.971,
                77.014,
                77.06,
                77.103,
                77.148,
                77.192,
                77.237,
                77.28,
                77.325,
                77.369,
                77.412,
                77.457,
                77.5,
                77.544,
                77.588,
                77.632,
                77.675,
                77.719,
                77.762,
                77.807,
                77.849,
                77.894,
                77.936,
                77.98,
                78.023,
                78.067,
                78.11,
                78.154,
                78.196,
                78.24,
                78.282,
                78.326,
                78.368,
                78.412,
                78.455,
                78.498,
                78.541,
                78.583,
                78.626,
                78.668,
                78.712,
                78.754,
                78.797,
                78.839,
                78.882,
                78.925,
                78.966,
                79.009,
                79.051,
                79.094,
                79.135,
                79.178,
                79.22,
                79.262,
                79.305,
                79.346,
                79.389,
                79.43,
                79.472,
                79.513,
                79.556,
                79.598,
                79.639,
                79.681,
                79.722,
                79.764,
                79.806,
                79.847,
                79.889,
                79.93,
                79.972,
                80.014,
                80.054,
                80.096,
                80.137,
                80.178,
                80.22,
                80.26,
                80.302,
                80.342,
                80.384,
                80.425,
                80.465,
                80.507,
                80.548,
                80.588,
                80.629,
                80.669,
                80.711,
                80.752,
                80.792,
                80.833,
                80.874,
                80.913,
                80.954,
                80.995,
                81.035,
                81.076,
                81.115,
                81.156,
                81.197,
                81.236,
                81.277,
                81.317,
                81.356,
                81.397,
                81.437,
                81.477,
                81.517,
                81.557,
                81.596,
                81.637,
                81.677,
                81.716,
                81.756,
                81.795,
                81.835,
                81.875,
                81.914,
                81.954,
                81.994,
                82.032,
                82.072,
                82.112,
                82.151,
                82.19,
                82.23,
                82.269,
                82.308,
                82.348,
                82.386,
                82.426,
                82.465,
                82.505,
                82.543,
                82.582,
                82.622,
                82.66,
                82.699,
                82.738,
                82.776,
                82.816,
                82.855,
                82.893,
                82.932,
                82.971,
                83.008,
                83.048,
                83.087,
                83.124,
                83.163,
                83.202,
                83.241,
                83.278,
                83.317,
                83.356,
                83.393,
                83.432,
                83.471,
                83.508,
                83.546,
                83.585,
                83.622,
                83.661,
                83.699,
                83.738,
                83.775,
                83.813,
                83.851,
                83.888,
                83.927,
                83.965,
                84.002,
                84.04,
                84.078,
                84.116,
                84.153,
                84.191,
                84.229,
                84.265,
                84.303,
                84.341,
                84.379,
                84.416,
                84.453,
                84.491,
                84.528,
                84.565,
                84.603,
                84.641,
                84.677,
                84.715,
                84.752,
                84.788,
                84.826,
                84.863,
                84.901,
                84.937,
                84.974,
                85.012,
                85.048,
                85.085,
                85.122,
                85.159,
                85.195,
                85.232,
                85.269,
                85.305,
                85.342,
                85.379,
                85.416,
                85.452,
                85.489,
                85.526,
                85.563,
                85.598,
                85.635,
                85.672,
                85.707,
                85.744,
                85.781,
                85.817,
                85.853,
                85.889,
                85.926,
                85.962,
                85.998,
                86.034,
                86.071,
                86.107,
                86.142,
                86.179,
                86.215,
                86.25,
                86.286,
                86.323,
                86.359,
                86.394,
                86.43,
                86.466,
                86.502,
                86.537,
                86.573,
                86.609,
                86.645,
                86.68,
                86.716,
                86.752,
                86.786,
                86.822,
                86.858,
                86.894,
                86.929,
                86.964,
                87,
                87.036,
                87.07,
                87.106,
                87.142,
                87.177,
                87.211,
                87.247,
                87.283,
                87.318,
                87.352,
                87.388,
                87.423,
                87.459,
                87.493,
                87.528,
                87.563,
                87.599,
                87.633,
                87.668,
                87.703,
                87.738,
                87.772,
                87.807,
                87.842,
                87.878,
                87.911,
                87.946,
                87.981,
                88.015,
                88.05,
                88.085,
                88.12,
                88.153,
                88.188,
                88.223,
                88.258,
                88.291,
                88.326,
                88.361,
                88.396,
                88.429,
                88.464,
                88.498,
                88.533,
                88.566,
                88.601,
                88.635,
                88.67,
                88.703,
                88.737,
                88.772,
                88.806,
                88.839,
                88.874,
                88.908,
                88.942,
                88.975,
                89.009,
                89.044,
                89.078,
                89.111,
                89.145,
                89.179,
                89.213,
                89.246,
                89.28,
                89.314,
                89.348,
                89.38,
                89.414,
                89.448,
                89.482,
                89.515,
                89.549,
                89.582,
                89.616,
                89.649,
                89.682,
                89.716,
                89.75,
                89.782,
                89.816,
                89.849,
                89.883,
                89.915,
                89.949,
                89.982,
                90.016,
                90.048,
                90.081,
                90.115,
                90.148,
                90.18,
                90.213,
                90.247,
                90.28,
                90.312,
                90.345,
                90.379,
                90.412,
                90.444,
                90.477,
                90.51,
                90.542,
                90.575,
                90.608,
                90.641,
                90.672,
                90.705,
                90.738,
                90.771,
                90.803,
                90.836,
                90.869,
                90.902,
                90.933,
                90.966,
                90.999,
                91.031,
                91.063,
                91.095,
                91.128,
                91.161,
                91.192,
                91.225,
                91.257,
                91.29,
                91.321,
                91.354,
                91.386,
                91.419,
                91.45,
                91.482,
                91.515,
                91.546,
                91.578,
                91.61,
                91.643,
                91.674,
                91.706,
                91.038,
                91.07,
                91.102,
                91.134,
                91.165,
                91.197,
                91.229,
                91.262,
                91.292,
                91.324,
                91.356,
                91.387,
                91.419,
                91.451,
                91.483,
                91.514,
                91.545,
                91.577,
                91.609,
                91.64,
                91.672,
                91.703,
                91.734,
                91.766,
                91.797,
                91.829,
                91.859,
                91.891,
                91.923,
                91.954,
                91.985,
                92.016,
                92.048,
                92.078,
                92.11,
                92.141,
                92.173,
                92.203,
                92.234,
                92.266,
                92.297,
                92.327,
                92.358,
                92.39,
                92.42,
                92.451,
                92.482,
                92.513,
                92.543,
                92.575,
                92.606,
                92.636,
                92.667,
                92.698,
                92.729,
                92.759,
                92.79,
                92.821,
                92.85,
                92.881,
                92.912,
                92.943,
                92.973,
                93.004,
                93.035,
                93.066,
                93.095,
                93.126,
                93.157,
                93.186,
                93.217,
                93.248,
                93.279,
                93.308,
                93.339,
                93.369,
                93.398,
                93.429,
                93.46,
                93.489,
                93.519,
                93.55,
                93.581,
                93.61,
                93.64,
                93.671,
                93.699,
                93.73,
                93.76,
                93.791,
                93.82,
                93.85,
                93.88,
                93.909,
                93.939,
                93.97,
                94,
                94.029,
                94.059,
                94.089,
                94.118,
                94.148,
                94.178,
                94.207,
                94.237,
                94.267,
                94.297,
                94.325,
                94.355,
                94.385,
                94.414,
                94.444,
                94.474,
                94.502,
                94.532,
                94.562,
                94.591,
                94.62,
                94.65,
                94.679,
                94.708,
                94.737,
                94.767,
                94.795,
                94.825,
                94.854,
                94.884,
                94.912,
                94.942,
                94.971,
                94.999,
                95.029,
                95.058,
                95.086,
                95.116,
                95.145,
                95.173,
                95.202,
                95.232,
                95.261,
                95.289,
                95.318,
                95.348,
                95.375,
                95.405,
                95.434,
                95.462,
                95.491,
                95.52,
                95.547,
                95.577,
                95.606,
                95.633,
                95.662,
                95.691,
                95.719,
                95.748,
                95.777,
                95.806,
                95.833,
                95.862,
                95.891,
                95.919,
                95.947,
                95.976,
                96.004,
                96.032,
                96.061,
                96.088,
                96.117,
                96.146,
                96.173,
                96.202,
                96.23,
                96.258,
                96.286,
                96.315,
                96.342,
                96.37,
                96.399,
                96.426,
                96.454,
                96.483,
                96.51,
                96.538,
                96.567,
                96.594,
                96.622,
                96.65,
                96.677,
                96.706,
                96.734,
                96.761,
                96.789,
                96.817,
                96.844,
                96.872,
                96.9,
                96.927,
                96.955,
                96.983,
                97.01,
                97.038,
                97.066,
                97.093,
                97.121,
                97.149,
                97.175,
                97.203,
                97.231,
                97.258,
                97.286,
                97.314,
                97.34,
                97.368,
                97.396,
                97.422,
                97.45,
                97.478,
                97.504,
                97.532,
                97.558,
                97.586,
                97.614,
                97.64,
                97.668,
                97.695,
                97.722,
                97.749,
                97.777,
                97.803,
                97.831,
                97.858,
                97.884,
                97.912,
                97.939,
                97.965,
                97.993,
                98.019,
                98.046,
                98.074,
                98.1,
                98.127,
                98.155,
                98.18,
                98.208,
                98.235,
                98.261,
                98.288,
                98.314,
                98.341,
                98.369,
                98.395,
                98.422,
                98.449,
                98.475,
                98.502,
                98.529,
                98.555,
                98.582,
                98.608,
                98.635,
                98.662,
                98.687,
                98.715,
                98.742,
                98.767,
                98.794,
                98.82,
                98.847,
                98.874,
                98.899,
                98.926,
                98.953,
                98.979,
                99.006,
                99.031,
                99.058,
                99.085,
                99.11,
                99.137,
                99.164,
                99.189,
                99.216,
                99.241,
                99.268,
                99.295,
                99.32,
                99.347,
                99.372,
                99.399,
                99.426,
                99.451,
                99.478,
                99.503,
                99.529,
                99.556,
                99.581,
                99.608,
                99.634,
                99.659,
                99.686,
                99.711,
                99.738,
                99.764,
                99.789,
                99.816,
                99.841,
                99.867,
                99.894,
                99.919,
                99.945,
                99.97,
                99.996,
                100.023,
                100.048,
                100.074,
                100.099,
                100.125,
                100.152,
                100.176,
                100.203,
                100.228,
                100.254,
                100.28,
                100.305,
                100.331,
                100.356,
                100.382,
                100.408,
                100.433,
                100.459,
                100.484,
                100.51,
                100.536,
                100.561,
                100.587,
                100.612,
                100.638,
                100.663,
                100.689,
                100.715,
                100.739,
                100.765,
                100.79,
                100.816,
                100.842,
                100.866,
                100.892,
                100.917,
                100.943,
                100.969,
                100.993,
                101.019,
                101.044,
                101.07,
                101.094,
                101.12,
                101.146,
                101.17,
                101.196,
                101.22,
                101.246,
                101.271,
                101.296,
                101.322,
                101.347,
                101.372,
                101.397,
                101.422,
                101.447,
                101.472,
                101.498,
                101.522,
                101.548,
                101.572,
                101.598,
                101.622,
                101.648,
                101.674,
                101.698,
                101.723,
                101.747,
                101.773,
                101.797,
                101.823,
                101.848,
                101.872,
                101.898,
                101.922,
                101.948,
                101.972,
                101.997,
                102.023,
                102.047,
                102.072,
                102.096,
                102.122,
                102.146,
                102.171,
                102.195,
                102.22,
                102.246,
                102.27,
                102.295,
                102.319,
                102.345,
                102.368,
                102.394,
                102.419,
                102.443,
                102.468,
                102.492,
                102.517,
                102.541,
                102.566,
                102.59,
                102.615,
                102.639,
                102.665,
                102.69,
                102.713,
                102.739,
                102.762,
                102.788,
                102.811,
                102.836,
                102.86,
                102.885,
                102.91,
                102.934,
                102.959,
                102.983,
                103.008,
                103.031,
                103.057,
                103.08,
                103.105,
                103.129,
                103.154,
                103.179,
                103.202,
                103.227,
                103.251,
                103.276,
                103.299,
                103.324,
                103.348,
                103.373,
                103.396,
                103.421,
                103.444,
                103.469,
                103.494,
                103.518,
                103.543,
                103.566,
                103.591,
                103.614,
                103.639,
                103.662,
                103.687,
                103.71,
                103.735,
                103.758,
                103.783,
                103.808,
                103.831,
                103.856,
                103.879,
                103.904,
                103.927,
                103.952,
                103.975,
                104,
                104.023,
                104.048,
                104.071,
                104.096,
                104.119,
                104.143,
                104.167,
                104.191,
                104.216,
                104.239,
                104.264,
                104.286,
                104.311,
                104.334,
                104.359,
                104.382,
                104.406,
                104.429,
                104.454,
                104.477,
                104.501,
                104.524,
                104.549,
                104.572,
                104.596,
                104.619,
                104.644,
                104.666,
                104.691,
                104.714,
                104.738,
                104.763,
                104.785,
                104.81,
                104.833,
                104.857,
                104.88,
                104.904,
                104.927,
                104.951,
                104.974,
                104.998,
                105.021,
                105.046,
                105.068,
                105.093,
                105.115,
                105.139,
                105.162,
                105.186,
                105.209,
                105.233,
                105.256,
                105.28,
                105.303,
                105.327,
                105.35,
                105.374,
                105.396,
                105.42,
                105.443,
                105.467,
                105.49,
                105.514,
                105.536,
                105.56,
                105.583,
                105.607,
                105.631,
                105.653,
                105.678,
                105.7,
                105.724,
                105.746,
                105.77,
                105.793,
                105.817,
                105.839,
                105.863,
                105.886,
                105.91,
                105.932,
                105.956,
                105.978,
                106.002,
                106.024,
                106.048,
                106.071,
                106.094,
                106.117,
                106.141,
                106.163,
                106.187,
                106.209,
                106.233,
                106.255,
                106.279,
                106.301,
                106.325,
                106.347,
                106.371,
                106.393,
                106.417,
                106.439,
                106.462,
                106.485,
                106.508,
                106.53,
                106.554,
                106.576,
                106.6,
                106.622,
                106.646,
                106.668,
                106.691,
                106.713,
                106.735,
                106.759,
                106.781,
                106.805,
                106.826,
                106.85,
                106.872,
                106.896,
                106.918,
                106.941,
                106.963,
                106.987,
                107.008,
                107.032,
                107.054,
                107.077,
                107.099,
                107.123,
                107.145,
                107.168,
                107.19,
                107.213,
                107.235,
                107.258,
                107.28,
                107.304,
                107.325,
                107.349,
                107.37,
                107.394,
                107.416,
                107.439,
                107.461,
                107.484,
                107.506,
                107.529,
                107.551,
                107.574,
                107.596,
                107.619,
                107.64,
                107.662,
                107.685,
                107.707,
                107.73,
                107.752,
                107.775,
                107.796,
                107.82,
                107.841,
                107.864,
                107.886,
                107.909,
                107.931,
                107.954,
                107.975,
                107.998,
                108.02,
                108.043,
                108.064,
                108.087,
                108.109,
                108.132,
                108.153,
                108.177,
                108.198,
                108.219,
                108.242,
                108.264,
                108.287,
                108.308,
                108.331,
                108.352,
                108.375,
                108.397,
                108.42,
                108.441,
                108.464,
                108.485,
                108.508,
                108.529,
                108.552,
                108.574,
                108.596,
                108.618,
                108.639,
                108.662,
                108.683,
                108.706,
                108.727,
                108.75,
                108.771,
                108.794,
                108.815,
                108.838,
                108.859,
                108.882,
                108.903,
                108.926,
                108.947,
                108.97,
                108.991,
                109.012,
                109.035,
                109.056,
                109.078,
                109.099,
                109.122,
                109.143,
                109.166,
                109.187,
                109.21,
                109.23,
                109.253,
                109.274,
                109.295,
                109.318,
                109.339,
                109.361,
                109.382,
                109.405,
                109.426,
                109.448,
                109.469,
                109.492,
                109.513,
                109.535,
                109.556,
                109.577,
                109.6,
                109.621,
                109.643,
                109.664,
                109.687,
                109.707,
                109.73,
                109.751,
                109.773,
                109.794,
                109.816,
                109.837,
                109.858,
                109.88,
                109.901,
                109.924,
                109.944,
                109.967,
                109.988,
                110.01,
                110.031,
                110.053,
                110.074,
                110.094,
                110.117,
                110.137,
                110.16,
                110.181,
                110.203,
                110.223,
                110.246,
                110.266,
                110.289,
                110.309,
                110.33,
                110.352,
                110.373,
                110.395,
                110.416,
                110.438,
                110.459,
                110.481,
                110.502,
                110.524,
                110.544,
                110.565,
                110.587,
                110.608,
                110.63,
                110.65,
                110.673,
                110.693,
                110.715,
                110.736,
                110.756,
                110.778,
                110.799,
                110.821,
                110.841,
                110.863,
                110.884,
                110.906,
                110.926,
                110.947,
                110.969,
                110.989,
                111.011,
                111.032,
                111.054,
                111.074,
                111.096,
                111.117,
                111.137,
                111.159,
                111.18,
                111.202,
                111.222,
                111.244,
                111.264,
                111.286,
                111.307,
                111.327,
                111.349,
                111.369,
                111.391,
                111.411,
                111.433,
                111.454,
                111.474,
                111.496,
                111.516,
                111.538,
                111.558,
                111.58,
                111.6,
                111.622,
                111.642,
                111.663,
                111.685,
                111.705,
                111.727,
                111.747,
                111.769,
                111.789,
                111.809,
                111.831,
                111.851,
                111.873,
                111.893,
                111.915,
                111.935,
                111.957,
                111.977,
                111.997,
                112.019,
                112.039,
                112.06,
                112.081,
                112.102,
                112.122,
                112.142,
                112.164,
                112.184,
                112.206,
                112.226,
                112.248,
                112.268,
                112.288,
                112.309,
                112.329,
                112.351,
                112.371,
                112.393,
                112.413,
                112.433,
                112.454,
                112.474,
                112.496,
                112.516,
                112.538,
                112.558,
                112.577,
                112.599,
                112.619,
                112.641,
                112.661,
                112.682,
                112.702,
                112.722,
                112.743,
                112.763,
                112.785,
                112.805,
                112.826,
                112.846,
                112.866,
                112.888,
                112.907,
                112.929,
                112.949,
                112.97,
                112.99,
                113.01,
                113.031,
                113.051,
                113.073,
                113.092,
                113.114,
                113.134,
                113.153,
                113.175,
                113.195,
                113.216,
                113.236,
                113.256,
                113.277,
                113.297,
                113.318,
                113.338,
                113.359,
                113.379,
                113.399,
                113.42,
                113.44,
                113.461,
                113.481,
                113.502,
                113.522,
                113.541,
                113.563,
                113.582,
                113.604,
                113.623,
                113.643,
                113.664,
                113.684,
                113.705,
                113.725,
                113.746,
                113.766,
                113.785,
                113.806,
                113.826,
                113.847,
                113.867,
                113.886,
                113.908,
                113.927,
                113.948,
                113.968,
                113.989,
                114.009,
                114.028,
                114.049,
                114.069,
                114.09,
                114.11,
                114.129,
                114.15,
                114.17,
                114.191,
                114.21,
                114.231,
                114.251,
                114.27,
                114.291,
                114.311,
                114.332,
                114.351,
                114.371,
                114.392,
                114.411,
                114.432,
                114.452,
                114.471,
                114.492,
                114.511,
                114.533,
                114.552,
                114.573,
                114.592,
                114.612,
                114.633,
                114.652,
                114.673,
                114.692,
                114.712,
                114.733,
                114.752,
                114.773,
                114.792,
                114.811,
                114.832,
                114.852,
                114.873,
                114.892,
                114.913,
                114.932,
                114.951,
                114.972,
                114.991,
                115.012,
                115.031,
                115.051,
                115.072,
                115.091,
                115.112,
                115.131,
                115.15,
                115.171,
                115.19,
                115.211,
                115.23,
                115.249,
                115.27,
                115.289,
                115.31,
                115.329,
                115.35,
                115.369,
                115.388,
                115.409,
                115.428,
                115.449,
                115.468,
                115.487,
                115.508,
                115.527,
                115.547,
                115.567,
                115.585,
                115.606,
                115.625,
                115.646,
                115.665,
                115.684,
                115.705,
                115.724,
                115.744,
                115.763,
                115.782,
                115.803,
                115.822,
                115.843,
                115.862,
                115.881,
                115.901,
                115.92,
                115.941,
                115.96,
                115.98,
                115.999,
                116.018,
                116.039,
                116.058,
                116.078,
                116.097,
                116.116,
                116.137,
                116.156,
                116.176,
                116.195,
                116.214,
                116.234,
                116.253,
                116.274,
                116.293,
                116.311,
                116.332,
                116.351,
                116.371,
                116.39,
                116.409,
                116.429,
                116.448,
                116.469,
                116.487,
                116.506,
                116.527,
                116.545,
                116.566,
                116.585,
                116.603,
                116.624,
                116.642,
                116.663,
                116.682,
                116.7,
                116.721,
                116.739,
                116.76,
                116.779,
                116.797,
                116.818,
                116.836,
                116.857,
                116.875,
                116.894,
                116.914,
                116.933,
                116.953,
                116.972,
                116.991,
                117.011,
                117.03,
                117.05,
                117.069,
                117.087,
                117.107,
                117.126,
                117.146,
                117.165,
                117.183,
                117.204,
                117.222,
                117.241,
                117.261,
                117.28,
                117.3,
                117.318,
                117.337,
                117.357,
                117.376,
                117.396,
                117.414,
                117.433,
                117.453,
                117.472,
                117.492,
                117.51,
                117.529,
                117.549,
                117.567,
                117.588,
                117.606,
                117.625,
                117.645,
                117.663,
                117.683,
                117.702,
                117.72,
                117.74,
                117.759,
                117.779,
                117.797,
                117.816
            ],
            P97: [
                52.651,
                52.825,
                53,
                53.175,
                53.349,
                53.524,
                53.698,
                53.872,
                54.046,
                54.22,
                54.395,
                54.569,
                54.743,
                54.917,
                55.091,
                55.236,
                55.381,
                55.524,
                55.668,
                55.809,
                55.951,
                56.09,
                56.23,
                56.368,
                56.505,
                56.64,
                56.776,
                56.91,
                57.042,
                57.174,
                57.305,
                57.435,
                57.565,
                57.693,
                57.82,
                57.946,
                58.072,
                58.195,
                58.318,
                58.441,
                58.562,
                58.683,
                58.802,
                58.921,
                59.038,
                59.155,
                59.272,
                59.386,
                59.501,
                59.614,
                59.727,
                59.838,
                59.949,
                60.059,
                60.168,
                60.276,
                60.384,
                60.491,
                60.597,
                60.702,
                60.806,
                60.91,
                61.013,
                61.115,
                61.217,
                61.319,
                61.419,
                61.519,
                61.618,
                61.716,
                61.815,
                61.911,
                62.008,
                62.105,
                62.2,
                62.294,
                62.389,
                62.482,
                62.576,
                62.668,
                62.76,
                62.851,
                62.942,
                63.032,
                63.122,
                63.211,
                63.3,
                63.388,
                63.475,
                63.563,
                63.648,
                63.735,
                63.821,
                63.905,
                63.99,
                64.074,
                64.158,
                64.241,
                64.324,
                64.406,
                64.488,
                64.57,
                64.65,
                64.731,
                64.811,
                64.89,
                64.97,
                65.049,
                65.127,
                65.205,
                65.281,
                65.358,
                65.435,
                65.511,
                65.587,
                65.662,
                65.737,
                65.812,
                65.886,
                65.959,
                66.033,
                66.107,
                66.179,
                66.251,
                66.323,
                66.394,
                66.465,
                66.535,
                66.605,
                66.675,
                66.745,
                66.814,
                66.883,
                66.951,
                67.018,
                67.087,
                67.154,
                67.221,
                67.287,
                67.353,
                67.42,
                67.485,
                67.55,
                67.616,
                67.681,
                67.745,
                67.81,
                67.873,
                67.937,
                68.001,
                68.063,
                68.126,
                68.189,
                68.25,
                68.312,
                68.374,
                68.435,
                68.497,
                68.558,
                68.619,
                68.679,
                68.739,
                68.799,
                68.859,
                68.919,
                68.978,
                69.037,
                69.096,
                69.155,
                69.213,
                69.272,
                69.331,
                69.388,
                69.446,
                69.503,
                69.561,
                69.618,
                69.675,
                69.733,
                69.789,
                69.846,
                69.903,
                69.958,
                70.015,
                70.07,
                70.126,
                70.182,
                70.238,
                70.293,
                70.349,
                70.404,
                70.458,
                70.514,
                70.569,
                70.622,
                70.677,
                70.732,
                70.786,
                70.841,
                70.894,
                70.948,
                71.002,
                71.056,
                71.108,
                71.162,
                71.216,
                71.269,
                71.322,
                71.376,
                71.427,
                71.48,
                71.533,
                71.586,
                71.639,
                71.691,
                71.744,
                71.796,
                71.847,
                71.899,
                71.951,
                72.003,
                72.055,
                72.107,
                72.158,
                72.21,
                72.261,
                72.313,
                72.364,
                72.415,
                72.466,
                72.517,
                72.568,
                72.619,
                72.67,
                72.721,
                72.771,
                72.822,
                72.872,
                72.922,
                72.973,
                73.023,
                73.073,
                73.123,
                73.173,
                73.223,
                73.273,
                73.322,
                73.372,
                73.421,
                73.471,
                73.52,
                73.571,
                73.62,
                73.669,
                73.719,
                73.768,
                73.816,
                73.865,
                73.914,
                73.963,
                74.013,
                74.062,
                74.11,
                74.159,
                74.207,
                74.255,
                74.305,
                74.353,
                74.401,
                74.449,
                74.497,
                74.547,
                74.594,
                74.642,
                74.69,
                74.738,
                74.787,
                74.834,
                74.882,
                74.929,
                74.977,
                75.025,
                75.072,
                75.12,
                75.167,
                75.215,
                75.262,
                75.309,
                75.358,
                75.404,
                75.451,
                75.498,
                75.546,
                75.592,
                75.639,
                75.687,
                75.733,
                75.78,
                75.826,
                75.874,
                75.92,
                75.966,
                76.013,
                76.059,
                76.106,
                76.153,
                76.199,
                76.245,
                76.292,
                76.337,
                76.383,
                76.43,
                76.475,
                76.522,
                76.568,
                76.613,
                76.66,
                76.705,
                76.75,
                76.797,
                76.842,
                76.888,
                76.933,
                76.978,
                77.025,
                77.069,
                77.115,
                77.16,
                77.205,
                77.251,
                77.295,
                77.341,
                77.386,
                77.432,
                77.476,
                77.52,
                77.566,
                77.61,
                77.656,
                77.7,
                77.745,
                77.789,
                77.835,
                77.879,
                77.924,
                77.968,
                78.011,
                78.056,
                78.1,
                78.145,
                78.189,
                78.233,
                78.277,
                78.322,
                78.365,
                78.41,
                78.453,
                78.498,
                78.541,
                78.585,
                78.628,
                78.673,
                78.716,
                78.76,
                78.803,
                78.847,
                78.89,
                78.934,
                78.977,
                79.021,
                79.065,
                79.107,
                79.151,
                79.194,
                79.238,
                79.28,
                79.324,
                79.366,
                79.41,
                79.452,
                79.495,
                79.539,
                79.581,
                79.624,
                79.666,
                79.71,
                79.752,
                79.795,
                79.837,
                79.88,
                79.923,
                79.964,
                80.007,
                80.049,
                80.092,
                80.133,
                80.176,
                80.219,
                80.26,
                80.303,
                80.344,
                80.387,
                80.43,
                80.471,
                80.513,
                80.554,
                80.597,
                80.639,
                80.68,
                80.722,
                80.763,
                80.805,
                80.847,
                80.888,
                80.93,
                80.971,
                81.013,
                81.055,
                81.095,
                81.137,
                81.179,
                81.219,
                81.261,
                81.301,
                81.343,
                81.385,
                81.425,
                81.466,
                81.508,
                81.548,
                81.589,
                81.631,
                81.671,
                81.712,
                81.752,
                81.793,
                81.834,
                81.874,
                81.915,
                81.956,
                81.996,
                82.037,
                82.078,
                82.117,
                82.158,
                82.199,
                82.238,
                82.279,
                82.32,
                82.359,
                82.4,
                82.439,
                82.479,
                82.52,
                82.559,
                82.599,
                82.64,
                82.679,
                82.719,
                82.759,
                82.798,
                82.839,
                82.879,
                82.917,
                82.958,
                82.998,
                83.036,
                83.076,
                83.116,
                83.156,
                83.195,
                83.235,
                83.275,
                83.313,
                83.353,
                83.393,
                83.431,
                83.471,
                83.51,
                83.548,
                83.588,
                83.628,
                83.666,
                83.705,
                83.745,
                83.783,
                83.822,
                83.861,
                83.901,
                83.938,
                83.978,
                84.017,
                84.055,
                84.094,
                84.133,
                84.17,
                84.21,
                84.249,
                84.286,
                84.325,
                84.364,
                84.403,
                84.44,
                84.479,
                84.518,
                84.555,
                84.594,
                84.633,
                84.67,
                84.708,
                84.747,
                84.786,
                84.823,
                84.861,
                84.9,
                84.937,
                84.975,
                85.013,
                85.052,
                85.088,
                85.127,
                85.165,
                85.202,
                85.24,
                85.278,
                85.316,
                85.353,
                85.391,
                85.429,
                85.465,
                85.503,
                85.541,
                85.579,
                85.616,
                85.653,
                85.691,
                85.728,
                85.765,
                85.803,
                85.841,
                85.877,
                85.914,
                85.952,
                85.988,
                86.026,
                86.063,
                86.101,
                86.137,
                86.174,
                86.211,
                86.249,
                86.285,
                86.322,
                86.359,
                86.395,
                86.432,
                86.469,
                86.506,
                86.542,
                86.579,
                86.616,
                86.653,
                86.689,
                86.726,
                86.763,
                86.8,
                86.835,
                86.872,
                86.909,
                86.944,
                86.981,
                87.018,
                87.054,
                87.089,
                87.126,
                87.163,
                87.199,
                87.234,
                87.271,
                87.308,
                87.344,
                87.379,
                87.415,
                87.452,
                87.487,
                87.523,
                87.56,
                87.596,
                87.631,
                87.667,
                87.703,
                87.739,
                87.774,
                87.81,
                87.846,
                87.882,
                87.917,
                87.953,
                87.989,
                88.025,
                88.059,
                88.095,
                88.131,
                88.167,
                88.201,
                88.237,
                88.273,
                88.309,
                88.343,
                88.379,
                88.415,
                88.45,
                88.484,
                88.52,
                88.556,
                88.591,
                88.625,
                88.661,
                88.696,
                88.73,
                88.765,
                88.801,
                88.836,
                88.87,
                88.905,
                88.941,
                88.976,
                89.01,
                89.045,
                89.08,
                89.115,
                89.149,
                89.184,
                89.219,
                89.254,
                89.288,
                89.323,
                89.358,
                89.393,
                89.426,
                89.461,
                89.496,
                89.531,
                89.564,
                89.599,
                89.634,
                89.669,
                89.702,
                89.737,
                89.771,
                89.806,
                89.839,
                89.874,
                89.908,
                89.943,
                89.976,
                90.01,
                90.045,
                90.079,
                90.112,
                90.147,
                90.181,
                90.215,
                90.248,
                90.282,
                90.317,
                90.351,
                90.384,
                90.418,
                90.452,
                90.486,
                90.519,
                90.553,
                90.587,
                90.621,
                90.654,
                90.688,
                90.722,
                90.756,
                90.788,
                90.822,
                90.856,
                90.89,
                90.922,
                90.956,
                90.989,
                91.023,
                91.055,
                91.089,
                91.123,
                91.156,
                91.189,
                91.222,
                91.256,
                91.288,
                91.321,
                91.355,
                91.388,
                91.42,
                91.454,
                91.487,
                91.521,
                91.552,
                91.586,
                91.619,
                91.652,
                91.684,
                91.717,
                91.751,
                91.784,
                91.816,
                91.849,
                91.882,
                91.915,
                91.947,
                91.98,
                92.013,
                92.046,
                92.077,
                92.11,
                92.143,
                92.176,
                92.207,
                92.24,
                92.273,
                92.305,
                92.337,
                92.37,
                92.403,
                92.434,
                92.467,
                91.799,
                91.832,
                91.864,
                91.897,
                91.928,
                91.961,
                91.993,
                92.026,
                92.057,
                92.089,
                92.122,
                92.153,
                92.185,
                92.218,
                92.25,
                92.281,
                92.313,
                92.346,
                92.378,
                92.409,
                92.441,
                92.473,
                92.504,
                92.536,
                92.568,
                92.601,
                92.631,
                92.663,
                92.696,
                92.728,
                92.758,
                92.79,
                92.822,
                92.853,
                92.885,
                92.917,
                92.949,
                92.979,
                93.011,
                93.043,
                93.075,
                93.105,
                93.137,
                93.169,
                93.199,
                93.23,
                93.262,
                93.294,
                93.324,
                93.356,
                93.387,
                93.417,
                93.449,
                93.481,
                93.512,
                93.542,
                93.574,
                93.605,
                93.635,
                93.667,
                93.698,
                93.729,
                93.759,
                93.791,
                93.822,
                93.853,
                93.883,
                93.914,
                93.946,
                93.975,
                94.007,
                94.038,
                94.069,
                94.098,
                94.13,
                94.161,
                94.19,
                94.221,
                94.252,
                94.282,
                94.313,
                94.344,
                94.375,
                94.404,
                94.435,
                94.466,
                94.495,
                94.526,
                94.557,
                94.588,
                94.617,
                94.648,
                94.679,
                94.708,
                94.738,
                94.769,
                94.8,
                94.829,
                94.859,
                94.89,
                94.919,
                94.95,
                94.98,
                95.009,
                95.04,
                95.07,
                95.101,
                95.129,
                95.16,
                95.19,
                95.219,
                95.249,
                95.28,
                95.308,
                95.339,
                95.369,
                95.399,
                95.428,
                95.458,
                95.488,
                95.517,
                95.547,
                95.577,
                95.605,
                95.635,
                95.666,
                95.696,
                95.724,
                95.754,
                95.784,
                95.812,
                95.842,
                95.872,
                95.9,
                95.93,
                95.96,
                95.988,
                96.018,
                96.048,
                96.078,
                96.106,
                96.136,
                96.165,
                96.193,
                96.223,
                96.253,
                96.281,
                96.31,
                96.34,
                96.368,
                96.397,
                96.427,
                96.455,
                96.484,
                96.514,
                96.541,
                96.571,
                96.6,
                96.63,
                96.657,
                96.687,
                96.716,
                96.744,
                96.773,
                96.802,
                96.83,
                96.859,
                96.888,
                96.916,
                96.945,
                96.974,
                97.002,
                97.031,
                97.06,
                97.087,
                97.116,
                97.145,
                97.173,
                97.202,
                97.231,
                97.258,
                97.287,
                97.316,
                97.343,
                97.372,
                97.401,
                97.428,
                97.457,
                97.486,
                97.513,
                97.541,
                97.57,
                97.597,
                97.626,
                97.655,
                97.682,
                97.71,
                97.739,
                97.766,
                97.794,
                97.823,
                97.85,
                97.878,
                97.907,
                97.934,
                97.962,
                97.991,
                98.018,
                98.046,
                98.074,
                98.101,
                98.13,
                98.158,
                98.184,
                98.213,
                98.241,
                98.268,
                98.296,
                98.324,
                98.351,
                98.379,
                98.406,
                98.434,
                98.462,
                98.488,
                98.517,
                98.545,
                98.571,
                98.599,
                98.627,
                98.654,
                98.682,
                98.71,
                98.736,
                98.764,
                98.792,
                98.818,
                98.846,
                98.872,
                98.9,
                98.928,
                98.954,
                98.982,
                99.01,
                99.036,
                99.064,
                99.092,
                99.118,
                99.146,
                99.172,
                99.199,
                99.227,
                99.253,
                99.281,
                99.309,
                99.334,
                99.362,
                99.39,
                99.416,
                99.443,
                99.469,
                99.497,
                99.524,
                99.55,
                99.578,
                99.605,
                99.631,
                99.658,
                99.684,
                99.712,
                99.739,
                99.765,
                99.792,
                99.82,
                99.845,
                99.873,
                99.898,
                99.926,
                99.953,
                99.979,
                100.006,
                100.033,
                100.059,
                100.086,
                100.111,
                100.139,
                100.166,
                100.191,
                100.219,
                100.244,
                100.271,
                100.298,
                100.324,
                100.351,
                100.376,
                100.403,
                100.431,
                100.456,
                100.483,
                100.51,
                100.535,
                100.562,
                100.588,
                100.615,
                100.641,
                100.667,
                100.694,
                100.719,
                100.746,
                100.773,
                100.798,
                100.825,
                100.85,
                100.877,
                100.904,
                100.929,
                100.956,
                100.981,
                101.007,
                101.034,
                101.059,
                101.086,
                101.111,
                101.138,
                101.165,
                101.19,
                101.216,
                101.241,
                101.268,
                101.295,
                101.32,
                101.346,
                101.371,
                101.398,
                101.424,
                101.449,
                101.476,
                101.501,
                101.527,
                101.552,
                101.579,
                101.605,
                101.63,
                101.656,
                101.681,
                101.708,
                101.734,
                101.759,
                101.785,
                101.81,
                101.836,
                101.863,
                101.887,
                101.914,
                101.938,
                101.965,
                101.989,
                102.016,
                102.042,
                102.067,
                102.093,
                102.118,
                102.144,
                102.168,
                102.195,
                102.221,
                102.245,
                102.272,
                102.296,
                102.322,
                102.347,
                102.373,
                102.399,
                102.424,
                102.45,
                102.474,
                102.5,
                102.525,
                102.551,
                102.577,
                102.601,
                102.628,
                102.652,
                102.678,
                102.702,
                102.728,
                102.754,
                102.779,
                102.805,
                102.829,
                102.855,
                102.879,
                102.905,
                102.931,
                102.955,
                102.981,
                103.005,
                103.031,
                103.055,
                103.081,
                103.105,
                103.131,
                103.157,
                103.181,
                103.207,
                103.231,
                103.257,
                103.281,
                103.307,
                103.333,
                103.357,
                103.383,
                103.407,
                103.432,
                103.456,
                103.482,
                103.506,
                103.532,
                103.556,
                103.581,
                103.607,
                103.631,
                103.657,
                103.681,
                103.706,
                103.73,
                103.756,
                103.78,
                103.805,
                103.831,
                103.855,
                103.88,
                103.904,
                103.93,
                103.953,
                103.979,
                104.003,
                104.028,
                104.052,
                104.077,
                104.103,
                104.127,
                104.152,
                104.176,
                104.201,
                104.225,
                104.25,
                104.274,
                104.299,
                104.323,
                104.348,
                104.372,
                104.397,
                104.423,
                104.446,
                104.472,
                104.495,
                104.521,
                104.544,
                104.569,
                104.593,
                104.618,
                104.642,
                104.667,
                104.69,
                104.716,
                104.741,
                104.764,
                104.79,
                104.813,
                104.838,
                104.862,
                104.887,
                104.91,
                104.935,
                104.959,
                104.984,
                105.007,
                105.032,
                105.056,
                105.081,
                105.104,
                105.129,
                105.154,
                105.177,
                105.203,
                105.226,
                105.251,
                105.274,
                105.299,
                105.322,
                105.347,
                105.37,
                105.395,
                105.419,
                105.444,
                105.467,
                105.492,
                105.515,
                105.54,
                105.563,
                105.588,
                105.611,
                105.636,
                105.659,
                105.684,
                105.709,
                105.732,
                105.756,
                105.779,
                105.804,
                105.827,
                105.852,
                105.875,
                105.9,
                105.923,
                105.948,
                105.971,
                105.995,
                106.018,
                106.043,
                106.066,
                106.091,
                106.113,
                106.138,
                106.161,
                106.186,
                106.208,
                106.233,
                106.256,
                106.281,
                106.303,
                106.328,
                106.351,
                106.375,
                106.398,
                106.423,
                106.445,
                106.47,
                106.493,
                106.517,
                106.54,
                106.564,
                106.589,
                106.612,
                106.636,
                106.659,
                106.683,
                106.706,
                106.73,
                106.753,
                106.777,
                106.8,
                106.824,
                106.847,
                106.871,
                106.894,
                106.918,
                106.941,
                106.965,
                106.988,
                107.012,
                107.035,
                107.059,
                107.081,
                107.106,
                107.128,
                107.152,
                107.175,
                107.199,
                107.221,
                107.246,
                107.268,
                107.292,
                107.315,
                107.339,
                107.361,
                107.385,
                107.408,
                107.432,
                107.454,
                107.478,
                107.501,
                107.525,
                107.547,
                107.571,
                107.593,
                107.618,
                107.64,
                107.664,
                107.686,
                107.708,
                107.732,
                107.755,
                107.779,
                107.801,
                107.825,
                107.847,
                107.871,
                107.893,
                107.917,
                107.939,
                107.963,
                107.985,
                108.009,
                108.031,
                108.055,
                108.077,
                108.101,
                108.123,
                108.147,
                108.169,
                108.193,
                108.215,
                108.239,
                108.261,
                108.285,
                108.307,
                108.33,
                108.352,
                108.376,
                108.398,
                108.422,
                108.444,
                108.468,
                108.489,
                108.513,
                108.535,
                108.559,
                108.581,
                108.604,
                108.626,
                108.648,
                108.672,
                108.693,
                108.717,
                108.739,
                108.763,
                108.784,
                108.808,
                108.83,
                108.853,
                108.875,
                108.899,
                108.92,
                108.944,
                108.966,
                108.989,
                109.011,
                109.034,
                109.056,
                109.08,
                109.101,
                109.125,
                109.146,
                109.17,
                109.191,
                109.213,
                109.237,
                109.258,
                109.282,
                109.303,
                109.326,
                109.348,
                109.371,
                109.393,
                109.416,
                109.438,
                109.461,
                109.483,
                109.506,
                109.528,
                109.551,
                109.572,
                109.596,
                109.617,
                109.639,
                109.662,
                109.683,
                109.707,
                109.728,
                109.751,
                109.773,
                109.796,
                109.817,
                109.84,
                109.862,
                109.885,
                109.906,
                109.93,
                109.951,
                109.974,
                109.995,
                110.017,
                110.04,
                110.061,
                110.084,
                110.105,
                110.129,
                110.15,
                110.173,
                110.194,
                110.217,
                110.238,
                110.262,
                110.283,
                110.304,
                110.327,
                110.348,
                110.371,
                110.392,
                110.415,
                110.437,
                110.46,
                110.481,
                110.504,
                110.525,
                110.548,
                110.569,
                110.59,
                110.613,
                110.634,
                110.657,
                110.678,
                110.701,
                110.722,
                110.745,
                110.766,
                110.789,
                110.81,
                110.833,
                110.854,
                110.875,
                110.898,
                110.919,
                110.941,
                110.962,
                110.985,
                111.006,
                111.029,
                111.05,
                111.073,
                111.094,
                111.114,
                111.137,
                111.158,
                111.181,
                111.202,
                111.225,
                111.245,
                111.268,
                111.289,
                111.312,
                111.333,
                111.353,
                111.376,
                111.397,
                111.42,
                111.44,
                111.463,
                111.484,
                111.507,
                111.527,
                111.55,
                111.571,
                111.591,
                111.614,
                111.635,
                111.658,
                111.678,
                111.701,
                111.722,
                111.744,
                111.765,
                111.786,
                111.808,
                111.829,
                111.851,
                111.872,
                111.895,
                111.915,
                111.938,
                111.958,
                111.979,
                112.002,
                112.022,
                112.045,
                112.065,
                112.088,
                112.108,
                112.131,
                112.151,
                112.172,
                112.194,
                112.215,
                112.237,
                112.258,
                112.28,
                112.301,
                112.323,
                112.344,
                112.364,
                112.387,
                112.407,
                112.43,
                112.45,
                112.472,
                112.493,
                112.513,
                112.536,
                112.556,
                112.579,
                112.599,
                112.621,
                112.642,
                112.664,
                112.684,
                112.705,
                112.727,
                112.748,
                112.77,
                112.79,
                112.812,
                112.833,
                112.853,
                112.875,
                112.896,
                112.918,
                112.938,
                112.961,
                112.981,
                113.003,
                113.023,
                113.044,
                113.066,
                113.086,
                113.108,
                113.129,
                113.151,
                113.171,
                113.191,
                113.214,
                113.234,
                113.256,
                113.276,
                113.298,
                113.319,
                113.339,
                113.361,
                113.381,
                113.403,
                113.423,
                113.446,
                113.466,
                113.486,
                113.508,
                113.528,
                113.55,
                113.57,
                113.592,
                113.612,
                113.633,
                113.655,
                113.675,
                113.697,
                113.717,
                113.739,
                113.759,
                113.779,
                113.801,
                113.821,
                113.843,
                113.863,
                113.885,
                113.905,
                113.925,
                113.947,
                113.967,
                113.989,
                114.009,
                114.031,
                114.051,
                114.071,
                114.093,
                114.113,
                114.135,
                114.155,
                114.177,
                114.197,
                114.217,
                114.239,
                114.259,
                114.281,
                114.3,
                114.32,
                114.342,
                114.362,
                114.384,
                114.404,
                114.426,
                114.446,
                114.465,
                114.487,
                114.507,
                114.529,
                114.549,
                114.571,
                114.59,
                114.61,
                114.632,
                114.652,
                114.674,
                114.693,
                114.713,
                114.735,
                114.755,
                114.776,
                114.796,
                114.818,
                114.838,
                114.857,
                114.879,
                114.899,
                114.921,
                114.94,
                114.96,
                114.982,
                115.001,
                115.023,
                115.043,
                115.064,
                115.084,
                115.104,
                115.125,
                115.145,
                115.167,
                115.186,
                115.206,
                115.228,
                115.247,
                115.269,
                115.289,
                115.31,
                115.33,
                115.349,
                115.371,
                115.391,
                115.412,
                115.432,
                115.451,
                115.473,
                115.492,
                115.514,
                115.533,
                115.553,
                115.575,
                115.594,
                115.616,
                115.635,
                115.657,
                115.676,
                115.696,
                115.717,
                115.737,
                115.758,
                115.778,
                115.797,
                115.819,
                115.838,
                115.859,
                115.879,
                115.898,
                115.92,
                115.939,
                115.961,
                115.98,
                116.001,
                116.021,
                116.04,
                116.062,
                116.081,
                116.102,
                116.122,
                116.141,
                116.162,
                116.182,
                116.203,
                116.222,
                116.242,
                116.263,
                116.282,
                116.304,
                116.323,
                116.342,
                116.364,
                116.383,
                116.404,
                116.423,
                116.445,
                116.464,
                116.483,
                116.505,
                116.524,
                116.545,
                116.564,
                116.583,
                116.605,
                116.624,
                116.645,
                116.664,
                116.684,
                116.705,
                116.724,
                116.745,
                116.764,
                116.783,
                116.805,
                116.824,
                116.845,
                116.864,
                116.883,
                116.904,
                116.924,
                116.945,
                116.964,
                116.983,
                117.004,
                117.023,
                117.044,
                117.063,
                117.084,
                117.103,
                117.123,
                117.144,
                117.163,
                117.184,
                117.203,
                117.222,
                117.243,
                117.262,
                117.283,
                117.302,
                117.321,
                117.342,
                117.361,
                117.382,
                117.401,
                117.42,
                117.441,
                117.46,
                117.481,
                117.5,
                117.519,
                117.54,
                117.559,
                117.58,
                117.599,
                117.617,
                117.638,
                117.657,
                117.678,
                117.697,
                117.716,
                117.737,
                117.756,
                117.777,
                117.796,
                117.814,
                117.835,
                117.854,
                117.875,
                117.894,
                117.913,
                117.934,
                117.952,
                117.973,
                117.992,
                118.011,
                118.032,
                118.051,
                118.071,
                118.09,
                118.109,
                118.13,
                118.149,
                118.169,
                118.188,
                118.207,
                118.228,
                118.246,
                118.267,
                118.286,
                118.305,
                118.325,
                118.344,
                118.363,
                118.383,
                118.402,
                118.423,
                118.442,
                118.46,
                118.481,
                118.5,
                118.52,
                118.539,
                118.558,
                118.578,
                118.597,
                118.618,
                118.636,
                118.655,
                118.676,
                118.694,
                118.715,
                118.734,
                118.752,
                118.773,
                118.791,
                118.812,
                118.831,
                118.849,
                118.87,
                118.888,
                118.909,
                118.927,
                118.946
            ],
            P99: [
                53.481,
                53.657,
                53.832,
                54.009,
                54.185,
                54.36,
                54.536,
                54.711,
                54.886,
                55.062,
                55.238,
                55.413,
                55.589,
                55.764,
                55.939,
                56.085,
                56.232,
                56.376,
                56.521,
                56.664,
                56.807,
                56.948,
                57.089,
                57.229,
                57.367,
                57.504,
                57.64,
                57.776,
                57.91,
                58.043,
                58.175,
                58.307,
                58.438,
                58.567,
                58.695,
                58.822,
                58.95,
                59.075,
                59.199,
                59.323,
                59.445,
                59.567,
                59.687,
                59.808,
                59.926,
                60.045,
                60.162,
                60.277,
                60.393,
                60.508,
                60.622,
                60.734,
                60.846,
                60.957,
                61.068,
                61.177,
                61.286,
                61.394,
                61.501,
                61.607,
                61.713,
                61.817,
                61.922,
                62.025,
                62.128,
                62.231,
                62.332,
                62.433,
                62.533,
                62.632,
                62.732,
                62.829,
                62.927,
                63.025,
                63.121,
                63.216,
                63.312,
                63.406,
                63.501,
                63.594,
                63.687,
                63.779,
                63.871,
                63.962,
                64.053,
                64.142,
                64.233,
                64.322,
                64.41,
                64.498,
                64.585,
                64.672,
                64.759,
                64.844,
                64.93,
                65.016,
                65.101,
                65.184,
                65.268,
                65.351,
                65.434,
                65.517,
                65.597,
                65.679,
                65.76,
                65.841,
                65.921,
                66.001,
                66.08,
                66.159,
                66.236,
                66.314,
                66.391,
                66.468,
                66.545,
                66.621,
                66.697,
                66.772,
                66.847,
                66.921,
                66.995,
                67.071,
                67.144,
                67.217,
                67.289,
                67.361,
                67.432,
                67.504,
                67.574,
                67.645,
                67.716,
                67.786,
                67.855,
                67.924,
                67.992,
                68.062,
                68.13,
                68.197,
                68.264,
                68.331,
                68.399,
                68.465,
                68.531,
                68.597,
                68.662,
                68.727,
                68.793,
                68.857,
                68.921,
                68.986,
                69.049,
                69.112,
                69.176,
                69.239,
                69.301,
                69.364,
                69.426,
                69.489,
                69.55,
                69.612,
                69.673,
                69.733,
                69.794,
                69.854,
                69.915,
                69.975,
                70.035,
                70.094,
                70.154,
                70.213,
                70.273,
                70.332,
                70.39,
                70.449,
                70.507,
                70.566,
                70.623,
                70.681,
                70.739,
                70.796,
                70.854,
                70.912,
                70.968,
                71.025,
                71.081,
                71.138,
                71.195,
                71.252,
                71.307,
                71.363,
                71.419,
                71.474,
                71.53,
                71.586,
                71.64,
                71.696,
                71.751,
                71.807,
                71.862,
                71.915,
                71.97,
                72.025,
                72.08,
                72.133,
                72.187,
                72.241,
                72.296,
                72.35,
                72.404,
                72.456,
                72.51,
                72.563,
                72.617,
                72.67,
                72.724,
                72.777,
                72.83,
                72.881,
                72.934,
                72.987,
                73.04,
                73.092,
                73.145,
                73.197,
                73.249,
                73.302,
                73.354,
                73.406,
                73.458,
                73.51,
                73.561,
                73.613,
                73.665,
                73.716,
                73.768,
                73.819,
                73.87,
                73.921,
                73.972,
                74.023,
                74.074,
                74.125,
                74.176,
                74.226,
                74.277,
                74.327,
                74.378,
                74.428,
                74.478,
                74.529,
                74.579,
                74.63,
                74.68,
                74.73,
                74.78,
                74.83,
                74.879,
                74.929,
                74.979,
                75.028,
                75.079,
                75.128,
                75.178,
                75.227,
                75.276,
                75.325,
                75.375,
                75.424,
                75.473,
                75.522,
                75.571,
                75.621,
                75.669,
                75.718,
                75.766,
                75.815,
                75.865,
                75.913,
                75.961,
                76.009,
                76.057,
                76.107,
                76.155,
                76.203,
                76.251,
                76.3,
                76.348,
                76.395,
                76.445,
                76.492,
                76.54,
                76.587,
                76.636,
                76.683,
                76.731,
                76.779,
                76.827,
                76.874,
                76.921,
                76.969,
                77.016,
                77.063,
                77.111,
                77.158,
                77.205,
                77.253,
                77.299,
                77.346,
                77.394,
                77.44,
                77.487,
                77.535,
                77.581,
                77.629,
                77.675,
                77.721,
                77.769,
                77.814,
                77.86,
                77.908,
                77.954,
                78.001,
                78.047,
                78.092,
                78.139,
                78.185,
                78.232,
                78.277,
                78.323,
                78.37,
                78.415,
                78.462,
                78.507,
                78.554,
                78.599,
                78.644,
                78.69,
                78.735,
                78.782,
                78.826,
                78.873,
                78.917,
                78.964,
                79.008,
                79.054,
                79.099,
                79.143,
                79.189,
                79.234,
                79.28,
                79.324,
                79.37,
                79.414,
                79.459,
                79.503,
                79.549,
                79.593,
                79.638,
                79.682,
                79.728,
                79.771,
                79.817,
                79.86,
                79.906,
                79.949,
                79.994,
                80.038,
                80.083,
                80.126,
                80.171,
                80.216,
                80.259,
                80.304,
                80.347,
                80.392,
                80.435,
                80.48,
                80.523,
                80.567,
                80.61,
                80.654,
                80.699,
                80.742,
                80.786,
                80.829,
                80.873,
                80.915,
                80.959,
                81.002,
                81.046,
                81.09,
                81.132,
                81.176,
                81.218,
                81.262,
                81.304,
                81.348,
                81.392,
                81.434,
                81.477,
                81.519,
                81.563,
                81.606,
                81.648,
                81.692,
                81.733,
                81.777,
                81.82,
                81.861,
                81.905,
                81.946,
                81.989,
                82.032,
                82.074,
                82.117,
                82.158,
                82.201,
                82.244,
                82.285,
                82.327,
                82.37,
                82.411,
                82.454,
                82.495,
                82.537,
                82.58,
                82.621,
                82.663,
                82.706,
                82.746,
                82.788,
                82.831,
                82.871,
                82.914,
                82.954,
                82.996,
                83.038,
                83.079,
                83.121,
                83.163,
                83.203,
                83.245,
                83.287,
                83.327,
                83.368,
                83.41,
                83.45,
                83.492,
                83.533,
                83.573,
                83.615,
                83.655,
                83.696,
                83.738,
                83.777,
                83.819,
                83.86,
                83.899,
                83.941,
                83.982,
                84.021,
                84.063,
                84.104,
                84.143,
                84.184,
                84.225,
                84.264,
                84.305,
                84.346,
                84.387,
                84.426,
                84.467,
                84.508,
                84.547,
                84.587,
                84.628,
                84.667,
                84.708,
                84.748,
                84.787,
                84.827,
                84.868,
                84.906,
                84.947,
                84.987,
                85.026,
                85.066,
                85.106,
                85.147,
                85.185,
                85.225,
                85.265,
                85.304,
                85.344,
                85.384,
                85.422,
                85.462,
                85.502,
                85.54,
                85.58,
                85.619,
                85.659,
                85.697,
                85.737,
                85.777,
                85.815,
                85.854,
                85.894,
                85.932,
                85.971,
                86.011,
                86.05,
                86.088,
                86.127,
                86.166,
                86.204,
                86.243,
                86.282,
                86.322,
                86.359,
                86.398,
                86.437,
                86.475,
                86.514,
                86.553,
                86.592,
                86.629,
                86.668,
                86.707,
                86.744,
                86.783,
                86.822,
                86.861,
                86.897,
                86.936,
                86.975,
                87.012,
                87.05,
                87.089,
                87.128,
                87.164,
                87.203,
                87.241,
                87.278,
                87.316,
                87.355,
                87.393,
                87.43,
                87.468,
                87.506,
                87.544,
                87.581,
                87.619,
                87.657,
                87.693,
                87.732,
                87.77,
                87.808,
                87.844,
                87.882,
                87.92,
                87.958,
                87.994,
                88.032,
                88.069,
                88.107,
                88.143,
                88.181,
                88.219,
                88.255,
                88.292,
                88.33,
                88.368,
                88.403,
                88.441,
                88.478,
                88.516,
                88.551,
                88.589,
                88.626,
                88.664,
                88.699,
                88.737,
                88.774,
                88.809,
                88.847,
                88.884,
                88.921,
                88.956,
                88.993,
                89.03,
                89.068,
                89.103,
                89.14,
                89.177,
                89.214,
                89.249,
                89.286,
                89.323,
                89.36,
                89.395,
                89.431,
                89.468,
                89.505,
                89.54,
                89.577,
                89.613,
                89.65,
                89.685,
                89.721,
                89.758,
                89.794,
                89.829,
                89.866,
                89.902,
                89.939,
                89.973,
                90.01,
                90.046,
                90.08,
                90.117,
                90.153,
                90.189,
                90.224,
                90.26,
                90.296,
                90.332,
                90.366,
                90.403,
                90.439,
                90.475,
                90.509,
                90.545,
                90.581,
                90.617,
                90.651,
                90.687,
                90.723,
                90.759,
                90.792,
                90.828,
                90.864,
                90.9,
                90.934,
                90.969,
                91.005,
                91.041,
                91.074,
                91.11,
                91.146,
                91.181,
                91.215,
                91.25,
                91.286,
                91.321,
                91.354,
                91.39,
                91.425,
                91.461,
                91.494,
                91.529,
                91.565,
                91.6,
                91.633,
                91.668,
                91.703,
                91.739,
                91.772,
                91.807,
                91.842,
                91.877,
                91.91,
                91.945,
                91.98,
                92.015,
                92.048,
                92.083,
                92.118,
                92.153,
                92.185,
                92.22,
                92.255,
                92.29,
                92.323,
                92.357,
                92.392,
                92.427,
                92.459,
                92.494,
                92.528,
                92.563,
                92.595,
                92.63,
                92.665,
                92.697,
                92.731,
                92.766,
                92.8,
                92.833,
                92.867,
                92.901,
                92.936,
                92.968,
                93.002,
                93.036,
                93.07,
                93.103,
                93.137,
                93.171,
                93.205,
                93.237,
                93.271,
                93.305,
                93.339,
                93.371,
                93.405,
                93.439,
                93.473,
                93.505,
                93.539,
                93.573,
                93.607,
                93.638,
                93.672,
                93.706,
                93.738,
                93.771,
                93.805,
                93.839,
                93.87,
                93.904,
                93.237,
                93.27,
                93.304,
                93.337,
                93.369,
                93.402,
                93.436,
                93.469,
                93.501,
                93.534,
                93.568,
                93.599,
                93.632,
                93.666,
                93.699,
                93.73,
                93.763,
                93.797,
                93.83,
                93.861,
                93.894,
                93.927,
                93.959,
                93.992,
                94.025,
                94.058,
                94.089,
                94.122,
                94.155,
                94.188,
                94.219,
                94.252,
                94.285,
                94.316,
                94.348,
                94.381,
                94.414,
                94.445,
                94.478,
                94.51,
                94.543,
                94.574,
                94.607,
                94.639,
                94.67,
                94.703,
                94.735,
                94.768,
                94.798,
                94.831,
                94.863,
                94.894,
                94.926,
                94.959,
                94.991,
                95.022,
                95.054,
                95.087,
                95.117,
                95.149,
                95.182,
                95.214,
                95.244,
                95.276,
                95.309,
                95.341,
                95.371,
                95.403,
                95.435,
                95.465,
                95.497,
                95.53,
                95.562,
                95.592,
                95.624,
                95.656,
                95.686,
                95.717,
                95.749,
                95.779,
                95.811,
                95.843,
                95.875,
                95.905,
                95.937,
                95.968,
                95.998,
                96.03,
                96.062,
                96.093,
                96.123,
                96.154,
                96.186,
                96.216,
                96.247,
                96.279,
                96.31,
                96.34,
                96.371,
                96.403,
                96.432,
                96.464,
                96.495,
                96.524,
                96.556,
                96.587,
                96.618,
                96.648,
                96.679,
                96.71,
                96.739,
                96.771,
                96.802,
                96.831,
                96.862,
                96.893,
                96.924,
                96.953,
                96.984,
                97.015,
                97.044,
                97.076,
                97.106,
                97.135,
                97.166,
                97.197,
                97.228,
                97.257,
                97.288,
                97.319,
                97.347,
                97.378,
                97.409,
                97.438,
                97.468,
                97.499,
                97.528,
                97.558,
                97.589,
                97.62,
                97.648,
                97.679,
                97.709,
                97.738,
                97.768,
                97.799,
                97.827,
                97.858,
                97.888,
                97.917,
                97.947,
                97.977,
                98.006,
                98.036,
                98.066,
                98.095,
                98.125,
                98.155,
                98.185,
                98.214,
                98.244,
                98.274,
                98.302,
                98.332,
                98.362,
                98.39,
                98.42,
                98.45,
                98.478,
                98.508,
                98.538,
                98.566,
                98.596,
                98.626,
                98.654,
                98.684,
                98.714,
                98.742,
                98.772,
                98.801,
                98.829,
                98.859,
                98.889,
                98.916,
                98.946,
                98.976,
                99.003,
                99.033,
                99.063,
                99.09,
                99.12,
                99.149,
                99.177,
                99.206,
                99.236,
                99.263,
                99.293,
                99.322,
                99.35,
                99.379,
                99.409,
                99.436,
                99.465,
                99.495,
                99.522,
                99.551,
                99.581,
                99.608,
                99.637,
                99.666,
                99.693,
                99.723,
                99.752,
                99.779,
                99.808,
                99.837,
                99.864,
                99.893,
                99.923,
                99.949,
                99.979,
                100.006,
                100.034,
                100.064,
                100.09,
                100.119,
                100.148,
                100.175,
                100.204,
                100.233,
                100.26,
                100.289,
                100.318,
                100.344,
                100.373,
                100.402,
                100.429,
                100.457,
                100.484,
                100.513,
                100.542,
                100.568,
                100.597,
                100.626,
                100.652,
                100.681,
                100.709,
                100.736,
                100.765,
                100.791,
                100.82,
                100.848,
                100.875,
                100.903,
                100.932,
                100.958,
                100.986,
                101.015,
                101.041,
                101.07,
                101.096,
                101.124,
                101.153,
                101.179,
                101.207,
                101.236,
                101.262,
                101.29,
                101.316,
                101.345,
                101.373,
                101.399,
                101.427,
                101.456,
                101.482,
                101.51,
                101.536,
                101.564,
                101.592,
                101.618,
                101.647,
                101.675,
                101.701,
                101.729,
                101.755,
                101.783,
                101.811,
                101.837,
                101.865,
                101.891,
                101.919,
                101.947,
                101.972,
                102,
                102.026,
                102.054,
                102.082,
                102.108,
                102.136,
                102.163,
                102.189,
                102.217,
                102.243,
                102.271,
                102.298,
                102.324,
                102.352,
                102.377,
                102.405,
                102.433,
                102.459,
                102.486,
                102.512,
                102.54,
                102.567,
                102.593,
                102.62,
                102.646,
                102.674,
                102.701,
                102.727,
                102.754,
                102.78,
                102.807,
                102.835,
                102.86,
                102.888,
                102.913,
                102.941,
                102.968,
                102.993,
                103.021,
                103.046,
                103.074,
                103.101,
                103.126,
                103.154,
                103.179,
                103.206,
                103.232,
                103.259,
                103.286,
                103.312,
                103.339,
                103.364,
                103.391,
                103.419,
                103.444,
                103.471,
                103.496,
                103.524,
                103.551,
                103.576,
                103.603,
                103.628,
                103.655,
                103.68,
                103.708,
                103.735,
                103.76,
                103.787,
                103.812,
                103.839,
                103.864,
                103.891,
                103.918,
                103.943,
                103.97,
                103.995,
                104.022,
                104.047,
                104.074,
                104.101,
                104.126,
                104.153,
                104.178,
                104.205,
                104.229,
                104.256,
                104.283,
                104.308,
                104.335,
                104.36,
                104.387,
                104.411,
                104.438,
                104.465,
                104.49,
                104.517,
                104.541,
                104.568,
                104.593,
                104.619,
                104.646,
                104.671,
                104.698,
                104.722,
                104.749,
                104.774,
                104.8,
                104.825,
                104.852,
                104.878,
                104.903,
                104.929,
                104.954,
                104.981,
                105.005,
                105.032,
                105.058,
                105.083,
                105.109,
                105.134,
                105.16,
                105.185,
                105.211,
                105.236,
                105.262,
                105.287,
                105.313,
                105.34,
                105.364,
                105.39,
                105.415,
                105.441,
                105.465,
                105.492,
                105.516,
                105.543,
                105.569,
                105.593,
                105.62,
                105.644,
                105.67,
                105.694,
                105.721,
                105.745,
                105.771,
                105.795,
                105.822,
                105.848,
                105.872,
                105.899,
                105.923,
                105.949,
                105.973,
                105.999,
                106.023,
                106.049,
                106.073,
                106.1,
                106.124,
                106.15,
                106.176,
                106.2,
                106.226,
                106.25,
                106.276,
                106.3,
                106.326,
                106.35,
                106.376,
                106.4,
                106.426,
                106.45,
                106.476,
                106.502,
                106.526,
                106.552,
                106.576,
                106.602,
                106.626,
                106.652,
                106.676,
                106.702,
                106.725,
                106.751,
                106.775,
                106.801,
                106.825,
                106.851,
                106.874,
                106.9,
                106.926,
                106.95,
                106.976,
                106.999,
                107.025,
                107.049,
                107.075,
                107.098,
                107.124,
                107.148,
                107.174,
                107.197,
                107.223,
                107.247,
                107.272,
                107.296,
                107.322,
                107.345,
                107.371,
                107.394,
                107.42,
                107.443,
                107.469,
                107.495,
                107.518,
                107.544,
                107.567,
                107.593,
                107.616,
                107.642,
                107.665,
                107.691,
                107.714,
                107.74,
                107.763,
                107.789,
                107.812,
                107.838,
                107.861,
                107.887,
                107.91,
                107.935,
                107.959,
                107.984,
                108.007,
                108.033,
                108.056,
                108.081,
                108.105,
                108.13,
                108.153,
                108.179,
                108.202,
                108.227,
                108.25,
                108.276,
                108.299,
                108.324,
                108.347,
                108.373,
                108.398,
                108.421,
                108.446,
                108.469,
                108.495,
                108.518,
                108.543,
                108.566,
                108.591,
                108.614,
                108.639,
                108.662,
                108.688,
                108.71,
                108.736,
                108.759,
                108.784,
                108.807,
                108.832,
                108.855,
                108.88,
                108.903,
                108.928,
                108.951,
                108.976,
                108.999,
                109.024,
                109.046,
                109.072,
                109.094,
                109.119,
                109.142,
                109.167,
                109.19,
                109.215,
                109.238,
                109.263,
                109.285,
                109.31,
                109.333,
                109.358,
                109.381,
                109.406,
                109.428,
                109.453,
                109.476,
                109.501,
                109.523,
                109.546,
                109.571,
                109.593,
                109.618,
                109.641,
                109.666,
                109.688,
                109.713,
                109.735,
                109.76,
                109.783,
                109.807,
                109.83,
                109.855,
                109.877,
                109.902,
                109.924,
                109.949,
                109.972,
                109.996,
                110.019,
                110.043,
                110.066,
                110.09,
                110.113,
                110.137,
                110.16,
                110.184,
                110.207,
                110.231,
                110.254,
                110.278,
                110.3,
                110.325,
                110.347,
                110.372,
                110.394,
                110.419,
                110.441,
                110.465,
                110.488,
                110.51,
                110.534,
                110.556,
                110.581,
                110.603,
                110.628,
                110.65,
                110.674,
                110.696,
                110.721,
                110.743,
                110.767,
                110.789,
                110.814,
                110.836,
                110.86,
                110.882,
                110.907,
                110.929,
                110.953,
                110.975,
                110.999,
                111.021,
                111.046,
                111.068,
                111.09,
                111.114,
                111.136,
                111.16,
                111.182,
                111.206,
                111.228,
                111.252,
                111.274,
                111.298,
                111.32,
                111.345,
                111.366,
                111.391,
                111.412,
                111.437,
                111.458,
                111.483,
                111.504,
                111.526,
                111.55,
                111.572,
                111.596,
                111.618,
                111.642,
                111.664,
                111.688,
                111.71,
                111.734,
                111.755,
                111.779,
                111.801,
                111.825,
                111.847,
                111.871,
                111.892,
                111.914,
                111.938,
                111.96,
                111.984,
                112.005,
                112.029,
                112.051,
                112.075,
                112.096,
                112.12,
                112.142,
                112.166,
                112.187,
                112.209,
                112.233,
                112.254,
                112.278,
                112.3,
                112.324,
                112.345,
                112.369,
                112.39,
                112.414,
                112.436,
                112.46,
                112.481,
                112.502,
                112.526,
                112.548,
                112.571,
                112.593,
                112.617,
                112.638,
                112.662,
                112.683,
                112.707,
                112.728,
                112.752,
                112.773,
                112.795,
                112.818,
                112.84,
                112.863,
                112.885,
                112.908,
                112.93,
                112.953,
                112.974,
                112.998,
                113.019,
                113.041,
                113.064,
                113.086,
                113.109,
                113.13,
                113.154,
                113.175,
                113.199,
                113.22,
                113.244,
                113.265,
                113.286,
                113.31,
                113.331,
                113.354,
                113.375,
                113.399,
                113.42,
                113.444,
                113.465,
                113.488,
                113.509,
                113.53,
                113.554,
                113.575,
                113.598,
                113.619,
                113.643,
                113.664,
                113.687,
                113.708,
                113.729,
                113.753,
                113.774,
                113.797,
                113.818,
                113.842,
                113.863,
                113.886,
                113.907,
                113.928,
                113.951,
                113.972,
                113.996,
                114.016,
                114.04,
                114.061,
                114.084,
                114.105,
                114.126,
                114.149,
                114.17,
                114.193,
                114.214,
                114.238,
                114.258,
                114.282,
                114.302,
                114.323,
                114.347,
                114.367,
                114.391,
                114.411,
                114.435,
                114.455,
                114.476,
                114.5,
                114.52,
                114.543,
                114.564,
                114.587,
                114.608,
                114.631,
                114.652,
                114.673,
                114.696,
                114.717,
                114.74,
                114.761,
                114.784,
                114.804,
                114.825,
                114.848,
                114.869,
                114.892,
                114.913,
                114.936,
                114.956,
                114.979,
                115,
                115.021,
                115.044,
                115.064,
                115.087,
                115.108,
                115.131,
                115.152,
                115.172,
                115.195,
                115.216,
                115.239,
                115.259,
                115.282,
                115.303,
                115.323,
                115.346,
                115.367,
                115.39,
                115.41,
                115.433,
                115.454,
                115.474,
                115.497,
                115.518,
                115.541,
                115.561,
                115.584,
                115.605,
                115.625,
                115.648,
                115.668,
                115.691,
                115.712,
                115.735,
                115.755,
                115.775,
                115.798,
                115.819,
                115.841,
                115.862,
                115.885,
                115.905,
                115.925,
                115.948,
                115.969,
                115.992,
                116.012,
                116.035,
                116.055,
                116.075,
                116.098,
                116.118,
                116.141,
                116.161,
                116.184,
                116.204,
                116.225,
                116.247,
                116.268,
                116.29,
                116.311,
                116.331,
                116.354,
                116.374,
                116.397,
                116.417,
                116.439,
                116.46,
                116.48,
                116.503,
                116.523,
                116.545,
                116.566,
                116.588,
                116.608,
                116.629,
                116.651,
                116.671,
                116.694,
                116.714,
                116.734,
                116.757,
                116.777,
                116.8,
                116.82,
                116.842,
                116.862,
                116.882,
                116.905,
                116.925,
                116.948,
                116.968,
                116.988,
                117.01,
                117.03,
                117.053,
                117.073,
                117.095,
                117.115,
                117.135,
                117.158,
                117.178,
                117.2,
                117.22,
                117.24,
                117.263,
                117.283,
                117.305,
                117.325,
                117.348,
                117.368,
                117.387,
                117.41,
                117.43,
                117.452,
                117.472,
                117.492,
                117.514,
                117.534,
                117.557,
                117.577,
                117.596,
                117.619,
                117.639,
                117.661,
                117.681,
                117.703,
                117.723,
                117.743,
                117.765,
                117.785,
                117.807,
                117.827,
                117.847,
                117.869,
                117.889,
                117.911,
                117.931,
                117.951,
                117.973,
                117.993,
                118.015,
                118.035,
                118.057,
                118.077,
                118.097,
                118.119,
                118.138,
                118.161,
                118.18,
                118.2,
                118.222,
                118.242,
                118.264,
                118.284,
                118.304,
                118.326,
                118.345,
                118.367,
                118.387,
                118.407,
                118.429,
                118.449,
                118.471,
                118.49,
                118.512,
                118.532,
                118.552,
                118.574,
                118.593,
                118.615,
                118.635,
                118.654,
                118.676,
                118.696,
                118.718,
                118.738,
                118.757,
                118.779,
                118.799,
                118.821,
                118.84,
                118.86,
                118.882,
                118.901,
                118.923,
                118.943,
                118.962,
                118.984,
                119.004,
                119.026,
                119.045,
                119.064,
                119.086,
                119.106,
                119.128,
                119.147,
                119.169,
                119.189,
                119.208,
                119.23,
                119.249,
                119.271,
                119.29,
                119.31,
                119.332,
                119.351,
                119.373,
                119.392,
                119.412,
                119.434,
                119.453,
                119.475,
                119.494,
                119.513,
                119.535,
                119.554,
                119.576,
                119.596,
                119.615,
                119.637,
                119.656,
                119.678,
                119.697,
                119.716,
                119.738,
                119.757,
                119.779,
                119.798,
                119.817,
                119.839,
                119.858,
                119.88,
                119.899,
                119.918,
                119.94,
                119.959,
                119.981,
                120,
                120.019,
                120.041,
                120.06,
                120.082,
                120.101,
                120.12,
                120.142,
                120.161,
                120.183,
                120.202,
                120.221,
                120.243,
                120.262,
                120.283,
                120.302,
                120.321,
                120.343,
                120.362,
                120.384,
                120.403,
                120.422,
                120.443,
                120.462,
                120.481,
                120.503,
                120.522,
                120.544,
                120.563,
                120.582,
                120.603,
                120.622,
                120.644,
                120.663,
                120.682,
                120.703,
                120.722,
                120.744,
                120.763,
                120.782,
                120.803,
                120.822,
                120.844,
                120.863,
                120.881,
                120.903,
                120.922,
                120.943,
                120.962,
                120.981,
                121.003,
                121.021,
                121.043,
                121.062,
                121.081
            ],
            P999: [
                54.904,
                55.082,
                55.26,
                55.439,
                55.617,
                55.795,
                55.972,
                56.15,
                56.327,
                56.505,
                56.684,
                56.861,
                57.038,
                57.215,
                57.392,
                57.541,
                57.69,
                57.837,
                57.985,
                58.13,
                58.276,
                58.419,
                58.562,
                58.704,
                58.845,
                58.984,
                59.123,
                59.26,
                59.397,
                59.533,
                59.667,
                59.801,
                59.935,
                60.066,
                60.196,
                60.325,
                60.455,
                60.582,
                60.708,
                60.835,
                60.959,
                61.083,
                61.205,
                61.328,
                61.448,
                61.569,
                61.689,
                61.806,
                61.924,
                62.041,
                62.157,
                62.27,
                62.384,
                62.498,
                62.61,
                62.722,
                62.832,
                62.942,
                63.051,
                63.159,
                63.267,
                63.373,
                63.479,
                63.584,
                63.689,
                63.794,
                63.897,
                63.999,
                64.101,
                64.202,
                64.304,
                64.403,
                64.502,
                64.602,
                64.7,
                64.796,
                64.895,
                64.99,
                65.087,
                65.181,
                65.277,
                65.37,
                65.464,
                65.556,
                65.649,
                65.74,
                65.832,
                65.923,
                66.012,
                66.103,
                66.19,
                66.28,
                66.368,
                66.455,
                66.542,
                66.629,
                66.716,
                66.8,
                66.886,
                66.971,
                67.056,
                67.14,
                67.222,
                67.305,
                67.388,
                67.47,
                67.552,
                67.633,
                67.714,
                67.794,
                67.872,
                67.951,
                68.03,
                68.109,
                68.187,
                68.265,
                68.342,
                68.419,
                68.495,
                68.571,
                68.646,
                68.723,
                68.798,
                68.872,
                68.946,
                69.019,
                69.092,
                69.164,
                69.236,
                69.308,
                69.381,
                69.452,
                69.522,
                69.593,
                69.662,
                69.734,
                69.803,
                69.871,
                69.939,
                70.007,
                70.077,
                70.144,
                70.211,
                70.28,
                70.346,
                70.412,
                70.479,
                70.544,
                70.609,
                70.676,
                70.74,
                70.804,
                70.87,
                70.933,
                70.997,
                71.062,
                71.124,
                71.188,
                71.25,
                71.314,
                71.376,
                71.437,
                71.5,
                71.561,
                71.624,
                71.684,
                71.746,
                71.806,
                71.868,
                71.927,
                71.988,
                72.049,
                72.108,
                72.169,
                72.227,
                72.288,
                72.346,
                72.405,
                72.465,
                72.523,
                72.582,
                72.641,
                72.698,
                72.757,
                72.813,
                72.872,
                72.93,
                72.988,
                73.044,
                73.102,
                73.16,
                73.215,
                73.273,
                73.33,
                73.385,
                73.442,
                73.499,
                73.556,
                73.612,
                73.666,
                73.723,
                73.779,
                73.835,
                73.889,
                73.944,
                74,
                74.056,
                74.111,
                74.166,
                74.219,
                74.274,
                74.329,
                74.384,
                74.439,
                74.493,
                74.548,
                74.602,
                74.654,
                74.709,
                74.763,
                74.817,
                74.871,
                74.924,
                74.978,
                75.032,
                75.085,
                75.139,
                75.192,
                75.245,
                75.298,
                75.351,
                75.404,
                75.457,
                75.51,
                75.562,
                75.615,
                75.667,
                75.72,
                75.772,
                75.824,
                75.876,
                75.928,
                75.98,
                76.032,
                76.084,
                76.136,
                76.187,
                76.239,
                76.291,
                76.342,
                76.393,
                76.447,
                76.498,
                76.549,
                76.6,
                76.651,
                76.702,
                76.753,
                76.803,
                76.854,
                76.907,
                76.957,
                77.008,
                77.058,
                77.108,
                77.159,
                77.211,
                77.261,
                77.311,
                77.361,
                77.411,
                77.463,
                77.513,
                77.562,
                77.612,
                77.661,
                77.713,
                77.762,
                77.812,
                77.861,
                77.91,
                77.962,
                78.011,
                78.06,
                78.109,
                78.16,
                78.209,
                78.258,
                78.309,
                78.357,
                78.406,
                78.454,
                78.505,
                78.554,
                78.602,
                78.653,
                78.701,
                78.749,
                78.797,
                78.847,
                78.896,
                78.943,
                78.994,
                79.041,
                79.089,
                79.139,
                79.187,
                79.234,
                79.284,
                79.332,
                79.379,
                79.429,
                79.476,
                79.526,
                79.573,
                79.62,
                79.669,
                79.716,
                79.763,
                79.812,
                79.859,
                79.908,
                79.955,
                80.002,
                80.051,
                80.097,
                80.146,
                80.193,
                80.239,
                80.288,
                80.334,
                80.383,
                80.429,
                80.477,
                80.523,
                80.569,
                80.618,
                80.664,
                80.712,
                80.758,
                80.806,
                80.851,
                80.899,
                80.945,
                80.993,
                81.038,
                81.084,
                81.132,
                81.177,
                81.225,
                81.27,
                81.317,
                81.362,
                81.41,
                81.455,
                81.502,
                81.547,
                81.594,
                81.639,
                81.686,
                81.731,
                81.778,
                81.823,
                81.87,
                81.914,
                81.961,
                82.006,
                82.052,
                82.097,
                82.143,
                82.19,
                82.234,
                82.281,
                82.325,
                82.371,
                82.415,
                82.462,
                82.505,
                82.552,
                82.596,
                82.642,
                82.688,
                82.731,
                82.777,
                82.821,
                82.867,
                82.91,
                82.956,
                83,
                83.045,
                83.091,
                83.134,
                83.18,
                83.223,
                83.269,
                83.312,
                83.357,
                83.403,
                83.445,
                83.491,
                83.534,
                83.579,
                83.624,
                83.667,
                83.712,
                83.754,
                83.799,
                83.844,
                83.887,
                83.932,
                83.974,
                84.019,
                84.064,
                84.106,
                84.15,
                84.193,
                84.237,
                84.282,
                84.324,
                84.368,
                84.413,
                84.455,
                84.499,
                84.541,
                84.585,
                84.629,
                84.671,
                84.715,
                84.759,
                84.801,
                84.844,
                84.888,
                84.93,
                84.974,
                85.015,
                85.059,
                85.103,
                85.144,
                85.187,
                85.231,
                85.272,
                85.316,
                85.359,
                85.4,
                85.444,
                85.487,
                85.528,
                85.571,
                85.614,
                85.655,
                85.698,
                85.739,
                85.782,
                85.825,
                85.866,
                85.909,
                85.952,
                85.992,
                86.035,
                86.078,
                86.118,
                86.161,
                86.204,
                86.244,
                86.287,
                86.329,
                86.37,
                86.412,
                86.455,
                86.497,
                86.537,
                86.579,
                86.622,
                86.662,
                86.704,
                86.746,
                86.786,
                86.828,
                86.87,
                86.91,
                86.952,
                86.994,
                87.034,
                87.076,
                87.118,
                87.157,
                87.199,
                87.241,
                87.283,
                87.322,
                87.364,
                87.405,
                87.445,
                87.486,
                87.528,
                87.567,
                87.609,
                87.65,
                87.689,
                87.731,
                87.772,
                87.813,
                87.852,
                87.894,
                87.935,
                87.974,
                88.015,
                88.056,
                88.095,
                88.136,
                88.177,
                88.218,
                88.256,
                88.297,
                88.338,
                88.377,
                88.418,
                88.458,
                88.499,
                88.537,
                88.578,
                88.619,
                88.657,
                88.698,
                88.738,
                88.779,
                88.817,
                88.858,
                88.898,
                88.936,
                88.976,
                89.017,
                89.057,
                89.095,
                89.135,
                89.176,
                89.213,
                89.254,
                89.294,
                89.334,
                89.371,
                89.412,
                89.452,
                89.489,
                89.529,
                89.569,
                89.609,
                89.646,
                89.686,
                89.726,
                89.766,
                89.803,
                89.843,
                89.883,
                89.92,
                89.959,
                89.999,
                90.039,
                90.076,
                90.115,
                90.155,
                90.194,
                90.231,
                90.271,
                90.31,
                90.349,
                90.386,
                90.425,
                90.465,
                90.501,
                90.541,
                90.58,
                90.619,
                90.656,
                90.695,
                90.734,
                90.773,
                90.809,
                90.848,
                90.887,
                90.926,
                90.963,
                91.002,
                91.04,
                91.077,
                91.115,
                91.154,
                91.193,
                91.229,
                91.268,
                91.306,
                91.345,
                91.381,
                91.42,
                91.458,
                91.497,
                91.533,
                91.571,
                91.61,
                91.648,
                91.684,
                91.722,
                91.761,
                91.799,
                91.835,
                91.873,
                91.911,
                91.949,
                91.985,
                92.023,
                92.061,
                92.099,
                92.135,
                92.173,
                92.211,
                92.249,
                92.284,
                92.322,
                92.36,
                92.395,
                92.433,
                92.471,
                92.509,
                92.544,
                92.582,
                92.62,
                92.657,
                92.692,
                92.73,
                92.768,
                92.805,
                92.84,
                92.878,
                92.915,
                92.953,
                92.988,
                93.025,
                93.062,
                93.1,
                93.135,
                93.172,
                93.209,
                93.246,
                93.281,
                93.318,
                93.356,
                93.393,
                93.427,
                93.464,
                93.502,
                93.539,
                93.573,
                93.61,
                93.647,
                93.684,
                93.718,
                93.755,
                93.792,
                93.829,
                93.863,
                93.9,
                93.937,
                93.974,
                94.008,
                94.044,
                94.081,
                94.118,
                94.152,
                94.188,
                94.225,
                94.261,
                94.295,
                94.332,
                94.368,
                94.405,
                94.439,
                94.475,
                94.511,
                94.548,
                94.581,
                94.618,
                94.654,
                94.69,
                94.724,
                94.76,
                94.796,
                94.832,
                94.866,
                94.902,
                94.938,
                94.974,
                95.008,
                95.044,
                95.08,
                95.113,
                95.149,
                95.185,
                95.221,
                95.254,
                95.29,
                95.326,
                95.361,
                95.394,
                95.43,
                95.466,
                95.502,
                95.535,
                95.57,
                95.606,
                95.641,
                95.674,
                95.71,
                95.745,
                95.781,
                95.814,
                95.849,
                95.885,
                95.92,
                95.953,
                95.988,
                96.024,
                96.059,
                96.092,
                96.127,
                96.162,
                96.195,
                96.23,
                96.265,
                96.3,
                96.333,
                96.368,
                95.702,
                95.737,
                95.772,
                95.807,
                95.839,
                95.874,
                95.909,
                95.944,
                95.976,
                96.011,
                96.046,
                96.078,
                96.113,
                96.148,
                96.183,
                96.215,
                96.25,
                96.284,
                96.319,
                96.351,
                96.386,
                96.42,
                96.452,
                96.487,
                96.521,
                96.556,
                96.588,
                96.622,
                96.657,
                96.691,
                96.723,
                96.758,
                96.792,
                96.824,
                96.858,
                96.892,
                96.927,
                96.958,
                96.993,
                97.027,
                97.061,
                97.093,
                97.127,
                97.161,
                97.192,
                97.226,
                97.261,
                97.295,
                97.326,
                97.36,
                97.394,
                97.425,
                97.459,
                97.493,
                97.527,
                97.558,
                97.592,
                97.626,
                97.657,
                97.691,
                97.725,
                97.759,
                97.79,
                97.824,
                97.857,
                97.891,
                97.922,
                97.955,
                97.989,
                98.02,
                98.054,
                98.087,
                98.121,
                98.151,
                98.185,
                98.218,
                98.249,
                98.283,
                98.316,
                98.347,
                98.38,
                98.413,
                98.447,
                98.477,
                98.511,
                98.544,
                98.574,
                98.608,
                98.641,
                98.674,
                98.704,
                98.738,
                98.771,
                98.801,
                98.834,
                98.867,
                98.9,
                98.93,
                98.963,
                98.996,
                99.026,
                99.059,
                99.092,
                99.122,
                99.155,
                99.188,
                99.221,
                99.251,
                99.284,
                99.316,
                99.346,
                99.379,
                99.412,
                99.441,
                99.474,
                99.507,
                99.539,
                99.569,
                99.602,
                99.634,
                99.664,
                99.696,
                99.729,
                99.758,
                99.791,
                99.823,
                99.856,
                99.885,
                99.918,
                99.95,
                99.979,
                100.012,
                100.044,
                100.073,
                100.106,
                100.138,
                100.167,
                100.199,
                100.231,
                100.263,
                100.293,
                100.325,
                100.357,
                100.386,
                100.418,
                100.45,
                100.479,
                100.511,
                100.543,
                100.572,
                100.604,
                100.636,
                100.665,
                100.697,
                100.729,
                100.757,
                100.789,
                100.821,
                100.853,
                100.882,
                100.913,
                100.945,
                100.974,
                101.005,
                101.037,
                101.066,
                101.097,
                101.129,
                101.157,
                101.189,
                101.22,
                101.249,
                101.28,
                101.312,
                101.34,
                101.372,
                101.403,
                101.432,
                101.463,
                101.494,
                101.523,
                101.554,
                101.585,
                101.614,
                101.645,
                101.676,
                101.704,
                101.735,
                101.766,
                101.795,
                101.826,
                101.857,
                101.885,
                101.916,
                101.947,
                101.975,
                102.006,
                102.037,
                102.065,
                102.096,
                102.127,
                102.155,
                102.186,
                102.217,
                102.245,
                102.275,
                102.306,
                102.334,
                102.365,
                102.396,
                102.423,
                102.454,
                102.485,
                102.513,
                102.543,
                102.574,
                102.602,
                102.632,
                102.663,
                102.69,
                102.721,
                102.749,
                102.779,
                102.81,
                102.837,
                102.868,
                102.898,
                102.925,
                102.956,
                102.986,
                103.014,
                103.044,
                103.074,
                103.102,
                103.132,
                103.162,
                103.19,
                103.22,
                103.247,
                103.278,
                103.308,
                103.335,
                103.365,
                103.395,
                103.423,
                103.453,
                103.483,
                103.51,
                103.54,
                103.567,
                103.597,
                103.627,
                103.654,
                103.684,
                103.714,
                103.741,
                103.771,
                103.801,
                103.828,
                103.858,
                103.885,
                103.915,
                103.945,
                103.972,
                104.002,
                104.031,
                104.058,
                104.088,
                104.115,
                104.145,
                104.174,
                104.201,
                104.231,
                104.261,
                104.287,
                104.317,
                104.344,
                104.373,
                104.403,
                104.43,
                104.459,
                104.489,
                104.516,
                104.545,
                104.572,
                104.601,
                104.631,
                104.657,
                104.687,
                104.713,
                104.743,
                104.772,
                104.799,
                104.828,
                104.855,
                104.884,
                104.913,
                104.94,
                104.969,
                104.998,
                105.025,
                105.054,
                105.081,
                105.11,
                105.139,
                105.165,
                105.195,
                105.221,
                105.25,
                105.279,
                105.306,
                105.335,
                105.361,
                105.39,
                105.419,
                105.446,
                105.475,
                105.501,
                105.53,
                105.559,
                105.585,
                105.614,
                105.64,
                105.669,
                105.698,
                105.724,
                105.753,
                105.779,
                105.808,
                105.837,
                105.863,
                105.892,
                105.918,
                105.947,
                105.976,
                106.002,
                106.031,
                106.057,
                106.086,
                106.112,
                106.14,
                106.169,
                106.195,
                106.224,
                106.25,
                106.278,
                106.307,
                106.333,
                106.362,
                106.387,
                106.416,
                106.445,
                106.471,
                106.499,
                106.525,
                106.554,
                106.579,
                106.608,
                106.637,
                106.662,
                106.691,
                106.716,
                106.745,
                106.771,
                106.799,
                106.828,
                106.853,
                106.882,
                106.907,
                106.936,
                106.961,
                106.99,
                107.018,
                107.044,
                107.072,
                107.098,
                107.126,
                107.152,
                107.18,
                107.208,
                107.234,
                107.262,
                107.288,
                107.316,
                107.341,
                107.37,
                107.398,
                107.423,
                107.452,
                107.477,
                107.505,
                107.53,
                107.559,
                107.587,
                107.612,
                107.64,
                107.666,
                107.694,
                107.719,
                107.747,
                107.772,
                107.801,
                107.829,
                107.854,
                107.882,
                107.907,
                107.935,
                107.96,
                107.989,
                108.017,
                108.042,
                108.07,
                108.095,
                108.123,
                108.148,
                108.176,
                108.201,
                108.229,
                108.254,
                108.282,
                108.31,
                108.335,
                108.363,
                108.388,
                108.416,
                108.441,
                108.468,
                108.493,
                108.521,
                108.549,
                108.574,
                108.602,
                108.627,
                108.655,
                108.679,
                108.707,
                108.732,
                108.76,
                108.785,
                108.812,
                108.84,
                108.865,
                108.893,
                108.917,
                108.945,
                108.97,
                108.997,
                109.022,
                109.05,
                109.075,
                109.102,
                109.127,
                109.155,
                109.182,
                109.207,
                109.234,
                109.259,
                109.287,
                109.311,
                109.339,
                109.363,
                109.391,
                109.415,
                109.443,
                109.467,
                109.495,
                109.522,
                109.547,
                109.574,
                109.599,
                109.626,
                109.651,
                109.678,
                109.703,
                109.73,
                109.755,
                109.782,
                109.806,
                109.834,
                109.858,
                109.885,
                109.91,
                109.937,
                109.965,
                109.989,
                110.016,
                110.04,
                110.068,
                110.092,
                110.119,
                110.144,
                110.171,
                110.195,
                110.222,
                110.246,
                110.274,
                110.298,
                110.325,
                110.349,
                110.376,
                110.401,
                110.428,
                110.452,
                110.479,
                110.503,
                110.53,
                110.557,
                110.582,
                110.609,
                110.633,
                110.66,
                110.684,
                110.711,
                110.735,
                110.762,
                110.786,
                110.813,
                110.837,
                110.864,
                110.888,
                110.915,
                110.939,
                110.966,
                110.99,
                111.017,
                111.04,
                111.067,
                111.091,
                111.118,
                111.142,
                111.169,
                111.193,
                111.22,
                111.244,
                111.27,
                111.294,
                111.321,
                111.345,
                111.372,
                111.395,
                111.422,
                111.446,
                111.473,
                111.499,
                111.523,
                111.55,
                111.573,
                111.6,
                111.624,
                111.651,
                111.674,
                111.701,
                111.724,
                111.751,
                111.775,
                111.801,
                111.825,
                111.852,
                111.875,
                111.902,
                111.925,
                111.952,
                111.975,
                112.002,
                112.026,
                112.052,
                112.075,
                112.102,
                112.125,
                112.152,
                112.175,
                112.202,
                112.225,
                112.252,
                112.275,
                112.302,
                112.325,
                112.352,
                112.375,
                112.401,
                112.425,
                112.451,
                112.474,
                112.501,
                112.524,
                112.55,
                112.574,
                112.6,
                112.623,
                112.65,
                112.673,
                112.696,
                112.722,
                112.746,
                112.772,
                112.795,
                112.821,
                112.844,
                112.871,
                112.894,
                112.92,
                112.943,
                112.969,
                112.993,
                113.019,
                113.042,
                113.068,
                113.091,
                113.117,
                113.14,
                113.166,
                113.189,
                113.216,
                113.239,
                113.265,
                113.288,
                113.314,
                113.337,
                113.363,
                113.386,
                113.412,
                113.435,
                113.461,
                113.484,
                113.51,
                113.533,
                113.559,
                113.581,
                113.607,
                113.63,
                113.656,
                113.679,
                113.702,
                113.728,
                113.751,
                113.776,
                113.799,
                113.825,
                113.848,
                113.874,
                113.897,
                113.922,
                113.945,
                113.971,
                113.994,
                114.02,
                114.042,
                114.068,
                114.091,
                114.116,
                114.139,
                114.165,
                114.188,
                114.213,
                114.236,
                114.262,
                114.284,
                114.307,
                114.333,
                114.355,
                114.381,
                114.403,
                114.429,
                114.452,
                114.477,
                114.5,
                114.525,
                114.548,
                114.573,
                114.596,
                114.622,
                114.644,
                114.67,
                114.692,
                114.718,
                114.74,
                114.762,
                114.788,
                114.81,
                114.836,
                114.858,
                114.884,
                114.906,
                114.932,
                114.954,
                114.979,
                115.002,
                115.027,
                115.05,
                115.075,
                115.097,
                115.123,
                115.145,
                115.167,
                115.193,
                115.215,
                115.24,
                115.263,
                115.288,
                115.31,
                115.336,
                115.358,
                115.383,
                115.405,
                115.431,
                115.453,
                115.475,
                115.5,
                115.522,
                115.548,
                115.57,
                115.595,
                115.617,
                115.643,
                115.665,
                115.69,
                115.712,
                115.737,
                115.759,
                115.781,
                115.807,
                115.829,
                115.854,
                115.876,
                115.901,
                115.923,
                115.948,
                115.97,
                115.995,
                116.017,
                116.042,
                116.064,
                116.086,
                116.111,
                116.133,
                116.158,
                116.18,
                116.205,
                116.227,
                116.252,
                116.274,
                116.299,
                116.321,
                116.343,
                116.368,
                116.39,
                116.415,
                116.437,
                116.462,
                116.484,
                116.509,
                116.531,
                116.556,
                116.577,
                116.599,
                116.624,
                116.646,
                116.671,
                116.693,
                116.718,
                116.739,
                116.764,
                116.786,
                116.811,
                116.832,
                116.854,
                116.879,
                116.901,
                116.926,
                116.947,
                116.972,
                116.994,
                117.019,
                117.04,
                117.062,
                117.087,
                117.109,
                117.133,
                117.155,
                117.18,
                117.201,
                117.226,
                117.248,
                117.269,
                117.294,
                117.316,
                117.34,
                117.362,
                117.387,
                117.408,
                117.433,
                117.454,
                117.476,
                117.501,
                117.522,
                117.547,
                117.568,
                117.593,
                117.615,
                117.639,
                117.661,
                117.682,
                117.707,
                117.728,
                117.753,
                117.774,
                117.799,
                117.82,
                117.842,
                117.866,
                117.888,
                117.912,
                117.934,
                117.958,
                117.98,
                118.004,
                118.026,
                118.047,
                118.071,
                118.093,
                118.117,
                118.139,
                118.163,
                118.185,
                118.206,
                118.23,
                118.252,
                118.276,
                118.297,
                118.322,
                118.343,
                118.368,
                118.389,
                118.41,
                118.435,
                118.456,
                118.48,
                118.502,
                118.526,
                118.547,
                118.568,
                118.593,
                118.614,
                118.638,
                118.66,
                118.684,
                118.705,
                118.726,
                118.751,
                118.772,
                118.796,
                118.817,
                118.842,
                118.863,
                118.884,
                118.908,
                118.929,
                118.954,
                118.975,
                118.999,
                119.02,
                119.041,
                119.065,
                119.086,
                119.111,
                119.132,
                119.156,
                119.177,
                119.198,
                119.222,
                119.243,
                119.268,
                119.289,
                119.313,
                119.334,
                119.355,
                119.379,
                119.4,
                119.424,
                119.445,
                119.469,
                119.49,
                119.511,
                119.535,
                119.556,
                119.58,
                119.601,
                119.625,
                119.646,
                119.667,
                119.691,
                119.712,
                119.736,
                119.757,
                119.778,
                119.802,
                119.823,
                119.847,
                119.868,
                119.892,
                119.913,
                119.934,
                119.958,
                119.979,
                120.003,
                120.023,
                120.047,
                120.068,
                120.089,
                120.113,
                120.134,
                120.158,
                120.179,
                120.199,
                120.223,
                120.244,
                120.268,
                120.289,
                120.313,
                120.333,
                120.354,
                120.378,
                120.399,
                120.423,
                120.443,
                120.464,
                120.488,
                120.509,
                120.532,
                120.553,
                120.577,
                120.598,
                120.618,
                120.642,
                120.663,
                120.687,
                120.707,
                120.728,
                120.752,
                120.772,
                120.796,
                120.817,
                120.84,
                120.861,
                120.881,
                120.905,
                120.926,
                120.95,
                120.97,
                120.991,
                121.014,
                121.035,
                121.059,
                121.079,
                121.1,
                121.123,
                121.144,
                121.168,
                121.188,
                121.212,
                121.232,
                121.253,
                121.276,
                121.297,
                121.321,
                121.341,
                121.361,
                121.385,
                121.405,
                121.429,
                121.449,
                121.47,
                121.494,
                121.514,
                121.538,
                121.558,
                121.582,
                121.602,
                121.622,
                121.646,
                121.666,
                121.69,
                121.71,
                121.73,
                121.754,
                121.774,
                121.798,
                121.818,
                121.838,
                121.862,
                121.882,
                121.906,
                121.926,
                121.946,
                121.97,
                121.99,
                122.013,
                122.034,
                122.057,
                122.077,
                122.098,
                122.121,
                122.141,
                122.165,
                122.185,
                122.205,
                122.228,
                122.249,
                122.272,
                122.292,
                122.312,
                122.336,
                122.356,
                122.379,
                122.399,
                122.419,
                122.443,
                122.463,
                122.486,
                122.506,
                122.526,
                122.55,
                122.57,
                122.593,
                122.613,
                122.633,
                122.657,
                122.677,
                122.7,
                122.72,
                122.743,
                122.763,
                122.783,
                122.807,
                122.827,
                122.85,
                122.87,
                122.89,
                122.913,
                122.933,
                122.956,
                122.976,
                122.996,
                123.019,
                123.039,
                123.063,
                123.082,
                123.102,
                123.126,
                123.145,
                123.169,
                123.189,
                123.208,
                123.232,
                123.252,
                123.275,
                123.295,
                123.314,
                123.337,
                123.357,
                123.381,
                123.4,
                123.42,
                123.443,
                123.463,
                123.486,
                123.506,
                123.526,
                123.549,
                123.569,
                123.592,
                123.611,
                123.631,
                123.654,
                123.674,
                123.697,
                123.717,
                123.737,
                123.76,
                123.779,
                123.802,
                123.822,
                123.842,
                123.865,
                123.885,
                123.908,
                123.927,
                123.947,
                123.97,
                123.99,
                124.013,
                124.032,
                124.052,
                124.075,
                124.094,
                124.114,
                124.137,
                124.157,
                124.18,
                124.199,
                124.219,
                124.242,
                124.261,
                124.284,
                124.304,
                124.323,
                124.346,
                124.366,
                124.389,
                124.408,
                124.428,
                124.451,
                124.47,
                124.493,
                124.513,
                124.532,
                124.555,
                124.575,
                124.597,
                124.617,
                124.636,
                124.659,
                124.679,
                124.702,
                124.721,
                124.74
            ]
        }
    },
    head: {
        boys: {
            P01: [
                30.536,
                30.657,
                30.779,
                30.901,
                31.023,
                31.146,
                31.267,
                31.391,
                31.514,
                31.636,
                31.76,
                31.883,
                32.007,
                32.131,
                32.254,
                32.355,
                32.454,
                32.55,
                32.644,
                32.736,
                32.825,
                32.913,
                33,
                33.084,
                33.167,
                33.248,
                33.329,
                33.407,
                33.484,
                33.561,
                33.635,
                33.709,
                33.781,
                33.852,
                33.922,
                33.991,
                34.06,
                34.126,
                34.192,
                34.258,
                34.322,
                34.386,
                34.448,
                34.51,
                34.572,
                34.632,
                34.691,
                34.751,
                34.809,
                34.866,
                34.923,
                34.979,
                35.035,
                35.09,
                35.145,
                35.198,
                35.252,
                35.305,
                35.357,
                35.409,
                35.46,
                35.51,
                35.562,
                35.611,
                35.661,
                35.71,
                35.758,
                35.806,
                35.854,
                35.901,
                35.948,
                35.995,
                36.041,
                36.086,
                36.132,
                36.177,
                36.221,
                36.266,
                36.309,
                36.353,
                36.395,
                36.439,
                36.481,
                36.522,
                36.565,
                36.606,
                36.647,
                36.688,
                36.728,
                36.769,
                36.809,
                36.849,
                36.888,
                36.927,
                36.966,
                37.005,
                37.043,
                37.08,
                37.118,
                37.156,
                37.193,
                37.23,
                37.266,
                37.303,
                37.339,
                37.374,
                37.41,
                37.446,
                37.481,
                37.516,
                37.55,
                37.585,
                37.619,
                37.654,
                37.687,
                37.721,
                37.754,
                37.787,
                37.82,
                37.853,
                37.885,
                37.918,
                37.95,
                37.981,
                38.014,
                38.045,
                38.076,
                38.107,
                38.137,
                38.169,
                38.199,
                38.229,
                38.259,
                38.289,
                38.319,
                38.348,
                38.377,
                38.406,
                38.435,
                38.464,
                38.492,
                38.521,
                38.549,
                38.577,
                38.605,
                38.633,
                38.661,
                38.688,
                38.715,
                38.743,
                38.768,
                38.795,
                38.822,
                38.848,
                38.875,
                38.9,
                38.926,
                38.952,
                38.978,
                39.002,
                39.028,
                39.053,
                39.078,
                39.102,
                39.127,
                39.152,
                39.175,
                39.2,
                39.224,
                39.247,
                39.272,
                39.296,
                39.318,
                39.342,
                39.364,
                39.388,
                39.411,
                39.433,
                39.457,
                39.478,
                39.501,
                39.523,
                39.545,
                39.567,
                39.589,
                39.61,
                39.632,
                39.653,
                39.675,
                39.695,
                39.717,
                39.737,
                39.759,
                39.779,
                39.8,
                39.82,
                39.841,
                39.861,
                39.88,
                39.901,
                39.92,
                39.941,
                39.96,
                39.979,
                39.999,
                40.018,
                40.037,
                40.056,
                40.075,
                40.093,
                40.113,
                40.131,
                40.149,
                40.168,
                40.186,
                40.204,
                40.223,
                40.24,
                40.258,
                40.277,
                40.294,
                40.311,
                40.33,
                40.347,
                40.363,
                40.38,
                40.398,
                40.415,
                40.432,
                40.448,
                40.466,
                40.482,
                40.498,
                40.514,
                40.532,
                40.548,
                40.563,
                40.579,
                40.595,
                40.612,
                40.627,
                40.643,
                40.658,
                40.674,
                40.69,
                40.705,
                40.72,
                40.735,
                40.75,
                40.766,
                40.781,
                40.796,
                40.81,
                40.825,
                40.839,
                40.855,
                40.869,
                40.884,
                40.898,
                40.912,
                40.926,
                40.94,
                40.955,
                40.969,
                40.983,
                40.996,
                41.01,
                41.024,
                41.037,
                41.051,
                41.065,
                41.079,
                41.092,
                41.105,
                41.118,
                41.131,
                41.144,
                41.157,
                41.17,
                41.184,
                41.197,
                41.209,
                41.222,
                41.235,
                41.247,
                41.259,
                41.272,
                41.284,
                41.296,
                41.309,
                41.321,
                41.334,
                41.346,
                41.358,
                41.37,
                41.382,
                41.394,
                41.405,
                41.417,
                41.429,
                41.44,
                41.452,
                41.463,
                41.475,
                41.486,
                41.497,
                41.509,
                41.521,
                41.532,
                41.544,
                41.555,
                41.566,
                41.577,
                41.587,
                41.598,
                41.609,
                41.62,
                41.631,
                41.641,
                41.652,
                41.662,
                41.673,
                41.684,
                41.694,
                41.704,
                41.715,
                41.725,
                41.735,
                41.745,
                41.756,
                41.766,
                41.776,
                41.786,
                41.796,
                41.806,
                41.815,
                41.825,
                41.835,
                41.845,
                41.855,
                41.864,
                41.875,
                41.885,
                41.895,
                41.904,
                41.914,
                41.923,
                41.932,
                41.942,
                41.951,
                41.96,
                41.97,
                41.979,
                41.988,
                41.997,
                42.006,
                42.015,
                42.024,
                42.033,
                42.042,
                42.051,
                42.06,
                42.067,
                42.076,
                42.085,
                42.094,
                42.102,
                42.111,
                42.12,
                42.128,
                42.137,
                42.145,
                42.154,
                42.162,
                42.171,
                42.179,
                42.187,
                42.196,
                42.204,
                42.212,
                42.22,
                42.229,
                42.237,
                42.245,
                42.253,
                42.261,
                42.269,
                42.277,
                42.285,
                42.293,
                42.301,
                42.309,
                42.317,
                42.325,
                42.332,
                42.34,
                42.348,
                42.356,
                42.363,
                42.371,
                42.379,
                42.386,
                42.393,
                42.4,
                42.408,
                42.415,
                42.423,
                42.43,
                42.438,
                42.445,
                42.452,
                42.46,
                42.467,
                42.474,
                42.482,
                42.489,
                42.496,
                42.503,
                42.511,
                42.518,
                42.525,
                42.532,
                42.539,
                42.546,
                42.553,
                42.559,
                42.566,
                42.573,
                42.58,
                42.586,
                42.593,
                42.6,
                42.607,
                42.614,
                42.621,
                42.628,
                42.634,
                42.641,
                42.648,
                42.655,
                42.661,
                42.668,
                42.675,
                42.68,
                42.686,
                42.693,
                42.699,
                42.706,
                42.713,
                42.719,
                42.726,
                42.732,
                42.738,
                42.745,
                42.751,
                42.758,
                42.764,
                42.77,
                42.777,
                42.782,
                42.788,
                42.794,
                42.8,
                42.807,
                42.813,
                42.819,
                42.825,
                42.831,
                42.838,
                42.844,
                42.85,
                42.856,
                42.862,
                42.867,
                42.873,
                42.879,
                42.885,
                42.891,
                42.897,
                42.903,
                42.909,
                42.915,
                42.921,
                42.927,
                42.932,
                42.938,
                42.943,
                42.949,
                42.954,
                42.96,
                42.966,
                42.972,
                42.978,
                42.983,
                42.989,
                42.995,
                43.001,
                43.006,
                43.012,
                43.016,
                43.022,
                43.027,
                43.033,
                43.039,
                43.044,
                43.05,
                43.055,
                43.061,
                43.067,
                43.072,
                43.078,
                43.082,
                43.087,
                43.093,
                43.098,
                43.104,
                43.109,
                43.114,
                43.12,
                43.125,
                43.131,
                43.136,
                43.142,
                43.145,
                43.151,
                43.156,
                43.161,
                43.167,
                43.172,
                43.177,
                43.183,
                43.188,
                43.193,
                43.198,
                43.202,
                43.207,
                43.213,
                43.218,
                43.223,
                43.228,
                43.233,
                43.239,
                43.244,
                43.249,
                43.252,
                43.258,
                43.263,
                43.268,
                43.273,
                43.278,
                43.283,
                43.288,
                43.293,
                43.298,
                43.303,
                43.307,
                43.312,
                43.317,
                43.322,
                43.327,
                43.332,
                43.337,
                43.342,
                43.347,
                43.352,
                43.355,
                43.36,
                43.365,
                43.37,
                43.375,
                43.379,
                43.384,
                43.389,
                43.394,
                43.399,
                43.402,
                43.407,
                43.412,
                43.417,
                43.422,
                43.426,
                43.431,
                43.436,
                43.441,
                43.444,
                43.449,
                43.453,
                43.458,
                43.463,
                43.468,
                43.472,
                43.477,
                43.482,
                43.485,
                43.49,
                43.494,
                43.499,
                43.504,
                43.508,
                43.513,
                43.518,
                43.522,
                43.527,
                43.53,
                43.535,
                43.539,
                43.544,
                43.548,
                43.553,
                43.558,
                43.562,
                43.567,
                43.57,
                43.574,
                43.579,
                43.583,
                43.588,
                43.592,
                43.597,
                43.601,
                43.604,
                43.609,
                43.613,
                43.618,
                43.622,
                43.627,
                43.631,
                43.636,
                43.64,
                43.643,
                43.648,
                43.652,
                43.656,
                43.661,
                43.665,
                43.67,
                43.674,
                43.677,
                43.681,
                43.686,
                43.69,
                43.694,
                43.699,
                43.703,
                43.707,
                43.712,
                43.715,
                43.719,
                43.723,
                43.728,
                43.732,
                43.736,
                43.74,
                43.745,
                43.748,
                43.752,
                43.756,
                43.76,
                43.765,
                43.769,
                43.773,
                43.777,
                43.78,
                43.784,
                43.789,
                43.793,
                43.797,
                43.801,
                43.805,
                43.81,
                43.812,
                43.816,
                43.821,
                43.825,
                43.829,
                43.833,
                43.837,
                43.841,
                43.844,
                43.848,
                43.852,
                43.857,
                43.861,
                43.865,
                43.869,
                43.873,
                43.876,
                43.88,
                43.884,
                43.888,
                43.892,
                43.896,
                43.9,
                43.904,
                43.907,
                43.911,
                43.915,
                43.919,
                43.923,
                43.927,
                43.931,
                43.935,
                43.937,
                43.941,
                43.945,
                43.949,
                43.953,
                43.957,
                43.961,
                43.964,
                43.968,
                43.972,
                43.976,
                43.98,
                43.984,
                43.988,
                43.992,
                43.994,
                43.998,
                44.002,
                44.006,
                44.01,
                44.014,
                44.018,
                44.021,
                44.024,
                44.028,
                44.032,
                44.035,
                44.039,
                44.043,
                44.047,
                44.05,
                44.053,
                44.057,
                44.061,
                44.065,
                44.069,
                44.073,
                44.075,
                44.079,
                44.082,
                44.086,
                44.09,
                44.094,
                44.098,
                44.101,
                44.104,
                44.107,
                44.111,
                44.115,
                44.119,
                44.123,
                44.126,
                44.129,
                44.132,
                44.136,
                44.14,
                44.144,
                44.147,
                44.151,
                44.155,
                44.157,
                44.161,
                44.164,
                44.168,
                44.172,
                44.175,
                44.179,
                44.181,
                44.185,
                44.189,
                44.192,
                44.196,
                44.199,
                44.203,
                44.205,
                44.209,
                44.212,
                44.216,
                44.22,
                44.223,
                44.227,
                44.229,
                44.233,
                44.236,
                44.24,
                44.243,
                44.247,
                44.251,
                44.254,
                44.256,
                44.26,
                44.263,
                44.267,
                44.27,
                44.274,
                44.277,
                44.279,
                44.283,
                44.286,
                44.29,
                44.294,
                44.297,
                44.301,
                44.302,
                44.306,
                44.309,
                44.313,
                44.316,
                44.32,
                44.323,
                44.325,
                44.329,
                44.332,
                44.336,
                44.339,
                44.343,
                44.346,
                44.348,
                44.351,
                44.355,
                44.358,
                44.362,
                44.365,
                44.368,
                44.37,
                44.374,
                44.377,
                44.38,
                44.384,
                44.387,
                44.391,
                44.394,
                44.396,
                44.399,
                44.402,
                44.406,
                44.409,
                44.412,
                44.416,
                44.418,
                44.421,
                44.424,
                44.427,
                44.431,
                44.434,
                44.437,
                44.439,
                44.442,
                44.446,
                44.449,
                44.452,
                44.456,
                44.459,
                44.461,
                44.464,
                44.467,
                44.47,
                44.474,
                44.477,
                44.48,
                44.482,
                44.485,
                44.488,
                44.491,
                44.495,
                44.498,
                44.501,
                44.503,
                44.506,
                44.509,
                44.512,
                44.515,
                44.519,
                44.522,
                44.523,
                44.526,
                44.53,
                44.533,
                44.536,
                44.539,
                44.542,
                44.544,
                44.547,
                44.55,
                44.553,
                44.556,
                44.559,
                44.562,
                44.566,
                44.567,
                44.57,
                44.573,
                44.576,
                44.579,
                44.583,
                44.586,
                44.587,
                44.59,
                44.593,
                44.596,
                44.599,
                44.602,
                44.605,
                44.607,
                44.61,
                44.613,
                44.616,
                44.619,
                44.622,
                44.625,
                44.626,
                44.629,
                44.632,
                44.635,
                44.638,
                44.641,
                44.644,
                44.646,
                44.649,
                44.652,
                44.655,
                44.658,
                44.661,
                44.664,
                44.666,
                44.668,
                44.671,
                44.674,
                44.677,
                44.68,
                44.683,
                44.685,
                44.687,
                44.69,
                44.693,
                44.696,
                44.698,
                44.701,
                44.704,
                44.706,
                44.708,
                44.711,
                44.714,
                44.717,
                44.72,
                44.723,
                44.724,
                44.727,
                44.73,
                44.732,
                44.735,
                44.738,
                44.741,
                44.744,
                44.745,
                44.748,
                44.751,
                44.753,
                44.756,
                44.759,
                44.762,
                44.763,
                44.766,
                44.769,
                44.771,
                44.774,
                44.777,
                44.78,
                44.782,
                44.784,
                44.786,
                44.789,
                44.792,
                44.795,
                44.797,
                44.8,
                44.801,
                44.804,
                44.807,
                44.809,
                44.812,
                44.815,
                44.817,
                44.819,
                44.821,
                44.824,
                44.827,
                44.829,
                44.832,
                44.835,
                44.837,
                44.838,
                44.841,
                44.844,
                44.846,
                44.849,
                44.852,
                44.854,
                44.855,
                44.858,
                44.861,
                44.863,
                44.866,
                44.869,
                44.871,
                44.874,
                44.875,
                44.877,
                44.88,
                44.883,
                44.885,
                44.888,
                44.89,
                44.892,
                44.894,
                44.897,
                44.899,
                44.902,
                44.904,
                44.907,
                44.909,
                44.91,
                44.913,
                44.916,
                44.918,
                44.921,
                44.923,
                44.926,
                44.928,
                44.929,
                44.932,
                44.934,
                44.937,
                44.939,
                44.942,
                44.944,
                44.945,
                44.948,
                44.95,
                44.953,
                44.955,
                44.958,
                44.96,
                44.963,
                44.964,
                44.966,
                44.968,
                44.971,
                44.973,
                44.976,
                44.978,
                44.981,
                44.982,
                44.984,
                44.986,
                44.989,
                44.991,
                44.994,
                44.996,
                44.997,
                45,
                45.002,
                45.004,
                45.007,
                45.009,
                45.012,
                45.014,
                45.015,
                45.017,
                45.02,
                45.022,
                45.024,
                45.027,
                45.029,
                45.031,
                45.032,
                45.035,
                45.037,
                45.039,
                45.042,
                45.044,
                45.046,
                45.049,
                45.05,
                45.052,
                45.054,
                45.057,
                45.059,
                45.061,
                45.064,
                45.066,
                45.067,
                45.069,
                45.071,
                45.074,
                45.076,
                45.078,
                45.08,
                45.083,
                45.084,
                45.086,
                45.088,
                45.09,
                45.093,
                45.095,
                45.097,
                45.1,
                45.1,
                45.102,
                45.105,
                45.107,
                45.109,
                45.111,
                45.114,
                45.116,
                45.117,
                45.119,
                45.121,
                45.123,
                45.126,
                45.128,
                45.13,
                45.132,
                45.133,
                45.135,
                45.137,
                45.14,
                45.142,
                45.144,
                45.146,
                45.148,
                45.149,
                45.151,
                45.154,
                45.156,
                45.158,
                45.16,
                45.162,
                45.164,
                45.165,
                45.167,
                45.169,
                45.172,
                45.174,
                45.176,
                45.178,
                45.18,
                45.181,
                45.183,
                45.185,
                45.187,
                45.189,
                45.192,
                45.194,
                45.196,
                45.198,
                45.199,
                45.201,
                45.203,
                45.205,
                45.207,
                45.209,
                45.211,
                45.213,
                45.214,
                45.216,
                45.218,
                45.22,
                45.222,
                45.224,
                45.227,
                45.229,
                45.229,
                45.231,
                45.233,
                45.235,
                45.238,
                45.24,
                45.242,
                45.244,
                45.244,
                45.246,
                45.248,
                45.25,
                45.252,
                45.255,
                45.257,
                45.259,
                45.261,
                45.261,
                45.263,
                45.265,
                45.267,
                45.269,
                45.271,
                45.273,
                45.275,
                45.276,
                45.278,
                45.28,
                45.282,
                45.284,
                45.286,
                45.288,
                45.29,
                45.292,
                45.292,
                45.294,
                45.297,
                45.299,
                45.301,
                45.303,
                45.305,
                45.307,
                45.307,
                45.309,
                45.311,
                45.313,
                45.315,
                45.317,
                45.319,
                45.321,
                45.323,
                45.323,
                45.325,
                45.327,
                45.329,
                45.331,
                45.333,
                45.335,
                45.337,
                45.339,
                45.339,
                45.341,
                45.343,
                45.345,
                45.347,
                45.349,
                45.351,
                45.353,
                45.353,
                45.355,
                45.357,
                45.359,
                45.361,
                45.363,
                45.365,
                45.367,
                45.368,
                45.369,
                45.371,
                45.373,
                45.375,
                45.377,
                45.378,
                45.38,
                45.382,
                45.384,
                45.384,
                45.386,
                45.388,
                45.39,
                45.392,
                45.394,
                45.396,
                45.398,
                45.399,
                45.4,
                45.402,
                45.404,
                45.405,
                45.407,
                45.409,
                45.411,
                45.413,
                45.415,
                45.415,
                45.417,
                45.419,
                45.421,
                45.422,
                45.424,
                45.426,
                45.428,
                45.43,
                45.43,
                45.432,
                45.434,
                45.436,
                45.437,
                45.439,
                45.441,
                45.443,
                45.445,
                45.445,
                45.447,
                45.449,
                45.45,
                45.452,
                45.454,
                45.456,
                45.458,
                45.459,
                45.46,
                45.461,
                45.463,
                45.465,
                45.467,
                45.469,
                45.47,
                45.472,
                45.474,
                45.474,
                45.476,
                45.478,
                45.48,
                45.481,
                45.483,
                45.485,
                45.487,
                45.488,
                45.489,
                45.49,
                45.492,
                45.494,
                45.496,
                45.497,
                45.499,
                45.501,
                45.503,
                45.503,
                45.505,
                45.506,
                45.508,
                45.51,
                45.512,
                45.513,
                45.515,
                45.517,
                45.517,
                45.519,
                45.52,
                45.522,
                45.524,
                45.526,
                45.527,
                45.529,
                45.531,
                45.532,
                45.533,
                45.534,
                45.536,
                45.538,
                45.539,
                45.541,
                45.543,
                45.545,
                45.546,
                45.546,
                45.548,
                45.55,
                45.551,
                45.553,
                45.555,
                45.556,
                45.558,
                45.56,
                45.56,
                45.562,
                45.563,
                45.565,
                45.567,
                45.568,
                45.57,
                45.572,
                45.573,
                45.575,
                45.575,
                45.577,
                45.578,
                45.58,
                45.582,
                45.583,
                45.585,
                45.587,
                45.588,
                45.588,
                45.59,
                45.592,
                45.593,
                45.595,
                45.597,
                45.598,
                45.6,
                45.602,
                45.603,
                45.603,
                45.605,
                45.607,
                45.608,
                45.61,
                45.611,
                45.613,
                45.615,
                45.616,
                45.618,
                45.618,
                45.619,
                45.621,
                45.623,
                45.624,
                45.626,
                45.627,
                45.629,
                45.631,
                45.631,
                45.632,
                45.634,
                45.636,
                45.637,
                45.639,
                45.64,
                45.642,
                45.644,
                45.645,
                45.645,
                45.647,
                45.648,
                45.65,
                45.651,
                45.653,
                45.655,
                45.656,
                45.658,
                45.659,
                45.659,
                45.661,
                45.662,
                45.664,
                45.665,
                45.667,
                45.669,
                45.67,
                45.672,
                45.673,
                45.673,
                45.675,
                45.676,
                45.678,
                45.679,
                45.681,
                45.682,
                45.684,
                45.686,
                45.686,
                45.687,
                45.689,
                45.69,
                45.692,
                45.693,
                45.695,
                45.696,
                45.698,
                45.699,
                45.699,
                45.701,
                45.702,
                45.704,
                45.705,
                45.707,
                45.708,
                45.71,
                45.711,
                45.713,
                45.713,
                45.714,
                45.716,
                45.717,
                45.719,
                45.72,
                45.722,
                45.723,
                45.725,
                45.726,
                45.728,
                45.728,
                45.729,
                45.731,
                45.732,
                45.733,
                45.735,
                45.736,
                45.738,
                45.739,
                45.741,
                45.741,
                45.742,
                45.744,
                45.745,
                45.747,
                45.748,
                45.75,
                45.751,
                45.752,
                45.754,
                45.754,
                45.755,
                45.757,
                45.758,
                45.76,
                45.761,
                45.763,
                45.764,
                45.765,
                45.767,
                45.767,
                45.768,
                45.77,
                45.771,
                45.773,
                45.774,
                45.775,
                45.777,
                45.778,
                45.78,
                45.78,
                45.781,
                45.782,
                45.784,
                45.785,
                45.787,
                45.788,
                45.79,
                45.791,
                45.792,
                45.794,
                45.794,
                45.795,
                45.796,
                45.798,
                45.799,
                45.801,
                45.802,
                45.804,
                45.805,
                45.806,
                45.806,
                45.808,
                45.809,
                45.81,
                45.812,
                45.813,
                45.815,
                45.816,
                45.817,
                45.819,
                45.82,
                45.82,
                45.821,
                45.823,
                45.824,
                45.825,
                45.827,
                45.828,
                45.83,
                45.831,
                45.832,
                45.832,
                45.834,
                45.835,
                45.836,
                45.838,
                45.839,
                45.84,
                45.842,
                45.843,
                45.845,
                45.846,
                45.846,
                45.847,
                45.848,
                45.85,
                45.851,
                45.853,
                45.854,
                45.855,
                45.857,
                45.858,
                45.858,
                45.859,
                45.86,
                45.862,
                45.863,
                45.864,
                45.866,
                45.867,
                45.868,
                45.87,
                45.871,
                45.871,
                45.872,
                45.874,
                45.875,
                45.876,
                45.878,
                45.879,
                45.88,
                45.882,
                45.883,
                45.884,
                45.884,
                45.885,
                45.887,
                45.888,
                45.889,
                45.891,
                45.892,
                45.893,
                45.895,
                45.896,
                45.897,
                45.897,
                45.898,
                45.9,
                45.901,
                45.902,
                45.904,
                45.905,
                45.906,
                45.908,
                45.909,
                45.909,
                45.91,
                45.911,
                45.912,
                45.914,
                45.915,
                45.916,
                45.918,
                45.919,
                45.92,
                45.922,
                45.921,
                45.923,
                45.924,
                45.925,
                45.926,
                45.928,
                45.929,
                45.93,
                45.932,
                45.933,
                45.934,
                45.934,
                45.935,
                45.936,
                45.938,
                45.939,
                45.94,
                45.942,
                45.943,
                45.944,
                45.945,
                45.947,
                45.946,
                45.948,
                45.949,
                45.95,
                45.951,
                45.953,
                45.954,
                45.955,
                45.957,
                45.958,
                45.959,
                45.959,
                45.96,
                45.961,
                45.963,
                45.964,
                45.965,
                45.966,
                45.968,
                45.969,
                45.97,
                45.971,
                45.973,
                45.972,
                45.973,
                45.975,
                45.976,
                45.977,
                45.978,
                45.98,
                45.981,
                45.982,
                45.983,
                45.985,
                45.984,
                45.986,
                45.987,
                45.988,
                45.989,
                45.991,
                45.992,
                45.993,
                45.994,
                45.996,
                45.997,
                45.996,
                45.998,
                45.999,
                46,
                46.001,
                46.003,
                46.004,
                46.005,
                46.006,
                46.008,
                46.009,
                46.008,
                46.01,
                46.011,
                46.012,
                46.013,
                46.015,
                46.016,
                46.017,
                46.018,
                46.019,
                46.021,
                46.022,
                46.022,
                46.023,
                46.024,
                46.025,
                46.026,
                46.028,
                46.029,
                46.03,
                46.031,
                46.032,
                46.034,
                46.033,
                46.035,
                46.036,
                46.037,
                46.038,
                46.039,
                46.041,
                46.042,
                46.043,
                46.044,
                46.045,
                46.045,
                46.046,
                46.047,
                46.049,
                46.05,
                46.051,
                46.052,
                46.053,
                46.055,
                46.056,
                46.057,
                46.058,
                46.058,
                46.059,
                46.06,
                46.061,
                46.063,
                46.064,
                46.065,
                46.066,
                46.067,
                46.068,
                46.07,
                46.071,
                46.07,
                46.072,
                46.073,
                46.074,
                46.075,
                46.076,
                46.078,
                46.079,
                46.08,
                46.081,
                46.082,
                46.082,
                46.083,
                46.084,
                46.085,
                46.087,
                46.088,
                46.089,
                46.09,
                46.091,
                46.093,
                46.094,
                46.095,
                46.094,
                46.096,
                46.097,
                46.098,
                46.099,
                46.1,
                46.101,
                46.103,
                46.104,
                46.105,
                46.106,
                46.107,
                46.107,
                46.108,
                46.109,
                46.11,
                46.112,
                46.113,
                46.114,
                46.115,
                46.116,
                46.117,
                46.119,
                46.12,
                46.119,
                46.12,
                46.122,
                46.123,
                46.124,
                46.125,
                46.126,
                46.127,
                46.129,
                46.13,
                46.131,
                46.13,
                46.132,
                46.133,
                46.134,
                46.135,
                46.136,
                46.137,
                46.139,
                46.14,
                46.141,
                46.142,
                46.143,
                46.143,
                46.144,
                46.145,
                46.146,
                46.147,
                46.148,
                46.15
            ],
            P1: [
                31.507,
                31.622,
                31.739,
                31.855,
                31.972,
                32.089,
                32.206,
                32.323,
                32.441,
                32.558,
                32.675,
                32.793,
                32.911,
                33.029,
                33.147,
                33.248,
                33.346,
                33.442,
                33.536,
                33.628,
                33.717,
                33.805,
                33.892,
                33.976,
                34.059,
                34.14,
                34.22,
                34.299,
                34.376,
                34.453,
                34.527,
                34.601,
                34.673,
                34.744,
                34.814,
                34.883,
                34.952,
                35.019,
                35.085,
                35.15,
                35.215,
                35.279,
                35.341,
                35.403,
                35.465,
                35.525,
                35.585,
                35.644,
                35.702,
                35.76,
                35.817,
                35.873,
                35.929,
                35.985,
                36.04,
                36.093,
                36.147,
                36.2,
                36.252,
                36.305,
                36.356,
                36.406,
                36.458,
                36.508,
                36.557,
                36.606,
                36.655,
                36.703,
                36.751,
                36.799,
                36.846,
                36.893,
                36.939,
                36.985,
                37.03,
                37.076,
                37.12,
                37.165,
                37.208,
                37.252,
                37.295,
                37.339,
                37.381,
                37.423,
                37.466,
                37.508,
                37.549,
                37.59,
                37.63,
                37.672,
                37.712,
                37.751,
                37.791,
                37.83,
                37.87,
                37.908,
                37.947,
                37.985,
                38.023,
                38.061,
                38.098,
                38.136,
                38.172,
                38.209,
                38.246,
                38.281,
                38.318,
                38.354,
                38.389,
                38.424,
                38.459,
                38.494,
                38.528,
                38.563,
                38.597,
                38.631,
                38.664,
                38.698,
                38.731,
                38.764,
                38.796,
                38.83,
                38.862,
                38.894,
                38.926,
                38.958,
                38.989,
                39.02,
                39.052,
                39.083,
                39.114,
                39.144,
                39.175,
                39.205,
                39.235,
                39.264,
                39.294,
                39.323,
                39.353,
                39.382,
                39.411,
                39.439,
                39.468,
                39.496,
                39.525,
                39.553,
                39.581,
                39.608,
                39.636,
                39.664,
                39.69,
                39.717,
                39.744,
                39.771,
                39.798,
                39.823,
                39.849,
                39.876,
                39.902,
                39.927,
                39.953,
                39.978,
                40.004,
                40.028,
                40.053,
                40.078,
                40.102,
                40.127,
                40.152,
                40.175,
                40.2,
                40.224,
                40.247,
                40.271,
                40.294,
                40.318,
                40.341,
                40.364,
                40.387,
                40.409,
                40.433,
                40.455,
                40.477,
                40.499,
                40.522,
                40.543,
                40.566,
                40.587,
                40.609,
                40.63,
                40.652,
                40.673,
                40.694,
                40.715,
                40.736,
                40.757,
                40.778,
                40.798,
                40.818,
                40.839,
                40.858,
                40.879,
                40.899,
                40.918,
                40.938,
                40.957,
                40.977,
                40.997,
                41.015,
                41.034,
                41.054,
                41.072,
                41.091,
                41.11,
                41.129,
                41.147,
                41.166,
                41.184,
                41.202,
                41.22,
                41.238,
                41.256,
                41.274,
                41.292,
                41.309,
                41.326,
                41.344,
                41.361,
                41.378,
                41.395,
                41.413,
                41.429,
                41.446,
                41.463,
                41.48,
                41.496,
                41.512,
                41.529,
                41.545,
                41.562,
                41.578,
                41.593,
                41.609,
                41.625,
                41.641,
                41.657,
                41.672,
                41.688,
                41.703,
                41.719,
                41.734,
                41.749,
                41.764,
                41.779,
                41.794,
                41.81,
                41.824,
                41.839,
                41.853,
                41.868,
                41.882,
                41.896,
                41.912,
                41.926,
                41.94,
                41.954,
                41.968,
                41.982,
                41.995,
                42.009,
                42.024,
                42.037,
                42.051,
                42.064,
                42.078,
                42.091,
                42.104,
                42.118,
                42.131,
                42.145,
                42.158,
                42.171,
                42.184,
                42.197,
                42.209,
                42.222,
                42.235,
                42.247,
                42.26,
                42.272,
                42.285,
                42.298,
                42.311,
                42.323,
                42.335,
                42.347,
                42.359,
                42.371,
                42.383,
                42.395,
                42.407,
                42.419,
                42.43,
                42.442,
                42.454,
                42.465,
                42.477,
                42.489,
                42.501,
                42.512,
                42.523,
                42.535,
                42.546,
                42.557,
                42.568,
                42.579,
                42.59,
                42.601,
                42.612,
                42.623,
                42.634,
                42.645,
                42.655,
                42.666,
                42.677,
                42.687,
                42.698,
                42.708,
                42.719,
                42.729,
                42.739,
                42.75,
                42.76,
                42.77,
                42.78,
                42.79,
                42.8,
                42.81,
                42.82,
                42.83,
                42.84,
                42.851,
                42.861,
                42.871,
                42.881,
                42.89,
                42.9,
                42.91,
                42.919,
                42.929,
                42.938,
                42.948,
                42.957,
                42.966,
                42.976,
                42.985,
                42.994,
                43.004,
                43.013,
                43.022,
                43.031,
                43.04,
                43.048,
                43.057,
                43.066,
                43.075,
                43.084,
                43.093,
                43.102,
                43.11,
                43.119,
                43.128,
                43.137,
                43.145,
                43.154,
                43.162,
                43.171,
                43.179,
                43.188,
                43.196,
                43.205,
                43.213,
                43.222,
                43.23,
                43.238,
                43.247,
                43.255,
                43.263,
                43.271,
                43.279,
                43.287,
                43.295,
                43.303,
                43.311,
                43.319,
                43.327,
                43.335,
                43.343,
                43.351,
                43.359,
                43.367,
                43.375,
                43.381,
                43.389,
                43.397,
                43.404,
                43.412,
                43.42,
                43.427,
                43.435,
                43.442,
                43.45,
                43.458,
                43.465,
                43.472,
                43.48,
                43.487,
                43.495,
                43.502,
                43.509,
                43.517,
                43.524,
                43.531,
                43.538,
                43.546,
                43.552,
                43.559,
                43.566,
                43.573,
                43.58,
                43.587,
                43.594,
                43.601,
                43.608,
                43.615,
                43.622,
                43.629,
                43.636,
                43.643,
                43.65,
                43.657,
                43.663,
                43.67,
                43.676,
                43.683,
                43.689,
                43.696,
                43.703,
                43.709,
                43.716,
                43.723,
                43.729,
                43.736,
                43.743,
                43.749,
                43.756,
                43.762,
                43.769,
                43.775,
                43.781,
                43.787,
                43.793,
                43.8,
                43.806,
                43.813,
                43.819,
                43.825,
                43.832,
                43.838,
                43.844,
                43.85,
                43.857,
                43.863,
                43.868,
                43.874,
                43.88,
                43.887,
                43.893,
                43.899,
                43.905,
                43.911,
                43.917,
                43.923,
                43.929,
                43.935,
                43.941,
                43.946,
                43.952,
                43.958,
                43.964,
                43.97,
                43.976,
                43.982,
                43.988,
                43.994,
                44,
                44.005,
                44.011,
                44.017,
                44.022,
                44.028,
                44.033,
                44.039,
                44.045,
                44.051,
                44.056,
                44.062,
                44.068,
                44.073,
                44.079,
                44.085,
                44.089,
                44.095,
                44.1,
                44.106,
                44.112,
                44.117,
                44.123,
                44.128,
                44.134,
                44.139,
                44.145,
                44.15,
                44.155,
                44.16,
                44.166,
                44.171,
                44.177,
                44.182,
                44.188,
                44.193,
                44.198,
                44.204,
                44.209,
                44.213,
                44.219,
                44.224,
                44.229,
                44.235,
                44.24,
                44.245,
                44.251,
                44.256,
                44.261,
                44.265,
                44.27,
                44.276,
                44.281,
                44.286,
                44.291,
                44.296,
                44.302,
                44.307,
                44.312,
                44.317,
                44.321,
                44.326,
                44.331,
                44.337,
                44.342,
                44.347,
                44.352,
                44.357,
                44.362,
                44.367,
                44.371,
                44.376,
                44.381,
                44.386,
                44.391,
                44.396,
                44.401,
                44.406,
                44.411,
                44.416,
                44.42,
                44.425,
                44.43,
                44.435,
                44.439,
                44.444,
                44.449,
                44.454,
                44.459,
                44.463,
                44.468,
                44.472,
                44.477,
                44.482,
                44.487,
                44.492,
                44.497,
                44.501,
                44.505,
                44.51,
                44.515,
                44.52,
                44.524,
                44.529,
                44.534,
                44.539,
                44.543,
                44.548,
                44.552,
                44.556,
                44.561,
                44.566,
                44.57,
                44.575,
                44.58,
                44.584,
                44.589,
                44.593,
                44.597,
                44.602,
                44.607,
                44.611,
                44.616,
                44.62,
                44.625,
                44.629,
                44.633,
                44.638,
                44.642,
                44.647,
                44.652,
                44.656,
                44.661,
                44.665,
                44.669,
                44.673,
                44.678,
                44.682,
                44.687,
                44.691,
                44.696,
                44.7,
                44.704,
                44.708,
                44.713,
                44.717,
                44.721,
                44.726,
                44.73,
                44.735,
                44.739,
                44.743,
                44.747,
                44.751,
                44.756,
                44.76,
                44.765,
                44.769,
                44.773,
                44.777,
                44.781,
                44.785,
                44.79,
                44.794,
                44.798,
                44.803,
                44.807,
                44.81,
                44.815,
                44.819,
                44.823,
                44.828,
                44.832,
                44.836,
                44.841,
                44.844,
                44.848,
                44.852,
                44.857,
                44.861,
                44.865,
                44.869,
                44.874,
                44.877,
                44.881,
                44.885,
                44.889,
                44.894,
                44.898,
                44.902,
                44.906,
                44.909,
                44.914,
                44.918,
                44.922,
                44.926,
                44.93,
                44.934,
                44.939,
                44.942,
                44.946,
                44.95,
                44.954,
                44.958,
                44.962,
                44.966,
                44.97,
                44.973,
                44.978,
                44.982,
                44.986,
                44.99,
                44.994,
                44.998,
                45.001,
                45.005,
                45.009,
                45.013,
                45.017,
                45.021,
                45.025,
                45.029,
                45.032,
                45.036,
                45.04,
                45.044,
                45.048,
                45.052,
                45.056,
                45.06,
                45.063,
                45.067,
                45.071,
                45.075,
                45.079,
                45.083,
                45.087,
                45.09,
                45.094,
                45.098,
                45.102,
                45.105,
                45.109,
                45.113,
                45.116,
                45.12,
                45.124,
                45.128,
                45.132,
                45.136,
                45.139,
                45.143,
                45.146,
                45.15,
                45.154,
                45.158,
                45.162,
                45.165,
                45.169,
                45.172,
                45.176,
                45.18,
                45.183,
                45.187,
                45.191,
                45.195,
                45.199,
                45.201,
                45.205,
                45.209,
                45.213,
                45.216,
                45.22,
                45.224,
                45.227,
                45.23,
                45.234,
                45.238,
                45.242,
                45.245,
                45.249,
                45.252,
                45.255,
                45.259,
                45.263,
                45.266,
                45.27,
                45.274,
                45.276,
                45.28,
                45.284,
                45.287,
                45.291,
                45.295,
                45.298,
                45.302,
                45.305,
                45.308,
                45.312,
                45.315,
                45.319,
                45.323,
                45.326,
                45.329,
                45.332,
                45.336,
                45.34,
                45.343,
                45.347,
                45.35,
                45.353,
                45.356,
                45.36,
                45.364,
                45.367,
                45.371,
                45.374,
                45.377,
                45.38,
                45.384,
                45.387,
                45.391,
                45.394,
                45.398,
                45.4,
                45.404,
                45.407,
                45.411,
                45.414,
                45.418,
                45.421,
                45.423,
                45.427,
                45.43,
                45.434,
                45.437,
                45.441,
                45.444,
                45.448,
                45.45,
                45.453,
                45.457,
                45.46,
                45.464,
                45.467,
                45.47,
                45.473,
                45.476,
                45.479,
                45.483,
                45.486,
                45.49,
                45.493,
                45.495,
                45.499,
                45.502,
                45.505,
                45.509,
                45.512,
                45.515,
                45.518,
                45.521,
                45.524,
                45.527,
                45.531,
                45.534,
                45.537,
                45.54,
                45.543,
                45.546,
                45.549,
                45.553,
                45.556,
                45.559,
                45.561,
                45.565,
                45.568,
                45.571,
                45.574,
                45.578,
                45.581,
                45.583,
                45.586,
                45.589,
                45.593,
                45.596,
                45.599,
                45.602,
                45.604,
                45.608,
                45.611,
                45.614,
                45.617,
                45.62,
                45.623,
                45.627,
                45.629,
                45.632,
                45.635,
                45.638,
                45.641,
                45.644,
                45.648,
                45.65,
                45.653,
                45.656,
                45.659,
                45.662,
                45.665,
                45.668,
                45.67,
                45.673,
                45.676,
                45.679,
                45.683,
                45.686,
                45.689,
                45.691,
                45.694,
                45.697,
                45.7,
                45.703,
                45.706,
                45.709,
                45.711,
                45.714,
                45.717,
                45.72,
                45.723,
                45.726,
                45.729,
                45.732,
                45.734,
                45.737,
                45.74,
                45.743,
                45.746,
                45.749,
                45.752,
                45.754,
                45.757,
                45.76,
                45.763,
                45.765,
                45.768,
                45.771,
                45.773,
                45.776,
                45.779,
                45.782,
                45.785,
                45.788,
                45.791,
                45.792,
                45.795,
                45.798,
                45.801,
                45.804,
                45.807,
                45.81,
                45.813,
                45.814,
                45.817,
                45.82,
                45.823,
                45.826,
                45.829,
                45.832,
                45.833,
                45.836,
                45.839,
                45.842,
                45.845,
                45.848,
                45.85,
                45.853,
                45.855,
                45.858,
                45.86,
                45.863,
                45.866,
                45.869,
                45.872,
                45.873,
                45.876,
                45.879,
                45.882,
                45.884,
                45.887,
                45.89,
                45.892,
                45.894,
                45.897,
                45.9,
                45.902,
                45.905,
                45.908,
                45.911,
                45.912,
                45.915,
                45.918,
                45.92,
                45.923,
                45.926,
                45.928,
                45.93,
                45.933,
                45.935,
                45.938,
                45.941,
                45.943,
                45.946,
                45.949,
                45.95,
                45.953,
                45.956,
                45.958,
                45.961,
                45.964,
                45.966,
                45.968,
                45.97,
                45.973,
                45.976,
                45.978,
                45.981,
                45.983,
                45.986,
                45.988,
                45.99,
                45.993,
                45.995,
                45.998,
                46.001,
                46.003,
                46.006,
                46.007,
                46.01,
                46.012,
                46.015,
                46.017,
                46.02,
                46.023,
                46.024,
                46.027,
                46.029,
                46.032,
                46.034,
                46.037,
                46.039,
                46.042,
                46.043,
                46.046,
                46.048,
                46.051,
                46.053,
                46.056,
                46.058,
                46.061,
                46.062,
                46.065,
                46.067,
                46.07,
                46.072,
                46.075,
                46.077,
                46.078,
                46.081,
                46.083,
                46.086,
                46.088,
                46.091,
                46.093,
                46.096,
                46.097,
                46.099,
                46.102,
                46.104,
                46.107,
                46.109,
                46.112,
                46.114,
                46.115,
                46.118,
                46.12,
                46.122,
                46.125,
                46.127,
                46.13,
                46.132,
                46.133,
                46.136,
                46.138,
                46.14,
                46.143,
                46.145,
                46.148,
                46.15,
                46.151,
                46.154,
                46.156,
                46.158,
                46.161,
                46.163,
                46.165,
                46.168,
                46.169,
                46.171,
                46.174,
                46.176,
                46.178,
                46.181,
                46.183,
                46.185,
                46.186,
                46.189,
                46.191,
                46.193,
                46.196,
                46.198,
                46.2,
                46.203,
                46.204,
                46.206,
                46.208,
                46.211,
                46.213,
                46.215,
                46.217,
                46.22,
                46.221,
                46.223,
                46.225,
                46.228,
                46.23,
                46.232,
                46.234,
                46.237,
                46.238,
                46.24,
                46.242,
                46.245,
                46.247,
                46.249,
                46.251,
                46.253,
                46.254,
                46.257,
                46.259,
                46.261,
                46.263,
                46.266,
                46.268,
                46.27,
                46.271,
                46.273,
                46.276,
                46.278,
                46.28,
                46.282,
                46.284,
                46.286,
                46.289,
                46.29,
                46.292,
                46.294,
                46.296,
                46.298,
                46.3,
                46.303,
                46.305,
                46.306,
                46.308,
                46.31,
                46.312,
                46.314,
                46.317,
                46.319,
                46.321,
                46.322,
                46.324,
                46.326,
                46.328,
                46.33,
                46.333,
                46.335,
                46.337,
                46.338,
                46.34,
                46.342,
                46.344,
                46.346,
                46.348,
                46.35,
                46.352,
                46.355,
                46.355,
                46.358,
                46.36,
                46.362,
                46.364,
                46.366,
                46.368,
                46.37,
                46.371,
                46.373,
                46.375,
                46.377,
                46.379,
                46.381,
                46.383,
                46.385,
                46.387,
                46.388,
                46.39,
                46.392,
                46.395,
                46.397,
                46.399,
                46.401,
                46.403,
                46.404,
                46.406,
                46.408,
                46.41,
                46.412,
                46.414,
                46.416,
                46.418,
                46.42,
                46.421,
                46.423,
                46.425,
                46.427,
                46.429,
                46.431,
                46.433,
                46.435,
                46.437,
                46.437,
                46.439,
                46.441,
                46.443,
                46.445,
                46.447,
                46.449,
                46.451,
                46.452,
                46.454,
                46.456,
                46.458,
                46.46,
                46.462,
                46.464,
                46.466,
                46.468,
                46.469,
                46.471,
                46.473,
                46.475,
                46.476,
                46.478,
                46.48,
                46.482,
                46.484,
                46.485,
                46.487,
                46.489,
                46.491,
                46.493,
                46.495,
                46.497,
                46.498,
                46.5,
                46.501,
                46.503,
                46.505,
                46.507,
                46.509,
                46.511,
                46.513,
                46.515,
                46.516,
                46.517,
                46.519,
                46.521,
                46.523,
                46.525,
                46.527,
                46.529,
                46.53,
                46.532,
                46.533,
                46.535,
                46.537,
                46.539,
                46.541,
                46.542,
                46.544,
                46.546,
                46.548,
                46.549,
                46.551,
                46.552,
                46.554,
                46.556,
                46.558,
                46.56,
                46.562,
                46.564,
                46.564,
                46.566,
                46.568,
                46.57,
                46.571,
                46.573,
                46.575,
                46.577,
                46.579,
                46.579,
                46.581,
                46.583,
                46.585,
                46.587,
                46.589,
                46.59,
                46.592,
                46.594,
                46.595,
                46.596,
                46.598,
                46.6,
                46.602,
                46.604,
                46.605,
                46.607,
                46.609,
                46.61,
                46.611,
                46.613,
                46.615,
                46.617,
                46.619,
                46.62,
                46.622,
                46.624,
                46.625,
                46.626,
                46.628,
                46.63,
                46.632,
                46.633,
                46.635,
                46.637,
                46.639,
                46.64,
                46.641,
                46.643,
                46.644,
                46.646,
                46.648,
                46.65,
                46.651,
                46.653,
                46.655,
                46.656,
                46.657,
                46.659,
                46.661,
                46.662,
                46.664,
                46.666,
                46.668,
                46.669,
                46.67,
                46.672,
                46.673,
                46.675,
                46.677,
                46.678,
                46.68,
                46.682,
                46.684,
                46.685,
                46.686,
                46.688,
                46.689,
                46.691,
                46.693,
                46.694,
                46.696,
                46.698,
                46.699,
                46.7,
                46.702,
                46.703,
                46.705,
                46.707,
                46.708,
                46.71,
                46.712,
                46.713,
                46.715,
                46.715,
                46.717,
                46.719,
                46.72,
                46.722,
                46.724,
                46.725,
                46.727,
                46.729,
                46.73,
                46.731,
                46.732,
                46.734,
                46.736,
                46.737,
                46.739,
                46.741,
                46.742,
                46.744,
                46.744,
                46.746,
                46.748,
                46.749,
                46.751,
                46.753,
                46.754,
                46.756,
                46.758,
                46.759,
                46.76,
                46.761,
                46.763,
                46.764,
                46.766,
                46.768,
                46.769,
                46.771,
                46.772,
                46.774,
                46.774,
                46.776,
                46.778,
                46.779,
                46.781,
                46.782,
                46.784,
                46.786,
                46.787,
                46.789,
                46.789,
                46.791,
                46.792,
                46.794,
                46.796,
                46.797,
                46.799,
                46.8,
                46.802,
                46.802,
                46.804,
                46.805,
                46.807,
                46.809,
                46.81,
                46.812,
                46.813,
                46.815,
                46.816,
                46.817,
                46.818,
                46.82,
                46.821,
                46.823,
                46.824,
                46.826,
                46.827,
                46.829,
                46.831,
                46.831,
                46.832,
                46.834,
                46.836,
                46.837,
                46.839,
                46.84,
                46.842,
                46.843,
                46.845,
                46.846,
                46.847,
                46.848,
                46.85,
                46.851,
                46.853,
                46.854,
                46.856,
                46.857,
                46.859,
                46.86,
                46.861,
                46.862,
                46.864,
                46.865,
                46.867,
                46.868,
                46.87,
                46.871,
                46.873,
                46.874,
                46.874,
                46.876,
                46.877,
                46.879,
                46.88,
                46.882,
                46.883,
                46.885,
                46.886,
                46.888,
                46.888,
                46.89,
                46.891,
                46.892,
                46.894,
                46.895,
                46.897,
                46.898,
                46.9,
                46.901,
                46.902,
                46.903,
                46.905,
                46.906,
                46.907,
                46.909,
                46.91,
                46.912,
                46.913,
                46.915,
                46.916,
                46.916,
                46.918,
                46.919,
                46.921,
                46.922,
                46.924,
                46.925,
                46.927,
                46.928,
                46.929,
                46.93,
                46.931,
                46.933,
                46.934,
                46.935,
                46.937,
                46.938,
                46.94,
                46.941,
                46.943,
                46.944,
                46.944,
                46.946,
                46.947,
                46.948,
                46.95,
                46.951,
                46.953,
                46.954,
                46.956,
                46.957,
                46.957,
                46.959,
                46.96,
                46.961,
                46.963,
                46.964,
                46.966,
                46.967,
                46.968,
                46.97,
                46.971,
                46.971,
                46.973,
                46.974,
                46.976,
                46.977,
                46.978,
                46.98,
                46.981,
                46.983,
                46.984,
                46.984,
                46.986,
                46.987,
                46.988,
                46.99,
                46.991,
                46.992,
                46.994,
                46.995,
                46.997,
                46.998,
                46.998,
                47,
                47.001,
                47.002,
                47.004,
                47.005,
                47.006,
                47.008,
                47.009,
                47.011,
                47.012,
                47.012,
                47.013,
                47.015,
                47.016,
                47.017,
                47.019,
                47.02,
                47.022,
                47.023,
                47.024,
                47.026,
                47.026,
                47.027,
                47.028,
                47.03,
                47.031,
                47.032,
                47.034,
                47.035,
                47.036,
                47.038,
                47.038,
                47.039,
                47.041,
                47.042,
                47.043,
                47.045,
                47.046,
                47.047,
                47.049,
                47.05,
                47.051,
                47.051,
                47.053,
                47.054,
                47.055,
                47.057,
                47.058,
                47.059,
                47.061,
                47.062,
                47.063,
                47.065,
                47.065,
                47.066,
                47.067,
                47.069,
                47.07,
                47.071,
                47.073,
                47.074,
                47.075,
                47.076,
                47.078,
                47.078,
                47.079,
                47.081,
                47.082,
                47.083,
                47.084,
                47.086,
                47.087,
                47.088,
                47.09,
                47.091,
                47.091,
                47.092,
                47.094,
                47.095,
                47.096,
                47.098,
                47.099,
                47.1,
                47.101,
                47.103,
                47.104,
                47.105,
                47.105,
                47.107,
                47.108,
                47.109,
                47.11,
                47.112,
                47.113,
                47.114,
                47.116,
                47.117,
                47.118,
                47.118,
                47.119,
                47.121,
                47.122,
                47.123,
                47.125,
                47.126,
                47.127,
                47.128,
                47.13,
                47.131,
                47.131,
                47.132,
                47.134,
                47.135,
                47.136,
                47.137,
                47.139,
                47.14,
                47.141,
                47.142,
                47.144,
                47.144,
                47.145,
                47.146,
                47.147,
                47.149,
                47.15,
                47.151,
                47.152,
                47.154,
                47.155,
                47.156,
                47.157,
                47.158,
                47.159,
                47.16,
                47.161,
                47.162,
                47.164,
                47.165,
                47.166,
                47.167,
                47.169,
                47.17,
                47.17,
                47.171,
                47.172,
                47.174,
                47.175,
                47.176,
                47.177,
                47.179,
                47.18,
                47.181,
                47.182,
                47.182,
                47.184,
                47.185,
                47.186,
                47.187,
                47.189,
                47.19,
                47.191,
                47.192,
                47.193,
                47.195,
                47.196,
                47.196,
                47.197,
                47.198,
                47.2,
                47.201,
                47.202,
                47.203,
                47.204,
                47.206,
                47.207,
                47.208,
                47.209,
                47.209,
                47.211,
                47.212,
                47.213,
                47.214,
                47.215,
                47.217,
                47.218,
                47.219,
                47.22,
                47.221,
                47.221,
                47.223,
                47.224,
                47.225,
                47.226,
                47.228,
                47.229,
                47.23,
                47.231,
                47.232,
                47.234,
                47.235,
                47.235,
                47.236,
                47.237,
                47.238,
                47.24,
                47.241,
                47.242,
                47.243,
                47.244,
                47.246,
                47.247,
                47.248,
                47.248,
                47.249,
                47.25,
                47.252,
                47.253,
                47.254,
                47.255,
                47.256,
                47.258,
                47.259,
                47.26,
                47.261,
                47.261,
                47.262,
                47.264,
                47.265,
                47.266,
                47.267,
                47.268,
                47.269,
                47.271,
                47.272,
                47.273,
                47.273,
                47.274,
                47.275,
                47.276,
                47.278,
                47.279,
                47.28,
                47.281,
                47.282,
                47.284,
                47.285,
                47.286,
                47.286,
                47.287,
                47.288,
                47.289,
                47.291,
                47.292,
                47.293
            ],
            P3: [
                32.073,
                32.185,
                32.299,
                32.412,
                32.526,
                32.64,
                32.753,
                32.867,
                32.981,
                33.095,
                33.21,
                33.324,
                33.438,
                33.553,
                33.667,
                33.768,
                33.866,
                33.962,
                34.056,
                34.148,
                34.238,
                34.326,
                34.412,
                34.496,
                34.579,
                34.661,
                34.741,
                34.819,
                34.896,
                34.973,
                35.048,
                35.121,
                35.194,
                35.264,
                35.335,
                35.404,
                35.472,
                35.539,
                35.606,
                35.671,
                35.736,
                35.8,
                35.862,
                35.924,
                35.986,
                36.046,
                36.106,
                36.165,
                36.224,
                36.281,
                36.339,
                36.395,
                36.451,
                36.506,
                36.561,
                36.615,
                36.669,
                36.722,
                36.774,
                36.827,
                36.878,
                36.929,
                36.98,
                37.03,
                37.08,
                37.129,
                37.178,
                37.227,
                37.275,
                37.322,
                37.37,
                37.417,
                37.463,
                37.509,
                37.554,
                37.6,
                37.644,
                37.689,
                37.733,
                37.777,
                37.82,
                37.864,
                37.907,
                37.949,
                37.991,
                38.033,
                38.075,
                38.116,
                38.156,
                38.198,
                38.238,
                38.278,
                38.318,
                38.357,
                38.397,
                38.436,
                38.474,
                38.512,
                38.55,
                38.589,
                38.626,
                38.664,
                38.701,
                38.738,
                38.775,
                38.81,
                38.847,
                38.883,
                38.918,
                38.954,
                38.989,
                39.024,
                39.059,
                39.093,
                39.127,
                39.162,
                39.195,
                39.229,
                39.263,
                39.296,
                39.328,
                39.362,
                39.394,
                39.426,
                39.459,
                39.491,
                39.522,
                39.554,
                39.585,
                39.617,
                39.647,
                39.678,
                39.709,
                39.739,
                39.769,
                39.799,
                39.829,
                39.858,
                39.888,
                39.917,
                39.946,
                39.975,
                40.004,
                40.033,
                40.061,
                40.089,
                40.117,
                40.145,
                40.173,
                40.201,
                40.227,
                40.255,
                40.282,
                40.309,
                40.336,
                40.362,
                40.388,
                40.415,
                40.441,
                40.466,
                40.492,
                40.518,
                40.543,
                40.568,
                40.594,
                40.619,
                40.643,
                40.668,
                40.693,
                40.717,
                40.741,
                40.765,
                40.789,
                40.813,
                40.836,
                40.86,
                40.884,
                40.907,
                40.93,
                40.953,
                40.976,
                40.998,
                41.021,
                41.043,
                41.066,
                41.088,
                41.11,
                41.132,
                41.154,
                41.175,
                41.197,
                41.218,
                41.24,
                41.261,
                41.282,
                41.303,
                41.324,
                41.344,
                41.365,
                41.385,
                41.405,
                41.426,
                41.446,
                41.466,
                41.486,
                41.505,
                41.525,
                41.545,
                41.564,
                41.583,
                41.603,
                41.622,
                41.64,
                41.66,
                41.678,
                41.697,
                41.716,
                41.734,
                41.752,
                41.771,
                41.789,
                41.807,
                41.825,
                41.843,
                41.86,
                41.878,
                41.896,
                41.913,
                41.93,
                41.947,
                41.965,
                41.982,
                41.999,
                42.016,
                42.033,
                42.05,
                42.066,
                42.082,
                42.099,
                42.116,
                42.132,
                42.148,
                42.164,
                42.18,
                42.196,
                42.212,
                42.228,
                42.243,
                42.259,
                42.275,
                42.29,
                42.305,
                42.32,
                42.335,
                42.35,
                42.366,
                42.381,
                42.396,
                42.41,
                42.425,
                42.44,
                42.454,
                42.469,
                42.484,
                42.498,
                42.512,
                42.526,
                42.54,
                42.554,
                42.568,
                42.583,
                42.597,
                42.61,
                42.624,
                42.638,
                42.651,
                42.665,
                42.678,
                42.691,
                42.705,
                42.719,
                42.732,
                42.745,
                42.758,
                42.771,
                42.784,
                42.796,
                42.809,
                42.822,
                42.835,
                42.847,
                42.86,
                42.873,
                42.885,
                42.898,
                42.91,
                42.922,
                42.934,
                42.947,
                42.959,
                42.971,
                42.983,
                42.994,
                43.006,
                43.018,
                43.03,
                43.041,
                43.054,
                43.066,
                43.077,
                43.089,
                43.1,
                43.111,
                43.123,
                43.134,
                43.145,
                43.156,
                43.167,
                43.178,
                43.189,
                43.2,
                43.211,
                43.222,
                43.233,
                43.244,
                43.254,
                43.265,
                43.276,
                43.286,
                43.297,
                43.307,
                43.318,
                43.328,
                43.338,
                43.349,
                43.359,
                43.369,
                43.379,
                43.389,
                43.4,
                43.41,
                43.42,
                43.43,
                43.44,
                43.45,
                43.46,
                43.47,
                43.48,
                43.489,
                43.499,
                43.509,
                43.518,
                43.528,
                43.537,
                43.547,
                43.556,
                43.565,
                43.575,
                43.584,
                43.593,
                43.603,
                43.612,
                43.62,
                43.629,
                43.638,
                43.647,
                43.656,
                43.665,
                43.674,
                43.683,
                43.692,
                43.701,
                43.71,
                43.718,
                43.727,
                43.736,
                43.745,
                43.753,
                43.762,
                43.77,
                43.779,
                43.787,
                43.796,
                43.804,
                43.813,
                43.821,
                43.83,
                43.838,
                43.846,
                43.854,
                43.863,
                43.871,
                43.879,
                43.887,
                43.895,
                43.903,
                43.911,
                43.919,
                43.927,
                43.935,
                43.943,
                43.951,
                43.958,
                43.966,
                43.974,
                43.981,
                43.989,
                43.997,
                44.005,
                44.012,
                44.02,
                44.028,
                44.035,
                44.043,
                44.05,
                44.058,
                44.065,
                44.073,
                44.08,
                44.088,
                44.095,
                44.102,
                44.11,
                44.117,
                44.124,
                44.131,
                44.138,
                44.145,
                44.153,
                44.16,
                44.167,
                44.174,
                44.181,
                44.188,
                44.195,
                44.202,
                44.209,
                44.216,
                44.223,
                44.23,
                44.237,
                44.244,
                44.251,
                44.257,
                44.264,
                44.271,
                44.277,
                44.284,
                44.291,
                44.298,
                44.305,
                44.311,
                44.318,
                44.325,
                44.331,
                44.338,
                44.344,
                44.351,
                44.358,
                44.363,
                44.37,
                44.376,
                44.383,
                44.389,
                44.396,
                44.402,
                44.409,
                44.415,
                44.421,
                44.428,
                44.434,
                44.44,
                44.447,
                44.452,
                44.458,
                44.465,
                44.471,
                44.477,
                44.483,
                44.49,
                44.496,
                44.502,
                44.508,
                44.514,
                44.52,
                44.526,
                44.532,
                44.538,
                44.544,
                44.55,
                44.556,
                44.562,
                44.568,
                44.574,
                44.58,
                44.586,
                44.592,
                44.597,
                44.603,
                44.608,
                44.614,
                44.62,
                44.626,
                44.632,
                44.638,
                44.643,
                44.649,
                44.655,
                44.661,
                44.666,
                44.672,
                44.677,
                44.683,
                44.688,
                44.694,
                44.7,
                44.705,
                44.711,
                44.717,
                44.722,
                44.728,
                44.733,
                44.739,
                44.744,
                44.749,
                44.755,
                44.76,
                44.766,
                44.771,
                44.777,
                44.782,
                44.788,
                44.793,
                44.799,
                44.803,
                44.809,
                44.814,
                44.819,
                44.825,
                44.83,
                44.835,
                44.841,
                44.846,
                44.851,
                44.856,
                44.861,
                44.866,
                44.872,
                44.877,
                44.882,
                44.888,
                44.893,
                44.898,
                44.903,
                44.909,
                44.913,
                44.918,
                44.923,
                44.928,
                44.934,
                44.939,
                44.944,
                44.949,
                44.954,
                44.959,
                44.964,
                44.969,
                44.974,
                44.979,
                44.984,
                44.989,
                44.994,
                44.999,
                45.004,
                45.009,
                45.013,
                45.018,
                45.023,
                45.028,
                45.033,
                45.038,
                45.043,
                45.048,
                45.053,
                45.057,
                45.062,
                45.067,
                45.072,
                45.077,
                45.082,
                45.086,
                45.091,
                45.096,
                45.1,
                45.105,
                45.11,
                45.115,
                45.12,
                45.124,
                45.129,
                45.134,
                45.139,
                45.144,
                45.148,
                45.152,
                45.157,
                45.162,
                45.167,
                45.171,
                45.176,
                45.181,
                45.186,
                45.189,
                45.194,
                45.199,
                45.204,
                45.208,
                45.213,
                45.218,
                45.222,
                45.226,
                45.231,
                45.235,
                45.24,
                45.245,
                45.249,
                45.254,
                45.259,
                45.263,
                45.267,
                45.271,
                45.276,
                45.281,
                45.285,
                45.29,
                45.294,
                45.299,
                45.302,
                45.307,
                45.312,
                45.316,
                45.321,
                45.325,
                45.33,
                45.334,
                45.339,
                45.342,
                45.347,
                45.351,
                45.356,
                45.36,
                45.365,
                45.369,
                45.373,
                45.377,
                45.381,
                45.386,
                45.39,
                45.395,
                45.399,
                45.404,
                45.408,
                45.411,
                45.416,
                45.42,
                45.424,
                45.429,
                45.433,
                45.438,
                45.442,
                45.445,
                45.45,
                45.454,
                45.458,
                45.463,
                45.467,
                45.471,
                45.476,
                45.479,
                45.483,
                45.488,
                45.492,
                45.496,
                45.5,
                45.505,
                45.509,
                45.512,
                45.517,
                45.521,
                45.525,
                45.529,
                45.534,
                45.538,
                45.542,
                45.545,
                45.549,
                45.554,
                45.558,
                45.562,
                45.566,
                45.57,
                45.574,
                45.578,
                45.582,
                45.586,
                45.59,
                45.594,
                45.598,
                45.603,
                45.606,
                45.61,
                45.614,
                45.618,
                45.622,
                45.626,
                45.631,
                45.635,
                45.638,
                45.642,
                45.646,
                45.65,
                45.654,
                45.658,
                45.662,
                45.666,
                45.669,
                45.673,
                45.677,
                45.681,
                45.685,
                45.689,
                45.693,
                45.697,
                45.701,
                45.705,
                45.708,
                45.712,
                45.716,
                45.72,
                45.723,
                45.727,
                45.731,
                45.735,
                45.739,
                45.743,
                45.747,
                45.751,
                45.754,
                45.758,
                45.762,
                45.766,
                45.77,
                45.774,
                45.778,
                45.78,
                45.784,
                45.788,
                45.792,
                45.796,
                45.8,
                45.804,
                45.808,
                45.811,
                45.814,
                45.818,
                45.822,
                45.826,
                45.83,
                45.833,
                45.836,
                45.84,
                45.844,
                45.848,
                45.852,
                45.855,
                45.859,
                45.862,
                45.866,
                45.869,
                45.873,
                45.877,
                45.881,
                45.884,
                45.887,
                45.891,
                45.895,
                45.899,
                45.902,
                45.906,
                45.91,
                45.913,
                45.916,
                45.92,
                45.923,
                45.927,
                45.931,
                45.935,
                45.938,
                45.941,
                45.945,
                45.948,
                45.952,
                45.955,
                45.959,
                45.963,
                45.965,
                45.969,
                45.973,
                45.976,
                45.98,
                45.984,
                45.987,
                45.99,
                45.993,
                45.997,
                46.001,
                46.004,
                46.008,
                46.011,
                46.014,
                46.017,
                46.021,
                46.024,
                46.028,
                46.032,
                46.035,
                46.038,
                46.041,
                46.045,
                46.048,
                46.052,
                46.055,
                46.059,
                46.062,
                46.065,
                46.068,
                46.072,
                46.075,
                46.079,
                46.082,
                46.086,
                46.088,
                46.092,
                46.095,
                46.098,
                46.102,
                46.105,
                46.109,
                46.111,
                46.115,
                46.118,
                46.121,
                46.125,
                46.128,
                46.131,
                46.134,
                46.137,
                46.141,
                46.144,
                46.147,
                46.151,
                46.154,
                46.157,
                46.16,
                46.163,
                46.167,
                46.17,
                46.173,
                46.177,
                46.179,
                46.182,
                46.186,
                46.189,
                46.192,
                46.195,
                46.199,
                46.201,
                46.204,
                46.208,
                46.211,
                46.214,
                46.217,
                46.221,
                46.223,
                46.226,
                46.229,
                46.233,
                46.236,
                46.239,
                46.242,
                46.246,
                46.248,
                46.251,
                46.254,
                46.257,
                46.261,
                46.264,
                46.267,
                46.269,
                46.272,
                46.276,
                46.279,
                46.282,
                46.285,
                46.288,
                46.29,
                46.293,
                46.297,
                46.3,
                46.303,
                46.306,
                46.309,
                46.311,
                46.314,
                46.318,
                46.321,
                46.324,
                46.327,
                46.33,
                46.332,
                46.335,
                46.338,
                46.341,
                46.344,
                46.347,
                46.35,
                46.353,
                46.356,
                46.359,
                46.362,
                46.365,
                46.368,
                46.371,
                46.374,
                46.376,
                46.379,
                46.382,
                46.385,
                46.388,
                46.391,
                46.394,
                46.396,
                46.399,
                46.402,
                46.405,
                46.408,
                46.411,
                46.414,
                46.416,
                46.419,
                46.422,
                46.425,
                46.427,
                46.43,
                46.433,
                46.436,
                46.438,
                46.441,
                46.444,
                46.447,
                46.45,
                46.453,
                46.456,
                46.458,
                46.46,
                46.463,
                46.466,
                46.469,
                46.472,
                46.475,
                46.478,
                46.48,
                46.482,
                46.485,
                46.488,
                46.491,
                46.494,
                46.497,
                46.499,
                46.501,
                46.504,
                46.507,
                46.51,
                46.513,
                46.515,
                46.517,
                46.52,
                46.523,
                46.526,
                46.528,
                46.531,
                46.534,
                46.537,
                46.539,
                46.541,
                46.544,
                46.547,
                46.549,
                46.552,
                46.555,
                46.557,
                46.56,
                46.562,
                46.565,
                46.568,
                46.57,
                46.573,
                46.576,
                46.578,
                46.58,
                46.583,
                46.586,
                46.588,
                46.591,
                46.594,
                46.596,
                46.598,
                46.601,
                46.603,
                46.606,
                46.609,
                46.611,
                46.614,
                46.616,
                46.618,
                46.621,
                46.624,
                46.626,
                46.629,
                46.632,
                46.634,
                46.636,
                46.639,
                46.641,
                46.644,
                46.646,
                46.649,
                46.652,
                46.653,
                46.656,
                46.658,
                46.661,
                46.664,
                46.666,
                46.669,
                46.671,
                46.673,
                46.675,
                46.678,
                46.681,
                46.683,
                46.686,
                46.688,
                46.691,
                46.692,
                46.695,
                46.697,
                46.7,
                46.702,
                46.705,
                46.707,
                46.709,
                46.712,
                46.714,
                46.717,
                46.719,
                46.721,
                46.724,
                46.726,
                46.728,
                46.731,
                46.733,
                46.735,
                46.738,
                46.74,
                46.743,
                46.745,
                46.747,
                46.749,
                46.752,
                46.754,
                46.757,
                46.759,
                46.762,
                46.764,
                46.765,
                46.768,
                46.77,
                46.773,
                46.775,
                46.778,
                46.78,
                46.782,
                46.784,
                46.786,
                46.789,
                46.791,
                46.793,
                46.796,
                46.798,
                46.801,
                46.802,
                46.804,
                46.807,
                46.809,
                46.812,
                46.814,
                46.816,
                46.819,
                46.82,
                46.822,
                46.825,
                46.827,
                46.829,
                46.832,
                46.834,
                46.836,
                46.838,
                46.84,
                46.842,
                46.845,
                46.847,
                46.849,
                46.852,
                46.854,
                46.855,
                46.858,
                46.86,
                46.862,
                46.865,
                46.867,
                46.869,
                46.871,
                46.873,
                46.875,
                46.877,
                46.88,
                46.882,
                46.884,
                46.886,
                46.889,
                46.89,
                46.892,
                46.894,
                46.897,
                46.899,
                46.901,
                46.903,
                46.906,
                46.907,
                46.909,
                46.912,
                46.914,
                46.916,
                46.918,
                46.92,
                46.923,
                46.925,
                46.926,
                46.928,
                46.93,
                46.933,
                46.935,
                46.937,
                46.939,
                46.941,
                46.943,
                46.945,
                46.947,
                46.949,
                46.951,
                46.954,
                46.956,
                46.958,
                46.959,
                46.961,
                46.963,
                46.966,
                46.968,
                46.97,
                46.972,
                46.974,
                46.975,
                46.978,
                46.98,
                46.982,
                46.984,
                46.986,
                46.988,
                46.99,
                46.993,
                46.994,
                46.996,
                46.998,
                47,
                47.002,
                47.004,
                47.006,
                47.009,
                47.01,
                47.012,
                47.014,
                47.016,
                47.018,
                47.02,
                47.022,
                47.024,
                47.026,
                47.028,
                47.03,
                47.032,
                47.034,
                47.036,
                47.038,
                47.04,
                47.042,
                47.043,
                47.045,
                47.047,
                47.049,
                47.051,
                47.053,
                47.056,
                47.058,
                47.06,
                47.061,
                47.063,
                47.065,
                47.067,
                47.069,
                47.071,
                47.073,
                47.075,
                47.077,
                47.078,
                47.08,
                47.082,
                47.084,
                47.086,
                47.088,
                47.09,
                47.092,
                47.093,
                47.095,
                47.097,
                47.099,
                47.101,
                47.103,
                47.105,
                47.107,
                47.109,
                47.11,
                47.112,
                47.114,
                47.116,
                47.118,
                47.12,
                47.122,
                47.124,
                47.126,
                47.127,
                47.129,
                47.131,
                47.133,
                47.135,
                47.137,
                47.139,
                47.141,
                47.143,
                47.144,
                47.146,
                47.148,
                47.149,
                47.151,
                47.153,
                47.155,
                47.157,
                47.159,
                47.16,
                47.162,
                47.164,
                47.166,
                47.168,
                47.17,
                47.172,
                47.173,
                47.175,
                47.176,
                47.178,
                47.18,
                47.182,
                47.184,
                47.186,
                47.188,
                47.19,
                47.191,
                47.192,
                47.194,
                47.196,
                47.198,
                47.2,
                47.202,
                47.204,
                47.206,
                47.207,
                47.208,
                47.21,
                47.212,
                47.214,
                47.216,
                47.218,
                47.22,
                47.221,
                47.223,
                47.224,
                47.226,
                47.228,
                47.23,
                47.232,
                47.233,
                47.235,
                47.237,
                47.239,
                47.24,
                47.242,
                47.243,
                47.245,
                47.247,
                47.249,
                47.251,
                47.253,
                47.254,
                47.255,
                47.257,
                47.259,
                47.261,
                47.262,
                47.264,
                47.266,
                47.268,
                47.27,
                47.271,
                47.272,
                47.274,
                47.276,
                47.278,
                47.279,
                47.281,
                47.283,
                47.285,
                47.287,
                47.288,
                47.289,
                47.291,
                47.293,
                47.295,
                47.296,
                47.298,
                47.3,
                47.302,
                47.302,
                47.304,
                47.306,
                47.308,
                47.309,
                47.311,
                47.313,
                47.315,
                47.316,
                47.317,
                47.319,
                47.321,
                47.322,
                47.324,
                47.326,
                47.328,
                47.329,
                47.331,
                47.333,
                47.334,
                47.335,
                47.337,
                47.339,
                47.341,
                47.342,
                47.344,
                47.346,
                47.347,
                47.348,
                47.35,
                47.352,
                47.353,
                47.355,
                47.357,
                47.358,
                47.36,
                47.362,
                47.363,
                47.364,
                47.366,
                47.368,
                47.369,
                47.371,
                47.373,
                47.374,
                47.376,
                47.378,
                47.379,
                47.38,
                47.382,
                47.383,
                47.385,
                47.387,
                47.388,
                47.39,
                47.392,
                47.393,
                47.394,
                47.396,
                47.397,
                47.399,
                47.401,
                47.402,
                47.404,
                47.406,
                47.407,
                47.409,
                47.41,
                47.411,
                47.413,
                47.414,
                47.416,
                47.418,
                47.419,
                47.421,
                47.423,
                47.424,
                47.425,
                47.427,
                47.428,
                47.43,
                47.431,
                47.433,
                47.435,
                47.436,
                47.438,
                47.439,
                47.44,
                47.442,
                47.443,
                47.445,
                47.447,
                47.448,
                47.45,
                47.451,
                47.453,
                47.454,
                47.455,
                47.457,
                47.458,
                47.46,
                47.461,
                47.463,
                47.465,
                47.466,
                47.468,
                47.468,
                47.47,
                47.472,
                47.473,
                47.475,
                47.476,
                47.478,
                47.479,
                47.481,
                47.483,
                47.483,
                47.485,
                47.486,
                47.488,
                47.489,
                47.491,
                47.492,
                47.494,
                47.496,
                47.497,
                47.499,
                47.499,
                47.501,
                47.502,
                47.504,
                47.505,
                47.507,
                47.509,
                47.51,
                47.512,
                47.513,
                47.514,
                47.515,
                47.517,
                47.518,
                47.52,
                47.521,
                47.523,
                47.524,
                47.526,
                47.527,
                47.528,
                47.529,
                47.531,
                47.532,
                47.534,
                47.535,
                47.537,
                47.539,
                47.54,
                47.542,
                47.542,
                47.544,
                47.545,
                47.547,
                47.548,
                47.55,
                47.551,
                47.553,
                47.554,
                47.556,
                47.556,
                47.558,
                47.559,
                47.56,
                47.562,
                47.563,
                47.565,
                47.566,
                47.568,
                47.569,
                47.571,
                47.571,
                47.573,
                47.574,
                47.576,
                47.577,
                47.579,
                47.58,
                47.582,
                47.583,
                47.585,
                47.585,
                47.586,
                47.588,
                47.589,
                47.591,
                47.592,
                47.594,
                47.595,
                47.597,
                47.598,
                47.599,
                47.6,
                47.601,
                47.603,
                47.604,
                47.606,
                47.607,
                47.609,
                47.61,
                47.611,
                47.613,
                47.613,
                47.615,
                47.616,
                47.618,
                47.619,
                47.621,
                47.622,
                47.623,
                47.625,
                47.626,
                47.628,
                47.628,
                47.63,
                47.631,
                47.632,
                47.634,
                47.635,
                47.637,
                47.638,
                47.639,
                47.641,
                47.641,
                47.643,
                47.644,
                47.645,
                47.647,
                47.648,
                47.65,
                47.651,
                47.652,
                47.654,
                47.655,
                47.656,
                47.657,
                47.658,
                47.66,
                47.661,
                47.663,
                47.664,
                47.665,
                47.667,
                47.668,
                47.669,
                47.67,
                47.671,
                47.673,
                47.674,
                47.675,
                47.677,
                47.678,
                47.68,
                47.681,
                47.682,
                47.684,
                47.684,
                47.685,
                47.687,
                47.688,
                47.69,
                47.691,
                47.692,
                47.694,
                47.695,
                47.696,
                47.697,
                47.698,
                47.699,
                47.701,
                47.702,
                47.703,
                47.705,
                47.706,
                47.708,
                47.709,
                47.71,
                47.711,
                47.712,
                47.713,
                47.715,
                47.716,
                47.717,
                47.719,
                47.72,
                47.721,
                47.723,
                47.724,
                47.724,
                47.726,
                47.727,
                47.728,
                47.73,
                47.731,
                47.732,
                47.734,
                47.735,
                47.736,
                47.738,
                47.738,
                47.739,
                47.741,
                47.742,
                47.743,
                47.745,
                47.746,
                47.747,
                47.749,
                47.75,
                47.751,
                47.752,
                47.753,
                47.754,
                47.755,
                47.757,
                47.758,
                47.759,
                47.761,
                47.762,
                47.763,
                47.765,
                47.766,
                47.766,
                47.768,
                47.769,
                47.77,
                47.771,
                47.773,
                47.774,
                47.775,
                47.777,
                47.778,
                47.779,
                47.78,
                47.781,
                47.782,
                47.783,
                47.785,
                47.786,
                47.787,
                47.789,
                47.79,
                47.791,
                47.792,
                47.793,
                47.794,
                47.795,
                47.797,
                47.798,
                47.799,
                47.8,
                47.802,
                47.803,
                47.804,
                47.805,
                47.806,
                47.807,
                47.808,
                47.81,
                47.811,
                47.812,
                47.813,
                47.815,
                47.816,
                47.817,
                47.819,
                47.82,
                47.82,
                47.821,
                47.823,
                47.824,
                47.825,
                47.826,
                47.828,
                47.829,
                47.83,
                47.831,
                47.833,
                47.833,
                47.834,
                47.836,
                47.837,
                47.838,
                47.839,
                47.841,
                47.842,
                47.843,
                47.844,
                47.846,
                47.846,
                47.847,
                47.848,
                47.85,
                47.851,
                47.852,
                47.853,
                47.854,
                47.856,
                47.857,
                47.858,
                47.859,
                47.86,
                47.861,
                47.862,
                47.864,
                47.865,
                47.866,
                47.867,
                47.868,
                47.87,
                47.871,
                47.872,
                47.873,
                47.874,
                47.875,
                47.876,
                47.877,
                47.879,
                47.88,
                47.881,
                47.882,
                47.883,
                47.885,
                47.886,
                47.886,
                47.887,
                47.889,
                47.89,
                47.891,
                47.892,
                47.894,
                47.895,
                47.896,
                47.897,
                47.898,
                47.9,
                47.9,
                47.901,
                47.902,
                47.904,
                47.905,
                47.906,
                47.907,
                47.908,
                47.91,
                47.911,
                47.912,
                47.913,
                47.914,
                47.915,
                47.916,
                47.917,
                47.918,
                47.92,
                47.921,
                47.922,
                47.923,
                47.924,
                47.926,
                47.927,
                47.927,
                47.928,
                47.93,
                47.931,
                47.932,
                47.933,
                47.934,
                47.936,
                47.937,
                47.938,
                47.939,
                47.939,
                47.941,
                47.942,
                47.943,
                47.944,
                47.945,
                47.947,
                47.948,
                47.949,
                47.95,
                47.951,
                47.953,
                47.953,
                47.954,
                47.955,
                47.956,
                47.958,
                47.959,
                47.96
            ],
            P5: [
                32.372,
                32.484,
                32.595,
                32.707,
                32.819,
                32.931,
                33.043,
                33.155,
                33.268,
                33.38,
                33.492,
                33.605,
                33.717,
                33.83,
                33.943,
                34.044,
                34.142,
                34.238,
                34.332,
                34.423,
                34.513,
                34.601,
                34.688,
                34.772,
                34.855,
                34.936,
                35.016,
                35.095,
                35.172,
                35.248,
                35.323,
                35.397,
                35.469,
                35.54,
                35.61,
                35.679,
                35.748,
                35.815,
                35.881,
                35.947,
                36.011,
                36.075,
                36.138,
                36.2,
                36.262,
                36.322,
                36.382,
                36.441,
                36.5,
                36.558,
                36.615,
                36.671,
                36.727,
                36.783,
                36.838,
                36.891,
                36.945,
                36.999,
                37.051,
                37.103,
                37.155,
                37.206,
                37.257,
                37.307,
                37.357,
                37.406,
                37.455,
                37.504,
                37.552,
                37.599,
                37.647,
                37.694,
                37.74,
                37.786,
                37.832,
                37.878,
                37.922,
                37.967,
                38.011,
                38.055,
                38.098,
                38.142,
                38.185,
                38.227,
                38.27,
                38.312,
                38.353,
                38.394,
                38.435,
                38.476,
                38.517,
                38.557,
                38.597,
                38.636,
                38.676,
                38.715,
                38.754,
                38.792,
                38.83,
                38.868,
                38.906,
                38.944,
                38.98,
                39.018,
                39.055,
                39.091,
                39.127,
                39.163,
                39.199,
                39.235,
                39.269,
                39.305,
                39.339,
                39.374,
                39.408,
                39.443,
                39.477,
                39.51,
                39.544,
                39.577,
                39.61,
                39.643,
                39.676,
                39.708,
                39.741,
                39.773,
                39.804,
                39.836,
                39.867,
                39.899,
                39.93,
                39.961,
                39.991,
                40.022,
                40.052,
                40.082,
                40.112,
                40.142,
                40.171,
                40.201,
                40.23,
                40.259,
                40.288,
                40.316,
                40.345,
                40.373,
                40.402,
                40.43,
                40.457,
                40.485,
                40.512,
                40.54,
                40.567,
                40.594,
                40.621,
                40.647,
                40.673,
                40.7,
                40.726,
                40.752,
                40.778,
                40.804,
                40.829,
                40.854,
                40.88,
                40.905,
                40.929,
                40.954,
                40.979,
                41.003,
                41.028,
                41.052,
                41.076,
                41.1,
                41.123,
                41.147,
                41.171,
                41.194,
                41.218,
                41.24,
                41.263,
                41.286,
                41.309,
                41.331,
                41.354,
                41.376,
                41.398,
                41.42,
                41.442,
                41.464,
                41.486,
                41.507,
                41.529,
                41.55,
                41.571,
                41.592,
                41.613,
                41.634,
                41.654,
                41.675,
                41.695,
                41.716,
                41.736,
                41.756,
                41.776,
                41.796,
                41.815,
                41.835,
                41.855,
                41.874,
                41.893,
                41.912,
                41.931,
                41.951,
                41.969,
                41.988,
                42.007,
                42.025,
                42.044,
                42.062,
                42.08,
                42.098,
                42.117,
                42.135,
                42.152,
                42.17,
                42.188,
                42.206,
                42.223,
                42.24,
                42.258,
                42.275,
                42.292,
                42.309,
                42.326,
                42.343,
                42.359,
                42.376,
                42.392,
                42.409,
                42.425,
                42.441,
                42.457,
                42.473,
                42.49,
                42.506,
                42.522,
                42.537,
                42.553,
                42.569,
                42.584,
                42.6,
                42.615,
                42.63,
                42.645,
                42.661,
                42.676,
                42.691,
                42.706,
                42.72,
                42.735,
                42.75,
                42.765,
                42.779,
                42.794,
                42.808,
                42.822,
                42.836,
                42.85,
                42.864,
                42.879,
                42.893,
                42.907,
                42.92,
                42.934,
                42.948,
                42.961,
                42.975,
                42.988,
                43.002,
                43.016,
                43.029,
                43.042,
                43.055,
                43.068,
                43.081,
                43.094,
                43.107,
                43.12,
                43.132,
                43.145,
                43.158,
                43.171,
                43.183,
                43.196,
                43.208,
                43.22,
                43.233,
                43.245,
                43.257,
                43.269,
                43.281,
                43.293,
                43.305,
                43.317,
                43.329,
                43.34,
                43.353,
                43.365,
                43.376,
                43.388,
                43.399,
                43.411,
                43.422,
                43.433,
                43.445,
                43.456,
                43.467,
                43.478,
                43.489,
                43.5,
                43.511,
                43.522,
                43.533,
                43.544,
                43.555,
                43.566,
                43.576,
                43.587,
                43.598,
                43.608,
                43.619,
                43.629,
                43.639,
                43.65,
                43.66,
                43.67,
                43.681,
                43.691,
                43.701,
                43.711,
                43.722,
                43.732,
                43.742,
                43.752,
                43.762,
                43.772,
                43.781,
                43.791,
                43.801,
                43.811,
                43.82,
                43.83,
                43.839,
                43.849,
                43.858,
                43.868,
                43.877,
                43.887,
                43.896,
                43.905,
                43.915,
                43.923,
                43.932,
                43.941,
                43.951,
                43.96,
                43.969,
                43.978,
                43.987,
                43.996,
                44.004,
                44.013,
                44.022,
                44.031,
                44.04,
                44.048,
                44.057,
                44.066,
                44.074,
                44.083,
                44.092,
                44.1,
                44.109,
                44.117,
                44.126,
                44.134,
                44.142,
                44.151,
                44.159,
                44.167,
                44.175,
                44.184,
                44.192,
                44.2,
                44.208,
                44.216,
                44.224,
                44.232,
                44.24,
                44.248,
                44.256,
                44.263,
                44.271,
                44.279,
                44.287,
                44.295,
                44.303,
                44.31,
                44.318,
                44.326,
                44.334,
                44.341,
                44.349,
                44.356,
                44.364,
                44.371,
                44.379,
                44.387,
                44.394,
                44.401,
                44.409,
                44.416,
                44.424,
                44.431,
                44.438,
                44.445,
                44.452,
                44.459,
                44.467,
                44.474,
                44.481,
                44.488,
                44.495,
                44.502,
                44.51,
                44.517,
                44.524,
                44.531,
                44.538,
                44.545,
                44.552,
                44.559,
                44.565,
                44.572,
                44.579,
                44.585,
                44.592,
                44.599,
                44.606,
                44.613,
                44.619,
                44.626,
                44.633,
                44.639,
                44.646,
                44.653,
                44.659,
                44.666,
                44.672,
                44.678,
                44.685,
                44.691,
                44.698,
                44.704,
                44.711,
                44.717,
                44.724,
                44.73,
                44.737,
                44.743,
                44.749,
                44.756,
                44.761,
                44.768,
                44.774,
                44.78,
                44.787,
                44.793,
                44.799,
                44.805,
                44.811,
                44.818,
                44.824,
                44.83,
                44.836,
                44.842,
                44.848,
                44.854,
                44.86,
                44.866,
                44.872,
                44.878,
                44.884,
                44.89,
                44.896,
                44.902,
                44.908,
                44.914,
                44.919,
                44.925,
                44.931,
                44.937,
                44.942,
                44.948,
                44.954,
                44.96,
                44.966,
                44.972,
                44.977,
                44.983,
                44.988,
                44.994,
                45,
                45.005,
                45.011,
                45.017,
                45.022,
                45.028,
                45.034,
                45.039,
                45.045,
                45.051,
                45.055,
                45.061,
                45.067,
                45.072,
                45.078,
                45.083,
                45.089,
                45.094,
                45.1,
                45.105,
                45.111,
                45.115,
                45.121,
                45.126,
                45.132,
                45.137,
                45.143,
                45.148,
                45.153,
                45.159,
                45.164,
                45.169,
                45.174,
                45.179,
                45.185,
                45.19,
                45.195,
                45.201,
                45.206,
                45.211,
                45.216,
                45.222,
                45.226,
                45.231,
                45.237,
                45.242,
                45.247,
                45.252,
                45.257,
                45.263,
                45.268,
                45.273,
                45.277,
                45.282,
                45.288,
                45.293,
                45.298,
                45.303,
                45.308,
                45.313,
                45.318,
                45.323,
                45.327,
                45.333,
                45.337,
                45.343,
                45.348,
                45.353,
                45.358,
                45.363,
                45.368,
                45.372,
                45.377,
                45.382,
                45.387,
                45.392,
                45.396,
                45.401,
                45.406,
                45.411,
                45.415,
                45.42,
                45.425,
                45.43,
                45.435,
                45.44,
                45.445,
                45.449,
                45.454,
                45.459,
                45.463,
                45.468,
                45.473,
                45.478,
                45.482,
                45.487,
                45.492,
                45.497,
                45.501,
                45.505,
                45.51,
                45.515,
                45.52,
                45.524,
                45.529,
                45.534,
                45.538,
                45.542,
                45.547,
                45.552,
                45.556,
                45.561,
                45.566,
                45.57,
                45.575,
                45.58,
                45.584,
                45.588,
                45.593,
                45.597,
                45.602,
                45.607,
                45.611,
                45.616,
                45.62,
                45.624,
                45.629,
                45.633,
                45.638,
                45.642,
                45.647,
                45.651,
                45.656,
                45.66,
                45.664,
                45.669,
                45.673,
                45.678,
                45.682,
                45.687,
                45.691,
                45.695,
                45.699,
                45.704,
                45.708,
                45.713,
                45.717,
                45.722,
                45.726,
                45.73,
                45.734,
                45.738,
                45.743,
                45.747,
                45.752,
                45.756,
                45.76,
                45.764,
                45.768,
                45.773,
                45.777,
                45.781,
                45.786,
                45.79,
                45.794,
                45.798,
                45.802,
                45.807,
                45.811,
                45.815,
                45.82,
                45.824,
                45.828,
                45.832,
                45.836,
                45.84,
                45.844,
                45.849,
                45.853,
                45.857,
                45.861,
                45.865,
                45.869,
                45.873,
                45.878,
                45.882,
                45.886,
                45.89,
                45.894,
                45.898,
                45.902,
                45.906,
                45.91,
                45.915,
                45.919,
                45.923,
                45.926,
                45.93,
                45.934,
                45.939,
                45.943,
                45.947,
                45.951,
                45.955,
                45.958,
                45.963,
                45.967,
                45.971,
                45.975,
                45.979,
                45.983,
                45.987,
                45.99,
                45.994,
                45.998,
                46.002,
                46.007,
                46.011,
                46.015,
                46.018,
                46.022,
                46.026,
                46.03,
                46.034,
                46.038,
                46.042,
                46.045,
                46.049,
                46.053,
                46.057,
                46.061,
                46.065,
                46.069,
                46.073,
                46.076,
                46.08,
                46.084,
                46.088,
                46.092,
                46.096,
                46.1,
                46.103,
                46.107,
                46.111,
                46.114,
                46.118,
                46.122,
                46.126,
                46.13,
                46.133,
                46.137,
                46.141,
                46.145,
                46.148,
                46.152,
                46.156,
                46.159,
                46.163,
                46.167,
                46.171,
                46.175,
                46.178,
                46.182,
                46.185,
                46.189,
                46.193,
                46.196,
                46.2,
                46.204,
                46.208,
                46.211,
                46.215,
                46.218,
                46.222,
                46.226,
                46.23,
                46.233,
                46.237,
                46.24,
                46.244,
                46.247,
                46.251,
                46.255,
                46.258,
                46.262,
                46.265,
                46.269,
                46.272,
                46.276,
                46.28,
                46.283,
                46.287,
                46.29,
                46.293,
                46.297,
                46.301,
                46.304,
                46.308,
                46.312,
                46.315,
                46.318,
                46.322,
                46.325,
                46.329,
                46.332,
                46.336,
                46.339,
                46.342,
                46.346,
                46.35,
                46.353,
                46.357,
                46.36,
                46.363,
                46.367,
                46.37,
                46.374,
                46.377,
                46.381,
                46.384,
                46.388,
                46.39,
                46.394,
                46.397,
                46.401,
                46.404,
                46.408,
                46.411,
                46.414,
                46.417,
                46.421,
                46.424,
                46.428,
                46.431,
                46.435,
                46.437,
                46.441,
                46.444,
                46.448,
                46.451,
                46.454,
                46.458,
                46.46,
                46.464,
                46.467,
                46.471,
                46.474,
                46.477,
                46.481,
                46.483,
                46.487,
                46.49,
                46.493,
                46.497,
                46.5,
                46.503,
                46.506,
                46.509,
                46.513,
                46.516,
                46.519,
                46.523,
                46.526,
                46.528,
                46.532,
                46.535,
                46.538,
                46.541,
                46.545,
                46.548,
                46.55,
                46.554,
                46.557,
                46.56,
                46.564,
                46.567,
                46.57,
                46.573,
                46.576,
                46.579,
                46.582,
                46.585,
                46.589,
                46.592,
                46.595,
                46.597,
                46.601,
                46.604,
                46.607,
                46.61,
                46.613,
                46.616,
                46.619,
                46.622,
                46.625,
                46.628,
                46.631,
                46.635,
                46.638,
                46.64,
                46.643,
                46.646,
                46.649,
                46.652,
                46.656,
                46.659,
                46.661,
                46.664,
                46.667,
                46.67,
                46.673,
                46.676,
                46.68,
                46.683,
                46.685,
                46.688,
                46.691,
                46.694,
                46.697,
                46.7,
                46.703,
                46.705,
                46.708,
                46.711,
                46.714,
                46.717,
                46.72,
                46.723,
                46.726,
                46.729,
                46.732,
                46.735,
                46.738,
                46.741,
                46.744,
                46.746,
                46.749,
                46.752,
                46.755,
                46.758,
                46.761,
                46.763,
                46.766,
                46.769,
                46.771,
                46.774,
                46.777,
                46.78,
                46.783,
                46.786,
                46.788,
                46.791,
                46.794,
                46.797,
                46.8,
                46.803,
                46.806,
                46.808,
                46.81,
                46.813,
                46.816,
                46.819,
                46.822,
                46.825,
                46.828,
                46.83,
                46.833,
                46.835,
                46.838,
                46.841,
                46.844,
                46.847,
                46.849,
                46.851,
                46.854,
                46.857,
                46.86,
                46.863,
                46.865,
                46.868,
                46.87,
                46.873,
                46.876,
                46.878,
                46.881,
                46.884,
                46.887,
                46.889,
                46.891,
                46.894,
                46.897,
                46.9,
                46.902,
                46.905,
                46.908,
                46.91,
                46.912,
                46.915,
                46.918,
                46.921,
                46.923,
                46.926,
                46.928,
                46.931,
                46.933,
                46.936,
                46.939,
                46.941,
                46.944,
                46.947,
                46.948,
                46.951,
                46.954,
                46.956,
                46.959,
                46.962,
                46.964,
                46.967,
                46.969,
                46.971,
                46.974,
                46.977,
                46.979,
                46.982,
                46.985,
                46.986,
                46.989,
                46.992,
                46.994,
                46.997,
                46.999,
                47.002,
                47.005,
                47.006,
                47.009,
                47.012,
                47.014,
                47.017,
                47.019,
                47.022,
                47.024,
                47.026,
                47.029,
                47.031,
                47.034,
                47.036,
                47.039,
                47.041,
                47.043,
                47.046,
                47.048,
                47.051,
                47.053,
                47.056,
                47.058,
                47.061,
                47.062,
                47.065,
                47.067,
                47.07,
                47.072,
                47.075,
                47.077,
                47.08,
                47.081,
                47.084,
                47.086,
                47.089,
                47.091,
                47.094,
                47.096,
                47.099,
                47.1,
                47.103,
                47.105,
                47.108,
                47.11,
                47.112,
                47.115,
                47.117,
                47.119,
                47.121,
                47.124,
                47.126,
                47.129,
                47.131,
                47.133,
                47.136,
                47.137,
                47.14,
                47.142,
                47.144,
                47.147,
                47.149,
                47.152,
                47.154,
                47.155,
                47.158,
                47.16,
                47.163,
                47.165,
                47.167,
                47.17,
                47.172,
                47.174,
                47.176,
                47.178,
                47.181,
                47.183,
                47.185,
                47.188,
                47.19,
                47.191,
                47.194,
                47.196,
                47.198,
                47.201,
                47.203,
                47.205,
                47.208,
                47.209,
                47.211,
                47.214,
                47.216,
                47.218,
                47.22,
                47.223,
                47.225,
                47.226,
                47.229,
                47.231,
                47.233,
                47.236,
                47.238,
                47.24,
                47.242,
                47.244,
                47.246,
                47.248,
                47.25,
                47.253,
                47.255,
                47.257,
                47.259,
                47.262,
                47.263,
                47.265,
                47.267,
                47.27,
                47.272,
                47.274,
                47.276,
                47.279,
                47.28,
                47.282,
                47.284,
                47.287,
                47.289,
                47.291,
                47.293,
                47.295,
                47.297,
                47.299,
                47.301,
                47.303,
                47.305,
                47.308,
                47.31,
                47.312,
                47.313,
                47.315,
                47.318,
                47.32,
                47.322,
                47.324,
                47.326,
                47.328,
                47.33,
                47.332,
                47.334,
                47.336,
                47.338,
                47.34,
                47.342,
                47.345,
                47.347,
                47.348,
                47.35,
                47.352,
                47.354,
                47.356,
                47.358,
                47.361,
                47.363,
                47.365,
                47.366,
                47.368,
                47.37,
                47.372,
                47.374,
                47.376,
                47.379,
                47.381,
                47.382,
                47.384,
                47.386,
                47.388,
                47.39,
                47.392,
                47.394,
                47.396,
                47.398,
                47.4,
                47.402,
                47.404,
                47.406,
                47.408,
                47.41,
                47.412,
                47.414,
                47.416,
                47.417,
                47.419,
                47.421,
                47.423,
                47.425,
                47.427,
                47.429,
                47.431,
                47.433,
                47.435,
                47.437,
                47.439,
                47.441,
                47.443,
                47.445,
                47.447,
                47.449,
                47.45,
                47.452,
                47.454,
                47.456,
                47.458,
                47.46,
                47.462,
                47.464,
                47.466,
                47.467,
                47.469,
                47.471,
                47.473,
                47.475,
                47.477,
                47.479,
                47.481,
                47.483,
                47.484,
                47.486,
                47.488,
                47.49,
                47.492,
                47.494,
                47.495,
                47.497,
                47.499,
                47.5,
                47.502,
                47.504,
                47.506,
                47.508,
                47.51,
                47.512,
                47.514,
                47.516,
                47.517,
                47.519,
                47.521,
                47.523,
                47.525,
                47.527,
                47.528,
                47.53,
                47.532,
                47.533,
                47.535,
                47.537,
                47.539,
                47.541,
                47.543,
                47.545,
                47.547,
                47.549,
                47.549,
                47.551,
                47.553,
                47.555,
                47.557,
                47.559,
                47.561,
                47.563,
                47.565,
                47.566,
                47.567,
                47.569,
                47.571,
                47.573,
                47.575,
                47.577,
                47.579,
                47.58,
                47.581,
                47.583,
                47.585,
                47.587,
                47.589,
                47.591,
                47.592,
                47.594,
                47.596,
                47.597,
                47.599,
                47.601,
                47.603,
                47.604,
                47.606,
                47.608,
                47.61,
                47.612,
                47.613,
                47.614,
                47.616,
                47.618,
                47.62,
                47.622,
                47.623,
                47.625,
                47.627,
                47.629,
                47.63,
                47.632,
                47.633,
                47.635,
                47.637,
                47.639,
                47.641,
                47.642,
                47.644,
                47.645,
                47.647,
                47.649,
                47.65,
                47.652,
                47.654,
                47.656,
                47.657,
                47.659,
                47.66,
                47.662,
                47.664,
                47.665,
                47.667,
                47.669,
                47.671,
                47.672,
                47.674,
                47.676,
                47.677,
                47.678,
                47.68,
                47.682,
                47.684,
                47.685,
                47.687,
                47.689,
                47.691,
                47.691,
                47.693,
                47.695,
                47.697,
                47.698,
                47.7,
                47.702,
                47.703,
                47.705,
                47.707,
                47.708,
                47.709,
                47.711,
                47.713,
                47.714,
                47.716,
                47.718,
                47.72,
                47.721,
                47.723,
                47.724,
                47.725,
                47.727,
                47.729,
                47.731,
                47.732,
                47.734,
                47.736,
                47.737,
                47.738,
                47.74,
                47.741,
                47.743,
                47.745,
                47.746,
                47.748,
                47.75,
                47.751,
                47.753,
                47.754,
                47.756,
                47.757,
                47.759,
                47.76,
                47.762,
                47.764,
                47.765,
                47.767,
                47.769,
                47.769,
                47.771,
                47.773,
                47.774,
                47.776,
                47.778,
                47.779,
                47.781,
                47.782,
                47.784,
                47.785,
                47.786,
                47.788,
                47.79,
                47.791,
                47.793,
                47.795,
                47.796,
                47.798,
                47.799,
                47.8,
                47.802,
                47.803,
                47.805,
                47.806,
                47.808,
                47.81,
                47.811,
                47.813,
                47.814,
                47.815,
                47.817,
                47.818,
                47.82,
                47.822,
                47.823,
                47.825,
                47.826,
                47.828,
                47.829,
                47.83,
                47.832,
                47.833,
                47.835,
                47.836,
                47.838,
                47.84,
                47.841,
                47.843,
                47.844,
                47.845,
                47.846,
                47.848,
                47.85,
                47.851,
                47.853,
                47.854,
                47.856,
                47.857,
                47.859,
                47.86,
                47.861,
                47.863,
                47.864,
                47.866,
                47.867,
                47.869,
                47.87,
                47.872,
                47.873,
                47.874,
                47.876,
                47.877,
                47.879,
                47.88,
                47.882,
                47.883,
                47.885,
                47.886,
                47.888,
                47.888,
                47.89,
                47.891,
                47.893,
                47.894,
                47.896,
                47.897,
                47.899,
                47.9,
                47.902,
                47.903,
                47.904,
                47.906,
                47.907,
                47.909,
                47.91,
                47.911,
                47.913,
                47.915,
                47.916,
                47.917,
                47.918,
                47.92,
                47.921,
                47.923,
                47.924,
                47.926,
                47.927,
                47.928,
                47.93,
                47.931,
                47.932,
                47.933,
                47.935,
                47.936,
                47.938,
                47.939,
                47.941,
                47.942,
                47.944,
                47.945,
                47.947,
                47.947,
                47.949,
                47.95,
                47.952,
                47.953,
                47.955,
                47.956,
                47.957,
                47.959,
                47.96,
                47.961,
                47.962,
                47.964,
                47.965,
                47.967,
                47.968,
                47.969,
                47.971,
                47.972,
                47.974,
                47.975,
                47.976,
                47.977,
                47.979,
                47.98,
                47.982,
                47.983,
                47.984,
                47.986,
                47.987,
                47.989,
                47.989,
                47.991,
                47.992,
                47.993,
                47.995,
                47.996,
                47.998,
                47.999,
                48,
                48.002,
                48.003,
                48.004,
                48.005,
                48.007,
                48.008,
                48.009,
                48.011,
                48.012,
                48.014,
                48.015,
                48.016,
                48.018,
                48.018,
                48.02,
                48.021,
                48.022,
                48.024,
                48.025,
                48.027,
                48.028,
                48.029,
                48.031,
                48.032,
                48.033,
                48.034,
                48.035,
                48.037,
                48.038,
                48.039,
                48.041,
                48.042,
                48.044,
                48.045,
                48.046,
                48.047,
                48.048,
                48.05,
                48.051,
                48.052,
                48.054,
                48.055,
                48.056,
                48.058,
                48.059,
                48.06,
                48.061,
                48.062,
                48.064,
                48.065,
                48.066,
                48.068,
                48.069,
                48.07,
                48.072,
                48.073,
                48.074,
                48.075,
                48.076,
                48.078,
                48.079,
                48.08,
                48.082,
                48.083,
                48.084,
                48.086,
                48.087,
                48.087,
                48.089,
                48.09,
                48.091,
                48.093,
                48.094,
                48.095,
                48.097,
                48.098,
                48.099,
                48.101,
                48.101,
                48.103,
                48.104,
                48.105,
                48.107,
                48.108,
                48.109,
                48.11,
                48.112,
                48.113,
                48.114,
                48.116,
                48.116,
                48.117,
                48.119,
                48.12,
                48.121,
                48.123,
                48.124,
                48.125,
                48.127,
                48.128,
                48.129,
                48.13,
                48.131,
                48.132,
                48.134,
                48.135,
                48.136,
                48.138,
                48.139,
                48.14,
                48.141,
                48.143,
                48.143,
                48.144,
                48.146,
                48.147,
                48.148,
                48.15,
                48.151,
                48.152,
                48.153,
                48.155,
                48.156,
                48.156,
                48.158,
                48.159,
                48.16,
                48.162,
                48.163,
                48.164,
                48.165,
                48.167,
                48.168,
                48.169,
                48.17,
                48.171,
                48.172,
                48.173,
                48.175,
                48.176,
                48.177,
                48.179,
                48.18,
                48.181,
                48.182,
                48.184,
                48.184,
                48.185,
                48.187,
                48.188,
                48.189,
                48.19,
                48.192,
                48.193,
                48.194,
                48.195,
                48.197,
                48.197,
                48.198,
                48.2,
                48.201,
                48.202,
                48.203,
                48.205,
                48.206,
                48.207,
                48.208,
                48.21,
                48.211,
                48.211,
                48.213,
                48.214,
                48.215,
                48.216,
                48.218,
                48.219,
                48.22,
                48.221,
                48.223,
                48.224,
                48.225,
                48.225,
                48.227,
                48.228,
                48.229,
                48.23,
                48.232,
                48.233,
                48.234,
                48.235,
                48.237,
                48.238,
                48.238,
                48.239,
                48.241,
                48.242,
                48.243,
                48.244,
                48.246,
                48.247,
                48.248,
                48.249,
                48.251,
                48.252,
                48.252,
                48.253,
                48.255,
                48.256,
                48.257,
                48.258,
                48.26,
                48.261,
                48.262,
                48.263,
                48.264,
                48.266,
                48.266,
                48.267,
                48.268,
                48.27,
                48.271,
                48.272,
                48.273,
                48.275,
                48.276,
                48.277,
                48.278,
                48.279,
                48.28,
                48.281,
                48.282,
                48.283,
                48.285,
                48.286,
                48.287,
                48.288,
                48.289,
                48.291,
                48.292,
                48.292,
                48.293,
                48.295,
                48.296,
                48.297,
                48.298,
                48.299,
                48.301,
                48.302,
                48.303,
                48.304,
                48.306,
                48.306,
                48.307,
                48.308,
                48.309,
                48.311,
                48.312,
                48.313
            ],
            P10: [
                32.834,
                32.943,
                33.052,
                33.161,
                33.27,
                33.38,
                33.489,
                33.599,
                33.709,
                33.818,
                33.928,
                34.037,
                34.148,
                34.258,
                34.367,
                34.468,
                34.566,
                34.662,
                34.756,
                34.848,
                34.937,
                35.025,
                35.112,
                35.196,
                35.279,
                35.36,
                35.44,
                35.519,
                35.596,
                35.673,
                35.747,
                35.821,
                35.893,
                35.964,
                36.035,
                36.104,
                36.172,
                36.24,
                36.306,
                36.371,
                36.436,
                36.5,
                36.562,
                36.625,
                36.687,
                36.747,
                36.807,
                36.866,
                36.925,
                36.983,
                37.04,
                37.097,
                37.153,
                37.208,
                37.263,
                37.317,
                37.371,
                37.424,
                37.477,
                37.529,
                37.581,
                37.632,
                37.683,
                37.733,
                37.783,
                37.833,
                37.882,
                37.93,
                37.978,
                38.026,
                38.074,
                38.121,
                38.167,
                38.213,
                38.259,
                38.305,
                38.35,
                38.395,
                38.439,
                38.483,
                38.526,
                38.57,
                38.613,
                38.655,
                38.698,
                38.74,
                38.782,
                38.823,
                38.864,
                38.906,
                38.946,
                38.986,
                39.026,
                39.066,
                39.105,
                39.145,
                39.184,
                39.222,
                39.26,
                39.299,
                39.337,
                39.374,
                39.411,
                39.449,
                39.486,
                39.522,
                39.558,
                39.595,
                39.63,
                39.666,
                39.701,
                39.737,
                39.772,
                39.807,
                39.841,
                39.876,
                39.91,
                39.943,
                39.977,
                40.01,
                40.043,
                40.077,
                40.11,
                40.142,
                40.175,
                40.207,
                40.239,
                40.271,
                40.302,
                40.334,
                40.365,
                40.396,
                40.427,
                40.457,
                40.488,
                40.518,
                40.548,
                40.578,
                40.608,
                40.637,
                40.667,
                40.696,
                40.725,
                40.754,
                40.782,
                40.811,
                40.839,
                40.867,
                40.895,
                40.923,
                40.95,
                40.978,
                41.005,
                41.033,
                41.06,
                41.086,
                41.113,
                41.139,
                41.166,
                41.191,
                41.218,
                41.244,
                41.269,
                41.295,
                41.32,
                41.345,
                41.37,
                41.395,
                41.42,
                41.445,
                41.469,
                41.494,
                41.518,
                41.542,
                41.566,
                41.59,
                41.613,
                41.637,
                41.66,
                41.683,
                41.706,
                41.729,
                41.752,
                41.775,
                41.798,
                41.82,
                41.842,
                41.864,
                41.887,
                41.908,
                41.93,
                41.952,
                41.974,
                41.995,
                42.016,
                42.037,
                42.059,
                42.079,
                42.1,
                42.121,
                42.141,
                42.162,
                42.182,
                42.202,
                42.223,
                42.242,
                42.262,
                42.282,
                42.302,
                42.321,
                42.341,
                42.36,
                42.379,
                42.399,
                42.418,
                42.436,
                42.455,
                42.474,
                42.492,
                42.511,
                42.53,
                42.548,
                42.566,
                42.584,
                42.602,
                42.62,
                42.638,
                42.656,
                42.673,
                42.69,
                42.708,
                42.725,
                42.743,
                42.76,
                42.777,
                42.794,
                42.811,
                42.827,
                42.844,
                42.861,
                42.877,
                42.894,
                42.91,
                42.926,
                42.942,
                42.958,
                42.974,
                42.99,
                43.006,
                43.022,
                43.038,
                43.053,
                43.069,
                43.084,
                43.099,
                43.115,
                43.13,
                43.145,
                43.16,
                43.175,
                43.19,
                43.204,
                43.22,
                43.234,
                43.249,
                43.263,
                43.277,
                43.292,
                43.306,
                43.32,
                43.335,
                43.349,
                43.363,
                43.377,
                43.391,
                43.404,
                43.418,
                43.432,
                43.445,
                43.459,
                43.473,
                43.486,
                43.499,
                43.513,
                43.526,
                43.539,
                43.552,
                43.565,
                43.578,
                43.591,
                43.603,
                43.617,
                43.629,
                43.642,
                43.655,
                43.667,
                43.68,
                43.692,
                43.704,
                43.717,
                43.729,
                43.741,
                43.753,
                43.765,
                43.777,
                43.789,
                43.801,
                43.813,
                43.825,
                43.837,
                43.849,
                43.86,
                43.872,
                43.883,
                43.895,
                43.906,
                43.917,
                43.929,
                43.94,
                43.951,
                43.962,
                43.973,
                43.985,
                43.996,
                44.006,
                44.017,
                44.028,
                44.039,
                44.05,
                44.06,
                44.071,
                44.082,
                44.092,
                44.103,
                44.113,
                44.124,
                44.134,
                44.144,
                44.155,
                44.165,
                44.175,
                44.186,
                44.196,
                44.206,
                44.216,
                44.226,
                44.236,
                44.246,
                44.256,
                44.266,
                44.276,
                44.285,
                44.295,
                44.305,
                44.314,
                44.324,
                44.334,
                44.343,
                44.353,
                44.362,
                44.371,
                44.381,
                44.389,
                44.399,
                44.408,
                44.417,
                44.426,
                44.436,
                44.445,
                44.454,
                44.463,
                44.472,
                44.481,
                44.49,
                44.499,
                44.507,
                44.516,
                44.525,
                44.534,
                44.542,
                44.551,
                44.56,
                44.568,
                44.577,
                44.586,
                44.594,
                44.603,
                44.611,
                44.619,
                44.628,
                44.636,
                44.645,
                44.653,
                44.661,
                44.669,
                44.678,
                44.686,
                44.694,
                44.702,
                44.71,
                44.718,
                44.726,
                44.734,
                44.742,
                44.75,
                44.758,
                44.765,
                44.773,
                44.781,
                44.789,
                44.797,
                44.804,
                44.812,
                44.82,
                44.828,
                44.835,
                44.843,
                44.85,
                44.858,
                44.866,
                44.873,
                44.881,
                44.888,
                44.896,
                44.903,
                44.91,
                44.917,
                44.925,
                44.932,
                44.939,
                44.947,
                44.954,
                44.961,
                44.968,
                44.975,
                44.983,
                44.99,
                44.997,
                45.004,
                45.011,
                45.018,
                45.025,
                45.032,
                45.038,
                45.046,
                45.052,
                45.059,
                45.066,
                45.073,
                45.08,
                45.087,
                45.094,
                45.1,
                45.107,
                45.114,
                45.121,
                45.127,
                45.134,
                45.141,
                45.147,
                45.154,
                45.16,
                45.167,
                45.173,
                45.18,
                45.186,
                45.193,
                45.2,
                45.206,
                45.212,
                45.219,
                45.225,
                45.232,
                45.238,
                45.244,
                45.25,
                45.257,
                45.263,
                45.269,
                45.276,
                45.282,
                45.288,
                45.295,
                45.301,
                45.307,
                45.313,
                45.319,
                45.325,
                45.331,
                45.337,
                45.343,
                45.349,
                45.356,
                45.362,
                45.368,
                45.374,
                45.38,
                45.386,
                45.392,
                45.397,
                45.403,
                45.409,
                45.415,
                45.421,
                45.427,
                45.433,
                45.439,
                45.445,
                45.45,
                45.456,
                45.462,
                45.467,
                45.473,
                45.479,
                45.485,
                45.49,
                45.496,
                45.502,
                45.508,
                45.513,
                45.519,
                45.525,
                45.53,
                45.535,
                45.541,
                45.547,
                45.552,
                45.558,
                45.564,
                45.569,
                45.575,
                45.58,
                45.586,
                45.591,
                45.596,
                45.602,
                45.607,
                45.613,
                45.618,
                45.624,
                45.629,
                45.635,
                45.64,
                45.646,
                45.65,
                45.656,
                45.661,
                45.667,
                45.672,
                45.677,
                45.683,
                45.688,
                45.693,
                45.699,
                45.704,
                45.709,
                45.714,
                45.719,
                45.724,
                45.73,
                45.735,
                45.74,
                45.745,
                45.751,
                45.756,
                45.76,
                45.766,
                45.771,
                45.776,
                45.781,
                45.786,
                45.791,
                45.797,
                45.802,
                45.807,
                45.811,
                45.816,
                45.821,
                45.827,
                45.832,
                45.837,
                45.842,
                45.847,
                45.852,
                45.856,
                45.861,
                45.866,
                45.871,
                45.876,
                45.881,
                45.886,
                45.891,
                45.896,
                45.9,
                45.905,
                45.91,
                45.915,
                45.92,
                45.925,
                45.93,
                45.935,
                45.94,
                45.945,
                45.949,
                45.954,
                45.959,
                45.964,
                45.968,
                45.973,
                45.978,
                45.983,
                45.988,
                45.992,
                45.997,
                46.001,
                46.006,
                46.011,
                46.016,
                46.021,
                46.025,
                46.029,
                46.034,
                46.039,
                46.044,
                46.048,
                46.053,
                46.058,
                46.063,
                46.067,
                46.071,
                46.076,
                46.081,
                46.085,
                46.09,
                46.095,
                46.099,
                46.104,
                46.108,
                46.112,
                46.117,
                46.122,
                46.126,
                46.131,
                46.136,
                46.14,
                46.145,
                46.149,
                46.153,
                46.158,
                46.162,
                46.167,
                46.171,
                46.176,
                46.18,
                46.184,
                46.189,
                46.193,
                46.198,
                46.202,
                46.207,
                46.211,
                46.216,
                46.22,
                46.224,
                46.229,
                46.233,
                46.237,
                46.242,
                46.246,
                46.251,
                46.255,
                46.259,
                46.263,
                46.268,
                46.272,
                46.277,
                46.281,
                46.285,
                46.289,
                46.293,
                46.298,
                46.302,
                46.307,
                46.311,
                46.315,
                46.32,
                46.323,
                46.328,
                46.332,
                46.336,
                46.341,
                46.345,
                46.349,
                46.353,
                46.357,
                46.361,
                46.366,
                46.37,
                46.374,
                46.378,
                46.383,
                46.387,
                46.39,
                46.395,
                46.399,
                46.403,
                46.407,
                46.412,
                46.416,
                46.419,
                46.424,
                46.428,
                46.432,
                46.436,
                46.44,
                46.445,
                46.449,
                46.452,
                46.456,
                46.46,
                46.465,
                46.469,
                46.473,
                46.477,
                46.481,
                46.485,
                46.489,
                46.493,
                46.497,
                46.501,
                46.505,
                46.509,
                46.513,
                46.517,
                46.521,
                46.525,
                46.529,
                46.533,
                46.537,
                46.54,
                46.544,
                46.548,
                46.552,
                46.556,
                46.56,
                46.564,
                46.568,
                46.572,
                46.576,
                46.58,
                46.584,
                46.588,
                46.592,
                46.596,
                46.599,
                46.603,
                46.607,
                46.611,
                46.615,
                46.619,
                46.623,
                46.627,
                46.63,
                46.634,
                46.638,
                46.642,
                46.645,
                46.649,
                46.653,
                46.656,
                46.66,
                46.664,
                46.668,
                46.672,
                46.676,
                46.68,
                46.683,
                46.687,
                46.69,
                46.694,
                46.698,
                46.702,
                46.706,
                46.709,
                46.713,
                46.716,
                46.72,
                46.724,
                46.728,
                46.732,
                46.735,
                46.739,
                46.742,
                46.746,
                46.75,
                46.754,
                46.757,
                46.761,
                46.764,
                46.768,
                46.772,
                46.775,
                46.779,
                46.783,
                46.786,
                46.789,
                46.793,
                46.797,
                46.8,
                46.804,
                46.808,
                46.811,
                46.815,
                46.818,
                46.822,
                46.825,
                46.829,
                46.833,
                46.836,
                46.839,
                46.843,
                46.846,
                46.85,
                46.854,
                46.857,
                46.861,
                46.864,
                46.867,
                46.871,
                46.875,
                46.878,
                46.882,
                46.885,
                46.889,
                46.892,
                46.895,
                46.899,
                46.902,
                46.906,
                46.909,
                46.913,
                46.916,
                46.919,
                46.923,
                46.926,
                46.93,
                46.933,
                46.937,
                46.94,
                46.943,
                46.946,
                46.95,
                46.953,
                46.957,
                46.96,
                46.963,
                46.966,
                46.97,
                46.973,
                46.977,
                46.98,
                46.984,
                46.986,
                46.99,
                46.993,
                46.997,
                47,
                47.003,
                47.007,
                47.009,
                47.013,
                47.016,
                47.02,
                47.023,
                47.026,
                47.03,
                47.032,
                47.036,
                47.039,
                47.042,
                47.046,
                47.049,
                47.052,
                47.055,
                47.058,
                47.062,
                47.065,
                47.068,
                47.071,
                47.075,
                47.078,
                47.081,
                47.084,
                47.087,
                47.09,
                47.094,
                47.097,
                47.1,
                47.103,
                47.106,
                47.109,
                47.112,
                47.115,
                47.119,
                47.122,
                47.124,
                47.128,
                47.131,
                47.134,
                47.137,
                47.14,
                47.144,
                47.146,
                47.149,
                47.152,
                47.156,
                47.159,
                47.162,
                47.165,
                47.168,
                47.171,
                47.174,
                47.177,
                47.18,
                47.183,
                47.186,
                47.189,
                47.192,
                47.195,
                47.198,
                47.201,
                47.204,
                47.207,
                47.21,
                47.213,
                47.216,
                47.219,
                47.222,
                47.225,
                47.228,
                47.231,
                47.233,
                47.236,
                47.24,
                47.243,
                47.246,
                47.249,
                47.252,
                47.254,
                47.257,
                47.26,
                47.263,
                47.266,
                47.269,
                47.272,
                47.275,
                47.277,
                47.28,
                47.283,
                47.286,
                47.289,
                47.292,
                47.295,
                47.297,
                47.3,
                47.303,
                47.306,
                47.309,
                47.312,
                47.315,
                47.318,
                47.32,
                47.323,
                47.326,
                47.329,
                47.331,
                47.334,
                47.337,
                47.339,
                47.342,
                47.345,
                47.348,
                47.351,
                47.354,
                47.357,
                47.359,
                47.362,
                47.364,
                47.367,
                47.37,
                47.373,
                47.376,
                47.379,
                47.381,
                47.384,
                47.386,
                47.389,
                47.392,
                47.395,
                47.398,
                47.4,
                47.403,
                47.405,
                47.408,
                47.411,
                47.414,
                47.416,
                47.419,
                47.421,
                47.424,
                47.427,
                47.429,
                47.432,
                47.435,
                47.438,
                47.44,
                47.443,
                47.445,
                47.448,
                47.451,
                47.453,
                47.456,
                47.459,
                47.461,
                47.463,
                47.466,
                47.469,
                47.472,
                47.474,
                47.477,
                47.48,
                47.482,
                47.484,
                47.487,
                47.49,
                47.492,
                47.495,
                47.498,
                47.499,
                47.502,
                47.505,
                47.507,
                47.51,
                47.513,
                47.515,
                47.518,
                47.52,
                47.522,
                47.525,
                47.528,
                47.53,
                47.533,
                47.535,
                47.538,
                47.54,
                47.543,
                47.545,
                47.548,
                47.55,
                47.553,
                47.555,
                47.557,
                47.56,
                47.562,
                47.565,
                47.567,
                47.57,
                47.573,
                47.575,
                47.577,
                47.579,
                47.582,
                47.585,
                47.587,
                47.59,
                47.592,
                47.595,
                47.596,
                47.599,
                47.601,
                47.604,
                47.606,
                47.609,
                47.611,
                47.614,
                47.616,
                47.618,
                47.621,
                47.623,
                47.626,
                47.628,
                47.63,
                47.633,
                47.635,
                47.637,
                47.64,
                47.642,
                47.644,
                47.647,
                47.649,
                47.652,
                47.654,
                47.656,
                47.658,
                47.661,
                47.663,
                47.666,
                47.668,
                47.67,
                47.672,
                47.674,
                47.677,
                47.679,
                47.682,
                47.684,
                47.686,
                47.689,
                47.691,
                47.693,
                47.695,
                47.698,
                47.7,
                47.702,
                47.705,
                47.707,
                47.709,
                47.711,
                47.713,
                47.716,
                47.718,
                47.72,
                47.723,
                47.725,
                47.727,
                47.729,
                47.731,
                47.734,
                47.736,
                47.738,
                47.741,
                47.743,
                47.745,
                47.747,
                47.749,
                47.751,
                47.754,
                47.756,
                47.758,
                47.761,
                47.762,
                47.765,
                47.767,
                47.769,
                47.771,
                47.774,
                47.776,
                47.778,
                47.78,
                47.782,
                47.784,
                47.786,
                47.789,
                47.791,
                47.793,
                47.795,
                47.798,
                47.799,
                47.801,
                47.804,
                47.806,
                47.808,
                47.81,
                47.813,
                47.815,
                47.816,
                47.818,
                47.821,
                47.823,
                47.825,
                47.827,
                47.829,
                47.832,
                47.833,
                47.835,
                47.838,
                47.84,
                47.842,
                47.844,
                47.846,
                47.848,
                47.851,
                47.852,
                47.854,
                47.857,
                47.859,
                47.861,
                47.863,
                47.865,
                47.867,
                47.869,
                47.871,
                47.873,
                47.875,
                47.877,
                47.879,
                47.881,
                47.884,
                47.886,
                47.887,
                47.889,
                47.891,
                47.894,
                47.896,
                47.898,
                47.9,
                47.902,
                47.904,
                47.906,
                47.908,
                47.91,
                47.912,
                47.914,
                47.916,
                47.918,
                47.92,
                47.922,
                47.924,
                47.926,
                47.928,
                47.93,
                47.932,
                47.934,
                47.936,
                47.938,
                47.94,
                47.942,
                47.944,
                47.946,
                47.948,
                47.95,
                47.952,
                47.954,
                47.955,
                47.957,
                47.959,
                47.961,
                47.963,
                47.965,
                47.967,
                47.969,
                47.972,
                47.973,
                47.975,
                47.977,
                47.979,
                47.981,
                47.983,
                47.985,
                47.987,
                47.989,
                47.99,
                47.992,
                47.994,
                47.996,
                47.998,
                48,
                48.002,
                48.004,
                48.006,
                48.008,
                48.01,
                48.012,
                48.014,
                48.015,
                48.017,
                48.019,
                48.021,
                48.023,
                48.025,
                48.027,
                48.029,
                48.03,
                48.033,
                48.034,
                48.036,
                48.038,
                48.04,
                48.042,
                48.044,
                48.045,
                48.047,
                48.049,
                48.051,
                48.053,
                48.055,
                48.057,
                48.058,
                48.06,
                48.062,
                48.064,
                48.066,
                48.068,
                48.07,
                48.072,
                48.074,
                48.075,
                48.077,
                48.079,
                48.081,
                48.082,
                48.084,
                48.086,
                48.088,
                48.09,
                48.091,
                48.093,
                48.095,
                48.097,
                48.099,
                48.101,
                48.102,
                48.104,
                48.106,
                48.107,
                48.109,
                48.111,
                48.113,
                48.115,
                48.117,
                48.119,
                48.12,
                48.122,
                48.124,
                48.125,
                48.127,
                48.129,
                48.131,
                48.133,
                48.135,
                48.136,
                48.138,
                48.139,
                48.141,
                48.143,
                48.145,
                48.147,
                48.148,
                48.15,
                48.152,
                48.154,
                48.156,
                48.157,
                48.159,
                48.161,
                48.162,
                48.164,
                48.166,
                48.168,
                48.17,
                48.171,
                48.173,
                48.174,
                48.176,
                48.178,
                48.18,
                48.181,
                48.183,
                48.185,
                48.187,
                48.188,
                48.19,
                48.191,
                48.193,
                48.195,
                48.197,
                48.199,
                48.2,
                48.202,
                48.204,
                48.205,
                48.207,
                48.208,
                48.21,
                48.212,
                48.214,
                48.215,
                48.217,
                48.219,
                48.22,
                48.222,
                48.223,
                48.225,
                48.227,
                48.229,
                48.23,
                48.232,
                48.234,
                48.236,
                48.237,
                48.238,
                48.24,
                48.242,
                48.244,
                48.245,
                48.247,
                48.249,
                48.25,
                48.252,
                48.253,
                48.255,
                48.257,
                48.258,
                48.26,
                48.262,
                48.263,
                48.265,
                48.267,
                48.268,
                48.269,
                48.271,
                48.273,
                48.274,
                48.276,
                48.278,
                48.279,
                48.281,
                48.283,
                48.284,
                48.286,
                48.287,
                48.289,
                48.291,
                48.292,
                48.294,
                48.296,
                48.297,
                48.299,
                48.3,
                48.302,
                48.303,
                48.305,
                48.306,
                48.308,
                48.31,
                48.311,
                48.313,
                48.315,
                48.316,
                48.317,
                48.319,
                48.321,
                48.322,
                48.324,
                48.325,
                48.327,
                48.329,
                48.33,
                48.331,
                48.333,
                48.334,
                48.336,
                48.338,
                48.339,
                48.341,
                48.343,
                48.344,
                48.345,
                48.347,
                48.348,
                48.35,
                48.351,
                48.353,
                48.355,
                48.356,
                48.358,
                48.359,
                48.36,
                48.362,
                48.364,
                48.365,
                48.367,
                48.368,
                48.37,
                48.372,
                48.373,
                48.375,
                48.376,
                48.377,
                48.379,
                48.38,
                48.382,
                48.383,
                48.385,
                48.387,
                48.388,
                48.39,
                48.391,
                48.392,
                48.394,
                48.395,
                48.397,
                48.398,
                48.4,
                48.401,
                48.403,
                48.404,
                48.406,
                48.407,
                48.408,
                48.41,
                48.412,
                48.413,
                48.415,
                48.416,
                48.418,
                48.419,
                48.421,
                48.422,
                48.423,
                48.425,
                48.426,
                48.428,
                48.429,
                48.431,
                48.432,
                48.434,
                48.435,
                48.436,
                48.438,
                48.439,
                48.441,
                48.442,
                48.444,
                48.445,
                48.447,
                48.448,
                48.45,
                48.451,
                48.452,
                48.454,
                48.455,
                48.457,
                48.458,
                48.46,
                48.461,
                48.463,
                48.464,
                48.466,
                48.466,
                48.468,
                48.469,
                48.471,
                48.472,
                48.474,
                48.475,
                48.477,
                48.478,
                48.48,
                48.481,
                48.482,
                48.483,
                48.485,
                48.486,
                48.488,
                48.489,
                48.491,
                48.492,
                48.494,
                48.495,
                48.496,
                48.497,
                48.499,
                48.5,
                48.502,
                48.503,
                48.505,
                48.506,
                48.508,
                48.509,
                48.51,
                48.511,
                48.513,
                48.514,
                48.516,
                48.517,
                48.518,
                48.52,
                48.521,
                48.523,
                48.524,
                48.525,
                48.526,
                48.528,
                48.529,
                48.531,
                48.532,
                48.533,
                48.535,
                48.536,
                48.538,
                48.539,
                48.54,
                48.541,
                48.543,
                48.544,
                48.546,
                48.547,
                48.548,
                48.55,
                48.551,
                48.553,
                48.554,
                48.555,
                48.556,
                48.558,
                48.559,
                48.56,
                48.562,
                48.563,
                48.565,
                48.566,
                48.567,
                48.569,
                48.569,
                48.571,
                48.572,
                48.574,
                48.575,
                48.576,
                48.578,
                48.579,
                48.581,
                48.582,
                48.583,
                48.584,
                48.585,
                48.587,
                48.588,
                48.59,
                48.591,
                48.592,
                48.594,
                48.595,
                48.596,
                48.597,
                48.598,
                48.6,
                48.601,
                48.603,
                48.604,
                48.605,
                48.607,
                48.608,
                48.609,
                48.611,
                48.611,
                48.613,
                48.614,
                48.616,
                48.617,
                48.618,
                48.62,
                48.621,
                48.622,
                48.624,
                48.625,
                48.626,
                48.627,
                48.628,
                48.63,
                48.631,
                48.632,
                48.634,
                48.635,
                48.636,
                48.638,
                48.639,
                48.64,
                48.641,
                48.642,
                48.644,
                48.645,
                48.646,
                48.648,
                48.649,
                48.65,
                48.652,
                48.653,
                48.654,
                48.655,
                48.656,
                48.658,
                48.659,
                48.66,
                48.662,
                48.663,
                48.664,
                48.666,
                48.667,
                48.668,
                48.669,
                48.67,
                48.672,
                48.673,
                48.674,
                48.675,
                48.677,
                48.678,
                48.679,
                48.681,
                48.682,
                48.683,
                48.684,
                48.685,
                48.687,
                48.688,
                48.689,
                48.691,
                48.692,
                48.693,
                48.694,
                48.696,
                48.696,
                48.698,
                48.699,
                48.7,
                48.702,
                48.703,
                48.704,
                48.705,
                48.707,
                48.708,
                48.709,
                48.711,
                48.711,
                48.713,
                48.714,
                48.715,
                48.716,
                48.718,
                48.719,
                48.72,
                48.721,
                48.723,
                48.724,
                48.725,
                48.726,
                48.727,
                48.729,
                48.73,
                48.731,
                48.732,
                48.734,
                48.735,
                48.736,
                48.737,
                48.738,
                48.739,
                48.741,
                48.742,
                48.743,
                48.744,
                48.746,
                48.747,
                48.748,
                48.75,
                48.751,
                48.752,
                48.753,
                48.754,
                48.755,
                48.756,
                48.758,
                48.759,
                48.76,
                48.761,
                48.763,
                48.764,
                48.765,
                48.766,
                48.767,
                48.768,
                48.77,
                48.771,
                48.772,
                48.773,
                48.775,
                48.776,
                48.777,
                48.778,
                48.78,
                48.78,
                48.781,
                48.783,
                48.784,
                48.785,
                48.786,
                48.788,
                48.789,
                48.79,
                48.791,
                48.793,
                48.794,
                48.795,
                48.796,
                48.797,
                48.798,
                48.799,
                48.801,
                48.802,
                48.803,
                48.804,
                48.806,
                48.807,
                48.808,
                48.809,
                48.81,
                48.811,
                48.812,
                48.814,
                48.815,
                48.816,
                48.817,
                48.819,
                48.82,
                48.821,
                48.822,
                48.823,
                48.824,
                48.825,
                48.827,
                48.828,
                48.829,
                48.83,
                48.831,
                48.833,
                48.834,
                48.835,
                48.836,
                48.837,
                48.838,
                48.839,
                48.841,
                48.842,
                48.843,
                48.844,
                48.845,
                48.847,
                48.848,
                48.849,
                48.85,
                48.851,
                48.852,
                48.853,
                48.855,
                48.856,
                48.857
            ],
            P15: [
                33.145,
                33.252,
                33.36,
                33.467,
                33.575,
                33.683,
                33.79,
                33.898,
                34.006,
                34.114,
                34.222,
                34.329,
                34.438,
                34.546,
                34.654,
                34.754,
                34.853,
                34.949,
                35.042,
                35.134,
                35.224,
                35.311,
                35.398,
                35.482,
                35.565,
                35.646,
                35.726,
                35.805,
                35.882,
                35.959,
                36.033,
                36.107,
                36.18,
                36.251,
                36.321,
                36.39,
                36.459,
                36.526,
                36.592,
                36.658,
                36.722,
                36.786,
                36.849,
                36.911,
                36.973,
                37.034,
                37.094,
                37.153,
                37.212,
                37.27,
                37.327,
                37.384,
                37.44,
                37.495,
                37.55,
                37.604,
                37.658,
                37.711,
                37.764,
                37.816,
                37.868,
                37.919,
                37.971,
                38.021,
                38.071,
                38.12,
                38.169,
                38.218,
                38.266,
                38.314,
                38.362,
                38.409,
                38.456,
                38.502,
                38.548,
                38.593,
                38.638,
                38.683,
                38.727,
                38.772,
                38.815,
                38.859,
                38.902,
                38.945,
                38.987,
                39.029,
                39.071,
                39.113,
                39.154,
                39.195,
                39.236,
                39.276,
                39.316,
                39.356,
                39.395,
                39.435,
                39.474,
                39.512,
                39.551,
                39.589,
                39.627,
                39.665,
                39.702,
                39.739,
                39.777,
                39.813,
                39.85,
                39.886,
                39.922,
                39.958,
                39.993,
                40.029,
                40.063,
                40.099,
                40.133,
                40.168,
                40.202,
                40.235,
                40.27,
                40.303,
                40.336,
                40.37,
                40.402,
                40.435,
                40.468,
                40.5,
                40.532,
                40.564,
                40.595,
                40.627,
                40.659,
                40.69,
                40.721,
                40.751,
                40.782,
                40.812,
                40.842,
                40.872,
                40.902,
                40.932,
                40.961,
                40.991,
                41.02,
                41.049,
                41.077,
                41.106,
                41.134,
                41.163,
                41.191,
                41.219,
                41.246,
                41.274,
                41.301,
                41.329,
                41.356,
                41.382,
                41.409,
                41.436,
                41.462,
                41.488,
                41.514,
                41.54,
                41.566,
                41.592,
                41.617,
                41.643,
                41.668,
                41.693,
                41.718,
                41.742,
                41.767,
                41.792,
                41.816,
                41.84,
                41.864,
                41.888,
                41.912,
                41.935,
                41.959,
                41.982,
                42.005,
                42.028,
                42.051,
                42.074,
                42.097,
                42.119,
                42.142,
                42.164,
                42.186,
                42.208,
                42.23,
                42.252,
                42.274,
                42.295,
                42.317,
                42.338,
                42.359,
                42.38,
                42.401,
                42.422,
                42.442,
                42.463,
                42.483,
                42.504,
                42.524,
                42.544,
                42.564,
                42.584,
                42.604,
                42.623,
                42.643,
                42.662,
                42.681,
                42.701,
                42.72,
                42.739,
                42.758,
                42.777,
                42.795,
                42.814,
                42.833,
                42.851,
                42.869,
                42.888,
                42.905,
                42.923,
                42.942,
                42.959,
                42.977,
                42.994,
                43.012,
                43.029,
                43.047,
                43.064,
                43.081,
                43.098,
                43.115,
                43.132,
                43.149,
                43.166,
                43.182,
                43.199,
                43.215,
                43.231,
                43.248,
                43.264,
                43.28,
                43.296,
                43.312,
                43.328,
                43.343,
                43.359,
                43.375,
                43.39,
                43.405,
                43.421,
                43.436,
                43.451,
                43.466,
                43.481,
                43.496,
                43.511,
                43.526,
                43.541,
                43.556,
                43.57,
                43.585,
                43.599,
                43.613,
                43.628,
                43.642,
                43.657,
                43.671,
                43.685,
                43.699,
                43.712,
                43.726,
                43.74,
                43.753,
                43.768,
                43.781,
                43.795,
                43.808,
                43.821,
                43.835,
                43.848,
                43.861,
                43.874,
                43.887,
                43.9,
                43.913,
                43.926,
                43.939,
                43.952,
                43.964,
                43.977,
                43.989,
                44.002,
                44.014,
                44.027,
                44.039,
                44.051,
                44.063,
                44.076,
                44.088,
                44.1,
                44.112,
                44.124,
                44.136,
                44.148,
                44.159,
                44.171,
                44.183,
                44.194,
                44.206,
                44.217,
                44.229,
                44.24,
                44.251,
                44.263,
                44.274,
                44.285,
                44.296,
                44.307,
                44.318,
                44.329,
                44.34,
                44.351,
                44.362,
                44.373,
                44.384,
                44.394,
                44.405,
                44.416,
                44.426,
                44.437,
                44.447,
                44.457,
                44.468,
                44.478,
                44.489,
                44.499,
                44.509,
                44.52,
                44.53,
                44.54,
                44.55,
                44.56,
                44.57,
                44.58,
                44.59,
                44.599,
                44.609,
                44.619,
                44.629,
                44.638,
                44.648,
                44.657,
                44.667,
                44.676,
                44.686,
                44.695,
                44.704,
                44.714,
                44.723,
                44.732,
                44.741,
                44.751,
                44.76,
                44.769,
                44.778,
                44.787,
                44.796,
                44.805,
                44.814,
                44.823,
                44.832,
                44.841,
                44.849,
                44.858,
                44.867,
                44.876,
                44.884,
                44.893,
                44.902,
                44.91,
                44.919,
                44.927,
                44.936,
                44.944,
                44.953,
                44.961,
                44.969,
                44.978,
                44.986,
                44.994,
                45.003,
                45.011,
                45.019,
                45.027,
                45.035,
                45.043,
                45.051,
                45.059,
                45.067,
                45.075,
                45.083,
                45.091,
                45.099,
                45.107,
                45.114,
                45.122,
                45.13,
                45.138,
                45.146,
                45.153,
                45.161,
                45.169,
                45.176,
                45.184,
                45.191,
                45.199,
                45.207,
                45.214,
                45.222,
                45.229,
                45.236,
                45.243,
                45.251,
                45.258,
                45.265,
                45.273,
                45.28,
                45.287,
                45.295,
                45.302,
                45.309,
                45.316,
                45.323,
                45.33,
                45.337,
                45.345,
                45.352,
                45.358,
                45.365,
                45.372,
                45.379,
                45.386,
                45.393,
                45.4,
                45.407,
                45.414,
                45.421,
                45.427,
                45.434,
                45.441,
                45.448,
                45.454,
                45.461,
                45.467,
                45.474,
                45.481,
                45.487,
                45.494,
                45.501,
                45.507,
                45.514,
                45.521,
                45.527,
                45.534,
                45.54,
                45.547,
                45.553,
                45.559,
                45.565,
                45.572,
                45.578,
                45.585,
                45.591,
                45.597,
                45.604,
                45.61,
                45.616,
                45.623,
                45.629,
                45.635,
                45.641,
                45.647,
                45.653,
                45.659,
                45.666,
                45.672,
                45.678,
                45.684,
                45.69,
                45.696,
                45.702,
                45.708,
                45.714,
                45.72,
                45.726,
                45.732,
                45.738,
                45.744,
                45.75,
                45.756,
                45.762,
                45.768,
                45.774,
                45.779,
                45.785,
                45.791,
                45.796,
                45.802,
                45.808,
                45.814,
                45.82,
                45.826,
                45.831,
                45.837,
                45.843,
                45.849,
                45.854,
                45.859,
                45.865,
                45.871,
                45.876,
                45.882,
                45.888,
                45.893,
                45.899,
                45.905,
                45.91,
                45.916,
                45.921,
                45.926,
                45.932,
                45.937,
                45.943,
                45.949,
                45.954,
                45.959,
                45.965,
                45.97,
                45.975,
                45.981,
                45.986,
                45.992,
                45.997,
                46.003,
                46.008,
                46.013,
                46.019,
                46.024,
                46.029,
                46.034,
                46.039,
                46.045,
                46.05,
                46.055,
                46.061,
                46.066,
                46.071,
                46.076,
                46.082,
                46.086,
                46.092,
                46.097,
                46.102,
                46.107,
                46.113,
                46.118,
                46.123,
                46.128,
                46.133,
                46.138,
                46.143,
                46.148,
                46.153,
                46.158,
                46.163,
                46.168,
                46.174,
                46.179,
                46.183,
                46.188,
                46.193,
                46.198,
                46.203,
                46.208,
                46.213,
                46.218,
                46.223,
                46.228,
                46.233,
                46.238,
                46.243,
                46.248,
                46.253,
                46.258,
                46.263,
                46.267,
                46.272,
                46.277,
                46.282,
                46.287,
                46.292,
                46.296,
                46.301,
                46.306,
                46.311,
                46.316,
                46.32,
                46.325,
                46.33,
                46.335,
                46.339,
                46.344,
                46.349,
                46.354,
                46.358,
                46.363,
                46.368,
                46.372,
                46.377,
                46.382,
                46.387,
                46.391,
                46.396,
                46.4,
                46.405,
                46.41,
                46.414,
                46.419,
                46.424,
                46.428,
                46.433,
                46.437,
                46.442,
                46.447,
                46.451,
                46.456,
                46.461,
                46.465,
                46.47,
                46.474,
                46.478,
                46.483,
                46.488,
                46.492,
                46.497,
                46.501,
                46.506,
                46.51,
                46.515,
                46.519,
                46.524,
                46.528,
                46.533,
                46.537,
                46.542,
                46.546,
                46.55,
                46.555,
                46.559,
                46.564,
                46.568,
                46.573,
                46.577,
                46.582,
                46.586,
                46.59,
                46.594,
                46.599,
                46.603,
                46.608,
                46.612,
                46.617,
                46.62,
                46.625,
                46.629,
                46.634,
                46.638,
                46.642,
                46.647,
                46.651,
                46.655,
                46.659,
                46.664,
                46.668,
                46.672,
                46.677,
                46.681,
                46.685,
                46.689,
                46.693,
                46.698,
                46.702,
                46.706,
                46.711,
                46.715,
                46.719,
                46.723,
                46.727,
                46.731,
                46.736,
                46.74,
                46.744,
                46.748,
                46.752,
                46.756,
                46.761,
                46.765,
                46.769,
                46.773,
                46.777,
                46.782,
                46.785,
                46.789,
                46.794,
                46.798,
                46.802,
                46.806,
                46.81,
                46.814,
                46.818,
                46.822,
                46.826,
                46.83,
                46.835,
                46.839,
                46.843,
                46.846,
                46.851,
                46.855,
                46.859,
                46.863,
                46.867,
                46.871,
                46.874,
                46.879,
                46.883,
                46.887,
                46.891,
                46.895,
                46.899,
                46.903,
                46.906,
                46.91,
                46.914,
                46.918,
                46.922,
                46.926,
                46.93,
                46.934,
                46.938,
                46.942,
                46.946,
                46.95,
                46.954,
                46.958,
                46.962,
                46.965,
                46.969,
                46.973,
                46.977,
                46.981,
                46.985,
                46.988,
                46.992,
                46.996,
                47,
                47.004,
                47.008,
                47.011,
                47.015,
                47.019,
                47.022,
                47.026,
                47.03,
                47.034,
                47.038,
                47.042,
                47.045,
                47.049,
                47.053,
                47.056,
                47.06,
                47.064,
                47.068,
                47.072,
                47.075,
                47.079,
                47.082,
                47.086,
                47.09,
                47.094,
                47.098,
                47.101,
                47.105,
                47.108,
                47.112,
                47.116,
                47.119,
                47.123,
                47.126,
                47.13,
                47.134,
                47.138,
                47.141,
                47.145,
                47.149,
                47.152,
                47.155,
                47.159,
                47.163,
                47.166,
                47.17,
                47.174,
                47.177,
                47.181,
                47.184,
                47.188,
                47.191,
                47.195,
                47.199,
                47.202,
                47.205,
                47.209,
                47.213,
                47.216,
                47.22,
                47.223,
                47.227,
                47.23,
                47.234,
                47.237,
                47.241,
                47.244,
                47.248,
                47.251,
                47.254,
                47.258,
                47.261,
                47.265,
                47.268,
                47.272,
                47.275,
                47.278,
                47.282,
                47.285,
                47.289,
                47.292,
                47.296,
                47.299,
                47.302,
                47.306,
                47.309,
                47.313,
                47.316,
                47.319,
                47.323,
                47.326,
                47.329,
                47.333,
                47.336,
                47.34,
                47.343,
                47.346,
                47.349,
                47.353,
                47.356,
                47.359,
                47.363,
                47.366,
                47.369,
                47.372,
                47.376,
                47.379,
                47.382,
                47.386,
                47.389,
                47.392,
                47.395,
                47.399,
                47.402,
                47.405,
                47.408,
                47.412,
                47.415,
                47.418,
                47.421,
                47.424,
                47.428,
                47.431,
                47.434,
                47.438,
                47.441,
                47.444,
                47.447,
                47.45,
                47.453,
                47.456,
                47.46,
                47.463,
                47.466,
                47.469,
                47.472,
                47.475,
                47.478,
                47.482,
                47.485,
                47.488,
                47.491,
                47.494,
                47.497,
                47.5,
                47.503,
                47.507,
                47.509,
                47.512,
                47.516,
                47.519,
                47.522,
                47.525,
                47.528,
                47.531,
                47.534,
                47.537,
                47.54,
                47.543,
                47.546,
                47.549,
                47.552,
                47.555,
                47.558,
                47.561,
                47.564,
                47.567,
                47.57,
                47.573,
                47.576,
                47.579,
                47.582,
                47.585,
                47.588,
                47.591,
                47.594,
                47.597,
                47.6,
                47.603,
                47.606,
                47.609,
                47.612,
                47.615,
                47.618,
                47.62,
                47.623,
                47.626,
                47.629,
                47.632,
                47.635,
                47.638,
                47.641,
                47.644,
                47.647,
                47.649,
                47.652,
                47.655,
                47.658,
                47.661,
                47.664,
                47.667,
                47.669,
                47.672,
                47.675,
                47.678,
                47.681,
                47.683,
                47.686,
                47.689,
                47.692,
                47.695,
                47.698,
                47.701,
                47.703,
                47.706,
                47.709,
                47.712,
                47.715,
                47.717,
                47.72,
                47.723,
                47.725,
                47.728,
                47.731,
                47.734,
                47.737,
                47.739,
                47.742,
                47.745,
                47.747,
                47.75,
                47.753,
                47.756,
                47.759,
                47.761,
                47.764,
                47.766,
                47.769,
                47.772,
                47.775,
                47.777,
                47.78,
                47.783,
                47.785,
                47.788,
                47.791,
                47.793,
                47.796,
                47.799,
                47.801,
                47.804,
                47.806,
                47.809,
                47.812,
                47.815,
                47.817,
                47.82,
                47.823,
                47.825,
                47.828,
                47.83,
                47.833,
                47.835,
                47.838,
                47.841,
                47.844,
                47.846,
                47.848,
                47.851,
                47.854,
                47.856,
                47.859,
                47.862,
                47.864,
                47.866,
                47.869,
                47.872,
                47.874,
                47.877,
                47.879,
                47.882,
                47.885,
                47.887,
                47.889,
                47.892,
                47.894,
                47.897,
                47.9,
                47.902,
                47.904,
                47.907,
                47.909,
                47.912,
                47.914,
                47.917,
                47.92,
                47.922,
                47.924,
                47.927,
                47.929,
                47.932,
                47.934,
                47.937,
                47.939,
                47.942,
                47.944,
                47.946,
                47.949,
                47.951,
                47.954,
                47.956,
                47.959,
                47.961,
                47.963,
                47.966,
                47.968,
                47.971,
                47.973,
                47.976,
                47.978,
                47.981,
                47.983,
                47.985,
                47.988,
                47.99,
                47.993,
                47.995,
                47.997,
                48,
                48.002,
                48.004,
                48.007,
                48.009,
                48.012,
                48.014,
                48.016,
                48.019,
                48.021,
                48.023,
                48.025,
                48.028,
                48.03,
                48.033,
                48.035,
                48.038,
                48.039,
                48.042,
                48.044,
                48.046,
                48.049,
                48.051,
                48.054,
                48.056,
                48.058,
                48.06,
                48.063,
                48.065,
                48.067,
                48.07,
                48.072,
                48.074,
                48.076,
                48.078,
                48.081,
                48.083,
                48.085,
                48.088,
                48.09,
                48.092,
                48.094,
                48.096,
                48.099,
                48.101,
                48.103,
                48.106,
                48.108,
                48.11,
                48.112,
                48.114,
                48.117,
                48.119,
                48.121,
                48.124,
                48.126,
                48.128,
                48.13,
                48.132,
                48.134,
                48.137,
                48.139,
                48.141,
                48.143,
                48.146,
                48.148,
                48.15,
                48.152,
                48.154,
                48.156,
                48.159,
                48.161,
                48.163,
                48.165,
                48.167,
                48.169,
                48.171,
                48.174,
                48.176,
                48.178,
                48.18,
                48.182,
                48.184,
                48.186,
                48.188,
                48.191,
                48.193,
                48.195,
                48.197,
                48.199,
                48.202,
                48.203,
                48.205,
                48.208,
                48.21,
                48.212,
                48.214,
                48.216,
                48.218,
                48.22,
                48.222,
                48.224,
                48.227,
                48.229,
                48.231,
                48.233,
                48.235,
                48.237,
                48.239,
                48.241,
                48.243,
                48.245,
                48.247,
                48.25,
                48.252,
                48.254,
                48.255,
                48.257,
                48.26,
                48.262,
                48.264,
                48.266,
                48.268,
                48.27,
                48.272,
                48.274,
                48.276,
                48.278,
                48.28,
                48.282,
                48.284,
                48.286,
                48.288,
                48.29,
                48.292,
                48.294,
                48.296,
                48.298,
                48.3,
                48.302,
                48.304,
                48.306,
                48.308,
                48.31,
                48.312,
                48.314,
                48.316,
                48.318,
                48.32,
                48.322,
                48.324,
                48.326,
                48.328,
                48.33,
                48.332,
                48.334,
                48.336,
                48.338,
                48.34,
                48.342,
                48.344,
                48.346,
                48.348,
                48.35,
                48.352,
                48.354,
                48.356,
                48.357,
                48.36,
                48.361,
                48.363,
                48.365,
                48.367,
                48.369,
                48.371,
                48.373,
                48.375,
                48.377,
                48.378,
                48.38,
                48.382,
                48.384,
                48.386,
                48.388,
                48.39,
                48.392,
                48.394,
                48.395,
                48.397,
                48.399,
                48.401,
                48.403,
                48.405,
                48.407,
                48.409,
                48.411,
                48.412,
                48.414,
                48.416,
                48.418,
                48.42,
                48.422,
                48.424,
                48.426,
                48.428,
                48.429,
                48.431,
                48.433,
                48.435,
                48.437,
                48.439,
                48.441,
                48.443,
                48.445,
                48.446,
                48.448,
                48.45,
                48.452,
                48.453,
                48.455,
                48.457,
                48.459,
                48.461,
                48.462,
                48.464,
                48.466,
                48.468,
                48.47,
                48.472,
                48.474,
                48.475,
                48.477,
                48.479,
                48.481,
                48.482,
                48.484,
                48.486,
                48.488,
                48.49,
                48.492,
                48.494,
                48.495,
                48.497,
                48.499,
                48.5,
                48.502,
                48.504,
                48.506,
                48.508,
                48.509,
                48.511,
                48.513,
                48.514,
                48.516,
                48.518,
                48.52,
                48.522,
                48.524,
                48.525,
                48.527,
                48.528,
                48.53,
                48.532,
                48.534,
                48.536,
                48.537,
                48.539,
                48.541,
                48.543,
                48.544,
                48.546,
                48.548,
                48.549,
                48.551,
                48.553,
                48.555,
                48.557,
                48.558,
                48.56,
                48.561,
                48.563,
                48.565,
                48.567,
                48.568,
                48.57,
                48.572,
                48.574,
                48.575,
                48.577,
                48.578,
                48.58,
                48.582,
                48.584,
                48.585,
                48.587,
                48.589,
                48.591,
                48.592,
                48.594,
                48.595,
                48.597,
                48.599,
                48.6,
                48.602,
                48.604,
                48.606,
                48.607,
                48.609,
                48.61,
                48.612,
                48.614,
                48.615,
                48.617,
                48.619,
                48.621,
                48.622,
                48.624,
                48.625,
                48.627,
                48.629,
                48.63,
                48.632,
                48.634,
                48.635,
                48.637,
                48.639,
                48.64,
                48.641,
                48.643,
                48.645,
                48.647,
                48.648,
                48.65,
                48.652,
                48.653,
                48.655,
                48.657,
                48.658,
                48.659,
                48.661,
                48.663,
                48.664,
                48.666,
                48.668,
                48.669,
                48.671,
                48.673,
                48.674,
                48.675,
                48.677,
                48.679,
                48.68,
                48.682,
                48.684,
                48.685,
                48.687,
                48.688,
                48.69,
                48.691,
                48.693,
                48.695,
                48.696,
                48.698,
                48.699,
                48.701,
                48.703,
                48.704,
                48.705,
                48.707,
                48.709,
                48.71,
                48.712,
                48.713,
                48.715,
                48.717,
                48.718,
                48.719,
                48.721,
                48.722,
                48.724,
                48.726,
                48.727,
                48.729,
                48.73,
                48.732,
                48.734,
                48.735,
                48.736,
                48.738,
                48.739,
                48.741,
                48.743,
                48.744,
                48.746,
                48.747,
                48.749,
                48.75,
                48.751,
                48.753,
                48.755,
                48.756,
                48.758,
                48.759,
                48.761,
                48.762,
                48.764,
                48.765,
                48.766,
                48.768,
                48.77,
                48.771,
                48.773,
                48.774,
                48.776,
                48.777,
                48.779,
                48.78,
                48.781,
                48.783,
                48.784,
                48.786,
                48.788,
                48.789,
                48.791,
                48.792,
                48.794,
                48.795,
                48.796,
                48.798,
                48.799,
                48.801,
                48.802,
                48.804,
                48.805,
                48.807,
                48.808,
                48.81,
                48.811,
                48.812,
                48.814,
                48.815,
                48.817,
                48.818,
                48.82,
                48.821,
                48.823,
                48.824,
                48.826,
                48.827,
                48.828,
                48.83,
                48.831,
                48.833,
                48.834,
                48.836,
                48.837,
                48.839,
                48.84,
                48.842,
                48.843,
                48.844,
                48.846,
                48.847,
                48.849,
                48.85,
                48.852,
                48.853,
                48.854,
                48.856,
                48.857,
                48.858,
                48.86,
                48.861,
                48.863,
                48.864,
                48.866,
                48.867,
                48.869,
                48.87,
                48.871,
                48.872,
                48.874,
                48.875,
                48.877,
                48.878,
                48.88,
                48.881,
                48.883,
                48.884,
                48.885,
                48.886,
                48.888,
                48.889,
                48.891,
                48.892,
                48.894,
                48.895,
                48.896,
                48.898,
                48.899,
                48.901,
                48.902,
                48.903,
                48.904,
                48.906,
                48.907,
                48.909,
                48.91,
                48.912,
                48.913,
                48.914,
                48.916,
                48.917,
                48.918,
                48.92,
                48.921,
                48.922,
                48.924,
                48.925,
                48.927,
                48.928,
                48.929,
                48.931,
                48.932,
                48.933,
                48.934,
                48.936,
                48.937,
                48.939,
                48.94,
                48.941,
                48.943,
                48.944,
                48.945,
                48.947,
                48.948,
                48.949,
                48.951,
                48.952,
                48.953,
                48.955,
                48.956,
                48.958,
                48.959,
                48.96,
                48.961,
                48.963,
                48.964,
                48.965,
                48.967,
                48.968,
                48.969,
                48.971,
                48.972,
                48.974,
                48.974,
                48.976,
                48.977,
                48.978,
                48.98,
                48.981,
                48.982,
                48.984,
                48.985,
                48.987,
                48.988,
                48.989,
                48.99,
                48.991,
                48.993,
                48.994,
                48.996,
                48.997,
                48.998,
                49,
                49.001,
                49.002,
                49.003,
                49.004,
                49.006,
                49.007,
                49.009,
                49.01,
                49.011,
                49.012,
                49.014,
                49.015,
                49.017,
                49.018,
                49.019,
                49.02,
                49.021,
                49.023,
                49.024,
                49.025,
                49.027,
                49.028,
                49.029,
                49.031,
                49.032,
                49.033,
                49.034,
                49.035,
                49.037,
                49.038,
                49.039,
                49.041,
                49.042,
                49.043,
                49.045,
                49.046,
                49.047,
                49.048,
                49.049,
                49.051,
                49.052,
                49.053,
                49.055,
                49.056,
                49.057,
                49.059,
                49.06,
                49.061,
                49.062,
                49.063,
                49.065,
                49.066,
                49.067,
                49.068,
                49.07,
                49.071,
                49.072,
                49.074,
                49.075,
                49.076,
                49.077,
                49.078,
                49.08,
                49.081,
                49.082,
                49.084,
                49.085,
                49.086,
                49.087,
                49.089,
                49.089,
                49.091,
                49.092,
                49.093,
                49.095,
                49.096,
                49.097,
                49.098,
                49.1,
                49.101,
                49.102,
                49.103,
                49.104,
                49.106,
                49.107,
                49.108,
                49.109,
                49.111,
                49.112,
                49.113,
                49.115,
                49.116,
                49.117,
                49.118,
                49.119,
                49.12,
                49.122,
                49.123,
                49.124,
                49.126,
                49.127,
                49.128,
                49.129,
                49.131,
                49.132,
                49.133,
                49.134,
                49.135,
                49.136,
                49.138,
                49.139,
                49.14,
                49.141,
                49.143,
                49.144,
                49.145,
                49.146,
                49.147,
                49.148,
                49.15,
                49.151,
                49.152,
                49.153,
                49.155,
                49.156,
                49.157,
                49.158,
                49.16,
                49.16,
                49.162,
                49.163,
                49.164,
                49.165,
                49.167,
                49.168,
                49.169,
                49.17,
                49.172,
                49.173,
                49.174,
                49.175,
                49.176,
                49.177,
                49.179,
                49.18,
                49.181,
                49.182,
                49.184,
                49.185,
                49.186,
                49.187,
                49.189,
                49.189,
                49.19,
                49.192,
                49.193,
                49.194,
                49.195,
                49.197,
                49.198,
                49.199,
                49.2,
                49.202,
                49.202,
                49.203,
                49.205,
                49.206,
                49.207,
                49.208,
                49.21,
                49.211,
                49.212,
                49.213,
                49.215,
                49.216,
                49.216,
                49.218,
                49.219,
                49.22,
                49.221,
                49.223,
                49.224
            ],
            P25: [
                33.605,
                33.71,
                33.815,
                33.92,
                34.025,
                34.13,
                34.235,
                34.34,
                34.445,
                34.55,
                34.656,
                34.761,
                34.866,
                34.972,
                35.077,
                35.177,
                35.275,
                35.371,
                35.465,
                35.556,
                35.646,
                35.734,
                35.82,
                35.905,
                35.988,
                36.069,
                36.149,
                36.228,
                36.305,
                36.381,
                36.456,
                36.53,
                36.602,
                36.673,
                36.744,
                36.813,
                36.882,
                36.949,
                37.015,
                37.081,
                37.145,
                37.209,
                37.272,
                37.335,
                37.396,
                37.457,
                37.517,
                37.577,
                37.635,
                37.693,
                37.75,
                37.807,
                37.863,
                37.919,
                37.974,
                38.028,
                38.082,
                38.136,
                38.188,
                38.241,
                38.293,
                38.344,
                38.395,
                38.446,
                38.496,
                38.545,
                38.594,
                38.643,
                38.692,
                38.74,
                38.787,
                38.834,
                38.881,
                38.927,
                38.973,
                39.019,
                39.064,
                39.109,
                39.154,
                39.198,
                39.242,
                39.286,
                39.329,
                39.371,
                39.414,
                39.456,
                39.498,
                39.54,
                39.581,
                39.622,
                39.663,
                39.704,
                39.744,
                39.784,
                39.824,
                39.863,
                39.902,
                39.941,
                39.979,
                40.018,
                40.056,
                40.094,
                40.131,
                40.169,
                40.206,
                40.243,
                40.279,
                40.316,
                40.352,
                40.388,
                40.423,
                40.459,
                40.494,
                40.529,
                40.564,
                40.599,
                40.633,
                40.667,
                40.701,
                40.735,
                40.768,
                40.802,
                40.835,
                40.867,
                40.9,
                40.933,
                40.965,
                40.997,
                41.029,
                41.061,
                41.092,
                41.123,
                41.154,
                41.185,
                41.216,
                41.246,
                41.277,
                41.307,
                41.337,
                41.367,
                41.396,
                41.426,
                41.455,
                41.484,
                41.513,
                41.542,
                41.57,
                41.599,
                41.627,
                41.655,
                41.683,
                41.711,
                41.738,
                41.766,
                41.793,
                41.82,
                41.847,
                41.873,
                41.9,
                41.926,
                41.953,
                41.979,
                42.005,
                42.03,
                42.056,
                42.082,
                42.107,
                42.132,
                42.157,
                42.182,
                42.207,
                42.232,
                42.256,
                42.28,
                42.304,
                42.328,
                42.353,
                42.376,
                42.4,
                42.423,
                42.447,
                42.47,
                42.493,
                42.516,
                42.539,
                42.561,
                42.584,
                42.606,
                42.629,
                42.651,
                42.673,
                42.695,
                42.717,
                42.738,
                42.76,
                42.781,
                42.803,
                42.824,
                42.845,
                42.866,
                42.887,
                42.908,
                42.928,
                42.949,
                42.969,
                42.989,
                43.009,
                43.03,
                43.049,
                43.069,
                43.089,
                43.108,
                43.128,
                43.147,
                43.166,
                43.186,
                43.205,
                43.224,
                43.242,
                43.261,
                43.28,
                43.298,
                43.317,
                43.335,
                43.353,
                43.372,
                43.39,
                43.408,
                43.425,
                43.443,
                43.461,
                43.478,
                43.496,
                43.513,
                43.531,
                43.548,
                43.565,
                43.582,
                43.599,
                43.616,
                43.632,
                43.649,
                43.665,
                43.682,
                43.698,
                43.715,
                43.731,
                43.747,
                43.763,
                43.779,
                43.795,
                43.811,
                43.826,
                43.842,
                43.858,
                43.873,
                43.889,
                43.904,
                43.919,
                43.934,
                43.949,
                43.964,
                43.98,
                43.994,
                44.009,
                44.024,
                44.038,
                44.053,
                44.067,
                44.082,
                44.097,
                44.111,
                44.125,
                44.139,
                44.153,
                44.167,
                44.181,
                44.195,
                44.209,
                44.223,
                44.237,
                44.25,
                44.264,
                44.277,
                44.291,
                44.304,
                44.317,
                44.33,
                44.344,
                44.357,
                44.37,
                44.383,
                44.396,
                44.409,
                44.421,
                44.434,
                44.447,
                44.459,
                44.472,
                44.485,
                44.497,
                44.509,
                44.522,
                44.534,
                44.546,
                44.558,
                44.57,
                44.583,
                44.595,
                44.607,
                44.618,
                44.63,
                44.642,
                44.654,
                44.665,
                44.677,
                44.689,
                44.7,
                44.711,
                44.723,
                44.734,
                44.746,
                44.757,
                44.768,
                44.779,
                44.79,
                44.801,
                44.812,
                44.823,
                44.834,
                44.845,
                44.856,
                44.867,
                44.877,
                44.888,
                44.898,
                44.909,
                44.92,
                44.93,
                44.941,
                44.951,
                44.962,
                44.972,
                44.982,
                44.992,
                45.003,
                45.013,
                45.023,
                45.033,
                45.043,
                45.053,
                45.063,
                45.073,
                45.082,
                45.092,
                45.102,
                45.112,
                45.121,
                45.131,
                45.141,
                45.15,
                45.16,
                45.169,
                45.178,
                45.188,
                45.197,
                45.206,
                45.216,
                45.225,
                45.234,
                45.243,
                45.253,
                45.262,
                45.271,
                45.28,
                45.289,
                45.298,
                45.307,
                45.316,
                45.325,
                45.333,
                45.342,
                45.351,
                45.36,
                45.368,
                45.377,
                45.386,
                45.394,
                45.403,
                45.411,
                45.42,
                45.428,
                45.437,
                45.445,
                45.454,
                45.462,
                45.47,
                45.479,
                45.487,
                45.495,
                45.503,
                45.512,
                45.519,
                45.528,
                45.536,
                45.544,
                45.552,
                45.56,
                45.568,
                45.576,
                45.584,
                45.591,
                45.599,
                45.607,
                45.615,
                45.623,
                45.631,
                45.638,
                45.646,
                45.654,
                45.661,
                45.669,
                45.677,
                45.684,
                45.692,
                45.699,
                45.706,
                45.714,
                45.721,
                45.729,
                45.736,
                45.744,
                45.751,
                45.758,
                45.766,
                45.773,
                45.78,
                45.788,
                45.795,
                45.802,
                45.809,
                45.816,
                45.823,
                45.83,
                45.837,
                45.844,
                45.851,
                45.858,
                45.865,
                45.872,
                45.879,
                45.886,
                45.893,
                45.9,
                45.907,
                45.914,
                45.921,
                45.928,
                45.934,
                45.941,
                45.948,
                45.954,
                45.961,
                45.968,
                45.974,
                45.981,
                45.988,
                45.994,
                46.001,
                46.008,
                46.014,
                46.021,
                46.027,
                46.033,
                46.04,
                46.046,
                46.053,
                46.059,
                46.066,
                46.072,
                46.079,
                46.085,
                46.091,
                46.098,
                46.104,
                46.11,
                46.116,
                46.123,
                46.129,
                46.135,
                46.141,
                46.148,
                46.154,
                46.16,
                46.166,
                46.172,
                46.178,
                46.185,
                46.191,
                46.196,
                46.202,
                46.209,
                46.215,
                46.221,
                46.227,
                46.233,
                46.239,
                46.245,
                46.251,
                46.256,
                46.262,
                46.268,
                46.274,
                46.28,
                46.286,
                46.292,
                46.297,
                46.303,
                46.309,
                46.315,
                46.321,
                46.327,
                46.332,
                46.338,
                46.343,
                46.349,
                46.355,
                46.361,
                46.366,
                46.372,
                46.378,
                46.383,
                46.389,
                46.395,
                46.4,
                46.406,
                46.411,
                46.417,
                46.422,
                46.428,
                46.433,
                46.439,
                46.445,
                46.45,
                46.455,
                46.461,
                46.466,
                46.472,
                46.477,
                46.483,
                46.488,
                46.493,
                46.499,
                46.504,
                46.51,
                46.515,
                46.52,
                46.526,
                46.531,
                46.536,
                46.542,
                46.547,
                46.552,
                46.558,
                46.563,
                46.568,
                46.573,
                46.578,
                46.584,
                46.589,
                46.594,
                46.599,
                46.605,
                46.61,
                46.615,
                46.62,
                46.625,
                46.63,
                46.635,
                46.641,
                46.646,
                46.651,
                46.656,
                46.661,
                46.666,
                46.671,
                46.676,
                46.681,
                46.686,
                46.691,
                46.696,
                46.702,
                46.707,
                46.711,
                46.716,
                46.721,
                46.726,
                46.731,
                46.736,
                46.741,
                46.746,
                46.751,
                46.756,
                46.761,
                46.766,
                46.771,
                46.776,
                46.781,
                46.786,
                46.79,
                46.795,
                46.8,
                46.805,
                46.81,
                46.815,
                46.819,
                46.824,
                46.829,
                46.834,
                46.839,
                46.843,
                46.848,
                46.853,
                46.858,
                46.863,
                46.867,
                46.872,
                46.877,
                46.882,
                46.886,
                46.891,
                46.896,
                46.9,
                46.905,
                46.91,
                46.915,
                46.919,
                46.924,
                46.928,
                46.933,
                46.938,
                46.943,
                46.947,
                46.952,
                46.957,
                46.961,
                46.966,
                46.97,
                46.975,
                46.979,
                46.984,
                46.989,
                46.993,
                46.998,
                47.002,
                47.007,
                47.011,
                47.016,
                47.021,
                47.025,
                47.03,
                47.034,
                47.038,
                47.043,
                47.047,
                47.052,
                47.057,
                47.061,
                47.066,
                47.07,
                47.074,
                47.079,
                47.083,
                47.088,
                47.092,
                47.097,
                47.101,
                47.106,
                47.11,
                47.114,
                47.119,
                47.123,
                47.127,
                47.132,
                47.136,
                47.141,
                47.145,
                47.149,
                47.154,
                47.158,
                47.162,
                47.167,
                47.171,
                47.176,
                47.18,
                47.184,
                47.188,
                47.193,
                47.197,
                47.201,
                47.206,
                47.21,
                47.214,
                47.218,
                47.222,
                47.227,
                47.231,
                47.235,
                47.24,
                47.244,
                47.248,
                47.252,
                47.256,
                47.261,
                47.265,
                47.269,
                47.273,
                47.277,
                47.281,
                47.286,
                47.29,
                47.294,
                47.298,
                47.302,
                47.307,
                47.311,
                47.315,
                47.319,
                47.323,
                47.327,
                47.331,
                47.335,
                47.339,
                47.343,
                47.348,
                47.352,
                47.356,
                47.36,
                47.364,
                47.368,
                47.372,
                47.376,
                47.38,
                47.384,
                47.388,
                47.392,
                47.396,
                47.4,
                47.404,
                47.408,
                47.412,
                47.416,
                47.42,
                47.424,
                47.428,
                47.432,
                47.436,
                47.44,
                47.444,
                47.448,
                47.452,
                47.456,
                47.46,
                47.464,
                47.468,
                47.472,
                47.476,
                47.48,
                47.484,
                47.487,
                47.491,
                47.495,
                47.499,
                47.503,
                47.507,
                47.511,
                47.514,
                47.518,
                47.522,
                47.526,
                47.53,
                47.534,
                47.538,
                47.541,
                47.545,
                47.549,
                47.553,
                47.557,
                47.561,
                47.564,
                47.568,
                47.572,
                47.576,
                47.579,
                47.583,
                47.587,
                47.591,
                47.595,
                47.598,
                47.602,
                47.606,
                47.609,
                47.613,
                47.617,
                47.621,
                47.624,
                47.628,
                47.632,
                47.635,
                47.639,
                47.643,
                47.647,
                47.65,
                47.654,
                47.657,
                47.661,
                47.665,
                47.668,
                47.672,
                47.675,
                47.679,
                47.683,
                47.687,
                47.69,
                47.694,
                47.697,
                47.701,
                47.704,
                47.708,
                47.712,
                47.715,
                47.719,
                47.723,
                47.726,
                47.729,
                47.733,
                47.737,
                47.74,
                47.744,
                47.747,
                47.751,
                47.754,
                47.758,
                47.761,
                47.765,
                47.768,
                47.772,
                47.776,
                47.779,
                47.782,
                47.786,
                47.789,
                47.793,
                47.796,
                47.8,
                47.803,
                47.806,
                47.81,
                47.813,
                47.817,
                47.82,
                47.824,
                47.827,
                47.83,
                47.834,
                47.837,
                47.841,
                47.844,
                47.848,
                47.851,
                47.854,
                47.858,
                47.861,
                47.864,
                47.868,
                47.871,
                47.874,
                47.878,
                47.881,
                47.885,
                47.888,
                47.891,
                47.895,
                47.898,
                47.901,
                47.904,
                47.908,
                47.911,
                47.914,
                47.918,
                47.921,
                47.924,
                47.927,
                47.931,
                47.934,
                47.937,
                47.941,
                47.944,
                47.947,
                47.95,
                47.954,
                47.957,
                47.96,
                47.963,
                47.967,
                47.969,
                47.973,
                47.976,
                47.979,
                47.982,
                47.986,
                47.989,
                47.992,
                47.995,
                47.998,
                48.001,
                48.005,
                48.008,
                48.011,
                48.014,
                48.017,
                48.02,
                48.023,
                48.027,
                48.03,
                48.033,
                48.036,
                48.039,
                48.042,
                48.045,
                48.048,
                48.052,
                48.055,
                48.058,
                48.061,
                48.064,
                48.067,
                48.07,
                48.073,
                48.076,
                48.079,
                48.082,
                48.085,
                48.088,
                48.091,
                48.094,
                48.097,
                48.1,
                48.103,
                48.106,
                48.109,
                48.112,
                48.115,
                48.118,
                48.121,
                48.124,
                48.127,
                48.13,
                48.133,
                48.136,
                48.139,
                48.142,
                48.145,
                48.148,
                48.151,
                48.154,
                48.157,
                48.16,
                48.163,
                48.166,
                48.169,
                48.171,
                48.174,
                48.177,
                48.18,
                48.183,
                48.186,
                48.189,
                48.191,
                48.194,
                48.197,
                48.2,
                48.203,
                48.206,
                48.209,
                48.212,
                48.214,
                48.217,
                48.22,
                48.223,
                48.226,
                48.229,
                48.232,
                48.234,
                48.237,
                48.24,
                48.243,
                48.245,
                48.248,
                48.251,
                48.254,
                48.257,
                48.259,
                48.262,
                48.265,
                48.268,
                48.271,
                48.273,
                48.276,
                48.279,
                48.282,
                48.284,
                48.287,
                48.29,
                48.293,
                48.295,
                48.298,
                48.301,
                48.303,
                48.306,
                48.309,
                48.312,
                48.314,
                48.317,
                48.319,
                48.322,
                48.325,
                48.328,
                48.33,
                48.333,
                48.336,
                48.338,
                48.341,
                48.344,
                48.346,
                48.349,
                48.352,
                48.354,
                48.357,
                48.359,
                48.362,
                48.365,
                48.367,
                48.37,
                48.373,
                48.376,
                48.378,
                48.38,
                48.383,
                48.386,
                48.388,
                48.391,
                48.394,
                48.396,
                48.399,
                48.401,
                48.404,
                48.406,
                48.409,
                48.412,
                48.414,
                48.416,
                48.419,
                48.422,
                48.424,
                48.427,
                48.429,
                48.432,
                48.435,
                48.437,
                48.439,
                48.442,
                48.445,
                48.447,
                48.45,
                48.452,
                48.455,
                48.457,
                48.46,
                48.462,
                48.465,
                48.467,
                48.47,
                48.472,
                48.475,
                48.477,
                48.479,
                48.482,
                48.484,
                48.487,
                48.489,
                48.492,
                48.494,
                48.497,
                48.499,
                48.502,
                48.504,
                48.507,
                48.509,
                48.511,
                48.514,
                48.516,
                48.519,
                48.521,
                48.523,
                48.526,
                48.528,
                48.531,
                48.533,
                48.535,
                48.538,
                48.54,
                48.543,
                48.545,
                48.547,
                48.55,
                48.552,
                48.554,
                48.557,
                48.559,
                48.562,
                48.564,
                48.566,
                48.569,
                48.571,
                48.573,
                48.576,
                48.578,
                48.58,
                48.583,
                48.585,
                48.587,
                48.59,
                48.592,
                48.594,
                48.597,
                48.599,
                48.601,
                48.604,
                48.606,
                48.608,
                48.61,
                48.613,
                48.615,
                48.617,
                48.62,
                48.622,
                48.624,
                48.627,
                48.629,
                48.631,
                48.633,
                48.636,
                48.638,
                48.64,
                48.642,
                48.645,
                48.647,
                48.649,
                48.651,
                48.654,
                48.656,
                48.658,
                48.66,
                48.663,
                48.665,
                48.667,
                48.669,
                48.672,
                48.674,
                48.676,
                48.678,
                48.681,
                48.683,
                48.685,
                48.687,
                48.689,
                48.691,
                48.694,
                48.696,
                48.698,
                48.7,
                48.702,
                48.704,
                48.707,
                48.709,
                48.711,
                48.713,
                48.715,
                48.718,
                48.72,
                48.722,
                48.724,
                48.726,
                48.728,
                48.731,
                48.733,
                48.735,
                48.737,
                48.739,
                48.741,
                48.743,
                48.745,
                48.748,
                48.75,
                48.752,
                48.754,
                48.756,
                48.758,
                48.76,
                48.762,
                48.765,
                48.767,
                48.769,
                48.771,
                48.773,
                48.775,
                48.777,
                48.779,
                48.781,
                48.784,
                48.786,
                48.788,
                48.79,
                48.792,
                48.794,
                48.796,
                48.798,
                48.8,
                48.802,
                48.804,
                48.806,
                48.809,
                48.811,
                48.812,
                48.814,
                48.816,
                48.819,
                48.821,
                48.823,
                48.825,
                48.827,
                48.829,
                48.831,
                48.833,
                48.835,
                48.837,
                48.839,
                48.841,
                48.843,
                48.845,
                48.847,
                48.849,
                48.851,
                48.853,
                48.855,
                48.857,
                48.859,
                48.861,
                48.863,
                48.865,
                48.867,
                48.869,
                48.871,
                48.873,
                48.875,
                48.877,
                48.879,
                48.881,
                48.883,
                48.885,
                48.887,
                48.889,
                48.891,
                48.893,
                48.895,
                48.897,
                48.899,
                48.901,
                48.903,
                48.905,
                48.906,
                48.909,
                48.911,
                48.912,
                48.914,
                48.916,
                48.918,
                48.92,
                48.922,
                48.924,
                48.926,
                48.928,
                48.93,
                48.932,
                48.934,
                48.935,
                48.937,
                48.939,
                48.941,
                48.943,
                48.945,
                48.947,
                48.949,
                48.951,
                48.953,
                48.955,
                48.956,
                48.958,
                48.96,
                48.962,
                48.964,
                48.966,
                48.968,
                48.97,
                48.972,
                48.973,
                48.975,
                48.977,
                48.979,
                48.981,
                48.983,
                48.985,
                48.986,
                48.988,
                48.99,
                48.992,
                48.994,
                48.996,
                48.998,
                49,
                49.002,
                49.003,
                49.005,
                49.007,
                49.009,
                49.011,
                49.013,
                49.014,
                49.016,
                49.018,
                49.02,
                49.021,
                49.023,
                49.025,
                49.027,
                49.029,
                49.031,
                49.033,
                49.034,
                49.036,
                49.038,
                49.04,
                49.041,
                49.043,
                49.045,
                49.047,
                49.049,
                49.051,
                49.052,
                49.054,
                49.056,
                49.058,
                49.059,
                49.061,
                49.063,
                49.065,
                49.067,
                49.068,
                49.07,
                49.072,
                49.074,
                49.075,
                49.077,
                49.079,
                49.081,
                49.083,
                49.084,
                49.086,
                49.088,
                49.089,
                49.091,
                49.093,
                49.095,
                49.097,
                49.098,
                49.1,
                49.102,
                49.103,
                49.105,
                49.107,
                49.109,
                49.11,
                49.112,
                49.114,
                49.116,
                49.117,
                49.119,
                49.121,
                49.122,
                49.124,
                49.126,
                49.128,
                49.129,
                49.131,
                49.133,
                49.134,
                49.136,
                49.138,
                49.139,
                49.141,
                49.143,
                49.145,
                49.146,
                49.148,
                49.15,
                49.151,
                49.153,
                49.155,
                49.156,
                49.158,
                49.16,
                49.161,
                49.163,
                49.165,
                49.167,
                49.168,
                49.17,
                49.171,
                49.173,
                49.175,
                49.176,
                49.178,
                49.18,
                49.181,
                49.183,
                49.185,
                49.186,
                49.188,
                49.189,
                49.191,
                49.193,
                49.194,
                49.196,
                49.198,
                49.199,
                49.201,
                49.202,
                49.204,
                49.206,
                49.207,
                49.209,
                49.211,
                49.212,
                49.214,
                49.216,
                49.217,
                49.219,
                49.22,
                49.222,
                49.224,
                49.225,
                49.227,
                49.229,
                49.23,
                49.232,
                49.233,
                49.235,
                49.236,
                49.238,
                49.24,
                49.241,
                49.243,
                49.245,
                49.246,
                49.248,
                49.249,
                49.251,
                49.252,
                49.254,
                49.256,
                49.257,
                49.259,
                49.26,
                49.262,
                49.264,
                49.265,
                49.266,
                49.268,
                49.27,
                49.271,
                49.273,
                49.274,
                49.276,
                49.278,
                49.279,
                49.281,
                49.282,
                49.284,
                49.285,
                49.287,
                49.288,
                49.29,
                49.291,
                49.293,
                49.295,
                49.296,
                49.297,
                49.299,
                49.301,
                49.302,
                49.304,
                49.305,
                49.307,
                49.308,
                49.31,
                49.312,
                49.313,
                49.314,
                49.316,
                49.317,
                49.319,
                49.321,
                49.322,
                49.324,
                49.325,
                49.327,
                49.328,
                49.329,
                49.331,
                49.332,
                49.334,
                49.336,
                49.337,
                49.339,
                49.34,
                49.342,
                49.343,
                49.344,
                49.346,
                49.347,
                49.349,
                49.35,
                49.352,
                49.353,
                49.355,
                49.356,
                49.358,
                49.359,
                49.361,
                49.362,
                49.364,
                49.365,
                49.367,
                49.368,
                49.37,
                49.371,
                49.373,
                49.374,
                49.375,
                49.377,
                49.378,
                49.38,
                49.381,
                49.383,
                49.384,
                49.386,
                49.387,
                49.389,
                49.39,
                49.391,
                49.393,
                49.394,
                49.396,
                49.397,
                49.399,
                49.4,
                49.402,
                49.403,
                49.405,
                49.406,
                49.407,
                49.409,
                49.41,
                49.412,
                49.413,
                49.415,
                49.416,
                49.417,
                49.419,
                49.42,
                49.422,
                49.423,
                49.424,
                49.426,
                49.427,
                49.429,
                49.43,
                49.432,
                49.433,
                49.435,
                49.436,
                49.437,
                49.439,
                49.44,
                49.441,
                49.443,
                49.444,
                49.446,
                49.447,
                49.449,
                49.45,
                49.451,
                49.453,
                49.454,
                49.455,
                49.457,
                49.458,
                49.46,
                49.461,
                49.463,
                49.464,
                49.465,
                49.466,
                49.468,
                49.469,
                49.471,
                49.472,
                49.473,
                49.475,
                49.476,
                49.478,
                49.479,
                49.48,
                49.482,
                49.483,
                49.484,
                49.486,
                49.487,
                49.489,
                49.49,
                49.491,
                49.493,
                49.494,
                49.495,
                49.497,
                49.498,
                49.499,
                49.501,
                49.502,
                49.504,
                49.505,
                49.506,
                49.508,
                49.509,
                49.51,
                49.512,
                49.513,
                49.514,
                49.516,
                49.517,
                49.518,
                49.52,
                49.521,
                49.523,
                49.524,
                49.525,
                49.526,
                49.528,
                49.529,
                49.53,
                49.532,
                49.533,
                49.535,
                49.536,
                49.537,
                49.539,
                49.54,
                49.541,
                49.542,
                49.544,
                49.545,
                49.546,
                49.548,
                49.549,
                49.55,
                49.552,
                49.553,
                49.555,
                49.556,
                49.557,
                49.558,
                49.56,
                49.561,
                49.562,
                49.564,
                49.565,
                49.566,
                49.568,
                49.569,
                49.57,
                49.571,
                49.573,
                49.574,
                49.575,
                49.577,
                49.578,
                49.579,
                49.581,
                49.582,
                49.583,
                49.584,
                49.586,
                49.587,
                49.588,
                49.59,
                49.591,
                49.592,
                49.594,
                49.595,
                49.596,
                49.598,
                49.599,
                49.6,
                49.601,
                49.603,
                49.604,
                49.605,
                49.606,
                49.608,
                49.609,
                49.61,
                49.612,
                49.613,
                49.614,
                49.615,
                49.617,
                49.618,
                49.619,
                49.621,
                49.622,
                49.623,
                49.624,
                49.626,
                49.627,
                49.628,
                49.629,
                49.631,
                49.632,
                49.633,
                49.635,
                49.636,
                49.637,
                49.638,
                49.64,
                49.641,
                49.642,
                49.643,
                49.645,
                49.646,
                49.647,
                49.648,
                49.65,
                49.651,
                49.652,
                49.654,
                49.655,
                49.656,
                49.657,
                49.658,
                49.66,
                49.661,
                49.662,
                49.664,
                49.665,
                49.666,
                49.667,
                49.669,
                49.67,
                49.671,
                49.672,
                49.673,
                49.675,
                49.676,
                49.677,
                49.679,
                49.68,
                49.681,
                49.682,
                49.684,
                49.685,
                49.686,
                49.687,
                49.688,
                49.69,
                49.691,
                49.692,
                49.693,
                49.695,
                49.696,
                49.697,
                49.699,
                49.7,
                49.701,
                49.702,
                49.703,
                49.705,
                49.706,
                49.707,
                49.708,
                49.71,
                49.711,
                49.712,
                49.713,
                49.715,
                49.716,
                49.717,
                49.718,
                49.719,
                49.721,
                49.722,
                49.723,
                49.724,
                49.726,
                49.727,
                49.728,
                49.729,
                49.73,
                49.731,
                49.733,
                49.734,
                49.735,
                49.736,
                49.738,
                49.739,
                49.74,
                49.741,
                49.743,
                49.744,
                49.745,
                49.746,
                49.747,
                49.749,
                49.75,
                49.751,
                49.752,
                49.754,
                49.755,
                49.756,
                49.757,
                49.758,
                49.759,
                49.761,
                49.762,
                49.763,
                49.764,
                49.766
            ],
            P50: [
                34.462,
                34.562,
                34.662,
                34.763,
                34.863,
                34.963,
                35.063,
                35.163,
                35.264,
                35.364,
                35.464,
                35.564,
                35.665,
                35.765,
                35.865,
                35.965,
                36.063,
                36.159,
                36.253,
                36.344,
                36.434,
                36.522,
                36.608,
                36.692,
                36.775,
                36.857,
                36.937,
                37.015,
                37.093,
                37.169,
                37.244,
                37.317,
                37.39,
                37.461,
                37.532,
                37.601,
                37.669,
                37.737,
                37.803,
                37.869,
                37.934,
                37.998,
                38.061,
                38.123,
                38.185,
                38.246,
                38.306,
                38.366,
                38.424,
                38.482,
                38.54,
                38.597,
                38.653,
                38.709,
                38.764,
                38.818,
                38.872,
                38.926,
                38.979,
                39.031,
                39.083,
                39.135,
                39.186,
                39.237,
                39.287,
                39.337,
                39.386,
                39.435,
                39.484,
                39.532,
                39.58,
                39.627,
                39.674,
                39.721,
                39.767,
                39.813,
                39.858,
                39.903,
                39.948,
                39.992,
                40.037,
                40.08,
                40.124,
                40.167,
                40.21,
                40.252,
                40.294,
                40.336,
                40.378,
                40.419,
                40.46,
                40.501,
                40.541,
                40.582,
                40.621,
                40.661,
                40.701,
                40.74,
                40.779,
                40.817,
                40.856,
                40.894,
                40.932,
                40.969,
                41.007,
                41.044,
                41.081,
                41.117,
                41.154,
                41.19,
                41.226,
                41.262,
                41.297,
                41.332,
                41.367,
                41.402,
                41.437,
                41.471,
                41.505,
                41.539,
                41.573,
                41.607,
                41.64,
                41.673,
                41.706,
                41.739,
                41.772,
                41.804,
                41.836,
                41.868,
                41.9,
                41.931,
                41.963,
                41.994,
                42.025,
                42.056,
                42.086,
                42.117,
                42.147,
                42.177,
                42.207,
                42.237,
                42.266,
                42.296,
                42.325,
                42.354,
                42.383,
                42.412,
                42.44,
                42.468,
                42.497,
                42.525,
                42.552,
                42.58,
                42.608,
                42.635,
                42.662,
                42.689,
                42.716,
                42.743,
                42.769,
                42.796,
                42.822,
                42.848,
                42.874,
                42.9,
                42.925,
                42.951,
                42.976,
                43.001,
                43.026,
                43.051,
                43.076,
                43.101,
                43.125,
                43.15,
                43.174,
                43.198,
                43.222,
                43.246,
                43.269,
                43.293,
                43.316,
                43.339,
                43.362,
                43.385,
                43.408,
                43.431,
                43.454,
                43.476,
                43.498,
                43.521,
                43.543,
                43.565,
                43.587,
                43.608,
                43.63,
                43.651,
                43.673,
                43.694,
                43.715,
                43.736,
                43.757,
                43.778,
                43.798,
                43.819,
                43.839,
                43.86,
                43.88,
                43.9,
                43.92,
                43.94,
                43.959,
                43.979,
                43.999,
                44.018,
                44.037,
                44.057,
                44.076,
                44.095,
                44.114,
                44.133,
                44.151,
                44.17,
                44.188,
                44.207,
                44.225,
                44.243,
                44.261,
                44.279,
                44.297,
                44.315,
                44.333,
                44.35,
                44.368,
                44.385,
                44.403,
                44.42,
                44.437,
                44.454,
                44.471,
                44.488,
                44.505,
                44.522,
                44.538,
                44.555,
                44.571,
                44.588,
                44.604,
                44.62,
                44.637,
                44.653,
                44.669,
                44.684,
                44.7,
                44.716,
                44.732,
                44.747,
                44.763,
                44.778,
                44.794,
                44.809,
                44.824,
                44.839,
                44.854,
                44.869,
                44.884,
                44.899,
                44.914,
                44.928,
                44.943,
                44.958,
                44.972,
                44.986,
                45.001,
                45.015,
                45.029,
                45.043,
                45.057,
                45.071,
                45.085,
                45.099,
                45.113,
                45.127,
                45.14,
                45.154,
                45.167,
                45.181,
                45.194,
                45.208,
                45.221,
                45.234,
                45.247,
                45.26,
                45.273,
                45.286,
                45.299,
                45.312,
                45.325,
                45.338,
                45.35,
                45.363,
                45.376,
                45.388,
                45.4,
                45.413,
                45.425,
                45.437,
                45.45,
                45.462,
                45.474,
                45.486,
                45.498,
                45.51,
                45.522,
                45.534,
                45.545,
                45.557,
                45.569,
                45.58,
                45.592,
                45.603,
                45.615,
                45.626,
                45.638,
                45.649,
                45.66,
                45.671,
                45.683,
                45.694,
                45.705,
                45.716,
                45.727,
                45.738,
                45.749,
                45.759,
                45.77,
                45.781,
                45.792,
                45.802,
                45.813,
                45.823,
                45.834,
                45.844,
                45.855,
                45.865,
                45.875,
                45.886,
                45.896,
                45.906,
                45.916,
                45.926,
                45.936,
                45.947,
                45.957,
                45.966,
                45.976,
                45.986,
                45.996,
                46.006,
                46.016,
                46.025,
                46.035,
                46.045,
                46.054,
                46.064,
                46.073,
                46.083,
                46.092,
                46.102,
                46.111,
                46.12,
                46.13,
                46.139,
                46.148,
                46.157,
                46.166,
                46.175,
                46.185,
                46.194,
                46.203,
                46.212,
                46.22,
                46.229,
                46.238,
                46.247,
                46.256,
                46.265,
                46.273,
                46.282,
                46.291,
                46.299,
                46.308,
                46.317,
                46.325,
                46.334,
                46.342,
                46.351,
                46.359,
                46.367,
                46.376,
                46.384,
                46.392,
                46.401,
                46.409,
                46.417,
                46.425,
                46.434,
                46.442,
                46.45,
                46.458,
                46.466,
                46.474,
                46.482,
                46.49,
                46.498,
                46.506,
                46.514,
                46.522,
                46.529,
                46.537,
                46.545,
                46.553,
                46.56,
                46.568,
                46.576,
                46.583,
                46.591,
                46.599,
                46.606,
                46.614,
                46.621,
                46.629,
                46.636,
                46.644,
                46.651,
                46.659,
                46.666,
                46.673,
                46.681,
                46.688,
                46.695,
                46.703,
                46.71,
                46.717,
                46.724,
                46.731,
                46.739,
                46.746,
                46.753,
                46.76,
                46.767,
                46.774,
                46.781,
                46.788,
                46.795,
                46.802,
                46.809,
                46.816,
                46.823,
                46.83,
                46.837,
                46.844,
                46.85,
                46.857,
                46.864,
                46.871,
                46.878,
                46.884,
                46.891,
                46.898,
                46.904,
                46.911,
                46.918,
                46.924,
                46.931,
                46.937,
                46.944,
                46.951,
                46.957,
                46.964,
                46.97,
                46.977,
                46.983,
                46.99,
                46.996,
                47.002,
                47.009,
                47.015,
                47.022,
                47.028,
                47.034,
                47.041,
                47.047,
                47.053,
                47.059,
                47.066,
                47.072,
                47.078,
                47.084,
                47.091,
                47.097,
                47.103,
                47.109,
                47.115,
                47.121,
                47.127,
                47.134,
                47.14,
                47.146,
                47.152,
                47.158,
                47.164,
                47.17,
                47.176,
                47.182,
                47.188,
                47.194,
                47.2,
                47.206,
                47.211,
                47.217,
                47.223,
                47.229,
                47.235,
                47.241,
                47.247,
                47.252,
                47.258,
                47.264,
                47.27,
                47.276,
                47.281,
                47.287,
                47.293,
                47.299,
                47.304,
                47.31,
                47.316,
                47.321,
                47.327,
                47.333,
                47.338,
                47.344,
                47.349,
                47.355,
                47.361,
                47.366,
                47.372,
                47.377,
                47.383,
                47.388,
                47.394,
                47.4,
                47.405,
                47.411,
                47.416,
                47.422,
                47.427,
                47.432,
                47.438,
                47.443,
                47.449,
                47.454,
                47.46,
                47.465,
                47.47,
                47.476,
                47.481,
                47.486,
                47.492,
                47.497,
                47.502,
                47.508,
                47.513,
                47.518,
                47.524,
                47.529,
                47.534,
                47.539,
                47.545,
                47.55,
                47.555,
                47.56,
                47.566,
                47.571,
                47.576,
                47.581,
                47.586,
                47.592,
                47.597,
                47.602,
                47.607,
                47.612,
                47.617,
                47.622,
                47.627,
                47.633,
                47.638,
                47.643,
                47.648,
                47.653,
                47.658,
                47.663,
                47.668,
                47.673,
                47.678,
                47.683,
                47.688,
                47.693,
                47.698,
                47.703,
                47.708,
                47.713,
                47.718,
                47.723,
                47.728,
                47.733,
                47.738,
                47.743,
                47.748,
                47.753,
                47.758,
                47.762,
                47.767,
                47.772,
                47.777,
                47.782,
                47.787,
                47.792,
                47.797,
                47.801,
                47.806,
                47.811,
                47.816,
                47.821,
                47.826,
                47.83,
                47.835,
                47.84,
                47.845,
                47.849,
                47.854,
                47.859,
                47.864,
                47.869,
                47.873,
                47.878,
                47.883,
                47.887,
                47.892,
                47.897,
                47.902,
                47.906,
                47.911,
                47.916,
                47.92,
                47.925,
                47.93,
                47.934,
                47.939,
                47.944,
                47.948,
                47.953,
                47.957,
                47.962,
                47.967,
                47.971,
                47.976,
                47.98,
                47.985,
                47.99,
                47.994,
                47.999,
                48.003,
                48.008,
                48.012,
                48.017,
                48.022,
                48.026,
                48.031,
                48.035,
                48.04,
                48.044,
                48.049,
                48.053,
                48.058,
                48.062,
                48.067,
                48.071,
                48.076,
                48.08,
                48.084,
                48.089,
                48.093,
                48.098,
                48.102,
                48.107,
                48.111,
                48.115,
                48.12,
                48.124,
                48.129,
                48.133,
                48.137,
                48.142,
                48.146,
                48.151,
                48.155,
                48.159,
                48.164,
                48.168,
                48.172,
                48.177,
                48.181,
                48.185,
                48.19,
                48.194,
                48.198,
                48.203,
                48.207,
                48.211,
                48.215,
                48.22,
                48.224,
                48.228,
                48.232,
                48.237,
                48.241,
                48.245,
                48.249,
                48.254,
                48.258,
                48.262,
                48.266,
                48.271,
                48.275,
                48.279,
                48.283,
                48.287,
                48.292,
                48.296,
                48.3,
                48.304,
                48.308,
                48.312,
                48.316,
                48.321,
                48.325,
                48.329,
                48.333,
                48.337,
                48.341,
                48.345,
                48.349,
                48.354,
                48.358,
                48.362,
                48.366,
                48.37,
                48.374,
                48.378,
                48.382,
                48.386,
                48.39,
                48.394,
                48.398,
                48.402,
                48.406,
                48.41,
                48.414,
                48.418,
                48.422,
                48.426,
                48.43,
                48.434,
                48.438,
                48.442,
                48.446,
                48.45,
                48.454,
                48.458,
                48.462,
                48.466,
                48.47,
                48.474,
                48.478,
                48.482,
                48.486,
                48.49,
                48.494,
                48.497,
                48.501,
                48.505,
                48.509,
                48.513,
                48.517,
                48.521,
                48.525,
                48.529,
                48.532,
                48.536,
                48.54,
                48.544,
                48.548,
                48.552,
                48.555,
                48.559,
                48.563,
                48.567,
                48.571,
                48.574,
                48.578,
                48.582,
                48.586,
                48.59,
                48.593,
                48.597,
                48.601,
                48.605,
                48.608,
                48.612,
                48.616,
                48.62,
                48.623,
                48.627,
                48.631,
                48.634,
                48.638,
                48.642,
                48.646,
                48.649,
                48.653,
                48.657,
                48.66,
                48.664,
                48.668,
                48.671,
                48.675,
                48.679,
                48.682,
                48.686,
                48.69,
                48.693,
                48.697,
                48.7,
                48.704,
                48.708,
                48.711,
                48.715,
                48.718,
                48.722,
                48.726,
                48.729,
                48.733,
                48.736,
                48.74,
                48.743,
                48.747,
                48.751,
                48.754,
                48.758,
                48.761,
                48.765,
                48.768,
                48.772,
                48.775,
                48.779,
                48.782,
                48.786,
                48.789,
                48.793,
                48.796,
                48.8,
                48.803,
                48.807,
                48.81,
                48.814,
                48.817,
                48.82,
                48.824,
                48.827,
                48.831,
                48.834,
                48.838,
                48.841,
                48.844,
                48.848,
                48.851,
                48.855,
                48.858,
                48.861,
                48.865,
                48.868,
                48.872,
                48.875,
                48.878,
                48.882,
                48.885,
                48.888,
                48.892,
                48.895,
                48.898,
                48.902,
                48.905,
                48.908,
                48.912,
                48.915,
                48.918,
                48.922,
                48.925,
                48.928,
                48.931,
                48.935,
                48.938,
                48.941,
                48.945,
                48.948,
                48.951,
                48.954,
                48.958,
                48.961,
                48.964,
                48.967,
                48.971,
                48.974,
                48.977,
                48.98,
                48.983,
                48.987,
                48.99,
                48.993,
                48.996,
                48.999,
                49.003,
                49.006,
                49.009,
                49.012,
                49.015,
                49.018,
                49.022,
                49.025,
                49.028,
                49.031,
                49.034,
                49.037,
                49.04,
                49.044,
                49.047,
                49.05,
                49.053,
                49.056,
                49.059,
                49.062,
                49.065,
                49.068,
                49.071,
                49.074,
                49.078,
                49.081,
                49.084,
                49.087,
                49.09,
                49.093,
                49.096,
                49.099,
                49.102,
                49.105,
                49.108,
                49.111,
                49.114,
                49.117,
                49.12,
                49.123,
                49.126,
                49.129,
                49.132,
                49.135,
                49.138,
                49.141,
                49.144,
                49.147,
                49.15,
                49.153,
                49.156,
                49.159,
                49.162,
                49.165,
                49.168,
                49.171,
                49.174,
                49.176,
                49.179,
                49.182,
                49.185,
                49.188,
                49.191,
                49.194,
                49.197,
                49.2,
                49.203,
                49.205,
                49.208,
                49.211,
                49.214,
                49.217,
                49.22,
                49.223,
                49.226,
                49.228,
                49.231,
                49.234,
                49.237,
                49.24,
                49.243,
                49.245,
                49.248,
                49.251,
                49.254,
                49.257,
                49.259,
                49.262,
                49.265,
                49.268,
                49.271,
                49.273,
                49.276,
                49.279,
                49.282,
                49.285,
                49.287,
                49.29,
                49.293,
                49.296,
                49.298,
                49.301,
                49.304,
                49.307,
                49.309,
                49.312,
                49.315,
                49.318,
                49.32,
                49.323,
                49.326,
                49.328,
                49.331,
                49.334,
                49.337,
                49.339,
                49.342,
                49.345,
                49.347,
                49.35,
                49.353,
                49.355,
                49.358,
                49.361,
                49.363,
                49.366,
                49.369,
                49.371,
                49.374,
                49.377,
                49.379,
                49.382,
                49.384,
                49.387,
                49.39,
                49.392,
                49.395,
                49.398,
                49.4,
                49.403,
                49.405,
                49.408,
                49.411,
                49.413,
                49.416,
                49.418,
                49.421,
                49.424,
                49.426,
                49.429,
                49.431,
                49.434,
                49.436,
                49.439,
                49.442,
                49.444,
                49.447,
                49.449,
                49.452,
                49.454,
                49.457,
                49.459,
                49.462,
                49.464,
                49.467,
                49.469,
                49.472,
                49.475,
                49.477,
                49.48,
                49.482,
                49.485,
                49.487,
                49.49,
                49.492,
                49.494,
                49.497,
                49.499,
                49.502,
                49.504,
                49.507,
                49.509,
                49.512,
                49.514,
                49.517,
                49.519,
                49.522,
                49.524,
                49.527,
                49.529,
                49.531,
                49.534,
                49.536,
                49.539,
                49.541,
                49.544,
                49.546,
                49.548,
                49.551,
                49.553,
                49.556,
                49.558,
                49.56,
                49.563,
                49.565,
                49.568,
                49.57,
                49.572,
                49.575,
                49.577,
                49.58,
                49.582,
                49.584,
                49.587,
                49.589,
                49.591,
                49.594,
                49.596,
                49.598,
                49.601,
                49.603,
                49.605,
                49.608,
                49.61,
                49.613,
                49.615,
                49.617,
                49.62,
                49.622,
                49.624,
                49.626,
                49.629,
                49.631,
                49.633,
                49.636,
                49.638,
                49.64,
                49.643,
                49.645,
                49.647,
                49.65,
                49.652,
                49.654,
                49.656,
                49.659,
                49.661,
                49.663,
                49.665,
                49.668,
                49.67,
                49.672,
                49.675,
                49.677,
                49.679,
                49.681,
                49.684,
                49.686,
                49.688,
                49.69,
                49.693,
                49.695,
                49.697,
                49.699,
                49.701,
                49.704,
                49.706,
                49.708,
                49.71,
                49.713,
                49.715,
                49.717,
                49.719,
                49.721,
                49.724,
                49.726,
                49.728,
                49.73,
                49.732,
                49.735,
                49.737,
                49.739,
                49.741,
                49.743,
                49.745,
                49.748,
                49.75,
                49.752,
                49.754,
                49.756,
                49.758,
                49.761,
                49.763,
                49.765,
                49.767,
                49.769,
                49.771,
                49.774,
                49.776,
                49.778,
                49.78,
                49.782,
                49.784,
                49.786,
                49.789,
                49.791,
                49.793,
                49.795,
                49.797,
                49.799,
                49.801,
                49.803,
                49.805,
                49.808,
                49.81,
                49.812,
                49.814,
                49.816,
                49.818,
                49.82,
                49.822,
                49.824,
                49.826,
                49.829,
                49.831,
                49.833,
                49.835,
                49.837,
                49.839,
                49.841,
                49.843,
                49.845,
                49.847,
                49.849,
                49.851,
                49.853,
                49.855,
                49.857,
                49.86,
                49.862,
                49.864,
                49.866,
                49.868,
                49.87,
                49.872,
                49.874,
                49.876,
                49.878,
                49.88,
                49.882,
                49.884,
                49.886,
                49.888,
                49.89,
                49.892,
                49.894,
                49.896,
                49.898,
                49.9,
                49.902,
                49.904,
                49.906,
                49.908,
                49.91,
                49.912,
                49.914,
                49.916,
                49.918,
                49.92,
                49.922,
                49.924,
                49.926,
                49.928,
                49.93,
                49.932,
                49.934,
                49.936,
                49.938,
                49.94,
                49.942,
                49.944,
                49.946,
                49.948,
                49.949,
                49.951,
                49.953,
                49.955,
                49.957,
                49.959,
                49.961,
                49.963,
                49.965,
                49.967,
                49.969,
                49.971,
                49.973,
                49.975,
                49.977,
                49.979,
                49.98,
                49.982,
                49.984,
                49.986,
                49.988,
                49.99,
                49.992,
                49.994,
                49.996,
                49.998,
                50,
                50.001,
                50.003,
                50.005,
                50.007,
                50.009,
                50.011,
                50.013,
                50.015,
                50.017,
                50.018,
                50.02,
                50.022,
                50.024,
                50.026,
                50.028,
                50.03,
                50.031,
                50.033,
                50.035,
                50.037,
                50.039,
                50.041,
                50.043,
                50.044,
                50.046,
                50.048,
                50.05,
                50.052,
                50.054,
                50.056,
                50.057,
                50.059,
                50.061,
                50.063,
                50.065,
                50.067,
                50.068,
                50.07,
                50.072,
                50.074,
                50.076,
                50.077,
                50.079,
                50.081,
                50.083,
                50.085,
                50.086,
                50.088,
                50.09,
                50.092,
                50.094,
                50.095,
                50.097,
                50.099,
                50.101,
                50.103,
                50.104,
                50.106,
                50.108,
                50.11,
                50.112,
                50.113,
                50.115,
                50.117,
                50.119,
                50.12,
                50.122,
                50.124,
                50.126,
                50.127,
                50.129,
                50.131,
                50.133,
                50.135,
                50.136,
                50.138,
                50.14,
                50.142,
                50.143,
                50.145,
                50.147,
                50.148,
                50.15,
                50.152,
                50.154,
                50.155,
                50.157,
                50.159,
                50.161,
                50.162,
                50.164,
                50.166,
                50.167,
                50.169,
                50.171,
                50.173,
                50.174,
                50.176,
                50.178,
                50.179,
                50.181,
                50.183,
                50.185,
                50.186,
                50.188,
                50.19,
                50.191,
                50.193,
                50.195,
                50.196,
                50.198,
                50.2,
                50.202,
                50.203,
                50.205,
                50.207,
                50.208,
                50.21,
                50.212,
                50.213,
                50.215,
                50.217,
                50.218,
                50.22,
                50.222,
                50.223,
                50.225,
                50.227,
                50.228,
                50.23,
                50.232,
                50.233,
                50.235,
                50.237,
                50.238,
                50.24,
                50.241,
                50.243,
                50.245,
                50.246,
                50.248,
                50.25,
                50.251,
                50.253,
                50.255,
                50.256,
                50.258,
                50.259,
                50.261,
                50.263,
                50.264,
                50.266,
                50.268,
                50.269,
                50.271,
                50.272,
                50.274,
                50.276,
                50.277,
                50.279,
                50.28,
                50.282,
                50.284,
                50.285,
                50.287,
                50.288,
                50.29,
                50.292,
                50.293,
                50.295,
                50.296,
                50.298,
                50.3,
                50.301,
                50.303,
                50.304,
                50.306,
                50.308,
                50.309,
                50.311,
                50.312,
                50.314,
                50.315,
                50.317,
                50.319,
                50.32,
                50.322,
                50.323,
                50.325,
                50.326,
                50.328,
                50.33,
                50.331,
                50.333,
                50.334,
                50.336,
                50.337,
                50.339,
                50.34,
                50.342,
                50.344,
                50.345,
                50.347,
                50.348,
                50.35,
                50.351,
                50.353,
                50.354,
                50.356,
                50.357,
                50.359,
                50.36,
                50.362,
                50.364,
                50.365,
                50.367,
                50.368,
                50.37,
                50.371,
                50.373,
                50.374,
                50.376,
                50.377,
                50.379,
                50.38,
                50.382,
                50.383,
                50.385,
                50.386,
                50.388,
                50.389,
                50.391,
                50.392,
                50.394,
                50.395,
                50.397,
                50.398,
                50.4,
                50.401,
                50.403,
                50.404,
                50.406,
                50.407,
                50.409,
                50.41,
                50.412,
                50.413,
                50.415,
                50.416,
                50.418,
                50.419,
                50.421,
                50.422,
                50.424,
                50.425,
                50.427,
                50.428,
                50.43,
                50.431,
                50.432,
                50.434,
                50.435,
                50.437,
                50.438,
                50.44,
                50.441,
                50.443,
                50.444,
                50.446,
                50.447,
                50.449,
                50.45,
                50.451,
                50.453,
                50.454,
                50.456,
                50.457,
                50.459,
                50.46,
                50.462,
                50.463,
                50.465,
                50.466,
                50.467,
                50.469,
                50.47,
                50.472,
                50.473,
                50.475,
                50.476,
                50.477,
                50.479,
                50.48,
                50.482,
                50.483,
                50.485,
                50.486,
                50.487,
                50.489,
                50.49,
                50.492,
                50.493,
                50.495,
                50.496,
                50.497,
                50.499,
                50.5,
                50.502,
                50.503,
                50.505,
                50.506,
                50.507,
                50.509,
                50.51,
                50.512,
                50.513,
                50.514,
                50.516,
                50.517,
                50.519,
                50.52,
                50.521,
                50.523,
                50.524,
                50.526,
                50.527,
                50.528,
                50.53,
                50.531,
                50.533,
                50.534,
                50.535,
                50.537,
                50.538,
                50.54,
                50.541,
                50.542,
                50.544,
                50.545,
                50.546,
                50.548,
                50.549,
                50.551,
                50.552,
                50.553,
                50.555,
                50.556,
                50.557,
                50.559,
                50.56,
                50.562,
                50.563,
                50.564,
                50.566,
                50.567,
                50.568,
                50.57,
                50.571,
                50.573,
                50.574,
                50.575,
                50.577,
                50.578,
                50.579,
                50.581,
                50.582,
                50.583,
                50.585,
                50.586,
                50.587,
                50.589,
                50.59,
                50.592,
                50.593,
                50.594,
                50.596,
                50.597,
                50.598,
                50.6,
                50.601,
                50.602,
                50.604,
                50.605,
                50.606,
                50.608,
                50.609,
                50.61,
                50.612,
                50.613,
                50.614,
                50.616,
                50.617,
                50.618,
                50.62,
                50.621,
                50.622,
                50.624,
                50.625,
                50.626,
                50.628,
                50.629,
                50.63,
                50.632,
                50.633,
                50.634,
                50.636,
                50.637,
                50.638,
                50.64,
                50.641,
                50.642,
                50.644,
                50.645,
                50.646,
                50.648,
                50.649,
                50.65,
                50.652,
                50.653,
                50.654,
                50.656,
                50.657,
                50.658,
                50.66,
                50.661,
                50.662,
                50.663,
                50.665,
                50.666,
                50.667,
                50.669,
                50.67,
                50.671,
                50.673,
                50.674,
                50.675,
                50.677,
                50.678,
                50.679,
                50.68,
                50.682,
                50.683,
                50.684,
                50.686,
                50.687,
                50.688,
                50.69,
                50.691,
                50.692,
                50.693,
                50.695,
                50.696,
                50.697,
                50.699,
                50.7,
                50.701,
                50.703,
                50.704,
                50.705,
                50.706,
                50.708,
                50.709,
                50.71,
                50.712,
                50.713,
                50.714,
                50.715,
                50.717,
                50.718,
                50.719,
                50.721,
                50.722,
                50.723,
                50.724,
                50.726,
                50.727,
                50.728,
                50.73,
                50.731,
                50.732,
                50.733,
                50.735,
                50.736,
                50.737,
                50.738,
                50.74,
                50.741,
                50.742,
                50.744,
                50.745,
                50.746,
                50.747,
                50.749,
                50.75,
                50.751,
                50.752,
                50.754,
                50.755,
                50.756,
                50.758,
                50.759,
                50.76,
                50.761,
                50.763,
                50.764,
                50.765,
                50.766,
                50.768,
                50.769,
                50.77,
                50.771,
                50.773,
                50.774,
                50.775
            ],
            P75: [
                35.319,
                35.414,
                35.51,
                35.605,
                35.701,
                35.796,
                35.892,
                35.987,
                36.082,
                36.177,
                36.272,
                36.368,
                36.463,
                36.558,
                36.653,
                36.753,
                36.851,
                36.947,
                37.04,
                37.132,
                37.221,
                37.309,
                37.395,
                37.48,
                37.563,
                37.644,
                37.724,
                37.803,
                37.88,
                37.956,
                38.031,
                38.105,
                38.177,
                38.249,
                38.319,
                38.389,
                38.457,
                38.525,
                38.592,
                38.657,
                38.722,
                38.786,
                38.85,
                38.912,
                38.974,
                39.035,
                39.095,
                39.154,
                39.213,
                39.272,
                39.329,
                39.386,
                39.443,
                39.498,
                39.554,
                39.608,
                39.663,
                39.716,
                39.769,
                39.822,
                39.874,
                39.926,
                39.977,
                40.028,
                40.079,
                40.129,
                40.178,
                40.227,
                40.276,
                40.324,
                40.372,
                40.42,
                40.467,
                40.514,
                40.56,
                40.606,
                40.652,
                40.697,
                40.742,
                40.787,
                40.831,
                40.875,
                40.919,
                40.962,
                41.005,
                41.048,
                41.09,
                41.133,
                41.174,
                41.216,
                41.257,
                41.298,
                41.339,
                41.379,
                41.419,
                41.459,
                41.499,
                41.538,
                41.578,
                41.616,
                41.655,
                41.693,
                41.732,
                41.769,
                41.807,
                41.844,
                41.882,
                41.918,
                41.955,
                41.992,
                42.028,
                42.064,
                42.1,
                42.135,
                42.171,
                42.206,
                42.241,
                42.275,
                42.31,
                42.344,
                42.378,
                42.412,
                42.446,
                42.479,
                42.512,
                42.545,
                42.578,
                42.611,
                42.643,
                42.675,
                42.707,
                42.739,
                42.771,
                42.803,
                42.834,
                42.865,
                42.896,
                42.927,
                42.957,
                42.988,
                43.018,
                43.048,
                43.078,
                43.107,
                43.137,
                43.166,
                43.195,
                43.224,
                43.253,
                43.282,
                43.31,
                43.339,
                43.367,
                43.395,
                43.422,
                43.45,
                43.478,
                43.505,
                43.532,
                43.559,
                43.586,
                43.613,
                43.639,
                43.666,
                43.692,
                43.718,
                43.744,
                43.77,
                43.795,
                43.821,
                43.846,
                43.871,
                43.896,
                43.921,
                43.946,
                43.971,
                43.995,
                44.019,
                44.044,
                44.068,
                44.092,
                44.116,
                44.139,
                44.163,
                44.186,
                44.209,
                44.232,
                44.256,
                44.278,
                44.301,
                44.324,
                44.346,
                44.369,
                44.391,
                44.413,
                44.435,
                44.457,
                44.479,
                44.5,
                44.522,
                44.543,
                44.564,
                44.586,
                44.607,
                44.628,
                44.648,
                44.669,
                44.69,
                44.71,
                44.731,
                44.751,
                44.771,
                44.791,
                44.811,
                44.831,
                44.851,
                44.87,
                44.89,
                44.909,
                44.928,
                44.947,
                44.967,
                44.985,
                45.004,
                45.023,
                45.042,
                45.06,
                45.079,
                45.097,
                45.116,
                45.133,
                45.152,
                45.17,
                45.188,
                45.205,
                45.223,
                45.241,
                45.258,
                45.276,
                45.293,
                45.31,
                45.327,
                45.345,
                45.362,
                45.378,
                45.395,
                45.412,
                45.429,
                45.445,
                45.461,
                45.478,
                45.494,
                45.511,
                45.527,
                45.543,
                45.559,
                45.575,
                45.59,
                45.606,
                45.622,
                45.638,
                45.653,
                45.668,
                45.684,
                45.699,
                45.714,
                45.73,
                45.745,
                45.76,
                45.775,
                45.789,
                45.804,
                45.819,
                45.834,
                45.848,
                45.863,
                45.877,
                45.892,
                45.906,
                45.92,
                45.934,
                45.948,
                45.962,
                45.976,
                45.99,
                46.004,
                46.018,
                46.031,
                46.045,
                46.059,
                46.072,
                46.085,
                46.099,
                46.112,
                46.125,
                46.139,
                46.152,
                46.165,
                46.178,
                46.191,
                46.204,
                46.217,
                46.229,
                46.242,
                46.255,
                46.267,
                46.28,
                46.292,
                46.305,
                46.317,
                46.329,
                46.342,
                46.354,
                46.366,
                46.378,
                46.39,
                46.402,
                46.414,
                46.426,
                46.438,
                46.45,
                46.461,
                46.473,
                46.485,
                46.496,
                46.508,
                46.519,
                46.531,
                46.542,
                46.553,
                46.564,
                46.576,
                46.587,
                46.598,
                46.609,
                46.62,
                46.631,
                46.642,
                46.653,
                46.664,
                46.675,
                46.685,
                46.696,
                46.706,
                46.717,
                46.728,
                46.738,
                46.749,
                46.759,
                46.769,
                46.78,
                46.79,
                46.8,
                46.811,
                46.821,
                46.831,
                46.841,
                46.851,
                46.861,
                46.871,
                46.881,
                46.891,
                46.901,
                46.911,
                46.92,
                46.93,
                46.94,
                46.95,
                46.959,
                46.969,
                46.978,
                46.988,
                46.997,
                47.007,
                47.016,
                47.025,
                47.035,
                47.044,
                47.053,
                47.062,
                47.072,
                47.081,
                47.09,
                47.099,
                47.108,
                47.117,
                47.126,
                47.135,
                47.144,
                47.153,
                47.162,
                47.17,
                47.179,
                47.188,
                47.197,
                47.205,
                47.214,
                47.223,
                47.231,
                47.24,
                47.248,
                47.257,
                47.265,
                47.274,
                47.282,
                47.291,
                47.299,
                47.307,
                47.316,
                47.324,
                47.332,
                47.34,
                47.348,
                47.357,
                47.365,
                47.373,
                47.381,
                47.389,
                47.397,
                47.405,
                47.413,
                47.421,
                47.429,
                47.437,
                47.444,
                47.453,
                47.46,
                47.468,
                47.476,
                47.484,
                47.491,
                47.499,
                47.507,
                47.514,
                47.522,
                47.529,
                47.537,
                47.544,
                47.552,
                47.559,
                47.567,
                47.574,
                47.582,
                47.589,
                47.597,
                47.604,
                47.611,
                47.619,
                47.626,
                47.633,
                47.641,
                47.648,
                47.655,
                47.662,
                47.669,
                47.676,
                47.683,
                47.69,
                47.698,
                47.705,
                47.712,
                47.719,
                47.726,
                47.733,
                47.74,
                47.747,
                47.754,
                47.761,
                47.767,
                47.774,
                47.781,
                47.788,
                47.795,
                47.802,
                47.808,
                47.815,
                47.822,
                47.829,
                47.835,
                47.842,
                47.849,
                47.855,
                47.862,
                47.869,
                47.875,
                47.882,
                47.888,
                47.895,
                47.902,
                47.908,
                47.914,
                47.921,
                47.927,
                47.934,
                47.94,
                47.947,
                47.953,
                47.959,
                47.966,
                47.972,
                47.979,
                47.985,
                47.991,
                47.997,
                48.004,
                48.01,
                48.016,
                48.022,
                48.029,
                48.035,
                48.041,
                48.047,
                48.053,
                48.06,
                48.066,
                48.072,
                48.078,
                48.084,
                48.09,
                48.096,
                48.102,
                48.108,
                48.114,
                48.12,
                48.126,
                48.132,
                48.138,
                48.144,
                48.15,
                48.156,
                48.162,
                48.168,
                48.174,
                48.179,
                48.186,
                48.191,
                48.197,
                48.203,
                48.209,
                48.215,
                48.22,
                48.226,
                48.232,
                48.238,
                48.244,
                48.249,
                48.255,
                48.261,
                48.266,
                48.272,
                48.278,
                48.283,
                48.289,
                48.295,
                48.3,
                48.306,
                48.312,
                48.317,
                48.323,
                48.329,
                48.334,
                48.34,
                48.345,
                48.351,
                48.356,
                48.362,
                48.367,
                48.373,
                48.378,
                48.384,
                48.389,
                48.395,
                48.4,
                48.406,
                48.411,
                48.417,
                48.422,
                48.427,
                48.433,
                48.438,
                48.443,
                48.449,
                48.454,
                48.459,
                48.465,
                48.47,
                48.476,
                48.481,
                48.486,
                48.492,
                48.497,
                48.502,
                48.507,
                48.513,
                48.518,
                48.523,
                48.528,
                48.534,
                48.539,
                48.544,
                48.549,
                48.554,
                48.56,
                48.565,
                48.57,
                48.575,
                48.58,
                48.586,
                48.591,
                48.596,
                48.601,
                48.606,
                48.611,
                48.616,
                48.621,
                48.627,
                48.632,
                48.637,
                48.642,
                48.647,
                48.652,
                48.657,
                48.662,
                48.667,
                48.672,
                48.677,
                48.682,
                48.687,
                48.692,
                48.697,
                48.702,
                48.707,
                48.712,
                48.717,
                48.722,
                48.727,
                48.732,
                48.737,
                48.742,
                48.747,
                48.751,
                48.756,
                48.761,
                48.766,
                48.771,
                48.776,
                48.781,
                48.786,
                48.791,
                48.795,
                48.8,
                48.805,
                48.81,
                48.814,
                48.82,
                48.824,
                48.829,
                48.834,
                48.839,
                48.843,
                48.848,
                48.853,
                48.858,
                48.863,
                48.867,
                48.872,
                48.877,
                48.881,
                48.886,
                48.891,
                48.896,
                48.9,
                48.905,
                48.91,
                48.914,
                48.919,
                48.924,
                48.928,
                48.933,
                48.938,
                48.943,
                48.947,
                48.952,
                48.956,
                48.961,
                48.965,
                48.97,
                48.975,
                48.98,
                48.984,
                48.989,
                48.993,
                48.998,
                49.002,
                49.007,
                49.012,
                49.016,
                49.021,
                49.025,
                49.03,
                49.034,
                49.039,
                49.043,
                49.048,
                49.052,
                49.057,
                49.061,
                49.066,
                49.07,
                49.075,
                49.079,
                49.084,
                49.088,
                49.093,
                49.097,
                49.101,
                49.106,
                49.111,
                49.115,
                49.119,
                49.124,
                49.128,
                49.132,
                49.137,
                49.141,
                49.146,
                49.15,
                49.155,
                49.159,
                49.163,
                49.167,
                49.172,
                49.176,
                49.181,
                49.185,
                49.189,
                49.194,
                49.198,
                49.202,
                49.207,
                49.211,
                49.215,
                49.219,
                49.224,
                49.228,
                49.232,
                49.236,
                49.241,
                49.245,
                49.249,
                49.254,
                49.258,
                49.262,
                49.266,
                49.271,
                49.275,
                49.279,
                49.283,
                49.287,
                49.292,
                49.296,
                49.3,
                49.304,
                49.308,
                49.313,
                49.317,
                49.321,
                49.325,
                49.329,
                49.333,
                49.337,
                49.342,
                49.346,
                49.35,
                49.354,
                49.358,
                49.362,
                49.366,
                49.37,
                49.374,
                49.378,
                49.383,
                49.386,
                49.391,
                49.395,
                49.399,
                49.403,
                49.407,
                49.411,
                49.415,
                49.419,
                49.423,
                49.427,
                49.431,
                49.435,
                49.439,
                49.443,
                49.447,
                49.451,
                49.455,
                49.459,
                49.463,
                49.467,
                49.471,
                49.475,
                49.479,
                49.483,
                49.487,
                49.491,
                49.495,
                49.498,
                49.502,
                49.506,
                49.51,
                49.514,
                49.518,
                49.522,
                49.526,
                49.529,
                49.534,
                49.537,
                49.541,
                49.545,
                49.549,
                49.553,
                49.557,
                49.561,
                49.564,
                49.568,
                49.572,
                49.576,
                49.579,
                49.583,
                49.587,
                49.591,
                49.595,
                49.599,
                49.602,
                49.606,
                49.61,
                49.613,
                49.617,
                49.621,
                49.625,
                49.629,
                49.632,
                49.636,
                49.64,
                49.644,
                49.647,
                49.651,
                49.655,
                49.658,
                49.662,
                49.666,
                49.67,
                49.673,
                49.677,
                49.68,
                49.684,
                49.688,
                49.691,
                49.695,
                49.699,
                49.702,
                49.706,
                49.71,
                49.713,
                49.717,
                49.721,
                49.724,
                49.728,
                49.731,
                49.735,
                49.738,
                49.742,
                49.746,
                49.749,
                49.753,
                49.756,
                49.76,
                49.763,
                49.767,
                49.771,
                49.774,
                49.778,
                49.781,
                49.784,
                49.788,
                49.791,
                49.795,
                49.799,
                49.802,
                49.806,
                49.809,
                49.812,
                49.816,
                49.819,
                49.823,
                49.826,
                49.83,
                49.833,
                49.837,
                49.84,
                49.843,
                49.847,
                49.851,
                49.854,
                49.857,
                49.861,
                49.864,
                49.867,
                49.871,
                49.874,
                49.878,
                49.881,
                49.884,
                49.888,
                49.891,
                49.895,
                49.898,
                49.901,
                49.905,
                49.908,
                49.911,
                49.914,
                49.918,
                49.921,
                49.925,
                49.928,
                49.931,
                49.934,
                49.938,
                49.941,
                49.944,
                49.948,
                49.951,
                49.954,
                49.957,
                49.961,
                49.964,
                49.967,
                49.971,
                49.974,
                49.977,
                49.98,
                49.983,
                49.987,
                49.99,
                49.993,
                49.996,
                50,
                50.003,
                50.006,
                50.009,
                50.012,
                50.016,
                50.019,
                50.022,
                50.025,
                50.028,
                50.031,
                50.034,
                50.038,
                50.041,
                50.044,
                50.047,
                50.05,
                50.053,
                50.056,
                50.059,
                50.063,
                50.066,
                50.069,
                50.072,
                50.075,
                50.078,
                50.081,
                50.085,
                50.088,
                50.091,
                50.094,
                50.097,
                50.1,
                50.103,
                50.106,
                50.109,
                50.112,
                50.115,
                50.118,
                50.121,
                50.124,
                50.127,
                50.13,
                50.133,
                50.136,
                50.139,
                50.142,
                50.145,
                50.148,
                50.151,
                50.154,
                50.157,
                50.16,
                50.163,
                50.166,
                50.169,
                50.172,
                50.175,
                50.178,
                50.181,
                50.184,
                50.187,
                50.19,
                50.192,
                50.196,
                50.199,
                50.201,
                50.204,
                50.207,
                50.21,
                50.213,
                50.216,
                50.219,
                50.222,
                50.225,
                50.227,
                50.23,
                50.233,
                50.236,
                50.239,
                50.242,
                50.245,
                50.248,
                50.25,
                50.253,
                50.256,
                50.259,
                50.262,
                50.265,
                50.267,
                50.27,
                50.273,
                50.276,
                50.279,
                50.281,
                50.284,
                50.287,
                50.29,
                50.293,
                50.295,
                50.298,
                50.301,
                50.304,
                50.307,
                50.309,
                50.312,
                50.315,
                50.318,
                50.32,
                50.323,
                50.326,
                50.329,
                50.331,
                50.334,
                50.337,
                50.339,
                50.342,
                50.345,
                50.348,
                50.351,
                50.353,
                50.356,
                50.358,
                50.361,
                50.364,
                50.366,
                50.369,
                50.372,
                50.375,
                50.377,
                50.38,
                50.383,
                50.385,
                50.388,
                50.391,
                50.393,
                50.396,
                50.399,
                50.401,
                50.404,
                50.406,
                50.409,
                50.412,
                50.415,
                50.417,
                50.42,
                50.422,
                50.425,
                50.427,
                50.43,
                50.433,
                50.435,
                50.438,
                50.441,
                50.443,
                50.446,
                50.448,
                50.451,
                50.454,
                50.456,
                50.459,
                50.461,
                50.464,
                50.466,
                50.469,
                50.471,
                50.474,
                50.477,
                50.479,
                50.482,
                50.484,
                50.487,
                50.489,
                50.492,
                50.494,
                50.497,
                50.499,
                50.502,
                50.504,
                50.507,
                50.509,
                50.512,
                50.514,
                50.517,
                50.519,
                50.522,
                50.524,
                50.527,
                50.529,
                50.532,
                50.534,
                50.537,
                50.539,
                50.542,
                50.544,
                50.546,
                50.549,
                50.551,
                50.554,
                50.556,
                50.559,
                50.561,
                50.564,
                50.566,
                50.568,
                50.571,
                50.573,
                50.576,
                50.578,
                50.581,
                50.583,
                50.585,
                50.588,
                50.59,
                50.592,
                50.595,
                50.598,
                50.6,
                50.602,
                50.605,
                50.607,
                50.609,
                50.612,
                50.614,
                50.617,
                50.619,
                50.621,
                50.624,
                50.626,
                50.628,
                50.631,
                50.633,
                50.636,
                50.638,
                50.64,
                50.642,
                50.645,
                50.647,
                50.649,
                50.652,
                50.654,
                50.656,
                50.659,
                50.661,
                50.663,
                50.666,
                50.668,
                50.67,
                50.673,
                50.675,
                50.677,
                50.68,
                50.682,
                50.684,
                50.686,
                50.688,
                50.691,
                50.693,
                50.696,
                50.698,
                50.7,
                50.702,
                50.705,
                50.707,
                50.709,
                50.712,
                50.714,
                50.716,
                50.718,
                50.72,
                50.723,
                50.725,
                50.727,
                50.729,
                50.732,
                50.734,
                50.736,
                50.738,
                50.741,
                50.743,
                50.745,
                50.747,
                50.749,
                50.752,
                50.754,
                50.756,
                50.758,
                50.76,
                50.763,
                50.765,
                50.767,
                50.769,
                50.772,
                50.774,
                50.776,
                50.778,
                50.78,
                50.782,
                50.784,
                50.787,
                50.789,
                50.791,
                50.793,
                50.796,
                50.798,
                50.8,
                50.802,
                50.804,
                50.806,
                50.809,
                50.811,
                50.813,
                50.815,
                50.817,
                50.819,
                50.821,
                50.823,
                50.825,
                50.828,
                50.83,
                50.832,
                50.834,
                50.836,
                50.838,
                50.84,
                50.842,
                50.845,
                50.847,
                50.849,
                50.851,
                50.853,
                50.855,
                50.857,
                50.859,
                50.861,
                50.863,
                50.866,
                50.868,
                50.87,
                50.872,
                50.874,
                50.876,
                50.878,
                50.88,
                50.882,
                50.885,
                50.887,
                50.889,
                50.891,
                50.893,
                50.895,
                50.897,
                50.899,
                50.901,
                50.903,
                50.905,
                50.907,
                50.909,
                50.911,
                50.913,
                50.915,
                50.917,
                50.919,
                50.921,
                50.923,
                50.925,
                50.927,
                50.929,
                50.931,
                50.933,
                50.935,
                50.937,
                50.94,
                50.942,
                50.944,
                50.946,
                50.948,
                50.95,
                50.951,
                50.953,
                50.955,
                50.958,
                50.96,
                50.962,
                50.964,
                50.965,
                50.967,
                50.969,
                50.971,
                50.973,
                50.976,
                50.978,
                50.979,
                50.981,
                50.983,
                50.985,
                50.987,
                50.989,
                50.991,
                50.993,
                50.995,
                50.997,
                50.999,
                51.001,
                51.003,
                51.005,
                51.007,
                51.009,
                51.01,
                51.013,
                51.014,
                51.016,
                51.018,
                51.02,
                51.022,
                51.024,
                51.026,
                51.028,
                51.03,
                51.032,
                51.034,
                51.036,
                51.037,
                51.039,
                51.041,
                51.043,
                51.045,
                51.047,
                51.049,
                51.051,
                51.053,
                51.055,
                51.056,
                51.058,
                51.06,
                51.062,
                51.064,
                51.066,
                51.068,
                51.07,
                51.071,
                51.073,
                51.075,
                51.077,
                51.079,
                51.081,
                51.082,
                51.085,
                51.086,
                51.088,
                51.09,
                51.092,
                51.094,
                51.096,
                51.097,
                51.099,
                51.101,
                51.103,
                51.105,
                51.107,
                51.109,
                51.11,
                51.112,
                51.114,
                51.116,
                51.118,
                51.12,
                51.121,
                51.123,
                51.125,
                51.127,
                51.128,
                51.13,
                51.132,
                51.134,
                51.136,
                51.138,
                51.139,
                51.141,
                51.143,
                51.145,
                51.147,
                51.148,
                51.15,
                51.152,
                51.154,
                51.156,
                51.157,
                51.159,
                51.161,
                51.163,
                51.164,
                51.166,
                51.168,
                51.17,
                51.171,
                51.173,
                51.175,
                51.177,
                51.179,
                51.18,
                51.182,
                51.184,
                51.185,
                51.187,
                51.189,
                51.191,
                51.193,
                51.194,
                51.196,
                51.198,
                51.199,
                51.201,
                51.203,
                51.205,
                51.207,
                51.208,
                51.21,
                51.212,
                51.213,
                51.215,
                51.217,
                51.218,
                51.22,
                51.222,
                51.224,
                51.226,
                51.227,
                51.229,
                51.231,
                51.232,
                51.234,
                51.236,
                51.237,
                51.239,
                51.241,
                51.243,
                51.244,
                51.246,
                51.248,
                51.249,
                51.251,
                51.253,
                51.254,
                51.256,
                51.257,
                51.259,
                51.261,
                51.263,
                51.264,
                51.266,
                51.268,
                51.269,
                51.271,
                51.273,
                51.274,
                51.276,
                51.278,
                51.279,
                51.281,
                51.283,
                51.284,
                51.286,
                51.288,
                51.289,
                51.291,
                51.293,
                51.294,
                51.296,
                51.298,
                51.299,
                51.301,
                51.302,
                51.304,
                51.306,
                51.307,
                51.309,
                51.311,
                51.312,
                51.314,
                51.316,
                51.317,
                51.319,
                51.32,
                51.322,
                51.324,
                51.325,
                51.327,
                51.329,
                51.33,
                51.332,
                51.333,
                51.335,
                51.337,
                51.338,
                51.34,
                51.341,
                51.343,
                51.345,
                51.346,
                51.348,
                51.349,
                51.351,
                51.353,
                51.354,
                51.356,
                51.357,
                51.359,
                51.361,
                51.362,
                51.364,
                51.365,
                51.367,
                51.369,
                51.37,
                51.372,
                51.373,
                51.375,
                51.377,
                51.378,
                51.38,
                51.381,
                51.383,
                51.384,
                51.386,
                51.387,
                51.389,
                51.39,
                51.392,
                51.394,
                51.395,
                51.397,
                51.398,
                51.4,
                51.401,
                51.403,
                51.404,
                51.406,
                51.407,
                51.409,
                51.411,
                51.412,
                51.414,
                51.415,
                51.417,
                51.418,
                51.42,
                51.421,
                51.423,
                51.424,
                51.426,
                51.428,
                51.429,
                51.431,
                51.432,
                51.434,
                51.435,
                51.437,
                51.438,
                51.44,
                51.441,
                51.443,
                51.444,
                51.446,
                51.447,
                51.449,
                51.45,
                51.452,
                51.453,
                51.455,
                51.456,
                51.458,
                51.46,
                51.461,
                51.463,
                51.464,
                51.466,
                51.467,
                51.468,
                51.47,
                51.471,
                51.473,
                51.475,
                51.476,
                51.478,
                51.479,
                51.481,
                51.482,
                51.483,
                51.485,
                51.486,
                51.488,
                51.489,
                51.491,
                51.492,
                51.494,
                51.495,
                51.497,
                51.498,
                51.5,
                51.501,
                51.503,
                51.504,
                51.505,
                51.507,
                51.509,
                51.51,
                51.512,
                51.513,
                51.514,
                51.516,
                51.517,
                51.519,
                51.52,
                51.521,
                51.523,
                51.525,
                51.526,
                51.528,
                51.529,
                51.53,
                51.532,
                51.533,
                51.535,
                51.536,
                51.538,
                51.539,
                51.541,
                51.542,
                51.543,
                51.545,
                51.546,
                51.548,
                51.549,
                51.551,
                51.552,
                51.553,
                51.555,
                51.556,
                51.558,
                51.559,
                51.561,
                51.562,
                51.564,
                51.565,
                51.566,
                51.568,
                51.569,
                51.57,
                51.572,
                51.574,
                51.575,
                51.576,
                51.578,
                51.579,
                51.581,
                51.582,
                51.583,
                51.585,
                51.586,
                51.588,
                51.589,
                51.591,
                51.592,
                51.593,
                51.595,
                51.596,
                51.598,
                51.599,
                51.6,
                51.602,
                51.603,
                51.605,
                51.606,
                51.607,
                51.609,
                51.61,
                51.612,
                51.613,
                51.614,
                51.616,
                51.617,
                51.618,
                51.62,
                51.621,
                51.623,
                51.624,
                51.626,
                51.627,
                51.628,
                51.63,
                51.631,
                51.632,
                51.634,
                51.635,
                51.637,
                51.638,
                51.639,
                51.641,
                51.642,
                51.644,
                51.645,
                51.646,
                51.648,
                51.649,
                51.651,
                51.652,
                51.653,
                51.655,
                51.656,
                51.657,
                51.659,
                51.66,
                51.661,
                51.663,
                51.664,
                51.665,
                51.667,
                51.668,
                51.67,
                51.671,
                51.672,
                51.674,
                51.675,
                51.676,
                51.678,
                51.679,
                51.68,
                51.682,
                51.683,
                51.685,
                51.686,
                51.687,
                51.689,
                51.69,
                51.691,
                51.693,
                51.694,
                51.695,
                51.697,
                51.698,
                51.7,
                51.701,
                51.702,
                51.704,
                51.705,
                51.706,
                51.708,
                51.709,
                51.71,
                51.712,
                51.713,
                51.715,
                51.716,
                51.717,
                51.718,
                51.72,
                51.721,
                51.722,
                51.724,
                51.725,
                51.726,
                51.728,
                51.729,
                51.731,
                51.732,
                51.733,
                51.735,
                51.736,
                51.737,
                51.739,
                51.74,
                51.741,
                51.742,
                51.744,
                51.745,
                51.747,
                51.748,
                51.749,
                51.751,
                51.752,
                51.753,
                51.754,
                51.756,
                51.757,
                51.758,
                51.76,
                51.761,
                51.763,
                51.764,
                51.765,
                51.766,
                51.768,
                51.769,
                51.77,
                51.772,
                51.773,
                51.774,
                51.775,
                51.777,
                51.778,
                51.78,
                51.781,
                51.782,
                51.783,
                51.785
            ],
            P85: [
                35.778,
                35.872,
                35.964,
                36.058,
                36.15,
                36.243,
                36.336,
                36.429,
                36.521,
                36.614,
                36.706,
                36.799,
                36.891,
                36.983,
                37.076,
                37.176,
                37.274,
                37.369,
                37.463,
                37.554,
                37.644,
                37.732,
                37.818,
                37.902,
                37.985,
                38.067,
                38.147,
                38.225,
                38.303,
                38.379,
                38.454,
                38.527,
                38.6,
                38.672,
                38.742,
                38.812,
                38.88,
                38.948,
                39.014,
                39.08,
                39.145,
                39.209,
                39.273,
                39.335,
                39.397,
                39.458,
                39.519,
                39.578,
                39.637,
                39.695,
                39.753,
                39.81,
                39.866,
                39.922,
                39.978,
                40.033,
                40.087,
                40.14,
                40.194,
                40.246,
                40.299,
                40.351,
                40.402,
                40.453,
                40.503,
                40.553,
                40.603,
                40.652,
                40.701,
                40.75,
                40.798,
                40.845,
                40.892,
                40.94,
                40.986,
                41.032,
                41.078,
                41.123,
                41.169,
                41.213,
                41.258,
                41.302,
                41.345,
                41.389,
                41.432,
                41.475,
                41.518,
                41.56,
                41.602,
                41.643,
                41.685,
                41.726,
                41.767,
                41.807,
                41.847,
                41.887,
                41.927,
                41.967,
                42.006,
                42.045,
                42.084,
                42.122,
                42.161,
                42.199,
                42.236,
                42.274,
                42.311,
                42.348,
                42.385,
                42.422,
                42.458,
                42.494,
                42.531,
                42.566,
                42.602,
                42.637,
                42.672,
                42.707,
                42.741,
                42.776,
                42.81,
                42.844,
                42.878,
                42.912,
                42.945,
                42.978,
                43.011,
                43.044,
                43.077,
                43.109,
                43.141,
                43.173,
                43.205,
                43.237,
                43.268,
                43.299,
                43.33,
                43.361,
                43.392,
                43.423,
                43.453,
                43.483,
                43.513,
                43.543,
                43.572,
                43.602,
                43.631,
                43.66,
                43.689,
                43.718,
                43.747,
                43.775,
                43.804,
                43.832,
                43.86,
                43.888,
                43.915,
                43.943,
                43.97,
                43.997,
                44.024,
                44.051,
                44.077,
                44.104,
                44.131,
                44.157,
                44.183,
                44.209,
                44.235,
                44.26,
                44.286,
                44.311,
                44.336,
                44.361,
                44.387,
                44.411,
                44.436,
                44.46,
                44.485,
                44.509,
                44.533,
                44.557,
                44.581,
                44.605,
                44.628,
                44.652,
                44.675,
                44.698,
                44.721,
                44.744,
                44.767,
                44.789,
                44.812,
                44.834,
                44.856,
                44.879,
                44.9,
                44.923,
                44.945,
                44.966,
                44.988,
                45.009,
                45.03,
                45.052,
                45.073,
                45.094,
                45.115,
                45.135,
                45.156,
                45.177,
                45.197,
                45.217,
                45.237,
                45.257,
                45.277,
                45.297,
                45.317,
                45.336,
                45.356,
                45.375,
                45.395,
                45.414,
                45.433,
                45.452,
                45.471,
                45.49,
                45.508,
                45.527,
                45.546,
                45.564,
                45.582,
                45.601,
                45.619,
                45.637,
                45.655,
                45.673,
                45.69,
                45.708,
                45.726,
                45.743,
                45.76,
                45.778,
                45.795,
                45.812,
                45.829,
                45.846,
                45.863,
                45.88,
                45.897,
                45.913,
                45.93,
                45.946,
                45.962,
                45.979,
                45.995,
                46.011,
                46.027,
                46.043,
                46.059,
                46.075,
                46.091,
                46.106,
                46.122,
                46.137,
                46.153,
                46.168,
                46.183,
                46.199,
                46.214,
                46.229,
                46.243,
                46.258,
                46.273,
                46.288,
                46.303,
                46.318,
                46.332,
                46.347,
                46.361,
                46.375,
                46.389,
                46.404,
                46.418,
                46.432,
                46.446,
                46.46,
                46.474,
                46.488,
                46.502,
                46.515,
                46.529,
                46.542,
                46.556,
                46.569,
                46.583,
                46.596,
                46.609,
                46.622,
                46.636,
                46.649,
                46.662,
                46.675,
                46.688,
                46.7,
                46.713,
                46.726,
                46.739,
                46.751,
                46.763,
                46.776,
                46.788,
                46.801,
                46.813,
                46.825,
                46.838,
                46.85,
                46.862,
                46.874,
                46.886,
                46.898,
                46.91,
                46.922,
                46.933,
                46.945,
                46.957,
                46.968,
                46.98,
                46.992,
                47.003,
                47.015,
                47.026,
                47.037,
                47.048,
                47.06,
                47.071,
                47.082,
                47.093,
                47.104,
                47.115,
                47.126,
                47.137,
                47.147,
                47.158,
                47.169,
                47.18,
                47.19,
                47.201,
                47.212,
                47.222,
                47.233,
                47.243,
                47.253,
                47.264,
                47.274,
                47.284,
                47.295,
                47.305,
                47.315,
                47.325,
                47.335,
                47.345,
                47.355,
                47.366,
                47.375,
                47.385,
                47.395,
                47.405,
                47.415,
                47.424,
                47.434,
                47.444,
                47.453,
                47.463,
                47.472,
                47.482,
                47.491,
                47.501,
                47.51,
                47.52,
                47.529,
                47.538,
                47.547,
                47.556,
                47.566,
                47.575,
                47.584,
                47.593,
                47.602,
                47.611,
                47.62,
                47.629,
                47.638,
                47.647,
                47.655,
                47.664,
                47.673,
                47.682,
                47.69,
                47.699,
                47.708,
                47.716,
                47.725,
                47.734,
                47.742,
                47.751,
                47.759,
                47.768,
                47.776,
                47.785,
                47.793,
                47.801,
                47.81,
                47.818,
                47.826,
                47.834,
                47.842,
                47.85,
                47.859,
                47.867,
                47.875,
                47.883,
                47.891,
                47.899,
                47.907,
                47.915,
                47.923,
                47.931,
                47.939,
                47.947,
                47.954,
                47.962,
                47.97,
                47.978,
                47.985,
                47.993,
                48.001,
                48.008,
                48.016,
                48.024,
                48.031,
                48.039,
                48.046,
                48.054,
                48.061,
                48.069,
                48.076,
                48.084,
                48.091,
                48.098,
                48.106,
                48.113,
                48.12,
                48.128,
                48.135,
                48.142,
                48.149,
                48.156,
                48.164,
                48.171,
                48.178,
                48.185,
                48.192,
                48.2,
                48.207,
                48.213,
                48.22,
                48.227,
                48.234,
                48.241,
                48.248,
                48.255,
                48.262,
                48.269,
                48.276,
                48.283,
                48.29,
                48.297,
                48.303,
                48.31,
                48.317,
                48.324,
                48.33,
                48.337,
                48.344,
                48.35,
                48.357,
                48.364,
                48.371,
                48.377,
                48.384,
                48.39,
                48.397,
                48.403,
                48.41,
                48.416,
                48.423,
                48.429,
                48.435,
                48.442,
                48.449,
                48.455,
                48.462,
                48.468,
                48.474,
                48.481,
                48.487,
                48.493,
                48.499,
                48.506,
                48.512,
                48.518,
                48.525,
                48.531,
                48.537,
                48.543,
                48.549,
                48.556,
                48.562,
                48.568,
                48.574,
                48.58,
                48.586,
                48.592,
                48.599,
                48.605,
                48.611,
                48.617,
                48.623,
                48.629,
                48.635,
                48.641,
                48.646,
                48.652,
                48.658,
                48.665,
                48.671,
                48.676,
                48.682,
                48.688,
                48.694,
                48.7,
                48.706,
                48.711,
                48.717,
                48.723,
                48.729,
                48.735,
                48.741,
                48.747,
                48.752,
                48.758,
                48.764,
                48.769,
                48.775,
                48.781,
                48.787,
                48.793,
                48.798,
                48.804,
                48.809,
                48.815,
                48.82,
                48.826,
                48.832,
                48.837,
                48.843,
                48.849,
                48.854,
                48.86,
                48.865,
                48.871,
                48.876,
                48.882,
                48.887,
                48.893,
                48.899,
                48.904,
                48.91,
                48.915,
                48.92,
                48.926,
                48.931,
                48.937,
                48.942,
                48.948,
                48.953,
                48.959,
                48.964,
                48.969,
                48.975,
                48.98,
                48.985,
                48.99,
                48.996,
                49.002,
                49.007,
                49.012,
                49.017,
                49.023,
                49.028,
                49.033,
                49.038,
                49.043,
                49.049,
                49.054,
                49.059,
                49.065,
                49.07,
                49.075,
                49.08,
                49.085,
                49.09,
                49.096,
                49.101,
                49.106,
                49.111,
                49.117,
                49.122,
                49.127,
                49.132,
                49.137,
                49.142,
                49.147,
                49.152,
                49.157,
                49.163,
                49.168,
                49.173,
                49.178,
                49.183,
                49.188,
                49.193,
                49.198,
                49.203,
                49.208,
                49.213,
                49.218,
                49.223,
                49.228,
                49.233,
                49.238,
                49.243,
                49.248,
                49.253,
                49.258,
                49.263,
                49.268,
                49.273,
                49.278,
                49.283,
                49.287,
                49.292,
                49.297,
                49.302,
                49.307,
                49.312,
                49.317,
                49.322,
                49.326,
                49.331,
                49.336,
                49.341,
                49.346,
                49.351,
                49.356,
                49.36,
                49.365,
                49.37,
                49.375,
                49.379,
                49.384,
                49.389,
                49.394,
                49.399,
                49.403,
                49.408,
                49.413,
                49.417,
                49.423,
                49.427,
                49.432,
                49.437,
                49.441,
                49.446,
                49.45,
                49.455,
                49.46,
                49.465,
                49.469,
                49.474,
                49.479,
                49.483,
                49.488,
                49.492,
                49.497,
                49.502,
                49.507,
                49.511,
                49.516,
                49.52,
                49.525,
                49.529,
                49.534,
                49.539,
                49.543,
                49.548,
                49.552,
                49.557,
                49.561,
                49.566,
                49.571,
                49.575,
                49.58,
                49.584,
                49.589,
                49.593,
                49.598,
                49.602,
                49.607,
                49.611,
                49.616,
                49.62,
                49.625,
                49.629,
                49.633,
                49.638,
                49.643,
                49.647,
                49.651,
                49.656,
                49.66,
                49.664,
                49.669,
                49.674,
                49.678,
                49.682,
                49.687,
                49.691,
                49.695,
                49.7,
                49.704,
                49.709,
                49.713,
                49.717,
                49.722,
                49.726,
                49.73,
                49.735,
                49.739,
                49.743,
                49.748,
                49.752,
                49.756,
                49.76,
                49.765,
                49.769,
                49.773,
                49.778,
                49.782,
                49.786,
                49.79,
                49.794,
                49.799,
                49.803,
                49.808,
                49.812,
                49.816,
                49.82,
                49.824,
                49.829,
                49.833,
                49.837,
                49.841,
                49.845,
                49.849,
                49.853,
                49.858,
                49.862,
                49.866,
                49.87,
                49.874,
                49.879,
                49.883,
                49.887,
                49.891,
                49.895,
                49.899,
                49.903,
                49.907,
                49.911,
                49.915,
                49.92,
                49.924,
                49.928,
                49.932,
                49.936,
                49.94,
                49.944,
                49.948,
                49.952,
                49.956,
                49.96,
                49.964,
                49.968,
                49.972,
                49.977,
                49.98,
                49.984,
                49.988,
                49.992,
                49.996,
                50,
                50.005,
                50.008,
                50.012,
                50.016,
                50.02,
                50.024,
                50.028,
                50.032,
                50.036,
                50.04,
                50.044,
                50.048,
                50.051,
                50.055,
                50.06,
                50.063,
                50.067,
                50.071,
                50.075,
                50.079,
                50.082,
                50.086,
                50.09,
                50.094,
                50.098,
                50.102,
                50.106,
                50.109,
                50.113,
                50.117,
                50.121,
                50.125,
                50.129,
                50.132,
                50.136,
                50.14,
                50.144,
                50.148,
                50.151,
                50.155,
                50.159,
                50.162,
                50.166,
                50.17,
                50.174,
                50.178,
                50.181,
                50.185,
                50.189,
                50.192,
                50.196,
                50.2,
                50.204,
                50.207,
                50.211,
                50.214,
                50.218,
                50.222,
                50.226,
                50.229,
                50.233,
                50.237,
                50.24,
                50.244,
                50.248,
                50.251,
                50.255,
                50.258,
                50.262,
                50.266,
                50.269,
                50.273,
                50.277,
                50.28,
                50.284,
                50.287,
                50.291,
                50.294,
                50.298,
                50.302,
                50.305,
                50.309,
                50.312,
                50.316,
                50.319,
                50.322,
                50.326,
                50.33,
                50.333,
                50.337,
                50.34,
                50.344,
                50.347,
                50.351,
                50.354,
                50.358,
                50.361,
                50.365,
                50.368,
                50.371,
                50.375,
                50.379,
                50.382,
                50.385,
                50.389,
                50.392,
                50.395,
                50.399,
                50.403,
                50.406,
                50.409,
                50.413,
                50.416,
                50.419,
                50.423,
                50.426,
                50.43,
                50.433,
                50.436,
                50.44,
                50.443,
                50.446,
                50.45,
                50.453,
                50.457,
                50.46,
                50.463,
                50.466,
                50.47,
                50.473,
                50.477,
                50.48,
                50.483,
                50.486,
                50.489,
                50.493,
                50.496,
                50.5,
                50.503,
                50.506,
                50.509,
                50.512,
                50.516,
                50.519,
                50.522,
                50.526,
                50.529,
                50.532,
                50.535,
                50.538,
                50.541,
                50.545,
                50.548,
                50.551,
                50.554,
                50.557,
                50.561,
                50.564,
                50.567,
                50.57,
                50.573,
                50.577,
                50.58,
                50.583,
                50.586,
                50.589,
                50.593,
                50.596,
                50.599,
                50.602,
                50.605,
                50.608,
                50.611,
                50.614,
                50.617,
                50.62,
                50.623,
                50.626,
                50.63,
                50.633,
                50.636,
                50.639,
                50.642,
                50.645,
                50.648,
                50.651,
                50.654,
                50.657,
                50.66,
                50.663,
                50.666,
                50.669,
                50.672,
                50.675,
                50.678,
                50.681,
                50.685,
                50.688,
                50.691,
                50.693,
                50.696,
                50.699,
                50.702,
                50.706,
                50.709,
                50.711,
                50.714,
                50.717,
                50.72,
                50.723,
                50.726,
                50.729,
                50.732,
                50.735,
                50.738,
                50.741,
                50.744,
                50.746,
                50.749,
                50.753,
                50.755,
                50.758,
                50.761,
                50.764,
                50.767,
                50.77,
                50.773,
                50.776,
                50.779,
                50.781,
                50.784,
                50.787,
                50.79,
                50.793,
                50.796,
                50.799,
                50.801,
                50.804,
                50.807,
                50.81,
                50.813,
                50.815,
                50.819,
                50.821,
                50.824,
                50.827,
                50.83,
                50.832,
                50.835,
                50.838,
                50.841,
                50.844,
                50.846,
                50.849,
                50.852,
                50.855,
                50.857,
                50.86,
                50.863,
                50.866,
                50.869,
                50.871,
                50.874,
                50.877,
                50.879,
                50.883,
                50.885,
                50.888,
                50.891,
                50.893,
                50.896,
                50.898,
                50.901,
                50.904,
                50.907,
                50.91,
                50.912,
                50.915,
                50.917,
                50.92,
                50.923,
                50.926,
                50.928,
                50.931,
                50.934,
                50.936,
                50.939,
                50.941,
                50.944,
                50.947,
                50.95,
                50.952,
                50.955,
                50.957,
                50.96,
                50.963,
                50.965,
                50.968,
                50.971,
                50.973,
                50.976,
                50.978,
                50.981,
                50.984,
                50.986,
                50.989,
                50.992,
                50.994,
                50.997,
                50.999,
                51.002,
                51.004,
                51.007,
                51.01,
                51.012,
                51.015,
                51.017,
                51.02,
                51.022,
                51.025,
                51.027,
                51.03,
                51.033,
                51.035,
                51.038,
                51.04,
                51.043,
                51.045,
                51.048,
                51.05,
                51.053,
                51.055,
                51.058,
                51.06,
                51.063,
                51.065,
                51.068,
                51.071,
                51.073,
                51.075,
                51.078,
                51.08,
                51.083,
                51.085,
                51.088,
                51.09,
                51.093,
                51.095,
                51.098,
                51.1,
                51.102,
                51.105,
                51.107,
                51.11,
                51.113,
                51.115,
                51.117,
                51.12,
                51.122,
                51.124,
                51.127,
                51.129,
                51.132,
                51.134,
                51.137,
                51.139,
                51.141,
                51.144,
                51.146,
                51.148,
                51.151,
                51.154,
                51.156,
                51.158,
                51.161,
                51.163,
                51.165,
                51.168,
                51.17,
                51.173,
                51.175,
                51.177,
                51.18,
                51.182,
                51.184,
                51.186,
                51.189,
                51.192,
                51.194,
                51.196,
                51.198,
                51.201,
                51.203,
                51.205,
                51.208,
                51.21,
                51.213,
                51.215,
                51.217,
                51.219,
                51.222,
                51.224,
                51.226,
                51.228,
                51.231,
                51.233,
                51.236,
                51.238,
                51.24,
                51.242,
                51.245,
                51.247,
                51.249,
                51.252,
                51.254,
                51.256,
                51.258,
                51.261,
                51.263,
                51.265,
                51.267,
                51.269,
                51.272,
                51.274,
                51.277,
                51.279,
                51.281,
                51.283,
                51.285,
                51.288,
                51.29,
                51.292,
                51.295,
                51.297,
                51.299,
                51.301,
                51.303,
                51.305,
                51.308,
                51.31,
                51.312,
                51.315,
                51.317,
                51.319,
                51.321,
                51.323,
                51.325,
                51.327,
                51.33,
                51.332,
                51.334,
                51.336,
                51.339,
                51.341,
                51.343,
                51.345,
                51.347,
                51.35,
                51.352,
                51.354,
                51.356,
                51.358,
                51.36,
                51.362,
                51.364,
                51.367,
                51.369,
                51.371,
                51.373,
                51.375,
                51.378,
                51.38,
                51.382,
                51.384,
                51.386,
                51.388,
                51.391,
                51.393,
                51.395,
                51.397,
                51.399,
                51.401,
                51.403,
                51.405,
                51.408,
                51.41,
                51.412,
                51.414,
                51.416,
                51.418,
                51.42,
                51.422,
                51.424,
                51.426,
                51.428,
                51.431,
                51.433,
                51.435,
                51.437,
                51.439,
                51.441,
                51.443,
                51.445,
                51.447,
                51.449,
                51.451,
                51.453,
                51.455,
                51.457,
                51.459,
                51.461,
                51.464,
                51.466,
                51.468,
                51.47,
                51.472,
                51.474,
                51.476,
                51.478,
                51.48,
                51.482,
                51.484,
                51.486,
                51.488,
                51.49,
                51.492,
                51.494,
                51.496,
                51.498,
                51.5,
                51.502,
                51.504,
                51.506,
                51.508,
                51.51,
                51.512,
                51.514,
                51.516,
                51.518,
                51.52,
                51.522,
                51.524,
                51.526,
                51.528,
                51.53,
                51.532,
                51.534,
                51.536,
                51.538,
                51.54,
                51.542,
                51.544,
                51.546,
                51.548,
                51.55,
                51.552,
                51.553,
                51.556,
                51.558,
                51.56,
                51.562,
                51.563,
                51.565,
                51.567,
                51.569,
                51.571,
                51.573,
                51.575,
                51.577,
                51.579,
                51.581,
                51.583,
                51.585,
                51.586,
                51.588,
                51.59,
                51.593,
                51.594,
                51.596,
                51.598,
                51.6,
                51.602,
                51.604,
                51.606,
                51.607,
                51.609,
                51.612,
                51.613,
                51.615,
                51.617,
                51.619,
                51.621,
                51.623,
                51.624,
                51.626,
                51.628,
                51.63,
                51.632,
                51.634,
                51.636,
                51.638,
                51.64,
                51.641,
                51.643,
                51.645,
                51.647,
                51.649,
                51.651,
                51.653,
                51.654,
                51.656,
                51.658,
                51.66,
                51.662,
                51.663,
                51.666,
                51.668,
                51.669,
                51.671,
                51.673,
                51.675,
                51.676,
                51.678,
                51.68,
                51.682,
                51.684,
                51.686,
                51.688,
                51.689,
                51.691,
                51.693,
                51.695,
                51.696,
                51.698,
                51.7,
                51.702,
                51.704,
                51.706,
                51.707,
                51.709,
                51.711,
                51.713,
                51.714,
                51.716,
                51.718,
                51.72,
                51.722,
                51.724,
                51.725,
                51.727,
                51.729,
                51.73,
                51.732,
                51.734,
                51.736,
                51.738,
                51.74,
                51.741,
                51.743,
                51.745,
                51.746,
                51.748,
                51.75,
                51.751,
                51.754,
                51.755,
                51.757,
                51.759,
                51.761,
                51.762,
                51.764,
                51.766,
                51.767,
                51.769,
                51.771,
                51.773,
                51.774,
                51.776,
                51.778,
                51.779,
                51.781,
                51.783,
                51.785,
                51.786,
                51.788,
                51.79,
                51.792,
                51.793,
                51.795,
                51.797,
                51.798,
                51.8,
                51.802,
                51.803,
                51.805,
                51.807,
                51.809,
                51.81,
                51.812,
                51.814,
                51.815,
                51.817,
                51.819,
                51.82,
                51.822,
                51.824,
                51.826,
                51.827,
                51.829,
                51.831,
                51.832,
                51.834,
                51.835,
                51.837,
                51.839,
                51.841,
                51.842,
                51.844,
                51.846,
                51.847,
                51.849,
                51.85,
                51.852,
                51.854,
                51.855,
                51.857,
                51.859,
                51.861,
                51.862,
                51.864,
                51.865,
                51.867,
                51.869,
                51.87,
                51.872,
                51.873,
                51.876,
                51.877,
                51.879,
                51.88,
                51.882,
                51.883,
                51.885,
                51.887,
                51.888,
                51.89,
                51.891,
                51.893,
                51.895,
                51.897,
                51.898,
                51.9,
                51.901,
                51.903,
                51.904,
                51.906,
                51.907,
                51.91,
                51.911,
                51.913,
                51.914,
                51.916,
                51.917,
                51.919,
                51.92,
                51.922,
                51.924,
                51.925,
                51.927,
                51.929,
                51.93,
                51.932,
                51.933,
                51.935,
                51.936,
                51.938,
                51.939,
                51.941,
                51.943,
                51.945,
                51.946,
                51.948,
                51.949,
                51.951,
                51.952,
                51.954,
                51.955,
                51.957,
                51.958,
                51.96,
                51.962,
                51.963,
                51.965,
                51.966,
                51.968,
                51.969,
                51.971,
                51.972,
                51.974,
                51.975,
                51.977,
                51.979,
                51.98,
                51.982,
                51.983,
                51.985,
                51.986,
                51.988,
                51.989,
                51.991,
                51.992,
                51.994,
                51.996,
                51.997,
                51.999,
                52,
                52.002,
                52.003,
                52.005,
                52.006,
                52.008,
                52.01,
                52.011,
                52.013,
                52.014,
                52.016,
                52.017,
                52.019,
                52.02,
                52.022,
                52.023,
                52.024,
                52.026,
                52.028,
                52.029,
                52.031,
                52.032,
                52.034,
                52.035,
                52.037,
                52.038,
                52.04,
                52.041,
                52.043,
                52.044,
                52.046,
                52.047,
                52.049,
                52.05,
                52.052,
                52.053,
                52.055,
                52.056,
                52.057,
                52.059,
                52.061,
                52.062,
                52.064,
                52.065,
                52.067,
                52.068,
                52.07,
                52.071,
                52.072,
                52.074,
                52.076,
                52.077,
                52.079,
                52.08,
                52.081,
                52.083,
                52.084,
                52.086,
                52.087,
                52.089,
                52.09,
                52.091,
                52.093,
                52.095,
                52.096,
                52.098,
                52.099,
                52.1,
                52.102,
                52.103,
                52.105,
                52.106,
                52.107,
                52.109,
                52.111,
                52.112,
                52.114,
                52.115,
                52.116,
                52.118,
                52.119,
                52.121,
                52.122,
                52.123,
                52.125,
                52.127,
                52.128,
                52.13,
                52.131,
                52.132,
                52.134,
                52.135,
                52.137,
                52.138,
                52.139,
                52.141,
                52.143,
                52.144,
                52.145,
                52.147,
                52.148,
                52.15,
                52.151,
                52.152,
                52.154,
                52.155,
                52.156,
                52.158,
                52.16,
                52.161,
                52.163,
                52.164,
                52.165,
                52.167,
                52.168,
                52.169,
                52.171,
                52.172,
                52.174,
                52.175,
                52.177,
                52.178,
                52.179,
                52.181,
                52.182,
                52.184,
                52.185,
                52.186,
                52.188,
                52.19,
                52.191,
                52.192,
                52.194,
                52.195,
                52.196,
                52.198,
                52.199,
                52.2,
                52.202,
                52.203,
                52.204,
                52.206,
                52.208,
                52.209,
                52.21,
                52.212,
                52.213,
                52.214,
                52.216,
                52.217,
                52.219,
                52.22,
                52.221,
                52.223,
                52.224,
                52.226,
                52.227,
                52.228,
                52.23,
                52.231,
                52.232,
                52.234,
                52.235,
                52.236,
                52.238,
                52.24,
                52.241,
                52.242,
                52.244,
                52.245,
                52.246,
                52.248,
                52.249,
                52.25,
                52.252,
                52.253,
                52.255,
                52.256,
                52.257,
                52.259,
                52.26,
                52.262,
                52.263,
                52.264,
                52.266,
                52.267,
                52.268,
                52.269,
                52.271,
                52.273,
                52.274,
                52.275,
                52.277,
                52.278,
                52.279,
                52.281,
                52.282,
                52.283,
                52.285,
                52.286,
                52.288,
                52.289,
                52.29,
                52.292,
                52.293,
                52.294,
                52.296,
                52.297,
                52.298,
                52.299,
                52.301,
                52.303,
                52.304,
                52.305,
                52.306,
                52.308,
                52.309,
                52.31,
                52.312,
                52.313,
                52.314,
                52.316,
                52.317,
                52.319,
                52.32,
                52.321,
                52.323,
                52.324,
                52.325,
                52.327
            ],
            P90: [
                36.09,
                36.181,
                36.272,
                36.364,
                36.455,
                36.546,
                36.637,
                36.728,
                36.819,
                36.91,
                37,
                37.091,
                37.181,
                37.272,
                37.362,
                37.462,
                37.56,
                37.656,
                37.749,
                37.841,
                37.93,
                38.018,
                38.104,
                38.188,
                38.271,
                38.353,
                38.433,
                38.511,
                38.589,
                38.665,
                38.74,
                38.814,
                38.886,
                38.958,
                39.028,
                39.098,
                39.166,
                39.234,
                39.301,
                39.367,
                39.432,
                39.496,
                39.559,
                39.622,
                39.683,
                39.745,
                39.805,
                39.865,
                39.924,
                39.982,
                40.04,
                40.097,
                40.153,
                40.209,
                40.265,
                40.32,
                40.374,
                40.427,
                40.481,
                40.533,
                40.586,
                40.638,
                40.689,
                40.74,
                40.791,
                40.841,
                40.891,
                40.94,
                40.989,
                41.038,
                41.086,
                41.133,
                41.181,
                41.228,
                41.274,
                41.32,
                41.366,
                41.412,
                41.457,
                41.502,
                41.547,
                41.59,
                41.634,
                41.678,
                41.721,
                41.764,
                41.807,
                41.849,
                41.891,
                41.933,
                41.974,
                42.015,
                42.056,
                42.097,
                42.137,
                42.177,
                42.217,
                42.257,
                42.297,
                42.336,
                42.374,
                42.413,
                42.452,
                42.49,
                42.527,
                42.565,
                42.603,
                42.64,
                42.677,
                42.713,
                42.75,
                42.786,
                42.822,
                42.858,
                42.894,
                42.929,
                42.964,
                42.999,
                43.034,
                43.068,
                43.103,
                43.136,
                43.171,
                43.204,
                43.238,
                43.271,
                43.304,
                43.337,
                43.37,
                43.402,
                43.434,
                43.467,
                43.499,
                43.53,
                43.562,
                43.593,
                43.625,
                43.656,
                43.686,
                43.717,
                43.748,
                43.778,
                43.808,
                43.838,
                43.868,
                43.897,
                43.926,
                43.956,
                43.985,
                44.014,
                44.043,
                44.071,
                44.1,
                44.128,
                44.156,
                44.184,
                44.212,
                44.239,
                44.266,
                44.294,
                44.321,
                44.348,
                44.374,
                44.401,
                44.428,
                44.454,
                44.481,
                44.506,
                44.532,
                44.558,
                44.584,
                44.609,
                44.635,
                44.659,
                44.685,
                44.709,
                44.734,
                44.759,
                44.783,
                44.808,
                44.832,
                44.856,
                44.88,
                44.904,
                44.927,
                44.951,
                44.974,
                44.998,
                45.021,
                45.044,
                45.067,
                45.09,
                45.112,
                45.135,
                45.157,
                45.179,
                45.201,
                45.223,
                45.245,
                45.267,
                45.289,
                45.31,
                45.332,
                45.353,
                45.374,
                45.395,
                45.416,
                45.437,
                45.458,
                45.478,
                45.499,
                45.519,
                45.54,
                45.559,
                45.58,
                45.6,
                45.619,
                45.639,
                45.659,
                45.678,
                45.698,
                45.717,
                45.736,
                45.755,
                45.774,
                45.794,
                45.812,
                45.831,
                45.85,
                45.868,
                45.886,
                45.905,
                45.923,
                45.941,
                45.959,
                45.977,
                45.995,
                46.013,
                46.031,
                46.048,
                46.065,
                46.083,
                46.1,
                46.118,
                46.134,
                46.151,
                46.168,
                46.185,
                46.202,
                46.219,
                46.235,
                46.252,
                46.268,
                46.285,
                46.301,
                46.317,
                46.333,
                46.349,
                46.365,
                46.381,
                46.397,
                46.413,
                46.428,
                46.444,
                46.46,
                46.475,
                46.491,
                46.506,
                46.521,
                46.536,
                46.551,
                46.566,
                46.581,
                46.596,
                46.611,
                46.626,
                46.64,
                46.655,
                46.669,
                46.683,
                46.698,
                46.712,
                46.727,
                46.741,
                46.755,
                46.769,
                46.783,
                46.797,
                46.811,
                46.825,
                46.838,
                46.851,
                46.865,
                46.879,
                46.892,
                46.906,
                46.919,
                46.932,
                46.946,
                46.959,
                46.972,
                46.985,
                46.998,
                47.011,
                47.024,
                47.037,
                47.049,
                47.061,
                47.074,
                47.087,
                47.099,
                47.112,
                47.124,
                47.136,
                47.149,
                47.161,
                47.173,
                47.185,
                47.197,
                47.209,
                47.221,
                47.233,
                47.245,
                47.257,
                47.269,
                47.281,
                47.292,
                47.304,
                47.315,
                47.327,
                47.338,
                47.35,
                47.361,
                47.372,
                47.384,
                47.395,
                47.406,
                47.417,
                47.428,
                47.439,
                47.45,
                47.461,
                47.471,
                47.482,
                47.493,
                47.504,
                47.515,
                47.525,
                47.536,
                47.546,
                47.557,
                47.567,
                47.578,
                47.588,
                47.599,
                47.609,
                47.619,
                47.629,
                47.639,
                47.65,
                47.66,
                47.67,
                47.68,
                47.69,
                47.7,
                47.71,
                47.72,
                47.73,
                47.74,
                47.749,
                47.759,
                47.769,
                47.778,
                47.788,
                47.797,
                47.807,
                47.816,
                47.826,
                47.835,
                47.845,
                47.854,
                47.863,
                47.872,
                47.882,
                47.891,
                47.9,
                47.909,
                47.918,
                47.927,
                47.936,
                47.945,
                47.954,
                47.963,
                47.972,
                47.981,
                47.99,
                47.998,
                48.007,
                48.016,
                48.025,
                48.033,
                48.042,
                48.051,
                48.06,
                48.068,
                48.077,
                48.085,
                48.094,
                48.102,
                48.111,
                48.119,
                48.127,
                48.136,
                48.144,
                48.152,
                48.16,
                48.169,
                48.177,
                48.185,
                48.193,
                48.201,
                48.209,
                48.217,
                48.225,
                48.233,
                48.242,
                48.25,
                48.257,
                48.265,
                48.273,
                48.281,
                48.289,
                48.297,
                48.304,
                48.312,
                48.32,
                48.327,
                48.335,
                48.343,
                48.35,
                48.358,
                48.366,
                48.373,
                48.381,
                48.389,
                48.396,
                48.403,
                48.411,
                48.418,
                48.426,
                48.433,
                48.44,
                48.448,
                48.455,
                48.462,
                48.469,
                48.477,
                48.484,
                48.491,
                48.499,
                48.506,
                48.513,
                48.52,
                48.527,
                48.534,
                48.541,
                48.548,
                48.555,
                48.562,
                48.569,
                48.576,
                48.583,
                48.59,
                48.598,
                48.604,
                48.611,
                48.618,
                48.625,
                48.632,
                48.638,
                48.645,
                48.652,
                48.659,
                48.665,
                48.672,
                48.679,
                48.686,
                48.693,
                48.699,
                48.706,
                48.712,
                48.719,
                48.725,
                48.732,
                48.739,
                48.745,
                48.752,
                48.758,
                48.764,
                48.771,
                48.778,
                48.784,
                48.791,
                48.797,
                48.803,
                48.81,
                48.816,
                48.822,
                48.829,
                48.835,
                48.841,
                48.848,
                48.854,
                48.86,
                48.867,
                48.873,
                48.879,
                48.885,
                48.891,
                48.898,
                48.904,
                48.91,
                48.916,
                48.923,
                48.929,
                48.935,
                48.941,
                48.947,
                48.953,
                48.959,
                48.965,
                48.971,
                48.977,
                48.983,
                48.989,
                48.995,
                49.001,
                49.007,
                49.013,
                49.019,
                49.025,
                49.03,
                49.036,
                49.042,
                49.048,
                49.054,
                49.06,
                49.066,
                49.072,
                49.077,
                49.083,
                49.089,
                49.095,
                49.1,
                49.106,
                49.112,
                49.118,
                49.124,
                49.129,
                49.135,
                49.141,
                49.146,
                49.152,
                49.158,
                49.163,
                49.169,
                49.175,
                49.18,
                49.186,
                49.191,
                49.197,
                49.203,
                49.208,
                49.214,
                49.219,
                49.225,
                49.231,
                49.236,
                49.242,
                49.247,
                49.252,
                49.258,
                49.263,
                49.269,
                49.275,
                49.28,
                49.286,
                49.291,
                49.296,
                49.302,
                49.307,
                49.312,
                49.318,
                49.324,
                49.329,
                49.334,
                49.339,
                49.345,
                49.35,
                49.355,
                49.361,
                49.366,
                49.371,
                49.377,
                49.382,
                49.387,
                49.393,
                49.398,
                49.403,
                49.408,
                49.413,
                49.419,
                49.424,
                49.429,
                49.435,
                49.44,
                49.445,
                49.45,
                49.455,
                49.46,
                49.466,
                49.471,
                49.476,
                49.481,
                49.486,
                49.491,
                49.496,
                49.501,
                49.506,
                49.512,
                49.517,
                49.522,
                49.527,
                49.532,
                49.537,
                49.542,
                49.547,
                49.553,
                49.558,
                49.563,
                49.568,
                49.573,
                49.578,
                49.582,
                49.587,
                49.592,
                49.598,
                49.603,
                49.608,
                49.613,
                49.617,
                49.622,
                49.627,
                49.632,
                49.637,
                49.642,
                49.647,
                49.652,
                49.657,
                49.662,
                49.666,
                49.671,
                49.677,
                49.682,
                49.686,
                49.691,
                49.696,
                49.701,
                49.705,
                49.71,
                49.715,
                49.72,
                49.725,
                49.73,
                49.734,
                49.739,
                49.744,
                49.749,
                49.754,
                49.759,
                49.763,
                49.768,
                49.773,
                49.777,
                49.782,
                49.787,
                49.792,
                49.797,
                49.801,
                49.806,
                49.81,
                49.815,
                49.82,
                49.824,
                49.83,
                49.834,
                49.839,
                49.843,
                49.848,
                49.852,
                49.857,
                49.862,
                49.867,
                49.871,
                49.876,
                49.88,
                49.885,
                49.889,
                49.894,
                49.899,
                49.904,
                49.908,
                49.913,
                49.917,
                49.922,
                49.926,
                49.931,
                49.936,
                49.94,
                49.945,
                49.949,
                49.953,
                49.958,
                49.962,
                49.967,
                49.972,
                49.976,
                49.981,
                49.985,
                49.989,
                49.994,
                49.998,
                50.003,
                50.008,
                50.012,
                50.016,
                50.021,
                50.025,
                50.029,
                50.034,
                50.039,
                50.043,
                50.047,
                50.052,
                50.056,
                50.06,
                50.064,
                50.069,
                50.074,
                50.078,
                50.082,
                50.086,
                50.091,
                50.095,
                50.1,
                50.104,
                50.108,
                50.113,
                50.117,
                50.121,
                50.125,
                50.129,
                50.134,
                50.138,
                50.143,
                50.147,
                50.151,
                50.155,
                50.159,
                50.164,
                50.168,
                50.173,
                50.177,
                50.181,
                50.185,
                50.189,
                50.194,
                50.198,
                50.202,
                50.206,
                50.21,
                50.214,
                50.218,
                50.223,
                50.227,
                50.231,
                50.235,
                50.24,
                50.244,
                50.248,
                50.252,
                50.256,
                50.26,
                50.264,
                50.268,
                50.272,
                50.277,
                50.28,
                50.285,
                50.289,
                50.293,
                50.297,
                50.301,
                50.305,
                50.309,
                50.314,
                50.318,
                50.321,
                50.326,
                50.329,
                50.333,
                50.337,
                50.342,
                50.346,
                50.35,
                50.354,
                50.358,
                50.361,
                50.365,
                50.37,
                50.374,
                50.378,
                50.381,
                50.385,
                50.389,
                50.393,
                50.398,
                50.401,
                50.405,
                50.409,
                50.413,
                50.417,
                50.421,
                50.424,
                50.429,
                50.433,
                50.436,
                50.44,
                50.444,
                50.448,
                50.452,
                50.456,
                50.46,
                50.463,
                50.467,
                50.471,
                50.475,
                50.478,
                50.483,
                50.487,
                50.49,
                50.494,
                50.498,
                50.501,
                50.505,
                50.509,
                50.513,
                50.517,
                50.52,
                50.524,
                50.528,
                50.532,
                50.536,
                50.539,
                50.543,
                50.547,
                50.55,
                50.554,
                50.558,
                50.562,
                50.566,
                50.569,
                50.573,
                50.576,
                50.58,
                50.584,
                50.588,
                50.591,
                50.595,
                50.599,
                50.602,
                50.606,
                50.609,
                50.613,
                50.617,
                50.62,
                50.624,
                50.628,
                50.631,
                50.635,
                50.638,
                50.642,
                50.646,
                50.649,
                50.653,
                50.656,
                50.66,
                50.663,
                50.667,
                50.671,
                50.674,
                50.678,
                50.681,
                50.685,
                50.688,
                50.692,
                50.696,
                50.699,
                50.702,
                50.706,
                50.709,
                50.713,
                50.717,
                50.72,
                50.724,
                50.727,
                50.73,
                50.734,
                50.737,
                50.741,
                50.744,
                50.748,
                50.751,
                50.755,
                50.758,
                50.761,
                50.764,
                50.769,
                50.772,
                50.775,
                50.778,
                50.782,
                50.785,
                50.788,
                50.792,
                50.796,
                50.799,
                50.802,
                50.805,
                50.809,
                50.812,
                50.816,
                50.819,
                50.822,
                50.826,
                50.829,
                50.832,
                50.835,
                50.839,
                50.842,
                50.846,
                50.849,
                50.852,
                50.855,
                50.859,
                50.862,
                50.866,
                50.869,
                50.872,
                50.875,
                50.878,
                50.881,
                50.885,
                50.888,
                50.892,
                50.895,
                50.898,
                50.901,
                50.904,
                50.907,
                50.91,
                50.914,
                50.917,
                50.92,
                50.923,
                50.927,
                50.93,
                50.933,
                50.937,
                50.94,
                50.943,
                50.946,
                50.949,
                50.952,
                50.955,
                50.959,
                50.962,
                50.965,
                50.968,
                50.971,
                50.974,
                50.977,
                50.98,
                50.984,
                50.987,
                50.99,
                50.993,
                50.996,
                50.999,
                51.002,
                51.005,
                51.008,
                51.011,
                51.014,
                51.017,
                51.02,
                51.023,
                51.026,
                51.03,
                51.033,
                51.036,
                51.039,
                51.042,
                51.044,
                51.047,
                51.051,
                51.054,
                51.057,
                51.06,
                51.063,
                51.066,
                51.068,
                51.071,
                51.075,
                51.078,
                51.081,
                51.084,
                51.086,
                51.089,
                51.092,
                51.095,
                51.099,
                51.101,
                51.104,
                51.107,
                51.11,
                51.113,
                51.116,
                51.119,
                51.122,
                51.125,
                51.128,
                51.13,
                51.133,
                51.136,
                51.139,
                51.142,
                51.145,
                51.148,
                51.151,
                51.154,
                51.156,
                51.159,
                51.162,
                51.165,
                51.168,
                51.171,
                51.174,
                51.176,
                51.179,
                51.182,
                51.185,
                51.188,
                51.191,
                51.193,
                51.196,
                51.199,
                51.202,
                51.204,
                51.208,
                51.211,
                51.213,
                51.216,
                51.219,
                51.221,
                51.224,
                51.227,
                51.23,
                51.233,
                51.235,
                51.238,
                51.241,
                51.243,
                51.246,
                51.249,
                51.252,
                51.255,
                51.257,
                51.26,
                51.263,
                51.265,
                51.268,
                51.271,
                51.274,
                51.276,
                51.279,
                51.282,
                51.284,
                51.287,
                51.29,
                51.292,
                51.295,
                51.298,
                51.301,
                51.303,
                51.306,
                51.308,
                51.311,
                51.314,
                51.317,
                51.319,
                51.322,
                51.325,
                51.327,
                51.33,
                51.332,
                51.335,
                51.338,
                51.34,
                51.343,
                51.346,
                51.348,
                51.351,
                51.353,
                51.356,
                51.359,
                51.361,
                51.364,
                51.366,
                51.369,
                51.371,
                51.374,
                51.376,
                51.38,
                51.382,
                51.385,
                51.387,
                51.39,
                51.392,
                51.395,
                51.397,
                51.4,
                51.403,
                51.405,
                51.408,
                51.41,
                51.412,
                51.415,
                51.417,
                51.42,
                51.423,
                51.425,
                51.428,
                51.43,
                51.433,
                51.435,
                51.437,
                51.44,
                51.443,
                51.445,
                51.448,
                51.45,
                51.453,
                51.455,
                51.457,
                51.46,
                51.463,
                51.465,
                51.468,
                51.47,
                51.472,
                51.475,
                51.477,
                51.48,
                51.483,
                51.485,
                51.487,
                51.49,
                51.492,
                51.494,
                51.497,
                51.499,
                51.502,
                51.505,
                51.507,
                51.509,
                51.511,
                51.514,
                51.516,
                51.519,
                51.521,
                51.524,
                51.526,
                51.528,
                51.531,
                51.533,
                51.535,
                51.538,
                51.54,
                51.543,
                51.545,
                51.548,
                51.55,
                51.552,
                51.554,
                51.557,
                51.559,
                51.561,
                51.564,
                51.566,
                51.569,
                51.571,
                51.573,
                51.576,
                51.578,
                51.58,
                51.583,
                51.585,
                51.588,
                51.59,
                51.592,
                51.594,
                51.597,
                51.599,
                51.601,
                51.604,
                51.606,
                51.608,
                51.611,
                51.613,
                51.615,
                51.617,
                51.619,
                51.622,
                51.625,
                51.627,
                51.629,
                51.631,
                51.633,
                51.636,
                51.638,
                51.64,
                51.643,
                51.645,
                51.647,
                51.649,
                51.652,
                51.654,
                51.656,
                51.658,
                51.66,
                51.663,
                51.665,
                51.667,
                51.67,
                51.672,
                51.674,
                51.676,
                51.678,
                51.68,
                51.683,
                51.685,
                51.687,
                51.69,
                51.692,
                51.694,
                51.696,
                51.698,
                51.7,
                51.703,
                51.705,
                51.707,
                51.709,
                51.712,
                51.714,
                51.716,
                51.718,
                51.72,
                51.723,
                51.725,
                51.727,
                51.729,
                51.731,
                51.733,
                51.735,
                51.738,
                51.74,
                51.742,
                51.744,
                51.747,
                51.749,
                51.751,
                51.753,
                51.755,
                51.757,
                51.759,
                51.762,
                51.764,
                51.766,
                51.768,
                51.77,
                51.772,
                51.774,
                51.776,
                51.778,
                51.781,
                51.783,
                51.785,
                51.787,
                51.789,
                51.791,
                51.793,
                51.795,
                51.797,
                51.8,
                51.802,
                51.804,
                51.806,
                51.808,
                51.81,
                51.812,
                51.814,
                51.816,
                51.819,
                51.821,
                51.823,
                51.825,
                51.827,
                51.829,
                51.831,
                51.833,
                51.835,
                51.837,
                51.839,
                51.841,
                51.843,
                51.845,
                51.847,
                51.849,
                51.851,
                51.853,
                51.856,
                51.858,
                51.86,
                51.862,
                51.864,
                51.866,
                51.867,
                51.869,
                51.871,
                51.873,
                51.876,
                51.878,
                51.88,
                51.882,
                51.884,
                51.886,
                51.888,
                51.89,
                51.891,
                51.894,
                51.896,
                51.898,
                51.9,
                51.902,
                51.904,
                51.906,
                51.908,
                51.909,
                51.912,
                51.914,
                51.916,
                51.918,
                51.92,
                51.921,
                51.923,
                51.925,
                51.927,
                51.929,
                51.932,
                51.934,
                51.935,
                51.937,
                51.939,
                51.941,
                51.943,
                51.945,
                51.947,
                51.949,
                51.951,
                51.953,
                51.955,
                51.957,
                51.959,
                51.96,
                51.962,
                51.964,
                51.966,
                51.969,
                51.97,
                51.972,
                51.974,
                51.976,
                51.978,
                51.98,
                51.981,
                51.983,
                51.985,
                51.988,
                51.989,
                51.991,
                51.993,
                51.995,
                51.997,
                51.999,
                52,
                52.002,
                52.005,
                52.007,
                52.008,
                52.01,
                52.012,
                52.014,
                52.016,
                52.017,
                52.019,
                52.021,
                52.023,
                52.025,
                52.027,
                52.029,
                52.031,
                52.032,
                52.034,
                52.036,
                52.038,
                52.04,
                52.042,
                52.044,
                52.045,
                52.047,
                52.049,
                52.051,
                52.053,
                52.054,
                52.056,
                52.058,
                52.06,
                52.062,
                52.064,
                52.065,
                52.067,
                52.069,
                52.071,
                52.073,
                52.074,
                52.077,
                52.078,
                52.08,
                52.082,
                52.084,
                52.085,
                52.087,
                52.089,
                52.091,
                52.092,
                52.095,
                52.096,
                52.098,
                52.1,
                52.102,
                52.103,
                52.105,
                52.107,
                52.108,
                52.11,
                52.113,
                52.114,
                52.116,
                52.118,
                52.119,
                52.121,
                52.123,
                52.124,
                52.126,
                52.128,
                52.13,
                52.132,
                52.134,
                52.135,
                52.137,
                52.139,
                52.14,
                52.142,
                52.144,
                52.145,
                52.147,
                52.149,
                52.151,
                52.153,
                52.154,
                52.156,
                52.158,
                52.159,
                52.161,
                52.163,
                52.164,
                52.167,
                52.168,
                52.17,
                52.172,
                52.173,
                52.175,
                52.177,
                52.178,
                52.18,
                52.182,
                52.184,
                52.185,
                52.187,
                52.189,
                52.19,
                52.192,
                52.194,
                52.195,
                52.197,
                52.199,
                52.201,
                52.202,
                52.204,
                52.206,
                52.207,
                52.209,
                52.211,
                52.212,
                52.214,
                52.215,
                52.217,
                52.219,
                52.221,
                52.223,
                52.224,
                52.226,
                52.227,
                52.229,
                52.231,
                52.232,
                52.234,
                52.236,
                52.238,
                52.239,
                52.241,
                52.242,
                52.244,
                52.246,
                52.247,
                52.249,
                52.25,
                52.252,
                52.254,
                52.256,
                52.257,
                52.259,
                52.26,
                52.262,
                52.264,
                52.265,
                52.267,
                52.268,
                52.271,
                52.272,
                52.274,
                52.275,
                52.277,
                52.278,
                52.28,
                52.282,
                52.283,
                52.285,
                52.286,
                52.288,
                52.29,
                52.292,
                52.293,
                52.295,
                52.296,
                52.298,
                52.299,
                52.301,
                52.302,
                52.305,
                52.306,
                52.308,
                52.309,
                52.311,
                52.312,
                52.314,
                52.315,
                52.317,
                52.318,
                52.32,
                52.322,
                52.324,
                52.325,
                52.327,
                52.328,
                52.33,
                52.331,
                52.333,
                52.334,
                52.336,
                52.337,
                52.339,
                52.341,
                52.342,
                52.344,
                52.345,
                52.347,
                52.348,
                52.35,
                52.351,
                52.353,
                52.354,
                52.357,
                52.358,
                52.36,
                52.361,
                52.363,
                52.364,
                52.366,
                52.367,
                52.369,
                52.37,
                52.372,
                52.374,
                52.375,
                52.377,
                52.378,
                52.38,
                52.381,
                52.383,
                52.384,
                52.386,
                52.387,
                52.389,
                52.391,
                52.392,
                52.394,
                52.395,
                52.396,
                52.398,
                52.399,
                52.401,
                52.402,
                52.404,
                52.406,
                52.407,
                52.409,
                52.41,
                52.412,
                52.413,
                52.415,
                52.416,
                52.418,
                52.419,
                52.42,
                52.423,
                52.424,
                52.425,
                52.427,
                52.428,
                52.43,
                52.431,
                52.433,
                52.434,
                52.436,
                52.437,
                52.439,
                52.441,
                52.442,
                52.443,
                52.445,
                52.446,
                52.448,
                52.449,
                52.451,
                52.452,
                52.453,
                52.455,
                52.457,
                52.458,
                52.46,
                52.461,
                52.463,
                52.464,
                52.466,
                52.467,
                52.468,
                52.47,
                52.471,
                52.473,
                52.475,
                52.476,
                52.478,
                52.479,
                52.48,
                52.482,
                52.483,
                52.485,
                52.486,
                52.487,
                52.489,
                52.491,
                52.492,
                52.494,
                52.495,
                52.497,
                52.498,
                52.499,
                52.501,
                52.502,
                52.503,
                52.506,
                52.507,
                52.508,
                52.51,
                52.511,
                52.513,
                52.514,
                52.515,
                52.517,
                52.518,
                52.519,
                52.521,
                52.523,
                52.524,
                52.526,
                52.527,
                52.528,
                52.53,
                52.531,
                52.533,
                52.534,
                52.535,
                52.537,
                52.539,
                52.54,
                52.542,
                52.543,
                52.544,
                52.546,
                52.547,
                52.548,
                52.55,
                52.551,
                52.553,
                52.555,
                52.556,
                52.557,
                52.559,
                52.56,
                52.561,
                52.563,
                52.564,
                52.566,
                52.567,
                52.568,
                52.57,
                52.572,
                52.573,
                52.574,
                52.576,
                52.577,
                52.578,
                52.58,
                52.581,
                52.582,
                52.584,
                52.585,
                52.587,
                52.589,
                52.59,
                52.591,
                52.593,
                52.594,
                52.595,
                52.597,
                52.598,
                52.599,
                52.601,
                52.602,
                52.604,
                52.605,
                52.607,
                52.608,
                52.609,
                52.611,
                52.612,
                52.613,
                52.615,
                52.616,
                52.617,
                52.619,
                52.621,
                52.622,
                52.623,
                52.625,
                52.626,
                52.627,
                52.629,
                52.63,
                52.632,
                52.633,
                52.634,
                52.635,
                52.637,
                52.639,
                52.64,
                52.641,
                52.643,
                52.644,
                52.645,
                52.647,
                52.648,
                52.649,
                52.651,
                52.652,
                52.654,
                52.655,
                52.657,
                52.658,
                52.659,
                52.661,
                52.662,
                52.663,
                52.665,
                52.666,
                52.667,
                52.669,
                52.671,
                52.672,
                52.673,
                52.674,
                52.676,
                52.677,
                52.678,
                52.68,
                52.681,
                52.682,
                52.684,
                52.686,
                52.687,
                52.688,
                52.69,
                52.691,
                52.692,
                52.693
            ],
            P95: [
                36.551,
                36.64,
                36.729,
                36.818,
                36.906,
                36.995,
                37.083,
                37.172,
                37.259,
                37.348,
                37.436,
                37.524,
                37.612,
                37.699,
                37.787,
                37.887,
                37.985,
                38.08,
                38.173,
                38.265,
                38.355,
                38.442,
                38.528,
                38.613,
                38.696,
                38.777,
                38.857,
                38.935,
                39.013,
                39.089,
                39.164,
                39.238,
                39.31,
                39.382,
                39.453,
                39.523,
                39.591,
                39.659,
                39.725,
                39.791,
                39.856,
                39.92,
                39.984,
                40.047,
                40.108,
                40.17,
                40.23,
                40.29,
                40.349,
                40.407,
                40.465,
                40.522,
                40.579,
                40.635,
                40.69,
                40.745,
                40.8,
                40.853,
                40.907,
                40.959,
                41.012,
                41.064,
                41.115,
                41.167,
                41.217,
                41.268,
                41.318,
                41.367,
                41.416,
                41.465,
                41.513,
                41.56,
                41.608,
                41.655,
                41.702,
                41.748,
                41.794,
                41.84,
                41.885,
                41.93,
                41.975,
                42.019,
                42.063,
                42.107,
                42.149,
                42.193,
                42.236,
                42.278,
                42.32,
                42.362,
                42.403,
                42.445,
                42.486,
                42.527,
                42.567,
                42.607,
                42.647,
                42.688,
                42.727,
                42.766,
                42.805,
                42.844,
                42.883,
                42.921,
                42.958,
                42.997,
                43.034,
                43.071,
                43.109,
                43.145,
                43.182,
                43.218,
                43.255,
                43.29,
                43.326,
                43.361,
                43.397,
                43.432,
                43.467,
                43.502,
                43.536,
                43.57,
                43.604,
                43.638,
                43.672,
                43.705,
                43.739,
                43.772,
                43.805,
                43.837,
                43.869,
                43.902,
                43.934,
                43.966,
                43.998,
                44.029,
                44.061,
                44.092,
                44.123,
                44.154,
                44.184,
                44.215,
                44.245,
                44.275,
                44.305,
                44.335,
                44.364,
                44.393,
                44.423,
                44.452,
                44.481,
                44.51,
                44.538,
                44.566,
                44.594,
                44.623,
                44.651,
                44.678,
                44.706,
                44.734,
                44.761,
                44.788,
                44.815,
                44.842,
                44.868,
                44.895,
                44.921,
                44.947,
                44.973,
                45,
                45.025,
                45.05,
                45.076,
                45.101,
                45.127,
                45.152,
                45.176,
                45.202,
                45.226,
                45.251,
                45.275,
                45.299,
                45.323,
                45.347,
                45.371,
                45.395,
                45.418,
                45.442,
                45.465,
                45.488,
                45.511,
                45.534,
                45.557,
                45.58,
                45.602,
                45.625,
                45.646,
                45.669,
                45.691,
                45.713,
                45.735,
                45.756,
                45.778,
                45.8,
                45.821,
                45.842,
                45.863,
                45.884,
                45.905,
                45.926,
                45.946,
                45.967,
                45.988,
                46.007,
                46.028,
                46.048,
                46.068,
                46.088,
                46.108,
                46.127,
                46.147,
                46.167,
                46.185,
                46.205,
                46.224,
                46.243,
                46.262,
                46.281,
                46.3,
                46.319,
                46.337,
                46.355,
                46.374,
                46.392,
                46.41,
                46.428,
                46.446,
                46.464,
                46.482,
                46.499,
                46.517,
                46.535,
                46.553,
                46.57,
                46.587,
                46.604,
                46.621,
                46.638,
                46.655,
                46.672,
                46.689,
                46.705,
                46.722,
                46.739,
                46.755,
                46.771,
                46.787,
                46.804,
                46.82,
                46.836,
                46.852,
                46.868,
                46.883,
                46.899,
                46.915,
                46.93,
                46.946,
                46.962,
                46.977,
                46.992,
                47.007,
                47.022,
                47.037,
                47.052,
                47.067,
                47.082,
                47.097,
                47.112,
                47.127,
                47.14,
                47.155,
                47.17,
                47.184,
                47.198,
                47.213,
                47.227,
                47.241,
                47.255,
                47.269,
                47.283,
                47.297,
                47.31,
                47.324,
                47.337,
                47.351,
                47.365,
                47.378,
                47.392,
                47.405,
                47.418,
                47.431,
                47.445,
                47.458,
                47.471,
                47.484,
                47.497,
                47.51,
                47.522,
                47.535,
                47.547,
                47.56,
                47.573,
                47.585,
                47.598,
                47.61,
                47.622,
                47.635,
                47.647,
                47.659,
                47.671,
                47.683,
                47.695,
                47.707,
                47.719,
                47.731,
                47.743,
                47.755,
                47.767,
                47.778,
                47.79,
                47.801,
                47.813,
                47.824,
                47.836,
                47.847,
                47.859,
                47.87,
                47.881,
                47.892,
                47.903,
                47.914,
                47.925,
                47.936,
                47.947,
                47.958,
                47.968,
                47.979,
                47.99,
                48.001,
                48.011,
                48.022,
                48.033,
                48.043,
                48.054,
                48.064,
                48.074,
                48.085,
                48.095,
                48.105,
                48.116,
                48.126,
                48.136,
                48.147,
                48.157,
                48.167,
                48.177,
                48.187,
                48.197,
                48.207,
                48.216,
                48.226,
                48.236,
                48.246,
                48.255,
                48.265,
                48.275,
                48.284,
                48.294,
                48.303,
                48.313,
                48.322,
                48.331,
                48.341,
                48.35,
                48.359,
                48.369,
                48.378,
                48.387,
                48.396,
                48.405,
                48.414,
                48.423,
                48.432,
                48.441,
                48.45,
                48.459,
                48.468,
                48.477,
                48.486,
                48.495,
                48.503,
                48.512,
                48.521,
                48.53,
                48.539,
                48.547,
                48.556,
                48.564,
                48.573,
                48.581,
                48.59,
                48.598,
                48.607,
                48.615,
                48.623,
                48.632,
                48.64,
                48.648,
                48.656,
                48.665,
                48.673,
                48.681,
                48.689,
                48.697,
                48.705,
                48.714,
                48.722,
                48.73,
                48.738,
                48.746,
                48.754,
                48.762,
                48.769,
                48.777,
                48.785,
                48.793,
                48.801,
                48.808,
                48.816,
                48.824,
                48.831,
                48.839,
                48.847,
                48.855,
                48.863,
                48.87,
                48.878,
                48.885,
                48.892,
                48.9,
                48.907,
                48.915,
                48.922,
                48.929,
                48.937,
                48.944,
                48.951,
                48.959,
                48.966,
                48.974,
                48.981,
                48.988,
                48.996,
                49.003,
                49.01,
                49.017,
                49.024,
                49.031,
                49.038,
                49.045,
                49.052,
                49.059,
                49.066,
                49.074,
                49.081,
                49.088,
                49.095,
                49.101,
                49.108,
                49.115,
                49.122,
                49.129,
                49.136,
                49.142,
                49.149,
                49.156,
                49.163,
                49.17,
                49.177,
                49.183,
                49.19,
                49.197,
                49.203,
                49.21,
                49.216,
                49.223,
                49.229,
                49.236,
                49.242,
                49.25,
                49.256,
                49.263,
                49.269,
                49.276,
                49.282,
                49.288,
                49.295,
                49.301,
                49.308,
                49.314,
                49.32,
                49.327,
                49.334,
                49.34,
                49.346,
                49.352,
                49.359,
                49.365,
                49.371,
                49.377,
                49.383,
                49.39,
                49.396,
                49.403,
                49.409,
                49.415,
                49.421,
                49.427,
                49.433,
                49.439,
                49.445,
                49.451,
                49.457,
                49.463,
                49.47,
                49.476,
                49.482,
                49.488,
                49.494,
                49.5,
                49.506,
                49.512,
                49.518,
                49.523,
                49.53,
                49.536,
                49.542,
                49.548,
                49.554,
                49.559,
                49.565,
                49.571,
                49.577,
                49.583,
                49.588,
                49.595,
                49.601,
                49.606,
                49.612,
                49.618,
                49.623,
                49.629,
                49.635,
                49.64,
                49.646,
                49.653,
                49.658,
                49.664,
                49.669,
                49.675,
                49.681,
                49.686,
                49.692,
                49.697,
                49.703,
                49.709,
                49.715,
                49.72,
                49.726,
                49.731,
                49.737,
                49.742,
                49.748,
                49.753,
                49.759,
                49.765,
                49.77,
                49.776,
                49.781,
                49.787,
                49.792,
                49.797,
                49.803,
                49.809,
                49.814,
                49.82,
                49.825,
                49.83,
                49.836,
                49.841,
                49.846,
                49.851,
                49.857,
                49.863,
                49.868,
                49.873,
                49.879,
                49.884,
                49.889,
                49.894,
                49.9,
                49.905,
                49.911,
                49.916,
                49.921,
                49.926,
                49.932,
                49.937,
                49.942,
                49.947,
                49.953,
                49.958,
                49.963,
                49.968,
                49.974,
                49.979,
                49.984,
                49.989,
                49.994,
                50,
                50.005,
                50.01,
                50.015,
                50.02,
                50.025,
                50.03,
                50.035,
                50.041,
                50.046,
                50.051,
                50.056,
                50.061,
                50.066,
                50.071,
                50.076,
                50.081,
                50.087,
                50.092,
                50.097,
                50.102,
                50.106,
                50.111,
                50.116,
                50.121,
                50.127,
                50.132,
                50.137,
                50.142,
                50.147,
                50.151,
                50.156,
                50.161,
                50.167,
                50.172,
                50.176,
                50.181,
                50.186,
                50.191,
                50.196,
                50.2,
                50.206,
                50.211,
                50.216,
                50.22,
                50.225,
                50.23,
                50.235,
                50.24,
                50.245,
                50.25,
                50.255,
                50.259,
                50.264,
                50.269,
                50.273,
                50.278,
                50.284,
                50.288,
                50.293,
                50.298,
                50.302,
                50.307,
                50.312,
                50.316,
                50.322,
                50.326,
                50.331,
                50.336,
                50.34,
                50.345,
                50.349,
                50.354,
                50.359,
                50.364,
                50.369,
                50.373,
                50.378,
                50.382,
                50.387,
                50.392,
                50.397,
                50.401,
                50.406,
                50.41,
                50.415,
                50.42,
                50.424,
                50.429,
                50.434,
                50.438,
                50.443,
                50.447,
                50.452,
                50.456,
                50.461,
                50.466,
                50.47,
                50.475,
                50.479,
                50.484,
                50.488,
                50.493,
                50.498,
                50.502,
                50.507,
                50.511,
                50.516,
                50.52,
                50.524,
                50.529,
                50.534,
                50.538,
                50.543,
                50.547,
                50.551,
                50.556,
                50.56,
                50.565,
                50.569,
                50.574,
                50.578,
                50.582,
                50.587,
                50.591,
                50.596,
                50.6,
                50.605,
                50.609,
                50.613,
                50.618,
                50.622,
                50.626,
                50.631,
                50.635,
                50.64,
                50.644,
                50.648,
                50.652,
                50.656,
                50.661,
                50.666,
                50.67,
                50.674,
                50.678,
                50.682,
                50.686,
                50.691,
                50.696,
                50.7,
                50.704,
                50.708,
                50.712,
                50.716,
                50.721,
                50.725,
                50.73,
                50.734,
                50.738,
                50.742,
                50.746,
                50.75,
                50.755,
                50.759,
                50.763,
                50.767,
                50.771,
                50.775,
                50.779,
                50.784,
                50.788,
                50.792,
                50.796,
                50.8,
                50.804,
                50.808,
                50.813,
                50.817,
                50.821,
                50.825,
                50.829,
                50.833,
                50.837,
                50.842,
                50.846,
                50.85,
                50.854,
                50.858,
                50.862,
                50.866,
                50.87,
                50.874,
                50.878,
                50.882,
                50.886,
                50.89,
                50.894,
                50.898,
                50.902,
                50.906,
                50.91,
                50.914,
                50.918,
                50.922,
                50.926,
                50.93,
                50.934,
                50.938,
                50.942,
                50.945,
                50.949,
                50.953,
                50.958,
                50.962,
                50.965,
                50.969,
                50.973,
                50.977,
                50.981,
                50.985,
                50.989,
                50.993,
                50.996,
                51,
                51.004,
                51.008,
                51.012,
                51.016,
                51.02,
                51.023,
                51.027,
                51.031,
                51.034,
                51.039,
                51.043,
                51.046,
                51.05,
                51.054,
                51.057,
                51.061,
                51.065,
                51.069,
                51.073,
                51.076,
                51.08,
                51.084,
                51.087,
                51.092,
                51.095,
                51.099,
                51.103,
                51.106,
                51.11,
                51.113,
                51.118,
                51.121,
                51.125,
                51.129,
                51.132,
                51.136,
                51.139,
                51.143,
                51.147,
                51.151,
                51.154,
                51.158,
                51.161,
                51.165,
                51.168,
                51.173,
                51.176,
                51.18,
                51.183,
                51.187,
                51.19,
                51.194,
                51.198,
                51.201,
                51.205,
                51.208,
                51.212,
                51.215,
                51.219,
                51.223,
                51.226,
                51.23,
                51.233,
                51.237,
                51.24,
                51.243,
                51.248,
                51.251,
                51.254,
                51.258,
                51.261,
                51.265,
                51.268,
                51.271,
                51.276,
                51.279,
                51.282,
                51.286,
                51.289,
                51.292,
                51.295,
                51.3,
                51.303,
                51.306,
                51.31,
                51.313,
                51.316,
                51.32,
                51.324,
                51.327,
                51.33,
                51.334,
                51.337,
                51.34,
                51.343,
                51.347,
                51.351,
                51.354,
                51.357,
                51.36,
                51.364,
                51.367,
                51.37,
                51.374,
                51.377,
                51.381,
                51.384,
                51.387,
                51.39,
                51.393,
                51.397,
                51.401,
                51.404,
                51.407,
                51.41,
                51.413,
                51.416,
                51.42,
                51.424,
                51.427,
                51.43,
                51.433,
                51.436,
                51.439,
                51.442,
                51.446,
                51.449,
                51.453,
                51.456,
                51.459,
                51.462,
                51.465,
                51.469,
                51.472,
                51.475,
                51.478,
                51.481,
                51.484,
                51.487,
                51.49,
                51.494,
                51.497,
                51.5,
                51.503,
                51.506,
                51.509,
                51.512,
                51.516,
                51.519,
                51.522,
                51.525,
                51.528,
                51.531,
                51.534,
                51.537,
                51.541,
                51.544,
                51.547,
                51.55,
                51.553,
                51.556,
                51.559,
                51.563,
                51.566,
                51.569,
                51.572,
                51.575,
                51.578,
                51.58,
                51.583,
                51.587,
                51.59,
                51.593,
                51.596,
                51.599,
                51.602,
                51.605,
                51.608,
                51.611,
                51.614,
                51.617,
                51.62,
                51.623,
                51.626,
                51.629,
                51.632,
                51.635,
                51.638,
                51.641,
                51.644,
                51.646,
                51.649,
                51.652,
                51.656,
                51.659,
                51.661,
                51.664,
                51.667,
                51.67,
                51.673,
                51.676,
                51.679,
                51.682,
                51.685,
                51.688,
                51.69,
                51.693,
                51.696,
                51.699,
                51.702,
                51.705,
                51.708,
                51.711,
                51.713,
                51.716,
                51.719,
                51.722,
                51.725,
                51.728,
                51.731,
                51.733,
                51.736,
                51.739,
                51.742,
                51.745,
                51.748,
                51.75,
                51.753,
                51.756,
                51.759,
                51.761,
                51.764,
                51.767,
                51.77,
                51.773,
                51.775,
                51.778,
                51.781,
                51.784,
                51.786,
                51.79,
                51.792,
                51.795,
                51.798,
                51.8,
                51.803,
                51.806,
                51.808,
                51.812,
                51.814,
                51.817,
                51.82,
                51.822,
                51.825,
                51.827,
                51.83,
                51.833,
                51.836,
                51.839,
                51.841,
                51.844,
                51.846,
                51.849,
                51.852,
                51.855,
                51.858,
                51.86,
                51.863,
                51.865,
                51.868,
                51.87,
                51.873,
                51.876,
                51.879,
                51.881,
                51.884,
                51.886,
                51.889,
                51.891,
                51.894,
                51.897,
                51.9,
                51.902,
                51.905,
                51.907,
                51.91,
                51.912,
                51.915,
                51.918,
                51.921,
                51.923,
                51.926,
                51.928,
                51.931,
                51.933,
                51.936,
                51.939,
                51.941,
                51.944,
                51.946,
                51.949,
                51.951,
                51.954,
                51.956,
                51.959,
                51.962,
                51.964,
                51.967,
                51.969,
                51.972,
                51.974,
                51.976,
                51.979,
                51.982,
                51.985,
                51.987,
                51.989,
                51.992,
                51.994,
                51.997,
                51.999,
                52.002,
                52.005,
                52.007,
                52.009,
                52.012,
                52.014,
                52.017,
                52.019,
                52.022,
                52.025,
                52.027,
                52.029,
                52.032,
                52.034,
                52.036,
                52.039,
                52.041,
                52.044,
                52.047,
                52.049,
                52.051,
                52.054,
                52.056,
                52.058,
                52.061,
                52.064,
                52.066,
                52.068,
                52.071,
                52.073,
                52.075,
                52.078,
                52.08,
                52.082,
                52.085,
                52.088,
                52.09,
                52.092,
                52.095,
                52.097,
                52.099,
                52.102,
                52.105,
                52.107,
                52.109,
                52.111,
                52.114,
                52.116,
                52.118,
                52.12,
                52.123,
                52.126,
                52.128,
                52.13,
                52.133,
                52.135,
                52.137,
                52.139,
                52.142,
                52.144,
                52.147,
                52.149,
                52.151,
                52.154,
                52.156,
                52.158,
                52.16,
                52.163,
                52.166,
                52.168,
                52.17,
                52.172,
                52.174,
                52.177,
                52.179,
                52.181,
                52.183,
                52.186,
                52.188,
                52.191,
                52.193,
                52.195,
                52.197,
                52.199,
                52.201,
                52.204,
                52.207,
                52.209,
                52.211,
                52.213,
                52.215,
                52.217,
                52.22,
                52.222,
                52.224,
                52.227,
                52.229,
                52.231,
                52.233,
                52.235,
                52.238,
                52.24,
                52.242,
                52.244,
                52.247,
                52.249,
                52.251,
                52.253,
                52.256,
                52.258,
                52.26,
                52.262,
                52.264,
                52.267,
                52.269,
                52.271,
                52.273,
                52.275,
                52.277,
                52.28,
                52.282,
                52.284,
                52.287,
                52.289,
                52.291,
                52.293,
                52.295,
                52.297,
                52.299,
                52.301,
                52.303,
                52.306,
                52.308,
                52.31,
                52.312,
                52.314,
                52.316,
                52.319,
                52.321,
                52.323,
                52.325,
                52.328,
                52.33,
                52.332,
                52.334,
                52.336,
                52.338,
                52.34,
                52.342,
                52.345,
                52.347,
                52.349,
                52.351,
                52.353,
                52.355,
                52.357,
                52.359,
                52.361,
                52.364,
                52.366,
                52.368,
                52.37,
                52.372,
                52.374,
                52.376,
                52.378,
                52.38,
                52.383,
                52.385,
                52.387,
                52.388,
                52.39,
                52.392,
                52.394,
                52.396,
                52.398,
                52.4,
                52.403,
                52.405,
                52.407,
                52.409,
                52.411,
                52.413,
                52.415,
                52.417,
                52.419,
                52.422,
                52.423,
                52.425,
                52.427,
                52.429,
                52.431,
                52.433,
                52.435,
                52.437,
                52.44,
                52.442,
                52.444,
                52.446,
                52.448,
                52.449,
                52.451,
                52.453,
                52.455,
                52.457,
                52.46,
                52.462,
                52.464,
                52.466,
                52.468,
                52.469,
                52.471,
                52.473,
                52.475,
                52.478,
                52.48,
                52.482,
                52.483,
                52.485,
                52.487,
                52.489,
                52.491,
                52.493,
                52.495,
                52.497,
                52.499,
                52.501,
                52.503,
                52.505,
                52.507,
                52.509,
                52.51,
                52.512,
                52.514,
                52.517,
                52.519,
                52.521,
                52.523,
                52.524,
                52.526,
                52.528,
                52.53,
                52.532,
                52.534,
                52.536,
                52.538,
                52.54,
                52.542,
                52.543,
                52.545,
                52.547,
                52.549,
                52.551,
                52.553,
                52.555,
                52.557,
                52.559,
                52.561,
                52.563,
                52.564,
                52.566,
                52.568,
                52.57,
                52.572,
                52.574,
                52.576,
                52.578,
                52.579,
                52.581,
                52.583,
                52.585,
                52.587,
                52.588,
                52.591,
                52.593,
                52.595,
                52.596,
                52.598,
                52.6,
                52.602,
                52.603,
                52.605,
                52.608,
                52.61,
                52.611,
                52.613,
                52.615,
                52.617,
                52.618,
                52.62,
                52.622,
                52.624,
                52.626,
                52.628,
                52.63,
                52.631,
                52.633,
                52.635,
                52.637,
                52.638,
                52.64,
                52.642,
                52.644,
                52.646,
                52.648,
                52.65,
                52.651,
                52.653,
                52.655,
                52.656,
                52.658,
                52.66,
                52.662,
                52.664,
                52.666,
                52.668,
                52.669,
                52.671,
                52.673,
                52.674,
                52.676,
                52.678,
                52.679,
                52.682,
                52.684,
                52.685,
                52.687,
                52.689,
                52.69,
                52.692,
                52.694,
                52.695,
                52.697,
                52.7,
                52.701,
                52.703,
                52.705,
                52.706,
                52.708,
                52.71,
                52.711,
                52.713,
                52.715,
                52.717,
                52.719,
                52.72,
                52.722,
                52.724,
                52.725,
                52.727,
                52.729,
                52.73,
                52.732,
                52.734,
                52.736,
                52.738,
                52.739,
                52.741,
                52.743,
                52.744,
                52.746,
                52.748,
                52.749,
                52.751,
                52.753,
                52.755,
                52.757,
                52.758,
                52.76,
                52.761,
                52.763,
                52.765,
                52.766,
                52.768,
                52.77,
                52.772,
                52.774,
                52.775,
                52.777,
                52.778,
                52.78,
                52.782,
                52.783,
                52.785,
                52.786,
                52.789,
                52.79,
                52.792,
                52.794,
                52.795,
                52.797,
                52.798,
                52.8,
                52.802,
                52.803,
                52.806,
                52.807,
                52.809,
                52.81,
                52.812,
                52.814,
                52.815,
                52.817,
                52.818,
                52.82,
                52.821,
                52.824,
                52.825,
                52.827,
                52.829,
                52.83,
                52.832,
                52.833,
                52.835,
                52.836,
                52.838,
                52.84,
                52.842,
                52.843,
                52.845,
                52.846,
                52.848,
                52.85,
                52.851,
                52.853,
                52.854,
                52.856,
                52.858,
                52.86,
                52.861,
                52.863,
                52.864,
                52.866,
                52.867,
                52.869,
                52.87,
                52.872,
                52.873,
                52.876,
                52.877,
                52.879,
                52.88,
                52.882,
                52.883,
                52.885,
                52.887,
                52.888,
                52.889,
                52.891,
                52.893,
                52.895,
                52.896,
                52.898,
                52.899,
                52.901,
                52.902,
                52.904,
                52.906,
                52.907,
                52.909,
                52.911,
                52.912,
                52.914,
                52.915,
                52.917,
                52.918,
                52.92,
                52.921,
                52.923,
                52.924,
                52.927,
                52.928,
                52.93,
                52.931,
                52.933,
                52.934,
                52.935,
                52.937,
                52.939,
                52.94,
                52.941,
                52.944,
                52.945,
                52.947,
                52.948,
                52.95,
                52.951,
                52.953,
                52.954,
                52.955,
                52.957,
                52.958,
                52.961,
                52.962,
                52.964,
                52.965,
                52.967,
                52.968,
                52.97,
                52.971,
                52.972,
                52.974,
                52.975,
                52.978,
                52.979,
                52.981,
                52.982,
                52.983,
                52.985,
                52.986,
                52.988,
                52.989,
                52.991,
                52.992,
                52.994,
                52.996,
                52.997,
                52.999,
                53,
                53.002,
                53.003,
                53.005,
                53.006,
                53.007,
                53.009,
                53.01,
                53.012,
                53.014,
                53.015,
                53.017,
                53.018,
                53.02,
                53.021,
                53.023,
                53.024,
                53.025,
                53.027,
                53.029,
                53.03,
                53.032,
                53.033,
                53.035,
                53.036,
                53.038,
                53.039,
                53.04,
                53.042,
                53.043,
                53.046,
                53.047,
                53.048,
                53.05,
                53.051,
                53.053,
                53.054,
                53.055,
                53.057,
                53.058,
                53.06,
                53.061,
                53.063,
                53.065,
                53.066,
                53.067,
                53.069,
                53.07,
                53.072,
                53.073,
                53.074,
                53.076,
                53.077,
                53.079,
                53.081,
                53.082,
                53.084,
                53.085,
                53.086,
                53.088,
                53.089,
                53.09,
                53.092,
                53.093,
                53.095,
                53.097,
                53.098,
                53.1,
                53.101,
                53.102,
                53.104,
                53.105,
                53.107,
                53.108,
                53.109,
                53.111,
                53.113,
                53.114,
                53.116,
                53.117,
                53.118,
                53.12,
                53.121,
                53.123,
                53.124,
                53.125,
                53.127,
                53.128,
                53.13,
                53.132,
                53.133,
                53.134,
                53.136,
                53.137,
                53.138,
                53.14,
                53.141,
                53.142,
                53.144,
                53.146,
                53.147,
                53.149,
                53.15,
                53.151,
                53.153,
                53.154,
                53.156,
                53.157,
                53.158,
                53.16,
                53.161,
                53.163,
                53.165,
                53.166,
                53.167,
                53.169,
                53.17,
                53.171,
                53.173,
                53.174,
                53.175,
                53.177,
                53.178,
                53.18,
                53.182,
                53.183,
                53.184,
                53.186,
                53.187,
                53.188,
                53.19,
                53.191,
                53.192,
                53.194,
                53.195,
                53.197,
                53.198,
                53.2,
                53.201,
                53.202,
                53.204,
                53.205,
                53.206,
                53.208,
                53.209,
                53.21,
                53.213,
                53.214,
                53.215,
                53.217,
                53.218,
                53.219,
                53.221,
                53.222,
                53.223,
                53.225,
                53.226,
                53.227,
                53.229,
                53.231,
                53.232,
                53.233,
                53.235,
                53.236,
                53.237
            ],
            P97: [
                36.851,
                36.939,
                37.025,
                37.113,
                37.2,
                37.286,
                37.373,
                37.46,
                37.546,
                37.633,
                37.718,
                37.805,
                37.891,
                37.976,
                38.063,
                38.162,
                38.26,
                38.356,
                38.449,
                38.54,
                38.63,
                38.718,
                38.803,
                38.888,
                38.971,
                39.053,
                39.133,
                39.211,
                39.289,
                39.364,
                39.439,
                39.513,
                39.586,
                39.658,
                39.728,
                39.798,
                39.866,
                39.934,
                40.001,
                40.067,
                40.132,
                40.196,
                40.26,
                40.322,
                40.384,
                40.446,
                40.506,
                40.566,
                40.625,
                40.683,
                40.741,
                40.799,
                40.855,
                40.911,
                40.966,
                41.022,
                41.076,
                41.129,
                41.183,
                41.236,
                41.289,
                41.341,
                41.392,
                41.443,
                41.494,
                41.545,
                41.595,
                41.644,
                41.693,
                41.742,
                41.79,
                41.838,
                41.885,
                41.933,
                41.979,
                42.025,
                42.072,
                42.117,
                42.163,
                42.208,
                42.253,
                42.297,
                42.341,
                42.385,
                42.428,
                42.471,
                42.514,
                42.557,
                42.599,
                42.64,
                42.682,
                42.724,
                42.765,
                42.806,
                42.846,
                42.887,
                42.927,
                42.967,
                43.007,
                43.046,
                43.085,
                43.123,
                43.162,
                43.201,
                43.238,
                43.277,
                43.314,
                43.351,
                43.389,
                43.426,
                43.463,
                43.499,
                43.535,
                43.571,
                43.607,
                43.642,
                43.678,
                43.714,
                43.748,
                43.783,
                43.818,
                43.852,
                43.886,
                43.92,
                43.954,
                43.987,
                44.021,
                44.054,
                44.087,
                44.119,
                44.152,
                44.184,
                44.217,
                44.249,
                44.281,
                44.312,
                44.344,
                44.375,
                44.406,
                44.437,
                44.468,
                44.498,
                44.529,
                44.559,
                44.589,
                44.619,
                44.648,
                44.678,
                44.707,
                44.736,
                44.766,
                44.794,
                44.823,
                44.851,
                44.879,
                44.908,
                44.936,
                44.964,
                44.991,
                45.019,
                45.047,
                45.074,
                45.1,
                45.128,
                45.154,
                45.181,
                45.208,
                45.234,
                45.26,
                45.286,
                45.312,
                45.337,
                45.363,
                45.388,
                45.414,
                45.439,
                45.464,
                45.489,
                45.513,
                45.538,
                45.562,
                45.587,
                45.611,
                45.636,
                45.659,
                45.683,
                45.707,
                45.73,
                45.753,
                45.777,
                45.8,
                45.823,
                45.846,
                45.869,
                45.891,
                45.914,
                45.936,
                45.958,
                45.981,
                46.002,
                46.025,
                46.046,
                46.068,
                46.09,
                46.111,
                46.132,
                46.154,
                46.174,
                46.196,
                46.217,
                46.237,
                46.258,
                46.279,
                46.298,
                46.319,
                46.34,
                46.359,
                46.379,
                46.399,
                46.418,
                46.438,
                46.458,
                46.477,
                46.497,
                46.516,
                46.536,
                46.554,
                46.573,
                46.592,
                46.611,
                46.629,
                46.648,
                46.667,
                46.685,
                46.703,
                46.721,
                46.739,
                46.758,
                46.776,
                46.793,
                46.811,
                46.829,
                46.846,
                46.864,
                46.881,
                46.898,
                46.915,
                46.933,
                46.95,
                46.966,
                46.983,
                47,
                47.017,
                47.033,
                47.05,
                47.066,
                47.082,
                47.099,
                47.115,
                47.131,
                47.147,
                47.163,
                47.179,
                47.195,
                47.21,
                47.226,
                47.242,
                47.257,
                47.273,
                47.288,
                47.303,
                47.318,
                47.334,
                47.349,
                47.364,
                47.379,
                47.394,
                47.409,
                47.423,
                47.437,
                47.452,
                47.466,
                47.481,
                47.496,
                47.51,
                47.524,
                47.538,
                47.553,
                47.567,
                47.581,
                47.595,
                47.608,
                47.622,
                47.635,
                47.649,
                47.663,
                47.676,
                47.69,
                47.703,
                47.717,
                47.73,
                47.743,
                47.757,
                47.77,
                47.783,
                47.796,
                47.809,
                47.821,
                47.834,
                47.847,
                47.859,
                47.872,
                47.885,
                47.897,
                47.91,
                47.922,
                47.934,
                47.947,
                47.959,
                47.971,
                47.983,
                47.996,
                48.008,
                48.02,
                48.032,
                48.043,
                48.055,
                48.067,
                48.079,
                48.091,
                48.102,
                48.114,
                48.125,
                48.137,
                48.148,
                48.16,
                48.171,
                48.182,
                48.194,
                48.205,
                48.216,
                48.226,
                48.237,
                48.248,
                48.259,
                48.27,
                48.281,
                48.292,
                48.303,
                48.313,
                48.324,
                48.335,
                48.345,
                48.356,
                48.366,
                48.377,
                48.387,
                48.398,
                48.408,
                48.418,
                48.428,
                48.439,
                48.45,
                48.46,
                48.47,
                48.48,
                48.49,
                48.5,
                48.51,
                48.52,
                48.53,
                48.539,
                48.549,
                48.559,
                48.569,
                48.578,
                48.588,
                48.598,
                48.607,
                48.617,
                48.626,
                48.636,
                48.645,
                48.654,
                48.664,
                48.673,
                48.682,
                48.691,
                48.701,
                48.71,
                48.719,
                48.728,
                48.737,
                48.746,
                48.755,
                48.764,
                48.773,
                48.782,
                48.791,
                48.8,
                48.808,
                48.817,
                48.827,
                48.836,
                48.844,
                48.853,
                48.862,
                48.87,
                48.879,
                48.887,
                48.896,
                48.904,
                48.913,
                48.921,
                48.929,
                48.938,
                48.946,
                48.954,
                48.963,
                48.971,
                48.979,
                48.987,
                48.996,
                49.004,
                49.012,
                49.021,
                49.029,
                49.037,
                49.045,
                49.053,
                49.061,
                49.069,
                49.076,
                49.084,
                49.092,
                49.1,
                49.108,
                49.116,
                49.123,
                49.131,
                49.139,
                49.146,
                49.154,
                49.163,
                49.17,
                49.178,
                49.185,
                49.193,
                49.2,
                49.208,
                49.215,
                49.223,
                49.23,
                49.238,
                49.245,
                49.252,
                49.26,
                49.267,
                49.274,
                49.283,
                49.29,
                49.297,
                49.304,
                49.311,
                49.319,
                49.326,
                49.333,
                49.34,
                49.347,
                49.354,
                49.361,
                49.368,
                49.375,
                49.383,
                49.39,
                49.397,
                49.404,
                49.411,
                49.418,
                49.425,
                49.432,
                49.438,
                49.445,
                49.452,
                49.459,
                49.466,
                49.473,
                49.48,
                49.487,
                49.493,
                49.5,
                49.507,
                49.513,
                49.52,
                49.527,
                49.533,
                49.54,
                49.546,
                49.553,
                49.56,
                49.567,
                49.573,
                49.58,
                49.586,
                49.593,
                49.599,
                49.606,
                49.612,
                49.619,
                49.625,
                49.631,
                49.638,
                49.645,
                49.651,
                49.657,
                49.664,
                49.67,
                49.676,
                49.682,
                49.689,
                49.695,
                49.701,
                49.707,
                49.714,
                49.721,
                49.727,
                49.733,
                49.739,
                49.745,
                49.751,
                49.757,
                49.763,
                49.769,
                49.775,
                49.782,
                49.788,
                49.794,
                49.8,
                49.806,
                49.812,
                49.818,
                49.824,
                49.83,
                49.836,
                49.843,
                49.849,
                49.855,
                49.861,
                49.867,
                49.872,
                49.878,
                49.884,
                49.89,
                49.896,
                49.901,
                49.908,
                49.914,
                49.92,
                49.925,
                49.931,
                49.937,
                49.943,
                49.948,
                49.954,
                49.96,
                49.966,
                49.972,
                49.978,
                49.983,
                49.989,
                49.995,
                50,
                50.006,
                50.011,
                50.017,
                50.023,
                50.029,
                50.034,
                50.04,
                50.046,
                50.051,
                50.057,
                50.062,
                50.068,
                50.074,
                50.079,
                50.085,
                50.09,
                50.096,
                50.101,
                50.107,
                50.112,
                50.118,
                50.124,
                50.129,
                50.135,
                50.14,
                50.145,
                50.151,
                50.156,
                50.162,
                50.167,
                50.172,
                50.178,
                50.184,
                50.189,
                50.194,
                50.2,
                50.205,
                50.21,
                50.215,
                50.221,
                50.227,
                50.232,
                50.237,
                50.242,
                50.248,
                50.253,
                50.258,
                50.263,
                50.269,
                50.275,
                50.28,
                50.285,
                50.29,
                50.295,
                50.3,
                50.305,
                50.311,
                50.317,
                50.322,
                50.327,
                50.332,
                50.337,
                50.342,
                50.347,
                50.352,
                50.358,
                50.363,
                50.368,
                50.373,
                50.378,
                50.383,
                50.388,
                50.393,
                50.398,
                50.404,
                50.409,
                50.414,
                50.419,
                50.424,
                50.429,
                50.434,
                50.439,
                50.445,
                50.45,
                50.455,
                50.46,
                50.464,
                50.469,
                50.474,
                50.479,
                50.485,
                50.49,
                50.495,
                50.5,
                50.504,
                50.509,
                50.514,
                50.519,
                50.525,
                50.529,
                50.534,
                50.539,
                50.544,
                50.549,
                50.553,
                50.558,
                50.564,
                50.569,
                50.574,
                50.578,
                50.583,
                50.588,
                50.593,
                50.597,
                50.603,
                50.608,
                50.612,
                50.617,
                50.622,
                50.626,
                50.631,
                50.636,
                50.641,
                50.646,
                50.651,
                50.655,
                50.66,
                50.665,
                50.669,
                50.674,
                50.679,
                50.684,
                50.689,
                50.693,
                50.698,
                50.703,
                50.707,
                50.713,
                50.717,
                50.722,
                50.726,
                50.731,
                50.735,
                50.74,
                50.745,
                50.75,
                50.755,
                50.759,
                50.764,
                50.768,
                50.773,
                50.777,
                50.782,
                50.787,
                50.791,
                50.796,
                50.8,
                50.805,
                50.809,
                50.814,
                50.819,
                50.824,
                50.828,
                50.833,
                50.837,
                50.841,
                50.846,
                50.851,
                50.856,
                50.86,
                50.864,
                50.869,
                50.873,
                50.877,
                50.882,
                50.887,
                50.891,
                50.896,
                50.9,
                50.904,
                50.909,
                50.913,
                50.918,
                50.923,
                50.927,
                50.931,
                50.936,
                50.94,
                50.944,
                50.948,
                50.954,
                50.958,
                50.962,
                50.966,
                50.971,
                50.975,
                50.979,
                50.984,
                50.988,
                50.993,
                50.997,
                51.001,
                51.005,
                51.01,
                51.015,
                51.019,
                51.023,
                51.027,
                51.031,
                51.036,
                51.04,
                51.045,
                51.049,
                51.053,
                51.057,
                51.061,
                51.066,
                51.07,
                51.074,
                51.079,
                51.083,
                51.087,
                51.091,
                51.095,
                51.099,
                51.103,
                51.108,
                51.112,
                51.116,
                51.121,
                51.125,
                51.129,
                51.133,
                51.138,
                51.142,
                51.146,
                51.15,
                51.154,
                51.158,
                51.162,
                51.167,
                51.17,
                51.174,
                51.178,
                51.182,
                51.186,
                51.19,
                51.195,
                51.199,
                51.203,
                51.207,
                51.211,
                51.215,
                51.219,
                51.224,
                51.228,
                51.232,
                51.235,
                51.239,
                51.243,
                51.247,
                51.251,
                51.256,
                51.26,
                51.263,
                51.267,
                51.271,
                51.275,
                51.279,
                51.284,
                51.287,
                51.291,
                51.295,
                51.299,
                51.303,
                51.307,
                51.311,
                51.315,
                51.319,
                51.323,
                51.326,
                51.33,
                51.334,
                51.339,
                51.342,
                51.346,
                51.35,
                51.354,
                51.357,
                51.361,
                51.366,
                51.369,
                51.373,
                51.377,
                51.381,
                51.384,
                51.388,
                51.392,
                51.396,
                51.4,
                51.404,
                51.407,
                51.411,
                51.414,
                51.419,
                51.423,
                51.426,
                51.43,
                51.434,
                51.437,
                51.441,
                51.445,
                51.449,
                51.453,
                51.456,
                51.46,
                51.463,
                51.467,
                51.47,
                51.475,
                51.479,
                51.482,
                51.486,
                51.489,
                51.493,
                51.496,
                51.501,
                51.504,
                51.508,
                51.511,
                51.515,
                51.518,
                51.522,
                51.526,
                51.53,
                51.533,
                51.537,
                51.54,
                51.544,
                51.547,
                51.551,
                51.555,
                51.558,
                51.562,
                51.565,
                51.569,
                51.572,
                51.577,
                51.58,
                51.583,
                51.587,
                51.59,
                51.594,
                51.597,
                51.6,
                51.605,
                51.608,
                51.611,
                51.615,
                51.618,
                51.622,
                51.625,
                51.629,
                51.633,
                51.636,
                51.639,
                51.643,
                51.646,
                51.649,
                51.653,
                51.657,
                51.66,
                51.663,
                51.667,
                51.67,
                51.673,
                51.677,
                51.681,
                51.684,
                51.687,
                51.691,
                51.694,
                51.697,
                51.7,
                51.705,
                51.708,
                51.711,
                51.714,
                51.717,
                51.721,
                51.724,
                51.728,
                51.731,
                51.734,
                51.738,
                51.741,
                51.744,
                51.747,
                51.75,
                51.754,
                51.758,
                51.761,
                51.764,
                51.767,
                51.77,
                51.773,
                51.777,
                51.781,
                51.784,
                51.787,
                51.79,
                51.793,
                51.796,
                51.8,
                51.803,
                51.806,
                51.81,
                51.813,
                51.816,
                51.819,
                51.822,
                51.826,
                51.829,
                51.832,
                51.835,
                51.838,
                51.841,
                51.844,
                51.848,
                51.851,
                51.854,
                51.857,
                51.86,
                51.863,
                51.866,
                51.869,
                51.873,
                51.876,
                51.879,
                51.882,
                51.885,
                51.888,
                51.891,
                51.895,
                51.898,
                51.901,
                51.904,
                51.907,
                51.91,
                51.913,
                51.916,
                51.92,
                51.923,
                51.926,
                51.929,
                51.932,
                51.935,
                51.937,
                51.94,
                51.944,
                51.947,
                51.95,
                51.953,
                51.956,
                51.959,
                51.962,
                51.965,
                51.968,
                51.971,
                51.974,
                51.977,
                51.98,
                51.983,
                51.986,
                51.989,
                51.992,
                51.995,
                51.998,
                52.001,
                52.004,
                52.006,
                52.009,
                52.013,
                52.016,
                52.018,
                52.021,
                52.024,
                52.027,
                52.03,
                52.033,
                52.036,
                52.039,
                52.042,
                52.045,
                52.047,
                52.05,
                52.053,
                52.057,
                52.059,
                52.062,
                52.065,
                52.068,
                52.07,
                52.073,
                52.076,
                52.08,
                52.082,
                52.085,
                52.088,
                52.09,
                52.093,
                52.096,
                52.099,
                52.102,
                52.105,
                52.108,
                52.11,
                52.113,
                52.116,
                52.118,
                52.121,
                52.125,
                52.127,
                52.13,
                52.133,
                52.135,
                52.138,
                52.141,
                52.143,
                52.147,
                52.15,
                52.152,
                52.155,
                52.157,
                52.16,
                52.163,
                52.165,
                52.169,
                52.171,
                52.174,
                52.177,
                52.179,
                52.182,
                52.185,
                52.187,
                52.191,
                52.193,
                52.196,
                52.198,
                52.201,
                52.204,
                52.206,
                52.209,
                52.212,
                52.215,
                52.217,
                52.22,
                52.222,
                52.225,
                52.228,
                52.23,
                52.234,
                52.236,
                52.239,
                52.241,
                52.244,
                52.246,
                52.249,
                52.251,
                52.255,
                52.257,
                52.26,
                52.262,
                52.265,
                52.267,
                52.27,
                52.272,
                52.276,
                52.278,
                52.281,
                52.283,
                52.286,
                52.288,
                52.291,
                52.293,
                52.295,
                52.299,
                52.301,
                52.304,
                52.306,
                52.309,
                52.311,
                52.314,
                52.316,
                52.319,
                52.322,
                52.324,
                52.327,
                52.329,
                52.332,
                52.334,
                52.336,
                52.34,
                52.342,
                52.345,
                52.347,
                52.349,
                52.352,
                52.354,
                52.357,
                52.36,
                52.362,
                52.365,
                52.367,
                52.369,
                52.372,
                52.374,
                52.377,
                52.379,
                52.382,
                52.385,
                52.387,
                52.389,
                52.392,
                52.394,
                52.396,
                52.399,
                52.402,
                52.404,
                52.407,
                52.409,
                52.411,
                52.414,
                52.416,
                52.418,
                52.421,
                52.424,
                52.426,
                52.428,
                52.431,
                52.433,
                52.435,
                52.438,
                52.44,
                52.443,
                52.446,
                52.448,
                52.45,
                52.453,
                52.455,
                52.457,
                52.459,
                52.462,
                52.465,
                52.467,
                52.469,
                52.472,
                52.474,
                52.476,
                52.478,
                52.481,
                52.483,
                52.486,
                52.488,
                52.491,
                52.493,
                52.495,
                52.497,
                52.5,
                52.502,
                52.505,
                52.507,
                52.509,
                52.512,
                52.514,
                52.516,
                52.518,
                52.52,
                52.523,
                52.526,
                52.528,
                52.53,
                52.532,
                52.535,
                52.537,
                52.539,
                52.541,
                52.543,
                52.547,
                52.549,
                52.551,
                52.553,
                52.555,
                52.557,
                52.56,
                52.562,
                52.564,
                52.567,
                52.569,
                52.571,
                52.574,
                52.576,
                52.578,
                52.58,
                52.582,
                52.584,
                52.587,
                52.59,
                52.592,
                52.594,
                52.596,
                52.598,
                52.6,
                52.602,
                52.604,
                52.608,
                52.61,
                52.612,
                52.614,
                52.616,
                52.618,
                52.62,
                52.622,
                52.625,
                52.628,
                52.63,
                52.632,
                52.634,
                52.636,
                52.638,
                52.64,
                52.642,
                52.644,
                52.647,
                52.649,
                52.651,
                52.654,
                52.656,
                52.658,
                52.66,
                52.662,
                52.664,
                52.667,
                52.669,
                52.671,
                52.673,
                52.675,
                52.677,
                52.679,
                52.681,
                52.683,
                52.686,
                52.688,
                52.69,
                52.692,
                52.694,
                52.696,
                52.698,
                52.7,
                52.703,
                52.706,
                52.708,
                52.71,
                52.712,
                52.714,
                52.716,
                52.718,
                52.72,
                52.722,
                52.725,
                52.727,
                52.729,
                52.731,
                52.733,
                52.735,
                52.737,
                52.739,
                52.741,
                52.743,
                52.745,
                52.747,
                52.749,
                52.751,
                52.753,
                52.755,
                52.757,
                52.759,
                52.761,
                52.764,
                52.766,
                52.768,
                52.77,
                52.772,
                52.774,
                52.776,
                52.778,
                52.78,
                52.783,
                52.785,
                52.786,
                52.789,
                52.79,
                52.792,
                52.794,
                52.796,
                52.798,
                52.8,
                52.803,
                52.805,
                52.807,
                52.809,
                52.811,
                52.813,
                52.814,
                52.816,
                52.818,
                52.821,
                52.823,
                52.825,
                52.827,
                52.829,
                52.831,
                52.832,
                52.834,
                52.836,
                52.838,
                52.841,
                52.843,
                52.845,
                52.847,
                52.848,
                52.85,
                52.852,
                52.854,
                52.856,
                52.858,
                52.861,
                52.863,
                52.864,
                52.866,
                52.868,
                52.87,
                52.872,
                52.874,
                52.876,
                52.878,
                52.88,
                52.882,
                52.884,
                52.886,
                52.887,
                52.889,
                52.891,
                52.893,
                52.895,
                52.898,
                52.9,
                52.901,
                52.903,
                52.905,
                52.907,
                52.909,
                52.91,
                52.912,
                52.914,
                52.917,
                52.919,
                52.92,
                52.922,
                52.924,
                52.926,
                52.928,
                52.929,
                52.931,
                52.933,
                52.936,
                52.937,
                52.939,
                52.941,
                52.943,
                52.945,
                52.946,
                52.948,
                52.95,
                52.953,
                52.954,
                52.956,
                52.958,
                52.96,
                52.962,
                52.963,
                52.965,
                52.967,
                52.969,
                52.971,
                52.973,
                52.975,
                52.977,
                52.978,
                52.98,
                52.982,
                52.984,
                52.985,
                52.987,
                52.99,
                52.991,
                52.993,
                52.995,
                52.997,
                52.998,
                53,
                53.002,
                53.004,
                53.005,
                53.007,
                53.01,
                53.011,
                53.013,
                53.015,
                53.017,
                53.018,
                53.02,
                53.022,
                53.023,
                53.025,
                53.028,
                53.03,
                53.031,
                53.033,
                53.035,
                53.036,
                53.038,
                53.04,
                53.041,
                53.043,
                53.046,
                53.047,
                53.049,
                53.051,
                53.052,
                53.054,
                53.056,
                53.057,
                53.059,
                53.061,
                53.063,
                53.065,
                53.067,
                53.068,
                53.07,
                53.072,
                53.073,
                53.075,
                53.077,
                53.078,
                53.081,
                53.083,
                53.084,
                53.086,
                53.088,
                53.089,
                53.091,
                53.093,
                53.094,
                53.096,
                53.098,
                53.1,
                53.102,
                53.103,
                53.105,
                53.107,
                53.108,
                53.11,
                53.112,
                53.113,
                53.115,
                53.117,
                53.119,
                53.121,
                53.122,
                53.124,
                53.126,
                53.127,
                53.129,
                53.13,
                53.132,
                53.134,
                53.136,
                53.138,
                53.139,
                53.141,
                53.142,
                53.144,
                53.146,
                53.147,
                53.149,
                53.151,
                53.153,
                53.155,
                53.156,
                53.158,
                53.159,
                53.161,
                53.163,
                53.164,
                53.166,
                53.167,
                53.169,
                53.172,
                53.173,
                53.175,
                53.176,
                53.178,
                53.179,
                53.181,
                53.182,
                53.184,
                53.186,
                53.188,
                53.19,
                53.191,
                53.193,
                53.194,
                53.196,
                53.198,
                53.199,
                53.201,
                53.202,
                53.204,
                53.206,
                53.208,
                53.209,
                53.211,
                53.212,
                53.214,
                53.216,
                53.217,
                53.219,
                53.22,
                53.222,
                53.224,
                53.226,
                53.227,
                53.229,
                53.23,
                53.232,
                53.233,
                53.235,
                53.236,
                53.238,
                53.24,
                53.242,
                53.244,
                53.245,
                53.247,
                53.248,
                53.25,
                53.251,
                53.253,
                53.254,
                53.256,
                53.258,
                53.26,
                53.261,
                53.263,
                53.264,
                53.266,
                53.267,
                53.269,
                53.27,
                53.272,
                53.273,
                53.276,
                53.277,
                53.279,
                53.28,
                53.282,
                53.283,
                53.285,
                53.286,
                53.288,
                53.289,
                53.291,
                53.293,
                53.295,
                53.296,
                53.297,
                53.299,
                53.3,
                53.302,
                53.303,
                53.305,
                53.306,
                53.308,
                53.31,
                53.312,
                53.313,
                53.315,
                53.316,
                53.318,
                53.319,
                53.321,
                53.322,
                53.324,
                53.325,
                53.327,
                53.329,
                53.33,
                53.332,
                53.333,
                53.335,
                53.336,
                53.338,
                53.339,
                53.341,
                53.342,
                53.343,
                53.346,
                53.347,
                53.349,
                53.35,
                53.352,
                53.353,
                53.355,
                53.356,
                53.357,
                53.359,
                53.36,
                53.363,
                53.364,
                53.366,
                53.367,
                53.368,
                53.37,
                53.371,
                53.373,
                53.374,
                53.376,
                53.377,
                53.379,
                53.381,
                53.382,
                53.384,
                53.385,
                53.387,
                53.388,
                53.389,
                53.391,
                53.392,
                53.394,
                53.396,
                53.398,
                53.399,
                53.4,
                53.402,
                53.403,
                53.405,
                53.406,
                53.407,
                53.409,
                53.41,
                53.412,
                53.414,
                53.415,
                53.417,
                53.418,
                53.42,
                53.421,
                53.423,
                53.424,
                53.425,
                53.427,
                53.428,
                53.43,
                53.432,
                53.433,
                53.435,
                53.436,
                53.438,
                53.439,
                53.44,
                53.442,
                53.443,
                53.444,
                53.447,
                53.448,
                53.45,
                53.451,
                53.452,
                53.454,
                53.455,
                53.457,
                53.458,
                53.459,
                53.461,
                53.462,
                53.464,
                53.466,
                53.467,
                53.469,
                53.47,
                53.471,
                53.473,
                53.474,
                53.476,
                53.477,
                53.478,
                53.48,
                53.482,
                53.483,
                53.485,
                53.486,
                53.487,
                53.489,
                53.49,
                53.492,
                53.493,
                53.494,
                53.496,
                53.498,
                53.499,
                53.501,
                53.502,
                53.503,
                53.505,
                53.506,
                53.508,
                53.509,
                53.51,
                53.512,
                53.513,
                53.515,
                53.517,
                53.518,
                53.519,
                53.521,
                53.522,
                53.524,
                53.525,
                53.526,
                53.528,
                53.529,
                53.53,
                53.533,
                53.534,
                53.535,
                53.537,
                53.538,
                53.539,
                53.541,
                53.542,
                53.543,
                53.545,
                53.546,
                53.548,
                53.55,
                53.551,
                53.552,
                53.554,
                53.555,
                53.556,
                53.558,
                53.559,
                53.56,
                53.562,
                53.563,
                53.565,
                53.567,
                53.568,
                53.569,
                53.571,
                53.572,
                53.573,
                53.575,
                53.576,
                53.577,
                53.579,
                53.58,
                53.582,
                53.584,
                53.585,
                53.586,
                53.588,
                53.589,
                53.59
            ],
            P99: [
                37.417,
                37.502,
                37.585,
                37.67,
                37.753,
                37.836,
                37.92,
                38.004,
                38.086,
                38.17,
                38.253,
                38.336,
                38.418,
                38.5,
                38.583,
                38.683,
                38.781,
                38.876,
                38.969,
                39.061,
                39.15,
                39.238,
                39.324,
                39.408,
                39.491,
                39.573,
                39.653,
                39.731,
                39.809,
                39.885,
                39.96,
                40.034,
                40.106,
                40.178,
                40.249,
                40.319,
                40.387,
                40.455,
                40.522,
                40.588,
                40.653,
                40.717,
                40.781,
                40.843,
                40.905,
                40.967,
                41.028,
                41.087,
                41.146,
                41.205,
                41.263,
                41.32,
                41.377,
                41.433,
                41.488,
                41.544,
                41.598,
                41.652,
                41.705,
                41.758,
                41.811,
                41.863,
                41.915,
                41.966,
                42.017,
                42.068,
                42.118,
                42.167,
                42.216,
                42.265,
                42.313,
                42.361,
                42.409,
                42.457,
                42.503,
                42.55,
                42.596,
                42.642,
                42.688,
                42.732,
                42.778,
                42.822,
                42.866,
                42.91,
                42.953,
                42.997,
                43.04,
                43.083,
                43.125,
                43.167,
                43.209,
                43.25,
                43.291,
                43.333,
                43.373,
                43.414,
                43.454,
                43.495,
                43.534,
                43.574,
                43.613,
                43.651,
                43.691,
                43.729,
                43.767,
                43.806,
                43.843,
                43.881,
                43.919,
                43.955,
                43.993,
                44.029,
                44.066,
                44.102,
                44.138,
                44.173,
                44.209,
                44.245,
                44.279,
                44.315,
                44.35,
                44.384,
                44.418,
                44.453,
                44.486,
                44.52,
                44.554,
                44.587,
                44.62,
                44.653,
                44.685,
                44.718,
                44.751,
                44.783,
                44.815,
                44.847,
                44.879,
                44.91,
                44.941,
                44.973,
                45.004,
                45.034,
                45.065,
                45.095,
                45.125,
                45.155,
                45.185,
                45.215,
                45.244,
                45.273,
                45.303,
                45.332,
                45.361,
                45.389,
                45.418,
                45.447,
                45.475,
                45.503,
                45.53,
                45.559,
                45.586,
                45.613,
                45.64,
                45.668,
                45.695,
                45.721,
                45.748,
                45.775,
                45.801,
                45.827,
                45.853,
                45.879,
                45.905,
                45.93,
                45.956,
                45.981,
                46.006,
                46.032,
                46.056,
                46.082,
                46.106,
                46.131,
                46.155,
                46.179,
                46.203,
                46.228,
                46.251,
                46.275,
                46.298,
                46.322,
                46.345,
                46.369,
                46.391,
                46.415,
                46.437,
                46.46,
                46.482,
                46.505,
                46.528,
                46.549,
                46.572,
                46.593,
                46.615,
                46.637,
                46.658,
                46.68,
                46.702,
                46.723,
                46.744,
                46.765,
                46.786,
                46.807,
                46.828,
                46.848,
                46.869,
                46.889,
                46.909,
                46.929,
                46.95,
                46.969,
                46.989,
                47.009,
                47.028,
                47.048,
                47.068,
                47.087,
                47.106,
                47.125,
                47.144,
                47.164,
                47.182,
                47.201,
                47.22,
                47.238,
                47.256,
                47.274,
                47.293,
                47.311,
                47.33,
                47.347,
                47.365,
                47.383,
                47.401,
                47.419,
                47.435,
                47.453,
                47.471,
                47.488,
                47.505,
                47.522,
                47.539,
                47.556,
                47.573,
                47.59,
                47.607,
                47.622,
                47.639,
                47.656,
                47.672,
                47.689,
                47.705,
                47.721,
                47.736,
                47.753,
                47.769,
                47.784,
                47.8,
                47.816,
                47.832,
                47.848,
                47.862,
                47.878,
                47.893,
                47.908,
                47.924,
                47.939,
                47.954,
                47.969,
                47.984,
                47.998,
                48.013,
                48.027,
                48.042,
                48.057,
                48.071,
                48.086,
                48.1,
                48.114,
                48.129,
                48.143,
                48.157,
                48.17,
                48.184,
                48.198,
                48.212,
                48.226,
                48.239,
                48.253,
                48.267,
                48.28,
                48.294,
                48.307,
                48.321,
                48.334,
                48.347,
                48.36,
                48.373,
                48.385,
                48.398,
                48.411,
                48.424,
                48.437,
                48.45,
                48.463,
                48.475,
                48.488,
                48.5,
                48.513,
                48.525,
                48.538,
                48.55,
                48.562,
                48.574,
                48.587,
                48.599,
                48.611,
                48.623,
                48.635,
                48.647,
                48.658,
                48.67,
                48.682,
                48.694,
                48.705,
                48.717,
                48.728,
                48.74,
                48.751,
                48.763,
                48.774,
                48.785,
                48.795,
                48.807,
                48.818,
                48.829,
                48.84,
                48.851,
                48.862,
                48.873,
                48.884,
                48.894,
                48.905,
                48.916,
                48.927,
                48.937,
                48.948,
                48.958,
                48.969,
                48.979,
                48.99,
                49,
                49.01,
                49.022,
                49.032,
                49.042,
                49.052,
                49.063,
                49.073,
                49.083,
                49.093,
                49.103,
                49.113,
                49.122,
                49.132,
                49.142,
                49.152,
                49.162,
                49.171,
                49.181,
                49.191,
                49.2,
                49.21,
                49.219,
                49.229,
                49.238,
                49.248,
                49.257,
                49.266,
                49.276,
                49.285,
                49.294,
                49.303,
                49.313,
                49.322,
                49.331,
                49.34,
                49.349,
                49.358,
                49.367,
                49.376,
                49.385,
                49.394,
                49.404,
                49.412,
                49.421,
                49.43,
                49.439,
                49.447,
                49.456,
                49.465,
                49.473,
                49.482,
                49.49,
                49.499,
                49.507,
                49.516,
                49.524,
                49.533,
                49.541,
                49.549,
                49.558,
                49.566,
                49.574,
                49.582,
                49.591,
                49.6,
                49.608,
                49.616,
                49.624,
                49.632,
                49.64,
                49.648,
                49.656,
                49.664,
                49.672,
                49.68,
                49.688,
                49.696,
                49.704,
                49.712,
                49.719,
                49.727,
                49.735,
                49.744,
                49.751,
                49.759,
                49.767,
                49.774,
                49.782,
                49.79,
                49.797,
                49.805,
                49.812,
                49.82,
                49.827,
                49.835,
                49.842,
                49.849,
                49.857,
                49.865,
                49.873,
                49.88,
                49.887,
                49.894,
                49.902,
                49.909,
                49.916,
                49.923,
                49.93,
                49.938,
                49.945,
                49.952,
                49.959,
                49.967,
                49.974,
                49.981,
                49.988,
                49.995,
                50.002,
                50.009,
                50.016,
                50.023,
                50.03,
                50.037,
                50.044,
                50.051,
                50.059,
                50.065,
                50.072,
                50.079,
                50.086,
                50.092,
                50.099,
                50.106,
                50.113,
                50.119,
                50.126,
                50.133,
                50.139,
                50.147,
                50.153,
                50.16,
                50.167,
                50.173,
                50.18,
                50.186,
                50.193,
                50.199,
                50.206,
                50.212,
                50.219,
                50.226,
                50.233,
                50.239,
                50.245,
                50.252,
                50.258,
                50.264,
                50.271,
                50.277,
                50.283,
                50.29,
                50.296,
                50.303,
                50.309,
                50.316,
                50.322,
                50.328,
                50.334,
                50.34,
                50.347,
                50.353,
                50.359,
                50.365,
                50.372,
                50.378,
                50.384,
                50.39,
                50.397,
                50.403,
                50.409,
                50.415,
                50.421,
                50.427,
                50.434,
                50.44,
                50.446,
                50.452,
                50.457,
                50.463,
                50.469,
                50.475,
                50.481,
                50.487,
                50.493,
                50.5,
                50.506,
                50.512,
                50.517,
                50.523,
                50.529,
                50.535,
                50.541,
                50.546,
                50.552,
                50.559,
                50.565,
                50.57,
                50.576,
                50.582,
                50.587,
                50.593,
                50.599,
                50.604,
                50.61,
                50.617,
                50.622,
                50.628,
                50.634,
                50.639,
                50.645,
                50.65,
                50.656,
                50.662,
                50.668,
                50.674,
                50.679,
                50.685,
                50.69,
                50.696,
                50.701,
                50.707,
                50.712,
                50.719,
                50.724,
                50.73,
                50.735,
                50.741,
                50.746,
                50.752,
                50.757,
                50.762,
                50.768,
                50.774,
                50.78,
                50.785,
                50.79,
                50.796,
                50.801,
                50.806,
                50.812,
                50.817,
                50.823,
                50.829,
                50.834,
                50.839,
                50.845,
                50.85,
                50.855,
                50.86,
                50.867,
                50.872,
                50.877,
                50.882,
                50.888,
                50.893,
                50.898,
                50.903,
                50.908,
                50.915,
                50.92,
                50.925,
                50.93,
                50.935,
                50.941,
                50.946,
                50.951,
                50.957,
                50.962,
                50.967,
                50.972,
                50.977,
                50.982,
                50.988,
                50.993,
                50.998,
                51.004,
                51.009,
                51.014,
                51.019,
                51.024,
                51.029,
                51.034,
                51.039,
                51.045,
                51.05,
                51.055,
                51.06,
                51.065,
                51.07,
                51.075,
                51.08,
                51.086,
                51.091,
                51.096,
                51.101,
                51.106,
                51.111,
                51.115,
                51.12,
                51.126,
                51.131,
                51.136,
                51.141,
                51.146,
                51.151,
                51.155,
                51.16,
                51.166,
                51.171,
                51.176,
                51.181,
                51.186,
                51.19,
                51.195,
                51.2,
                51.206,
                51.211,
                51.215,
                51.22,
                51.225,
                51.23,
                51.234,
                51.239,
                51.245,
                51.25,
                51.254,
                51.259,
                51.264,
                51.269,
                51.273,
                51.278,
                51.284,
                51.288,
                51.293,
                51.298,
                51.302,
                51.307,
                51.312,
                51.317,
                51.322,
                51.327,
                51.331,
                51.336,
                51.341,
                51.345,
                51.35,
                51.356,
                51.36,
                51.365,
                51.369,
                51.374,
                51.378,
                51.383,
                51.388,
                51.393,
                51.398,
                51.402,
                51.407,
                51.411,
                51.416,
                51.42,
                51.426,
                51.43,
                51.435,
                51.439,
                51.444,
                51.448,
                51.453,
                51.458,
                51.463,
                51.467,
                51.472,
                51.476,
                51.481,
                51.485,
                51.489,
                51.495,
                51.499,
                51.504,
                51.508,
                51.513,
                51.517,
                51.521,
                51.527,
                51.531,
                51.536,
                51.54,
                51.544,
                51.549,
                51.553,
                51.557,
                51.563,
                51.567,
                51.571,
                51.576,
                51.58,
                51.584,
                51.589,
                51.594,
                51.598,
                51.603,
                51.607,
                51.611,
                51.615,
                51.62,
                51.625,
                51.629,
                51.634,
                51.638,
                51.642,
                51.646,
                51.65,
                51.656,
                51.66,
                51.664,
                51.668,
                51.673,
                51.677,
                51.681,
                51.685,
                51.69,
                51.694,
                51.699,
                51.703,
                51.707,
                51.711,
                51.715,
                51.72,
                51.725,
                51.729,
                51.733,
                51.737,
                51.741,
                51.745,
                51.75,
                51.754,
                51.758,
                51.762,
                51.766,
                51.771,
                51.775,
                51.78,
                51.784,
                51.788,
                51.792,
                51.796,
                51.8,
                51.804,
                51.809,
                51.813,
                51.817,
                51.821,
                51.825,
                51.829,
                51.833,
                51.838,
                51.842,
                51.846,
                51.85,
                51.854,
                51.858,
                51.862,
                51.866,
                51.871,
                51.875,
                51.878,
                51.882,
                51.886,
                51.89,
                51.894,
                51.899,
                51.903,
                51.907,
                51.911,
                51.914,
                51.918,
                51.922,
                51.927,
                51.931,
                51.935,
                51.939,
                51.942,
                51.946,
                51.95,
                51.955,
                51.959,
                51.963,
                51.966,
                51.97,
                51.974,
                51.978,
                51.983,
                51.986,
                51.99,
                51.994,
                51.998,
                52.001,
                52.005,
                52.01,
                52.014,
                52.017,
                52.021,
                52.025,
                52.029,
                52.032,
                52.037,
                52.041,
                52.044,
                52.048,
                52.052,
                52.056,
                52.059,
                52.064,
                52.068,
                52.071,
                52.075,
                52.078,
                52.082,
                52.086,
                52.089,
                52.094,
                52.098,
                52.101,
                52.105,
                52.109,
                52.112,
                52.116,
                52.12,
                52.124,
                52.128,
                52.131,
                52.135,
                52.138,
                52.142,
                52.146,
                52.15,
                52.153,
                52.157,
                52.16,
                52.164,
                52.168,
                52.172,
                52.176,
                52.179,
                52.183,
                52.186,
                52.19,
                52.193,
                52.198,
                52.201,
                52.205,
                52.208,
                52.212,
                52.215,
                52.218,
                52.222,
                52.226,
                52.23,
                52.233,
                52.237,
                52.24,
                52.244,
                52.247,
                52.251,
                52.255,
                52.258,
                52.262,
                52.265,
                52.268,
                52.272,
                52.276,
                52.28,
                52.283,
                52.286,
                52.29,
                52.293,
                52.296,
                52.301,
                52.304,
                52.307,
                52.311,
                52.314,
                52.317,
                52.321,
                52.324,
                52.328,
                52.332,
                52.335,
                52.338,
                52.341,
                52.345,
                52.348,
                52.352,
                52.355,
                52.359,
                52.362,
                52.365,
                52.368,
                52.372,
                52.375,
                52.379,
                52.382,
                52.386,
                52.389,
                52.392,
                52.395,
                52.398,
                52.403,
                52.406,
                52.409,
                52.412,
                52.415,
                52.419,
                52.422,
                52.426,
                52.429,
                52.432,
                52.435,
                52.439,
                52.442,
                52.445,
                52.448,
                52.452,
                52.455,
                52.458,
                52.461,
                52.465,
                52.468,
                52.471,
                52.475,
                52.478,
                52.481,
                52.484,
                52.487,
                52.49,
                52.493,
                52.496,
                52.501,
                52.504,
                52.507,
                52.51,
                52.513,
                52.516,
                52.519,
                52.523,
                52.526,
                52.529,
                52.532,
                52.535,
                52.538,
                52.541,
                52.544,
                52.548,
                52.551,
                52.554,
                52.557,
                52.56,
                52.563,
                52.566,
                52.569,
                52.573,
                52.576,
                52.579,
                52.582,
                52.585,
                52.588,
                52.591,
                52.595,
                52.597,
                52.6,
                52.603,
                52.606,
                52.609,
                52.612,
                52.615,
                52.619,
                52.622,
                52.625,
                52.628,
                52.631,
                52.633,
                52.636,
                52.639,
                52.643,
                52.646,
                52.649,
                52.652,
                52.655,
                52.657,
                52.66,
                52.664,
                52.667,
                52.67,
                52.673,
                52.675,
                52.678,
                52.681,
                52.684,
                52.688,
                52.691,
                52.693,
                52.696,
                52.699,
                52.702,
                52.704,
                52.707,
                52.711,
                52.714,
                52.717,
                52.72,
                52.722,
                52.725,
                52.728,
                52.73,
                52.734,
                52.737,
                52.74,
                52.743,
                52.745,
                52.748,
                52.751,
                52.753,
                52.757,
                52.76,
                52.763,
                52.765,
                52.768,
                52.771,
                52.773,
                52.776,
                52.78,
                52.783,
                52.785,
                52.788,
                52.791,
                52.793,
                52.796,
                52.799,
                52.802,
                52.805,
                52.808,
                52.81,
                52.813,
                52.816,
                52.818,
                52.821,
                52.825,
                52.827,
                52.83,
                52.833,
                52.835,
                52.838,
                52.84,
                52.843,
                52.847,
                52.849,
                52.852,
                52.855,
                52.857,
                52.86,
                52.862,
                52.865,
                52.869,
                52.871,
                52.874,
                52.876,
                52.879,
                52.881,
                52.884,
                52.887,
                52.89,
                52.893,
                52.895,
                52.898,
                52.9,
                52.903,
                52.905,
                52.908,
                52.912,
                52.914,
                52.917,
                52.919,
                52.922,
                52.924,
                52.927,
                52.929,
                52.932,
                52.935,
                52.938,
                52.94,
                52.943,
                52.945,
                52.948,
                52.95,
                52.953,
                52.956,
                52.959,
                52.961,
                52.964,
                52.966,
                52.969,
                52.971,
                52.974,
                52.977,
                52.979,
                52.982,
                52.984,
                52.987,
                52.989,
                52.992,
                52.994,
                52.998,
                53,
                53.002,
                53.005,
                53.007,
                53.01,
                53.012,
                53.015,
                53.017,
                53.021,
                53.023,
                53.025,
                53.028,
                53.03,
                53.032,
                53.035,
                53.037,
                53.041,
                53.043,
                53.045,
                53.048,
                53.05,
                53.052,
                53.055,
                53.057,
                53.06,
                53.063,
                53.065,
                53.068,
                53.07,
                53.072,
                53.075,
                53.077,
                53.079,
                53.083,
                53.085,
                53.088,
                53.09,
                53.092,
                53.095,
                53.097,
                53.099,
                53.101,
                53.105,
                53.107,
                53.11,
                53.112,
                53.114,
                53.116,
                53.119,
                53.121,
                53.123,
                53.127,
                53.129,
                53.131,
                53.134,
                53.136,
                53.138,
                53.14,
                53.143,
                53.146,
                53.148,
                53.15,
                53.153,
                53.155,
                53.157,
                53.159,
                53.162,
                53.164,
                53.167,
                53.17,
                53.172,
                53.174,
                53.176,
                53.179,
                53.181,
                53.183,
                53.185,
                53.189,
                53.191,
                53.193,
                53.195,
                53.197,
                53.2,
                53.202,
                53.204,
                53.206,
                53.21,
                53.212,
                53.214,
                53.216,
                53.218,
                53.22,
                53.223,
                53.225,
                53.227,
                53.23,
                53.233,
                53.235,
                53.237,
                53.239,
                53.241,
                53.243,
                53.245,
                53.248,
                53.251,
                53.253,
                53.255,
                53.257,
                53.259,
                53.262,
                53.264,
                53.266,
                53.268,
                53.271,
                53.273,
                53.276,
                53.278,
                53.28,
                53.282,
                53.284,
                53.286,
                53.288,
                53.291,
                53.294,
                53.296,
                53.298,
                53.3,
                53.302,
                53.304,
                53.306,
                53.308,
                53.312,
                53.314,
                53.316,
                53.318,
                53.32,
                53.322,
                53.324,
                53.326,
                53.328,
                53.331,
                53.334,
                53.336,
                53.338,
                53.34,
                53.342,
                53.344,
                53.346,
                53.348,
                53.351,
                53.353,
                53.355,
                53.357,
                53.359,
                53.361,
                53.363,
                53.365,
                53.367,
                53.371,
                53.373,
                53.375,
                53.377,
                53.379,
                53.381,
                53.383,
                53.385,
                53.387,
                53.389,
                53.392,
                53.394,
                53.396,
                53.398,
                53.4,
                53.402,
                53.404,
                53.406,
                53.408,
                53.411,
                53.413,
                53.415,
                53.417,
                53.419,
                53.421,
                53.423,
                53.425,
                53.427,
                53.43,
                53.432,
                53.434,
                53.436,
                53.438,
                53.44,
                53.442,
                53.444,
                53.446,
                53.448,
                53.451,
                53.453,
                53.455,
                53.457,
                53.459,
                53.46,
                53.462,
                53.464,
                53.466,
                53.469,
                53.471,
                53.473,
                53.475,
                53.477,
                53.479,
                53.481,
                53.483,
                53.485,
                53.487,
                53.49,
                53.492,
                53.494,
                53.495,
                53.497,
                53.499,
                53.501,
                53.503,
                53.505,
                53.507,
                53.51,
                53.512,
                53.514,
                53.516,
                53.517,
                53.519,
                53.521,
                53.523,
                53.525,
                53.528,
                53.53,
                53.532,
                53.534,
                53.535,
                53.537,
                53.539,
                53.541,
                53.543,
                53.545,
                53.548,
                53.55,
                53.551,
                53.553,
                53.555,
                53.557,
                53.559,
                53.561,
                53.562,
                53.564,
                53.567,
                53.569,
                53.571,
                53.573,
                53.575,
                53.576,
                53.578,
                53.58,
                53.582,
                53.584,
                53.587,
                53.588,
                53.59,
                53.592,
                53.594,
                53.596,
                53.598,
                53.599,
                53.601,
                53.604,
                53.606,
                53.608,
                53.609,
                53.611,
                53.613,
                53.615,
                53.617,
                53.618,
                53.62,
                53.623,
                53.625,
                53.627,
                53.628,
                53.63,
                53.632,
                53.634,
                53.636,
                53.637,
                53.639,
                53.642,
                53.644,
                53.646,
                53.647,
                53.649,
                53.651,
                53.652,
                53.654,
                53.656,
                53.658,
                53.66,
                53.662,
                53.664,
                53.666,
                53.668,
                53.669,
                53.671,
                53.673,
                53.675,
                53.676,
                53.678,
                53.681,
                53.683,
                53.684,
                53.686,
                53.688,
                53.69,
                53.691,
                53.693,
                53.695,
                53.696,
                53.699,
                53.701,
                53.703,
                53.704,
                53.706,
                53.708,
                53.709,
                53.711,
                53.713,
                53.715,
                53.717,
                53.719,
                53.721,
                53.723,
                53.724,
                53.726,
                53.728,
                53.729,
                53.731,
                53.733,
                53.735,
                53.737,
                53.739,
                53.74,
                53.742,
                53.744,
                53.745,
                53.747,
                53.749,
                53.75,
                53.752,
                53.755,
                53.757,
                53.758,
                53.76,
                53.762,
                53.763,
                53.765,
                53.767,
                53.768,
                53.77,
                53.773,
                53.774,
                53.776,
                53.778,
                53.779,
                53.781,
                53.783,
                53.784,
                53.786,
                53.787,
                53.789,
                53.792,
                53.794,
                53.795,
                53.797,
                53.798,
                53.8,
                53.802,
                53.803,
                53.805,
                53.806,
                53.809,
                53.811,
                53.813,
                53.814,
                53.816,
                53.817,
                53.819,
                53.821,
                53.822,
                53.824,
                53.825,
                53.828,
                53.83,
                53.831,
                53.833,
                53.835,
                53.836,
                53.838,
                53.839,
                53.841,
                53.842,
                53.845,
                53.847,
                53.848,
                53.85,
                53.852,
                53.853,
                53.855,
                53.856,
                53.858,
                53.859,
                53.861,
                53.864,
                53.865,
                53.867,
                53.868,
                53.87,
                53.872,
                53.873,
                53.875,
                53.876,
                53.878,
                53.879,
                53.882,
                53.884,
                53.885,
                53.887,
                53.888,
                53.89,
                53.891,
                53.893,
                53.895,
                53.896,
                53.898,
                53.9,
                53.902,
                53.903,
                53.905,
                53.907,
                53.908,
                53.91,
                53.911,
                53.913,
                53.914,
                53.917,
                53.918,
                53.92,
                53.921,
                53.923,
                53.925,
                53.926,
                53.928,
                53.929,
                53.931,
                53.932,
                53.935,
                53.936,
                53.938,
                53.939,
                53.941,
                53.942,
                53.944,
                53.945,
                53.947,
                53.948,
                53.95,
                53.953,
                53.954,
                53.956,
                53.957,
                53.959,
                53.96,
                53.962,
                53.963,
                53.965,
                53.966,
                53.968,
                53.97,
                53.972,
                53.973,
                53.975,
                53.976,
                53.978,
                53.979,
                53.981,
                53.982,
                53.984,
                53.985,
                53.988,
                53.989,
                53.991,
                53.992,
                53.994,
                53.995,
                53.997,
                53.998,
                54,
                54.001,
                54.003,
                54.004,
                54.007,
                54.008,
                54.01,
                54.011,
                54.013,
                54.014,
                54.016,
                54.017,
                54.018,
                54.02,
                54.021,
                54.024,
                54.026,
                54.027,
                54.028,
                54.03,
                54.031,
                54.033,
                54.034,
                54.036,
                54.037,
                54.039,
                54.041,
                54.043,
                54.044,
                54.045,
                54.047,
                54.048,
                54.05,
                54.051,
                54.053,
                54.054,
                54.056,
                54.058,
                54.06,
                54.061,
                54.063,
                54.064,
                54.065,
                54.067,
                54.068,
                54.07,
                54.071,
                54.073,
                54.074,
                54.077,
                54.078,
                54.079,
                54.081,
                54.082,
                54.084,
                54.085,
                54.087,
                54.088,
                54.09,
                54.091,
                54.093,
                54.095,
                54.096,
                54.098,
                54.099,
                54.101,
                54.102,
                54.103,
                54.105,
                54.106,
                54.108,
                54.11,
                54.112,
                54.113,
                54.115,
                54.116,
                54.117,
                54.119,
                54.12,
                54.122,
                54.123,
                54.124,
                54.126,
                54.128,
                54.13,
                54.131,
                54.133,
                54.134,
                54.135,
                54.137,
                54.138,
                54.14,
                54.141,
                54.142,
                54.144,
                54.146,
                54.148,
                54.149,
                54.15,
                54.152,
                54.153,
                54.155,
                54.156,
                54.157,
                54.159,
                54.16,
                54.163,
                54.164,
                54.165,
                54.167,
                54.168,
                54.17,
                54.171,
                54.172,
                54.174,
                54.175,
                54.177,
                54.178,
                54.181,
                54.182,
                54.183,
                54.185,
                54.186,
                54.187,
                54.189,
                54.19,
                54.192,
                54.193,
                54.194,
                54.196,
                54.198,
                54.2,
                54.201,
                54.202,
                54.204,
                54.205,
                54.206,
                54.208,
                54.209,
                54.21,
                54.212,
                54.213,
                54.216,
                54.217,
                54.218,
                54.22,
                54.221,
                54.223,
                54.224,
                54.225,
                54.227,
                54.228,
                54.229,
                54.232,
                54.233,
                54.235,
                54.236,
                54.237,
                54.239,
                54.24,
                54.241,
                54.243,
                54.244,
                54.245,
                54.247,
                54.249,
                54.251,
                54.252,
                54.253,
                54.255,
                54.256,
                54.257
            ],
            P999: [
                38.387,
                38.467,
                38.545,
                38.624,
                38.702,
                38.78,
                38.859,
                38.936,
                39.013,
                39.091,
                39.168,
                39.246,
                39.322,
                39.399,
                39.476,
                39.575,
                39.673,
                39.768,
                39.861,
                39.953,
                40.042,
                40.13,
                40.215,
                40.3,
                40.383,
                40.465,
                40.545,
                40.623,
                40.701,
                40.776,
                40.852,
                40.926,
                40.998,
                41.071,
                41.141,
                41.211,
                41.279,
                41.347,
                41.414,
                41.48,
                41.545,
                41.61,
                41.674,
                41.737,
                41.798,
                41.86,
                41.921,
                41.98,
                42.04,
                42.099,
                42.157,
                42.214,
                42.271,
                42.327,
                42.383,
                42.439,
                42.493,
                42.547,
                42.601,
                42.653,
                42.707,
                42.759,
                42.81,
                42.862,
                42.914,
                42.964,
                43.015,
                43.064,
                43.114,
                43.163,
                43.211,
                43.259,
                43.307,
                43.355,
                43.402,
                43.448,
                43.495,
                43.541,
                43.587,
                43.632,
                43.678,
                43.722,
                43.767,
                43.811,
                43.854,
                43.898,
                43.941,
                43.985,
                44.028,
                44.069,
                44.111,
                44.153,
                44.195,
                44.236,
                44.277,
                44.318,
                44.358,
                44.399,
                44.439,
                44.479,
                44.518,
                44.557,
                44.597,
                44.636,
                44.674,
                44.713,
                44.751,
                44.788,
                44.826,
                44.863,
                44.901,
                44.938,
                44.975,
                45.011,
                45.048,
                45.083,
                45.119,
                45.156,
                45.19,
                45.226,
                45.261,
                45.295,
                45.331,
                45.365,
                45.399,
                45.433,
                45.467,
                45.501,
                45.535,
                45.567,
                45.6,
                45.633,
                45.666,
                45.699,
                45.731,
                45.764,
                45.796,
                45.827,
                45.859,
                45.891,
                45.922,
                45.953,
                45.984,
                46.014,
                46.045,
                46.075,
                46.105,
                46.135,
                46.165,
                46.194,
                46.225,
                46.254,
                46.283,
                46.312,
                46.34,
                46.37,
                46.398,
                46.426,
                46.454,
                46.483,
                46.511,
                46.538,
                46.566,
                46.594,
                46.621,
                46.647,
                46.675,
                46.702,
                46.728,
                46.755,
                46.781,
                46.807,
                46.834,
                46.859,
                46.886,
                46.911,
                46.936,
                46.962,
                46.987,
                47.013,
                47.037,
                47.063,
                47.087,
                47.112,
                47.136,
                47.161,
                47.184,
                47.209,
                47.232,
                47.257,
                47.28,
                47.304,
                47.326,
                47.35,
                47.373,
                47.396,
                47.418,
                47.442,
                47.465,
                47.487,
                47.51,
                47.531,
                47.554,
                47.576,
                47.597,
                47.62,
                47.642,
                47.663,
                47.685,
                47.706,
                47.727,
                47.748,
                47.77,
                47.79,
                47.811,
                47.832,
                47.852,
                47.873,
                47.894,
                47.913,
                47.933,
                47.954,
                47.973,
                47.993,
                48.013,
                48.033,
                48.052,
                48.071,
                48.091,
                48.111,
                48.129,
                48.148,
                48.167,
                48.187,
                48.204,
                48.223,
                48.242,
                48.261,
                48.279,
                48.297,
                48.315,
                48.333,
                48.352,
                48.37,
                48.387,
                48.405,
                48.422,
                48.44,
                48.458,
                48.474,
                48.492,
                48.509,
                48.527,
                48.544,
                48.561,
                48.577,
                48.594,
                48.611,
                48.628,
                48.644,
                48.661,
                48.678,
                48.693,
                48.709,
                48.726,
                48.742,
                48.758,
                48.774,
                48.79,
                48.806,
                48.821,
                48.836,
                48.852,
                48.868,
                48.883,
                48.899,
                48.914,
                48.93,
                48.945,
                48.959,
                48.974,
                48.989,
                49.004,
                49.019,
                49.034,
                49.048,
                49.063,
                49.078,
                49.092,
                49.107,
                49.121,
                49.134,
                49.148,
                49.163,
                49.177,
                49.191,
                49.205,
                49.219,
                49.233,
                49.247,
                49.26,
                49.274,
                49.288,
                49.301,
                49.315,
                49.328,
                49.342,
                49.353,
                49.367,
                49.38,
                49.393,
                49.406,
                49.419,
                49.432,
                49.445,
                49.458,
                49.471,
                49.484,
                49.496,
                49.509,
                49.521,
                49.534,
                49.546,
                49.559,
                49.571,
                49.583,
                49.595,
                49.608,
                49.62,
                49.632,
                49.644,
                49.656,
                49.668,
                49.68,
                49.691,
                49.703,
                49.715,
                49.726,
                49.738,
                49.75,
                49.761,
                49.771,
                49.783,
                49.794,
                49.805,
                49.817,
                49.828,
                49.839,
                49.85,
                49.861,
                49.872,
                49.883,
                49.894,
                49.905,
                49.916,
                49.927,
                49.937,
                49.948,
                49.959,
                49.969,
                49.98,
                49.991,
                50.002,
                50.013,
                50.023,
                50.034,
                50.044,
                50.054,
                50.065,
                50.075,
                50.085,
                50.095,
                50.105,
                50.115,
                50.125,
                50.135,
                50.145,
                50.155,
                50.165,
                50.175,
                50.185,
                50.194,
                50.204,
                50.214,
                50.223,
                50.233,
                50.243,
                50.252,
                50.262,
                50.271,
                50.28,
                50.29,
                50.299,
                50.308,
                50.318,
                50.327,
                50.336,
                50.345,
                50.355,
                50.364,
                50.373,
                50.382,
                50.392,
                50.401,
                50.41,
                50.419,
                50.428,
                50.437,
                50.446,
                50.455,
                50.463,
                50.472,
                50.481,
                50.489,
                50.498,
                50.507,
                50.515,
                50.524,
                50.532,
                50.541,
                50.549,
                50.558,
                50.566,
                50.575,
                50.583,
                50.593,
                50.601,
                50.609,
                50.618,
                50.626,
                50.634,
                50.642,
                50.65,
                50.659,
                50.667,
                50.675,
                50.683,
                50.691,
                50.699,
                50.707,
                50.715,
                50.723,
                50.731,
                50.74,
                50.748,
                50.756,
                50.763,
                50.771,
                50.779,
                50.787,
                50.794,
                50.802,
                50.81,
                50.817,
                50.825,
                50.833,
                50.84,
                50.848,
                50.855,
                50.864,
                50.872,
                50.879,
                50.887,
                50.894,
                50.901,
                50.909,
                50.916,
                50.924,
                50.931,
                50.938,
                50.945,
                50.953,
                50.96,
                50.969,
                50.976,
                50.983,
                50.99,
                50.997,
                51.004,
                51.011,
                51.018,
                51.026,
                51.033,
                51.04,
                51.047,
                51.054,
                51.062,
                51.069,
                51.076,
                51.083,
                51.09,
                51.097,
                51.103,
                51.11,
                51.117,
                51.124,
                51.131,
                51.138,
                51.144,
                51.153,
                51.159,
                51.166,
                51.173,
                51.179,
                51.186,
                51.193,
                51.199,
                51.206,
                51.213,
                51.219,
                51.226,
                51.234,
                51.24,
                51.247,
                51.253,
                51.26,
                51.266,
                51.273,
                51.279,
                51.286,
                51.292,
                51.298,
                51.305,
                51.313,
                51.319,
                51.325,
                51.332,
                51.338,
                51.344,
                51.351,
                51.357,
                51.363,
                51.369,
                51.376,
                51.383,
                51.39,
                51.396,
                51.402,
                51.408,
                51.414,
                51.42,
                51.427,
                51.433,
                51.439,
                51.446,
                51.453,
                51.459,
                51.465,
                51.471,
                51.477,
                51.483,
                51.489,
                51.495,
                51.501,
                51.507,
                51.514,
                51.52,
                51.526,
                51.532,
                51.538,
                51.544,
                51.55,
                51.556,
                51.562,
                51.567,
                51.575,
                51.581,
                51.586,
                51.592,
                51.598,
                51.604,
                51.61,
                51.615,
                51.621,
                51.627,
                51.634,
                51.64,
                51.646,
                51.651,
                51.657,
                51.663,
                51.669,
                51.674,
                51.68,
                51.687,
                51.693,
                51.698,
                51.704,
                51.71,
                51.715,
                51.721,
                51.727,
                51.732,
                51.739,
                51.745,
                51.75,
                51.756,
                51.761,
                51.767,
                51.772,
                51.778,
                51.783,
                51.789,
                51.796,
                51.801,
                51.807,
                51.812,
                51.818,
                51.823,
                51.829,
                51.834,
                51.84,
                51.846,
                51.852,
                51.857,
                51.863,
                51.868,
                51.873,
                51.879,
                51.884,
                51.891,
                51.896,
                51.902,
                51.907,
                51.912,
                51.918,
                51.923,
                51.928,
                51.933,
                51.94,
                51.945,
                51.951,
                51.956,
                51.961,
                51.967,
                51.972,
                51.977,
                51.984,
                51.989,
                51.994,
                51.999,
                52.004,
                52.01,
                52.015,
                52.02,
                52.025,
                52.032,
                52.037,
                52.042,
                52.047,
                52.052,
                52.057,
                52.063,
                52.068,
                52.074,
                52.079,
                52.084,
                52.089,
                52.095,
                52.1,
                52.105,
                52.11,
                52.116,
                52.121,
                52.126,
                52.131,
                52.136,
                52.141,
                52.146,
                52.151,
                52.158,
                52.163,
                52.168,
                52.173,
                52.178,
                52.183,
                52.188,
                52.193,
                52.199,
                52.204,
                52.209,
                52.214,
                52.219,
                52.223,
                52.228,
                52.233,
                52.24,
                52.245,
                52.249,
                52.254,
                52.259,
                52.264,
                52.269,
                52.274,
                52.28,
                52.285,
                52.29,
                52.294,
                52.299,
                52.304,
                52.309,
                52.314,
                52.32,
                52.325,
                52.329,
                52.334,
                52.339,
                52.344,
                52.348,
                52.355,
                52.359,
                52.364,
                52.369,
                52.373,
                52.378,
                52.383,
                52.388,
                52.394,
                52.398,
                52.403,
                52.408,
                52.412,
                52.417,
                52.422,
                52.426,
                52.433,
                52.437,
                52.442,
                52.446,
                52.451,
                52.456,
                52.46,
                52.466,
                52.471,
                52.475,
                52.48,
                52.485,
                52.489,
                52.494,
                52.5,
                52.504,
                52.509,
                52.513,
                52.518,
                52.522,
                52.527,
                52.531,
                52.537,
                52.542,
                52.546,
                52.551,
                52.555,
                52.56,
                52.564,
                52.57,
                52.575,
                52.579,
                52.584,
                52.588,
                52.593,
                52.597,
                52.601,
                52.607,
                52.612,
                52.616,
                52.62,
                52.625,
                52.629,
                52.634,
                52.639,
                52.644,
                52.648,
                52.653,
                52.657,
                52.661,
                52.666,
                52.671,
                52.676,
                52.68,
                52.684,
                52.689,
                52.693,
                52.697,
                52.703,
                52.707,
                52.712,
                52.716,
                52.72,
                52.724,
                52.729,
                52.733,
                52.739,
                52.743,
                52.747,
                52.751,
                52.756,
                52.76,
                52.764,
                52.77,
                52.774,
                52.778,
                52.782,
                52.786,
                52.791,
                52.795,
                52.801,
                52.805,
                52.809,
                52.813,
                52.817,
                52.821,
                52.825,
                52.831,
                52.835,
                52.839,
                52.843,
                52.847,
                52.851,
                52.856,
                52.861,
                52.865,
                52.869,
                52.873,
                52.877,
                52.881,
                52.886,
                52.891,
                52.895,
                52.899,
                52.903,
                52.907,
                52.911,
                52.915,
                52.919,
                52.925,
                52.929,
                52.933,
                52.937,
                52.941,
                52.945,
                52.949,
                52.954,
                52.958,
                52.962,
                52.966,
                52.97,
                52.974,
                52.978,
                52.983,
                52.987,
                52.991,
                52.995,
                52.999,
                53.003,
                53.007,
                53.012,
                53.016,
                53.02,
                53.024,
                53.027,
                53.031,
                53.035,
                53.041,
                53.044,
                53.048,
                53.052,
                53.056,
                53.06,
                53.063,
                53.069,
                53.073,
                53.076,
                53.08,
                53.084,
                53.088,
                53.092,
                53.097,
                53.101,
                53.104,
                53.108,
                53.112,
                53.116,
                53.119,
                53.124,
                53.128,
                53.132,
                53.136,
                53.139,
                53.143,
                53.147,
                53.15,
                53.156,
                53.159,
                53.163,
                53.167,
                53.17,
                53.174,
                53.178,
                53.183,
                53.186,
                53.19,
                53.194,
                53.197,
                53.201,
                53.205,
                53.21,
                53.213,
                53.217,
                53.22,
                53.224,
                53.228,
                53.231,
                53.236,
                53.24,
                53.244,
                53.247,
                53.251,
                53.254,
                53.258,
                53.263,
                53.266,
                53.27,
                53.273,
                53.277,
                53.28,
                53.284,
                53.287,
                53.292,
                53.296,
                53.299,
                53.303,
                53.306,
                53.31,
                53.313,
                53.318,
                53.322,
                53.325,
                53.329,
                53.332,
                53.336,
                53.339,
                53.344,
                53.347,
                53.351,
                53.354,
                53.358,
                53.361,
                53.364,
                53.369,
                53.373,
                53.376,
                53.379,
                53.383,
                53.386,
                53.389,
                53.393,
                53.398,
                53.401,
                53.404,
                53.408,
                53.411,
                53.414,
                53.418,
                53.423,
                53.426,
                53.429,
                53.432,
                53.436,
                53.439,
                53.442,
                53.446,
                53.45,
                53.454,
                53.457,
                53.46,
                53.463,
                53.467,
                53.47,
                53.475,
                53.478,
                53.481,
                53.484,
                53.488,
                53.491,
                53.494,
                53.499,
                53.502,
                53.505,
                53.509,
                53.512,
                53.515,
                53.518,
                53.521,
                53.526,
                53.529,
                53.532,
                53.535,
                53.539,
                53.542,
                53.545,
                53.55,
                53.553,
                53.556,
                53.559,
                53.562,
                53.565,
                53.568,
                53.571,
                53.576,
                53.579,
                53.582,
                53.585,
                53.588,
                53.592,
                53.595,
                53.599,
                53.602,
                53.605,
                53.608,
                53.611,
                53.614,
                53.618,
                53.621,
                53.625,
                53.628,
                53.631,
                53.634,
                53.637,
                53.64,
                53.643,
                53.646,
                53.651,
                53.654,
                53.657,
                53.66,
                53.663,
                53.666,
                53.669,
                53.673,
                53.676,
                53.679,
                53.682,
                53.685,
                53.688,
                53.691,
                53.694,
                53.699,
                53.702,
                53.705,
                53.707,
                53.71,
                53.713,
                53.716,
                53.719,
                53.724,
                53.727,
                53.729,
                53.732,
                53.735,
                53.738,
                53.741,
                53.745,
                53.748,
                53.751,
                53.754,
                53.757,
                53.76,
                53.763,
                53.765,
                53.77,
                53.773,
                53.776,
                53.778,
                53.781,
                53.784,
                53.787,
                53.79,
                53.794,
                53.797,
                53.8,
                53.803,
                53.805,
                53.808,
                53.811,
                53.814,
                53.818,
                53.821,
                53.824,
                53.826,
                53.829,
                53.832,
                53.835,
                53.838,
                53.842,
                53.845,
                53.847,
                53.85,
                53.853,
                53.856,
                53.858,
                53.861,
                53.865,
                53.868,
                53.871,
                53.874,
                53.876,
                53.879,
                53.882,
                53.884,
                53.889,
                53.891,
                53.894,
                53.897,
                53.9,
                53.902,
                53.905,
                53.908,
                53.912,
                53.914,
                53.917,
                53.92,
                53.922,
                53.925,
                53.928,
                53.93,
                53.935,
                53.937,
                53.94,
                53.943,
                53.945,
                53.948,
                53.95,
                53.953,
                53.957,
                53.96,
                53.962,
                53.965,
                53.968,
                53.97,
                53.973,
                53.976,
                53.98,
                53.982,
                53.985,
                53.987,
                53.99,
                53.993,
                53.995,
                53.998,
                54.002,
                54.004,
                54.007,
                54.01,
                54.012,
                54.015,
                54.017,
                54.02,
                54.022,
                54.026,
                54.029,
                54.031,
                54.034,
                54.037,
                54.039,
                54.042,
                54.044,
                54.048,
                54.051,
                54.053,
                54.056,
                54.058,
                54.061,
                54.063,
                54.066,
                54.07,
                54.072,
                54.075,
                54.077,
                54.08,
                54.082,
                54.085,
                54.087,
                54.091,
                54.094,
                54.096,
                54.099,
                54.101,
                54.103,
                54.106,
                54.108,
                54.111,
                54.115,
                54.117,
                54.12,
                54.122,
                54.124,
                54.127,
                54.129,
                54.132,
                54.136,
                54.138,
                54.141,
                54.143,
                54.145,
                54.148,
                54.15,
                54.153,
                54.155,
                54.159,
                54.161,
                54.164,
                54.166,
                54.168,
                54.171,
                54.173,
                54.176,
                54.18,
                54.182,
                54.184,
                54.187,
                54.189,
                54.191,
                54.194,
                54.196,
                54.198,
                54.202,
                54.205,
                54.207,
                54.209,
                54.212,
                54.214,
                54.216,
                54.219,
                54.221,
                54.225,
                54.227,
                54.23,
                54.232,
                54.234,
                54.236,
                54.239,
                54.241,
                54.245,
                54.247,
                54.25,
                54.252,
                54.254,
                54.256,
                54.259,
                54.261,
                54.263,
                54.267,
                54.269,
                54.272,
                54.274,
                54.276,
                54.279,
                54.281,
                54.283,
                54.285,
                54.289,
                54.291,
                54.294,
                54.296,
                54.298,
                54.3,
                54.303,
                54.305,
                54.307,
                54.311,
                54.313,
                54.315,
                54.318,
                54.32,
                54.322,
                54.324,
                54.327,
                54.329,
                54.332,
                54.335,
                54.337,
                54.339,
                54.341,
                54.344,
                54.346,
                54.348,
                54.35,
                54.354,
                54.356,
                54.358,
                54.36,
                54.363,
                54.365,
                54.367,
                54.369,
                54.371,
                54.375,
                54.377,
                54.379,
                54.382,
                54.384,
                54.386,
                54.388,
                54.39,
                54.392,
                54.396,
                54.398,
                54.4,
                54.403,
                54.405,
                54.407,
                54.409,
                54.411,
                54.413,
                54.417,
                54.419,
                54.421,
                54.423,
                54.425,
                54.427,
                54.43,
                54.432,
                54.434,
                54.437,
                54.44,
                54.442,
                54.444,
                54.446,
                54.448,
                54.45,
                54.452,
                54.454,
                54.458,
                54.46,
                54.462,
                54.464,
                54.466,
                54.468,
                54.47,
                54.473,
                54.475,
                54.478,
                54.48,
                54.482,
                54.484,
                54.486,
                54.488,
                54.491,
                54.493,
                54.495,
                54.497,
                54.5,
                54.502,
                54.504,
                54.506,
                54.509,
                54.51,
                54.513,
                54.515,
                54.517,
                54.52,
                54.522,
                54.524,
                54.526,
                54.528,
                54.53,
                54.532,
                54.534,
                54.536,
                54.54,
                54.542,
                54.544,
                54.546,
                54.548,
                54.55,
                54.552,
                54.554,
                54.556,
                54.558,
                54.561,
                54.563,
                54.565,
                54.567,
                54.569,
                54.571,
                54.573,
                54.575,
                54.577,
                54.581,
                54.583,
                54.585,
                54.587,
                54.589,
                54.591,
                54.593,
                54.594,
                54.596,
                54.598,
                54.602,
                54.604,
                54.606,
                54.608,
                54.61,
                54.612,
                54.614,
                54.615,
                54.617,
                54.619,
                54.623,
                54.625,
                54.627,
                54.629,
                54.63,
                54.632,
                54.634,
                54.636,
                54.638,
                54.642,
                54.644,
                54.645,
                54.647,
                54.649,
                54.651,
                54.653,
                54.655,
                54.657,
                54.659,
                54.662,
                54.664,
                54.666,
                54.668,
                54.67,
                54.672,
                54.673,
                54.675,
                54.677,
                54.679,
                54.683,
                54.684,
                54.686,
                54.688,
                54.69,
                54.692,
                54.694,
                54.696,
                54.697,
                54.699,
                54.703,
                54.704,
                54.706,
                54.708,
                54.71,
                54.712,
                54.714,
                54.716,
                54.717,
                54.721,
                54.723,
                54.724,
                54.726,
                54.728,
                54.73,
                54.732,
                54.734,
                54.735,
                54.737,
                54.741,
                54.742,
                54.744,
                54.746,
                54.748,
                54.75,
                54.752,
                54.753,
                54.755,
                54.757,
                54.76,
                54.762,
                54.764,
                54.766,
                54.767,
                54.769,
                54.771,
                54.773,
                54.775,
                54.776,
                54.778,
                54.781,
                54.783,
                54.785,
                54.787,
                54.789,
                54.79,
                54.792,
                54.794,
                54.796,
                54.797,
                54.801,
                54.803,
                54.804,
                54.806,
                54.808,
                54.809,
                54.811,
                54.813,
                54.815,
                54.816,
                54.82,
                54.822,
                54.823,
                54.825,
                54.827,
                54.828,
                54.83,
                54.832,
                54.834,
                54.835,
                54.839,
                54.84,
                54.842,
                54.844,
                54.846,
                54.847,
                54.849,
                54.851,
                54.852,
                54.854,
                54.857,
                54.859,
                54.861,
                54.863,
                54.864,
                54.866,
                54.868,
                54.869,
                54.871,
                54.873,
                54.875,
                54.878,
                54.879,
                54.881,
                54.883,
                54.884,
                54.886,
                54.888,
                54.89,
                54.891,
                54.893,
                54.896,
                54.898,
                54.9,
                54.901,
                54.903,
                54.905,
                54.906,
                54.908,
                54.91,
                54.911,
                54.913,
                54.916,
                54.918,
                54.919,
                54.921,
                54.923,
                54.924,
                54.926,
                54.928,
                54.929,
                54.931,
                54.934,
                54.936,
                54.938,
                54.939,
                54.941,
                54.942,
                54.944,
                54.946,
                54.947,
                54.949,
                54.951,
                54.954,
                54.955,
                54.957,
                54.959,
                54.96,
                54.962,
                54.964,
                54.965,
                54.967,
                54.968,
                54.972,
                54.973,
                54.975,
                54.977,
                54.978,
                54.98,
                54.981,
                54.983,
                54.985,
                54.986,
                54.988,
                54.991,
                54.993,
                54.994,
                54.996,
                54.997,
                54.999,
                55.001,
                55.002,
                55.004,
                55.005,
                55.007,
                55.01,
                55.012,
                55.013,
                55.015,
                55.016,
                55.018,
                55.02,
                55.021,
                55.023,
                55.024,
                55.026,
                55.029,
                55.031,
                55.032,
                55.034,
                55.035,
                55.037,
                55.038,
                55.04,
                55.042,
                55.043,
                55.046,
                55.048,
                55.049,
                55.051,
                55.053,
                55.054,
                55.056,
                55.057,
                55.059,
                55.06,
                55.062,
                55.065,
                55.066,
                55.068,
                55.07,
                55.071,
                55.073,
                55.074,
                55.076,
                55.077,
                55.079,
                55.08,
                55.084,
                55.085,
                55.087,
                55.088,
                55.09,
                55.091,
                55.093,
                55.094,
                55.096,
                55.097,
                55.099,
                55.102,
                55.103,
                55.105,
                55.106,
                55.108,
                55.11,
                55.111,
                55.113,
                55.114,
                55.116,
                55.117,
                55.12,
                55.122,
                55.123,
                55.125,
                55.126,
                55.128,
                55.129,
                55.131,
                55.132,
                55.134,
                55.135,
                55.137,
                55.14,
                55.141,
                55.143,
                55.144,
                55.146,
                55.147,
                55.149,
                55.15,
                55.152,
                55.153,
                55.155,
                55.158,
                55.159,
                55.161,
                55.162,
                55.164,
                55.165,
                55.167,
                55.168,
                55.17,
                55.171,
                55.173,
                55.176,
                55.177,
                55.179,
                55.18,
                55.182,
                55.183,
                55.185,
                55.186,
                55.188,
                55.189,
                55.19,
                55.194,
                55.195,
                55.196,
                55.198,
                55.199,
                55.201,
                55.202,
                55.204,
                55.205,
                55.207,
                55.208,
                55.21,
                55.213,
                55.214,
                55.216,
                55.217,
                55.218,
                55.22,
                55.221,
                55.223,
                55.224,
                55.226,
                55.227,
                55.23,
                55.232,
                55.233,
                55.235,
                55.236,
                55.237,
                55.239,
                55.24,
                55.242,
                55.243,
                55.245,
                55.248,
                55.249,
                55.251,
                55.252,
                55.253,
                55.255,
                55.256,
                55.258,
                55.259,
                55.261,
                55.262,
                55.263,
                55.266,
                55.268,
                55.269,
                55.271,
                55.272,
                55.274,
                55.275,
                55.276,
                55.278,
                55.279,
                55.281,
                55.282,
                55.285,
                55.287,
                55.288,
                55.289,
                55.291,
                55.292,
                55.294,
                55.295,
                55.296,
                55.298,
                55.299,
                55.302,
                55.304,
                55.305,
                55.307,
                55.308,
                55.309,
                55.311,
                55.312,
                55.314,
                55.315,
                55.317,
                55.318,
                55.321,
                55.322,
                55.324,
                55.325,
                55.326,
                55.328,
                55.329,
                55.331,
                55.332,
                55.334,
                55.335,
                55.336,
                55.339,
                55.341,
                55.342,
                55.343,
                55.345,
                55.346,
                55.348,
                55.349,
                55.35,
                55.352,
                55.353,
                55.355,
                55.358,
                55.359,
                55.36,
                55.362,
                55.363,
                55.365,
                55.366,
                55.367,
                55.369,
                55.37,
                55.372,
                55.374,
                55.376,
                55.377,
                55.379,
                55.38,
                55.381,
                55.383,
                55.384,
                55.386,
                55.387,
                55.388,
                55.39,
                55.393,
                55.394,
                55.395,
                55.397,
                55.398,
                55.399,
                55.401
            ]
        },
        girls: {
            P01: [
                30.219,
                30.322,
                30.427,
                30.531,
                30.636,
                30.741,
                30.846,
                30.95,
                31.056,
                31.16,
                31.266,
                31.371,
                31.477,
                31.582,
                31.688,
                31.776,
                31.862,
                31.946,
                32.028,
                32.109,
                32.188,
                32.264,
                32.34,
                32.414,
                32.487,
                32.557,
                32.627,
                32.695,
                32.762,
                32.828,
                32.893,
                32.957,
                33.02,
                33.08,
                33.141,
                33.201,
                33.26,
                33.318,
                33.375,
                33.431,
                33.487,
                33.541,
                33.596,
                33.649,
                33.702,
                33.754,
                33.805,
                33.856,
                33.906,
                33.955,
                34.005,
                34.054,
                34.101,
                34.149,
                34.196,
                34.243,
                34.289,
                34.335,
                34.38,
                34.424,
                34.469,
                34.513,
                34.556,
                34.599,
                34.643,
                34.686,
                34.728,
                34.77,
                34.812,
                34.853,
                34.894,
                34.933,
                34.973,
                35.013,
                35.053,
                35.093,
                35.132,
                35.171,
                35.209,
                35.248,
                35.286,
                35.322,
                35.36,
                35.397,
                35.434,
                35.471,
                35.508,
                35.543,
                35.579,
                35.615,
                35.651,
                35.686,
                35.72,
                35.755,
                35.79,
                35.825,
                35.858,
                35.892,
                35.926,
                35.96,
                35.993,
                36.026,
                36.059,
                36.092,
                36.124,
                36.157,
                36.189,
                36.22,
                36.252,
                36.284,
                36.315,
                36.346,
                36.378,
                36.408,
                36.439,
                36.47,
                36.499,
                36.53,
                36.56,
                36.589,
                36.619,
                36.648,
                36.677,
                36.707,
                36.735,
                36.764,
                36.792,
                36.821,
                36.85,
                36.877,
                36.905,
                36.932,
                36.96,
                36.988,
                37.015,
                37.042,
                37.069,
                37.096,
                37.122,
                37.149,
                37.176,
                37.201,
                37.228,
                37.253,
                37.28,
                37.305,
                37.331,
                37.355,
                37.381,
                37.405,
                37.431,
                37.455,
                37.48,
                37.504,
                37.529,
                37.553,
                37.577,
                37.602,
                37.625,
                37.65,
                37.672,
                37.695,
                37.719,
                37.742,
                37.766,
                37.788,
                37.811,
                37.833,
                37.857,
                37.879,
                37.902,
                37.923,
                37.946,
                37.967,
                37.99,
                38.011,
                38.033,
                38.054,
                38.076,
                38.097,
                38.118,
                38.14,
                38.16,
                38.181,
                38.202,
                38.223,
                38.243,
                38.264,
                38.284,
                38.303,
                38.324,
                38.344,
                38.364,
                38.384,
                38.404,
                38.423,
                38.442,
                38.462,
                38.481,
                38.501,
                38.519,
                38.539,
                38.557,
                38.576,
                38.595,
                38.613,
                38.632,
                38.65,
                38.668,
                38.687,
                38.705,
                38.723,
                38.741,
                38.758,
                38.777,
                38.794,
                38.812,
                38.829,
                38.846,
                38.864,
                38.881,
                38.897,
                38.915,
                38.932,
                38.95,
                38.966,
                38.982,
                38.999,
                39.016,
                39.032,
                39.049,
                39.065,
                39.082,
                39.097,
                39.113,
                39.13,
                39.145,
                39.16,
                39.177,
                39.192,
                39.207,
                39.224,
                39.239,
                39.255,
                39.27,
                39.284,
                39.3,
                39.315,
                39.329,
                39.345,
                39.36,
                39.374,
                39.39,
                39.404,
                39.418,
                39.433,
                39.447,
                39.461,
                39.476,
                39.49,
                39.504,
                39.519,
                39.532,
                39.546,
                39.561,
                39.574,
                39.587,
                39.602,
                39.615,
                39.628,
                39.643,
                39.656,
                39.669,
                39.683,
                39.696,
                39.708,
                39.722,
                39.735,
                39.748,
                39.762,
                39.774,
                39.787,
                39.8,
                39.813,
                39.825,
                39.838,
                39.851,
                39.863,
                39.875,
                39.888,
                39.9,
                39.912,
                39.925,
                39.937,
                39.948,
                39.962,
                39.973,
                39.985,
                39.998,
                40.009,
                40.02,
                40.032,
                40.045,
                40.056,
                40.067,
                40.079,
                40.091,
                40.102,
                40.113,
                40.125,
                40.136,
                40.147,
                40.159,
                40.17,
                40.181,
                40.193,
                40.203,
                40.214,
                40.225,
                40.237,
                40.247,
                40.257,
                40.269,
                40.28,
                40.29,
                40.3,
                40.312,
                40.322,
                40.332,
                40.342,
                40.354,
                40.364,
                40.374,
                40.385,
                40.395,
                40.405,
                40.415,
                40.426,
                40.436,
                40.445,
                40.455,
                40.466,
                40.476,
                40.485,
                40.496,
                40.505,
                40.515,
                40.524,
                40.535,
                40.544,
                40.554,
                40.563,
                40.574,
                40.583,
                40.592,
                40.601,
                40.611,
                40.621,
                40.63,
                40.64,
                40.649,
                40.658,
                40.667,
                40.677,
                40.686,
                40.695,
                40.703,
                40.713,
                40.722,
                40.731,
                40.739,
                40.749,
                40.758,
                40.767,
                40.775,
                40.785,
                40.793,
                40.802,
                40.81,
                40.82,
                40.828,
                40.837,
                40.845,
                40.854,
                40.863,
                40.871,
                40.879,
                40.888,
                40.897,
                40.905,
                40.913,
                40.922,
                40.93,
                40.938,
                40.946,
                40.955,
                40.963,
                40.971,
                40.979,
                40.987,
                40.996,
                41.003,
                41.011,
                41.019,
                41.028,
                41.036,
                41.043,
                41.051,
                41.06,
                41.067,
                41.075,
                41.082,
                41.091,
                41.099,
                41.106,
                41.113,
                41.121,
                41.129,
                41.137,
                41.144,
                41.151,
                41.16,
                41.167,
                41.174,
                41.182,
                41.189,
                41.197,
                41.205,
                41.212,
                41.219,
                41.227,
                41.234,
                41.241,
                41.248,
                41.257,
                41.264,
                41.27,
                41.277,
                41.284,
                41.292,
                41.299,
                41.306,
                41.313,
                41.321,
                41.328,
                41.335,
                41.341,
                41.348,
                41.356,
                41.363,
                41.37,
                41.376,
                41.383,
                41.391,
                41.397,
                41.404,
                41.41,
                41.418,
                41.425,
                41.431,
                41.438,
                41.444,
                41.452,
                41.458,
                41.465,
                41.471,
                41.478,
                41.485,
                41.492,
                41.498,
                41.504,
                41.51,
                41.518,
                41.524,
                41.531,
                41.537,
                41.544,
                41.551,
                41.557,
                41.563,
                41.569,
                41.577,
                41.583,
                41.589,
                41.595,
                41.601,
                41.608,
                41.614,
                41.62,
                41.626,
                41.632,
                41.64,
                41.646,
                41.652,
                41.658,
                41.663,
                41.671,
                41.677,
                41.683,
                41.688,
                41.694,
                41.702,
                41.707,
                41.713,
                41.719,
                41.725,
                41.732,
                41.738,
                41.743,
                41.749,
                41.755,
                41.762,
                41.768,
                41.773,
                41.779,
                41.785,
                41.792,
                41.797,
                41.803,
                41.808,
                41.814,
                41.821,
                41.827,
                41.832,
                41.838,
                41.843,
                41.849,
                41.856,
                41.861,
                41.867,
                41.872,
                41.878,
                41.884,
                41.89,
                41.895,
                41.901,
                41.906,
                41.913,
                41.918,
                41.924,
                41.929,
                41.935,
                41.94,
                41.947,
                41.952,
                41.957,
                41.963,
                41.968,
                41.975,
                41.98,
                41.985,
                41.991,
                41.996,
                42.002,
                42.008,
                42.013,
                42.018,
                42.023,
                42.029,
                42.035,
                42.04,
                42.045,
                42.051,
                42.056,
                42.062,
                42.068,
                42.073,
                42.078,
                42.083,
                42.088,
                42.095,
                42.1,
                42.105,
                42.11,
                42.115,
                42.12,
                42.126,
                42.131,
                42.136,
                42.141,
                42.146,
                42.153,
                42.158,
                42.163,
                42.168,
                42.173,
                42.178,
                42.184,
                42.189,
                42.194,
                42.199,
                42.204,
                42.209,
                42.215,
                42.22,
                42.225,
                42.23,
                42.235,
                42.241,
                42.246,
                42.251,
                42.256,
                42.261,
                42.266,
                42.272,
                42.277,
                42.282,
                42.286,
                42.291,
                42.296,
                42.302,
                42.307,
                42.312,
                42.317,
                42.321,
                42.326,
                42.332,
                42.337,
                42.342,
                42.347,
                42.352,
                42.356,
                42.362,
                42.367,
                42.372,
                42.377,
                42.381,
                42.386,
                42.392,
                42.397,
                42.402,
                42.406,
                42.411,
                42.416,
                42.422,
                42.426,
                42.431,
                42.436,
                42.44,
                42.445,
                42.451,
                42.456,
                42.46,
                42.465,
                42.47,
                42.474,
                42.48,
                42.485,
                42.489,
                42.494,
                42.499,
                42.503,
                42.508,
                42.514,
                42.518,
                42.523,
                42.527,
                42.532,
                42.537,
                42.543,
                42.547,
                42.552,
                42.556,
                42.561,
                42.565,
                42.571,
                42.576,
                42.58,
                42.585,
                42.589,
                42.594,
                42.6,
                42.604,
                42.608,
                42.613,
                42.617,
                42.622,
                42.626,
                42.632,
                42.637,
                42.641,
                42.646,
                42.65,
                42.654,
                42.66,
                42.665,
                42.669,
                42.674,
                42.678,
                42.682,
                42.687,
                42.693,
                42.697,
                42.701,
                42.706,
                42.71,
                42.714,
                42.72,
                42.725,
                42.729,
                42.733,
                42.738,
                42.742,
                42.746,
                42.752,
                42.756,
                42.761,
                42.765,
                42.769,
                42.774,
                42.778,
                42.784,
                42.788,
                42.792,
                42.796,
                42.801,
                42.805,
                42.811,
                42.815,
                42.819,
                42.824,
                42.828,
                42.832,
                42.836,
                42.842,
                42.846,
                42.85,
                42.855,
                42.859,
                42.863,
                42.867,
                42.873,
                42.877,
                42.881,
                42.885,
                42.89,
                42.894,
                42.898,
                42.904,
                42.908,
                42.912,
                42.916,
                42.92,
                42.924,
                42.93,
                42.934,
                42.938,
                42.942,
                42.946,
                42.951,
                42.955,
                42.96,
                42.964,
                42.968,
                42.972,
                42.977,
                42.981,
                42.985,
                42.99,
                42.994,
                42.998,
                43.002,
                43.006,
                43.011,
                43.015,
                43.02,
                43.024,
                43.028,
                43.032,
                43.036,
                43.04,
                43.044,
                43.048,
                43.054,
                43.058,
                43.062,
                43.066,
                43.07,
                43.074,
                43.078,
                43.083,
                43.087,
                43.091,
                43.095,
                43.099,
                43.103,
                43.107,
                43.112,
                43.116,
                43.12,
                43.124,
                43.128,
                43.132,
                43.135,
                43.141,
                43.145,
                43.149,
                43.152,
                43.156,
                43.16,
                43.164,
                43.169,
                43.173,
                43.177,
                43.181,
                43.185,
                43.189,
                43.192,
                43.196,
                43.201,
                43.205,
                43.209,
                43.213,
                43.217,
                43.22,
                43.224,
                43.23,
                43.233,
                43.237,
                43.241,
                43.245,
                43.248,
                43.252,
                43.256,
                43.261,
                43.265,
                43.269,
                43.272,
                43.276,
                43.28,
                43.283,
                43.289,
                43.292,
                43.296,
                43.3,
                43.303,
                43.307,
                43.311,
                43.314,
                43.319,
                43.323,
                43.327,
                43.33,
                43.334,
                43.338,
                43.341,
                43.346,
                43.35,
                43.354,
                43.357,
                43.361,
                43.364,
                43.368,
                43.372,
                43.377,
                43.38,
                43.384,
                43.387,
                43.391,
                43.395,
                43.398,
                43.402,
                43.407,
                43.41,
                43.414,
                43.417,
                43.421,
                43.424,
                43.428,
                43.431,
                43.436,
                43.44,
                43.443,
                43.447,
                43.45,
                43.454,
                43.457,
                43.461,
                43.465,
                43.469,
                43.472,
                43.476,
                43.479,
                43.483,
                43.486,
                43.489,
                43.494,
                43.498,
                43.501,
                43.505,
                43.508,
                43.511,
                43.515,
                43.52,
                43.523,
                43.526,
                43.53,
                43.533,
                43.536,
                43.54,
                43.543,
                43.546,
                43.551,
                43.555,
                43.558,
                43.561,
                43.564,
                43.568,
                43.571,
                43.574,
                43.579,
                43.583,
                43.586,
                43.589,
                43.592,
                43.596,
                43.599,
                43.602,
                43.607,
                43.61,
                43.613,
                43.617,
                43.62,
                43.623,
                43.626,
                43.629,
                43.634,
                43.637,
                43.641,
                43.644,
                43.647,
                43.65,
                43.653,
                43.656,
                43.661,
                43.664,
                43.667,
                43.671,
                43.674,
                43.677,
                43.68,
                43.683,
                43.686,
                43.691,
                43.694,
                43.697,
                43.7,
                43.703,
                43.706,
                43.71,
                43.713,
                43.717,
                43.72,
                43.723,
                43.727,
                43.73,
                43.733,
                43.736,
                43.739,
                43.743,
                43.746,
                43.749,
                43.752,
                43.755,
                43.758,
                43.762,
                43.765,
                43.768,
                43.772,
                43.775,
                43.778,
                43.781,
                43.784,
                43.787,
                43.79,
                43.793,
                43.797,
                43.8,
                43.803,
                43.806,
                43.809,
                43.812,
                43.815,
                43.818,
                43.821,
                43.826,
                43.829,
                43.831,
                43.834,
                43.837,
                43.84,
                43.843,
                43.846,
                43.849,
                43.853,
                43.856,
                43.859,
                43.862,
                43.865,
                43.868,
                43.871,
                43.874,
                43.876,
                43.881,
                43.884,
                43.886,
                43.889,
                43.892,
                43.895,
                43.898,
                43.901,
                43.905,
                43.908,
                43.911,
                43.913,
                43.916,
                43.919,
                43.922,
                43.925,
                43.928,
                43.932,
                43.935,
                43.937,
                43.94,
                43.943,
                43.946,
                43.948,
                43.951,
                43.954,
                43.958,
                43.961,
                43.964,
                43.967,
                43.969,
                43.972,
                43.975,
                43.977,
                43.98,
                43.984,
                43.987,
                43.99,
                43.993,
                43.995,
                43.998,
                44.001,
                44.003,
                44.006,
                44.01,
                44.013,
                44.016,
                44.018,
                44.021,
                44.024,
                44.026,
                44.029,
                44.032,
                44.036,
                44.038,
                44.041,
                44.044,
                44.046,
                44.049,
                44.052,
                44.054,
                44.057,
                44.06,
                44.064,
                44.066,
                44.069,
                44.072,
                44.074,
                44.077,
                44.079,
                44.082,
                44.085,
                44.089,
                44.091,
                44.094,
                44.096,
                44.099,
                44.102,
                44.104,
                44.107,
                44.109,
                44.113,
                44.116,
                44.118,
                44.121,
                44.124,
                44.126,
                44.129,
                44.131,
                44.134,
                44.138,
                44.14,
                44.143,
                44.145,
                44.148,
                44.15,
                44.153,
                44.155,
                44.158,
                44.16,
                44.164,
                44.167,
                44.169,
                44.172,
                44.174,
                44.177,
                44.179,
                44.182,
                44.184,
                44.188,
                44.191,
                44.193,
                44.196,
                44.198,
                44.2,
                44.203,
                44.205,
                44.208,
                44.21,
                44.214,
                44.217,
                44.219,
                44.222,
                44.224,
                44.226,
                44.229,
                44.231,
                44.234,
                44.236,
                44.24,
                44.242,
                44.245,
                44.247,
                44.25,
                44.252,
                44.254,
                44.257,
                44.259,
                44.263,
                44.265,
                44.268,
                44.27,
                44.273,
                44.275,
                44.277,
                44.28,
                44.282,
                44.284,
                44.288,
                44.291,
                44.293,
                44.295,
                44.298,
                44.3,
                44.302,
                44.305,
                44.307,
                44.309,
                44.313,
                44.315,
                44.318,
                44.32,
                44.322,
                44.325,
                44.327,
                44.329,
                44.332,
                44.334,
                44.338,
                44.34,
                44.342,
                44.344,
                44.347,
                44.349,
                44.351,
                44.354,
                44.356,
                44.358,
                44.362,
                44.364,
                44.366,
                44.369,
                44.371,
                44.373,
                44.375,
                44.378,
                44.38,
                44.382,
                44.386,
                44.388,
                44.39,
                44.393,
                44.395,
                44.397,
                44.399,
                44.402,
                44.404,
                44.406,
                44.41,
                44.412,
                44.414,
                44.416,
                44.419,
                44.421,
                44.423,
                44.425,
                44.427,
                44.43,
                44.433,
                44.435,
                44.438,
                44.44,
                44.442,
                44.444,
                44.446,
                44.449,
                44.451,
                44.453,
                44.457,
                44.459,
                44.461,
                44.463,
                44.465,
                44.467,
                44.469,
                44.472,
                44.474,
                44.476,
                44.478,
                44.482,
                44.484,
                44.486,
                44.488,
                44.49,
                44.492,
                44.494,
                44.497,
                44.499,
                44.501,
                44.504,
                44.507,
                44.509,
                44.511,
                44.513,
                44.515,
                44.517,
                44.519,
                44.521,
                44.523,
                44.525,
                44.529,
                44.531,
                44.533,
                44.535,
                44.537,
                44.539,
                44.541,
                44.544,
                44.546,
                44.548,
                44.551,
                44.553,
                44.555,
                44.557,
                44.559,
                44.561,
                44.564,
                44.566,
                44.568,
                44.57,
                44.572,
                44.575,
                44.577,
                44.579,
                44.581,
                44.583,
                44.585,
                44.587,
                44.589,
                44.591,
                44.593,
                44.597,
                44.599,
                44.601,
                44.603,
                44.605,
                44.607,
                44.609,
                44.611,
                44.613,
                44.615,
                44.617,
                44.62,
                44.622,
                44.624,
                44.626,
                44.628,
                44.63,
                44.632,
                44.634,
                44.636,
                44.638,
                44.64,
                44.644,
                44.646,
                44.648,
                44.65,
                44.651,
                44.653,
                44.655,
                44.657,
                44.659,
                44.661,
                44.663,
                44.667,
                44.669,
                44.67,
                44.672,
                44.674,
                44.676,
                44.678,
                44.68,
                44.682,
                44.684,
                44.686,
                44.689,
                44.691,
                44.693,
                44.695,
                44.697,
                44.699,
                44.701,
                44.703,
                44.705,
                44.706,
                44.708,
                44.712,
                44.714,
                44.716,
                44.717,
                44.719,
                44.721,
                44.723,
                44.725,
                44.727,
                44.729,
                44.731,
                44.734,
                44.736,
                44.738,
                44.74,
                44.741,
                44.743,
                44.745,
                44.747,
                44.749,
                44.751,
                44.753,
                44.756,
                44.758,
                44.76,
                44.762,
                44.763,
                44.765,
                44.767,
                44.769,
                44.771,
                44.773,
                44.774,
                44.778,
                44.78,
                44.782,
                44.783,
                44.785,
                44.787,
                44.789,
                44.791,
                44.792,
                44.794,
                44.796,
                44.798,
                44.801,
                44.803,
                44.805,
                44.807,
                44.808,
                44.81,
                44.812,
                44.814,
                44.816,
                44.817,
                44.819,
                44.823,
                44.824,
                44.826,
                44.828,
                44.83,
                44.831,
                44.833,
                44.835,
                44.837,
                44.839,
                44.84,
                44.842,
                44.845,
                44.847,
                44.849,
                44.851,
                44.852,
                44.854,
                44.856,
                44.858,
                44.859,
                44.861,
                44.863,
                44.866,
                44.868,
                44.87,
                44.871,
                44.873,
                44.875,
                44.877,
                44.879,
                44.88,
                44.882,
                44.884,
                44.885,
                44.889,
                44.89,
                44.892,
                44.894,
                44.896,
                44.897,
                44.899,
                44.901,
                44.903,
                44.904,
                44.906,
                44.908,
                44.911,
                44.913,
                44.914,
                44.916,
                44.918,
                44.919,
                44.921,
                44.923,
                44.925,
                44.926,
                44.928,
                44.931,
                44.933,
                44.935,
                44.936,
                44.938,
                44.94,
                44.941,
                44.943,
                44.945,
                44.946,
                44.948,
                44.95,
                44.953,
                44.955,
                44.956,
                44.958,
                44.96,
                44.961,
                44.963,
                44.965,
                44.966,
                44.968,
                44.97,
                44.971,
                44.974,
                44.976,
                44.978,
                44.98,
                44.981,
                44.983,
                44.984,
                44.986,
                44.988,
                44.989,
                44.991,
                44.993,
                44.996,
                44.998,
                44.999,
                45.001,
                45.002,
                45.004,
                45.006,
                45.007,
                45.009,
                45.011,
                45.012,
                45.014,
                45.017,
                45.019,
                45.02,
                45.022,
                45.024,
                45.025,
                45.027,
                45.028,
                45.03,
                45.032,
                45.033,
                45.035,
                45.038,
                45.04,
                45.041,
                45.043,
                45.044,
                45.046,
                45.048,
                45.049,
                45.051,
                45.053,
                45.054,
                45.056,
                45.057,
                45.06,
                45.062,
                45.064,
                45.065,
                45.067,
                45.068,
                45.07,
                45.072,
                45.073,
                45.075,
                45.076,
                45.078,
                45.081,
                45.083,
                45.084,
                45.086,
                45.087,
                45.089,
                45.09,
                45.092,
                45.094,
                45.095,
                45.097,
                45.098,
                45.101,
                45.103,
                45.105,
                45.106,
                45.108,
                45.109,
                45.111,
                45.112,
                45.114,
                45.116,
                45.117,
                45.119,
                45.12,
                45.123,
                45.125,
                45.126,
                45.128,
                45.129,
                45.131,
                45.133,
                45.134,
                45.136,
                45.137,
                45.139,
                45.14,
                45.143,
                45.145,
                45.146,
                45.148,
                45.149,
                45.151,
                45.153,
                45.154,
                45.156,
                45.157,
                45.159,
                45.16,
                45.162,
                45.165,
                45.166,
                45.168,
                45.169,
                45.171,
                45.172,
                45.174,
                45.175,
                45.177,
                45.178,
                45.18,
                45.181,
                45.183,
                45.186,
                45.188,
                45.189,
                45.191,
                45.192,
                45.194,
                45.195,
                45.197,
                45.198,
                45.2,
                45.201,
                45.203,
                45.204,
                45.207,
                45.209,
                45.21,
                45.212,
                45.213,
                45.215,
                45.216,
                45.217,
                45.219,
                45.221,
                45.222,
                45.223,
                45.225,
                45.228,
                45.229,
                45.231,
                45.232,
                45.234,
                45.235,
                45.237,
                45.238,
                45.24,
                45.241,
                45.243,
                45.244,
                45.246,
                45.249,
                45.25,
                45.252,
                45.253,
                45.255,
                45.256,
                45.257,
                45.259,
                45.26,
                45.262,
                45.263,
                45.265,
                45.266,
                45.269,
                45.271,
                45.272,
                45.274,
                45.275,
                45.276,
                45.278,
                45.279,
                45.281,
                45.282,
                45.284,
                45.285,
                45.287,
                45.29,
                45.291,
                45.292,
                45.294,
                45.295,
                45.297,
                45.298,
                45.3,
                45.301,
                45.302,
                45.304,
                45.305,
                45.307,
                45.31,
                45.311,
                45.313,
                45.314,
                45.315,
                45.317,
                45.318,
                45.32,
                45.321,
                45.323,
                45.324,
                45.325,
                45.327,
                45.33,
                45.331,
                45.333,
                45.334,
                45.335,
                45.337,
                45.338,
                45.34,
                45.341,
                45.342,
                45.344,
                45.345,
                45.347,
                45.348,
                45.351,
                45.352,
                45.354,
                45.355,
                45.357,
                45.358,
                45.359,
                45.361,
                45.362,
                45.364,
                45.365,
                45.366,
                45.368,
                45.371,
                45.372,
                45.374,
                45.375,
                45.376,
                45.378,
                45.379,
                45.38,
                45.382,
                45.383,
                45.385,
                45.386,
                45.387,
                45.389,
                45.392,
                45.393,
                45.394,
                45.396,
                45.397,
                45.399,
                45.4,
                45.401,
                45.403,
                45.404,
                45.405,
                45.407,
                45.408,
                45.411,
                45.412,
                45.414,
                45.415,
                45.417,
                45.418,
                45.419,
                45.421,
                45.422,
                45.423,
                45.425,
                45.426,
                45.427,
                45.429,
                45.432,
                45.433,
                45.434,
                45.436,
                45.437,
                45.438,
                45.44,
                45.441,
                45.443,
                45.444,
                45.445,
                45.447,
                45.448,
                45.449,
                45.452,
                45.453,
                45.455,
                45.456,
                45.458,
                45.459,
                45.46,
                45.462,
                45.463,
                45.464,
                45.466,
                45.467,
                45.468,
                45.47,
                45.472,
                45.474,
                45.475,
                45.476,
                45.478,
                45.479,
                45.48,
                45.482,
                45.483,
                45.484,
                45.486,
                45.487,
                45.488,
                45.49,
                45.493,
                45.494,
                45.495,
                45.497,
                45.498,
                45.499,
                45.501,
                45.502,
                45.503,
                45.505,
                45.506,
                45.507,
                45.508,
                45.51,
                45.513,
                45.514,
                45.515,
                45.517,
                45.518,
                45.519,
                45.521,
                45.522,
                45.523,
                45.524,
                45.526,
                45.527,
                45.528,
                45.53,
                45.532,
                45.534,
                45.535,
                45.536,
                45.538,
                45.539,
                45.54,
                45.542,
                45.543,
                45.544,
                45.546,
                45.547,
                45.548,
                45.549,
                45.552,
                45.554,
                45.555,
                45.556,
                45.557,
                45.559,
                45.56,
                45.561,
                45.563,
                45.564,
                45.565,
                45.566,
                45.568
            ],
            P1: [
                31.123,
                31.225,
                31.328,
                31.43,
                31.533,
                31.635,
                31.738,
                31.841,
                31.944,
                32.047,
                32.15,
                32.253,
                32.356,
                32.459,
                32.563,
                32.652,
                32.74,
                32.825,
                32.909,
                32.991,
                33.071,
                33.149,
                33.226,
                33.301,
                33.375,
                33.447,
                33.518,
                33.587,
                33.655,
                33.723,
                33.789,
                33.853,
                33.917,
                33.979,
                34.041,
                34.102,
                34.163,
                34.221,
                34.28,
                34.337,
                34.393,
                34.449,
                34.504,
                34.558,
                34.612,
                34.665,
                34.717,
                34.77,
                34.82,
                34.871,
                34.921,
                34.971,
                35.019,
                35.068,
                35.115,
                35.164,
                35.21,
                35.257,
                35.303,
                35.348,
                35.394,
                35.438,
                35.483,
                35.527,
                35.572,
                35.615,
                35.658,
                35.701,
                35.743,
                35.785,
                35.827,
                35.867,
                35.908,
                35.949,
                35.989,
                36.029,
                36.069,
                36.109,
                36.148,
                36.187,
                36.226,
                36.264,
                36.302,
                36.34,
                36.378,
                36.415,
                36.452,
                36.488,
                36.525,
                36.562,
                36.598,
                36.634,
                36.669,
                36.705,
                36.74,
                36.775,
                36.81,
                36.844,
                36.879,
                36.913,
                36.947,
                36.981,
                37.014,
                37.048,
                37.081,
                37.114,
                37.147,
                37.179,
                37.211,
                37.244,
                37.275,
                37.307,
                37.339,
                37.37,
                37.401,
                37.433,
                37.463,
                37.494,
                37.525,
                37.554,
                37.585,
                37.614,
                37.644,
                37.674,
                37.703,
                37.733,
                37.761,
                37.791,
                37.82,
                37.848,
                37.877,
                37.904,
                37.933,
                37.961,
                37.988,
                38.016,
                38.043,
                38.071,
                38.098,
                38.125,
                38.152,
                38.179,
                38.205,
                38.231,
                38.258,
                38.284,
                38.31,
                38.335,
                38.361,
                38.386,
                38.412,
                38.437,
                38.463,
                38.487,
                38.512,
                38.537,
                38.562,
                38.586,
                38.61,
                38.635,
                38.658,
                38.682,
                38.706,
                38.729,
                38.753,
                38.776,
                38.8,
                38.822,
                38.846,
                38.868,
                38.892,
                38.914,
                38.937,
                38.959,
                38.982,
                39.003,
                39.026,
                39.047,
                39.07,
                39.091,
                39.112,
                39.134,
                39.155,
                39.177,
                39.197,
                39.219,
                39.239,
                39.261,
                39.281,
                39.301,
                39.322,
                39.342,
                39.363,
                39.383,
                39.403,
                39.423,
                39.442,
                39.462,
                39.482,
                39.502,
                39.521,
                39.541,
                39.559,
                39.578,
                39.598,
                39.616,
                39.636,
                39.654,
                39.672,
                39.692,
                39.71,
                39.728,
                39.746,
                39.764,
                39.783,
                39.8,
                39.819,
                39.836,
                39.854,
                39.872,
                39.889,
                39.906,
                39.924,
                39.941,
                39.959,
                39.975,
                39.992,
                40.01,
                40.026,
                40.042,
                40.06,
                40.076,
                40.093,
                40.109,
                40.125,
                40.142,
                40.158,
                40.174,
                40.19,
                40.206,
                40.221,
                40.238,
                40.253,
                40.269,
                40.285,
                40.3,
                40.316,
                40.331,
                40.346,
                40.361,
                40.376,
                40.391,
                40.406,
                40.421,
                40.435,
                40.451,
                40.465,
                40.479,
                40.495,
                40.509,
                40.523,
                40.538,
                40.552,
                40.566,
                40.58,
                40.594,
                40.608,
                40.622,
                40.636,
                40.649,
                40.664,
                40.677,
                40.69,
                40.705,
                40.718,
                40.731,
                40.745,
                40.758,
                40.771,
                40.785,
                40.798,
                40.81,
                40.824,
                40.837,
                40.849,
                40.863,
                40.875,
                40.888,
                40.9,
                40.913,
                40.926,
                40.938,
                40.951,
                40.963,
                40.975,
                40.988,
                41,
                41.012,
                41.025,
                41.036,
                41.048,
                41.06,
                41.072,
                41.084,
                41.095,
                41.108,
                41.119,
                41.131,
                41.142,
                41.154,
                41.165,
                41.177,
                41.189,
                41.2,
                41.211,
                41.223,
                41.234,
                41.245,
                41.256,
                41.267,
                41.278,
                41.289,
                41.301,
                41.311,
                41.322,
                41.332,
                41.344,
                41.354,
                41.365,
                41.375,
                41.386,
                41.397,
                41.407,
                41.418,
                41.428,
                41.438,
                41.449,
                41.46,
                41.47,
                41.48,
                41.489,
                41.5,
                41.51,
                41.52,
                41.531,
                41.541,
                41.55,
                41.56,
                41.571,
                41.58,
                41.59,
                41.599,
                41.61,
                41.619,
                41.628,
                41.638,
                41.648,
                41.657,
                41.667,
                41.677,
                41.686,
                41.695,
                41.704,
                41.715,
                41.724,
                41.733,
                41.742,
                41.752,
                41.761,
                41.769,
                41.778,
                41.788,
                41.797,
                41.806,
                41.814,
                41.824,
                41.833,
                41.841,
                41.85,
                41.86,
                41.868,
                41.877,
                41.885,
                41.895,
                41.903,
                41.912,
                41.92,
                41.929,
                41.937,
                41.946,
                41.954,
                41.963,
                41.971,
                41.98,
                41.988,
                41.997,
                42.005,
                42.013,
                42.021,
                42.029,
                42.038,
                42.046,
                42.054,
                42.062,
                42.071,
                42.079,
                42.086,
                42.094,
                42.103,
                42.111,
                42.118,
                42.126,
                42.135,
                42.142,
                42.15,
                42.158,
                42.165,
                42.174,
                42.181,
                42.189,
                42.196,
                42.205,
                42.212,
                42.219,
                42.227,
                42.234,
                42.243,
                42.25,
                42.257,
                42.264,
                42.273,
                42.28,
                42.287,
                42.294,
                42.302,
                42.31,
                42.317,
                42.324,
                42.331,
                42.339,
                42.346,
                42.353,
                42.36,
                42.368,
                42.375,
                42.382,
                42.389,
                42.395,
                42.403,
                42.41,
                42.417,
                42.424,
                42.431,
                42.438,
                42.445,
                42.452,
                42.459,
                42.466,
                42.473,
                42.48,
                42.486,
                42.493,
                42.501,
                42.507,
                42.514,
                42.52,
                42.527,
                42.534,
                42.541,
                42.547,
                42.554,
                42.56,
                42.568,
                42.574,
                42.58,
                42.587,
                42.594,
                42.6,
                42.607,
                42.613,
                42.619,
                42.627,
                42.633,
                42.639,
                42.645,
                42.652,
                42.659,
                42.665,
                42.671,
                42.677,
                42.684,
                42.691,
                42.697,
                42.703,
                42.709,
                42.715,
                42.722,
                42.728,
                42.734,
                42.74,
                42.746,
                42.753,
                42.759,
                42.765,
                42.771,
                42.777,
                42.784,
                42.79,
                42.796,
                42.802,
                42.807,
                42.814,
                42.82,
                42.826,
                42.832,
                42.838,
                42.844,
                42.85,
                42.856,
                42.862,
                42.867,
                42.874,
                42.88,
                42.886,
                42.891,
                42.897,
                42.903,
                42.909,
                42.915,
                42.921,
                42.926,
                42.932,
                42.939,
                42.944,
                42.95,
                42.955,
                42.961,
                42.967,
                42.973,
                42.978,
                42.984,
                42.989,
                42.995,
                43.002,
                43.007,
                43.012,
                43.018,
                43.023,
                43.03,
                43.035,
                43.041,
                43.046,
                43.051,
                43.058,
                43.063,
                43.069,
                43.074,
                43.079,
                43.085,
                43.091,
                43.096,
                43.102,
                43.107,
                43.112,
                43.119,
                43.124,
                43.129,
                43.134,
                43.14,
                43.145,
                43.151,
                43.156,
                43.162,
                43.167,
                43.172,
                43.177,
                43.183,
                43.189,
                43.194,
                43.199,
                43.204,
                43.21,
                43.215,
                43.22,
                43.226,
                43.231,
                43.236,
                43.242,
                43.247,
                43.252,
                43.257,
                43.262,
                43.267,
                43.273,
                43.278,
                43.284,
                43.289,
                43.294,
                43.3,
                43.305,
                43.31,
                43.315,
                43.32,
                43.325,
                43.331,
                43.336,
                43.341,
                43.346,
                43.35,
                43.355,
                43.361,
                43.366,
                43.371,
                43.376,
                43.381,
                43.386,
                43.392,
                43.397,
                43.402,
                43.407,
                43.411,
                43.416,
                43.422,
                43.427,
                43.432,
                43.437,
                43.442,
                43.446,
                43.452,
                43.457,
                43.462,
                43.467,
                43.472,
                43.476,
                43.482,
                43.487,
                43.492,
                43.497,
                43.501,
                43.506,
                43.512,
                43.517,
                43.521,
                43.526,
                43.531,
                43.536,
                43.541,
                43.546,
                43.551,
                43.556,
                43.56,
                43.565,
                43.57,
                43.575,
                43.58,
                43.585,
                43.589,
                43.594,
                43.599,
                43.604,
                43.609,
                43.614,
                43.618,
                43.623,
                43.628,
                43.633,
                43.638,
                43.643,
                43.647,
                43.652,
                43.656,
                43.662,
                43.667,
                43.671,
                43.676,
                43.68,
                43.685,
                43.69,
                43.695,
                43.7,
                43.704,
                43.709,
                43.713,
                43.718,
                43.724,
                43.728,
                43.733,
                43.737,
                43.742,
                43.746,
                43.751,
                43.756,
                43.761,
                43.765,
                43.77,
                43.774,
                43.779,
                43.784,
                43.789,
                43.793,
                43.798,
                43.802,
                43.806,
                43.811,
                43.816,
                43.821,
                43.825,
                43.83,
                43.834,
                43.838,
                43.843,
                43.848,
                43.853,
                43.857,
                43.862,
                43.866,
                43.87,
                43.876,
                43.88,
                43.885,
                43.889,
                43.893,
                43.898,
                43.902,
                43.907,
                43.912,
                43.916,
                43.92,
                43.925,
                43.929,
                43.933,
                43.939,
                43.943,
                43.947,
                43.952,
                43.956,
                43.96,
                43.964,
                43.97,
                43.974,
                43.978,
                43.983,
                43.987,
                43.991,
                43.996,
                44.001,
                44.005,
                44.009,
                44.013,
                44.018,
                44.022,
                44.027,
                44.031,
                44.035,
                44.04,
                44.044,
                44.048,
                44.052,
                44.057,
                44.062,
                44.066,
                44.07,
                44.074,
                44.078,
                44.082,
                44.088,
                44.092,
                44.096,
                44.1,
                44.104,
                44.108,
                44.112,
                44.116,
                44.122,
                44.126,
                44.13,
                44.134,
                44.138,
                44.142,
                44.146,
                44.151,
                44.155,
                44.159,
                44.163,
                44.167,
                44.171,
                44.175,
                44.181,
                44.185,
                44.189,
                44.193,
                44.197,
                44.201,
                44.205,
                44.21,
                44.214,
                44.218,
                44.222,
                44.226,
                44.23,
                44.234,
                44.239,
                44.242,
                44.247,
                44.25,
                44.254,
                44.258,
                44.262,
                44.266,
                44.271,
                44.275,
                44.279,
                44.283,
                44.287,
                44.291,
                44.294,
                44.299,
                44.303,
                44.307,
                44.311,
                44.315,
                44.319,
                44.323,
                44.326,
                44.331,
                44.335,
                44.339,
                44.343,
                44.347,
                44.35,
                44.354,
                44.359,
                44.363,
                44.367,
                44.37,
                44.374,
                44.378,
                44.382,
                44.386,
                44.39,
                44.394,
                44.398,
                44.402,
                44.405,
                44.409,
                44.413,
                44.418,
                44.421,
                44.425,
                44.429,
                44.432,
                44.436,
                44.44,
                44.443,
                44.448,
                44.452,
                44.455,
                44.459,
                44.463,
                44.466,
                44.47,
                44.474,
                44.478,
                44.482,
                44.486,
                44.489,
                44.493,
                44.496,
                44.5,
                44.504,
                44.508,
                44.512,
                44.516,
                44.519,
                44.523,
                44.526,
                44.53,
                44.533,
                44.538,
                44.542,
                44.545,
                44.549,
                44.552,
                44.556,
                44.559,
                44.563,
                44.567,
                44.571,
                44.574,
                44.578,
                44.581,
                44.585,
                44.588,
                44.593,
                44.596,
                44.6,
                44.603,
                44.606,
                44.61,
                44.613,
                44.617,
                44.62,
                44.625,
                44.628,
                44.631,
                44.635,
                44.638,
                44.642,
                44.645,
                44.648,
                44.653,
                44.656,
                44.66,
                44.663,
                44.666,
                44.67,
                44.673,
                44.676,
                44.681,
                44.684,
                44.687,
                44.691,
                44.694,
                44.697,
                44.701,
                44.704,
                44.708,
                44.712,
                44.715,
                44.718,
                44.721,
                44.725,
                44.728,
                44.731,
                44.736,
                44.739,
                44.742,
                44.745,
                44.749,
                44.752,
                44.755,
                44.758,
                44.761,
                44.766,
                44.769,
                44.772,
                44.775,
                44.779,
                44.782,
                44.785,
                44.788,
                44.792,
                44.796,
                44.799,
                44.802,
                44.805,
                44.808,
                44.811,
                44.814,
                44.819,
                44.822,
                44.825,
                44.828,
                44.831,
                44.834,
                44.837,
                44.84,
                44.843,
                44.848,
                44.851,
                44.854,
                44.857,
                44.86,
                44.863,
                44.866,
                44.869,
                44.873,
                44.876,
                44.879,
                44.882,
                44.886,
                44.889,
                44.892,
                44.895,
                44.898,
                44.902,
                44.905,
                44.908,
                44.911,
                44.914,
                44.917,
                44.92,
                44.923,
                44.926,
                44.93,
                44.933,
                44.936,
                44.939,
                44.942,
                44.945,
                44.948,
                44.95,
                44.953,
                44.957,
                44.96,
                44.963,
                44.966,
                44.969,
                44.972,
                44.975,
                44.978,
                44.982,
                44.985,
                44.988,
                44.99,
                44.993,
                44.996,
                44.999,
                45.002,
                45.005,
                45.009,
                45.012,
                45.015,
                45.017,
                45.02,
                45.023,
                45.026,
                45.029,
                45.032,
                45.036,
                45.038,
                45.041,
                45.044,
                45.047,
                45.05,
                45.052,
                45.055,
                45.058,
                45.062,
                45.065,
                45.068,
                45.07,
                45.073,
                45.076,
                45.079,
                45.081,
                45.084,
                45.088,
                45.091,
                45.094,
                45.096,
                45.099,
                45.102,
                45.105,
                45.107,
                45.11,
                45.114,
                45.117,
                45.119,
                45.122,
                45.125,
                45.127,
                45.13,
                45.133,
                45.135,
                45.138,
                45.142,
                45.145,
                45.147,
                45.15,
                45.153,
                45.155,
                45.158,
                45.161,
                45.163,
                45.167,
                45.17,
                45.172,
                45.175,
                45.178,
                45.18,
                45.183,
                45.186,
                45.188,
                45.192,
                45.195,
                45.197,
                45.2,
                45.203,
                45.205,
                45.208,
                45.21,
                45.213,
                45.217,
                45.219,
                45.222,
                45.224,
                45.227,
                45.23,
                45.232,
                45.235,
                45.237,
                45.24,
                45.243,
                45.246,
                45.249,
                45.251,
                45.254,
                45.256,
                45.259,
                45.261,
                45.264,
                45.268,
                45.27,
                45.273,
                45.275,
                45.278,
                45.28,
                45.283,
                45.285,
                45.288,
                45.29,
                45.294,
                45.296,
                45.299,
                45.301,
                45.304,
                45.306,
                45.309,
                45.311,
                45.314,
                45.316,
                45.32,
                45.322,
                45.325,
                45.327,
                45.33,
                45.332,
                45.334,
                45.337,
                45.339,
                45.343,
                45.345,
                45.348,
                45.35,
                45.353,
                45.355,
                45.358,
                45.36,
                45.362,
                45.365,
                45.368,
                45.371,
                45.373,
                45.376,
                45.378,
                45.38,
                45.383,
                45.385,
                45.387,
                45.39,
                45.393,
                45.396,
                45.398,
                45.4,
                45.403,
                45.405,
                45.408,
                45.41,
                45.412,
                45.415,
                45.418,
                45.421,
                45.423,
                45.425,
                45.427,
                45.43,
                45.432,
                45.434,
                45.437,
                45.439,
                45.443,
                45.445,
                45.447,
                45.45,
                45.452,
                45.454,
                45.457,
                45.459,
                45.461,
                45.463,
                45.467,
                45.469,
                45.471,
                45.474,
                45.476,
                45.478,
                45.481,
                45.483,
                45.485,
                45.487,
                45.491,
                45.493,
                45.495,
                45.498,
                45.5,
                45.502,
                45.504,
                45.507,
                45.509,
                45.511,
                45.514,
                45.517,
                45.519,
                45.521,
                45.523,
                45.526,
                45.528,
                45.53,
                45.532,
                45.535,
                45.538,
                45.54,
                45.542,
                45.545,
                45.547,
                45.549,
                45.551,
                45.553,
                45.556,
                45.558,
                45.56,
                45.563,
                45.565,
                45.568,
                45.57,
                45.572,
                45.574,
                45.576,
                45.578,
                45.581,
                45.583,
                45.586,
                45.588,
                45.59,
                45.593,
                45.595,
                45.597,
                45.599,
                45.601,
                45.603,
                45.605,
                45.608,
                45.611,
                45.613,
                45.615,
                45.617,
                45.619,
                45.622,
                45.624,
                45.626,
                45.628,
                45.63,
                45.633,
                45.635,
                45.637,
                45.64,
                45.642,
                45.644,
                45.646,
                45.648,
                45.65,
                45.652,
                45.654,
                45.657,
                45.66,
                45.662,
                45.664,
                45.666,
                45.668,
                45.67,
                45.672,
                45.674,
                45.676,
                45.679,
                45.681,
                45.683,
                45.685,
                45.688,
                45.69,
                45.692,
                45.694,
                45.696,
                45.698,
                45.7,
                45.703,
                45.705,
                45.707,
                45.709,
                45.711,
                45.713,
                45.715,
                45.717,
                45.719,
                45.721,
                45.723,
                45.726,
                45.728,
                45.73,
                45.732,
                45.734,
                45.736,
                45.738,
                45.74,
                45.742,
                45.744,
                45.746,
                45.749,
                45.751,
                45.753,
                45.755,
                45.757,
                45.759,
                45.761,
                45.763,
                45.765,
                45.767,
                45.769,
                45.772,
                45.774,
                45.776,
                45.778,
                45.78,
                45.782,
                45.784,
                45.786,
                45.788,
                45.79,
                45.792,
                45.795,
                45.797,
                45.799,
                45.801,
                45.803,
                45.804,
                45.806,
                45.808,
                45.81,
                45.812,
                45.814,
                45.817,
                45.819,
                45.821,
                45.823,
                45.825,
                45.827,
                45.829,
                45.831,
                45.832,
                45.834,
                45.836,
                45.839,
                45.841,
                45.843,
                45.845,
                45.847,
                45.849,
                45.851,
                45.853,
                45.854,
                45.856,
                45.858,
                45.861,
                45.863,
                45.865,
                45.867,
                45.869,
                45.871,
                45.872,
                45.874,
                45.876,
                45.878,
                45.88,
                45.882,
                45.885,
                45.887,
                45.888,
                45.89,
                45.892,
                45.894,
                45.896,
                45.898,
                45.9,
                45.901,
                45.903,
                45.906,
                45.908,
                45.91,
                45.912,
                45.913,
                45.915,
                45.917,
                45.919,
                45.921,
                45.923,
                45.924,
                45.926,
                45.929,
                45.931,
                45.933,
                45.935,
                45.936,
                45.938,
                45.94,
                45.942,
                45.944,
                45.945,
                45.947,
                45.95,
                45.952,
                45.954,
                45.956,
                45.957,
                45.959,
                45.961,
                45.963,
                45.964,
                45.966,
                45.968,
                45.97,
                45.973,
                45.974,
                45.976,
                45.978,
                45.98,
                45.982,
                45.983,
                45.985,
                45.987,
                45.989,
                45.99,
                45.992,
                45.995,
                45.997,
                45.999,
                46,
                46.002,
                46.004,
                46.006,
                46.007,
                46.009,
                46.011,
                46.012,
                46.015,
                46.017,
                46.019,
                46.021,
                46.022,
                46.024,
                46.026,
                46.028,
                46.029,
                46.031,
                46.033,
                46.034,
                46.037,
                46.039,
                46.041,
                46.042,
                46.044,
                46.046,
                46.048,
                46.049,
                46.051,
                46.053,
                46.054,
                46.056,
                46.059,
                46.061,
                46.062,
                46.064,
                46.066,
                46.067,
                46.069,
                46.071,
                46.073,
                46.074,
                46.076,
                46.078,
                46.08,
                46.082,
                46.084,
                46.085,
                46.087,
                46.089,
                46.09,
                46.092,
                46.094,
                46.096,
                46.097,
                46.099,
                46.102,
                46.103,
                46.105,
                46.107,
                46.108,
                46.11,
                46.112,
                46.113,
                46.115,
                46.117,
                46.118,
                46.12,
                46.123,
                46.124,
                46.126,
                46.128,
                46.129,
                46.131,
                46.133,
                46.134,
                46.136,
                46.138,
                46.139,
                46.141,
                46.142,
                46.145,
                46.147,
                46.149,
                46.15,
                46.152,
                46.153,
                46.155,
                46.157,
                46.158,
                46.16,
                46.162,
                46.163,
                46.166,
                46.168,
                46.169,
                46.171,
                46.172,
                46.174,
                46.176,
                46.177,
                46.179,
                46.18,
                46.182,
                46.184,
                46.186,
                46.188,
                46.19,
                46.191,
                46.193,
                46.194,
                46.196,
                46.198,
                46.199,
                46.201,
                46.202,
                46.204,
                46.206,
                46.208,
                46.21,
                46.212,
                46.213,
                46.215,
                46.216,
                46.218,
                46.219,
                46.221,
                46.223,
                46.224,
                46.226,
                46.229,
                46.23,
                46.232,
                46.233,
                46.235,
                46.236,
                46.238,
                46.24,
                46.241,
                46.243,
                46.244,
                46.246,
                46.247,
                46.25,
                46.252,
                46.253,
                46.255,
                46.256,
                46.258,
                46.259,
                46.261,
                46.262,
                46.264,
                46.266,
                46.267,
                46.269,
                46.271,
                46.273,
                46.275,
                46.276,
                46.278,
                46.279,
                46.281,
                46.282,
                46.284,
                46.285,
                46.287,
                46.288,
                46.29,
                46.293,
                46.294,
                46.296,
                46.297,
                46.299,
                46.3,
                46.302,
                46.303,
                46.305,
                46.306,
                46.308,
                46.309,
                46.311,
                46.314,
                46.315,
                46.317,
                46.318,
                46.32,
                46.321,
                46.323,
                46.324,
                46.326,
                46.327,
                46.329,
                46.33,
                46.332,
                46.334,
                46.336,
                46.337,
                46.339,
                46.34,
                46.342,
                46.343,
                46.345,
                46.346,
                46.348,
                46.349,
                46.351,
                46.352,
                46.355,
                46.356,
                46.358,
                46.359,
                46.361,
                46.362,
                46.364,
                46.365,
                46.367,
                46.368,
                46.37,
                46.371,
                46.373,
                46.375,
                46.377,
                46.378,
                46.38,
                46.381,
                46.383,
                46.384,
                46.386,
                46.387,
                46.389,
                46.39,
                46.391,
                46.393,
                46.396,
                46.397,
                46.399,
                46.4,
                46.401,
                46.403,
                46.404,
                46.406,
                46.407,
                46.409,
                46.41,
                46.412,
                46.413,
                46.416,
                46.417,
                46.419,
                46.42,
                46.422,
                46.423,
                46.424,
                46.426,
                46.427,
                46.429,
                46.43,
                46.432,
                46.433,
                46.434,
                46.437,
                46.438,
                46.44,
                46.441,
                46.443,
                46.444,
                46.446,
                46.447,
                46.449,
                46.45,
                46.451,
                46.453,
                46.454,
                46.457,
                46.458,
                46.46,
                46.461,
                46.462,
                46.464,
                46.465,
                46.467,
                46.468,
                46.47,
                46.471,
                46.472,
                46.474,
                46.475,
                46.478,
                46.479,
                46.481,
                46.482,
                46.483,
                46.485,
                46.486,
                46.488,
                46.489,
                46.491,
                46.492,
                46.493,
                46.495,
                46.497,
                46.499,
                46.5,
                46.501,
                46.503,
                46.504,
                46.506,
                46.507,
                46.508,
                46.51,
                46.511,
                46.513,
                46.514,
                46.515,
                46.518,
                46.519,
                46.521,
                46.522,
                46.524,
                46.525,
                46.526,
                46.528,
                46.529,
                46.53,
                46.532,
                46.533,
                46.535,
                46.536,
                46.539,
                46.54,
                46.541,
                46.543,
                46.544,
                46.545,
                46.547,
                46.548,
                46.549,
                46.551,
                46.552,
                46.554,
                46.555,
                46.556,
                46.559,
                46.56,
                46.562,
                46.563,
                46.564,
                46.566,
                46.567,
                46.568,
                46.57,
                46.571,
                46.572,
                46.574,
                46.575,
                46.577,
                46.579,
                46.58,
                46.582,
                46.583,
                46.585,
                46.586,
                46.587,
                46.589,
                46.59,
                46.591,
                46.593,
                46.594,
                46.595,
                46.597,
                46.599,
                46.601,
                46.602,
                46.603,
                46.605,
                46.606,
                46.607,
                46.609,
                46.61,
                46.611,
                46.613,
                46.614,
                46.615,
                46.617,
                46.619,
                46.62,
                46.622,
                46.623,
                46.624,
                46.626,
                46.627,
                46.629,
                46.63,
                46.631,
                46.632,
                46.634,
                46.635,
                46.636,
                46.639,
                46.64,
                46.642,
                46.643,
                46.644,
                46.646,
                46.647,
                46.648,
                46.65,
                46.651,
                46.652,
                46.653,
                46.655
            ],
            P3: [
                31.651,
                31.752,
                31.854,
                31.955,
                32.056,
                32.157,
                32.259,
                32.36,
                32.462,
                32.564,
                32.666,
                32.767,
                32.869,
                32.971,
                33.073,
                33.163,
                33.252,
                33.338,
                33.422,
                33.505,
                33.586,
                33.665,
                33.743,
                33.818,
                33.893,
                33.966,
                34.037,
                34.108,
                34.177,
                34.244,
                34.311,
                34.377,
                34.441,
                34.504,
                34.567,
                34.628,
                34.689,
                34.748,
                34.807,
                34.865,
                34.922,
                34.978,
                35.034,
                35.089,
                35.144,
                35.197,
                35.25,
                35.302,
                35.354,
                35.405,
                35.456,
                35.506,
                35.555,
                35.604,
                35.652,
                35.701,
                35.748,
                35.795,
                35.841,
                35.887,
                35.933,
                35.978,
                36.023,
                36.068,
                36.113,
                36.157,
                36.2,
                36.243,
                36.286,
                36.329,
                36.371,
                36.412,
                36.453,
                36.494,
                36.535,
                36.576,
                36.616,
                36.656,
                36.696,
                36.735,
                36.774,
                36.813,
                36.851,
                36.89,
                36.928,
                36.966,
                37.003,
                37.04,
                37.077,
                37.114,
                37.151,
                37.187,
                37.222,
                37.259,
                37.294,
                37.33,
                37.365,
                37.4,
                37.435,
                37.469,
                37.503,
                37.537,
                37.572,
                37.606,
                37.638,
                37.672,
                37.705,
                37.738,
                37.771,
                37.803,
                37.835,
                37.868,
                37.9,
                37.931,
                37.963,
                37.994,
                38.025,
                38.056,
                38.087,
                38.118,
                38.148,
                38.178,
                38.208,
                38.239,
                38.268,
                38.298,
                38.327,
                38.356,
                38.386,
                38.414,
                38.443,
                38.471,
                38.5,
                38.529,
                38.556,
                38.584,
                38.612,
                38.64,
                38.667,
                38.694,
                38.722,
                38.748,
                38.776,
                38.802,
                38.829,
                38.855,
                38.881,
                38.907,
                38.933,
                38.959,
                38.985,
                39.01,
                39.035,
                39.06,
                39.086,
                39.11,
                39.136,
                39.161,
                39.185,
                39.21,
                39.233,
                39.257,
                39.282,
                39.305,
                39.329,
                39.353,
                39.376,
                39.399,
                39.423,
                39.446,
                39.469,
                39.492,
                39.515,
                39.537,
                39.56,
                39.582,
                39.605,
                39.627,
                39.649,
                39.671,
                39.692,
                39.714,
                39.735,
                39.757,
                39.778,
                39.8,
                39.821,
                39.842,
                39.863,
                39.883,
                39.904,
                39.925,
                39.945,
                39.965,
                39.986,
                40.006,
                40.026,
                40.046,
                40.066,
                40.086,
                40.105,
                40.125,
                40.144,
                40.163,
                40.183,
                40.202,
                40.221,
                40.24,
                40.258,
                40.277,
                40.296,
                40.315,
                40.333,
                40.351,
                40.37,
                40.387,
                40.406,
                40.424,
                40.441,
                40.46,
                40.477,
                40.494,
                40.512,
                40.529,
                40.547,
                40.564,
                40.581,
                40.599,
                40.615,
                40.632,
                40.649,
                40.666,
                40.683,
                40.699,
                40.715,
                40.732,
                40.749,
                40.764,
                40.781,
                40.797,
                40.813,
                40.829,
                40.845,
                40.861,
                40.877,
                40.892,
                40.908,
                40.923,
                40.938,
                40.954,
                40.969,
                40.984,
                41,
                41.014,
                41.029,
                41.044,
                41.059,
                41.073,
                41.089,
                41.103,
                41.117,
                41.132,
                41.146,
                41.16,
                41.175,
                41.189,
                41.203,
                41.218,
                41.231,
                41.245,
                41.259,
                41.273,
                41.287,
                41.301,
                41.314,
                41.327,
                41.341,
                41.355,
                41.368,
                41.382,
                41.395,
                41.407,
                41.421,
                41.434,
                41.447,
                41.46,
                41.473,
                41.485,
                41.498,
                41.511,
                41.524,
                41.536,
                41.549,
                41.561,
                41.574,
                41.587,
                41.599,
                41.611,
                41.624,
                41.635,
                41.647,
                41.659,
                41.672,
                41.684,
                41.695,
                41.708,
                41.719,
                41.731,
                41.742,
                41.754,
                41.766,
                41.777,
                41.789,
                41.801,
                41.812,
                41.824,
                41.835,
                41.846,
                41.857,
                41.869,
                41.88,
                41.891,
                41.902,
                41.913,
                41.924,
                41.934,
                41.946,
                41.956,
                41.967,
                41.978,
                41.989,
                41.999,
                42.01,
                42.021,
                42.031,
                42.041,
                42.052,
                42.063,
                42.073,
                42.083,
                42.093,
                42.104,
                42.114,
                42.124,
                42.134,
                42.144,
                42.154,
                42.164,
                42.175,
                42.184,
                42.194,
                42.204,
                42.214,
                42.224,
                42.233,
                42.243,
                42.253,
                42.262,
                42.272,
                42.282,
                42.291,
                42.3,
                42.31,
                42.32,
                42.329,
                42.338,
                42.347,
                42.357,
                42.366,
                42.375,
                42.384,
                42.394,
                42.403,
                42.412,
                42.421,
                42.43,
                42.439,
                42.448,
                42.457,
                42.466,
                42.475,
                42.483,
                42.492,
                42.501,
                42.51,
                42.519,
                42.527,
                42.536,
                42.545,
                42.553,
                42.561,
                42.571,
                42.579,
                42.587,
                42.595,
                42.604,
                42.613,
                42.621,
                42.629,
                42.637,
                42.646,
                42.654,
                42.662,
                42.67,
                42.679,
                42.687,
                42.695,
                42.703,
                42.711,
                42.719,
                42.727,
                42.735,
                42.743,
                42.751,
                42.759,
                42.767,
                42.774,
                42.783,
                42.79,
                42.798,
                42.806,
                42.814,
                42.822,
                42.829,
                42.836,
                42.844,
                42.852,
                42.86,
                42.867,
                42.874,
                42.883,
                42.89,
                42.897,
                42.904,
                42.912,
                42.92,
                42.927,
                42.934,
                42.941,
                42.949,
                42.956,
                42.964,
                42.971,
                42.979,
                42.985,
                42.992,
                43,
                43.006,
                43.014,
                43.021,
                43.028,
                43.035,
                43.042,
                43.05,
                43.056,
                43.063,
                43.07,
                43.078,
                43.084,
                43.091,
                43.098,
                43.105,
                43.112,
                43.119,
                43.125,
                43.132,
                43.139,
                43.146,
                43.153,
                43.159,
                43.166,
                43.172,
                43.18,
                43.186,
                43.193,
                43.199,
                43.206,
                43.213,
                43.219,
                43.226,
                43.232,
                43.239,
                43.246,
                43.252,
                43.258,
                43.265,
                43.272,
                43.278,
                43.284,
                43.29,
                43.297,
                43.304,
                43.31,
                43.316,
                43.322,
                43.328,
                43.335,
                43.342,
                43.348,
                43.354,
                43.36,
                43.367,
                43.373,
                43.379,
                43.385,
                43.391,
                43.398,
                43.404,
                43.41,
                43.416,
                43.421,
                43.428,
                43.434,
                43.44,
                43.446,
                43.452,
                43.459,
                43.464,
                43.47,
                43.476,
                43.482,
                43.489,
                43.494,
                43.5,
                43.506,
                43.512,
                43.517,
                43.524,
                43.53,
                43.535,
                43.541,
                43.547,
                43.553,
                43.559,
                43.565,
                43.57,
                43.576,
                43.582,
                43.588,
                43.594,
                43.599,
                43.605,
                43.61,
                43.617,
                43.622,
                43.628,
                43.633,
                43.639,
                43.645,
                43.651,
                43.656,
                43.662,
                43.667,
                43.673,
                43.679,
                43.684,
                43.69,
                43.695,
                43.701,
                43.707,
                43.712,
                43.718,
                43.723,
                43.728,
                43.735,
                43.74,
                43.745,
                43.751,
                43.756,
                43.761,
                43.767,
                43.773,
                43.778,
                43.783,
                43.788,
                43.794,
                43.8,
                43.805,
                43.81,
                43.816,
                43.821,
                43.827,
                43.832,
                43.837,
                43.843,
                43.848,
                43.853,
                43.859,
                43.864,
                43.869,
                43.874,
                43.879,
                43.885,
                43.891,
                43.896,
                43.901,
                43.906,
                43.911,
                43.917,
                43.922,
                43.927,
                43.932,
                43.937,
                43.942,
                43.948,
                43.953,
                43.958,
                43.963,
                43.968,
                43.973,
                43.979,
                43.984,
                43.989,
                43.994,
                43.999,
                44.004,
                44.01,
                44.015,
                44.02,
                44.025,
                44.03,
                44.035,
                44.04,
                44.045,
                44.05,
                44.055,
                44.06,
                44.065,
                44.071,
                44.076,
                44.081,
                44.085,
                44.09,
                44.095,
                44.101,
                44.106,
                44.11,
                44.115,
                44.12,
                44.125,
                44.131,
                44.136,
                44.14,
                44.145,
                44.15,
                44.155,
                44.16,
                44.165,
                44.17,
                44.175,
                44.179,
                44.184,
                44.189,
                44.195,
                44.199,
                44.204,
                44.209,
                44.214,
                44.218,
                44.224,
                44.229,
                44.233,
                44.238,
                44.243,
                44.247,
                44.253,
                44.258,
                44.262,
                44.267,
                44.272,
                44.276,
                44.282,
                44.287,
                44.291,
                44.296,
                44.3,
                44.305,
                44.31,
                44.315,
                44.32,
                44.324,
                44.329,
                44.334,
                44.338,
                44.344,
                44.348,
                44.353,
                44.358,
                44.362,
                44.367,
                44.371,
                44.377,
                44.381,
                44.386,
                44.39,
                44.395,
                44.399,
                44.405,
                44.409,
                44.414,
                44.418,
                44.423,
                44.427,
                44.432,
                44.437,
                44.442,
                44.446,
                44.451,
                44.455,
                44.46,
                44.464,
                44.469,
                44.474,
                44.478,
                44.483,
                44.487,
                44.492,
                44.497,
                44.501,
                44.506,
                44.51,
                44.515,
                44.519,
                44.524,
                44.529,
                44.533,
                44.538,
                44.542,
                44.546,
                44.551,
                44.555,
                44.56,
                44.565,
                44.569,
                44.573,
                44.578,
                44.582,
                44.586,
                44.592,
                44.596,
                44.6,
                44.605,
                44.609,
                44.613,
                44.618,
                44.623,
                44.627,
                44.631,
                44.635,
                44.64,
                44.644,
                44.649,
                44.653,
                44.658,
                44.662,
                44.666,
                44.67,
                44.675,
                44.68,
                44.684,
                44.688,
                44.693,
                44.697,
                44.701,
                44.705,
                44.71,
                44.714,
                44.719,
                44.723,
                44.727,
                44.731,
                44.735,
                44.739,
                44.744,
                44.749,
                44.753,
                44.757,
                44.761,
                44.765,
                44.769,
                44.774,
                44.778,
                44.782,
                44.787,
                44.791,
                44.795,
                44.799,
                44.804,
                44.808,
                44.812,
                44.816,
                44.82,
                44.824,
                44.828,
                44.833,
                44.837,
                44.841,
                44.845,
                44.849,
                44.853,
                44.857,
                44.862,
                44.866,
                44.87,
                44.874,
                44.878,
                44.882,
                44.886,
                44.89,
                44.895,
                44.899,
                44.903,
                44.907,
                44.911,
                44.915,
                44.919,
                44.924,
                44.927,
                44.931,
                44.935,
                44.939,
                44.943,
                44.947,
                44.951,
                44.956,
                44.959,
                44.963,
                44.967,
                44.971,
                44.975,
                44.979,
                44.983,
                44.987,
                44.991,
                44.995,
                44.999,
                45.003,
                45.006,
                45.01,
                45.015,
                45.019,
                45.023,
                45.026,
                45.03,
                45.034,
                45.038,
                45.042,
                45.046,
                45.05,
                45.054,
                45.057,
                45.061,
                45.065,
                45.069,
                45.073,
                45.077,
                45.081,
                45.084,
                45.088,
                45.092,
                45.095,
                45.099,
                45.104,
                45.107,
                45.111,
                45.115,
                45.118,
                45.122,
                45.126,
                45.129,
                45.134,
                45.137,
                45.141,
                45.145,
                45.148,
                45.152,
                45.155,
                45.159,
                45.164,
                45.167,
                45.171,
                45.174,
                45.178,
                45.181,
                45.185,
                45.188,
                45.193,
                45.196,
                45.2,
                45.204,
                45.207,
                45.211,
                45.214,
                45.219,
                45.222,
                45.226,
                45.229,
                45.233,
                45.236,
                45.239,
                45.243,
                45.246,
                45.251,
                45.254,
                45.258,
                45.261,
                45.265,
                45.268,
                45.271,
                45.275,
                45.279,
                45.283,
                45.286,
                45.289,
                45.293,
                45.296,
                45.3,
                45.303,
                45.307,
                45.311,
                45.314,
                45.317,
                45.321,
                45.324,
                45.327,
                45.331,
                45.335,
                45.338,
                45.342,
                45.345,
                45.348,
                45.352,
                45.355,
                45.358,
                45.362,
                45.366,
                45.369,
                45.372,
                45.375,
                45.379,
                45.382,
                45.385,
                45.388,
                45.393,
                45.396,
                45.399,
                45.402,
                45.406,
                45.409,
                45.412,
                45.415,
                45.419,
                45.423,
                45.426,
                45.429,
                45.432,
                45.435,
                45.439,
                45.442,
                45.446,
                45.449,
                45.452,
                45.455,
                45.458,
                45.462,
                45.465,
                45.468,
                45.471,
                45.475,
                45.478,
                45.481,
                45.484,
                45.488,
                45.491,
                45.494,
                45.497,
                45.501,
                45.504,
                45.507,
                45.51,
                45.513,
                45.516,
                45.519,
                45.522,
                45.525,
                45.529,
                45.532,
                45.535,
                45.538,
                45.542,
                45.545,
                45.548,
                45.551,
                45.554,
                45.558,
                45.561,
                45.564,
                45.567,
                45.57,
                45.573,
                45.576,
                45.579,
                45.582,
                45.585,
                45.588,
                45.591,
                45.594,
                45.597,
                45.6,
                45.603,
                45.606,
                45.61,
                45.613,
                45.616,
                45.619,
                45.622,
                45.625,
                45.627,
                45.63,
                45.633,
                45.637,
                45.64,
                45.643,
                45.646,
                45.649,
                45.652,
                45.654,
                45.657,
                45.66,
                45.664,
                45.667,
                45.67,
                45.673,
                45.675,
                45.678,
                45.681,
                45.684,
                45.687,
                45.691,
                45.693,
                45.696,
                45.699,
                45.702,
                45.705,
                45.707,
                45.71,
                45.713,
                45.717,
                45.72,
                45.722,
                45.725,
                45.728,
                45.731,
                45.733,
                45.736,
                45.739,
                45.743,
                45.745,
                45.748,
                45.751,
                45.754,
                45.756,
                45.759,
                45.762,
                45.765,
                45.767,
                45.771,
                45.774,
                45.776,
                45.779,
                45.782,
                45.785,
                45.787,
                45.79,
                45.793,
                45.796,
                45.799,
                45.802,
                45.804,
                45.807,
                45.81,
                45.812,
                45.815,
                45.818,
                45.821,
                45.824,
                45.827,
                45.829,
                45.832,
                45.834,
                45.837,
                45.84,
                45.842,
                45.846,
                45.849,
                45.851,
                45.854,
                45.856,
                45.859,
                45.862,
                45.864,
                45.867,
                45.869,
                45.873,
                45.876,
                45.878,
                45.881,
                45.883,
                45.886,
                45.888,
                45.891,
                45.894,
                45.897,
                45.9,
                45.902,
                45.905,
                45.907,
                45.91,
                45.912,
                45.915,
                45.917,
                45.92,
                45.923,
                45.926,
                45.929,
                45.931,
                45.934,
                45.936,
                45.939,
                45.941,
                45.944,
                45.946,
                45.95,
                45.952,
                45.954,
                45.957,
                45.959,
                45.962,
                45.964,
                45.967,
                45.969,
                45.973,
                45.975,
                45.978,
                45.98,
                45.983,
                45.985,
                45.988,
                45.99,
                45.992,
                45.995,
                45.998,
                46.001,
                46.003,
                46.006,
                46.008,
                46.01,
                46.013,
                46.015,
                46.018,
                46.02,
                46.023,
                46.026,
                46.028,
                46.031,
                46.033,
                46.035,
                46.038,
                46.04,
                46.043,
                46.045,
                46.048,
                46.051,
                46.053,
                46.056,
                46.058,
                46.06,
                46.063,
                46.065,
                46.067,
                46.07,
                46.073,
                46.075,
                46.078,
                46.08,
                46.082,
                46.085,
                46.087,
                46.089,
                46.092,
                46.094,
                46.097,
                46.1,
                46.102,
                46.104,
                46.107,
                46.109,
                46.111,
                46.114,
                46.116,
                46.118,
                46.121,
                46.124,
                46.126,
                46.128,
                46.131,
                46.133,
                46.135,
                46.137,
                46.14,
                46.142,
                46.145,
                46.147,
                46.15,
                46.152,
                46.154,
                46.156,
                46.159,
                46.161,
                46.163,
                46.166,
                46.169,
                46.171,
                46.173,
                46.175,
                46.178,
                46.18,
                46.182,
                46.184,
                46.186,
                46.189,
                46.191,
                46.194,
                46.196,
                46.199,
                46.201,
                46.203,
                46.205,
                46.207,
                46.21,
                46.212,
                46.214,
                46.217,
                46.219,
                46.221,
                46.224,
                46.226,
                46.228,
                46.23,
                46.232,
                46.234,
                46.237,
                46.239,
                46.242,
                46.244,
                46.246,
                46.248,
                46.251,
                46.253,
                46.255,
                46.257,
                46.259,
                46.261,
                46.264,
                46.266,
                46.269,
                46.271,
                46.273,
                46.275,
                46.277,
                46.279,
                46.281,
                46.283,
                46.286,
                46.289,
                46.291,
                46.293,
                46.295,
                46.297,
                46.299,
                46.301,
                46.303,
                46.305,
                46.308,
                46.311,
                46.313,
                46.315,
                46.317,
                46.319,
                46.321,
                46.323,
                46.325,
                46.327,
                46.329,
                46.331,
                46.334,
                46.336,
                46.338,
                46.34,
                46.343,
                46.345,
                46.347,
                46.349,
                46.351,
                46.353,
                46.355,
                46.358,
                46.36,
                46.362,
                46.364,
                46.366,
                46.368,
                46.37,
                46.372,
                46.374,
                46.376,
                46.378,
                46.381,
                46.383,
                46.385,
                46.387,
                46.389,
                46.391,
                46.393,
                46.395,
                46.397,
                46.399,
                46.401,
                46.404,
                46.406,
                46.408,
                46.41,
                46.412,
                46.414,
                46.416,
                46.418,
                46.42,
                46.422,
                46.424,
                46.427,
                46.429,
                46.43,
                46.432,
                46.434,
                46.436,
                46.438,
                46.44,
                46.442,
                46.444,
                46.446,
                46.449,
                46.451,
                46.453,
                46.455,
                46.457,
                46.459,
                46.461,
                46.463,
                46.464,
                46.466,
                46.468,
                46.471,
                46.473,
                46.475,
                46.477,
                46.479,
                46.481,
                46.483,
                46.485,
                46.487,
                46.488,
                46.49,
                46.493,
                46.495,
                46.497,
                46.499,
                46.501,
                46.503,
                46.505,
                46.506,
                46.508,
                46.51,
                46.512,
                46.514,
                46.517,
                46.519,
                46.521,
                46.522,
                46.524,
                46.526,
                46.528,
                46.53,
                46.532,
                46.534,
                46.535,
                46.538,
                46.54,
                46.542,
                46.544,
                46.546,
                46.548,
                46.549,
                46.551,
                46.553,
                46.555,
                46.557,
                46.559,
                46.561,
                46.563,
                46.565,
                46.567,
                46.569,
                46.57,
                46.572,
                46.574,
                46.576,
                46.578,
                46.58,
                46.582,
                46.584,
                46.586,
                46.588,
                46.59,
                46.591,
                46.593,
                46.595,
                46.597,
                46.599,
                46.6,
                46.602,
                46.605,
                46.607,
                46.609,
                46.61,
                46.612,
                46.614,
                46.616,
                46.618,
                46.619,
                46.621,
                46.623,
                46.625,
                46.627,
                46.629,
                46.631,
                46.633,
                46.634,
                46.636,
                46.638,
                46.64,
                46.642,
                46.643,
                46.645,
                46.648,
                46.649,
                46.651,
                46.653,
                46.655,
                46.657,
                46.658,
                46.66,
                46.662,
                46.664,
                46.665,
                46.667,
                46.67,
                46.672,
                46.673,
                46.675,
                46.677,
                46.678,
                46.68,
                46.682,
                46.684,
                46.685,
                46.687,
                46.689,
                46.691,
                46.693,
                46.695,
                46.697,
                46.698,
                46.7,
                46.702,
                46.703,
                46.705,
                46.707,
                46.709,
                46.71,
                46.713,
                46.715,
                46.716,
                46.718,
                46.72,
                46.722,
                46.723,
                46.725,
                46.727,
                46.728,
                46.73,
                46.732,
                46.734,
                46.736,
                46.738,
                46.739,
                46.741,
                46.743,
                46.744,
                46.746,
                46.748,
                46.75,
                46.751,
                46.753,
                46.755,
                46.757,
                46.759,
                46.76,
                46.762,
                46.764,
                46.765,
                46.767,
                46.769,
                46.77,
                46.772,
                46.774,
                46.775,
                46.778,
                46.78,
                46.781,
                46.783,
                46.785,
                46.786,
                46.788,
                46.79,
                46.791,
                46.793,
                46.795,
                46.796,
                46.799,
                46.8,
                46.802,
                46.804,
                46.805,
                46.807,
                46.809,
                46.81,
                46.812,
                46.813,
                46.815,
                46.817,
                46.819,
                46.821,
                46.823,
                46.824,
                46.826,
                46.827,
                46.829,
                46.831,
                46.832,
                46.834,
                46.836,
                46.837,
                46.839,
                46.841,
                46.843,
                46.844,
                46.846,
                46.848,
                46.849,
                46.851,
                46.853,
                46.854,
                46.856,
                46.857,
                46.859,
                46.862,
                46.863,
                46.865,
                46.866,
                46.868,
                46.869,
                46.871,
                46.873,
                46.874,
                46.876,
                46.877,
                46.879,
                46.881,
                46.883,
                46.885,
                46.886,
                46.888,
                46.889,
                46.891,
                46.893,
                46.894,
                46.896,
                46.897,
                46.899,
                46.9,
                46.902,
                46.904,
                46.906,
                46.908,
                46.909,
                46.911,
                46.912,
                46.914,
                46.915,
                46.917,
                46.919,
                46.92,
                46.922,
                46.923,
                46.926,
                46.927,
                46.929,
                46.93,
                46.932,
                46.933,
                46.935,
                46.936,
                46.938,
                46.94,
                46.941,
                46.943,
                46.944,
                46.947,
                46.948,
                46.95,
                46.951,
                46.953,
                46.954,
                46.956,
                46.957,
                46.959,
                46.961,
                46.962,
                46.964,
                46.965,
                46.968,
                46.969,
                46.971,
                46.972,
                46.974,
                46.975,
                46.977,
                46.978,
                46.98,
                46.981,
                46.983,
                46.984,
                46.986,
                46.988,
                46.99,
                46.991,
                46.993,
                46.994,
                46.996,
                46.997,
                46.999,
                47,
                47.002,
                47.003,
                47.005,
                47.006,
                47.009,
                47.01,
                47.012,
                47.013,
                47.015,
                47.016,
                47.018,
                47.019,
                47.021,
                47.022,
                47.024,
                47.025,
                47.027,
                47.029,
                47.03,
                47.032,
                47.033,
                47.035,
                47.036,
                47.038,
                47.039,
                47.041,
                47.042,
                47.044,
                47.045,
                47.047,
                47.049,
                47.051,
                47.052,
                47.053,
                47.055,
                47.056,
                47.058,
                47.059,
                47.061,
                47.062,
                47.064,
                47.065,
                47.067,
                47.068,
                47.07,
                47.072,
                47.073,
                47.075,
                47.076,
                47.078,
                47.079,
                47.081,
                47.082,
                47.084,
                47.085,
                47.086,
                47.088,
                47.09,
                47.092,
                47.093,
                47.095,
                47.096,
                47.097,
                47.099,
                47.1,
                47.102,
                47.103,
                47.105,
                47.106,
                47.108,
                47.109,
                47.111,
                47.113,
                47.114,
                47.116,
                47.117,
                47.119,
                47.12,
                47.121,
                47.123,
                47.124,
                47.126,
                47.127,
                47.128,
                47.131,
                47.132,
                47.134,
                47.135,
                47.137,
                47.138,
                47.139,
                47.141,
                47.142,
                47.144,
                47.145,
                47.146,
                47.148,
                47.149,
                47.152,
                47.153,
                47.154,
                47.156,
                47.157,
                47.159,
                47.16,
                47.161,
                47.163,
                47.164,
                47.166,
                47.167,
                47.168,
                47.17,
                47.172,
                47.173,
                47.175,
                47.176,
                47.178,
                47.179,
                47.18,
                47.182,
                47.183,
                47.185,
                47.186,
                47.187,
                47.189,
                47.19,
                47.193,
                47.194,
                47.195,
                47.197,
                47.198,
                47.2,
                47.201,
                47.202,
                47.204,
                47.205,
                47.206,
                47.208,
                47.209,
                47.21,
                47.213,
                47.214,
                47.216,
                47.217,
                47.218,
                47.22,
                47.221,
                47.222,
                47.224,
                47.225,
                47.227,
                47.228,
                47.229,
                47.231,
                47.233,
                47.234,
                47.236,
                47.237,
                47.238,
                47.24,
                47.241,
                47.242,
                47.244,
                47.245,
                47.247,
                47.248,
                47.249,
                47.251,
                47.253,
                47.254,
                47.256,
                47.257,
                47.258,
                47.26,
                47.261,
                47.262,
                47.264,
                47.265,
                47.266,
                47.268,
                47.269,
                47.27,
                47.273,
                47.274,
                47.275,
                47.277,
                47.278,
                47.279,
                47.281,
                47.282,
                47.284,
                47.285,
                47.286,
                47.287,
                47.289
            ],
            P5: [
                31.931,
                32.031,
                32.132,
                32.232,
                32.333,
                32.434,
                32.535,
                32.635,
                32.737,
                32.837,
                32.939,
                33.039,
                33.141,
                33.242,
                33.343,
                33.434,
                33.523,
                33.61,
                33.694,
                33.778,
                33.859,
                33.938,
                34.016,
                34.092,
                34.168,
                34.241,
                34.313,
                34.383,
                34.452,
                34.521,
                34.588,
                34.654,
                34.718,
                34.782,
                34.845,
                34.907,
                34.968,
                35.027,
                35.087,
                35.144,
                35.202,
                35.259,
                35.315,
                35.37,
                35.425,
                35.478,
                35.531,
                35.584,
                35.636,
                35.687,
                35.739,
                35.789,
                35.838,
                35.888,
                35.936,
                35.985,
                36.033,
                36.08,
                36.127,
                36.173,
                36.219,
                36.264,
                36.31,
                36.354,
                36.399,
                36.444,
                36.487,
                36.531,
                36.574,
                36.616,
                36.659,
                36.7,
                36.742,
                36.783,
                36.824,
                36.865,
                36.906,
                36.946,
                36.986,
                37.025,
                37.065,
                37.103,
                37.142,
                37.181,
                37.219,
                37.257,
                37.295,
                37.332,
                37.369,
                37.406,
                37.443,
                37.48,
                37.516,
                37.552,
                37.588,
                37.624,
                37.658,
                37.694,
                37.729,
                37.764,
                37.798,
                37.832,
                37.867,
                37.901,
                37.934,
                37.968,
                38.001,
                38.034,
                38.067,
                38.1,
                38.132,
                38.164,
                38.197,
                38.228,
                38.26,
                38.292,
                38.323,
                38.354,
                38.385,
                38.416,
                38.447,
                38.477,
                38.507,
                38.537,
                38.567,
                38.597,
                38.626,
                38.656,
                38.685,
                38.714,
                38.743,
                38.772,
                38.8,
                38.829,
                38.857,
                38.885,
                38.913,
                38.941,
                38.968,
                38.996,
                39.023,
                39.05,
                39.077,
                39.104,
                39.131,
                39.157,
                39.184,
                39.21,
                39.236,
                39.262,
                39.288,
                39.313,
                39.339,
                39.364,
                39.389,
                39.414,
                39.44,
                39.465,
                39.489,
                39.514,
                39.538,
                39.562,
                39.586,
                39.61,
                39.634,
                39.658,
                39.682,
                39.705,
                39.729,
                39.752,
                39.775,
                39.798,
                39.821,
                39.843,
                39.866,
                39.889,
                39.911,
                39.933,
                39.956,
                39.978,
                39.999,
                40.021,
                40.043,
                40.065,
                40.086,
                40.108,
                40.129,
                40.15,
                40.171,
                40.191,
                40.213,
                40.233,
                40.254,
                40.274,
                40.295,
                40.315,
                40.335,
                40.355,
                40.375,
                40.395,
                40.414,
                40.434,
                40.454,
                40.473,
                40.492,
                40.511,
                40.531,
                40.55,
                40.568,
                40.588,
                40.606,
                40.625,
                40.643,
                40.662,
                40.68,
                40.698,
                40.717,
                40.735,
                40.752,
                40.771,
                40.788,
                40.806,
                40.824,
                40.841,
                40.859,
                40.876,
                40.893,
                40.911,
                40.928,
                40.944,
                40.962,
                40.978,
                40.995,
                41.012,
                41.028,
                41.045,
                41.061,
                41.077,
                41.094,
                41.11,
                41.126,
                41.142,
                41.158,
                41.174,
                41.19,
                41.205,
                41.221,
                41.237,
                41.252,
                41.268,
                41.283,
                41.298,
                41.314,
                41.328,
                41.343,
                41.359,
                41.373,
                41.388,
                41.403,
                41.418,
                41.432,
                41.447,
                41.461,
                41.475,
                41.49,
                41.504,
                41.518,
                41.533,
                41.547,
                41.56,
                41.575,
                41.589,
                41.602,
                41.616,
                41.63,
                41.643,
                41.657,
                41.671,
                41.684,
                41.698,
                41.711,
                41.724,
                41.737,
                41.75,
                41.763,
                41.777,
                41.789,
                41.802,
                41.815,
                41.828,
                41.841,
                41.853,
                41.866,
                41.878,
                41.891,
                41.904,
                41.916,
                41.928,
                41.941,
                41.953,
                41.965,
                41.977,
                41.989,
                42.001,
                42.013,
                42.025,
                42.037,
                42.049,
                42.06,
                42.072,
                42.084,
                42.095,
                42.107,
                42.119,
                42.13,
                42.142,
                42.153,
                42.164,
                42.175,
                42.187,
                42.198,
                42.209,
                42.221,
                42.232,
                42.242,
                42.253,
                42.265,
                42.275,
                42.286,
                42.297,
                42.308,
                42.318,
                42.329,
                42.34,
                42.35,
                42.361,
                42.371,
                42.382,
                42.392,
                42.402,
                42.412,
                42.423,
                42.433,
                42.443,
                42.454,
                42.464,
                42.474,
                42.484,
                42.494,
                42.504,
                42.514,
                42.524,
                42.534,
                42.544,
                42.553,
                42.563,
                42.573,
                42.583,
                42.592,
                42.602,
                42.612,
                42.621,
                42.63,
                42.64,
                42.65,
                42.659,
                42.668,
                42.678,
                42.687,
                42.696,
                42.705,
                42.715,
                42.724,
                42.733,
                42.742,
                42.751,
                42.76,
                42.769,
                42.778,
                42.787,
                42.796,
                42.805,
                42.813,
                42.823,
                42.831,
                42.84,
                42.848,
                42.858,
                42.866,
                42.875,
                42.883,
                42.892,
                42.901,
                42.909,
                42.917,
                42.926,
                42.934,
                42.943,
                42.951,
                42.959,
                42.968,
                42.976,
                42.984,
                42.992,
                43.001,
                43.009,
                43.017,
                43.025,
                43.034,
                43.042,
                43.049,
                43.057,
                43.066,
                43.074,
                43.081,
                43.089,
                43.097,
                43.105,
                43.113,
                43.121,
                43.128,
                43.137,
                43.144,
                43.152,
                43.159,
                43.167,
                43.175,
                43.182,
                43.19,
                43.197,
                43.205,
                43.213,
                43.22,
                43.228,
                43.236,
                43.243,
                43.25,
                43.257,
                43.264,
                43.272,
                43.28,
                43.287,
                43.294,
                43.302,
                43.309,
                43.316,
                43.323,
                43.33,
                43.338,
                43.345,
                43.352,
                43.359,
                43.366,
                43.373,
                43.38,
                43.387,
                43.394,
                43.401,
                43.408,
                43.415,
                43.422,
                43.429,
                43.436,
                43.443,
                43.449,
                43.456,
                43.463,
                43.47,
                43.477,
                43.483,
                43.49,
                43.497,
                43.504,
                43.51,
                43.517,
                43.523,
                43.531,
                43.537,
                43.544,
                43.55,
                43.557,
                43.564,
                43.57,
                43.576,
                43.583,
                43.589,
                43.596,
                43.603,
                43.609,
                43.615,
                43.621,
                43.628,
                43.635,
                43.641,
                43.647,
                43.653,
                43.66,
                43.666,
                43.672,
                43.679,
                43.685,
                43.692,
                43.698,
                43.704,
                43.71,
                43.716,
                43.723,
                43.729,
                43.735,
                43.741,
                43.747,
                43.753,
                43.759,
                43.765,
                43.771,
                43.777,
                43.784,
                43.79,
                43.796,
                43.801,
                43.807,
                43.814,
                43.82,
                43.826,
                43.831,
                43.837,
                43.843,
                43.849,
                43.855,
                43.861,
                43.867,
                43.872,
                43.879,
                43.885,
                43.89,
                43.896,
                43.902,
                43.908,
                43.914,
                43.919,
                43.925,
                43.931,
                43.936,
                43.943,
                43.948,
                43.954,
                43.959,
                43.965,
                43.971,
                43.977,
                43.982,
                43.988,
                43.993,
                43.999,
                44.005,
                44.01,
                44.016,
                44.021,
                44.027,
                44.033,
                44.038,
                44.044,
                44.049,
                44.055,
                44.061,
                44.066,
                44.072,
                44.077,
                44.082,
                44.088,
                44.094,
                44.099,
                44.104,
                44.11,
                44.115,
                44.12,
                44.126,
                44.132,
                44.137,
                44.142,
                44.147,
                44.153,
                44.159,
                44.164,
                44.169,
                44.174,
                44.18,
                44.186,
                44.191,
                44.196,
                44.201,
                44.206,
                44.211,
                44.217,
                44.223,
                44.228,
                44.233,
                44.238,
                44.244,
                44.249,
                44.254,
                44.259,
                44.264,
                44.269,
                44.275,
                44.28,
                44.285,
                44.29,
                44.295,
                44.3,
                44.306,
                44.311,
                44.316,
                44.321,
                44.326,
                44.331,
                44.337,
                44.342,
                44.347,
                44.352,
                44.357,
                44.362,
                44.368,
                44.373,
                44.378,
                44.383,
                44.388,
                44.392,
                44.398,
                44.403,
                44.408,
                44.413,
                44.418,
                44.423,
                44.428,
                44.433,
                44.438,
                44.443,
                44.448,
                44.453,
                44.458,
                44.463,
                44.468,
                44.473,
                44.478,
                44.483,
                44.488,
                44.493,
                44.498,
                44.503,
                44.507,
                44.512,
                44.517,
                44.522,
                44.527,
                44.532,
                44.537,
                44.542,
                44.546,
                44.552,
                44.557,
                44.561,
                44.566,
                44.571,
                44.576,
                44.581,
                44.586,
                44.591,
                44.595,
                44.6,
                44.605,
                44.61,
                44.615,
                44.619,
                44.624,
                44.629,
                44.634,
                44.638,
                44.644,
                44.648,
                44.653,
                44.657,
                44.662,
                44.667,
                44.672,
                44.677,
                44.681,
                44.686,
                44.691,
                44.695,
                44.7,
                44.705,
                44.71,
                44.714,
                44.719,
                44.724,
                44.728,
                44.733,
                44.738,
                44.743,
                44.747,
                44.752,
                44.756,
                44.761,
                44.766,
                44.771,
                44.775,
                44.78,
                44.784,
                44.789,
                44.793,
                44.798,
                44.803,
                44.807,
                44.812,
                44.816,
                44.821,
                44.826,
                44.83,
                44.835,
                44.839,
                44.844,
                44.848,
                44.853,
                44.858,
                44.862,
                44.867,
                44.871,
                44.876,
                44.88,
                44.884,
                44.89,
                44.894,
                44.898,
                44.903,
                44.907,
                44.911,
                44.916,
                44.921,
                44.925,
                44.93,
                44.934,
                44.938,
                44.943,
                44.948,
                44.952,
                44.956,
                44.961,
                44.965,
                44.969,
                44.974,
                44.979,
                44.983,
                44.987,
                44.992,
                44.996,
                45,
                45.004,
                45.009,
                45.014,
                45.018,
                45.022,
                45.026,
                45.031,
                45.035,
                45.04,
                45.044,
                45.048,
                45.052,
                45.057,
                45.061,
                45.065,
                45.069,
                45.074,
                45.078,
                45.083,
                45.087,
                45.091,
                45.095,
                45.099,
                45.104,
                45.108,
                45.112,
                45.117,
                45.121,
                45.125,
                45.129,
                45.134,
                45.138,
                45.142,
                45.146,
                45.15,
                45.154,
                45.158,
                45.163,
                45.167,
                45.171,
                45.175,
                45.18,
                45.184,
                45.188,
                45.192,
                45.196,
                45.201,
                45.205,
                45.209,
                45.213,
                45.217,
                45.221,
                45.225,
                45.229,
                45.233,
                45.237,
                45.241,
                45.245,
                45.249,
                45.254,
                45.258,
                45.262,
                45.266,
                45.27,
                45.274,
                45.278,
                45.281,
                45.286,
                45.29,
                45.294,
                45.298,
                45.302,
                45.306,
                45.31,
                45.314,
                45.318,
                45.322,
                45.326,
                45.33,
                45.333,
                45.337,
                45.341,
                45.346,
                45.35,
                45.353,
                45.357,
                45.361,
                45.365,
                45.369,
                45.373,
                45.377,
                45.381,
                45.385,
                45.388,
                45.392,
                45.396,
                45.4,
                45.404,
                45.408,
                45.412,
                45.415,
                45.419,
                45.423,
                45.427,
                45.43,
                45.435,
                45.438,
                45.442,
                45.446,
                45.449,
                45.453,
                45.457,
                45.461,
                45.465,
                45.469,
                45.472,
                45.476,
                45.479,
                45.483,
                45.487,
                45.49,
                45.495,
                45.498,
                45.502,
                45.506,
                45.509,
                45.513,
                45.516,
                45.52,
                45.524,
                45.528,
                45.531,
                45.535,
                45.539,
                45.542,
                45.546,
                45.55,
                45.553,
                45.557,
                45.561,
                45.564,
                45.568,
                45.571,
                45.575,
                45.578,
                45.582,
                45.586,
                45.589,
                45.593,
                45.596,
                45.6,
                45.603,
                45.607,
                45.611,
                45.614,
                45.618,
                45.621,
                45.625,
                45.628,
                45.631,
                45.635,
                45.639,
                45.642,
                45.646,
                45.649,
                45.652,
                45.656,
                45.659,
                45.663,
                45.667,
                45.67,
                45.673,
                45.677,
                45.68,
                45.684,
                45.687,
                45.69,
                45.694,
                45.698,
                45.701,
                45.704,
                45.707,
                45.711,
                45.714,
                45.717,
                45.721,
                45.725,
                45.728,
                45.731,
                45.734,
                45.738,
                45.741,
                45.744,
                45.747,
                45.751,
                45.755,
                45.758,
                45.761,
                45.764,
                45.768,
                45.771,
                45.774,
                45.778,
                45.781,
                45.784,
                45.788,
                45.791,
                45.794,
                45.797,
                45.8,
                45.803,
                45.807,
                45.81,
                45.814,
                45.817,
                45.82,
                45.823,
                45.826,
                45.829,
                45.833,
                45.836,
                45.839,
                45.842,
                45.846,
                45.849,
                45.852,
                45.855,
                45.858,
                45.862,
                45.865,
                45.868,
                45.871,
                45.874,
                45.877,
                45.88,
                45.883,
                45.886,
                45.89,
                45.893,
                45.896,
                45.899,
                45.902,
                45.905,
                45.908,
                45.911,
                45.914,
                45.918,
                45.921,
                45.924,
                45.927,
                45.93,
                45.933,
                45.936,
                45.939,
                45.943,
                45.945,
                45.949,
                45.951,
                45.954,
                45.957,
                45.96,
                45.963,
                45.966,
                45.97,
                45.973,
                45.976,
                45.979,
                45.981,
                45.984,
                45.987,
                45.99,
                45.993,
                45.997,
                46,
                46.002,
                46.005,
                46.008,
                46.011,
                46.014,
                46.017,
                46.02,
                46.023,
                46.026,
                46.029,
                46.032,
                46.035,
                46.038,
                46.04,
                46.043,
                46.046,
                46.05,
                46.052,
                46.055,
                46.058,
                46.061,
                46.064,
                46.066,
                46.069,
                46.072,
                46.076,
                46.078,
                46.081,
                46.084,
                46.087,
                46.089,
                46.092,
                46.095,
                46.098,
                46.1,
                46.104,
                46.107,
                46.11,
                46.112,
                46.115,
                46.118,
                46.12,
                46.123,
                46.126,
                46.129,
                46.132,
                46.135,
                46.137,
                46.14,
                46.143,
                46.146,
                46.148,
                46.151,
                46.154,
                46.157,
                46.16,
                46.162,
                46.165,
                46.168,
                46.17,
                46.173,
                46.176,
                46.179,
                46.182,
                46.184,
                46.187,
                46.19,
                46.192,
                46.195,
                46.198,
                46.2,
                46.203,
                46.206,
                46.209,
                46.211,
                46.214,
                46.217,
                46.219,
                46.222,
                46.224,
                46.227,
                46.23,
                46.233,
                46.236,
                46.238,
                46.241,
                46.243,
                46.246,
                46.248,
                46.251,
                46.254,
                46.257,
                46.259,
                46.262,
                46.265,
                46.267,
                46.27,
                46.272,
                46.275,
                46.277,
                46.28,
                46.283,
                46.286,
                46.288,
                46.291,
                46.293,
                46.296,
                46.298,
                46.301,
                46.303,
                46.306,
                46.309,
                46.311,
                46.314,
                46.316,
                46.319,
                46.321,
                46.324,
                46.326,
                46.329,
                46.332,
                46.334,
                46.337,
                46.339,
                46.342,
                46.344,
                46.347,
                46.349,
                46.352,
                46.354,
                46.357,
                46.36,
                46.362,
                46.364,
                46.367,
                46.369,
                46.372,
                46.374,
                46.376,
                46.379,
                46.382,
                46.385,
                46.387,
                46.389,
                46.392,
                46.394,
                46.396,
                46.399,
                46.401,
                46.404,
                46.407,
                46.409,
                46.412,
                46.414,
                46.416,
                46.419,
                46.421,
                46.423,
                46.426,
                46.428,
                46.431,
                46.434,
                46.436,
                46.438,
                46.441,
                46.443,
                46.445,
                46.448,
                46.45,
                46.452,
                46.455,
                46.458,
                46.46,
                46.462,
                46.465,
                46.467,
                46.469,
                46.471,
                46.474,
                46.476,
                46.479,
                46.481,
                46.484,
                46.486,
                46.488,
                46.49,
                46.493,
                46.495,
                46.497,
                46.5,
                46.503,
                46.505,
                46.507,
                46.509,
                46.512,
                46.514,
                46.516,
                46.518,
                46.521,
                46.523,
                46.525,
                46.528,
                46.53,
                46.533,
                46.535,
                46.537,
                46.539,
                46.542,
                46.544,
                46.546,
                46.548,
                46.551,
                46.553,
                46.556,
                46.558,
                46.56,
                46.562,
                46.564,
                46.567,
                46.569,
                46.571,
                46.573,
                46.576,
                46.578,
                46.58,
                46.583,
                46.585,
                46.587,
                46.589,
                46.591,
                46.593,
                46.596,
                46.599,
                46.601,
                46.603,
                46.605,
                46.607,
                46.609,
                46.611,
                46.614,
                46.616,
                46.618,
                46.62,
                46.623,
                46.625,
                46.627,
                46.629,
                46.631,
                46.633,
                46.636,
                46.638,
                46.64,
                46.642,
                46.645,
                46.647,
                46.649,
                46.651,
                46.653,
                46.655,
                46.657,
                46.66,
                46.662,
                46.664,
                46.666,
                46.669,
                46.671,
                46.673,
                46.675,
                46.677,
                46.679,
                46.681,
                46.683,
                46.685,
                46.687,
                46.689,
                46.692,
                46.694,
                46.696,
                46.698,
                46.7,
                46.702,
                46.704,
                46.706,
                46.708,
                46.71,
                46.713,
                46.715,
                46.717,
                46.719,
                46.721,
                46.723,
                46.725,
                46.727,
                46.729,
                46.731,
                46.734,
                46.736,
                46.738,
                46.74,
                46.742,
                46.744,
                46.746,
                46.748,
                46.75,
                46.752,
                46.754,
                46.756,
                46.758,
                46.761,
                46.763,
                46.765,
                46.767,
                46.769,
                46.771,
                46.773,
                46.775,
                46.777,
                46.779,
                46.781,
                46.784,
                46.786,
                46.787,
                46.789,
                46.791,
                46.793,
                46.795,
                46.797,
                46.799,
                46.801,
                46.803,
                46.806,
                46.808,
                46.81,
                46.812,
                46.813,
                46.815,
                46.817,
                46.819,
                46.821,
                46.823,
                46.825,
                46.828,
                46.83,
                46.832,
                46.834,
                46.835,
                46.837,
                46.839,
                46.841,
                46.843,
                46.845,
                46.847,
                46.849,
                46.851,
                46.853,
                46.855,
                46.857,
                46.859,
                46.861,
                46.863,
                46.865,
                46.867,
                46.868,
                46.87,
                46.873,
                46.875,
                46.877,
                46.879,
                46.88,
                46.882,
                46.884,
                46.886,
                46.888,
                46.89,
                46.892,
                46.893,
                46.896,
                46.898,
                46.9,
                46.902,
                46.904,
                46.905,
                46.907,
                46.909,
                46.911,
                46.913,
                46.915,
                46.917,
                46.919,
                46.921,
                46.923,
                46.925,
                46.926,
                46.928,
                46.93,
                46.932,
                46.934,
                46.935,
                46.937,
                46.94,
                46.942,
                46.943,
                46.945,
                46.947,
                46.949,
                46.951,
                46.952,
                46.954,
                46.956,
                46.958,
                46.96,
                46.962,
                46.964,
                46.966,
                46.968,
                46.969,
                46.971,
                46.973,
                46.975,
                46.977,
                46.978,
                46.98,
                46.983,
                46.984,
                46.986,
                46.988,
                46.99,
                46.992,
                46.993,
                46.995,
                46.997,
                46.999,
                47,
                47.002,
                47.005,
                47.006,
                47.008,
                47.01,
                47.012,
                47.013,
                47.015,
                47.017,
                47.019,
                47.02,
                47.022,
                47.024,
                47.026,
                47.028,
                47.03,
                47.032,
                47.033,
                47.035,
                47.037,
                47.039,
                47.04,
                47.042,
                47.044,
                47.045,
                47.048,
                47.05,
                47.051,
                47.053,
                47.055,
                47.057,
                47.058,
                47.06,
                47.062,
                47.063,
                47.065,
                47.067,
                47.069,
                47.071,
                47.073,
                47.074,
                47.076,
                47.078,
                47.079,
                47.081,
                47.083,
                47.085,
                47.086,
                47.088,
                47.09,
                47.092,
                47.094,
                47.096,
                47.097,
                47.099,
                47.101,
                47.102,
                47.104,
                47.106,
                47.107,
                47.109,
                47.111,
                47.113,
                47.115,
                47.116,
                47.118,
                47.12,
                47.121,
                47.123,
                47.125,
                47.126,
                47.128,
                47.13,
                47.131,
                47.134,
                47.136,
                47.137,
                47.139,
                47.141,
                47.142,
                47.144,
                47.145,
                47.147,
                47.149,
                47.15,
                47.152,
                47.154,
                47.156,
                47.158,
                47.159,
                47.161,
                47.163,
                47.164,
                47.166,
                47.168,
                47.169,
                47.171,
                47.172,
                47.174,
                47.176,
                47.178,
                47.18,
                47.181,
                47.183,
                47.185,
                47.186,
                47.188,
                47.189,
                47.191,
                47.193,
                47.194,
                47.197,
                47.198,
                47.2,
                47.201,
                47.203,
                47.205,
                47.206,
                47.208,
                47.209,
                47.211,
                47.213,
                47.214,
                47.216,
                47.218,
                47.22,
                47.221,
                47.223,
                47.225,
                47.226,
                47.228,
                47.229,
                47.231,
                47.233,
                47.234,
                47.236,
                47.237,
                47.24,
                47.241,
                47.243,
                47.244,
                47.246,
                47.248,
                47.249,
                47.251,
                47.252,
                47.254,
                47.255,
                47.257,
                47.259,
                47.261,
                47.263,
                47.264,
                47.266,
                47.267,
                47.269,
                47.27,
                47.272,
                47.273,
                47.275,
                47.277,
                47.278,
                47.28,
                47.282,
                47.284,
                47.285,
                47.287,
                47.288,
                47.29,
                47.291,
                47.293,
                47.294,
                47.296,
                47.297,
                47.299,
                47.3,
                47.303,
                47.304,
                47.306,
                47.307,
                47.309,
                47.311,
                47.312,
                47.314,
                47.315,
                47.317,
                47.318,
                47.32,
                47.321,
                47.324,
                47.325,
                47.327,
                47.328,
                47.33,
                47.331,
                47.333,
                47.334,
                47.336,
                47.337,
                47.339,
                47.34,
                47.342,
                47.344,
                47.346,
                47.347,
                47.348,
                47.35,
                47.352,
                47.353,
                47.355,
                47.356,
                47.358,
                47.359,
                47.36,
                47.362,
                47.364,
                47.366,
                47.367,
                47.369,
                47.37,
                47.372,
                47.373,
                47.375,
                47.376,
                47.378,
                47.379,
                47.381,
                47.382,
                47.385,
                47.386,
                47.387,
                47.389,
                47.39,
                47.392,
                47.393,
                47.395,
                47.396,
                47.398,
                47.399,
                47.401,
                47.402,
                47.404,
                47.406,
                47.407,
                47.409,
                47.41,
                47.412,
                47.413,
                47.415,
                47.416,
                47.418,
                47.419,
                47.421,
                47.422,
                47.423,
                47.426,
                47.427,
                47.429,
                47.43,
                47.432,
                47.433,
                47.434,
                47.436,
                47.437,
                47.439,
                47.44,
                47.442,
                47.443,
                47.445,
                47.447,
                47.448,
                47.45,
                47.451,
                47.453,
                47.454,
                47.455,
                47.457,
                47.458,
                47.46,
                47.461,
                47.463,
                47.464,
                47.466,
                47.468,
                47.469,
                47.471,
                47.472,
                47.473,
                47.475,
                47.476,
                47.478,
                47.479,
                47.481,
                47.482,
                47.483,
                47.485,
                47.487,
                47.488,
                47.49,
                47.491,
                47.493,
                47.494,
                47.496,
                47.497,
                47.498,
                47.5,
                47.501,
                47.503,
                47.504,
                47.505,
                47.508,
                47.509,
                47.51,
                47.512,
                47.513,
                47.515,
                47.516,
                47.518,
                47.519,
                47.52,
                47.522,
                47.523,
                47.524,
                47.526,
                47.528,
                47.529,
                47.531,
                47.532,
                47.534,
                47.535,
                47.536,
                47.538,
                47.539,
                47.541,
                47.542,
                47.543,
                47.545,
                47.546,
                47.548,
                47.55,
                47.551,
                47.553,
                47.554,
                47.555,
                47.557,
                47.558,
                47.559,
                47.561,
                47.562,
                47.564,
                47.565,
                47.566,
                47.568,
                47.57,
                47.571,
                47.573,
                47.574,
                47.575,
                47.577,
                47.578,
                47.579,
                47.581,
                47.582,
                47.584,
                47.585,
                47.586,
                47.588,
                47.59,
                47.591,
                47.593,
                47.594,
                47.595,
                47.597,
                47.598,
                47.599,
                47.601,
                47.602,
                47.604,
                47.605,
                47.606,
                47.608,
                47.61,
                47.611,
                47.612,
                47.614,
                47.615,
                47.616,
                47.618,
                47.619,
                47.621,
                47.622,
                47.623,
                47.625
            ],
            P10: [
                32.361,
                32.46,
                32.56,
                32.66,
                32.76,
                32.859,
                32.959,
                33.059,
                33.159,
                33.259,
                33.359,
                33.459,
                33.559,
                33.659,
                33.76,
                33.851,
                33.94,
                34.028,
                34.113,
                34.197,
                34.279,
                34.359,
                34.438,
                34.514,
                34.59,
                34.664,
                34.736,
                34.807,
                34.877,
                34.946,
                35.014,
                35.08,
                35.146,
                35.209,
                35.273,
                35.335,
                35.397,
                35.457,
                35.517,
                35.575,
                35.633,
                35.69,
                35.747,
                35.802,
                35.858,
                35.912,
                35.965,
                36.019,
                36.071,
                36.123,
                36.174,
                36.225,
                36.275,
                36.325,
                36.374,
                36.423,
                36.471,
                36.518,
                36.566,
                36.612,
                36.659,
                36.705,
                36.75,
                36.796,
                36.841,
                36.885,
                36.929,
                36.973,
                37.017,
                37.06,
                37.102,
                37.144,
                37.186,
                37.228,
                37.27,
                37.311,
                37.352,
                37.392,
                37.432,
                37.472,
                37.512,
                37.551,
                37.59,
                37.629,
                37.668,
                37.706,
                37.744,
                37.781,
                37.819,
                37.857,
                37.894,
                37.931,
                37.967,
                38.003,
                38.04,
                38.076,
                38.111,
                38.147,
                38.182,
                38.217,
                38.252,
                38.286,
                38.321,
                38.355,
                38.389,
                38.423,
                38.456,
                38.49,
                38.523,
                38.556,
                38.588,
                38.621,
                38.654,
                38.686,
                38.718,
                38.75,
                38.781,
                38.813,
                38.844,
                38.875,
                38.906,
                38.936,
                38.967,
                38.998,
                39.027,
                39.058,
                39.087,
                39.117,
                39.147,
                39.176,
                39.205,
                39.234,
                39.263,
                39.292,
                39.32,
                39.348,
                39.376,
                39.405,
                39.432,
                39.46,
                39.488,
                39.515,
                39.542,
                39.569,
                39.596,
                39.623,
                39.65,
                39.676,
                39.702,
                39.728,
                39.755,
                39.78,
                39.806,
                39.831,
                39.857,
                39.882,
                39.908,
                39.933,
                39.957,
                39.982,
                40.007,
                40.031,
                40.056,
                40.08,
                40.104,
                40.128,
                40.152,
                40.175,
                40.199,
                40.222,
                40.246,
                40.269,
                40.292,
                40.315,
                40.338,
                40.361,
                40.383,
                40.406,
                40.428,
                40.45,
                40.472,
                40.494,
                40.516,
                40.538,
                40.56,
                40.581,
                40.603,
                40.624,
                40.645,
                40.666,
                40.687,
                40.708,
                40.729,
                40.749,
                40.77,
                40.79,
                40.81,
                40.831,
                40.851,
                40.871,
                40.891,
                40.911,
                40.93,
                40.95,
                40.969,
                40.989,
                41.008,
                41.027,
                41.046,
                41.065,
                41.084,
                41.103,
                41.122,
                41.14,
                41.159,
                41.177,
                41.196,
                41.214,
                41.232,
                41.25,
                41.268,
                41.285,
                41.304,
                41.321,
                41.339,
                41.356,
                41.373,
                41.391,
                41.408,
                41.425,
                41.442,
                41.459,
                41.476,
                41.493,
                41.51,
                41.527,
                41.543,
                41.559,
                41.576,
                41.592,
                41.608,
                41.625,
                41.64,
                41.657,
                41.673,
                41.688,
                41.704,
                41.72,
                41.735,
                41.751,
                41.766,
                41.782,
                41.797,
                41.812,
                41.827,
                41.843,
                41.858,
                41.872,
                41.888,
                41.902,
                41.917,
                41.932,
                41.946,
                41.96,
                41.975,
                41.989,
                42.004,
                42.018,
                42.032,
                42.046,
                42.061,
                42.074,
                42.088,
                42.102,
                42.116,
                42.13,
                42.144,
                42.157,
                42.17,
                42.184,
                42.197,
                42.211,
                42.224,
                42.237,
                42.25,
                42.264,
                42.277,
                42.29,
                42.302,
                42.316,
                42.328,
                42.341,
                42.354,
                42.366,
                42.379,
                42.392,
                42.404,
                42.416,
                42.429,
                42.441,
                42.453,
                42.466,
                42.478,
                42.49,
                42.502,
                42.514,
                42.526,
                42.538,
                42.55,
                42.562,
                42.573,
                42.585,
                42.597,
                42.609,
                42.62,
                42.632,
                42.643,
                42.654,
                42.666,
                42.677,
                42.688,
                42.7,
                42.711,
                42.722,
                42.733,
                42.744,
                42.755,
                42.766,
                42.777,
                42.788,
                42.799,
                42.81,
                42.82,
                42.831,
                42.842,
                42.852,
                42.863,
                42.874,
                42.884,
                42.894,
                42.904,
                42.915,
                42.926,
                42.936,
                42.946,
                42.956,
                42.966,
                42.976,
                42.987,
                42.997,
                43.007,
                43.016,
                43.027,
                43.037,
                43.046,
                43.056,
                43.066,
                43.076,
                43.085,
                43.095,
                43.105,
                43.114,
                43.124,
                43.134,
                43.143,
                43.152,
                43.162,
                43.172,
                43.181,
                43.19,
                43.199,
                43.209,
                43.218,
                43.227,
                43.236,
                43.246,
                43.255,
                43.263,
                43.272,
                43.282,
                43.291,
                43.299,
                43.308,
                43.317,
                43.326,
                43.335,
                43.343,
                43.353,
                43.361,
                43.37,
                43.378,
                43.387,
                43.396,
                43.404,
                43.413,
                43.422,
                43.43,
                43.438,
                43.447,
                43.455,
                43.464,
                43.472,
                43.48,
                43.488,
                43.497,
                43.505,
                43.513,
                43.521,
                43.53,
                43.538,
                43.546,
                43.554,
                43.562,
                43.57,
                43.578,
                43.586,
                43.594,
                43.602,
                43.61,
                43.617,
                43.625,
                43.633,
                43.641,
                43.649,
                43.656,
                43.664,
                43.672,
                43.68,
                43.687,
                43.695,
                43.703,
                43.71,
                43.718,
                43.725,
                43.733,
                43.74,
                43.748,
                43.755,
                43.762,
                43.77,
                43.777,
                43.785,
                43.792,
                43.8,
                43.807,
                43.814,
                43.821,
                43.828,
                43.836,
                43.843,
                43.85,
                43.857,
                43.864,
                43.872,
                43.879,
                43.885,
                43.892,
                43.9,
                43.907,
                43.914,
                43.92,
                43.927,
                43.935,
                43.942,
                43.948,
                43.955,
                43.962,
                43.969,
                43.976,
                43.982,
                43.989,
                43.996,
                44.003,
                44.01,
                44.016,
                44.023,
                44.03,
                44.036,
                44.043,
                44.05,
                44.056,
                44.063,
                44.07,
                44.076,
                44.082,
                44.089,
                44.096,
                44.102,
                44.109,
                44.115,
                44.121,
                44.128,
                44.134,
                44.141,
                44.147,
                44.153,
                44.16,
                44.166,
                44.173,
                44.179,
                44.185,
                44.192,
                44.198,
                44.204,
                44.21,
                44.216,
                44.223,
                44.229,
                44.235,
                44.241,
                44.247,
                44.254,
                44.26,
                44.266,
                44.272,
                44.278,
                44.285,
                44.29,
                44.296,
                44.302,
                44.308,
                44.315,
                44.321,
                44.327,
                44.332,
                44.338,
                44.344,
                44.351,
                44.356,
                44.362,
                44.368,
                44.374,
                44.38,
                44.386,
                44.392,
                44.397,
                44.403,
                44.41,
                44.415,
                44.421,
                44.427,
                44.432,
                44.438,
                44.444,
                44.45,
                44.455,
                44.461,
                44.467,
                44.473,
                44.479,
                44.484,
                44.49,
                44.495,
                44.501,
                44.507,
                44.513,
                44.518,
                44.524,
                44.529,
                44.535,
                44.541,
                44.546,
                44.552,
                44.557,
                44.563,
                44.569,
                44.574,
                44.579,
                44.585,
                44.59,
                44.596,
                44.602,
                44.607,
                44.612,
                44.618,
                44.623,
                44.629,
                44.634,
                44.64,
                44.645,
                44.65,
                44.656,
                44.662,
                44.667,
                44.672,
                44.677,
                44.683,
                44.689,
                44.694,
                44.699,
                44.704,
                44.71,
                44.715,
                44.721,
                44.726,
                44.731,
                44.736,
                44.741,
                44.747,
                44.752,
                44.757,
                44.763,
                44.768,
                44.773,
                44.779,
                44.784,
                44.789,
                44.794,
                44.799,
                44.804,
                44.81,
                44.815,
                44.82,
                44.825,
                44.83,
                44.835,
                44.841,
                44.846,
                44.851,
                44.856,
                44.861,
                44.866,
                44.872,
                44.877,
                44.882,
                44.887,
                44.892,
                44.897,
                44.902,
                44.907,
                44.912,
                44.917,
                44.922,
                44.927,
                44.933,
                44.938,
                44.943,
                44.948,
                44.952,
                44.957,
                44.963,
                44.968,
                44.973,
                44.978,
                44.983,
                44.987,
                44.993,
                44.998,
                45.003,
                45.007,
                45.012,
                45.017,
                45.022,
                45.027,
                45.032,
                45.037,
                45.042,
                45.047,
                45.052,
                45.057,
                45.062,
                45.067,
                45.071,
                45.076,
                45.081,
                45.086,
                45.091,
                45.096,
                45.101,
                45.105,
                45.11,
                45.115,
                45.12,
                45.125,
                45.13,
                45.134,
                45.139,
                45.144,
                45.149,
                45.154,
                45.158,
                45.163,
                45.168,
                45.173,
                45.178,
                45.183,
                45.187,
                45.192,
                45.197,
                45.201,
                45.206,
                45.211,
                45.216,
                45.22,
                45.225,
                45.23,
                45.234,
                45.239,
                45.244,
                45.249,
                45.253,
                45.258,
                45.262,
                45.267,
                45.272,
                45.277,
                45.281,
                45.286,
                45.29,
                45.295,
                45.3,
                45.305,
                45.309,
                45.314,
                45.318,
                45.323,
                45.327,
                45.332,
                45.337,
                45.342,
                45.346,
                45.35,
                45.355,
                45.36,
                45.365,
                45.369,
                45.373,
                45.378,
                45.382,
                45.387,
                45.391,
                45.396,
                45.401,
                45.405,
                45.41,
                45.414,
                45.419,
                45.423,
                45.428,
                45.432,
                45.437,
                45.441,
                45.446,
                45.45,
                45.455,
                45.459,
                45.464,
                45.468,
                45.472,
                45.477,
                45.481,
                45.486,
                45.49,
                45.495,
                45.499,
                45.503,
                45.508,
                45.512,
                45.517,
                45.521,
                45.526,
                45.53,
                45.534,
                45.538,
                45.543,
                45.548,
                45.552,
                45.556,
                45.56,
                45.565,
                45.569,
                45.573,
                45.577,
                45.582,
                45.586,
                45.591,
                45.595,
                45.599,
                45.603,
                45.607,
                45.612,
                45.616,
                45.621,
                45.625,
                45.629,
                45.633,
                45.637,
                45.642,
                45.646,
                45.65,
                45.655,
                45.659,
                45.663,
                45.667,
                45.672,
                45.676,
                45.68,
                45.684,
                45.688,
                45.692,
                45.696,
                45.701,
                45.705,
                45.709,
                45.713,
                45.717,
                45.721,
                45.725,
                45.729,
                45.734,
                45.738,
                45.742,
                45.746,
                45.75,
                45.754,
                45.758,
                45.763,
                45.767,
                45.771,
                45.775,
                45.779,
                45.783,
                45.787,
                45.791,
                45.795,
                45.799,
                45.803,
                45.807,
                45.811,
                45.815,
                45.819,
                45.823,
                45.827,
                45.831,
                45.835,
                45.839,
                45.843,
                45.847,
                45.851,
                45.855,
                45.859,
                45.863,
                45.867,
                45.87,
                45.874,
                45.878,
                45.883,
                45.886,
                45.89,
                45.894,
                45.898,
                45.902,
                45.906,
                45.909,
                45.914,
                45.918,
                45.921,
                45.925,
                45.929,
                45.933,
                45.936,
                45.94,
                45.944,
                45.948,
                45.952,
                45.956,
                45.959,
                45.963,
                45.967,
                45.971,
                45.975,
                45.978,
                45.982,
                45.986,
                45.99,
                45.993,
                45.997,
                46.001,
                46.005,
                46.009,
                46.012,
                46.016,
                46.019,
                46.023,
                46.027,
                46.03,
                46.035,
                46.038,
                46.042,
                46.045,
                46.049,
                46.052,
                46.056,
                46.06,
                46.064,
                46.067,
                46.071,
                46.075,
                46.078,
                46.082,
                46.085,
                46.089,
                46.093,
                46.096,
                46.1,
                46.103,
                46.107,
                46.11,
                46.114,
                46.117,
                46.121,
                46.125,
                46.128,
                46.132,
                46.135,
                46.139,
                46.142,
                46.146,
                46.15,
                46.153,
                46.157,
                46.16,
                46.163,
                46.167,
                46.17,
                46.174,
                46.178,
                46.181,
                46.184,
                46.188,
                46.191,
                46.195,
                46.198,
                46.201,
                46.205,
                46.209,
                46.212,
                46.215,
                46.219,
                46.222,
                46.225,
                46.229,
                46.232,
                46.236,
                46.239,
                46.242,
                46.246,
                46.249,
                46.252,
                46.256,
                46.259,
                46.263,
                46.266,
                46.269,
                46.273,
                46.276,
                46.279,
                46.282,
                46.286,
                46.289,
                46.293,
                46.296,
                46.299,
                46.302,
                46.305,
                46.309,
                46.312,
                46.315,
                46.319,
                46.322,
                46.325,
                46.328,
                46.332,
                46.335,
                46.338,
                46.341,
                46.345,
                46.348,
                46.351,
                46.354,
                46.357,
                46.36,
                46.364,
                46.367,
                46.37,
                46.374,
                46.377,
                46.38,
                46.383,
                46.386,
                46.389,
                46.392,
                46.395,
                46.398,
                46.402,
                46.405,
                46.408,
                46.411,
                46.414,
                46.417,
                46.42,
                46.423,
                46.426,
                46.43,
                46.433,
                46.436,
                46.439,
                46.442,
                46.445,
                46.448,
                46.451,
                46.455,
                46.458,
                46.461,
                46.464,
                46.467,
                46.47,
                46.473,
                46.476,
                46.479,
                46.482,
                46.485,
                46.488,
                46.491,
                46.494,
                46.497,
                46.5,
                46.503,
                46.506,
                46.509,
                46.512,
                46.515,
                46.518,
                46.521,
                46.524,
                46.527,
                46.529,
                46.532,
                46.536,
                46.539,
                46.542,
                46.545,
                46.547,
                46.55,
                46.553,
                46.556,
                46.559,
                46.562,
                46.565,
                46.568,
                46.571,
                46.574,
                46.576,
                46.579,
                46.582,
                46.585,
                46.588,
                46.591,
                46.594,
                46.597,
                46.599,
                46.602,
                46.605,
                46.608,
                46.611,
                46.613,
                46.617,
                46.62,
                46.622,
                46.625,
                46.628,
                46.631,
                46.633,
                46.636,
                46.639,
                46.642,
                46.645,
                46.648,
                46.65,
                46.653,
                46.656,
                46.659,
                46.661,
                46.664,
                46.667,
                46.67,
                46.673,
                46.676,
                46.678,
                46.681,
                46.684,
                46.686,
                46.689,
                46.692,
                46.695,
                46.698,
                46.7,
                46.703,
                46.706,
                46.708,
                46.711,
                46.714,
                46.716,
                46.719,
                46.722,
                46.725,
                46.727,
                46.73,
                46.733,
                46.735,
                46.738,
                46.741,
                46.744,
                46.746,
                46.749,
                46.752,
                46.754,
                46.757,
                46.759,
                46.762,
                46.765,
                46.767,
                46.77,
                46.773,
                46.775,
                46.778,
                46.781,
                46.783,
                46.786,
                46.788,
                46.791,
                46.793,
                46.797,
                46.799,
                46.802,
                46.804,
                46.807,
                46.809,
                46.812,
                46.814,
                46.817,
                46.82,
                46.822,
                46.825,
                46.828,
                46.83,
                46.833,
                46.835,
                46.838,
                46.84,
                46.842,
                46.846,
                46.848,
                46.851,
                46.853,
                46.856,
                46.858,
                46.861,
                46.863,
                46.865,
                46.868,
                46.871,
                46.873,
                46.876,
                46.878,
                46.881,
                46.883,
                46.886,
                46.888,
                46.891,
                46.893,
                46.896,
                46.898,
                46.901,
                46.903,
                46.906,
                46.908,
                46.91,
                46.913,
                46.915,
                46.918,
                46.921,
                46.923,
                46.926,
                46.928,
                46.93,
                46.933,
                46.935,
                46.937,
                46.94,
                46.942,
                46.945,
                46.948,
                46.95,
                46.952,
                46.955,
                46.957,
                46.959,
                46.962,
                46.964,
                46.966,
                46.969,
                46.972,
                46.974,
                46.976,
                46.979,
                46.981,
                46.983,
                46.986,
                46.988,
                46.99,
                46.993,
                46.996,
                46.998,
                47,
                47.003,
                47.005,
                47.007,
                47.009,
                47.012,
                47.014,
                47.017,
                47.019,
                47.022,
                47.024,
                47.026,
                47.028,
                47.031,
                47.033,
                47.035,
                47.037,
                47.04,
                47.043,
                47.045,
                47.047,
                47.049,
                47.052,
                47.054,
                47.056,
                47.058,
                47.06,
                47.063,
                47.066,
                47.068,
                47.07,
                47.072,
                47.074,
                47.077,
                47.079,
                47.081,
                47.083,
                47.086,
                47.088,
                47.091,
                47.093,
                47.095,
                47.097,
                47.099,
                47.102,
                47.104,
                47.106,
                47.108,
                47.11,
                47.113,
                47.115,
                47.118,
                47.12,
                47.122,
                47.124,
                47.126,
                47.128,
                47.131,
                47.133,
                47.135,
                47.138,
                47.14,
                47.142,
                47.144,
                47.146,
                47.148,
                47.15,
                47.153,
                47.155,
                47.157,
                47.16,
                47.162,
                47.164,
                47.166,
                47.168,
                47.17,
                47.172,
                47.174,
                47.176,
                47.179,
                47.181,
                47.183,
                47.186,
                47.188,
                47.19,
                47.192,
                47.194,
                47.196,
                47.198,
                47.2,
                47.202,
                47.204,
                47.207,
                47.209,
                47.211,
                47.213,
                47.215,
                47.217,
                47.219,
                47.222,
                47.224,
                47.226,
                47.228,
                47.23,
                47.232,
                47.234,
                47.237,
                47.239,
                47.241,
                47.243,
                47.245,
                47.247,
                47.249,
                47.251,
                47.253,
                47.255,
                47.257,
                47.259,
                47.262,
                47.264,
                47.266,
                47.267,
                47.27,
                47.272,
                47.274,
                47.276,
                47.278,
                47.28,
                47.282,
                47.284,
                47.286,
                47.288,
                47.29,
                47.292,
                47.294,
                47.296,
                47.299,
                47.301,
                47.303,
                47.305,
                47.307,
                47.309,
                47.311,
                47.313,
                47.314,
                47.316,
                47.318,
                47.321,
                47.323,
                47.325,
                47.327,
                47.329,
                47.331,
                47.333,
                47.335,
                47.337,
                47.339,
                47.34,
                47.343,
                47.345,
                47.347,
                47.349,
                47.351,
                47.353,
                47.355,
                47.357,
                47.359,
                47.36,
                47.362,
                47.364,
                47.367,
                47.369,
                47.371,
                47.373,
                47.374,
                47.376,
                47.378,
                47.38,
                47.382,
                47.384,
                47.386,
                47.388,
                47.39,
                47.392,
                47.394,
                47.396,
                47.398,
                47.4,
                47.402,
                47.403,
                47.405,
                47.407,
                47.409,
                47.412,
                47.413,
                47.415,
                47.417,
                47.419,
                47.421,
                47.423,
                47.425,
                47.426,
                47.428,
                47.43,
                47.433,
                47.435,
                47.436,
                47.438,
                47.44,
                47.442,
                47.444,
                47.446,
                47.447,
                47.449,
                47.451,
                47.453,
                47.455,
                47.457,
                47.459,
                47.461,
                47.463,
                47.465,
                47.466,
                47.468,
                47.47,
                47.472,
                47.474,
                47.475,
                47.478,
                47.48,
                47.482,
                47.483,
                47.485,
                47.487,
                47.489,
                47.49,
                47.492,
                47.494,
                47.496,
                47.498,
                47.5,
                47.502,
                47.504,
                47.505,
                47.507,
                47.509,
                47.511,
                47.513,
                47.514,
                47.516,
                47.518,
                47.52,
                47.522,
                47.524,
                47.526,
                47.527,
                47.529,
                47.531,
                47.533,
                47.534,
                47.536,
                47.538,
                47.54,
                47.542,
                47.544,
                47.546,
                47.547,
                47.549,
                47.551,
                47.553,
                47.554,
                47.556,
                47.558,
                47.56,
                47.561,
                47.564,
                47.566,
                47.567,
                47.569,
                47.571,
                47.572,
                47.574,
                47.576,
                47.578,
                47.579,
                47.581,
                47.583,
                47.585,
                47.587,
                47.589,
                47.59,
                47.592,
                47.594,
                47.595,
                47.597,
                47.599,
                47.601,
                47.602,
                47.604,
                47.606,
                47.608,
                47.61,
                47.611,
                47.613,
                47.615,
                47.617,
                47.618,
                47.62,
                47.622,
                47.623,
                47.625,
                47.627,
                47.629,
                47.631,
                47.632,
                47.634,
                47.636,
                47.637,
                47.639,
                47.641,
                47.643,
                47.644,
                47.646,
                47.648,
                47.65,
                47.652,
                47.653,
                47.655,
                47.657,
                47.658,
                47.66,
                47.661,
                47.663,
                47.665,
                47.666,
                47.668,
                47.671,
                47.672,
                47.674,
                47.675,
                47.677,
                47.679,
                47.68,
                47.682,
                47.684,
                47.685,
                47.687,
                47.689,
                47.69,
                47.693,
                47.694,
                47.696,
                47.697,
                47.699,
                47.701,
                47.702,
                47.704,
                47.706,
                47.707,
                47.709,
                47.711,
                47.713,
                47.714,
                47.716,
                47.718,
                47.719,
                47.721,
                47.723,
                47.724,
                47.726,
                47.727,
                47.729,
                47.731,
                47.732,
                47.734,
                47.736,
                47.738,
                47.739,
                47.741,
                47.743,
                47.744,
                47.746,
                47.747,
                47.749,
                47.751,
                47.752,
                47.754,
                47.756,
                47.758,
                47.759,
                47.761,
                47.762,
                47.764,
                47.766,
                47.767,
                47.769,
                47.77,
                47.772,
                47.773,
                47.775,
                47.777,
                47.779,
                47.78,
                47.782,
                47.783,
                47.785,
                47.787,
                47.788,
                47.79,
                47.791,
                47.793,
                47.794,
                47.796,
                47.798,
                47.8,
                47.801,
                47.803,
                47.805,
                47.806,
                47.808,
                47.809,
                47.811,
                47.812,
                47.814,
                47.815,
                47.817,
                47.819,
                47.821,
                47.822,
                47.824,
                47.825,
                47.827,
                47.828,
                47.83,
                47.832,
                47.833,
                47.835,
                47.836,
                47.838,
                47.84,
                47.841,
                47.843,
                47.844,
                47.846,
                47.848,
                47.849,
                47.851,
                47.852,
                47.854,
                47.855,
                47.857,
                47.858,
                47.86,
                47.862,
                47.863,
                47.865,
                47.866,
                47.868,
                47.87,
                47.871,
                47.873,
                47.874,
                47.876,
                47.877,
                47.879,
                47.881,
                47.882,
                47.884,
                47.885,
                47.887,
                47.888,
                47.89,
                47.891,
                47.893,
                47.894,
                47.896,
                47.897,
                47.899,
                47.901,
                47.902,
                47.904,
                47.905,
                47.907,
                47.908,
                47.91,
                47.911,
                47.913,
                47.914,
                47.916,
                47.917,
                47.919,
                47.92,
                47.922,
                47.924,
                47.925,
                47.927,
                47.928,
                47.93,
                47.931,
                47.933,
                47.934,
                47.936,
                47.937,
                47.939,
                47.94,
                47.942,
                47.944,
                47.945,
                47.947,
                47.948,
                47.95,
                47.951,
                47.953,
                47.954,
                47.955,
                47.957,
                47.958,
                47.96,
                47.961,
                47.963,
                47.965,
                47.966,
                47.968,
                47.969,
                47.971,
                47.972,
                47.974,
                47.975,
                47.976,
                47.978,
                47.979,
                47.981,
                47.983,
                47.984,
                47.986,
                47.987,
                47.989,
                47.99,
                47.992,
                47.993,
                47.994,
                47.996,
                47.997,
                47.999,
                48,
                48.002,
                48.004,
                48.005,
                48.007,
                48.008,
                48.009,
                48.011,
                48.012,
                48.014,
                48.015,
                48.017,
                48.018,
                48.019,
                48.021,
                48.022,
                48.024,
                48.026,
                48.027,
                48.029,
                48.03,
                48.031,
                48.033,
                48.034,
                48.036,
                48.037,
                48.039,
                48.04,
                48.041,
                48.043,
                48.045,
                48.046,
                48.048,
                48.049,
                48.05,
                48.052,
                48.053,
                48.055,
                48.056,
                48.057,
                48.059,
                48.06,
                48.062,
                48.063,
                48.065,
                48.066,
                48.068,
                48.069,
                48.071,
                48.072,
                48.074,
                48.075,
                48.076,
                48.078,
                48.079,
                48.08,
                48.082,
                48.083,
                48.085,
                48.087,
                48.088,
                48.089,
                48.091,
                48.092,
                48.094,
                48.095,
                48.096,
                48.098,
                48.099,
                48.101,
                48.102,
                48.103,
                48.105,
                48.107,
                48.108,
                48.109,
                48.111,
                48.112,
                48.114,
                48.115,
                48.116,
                48.118,
                48.119,
                48.12,
                48.122,
                48.123,
                48.125,
                48.127,
                48.128,
                48.129,
                48.131,
                48.132,
                48.133,
                48.135,
                48.136,
                48.137,
                48.139,
                48.14,
                48.142
            ],
            P15: [
                32.651,
                32.75,
                32.849,
                32.948,
                33.047,
                33.146,
                33.246,
                33.345,
                33.444,
                33.543,
                33.643,
                33.742,
                33.841,
                33.941,
                34.04,
                34.132,
                34.222,
                34.31,
                34.396,
                34.48,
                34.562,
                34.643,
                34.722,
                34.799,
                34.875,
                34.949,
                35.022,
                35.094,
                35.164,
                35.233,
                35.301,
                35.368,
                35.434,
                35.498,
                35.562,
                35.624,
                35.686,
                35.747,
                35.807,
                35.866,
                35.924,
                35.981,
                36.038,
                36.094,
                36.15,
                36.204,
                36.258,
                36.312,
                36.364,
                36.416,
                36.468,
                36.519,
                36.57,
                36.619,
                36.669,
                36.718,
                36.766,
                36.814,
                36.862,
                36.909,
                36.956,
                37.002,
                37.048,
                37.093,
                37.139,
                37.183,
                37.228,
                37.272,
                37.315,
                37.359,
                37.402,
                37.444,
                37.486,
                37.528,
                37.57,
                37.611,
                37.652,
                37.693,
                37.734,
                37.774,
                37.814,
                37.853,
                37.892,
                37.931,
                37.97,
                38.009,
                38.047,
                38.085,
                38.123,
                38.16,
                38.198,
                38.235,
                38.271,
                38.308,
                38.344,
                38.381,
                38.416,
                38.452,
                38.488,
                38.523,
                38.558,
                38.593,
                38.627,
                38.662,
                38.696,
                38.73,
                38.764,
                38.797,
                38.831,
                38.864,
                38.897,
                38.929,
                38.962,
                38.994,
                39.027,
                39.059,
                39.09,
                39.122,
                39.154,
                39.185,
                39.216,
                39.247,
                39.277,
                39.308,
                39.338,
                39.369,
                39.398,
                39.428,
                39.458,
                39.487,
                39.517,
                39.546,
                39.575,
                39.604,
                39.632,
                39.661,
                39.689,
                39.717,
                39.745,
                39.773,
                39.801,
                39.828,
                39.856,
                39.883,
                39.91,
                39.937,
                39.964,
                39.99,
                40.017,
                40.043,
                40.069,
                40.095,
                40.121,
                40.147,
                40.173,
                40.198,
                40.223,
                40.249,
                40.274,
                40.299,
                40.323,
                40.348,
                40.372,
                40.397,
                40.421,
                40.445,
                40.469,
                40.493,
                40.517,
                40.54,
                40.564,
                40.587,
                40.61,
                40.633,
                40.656,
                40.679,
                40.702,
                40.724,
                40.747,
                40.769,
                40.791,
                40.814,
                40.835,
                40.857,
                40.879,
                40.901,
                40.922,
                40.944,
                40.965,
                40.986,
                41.007,
                41.028,
                41.049,
                41.07,
                41.091,
                41.111,
                41.131,
                41.152,
                41.172,
                41.192,
                41.212,
                41.232,
                41.252,
                41.271,
                41.291,
                41.311,
                41.33,
                41.349,
                41.368,
                41.388,
                41.407,
                41.426,
                41.444,
                41.463,
                41.482,
                41.5,
                41.519,
                41.537,
                41.555,
                41.573,
                41.591,
                41.609,
                41.627,
                41.645,
                41.663,
                41.68,
                41.698,
                41.715,
                41.732,
                41.749,
                41.767,
                41.784,
                41.801,
                41.818,
                41.834,
                41.851,
                41.868,
                41.884,
                41.901,
                41.917,
                41.934,
                41.95,
                41.966,
                41.982,
                41.998,
                42.014,
                42.03,
                42.046,
                42.061,
                42.077,
                42.093,
                42.108,
                42.124,
                42.139,
                42.154,
                42.169,
                42.184,
                42.199,
                42.214,
                42.229,
                42.244,
                42.259,
                42.273,
                42.288,
                42.303,
                42.317,
                42.331,
                42.346,
                42.36,
                42.374,
                42.388,
                42.402,
                42.416,
                42.43,
                42.444,
                42.458,
                42.472,
                42.485,
                42.499,
                42.512,
                42.526,
                42.539,
                42.553,
                42.566,
                42.579,
                42.593,
                42.606,
                42.618,
                42.631,
                42.645,
                42.657,
                42.67,
                42.683,
                42.696,
                42.708,
                42.721,
                42.734,
                42.746,
                42.759,
                42.771,
                42.783,
                42.795,
                42.808,
                42.82,
                42.832,
                42.844,
                42.856,
                42.868,
                42.88,
                42.892,
                42.904,
                42.915,
                42.927,
                42.939,
                42.951,
                42.962,
                42.974,
                42.985,
                42.996,
                43.008,
                43.019,
                43.031,
                43.042,
                43.053,
                43.064,
                43.075,
                43.087,
                43.097,
                43.108,
                43.119,
                43.13,
                43.141,
                43.152,
                43.163,
                43.173,
                43.184,
                43.194,
                43.205,
                43.216,
                43.226,
                43.236,
                43.247,
                43.258,
                43.268,
                43.278,
                43.289,
                43.299,
                43.309,
                43.319,
                43.329,
                43.339,
                43.349,
                43.359,
                43.369,
                43.379,
                43.389,
                43.399,
                43.408,
                43.418,
                43.428,
                43.438,
                43.447,
                43.457,
                43.467,
                43.476,
                43.486,
                43.495,
                43.505,
                43.514,
                43.523,
                43.533,
                43.542,
                43.551,
                43.56,
                43.569,
                43.579,
                43.588,
                43.597,
                43.606,
                43.615,
                43.624,
                43.633,
                43.642,
                43.651,
                43.66,
                43.669,
                43.677,
                43.687,
                43.695,
                43.704,
                43.712,
                43.721,
                43.73,
                43.739,
                43.747,
                43.756,
                43.764,
                43.773,
                43.781,
                43.789,
                43.798,
                43.806,
                43.815,
                43.823,
                43.831,
                43.84,
                43.848,
                43.856,
                43.865,
                43.873,
                43.881,
                43.889,
                43.897,
                43.905,
                43.913,
                43.921,
                43.929,
                43.937,
                43.945,
                43.953,
                43.96,
                43.969,
                43.976,
                43.984,
                43.992,
                43.999,
                44.007,
                44.015,
                44.023,
                44.03,
                44.038,
                44.046,
                44.053,
                44.061,
                44.069,
                44.076,
                44.083,
                44.091,
                44.098,
                44.106,
                44.113,
                44.121,
                44.128,
                44.136,
                44.143,
                44.15,
                44.157,
                44.164,
                44.172,
                44.179,
                44.186,
                44.193,
                44.2,
                44.208,
                44.215,
                44.222,
                44.229,
                44.236,
                44.243,
                44.25,
                44.257,
                44.264,
                44.271,
                44.278,
                44.285,
                44.292,
                44.298,
                44.306,
                44.312,
                44.319,
                44.326,
                44.333,
                44.34,
                44.346,
                44.353,
                44.36,
                44.367,
                44.373,
                44.38,
                44.387,
                44.393,
                44.4,
                44.407,
                44.413,
                44.42,
                44.426,
                44.433,
                44.439,
                44.446,
                44.452,
                44.459,
                44.465,
                44.472,
                44.478,
                44.484,
                44.491,
                44.498,
                44.504,
                44.51,
                44.516,
                44.523,
                44.529,
                44.535,
                44.542,
                44.548,
                44.554,
                44.561,
                44.567,
                44.573,
                44.579,
                44.585,
                44.592,
                44.598,
                44.604,
                44.61,
                44.616,
                44.622,
                44.628,
                44.634,
                44.64,
                44.646,
                44.653,
                44.659,
                44.665,
                44.671,
                44.676,
                44.682,
                44.689,
                44.695,
                44.7,
                44.706,
                44.712,
                44.718,
                44.724,
                44.73,
                44.736,
                44.742,
                44.748,
                44.754,
                44.759,
                44.765,
                44.771,
                44.777,
                44.783,
                44.788,
                44.794,
                44.8,
                44.805,
                44.811,
                44.817,
                44.823,
                44.828,
                44.834,
                44.84,
                44.846,
                44.851,
                44.857,
                44.862,
                44.868,
                44.874,
                44.88,
                44.885,
                44.891,
                44.896,
                44.902,
                44.908,
                44.913,
                44.918,
                44.924,
                44.929,
                44.935,
                44.941,
                44.946,
                44.952,
                44.957,
                44.962,
                44.968,
                44.974,
                44.979,
                44.984,
                44.99,
                44.996,
                45.001,
                45.006,
                45.012,
                45.017,
                45.022,
                45.028,
                45.033,
                45.039,
                45.044,
                45.049,
                45.054,
                45.06,
                45.065,
                45.071,
                45.076,
                45.081,
                45.087,
                45.092,
                45.097,
                45.102,
                45.108,
                45.113,
                45.118,
                45.124,
                45.129,
                45.134,
                45.139,
                45.144,
                45.15,
                45.155,
                45.16,
                45.165,
                45.17,
                45.175,
                45.181,
                45.186,
                45.191,
                45.196,
                45.201,
                45.206,
                45.212,
                45.217,
                45.222,
                45.227,
                45.232,
                45.237,
                45.243,
                45.248,
                45.253,
                45.258,
                45.263,
                45.268,
                45.273,
                45.278,
                45.283,
                45.288,
                45.293,
                45.298,
                45.303,
                45.308,
                45.313,
                45.318,
                45.323,
                45.328,
                45.333,
                45.338,
                45.343,
                45.348,
                45.353,
                45.358,
                45.363,
                45.368,
                45.373,
                45.378,
                45.383,
                45.388,
                45.392,
                45.398,
                45.403,
                45.407,
                45.412,
                45.417,
                45.422,
                45.427,
                45.432,
                45.437,
                45.441,
                45.446,
                45.451,
                45.456,
                45.461,
                45.466,
                45.471,
                45.475,
                45.48,
                45.485,
                45.49,
                45.495,
                45.5,
                45.504,
                45.509,
                45.514,
                45.519,
                45.524,
                45.528,
                45.533,
                45.538,
                45.543,
                45.547,
                45.552,
                45.557,
                45.562,
                45.566,
                45.571,
                45.576,
                45.581,
                45.586,
                45.59,
                45.595,
                45.599,
                45.604,
                45.609,
                45.614,
                45.618,
                45.623,
                45.628,
                45.632,
                45.637,
                45.641,
                45.646,
                45.651,
                45.656,
                45.66,
                45.665,
                45.669,
                45.674,
                45.679,
                45.683,
                45.688,
                45.692,
                45.697,
                45.701,
                45.706,
                45.711,
                45.715,
                45.72,
                45.724,
                45.729,
                45.733,
                45.738,
                45.743,
                45.747,
                45.752,
                45.756,
                45.761,
                45.765,
                45.77,
                45.775,
                45.779,
                45.783,
                45.788,
                45.792,
                45.797,
                45.802,
                45.806,
                45.81,
                45.815,
                45.819,
                45.824,
                45.828,
                45.833,
                45.837,
                45.841,
                45.846,
                45.85,
                45.855,
                45.859,
                45.864,
                45.868,
                45.872,
                45.877,
                45.881,
                45.885,
                45.89,
                45.894,
                45.899,
                45.903,
                45.907,
                45.912,
                45.916,
                45.92,
                45.925,
                45.929,
                45.933,
                45.938,
                45.942,
                45.946,
                45.95,
                45.955,
                45.959,
                45.963,
                45.968,
                45.972,
                45.976,
                45.98,
                45.985,
                45.989,
                45.993,
                45.997,
                46.002,
                46.006,
                46.01,
                46.015,
                46.019,
                46.023,
                46.027,
                46.031,
                46.035,
                46.039,
                46.044,
                46.048,
                46.052,
                46.056,
                46.06,
                46.064,
                46.069,
                46.073,
                46.077,
                46.081,
                46.085,
                46.089,
                46.093,
                46.097,
                46.102,
                46.106,
                46.11,
                46.114,
                46.118,
                46.122,
                46.126,
                46.13,
                46.134,
                46.139,
                46.143,
                46.147,
                46.151,
                46.154,
                46.158,
                46.162,
                46.167,
                46.171,
                46.175,
                46.179,
                46.183,
                46.186,
                46.19,
                46.194,
                46.199,
                46.203,
                46.206,
                46.21,
                46.214,
                46.218,
                46.222,
                46.226,
                46.23,
                46.234,
                46.238,
                46.242,
                46.246,
                46.249,
                46.253,
                46.258,
                46.261,
                46.265,
                46.269,
                46.273,
                46.277,
                46.28,
                46.284,
                46.288,
                46.292,
                46.296,
                46.3,
                46.303,
                46.307,
                46.311,
                46.315,
                46.319,
                46.323,
                46.326,
                46.33,
                46.334,
                46.337,
                46.341,
                46.345,
                46.349,
                46.353,
                46.356,
                46.36,
                46.364,
                46.367,
                46.371,
                46.375,
                46.379,
                46.382,
                46.386,
                46.39,
                46.393,
                46.397,
                46.401,
                46.405,
                46.408,
                46.412,
                46.415,
                46.419,
                46.423,
                46.426,
                46.43,
                46.433,
                46.437,
                46.441,
                46.444,
                46.448,
                46.451,
                46.455,
                46.458,
                46.462,
                46.466,
                46.47,
                46.473,
                46.476,
                46.48,
                46.483,
                46.487,
                46.49,
                46.494,
                46.498,
                46.501,
                46.505,
                46.508,
                46.512,
                46.515,
                46.518,
                46.522,
                46.526,
                46.529,
                46.533,
                46.536,
                46.539,
                46.543,
                46.546,
                46.55,
                46.553,
                46.557,
                46.56,
                46.563,
                46.567,
                46.57,
                46.574,
                46.577,
                46.581,
                46.584,
                46.587,
                46.591,
                46.594,
                46.597,
                46.601,
                46.604,
                46.608,
                46.611,
                46.614,
                46.618,
                46.621,
                46.624,
                46.627,
                46.631,
                46.634,
                46.638,
                46.641,
                46.644,
                46.647,
                46.651,
                46.654,
                46.657,
                46.66,
                46.664,
                46.667,
                46.67,
                46.674,
                46.677,
                46.68,
                46.683,
                46.686,
                46.69,
                46.693,
                46.696,
                46.7,
                46.703,
                46.706,
                46.709,
                46.712,
                46.715,
                46.719,
                46.722,
                46.725,
                46.728,
                46.731,
                46.735,
                46.738,
                46.741,
                46.744,
                46.747,
                46.75,
                46.754,
                46.757,
                46.76,
                46.763,
                46.766,
                46.769,
                46.772,
                46.775,
                46.779,
                46.782,
                46.785,
                46.788,
                46.791,
                46.794,
                46.797,
                46.8,
                46.803,
                46.806,
                46.809,
                46.812,
                46.815,
                46.818,
                46.821,
                46.824,
                46.828,
                46.831,
                46.834,
                46.837,
                46.84,
                46.843,
                46.846,
                46.848,
                46.851,
                46.855,
                46.858,
                46.861,
                46.864,
                46.867,
                46.869,
                46.872,
                46.875,
                46.878,
                46.882,
                46.885,
                46.887,
                46.89,
                46.893,
                46.896,
                46.899,
                46.902,
                46.905,
                46.908,
                46.911,
                46.914,
                46.917,
                46.92,
                46.922,
                46.925,
                46.928,
                46.931,
                46.934,
                46.937,
                46.94,
                46.943,
                46.945,
                46.948,
                46.951,
                46.954,
                46.957,
                46.96,
                46.963,
                46.966,
                46.968,
                46.971,
                46.974,
                46.977,
                46.98,
                46.982,
                46.985,
                46.988,
                46.991,
                46.994,
                46.997,
                46.999,
                47.002,
                47.005,
                47.008,
                47.01,
                47.014,
                47.016,
                47.019,
                47.022,
                47.024,
                47.027,
                47.03,
                47.033,
                47.035,
                47.038,
                47.041,
                47.044,
                47.047,
                47.049,
                47.052,
                47.055,
                47.057,
                47.06,
                47.063,
                47.066,
                47.068,
                47.071,
                47.074,
                47.076,
                47.079,
                47.082,
                47.084,
                47.087,
                47.09,
                47.093,
                47.095,
                47.098,
                47.101,
                47.103,
                47.106,
                47.108,
                47.111,
                47.114,
                47.117,
                47.119,
                47.122,
                47.125,
                47.127,
                47.13,
                47.132,
                47.135,
                47.137,
                47.14,
                47.143,
                47.146,
                47.148,
                47.151,
                47.153,
                47.156,
                47.158,
                47.161,
                47.163,
                47.166,
                47.169,
                47.172,
                47.174,
                47.177,
                47.179,
                47.182,
                47.184,
                47.187,
                47.189,
                47.192,
                47.195,
                47.197,
                47.2,
                47.202,
                47.205,
                47.207,
                47.21,
                47.212,
                47.215,
                47.218,
                47.22,
                47.223,
                47.225,
                47.228,
                47.23,
                47.232,
                47.235,
                47.237,
                47.24,
                47.243,
                47.245,
                47.248,
                47.25,
                47.252,
                47.255,
                47.257,
                47.26,
                47.262,
                47.265,
                47.268,
                47.27,
                47.272,
                47.275,
                47.277,
                47.28,
                47.282,
                47.284,
                47.287,
                47.289,
                47.292,
                47.294,
                47.297,
                47.299,
                47.302,
                47.304,
                47.306,
                47.309,
                47.311,
                47.313,
                47.316,
                47.319,
                47.321,
                47.323,
                47.326,
                47.328,
                47.33,
                47.333,
                47.335,
                47.337,
                47.34,
                47.343,
                47.345,
                47.347,
                47.35,
                47.352,
                47.354,
                47.357,
                47.359,
                47.361,
                47.364,
                47.366,
                47.369,
                47.371,
                47.373,
                47.375,
                47.378,
                47.38,
                47.382,
                47.385,
                47.387,
                47.39,
                47.392,
                47.394,
                47.396,
                47.399,
                47.401,
                47.403,
                47.405,
                47.408,
                47.41,
                47.413,
                47.415,
                47.417,
                47.419,
                47.422,
                47.424,
                47.426,
                47.428,
                47.431,
                47.433,
                47.435,
                47.438,
                47.44,
                47.442,
                47.444,
                47.447,
                47.449,
                47.451,
                47.453,
                47.455,
                47.458,
                47.46,
                47.463,
                47.465,
                47.467,
                47.469,
                47.471,
                47.474,
                47.476,
                47.478,
                47.48,
                47.482,
                47.485,
                47.487,
                47.489,
                47.491,
                47.494,
                47.496,
                47.498,
                47.5,
                47.502,
                47.504,
                47.507,
                47.509,
                47.511,
                47.513,
                47.515,
                47.518,
                47.52,
                47.522,
                47.524,
                47.526,
                47.528,
                47.531,
                47.533,
                47.535,
                47.537,
                47.539,
                47.541,
                47.543,
                47.546,
                47.548,
                47.55,
                47.552,
                47.554,
                47.557,
                47.559,
                47.561,
                47.563,
                47.565,
                47.567,
                47.569,
                47.571,
                47.573,
                47.575,
                47.578,
                47.58,
                47.582,
                47.584,
                47.586,
                47.588,
                47.59,
                47.592,
                47.594,
                47.596,
                47.598,
                47.601,
                47.603,
                47.605,
                47.607,
                47.609,
                47.611,
                47.613,
                47.615,
                47.617,
                47.619,
                47.621,
                47.624,
                47.626,
                47.628,
                47.63,
                47.632,
                47.634,
                47.636,
                47.638,
                47.64,
                47.642,
                47.644,
                47.646,
                47.648,
                47.65,
                47.652,
                47.654,
                47.656,
                47.658,
                47.66,
                47.662,
                47.664,
                47.666,
                47.669,
                47.671,
                47.673,
                47.675,
                47.676,
                47.678,
                47.68,
                47.682,
                47.684,
                47.686,
                47.688,
                47.691,
                47.693,
                47.695,
                47.697,
                47.699,
                47.7,
                47.702,
                47.704,
                47.706,
                47.708,
                47.71,
                47.712,
                47.714,
                47.716,
                47.718,
                47.72,
                47.722,
                47.724,
                47.726,
                47.728,
                47.73,
                47.732,
                47.734,
                47.736,
                47.738,
                47.74,
                47.742,
                47.744,
                47.746,
                47.747,
                47.749,
                47.751,
                47.753,
                47.755,
                47.757,
                47.759,
                47.761,
                47.763,
                47.765,
                47.767,
                47.769,
                47.771,
                47.772,
                47.774,
                47.776,
                47.778,
                47.78,
                47.782,
                47.784,
                47.786,
                47.788,
                47.79,
                47.792,
                47.793,
                47.795,
                47.797,
                47.799,
                47.801,
                47.803,
                47.805,
                47.807,
                47.809,
                47.811,
                47.812,
                47.814,
                47.816,
                47.818,
                47.82,
                47.822,
                47.823,
                47.826,
                47.828,
                47.829,
                47.831,
                47.833,
                47.835,
                47.837,
                47.838,
                47.84,
                47.842,
                47.844,
                47.846,
                47.848,
                47.85,
                47.852,
                47.853,
                47.855,
                47.857,
                47.859,
                47.861,
                47.862,
                47.864,
                47.866,
                47.868,
                47.87,
                47.872,
                47.874,
                47.875,
                47.877,
                47.879,
                47.881,
                47.883,
                47.884,
                47.886,
                47.888,
                47.89,
                47.892,
                47.894,
                47.895,
                47.897,
                47.899,
                47.901,
                47.902,
                47.904,
                47.906,
                47.908,
                47.91,
                47.912,
                47.914,
                47.915,
                47.917,
                47.919,
                47.921,
                47.922,
                47.924,
                47.926,
                47.928,
                47.929,
                47.931,
                47.933,
                47.935,
                47.937,
                47.938,
                47.94,
                47.942,
                47.944,
                47.945,
                47.947,
                47.949,
                47.951,
                47.952,
                47.954,
                47.956,
                47.958,
                47.96,
                47.961,
                47.963,
                47.965,
                47.966,
                47.968,
                47.97,
                47.972,
                47.973,
                47.975,
                47.977,
                47.979,
                47.981,
                47.982,
                47.984,
                47.986,
                47.987,
                47.989,
                47.991,
                47.992,
                47.994,
                47.996,
                47.998,
                48,
                48.001,
                48.003,
                48.005,
                48.006,
                48.008,
                48.01,
                48.011,
                48.013,
                48.015,
                48.016,
                48.019,
                48.02,
                48.022,
                48.024,
                48.025,
                48.027,
                48.029,
                48.03,
                48.032,
                48.034,
                48.035,
                48.037,
                48.039,
                48.041,
                48.042,
                48.044,
                48.046,
                48.047,
                48.049,
                48.051,
                48.052,
                48.054,
                48.056,
                48.057,
                48.059,
                48.061,
                48.063,
                48.064,
                48.066,
                48.068,
                48.069,
                48.071,
                48.072,
                48.074,
                48.076,
                48.077,
                48.079,
                48.081,
                48.083,
                48.084,
                48.086,
                48.088,
                48.089,
                48.091,
                48.092,
                48.094,
                48.096,
                48.097,
                48.099,
                48.1,
                48.102,
                48.104,
                48.106,
                48.107,
                48.109,
                48.111,
                48.112,
                48.114,
                48.115,
                48.117,
                48.119,
                48.12,
                48.122,
                48.123,
                48.126,
                48.127,
                48.129,
                48.13,
                48.132,
                48.133,
                48.135,
                48.137,
                48.138,
                48.14,
                48.141,
                48.143,
                48.145,
                48.147,
                48.148,
                48.15,
                48.151,
                48.153,
                48.154,
                48.156,
                48.158,
                48.159,
                48.161,
                48.162,
                48.164,
                48.165,
                48.168,
                48.169,
                48.171,
                48.172,
                48.174,
                48.175,
                48.177,
                48.178,
                48.18,
                48.182,
                48.183,
                48.185,
                48.186,
                48.188,
                48.19,
                48.191,
                48.193,
                48.194,
                48.196,
                48.198,
                48.199,
                48.201,
                48.202,
                48.204,
                48.205,
                48.207,
                48.209,
                48.21,
                48.212,
                48.213,
                48.215,
                48.216,
                48.218,
                48.22,
                48.221,
                48.223,
                48.224,
                48.226,
                48.227,
                48.229,
                48.231,
                48.232,
                48.234,
                48.235,
                48.237,
                48.238,
                48.24,
                48.241,
                48.243,
                48.244,
                48.246,
                48.247,
                48.249,
                48.251,
                48.252,
                48.254,
                48.255,
                48.257,
                48.258,
                48.26,
                48.261,
                48.263,
                48.264,
                48.266,
                48.267,
                48.269,
                48.271,
                48.272,
                48.274,
                48.275,
                48.277,
                48.278,
                48.28,
                48.281,
                48.283,
                48.284,
                48.286,
                48.287,
                48.289,
                48.291,
                48.292,
                48.294,
                48.295,
                48.297,
                48.298,
                48.3,
                48.301,
                48.303,
                48.304,
                48.305,
                48.307,
                48.308,
                48.31,
                48.312,
                48.313,
                48.315,
                48.316,
                48.318,
                48.319,
                48.321,
                48.322,
                48.324,
                48.325,
                48.327,
                48.328,
                48.329,
                48.331,
                48.333,
                48.334,
                48.336,
                48.337,
                48.339,
                48.34,
                48.342,
                48.343,
                48.345,
                48.346,
                48.347,
                48.349,
                48.35,
                48.352,
                48.354,
                48.355,
                48.357,
                48.358,
                48.359,
                48.361,
                48.362,
                48.364,
                48.365,
                48.367,
                48.368,
                48.37,
                48.371,
                48.373,
                48.374,
                48.376,
                48.377,
                48.379,
                48.38,
                48.381,
                48.383,
                48.384,
                48.386,
                48.387,
                48.389,
                48.39,
                48.391,
                48.393,
                48.395,
                48.396,
                48.398,
                48.399,
                48.401,
                48.402,
                48.403,
                48.405,
                48.406,
                48.408,
                48.409,
                48.41,
                48.412,
                48.414,
                48.415,
                48.417,
                48.418,
                48.419,
                48.421,
                48.422,
                48.424,
                48.425,
                48.426,
                48.428,
                48.429,
                48.431,
                48.432,
                48.434,
                48.435,
                48.437,
                48.438,
                48.44,
                48.441,
                48.442,
                48.444,
                48.445,
                48.447,
                48.448,
                48.449,
                48.451,
                48.452,
                48.454,
                48.455,
                48.457,
                48.458,
                48.46,
                48.461,
                48.462,
                48.464,
                48.465,
                48.466,
                48.468,
                48.469,
                48.471,
                48.472,
                48.474,
                48.475,
                48.477,
                48.478,
                48.479,
                48.481,
                48.482,
                48.484,
                48.485,
                48.486,
                48.488,
                48.489,
                48.49
            ],
            P25: [
                33.08,
                33.178,
                33.276,
                33.374,
                33.472,
                33.57,
                33.669,
                33.767,
                33.865,
                33.963,
                34.061,
                34.16,
                34.258,
                34.356,
                34.455,
                34.547,
                34.638,
                34.726,
                34.813,
                34.898,
                34.981,
                35.062,
                35.142,
                35.219,
                35.296,
                35.371,
                35.444,
                35.516,
                35.587,
                35.657,
                35.725,
                35.793,
                35.859,
                35.924,
                35.988,
                36.051,
                36.114,
                36.175,
                36.235,
                36.295,
                36.354,
                36.412,
                36.469,
                36.525,
                36.581,
                36.636,
                36.691,
                36.745,
                36.798,
                36.85,
                36.902,
                36.954,
                37.005,
                37.055,
                37.105,
                37.154,
                37.203,
                37.251,
                37.299,
                37.347,
                37.394,
                37.441,
                37.487,
                37.533,
                37.578,
                37.624,
                37.668,
                37.713,
                37.757,
                37.8,
                37.844,
                37.886,
                37.929,
                37.971,
                38.013,
                38.055,
                38.097,
                38.138,
                38.178,
                38.219,
                38.259,
                38.299,
                38.339,
                38.378,
                38.417,
                38.456,
                38.495,
                38.533,
                38.571,
                38.609,
                38.647,
                38.684,
                38.721,
                38.758,
                38.795,
                38.831,
                38.867,
                38.903,
                38.939,
                38.975,
                39.01,
                39.045,
                39.08,
                39.115,
                39.149,
                39.183,
                39.217,
                39.251,
                39.285,
                39.318,
                39.352,
                39.385,
                39.418,
                39.45,
                39.483,
                39.515,
                39.547,
                39.579,
                39.611,
                39.642,
                39.674,
                39.705,
                39.736,
                39.767,
                39.797,
                39.828,
                39.858,
                39.888,
                39.918,
                39.947,
                39.977,
                40.006,
                40.036,
                40.065,
                40.094,
                40.122,
                40.151,
                40.179,
                40.208,
                40.236,
                40.264,
                40.291,
                40.319,
                40.346,
                40.374,
                40.401,
                40.428,
                40.455,
                40.481,
                40.508,
                40.534,
                40.56,
                40.587,
                40.613,
                40.638,
                40.664,
                40.69,
                40.715,
                40.74,
                40.765,
                40.79,
                40.815,
                40.84,
                40.864,
                40.889,
                40.913,
                40.937,
                40.961,
                40.985,
                41.009,
                41.033,
                41.056,
                41.08,
                41.103,
                41.126,
                41.149,
                41.172,
                41.195,
                41.218,
                41.24,
                41.262,
                41.285,
                41.307,
                41.329,
                41.351,
                41.373,
                41.394,
                41.416,
                41.438,
                41.459,
                41.48,
                41.501,
                41.522,
                41.543,
                41.564,
                41.585,
                41.605,
                41.626,
                41.646,
                41.667,
                41.687,
                41.707,
                41.727,
                41.747,
                41.766,
                41.786,
                41.806,
                41.825,
                41.844,
                41.864,
                41.883,
                41.902,
                41.921,
                41.94,
                41.959,
                41.977,
                41.996,
                42.014,
                42.032,
                42.051,
                42.069,
                42.087,
                42.105,
                42.123,
                42.141,
                42.159,
                42.176,
                42.194,
                42.211,
                42.228,
                42.246,
                42.263,
                42.28,
                42.297,
                42.314,
                42.331,
                42.348,
                42.364,
                42.381,
                42.398,
                42.414,
                42.431,
                42.447,
                42.463,
                42.479,
                42.495,
                42.511,
                42.527,
                42.543,
                42.559,
                42.574,
                42.59,
                42.605,
                42.621,
                42.636,
                42.651,
                42.667,
                42.682,
                42.697,
                42.712,
                42.727,
                42.742,
                42.756,
                42.771,
                42.786,
                42.8,
                42.815,
                42.829,
                42.844,
                42.858,
                42.872,
                42.886,
                42.9,
                42.914,
                42.928,
                42.942,
                42.956,
                42.97,
                42.983,
                42.997,
                43.011,
                43.024,
                43.038,
                43.051,
                43.064,
                43.078,
                43.091,
                43.104,
                43.117,
                43.13,
                43.143,
                43.156,
                43.169,
                43.182,
                43.195,
                43.208,
                43.22,
                43.233,
                43.245,
                43.258,
                43.27,
                43.282,
                43.295,
                43.307,
                43.319,
                43.332,
                43.344,
                43.356,
                43.368,
                43.38,
                43.392,
                43.403,
                43.415,
                43.427,
                43.439,
                43.451,
                43.462,
                43.474,
                43.485,
                43.497,
                43.508,
                43.519,
                43.531,
                43.542,
                43.553,
                43.564,
                43.576,
                43.587,
                43.598,
                43.609,
                43.62,
                43.631,
                43.641,
                43.652,
                43.663,
                43.674,
                43.684,
                43.695,
                43.706,
                43.716,
                43.727,
                43.737,
                43.748,
                43.758,
                43.769,
                43.779,
                43.789,
                43.799,
                43.81,
                43.82,
                43.83,
                43.84,
                43.85,
                43.86,
                43.87,
                43.88,
                43.89,
                43.9,
                43.91,
                43.92,
                43.929,
                43.939,
                43.949,
                43.958,
                43.968,
                43.977,
                43.987,
                43.997,
                44.006,
                44.015,
                44.025,
                44.034,
                44.044,
                44.053,
                44.062,
                44.071,
                44.081,
                44.09,
                44.099,
                44.108,
                44.117,
                44.126,
                44.135,
                44.144,
                44.153,
                44.162,
                44.171,
                44.18,
                44.188,
                44.197,
                44.206,
                44.215,
                44.223,
                44.232,
                44.241,
                44.249,
                44.258,
                44.266,
                44.275,
                44.283,
                44.292,
                44.3,
                44.309,
                44.317,
                44.326,
                44.334,
                44.342,
                44.35,
                44.359,
                44.367,
                44.375,
                44.383,
                44.392,
                44.4,
                44.408,
                44.416,
                44.423,
                44.432,
                44.44,
                44.448,
                44.455,
                44.464,
                44.471,
                44.479,
                44.487,
                44.495,
                44.503,
                44.51,
                44.518,
                44.526,
                44.534,
                44.541,
                44.549,
                44.556,
                44.564,
                44.572,
                44.579,
                44.587,
                44.594,
                44.602,
                44.609,
                44.617,
                44.624,
                44.632,
                44.639,
                44.646,
                44.653,
                44.661,
                44.668,
                44.675,
                44.682,
                44.69,
                44.697,
                44.704,
                44.711,
                44.718,
                44.725,
                44.733,
                44.74,
                44.747,
                44.754,
                44.761,
                44.768,
                44.775,
                44.782,
                44.789,
                44.796,
                44.803,
                44.81,
                44.816,
                44.823,
                44.83,
                44.837,
                44.844,
                44.85,
                44.857,
                44.864,
                44.871,
                44.877,
                44.884,
                44.891,
                44.898,
                44.904,
                44.911,
                44.917,
                44.924,
                44.931,
                44.937,
                44.944,
                44.95,
                44.957,
                44.963,
                44.97,
                44.976,
                44.983,
                44.989,
                44.996,
                45.002,
                45.008,
                45.015,
                45.021,
                45.028,
                45.034,
                45.04,
                45.046,
                45.053,
                45.059,
                45.065,
                45.072,
                45.078,
                45.084,
                45.09,
                45.097,
                45.103,
                45.109,
                45.115,
                45.121,
                45.127,
                45.133,
                45.139,
                45.145,
                45.152,
                45.158,
                45.164,
                45.17,
                45.176,
                45.182,
                45.188,
                45.194,
                45.2,
                45.206,
                45.212,
                45.218,
                45.224,
                45.23,
                45.235,
                45.241,
                45.247,
                45.253,
                45.259,
                45.265,
                45.271,
                45.276,
                45.283,
                45.288,
                45.294,
                45.3,
                45.305,
                45.311,
                45.317,
                45.323,
                45.329,
                45.334,
                45.34,
                45.346,
                45.352,
                45.357,
                45.363,
                45.368,
                45.374,
                45.38,
                45.385,
                45.391,
                45.397,
                45.402,
                45.408,
                45.413,
                45.419,
                45.425,
                45.43,
                45.436,
                45.441,
                45.447,
                45.452,
                45.458,
                45.463,
                45.469,
                45.474,
                45.48,
                45.485,
                45.491,
                45.497,
                45.502,
                45.507,
                45.513,
                45.518,
                45.523,
                45.529,
                45.534,
                45.54,
                45.545,
                45.55,
                45.556,
                45.561,
                45.567,
                45.572,
                45.577,
                45.583,
                45.588,
                45.593,
                45.599,
                45.604,
                45.609,
                45.614,
                45.62,
                45.625,
                45.63,
                45.636,
                45.641,
                45.646,
                45.652,
                45.657,
                45.662,
                45.667,
                45.672,
                45.677,
                45.683,
                45.688,
                45.693,
                45.698,
                45.703,
                45.709,
                45.714,
                45.719,
                45.724,
                45.729,
                45.734,
                45.739,
                45.745,
                45.75,
                45.755,
                45.76,
                45.765,
                45.77,
                45.776,
                45.781,
                45.786,
                45.791,
                45.796,
                45.801,
                45.806,
                45.811,
                45.816,
                45.821,
                45.826,
                45.831,
                45.836,
                45.841,
                45.846,
                45.851,
                45.856,
                45.861,
                45.866,
                45.871,
                45.876,
                45.881,
                45.886,
                45.891,
                45.896,
                45.901,
                45.906,
                45.911,
                45.916,
                45.92,
                45.925,
                45.93,
                45.935,
                45.94,
                45.945,
                45.95,
                45.955,
                45.96,
                45.965,
                45.969,
                45.974,
                45.979,
                45.984,
                45.989,
                45.994,
                45.999,
                46.003,
                46.008,
                46.013,
                46.018,
                46.023,
                46.028,
                46.032,
                46.037,
                46.042,
                46.047,
                46.051,
                46.056,
                46.061,
                46.066,
                46.071,
                46.075,
                46.08,
                46.085,
                46.09,
                46.094,
                46.099,
                46.104,
                46.108,
                46.113,
                46.118,
                46.123,
                46.127,
                46.132,
                46.137,
                46.141,
                46.146,
                46.151,
                46.156,
                46.16,
                46.165,
                46.169,
                46.174,
                46.179,
                46.183,
                46.188,
                46.193,
                46.197,
                46.202,
                46.206,
                46.211,
                46.216,
                46.22,
                46.225,
                46.229,
                46.234,
                46.239,
                46.243,
                46.248,
                46.252,
                46.257,
                46.261,
                46.266,
                46.27,
                46.275,
                46.28,
                46.284,
                46.289,
                46.293,
                46.298,
                46.302,
                46.307,
                46.311,
                46.316,
                46.32,
                46.325,
                46.329,
                46.334,
                46.338,
                46.343,
                46.347,
                46.351,
                46.356,
                46.36,
                46.365,
                46.369,
                46.374,
                46.378,
                46.382,
                46.387,
                46.391,
                46.396,
                46.4,
                46.405,
                46.409,
                46.413,
                46.418,
                46.422,
                46.426,
                46.431,
                46.435,
                46.439,
                46.444,
                46.448,
                46.452,
                46.457,
                46.461,
                46.465,
                46.47,
                46.474,
                46.478,
                46.482,
                46.487,
                46.491,
                46.496,
                46.5,
                46.504,
                46.508,
                46.512,
                46.517,
                46.521,
                46.525,
                46.529,
                46.534,
                46.538,
                46.542,
                46.546,
                46.551,
                46.555,
                46.559,
                46.563,
                46.567,
                46.571,
                46.575,
                46.58,
                46.584,
                46.588,
                46.592,
                46.596,
                46.6,
                46.604,
                46.609,
                46.613,
                46.617,
                46.621,
                46.625,
                46.629,
                46.633,
                46.637,
                46.641,
                46.646,
                46.65,
                46.654,
                46.658,
                46.662,
                46.666,
                46.67,
                46.674,
                46.678,
                46.682,
                46.686,
                46.69,
                46.694,
                46.698,
                46.702,
                46.706,
                46.71,
                46.714,
                46.718,
                46.722,
                46.726,
                46.73,
                46.734,
                46.738,
                46.742,
                46.746,
                46.749,
                46.753,
                46.757,
                46.761,
                46.765,
                46.769,
                46.773,
                46.777,
                46.781,
                46.784,
                46.788,
                46.792,
                46.796,
                46.8,
                46.804,
                46.808,
                46.811,
                46.815,
                46.819,
                46.823,
                46.827,
                46.831,
                46.834,
                46.838,
                46.842,
                46.846,
                46.849,
                46.853,
                46.857,
                46.861,
                46.865,
                46.868,
                46.872,
                46.876,
                46.879,
                46.883,
                46.887,
                46.891,
                46.894,
                46.898,
                46.902,
                46.905,
                46.909,
                46.913,
                46.917,
                46.92,
                46.924,
                46.928,
                46.931,
                46.935,
                46.938,
                46.942,
                46.946,
                46.949,
                46.953,
                46.957,
                46.96,
                46.964,
                46.967,
                46.971,
                46.975,
                46.978,
                46.982,
                46.985,
                46.989,
                46.992,
                46.996,
                46.999,
                47.003,
                47.007,
                47.01,
                47.014,
                47.017,
                47.021,
                47.024,
                47.028,
                47.031,
                47.035,
                47.038,
                47.042,
                47.045,
                47.049,
                47.052,
                47.055,
                47.059,
                47.063,
                47.066,
                47.069,
                47.073,
                47.076,
                47.08,
                47.083,
                47.086,
                47.09,
                47.093,
                47.097,
                47.1,
                47.103,
                47.107,
                47.11,
                47.113,
                47.117,
                47.12,
                47.124,
                47.127,
                47.13,
                47.134,
                47.137,
                47.14,
                47.144,
                47.147,
                47.15,
                47.154,
                47.157,
                47.16,
                47.164,
                47.167,
                47.17,
                47.174,
                47.177,
                47.18,
                47.183,
                47.187,
                47.19,
                47.193,
                47.196,
                47.2,
                47.203,
                47.206,
                47.209,
                47.213,
                47.216,
                47.219,
                47.222,
                47.225,
                47.229,
                47.232,
                47.235,
                47.238,
                47.241,
                47.245,
                47.248,
                47.251,
                47.254,
                47.257,
                47.261,
                47.264,
                47.267,
                47.27,
                47.273,
                47.276,
                47.279,
                47.282,
                47.286,
                47.289,
                47.292,
                47.295,
                47.298,
                47.301,
                47.304,
                47.307,
                47.31,
                47.314,
                47.317,
                47.32,
                47.323,
                47.326,
                47.329,
                47.332,
                47.335,
                47.338,
                47.341,
                47.344,
                47.347,
                47.35,
                47.353,
                47.356,
                47.359,
                47.362,
                47.365,
                47.368,
                47.371,
                47.374,
                47.377,
                47.38,
                47.383,
                47.386,
                47.389,
                47.392,
                47.395,
                47.398,
                47.401,
                47.404,
                47.407,
                47.41,
                47.413,
                47.416,
                47.419,
                47.422,
                47.425,
                47.427,
                47.43,
                47.433,
                47.436,
                47.439,
                47.442,
                47.445,
                47.448,
                47.451,
                47.454,
                47.456,
                47.459,
                47.462,
                47.465,
                47.468,
                47.471,
                47.474,
                47.477,
                47.479,
                47.482,
                47.485,
                47.488,
                47.491,
                47.493,
                47.496,
                47.499,
                47.502,
                47.505,
                47.508,
                47.51,
                47.513,
                47.516,
                47.519,
                47.522,
                47.525,
                47.527,
                47.53,
                47.533,
                47.536,
                47.538,
                47.541,
                47.544,
                47.547,
                47.55,
                47.552,
                47.555,
                47.558,
                47.561,
                47.563,
                47.566,
                47.569,
                47.571,
                47.574,
                47.577,
                47.58,
                47.582,
                47.585,
                47.588,
                47.59,
                47.593,
                47.596,
                47.599,
                47.601,
                47.604,
                47.607,
                47.609,
                47.612,
                47.615,
                47.617,
                47.62,
                47.623,
                47.625,
                47.628,
                47.631,
                47.633,
                47.636,
                47.639,
                47.641,
                47.644,
                47.647,
                47.649,
                47.652,
                47.655,
                47.657,
                47.66,
                47.662,
                47.665,
                47.668,
                47.67,
                47.673,
                47.675,
                47.678,
                47.681,
                47.683,
                47.686,
                47.688,
                47.691,
                47.694,
                47.696,
                47.699,
                47.701,
                47.704,
                47.707,
                47.709,
                47.712,
                47.714,
                47.717,
                47.719,
                47.722,
                47.724,
                47.727,
                47.73,
                47.732,
                47.735,
                47.737,
                47.74,
                47.742,
                47.745,
                47.747,
                47.749,
                47.752,
                47.755,
                47.757,
                47.76,
                47.762,
                47.765,
                47.767,
                47.769,
                47.772,
                47.774,
                47.777,
                47.78,
                47.782,
                47.784,
                47.787,
                47.789,
                47.792,
                47.794,
                47.797,
                47.799,
                47.801,
                47.804,
                47.807,
                47.809,
                47.811,
                47.814,
                47.816,
                47.819,
                47.821,
                47.823,
                47.826,
                47.829,
                47.831,
                47.833,
                47.836,
                47.838,
                47.84,
                47.843,
                47.845,
                47.847,
                47.85,
                47.853,
                47.855,
                47.857,
                47.86,
                47.862,
                47.864,
                47.867,
                47.869,
                47.871,
                47.874,
                47.876,
                47.879,
                47.881,
                47.883,
                47.886,
                47.888,
                47.89,
                47.893,
                47.895,
                47.897,
                47.899,
                47.902,
                47.904,
                47.907,
                47.909,
                47.911,
                47.914,
                47.916,
                47.918,
                47.92,
                47.923,
                47.925,
                47.928,
                47.93,
                47.932,
                47.934,
                47.937,
                47.939,
                47.941,
                47.943,
                47.946,
                47.948,
                47.95,
                47.953,
                47.955,
                47.957,
                47.959,
                47.962,
                47.964,
                47.966,
                47.968,
                47.971,
                47.973,
                47.975,
                47.978,
                47.98,
                47.982,
                47.984,
                47.986,
                47.988,
                47.991,
                47.993,
                47.995,
                47.998,
                48,
                48.002,
                48.004,
                48.006,
                48.009,
                48.011,
                48.013,
                48.015,
                48.017,
                48.02,
                48.022,
                48.024,
                48.026,
                48.028,
                48.031,
                48.033,
                48.035,
                48.037,
                48.039,
                48.041,
                48.044,
                48.046,
                48.048,
                48.05,
                48.052,
                48.054,
                48.056,
                48.059,
                48.061,
                48.063,
                48.065,
                48.067,
                48.07,
                48.072,
                48.074,
                48.076,
                48.078,
                48.08,
                48.082,
                48.084,
                48.086,
                48.088,
                48.091,
                48.093,
                48.095,
                48.097,
                48.099,
                48.101,
                48.103,
                48.105,
                48.107,
                48.11,
                48.112,
                48.114,
                48.116,
                48.118,
                48.12,
                48.122,
                48.124,
                48.126,
                48.128,
                48.13,
                48.132,
                48.135,
                48.137,
                48.139,
                48.141,
                48.143,
                48.145,
                48.147,
                48.149,
                48.151,
                48.153,
                48.155,
                48.157,
                48.159,
                48.162,
                48.164,
                48.166,
                48.168,
                48.17,
                48.172,
                48.174,
                48.176,
                48.178,
                48.18,
                48.182,
                48.184,
                48.186,
                48.188,
                48.19,
                48.192,
                48.194,
                48.196,
                48.198,
                48.2,
                48.202,
                48.204,
                48.206,
                48.208,
                48.21,
                48.212,
                48.214,
                48.216,
                48.218,
                48.22,
                48.222,
                48.224,
                48.226,
                48.228,
                48.23,
                48.232,
                48.234,
                48.236,
                48.238,
                48.24,
                48.241,
                48.243,
                48.245,
                48.247,
                48.25,
                48.251,
                48.253,
                48.255,
                48.257,
                48.259,
                48.261,
                48.263,
                48.265,
                48.267,
                48.269,
                48.271,
                48.273,
                48.275,
                48.277,
                48.279,
                48.28,
                48.282,
                48.284,
                48.286,
                48.288,
                48.29,
                48.292,
                48.294,
                48.296,
                48.298,
                48.3,
                48.302,
                48.303,
                48.305,
                48.307,
                48.309,
                48.311,
                48.313,
                48.315,
                48.317,
                48.319,
                48.321,
                48.322,
                48.324,
                48.326,
                48.328,
                48.33,
                48.332,
                48.334,
                48.335,
                48.337,
                48.339,
                48.341,
                48.343,
                48.345,
                48.347,
                48.349,
                48.351,
                48.352,
                48.354,
                48.356,
                48.358,
                48.36,
                48.362,
                48.364,
                48.365,
                48.367,
                48.369,
                48.371,
                48.373,
                48.374,
                48.376,
                48.378,
                48.38,
                48.382,
                48.384,
                48.386,
                48.387,
                48.389,
                48.391,
                48.393,
                48.395,
                48.396,
                48.398,
                48.4,
                48.402,
                48.404,
                48.406,
                48.408,
                48.409,
                48.411,
                48.413,
                48.415,
                48.416,
                48.418,
                48.42,
                48.422,
                48.424,
                48.426,
                48.427,
                48.429,
                48.431,
                48.433,
                48.435,
                48.436,
                48.438,
                48.44,
                48.442,
                48.443,
                48.445,
                48.447,
                48.449,
                48.451,
                48.452,
                48.454,
                48.456,
                48.458,
                48.459,
                48.461,
                48.463,
                48.465,
                48.466,
                48.468,
                48.47,
                48.472,
                48.474,
                48.475,
                48.477,
                48.479,
                48.481,
                48.482,
                48.484,
                48.486,
                48.487,
                48.489,
                48.491,
                48.493,
                48.495,
                48.496,
                48.498,
                48.5,
                48.502,
                48.503,
                48.505,
                48.507,
                48.508,
                48.51,
                48.512,
                48.514,
                48.515,
                48.517,
                48.519,
                48.521,
                48.522,
                48.524,
                48.526,
                48.527,
                48.529,
                48.531,
                48.533,
                48.534,
                48.536,
                48.538,
                48.539,
                48.541,
                48.543,
                48.545,
                48.546,
                48.548,
                48.55,
                48.551,
                48.553,
                48.555,
                48.557,
                48.558,
                48.56,
                48.562,
                48.563,
                48.565,
                48.567,
                48.568,
                48.57,
                48.572,
                48.573,
                48.575,
                48.577,
                48.578,
                48.58,
                48.582,
                48.583,
                48.585,
                48.587,
                48.588,
                48.59,
                48.592,
                48.593,
                48.595,
                48.597,
                48.599,
                48.6,
                48.602,
                48.603,
                48.605,
                48.607,
                48.608,
                48.61,
                48.612,
                48.613,
                48.615,
                48.617,
                48.618,
                48.62,
                48.622,
                48.623,
                48.625,
                48.627,
                48.628,
                48.63,
                48.631,
                48.633,
                48.635,
                48.636,
                48.638,
                48.64,
                48.641,
                48.643,
                48.645,
                48.646,
                48.648,
                48.649,
                48.651,
                48.653,
                48.654,
                48.656,
                48.657,
                48.659,
                48.661,
                48.663,
                48.664,
                48.666,
                48.667,
                48.669,
                48.67,
                48.672,
                48.674,
                48.675,
                48.677,
                48.678,
                48.68,
                48.682,
                48.683,
                48.685,
                48.687,
                48.688,
                48.69,
                48.691,
                48.693,
                48.695,
                48.696,
                48.698,
                48.699,
                48.701,
                48.703,
                48.704,
                48.706,
                48.707,
                48.709,
                48.71,
                48.712,
                48.714,
                48.715,
                48.717,
                48.718,
                48.72,
                48.721,
                48.723,
                48.725,
                48.726,
                48.728,
                48.729,
                48.731,
                48.733,
                48.734,
                48.736,
                48.737,
                48.739,
                48.74,
                48.742,
                48.744,
                48.745,
                48.747,
                48.748,
                48.75,
                48.751,
                48.753,
                48.754,
                48.756,
                48.757,
                48.759,
                48.76,
                48.762,
                48.764,
                48.765,
                48.767,
                48.768,
                48.77,
                48.772,
                48.773,
                48.775,
                48.776,
                48.778,
                48.779,
                48.781,
                48.782,
                48.784,
                48.785,
                48.787,
                48.789,
                48.79,
                48.792,
                48.793,
                48.795,
                48.796,
                48.798,
                48.799,
                48.8,
                48.802,
                48.804,
                48.805,
                48.807,
                48.808,
                48.81,
                48.811,
                48.813,
                48.814,
                48.816,
                48.817,
                48.819,
                48.82,
                48.822,
                48.823,
                48.825,
                48.827,
                48.828,
                48.83,
                48.831,
                48.832,
                48.834,
                48.835,
                48.837,
                48.838,
                48.84,
                48.841,
                48.843,
                48.844,
                48.846,
                48.848,
                48.849,
                48.851,
                48.852,
                48.853,
                48.855,
                48.856,
                48.858,
                48.859,
                48.861,
                48.862,
                48.864,
                48.865,
                48.867,
                48.868,
                48.87,
                48.871,
                48.873,
                48.874,
                48.876,
                48.877,
                48.879,
                48.88,
                48.881,
                48.883,
                48.884,
                48.886,
                48.888,
                48.889,
                48.891,
                48.892,
                48.893,
                48.895,
                48.896,
                48.898,
                48.899,
                48.901,
                48.902,
                48.903,
                48.905,
                48.906,
                48.908,
                48.91,
                48.911,
                48.912,
                48.914,
                48.915,
                48.917,
                48.918,
                48.92,
                48.921,
                48.922,
                48.924,
                48.925,
                48.927,
                48.929,
                48.93,
                48.931,
                48.933,
                48.934,
                48.936,
                48.937,
                48.938,
                48.94,
                48.941,
                48.943,
                48.944,
                48.946,
                48.947,
                48.949,
                48.95,
                48.952,
                48.953,
                48.954,
                48.956,
                48.957,
                48.959,
                48.96,
                48.961,
                48.963,
                48.964,
                48.966,
                48.967,
                48.969,
                48.97,
                48.972,
                48.973,
                48.974,
                48.976,
                48.977,
                48.979,
                48.98,
                48.981,
                48.983,
                48.984,
                48.986,
                48.987,
                48.989,
                48.99,
                48.992,
                48.993,
                48.994,
                48.996,
                48.997,
                48.999,
                49,
                49.001,
                49.003,
                49.004,
                49.005
            ],
            P50: [
                33.879,
                33.975,
                34.071,
                34.168,
                34.264,
                34.36,
                34.457,
                34.553,
                34.649,
                34.746,
                34.842,
                34.938,
                35.035,
                35.131,
                35.227,
                35.321,
                35.413,
                35.503,
                35.591,
                35.677,
                35.761,
                35.843,
                35.924,
                36.003,
                36.08,
                36.156,
                36.231,
                36.304,
                36.376,
                36.447,
                36.516,
                36.585,
                36.652,
                36.718,
                36.783,
                36.847,
                36.91,
                36.973,
                37.034,
                37.095,
                37.154,
                37.213,
                37.271,
                37.328,
                37.385,
                37.441,
                37.496,
                37.551,
                37.605,
                37.658,
                37.711,
                37.764,
                37.815,
                37.866,
                37.917,
                37.967,
                38.017,
                38.066,
                38.115,
                38.163,
                38.211,
                38.258,
                38.305,
                38.352,
                38.398,
                38.444,
                38.489,
                38.534,
                38.579,
                38.623,
                38.667,
                38.711,
                38.754,
                38.797,
                38.84,
                38.882,
                38.924,
                38.966,
                39.008,
                39.049,
                39.09,
                39.13,
                39.17,
                39.21,
                39.25,
                39.29,
                39.329,
                39.368,
                39.406,
                39.445,
                39.483,
                39.521,
                39.559,
                39.596,
                39.634,
                39.671,
                39.707,
                39.744,
                39.78,
                39.816,
                39.852,
                39.888,
                39.923,
                39.959,
                39.994,
                40.028,
                40.063,
                40.097,
                40.132,
                40.166,
                40.199,
                40.233,
                40.266,
                40.3,
                40.333,
                40.365,
                40.398,
                40.43,
                40.463,
                40.495,
                40.526,
                40.558,
                40.59,
                40.621,
                40.652,
                40.683,
                40.714,
                40.744,
                40.775,
                40.805,
                40.835,
                40.865,
                40.894,
                40.924,
                40.953,
                40.982,
                41.012,
                41.04,
                41.069,
                41.098,
                41.126,
                41.154,
                41.182,
                41.21,
                41.238,
                41.265,
                41.293,
                41.32,
                41.347,
                41.374,
                41.401,
                41.428,
                41.454,
                41.48,
                41.507,
                41.533,
                41.559,
                41.584,
                41.61,
                41.636,
                41.661,
                41.686,
                41.711,
                41.736,
                41.761,
                41.786,
                41.81,
                41.835,
                41.859,
                41.883,
                41.907,
                41.931,
                41.955,
                41.978,
                42.002,
                42.025,
                42.049,
                42.072,
                42.095,
                42.118,
                42.14,
                42.163,
                42.186,
                42.208,
                42.23,
                42.252,
                42.274,
                42.296,
                42.318,
                42.34,
                42.361,
                42.383,
                42.404,
                42.426,
                42.447,
                42.468,
                42.489,
                42.509,
                42.53,
                42.551,
                42.571,
                42.592,
                42.612,
                42.632,
                42.652,
                42.672,
                42.692,
                42.712,
                42.731,
                42.751,
                42.77,
                42.789,
                42.809,
                42.828,
                42.847,
                42.866,
                42.885,
                42.903,
                42.922,
                42.941,
                42.959,
                42.977,
                42.996,
                43.014,
                43.032,
                43.05,
                43.068,
                43.086,
                43.103,
                43.121,
                43.139,
                43.156,
                43.173,
                43.191,
                43.208,
                43.225,
                43.242,
                43.259,
                43.276,
                43.293,
                43.309,
                43.326,
                43.342,
                43.359,
                43.375,
                43.392,
                43.408,
                43.424,
                43.44,
                43.456,
                43.472,
                43.488,
                43.503,
                43.519,
                43.535,
                43.55,
                43.565,
                43.581,
                43.596,
                43.611,
                43.626,
                43.642,
                43.656,
                43.671,
                43.686,
                43.701,
                43.716,
                43.73,
                43.745,
                43.759,
                43.774,
                43.788,
                43.803,
                43.817,
                43.831,
                43.845,
                43.859,
                43.873,
                43.887,
                43.901,
                43.915,
                43.928,
                43.942,
                43.956,
                43.969,
                43.982,
                43.996,
                44.009,
                44.023,
                44.036,
                44.049,
                44.062,
                44.075,
                44.088,
                44.101,
                44.114,
                44.127,
                44.14,
                44.152,
                44.165,
                44.177,
                44.19,
                44.203,
                44.215,
                44.227,
                44.24,
                44.252,
                44.264,
                44.276,
                44.288,
                44.301,
                44.313,
                44.325,
                44.336,
                44.348,
                44.36,
                44.372,
                44.384,
                44.395,
                44.407,
                44.418,
                44.43,
                44.441,
                44.453,
                44.464,
                44.476,
                44.487,
                44.498,
                44.509,
                44.521,
                44.532,
                44.543,
                44.554,
                44.565,
                44.576,
                44.587,
                44.597,
                44.608,
                44.619,
                44.63,
                44.64,
                44.651,
                44.662,
                44.672,
                44.683,
                44.693,
                44.703,
                44.714,
                44.724,
                44.734,
                44.745,
                44.755,
                44.765,
                44.775,
                44.785,
                44.795,
                44.805,
                44.815,
                44.825,
                44.835,
                44.845,
                44.855,
                44.865,
                44.875,
                44.884,
                44.894,
                44.904,
                44.913,
                44.923,
                44.933,
                44.942,
                44.952,
                44.961,
                44.97,
                44.98,
                44.989,
                44.998,
                45.008,
                45.017,
                45.026,
                45.035,
                45.045,
                45.054,
                45.063,
                45.072,
                45.081,
                45.09,
                45.099,
                45.108,
                45.117,
                45.125,
                45.134,
                45.143,
                45.152,
                45.161,
                45.169,
                45.178,
                45.187,
                45.195,
                45.204,
                45.212,
                45.221,
                45.229,
                45.238,
                45.246,
                45.255,
                45.263,
                45.272,
                45.28,
                45.288,
                45.297,
                45.305,
                45.313,
                45.321,
                45.329,
                45.338,
                45.346,
                45.354,
                45.362,
                45.37,
                45.378,
                45.386,
                45.394,
                45.402,
                45.41,
                45.418,
                45.426,
                45.433,
                45.441,
                45.449,
                45.457,
                45.465,
                45.472,
                45.48,
                45.488,
                45.495,
                45.503,
                45.511,
                45.518,
                45.526,
                45.533,
                45.541,
                45.548,
                45.556,
                45.563,
                45.571,
                45.578,
                45.585,
                45.593,
                45.6,
                45.608,
                45.615,
                45.622,
                45.629,
                45.637,
                45.644,
                45.651,
                45.658,
                45.665,
                45.673,
                45.68,
                45.687,
                45.694,
                45.701,
                45.708,
                45.715,
                45.722,
                45.729,
                45.736,
                45.743,
                45.75,
                45.757,
                45.764,
                45.771,
                45.777,
                45.784,
                45.791,
                45.798,
                45.805,
                45.811,
                45.818,
                45.825,
                45.832,
                45.838,
                45.845,
                45.852,
                45.858,
                45.865,
                45.872,
                45.878,
                45.885,
                45.891,
                45.898,
                45.905,
                45.911,
                45.918,
                45.924,
                45.931,
                45.937,
                45.943,
                45.95,
                45.956,
                45.963,
                45.969,
                45.976,
                45.982,
                45.988,
                45.995,
                46.001,
                46.007,
                46.013,
                46.02,
                46.026,
                46.032,
                46.039,
                46.045,
                46.051,
                46.057,
                46.063,
                46.069,
                46.076,
                46.082,
                46.088,
                46.094,
                46.1,
                46.106,
                46.112,
                46.118,
                46.124,
                46.131,
                46.137,
                46.143,
                46.149,
                46.155,
                46.161,
                46.167,
                46.173,
                46.178,
                46.184,
                46.19,
                46.196,
                46.202,
                46.208,
                46.214,
                46.22,
                46.226,
                46.232,
                46.237,
                46.243,
                46.249,
                46.255,
                46.261,
                46.266,
                46.272,
                46.278,
                46.284,
                46.289,
                46.295,
                46.301,
                46.307,
                46.312,
                46.318,
                46.324,
                46.329,
                46.335,
                46.341,
                46.346,
                46.352,
                46.358,
                46.363,
                46.369,
                46.375,
                46.38,
                46.386,
                46.391,
                46.397,
                46.402,
                46.408,
                46.414,
                46.419,
                46.425,
                46.43,
                46.436,
                46.441,
                46.447,
                46.452,
                46.458,
                46.463,
                46.469,
                46.474,
                46.479,
                46.485,
                46.49,
                46.496,
                46.501,
                46.507,
                46.512,
                46.517,
                46.523,
                46.528,
                46.533,
                46.539,
                46.544,
                46.55,
                46.555,
                46.56,
                46.566,
                46.571,
                46.576,
                46.581,
                46.587,
                46.592,
                46.597,
                46.603,
                46.608,
                46.613,
                46.618,
                46.624,
                46.629,
                46.634,
                46.639,
                46.645,
                46.65,
                46.655,
                46.66,
                46.665,
                46.671,
                46.676,
                46.681,
                46.686,
                46.691,
                46.696,
                46.702,
                46.707,
                46.712,
                46.717,
                46.722,
                46.727,
                46.732,
                46.738,
                46.743,
                46.748,
                46.753,
                46.758,
                46.763,
                46.768,
                46.773,
                46.778,
                46.783,
                46.788,
                46.793,
                46.798,
                46.803,
                46.808,
                46.814,
                46.819,
                46.824,
                46.829,
                46.834,
                46.839,
                46.844,
                46.849,
                46.854,
                46.858,
                46.863,
                46.868,
                46.873,
                46.878,
                46.883,
                46.888,
                46.893,
                46.898,
                46.903,
                46.908,
                46.913,
                46.918,
                46.923,
                46.928,
                46.933,
                46.937,
                46.942,
                46.947,
                46.952,
                46.957,
                46.962,
                46.967,
                46.971,
                46.976,
                46.981,
                46.986,
                46.991,
                46.996,
                47,
                47.005,
                47.01,
                47.015,
                47.02,
                47.025,
                47.029,
                47.034,
                47.039,
                47.044,
                47.048,
                47.053,
                47.058,
                47.063,
                47.067,
                47.072,
                47.077,
                47.082,
                47.086,
                47.091,
                47.096,
                47.101,
                47.105,
                47.11,
                47.115,
                47.119,
                47.124,
                47.129,
                47.133,
                47.138,
                47.143,
                47.147,
                47.152,
                47.157,
                47.161,
                47.166,
                47.171,
                47.175,
                47.18,
                47.185,
                47.189,
                47.194,
                47.198,
                47.203,
                47.208,
                47.212,
                47.217,
                47.221,
                47.226,
                47.23,
                47.235,
                47.24,
                47.244,
                47.249,
                47.253,
                47.258,
                47.262,
                47.267,
                47.271,
                47.276,
                47.28,
                47.285,
                47.289,
                47.294,
                47.298,
                47.303,
                47.307,
                47.312,
                47.316,
                47.321,
                47.325,
                47.33,
                47.334,
                47.339,
                47.343,
                47.347,
                47.352,
                47.356,
                47.361,
                47.365,
                47.369,
                47.374,
                47.378,
                47.383,
                47.387,
                47.391,
                47.396,
                47.4,
                47.404,
                47.409,
                47.413,
                47.418,
                47.422,
                47.426,
                47.431,
                47.435,
                47.439,
                47.443,
                47.448,
                47.452,
                47.456,
                47.461,
                47.465,
                47.469,
                47.473,
                47.478,
                47.482,
                47.486,
                47.491,
                47.495,
                47.499,
                47.503,
                47.507,
                47.512,
                47.516,
                47.52,
                47.524,
                47.528,
                47.533,
                47.537,
                47.541,
                47.545,
                47.549,
                47.554,
                47.558,
                47.562,
                47.566,
                47.57,
                47.574,
                47.578,
                47.583,
                47.587,
                47.591,
                47.595,
                47.599,
                47.603,
                47.607,
                47.611,
                47.615,
                47.619,
                47.623,
                47.628,
                47.632,
                47.636,
                47.64,
                47.644,
                47.648,
                47.652,
                47.656,
                47.66,
                47.664,
                47.668,
                47.672,
                47.676,
                47.68,
                47.684,
                47.688,
                47.692,
                47.696,
                47.7,
                47.704,
                47.707,
                47.711,
                47.715,
                47.719,
                47.723,
                47.727,
                47.731,
                47.735,
                47.739,
                47.743,
                47.747,
                47.75,
                47.754,
                47.758,
                47.762,
                47.766,
                47.77,
                47.774,
                47.777,
                47.781,
                47.785,
                47.789,
                47.793,
                47.797,
                47.8,
                47.804,
                47.808,
                47.812,
                47.816,
                47.819,
                47.823,
                47.827,
                47.831,
                47.834,
                47.838,
                47.842,
                47.846,
                47.849,
                47.853,
                47.857,
                47.861,
                47.864,
                47.868,
                47.872,
                47.875,
                47.879,
                47.883,
                47.886,
                47.89,
                47.894,
                47.897,
                47.901,
                47.905,
                47.908,
                47.912,
                47.916,
                47.919,
                47.923,
                47.926,
                47.93,
                47.934,
                47.937,
                47.941,
                47.944,
                47.948,
                47.952,
                47.955,
                47.959,
                47.962,
                47.966,
                47.969,
                47.973,
                47.976,
                47.98,
                47.983,
                47.987,
                47.99,
                47.994,
                47.998,
                48.001,
                48.004,
                48.008,
                48.011,
                48.015,
                48.018,
                48.022,
                48.025,
                48.029,
                48.032,
                48.036,
                48.039,
                48.043,
                48.046,
                48.049,
                48.053,
                48.056,
                48.06,
                48.063,
                48.066,
                48.07,
                48.073,
                48.077,
                48.08,
                48.083,
                48.087,
                48.09,
                48.093,
                48.097,
                48.1,
                48.104,
                48.107,
                48.11,
                48.114,
                48.117,
                48.12,
                48.123,
                48.127,
                48.13,
                48.133,
                48.137,
                48.14,
                48.143,
                48.147,
                48.15,
                48.153,
                48.156,
                48.16,
                48.163,
                48.166,
                48.169,
                48.173,
                48.176,
                48.179,
                48.182,
                48.185,
                48.189,
                48.192,
                48.195,
                48.198,
                48.202,
                48.205,
                48.208,
                48.211,
                48.214,
                48.217,
                48.221,
                48.224,
                48.227,
                48.23,
                48.233,
                48.236,
                48.24,
                48.243,
                48.246,
                48.249,
                48.252,
                48.255,
                48.258,
                48.261,
                48.264,
                48.268,
                48.271,
                48.274,
                48.277,
                48.28,
                48.283,
                48.286,
                48.289,
                48.292,
                48.295,
                48.298,
                48.301,
                48.304,
                48.308,
                48.311,
                48.314,
                48.317,
                48.32,
                48.323,
                48.326,
                48.329,
                48.332,
                48.335,
                48.338,
                48.341,
                48.344,
                48.347,
                48.35,
                48.353,
                48.356,
                48.359,
                48.362,
                48.365,
                48.368,
                48.371,
                48.373,
                48.376,
                48.379,
                48.382,
                48.385,
                48.388,
                48.391,
                48.394,
                48.397,
                48.4,
                48.403,
                48.406,
                48.409,
                48.411,
                48.414,
                48.417,
                48.42,
                48.423,
                48.426,
                48.429,
                48.432,
                48.435,
                48.437,
                48.44,
                48.443,
                48.446,
                48.449,
                48.452,
                48.455,
                48.457,
                48.46,
                48.463,
                48.466,
                48.469,
                48.472,
                48.474,
                48.477,
                48.48,
                48.483,
                48.486,
                48.488,
                48.491,
                48.494,
                48.497,
                48.5,
                48.502,
                48.505,
                48.508,
                48.511,
                48.513,
                48.516,
                48.519,
                48.522,
                48.524,
                48.527,
                48.53,
                48.533,
                48.535,
                48.538,
                48.541,
                48.544,
                48.546,
                48.549,
                48.552,
                48.555,
                48.557,
                48.56,
                48.563,
                48.565,
                48.568,
                48.571,
                48.573,
                48.576,
                48.579,
                48.581,
                48.584,
                48.587,
                48.59,
                48.592,
                48.595,
                48.597,
                48.6,
                48.603,
                48.605,
                48.608,
                48.611,
                48.613,
                48.616,
                48.619,
                48.621,
                48.624,
                48.627,
                48.629,
                48.632,
                48.634,
                48.637,
                48.64,
                48.642,
                48.645,
                48.647,
                48.65,
                48.653,
                48.655,
                48.658,
                48.66,
                48.663,
                48.666,
                48.668,
                48.671,
                48.673,
                48.676,
                48.678,
                48.681,
                48.683,
                48.686,
                48.689,
                48.691,
                48.694,
                48.696,
                48.699,
                48.701,
                48.704,
                48.706,
                48.709,
                48.711,
                48.714,
                48.716,
                48.719,
                48.721,
                48.724,
                48.726,
                48.729,
                48.731,
                48.734,
                48.736,
                48.739,
                48.741,
                48.744,
                48.746,
                48.749,
                48.751,
                48.754,
                48.756,
                48.759,
                48.761,
                48.764,
                48.766,
                48.768,
                48.771,
                48.773,
                48.776,
                48.778,
                48.781,
                48.783,
                48.786,
                48.788,
                48.79,
                48.793,
                48.795,
                48.798,
                48.8,
                48.802,
                48.805,
                48.807,
                48.81,
                48.812,
                48.814,
                48.817,
                48.819,
                48.822,
                48.824,
                48.826,
                48.829,
                48.831,
                48.834,
                48.836,
                48.838,
                48.841,
                48.843,
                48.845,
                48.848,
                48.85,
                48.852,
                48.855,
                48.857,
                48.859,
                48.862,
                48.864,
                48.866,
                48.869,
                48.871,
                48.873,
                48.876,
                48.878,
                48.88,
                48.883,
                48.885,
                48.887,
                48.89,
                48.892,
                48.894,
                48.897,
                48.899,
                48.901,
                48.903,
                48.906,
                48.908,
                48.91,
                48.913,
                48.915,
                48.917,
                48.919,
                48.922,
                48.924,
                48.926,
                48.928,
                48.931,
                48.933,
                48.935,
                48.938,
                48.94,
                48.942,
                48.944,
                48.947,
                48.949,
                48.951,
                48.953,
                48.955,
                48.958,
                48.96,
                48.962,
                48.964,
                48.967,
                48.969,
                48.971,
                48.973,
                48.975,
                48.978,
                48.98,
                48.982,
                48.984,
                48.986,
                48.989,
                48.991,
                48.993,
                48.995,
                48.997,
                49,
                49.002,
                49.004,
                49.006,
                49.008,
                49.01,
                49.013,
                49.015,
                49.017,
                49.019,
                49.021,
                49.023,
                49.026,
                49.028,
                49.03,
                49.032,
                49.034,
                49.036,
                49.038,
                49.041,
                49.043,
                49.045,
                49.047,
                49.049,
                49.051,
                49.053,
                49.055,
                49.058,
                49.06,
                49.062,
                49.064,
                49.066,
                49.068,
                49.07,
                49.072,
                49.074,
                49.077,
                49.079,
                49.081,
                49.083,
                49.085,
                49.087,
                49.089,
                49.091,
                49.093,
                49.095,
                49.097,
                49.099,
                49.102,
                49.104,
                49.106,
                49.108,
                49.11,
                49.112,
                49.114,
                49.116,
                49.118,
                49.12,
                49.122,
                49.124,
                49.126,
                49.128,
                49.13,
                49.132,
                49.134,
                49.136,
                49.138,
                49.14,
                49.143,
                49.145,
                49.147,
                49.149,
                49.151,
                49.153,
                49.155,
                49.157,
                49.159,
                49.161,
                49.163,
                49.165,
                49.167,
                49.169,
                49.171,
                49.173,
                49.175,
                49.177,
                49.179,
                49.181,
                49.183,
                49.185,
                49.187,
                49.189,
                49.191,
                49.193,
                49.195,
                49.197,
                49.199,
                49.201,
                49.202,
                49.204,
                49.206,
                49.208,
                49.21,
                49.212,
                49.214,
                49.216,
                49.218,
                49.22,
                49.222,
                49.224,
                49.226,
                49.228,
                49.23,
                49.232,
                49.234,
                49.236,
                49.238,
                49.24,
                49.241,
                49.243,
                49.245,
                49.247,
                49.249,
                49.251,
                49.253,
                49.255,
                49.257,
                49.259,
                49.261,
                49.263,
                49.265,
                49.266,
                49.268,
                49.27,
                49.272,
                49.274,
                49.276,
                49.278,
                49.28,
                49.282,
                49.284,
                49.285,
                49.287,
                49.289,
                49.291,
                49.293,
                49.295,
                49.297,
                49.299,
                49.301,
                49.302,
                49.304,
                49.306,
                49.308,
                49.31,
                49.312,
                49.314,
                49.315,
                49.317,
                49.319,
                49.321,
                49.323,
                49.325,
                49.327,
                49.328,
                49.33,
                49.332,
                49.334,
                49.336,
                49.338,
                49.34,
                49.341,
                49.343,
                49.345,
                49.347,
                49.349,
                49.351,
                49.352,
                49.354,
                49.356,
                49.358,
                49.36,
                49.362,
                49.363,
                49.365,
                49.367,
                49.369,
                49.371,
                49.372,
                49.374,
                49.376,
                49.378,
                49.38,
                49.382,
                49.383,
                49.385,
                49.387,
                49.389,
                49.391,
                49.392,
                49.394,
                49.396,
                49.398,
                49.4,
                49.401,
                49.403,
                49.405,
                49.407,
                49.408,
                49.41,
                49.412,
                49.414,
                49.416,
                49.417,
                49.419,
                49.421,
                49.423,
                49.424,
                49.426,
                49.428,
                49.43,
                49.432,
                49.433,
                49.435,
                49.437,
                49.439,
                49.44,
                49.442,
                49.444,
                49.446,
                49.447,
                49.449,
                49.451,
                49.453,
                49.454,
                49.456,
                49.458,
                49.46,
                49.461,
                49.463,
                49.465,
                49.467,
                49.468,
                49.47,
                49.472,
                49.474,
                49.475,
                49.477,
                49.479,
                49.48,
                49.482,
                49.484,
                49.486,
                49.487,
                49.489,
                49.491,
                49.493,
                49.494,
                49.496,
                49.498,
                49.499,
                49.501,
                49.503,
                49.505,
                49.506,
                49.508,
                49.51,
                49.511,
                49.513,
                49.515,
                49.516,
                49.518,
                49.52,
                49.522,
                49.523,
                49.525,
                49.527,
                49.528,
                49.53,
                49.532,
                49.533,
                49.535,
                49.537,
                49.538,
                49.54,
                49.542,
                49.544,
                49.545,
                49.547,
                49.549,
                49.55,
                49.552,
                49.554,
                49.555,
                49.557,
                49.559,
                49.56,
                49.562,
                49.564,
                49.565,
                49.567,
                49.569,
                49.57,
                49.572,
                49.574,
                49.575,
                49.577,
                49.579,
                49.58,
                49.582,
                49.584,
                49.585,
                49.587,
                49.588,
                49.59,
                49.592,
                49.593,
                49.595,
                49.597,
                49.598,
                49.6,
                49.602,
                49.603,
                49.605,
                49.607,
                49.608,
                49.61,
                49.611,
                49.613,
                49.615,
                49.616,
                49.618,
                49.62,
                49.621,
                49.623,
                49.624,
                49.626,
                49.628,
                49.629,
                49.631,
                49.633,
                49.634,
                49.636,
                49.637,
                49.639,
                49.641,
                49.642,
                49.644,
                49.645,
                49.647,
                49.649,
                49.65,
                49.652,
                49.653,
                49.655,
                49.657,
                49.658,
                49.66,
                49.661,
                49.663,
                49.665,
                49.666,
                49.668,
                49.669,
                49.671,
                49.673,
                49.674,
                49.676,
                49.677,
                49.679,
                49.68,
                49.682,
                49.684,
                49.685,
                49.687,
                49.688,
                49.69,
                49.692,
                49.693,
                49.695,
                49.696,
                49.698,
                49.699,
                49.701,
                49.703,
                49.704,
                49.706,
                49.707,
                49.709,
                49.71,
                49.712,
                49.713,
                49.715,
                49.717,
                49.718,
                49.72,
                49.721,
                49.723,
                49.724,
                49.726,
                49.727,
                49.729,
                49.731,
                49.732,
                49.734,
                49.735,
                49.737,
                49.738,
                49.74,
                49.741,
                49.743,
                49.744,
                49.746,
                49.748,
                49.749,
                49.751,
                49.752,
                49.754,
                49.755,
                49.757,
                49.758,
                49.76,
                49.761,
                49.763,
                49.764,
                49.766,
                49.767,
                49.769,
                49.77,
                49.772,
                49.774,
                49.775,
                49.777,
                49.778,
                49.78,
                49.781,
                49.783,
                49.784,
                49.786,
                49.787,
                49.789,
                49.79,
                49.792,
                49.793,
                49.795,
                49.796,
                49.798,
                49.799,
                49.801,
                49.802,
                49.804,
                49.805,
                49.807,
                49.808,
                49.81,
                49.811,
                49.813,
                49.814,
                49.816,
                49.817,
                49.819,
                49.82,
                49.822,
                49.823,
                49.825,
                49.826,
                49.828,
                49.829,
                49.831,
                49.832,
                49.834,
                49.835,
                49.837,
                49.838,
                49.84,
                49.841,
                49.842,
                49.844,
                49.845,
                49.847,
                49.848,
                49.85,
                49.851,
                49.853,
                49.854,
                49.856,
                49.857,
                49.859,
                49.86,
                49.862,
                49.863,
                49.865,
                49.866,
                49.867,
                49.869,
                49.87,
                49.872,
                49.873,
                49.875,
                49.876,
                49.878,
                49.879,
                49.881,
                49.882,
                49.884,
                49.885,
                49.886,
                49.888,
                49.889,
                49.891,
                49.892,
                49.894,
                49.895,
                49.897,
                49.898,
                49.9,
                49.901,
                49.902,
                49.904,
                49.905,
                49.907,
                49.908,
                49.91,
                49.911,
                49.913,
                49.914,
                49.915,
                49.917,
                49.918,
                49.92,
                49.921,
                49.923,
                49.924,
                49.925,
                49.927,
                49.928,
                49.93,
                49.931,
                49.933,
                49.934,
                49.935,
                49.937,
                49.938,
                49.94,
                49.941,
                49.943,
                49.944,
                49.945,
                49.947,
                49.948,
                49.95,
                49.951,
                49.953,
                49.954,
                49.955,
                49.957,
                49.958,
                49.96,
                49.961,
                49.962,
                49.964,
                49.965
            ],
            P75: [
                34.678,
                34.772,
                34.867,
                34.961,
                35.056,
                35.15,
                35.245,
                35.339,
                35.434,
                35.528,
                35.622,
                35.717,
                35.811,
                35.906,
                36,
                36.095,
                36.188,
                36.279,
                36.368,
                36.455,
                36.541,
                36.624,
                36.706,
                36.786,
                36.865,
                36.942,
                37.018,
                37.092,
                37.165,
                37.237,
                37.307,
                37.376,
                37.445,
                37.512,
                37.578,
                37.643,
                37.707,
                37.77,
                37.833,
                37.894,
                37.955,
                38.014,
                38.073,
                38.132,
                38.189,
                38.246,
                38.302,
                38.357,
                38.412,
                38.467,
                38.52,
                38.573,
                38.626,
                38.678,
                38.729,
                38.78,
                38.83,
                38.88,
                38.93,
                38.979,
                39.027,
                39.075,
                39.123,
                39.171,
                39.217,
                39.264,
                39.31,
                39.356,
                39.401,
                39.446,
                39.491,
                39.536,
                39.58,
                39.623,
                39.667,
                39.709,
                39.752,
                39.795,
                39.837,
                39.878,
                39.92,
                39.961,
                40.002,
                40.043,
                40.083,
                40.123,
                40.163,
                40.203,
                40.242,
                40.281,
                40.319,
                40.358,
                40.397,
                40.435,
                40.472,
                40.51,
                40.547,
                40.584,
                40.621,
                40.658,
                40.695,
                40.731,
                40.767,
                40.802,
                40.838,
                40.873,
                40.909,
                40.944,
                40.978,
                41.013,
                41.047,
                41.081,
                41.115,
                41.149,
                41.182,
                41.216,
                41.249,
                41.282,
                41.314,
                41.347,
                41.379,
                41.412,
                41.443,
                41.475,
                41.507,
                41.538,
                41.57,
                41.601,
                41.631,
                41.662,
                41.692,
                41.723,
                41.753,
                41.783,
                41.813,
                41.842,
                41.872,
                41.901,
                41.93,
                41.959,
                41.988,
                42.017,
                42.045,
                42.074,
                42.102,
                42.13,
                42.157,
                42.185,
                42.213,
                42.24,
                42.267,
                42.295,
                42.321,
                42.348,
                42.375,
                42.401,
                42.428,
                42.454,
                42.48,
                42.506,
                42.531,
                42.557,
                42.583,
                42.608,
                42.633,
                42.658,
                42.683,
                42.708,
                42.732,
                42.757,
                42.781,
                42.806,
                42.83,
                42.854,
                42.878,
                42.901,
                42.925,
                42.949,
                42.972,
                42.995,
                43.018,
                43.041,
                43.064,
                43.087,
                43.109,
                43.132,
                43.154,
                43.176,
                43.199,
                43.221,
                43.243,
                43.265,
                43.286,
                43.308,
                43.329,
                43.35,
                43.372,
                43.393,
                43.414,
                43.435,
                43.455,
                43.476,
                43.497,
                43.517,
                43.537,
                43.558,
                43.578,
                43.598,
                43.618,
                43.638,
                43.657,
                43.677,
                43.697,
                43.716,
                43.735,
                43.755,
                43.773,
                43.793,
                43.812,
                43.83,
                43.849,
                43.868,
                43.886,
                43.905,
                43.923,
                43.941,
                43.96,
                43.978,
                43.996,
                44.014,
                44.031,
                44.049,
                44.067,
                44.084,
                44.102,
                44.119,
                44.136,
                44.154,
                44.171,
                44.188,
                44.205,
                44.221,
                44.238,
                44.255,
                44.271,
                44.288,
                44.304,
                44.321,
                44.337,
                44.353,
                44.369,
                44.385,
                44.401,
                44.417,
                44.433,
                44.449,
                44.464,
                44.48,
                44.495,
                44.511,
                44.526,
                44.541,
                44.557,
                44.572,
                44.587,
                44.602,
                44.617,
                44.631,
                44.646,
                44.661,
                44.675,
                44.69,
                44.705,
                44.719,
                44.733,
                44.748,
                44.762,
                44.776,
                44.79,
                44.804,
                44.818,
                44.832,
                44.846,
                44.86,
                44.874,
                44.887,
                44.901,
                44.914,
                44.928,
                44.941,
                44.954,
                44.968,
                44.981,
                44.994,
                45.007,
                45.02,
                45.033,
                45.046,
                45.059,
                45.072,
                45.085,
                45.098,
                45.11,
                45.123,
                45.135,
                45.148,
                45.16,
                45.173,
                45.185,
                45.197,
                45.209,
                45.222,
                45.234,
                45.246,
                45.258,
                45.27,
                45.282,
                45.294,
                45.306,
                45.317,
                45.329,
                45.341,
                45.352,
                45.364,
                45.375,
                45.387,
                45.398,
                45.41,
                45.421,
                45.432,
                45.443,
                45.455,
                45.466,
                45.477,
                45.488,
                45.499,
                45.51,
                45.521,
                45.532,
                45.543,
                45.554,
                45.564,
                45.575,
                45.586,
                45.596,
                45.607,
                45.618,
                45.628,
                45.638,
                45.649,
                45.659,
                45.67,
                45.68,
                45.69,
                45.701,
                45.711,
                45.721,
                45.731,
                45.741,
                45.751,
                45.761,
                45.771,
                45.781,
                45.791,
                45.801,
                45.811,
                45.82,
                45.83,
                45.84,
                45.85,
                45.859,
                45.869,
                45.878,
                45.888,
                45.897,
                45.907,
                45.916,
                45.926,
                45.935,
                45.944,
                45.954,
                45.963,
                45.972,
                45.981,
                45.99,
                46,
                46.009,
                46.018,
                46.027,
                46.036,
                46.045,
                46.054,
                46.063,
                46.072,
                46.08,
                46.089,
                46.098,
                46.107,
                46.116,
                46.124,
                46.133,
                46.141,
                46.15,
                46.159,
                46.167,
                46.176,
                46.184,
                46.193,
                46.201,
                46.209,
                46.218,
                46.226,
                46.234,
                46.243,
                46.251,
                46.259,
                46.268,
                46.276,
                46.284,
                46.292,
                46.3,
                46.308,
                46.316,
                46.324,
                46.333,
                46.341,
                46.348,
                46.356,
                46.364,
                46.372,
                46.38,
                46.388,
                46.396,
                46.404,
                46.411,
                46.419,
                46.427,
                46.434,
                46.442,
                46.45,
                46.457,
                46.465,
                46.473,
                46.48,
                46.488,
                46.495,
                46.503,
                46.51,
                46.518,
                46.525,
                46.533,
                46.54,
                46.547,
                46.554,
                46.562,
                46.569,
                46.577,
                46.584,
                46.591,
                46.598,
                46.605,
                46.613,
                46.62,
                46.627,
                46.634,
                46.641,
                46.648,
                46.655,
                46.662,
                46.669,
                46.676,
                46.683,
                46.69,
                46.697,
                46.704,
                46.711,
                46.718,
                46.725,
                46.732,
                46.739,
                46.746,
                46.752,
                46.759,
                46.766,
                46.773,
                46.779,
                46.786,
                46.793,
                46.799,
                46.806,
                46.813,
                46.819,
                46.826,
                46.833,
                46.839,
                46.846,
                46.852,
                46.859,
                46.866,
                46.872,
                46.879,
                46.885,
                46.892,
                46.898,
                46.905,
                46.911,
                46.917,
                46.924,
                46.93,
                46.936,
                46.943,
                46.949,
                46.955,
                46.962,
                46.968,
                46.975,
                46.981,
                46.987,
                46.993,
                46.999,
                47.006,
                47.012,
                47.018,
                47.024,
                47.03,
                47.037,
                47.043,
                47.049,
                47.055,
                47.061,
                47.067,
                47.073,
                47.079,
                47.085,
                47.091,
                47.098,
                47.104,
                47.109,
                47.115,
                47.122,
                47.128,
                47.134,
                47.14,
                47.145,
                47.151,
                47.157,
                47.163,
                47.169,
                47.175,
                47.181,
                47.187,
                47.193,
                47.198,
                47.204,
                47.21,
                47.216,
                47.222,
                47.227,
                47.233,
                47.239,
                47.245,
                47.251,
                47.256,
                47.262,
                47.268,
                47.273,
                47.279,
                47.285,
                47.291,
                47.296,
                47.302,
                47.308,
                47.313,
                47.319,
                47.325,
                47.33,
                47.336,
                47.341,
                47.347,
                47.353,
                47.358,
                47.364,
                47.369,
                47.375,
                47.38,
                47.386,
                47.392,
                47.397,
                47.403,
                47.408,
                47.414,
                47.419,
                47.425,
                47.43,
                47.435,
                47.441,
                47.446,
                47.452,
                47.457,
                47.463,
                47.468,
                47.474,
                47.479,
                47.485,
                47.49,
                47.495,
                47.501,
                47.506,
                47.511,
                47.517,
                47.522,
                47.527,
                47.533,
                47.538,
                47.543,
                47.549,
                47.554,
                47.559,
                47.564,
                47.57,
                47.575,
                47.58,
                47.585,
                47.591,
                47.596,
                47.601,
                47.607,
                47.612,
                47.617,
                47.622,
                47.628,
                47.633,
                47.638,
                47.643,
                47.648,
                47.653,
                47.659,
                47.664,
                47.669,
                47.674,
                47.679,
                47.684,
                47.69,
                47.695,
                47.7,
                47.705,
                47.71,
                47.715,
                47.72,
                47.726,
                47.731,
                47.736,
                47.741,
                47.746,
                47.751,
                47.756,
                47.761,
                47.766,
                47.771,
                47.776,
                47.781,
                47.786,
                47.791,
                47.796,
                47.802,
                47.806,
                47.811,
                47.816,
                47.821,
                47.827,
                47.832,
                47.836,
                47.841,
                47.846,
                47.851,
                47.856,
                47.861,
                47.866,
                47.871,
                47.876,
                47.881,
                47.886,
                47.891,
                47.896,
                47.901,
                47.906,
                47.91,
                47.915,
                47.92,
                47.925,
                47.93,
                47.935,
                47.94,
                47.945,
                47.95,
                47.954,
                47.959,
                47.964,
                47.969,
                47.974,
                47.979,
                47.983,
                47.988,
                47.993,
                47.998,
                48.003,
                48.007,
                48.012,
                48.017,
                48.022,
                48.027,
                48.031,
                48.036,
                48.041,
                48.046,
                48.05,
                48.055,
                48.06,
                48.065,
                48.069,
                48.074,
                48.079,
                48.084,
                48.088,
                48.093,
                48.098,
                48.102,
                48.107,
                48.112,
                48.116,
                48.121,
                48.126,
                48.13,
                48.135,
                48.14,
                48.144,
                48.149,
                48.154,
                48.158,
                48.163,
                48.167,
                48.172,
                48.177,
                48.181,
                48.186,
                48.19,
                48.195,
                48.2,
                48.204,
                48.209,
                48.213,
                48.218,
                48.222,
                48.227,
                48.232,
                48.236,
                48.241,
                48.245,
                48.25,
                48.254,
                48.259,
                48.263,
                48.268,
                48.272,
                48.277,
                48.281,
                48.286,
                48.29,
                48.295,
                48.299,
                48.304,
                48.308,
                48.313,
                48.317,
                48.321,
                48.326,
                48.33,
                48.335,
                48.339,
                48.344,
                48.348,
                48.352,
                48.356,
                48.361,
                48.365,
                48.37,
                48.374,
                48.378,
                48.383,
                48.387,
                48.391,
                48.396,
                48.4,
                48.405,
                48.409,
                48.413,
                48.417,
                48.422,
                48.426,
                48.43,
                48.435,
                48.439,
                48.443,
                48.447,
                48.452,
                48.456,
                48.46,
                48.465,
                48.469,
                48.473,
                48.477,
                48.481,
                48.486,
                48.49,
                48.494,
                48.498,
                48.502,
                48.507,
                48.511,
                48.515,
                48.519,
                48.523,
                48.528,
                48.532,
                48.536,
                48.54,
                48.544,
                48.548,
                48.552,
                48.557,
                48.561,
                48.565,
                48.569,
                48.573,
                48.577,
                48.581,
                48.585,
                48.589,
                48.594,
                48.597,
                48.601,
                48.605,
                48.61,
                48.614,
                48.618,
                48.622,
                48.626,
                48.63,
                48.634,
                48.638,
                48.642,
                48.646,
                48.65,
                48.654,
                48.658,
                48.661,
                48.665,
                48.67,
                48.674,
                48.678,
                48.681,
                48.685,
                48.689,
                48.693,
                48.697,
                48.701,
                48.705,
                48.709,
                48.713,
                48.717,
                48.72,
                48.724,
                48.728,
                48.732,
                48.736,
                48.74,
                48.744,
                48.748,
                48.751,
                48.755,
                48.759,
                48.763,
                48.767,
                48.77,
                48.774,
                48.778,
                48.782,
                48.785,
                48.789,
                48.793,
                48.797,
                48.801,
                48.805,
                48.808,
                48.812,
                48.816,
                48.819,
                48.823,
                48.827,
                48.83,
                48.834,
                48.838,
                48.841,
                48.845,
                48.849,
                48.853,
                48.856,
                48.86,
                48.864,
                48.867,
                48.871,
                48.875,
                48.878,
                48.882,
                48.886,
                48.889,
                48.893,
                48.896,
                48.9,
                48.903,
                48.907,
                48.911,
                48.914,
                48.918,
                48.922,
                48.925,
                48.928,
                48.932,
                48.936,
                48.939,
                48.943,
                48.946,
                48.95,
                48.953,
                48.957,
                48.96,
                48.964,
                48.967,
                48.971,
                48.974,
                48.978,
                48.981,
                48.985,
                48.988,
                48.992,
                48.995,
                48.999,
                49.002,
                49.006,
                49.009,
                49.013,
                49.016,
                49.019,
                49.023,
                49.026,
                49.03,
                49.033,
                49.036,
                49.04,
                49.043,
                49.046,
                49.05,
                49.053,
                49.057,
                49.06,
                49.063,
                49.067,
                49.07,
                49.073,
                49.077,
                49.08,
                49.083,
                49.087,
                49.09,
                49.093,
                49.097,
                49.1,
                49.103,
                49.106,
                49.11,
                49.113,
                49.116,
                49.12,
                49.123,
                49.126,
                49.129,
                49.132,
                49.136,
                49.139,
                49.142,
                49.146,
                49.149,
                49.152,
                49.155,
                49.158,
                49.161,
                49.165,
                49.168,
                49.171,
                49.174,
                49.178,
                49.181,
                49.184,
                49.187,
                49.19,
                49.193,
                49.197,
                49.2,
                49.203,
                49.206,
                49.209,
                49.212,
                49.215,
                49.219,
                49.222,
                49.225,
                49.228,
                49.231,
                49.234,
                49.237,
                49.24,
                49.243,
                49.246,
                49.25,
                49.253,
                49.256,
                49.259,
                49.262,
                49.265,
                49.268,
                49.271,
                49.274,
                49.277,
                49.28,
                49.283,
                49.286,
                49.289,
                49.292,
                49.295,
                49.298,
                49.301,
                49.304,
                49.307,
                49.31,
                49.313,
                49.316,
                49.319,
                49.322,
                49.325,
                49.328,
                49.331,
                49.334,
                49.337,
                49.34,
                49.343,
                49.346,
                49.349,
                49.352,
                49.355,
                49.358,
                49.361,
                49.364,
                49.367,
                49.369,
                49.372,
                49.375,
                49.378,
                49.381,
                49.384,
                49.387,
                49.39,
                49.393,
                49.396,
                49.399,
                49.401,
                49.404,
                49.407,
                49.41,
                49.413,
                49.416,
                49.418,
                49.421,
                49.424,
                49.427,
                49.43,
                49.432,
                49.435,
                49.438,
                49.441,
                49.444,
                49.447,
                49.45,
                49.452,
                49.455,
                49.458,
                49.461,
                49.463,
                49.466,
                49.469,
                49.472,
                49.475,
                49.477,
                49.48,
                49.483,
                49.486,
                49.488,
                49.491,
                49.494,
                49.497,
                49.499,
                49.502,
                49.505,
                49.508,
                49.51,
                49.513,
                49.516,
                49.519,
                49.521,
                49.524,
                49.527,
                49.53,
                49.532,
                49.535,
                49.537,
                49.54,
                49.543,
                49.546,
                49.548,
                49.551,
                49.554,
                49.556,
                49.559,
                49.562,
                49.564,
                49.567,
                49.57,
                49.572,
                49.575,
                49.578,
                49.58,
                49.583,
                49.585,
                49.588,
                49.591,
                49.593,
                49.596,
                49.599,
                49.601,
                49.604,
                49.606,
                49.609,
                49.611,
                49.614,
                49.617,
                49.619,
                49.622,
                49.625,
                49.627,
                49.63,
                49.632,
                49.635,
                49.637,
                49.64,
                49.642,
                49.645,
                49.648,
                49.65,
                49.653,
                49.655,
                49.658,
                49.661,
                49.663,
                49.665,
                49.668,
                49.671,
                49.673,
                49.676,
                49.678,
                49.681,
                49.683,
                49.686,
                49.688,
                49.691,
                49.693,
                49.696,
                49.698,
                49.701,
                49.703,
                49.706,
                49.708,
                49.711,
                49.713,
                49.716,
                49.718,
                49.721,
                49.723,
                49.726,
                49.728,
                49.731,
                49.733,
                49.735,
                49.738,
                49.74,
                49.743,
                49.745,
                49.748,
                49.75,
                49.752,
                49.755,
                49.757,
                49.76,
                49.762,
                49.764,
                49.767,
                49.769,
                49.772,
                49.774,
                49.777,
                49.779,
                49.781,
                49.784,
                49.786,
                49.788,
                49.791,
                49.793,
                49.796,
                49.798,
                49.8,
                49.803,
                49.805,
                49.808,
                49.81,
                49.812,
                49.814,
                49.817,
                49.819,
                49.822,
                49.824,
                49.826,
                49.829,
                49.831,
                49.834,
                49.836,
                49.838,
                49.84,
                49.843,
                49.845,
                49.847,
                49.85,
                49.852,
                49.854,
                49.857,
                49.859,
                49.861,
                49.863,
                49.866,
                49.868,
                49.87,
                49.873,
                49.875,
                49.877,
                49.88,
                49.882,
                49.884,
                49.886,
                49.888,
                49.891,
                49.893,
                49.895,
                49.898,
                49.9,
                49.902,
                49.904,
                49.907,
                49.909,
                49.911,
                49.913,
                49.915,
                49.918,
                49.92,
                49.922,
                49.925,
                49.927,
                49.929,
                49.931,
                49.933,
                49.936,
                49.938,
                49.94,
                49.942,
                49.945,
                49.947,
                49.949,
                49.951,
                49.953,
                49.955,
                49.958,
                49.96,
                49.962,
                49.964,
                49.966,
                49.969,
                49.971,
                49.973,
                49.975,
                49.977,
                49.979,
                49.981,
                49.984,
                49.986,
                49.988,
                49.99,
                49.992,
                49.995,
                49.997,
                49.999,
                50.001,
                50.003,
                50.005,
                50.007,
                50.009,
                50.012,
                50.014,
                50.016,
                50.018,
                50.02,
                50.022,
                50.025,
                50.026,
                50.029,
                50.031,
                50.033,
                50.035,
                50.037,
                50.039,
                50.041,
                50.043,
                50.046,
                50.048,
                50.049,
                50.052,
                50.054,
                50.056,
                50.058,
                50.06,
                50.062,
                50.064,
                50.066,
                50.068,
                50.071,
                50.072,
                50.074,
                50.076,
                50.079,
                50.081,
                50.083,
                50.085,
                50.087,
                50.089,
                50.091,
                50.093,
                50.095,
                50.097,
                50.099,
                50.101,
                50.103,
                50.105,
                50.107,
                50.109,
                50.111,
                50.113,
                50.116,
                50.117,
                50.119,
                50.121,
                50.123,
                50.125,
                50.128,
                50.13,
                50.132,
                50.134,
                50.136,
                50.138,
                50.14,
                50.141,
                50.143,
                50.145,
                50.147,
                50.15,
                50.151,
                50.153,
                50.156,
                50.158,
                50.159,
                50.162,
                50.163,
                50.165,
                50.167,
                50.169,
                50.171,
                50.173,
                50.175,
                50.177,
                50.179,
                50.181,
                50.183,
                50.185,
                50.187,
                50.189,
                50.191,
                50.193,
                50.195,
                50.197,
                50.199,
                50.201,
                50.203,
                50.205,
                50.207,
                50.208,
                50.21,
                50.212,
                50.214,
                50.216,
                50.218,
                50.22,
                50.222,
                50.224,
                50.226,
                50.228,
                50.23,
                50.231,
                50.233,
                50.235,
                50.237,
                50.239,
                50.241,
                50.243,
                50.245,
                50.247,
                50.249,
                50.25,
                50.252,
                50.254,
                50.256,
                50.258,
                50.26,
                50.262,
                50.264,
                50.265,
                50.267,
                50.269,
                50.271,
                50.273,
                50.275,
                50.276,
                50.278,
                50.28,
                50.282,
                50.284,
                50.286,
                50.288,
                50.29,
                50.292,
                50.293,
                50.295,
                50.297,
                50.299,
                50.301,
                50.303,
                50.304,
                50.306,
                50.308,
                50.31,
                50.312,
                50.314,
                50.316,
                50.318,
                50.319,
                50.321,
                50.323,
                50.325,
                50.326,
                50.328,
                50.33,
                50.332,
                50.334,
                50.336,
                50.338,
                50.339,
                50.341,
                50.343,
                50.345,
                50.346,
                50.348,
                50.35,
                50.352,
                50.354,
                50.356,
                50.357,
                50.359,
                50.361,
                50.363,
                50.364,
                50.366,
                50.368,
                50.37,
                50.372,
                50.373,
                50.375,
                50.377,
                50.379,
                50.381,
                50.382,
                50.384,
                50.386,
                50.388,
                50.389,
                50.391,
                50.393,
                50.395,
                50.397,
                50.398,
                50.4,
                50.402,
                50.404,
                50.405,
                50.407,
                50.409,
                50.411,
                50.412,
                50.414,
                50.416,
                50.418,
                50.419,
                50.421,
                50.423,
                50.425,
                50.427,
                50.428,
                50.43,
                50.432,
                50.433,
                50.435,
                50.437,
                50.439,
                50.44,
                50.442,
                50.444,
                50.446,
                50.447,
                50.449,
                50.451,
                50.452,
                50.454,
                50.456,
                50.458,
                50.459,
                50.461,
                50.463,
                50.465,
                50.466,
                50.468,
                50.47,
                50.471,
                50.473,
                50.475,
                50.476,
                50.478,
                50.48,
                50.482,
                50.483,
                50.485,
                50.487,
                50.488,
                50.49,
                50.492,
                50.493,
                50.495,
                50.497,
                50.498,
                50.5,
                50.502,
                50.504,
                50.505,
                50.507,
                50.509,
                50.51,
                50.512,
                50.513,
                50.515,
                50.517,
                50.519,
                50.52,
                50.522,
                50.524,
                50.525,
                50.527,
                50.529,
                50.531,
                50.532,
                50.534,
                50.535,
                50.537,
                50.539,
                50.54,
                50.542,
                50.544,
                50.545,
                50.547,
                50.549,
                50.55,
                50.552,
                50.554,
                50.555,
                50.557,
                50.558,
                50.56,
                50.562,
                50.563,
                50.565,
                50.567,
                50.568,
                50.57,
                50.572,
                50.573,
                50.575,
                50.577,
                50.578,
                50.58,
                50.581,
                50.583,
                50.585,
                50.586,
                50.588,
                50.59,
                50.591,
                50.593,
                50.595,
                50.596,
                50.598,
                50.599,
                50.601,
                50.602,
                50.604,
                50.606,
                50.607,
                50.609,
                50.611,
                50.612,
                50.614,
                50.616,
                50.617,
                50.619,
                50.62,
                50.622,
                50.623,
                50.625,
                50.626,
                50.628,
                50.63,
                50.631,
                50.633,
                50.635,
                50.636,
                50.638,
                50.639,
                50.641,
                50.642,
                50.644,
                50.646,
                50.647,
                50.649,
                50.65,
                50.652,
                50.654,
                50.655,
                50.657,
                50.658,
                50.66,
                50.661,
                50.663,
                50.664,
                50.666,
                50.668,
                50.669,
                50.671,
                50.672,
                50.674,
                50.676,
                50.677,
                50.679,
                50.68,
                50.682,
                50.683,
                50.685,
                50.686,
                50.688,
                50.689,
                50.691,
                50.693,
                50.694,
                50.696,
                50.697,
                50.699,
                50.7,
                50.702,
                50.703,
                50.705,
                50.706,
                50.708,
                50.71,
                50.711,
                50.713,
                50.714,
                50.716,
                50.717,
                50.719,
                50.721,
                50.722,
                50.723,
                50.725,
                50.726,
                50.728,
                50.729,
                50.731,
                50.733,
                50.734,
                50.736,
                50.737,
                50.739,
                50.74,
                50.742,
                50.743,
                50.745,
                50.746,
                50.748,
                50.749,
                50.751,
                50.752,
                50.754,
                50.755,
                50.757,
                50.759,
                50.76,
                50.762,
                50.763,
                50.764,
                50.766,
                50.767,
                50.769,
                50.77,
                50.772,
                50.773,
                50.775,
                50.777,
                50.778,
                50.779,
                50.781,
                50.783,
                50.784,
                50.785,
                50.787,
                50.788,
                50.79,
                50.791,
                50.793,
                50.794,
                50.796,
                50.797,
                50.799,
                50.8,
                50.802,
                50.803,
                50.805,
                50.806,
                50.808,
                50.809,
                50.811,
                50.812,
                50.814,
                50.815,
                50.817,
                50.818,
                50.82,
                50.821,
                50.823,
                50.824,
                50.826,
                50.827,
                50.828,
                50.83,
                50.831,
                50.833,
                50.834,
                50.836,
                50.837,
                50.839,
                50.84,
                50.842,
                50.843,
                50.845,
                50.846,
                50.847,
                50.849,
                50.85,
                50.852,
                50.853,
                50.855,
                50.856,
                50.858,
                50.859,
                50.86,
                50.862,
                50.863,
                50.865,
                50.866,
                50.867,
                50.869,
                50.87,
                50.872,
                50.873,
                50.875,
                50.876,
                50.878,
                50.879,
                50.881,
                50.882,
                50.884,
                50.885,
                50.887,
                50.888,
                50.889,
                50.891,
                50.892,
                50.894,
                50.895,
                50.896,
                50.898,
                50.899,
                50.901,
                50.902,
                50.904,
                50.905,
                50.907,
                50.908,
                50.909,
                50.911,
                50.912,
                50.913,
                50.915,
                50.916,
                50.918,
                50.919,
                50.921,
                50.922,
                50.924,
                50.925
            ],
            P85: [
                35.106,
                35.2,
                35.294,
                35.387,
                35.481,
                35.574,
                35.668,
                35.761,
                35.854,
                35.948,
                36.041,
                36.135,
                36.228,
                36.321,
                36.414,
                36.51,
                36.604,
                36.696,
                36.785,
                36.873,
                36.959,
                37.043,
                37.126,
                37.207,
                37.286,
                37.363,
                37.44,
                37.515,
                37.588,
                37.661,
                37.732,
                37.801,
                37.87,
                37.938,
                38.005,
                38.07,
                38.135,
                38.198,
                38.261,
                38.323,
                38.384,
                38.445,
                38.504,
                38.563,
                38.62,
                38.678,
                38.735,
                38.79,
                38.846,
                38.9,
                38.954,
                39.008,
                39.061,
                39.113,
                39.165,
                39.216,
                39.267,
                39.317,
                39.367,
                39.417,
                39.466,
                39.514,
                39.562,
                39.61,
                39.657,
                39.704,
                39.75,
                39.797,
                39.842,
                39.888,
                39.933,
                39.978,
                40.022,
                40.066,
                40.11,
                40.153,
                40.196,
                40.239,
                40.281,
                40.324,
                40.365,
                40.407,
                40.448,
                40.489,
                40.53,
                40.57,
                40.61,
                40.651,
                40.69,
                40.729,
                40.768,
                40.807,
                40.846,
                40.884,
                40.923,
                40.96,
                40.998,
                41.036,
                41.073,
                41.11,
                41.147,
                41.183,
                41.219,
                41.255,
                41.291,
                41.327,
                41.362,
                41.398,
                41.433,
                41.467,
                41.502,
                41.537,
                41.571,
                41.605,
                41.638,
                41.672,
                41.705,
                41.739,
                41.771,
                41.804,
                41.837,
                41.87,
                41.902,
                41.934,
                41.966,
                41.997,
                42.029,
                42.06,
                42.091,
                42.122,
                42.153,
                42.184,
                42.214,
                42.244,
                42.274,
                42.304,
                42.334,
                42.363,
                42.393,
                42.422,
                42.451,
                42.48,
                42.508,
                42.537,
                42.565,
                42.594,
                42.622,
                42.65,
                42.677,
                42.705,
                42.732,
                42.76,
                42.787,
                42.814,
                42.841,
                42.868,
                42.894,
                42.92,
                42.946,
                42.972,
                42.999,
                43.025,
                43.05,
                43.076,
                43.101,
                43.127,
                43.151,
                43.177,
                43.201,
                43.226,
                43.251,
                43.275,
                43.299,
                43.324,
                43.347,
                43.372,
                43.395,
                43.419,
                43.442,
                43.466,
                43.489,
                43.512,
                43.536,
                43.558,
                43.581,
                43.604,
                43.627,
                43.649,
                43.671,
                43.694,
                43.715,
                43.738,
                43.759,
                43.781,
                43.802,
                43.824,
                43.846,
                43.867,
                43.888,
                43.909,
                43.93,
                43.951,
                43.972,
                43.992,
                44.013,
                44.033,
                44.053,
                44.074,
                44.094,
                44.114,
                44.134,
                44.153,
                44.173,
                44.193,
                44.212,
                44.232,
                44.25,
                44.27,
                44.289,
                44.308,
                44.327,
                44.346,
                44.364,
                44.383,
                44.401,
                44.42,
                44.438,
                44.456,
                44.474,
                44.493,
                44.51,
                44.528,
                44.546,
                44.564,
                44.581,
                44.599,
                44.616,
                44.634,
                44.651,
                44.668,
                44.685,
                44.702,
                44.719,
                44.735,
                44.752,
                44.769,
                44.785,
                44.802,
                44.818,
                44.835,
                44.851,
                44.867,
                44.883,
                44.899,
                44.915,
                44.931,
                44.947,
                44.963,
                44.978,
                44.994,
                45.009,
                45.024,
                45.04,
                45.055,
                45.07,
                45.085,
                45.1,
                45.115,
                45.13,
                45.145,
                45.159,
                45.174,
                45.189,
                45.203,
                45.218,
                45.232,
                45.246,
                45.261,
                45.275,
                45.289,
                45.303,
                45.317,
                45.331,
                45.345,
                45.359,
                45.372,
                45.386,
                45.4,
                45.414,
                45.427,
                45.44,
                45.454,
                45.467,
                45.48,
                45.494,
                45.507,
                45.52,
                45.533,
                45.546,
                45.559,
                45.572,
                45.585,
                45.597,
                45.61,
                45.623,
                45.635,
                45.648,
                45.66,
                45.673,
                45.685,
                45.697,
                45.71,
                45.722,
                45.734,
                45.746,
                45.758,
                45.77,
                45.782,
                45.794,
                45.806,
                45.817,
                45.829,
                45.841,
                45.853,
                45.864,
                45.876,
                45.887,
                45.899,
                45.91,
                45.922,
                45.933,
                45.944,
                45.956,
                45.966,
                45.978,
                45.989,
                46,
                46.011,
                46.022,
                46.033,
                46.044,
                46.055,
                46.065,
                46.076,
                46.087,
                46.097,
                46.108,
                46.119,
                46.129,
                46.14,
                46.15,
                46.161,
                46.171,
                46.181,
                46.192,
                46.202,
                46.212,
                46.222,
                46.233,
                46.242,
                46.253,
                46.263,
                46.273,
                46.282,
                46.293,
                46.302,
                46.312,
                46.322,
                46.332,
                46.342,
                46.351,
                46.361,
                46.371,
                46.38,
                46.39,
                46.399,
                46.409,
                46.418,
                46.428,
                46.437,
                46.446,
                46.456,
                46.465,
                46.474,
                46.483,
                46.493,
                46.502,
                46.511,
                46.52,
                46.529,
                46.538,
                46.547,
                46.556,
                46.565,
                46.574,
                46.583,
                46.592,
                46.6,
                46.609,
                46.618,
                46.627,
                46.635,
                46.644,
                46.653,
                46.661,
                46.67,
                46.679,
                46.687,
                46.695,
                46.704,
                46.712,
                46.721,
                46.729,
                46.737,
                46.746,
                46.754,
                46.763,
                46.77,
                46.779,
                46.787,
                46.795,
                46.803,
                46.811,
                46.82,
                46.828,
                46.836,
                46.844,
                46.852,
                46.86,
                46.868,
                46.875,
                46.883,
                46.891,
                46.899,
                46.907,
                46.915,
                46.922,
                46.93,
                46.938,
                46.945,
                46.953,
                46.961,
                46.969,
                46.976,
                46.984,
                46.991,
                46.999,
                47.007,
                47.014,
                47.021,
                47.029,
                47.036,
                47.044,
                47.051,
                47.058,
                47.066,
                47.073,
                47.08,
                47.088,
                47.095,
                47.102,
                47.11,
                47.116,
                47.124,
                47.131,
                47.138,
                47.146,
                47.152,
                47.159,
                47.167,
                47.174,
                47.181,
                47.187,
                47.195,
                47.202,
                47.209,
                47.215,
                47.222,
                47.229,
                47.236,
                47.243,
                47.25,
                47.257,
                47.264,
                47.27,
                47.277,
                47.284,
                47.291,
                47.297,
                47.304,
                47.311,
                47.317,
                47.324,
                47.331,
                47.338,
                47.344,
                47.35,
                47.357,
                47.364,
                47.37,
                47.377,
                47.383,
                47.39,
                47.397,
                47.403,
                47.41,
                47.416,
                47.422,
                47.429,
                47.435,
                47.442,
                47.448,
                47.454,
                47.461,
                47.467,
                47.474,
                47.479,
                47.486,
                47.492,
                47.498,
                47.505,
                47.511,
                47.517,
                47.523,
                47.53,
                47.536,
                47.542,
                47.548,
                47.554,
                47.561,
                47.567,
                47.573,
                47.579,
                47.585,
                47.591,
                47.597,
                47.603,
                47.609,
                47.615,
                47.621,
                47.627,
                47.633,
                47.639,
                47.645,
                47.651,
                47.657,
                47.663,
                47.669,
                47.675,
                47.681,
                47.687,
                47.693,
                47.699,
                47.704,
                47.71,
                47.716,
                47.722,
                47.728,
                47.734,
                47.739,
                47.745,
                47.751,
                47.757,
                47.763,
                47.768,
                47.774,
                47.78,
                47.786,
                47.791,
                47.797,
                47.802,
                47.808,
                47.814,
                47.82,
                47.825,
                47.831,
                47.837,
                47.842,
                47.848,
                47.854,
                47.859,
                47.865,
                47.87,
                47.876,
                47.882,
                47.887,
                47.893,
                47.898,
                47.904,
                47.909,
                47.915,
                47.921,
                47.926,
                47.931,
                47.937,
                47.942,
                47.948,
                47.954,
                47.959,
                47.964,
                47.97,
                47.975,
                47.981,
                47.986,
                47.991,
                47.997,
                48.002,
                48.008,
                48.013,
                48.019,
                48.024,
                48.029,
                48.035,
                48.04,
                48.045,
                48.051,
                48.056,
                48.061,
                48.067,
                48.072,
                48.077,
                48.083,
                48.088,
                48.093,
                48.098,
                48.104,
                48.109,
                48.114,
                48.119,
                48.125,
                48.13,
                48.135,
                48.141,
                48.146,
                48.151,
                48.156,
                48.161,
                48.167,
                48.172,
                48.177,
                48.182,
                48.187,
                48.192,
                48.198,
                48.203,
                48.208,
                48.213,
                48.218,
                48.223,
                48.229,
                48.234,
                48.239,
                48.244,
                48.249,
                48.254,
                48.259,
                48.264,
                48.269,
                48.275,
                48.279,
                48.284,
                48.29,
                48.295,
                48.3,
                48.305,
                48.31,
                48.315,
                48.32,
                48.325,
                48.33,
                48.335,
                48.34,
                48.345,
                48.35,
                48.355,
                48.36,
                48.365,
                48.37,
                48.375,
                48.38,
                48.385,
                48.39,
                48.395,
                48.4,
                48.404,
                48.409,
                48.414,
                48.419,
                48.424,
                48.429,
                48.434,
                48.439,
                48.444,
                48.449,
                48.454,
                48.459,
                48.464,
                48.468,
                48.473,
                48.478,
                48.483,
                48.488,
                48.493,
                48.498,
                48.502,
                48.507,
                48.512,
                48.517,
                48.522,
                48.526,
                48.531,
                48.536,
                48.541,
                48.545,
                48.55,
                48.555,
                48.56,
                48.564,
                48.569,
                48.574,
                48.579,
                48.584,
                48.588,
                48.593,
                48.598,
                48.602,
                48.607,
                48.612,
                48.617,
                48.621,
                48.626,
                48.631,
                48.635,
                48.64,
                48.645,
                48.65,
                48.654,
                48.659,
                48.663,
                48.668,
                48.673,
                48.677,
                48.682,
                48.687,
                48.691,
                48.696,
                48.7,
                48.705,
                48.71,
                48.714,
                48.719,
                48.723,
                48.728,
                48.732,
                48.737,
                48.742,
                48.746,
                48.751,
                48.755,
                48.76,
                48.764,
                48.769,
                48.774,
                48.778,
                48.783,
                48.787,
                48.791,
                48.796,
                48.8,
                48.805,
                48.81,
                48.814,
                48.819,
                48.823,
                48.827,
                48.832,
                48.836,
                48.841,
                48.845,
                48.85,
                48.854,
                48.858,
                48.863,
                48.867,
                48.872,
                48.876,
                48.881,
                48.885,
                48.889,
                48.893,
                48.898,
                48.902,
                48.907,
                48.911,
                48.915,
                48.92,
                48.924,
                48.928,
                48.933,
                48.937,
                48.942,
                48.945,
                48.95,
                48.954,
                48.958,
                48.963,
                48.967,
                48.971,
                48.976,
                48.98,
                48.984,
                48.988,
                48.993,
                48.997,
                49.001,
                49.005,
                49.009,
                49.014,
                49.018,
                49.022,
                49.026,
                49.031,
                49.035,
                49.039,
                49.043,
                49.047,
                49.051,
                49.055,
                49.06,
                49.064,
                49.068,
                49.072,
                49.076,
                49.08,
                49.084,
                49.089,
                49.093,
                49.097,
                49.101,
                49.105,
                49.109,
                49.113,
                49.117,
                49.121,
                49.125,
                49.129,
                49.133,
                49.137,
                49.141,
                49.145,
                49.149,
                49.153,
                49.158,
                49.162,
                49.165,
                49.169,
                49.173,
                49.177,
                49.181,
                49.185,
                49.189,
                49.193,
                49.197,
                49.201,
                49.205,
                49.209,
                49.213,
                49.217,
                49.221,
                49.225,
                49.228,
                49.232,
                49.236,
                49.24,
                49.244,
                49.248,
                49.252,
                49.256,
                49.259,
                49.263,
                49.267,
                49.271,
                49.275,
                49.279,
                49.283,
                49.287,
                49.29,
                49.294,
                49.298,
                49.302,
                49.305,
                49.309,
                49.313,
                49.316,
                49.32,
                49.324,
                49.328,
                49.332,
                49.335,
                49.339,
                49.343,
                49.347,
                49.35,
                49.354,
                49.358,
                49.361,
                49.365,
                49.369,
                49.373,
                49.376,
                49.379,
                49.383,
                49.387,
                49.391,
                49.394,
                49.398,
                49.402,
                49.405,
                49.409,
                49.412,
                49.416,
                49.42,
                49.423,
                49.427,
                49.431,
                49.434,
                49.437,
                49.441,
                49.445,
                49.448,
                49.452,
                49.456,
                49.459,
                49.463,
                49.466,
                49.469,
                49.473,
                49.477,
                49.48,
                49.484,
                49.487,
                49.491,
                49.494,
                49.497,
                49.501,
                49.504,
                49.508,
                49.512,
                49.515,
                49.519,
                49.522,
                49.525,
                49.529,
                49.532,
                49.536,
                49.539,
                49.542,
                49.546,
                49.549,
                49.552,
                49.556,
                49.559,
                49.563,
                49.566,
                49.57,
                49.573,
                49.576,
                49.58,
                49.583,
                49.586,
                49.59,
                49.593,
                49.596,
                49.6,
                49.603,
                49.607,
                49.609,
                49.613,
                49.616,
                49.619,
                49.623,
                49.626,
                49.63,
                49.633,
                49.636,
                49.639,
                49.642,
                49.646,
                49.649,
                49.652,
                49.656,
                49.659,
                49.662,
                49.666,
                49.668,
                49.672,
                49.675,
                49.678,
                49.681,
                49.685,
                49.688,
                49.691,
                49.694,
                49.697,
                49.7,
                49.704,
                49.707,
                49.71,
                49.713,
                49.716,
                49.72,
                49.722,
                49.726,
                49.729,
                49.732,
                49.735,
                49.738,
                49.742,
                49.745,
                49.748,
                49.751,
                49.754,
                49.757,
                49.76,
                49.763,
                49.766,
                49.769,
                49.773,
                49.776,
                49.778,
                49.781,
                49.785,
                49.788,
                49.791,
                49.794,
                49.797,
                49.8,
                49.803,
                49.806,
                49.809,
                49.812,
                49.815,
                49.818,
                49.821,
                49.824,
                49.827,
                49.83,
                49.833,
                49.836,
                49.839,
                49.842,
                49.845,
                49.848,
                49.851,
                49.854,
                49.857,
                49.86,
                49.863,
                49.866,
                49.869,
                49.872,
                49.875,
                49.877,
                49.88,
                49.883,
                49.886,
                49.889,
                49.892,
                49.895,
                49.898,
                49.901,
                49.904,
                49.907,
                49.91,
                49.913,
                49.915,
                49.918,
                49.921,
                49.924,
                49.927,
                49.93,
                49.933,
                49.935,
                49.938,
                49.941,
                49.944,
                49.947,
                49.949,
                49.952,
                49.955,
                49.958,
                49.961,
                49.964,
                49.966,
                49.969,
                49.972,
                49.975,
                49.978,
                49.98,
                49.983,
                49.986,
                49.989,
                49.992,
                49.994,
                49.997,
                50,
                50.003,
                50.005,
                50.008,
                50.011,
                50.014,
                50.017,
                50.019,
                50.022,
                50.024,
                50.027,
                50.03,
                50.033,
                50.036,
                50.038,
                50.041,
                50.044,
                50.046,
                50.049,
                50.052,
                50.054,
                50.057,
                50.06,
                50.063,
                50.065,
                50.068,
                50.071,
                50.073,
                50.076,
                50.078,
                50.081,
                50.084,
                50.087,
                50.089,
                50.092,
                50.095,
                50.097,
                50.1,
                50.102,
                50.105,
                50.108,
                50.11,
                50.113,
                50.116,
                50.118,
                50.121,
                50.123,
                50.126,
                50.129,
                50.131,
                50.134,
                50.136,
                50.139,
                50.142,
                50.144,
                50.147,
                50.149,
                50.152,
                50.154,
                50.157,
                50.16,
                50.162,
                50.165,
                50.168,
                50.17,
                50.173,
                50.175,
                50.177,
                50.18,
                50.183,
                50.185,
                50.188,
                50.19,
                50.193,
                50.195,
                50.198,
                50.2,
                50.203,
                50.205,
                50.208,
                50.21,
                50.213,
                50.215,
                50.218,
                50.221,
                50.223,
                50.225,
                50.228,
                50.23,
                50.233,
                50.235,
                50.238,
                50.24,
                50.243,
                50.245,
                50.248,
                50.25,
                50.252,
                50.255,
                50.257,
                50.26,
                50.262,
                50.265,
                50.267,
                50.27,
                50.272,
                50.274,
                50.277,
                50.279,
                50.282,
                50.284,
                50.287,
                50.289,
                50.291,
                50.294,
                50.296,
                50.298,
                50.301,
                50.303,
                50.306,
                50.308,
                50.311,
                50.313,
                50.315,
                50.318,
                50.32,
                50.323,
                50.325,
                50.327,
                50.329,
                50.332,
                50.334,
                50.337,
                50.339,
                50.341,
                50.344,
                50.346,
                50.348,
                50.35,
                50.353,
                50.355,
                50.358,
                50.36,
                50.362,
                50.365,
                50.367,
                50.369,
                50.372,
                50.374,
                50.376,
                50.378,
                50.381,
                50.383,
                50.385,
                50.388,
                50.39,
                50.392,
                50.395,
                50.396,
                50.399,
                50.401,
                50.403,
                50.406,
                50.408,
                50.41,
                50.413,
                50.415,
                50.417,
                50.42,
                50.422,
                50.424,
                50.426,
                50.428,
                50.431,
                50.433,
                50.435,
                50.437,
                50.44,
                50.442,
                50.444,
                50.446,
                50.448,
                50.451,
                50.453,
                50.455,
                50.457,
                50.46,
                50.462,
                50.464,
                50.466,
                50.468,
                50.47,
                50.473,
                50.475,
                50.477,
                50.479,
                50.482,
                50.484,
                50.486,
                50.488,
                50.491,
                50.492,
                50.494,
                50.497,
                50.499,
                50.501,
                50.503,
                50.505,
                50.508,
                50.51,
                50.512,
                50.514,
                50.516,
                50.518,
                50.52,
                50.523,
                50.525,
                50.527,
                50.529,
                50.531,
                50.533,
                50.536,
                50.538,
                50.539,
                50.542,
                50.544,
                50.546,
                50.548,
                50.55,
                50.552,
                50.555,
                50.557,
                50.559,
                50.561,
                50.563,
                50.565,
                50.567,
                50.569,
                50.571,
                50.573,
                50.575,
                50.578,
                50.58,
                50.582,
                50.584,
                50.586,
                50.588,
                50.59,
                50.592,
                50.594,
                50.596,
                50.598,
                50.6,
                50.602,
                50.604,
                50.607,
                50.608,
                50.61,
                50.612,
                50.614,
                50.617,
                50.619,
                50.621,
                50.623,
                50.625,
                50.627,
                50.629,
                50.631,
                50.633,
                50.635,
                50.637,
                50.639,
                50.641,
                50.643,
                50.645,
                50.647,
                50.649,
                50.651,
                50.653,
                50.655,
                50.657,
                50.659,
                50.661,
                50.663,
                50.665,
                50.667,
                50.669,
                50.671,
                50.673,
                50.675,
                50.677,
                50.679,
                50.681,
                50.683,
                50.685,
                50.687,
                50.689,
                50.691,
                50.693,
                50.695,
                50.697,
                50.699,
                50.7,
                50.702,
                50.704,
                50.706,
                50.708,
                50.71,
                50.712,
                50.714,
                50.716,
                50.718,
                50.72,
                50.722,
                50.724,
                50.726,
                50.728,
                50.73,
                50.732,
                50.734,
                50.736,
                50.737,
                50.739,
                50.741,
                50.743,
                50.745,
                50.747,
                50.749,
                50.751,
                50.753,
                50.755,
                50.757,
                50.758,
                50.76,
                50.762,
                50.764,
                50.766,
                50.768,
                50.77,
                50.772,
                50.773,
                50.775,
                50.777,
                50.779,
                50.781,
                50.783,
                50.785,
                50.787,
                50.788,
                50.79,
                50.792,
                50.794,
                50.796,
                50.798,
                50.8,
                50.802,
                50.804,
                50.806,
                50.807,
                50.809,
                50.811,
                50.813,
                50.815,
                50.816,
                50.818,
                50.82,
                50.822,
                50.824,
                50.826,
                50.828,
                50.83,
                50.832,
                50.833,
                50.835,
                50.837,
                50.839,
                50.84,
                50.842,
                50.844,
                50.846,
                50.848,
                50.85,
                50.852,
                50.853,
                50.855,
                50.857,
                50.859,
                50.86,
                50.862,
                50.864,
                50.866,
                50.868,
                50.87,
                50.871,
                50.873,
                50.875,
                50.876,
                50.878,
                50.88,
                50.882,
                50.884,
                50.886,
                50.887,
                50.889,
                50.891,
                50.893,
                50.895,
                50.897,
                50.898,
                50.9,
                50.902,
                50.903,
                50.905,
                50.907,
                50.909,
                50.911,
                50.912,
                50.914,
                50.916,
                50.918,
                50.92,
                50.921,
                50.923,
                50.925,
                50.926,
                50.928,
                50.93,
                50.932,
                50.934,
                50.935,
                50.937,
                50.939,
                50.941,
                50.942,
                50.944,
                50.946,
                50.947,
                50.949,
                50.951,
                50.953,
                50.954,
                50.956,
                50.958,
                50.96,
                50.962,
                50.963,
                50.965,
                50.966,
                50.968,
                50.97,
                50.972,
                50.974,
                50.975,
                50.977,
                50.979,
                50.981,
                50.982,
                50.984,
                50.985,
                50.987,
                50.989,
                50.991,
                50.992,
                50.994,
                50.996,
                50.998,
                50.999,
                51.001,
                51.003,
                51.005,
                51.006,
                51.008,
                51.009,
                51.011,
                51.013,
                51.014,
                51.016,
                51.018,
                51.02,
                51.021,
                51.023,
                51.025,
                51.026,
                51.028,
                51.029,
                51.031,
                51.033,
                51.035,
                51.036,
                51.038,
                51.04,
                51.041,
                51.043,
                51.045,
                51.047,
                51.048,
                51.049,
                51.051,
                51.053,
                51.055,
                51.056,
                51.058,
                51.06,
                51.061,
                51.063,
                51.065,
                51.066,
                51.068,
                51.07,
                51.071,
                51.073,
                51.074,
                51.076,
                51.078,
                51.08,
                51.081,
                51.083,
                51.085,
                51.086,
                51.088,
                51.089,
                51.091,
                51.092,
                51.094,
                51.096,
                51.097,
                51.099,
                51.101,
                51.102,
                51.104,
                51.106,
                51.107,
                51.109,
                51.111,
                51.112,
                51.113,
                51.115,
                51.117,
                51.119,
                51.12,
                51.122,
                51.123,
                51.125,
                51.127,
                51.128,
                51.13,
                51.132,
                51.133,
                51.134,
                51.136,
                51.138,
                51.139,
                51.141,
                51.143,
                51.144,
                51.146,
                51.148,
                51.149,
                51.151,
                51.152,
                51.154,
                51.155,
                51.157,
                51.158,
                51.16,
                51.162,
                51.163,
                51.165,
                51.167,
                51.168,
                51.17,
                51.171,
                51.173,
                51.175,
                51.176,
                51.177,
                51.179,
                51.181,
                51.182,
                51.184,
                51.185,
                51.187,
                51.189,
                51.19,
                51.192,
                51.193,
                51.195,
                51.196,
                51.198,
                51.199,
                51.201,
                51.203,
                51.204,
                51.206,
                51.207,
                51.209,
                51.21,
                51.212,
                51.214,
                51.215,
                51.217,
                51.218,
                51.219,
                51.221,
                51.223,
                51.224,
                51.226,
                51.227,
                51.229,
                51.231,
                51.232,
                51.234,
                51.235,
                51.237,
                51.238,
                51.24,
                51.241,
                51.243,
                51.244,
                51.246,
                51.247,
                51.249,
                51.25,
                51.252,
                51.254,
                51.255,
                51.257,
                51.258,
                51.259,
                51.261,
                51.262,
                51.264,
                51.265,
                51.267,
                51.269,
                51.27,
                51.272,
                51.273,
                51.275,
                51.276,
                51.278,
                51.279,
                51.28,
                51.282,
                51.284,
                51.285,
                51.287,
                51.288,
                51.29,
                51.291,
                51.293,
                51.294,
                51.296,
                51.297,
                51.299,
                51.3,
                51.301,
                51.303,
                51.305,
                51.306,
                51.308,
                51.309,
                51.311,
                51.312,
                51.314,
                51.315,
                51.317,
                51.318,
                51.32,
                51.321,
                51.322,
                51.324,
                51.325,
                51.327,
                51.328,
                51.33,
                51.331,
                51.333,
                51.334,
                51.336,
                51.337,
                51.339,
                51.341,
                51.341,
                51.343,
                51.345,
                51.346,
                51.348,
                51.349,
                51.35,
                51.352,
                51.353,
                51.355,
                51.356,
                51.358,
                51.36,
                51.361,
                51.362,
                51.363,
                51.365,
                51.366,
                51.368,
                51.369,
                51.371,
                51.372,
                51.374,
                51.375,
                51.377,
                51.378,
                51.38,
                51.381,
                51.382,
                51.384,
                51.385,
                51.387,
                51.388,
                51.39,
                51.391,
                51.393,
                51.394,
                51.396,
                51.397,
                51.399,
                51.4,
                51.402,
                51.402,
                51.404,
                51.405,
                51.407,
                51.408,
                51.41,
                51.411,
                51.413,
                51.414,
                51.416,
                51.417,
                51.419,
                51.42,
                51.422,
                51.423,
                51.424,
                51.426,
                51.427,
                51.428,
                51.43,
                51.431,
                51.433,
                51.434,
                51.436,
                51.437,
                51.439,
                51.44
            ],
            P90: [
                35.397,
                35.49,
                35.583,
                35.676,
                35.768,
                35.861,
                35.954,
                36.047,
                36.14,
                36.232,
                36.325,
                36.418,
                36.51,
                36.603,
                36.695,
                36.791,
                36.886,
                36.978,
                37.068,
                37.156,
                37.242,
                37.327,
                37.41,
                37.491,
                37.571,
                37.649,
                37.726,
                37.801,
                37.875,
                37.948,
                38.019,
                38.089,
                38.158,
                38.227,
                38.293,
                38.359,
                38.424,
                38.488,
                38.551,
                38.614,
                38.675,
                38.736,
                38.795,
                38.854,
                38.913,
                38.97,
                39.027,
                39.083,
                39.139,
                39.194,
                39.248,
                39.302,
                39.355,
                39.408,
                39.46,
                39.511,
                39.563,
                39.613,
                39.663,
                39.713,
                39.762,
                39.811,
                39.86,
                39.908,
                39.955,
                40.002,
                40.049,
                40.095,
                40.141,
                40.187,
                40.232,
                40.278,
                40.322,
                40.366,
                40.41,
                40.454,
                40.497,
                40.54,
                40.583,
                40.625,
                40.667,
                40.709,
                40.751,
                40.792,
                40.833,
                40.873,
                40.913,
                40.954,
                40.994,
                41.033,
                41.072,
                41.111,
                41.151,
                41.189,
                41.227,
                41.265,
                41.304,
                41.341,
                41.378,
                41.415,
                41.453,
                41.489,
                41.526,
                41.562,
                41.598,
                41.634,
                41.67,
                41.705,
                41.74,
                41.775,
                41.81,
                41.845,
                41.879,
                41.913,
                41.947,
                41.981,
                42.015,
                42.048,
                42.081,
                42.114,
                42.147,
                42.18,
                42.212,
                42.244,
                42.276,
                42.308,
                42.34,
                42.371,
                42.402,
                42.434,
                42.464,
                42.496,
                42.526,
                42.556,
                42.587,
                42.616,
                42.647,
                42.676,
                42.706,
                42.735,
                42.764,
                42.793,
                42.822,
                42.851,
                42.879,
                42.908,
                42.936,
                42.964,
                42.992,
                43.02,
                43.047,
                43.075,
                43.102,
                43.129,
                43.156,
                43.183,
                43.21,
                43.236,
                43.263,
                43.289,
                43.315,
                43.341,
                43.367,
                43.393,
                43.418,
                43.444,
                43.469,
                43.494,
                43.519,
                43.544,
                43.568,
                43.593,
                43.617,
                43.642,
                43.666,
                43.69,
                43.714,
                43.738,
                43.761,
                43.785,
                43.809,
                43.832,
                43.855,
                43.878,
                43.901,
                43.923,
                43.946,
                43.969,
                43.991,
                44.014,
                44.036,
                44.058,
                44.08,
                44.102,
                44.123,
                44.145,
                44.167,
                44.188,
                44.209,
                44.23,
                44.251,
                44.272,
                44.293,
                44.314,
                44.334,
                44.355,
                44.375,
                44.396,
                44.416,
                44.436,
                44.456,
                44.476,
                44.496,
                44.516,
                44.535,
                44.554,
                44.573,
                44.593,
                44.612,
                44.631,
                44.65,
                44.669,
                44.688,
                44.707,
                44.725,
                44.744,
                44.762,
                44.78,
                44.799,
                44.817,
                44.835,
                44.853,
                44.87,
                44.888,
                44.906,
                44.923,
                44.941,
                44.959,
                44.976,
                44.993,
                45.01,
                45.027,
                45.044,
                45.061,
                45.078,
                45.095,
                45.111,
                45.128,
                45.145,
                45.161,
                45.177,
                45.194,
                45.209,
                45.226,
                45.242,
                45.257,
                45.273,
                45.289,
                45.305,
                45.32,
                45.336,
                45.351,
                45.367,
                45.382,
                45.397,
                45.413,
                45.428,
                45.442,
                45.458,
                45.473,
                45.487,
                45.502,
                45.517,
                45.531,
                45.546,
                45.56,
                45.574,
                45.589,
                45.603,
                45.617,
                45.632,
                45.646,
                45.659,
                45.674,
                45.688,
                45.701,
                45.715,
                45.729,
                45.743,
                45.756,
                45.77,
                45.783,
                45.796,
                45.81,
                45.823,
                45.836,
                45.849,
                45.863,
                45.875,
                45.888,
                45.901,
                45.914,
                45.927,
                45.94,
                45.953,
                45.965,
                45.978,
                45.99,
                46.003,
                46.015,
                46.028,
                46.04,
                46.052,
                46.064,
                46.077,
                46.088,
                46.101,
                46.113,
                46.125,
                46.136,
                46.148,
                46.16,
                46.172,
                46.184,
                46.195,
                46.207,
                46.218,
                46.23,
                46.242,
                46.253,
                46.264,
                46.276,
                46.287,
                46.298,
                46.309,
                46.321,
                46.332,
                46.343,
                46.354,
                46.365,
                46.376,
                46.386,
                46.397,
                46.408,
                46.419,
                46.43,
                46.44,
                46.451,
                46.461,
                46.472,
                46.483,
                46.493,
                46.503,
                46.514,
                46.524,
                46.535,
                46.545,
                46.555,
                46.565,
                46.575,
                46.585,
                46.596,
                46.606,
                46.615,
                46.626,
                46.636,
                46.646,
                46.655,
                46.665,
                46.675,
                46.685,
                46.694,
                46.704,
                46.714,
                46.723,
                46.733,
                46.742,
                46.752,
                46.761,
                46.771,
                46.78,
                46.79,
                46.799,
                46.808,
                46.817,
                46.827,
                46.836,
                46.845,
                46.854,
                46.863,
                46.872,
                46.881,
                46.89,
                46.899,
                46.908,
                46.917,
                46.926,
                46.935,
                46.944,
                46.953,
                46.961,
                46.97,
                46.979,
                46.988,
                46.996,
                47.005,
                47.013,
                47.022,
                47.03,
                47.039,
                47.047,
                47.056,
                47.064,
                47.072,
                47.081,
                47.089,
                47.098,
                47.106,
                47.114,
                47.122,
                47.131,
                47.138,
                47.147,
                47.155,
                47.163,
                47.171,
                47.179,
                47.187,
                47.195,
                47.203,
                47.211,
                47.219,
                47.227,
                47.235,
                47.242,
                47.25,
                47.258,
                47.266,
                47.274,
                47.281,
                47.289,
                47.297,
                47.305,
                47.312,
                47.32,
                47.327,
                47.335,
                47.343,
                47.35,
                47.357,
                47.365,
                47.373,
                47.38,
                47.387,
                47.395,
                47.402,
                47.41,
                47.417,
                47.424,
                47.431,
                47.439,
                47.446,
                47.453,
                47.46,
                47.468,
                47.475,
                47.482,
                47.489,
                47.496,
                47.503,
                47.51,
                47.518,
                47.524,
                47.531,
                47.539,
                47.546,
                47.552,
                47.559,
                47.566,
                47.573,
                47.58,
                47.587,
                47.594,
                47.601,
                47.608,
                47.615,
                47.621,
                47.628,
                47.635,
                47.641,
                47.648,
                47.655,
                47.661,
                47.668,
                47.675,
                47.682,
                47.688,
                47.695,
                47.701,
                47.708,
                47.715,
                47.721,
                47.727,
                47.734,
                47.741,
                47.747,
                47.753,
                47.76,
                47.766,
                47.773,
                47.78,
                47.785,
                47.792,
                47.798,
                47.805,
                47.811,
                47.817,
                47.824,
                47.83,
                47.836,
                47.843,
                47.849,
                47.855,
                47.861,
                47.868,
                47.874,
                47.88,
                47.886,
                47.892,
                47.899,
                47.905,
                47.911,
                47.917,
                47.923,
                47.929,
                47.936,
                47.942,
                47.947,
                47.953,
                47.96,
                47.966,
                47.972,
                47.978,
                47.984,
                47.99,
                47.996,
                48.002,
                48.008,
                48.013,
                48.019,
                48.025,
                48.031,
                48.037,
                48.043,
                48.049,
                48.055,
                48.061,
                48.067,
                48.073,
                48.078,
                48.084,
                48.09,
                48.096,
                48.102,
                48.107,
                48.113,
                48.119,
                48.125,
                48.13,
                48.136,
                48.142,
                48.147,
                48.153,
                48.159,
                48.165,
                48.17,
                48.176,
                48.181,
                48.187,
                48.193,
                48.199,
                48.204,
                48.21,
                48.215,
                48.221,
                48.227,
                48.232,
                48.237,
                48.243,
                48.249,
                48.254,
                48.26,
                48.266,
                48.271,
                48.276,
                48.282,
                48.288,
                48.293,
                48.298,
                48.304,
                48.309,
                48.315,
                48.32,
                48.326,
                48.331,
                48.336,
                48.342,
                48.348,
                48.353,
                48.359,
                48.363,
                48.369,
                48.374,
                48.38,
                48.385,
                48.391,
                48.396,
                48.401,
                48.407,
                48.412,
                48.417,
                48.423,
                48.428,
                48.433,
                48.439,
                48.444,
                48.449,
                48.455,
                48.459,
                48.465,
                48.47,
                48.476,
                48.481,
                48.486,
                48.491,
                48.496,
                48.502,
                48.507,
                48.512,
                48.518,
                48.522,
                48.528,
                48.533,
                48.538,
                48.543,
                48.549,
                48.553,
                48.559,
                48.564,
                48.569,
                48.574,
                48.58,
                48.585,
                48.589,
                48.595,
                48.6,
                48.605,
                48.61,
                48.615,
                48.62,
                48.625,
                48.63,
                48.636,
                48.641,
                48.646,
                48.651,
                48.656,
                48.661,
                48.666,
                48.671,
                48.676,
                48.681,
                48.686,
                48.691,
                48.696,
                48.701,
                48.706,
                48.711,
                48.716,
                48.721,
                48.726,
                48.731,
                48.736,
                48.741,
                48.746,
                48.751,
                48.756,
                48.761,
                48.766,
                48.771,
                48.776,
                48.78,
                48.785,
                48.79,
                48.795,
                48.8,
                48.805,
                48.81,
                48.815,
                48.819,
                48.824,
                48.829,
                48.834,
                48.839,
                48.844,
                48.849,
                48.853,
                48.858,
                48.863,
                48.868,
                48.873,
                48.877,
                48.882,
                48.887,
                48.892,
                48.897,
                48.902,
                48.906,
                48.911,
                48.916,
                48.921,
                48.926,
                48.93,
                48.935,
                48.939,
                48.944,
                48.949,
                48.954,
                48.959,
                48.964,
                48.968,
                48.973,
                48.977,
                48.982,
                48.987,
                48.992,
                48.996,
                49.001,
                49.005,
                49.01,
                49.015,
                49.02,
                49.024,
                49.029,
                49.033,
                49.038,
                49.043,
                49.047,
                49.052,
                49.057,
                49.061,
                49.066,
                49.07,
                49.075,
                49.08,
                49.084,
                49.089,
                49.094,
                49.098,
                49.102,
                49.107,
                49.112,
                49.116,
                49.121,
                49.125,
                49.129,
                49.134,
                49.139,
                49.143,
                49.148,
                49.152,
                49.157,
                49.161,
                49.165,
                49.17,
                49.175,
                49.179,
                49.184,
                49.188,
                49.193,
                49.197,
                49.201,
                49.206,
                49.21,
                49.215,
                49.219,
                49.224,
                49.228,
                49.232,
                49.236,
                49.241,
                49.245,
                49.25,
                49.254,
                49.258,
                49.263,
                49.267,
                49.271,
                49.276,
                49.28,
                49.285,
                49.288,
                49.293,
                49.297,
                49.302,
                49.306,
                49.31,
                49.315,
                49.319,
                49.323,
                49.327,
                49.332,
                49.336,
                49.34,
                49.345,
                49.349,
                49.353,
                49.357,
                49.361,
                49.365,
                49.37,
                49.374,
                49.378,
                49.383,
                49.386,
                49.39,
                49.395,
                49.399,
                49.403,
                49.408,
                49.412,
                49.415,
                49.42,
                49.424,
                49.428,
                49.432,
                49.436,
                49.441,
                49.445,
                49.448,
                49.452,
                49.457,
                49.461,
                49.465,
                49.469,
                49.473,
                49.477,
                49.481,
                49.485,
                49.489,
                49.493,
                49.497,
                49.501,
                49.506,
                49.509,
                49.513,
                49.517,
                49.521,
                49.525,
                49.529,
                49.533,
                49.537,
                49.541,
                49.545,
                49.549,
                49.553,
                49.557,
                49.561,
                49.565,
                49.569,
                49.572,
                49.576,
                49.58,
                49.584,
                49.588,
                49.592,
                49.596,
                49.6,
                49.604,
                49.607,
                49.611,
                49.615,
                49.619,
                49.623,
                49.627,
                49.631,
                49.634,
                49.638,
                49.642,
                49.646,
                49.65,
                49.654,
                49.657,
                49.661,
                49.665,
                49.668,
                49.672,
                49.676,
                49.68,
                49.684,
                49.687,
                49.691,
                49.694,
                49.698,
                49.702,
                49.706,
                49.71,
                49.713,
                49.717,
                49.721,
                49.724,
                49.728,
                49.732,
                49.735,
                49.739,
                49.743,
                49.746,
                49.75,
                49.753,
                49.757,
                49.761,
                49.764,
                49.768,
                49.772,
                49.775,
                49.779,
                49.782,
                49.786,
                49.789,
                49.793,
                49.797,
                49.8,
                49.804,
                49.808,
                49.811,
                49.814,
                49.818,
                49.821,
                49.825,
                49.829,
                49.832,
                49.836,
                49.839,
                49.842,
                49.846,
                49.849,
                49.853,
                49.857,
                49.86,
                49.864,
                49.867,
                49.87,
                49.874,
                49.877,
                49.881,
                49.884,
                49.888,
                49.891,
                49.895,
                49.897,
                49.901,
                49.904,
                49.908,
                49.911,
                49.915,
                49.918,
                49.922,
                49.925,
                49.928,
                49.931,
                49.935,
                49.938,
                49.942,
                49.945,
                49.948,
                49.952,
                49.955,
                49.958,
                49.961,
                49.965,
                49.968,
                49.972,
                49.975,
                49.978,
                49.982,
                49.984,
                49.988,
                49.991,
                49.994,
                49.998,
                50.001,
                50.004,
                50.008,
                50.011,
                50.014,
                50.017,
                50.02,
                50.024,
                50.027,
                50.03,
                50.033,
                50.037,
                50.04,
                50.043,
                50.046,
                50.049,
                50.052,
                50.056,
                50.059,
                50.062,
                50.065,
                50.068,
                50.071,
                50.074,
                50.078,
                50.081,
                50.084,
                50.087,
                50.09,
                50.094,
                50.096,
                50.099,
                50.103,
                50.106,
                50.109,
                50.112,
                50.115,
                50.118,
                50.122,
                50.124,
                50.127,
                50.13,
                50.133,
                50.137,
                50.14,
                50.143,
                50.146,
                50.149,
                50.152,
                50.155,
                50.158,
                50.161,
                50.164,
                50.167,
                50.17,
                50.173,
                50.176,
                50.179,
                50.182,
                50.185,
                50.188,
                50.191,
                50.194,
                50.197,
                50.2,
                50.203,
                50.205,
                50.209,
                50.212,
                50.215,
                50.218,
                50.221,
                50.224,
                50.227,
                50.23,
                50.233,
                50.235,
                50.238,
                50.241,
                50.244,
                50.247,
                50.25,
                50.253,
                50.256,
                50.259,
                50.261,
                50.264,
                50.267,
                50.27,
                50.273,
                50.276,
                50.279,
                50.282,
                50.285,
                50.287,
                50.29,
                50.293,
                50.296,
                50.299,
                50.301,
                50.304,
                50.307,
                50.31,
                50.312,
                50.315,
                50.318,
                50.321,
                50.324,
                50.327,
                50.33,
                50.332,
                50.335,
                50.338,
                50.34,
                50.343,
                50.346,
                50.349,
                50.352,
                50.355,
                50.357,
                50.36,
                50.363,
                50.365,
                50.368,
                50.371,
                50.374,
                50.376,
                50.379,
                50.382,
                50.385,
                50.388,
                50.39,
                50.393,
                50.395,
                50.398,
                50.401,
                50.404,
                50.406,
                50.409,
                50.412,
                50.415,
                50.417,
                50.42,
                50.422,
                50.425,
                50.428,
                50.43,
                50.433,
                50.436,
                50.439,
                50.441,
                50.443,
                50.446,
                50.449,
                50.452,
                50.454,
                50.457,
                50.46,
                50.462,
                50.465,
                50.468,
                50.47,
                50.473,
                50.475,
                50.478,
                50.48,
                50.483,
                50.486,
                50.488,
                50.491,
                50.494,
                50.496,
                50.499,
                50.501,
                50.504,
                50.506,
                50.509,
                50.512,
                50.514,
                50.517,
                50.519,
                50.522,
                50.524,
                50.527,
                50.529,
                50.532,
                50.535,
                50.537,
                50.54,
                50.542,
                50.545,
                50.547,
                50.549,
                50.552,
                50.555,
                50.557,
                50.56,
                50.562,
                50.565,
                50.568,
                50.57,
                50.572,
                50.575,
                50.577,
                50.58,
                50.582,
                50.585,
                50.587,
                50.59,
                50.592,
                50.595,
                50.597,
                50.599,
                50.602,
                50.604,
                50.607,
                50.609,
                50.612,
                50.614,
                50.617,
                50.619,
                50.621,
                50.624,
                50.626,
                50.629,
                50.631,
                50.634,
                50.636,
                50.639,
                50.641,
                50.644,
                50.645,
                50.648,
                50.65,
                50.653,
                50.655,
                50.658,
                50.66,
                50.662,
                50.665,
                50.667,
                50.67,
                50.672,
                50.674,
                50.676,
                50.679,
                50.681,
                50.684,
                50.686,
                50.689,
                50.691,
                50.693,
                50.695,
                50.698,
                50.7,
                50.702,
                50.705,
                50.707,
                50.709,
                50.712,
                50.714,
                50.717,
                50.719,
                50.721,
                50.723,
                50.726,
                50.728,
                50.73,
                50.733,
                50.735,
                50.737,
                50.74,
                50.742,
                50.744,
                50.746,
                50.748,
                50.751,
                50.753,
                50.755,
                50.758,
                50.76,
                50.762,
                50.765,
                50.767,
                50.769,
                50.771,
                50.773,
                50.776,
                50.778,
                50.78,
                50.783,
                50.785,
                50.787,
                50.79,
                50.791,
                50.793,
                50.796,
                50.798,
                50.8,
                50.803,
                50.805,
                50.807,
                50.809,
                50.812,
                50.814,
                50.816,
                50.818,
                50.82,
                50.822,
                50.825,
                50.827,
                50.829,
                50.831,
                50.834,
                50.836,
                50.838,
                50.84,
                50.842,
                50.844,
                50.846,
                50.849,
                50.851,
                50.853,
                50.855,
                50.857,
                50.86,
                50.862,
                50.863,
                50.866,
                50.868,
                50.87,
                50.872,
                50.874,
                50.877,
                50.879,
                50.881,
                50.883,
                50.885,
                50.887,
                50.889,
                50.891,
                50.894,
                50.896,
                50.898,
                50.9,
                50.902,
                50.904,
                50.906,
                50.909,
                50.91,
                50.912,
                50.914,
                50.917,
                50.919,
                50.921,
                50.923,
                50.925,
                50.927,
                50.929,
                50.932,
                50.933,
                50.935,
                50.937,
                50.94,
                50.942,
                50.944,
                50.946,
                50.948,
                50.95,
                50.952,
                50.954,
                50.956,
                50.958,
                50.96,
                50.962,
                50.964,
                50.966,
                50.968,
                50.971,
                50.973,
                50.975,
                50.977,
                50.978,
                50.98,
                50.982,
                50.985,
                50.987,
                50.989,
                50.991,
                50.993,
                50.995,
                50.997,
                50.999,
                51.001,
                51.002,
                51.005,
                51.007,
                51.009,
                51.011,
                51.013,
                51.015,
                51.017,
                51.019,
                51.021,
                51.023,
                51.024,
                51.026,
                51.028,
                51.031,
                51.033,
                51.035,
                51.037,
                51.039,
                51.041,
                51.043,
                51.045,
                51.047,
                51.048,
                51.05,
                51.052,
                51.054,
                51.056,
                51.058,
                51.06,
                51.062,
                51.064,
                51.066,
                51.068,
                51.07,
                51.071,
                51.073,
                51.075,
                51.078,
                51.079,
                51.081,
                51.083,
                51.085,
                51.087,
                51.089,
                51.091,
                51.093,
                51.095,
                51.097,
                51.099,
                51.101,
                51.102,
                51.104,
                51.106,
                51.108,
                51.11,
                51.112,
                51.114,
                51.116,
                51.118,
                51.119,
                51.121,
                51.123,
                51.125,
                51.127,
                51.129,
                51.131,
                51.133,
                51.135,
                51.136,
                51.138,
                51.14,
                51.142,
                51.144,
                51.146,
                51.148,
                51.15,
                51.152,
                51.154,
                51.155,
                51.157,
                51.159,
                51.161,
                51.162,
                51.164,
                51.166,
                51.168,
                51.17,
                51.172,
                51.174,
                51.176,
                51.178,
                51.18,
                51.181,
                51.183,
                51.185,
                51.187,
                51.188,
                51.19,
                51.192,
                51.194,
                51.196,
                51.198,
                51.2,
                51.202,
                51.203,
                51.205,
                51.207,
                51.208,
                51.21,
                51.212,
                51.214,
                51.216,
                51.218,
                51.22,
                51.221,
                51.223,
                51.224,
                51.226,
                51.228,
                51.23,
                51.232,
                51.234,
                51.236,
                51.237,
                51.239,
                51.241,
                51.243,
                51.245,
                51.246,
                51.248,
                51.25,
                51.252,
                51.253,
                51.255,
                51.257,
                51.259,
                51.261,
                51.263,
                51.264,
                51.266,
                51.268,
                51.269,
                51.271,
                51.273,
                51.275,
                51.276,
                51.278,
                51.28,
                51.282,
                51.284,
                51.285,
                51.287,
                51.289,
                51.29,
                51.292,
                51.294,
                51.296,
                51.297,
                51.299,
                51.301,
                51.303,
                51.305,
                51.306,
                51.308,
                51.31,
                51.311,
                51.313,
                51.315,
                51.316,
                51.318,
                51.32,
                51.322,
                51.324,
                51.325,
                51.327,
                51.329,
                51.331,
                51.332,
                51.333,
                51.335,
                51.337,
                51.339,
                51.341,
                51.342,
                51.344,
                51.346,
                51.348,
                51.349,
                51.351,
                51.353,
                51.354,
                51.356,
                51.357,
                51.359,
                51.361,
                51.363,
                51.364,
                51.366,
                51.368,
                51.37,
                51.371,
                51.373,
                51.375,
                51.376,
                51.378,
                51.379,
                51.381,
                51.383,
                51.385,
                51.386,
                51.388,
                51.39,
                51.392,
                51.393,
                51.395,
                51.397,
                51.398,
                51.399,
                51.401,
                51.403,
                51.405,
                51.406,
                51.408,
                51.41,
                51.412,
                51.413,
                51.415,
                51.417,
                51.418,
                51.419,
                51.421,
                51.423,
                51.424,
                51.426,
                51.428,
                51.43,
                51.431,
                51.433,
                51.435,
                51.436,
                51.438,
                51.44,
                51.441,
                51.442,
                51.444,
                51.446,
                51.447,
                51.449,
                51.451,
                51.452,
                51.454,
                51.456,
                51.458,
                51.459,
                51.461,
                51.462,
                51.464,
                51.465,
                51.467,
                51.469,
                51.47,
                51.472,
                51.474,
                51.475,
                51.477,
                51.479,
                51.48,
                51.482,
                51.483,
                51.485,
                51.486,
                51.488,
                51.489,
                51.491,
                51.493,
                51.494,
                51.496,
                51.498,
                51.499,
                51.501,
                51.503,
                51.504,
                51.505,
                51.507,
                51.508,
                51.51,
                51.512,
                51.513,
                51.515,
                51.517,
                51.518,
                51.52,
                51.522,
                51.523,
                51.524,
                51.526,
                51.527,
                51.529,
                51.531,
                51.532,
                51.534,
                51.536,
                51.537,
                51.539,
                51.54,
                51.542,
                51.544,
                51.545,
                51.546,
                51.548,
                51.549,
                51.551,
                51.553,
                51.554,
                51.556,
                51.557,
                51.559,
                51.561,
                51.562,
                51.564,
                51.565,
                51.566,
                51.568,
                51.57,
                51.571,
                51.573,
                51.574,
                51.576,
                51.578,
                51.579,
                51.581,
                51.582,
                51.584,
                51.585,
                51.586,
                51.588,
                51.59,
                51.591,
                51.593,
                51.594,
                51.596,
                51.597,
                51.599,
                51.601,
                51.602,
                51.604,
                51.605,
                51.607,
                51.608,
                51.609,
                51.611,
                51.612,
                51.614,
                51.616,
                51.617,
                51.619,
                51.62,
                51.622,
                51.623,
                51.625,
                51.627,
                51.627,
                51.629,
                51.631,
                51.632,
                51.634,
                51.635,
                51.637,
                51.638,
                51.64,
                51.641,
                51.643,
                51.644,
                51.646,
                51.648,
                51.649,
                51.65,
                51.652,
                51.653,
                51.655,
                51.656,
                51.658,
                51.659,
                51.661,
                51.662,
                51.664,
                51.665,
                51.667,
                51.669,
                51.669,
                51.671,
                51.672,
                51.674,
                51.676,
                51.677,
                51.679,
                51.68,
                51.682,
                51.683,
                51.685,
                51.686,
                51.688,
                51.689,
                51.69,
                51.692,
                51.693,
                51.695,
                51.696,
                51.698,
                51.699,
                51.701,
                51.702,
                51.704,
                51.705,
                51.707,
                51.708,
                51.71,
                51.711,
                51.712,
                51.714,
                51.715,
                51.717,
                51.718,
                51.72,
                51.721,
                51.723,
                51.724,
                51.726,
                51.727,
                51.729,
                51.73,
                51.731,
                51.733,
                51.734,
                51.736,
                51.737,
                51.738,
                51.74,
                51.741,
                51.743,
                51.744,
                51.746,
                51.747,
                51.749,
                51.75,
                51.751,
                51.753,
                51.754,
                51.756,
                51.757,
                51.759,
                51.76,
                51.762,
                51.763,
                51.765,
                51.766,
                51.768,
                51.769,
                51.77,
                51.771,
                51.773,
                51.774,
                51.776,
                51.777,
                51.779,
                51.78,
                51.782,
                51.783,
                51.785,
                51.786,
                51.787,
                51.789
            ],
            P95: [
                35.827,
                35.919,
                36.011,
                36.103,
                36.195,
                36.287,
                36.378,
                36.47,
                36.562,
                36.654,
                36.745,
                36.837,
                36.928,
                37.02,
                37.111,
                37.208,
                37.303,
                37.396,
                37.487,
                37.576,
                37.662,
                37.748,
                37.831,
                37.913,
                37.993,
                38.072,
                38.149,
                38.225,
                38.3,
                38.373,
                38.445,
                38.516,
                38.585,
                38.654,
                38.722,
                38.788,
                38.853,
                38.918,
                38.981,
                39.045,
                39.106,
                39.167,
                39.227,
                39.287,
                39.345,
                39.404,
                39.461,
                39.518,
                39.574,
                39.629,
                39.684,
                39.738,
                39.792,
                39.845,
                39.898,
                39.949,
                40.001,
                40.052,
                40.102,
                40.153,
                40.202,
                40.252,
                40.3,
                40.349,
                40.396,
                40.444,
                40.491,
                40.538,
                40.584,
                40.63,
                40.676,
                40.722,
                40.767,
                40.811,
                40.856,
                40.899,
                40.943,
                40.986,
                41.029,
                41.072,
                41.114,
                41.157,
                41.199,
                41.24,
                41.281,
                41.322,
                41.363,
                41.404,
                41.444,
                41.483,
                41.523,
                41.562,
                41.602,
                41.641,
                41.679,
                41.717,
                41.756,
                41.794,
                41.832,
                41.869,
                41.907,
                41.943,
                41.98,
                42.016,
                42.053,
                42.089,
                42.125,
                42.161,
                42.196,
                42.232,
                42.267,
                42.302,
                42.336,
                42.371,
                42.405,
                42.439,
                42.473,
                42.506,
                42.54,
                42.573,
                42.606,
                42.64,
                42.672,
                42.704,
                42.737,
                42.769,
                42.801,
                42.832,
                42.864,
                42.896,
                42.926,
                42.958,
                42.988,
                43.019,
                43.05,
                43.08,
                43.11,
                43.14,
                43.17,
                43.199,
                43.228,
                43.258,
                43.287,
                43.316,
                43.345,
                43.374,
                43.402,
                43.43,
                43.458,
                43.487,
                43.514,
                43.542,
                43.569,
                43.597,
                43.624,
                43.651,
                43.678,
                43.704,
                43.731,
                43.757,
                43.784,
                43.81,
                43.836,
                43.862,
                43.888,
                43.914,
                43.939,
                43.964,
                43.989,
                44.015,
                44.039,
                44.064,
                44.089,
                44.113,
                44.137,
                44.162,
                44.186,
                44.21,
                44.234,
                44.258,
                44.281,
                44.305,
                44.328,
                44.351,
                44.375,
                44.397,
                44.42,
                44.443,
                44.465,
                44.488,
                44.51,
                44.533,
                44.555,
                44.577,
                44.598,
                44.62,
                44.642,
                44.664,
                44.685,
                44.706,
                44.728,
                44.749,
                44.77,
                44.791,
                44.811,
                44.832,
                44.852,
                44.873,
                44.894,
                44.914,
                44.934,
                44.954,
                44.974,
                44.994,
                45.013,
                45.033,
                45.052,
                45.072,
                45.092,
                45.11,
                45.13,
                45.149,
                45.168,
                45.187,
                45.205,
                45.224,
                45.243,
                45.261,
                45.279,
                45.298,
                45.316,
                45.334,
                45.351,
                45.37,
                45.388,
                45.405,
                45.423,
                45.441,
                45.458,
                45.475,
                45.493,
                45.509,
                45.527,
                45.543,
                45.561,
                45.578,
                45.594,
                45.611,
                45.628,
                45.644,
                45.66,
                45.677,
                45.693,
                45.709,
                45.726,
                45.741,
                45.757,
                45.774,
                45.789,
                45.805,
                45.821,
                45.836,
                45.852,
                45.867,
                45.882,
                45.898,
                45.913,
                45.928,
                45.943,
                45.958,
                45.973,
                45.988,
                46.003,
                46.017,
                46.032,
                46.047,
                46.061,
                46.075,
                46.09,
                46.104,
                46.118,
                46.133,
                46.146,
                46.161,
                46.175,
                46.188,
                46.202,
                46.216,
                46.23,
                46.243,
                46.257,
                46.271,
                46.284,
                46.298,
                46.311,
                46.324,
                46.338,
                46.351,
                46.364,
                46.377,
                46.39,
                46.403,
                46.416,
                46.429,
                46.442,
                46.454,
                46.467,
                46.48,
                46.492,
                46.504,
                46.517,
                46.53,
                46.542,
                46.554,
                46.567,
                46.578,
                46.591,
                46.603,
                46.615,
                46.627,
                46.639,
                46.651,
                46.662,
                46.674,
                46.686,
                46.698,
                46.709,
                46.721,
                46.733,
                46.744,
                46.755,
                46.767,
                46.779,
                46.789,
                46.801,
                46.812,
                46.824,
                46.834,
                46.846,
                46.857,
                46.868,
                46.878,
                46.89,
                46.901,
                46.911,
                46.922,
                46.933,
                46.944,
                46.954,
                46.965,
                46.975,
                46.986,
                46.996,
                47.007,
                47.017,
                47.028,
                47.038,
                47.048,
                47.059,
                47.068,
                47.079,
                47.089,
                47.099,
                47.109,
                47.119,
                47.129,
                47.139,
                47.149,
                47.159,
                47.169,
                47.179,
                47.188,
                47.198,
                47.208,
                47.218,
                47.227,
                47.237,
                47.246,
                47.256,
                47.265,
                47.275,
                47.284,
                47.294,
                47.303,
                47.312,
                47.322,
                47.331,
                47.34,
                47.349,
                47.358,
                47.368,
                47.376,
                47.385,
                47.395,
                47.404,
                47.412,
                47.421,
                47.43,
                47.44,
                47.449,
                47.457,
                47.466,
                47.475,
                47.484,
                47.492,
                47.501,
                47.509,
                47.518,
                47.526,
                47.535,
                47.544,
                47.552,
                47.56,
                47.569,
                47.577,
                47.586,
                47.594,
                47.602,
                47.611,
                47.619,
                47.628,
                47.635,
                47.644,
                47.652,
                47.66,
                47.668,
                47.676,
                47.684,
                47.692,
                47.701,
                47.708,
                47.716,
                47.724,
                47.732,
                47.74,
                47.748,
                47.756,
                47.764,
                47.772,
                47.779,
                47.787,
                47.795,
                47.803,
                47.81,
                47.818,
                47.825,
                47.833,
                47.841,
                47.848,
                47.856,
                47.863,
                47.871,
                47.879,
                47.885,
                47.893,
                47.901,
                47.908,
                47.915,
                47.923,
                47.93,
                47.937,
                47.945,
                47.952,
                47.959,
                47.966,
                47.974,
                47.981,
                47.988,
                47.995,
                48.002,
                48.01,
                48.017,
                48.023,
                48.031,
                48.038,
                48.045,
                48.051,
                48.058,
                48.066,
                48.073,
                48.08,
                48.086,
                48.093,
                48.1,
                48.107,
                48.114,
                48.12,
                48.127,
                48.134,
                48.141,
                48.148,
                48.154,
                48.161,
                48.168,
                48.175,
                48.182,
                48.188,
                48.195,
                48.202,
                48.208,
                48.215,
                48.221,
                48.228,
                48.234,
                48.241,
                48.248,
                48.254,
                48.26,
                48.267,
                48.274,
                48.28,
                48.286,
                48.293,
                48.299,
                48.306,
                48.312,
                48.318,
                48.325,
                48.331,
                48.337,
                48.344,
                48.35,
                48.356,
                48.362,
                48.369,
                48.375,
                48.382,
                48.387,
                48.394,
                48.4,
                48.406,
                48.413,
                48.418,
                48.424,
                48.431,
                48.437,
                48.443,
                48.449,
                48.455,
                48.461,
                48.467,
                48.474,
                48.48,
                48.485,
                48.491,
                48.498,
                48.504,
                48.51,
                48.515,
                48.521,
                48.527,
                48.533,
                48.539,
                48.545,
                48.551,
                48.557,
                48.563,
                48.569,
                48.575,
                48.58,
                48.586,
                48.592,
                48.598,
                48.604,
                48.609,
                48.615,
                48.621,
                48.627,
                48.633,
                48.639,
                48.644,
                48.65,
                48.656,
                48.662,
                48.667,
                48.673,
                48.678,
                48.684,
                48.69,
                48.696,
                48.702,
                48.707,
                48.712,
                48.718,
                48.724,
                48.73,
                48.735,
                48.74,
                48.746,
                48.752,
                48.758,
                48.763,
                48.769,
                48.774,
                48.78,
                48.785,
                48.791,
                48.797,
                48.802,
                48.807,
                48.813,
                48.818,
                48.824,
                48.83,
                48.834,
                48.84,
                48.846,
                48.851,
                48.857,
                48.862,
                48.867,
                48.873,
                48.878,
                48.884,
                48.889,
                48.895,
                48.9,
                48.905,
                48.911,
                48.916,
                48.922,
                48.927,
                48.932,
                48.937,
                48.943,
                48.948,
                48.954,
                48.959,
                48.964,
                48.969,
                48.975,
                48.98,
                48.985,
                48.991,
                48.995,
                49.001,
                49.006,
                49.012,
                49.017,
                49.022,
                49.027,
                49.032,
                49.038,
                49.043,
                49.048,
                49.053,
                49.058,
                49.063,
                49.069,
                49.074,
                49.079,
                49.085,
                49.09,
                49.094,
                49.1,
                49.105,
                49.11,
                49.115,
                49.121,
                49.125,
                49.13,
                49.136,
                49.141,
                49.146,
                49.151,
                49.156,
                49.161,
                49.166,
                49.171,
                49.176,
                49.182,
                49.186,
                49.191,
                49.196,
                49.202,
                49.207,
                49.212,
                49.217,
                49.221,
                49.226,
                49.232,
                49.237,
                49.242,
                49.247,
                49.251,
                49.256,
                49.261,
                49.267,
                49.272,
                49.277,
                49.282,
                49.286,
                49.291,
                49.296,
                49.301,
                49.306,
                49.311,
                49.316,
                49.321,
                49.326,
                49.331,
                49.336,
                49.341,
                49.346,
                49.35,
                49.355,
                49.36,
                49.365,
                49.37,
                49.375,
                49.38,
                49.384,
                49.389,
                49.394,
                49.399,
                49.404,
                49.408,
                49.413,
                49.418,
                49.423,
                49.427,
                49.432,
                49.437,
                49.442,
                49.446,
                49.451,
                49.456,
                49.461,
                49.466,
                49.47,
                49.475,
                49.479,
                49.484,
                49.489,
                49.494,
                49.499,
                49.504,
                49.508,
                49.512,
                49.517,
                49.522,
                49.527,
                49.532,
                49.536,
                49.54,
                49.545,
                49.55,
                49.555,
                49.559,
                49.564,
                49.569,
                49.573,
                49.578,
                49.582,
                49.587,
                49.592,
                49.596,
                49.601,
                49.605,
                49.61,
                49.614,
                49.619,
                49.624,
                49.629,
                49.633,
                49.637,
                49.642,
                49.646,
                49.651,
                49.656,
                49.66,
                49.665,
                49.669,
                49.673,
                49.678,
                49.683,
                49.687,
                49.692,
                49.696,
                49.701,
                49.705,
                49.709,
                49.714,
                49.718,
                49.723,
                49.727,
                49.732,
                49.736,
                49.74,
                49.745,
                49.749,
                49.754,
                49.758,
                49.763,
                49.766,
                49.771,
                49.775,
                49.78,
                49.784,
                49.789,
                49.793,
                49.797,
                49.801,
                49.806,
                49.81,
                49.815,
                49.819,
                49.823,
                49.828,
                49.832,
                49.836,
                49.84,
                49.845,
                49.849,
                49.853,
                49.858,
                49.861,
                49.866,
                49.87,
                49.874,
                49.879,
                49.883,
                49.887,
                49.892,
                49.895,
                49.9,
                49.904,
                49.908,
                49.912,
                49.917,
                49.921,
                49.924,
                49.929,
                49.933,
                49.937,
                49.942,
                49.946,
                49.95,
                49.954,
                49.958,
                49.962,
                49.966,
                49.97,
                49.974,
                49.979,
                49.983,
                49.986,
                49.99,
                49.995,
                49.999,
                50.003,
                50.007,
                50.011,
                50.015,
                50.019,
                50.023,
                50.027,
                50.031,
                50.035,
                50.039,
                50.043,
                50.047,
                50.051,
                50.055,
                50.059,
                50.063,
                50.067,
                50.071,
                50.075,
                50.079,
                50.082,
                50.086,
                50.09,
                50.094,
                50.098,
                50.102,
                50.106,
                50.11,
                50.114,
                50.118,
                50.121,
                50.125,
                50.129,
                50.133,
                50.137,
                50.141,
                50.144,
                50.148,
                50.152,
                50.156,
                50.16,
                50.164,
                50.168,
                50.171,
                50.175,
                50.179,
                50.183,
                50.187,
                50.19,
                50.194,
                50.198,
                50.202,
                50.205,
                50.209,
                50.213,
                50.216,
                50.22,
                50.224,
                50.228,
                50.232,
                50.235,
                50.239,
                50.242,
                50.246,
                50.25,
                50.254,
                50.257,
                50.261,
                50.264,
                50.268,
                50.272,
                50.275,
                50.279,
                50.283,
                50.286,
                50.29,
                50.293,
                50.297,
                50.3,
                50.304,
                50.308,
                50.311,
                50.315,
                50.319,
                50.322,
                50.325,
                50.329,
                50.333,
                50.336,
                50.34,
                50.343,
                50.347,
                50.351,
                50.354,
                50.357,
                50.361,
                50.364,
                50.368,
                50.371,
                50.375,
                50.379,
                50.381,
                50.385,
                50.388,
                50.392,
                50.396,
                50.399,
                50.403,
                50.406,
                50.409,
                50.412,
                50.416,
                50.419,
                50.423,
                50.426,
                50.43,
                50.433,
                50.437,
                50.44,
                50.443,
                50.446,
                50.45,
                50.453,
                50.457,
                50.46,
                50.464,
                50.466,
                50.47,
                50.473,
                50.477,
                50.48,
                50.483,
                50.487,
                50.49,
                50.494,
                50.496,
                50.5,
                50.503,
                50.506,
                50.51,
                50.513,
                50.516,
                50.52,
                50.523,
                50.526,
                50.529,
                50.532,
                50.536,
                50.539,
                50.542,
                50.546,
                50.549,
                50.552,
                50.555,
                50.558,
                50.561,
                50.565,
                50.568,
                50.571,
                50.574,
                50.578,
                50.58,
                50.583,
                50.587,
                50.59,
                50.593,
                50.596,
                50.6,
                50.603,
                50.606,
                50.608,
                50.612,
                50.615,
                50.618,
                50.621,
                50.624,
                50.628,
                50.631,
                50.634,
                50.636,
                50.64,
                50.643,
                50.646,
                50.649,
                50.652,
                50.655,
                50.659,
                50.662,
                50.664,
                50.667,
                50.67,
                50.673,
                50.677,
                50.68,
                50.683,
                50.686,
                50.689,
                50.691,
                50.694,
                50.698,
                50.701,
                50.704,
                50.707,
                50.71,
                50.713,
                50.716,
                50.718,
                50.721,
                50.724,
                50.727,
                50.73,
                50.733,
                50.736,
                50.739,
                50.743,
                50.746,
                50.748,
                50.751,
                50.754,
                50.757,
                50.76,
                50.763,
                50.766,
                50.769,
                50.772,
                50.774,
                50.777,
                50.78,
                50.783,
                50.786,
                50.789,
                50.792,
                50.795,
                50.798,
                50.8,
                50.803,
                50.806,
                50.809,
                50.812,
                50.815,
                50.817,
                50.82,
                50.823,
                50.825,
                50.828,
                50.831,
                50.834,
                50.837,
                50.84,
                50.843,
                50.846,
                50.849,
                50.852,
                50.854,
                50.857,
                50.859,
                50.862,
                50.865,
                50.868,
                50.871,
                50.874,
                50.877,
                50.879,
                50.881,
                50.884,
                50.887,
                50.89,
                50.893,
                50.896,
                50.898,
                50.901,
                50.904,
                50.906,
                50.909,
                50.912,
                50.914,
                50.917,
                50.92,
                50.923,
                50.926,
                50.928,
                50.931,
                50.933,
                50.936,
                50.939,
                50.941,
                50.944,
                50.947,
                50.95,
                50.952,
                50.955,
                50.957,
                50.96,
                50.963,
                50.965,
                50.968,
                50.971,
                50.974,
                50.976,
                50.979,
                50.982,
                50.984,
                50.986,
                50.989,
                50.992,
                50.994,
                50.997,
                51,
                51.002,
                51.005,
                51.008,
                51.01,
                51.012,
                51.015,
                51.018,
                51.02,
                51.023,
                51.026,
                51.028,
                51.031,
                51.034,
                51.035,
                51.038,
                51.041,
                51.043,
                51.046,
                51.049,
                51.051,
                51.054,
                51.056,
                51.059,
                51.061,
                51.063,
                51.066,
                51.069,
                51.071,
                51.074,
                51.076,
                51.079,
                51.082,
                51.084,
                51.086,
                51.089,
                51.091,
                51.094,
                51.096,
                51.099,
                51.101,
                51.104,
                51.107,
                51.109,
                51.111,
                51.113,
                51.116,
                51.118,
                51.121,
                51.124,
                51.126,
                51.129,
                51.131,
                51.134,
                51.135,
                51.138,
                51.14,
                51.143,
                51.145,
                51.148,
                51.15,
                51.153,
                51.155,
                51.158,
                51.16,
                51.162,
                51.165,
                51.167,
                51.17,
                51.172,
                51.174,
                51.177,
                51.179,
                51.182,
                51.184,
                51.186,
                51.188,
                51.191,
                51.193,
                51.196,
                51.198,
                51.201,
                51.203,
                51.205,
                51.208,
                51.21,
                51.212,
                51.214,
                51.217,
                51.219,
                51.222,
                51.224,
                51.226,
                51.229,
                51.231,
                51.234,
                51.235,
                51.238,
                51.24,
                51.243,
                51.245,
                51.247,
                51.25,
                51.252,
                51.254,
                51.257,
                51.258,
                51.261,
                51.263,
                51.265,
                51.268,
                51.27,
                51.273,
                51.275,
                51.277,
                51.28,
                51.282,
                51.283,
                51.286,
                51.288,
                51.29,
                51.293,
                51.295,
                51.297,
                51.3,
                51.302,
                51.304,
                51.306,
                51.308,
                51.311,
                51.313,
                51.315,
                51.317,
                51.32,
                51.322,
                51.324,
                51.327,
                51.329,
                51.33,
                51.333,
                51.335,
                51.337,
                51.339,
                51.342,
                51.344,
                51.346,
                51.349,
                51.351,
                51.353,
                51.354,
                51.357,
                51.359,
                51.361,
                51.364,
                51.366,
                51.368,
                51.37,
                51.373,
                51.375,
                51.377,
                51.378,
                51.381,
                51.383,
                51.385,
                51.387,
                51.39,
                51.392,
                51.394,
                51.396,
                51.398,
                51.401,
                51.402,
                51.404,
                51.406,
                51.409,
                51.411,
                51.413,
                51.415,
                51.417,
                51.42,
                51.422,
                51.424,
                51.425,
                51.427,
                51.43,
                51.432,
                51.434,
                51.436,
                51.438,
                51.44,
                51.443,
                51.445,
                51.447,
                51.448,
                51.45,
                51.453,
                51.455,
                51.457,
                51.459,
                51.461,
                51.463,
                51.465,
                51.468,
                51.47,
                51.471,
                51.473,
                51.475,
                51.477,
                51.48,
                51.482,
                51.484,
                51.486,
                51.488,
                51.49,
                51.492,
                51.494,
                51.496,
                51.498,
                51.5,
                51.502,
                51.504,
                51.506,
                51.508,
                51.51,
                51.512,
                51.515,
                51.517,
                51.518,
                51.52,
                51.522,
                51.524,
                51.526,
                51.528,
                51.53,
                51.532,
                51.534,
                51.536,
                51.539,
                51.54,
                51.542,
                51.544,
                51.546,
                51.548,
                51.55,
                51.552,
                51.554,
                51.556,
                51.558,
                51.56,
                51.562,
                51.564,
                51.566,
                51.568,
                51.57,
                51.572,
                51.574,
                51.576,
                51.578,
                51.58,
                51.582,
                51.584,
                51.585,
                51.587,
                51.589,
                51.591,
                51.593,
                51.595,
                51.597,
                51.599,
                51.601,
                51.603,
                51.605,
                51.607,
                51.608,
                51.61,
                51.612,
                51.614,
                51.616,
                51.618,
                51.62,
                51.622,
                51.624,
                51.626,
                51.628,
                51.63,
                51.631,
                51.633,
                51.635,
                51.637,
                51.639,
                51.641,
                51.643,
                51.645,
                51.647,
                51.649,
                51.651,
                51.652,
                51.654,
                51.656,
                51.658,
                51.66,
                51.662,
                51.664,
                51.666,
                51.667,
                51.669,
                51.671,
                51.673,
                51.674,
                51.676,
                51.678,
                51.68,
                51.682,
                51.684,
                51.686,
                51.688,
                51.69,
                51.692,
                51.694,
                51.696,
                51.697,
                51.698,
                51.7,
                51.702,
                51.704,
                51.706,
                51.708,
                51.71,
                51.712,
                51.714,
                51.716,
                51.718,
                51.719,
                51.72,
                51.722,
                51.724,
                51.726,
                51.728,
                51.73,
                51.732,
                51.734,
                51.736,
                51.737,
                51.739,
                51.74,
                51.742,
                51.744,
                51.746,
                51.748,
                51.75,
                51.752,
                51.753,
                51.755,
                51.757,
                51.759,
                51.761,
                51.762,
                51.764,
                51.766,
                51.767,
                51.769,
                51.771,
                51.773,
                51.775,
                51.777,
                51.779,
                51.78,
                51.782,
                51.784,
                51.785,
                51.787,
                51.789,
                51.791,
                51.792,
                51.794,
                51.796,
                51.798,
                51.8,
                51.802,
                51.803,
                51.805,
                51.806,
                51.808,
                51.81,
                51.812,
                51.813,
                51.815,
                51.817,
                51.819,
                51.821,
                51.822,
                51.824,
                51.826,
                51.827,
                51.829,
                51.831,
                51.832,
                51.834,
                51.836,
                51.838,
                51.84,
                51.841,
                51.843,
                51.845,
                51.847,
                51.849,
                51.85,
                51.851,
                51.853,
                51.855,
                51.857,
                51.858,
                51.86,
                51.862,
                51.864,
                51.866,
                51.867,
                51.869,
                51.87,
                51.872,
                51.874,
                51.875,
                51.877,
                51.879,
                51.881,
                51.882,
                51.884,
                51.886,
                51.888,
                51.889,
                51.891,
                51.892,
                51.894,
                51.896,
                51.897,
                51.899,
                51.901,
                51.903,
                51.904,
                51.906,
                51.908,
                51.91,
                51.911,
                51.913,
                51.914,
                51.916,
                51.917,
                51.919,
                51.921,
                51.923,
                51.924,
                51.926,
                51.928,
                51.93,
                51.931,
                51.933,
                51.935,
                51.936,
                51.937,
                51.939,
                51.941,
                51.942,
                51.944,
                51.946,
                51.948,
                51.949,
                51.951,
                51.953,
                51.954,
                51.956,
                51.957,
                51.959,
                51.96,
                51.962,
                51.964,
                51.965,
                51.967,
                51.969,
                51.971,
                51.972,
                51.974,
                51.976,
                51.977,
                51.978,
                51.98,
                51.982,
                51.983,
                51.985,
                51.987,
                51.988,
                51.99,
                51.992,
                51.993,
                51.995,
                51.997,
                51.998,
                51.999,
                52.001,
                52.003,
                52.004,
                52.006,
                52.008,
                52.009,
                52.011,
                52.013,
                52.014,
                52.016,
                52.017,
                52.019,
                52.02,
                52.022,
                52.023,
                52.025,
                52.027,
                52.028,
                52.03,
                52.032,
                52.033,
                52.035,
                52.037,
                52.038,
                52.04,
                52.041,
                52.042,
                52.044,
                52.046,
                52.047,
                52.049,
                52.05,
                52.052,
                52.054,
                52.055,
                52.057,
                52.059,
                52.06,
                52.061,
                52.063,
                52.064,
                52.066,
                52.068,
                52.069,
                52.071,
                52.072,
                52.074,
                52.076,
                52.077,
                52.079,
                52.08,
                52.082,
                52.083,
                52.084,
                52.086,
                52.088,
                52.089,
                52.091,
                52.093,
                52.094,
                52.096,
                52.097,
                52.099,
                52.101,
                52.102,
                52.103,
                52.105,
                52.106,
                52.108,
                52.109,
                52.111,
                52.113,
                52.114,
                52.116,
                52.117,
                52.119,
                52.12,
                52.122,
                52.124,
                52.124,
                52.126,
                52.128,
                52.129,
                52.131,
                52.132,
                52.134,
                52.135,
                52.137,
                52.139,
                52.14,
                52.142,
                52.143,
                52.144,
                52.146,
                52.147,
                52.149,
                52.15,
                52.152,
                52.154,
                52.155,
                52.157,
                52.158,
                52.16,
                52.161,
                52.163,
                52.164,
                52.165,
                52.167,
                52.168,
                52.17,
                52.171,
                52.173,
                52.174,
                52.176,
                52.178,
                52.179,
                52.181,
                52.182,
                52.184,
                52.185,
                52.186,
                52.188,
                52.189,
                52.191,
                52.192,
                52.194,
                52.195,
                52.197,
                52.198,
                52.2,
                52.201,
                52.203,
                52.205,
                52.206,
                52.207,
                52.208,
                52.21,
                52.211,
                52.213,
                52.214,
                52.216,
                52.218,
                52.219,
                52.221,
                52.222,
                52.224,
                52.225,
                52.227,
                52.227,
                52.229,
                52.23,
                52.232,
                52.233,
                52.235,
                52.236,
                52.238,
                52.24,
                52.241,
                52.243,
                52.244,
                52.245,
                52.247,
                52.248,
                52.249,
                52.251,
                52.252,
                52.254,
                52.255,
                52.257,
                52.258,
                52.26,
                52.261,
                52.263,
                52.264,
                52.266,
                52.267,
                52.268,
                52.269,
                52.271,
                52.272,
                52.274,
                52.275,
                52.277,
                52.279,
                52.28,
                52.281,
                52.283,
                52.284,
                52.286,
                52.287,
                52.288,
                52.29,
                52.291,
                52.293,
                52.294,
                52.295,
                52.297,
                52.299,
                52.3,
                52.301,
                52.303,
                52.304,
                52.306
            ],
            P97: [
                36.106,
                36.198,
                36.289,
                36.381,
                36.472,
                36.563,
                36.654,
                36.746,
                36.836,
                36.928,
                37.018,
                37.109,
                37.2,
                37.291,
                37.381,
                37.479,
                37.574,
                37.668,
                37.759,
                37.848,
                37.935,
                38.021,
                38.105,
                38.187,
                38.267,
                38.347,
                38.424,
                38.501,
                38.576,
                38.649,
                38.722,
                38.793,
                38.863,
                38.932,
                39,
                39.066,
                39.132,
                39.197,
                39.261,
                39.324,
                39.386,
                39.448,
                39.508,
                39.568,
                39.627,
                39.685,
                39.743,
                39.8,
                39.856,
                39.912,
                39.967,
                40.021,
                40.075,
                40.129,
                40.182,
                40.234,
                40.285,
                40.337,
                40.388,
                40.438,
                40.488,
                40.538,
                40.587,
                40.635,
                40.683,
                40.731,
                40.778,
                40.825,
                40.872,
                40.918,
                40.964,
                41.01,
                41.055,
                41.1,
                41.145,
                41.189,
                41.233,
                41.276,
                41.319,
                41.362,
                41.405,
                41.448,
                41.49,
                41.531,
                41.573,
                41.614,
                41.654,
                41.696,
                41.736,
                41.776,
                41.815,
                41.855,
                41.895,
                41.934,
                41.973,
                42.011,
                42.05,
                42.088,
                42.126,
                42.163,
                42.201,
                42.238,
                42.275,
                42.312,
                42.349,
                42.385,
                42.421,
                42.457,
                42.493,
                42.528,
                42.564,
                42.598,
                42.633,
                42.668,
                42.702,
                42.736,
                42.771,
                42.804,
                42.838,
                42.872,
                42.905,
                42.938,
                42.971,
                43.003,
                43.036,
                43.068,
                43.1,
                43.132,
                43.163,
                43.195,
                43.226,
                43.258,
                43.289,
                43.319,
                43.35,
                43.38,
                43.411,
                43.441,
                43.471,
                43.501,
                43.53,
                43.56,
                43.589,
                43.618,
                43.647,
                43.676,
                43.704,
                43.733,
                43.761,
                43.79,
                43.817,
                43.845,
                43.873,
                43.9,
                43.927,
                43.955,
                43.982,
                44.008,
                44.035,
                44.061,
                44.088,
                44.115,
                44.141,
                44.167,
                44.193,
                44.219,
                44.244,
                44.27,
                44.295,
                44.32,
                44.345,
                44.37,
                44.395,
                44.42,
                44.444,
                44.468,
                44.492,
                44.517,
                44.54,
                44.565,
                44.589,
                44.612,
                44.636,
                44.658,
                44.682,
                44.705,
                44.728,
                44.75,
                44.774,
                44.796,
                44.819,
                44.841,
                44.863,
                44.886,
                44.907,
                44.929,
                44.951,
                44.973,
                44.994,
                45.015,
                45.037,
                45.058,
                45.079,
                45.101,
                45.121,
                45.142,
                45.162,
                45.183,
                45.204,
                45.224,
                45.244,
                45.264,
                45.285,
                45.305,
                45.324,
                45.344,
                45.363,
                45.383,
                45.403,
                45.422,
                45.441,
                45.461,
                45.479,
                45.498,
                45.517,
                45.536,
                45.555,
                45.573,
                45.591,
                45.61,
                45.628,
                45.646,
                45.664,
                45.682,
                45.7,
                45.718,
                45.736,
                45.754,
                45.771,
                45.788,
                45.806,
                45.823,
                45.84,
                45.857,
                45.874,
                45.891,
                45.908,
                45.925,
                45.942,
                45.958,
                45.974,
                45.991,
                46.007,
                46.024,
                46.04,
                46.056,
                46.072,
                46.088,
                46.104,
                46.12,
                46.136,
                46.151,
                46.166,
                46.182,
                46.197,
                46.213,
                46.228,
                46.243,
                46.258,
                46.274,
                46.288,
                46.303,
                46.318,
                46.333,
                46.348,
                46.363,
                46.377,
                46.391,
                46.406,
                46.42,
                46.434,
                46.449,
                46.463,
                46.477,
                46.491,
                46.505,
                46.519,
                46.533,
                46.547,
                46.56,
                46.574,
                46.588,
                46.601,
                46.615,
                46.628,
                46.641,
                46.655,
                46.668,
                46.681,
                46.694,
                46.707,
                46.721,
                46.733,
                46.746,
                46.759,
                46.772,
                46.785,
                46.797,
                46.81,
                46.822,
                46.835,
                46.848,
                46.86,
                46.872,
                46.885,
                46.896,
                46.909,
                46.921,
                46.934,
                46.945,
                46.957,
                46.969,
                46.981,
                46.993,
                47.005,
                47.017,
                47.028,
                47.04,
                47.052,
                47.063,
                47.074,
                47.086,
                47.098,
                47.108,
                47.12,
                47.132,
                47.143,
                47.154,
                47.165,
                47.176,
                47.188,
                47.198,
                47.209,
                47.22,
                47.231,
                47.242,
                47.253,
                47.264,
                47.274,
                47.285,
                47.295,
                47.306,
                47.316,
                47.327,
                47.338,
                47.348,
                47.358,
                47.368,
                47.379,
                47.389,
                47.399,
                47.41,
                47.42,
                47.429,
                47.44,
                47.45,
                47.46,
                47.469,
                47.48,
                47.49,
                47.5,
                47.509,
                47.519,
                47.529,
                47.539,
                47.548,
                47.558,
                47.568,
                47.577,
                47.586,
                47.596,
                47.606,
                47.615,
                47.624,
                47.633,
                47.643,
                47.652,
                47.661,
                47.671,
                47.68,
                47.689,
                47.698,
                47.707,
                47.716,
                47.726,
                47.734,
                47.743,
                47.752,
                47.761,
                47.771,
                47.779,
                47.788,
                47.797,
                47.806,
                47.814,
                47.823,
                47.832,
                47.84,
                47.848,
                47.857,
                47.866,
                47.875,
                47.883,
                47.891,
                47.9,
                47.908,
                47.917,
                47.925,
                47.933,
                47.942,
                47.95,
                47.958,
                47.966,
                47.975,
                47.983,
                47.991,
                47.999,
                48.007,
                48.015,
                48.024,
                48.031,
                48.039,
                48.047,
                48.056,
                48.063,
                48.071,
                48.079,
                48.087,
                48.095,
                48.102,
                48.11,
                48.118,
                48.126,
                48.133,
                48.141,
                48.149,
                48.157,
                48.164,
                48.171,
                48.179,
                48.187,
                48.195,
                48.202,
                48.209,
                48.217,
                48.224,
                48.232,
                48.239,
                48.246,
                48.254,
                48.261,
                48.269,
                48.275,
                48.283,
                48.29,
                48.298,
                48.305,
                48.312,
                48.319,
                48.326,
                48.334,
                48.341,
                48.347,
                48.355,
                48.362,
                48.369,
                48.376,
                48.383,
                48.39,
                48.397,
                48.404,
                48.411,
                48.418,
                48.425,
                48.432,
                48.439,
                48.445,
                48.452,
                48.459,
                48.466,
                48.473,
                48.479,
                48.486,
                48.493,
                48.5,
                48.507,
                48.513,
                48.519,
                48.526,
                48.533,
                48.54,
                48.546,
                48.553,
                48.559,
                48.566,
                48.573,
                48.579,
                48.585,
                48.592,
                48.599,
                48.605,
                48.611,
                48.618,
                48.624,
                48.631,
                48.638,
                48.643,
                48.65,
                48.656,
                48.663,
                48.669,
                48.675,
                48.681,
                48.688,
                48.694,
                48.701,
                48.707,
                48.713,
                48.719,
                48.726,
                48.732,
                48.738,
                48.744,
                48.75,
                48.756,
                48.763,
                48.769,
                48.774,
                48.781,
                48.787,
                48.793,
                48.799,
                48.806,
                48.811,
                48.817,
                48.823,
                48.83,
                48.836,
                48.841,
                48.847,
                48.853,
                48.859,
                48.866,
                48.871,
                48.877,
                48.883,
                48.889,
                48.895,
                48.901,
                48.906,
                48.912,
                48.918,
                48.924,
                48.93,
                48.935,
                48.941,
                48.947,
                48.953,
                48.959,
                48.965,
                48.97,
                48.976,
                48.982,
                48.988,
                48.994,
                49,
                49.005,
                49.011,
                49.017,
                49.022,
                49.028,
                49.033,
                49.039,
                49.045,
                49.051,
                49.057,
                49.062,
                49.067,
                49.073,
                49.079,
                49.084,
                49.09,
                49.096,
                49.101,
                49.107,
                49.112,
                49.118,
                49.124,
                49.128,
                49.134,
                49.14,
                49.145,
                49.151,
                49.157,
                49.161,
                49.167,
                49.173,
                49.178,
                49.184,
                49.19,
                49.194,
                49.2,
                49.205,
                49.211,
                49.217,
                49.222,
                49.227,
                49.232,
                49.238,
                49.243,
                49.249,
                49.254,
                49.259,
                49.265,
                49.27,
                49.276,
                49.281,
                49.286,
                49.291,
                49.297,
                49.302,
                49.307,
                49.313,
                49.318,
                49.323,
                49.328,
                49.334,
                49.339,
                49.345,
                49.35,
                49.355,
                49.36,
                49.365,
                49.371,
                49.376,
                49.381,
                49.386,
                49.391,
                49.396,
                49.402,
                49.407,
                49.413,
                49.418,
                49.422,
                49.428,
                49.433,
                49.438,
                49.443,
                49.449,
                49.453,
                49.458,
                49.464,
                49.469,
                49.474,
                49.479,
                49.484,
                49.489,
                49.494,
                49.499,
                49.505,
                49.51,
                49.514,
                49.519,
                49.525,
                49.53,
                49.535,
                49.54,
                49.545,
                49.55,
                49.555,
                49.56,
                49.565,
                49.57,
                49.575,
                49.58,
                49.585,
                49.59,
                49.595,
                49.6,
                49.605,
                49.61,
                49.615,
                49.62,
                49.625,
                49.63,
                49.635,
                49.64,
                49.644,
                49.649,
                49.654,
                49.659,
                49.664,
                49.669,
                49.674,
                49.679,
                49.684,
                49.689,
                49.694,
                49.699,
                49.704,
                49.709,
                49.713,
                49.718,
                49.723,
                49.728,
                49.733,
                49.738,
                49.742,
                49.747,
                49.752,
                49.757,
                49.761,
                49.766,
                49.771,
                49.775,
                49.78,
                49.785,
                49.79,
                49.795,
                49.8,
                49.805,
                49.809,
                49.814,
                49.818,
                49.823,
                49.828,
                49.833,
                49.838,
                49.842,
                49.847,
                49.851,
                49.856,
                49.861,
                49.866,
                49.87,
                49.875,
                49.879,
                49.884,
                49.889,
                49.894,
                49.899,
                49.902,
                49.907,
                49.912,
                49.917,
                49.921,
                49.926,
                49.931,
                49.935,
                49.939,
                49.944,
                49.949,
                49.954,
                49.958,
                49.963,
                49.967,
                49.971,
                49.976,
                49.981,
                49.985,
                49.99,
                49.995,
                49.999,
                50.003,
                50.008,
                50.012,
                50.017,
                50.022,
                50.026,
                50.031,
                50.035,
                50.039,
                50.044,
                50.048,
                50.053,
                50.057,
                50.062,
                50.066,
                50.07,
                50.075,
                50.079,
                50.084,
                50.088,
                50.093,
                50.097,
                50.101,
                50.106,
                50.11,
                50.115,
                50.119,
                50.124,
                50.127,
                50.132,
                50.136,
                50.141,
                50.145,
                50.149,
                50.154,
                50.158,
                50.162,
                50.166,
                50.171,
                50.175,
                50.18,
                50.184,
                50.188,
                50.192,
                50.196,
                50.201,
                50.205,
                50.209,
                50.214,
                50.218,
                50.222,
                50.226,
                50.23,
                50.234,
                50.239,
                50.243,
                50.247,
                50.252,
                50.255,
                50.259,
                50.264,
                50.268,
                50.272,
                50.277,
                50.281,
                50.285,
                50.288,
                50.293,
                50.297,
                50.301,
                50.305,
                50.31,
                50.314,
                50.317,
                50.321,
                50.326,
                50.33,
                50.334,
                50.338,
                50.342,
                50.346,
                50.35,
                50.354,
                50.358,
                50.362,
                50.366,
                50.37,
                50.374,
                50.379,
                50.382,
                50.386,
                50.39,
                50.394,
                50.398,
                50.402,
                50.406,
                50.41,
                50.413,
                50.417,
                50.422,
                50.426,
                50.43,
                50.434,
                50.438,
                50.442,
                50.445,
                50.449,
                50.453,
                50.457,
                50.461,
                50.465,
                50.469,
                50.473,
                50.476,
                50.48,
                50.484,
                50.488,
                50.492,
                50.495,
                50.499,
                50.502,
                50.506,
                50.51,
                50.514,
                50.518,
                50.522,
                50.526,
                50.53,
                50.534,
                50.536,
                50.54,
                50.544,
                50.548,
                50.552,
                50.556,
                50.56,
                50.563,
                50.566,
                50.57,
                50.574,
                50.578,
                50.582,
                50.585,
                50.589,
                50.593,
                50.596,
                50.6,
                50.603,
                50.607,
                50.611,
                50.615,
                50.618,
                50.622,
                50.625,
                50.629,
                50.632,
                50.636,
                50.64,
                50.643,
                50.647,
                50.651,
                50.653,
                50.657,
                50.661,
                50.665,
                50.668,
                50.672,
                50.675,
                50.679,
                50.683,
                50.685,
                50.689,
                50.693,
                50.696,
                50.7,
                50.704,
                50.707,
                50.711,
                50.713,
                50.717,
                50.721,
                50.724,
                50.728,
                50.731,
                50.735,
                50.738,
                50.741,
                50.745,
                50.748,
                50.752,
                50.755,
                50.759,
                50.762,
                50.766,
                50.769,
                50.772,
                50.775,
                50.779,
                50.782,
                50.786,
                50.789,
                50.793,
                50.796,
                50.799,
                50.802,
                50.806,
                50.809,
                50.812,
                50.816,
                50.819,
                50.823,
                50.826,
                50.829,
                50.832,
                50.835,
                50.839,
                50.842,
                50.846,
                50.849,
                50.852,
                50.856,
                50.858,
                50.861,
                50.865,
                50.868,
                50.871,
                50.875,
                50.878,
                50.881,
                50.885,
                50.887,
                50.891,
                50.894,
                50.897,
                50.901,
                50.904,
                50.907,
                50.91,
                50.913,
                50.916,
                50.919,
                50.922,
                50.926,
                50.929,
                50.932,
                50.936,
                50.939,
                50.941,
                50.944,
                50.948,
                50.951,
                50.954,
                50.957,
                50.961,
                50.964,
                50.967,
                50.969,
                50.972,
                50.976,
                50.979,
                50.982,
                50.985,
                50.988,
                50.991,
                50.995,
                50.997,
                51,
                51.003,
                51.006,
                51.009,
                51.013,
                51.016,
                51.019,
                51.022,
                51.024,
                51.027,
                51.03,
                51.034,
                51.037,
                51.04,
                51.043,
                51.046,
                51.049,
                51.051,
                51.054,
                51.057,
                51.06,
                51.063,
                51.066,
                51.07,
                51.073,
                51.076,
                51.079,
                51.081,
                51.084,
                51.087,
                51.09,
                51.093,
                51.096,
                51.099,
                51.102,
                51.105,
                51.107,
                51.11,
                51.113,
                51.116,
                51.119,
                51.122,
                51.125,
                51.128,
                51.131,
                51.133,
                51.136,
                51.139,
                51.142,
                51.145,
                51.148,
                51.151,
                51.154,
                51.157,
                51.159,
                51.162,
                51.165,
                51.167,
                51.17,
                51.173,
                51.176,
                51.179,
                51.182,
                51.185,
                51.187,
                51.19,
                51.193,
                51.196,
                51.199,
                51.201,
                51.204,
                51.207,
                51.21,
                51.212,
                51.215,
                51.218,
                51.22,
                51.223,
                51.226,
                51.229,
                51.232,
                51.235,
                51.238,
                51.239,
                51.242,
                51.245,
                51.248,
                51.251,
                51.254,
                51.256,
                51.259,
                51.262,
                51.265,
                51.267,
                51.269,
                51.272,
                51.275,
                51.278,
                51.28,
                51.283,
                51.286,
                51.289,
                51.291,
                51.293,
                51.296,
                51.299,
                51.302,
                51.304,
                51.307,
                51.31,
                51.313,
                51.315,
                51.317,
                51.32,
                51.323,
                51.325,
                51.328,
                51.331,
                51.333,
                51.336,
                51.339,
                51.341,
                51.343,
                51.346,
                51.349,
                51.351,
                51.354,
                51.357,
                51.359,
                51.362,
                51.365,
                51.367,
                51.369,
                51.372,
                51.374,
                51.377,
                51.38,
                51.382,
                51.385,
                51.388,
                51.39,
                51.393,
                51.395,
                51.397,
                51.4,
                51.403,
                51.405,
                51.408,
                51.41,
                51.413,
                51.416,
                51.418,
                51.42,
                51.423,
                51.425,
                51.428,
                51.43,
                51.433,
                51.435,
                51.438,
                51.441,
                51.443,
                51.445,
                51.447,
                51.45,
                51.452,
                51.455,
                51.458,
                51.46,
                51.463,
                51.465,
                51.468,
                51.469,
                51.472,
                51.474,
                51.477,
                51.479,
                51.482,
                51.484,
                51.487,
                51.49,
                51.492,
                51.494,
                51.496,
                51.499,
                51.501,
                51.504,
                51.506,
                51.509,
                51.511,
                51.514,
                51.516,
                51.518,
                51.52,
                51.523,
                51.525,
                51.527,
                51.53,
                51.532,
                51.535,
                51.537,
                51.54,
                51.542,
                51.544,
                51.546,
                51.549,
                51.551,
                51.553,
                51.556,
                51.558,
                51.561,
                51.563,
                51.566,
                51.568,
                51.569,
                51.572,
                51.574,
                51.577,
                51.579,
                51.582,
                51.584,
                51.586,
                51.589,
                51.591,
                51.593,
                51.595,
                51.597,
                51.6,
                51.602,
                51.604,
                51.607,
                51.609,
                51.612,
                51.614,
                51.616,
                51.618,
                51.62,
                51.622,
                51.625,
                51.627,
                51.629,
                51.632,
                51.634,
                51.636,
                51.639,
                51.64,
                51.643,
                51.645,
                51.647,
                51.649,
                51.652,
                51.654,
                51.656,
                51.659,
                51.661,
                51.663,
                51.665,
                51.667,
                51.669,
                51.672,
                51.674,
                51.676,
                51.678,
                51.681,
                51.683,
                51.685,
                51.688,
                51.689,
                51.691,
                51.693,
                51.696,
                51.698,
                51.7,
                51.702,
                51.705,
                51.707,
                51.709,
                51.712,
                51.713,
                51.715,
                51.717,
                51.72,
                51.722,
                51.724,
                51.726,
                51.728,
                51.731,
                51.733,
                51.735,
                51.736,
                51.739,
                51.741,
                51.743,
                51.745,
                51.748,
                51.75,
                51.752,
                51.754,
                51.756,
                51.759,
                51.76,
                51.762,
                51.764,
                51.766,
                51.769,
                51.771,
                51.773,
                51.775,
                51.777,
                51.779,
                51.782,
                51.783,
                51.785,
                51.787,
                51.789,
                51.791,
                51.794,
                51.796,
                51.798,
                51.8,
                51.802,
                51.804,
                51.806,
                51.808,
                51.81,
                51.812,
                51.814,
                51.816,
                51.818,
                51.821,
                51.823,
                51.825,
                51.827,
                51.828,
                51.83,
                51.832,
                51.835,
                51.837,
                51.839,
                51.841,
                51.843,
                51.845,
                51.847,
                51.849,
                51.851,
                51.852,
                51.855,
                51.857,
                51.859,
                51.861,
                51.863,
                51.865,
                51.867,
                51.869,
                51.871,
                51.873,
                51.875,
                51.877,
                51.879,
                51.881,
                51.883,
                51.885,
                51.887,
                51.889,
                51.891,
                51.893,
                51.895,
                51.897,
                51.898,
                51.9,
                51.902,
                51.904,
                51.906,
                51.909,
                51.911,
                51.913,
                51.915,
                51.917,
                51.919,
                51.92,
                51.922,
                51.924,
                51.926,
                51.928,
                51.93,
                51.932,
                51.934,
                51.936,
                51.938,
                51.94,
                51.942,
                51.943,
                51.945,
                51.947,
                51.949,
                51.951,
                51.953,
                51.955,
                51.957,
                51.959,
                51.961,
                51.963,
                51.965,
                51.966,
                51.968,
                51.97,
                51.972,
                51.974,
                51.976,
                51.978,
                51.98,
                51.982,
                51.984,
                51.986,
                51.987,
                51.989,
                51.991,
                51.993,
                51.995,
                51.997,
                51.999,
                52.001,
                52.002,
                52.004,
                52.006,
                52.008,
                52.009,
                52.011,
                52.013,
                52.015,
                52.017,
                52.019,
                52.021,
                52.023,
                52.025,
                52.027,
                52.029,
                52.031,
                52.032,
                52.033,
                52.035,
                52.037,
                52.039,
                52.041,
                52.043,
                52.045,
                52.047,
                52.049,
                52.051,
                52.053,
                52.054,
                52.055,
                52.057,
                52.059,
                52.061,
                52.063,
                52.065,
                52.067,
                52.069,
                52.071,
                52.073,
                52.074,
                52.075,
                52.077,
                52.079,
                52.081,
                52.083,
                52.085,
                52.087,
                52.089,
                52.09,
                52.092,
                52.094,
                52.096,
                52.097,
                52.099,
                52.101,
                52.103,
                52.104,
                52.106,
                52.108,
                52.11,
                52.112,
                52.114,
                52.116,
                52.117,
                52.119,
                52.12,
                52.122,
                52.124,
                52.126,
                52.128,
                52.129,
                52.131,
                52.133,
                52.135,
                52.137,
                52.139,
                52.14,
                52.141,
                52.143,
                52.145,
                52.147,
                52.149,
                52.15,
                52.152,
                52.154,
                52.156,
                52.158,
                52.159,
                52.161,
                52.162,
                52.164,
                52.166,
                52.168,
                52.169,
                52.171,
                52.173,
                52.175,
                52.177,
                52.178,
                52.18,
                52.182,
                52.184,
                52.185,
                52.187,
                52.188,
                52.19,
                52.192,
                52.194,
                52.195,
                52.197,
                52.199,
                52.201,
                52.203,
                52.204,
                52.205,
                52.207,
                52.209,
                52.211,
                52.212,
                52.214,
                52.216,
                52.218,
                52.219,
                52.221,
                52.223,
                52.225,
                52.226,
                52.227,
                52.229,
                52.231,
                52.233,
                52.234,
                52.236,
                52.238,
                52.24,
                52.241,
                52.243,
                52.245,
                52.247,
                52.248,
                52.249,
                52.251,
                52.253,
                52.254,
                52.256,
                52.258,
                52.26,
                52.261,
                52.263,
                52.265,
                52.267,
                52.268,
                52.27,
                52.271,
                52.273,
                52.274,
                52.276,
                52.278,
                52.28,
                52.281,
                52.283,
                52.285,
                52.286,
                52.288,
                52.29,
                52.292,
                52.292,
                52.294,
                52.296,
                52.297,
                52.299,
                52.301,
                52.303,
                52.304,
                52.306,
                52.308,
                52.309,
                52.311,
                52.313,
                52.313,
                52.315,
                52.317,
                52.319,
                52.32,
                52.322,
                52.324,
                52.325,
                52.327,
                52.329,
                52.33,
                52.332,
                52.334,
                52.335,
                52.336,
                52.338,
                52.34,
                52.341,
                52.343,
                52.345,
                52.346,
                52.348,
                52.35,
                52.351,
                52.353,
                52.355,
                52.355,
                52.357,
                52.359,
                52.36,
                52.362,
                52.364,
                52.365,
                52.367,
                52.369,
                52.37,
                52.372,
                52.374,
                52.375,
                52.376,
                52.378,
                52.379,
                52.381,
                52.383,
                52.384,
                52.386,
                52.388,
                52.389,
                52.391,
                52.392,
                52.394,
                52.396,
                52.396,
                52.398,
                52.4,
                52.401,
                52.403,
                52.405,
                52.406,
                52.408,
                52.41,
                52.411,
                52.413,
                52.414,
                52.416,
                52.418,
                52.418,
                52.42,
                52.422,
                52.423,
                52.425,
                52.426,
                52.428,
                52.43,
                52.431,
                52.433,
                52.434,
                52.436,
                52.438,
                52.438,
                52.44,
                52.442,
                52.443,
                52.445,
                52.446,
                52.448,
                52.45,
                52.451,
                52.453,
                52.454,
                52.456,
                52.458,
                52.459,
                52.46,
                52.461,
                52.463,
                52.465,
                52.466,
                52.468,
                52.469,
                52.471,
                52.473,
                52.474,
                52.476,
                52.477,
                52.479,
                52.48,
                52.481,
                52.483,
                52.484,
                52.486,
                52.487,
                52.489,
                52.491,
                52.492,
                52.494,
                52.495,
                52.497,
                52.498,
                52.5,
                52.501,
                52.502,
                52.504,
                52.505,
                52.507,
                52.508,
                52.51,
                52.512,
                52.513,
                52.515,
                52.516,
                52.518,
                52.519,
                52.521,
                52.522,
                52.523,
                52.525,
                52.526,
                52.528,
                52.529,
                52.531,
                52.532,
                52.534,
                52.536,
                52.537,
                52.539,
                52.54,
                52.542,
                52.542,
                52.544,
                52.545,
                52.547,
                52.549,
                52.55,
                52.552,
                52.553,
                52.555,
                52.556,
                52.558,
                52.559,
                52.561,
                52.562,
                52.563,
                52.564,
                52.566,
                52.567,
                52.569,
                52.571,
                52.572,
                52.574,
                52.575,
                52.577,
                52.578,
                52.58,
                52.581,
                52.583,
                52.583,
                52.585,
                52.586,
                52.588,
                52.589,
                52.591,
                52.592,
                52.594,
                52.595,
                52.597,
                52.598,
                52.6,
                52.602,
                52.603,
                52.604,
                52.605,
                52.607,
                52.608,
                52.61,
                52.611,
                52.613,
                52.614,
                52.616,
                52.617,
                52.619,
                52.62,
                52.622,
                52.623,
                52.624,
                52.625,
                52.627,
                52.628,
                52.63,
                52.631,
                52.633,
                52.634,
                52.636,
                52.637,
                52.639,
                52.64,
                52.642
            ],
            P99: [
                36.634,
                36.725,
                36.815,
                36.905,
                36.995,
                37.085,
                37.175,
                37.265,
                37.354,
                37.445,
                37.534,
                37.624,
                37.713,
                37.803,
                37.891,
                37.99,
                38.086,
                38.18,
                38.272,
                38.362,
                38.45,
                38.537,
                38.621,
                38.705,
                38.786,
                38.866,
                38.944,
                39.021,
                39.097,
                39.171,
                39.244,
                39.316,
                39.386,
                39.457,
                39.525,
                39.592,
                39.658,
                39.724,
                39.788,
                39.852,
                39.915,
                39.977,
                40.038,
                40.099,
                40.158,
                40.217,
                40.276,
                40.332,
                40.39,
                40.446,
                40.501,
                40.556,
                40.611,
                40.665,
                40.718,
                40.771,
                40.823,
                40.875,
                40.926,
                40.977,
                41.028,
                41.078,
                41.127,
                41.176,
                41.224,
                41.272,
                41.32,
                41.368,
                41.415,
                41.462,
                41.508,
                41.555,
                41.601,
                41.646,
                41.691,
                41.735,
                41.779,
                41.823,
                41.867,
                41.91,
                41.953,
                41.997,
                42.039,
                42.081,
                42.123,
                42.164,
                42.205,
                42.247,
                42.288,
                42.328,
                42.368,
                42.408,
                42.448,
                42.488,
                42.527,
                42.566,
                42.605,
                42.643,
                42.681,
                42.719,
                42.758,
                42.795,
                42.832,
                42.869,
                42.907,
                42.943,
                42.979,
                43.016,
                43.052,
                43.087,
                43.124,
                43.159,
                43.194,
                43.229,
                43.264,
                43.298,
                43.333,
                43.367,
                43.4,
                43.435,
                43.468,
                43.502,
                43.535,
                43.567,
                43.601,
                43.633,
                43.666,
                43.698,
                43.729,
                43.762,
                43.793,
                43.825,
                43.856,
                43.887,
                43.918,
                43.948,
                43.98,
                44.01,
                44.04,
                44.07,
                44.099,
                44.13,
                44.159,
                44.189,
                44.217,
                44.247,
                44.275,
                44.305,
                44.333,
                44.362,
                44.389,
                44.418,
                44.445,
                44.474,
                44.501,
                44.529,
                44.556,
                44.582,
                44.61,
                44.636,
                44.663,
                44.69,
                44.716,
                44.743,
                44.769,
                44.795,
                44.821,
                44.847,
                44.872,
                44.898,
                44.922,
                44.948,
                44.973,
                44.998,
                45.022,
                45.047,
                45.071,
                45.096,
                45.12,
                45.144,
                45.169,
                45.192,
                45.216,
                45.239,
                45.263,
                45.286,
                45.309,
                45.332,
                45.355,
                45.378,
                45.401,
                45.424,
                45.445,
                45.468,
                45.49,
                45.512,
                45.535,
                45.556,
                45.578,
                45.599,
                45.621,
                45.642,
                45.664,
                45.686,
                45.706,
                45.727,
                45.748,
                45.769,
                45.79,
                45.81,
                45.831,
                45.85,
                45.871,
                45.891,
                45.911,
                45.931,
                45.95,
                45.971,
                45.99,
                46.009,
                46.029,
                46.049,
                46.067,
                46.087,
                46.105,
                46.125,
                46.144,
                46.162,
                46.181,
                46.2,
                46.218,
                46.236,
                46.254,
                46.272,
                46.291,
                46.308,
                46.326,
                46.344,
                46.362,
                46.379,
                46.397,
                46.414,
                46.432,
                46.448,
                46.466,
                46.483,
                46.5,
                46.517,
                46.534,
                46.55,
                46.567,
                46.584,
                46.6,
                46.617,
                46.634,
                46.649,
                46.666,
                46.682,
                46.698,
                46.714,
                46.73,
                46.745,
                46.761,
                46.777,
                46.792,
                46.808,
                46.824,
                46.838,
                46.854,
                46.869,
                46.884,
                46.899,
                46.915,
                46.929,
                46.944,
                46.959,
                46.973,
                46.988,
                47.003,
                47.017,
                47.031,
                47.046,
                47.06,
                47.074,
                47.089,
                47.102,
                47.117,
                47.131,
                47.145,
                47.158,
                47.172,
                47.186,
                47.199,
                47.213,
                47.227,
                47.24,
                47.254,
                47.267,
                47.28,
                47.293,
                47.307,
                47.32,
                47.333,
                47.346,
                47.359,
                47.371,
                47.385,
                47.398,
                47.411,
                47.423,
                47.436,
                47.448,
                47.46,
                47.473,
                47.486,
                47.497,
                47.51,
                47.522,
                47.535,
                47.546,
                47.559,
                47.571,
                47.582,
                47.595,
                47.607,
                47.619,
                47.63,
                47.642,
                47.654,
                47.666,
                47.677,
                47.689,
                47.7,
                47.711,
                47.723,
                47.735,
                47.746,
                47.757,
                47.768,
                47.78,
                47.791,
                47.801,
                47.813,
                47.824,
                47.834,
                47.845,
                47.857,
                47.868,
                47.878,
                47.889,
                47.9,
                47.911,
                47.921,
                47.931,
                47.942,
                47.953,
                47.963,
                47.973,
                47.984,
                47.994,
                48.004,
                48.015,
                48.025,
                48.035,
                48.045,
                48.055,
                48.066,
                48.075,
                48.085,
                48.096,
                48.106,
                48.115,
                48.125,
                48.135,
                48.145,
                48.154,
                48.164,
                48.174,
                48.184,
                48.193,
                48.202,
                48.212,
                48.222,
                48.231,
                48.24,
                48.25,
                48.26,
                48.268,
                48.278,
                48.287,
                48.297,
                48.305,
                48.315,
                48.324,
                48.333,
                48.342,
                48.351,
                48.36,
                48.369,
                48.379,
                48.387,
                48.396,
                48.405,
                48.414,
                48.422,
                48.431,
                48.44,
                48.449,
                48.457,
                48.466,
                48.475,
                48.483,
                48.491,
                48.5,
                48.509,
                48.517,
                48.526,
                48.534,
                48.542,
                48.551,
                48.56,
                48.567,
                48.576,
                48.584,
                48.593,
                48.601,
                48.608,
                48.617,
                48.625,
                48.634,
                48.641,
                48.649,
                48.657,
                48.666,
                48.673,
                48.681,
                48.689,
                48.697,
                48.705,
                48.712,
                48.721,
                48.729,
                48.737,
                48.744,
                48.752,
                48.759,
                48.767,
                48.775,
                48.782,
                48.79,
                48.798,
                48.806,
                48.814,
                48.82,
                48.828,
                48.836,
                48.843,
                48.85,
                48.858,
                48.865,
                48.873,
                48.881,
                48.887,
                48.895,
                48.902,
                48.91,
                48.917,
                48.924,
                48.931,
                48.939,
                48.946,
                48.953,
                48.96,
                48.967,
                48.974,
                48.982,
                48.988,
                48.995,
                49.002,
                49.01,
                49.017,
                49.023,
                49.03,
                49.037,
                49.045,
                49.052,
                49.058,
                49.065,
                49.072,
                49.079,
                49.086,
                49.092,
                49.099,
                49.106,
                49.113,
                49.12,
                49.126,
                49.133,
                49.14,
                49.147,
                49.154,
                49.159,
                49.166,
                49.173,
                49.18,
                49.187,
                49.192,
                49.199,
                49.206,
                49.213,
                49.219,
                49.225,
                49.232,
                49.238,
                49.245,
                49.252,
                49.257,
                49.264,
                49.271,
                49.277,
                49.284,
                49.289,
                49.296,
                49.302,
                49.309,
                49.315,
                49.322,
                49.327,
                49.334,
                49.34,
                49.347,
                49.353,
                49.358,
                49.365,
                49.371,
                49.378,
                49.384,
                49.389,
                49.396,
                49.402,
                49.408,
                49.415,
                49.421,
                49.426,
                49.432,
                49.439,
                49.445,
                49.451,
                49.456,
                49.463,
                49.469,
                49.475,
                49.481,
                49.486,
                49.493,
                49.499,
                49.505,
                49.511,
                49.517,
                49.522,
                49.528,
                49.534,
                49.54,
                49.547,
                49.551,
                49.558,
                49.564,
                49.57,
                49.576,
                49.582,
                49.587,
                49.593,
                49.599,
                49.605,
                49.611,
                49.617,
                49.621,
                49.627,
                49.633,
                49.639,
                49.645,
                49.65,
                49.656,
                49.662,
                49.668,
                49.673,
                49.679,
                49.684,
                49.69,
                49.696,
                49.702,
                49.707,
                49.713,
                49.718,
                49.724,
                49.729,
                49.735,
                49.741,
                49.746,
                49.751,
                49.757,
                49.763,
                49.769,
                49.774,
                49.779,
                49.785,
                49.79,
                49.796,
                49.802,
                49.807,
                49.812,
                49.818,
                49.823,
                49.829,
                49.835,
                49.84,
                49.845,
                49.85,
                49.856,
                49.862,
                49.867,
                49.873,
                49.877,
                49.883,
                49.888,
                49.894,
                49.9,
                49.905,
                49.909,
                49.915,
                49.921,
                49.926,
                49.932,
                49.937,
                49.942,
                49.947,
                49.952,
                49.958,
                49.963,
                49.969,
                49.973,
                49.979,
                49.984,
                49.99,
                49.995,
                50,
                50.005,
                50.01,
                50.016,
                50.021,
                50.026,
                50.032,
                50.037,
                50.041,
                50.047,
                50.052,
                50.058,
                50.063,
                50.068,
                50.073,
                50.078,
                50.083,
                50.089,
                50.094,
                50.099,
                50.103,
                50.109,
                50.114,
                50.119,
                50.125,
                50.13,
                50.134,
                50.139,
                50.145,
                50.15,
                50.155,
                50.16,
                50.166,
                50.17,
                50.175,
                50.18,
                50.185,
                50.191,
                50.196,
                50.2,
                50.205,
                50.21,
                50.215,
                50.221,
                50.226,
                50.231,
                50.235,
                50.24,
                50.245,
                50.25,
                50.256,
                50.261,
                50.265,
                50.27,
                50.275,
                50.28,
                50.285,
                50.29,
                50.295,
                50.299,
                50.305,
                50.31,
                50.315,
                50.32,
                50.325,
                50.33,
                50.334,
                50.339,
                50.344,
                50.349,
                50.354,
                50.359,
                50.363,
                50.368,
                50.373,
                50.378,
                50.383,
                50.388,
                50.393,
                50.397,
                50.402,
                50.407,
                50.412,
                50.417,
                50.421,
                50.427,
                50.43,
                50.435,
                50.44,
                50.445,
                50.45,
                50.455,
                50.46,
                50.464,
                50.469,
                50.473,
                50.478,
                50.483,
                50.488,
                50.492,
                50.497,
                50.502,
                50.506,
                50.511,
                50.516,
                50.521,
                50.525,
                50.529,
                50.534,
                50.539,
                50.544,
                50.549,
                50.553,
                50.557,
                50.562,
                50.567,
                50.571,
                50.576,
                50.581,
                50.586,
                50.589,
                50.594,
                50.599,
                50.604,
                50.608,
                50.613,
                50.618,
                50.622,
                50.626,
                50.631,
                50.635,
                50.64,
                50.645,
                50.649,
                50.654,
                50.658,
                50.662,
                50.667,
                50.672,
                50.676,
                50.681,
                50.686,
                50.689,
                50.694,
                50.698,
                50.703,
                50.707,
                50.712,
                50.717,
                50.72,
                50.725,
                50.729,
                50.734,
                50.738,
                50.743,
                50.747,
                50.751,
                50.755,
                50.76,
                50.764,
                50.769,
                50.773,
                50.778,
                50.782,
                50.786,
                50.79,
                50.795,
                50.799,
                50.804,
                50.808,
                50.813,
                50.816,
                50.82,
                50.825,
                50.829,
                50.834,
                50.838,
                50.842,
                50.847,
                50.85,
                50.854,
                50.859,
                50.863,
                50.868,
                50.872,
                50.876,
                50.88,
                50.884,
                50.888,
                50.893,
                50.897,
                50.901,
                50.905,
                50.91,
                50.913,
                50.917,
                50.922,
                50.926,
                50.93,
                50.934,
                50.939,
                50.942,
                50.946,
                50.95,
                50.955,
                50.959,
                50.963,
                50.967,
                50.971,
                50.975,
                50.979,
                50.983,
                50.987,
                50.991,
                50.996,
                51,
                51.004,
                51.007,
                51.011,
                51.015,
                51.019,
                51.023,
                51.028,
                51.032,
                51.036,
                51.039,
                51.043,
                51.047,
                51.051,
                51.055,
                51.059,
                51.063,
                51.067,
                51.07,
                51.074,
                51.078,
                51.082,
                51.087,
                51.091,
                51.095,
                51.099,
                51.102,
                51.106,
                51.109,
                51.114,
                51.117,
                51.121,
                51.125,
                51.128,
                51.132,
                51.136,
                51.14,
                51.144,
                51.148,
                51.152,
                51.156,
                51.16,
                51.163,
                51.167,
                51.17,
                51.174,
                51.178,
                51.182,
                51.186,
                51.19,
                51.193,
                51.197,
                51.2,
                51.204,
                51.208,
                51.212,
                51.216,
                51.219,
                51.222,
                51.226,
                51.23,
                51.234,
                51.237,
                51.241,
                51.245,
                51.249,
                51.251,
                51.255,
                51.259,
                51.263,
                51.266,
                51.27,
                51.274,
                51.278,
                51.28,
                51.284,
                51.288,
                51.291,
                51.295,
                51.299,
                51.302,
                51.306,
                51.31,
                51.312,
                51.316,
                51.32,
                51.323,
                51.327,
                51.331,
                51.334,
                51.338,
                51.34,
                51.344,
                51.348,
                51.351,
                51.355,
                51.359,
                51.362,
                51.366,
                51.368,
                51.372,
                51.375,
                51.379,
                51.382,
                51.386,
                51.39,
                51.393,
                51.397,
                51.399,
                51.403,
                51.406,
                51.41,
                51.413,
                51.417,
                51.42,
                51.424,
                51.426,
                51.43,
                51.433,
                51.437,
                51.44,
                51.443,
                51.447,
                51.45,
                51.454,
                51.456,
                51.46,
                51.463,
                51.467,
                51.47,
                51.473,
                51.477,
                51.48,
                51.484,
                51.486,
                51.489,
                51.493,
                51.496,
                51.499,
                51.503,
                51.506,
                51.51,
                51.513,
                51.515,
                51.519,
                51.522,
                51.525,
                51.529,
                51.532,
                51.535,
                51.539,
                51.541,
                51.544,
                51.548,
                51.551,
                51.554,
                51.557,
                51.561,
                51.564,
                51.567,
                51.569,
                51.573,
                51.576,
                51.579,
                51.582,
                51.586,
                51.589,
                51.592,
                51.596,
                51.598,
                51.601,
                51.604,
                51.607,
                51.611,
                51.614,
                51.617,
                51.62,
                51.623,
                51.625,
                51.629,
                51.632,
                51.635,
                51.638,
                51.641,
                51.645,
                51.648,
                51.651,
                51.653,
                51.656,
                51.659,
                51.662,
                51.666,
                51.669,
                51.672,
                51.675,
                51.678,
                51.68,
                51.683,
                51.686,
                51.689,
                51.692,
                51.695,
                51.699,
                51.702,
                51.705,
                51.708,
                51.71,
                51.713,
                51.716,
                51.719,
                51.722,
                51.725,
                51.728,
                51.731,
                51.734,
                51.736,
                51.739,
                51.742,
                51.745,
                51.748,
                51.751,
                51.754,
                51.757,
                51.76,
                51.762,
                51.765,
                51.768,
                51.771,
                51.774,
                51.777,
                51.78,
                51.783,
                51.786,
                51.788,
                51.791,
                51.794,
                51.797,
                51.8,
                51.803,
                51.806,
                51.809,
                51.812,
                51.815,
                51.816,
                51.819,
                51.822,
                51.825,
                51.828,
                51.831,
                51.834,
                51.837,
                51.84,
                51.841,
                51.844,
                51.847,
                51.85,
                51.853,
                51.856,
                51.859,
                51.862,
                51.865,
                51.867,
                51.869,
                51.872,
                51.875,
                51.878,
                51.88,
                51.883,
                51.886,
                51.889,
                51.892,
                51.895,
                51.896,
                51.899,
                51.902,
                51.905,
                51.908,
                51.91,
                51.913,
                51.916,
                51.919,
                51.921,
                51.923,
                51.926,
                51.929,
                51.932,
                51.934,
                51.937,
                51.94,
                51.943,
                51.945,
                51.947,
                51.95,
                51.953,
                51.955,
                51.958,
                51.961,
                51.964,
                51.966,
                51.969,
                51.972,
                51.973,
                51.976,
                51.979,
                51.982,
                51.984,
                51.987,
                51.99,
                51.992,
                51.995,
                51.998,
                51.999,
                52.002,
                52.005,
                52.007,
                52.01,
                52.013,
                52.015,
                52.018,
                52.021,
                52.023,
                52.025,
                52.028,
                52.03,
                52.033,
                52.036,
                52.038,
                52.041,
                52.044,
                52.046,
                52.049,
                52.05,
                52.053,
                52.056,
                52.058,
                52.061,
                52.063,
                52.066,
                52.069,
                52.071,
                52.074,
                52.075,
                52.078,
                52.08,
                52.083,
                52.086,
                52.088,
                52.091,
                52.093,
                52.096,
                52.099,
                52.1,
                52.102,
                52.105,
                52.108,
                52.11,
                52.113,
                52.115,
                52.118,
                52.12,
                52.123,
                52.124,
                52.127,
                52.129,
                52.132,
                52.134,
                52.137,
                52.139,
                52.142,
                52.144,
                52.147,
                52.15,
                52.151,
                52.153,
                52.156,
                52.158,
                52.161,
                52.163,
                52.166,
                52.168,
                52.171,
                52.173,
                52.175,
                52.177,
                52.18,
                52.182,
                52.184,
                52.187,
                52.189,
                52.192,
                52.194,
                52.197,
                52.199,
                52.201,
                52.203,
                52.205,
                52.208,
                52.21,
                52.213,
                52.215,
                52.218,
                52.22,
                52.222,
                52.224,
                52.226,
                52.229,
                52.231,
                52.233,
                52.236,
                52.238,
                52.24,
                52.243,
                52.245,
                52.248,
                52.249,
                52.251,
                52.254,
                52.256,
                52.258,
                52.261,
                52.263,
                52.265,
                52.268,
                52.27,
                52.272,
                52.274,
                52.276,
                52.279,
                52.281,
                52.283,
                52.286,
                52.288,
                52.29,
                52.293,
                52.295,
                52.296,
                52.298,
                52.301,
                52.303,
                52.305,
                52.308,
                52.31,
                52.312,
                52.315,
                52.317,
                52.319,
                52.32,
                52.323,
                52.325,
                52.327,
                52.33,
                52.332,
                52.334,
                52.336,
                52.339,
                52.341,
                52.343,
                52.344,
                52.347,
                52.349,
                52.351,
                52.353,
                52.356,
                52.358,
                52.36,
                52.362,
                52.365,
                52.367,
                52.368,
                52.37,
                52.373,
                52.375,
                52.377,
                52.379,
                52.382,
                52.384,
                52.386,
                52.388,
                52.39,
                52.392,
                52.394,
                52.396,
                52.398,
                52.4,
                52.403,
                52.405,
                52.407,
                52.409,
                52.411,
                52.414,
                52.415,
                52.417,
                52.419,
                52.421,
                52.423,
                52.426,
                52.428,
                52.43,
                52.432,
                52.434,
                52.436,
                52.437,
                52.44,
                52.442,
                52.444,
                52.446,
                52.448,
                52.45,
                52.453,
                52.455,
                52.457,
                52.459,
                52.46,
                52.462,
                52.464,
                52.467,
                52.469,
                52.471,
                52.473,
                52.475,
                52.477,
                52.479,
                52.481,
                52.483,
                52.484,
                52.487,
                52.489,
                52.491,
                52.493,
                52.495,
                52.497,
                52.499,
                52.501,
                52.503,
                52.506,
                52.507,
                52.509,
                52.511,
                52.513,
                52.515,
                52.517,
                52.519,
                52.521,
                52.523,
                52.525,
                52.527,
                52.53,
                52.53,
                52.533,
                52.535,
                52.537,
                52.539,
                52.541,
                52.543,
                52.545,
                52.547,
                52.549,
                52.551,
                52.552,
                52.554,
                52.556,
                52.558,
                52.56,
                52.562,
                52.564,
                52.566,
                52.568,
                52.57,
                52.572,
                52.574,
                52.575,
                52.577,
                52.579,
                52.581,
                52.583,
                52.585,
                52.587,
                52.589,
                52.592,
                52.594,
                52.595,
                52.597,
                52.598,
                52.6,
                52.602,
                52.604,
                52.606,
                52.608,
                52.61,
                52.612,
                52.614,
                52.616,
                52.618,
                52.619,
                52.621,
                52.623,
                52.625,
                52.627,
                52.629,
                52.631,
                52.633,
                52.635,
                52.637,
                52.639,
                52.641,
                52.642,
                52.644,
                52.646,
                52.648,
                52.65,
                52.652,
                52.653,
                52.656,
                52.657,
                52.659,
                52.661,
                52.663,
                52.664,
                52.666,
                52.668,
                52.67,
                52.672,
                52.674,
                52.676,
                52.678,
                52.68,
                52.682,
                52.683,
                52.685,
                52.686,
                52.688,
                52.69,
                52.692,
                52.694,
                52.696,
                52.698,
                52.7,
                52.702,
                52.703,
                52.705,
                52.707,
                52.708,
                52.71,
                52.712,
                52.714,
                52.716,
                52.718,
                52.719,
                52.721,
                52.723,
                52.725,
                52.727,
                52.729,
                52.73,
                52.732,
                52.733,
                52.735,
                52.737,
                52.739,
                52.741,
                52.743,
                52.745,
                52.747,
                52.748,
                52.75,
                52.752,
                52.753,
                52.755,
                52.757,
                52.758,
                52.76,
                52.762,
                52.764,
                52.766,
                52.768,
                52.77,
                52.772,
                52.773,
                52.774,
                52.776,
                52.778,
                52.78,
                52.782,
                52.783,
                52.785,
                52.787,
                52.789,
                52.791,
                52.793,
                52.794,
                52.795,
                52.797,
                52.799,
                52.801,
                52.802,
                52.804,
                52.806,
                52.808,
                52.81,
                52.812,
                52.813,
                52.815,
                52.817,
                52.818,
                52.819,
                52.821,
                52.823,
                52.825,
                52.827,
                52.829,
                52.83,
                52.832,
                52.834,
                52.836,
                52.838,
                52.838,
                52.84,
                52.842,
                52.844,
                52.845,
                52.847,
                52.849,
                52.851,
                52.853,
                52.854,
                52.856,
                52.858,
                52.86,
                52.86,
                52.862,
                52.864,
                52.866,
                52.867,
                52.869,
                52.871,
                52.873,
                52.875,
                52.876,
                52.878,
                52.88,
                52.882,
                52.882,
                52.884,
                52.886,
                52.888,
                52.889,
                52.891,
                52.893,
                52.895,
                52.896,
                52.898,
                52.9,
                52.902,
                52.903,
                52.904,
                52.906,
                52.907,
                52.909,
                52.911,
                52.913,
                52.914,
                52.916,
                52.918,
                52.92,
                52.921,
                52.923,
                52.925,
                52.925,
                52.927,
                52.929,
                52.931,
                52.932,
                52.934,
                52.936,
                52.938,
                52.939,
                52.941,
                52.943,
                52.944,
                52.946,
                52.947,
                52.948,
                52.95,
                52.952,
                52.954,
                52.955,
                52.957,
                52.959,
                52.96,
                52.962,
                52.964,
                52.966,
                52.967,
                52.968,
                52.97,
                52.971,
                52.973,
                52.975,
                52.976,
                52.978,
                52.98,
                52.981,
                52.983,
                52.985,
                52.986,
                52.988,
                52.989,
                52.99,
                52.992,
                52.994,
                52.995,
                52.997,
                52.999,
                53.001,
                53.002,
                53.004,
                53.006,
                53.007,
                53.009,
                53.009,
                53.011,
                53.013,
                53.014,
                53.016,
                53.018,
                53.019,
                53.021,
                53.023,
                53.024,
                53.026,
                53.028,
                53.029,
                53.03,
                53.031,
                53.033,
                53.035,
                53.036,
                53.038,
                53.04,
                53.041,
                53.043,
                53.045,
                53.046,
                53.048,
                53.05,
                53.051,
                53.052,
                53.053,
                53.055,
                53.057,
                53.058,
                53.06,
                53.062,
                53.063,
                53.065,
                53.066,
                53.068,
                53.07,
                53.071,
                53.072,
                53.074,
                53.075,
                53.077,
                53.078,
                53.08,
                53.082,
                53.083,
                53.085,
                53.086,
                53.088,
                53.09,
                53.091,
                53.093,
                53.093,
                53.095,
                53.097,
                53.098,
                53.1,
                53.101,
                53.103,
                53.105,
                53.106,
                53.108,
                53.109,
                53.111,
                53.113,
                53.113,
                53.115,
                53.116,
                53.118,
                53.12,
                53.121,
                53.123,
                53.124,
                53.126,
                53.128,
                53.129,
                53.131,
                53.132,
                53.134,
                53.134,
                53.136,
                53.137,
                53.139,
                53.141,
                53.142,
                53.144,
                53.145,
                53.147,
                53.149,
                53.15,
                53.152,
                53.153,
                53.155,
                53.155,
                53.157,
                53.158,
                53.16,
                53.162,
                53.163,
                53.165,
                53.166,
                53.168,
                53.169,
                53.171,
                53.172,
                53.174,
                53.176,
                53.176,
                53.178,
                53.179,
                53.181,
                53.182,
                53.184,
                53.185,
                53.187,
                53.188,
                53.19,
                53.192,
                53.193,
                53.195,
                53.196,
                53.197,
                53.198,
                53.2,
                53.201,
                53.203,
                53.204,
                53.206,
                53.207,
                53.209,
                53.211,
                53.212,
                53.214,
                53.215,
                53.217,
                53.217,
                53.219,
                53.22,
                53.222,
                53.223,
                53.225,
                53.226,
                53.228,
                53.229,
                53.231,
                53.232,
                53.234,
                53.235,
                53.237,
                53.237,
                53.239,
                53.24,
                53.242,
                53.244,
                53.245,
                53.246,
                53.248,
                53.25,
                53.251,
                53.253,
                53.254,
                53.256,
                53.257,
                53.257,
                53.259,
                53.261,
                53.262,
                53.264,
                53.265,
                53.267,
                53.268,
                53.27,
                53.271,
                53.273,
                53.274,
                53.276
            ],
            P999: [
                37.539,
                37.628,
                37.715,
                37.804,
                37.892,
                37.98,
                38.067,
                38.156,
                38.243,
                38.331,
                38.418,
                38.505,
                38.592,
                38.68,
                38.766,
                38.866,
                38.964,
                39.06,
                39.153,
                39.244,
                39.333,
                39.422,
                39.507,
                39.592,
                39.674,
                39.755,
                39.835,
                39.913,
                39.99,
                40.066,
                40.14,
                40.213,
                40.284,
                40.356,
                40.425,
                40.493,
                40.56,
                40.628,
                40.693,
                40.758,
                40.821,
                40.885,
                40.946,
                41.008,
                41.068,
                41.129,
                41.188,
                41.246,
                41.304,
                41.361,
                41.417,
                41.473,
                41.529,
                41.584,
                41.638,
                41.691,
                41.744,
                41.797,
                41.849,
                41.901,
                41.953,
                42.003,
                42.054,
                42.104,
                42.152,
                42.201,
                42.25,
                42.298,
                42.346,
                42.394,
                42.441,
                42.489,
                42.535,
                42.581,
                42.627,
                42.672,
                42.717,
                42.762,
                42.806,
                42.85,
                42.893,
                42.938,
                42.981,
                43.024,
                43.066,
                43.108,
                43.15,
                43.193,
                43.234,
                43.275,
                43.315,
                43.356,
                43.397,
                43.437,
                43.477,
                43.516,
                43.557,
                43.595,
                43.634,
                43.672,
                43.712,
                43.75,
                43.787,
                43.825,
                43.863,
                43.9,
                43.937,
                43.975,
                44.011,
                44.047,
                44.084,
                44.12,
                44.155,
                44.191,
                44.226,
                44.261,
                44.297,
                44.331,
                44.365,
                44.4,
                44.434,
                44.469,
                44.502,
                44.535,
                44.569,
                44.602,
                44.635,
                44.668,
                44.7,
                44.733,
                44.764,
                44.797,
                44.828,
                44.859,
                44.892,
                44.922,
                44.954,
                44.985,
                45.016,
                45.046,
                45.076,
                45.107,
                45.136,
                45.167,
                45.196,
                45.226,
                45.255,
                45.285,
                45.313,
                45.343,
                45.371,
                45.4,
                45.428,
                45.457,
                45.484,
                45.513,
                45.54,
                45.567,
                45.595,
                45.621,
                45.649,
                45.677,
                45.703,
                45.731,
                45.756,
                45.783,
                45.809,
                45.836,
                45.861,
                45.888,
                45.913,
                45.939,
                45.964,
                45.989,
                46.014,
                46.04,
                46.064,
                46.089,
                46.113,
                46.138,
                46.163,
                46.186,
                46.211,
                46.234,
                46.259,
                46.282,
                46.306,
                46.329,
                46.352,
                46.376,
                46.399,
                46.422,
                46.444,
                46.467,
                46.489,
                46.512,
                46.535,
                46.557,
                46.579,
                46.601,
                46.623,
                46.644,
                46.666,
                46.688,
                46.709,
                46.731,
                46.751,
                46.773,
                46.794,
                46.814,
                46.836,
                46.855,
                46.877,
                46.897,
                46.917,
                46.938,
                46.957,
                46.978,
                46.998,
                47.017,
                47.037,
                47.057,
                47.076,
                47.096,
                47.114,
                47.134,
                47.154,
                47.172,
                47.191,
                47.211,
                47.228,
                47.248,
                47.265,
                47.284,
                47.303,
                47.32,
                47.339,
                47.358,
                47.375,
                47.393,
                47.411,
                47.428,
                47.446,
                47.463,
                47.481,
                47.499,
                47.515,
                47.533,
                47.55,
                47.566,
                47.584,
                47.601,
                47.617,
                47.634,
                47.651,
                47.667,
                47.684,
                47.701,
                47.716,
                47.733,
                47.749,
                47.764,
                47.781,
                47.797,
                47.812,
                47.828,
                47.844,
                47.859,
                47.875,
                47.891,
                47.905,
                47.921,
                47.936,
                47.951,
                47.966,
                47.982,
                47.996,
                48.011,
                48.026,
                48.04,
                48.055,
                48.07,
                48.084,
                48.098,
                48.113,
                48.126,
                48.141,
                48.156,
                48.17,
                48.183,
                48.198,
                48.212,
                48.225,
                48.239,
                48.254,
                48.266,
                48.28,
                48.294,
                48.307,
                48.321,
                48.334,
                48.348,
                48.36,
                48.374,
                48.388,
                48.4,
                48.413,
                48.427,
                48.44,
                48.452,
                48.465,
                48.478,
                48.49,
                48.503,
                48.516,
                48.527,
                48.54,
                48.553,
                48.566,
                48.577,
                48.59,
                48.603,
                48.614,
                48.626,
                48.639,
                48.651,
                48.662,
                48.674,
                48.687,
                48.699,
                48.71,
                48.722,
                48.734,
                48.744,
                48.756,
                48.768,
                48.78,
                48.79,
                48.802,
                48.814,
                48.826,
                48.836,
                48.847,
                48.859,
                48.869,
                48.881,
                48.892,
                48.903,
                48.913,
                48.924,
                48.936,
                48.947,
                48.957,
                48.968,
                48.979,
                48.99,
                48.999,
                49.01,
                49.021,
                49.031,
                49.041,
                49.052,
                49.063,
                49.072,
                49.083,
                49.093,
                49.104,
                49.113,
                49.124,
                49.134,
                49.145,
                49.154,
                49.164,
                49.174,
                49.184,
                49.193,
                49.203,
                49.214,
                49.224,
                49.232,
                49.242,
                49.252,
                49.262,
                49.271,
                49.281,
                49.291,
                49.3,
                49.309,
                49.319,
                49.328,
                49.338,
                49.346,
                49.356,
                49.366,
                49.375,
                49.383,
                49.393,
                49.402,
                49.412,
                49.421,
                49.429,
                49.438,
                49.448,
                49.457,
                49.465,
                49.474,
                49.483,
                49.492,
                49.5,
                49.509,
                49.518,
                49.527,
                49.535,
                49.544,
                49.553,
                49.562,
                49.57,
                49.578,
                49.587,
                49.596,
                49.604,
                49.612,
                49.62,
                49.629,
                49.638,
                49.646,
                49.654,
                49.662,
                49.671,
                49.679,
                49.686,
                49.695,
                49.703,
                49.712,
                49.719,
                49.727,
                49.735,
                49.744,
                49.752,
                49.759,
                49.767,
                49.775,
                49.784,
                49.79,
                49.798,
                49.807,
                49.815,
                49.823,
                49.829,
                49.838,
                49.845,
                49.853,
                49.861,
                49.868,
                49.876,
                49.884,
                49.892,
                49.898,
                49.906,
                49.914,
                49.921,
                49.929,
                49.936,
                49.943,
                49.951,
                49.959,
                49.966,
                49.973,
                49.98,
                49.988,
                49.995,
                50.003,
                50.009,
                50.017,
                50.024,
                50.032,
                50.038,
                50.045,
                50.052,
                50.06,
                50.067,
                50.073,
                50.081,
                50.088,
                50.095,
                50.102,
                50.108,
                50.116,
                50.123,
                50.13,
                50.137,
                50.143,
                50.15,
                50.157,
                50.164,
                50.172,
                50.177,
                50.184,
                50.191,
                50.198,
                50.206,
                50.211,
                50.218,
                50.225,
                50.232,
                50.239,
                50.245,
                50.251,
                50.258,
                50.265,
                50.272,
                50.278,
                50.284,
                50.291,
                50.298,
                50.305,
                50.31,
                50.317,
                50.324,
                50.33,
                50.337,
                50.343,
                50.349,
                50.356,
                50.363,
                50.369,
                50.376,
                50.381,
                50.388,
                50.394,
                50.401,
                50.407,
                50.413,
                50.419,
                50.426,
                50.432,
                50.439,
                50.444,
                50.45,
                50.457,
                50.463,
                50.47,
                50.476,
                50.481,
                50.487,
                50.494,
                50.5,
                50.507,
                50.512,
                50.518,
                50.524,
                50.531,
                50.537,
                50.542,
                50.548,
                50.554,
                50.561,
                50.567,
                50.573,
                50.578,
                50.584,
                50.591,
                50.597,
                50.603,
                50.608,
                50.614,
                50.62,
                50.626,
                50.632,
                50.638,
                50.643,
                50.649,
                50.655,
                50.662,
                50.668,
                50.674,
                50.678,
                50.684,
                50.691,
                50.697,
                50.703,
                50.707,
                50.713,
                50.719,
                50.725,
                50.731,
                50.737,
                50.742,
                50.748,
                50.754,
                50.76,
                50.766,
                50.771,
                50.776,
                50.782,
                50.788,
                50.794,
                50.8,
                50.804,
                50.81,
                50.816,
                50.822,
                50.827,
                50.833,
                50.838,
                50.843,
                50.849,
                50.855,
                50.861,
                50.867,
                50.871,
                50.877,
                50.883,
                50.888,
                50.894,
                50.9,
                50.904,
                50.91,
                50.916,
                50.921,
                50.927,
                50.933,
                50.937,
                50.943,
                50.948,
                50.954,
                50.96,
                50.965,
                50.97,
                50.975,
                50.981,
                50.987,
                50.992,
                50.998,
                51.002,
                51.008,
                51.013,
                51.019,
                51.024,
                51.03,
                51.034,
                51.04,
                51.045,
                51.051,
                51.056,
                51.062,
                51.066,
                51.071,
                51.077,
                51.083,
                51.088,
                51.094,
                51.099,
                51.103,
                51.109,
                51.114,
                51.12,
                51.125,
                51.13,
                51.134,
                51.14,
                51.145,
                51.151,
                51.156,
                51.162,
                51.166,
                51.171,
                51.176,
                51.182,
                51.187,
                51.193,
                51.197,
                51.202,
                51.207,
                51.213,
                51.218,
                51.223,
                51.229,
                51.233,
                51.238,
                51.243,
                51.249,
                51.254,
                51.259,
                51.263,
                51.268,
                51.274,
                51.279,
                51.284,
                51.29,
                51.295,
                51.299,
                51.304,
                51.309,
                51.315,
                51.32,
                51.325,
                51.329,
                51.334,
                51.339,
                51.344,
                51.35,
                51.355,
                51.36,
                51.364,
                51.369,
                51.374,
                51.379,
                51.385,
                51.39,
                51.395,
                51.399,
                51.404,
                51.409,
                51.414,
                51.419,
                51.424,
                51.428,
                51.433,
                51.438,
                51.443,
                51.448,
                51.453,
                51.459,
                51.462,
                51.467,
                51.472,
                51.477,
                51.482,
                51.487,
                51.493,
                51.496,
                51.501,
                51.506,
                51.511,
                51.516,
                51.521,
                51.526,
                51.53,
                51.535,
                51.54,
                51.545,
                51.55,
                51.555,
                51.558,
                51.563,
                51.568,
                51.573,
                51.578,
                51.583,
                51.588,
                51.591,
                51.596,
                51.601,
                51.606,
                51.611,
                51.616,
                51.621,
                51.624,
                51.629,
                51.634,
                51.639,
                51.644,
                51.649,
                51.653,
                51.657,
                51.662,
                51.666,
                51.671,
                51.676,
                51.681,
                51.686,
                51.691,
                51.694,
                51.699,
                51.704,
                51.708,
                51.713,
                51.718,
                51.723,
                51.726,
                51.731,
                51.735,
                51.74,
                51.745,
                51.75,
                51.754,
                51.758,
                51.762,
                51.767,
                51.772,
                51.776,
                51.781,
                51.786,
                51.789,
                51.794,
                51.798,
                51.803,
                51.808,
                51.812,
                51.817,
                51.82,
                51.825,
                51.829,
                51.834,
                51.838,
                51.843,
                51.848,
                51.852,
                51.855,
                51.86,
                51.864,
                51.869,
                51.874,
                51.878,
                51.883,
                51.886,
                51.89,
                51.895,
                51.899,
                51.904,
                51.908,
                51.913,
                51.917,
                51.92,
                51.925,
                51.929,
                51.934,
                51.938,
                51.943,
                51.947,
                51.95,
                51.955,
                51.959,
                51.963,
                51.968,
                51.972,
                51.977,
                51.981,
                51.984,
                51.988,
                51.993,
                51.997,
                52.001,
                52.006,
                52.01,
                52.013,
                52.017,
                52.022,
                52.026,
                52.03,
                52.035,
                52.039,
                52.043,
                52.046,
                52.05,
                52.055,
                52.059,
                52.063,
                52.067,
                52.072,
                52.076,
                52.079,
                52.083,
                52.087,
                52.091,
                52.096,
                52.1,
                52.104,
                52.108,
                52.111,
                52.115,
                52.119,
                52.124,
                52.128,
                52.132,
                52.136,
                52.14,
                52.143,
                52.147,
                52.151,
                52.155,
                52.159,
                52.164,
                52.168,
                52.172,
                52.174,
                52.178,
                52.182,
                52.187,
                52.191,
                52.195,
                52.199,
                52.201,
                52.205,
                52.209,
                52.213,
                52.218,
                52.222,
                52.225,
                52.23,
                52.234,
                52.236,
                52.24,
                52.244,
                52.248,
                52.252,
                52.256,
                52.26,
                52.264,
                52.266,
                52.27,
                52.274,
                52.278,
                52.282,
                52.286,
                52.29,
                52.294,
                52.296,
                52.3,
                52.304,
                52.308,
                52.312,
                52.316,
                52.319,
                52.323,
                52.326,
                52.329,
                52.333,
                52.337,
                52.341,
                52.345,
                52.349,
                52.352,
                52.355,
                52.359,
                52.362,
                52.366,
                52.37,
                52.374,
                52.377,
                52.381,
                52.385,
                52.387,
                52.391,
                52.395,
                52.398,
                52.402,
                52.406,
                52.41,
                52.413,
                52.416,
                52.419,
                52.423,
                52.427,
                52.43,
                52.434,
                52.438,
                52.441,
                52.444,
                52.447,
                52.451,
                52.455,
                52.458,
                52.462,
                52.465,
                52.469,
                52.473,
                52.475,
                52.478,
                52.482,
                52.486,
                52.489,
                52.493,
                52.496,
                52.5,
                52.502,
                52.506,
                52.509,
                52.513,
                52.516,
                52.52,
                52.523,
                52.527,
                52.53,
                52.532,
                52.536,
                52.539,
                52.543,
                52.546,
                52.55,
                52.553,
                52.557,
                52.56,
                52.562,
                52.566,
                52.569,
                52.573,
                52.576,
                52.58,
                52.583,
                52.586,
                52.59,
                52.592,
                52.595,
                52.599,
                52.602,
                52.606,
                52.609,
                52.612,
                52.616,
                52.618,
                52.621,
                52.625,
                52.628,
                52.631,
                52.635,
                52.638,
                52.641,
                52.645,
                52.646,
                52.65,
                52.653,
                52.656,
                52.66,
                52.663,
                52.667,
                52.67,
                52.673,
                52.675,
                52.678,
                52.681,
                52.685,
                52.688,
                52.691,
                52.695,
                52.698,
                52.701,
                52.703,
                52.706,
                52.71,
                52.713,
                52.716,
                52.719,
                52.723,
                52.726,
                52.729,
                52.731,
                52.734,
                52.737,
                52.74,
                52.744,
                52.747,
                52.75,
                52.753,
                52.756,
                52.758,
                52.761,
                52.764,
                52.767,
                52.771,
                52.774,
                52.777,
                52.78,
                52.783,
                52.786,
                52.788,
                52.791,
                52.794,
                52.797,
                52.801,
                52.804,
                52.807,
                52.81,
                52.813,
                52.815,
                52.818,
                52.821,
                52.824,
                52.827,
                52.83,
                52.833,
                52.836,
                52.839,
                52.841,
                52.844,
                52.847,
                52.85,
                52.853,
                52.856,
                52.859,
                52.862,
                52.865,
                52.867,
                52.87,
                52.873,
                52.876,
                52.879,
                52.882,
                52.885,
                52.888,
                52.891,
                52.894,
                52.895,
                52.899,
                52.901,
                52.904,
                52.907,
                52.91,
                52.913,
                52.916,
                52.919,
                52.921,
                52.924,
                52.927,
                52.93,
                52.933,
                52.936,
                52.938,
                52.941,
                52.944,
                52.947,
                52.949,
                52.952,
                52.955,
                52.957,
                52.96,
                52.963,
                52.966,
                52.969,
                52.972,
                52.975,
                52.976,
                52.979,
                52.982,
                52.985,
                52.988,
                52.99,
                52.993,
                52.996,
                52.999,
                53,
                53.003,
                53.006,
                53.009,
                53.012,
                53.015,
                53.018,
                53.02,
                53.023,
                53.026,
                53.027,
                53.03,
                53.033,
                53.036,
                53.038,
                53.041,
                53.044,
                53.047,
                53.05,
                53.052,
                53.054,
                53.057,
                53.059,
                53.062,
                53.065,
                53.068,
                53.07,
                53.073,
                53.076,
                53.079,
                53.08,
                53.083,
                53.085,
                53.088,
                53.091,
                53.094,
                53.096,
                53.099,
                53.102,
                53.104,
                53.106,
                53.108,
                53.111,
                53.114,
                53.117,
                53.119,
                53.122,
                53.125,
                53.127,
                53.13,
                53.131,
                53.134,
                53.137,
                53.139,
                53.142,
                53.145,
                53.147,
                53.15,
                53.153,
                53.155,
                53.156,
                53.159,
                53.162,
                53.164,
                53.167,
                53.17,
                53.172,
                53.175,
                53.177,
                53.18,
                53.181,
                53.184,
                53.186,
                53.189,
                53.192,
                53.194,
                53.197,
                53.199,
                53.202,
                53.205,
                53.206,
                53.208,
                53.211,
                53.213,
                53.216,
                53.219,
                53.221,
                53.224,
                53.226,
                53.229,
                53.231,
                53.232,
                53.235,
                53.238,
                53.24,
                53.243,
                53.245,
                53.248,
                53.25,
                53.253,
                53.255,
                53.256,
                53.259,
                53.261,
                53.264,
                53.266,
                53.269,
                53.271,
                53.274,
                53.276,
                53.279,
                53.281,
                53.282,
                53.285,
                53.287,
                53.29,
                53.292,
                53.295,
                53.297,
                53.3,
                53.302,
                53.305,
                53.306,
                53.308,
                53.311,
                53.313,
                53.316,
                53.318,
                53.32,
                53.323,
                53.325,
                53.328,
                53.33,
                53.331,
                53.334,
                53.336,
                53.338,
                53.341,
                53.343,
                53.346,
                53.348,
                53.35,
                53.353,
                53.354,
                53.356,
                53.359,
                53.361,
                53.363,
                53.366,
                53.368,
                53.371,
                53.373,
                53.375,
                53.378,
                53.379,
                53.381,
                53.383,
                53.386,
                53.388,
                53.39,
                53.393,
                53.395,
                53.398,
                53.4,
                53.402,
                53.403,
                53.405,
                53.408,
                53.41,
                53.412,
                53.415,
                53.417,
                53.419,
                53.422,
                53.424,
                53.426,
                53.427,
                53.429,
                53.432,
                53.434,
                53.436,
                53.439,
                53.441,
                53.443,
                53.446,
                53.448,
                53.45,
                53.451,
                53.453,
                53.456,
                53.458,
                53.46,
                53.462,
                53.465,
                53.467,
                53.469,
                53.472,
                53.474,
                53.475,
                53.477,
                53.479,
                53.481,
                53.484,
                53.486,
                53.488,
                53.49,
                53.493,
                53.495,
                53.497,
                53.498,
                53.5,
                53.502,
                53.505,
                53.507,
                53.509,
                53.511,
                53.513,
                53.516,
                53.518,
                53.52,
                53.521,
                53.523,
                53.525,
                53.527,
                53.53,
                53.532,
                53.534,
                53.536,
                53.538,
                53.541,
                53.543,
                53.544,
                53.546,
                53.548,
                53.55,
                53.552,
                53.554,
                53.557,
                53.559,
                53.561,
                53.563,
                53.565,
                53.567,
                53.568,
                53.57,
                53.572,
                53.575,
                53.577,
                53.579,
                53.581,
                53.583,
                53.585,
                53.587,
                53.59,
                53.59,
                53.592,
                53.595,
                53.597,
                53.599,
                53.601,
                53.603,
                53.605,
                53.607,
                53.609,
                53.612,
                53.614,
                53.614,
                53.616,
                53.618,
                53.621,
                53.623,
                53.625,
                53.627,
                53.629,
                53.631,
                53.633,
                53.635,
                53.636,
                53.638,
                53.64,
                53.642,
                53.644,
                53.646,
                53.648,
                53.65,
                53.653,
                53.655,
                53.657,
                53.659,
                53.659,
                53.661,
                53.663,
                53.666,
                53.668,
                53.67,
                53.672,
                53.674,
                53.676,
                53.678,
                53.68,
                53.682,
                53.683,
                53.685,
                53.687,
                53.689,
                53.691,
                53.693,
                53.695,
                53.697,
                53.699,
                53.701,
                53.703,
                53.703,
                53.705,
                53.707,
                53.71,
                53.711,
                53.714,
                53.716,
                53.718,
                53.72,
                53.722,
                53.724,
                53.726,
                53.726,
                53.728,
                53.73,
                53.732,
                53.734,
                53.736,
                53.738,
                53.74,
                53.742,
                53.744,
                53.746,
                53.748,
                53.749,
                53.75,
                53.753,
                53.754,
                53.756,
                53.758,
                53.76,
                53.762,
                53.764,
                53.766,
                53.768,
                53.77,
                53.771,
                53.773,
                53.775,
                53.777,
                53.779,
                53.781,
                53.782,
                53.784,
                53.786,
                53.788,
                53.79,
                53.792,
                53.793,
                53.795,
                53.797,
                53.798,
                53.8,
                53.802,
                53.804,
                53.806,
                53.808,
                53.81,
                53.812,
                53.814,
                53.814,
                53.816,
                53.818,
                53.82,
                53.822,
                53.824,
                53.826,
                53.828,
                53.83,
                53.832,
                53.834,
                53.835,
                53.837,
                53.838,
                53.84,
                53.842,
                53.843,
                53.845,
                53.847,
                53.849,
                53.851,
                53.853,
                53.855,
                53.857,
                53.859,
                53.859,
                53.861,
                53.863,
                53.865,
                53.867,
                53.868,
                53.87,
                53.872,
                53.874,
                53.876,
                53.878,
                53.88,
                53.88,
                53.882,
                53.884,
                53.886,
                53.888,
                53.889,
                53.891,
                53.893,
                53.895,
                53.897,
                53.899,
                53.901,
                53.902,
                53.903,
                53.905,
                53.906,
                53.908,
                53.91,
                53.912,
                53.914,
                53.916,
                53.918,
                53.919,
                53.921,
                53.923,
                53.923,
                53.925,
                53.927,
                53.929,
                53.931,
                53.933,
                53.934,
                53.936,
                53.938,
                53.94,
                53.942,
                53.944,
                53.945,
                53.946,
                53.947,
                53.949,
                53.951,
                53.953,
                53.955,
                53.957,
                53.958,
                53.96,
                53.962,
                53.964,
                53.966,
                53.967,
                53.968,
                53.969,
                53.971,
                53.973,
                53.975,
                53.977,
                53.979,
                53.98,
                53.982,
                53.984,
                53.986,
                53.987,
                53.989,
                53.989,
                53.991,
                53.993,
                53.995,
                53.997,
                53.998,
                54,
                54.002,
                54.004,
                54.005,
                54.007,
                54.009,
                54.011,
                54.011,
                54.013,
                54.015,
                54.016,
                54.018,
                54.02,
                54.022,
                54.023,
                54.025,
                54.027,
                54.029,
                54.03,
                54.032,
                54.032,
                54.034,
                54.036,
                54.038,
                54.039,
                54.041,
                54.043,
                54.045,
                54.046,
                54.048,
                54.05,
                54.052,
                54.053,
                54.054,
                54.055,
                54.057,
                54.059,
                54.06,
                54.062,
                54.064,
                54.066,
                54.067,
                54.069,
                54.071,
                54.073,
                54.074,
                54.074,
                54.076,
                54.078,
                54.08,
                54.081,
                54.083,
                54.085,
                54.087,
                54.088,
                54.09,
                54.092,
                54.093,
                54.095,
                54.095,
                54.097,
                54.099,
                54.1,
                54.102,
                54.104,
                54.105,
                54.107,
                54.109,
                54.11,
                54.112,
                54.114,
                54.116,
                54.116,
                54.117,
                54.119,
                54.121,
                54.123,
                54.124,
                54.126,
                54.128,
                54.129,
                54.131,
                54.133,
                54.134,
                54.136,
                54.138,
                54.138,
                54.139,
                54.141,
                54.143,
                54.145,
                54.146,
                54.148,
                54.15,
                54.151,
                54.153,
                54.154,
                54.156,
                54.158,
                54.158,
                54.16,
                54.161,
                54.163,
                54.165,
                54.166,
                54.168,
                54.17,
                54.171,
                54.173,
                54.174,
                54.176,
                54.178,
                54.179,
                54.18,
                54.181,
                54.183,
                54.184,
                54.186,
                54.188,
                54.189,
                54.191,
                54.193,
                54.194,
                54.196,
                54.198,
                54.199,
                54.199,
                54.201,
                54.203,
                54.204,
                54.206,
                54.207,
                54.209,
                54.211,
                54.212,
                54.214,
                54.216,
                54.217,
                54.219,
                54.22,
                54.221,
                54.222,
                54.224,
                54.225,
                54.227,
                54.229,
                54.23,
                54.232,
                54.233,
                54.235,
                54.237,
                54.238,
                54.24,
                54.242,
                54.242,
                54.243,
                54.245,
                54.246,
                54.248,
                54.25,
                54.251,
                54.253,
                54.254,
                54.256,
                54.258,
                54.259,
                54.261,
                54.262,
                54.262,
                54.264,
                54.266,
                54.267,
                54.269,
                54.27,
                54.272,
                54.274,
                54.275,
                54.277,
                54.278,
                54.28,
                54.282,
                54.283,
                54.283,
                54.285,
                54.286,
                54.288,
                54.289,
                54.291,
                54.293,
                54.294,
                54.296,
                54.297,
                54.299,
                54.3,
                54.302,
                54.304,
                54.304,
                54.305,
                54.307,
                54.308,
                54.31,
                54.311,
                54.313,
                54.315,
                54.316,
                54.318,
                54.319,
                54.321,
                54.322,
                54.324,
                54.324,
                54.326,
                54.327,
                54.329,
                54.33,
                54.332,
                54.333,
                54.335,
                54.336,
                54.338,
                54.339,
                54.341,
                54.343,
                54.344,
                54.344,
                54.346,
                54.347,
                54.349,
                54.35,
                54.352,
                54.353,
                54.355,
                54.357,
                54.358,
                54.36,
                54.361,
                54.363
            ]
        }
    },
    imc: {
        boys: {
            P01: [
                10.104,
                10.038,
                9.971,
                9.9,
                9.827,
                9.75,
                9.67,
                9.586,
                9.644,
                9.701,
                9.758,
                9.815,
                9.872,
                9.929,
                9.987,
                10.065,
                10.144,
                10.223,
                10.303,
                10.381,
                10.459,
                10.535,
                10.61,
                10.683,
                10.755,
                10.825,
                10.894,
                10.961,
                11.027,
                11.092,
                11.155,
                11.216,
                11.275,
                11.332,
                11.388,
                11.442,
                11.495,
                11.546,
                11.596,
                11.644,
                11.69,
                11.736,
                11.78,
                11.823,
                11.865,
                11.906,
                11.945,
                11.984,
                12.022,
                12.058,
                12.094,
                12.129,
                12.163,
                12.196,
                12.229,
                12.26,
                12.291,
                12.321,
                12.35,
                12.378,
                12.406,
                12.433,
                12.46,
                12.486,
                12.51,
                12.535,
                12.559,
                12.582,
                12.604,
                12.626,
                12.648,
                12.668,
                12.689,
                12.708,
                12.727,
                12.746,
                12.764,
                12.782,
                12.799,
                12.816,
                12.833,
                12.849,
                12.865,
                12.88,
                12.895,
                12.909,
                12.924,
                12.938,
                12.951,
                12.964,
                12.978,
                12.99,
                13.002,
                13.015,
                13.026,
                13.038,
                13.049,
                13.06,
                13.071,
                13.082,
                13.092,
                13.102,
                13.112,
                13.122,
                13.132,
                13.141,
                13.151,
                13.16,
                13.168,
                13.177,
                13.186,
                13.194,
                13.202,
                13.21,
                13.218,
                13.226,
                13.234,
                13.242,
                13.249,
                13.256,
                13.264,
                13.271,
                13.277,
                13.284,
                13.291,
                13.298,
                13.305,
                13.311,
                13.317,
                13.324,
                13.329,
                13.335,
                13.341,
                13.347,
                13.353,
                13.358,
                13.364,
                13.37,
                13.375,
                13.38,
                13.385,
                13.39,
                13.395,
                13.4,
                13.405,
                13.409,
                13.414,
                13.418,
                13.423,
                13.427,
                13.431,
                13.436,
                13.44,
                13.443,
                13.448,
                13.451,
                13.455,
                13.459,
                13.462,
                13.466,
                13.469,
                13.472,
                13.475,
                13.479,
                13.482,
                13.485,
                13.488,
                13.491,
                13.494,
                13.496,
                13.499,
                13.502,
                13.504,
                13.507,
                13.509,
                13.511,
                13.514,
                13.516,
                13.518,
                13.52,
                13.522,
                13.524,
                13.526,
                13.528,
                13.53,
                13.531,
                13.533,
                13.535,
                13.536,
                13.538,
                13.539,
                13.54,
                13.542,
                13.543,
                13.544,
                13.545,
                13.546,
                13.547,
                13.548,
                13.549,
                13.55,
                13.551,
                13.551,
                13.552,
                13.553,
                13.553,
                13.554,
                13.554,
                13.555,
                13.555,
                13.555,
                13.556,
                13.556,
                13.556,
                13.556,
                13.556,
                13.556,
                13.556,
                13.556,
                13.556,
                13.556,
                13.556,
                13.555,
                13.555,
                13.554,
                13.554,
                13.554,
                13.553,
                13.553,
                13.552,
                13.551,
                13.551,
                13.55,
                13.549,
                13.548,
                13.548,
                13.547,
                13.546,
                13.545,
                13.544,
                13.543,
                13.542,
                13.541,
                13.54,
                13.539,
                13.537,
                13.536,
                13.535,
                13.534,
                13.532,
                13.531,
                13.53,
                13.528,
                13.527,
                13.526,
                13.524,
                13.522,
                13.521,
                13.519,
                13.518,
                13.516,
                13.515,
                13.513,
                13.511,
                13.509,
                13.507,
                13.506,
                13.504,
                13.502,
                13.5,
                13.498,
                13.497,
                13.495,
                13.493,
                13.491,
                13.489,
                13.487,
                13.485,
                13.483,
                13.481,
                13.479,
                13.477,
                13.475,
                13.473,
                13.471,
                13.469,
                13.466,
                13.464,
                13.462,
                13.46,
                13.457,
                13.456,
                13.453,
                13.451,
                13.449,
                13.446,
                13.444,
                13.442,
                13.439,
                13.437,
                13.435,
                13.433,
                13.43,
                13.428,
                13.426,
                13.423,
                13.421,
                13.418,
                13.416,
                13.414,
                13.411,
                13.409,
                13.406,
                13.404,
                13.401,
                13.399,
                13.396,
                13.394,
                13.392,
                13.389,
                13.386,
                13.384,
                13.382,
                13.379,
                13.377,
                13.374,
                13.372,
                13.369,
                13.367,
                13.364,
                13.361,
                13.359,
                13.356,
                13.354,
                13.351,
                13.349,
                13.346,
                13.344,
                13.341,
                13.339,
                13.336,
                13.334,
                13.331,
                13.329,
                13.326,
                13.323,
                13.321,
                13.318,
                13.316,
                13.313,
                13.311,
                13.308,
                13.305,
                13.303,
                13.3,
                13.298,
                13.295,
                13.292,
                13.29,
                13.287,
                13.285,
                13.282,
                13.28,
                13.277,
                13.274,
                13.272,
                13.269,
                13.267,
                13.264,
                13.261,
                13.259,
                13.256,
                13.253,
                13.251,
                13.248,
                13.246,
                13.243,
                13.241,
                13.238,
                13.236,
                13.233,
                13.23,
                13.228,
                13.225,
                13.223,
                13.22,
                13.218,
                13.215,
                13.212,
                13.21,
                13.207,
                13.205,
                13.202,
                13.199,
                13.197,
                13.194,
                13.192,
                13.189,
                13.187,
                13.184,
                13.181,
                13.179,
                13.176,
                13.174,
                13.171,
                13.168,
                13.166,
                13.163,
                13.161,
                13.159,
                13.156,
                13.153,
                13.151,
                13.148,
                13.146,
                13.143,
                13.141,
                13.138,
                13.136,
                13.133,
                13.13,
                13.128,
                13.126,
                13.123,
                13.121,
                13.118,
                13.116,
                13.113,
                13.111,
                13.108,
                13.106,
                13.103,
                13.101,
                13.098,
                13.096,
                13.093,
                13.091,
                13.089,
                13.086,
                13.084,
                13.081,
                13.079,
                13.076,
                13.074,
                13.071,
                13.069,
                13.066,
                13.064,
                13.062,
                13.059,
                13.057,
                13.054,
                13.052,
                13.05,
                13.047,
                13.045,
                13.043,
                13.04,
                13.038,
                13.035,
                13.033,
                13.031,
                13.028,
                13.026,
                13.024,
                13.021,
                13.019,
                13.017,
                13.015,
                13.012,
                13.01,
                13.008,
                13.005,
                13.003,
                13.001,
                12.998,
                12.996,
                12.994,
                12.992,
                12.989,
                12.987,
                12.985,
                12.983,
                12.98,
                12.978,
                12.976,
                12.973,
                12.971,
                12.969,
                12.967,
                12.964,
                12.962,
                12.96,
                12.958,
                12.956,
                12.954,
                12.951,
                12.949,
                12.947,
                12.945,
                12.943,
                12.941,
                12.938,
                12.936,
                12.934,
                12.932,
                12.93,
                12.928,
                12.925,
                12.923,
                12.921,
                12.919,
                12.917,
                12.915,
                12.912,
                12.91,
                12.908,
                12.906,
                12.904,
                12.902,
                12.9,
                12.898,
                12.896,
                12.894,
                12.892,
                12.89,
                12.888,
                12.886,
                12.884,
                12.881,
                12.88,
                12.878,
                12.875,
                12.874,
                12.872,
                12.869,
                12.868,
                12.865,
                12.863,
                12.862,
                12.859,
                12.857,
                12.856,
                12.853,
                12.852,
                12.85,
                12.848,
                12.846,
                12.844,
                12.842,
                12.84,
                12.838,
                12.836,
                12.834,
                12.832,
                12.83,
                12.829,
                12.827,
                12.825,
                12.823,
                12.821,
                12.819,
                12.817,
                12.815,
                12.813,
                12.812,
                12.81,
                12.808,
                12.806,
                12.805,
                12.803,
                12.801,
                12.799,
                12.797,
                12.796,
                12.794,
                12.792,
                12.79,
                12.788,
                12.787,
                12.785,
                12.783,
                12.781,
                12.78,
                12.778,
                12.776,
                12.774,
                12.773,
                12.771,
                12.769,
                12.768,
                12.766,
                12.764,
                12.763,
                12.761,
                12.759,
                12.758,
                12.756,
                12.754,
                12.753,
                12.751,
                12.749,
                12.748,
                12.746,
                12.745,
                12.743,
                12.742,
                12.74,
                12.738,
                12.736,
                12.735,
                12.734,
                12.732,
                12.731,
                12.729,
                12.727,
                12.726,
                12.724,
                12.723,
                12.721,
                12.72,
                12.718,
                12.717,
                12.715,
                12.714,
                12.712,
                12.711,
                12.709,
                12.708,
                12.707,
                12.705,
                12.704,
                12.702,
                12.701,
                12.699,
                12.698,
                12.697,
                12.695,
                12.694,
                12.692,
                12.691,
                12.69,
                12.688,
                12.687,
                12.685,
                12.684,
                12.683,
                12.681,
                12.68,
                12.679,
                12.678,
                12.676,
                12.675,
                12.673,
                12.672,
                12.671,
                12.67,
                12.668,
                12.667,
                12.666,
                12.664,
                12.663,
                12.662,
                12.661,
                12.659,
                12.658,
                12.657,
                12.656,
                12.654,
                12.653,
                12.652,
                12.651,
                12.65,
                12.649,
                12.648,
                12.646,
                12.645,
                12.644,
                12.643,
                12.641,
                12.64,
                12.639,
                12.638,
                12.637,
                12.636,
                12.634,
                12.633,
                12.632,
                12.631,
                12.63,
                12.629,
                12.628,
                12.627,
                12.626,
                12.625,
                12.624,
                12.622,
                12.621,
                12.62,
                12.619,
                12.618,
                12.617,
                12.616,
                12.615,
                12.614,
                12.613,
                12.612,
                12.611,
                12.61,
                12.609,
                12.607,
                12.607,
                12.605,
                12.605,
                12.603,
                12.603,
                12.601,
                12.601,
                12.599,
                12.599,
                12.597,
                12.597,
                12.595,
                12.595,
                12.593,
                12.593,
                12.592,
                12.591,
                12.59,
                12.589,
                12.588,
                12.801,
                12.799,
                12.798,
                12.797,
                12.795,
                12.793,
                12.792,
                12.79,
                12.789,
                12.787,
                12.786,
                12.785,
                12.783,
                12.781,
                12.78,
                12.779,
                12.777,
                12.775,
                12.774,
                12.773,
                12.771,
                12.77,
                12.768,
                12.767,
                12.765,
                12.764,
                12.762,
                12.761,
                12.76,
                12.758,
                12.756,
                12.755,
                12.754,
                12.752,
                12.751,
                12.749,
                12.748,
                12.746,
                12.745,
                12.743,
                12.742,
                12.741,
                12.739,
                12.738,
                12.736,
                12.735,
                12.733,
                12.732,
                12.73,
                12.729,
                12.727,
                12.726,
                12.725,
                12.723,
                12.722,
                12.72,
                12.719,
                12.717,
                12.716,
                12.714,
                12.713,
                12.711,
                12.71,
                12.709,
                12.707,
                12.706,
                12.704,
                12.703,
                12.701,
                12.7,
                12.699,
                12.697,
                12.696,
                12.694,
                12.693,
                12.691,
                12.69,
                12.689,
                12.687,
                12.686,
                12.684,
                12.683,
                12.681,
                12.68,
                12.679,
                12.677,
                12.676,
                12.674,
                12.673,
                12.671,
                12.67,
                12.668,
                12.667,
                12.666,
                12.664,
                12.663,
                12.662,
                12.66,
                12.659,
                12.657,
                12.656,
                12.654,
                12.653,
                12.652,
                12.65,
                12.649,
                12.647,
                12.646,
                12.645,
                12.643,
                12.642,
                12.64,
                12.639,
                12.637,
                12.636,
                12.635,
                12.633,
                12.632,
                12.631,
                12.629,
                12.628,
                12.626,
                12.625,
                12.624,
                12.622,
                12.621,
                12.619,
                12.618,
                12.617,
                12.615,
                12.614,
                12.612,
                12.611,
                12.61,
                12.608,
                12.607,
                12.605,
                12.604,
                12.603,
                12.601,
                12.6,
                12.599,
                12.597,
                12.596,
                12.595,
                12.593,
                12.592,
                12.59,
                12.589,
                12.588,
                12.586,
                12.585,
                12.584,
                12.582,
                12.581,
                12.579,
                12.578,
                12.577,
                12.575,
                12.574,
                12.573,
                12.571,
                12.57,
                12.568,
                12.567,
                12.566,
                12.564,
                12.563,
                12.562,
                12.56,
                12.559,
                12.558,
                12.556,
                12.555,
                12.554,
                12.552,
                12.551,
                12.55,
                12.548,
                12.547,
                12.546,
                12.544,
                12.543,
                12.542,
                12.54,
                12.539,
                12.537,
                12.536,
                12.535,
                12.534,
                12.532,
                12.531,
                12.53,
                12.528,
                12.527,
                12.526,
                12.524,
                12.523,
                12.522,
                12.52,
                12.519,
                12.518,
                12.516,
                12.515,
                12.514,
                12.513,
                12.511,
                12.51,
                12.508,
                12.507,
                12.506,
                12.505,
                12.503,
                12.502,
                12.501,
                12.499,
                12.498,
                12.497,
                12.495,
                12.494,
                12.493,
                12.492,
                12.49,
                12.489,
                12.487,
                12.486,
                12.485,
                12.484,
                12.482,
                12.481,
                12.48,
                12.478,
                12.477,
                12.476,
                12.475,
                12.473,
                12.472,
                12.471,
                12.47,
                12.468,
                12.467,
                12.466,
                12.464,
                12.463,
                12.462,
                12.461,
                12.459,
                12.458,
                12.457,
                12.455,
                12.454,
                12.453,
                12.452,
                12.45,
                12.449,
                12.448,
                12.447,
                12.445,
                12.444,
                12.443,
                12.442,
                12.44,
                12.439,
                12.438,
                12.437,
                12.435,
                12.434,
                12.433,
                12.432,
                12.43,
                12.429,
                12.428,
                12.427,
                12.425,
                12.424,
                12.423,
                12.422,
                12.42,
                12.419,
                12.418,
                12.417,
                12.415,
                12.414,
                12.413,
                12.412,
                12.41,
                12.409,
                12.408,
                12.407,
                12.405,
                12.404,
                12.403,
                12.402,
                12.401,
                12.4,
                12.398,
                12.397,
                12.396,
                12.395,
                12.393,
                12.392,
                12.391,
                12.39,
                12.389,
                12.388,
                12.386,
                12.385,
                12.384,
                12.383,
                12.381,
                12.38,
                12.379,
                12.378,
                12.377,
                12.375,
                12.374,
                12.373,
                12.372,
                12.371,
                12.37,
                12.368,
                12.367,
                12.366,
                12.365,
                12.364,
                12.363,
                12.361,
                12.36,
                12.359,
                12.358,
                12.357,
                12.355,
                12.354,
                12.353,
                12.352,
                12.351,
                12.349,
                12.349,
                12.347,
                12.346,
                12.345,
                12.344,
                12.343,
                12.342,
                12.34,
                12.339,
                12.338,
                12.337,
                12.336,
                12.335,
                12.334,
                12.332,
                12.331,
                12.33,
                12.329,
                12.328,
                12.327,
                12.326,
                12.325,
                12.323,
                12.322,
                12.321,
                12.32,
                12.319,
                12.318,
                12.317,
                12.315,
                12.315,
                12.313,
                12.312,
                12.311,
                12.31,
                12.309,
                12.308,
                12.307,
                12.306,
                12.304,
                12.304,
                12.302,
                12.301,
                12.3,
                12.299,
                12.298,
                12.297,
                12.296,
                12.295,
                12.294,
                12.293,
                12.291,
                12.29,
                12.289,
                12.288,
                12.287,
                12.286,
                12.285,
                12.284,
                12.283,
                12.282,
                12.281,
                12.28,
                12.278,
                12.278,
                12.276,
                12.276,
                12.274,
                12.273,
                12.272,
                12.271,
                12.27,
                12.269,
                12.268,
                12.267,
                12.266,
                12.265,
                12.264,
                12.263,
                12.262,
                12.261,
                12.26,
                12.259,
                12.258,
                12.257,
                12.256,
                12.255,
                12.254,
                12.252,
                12.252,
                12.251,
                12.249,
                12.248,
                12.248,
                12.246,
                12.245,
                12.245,
                12.243,
                12.242,
                12.242,
                12.24,
                12.239,
                12.239,
                12.238,
                12.236,
                12.236,
                12.235,
                12.233,
                12.233,
                12.232,
                12.231,
                12.229,
                12.229,
                12.228,
                12.227,
                12.226,
                12.225,
                12.224,
                12.223,
                12.222,
                12.221,
                12.22,
                12.219,
                12.218,
                12.217,
                12.216,
                12.215,
                12.214,
                12.213,
                12.212,
                12.211,
                12.21,
                12.21,
                12.209,
                12.208,
                12.207,
                12.206,
                12.205,
                12.204,
                12.203,
                12.202,
                12.201,
                12.2,
                12.199,
                12.199,
                12.198,
                12.197,
                12.195,
                12.195,
                12.194,
                12.193,
                12.192,
                12.191,
                12.19,
                12.189,
                12.188,
                12.188,
                12.187,
                12.186,
                12.185,
                12.184,
                12.183,
                12.182,
                12.181,
                12.181,
                12.18,
                12.179,
                12.178,
                12.177,
                12.176,
                12.175,
                12.174,
                12.173,
                12.172,
                12.172,
                12.171,
                12.17,
                12.169,
                12.168,
                12.167,
                12.167,
                12.166,
                12.165,
                12.164,
                12.163,
                12.162,
                12.161,
                12.161,
                12.16,
                12.159,
                12.158,
                12.157,
                12.156,
                12.156,
                12.155,
                12.154,
                12.153,
                12.152,
                12.151,
                12.151,
                12.15,
                12.149,
                12.148,
                12.147,
                12.147,
                12.146,
                12.145,
                12.144,
                12.143,
                12.143,
                12.142,
                12.141,
                12.14,
                12.14,
                12.139,
                12.138,
                12.137,
                12.136,
                12.136,
                12.135,
                12.134,
                12.133,
                12.132,
                12.132,
                12.131,
                12.13,
                12.129,
                12.129,
                12.128,
                12.127,
                12.126,
                12.126,
                12.125,
                12.124,
                12.123,
                12.122,
                12.122,
                12.121,
                12.12,
                12.12,
                12.119,
                12.118,
                12.117,
                12.116,
                12.116,
                12.115,
                12.114,
                12.113,
                12.113,
                12.112,
                12.111,
                12.111,
                12.11,
                12.109,
                12.108,
                12.107,
                12.107,
                12.106,
                12.106,
                12.105,
                12.104,
                12.103,
                12.103,
                12.102,
                12.101,
                12.1,
                12.1,
                12.099,
                12.098,
                12.097,
                12.097,
                12.096,
                12.095,
                12.095,
                12.094,
                12.093,
                12.093,
                12.092,
                12.091,
                12.09,
                12.09,
                12.089,
                12.088,
                12.088,
                12.087,
                12.086,
                12.086,
                12.085,
                12.084,
                12.084,
                12.083,
                12.082,
                12.082,
                12.081,
                12.08,
                12.08,
                12.079,
                12.078,
                12.077,
                12.077,
                12.076,
                12.075,
                12.075,
                12.074,
                12.073,
                12.073,
                12.072,
                12.071,
                12.071,
                12.07,
                12.069,
                12.069,
                12.068,
                12.067,
                12.067,
                12.066,
                12.065,
                12.065,
                12.064,
                12.063,
                12.063,
                12.062,
                12.062,
                12.061,
                12.06,
                12.06,
                12.059,
                12.058,
                12.058,
                12.057,
                12.056,
                12.056,
                12.055,
                12.055,
                12.054,
                12.053,
                12.053,
                12.052,
                12.051,
                12.051,
                12.05,
                12.05,
                12.049,
                12.048,
                12.048,
                12.047,
                12.047,
                12.046,
                12.045,
                12.045,
                12.044,
                12.044,
                12.043,
                12.042,
                12.042,
                12.041,
                12.041,
                12.04,
                12.039,
                12.039,
                12.038,
                12.037,
                12.037,
                12.036,
                12.036,
                12.035,
                12.034,
                12.034,
                12.033,
                12.033,
                12.032,
                12.032,
                12.031,
                12.03,
                12.03,
                12.029,
                12.028,
                12.028,
                12.027,
                12.027,
                12.026,
                12.026,
                12.025,
                12.025,
                12.024,
                12.023,
                12.023,
                12.022,
                12.022,
                12.021,
                12.02,
                12.02,
                12.019,
                12.019,
                12.018,
                12.018,
                12.017,
                12.016,
                12.016,
                12.015,
                12.015,
                12.014,
                12.014,
                12.013,
                12.013,
                12.012,
                12.011,
                12.011,
                12.01,
                12.01,
                12.009,
                12.009,
                12.008,
                12.008,
                12.007,
                12.006,
                12.006,
                12.005,
                12.005,
                12.004,
                12.004,
                12.003,
                12.002,
                12.002,
                12.001,
                12.001,
                12.001,
                12,
                12,
                11.999,
                11.998,
                11.998,
                11.997,
                11.997,
                11.996,
                11.996,
                11.995,
                11.994,
                11.994,
                11.994,
                11.993,
                11.993,
                11.992,
                11.991,
                11.991,
                11.99,
                11.99,
                11.989,
                11.989,
                11.988,
                11.988,
                11.987,
                11.987,
                11.986,
                11.986,
                11.985,
                11.985,
                11.984,
                11.984,
                11.983,
                11.983,
                11.982,
                11.982,
                11.981,
                11.981,
                11.98,
                11.98,
                11.979,
                11.979,
                11.978,
                11.978,
                11.977,
                11.977,
                11.976,
                11.976,
                11.975,
                11.975,
                11.974,
                11.974,
                11.973,
                11.973,
                11.972,
                11.972,
                11.971,
                11.971,
                11.971,
                11.97,
                11.969,
                11.969,
                11.969,
                11.968,
                11.968,
                11.967,
                11.967,
                11.966,
                11.966,
                11.965,
                11.965,
                11.964,
                11.964,
                11.963,
                11.963,
                11.962,
                11.962,
                11.962,
                11.961,
                11.96,
                11.96,
                11.96,
                11.959,
                11.959,
                11.958,
                11.958,
                11.957,
                11.957,
                11.956,
                11.956,
                11.956,
                11.955,
                11.955,
                11.954,
                11.954,
                11.953,
                11.953,
                11.952,
                11.952,
                11.952,
                11.951,
                11.951,
                11.95,
                11.95,
                11.949,
                11.949,
                11.948,
                11.948,
                11.948,
                11.947,
                11.947,
                11.946,
                11.946,
                11.945,
                11.945,
                11.945,
                11.944,
                11.944,
                11.943,
                11.943,
                11.943,
                11.942,
                11.942,
                11.941,
                11.941,
                11.94,
                11.94,
                11.94,
                11.939,
                11.939,
                11.938,
                11.938,
                11.937,
                11.937,
                11.937,
                11.936,
                11.936,
                11.935,
                11.935,
                11.935,
                11.934,
                11.934,
                11.933,
                11.933,
                11.932,
                11.932,
                11.932,
                11.931,
                11.931,
                11.931,
                11.93,
                11.93,
                11.929,
                11.929,
                11.928,
                11.928,
                11.928,
                11.927,
                11.927,
                11.927,
                11.926,
                11.926,
                11.925,
                11.925,
                11.924,
                11.924,
                11.924,
                11.924,
                11.923,
                11.923,
                11.922,
                11.922,
                11.921,
                11.921,
                11.921,
                11.92,
                11.92,
                11.92,
                11.919,
                11.919,
                11.919,
                11.918,
                11.918,
                11.917,
                11.917,
                11.916,
                11.916,
                11.916,
                11.915,
                11.915,
                11.915,
                11.914,
                11.914,
                11.914,
                11.913,
                11.913,
                11.913,
                11.912,
                11.912,
                11.911,
                11.911,
                11.911,
                11.91,
                11.91,
                11.91,
                11.909,
                11.909,
                11.908,
                11.908,
                11.908,
                11.907,
                11.907,
                11.907,
                11.906,
                11.906,
                11.906,
                11.905,
                11.905,
                11.905,
                11.904,
                11.904,
                11.904,
                11.903,
                11.903,
                11.903,
                11.902,
                11.902,
                11.902,
                11.901,
                11.901,
                11.901,
                11.9,
                11.9,
                11.9,
                11.899,
                11.899,
                11.899,
                11.898,
                11.898,
                11.898,
                11.897,
                11.897,
                11.897,
                11.896,
                11.896,
                11.896,
                11.895,
                11.895,
                11.895,
                11.894,
                11.894,
                11.894,
                11.893,
                11.893,
                11.893,
                11.892,
                11.892,
                11.892,
                11.891,
                11.891,
                11.891,
                11.89,
                11.89,
                11.89,
                11.889,
                11.889,
                11.889,
                11.888,
                11.888,
                11.888,
                11.888,
                11.887,
                11.887,
                11.887,
                11.887,
                11.886,
                11.886,
                11.886,
                11.885,
                11.885,
                11.885,
                11.884,
                11.884,
                11.884,
                11.883,
                11.883,
                11.883,
                11.883,
                11.882,
                11.882,
                11.882,
                11.882,
                11.881,
                11.881,
                11.881,
                11.88,
                11.88,
                11.88,
                11.879,
                11.879,
                11.879,
                11.879,
                11.879,
                11.878,
                11.878,
                11.878,
                11.877,
                11.877,
                11.877,
                11.877,
                11.876,
                11.876,
                11.876,
                11.876,
                11.875,
                11.875,
                11.875,
                11.875,
                11.874,
                11.874,
                11.874,
                11.874,
                11.873,
                11.873,
                11.873,
                11.873,
                11.872,
                11.872,
                11.872,
                11.871,
                11.871,
                11.871,
                11.871,
                11.871,
                11.87,
                11.87,
                11.87,
                11.87,
                11.869,
                11.869,
                11.869,
                11.869,
                11.869,
                11.868,
                11.868,
                11.868,
                11.868,
                11.867,
                11.867,
                11.867,
                11.867,
                11.866,
                11.866,
                11.866,
                11.866,
                11.866,
                11.866,
                11.865,
                11.865,
                11.865,
                11.865,
                11.864
            ],
            P1: [
                10.811,
                10.765,
                10.718,
                10.67,
                10.62,
                10.568,
                10.515,
                10.46,
                10.512,
                10.565,
                10.617,
                10.67,
                10.723,
                10.775,
                10.828,
                10.905,
                10.985,
                11.065,
                11.145,
                11.224,
                11.303,
                11.38,
                11.456,
                11.53,
                11.604,
                11.675,
                11.745,
                11.814,
                11.881,
                11.947,
                12.011,
                12.073,
                12.134,
                12.192,
                12.249,
                12.304,
                12.358,
                12.41,
                12.46,
                12.509,
                12.557,
                12.603,
                12.648,
                12.692,
                12.734,
                12.775,
                12.816,
                12.855,
                12.893,
                12.93,
                12.966,
                13.002,
                13.036,
                13.07,
                13.103,
                13.134,
                13.165,
                13.196,
                13.225,
                13.254,
                13.282,
                13.309,
                13.335,
                13.361,
                13.386,
                13.411,
                13.435,
                13.458,
                13.48,
                13.502,
                13.524,
                13.544,
                13.565,
                13.584,
                13.603,
                13.622,
                13.64,
                13.658,
                13.675,
                13.692,
                13.708,
                13.724,
                13.74,
                13.755,
                13.769,
                13.784,
                13.798,
                13.811,
                13.825,
                13.838,
                13.85,
                13.863,
                13.875,
                13.887,
                13.898,
                13.91,
                13.921,
                13.931,
                13.942,
                13.952,
                13.962,
                13.972,
                13.982,
                13.991,
                14.001,
                14.01,
                14.019,
                14.027,
                14.036,
                14.044,
                14.053,
                14.06,
                14.069,
                14.076,
                14.084,
                14.091,
                14.099,
                14.106,
                14.113,
                14.12,
                14.127,
                14.134,
                14.14,
                14.147,
                14.153,
                14.16,
                14.166,
                14.172,
                14.178,
                14.184,
                14.189,
                14.195,
                14.201,
                14.206,
                14.212,
                14.217,
                14.222,
                14.227,
                14.232,
                14.237,
                14.242,
                14.246,
                14.251,
                14.255,
                14.26,
                14.264,
                14.269,
                14.273,
                14.277,
                14.281,
                14.284,
                14.288,
                14.292,
                14.295,
                14.299,
                14.303,
                14.306,
                14.309,
                14.312,
                14.315,
                14.318,
                14.321,
                14.324,
                14.327,
                14.33,
                14.333,
                14.335,
                14.338,
                14.34,
                14.342,
                14.345,
                14.347,
                14.349,
                14.351,
                14.353,
                14.355,
                14.357,
                14.359,
                14.361,
                14.362,
                14.364,
                14.366,
                14.367,
                14.369,
                14.37,
                14.371,
                14.373,
                14.374,
                14.375,
                14.376,
                14.377,
                14.378,
                14.379,
                14.38,
                14.381,
                14.382,
                14.382,
                14.383,
                14.383,
                14.384,
                14.384,
                14.385,
                14.385,
                14.385,
                14.386,
                14.386,
                14.386,
                14.386,
                14.386,
                14.386,
                14.386,
                14.386,
                14.385,
                14.385,
                14.385,
                14.385,
                14.384,
                14.384,
                14.383,
                14.383,
                14.382,
                14.382,
                14.381,
                14.38,
                14.379,
                14.379,
                14.378,
                14.376,
                14.376,
                14.375,
                14.374,
                14.373,
                14.372,
                14.37,
                14.369,
                14.368,
                14.367,
                14.365,
                14.364,
                14.363,
                14.361,
                14.36,
                14.358,
                14.356,
                14.355,
                14.353,
                14.352,
                14.35,
                14.348,
                14.347,
                14.345,
                14.343,
                14.341,
                14.339,
                14.338,
                14.336,
                14.334,
                14.332,
                14.33,
                14.328,
                14.326,
                14.324,
                14.322,
                14.319,
                14.317,
                14.315,
                14.313,
                14.311,
                14.308,
                14.306,
                14.304,
                14.302,
                14.299,
                14.297,
                14.294,
                14.292,
                14.29,
                14.287,
                14.285,
                14.282,
                14.28,
                14.277,
                14.275,
                14.273,
                14.27,
                14.267,
                14.265,
                14.262,
                14.26,
                14.257,
                14.254,
                14.252,
                14.249,
                14.247,
                14.244,
                14.241,
                14.238,
                14.236,
                14.233,
                14.23,
                14.227,
                14.225,
                14.222,
                14.219,
                14.217,
                14.214,
                14.211,
                14.208,
                14.205,
                14.202,
                14.2,
                14.197,
                14.194,
                14.191,
                14.188,
                14.185,
                14.182,
                14.18,
                14.177,
                14.174,
                14.171,
                14.168,
                14.165,
                14.162,
                14.159,
                14.156,
                14.153,
                14.151,
                14.148,
                14.145,
                14.142,
                14.139,
                14.136,
                14.133,
                14.13,
                14.127,
                14.124,
                14.121,
                14.118,
                14.116,
                14.113,
                14.11,
                14.107,
                14.104,
                14.101,
                14.098,
                14.095,
                14.092,
                14.089,
                14.086,
                14.083,
                14.08,
                14.077,
                14.074,
                14.071,
                14.068,
                14.065,
                14.062,
                14.059,
                14.056,
                14.054,
                14.051,
                14.048,
                14.044,
                14.041,
                14.039,
                14.036,
                14.033,
                14.03,
                14.027,
                14.024,
                14.021,
                14.018,
                14.015,
                14.012,
                14.009,
                14.006,
                14.003,
                14,
                13.997,
                13.994,
                13.991,
                13.988,
                13.985,
                13.982,
                13.98,
                13.977,
                13.973,
                13.971,
                13.968,
                13.965,
                13.962,
                13.959,
                13.956,
                13.953,
                13.95,
                13.947,
                13.944,
                13.941,
                13.938,
                13.935,
                13.933,
                13.93,
                13.927,
                13.924,
                13.921,
                13.918,
                13.915,
                13.912,
                13.91,
                13.906,
                13.904,
                13.901,
                13.898,
                13.895,
                13.892,
                13.889,
                13.887,
                13.884,
                13.881,
                13.878,
                13.875,
                13.872,
                13.87,
                13.867,
                13.864,
                13.861,
                13.858,
                13.856,
                13.853,
                13.85,
                13.847,
                13.844,
                13.842,
                13.839,
                13.836,
                13.833,
                13.83,
                13.827,
                13.825,
                13.822,
                13.819,
                13.816,
                13.814,
                13.811,
                13.808,
                13.805,
                13.803,
                13.8,
                13.798,
                13.795,
                13.792,
                13.789,
                13.787,
                13.784,
                13.781,
                13.779,
                13.776,
                13.773,
                13.77,
                13.768,
                13.765,
                13.762,
                13.76,
                13.757,
                13.754,
                13.752,
                13.749,
                13.747,
                13.744,
                13.741,
                13.739,
                13.736,
                13.734,
                13.731,
                13.729,
                13.726,
                13.723,
                13.721,
                13.718,
                13.716,
                13.713,
                13.711,
                13.708,
                13.706,
                13.703,
                13.7,
                13.698,
                13.695,
                13.693,
                13.69,
                13.688,
                13.686,
                13.683,
                13.68,
                13.678,
                13.675,
                13.673,
                13.671,
                13.668,
                13.666,
                13.663,
                13.661,
                13.658,
                13.656,
                13.653,
                13.651,
                13.649,
                13.646,
                13.644,
                13.641,
                13.639,
                13.637,
                13.634,
                13.632,
                13.629,
                13.627,
                13.625,
                13.622,
                13.62,
                13.618,
                13.615,
                13.613,
                13.611,
                13.608,
                13.606,
                13.604,
                13.601,
                13.599,
                13.597,
                13.595,
                13.592,
                13.59,
                13.588,
                13.586,
                13.583,
                13.581,
                13.579,
                13.576,
                13.574,
                13.572,
                13.57,
                13.568,
                13.565,
                13.563,
                13.561,
                13.559,
                13.557,
                13.554,
                13.552,
                13.55,
                13.548,
                13.546,
                13.544,
                13.541,
                13.539,
                13.537,
                13.535,
                13.533,
                13.531,
                13.529,
                13.527,
                13.524,
                13.522,
                13.52,
                13.518,
                13.516,
                13.514,
                13.512,
                13.51,
                13.508,
                13.506,
                13.504,
                13.502,
                13.5,
                13.498,
                13.496,
                13.494,
                13.492,
                13.49,
                13.488,
                13.486,
                13.484,
                13.482,
                13.48,
                13.478,
                13.476,
                13.474,
                13.472,
                13.47,
                13.468,
                13.466,
                13.464,
                13.463,
                13.461,
                13.459,
                13.457,
                13.455,
                13.453,
                13.452,
                13.45,
                13.448,
                13.446,
                13.444,
                13.442,
                13.44,
                13.439,
                13.437,
                13.435,
                13.433,
                13.432,
                13.43,
                13.428,
                13.426,
                13.424,
                13.423,
                13.421,
                13.419,
                13.418,
                13.416,
                13.414,
                13.412,
                13.411,
                13.409,
                13.407,
                13.406,
                13.404,
                13.402,
                13.401,
                13.399,
                13.397,
                13.396,
                13.394,
                13.392,
                13.391,
                13.389,
                13.388,
                13.386,
                13.385,
                13.383,
                13.381,
                13.38,
                13.378,
                13.377,
                13.375,
                13.374,
                13.372,
                13.371,
                13.369,
                13.367,
                13.366,
                13.364,
                13.363,
                13.361,
                13.36,
                13.358,
                13.357,
                13.355,
                13.354,
                13.353,
                13.351,
                13.35,
                13.348,
                13.347,
                13.345,
                13.344,
                13.343,
                13.341,
                13.34,
                13.338,
                13.337,
                13.336,
                13.334,
                13.333,
                13.332,
                13.33,
                13.329,
                13.327,
                13.326,
                13.325,
                13.324,
                13.322,
                13.321,
                13.319,
                13.318,
                13.317,
                13.316,
                13.314,
                13.313,
                13.312,
                13.31,
                13.309,
                13.308,
                13.307,
                13.305,
                13.304,
                13.303,
                13.302,
                13.3,
                13.299,
                13.298,
                13.297,
                13.295,
                13.294,
                13.293,
                13.292,
                13.291,
                13.289,
                13.288,
                13.287,
                13.286,
                13.285,
                13.283,
                13.282,
                13.281,
                13.28,
                13.279,
                13.278,
                13.276,
                13.275,
                13.274,
                13.273,
                13.272,
                13.271,
                13.27,
                13.269,
                13.267,
                13.266,
                13.265,
                13.264,
                13.263,
                13.492,
                13.491,
                13.49,
                13.488,
                13.487,
                13.485,
                13.484,
                13.483,
                13.481,
                13.48,
                13.478,
                13.477,
                13.476,
                13.474,
                13.473,
                13.471,
                13.47,
                13.469,
                13.467,
                13.466,
                13.465,
                13.463,
                13.462,
                13.46,
                13.459,
                13.458,
                13.456,
                13.455,
                13.454,
                13.452,
                13.451,
                13.449,
                13.448,
                13.447,
                13.445,
                13.444,
                13.443,
                13.441,
                13.44,
                13.438,
                13.437,
                13.436,
                13.434,
                13.433,
                13.432,
                13.43,
                13.429,
                13.428,
                13.426,
                13.425,
                13.423,
                13.422,
                13.421,
                13.419,
                13.418,
                13.417,
                13.415,
                13.414,
                13.413,
                13.411,
                13.41,
                13.409,
                13.407,
                13.406,
                13.404,
                13.403,
                13.402,
                13.401,
                13.399,
                13.398,
                13.397,
                13.395,
                13.394,
                13.393,
                13.391,
                13.39,
                13.388,
                13.387,
                13.386,
                13.384,
                13.383,
                13.382,
                13.38,
                13.379,
                13.378,
                13.376,
                13.375,
                13.374,
                13.372,
                13.371,
                13.37,
                13.368,
                13.367,
                13.366,
                13.365,
                13.363,
                13.362,
                13.361,
                13.359,
                13.358,
                13.357,
                13.355,
                13.354,
                13.353,
                13.351,
                13.35,
                13.349,
                13.347,
                13.346,
                13.345,
                13.343,
                13.342,
                13.341,
                13.339,
                13.338,
                13.337,
                13.335,
                13.334,
                13.333,
                13.332,
                13.33,
                13.329,
                13.328,
                13.326,
                13.325,
                13.324,
                13.323,
                13.321,
                13.32,
                13.319,
                13.318,
                13.316,
                13.315,
                13.314,
                13.312,
                13.311,
                13.309,
                13.308,
                13.307,
                13.306,
                13.304,
                13.303,
                13.302,
                13.301,
                13.299,
                13.298,
                13.297,
                13.295,
                13.294,
                13.293,
                13.291,
                13.29,
                13.289,
                13.288,
                13.286,
                13.285,
                13.284,
                13.283,
                13.281,
                13.28,
                13.279,
                13.277,
                13.276,
                13.275,
                13.274,
                13.273,
                13.271,
                13.27,
                13.269,
                13.267,
                13.266,
                13.265,
                13.264,
                13.262,
                13.261,
                13.26,
                13.258,
                13.257,
                13.256,
                13.255,
                13.254,
                13.252,
                13.251,
                13.25,
                13.248,
                13.247,
                13.246,
                13.245,
                13.244,
                13.242,
                13.241,
                13.24,
                13.238,
                13.237,
                13.236,
                13.235,
                13.233,
                13.232,
                13.231,
                13.23,
                13.229,
                13.227,
                13.226,
                13.225,
                13.224,
                13.223,
                13.221,
                13.22,
                13.219,
                13.217,
                13.216,
                13.215,
                13.214,
                13.212,
                13.211,
                13.21,
                13.209,
                13.207,
                13.206,
                13.205,
                13.204,
                13.203,
                13.201,
                13.2,
                13.199,
                13.198,
                13.196,
                13.195,
                13.194,
                13.193,
                13.192,
                13.19,
                13.189,
                13.188,
                13.187,
                13.185,
                13.184,
                13.183,
                13.182,
                13.181,
                13.18,
                13.178,
                13.177,
                13.176,
                13.175,
                13.174,
                13.172,
                13.171,
                13.17,
                13.169,
                13.167,
                13.166,
                13.165,
                13.164,
                13.163,
                13.162,
                13.16,
                13.159,
                13.158,
                13.157,
                13.156,
                13.154,
                13.153,
                13.152,
                13.151,
                13.15,
                13.149,
                13.147,
                13.146,
                13.145,
                13.144,
                13.142,
                13.141,
                13.14,
                13.139,
                13.138,
                13.137,
                13.135,
                13.134,
                13.133,
                13.132,
                13.131,
                13.13,
                13.128,
                13.127,
                13.126,
                13.125,
                13.124,
                13.123,
                13.121,
                13.12,
                13.119,
                13.118,
                13.117,
                13.116,
                13.115,
                13.113,
                13.112,
                13.111,
                13.11,
                13.109,
                13.108,
                13.107,
                13.105,
                13.104,
                13.103,
                13.102,
                13.101,
                13.1,
                13.099,
                13.098,
                13.096,
                13.095,
                13.094,
                13.093,
                13.092,
                13.09,
                13.09,
                13.088,
                13.087,
                13.086,
                13.085,
                13.084,
                13.083,
                13.082,
                13.081,
                13.079,
                13.078,
                13.077,
                13.076,
                13.075,
                13.074,
                13.073,
                13.072,
                13.071,
                13.069,
                13.068,
                13.067,
                13.066,
                13.065,
                13.064,
                13.063,
                13.062,
                13.061,
                13.059,
                13.058,
                13.057,
                13.056,
                13.055,
                13.054,
                13.053,
                13.052,
                13.05,
                13.05,
                13.048,
                13.047,
                13.046,
                13.045,
                13.044,
                13.043,
                13.042,
                13.041,
                13.04,
                13.039,
                13.037,
                13.037,
                13.035,
                13.035,
                13.033,
                13.032,
                13.031,
                13.03,
                13.029,
                13.028,
                13.027,
                13.026,
                13.025,
                13.024,
                13.023,
                13.021,
                13.021,
                13.019,
                13.019,
                13.017,
                13.016,
                13.015,
                13.014,
                13.013,
                13.012,
                13.011,
                13.01,
                13.009,
                13.008,
                13.007,
                13.006,
                13.005,
                13.004,
                13.003,
                13.002,
                13.001,
                13,
                12.999,
                12.998,
                12.997,
                12.996,
                12.995,
                12.994,
                12.993,
                12.992,
                12.991,
                12.99,
                12.988,
                12.988,
                12.986,
                12.985,
                12.985,
                12.984,
                12.982,
                12.982,
                12.98,
                12.979,
                12.979,
                12.978,
                12.977,
                12.976,
                12.974,
                12.974,
                12.973,
                12.972,
                12.97,
                12.97,
                12.969,
                12.968,
                12.967,
                12.966,
                12.965,
                12.964,
                12.963,
                12.962,
                12.961,
                12.96,
                12.959,
                12.958,
                12.957,
                12.956,
                12.955,
                12.954,
                12.953,
                12.952,
                12.951,
                12.95,
                12.949,
                12.948,
                12.947,
                12.946,
                12.946,
                12.945,
                12.944,
                12.943,
                12.942,
                12.941,
                12.94,
                12.939,
                12.938,
                12.937,
                12.936,
                12.935,
                12.934,
                12.933,
                12.933,
                12.932,
                12.931,
                12.93,
                12.929,
                12.928,
                12.927,
                12.926,
                12.925,
                12.924,
                12.923,
                12.922,
                12.922,
                12.921,
                12.92,
                12.919,
                12.918,
                12.917,
                12.916,
                12.915,
                12.914,
                12.914,
                12.913,
                12.912,
                12.911,
                12.91,
                12.909,
                12.908,
                12.907,
                12.906,
                12.906,
                12.905,
                12.904,
                12.903,
                12.902,
                12.901,
                12.9,
                12.9,
                12.899,
                12.898,
                12.897,
                12.896,
                12.895,
                12.894,
                12.893,
                12.892,
                12.892,
                12.891,
                12.89,
                12.889,
                12.888,
                12.888,
                12.887,
                12.886,
                12.885,
                12.884,
                12.884,
                12.883,
                12.882,
                12.881,
                12.88,
                12.879,
                12.878,
                12.878,
                12.877,
                12.876,
                12.875,
                12.874,
                12.874,
                12.873,
                12.872,
                12.871,
                12.87,
                12.87,
                12.869,
                12.868,
                12.867,
                12.866,
                12.865,
                12.865,
                12.864,
                12.863,
                12.862,
                12.861,
                12.861,
                12.86,
                12.859,
                12.858,
                12.858,
                12.857,
                12.856,
                12.855,
                12.855,
                12.854,
                12.853,
                12.852,
                12.851,
                12.85,
                12.85,
                12.849,
                12.848,
                12.848,
                12.847,
                12.846,
                12.845,
                12.844,
                12.844,
                12.843,
                12.842,
                12.841,
                12.841,
                12.84,
                12.839,
                12.838,
                12.838,
                12.837,
                12.836,
                12.835,
                12.835,
                12.834,
                12.833,
                12.832,
                12.832,
                12.831,
                12.83,
                12.829,
                12.829,
                12.828,
                12.827,
                12.827,
                12.826,
                12.825,
                12.824,
                12.824,
                12.823,
                12.822,
                12.821,
                12.821,
                12.82,
                12.819,
                12.819,
                12.818,
                12.817,
                12.817,
                12.816,
                12.815,
                12.814,
                12.814,
                12.813,
                12.812,
                12.811,
                12.811,
                12.81,
                12.809,
                12.809,
                12.808,
                12.807,
                12.807,
                12.806,
                12.805,
                12.805,
                12.804,
                12.803,
                12.802,
                12.802,
                12.801,
                12.8,
                12.8,
                12.799,
                12.798,
                12.798,
                12.797,
                12.796,
                12.796,
                12.795,
                12.794,
                12.793,
                12.793,
                12.792,
                12.791,
                12.791,
                12.79,
                12.789,
                12.789,
                12.788,
                12.787,
                12.787,
                12.786,
                12.785,
                12.785,
                12.784,
                12.783,
                12.783,
                12.782,
                12.781,
                12.781,
                12.78,
                12.779,
                12.779,
                12.778,
                12.777,
                12.777,
                12.776,
                12.776,
                12.775,
                12.774,
                12.774,
                12.773,
                12.772,
                12.772,
                12.771,
                12.77,
                12.77,
                12.769,
                12.769,
                12.768,
                12.767,
                12.767,
                12.766,
                12.765,
                12.765,
                12.764,
                12.763,
                12.763,
                12.762,
                12.761,
                12.761,
                12.76,
                12.76,
                12.759,
                12.758,
                12.758,
                12.757,
                12.756,
                12.756,
                12.755,
                12.755,
                12.754,
                12.753,
                12.753,
                12.752,
                12.752,
                12.751,
                12.75,
                12.75,
                12.749,
                12.748,
                12.748,
                12.747,
                12.747,
                12.746,
                12.745,
                12.745,
                12.744,
                12.743,
                12.743,
                12.742,
                12.742,
                12.741,
                12.74,
                12.74,
                12.739,
                12.739,
                12.738,
                12.738,
                12.737,
                12.736,
                12.736,
                12.735,
                12.734,
                12.734,
                12.733,
                12.733,
                12.732,
                12.731,
                12.731,
                12.73,
                12.73,
                12.729,
                12.729,
                12.728,
                12.727,
                12.727,
                12.726,
                12.726,
                12.725,
                12.725,
                12.724,
                12.723,
                12.723,
                12.722,
                12.721,
                12.721,
                12.72,
                12.72,
                12.719,
                12.719,
                12.718,
                12.717,
                12.717,
                12.716,
                12.716,
                12.715,
                12.715,
                12.714,
                12.713,
                12.713,
                12.712,
                12.712,
                12.711,
                12.711,
                12.71,
                12.71,
                12.709,
                12.709,
                12.708,
                12.708,
                12.707,
                12.706,
                12.706,
                12.705,
                12.705,
                12.704,
                12.703,
                12.703,
                12.702,
                12.702,
                12.701,
                12.701,
                12.7,
                12.7,
                12.699,
                12.699,
                12.698,
                12.697,
                12.697,
                12.696,
                12.696,
                12.695,
                12.695,
                12.694,
                12.694,
                12.693,
                12.693,
                12.692,
                12.691,
                12.691,
                12.69,
                12.69,
                12.689,
                12.689,
                12.688,
                12.688,
                12.687,
                12.687,
                12.686,
                12.686,
                12.685,
                12.685,
                12.684,
                12.683,
                12.683,
                12.683,
                12.682,
                12.682,
                12.681,
                12.68,
                12.68,
                12.679,
                12.679,
                12.678,
                12.678,
                12.677,
                12.677,
                12.676,
                12.676,
                12.675,
                12.675,
                12.674,
                12.674,
                12.673,
                12.673,
                12.672,
                12.672,
                12.671,
                12.671,
                12.67,
                12.67,
                12.669,
                12.669,
                12.668,
                12.668,
                12.667,
                12.667,
                12.666,
                12.666,
                12.665,
                12.665,
                12.664,
                12.664,
                12.663,
                12.663,
                12.662,
                12.662,
                12.661,
                12.661,
                12.66,
                12.66,
                12.659,
                12.659,
                12.658,
                12.658,
                12.657,
                12.657,
                12.656,
                12.656,
                12.655,
                12.655,
                12.655,
                12.654,
                12.653,
                12.653,
                12.653,
                12.652,
                12.652,
                12.651,
                12.651,
                12.65,
                12.65,
                12.649,
                12.649,
                12.648,
                12.648,
                12.647,
                12.647,
                12.646,
                12.646,
                12.646,
                12.645,
                12.645,
                12.644,
                12.644,
                12.643,
                12.643,
                12.642,
                12.642,
                12.641,
                12.641,
                12.64,
                12.64,
                12.639,
                12.639,
                12.638,
                12.638,
                12.638,
                12.637,
                12.637,
                12.636,
                12.636,
                12.635,
                12.635,
                12.634,
                12.634,
                12.633,
                12.633,
                12.633,
                12.632,
                12.632,
                12.631,
                12.631,
                12.63,
                12.63,
                12.63,
                12.629,
                12.629,
                12.628,
                12.628,
                12.627,
                12.627,
                12.626,
                12.626,
                12.626,
                12.625,
                12.625,
                12.624,
                12.624,
                12.623,
                12.623,
                12.623,
                12.622,
                12.622,
                12.621,
                12.621,
                12.62,
                12.62,
                12.619,
                12.619,
                12.619,
                12.618,
                12.618,
                12.617,
                12.617,
                12.616,
                12.616,
                12.616,
                12.615,
                12.615,
                12.614,
                12.614,
                12.613,
                12.613,
                12.613,
                12.612,
                12.612,
                12.611,
                12.611,
                12.611,
                12.61,
                12.61,
                12.609,
                12.609,
                12.608,
                12.608,
                12.608,
                12.607,
                12.607,
                12.606,
                12.606,
                12.606,
                12.605,
                12.605,
                12.604,
                12.604,
                12.604,
                12.603,
                12.603,
                12.602,
                12.602,
                12.601,
                12.601,
                12.601,
                12.6,
                12.6,
                12.6,
                12.599,
                12.599,
                12.598,
                12.598,
                12.598,
                12.597,
                12.597,
                12.596,
                12.596,
                12.596,
                12.595,
                12.595,
                12.594,
                12.594,
                12.594,
                12.593,
                12.593,
                12.592,
                12.592,
                12.592,
                12.591,
                12.591,
                12.591,
                12.59,
                12.59,
                12.59,
                12.589,
                12.589,
                12.589,
                12.588,
                12.588,
                12.587,
                12.587,
                12.587,
                12.586,
                12.586,
                12.586,
                12.585,
                12.585,
                12.584,
                12.584,
                12.584,
                12.583,
                12.583,
                12.583,
                12.582,
                12.582,
                12.582,
                12.581,
                12.581,
                12.58,
                12.58,
                12.58,
                12.579,
                12.579,
                12.579,
                12.578,
                12.578,
                12.578,
                12.577,
                12.577,
                12.577,
                12.576,
                12.576,
                12.576,
                12.575,
                12.575,
                12.575,
                12.574,
                12.574,
                12.574,
                12.573,
                12.573,
                12.573,
                12.572,
                12.572,
                12.572,
                12.571,
                12.571,
                12.571,
                12.57,
                12.57,
                12.57,
                12.569,
                12.569,
                12.569,
                12.568,
                12.568,
                12.568,
                12.567,
                12.567,
                12.567,
                12.567,
                12.566,
                12.566,
                12.566,
                12.565,
                12.565,
                12.565,
                12.565,
                12.564,
                12.564,
                12.563,
                12.563,
                12.563,
                12.562,
                12.562,
                12.562,
                12.562,
                12.561,
                12.561,
                12.561,
                12.56
            ],
            P3: [
                11.254,
                11.218,
                11.182,
                11.144,
                11.106,
                11.067,
                11.027,
                10.986,
                11.036,
                11.086,
                11.136,
                11.187,
                11.237,
                11.287,
                11.337,
                11.414,
                11.494,
                11.575,
                11.655,
                11.735,
                11.815,
                11.893,
                11.97,
                12.045,
                12.119,
                12.192,
                12.263,
                12.332,
                12.4,
                12.467,
                12.532,
                12.595,
                12.656,
                12.716,
                12.773,
                12.829,
                12.884,
                12.936,
                12.987,
                13.037,
                13.085,
                13.132,
                13.177,
                13.221,
                13.265,
                13.306,
                13.347,
                13.387,
                13.425,
                13.463,
                13.499,
                13.535,
                13.57,
                13.604,
                13.637,
                13.669,
                13.7,
                13.731,
                13.76,
                13.789,
                13.817,
                13.845,
                13.871,
                13.897,
                13.923,
                13.947,
                13.971,
                13.995,
                14.017,
                14.039,
                14.06,
                14.081,
                14.101,
                14.121,
                14.14,
                14.159,
                14.177,
                14.195,
                14.211,
                14.228,
                14.245,
                14.26,
                14.276,
                14.291,
                14.306,
                14.32,
                14.334,
                14.347,
                14.361,
                14.374,
                14.386,
                14.399,
                14.41,
                14.422,
                14.433,
                14.445,
                14.456,
                14.466,
                14.477,
                14.487,
                14.497,
                14.506,
                14.516,
                14.525,
                14.534,
                14.543,
                14.552,
                14.561,
                14.569,
                14.577,
                14.585,
                14.593,
                14.601,
                14.608,
                14.616,
                14.623,
                14.631,
                14.638,
                14.644,
                14.652,
                14.658,
                14.665,
                14.671,
                14.677,
                14.684,
                14.69,
                14.696,
                14.702,
                14.708,
                14.713,
                14.719,
                14.724,
                14.73,
                14.735,
                14.74,
                14.745,
                14.75,
                14.755,
                14.76,
                14.765,
                14.77,
                14.774,
                14.778,
                14.783,
                14.787,
                14.791,
                14.795,
                14.799,
                14.803,
                14.807,
                14.81,
                14.814,
                14.817,
                14.821,
                14.824,
                14.827,
                14.831,
                14.834,
                14.837,
                14.84,
                14.842,
                14.845,
                14.848,
                14.851,
                14.853,
                14.856,
                14.858,
                14.86,
                14.862,
                14.865,
                14.867,
                14.869,
                14.871,
                14.873,
                14.875,
                14.876,
                14.878,
                14.88,
                14.881,
                14.883,
                14.884,
                14.885,
                14.887,
                14.888,
                14.889,
                14.89,
                14.891,
                14.892,
                14.893,
                14.894,
                14.895,
                14.896,
                14.897,
                14.897,
                14.898,
                14.898,
                14.899,
                14.899,
                14.899,
                14.9,
                14.9,
                14.9,
                14.9,
                14.9,
                14.9,
                14.9,
                14.9,
                14.9,
                14.9,
                14.9,
                14.899,
                14.899,
                14.898,
                14.898,
                14.897,
                14.897,
                14.896,
                14.895,
                14.895,
                14.894,
                14.893,
                14.892,
                14.891,
                14.89,
                14.889,
                14.888,
                14.887,
                14.886,
                14.885,
                14.884,
                14.882,
                14.881,
                14.88,
                14.878,
                14.877,
                14.876,
                14.874,
                14.872,
                14.871,
                14.869,
                14.867,
                14.866,
                14.864,
                14.862,
                14.86,
                14.859,
                14.857,
                14.855,
                14.853,
                14.851,
                14.849,
                14.847,
                14.845,
                14.842,
                14.841,
                14.838,
                14.836,
                14.834,
                14.832,
                14.829,
                14.827,
                14.825,
                14.823,
                14.82,
                14.818,
                14.815,
                14.813,
                14.81,
                14.808,
                14.805,
                14.803,
                14.8,
                14.798,
                14.795,
                14.792,
                14.79,
                14.787,
                14.785,
                14.782,
                14.779,
                14.776,
                14.774,
                14.771,
                14.768,
                14.765,
                14.763,
                14.76,
                14.757,
                14.754,
                14.751,
                14.748,
                14.746,
                14.743,
                14.74,
                14.737,
                14.734,
                14.731,
                14.728,
                14.725,
                14.722,
                14.719,
                14.716,
                14.713,
                14.71,
                14.707,
                14.704,
                14.701,
                14.698,
                14.695,
                14.692,
                14.689,
                14.686,
                14.683,
                14.679,
                14.676,
                14.673,
                14.67,
                14.667,
                14.664,
                14.661,
                14.658,
                14.654,
                14.651,
                14.648,
                14.645,
                14.642,
                14.639,
                14.636,
                14.633,
                14.629,
                14.626,
                14.623,
                14.62,
                14.617,
                14.614,
                14.61,
                14.607,
                14.604,
                14.601,
                14.598,
                14.595,
                14.591,
                14.588,
                14.585,
                14.582,
                14.579,
                14.576,
                14.572,
                14.569,
                14.566,
                14.563,
                14.559,
                14.556,
                14.553,
                14.55,
                14.547,
                14.544,
                14.54,
                14.537,
                14.534,
                14.531,
                14.528,
                14.525,
                14.521,
                14.518,
                14.515,
                14.512,
                14.509,
                14.505,
                14.502,
                14.499,
                14.496,
                14.493,
                14.489,
                14.486,
                14.483,
                14.48,
                14.477,
                14.474,
                14.471,
                14.467,
                14.464,
                14.461,
                14.458,
                14.455,
                14.452,
                14.448,
                14.445,
                14.442,
                14.439,
                14.436,
                14.433,
                14.43,
                14.426,
                14.423,
                14.42,
                14.417,
                14.414,
                14.411,
                14.407,
                14.404,
                14.401,
                14.398,
                14.395,
                14.392,
                14.389,
                14.386,
                14.383,
                14.38,
                14.377,
                14.373,
                14.37,
                14.367,
                14.364,
                14.361,
                14.358,
                14.355,
                14.352,
                14.349,
                14.346,
                14.343,
                14.34,
                14.337,
                14.334,
                14.331,
                14.328,
                14.325,
                14.322,
                14.319,
                14.316,
                14.313,
                14.31,
                14.307,
                14.304,
                14.301,
                14.298,
                14.295,
                14.292,
                14.289,
                14.286,
                14.283,
                14.28,
                14.277,
                14.274,
                14.271,
                14.268,
                14.265,
                14.263,
                14.26,
                14.257,
                14.254,
                14.251,
                14.248,
                14.245,
                14.242,
                14.239,
                14.237,
                14.234,
                14.231,
                14.228,
                14.225,
                14.222,
                14.219,
                14.217,
                14.214,
                14.211,
                14.208,
                14.205,
                14.203,
                14.2,
                14.197,
                14.194,
                14.191,
                14.189,
                14.186,
                14.183,
                14.18,
                14.178,
                14.175,
                14.172,
                14.169,
                14.167,
                14.164,
                14.161,
                14.159,
                14.156,
                14.153,
                14.151,
                14.148,
                14.145,
                14.142,
                14.14,
                14.137,
                14.134,
                14.132,
                14.129,
                14.127,
                14.124,
                14.121,
                14.119,
                14.116,
                14.114,
                14.111,
                14.108,
                14.106,
                14.103,
                14.1,
                14.098,
                14.095,
                14.093,
                14.09,
                14.088,
                14.085,
                14.083,
                14.08,
                14.077,
                14.075,
                14.072,
                14.07,
                14.067,
                14.065,
                14.062,
                14.06,
                14.057,
                14.055,
                14.053,
                14.05,
                14.048,
                14.045,
                14.043,
                14.04,
                14.038,
                14.035,
                14.033,
                14.031,
                14.028,
                14.026,
                14.023,
                14.021,
                14.019,
                14.016,
                14.014,
                14.011,
                14.009,
                14.007,
                14.004,
                14.002,
                14,
                13.997,
                13.995,
                13.993,
                13.99,
                13.988,
                13.986,
                13.983,
                13.981,
                13.979,
                13.977,
                13.974,
                13.972,
                13.97,
                13.968,
                13.965,
                13.963,
                13.961,
                13.959,
                13.957,
                13.954,
                13.952,
                13.95,
                13.948,
                13.946,
                13.943,
                13.941,
                13.939,
                13.937,
                13.935,
                13.933,
                13.93,
                13.928,
                13.926,
                13.924,
                13.922,
                13.92,
                13.918,
                13.916,
                13.914,
                13.912,
                13.909,
                13.908,
                13.905,
                13.903,
                13.901,
                13.899,
                13.897,
                13.895,
                13.893,
                13.891,
                13.889,
                13.887,
                13.885,
                13.883,
                13.881,
                13.88,
                13.878,
                13.876,
                13.874,
                13.872,
                13.87,
                13.868,
                13.866,
                13.864,
                13.862,
                13.86,
                13.858,
                13.857,
                13.855,
                13.853,
                13.851,
                13.849,
                13.847,
                13.845,
                13.844,
                13.842,
                13.84,
                13.838,
                13.837,
                13.835,
                13.833,
                13.831,
                13.829,
                13.828,
                13.826,
                13.824,
                13.823,
                13.821,
                13.819,
                13.817,
                13.816,
                13.814,
                13.812,
                13.811,
                13.809,
                13.807,
                13.806,
                13.804,
                13.802,
                13.801,
                13.799,
                13.797,
                13.796,
                13.794,
                13.793,
                13.791,
                13.789,
                13.788,
                13.786,
                13.785,
                13.783,
                13.782,
                13.78,
                13.778,
                13.777,
                13.775,
                13.774,
                13.772,
                13.771,
                13.769,
                13.768,
                13.766,
                13.765,
                13.763,
                13.762,
                13.76,
                13.759,
                13.757,
                13.756,
                13.754,
                13.753,
                13.751,
                13.75,
                13.749,
                13.747,
                13.746,
                13.744,
                13.743,
                13.742,
                13.74,
                13.739,
                13.738,
                13.736,
                13.735,
                13.733,
                13.732,
                13.731,
                13.729,
                13.728,
                13.727,
                13.725,
                13.724,
                13.722,
                13.721,
                13.72,
                13.719,
                13.717,
                13.716,
                13.715,
                13.713,
                13.712,
                13.711,
                13.709,
                13.708,
                13.707,
                13.706,
                13.704,
                13.703,
                13.702,
                13.701,
                13.699,
                13.698,
                13.697,
                13.696,
                13.694,
                13.693,
                13.692,
                13.691,
                13.69,
                13.688,
                13.687,
                13.686,
                13.685,
                13.924,
                13.923,
                13.921,
                13.92,
                13.918,
                13.917,
                13.916,
                13.915,
                13.913,
                13.912,
                13.91,
                13.909,
                13.908,
                13.906,
                13.905,
                13.904,
                13.903,
                13.901,
                13.9,
                13.898,
                13.897,
                13.896,
                13.894,
                13.893,
                13.892,
                13.891,
                13.889,
                13.888,
                13.887,
                13.885,
                13.884,
                13.882,
                13.881,
                13.88,
                13.878,
                13.877,
                13.876,
                13.875,
                13.873,
                13.872,
                13.871,
                13.869,
                13.868,
                13.867,
                13.865,
                13.864,
                13.863,
                13.861,
                13.86,
                13.859,
                13.857,
                13.856,
                13.855,
                13.853,
                13.852,
                13.851,
                13.85,
                13.848,
                13.847,
                13.846,
                13.844,
                13.843,
                13.842,
                13.84,
                13.839,
                13.838,
                13.836,
                13.835,
                13.834,
                13.833,
                13.831,
                13.83,
                13.829,
                13.827,
                13.826,
                13.825,
                13.824,
                13.822,
                13.821,
                13.82,
                13.818,
                13.817,
                13.816,
                13.814,
                13.813,
                13.812,
                13.811,
                13.809,
                13.808,
                13.807,
                13.806,
                13.804,
                13.803,
                13.802,
                13.8,
                13.799,
                13.798,
                13.797,
                13.795,
                13.794,
                13.793,
                13.791,
                13.79,
                13.789,
                13.787,
                13.786,
                13.785,
                13.784,
                13.783,
                13.781,
                13.78,
                13.779,
                13.778,
                13.776,
                13.775,
                13.774,
                13.772,
                13.771,
                13.77,
                13.768,
                13.767,
                13.766,
                13.765,
                13.764,
                13.762,
                13.761,
                13.76,
                13.759,
                13.757,
                13.756,
                13.755,
                13.753,
                13.752,
                13.751,
                13.75,
                13.749,
                13.747,
                13.746,
                13.745,
                13.743,
                13.742,
                13.741,
                13.74,
                13.739,
                13.737,
                13.736,
                13.735,
                13.733,
                13.732,
                13.731,
                13.73,
                13.729,
                13.727,
                13.726,
                13.725,
                13.724,
                13.722,
                13.721,
                13.72,
                13.719,
                13.718,
                13.716,
                13.715,
                13.714,
                13.713,
                13.711,
                13.71,
                13.709,
                13.708,
                13.706,
                13.705,
                13.704,
                13.703,
                13.702,
                13.7,
                13.699,
                13.698,
                13.697,
                13.696,
                13.694,
                13.693,
                13.692,
                13.691,
                13.69,
                13.688,
                13.687,
                13.686,
                13.685,
                13.683,
                13.682,
                13.681,
                13.68,
                13.678,
                13.677,
                13.676,
                13.675,
                13.674,
                13.672,
                13.671,
                13.67,
                13.669,
                13.668,
                13.667,
                13.665,
                13.664,
                13.663,
                13.662,
                13.661,
                13.659,
                13.658,
                13.657,
                13.656,
                13.655,
                13.653,
                13.652,
                13.651,
                13.65,
                13.648,
                13.647,
                13.646,
                13.645,
                13.644,
                13.643,
                13.641,
                13.64,
                13.639,
                13.638,
                13.637,
                13.635,
                13.634,
                13.633,
                13.632,
                13.631,
                13.629,
                13.628,
                13.627,
                13.626,
                13.625,
                13.624,
                13.623,
                13.621,
                13.62,
                13.619,
                13.618,
                13.617,
                13.616,
                13.614,
                13.613,
                13.612,
                13.611,
                13.61,
                13.609,
                13.607,
                13.606,
                13.605,
                13.604,
                13.603,
                13.602,
                13.6,
                13.599,
                13.598,
                13.597,
                13.596,
                13.595,
                13.594,
                13.592,
                13.591,
                13.59,
                13.589,
                13.588,
                13.587,
                13.585,
                13.584,
                13.583,
                13.582,
                13.581,
                13.58,
                13.578,
                13.577,
                13.576,
                13.575,
                13.574,
                13.573,
                13.572,
                13.571,
                13.569,
                13.568,
                13.567,
                13.566,
                13.565,
                13.564,
                13.563,
                13.562,
                13.56,
                13.559,
                13.558,
                13.557,
                13.556,
                13.555,
                13.554,
                13.553,
                13.551,
                13.55,
                13.549,
                13.548,
                13.547,
                13.546,
                13.545,
                13.544,
                13.543,
                13.542,
                13.54,
                13.539,
                13.538,
                13.537,
                13.536,
                13.535,
                13.534,
                13.533,
                13.532,
                13.53,
                13.529,
                13.528,
                13.527,
                13.526,
                13.525,
                13.524,
                13.523,
                13.522,
                13.521,
                13.52,
                13.518,
                13.517,
                13.516,
                13.515,
                13.514,
                13.513,
                13.512,
                13.511,
                13.51,
                13.509,
                13.508,
                13.506,
                13.506,
                13.504,
                13.503,
                13.502,
                13.501,
                13.5,
                13.499,
                13.498,
                13.497,
                13.496,
                13.495,
                13.494,
                13.493,
                13.491,
                13.491,
                13.489,
                13.488,
                13.487,
                13.486,
                13.485,
                13.484,
                13.483,
                13.482,
                13.481,
                13.48,
                13.479,
                13.478,
                13.477,
                13.476,
                13.475,
                13.474,
                13.473,
                13.472,
                13.47,
                13.47,
                13.468,
                13.467,
                13.466,
                13.465,
                13.464,
                13.463,
                13.462,
                13.461,
                13.46,
                13.459,
                13.458,
                13.457,
                13.456,
                13.455,
                13.454,
                13.453,
                13.452,
                13.451,
                13.45,
                13.449,
                13.448,
                13.447,
                13.446,
                13.445,
                13.444,
                13.443,
                13.442,
                13.441,
                13.44,
                13.439,
                13.438,
                13.437,
                13.436,
                13.435,
                13.434,
                13.433,
                13.432,
                13.431,
                13.43,
                13.429,
                13.428,
                13.427,
                13.426,
                13.425,
                13.424,
                13.423,
                13.422,
                13.421,
                13.42,
                13.419,
                13.418,
                13.417,
                13.416,
                13.415,
                13.414,
                13.413,
                13.412,
                13.411,
                13.411,
                13.41,
                13.409,
                13.408,
                13.407,
                13.406,
                13.405,
                13.404,
                13.403,
                13.402,
                13.401,
                13.4,
                13.399,
                13.398,
                13.397,
                13.396,
                13.395,
                13.395,
                13.393,
                13.393,
                13.392,
                13.391,
                13.39,
                13.389,
                13.388,
                13.387,
                13.386,
                13.385,
                13.384,
                13.383,
                13.383,
                13.382,
                13.381,
                13.38,
                13.379,
                13.378,
                13.377,
                13.376,
                13.375,
                13.374,
                13.373,
                13.373,
                13.372,
                13.371,
                13.37,
                13.369,
                13.368,
                13.367,
                13.366,
                13.365,
                13.364,
                13.364,
                13.363,
                13.362,
                13.361,
                13.36,
                13.359,
                13.358,
                13.357,
                13.357,
                13.356,
                13.355,
                13.354,
                13.353,
                13.352,
                13.352,
                13.351,
                13.35,
                13.349,
                13.348,
                13.347,
                13.346,
                13.345,
                13.344,
                13.344,
                13.343,
                13.342,
                13.341,
                13.34,
                13.34,
                13.339,
                13.338,
                13.337,
                13.336,
                13.335,
                13.334,
                13.334,
                13.333,
                13.332,
                13.331,
                13.33,
                13.33,
                13.329,
                13.328,
                13.327,
                13.326,
                13.325,
                13.325,
                13.324,
                13.323,
                13.322,
                13.321,
                13.321,
                13.32,
                13.319,
                13.318,
                13.317,
                13.317,
                13.316,
                13.315,
                13.314,
                13.313,
                13.313,
                13.312,
                13.311,
                13.31,
                13.309,
                13.308,
                13.308,
                13.307,
                13.306,
                13.305,
                13.305,
                13.304,
                13.303,
                13.303,
                13.302,
                13.301,
                13.3,
                13.299,
                13.298,
                13.298,
                13.297,
                13.296,
                13.296,
                13.295,
                13.294,
                13.293,
                13.292,
                13.292,
                13.291,
                13.29,
                13.289,
                13.289,
                13.288,
                13.287,
                13.286,
                13.286,
                13.285,
                13.284,
                13.284,
                13.283,
                13.282,
                13.281,
                13.28,
                13.28,
                13.279,
                13.278,
                13.277,
                13.277,
                13.276,
                13.275,
                13.275,
                13.274,
                13.273,
                13.272,
                13.272,
                13.271,
                13.27,
                13.269,
                13.269,
                13.268,
                13.268,
                13.267,
                13.266,
                13.265,
                13.265,
                13.264,
                13.263,
                13.262,
                13.262,
                13.261,
                13.26,
                13.259,
                13.259,
                13.258,
                13.257,
                13.257,
                13.256,
                13.255,
                13.255,
                13.254,
                13.253,
                13.253,
                13.252,
                13.251,
                13.251,
                13.25,
                13.249,
                13.248,
                13.248,
                13.247,
                13.246,
                13.246,
                13.245,
                13.244,
                13.244,
                13.243,
                13.242,
                13.242,
                13.241,
                13.24,
                13.24,
                13.239,
                13.238,
                13.237,
                13.237,
                13.236,
                13.235,
                13.235,
                13.234,
                13.233,
                13.233,
                13.232,
                13.231,
                13.231,
                13.23,
                13.229,
                13.229,
                13.228,
                13.227,
                13.227,
                13.226,
                13.225,
                13.225,
                13.224,
                13.224,
                13.223,
                13.222,
                13.222,
                13.221,
                13.22,
                13.22,
                13.219,
                13.218,
                13.218,
                13.217,
                13.217,
                13.216,
                13.215,
                13.215,
                13.214,
                13.213,
                13.213,
                13.212,
                13.211,
                13.211,
                13.21,
                13.209,
                13.209,
                13.208,
                13.207,
                13.207,
                13.206,
                13.206,
                13.205,
                13.204,
                13.204,
                13.203,
                13.202,
                13.202,
                13.201,
                13.201,
                13.2,
                13.199,
                13.199,
                13.198,
                13.197,
                13.197,
                13.196,
                13.196,
                13.195,
                13.194,
                13.194,
                13.193,
                13.193,
                13.192,
                13.191,
                13.191,
                13.19,
                13.189,
                13.189,
                13.188,
                13.188,
                13.187,
                13.186,
                13.186,
                13.185,
                13.184,
                13.184,
                13.183,
                13.183,
                13.182,
                13.182,
                13.181,
                13.18,
                13.18,
                13.179,
                13.178,
                13.178,
                13.177,
                13.177,
                13.176,
                13.176,
                13.175,
                13.174,
                13.174,
                13.173,
                13.173,
                13.172,
                13.171,
                13.171,
                13.17,
                13.169,
                13.169,
                13.168,
                13.168,
                13.167,
                13.167,
                13.166,
                13.165,
                13.165,
                13.164,
                13.164,
                13.163,
                13.163,
                13.162,
                13.161,
                13.161,
                13.16,
                13.16,
                13.159,
                13.159,
                13.158,
                13.157,
                13.157,
                13.156,
                13.156,
                13.155,
                13.155,
                13.154,
                13.153,
                13.153,
                13.152,
                13.152,
                13.151,
                13.15,
                13.15,
                13.149,
                13.149,
                13.148,
                13.148,
                13.147,
                13.147,
                13.146,
                13.145,
                13.145,
                13.144,
                13.144,
                13.143,
                13.143,
                13.142,
                13.142,
                13.141,
                13.14,
                13.14,
                13.139,
                13.139,
                13.138,
                13.138,
                13.137,
                13.137,
                13.136,
                13.135,
                13.135,
                13.134,
                13.134,
                13.133,
                13.133,
                13.132,
                13.132,
                13.131,
                13.131,
                13.13,
                13.129,
                13.129,
                13.129,
                13.128,
                13.127,
                13.127,
                13.126,
                13.126,
                13.125,
                13.125,
                13.124,
                13.124,
                13.123,
                13.122,
                13.122,
                13.122,
                13.121,
                13.12,
                13.12,
                13.119,
                13.119,
                13.118,
                13.118,
                13.117,
                13.117,
                13.116,
                13.116,
                13.115,
                13.115,
                13.114,
                13.114,
                13.113,
                13.113,
                13.112,
                13.112,
                13.111,
                13.111,
                13.11,
                13.11,
                13.109,
                13.109,
                13.108,
                13.108,
                13.107,
                13.107,
                13.106,
                13.106,
                13.105,
                13.104,
                13.104,
                13.104,
                13.103,
                13.102,
                13.102,
                13.102,
                13.101,
                13.1,
                13.1,
                13.1,
                13.099,
                13.098,
                13.098,
                13.097,
                13.097,
                13.096,
                13.096,
                13.095,
                13.095,
                13.095,
                13.094,
                13.093,
                13.093,
                13.093,
                13.092,
                13.091,
                13.091,
                13.091,
                13.09,
                13.09,
                13.089,
                13.089,
                13.088,
                13.088,
                13.087,
                13.087,
                13.086,
                13.086,
                13.085,
                13.085,
                13.084,
                13.084,
                13.083,
                13.083,
                13.082,
                13.082,
                13.081,
                13.081,
                13.081,
                13.08,
                13.079,
                13.079,
                13.079,
                13.078,
                13.078,
                13.077,
                13.077,
                13.076,
                13.076,
                13.075,
                13.075,
                13.074,
                13.074,
                13.073,
                13.073,
                13.073,
                13.072,
                13.071,
                13.071,
                13.07,
                13.07,
                13.07,
                13.069,
                13.069,
                13.068,
                13.068,
                13.067,
                13.067,
                13.067,
                13.066,
                13.065,
                13.065,
                13.064,
                13.064,
                13.064,
                13.063,
                13.063,
                13.062,
                13.062,
                13.061,
                13.061,
                13.061,
                13.06,
                13.06,
                13.059,
                13.059,
                13.058,
                13.058,
                13.057,
                13.057,
                13.057,
                13.056,
                13.056,
                13.055,
                13.055,
                13.054,
                13.054,
                13.053,
                13.053,
                13.053,
                13.052,
                13.052,
                13.051,
                13.051,
                13.05,
                13.05,
                13.049,
                13.049,
                13.049,
                13.048,
                13.048,
                13.047,
                13.047,
                13.046,
                13.046,
                13.046,
                13.045,
                13.045,
                13.044,
                13.044,
                13.043,
                13.043,
                13.043,
                13.042,
                13.042,
                13.041,
                13.041,
                13.04,
                13.04,
                13.04,
                13.039,
                13.039,
                13.039,
                13.038,
                13.038,
                13.037,
                13.037,
                13.036,
                13.036,
                13.036,
                13.035,
                13.035,
                13.034,
                13.034,
                13.033,
                13.033,
                13.033,
                13.032,
                13.032,
                13.032,
                13.031,
                13.031,
                13.03,
                13.03,
                13.03,
                13.029,
                13.029,
                13.028,
                13.028,
                13.027,
                13.027,
                13.027,
                13.026,
                13.026,
                13.025,
                13.025,
                13.025,
                13.024,
                13.024,
                13.024,
                13.023,
                13.023,
                13.023,
                13.022,
                13.022,
                13.021,
                13.021,
                13.021,
                13.02,
                13.02,
                13.019,
                13.019,
                13.019,
                13.018,
                13.018,
                13.018,
                13.017,
                13.017,
                13.016,
                13.016,
                13.016,
                13.015,
                13.015,
                13.015,
                13.014,
                13.014,
                13.013,
                13.013,
                13.013,
                13.012,
                13.012,
                13.012,
                13.011,
                13.011,
                13.011,
                13.01,
                13.01,
                13.009,
                13.009,
                13.009,
                13.008,
                13.008,
                13.008,
                13.007,
                13.007,
                13.007,
                13.006,
                13.006,
                13.006,
                13.005,
                13.005,
                13.005,
                13.004,
                13.004,
                13.004,
                13.003,
                13.003,
                13.003,
                13.002,
                13.002,
                13.002,
                13.001,
                13.001,
                13.001,
                13,
                13,
                13
            ],
            P5: [
                11.498,
                11.467,
                11.436,
                11.404,
                11.371,
                11.338,
                11.304,
                11.27,
                11.318,
                11.367,
                11.416,
                11.465,
                11.514,
                11.563,
                11.612,
                11.69,
                11.77,
                11.85,
                11.932,
                12.012,
                12.092,
                12.17,
                12.248,
                12.324,
                12.398,
                12.471,
                12.543,
                12.613,
                12.682,
                12.749,
                12.815,
                12.878,
                12.94,
                13,
                13.058,
                13.114,
                13.169,
                13.222,
                13.273,
                13.323,
                13.372,
                13.419,
                13.465,
                13.509,
                13.553,
                13.595,
                13.636,
                13.675,
                13.714,
                13.752,
                13.789,
                13.825,
                13.86,
                13.894,
                13.927,
                13.959,
                13.991,
                14.021,
                14.051,
                14.08,
                14.108,
                14.136,
                14.163,
                14.189,
                14.214,
                14.239,
                14.263,
                14.286,
                14.309,
                14.331,
                14.352,
                14.373,
                14.394,
                14.413,
                14.432,
                14.451,
                14.469,
                14.487,
                14.504,
                14.521,
                14.537,
                14.553,
                14.568,
                14.583,
                14.598,
                14.612,
                14.626,
                14.639,
                14.653,
                14.665,
                14.678,
                14.69,
                14.702,
                14.714,
                14.725,
                14.736,
                14.747,
                14.758,
                14.768,
                14.778,
                14.788,
                14.798,
                14.807,
                14.816,
                14.826,
                14.834,
                14.843,
                14.852,
                14.86,
                14.868,
                14.876,
                14.884,
                14.892,
                14.899,
                14.906,
                14.914,
                14.921,
                14.928,
                14.934,
                14.941,
                14.948,
                14.954,
                14.961,
                14.967,
                14.973,
                14.979,
                14.985,
                14.991,
                14.997,
                15.003,
                15.008,
                15.013,
                15.019,
                15.024,
                15.029,
                15.034,
                15.039,
                15.044,
                15.048,
                15.053,
                15.058,
                15.062,
                15.066,
                15.071,
                15.075,
                15.079,
                15.083,
                15.087,
                15.09,
                15.094,
                15.098,
                15.101,
                15.104,
                15.108,
                15.111,
                15.114,
                15.117,
                15.12,
                15.123,
                15.126,
                15.129,
                15.131,
                15.134,
                15.137,
                15.139,
                15.141,
                15.144,
                15.146,
                15.148,
                15.15,
                15.152,
                15.154,
                15.156,
                15.158,
                15.16,
                15.161,
                15.163,
                15.164,
                15.166,
                15.167,
                15.168,
                15.17,
                15.171,
                15.172,
                15.173,
                15.174,
                15.175,
                15.176,
                15.177,
                15.178,
                15.178,
                15.179,
                15.18,
                15.18,
                15.181,
                15.181,
                15.181,
                15.182,
                15.182,
                15.182,
                15.182,
                15.182,
                15.182,
                15.182,
                15.182,
                15.182,
                15.182,
                15.181,
                15.181,
                15.181,
                15.18,
                15.18,
                15.179,
                15.179,
                15.178,
                15.177,
                15.176,
                15.176,
                15.175,
                15.174,
                15.173,
                15.172,
                15.171,
                15.17,
                15.169,
                15.168,
                15.166,
                15.165,
                15.164,
                15.162,
                15.161,
                15.16,
                15.158,
                15.157,
                15.155,
                15.154,
                15.152,
                15.15,
                15.149,
                15.147,
                15.145,
                15.143,
                15.141,
                15.139,
                15.137,
                15.135,
                15.133,
                15.131,
                15.129,
                15.127,
                15.125,
                15.123,
                15.121,
                15.118,
                15.116,
                15.114,
                15.111,
                15.109,
                15.107,
                15.104,
                15.102,
                15.1,
                15.097,
                15.094,
                15.092,
                15.089,
                15.087,
                15.084,
                15.082,
                15.079,
                15.076,
                15.074,
                15.071,
                15.068,
                15.065,
                15.063,
                15.06,
                15.057,
                15.054,
                15.052,
                15.049,
                15.046,
                15.043,
                15.04,
                15.037,
                15.034,
                15.031,
                15.028,
                15.025,
                15.022,
                15.019,
                15.016,
                15.013,
                15.01,
                15.007,
                15.004,
                15.001,
                14.998,
                14.995,
                14.992,
                14.989,
                14.986,
                14.982,
                14.979,
                14.976,
                14.973,
                14.97,
                14.967,
                14.963,
                14.96,
                14.957,
                14.954,
                14.951,
                14.947,
                14.944,
                14.941,
                14.938,
                14.934,
                14.931,
                14.928,
                14.925,
                14.921,
                14.918,
                14.915,
                14.912,
                14.908,
                14.905,
                14.902,
                14.899,
                14.895,
                14.892,
                14.889,
                14.885,
                14.882,
                14.879,
                14.876,
                14.872,
                14.869,
                14.866,
                14.862,
                14.859,
                14.856,
                14.853,
                14.849,
                14.846,
                14.843,
                14.839,
                14.836,
                14.833,
                14.83,
                14.826,
                14.823,
                14.819,
                14.816,
                14.813,
                14.81,
                14.806,
                14.803,
                14.8,
                14.796,
                14.793,
                14.79,
                14.787,
                14.783,
                14.78,
                14.777,
                14.773,
                14.77,
                14.767,
                14.763,
                14.76,
                14.757,
                14.753,
                14.75,
                14.747,
                14.744,
                14.74,
                14.737,
                14.734,
                14.731,
                14.727,
                14.724,
                14.721,
                14.717,
                14.714,
                14.711,
                14.708,
                14.704,
                14.701,
                14.698,
                14.694,
                14.691,
                14.688,
                14.685,
                14.681,
                14.678,
                14.675,
                14.672,
                14.669,
                14.665,
                14.662,
                14.659,
                14.656,
                14.652,
                14.649,
                14.646,
                14.643,
                14.64,
                14.636,
                14.633,
                14.63,
                14.627,
                14.624,
                14.62,
                14.617,
                14.614,
                14.611,
                14.608,
                14.605,
                14.602,
                14.598,
                14.595,
                14.592,
                14.589,
                14.586,
                14.583,
                14.58,
                14.576,
                14.573,
                14.57,
                14.567,
                14.564,
                14.561,
                14.558,
                14.555,
                14.552,
                14.549,
                14.546,
                14.542,
                14.539,
                14.536,
                14.534,
                14.53,
                14.527,
                14.524,
                14.521,
                14.518,
                14.515,
                14.512,
                14.509,
                14.506,
                14.503,
                14.5,
                14.497,
                14.494,
                14.491,
                14.488,
                14.486,
                14.482,
                14.479,
                14.477,
                14.474,
                14.471,
                14.468,
                14.465,
                14.462,
                14.459,
                14.456,
                14.453,
                14.45,
                14.447,
                14.445,
                14.442,
                14.439,
                14.436,
                14.433,
                14.43,
                14.427,
                14.425,
                14.422,
                14.419,
                14.416,
                14.413,
                14.41,
                14.408,
                14.405,
                14.402,
                14.399,
                14.397,
                14.394,
                14.391,
                14.388,
                14.386,
                14.383,
                14.38,
                14.377,
                14.375,
                14.372,
                14.369,
                14.366,
                14.364,
                14.361,
                14.358,
                14.356,
                14.353,
                14.35,
                14.348,
                14.345,
                14.342,
                14.34,
                14.337,
                14.334,
                14.332,
                14.329,
                14.326,
                14.324,
                14.321,
                14.319,
                14.316,
                14.314,
                14.311,
                14.308,
                14.306,
                14.303,
                14.301,
                14.298,
                14.295,
                14.293,
                14.291,
                14.288,
                14.285,
                14.283,
                14.28,
                14.278,
                14.275,
                14.273,
                14.27,
                14.268,
                14.265,
                14.263,
                14.26,
                14.258,
                14.256,
                14.253,
                14.251,
                14.248,
                14.246,
                14.243,
                14.241,
                14.239,
                14.236,
                14.234,
                14.231,
                14.229,
                14.227,
                14.224,
                14.222,
                14.22,
                14.217,
                14.215,
                14.213,
                14.21,
                14.208,
                14.206,
                14.203,
                14.201,
                14.199,
                14.196,
                14.194,
                14.192,
                14.19,
                14.187,
                14.185,
                14.183,
                14.181,
                14.178,
                14.176,
                14.174,
                14.172,
                14.17,
                14.167,
                14.165,
                14.163,
                14.161,
                14.159,
                14.157,
                14.155,
                14.152,
                14.15,
                14.148,
                14.146,
                14.144,
                14.142,
                14.14,
                14.138,
                14.136,
                14.134,
                14.131,
                14.129,
                14.127,
                14.125,
                14.123,
                14.121,
                14.119,
                14.117,
                14.115,
                14.113,
                14.111,
                14.109,
                14.107,
                14.105,
                14.103,
                14.101,
                14.099,
                14.097,
                14.096,
                14.093,
                14.092,
                14.09,
                14.088,
                14.086,
                14.084,
                14.082,
                14.08,
                14.078,
                14.077,
                14.075,
                14.073,
                14.071,
                14.069,
                14.067,
                14.066,
                14.064,
                14.062,
                14.06,
                14.058,
                14.057,
                14.055,
                14.053,
                14.051,
                14.05,
                14.048,
                14.046,
                14.044,
                14.043,
                14.041,
                14.039,
                14.038,
                14.036,
                14.034,
                14.033,
                14.031,
                14.029,
                14.027,
                14.026,
                14.024,
                14.023,
                14.021,
                14.019,
                14.018,
                14.016,
                14.014,
                14.013,
                14.011,
                14.01,
                14.008,
                14.006,
                14.005,
                14.003,
                14.002,
                14,
                13.999,
                13.997,
                13.996,
                13.994,
                13.993,
                13.991,
                13.99,
                13.988,
                13.987,
                13.985,
                13.984,
                13.982,
                13.981,
                13.979,
                13.978,
                13.976,
                13.975,
                13.973,
                13.972,
                13.971,
                13.969,
                13.968,
                13.966,
                13.965,
                13.963,
                13.962,
                13.961,
                13.959,
                13.958,
                13.956,
                13.955,
                13.954,
                13.952,
                13.951,
                13.95,
                13.948,
                13.947,
                13.946,
                13.944,
                13.943,
                13.942,
                13.94,
                13.939,
                13.938,
                13.936,
                13.935,
                13.934,
                13.932,
                13.931,
                13.93,
                13.929,
                13.927,
                13.926,
                13.925,
                13.923,
                13.922,
                13.921,
                13.92,
                13.918,
                13.917,
                14.162,
                14.16,
                14.159,
                14.158,
                14.156,
                14.155,
                14.154,
                14.152,
                14.151,
                14.15,
                14.148,
                14.147,
                14.146,
                14.144,
                14.143,
                14.142,
                14.141,
                14.139,
                14.138,
                14.137,
                14.135,
                14.134,
                14.133,
                14.131,
                14.13,
                14.129,
                14.127,
                14.126,
                14.125,
                14.123,
                14.122,
                14.121,
                14.119,
                14.118,
                14.117,
                14.116,
                14.114,
                14.113,
                14.112,
                14.11,
                14.109,
                14.108,
                14.106,
                14.105,
                14.104,
                14.103,
                14.101,
                14.1,
                14.099,
                14.097,
                14.096,
                14.095,
                14.094,
                14.092,
                14.091,
                14.09,
                14.088,
                14.087,
                14.086,
                14.085,
                14.083,
                14.082,
                14.081,
                14.079,
                14.078,
                14.077,
                14.075,
                14.074,
                14.073,
                14.072,
                14.07,
                14.069,
                14.068,
                14.067,
                14.065,
                14.064,
                14.063,
                14.061,
                14.06,
                14.059,
                14.058,
                14.056,
                14.055,
                14.054,
                14.053,
                14.051,
                14.05,
                14.049,
                14.047,
                14.046,
                14.045,
                14.044,
                14.042,
                14.041,
                14.04,
                14.039,
                14.037,
                14.036,
                14.035,
                14.034,
                14.032,
                14.031,
                14.03,
                14.029,
                14.027,
                14.026,
                14.025,
                14.024,
                14.022,
                14.021,
                14.02,
                14.019,
                14.017,
                14.016,
                14.015,
                14.014,
                14.012,
                14.011,
                14.01,
                14.009,
                14.007,
                14.006,
                14.005,
                14.004,
                14.002,
                14.001,
                14,
                13.999,
                13.997,
                13.996,
                13.995,
                13.994,
                13.993,
                13.991,
                13.99,
                13.989,
                13.988,
                13.986,
                13.985,
                13.984,
                13.983,
                13.982,
                13.98,
                13.979,
                13.978,
                13.977,
                13.975,
                13.974,
                13.973,
                13.972,
                13.97,
                13.969,
                13.968,
                13.967,
                13.966,
                13.964,
                13.963,
                13.962,
                13.961,
                13.96,
                13.958,
                13.957,
                13.956,
                13.955,
                13.953,
                13.952,
                13.951,
                13.95,
                13.949,
                13.947,
                13.946,
                13.945,
                13.944,
                13.943,
                13.941,
                13.94,
                13.939,
                13.938,
                13.937,
                13.935,
                13.934,
                13.933,
                13.932,
                13.931,
                13.929,
                13.928,
                13.927,
                13.926,
                13.925,
                13.923,
                13.922,
                13.921,
                13.92,
                13.919,
                13.918,
                13.916,
                13.915,
                13.914,
                13.913,
                13.912,
                13.91,
                13.909,
                13.908,
                13.907,
                13.906,
                13.905,
                13.903,
                13.902,
                13.901,
                13.9,
                13.899,
                13.897,
                13.896,
                13.895,
                13.894,
                13.893,
                13.892,
                13.89,
                13.889,
                13.888,
                13.887,
                13.886,
                13.884,
                13.883,
                13.882,
                13.881,
                13.88,
                13.879,
                13.877,
                13.876,
                13.875,
                13.874,
                13.873,
                13.872,
                13.871,
                13.869,
                13.868,
                13.867,
                13.866,
                13.865,
                13.864,
                13.863,
                13.861,
                13.86,
                13.859,
                13.858,
                13.857,
                13.856,
                13.854,
                13.853,
                13.852,
                13.851,
                13.85,
                13.849,
                13.848,
                13.846,
                13.845,
                13.844,
                13.843,
                13.842,
                13.841,
                13.839,
                13.838,
                13.837,
                13.836,
                13.835,
                13.834,
                13.833,
                13.832,
                13.83,
                13.829,
                13.828,
                13.827,
                13.826,
                13.825,
                13.824,
                13.823,
                13.821,
                13.82,
                13.819,
                13.818,
                13.817,
                13.816,
                13.815,
                13.814,
                13.812,
                13.811,
                13.81,
                13.809,
                13.808,
                13.807,
                13.806,
                13.805,
                13.803,
                13.802,
                13.801,
                13.8,
                13.799,
                13.798,
                13.797,
                13.796,
                13.795,
                13.794,
                13.792,
                13.791,
                13.79,
                13.789,
                13.788,
                13.787,
                13.786,
                13.785,
                13.784,
                13.783,
                13.781,
                13.78,
                13.779,
                13.778,
                13.777,
                13.776,
                13.775,
                13.774,
                13.773,
                13.772,
                13.771,
                13.769,
                13.769,
                13.767,
                13.766,
                13.765,
                13.764,
                13.763,
                13.762,
                13.761,
                13.76,
                13.759,
                13.758,
                13.757,
                13.756,
                13.754,
                13.753,
                13.752,
                13.751,
                13.75,
                13.749,
                13.748,
                13.747,
                13.746,
                13.745,
                13.744,
                13.743,
                13.742,
                13.741,
                13.74,
                13.739,
                13.737,
                13.737,
                13.735,
                13.734,
                13.733,
                13.732,
                13.731,
                13.73,
                13.729,
                13.728,
                13.727,
                13.726,
                13.725,
                13.724,
                13.723,
                13.722,
                13.721,
                13.72,
                13.719,
                13.718,
                13.717,
                13.716,
                13.715,
                13.714,
                13.713,
                13.711,
                13.711,
                13.709,
                13.709,
                13.707,
                13.706,
                13.705,
                13.704,
                13.703,
                13.702,
                13.701,
                13.7,
                13.699,
                13.698,
                13.697,
                13.696,
                13.695,
                13.694,
                13.693,
                13.692,
                13.691,
                13.69,
                13.689,
                13.688,
                13.687,
                13.686,
                13.685,
                13.684,
                13.683,
                13.682,
                13.681,
                13.68,
                13.679,
                13.679,
                13.677,
                13.676,
                13.676,
                13.674,
                13.673,
                13.673,
                13.672,
                13.671,
                13.67,
                13.669,
                13.668,
                13.667,
                13.666,
                13.665,
                13.664,
                13.663,
                13.662,
                13.661,
                13.66,
                13.659,
                13.658,
                13.657,
                13.656,
                13.655,
                13.654,
                13.653,
                13.652,
                13.651,
                13.65,
                13.65,
                13.649,
                13.648,
                13.647,
                13.646,
                13.645,
                13.644,
                13.643,
                13.642,
                13.641,
                13.64,
                13.639,
                13.638,
                13.637,
                13.637,
                13.636,
                13.635,
                13.634,
                13.633,
                13.632,
                13.631,
                13.63,
                13.629,
                13.628,
                13.627,
                13.626,
                13.625,
                13.625,
                13.624,
                13.623,
                13.622,
                13.621,
                13.62,
                13.619,
                13.618,
                13.618,
                13.617,
                13.616,
                13.615,
                13.614,
                13.613,
                13.612,
                13.611,
                13.61,
                13.61,
                13.609,
                13.608,
                13.607,
                13.606,
                13.605,
                13.604,
                13.603,
                13.602,
                13.602,
                13.601,
                13.6,
                13.599,
                13.598,
                13.597,
                13.597,
                13.596,
                13.595,
                13.594,
                13.593,
                13.592,
                13.591,
                13.591,
                13.59,
                13.589,
                13.588,
                13.587,
                13.586,
                13.585,
                13.585,
                13.584,
                13.583,
                13.582,
                13.581,
                13.581,
                13.58,
                13.579,
                13.578,
                13.577,
                13.576,
                13.576,
                13.575,
                13.574,
                13.573,
                13.572,
                13.572,
                13.571,
                13.57,
                13.569,
                13.568,
                13.568,
                13.567,
                13.566,
                13.565,
                13.564,
                13.563,
                13.563,
                13.562,
                13.561,
                13.56,
                13.559,
                13.559,
                13.558,
                13.557,
                13.556,
                13.556,
                13.555,
                13.554,
                13.553,
                13.552,
                13.552,
                13.551,
                13.55,
                13.549,
                13.549,
                13.548,
                13.547,
                13.546,
                13.545,
                13.545,
                13.544,
                13.543,
                13.542,
                13.542,
                13.541,
                13.54,
                13.539,
                13.539,
                13.538,
                13.537,
                13.536,
                13.536,
                13.535,
                13.534,
                13.533,
                13.533,
                13.532,
                13.531,
                13.53,
                13.53,
                13.529,
                13.528,
                13.528,
                13.527,
                13.526,
                13.525,
                13.524,
                13.524,
                13.523,
                13.522,
                13.522,
                13.521,
                13.52,
                13.519,
                13.519,
                13.518,
                13.517,
                13.516,
                13.516,
                13.515,
                13.514,
                13.514,
                13.513,
                13.512,
                13.512,
                13.511,
                13.51,
                13.509,
                13.509,
                13.508,
                13.507,
                13.507,
                13.506,
                13.505,
                13.504,
                13.504,
                13.503,
                13.503,
                13.502,
                13.501,
                13.5,
                13.5,
                13.499,
                13.498,
                13.498,
                13.497,
                13.496,
                13.495,
                13.495,
                13.494,
                13.493,
                13.493,
                13.492,
                13.491,
                13.491,
                13.49,
                13.489,
                13.489,
                13.488,
                13.487,
                13.487,
                13.486,
                13.485,
                13.485,
                13.484,
                13.483,
                13.482,
                13.482,
                13.481,
                13.48,
                13.48,
                13.479,
                13.478,
                13.478,
                13.477,
                13.477,
                13.476,
                13.475,
                13.475,
                13.474,
                13.473,
                13.473,
                13.472,
                13.471,
                13.471,
                13.47,
                13.469,
                13.469,
                13.468,
                13.467,
                13.467,
                13.466,
                13.465,
                13.465,
                13.464,
                13.464,
                13.463,
                13.462,
                13.462,
                13.461,
                13.46,
                13.46,
                13.459,
                13.458,
                13.458,
                13.457,
                13.457,
                13.456,
                13.455,
                13.454,
                13.454,
                13.453,
                13.453,
                13.452,
                13.451,
                13.451,
                13.45,
                13.45,
                13.449,
                13.448,
                13.448,
                13.447,
                13.446,
                13.446,
                13.445,
                13.444,
                13.444,
                13.443,
                13.443,
                13.442,
                13.441,
                13.441,
                13.44,
                13.44,
                13.439,
                13.438,
                13.438,
                13.437,
                13.436,
                13.436,
                13.435,
                13.435,
                13.434,
                13.433,
                13.433,
                13.432,
                13.432,
                13.431,
                13.43,
                13.43,
                13.429,
                13.428,
                13.428,
                13.427,
                13.427,
                13.426,
                13.426,
                13.425,
                13.424,
                13.424,
                13.423,
                13.422,
                13.422,
                13.421,
                13.421,
                13.42,
                13.419,
                13.419,
                13.418,
                13.418,
                13.417,
                13.417,
                13.416,
                13.415,
                13.415,
                13.414,
                13.414,
                13.413,
                13.412,
                13.412,
                13.411,
                13.411,
                13.41,
                13.409,
                13.409,
                13.408,
                13.408,
                13.407,
                13.407,
                13.406,
                13.405,
                13.405,
                13.404,
                13.404,
                13.403,
                13.402,
                13.402,
                13.401,
                13.401,
                13.4,
                13.4,
                13.399,
                13.399,
                13.398,
                13.397,
                13.397,
                13.396,
                13.396,
                13.395,
                13.395,
                13.394,
                13.394,
                13.393,
                13.392,
                13.392,
                13.391,
                13.391,
                13.39,
                13.389,
                13.389,
                13.388,
                13.388,
                13.387,
                13.387,
                13.386,
                13.386,
                13.385,
                13.385,
                13.384,
                13.383,
                13.383,
                13.382,
                13.382,
                13.381,
                13.38,
                13.38,
                13.38,
                13.379,
                13.378,
                13.378,
                13.377,
                13.377,
                13.376,
                13.376,
                13.375,
                13.375,
                13.374,
                13.374,
                13.373,
                13.372,
                13.372,
                13.371,
                13.371,
                13.37,
                13.37,
                13.369,
                13.369,
                13.368,
                13.368,
                13.367,
                13.367,
                13.366,
                13.365,
                13.365,
                13.364,
                13.364,
                13.363,
                13.363,
                13.362,
                13.362,
                13.361,
                13.361,
                13.36,
                13.36,
                13.359,
                13.359,
                13.358,
                13.358,
                13.357,
                13.356,
                13.356,
                13.356,
                13.355,
                13.354,
                13.354,
                13.354,
                13.353,
                13.352,
                13.352,
                13.351,
                13.351,
                13.35,
                13.35,
                13.349,
                13.349,
                13.348,
                13.348,
                13.347,
                13.347,
                13.346,
                13.346,
                13.345,
                13.345,
                13.344,
                13.344,
                13.343,
                13.343,
                13.342,
                13.342,
                13.341,
                13.341,
                13.34,
                13.34,
                13.339,
                13.339,
                13.338,
                13.338,
                13.337,
                13.337,
                13.336,
                13.336,
                13.335,
                13.335,
                13.334,
                13.334,
                13.333,
                13.333,
                13.332,
                13.332,
                13.331,
                13.331,
                13.33,
                13.33,
                13.329,
                13.329,
                13.328,
                13.328,
                13.328,
                13.327,
                13.326,
                13.326,
                13.326,
                13.325,
                13.325,
                13.324,
                13.324,
                13.323,
                13.323,
                13.322,
                13.322,
                13.321,
                13.321,
                13.32,
                13.32,
                13.319,
                13.319,
                13.318,
                13.318,
                13.318,
                13.317,
                13.317,
                13.316,
                13.316,
                13.315,
                13.315,
                13.314,
                13.314,
                13.313,
                13.313,
                13.312,
                13.312,
                13.311,
                13.311,
                13.31,
                13.31,
                13.309,
                13.309,
                13.309,
                13.308,
                13.308,
                13.307,
                13.307,
                13.306,
                13.306,
                13.306,
                13.305,
                13.305,
                13.304,
                13.304,
                13.303,
                13.303,
                13.302,
                13.302,
                13.301,
                13.301,
                13.3,
                13.3,
                13.299,
                13.299,
                13.299,
                13.298,
                13.298,
                13.297,
                13.297,
                13.296,
                13.296,
                13.295,
                13.295,
                13.295,
                13.294,
                13.294,
                13.293,
                13.293,
                13.292,
                13.292,
                13.291,
                13.291,
                13.291,
                13.29,
                13.29,
                13.289,
                13.289,
                13.288,
                13.288,
                13.288,
                13.287,
                13.287,
                13.286,
                13.286,
                13.286,
                13.285,
                13.285,
                13.284,
                13.284,
                13.283,
                13.283,
                13.282,
                13.282,
                13.282,
                13.281,
                13.281,
                13.28,
                13.28,
                13.28,
                13.279,
                13.279,
                13.278,
                13.278,
                13.277,
                13.277,
                13.277,
                13.276,
                13.276,
                13.275,
                13.275,
                13.275,
                13.274,
                13.274,
                13.273,
                13.273,
                13.273,
                13.272,
                13.272,
                13.271,
                13.271,
                13.271,
                13.27,
                13.27,
                13.269,
                13.269,
                13.268,
                13.268,
                13.268,
                13.267,
                13.267,
                13.267,
                13.266,
                13.266,
                13.266,
                13.265,
                13.265,
                13.264,
                13.264,
                13.263,
                13.263,
                13.263,
                13.262,
                13.262,
                13.262,
                13.261,
                13.261,
                13.26,
                13.26,
                13.26,
                13.259,
                13.259,
                13.258,
                13.258,
                13.258,
                13.257,
                13.257,
                13.257,
                13.256,
                13.256,
                13.255,
                13.255,
                13.255,
                13.254,
                13.254,
                13.254,
                13.253,
                13.253,
                13.253,
                13.252,
                13.252,
                13.251,
                13.251,
                13.251,
                13.25,
                13.25,
                13.25,
                13.249,
                13.249,
                13.249,
                13.248,
                13.248,
                13.248,
                13.247,
                13.247,
                13.246,
                13.246,
                13.246,
                13.245,
                13.245,
                13.245,
                13.244,
                13.244,
                13.244,
                13.243,
                13.243
            ],
            P10: [
                11.888,
                11.864,
                11.839,
                11.815,
                11.79,
                11.765,
                11.739,
                11.713,
                11.76,
                11.807,
                11.854,
                11.902,
                11.949,
                11.996,
                12.043,
                12.121,
                12.201,
                12.283,
                12.365,
                12.446,
                12.526,
                12.606,
                12.684,
                12.761,
                12.837,
                12.911,
                12.983,
                13.054,
                13.124,
                13.192,
                13.258,
                13.323,
                13.386,
                13.446,
                13.505,
                13.562,
                13.618,
                13.671,
                13.723,
                13.774,
                13.823,
                13.871,
                13.917,
                13.962,
                14.006,
                14.048,
                14.09,
                14.13,
                14.169,
                14.208,
                14.245,
                14.281,
                14.316,
                14.351,
                14.384,
                14.417,
                14.449,
                14.48,
                14.51,
                14.539,
                14.567,
                14.595,
                14.622,
                14.649,
                14.674,
                14.699,
                14.723,
                14.747,
                14.769,
                14.791,
                14.813,
                14.834,
                14.854,
                14.874,
                14.893,
                14.912,
                14.93,
                14.948,
                14.965,
                14.982,
                14.998,
                15.014,
                15.029,
                15.044,
                15.059,
                15.073,
                15.087,
                15.101,
                15.114,
                15.127,
                15.139,
                15.151,
                15.163,
                15.175,
                15.186,
                15.197,
                15.208,
                15.218,
                15.229,
                15.239,
                15.249,
                15.258,
                15.267,
                15.277,
                15.286,
                15.294,
                15.303,
                15.311,
                15.319,
                15.328,
                15.336,
                15.343,
                15.351,
                15.358,
                15.365,
                15.373,
                15.38,
                15.386,
                15.393,
                15.4,
                15.406,
                15.413,
                15.419,
                15.425,
                15.431,
                15.437,
                15.443,
                15.449,
                15.454,
                15.46,
                15.465,
                15.471,
                15.476,
                15.481,
                15.486,
                15.491,
                15.496,
                15.5,
                15.505,
                15.509,
                15.514,
                15.518,
                15.522,
                15.526,
                15.53,
                15.534,
                15.538,
                15.542,
                15.546,
                15.549,
                15.552,
                15.556,
                15.559,
                15.562,
                15.565,
                15.568,
                15.571,
                15.574,
                15.577,
                15.58,
                15.582,
                15.585,
                15.587,
                15.59,
                15.592,
                15.594,
                15.596,
                15.598,
                15.6,
                15.602,
                15.604,
                15.606,
                15.608,
                15.609,
                15.611,
                15.613,
                15.614,
                15.615,
                15.617,
                15.618,
                15.619,
                15.62,
                15.621,
                15.622,
                15.623,
                15.624,
                15.625,
                15.626,
                15.626,
                15.627,
                15.627,
                15.628,
                15.628,
                15.629,
                15.629,
                15.629,
                15.629,
                15.63,
                15.63,
                15.63,
                15.63,
                15.629,
                15.629,
                15.629,
                15.629,
                15.628,
                15.628,
                15.627,
                15.627,
                15.627,
                15.626,
                15.625,
                15.624,
                15.624,
                15.623,
                15.622,
                15.621,
                15.62,
                15.619,
                15.618,
                15.617,
                15.616,
                15.615,
                15.613,
                15.612,
                15.611,
                15.609,
                15.608,
                15.606,
                15.605,
                15.603,
                15.601,
                15.6,
                15.598,
                15.596,
                15.595,
                15.593,
                15.591,
                15.589,
                15.587,
                15.585,
                15.583,
                15.581,
                15.579,
                15.577,
                15.574,
                15.572,
                15.57,
                15.568,
                15.565,
                15.563,
                15.561,
                15.558,
                15.556,
                15.554,
                15.551,
                15.548,
                15.546,
                15.543,
                15.541,
                15.538,
                15.536,
                15.533,
                15.53,
                15.527,
                15.525,
                15.522,
                15.519,
                15.516,
                15.513,
                15.511,
                15.508,
                15.505,
                15.502,
                15.499,
                15.496,
                15.493,
                15.49,
                15.487,
                15.484,
                15.481,
                15.478,
                15.475,
                15.472,
                15.469,
                15.465,
                15.462,
                15.459,
                15.456,
                15.453,
                15.45,
                15.446,
                15.443,
                15.44,
                15.437,
                15.433,
                15.43,
                15.427,
                15.424,
                15.42,
                15.417,
                15.414,
                15.41,
                15.407,
                15.404,
                15.4,
                15.397,
                15.394,
                15.39,
                15.387,
                15.383,
                15.38,
                15.376,
                15.373,
                15.37,
                15.366,
                15.363,
                15.359,
                15.356,
                15.353,
                15.349,
                15.346,
                15.342,
                15.339,
                15.335,
                15.332,
                15.328,
                15.325,
                15.321,
                15.318,
                15.314,
                15.311,
                15.307,
                15.304,
                15.301,
                15.297,
                15.294,
                15.29,
                15.287,
                15.283,
                15.28,
                15.276,
                15.273,
                15.269,
                15.266,
                15.262,
                15.259,
                15.255,
                15.252,
                15.248,
                15.245,
                15.241,
                15.238,
                15.234,
                15.231,
                15.227,
                15.224,
                15.22,
                15.217,
                15.213,
                15.21,
                15.206,
                15.203,
                15.199,
                15.196,
                15.192,
                15.189,
                15.185,
                15.182,
                15.179,
                15.175,
                15.172,
                15.168,
                15.165,
                15.161,
                15.158,
                15.154,
                15.151,
                15.147,
                15.144,
                15.14,
                15.137,
                15.133,
                15.13,
                15.127,
                15.123,
                15.12,
                15.116,
                15.113,
                15.109,
                15.106,
                15.103,
                15.099,
                15.096,
                15.092,
                15.089,
                15.085,
                15.082,
                15.079,
                15.075,
                15.072,
                15.068,
                15.065,
                15.062,
                15.058,
                15.055,
                15.051,
                15.048,
                15.045,
                15.042,
                15.038,
                15.035,
                15.031,
                15.028,
                15.025,
                15.022,
                15.018,
                15.015,
                15.011,
                15.008,
                15.005,
                15.001,
                14.998,
                14.995,
                14.992,
                14.988,
                14.985,
                14.982,
                14.979,
                14.975,
                14.972,
                14.969,
                14.966,
                14.962,
                14.959,
                14.956,
                14.953,
                14.949,
                14.946,
                14.943,
                14.94,
                14.937,
                14.933,
                14.93,
                14.927,
                14.924,
                14.921,
                14.917,
                14.914,
                14.911,
                14.908,
                14.905,
                14.902,
                14.899,
                14.895,
                14.892,
                14.889,
                14.886,
                14.883,
                14.88,
                14.877,
                14.874,
                14.871,
                14.868,
                14.865,
                14.861,
                14.858,
                14.855,
                14.852,
                14.849,
                14.846,
                14.843,
                14.84,
                14.837,
                14.834,
                14.831,
                14.828,
                14.825,
                14.822,
                14.819,
                14.816,
                14.813,
                14.81,
                14.807,
                14.805,
                14.802,
                14.799,
                14.796,
                14.793,
                14.79,
                14.787,
                14.784,
                14.781,
                14.778,
                14.775,
                14.773,
                14.77,
                14.767,
                14.764,
                14.761,
                14.758,
                14.756,
                14.753,
                14.75,
                14.747,
                14.744,
                14.741,
                14.739,
                14.736,
                14.733,
                14.73,
                14.727,
                14.725,
                14.722,
                14.719,
                14.716,
                14.714,
                14.711,
                14.708,
                14.705,
                14.703,
                14.7,
                14.697,
                14.695,
                14.692,
                14.689,
                14.687,
                14.684,
                14.681,
                14.678,
                14.676,
                14.673,
                14.671,
                14.668,
                14.665,
                14.663,
                14.66,
                14.657,
                14.655,
                14.652,
                14.65,
                14.647,
                14.645,
                14.642,
                14.64,
                14.637,
                14.634,
                14.632,
                14.629,
                14.627,
                14.624,
                14.622,
                14.619,
                14.617,
                14.614,
                14.612,
                14.609,
                14.607,
                14.604,
                14.602,
                14.6,
                14.597,
                14.595,
                14.592,
                14.59,
                14.587,
                14.585,
                14.583,
                14.58,
                14.578,
                14.575,
                14.573,
                14.571,
                14.568,
                14.566,
                14.564,
                14.561,
                14.559,
                14.557,
                14.554,
                14.552,
                14.55,
                14.547,
                14.545,
                14.543,
                14.541,
                14.538,
                14.536,
                14.534,
                14.532,
                14.529,
                14.527,
                14.525,
                14.523,
                14.521,
                14.518,
                14.516,
                14.514,
                14.512,
                14.51,
                14.508,
                14.506,
                14.503,
                14.501,
                14.499,
                14.497,
                14.495,
                14.493,
                14.491,
                14.489,
                14.487,
                14.485,
                14.482,
                14.48,
                14.478,
                14.476,
                14.474,
                14.472,
                14.47,
                14.468,
                14.466,
                14.464,
                14.462,
                14.46,
                14.458,
                14.456,
                14.454,
                14.453,
                14.45,
                14.449,
                14.447,
                14.445,
                14.443,
                14.441,
                14.439,
                14.437,
                14.435,
                14.433,
                14.432,
                14.43,
                14.428,
                14.426,
                14.424,
                14.422,
                14.421,
                14.419,
                14.417,
                14.415,
                14.414,
                14.412,
                14.41,
                14.408,
                14.406,
                14.405,
                14.403,
                14.401,
                14.399,
                14.398,
                14.396,
                14.394,
                14.393,
                14.391,
                14.389,
                14.388,
                14.386,
                14.384,
                14.382,
                14.381,
                14.379,
                14.378,
                14.376,
                14.374,
                14.373,
                14.371,
                14.37,
                14.368,
                14.366,
                14.365,
                14.363,
                14.361,
                14.36,
                14.358,
                14.357,
                14.355,
                14.354,
                14.352,
                14.351,
                14.349,
                14.348,
                14.346,
                14.344,
                14.343,
                14.341,
                14.34,
                14.338,
                14.337,
                14.335,
                14.334,
                14.332,
                14.331,
                14.33,
                14.328,
                14.327,
                14.325,
                14.324,
                14.322,
                14.321,
                14.32,
                14.318,
                14.317,
                14.315,
                14.314,
                14.312,
                14.311,
                14.31,
                14.308,
                14.307,
                14.306,
                14.304,
                14.303,
                14.301,
                14.3,
                14.299,
                14.297,
                14.296,
                14.295,
                14.293,
                14.292,
                14.291,
                14.289,
                14.288,
                14.541,
                14.54,
                14.538,
                14.537,
                14.536,
                14.534,
                14.533,
                14.532,
                14.53,
                14.529,
                14.528,
                14.527,
                14.525,
                14.524,
                14.523,
                14.521,
                14.52,
                14.519,
                14.517,
                14.516,
                14.515,
                14.514,
                14.512,
                14.511,
                14.51,
                14.508,
                14.507,
                14.506,
                14.505,
                14.503,
                14.502,
                14.501,
                14.499,
                14.498,
                14.497,
                14.495,
                14.494,
                14.493,
                14.492,
                14.49,
                14.489,
                14.488,
                14.487,
                14.485,
                14.484,
                14.483,
                14.481,
                14.48,
                14.479,
                14.478,
                14.476,
                14.475,
                14.474,
                14.472,
                14.471,
                14.47,
                14.469,
                14.467,
                14.466,
                14.465,
                14.464,
                14.462,
                14.461,
                14.46,
                14.459,
                14.457,
                14.456,
                14.455,
                14.454,
                14.452,
                14.451,
                14.45,
                14.449,
                14.447,
                14.446,
                14.445,
                14.444,
                14.442,
                14.441,
                14.44,
                14.439,
                14.437,
                14.436,
                14.435,
                14.434,
                14.432,
                14.431,
                14.43,
                14.429,
                14.427,
                14.426,
                14.425,
                14.424,
                14.423,
                14.421,
                14.42,
                14.419,
                14.418,
                14.416,
                14.415,
                14.414,
                14.413,
                14.411,
                14.41,
                14.409,
                14.408,
                14.407,
                14.405,
                14.404,
                14.403,
                14.402,
                14.4,
                14.399,
                14.398,
                14.397,
                14.396,
                14.394,
                14.393,
                14.392,
                14.391,
                14.389,
                14.388,
                14.387,
                14.386,
                14.385,
                14.383,
                14.382,
                14.381,
                14.38,
                14.379,
                14.377,
                14.376,
                14.375,
                14.374,
                14.372,
                14.371,
                14.37,
                14.369,
                14.368,
                14.366,
                14.365,
                14.364,
                14.363,
                14.362,
                14.361,
                14.359,
                14.358,
                14.357,
                14.356,
                14.354,
                14.353,
                14.352,
                14.351,
                14.35,
                14.348,
                14.347,
                14.346,
                14.345,
                14.344,
                14.343,
                14.341,
                14.34,
                14.339,
                14.338,
                14.337,
                14.335,
                14.334,
                14.333,
                14.332,
                14.331,
                14.33,
                14.328,
                14.327,
                14.326,
                14.325,
                14.324,
                14.322,
                14.321,
                14.32,
                14.319,
                14.318,
                14.316,
                14.315,
                14.314,
                14.313,
                14.312,
                14.311,
                14.31,
                14.308,
                14.307,
                14.306,
                14.305,
                14.304,
                14.303,
                14.301,
                14.3,
                14.299,
                14.298,
                14.297,
                14.296,
                14.294,
                14.293,
                14.292,
                14.291,
                14.29,
                14.289,
                14.287,
                14.286,
                14.285,
                14.284,
                14.283,
                14.282,
                14.28,
                14.279,
                14.278,
                14.277,
                14.276,
                14.275,
                14.274,
                14.272,
                14.271,
                14.27,
                14.269,
                14.268,
                14.267,
                14.265,
                14.264,
                14.263,
                14.262,
                14.261,
                14.26,
                14.259,
                14.258,
                14.256,
                14.255,
                14.254,
                14.253,
                14.252,
                14.251,
                14.25,
                14.248,
                14.247,
                14.246,
                14.245,
                14.244,
                14.243,
                14.242,
                14.24,
                14.239,
                14.238,
                14.237,
                14.236,
                14.235,
                14.234,
                14.233,
                14.232,
                14.23,
                14.229,
                14.228,
                14.227,
                14.226,
                14.225,
                14.224,
                14.222,
                14.222,
                14.22,
                14.219,
                14.218,
                14.217,
                14.216,
                14.215,
                14.214,
                14.213,
                14.211,
                14.21,
                14.209,
                14.208,
                14.207,
                14.206,
                14.205,
                14.204,
                14.203,
                14.201,
                14.2,
                14.199,
                14.198,
                14.197,
                14.196,
                14.195,
                14.194,
                14.193,
                14.192,
                14.19,
                14.189,
                14.188,
                14.187,
                14.186,
                14.185,
                14.184,
                14.183,
                14.182,
                14.181,
                14.18,
                14.178,
                14.178,
                14.176,
                14.175,
                14.174,
                14.173,
                14.172,
                14.171,
                14.17,
                14.169,
                14.168,
                14.167,
                14.166,
                14.164,
                14.164,
                14.162,
                14.161,
                14.16,
                14.159,
                14.158,
                14.157,
                14.156,
                14.155,
                14.154,
                14.153,
                14.152,
                14.151,
                14.15,
                14.148,
                14.148,
                14.146,
                14.145,
                14.144,
                14.143,
                14.142,
                14.141,
                14.14,
                14.139,
                14.138,
                14.137,
                14.136,
                14.135,
                14.134,
                14.133,
                14.132,
                14.131,
                14.13,
                14.129,
                14.128,
                14.126,
                14.126,
                14.124,
                14.123,
                14.122,
                14.121,
                14.12,
                14.119,
                14.118,
                14.117,
                14.116,
                14.115,
                14.114,
                14.113,
                14.112,
                14.111,
                14.11,
                14.109,
                14.108,
                14.107,
                14.106,
                14.105,
                14.104,
                14.103,
                14.102,
                14.101,
                14.1,
                14.099,
                14.098,
                14.097,
                14.096,
                14.095,
                14.094,
                14.093,
                14.092,
                14.091,
                14.09,
                14.089,
                14.088,
                14.087,
                14.086,
                14.085,
                14.084,
                14.083,
                14.082,
                14.081,
                14.08,
                14.079,
                14.078,
                14.077,
                14.076,
                14.075,
                14.074,
                14.073,
                14.072,
                14.071,
                14.07,
                14.069,
                14.068,
                14.067,
                14.066,
                14.065,
                14.064,
                14.063,
                14.062,
                14.061,
                14.06,
                14.059,
                14.058,
                14.058,
                14.057,
                14.056,
                14.055,
                14.054,
                14.053,
                14.052,
                14.051,
                14.05,
                14.049,
                14.048,
                14.047,
                14.046,
                14.045,
                14.044,
                14.043,
                14.042,
                14.041,
                14.041,
                14.04,
                14.039,
                14.038,
                14.037,
                14.036,
                14.035,
                14.034,
                14.033,
                14.032,
                14.031,
                14.03,
                14.029,
                14.029,
                14.028,
                14.027,
                14.026,
                14.025,
                14.024,
                14.023,
                14.022,
                14.021,
                14.02,
                14.019,
                14.019,
                14.018,
                14.017,
                14.016,
                14.015,
                14.014,
                14.013,
                14.012,
                14.011,
                14.01,
                14.01,
                14.009,
                14.008,
                14.007,
                14.006,
                14.005,
                14.004,
                14.003,
                14.003,
                14.002,
                14.001,
                14,
                13.999,
                13.998,
                13.997,
                13.996,
                13.996,
                13.995,
                13.994,
                13.993,
                13.992,
                13.991,
                13.99,
                13.989,
                13.989,
                13.988,
                13.987,
                13.986,
                13.985,
                13.985,
                13.984,
                13.983,
                13.982,
                13.981,
                13.98,
                13.979,
                13.979,
                13.978,
                13.977,
                13.976,
                13.975,
                13.974,
                13.974,
                13.973,
                13.972,
                13.971,
                13.97,
                13.97,
                13.969,
                13.968,
                13.967,
                13.966,
                13.966,
                13.965,
                13.964,
                13.963,
                13.962,
                13.962,
                13.961,
                13.96,
                13.959,
                13.958,
                13.957,
                13.957,
                13.956,
                13.955,
                13.954,
                13.953,
                13.953,
                13.952,
                13.951,
                13.95,
                13.95,
                13.949,
                13.948,
                13.947,
                13.947,
                13.946,
                13.945,
                13.944,
                13.943,
                13.943,
                13.942,
                13.941,
                13.94,
                13.939,
                13.939,
                13.938,
                13.937,
                13.937,
                13.936,
                13.935,
                13.934,
                13.933,
                13.933,
                13.932,
                13.931,
                13.931,
                13.93,
                13.929,
                13.928,
                13.927,
                13.927,
                13.926,
                13.925,
                13.925,
                13.924,
                13.923,
                13.922,
                13.921,
                13.921,
                13.92,
                13.919,
                13.919,
                13.918,
                13.917,
                13.916,
                13.916,
                13.915,
                13.914,
                13.913,
                13.913,
                13.912,
                13.911,
                13.911,
                13.91,
                13.909,
                13.909,
                13.908,
                13.907,
                13.906,
                13.906,
                13.905,
                13.904,
                13.904,
                13.903,
                13.902,
                13.902,
                13.901,
                13.9,
                13.899,
                13.899,
                13.898,
                13.897,
                13.896,
                13.896,
                13.895,
                13.894,
                13.894,
                13.893,
                13.893,
                13.892,
                13.891,
                13.89,
                13.89,
                13.889,
                13.888,
                13.888,
                13.887,
                13.886,
                13.886,
                13.885,
                13.884,
                13.884,
                13.883,
                13.882,
                13.881,
                13.881,
                13.88,
                13.879,
                13.879,
                13.878,
                13.877,
                13.877,
                13.876,
                13.875,
                13.875,
                13.874,
                13.873,
                13.873,
                13.872,
                13.871,
                13.871,
                13.87,
                13.869,
                13.869,
                13.868,
                13.867,
                13.867,
                13.866,
                13.865,
                13.865,
                13.864,
                13.864,
                13.863,
                13.862,
                13.862,
                13.861,
                13.86,
                13.86,
                13.859,
                13.858,
                13.858,
                13.857,
                13.857,
                13.856,
                13.855,
                13.855,
                13.854,
                13.853,
                13.853,
                13.852,
                13.851,
                13.851,
                13.85,
                13.85,
                13.849,
                13.848,
                13.848,
                13.847,
                13.846,
                13.846,
                13.845,
                13.844,
                13.844,
                13.843,
                13.843,
                13.842,
                13.841,
                13.841,
                13.84,
                13.839,
                13.839,
                13.838,
                13.838,
                13.837,
                13.836,
                13.836,
                13.835,
                13.834,
                13.834,
                13.833,
                13.833,
                13.832,
                13.831,
                13.831,
                13.83,
                13.83,
                13.829,
                13.828,
                13.828,
                13.827,
                13.827,
                13.826,
                13.825,
                13.825,
                13.824,
                13.824,
                13.823,
                13.822,
                13.822,
                13.821,
                13.821,
                13.82,
                13.819,
                13.819,
                13.818,
                13.818,
                13.817,
                13.816,
                13.816,
                13.815,
                13.814,
                13.814,
                13.813,
                13.813,
                13.812,
                13.812,
                13.811,
                13.81,
                13.81,
                13.809,
                13.809,
                13.808,
                13.808,
                13.807,
                13.806,
                13.806,
                13.805,
                13.804,
                13.804,
                13.803,
                13.803,
                13.802,
                13.802,
                13.801,
                13.8,
                13.8,
                13.799,
                13.799,
                13.798,
                13.798,
                13.797,
                13.796,
                13.796,
                13.795,
                13.795,
                13.794,
                13.793,
                13.793,
                13.792,
                13.792,
                13.791,
                13.791,
                13.79,
                13.79,
                13.789,
                13.788,
                13.788,
                13.787,
                13.787,
                13.786,
                13.786,
                13.785,
                13.785,
                13.784,
                13.783,
                13.783,
                13.782,
                13.782,
                13.781,
                13.781,
                13.78,
                13.779,
                13.779,
                13.778,
                13.778,
                13.777,
                13.777,
                13.776,
                13.776,
                13.775,
                13.774,
                13.774,
                13.773,
                13.773,
                13.772,
                13.772,
                13.771,
                13.771,
                13.77,
                13.77,
                13.769,
                13.769,
                13.768,
                13.767,
                13.767,
                13.766,
                13.766,
                13.765,
                13.765,
                13.764,
                13.764,
                13.763,
                13.763,
                13.762,
                13.761,
                13.761,
                13.761,
                13.76,
                13.759,
                13.759,
                13.758,
                13.758,
                13.757,
                13.757,
                13.756,
                13.756,
                13.755,
                13.755,
                13.754,
                13.754,
                13.753,
                13.752,
                13.752,
                13.751,
                13.751,
                13.75,
                13.75,
                13.749,
                13.749,
                13.748,
                13.748,
                13.747,
                13.747,
                13.746,
                13.746,
                13.745,
                13.745,
                13.744,
                13.744,
                13.743,
                13.743,
                13.742,
                13.742,
                13.741,
                13.741,
                13.74,
                13.74,
                13.739,
                13.739,
                13.738,
                13.738,
                13.737,
                13.737,
                13.736,
                13.736,
                13.735,
                13.735,
                13.734,
                13.734,
                13.733,
                13.733,
                13.732,
                13.732,
                13.731,
                13.731,
                13.73,
                13.73,
                13.729,
                13.729,
                13.728,
                13.728,
                13.727,
                13.727,
                13.726,
                13.726,
                13.725,
                13.725,
                13.724,
                13.724,
                13.723,
                13.723,
                13.722,
                13.722,
                13.721,
                13.721,
                13.72,
                13.72,
                13.719,
                13.719,
                13.718,
                13.718,
                13.717,
                13.717,
                13.716,
                13.716,
                13.715,
                13.715,
                13.714,
                13.714,
                13.714,
                13.713,
                13.713,
                13.712,
                13.712,
                13.711,
                13.711,
                13.71,
                13.71,
                13.709,
                13.709,
                13.708,
                13.708,
                13.707,
                13.707,
                13.706,
                13.706,
                13.706,
                13.705,
                13.705,
                13.704,
                13.704,
                13.703,
                13.703,
                13.702,
                13.702,
                13.701,
                13.701,
                13.7,
                13.7,
                13.699,
                13.699,
                13.699,
                13.698,
                13.698,
                13.697,
                13.697,
                13.696,
                13.696,
                13.695,
                13.695,
                13.694,
                13.694,
                13.693,
                13.693,
                13.693,
                13.692,
                13.692,
                13.691,
                13.691,
                13.69,
                13.69,
                13.689,
                13.689,
                13.689,
                13.688,
                13.688,
                13.687,
                13.687,
                13.686,
                13.686,
                13.685,
                13.685,
                13.685,
                13.684,
                13.684,
                13.683,
                13.683,
                13.682,
                13.682,
                13.681,
                13.681,
                13.681,
                13.68,
                13.68,
                13.679,
                13.679,
                13.678,
                13.678,
                13.677,
                13.677,
                13.677,
                13.676,
                13.676,
                13.675,
                13.675,
                13.674,
                13.674,
                13.674,
                13.673,
                13.673,
                13.672,
                13.672,
                13.671,
                13.671,
                13.671,
                13.67,
                13.67,
                13.669,
                13.669,
                13.669,
                13.668,
                13.668,
                13.667,
                13.667,
                13.666,
                13.666,
                13.666,
                13.665,
                13.665,
                13.664,
                13.664,
                13.664,
                13.663,
                13.663,
                13.662,
                13.662,
                13.662,
                13.661,
                13.661,
                13.66,
                13.66,
                13.66,
                13.659,
                13.659,
                13.658,
                13.658,
                13.658,
                13.657,
                13.657,
                13.656,
                13.656,
                13.656,
                13.655,
                13.655,
                13.654,
                13.654,
                13.654,
                13.653,
                13.653,
                13.652,
                13.652,
                13.652,
                13.651,
                13.651,
                13.65,
                13.65,
                13.65,
                13.649,
                13.649,
                13.649,
                13.648,
                13.648,
                13.647,
                13.647,
                13.647,
                13.646,
                13.646,
                13.646,
                13.645,
                13.645,
                13.645,
                13.644,
                13.644,
                13.643,
                13.643,
                13.643,
                13.642,
                13.642,
                13.642,
                13.641,
                13.641,
                13.64,
                13.64,
                13.64,
                13.639,
                13.639,
                13.639,
                13.638,
                13.638,
                13.638,
                13.637,
                13.637,
                13.637,
                13.636,
                13.636,
                13.636,
                13.635,
                13.635,
                13.634,
                13.634,
                13.634
            ],
            P15: [
                12.16,
                12.14,
                12.12,
                12.1,
                12.079,
                12.059,
                12.038,
                12.017,
                12.063,
                12.109,
                12.155,
                12.201,
                12.247,
                12.293,
                12.339,
                12.417,
                12.498,
                12.58,
                12.662,
                12.744,
                12.825,
                12.906,
                12.985,
                13.062,
                13.138,
                13.213,
                13.286,
                13.358,
                13.428,
                13.497,
                13.564,
                13.629,
                13.693,
                13.754,
                13.813,
                13.871,
                13.927,
                13.981,
                14.034,
                14.085,
                14.134,
                14.182,
                14.229,
                14.274,
                14.319,
                14.362,
                14.403,
                14.444,
                14.484,
                14.522,
                14.56,
                14.596,
                14.632,
                14.666,
                14.7,
                14.733,
                14.765,
                14.796,
                14.826,
                14.856,
                14.885,
                14.913,
                14.94,
                14.966,
                14.992,
                15.017,
                15.041,
                15.065,
                15.088,
                15.11,
                15.132,
                15.153,
                15.173,
                15.193,
                15.212,
                15.231,
                15.249,
                15.267,
                15.284,
                15.301,
                15.317,
                15.333,
                15.349,
                15.363,
                15.378,
                15.392,
                15.406,
                15.42,
                15.433,
                15.446,
                15.458,
                15.47,
                15.482,
                15.494,
                15.505,
                15.516,
                15.527,
                15.537,
                15.548,
                15.558,
                15.567,
                15.577,
                15.586,
                15.595,
                15.604,
                15.613,
                15.622,
                15.63,
                15.638,
                15.646,
                15.654,
                15.661,
                15.669,
                15.676,
                15.684,
                15.691,
                15.698,
                15.704,
                15.711,
                15.718,
                15.724,
                15.73,
                15.737,
                15.743,
                15.749,
                15.755,
                15.76,
                15.766,
                15.772,
                15.777,
                15.782,
                15.788,
                15.793,
                15.798,
                15.803,
                15.807,
                15.812,
                15.817,
                15.821,
                15.826,
                15.83,
                15.834,
                15.838,
                15.843,
                15.847,
                15.85,
                15.854,
                15.858,
                15.861,
                15.865,
                15.868,
                15.871,
                15.875,
                15.878,
                15.881,
                15.884,
                15.886,
                15.889,
                15.892,
                15.895,
                15.897,
                15.899,
                15.902,
                15.904,
                15.906,
                15.909,
                15.911,
                15.913,
                15.914,
                15.916,
                15.918,
                15.92,
                15.921,
                15.923,
                15.925,
                15.926,
                15.927,
                15.929,
                15.93,
                15.931,
                15.932,
                15.933,
                15.934,
                15.935,
                15.936,
                15.936,
                15.937,
                15.938,
                15.938,
                15.939,
                15.939,
                15.94,
                15.94,
                15.94,
                15.941,
                15.941,
                15.941,
                15.941,
                15.941,
                15.941,
                15.94,
                15.94,
                15.94,
                15.94,
                15.939,
                15.939,
                15.938,
                15.938,
                15.937,
                15.936,
                15.936,
                15.935,
                15.934,
                15.933,
                15.932,
                15.931,
                15.93,
                15.929,
                15.928,
                15.927,
                15.926,
                15.924,
                15.923,
                15.922,
                15.92,
                15.919,
                15.917,
                15.915,
                15.914,
                15.912,
                15.911,
                15.909,
                15.907,
                15.905,
                15.903,
                15.901,
                15.899,
                15.897,
                15.895,
                15.893,
                15.891,
                15.889,
                15.887,
                15.885,
                15.882,
                15.88,
                15.878,
                15.875,
                15.873,
                15.871,
                15.868,
                15.866,
                15.863,
                15.86,
                15.858,
                15.855,
                15.853,
                15.85,
                15.847,
                15.845,
                15.842,
                15.839,
                15.836,
                15.833,
                15.831,
                15.828,
                15.825,
                15.822,
                15.819,
                15.816,
                15.813,
                15.81,
                15.807,
                15.804,
                15.801,
                15.798,
                15.795,
                15.791,
                15.788,
                15.785,
                15.782,
                15.779,
                15.775,
                15.772,
                15.769,
                15.766,
                15.763,
                15.759,
                15.756,
                15.753,
                15.749,
                15.746,
                15.743,
                15.739,
                15.736,
                15.732,
                15.729,
                15.726,
                15.722,
                15.719,
                15.715,
                15.712,
                15.708,
                15.705,
                15.701,
                15.698,
                15.694,
                15.691,
                15.687,
                15.684,
                15.68,
                15.677,
                15.673,
                15.67,
                15.666,
                15.663,
                15.659,
                15.655,
                15.652,
                15.648,
                15.645,
                15.641,
                15.638,
                15.634,
                15.63,
                15.627,
                15.623,
                15.62,
                15.616,
                15.612,
                15.609,
                15.605,
                15.602,
                15.598,
                15.594,
                15.591,
                15.587,
                15.584,
                15.58,
                15.576,
                15.573,
                15.569,
                15.566,
                15.562,
                15.558,
                15.555,
                15.551,
                15.548,
                15.544,
                15.54,
                15.537,
                15.533,
                15.529,
                15.526,
                15.522,
                15.519,
                15.515,
                15.511,
                15.508,
                15.504,
                15.501,
                15.497,
                15.493,
                15.49,
                15.486,
                15.482,
                15.479,
                15.475,
                15.472,
                15.468,
                15.464,
                15.461,
                15.457,
                15.454,
                15.45,
                15.447,
                15.443,
                15.439,
                15.436,
                15.432,
                15.428,
                15.425,
                15.421,
                15.418,
                15.414,
                15.411,
                15.407,
                15.404,
                15.4,
                15.396,
                15.393,
                15.389,
                15.386,
                15.382,
                15.379,
                15.375,
                15.372,
                15.368,
                15.365,
                15.361,
                15.358,
                15.354,
                15.351,
                15.347,
                15.344,
                15.34,
                15.337,
                15.333,
                15.33,
                15.326,
                15.323,
                15.319,
                15.316,
                15.312,
                15.309,
                15.306,
                15.302,
                15.299,
                15.295,
                15.292,
                15.288,
                15.285,
                15.282,
                15.278,
                15.275,
                15.271,
                15.268,
                15.265,
                15.261,
                15.258,
                15.254,
                15.251,
                15.248,
                15.245,
                15.241,
                15.238,
                15.235,
                15.231,
                15.228,
                15.225,
                15.221,
                15.218,
                15.215,
                15.211,
                15.208,
                15.205,
                15.201,
                15.198,
                15.195,
                15.192,
                15.188,
                15.185,
                15.182,
                15.179,
                15.175,
                15.172,
                15.169,
                15.166,
                15.163,
                15.159,
                15.156,
                15.153,
                15.15,
                15.147,
                15.144,
                15.14,
                15.137,
                15.134,
                15.131,
                15.128,
                15.125,
                15.122,
                15.118,
                15.115,
                15.112,
                15.109,
                15.106,
                15.103,
                15.1,
                15.097,
                15.094,
                15.091,
                15.088,
                15.085,
                15.082,
                15.079,
                15.075,
                15.072,
                15.069,
                15.066,
                15.063,
                15.06,
                15.058,
                15.054,
                15.052,
                15.049,
                15.046,
                15.043,
                15.04,
                15.037,
                15.034,
                15.031,
                15.028,
                15.025,
                15.022,
                15.019,
                15.016,
                15.013,
                15.011,
                15.008,
                15.005,
                15.002,
                14.999,
                14.996,
                14.993,
                14.991,
                14.988,
                14.985,
                14.982,
                14.979,
                14.976,
                14.974,
                14.971,
                14.968,
                14.965,
                14.963,
                14.96,
                14.957,
                14.954,
                14.951,
                14.949,
                14.946,
                14.943,
                14.941,
                14.938,
                14.935,
                14.933,
                14.93,
                14.927,
                14.925,
                14.922,
                14.919,
                14.917,
                14.914,
                14.911,
                14.909,
                14.906,
                14.903,
                14.901,
                14.898,
                14.896,
                14.893,
                14.89,
                14.888,
                14.885,
                14.883,
                14.88,
                14.877,
                14.875,
                14.872,
                14.87,
                14.867,
                14.865,
                14.862,
                14.86,
                14.857,
                14.855,
                14.852,
                14.85,
                14.847,
                14.845,
                14.843,
                14.84,
                14.838,
                14.835,
                14.833,
                14.83,
                14.828,
                14.826,
                14.823,
                14.821,
                14.818,
                14.816,
                14.814,
                14.811,
                14.809,
                14.807,
                14.804,
                14.802,
                14.8,
                14.797,
                14.795,
                14.793,
                14.791,
                14.788,
                14.786,
                14.784,
                14.782,
                14.779,
                14.777,
                14.775,
                14.773,
                14.77,
                14.768,
                14.766,
                14.764,
                14.762,
                14.76,
                14.757,
                14.755,
                14.753,
                14.751,
                14.749,
                14.747,
                14.745,
                14.743,
                14.74,
                14.738,
                14.736,
                14.734,
                14.732,
                14.73,
                14.728,
                14.726,
                14.724,
                14.722,
                14.72,
                14.718,
                14.716,
                14.714,
                14.712,
                14.71,
                14.708,
                14.706,
                14.704,
                14.702,
                14.7,
                14.698,
                14.696,
                14.694,
                14.692,
                14.691,
                14.689,
                14.687,
                14.685,
                14.683,
                14.681,
                14.679,
                14.678,
                14.676,
                14.674,
                14.672,
                14.67,
                14.668,
                14.667,
                14.665,
                14.663,
                14.661,
                14.659,
                14.658,
                14.656,
                14.654,
                14.652,
                14.651,
                14.649,
                14.647,
                14.645,
                14.644,
                14.642,
                14.64,
                14.639,
                14.637,
                14.635,
                14.634,
                14.632,
                14.63,
                14.629,
                14.627,
                14.625,
                14.624,
                14.622,
                14.62,
                14.619,
                14.617,
                14.616,
                14.614,
                14.612,
                14.611,
                14.609,
                14.608,
                14.606,
                14.605,
                14.603,
                14.601,
                14.6,
                14.598,
                14.597,
                14.595,
                14.594,
                14.592,
                14.591,
                14.589,
                14.588,
                14.586,
                14.585,
                14.583,
                14.582,
                14.58,
                14.579,
                14.577,
                14.576,
                14.574,
                14.573,
                14.571,
                14.57,
                14.569,
                14.567,
                14.566,
                14.564,
                14.563,
                14.561,
                14.56,
                14.559,
                14.557,
                14.556,
                14.554,
                14.553,
                14.552,
                14.55,
                14.549,
                14.547,
                14.806,
                14.805,
                14.803,
                14.802,
                14.801,
                14.799,
                14.798,
                14.797,
                14.796,
                14.794,
                14.793,
                14.792,
                14.791,
                14.789,
                14.788,
                14.787,
                14.785,
                14.784,
                14.783,
                14.781,
                14.78,
                14.779,
                14.778,
                14.776,
                14.775,
                14.774,
                14.772,
                14.771,
                14.77,
                14.769,
                14.767,
                14.766,
                14.765,
                14.764,
                14.762,
                14.761,
                14.76,
                14.759,
                14.757,
                14.756,
                14.755,
                14.753,
                14.752,
                14.751,
                14.75,
                14.748,
                14.747,
                14.746,
                14.745,
                14.743,
                14.742,
                14.741,
                14.74,
                14.738,
                14.737,
                14.736,
                14.735,
                14.733,
                14.732,
                14.731,
                14.73,
                14.728,
                14.727,
                14.726,
                14.724,
                14.723,
                14.722,
                14.721,
                14.72,
                14.718,
                14.717,
                14.716,
                14.715,
                14.713,
                14.712,
                14.711,
                14.71,
                14.708,
                14.707,
                14.706,
                14.705,
                14.704,
                14.702,
                14.701,
                14.7,
                14.699,
                14.697,
                14.696,
                14.695,
                14.694,
                14.692,
                14.691,
                14.69,
                14.689,
                14.688,
                14.686,
                14.685,
                14.684,
                14.683,
                14.681,
                14.68,
                14.679,
                14.678,
                14.677,
                14.675,
                14.674,
                14.673,
                14.672,
                14.67,
                14.669,
                14.668,
                14.667,
                14.666,
                14.664,
                14.663,
                14.662,
                14.661,
                14.66,
                14.658,
                14.657,
                14.656,
                14.655,
                14.653,
                14.652,
                14.651,
                14.65,
                14.649,
                14.648,
                14.646,
                14.645,
                14.644,
                14.643,
                14.642,
                14.64,
                14.639,
                14.638,
                14.637,
                14.636,
                14.634,
                14.633,
                14.632,
                14.631,
                14.63,
                14.629,
                14.627,
                14.626,
                14.625,
                14.624,
                14.623,
                14.621,
                14.62,
                14.619,
                14.618,
                14.617,
                14.615,
                14.614,
                14.613,
                14.612,
                14.611,
                14.61,
                14.608,
                14.607,
                14.606,
                14.605,
                14.604,
                14.603,
                14.601,
                14.6,
                14.599,
                14.598,
                14.597,
                14.595,
                14.594,
                14.593,
                14.592,
                14.591,
                14.59,
                14.588,
                14.587,
                14.586,
                14.585,
                14.584,
                14.583,
                14.582,
                14.58,
                14.579,
                14.578,
                14.577,
                14.576,
                14.575,
                14.573,
                14.572,
                14.571,
                14.57,
                14.569,
                14.568,
                14.566,
                14.565,
                14.564,
                14.563,
                14.562,
                14.561,
                14.56,
                14.558,
                14.557,
                14.556,
                14.555,
                14.554,
                14.553,
                14.552,
                14.55,
                14.549,
                14.548,
                14.547,
                14.546,
                14.545,
                14.544,
                14.542,
                14.541,
                14.54,
                14.539,
                14.538,
                14.537,
                14.536,
                14.534,
                14.533,
                14.532,
                14.531,
                14.53,
                14.529,
                14.528,
                14.527,
                14.525,
                14.524,
                14.523,
                14.522,
                14.521,
                14.52,
                14.519,
                14.518,
                14.516,
                14.515,
                14.514,
                14.513,
                14.512,
                14.511,
                14.51,
                14.509,
                14.507,
                14.506,
                14.505,
                14.504,
                14.503,
                14.502,
                14.501,
                14.5,
                14.499,
                14.497,
                14.496,
                14.495,
                14.494,
                14.493,
                14.492,
                14.491,
                14.49,
                14.489,
                14.488,
                14.486,
                14.485,
                14.484,
                14.483,
                14.482,
                14.481,
                14.48,
                14.479,
                14.478,
                14.477,
                14.475,
                14.474,
                14.473,
                14.472,
                14.471,
                14.47,
                14.469,
                14.468,
                14.467,
                14.466,
                14.464,
                14.463,
                14.462,
                14.461,
                14.46,
                14.459,
                14.458,
                14.457,
                14.456,
                14.455,
                14.454,
                14.453,
                14.451,
                14.451,
                14.449,
                14.448,
                14.447,
                14.446,
                14.445,
                14.444,
                14.443,
                14.442,
                14.441,
                14.44,
                14.439,
                14.438,
                14.437,
                14.435,
                14.434,
                14.433,
                14.432,
                14.431,
                14.43,
                14.429,
                14.428,
                14.427,
                14.426,
                14.425,
                14.424,
                14.423,
                14.422,
                14.421,
                14.42,
                14.419,
                14.417,
                14.417,
                14.415,
                14.414,
                14.413,
                14.412,
                14.411,
                14.41,
                14.409,
                14.408,
                14.407,
                14.406,
                14.405,
                14.404,
                14.403,
                14.402,
                14.401,
                14.4,
                14.399,
                14.398,
                14.397,
                14.396,
                14.395,
                14.394,
                14.393,
                14.392,
                14.391,
                14.39,
                14.388,
                14.388,
                14.386,
                14.386,
                14.384,
                14.383,
                14.382,
                14.381,
                14.38,
                14.379,
                14.378,
                14.377,
                14.376,
                14.375,
                14.374,
                14.373,
                14.372,
                14.371,
                14.37,
                14.369,
                14.368,
                14.367,
                14.366,
                14.365,
                14.364,
                14.363,
                14.362,
                14.361,
                14.36,
                14.359,
                14.358,
                14.357,
                14.356,
                14.355,
                14.355,
                14.353,
                14.353,
                14.351,
                14.351,
                14.35,
                14.349,
                14.348,
                14.347,
                14.346,
                14.345,
                14.344,
                14.343,
                14.342,
                14.341,
                14.34,
                14.339,
                14.338,
                14.337,
                14.336,
                14.335,
                14.334,
                14.333,
                14.332,
                14.331,
                14.33,
                14.329,
                14.328,
                14.327,
                14.326,
                14.326,
                14.325,
                14.324,
                14.323,
                14.322,
                14.321,
                14.32,
                14.319,
                14.318,
                14.317,
                14.316,
                14.315,
                14.314,
                14.313,
                14.312,
                14.311,
                14.311,
                14.31,
                14.309,
                14.308,
                14.307,
                14.306,
                14.305,
                14.304,
                14.303,
                14.302,
                14.301,
                14.3,
                14.299,
                14.299,
                14.298,
                14.297,
                14.296,
                14.295,
                14.294,
                14.293,
                14.292,
                14.291,
                14.29,
                14.29,
                14.289,
                14.288,
                14.287,
                14.286,
                14.285,
                14.284,
                14.283,
                14.282,
                14.282,
                14.281,
                14.28,
                14.279,
                14.278,
                14.277,
                14.276,
                14.276,
                14.275,
                14.274,
                14.273,
                14.272,
                14.271,
                14.27,
                14.269,
                14.269,
                14.268,
                14.267,
                14.266,
                14.265,
                14.264,
                14.263,
                14.263,
                14.262,
                14.261,
                14.26,
                14.259,
                14.258,
                14.258,
                14.257,
                14.256,
                14.255,
                14.254,
                14.253,
                14.252,
                14.252,
                14.251,
                14.25,
                14.249,
                14.248,
                14.248,
                14.247,
                14.246,
                14.245,
                14.244,
                14.243,
                14.243,
                14.242,
                14.241,
                14.24,
                14.239,
                14.239,
                14.238,
                14.237,
                14.236,
                14.235,
                14.235,
                14.234,
                14.233,
                14.232,
                14.231,
                14.231,
                14.23,
                14.229,
                14.228,
                14.228,
                14.227,
                14.226,
                14.225,
                14.224,
                14.223,
                14.223,
                14.222,
                14.221,
                14.22,
                14.22,
                14.219,
                14.218,
                14.217,
                14.217,
                14.216,
                14.215,
                14.214,
                14.214,
                14.213,
                14.212,
                14.211,
                14.21,
                14.21,
                14.209,
                14.208,
                14.208,
                14.207,
                14.206,
                14.205,
                14.204,
                14.204,
                14.203,
                14.202,
                14.202,
                14.201,
                14.2,
                14.199,
                14.199,
                14.198,
                14.197,
                14.196,
                14.196,
                14.195,
                14.194,
                14.193,
                14.193,
                14.192,
                14.191,
                14.191,
                14.19,
                14.189,
                14.188,
                14.188,
                14.187,
                14.186,
                14.186,
                14.185,
                14.184,
                14.183,
                14.183,
                14.182,
                14.181,
                14.181,
                14.18,
                14.179,
                14.178,
                14.178,
                14.177,
                14.176,
                14.176,
                14.175,
                14.174,
                14.174,
                14.173,
                14.172,
                14.172,
                14.171,
                14.17,
                14.169,
                14.169,
                14.168,
                14.167,
                14.167,
                14.166,
                14.165,
                14.165,
                14.164,
                14.163,
                14.163,
                14.162,
                14.161,
                14.161,
                14.16,
                14.159,
                14.159,
                14.158,
                14.157,
                14.156,
                14.156,
                14.155,
                14.154,
                14.154,
                14.153,
                14.153,
                14.152,
                14.151,
                14.151,
                14.15,
                14.149,
                14.148,
                14.148,
                14.147,
                14.147,
                14.146,
                14.145,
                14.145,
                14.144,
                14.143,
                14.143,
                14.142,
                14.141,
                14.141,
                14.14,
                14.139,
                14.139,
                14.138,
                14.137,
                14.137,
                14.136,
                14.135,
                14.135,
                14.134,
                14.134,
                14.133,
                14.132,
                14.132,
                14.131,
                14.13,
                14.13,
                14.129,
                14.129,
                14.128,
                14.127,
                14.127,
                14.126,
                14.125,
                14.125,
                14.124,
                14.124,
                14.123,
                14.122,
                14.122,
                14.121,
                14.12,
                14.12,
                14.119,
                14.119,
                14.118,
                14.117,
                14.117,
                14.116,
                14.115,
                14.115,
                14.114,
                14.114,
                14.113,
                14.112,
                14.112,
                14.111,
                14.111,
                14.11,
                14.109,
                14.109,
                14.108,
                14.107,
                14.107,
                14.106,
                14.106,
                14.105,
                14.104,
                14.104,
                14.103,
                14.103,
                14.102,
                14.101,
                14.101,
                14.1,
                14.1,
                14.099,
                14.098,
                14.098,
                14.097,
                14.097,
                14.096,
                14.095,
                14.095,
                14.094,
                14.094,
                14.093,
                14.092,
                14.092,
                14.091,
                14.091,
                14.09,
                14.09,
                14.089,
                14.088,
                14.088,
                14.087,
                14.087,
                14.086,
                14.085,
                14.085,
                14.084,
                14.084,
                14.083,
                14.082,
                14.082,
                14.081,
                14.081,
                14.08,
                14.08,
                14.079,
                14.078,
                14.078,
                14.077,
                14.077,
                14.076,
                14.076,
                14.075,
                14.074,
                14.074,
                14.073,
                14.073,
                14.072,
                14.072,
                14.071,
                14.07,
                14.07,
                14.069,
                14.069,
                14.068,
                14.068,
                14.067,
                14.066,
                14.066,
                14.065,
                14.065,
                14.064,
                14.064,
                14.063,
                14.063,
                14.062,
                14.061,
                14.061,
                14.06,
                14.06,
                14.059,
                14.059,
                14.058,
                14.058,
                14.057,
                14.056,
                14.056,
                14.055,
                14.055,
                14.054,
                14.054,
                14.053,
                14.053,
                14.052,
                14.052,
                14.051,
                14.05,
                14.05,
                14.049,
                14.049,
                14.048,
                14.048,
                14.047,
                14.047,
                14.046,
                14.045,
                14.045,
                14.044,
                14.044,
                14.043,
                14.043,
                14.042,
                14.042,
                14.041,
                14.041,
                14.04,
                14.04,
                14.039,
                14.039,
                14.038,
                14.037,
                14.037,
                14.036,
                14.036,
                14.035,
                14.035,
                14.034,
                14.034,
                14.033,
                14.033,
                14.032,
                14.032,
                14.031,
                14.031,
                14.03,
                14.03,
                14.029,
                14.029,
                14.028,
                14.027,
                14.027,
                14.027,
                14.026,
                14.025,
                14.025,
                14.024,
                14.024,
                14.023,
                14.023,
                14.022,
                14.022,
                14.021,
                14.021,
                14.02,
                14.02,
                14.019,
                14.019,
                14.018,
                14.018,
                14.017,
                14.017,
                14.016,
                14.016,
                14.015,
                14.015,
                14.014,
                14.014,
                14.013,
                14.013,
                14.012,
                14.012,
                14.011,
                14.011,
                14.01,
                14.01,
                14.009,
                14.009,
                14.008,
                14.008,
                14.007,
                14.007,
                14.006,
                14.006,
                14.005,
                14.005,
                14.004,
                14.004,
                14.003,
                14.003,
                14.002,
                14.002,
                14.001,
                14.001,
                14,
                14,
                14,
                13.999,
                13.998,
                13.998,
                13.998,
                13.997,
                13.997,
                13.996,
                13.996,
                13.995,
                13.995,
                13.994,
                13.994,
                13.993,
                13.993,
                13.992,
                13.992,
                13.991,
                13.991,
                13.99,
                13.99,
                13.989,
                13.989,
                13.988,
                13.988,
                13.988,
                13.987,
                13.987,
                13.986,
                13.986,
                13.985,
                13.985,
                13.984,
                13.984,
                13.983,
                13.983,
                13.982,
                13.982,
                13.981,
                13.981,
                13.98,
                13.98,
                13.98,
                13.979,
                13.979,
                13.978,
                13.978,
                13.977,
                13.977,
                13.976,
                13.976,
                13.975,
                13.975,
                13.975,
                13.974,
                13.974,
                13.973,
                13.973,
                13.972,
                13.972,
                13.971,
                13.971,
                13.97,
                13.97,
                13.97,
                13.969,
                13.969,
                13.968,
                13.968,
                13.967,
                13.967,
                13.966,
                13.966,
                13.966,
                13.965,
                13.965,
                13.964,
                13.964,
                13.963,
                13.963,
                13.962,
                13.962,
                13.961,
                13.961,
                13.961,
                13.96,
                13.96,
                13.959,
                13.959,
                13.958,
                13.958,
                13.958,
                13.957,
                13.957,
                13.956,
                13.956,
                13.955,
                13.955,
                13.954,
                13.954,
                13.954,
                13.953,
                13.953,
                13.952,
                13.952,
                13.951,
                13.951,
                13.951,
                13.95,
                13.95,
                13.949,
                13.949,
                13.949,
                13.948,
                13.948,
                13.947,
                13.947,
                13.946,
                13.946,
                13.946,
                13.945,
                13.945,
                13.944,
                13.944,
                13.943,
                13.943,
                13.943,
                13.942,
                13.942,
                13.942,
                13.941,
                13.941,
                13.94,
                13.94,
                13.939,
                13.939,
                13.939,
                13.938,
                13.938,
                13.937,
                13.937,
                13.937,
                13.936,
                13.936,
                13.935,
                13.935,
                13.935,
                13.934,
                13.934,
                13.933,
                13.933,
                13.933,
                13.932,
                13.932,
                13.931,
                13.931,
                13.931,
                13.93,
                13.93,
                13.929,
                13.929,
                13.929,
                13.928,
                13.928,
                13.927,
                13.927,
                13.927,
                13.926,
                13.926,
                13.926,
                13.925,
                13.925,
                13.925,
                13.924,
                13.924,
                13.923,
                13.923,
                13.923,
                13.922,
                13.922,
                13.922,
                13.921,
                13.921,
                13.92,
                13.92,
                13.92,
                13.919,
                13.919,
                13.919,
                13.918,
                13.918,
                13.917,
                13.917,
                13.917,
                13.916,
                13.916,
                13.916,
                13.915,
                13.915,
                13.915,
                13.914,
                13.914,
                13.913,
                13.913,
                13.913,
                13.912,
                13.912,
                13.912,
                13.911,
                13.911,
                13.911,
                13.91,
                13.91,
                13.91,
                13.909,
                13.909,
                13.909
            ],
            P25: [
                12.578,
                12.563,
                12.548,
                12.533,
                12.518,
                12.503,
                12.487,
                12.472,
                12.517,
                12.561,
                12.606,
                12.65,
                12.695,
                12.739,
                12.784,
                12.863,
                12.944,
                13.027,
                13.11,
                13.193,
                13.275,
                13.357,
                13.437,
                13.515,
                13.593,
                13.668,
                13.743,
                13.816,
                13.887,
                13.957,
                14.025,
                14.091,
                14.155,
                14.217,
                14.278,
                14.336,
                14.393,
                14.448,
                14.502,
                14.554,
                14.604,
                14.653,
                14.7,
                14.746,
                14.791,
                14.834,
                14.877,
                14.918,
                14.958,
                14.997,
                15.035,
                15.072,
                15.108,
                15.143,
                15.177,
                15.211,
                15.243,
                15.274,
                15.305,
                15.335,
                15.364,
                15.392,
                15.42,
                15.447,
                15.473,
                15.498,
                15.522,
                15.546,
                15.569,
                15.592,
                15.613,
                15.635,
                15.655,
                15.675,
                15.695,
                15.713,
                15.732,
                15.75,
                15.767,
                15.784,
                15.8,
                15.816,
                15.832,
                15.847,
                15.861,
                15.876,
                15.889,
                15.903,
                15.916,
                15.929,
                15.942,
                15.954,
                15.966,
                15.977,
                15.988,
                16,
                16.01,
                16.021,
                16.031,
                16.041,
                16.051,
                16.06,
                16.069,
                16.078,
                16.087,
                16.096,
                16.104,
                16.113,
                16.121,
                16.129,
                16.137,
                16.144,
                16.152,
                16.159,
                16.166,
                16.173,
                16.18,
                16.187,
                16.193,
                16.2,
                16.206,
                16.212,
                16.219,
                16.224,
                16.231,
                16.236,
                16.242,
                16.248,
                16.253,
                16.258,
                16.264,
                16.269,
                16.274,
                16.279,
                16.284,
                16.288,
                16.293,
                16.298,
                16.302,
                16.306,
                16.311,
                16.315,
                16.319,
                16.323,
                16.327,
                16.33,
                16.334,
                16.337,
                16.341,
                16.344,
                16.347,
                16.351,
                16.354,
                16.357,
                16.36,
                16.363,
                16.365,
                16.368,
                16.371,
                16.373,
                16.375,
                16.378,
                16.38,
                16.382,
                16.384,
                16.386,
                16.388,
                16.39,
                16.392,
                16.394,
                16.395,
                16.397,
                16.398,
                16.4,
                16.401,
                16.402,
                16.404,
                16.405,
                16.406,
                16.407,
                16.408,
                16.409,
                16.41,
                16.41,
                16.411,
                16.412,
                16.412,
                16.413,
                16.413,
                16.414,
                16.414,
                16.414,
                16.414,
                16.414,
                16.414,
                16.415,
                16.414,
                16.414,
                16.414,
                16.414,
                16.414,
                16.413,
                16.413,
                16.412,
                16.412,
                16.411,
                16.41,
                16.41,
                16.409,
                16.408,
                16.407,
                16.406,
                16.405,
                16.404,
                16.403,
                16.402,
                16.401,
                16.399,
                16.398,
                16.397,
                16.395,
                16.394,
                16.392,
                16.391,
                16.389,
                16.388,
                16.386,
                16.384,
                16.382,
                16.381,
                16.379,
                16.377,
                16.375,
                16.373,
                16.371,
                16.369,
                16.367,
                16.364,
                16.362,
                16.36,
                16.358,
                16.355,
                16.353,
                16.35,
                16.348,
                16.345,
                16.343,
                16.34,
                16.338,
                16.335,
                16.333,
                16.33,
                16.327,
                16.324,
                16.322,
                16.319,
                16.316,
                16.313,
                16.31,
                16.307,
                16.304,
                16.301,
                16.298,
                16.295,
                16.292,
                16.289,
                16.286,
                16.283,
                16.28,
                16.277,
                16.274,
                16.27,
                16.267,
                16.264,
                16.261,
                16.257,
                16.254,
                16.251,
                16.247,
                16.244,
                16.241,
                16.237,
                16.234,
                16.231,
                16.227,
                16.224,
                16.22,
                16.217,
                16.213,
                16.21,
                16.206,
                16.203,
                16.199,
                16.195,
                16.192,
                16.188,
                16.185,
                16.181,
                16.177,
                16.174,
                16.17,
                16.167,
                16.163,
                16.159,
                16.156,
                16.152,
                16.148,
                16.144,
                16.141,
                16.137,
                16.133,
                16.13,
                16.126,
                16.122,
                16.118,
                16.115,
                16.111,
                16.107,
                16.103,
                16.1,
                16.096,
                16.092,
                16.088,
                16.084,
                16.081,
                16.077,
                16.073,
                16.069,
                16.066,
                16.062,
                16.058,
                16.054,
                16.05,
                16.047,
                16.043,
                16.039,
                16.035,
                16.031,
                16.028,
                16.024,
                16.02,
                16.016,
                16.013,
                16.009,
                16.005,
                16.001,
                15.997,
                15.994,
                15.99,
                15.986,
                15.982,
                15.978,
                15.974,
                15.971,
                15.967,
                15.963,
                15.959,
                15.955,
                15.952,
                15.948,
                15.944,
                15.94,
                15.937,
                15.933,
                15.929,
                15.925,
                15.921,
                15.918,
                15.914,
                15.91,
                15.906,
                15.903,
                15.899,
                15.895,
                15.891,
                15.887,
                15.884,
                15.88,
                15.876,
                15.872,
                15.869,
                15.865,
                15.861,
                15.857,
                15.854,
                15.85,
                15.846,
                15.842,
                15.839,
                15.835,
                15.831,
                15.828,
                15.824,
                15.82,
                15.816,
                15.813,
                15.809,
                15.805,
                15.802,
                15.798,
                15.794,
                15.791,
                15.787,
                15.783,
                15.78,
                15.776,
                15.772,
                15.769,
                15.765,
                15.761,
                15.758,
                15.754,
                15.751,
                15.747,
                15.743,
                15.74,
                15.736,
                15.733,
                15.729,
                15.725,
                15.722,
                15.718,
                15.715,
                15.711,
                15.707,
                15.704,
                15.7,
                15.697,
                15.693,
                15.69,
                15.686,
                15.683,
                15.679,
                15.676,
                15.672,
                15.669,
                15.665,
                15.662,
                15.658,
                15.655,
                15.651,
                15.648,
                15.644,
                15.641,
                15.637,
                15.634,
                15.631,
                15.627,
                15.624,
                15.62,
                15.617,
                15.614,
                15.61,
                15.607,
                15.603,
                15.6,
                15.597,
                15.593,
                15.59,
                15.587,
                15.583,
                15.58,
                15.577,
                15.573,
                15.57,
                15.567,
                15.563,
                15.56,
                15.557,
                15.553,
                15.55,
                15.547,
                15.544,
                15.54,
                15.537,
                15.534,
                15.531,
                15.527,
                15.524,
                15.521,
                15.518,
                15.515,
                15.512,
                15.508,
                15.505,
                15.502,
                15.499,
                15.496,
                15.493,
                15.489,
                15.486,
                15.483,
                15.48,
                15.477,
                15.474,
                15.471,
                15.468,
                15.464,
                15.461,
                15.458,
                15.455,
                15.452,
                15.449,
                15.446,
                15.443,
                15.44,
                15.437,
                15.434,
                15.431,
                15.428,
                15.425,
                15.422,
                15.419,
                15.416,
                15.413,
                15.41,
                15.407,
                15.404,
                15.401,
                15.398,
                15.395,
                15.392,
                15.389,
                15.386,
                15.383,
                15.381,
                15.378,
                15.375,
                15.372,
                15.369,
                15.366,
                15.363,
                15.36,
                15.358,
                15.355,
                15.352,
                15.349,
                15.346,
                15.343,
                15.341,
                15.338,
                15.335,
                15.332,
                15.33,
                15.327,
                15.324,
                15.321,
                15.319,
                15.316,
                15.313,
                15.31,
                15.308,
                15.305,
                15.302,
                15.3,
                15.297,
                15.294,
                15.292,
                15.289,
                15.286,
                15.284,
                15.281,
                15.278,
                15.276,
                15.273,
                15.27,
                15.268,
                15.265,
                15.263,
                15.26,
                15.257,
                15.255,
                15.252,
                15.25,
                15.247,
                15.245,
                15.242,
                15.24,
                15.237,
                15.235,
                15.232,
                15.23,
                15.227,
                15.225,
                15.222,
                15.22,
                15.217,
                15.215,
                15.213,
                15.21,
                15.208,
                15.205,
                15.203,
                15.201,
                15.198,
                15.196,
                15.193,
                15.191,
                15.189,
                15.187,
                15.184,
                15.182,
                15.179,
                15.177,
                15.175,
                15.173,
                15.17,
                15.168,
                15.166,
                15.163,
                15.161,
                15.159,
                15.157,
                15.155,
                15.152,
                15.15,
                15.148,
                15.146,
                15.144,
                15.141,
                15.139,
                15.137,
                15.135,
                15.133,
                15.131,
                15.129,
                15.126,
                15.124,
                15.122,
                15.12,
                15.118,
                15.116,
                15.114,
                15.112,
                15.11,
                15.108,
                15.106,
                15.104,
                15.102,
                15.1,
                15.098,
                15.096,
                15.094,
                15.092,
                15.09,
                15.088,
                15.086,
                15.084,
                15.082,
                15.08,
                15.078,
                15.076,
                15.074,
                15.072,
                15.07,
                15.069,
                15.067,
                15.065,
                15.063,
                15.061,
                15.059,
                15.057,
                15.055,
                15.054,
                15.052,
                15.05,
                15.048,
                15.046,
                15.045,
                15.043,
                15.041,
                15.039,
                15.038,
                15.036,
                15.034,
                15.032,
                15.031,
                15.029,
                15.027,
                15.025,
                15.024,
                15.022,
                15.02,
                15.018,
                15.017,
                15.015,
                15.013,
                15.012,
                15.01,
                15.009,
                15.007,
                15.005,
                15.004,
                15.002,
                15,
                14.999,
                14.997,
                14.995,
                14.994,
                14.992,
                14.991,
                14.989,
                14.987,
                14.986,
                14.984,
                14.983,
                14.981,
                14.98,
                14.978,
                14.976,
                14.975,
                14.973,
                14.972,
                14.97,
                14.969,
                14.967,
                14.966,
                14.964,
                14.963,
                14.961,
                14.96,
                14.958,
                14.957,
                14.955,
                14.954,
                14.952,
                14.951,
                14.949,
                14.948,
                14.947,
                14.945,
                15.212,
                15.211,
                15.21,
                15.208,
                15.207,
                15.206,
                15.204,
                15.203,
                15.202,
                15.201,
                15.199,
                15.198,
                15.197,
                15.195,
                15.194,
                15.193,
                15.192,
                15.19,
                15.189,
                15.188,
                15.187,
                15.185,
                15.184,
                15.183,
                15.181,
                15.18,
                15.179,
                15.178,
                15.176,
                15.175,
                15.174,
                15.172,
                15.171,
                15.17,
                15.169,
                15.167,
                15.166,
                15.165,
                15.164,
                15.162,
                15.161,
                15.16,
                15.159,
                15.157,
                15.156,
                15.155,
                15.154,
                15.152,
                15.151,
                15.15,
                15.149,
                15.147,
                15.146,
                15.145,
                15.144,
                15.142,
                15.141,
                15.14,
                15.139,
                15.137,
                15.136,
                15.135,
                15.134,
                15.133,
                15.131,
                15.13,
                15.129,
                15.128,
                15.126,
                15.125,
                15.124,
                15.123,
                15.121,
                15.12,
                15.119,
                15.118,
                15.116,
                15.115,
                15.114,
                15.113,
                15.112,
                15.11,
                15.109,
                15.108,
                15.107,
                15.105,
                15.104,
                15.103,
                15.102,
                15.1,
                15.099,
                15.098,
                15.097,
                15.096,
                15.094,
                15.093,
                15.092,
                15.091,
                15.09,
                15.088,
                15.087,
                15.086,
                15.085,
                15.084,
                15.082,
                15.081,
                15.08,
                15.079,
                15.078,
                15.076,
                15.075,
                15.074,
                15.073,
                15.072,
                15.07,
                15.069,
                15.068,
                15.067,
                15.066,
                15.064,
                15.063,
                15.062,
                15.061,
                15.06,
                15.058,
                15.057,
                15.056,
                15.055,
                15.054,
                15.052,
                15.051,
                15.05,
                15.049,
                15.048,
                15.047,
                15.045,
                15.044,
                15.043,
                15.042,
                15.041,
                15.039,
                15.038,
                15.037,
                15.036,
                15.035,
                15.034,
                15.032,
                15.031,
                15.03,
                15.029,
                15.028,
                15.026,
                15.025,
                15.024,
                15.023,
                15.022,
                15.021,
                15.02,
                15.018,
                15.017,
                15.016,
                15.015,
                15.014,
                15.012,
                15.011,
                15.01,
                15.009,
                15.008,
                15.007,
                15.006,
                15.004,
                15.003,
                15.002,
                15.001,
                15,
                14.999,
                14.997,
                14.996,
                14.995,
                14.994,
                14.993,
                14.992,
                14.991,
                14.99,
                14.988,
                14.987,
                14.986,
                14.985,
                14.984,
                14.983,
                14.981,
                14.98,
                14.979,
                14.978,
                14.977,
                14.976,
                14.975,
                14.973,
                14.972,
                14.971,
                14.97,
                14.969,
                14.968,
                14.967,
                14.965,
                14.964,
                14.963,
                14.962,
                14.961,
                14.96,
                14.959,
                14.958,
                14.956,
                14.955,
                14.954,
                14.953,
                14.952,
                14.951,
                14.95,
                14.948,
                14.947,
                14.946,
                14.945,
                14.944,
                14.943,
                14.942,
                14.941,
                14.94,
                14.938,
                14.937,
                14.936,
                14.935,
                14.934,
                14.933,
                14.932,
                14.931,
                14.93,
                14.928,
                14.927,
                14.926,
                14.925,
                14.924,
                14.923,
                14.922,
                14.921,
                14.92,
                14.918,
                14.917,
                14.916,
                14.915,
                14.914,
                14.913,
                14.912,
                14.911,
                14.91,
                14.909,
                14.907,
                14.906,
                14.905,
                14.904,
                14.903,
                14.902,
                14.901,
                14.9,
                14.899,
                14.898,
                14.896,
                14.895,
                14.894,
                14.893,
                14.892,
                14.891,
                14.89,
                14.889,
                14.888,
                14.887,
                14.886,
                14.884,
                14.883,
                14.882,
                14.881,
                14.88,
                14.879,
                14.878,
                14.877,
                14.876,
                14.875,
                14.874,
                14.873,
                14.871,
                14.871,
                14.869,
                14.868,
                14.867,
                14.866,
                14.865,
                14.864,
                14.863,
                14.862,
                14.861,
                14.86,
                14.859,
                14.858,
                14.857,
                14.856,
                14.854,
                14.853,
                14.852,
                14.851,
                14.85,
                14.849,
                14.848,
                14.847,
                14.846,
                14.845,
                14.844,
                14.843,
                14.842,
                14.841,
                14.84,
                14.839,
                14.838,
                14.837,
                14.836,
                14.834,
                14.834,
                14.832,
                14.831,
                14.83,
                14.829,
                14.828,
                14.827,
                14.826,
                14.825,
                14.824,
                14.823,
                14.822,
                14.821,
                14.82,
                14.819,
                14.818,
                14.817,
                14.816,
                14.815,
                14.814,
                14.813,
                14.812,
                14.811,
                14.81,
                14.809,
                14.808,
                14.807,
                14.806,
                14.805,
                14.804,
                14.803,
                14.802,
                14.801,
                14.8,
                14.799,
                14.798,
                14.797,
                14.796,
                14.795,
                14.793,
                14.793,
                14.792,
                14.791,
                14.79,
                14.788,
                14.788,
                14.786,
                14.786,
                14.785,
                14.784,
                14.783,
                14.782,
                14.781,
                14.78,
                14.779,
                14.778,
                14.777,
                14.776,
                14.775,
                14.774,
                14.773,
                14.772,
                14.771,
                14.77,
                14.769,
                14.768,
                14.767,
                14.766,
                14.765,
                14.764,
                14.763,
                14.762,
                14.761,
                14.76,
                14.759,
                14.758,
                14.757,
                14.756,
                14.755,
                14.754,
                14.753,
                14.752,
                14.751,
                14.75,
                14.75,
                14.748,
                14.748,
                14.747,
                14.746,
                14.745,
                14.744,
                14.743,
                14.742,
                14.741,
                14.74,
                14.739,
                14.738,
                14.737,
                14.736,
                14.735,
                14.734,
                14.734,
                14.733,
                14.732,
                14.731,
                14.73,
                14.729,
                14.728,
                14.727,
                14.726,
                14.725,
                14.724,
                14.723,
                14.722,
                14.721,
                14.721,
                14.72,
                14.719,
                14.718,
                14.717,
                14.716,
                14.715,
                14.714,
                14.713,
                14.712,
                14.712,
                14.711,
                14.71,
                14.709,
                14.708,
                14.707,
                14.706,
                14.705,
                14.704,
                14.704,
                14.703,
                14.702,
                14.701,
                14.7,
                14.699,
                14.698,
                14.697,
                14.697,
                14.696,
                14.695,
                14.694,
                14.693,
                14.692,
                14.691,
                14.69,
                14.69,
                14.689,
                14.688,
                14.687,
                14.686,
                14.685,
                14.684,
                14.684,
                14.683,
                14.682,
                14.681,
                14.68,
                14.679,
                14.678,
                14.678,
                14.677,
                14.676,
                14.675,
                14.674,
                14.673,
                14.673,
                14.672,
                14.671,
                14.67,
                14.669,
                14.668,
                14.668,
                14.667,
                14.666,
                14.665,
                14.664,
                14.664,
                14.663,
                14.662,
                14.661,
                14.66,
                14.66,
                14.659,
                14.658,
                14.657,
                14.656,
                14.655,
                14.655,
                14.654,
                14.653,
                14.652,
                14.652,
                14.651,
                14.65,
                14.649,
                14.648,
                14.648,
                14.647,
                14.646,
                14.645,
                14.645,
                14.644,
                14.643,
                14.642,
                14.641,
                14.641,
                14.64,
                14.639,
                14.638,
                14.638,
                14.637,
                14.636,
                14.635,
                14.634,
                14.634,
                14.633,
                14.632,
                14.631,
                14.631,
                14.63,
                14.629,
                14.628,
                14.628,
                14.627,
                14.626,
                14.625,
                14.625,
                14.624,
                14.623,
                14.622,
                14.622,
                14.621,
                14.62,
                14.62,
                14.619,
                14.618,
                14.617,
                14.617,
                14.616,
                14.615,
                14.614,
                14.614,
                14.613,
                14.612,
                14.612,
                14.611,
                14.61,
                14.609,
                14.609,
                14.608,
                14.607,
                14.607,
                14.606,
                14.605,
                14.604,
                14.604,
                14.603,
                14.602,
                14.602,
                14.601,
                14.6,
                14.599,
                14.599,
                14.598,
                14.597,
                14.597,
                14.596,
                14.595,
                14.595,
                14.594,
                14.593,
                14.593,
                14.592,
                14.591,
                14.591,
                14.59,
                14.589,
                14.589,
                14.588,
                14.587,
                14.586,
                14.586,
                14.585,
                14.584,
                14.584,
                14.583,
                14.582,
                14.582,
                14.581,
                14.58,
                14.58,
                14.579,
                14.578,
                14.578,
                14.577,
                14.576,
                14.576,
                14.575,
                14.574,
                14.574,
                14.573,
                14.572,
                14.572,
                14.571,
                14.57,
                14.57,
                14.569,
                14.569,
                14.568,
                14.567,
                14.567,
                14.566,
                14.565,
                14.565,
                14.564,
                14.563,
                14.563,
                14.562,
                14.562,
                14.561,
                14.56,
                14.56,
                14.559,
                14.558,
                14.558,
                14.557,
                14.556,
                14.556,
                14.555,
                14.554,
                14.554,
                14.553,
                14.553,
                14.552,
                14.551,
                14.551,
                14.55,
                14.55,
                14.549,
                14.548,
                14.548,
                14.547,
                14.546,
                14.546,
                14.545,
                14.545,
                14.544,
                14.543,
                14.543,
                14.542,
                14.541,
                14.541,
                14.54,
                14.54,
                14.539,
                14.539,
                14.538,
                14.537,
                14.537,
                14.536,
                14.536,
                14.535,
                14.534,
                14.534,
                14.533,
                14.532,
                14.532,
                14.531,
                14.531,
                14.53,
                14.529,
                14.529,
                14.528,
                14.528,
                14.527,
                14.526,
                14.526,
                14.525,
                14.525,
                14.524,
                14.524,
                14.523,
                14.522,
                14.522,
                14.521,
                14.521,
                14.52,
                14.519,
                14.519,
                14.518,
                14.518,
                14.517,
                14.517,
                14.516,
                14.515,
                14.515,
                14.514,
                14.514,
                14.513,
                14.512,
                14.512,
                14.511,
                14.511,
                14.51,
                14.51,
                14.509,
                14.508,
                14.508,
                14.507,
                14.507,
                14.506,
                14.506,
                14.505,
                14.504,
                14.504,
                14.503,
                14.503,
                14.502,
                14.502,
                14.501,
                14.5,
                14.5,
                14.499,
                14.499,
                14.498,
                14.498,
                14.497,
                14.497,
                14.496,
                14.495,
                14.495,
                14.494,
                14.494,
                14.493,
                14.493,
                14.492,
                14.492,
                14.491,
                14.49,
                14.49,
                14.489,
                14.489,
                14.488,
                14.488,
                14.487,
                14.487,
                14.486,
                14.486,
                14.485,
                14.485,
                14.484,
                14.483,
                14.483,
                14.482,
                14.482,
                14.481,
                14.481,
                14.48,
                14.48,
                14.479,
                14.479,
                14.478,
                14.477,
                14.477,
                14.476,
                14.476,
                14.475,
                14.475,
                14.474,
                14.474,
                14.473,
                14.473,
                14.472,
                14.472,
                14.471,
                14.471,
                14.47,
                14.47,
                14.469,
                14.468,
                14.468,
                14.467,
                14.467,
                14.466,
                14.466,
                14.465,
                14.465,
                14.464,
                14.464,
                14.463,
                14.463,
                14.462,
                14.462,
                14.461,
                14.461,
                14.46,
                14.46,
                14.459,
                14.459,
                14.458,
                14.457,
                14.457,
                14.457,
                14.456,
                14.455,
                14.455,
                14.454,
                14.454,
                14.453,
                14.453,
                14.452,
                14.452,
                14.451,
                14.451,
                14.45,
                14.45,
                14.449,
                14.449,
                14.448,
                14.448,
                14.447,
                14.447,
                14.446,
                14.446,
                14.445,
                14.445,
                14.444,
                14.444,
                14.443,
                14.443,
                14.442,
                14.442,
                14.441,
                14.441,
                14.441,
                14.44,
                14.439,
                14.439,
                14.439,
                14.438,
                14.438,
                14.437,
                14.437,
                14.436,
                14.436,
                14.435,
                14.435,
                14.434,
                14.434,
                14.433,
                14.433,
                14.432,
                14.432,
                14.431,
                14.431,
                14.43,
                14.43,
                14.429,
                14.429,
                14.428,
                14.428,
                14.427,
                14.427,
                14.426,
                14.426,
                14.426,
                14.425,
                14.425,
                14.424,
                14.424,
                14.423,
                14.423,
                14.422,
                14.422,
                14.421,
                14.421,
                14.421,
                14.42,
                14.42,
                14.419,
                14.419,
                14.418,
                14.418,
                14.417,
                14.417,
                14.416,
                14.416,
                14.415,
                14.415,
                14.414,
                14.414,
                14.413,
                14.413,
                14.413,
                14.412,
                14.412,
                14.411,
                14.411,
                14.41,
                14.41,
                14.409,
                14.409,
                14.408,
                14.408,
                14.408,
                14.407,
                14.407,
                14.406,
                14.406,
                14.405,
                14.405,
                14.405,
                14.404,
                14.404,
                14.403,
                14.403,
                14.402,
                14.402,
                14.401,
                14.401,
                14.4,
                14.4,
                14.4,
                14.399,
                14.399,
                14.398,
                14.398,
                14.397,
                14.397,
                14.396,
                14.396,
                14.396,
                14.395,
                14.395,
                14.394,
                14.394,
                14.393,
                14.393,
                14.393,
                14.392,
                14.392,
                14.391,
                14.391,
                14.39,
                14.39,
                14.389,
                14.389,
                14.389,
                14.388,
                14.388,
                14.387,
                14.387,
                14.387,
                14.386,
                14.386,
                14.385,
                14.385,
                14.384,
                14.384,
                14.383,
                14.383,
                14.383,
                14.382,
                14.382,
                14.381,
                14.381,
                14.381,
                14.38,
                14.38,
                14.379,
                14.379,
                14.378,
                14.378,
                14.378,
                14.377,
                14.377,
                14.376,
                14.376,
                14.376,
                14.375,
                14.375,
                14.374,
                14.374,
                14.374,
                14.373,
                14.373,
                14.372,
                14.372,
                14.372,
                14.371,
                14.371,
                14.37,
                14.37,
                14.37,
                14.369,
                14.369,
                14.368,
                14.368,
                14.368,
                14.367,
                14.367,
                14.366,
                14.366,
                14.366,
                14.365,
                14.365,
                14.364,
                14.364,
                14.364,
                14.363,
                14.363,
                14.363,
                14.362,
                14.362,
                14.361,
                14.361,
                14.36,
                14.36,
                14.36,
                14.359,
                14.359,
                14.359,
                14.358,
                14.358,
                14.357,
                14.357,
                14.357,
                14.356,
                14.356,
                14.356,
                14.355,
                14.355,
                14.354,
                14.354,
                14.354,
                14.353,
                14.353,
                14.353,
                14.352,
                14.352,
                14.351,
                14.351,
                14.351,
                14.35,
                14.35,
                14.35,
                14.349,
                14.349,
                14.349,
                14.348,
                14.348,
                14.348,
                14.347,
                14.347,
                14.346,
                14.346,
                14.346,
                14.345,
                14.345,
                14.345,
                14.344,
                14.344,
                14.344,
                14.343,
                14.343,
                14.343,
                14.342,
                14.342,
                14.341,
                14.341,
                14.341,
                14.34,
                14.34,
                14.34,
                14.339,
                14.339,
                14.339,
                14.338,
                14.338,
                14.338,
                14.337,
                14.337,
                14.337,
                14.336,
                14.336,
                14.336,
                14.335,
                14.335,
                14.335,
                14.334,
                14.334,
                14.334,
                14.333,
                14.333,
                14.333,
                14.332
            ],
            P50: [
                13.407,
                13.398,
                13.388,
                13.379,
                13.37,
                13.361,
                13.351,
                13.342,
                13.384,
                13.427,
                13.469,
                13.511,
                13.553,
                13.595,
                13.638,
                13.717,
                13.801,
                13.885,
                13.971,
                14.056,
                14.14,
                14.224,
                14.307,
                14.388,
                14.468,
                14.546,
                14.623,
                14.698,
                14.771,
                14.844,
                14.914,
                14.982,
                15.049,
                15.113,
                15.175,
                15.236,
                15.294,
                15.351,
                15.406,
                15.46,
                15.512,
                15.562,
                15.611,
                15.658,
                15.704,
                15.749,
                15.793,
                15.835,
                15.877,
                15.917,
                15.956,
                15.994,
                16.031,
                16.067,
                16.102,
                16.137,
                16.17,
                16.202,
                16.234,
                16.264,
                16.294,
                16.323,
                16.351,
                16.379,
                16.405,
                16.431,
                16.456,
                16.481,
                16.504,
                16.527,
                16.549,
                16.571,
                16.592,
                16.612,
                16.632,
                16.651,
                16.67,
                16.688,
                16.706,
                16.723,
                16.74,
                16.756,
                16.772,
                16.787,
                16.802,
                16.816,
                16.83,
                16.844,
                16.857,
                16.87,
                16.883,
                16.895,
                16.907,
                16.919,
                16.93,
                16.941,
                16.952,
                16.962,
                16.973,
                16.983,
                16.992,
                17.002,
                17.011,
                17.02,
                17.029,
                17.038,
                17.046,
                17.054,
                17.062,
                17.07,
                17.078,
                17.086,
                17.093,
                17.1,
                17.107,
                17.114,
                17.121,
                17.128,
                17.134,
                17.141,
                17.147,
                17.153,
                17.159,
                17.165,
                17.171,
                17.177,
                17.183,
                17.188,
                17.193,
                17.199,
                17.204,
                17.209,
                17.214,
                17.219,
                17.223,
                17.228,
                17.233,
                17.237,
                17.241,
                17.246,
                17.25,
                17.254,
                17.258,
                17.262,
                17.265,
                17.269,
                17.272,
                17.276,
                17.279,
                17.282,
                17.285,
                17.289,
                17.291,
                17.294,
                17.297,
                17.3,
                17.302,
                17.305,
                17.307,
                17.31,
                17.312,
                17.314,
                17.316,
                17.318,
                17.32,
                17.322,
                17.324,
                17.325,
                17.327,
                17.328,
                17.33,
                17.331,
                17.333,
                17.334,
                17.335,
                17.336,
                17.337,
                17.338,
                17.339,
                17.34,
                17.341,
                17.341,
                17.342,
                17.342,
                17.343,
                17.343,
                17.344,
                17.344,
                17.344,
                17.344,
                17.344,
                17.344,
                17.344,
                17.344,
                17.344,
                17.344,
                17.343,
                17.343,
                17.343,
                17.342,
                17.342,
                17.341,
                17.34,
                17.34,
                17.339,
                17.338,
                17.337,
                17.336,
                17.335,
                17.334,
                17.333,
                17.331,
                17.33,
                17.329,
                17.328,
                17.326,
                17.325,
                17.323,
                17.322,
                17.32,
                17.318,
                17.316,
                17.315,
                17.313,
                17.311,
                17.309,
                17.307,
                17.305,
                17.303,
                17.301,
                17.298,
                17.296,
                17.294,
                17.292,
                17.289,
                17.287,
                17.284,
                17.282,
                17.279,
                17.277,
                17.274,
                17.272,
                17.269,
                17.266,
                17.263,
                17.261,
                17.258,
                17.255,
                17.252,
                17.249,
                17.246,
                17.243,
                17.24,
                17.237,
                17.234,
                17.231,
                17.227,
                17.224,
                17.221,
                17.218,
                17.214,
                17.211,
                17.208,
                17.204,
                17.201,
                17.198,
                17.194,
                17.191,
                17.187,
                17.184,
                17.18,
                17.177,
                17.173,
                17.17,
                17.166,
                17.162,
                17.159,
                17.155,
                17.151,
                17.148,
                17.144,
                17.14,
                17.136,
                17.133,
                17.129,
                17.125,
                17.121,
                17.117,
                17.114,
                17.11,
                17.106,
                17.102,
                17.098,
                17.094,
                17.09,
                17.086,
                17.082,
                17.078,
                17.074,
                17.07,
                17.066,
                17.062,
                17.058,
                17.054,
                17.05,
                17.046,
                17.042,
                17.038,
                17.034,
                17.03,
                17.026,
                17.022,
                17.018,
                17.014,
                17.01,
                17.006,
                17.002,
                16.997,
                16.993,
                16.989,
                16.985,
                16.981,
                16.977,
                16.973,
                16.969,
                16.964,
                16.96,
                16.956,
                16.952,
                16.948,
                16.944,
                16.94,
                16.936,
                16.931,
                16.927,
                16.923,
                16.919,
                16.915,
                16.911,
                16.907,
                16.902,
                16.898,
                16.894,
                16.89,
                16.886,
                16.882,
                16.878,
                16.874,
                16.869,
                16.865,
                16.861,
                16.857,
                16.853,
                16.849,
                16.845,
                16.84,
                16.836,
                16.832,
                16.828,
                16.824,
                16.82,
                16.816,
                16.812,
                16.807,
                16.803,
                16.799,
                16.795,
                16.791,
                16.787,
                16.783,
                16.779,
                16.775,
                16.77,
                16.766,
                16.762,
                16.758,
                16.754,
                16.75,
                16.746,
                16.742,
                16.738,
                16.734,
                16.73,
                16.726,
                16.722,
                16.717,
                16.713,
                16.709,
                16.705,
                16.701,
                16.697,
                16.693,
                16.689,
                16.685,
                16.681,
                16.677,
                16.673,
                16.669,
                16.665,
                16.661,
                16.657,
                16.653,
                16.649,
                16.645,
                16.641,
                16.637,
                16.633,
                16.629,
                16.625,
                16.621,
                16.618,
                16.614,
                16.61,
                16.606,
                16.602,
                16.598,
                16.594,
                16.59,
                16.586,
                16.582,
                16.578,
                16.575,
                16.571,
                16.567,
                16.563,
                16.559,
                16.555,
                16.551,
                16.548,
                16.544,
                16.54,
                16.536,
                16.532,
                16.529,
                16.525,
                16.521,
                16.517,
                16.513,
                16.51,
                16.506,
                16.502,
                16.498,
                16.495,
                16.491,
                16.487,
                16.483,
                16.48,
                16.476,
                16.472,
                16.469,
                16.465,
                16.461,
                16.458,
                16.454,
                16.45,
                16.447,
                16.443,
                16.439,
                16.436,
                16.432,
                16.428,
                16.425,
                16.421,
                16.418,
                16.414,
                16.41,
                16.407,
                16.403,
                16.4,
                16.396,
                16.393,
                16.389,
                16.386,
                16.382,
                16.378,
                16.375,
                16.372,
                16.368,
                16.365,
                16.361,
                16.358,
                16.354,
                16.351,
                16.347,
                16.344,
                16.34,
                16.337,
                16.334,
                16.33,
                16.327,
                16.323,
                16.32,
                16.317,
                16.313,
                16.31,
                16.306,
                16.303,
                16.3,
                16.296,
                16.293,
                16.29,
                16.286,
                16.283,
                16.28,
                16.276,
                16.273,
                16.27,
                16.267,
                16.263,
                16.26,
                16.257,
                16.254,
                16.25,
                16.247,
                16.244,
                16.241,
                16.238,
                16.234,
                16.231,
                16.228,
                16.225,
                16.221,
                16.218,
                16.215,
                16.212,
                16.209,
                16.206,
                16.203,
                16.2,
                16.196,
                16.193,
                16.19,
                16.187,
                16.184,
                16.181,
                16.178,
                16.175,
                16.172,
                16.169,
                16.166,
                16.163,
                16.16,
                16.157,
                16.154,
                16.151,
                16.148,
                16.145,
                16.142,
                16.139,
                16.136,
                16.133,
                16.13,
                16.127,
                16.124,
                16.121,
                16.118,
                16.115,
                16.113,
                16.11,
                16.107,
                16.104,
                16.101,
                16.098,
                16.095,
                16.093,
                16.09,
                16.087,
                16.084,
                16.081,
                16.078,
                16.076,
                16.073,
                16.07,
                16.067,
                16.065,
                16.062,
                16.059,
                16.056,
                16.054,
                16.051,
                16.048,
                16.046,
                16.043,
                16.04,
                16.038,
                16.035,
                16.032,
                16.03,
                16.027,
                16.024,
                16.022,
                16.019,
                16.017,
                16.014,
                16.011,
                16.009,
                16.006,
                16.004,
                16.001,
                15.999,
                15.996,
                15.994,
                15.991,
                15.989,
                15.986,
                15.984,
                15.981,
                15.979,
                15.976,
                15.974,
                15.971,
                15.969,
                15.966,
                15.964,
                15.962,
                15.959,
                15.957,
                15.954,
                15.952,
                15.95,
                15.947,
                15.945,
                15.943,
                15.94,
                15.938,
                15.936,
                15.933,
                15.931,
                15.929,
                15.927,
                15.924,
                15.922,
                15.92,
                15.918,
                15.915,
                15.913,
                15.911,
                15.909,
                15.907,
                15.904,
                15.902,
                15.9,
                15.898,
                15.896,
                15.894,
                15.891,
                15.889,
                15.887,
                15.885,
                15.883,
                15.881,
                15.879,
                15.877,
                15.875,
                15.873,
                15.87,
                15.868,
                15.866,
                15.864,
                15.862,
                15.86,
                15.858,
                15.856,
                15.854,
                15.852,
                15.85,
                15.848,
                15.846,
                15.844,
                15.842,
                15.841,
                15.839,
                15.837,
                15.835,
                15.833,
                15.831,
                15.829,
                15.827,
                15.825,
                15.823,
                15.821,
                15.82,
                15.818,
                15.816,
                15.814,
                15.812,
                15.81,
                15.809,
                15.807,
                15.805,
                15.803,
                15.801,
                15.8,
                15.798,
                15.796,
                15.794,
                15.792,
                15.791,
                15.789,
                15.787,
                15.785,
                15.784,
                15.782,
                15.78,
                15.778,
                15.777,
                15.775,
                15.773,
                15.772,
                15.77,
                15.768,
                15.767,
                15.765,
                15.763,
                15.762,
                15.76,
                15.758,
                15.757,
                15.755,
                15.753,
                15.752,
                15.75,
                15.748,
                15.747,
                15.745,
                15.744,
                15.742,
                15.74,
                15.739,
                15.737,
                15.736,
                16.019,
                16.018,
                16.016,
                16.015,
                16.014,
                16.012,
                16.011,
                16.01,
                16.008,
                16.007,
                16.006,
                16.005,
                16.003,
                16.002,
                16.001,
                15.999,
                15.998,
                15.997,
                15.995,
                15.994,
                15.993,
                15.992,
                15.99,
                15.989,
                15.988,
                15.986,
                15.985,
                15.984,
                15.983,
                15.981,
                15.98,
                15.979,
                15.977,
                15.976,
                15.975,
                15.974,
                15.972,
                15.971,
                15.97,
                15.968,
                15.967,
                15.966,
                15.965,
                15.963,
                15.962,
                15.961,
                15.96,
                15.958,
                15.957,
                15.956,
                15.954,
                15.953,
                15.952,
                15.951,
                15.949,
                15.948,
                15.947,
                15.946,
                15.944,
                15.943,
                15.942,
                15.941,
                15.939,
                15.938,
                15.937,
                15.936,
                15.934,
                15.933,
                15.932,
                15.931,
                15.929,
                15.928,
                15.927,
                15.926,
                15.924,
                15.923,
                15.922,
                15.921,
                15.919,
                15.918,
                15.917,
                15.916,
                15.915,
                15.913,
                15.912,
                15.911,
                15.91,
                15.908,
                15.907,
                15.906,
                15.905,
                15.903,
                15.902,
                15.901,
                15.9,
                15.899,
                15.897,
                15.896,
                15.895,
                15.894,
                15.892,
                15.891,
                15.89,
                15.889,
                15.888,
                15.886,
                15.885,
                15.884,
                15.883,
                15.882,
                15.88,
                15.879,
                15.878,
                15.877,
                15.876,
                15.874,
                15.873,
                15.872,
                15.871,
                15.869,
                15.868,
                15.867,
                15.866,
                15.865,
                15.863,
                15.862,
                15.861,
                15.86,
                15.859,
                15.858,
                15.856,
                15.855,
                15.854,
                15.853,
                15.852,
                15.85,
                15.849,
                15.848,
                15.847,
                15.846,
                15.844,
                15.843,
                15.842,
                15.841,
                15.84,
                15.839,
                15.837,
                15.836,
                15.835,
                15.834,
                15.833,
                15.831,
                15.83,
                15.829,
                15.828,
                15.827,
                15.826,
                15.824,
                15.823,
                15.822,
                15.821,
                15.82,
                15.819,
                15.817,
                15.816,
                15.815,
                15.814,
                15.813,
                15.812,
                15.81,
                15.809,
                15.808,
                15.807,
                15.806,
                15.805,
                15.804,
                15.802,
                15.801,
                15.8,
                15.799,
                15.798,
                15.797,
                15.795,
                15.794,
                15.793,
                15.792,
                15.791,
                15.79,
                15.789,
                15.787,
                15.786,
                15.785,
                15.784,
                15.783,
                15.782,
                15.781,
                15.779,
                15.778,
                15.777,
                15.776,
                15.775,
                15.774,
                15.773,
                15.771,
                15.77,
                15.769,
                15.768,
                15.767,
                15.766,
                15.765,
                15.764,
                15.762,
                15.761,
                15.76,
                15.759,
                15.758,
                15.757,
                15.756,
                15.755,
                15.753,
                15.752,
                15.751,
                15.75,
                15.749,
                15.748,
                15.747,
                15.746,
                15.744,
                15.743,
                15.742,
                15.741,
                15.74,
                15.739,
                15.738,
                15.737,
                15.736,
                15.734,
                15.733,
                15.732,
                15.731,
                15.73,
                15.729,
                15.728,
                15.727,
                15.726,
                15.725,
                15.723,
                15.722,
                15.721,
                15.72,
                15.719,
                15.718,
                15.717,
                15.716,
                15.715,
                15.714,
                15.712,
                15.711,
                15.71,
                15.709,
                15.708,
                15.707,
                15.706,
                15.705,
                15.704,
                15.703,
                15.702,
                15.7,
                15.699,
                15.698,
                15.697,
                15.696,
                15.695,
                15.694,
                15.693,
                15.692,
                15.691,
                15.69,
                15.689,
                15.687,
                15.686,
                15.685,
                15.684,
                15.683,
                15.682,
                15.681,
                15.68,
                15.679,
                15.678,
                15.677,
                15.676,
                15.675,
                15.674,
                15.673,
                15.671,
                15.67,
                15.669,
                15.668,
                15.667,
                15.666,
                15.665,
                15.664,
                15.663,
                15.662,
                15.661,
                15.66,
                15.659,
                15.658,
                15.657,
                15.656,
                15.655,
                15.654,
                15.653,
                15.651,
                15.65,
                15.649,
                15.648,
                15.647,
                15.646,
                15.645,
                15.644,
                15.643,
                15.642,
                15.641,
                15.64,
                15.639,
                15.638,
                15.637,
                15.636,
                15.635,
                15.634,
                15.633,
                15.632,
                15.631,
                15.63,
                15.629,
                15.628,
                15.627,
                15.626,
                15.625,
                15.624,
                15.623,
                15.622,
                15.621,
                15.62,
                15.619,
                15.618,
                15.617,
                15.616,
                15.615,
                15.614,
                15.613,
                15.612,
                15.611,
                15.61,
                15.609,
                15.608,
                15.607,
                15.606,
                15.605,
                15.604,
                15.603,
                15.602,
                15.601,
                15.6,
                15.599,
                15.598,
                15.597,
                15.596,
                15.595,
                15.594,
                15.593,
                15.592,
                15.591,
                15.59,
                15.589,
                15.588,
                15.587,
                15.586,
                15.585,
                15.584,
                15.583,
                15.582,
                15.581,
                15.58,
                15.579,
                15.578,
                15.577,
                15.576,
                15.575,
                15.574,
                15.573,
                15.572,
                15.571,
                15.57,
                15.57,
                15.569,
                15.568,
                15.567,
                15.566,
                15.565,
                15.564,
                15.563,
                15.562,
                15.561,
                15.56,
                15.559,
                15.558,
                15.557,
                15.556,
                15.555,
                15.554,
                15.554,
                15.553,
                15.552,
                15.551,
                15.55,
                15.549,
                15.548,
                15.547,
                15.546,
                15.545,
                15.544,
                15.543,
                15.542,
                15.542,
                15.541,
                15.54,
                15.539,
                15.538,
                15.537,
                15.536,
                15.535,
                15.534,
                15.533,
                15.533,
                15.532,
                15.531,
                15.53,
                15.529,
                15.528,
                15.527,
                15.526,
                15.525,
                15.525,
                15.524,
                15.523,
                15.522,
                15.521,
                15.52,
                15.519,
                15.518,
                15.518,
                15.517,
                15.516,
                15.515,
                15.514,
                15.513,
                15.512,
                15.512,
                15.511,
                15.51,
                15.509,
                15.508,
                15.507,
                15.506,
                15.506,
                15.505,
                15.504,
                15.503,
                15.502,
                15.501,
                15.501,
                15.5,
                15.499,
                15.498,
                15.497,
                15.496,
                15.496,
                15.495,
                15.494,
                15.493,
                15.492,
                15.491,
                15.491,
                15.49,
                15.489,
                15.488,
                15.487,
                15.487,
                15.486,
                15.485,
                15.484,
                15.483,
                15.482,
                15.482,
                15.481,
                15.48,
                15.479,
                15.479,
                15.478,
                15.477,
                15.476,
                15.475,
                15.475,
                15.474,
                15.473,
                15.472,
                15.471,
                15.471,
                15.47,
                15.469,
                15.468,
                15.468,
                15.467,
                15.466,
                15.465,
                15.465,
                15.464,
                15.463,
                15.462,
                15.461,
                15.461,
                15.46,
                15.459,
                15.458,
                15.458,
                15.457,
                15.456,
                15.455,
                15.455,
                15.454,
                15.453,
                15.453,
                15.452,
                15.451,
                15.45,
                15.45,
                15.449,
                15.448,
                15.447,
                15.447,
                15.446,
                15.445,
                15.445,
                15.444,
                15.443,
                15.442,
                15.442,
                15.441,
                15.44,
                15.44,
                15.439,
                15.438,
                15.437,
                15.437,
                15.436,
                15.435,
                15.435,
                15.434,
                15.433,
                15.433,
                15.432,
                15.431,
                15.43,
                15.43,
                15.429,
                15.428,
                15.428,
                15.427,
                15.426,
                15.426,
                15.425,
                15.424,
                15.424,
                15.423,
                15.422,
                15.422,
                15.421,
                15.42,
                15.42,
                15.419,
                15.418,
                15.418,
                15.417,
                15.416,
                15.416,
                15.415,
                15.414,
                15.414,
                15.413,
                15.412,
                15.412,
                15.411,
                15.41,
                15.41,
                15.409,
                15.408,
                15.408,
                15.407,
                15.407,
                15.406,
                15.405,
                15.405,
                15.404,
                15.403,
                15.403,
                15.402,
                15.402,
                15.401,
                15.4,
                15.4,
                15.399,
                15.398,
                15.398,
                15.397,
                15.397,
                15.396,
                15.395,
                15.395,
                15.394,
                15.393,
                15.393,
                15.392,
                15.392,
                15.391,
                15.39,
                15.39,
                15.389,
                15.389,
                15.388,
                15.387,
                15.387,
                15.386,
                15.386,
                15.385,
                15.384,
                15.384,
                15.383,
                15.383,
                15.382,
                15.381,
                15.381,
                15.38,
                15.38,
                15.379,
                15.378,
                15.378,
                15.377,
                15.377,
                15.376,
                15.376,
                15.375,
                15.374,
                15.374,
                15.373,
                15.373,
                15.372,
                15.372,
                15.371,
                15.37,
                15.37,
                15.369,
                15.369,
                15.368,
                15.368,
                15.367,
                15.366,
                15.366,
                15.365,
                15.365,
                15.364,
                15.364,
                15.363,
                15.362,
                15.362,
                15.361,
                15.361,
                15.36,
                15.36,
                15.359,
                15.359,
                15.358,
                15.358,
                15.357,
                15.356,
                15.356,
                15.355,
                15.355,
                15.354,
                15.354,
                15.353,
                15.353,
                15.352,
                15.352,
                15.351,
                15.35,
                15.35,
                15.349,
                15.349,
                15.348,
                15.348,
                15.347,
                15.347,
                15.346,
                15.346,
                15.345,
                15.345,
                15.344,
                15.344,
                15.343,
                15.342,
                15.342,
                15.341,
                15.341,
                15.34,
                15.34,
                15.339,
                15.339,
                15.338,
                15.338,
                15.337,
                15.337,
                15.336,
                15.336,
                15.335,
                15.335,
                15.334,
                15.334,
                15.333,
                15.333,
                15.332,
                15.332,
                15.331,
                15.331,
                15.33,
                15.33,
                15.329,
                15.329,
                15.328,
                15.328,
                15.327,
                15.327,
                15.326,
                15.326,
                15.325,
                15.325,
                15.324,
                15.324,
                15.323,
                15.323,
                15.322,
                15.322,
                15.321,
                15.321,
                15.32,
                15.32,
                15.319,
                15.319,
                15.318,
                15.318,
                15.317,
                15.317,
                15.316,
                15.316,
                15.315,
                15.315,
                15.314,
                15.314,
                15.313,
                15.313,
                15.312,
                15.312,
                15.311,
                15.311,
                15.31,
                15.31,
                15.309,
                15.309,
                15.309,
                15.308,
                15.308,
                15.307,
                15.307,
                15.306,
                15.306,
                15.305,
                15.305,
                15.304,
                15.304,
                15.303,
                15.303,
                15.302,
                15.302,
                15.302,
                15.301,
                15.301,
                15.3,
                15.3,
                15.299,
                15.299,
                15.298,
                15.298,
                15.297,
                15.297,
                15.296,
                15.296,
                15.296,
                15.295,
                15.295,
                15.294,
                15.294,
                15.293,
                15.293,
                15.292,
                15.292,
                15.291,
                15.291,
                15.291,
                15.29,
                15.29,
                15.289,
                15.289,
                15.288,
                15.288,
                15.287,
                15.287,
                15.287,
                15.286,
                15.286,
                15.285,
                15.285,
                15.284,
                15.284,
                15.284,
                15.283,
                15.283,
                15.282,
                15.282,
                15.281,
                15.281,
                15.281,
                15.28,
                15.28,
                15.279,
                15.279,
                15.278,
                15.278,
                15.278,
                15.277,
                15.277,
                15.276,
                15.276,
                15.275,
                15.275,
                15.275,
                15.274,
                15.274,
                15.273,
                15.273,
                15.272,
                15.272,
                15.272,
                15.271,
                15.271,
                15.27,
                15.27,
                15.27,
                15.269,
                15.269,
                15.268,
                15.268,
                15.268,
                15.267,
                15.267,
                15.266,
                15.266,
                15.265,
                15.265,
                15.265,
                15.264,
                15.264,
                15.263,
                15.263,
                15.263,
                15.262,
                15.262,
                15.261,
                15.261,
                15.261,
                15.26,
                15.26,
                15.259,
                15.259,
                15.259,
                15.258,
                15.258,
                15.258,
                15.257,
                15.257,
                15.256,
                15.256,
                15.256,
                15.255,
                15.255,
                15.254,
                15.254,
                15.254,
                15.253,
                15.253,
                15.252,
                15.252,
                15.252,
                15.251,
                15.251,
                15.251,
                15.25,
                15.25,
                15.249,
                15.249,
                15.249,
                15.248,
                15.248,
                15.248,
                15.247,
                15.247,
                15.246,
                15.246,
                15.246,
                15.245,
                15.245,
                15.245,
                15.244,
                15.244,
                15.243,
                15.243,
                15.243,
                15.242,
                15.242,
                15.242,
                15.241,
                15.241,
                15.24,
                15.24,
                15.24,
                15.239,
                15.239,
                15.239,
                15.238,
                15.238,
                15.238,
                15.237,
                15.237,
                15.236,
                15.236,
                15.236,
                15.235,
                15.235,
                15.235,
                15.234,
                15.234,
                15.234,
                15.233,
                15.233,
                15.233,
                15.232,
                15.232,
                15.231,
                15.231,
                15.231,
                15.23,
                15.23,
                15.23,
                15.229,
                15.229,
                15.229,
                15.228,
                15.228,
                15.228,
                15.227,
                15.227,
                15.227,
                15.226,
                15.226,
                15.226,
                15.225,
                15.225,
                15.225,
                15.224,
                15.224,
                15.224,
                15.223,
                15.223,
                15.222,
                15.222,
                15.222,
                15.221,
                15.221,
                15.221,
                15.22,
                15.22,
                15.22,
                15.219,
                15.219,
                15.219,
                15.218,
                15.218,
                15.218,
                15.218,
                15.217,
                15.217,
                15.217,
                15.216,
                15.216,
                15.216,
                15.215,
                15.215,
                15.215,
                15.214,
                15.214,
                15.214,
                15.213,
                15.213,
                15.213,
                15.212,
                15.212,
                15.212,
                15.211,
                15.211,
                15.211,
                15.21,
                15.21,
                15.21,
                15.21,
                15.209,
                15.209,
                15.209,
                15.208,
                15.208,
                15.208,
                15.207,
                15.207,
                15.207,
                15.207,
                15.206,
                15.206,
                15.206,
                15.205,
                15.205,
                15.205,
                15.204,
                15.204,
                15.204,
                15.204,
                15.203,
                15.203,
                15.203,
                15.202,
                15.202,
                15.202,
                15.201,
                15.201,
                15.201,
                15.201,
                15.2,
                15.2,
                15.2,
                15.199,
                15.199,
                15.199,
                15.199,
                15.198,
                15.198,
                15.198,
                15.197,
                15.197,
                15.197,
                15.197,
                15.196,
                15.196,
                15.196,
                15.196,
                15.195,
                15.195,
                15.195,
                15.194,
                15.194,
                15.194,
                15.194,
                15.193,
                15.193,
                15.193,
                15.193,
                15.192,
                15.192,
                15.192,
                15.191,
                15.191,
                15.191,
                15.191,
                15.19,
                15.19,
                15.19,
                15.19,
                15.189,
                15.189,
                15.189,
                15.189,
                15.188,
                15.188,
                15.188,
                15.188,
                15.187,
                15.187,
                15.187,
                15.187,
                15.186,
                15.186,
                15.186,
                15.186,
                15.185,
                15.185,
                15.185,
                15.185,
                15.184,
                15.184
            ],
            P75: [
                14.309,
                14.299,
                14.289,
                14.279,
                14.27,
                14.26,
                14.25,
                14.24,
                14.28,
                14.321,
                14.362,
                14.402,
                14.443,
                14.483,
                14.524,
                14.605,
                14.691,
                14.778,
                14.866,
                14.954,
                15.041,
                15.128,
                15.213,
                15.297,
                15.38,
                15.461,
                15.541,
                15.619,
                15.695,
                15.77,
                15.843,
                15.914,
                15.983,
                16.049,
                16.114,
                16.177,
                16.238,
                16.297,
                16.354,
                16.409,
                16.463,
                16.515,
                16.566,
                16.615,
                16.663,
                16.71,
                16.755,
                16.799,
                16.842,
                16.883,
                16.924,
                16.963,
                17.002,
                17.039,
                17.075,
                17.111,
                17.145,
                17.178,
                17.211,
                17.243,
                17.274,
                17.304,
                17.333,
                17.361,
                17.388,
                17.415,
                17.441,
                17.466,
                17.49,
                17.514,
                17.537,
                17.559,
                17.581,
                17.602,
                17.622,
                17.642,
                17.661,
                17.68,
                17.698,
                17.715,
                17.732,
                17.749,
                17.765,
                17.781,
                17.796,
                17.811,
                17.825,
                17.839,
                17.853,
                17.866,
                17.879,
                17.891,
                17.903,
                17.915,
                17.927,
                17.938,
                17.949,
                17.96,
                17.97,
                17.98,
                17.99,
                18,
                18.009,
                18.018,
                18.027,
                18.036,
                18.045,
                18.053,
                18.061,
                18.069,
                18.077,
                18.085,
                18.092,
                18.1,
                18.107,
                18.114,
                18.121,
                18.127,
                18.134,
                18.14,
                18.147,
                18.153,
                18.159,
                18.165,
                18.171,
                18.177,
                18.182,
                18.188,
                18.193,
                18.198,
                18.204,
                18.209,
                18.214,
                18.219,
                18.223,
                18.228,
                18.232,
                18.237,
                18.241,
                18.245,
                18.249,
                18.253,
                18.257,
                18.261,
                18.265,
                18.268,
                18.272,
                18.275,
                18.278,
                18.282,
                18.285,
                18.288,
                18.291,
                18.293,
                18.296,
                18.299,
                18.301,
                18.304,
                18.306,
                18.308,
                18.31,
                18.312,
                18.314,
                18.316,
                18.318,
                18.32,
                18.321,
                18.323,
                18.325,
                18.326,
                18.327,
                18.329,
                18.33,
                18.331,
                18.332,
                18.333,
                18.334,
                18.335,
                18.335,
                18.336,
                18.337,
                18.337,
                18.338,
                18.338,
                18.338,
                18.339,
                18.339,
                18.339,
                18.339,
                18.339,
                18.339,
                18.339,
                18.339,
                18.338,
                18.338,
                18.338,
                18.337,
                18.337,
                18.336,
                18.335,
                18.334,
                18.333,
                18.333,
                18.332,
                18.331,
                18.33,
                18.328,
                18.327,
                18.326,
                18.325,
                18.323,
                18.322,
                18.321,
                18.319,
                18.317,
                18.316,
                18.314,
                18.312,
                18.31,
                18.308,
                18.307,
                18.305,
                18.303,
                18.3,
                18.298,
                18.296,
                18.294,
                18.292,
                18.289,
                18.287,
                18.284,
                18.282,
                18.279,
                18.277,
                18.274,
                18.271,
                18.269,
                18.266,
                18.263,
                18.26,
                18.257,
                18.254,
                18.251,
                18.248,
                18.245,
                18.242,
                18.239,
                18.236,
                18.233,
                18.23,
                18.226,
                18.223,
                18.22,
                18.216,
                18.213,
                18.209,
                18.206,
                18.202,
                18.199,
                18.195,
                18.192,
                18.188,
                18.184,
                18.181,
                18.177,
                18.173,
                18.17,
                18.166,
                18.162,
                18.158,
                18.154,
                18.15,
                18.147,
                18.143,
                18.139,
                18.135,
                18.131,
                18.127,
                18.123,
                18.119,
                18.115,
                18.111,
                18.107,
                18.103,
                18.098,
                18.094,
                18.09,
                18.086,
                18.082,
                18.078,
                18.074,
                18.069,
                18.065,
                18.061,
                18.056,
                18.052,
                18.048,
                18.044,
                18.039,
                18.035,
                18.031,
                18.026,
                18.022,
                18.018,
                18.013,
                18.009,
                18.005,
                18,
                17.996,
                17.992,
                17.987,
                17.983,
                17.978,
                17.974,
                17.969,
                17.965,
                17.961,
                17.956,
                17.952,
                17.947,
                17.943,
                17.938,
                17.934,
                17.93,
                17.925,
                17.921,
                17.916,
                17.912,
                17.907,
                17.903,
                17.898,
                17.894,
                17.889,
                17.885,
                17.881,
                17.876,
                17.872,
                17.867,
                17.863,
                17.858,
                17.854,
                17.849,
                17.845,
                17.84,
                17.836,
                17.831,
                17.827,
                17.822,
                17.818,
                17.813,
                17.809,
                17.805,
                17.8,
                17.796,
                17.791,
                17.787,
                17.782,
                17.778,
                17.773,
                17.769,
                17.765,
                17.76,
                17.756,
                17.751,
                17.747,
                17.742,
                17.738,
                17.733,
                17.729,
                17.725,
                17.72,
                17.716,
                17.711,
                17.707,
                17.703,
                17.698,
                17.694,
                17.689,
                17.685,
                17.681,
                17.676,
                17.672,
                17.668,
                17.663,
                17.659,
                17.654,
                17.65,
                17.646,
                17.641,
                17.637,
                17.633,
                17.628,
                17.624,
                17.62,
                17.615,
                17.611,
                17.607,
                17.602,
                17.598,
                17.594,
                17.59,
                17.585,
                17.581,
                17.577,
                17.572,
                17.568,
                17.564,
                17.56,
                17.555,
                17.551,
                17.547,
                17.543,
                17.538,
                17.534,
                17.53,
                17.526,
                17.522,
                17.517,
                17.513,
                17.509,
                17.505,
                17.501,
                17.497,
                17.492,
                17.488,
                17.484,
                17.48,
                17.476,
                17.472,
                17.468,
                17.464,
                17.459,
                17.455,
                17.451,
                17.447,
                17.443,
                17.439,
                17.435,
                17.431,
                17.427,
                17.423,
                17.419,
                17.415,
                17.411,
                17.407,
                17.403,
                17.399,
                17.395,
                17.391,
                17.387,
                17.383,
                17.379,
                17.375,
                17.371,
                17.367,
                17.363,
                17.359,
                17.355,
                17.352,
                17.348,
                17.344,
                17.34,
                17.336,
                17.332,
                17.328,
                17.324,
                17.321,
                17.317,
                17.313,
                17.309,
                17.305,
                17.301,
                17.298,
                17.294,
                17.29,
                17.286,
                17.283,
                17.279,
                17.275,
                17.271,
                17.268,
                17.264,
                17.26,
                17.256,
                17.253,
                17.249,
                17.245,
                17.242,
                17.238,
                17.234,
                17.231,
                17.227,
                17.223,
                17.22,
                17.216,
                17.212,
                17.209,
                17.205,
                17.202,
                17.198,
                17.195,
                17.191,
                17.188,
                17.184,
                17.18,
                17.177,
                17.173,
                17.17,
                17.166,
                17.163,
                17.159,
                17.156,
                17.152,
                17.149,
                17.145,
                17.142,
                17.138,
                17.135,
                17.132,
                17.128,
                17.125,
                17.121,
                17.118,
                17.115,
                17.111,
                17.108,
                17.104,
                17.101,
                17.098,
                17.094,
                17.091,
                17.088,
                17.084,
                17.081,
                17.078,
                17.074,
                17.071,
                17.068,
                17.065,
                17.061,
                17.058,
                17.055,
                17.052,
                17.048,
                17.045,
                17.042,
                17.039,
                17.036,
                17.032,
                17.029,
                17.026,
                17.023,
                17.02,
                17.016,
                17.013,
                17.01,
                17.007,
                17.004,
                17.001,
                16.998,
                16.995,
                16.992,
                16.989,
                16.986,
                16.983,
                16.979,
                16.976,
                16.973,
                16.97,
                16.967,
                16.964,
                16.961,
                16.958,
                16.955,
                16.952,
                16.95,
                16.947,
                16.944,
                16.941,
                16.938,
                16.935,
                16.932,
                16.929,
                16.926,
                16.923,
                16.921,
                16.918,
                16.915,
                16.912,
                16.909,
                16.906,
                16.904,
                16.901,
                16.898,
                16.895,
                16.892,
                16.89,
                16.887,
                16.884,
                16.881,
                16.879,
                16.876,
                16.873,
                16.871,
                16.868,
                16.865,
                16.863,
                16.86,
                16.857,
                16.855,
                16.852,
                16.849,
                16.847,
                16.844,
                16.842,
                16.839,
                16.837,
                16.834,
                16.831,
                16.829,
                16.826,
                16.824,
                16.821,
                16.819,
                16.816,
                16.814,
                16.811,
                16.809,
                16.806,
                16.804,
                16.801,
                16.799,
                16.797,
                16.794,
                16.792,
                16.789,
                16.787,
                16.785,
                16.782,
                16.78,
                16.777,
                16.775,
                16.773,
                16.77,
                16.768,
                16.766,
                16.764,
                16.761,
                16.759,
                16.757,
                16.754,
                16.752,
                16.75,
                16.748,
                16.746,
                16.743,
                16.741,
                16.739,
                16.737,
                16.734,
                16.732,
                16.73,
                16.728,
                16.726,
                16.724,
                16.722,
                16.719,
                16.717,
                16.715,
                16.713,
                16.711,
                16.709,
                16.707,
                16.705,
                16.703,
                16.701,
                16.698,
                16.696,
                16.694,
                16.692,
                16.69,
                16.688,
                16.686,
                16.684,
                16.682,
                16.68,
                16.678,
                16.676,
                16.674,
                16.672,
                16.671,
                16.669,
                16.667,
                16.665,
                16.663,
                16.661,
                16.659,
                16.657,
                16.655,
                16.653,
                16.651,
                16.649,
                16.648,
                16.646,
                16.644,
                16.642,
                16.64,
                16.638,
                16.636,
                16.635,
                16.633,
                16.631,
                16.629,
                16.627,
                16.626,
                16.624,
                16.622,
                16.62,
                16.618,
                16.617,
                16.615,
                16.613,
                16.611,
                16.61,
                16.608,
                16.606,
                16.604,
                16.603,
                16.601,
                16.599,
                16.597,
                16.897,
                16.896,
                16.894,
                16.893,
                16.892,
                16.89,
                16.889,
                16.887,
                16.886,
                16.885,
                16.883,
                16.882,
                16.881,
                16.879,
                16.878,
                16.876,
                16.875,
                16.874,
                16.872,
                16.871,
                16.87,
                16.868,
                16.867,
                16.866,
                16.864,
                16.863,
                16.861,
                16.86,
                16.859,
                16.857,
                16.856,
                16.855,
                16.853,
                16.852,
                16.851,
                16.849,
                16.848,
                16.847,
                16.845,
                16.844,
                16.843,
                16.841,
                16.84,
                16.839,
                16.837,
                16.836,
                16.835,
                16.833,
                16.832,
                16.83,
                16.829,
                16.828,
                16.827,
                16.825,
                16.824,
                16.823,
                16.821,
                16.82,
                16.819,
                16.817,
                16.816,
                16.815,
                16.813,
                16.812,
                16.811,
                16.809,
                16.808,
                16.807,
                16.805,
                16.804,
                16.803,
                16.802,
                16.8,
                16.799,
                16.798,
                16.796,
                16.795,
                16.794,
                16.792,
                16.791,
                16.79,
                16.789,
                16.787,
                16.786,
                16.785,
                16.783,
                16.782,
                16.781,
                16.78,
                16.778,
                16.777,
                16.776,
                16.774,
                16.773,
                16.772,
                16.771,
                16.769,
                16.768,
                16.767,
                16.765,
                16.764,
                16.763,
                16.762,
                16.76,
                16.759,
                16.758,
                16.757,
                16.755,
                16.754,
                16.753,
                16.752,
                16.75,
                16.749,
                16.748,
                16.747,
                16.745,
                16.744,
                16.743,
                16.741,
                16.74,
                16.739,
                16.738,
                16.736,
                16.735,
                16.734,
                16.733,
                16.732,
                16.73,
                16.729,
                16.728,
                16.726,
                16.725,
                16.724,
                16.723,
                16.722,
                16.72,
                16.719,
                16.718,
                16.717,
                16.715,
                16.714,
                16.713,
                16.712,
                16.711,
                16.709,
                16.708,
                16.707,
                16.706,
                16.704,
                16.703,
                16.702,
                16.701,
                16.699,
                16.698,
                16.697,
                16.696,
                16.695,
                16.693,
                16.692,
                16.691,
                16.69,
                16.689,
                16.687,
                16.686,
                16.685,
                16.684,
                16.683,
                16.681,
                16.68,
                16.679,
                16.678,
                16.677,
                16.675,
                16.674,
                16.673,
                16.672,
                16.671,
                16.669,
                16.668,
                16.667,
                16.666,
                16.665,
                16.663,
                16.662,
                16.661,
                16.66,
                16.659,
                16.657,
                16.656,
                16.655,
                16.654,
                16.653,
                16.651,
                16.65,
                16.649,
                16.648,
                16.647,
                16.646,
                16.645,
                16.643,
                16.642,
                16.641,
                16.64,
                16.639,
                16.637,
                16.636,
                16.635,
                16.634,
                16.633,
                16.632,
                16.631,
                16.629,
                16.628,
                16.627,
                16.626,
                16.625,
                16.623,
                16.622,
                16.621,
                16.62,
                16.619,
                16.618,
                16.617,
                16.615,
                16.614,
                16.613,
                16.612,
                16.611,
                16.61,
                16.609,
                16.607,
                16.606,
                16.605,
                16.604,
                16.603,
                16.602,
                16.601,
                16.6,
                16.598,
                16.597,
                16.596,
                16.595,
                16.594,
                16.593,
                16.592,
                16.59,
                16.589,
                16.588,
                16.587,
                16.586,
                16.585,
                16.584,
                16.583,
                16.581,
                16.58,
                16.579,
                16.578,
                16.577,
                16.576,
                16.575,
                16.574,
                16.573,
                16.571,
                16.57,
                16.569,
                16.568,
                16.567,
                16.566,
                16.565,
                16.564,
                16.562,
                16.562,
                16.56,
                16.559,
                16.558,
                16.557,
                16.556,
                16.555,
                16.554,
                16.553,
                16.552,
                16.551,
                16.549,
                16.548,
                16.547,
                16.546,
                16.545,
                16.544,
                16.543,
                16.542,
                16.541,
                16.54,
                16.539,
                16.538,
                16.536,
                16.535,
                16.534,
                16.533,
                16.532,
                16.531,
                16.53,
                16.529,
                16.528,
                16.527,
                16.526,
                16.525,
                16.524,
                16.523,
                16.521,
                16.52,
                16.519,
                16.518,
                16.517,
                16.516,
                16.515,
                16.514,
                16.513,
                16.512,
                16.511,
                16.51,
                16.509,
                16.508,
                16.507,
                16.506,
                16.505,
                16.504,
                16.503,
                16.502,
                16.501,
                16.5,
                16.498,
                16.497,
                16.496,
                16.495,
                16.494,
                16.493,
                16.492,
                16.491,
                16.49,
                16.489,
                16.488,
                16.487,
                16.486,
                16.485,
                16.484,
                16.483,
                16.482,
                16.481,
                16.48,
                16.479,
                16.478,
                16.477,
                16.476,
                16.475,
                16.474,
                16.473,
                16.472,
                16.471,
                16.47,
                16.469,
                16.468,
                16.467,
                16.466,
                16.465,
                16.464,
                16.463,
                16.462,
                16.461,
                16.46,
                16.459,
                16.458,
                16.457,
                16.456,
                16.455,
                16.454,
                16.453,
                16.452,
                16.451,
                16.45,
                16.449,
                16.449,
                16.448,
                16.446,
                16.446,
                16.445,
                16.444,
                16.443,
                16.442,
                16.441,
                16.44,
                16.439,
                16.438,
                16.437,
                16.436,
                16.435,
                16.434,
                16.433,
                16.432,
                16.431,
                16.431,
                16.43,
                16.429,
                16.428,
                16.427,
                16.426,
                16.425,
                16.424,
                16.423,
                16.422,
                16.421,
                16.42,
                16.419,
                16.418,
                16.418,
                16.417,
                16.416,
                16.415,
                16.414,
                16.413,
                16.412,
                16.411,
                16.41,
                16.409,
                16.409,
                16.408,
                16.407,
                16.406,
                16.405,
                16.404,
                16.403,
                16.402,
                16.401,
                16.401,
                16.4,
                16.399,
                16.398,
                16.397,
                16.396,
                16.395,
                16.394,
                16.394,
                16.393,
                16.392,
                16.391,
                16.39,
                16.389,
                16.388,
                16.388,
                16.387,
                16.386,
                16.385,
                16.384,
                16.383,
                16.383,
                16.382,
                16.381,
                16.38,
                16.379,
                16.378,
                16.378,
                16.377,
                16.376,
                16.375,
                16.374,
                16.373,
                16.373,
                16.372,
                16.371,
                16.37,
                16.369,
                16.368,
                16.368,
                16.367,
                16.366,
                16.365,
                16.365,
                16.364,
                16.363,
                16.362,
                16.361,
                16.361,
                16.36,
                16.359,
                16.358,
                16.357,
                16.357,
                16.356,
                16.355,
                16.354,
                16.354,
                16.353,
                16.352,
                16.351,
                16.35,
                16.35,
                16.349,
                16.348,
                16.347,
                16.347,
                16.346,
                16.345,
                16.344,
                16.344,
                16.343,
                16.342,
                16.342,
                16.341,
                16.34,
                16.339,
                16.339,
                16.338,
                16.337,
                16.336,
                16.336,
                16.335,
                16.334,
                16.333,
                16.333,
                16.332,
                16.331,
                16.331,
                16.33,
                16.329,
                16.329,
                16.328,
                16.327,
                16.326,
                16.326,
                16.325,
                16.324,
                16.324,
                16.323,
                16.322,
                16.322,
                16.321,
                16.32,
                16.32,
                16.319,
                16.318,
                16.318,
                16.317,
                16.316,
                16.315,
                16.315,
                16.314,
                16.314,
                16.313,
                16.312,
                16.312,
                16.311,
                16.31,
                16.31,
                16.309,
                16.308,
                16.308,
                16.307,
                16.306,
                16.306,
                16.305,
                16.304,
                16.304,
                16.303,
                16.303,
                16.302,
                16.301,
                16.301,
                16.3,
                16.299,
                16.299,
                16.298,
                16.298,
                16.297,
                16.296,
                16.296,
                16.295,
                16.295,
                16.294,
                16.293,
                16.293,
                16.292,
                16.292,
                16.291,
                16.29,
                16.29,
                16.289,
                16.288,
                16.288,
                16.287,
                16.287,
                16.286,
                16.286,
                16.285,
                16.285,
                16.284,
                16.283,
                16.283,
                16.282,
                16.282,
                16.281,
                16.281,
                16.28,
                16.279,
                16.279,
                16.278,
                16.278,
                16.277,
                16.277,
                16.276,
                16.275,
                16.275,
                16.274,
                16.274,
                16.273,
                16.273,
                16.272,
                16.272,
                16.271,
                16.27,
                16.27,
                16.269,
                16.269,
                16.268,
                16.268,
                16.267,
                16.267,
                16.266,
                16.266,
                16.265,
                16.265,
                16.264,
                16.264,
                16.263,
                16.263,
                16.262,
                16.262,
                16.261,
                16.261,
                16.26,
                16.259,
                16.259,
                16.259,
                16.258,
                16.258,
                16.257,
                16.257,
                16.256,
                16.256,
                16.255,
                16.255,
                16.254,
                16.254,
                16.253,
                16.253,
                16.252,
                16.252,
                16.251,
                16.251,
                16.25,
                16.25,
                16.249,
                16.249,
                16.248,
                16.248,
                16.247,
                16.247,
                16.246,
                16.246,
                16.245,
                16.245,
                16.244,
                16.244,
                16.243,
                16.243,
                16.243,
                16.242,
                16.242,
                16.241,
                16.241,
                16.24,
                16.24,
                16.239,
                16.239,
                16.238,
                16.238,
                16.237,
                16.237,
                16.237,
                16.236,
                16.236,
                16.235,
                16.235,
                16.234,
                16.234,
                16.233,
                16.233,
                16.233,
                16.232,
                16.232,
                16.231,
                16.231,
                16.23,
                16.23,
                16.23,
                16.229,
                16.229,
                16.228,
                16.228,
                16.227,
                16.227,
                16.227,
                16.226,
                16.226,
                16.225,
                16.225,
                16.224,
                16.224,
                16.224,
                16.223,
                16.223,
                16.222,
                16.222,
                16.221,
                16.221,
                16.221,
                16.22,
                16.22,
                16.219,
                16.219,
                16.219,
                16.218,
                16.218,
                16.217,
                16.217,
                16.217,
                16.216,
                16.216,
                16.215,
                16.215,
                16.215,
                16.214,
                16.214,
                16.213,
                16.213,
                16.213,
                16.212,
                16.212,
                16.212,
                16.211,
                16.211,
                16.21,
                16.21,
                16.21,
                16.209,
                16.209,
                16.209,
                16.208,
                16.208,
                16.207,
                16.207,
                16.207,
                16.206,
                16.206,
                16.205,
                16.205,
                16.205,
                16.204,
                16.204,
                16.204,
                16.203,
                16.203,
                16.203,
                16.202,
                16.202,
                16.201,
                16.201,
                16.201,
                16.2,
                16.2,
                16.2,
                16.199,
                16.199,
                16.199,
                16.198,
                16.198,
                16.198,
                16.197,
                16.197,
                16.197,
                16.196,
                16.196,
                16.195,
                16.195,
                16.195,
                16.194,
                16.194,
                16.194,
                16.193,
                16.193,
                16.193,
                16.193,
                16.192,
                16.192,
                16.191,
                16.191,
                16.191,
                16.19,
                16.19,
                16.19,
                16.189,
                16.189,
                16.189,
                16.188,
                16.188,
                16.188,
                16.188,
                16.187,
                16.187,
                16.187,
                16.186,
                16.186,
                16.186,
                16.185,
                16.185,
                16.185,
                16.184,
                16.184,
                16.184,
                16.183,
                16.183,
                16.183,
                16.182,
                16.182,
                16.182,
                16.181,
                16.181,
                16.181,
                16.181,
                16.18,
                16.18,
                16.18,
                16.179,
                16.179,
                16.179,
                16.179,
                16.178,
                16.178,
                16.178,
                16.177,
                16.177,
                16.177,
                16.176,
                16.176,
                16.176,
                16.176,
                16.175,
                16.175,
                16.175,
                16.175,
                16.174,
                16.174,
                16.174,
                16.173,
                16.173,
                16.173,
                16.173,
                16.172,
                16.172,
                16.172,
                16.171,
                16.171,
                16.171,
                16.171,
                16.17,
                16.17,
                16.17,
                16.169,
                16.169,
                16.169,
                16.169,
                16.168,
                16.168,
                16.168,
                16.168,
                16.167,
                16.167,
                16.167,
                16.167,
                16.166,
                16.166,
                16.166,
                16.166,
                16.165,
                16.165,
                16.165,
                16.165,
                16.164,
                16.164,
                16.164,
                16.164,
                16.163,
                16.163,
                16.163,
                16.163,
                16.162,
                16.162,
                16.162,
                16.162,
                16.162,
                16.161,
                16.161,
                16.161,
                16.16,
                16.16,
                16.16,
                16.16,
                16.16,
                16.159,
                16.159,
                16.159,
                16.159,
                16.158,
                16.158,
                16.158,
                16.158,
                16.157,
                16.157,
                16.157,
                16.157,
                16.157,
                16.156,
                16.156,
                16.156,
                16.156,
                16.155,
                16.155,
                16.155,
                16.155,
                16.154,
                16.154,
                16.154,
                16.154,
                16.154,
                16.153,
                16.153,
                16.153,
                16.153,
                16.153,
                16.152,
                16.152,
                16.152,
                16.152,
                16.151,
                16.151,
                16.151,
                16.151,
                16.151,
                16.15,
                16.15,
                16.15,
                16.15,
                16.15,
                16.149,
                16.149,
                16.149,
                16.149,
                16.149,
                16.148,
                16.148,
                16.148,
                16.148,
                16.148,
                16.147,
                16.147,
                16.147,
                16.147,
                16.147,
                16.147,
                16.146,
                16.146,
                16.146,
                16.146,
                16.146,
                16.145,
                16.145,
                16.145,
                16.145,
                16.145,
                16.144,
                16.144,
                16.144,
                16.144,
                16.144,
                16.144,
                16.143,
                16.143,
                16.143,
                16.143,
                16.143,
                16.143,
                16.142,
                16.142,
                16.142,
                16.142,
                16.142,
                16.142,
                16.141,
                16.141,
                16.141,
                16.141,
                16.141,
                16.14,
                16.14,
                16.14,
                16.14,
                16.14,
                16.14,
                16.14,
                16.139,
                16.139,
                16.139,
                16.139,
                16.139,
                16.139,
                16.138,
                16.138,
                16.138,
                16.138,
                16.138,
                16.138,
                16.137,
                16.137,
                16.137,
                16.137,
                16.137,
                16.137,
                16.137,
                16.136,
                16.136,
                16.136,
                16.136,
                16.136,
                16.136,
                16.136,
                16.135,
                16.135,
                16.135,
                16.135,
                16.135,
                16.135,
                16.135,
                16.135,
                16.134,
                16.134,
                16.134,
                16.134,
                16.134,
                16.134,
                16.134,
                16.133,
                16.133,
                16.133,
                16.133,
                16.133,
                16.133,
                16.133,
                16.133,
                16.133,
                16.132,
                16.132,
                16.132,
                16.132,
                16.132,
                16.132,
                16.132,
                16.132,
                16.131,
                16.131,
                16.131,
                16.131,
                16.131,
                16.131,
                16.131,
                16.131,
                16.131,
                16.131,
                16.13,
                16.13,
                16.13,
                16.13,
                16.13,
                16.13,
                16.13,
                16.13,
                16.13,
                16.13,
                16.129,
                16.129,
                16.129,
                16.129,
                16.129,
                16.129,
                16.129,
                16.129,
                16.129,
                16.129,
                16.129,
                16.128,
                16.128,
                16.128,
                16.128,
                16.128,
                16.128,
                16.128,
                16.128,
                16.128,
                16.128,
                16.128,
                16.127,
                16.128,
                16.127,
                16.127,
                16.127,
                16.127,
                16.127,
                16.127,
                16.127
            ],
            P85: [
                14.826,
                14.813,
                14.799,
                14.786,
                14.773,
                14.759,
                14.746,
                14.733,
                14.773,
                14.813,
                14.853,
                14.893,
                14.933,
                14.973,
                15.012,
                15.095,
                15.182,
                15.271,
                15.361,
                15.45,
                15.539,
                15.628,
                15.715,
                15.801,
                15.885,
                15.968,
                16.049,
                16.129,
                16.207,
                16.284,
                16.359,
                16.431,
                16.501,
                16.57,
                16.636,
                16.7,
                16.762,
                16.822,
                16.881,
                16.938,
                16.993,
                17.046,
                17.098,
                17.148,
                17.197,
                17.244,
                17.291,
                17.335,
                17.379,
                17.422,
                17.463,
                17.503,
                17.543,
                17.581,
                17.618,
                17.654,
                17.689,
                17.723,
                17.757,
                17.789,
                17.82,
                17.851,
                17.881,
                17.91,
                17.938,
                17.965,
                17.991,
                18.017,
                18.042,
                18.066,
                18.089,
                18.112,
                18.134,
                18.155,
                18.176,
                18.196,
                18.215,
                18.234,
                18.253,
                18.271,
                18.288,
                18.305,
                18.321,
                18.337,
                18.353,
                18.368,
                18.382,
                18.397,
                18.41,
                18.424,
                18.437,
                18.45,
                18.462,
                18.474,
                18.486,
                18.497,
                18.508,
                18.519,
                18.53,
                18.54,
                18.55,
                18.56,
                18.57,
                18.579,
                18.588,
                18.597,
                18.605,
                18.614,
                18.622,
                18.63,
                18.638,
                18.646,
                18.654,
                18.661,
                18.668,
                18.675,
                18.682,
                18.689,
                18.696,
                18.702,
                18.709,
                18.715,
                18.721,
                18.727,
                18.733,
                18.739,
                18.744,
                18.75,
                18.756,
                18.761,
                18.766,
                18.771,
                18.776,
                18.781,
                18.786,
                18.791,
                18.795,
                18.8,
                18.804,
                18.808,
                18.812,
                18.816,
                18.82,
                18.824,
                18.828,
                18.831,
                18.835,
                18.838,
                18.842,
                18.845,
                18.848,
                18.851,
                18.854,
                18.857,
                18.859,
                18.862,
                18.864,
                18.867,
                18.869,
                18.871,
                18.873,
                18.876,
                18.878,
                18.879,
                18.881,
                18.883,
                18.884,
                18.886,
                18.887,
                18.889,
                18.89,
                18.892,
                18.893,
                18.894,
                18.895,
                18.896,
                18.897,
                18.897,
                18.898,
                18.899,
                18.899,
                18.9,
                18.9,
                18.901,
                18.901,
                18.901,
                18.901,
                18.901,
                18.901,
                18.901,
                18.901,
                18.901,
                18.9,
                18.9,
                18.9,
                18.899,
                18.899,
                18.898,
                18.897,
                18.896,
                18.896,
                18.895,
                18.894,
                18.893,
                18.892,
                18.891,
                18.889,
                18.888,
                18.887,
                18.885,
                18.884,
                18.882,
                18.881,
                18.879,
                18.877,
                18.876,
                18.874,
                18.872,
                18.87,
                18.868,
                18.866,
                18.864,
                18.862,
                18.859,
                18.857,
                18.855,
                18.853,
                18.85,
                18.848,
                18.845,
                18.843,
                18.84,
                18.837,
                18.835,
                18.832,
                18.829,
                18.826,
                18.823,
                18.82,
                18.817,
                18.815,
                18.811,
                18.808,
                18.805,
                18.802,
                18.799,
                18.795,
                18.792,
                18.789,
                18.785,
                18.782,
                18.779,
                18.775,
                18.772,
                18.768,
                18.764,
                18.761,
                18.757,
                18.753,
                18.75,
                18.746,
                18.742,
                18.738,
                18.735,
                18.731,
                18.727,
                18.723,
                18.719,
                18.715,
                18.711,
                18.707,
                18.703,
                18.699,
                18.695,
                18.691,
                18.687,
                18.683,
                18.678,
                18.674,
                18.67,
                18.666,
                18.662,
                18.658,
                18.653,
                18.649,
                18.645,
                18.64,
                18.636,
                18.632,
                18.628,
                18.623,
                18.619,
                18.614,
                18.61,
                18.605,
                18.601,
                18.597,
                18.592,
                18.588,
                18.583,
                18.579,
                18.574,
                18.57,
                18.565,
                18.561,
                18.556,
                18.552,
                18.547,
                18.542,
                18.538,
                18.533,
                18.529,
                18.524,
                18.52,
                18.515,
                18.511,
                18.506,
                18.501,
                18.497,
                18.492,
                18.487,
                18.483,
                18.478,
                18.474,
                18.469,
                18.464,
                18.46,
                18.455,
                18.451,
                18.446,
                18.441,
                18.437,
                18.432,
                18.427,
                18.423,
                18.418,
                18.414,
                18.409,
                18.404,
                18.4,
                18.395,
                18.39,
                18.386,
                18.381,
                18.376,
                18.372,
                18.367,
                18.363,
                18.358,
                18.353,
                18.349,
                18.344,
                18.34,
                18.335,
                18.33,
                18.326,
                18.321,
                18.316,
                18.312,
                18.307,
                18.303,
                18.298,
                18.293,
                18.289,
                18.284,
                18.28,
                18.275,
                18.27,
                18.266,
                18.261,
                18.257,
                18.252,
                18.248,
                18.243,
                18.238,
                18.234,
                18.229,
                18.225,
                18.22,
                18.215,
                18.211,
                18.207,
                18.202,
                18.197,
                18.193,
                18.188,
                18.184,
                18.179,
                18.175,
                18.17,
                18.166,
                18.161,
                18.157,
                18.152,
                18.148,
                18.143,
                18.139,
                18.134,
                18.13,
                18.126,
                18.121,
                18.117,
                18.112,
                18.108,
                18.103,
                18.099,
                18.095,
                18.09,
                18.086,
                18.081,
                18.077,
                18.073,
                18.068,
                18.064,
                18.059,
                18.055,
                18.051,
                18.046,
                18.042,
                18.038,
                18.034,
                18.029,
                18.025,
                18.02,
                18.016,
                18.012,
                18.008,
                18.003,
                17.999,
                17.995,
                17.991,
                17.986,
                17.982,
                17.978,
                17.974,
                17.969,
                17.965,
                17.961,
                17.957,
                17.953,
                17.948,
                17.944,
                17.94,
                17.936,
                17.932,
                17.928,
                17.924,
                17.919,
                17.915,
                17.911,
                17.907,
                17.903,
                17.899,
                17.895,
                17.891,
                17.887,
                17.883,
                17.878,
                17.875,
                17.87,
                17.866,
                17.862,
                17.858,
                17.854,
                17.85,
                17.846,
                17.842,
                17.838,
                17.834,
                17.831,
                17.827,
                17.823,
                17.819,
                17.815,
                17.811,
                17.807,
                17.803,
                17.799,
                17.795,
                17.792,
                17.788,
                17.784,
                17.78,
                17.776,
                17.772,
                17.768,
                17.765,
                17.761,
                17.757,
                17.753,
                17.749,
                17.746,
                17.742,
                17.738,
                17.734,
                17.73,
                17.727,
                17.723,
                17.719,
                17.716,
                17.712,
                17.708,
                17.705,
                17.701,
                17.697,
                17.694,
                17.69,
                17.686,
                17.683,
                17.679,
                17.675,
                17.672,
                17.668,
                17.664,
                17.661,
                17.657,
                17.654,
                17.65,
                17.647,
                17.643,
                17.64,
                17.636,
                17.632,
                17.629,
                17.626,
                17.622,
                17.619,
                17.615,
                17.612,
                17.608,
                17.605,
                17.601,
                17.598,
                17.594,
                17.591,
                17.587,
                17.584,
                17.581,
                17.577,
                17.574,
                17.571,
                17.567,
                17.564,
                17.56,
                17.557,
                17.554,
                17.55,
                17.547,
                17.544,
                17.541,
                17.537,
                17.534,
                17.531,
                17.527,
                17.524,
                17.521,
                17.518,
                17.515,
                17.511,
                17.508,
                17.505,
                17.502,
                17.498,
                17.495,
                17.492,
                17.489,
                17.486,
                17.483,
                17.48,
                17.476,
                17.473,
                17.47,
                17.467,
                17.464,
                17.461,
                17.458,
                17.455,
                17.452,
                17.449,
                17.446,
                17.443,
                17.44,
                17.437,
                17.434,
                17.431,
                17.428,
                17.425,
                17.422,
                17.419,
                17.416,
                17.413,
                17.41,
                17.407,
                17.405,
                17.402,
                17.399,
                17.396,
                17.393,
                17.39,
                17.387,
                17.385,
                17.382,
                17.379,
                17.376,
                17.373,
                17.371,
                17.368,
                17.365,
                17.362,
                17.36,
                17.357,
                17.354,
                17.351,
                17.349,
                17.346,
                17.343,
                17.341,
                17.338,
                17.335,
                17.333,
                17.33,
                17.328,
                17.325,
                17.322,
                17.32,
                17.317,
                17.315,
                17.312,
                17.309,
                17.307,
                17.304,
                17.302,
                17.299,
                17.297,
                17.294,
                17.292,
                17.289,
                17.287,
                17.284,
                17.282,
                17.28,
                17.277,
                17.275,
                17.272,
                17.27,
                17.267,
                17.265,
                17.263,
                17.26,
                17.258,
                17.256,
                17.253,
                17.251,
                17.249,
                17.246,
                17.244,
                17.242,
                17.239,
                17.237,
                17.235,
                17.233,
                17.23,
                17.228,
                17.226,
                17.224,
                17.221,
                17.219,
                17.217,
                17.215,
                17.213,
                17.211,
                17.208,
                17.206,
                17.204,
                17.202,
                17.2,
                17.197,
                17.195,
                17.193,
                17.191,
                17.189,
                17.187,
                17.185,
                17.183,
                17.181,
                17.179,
                17.177,
                17.174,
                17.172,
                17.17,
                17.168,
                17.166,
                17.164,
                17.162,
                17.16,
                17.158,
                17.156,
                17.154,
                17.152,
                17.15,
                17.148,
                17.146,
                17.145,
                17.143,
                17.141,
                17.139,
                17.137,
                17.135,
                17.133,
                17.131,
                17.129,
                17.127,
                17.126,
                17.124,
                17.122,
                17.12,
                17.118,
                17.116,
                17.114,
                17.112,
                17.111,
                17.109,
                17.107,
                17.105,
                17.103,
                17.101,
                17.1,
                17.098,
                17.096,
                17.094,
                17.093,
                17.401,
                17.4,
                17.398,
                17.397,
                17.395,
                17.394,
                17.392,
                17.391,
                17.39,
                17.388,
                17.387,
                17.385,
                17.384,
                17.382,
                17.381,
                17.379,
                17.378,
                17.377,
                17.375,
                17.374,
                17.372,
                17.371,
                17.369,
                17.368,
                17.366,
                17.365,
                17.364,
                17.362,
                17.361,
                17.359,
                17.358,
                17.357,
                17.355,
                17.354,
                17.352,
                17.351,
                17.349,
                17.348,
                17.347,
                17.345,
                17.344,
                17.342,
                17.341,
                17.34,
                17.338,
                17.337,
                17.336,
                17.334,
                17.333,
                17.331,
                17.33,
                17.329,
                17.327,
                17.326,
                17.324,
                17.323,
                17.321,
                17.32,
                17.319,
                17.317,
                17.316,
                17.315,
                17.313,
                17.312,
                17.311,
                17.309,
                17.308,
                17.306,
                17.305,
                17.304,
                17.302,
                17.301,
                17.3,
                17.298,
                17.297,
                17.296,
                17.294,
                17.293,
                17.292,
                17.29,
                17.289,
                17.287,
                17.286,
                17.285,
                17.283,
                17.282,
                17.281,
                17.279,
                17.278,
                17.277,
                17.275,
                17.274,
                17.273,
                17.271,
                17.27,
                17.269,
                17.267,
                17.266,
                17.265,
                17.263,
                17.262,
                17.261,
                17.259,
                17.258,
                17.257,
                17.255,
                17.254,
                17.253,
                17.251,
                17.25,
                17.249,
                17.248,
                17.246,
                17.245,
                17.244,
                17.242,
                17.241,
                17.24,
                17.238,
                17.237,
                17.236,
                17.234,
                17.233,
                17.232,
                17.23,
                17.229,
                17.228,
                17.227,
                17.226,
                17.224,
                17.223,
                17.222,
                17.22,
                17.219,
                17.218,
                17.216,
                17.215,
                17.214,
                17.213,
                17.211,
                17.21,
                17.209,
                17.207,
                17.206,
                17.205,
                17.204,
                17.202,
                17.201,
                17.2,
                17.199,
                17.197,
                17.196,
                17.195,
                17.194,
                17.192,
                17.191,
                17.19,
                17.188,
                17.187,
                17.186,
                17.185,
                17.183,
                17.182,
                17.181,
                17.18,
                17.178,
                17.177,
                17.176,
                17.175,
                17.173,
                17.172,
                17.171,
                17.17,
                17.168,
                17.167,
                17.166,
                17.165,
                17.164,
                17.162,
                17.161,
                17.16,
                17.159,
                17.157,
                17.156,
                17.155,
                17.154,
                17.153,
                17.151,
                17.15,
                17.149,
                17.148,
                17.146,
                17.145,
                17.144,
                17.143,
                17.141,
                17.14,
                17.139,
                17.138,
                17.137,
                17.135,
                17.134,
                17.133,
                17.132,
                17.131,
                17.129,
                17.128,
                17.127,
                17.126,
                17.125,
                17.124,
                17.122,
                17.121,
                17.12,
                17.119,
                17.118,
                17.116,
                17.115,
                17.114,
                17.113,
                17.112,
                17.11,
                17.109,
                17.108,
                17.107,
                17.106,
                17.105,
                17.103,
                17.102,
                17.101,
                17.1,
                17.099,
                17.097,
                17.096,
                17.095,
                17.094,
                17.093,
                17.092,
                17.09,
                17.089,
                17.088,
                17.087,
                17.086,
                17.085,
                17.084,
                17.082,
                17.081,
                17.08,
                17.079,
                17.078,
                17.077,
                17.075,
                17.074,
                17.073,
                17.072,
                17.071,
                17.07,
                17.069,
                17.067,
                17.066,
                17.065,
                17.064,
                17.063,
                17.062,
                17.061,
                17.06,
                17.058,
                17.057,
                17.056,
                17.055,
                17.054,
                17.053,
                17.052,
                17.051,
                17.049,
                17.048,
                17.047,
                17.046,
                17.045,
                17.044,
                17.043,
                17.042,
                17.04,
                17.039,
                17.038,
                17.037,
                17.036,
                17.035,
                17.034,
                17.033,
                17.032,
                17.031,
                17.029,
                17.028,
                17.027,
                17.026,
                17.025,
                17.024,
                17.023,
                17.022,
                17.021,
                17.02,
                17.018,
                17.017,
                17.016,
                17.015,
                17.014,
                17.013,
                17.012,
                17.011,
                17.01,
                17.009,
                17.008,
                17.007,
                17.006,
                17.004,
                17.003,
                17.002,
                17.001,
                17,
                16.999,
                16.998,
                16.997,
                16.996,
                16.995,
                16.994,
                16.993,
                16.992,
                16.991,
                16.99,
                16.989,
                16.988,
                16.987,
                16.986,
                16.984,
                16.983,
                16.982,
                16.981,
                16.98,
                16.979,
                16.978,
                16.977,
                16.976,
                16.975,
                16.974,
                16.973,
                16.972,
                16.971,
                16.97,
                16.969,
                16.968,
                16.967,
                16.966,
                16.965,
                16.964,
                16.963,
                16.962,
                16.961,
                16.96,
                16.959,
                16.958,
                16.957,
                16.956,
                16.955,
                16.954,
                16.953,
                16.952,
                16.951,
                16.95,
                16.949,
                16.948,
                16.947,
                16.946,
                16.945,
                16.944,
                16.943,
                16.942,
                16.941,
                16.94,
                16.939,
                16.938,
                16.937,
                16.936,
                16.936,
                16.935,
                16.933,
                16.933,
                16.932,
                16.931,
                16.93,
                16.929,
                16.928,
                16.927,
                16.926,
                16.925,
                16.924,
                16.923,
                16.922,
                16.921,
                16.92,
                16.919,
                16.918,
                16.918,
                16.917,
                16.916,
                16.915,
                16.914,
                16.913,
                16.912,
                16.911,
                16.91,
                16.909,
                16.908,
                16.907,
                16.907,
                16.906,
                16.905,
                16.904,
                16.903,
                16.902,
                16.901,
                16.9,
                16.899,
                16.898,
                16.898,
                16.897,
                16.896,
                16.895,
                16.894,
                16.893,
                16.892,
                16.892,
                16.891,
                16.89,
                16.889,
                16.888,
                16.887,
                16.886,
                16.886,
                16.885,
                16.884,
                16.883,
                16.882,
                16.881,
                16.88,
                16.879,
                16.879,
                16.878,
                16.877,
                16.876,
                16.875,
                16.875,
                16.874,
                16.873,
                16.872,
                16.871,
                16.87,
                16.87,
                16.869,
                16.868,
                16.867,
                16.866,
                16.866,
                16.865,
                16.864,
                16.863,
                16.862,
                16.862,
                16.861,
                16.86,
                16.859,
                16.858,
                16.858,
                16.857,
                16.856,
                16.855,
                16.855,
                16.854,
                16.853,
                16.852,
                16.852,
                16.851,
                16.85,
                16.849,
                16.848,
                16.848,
                16.847,
                16.846,
                16.846,
                16.845,
                16.844,
                16.843,
                16.842,
                16.842,
                16.841,
                16.84,
                16.84,
                16.839,
                16.838,
                16.837,
                16.837,
                16.836,
                16.835,
                16.834,
                16.834,
                16.833,
                16.832,
                16.832,
                16.831,
                16.83,
                16.83,
                16.829,
                16.828,
                16.827,
                16.827,
                16.826,
                16.825,
                16.825,
                16.824,
                16.823,
                16.823,
                16.822,
                16.821,
                16.821,
                16.82,
                16.819,
                16.819,
                16.818,
                16.817,
                16.817,
                16.816,
                16.815,
                16.815,
                16.814,
                16.813,
                16.813,
                16.812,
                16.811,
                16.811,
                16.81,
                16.81,
                16.809,
                16.808,
                16.808,
                16.807,
                16.806,
                16.806,
                16.805,
                16.805,
                16.804,
                16.803,
                16.803,
                16.802,
                16.802,
                16.801,
                16.8,
                16.8,
                16.799,
                16.799,
                16.798,
                16.797,
                16.797,
                16.796,
                16.796,
                16.795,
                16.794,
                16.794,
                16.793,
                16.793,
                16.792,
                16.792,
                16.791,
                16.79,
                16.79,
                16.789,
                16.789,
                16.788,
                16.788,
                16.787,
                16.786,
                16.786,
                16.785,
                16.785,
                16.784,
                16.784,
                16.783,
                16.783,
                16.782,
                16.782,
                16.781,
                16.781,
                16.78,
                16.78,
                16.779,
                16.779,
                16.778,
                16.777,
                16.777,
                16.776,
                16.776,
                16.775,
                16.775,
                16.774,
                16.774,
                16.773,
                16.773,
                16.772,
                16.772,
                16.771,
                16.771,
                16.77,
                16.77,
                16.769,
                16.769,
                16.768,
                16.768,
                16.767,
                16.767,
                16.766,
                16.766,
                16.765,
                16.765,
                16.765,
                16.764,
                16.763,
                16.763,
                16.762,
                16.762,
                16.762,
                16.761,
                16.761,
                16.76,
                16.76,
                16.759,
                16.759,
                16.758,
                16.758,
                16.757,
                16.757,
                16.757,
                16.756,
                16.756,
                16.755,
                16.755,
                16.754,
                16.754,
                16.754,
                16.753,
                16.753,
                16.752,
                16.752,
                16.751,
                16.751,
                16.751,
                16.75,
                16.75,
                16.749,
                16.749,
                16.748,
                16.748,
                16.748,
                16.747,
                16.747,
                16.746,
                16.746,
                16.746,
                16.745,
                16.745,
                16.744,
                16.744,
                16.744,
                16.743,
                16.743,
                16.742,
                16.742,
                16.741,
                16.741,
                16.741,
                16.74,
                16.74,
                16.739,
                16.739,
                16.739,
                16.738,
                16.738,
                16.738,
                16.737,
                16.737,
                16.736,
                16.736,
                16.736,
                16.735,
                16.735,
                16.734,
                16.734,
                16.734,
                16.734,
                16.733,
                16.733,
                16.732,
                16.732,
                16.732,
                16.731,
                16.731,
                16.731,
                16.73,
                16.73,
                16.729,
                16.729,
                16.729,
                16.728,
                16.728,
                16.728,
                16.728,
                16.727,
                16.727,
                16.726,
                16.726,
                16.726,
                16.725,
                16.725,
                16.725,
                16.724,
                16.724,
                16.724,
                16.724,
                16.723,
                16.723,
                16.722,
                16.722,
                16.722,
                16.721,
                16.721,
                16.721,
                16.721,
                16.72,
                16.72,
                16.72,
                16.719,
                16.719,
                16.719,
                16.718,
                16.718,
                16.718,
                16.717,
                16.717,
                16.717,
                16.716,
                16.716,
                16.716,
                16.715,
                16.715,
                16.715,
                16.715,
                16.714,
                16.714,
                16.714,
                16.713,
                16.713,
                16.713,
                16.713,
                16.712,
                16.712,
                16.712,
                16.711,
                16.711,
                16.711,
                16.711,
                16.71,
                16.71,
                16.71,
                16.71,
                16.709,
                16.709,
                16.709,
                16.708,
                16.708,
                16.708,
                16.708,
                16.707,
                16.707,
                16.707,
                16.707,
                16.706,
                16.706,
                16.706,
                16.706,
                16.705,
                16.705,
                16.705,
                16.704,
                16.704,
                16.704,
                16.704,
                16.704,
                16.703,
                16.703,
                16.703,
                16.703,
                16.702,
                16.702,
                16.702,
                16.702,
                16.701,
                16.701,
                16.701,
                16.701,
                16.7,
                16.7,
                16.7,
                16.7,
                16.699,
                16.699,
                16.699,
                16.699,
                16.699,
                16.698,
                16.698,
                16.698,
                16.698,
                16.697,
                16.697,
                16.697,
                16.697,
                16.697,
                16.696,
                16.696,
                16.696,
                16.696,
                16.696,
                16.695,
                16.695,
                16.695,
                16.695,
                16.695,
                16.694,
                16.694,
                16.694,
                16.694,
                16.693,
                16.693,
                16.693,
                16.693,
                16.693,
                16.693,
                16.692,
                16.692,
                16.692,
                16.692,
                16.691,
                16.691,
                16.691,
                16.691,
                16.691,
                16.691,
                16.69,
                16.69,
                16.69,
                16.69,
                16.69,
                16.69,
                16.689,
                16.689,
                16.689,
                16.689,
                16.689,
                16.688,
                16.688,
                16.688,
                16.688,
                16.688,
                16.688,
                16.687,
                16.687,
                16.687,
                16.687,
                16.687,
                16.687,
                16.686,
                16.686,
                16.686,
                16.686,
                16.686,
                16.686,
                16.685,
                16.685,
                16.685,
                16.685,
                16.685,
                16.685,
                16.685,
                16.684,
                16.684,
                16.684,
                16.684,
                16.684,
                16.684,
                16.684,
                16.684,
                16.683,
                16.683,
                16.683,
                16.683,
                16.683,
                16.683,
                16.683,
                16.682,
                16.682,
                16.682,
                16.682,
                16.682,
                16.682,
                16.682,
                16.682,
                16.682,
                16.681,
                16.681,
                16.681,
                16.681,
                16.681,
                16.681,
                16.681,
                16.68,
                16.68,
                16.68,
                16.68,
                16.68,
                16.68,
                16.68,
                16.68,
                16.68,
                16.679,
                16.679,
                16.679,
                16.679,
                16.679,
                16.679,
                16.679,
                16.679,
                16.679,
                16.678,
                16.678,
                16.678,
                16.678,
                16.678,
                16.678,
                16.678,
                16.678,
                16.678,
                16.678,
                16.678,
                16.677,
                16.677,
                16.677,
                16.677,
                16.677,
                16.677,
                16.677,
                16.677,
                16.677,
                16.677,
                16.677,
                16.677,
                16.676,
                16.676,
                16.676,
                16.676,
                16.676,
                16.676,
                16.676,
                16.676,
                16.676,
                16.676,
                16.676,
                16.676,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.674,
                16.675,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.673,
                16.674,
                16.673,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.674,
                16.675,
                16.674,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675,
                16.675
            ],
            P90: [
                15.19,
                15.173,
                15.156,
                15.139,
                15.122,
                15.105,
                15.088,
                15.071,
                15.111,
                15.151,
                15.191,
                15.23,
                15.27,
                15.309,
                15.349,
                15.432,
                15.52,
                15.611,
                15.701,
                15.792,
                15.883,
                15.973,
                16.061,
                16.148,
                16.234,
                16.318,
                16.401,
                16.482,
                16.561,
                16.639,
                16.715,
                16.788,
                16.86,
                16.929,
                16.996,
                17.061,
                17.124,
                17.186,
                17.245,
                17.303,
                17.359,
                17.413,
                17.465,
                17.516,
                17.566,
                17.614,
                17.661,
                17.707,
                17.751,
                17.795,
                17.837,
                17.878,
                17.917,
                17.956,
                17.994,
                18.03,
                18.066,
                18.101,
                18.134,
                18.167,
                18.199,
                18.23,
                18.261,
                18.29,
                18.318,
                18.346,
                18.373,
                18.399,
                18.424,
                18.448,
                18.472,
                18.495,
                18.517,
                18.539,
                18.56,
                18.58,
                18.6,
                18.619,
                18.638,
                18.656,
                18.674,
                18.691,
                18.708,
                18.724,
                18.739,
                18.755,
                18.77,
                18.784,
                18.798,
                18.812,
                18.825,
                18.838,
                18.85,
                18.862,
                18.874,
                18.886,
                18.897,
                18.908,
                18.919,
                18.929,
                18.939,
                18.949,
                18.959,
                18.968,
                18.978,
                18.987,
                18.995,
                19.004,
                19.012,
                19.021,
                19.028,
                19.036,
                19.044,
                19.052,
                19.059,
                19.066,
                19.073,
                19.08,
                19.087,
                19.093,
                19.1,
                19.106,
                19.112,
                19.119,
                19.124,
                19.13,
                19.136,
                19.142,
                19.147,
                19.153,
                19.158,
                19.163,
                19.168,
                19.173,
                19.178,
                19.183,
                19.187,
                19.192,
                19.196,
                19.2,
                19.204,
                19.209,
                19.212,
                19.217,
                19.22,
                19.224,
                19.227,
                19.231,
                19.234,
                19.237,
                19.24,
                19.243,
                19.246,
                19.249,
                19.252,
                19.254,
                19.257,
                19.259,
                19.262,
                19.264,
                19.266,
                19.268,
                19.27,
                19.272,
                19.274,
                19.276,
                19.277,
                19.279,
                19.28,
                19.282,
                19.283,
                19.284,
                19.285,
                19.286,
                19.287,
                19.288,
                19.289,
                19.29,
                19.291,
                19.291,
                19.292,
                19.292,
                19.293,
                19.293,
                19.293,
                19.294,
                19.294,
                19.294,
                19.294,
                19.294,
                19.294,
                19.293,
                19.293,
                19.293,
                19.292,
                19.291,
                19.291,
                19.29,
                19.289,
                19.288,
                19.288,
                19.287,
                19.286,
                19.285,
                19.284,
                19.282,
                19.281,
                19.28,
                19.278,
                19.277,
                19.275,
                19.274,
                19.272,
                19.271,
                19.269,
                19.267,
                19.265,
                19.263,
                19.261,
                19.259,
                19.257,
                19.255,
                19.253,
                19.25,
                19.248,
                19.246,
                19.243,
                19.241,
                19.238,
                19.236,
                19.233,
                19.23,
                19.227,
                19.225,
                19.222,
                19.219,
                19.216,
                19.213,
                19.21,
                19.207,
                19.204,
                19.201,
                19.198,
                19.195,
                19.191,
                19.188,
                19.185,
                19.181,
                19.178,
                19.174,
                19.171,
                19.167,
                19.164,
                19.16,
                19.156,
                19.153,
                19.149,
                19.145,
                19.141,
                19.138,
                19.134,
                19.13,
                19.126,
                19.122,
                19.118,
                19.114,
                19.11,
                19.106,
                19.102,
                19.098,
                19.094,
                19.09,
                19.086,
                19.082,
                19.077,
                19.073,
                19.069,
                19.065,
                19.06,
                19.056,
                19.052,
                19.048,
                19.043,
                19.039,
                19.034,
                19.03,
                19.026,
                19.021,
                19.017,
                19.013,
                19.008,
                19.003,
                18.999,
                18.994,
                18.99,
                18.985,
                18.981,
                18.976,
                18.972,
                18.967,
                18.963,
                18.958,
                18.953,
                18.949,
                18.944,
                18.939,
                18.935,
                18.93,
                18.925,
                18.921,
                18.916,
                18.911,
                18.907,
                18.902,
                18.898,
                18.893,
                18.888,
                18.883,
                18.879,
                18.874,
                18.869,
                18.865,
                18.86,
                18.855,
                18.85,
                18.846,
                18.841,
                18.836,
                18.831,
                18.827,
                18.822,
                18.817,
                18.812,
                18.808,
                18.803,
                18.798,
                18.794,
                18.789,
                18.784,
                18.779,
                18.775,
                18.77,
                18.765,
                18.76,
                18.756,
                18.751,
                18.746,
                18.741,
                18.737,
                18.732,
                18.727,
                18.723,
                18.718,
                18.713,
                18.708,
                18.704,
                18.699,
                18.694,
                18.689,
                18.685,
                18.68,
                18.675,
                18.67,
                18.666,
                18.661,
                18.657,
                18.652,
                18.647,
                18.642,
                18.638,
                18.633,
                18.628,
                18.624,
                18.619,
                18.614,
                18.61,
                18.605,
                18.6,
                18.596,
                18.591,
                18.586,
                18.582,
                18.577,
                18.572,
                18.568,
                18.563,
                18.559,
                18.554,
                18.549,
                18.545,
                18.54,
                18.536,
                18.531,
                18.526,
                18.522,
                18.517,
                18.513,
                18.508,
                18.503,
                18.499,
                18.494,
                18.49,
                18.485,
                18.481,
                18.476,
                18.472,
                18.467,
                18.463,
                18.458,
                18.454,
                18.449,
                18.445,
                18.44,
                18.436,
                18.431,
                18.427,
                18.423,
                18.418,
                18.414,
                18.409,
                18.405,
                18.4,
                18.396,
                18.391,
                18.387,
                18.383,
                18.378,
                18.374,
                18.37,
                18.365,
                18.361,
                18.357,
                18.352,
                18.348,
                18.344,
                18.339,
                18.335,
                18.331,
                18.326,
                18.322,
                18.318,
                18.314,
                18.309,
                18.305,
                18.301,
                18.296,
                18.292,
                18.288,
                18.284,
                18.28,
                18.276,
                18.271,
                18.267,
                18.263,
                18.259,
                18.255,
                18.25,
                18.246,
                18.242,
                18.238,
                18.234,
                18.23,
                18.226,
                18.222,
                18.217,
                18.213,
                18.209,
                18.205,
                18.201,
                18.197,
                18.193,
                18.189,
                18.185,
                18.181,
                18.177,
                18.173,
                18.169,
                18.165,
                18.161,
                18.157,
                18.153,
                18.149,
                18.145,
                18.141,
                18.137,
                18.134,
                18.13,
                18.126,
                18.122,
                18.118,
                18.114,
                18.11,
                18.106,
                18.103,
                18.099,
                18.095,
                18.091,
                18.087,
                18.083,
                18.08,
                18.076,
                18.072,
                18.068,
                18.065,
                18.061,
                18.057,
                18.053,
                18.049,
                18.046,
                18.042,
                18.038,
                18.035,
                18.031,
                18.027,
                18.024,
                18.02,
                18.016,
                18.013,
                18.009,
                18.005,
                18.002,
                17.998,
                17.995,
                17.991,
                17.987,
                17.984,
                17.98,
                17.977,
                17.973,
                17.97,
                17.966,
                17.962,
                17.959,
                17.955,
                17.952,
                17.948,
                17.945,
                17.942,
                17.938,
                17.935,
                17.931,
                17.928,
                17.924,
                17.921,
                17.917,
                17.914,
                17.911,
                17.907,
                17.904,
                17.901,
                17.897,
                17.894,
                17.89,
                17.887,
                17.884,
                17.88,
                17.877,
                17.874,
                17.87,
                17.867,
                17.864,
                17.861,
                17.857,
                17.854,
                17.851,
                17.848,
                17.844,
                17.841,
                17.838,
                17.835,
                17.832,
                17.828,
                17.826,
                17.822,
                17.819,
                17.816,
                17.813,
                17.81,
                17.807,
                17.803,
                17.801,
                17.797,
                17.795,
                17.791,
                17.788,
                17.785,
                17.782,
                17.779,
                17.776,
                17.773,
                17.77,
                17.767,
                17.764,
                17.761,
                17.758,
                17.755,
                17.752,
                17.75,
                17.747,
                17.744,
                17.741,
                17.738,
                17.735,
                17.732,
                17.729,
                17.727,
                17.724,
                17.721,
                17.718,
                17.715,
                17.713,
                17.71,
                17.707,
                17.704,
                17.702,
                17.699,
                17.696,
                17.693,
                17.691,
                17.688,
                17.685,
                17.683,
                17.68,
                17.677,
                17.674,
                17.672,
                17.669,
                17.666,
                17.664,
                17.661,
                17.659,
                17.656,
                17.654,
                17.651,
                17.648,
                17.646,
                17.643,
                17.641,
                17.638,
                17.636,
                17.633,
                17.631,
                17.628,
                17.626,
                17.624,
                17.621,
                17.619,
                17.616,
                17.614,
                17.611,
                17.609,
                17.607,
                17.604,
                17.602,
                17.599,
                17.597,
                17.595,
                17.592,
                17.59,
                17.588,
                17.585,
                17.583,
                17.581,
                17.578,
                17.576,
                17.574,
                17.572,
                17.569,
                17.567,
                17.565,
                17.563,
                17.56,
                17.558,
                17.556,
                17.554,
                17.552,
                17.549,
                17.547,
                17.545,
                17.543,
                17.541,
                17.539,
                17.536,
                17.534,
                17.532,
                17.53,
                17.528,
                17.526,
                17.524,
                17.522,
                17.52,
                17.517,
                17.515,
                17.513,
                17.511,
                17.509,
                17.507,
                17.505,
                17.503,
                17.501,
                17.499,
                17.497,
                17.495,
                17.493,
                17.491,
                17.489,
                17.487,
                17.485,
                17.483,
                17.481,
                17.479,
                17.477,
                17.476,
                17.474,
                17.472,
                17.47,
                17.468,
                17.466,
                17.464,
                17.462,
                17.46,
                17.458,
                17.457,
                17.455,
                17.453,
                17.451,
                17.449,
                17.447,
                17.446,
                17.444,
                17.442,
                17.756,
                17.755,
                17.753,
                17.752,
                17.75,
                17.749,
                17.747,
                17.746,
                17.744,
                17.743,
                17.741,
                17.74,
                17.738,
                17.737,
                17.735,
                17.734,
                17.732,
                17.731,
                17.729,
                17.728,
                17.726,
                17.725,
                17.723,
                17.722,
                17.72,
                17.719,
                17.717,
                17.716,
                17.714,
                17.713,
                17.712,
                17.71,
                17.709,
                17.707,
                17.706,
                17.704,
                17.703,
                17.701,
                17.7,
                17.698,
                17.697,
                17.695,
                17.694,
                17.693,
                17.691,
                17.689,
                17.688,
                17.687,
                17.685,
                17.684,
                17.682,
                17.681,
                17.679,
                17.678,
                17.677,
                17.675,
                17.674,
                17.672,
                17.671,
                17.669,
                17.668,
                17.667,
                17.665,
                17.664,
                17.662,
                17.661,
                17.659,
                17.658,
                17.657,
                17.655,
                17.654,
                17.652,
                17.651,
                17.649,
                17.648,
                17.647,
                17.645,
                17.644,
                17.643,
                17.641,
                17.64,
                17.638,
                17.637,
                17.635,
                17.634,
                17.633,
                17.631,
                17.63,
                17.629,
                17.627,
                17.626,
                17.624,
                17.623,
                17.621,
                17.62,
                17.619,
                17.617,
                17.616,
                17.615,
                17.613,
                17.612,
                17.611,
                17.609,
                17.608,
                17.606,
                17.605,
                17.604,
                17.602,
                17.601,
                17.6,
                17.598,
                17.597,
                17.596,
                17.594,
                17.593,
                17.591,
                17.59,
                17.589,
                17.587,
                17.586,
                17.585,
                17.583,
                17.582,
                17.581,
                17.579,
                17.578,
                17.577,
                17.575,
                17.574,
                17.573,
                17.571,
                17.57,
                17.569,
                17.567,
                17.566,
                17.565,
                17.564,
                17.562,
                17.561,
                17.56,
                17.558,
                17.557,
                17.556,
                17.554,
                17.553,
                17.552,
                17.55,
                17.549,
                17.548,
                17.546,
                17.545,
                17.544,
                17.542,
                17.541,
                17.54,
                17.539,
                17.537,
                17.536,
                17.535,
                17.533,
                17.532,
                17.531,
                17.529,
                17.528,
                17.527,
                17.526,
                17.524,
                17.523,
                17.522,
                17.521,
                17.519,
                17.518,
                17.517,
                17.515,
                17.514,
                17.513,
                17.512,
                17.51,
                17.509,
                17.508,
                17.506,
                17.505,
                17.504,
                17.503,
                17.501,
                17.5,
                17.499,
                17.498,
                17.496,
                17.495,
                17.494,
                17.493,
                17.491,
                17.49,
                17.489,
                17.488,
                17.487,
                17.485,
                17.484,
                17.483,
                17.481,
                17.48,
                17.479,
                17.478,
                17.476,
                17.475,
                17.474,
                17.473,
                17.472,
                17.47,
                17.469,
                17.468,
                17.466,
                17.465,
                17.464,
                17.463,
                17.462,
                17.461,
                17.459,
                17.458,
                17.457,
                17.456,
                17.455,
                17.453,
                17.452,
                17.451,
                17.45,
                17.448,
                17.447,
                17.446,
                17.445,
                17.444,
                17.442,
                17.441,
                17.44,
                17.439,
                17.438,
                17.437,
                17.435,
                17.434,
                17.433,
                17.432,
                17.431,
                17.429,
                17.428,
                17.427,
                17.426,
                17.424,
                17.423,
                17.422,
                17.421,
                17.42,
                17.419,
                17.418,
                17.416,
                17.415,
                17.414,
                17.413,
                17.412,
                17.411,
                17.409,
                17.408,
                17.407,
                17.406,
                17.405,
                17.404,
                17.402,
                17.401,
                17.4,
                17.399,
                17.398,
                17.397,
                17.396,
                17.394,
                17.393,
                17.392,
                17.391,
                17.39,
                17.389,
                17.388,
                17.386,
                17.385,
                17.384,
                17.383,
                17.382,
                17.381,
                17.38,
                17.379,
                17.377,
                17.376,
                17.375,
                17.374,
                17.373,
                17.372,
                17.371,
                17.37,
                17.368,
                17.367,
                17.366,
                17.365,
                17.364,
                17.363,
                17.362,
                17.361,
                17.36,
                17.359,
                17.357,
                17.356,
                17.355,
                17.354,
                17.353,
                17.352,
                17.351,
                17.35,
                17.349,
                17.348,
                17.347,
                17.345,
                17.344,
                17.343,
                17.342,
                17.341,
                17.34,
                17.339,
                17.338,
                17.337,
                17.336,
                17.335,
                17.334,
                17.333,
                17.332,
                17.331,
                17.329,
                17.329,
                17.327,
                17.326,
                17.325,
                17.324,
                17.323,
                17.322,
                17.321,
                17.32,
                17.319,
                17.318,
                17.317,
                17.316,
                17.315,
                17.314,
                17.313,
                17.312,
                17.311,
                17.31,
                17.309,
                17.308,
                17.307,
                17.306,
                17.305,
                17.304,
                17.303,
                17.302,
                17.301,
                17.3,
                17.299,
                17.298,
                17.297,
                17.296,
                17.295,
                17.294,
                17.293,
                17.292,
                17.291,
                17.29,
                17.289,
                17.288,
                17.287,
                17.286,
                17.285,
                17.284,
                17.283,
                17.282,
                17.281,
                17.28,
                17.279,
                17.278,
                17.277,
                17.276,
                17.275,
                17.274,
                17.273,
                17.272,
                17.271,
                17.27,
                17.27,
                17.269,
                17.268,
                17.267,
                17.266,
                17.265,
                17.264,
                17.263,
                17.262,
                17.261,
                17.26,
                17.259,
                17.258,
                17.257,
                17.256,
                17.255,
                17.255,
                17.254,
                17.253,
                17.252,
                17.251,
                17.25,
                17.249,
                17.248,
                17.247,
                17.247,
                17.246,
                17.245,
                17.244,
                17.243,
                17.242,
                17.241,
                17.24,
                17.239,
                17.238,
                17.238,
                17.237,
                17.236,
                17.235,
                17.234,
                17.233,
                17.232,
                17.232,
                17.231,
                17.23,
                17.229,
                17.228,
                17.227,
                17.227,
                17.226,
                17.225,
                17.224,
                17.223,
                17.222,
                17.222,
                17.221,
                17.22,
                17.219,
                17.218,
                17.218,
                17.217,
                17.216,
                17.215,
                17.214,
                17.213,
                17.213,
                17.212,
                17.211,
                17.21,
                17.209,
                17.208,
                17.208,
                17.207,
                17.206,
                17.205,
                17.205,
                17.204,
                17.203,
                17.202,
                17.202,
                17.201,
                17.2,
                17.199,
                17.199,
                17.198,
                17.197,
                17.196,
                17.195,
                17.195,
                17.194,
                17.193,
                17.193,
                17.192,
                17.191,
                17.19,
                17.19,
                17.189,
                17.188,
                17.187,
                17.187,
                17.186,
                17.185,
                17.184,
                17.184,
                17.183,
                17.182,
                17.182,
                17.181,
                17.18,
                17.179,
                17.179,
                17.178,
                17.178,
                17.177,
                17.176,
                17.175,
                17.175,
                17.174,
                17.173,
                17.173,
                17.172,
                17.171,
                17.171,
                17.17,
                17.169,
                17.169,
                17.168,
                17.167,
                17.167,
                17.166,
                17.165,
                17.165,
                17.164,
                17.163,
                17.163,
                17.162,
                17.162,
                17.161,
                17.16,
                17.16,
                17.159,
                17.159,
                17.158,
                17.157,
                17.157,
                17.156,
                17.155,
                17.155,
                17.154,
                17.154,
                17.153,
                17.152,
                17.152,
                17.151,
                17.151,
                17.15,
                17.149,
                17.149,
                17.148,
                17.148,
                17.147,
                17.147,
                17.146,
                17.145,
                17.145,
                17.144,
                17.144,
                17.143,
                17.143,
                17.142,
                17.141,
                17.141,
                17.14,
                17.14,
                17.139,
                17.139,
                17.138,
                17.138,
                17.137,
                17.137,
                17.136,
                17.136,
                17.135,
                17.135,
                17.134,
                17.133,
                17.133,
                17.132,
                17.132,
                17.131,
                17.131,
                17.13,
                17.13,
                17.129,
                17.129,
                17.128,
                17.128,
                17.127,
                17.127,
                17.126,
                17.126,
                17.125,
                17.125,
                17.124,
                17.124,
                17.123,
                17.123,
                17.122,
                17.122,
                17.122,
                17.121,
                17.121,
                17.12,
                17.12,
                17.119,
                17.119,
                17.118,
                17.118,
                17.117,
                17.117,
                17.116,
                17.116,
                17.116,
                17.115,
                17.115,
                17.114,
                17.114,
                17.113,
                17.113,
                17.113,
                17.112,
                17.112,
                17.111,
                17.111,
                17.11,
                17.11,
                17.109,
                17.109,
                17.109,
                17.108,
                17.108,
                17.107,
                17.107,
                17.107,
                17.106,
                17.106,
                17.105,
                17.105,
                17.105,
                17.104,
                17.104,
                17.103,
                17.103,
                17.103,
                17.102,
                17.102,
                17.102,
                17.101,
                17.101,
                17.1,
                17.1,
                17.1,
                17.099,
                17.099,
                17.098,
                17.098,
                17.098,
                17.097,
                17.097,
                17.097,
                17.096,
                17.096,
                17.096,
                17.095,
                17.095,
                17.095,
                17.094,
                17.094,
                17.093,
                17.093,
                17.093,
                17.092,
                17.092,
                17.092,
                17.091,
                17.091,
                17.091,
                17.09,
                17.09,
                17.09,
                17.089,
                17.089,
                17.089,
                17.088,
                17.088,
                17.088,
                17.087,
                17.087,
                17.087,
                17.086,
                17.086,
                17.086,
                17.086,
                17.085,
                17.085,
                17.085,
                17.084,
                17.084,
                17.084,
                17.083,
                17.083,
                17.083,
                17.082,
                17.082,
                17.082,
                17.081,
                17.081,
                17.081,
                17.081,
                17.08,
                17.08,
                17.08,
                17.08,
                17.079,
                17.079,
                17.079,
                17.078,
                17.078,
                17.078,
                17.078,
                17.077,
                17.077,
                17.077,
                17.077,
                17.076,
                17.076,
                17.076,
                17.075,
                17.075,
                17.075,
                17.075,
                17.074,
                17.074,
                17.074,
                17.074,
                17.073,
                17.073,
                17.073,
                17.073,
                17.072,
                17.072,
                17.072,
                17.072,
                17.072,
                17.071,
                17.071,
                17.071,
                17.07,
                17.07,
                17.07,
                17.07,
                17.07,
                17.069,
                17.069,
                17.069,
                17.069,
                17.068,
                17.068,
                17.068,
                17.068,
                17.068,
                17.067,
                17.067,
                17.067,
                17.067,
                17.067,
                17.066,
                17.066,
                17.066,
                17.066,
                17.065,
                17.065,
                17.065,
                17.065,
                17.065,
                17.064,
                17.064,
                17.064,
                17.064,
                17.064,
                17.064,
                17.063,
                17.063,
                17.063,
                17.063,
                17.063,
                17.063,
                17.062,
                17.062,
                17.062,
                17.062,
                17.062,
                17.061,
                17.061,
                17.061,
                17.061,
                17.061,
                17.061,
                17.06,
                17.06,
                17.06,
                17.06,
                17.06,
                17.06,
                17.059,
                17.059,
                17.059,
                17.059,
                17.059,
                17.059,
                17.058,
                17.058,
                17.058,
                17.058,
                17.058,
                17.058,
                17.057,
                17.058,
                17.057,
                17.057,
                17.057,
                17.057,
                17.057,
                17.057,
                17.056,
                17.056,
                17.056,
                17.056,
                17.056,
                17.056,
                17.056,
                17.055,
                17.055,
                17.055,
                17.055,
                17.055,
                17.055,
                17.055,
                17.055,
                17.055,
                17.054,
                17.054,
                17.054,
                17.054,
                17.054,
                17.054,
                17.054,
                17.054,
                17.053,
                17.053,
                17.053,
                17.053,
                17.053,
                17.053,
                17.053,
                17.053,
                17.053,
                17.053,
                17.053,
                17.053,
                17.052,
                17.052,
                17.052,
                17.052,
                17.052,
                17.052,
                17.052,
                17.052,
                17.052,
                17.052,
                17.051,
                17.052,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.049,
                17.05,
                17.05,
                17.049,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.05,
                17.051,
                17.05,
                17.05,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.051,
                17.052,
                17.052,
                17.052,
                17.052,
                17.052,
                17.052,
                17.052,
                17.052,
                17.052,
                17.052,
                17.052,
                17.053,
                17.053,
                17.053,
                17.053,
                17.053,
                17.053,
                17.053,
                17.053,
                17.053,
                17.054,
                17.054,
                17.053,
                17.054,
                17.054,
                17.054,
                17.054,
                17.054,
                17.054,
                17.054,
                17.054,
                17.055,
                17.055,
                17.055,
                17.055,
                17.055,
                17.055,
                17.055,
                17.055,
                17.055,
                17.056,
                17.056,
                17.056,
                17.056,
                17.056,
                17.056,
                17.056,
                17.056,
                17.057,
                17.057,
                17.057,
                17.057,
                17.057,
                17.057,
                17.058,
                17.058,
                17.058,
                17.058,
                17.058,
                17.058,
                17.058,
                17.058,
                17.059,
                17.059,
                17.059,
                17.059,
                17.059,
                17.059,
                17.06,
                17.06,
                17.06,
                17.06,
                17.06,
                17.06,
                17.06,
                17.061,
                17.061,
                17.061,
                17.061,
                17.061,
                17.061,
                17.061,
                17.062,
                17.062,
                17.062,
                17.062,
                17.062,
                17.062,
                17.063,
                17.063,
                17.063,
                17.063,
                17.063,
                17.064,
                17.064,
                17.064,
                17.064,
                17.064,
                17.064,
                17.065,
                17.065,
                17.065,
                17.065,
                17.065
            ],
            P95: [
                15.751,
                15.726,
                15.701,
                15.676,
                15.652,
                15.627,
                15.604,
                15.58,
                15.62,
                15.659,
                15.699,
                15.738,
                15.777,
                15.816,
                15.855,
                15.941,
                16.03,
                16.123,
                16.215,
                16.308,
                16.401,
                16.493,
                16.583,
                16.672,
                16.76,
                16.846,
                16.931,
                17.014,
                17.095,
                17.175,
                17.253,
                17.328,
                17.401,
                17.472,
                17.541,
                17.608,
                17.673,
                17.735,
                17.796,
                17.855,
                17.913,
                17.968,
                18.022,
                18.074,
                18.125,
                18.175,
                18.223,
                18.269,
                18.315,
                18.359,
                18.402,
                18.444,
                18.485,
                18.525,
                18.563,
                18.601,
                18.638,
                18.673,
                18.708,
                18.741,
                18.774,
                18.806,
                18.837,
                18.867,
                18.896,
                18.924,
                18.952,
                18.978,
                19.004,
                19.029,
                19.053,
                19.077,
                19.1,
                19.122,
                19.143,
                19.164,
                19.185,
                19.204,
                19.224,
                19.242,
                19.26,
                19.278,
                19.294,
                19.311,
                19.327,
                19.343,
                19.358,
                19.373,
                19.387,
                19.401,
                19.414,
                19.427,
                19.44,
                19.453,
                19.465,
                19.477,
                19.489,
                19.5,
                19.51,
                19.521,
                19.532,
                19.542,
                19.552,
                19.561,
                19.571,
                19.58,
                19.589,
                19.598,
                19.606,
                19.615,
                19.623,
                19.631,
                19.639,
                19.646,
                19.654,
                19.661,
                19.668,
                19.675,
                19.682,
                19.689,
                19.696,
                19.702,
                19.709,
                19.715,
                19.721,
                19.727,
                19.733,
                19.738,
                19.744,
                19.749,
                19.755,
                19.76,
                19.765,
                19.77,
                19.775,
                19.78,
                19.785,
                19.789,
                19.794,
                19.798,
                19.803,
                19.807,
                19.811,
                19.815,
                19.819,
                19.822,
                19.826,
                19.83,
                19.833,
                19.836,
                19.839,
                19.842,
                19.845,
                19.848,
                19.851,
                19.854,
                19.856,
                19.859,
                19.861,
                19.863,
                19.866,
                19.868,
                19.87,
                19.871,
                19.873,
                19.875,
                19.877,
                19.878,
                19.88,
                19.881,
                19.883,
                19.884,
                19.885,
                19.886,
                19.887,
                19.888,
                19.889,
                19.89,
                19.891,
                19.891,
                19.892,
                19.892,
                19.893,
                19.893,
                19.893,
                19.894,
                19.894,
                19.894,
                19.893,
                19.893,
                19.893,
                19.893,
                19.893,
                19.892,
                19.892,
                19.891,
                19.89,
                19.89,
                19.889,
                19.888,
                19.887,
                19.886,
                19.885,
                19.884,
                19.883,
                19.882,
                19.88,
                19.879,
                19.878,
                19.876,
                19.875,
                19.873,
                19.871,
                19.869,
                19.868,
                19.866,
                19.864,
                19.862,
                19.86,
                19.858,
                19.856,
                19.853,
                19.851,
                19.848,
                19.846,
                19.844,
                19.841,
                19.839,
                19.836,
                19.833,
                19.83,
                19.828,
                19.825,
                19.822,
                19.819,
                19.816,
                19.813,
                19.81,
                19.807,
                19.804,
                19.801,
                19.797,
                19.794,
                19.791,
                19.787,
                19.784,
                19.781,
                19.777,
                19.773,
                19.77,
                19.766,
                19.763,
                19.759,
                19.755,
                19.751,
                19.748,
                19.744,
                19.74,
                19.736,
                19.732,
                19.728,
                19.724,
                19.72,
                19.716,
                19.712,
                19.708,
                19.704,
                19.7,
                19.696,
                19.691,
                19.687,
                19.683,
                19.678,
                19.674,
                19.67,
                19.666,
                19.661,
                19.657,
                19.652,
                19.648,
                19.644,
                19.639,
                19.635,
                19.63,
                19.626,
                19.621,
                19.617,
                19.612,
                19.608,
                19.603,
                19.598,
                19.593,
                19.589,
                19.584,
                19.58,
                19.575,
                19.57,
                19.566,
                19.561,
                19.556,
                19.551,
                19.546,
                19.542,
                19.537,
                19.532,
                19.528,
                19.523,
                19.518,
                19.513,
                19.508,
                19.504,
                19.499,
                19.494,
                19.489,
                19.484,
                19.479,
                19.475,
                19.47,
                19.465,
                19.46,
                19.455,
                19.45,
                19.445,
                19.441,
                19.436,
                19.431,
                19.426,
                19.421,
                19.416,
                19.411,
                19.406,
                19.402,
                19.397,
                19.392,
                19.387,
                19.382,
                19.377,
                19.372,
                19.367,
                19.362,
                19.357,
                19.353,
                19.348,
                19.343,
                19.338,
                19.333,
                19.328,
                19.323,
                19.318,
                19.313,
                19.308,
                19.304,
                19.299,
                19.294,
                19.289,
                19.284,
                19.279,
                19.274,
                19.27,
                19.265,
                19.26,
                19.255,
                19.25,
                19.245,
                19.241,
                19.236,
                19.231,
                19.226,
                19.221,
                19.216,
                19.211,
                19.206,
                19.202,
                19.197,
                19.192,
                19.187,
                19.183,
                19.178,
                19.173,
                19.168,
                19.163,
                19.159,
                19.154,
                19.149,
                19.144,
                19.139,
                19.135,
                19.13,
                19.125,
                19.12,
                19.116,
                19.111,
                19.106,
                19.101,
                19.097,
                19.092,
                19.087,
                19.082,
                19.078,
                19.073,
                19.069,
                19.064,
                19.059,
                19.054,
                19.05,
                19.045,
                19.04,
                19.036,
                19.031,
                19.026,
                19.022,
                19.017,
                19.013,
                19.008,
                19.003,
                18.999,
                18.994,
                18.99,
                18.985,
                18.98,
                18.976,
                18.971,
                18.967,
                18.962,
                18.958,
                18.953,
                18.949,
                18.944,
                18.94,
                18.935,
                18.931,
                18.926,
                18.922,
                18.917,
                18.913,
                18.908,
                18.904,
                18.899,
                18.895,
                18.891,
                18.886,
                18.882,
                18.877,
                18.873,
                18.869,
                18.864,
                18.86,
                18.856,
                18.851,
                18.847,
                18.843,
                18.838,
                18.834,
                18.829,
                18.825,
                18.821,
                18.817,
                18.812,
                18.808,
                18.804,
                18.799,
                18.795,
                18.791,
                18.787,
                18.783,
                18.779,
                18.774,
                18.77,
                18.766,
                18.762,
                18.757,
                18.753,
                18.749,
                18.745,
                18.741,
                18.737,
                18.733,
                18.729,
                18.724,
                18.721,
                18.716,
                18.712,
                18.708,
                18.704,
                18.7,
                18.696,
                18.692,
                18.688,
                18.684,
                18.68,
                18.676,
                18.672,
                18.668,
                18.664,
                18.66,
                18.656,
                18.652,
                18.648,
                18.644,
                18.64,
                18.636,
                18.632,
                18.629,
                18.625,
                18.621,
                18.617,
                18.613,
                18.609,
                18.606,
                18.602,
                18.598,
                18.594,
                18.59,
                18.586,
                18.583,
                18.579,
                18.575,
                18.571,
                18.568,
                18.564,
                18.56,
                18.556,
                18.552,
                18.549,
                18.545,
                18.541,
                18.538,
                18.534,
                18.53,
                18.527,
                18.523,
                18.519,
                18.516,
                18.512,
                18.508,
                18.505,
                18.501,
                18.498,
                18.494,
                18.491,
                18.487,
                18.484,
                18.48,
                18.477,
                18.473,
                18.469,
                18.466,
                18.462,
                18.459,
                18.456,
                18.452,
                18.449,
                18.445,
                18.441,
                18.438,
                18.435,
                18.431,
                18.428,
                18.424,
                18.421,
                18.418,
                18.414,
                18.411,
                18.408,
                18.405,
                18.401,
                18.398,
                18.394,
                18.391,
                18.388,
                18.384,
                18.381,
                18.378,
                18.375,
                18.371,
                18.368,
                18.365,
                18.362,
                18.359,
                18.355,
                18.352,
                18.349,
                18.346,
                18.343,
                18.339,
                18.337,
                18.333,
                18.33,
                18.327,
                18.324,
                18.321,
                18.318,
                18.315,
                18.312,
                18.308,
                18.306,
                18.303,
                18.3,
                18.297,
                18.293,
                18.291,
                18.288,
                18.285,
                18.282,
                18.279,
                18.276,
                18.273,
                18.27,
                18.267,
                18.264,
                18.261,
                18.258,
                18.256,
                18.253,
                18.25,
                18.247,
                18.244,
                18.241,
                18.239,
                18.236,
                18.233,
                18.23,
                18.228,
                18.225,
                18.222,
                18.219,
                18.217,
                18.214,
                18.211,
                18.208,
                18.206,
                18.203,
                18.2,
                18.198,
                18.195,
                18.193,
                18.19,
                18.187,
                18.185,
                18.182,
                18.179,
                18.177,
                18.174,
                18.172,
                18.169,
                18.167,
                18.164,
                18.162,
                18.159,
                18.157,
                18.154,
                18.152,
                18.149,
                18.147,
                18.144,
                18.142,
                18.139,
                18.137,
                18.135,
                18.132,
                18.13,
                18.127,
                18.125,
                18.123,
                18.12,
                18.118,
                18.116,
                18.113,
                18.111,
                18.109,
                18.107,
                18.104,
                18.102,
                18.1,
                18.097,
                18.095,
                18.093,
                18.091,
                18.088,
                18.086,
                18.084,
                18.082,
                18.079,
                18.077,
                18.075,
                18.073,
                18.07,
                18.068,
                18.066,
                18.064,
                18.062,
                18.06,
                18.058,
                18.055,
                18.053,
                18.051,
                18.049,
                18.047,
                18.045,
                18.043,
                18.041,
                18.039,
                18.037,
                18.035,
                18.033,
                18.03,
                18.029,
                18.026,
                18.024,
                18.022,
                18.02,
                18.018,
                18.017,
                18.014,
                18.012,
                18.01,
                18.009,
                18.006,
                18.005,
                18.003,
                18.001,
                17.999,
                17.997,
                17.995,
                17.993,
                17.991,
                17.989,
                17.987,
                17.985,
                17.983,
                17.982,
                18.305,
                18.303,
                18.302,
                18.3,
                18.299,
                18.297,
                18.295,
                18.294,
                18.292,
                18.29,
                18.289,
                18.287,
                18.285,
                18.284,
                18.282,
                18.281,
                18.279,
                18.278,
                18.276,
                18.274,
                18.273,
                18.271,
                18.27,
                18.268,
                18.266,
                18.265,
                18.263,
                18.262,
                18.26,
                18.258,
                18.257,
                18.255,
                18.254,
                18.252,
                18.251,
                18.249,
                18.247,
                18.246,
                18.244,
                18.243,
                18.241,
                18.239,
                18.238,
                18.237,
                18.235,
                18.233,
                18.232,
                18.23,
                18.229,
                18.227,
                18.226,
                18.224,
                18.222,
                18.221,
                18.22,
                18.218,
                18.216,
                18.215,
                18.213,
                18.212,
                18.21,
                18.209,
                18.207,
                18.206,
                18.204,
                18.203,
                18.201,
                18.2,
                18.198,
                18.197,
                18.195,
                18.194,
                18.192,
                18.191,
                18.189,
                18.188,
                18.186,
                18.184,
                18.183,
                18.182,
                18.18,
                18.178,
                18.177,
                18.176,
                18.174,
                18.173,
                18.171,
                18.17,
                18.168,
                18.167,
                18.165,
                18.164,
                18.162,
                18.161,
                18.159,
                18.158,
                18.156,
                18.155,
                18.154,
                18.152,
                18.15,
                18.149,
                18.148,
                18.146,
                18.145,
                18.143,
                18.142,
                18.141,
                18.139,
                18.137,
                18.136,
                18.135,
                18.133,
                18.132,
                18.13,
                18.129,
                18.128,
                18.126,
                18.124,
                18.123,
                18.122,
                18.12,
                18.119,
                18.117,
                18.116,
                18.115,
                18.113,
                18.112,
                18.11,
                18.109,
                18.107,
                18.106,
                18.105,
                18.103,
                18.102,
                18.1,
                18.099,
                18.098,
                18.096,
                18.095,
                18.093,
                18.092,
                18.091,
                18.089,
                18.088,
                18.087,
                18.085,
                18.084,
                18.082,
                18.081,
                18.08,
                18.078,
                18.077,
                18.076,
                18.074,
                18.073,
                18.071,
                18.07,
                18.069,
                18.067,
                18.066,
                18.065,
                18.063,
                18.062,
                18.06,
                18.059,
                18.058,
                18.056,
                18.055,
                18.054,
                18.052,
                18.051,
                18.05,
                18.048,
                18.047,
                18.046,
                18.044,
                18.043,
                18.041,
                18.04,
                18.039,
                18.038,
                18.036,
                18.035,
                18.034,
                18.032,
                18.031,
                18.03,
                18.028,
                18.027,
                18.026,
                18.024,
                18.023,
                18.022,
                18.02,
                18.019,
                18.018,
                18.016,
                18.015,
                18.014,
                18.012,
                18.011,
                18.01,
                18.009,
                18.007,
                18.006,
                18.005,
                18.003,
                18.002,
                18.001,
                18,
                17.998,
                17.997,
                17.996,
                17.994,
                17.993,
                17.992,
                17.991,
                17.989,
                17.988,
                17.987,
                17.985,
                17.984,
                17.983,
                17.982,
                17.98,
                17.979,
                17.978,
                17.977,
                17.975,
                17.974,
                17.973,
                17.972,
                17.97,
                17.969,
                17.968,
                17.967,
                17.966,
                17.964,
                17.963,
                17.962,
                17.96,
                17.959,
                17.958,
                17.957,
                17.956,
                17.954,
                17.953,
                17.952,
                17.951,
                17.95,
                17.948,
                17.947,
                17.946,
                17.944,
                17.943,
                17.942,
                17.941,
                17.94,
                17.939,
                17.937,
                17.936,
                17.935,
                17.934,
                17.932,
                17.931,
                17.93,
                17.929,
                17.928,
                17.927,
                17.925,
                17.924,
                17.923,
                17.922,
                17.92,
                17.919,
                17.918,
                17.917,
                17.916,
                17.915,
                17.913,
                17.912,
                17.911,
                17.91,
                17.909,
                17.908,
                17.906,
                17.905,
                17.904,
                17.903,
                17.902,
                17.901,
                17.899,
                17.899,
                17.897,
                17.896,
                17.895,
                17.894,
                17.893,
                17.892,
                17.89,
                17.889,
                17.888,
                17.887,
                17.886,
                17.885,
                17.884,
                17.882,
                17.881,
                17.88,
                17.879,
                17.878,
                17.877,
                17.876,
                17.875,
                17.873,
                17.872,
                17.871,
                17.87,
                17.869,
                17.868,
                17.867,
                17.866,
                17.864,
                17.864,
                17.862,
                17.861,
                17.86,
                17.859,
                17.858,
                17.857,
                17.856,
                17.855,
                17.854,
                17.853,
                17.852,
                17.851,
                17.85,
                17.849,
                17.847,
                17.846,
                17.845,
                17.844,
                17.843,
                17.842,
                17.841,
                17.84,
                17.839,
                17.838,
                17.837,
                17.836,
                17.835,
                17.834,
                17.833,
                17.832,
                17.831,
                17.83,
                17.828,
                17.828,
                17.827,
                17.825,
                17.825,
                17.823,
                17.822,
                17.822,
                17.82,
                17.82,
                17.818,
                17.817,
                17.817,
                17.815,
                17.814,
                17.813,
                17.812,
                17.811,
                17.81,
                17.81,
                17.808,
                17.808,
                17.807,
                17.806,
                17.805,
                17.803,
                17.803,
                17.802,
                17.801,
                17.8,
                17.799,
                17.798,
                17.797,
                17.796,
                17.795,
                17.794,
                17.793,
                17.792,
                17.791,
                17.79,
                17.789,
                17.788,
                17.787,
                17.787,
                17.786,
                17.785,
                17.784,
                17.783,
                17.782,
                17.781,
                17.78,
                17.779,
                17.778,
                17.777,
                17.777,
                17.775,
                17.775,
                17.774,
                17.773,
                17.772,
                17.771,
                17.77,
                17.769,
                17.769,
                17.767,
                17.767,
                17.766,
                17.765,
                17.764,
                17.763,
                17.762,
                17.761,
                17.761,
                17.76,
                17.759,
                17.758,
                17.757,
                17.756,
                17.756,
                17.755,
                17.754,
                17.753,
                17.752,
                17.751,
                17.751,
                17.75,
                17.749,
                17.748,
                17.747,
                17.746,
                17.746,
                17.745,
                17.744,
                17.743,
                17.742,
                17.741,
                17.741,
                17.74,
                17.739,
                17.738,
                17.738,
                17.737,
                17.736,
                17.735,
                17.735,
                17.734,
                17.733,
                17.732,
                17.732,
                17.731,
                17.73,
                17.729,
                17.728,
                17.727,
                17.727,
                17.726,
                17.725,
                17.725,
                17.724,
                17.723,
                17.722,
                17.722,
                17.721,
                17.72,
                17.719,
                17.719,
                17.718,
                17.717,
                17.717,
                17.716,
                17.715,
                17.715,
                17.714,
                17.713,
                17.712,
                17.712,
                17.711,
                17.71,
                17.71,
                17.709,
                17.708,
                17.708,
                17.707,
                17.707,
                17.706,
                17.705,
                17.704,
                17.704,
                17.703,
                17.702,
                17.702,
                17.701,
                17.701,
                17.7,
                17.699,
                17.699,
                17.698,
                17.697,
                17.697,
                17.696,
                17.695,
                17.695,
                17.694,
                17.694,
                17.693,
                17.692,
                17.692,
                17.691,
                17.69,
                17.69,
                17.689,
                17.689,
                17.688,
                17.688,
                17.687,
                17.686,
                17.686,
                17.685,
                17.685,
                17.684,
                17.684,
                17.683,
                17.683,
                17.682,
                17.682,
                17.681,
                17.68,
                17.68,
                17.679,
                17.679,
                17.678,
                17.677,
                17.677,
                17.677,
                17.676,
                17.676,
                17.675,
                17.674,
                17.674,
                17.673,
                17.673,
                17.672,
                17.672,
                17.672,
                17.671,
                17.67,
                17.67,
                17.669,
                17.669,
                17.668,
                17.668,
                17.667,
                17.667,
                17.666,
                17.666,
                17.666,
                17.665,
                17.665,
                17.664,
                17.663,
                17.663,
                17.663,
                17.662,
                17.662,
                17.661,
                17.661,
                17.661,
                17.66,
                17.659,
                17.659,
                17.659,
                17.658,
                17.658,
                17.657,
                17.657,
                17.657,
                17.656,
                17.656,
                17.655,
                17.655,
                17.654,
                17.654,
                17.654,
                17.653,
                17.653,
                17.652,
                17.652,
                17.652,
                17.651,
                17.651,
                17.65,
                17.65,
                17.65,
                17.649,
                17.649,
                17.649,
                17.648,
                17.648,
                17.647,
                17.647,
                17.647,
                17.646,
                17.646,
                17.646,
                17.645,
                17.645,
                17.644,
                17.644,
                17.644,
                17.643,
                17.643,
                17.643,
                17.642,
                17.642,
                17.642,
                17.641,
                17.641,
                17.641,
                17.64,
                17.64,
                17.64,
                17.639,
                17.639,
                17.639,
                17.639,
                17.638,
                17.638,
                17.638,
                17.637,
                17.637,
                17.637,
                17.636,
                17.636,
                17.636,
                17.636,
                17.635,
                17.635,
                17.635,
                17.634,
                17.634,
                17.634,
                17.634,
                17.633,
                17.633,
                17.633,
                17.632,
                17.632,
                17.632,
                17.632,
                17.631,
                17.631,
                17.631,
                17.631,
                17.63,
                17.63,
                17.63,
                17.63,
                17.629,
                17.629,
                17.629,
                17.629,
                17.628,
                17.628,
                17.628,
                17.628,
                17.627,
                17.627,
                17.627,
                17.627,
                17.626,
                17.626,
                17.626,
                17.626,
                17.625,
                17.625,
                17.625,
                17.625,
                17.625,
                17.625,
                17.624,
                17.624,
                17.624,
                17.624,
                17.623,
                17.623,
                17.623,
                17.623,
                17.622,
                17.622,
                17.622,
                17.622,
                17.622,
                17.621,
                17.621,
                17.621,
                17.621,
                17.621,
                17.621,
                17.621,
                17.62,
                17.62,
                17.62,
                17.62,
                17.62,
                17.619,
                17.619,
                17.619,
                17.619,
                17.619,
                17.619,
                17.619,
                17.618,
                17.618,
                17.618,
                17.618,
                17.618,
                17.618,
                17.618,
                17.617,
                17.617,
                17.617,
                17.617,
                17.617,
                17.617,
                17.616,
                17.616,
                17.616,
                17.616,
                17.616,
                17.616,
                17.616,
                17.616,
                17.615,
                17.615,
                17.615,
                17.615,
                17.615,
                17.615,
                17.615,
                17.615,
                17.615,
                17.614,
                17.615,
                17.614,
                17.614,
                17.614,
                17.614,
                17.614,
                17.614,
                17.614,
                17.614,
                17.614,
                17.614,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.611,
                17.611,
                17.612,
                17.612,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.611,
                17.612,
                17.612,
                17.611,
                17.611,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.612,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.613,
                17.614,
                17.614,
                17.614,
                17.614,
                17.614,
                17.614,
                17.614,
                17.614,
                17.614,
                17.614,
                17.614,
                17.615,
                17.615,
                17.615,
                17.615,
                17.615,
                17.615,
                17.615,
                17.615,
                17.616,
                17.616,
                17.616,
                17.616,
                17.616,
                17.616,
                17.616,
                17.617,
                17.617,
                17.617,
                17.617,
                17.617,
                17.617,
                17.617,
                17.617,
                17.617,
                17.617,
                17.617,
                17.618,
                17.618,
                17.618,
                17.618,
                17.618,
                17.618,
                17.618,
                17.619,
                17.619,
                17.619,
                17.619,
                17.619,
                17.619,
                17.62,
                17.62,
                17.62,
                17.62,
                17.62,
                17.62,
                17.62,
                17.621,
                17.621,
                17.621,
                17.621,
                17.621,
                17.621,
                17.622,
                17.622,
                17.622,
                17.622,
                17.623,
                17.623,
                17.623,
                17.623,
                17.623,
                17.623,
                17.623,
                17.624,
                17.624,
                17.624,
                17.624,
                17.624,
                17.624,
                17.625,
                17.625,
                17.625,
                17.625,
                17.626,
                17.626,
                17.626,
                17.626,
                17.626,
                17.626,
                17.627,
                17.627,
                17.627,
                17.627,
                17.627,
                17.628,
                17.628,
                17.628,
                17.628,
                17.629,
                17.629,
                17.629,
                17.629,
                17.629,
                17.63,
                17.63,
                17.63,
                17.63,
                17.631,
                17.631,
                17.631,
                17.631,
                17.631,
                17.632,
                17.632,
                17.632,
                17.632,
                17.633,
                17.633,
                17.633,
                17.633,
                17.633,
                17.634,
                17.634,
                17.634,
                17.634,
                17.635,
                17.635,
                17.635,
                17.636,
                17.636,
                17.636,
                17.636,
                17.636,
                17.637,
                17.637,
                17.637,
                17.637,
                17.638,
                17.638,
                17.638,
                17.639,
                17.639,
                17.639,
                17.639,
                17.64,
                17.64,
                17.64,
                17.64,
                17.641,
                17.641,
                17.641,
                17.642,
                17.642,
                17.642,
                17.643,
                17.643,
                17.643,
                17.643,
                17.644,
                17.644,
                17.644,
                17.645,
                17.645,
                17.645,
                17.645,
                17.645,
                17.646,
                17.646,
                17.647,
                17.647,
                17.647,
                17.648,
                17.648,
                17.648,
                17.649,
                17.649,
                17.649,
                17.649,
                17.65,
                17.65,
                17.65,
                17.651,
                17.651,
                17.651,
                17.652,
                17.652,
                17.652,
                17.653,
                17.653,
                17.653,
                17.654,
                17.654,
                17.654,
                17.654,
                17.655,
                17.655,
                17.656,
                17.656,
                17.656,
                17.657,
                17.657,
                17.657,
                17.658,
                17.658,
                17.658,
                17.659,
                17.659,
                17.659,
                17.66,
                17.66,
                17.66,
                17.661,
                17.661,
                17.662,
                17.662,
                17.662,
                17.663,
                17.663,
                17.663,
                17.664,
                17.664,
                17.665,
                17.665,
                17.665,
                17.666,
                17.666,
                17.666,
                17.667,
                17.667,
                17.668,
                17.668,
                17.668,
                17.669,
                17.669,
                17.67,
                17.67,
                17.671,
                17.671,
                17.671,
                17.672,
                17.672,
                17.672,
                17.673,
                17.673
            ],
            P97: [
                16.13,
                16.098,
                16.066,
                16.035,
                16.004,
                15.974,
                15.944,
                15.914,
                15.954,
                15.994,
                16.033,
                16.072,
                16.111,
                16.151,
                16.189,
                16.276,
                16.367,
                16.46,
                16.555,
                16.649,
                16.743,
                16.836,
                16.928,
                17.019,
                17.108,
                17.196,
                17.281,
                17.366,
                17.449,
                17.53,
                17.609,
                17.685,
                17.76,
                17.832,
                17.902,
                17.97,
                18.036,
                18.1,
                18.161,
                18.222,
                18.28,
                18.336,
                18.391,
                18.444,
                18.496,
                18.546,
                18.595,
                18.643,
                18.689,
                18.734,
                18.778,
                18.82,
                18.862,
                18.902,
                18.941,
                18.98,
                19.017,
                19.053,
                19.088,
                19.123,
                19.156,
                19.188,
                19.22,
                19.25,
                19.28,
                19.309,
                19.336,
                19.363,
                19.39,
                19.415,
                19.44,
                19.464,
                19.487,
                19.51,
                19.532,
                19.553,
                19.573,
                19.593,
                19.613,
                19.632,
                19.65,
                19.668,
                19.685,
                19.702,
                19.718,
                19.734,
                19.75,
                19.765,
                19.779,
                19.793,
                19.807,
                19.82,
                19.834,
                19.846,
                19.859,
                19.871,
                19.882,
                19.894,
                19.905,
                19.916,
                19.926,
                19.937,
                19.947,
                19.957,
                19.966,
                19.975,
                19.984,
                19.993,
                20.002,
                20.011,
                20.019,
                20.027,
                20.035,
                20.043,
                20.05,
                20.058,
                20.065,
                20.073,
                20.08,
                20.086,
                20.093,
                20.1,
                20.106,
                20.113,
                20.119,
                20.125,
                20.131,
                20.137,
                20.142,
                20.148,
                20.154,
                20.159,
                20.164,
                20.169,
                20.174,
                20.18,
                20.184,
                20.189,
                20.194,
                20.198,
                20.202,
                20.207,
                20.21,
                20.215,
                20.218,
                20.222,
                20.226,
                20.23,
                20.233,
                20.236,
                20.24,
                20.243,
                20.246,
                20.249,
                20.251,
                20.254,
                20.257,
                20.259,
                20.262,
                20.264,
                20.266,
                20.269,
                20.271,
                20.272,
                20.274,
                20.276,
                20.278,
                20.28,
                20.281,
                20.283,
                20.284,
                20.285,
                20.287,
                20.288,
                20.289,
                20.29,
                20.29,
                20.291,
                20.292,
                20.293,
                20.293,
                20.294,
                20.294,
                20.294,
                20.295,
                20.295,
                20.295,
                20.295,
                20.295,
                20.295,
                20.295,
                20.295,
                20.294,
                20.294,
                20.293,
                20.293,
                20.292,
                20.292,
                20.291,
                20.29,
                20.289,
                20.288,
                20.287,
                20.286,
                20.284,
                20.283,
                20.282,
                20.281,
                20.279,
                20.277,
                20.276,
                20.274,
                20.273,
                20.271,
                20.269,
                20.267,
                20.265,
                20.263,
                20.261,
                20.259,
                20.257,
                20.254,
                20.252,
                20.249,
                20.247,
                20.244,
                20.242,
                20.24,
                20.237,
                20.234,
                20.231,
                20.228,
                20.225,
                20.223,
                20.22,
                20.216,
                20.214,
                20.211,
                20.207,
                20.204,
                20.201,
                20.197,
                20.194,
                20.191,
                20.187,
                20.184,
                20.18,
                20.177,
                20.173,
                20.17,
                20.166,
                20.162,
                20.158,
                20.155,
                20.151,
                20.147,
                20.143,
                20.139,
                20.135,
                20.131,
                20.127,
                20.123,
                20.119,
                20.115,
                20.111,
                20.107,
                20.102,
                20.098,
                20.094,
                20.09,
                20.085,
                20.081,
                20.076,
                20.072,
                20.068,
                20.064,
                20.059,
                20.054,
                20.05,
                20.046,
                20.041,
                20.037,
                20.032,
                20.027,
                20.023,
                20.018,
                20.013,
                20.009,
                20.004,
                20,
                19.995,
                19.99,
                19.985,
                19.981,
                19.976,
                19.971,
                19.966,
                19.962,
                19.957,
                19.952,
                19.947,
                19.942,
                19.937,
                19.932,
                19.928,
                19.923,
                19.918,
                19.913,
                19.908,
                19.903,
                19.898,
                19.893,
                19.888,
                19.884,
                19.879,
                19.874,
                19.869,
                19.864,
                19.859,
                19.854,
                19.849,
                19.844,
                19.839,
                19.834,
                19.829,
                19.824,
                19.819,
                19.814,
                19.809,
                19.804,
                19.799,
                19.794,
                19.789,
                19.784,
                19.78,
                19.774,
                19.769,
                19.764,
                19.759,
                19.755,
                19.749,
                19.745,
                19.74,
                19.735,
                19.73,
                19.724,
                19.72,
                19.715,
                19.71,
                19.705,
                19.699,
                19.695,
                19.69,
                19.685,
                19.68,
                19.675,
                19.67,
                19.665,
                19.66,
                19.655,
                19.65,
                19.645,
                19.64,
                19.635,
                19.631,
                19.626,
                19.621,
                19.616,
                19.611,
                19.606,
                19.601,
                19.596,
                19.591,
                19.586,
                19.581,
                19.576,
                19.571,
                19.566,
                19.561,
                19.556,
                19.552,
                19.547,
                19.542,
                19.537,
                19.532,
                19.527,
                19.522,
                19.518,
                19.513,
                19.508,
                19.503,
                19.498,
                19.493,
                19.488,
                19.484,
                19.479,
                19.474,
                19.469,
                19.464,
                19.46,
                19.455,
                19.45,
                19.445,
                19.441,
                19.436,
                19.431,
                19.426,
                19.422,
                19.417,
                19.412,
                19.408,
                19.403,
                19.398,
                19.393,
                19.389,
                19.384,
                19.38,
                19.375,
                19.37,
                19.365,
                19.361,
                19.356,
                19.351,
                19.347,
                19.342,
                19.338,
                19.333,
                19.328,
                19.324,
                19.319,
                19.315,
                19.31,
                19.305,
                19.301,
                19.297,
                19.292,
                19.287,
                19.283,
                19.278,
                19.274,
                19.269,
                19.265,
                19.26,
                19.256,
                19.251,
                19.247,
                19.243,
                19.238,
                19.234,
                19.229,
                19.225,
                19.22,
                19.216,
                19.211,
                19.207,
                19.203,
                19.198,
                19.194,
                19.19,
                19.185,
                19.181,
                19.177,
                19.172,
                19.168,
                19.164,
                19.16,
                19.155,
                19.151,
                19.146,
                19.143,
                19.138,
                19.134,
                19.13,
                19.126,
                19.121,
                19.117,
                19.113,
                19.109,
                19.104,
                19.101,
                19.096,
                19.092,
                19.088,
                19.084,
                19.08,
                19.075,
                19.071,
                19.067,
                19.063,
                19.059,
                19.055,
                19.051,
                19.047,
                19.043,
                19.039,
                19.035,
                19.031,
                19.027,
                19.023,
                19.019,
                19.015,
                19.011,
                19.007,
                19.003,
                18.999,
                18.995,
                18.991,
                18.987,
                18.983,
                18.979,
                18.975,
                18.972,
                18.968,
                18.964,
                18.96,
                18.956,
                18.952,
                18.949,
                18.945,
                18.941,
                18.937,
                18.933,
                18.929,
                18.926,
                18.922,
                18.918,
                18.914,
                18.91,
                18.907,
                18.903,
                18.899,
                18.895,
                18.892,
                18.888,
                18.884,
                18.881,
                18.877,
                18.874,
                18.87,
                18.866,
                18.863,
                18.859,
                18.855,
                18.852,
                18.848,
                18.844,
                18.841,
                18.837,
                18.834,
                18.83,
                18.827,
                18.823,
                18.82,
                18.816,
                18.813,
                18.809,
                18.805,
                18.802,
                18.799,
                18.795,
                18.792,
                18.788,
                18.785,
                18.781,
                18.778,
                18.775,
                18.771,
                18.768,
                18.765,
                18.761,
                18.758,
                18.754,
                18.751,
                18.748,
                18.744,
                18.741,
                18.738,
                18.735,
                18.731,
                18.728,
                18.725,
                18.722,
                18.719,
                18.715,
                18.712,
                18.709,
                18.706,
                18.703,
                18.699,
                18.696,
                18.693,
                18.69,
                18.687,
                18.684,
                18.68,
                18.678,
                18.674,
                18.671,
                18.668,
                18.665,
                18.662,
                18.659,
                18.656,
                18.653,
                18.65,
                18.647,
                18.644,
                18.641,
                18.638,
                18.635,
                18.632,
                18.629,
                18.626,
                18.623,
                18.621,
                18.618,
                18.615,
                18.612,
                18.609,
                18.606,
                18.603,
                18.601,
                18.598,
                18.595,
                18.592,
                18.589,
                18.587,
                18.584,
                18.581,
                18.578,
                18.576,
                18.573,
                18.57,
                18.568,
                18.565,
                18.562,
                18.559,
                18.557,
                18.554,
                18.551,
                18.549,
                18.546,
                18.544,
                18.541,
                18.539,
                18.536,
                18.533,
                18.531,
                18.528,
                18.526,
                18.523,
                18.521,
                18.518,
                18.516,
                18.513,
                18.511,
                18.508,
                18.506,
                18.503,
                18.501,
                18.498,
                18.496,
                18.493,
                18.491,
                18.489,
                18.486,
                18.484,
                18.481,
                18.479,
                18.477,
                18.474,
                18.472,
                18.469,
                18.467,
                18.465,
                18.463,
                18.46,
                18.458,
                18.456,
                18.454,
                18.451,
                18.449,
                18.447,
                18.445,
                18.442,
                18.44,
                18.438,
                18.435,
                18.433,
                18.431,
                18.429,
                18.427,
                18.425,
                18.422,
                18.42,
                18.418,
                18.416,
                18.414,
                18.412,
                18.409,
                18.407,
                18.405,
                18.403,
                18.401,
                18.399,
                18.397,
                18.395,
                18.393,
                18.391,
                18.389,
                18.387,
                18.384,
                18.383,
                18.38,
                18.379,
                18.376,
                18.375,
                18.372,
                18.371,
                18.368,
                18.367,
                18.364,
                18.363,
                18.36,
                18.359,
                18.357,
                18.355,
                18.353,
                18.351,
                18.349,
                18.347,
                18.676,
                18.674,
                18.673,
                18.671,
                18.669,
                18.668,
                18.666,
                18.664,
                18.663,
                18.661,
                18.659,
                18.657,
                18.656,
                18.654,
                18.652,
                18.651,
                18.649,
                18.647,
                18.646,
                18.644,
                18.642,
                18.64,
                18.639,
                18.637,
                18.635,
                18.634,
                18.632,
                18.631,
                18.629,
                18.627,
                18.626,
                18.624,
                18.622,
                18.62,
                18.619,
                18.617,
                18.615,
                18.614,
                18.612,
                18.611,
                18.609,
                18.607,
                18.606,
                18.604,
                18.602,
                18.601,
                18.599,
                18.598,
                18.596,
                18.594,
                18.593,
                18.591,
                18.589,
                18.588,
                18.586,
                18.584,
                18.583,
                18.581,
                18.58,
                18.578,
                18.576,
                18.575,
                18.573,
                18.572,
                18.57,
                18.569,
                18.567,
                18.565,
                18.564,
                18.562,
                18.56,
                18.559,
                18.557,
                18.556,
                18.554,
                18.553,
                18.551,
                18.549,
                18.548,
                18.546,
                18.545,
                18.543,
                18.542,
                18.54,
                18.538,
                18.537,
                18.535,
                18.534,
                18.532,
                18.531,
                18.529,
                18.528,
                18.526,
                18.524,
                18.523,
                18.522,
                18.52,
                18.518,
                18.517,
                18.515,
                18.514,
                18.512,
                18.511,
                18.509,
                18.508,
                18.506,
                18.504,
                18.503,
                18.502,
                18.5,
                18.499,
                18.497,
                18.495,
                18.494,
                18.493,
                18.491,
                18.49,
                18.488,
                18.486,
                18.485,
                18.484,
                18.482,
                18.481,
                18.479,
                18.477,
                18.476,
                18.475,
                18.473,
                18.472,
                18.47,
                18.469,
                18.467,
                18.466,
                18.464,
                18.463,
                18.461,
                18.46,
                18.459,
                18.457,
                18.456,
                18.454,
                18.452,
                18.451,
                18.45,
                18.448,
                18.447,
                18.445,
                18.444,
                18.443,
                18.441,
                18.44,
                18.438,
                18.437,
                18.435,
                18.434,
                18.433,
                18.431,
                18.429,
                18.428,
                18.427,
                18.425,
                18.424,
                18.422,
                18.421,
                18.42,
                18.418,
                18.417,
                18.415,
                18.414,
                18.413,
                18.411,
                18.41,
                18.408,
                18.407,
                18.406,
                18.404,
                18.403,
                18.401,
                18.4,
                18.399,
                18.397,
                18.396,
                18.394,
                18.393,
                18.392,
                18.39,
                18.389,
                18.388,
                18.386,
                18.385,
                18.383,
                18.382,
                18.381,
                18.379,
                18.378,
                18.376,
                18.375,
                18.374,
                18.373,
                18.371,
                18.37,
                18.368,
                18.367,
                18.366,
                18.364,
                18.363,
                18.362,
                18.36,
                18.359,
                18.358,
                18.356,
                18.355,
                18.353,
                18.352,
                18.351,
                18.35,
                18.348,
                18.347,
                18.346,
                18.345,
                18.343,
                18.342,
                18.341,
                18.339,
                18.338,
                18.336,
                18.335,
                18.334,
                18.333,
                18.331,
                18.33,
                18.329,
                18.327,
                18.326,
                18.325,
                18.324,
                18.322,
                18.321,
                18.32,
                18.318,
                18.317,
                18.316,
                18.315,
                18.313,
                18.312,
                18.311,
                18.31,
                18.308,
                18.307,
                18.306,
                18.305,
                18.303,
                18.302,
                18.301,
                18.299,
                18.298,
                18.297,
                18.296,
                18.294,
                18.293,
                18.292,
                18.291,
                18.289,
                18.288,
                18.287,
                18.286,
                18.284,
                18.283,
                18.282,
                18.281,
                18.279,
                18.278,
                18.277,
                18.276,
                18.275,
                18.274,
                18.272,
                18.271,
                18.27,
                18.269,
                18.267,
                18.266,
                18.265,
                18.264,
                18.263,
                18.262,
                18.26,
                18.259,
                18.258,
                18.257,
                18.255,
                18.254,
                18.253,
                18.252,
                18.251,
                18.25,
                18.248,
                18.247,
                18.246,
                18.245,
                18.244,
                18.243,
                18.241,
                18.24,
                18.239,
                18.238,
                18.237,
                18.236,
                18.234,
                18.233,
                18.232,
                18.231,
                18.23,
                18.229,
                18.228,
                18.226,
                18.225,
                18.224,
                18.223,
                18.222,
                18.221,
                18.22,
                18.219,
                18.217,
                18.216,
                18.215,
                18.214,
                18.213,
                18.212,
                18.211,
                18.21,
                18.209,
                18.207,
                18.206,
                18.205,
                18.204,
                18.203,
                18.202,
                18.201,
                18.2,
                18.199,
                18.198,
                18.197,
                18.195,
                18.194,
                18.193,
                18.192,
                18.191,
                18.19,
                18.189,
                18.188,
                18.187,
                18.186,
                18.185,
                18.184,
                18.183,
                18.182,
                18.181,
                18.18,
                18.179,
                18.177,
                18.177,
                18.175,
                18.174,
                18.174,
                18.172,
                18.172,
                18.17,
                18.169,
                18.169,
                18.167,
                18.166,
                18.165,
                18.164,
                18.163,
                18.162,
                18.161,
                18.16,
                18.159,
                18.159,
                18.157,
                18.156,
                18.155,
                18.155,
                18.154,
                18.152,
                18.152,
                18.15,
                18.15,
                18.149,
                18.148,
                18.147,
                18.146,
                18.145,
                18.144,
                18.143,
                18.142,
                18.141,
                18.14,
                18.139,
                18.138,
                18.137,
                18.136,
                18.135,
                18.134,
                18.134,
                18.133,
                18.132,
                18.131,
                18.13,
                18.129,
                18.128,
                18.127,
                18.126,
                18.126,
                18.124,
                18.124,
                18.123,
                18.122,
                18.121,
                18.12,
                18.119,
                18.118,
                18.117,
                18.117,
                18.116,
                18.115,
                18.114,
                18.113,
                18.112,
                18.111,
                18.111,
                18.11,
                18.109,
                18.108,
                18.107,
                18.106,
                18.106,
                18.105,
                18.104,
                18.103,
                18.102,
                18.101,
                18.101,
                18.1,
                18.099,
                18.098,
                18.098,
                18.097,
                18.096,
                18.095,
                18.094,
                18.093,
                18.093,
                18.092,
                18.091,
                18.09,
                18.09,
                18.089,
                18.088,
                18.087,
                18.087,
                18.086,
                18.085,
                18.084,
                18.084,
                18.083,
                18.082,
                18.081,
                18.08,
                18.08,
                18.079,
                18.079,
                18.077,
                18.077,
                18.076,
                18.076,
                18.075,
                18.074,
                18.073,
                18.073,
                18.072,
                18.071,
                18.071,
                18.07,
                18.069,
                18.068,
                18.068,
                18.067,
                18.066,
                18.066,
                18.065,
                18.065,
                18.064,
                18.063,
                18.062,
                18.062,
                18.061,
                18.06,
                18.06,
                18.059,
                18.058,
                18.058,
                18.057,
                18.057,
                18.056,
                18.055,
                18.055,
                18.054,
                18.054,
                18.053,
                18.052,
                18.052,
                18.051,
                18.051,
                18.05,
                18.049,
                18.049,
                18.048,
                18.048,
                18.047,
                18.046,
                18.046,
                18.045,
                18.045,
                18.044,
                18.044,
                18.043,
                18.043,
                18.042,
                18.041,
                18.041,
                18.04,
                18.04,
                18.039,
                18.039,
                18.038,
                18.037,
                18.037,
                18.037,
                18.036,
                18.036,
                18.035,
                18.034,
                18.034,
                18.033,
                18.033,
                18.033,
                18.032,
                18.031,
                18.031,
                18.03,
                18.03,
                18.03,
                18.029,
                18.028,
                18.028,
                18.027,
                18.027,
                18.027,
                18.026,
                18.026,
                18.025,
                18.025,
                18.024,
                18.024,
                18.023,
                18.023,
                18.022,
                18.022,
                18.022,
                18.021,
                18.021,
                18.02,
                18.02,
                18.02,
                18.019,
                18.019,
                18.018,
                18.018,
                18.017,
                18.017,
                18.017,
                18.016,
                18.016,
                18.015,
                18.015,
                18.015,
                18.014,
                18.014,
                18.014,
                18.013,
                18.013,
                18.012,
                18.012,
                18.011,
                18.011,
                18.011,
                18.01,
                18.01,
                18.01,
                18.009,
                18.009,
                18.009,
                18.009,
                18.008,
                18.008,
                18.007,
                18.007,
                18.007,
                18.006,
                18.006,
                18.006,
                18.005,
                18.005,
                18.005,
                18.005,
                18.004,
                18.004,
                18.004,
                18.003,
                18.003,
                18.003,
                18.002,
                18.002,
                18.002,
                18.001,
                18.001,
                18.001,
                18,
                18,
                18,
                18,
                17.999,
                17.999,
                17.999,
                17.999,
                17.998,
                17.998,
                17.998,
                17.998,
                17.997,
                17.997,
                17.997,
                17.997,
                17.996,
                17.996,
                17.996,
                17.996,
                17.996,
                17.995,
                17.995,
                17.995,
                17.995,
                17.994,
                17.994,
                17.994,
                17.994,
                17.994,
                17.993,
                17.993,
                17.993,
                17.993,
                17.992,
                17.992,
                17.992,
                17.992,
                17.992,
                17.991,
                17.991,
                17.991,
                17.991,
                17.991,
                17.99,
                17.99,
                17.99,
                17.99,
                17.99,
                17.989,
                17.989,
                17.989,
                17.989,
                17.989,
                17.989,
                17.988,
                17.988,
                17.988,
                17.988,
                17.988,
                17.988,
                17.987,
                17.987,
                17.987,
                17.987,
                17.987,
                17.987,
                17.987,
                17.987,
                17.986,
                17.986,
                17.986,
                17.986,
                17.986,
                17.986,
                17.986,
                17.986,
                17.985,
                17.985,
                17.985,
                17.985,
                17.985,
                17.985,
                17.985,
                17.985,
                17.985,
                17.985,
                17.984,
                17.984,
                17.984,
                17.984,
                17.984,
                17.984,
                17.984,
                17.984,
                17.984,
                17.984,
                17.984,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.982,
                17.982,
                17.983,
                17.983,
                17.983,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.982,
                17.983,
                17.983,
                17.983,
                17.983,
                17.982,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.983,
                17.984,
                17.984,
                17.983,
                17.984,
                17.983,
                17.984,
                17.984,
                17.984,
                17.984,
                17.984,
                17.984,
                17.984,
                17.984,
                17.984,
                17.984,
                17.985,
                17.985,
                17.985,
                17.985,
                17.985,
                17.985,
                17.985,
                17.985,
                17.985,
                17.985,
                17.986,
                17.986,
                17.986,
                17.986,
                17.986,
                17.986,
                17.986,
                17.986,
                17.987,
                17.987,
                17.987,
                17.987,
                17.987,
                17.987,
                17.988,
                17.988,
                17.988,
                17.988,
                17.988,
                17.988,
                17.988,
                17.988,
                17.988,
                17.989,
                17.989,
                17.989,
                17.989,
                17.99,
                17.99,
                17.99,
                17.99,
                17.99,
                17.99,
                17.99,
                17.991,
                17.991,
                17.991,
                17.991,
                17.991,
                17.991,
                17.992,
                17.992,
                17.992,
                17.992,
                17.993,
                17.993,
                17.993,
                17.993,
                17.993,
                17.993,
                17.993,
                17.994,
                17.994,
                17.994,
                17.994,
                17.994,
                17.995,
                17.995,
                17.995,
                17.995,
                17.996,
                17.996,
                17.996,
                17.996,
                17.997,
                17.997,
                17.997,
                17.997,
                17.997,
                17.998,
                17.998,
                17.998,
                17.998,
                17.998,
                17.999,
                17.999,
                17.999,
                17.999,
                18,
                18,
                18,
                18,
                18.001,
                18.001,
                18.001,
                18.002,
                18.002,
                18.002,
                18.002,
                18.003,
                18.003,
                18.003,
                18.003,
                18.004,
                18.004,
                18.004,
                18.004,
                18.005,
                18.005,
                18.005,
                18.005,
                18.006,
                18.006,
                18.006,
                18.007,
                18.007,
                18.007,
                18.007,
                18.008,
                18.008,
                18.008,
                18.008,
                18.009,
                18.009,
                18.009,
                18.01,
                18.01,
                18.01,
                18.011,
                18.011,
                18.011,
                18.012,
                18.012,
                18.012,
                18.012,
                18.013,
                18.013,
                18.013,
                18.014,
                18.014,
                18.014,
                18.015,
                18.015,
                18.015,
                18.016,
                18.016,
                18.016,
                18.017,
                18.017,
                18.017,
                18.018,
                18.018,
                18.018,
                18.019,
                18.019,
                18.019,
                18.02,
                18.02,
                18.02,
                18.021,
                18.021,
                18.022,
                18.022,
                18.022,
                18.023,
                18.023,
                18.023,
                18.024,
                18.024,
                18.024,
                18.025,
                18.025,
                18.025,
                18.026,
                18.026,
                18.026,
                18.027,
                18.027,
                18.027,
                18.028,
                18.028,
                18.029,
                18.029,
                18.029,
                18.03,
                18.03,
                18.031,
                18.031,
                18.032,
                18.032,
                18.032,
                18.033,
                18.033,
                18.033,
                18.034,
                18.034,
                18.034,
                18.035,
                18.035,
                18.036,
                18.036,
                18.037,
                18.037,
                18.038,
                18.038,
                18.038,
                18.039,
                18.039,
                18.04,
                18.04,
                18.04,
                18.041,
                18.041,
                18.042,
                18.042,
                18.042,
                18.043,
                18.044,
                18.044,
                18.044,
                18.045,
                18.045,
                18.045,
                18.046,
                18.046,
                18.047,
                18.047,
                18.048,
                18.048,
                18.049,
                18.049,
                18.05,
                18.05,
                18.05,
                18.051,
                18.051,
                18.052,
                18.053,
                18.053,
                18.053,
                18.054,
                18.054,
                18.055,
                18.055,
                18.056,
                18.056,
                18.057,
                18.057,
                18.058,
                18.058,
                18.058,
                18.059,
                18.06,
                18.06,
                18.061,
                18.061,
                18.061,
                18.062,
                18.062,
                18.063,
                18.064,
                18.064,
                18.064,
                18.065,
                18.065,
                18.066,
                18.067,
                18.067,
                18.068,
                18.068,
                18.068,
                18.069,
                18.07,
                18.07,
                18.071,
                18.071,
                18.072,
                18.072,
                18.073,
                18.073,
                18.074,
                18.074,
                18.075,
                18.075,
                18.076,
                18.077,
                18.077,
                18.077,
                18.078,
                18.079,
                18.079,
                18.08,
                18.08,
                18.081,
                18.081,
                18.082,
                18.083,
                18.083,
                18.083,
                18.084,
                18.085,
                18.085,
                18.086,
                18.086,
                18.087,
                18.088,
                18.088
            ],
            P99: [
                16.879,
                16.829,
                16.781,
                16.734,
                16.687,
                16.642,
                16.598,
                16.555,
                16.595,
                16.635,
                16.675,
                16.714,
                16.753,
                16.793,
                16.831,
                16.92,
                17.014,
                17.11,
                17.207,
                17.305,
                17.402,
                17.498,
                17.593,
                17.686,
                17.778,
                17.869,
                17.957,
                18.045,
                18.13,
                18.214,
                18.296,
                18.375,
                18.451,
                18.526,
                18.598,
                18.669,
                18.737,
                18.803,
                18.867,
                18.929,
                18.989,
                19.047,
                19.104,
                19.159,
                19.213,
                19.265,
                19.315,
                19.364,
                19.413,
                19.459,
                19.505,
                19.549,
                19.592,
                19.633,
                19.674,
                19.713,
                19.752,
                19.789,
                19.826,
                19.861,
                19.896,
                19.929,
                19.962,
                19.993,
                20.024,
                20.054,
                20.083,
                20.111,
                20.138,
                20.164,
                20.19,
                20.215,
                20.239,
                20.262,
                20.285,
                20.307,
                20.328,
                20.349,
                20.369,
                20.389,
                20.407,
                20.426,
                20.444,
                20.461,
                20.478,
                20.495,
                20.511,
                20.526,
                20.541,
                20.556,
                20.57,
                20.584,
                20.598,
                20.611,
                20.624,
                20.636,
                20.648,
                20.66,
                20.672,
                20.683,
                20.694,
                20.705,
                20.715,
                20.725,
                20.735,
                20.745,
                20.754,
                20.764,
                20.773,
                20.782,
                20.79,
                20.799,
                20.807,
                20.815,
                20.823,
                20.831,
                20.838,
                20.846,
                20.853,
                20.86,
                20.867,
                20.874,
                20.881,
                20.888,
                20.894,
                20.9,
                20.907,
                20.913,
                20.919,
                20.925,
                20.931,
                20.936,
                20.942,
                20.947,
                20.952,
                20.958,
                20.963,
                20.967,
                20.972,
                20.977,
                20.981,
                20.986,
                20.99,
                20.995,
                20.998,
                21.003,
                21.006,
                21.01,
                21.014,
                21.017,
                21.021,
                21.024,
                21.027,
                21.031,
                21.033,
                21.036,
                21.039,
                21.041,
                21.044,
                21.047,
                21.049,
                21.051,
                21.054,
                21.055,
                21.057,
                21.059,
                21.061,
                21.063,
                21.065,
                21.066,
                21.068,
                21.069,
                21.07,
                21.072,
                21.073,
                21.074,
                21.075,
                21.076,
                21.076,
                21.078,
                21.078,
                21.079,
                21.079,
                21.079,
                21.08,
                21.08,
                21.08,
                21.08,
                21.08,
                21.08,
                21.08,
                21.08,
                21.079,
                21.079,
                21.079,
                21.078,
                21.077,
                21.077,
                21.076,
                21.075,
                21.075,
                21.073,
                21.073,
                21.071,
                21.07,
                21.069,
                21.067,
                21.066,
                21.065,
                21.063,
                21.061,
                21.06,
                21.058,
                21.056,
                21.055,
                21.052,
                21.051,
                21.048,
                21.047,
                21.044,
                21.042,
                21.039,
                21.037,
                21.035,
                21.032,
                21.03,
                21.027,
                21.025,
                21.022,
                21.019,
                21.016,
                21.013,
                21.01,
                21.007,
                21.004,
                21.001,
                20.998,
                20.995,
                20.992,
                20.988,
                20.985,
                20.982,
                20.978,
                20.975,
                20.971,
                20.968,
                20.964,
                20.96,
                20.957,
                20.953,
                20.949,
                20.945,
                20.942,
                20.938,
                20.934,
                20.93,
                20.926,
                20.921,
                20.918,
                20.914,
                20.909,
                20.905,
                20.901,
                20.897,
                20.893,
                20.888,
                20.884,
                20.88,
                20.875,
                20.871,
                20.867,
                20.862,
                20.857,
                20.853,
                20.849,
                20.844,
                20.839,
                20.835,
                20.83,
                20.826,
                20.821,
                20.816,
                20.812,
                20.807,
                20.802,
                20.797,
                20.793,
                20.788,
                20.783,
                20.778,
                20.774,
                20.768,
                20.763,
                20.759,
                20.754,
                20.749,
                20.744,
                20.739,
                20.734,
                20.729,
                20.724,
                20.719,
                20.714,
                20.709,
                20.704,
                20.699,
                20.694,
                20.689,
                20.684,
                20.679,
                20.674,
                20.669,
                20.664,
                20.659,
                20.654,
                20.649,
                20.644,
                20.639,
                20.633,
                20.628,
                20.623,
                20.618,
                20.613,
                20.608,
                20.603,
                20.598,
                20.593,
                20.587,
                20.582,
                20.577,
                20.572,
                20.567,
                20.562,
                20.557,
                20.552,
                20.546,
                20.541,
                20.536,
                20.531,
                20.526,
                20.52,
                20.515,
                20.51,
                20.505,
                20.5,
                20.495,
                20.49,
                20.484,
                20.479,
                20.474,
                20.469,
                20.464,
                20.459,
                20.454,
                20.449,
                20.444,
                20.439,
                20.433,
                20.428,
                20.423,
                20.418,
                20.413,
                20.408,
                20.403,
                20.398,
                20.393,
                20.388,
                20.382,
                20.377,
                20.372,
                20.367,
                20.362,
                20.357,
                20.352,
                20.347,
                20.342,
                20.337,
                20.331,
                20.326,
                20.321,
                20.317,
                20.312,
                20.306,
                20.301,
                20.296,
                20.291,
                20.286,
                20.281,
                20.276,
                20.271,
                20.266,
                20.261,
                20.256,
                20.251,
                20.246,
                20.241,
                20.236,
                20.231,
                20.226,
                20.222,
                20.217,
                20.212,
                20.207,
                20.202,
                20.197,
                20.192,
                20.187,
                20.182,
                20.177,
                20.172,
                20.168,
                20.163,
                20.158,
                20.153,
                20.148,
                20.143,
                20.139,
                20.134,
                20.129,
                20.124,
                20.119,
                20.114,
                20.11,
                20.105,
                20.1,
                20.095,
                20.091,
                20.086,
                20.081,
                20.076,
                20.072,
                20.067,
                20.062,
                20.057,
                20.053,
                20.048,
                20.043,
                20.039,
                20.034,
                20.03,
                20.025,
                20.02,
                20.016,
                20.011,
                20.006,
                20.001,
                19.997,
                19.993,
                19.988,
                19.983,
                19.979,
                19.974,
                19.97,
                19.965,
                19.961,
                19.956,
                19.951,
                19.947,
                19.943,
                19.938,
                19.933,
                19.929,
                19.925,
                19.92,
                19.916,
                19.911,
                19.907,
                19.902,
                19.898,
                19.894,
                19.889,
                19.885,
                19.881,
                19.876,
                19.872,
                19.867,
                19.863,
                19.859,
                19.854,
                19.85,
                19.846,
                19.841,
                19.837,
                19.833,
                19.829,
                19.824,
                19.82,
                19.816,
                19.812,
                19.808,
                19.803,
                19.799,
                19.795,
                19.791,
                19.787,
                19.782,
                19.778,
                19.774,
                19.77,
                19.766,
                19.762,
                19.757,
                19.754,
                19.749,
                19.745,
                19.741,
                19.737,
                19.733,
                19.729,
                19.725,
                19.721,
                19.717,
                19.713,
                19.709,
                19.705,
                19.701,
                19.697,
                19.693,
                19.689,
                19.685,
                19.681,
                19.677,
                19.673,
                19.67,
                19.666,
                19.661,
                19.658,
                19.654,
                19.65,
                19.646,
                19.642,
                19.638,
                19.635,
                19.631,
                19.627,
                19.623,
                19.619,
                19.616,
                19.612,
                19.608,
                19.604,
                19.601,
                19.597,
                19.593,
                19.589,
                19.585,
                19.582,
                19.578,
                19.575,
                19.571,
                19.567,
                19.564,
                19.56,
                19.556,
                19.553,
                19.549,
                19.545,
                19.542,
                19.538,
                19.535,
                19.531,
                19.527,
                19.524,
                19.521,
                19.517,
                19.514,
                19.51,
                19.507,
                19.503,
                19.499,
                19.496,
                19.493,
                19.489,
                19.486,
                19.482,
                19.479,
                19.476,
                19.472,
                19.469,
                19.465,
                19.462,
                19.459,
                19.456,
                19.452,
                19.449,
                19.446,
                19.442,
                19.439,
                19.436,
                19.432,
                19.429,
                19.426,
                19.423,
                19.419,
                19.416,
                19.413,
                19.41,
                19.407,
                19.403,
                19.4,
                19.397,
                19.394,
                19.391,
                19.388,
                19.384,
                19.382,
                19.378,
                19.376,
                19.372,
                19.37,
                19.366,
                19.363,
                19.36,
                19.357,
                19.354,
                19.351,
                19.348,
                19.345,
                19.342,
                19.34,
                19.336,
                19.334,
                19.331,
                19.327,
                19.325,
                19.322,
                19.319,
                19.316,
                19.314,
                19.311,
                19.308,
                19.305,
                19.302,
                19.299,
                19.296,
                19.294,
                19.291,
                19.288,
                19.285,
                19.283,
                19.28,
                19.277,
                19.275,
                19.272,
                19.269,
                19.266,
                19.264,
                19.261,
                19.258,
                19.256,
                19.253,
                19.251,
                19.248,
                19.246,
                19.243,
                19.241,
                19.238,
                19.235,
                19.233,
                19.23,
                19.228,
                19.225,
                19.223,
                19.22,
                19.218,
                19.215,
                19.213,
                19.21,
                19.208,
                19.205,
                19.203,
                19.2,
                19.198,
                19.196,
                19.193,
                19.191,
                19.188,
                19.186,
                19.184,
                19.181,
                19.179,
                19.177,
                19.174,
                19.172,
                19.17,
                19.168,
                19.165,
                19.163,
                19.161,
                19.158,
                19.156,
                19.154,
                19.152,
                19.149,
                19.147,
                19.145,
                19.143,
                19.14,
                19.138,
                19.136,
                19.134,
                19.131,
                19.13,
                19.127,
                19.125,
                19.123,
                19.121,
                19.119,
                19.117,
                19.114,
                19.112,
                19.11,
                19.108,
                19.106,
                19.104,
                19.102,
                19.1,
                19.098,
                19.096,
                19.093,
                19.092,
                19.089,
                19.088,
                19.085,
                19.083,
                19.081,
                19.079,
                19.077,
                19.075,
                19.073,
                19.071,
                19.411,
                19.409,
                19.407,
                19.405,
                19.404,
                19.402,
                19.4,
                19.398,
                19.396,
                19.394,
                19.392,
                19.39,
                19.388,
                19.387,
                19.384,
                19.382,
                19.38,
                19.379,
                19.377,
                19.375,
                19.373,
                19.371,
                19.369,
                19.367,
                19.365,
                19.363,
                19.362,
                19.36,
                19.358,
                19.356,
                19.355,
                19.353,
                19.351,
                19.349,
                19.347,
                19.345,
                19.343,
                19.341,
                19.34,
                19.338,
                19.336,
                19.334,
                19.333,
                19.331,
                19.329,
                19.327,
                19.325,
                19.323,
                19.321,
                19.319,
                19.318,
                19.316,
                19.314,
                19.313,
                19.311,
                19.309,
                19.307,
                19.305,
                19.303,
                19.302,
                19.3,
                19.298,
                19.296,
                19.294,
                19.293,
                19.291,
                19.289,
                19.287,
                19.286,
                19.284,
                19.282,
                19.281,
                19.279,
                19.277,
                19.275,
                19.273,
                19.272,
                19.27,
                19.268,
                19.266,
                19.264,
                19.263,
                19.261,
                19.259,
                19.257,
                19.256,
                19.254,
                19.252,
                19.251,
                19.249,
                19.247,
                19.246,
                19.244,
                19.242,
                19.24,
                19.239,
                19.237,
                19.235,
                19.234,
                19.232,
                19.23,
                19.229,
                19.227,
                19.225,
                19.224,
                19.222,
                19.22,
                19.219,
                19.217,
                19.215,
                19.214,
                19.212,
                19.21,
                19.209,
                19.207,
                19.205,
                19.204,
                19.202,
                19.2,
                19.199,
                19.197,
                19.195,
                19.194,
                19.192,
                19.19,
                19.189,
                19.187,
                19.185,
                19.184,
                19.182,
                19.18,
                19.179,
                19.177,
                19.175,
                19.174,
                19.172,
                19.171,
                19.169,
                19.167,
                19.166,
                19.164,
                19.163,
                19.161,
                19.16,
                19.158,
                19.156,
                19.155,
                19.153,
                19.152,
                19.15,
                19.149,
                19.147,
                19.145,
                19.144,
                19.142,
                19.141,
                19.139,
                19.137,
                19.136,
                19.134,
                19.132,
                19.131,
                19.13,
                19.128,
                19.126,
                19.125,
                19.124,
                19.122,
                19.12,
                19.119,
                19.117,
                19.116,
                19.114,
                19.112,
                19.111,
                19.109,
                19.108,
                19.107,
                19.105,
                19.104,
                19.102,
                19.101,
                19.099,
                19.097,
                19.096,
                19.094,
                19.093,
                19.091,
                19.09,
                19.089,
                19.087,
                19.086,
                19.084,
                19.082,
                19.081,
                19.079,
                19.078,
                19.076,
                19.075,
                19.074,
                19.072,
                19.071,
                19.069,
                19.068,
                19.066,
                19.065,
                19.063,
                19.062,
                19.061,
                19.059,
                19.058,
                19.056,
                19.054,
                19.053,
                19.052,
                19.051,
                19.049,
                19.048,
                19.046,
                19.045,
                19.043,
                19.042,
                19.04,
                19.039,
                19.038,
                19.036,
                19.035,
                19.033,
                19.032,
                19.03,
                19.029,
                19.028,
                19.026,
                19.025,
                19.023,
                19.022,
                19.021,
                19.02,
                19.018,
                19.016,
                19.015,
                19.014,
                19.013,
                19.011,
                19.01,
                19.008,
                19.007,
                19.005,
                19.004,
                19.003,
                19.002,
                19,
                18.999,
                18.998,
                18.996,
                18.995,
                18.993,
                18.992,
                18.991,
                18.989,
                18.988,
                18.987,
                18.985,
                18.984,
                18.983,
                18.982,
                18.98,
                18.979,
                18.977,
                18.976,
                18.975,
                18.974,
                18.972,
                18.971,
                18.97,
                18.969,
                18.967,
                18.966,
                18.964,
                18.963,
                18.962,
                18.961,
                18.959,
                18.958,
                18.957,
                18.956,
                18.954,
                18.953,
                18.952,
                18.951,
                18.949,
                18.948,
                18.947,
                18.946,
                18.944,
                18.943,
                18.942,
                18.941,
                18.939,
                18.938,
                18.937,
                18.936,
                18.934,
                18.933,
                18.932,
                18.931,
                18.929,
                18.928,
                18.927,
                18.926,
                18.924,
                18.923,
                18.922,
                18.921,
                18.92,
                18.919,
                18.918,
                18.916,
                18.915,
                18.914,
                18.913,
                18.911,
                18.91,
                18.909,
                18.908,
                18.907,
                18.906,
                18.905,
                18.903,
                18.902,
                18.901,
                18.9,
                18.899,
                18.898,
                18.896,
                18.895,
                18.894,
                18.893,
                18.892,
                18.891,
                18.89,
                18.888,
                18.887,
                18.886,
                18.885,
                18.884,
                18.883,
                18.882,
                18.881,
                18.88,
                18.878,
                18.877,
                18.877,
                18.875,
                18.874,
                18.873,
                18.872,
                18.871,
                18.87,
                18.869,
                18.868,
                18.867,
                18.865,
                18.865,
                18.864,
                18.862,
                18.862,
                18.86,
                18.859,
                18.858,
                18.857,
                18.856,
                18.855,
                18.854,
                18.853,
                18.852,
                18.851,
                18.85,
                18.849,
                18.848,
                18.847,
                18.846,
                18.845,
                18.844,
                18.843,
                18.842,
                18.841,
                18.84,
                18.839,
                18.838,
                18.837,
                18.836,
                18.835,
                18.834,
                18.833,
                18.832,
                18.831,
                18.831,
                18.829,
                18.829,
                18.828,
                18.826,
                18.826,
                18.824,
                18.824,
                18.823,
                18.822,
                18.821,
                18.82,
                18.819,
                18.818,
                18.818,
                18.816,
                18.815,
                18.815,
                18.814,
                18.813,
                18.812,
                18.811,
                18.81,
                18.81,
                18.808,
                18.808,
                18.806,
                18.806,
                18.805,
                18.804,
                18.803,
                18.802,
                18.802,
                18.801,
                18.8,
                18.799,
                18.798,
                18.797,
                18.797,
                18.796,
                18.795,
                18.794,
                18.793,
                18.793,
                18.792,
                18.791,
                18.79,
                18.789,
                18.788,
                18.788,
                18.787,
                18.786,
                18.785,
                18.785,
                18.784,
                18.783,
                18.782,
                18.782,
                18.781,
                18.78,
                18.779,
                18.779,
                18.778,
                18.777,
                18.776,
                18.775,
                18.775,
                18.774,
                18.774,
                18.773,
                18.772,
                18.771,
                18.77,
                18.77,
                18.769,
                18.769,
                18.768,
                18.767,
                18.767,
                18.766,
                18.765,
                18.764,
                18.764,
                18.763,
                18.762,
                18.762,
                18.761,
                18.761,
                18.76,
                18.759,
                18.759,
                18.758,
                18.757,
                18.757,
                18.756,
                18.755,
                18.755,
                18.754,
                18.754,
                18.753,
                18.752,
                18.752,
                18.751,
                18.751,
                18.75,
                18.749,
                18.749,
                18.748,
                18.748,
                18.747,
                18.746,
                18.746,
                18.745,
                18.745,
                18.744,
                18.744,
                18.743,
                18.743,
                18.742,
                18.741,
                18.741,
                18.741,
                18.74,
                18.739,
                18.739,
                18.738,
                18.738,
                18.738,
                18.737,
                18.736,
                18.736,
                18.736,
                18.735,
                18.734,
                18.734,
                18.733,
                18.733,
                18.733,
                18.732,
                18.731,
                18.731,
                18.731,
                18.73,
                18.73,
                18.73,
                18.729,
                18.728,
                18.728,
                18.728,
                18.727,
                18.727,
                18.726,
                18.726,
                18.726,
                18.725,
                18.725,
                18.724,
                18.724,
                18.723,
                18.723,
                18.723,
                18.722,
                18.722,
                18.722,
                18.721,
                18.721,
                18.72,
                18.72,
                18.72,
                18.719,
                18.719,
                18.718,
                18.718,
                18.718,
                18.718,
                18.717,
                18.717,
                18.717,
                18.717,
                18.716,
                18.716,
                18.715,
                18.715,
                18.715,
                18.714,
                18.714,
                18.714,
                18.714,
                18.713,
                18.713,
                18.712,
                18.712,
                18.712,
                18.712,
                18.712,
                18.711,
                18.711,
                18.711,
                18.71,
                18.71,
                18.71,
                18.71,
                18.709,
                18.709,
                18.709,
                18.709,
                18.709,
                18.708,
                18.708,
                18.708,
                18.707,
                18.707,
                18.707,
                18.707,
                18.707,
                18.706,
                18.706,
                18.706,
                18.706,
                18.706,
                18.705,
                18.705,
                18.705,
                18.705,
                18.705,
                18.705,
                18.704,
                18.704,
                18.704,
                18.704,
                18.703,
                18.703,
                18.703,
                18.703,
                18.703,
                18.703,
                18.703,
                18.703,
                18.702,
                18.702,
                18.702,
                18.702,
                18.702,
                18.702,
                18.702,
                18.702,
                18.702,
                18.701,
                18.701,
                18.701,
                18.701,
                18.701,
                18.701,
                18.701,
                18.701,
                18.701,
                18.701,
                18.701,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.699,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.7,
                18.701,
                18.701,
                18.701,
                18.701,
                18.701,
                18.701,
                18.701,
                18.701,
                18.702,
                18.702,
                18.702,
                18.702,
                18.702,
                18.702,
                18.702,
                18.702,
                18.703,
                18.703,
                18.703,
                18.703,
                18.703,
                18.703,
                18.704,
                18.704,
                18.704,
                18.704,
                18.704,
                18.704,
                18.705,
                18.705,
                18.705,
                18.705,
                18.705,
                18.705,
                18.705,
                18.705,
                18.706,
                18.706,
                18.706,
                18.706,
                18.707,
                18.707,
                18.707,
                18.707,
                18.708,
                18.708,
                18.708,
                18.708,
                18.708,
                18.708,
                18.709,
                18.709,
                18.709,
                18.709,
                18.709,
                18.71,
                18.71,
                18.71,
                18.711,
                18.711,
                18.711,
                18.711,
                18.711,
                18.712,
                18.712,
                18.712,
                18.712,
                18.713,
                18.713,
                18.713,
                18.714,
                18.714,
                18.714,
                18.714,
                18.714,
                18.715,
                18.715,
                18.715,
                18.716,
                18.716,
                18.717,
                18.717,
                18.717,
                18.717,
                18.717,
                18.718,
                18.718,
                18.718,
                18.719,
                18.719,
                18.719,
                18.72,
                18.72,
                18.72,
                18.72,
                18.721,
                18.721,
                18.722,
                18.722,
                18.722,
                18.722,
                18.723,
                18.723,
                18.724,
                18.724,
                18.724,
                18.725,
                18.725,
                18.725,
                18.726,
                18.726,
                18.727,
                18.727,
                18.727,
                18.727,
                18.728,
                18.728,
                18.729,
                18.729,
                18.729,
                18.73,
                18.73,
                18.73,
                18.731,
                18.731,
                18.732,
                18.732,
                18.732,
                18.733,
                18.733,
                18.734,
                18.734,
                18.734,
                18.735,
                18.735,
                18.736,
                18.736,
                18.737,
                18.737,
                18.737,
                18.738,
                18.738,
                18.739,
                18.739,
                18.739,
                18.74,
                18.741,
                18.741,
                18.741,
                18.742,
                18.742,
                18.743,
                18.743,
                18.743,
                18.744,
                18.744,
                18.745,
                18.745,
                18.746,
                18.746,
                18.747,
                18.747,
                18.748,
                18.748,
                18.748,
                18.749,
                18.75,
                18.75,
                18.75,
                18.751,
                18.752,
                18.752,
                18.752,
                18.753,
                18.754,
                18.754,
                18.754,
                18.755,
                18.756,
                18.756,
                18.756,
                18.757,
                18.757,
                18.758,
                18.759,
                18.759,
                18.76,
                18.76,
                18.761,
                18.761,
                18.762,
                18.763,
                18.763,
                18.763,
                18.764,
                18.765,
                18.765,
                18.765,
                18.766,
                18.767,
                18.767,
                18.768,
                18.768,
                18.769,
                18.769,
                18.77,
                18.771,
                18.771,
                18.771,
                18.772,
                18.773,
                18.773,
                18.774,
                18.774,
                18.775,
                18.776,
                18.776,
                18.777,
                18.777,
                18.778,
                18.778,
                18.779,
                18.78,
                18.78,
                18.781,
                18.782,
                18.782,
                18.783,
                18.783,
                18.784,
                18.784,
                18.785,
                18.786,
                18.786,
                18.787,
                18.788,
                18.788,
                18.789,
                18.79,
                18.79,
                18.791,
                18.792,
                18.792,
                18.793,
                18.793,
                18.794,
                18.794,
                18.795,
                18.796,
                18.796,
                18.797,
                18.798,
                18.798,
                18.799,
                18.8,
                18.8,
                18.801,
                18.802,
                18.802,
                18.803,
                18.804,
                18.804,
                18.805,
                18.806,
                18.806,
                18.807,
                18.808,
                18.809,
                18.809,
                18.81,
                18.81,
                18.811,
                18.812,
                18.813,
                18.813,
                18.814,
                18.815,
                18.815,
                18.816,
                18.817,
                18.817,
                18.818,
                18.819,
                18.819,
                18.82,
                18.821,
                18.822,
                18.823,
                18.823,
                18.824,
                18.825,
                18.825,
                18.826,
                18.827,
                18.827,
                18.828,
                18.829,
                18.83,
                18.83,
                18.831,
                18.832,
                18.833,
                18.834,
                18.834,
                18.835,
                18.836,
                18.836,
                18.837,
                18.838,
                18.839,
                18.84,
                18.84,
                18.841,
                18.842,
                18.843,
                18.843,
                18.844,
                18.845,
                18.846,
                18.847,
                18.847,
                18.848,
                18.849,
                18.85,
                18.85,
                18.851,
                18.852,
                18.853,
                18.854,
                18.855,
                18.856,
                18.856,
                18.857,
                18.858,
                18.859,
                18.859,
                18.86,
                18.861,
                18.862,
                18.863,
                18.864,
                18.864,
                18.865,
                18.866,
                18.867,
                18.868,
                18.869,
                18.87,
                18.87,
                18.871,
                18.872,
                18.873,
                18.874,
                18.875,
                18.875,
                18.876,
                18.877,
                18.878,
                18.879,
                18.88,
                18.881,
                18.882,
                18.882,
                18.883,
                18.884,
                18.885,
                18.886,
                18.887,
                18.888,
                18.888,
                18.89,
                18.89,
                18.891,
                18.892,
                18.893,
                18.894,
                18.895,
                18.896,
                18.897,
                18.898,
                18.898,
                18.9,
                18.9,
                18.901,
                18.902,
                18.903,
                18.904,
                18.905,
                18.906,
                18.907,
                18.908,
                18.909,
                18.91,
                18.911,
                18.911,
                18.913,
                18.913,
                18.914,
                18.915,
                18.916,
                18.917,
                18.918,
                18.919
            ],
            P999: [
                18.272,
                18.177,
                18.086,
                18,
                17.916,
                17.835,
                17.757,
                17.682,
                17.723,
                17.764,
                17.805,
                17.846,
                17.886,
                17.927,
                17.966,
                18.06,
                18.159,
                18.261,
                18.364,
                18.467,
                18.569,
                18.671,
                18.772,
                18.871,
                18.969,
                19.065,
                19.159,
                19.252,
                19.342,
                19.431,
                19.518,
                19.602,
                19.684,
                19.763,
                19.84,
                19.915,
                19.987,
                20.058,
                20.126,
                20.192,
                20.256,
                20.318,
                20.378,
                20.437,
                20.494,
                20.55,
                20.604,
                20.656,
                20.707,
                20.757,
                20.805,
                20.852,
                20.898,
                20.942,
                20.986,
                21.028,
                21.07,
                21.109,
                21.148,
                21.186,
                21.223,
                21.259,
                21.294,
                21.327,
                21.361,
                21.392,
                21.423,
                21.453,
                21.482,
                21.51,
                21.538,
                21.565,
                21.59,
                21.615,
                21.64,
                21.663,
                21.686,
                21.708,
                21.73,
                21.751,
                21.771,
                21.791,
                21.811,
                21.829,
                21.848,
                21.866,
                21.883,
                21.9,
                21.916,
                21.932,
                21.947,
                21.962,
                21.977,
                21.991,
                22.005,
                22.018,
                22.032,
                22.045,
                22.057,
                22.069,
                22.081,
                22.093,
                22.104,
                22.115,
                22.126,
                22.136,
                22.146,
                22.156,
                22.167,
                22.176,
                22.186,
                22.195,
                22.204,
                22.213,
                22.221,
                22.23,
                22.238,
                22.247,
                22.255,
                22.262,
                22.27,
                22.278,
                22.285,
                22.293,
                22.299,
                22.306,
                22.313,
                22.32,
                22.327,
                22.333,
                22.34,
                22.346,
                22.352,
                22.358,
                22.364,
                22.37,
                22.375,
                22.381,
                22.386,
                22.391,
                22.396,
                22.402,
                22.406,
                22.411,
                22.415,
                22.42,
                22.424,
                22.429,
                22.433,
                22.437,
                22.441,
                22.444,
                22.448,
                22.452,
                22.455,
                22.458,
                22.462,
                22.464,
                22.467,
                22.47,
                22.473,
                22.476,
                22.479,
                22.481,
                22.483,
                22.485,
                22.488,
                22.49,
                22.492,
                22.494,
                22.496,
                22.497,
                22.499,
                22.5,
                22.502,
                22.503,
                22.504,
                22.505,
                22.506,
                22.508,
                22.509,
                22.51,
                22.51,
                22.511,
                22.511,
                22.512,
                22.513,
                22.513,
                22.513,
                22.513,
                22.513,
                22.513,
                22.513,
                22.513,
                22.513,
                22.512,
                22.511,
                22.512,
                22.511,
                22.51,
                22.51,
                22.508,
                22.508,
                22.507,
                22.505,
                22.505,
                22.503,
                22.502,
                22.501,
                22.499,
                22.498,
                22.496,
                22.495,
                22.493,
                22.491,
                22.489,
                22.488,
                22.485,
                22.484,
                22.481,
                22.479,
                22.477,
                22.475,
                22.472,
                22.47,
                22.467,
                22.464,
                22.462,
                22.459,
                22.457,
                22.453,
                22.451,
                22.447,
                22.445,
                22.442,
                22.438,
                22.435,
                22.432,
                22.429,
                22.426,
                22.423,
                22.419,
                22.415,
                22.412,
                22.408,
                22.405,
                22.401,
                22.397,
                22.394,
                22.39,
                22.386,
                22.382,
                22.378,
                22.374,
                22.37,
                22.366,
                22.362,
                22.357,
                22.354,
                22.349,
                22.345,
                22.341,
                22.336,
                22.332,
                22.328,
                22.324,
                22.319,
                22.315,
                22.31,
                22.306,
                22.302,
                22.296,
                22.292,
                22.287,
                22.283,
                22.278,
                22.273,
                22.268,
                22.264,
                22.259,
                22.254,
                22.25,
                22.245,
                22.239,
                22.235,
                22.23,
                22.225,
                22.22,
                22.215,
                22.21,
                22.205,
                22.2,
                22.195,
                22.19,
                22.185,
                22.18,
                22.175,
                22.17,
                22.165,
                22.16,
                22.155,
                22.149,
                22.144,
                22.139,
                22.133,
                22.128,
                22.123,
                22.118,
                22.113,
                22.107,
                22.102,
                22.097,
                22.092,
                22.087,
                22.081,
                22.076,
                22.071,
                22.066,
                22.06,
                22.055,
                22.05,
                22.044,
                22.039,
                22.034,
                22.028,
                22.023,
                22.018,
                22.012,
                22.007,
                22.002,
                21.996,
                21.991,
                21.986,
                21.98,
                21.975,
                21.97,
                21.964,
                21.959,
                21.954,
                21.948,
                21.943,
                21.937,
                21.932,
                21.927,
                21.921,
                21.916,
                21.91,
                21.905,
                21.9,
                21.894,
                21.889,
                21.884,
                21.879,
                21.873,
                21.868,
                21.863,
                21.857,
                21.852,
                21.847,
                21.841,
                21.836,
                21.83,
                21.825,
                21.82,
                21.815,
                21.81,
                21.804,
                21.799,
                21.793,
                21.788,
                21.783,
                21.777,
                21.773,
                21.767,
                21.762,
                21.757,
                21.751,
                21.746,
                21.74,
                21.735,
                21.73,
                21.725,
                21.72,
                21.714,
                21.709,
                21.704,
                21.698,
                21.694,
                21.688,
                21.683,
                21.678,
                21.672,
                21.667,
                21.662,
                21.657,
                21.652,
                21.646,
                21.641,
                21.636,
                21.631,
                21.626,
                21.621,
                21.615,
                21.61,
                21.605,
                21.6,
                21.595,
                21.59,
                21.585,
                21.579,
                21.575,
                21.57,
                21.564,
                21.559,
                21.554,
                21.549,
                21.544,
                21.539,
                21.534,
                21.529,
                21.524,
                21.519,
                21.514,
                21.509,
                21.504,
                21.499,
                21.494,
                21.489,
                21.484,
                21.479,
                21.474,
                21.469,
                21.464,
                21.459,
                21.455,
                21.45,
                21.445,
                21.44,
                21.435,
                21.43,
                21.425,
                21.42,
                21.416,
                21.411,
                21.406,
                21.401,
                21.397,
                21.392,
                21.387,
                21.382,
                21.378,
                21.373,
                21.368,
                21.363,
                21.358,
                21.354,
                21.349,
                21.344,
                21.34,
                21.335,
                21.33,
                21.325,
                21.321,
                21.316,
                21.311,
                21.307,
                21.302,
                21.297,
                21.292,
                21.288,
                21.284,
                21.279,
                21.275,
                21.27,
                21.265,
                21.26,
                21.256,
                21.252,
                21.247,
                21.243,
                21.238,
                21.234,
                21.229,
                21.225,
                21.22,
                21.216,
                21.212,
                21.207,
                21.202,
                21.198,
                21.194,
                21.189,
                21.185,
                21.181,
                21.176,
                21.172,
                21.168,
                21.163,
                21.159,
                21.155,
                21.15,
                21.146,
                21.142,
                21.137,
                21.133,
                21.129,
                21.125,
                21.12,
                21.116,
                21.112,
                21.107,
                21.104,
                21.099,
                21.095,
                21.091,
                21.087,
                21.082,
                21.079,
                21.074,
                21.07,
                21.066,
                21.062,
                21.057,
                21.054,
                21.05,
                21.045,
                21.042,
                21.037,
                21.033,
                21.029,
                21.025,
                21.021,
                21.017,
                21.013,
                21.009,
                21.005,
                21.001,
                20.997,
                20.993,
                20.989,
                20.986,
                20.981,
                20.977,
                20.974,
                20.97,
                20.966,
                20.962,
                20.958,
                20.955,
                20.951,
                20.946,
                20.943,
                20.939,
                20.935,
                20.932,
                20.928,
                20.923,
                20.92,
                20.916,
                20.913,
                20.909,
                20.905,
                20.902,
                20.898,
                20.894,
                20.89,
                20.886,
                20.883,
                20.879,
                20.875,
                20.872,
                20.868,
                20.865,
                20.861,
                20.857,
                20.854,
                20.851,
                20.847,
                20.843,
                20.84,
                20.837,
                20.833,
                20.83,
                20.826,
                20.822,
                20.819,
                20.816,
                20.813,
                20.809,
                20.805,
                20.802,
                20.798,
                20.795,
                20.792,
                20.788,
                20.785,
                20.782,
                20.779,
                20.775,
                20.771,
                20.769,
                20.765,
                20.762,
                20.759,
                20.755,
                20.752,
                20.749,
                20.746,
                20.742,
                20.74,
                20.736,
                20.733,
                20.73,
                20.727,
                20.724,
                20.72,
                20.718,
                20.714,
                20.711,
                20.708,
                20.705,
                20.702,
                20.699,
                20.695,
                20.693,
                20.689,
                20.687,
                20.684,
                20.681,
                20.678,
                20.675,
                20.672,
                20.669,
                20.666,
                20.663,
                20.661,
                20.657,
                20.655,
                20.652,
                20.648,
                20.646,
                20.643,
                20.64,
                20.637,
                20.635,
                20.632,
                20.629,
                20.626,
                20.623,
                20.621,
                20.618,
                20.616,
                20.612,
                20.61,
                20.607,
                20.605,
                20.602,
                20.6,
                20.597,
                20.594,
                20.591,
                20.588,
                20.586,
                20.583,
                20.581,
                20.578,
                20.576,
                20.573,
                20.571,
                20.568,
                20.566,
                20.563,
                20.56,
                20.558,
                20.555,
                20.553,
                20.55,
                20.548,
                20.545,
                20.543,
                20.54,
                20.538,
                20.536,
                20.533,
                20.531,
                20.529,
                20.526,
                20.523,
                20.521,
                20.519,
                20.517,
                20.514,
                20.512,
                20.509,
                20.507,
                20.504,
                20.503,
                20.5,
                20.498,
                20.495,
                20.493,
                20.491,
                20.489,
                20.486,
                20.484,
                20.482,
                20.48,
                20.477,
                20.475,
                20.473,
                20.471,
                20.468,
                20.467,
                20.464,
                20.462,
                20.46,
                20.458,
                20.456,
                20.454,
                20.451,
                20.449,
                20.447,
                20.445,
                20.443,
                20.441,
                20.438,
                20.437,
                20.434,
                20.433,
                20.43,
                20.428,
                20.785,
                20.783,
                20.78,
                20.778,
                20.776,
                20.773,
                20.771,
                20.768,
                20.766,
                20.764,
                20.761,
                20.759,
                20.756,
                20.754,
                20.752,
                20.749,
                20.747,
                20.745,
                20.742,
                20.74,
                20.737,
                20.735,
                20.733,
                20.73,
                20.728,
                20.725,
                20.724,
                20.721,
                20.719,
                20.716,
                20.714,
                20.712,
                20.709,
                20.707,
                20.705,
                20.703,
                20.7,
                20.698,
                20.696,
                20.693,
                20.691,
                20.688,
                20.687,
                20.684,
                20.682,
                20.679,
                20.678,
                20.675,
                20.673,
                20.67,
                20.668,
                20.666,
                20.664,
                20.662,
                20.659,
                20.657,
                20.654,
                20.653,
                20.65,
                20.648,
                20.645,
                20.644,
                20.641,
                20.639,
                20.637,
                20.635,
                20.632,
                20.63,
                20.628,
                20.626,
                20.624,
                20.622,
                20.619,
                20.617,
                20.615,
                20.613,
                20.611,
                20.608,
                20.607,
                20.604,
                20.602,
                20.6,
                20.598,
                20.596,
                20.593,
                20.592,
                20.589,
                20.587,
                20.585,
                20.583,
                20.58,
                20.579,
                20.577,
                20.574,
                20.572,
                20.57,
                20.568,
                20.566,
                20.564,
                20.562,
                20.559,
                20.558,
                20.555,
                20.553,
                20.551,
                20.549,
                20.547,
                20.545,
                20.543,
                20.541,
                20.539,
                20.537,
                20.535,
                20.533,
                20.531,
                20.528,
                20.527,
                20.524,
                20.522,
                20.521,
                20.518,
                20.516,
                20.515,
                20.512,
                20.51,
                20.509,
                20.506,
                20.504,
                20.503,
                20.5,
                20.498,
                20.497,
                20.494,
                20.492,
                20.491,
                20.488,
                20.487,
                20.485,
                20.482,
                20.481,
                20.479,
                20.476,
                20.475,
                20.473,
                20.471,
                20.469,
                20.467,
                20.465,
                20.463,
                20.461,
                20.46,
                20.457,
                20.455,
                20.454,
                20.452,
                20.45,
                20.448,
                20.446,
                20.444,
                20.442,
                20.44,
                20.439,
                20.436,
                20.435,
                20.433,
                20.431,
                20.429,
                20.427,
                20.425,
                20.423,
                20.421,
                20.42,
                20.418,
                20.416,
                20.414,
                20.412,
                20.411,
                20.409,
                20.407,
                20.405,
                20.403,
                20.402,
                20.4,
                20.398,
                20.396,
                20.394,
                20.393,
                20.391,
                20.388,
                20.387,
                20.385,
                20.384,
                20.382,
                20.379,
                20.378,
                20.376,
                20.375,
                20.373,
                20.371,
                20.369,
                20.367,
                20.366,
                20.364,
                20.362,
                20.36,
                20.358,
                20.357,
                20.355,
                20.354,
                20.352,
                20.35,
                20.348,
                20.346,
                20.345,
                20.343,
                20.342,
                20.34,
                20.338,
                20.336,
                20.335,
                20.333,
                20.331,
                20.33,
                20.328,
                20.327,
                20.324,
                20.323,
                20.321,
                20.32,
                20.318,
                20.316,
                20.315,
                20.313,
                20.312,
                20.31,
                20.308,
                20.306,
                20.305,
                20.303,
                20.301,
                20.3,
                20.298,
                20.297,
                20.295,
                20.294,
                20.292,
                20.291,
                20.289,
                20.287,
                20.286,
                20.284,
                20.283,
                20.281,
                20.279,
                20.278,
                20.276,
                20.274,
                20.273,
                20.271,
                20.27,
                20.268,
                20.267,
                20.265,
                20.264,
                20.262,
                20.261,
                20.259,
                20.258,
                20.256,
                20.255,
                20.253,
                20.252,
                20.25,
                20.249,
                20.247,
                20.246,
                20.244,
                20.243,
                20.241,
                20.24,
                20.238,
                20.237,
                20.235,
                20.234,
                20.233,
                20.231,
                20.23,
                20.229,
                20.227,
                20.226,
                20.224,
                20.223,
                20.221,
                20.22,
                20.218,
                20.217,
                20.215,
                20.214,
                20.212,
                20.211,
                20.21,
                20.208,
                20.207,
                20.206,
                20.204,
                20.203,
                20.201,
                20.2,
                20.198,
                20.197,
                20.196,
                20.195,
                20.193,
                20.192,
                20.191,
                20.189,
                20.188,
                20.186,
                20.185,
                20.184,
                20.183,
                20.181,
                20.18,
                20.178,
                20.177,
                20.176,
                20.175,
                20.174,
                20.172,
                20.171,
                20.169,
                20.169,
                20.167,
                20.166,
                20.164,
                20.163,
                20.162,
                20.161,
                20.16,
                20.158,
                20.157,
                20.156,
                20.155,
                20.153,
                20.152,
                20.151,
                20.15,
                20.149,
                20.147,
                20.146,
                20.145,
                20.144,
                20.143,
                20.141,
                20.14,
                20.139,
                20.138,
                20.136,
                20.135,
                20.135,
                20.133,
                20.132,
                20.131,
                20.13,
                20.129,
                20.127,
                20.127,
                20.125,
                20.124,
                20.123,
                20.122,
                20.121,
                20.119,
                20.119,
                20.117,
                20.116,
                20.115,
                20.114,
                20.113,
                20.112,
                20.111,
                20.11,
                20.108,
                20.108,
                20.107,
                20.105,
                20.105,
                20.103,
                20.102,
                20.102,
                20.1,
                20.1,
                20.099,
                20.097,
                20.097,
                20.095,
                20.094,
                20.094,
                20.092,
                20.092,
                20.091,
                20.089,
                20.089,
                20.087,
                20.086,
                20.086,
                20.084,
                20.084,
                20.083,
                20.082,
                20.081,
                20.08,
                20.079,
                20.078,
                20.078,
                20.076,
                20.076,
                20.075,
                20.073,
                20.073,
                20.072,
                20.071,
                20.07,
                20.069,
                20.068,
                20.068,
                20.066,
                20.066,
                20.065,
                20.064,
                20.063,
                20.063,
                20.062,
                20.061,
                20.06,
                20.059,
                20.058,
                20.058,
                20.057,
                20.056,
                20.055,
                20.055,
                20.053,
                20.053,
                20.052,
                20.051,
                20.051,
                20.05,
                20.049,
                20.048,
                20.048,
                20.047,
                20.046,
                20.045,
                20.045,
                20.044,
                20.043,
                20.043,
                20.042,
                20.041,
                20.04,
                20.04,
                20.039,
                20.038,
                20.038,
                20.037,
                20.036,
                20.036,
                20.035,
                20.034,
                20.034,
                20.033,
                20.033,
                20.032,
                20.031,
                20.031,
                20.03,
                20.029,
                20.029,
                20.028,
                20.028,
                20.027,
                20.027,
                20.026,
                20.026,
                20.025,
                20.024,
                20.024,
                20.024,
                20.023,
                20.022,
                20.022,
                20.021,
                20.02,
                20.02,
                20.02,
                20.019,
                20.018,
                20.018,
                20.018,
                20.017,
                20.016,
                20.016,
                20.016,
                20.015,
                20.015,
                20.014,
                20.014,
                20.013,
                20.013,
                20.013,
                20.012,
                20.012,
                20.011,
                20.011,
                20.01,
                20.01,
                20.01,
                20.009,
                20.009,
                20.008,
                20.008,
                20.008,
                20.007,
                20.007,
                20.006,
                20.006,
                20.006,
                20.006,
                20.005,
                20.004,
                20.004,
                20.004,
                20.004,
                20.004,
                20.003,
                20.003,
                20.002,
                20.002,
                20.002,
                20.002,
                20.002,
                20.001,
                20.001,
                20.001,
                20,
                20,
                20,
                19.999,
                19.999,
                19.999,
                19.999,
                19.999,
                19.999,
                19.998,
                19.998,
                19.997,
                19.997,
                19.997,
                19.997,
                19.997,
                19.996,
                19.996,
                19.996,
                19.996,
                19.996,
                19.996,
                19.996,
                19.995,
                19.995,
                19.995,
                19.995,
                19.995,
                19.995,
                19.995,
                19.995,
                19.994,
                19.994,
                19.994,
                19.994,
                19.994,
                19.994,
                19.994,
                19.994,
                19.994,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.993,
                19.994,
                19.994,
                19.994,
                19.994,
                19.994,
                19.994,
                19.994,
                19.994,
                19.994,
                19.994,
                19.994,
                19.994,
                19.994,
                19.995,
                19.995,
                19.995,
                19.995,
                19.995,
                19.995,
                19.996,
                19.996,
                19.996,
                19.996,
                19.996,
                19.996,
                19.997,
                19.997,
                19.997,
                19.997,
                19.997,
                19.998,
                19.998,
                19.998,
                19.998,
                19.998,
                19.999,
                19.999,
                19.999,
                19.999,
                20,
                20,
                20,
                20,
                20.001,
                20.001,
                20.001,
                20.001,
                20.002,
                20.002,
                20.002,
                20.002,
                20.003,
                20.003,
                20.003,
                20.003,
                20.004,
                20.004,
                20.004,
                20.005,
                20.005,
                20.005,
                20.006,
                20.006,
                20.006,
                20.006,
                20.007,
                20.007,
                20.007,
                20.008,
                20.008,
                20.008,
                20.009,
                20.009,
                20.009,
                20.01,
                20.01,
                20.011,
                20.011,
                20.012,
                20.012,
                20.012,
                20.013,
                20.013,
                20.013,
                20.014,
                20.014,
                20.015,
                20.015,
                20.015,
                20.016,
                20.016,
                20.017,
                20.017,
                20.018,
                20.018,
                20.019,
                20.019,
                20.02,
                20.02,
                20.02,
                20.021,
                20.021,
                20.022,
                20.022,
                20.022,
                20.024,
                20.024,
                20.025,
                20.025,
                20.025,
                20.026,
                20.026,
                20.027,
                20.027,
                20.028,
                20.028,
                20.029,
                20.03,
                20.03,
                20.031,
                20.031,
                20.032,
                20.032,
                20.032,
                20.033,
                20.034,
                20.035,
                20.035,
                20.036,
                20.036,
                20.037,
                20.037,
                20.038,
                20.039,
                20.039,
                20.04,
                20.04,
                20.041,
                20.041,
                20.042,
                20.042,
                20.044,
                20.044,
                20.045,
                20.045,
                20.046,
                20.046,
                20.047,
                20.048,
                20.049,
                20.049,
                20.05,
                20.05,
                20.051,
                20.052,
                20.053,
                20.053,
                20.054,
                20.055,
                20.055,
                20.056,
                20.056,
                20.058,
                20.058,
                20.059,
                20.059,
                20.06,
                20.061,
                20.062,
                20.062,
                20.063,
                20.064,
                20.064,
                20.065,
                20.066,
                20.067,
                20.068,
                20.068,
                20.069,
                20.07,
                20.071,
                20.072,
                20.072,
                20.073,
                20.074,
                20.075,
                20.076,
                20.076,
                20.077,
                20.078,
                20.078,
                20.08,
                20.08,
                20.081,
                20.082,
                20.082,
                20.084,
                20.084,
                20.085,
                20.086,
                20.086,
                20.088,
                20.089,
                20.089,
                20.09,
                20.091,
                20.092,
                20.093,
                20.094,
                20.094,
                20.095,
                20.097,
                20.097,
                20.098,
                20.099,
                20.099,
                20.101,
                20.102,
                20.102,
                20.103,
                20.105,
                20.105,
                20.106,
                20.107,
                20.108,
                20.109,
                20.11,
                20.111,
                20.111,
                20.113,
                20.114,
                20.114,
                20.115,
                20.116,
                20.118,
                20.118,
                20.119,
                20.12,
                20.122,
                20.123,
                20.123,
                20.124,
                20.126,
                20.126,
                20.127,
                20.128,
                20.129,
                20.13,
                20.131,
                20.132,
                20.133,
                20.135,
                20.135,
                20.136,
                20.137,
                20.139,
                20.14,
                20.14,
                20.141,
                20.143,
                20.144,
                20.145,
                20.146,
                20.147,
                20.148,
                20.149,
                20.15,
                20.151,
                20.152,
                20.153,
                20.154,
                20.156,
                20.157,
                20.158,
                20.159,
                20.16,
                20.161,
                20.162,
                20.164,
                20.165,
                20.165,
                20.166,
                20.168,
                20.169,
                20.17,
                20.171,
                20.172,
                20.173,
                20.174,
                20.176,
                20.177,
                20.178,
                20.179,
                20.181,
                20.182,
                20.183,
                20.184,
                20.185,
                20.186,
                20.187,
                20.189,
                20.19,
                20.191,
                20.193,
                20.194,
                20.195,
                20.196,
                20.197,
                20.198,
                20.199,
                20.201,
                20.202,
                20.203,
                20.204,
                20.206,
                20.207,
                20.208,
                20.21,
                20.211,
                20.212,
                20.213,
                20.214,
                20.215,
                20.217,
                20.218,
                20.219,
                20.22,
                20.222,
                20.223,
                20.224,
                20.226,
                20.227,
                20.228,
                20.229,
                20.231,
                20.232,
                20.233,
                20.235,
                20.236,
                20.237,
                20.239,
                20.24,
                20.241,
                20.243,
                20.244,
                20.245,
                20.247,
                20.248,
                20.249,
                20.25,
                20.252,
                20.253,
                20.255,
                20.256,
                20.258,
                20.259,
                20.261,
                20.262,
                20.263,
                20.265,
                20.266,
                20.267,
                20.269,
                20.27,
                20.271,
                20.273,
                20.274,
                20.275,
                20.277,
                20.278,
                20.28,
                20.282,
                20.283,
                20.284,
                20.286,
                20.287,
                20.288,
                20.29,
                20.291,
                20.293,
                20.295,
                20.296,
                20.297,
                20.299,
                20.3,
                20.301,
                20.303,
                20.304,
                20.306,
                20.308,
                20.309,
                20.31,
                20.312,
                20.313,
                20.315,
                20.317,
                20.318,
                20.319,
                20.321,
                20.322,
                20.324,
                20.326,
                20.327,
                20.328,
                20.33,
                20.331,
                20.333,
                20.335,
                20.336,
                20.338,
                20.339,
                20.341,
                20.343,
                20.344,
                20.345,
                20.347,
                20.349,
                20.35,
                20.352,
                20.353,
                20.355,
                20.357,
                20.358,
                20.36,
                20.362,
                20.363,
                20.364,
                20.366,
                20.368,
                20.369,
                20.371,
                20.373,
                20.375,
                20.376,
                20.377,
                20.38,
                20.381,
                20.382,
                20.385,
                20.386,
                20.387,
                20.389,
                20.391,
                20.392,
                20.394,
                20.396,
                20.397,
                20.399,
                20.401,
                20.403,
                20.404,
                20.406,
                20.408,
                20.409,
                20.411,
                20.413,
                20.414,
                20.416,
                20.418,
                20.42,
                20.421,
                20.423,
                20.425,
                20.426,
                20.429,
                20.43,
                20.432,
                20.434,
                20.435,
                20.437,
                20.439,
                20.44,
                20.443,
                20.444,
                20.446,
                20.448,
                20.45,
                20.451,
                20.453,
                20.455,
                20.457,
                20.459,
                20.46,
                20.462,
                20.464,
                20.466,
                20.468,
                20.47,
                20.471,
                20.473,
                20.475,
                20.476,
                20.479,
                20.48,
                20.482,
                20.484,
                20.486,
                20.488,
                20.49,
                20.491,
                20.494,
                20.495,
                20.497,
                20.499,
                20.501,
                20.503,
                20.505,
                20.507
            ]
        },
        girls: {
            P01: [
                10.039,
                9.958,
                9.874,
                9.788,
                9.698,
                9.605,
                9.509,
                9.408,
                9.451,
                9.493,
                9.535,
                9.576,
                9.618,
                9.66,
                9.702,
                9.762,
                9.826,
                9.889,
                9.953,
                10.017,
                10.08,
                10.142,
                10.203,
                10.263,
                10.322,
                10.379,
                10.435,
                10.49,
                10.544,
                10.596,
                10.648,
                10.697,
                10.745,
                10.792,
                10.838,
                10.882,
                10.925,
                10.966,
                11.007,
                11.047,
                11.086,
                11.123,
                11.16,
                11.196,
                11.231,
                11.265,
                11.299,
                11.331,
                11.363,
                11.394,
                11.424,
                11.454,
                11.483,
                11.511,
                11.539,
                11.566,
                11.592,
                11.618,
                11.644,
                11.669,
                11.693,
                11.717,
                11.741,
                11.764,
                11.786,
                11.808,
                11.83,
                11.851,
                11.872,
                11.892,
                11.912,
                11.931,
                11.95,
                11.969,
                11.988,
                12.006,
                12.023,
                12.041,
                12.058,
                12.074,
                12.091,
                12.107,
                12.123,
                12.138,
                12.153,
                12.168,
                12.183,
                12.197,
                12.211,
                12.225,
                12.239,
                12.252,
                12.265,
                12.278,
                12.291,
                12.304,
                12.316,
                12.328,
                12.34,
                12.351,
                12.363,
                12.374,
                12.385,
                12.396,
                12.406,
                12.417,
                12.427,
                12.437,
                12.447,
                12.457,
                12.466,
                12.476,
                12.485,
                12.494,
                12.503,
                12.512,
                12.521,
                12.529,
                12.537,
                12.546,
                12.554,
                12.562,
                12.57,
                12.577,
                12.585,
                12.593,
                12.6,
                12.607,
                12.614,
                12.622,
                12.628,
                12.635,
                12.642,
                12.649,
                12.655,
                12.662,
                12.668,
                12.674,
                12.68,
                12.686,
                12.692,
                12.698,
                12.703,
                12.709,
                12.715,
                12.72,
                12.725,
                12.73,
                12.735,
                12.74,
                12.745,
                12.75,
                12.755,
                12.759,
                12.764,
                12.768,
                12.772,
                12.777,
                12.781,
                12.785,
                12.789,
                12.793,
                12.797,
                12.801,
                12.804,
                12.808,
                12.811,
                12.815,
                12.818,
                12.822,
                12.825,
                12.828,
                12.831,
                12.834,
                12.837,
                12.839,
                12.842,
                12.845,
                12.848,
                12.85,
                12.852,
                12.855,
                12.857,
                12.859,
                12.862,
                12.863,
                12.865,
                12.867,
                12.869,
                12.871,
                12.873,
                12.875,
                12.876,
                12.878,
                12.879,
                12.881,
                12.882,
                12.883,
                12.885,
                12.886,
                12.887,
                12.888,
                12.889,
                12.89,
                12.891,
                12.892,
                12.892,
                12.893,
                12.894,
                12.894,
                12.895,
                12.895,
                12.896,
                12.896,
                12.896,
                12.897,
                12.897,
                12.897,
                12.897,
                12.897,
                12.898,
                12.897,
                12.898,
                12.897,
                12.897,
                12.897,
                12.897,
                12.896,
                12.896,
                12.896,
                12.895,
                12.895,
                12.895,
                12.894,
                12.893,
                12.893,
                12.892,
                12.891,
                12.89,
                12.89,
                12.889,
                12.888,
                12.887,
                12.886,
                12.885,
                12.884,
                12.883,
                12.882,
                12.881,
                12.88,
                12.879,
                12.878,
                12.876,
                12.875,
                12.874,
                12.872,
                12.871,
                12.87,
                12.868,
                12.867,
                12.865,
                12.864,
                12.862,
                12.861,
                12.859,
                12.858,
                12.856,
                12.854,
                12.852,
                12.851,
                12.849,
                12.847,
                12.845,
                12.843,
                12.841,
                12.839,
                12.838,
                12.836,
                12.834,
                12.832,
                12.83,
                12.828,
                12.826,
                12.824,
                12.822,
                12.82,
                12.817,
                12.815,
                12.813,
                12.811,
                12.809,
                12.807,
                12.804,
                12.802,
                12.8,
                12.798,
                12.795,
                12.793,
                12.791,
                12.789,
                12.786,
                12.784,
                12.782,
                12.779,
                12.777,
                12.775,
                12.772,
                12.77,
                12.767,
                12.765,
                12.763,
                12.76,
                12.758,
                12.756,
                12.753,
                12.751,
                12.748,
                12.745,
                12.743,
                12.74,
                12.738,
                12.736,
                12.733,
                12.731,
                12.728,
                12.726,
                12.723,
                12.721,
                12.718,
                12.715,
                12.713,
                12.71,
                12.708,
                12.706,
                12.703,
                12.7,
                12.698,
                12.695,
                12.693,
                12.69,
                12.688,
                12.685,
                12.682,
                12.68,
                12.677,
                12.675,
                12.672,
                12.67,
                12.667,
                12.664,
                12.662,
                12.659,
                12.657,
                12.654,
                12.652,
                12.649,
                12.646,
                12.644,
                12.641,
                12.639,
                12.636,
                12.634,
                12.631,
                12.629,
                12.626,
                12.624,
                12.621,
                12.618,
                12.616,
                12.613,
                12.611,
                12.608,
                12.606,
                12.603,
                12.601,
                12.598,
                12.596,
                12.593,
                12.591,
                12.588,
                12.586,
                12.583,
                12.58,
                12.578,
                12.576,
                12.573,
                12.571,
                12.568,
                12.566,
                12.563,
                12.561,
                12.558,
                12.556,
                12.553,
                12.551,
                12.548,
                12.546,
                12.543,
                12.541,
                12.539,
                12.536,
                12.534,
                12.531,
                12.529,
                12.526,
                12.524,
                12.522,
                12.519,
                12.517,
                12.514,
                12.512,
                12.51,
                12.508,
                12.505,
                12.502,
                12.5,
                12.498,
                12.496,
                12.493,
                12.491,
                12.488,
                12.486,
                12.484,
                12.481,
                12.479,
                12.477,
                12.475,
                12.472,
                12.47,
                12.468,
                12.465,
                12.463,
                12.461,
                12.458,
                12.456,
                12.454,
                12.452,
                12.45,
                12.447,
                12.445,
                12.443,
                12.441,
                12.438,
                12.436,
                12.434,
                12.432,
                12.429,
                12.427,
                12.425,
                12.423,
                12.421,
                12.419,
                12.417,
                12.414,
                12.412,
                12.41,
                12.408,
                12.406,
                12.404,
                12.402,
                12.399,
                12.397,
                12.395,
                12.393,
                12.391,
                12.389,
                12.387,
                12.385,
                12.383,
                12.38,
                12.378,
                12.376,
                12.375,
                12.372,
                12.37,
                12.368,
                12.366,
                12.364,
                12.362,
                12.36,
                12.358,
                12.356,
                12.354,
                12.352,
                12.35,
                12.348,
                12.346,
                12.344,
                12.343,
                12.34,
                12.339,
                12.337,
                12.335,
                12.333,
                12.331,
                12.329,
                12.327,
                12.325,
                12.323,
                12.322,
                12.32,
                12.318,
                12.316,
                12.314,
                12.312,
                12.31,
                12.309,
                12.307,
                12.305,
                12.303,
                12.301,
                12.299,
                12.298,
                12.296,
                12.294,
                12.292,
                12.291,
                12.289,
                12.287,
                12.285,
                12.284,
                12.282,
                12.28,
                12.278,
                12.277,
                12.275,
                12.273,
                12.272,
                12.27,
                12.268,
                12.267,
                12.265,
                12.263,
                12.261,
                12.26,
                12.258,
                12.257,
                12.255,
                12.253,
                12.252,
                12.25,
                12.248,
                12.247,
                12.245,
                12.243,
                12.242,
                12.24,
                12.239,
                12.237,
                12.236,
                12.234,
                12.233,
                12.231,
                12.229,
                12.228,
                12.227,
                12.225,
                12.223,
                12.222,
                12.22,
                12.219,
                12.217,
                12.216,
                12.214,
                12.213,
                12.211,
                12.21,
                12.208,
                12.207,
                12.206,
                12.204,
                12.203,
                12.201,
                12.2,
                12.199,
                12.197,
                12.196,
                12.194,
                12.193,
                12.192,
                12.19,
                12.189,
                12.187,
                12.186,
                12.185,
                12.183,
                12.182,
                12.181,
                12.18,
                12.178,
                12.177,
                12.176,
                12.174,
                12.173,
                12.171,
                12.17,
                12.169,
                12.168,
                12.167,
                12.165,
                12.164,
                12.163,
                12.161,
                12.16,
                12.159,
                12.158,
                12.157,
                12.155,
                12.154,
                12.153,
                12.152,
                12.151,
                12.149,
                12.148,
                12.147,
                12.146,
                12.145,
                12.144,
                12.143,
                12.142,
                12.14,
                12.139,
                12.138,
                12.137,
                12.136,
                12.135,
                12.134,
                12.133,
                12.132,
                12.13,
                12.13,
                12.129,
                12.127,
                12.126,
                12.125,
                12.124,
                12.123,
                12.122,
                12.121,
                12.12,
                12.119,
                12.118,
                12.117,
                12.117,
                12.115,
                12.115,
                12.113,
                12.113,
                12.112,
                12.111,
                12.11,
                12.109,
                12.108,
                12.107,
                12.106,
                12.105,
                12.104,
                12.103,
                12.103,
                12.102,
                12.101,
                12.1,
                12.099,
                12.098,
                12.097,
                12.097,
                12.096,
                12.095,
                12.094,
                12.093,
                12.093,
                12.092,
                12.091,
                12.09,
                12.09,
                12.089,
                12.088,
                12.087,
                12.087,
                12.086,
                12.085,
                12.084,
                12.084,
                12.083,
                12.082,
                12.082,
                12.081,
                12.08,
                12.079,
                12.079,
                12.078,
                12.077,
                12.077,
                12.076,
                12.076,
                12.075,
                12.074,
                12.074,
                12.073,
                12.072,
                12.072,
                12.071,
                12.071,
                12.07,
                12.069,
                12.069,
                12.068,
                12.068,
                12.067,
                12.067,
                12.066,
                12.066,
                12.065,
                12.064,
                12.064,
                12.063,
                12.063,
                12.062,
                12.062,
                12.061,
                12.061,
                12.06,
                12.06,
                12.059,
                12.059,
                12.058,
                12.058,
                12.057,
                12.057,
                12.057,
                12.056,
                12.297,
                12.296,
                12.295,
                12.294,
                12.293,
                12.293,
                12.292,
                12.291,
                12.29,
                12.29,
                12.289,
                12.288,
                12.287,
                12.287,
                12.286,
                12.285,
                12.285,
                12.284,
                12.283,
                12.282,
                12.282,
                12.281,
                12.28,
                12.279,
                12.279,
                12.278,
                12.277,
                12.277,
                12.276,
                12.275,
                12.274,
                12.274,
                12.273,
                12.272,
                12.271,
                12.271,
                12.27,
                12.27,
                12.269,
                12.268,
                12.267,
                12.267,
                12.266,
                12.265,
                12.264,
                12.264,
                12.263,
                12.263,
                12.262,
                12.261,
                12.26,
                12.26,
                12.259,
                12.258,
                12.258,
                12.257,
                12.256,
                12.256,
                12.255,
                12.254,
                12.254,
                12.253,
                12.252,
                12.251,
                12.251,
                12.25,
                12.249,
                12.249,
                12.248,
                12.248,
                12.247,
                12.246,
                12.245,
                12.245,
                12.244,
                12.243,
                12.243,
                12.242,
                12.242,
                12.241,
                12.24,
                12.239,
                12.239,
                12.238,
                12.237,
                12.237,
                12.236,
                12.235,
                12.235,
                12.234,
                12.234,
                12.233,
                12.232,
                12.232,
                12.231,
                12.23,
                12.23,
                12.229,
                12.228,
                12.228,
                12.227,
                12.227,
                12.226,
                12.225,
                12.224,
                12.224,
                12.223,
                12.223,
                12.222,
                12.221,
                12.22,
                12.22,
                12.219,
                12.218,
                12.218,
                12.217,
                12.217,
                12.216,
                12.215,
                12.215,
                12.214,
                12.214,
                12.213,
                12.212,
                12.211,
                12.211,
                12.21,
                12.21,
                12.209,
                12.208,
                12.208,
                12.207,
                12.206,
                12.206,
                12.205,
                12.205,
                12.204,
                12.203,
                12.203,
                12.202,
                12.201,
                12.201,
                12.2,
                12.199,
                12.199,
                12.198,
                12.197,
                12.197,
                12.196,
                12.195,
                12.195,
                12.194,
                12.194,
                12.193,
                12.192,
                12.192,
                12.191,
                12.19,
                12.19,
                12.189,
                12.188,
                12.188,
                12.187,
                12.187,
                12.186,
                12.185,
                12.185,
                12.184,
                12.183,
                12.183,
                12.182,
                12.181,
                12.181,
                12.18,
                12.179,
                12.179,
                12.178,
                12.177,
                12.177,
                12.176,
                12.175,
                12.175,
                12.174,
                12.174,
                12.173,
                12.172,
                12.172,
                12.171,
                12.17,
                12.17,
                12.169,
                12.168,
                12.168,
                12.167,
                12.166,
                12.166,
                12.165,
                12.164,
                12.164,
                12.163,
                12.162,
                12.162,
                12.161,
                12.16,
                12.16,
                12.159,
                12.158,
                12.158,
                12.157,
                12.156,
                12.156,
                12.155,
                12.154,
                12.153,
                12.153,
                12.152,
                12.152,
                12.151,
                12.15,
                12.149,
                12.149,
                12.148,
                12.147,
                12.147,
                12.146,
                12.146,
                12.145,
                12.144,
                12.143,
                12.143,
                12.142,
                12.141,
                12.141,
                12.14,
                12.139,
                12.138,
                12.138,
                12.137,
                12.136,
                12.136,
                12.135,
                12.134,
                12.134,
                12.133,
                12.132,
                12.131,
                12.131,
                12.13,
                12.129,
                12.129,
                12.128,
                12.127,
                12.127,
                12.126,
                12.125,
                12.125,
                12.124,
                12.123,
                12.123,
                12.122,
                12.121,
                12.12,
                12.12,
                12.119,
                12.118,
                12.117,
                12.117,
                12.116,
                12.115,
                12.115,
                12.114,
                12.113,
                12.112,
                12.112,
                12.111,
                12.11,
                12.11,
                12.109,
                12.108,
                12.107,
                12.107,
                12.106,
                12.105,
                12.104,
                12.104,
                12.103,
                12.102,
                12.101,
                12.101,
                12.1,
                12.099,
                12.099,
                12.098,
                12.097,
                12.096,
                12.096,
                12.095,
                12.094,
                12.093,
                12.093,
                12.092,
                12.091,
                12.09,
                12.09,
                12.089,
                12.088,
                12.087,
                12.087,
                12.086,
                12.085,
                12.084,
                12.084,
                12.083,
                12.082,
                12.081,
                12.08,
                12.08,
                12.079,
                12.078,
                12.078,
                12.077,
                12.076,
                12.075,
                12.075,
                12.074,
                12.073,
                12.072,
                12.071,
                12.071,
                12.07,
                12.069,
                12.068,
                12.067,
                12.067,
                12.066,
                12.065,
                12.065,
                12.064,
                12.063,
                12.062,
                12.062,
                12.061,
                12.06,
                12.059,
                12.058,
                12.057,
                12.057,
                12.056,
                12.055,
                12.055,
                12.054,
                12.053,
                12.052,
                12.051,
                12.051,
                12.05,
                12.049,
                12.048,
                12.047,
                12.047,
                12.046,
                12.045,
                12.044,
                12.043,
                12.043,
                12.042,
                12.041,
                12.04,
                12.04,
                12.039,
                12.038,
                12.037,
                12.036,
                12.036,
                12.035,
                12.034,
                12.033,
                12.032,
                12.031,
                12.031,
                12.03,
                12.029,
                12.028,
                12.028,
                12.027,
                12.026,
                12.025,
                12.024,
                12.024,
                12.023,
                12.022,
                12.021,
                12.02,
                12.019,
                12.019,
                12.018,
                12.017,
                12.016,
                12.015,
                12.014,
                12.014,
                12.013,
                12.012,
                12.011,
                12.01,
                12.01,
                12.009,
                12.008,
                12.007,
                12.006,
                12.005,
                12.005,
                12.004,
                12.003,
                12.002,
                12.001,
                12,
                12,
                11.999,
                11.998,
                11.997,
                11.996,
                11.995,
                11.995,
                11.994,
                11.993,
                11.992,
                11.991,
                11.99,
                11.989,
                11.989,
                11.988,
                11.987,
                11.986,
                11.985,
                11.985,
                11.984,
                11.983,
                11.982,
                11.981,
                11.98,
                11.979,
                11.979,
                11.978,
                11.977,
                11.976,
                11.975,
                11.974,
                11.974,
                11.973,
                11.972,
                11.971,
                11.97,
                11.969,
                11.968,
                11.968,
                11.967,
                11.966,
                11.965,
                11.964,
                11.963,
                11.962,
                11.961,
                11.961,
                11.96,
                11.959,
                11.958,
                11.957,
                11.956,
                11.955,
                11.955,
                11.954,
                11.953,
                11.952,
                11.951,
                11.95,
                11.949,
                11.948,
                11.948,
                11.947,
                11.946,
                11.945,
                11.944,
                11.943,
                11.942,
                11.941,
                11.94,
                11.94,
                11.939,
                11.938,
                11.937,
                11.936,
                11.935,
                11.934,
                11.934,
                11.933,
                11.932,
                11.931,
                11.93,
                11.929,
                11.928,
                11.927,
                11.927,
                11.926,
                11.925,
                11.924,
                11.923,
                11.922,
                11.921,
                11.92,
                11.919,
                11.919,
                11.918,
                11.917,
                11.916,
                11.915,
                11.914,
                11.913,
                11.912,
                11.911,
                11.911,
                11.91,
                11.909,
                11.908,
                11.907,
                11.906,
                11.905,
                11.905,
                11.904,
                11.903,
                11.902,
                11.901,
                11.9,
                11.899,
                11.898,
                11.897,
                11.896,
                11.896,
                11.895,
                11.894,
                11.893,
                11.892,
                11.891,
                11.89,
                11.889,
                11.888,
                11.887,
                11.887,
                11.886,
                11.885,
                11.884,
                11.883,
                11.882,
                11.881,
                11.88,
                11.879,
                11.879,
                11.878,
                11.877,
                11.876,
                11.875,
                11.874,
                11.873,
                11.872,
                11.872,
                11.871,
                11.87,
                11.869,
                11.868,
                11.867,
                11.866,
                11.865,
                11.865,
                11.864,
                11.863,
                11.862,
                11.861,
                11.86,
                11.859,
                11.858,
                11.857,
                11.856,
                11.856,
                11.855,
                11.854,
                11.853,
                11.852,
                11.851,
                11.85,
                11.849,
                11.848,
                11.848,
                11.847,
                11.846,
                11.845,
                11.844,
                11.843,
                11.842,
                11.842,
                11.841,
                11.84,
                11.839,
                11.838,
                11.837,
                11.836,
                11.835,
                11.835,
                11.834,
                11.833,
                11.832,
                11.831,
                11.83,
                11.829,
                11.828,
                11.827,
                11.827,
                11.826,
                11.825,
                11.824,
                11.823,
                11.822,
                11.821,
                11.821,
                11.82,
                11.819,
                11.818,
                11.817,
                11.817,
                11.816,
                11.815,
                11.814,
                11.813,
                11.812,
                11.811,
                11.811,
                11.81,
                11.809,
                11.808,
                11.807,
                11.806,
                11.805,
                11.805,
                11.804,
                11.803,
                11.802,
                11.801,
                11.8,
                11.799,
                11.799,
                11.798,
                11.797,
                11.796,
                11.796,
                11.795,
                11.794,
                11.793,
                11.792,
                11.791,
                11.791,
                11.79,
                11.789,
                11.788,
                11.787,
                11.786,
                11.786,
                11.785,
                11.784,
                11.783,
                11.782,
                11.782,
                11.781,
                11.78,
                11.779,
                11.779,
                11.778,
                11.777,
                11.776,
                11.775,
                11.774,
                11.774,
                11.773,
                11.772,
                11.771,
                11.77,
                11.77,
                11.769,
                11.768,
                11.767,
                11.767,
                11.766,
                11.765,
                11.764,
                11.764,
                11.763,
                11.762,
                11.761,
                11.76,
                11.76,
                11.759,
                11.758,
                11.757,
                11.757,
                11.756,
                11.755,
                11.754,
                11.754,
                11.753,
                11.752,
                11.751,
                11.75,
                11.75,
                11.749,
                11.748,
                11.747,
                11.747,
                11.746,
                11.745,
                11.744,
                11.744,
                11.743,
                11.742,
                11.741,
                11.741,
                11.74,
                11.739,
                11.738,
                11.738,
                11.737,
                11.736,
                11.735,
                11.735,
                11.734,
                11.733,
                11.733,
                11.732,
                11.731,
                11.73,
                11.73,
                11.729,
                11.728,
                11.727,
                11.727,
                11.726,
                11.725,
                11.724,
                11.724,
                11.723,
                11.722,
                11.722,
                11.721,
                11.72,
                11.719,
                11.719,
                11.718,
                11.717,
                11.717,
                11.716,
                11.715,
                11.714,
                11.714,
                11.713,
                11.712,
                11.711,
                11.711,
                11.71,
                11.71,
                11.709,
                11.708,
                11.708,
                11.707,
                11.706,
                11.705,
                11.705,
                11.704,
                11.703,
                11.702,
                11.702,
                11.701,
                11.7,
                11.7,
                11.699,
                11.699,
                11.698,
                11.697,
                11.696,
                11.696,
                11.695,
                11.694,
                11.694,
                11.693,
                11.692,
                11.692,
                11.691,
                11.69,
                11.69,
                11.689,
                11.689,
                11.688,
                11.687,
                11.686,
                11.686,
                11.685,
                11.684,
                11.684,
                11.683,
                11.682,
                11.682,
                11.681,
                11.68,
                11.68,
                11.679,
                11.679,
                11.678,
                11.677,
                11.677,
                11.676,
                11.675,
                11.675,
                11.674,
                11.673,
                11.673,
                11.672,
                11.672,
                11.671,
                11.671,
                11.67,
                11.669,
                11.669,
                11.668,
                11.667,
                11.667,
                11.666,
                11.665,
                11.665,
                11.664,
                11.664,
                11.663,
                11.662,
                11.662,
                11.661,
                11.661,
                11.66,
                11.659,
                11.659,
                11.658,
                11.658,
                11.657,
                11.657,
                11.656,
                11.655,
                11.655,
                11.654,
                11.653,
                11.653,
                11.652,
                11.652,
                11.651,
                11.651,
                11.65,
                11.649,
                11.649,
                11.648,
                11.648,
                11.647,
                11.647,
                11.646,
                11.645,
                11.645,
                11.644,
                11.644,
                11.643,
                11.643,
                11.642,
                11.642,
                11.641,
                11.64,
                11.64,
                11.639,
                11.639,
                11.638,
                11.638,
                11.637,
                11.637,
                11.636,
                11.636,
                11.635,
                11.635,
                11.634,
                11.633,
                11.633,
                11.632,
                11.632,
                11.631,
                11.631,
                11.63,
                11.63,
                11.629,
                11.629,
                11.628,
                11.628,
                11.627,
                11.627,
                11.626,
                11.626,
                11.625,
                11.625,
                11.624,
                11.624,
                11.623,
                11.623,
                11.622,
                11.622,
                11.621,
                11.621,
                11.62,
                11.62,
                11.619,
                11.619,
                11.618,
                11.618,
                11.617,
                11.617,
                11.616,
                11.616,
                11.615,
                11.615,
                11.615,
                11.614,
                11.613,
                11.613,
                11.613,
                11.612,
                11.612,
                11.611,
                11.611,
                11.61,
                11.61,
                11.609,
                11.609,
                11.608,
                11.608,
                11.608,
                11.607,
                11.607,
                11.606,
                11.606,
                11.605,
                11.605,
                11.604,
                11.604,
                11.604,
                11.603,
                11.603,
                11.602,
                11.602,
                11.601,
                11.601,
                11.6,
                11.6,
                11.6,
                11.599,
                11.599,
                11.598,
                11.598,
                11.598,
                11.597,
                11.597,
                11.596,
                11.596,
                11.596,
                11.595,
                11.595,
                11.594,
                11.594,
                11.594,
                11.593,
                11.593,
                11.592,
                11.592,
                11.592,
                11.591,
                11.591,
                11.59,
                11.59,
                11.59,
                11.589,
                11.589,
                11.588,
                11.588,
                11.588,
                11.587,
                11.587,
                11.586,
                11.586,
                11.586,
                11.585,
                11.585,
                11.585,
                11.584,
                11.584,
                11.583,
                11.583,
                11.583,
                11.582,
                11.582,
                11.582,
                11.581,
                11.581,
                11.58,
                11.58,
                11.58,
                11.58,
                11.579,
                11.579,
                11.578,
                11.578,
                11.578,
                11.577,
                11.577,
                11.577,
                11.577,
                11.576,
                11.576,
                11.575,
                11.575,
                11.575,
                11.574,
                11.574,
                11.574,
                11.574,
                11.573,
                11.573,
                11.572,
                11.572,
                11.572,
                11.571,
                11.571,
                11.571,
                11.57,
                11.57,
                11.57,
                11.57,
                11.569,
                11.569,
                11.569,
                11.568,
                11.568,
                11.568,
                11.567,
                11.567,
                11.567,
                11.566,
                11.566,
                11.566,
                11.566,
                11.565,
                11.565,
                11.565,
                11.564,
                11.564,
                11.564,
                11.564,
                11.563,
                11.563,
                11.563,
                11.562,
                11.562,
                11.562,
                11.562,
                11.561,
                11.561,
                11.561,
                11.56,
                11.56,
                11.56,
                11.56,
                11.559,
                11.559,
                11.559,
                11.558,
                11.558,
                11.558,
                11.558,
                11.557,
                11.557,
                11.557,
                11.557,
                11.556,
                11.556,
                11.556,
                11.556,
                11.555,
                11.555,
                11.555,
                11.555,
                11.554,
                11.554,
                11.554,
                11.554,
                11.553,
                11.553,
                11.553,
                11.553,
                11.552,
                11.552,
                11.552,
                11.552,
                11.552,
                11.551,
                11.551,
                11.551,
                11.55,
                11.55,
                11.55,
                11.55,
                11.549,
                11.549,
                11.549,
                11.549,
                11.549,
                11.548,
                11.548,
                11.548,
                11.548,
                11.547,
                11.547
            ],
            P1: [
                10.764,
                10.703,
                10.641,
                10.577,
                10.511,
                10.444,
                10.375,
                10.304,
                10.342,
                10.381,
                10.419,
                10.457,
                10.496,
                10.534,
                10.572,
                10.633,
                10.697,
                10.761,
                10.826,
                10.891,
                10.955,
                11.019,
                11.081,
                11.143,
                11.203,
                11.262,
                11.32,
                11.376,
                11.431,
                11.485,
                11.537,
                11.588,
                11.638,
                11.686,
                11.732,
                11.777,
                11.821,
                11.864,
                11.906,
                11.946,
                11.986,
                12.024,
                12.062,
                12.099,
                12.134,
                12.169,
                12.203,
                12.237,
                12.269,
                12.3,
                12.331,
                12.361,
                12.391,
                12.419,
                12.448,
                12.475,
                12.502,
                12.528,
                12.554,
                12.579,
                12.604,
                12.628,
                12.652,
                12.676,
                12.698,
                12.72,
                12.742,
                12.764,
                12.785,
                12.805,
                12.825,
                12.844,
                12.864,
                12.883,
                12.901,
                12.919,
                12.937,
                12.954,
                12.972,
                12.988,
                13.004,
                13.021,
                13.036,
                13.052,
                13.067,
                13.082,
                13.096,
                13.111,
                13.125,
                13.138,
                13.152,
                13.165,
                13.178,
                13.191,
                13.204,
                13.216,
                13.228,
                13.24,
                13.252,
                13.263,
                13.274,
                13.285,
                13.296,
                13.307,
                13.317,
                13.328,
                13.338,
                13.348,
                13.357,
                13.367,
                13.376,
                13.385,
                13.394,
                13.403,
                13.412,
                13.421,
                13.429,
                13.437,
                13.445,
                13.454,
                13.461,
                13.469,
                13.477,
                13.484,
                13.491,
                13.499,
                13.506,
                13.513,
                13.52,
                13.527,
                13.533,
                13.54,
                13.546,
                13.553,
                13.559,
                13.565,
                13.571,
                13.577,
                13.583,
                13.588,
                13.594,
                13.599,
                13.605,
                13.61,
                13.615,
                13.62,
                13.625,
                13.63,
                13.635,
                13.639,
                13.644,
                13.648,
                13.653,
                13.657,
                13.661,
                13.665,
                13.669,
                13.673,
                13.677,
                13.681,
                13.684,
                13.688,
                13.691,
                13.695,
                13.698,
                13.701,
                13.705,
                13.708,
                13.711,
                13.714,
                13.716,
                13.719,
                13.722,
                13.724,
                13.727,
                13.729,
                13.732,
                13.734,
                13.736,
                13.738,
                13.74,
                13.742,
                13.744,
                13.746,
                13.748,
                13.749,
                13.751,
                13.753,
                13.754,
                13.756,
                13.757,
                13.758,
                13.759,
                13.76,
                13.761,
                13.762,
                13.763,
                13.764,
                13.765,
                13.766,
                13.766,
                13.767,
                13.768,
                13.768,
                13.769,
                13.769,
                13.769,
                13.77,
                13.77,
                13.77,
                13.77,
                13.77,
                13.77,
                13.77,
                13.77,
                13.77,
                13.77,
                13.769,
                13.769,
                13.769,
                13.768,
                13.768,
                13.768,
                13.767,
                13.766,
                13.766,
                13.765,
                13.764,
                13.763,
                13.762,
                13.762,
                13.761,
                13.76,
                13.759,
                13.758,
                13.757,
                13.756,
                13.754,
                13.753,
                13.752,
                13.751,
                13.749,
                13.748,
                13.747,
                13.745,
                13.744,
                13.742,
                13.741,
                13.739,
                13.737,
                13.736,
                13.734,
                13.732,
                13.731,
                13.729,
                13.727,
                13.725,
                13.723,
                13.721,
                13.72,
                13.718,
                13.716,
                13.714,
                13.712,
                13.709,
                13.707,
                13.705,
                13.703,
                13.701,
                13.699,
                13.696,
                13.694,
                13.692,
                13.69,
                13.687,
                13.685,
                13.683,
                13.68,
                13.678,
                13.675,
                13.673,
                13.67,
                13.668,
                13.665,
                13.663,
                13.66,
                13.657,
                13.655,
                13.652,
                13.65,
                13.647,
                13.644,
                13.642,
                13.639,
                13.636,
                13.634,
                13.631,
                13.628,
                13.625,
                13.623,
                13.62,
                13.617,
                13.614,
                13.611,
                13.609,
                13.606,
                13.603,
                13.6,
                13.597,
                13.594,
                13.591,
                13.589,
                13.586,
                13.583,
                13.58,
                13.577,
                13.574,
                13.571,
                13.568,
                13.565,
                13.563,
                13.56,
                13.557,
                13.554,
                13.551,
                13.548,
                13.545,
                13.542,
                13.539,
                13.536,
                13.533,
                13.53,
                13.527,
                13.524,
                13.521,
                13.518,
                13.515,
                13.512,
                13.509,
                13.506,
                13.503,
                13.5,
                13.497,
                13.494,
                13.491,
                13.488,
                13.485,
                13.482,
                13.479,
                13.476,
                13.473,
                13.47,
                13.467,
                13.464,
                13.461,
                13.458,
                13.455,
                13.452,
                13.449,
                13.446,
                13.443,
                13.44,
                13.437,
                13.434,
                13.431,
                13.428,
                13.425,
                13.422,
                13.42,
                13.417,
                13.414,
                13.411,
                13.408,
                13.405,
                13.402,
                13.399,
                13.396,
                13.393,
                13.39,
                13.387,
                13.385,
                13.381,
                13.378,
                13.376,
                13.373,
                13.37,
                13.367,
                13.364,
                13.361,
                13.359,
                13.356,
                13.353,
                13.35,
                13.347,
                13.344,
                13.341,
                13.338,
                13.336,
                13.333,
                13.33,
                13.327,
                13.325,
                13.322,
                13.319,
                13.316,
                13.313,
                13.31,
                13.308,
                13.305,
                13.302,
                13.299,
                13.297,
                13.294,
                13.291,
                13.288,
                13.286,
                13.283,
                13.28,
                13.278,
                13.275,
                13.272,
                13.269,
                13.267,
                13.264,
                13.261,
                13.259,
                13.256,
                13.253,
                13.251,
                13.248,
                13.246,
                13.243,
                13.24,
                13.238,
                13.235,
                13.232,
                13.23,
                13.227,
                13.225,
                13.222,
                13.22,
                13.217,
                13.215,
                13.212,
                13.209,
                13.207,
                13.204,
                13.202,
                13.199,
                13.197,
                13.194,
                13.192,
                13.189,
                13.187,
                13.184,
                13.182,
                13.179,
                13.177,
                13.175,
                13.172,
                13.169,
                13.167,
                13.165,
                13.162,
                13.16,
                13.157,
                13.155,
                13.153,
                13.15,
                13.148,
                13.146,
                13.143,
                13.141,
                13.138,
                13.136,
                13.134,
                13.132,
                13.129,
                13.127,
                13.125,
                13.122,
                13.12,
                13.118,
                13.115,
                13.113,
                13.111,
                13.109,
                13.106,
                13.104,
                13.102,
                13.1,
                13.097,
                13.095,
                13.093,
                13.091,
                13.089,
                13.087,
                13.084,
                13.082,
                13.08,
                13.078,
                13.076,
                13.073,
                13.071,
                13.069,
                13.067,
                13.065,
                13.063,
                13.061,
                13.059,
                13.057,
                13.054,
                13.052,
                13.05,
                13.048,
                13.046,
                13.044,
                13.042,
                13.04,
                13.038,
                13.036,
                13.034,
                13.032,
                13.03,
                13.028,
                13.026,
                13.024,
                13.022,
                13.02,
                13.018,
                13.016,
                13.014,
                13.013,
                13.011,
                13.009,
                13.007,
                13.005,
                13.003,
                13.001,
                12.999,
                12.997,
                12.995,
                12.994,
                12.992,
                12.99,
                12.988,
                12.986,
                12.984,
                12.983,
                12.981,
                12.979,
                12.977,
                12.975,
                12.974,
                12.972,
                12.97,
                12.968,
                12.967,
                12.965,
                12.963,
                12.961,
                12.959,
                12.958,
                12.956,
                12.954,
                12.953,
                12.951,
                12.949,
                12.948,
                12.946,
                12.944,
                12.943,
                12.941,
                12.939,
                12.938,
                12.936,
                12.934,
                12.933,
                12.931,
                12.93,
                12.928,
                12.926,
                12.925,
                12.923,
                12.922,
                12.92,
                12.919,
                12.917,
                12.916,
                12.914,
                12.913,
                12.911,
                12.909,
                12.908,
                12.906,
                12.905,
                12.904,
                12.902,
                12.9,
                12.899,
                12.898,
                12.896,
                12.895,
                12.893,
                12.892,
                12.89,
                12.889,
                12.887,
                12.886,
                12.885,
                12.883,
                12.882,
                12.88,
                12.879,
                12.878,
                12.876,
                12.875,
                12.874,
                12.872,
                12.871,
                12.87,
                12.869,
                12.867,
                12.866,
                12.864,
                12.863,
                12.862,
                12.861,
                12.86,
                12.858,
                12.857,
                12.856,
                12.854,
                12.853,
                12.852,
                12.851,
                12.85,
                12.848,
                12.847,
                12.846,
                12.845,
                12.844,
                12.843,
                12.842,
                12.84,
                12.839,
                12.838,
                12.837,
                12.836,
                12.835,
                12.834,
                12.832,
                12.832,
                12.83,
                12.829,
                12.828,
                12.827,
                12.826,
                12.825,
                12.824,
                12.823,
                12.822,
                12.821,
                12.82,
                12.819,
                12.818,
                12.817,
                12.816,
                12.815,
                12.814,
                12.813,
                12.812,
                12.811,
                12.81,
                12.809,
                12.808,
                12.807,
                12.806,
                12.806,
                12.805,
                12.804,
                12.803,
                12.802,
                12.801,
                12.8,
                12.8,
                12.798,
                12.798,
                12.797,
                12.796,
                12.795,
                12.794,
                12.794,
                12.793,
                12.792,
                12.791,
                12.791,
                12.79,
                12.789,
                12.788,
                12.787,
                12.787,
                12.786,
                12.785,
                12.784,
                12.784,
                12.783,
                12.782,
                12.782,
                12.781,
                12.78,
                12.78,
                12.779,
                12.778,
                12.777,
                12.777,
                12.776,
                12.775,
                12.775,
                12.774,
                12.773,
                12.773,
                12.772,
                12.772,
                12.771,
                12.77,
                12.77,
                12.769,
                12.769,
                12.768,
                12.767,
                13.02,
                13.019,
                13.018,
                13.017,
                13.016,
                13.016,
                13.015,
                13.014,
                13.013,
                13.012,
                13.012,
                13.011,
                13.01,
                13.009,
                13.009,
                13.008,
                13.007,
                13.006,
                13.005,
                13.005,
                13.004,
                13.003,
                13.002,
                13.001,
                13.001,
                13,
                12.999,
                12.998,
                12.998,
                12.997,
                12.996,
                12.995,
                12.995,
                12.994,
                12.993,
                12.992,
                12.992,
                12.991,
                12.99,
                12.989,
                12.989,
                12.988,
                12.987,
                12.986,
                12.985,
                12.985,
                12.984,
                12.983,
                12.983,
                12.982,
                12.981,
                12.98,
                12.98,
                12.979,
                12.978,
                12.977,
                12.977,
                12.976,
                12.975,
                12.975,
                12.974,
                12.973,
                12.972,
                12.972,
                12.971,
                12.97,
                12.969,
                12.969,
                12.968,
                12.967,
                12.967,
                12.966,
                12.965,
                12.964,
                12.964,
                12.963,
                12.962,
                12.962,
                12.961,
                12.96,
                12.96,
                12.959,
                12.958,
                12.957,
                12.957,
                12.956,
                12.955,
                12.954,
                12.954,
                12.953,
                12.952,
                12.952,
                12.951,
                12.95,
                12.95,
                12.949,
                12.948,
                12.947,
                12.947,
                12.946,
                12.945,
                12.945,
                12.944,
                12.943,
                12.943,
                12.942,
                12.941,
                12.941,
                12.94,
                12.939,
                12.938,
                12.938,
                12.937,
                12.936,
                12.936,
                12.935,
                12.935,
                12.934,
                12.933,
                12.932,
                12.932,
                12.931,
                12.93,
                12.93,
                12.929,
                12.928,
                12.928,
                12.927,
                12.926,
                12.925,
                12.925,
                12.924,
                12.923,
                12.923,
                12.922,
                12.922,
                12.921,
                12.92,
                12.919,
                12.919,
                12.918,
                12.917,
                12.917,
                12.916,
                12.915,
                12.915,
                12.914,
                12.913,
                12.913,
                12.912,
                12.911,
                12.911,
                12.91,
                12.909,
                12.908,
                12.908,
                12.907,
                12.906,
                12.906,
                12.905,
                12.904,
                12.904,
                12.903,
                12.902,
                12.902,
                12.901,
                12.9,
                12.9,
                12.899,
                12.898,
                12.898,
                12.897,
                12.896,
                12.896,
                12.895,
                12.894,
                12.894,
                12.893,
                12.892,
                12.891,
                12.891,
                12.89,
                12.889,
                12.889,
                12.888,
                12.887,
                12.887,
                12.886,
                12.885,
                12.885,
                12.884,
                12.883,
                12.882,
                12.882,
                12.881,
                12.88,
                12.88,
                12.879,
                12.878,
                12.878,
                12.877,
                12.876,
                12.876,
                12.875,
                12.874,
                12.874,
                12.873,
                12.872,
                12.871,
                12.871,
                12.87,
                12.869,
                12.868,
                12.868,
                12.867,
                12.867,
                12.866,
                12.865,
                12.864,
                12.864,
                12.863,
                12.862,
                12.861,
                12.861,
                12.86,
                12.86,
                12.859,
                12.858,
                12.857,
                12.857,
                12.856,
                12.855,
                12.854,
                12.854,
                12.853,
                12.852,
                12.852,
                12.851,
                12.85,
                12.849,
                12.849,
                12.848,
                12.847,
                12.847,
                12.846,
                12.845,
                12.845,
                12.844,
                12.843,
                12.842,
                12.842,
                12.841,
                12.84,
                12.839,
                12.839,
                12.838,
                12.837,
                12.837,
                12.836,
                12.835,
                12.834,
                12.834,
                12.833,
                12.832,
                12.831,
                12.831,
                12.83,
                12.829,
                12.828,
                12.828,
                12.827,
                12.826,
                12.826,
                12.825,
                12.824,
                12.823,
                12.823,
                12.822,
                12.821,
                12.82,
                12.82,
                12.819,
                12.818,
                12.818,
                12.817,
                12.816,
                12.816,
                12.815,
                12.814,
                12.813,
                12.812,
                12.812,
                12.811,
                12.81,
                12.81,
                12.809,
                12.808,
                12.807,
                12.807,
                12.806,
                12.805,
                12.804,
                12.804,
                12.803,
                12.802,
                12.801,
                12.801,
                12.8,
                12.799,
                12.798,
                12.798,
                12.797,
                12.796,
                12.795,
                12.795,
                12.794,
                12.793,
                12.792,
                12.792,
                12.791,
                12.79,
                12.789,
                12.789,
                12.788,
                12.787,
                12.786,
                12.786,
                12.785,
                12.784,
                12.784,
                12.783,
                12.782,
                12.781,
                12.781,
                12.78,
                12.779,
                12.778,
                12.777,
                12.777,
                12.776,
                12.775,
                12.774,
                12.774,
                12.773,
                12.772,
                12.771,
                12.771,
                12.77,
                12.769,
                12.769,
                12.768,
                12.767,
                12.766,
                12.765,
                12.765,
                12.764,
                12.763,
                12.762,
                12.762,
                12.761,
                12.76,
                12.759,
                12.759,
                12.758,
                12.757,
                12.756,
                12.755,
                12.755,
                12.754,
                12.753,
                12.752,
                12.752,
                12.751,
                12.75,
                12.75,
                12.749,
                12.748,
                12.747,
                12.746,
                12.746,
                12.745,
                12.744,
                12.743,
                12.743,
                12.742,
                12.741,
                12.74,
                12.739,
                12.739,
                12.738,
                12.737,
                12.736,
                12.736,
                12.735,
                12.734,
                12.733,
                12.732,
                12.732,
                12.731,
                12.73,
                12.729,
                12.729,
                12.728,
                12.727,
                12.726,
                12.726,
                12.725,
                12.724,
                12.723,
                12.722,
                12.721,
                12.721,
                12.72,
                12.719,
                12.718,
                12.718,
                12.717,
                12.716,
                12.715,
                12.715,
                12.714,
                12.713,
                12.712,
                12.712,
                12.711,
                12.71,
                12.709,
                12.708,
                12.708,
                12.707,
                12.706,
                12.705,
                12.704,
                12.704,
                12.703,
                12.702,
                12.701,
                12.701,
                12.7,
                12.699,
                12.698,
                12.697,
                12.697,
                12.696,
                12.695,
                12.694,
                12.694,
                12.693,
                12.692,
                12.691,
                12.69,
                12.689,
                12.689,
                12.688,
                12.687,
                12.686,
                12.685,
                12.685,
                12.684,
                12.683,
                12.682,
                12.681,
                12.681,
                12.68,
                12.679,
                12.678,
                12.678,
                12.677,
                12.676,
                12.675,
                12.674,
                12.673,
                12.673,
                12.672,
                12.671,
                12.671,
                12.67,
                12.669,
                12.668,
                12.667,
                12.666,
                12.666,
                12.665,
                12.664,
                12.663,
                12.662,
                12.662,
                12.661,
                12.66,
                12.659,
                12.658,
                12.658,
                12.657,
                12.656,
                12.655,
                12.654,
                12.654,
                12.653,
                12.652,
                12.651,
                12.65,
                12.649,
                12.649,
                12.648,
                12.647,
                12.646,
                12.646,
                12.645,
                12.644,
                12.643,
                12.642,
                12.642,
                12.641,
                12.64,
                12.639,
                12.638,
                12.638,
                12.637,
                12.636,
                12.635,
                12.634,
                12.634,
                12.633,
                12.632,
                12.631,
                12.63,
                12.63,
                12.629,
                12.628,
                12.627,
                12.626,
                12.625,
                12.625,
                12.624,
                12.623,
                12.622,
                12.621,
                12.621,
                12.62,
                12.619,
                12.618,
                12.618,
                12.617,
                12.616,
                12.615,
                12.614,
                12.613,
                12.612,
                12.612,
                12.611,
                12.61,
                12.609,
                12.609,
                12.608,
                12.607,
                12.606,
                12.605,
                12.604,
                12.604,
                12.603,
                12.602,
                12.601,
                12.601,
                12.6,
                12.599,
                12.598,
                12.597,
                12.597,
                12.596,
                12.595,
                12.594,
                12.593,
                12.593,
                12.592,
                12.591,
                12.59,
                12.59,
                12.589,
                12.588,
                12.587,
                12.586,
                12.585,
                12.585,
                12.584,
                12.583,
                12.582,
                12.581,
                12.581,
                12.58,
                12.579,
                12.578,
                12.577,
                12.577,
                12.576,
                12.575,
                12.574,
                12.574,
                12.573,
                12.572,
                12.571,
                12.571,
                12.57,
                12.569,
                12.568,
                12.567,
                12.567,
                12.566,
                12.565,
                12.564,
                12.563,
                12.563,
                12.562,
                12.561,
                12.56,
                12.56,
                12.559,
                12.558,
                12.557,
                12.556,
                12.556,
                12.555,
                12.554,
                12.553,
                12.553,
                12.552,
                12.551,
                12.551,
                12.55,
                12.549,
                12.548,
                12.547,
                12.547,
                12.546,
                12.545,
                12.544,
                12.544,
                12.543,
                12.542,
                12.541,
                12.541,
                12.54,
                12.539,
                12.538,
                12.538,
                12.537,
                12.536,
                12.535,
                12.535,
                12.534,
                12.533,
                12.533,
                12.532,
                12.531,
                12.53,
                12.53,
                12.529,
                12.528,
                12.527,
                12.527,
                12.526,
                12.525,
                12.525,
                12.524,
                12.523,
                12.522,
                12.522,
                12.521,
                12.52,
                12.519,
                12.519,
                12.518,
                12.517,
                12.517,
                12.516,
                12.515,
                12.515,
                12.514,
                12.513,
                12.512,
                12.512,
                12.511,
                12.51,
                12.51,
                12.509,
                12.508,
                12.508,
                12.507,
                12.506,
                12.506,
                12.505,
                12.504,
                12.503,
                12.503,
                12.502,
                12.501,
                12.501,
                12.5,
                12.499,
                12.499,
                12.498,
                12.497,
                12.497,
                12.496,
                12.495,
                12.495,
                12.494,
                12.493,
                12.493,
                12.492,
                12.491,
                12.491,
                12.49,
                12.489,
                12.489,
                12.488,
                12.487,
                12.487,
                12.486,
                12.485,
                12.485,
                12.484,
                12.483,
                12.483,
                12.482,
                12.481,
                12.481,
                12.48,
                12.479,
                12.479,
                12.478,
                12.478,
                12.477,
                12.476,
                12.476,
                12.475,
                12.474,
                12.474,
                12.473,
                12.472,
                12.472,
                12.471,
                12.47,
                12.47,
                12.469,
                12.469,
                12.468,
                12.467,
                12.467,
                12.466,
                12.466,
                12.465,
                12.464,
                12.464,
                12.463,
                12.462,
                12.462,
                12.461,
                12.461,
                12.46,
                12.459,
                12.459,
                12.458,
                12.457,
                12.457,
                12.456,
                12.456,
                12.455,
                12.455,
                12.454,
                12.453,
                12.453,
                12.452,
                12.452,
                12.451,
                12.45,
                12.45,
                12.449,
                12.448,
                12.448,
                12.448,
                12.447,
                12.446,
                12.446,
                12.445,
                12.445,
                12.444,
                12.443,
                12.443,
                12.442,
                12.442,
                12.441,
                12.44,
                12.44,
                12.439,
                12.439,
                12.438,
                12.438,
                12.437,
                12.437,
                12.436,
                12.435,
                12.435,
                12.434,
                12.434,
                12.433,
                12.433,
                12.432,
                12.431,
                12.431,
                12.431,
                12.43,
                12.429,
                12.429,
                12.428,
                12.428,
                12.427,
                12.427,
                12.426,
                12.426,
                12.425,
                12.424,
                12.424,
                12.424,
                12.423,
                12.423,
                12.422,
                12.421,
                12.421,
                12.42,
                12.42,
                12.419,
                12.419,
                12.418,
                12.418,
                12.417,
                12.417,
                12.416,
                12.416,
                12.415,
                12.415,
                12.414,
                12.414,
                12.413,
                12.413,
                12.412,
                12.412,
                12.411,
                12.411,
                12.41,
                12.41,
                12.409,
                12.409,
                12.408,
                12.408,
                12.407,
                12.407,
                12.406,
                12.406,
                12.405,
                12.405,
                12.404,
                12.404,
                12.404,
                12.403,
                12.403,
                12.402,
                12.402,
                12.401,
                12.401,
                12.4,
                12.4,
                12.399,
                12.399,
                12.398,
                12.398,
                12.397,
                12.397,
                12.397,
                12.396,
                12.396,
                12.395,
                12.395,
                12.394,
                12.394,
                12.394,
                12.393,
                12.393,
                12.392,
                12.392,
                12.391,
                12.391,
                12.39,
                12.39,
                12.39,
                12.389,
                12.389,
                12.388,
                12.388,
                12.387,
                12.387,
                12.387,
                12.386,
                12.386,
                12.385,
                12.385,
                12.385,
                12.384,
                12.384,
                12.383,
                12.383,
                12.383,
                12.382,
                12.382,
                12.381,
                12.381,
                12.381,
                12.38,
                12.38,
                12.38,
                12.379,
                12.379,
                12.378,
                12.378,
                12.378,
                12.377,
                12.377,
                12.376,
                12.376,
                12.376,
                12.375,
                12.375,
                12.375,
                12.374,
                12.374,
                12.373,
                12.373,
                12.373,
                12.372,
                12.372,
                12.372,
                12.371,
                12.371,
                12.37,
                12.37,
                12.37,
                12.369,
                12.369,
                12.369,
                12.368,
                12.368,
                12.368,
                12.367,
                12.367,
                12.367,
                12.366,
                12.366,
                12.366,
                12.365,
                12.365,
                12.365,
                12.364,
                12.364,
                12.364,
                12.363,
                12.363,
                12.363,
                12.362,
                12.362,
                12.362,
                12.361,
                12.361,
                12.361,
                12.36,
                12.36,
                12.36,
                12.359,
                12.359,
                12.359,
                12.358,
                12.358,
                12.358,
                12.357,
                12.357,
                12.357,
                12.357,
                12.356,
                12.356,
                12.356,
                12.355,
                12.355,
                12.355,
                12.355,
                12.354,
                12.354,
                12.354,
                12.353,
                12.353,
                12.353,
                12.352,
                12.352,
                12.352,
                12.352,
                12.351,
                12.351,
                12.351,
                12.351,
                12.35,
                12.35,
                12.35,
                12.35,
                12.349,
                12.349,
                12.349,
                12.348,
                12.348,
                12.348,
                12.348,
                12.347,
                12.347,
                12.347,
                12.347,
                12.346,
                12.346,
                12.346,
                12.346,
                12.345,
                12.345,
                12.345,
                12.344,
                12.344,
                12.344,
                12.344,
                12.343,
                12.343,
                12.343,
                12.343,
                12.343,
                12.342,
                12.342,
                12.342,
                12.342,
                12.341,
                12.341,
                12.341,
                12.341,
                12.34,
                12.34,
                12.34,
                12.34,
                12.34,
                12.339,
                12.339,
                12.339,
                12.339,
                12.338,
                12.338,
                12.338,
                12.338,
                12.338,
                12.337,
                12.337,
                12.337,
                12.337,
                12.336,
                12.336,
                12.336,
                12.336,
                12.336,
                12.336,
                12.335,
                12.335,
                12.335,
                12.334,
                12.334,
                12.334,
                12.334,
                12.334,
                12.334,
                12.334,
                12.333,
                12.333,
                12.333,
                12.333,
                12.332,
                12.332,
                12.332,
                12.332,
                12.332,
                12.332,
                12.331,
                12.331,
                12.331,
                12.331,
                12.331,
                12.331,
                12.33,
                12.33,
                12.33,
                12.33,
                12.329,
                12.329,
                12.329,
                12.329,
                12.329,
                12.329,
                12.328,
                12.328,
                12.328,
                12.328,
                12.328,
                12.328,
                12.327,
                12.327,
                12.327,
                12.327,
                12.327,
                12.327,
                12.326,
                12.326,
                12.326,
                12.326,
                12.326,
                12.325,
                12.325,
                12.325,
                12.325
            ],
            P3: [
                11.213,
                11.162,
                11.111,
                11.059,
                11.006,
                10.952,
                10.897,
                10.84,
                10.877,
                10.914,
                10.95,
                10.987,
                11.024,
                11.06,
                11.097,
                11.159,
                11.223,
                11.288,
                11.354,
                11.42,
                11.485,
                11.55,
                11.613,
                11.676,
                11.737,
                11.797,
                11.856,
                11.914,
                11.97,
                12.025,
                12.078,
                12.13,
                12.18,
                12.229,
                12.277,
                12.323,
                12.367,
                12.411,
                12.453,
                12.494,
                12.535,
                12.574,
                12.612,
                12.649,
                12.686,
                12.721,
                12.756,
                12.789,
                12.822,
                12.854,
                12.886,
                12.916,
                12.946,
                12.975,
                13.003,
                13.031,
                13.058,
                13.085,
                13.111,
                13.137,
                13.162,
                13.186,
                13.21,
                13.234,
                13.257,
                13.279,
                13.302,
                13.323,
                13.344,
                13.365,
                13.385,
                13.405,
                13.424,
                13.443,
                13.462,
                13.48,
                13.498,
                13.515,
                13.532,
                13.549,
                13.566,
                13.582,
                13.597,
                13.613,
                13.628,
                13.643,
                13.658,
                13.672,
                13.686,
                13.7,
                13.714,
                13.727,
                13.74,
                13.753,
                13.765,
                13.778,
                13.79,
                13.802,
                13.813,
                13.825,
                13.836,
                13.847,
                13.858,
                13.868,
                13.879,
                13.889,
                13.899,
                13.909,
                13.918,
                13.928,
                13.937,
                13.946,
                13.955,
                13.964,
                13.972,
                13.981,
                13.989,
                13.997,
                14.005,
                14.014,
                14.021,
                14.029,
                14.036,
                14.044,
                14.051,
                14.058,
                14.065,
                14.072,
                14.079,
                14.086,
                14.092,
                14.098,
                14.105,
                14.111,
                14.117,
                14.123,
                14.129,
                14.135,
                14.14,
                14.146,
                14.151,
                14.156,
                14.162,
                14.167,
                14.172,
                14.177,
                14.181,
                14.186,
                14.191,
                14.195,
                14.2,
                14.204,
                14.208,
                14.212,
                14.216,
                14.22,
                14.224,
                14.228,
                14.231,
                14.235,
                14.238,
                14.242,
                14.245,
                14.248,
                14.251,
                14.254,
                14.257,
                14.26,
                14.263,
                14.266,
                14.268,
                14.271,
                14.273,
                14.276,
                14.278,
                14.28,
                14.282,
                14.285,
                14.287,
                14.288,
                14.29,
                14.292,
                14.294,
                14.295,
                14.297,
                14.298,
                14.3,
                14.301,
                14.302,
                14.304,
                14.304,
                14.306,
                14.307,
                14.308,
                14.308,
                14.309,
                14.31,
                14.31,
                14.311,
                14.312,
                14.312,
                14.312,
                14.313,
                14.313,
                14.313,
                14.313,
                14.313,
                14.314,
                14.314,
                14.313,
                14.313,
                14.313,
                14.313,
                14.313,
                14.312,
                14.312,
                14.311,
                14.311,
                14.31,
                14.31,
                14.309,
                14.308,
                14.308,
                14.307,
                14.306,
                14.305,
                14.304,
                14.303,
                14.302,
                14.301,
                14.3,
                14.299,
                14.297,
                14.296,
                14.295,
                14.293,
                14.292,
                14.291,
                14.289,
                14.288,
                14.286,
                14.285,
                14.283,
                14.281,
                14.28,
                14.278,
                14.276,
                14.274,
                14.272,
                14.27,
                14.268,
                14.267,
                14.265,
                14.262,
                14.261,
                14.258,
                14.256,
                14.254,
                14.252,
                14.25,
                14.248,
                14.245,
                14.243,
                14.241,
                14.238,
                14.236,
                14.234,
                14.231,
                14.229,
                14.226,
                14.224,
                14.221,
                14.219,
                14.216,
                14.213,
                14.211,
                14.208,
                14.205,
                14.203,
                14.2,
                14.197,
                14.194,
                14.192,
                14.189,
                14.186,
                14.183,
                14.18,
                14.177,
                14.174,
                14.172,
                14.169,
                14.166,
                14.163,
                14.16,
                14.157,
                14.154,
                14.151,
                14.148,
                14.145,
                14.142,
                14.139,
                14.136,
                14.133,
                14.13,
                14.126,
                14.123,
                14.12,
                14.117,
                14.114,
                14.111,
                14.108,
                14.105,
                14.102,
                14.098,
                14.095,
                14.092,
                14.089,
                14.086,
                14.082,
                14.079,
                14.076,
                14.073,
                14.07,
                14.066,
                14.063,
                14.06,
                14.057,
                14.053,
                14.05,
                14.047,
                14.044,
                14.04,
                14.037,
                14.034,
                14.031,
                14.028,
                14.024,
                14.021,
                14.018,
                14.015,
                14.011,
                14.008,
                14.005,
                14.002,
                13.998,
                13.995,
                13.992,
                13.988,
                13.985,
                13.982,
                13.979,
                13.975,
                13.972,
                13.969,
                13.966,
                13.963,
                13.959,
                13.956,
                13.953,
                13.95,
                13.946,
                13.943,
                13.94,
                13.937,
                13.934,
                13.93,
                13.927,
                13.924,
                13.921,
                13.918,
                13.915,
                13.911,
                13.908,
                13.905,
                13.902,
                13.899,
                13.896,
                13.892,
                13.889,
                13.886,
                13.883,
                13.88,
                13.876,
                13.873,
                13.87,
                13.867,
                13.864,
                13.861,
                13.858,
                13.855,
                13.852,
                13.849,
                13.846,
                13.843,
                13.84,
                13.836,
                13.833,
                13.83,
                13.827,
                13.824,
                13.821,
                13.818,
                13.815,
                13.812,
                13.809,
                13.806,
                13.803,
                13.8,
                13.797,
                13.794,
                13.791,
                13.788,
                13.785,
                13.782,
                13.779,
                13.776,
                13.773,
                13.771,
                13.768,
                13.765,
                13.762,
                13.759,
                13.756,
                13.753,
                13.75,
                13.747,
                13.744,
                13.741,
                13.739,
                13.736,
                13.733,
                13.73,
                13.727,
                13.724,
                13.722,
                13.719,
                13.716,
                13.713,
                13.711,
                13.708,
                13.705,
                13.702,
                13.7,
                13.697,
                13.694,
                13.691,
                13.688,
                13.686,
                13.683,
                13.681,
                13.678,
                13.675,
                13.672,
                13.67,
                13.667,
                13.664,
                13.662,
                13.659,
                13.656,
                13.654,
                13.651,
                13.648,
                13.646,
                13.643,
                13.641,
                13.638,
                13.635,
                13.633,
                13.63,
                13.628,
                13.625,
                13.623,
                13.62,
                13.617,
                13.615,
                13.613,
                13.61,
                13.607,
                13.605,
                13.603,
                13.6,
                13.597,
                13.595,
                13.593,
                13.59,
                13.588,
                13.585,
                13.583,
                13.581,
                13.578,
                13.576,
                13.573,
                13.571,
                13.568,
                13.566,
                13.564,
                13.561,
                13.559,
                13.557,
                13.554,
                13.552,
                13.55,
                13.547,
                13.545,
                13.543,
                13.54,
                13.538,
                13.536,
                13.534,
                13.531,
                13.529,
                13.527,
                13.525,
                13.523,
                13.52,
                13.518,
                13.516,
                13.514,
                13.511,
                13.509,
                13.507,
                13.505,
                13.503,
                13.501,
                13.499,
                13.496,
                13.494,
                13.492,
                13.49,
                13.488,
                13.486,
                13.484,
                13.482,
                13.48,
                13.477,
                13.475,
                13.473,
                13.471,
                13.469,
                13.467,
                13.465,
                13.463,
                13.461,
                13.459,
                13.457,
                13.455,
                13.453,
                13.451,
                13.449,
                13.447,
                13.445,
                13.443,
                13.442,
                13.44,
                13.438,
                13.436,
                13.434,
                13.432,
                13.43,
                13.428,
                13.426,
                13.424,
                13.422,
                13.421,
                13.419,
                13.417,
                13.415,
                13.413,
                13.411,
                13.41,
                13.408,
                13.406,
                13.404,
                13.402,
                13.401,
                13.399,
                13.397,
                13.396,
                13.394,
                13.392,
                13.39,
                13.388,
                13.387,
                13.385,
                13.383,
                13.382,
                13.38,
                13.378,
                13.377,
                13.375,
                13.373,
                13.372,
                13.37,
                13.368,
                13.367,
                13.365,
                13.364,
                13.362,
                13.36,
                13.359,
                13.357,
                13.356,
                13.354,
                13.352,
                13.351,
                13.349,
                13.348,
                13.346,
                13.345,
                13.343,
                13.342,
                13.34,
                13.339,
                13.337,
                13.336,
                13.334,
                13.333,
                13.331,
                13.33,
                13.329,
                13.327,
                13.326,
                13.324,
                13.323,
                13.321,
                13.32,
                13.319,
                13.317,
                13.316,
                13.314,
                13.313,
                13.312,
                13.31,
                13.309,
                13.308,
                13.306,
                13.305,
                13.304,
                13.303,
                13.301,
                13.3,
                13.298,
                13.297,
                13.296,
                13.295,
                13.294,
                13.292,
                13.291,
                13.29,
                13.289,
                13.288,
                13.286,
                13.285,
                13.284,
                13.283,
                13.281,
                13.28,
                13.279,
                13.278,
                13.277,
                13.276,
                13.275,
                13.273,
                13.272,
                13.271,
                13.27,
                13.269,
                13.268,
                13.267,
                13.266,
                13.265,
                13.264,
                13.263,
                13.262,
                13.261,
                13.26,
                13.259,
                13.257,
                13.257,
                13.256,
                13.255,
                13.254,
                13.253,
                13.252,
                13.251,
                13.25,
                13.249,
                13.248,
                13.247,
                13.246,
                13.245,
                13.244,
                13.243,
                13.242,
                13.242,
                13.241,
                13.24,
                13.239,
                13.238,
                13.237,
                13.236,
                13.235,
                13.235,
                13.234,
                13.233,
                13.232,
                13.231,
                13.231,
                13.23,
                13.229,
                13.228,
                13.228,
                13.227,
                13.226,
                13.225,
                13.225,
                13.224,
                13.223,
                13.222,
                13.221,
                13.221,
                13.22,
                13.219,
                13.219,
                13.218,
                13.217,
                13.217,
                13.216,
                13.215,
                13.215,
                13.214,
                13.473,
                13.472,
                13.471,
                13.47,
                13.469,
                13.469,
                13.468,
                13.467,
                13.466,
                13.465,
                13.464,
                13.464,
                13.463,
                13.462,
                13.461,
                13.46,
                13.46,
                13.459,
                13.458,
                13.457,
                13.456,
                13.455,
                13.455,
                13.454,
                13.453,
                13.452,
                13.452,
                13.451,
                13.45,
                13.449,
                13.448,
                13.448,
                13.447,
                13.446,
                13.445,
                13.444,
                13.444,
                13.443,
                13.442,
                13.441,
                13.44,
                13.44,
                13.439,
                13.438,
                13.437,
                13.436,
                13.436,
                13.435,
                13.434,
                13.433,
                13.433,
                13.432,
                13.431,
                13.43,
                13.43,
                13.429,
                13.428,
                13.427,
                13.427,
                13.426,
                13.425,
                13.424,
                13.424,
                13.423,
                13.422,
                13.421,
                13.42,
                13.42,
                13.419,
                13.418,
                13.418,
                13.417,
                13.416,
                13.415,
                13.415,
                13.414,
                13.413,
                13.413,
                13.412,
                13.411,
                13.41,
                13.409,
                13.409,
                13.408,
                13.407,
                13.406,
                13.406,
                13.405,
                13.404,
                13.404,
                13.403,
                13.402,
                13.401,
                13.401,
                13.4,
                13.399,
                13.398,
                13.398,
                13.397,
                13.396,
                13.395,
                13.395,
                13.394,
                13.393,
                13.393,
                13.392,
                13.391,
                13.391,
                13.39,
                13.389,
                13.388,
                13.388,
                13.387,
                13.386,
                13.385,
                13.385,
                13.384,
                13.383,
                13.383,
                13.382,
                13.381,
                13.381,
                13.38,
                13.379,
                13.378,
                13.378,
                13.377,
                13.376,
                13.375,
                13.375,
                13.374,
                13.373,
                13.373,
                13.372,
                13.371,
                13.371,
                13.37,
                13.369,
                13.369,
                13.368,
                13.367,
                13.366,
                13.366,
                13.365,
                13.364,
                13.364,
                13.363,
                13.362,
                13.361,
                13.361,
                13.36,
                13.359,
                13.359,
                13.358,
                13.357,
                13.356,
                13.356,
                13.355,
                13.354,
                13.354,
                13.353,
                13.352,
                13.352,
                13.351,
                13.35,
                13.349,
                13.349,
                13.348,
                13.347,
                13.347,
                13.346,
                13.345,
                13.345,
                13.344,
                13.343,
                13.342,
                13.342,
                13.341,
                13.34,
                13.34,
                13.339,
                13.338,
                13.337,
                13.337,
                13.336,
                13.335,
                13.335,
                13.334,
                13.333,
                13.333,
                13.332,
                13.331,
                13.33,
                13.33,
                13.329,
                13.328,
                13.328,
                13.327,
                13.326,
                13.325,
                13.325,
                13.324,
                13.323,
                13.323,
                13.322,
                13.321,
                13.32,
                13.32,
                13.319,
                13.318,
                13.318,
                13.317,
                13.316,
                13.315,
                13.315,
                13.314,
                13.313,
                13.313,
                13.312,
                13.311,
                13.31,
                13.31,
                13.309,
                13.308,
                13.307,
                13.307,
                13.306,
                13.305,
                13.305,
                13.304,
                13.303,
                13.302,
                13.302,
                13.301,
                13.3,
                13.299,
                13.299,
                13.298,
                13.297,
                13.297,
                13.296,
                13.295,
                13.294,
                13.294,
                13.293,
                13.292,
                13.292,
                13.291,
                13.29,
                13.289,
                13.289,
                13.288,
                13.287,
                13.286,
                13.286,
                13.285,
                13.284,
                13.284,
                13.283,
                13.282,
                13.281,
                13.281,
                13.28,
                13.279,
                13.278,
                13.278,
                13.277,
                13.276,
                13.275,
                13.275,
                13.274,
                13.273,
                13.273,
                13.272,
                13.271,
                13.27,
                13.27,
                13.269,
                13.268,
                13.267,
                13.267,
                13.266,
                13.265,
                13.265,
                13.264,
                13.263,
                13.262,
                13.262,
                13.261,
                13.26,
                13.259,
                13.259,
                13.258,
                13.257,
                13.256,
                13.256,
                13.255,
                13.254,
                13.254,
                13.253,
                13.252,
                13.251,
                13.251,
                13.25,
                13.249,
                13.248,
                13.248,
                13.247,
                13.246,
                13.245,
                13.245,
                13.244,
                13.243,
                13.242,
                13.242,
                13.241,
                13.24,
                13.24,
                13.239,
                13.238,
                13.237,
                13.237,
                13.236,
                13.235,
                13.234,
                13.234,
                13.233,
                13.232,
                13.231,
                13.231,
                13.23,
                13.229,
                13.228,
                13.228,
                13.227,
                13.226,
                13.226,
                13.225,
                13.224,
                13.223,
                13.223,
                13.222,
                13.221,
                13.22,
                13.22,
                13.219,
                13.218,
                13.218,
                13.217,
                13.216,
                13.215,
                13.214,
                13.214,
                13.213,
                13.212,
                13.212,
                13.211,
                13.21,
                13.209,
                13.209,
                13.208,
                13.207,
                13.206,
                13.206,
                13.205,
                13.204,
                13.203,
                13.203,
                13.202,
                13.201,
                13.2,
                13.2,
                13.199,
                13.198,
                13.198,
                13.197,
                13.196,
                13.195,
                13.194,
                13.194,
                13.193,
                13.192,
                13.192,
                13.191,
                13.19,
                13.189,
                13.189,
                13.188,
                13.187,
                13.186,
                13.186,
                13.185,
                13.184,
                13.184,
                13.183,
                13.182,
                13.181,
                13.18,
                13.18,
                13.179,
                13.178,
                13.177,
                13.177,
                13.176,
                13.175,
                13.175,
                13.174,
                13.173,
                13.172,
                13.172,
                13.171,
                13.17,
                13.169,
                13.169,
                13.168,
                13.167,
                13.166,
                13.166,
                13.165,
                13.164,
                13.163,
                13.163,
                13.162,
                13.161,
                13.161,
                13.16,
                13.159,
                13.158,
                13.157,
                13.157,
                13.156,
                13.155,
                13.154,
                13.154,
                13.153,
                13.152,
                13.151,
                13.151,
                13.15,
                13.149,
                13.149,
                13.148,
                13.147,
                13.146,
                13.146,
                13.145,
                13.144,
                13.143,
                13.143,
                13.142,
                13.141,
                13.14,
                13.139,
                13.139,
                13.138,
                13.137,
                13.137,
                13.136,
                13.135,
                13.134,
                13.134,
                13.133,
                13.132,
                13.131,
                13.131,
                13.13,
                13.129,
                13.128,
                13.128,
                13.127,
                13.126,
                13.125,
                13.125,
                13.124,
                13.123,
                13.123,
                13.122,
                13.121,
                13.12,
                13.119,
                13.119,
                13.118,
                13.117,
                13.116,
                13.116,
                13.115,
                13.114,
                13.114,
                13.113,
                13.112,
                13.111,
                13.111,
                13.11,
                13.109,
                13.108,
                13.107,
                13.107,
                13.106,
                13.105,
                13.105,
                13.104,
                13.103,
                13.102,
                13.102,
                13.101,
                13.1,
                13.099,
                13.099,
                13.098,
                13.097,
                13.096,
                13.096,
                13.095,
                13.094,
                13.093,
                13.093,
                13.092,
                13.091,
                13.09,
                13.09,
                13.089,
                13.088,
                13.087,
                13.087,
                13.086,
                13.085,
                13.084,
                13.084,
                13.083,
                13.082,
                13.081,
                13.081,
                13.08,
                13.079,
                13.078,
                13.078,
                13.077,
                13.076,
                13.075,
                13.075,
                13.074,
                13.073,
                13.072,
                13.072,
                13.071,
                13.07,
                13.069,
                13.069,
                13.068,
                13.067,
                13.067,
                13.066,
                13.065,
                13.064,
                13.063,
                13.063,
                13.062,
                13.061,
                13.061,
                13.06,
                13.059,
                13.058,
                13.058,
                13.057,
                13.056,
                13.055,
                13.055,
                13.054,
                13.053,
                13.052,
                13.052,
                13.051,
                13.05,
                13.05,
                13.049,
                13.048,
                13.047,
                13.047,
                13.046,
                13.045,
                13.044,
                13.044,
                13.043,
                13.042,
                13.041,
                13.041,
                13.04,
                13.039,
                13.039,
                13.038,
                13.037,
                13.036,
                13.036,
                13.035,
                13.034,
                13.034,
                13.033,
                13.032,
                13.031,
                13.031,
                13.03,
                13.029,
                13.028,
                13.028,
                13.027,
                13.026,
                13.026,
                13.025,
                13.024,
                13.023,
                13.023,
                13.022,
                13.021,
                13.021,
                13.02,
                13.019,
                13.018,
                13.018,
                13.017,
                13.016,
                13.016,
                13.015,
                13.014,
                13.014,
                13.013,
                13.012,
                13.012,
                13.011,
                13.01,
                13.01,
                13.009,
                13.008,
                13.007,
                13.007,
                13.006,
                13.005,
                13.005,
                13.004,
                13.003,
                13.003,
                13.002,
                13.001,
                13.001,
                13,
                12.999,
                12.999,
                12.998,
                12.997,
                12.996,
                12.996,
                12.995,
                12.995,
                12.994,
                12.993,
                12.993,
                12.992,
                12.991,
                12.991,
                12.99,
                12.989,
                12.989,
                12.988,
                12.987,
                12.987,
                12.986,
                12.985,
                12.985,
                12.984,
                12.983,
                12.983,
                12.982,
                12.981,
                12.981,
                12.98,
                12.98,
                12.979,
                12.978,
                12.978,
                12.977,
                12.976,
                12.976,
                12.975,
                12.974,
                12.974,
                12.973,
                12.973,
                12.972,
                12.971,
                12.971,
                12.97,
                12.969,
                12.969,
                12.968,
                12.968,
                12.967,
                12.966,
                12.966,
                12.965,
                12.964,
                12.964,
                12.963,
                12.963,
                12.962,
                12.962,
                12.961,
                12.96,
                12.96,
                12.959,
                12.958,
                12.958,
                12.957,
                12.957,
                12.956,
                12.955,
                12.955,
                12.954,
                12.954,
                12.953,
                12.953,
                12.952,
                12.951,
                12.951,
                12.95,
                12.95,
                12.949,
                12.948,
                12.948,
                12.947,
                12.947,
                12.946,
                12.946,
                12.945,
                12.944,
                12.944,
                12.943,
                12.943,
                12.942,
                12.942,
                12.941,
                12.94,
                12.94,
                12.939,
                12.939,
                12.938,
                12.937,
                12.937,
                12.936,
                12.936,
                12.935,
                12.935,
                12.934,
                12.934,
                12.933,
                12.933,
                12.932,
                12.931,
                12.931,
                12.93,
                12.93,
                12.929,
                12.929,
                12.928,
                12.928,
                12.927,
                12.927,
                12.926,
                12.926,
                12.925,
                12.925,
                12.924,
                12.923,
                12.923,
                12.922,
                12.922,
                12.921,
                12.921,
                12.92,
                12.92,
                12.919,
                12.919,
                12.918,
                12.918,
                12.917,
                12.917,
                12.916,
                12.916,
                12.915,
                12.915,
                12.914,
                12.914,
                12.913,
                12.913,
                12.912,
                12.911,
                12.911,
                12.911,
                12.91,
                12.91,
                12.909,
                12.909,
                12.908,
                12.908,
                12.907,
                12.907,
                12.906,
                12.906,
                12.905,
                12.905,
                12.904,
                12.904,
                12.903,
                12.903,
                12.902,
                12.902,
                12.901,
                12.901,
                12.9,
                12.9,
                12.9,
                12.899,
                12.899,
                12.898,
                12.898,
                12.897,
                12.897,
                12.896,
                12.896,
                12.895,
                12.895,
                12.894,
                12.894,
                12.894,
                12.893,
                12.893,
                12.892,
                12.892,
                12.891,
                12.891,
                12.891,
                12.89,
                12.89,
                12.889,
                12.889,
                12.888,
                12.888,
                12.888,
                12.887,
                12.887,
                12.886,
                12.886,
                12.885,
                12.885,
                12.884,
                12.884,
                12.884,
                12.883,
                12.883,
                12.883,
                12.882,
                12.882,
                12.881,
                12.881,
                12.881,
                12.88,
                12.88,
                12.879,
                12.879,
                12.878,
                12.878,
                12.878,
                12.877,
                12.877,
                12.877,
                12.876,
                12.876,
                12.875,
                12.875,
                12.875,
                12.874,
                12.874,
                12.873,
                12.873,
                12.873,
                12.872,
                12.872,
                12.872,
                12.871,
                12.871,
                12.87,
                12.87,
                12.87,
                12.869,
                12.869,
                12.869,
                12.868,
                12.868,
                12.868,
                12.867,
                12.867,
                12.866,
                12.866,
                12.866,
                12.866,
                12.865,
                12.865,
                12.865,
                12.864,
                12.864,
                12.863,
                12.863,
                12.863,
                12.862,
                12.862,
                12.862,
                12.862,
                12.861,
                12.861,
                12.86,
                12.86,
                12.86,
                12.86,
                12.859,
                12.859,
                12.859,
                12.858,
                12.858,
                12.858,
                12.857,
                12.857,
                12.857,
                12.856,
                12.856,
                12.856,
                12.856,
                12.855,
                12.855,
                12.854,
                12.854,
                12.854,
                12.854,
                12.854,
                12.853,
                12.853,
                12.853,
                12.852,
                12.852,
                12.852,
                12.851,
                12.851,
                12.851,
                12.851,
                12.85,
                12.85,
                12.85,
                12.849,
                12.849,
                12.849,
                12.848,
                12.848,
                12.848,
                12.848,
                12.848,
                12.847,
                12.847,
                12.847,
                12.846,
                12.846,
                12.846,
                12.846,
                12.845,
                12.845,
                12.845,
                12.845,
                12.844,
                12.844,
                12.844,
                12.843,
                12.843,
                12.843,
                12.843,
                12.843,
                12.842,
                12.842,
                12.842,
                12.842,
                12.841,
                12.841,
                12.841,
                12.84,
                12.84,
                12.84,
                12.84,
                12.84,
                12.839,
                12.839,
                12.839,
                12.839,
                12.839,
                12.838,
                12.838,
                12.838,
                12.838,
                12.837,
                12.837,
                12.837,
                12.837,
                12.837,
                12.836,
                12.836,
                12.836,
                12.836,
                12.835,
                12.835,
                12.835,
                12.835,
                12.835,
                12.834,
                12.834,
                12.834,
                12.834,
                12.834,
                12.833,
                12.833,
                12.833,
                12.833,
                12.832,
                12.832,
                12.832,
                12.832,
                12.832,
                12.832,
                12.831,
                12.831,
                12.831,
                12.831,
                12.831,
                12.83,
                12.83,
                12.83,
                12.83,
                12.83,
                12.83,
                12.829,
                12.829,
                12.829,
                12.829,
                12.829,
                12.829,
                12.828,
                12.828,
                12.828,
                12.828,
                12.828,
                12.828,
                12.827,
                12.827,
                12.827,
                12.827,
                12.827,
                12.826,
                12.826,
                12.826,
                12.826,
                12.826,
                12.826,
                12.826,
                12.825,
                12.825,
                12.825,
                12.825,
                12.825,
                12.825,
                12.824,
                12.824,
                12.824,
                12.824,
                12.824,
                12.824,
                12.824,
                12.823,
                12.823,
                12.823,
                12.823,
                12.823,
                12.823,
                12.823,
                12.823,
                12.822,
                12.822,
                12.822,
                12.822,
                12.822,
                12.822,
                12.821,
                12.821,
                12.821,
                12.821,
                12.821,
                12.821,
                12.821,
                12.821,
                12.82,
                12.82,
                12.82,
                12.82,
                12.82,
                12.82,
                12.82,
                12.819,
                12.819,
                12.819,
                12.819,
                12.819,
                12.819,
                12.819,
                12.819,
                12.819,
                12.818,
                12.818,
                12.818,
                12.818,
                12.818,
                12.818,
                12.818,
                12.818,
                12.818
            ],
            P5: [
                11.458,
                11.413,
                11.367,
                11.321,
                11.274,
                11.226,
                11.178,
                11.128,
                11.164,
                11.2,
                11.236,
                11.272,
                11.308,
                11.344,
                11.38,
                11.442,
                11.507,
                11.573,
                11.639,
                11.706,
                11.771,
                11.837,
                11.901,
                11.964,
                12.026,
                12.087,
                12.147,
                12.205,
                12.261,
                12.317,
                12.371,
                12.423,
                12.474,
                12.524,
                12.572,
                12.618,
                12.663,
                12.707,
                12.75,
                12.792,
                12.832,
                12.872,
                12.911,
                12.948,
                12.985,
                13.021,
                13.056,
                13.09,
                13.123,
                13.155,
                13.187,
                13.217,
                13.247,
                13.277,
                13.306,
                13.334,
                13.361,
                13.388,
                13.414,
                13.44,
                13.465,
                13.49,
                13.514,
                13.538,
                13.561,
                13.584,
                13.606,
                13.628,
                13.649,
                13.67,
                13.69,
                13.71,
                13.729,
                13.748,
                13.767,
                13.785,
                13.803,
                13.821,
                13.838,
                13.855,
                13.871,
                13.888,
                13.903,
                13.919,
                13.934,
                13.949,
                13.964,
                13.978,
                13.992,
                14.006,
                14.02,
                14.033,
                14.046,
                14.059,
                14.072,
                14.084,
                14.096,
                14.108,
                14.12,
                14.131,
                14.142,
                14.153,
                14.164,
                14.175,
                14.185,
                14.195,
                14.205,
                14.215,
                14.225,
                14.234,
                14.243,
                14.252,
                14.261,
                14.27,
                14.279,
                14.287,
                14.295,
                14.303,
                14.311,
                14.319,
                14.327,
                14.335,
                14.342,
                14.349,
                14.357,
                14.364,
                14.371,
                14.378,
                14.384,
                14.391,
                14.397,
                14.404,
                14.41,
                14.416,
                14.422,
                14.428,
                14.434,
                14.44,
                14.445,
                14.451,
                14.456,
                14.461,
                14.466,
                14.471,
                14.476,
                14.481,
                14.486,
                14.49,
                14.495,
                14.499,
                14.504,
                14.508,
                14.512,
                14.516,
                14.52,
                14.524,
                14.527,
                14.531,
                14.535,
                14.538,
                14.542,
                14.545,
                14.548,
                14.551,
                14.554,
                14.557,
                14.56,
                14.563,
                14.566,
                14.568,
                14.571,
                14.573,
                14.575,
                14.578,
                14.58,
                14.582,
                14.584,
                14.586,
                14.588,
                14.59,
                14.591,
                14.593,
                14.595,
                14.596,
                14.598,
                14.599,
                14.6,
                14.602,
                14.603,
                14.604,
                14.605,
                14.606,
                14.607,
                14.608,
                14.608,
                14.609,
                14.609,
                14.61,
                14.61,
                14.611,
                14.611,
                14.611,
                14.612,
                14.612,
                14.612,
                14.612,
                14.612,
                14.612,
                14.612,
                14.611,
                14.611,
                14.611,
                14.61,
                14.61,
                14.609,
                14.609,
                14.608,
                14.608,
                14.607,
                14.606,
                14.606,
                14.605,
                14.604,
                14.603,
                14.602,
                14.601,
                14.6,
                14.599,
                14.598,
                14.596,
                14.595,
                14.594,
                14.592,
                14.591,
                14.59,
                14.588,
                14.587,
                14.585,
                14.583,
                14.582,
                14.58,
                14.578,
                14.576,
                14.575,
                14.573,
                14.571,
                14.569,
                14.567,
                14.565,
                14.563,
                14.561,
                14.559,
                14.557,
                14.555,
                14.553,
                14.55,
                14.548,
                14.546,
                14.543,
                14.541,
                14.539,
                14.536,
                14.534,
                14.531,
                14.529,
                14.526,
                14.524,
                14.521,
                14.518,
                14.516,
                14.513,
                14.511,
                14.508,
                14.505,
                14.502,
                14.5,
                14.497,
                14.494,
                14.491,
                14.488,
                14.485,
                14.482,
                14.479,
                14.477,
                14.474,
                14.471,
                14.468,
                14.465,
                14.461,
                14.459,
                14.455,
                14.452,
                14.449,
                14.446,
                14.443,
                14.44,
                14.437,
                14.434,
                14.43,
                14.427,
                14.424,
                14.421,
                14.418,
                14.414,
                14.411,
                14.408,
                14.405,
                14.401,
                14.398,
                14.395,
                14.392,
                14.388,
                14.385,
                14.382,
                14.378,
                14.375,
                14.372,
                14.368,
                14.365,
                14.362,
                14.359,
                14.355,
                14.352,
                14.348,
                14.345,
                14.342,
                14.338,
                14.335,
                14.331,
                14.328,
                14.325,
                14.321,
                14.318,
                14.315,
                14.311,
                14.308,
                14.304,
                14.301,
                14.298,
                14.294,
                14.291,
                14.288,
                14.284,
                14.281,
                14.277,
                14.274,
                14.271,
                14.267,
                14.264,
                14.26,
                14.257,
                14.254,
                14.25,
                14.247,
                14.243,
                14.24,
                14.237,
                14.233,
                14.23,
                14.227,
                14.223,
                14.22,
                14.217,
                14.213,
                14.21,
                14.207,
                14.203,
                14.2,
                14.197,
                14.193,
                14.19,
                14.187,
                14.184,
                14.18,
                14.177,
                14.174,
                14.17,
                14.167,
                14.164,
                14.161,
                14.157,
                14.154,
                14.151,
                14.147,
                14.144,
                14.141,
                14.138,
                14.135,
                14.131,
                14.128,
                14.125,
                14.122,
                14.119,
                14.115,
                14.112,
                14.109,
                14.106,
                14.102,
                14.099,
                14.096,
                14.093,
                14.09,
                14.087,
                14.084,
                14.08,
                14.077,
                14.074,
                14.071,
                14.068,
                14.065,
                14.062,
                14.059,
                14.056,
                14.053,
                14.049,
                14.046,
                14.043,
                14.04,
                14.037,
                14.034,
                14.031,
                14.028,
                14.025,
                14.022,
                14.019,
                14.016,
                14.013,
                14.01,
                14.007,
                14.004,
                14.002,
                13.999,
                13.996,
                13.993,
                13.99,
                13.987,
                13.984,
                13.981,
                13.978,
                13.975,
                13.972,
                13.97,
                13.967,
                13.964,
                13.961,
                13.958,
                13.955,
                13.952,
                13.95,
                13.947,
                13.944,
                13.941,
                13.938,
                13.936,
                13.933,
                13.93,
                13.927,
                13.925,
                13.922,
                13.919,
                13.916,
                13.914,
                13.911,
                13.908,
                13.905,
                13.903,
                13.9,
                13.897,
                13.895,
                13.892,
                13.889,
                13.887,
                13.884,
                13.881,
                13.879,
                13.876,
                13.874,
                13.871,
                13.869,
                13.866,
                13.863,
                13.861,
                13.858,
                13.856,
                13.853,
                13.85,
                13.848,
                13.846,
                13.843,
                13.84,
                13.838,
                13.836,
                13.833,
                13.831,
                13.828,
                13.826,
                13.823,
                13.821,
                13.818,
                13.816,
                13.814,
                13.811,
                13.809,
                13.806,
                13.804,
                13.802,
                13.799,
                13.797,
                13.794,
                13.792,
                13.79,
                13.787,
                13.785,
                13.783,
                13.781,
                13.778,
                13.776,
                13.774,
                13.771,
                13.769,
                13.767,
                13.765,
                13.762,
                13.76,
                13.758,
                13.756,
                13.753,
                13.751,
                13.749,
                13.747,
                13.745,
                13.742,
                13.74,
                13.738,
                13.736,
                13.734,
                13.732,
                13.73,
                13.727,
                13.725,
                13.723,
                13.721,
                13.719,
                13.717,
                13.715,
                13.713,
                13.711,
                13.709,
                13.707,
                13.705,
                13.703,
                13.701,
                13.699,
                13.697,
                13.695,
                13.693,
                13.691,
                13.689,
                13.687,
                13.685,
                13.683,
                13.681,
                13.679,
                13.677,
                13.675,
                13.673,
                13.671,
                13.669,
                13.667,
                13.666,
                13.664,
                13.662,
                13.66,
                13.658,
                13.656,
                13.654,
                13.653,
                13.651,
                13.649,
                13.647,
                13.645,
                13.643,
                13.642,
                13.64,
                13.638,
                13.636,
                13.635,
                13.633,
                13.631,
                13.629,
                13.628,
                13.626,
                13.624,
                13.623,
                13.621,
                13.619,
                13.618,
                13.616,
                13.614,
                13.612,
                13.611,
                13.609,
                13.607,
                13.606,
                13.604,
                13.603,
                13.601,
                13.599,
                13.598,
                13.596,
                13.595,
                13.593,
                13.591,
                13.59,
                13.589,
                13.587,
                13.585,
                13.584,
                13.582,
                13.581,
                13.579,
                13.578,
                13.576,
                13.575,
                13.573,
                13.572,
                13.571,
                13.569,
                13.568,
                13.566,
                13.565,
                13.563,
                13.562,
                13.561,
                13.559,
                13.558,
                13.556,
                13.555,
                13.554,
                13.552,
                13.551,
                13.55,
                13.548,
                13.547,
                13.546,
                13.544,
                13.543,
                13.542,
                13.54,
                13.539,
                13.538,
                13.537,
                13.536,
                13.534,
                13.533,
                13.532,
                13.531,
                13.529,
                13.528,
                13.527,
                13.526,
                13.525,
                13.523,
                13.522,
                13.521,
                13.52,
                13.519,
                13.518,
                13.517,
                13.515,
                13.514,
                13.513,
                13.512,
                13.511,
                13.51,
                13.509,
                13.508,
                13.507,
                13.506,
                13.505,
                13.503,
                13.503,
                13.501,
                13.501,
                13.499,
                13.499,
                13.497,
                13.497,
                13.495,
                13.495,
                13.494,
                13.493,
                13.492,
                13.491,
                13.49,
                13.489,
                13.488,
                13.487,
                13.486,
                13.485,
                13.484,
                13.483,
                13.483,
                13.482,
                13.481,
                13.48,
                13.479,
                13.478,
                13.477,
                13.477,
                13.476,
                13.475,
                13.474,
                13.473,
                13.472,
                13.472,
                13.471,
                13.47,
                13.469,
                13.469,
                13.468,
                13.467,
                13.466,
                13.466,
                13.465,
                13.464,
                13.463,
                13.463,
                13.462,
                13.461,
                13.46,
                13.723,
                13.722,
                13.721,
                13.72,
                13.719,
                13.719,
                13.718,
                13.717,
                13.716,
                13.715,
                13.714,
                13.713,
                13.713,
                13.712,
                13.711,
                13.71,
                13.709,
                13.709,
                13.708,
                13.707,
                13.706,
                13.705,
                13.704,
                13.703,
                13.703,
                13.702,
                13.701,
                13.7,
                13.699,
                13.699,
                13.698,
                13.697,
                13.696,
                13.695,
                13.694,
                13.694,
                13.693,
                13.692,
                13.691,
                13.691,
                13.69,
                13.689,
                13.688,
                13.687,
                13.687,
                13.686,
                13.685,
                13.684,
                13.684,
                13.683,
                13.682,
                13.681,
                13.68,
                13.68,
                13.679,
                13.678,
                13.677,
                13.676,
                13.676,
                13.675,
                13.674,
                13.673,
                13.673,
                13.672,
                13.671,
                13.67,
                13.669,
                13.669,
                13.668,
                13.667,
                13.666,
                13.666,
                13.665,
                13.664,
                13.663,
                13.663,
                13.662,
                13.661,
                13.66,
                13.66,
                13.659,
                13.658,
                13.657,
                13.657,
                13.656,
                13.655,
                13.654,
                13.654,
                13.653,
                13.652,
                13.651,
                13.651,
                13.65,
                13.649,
                13.648,
                13.648,
                13.647,
                13.646,
                13.645,
                13.645,
                13.644,
                13.643,
                13.643,
                13.642,
                13.641,
                13.64,
                13.64,
                13.639,
                13.638,
                13.637,
                13.637,
                13.636,
                13.635,
                13.634,
                13.634,
                13.633,
                13.632,
                13.632,
                13.631,
                13.63,
                13.629,
                13.629,
                13.628,
                13.627,
                13.626,
                13.626,
                13.625,
                13.624,
                13.623,
                13.623,
                13.622,
                13.621,
                13.62,
                13.62,
                13.619,
                13.619,
                13.618,
                13.617,
                13.616,
                13.616,
                13.615,
                13.614,
                13.613,
                13.613,
                13.612,
                13.611,
                13.611,
                13.61,
                13.609,
                13.608,
                13.608,
                13.607,
                13.606,
                13.606,
                13.605,
                13.604,
                13.603,
                13.603,
                13.602,
                13.601,
                13.6,
                13.6,
                13.599,
                13.598,
                13.598,
                13.597,
                13.596,
                13.595,
                13.595,
                13.594,
                13.593,
                13.593,
                13.592,
                13.591,
                13.591,
                13.59,
                13.589,
                13.588,
                13.587,
                13.587,
                13.586,
                13.585,
                13.585,
                13.584,
                13.583,
                13.583,
                13.582,
                13.581,
                13.58,
                13.58,
                13.579,
                13.578,
                13.577,
                13.577,
                13.576,
                13.575,
                13.575,
                13.574,
                13.573,
                13.572,
                13.572,
                13.571,
                13.57,
                13.57,
                13.569,
                13.568,
                13.567,
                13.567,
                13.566,
                13.565,
                13.565,
                13.564,
                13.563,
                13.562,
                13.562,
                13.561,
                13.56,
                13.56,
                13.559,
                13.558,
                13.557,
                13.557,
                13.556,
                13.555,
                13.554,
                13.554,
                13.553,
                13.552,
                13.551,
                13.551,
                13.55,
                13.549,
                13.549,
                13.548,
                13.547,
                13.546,
                13.546,
                13.545,
                13.544,
                13.543,
                13.543,
                13.542,
                13.541,
                13.541,
                13.54,
                13.539,
                13.538,
                13.538,
                13.537,
                13.536,
                13.535,
                13.535,
                13.534,
                13.533,
                13.532,
                13.532,
                13.531,
                13.53,
                13.53,
                13.529,
                13.528,
                13.527,
                13.527,
                13.526,
                13.525,
                13.524,
                13.524,
                13.523,
                13.522,
                13.521,
                13.521,
                13.52,
                13.519,
                13.519,
                13.518,
                13.517,
                13.516,
                13.516,
                13.515,
                13.514,
                13.513,
                13.513,
                13.512,
                13.511,
                13.511,
                13.51,
                13.509,
                13.508,
                13.508,
                13.507,
                13.506,
                13.505,
                13.505,
                13.504,
                13.503,
                13.503,
                13.502,
                13.501,
                13.5,
                13.499,
                13.499,
                13.498,
                13.497,
                13.497,
                13.496,
                13.495,
                13.494,
                13.494,
                13.493,
                13.492,
                13.491,
                13.491,
                13.49,
                13.489,
                13.489,
                13.488,
                13.487,
                13.486,
                13.486,
                13.485,
                13.484,
                13.483,
                13.483,
                13.482,
                13.481,
                13.48,
                13.48,
                13.479,
                13.478,
                13.478,
                13.477,
                13.476,
                13.475,
                13.475,
                13.474,
                13.473,
                13.472,
                13.472,
                13.471,
                13.47,
                13.47,
                13.469,
                13.468,
                13.467,
                13.467,
                13.466,
                13.465,
                13.465,
                13.464,
                13.463,
                13.462,
                13.462,
                13.461,
                13.46,
                13.459,
                13.459,
                13.458,
                13.457,
                13.456,
                13.456,
                13.455,
                13.454,
                13.454,
                13.453,
                13.452,
                13.451,
                13.451,
                13.45,
                13.449,
                13.449,
                13.448,
                13.447,
                13.446,
                13.446,
                13.445,
                13.444,
                13.443,
                13.443,
                13.442,
                13.441,
                13.441,
                13.44,
                13.439,
                13.438,
                13.438,
                13.437,
                13.436,
                13.435,
                13.435,
                13.434,
                13.433,
                13.432,
                13.432,
                13.431,
                13.43,
                13.43,
                13.429,
                13.428,
                13.428,
                13.427,
                13.426,
                13.425,
                13.425,
                13.424,
                13.423,
                13.422,
                13.422,
                13.421,
                13.42,
                13.42,
                13.419,
                13.418,
                13.417,
                13.417,
                13.416,
                13.415,
                13.414,
                13.414,
                13.413,
                13.412,
                13.412,
                13.411,
                13.41,
                13.41,
                13.409,
                13.408,
                13.407,
                13.407,
                13.406,
                13.405,
                13.404,
                13.404,
                13.403,
                13.402,
                13.402,
                13.401,
                13.4,
                13.399,
                13.399,
                13.398,
                13.397,
                13.397,
                13.396,
                13.395,
                13.394,
                13.394,
                13.393,
                13.392,
                13.392,
                13.391,
                13.39,
                13.389,
                13.389,
                13.388,
                13.387,
                13.386,
                13.386,
                13.385,
                13.384,
                13.383,
                13.383,
                13.382,
                13.381,
                13.381,
                13.38,
                13.379,
                13.379,
                13.378,
                13.377,
                13.376,
                13.376,
                13.375,
                13.374,
                13.374,
                13.373,
                13.372,
                13.371,
                13.371,
                13.37,
                13.369,
                13.368,
                13.368,
                13.367,
                13.366,
                13.366,
                13.365,
                13.364,
                13.364,
                13.363,
                13.362,
                13.361,
                13.361,
                13.36,
                13.359,
                13.359,
                13.358,
                13.357,
                13.356,
                13.356,
                13.355,
                13.354,
                13.353,
                13.353,
                13.352,
                13.351,
                13.351,
                13.35,
                13.349,
                13.349,
                13.348,
                13.347,
                13.346,
                13.346,
                13.345,
                13.344,
                13.344,
                13.343,
                13.342,
                13.341,
                13.341,
                13.34,
                13.339,
                13.339,
                13.338,
                13.337,
                13.336,
                13.336,
                13.335,
                13.334,
                13.334,
                13.333,
                13.332,
                13.331,
                13.331,
                13.33,
                13.329,
                13.329,
                13.328,
                13.327,
                13.326,
                13.326,
                13.325,
                13.324,
                13.324,
                13.323,
                13.322,
                13.321,
                13.321,
                13.32,
                13.319,
                13.319,
                13.318,
                13.317,
                13.317,
                13.316,
                13.315,
                13.314,
                13.314,
                13.313,
                13.312,
                13.312,
                13.311,
                13.31,
                13.31,
                13.309,
                13.308,
                13.307,
                13.307,
                13.306,
                13.305,
                13.305,
                13.304,
                13.303,
                13.303,
                13.302,
                13.301,
                13.301,
                13.3,
                13.299,
                13.298,
                13.298,
                13.297,
                13.296,
                13.296,
                13.295,
                13.294,
                13.293,
                13.293,
                13.292,
                13.291,
                13.291,
                13.29,
                13.289,
                13.289,
                13.288,
                13.287,
                13.287,
                13.286,
                13.285,
                13.285,
                13.284,
                13.283,
                13.283,
                13.282,
                13.281,
                13.281,
                13.28,
                13.279,
                13.279,
                13.278,
                13.277,
                13.277,
                13.276,
                13.275,
                13.274,
                13.274,
                13.273,
                13.272,
                13.272,
                13.271,
                13.27,
                13.27,
                13.269,
                13.268,
                13.268,
                13.267,
                13.267,
                13.266,
                13.265,
                13.265,
                13.264,
                13.263,
                13.263,
                13.262,
                13.261,
                13.261,
                13.26,
                13.259,
                13.259,
                13.258,
                13.258,
                13.257,
                13.256,
                13.256,
                13.255,
                13.254,
                13.254,
                13.253,
                13.252,
                13.252,
                13.251,
                13.251,
                13.25,
                13.249,
                13.249,
                13.248,
                13.247,
                13.247,
                13.246,
                13.246,
                13.245,
                13.244,
                13.244,
                13.243,
                13.242,
                13.242,
                13.241,
                13.241,
                13.24,
                13.239,
                13.239,
                13.238,
                13.238,
                13.237,
                13.236,
                13.236,
                13.235,
                13.235,
                13.234,
                13.233,
                13.233,
                13.232,
                13.232,
                13.231,
                13.23,
                13.23,
                13.229,
                13.229,
                13.228,
                13.228,
                13.227,
                13.226,
                13.226,
                13.225,
                13.225,
                13.224,
                13.223,
                13.223,
                13.222,
                13.222,
                13.221,
                13.221,
                13.22,
                13.219,
                13.219,
                13.218,
                13.218,
                13.217,
                13.217,
                13.216,
                13.215,
                13.215,
                13.214,
                13.214,
                13.213,
                13.213,
                13.212,
                13.212,
                13.211,
                13.211,
                13.21,
                13.209,
                13.209,
                13.208,
                13.208,
                13.207,
                13.207,
                13.206,
                13.206,
                13.205,
                13.205,
                13.204,
                13.204,
                13.203,
                13.202,
                13.202,
                13.201,
                13.201,
                13.2,
                13.2,
                13.199,
                13.199,
                13.198,
                13.198,
                13.197,
                13.197,
                13.196,
                13.196,
                13.195,
                13.195,
                13.194,
                13.194,
                13.193,
                13.192,
                13.192,
                13.191,
                13.191,
                13.19,
                13.19,
                13.189,
                13.189,
                13.188,
                13.188,
                13.188,
                13.187,
                13.186,
                13.186,
                13.186,
                13.185,
                13.184,
                13.184,
                13.183,
                13.183,
                13.183,
                13.182,
                13.181,
                13.181,
                13.18,
                13.18,
                13.18,
                13.179,
                13.179,
                13.178,
                13.178,
                13.177,
                13.177,
                13.176,
                13.176,
                13.175,
                13.175,
                13.174,
                13.174,
                13.173,
                13.173,
                13.173,
                13.172,
                13.172,
                13.171,
                13.171,
                13.17,
                13.17,
                13.17,
                13.169,
                13.169,
                13.168,
                13.168,
                13.167,
                13.167,
                13.166,
                13.166,
                13.166,
                13.165,
                13.165,
                13.164,
                13.164,
                13.163,
                13.163,
                13.162,
                13.162,
                13.162,
                13.161,
                13.161,
                13.161,
                13.16,
                13.16,
                13.159,
                13.159,
                13.158,
                13.158,
                13.157,
                13.157,
                13.157,
                13.156,
                13.156,
                13.156,
                13.155,
                13.155,
                13.154,
                13.154,
                13.154,
                13.153,
                13.153,
                13.152,
                13.152,
                13.152,
                13.151,
                13.151,
                13.15,
                13.15,
                13.15,
                13.149,
                13.149,
                13.148,
                13.148,
                13.148,
                13.147,
                13.147,
                13.147,
                13.146,
                13.146,
                13.145,
                13.145,
                13.145,
                13.144,
                13.144,
                13.144,
                13.143,
                13.143,
                13.143,
                13.142,
                13.142,
                13.142,
                13.141,
                13.141,
                13.141,
                13.14,
                13.14,
                13.14,
                13.139,
                13.139,
                13.138,
                13.138,
                13.138,
                13.138,
                13.137,
                13.137,
                13.137,
                13.136,
                13.136,
                13.136,
                13.135,
                13.135,
                13.135,
                13.134,
                13.134,
                13.134,
                13.133,
                13.133,
                13.133,
                13.132,
                13.132,
                13.132,
                13.131,
                13.131,
                13.131,
                13.131,
                13.13,
                13.13,
                13.13,
                13.129,
                13.129,
                13.129,
                13.128,
                13.128,
                13.128,
                13.128,
                13.127,
                13.127,
                13.127,
                13.126,
                13.126,
                13.126,
                13.126,
                13.125,
                13.125,
                13.125,
                13.125,
                13.124,
                13.124,
                13.124,
                13.123,
                13.123,
                13.123,
                13.123,
                13.122,
                13.122,
                13.122,
                13.122,
                13.121,
                13.121,
                13.121,
                13.121,
                13.12,
                13.12,
                13.12,
                13.119,
                13.119,
                13.119,
                13.119,
                13.118,
                13.118,
                13.118,
                13.118,
                13.118,
                13.117,
                13.117,
                13.117,
                13.117,
                13.116,
                13.116,
                13.116,
                13.116,
                13.115,
                13.115,
                13.115,
                13.115,
                13.114,
                13.114,
                13.114,
                13.114,
                13.114,
                13.113,
                13.113,
                13.113,
                13.113,
                13.112,
                13.112,
                13.112,
                13.112,
                13.112,
                13.111,
                13.111,
                13.111,
                13.111,
                13.111,
                13.11,
                13.11,
                13.11,
                13.11,
                13.11,
                13.109,
                13.109,
                13.109,
                13.109,
                13.108,
                13.108,
                13.108,
                13.108,
                13.108,
                13.108,
                13.107,
                13.107,
                13.107,
                13.107,
                13.107,
                13.106,
                13.106,
                13.106,
                13.106,
                13.106,
                13.106,
                13.105,
                13.105,
                13.105,
                13.105,
                13.105,
                13.104,
                13.104,
                13.104,
                13.104,
                13.104,
                13.104,
                13.104,
                13.103,
                13.103,
                13.103,
                13.103,
                13.103,
                13.102,
                13.102,
                13.102,
                13.102,
                13.102,
                13.102,
                13.102,
                13.101,
                13.101,
                13.101,
                13.101,
                13.101,
                13.101,
                13.101,
                13.1,
                13.1,
                13.1,
                13.1,
                13.1,
                13.1,
                13.099,
                13.099,
                13.099,
                13.099,
                13.099,
                13.099,
                13.099,
                13.098,
                13.098,
                13.098,
                13.098,
                13.098,
                13.098,
                13.098,
                13.098,
                13.097,
                13.097,
                13.097,
                13.097,
                13.097,
                13.097,
                13.097,
                13.096,
                13.096,
                13.096,
                13.096,
                13.096,
                13.096,
                13.096,
                13.096,
                13.096,
                13.095,
                13.095,
                13.095,
                13.095,
                13.095,
                13.095,
                13.095,
                13.095,
                13.094,
                13.094,
                13.094,
                13.094,
                13.094,
                13.094,
                13.094,
                13.094,
                13.094,
                13.094,
                13.094,
                13.094,
                13.093,
                13.093,
                13.093,
                13.093,
                13.093,
                13.093,
                13.093,
                13.093,
                13.093,
                13.092,
                13.092,
                13.092,
                13.092,
                13.092,
                13.092,
                13.092,
                13.092,
                13.092,
                13.092,
                13.091,
                13.091,
                13.091,
                13.091,
                13.091,
                13.091,
                13.091,
                13.091
            ],
            P10: [
                11.847,
                11.81,
                11.772,
                11.734,
                11.695,
                11.656,
                11.617,
                11.577,
                11.612,
                11.647,
                11.682,
                11.717,
                11.752,
                11.787,
                11.823,
                11.885,
                11.951,
                12.018,
                12.085,
                12.153,
                12.22,
                12.287,
                12.352,
                12.417,
                12.48,
                12.542,
                12.602,
                12.661,
                12.719,
                12.776,
                12.831,
                12.884,
                12.936,
                12.987,
                13.035,
                13.083,
                13.129,
                13.174,
                13.217,
                13.26,
                13.301,
                13.341,
                13.38,
                13.419,
                13.456,
                13.492,
                13.528,
                13.563,
                13.596,
                13.629,
                13.661,
                13.692,
                13.723,
                13.752,
                13.782,
                13.81,
                13.838,
                13.865,
                13.892,
                13.918,
                13.944,
                13.969,
                13.993,
                14.017,
                14.041,
                14.064,
                14.086,
                14.108,
                14.13,
                14.151,
                14.171,
                14.191,
                14.211,
                14.23,
                14.249,
                14.268,
                14.286,
                14.303,
                14.321,
                14.338,
                14.354,
                14.371,
                14.387,
                14.402,
                14.418,
                14.433,
                14.448,
                14.462,
                14.476,
                14.49,
                14.504,
                14.517,
                14.53,
                14.543,
                14.556,
                14.569,
                14.581,
                14.593,
                14.604,
                14.616,
                14.627,
                14.638,
                14.649,
                14.659,
                14.67,
                14.68,
                14.69,
                14.7,
                14.709,
                14.719,
                14.728,
                14.737,
                14.746,
                14.755,
                14.763,
                14.772,
                14.78,
                14.788,
                14.796,
                14.804,
                14.811,
                14.819,
                14.826,
                14.834,
                14.841,
                14.848,
                14.855,
                14.862,
                14.868,
                14.875,
                14.881,
                14.888,
                14.894,
                14.9,
                14.906,
                14.912,
                14.918,
                14.923,
                14.928,
                14.934,
                14.939,
                14.944,
                14.949,
                14.954,
                14.959,
                14.964,
                14.968,
                14.973,
                14.977,
                14.982,
                14.986,
                14.99,
                14.994,
                14.998,
                15.002,
                15.005,
                15.009,
                15.013,
                15.016,
                15.019,
                15.023,
                15.026,
                15.029,
                15.032,
                15.035,
                15.038,
                15.04,
                15.043,
                15.046,
                15.048,
                15.051,
                15.053,
                15.055,
                15.057,
                15.059,
                15.061,
                15.063,
                15.065,
                15.067,
                15.068,
                15.07,
                15.071,
                15.073,
                15.074,
                15.076,
                15.077,
                15.078,
                15.079,
                15.08,
                15.081,
                15.082,
                15.082,
                15.083,
                15.084,
                15.084,
                15.085,
                15.085,
                15.085,
                15.086,
                15.086,
                15.086,
                15.086,
                15.086,
                15.086,
                15.086,
                15.086,
                15.086,
                15.086,
                15.085,
                15.085,
                15.084,
                15.084,
                15.083,
                15.083,
                15.082,
                15.081,
                15.08,
                15.08,
                15.079,
                15.078,
                15.077,
                15.076,
                15.075,
                15.074,
                15.072,
                15.071,
                15.07,
                15.069,
                15.067,
                15.066,
                15.064,
                15.063,
                15.061,
                15.06,
                15.058,
                15.056,
                15.055,
                15.053,
                15.051,
                15.049,
                15.047,
                15.045,
                15.043,
                15.041,
                15.039,
                15.037,
                15.035,
                15.033,
                15.031,
                15.028,
                15.026,
                15.024,
                15.022,
                15.019,
                15.017,
                15.014,
                15.012,
                15.009,
                15.007,
                15.004,
                15.002,
                14.999,
                14.996,
                14.994,
                14.991,
                14.988,
                14.985,
                14.983,
                14.98,
                14.977,
                14.974,
                14.971,
                14.968,
                14.965,
                14.962,
                14.959,
                14.956,
                14.953,
                14.95,
                14.947,
                14.944,
                14.941,
                14.938,
                14.934,
                14.931,
                14.928,
                14.925,
                14.922,
                14.918,
                14.915,
                14.912,
                14.908,
                14.905,
                14.902,
                14.899,
                14.895,
                14.892,
                14.888,
                14.885,
                14.882,
                14.878,
                14.875,
                14.871,
                14.868,
                14.864,
                14.861,
                14.858,
                14.854,
                14.85,
                14.847,
                14.843,
                14.84,
                14.837,
                14.833,
                14.829,
                14.826,
                14.822,
                14.819,
                14.815,
                14.812,
                14.808,
                14.805,
                14.801,
                14.797,
                14.794,
                14.79,
                14.787,
                14.783,
                14.779,
                14.776,
                14.772,
                14.769,
                14.765,
                14.762,
                14.758,
                14.754,
                14.751,
                14.747,
                14.744,
                14.74,
                14.736,
                14.733,
                14.729,
                14.726,
                14.722,
                14.718,
                14.715,
                14.711,
                14.708,
                14.704,
                14.7,
                14.697,
                14.693,
                14.69,
                14.686,
                14.682,
                14.679,
                14.675,
                14.672,
                14.668,
                14.665,
                14.661,
                14.658,
                14.654,
                14.651,
                14.647,
                14.643,
                14.64,
                14.636,
                14.633,
                14.629,
                14.626,
                14.622,
                14.619,
                14.615,
                14.612,
                14.609,
                14.605,
                14.602,
                14.598,
                14.595,
                14.591,
                14.588,
                14.584,
                14.581,
                14.577,
                14.574,
                14.57,
                14.567,
                14.564,
                14.56,
                14.557,
                14.554,
                14.55,
                14.547,
                14.543,
                14.54,
                14.537,
                14.533,
                14.53,
                14.527,
                14.523,
                14.52,
                14.517,
                14.513,
                14.51,
                14.507,
                14.503,
                14.5,
                14.497,
                14.494,
                14.49,
                14.487,
                14.484,
                14.48,
                14.477,
                14.474,
                14.471,
                14.468,
                14.464,
                14.461,
                14.458,
                14.455,
                14.452,
                14.449,
                14.445,
                14.442,
                14.439,
                14.436,
                14.433,
                14.43,
                14.427,
                14.424,
                14.42,
                14.417,
                14.414,
                14.411,
                14.408,
                14.405,
                14.402,
                14.399,
                14.396,
                14.393,
                14.39,
                14.387,
                14.384,
                14.381,
                14.378,
                14.375,
                14.372,
                14.369,
                14.366,
                14.363,
                14.36,
                14.357,
                14.354,
                14.351,
                14.348,
                14.346,
                14.343,
                14.34,
                14.337,
                14.334,
                14.331,
                14.328,
                14.325,
                14.322,
                14.32,
                14.317,
                14.314,
                14.311,
                14.308,
                14.306,
                14.303,
                14.3,
                14.297,
                14.295,
                14.292,
                14.289,
                14.286,
                14.284,
                14.281,
                14.278,
                14.276,
                14.273,
                14.27,
                14.267,
                14.265,
                14.262,
                14.259,
                14.257,
                14.254,
                14.252,
                14.249,
                14.246,
                14.244,
                14.241,
                14.238,
                14.236,
                14.233,
                14.231,
                14.228,
                14.226,
                14.223,
                14.221,
                14.218,
                14.216,
                14.213,
                14.211,
                14.208,
                14.206,
                14.203,
                14.201,
                14.198,
                14.196,
                14.193,
                14.191,
                14.189,
                14.186,
                14.184,
                14.181,
                14.179,
                14.177,
                14.174,
                14.172,
                14.17,
                14.167,
                14.165,
                14.163,
                14.16,
                14.158,
                14.156,
                14.153,
                14.151,
                14.149,
                14.147,
                14.144,
                14.142,
                14.14,
                14.138,
                14.135,
                14.133,
                14.131,
                14.129,
                14.126,
                14.124,
                14.122,
                14.12,
                14.118,
                14.116,
                14.113,
                14.111,
                14.109,
                14.107,
                14.105,
                14.103,
                14.101,
                14.099,
                14.097,
                14.094,
                14.092,
                14.09,
                14.088,
                14.086,
                14.084,
                14.082,
                14.08,
                14.078,
                14.076,
                14.074,
                14.072,
                14.07,
                14.068,
                14.066,
                14.064,
                14.062,
                14.06,
                14.058,
                14.056,
                14.055,
                14.053,
                14.051,
                14.049,
                14.047,
                14.045,
                14.043,
                14.041,
                14.039,
                14.038,
                14.036,
                14.034,
                14.032,
                14.03,
                14.029,
                14.027,
                14.025,
                14.023,
                14.021,
                14.02,
                14.018,
                14.016,
                14.014,
                14.012,
                14.011,
                14.009,
                14.007,
                14.006,
                14.004,
                14.002,
                14.001,
                13.999,
                13.997,
                13.995,
                13.994,
                13.992,
                13.991,
                13.989,
                13.987,
                13.986,
                13.984,
                13.982,
                13.981,
                13.979,
                13.978,
                13.976,
                13.975,
                13.973,
                13.971,
                13.97,
                13.968,
                13.967,
                13.966,
                13.964,
                13.963,
                13.961,
                13.96,
                13.958,
                13.957,
                13.955,
                13.954,
                13.952,
                13.951,
                13.949,
                13.948,
                13.947,
                13.945,
                13.944,
                13.943,
                13.941,
                13.94,
                13.938,
                13.937,
                13.936,
                13.934,
                13.933,
                13.932,
                13.93,
                13.929,
                13.928,
                13.927,
                13.925,
                13.924,
                13.923,
                13.922,
                13.92,
                13.919,
                13.918,
                13.917,
                13.915,
                13.914,
                13.913,
                13.912,
                13.911,
                13.909,
                13.908,
                13.907,
                13.906,
                13.905,
                13.904,
                13.903,
                13.901,
                13.901,
                13.899,
                13.898,
                13.897,
                13.896,
                13.895,
                13.894,
                13.893,
                13.892,
                13.891,
                13.89,
                13.889,
                13.888,
                13.887,
                13.886,
                13.885,
                13.884,
                13.883,
                13.882,
                13.881,
                13.88,
                13.879,
                13.878,
                13.877,
                13.876,
                13.875,
                13.874,
                13.873,
                13.873,
                13.871,
                13.871,
                13.87,
                13.869,
                13.868,
                13.867,
                13.866,
                13.865,
                13.865,
                13.864,
                13.863,
                13.862,
                13.861,
                13.86,
                13.86,
                13.859,
                13.858,
                13.857,
                13.856,
                13.856,
                13.855,
                14.122,
                14.122,
                14.121,
                14.12,
                14.119,
                14.118,
                14.117,
                14.116,
                14.115,
                14.114,
                14.114,
                14.113,
                14.112,
                14.111,
                14.11,
                14.109,
                14.108,
                14.108,
                14.107,
                14.106,
                14.105,
                14.104,
                14.103,
                14.102,
                14.101,
                14.101,
                14.1,
                14.099,
                14.098,
                14.097,
                14.096,
                14.096,
                14.095,
                14.094,
                14.093,
                14.092,
                14.092,
                14.091,
                14.09,
                14.089,
                14.088,
                14.087,
                14.086,
                14.086,
                14.085,
                14.084,
                14.083,
                14.083,
                14.082,
                14.081,
                14.08,
                14.079,
                14.078,
                14.078,
                14.077,
                14.076,
                14.075,
                14.074,
                14.074,
                14.073,
                14.072,
                14.071,
                14.07,
                14.07,
                14.069,
                14.068,
                14.067,
                14.066,
                14.066,
                14.065,
                14.064,
                14.063,
                14.062,
                14.062,
                14.061,
                14.06,
                14.059,
                14.059,
                14.058,
                14.057,
                14.056,
                14.055,
                14.055,
                14.054,
                14.053,
                14.052,
                14.051,
                14.051,
                14.05,
                14.049,
                14.048,
                14.048,
                14.047,
                14.046,
                14.045,
                14.045,
                14.044,
                14.043,
                14.042,
                14.041,
                14.041,
                14.04,
                14.039,
                14.039,
                14.038,
                14.037,
                14.036,
                14.035,
                14.035,
                14.034,
                14.033,
                14.032,
                14.032,
                14.031,
                14.03,
                14.029,
                14.029,
                14.028,
                14.027,
                14.026,
                14.026,
                14.025,
                14.024,
                14.023,
                14.023,
                14.022,
                14.021,
                14.02,
                14.02,
                14.019,
                14.018,
                14.017,
                14.017,
                14.016,
                14.015,
                14.014,
                14.014,
                14.013,
                14.012,
                14.012,
                14.011,
                14.01,
                14.009,
                14.009,
                14.008,
                14.007,
                14.006,
                14.006,
                14.005,
                14.004,
                14.003,
                14.003,
                14.002,
                14.001,
                14,
                14,
                13.999,
                13.998,
                13.997,
                13.997,
                13.996,
                13.995,
                13.994,
                13.994,
                13.993,
                13.992,
                13.992,
                13.991,
                13.99,
                13.989,
                13.989,
                13.988,
                13.987,
                13.986,
                13.986,
                13.985,
                13.984,
                13.983,
                13.983,
                13.982,
                13.981,
                13.98,
                13.98,
                13.979,
                13.978,
                13.978,
                13.977,
                13.976,
                13.975,
                13.975,
                13.974,
                13.973,
                13.972,
                13.972,
                13.971,
                13.97,
                13.969,
                13.969,
                13.968,
                13.967,
                13.966,
                13.966,
                13.965,
                13.964,
                13.964,
                13.963,
                13.962,
                13.961,
                13.961,
                13.96,
                13.959,
                13.958,
                13.958,
                13.957,
                13.956,
                13.955,
                13.955,
                13.954,
                13.953,
                13.952,
                13.952,
                13.951,
                13.95,
                13.949,
                13.949,
                13.948,
                13.947,
                13.947,
                13.946,
                13.945,
                13.944,
                13.944,
                13.943,
                13.942,
                13.941,
                13.941,
                13.94,
                13.939,
                13.938,
                13.938,
                13.937,
                13.936,
                13.935,
                13.935,
                13.934,
                13.933,
                13.933,
                13.932,
                13.931,
                13.93,
                13.93,
                13.929,
                13.928,
                13.927,
                13.927,
                13.926,
                13.925,
                13.924,
                13.924,
                13.923,
                13.922,
                13.921,
                13.921,
                13.92,
                13.919,
                13.919,
                13.918,
                13.917,
                13.916,
                13.916,
                13.915,
                13.914,
                13.913,
                13.913,
                13.912,
                13.911,
                13.911,
                13.91,
                13.909,
                13.908,
                13.908,
                13.907,
                13.906,
                13.905,
                13.905,
                13.904,
                13.903,
                13.902,
                13.902,
                13.901,
                13.9,
                13.9,
                13.899,
                13.898,
                13.897,
                13.897,
                13.896,
                13.895,
                13.894,
                13.894,
                13.893,
                13.892,
                13.891,
                13.891,
                13.89,
                13.889,
                13.889,
                13.888,
                13.887,
                13.886,
                13.886,
                13.885,
                13.884,
                13.884,
                13.883,
                13.882,
                13.881,
                13.881,
                13.88,
                13.879,
                13.879,
                13.878,
                13.877,
                13.876,
                13.876,
                13.875,
                13.874,
                13.873,
                13.873,
                13.872,
                13.871,
                13.871,
                13.87,
                13.869,
                13.869,
                13.868,
                13.867,
                13.866,
                13.866,
                13.865,
                13.864,
                13.863,
                13.863,
                13.862,
                13.861,
                13.861,
                13.86,
                13.859,
                13.859,
                13.858,
                13.857,
                13.856,
                13.856,
                13.855,
                13.854,
                13.854,
                13.853,
                13.852,
                13.851,
                13.851,
                13.85,
                13.849,
                13.849,
                13.848,
                13.847,
                13.846,
                13.846,
                13.845,
                13.844,
                13.844,
                13.843,
                13.842,
                13.842,
                13.841,
                13.84,
                13.84,
                13.839,
                13.838,
                13.837,
                13.837,
                13.836,
                13.835,
                13.835,
                13.834,
                13.833,
                13.833,
                13.832,
                13.831,
                13.831,
                13.83,
                13.829,
                13.828,
                13.828,
                13.827,
                13.826,
                13.826,
                13.825,
                13.824,
                13.824,
                13.823,
                13.822,
                13.821,
                13.821,
                13.82,
                13.819,
                13.819,
                13.818,
                13.817,
                13.817,
                13.816,
                13.815,
                13.815,
                13.814,
                13.813,
                13.813,
                13.812,
                13.811,
                13.811,
                13.81,
                13.809,
                13.809,
                13.808,
                13.807,
                13.806,
                13.806,
                13.805,
                13.804,
                13.804,
                13.803,
                13.802,
                13.802,
                13.801,
                13.8,
                13.8,
                13.799,
                13.798,
                13.798,
                13.797,
                13.796,
                13.796,
                13.795,
                13.794,
                13.794,
                13.793,
                13.792,
                13.792,
                13.791,
                13.79,
                13.79,
                13.789,
                13.788,
                13.788,
                13.787,
                13.786,
                13.785,
                13.785,
                13.784,
                13.784,
                13.783,
                13.782,
                13.782,
                13.781,
                13.78,
                13.779,
                13.779,
                13.778,
                13.777,
                13.777,
                13.776,
                13.775,
                13.775,
                13.774,
                13.774,
                13.773,
                13.772,
                13.772,
                13.771,
                13.77,
                13.77,
                13.769,
                13.768,
                13.768,
                13.767,
                13.766,
                13.766,
                13.765,
                13.764,
                13.764,
                13.763,
                13.762,
                13.761,
                13.761,
                13.76,
                13.759,
                13.759,
                13.758,
                13.757,
                13.757,
                13.756,
                13.756,
                13.755,
                13.754,
                13.754,
                13.753,
                13.752,
                13.752,
                13.751,
                13.75,
                13.75,
                13.749,
                13.748,
                13.748,
                13.747,
                13.746,
                13.746,
                13.745,
                13.744,
                13.744,
                13.743,
                13.742,
                13.742,
                13.741,
                13.74,
                13.74,
                13.739,
                13.738,
                13.738,
                13.737,
                13.736,
                13.736,
                13.735,
                13.735,
                13.734,
                13.733,
                13.733,
                13.732,
                13.731,
                13.731,
                13.73,
                13.729,
                13.729,
                13.728,
                13.727,
                13.727,
                13.726,
                13.725,
                13.725,
                13.724,
                13.724,
                13.723,
                13.722,
                13.722,
                13.721,
                13.72,
                13.72,
                13.719,
                13.718,
                13.718,
                13.717,
                13.716,
                13.716,
                13.715,
                13.715,
                13.714,
                13.713,
                13.713,
                13.712,
                13.711,
                13.711,
                13.71,
                13.709,
                13.709,
                13.708,
                13.708,
                13.707,
                13.706,
                13.706,
                13.705,
                13.704,
                13.704,
                13.703,
                13.702,
                13.702,
                13.701,
                13.701,
                13.7,
                13.699,
                13.699,
                13.698,
                13.697,
                13.697,
                13.696,
                13.695,
                13.695,
                13.694,
                13.694,
                13.693,
                13.693,
                13.692,
                13.691,
                13.691,
                13.69,
                13.689,
                13.689,
                13.688,
                13.688,
                13.687,
                13.686,
                13.686,
                13.685,
                13.685,
                13.684,
                13.683,
                13.683,
                13.682,
                13.681,
                13.681,
                13.68,
                13.68,
                13.679,
                13.678,
                13.678,
                13.677,
                13.677,
                13.676,
                13.676,
                13.675,
                13.674,
                13.674,
                13.673,
                13.673,
                13.672,
                13.671,
                13.671,
                13.67,
                13.67,
                13.669,
                13.668,
                13.668,
                13.667,
                13.667,
                13.666,
                13.666,
                13.665,
                13.664,
                13.664,
                13.663,
                13.663,
                13.662,
                13.662,
                13.661,
                13.661,
                13.66,
                13.659,
                13.659,
                13.658,
                13.658,
                13.657,
                13.657,
                13.656,
                13.655,
                13.655,
                13.654,
                13.654,
                13.653,
                13.653,
                13.652,
                13.652,
                13.651,
                13.651,
                13.65,
                13.649,
                13.649,
                13.648,
                13.648,
                13.647,
                13.647,
                13.646,
                13.646,
                13.645,
                13.645,
                13.644,
                13.644,
                13.643,
                13.642,
                13.642,
                13.642,
                13.641,
                13.64,
                13.64,
                13.639,
                13.639,
                13.638,
                13.638,
                13.637,
                13.637,
                13.636,
                13.636,
                13.635,
                13.635,
                13.634,
                13.634,
                13.633,
                13.633,
                13.632,
                13.632,
                13.631,
                13.631,
                13.63,
                13.63,
                13.629,
                13.629,
                13.628,
                13.628,
                13.627,
                13.627,
                13.626,
                13.626,
                13.625,
                13.625,
                13.624,
                13.624,
                13.623,
                13.623,
                13.622,
                13.622,
                13.621,
                13.621,
                13.62,
                13.62,
                13.62,
                13.619,
                13.619,
                13.618,
                13.618,
                13.617,
                13.617,
                13.616,
                13.616,
                13.615,
                13.615,
                13.614,
                13.614,
                13.614,
                13.613,
                13.613,
                13.612,
                13.612,
                13.611,
                13.611,
                13.61,
                13.61,
                13.61,
                13.609,
                13.609,
                13.608,
                13.608,
                13.607,
                13.607,
                13.606,
                13.606,
                13.605,
                13.605,
                13.605,
                13.604,
                13.604,
                13.603,
                13.603,
                13.603,
                13.602,
                13.602,
                13.601,
                13.601,
                13.6,
                13.6,
                13.6,
                13.599,
                13.599,
                13.598,
                13.598,
                13.598,
                13.597,
                13.597,
                13.596,
                13.596,
                13.596,
                13.595,
                13.595,
                13.594,
                13.594,
                13.594,
                13.593,
                13.593,
                13.592,
                13.592,
                13.592,
                13.591,
                13.591,
                13.59,
                13.59,
                13.59,
                13.589,
                13.589,
                13.589,
                13.588,
                13.588,
                13.587,
                13.587,
                13.587,
                13.586,
                13.586,
                13.586,
                13.585,
                13.585,
                13.585,
                13.584,
                13.584,
                13.583,
                13.583,
                13.583,
                13.582,
                13.582,
                13.582,
                13.581,
                13.581,
                13.581,
                13.58,
                13.58,
                13.58,
                13.579,
                13.579,
                13.579,
                13.578,
                13.578,
                13.578,
                13.577,
                13.577,
                13.577,
                13.576,
                13.576,
                13.576,
                13.575,
                13.575,
                13.575,
                13.574,
                13.574,
                13.574,
                13.573,
                13.573,
                13.573,
                13.572,
                13.572,
                13.572,
                13.571,
                13.571,
                13.571,
                13.571,
                13.57,
                13.57,
                13.57,
                13.569,
                13.569,
                13.569,
                13.569,
                13.568,
                13.568,
                13.568,
                13.567,
                13.567,
                13.567,
                13.567,
                13.566,
                13.566,
                13.566,
                13.565,
                13.565,
                13.565,
                13.565,
                13.564,
                13.564,
                13.564,
                13.563,
                13.563,
                13.563,
                13.563,
                13.562,
                13.562,
                13.562,
                13.562,
                13.561,
                13.561,
                13.561,
                13.561,
                13.56,
                13.56,
                13.56,
                13.56,
                13.559,
                13.559,
                13.559,
                13.559,
                13.558,
                13.558,
                13.558,
                13.558,
                13.557,
                13.557,
                13.557,
                13.557,
                13.557,
                13.556,
                13.556,
                13.556,
                13.556,
                13.555,
                13.555,
                13.555,
                13.555,
                13.554,
                13.554,
                13.554,
                13.554,
                13.554,
                13.553,
                13.553,
                13.553,
                13.553,
                13.552,
                13.552,
                13.552,
                13.552,
                13.552,
                13.551,
                13.551,
                13.551,
                13.551,
                13.551,
                13.551,
                13.55,
                13.55,
                13.55,
                13.55,
                13.55,
                13.549,
                13.549,
                13.549,
                13.549,
                13.549,
                13.548,
                13.548,
                13.548,
                13.548,
                13.548,
                13.547,
                13.547,
                13.547,
                13.547,
                13.547,
                13.546,
                13.546,
                13.546,
                13.546,
                13.546,
                13.546,
                13.546,
                13.545,
                13.545,
                13.545,
                13.545,
                13.545,
                13.545,
                13.544,
                13.544,
                13.544,
                13.544,
                13.544,
                13.544,
                13.543,
                13.543,
                13.543,
                13.543,
                13.543,
                13.543,
                13.542,
                13.542,
                13.542,
                13.542,
                13.542,
                13.542,
                13.542,
                13.541,
                13.541,
                13.541,
                13.541,
                13.541,
                13.541,
                13.541,
                13.541,
                13.54,
                13.54,
                13.54,
                13.54,
                13.54,
                13.54,
                13.54,
                13.54,
                13.539,
                13.539,
                13.539,
                13.539,
                13.539,
                13.539,
                13.539,
                13.539,
                13.538,
                13.538,
                13.538,
                13.538,
                13.538,
                13.538,
                13.538,
                13.538,
                13.537,
                13.537,
                13.537,
                13.537,
                13.537,
                13.537,
                13.537,
                13.537,
                13.537,
                13.536,
                13.536,
                13.536,
                13.536,
                13.536,
                13.536,
                13.536,
                13.536,
                13.536,
                13.536,
                13.536,
                13.535,
                13.535,
                13.535,
                13.535,
                13.535,
                13.535,
                13.535,
                13.535,
                13.535,
                13.534,
                13.535,
                13.534,
                13.534,
                13.534,
                13.534,
                13.534,
                13.534,
                13.534,
                13.534,
                13.534,
                13.534,
                13.533,
                13.534,
                13.533,
                13.533,
                13.533,
                13.533,
                13.533,
                13.533,
                13.533,
                13.533,
                13.533,
                13.533,
                13.533,
                13.533,
                13.533,
                13.533,
                13.532,
                13.532,
                13.532,
                13.532,
                13.532,
                13.532,
                13.532,
                13.532,
                13.532,
                13.532,
                13.532,
                13.532,
                13.532,
                13.532,
                13.532,
                13.532,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.531,
                13.53,
                13.53,
                13.53,
                13.53,
                13.53,
                13.53,
                13.53,
                13.53,
                13.53
            ],
            P15: [
                12.118,
                12.085,
                12.052,
                12.019,
                11.985,
                11.952,
                11.918,
                11.883,
                11.918,
                11.953,
                11.987,
                12.022,
                12.057,
                12.091,
                12.126,
                12.189,
                12.255,
                12.323,
                12.392,
                12.46,
                12.528,
                12.595,
                12.662,
                12.727,
                12.791,
                12.854,
                12.916,
                12.976,
                13.034,
                13.092,
                13.148,
                13.202,
                13.254,
                13.305,
                13.355,
                13.403,
                13.45,
                13.495,
                13.539,
                13.582,
                13.624,
                13.665,
                13.704,
                13.743,
                13.781,
                13.818,
                13.854,
                13.889,
                13.923,
                13.956,
                13.989,
                14.02,
                14.051,
                14.081,
                14.111,
                14.139,
                14.168,
                14.195,
                14.222,
                14.248,
                14.274,
                14.3,
                14.324,
                14.349,
                14.372,
                14.396,
                14.418,
                14.44,
                14.462,
                14.483,
                14.504,
                14.524,
                14.544,
                14.564,
                14.583,
                14.601,
                14.62,
                14.637,
                14.655,
                14.672,
                14.689,
                14.705,
                14.721,
                14.737,
                14.753,
                14.768,
                14.783,
                14.797,
                14.812,
                14.826,
                14.839,
                14.853,
                14.866,
                14.879,
                14.892,
                14.904,
                14.916,
                14.928,
                14.94,
                14.952,
                14.963,
                14.974,
                14.985,
                14.995,
                15.006,
                15.016,
                15.026,
                15.036,
                15.046,
                15.055,
                15.064,
                15.073,
                15.082,
                15.091,
                15.099,
                15.108,
                15.116,
                15.124,
                15.132,
                15.14,
                15.148,
                15.155,
                15.163,
                15.17,
                15.177,
                15.184,
                15.191,
                15.198,
                15.204,
                15.211,
                15.217,
                15.224,
                15.23,
                15.236,
                15.242,
                15.247,
                15.253,
                15.259,
                15.264,
                15.269,
                15.275,
                15.28,
                15.285,
                15.29,
                15.295,
                15.299,
                15.304,
                15.308,
                15.313,
                15.317,
                15.321,
                15.325,
                15.329,
                15.333,
                15.336,
                15.34,
                15.344,
                15.347,
                15.351,
                15.354,
                15.357,
                15.36,
                15.363,
                15.366,
                15.369,
                15.372,
                15.374,
                15.377,
                15.379,
                15.382,
                15.384,
                15.386,
                15.388,
                15.391,
                15.393,
                15.394,
                15.396,
                15.398,
                15.4,
                15.401,
                15.403,
                15.404,
                15.405,
                15.407,
                15.408,
                15.409,
                15.41,
                15.411,
                15.412,
                15.413,
                15.413,
                15.414,
                15.415,
                15.415,
                15.416,
                15.416,
                15.416,
                15.417,
                15.417,
                15.417,
                15.417,
                15.417,
                15.417,
                15.417,
                15.416,
                15.416,
                15.416,
                15.415,
                15.415,
                15.415,
                15.414,
                15.413,
                15.413,
                15.412,
                15.411,
                15.41,
                15.409,
                15.408,
                15.407,
                15.406,
                15.405,
                15.404,
                15.403,
                15.402,
                15.4,
                15.399,
                15.397,
                15.396,
                15.395,
                15.393,
                15.391,
                15.39,
                15.388,
                15.386,
                15.385,
                15.383,
                15.381,
                15.379,
                15.377,
                15.375,
                15.373,
                15.371,
                15.369,
                15.367,
                15.364,
                15.362,
                15.36,
                15.358,
                15.355,
                15.353,
                15.35,
                15.348,
                15.346,
                15.343,
                15.341,
                15.338,
                15.335,
                15.333,
                15.33,
                15.327,
                15.324,
                15.322,
                15.319,
                15.316,
                15.313,
                15.31,
                15.307,
                15.304,
                15.301,
                15.299,
                15.295,
                15.292,
                15.289,
                15.286,
                15.283,
                15.28,
                15.277,
                15.274,
                15.27,
                15.267,
                15.264,
                15.261,
                15.257,
                15.254,
                15.251,
                15.247,
                15.244,
                15.24,
                15.237,
                15.234,
                15.23,
                15.227,
                15.223,
                15.22,
                15.216,
                15.213,
                15.209,
                15.206,
                15.202,
                15.199,
                15.195,
                15.192,
                15.188,
                15.184,
                15.181,
                15.177,
                15.174,
                15.17,
                15.166,
                15.163,
                15.159,
                15.155,
                15.152,
                15.148,
                15.144,
                15.141,
                15.137,
                15.133,
                15.129,
                15.126,
                15.122,
                15.118,
                15.115,
                15.111,
                15.107,
                15.104,
                15.1,
                15.096,
                15.092,
                15.089,
                15.085,
                15.081,
                15.077,
                15.074,
                15.07,
                15.066,
                15.062,
                15.059,
                15.055,
                15.051,
                15.047,
                15.044,
                15.04,
                15.036,
                15.032,
                15.029,
                15.025,
                15.021,
                15.017,
                15.014,
                15.01,
                15.006,
                15.003,
                14.999,
                14.995,
                14.992,
                14.988,
                14.984,
                14.98,
                14.977,
                14.973,
                14.969,
                14.966,
                14.962,
                14.958,
                14.955,
                14.951,
                14.947,
                14.944,
                14.94,
                14.936,
                14.933,
                14.929,
                14.926,
                14.922,
                14.918,
                14.915,
                14.911,
                14.908,
                14.904,
                14.9,
                14.897,
                14.893,
                14.889,
                14.886,
                14.882,
                14.879,
                14.875,
                14.872,
                14.868,
                14.865,
                14.861,
                14.858,
                14.854,
                14.851,
                14.847,
                14.843,
                14.84,
                14.837,
                14.833,
                14.83,
                14.826,
                14.823,
                14.819,
                14.816,
                14.812,
                14.809,
                14.806,
                14.802,
                14.799,
                14.795,
                14.792,
                14.789,
                14.785,
                14.782,
                14.779,
                14.775,
                14.772,
                14.769,
                14.765,
                14.762,
                14.759,
                14.755,
                14.752,
                14.749,
                14.746,
                14.742,
                14.739,
                14.736,
                14.732,
                14.729,
                14.726,
                14.723,
                14.719,
                14.716,
                14.713,
                14.71,
                14.707,
                14.704,
                14.7,
                14.697,
                14.694,
                14.691,
                14.688,
                14.685,
                14.682,
                14.678,
                14.675,
                14.672,
                14.669,
                14.666,
                14.663,
                14.66,
                14.657,
                14.654,
                14.651,
                14.648,
                14.645,
                14.642,
                14.639,
                14.636,
                14.633,
                14.63,
                14.627,
                14.624,
                14.621,
                14.618,
                14.615,
                14.612,
                14.609,
                14.606,
                14.603,
                14.601,
                14.598,
                14.595,
                14.592,
                14.589,
                14.586,
                14.583,
                14.581,
                14.578,
                14.575,
                14.572,
                14.569,
                14.566,
                14.564,
                14.561,
                14.558,
                14.555,
                14.553,
                14.55,
                14.547,
                14.545,
                14.542,
                14.539,
                14.536,
                14.534,
                14.531,
                14.528,
                14.526,
                14.523,
                14.52,
                14.518,
                14.515,
                14.512,
                14.51,
                14.507,
                14.505,
                14.502,
                14.5,
                14.497,
                14.494,
                14.492,
                14.489,
                14.487,
                14.484,
                14.482,
                14.479,
                14.477,
                14.474,
                14.472,
                14.469,
                14.467,
                14.464,
                14.462,
                14.459,
                14.457,
                14.455,
                14.452,
                14.45,
                14.447,
                14.445,
                14.443,
                14.44,
                14.438,
                14.436,
                14.433,
                14.431,
                14.429,
                14.426,
                14.424,
                14.422,
                14.419,
                14.417,
                14.415,
                14.412,
                14.41,
                14.408,
                14.406,
                14.403,
                14.401,
                14.399,
                14.397,
                14.395,
                14.392,
                14.39,
                14.388,
                14.386,
                14.384,
                14.381,
                14.379,
                14.377,
                14.375,
                14.373,
                14.371,
                14.369,
                14.367,
                14.364,
                14.362,
                14.36,
                14.358,
                14.356,
                14.354,
                14.352,
                14.35,
                14.348,
                14.346,
                14.344,
                14.342,
                14.34,
                14.338,
                14.336,
                14.334,
                14.332,
                14.33,
                14.328,
                14.326,
                14.324,
                14.322,
                14.32,
                14.319,
                14.317,
                14.315,
                14.313,
                14.311,
                14.309,
                14.307,
                14.305,
                14.304,
                14.302,
                14.3,
                14.298,
                14.296,
                14.294,
                14.293,
                14.291,
                14.289,
                14.287,
                14.286,
                14.284,
                14.282,
                14.28,
                14.279,
                14.277,
                14.275,
                14.273,
                14.272,
                14.27,
                14.268,
                14.267,
                14.265,
                14.263,
                14.262,
                14.26,
                14.258,
                14.257,
                14.255,
                14.254,
                14.252,
                14.25,
                14.249,
                14.247,
                14.246,
                14.244,
                14.243,
                14.241,
                14.24,
                14.238,
                14.237,
                14.235,
                14.234,
                14.232,
                14.231,
                14.229,
                14.228,
                14.226,
                14.225,
                14.223,
                14.222,
                14.22,
                14.219,
                14.218,
                14.216,
                14.215,
                14.214,
                14.212,
                14.211,
                14.209,
                14.208,
                14.207,
                14.205,
                14.204,
                14.203,
                14.202,
                14.2,
                14.199,
                14.198,
                14.196,
                14.195,
                14.194,
                14.193,
                14.191,
                14.19,
                14.189,
                14.188,
                14.186,
                14.185,
                14.184,
                14.183,
                14.182,
                14.181,
                14.179,
                14.178,
                14.177,
                14.176,
                14.175,
                14.174,
                14.173,
                14.171,
                14.17,
                14.169,
                14.168,
                14.167,
                14.166,
                14.165,
                14.164,
                14.163,
                14.162,
                14.161,
                14.16,
                14.159,
                14.158,
                14.157,
                14.156,
                14.155,
                14.154,
                14.153,
                14.152,
                14.151,
                14.15,
                14.149,
                14.148,
                14.147,
                14.146,
                14.145,
                14.145,
                14.144,
                14.143,
                14.142,
                14.141,
                14.14,
                14.139,
                14.138,
                14.137,
                14.137,
                14.136,
                14.135,
                14.134,
                14.133,
                14.132,
                14.132,
                14.402,
                14.401,
                14.401,
                14.4,
                14.399,
                14.398,
                14.397,
                14.396,
                14.395,
                14.394,
                14.393,
                14.392,
                14.392,
                14.391,
                14.39,
                14.389,
                14.388,
                14.387,
                14.386,
                14.385,
                14.385,
                14.384,
                14.383,
                14.382,
                14.381,
                14.38,
                14.379,
                14.379,
                14.378,
                14.377,
                14.376,
                14.375,
                14.374,
                14.373,
                14.372,
                14.372,
                14.371,
                14.37,
                14.369,
                14.368,
                14.367,
                14.367,
                14.366,
                14.365,
                14.364,
                14.363,
                14.362,
                14.362,
                14.361,
                14.36,
                14.359,
                14.358,
                14.357,
                14.357,
                14.356,
                14.355,
                14.354,
                14.353,
                14.352,
                14.352,
                14.351,
                14.35,
                14.349,
                14.348,
                14.347,
                14.347,
                14.346,
                14.345,
                14.344,
                14.343,
                14.343,
                14.342,
                14.341,
                14.34,
                14.339,
                14.339,
                14.338,
                14.337,
                14.336,
                14.335,
                14.335,
                14.334,
                14.333,
                14.332,
                14.331,
                14.331,
                14.33,
                14.329,
                14.328,
                14.328,
                14.327,
                14.326,
                14.325,
                14.324,
                14.324,
                14.323,
                14.322,
                14.321,
                14.32,
                14.32,
                14.319,
                14.318,
                14.317,
                14.317,
                14.316,
                14.315,
                14.314,
                14.313,
                14.313,
                14.312,
                14.311,
                14.31,
                14.309,
                14.309,
                14.308,
                14.307,
                14.306,
                14.306,
                14.305,
                14.304,
                14.303,
                14.303,
                14.302,
                14.301,
                14.3,
                14.3,
                14.299,
                14.298,
                14.297,
                14.296,
                14.296,
                14.295,
                14.294,
                14.294,
                14.293,
                14.292,
                14.291,
                14.29,
                14.29,
                14.289,
                14.288,
                14.287,
                14.287,
                14.286,
                14.285,
                14.284,
                14.284,
                14.283,
                14.282,
                14.281,
                14.281,
                14.28,
                14.279,
                14.278,
                14.278,
                14.277,
                14.276,
                14.275,
                14.275,
                14.274,
                14.273,
                14.272,
                14.272,
                14.271,
                14.27,
                14.269,
                14.269,
                14.268,
                14.267,
                14.266,
                14.266,
                14.265,
                14.264,
                14.263,
                14.263,
                14.262,
                14.261,
                14.26,
                14.259,
                14.259,
                14.258,
                14.257,
                14.257,
                14.256,
                14.255,
                14.254,
                14.254,
                14.253,
                14.252,
                14.251,
                14.251,
                14.25,
                14.249,
                14.248,
                14.248,
                14.247,
                14.246,
                14.245,
                14.245,
                14.244,
                14.243,
                14.242,
                14.242,
                14.241,
                14.24,
                14.239,
                14.239,
                14.238,
                14.237,
                14.236,
                14.236,
                14.235,
                14.234,
                14.233,
                14.233,
                14.232,
                14.231,
                14.23,
                14.23,
                14.229,
                14.228,
                14.227,
                14.227,
                14.226,
                14.225,
                14.224,
                14.224,
                14.223,
                14.222,
                14.222,
                14.221,
                14.22,
                14.219,
                14.219,
                14.218,
                14.217,
                14.216,
                14.216,
                14.215,
                14.214,
                14.213,
                14.212,
                14.212,
                14.211,
                14.21,
                14.21,
                14.209,
                14.208,
                14.207,
                14.207,
                14.206,
                14.205,
                14.204,
                14.204,
                14.203,
                14.202,
                14.201,
                14.201,
                14.2,
                14.199,
                14.198,
                14.198,
                14.197,
                14.196,
                14.195,
                14.195,
                14.194,
                14.193,
                14.192,
                14.192,
                14.191,
                14.19,
                14.19,
                14.189,
                14.188,
                14.187,
                14.187,
                14.186,
                14.185,
                14.184,
                14.184,
                14.183,
                14.182,
                14.182,
                14.181,
                14.18,
                14.179,
                14.179,
                14.178,
                14.177,
                14.176,
                14.176,
                14.175,
                14.174,
                14.174,
                14.173,
                14.172,
                14.171,
                14.171,
                14.17,
                14.169,
                14.169,
                14.168,
                14.167,
                14.166,
                14.166,
                14.165,
                14.164,
                14.163,
                14.163,
                14.162,
                14.161,
                14.161,
                14.16,
                14.159,
                14.158,
                14.158,
                14.157,
                14.156,
                14.156,
                14.155,
                14.154,
                14.153,
                14.153,
                14.152,
                14.151,
                14.151,
                14.15,
                14.149,
                14.149,
                14.148,
                14.147,
                14.146,
                14.146,
                14.145,
                14.144,
                14.144,
                14.143,
                14.142,
                14.142,
                14.141,
                14.14,
                14.139,
                14.139,
                14.138,
                14.137,
                14.137,
                14.136,
                14.135,
                14.135,
                14.134,
                14.133,
                14.132,
                14.132,
                14.131,
                14.13,
                14.13,
                14.129,
                14.128,
                14.128,
                14.127,
                14.126,
                14.126,
                14.125,
                14.124,
                14.123,
                14.123,
                14.122,
                14.122,
                14.121,
                14.12,
                14.119,
                14.119,
                14.118,
                14.117,
                14.117,
                14.116,
                14.115,
                14.115,
                14.114,
                14.113,
                14.113,
                14.112,
                14.111,
                14.111,
                14.11,
                14.109,
                14.109,
                14.108,
                14.107,
                14.107,
                14.106,
                14.105,
                14.105,
                14.104,
                14.103,
                14.103,
                14.102,
                14.101,
                14.101,
                14.1,
                14.099,
                14.099,
                14.098,
                14.097,
                14.097,
                14.096,
                14.095,
                14.095,
                14.094,
                14.093,
                14.093,
                14.092,
                14.091,
                14.091,
                14.09,
                14.089,
                14.089,
                14.088,
                14.087,
                14.087,
                14.086,
                14.085,
                14.085,
                14.084,
                14.084,
                14.083,
                14.082,
                14.082,
                14.081,
                14.08,
                14.08,
                14.079,
                14.078,
                14.078,
                14.077,
                14.076,
                14.076,
                14.075,
                14.074,
                14.074,
                14.073,
                14.073,
                14.072,
                14.071,
                14.071,
                14.07,
                14.069,
                14.069,
                14.068,
                14.067,
                14.067,
                14.066,
                14.066,
                14.065,
                14.064,
                14.064,
                14.063,
                14.062,
                14.062,
                14.061,
                14.06,
                14.06,
                14.059,
                14.059,
                14.058,
                14.057,
                14.057,
                14.056,
                14.055,
                14.055,
                14.054,
                14.054,
                14.053,
                14.052,
                14.052,
                14.051,
                14.05,
                14.05,
                14.049,
                14.048,
                14.048,
                14.047,
                14.046,
                14.046,
                14.045,
                14.045,
                14.044,
                14.043,
                14.043,
                14.042,
                14.042,
                14.041,
                14.04,
                14.04,
                14.039,
                14.038,
                14.038,
                14.037,
                14.037,
                14.036,
                14.035,
                14.035,
                14.034,
                14.033,
                14.033,
                14.032,
                14.032,
                14.031,
                14.03,
                14.03,
                14.029,
                14.028,
                14.028,
                14.027,
                14.027,
                14.026,
                14.026,
                14.025,
                14.024,
                14.024,
                14.023,
                14.022,
                14.022,
                14.021,
                14.021,
                14.02,
                14.019,
                14.019,
                14.018,
                14.018,
                14.017,
                14.016,
                14.016,
                14.015,
                14.014,
                14.014,
                14.013,
                14.013,
                14.012,
                14.011,
                14.011,
                14.01,
                14.01,
                14.009,
                14.008,
                14.008,
                14.007,
                14.007,
                14.006,
                14.005,
                14.005,
                14.004,
                14.004,
                14.003,
                14.002,
                14.002,
                14.001,
                14.001,
                14,
                14,
                13.999,
                13.998,
                13.998,
                13.997,
                13.997,
                13.996,
                13.995,
                13.995,
                13.994,
                13.993,
                13.993,
                13.992,
                13.992,
                13.991,
                13.991,
                13.99,
                13.989,
                13.989,
                13.988,
                13.988,
                13.987,
                13.986,
                13.986,
                13.985,
                13.985,
                13.984,
                13.984,
                13.983,
                13.982,
                13.982,
                13.981,
                13.981,
                13.98,
                13.98,
                13.979,
                13.978,
                13.978,
                13.977,
                13.977,
                13.976,
                13.976,
                13.975,
                13.974,
                13.974,
                13.973,
                13.973,
                13.972,
                13.972,
                13.971,
                13.971,
                13.97,
                13.969,
                13.969,
                13.968,
                13.968,
                13.967,
                13.967,
                13.966,
                13.965,
                13.965,
                13.964,
                13.964,
                13.963,
                13.963,
                13.962,
                13.962,
                13.961,
                13.961,
                13.96,
                13.96,
                13.959,
                13.958,
                13.958,
                13.957,
                13.957,
                13.956,
                13.956,
                13.955,
                13.955,
                13.954,
                13.954,
                13.953,
                13.953,
                13.952,
                13.952,
                13.951,
                13.95,
                13.95,
                13.949,
                13.949,
                13.948,
                13.948,
                13.947,
                13.947,
                13.946,
                13.946,
                13.945,
                13.945,
                13.944,
                13.944,
                13.943,
                13.943,
                13.942,
                13.942,
                13.941,
                13.941,
                13.94,
                13.94,
                13.939,
                13.939,
                13.938,
                13.938,
                13.937,
                13.937,
                13.936,
                13.936,
                13.935,
                13.935,
                13.934,
                13.934,
                13.933,
                13.933,
                13.932,
                13.932,
                13.932,
                13.931,
                13.931,
                13.93,
                13.93,
                13.929,
                13.929,
                13.928,
                13.928,
                13.927,
                13.927,
                13.926,
                13.926,
                13.925,
                13.925,
                13.925,
                13.924,
                13.924,
                13.923,
                13.923,
                13.922,
                13.922,
                13.921,
                13.921,
                13.92,
                13.92,
                13.92,
                13.919,
                13.919,
                13.918,
                13.918,
                13.917,
                13.917,
                13.917,
                13.916,
                13.916,
                13.915,
                13.915,
                13.914,
                13.914,
                13.913,
                13.913,
                13.913,
                13.912,
                13.912,
                13.911,
                13.911,
                13.911,
                13.91,
                13.91,
                13.909,
                13.909,
                13.908,
                13.908,
                13.908,
                13.907,
                13.907,
                13.906,
                13.906,
                13.905,
                13.905,
                13.905,
                13.904,
                13.904,
                13.904,
                13.903,
                13.903,
                13.902,
                13.902,
                13.902,
                13.901,
                13.901,
                13.9,
                13.9,
                13.9,
                13.899,
                13.899,
                13.898,
                13.898,
                13.898,
                13.897,
                13.897,
                13.897,
                13.896,
                13.896,
                13.895,
                13.895,
                13.895,
                13.894,
                13.894,
                13.893,
                13.893,
                13.893,
                13.892,
                13.892,
                13.892,
                13.891,
                13.891,
                13.891,
                13.89,
                13.89,
                13.89,
                13.889,
                13.889,
                13.889,
                13.888,
                13.888,
                13.888,
                13.887,
                13.887,
                13.887,
                13.886,
                13.886,
                13.886,
                13.885,
                13.885,
                13.885,
                13.884,
                13.884,
                13.884,
                13.883,
                13.883,
                13.883,
                13.882,
                13.882,
                13.882,
                13.881,
                13.881,
                13.881,
                13.88,
                13.88,
                13.88,
                13.879,
                13.879,
                13.879,
                13.879,
                13.878,
                13.878,
                13.878,
                13.877,
                13.877,
                13.877,
                13.876,
                13.876,
                13.876,
                13.876,
                13.875,
                13.875,
                13.875,
                13.875,
                13.874,
                13.874,
                13.874,
                13.873,
                13.873,
                13.873,
                13.872,
                13.872,
                13.872,
                13.872,
                13.871,
                13.871,
                13.871,
                13.871,
                13.87,
                13.87,
                13.87,
                13.869,
                13.869,
                13.869,
                13.869,
                13.869,
                13.868,
                13.868,
                13.868,
                13.868,
                13.867,
                13.867,
                13.867,
                13.867,
                13.866,
                13.866,
                13.866,
                13.866,
                13.865,
                13.865,
                13.865,
                13.865,
                13.864,
                13.864,
                13.864,
                13.864,
                13.864,
                13.863,
                13.863,
                13.863,
                13.863,
                13.862,
                13.862,
                13.862,
                13.862,
                13.862,
                13.861,
                13.861,
                13.861,
                13.861,
                13.861,
                13.86,
                13.86,
                13.86,
                13.86,
                13.86,
                13.859,
                13.859,
                13.859,
                13.859,
                13.859,
                13.858,
                13.858,
                13.858,
                13.858,
                13.858,
                13.857,
                13.857,
                13.857,
                13.857,
                13.857,
                13.857,
                13.856,
                13.856,
                13.856,
                13.856,
                13.856,
                13.856,
                13.855,
                13.855,
                13.855,
                13.855,
                13.855,
                13.854,
                13.854,
                13.854,
                13.854,
                13.854,
                13.854,
                13.854,
                13.853,
                13.853,
                13.853,
                13.853,
                13.853,
                13.853,
                13.852,
                13.852,
                13.852,
                13.852,
                13.852,
                13.852,
                13.852,
                13.851,
                13.851,
                13.851,
                13.851,
                13.851,
                13.851,
                13.851,
                13.85,
                13.85,
                13.85,
                13.85,
                13.85,
                13.85,
                13.85,
                13.849,
                13.849,
                13.849,
                13.849,
                13.849,
                13.849,
                13.849,
                13.849,
                13.848,
                13.848,
                13.848,
                13.848,
                13.848,
                13.848,
                13.848,
                13.848,
                13.848,
                13.847,
                13.847,
                13.847,
                13.847,
                13.847,
                13.847,
                13.847,
                13.847,
                13.847,
                13.846,
                13.846,
                13.846,
                13.846,
                13.846,
                13.846,
                13.846,
                13.846,
                13.846,
                13.846,
                13.845,
                13.845,
                13.845,
                13.845,
                13.845,
                13.845,
                13.845,
                13.845,
                13.845,
                13.845,
                13.845,
                13.844,
                13.844,
                13.844,
                13.844,
                13.844,
                13.844,
                13.844,
                13.844,
                13.844,
                13.844,
                13.844,
                13.844,
                13.843,
                13.843,
                13.843,
                13.843,
                13.843,
                13.843,
                13.843,
                13.843,
                13.843,
                13.843,
                13.843,
                13.843,
                13.843,
                13.843,
                13.843,
                13.842,
                13.842,
                13.842,
                13.842,
                13.842,
                13.842,
                13.842,
                13.842,
                13.842,
                13.842,
                13.842,
                13.842,
                13.842,
                13.842,
                13.842,
                13.842,
                13.842,
                13.841,
                13.842,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.841,
                13.84,
                13.84,
                13.841,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84,
                13.84
            ],
            P25: [
                12.529,
                12.503,
                12.476,
                12.449,
                12.422,
                12.395,
                12.368,
                12.341,
                12.375,
                12.41,
                12.444,
                12.478,
                12.512,
                12.546,
                12.581,
                12.645,
                12.712,
                12.782,
                12.851,
                12.921,
                12.991,
                13.06,
                13.128,
                13.194,
                13.26,
                13.324,
                13.387,
                13.448,
                13.508,
                13.567,
                13.624,
                13.68,
                13.733,
                13.785,
                13.836,
                13.885,
                13.933,
                13.979,
                14.024,
                14.068,
                14.111,
                14.153,
                14.193,
                14.233,
                14.271,
                14.309,
                14.346,
                14.381,
                14.416,
                14.45,
                14.483,
                14.515,
                14.547,
                14.577,
                14.608,
                14.637,
                14.666,
                14.694,
                14.721,
                14.748,
                14.774,
                14.8,
                14.825,
                14.85,
                14.874,
                14.898,
                14.921,
                14.943,
                14.965,
                14.987,
                15.008,
                15.029,
                15.049,
                15.068,
                15.088,
                15.107,
                15.125,
                15.143,
                15.161,
                15.178,
                15.196,
                15.212,
                15.228,
                15.244,
                15.26,
                15.276,
                15.291,
                15.305,
                15.32,
                15.334,
                15.348,
                15.362,
                15.375,
                15.388,
                15.401,
                15.413,
                15.426,
                15.438,
                15.45,
                15.461,
                15.473,
                15.484,
                15.495,
                15.505,
                15.516,
                15.526,
                15.536,
                15.546,
                15.556,
                15.565,
                15.575,
                15.584,
                15.593,
                15.601,
                15.61,
                15.618,
                15.627,
                15.635,
                15.643,
                15.651,
                15.658,
                15.666,
                15.673,
                15.681,
                15.688,
                15.695,
                15.702,
                15.708,
                15.715,
                15.722,
                15.728,
                15.734,
                15.74,
                15.746,
                15.752,
                15.758,
                15.764,
                15.769,
                15.774,
                15.78,
                15.785,
                15.79,
                15.795,
                15.8,
                15.805,
                15.809,
                15.814,
                15.818,
                15.822,
                15.827,
                15.831,
                15.835,
                15.838,
                15.842,
                15.846,
                15.849,
                15.853,
                15.856,
                15.86,
                15.863,
                15.866,
                15.869,
                15.872,
                15.875,
                15.878,
                15.88,
                15.883,
                15.885,
                15.888,
                15.89,
                15.892,
                15.894,
                15.896,
                15.898,
                15.9,
                15.902,
                15.904,
                15.905,
                15.907,
                15.908,
                15.909,
                15.911,
                15.912,
                15.913,
                15.914,
                15.915,
                15.916,
                15.917,
                15.918,
                15.918,
                15.919,
                15.919,
                15.92,
                15.92,
                15.921,
                15.921,
                15.921,
                15.921,
                15.921,
                15.921,
                15.921,
                15.921,
                15.921,
                15.92,
                15.92,
                15.919,
                15.919,
                15.918,
                15.918,
                15.917,
                15.916,
                15.916,
                15.915,
                15.914,
                15.913,
                15.912,
                15.911,
                15.91,
                15.908,
                15.907,
                15.906,
                15.905,
                15.903,
                15.902,
                15.9,
                15.899,
                15.897,
                15.895,
                15.894,
                15.892,
                15.89,
                15.888,
                15.887,
                15.885,
                15.883,
                15.881,
                15.879,
                15.876,
                15.874,
                15.872,
                15.87,
                15.868,
                15.865,
                15.863,
                15.861,
                15.858,
                15.856,
                15.853,
                15.851,
                15.848,
                15.845,
                15.843,
                15.84,
                15.837,
                15.835,
                15.832,
                15.829,
                15.826,
                15.823,
                15.82,
                15.817,
                15.814,
                15.811,
                15.808,
                15.805,
                15.802,
                15.799,
                15.796,
                15.793,
                15.789,
                15.786,
                15.783,
                15.78,
                15.776,
                15.773,
                15.77,
                15.766,
                15.763,
                15.759,
                15.756,
                15.752,
                15.749,
                15.745,
                15.742,
                15.738,
                15.735,
                15.731,
                15.728,
                15.724,
                15.72,
                15.717,
                15.713,
                15.709,
                15.706,
                15.702,
                15.698,
                15.694,
                15.691,
                15.687,
                15.683,
                15.679,
                15.676,
                15.672,
                15.668,
                15.664,
                15.66,
                15.657,
                15.653,
                15.649,
                15.645,
                15.641,
                15.637,
                15.633,
                15.629,
                15.626,
                15.622,
                15.618,
                15.614,
                15.61,
                15.606,
                15.602,
                15.598,
                15.594,
                15.59,
                15.586,
                15.582,
                15.578,
                15.574,
                15.571,
                15.567,
                15.563,
                15.559,
                15.555,
                15.551,
                15.547,
                15.543,
                15.539,
                15.535,
                15.531,
                15.527,
                15.523,
                15.519,
                15.515,
                15.511,
                15.507,
                15.503,
                15.5,
                15.495,
                15.492,
                15.488,
                15.484,
                15.48,
                15.476,
                15.472,
                15.468,
                15.464,
                15.46,
                15.456,
                15.452,
                15.449,
                15.445,
                15.441,
                15.437,
                15.433,
                15.429,
                15.425,
                15.421,
                15.418,
                15.414,
                15.41,
                15.406,
                15.402,
                15.398,
                15.395,
                15.391,
                15.387,
                15.383,
                15.379,
                15.376,
                15.372,
                15.368,
                15.364,
                15.36,
                15.357,
                15.353,
                15.349,
                15.345,
                15.342,
                15.338,
                15.334,
                15.33,
                15.327,
                15.323,
                15.319,
                15.316,
                15.312,
                15.308,
                15.305,
                15.301,
                15.297,
                15.294,
                15.29,
                15.286,
                15.283,
                15.279,
                15.275,
                15.272,
                15.268,
                15.265,
                15.261,
                15.258,
                15.254,
                15.251,
                15.247,
                15.243,
                15.24,
                15.236,
                15.233,
                15.229,
                15.226,
                15.222,
                15.219,
                15.215,
                15.212,
                15.208,
                15.205,
                15.202,
                15.198,
                15.195,
                15.191,
                15.188,
                15.184,
                15.181,
                15.178,
                15.174,
                15.171,
                15.168,
                15.164,
                15.161,
                15.157,
                15.154,
                15.151,
                15.148,
                15.144,
                15.141,
                15.138,
                15.134,
                15.131,
                15.128,
                15.125,
                15.121,
                15.118,
                15.115,
                15.112,
                15.109,
                15.105,
                15.102,
                15.099,
                15.096,
                15.093,
                15.09,
                15.086,
                15.083,
                15.08,
                15.077,
                15.074,
                15.071,
                15.068,
                15.065,
                15.062,
                15.059,
                15.055,
                15.052,
                15.049,
                15.046,
                15.043,
                15.04,
                15.037,
                15.034,
                15.031,
                15.028,
                15.025,
                15.022,
                15.02,
                15.017,
                15.014,
                15.011,
                15.008,
                15.005,
                15.002,
                14.999,
                14.996,
                14.993,
                14.991,
                14.988,
                14.985,
                14.982,
                14.979,
                14.976,
                14.974,
                14.971,
                14.968,
                14.965,
                14.962,
                14.96,
                14.957,
                14.954,
                14.952,
                14.949,
                14.946,
                14.943,
                14.941,
                14.938,
                14.935,
                14.933,
                14.93,
                14.927,
                14.925,
                14.922,
                14.919,
                14.917,
                14.914,
                14.912,
                14.909,
                14.907,
                14.904,
                14.901,
                14.899,
                14.896,
                14.894,
                14.891,
                14.889,
                14.886,
                14.884,
                14.881,
                14.879,
                14.876,
                14.874,
                14.871,
                14.869,
                14.866,
                14.864,
                14.862,
                14.859,
                14.857,
                14.854,
                14.852,
                14.85,
                14.847,
                14.845,
                14.842,
                14.84,
                14.838,
                14.835,
                14.833,
                14.831,
                14.829,
                14.826,
                14.824,
                14.822,
                14.819,
                14.817,
                14.815,
                14.813,
                14.81,
                14.808,
                14.806,
                14.804,
                14.802,
                14.799,
                14.797,
                14.795,
                14.793,
                14.791,
                14.788,
                14.786,
                14.784,
                14.782,
                14.78,
                14.778,
                14.776,
                14.774,
                14.772,
                14.77,
                14.767,
                14.765,
                14.763,
                14.761,
                14.759,
                14.757,
                14.755,
                14.753,
                14.751,
                14.749,
                14.747,
                14.745,
                14.743,
                14.741,
                14.739,
                14.738,
                14.736,
                14.734,
                14.732,
                14.73,
                14.728,
                14.726,
                14.724,
                14.722,
                14.72,
                14.719,
                14.717,
                14.715,
                14.713,
                14.711,
                14.709,
                14.708,
                14.706,
                14.704,
                14.702,
                14.701,
                14.699,
                14.697,
                14.695,
                14.694,
                14.692,
                14.69,
                14.688,
                14.687,
                14.685,
                14.683,
                14.682,
                14.68,
                14.678,
                14.677,
                14.675,
                14.673,
                14.672,
                14.67,
                14.669,
                14.667,
                14.665,
                14.664,
                14.662,
                14.661,
                14.659,
                14.658,
                14.656,
                14.655,
                14.653,
                14.651,
                14.65,
                14.648,
                14.647,
                14.646,
                14.644,
                14.643,
                14.641,
                14.64,
                14.638,
                14.637,
                14.635,
                14.634,
                14.633,
                14.631,
                14.63,
                14.629,
                14.627,
                14.626,
                14.625,
                14.623,
                14.622,
                14.621,
                14.619,
                14.618,
                14.617,
                14.615,
                14.614,
                14.613,
                14.612,
                14.61,
                14.609,
                14.608,
                14.607,
                14.605,
                14.604,
                14.603,
                14.602,
                14.601,
                14.599,
                14.598,
                14.597,
                14.596,
                14.595,
                14.594,
                14.593,
                14.591,
                14.59,
                14.589,
                14.588,
                14.587,
                14.586,
                14.585,
                14.584,
                14.583,
                14.582,
                14.58,
                14.579,
                14.579,
                14.577,
                14.576,
                14.575,
                14.574,
                14.573,
                14.572,
                14.571,
                14.57,
                14.569,
                14.569,
                14.567,
                14.567,
                14.566,
                14.565,
                14.564,
                14.563,
                14.562,
                14.561,
                14.56,
                14.559,
                14.558,
                14.558,
                14.557,
                14.832,
                14.831,
                14.83,
                14.829,
                14.828,
                14.827,
                14.826,
                14.825,
                14.825,
                14.824,
                14.823,
                14.822,
                14.821,
                14.82,
                14.819,
                14.818,
                14.817,
                14.816,
                14.815,
                14.814,
                14.814,
                14.813,
                14.812,
                14.811,
                14.81,
                14.809,
                14.808,
                14.807,
                14.806,
                14.806,
                14.805,
                14.804,
                14.803,
                14.802,
                14.801,
                14.8,
                14.799,
                14.798,
                14.798,
                14.797,
                14.796,
                14.795,
                14.794,
                14.793,
                14.792,
                14.791,
                14.79,
                14.79,
                14.789,
                14.788,
                14.787,
                14.786,
                14.785,
                14.785,
                14.784,
                14.783,
                14.782,
                14.781,
                14.78,
                14.779,
                14.779,
                14.778,
                14.777,
                14.776,
                14.775,
                14.774,
                14.773,
                14.773,
                14.772,
                14.771,
                14.77,
                14.769,
                14.768,
                14.768,
                14.767,
                14.766,
                14.765,
                14.764,
                14.764,
                14.763,
                14.762,
                14.761,
                14.76,
                14.759,
                14.759,
                14.758,
                14.757,
                14.756,
                14.755,
                14.754,
                14.754,
                14.753,
                14.752,
                14.751,
                14.75,
                14.75,
                14.749,
                14.748,
                14.747,
                14.746,
                14.745,
                14.745,
                14.744,
                14.743,
                14.742,
                14.742,
                14.741,
                14.74,
                14.739,
                14.738,
                14.737,
                14.737,
                14.736,
                14.735,
                14.734,
                14.733,
                14.733,
                14.732,
                14.731,
                14.73,
                14.73,
                14.729,
                14.728,
                14.727,
                14.726,
                14.726,
                14.725,
                14.724,
                14.723,
                14.722,
                14.722,
                14.721,
                14.72,
                14.719,
                14.719,
                14.718,
                14.717,
                14.716,
                14.715,
                14.715,
                14.714,
                14.713,
                14.712,
                14.711,
                14.711,
                14.71,
                14.709,
                14.708,
                14.708,
                14.707,
                14.706,
                14.705,
                14.704,
                14.704,
                14.703,
                14.702,
                14.701,
                14.701,
                14.7,
                14.699,
                14.698,
                14.697,
                14.697,
                14.696,
                14.695,
                14.694,
                14.694,
                14.693,
                14.692,
                14.691,
                14.691,
                14.69,
                14.689,
                14.688,
                14.687,
                14.687,
                14.686,
                14.685,
                14.684,
                14.684,
                14.683,
                14.682,
                14.681,
                14.681,
                14.68,
                14.679,
                14.678,
                14.678,
                14.677,
                14.676,
                14.675,
                14.674,
                14.674,
                14.673,
                14.672,
                14.671,
                14.671,
                14.67,
                14.669,
                14.668,
                14.667,
                14.667,
                14.666,
                14.665,
                14.664,
                14.664,
                14.663,
                14.662,
                14.661,
                14.661,
                14.66,
                14.659,
                14.658,
                14.658,
                14.657,
                14.656,
                14.655,
                14.655,
                14.654,
                14.653,
                14.652,
                14.652,
                14.651,
                14.65,
                14.649,
                14.649,
                14.648,
                14.647,
                14.646,
                14.646,
                14.645,
                14.644,
                14.643,
                14.643,
                14.642,
                14.641,
                14.64,
                14.639,
                14.639,
                14.638,
                14.637,
                14.636,
                14.636,
                14.635,
                14.634,
                14.633,
                14.633,
                14.632,
                14.631,
                14.631,
                14.63,
                14.629,
                14.628,
                14.627,
                14.627,
                14.626,
                14.625,
                14.625,
                14.624,
                14.623,
                14.622,
                14.622,
                14.621,
                14.62,
                14.619,
                14.619,
                14.618,
                14.617,
                14.616,
                14.616,
                14.615,
                14.614,
                14.613,
                14.613,
                14.612,
                14.611,
                14.611,
                14.61,
                14.609,
                14.608,
                14.608,
                14.607,
                14.606,
                14.605,
                14.605,
                14.604,
                14.603,
                14.603,
                14.602,
                14.601,
                14.6,
                14.6,
                14.599,
                14.598,
                14.597,
                14.597,
                14.596,
                14.595,
                14.595,
                14.594,
                14.593,
                14.592,
                14.592,
                14.591,
                14.59,
                14.59,
                14.589,
                14.588,
                14.587,
                14.587,
                14.586,
                14.585,
                14.585,
                14.584,
                14.583,
                14.583,
                14.582,
                14.581,
                14.58,
                14.58,
                14.579,
                14.578,
                14.578,
                14.577,
                14.576,
                14.576,
                14.575,
                14.574,
                14.574,
                14.573,
                14.572,
                14.571,
                14.571,
                14.57,
                14.569,
                14.569,
                14.568,
                14.567,
                14.567,
                14.566,
                14.565,
                14.565,
                14.564,
                14.563,
                14.563,
                14.562,
                14.561,
                14.561,
                14.56,
                14.559,
                14.559,
                14.558,
                14.557,
                14.557,
                14.556,
                14.555,
                14.555,
                14.554,
                14.553,
                14.553,
                14.552,
                14.551,
                14.551,
                14.55,
                14.549,
                14.549,
                14.548,
                14.547,
                14.547,
                14.546,
                14.545,
                14.545,
                14.544,
                14.544,
                14.543,
                14.542,
                14.542,
                14.541,
                14.54,
                14.54,
                14.539,
                14.538,
                14.538,
                14.537,
                14.536,
                14.536,
                14.535,
                14.535,
                14.534,
                14.533,
                14.533,
                14.532,
                14.531,
                14.531,
                14.53,
                14.529,
                14.529,
                14.528,
                14.528,
                14.527,
                14.526,
                14.526,
                14.525,
                14.524,
                14.524,
                14.523,
                14.523,
                14.522,
                14.521,
                14.521,
                14.52,
                14.52,
                14.519,
                14.518,
                14.518,
                14.517,
                14.516,
                14.516,
                14.515,
                14.515,
                14.514,
                14.514,
                14.513,
                14.512,
                14.512,
                14.511,
                14.51,
                14.51,
                14.509,
                14.509,
                14.508,
                14.507,
                14.507,
                14.506,
                14.506,
                14.505,
                14.504,
                14.504,
                14.503,
                14.503,
                14.502,
                14.502,
                14.501,
                14.5,
                14.5,
                14.499,
                14.499,
                14.498,
                14.497,
                14.497,
                14.496,
                14.496,
                14.495,
                14.495,
                14.494,
                14.493,
                14.493,
                14.492,
                14.492,
                14.491,
                14.49,
                14.49,
                14.489,
                14.489,
                14.488,
                14.488,
                14.487,
                14.486,
                14.486,
                14.485,
                14.485,
                14.484,
                14.484,
                14.483,
                14.482,
                14.482,
                14.481,
                14.481,
                14.48,
                14.48,
                14.479,
                14.478,
                14.478,
                14.477,
                14.477,
                14.476,
                14.476,
                14.475,
                14.474,
                14.474,
                14.473,
                14.473,
                14.472,
                14.472,
                14.471,
                14.471,
                14.47,
                14.469,
                14.469,
                14.468,
                14.468,
                14.467,
                14.467,
                14.466,
                14.466,
                14.465,
                14.464,
                14.464,
                14.463,
                14.463,
                14.462,
                14.462,
                14.461,
                14.461,
                14.46,
                14.459,
                14.459,
                14.458,
                14.458,
                14.457,
                14.457,
                14.456,
                14.456,
                14.455,
                14.455,
                14.454,
                14.454,
                14.453,
                14.452,
                14.452,
                14.451,
                14.451,
                14.45,
                14.45,
                14.449,
                14.449,
                14.448,
                14.448,
                14.447,
                14.446,
                14.446,
                14.446,
                14.445,
                14.444,
                14.444,
                14.443,
                14.443,
                14.442,
                14.442,
                14.441,
                14.441,
                14.44,
                14.44,
                14.439,
                14.439,
                14.438,
                14.438,
                14.437,
                14.437,
                14.436,
                14.436,
                14.435,
                14.434,
                14.434,
                14.433,
                14.433,
                14.432,
                14.432,
                14.431,
                14.431,
                14.43,
                14.43,
                14.429,
                14.429,
                14.428,
                14.428,
                14.427,
                14.427,
                14.426,
                14.426,
                14.425,
                14.425,
                14.424,
                14.424,
                14.423,
                14.423,
                14.422,
                14.422,
                14.421,
                14.421,
                14.42,
                14.42,
                14.419,
                14.419,
                14.418,
                14.418,
                14.417,
                14.417,
                14.416,
                14.416,
                14.415,
                14.415,
                14.414,
                14.414,
                14.413,
                14.413,
                14.412,
                14.412,
                14.411,
                14.411,
                14.41,
                14.41,
                14.409,
                14.409,
                14.408,
                14.408,
                14.407,
                14.407,
                14.406,
                14.406,
                14.405,
                14.405,
                14.405,
                14.404,
                14.404,
                14.403,
                14.403,
                14.402,
                14.402,
                14.401,
                14.401,
                14.4,
                14.4,
                14.399,
                14.399,
                14.399,
                14.398,
                14.398,
                14.397,
                14.397,
                14.396,
                14.396,
                14.395,
                14.395,
                14.394,
                14.394,
                14.394,
                14.393,
                14.393,
                14.392,
                14.392,
                14.391,
                14.391,
                14.391,
                14.39,
                14.39,
                14.389,
                14.389,
                14.388,
                14.388,
                14.387,
                14.387,
                14.387,
                14.386,
                14.386,
                14.385,
                14.385,
                14.384,
                14.384,
                14.384,
                14.383,
                14.383,
                14.382,
                14.382,
                14.382,
                14.381,
                14.381,
                14.38,
                14.38,
                14.38,
                14.379,
                14.379,
                14.378,
                14.378,
                14.377,
                14.377,
                14.377,
                14.376,
                14.376,
                14.376,
                14.375,
                14.375,
                14.374,
                14.374,
                14.374,
                14.373,
                14.373,
                14.372,
                14.372,
                14.372,
                14.371,
                14.371,
                14.371,
                14.37,
                14.37,
                14.369,
                14.369,
                14.369,
                14.368,
                14.368,
                14.368,
                14.367,
                14.367,
                14.366,
                14.366,
                14.366,
                14.365,
                14.365,
                14.365,
                14.364,
                14.364,
                14.364,
                14.363,
                14.363,
                14.362,
                14.362,
                14.362,
                14.361,
                14.361,
                14.361,
                14.36,
                14.36,
                14.36,
                14.359,
                14.359,
                14.359,
                14.358,
                14.358,
                14.358,
                14.357,
                14.357,
                14.357,
                14.356,
                14.356,
                14.356,
                14.355,
                14.355,
                14.355,
                14.354,
                14.354,
                14.354,
                14.353,
                14.353,
                14.353,
                14.352,
                14.352,
                14.352,
                14.352,
                14.351,
                14.351,
                14.351,
                14.35,
                14.35,
                14.35,
                14.349,
                14.349,
                14.349,
                14.349,
                14.348,
                14.348,
                14.348,
                14.347,
                14.347,
                14.347,
                14.347,
                14.346,
                14.346,
                14.346,
                14.345,
                14.345,
                14.345,
                14.345,
                14.344,
                14.344,
                14.344,
                14.343,
                14.343,
                14.343,
                14.343,
                14.342,
                14.342,
                14.342,
                14.342,
                14.341,
                14.341,
                14.341,
                14.341,
                14.34,
                14.34,
                14.34,
                14.339,
                14.339,
                14.339,
                14.339,
                14.339,
                14.338,
                14.338,
                14.338,
                14.338,
                14.337,
                14.337,
                14.337,
                14.337,
                14.336,
                14.336,
                14.336,
                14.336,
                14.335,
                14.335,
                14.335,
                14.335,
                14.335,
                14.334,
                14.334,
                14.334,
                14.334,
                14.333,
                14.333,
                14.333,
                14.333,
                14.333,
                14.332,
                14.332,
                14.332,
                14.332,
                14.332,
                14.331,
                14.331,
                14.331,
                14.331,
                14.331,
                14.33,
                14.33,
                14.33,
                14.33,
                14.33,
                14.33,
                14.329,
                14.329,
                14.329,
                14.329,
                14.329,
                14.328,
                14.328,
                14.328,
                14.328,
                14.328,
                14.328,
                14.327,
                14.327,
                14.327,
                14.327,
                14.327,
                14.327,
                14.326,
                14.326,
                14.326,
                14.326,
                14.326,
                14.326,
                14.326,
                14.325,
                14.325,
                14.325,
                14.325,
                14.325,
                14.325,
                14.324,
                14.324,
                14.324,
                14.324,
                14.324,
                14.324,
                14.324,
                14.324,
                14.323,
                14.323,
                14.323,
                14.323,
                14.323,
                14.323,
                14.323,
                14.322,
                14.322,
                14.322,
                14.322,
                14.322,
                14.322,
                14.322,
                14.322,
                14.322,
                14.321,
                14.321,
                14.321,
                14.321,
                14.321,
                14.321,
                14.321,
                14.321,
                14.321,
                14.32,
                14.32,
                14.32,
                14.32,
                14.32,
                14.32,
                14.32,
                14.32,
                14.32,
                14.32,
                14.319,
                14.319,
                14.319,
                14.319,
                14.319,
                14.319,
                14.319,
                14.319,
                14.319,
                14.319,
                14.319,
                14.319,
                14.318,
                14.318,
                14.318,
                14.318,
                14.318,
                14.318,
                14.318,
                14.318,
                14.318,
                14.318,
                14.318,
                14.318,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.315,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.316,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317,
                14.317
            ],
            P50: [
                13.336,
                13.319,
                13.301,
                13.283,
                13.265,
                13.247,
                13.229,
                13.211,
                13.246,
                13.28,
                13.314,
                13.348,
                13.382,
                13.416,
                13.45,
                13.517,
                13.587,
                13.66,
                13.733,
                13.806,
                13.878,
                13.951,
                14.022,
                14.092,
                14.16,
                14.228,
                14.294,
                14.358,
                14.421,
                14.482,
                14.542,
                14.6,
                14.657,
                14.711,
                14.764,
                14.816,
                14.866,
                14.914,
                14.961,
                15.007,
                15.052,
                15.096,
                15.138,
                15.179,
                15.22,
                15.259,
                15.297,
                15.335,
                15.371,
                15.406,
                15.441,
                15.474,
                15.507,
                15.539,
                15.571,
                15.601,
                15.631,
                15.66,
                15.689,
                15.717,
                15.744,
                15.771,
                15.798,
                15.823,
                15.848,
                15.873,
                15.897,
                15.92,
                15.943,
                15.966,
                15.987,
                16.009,
                16.03,
                16.05,
                16.07,
                16.09,
                16.109,
                16.128,
                16.146,
                16.164,
                16.182,
                16.199,
                16.216,
                16.232,
                16.249,
                16.264,
                16.28,
                16.295,
                16.31,
                16.325,
                16.339,
                16.353,
                16.367,
                16.38,
                16.394,
                16.407,
                16.419,
                16.432,
                16.444,
                16.456,
                16.467,
                16.479,
                16.49,
                16.501,
                16.512,
                16.522,
                16.533,
                16.543,
                16.553,
                16.562,
                16.572,
                16.581,
                16.59,
                16.599,
                16.608,
                16.616,
                16.625,
                16.633,
                16.641,
                16.649,
                16.657,
                16.665,
                16.672,
                16.68,
                16.687,
                16.694,
                16.701,
                16.708,
                16.714,
                16.721,
                16.727,
                16.734,
                16.74,
                16.746,
                16.752,
                16.758,
                16.763,
                16.769,
                16.774,
                16.78,
                16.785,
                16.79,
                16.795,
                16.8,
                16.804,
                16.809,
                16.813,
                16.818,
                16.822,
                16.826,
                16.83,
                16.834,
                16.838,
                16.842,
                16.845,
                16.849,
                16.852,
                16.855,
                16.859,
                16.862,
                16.865,
                16.868,
                16.871,
                16.873,
                16.876,
                16.878,
                16.881,
                16.883,
                16.885,
                16.888,
                16.89,
                16.892,
                16.894,
                16.895,
                16.897,
                16.899,
                16.9,
                16.902,
                16.903,
                16.904,
                16.906,
                16.907,
                16.908,
                16.909,
                16.91,
                16.91,
                16.911,
                16.912,
                16.912,
                16.913,
                16.913,
                16.913,
                16.914,
                16.914,
                16.914,
                16.914,
                16.914,
                16.914,
                16.913,
                16.913,
                16.913,
                16.912,
                16.912,
                16.911,
                16.911,
                16.91,
                16.909,
                16.908,
                16.907,
                16.907,
                16.906,
                16.904,
                16.903,
                16.902,
                16.901,
                16.9,
                16.898,
                16.897,
                16.895,
                16.894,
                16.892,
                16.891,
                16.889,
                16.887,
                16.885,
                16.883,
                16.881,
                16.88,
                16.878,
                16.875,
                16.873,
                16.871,
                16.869,
                16.867,
                16.864,
                16.862,
                16.86,
                16.857,
                16.855,
                16.852,
                16.85,
                16.847,
                16.844,
                16.842,
                16.839,
                16.836,
                16.833,
                16.831,
                16.828,
                16.825,
                16.822,
                16.819,
                16.816,
                16.813,
                16.81,
                16.806,
                16.803,
                16.8,
                16.797,
                16.793,
                16.79,
                16.787,
                16.783,
                16.78,
                16.776,
                16.773,
                16.77,
                16.766,
                16.762,
                16.759,
                16.755,
                16.752,
                16.748,
                16.744,
                16.74,
                16.737,
                16.733,
                16.729,
                16.725,
                16.721,
                16.718,
                16.714,
                16.71,
                16.706,
                16.702,
                16.698,
                16.694,
                16.69,
                16.686,
                16.682,
                16.678,
                16.674,
                16.67,
                16.666,
                16.662,
                16.658,
                16.653,
                16.649,
                16.645,
                16.641,
                16.637,
                16.633,
                16.628,
                16.624,
                16.62,
                16.616,
                16.612,
                16.607,
                16.603,
                16.599,
                16.595,
                16.59,
                16.586,
                16.582,
                16.577,
                16.573,
                16.569,
                16.565,
                16.56,
                16.556,
                16.552,
                16.547,
                16.543,
                16.539,
                16.534,
                16.53,
                16.526,
                16.521,
                16.517,
                16.513,
                16.508,
                16.504,
                16.5,
                16.495,
                16.491,
                16.487,
                16.482,
                16.478,
                16.474,
                16.469,
                16.465,
                16.461,
                16.456,
                16.452,
                16.448,
                16.443,
                16.439,
                16.435,
                16.43,
                16.426,
                16.422,
                16.418,
                16.413,
                16.409,
                16.405,
                16.4,
                16.396,
                16.392,
                16.388,
                16.383,
                16.379,
                16.375,
                16.371,
                16.366,
                16.362,
                16.358,
                16.354,
                16.349,
                16.345,
                16.341,
                16.337,
                16.333,
                16.328,
                16.324,
                16.32,
                16.316,
                16.312,
                16.308,
                16.303,
                16.299,
                16.295,
                16.291,
                16.287,
                16.283,
                16.279,
                16.275,
                16.27,
                16.266,
                16.262,
                16.258,
                16.254,
                16.25,
                16.246,
                16.242,
                16.238,
                16.234,
                16.23,
                16.226,
                16.222,
                16.218,
                16.214,
                16.21,
                16.206,
                16.202,
                16.198,
                16.194,
                16.19,
                16.186,
                16.182,
                16.178,
                16.174,
                16.171,
                16.167,
                16.163,
                16.159,
                16.155,
                16.151,
                16.147,
                16.144,
                16.14,
                16.136,
                16.132,
                16.128,
                16.125,
                16.121,
                16.117,
                16.113,
                16.11,
                16.106,
                16.102,
                16.098,
                16.095,
                16.091,
                16.087,
                16.084,
                16.08,
                16.076,
                16.073,
                16.069,
                16.065,
                16.062,
                16.058,
                16.054,
                16.051,
                16.047,
                16.044,
                16.04,
                16.037,
                16.033,
                16.029,
                16.026,
                16.022,
                16.019,
                16.015,
                16.012,
                16.008,
                16.005,
                16.001,
                15.998,
                15.994,
                15.991,
                15.988,
                15.984,
                15.981,
                15.977,
                15.974,
                15.971,
                15.967,
                15.964,
                15.96,
                15.957,
                15.954,
                15.95,
                15.947,
                15.944,
                15.941,
                15.937,
                15.934,
                15.931,
                15.927,
                15.924,
                15.921,
                15.918,
                15.915,
                15.911,
                15.908,
                15.905,
                15.902,
                15.899,
                15.895,
                15.892,
                15.889,
                15.886,
                15.883,
                15.88,
                15.877,
                15.874,
                15.87,
                15.867,
                15.864,
                15.861,
                15.858,
                15.855,
                15.852,
                15.849,
                15.846,
                15.843,
                15.84,
                15.837,
                15.834,
                15.831,
                15.828,
                15.825,
                15.822,
                15.82,
                15.817,
                15.814,
                15.811,
                15.808,
                15.805,
                15.802,
                15.799,
                15.797,
                15.794,
                15.791,
                15.788,
                15.785,
                15.783,
                15.78,
                15.777,
                15.774,
                15.771,
                15.769,
                15.766,
                15.763,
                15.761,
                15.758,
                15.755,
                15.752,
                15.75,
                15.747,
                15.744,
                15.742,
                15.739,
                15.736,
                15.734,
                15.731,
                15.729,
                15.726,
                15.723,
                15.721,
                15.718,
                15.716,
                15.713,
                15.711,
                15.708,
                15.706,
                15.703,
                15.701,
                15.698,
                15.696,
                15.693,
                15.691,
                15.688,
                15.686,
                15.683,
                15.681,
                15.678,
                15.676,
                15.674,
                15.671,
                15.669,
                15.667,
                15.664,
                15.662,
                15.659,
                15.657,
                15.655,
                15.652,
                15.65,
                15.648,
                15.646,
                15.643,
                15.641,
                15.639,
                15.636,
                15.634,
                15.632,
                15.63,
                15.628,
                15.625,
                15.623,
                15.621,
                15.619,
                15.617,
                15.614,
                15.612,
                15.61,
                15.608,
                15.606,
                15.604,
                15.602,
                15.599,
                15.597,
                15.595,
                15.593,
                15.591,
                15.589,
                15.587,
                15.585,
                15.583,
                15.581,
                15.579,
                15.577,
                15.575,
                15.573,
                15.571,
                15.569,
                15.567,
                15.565,
                15.563,
                15.561,
                15.559,
                15.557,
                15.556,
                15.554,
                15.552,
                15.55,
                15.548,
                15.546,
                15.544,
                15.542,
                15.541,
                15.539,
                15.537,
                15.535,
                15.533,
                15.532,
                15.53,
                15.528,
                15.526,
                15.525,
                15.523,
                15.521,
                15.519,
                15.518,
                15.516,
                15.514,
                15.513,
                15.511,
                15.509,
                15.508,
                15.506,
                15.505,
                15.503,
                15.501,
                15.5,
                15.498,
                15.497,
                15.495,
                15.493,
                15.492,
                15.49,
                15.489,
                15.487,
                15.486,
                15.484,
                15.483,
                15.481,
                15.48,
                15.478,
                15.477,
                15.475,
                15.474,
                15.472,
                15.471,
                15.47,
                15.468,
                15.467,
                15.465,
                15.464,
                15.463,
                15.461,
                15.46,
                15.459,
                15.457,
                15.456,
                15.455,
                15.453,
                15.452,
                15.451,
                15.449,
                15.448,
                15.447,
                15.446,
                15.444,
                15.443,
                15.442,
                15.441,
                15.439,
                15.438,
                15.437,
                15.436,
                15.435,
                15.433,
                15.432,
                15.431,
                15.43,
                15.429,
                15.428,
                15.426,
                15.425,
                15.424,
                15.423,
                15.422,
                15.421,
                15.42,
                15.419,
                15.418,
                15.416,
                15.415,
                15.414,
                15.413,
                15.412,
                15.411,
                15.41,
                15.409,
                15.408,
                15.407,
                15.406,
                15.405,
                15.688,
                15.687,
                15.686,
                15.685,
                15.684,
                15.683,
                15.682,
                15.681,
                15.68,
                15.679,
                15.678,
                15.677,
                15.676,
                15.675,
                15.674,
                15.673,
                15.672,
                15.671,
                15.67,
                15.67,
                15.669,
                15.668,
                15.667,
                15.666,
                15.665,
                15.664,
                15.663,
                15.662,
                15.661,
                15.66,
                15.659,
                15.658,
                15.657,
                15.656,
                15.655,
                15.654,
                15.653,
                15.652,
                15.651,
                15.65,
                15.65,
                15.649,
                15.648,
                15.647,
                15.646,
                15.645,
                15.644,
                15.643,
                15.642,
                15.641,
                15.64,
                15.639,
                15.638,
                15.638,
                15.637,
                15.636,
                15.635,
                15.634,
                15.633,
                15.632,
                15.631,
                15.63,
                15.629,
                15.628,
                15.628,
                15.627,
                15.626,
                15.625,
                15.624,
                15.623,
                15.622,
                15.621,
                15.62,
                15.619,
                15.619,
                15.618,
                15.617,
                15.616,
                15.615,
                15.614,
                15.613,
                15.612,
                15.612,
                15.611,
                15.61,
                15.609,
                15.608,
                15.607,
                15.606,
                15.605,
                15.604,
                15.604,
                15.603,
                15.602,
                15.601,
                15.6,
                15.599,
                15.598,
                15.598,
                15.597,
                15.596,
                15.595,
                15.594,
                15.593,
                15.592,
                15.592,
                15.591,
                15.59,
                15.589,
                15.588,
                15.587,
                15.586,
                15.586,
                15.585,
                15.584,
                15.583,
                15.582,
                15.581,
                15.58,
                15.58,
                15.579,
                15.578,
                15.577,
                15.576,
                15.575,
                15.575,
                15.574,
                15.573,
                15.572,
                15.571,
                15.57,
                15.57,
                15.569,
                15.568,
                15.567,
                15.566,
                15.565,
                15.565,
                15.564,
                15.563,
                15.562,
                15.561,
                15.56,
                15.56,
                15.559,
                15.558,
                15.557,
                15.556,
                15.555,
                15.555,
                15.554,
                15.553,
                15.552,
                15.551,
                15.55,
                15.55,
                15.549,
                15.548,
                15.547,
                15.546,
                15.546,
                15.545,
                15.544,
                15.543,
                15.542,
                15.541,
                15.541,
                15.54,
                15.539,
                15.538,
                15.537,
                15.537,
                15.536,
                15.535,
                15.534,
                15.533,
                15.533,
                15.532,
                15.531,
                15.53,
                15.529,
                15.529,
                15.528,
                15.527,
                15.526,
                15.525,
                15.525,
                15.524,
                15.523,
                15.522,
                15.521,
                15.521,
                15.52,
                15.519,
                15.518,
                15.517,
                15.517,
                15.516,
                15.515,
                15.514,
                15.513,
                15.513,
                15.512,
                15.511,
                15.51,
                15.509,
                15.509,
                15.508,
                15.507,
                15.506,
                15.505,
                15.505,
                15.504,
                15.503,
                15.502,
                15.502,
                15.501,
                15.5,
                15.499,
                15.498,
                15.498,
                15.497,
                15.496,
                15.495,
                15.494,
                15.494,
                15.493,
                15.492,
                15.491,
                15.491,
                15.49,
                15.489,
                15.488,
                15.488,
                15.487,
                15.486,
                15.485,
                15.484,
                15.484,
                15.483,
                15.482,
                15.481,
                15.481,
                15.48,
                15.479,
                15.478,
                15.478,
                15.477,
                15.476,
                15.475,
                15.475,
                15.474,
                15.473,
                15.472,
                15.472,
                15.471,
                15.47,
                15.469,
                15.469,
                15.468,
                15.467,
                15.466,
                15.466,
                15.465,
                15.464,
                15.463,
                15.463,
                15.462,
                15.461,
                15.46,
                15.46,
                15.459,
                15.458,
                15.458,
                15.457,
                15.456,
                15.455,
                15.455,
                15.454,
                15.453,
                15.452,
                15.452,
                15.451,
                15.45,
                15.45,
                15.449,
                15.448,
                15.447,
                15.447,
                15.446,
                15.445,
                15.445,
                15.444,
                15.443,
                15.443,
                15.442,
                15.441,
                15.44,
                15.44,
                15.439,
                15.438,
                15.438,
                15.437,
                15.436,
                15.436,
                15.435,
                15.434,
                15.434,
                15.433,
                15.432,
                15.432,
                15.431,
                15.43,
                15.429,
                15.429,
                15.428,
                15.427,
                15.427,
                15.426,
                15.425,
                15.425,
                15.424,
                15.423,
                15.423,
                15.422,
                15.422,
                15.421,
                15.42,
                15.42,
                15.419,
                15.418,
                15.418,
                15.417,
                15.416,
                15.416,
                15.415,
                15.414,
                15.414,
                15.413,
                15.413,
                15.412,
                15.411,
                15.411,
                15.41,
                15.409,
                15.409,
                15.408,
                15.408,
                15.407,
                15.406,
                15.406,
                15.405,
                15.404,
                15.404,
                15.403,
                15.403,
                15.402,
                15.401,
                15.401,
                15.4,
                15.4,
                15.399,
                15.398,
                15.398,
                15.397,
                15.397,
                15.396,
                15.395,
                15.395,
                15.394,
                15.394,
                15.393,
                15.393,
                15.392,
                15.391,
                15.391,
                15.39,
                15.39,
                15.389,
                15.389,
                15.388,
                15.387,
                15.387,
                15.386,
                15.386,
                15.385,
                15.385,
                15.384,
                15.384,
                15.383,
                15.382,
                15.382,
                15.381,
                15.381,
                15.38,
                15.38,
                15.379,
                15.379,
                15.378,
                15.378,
                15.377,
                15.376,
                15.376,
                15.375,
                15.375,
                15.374,
                15.374,
                15.373,
                15.373,
                15.372,
                15.372,
                15.371,
                15.371,
                15.37,
                15.37,
                15.369,
                15.369,
                15.368,
                15.368,
                15.367,
                15.367,
                15.366,
                15.366,
                15.365,
                15.365,
                15.364,
                15.364,
                15.363,
                15.363,
                15.362,
                15.362,
                15.361,
                15.361,
                15.36,
                15.36,
                15.359,
                15.359,
                15.358,
                15.358,
                15.357,
                15.357,
                15.356,
                15.356,
                15.355,
                15.355,
                15.354,
                15.354,
                15.354,
                15.353,
                15.353,
                15.352,
                15.352,
                15.351,
                15.351,
                15.35,
                15.35,
                15.349,
                15.349,
                15.348,
                15.348,
                15.348,
                15.347,
                15.347,
                15.346,
                15.346,
                15.345,
                15.345,
                15.344,
                15.344,
                15.343,
                15.343,
                15.343,
                15.342,
                15.342,
                15.341,
                15.341,
                15.34,
                15.34,
                15.34,
                15.339,
                15.339,
                15.338,
                15.338,
                15.337,
                15.337,
                15.336,
                15.336,
                15.336,
                15.335,
                15.335,
                15.334,
                15.334,
                15.334,
                15.333,
                15.333,
                15.332,
                15.332,
                15.331,
                15.331,
                15.331,
                15.33,
                15.33,
                15.329,
                15.329,
                15.329,
                15.328,
                15.328,
                15.327,
                15.327,
                15.327,
                15.326,
                15.326,
                15.325,
                15.325,
                15.324,
                15.324,
                15.324,
                15.323,
                15.323,
                15.323,
                15.322,
                15.322,
                15.321,
                15.321,
                15.321,
                15.32,
                15.32,
                15.319,
                15.319,
                15.319,
                15.318,
                15.318,
                15.317,
                15.317,
                15.317,
                15.316,
                15.316,
                15.316,
                15.315,
                15.315,
                15.314,
                15.314,
                15.314,
                15.313,
                15.313,
                15.313,
                15.312,
                15.312,
                15.311,
                15.311,
                15.311,
                15.31,
                15.31,
                15.31,
                15.309,
                15.309,
                15.308,
                15.308,
                15.308,
                15.307,
                15.307,
                15.307,
                15.306,
                15.306,
                15.306,
                15.305,
                15.305,
                15.304,
                15.304,
                15.304,
                15.303,
                15.303,
                15.303,
                15.302,
                15.302,
                15.302,
                15.301,
                15.301,
                15.301,
                15.3,
                15.3,
                15.3,
                15.299,
                15.299,
                15.299,
                15.298,
                15.298,
                15.298,
                15.297,
                15.297,
                15.297,
                15.296,
                15.296,
                15.296,
                15.295,
                15.295,
                15.295,
                15.294,
                15.294,
                15.294,
                15.293,
                15.293,
                15.293,
                15.292,
                15.292,
                15.292,
                15.291,
                15.291,
                15.291,
                15.29,
                15.29,
                15.29,
                15.289,
                15.289,
                15.289,
                15.288,
                15.288,
                15.288,
                15.288,
                15.287,
                15.287,
                15.287,
                15.286,
                15.286,
                15.286,
                15.285,
                15.285,
                15.285,
                15.285,
                15.284,
                15.284,
                15.284,
                15.283,
                15.283,
                15.283,
                15.282,
                15.282,
                15.282,
                15.282,
                15.281,
                15.281,
                15.281,
                15.28,
                15.28,
                15.28,
                15.28,
                15.279,
                15.279,
                15.279,
                15.279,
                15.278,
                15.278,
                15.278,
                15.277,
                15.277,
                15.277,
                15.277,
                15.276,
                15.276,
                15.276,
                15.276,
                15.275,
                15.275,
                15.275,
                15.275,
                15.274,
                15.274,
                15.274,
                15.274,
                15.273,
                15.273,
                15.273,
                15.273,
                15.272,
                15.272,
                15.272,
                15.272,
                15.271,
                15.271,
                15.271,
                15.271,
                15.27,
                15.27,
                15.27,
                15.27,
                15.269,
                15.269,
                15.269,
                15.269,
                15.269,
                15.268,
                15.268,
                15.268,
                15.268,
                15.267,
                15.267,
                15.267,
                15.267,
                15.267,
                15.266,
                15.266,
                15.266,
                15.266,
                15.265,
                15.265,
                15.265,
                15.265,
                15.265,
                15.264,
                15.264,
                15.264,
                15.264,
                15.264,
                15.263,
                15.263,
                15.263,
                15.263,
                15.263,
                15.262,
                15.262,
                15.262,
                15.262,
                15.262,
                15.262,
                15.261,
                15.261,
                15.261,
                15.261,
                15.261,
                15.26,
                15.26,
                15.26,
                15.26,
                15.26,
                15.26,
                15.259,
                15.259,
                15.259,
                15.259,
                15.259,
                15.259,
                15.258,
                15.258,
                15.258,
                15.258,
                15.258,
                15.258,
                15.258,
                15.257,
                15.257,
                15.257,
                15.257,
                15.257,
                15.257,
                15.257,
                15.256,
                15.256,
                15.256,
                15.256,
                15.256,
                15.256,
                15.256,
                15.255,
                15.255,
                15.255,
                15.255,
                15.255,
                15.255,
                15.255,
                15.255,
                15.254,
                15.254,
                15.254,
                15.254,
                15.254,
                15.254,
                15.254,
                15.254,
                15.254,
                15.253,
                15.253,
                15.253,
                15.253,
                15.253,
                15.253,
                15.253,
                15.253,
                15.253,
                15.253,
                15.253,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.25,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.251,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.252,
                15.253,
                15.253,
                15.253,
                15.253,
                15.253,
                15.253,
                15.253,
                15.253,
                15.253,
                15.253,
                15.253,
                15.253,
                15.253,
                15.254,
                15.254,
                15.254,
                15.254,
                15.254,
                15.254,
                15.254,
                15.254,
                15.254,
                15.254,
                15.254,
                15.255,
                15.255,
                15.255,
                15.255,
                15.255,
                15.255,
                15.255,
                15.255,
                15.255,
                15.255,
                15.256,
                15.256,
                15.256,
                15.256,
                15.256,
                15.256,
                15.256,
                15.256,
                15.256,
                15.257,
                15.257,
                15.257,
                15.257,
                15.257,
                15.257,
                15.257,
                15.257,
                15.257,
                15.258,
                15.258,
                15.258,
                15.258,
                15.258,
                15.258,
                15.258,
                15.258,
                15.258,
                15.259,
                15.259,
                15.259,
                15.259,
                15.259,
                15.259,
                15.259,
                15.259,
                15.26,
                15.26,
                15.26,
                15.26,
                15.26,
                15.26,
                15.26,
                15.26,
                15.26,
                15.261,
                15.261,
                15.261,
                15.261,
                15.261,
                15.261,
                15.261,
                15.262,
                15.262,
                15.262,
                15.262,
                15.262,
                15.262,
                15.262,
                15.262,
                15.263,
                15.263,
                15.263,
                15.263,
                15.263,
                15.263,
                15.263,
                15.264,
                15.264,
                15.264,
                15.264,
                15.264,
                15.264,
                15.264,
                15.264,
                15.265,
                15.265,
                15.265,
                15.265,
                15.265,
                15.265,
                15.265,
                15.266,
                15.266,
                15.266,
                15.266,
                15.266,
                15.266,
                15.266,
                15.267,
                15.267,
                15.267,
                15.267,
                15.267,
                15.267,
                15.267,
                15.268,
                15.268,
                15.268,
                15.268,
                15.268,
                15.268,
                15.269,
                15.269,
                15.269,
                15.269,
                15.269,
                15.269,
                15.269,
                15.27,
                15.27,
                15.27,
                15.27,
                15.27,
                15.27,
                15.271,
                15.271,
                15.271,
                15.271,
                15.271,
                15.271,
                15.271,
                15.272,
                15.272,
                15.272,
                15.272,
                15.272,
                15.272,
                15.273,
                15.273,
                15.273,
                15.273,
                15.273,
                15.273,
                15.274,
                15.274,
                15.274,
                15.274,
                15.274,
                15.274,
                15.275,
                15.275,
                15.275,
                15.275,
                15.275,
                15.275,
                15.276,
                15.276,
                15.276,
                15.276,
                15.276,
                15.276,
                15.277,
                15.277,
                15.277,
                15.277,
                15.277,
                15.277,
                15.278,
                15.278,
                15.278,
                15.278,
                15.278,
                15.278,
                15.279,
                15.279,
                15.279,
                15.279,
                15.279,
                15.279,
                15.28,
                15.28
            ],
            P75: [
                14.199,
                14.185,
                14.172,
                14.158,
                14.145,
                14.131,
                14.117,
                14.103,
                14.138,
                14.173,
                14.208,
                14.243,
                14.278,
                14.313,
                14.348,
                14.418,
                14.492,
                14.568,
                14.645,
                14.722,
                14.799,
                14.875,
                14.95,
                15.024,
                15.097,
                15.168,
                15.237,
                15.305,
                15.372,
                15.437,
                15.5,
                15.561,
                15.62,
                15.678,
                15.734,
                15.788,
                15.841,
                15.892,
                15.942,
                15.99,
                16.037,
                16.083,
                16.128,
                16.172,
                16.214,
                16.256,
                16.296,
                16.335,
                16.373,
                16.411,
                16.447,
                16.482,
                16.517,
                16.551,
                16.583,
                16.616,
                16.647,
                16.678,
                16.708,
                16.737,
                16.766,
                16.795,
                16.822,
                16.849,
                16.875,
                16.901,
                16.926,
                16.951,
                16.975,
                16.998,
                17.021,
                17.044,
                17.066,
                17.087,
                17.108,
                17.129,
                17.149,
                17.168,
                17.188,
                17.207,
                17.225,
                17.243,
                17.261,
                17.278,
                17.295,
                17.312,
                17.328,
                17.344,
                17.359,
                17.375,
                17.389,
                17.404,
                17.419,
                17.433,
                17.446,
                17.46,
                17.473,
                17.486,
                17.499,
                17.511,
                17.523,
                17.535,
                17.547,
                17.558,
                17.57,
                17.581,
                17.591,
                17.602,
                17.612,
                17.622,
                17.632,
                17.642,
                17.651,
                17.66,
                17.669,
                17.678,
                17.687,
                17.696,
                17.704,
                17.712,
                17.72,
                17.728,
                17.736,
                17.744,
                17.751,
                17.759,
                17.766,
                17.773,
                17.78,
                17.786,
                17.793,
                17.8,
                17.806,
                17.812,
                17.818,
                17.824,
                17.83,
                17.836,
                17.841,
                17.847,
                17.852,
                17.857,
                17.862,
                17.867,
                17.872,
                17.877,
                17.881,
                17.886,
                17.89,
                17.894,
                17.898,
                17.902,
                17.906,
                17.91,
                17.914,
                17.917,
                17.921,
                17.924,
                17.927,
                17.93,
                17.933,
                17.936,
                17.939,
                17.942,
                17.944,
                17.947,
                17.949,
                17.952,
                17.954,
                17.956,
                17.958,
                17.96,
                17.962,
                17.964,
                17.965,
                17.967,
                17.968,
                17.97,
                17.971,
                17.972,
                17.973,
                17.974,
                17.975,
                17.976,
                17.977,
                17.977,
                17.978,
                17.978,
                17.979,
                17.979,
                17.979,
                17.98,
                17.98,
                17.98,
                17.98,
                17.979,
                17.979,
                17.979,
                17.979,
                17.978,
                17.978,
                17.977,
                17.976,
                17.976,
                17.975,
                17.974,
                17.973,
                17.972,
                17.971,
                17.97,
                17.968,
                17.967,
                17.966,
                17.964,
                17.963,
                17.961,
                17.96,
                17.958,
                17.956,
                17.955,
                17.953,
                17.951,
                17.949,
                17.947,
                17.945,
                17.943,
                17.941,
                17.938,
                17.936,
                17.934,
                17.931,
                17.929,
                17.927,
                17.924,
                17.921,
                17.919,
                17.916,
                17.913,
                17.911,
                17.908,
                17.905,
                17.902,
                17.899,
                17.896,
                17.893,
                17.89,
                17.887,
                17.884,
                17.88,
                17.877,
                17.874,
                17.871,
                17.867,
                17.864,
                17.86,
                17.857,
                17.853,
                17.85,
                17.846,
                17.843,
                17.839,
                17.835,
                17.832,
                17.828,
                17.824,
                17.82,
                17.817,
                17.813,
                17.809,
                17.805,
                17.801,
                17.797,
                17.793,
                17.789,
                17.785,
                17.781,
                17.776,
                17.772,
                17.768,
                17.764,
                17.76,
                17.755,
                17.751,
                17.747,
                17.743,
                17.738,
                17.734,
                17.73,
                17.725,
                17.721,
                17.716,
                17.712,
                17.708,
                17.703,
                17.699,
                17.694,
                17.69,
                17.685,
                17.681,
                17.676,
                17.672,
                17.667,
                17.663,
                17.658,
                17.654,
                17.649,
                17.644,
                17.64,
                17.635,
                17.63,
                17.626,
                17.621,
                17.616,
                17.612,
                17.607,
                17.602,
                17.598,
                17.593,
                17.589,
                17.584,
                17.579,
                17.574,
                17.57,
                17.565,
                17.56,
                17.556,
                17.551,
                17.546,
                17.542,
                17.537,
                17.532,
                17.528,
                17.523,
                17.518,
                17.513,
                17.509,
                17.504,
                17.499,
                17.495,
                17.49,
                17.485,
                17.481,
                17.476,
                17.471,
                17.466,
                17.462,
                17.457,
                17.452,
                17.448,
                17.443,
                17.438,
                17.434,
                17.429,
                17.424,
                17.42,
                17.415,
                17.411,
                17.406,
                17.401,
                17.397,
                17.392,
                17.388,
                17.383,
                17.378,
                17.374,
                17.369,
                17.365,
                17.36,
                17.355,
                17.351,
                17.346,
                17.342,
                17.337,
                17.333,
                17.328,
                17.324,
                17.319,
                17.315,
                17.31,
                17.306,
                17.301,
                17.297,
                17.292,
                17.288,
                17.283,
                17.279,
                17.275,
                17.27,
                17.266,
                17.261,
                17.257,
                17.252,
                17.248,
                17.244,
                17.239,
                17.235,
                17.231,
                17.226,
                17.222,
                17.218,
                17.213,
                17.209,
                17.205,
                17.2,
                17.196,
                17.192,
                17.188,
                17.183,
                17.179,
                17.175,
                17.171,
                17.166,
                17.162,
                17.158,
                17.154,
                17.15,
                17.146,
                17.141,
                17.137,
                17.133,
                17.129,
                17.125,
                17.121,
                17.117,
                17.113,
                17.108,
                17.104,
                17.1,
                17.096,
                17.092,
                17.088,
                17.084,
                17.08,
                17.076,
                17.072,
                17.068,
                17.064,
                17.06,
                17.056,
                17.053,
                17.049,
                17.045,
                17.041,
                17.037,
                17.033,
                17.029,
                17.025,
                17.021,
                17.018,
                17.014,
                17.01,
                17.006,
                17.002,
                16.998,
                16.995,
                16.991,
                16.987,
                16.983,
                16.98,
                16.976,
                16.972,
                16.968,
                16.965,
                16.961,
                16.957,
                16.954,
                16.95,
                16.946,
                16.943,
                16.939,
                16.936,
                16.932,
                16.928,
                16.925,
                16.921,
                16.918,
                16.914,
                16.91,
                16.907,
                16.903,
                16.9,
                16.896,
                16.893,
                16.889,
                16.886,
                16.882,
                16.879,
                16.876,
                16.872,
                16.869,
                16.865,
                16.862,
                16.858,
                16.855,
                16.852,
                16.848,
                16.845,
                16.842,
                16.838,
                16.835,
                16.832,
                16.829,
                16.825,
                16.822,
                16.819,
                16.815,
                16.812,
                16.809,
                16.806,
                16.803,
                16.799,
                16.796,
                16.793,
                16.79,
                16.787,
                16.784,
                16.78,
                16.777,
                16.774,
                16.771,
                16.768,
                16.765,
                16.762,
                16.759,
                16.756,
                16.753,
                16.75,
                16.747,
                16.744,
                16.74,
                16.738,
                16.735,
                16.732,
                16.729,
                16.726,
                16.723,
                16.72,
                16.717,
                16.714,
                16.711,
                16.708,
                16.705,
                16.702,
                16.699,
                16.697,
                16.694,
                16.691,
                16.688,
                16.685,
                16.682,
                16.68,
                16.677,
                16.674,
                16.671,
                16.669,
                16.666,
                16.663,
                16.66,
                16.658,
                16.655,
                16.652,
                16.65,
                16.647,
                16.644,
                16.642,
                16.639,
                16.636,
                16.634,
                16.631,
                16.628,
                16.626,
                16.623,
                16.621,
                16.618,
                16.616,
                16.613,
                16.611,
                16.608,
                16.606,
                16.603,
                16.6,
                16.598,
                16.596,
                16.593,
                16.591,
                16.588,
                16.586,
                16.583,
                16.581,
                16.579,
                16.576,
                16.574,
                16.571,
                16.569,
                16.567,
                16.564,
                16.562,
                16.56,
                16.557,
                16.555,
                16.553,
                16.55,
                16.548,
                16.546,
                16.543,
                16.541,
                16.539,
                16.537,
                16.535,
                16.532,
                16.53,
                16.528,
                16.526,
                16.524,
                16.521,
                16.519,
                16.517,
                16.515,
                16.513,
                16.511,
                16.509,
                16.506,
                16.504,
                16.502,
                16.5,
                16.498,
                16.496,
                16.494,
                16.492,
                16.49,
                16.488,
                16.486,
                16.484,
                16.482,
                16.48,
                16.478,
                16.476,
                16.474,
                16.472,
                16.47,
                16.468,
                16.467,
                16.465,
                16.463,
                16.461,
                16.459,
                16.457,
                16.455,
                16.454,
                16.452,
                16.45,
                16.448,
                16.446,
                16.445,
                16.443,
                16.441,
                16.439,
                16.438,
                16.436,
                16.434,
                16.432,
                16.431,
                16.429,
                16.427,
                16.426,
                16.424,
                16.422,
                16.421,
                16.419,
                16.418,
                16.416,
                16.414,
                16.413,
                16.411,
                16.41,
                16.408,
                16.406,
                16.405,
                16.403,
                16.402,
                16.4,
                16.399,
                16.397,
                16.396,
                16.394,
                16.393,
                16.391,
                16.39,
                16.389,
                16.387,
                16.386,
                16.384,
                16.383,
                16.381,
                16.38,
                16.379,
                16.377,
                16.376,
                16.375,
                16.373,
                16.372,
                16.371,
                16.369,
                16.368,
                16.367,
                16.365,
                16.364,
                16.363,
                16.362,
                16.36,
                16.359,
                16.358,
                16.357,
                16.355,
                16.354,
                16.353,
                16.352,
                16.351,
                16.349,
                16.348,
                16.347,
                16.346,
                16.345,
                16.343,
                16.342,
                16.341,
                16.34,
                16.339,
                16.338,
                16.337,
                16.336,
                16.624,
                16.623,
                16.622,
                16.621,
                16.62,
                16.619,
                16.618,
                16.617,
                16.616,
                16.615,
                16.614,
                16.613,
                16.612,
                16.611,
                16.61,
                16.609,
                16.608,
                16.607,
                16.605,
                16.605,
                16.603,
                16.602,
                16.601,
                16.6,
                16.599,
                16.598,
                16.597,
                16.596,
                16.595,
                16.594,
                16.593,
                16.592,
                16.591,
                16.59,
                16.589,
                16.588,
                16.587,
                16.586,
                16.585,
                16.584,
                16.583,
                16.582,
                16.581,
                16.58,
                16.579,
                16.578,
                16.577,
                16.576,
                16.575,
                16.574,
                16.573,
                16.572,
                16.571,
                16.57,
                16.569,
                16.568,
                16.567,
                16.566,
                16.565,
                16.564,
                16.563,
                16.562,
                16.561,
                16.561,
                16.56,
                16.559,
                16.558,
                16.557,
                16.556,
                16.555,
                16.554,
                16.553,
                16.552,
                16.551,
                16.55,
                16.549,
                16.548,
                16.547,
                16.546,
                16.545,
                16.544,
                16.543,
                16.542,
                16.541,
                16.54,
                16.54,
                16.539,
                16.538,
                16.537,
                16.536,
                16.535,
                16.534,
                16.533,
                16.532,
                16.531,
                16.53,
                16.529,
                16.528,
                16.527,
                16.526,
                16.526,
                16.525,
                16.524,
                16.523,
                16.522,
                16.521,
                16.52,
                16.519,
                16.518,
                16.517,
                16.516,
                16.515,
                16.515,
                16.514,
                16.513,
                16.512,
                16.511,
                16.51,
                16.509,
                16.508,
                16.507,
                16.506,
                16.505,
                16.505,
                16.504,
                16.503,
                16.502,
                16.501,
                16.5,
                16.499,
                16.498,
                16.498,
                16.497,
                16.496,
                16.495,
                16.494,
                16.493,
                16.492,
                16.491,
                16.49,
                16.489,
                16.489,
                16.488,
                16.487,
                16.486,
                16.485,
                16.484,
                16.483,
                16.482,
                16.482,
                16.481,
                16.48,
                16.479,
                16.478,
                16.477,
                16.476,
                16.475,
                16.475,
                16.474,
                16.473,
                16.472,
                16.471,
                16.47,
                16.469,
                16.469,
                16.468,
                16.467,
                16.466,
                16.465,
                16.464,
                16.463,
                16.463,
                16.462,
                16.461,
                16.46,
                16.459,
                16.458,
                16.457,
                16.457,
                16.456,
                16.455,
                16.454,
                16.453,
                16.452,
                16.452,
                16.451,
                16.45,
                16.449,
                16.448,
                16.447,
                16.447,
                16.446,
                16.445,
                16.444,
                16.443,
                16.442,
                16.441,
                16.441,
                16.44,
                16.439,
                16.438,
                16.437,
                16.437,
                16.436,
                16.435,
                16.434,
                16.433,
                16.432,
                16.432,
                16.431,
                16.43,
                16.429,
                16.428,
                16.428,
                16.427,
                16.426,
                16.425,
                16.424,
                16.423,
                16.423,
                16.422,
                16.421,
                16.42,
                16.419,
                16.419,
                16.418,
                16.417,
                16.416,
                16.415,
                16.415,
                16.414,
                16.413,
                16.412,
                16.412,
                16.411,
                16.41,
                16.409,
                16.408,
                16.408,
                16.407,
                16.406,
                16.405,
                16.405,
                16.404,
                16.403,
                16.402,
                16.402,
                16.401,
                16.4,
                16.399,
                16.398,
                16.398,
                16.397,
                16.396,
                16.395,
                16.395,
                16.394,
                16.393,
                16.392,
                16.392,
                16.391,
                16.39,
                16.389,
                16.389,
                16.388,
                16.387,
                16.387,
                16.386,
                16.385,
                16.384,
                16.384,
                16.383,
                16.382,
                16.381,
                16.381,
                16.38,
                16.379,
                16.379,
                16.378,
                16.377,
                16.376,
                16.376,
                16.375,
                16.374,
                16.374,
                16.373,
                16.372,
                16.372,
                16.371,
                16.37,
                16.37,
                16.369,
                16.368,
                16.367,
                16.367,
                16.366,
                16.365,
                16.365,
                16.364,
                16.363,
                16.363,
                16.362,
                16.361,
                16.361,
                16.36,
                16.36,
                16.359,
                16.358,
                16.358,
                16.357,
                16.356,
                16.356,
                16.355,
                16.354,
                16.354,
                16.353,
                16.352,
                16.352,
                16.351,
                16.351,
                16.35,
                16.349,
                16.349,
                16.348,
                16.347,
                16.347,
                16.346,
                16.346,
                16.345,
                16.345,
                16.344,
                16.343,
                16.343,
                16.342,
                16.342,
                16.341,
                16.34,
                16.34,
                16.339,
                16.339,
                16.338,
                16.338,
                16.337,
                16.336,
                16.336,
                16.335,
                16.335,
                16.334,
                16.334,
                16.333,
                16.333,
                16.332,
                16.331,
                16.331,
                16.33,
                16.33,
                16.329,
                16.329,
                16.328,
                16.328,
                16.327,
                16.327,
                16.326,
                16.326,
                16.325,
                16.325,
                16.324,
                16.324,
                16.323,
                16.323,
                16.322,
                16.322,
                16.321,
                16.321,
                16.32,
                16.32,
                16.319,
                16.319,
                16.318,
                16.318,
                16.317,
                16.317,
                16.316,
                16.316,
                16.315,
                16.315,
                16.315,
                16.314,
                16.314,
                16.313,
                16.313,
                16.312,
                16.312,
                16.311,
                16.311,
                16.311,
                16.31,
                16.31,
                16.309,
                16.309,
                16.308,
                16.308,
                16.307,
                16.307,
                16.307,
                16.306,
                16.306,
                16.306,
                16.305,
                16.305,
                16.304,
                16.304,
                16.303,
                16.303,
                16.303,
                16.302,
                16.302,
                16.301,
                16.301,
                16.301,
                16.3,
                16.3,
                16.3,
                16.299,
                16.299,
                16.299,
                16.298,
                16.298,
                16.297,
                16.297,
                16.297,
                16.296,
                16.296,
                16.296,
                16.295,
                16.295,
                16.295,
                16.294,
                16.294,
                16.293,
                16.293,
                16.293,
                16.292,
                16.292,
                16.292,
                16.292,
                16.291,
                16.291,
                16.29,
                16.29,
                16.29,
                16.29,
                16.289,
                16.289,
                16.289,
                16.288,
                16.288,
                16.288,
                16.287,
                16.287,
                16.287,
                16.286,
                16.286,
                16.286,
                16.285,
                16.285,
                16.285,
                16.285,
                16.284,
                16.284,
                16.284,
                16.284,
                16.283,
                16.283,
                16.283,
                16.282,
                16.282,
                16.282,
                16.282,
                16.281,
                16.281,
                16.281,
                16.28,
                16.28,
                16.28,
                16.28,
                16.279,
                16.279,
                16.279,
                16.279,
                16.278,
                16.278,
                16.278,
                16.278,
                16.277,
                16.277,
                16.277,
                16.277,
                16.276,
                16.276,
                16.276,
                16.276,
                16.275,
                16.275,
                16.275,
                16.275,
                16.275,
                16.274,
                16.274,
                16.274,
                16.274,
                16.273,
                16.273,
                16.273,
                16.273,
                16.272,
                16.272,
                16.272,
                16.272,
                16.272,
                16.272,
                16.271,
                16.271,
                16.271,
                16.271,
                16.27,
                16.27,
                16.27,
                16.27,
                16.27,
                16.269,
                16.269,
                16.269,
                16.269,
                16.269,
                16.268,
                16.268,
                16.268,
                16.268,
                16.268,
                16.267,
                16.267,
                16.267,
                16.267,
                16.267,
                16.267,
                16.266,
                16.266,
                16.266,
                16.266,
                16.266,
                16.265,
                16.265,
                16.265,
                16.265,
                16.265,
                16.265,
                16.264,
                16.264,
                16.264,
                16.264,
                16.264,
                16.264,
                16.263,
                16.263,
                16.263,
                16.263,
                16.263,
                16.263,
                16.262,
                16.262,
                16.262,
                16.262,
                16.262,
                16.262,
                16.261,
                16.261,
                16.261,
                16.261,
                16.261,
                16.261,
                16.261,
                16.26,
                16.26,
                16.26,
                16.26,
                16.26,
                16.26,
                16.26,
                16.26,
                16.259,
                16.259,
                16.259,
                16.259,
                16.259,
                16.259,
                16.259,
                16.258,
                16.258,
                16.258,
                16.258,
                16.258,
                16.258,
                16.258,
                16.258,
                16.258,
                16.257,
                16.257,
                16.257,
                16.257,
                16.257,
                16.257,
                16.257,
                16.257,
                16.256,
                16.256,
                16.256,
                16.256,
                16.256,
                16.256,
                16.256,
                16.256,
                16.256,
                16.256,
                16.256,
                16.255,
                16.255,
                16.255,
                16.255,
                16.255,
                16.255,
                16.255,
                16.255,
                16.255,
                16.255,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.251,
                16.251,
                16.252,
                16.252,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.251,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.252,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.253,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.254,
                16.255,
                16.255,
                16.255,
                16.255,
                16.255,
                16.255,
                16.255,
                16.255,
                16.255,
                16.255,
                16.256,
                16.256,
                16.256,
                16.256,
                16.256,
                16.256,
                16.256,
                16.256,
                16.257,
                16.257,
                16.257,
                16.257,
                16.257,
                16.257,
                16.257,
                16.257,
                16.258,
                16.258,
                16.258,
                16.258,
                16.258,
                16.258,
                16.258,
                16.259,
                16.259,
                16.259,
                16.259,
                16.259,
                16.259,
                16.259,
                16.26,
                16.26,
                16.26,
                16.26,
                16.26,
                16.26,
                16.261,
                16.261,
                16.261,
                16.261,
                16.261,
                16.261,
                16.262,
                16.262,
                16.262,
                16.262,
                16.262,
                16.262,
                16.263,
                16.263,
                16.263,
                16.263,
                16.263,
                16.264,
                16.264,
                16.264,
                16.264,
                16.264,
                16.265,
                16.265,
                16.265,
                16.265,
                16.265,
                16.266,
                16.266,
                16.266,
                16.266,
                16.266,
                16.267,
                16.267,
                16.267,
                16.267,
                16.267,
                16.268,
                16.268,
                16.268,
                16.268,
                16.269,
                16.269,
                16.269,
                16.269,
                16.269,
                16.27,
                16.27,
                16.27,
                16.27,
                16.271,
                16.271,
                16.271,
                16.271,
                16.272,
                16.272,
                16.272,
                16.272,
                16.273,
                16.273,
                16.273,
                16.273,
                16.273,
                16.274,
                16.274,
                16.274,
                16.274,
                16.275,
                16.275,
                16.275,
                16.276,
                16.276,
                16.276,
                16.276,
                16.277,
                16.277,
                16.277,
                16.277,
                16.278,
                16.278,
                16.278,
                16.278,
                16.279,
                16.279,
                16.279,
                16.279,
                16.28,
                16.28,
                16.28,
                16.28,
                16.281,
                16.281,
                16.281,
                16.282,
                16.282,
                16.282,
                16.282,
                16.283,
                16.283,
                16.283,
                16.284,
                16.284,
                16.284,
                16.284,
                16.285,
                16.285,
                16.285,
                16.285,
                16.286,
                16.286,
                16.286,
                16.287,
                16.287,
                16.287,
                16.287,
                16.288,
                16.288,
                16.288,
                16.289,
                16.289,
                16.289,
                16.29,
                16.29,
                16.29,
                16.29,
                16.291,
                16.291,
                16.291,
                16.292,
                16.292,
                16.292,
                16.292,
                16.293,
                16.293,
                16.293,
                16.294,
                16.294,
                16.294,
                16.295,
                16.295,
                16.295,
                16.295,
                16.296,
                16.296,
                16.296,
                16.297,
                16.297,
                16.297,
                16.298,
                16.298,
                16.298,
                16.298,
                16.299,
                16.299,
                16.299,
                16.3,
                16.3,
                16.3,
                16.301,
                16.301,
                16.301,
                16.301,
                16.302,
                16.302,
                16.302,
                16.303,
                16.303,
                16.303,
                16.304,
                16.304,
                16.304,
                16.305,
                16.305,
                16.305,
                16.305,
                16.306,
                16.306,
                16.306,
                16.307,
                16.307,
                16.307,
                16.308,
                16.308,
                16.308,
                16.308,
                16.309,
                16.309,
                16.309,
                16.31,
                16.31,
                16.31,
                16.311,
                16.311,
                16.311,
                16.312,
                16.312,
                16.312,
                16.313,
                16.313,
                16.313,
                16.313,
                16.314,
                16.314,
                16.314,
                16.315,
                16.315,
                16.315,
                16.316,
                16.316,
                16.316,
                16.317,
                16.317,
                16.317,
                16.317,
                16.318,
                16.318,
                16.318,
                16.319,
                16.319,
                16.319,
                16.32,
                16.32,
                16.32,
                16.321,
                16.321,
                16.321,
                16.321,
                16.322,
                16.322,
                16.322,
                16.323,
                16.323,
                16.323,
                16.324,
                16.324,
                16.324,
                16.325,
                16.325,
                16.325,
                16.326,
                16.326,
                16.326,
                16.327,
                16.327,
                16.327,
                16.327,
                16.328,
                16.328,
                16.328,
                16.329,
                16.329,
                16.329,
                16.33,
                16.33,
                16.33,
                16.331,
                16.331,
                16.331,
                16.332,
                16.332,
                16.332,
                16.333,
                16.333,
                16.333,
                16.333,
                16.334,
                16.334,
                16.334,
                16.335,
                16.335,
                16.335,
                16.336,
                16.336,
                16.336,
                16.337,
                16.337,
                16.337,
                16.338,
                16.338,
                16.338,
                16.339,
                16.339,
                16.339,
                16.34,
                16.34,
                16.34,
                16.341,
                16.341,
                16.341,
                16.341,
                16.342,
                16.342,
                16.342,
                16.343,
                16.343,
                16.343,
                16.344,
                16.344,
                16.344,
                16.345,
                16.345,
                16.345,
                16.346,
                16.346,
                16.346,
                16.347,
                16.347,
                16.347,
                16.348
            ],
            P85: [
                14.686,
                14.673,
                14.659,
                14.646,
                14.632,
                14.618,
                14.605,
                14.59,
                14.626,
                14.662,
                14.698,
                14.734,
                14.77,
                14.806,
                14.842,
                14.914,
                14.991,
                15.069,
                15.148,
                15.228,
                15.307,
                15.386,
                15.463,
                15.539,
                15.614,
                15.687,
                15.759,
                15.829,
                15.898,
                15.965,
                16.03,
                16.093,
                16.155,
                16.214,
                16.272,
                16.328,
                16.382,
                16.435,
                16.486,
                16.536,
                16.585,
                16.632,
                16.678,
                16.723,
                16.767,
                16.81,
                16.852,
                16.892,
                16.931,
                16.97,
                17.007,
                17.044,
                17.079,
                17.114,
                17.148,
                17.181,
                17.214,
                17.245,
                17.277,
                17.307,
                17.337,
                17.366,
                17.394,
                17.422,
                17.449,
                17.476,
                17.502,
                17.527,
                17.552,
                17.576,
                17.6,
                17.623,
                17.645,
                17.667,
                17.689,
                17.71,
                17.731,
                17.751,
                17.771,
                17.79,
                17.809,
                17.828,
                17.846,
                17.864,
                17.882,
                17.899,
                17.915,
                17.932,
                17.948,
                17.964,
                17.979,
                17.994,
                18.009,
                18.023,
                18.037,
                18.051,
                18.065,
                18.078,
                18.091,
                18.104,
                18.117,
                18.129,
                18.141,
                18.153,
                18.164,
                18.175,
                18.186,
                18.197,
                18.208,
                18.218,
                18.228,
                18.238,
                18.248,
                18.257,
                18.267,
                18.276,
                18.285,
                18.294,
                18.302,
                18.311,
                18.319,
                18.327,
                18.335,
                18.343,
                18.351,
                18.358,
                18.366,
                18.373,
                18.38,
                18.387,
                18.394,
                18.4,
                18.407,
                18.413,
                18.42,
                18.426,
                18.432,
                18.438,
                18.443,
                18.449,
                18.454,
                18.46,
                18.465,
                18.47,
                18.475,
                18.48,
                18.484,
                18.489,
                18.493,
                18.497,
                18.502,
                18.506,
                18.51,
                18.513,
                18.517,
                18.521,
                18.524,
                18.528,
                18.531,
                18.534,
                18.537,
                18.54,
                18.543,
                18.546,
                18.548,
                18.551,
                18.553,
                18.556,
                18.558,
                18.56,
                18.562,
                18.564,
                18.566,
                18.568,
                18.57,
                18.571,
                18.573,
                18.574,
                18.575,
                18.577,
                18.578,
                18.579,
                18.58,
                18.58,
                18.581,
                18.582,
                18.582,
                18.583,
                18.583,
                18.583,
                18.584,
                18.584,
                18.584,
                18.584,
                18.584,
                18.583,
                18.583,
                18.583,
                18.582,
                18.582,
                18.581,
                18.581,
                18.58,
                18.579,
                18.578,
                18.577,
                18.576,
                18.575,
                18.574,
                18.573,
                18.571,
                18.57,
                18.569,
                18.567,
                18.566,
                18.564,
                18.562,
                18.56,
                18.559,
                18.557,
                18.555,
                18.553,
                18.551,
                18.549,
                18.547,
                18.544,
                18.542,
                18.54,
                18.537,
                18.535,
                18.532,
                18.53,
                18.527,
                18.525,
                18.522,
                18.519,
                18.516,
                18.514,
                18.511,
                18.508,
                18.505,
                18.502,
                18.499,
                18.495,
                18.492,
                18.489,
                18.486,
                18.483,
                18.479,
                18.476,
                18.472,
                18.469,
                18.465,
                18.462,
                18.458,
                18.455,
                18.451,
                18.447,
                18.444,
                18.44,
                18.436,
                18.432,
                18.428,
                18.424,
                18.42,
                18.416,
                18.412,
                18.408,
                18.404,
                18.4,
                18.396,
                18.392,
                18.388,
                18.383,
                18.379,
                18.375,
                18.371,
                18.366,
                18.362,
                18.358,
                18.353,
                18.349,
                18.345,
                18.34,
                18.335,
                18.331,
                18.327,
                18.322,
                18.318,
                18.313,
                18.308,
                18.304,
                18.299,
                18.294,
                18.29,
                18.285,
                18.281,
                18.276,
                18.271,
                18.266,
                18.262,
                18.257,
                18.252,
                18.248,
                18.243,
                18.238,
                18.233,
                18.228,
                18.224,
                18.219,
                18.214,
                18.209,
                18.204,
                18.199,
                18.195,
                18.19,
                18.185,
                18.18,
                18.175,
                18.17,
                18.165,
                18.161,
                18.156,
                18.151,
                18.146,
                18.141,
                18.136,
                18.131,
                18.127,
                18.122,
                18.117,
                18.112,
                18.107,
                18.102,
                18.097,
                18.092,
                18.087,
                18.083,
                18.078,
                18.073,
                18.068,
                18.063,
                18.058,
                18.053,
                18.048,
                18.044,
                18.039,
                18.034,
                18.029,
                18.024,
                18.019,
                18.014,
                18.01,
                18.005,
                18,
                17.995,
                17.99,
                17.986,
                17.981,
                17.976,
                17.971,
                17.966,
                17.962,
                17.957,
                17.952,
                17.947,
                17.943,
                17.938,
                17.933,
                17.928,
                17.924,
                17.919,
                17.914,
                17.909,
                17.905,
                17.9,
                17.895,
                17.891,
                17.886,
                17.881,
                17.877,
                17.872,
                17.867,
                17.863,
                17.858,
                17.854,
                17.849,
                17.844,
                17.84,
                17.835,
                17.831,
                17.826,
                17.821,
                17.817,
                17.812,
                17.808,
                17.803,
                17.799,
                17.794,
                17.79,
                17.785,
                17.781,
                17.776,
                17.772,
                17.768,
                17.763,
                17.759,
                17.754,
                17.75,
                17.745,
                17.741,
                17.737,
                17.732,
                17.728,
                17.724,
                17.719,
                17.715,
                17.711,
                17.706,
                17.702,
                17.698,
                17.694,
                17.689,
                17.685,
                17.681,
                17.676,
                17.672,
                17.668,
                17.664,
                17.66,
                17.656,
                17.651,
                17.647,
                17.643,
                17.639,
                17.635,
                17.631,
                17.626,
                17.622,
                17.618,
                17.614,
                17.61,
                17.606,
                17.602,
                17.598,
                17.594,
                17.59,
                17.586,
                17.582,
                17.578,
                17.574,
                17.57,
                17.566,
                17.562,
                17.558,
                17.554,
                17.55,
                17.546,
                17.543,
                17.539,
                17.535,
                17.531,
                17.527,
                17.523,
                17.52,
                17.516,
                17.512,
                17.508,
                17.504,
                17.501,
                17.497,
                17.493,
                17.489,
                17.486,
                17.482,
                17.478,
                17.474,
                17.471,
                17.467,
                17.463,
                17.46,
                17.456,
                17.453,
                17.449,
                17.445,
                17.442,
                17.438,
                17.435,
                17.431,
                17.427,
                17.424,
                17.42,
                17.417,
                17.413,
                17.41,
                17.406,
                17.403,
                17.399,
                17.396,
                17.392,
                17.389,
                17.386,
                17.382,
                17.379,
                17.375,
                17.372,
                17.369,
                17.365,
                17.362,
                17.359,
                17.355,
                17.352,
                17.349,
                17.345,
                17.342,
                17.339,
                17.336,
                17.332,
                17.329,
                17.326,
                17.323,
                17.319,
                17.316,
                17.313,
                17.31,
                17.307,
                17.304,
                17.3,
                17.297,
                17.294,
                17.291,
                17.288,
                17.285,
                17.282,
                17.279,
                17.276,
                17.273,
                17.269,
                17.267,
                17.263,
                17.26,
                17.258,
                17.254,
                17.251,
                17.249,
                17.246,
                17.243,
                17.24,
                17.237,
                17.234,
                17.231,
                17.228,
                17.225,
                17.222,
                17.219,
                17.217,
                17.214,
                17.211,
                17.208,
                17.205,
                17.203,
                17.2,
                17.197,
                17.194,
                17.191,
                17.189,
                17.186,
                17.183,
                17.18,
                17.178,
                17.175,
                17.172,
                17.17,
                17.167,
                17.164,
                17.162,
                17.159,
                17.156,
                17.154,
                17.151,
                17.149,
                17.146,
                17.143,
                17.141,
                17.138,
                17.136,
                17.133,
                17.131,
                17.128,
                17.126,
                17.123,
                17.121,
                17.118,
                17.116,
                17.113,
                17.111,
                17.108,
                17.106,
                17.104,
                17.101,
                17.099,
                17.096,
                17.094,
                17.092,
                17.089,
                17.087,
                17.085,
                17.082,
                17.08,
                17.078,
                17.075,
                17.073,
                17.071,
                17.069,
                17.066,
                17.064,
                17.062,
                17.06,
                17.057,
                17.055,
                17.053,
                17.051,
                17.049,
                17.046,
                17.044,
                17.042,
                17.04,
                17.038,
                17.036,
                17.034,
                17.032,
                17.029,
                17.027,
                17.025,
                17.023,
                17.021,
                17.019,
                17.017,
                17.015,
                17.013,
                17.011,
                17.009,
                17.007,
                17.005,
                17.003,
                17.001,
                17,
                16.998,
                16.996,
                16.994,
                16.992,
                16.99,
                16.988,
                16.986,
                16.984,
                16.983,
                16.981,
                16.979,
                16.977,
                16.975,
                16.974,
                16.972,
                16.97,
                16.968,
                16.967,
                16.965,
                16.963,
                16.962,
                16.96,
                16.958,
                16.956,
                16.955,
                16.953,
                16.952,
                16.95,
                16.948,
                16.947,
                16.945,
                16.943,
                16.942,
                16.94,
                16.939,
                16.937,
                16.935,
                16.934,
                16.932,
                16.931,
                16.929,
                16.928,
                16.926,
                16.925,
                16.923,
                16.922,
                16.921,
                16.919,
                16.918,
                16.916,
                16.915,
                16.913,
                16.912,
                16.911,
                16.909,
                16.908,
                16.907,
                16.905,
                16.904,
                16.902,
                16.901,
                16.9,
                16.898,
                16.897,
                16.896,
                16.895,
                16.893,
                16.892,
                16.891,
                16.89,
                16.888,
                16.887,
                16.886,
                16.885,
                16.883,
                16.882,
                16.881,
                16.88,
                16.878,
                16.877,
                16.876,
                16.875,
                16.874,
                16.873,
                17.163,
                17.162,
                17.161,
                17.16,
                17.159,
                17.158,
                17.157,
                17.156,
                17.155,
                17.154,
                17.153,
                17.151,
                17.15,
                17.149,
                17.148,
                17.147,
                17.146,
                17.145,
                17.144,
                17.143,
                17.142,
                17.141,
                17.14,
                17.139,
                17.138,
                17.137,
                17.135,
                17.134,
                17.133,
                17.132,
                17.131,
                17.13,
                17.129,
                17.128,
                17.127,
                17.126,
                17.125,
                17.124,
                17.123,
                17.122,
                17.121,
                17.12,
                17.119,
                17.118,
                17.117,
                17.115,
                17.114,
                17.113,
                17.112,
                17.111,
                17.11,
                17.109,
                17.108,
                17.107,
                17.106,
                17.105,
                17.104,
                17.103,
                17.102,
                17.101,
                17.1,
                17.099,
                17.098,
                17.097,
                17.096,
                17.095,
                17.094,
                17.093,
                17.092,
                17.091,
                17.09,
                17.089,
                17.088,
                17.087,
                17.086,
                17.085,
                17.084,
                17.083,
                17.082,
                17.081,
                17.08,
                17.079,
                17.078,
                17.077,
                17.076,
                17.075,
                17.074,
                17.073,
                17.073,
                17.071,
                17.07,
                17.069,
                17.068,
                17.068,
                17.067,
                17.066,
                17.065,
                17.064,
                17.063,
                17.062,
                17.061,
                17.06,
                17.059,
                17.058,
                17.057,
                17.056,
                17.055,
                17.054,
                17.053,
                17.052,
                17.051,
                17.05,
                17.049,
                17.049,
                17.048,
                17.046,
                17.046,
                17.045,
                17.044,
                17.043,
                17.042,
                17.041,
                17.04,
                17.039,
                17.038,
                17.037,
                17.036,
                17.035,
                17.035,
                17.034,
                17.033,
                17.032,
                17.031,
                17.03,
                17.029,
                17.028,
                17.027,
                17.026,
                17.025,
                17.024,
                17.023,
                17.022,
                17.022,
                17.021,
                17.02,
                17.019,
                17.018,
                17.017,
                17.016,
                17.015,
                17.014,
                17.013,
                17.013,
                17.012,
                17.011,
                17.01,
                17.009,
                17.008,
                17.007,
                17.006,
                17.005,
                17.004,
                17.004,
                17.003,
                17.002,
                17.001,
                17,
                16.999,
                16.998,
                16.997,
                16.996,
                16.996,
                16.995,
                16.994,
                16.993,
                16.992,
                16.991,
                16.99,
                16.99,
                16.989,
                16.988,
                16.987,
                16.986,
                16.985,
                16.984,
                16.983,
                16.983,
                16.982,
                16.981,
                16.98,
                16.979,
                16.978,
                16.978,
                16.977,
                16.976,
                16.975,
                16.974,
                16.973,
                16.972,
                16.972,
                16.971,
                16.97,
                16.969,
                16.968,
                16.967,
                16.966,
                16.966,
                16.965,
                16.964,
                16.963,
                16.962,
                16.961,
                16.961,
                16.96,
                16.959,
                16.958,
                16.957,
                16.956,
                16.956,
                16.955,
                16.954,
                16.953,
                16.953,
                16.952,
                16.951,
                16.95,
                16.949,
                16.948,
                16.948,
                16.947,
                16.946,
                16.945,
                16.944,
                16.944,
                16.943,
                16.942,
                16.941,
                16.94,
                16.94,
                16.939,
                16.938,
                16.937,
                16.937,
                16.936,
                16.935,
                16.934,
                16.933,
                16.933,
                16.932,
                16.931,
                16.93,
                16.93,
                16.929,
                16.928,
                16.927,
                16.927,
                16.926,
                16.925,
                16.924,
                16.924,
                16.923,
                16.922,
                16.921,
                16.921,
                16.92,
                16.919,
                16.919,
                16.918,
                16.917,
                16.916,
                16.916,
                16.915,
                16.914,
                16.914,
                16.913,
                16.912,
                16.911,
                16.911,
                16.91,
                16.909,
                16.908,
                16.908,
                16.907,
                16.907,
                16.906,
                16.905,
                16.904,
                16.904,
                16.903,
                16.902,
                16.902,
                16.901,
                16.9,
                16.9,
                16.899,
                16.898,
                16.898,
                16.897,
                16.896,
                16.896,
                16.895,
                16.895,
                16.894,
                16.893,
                16.893,
                16.892,
                16.891,
                16.891,
                16.89,
                16.889,
                16.889,
                16.888,
                16.888,
                16.887,
                16.887,
                16.886,
                16.885,
                16.885,
                16.884,
                16.883,
                16.883,
                16.882,
                16.882,
                16.881,
                16.88,
                16.88,
                16.879,
                16.879,
                16.878,
                16.878,
                16.877,
                16.877,
                16.876,
                16.875,
                16.875,
                16.874,
                16.874,
                16.873,
                16.873,
                16.872,
                16.872,
                16.871,
                16.871,
                16.87,
                16.87,
                16.869,
                16.869,
                16.868,
                16.868,
                16.867,
                16.866,
                16.866,
                16.866,
                16.865,
                16.865,
                16.864,
                16.864,
                16.863,
                16.863,
                16.862,
                16.862,
                16.861,
                16.861,
                16.86,
                16.86,
                16.859,
                16.859,
                16.858,
                16.858,
                16.858,
                16.857,
                16.857,
                16.856,
                16.856,
                16.855,
                16.855,
                16.855,
                16.854,
                16.854,
                16.853,
                16.853,
                16.852,
                16.852,
                16.852,
                16.851,
                16.851,
                16.85,
                16.85,
                16.85,
                16.849,
                16.849,
                16.849,
                16.848,
                16.848,
                16.847,
                16.847,
                16.847,
                16.846,
                16.846,
                16.846,
                16.845,
                16.845,
                16.844,
                16.844,
                16.844,
                16.843,
                16.843,
                16.843,
                16.842,
                16.842,
                16.842,
                16.841,
                16.841,
                16.841,
                16.84,
                16.84,
                16.84,
                16.84,
                16.839,
                16.839,
                16.839,
                16.838,
                16.838,
                16.838,
                16.837,
                16.837,
                16.837,
                16.837,
                16.836,
                16.836,
                16.836,
                16.835,
                16.835,
                16.835,
                16.835,
                16.834,
                16.834,
                16.834,
                16.834,
                16.833,
                16.833,
                16.833,
                16.833,
                16.832,
                16.832,
                16.832,
                16.832,
                16.831,
                16.831,
                16.831,
                16.831,
                16.83,
                16.83,
                16.83,
                16.83,
                16.83,
                16.829,
                16.829,
                16.829,
                16.829,
                16.829,
                16.828,
                16.828,
                16.828,
                16.828,
                16.828,
                16.827,
                16.827,
                16.827,
                16.827,
                16.827,
                16.826,
                16.826,
                16.826,
                16.826,
                16.826,
                16.825,
                16.825,
                16.825,
                16.825,
                16.825,
                16.825,
                16.824,
                16.824,
                16.824,
                16.824,
                16.824,
                16.824,
                16.824,
                16.823,
                16.823,
                16.823,
                16.823,
                16.823,
                16.823,
                16.822,
                16.822,
                16.822,
                16.822,
                16.822,
                16.822,
                16.822,
                16.821,
                16.821,
                16.821,
                16.821,
                16.821,
                16.821,
                16.821,
                16.821,
                16.821,
                16.82,
                16.82,
                16.82,
                16.82,
                16.82,
                16.82,
                16.82,
                16.82,
                16.82,
                16.82,
                16.819,
                16.819,
                16.819,
                16.819,
                16.819,
                16.819,
                16.819,
                16.819,
                16.819,
                16.819,
                16.819,
                16.819,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.816,
                16.817,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.816,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.817,
                16.818,
                16.817,
                16.817,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.818,
                16.819,
                16.819,
                16.819,
                16.819,
                16.819,
                16.819,
                16.819,
                16.819,
                16.819,
                16.819,
                16.819,
                16.82,
                16.82,
                16.82,
                16.82,
                16.82,
                16.82,
                16.82,
                16.82,
                16.82,
                16.82,
                16.82,
                16.821,
                16.821,
                16.821,
                16.821,
                16.821,
                16.821,
                16.821,
                16.821,
                16.821,
                16.822,
                16.822,
                16.822,
                16.822,
                16.822,
                16.822,
                16.822,
                16.822,
                16.823,
                16.823,
                16.823,
                16.823,
                16.823,
                16.823,
                16.823,
                16.824,
                16.824,
                16.824,
                16.824,
                16.824,
                16.824,
                16.824,
                16.824,
                16.825,
                16.825,
                16.825,
                16.825,
                16.825,
                16.825,
                16.826,
                16.826,
                16.826,
                16.826,
                16.826,
                16.826,
                16.826,
                16.827,
                16.827,
                16.827,
                16.827,
                16.827,
                16.828,
                16.828,
                16.828,
                16.828,
                16.828,
                16.829,
                16.829,
                16.829,
                16.829,
                16.829,
                16.829,
                16.83,
                16.83,
                16.83,
                16.83,
                16.83,
                16.831,
                16.831,
                16.831,
                16.831,
                16.831,
                16.832,
                16.832,
                16.832,
                16.832,
                16.832,
                16.833,
                16.833,
                16.833,
                16.833,
                16.834,
                16.834,
                16.834,
                16.834,
                16.835,
                16.835,
                16.835,
                16.835,
                16.836,
                16.836,
                16.836,
                16.836,
                16.836,
                16.837,
                16.837,
                16.837,
                16.837,
                16.838,
                16.838,
                16.838,
                16.838,
                16.839,
                16.839,
                16.839,
                16.84,
                16.84,
                16.84,
                16.84,
                16.841,
                16.841,
                16.841,
                16.841,
                16.842,
                16.842,
                16.842,
                16.842,
                16.843,
                16.843,
                16.843,
                16.844,
                16.844,
                16.844,
                16.844,
                16.845,
                16.845,
                16.845,
                16.846,
                16.846,
                16.846,
                16.847,
                16.847,
                16.847,
                16.848,
                16.848,
                16.848,
                16.848,
                16.849,
                16.849,
                16.849,
                16.85,
                16.85,
                16.85,
                16.851,
                16.851,
                16.851,
                16.852,
                16.852,
                16.852,
                16.852,
                16.853,
                16.853,
                16.854,
                16.854,
                16.854,
                16.855,
                16.855,
                16.855,
                16.855,
                16.856,
                16.856,
                16.857,
                16.857,
                16.857,
                16.858,
                16.858,
                16.858,
                16.859,
                16.859,
                16.859,
                16.86,
                16.86,
                16.86,
                16.861,
                16.861,
                16.861,
                16.862,
                16.862,
                16.863,
                16.863,
                16.863,
                16.864,
                16.864,
                16.864,
                16.865,
                16.865,
                16.866,
                16.866,
                16.866,
                16.867,
                16.867,
                16.867,
                16.868,
                16.868,
                16.869,
                16.869,
                16.869,
                16.87,
                16.87,
                16.871,
                16.871,
                16.871,
                16.872,
                16.872,
                16.872,
                16.873,
                16.873,
                16.873,
                16.874,
                16.874,
                16.875,
                16.875,
                16.876,
                16.876,
                16.876,
                16.877,
                16.877,
                16.878,
                16.878,
                16.878,
                16.879,
                16.879,
                16.879,
                16.88,
                16.88,
                16.881,
                16.881,
                16.881,
                16.882,
                16.882,
                16.883,
                16.883,
                16.884,
                16.884,
                16.885,
                16.885,
                16.885,
                16.886,
                16.886,
                16.886,
                16.887,
                16.887,
                16.888,
                16.888,
                16.889,
                16.889,
                16.889,
                16.89,
                16.89,
                16.891,
                16.891,
                16.891,
                16.892,
                16.892,
                16.893,
                16.893,
                16.893,
                16.894,
                16.894,
                16.895,
                16.895,
                16.896,
                16.896,
                16.896,
                16.897,
                16.897,
                16.898,
                16.898,
                16.899,
                16.899,
                16.899,
                16.9,
                16.9,
                16.901,
                16.901,
                16.902,
                16.902,
                16.902,
                16.903,
                16.903,
                16.904,
                16.904,
                16.904,
                16.905,
                16.905,
                16.906,
                16.906,
                16.906,
                16.907,
                16.907,
                16.908,
                16.908,
                16.909,
                16.909,
                16.91,
                16.91,
                16.91,
                16.911,
                16.911,
                16.912,
                16.912,
                16.913,
                16.913,
                16.913,
                16.914,
                16.914,
                16.914,
                16.915,
                16.915,
                16.916,
                16.916,
                16.917,
                16.917,
                16.918,
                16.918,
                16.918,
                16.919,
                16.919,
                16.92,
                16.92,
                16.92,
                16.921,
                16.921,
                16.922,
                16.922,
                16.923,
                16.923,
                16.923,
                16.924,
                16.924,
                16.925,
                16.925,
                16.926,
                16.926,
                16.926,
                16.927,
                16.927,
                16.928,
                16.928,
                16.928,
                16.929,
                16.929,
                16.93,
                16.93,
                16.93,
                16.931,
                16.931,
                16.932,
                16.932,
                16.933,
                16.933,
                16.933,
                16.934,
                16.934,
                16.935,
                16.935,
                16.936,
                16.936,
                16.936,
                16.937,
                16.937,
                16.938,
                16.938,
                16.938,
                16.939,
                16.939,
                16.94,
                16.94,
                16.94,
                16.941,
                16.941,
                16.942,
                16.942,
                16.943,
                16.943,
                16.943,
                16.944,
                16.944,
                16.945,
                16.945,
                16.946,
                16.946,
                16.946,
                16.947,
                16.947,
                16.948,
                16.948,
                16.948,
                16.949,
                16.949,
                16.95,
                16.95,
                16.951,
                16.951,
                16.951,
                16.952,
                16.952,
                16.953,
                16.953,
                16.953,
                16.954,
                16.954,
                16.955,
                16.955,
                16.956,
                16.956,
                16.956,
                16.957,
                16.957,
                16.958,
                16.958,
                16.958,
                16.959,
                16.959,
                16.96,
                16.96,
                16.961,
                16.961,
                16.961,
                16.962,
                16.962,
                16.963,
                16.963,
                16.963,
                16.964,
                16.964,
                16.965,
                16.965,
                16.965,
                16.966,
                16.966,
                16.967,
                16.967,
                16.968,
                16.968,
                16.968,
                16.969,
                16.969,
                16.97
            ],
            P90: [
                15.026,
                15.012,
                14.998,
                14.983,
                14.969,
                14.954,
                14.939,
                14.924,
                14.961,
                14.997,
                15.034,
                15.071,
                15.107,
                15.144,
                15.181,
                15.255,
                15.333,
                15.413,
                15.494,
                15.576,
                15.657,
                15.737,
                15.816,
                15.894,
                15.97,
                16.045,
                16.119,
                16.191,
                16.261,
                16.329,
                16.396,
                16.46,
                16.523,
                16.584,
                16.643,
                16.7,
                16.756,
                16.81,
                16.862,
                16.913,
                16.963,
                17.012,
                17.059,
                17.105,
                17.15,
                17.193,
                17.236,
                17.277,
                17.317,
                17.357,
                17.395,
                17.432,
                17.469,
                17.505,
                17.539,
                17.573,
                17.606,
                17.639,
                17.671,
                17.701,
                17.732,
                17.762,
                17.791,
                17.819,
                17.847,
                17.874,
                17.901,
                17.926,
                17.952,
                17.977,
                18.001,
                18.024,
                18.048,
                18.07,
                18.092,
                18.114,
                18.135,
                18.156,
                18.176,
                18.196,
                18.215,
                18.234,
                18.253,
                18.271,
                18.289,
                18.306,
                18.324,
                18.34,
                18.357,
                18.373,
                18.388,
                18.404,
                18.419,
                18.434,
                18.448,
                18.463,
                18.477,
                18.49,
                18.504,
                18.517,
                18.529,
                18.542,
                18.554,
                18.566,
                18.578,
                18.589,
                18.601,
                18.612,
                18.622,
                18.633,
                18.644,
                18.654,
                18.663,
                18.673,
                18.683,
                18.692,
                18.701,
                18.71,
                18.719,
                18.728,
                18.736,
                18.745,
                18.753,
                18.761,
                18.769,
                18.776,
                18.784,
                18.791,
                18.799,
                18.806,
                18.813,
                18.819,
                18.826,
                18.833,
                18.839,
                18.845,
                18.851,
                18.857,
                18.863,
                18.869,
                18.874,
                18.88,
                18.885,
                18.89,
                18.895,
                18.9,
                18.905,
                18.91,
                18.914,
                18.918,
                18.923,
                18.927,
                18.931,
                18.935,
                18.939,
                18.942,
                18.946,
                18.949,
                18.952,
                18.956,
                18.959,
                18.962,
                18.965,
                18.968,
                18.97,
                18.973,
                18.975,
                18.978,
                18.98,
                18.982,
                18.984,
                18.987,
                18.989,
                18.99,
                18.992,
                18.994,
                18.995,
                18.996,
                18.998,
                18.999,
                19,
                19.001,
                19.002,
                19.003,
                19.003,
                19.004,
                19.005,
                19.005,
                19.006,
                19.006,
                19.006,
                19.006,
                19.006,
                19.006,
                19.006,
                19.006,
                19.005,
                19.005,
                19.005,
                19.004,
                19.004,
                19.003,
                19.002,
                19.001,
                19.001,
                18.999,
                18.998,
                18.997,
                18.996,
                18.995,
                18.993,
                18.992,
                18.991,
                18.989,
                18.988,
                18.986,
                18.984,
                18.982,
                18.98,
                18.978,
                18.976,
                18.975,
                18.972,
                18.97,
                18.968,
                18.966,
                18.963,
                18.961,
                18.958,
                18.956,
                18.953,
                18.951,
                18.948,
                18.945,
                18.943,
                18.94,
                18.937,
                18.934,
                18.931,
                18.928,
                18.925,
                18.922,
                18.919,
                18.915,
                18.912,
                18.909,
                18.906,
                18.902,
                18.899,
                18.895,
                18.892,
                18.888,
                18.885,
                18.881,
                18.877,
                18.874,
                18.87,
                18.866,
                18.862,
                18.858,
                18.854,
                18.851,
                18.846,
                18.843,
                18.839,
                18.834,
                18.83,
                18.826,
                18.822,
                18.818,
                18.814,
                18.809,
                18.805,
                18.801,
                18.796,
                18.792,
                18.788,
                18.783,
                18.779,
                18.774,
                18.77,
                18.765,
                18.761,
                18.756,
                18.751,
                18.747,
                18.742,
                18.738,
                18.733,
                18.728,
                18.724,
                18.719,
                18.714,
                18.709,
                18.705,
                18.7,
                18.695,
                18.69,
                18.686,
                18.681,
                18.676,
                18.671,
                18.666,
                18.661,
                18.657,
                18.651,
                18.647,
                18.642,
                18.637,
                18.632,
                18.627,
                18.622,
                18.617,
                18.612,
                18.607,
                18.602,
                18.597,
                18.592,
                18.587,
                18.582,
                18.577,
                18.572,
                18.567,
                18.562,
                18.557,
                18.552,
                18.547,
                18.542,
                18.537,
                18.533,
                18.527,
                18.523,
                18.517,
                18.512,
                18.507,
                18.502,
                18.497,
                18.492,
                18.487,
                18.482,
                18.477,
                18.472,
                18.467,
                18.462,
                18.457,
                18.452,
                18.448,
                18.442,
                18.438,
                18.433,
                18.428,
                18.423,
                18.418,
                18.413,
                18.408,
                18.403,
                18.398,
                18.393,
                18.388,
                18.383,
                18.378,
                18.373,
                18.368,
                18.364,
                18.359,
                18.354,
                18.349,
                18.344,
                18.339,
                18.334,
                18.33,
                18.325,
                18.32,
                18.315,
                18.31,
                18.305,
                18.301,
                18.296,
                18.291,
                18.286,
                18.281,
                18.277,
                18.272,
                18.267,
                18.263,
                18.258,
                18.253,
                18.248,
                18.244,
                18.239,
                18.234,
                18.23,
                18.225,
                18.22,
                18.216,
                18.211,
                18.206,
                18.202,
                18.197,
                18.193,
                18.188,
                18.183,
                18.179,
                18.174,
                18.17,
                18.165,
                18.161,
                18.156,
                18.152,
                18.147,
                18.143,
                18.138,
                18.134,
                18.129,
                18.125,
                18.12,
                18.116,
                18.111,
                18.107,
                18.103,
                18.098,
                18.094,
                18.09,
                18.085,
                18.081,
                18.076,
                18.072,
                18.068,
                18.064,
                18.059,
                18.055,
                18.051,
                18.046,
                18.042,
                18.038,
                18.034,
                18.03,
                18.025,
                18.021,
                18.017,
                18.013,
                18.009,
                18.004,
                18,
                17.996,
                17.992,
                17.988,
                17.984,
                17.98,
                17.976,
                17.972,
                17.967,
                17.963,
                17.959,
                17.955,
                17.951,
                17.947,
                17.943,
                17.939,
                17.935,
                17.931,
                17.927,
                17.923,
                17.919,
                17.916,
                17.912,
                17.908,
                17.904,
                17.9,
                17.896,
                17.892,
                17.888,
                17.885,
                17.881,
                17.877,
                17.873,
                17.869,
                17.866,
                17.862,
                17.858,
                17.854,
                17.851,
                17.847,
                17.843,
                17.839,
                17.836,
                17.832,
                17.829,
                17.825,
                17.821,
                17.818,
                17.814,
                17.81,
                17.807,
                17.803,
                17.8,
                17.796,
                17.793,
                17.789,
                17.785,
                17.782,
                17.779,
                17.775,
                17.771,
                17.768,
                17.765,
                17.761,
                17.757,
                17.754,
                17.751,
                17.747,
                17.744,
                17.741,
                17.737,
                17.734,
                17.731,
                17.727,
                17.724,
                17.721,
                17.717,
                17.714,
                17.711,
                17.707,
                17.704,
                17.701,
                17.698,
                17.694,
                17.691,
                17.688,
                17.685,
                17.682,
                17.678,
                17.675,
                17.672,
                17.669,
                17.666,
                17.663,
                17.659,
                17.656,
                17.653,
                17.65,
                17.647,
                17.644,
                17.641,
                17.638,
                17.635,
                17.632,
                17.629,
                17.626,
                17.623,
                17.62,
                17.617,
                17.614,
                17.611,
                17.608,
                17.605,
                17.602,
                17.599,
                17.596,
                17.594,
                17.591,
                17.588,
                17.585,
                17.582,
                17.58,
                17.577,
                17.574,
                17.571,
                17.568,
                17.565,
                17.563,
                17.56,
                17.557,
                17.554,
                17.552,
                17.549,
                17.546,
                17.544,
                17.541,
                17.538,
                17.536,
                17.533,
                17.53,
                17.528,
                17.525,
                17.522,
                17.52,
                17.517,
                17.515,
                17.512,
                17.51,
                17.507,
                17.505,
                17.502,
                17.499,
                17.497,
                17.494,
                17.492,
                17.489,
                17.487,
                17.485,
                17.482,
                17.48,
                17.477,
                17.475,
                17.472,
                17.47,
                17.468,
                17.465,
                17.463,
                17.46,
                17.458,
                17.456,
                17.453,
                17.451,
                17.449,
                17.446,
                17.444,
                17.442,
                17.44,
                17.437,
                17.435,
                17.433,
                17.431,
                17.429,
                17.426,
                17.424,
                17.422,
                17.42,
                17.418,
                17.416,
                17.413,
                17.411,
                17.409,
                17.407,
                17.405,
                17.403,
                17.401,
                17.399,
                17.397,
                17.395,
                17.393,
                17.391,
                17.389,
                17.387,
                17.385,
                17.383,
                17.381,
                17.379,
                17.377,
                17.375,
                17.373,
                17.371,
                17.369,
                17.367,
                17.366,
                17.364,
                17.362,
                17.36,
                17.358,
                17.356,
                17.354,
                17.353,
                17.351,
                17.349,
                17.347,
                17.346,
                17.344,
                17.342,
                17.34,
                17.339,
                17.337,
                17.335,
                17.334,
                17.332,
                17.33,
                17.328,
                17.327,
                17.325,
                17.323,
                17.322,
                17.32,
                17.319,
                17.317,
                17.316,
                17.314,
                17.312,
                17.311,
                17.309,
                17.308,
                17.306,
                17.305,
                17.303,
                17.302,
                17.3,
                17.299,
                17.297,
                17.296,
                17.294,
                17.293,
                17.291,
                17.29,
                17.289,
                17.287,
                17.286,
                17.285,
                17.283,
                17.282,
                17.28,
                17.279,
                17.278,
                17.276,
                17.275,
                17.274,
                17.272,
                17.271,
                17.27,
                17.268,
                17.267,
                17.266,
                17.265,
                17.263,
                17.262,
                17.261,
                17.26,
                17.259,
                17.257,
                17.256,
                17.255,
                17.254,
                17.253,
                17.544,
                17.543,
                17.542,
                17.541,
                17.54,
                17.539,
                17.538,
                17.537,
                17.535,
                17.534,
                17.533,
                17.532,
                17.531,
                17.53,
                17.529,
                17.527,
                17.526,
                17.525,
                17.524,
                17.523,
                17.522,
                17.521,
                17.52,
                17.519,
                17.518,
                17.517,
                17.515,
                17.514,
                17.513,
                17.512,
                17.511,
                17.51,
                17.509,
                17.508,
                17.507,
                17.506,
                17.504,
                17.503,
                17.502,
                17.501,
                17.5,
                17.499,
                17.498,
                17.497,
                17.496,
                17.495,
                17.494,
                17.493,
                17.492,
                17.491,
                17.49,
                17.489,
                17.488,
                17.487,
                17.486,
                17.485,
                17.484,
                17.482,
                17.481,
                17.48,
                17.479,
                17.478,
                17.477,
                17.476,
                17.475,
                17.474,
                17.473,
                17.472,
                17.471,
                17.47,
                17.469,
                17.468,
                17.467,
                17.466,
                17.465,
                17.464,
                17.463,
                17.462,
                17.461,
                17.46,
                17.459,
                17.458,
                17.457,
                17.456,
                17.455,
                17.454,
                17.453,
                17.452,
                17.451,
                17.45,
                17.449,
                17.448,
                17.447,
                17.446,
                17.445,
                17.444,
                17.443,
                17.442,
                17.441,
                17.44,
                17.439,
                17.438,
                17.437,
                17.436,
                17.435,
                17.434,
                17.433,
                17.432,
                17.431,
                17.43,
                17.429,
                17.428,
                17.427,
                17.426,
                17.425,
                17.424,
                17.423,
                17.422,
                17.421,
                17.421,
                17.419,
                17.419,
                17.418,
                17.417,
                17.416,
                17.415,
                17.414,
                17.413,
                17.412,
                17.411,
                17.41,
                17.409,
                17.408,
                17.407,
                17.406,
                17.405,
                17.404,
                17.403,
                17.402,
                17.401,
                17.401,
                17.4,
                17.399,
                17.398,
                17.397,
                17.396,
                17.395,
                17.394,
                17.393,
                17.392,
                17.391,
                17.39,
                17.39,
                17.389,
                17.388,
                17.387,
                17.386,
                17.385,
                17.384,
                17.383,
                17.382,
                17.381,
                17.38,
                17.379,
                17.379,
                17.378,
                17.377,
                17.376,
                17.375,
                17.374,
                17.373,
                17.372,
                17.371,
                17.37,
                17.369,
                17.368,
                17.368,
                17.367,
                17.366,
                17.365,
                17.364,
                17.363,
                17.362,
                17.362,
                17.361,
                17.36,
                17.359,
                17.358,
                17.357,
                17.356,
                17.356,
                17.355,
                17.354,
                17.353,
                17.352,
                17.351,
                17.35,
                17.349,
                17.348,
                17.348,
                17.347,
                17.346,
                17.345,
                17.344,
                17.343,
                17.342,
                17.342,
                17.341,
                17.34,
                17.339,
                17.338,
                17.337,
                17.337,
                17.336,
                17.335,
                17.334,
                17.333,
                17.332,
                17.332,
                17.331,
                17.33,
                17.329,
                17.328,
                17.328,
                17.327,
                17.326,
                17.325,
                17.324,
                17.323,
                17.323,
                17.322,
                17.321,
                17.32,
                17.319,
                17.319,
                17.318,
                17.317,
                17.316,
                17.316,
                17.315,
                17.314,
                17.313,
                17.312,
                17.312,
                17.311,
                17.31,
                17.309,
                17.308,
                17.308,
                17.307,
                17.306,
                17.306,
                17.305,
                17.304,
                17.303,
                17.302,
                17.302,
                17.301,
                17.3,
                17.3,
                17.299,
                17.298,
                17.297,
                17.296,
                17.296,
                17.295,
                17.294,
                17.294,
                17.293,
                17.292,
                17.291,
                17.291,
                17.29,
                17.289,
                17.289,
                17.288,
                17.287,
                17.287,
                17.286,
                17.285,
                17.284,
                17.284,
                17.283,
                17.283,
                17.282,
                17.281,
                17.28,
                17.28,
                17.279,
                17.278,
                17.278,
                17.277,
                17.277,
                17.276,
                17.275,
                17.274,
                17.274,
                17.273,
                17.273,
                17.272,
                17.271,
                17.271,
                17.27,
                17.269,
                17.269,
                17.268,
                17.268,
                17.267,
                17.266,
                17.266,
                17.265,
                17.265,
                17.264,
                17.263,
                17.263,
                17.262,
                17.262,
                17.261,
                17.261,
                17.26,
                17.259,
                17.259,
                17.258,
                17.258,
                17.257,
                17.257,
                17.256,
                17.256,
                17.255,
                17.254,
                17.254,
                17.253,
                17.253,
                17.252,
                17.252,
                17.251,
                17.251,
                17.25,
                17.25,
                17.249,
                17.249,
                17.248,
                17.248,
                17.247,
                17.247,
                17.246,
                17.246,
                17.245,
                17.245,
                17.244,
                17.244,
                17.243,
                17.243,
                17.243,
                17.242,
                17.242,
                17.241,
                17.241,
                17.24,
                17.24,
                17.239,
                17.239,
                17.239,
                17.238,
                17.238,
                17.237,
                17.237,
                17.236,
                17.236,
                17.236,
                17.235,
                17.235,
                17.234,
                17.234,
                17.234,
                17.233,
                17.233,
                17.233,
                17.232,
                17.232,
                17.231,
                17.231,
                17.231,
                17.23,
                17.23,
                17.23,
                17.229,
                17.229,
                17.229,
                17.228,
                17.228,
                17.228,
                17.227,
                17.227,
                17.226,
                17.226,
                17.226,
                17.226,
                17.225,
                17.225,
                17.225,
                17.224,
                17.224,
                17.224,
                17.223,
                17.223,
                17.223,
                17.223,
                17.222,
                17.222,
                17.222,
                17.222,
                17.221,
                17.221,
                17.221,
                17.22,
                17.22,
                17.22,
                17.22,
                17.22,
                17.219,
                17.219,
                17.219,
                17.218,
                17.218,
                17.218,
                17.218,
                17.218,
                17.217,
                17.217,
                17.217,
                17.217,
                17.217,
                17.216,
                17.216,
                17.216,
                17.216,
                17.215,
                17.215,
                17.215,
                17.215,
                17.215,
                17.214,
                17.214,
                17.214,
                17.214,
                17.214,
                17.214,
                17.213,
                17.213,
                17.213,
                17.213,
                17.213,
                17.213,
                17.213,
                17.212,
                17.212,
                17.212,
                17.212,
                17.212,
                17.212,
                17.212,
                17.211,
                17.211,
                17.211,
                17.211,
                17.211,
                17.211,
                17.211,
                17.211,
                17.21,
                17.21,
                17.21,
                17.21,
                17.21,
                17.21,
                17.21,
                17.21,
                17.21,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.208,
                17.208,
                17.209,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.207,
                17.208,
                17.208,
                17.208,
                17.207,
                17.207,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.208,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.209,
                17.21,
                17.21,
                17.21,
                17.21,
                17.21,
                17.21,
                17.21,
                17.21,
                17.21,
                17.21,
                17.21,
                17.21,
                17.211,
                17.211,
                17.211,
                17.211,
                17.211,
                17.211,
                17.211,
                17.211,
                17.211,
                17.211,
                17.212,
                17.212,
                17.212,
                17.212,
                17.212,
                17.212,
                17.212,
                17.212,
                17.212,
                17.213,
                17.213,
                17.213,
                17.213,
                17.213,
                17.213,
                17.213,
                17.214,
                17.214,
                17.214,
                17.214,
                17.214,
                17.214,
                17.214,
                17.214,
                17.214,
                17.215,
                17.215,
                17.215,
                17.215,
                17.215,
                17.215,
                17.215,
                17.216,
                17.216,
                17.216,
                17.216,
                17.216,
                17.216,
                17.216,
                17.217,
                17.217,
                17.217,
                17.217,
                17.217,
                17.217,
                17.217,
                17.218,
                17.218,
                17.218,
                17.218,
                17.218,
                17.218,
                17.219,
                17.219,
                17.219,
                17.219,
                17.219,
                17.219,
                17.22,
                17.22,
                17.22,
                17.22,
                17.22,
                17.22,
                17.221,
                17.221,
                17.221,
                17.221,
                17.221,
                17.222,
                17.222,
                17.222,
                17.222,
                17.222,
                17.223,
                17.223,
                17.223,
                17.223,
                17.223,
                17.223,
                17.224,
                17.224,
                17.224,
                17.224,
                17.224,
                17.225,
                17.225,
                17.225,
                17.225,
                17.226,
                17.226,
                17.226,
                17.226,
                17.226,
                17.226,
                17.227,
                17.227,
                17.227,
                17.227,
                17.228,
                17.228,
                17.228,
                17.228,
                17.229,
                17.229,
                17.229,
                17.229,
                17.23,
                17.23,
                17.23,
                17.23,
                17.23,
                17.231,
                17.231,
                17.231,
                17.232,
                17.232,
                17.232,
                17.232,
                17.233,
                17.233,
                17.233,
                17.233,
                17.233,
                17.234,
                17.234,
                17.234,
                17.235,
                17.235,
                17.235,
                17.235,
                17.236,
                17.236,
                17.236,
                17.237,
                17.237,
                17.237,
                17.237,
                17.238,
                17.238,
                17.238,
                17.238,
                17.239,
                17.239,
                17.239,
                17.24,
                17.24,
                17.24,
                17.241,
                17.241,
                17.241,
                17.242,
                17.242,
                17.242,
                17.243,
                17.243,
                17.243,
                17.243,
                17.244,
                17.244,
                17.244,
                17.245,
                17.245,
                17.245,
                17.246,
                17.246,
                17.246,
                17.247,
                17.247,
                17.247,
                17.248,
                17.248,
                17.248,
                17.249,
                17.249,
                17.249,
                17.25,
                17.25,
                17.25,
                17.251,
                17.251,
                17.251,
                17.252,
                17.252,
                17.253,
                17.253,
                17.253,
                17.253,
                17.254,
                17.254,
                17.255,
                17.255,
                17.255,
                17.256,
                17.256,
                17.257,
                17.257,
                17.257,
                17.258,
                17.258,
                17.259,
                17.259,
                17.259,
                17.26,
                17.26,
                17.26,
                17.261,
                17.261,
                17.262,
                17.262,
                17.262,
                17.263,
                17.263,
                17.264,
                17.264,
                17.264,
                17.265,
                17.265,
                17.266,
                17.266,
                17.267,
                17.267,
                17.267,
                17.268,
                17.268,
                17.269,
                17.269,
                17.269,
                17.27,
                17.27,
                17.271,
                17.271,
                17.272,
                17.272,
                17.272,
                17.273,
                17.273,
                17.274,
                17.274,
                17.275,
                17.275,
                17.276,
                17.276,
                17.276,
                17.277,
                17.277,
                17.278,
                17.278,
                17.279,
                17.279,
                17.28,
                17.28,
                17.281,
                17.281,
                17.282,
                17.282,
                17.282,
                17.283,
                17.283,
                17.284,
                17.284,
                17.285,
                17.285,
                17.286,
                17.286,
                17.286,
                17.287,
                17.288,
                17.288,
                17.289,
                17.289,
                17.289,
                17.29,
                17.29,
                17.291,
                17.291,
                17.292,
                17.292,
                17.293,
                17.293,
                17.294,
                17.294,
                17.295,
                17.295,
                17.296,
                17.296,
                17.297,
                17.297,
                17.298,
                17.298,
                17.299,
                17.299,
                17.3,
                17.3,
                17.3,
                17.301,
                17.302,
                17.302,
                17.302,
                17.303,
                17.304,
                17.304,
                17.304,
                17.305,
                17.305,
                17.306,
                17.307,
                17.307,
                17.307,
                17.308,
                17.309,
                17.309,
                17.309,
                17.31,
                17.311,
                17.311,
                17.311,
                17.312,
                17.313,
                17.313,
                17.313,
                17.314,
                17.315,
                17.315,
                17.316,
                17.316,
                17.316,
                17.317,
                17.318,
                17.318,
                17.319,
                17.319,
                17.32,
                17.32,
                17.321,
                17.321,
                17.322,
                17.322,
                17.323,
                17.323,
                17.324,
                17.324,
                17.325,
                17.325,
                17.326,
                17.326,
                17.327,
                17.327,
                17.328,
                17.328,
                17.329,
                17.329,
                17.33,
                17.33,
                17.331,
                17.331,
                17.332,
                17.332,
                17.333,
                17.334,
                17.334,
                17.334,
                17.335,
                17.335,
                17.336,
                17.336,
                17.337,
                17.337,
                17.338,
                17.339,
                17.339,
                17.34,
                17.34,
                17.341,
                17.341,
                17.342,
                17.342,
                17.343,
                17.343,
                17.344,
                17.344,
                17.345,
                17.345,
                17.346,
                17.346,
                17.347,
                17.347,
                17.348,
                17.348,
                17.349,
                17.349,
                17.35,
                17.35,
                17.351,
                17.351,
                17.352,
                17.352,
                17.353,
                17.353,
                17.354,
                17.354,
                17.355,
                17.355,
                17.356,
                17.356,
                17.357,
                17.357,
                17.358,
                17.358,
                17.359,
                17.359,
                17.36,
                17.36,
                17.361,
                17.361,
                17.362,
                17.362,
                17.363,
                17.363,
                17.364,
                17.364,
                17.365,
                17.365,
                17.366,
                17.366,
                17.367,
                17.367,
                17.368,
                17.368,
                17.369,
                17.369,
                17.37,
                17.37,
                17.371,
                17.371,
                17.372,
                17.372,
                17.373,
                17.373,
                17.374,
                17.374,
                17.375,
                17.375,
                17.376,
                17.376,
                17.377,
                17.377,
                17.378,
                17.378,
                17.379,
                17.379,
                17.38,
                17.38,
                17.381,
                17.381,
                17.381,
                17.382,
                17.383,
                17.383,
                17.384,
                17.384,
                17.384,
                17.385,
                17.385,
                17.386,
                17.387,
                17.387,
                17.387,
                17.388,
                17.389,
                17.389,
                17.389,
                17.39,
                17.39,
                17.391,
                17.391,
                17.392,
                17.392,
                17.393,
                17.393,
                17.394,
                17.394,
                17.395,
                17.395,
                17.396,
                17.396,
                17.397,
                17.397,
                17.398,
                17.398,
                17.399,
                17.399,
                17.4,
                17.4,
                17.401,
                17.401,
                17.402,
                17.402,
                17.403,
                17.403,
                17.403,
                17.404,
                17.405,
                17.405,
                17.405,
                17.406,
                17.407,
                17.407,
                17.407,
                17.408,
                17.409,
                17.409,
                17.409,
                17.41,
                17.41,
                17.411,
                17.411,
                17.412,
                17.412
            ],
            P95: [
                15.545,
                15.529,
                15.512,
                15.495,
                15.477,
                15.459,
                15.442,
                15.423,
                15.461,
                15.5,
                15.538,
                15.576,
                15.614,
                15.652,
                15.69,
                15.767,
                15.848,
                15.931,
                16.015,
                16.099,
                16.183,
                16.266,
                16.348,
                16.428,
                16.508,
                16.585,
                16.661,
                16.735,
                16.808,
                16.879,
                16.948,
                17.015,
                17.08,
                17.143,
                17.204,
                17.263,
                17.321,
                17.377,
                17.431,
                17.484,
                17.535,
                17.585,
                17.634,
                17.682,
                17.728,
                17.774,
                17.818,
                17.86,
                17.902,
                17.943,
                17.982,
                18.021,
                18.059,
                18.096,
                18.132,
                18.167,
                18.201,
                18.235,
                18.268,
                18.3,
                18.331,
                18.362,
                18.392,
                18.422,
                18.45,
                18.479,
                18.506,
                18.533,
                18.559,
                18.585,
                18.61,
                18.634,
                18.658,
                18.682,
                18.705,
                18.727,
                18.749,
                18.77,
                18.791,
                18.812,
                18.832,
                18.852,
                18.871,
                18.89,
                18.909,
                18.927,
                18.944,
                18.962,
                18.979,
                18.995,
                19.012,
                19.028,
                19.043,
                19.059,
                19.074,
                19.088,
                19.103,
                19.117,
                19.131,
                19.145,
                19.158,
                19.171,
                19.184,
                19.196,
                19.208,
                19.22,
                19.232,
                19.243,
                19.254,
                19.265,
                19.276,
                19.287,
                19.297,
                19.307,
                19.317,
                19.327,
                19.336,
                19.346,
                19.355,
                19.363,
                19.372,
                19.381,
                19.39,
                19.398,
                19.406,
                19.414,
                19.422,
                19.429,
                19.437,
                19.444,
                19.452,
                19.458,
                19.465,
                19.472,
                19.479,
                19.485,
                19.492,
                19.498,
                19.504,
                19.51,
                19.515,
                19.521,
                19.527,
                19.532,
                19.537,
                19.542,
                19.547,
                19.552,
                19.557,
                19.561,
                19.566,
                19.57,
                19.574,
                19.578,
                19.582,
                19.586,
                19.59,
                19.593,
                19.597,
                19.6,
                19.604,
                19.607,
                19.61,
                19.613,
                19.616,
                19.618,
                19.621,
                19.623,
                19.626,
                19.628,
                19.63,
                19.633,
                19.634,
                19.636,
                19.638,
                19.64,
                19.641,
                19.643,
                19.644,
                19.645,
                19.647,
                19.648,
                19.649,
                19.649,
                19.65,
                19.651,
                19.651,
                19.652,
                19.652,
                19.652,
                19.653,
                19.653,
                19.653,
                19.653,
                19.653,
                19.653,
                19.652,
                19.652,
                19.652,
                19.651,
                19.651,
                19.65,
                19.649,
                19.648,
                19.647,
                19.646,
                19.645,
                19.644,
                19.643,
                19.642,
                19.64,
                19.639,
                19.637,
                19.636,
                19.634,
                19.632,
                19.631,
                19.629,
                19.627,
                19.625,
                19.623,
                19.621,
                19.618,
                19.616,
                19.614,
                19.612,
                19.609,
                19.607,
                19.604,
                19.602,
                19.599,
                19.596,
                19.594,
                19.591,
                19.588,
                19.585,
                19.582,
                19.579,
                19.576,
                19.573,
                19.57,
                19.567,
                19.563,
                19.56,
                19.557,
                19.553,
                19.55,
                19.546,
                19.543,
                19.539,
                19.536,
                19.532,
                19.528,
                19.525,
                19.521,
                19.517,
                19.513,
                19.509,
                19.505,
                19.501,
                19.497,
                19.493,
                19.489,
                19.485,
                19.481,
                19.476,
                19.472,
                19.468,
                19.464,
                19.459,
                19.455,
                19.451,
                19.446,
                19.441,
                19.437,
                19.433,
                19.428,
                19.423,
                19.419,
                19.414,
                19.41,
                19.405,
                19.4,
                19.396,
                19.391,
                19.386,
                19.381,
                19.377,
                19.372,
                19.367,
                19.362,
                19.357,
                19.352,
                19.347,
                19.342,
                19.337,
                19.333,
                19.328,
                19.323,
                19.317,
                19.313,
                19.308,
                19.303,
                19.298,
                19.293,
                19.287,
                19.282,
                19.277,
                19.272,
                19.267,
                19.262,
                19.257,
                19.252,
                19.247,
                19.242,
                19.237,
                19.231,
                19.226,
                19.221,
                19.216,
                19.211,
                19.206,
                19.201,
                19.195,
                19.19,
                19.185,
                19.18,
                19.175,
                19.17,
                19.165,
                19.159,
                19.154,
                19.149,
                19.144,
                19.138,
                19.133,
                19.128,
                19.123,
                19.118,
                19.113,
                19.108,
                19.102,
                19.097,
                19.092,
                19.087,
                19.082,
                19.077,
                19.071,
                19.066,
                19.061,
                19.056,
                19.051,
                19.046,
                19.041,
                19.036,
                19.031,
                19.026,
                19.02,
                19.015,
                19.01,
                19.005,
                19,
                18.995,
                18.99,
                18.985,
                18.98,
                18.975,
                18.97,
                18.965,
                18.96,
                18.955,
                18.95,
                18.945,
                18.94,
                18.935,
                18.93,
                18.925,
                18.92,
                18.915,
                18.91,
                18.905,
                18.9,
                18.895,
                18.891,
                18.886,
                18.881,
                18.876,
                18.871,
                18.866,
                18.861,
                18.856,
                18.851,
                18.847,
                18.842,
                18.837,
                18.832,
                18.828,
                18.823,
                18.818,
                18.813,
                18.808,
                18.804,
                18.799,
                18.794,
                18.79,
                18.785,
                18.78,
                18.776,
                18.771,
                18.766,
                18.761,
                18.757,
                18.752,
                18.748,
                18.743,
                18.739,
                18.734,
                18.729,
                18.725,
                18.72,
                18.716,
                18.711,
                18.707,
                18.702,
                18.698,
                18.693,
                18.689,
                18.684,
                18.68,
                18.675,
                18.671,
                18.667,
                18.662,
                18.658,
                18.653,
                18.649,
                18.645,
                18.64,
                18.636,
                18.632,
                18.627,
                18.623,
                18.619,
                18.614,
                18.61,
                18.606,
                18.602,
                18.597,
                18.593,
                18.589,
                18.585,
                18.581,
                18.576,
                18.572,
                18.568,
                18.564,
                18.56,
                18.556,
                18.551,
                18.547,
                18.543,
                18.539,
                18.535,
                18.531,
                18.527,
                18.523,
                18.519,
                18.515,
                18.511,
                18.507,
                18.503,
                18.499,
                18.495,
                18.491,
                18.487,
                18.483,
                18.479,
                18.475,
                18.472,
                18.468,
                18.464,
                18.46,
                18.456,
                18.452,
                18.448,
                18.445,
                18.441,
                18.437,
                18.433,
                18.429,
                18.426,
                18.422,
                18.418,
                18.415,
                18.411,
                18.407,
                18.403,
                18.4,
                18.396,
                18.392,
                18.389,
                18.385,
                18.382,
                18.378,
                18.374,
                18.371,
                18.367,
                18.363,
                18.36,
                18.357,
                18.353,
                18.35,
                18.346,
                18.342,
                18.339,
                18.336,
                18.332,
                18.329,
                18.325,
                18.322,
                18.318,
                18.315,
                18.312,
                18.308,
                18.305,
                18.302,
                18.298,
                18.295,
                18.292,
                18.288,
                18.285,
                18.282,
                18.278,
                18.275,
                18.272,
                18.269,
                18.265,
                18.262,
                18.259,
                18.256,
                18.252,
                18.25,
                18.246,
                18.243,
                18.24,
                18.237,
                18.234,
                18.231,
                18.227,
                18.224,
                18.221,
                18.218,
                18.215,
                18.212,
                18.209,
                18.206,
                18.203,
                18.2,
                18.197,
                18.194,
                18.191,
                18.188,
                18.185,
                18.182,
                18.18,
                18.177,
                18.173,
                18.171,
                18.168,
                18.165,
                18.162,
                18.159,
                18.156,
                18.154,
                18.151,
                18.148,
                18.145,
                18.142,
                18.14,
                18.137,
                18.134,
                18.131,
                18.129,
                18.126,
                18.123,
                18.12,
                18.118,
                18.115,
                18.112,
                18.11,
                18.107,
                18.104,
                18.102,
                18.099,
                18.097,
                18.094,
                18.091,
                18.089,
                18.086,
                18.084,
                18.081,
                18.079,
                18.076,
                18.074,
                18.071,
                18.069,
                18.066,
                18.064,
                18.061,
                18.059,
                18.056,
                18.054,
                18.052,
                18.049,
                18.047,
                18.044,
                18.042,
                18.04,
                18.037,
                18.035,
                18.033,
                18.03,
                18.028,
                18.026,
                18.024,
                18.021,
                18.019,
                18.017,
                18.014,
                18.012,
                18.01,
                18.008,
                18.006,
                18.003,
                18.001,
                17.999,
                17.997,
                17.995,
                17.993,
                17.991,
                17.988,
                17.986,
                17.984,
                17.982,
                17.98,
                17.978,
                17.976,
                17.974,
                17.972,
                17.97,
                17.968,
                17.966,
                17.964,
                17.962,
                17.96,
                17.958,
                17.956,
                17.954,
                17.952,
                17.95,
                17.949,
                17.947,
                17.945,
                17.943,
                17.941,
                17.939,
                17.938,
                17.936,
                17.934,
                17.932,
                17.93,
                17.929,
                17.927,
                17.925,
                17.923,
                17.922,
                17.92,
                17.918,
                17.916,
                17.915,
                17.913,
                17.911,
                17.91,
                17.908,
                17.907,
                17.905,
                17.903,
                17.902,
                17.9,
                17.898,
                17.897,
                17.895,
                17.894,
                17.892,
                17.891,
                17.889,
                17.888,
                17.886,
                17.884,
                17.883,
                17.882,
                17.88,
                17.879,
                17.877,
                17.876,
                17.874,
                17.873,
                17.871,
                17.87,
                17.869,
                17.867,
                17.866,
                17.865,
                17.863,
                17.862,
                17.86,
                17.859,
                17.858,
                17.857,
                17.855,
                17.854,
                17.852,
                17.851,
                17.85,
                17.849,
                17.847,
                17.846,
                17.845,
                17.844,
                17.843,
                17.842,
                18.134,
                18.132,
                18.131,
                18.13,
                18.129,
                18.128,
                18.127,
                18.126,
                18.124,
                18.123,
                18.122,
                18.121,
                18.12,
                18.119,
                18.117,
                18.116,
                18.115,
                18.114,
                18.113,
                18.112,
                18.111,
                18.109,
                18.108,
                18.107,
                18.106,
                18.105,
                18.104,
                18.102,
                18.101,
                18.1,
                18.099,
                18.098,
                18.097,
                18.096,
                18.095,
                18.094,
                18.092,
                18.091,
                18.09,
                18.089,
                18.088,
                18.087,
                18.086,
                18.085,
                18.084,
                18.082,
                18.081,
                18.08,
                18.079,
                18.078,
                18.077,
                18.076,
                18.075,
                18.074,
                18.073,
                18.072,
                18.07,
                18.069,
                18.068,
                18.067,
                18.066,
                18.065,
                18.064,
                18.063,
                18.062,
                18.061,
                18.06,
                18.058,
                18.057,
                18.056,
                18.055,
                18.054,
                18.053,
                18.052,
                18.051,
                18.05,
                18.049,
                18.048,
                18.047,
                18.046,
                18.045,
                18.044,
                18.043,
                18.042,
                18.041,
                18.04,
                18.039,
                18.038,
                18.037,
                18.035,
                18.034,
                18.033,
                18.032,
                18.031,
                18.03,
                18.029,
                18.028,
                18.027,
                18.026,
                18.025,
                18.024,
                18.023,
                18.022,
                18.021,
                18.02,
                18.019,
                18.018,
                18.017,
                18.016,
                18.015,
                18.014,
                18.013,
                18.012,
                18.011,
                18.01,
                18.009,
                18.008,
                18.007,
                18.006,
                18.005,
                18.004,
                18.003,
                18.002,
                18.001,
                18,
                17.999,
                17.998,
                17.997,
                17.996,
                17.995,
                17.994,
                17.993,
                17.992,
                17.991,
                17.99,
                17.989,
                17.988,
                17.987,
                17.986,
                17.985,
                17.984,
                17.983,
                17.982,
                17.981,
                17.98,
                17.979,
                17.978,
                17.978,
                17.977,
                17.976,
                17.975,
                17.974,
                17.973,
                17.972,
                17.971,
                17.97,
                17.969,
                17.968,
                17.967,
                17.966,
                17.965,
                17.964,
                17.963,
                17.962,
                17.961,
                17.96,
                17.959,
                17.959,
                17.958,
                17.957,
                17.956,
                17.955,
                17.954,
                17.953,
                17.952,
                17.951,
                17.95,
                17.95,
                17.949,
                17.948,
                17.947,
                17.946,
                17.945,
                17.944,
                17.943,
                17.942,
                17.941,
                17.94,
                17.939,
                17.938,
                17.938,
                17.937,
                17.936,
                17.935,
                17.934,
                17.933,
                17.932,
                17.931,
                17.93,
                17.93,
                17.929,
                17.928,
                17.927,
                17.926,
                17.925,
                17.924,
                17.924,
                17.923,
                17.922,
                17.921,
                17.92,
                17.919,
                17.919,
                17.918,
                17.917,
                17.916,
                17.915,
                17.914,
                17.913,
                17.913,
                17.912,
                17.911,
                17.91,
                17.909,
                17.908,
                17.908,
                17.907,
                17.906,
                17.905,
                17.904,
                17.903,
                17.903,
                17.902,
                17.901,
                17.9,
                17.899,
                17.899,
                17.898,
                17.897,
                17.896,
                17.895,
                17.895,
                17.894,
                17.893,
                17.892,
                17.892,
                17.891,
                17.89,
                17.889,
                17.889,
                17.888,
                17.887,
                17.886,
                17.886,
                17.885,
                17.884,
                17.883,
                17.883,
                17.882,
                17.881,
                17.88,
                17.88,
                17.879,
                17.878,
                17.877,
                17.877,
                17.876,
                17.875,
                17.875,
                17.874,
                17.873,
                17.872,
                17.872,
                17.871,
                17.87,
                17.87,
                17.869,
                17.868,
                17.868,
                17.867,
                17.866,
                17.866,
                17.865,
                17.864,
                17.863,
                17.863,
                17.862,
                17.862,
                17.861,
                17.86,
                17.86,
                17.859,
                17.859,
                17.858,
                17.857,
                17.856,
                17.856,
                17.855,
                17.855,
                17.854,
                17.854,
                17.853,
                17.852,
                17.852,
                17.851,
                17.851,
                17.85,
                17.849,
                17.849,
                17.848,
                17.848,
                17.847,
                17.846,
                17.846,
                17.846,
                17.845,
                17.844,
                17.844,
                17.843,
                17.843,
                17.842,
                17.842,
                17.841,
                17.841,
                17.84,
                17.84,
                17.839,
                17.839,
                17.838,
                17.838,
                17.837,
                17.837,
                17.836,
                17.836,
                17.835,
                17.835,
                17.834,
                17.834,
                17.833,
                17.833,
                17.832,
                17.832,
                17.832,
                17.831,
                17.831,
                17.83,
                17.83,
                17.829,
                17.829,
                17.828,
                17.828,
                17.828,
                17.827,
                17.827,
                17.826,
                17.826,
                17.826,
                17.825,
                17.825,
                17.825,
                17.824,
                17.824,
                17.823,
                17.823,
                17.823,
                17.822,
                17.822,
                17.822,
                17.821,
                17.821,
                17.821,
                17.82,
                17.82,
                17.82,
                17.819,
                17.819,
                17.819,
                17.819,
                17.818,
                17.818,
                17.817,
                17.817,
                17.817,
                17.816,
                17.816,
                17.816,
                17.816,
                17.815,
                17.815,
                17.815,
                17.815,
                17.814,
                17.814,
                17.814,
                17.814,
                17.814,
                17.813,
                17.813,
                17.813,
                17.813,
                17.812,
                17.812,
                17.812,
                17.812,
                17.811,
                17.811,
                17.811,
                17.811,
                17.811,
                17.81,
                17.81,
                17.81,
                17.81,
                17.81,
                17.81,
                17.809,
                17.809,
                17.809,
                17.809,
                17.809,
                17.809,
                17.808,
                17.808,
                17.808,
                17.808,
                17.808,
                17.808,
                17.808,
                17.807,
                17.808,
                17.807,
                17.807,
                17.807,
                17.807,
                17.807,
                17.807,
                17.807,
                17.807,
                17.807,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.805,
                17.806,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.805,
                17.806,
                17.805,
                17.805,
                17.806,
                17.805,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.806,
                17.807,
                17.806,
                17.807,
                17.807,
                17.807,
                17.807,
                17.807,
                17.807,
                17.807,
                17.808,
                17.807,
                17.807,
                17.808,
                17.808,
                17.808,
                17.808,
                17.808,
                17.808,
                17.808,
                17.809,
                17.808,
                17.809,
                17.809,
                17.809,
                17.809,
                17.809,
                17.809,
                17.809,
                17.81,
                17.81,
                17.81,
                17.81,
                17.81,
                17.81,
                17.81,
                17.811,
                17.811,
                17.811,
                17.811,
                17.811,
                17.811,
                17.812,
                17.812,
                17.812,
                17.812,
                17.812,
                17.812,
                17.812,
                17.813,
                17.813,
                17.813,
                17.813,
                17.813,
                17.814,
                17.814,
                17.814,
                17.814,
                17.814,
                17.814,
                17.815,
                17.815,
                17.815,
                17.815,
                17.815,
                17.816,
                17.816,
                17.816,
                17.816,
                17.817,
                17.817,
                17.817,
                17.817,
                17.817,
                17.817,
                17.818,
                17.818,
                17.818,
                17.818,
                17.819,
                17.819,
                17.819,
                17.819,
                17.82,
                17.82,
                17.82,
                17.82,
                17.821,
                17.821,
                17.821,
                17.821,
                17.822,
                17.822,
                17.822,
                17.822,
                17.822,
                17.823,
                17.823,
                17.823,
                17.823,
                17.824,
                17.824,
                17.824,
                17.824,
                17.825,
                17.825,
                17.825,
                17.825,
                17.826,
                17.826,
                17.826,
                17.827,
                17.827,
                17.827,
                17.827,
                17.828,
                17.828,
                17.828,
                17.828,
                17.829,
                17.829,
                17.829,
                17.83,
                17.83,
                17.83,
                17.83,
                17.83,
                17.831,
                17.831,
                17.831,
                17.832,
                17.832,
                17.832,
                17.832,
                17.833,
                17.833,
                17.833,
                17.834,
                17.834,
                17.834,
                17.835,
                17.835,
                17.835,
                17.835,
                17.836,
                17.836,
                17.836,
                17.837,
                17.837,
                17.837,
                17.837,
                17.838,
                17.838,
                17.838,
                17.839,
                17.839,
                17.839,
                17.84,
                17.84,
                17.84,
                17.841,
                17.841,
                17.841,
                17.842,
                17.842,
                17.842,
                17.843,
                17.843,
                17.843,
                17.843,
                17.844,
                17.844,
                17.845,
                17.845,
                17.845,
                17.846,
                17.846,
                17.846,
                17.847,
                17.847,
                17.847,
                17.848,
                17.848,
                17.848,
                17.849,
                17.849,
                17.849,
                17.85,
                17.85,
                17.85,
                17.851,
                17.851,
                17.852,
                17.852,
                17.852,
                17.853,
                17.853,
                17.853,
                17.854,
                17.854,
                17.854,
                17.855,
                17.855,
                17.855,
                17.856,
                17.856,
                17.857,
                17.857,
                17.858,
                17.858,
                17.858,
                17.859,
                17.859,
                17.86,
                17.86,
                17.86,
                17.86,
                17.861,
                17.861,
                17.862,
                17.862,
                17.863,
                17.863,
                17.863,
                17.864,
                17.864,
                17.865,
                17.865,
                17.866,
                17.866,
                17.866,
                17.867,
                17.867,
                17.867,
                17.868,
                17.868,
                17.869,
                17.869,
                17.87,
                17.87,
                17.871,
                17.871,
                17.872,
                17.872,
                17.872,
                17.873,
                17.873,
                17.874,
                17.874,
                17.875,
                17.875,
                17.875,
                17.876,
                17.876,
                17.877,
                17.877,
                17.878,
                17.878,
                17.879,
                17.879,
                17.88,
                17.88,
                17.881,
                17.881,
                17.881,
                17.882,
                17.882,
                17.883,
                17.883,
                17.884,
                17.884,
                17.885,
                17.885,
                17.886,
                17.887,
                17.887,
                17.887,
                17.888,
                17.889,
                17.889,
                17.889,
                17.89,
                17.89,
                17.891,
                17.891,
                17.892,
                17.892,
                17.893,
                17.893,
                17.894,
                17.895,
                17.895,
                17.896,
                17.896,
                17.896,
                17.897,
                17.898,
                17.898,
                17.899,
                17.899,
                17.9,
                17.9,
                17.901,
                17.901,
                17.902,
                17.903,
                17.903,
                17.904,
                17.904,
                17.904,
                17.905,
                17.906,
                17.906,
                17.907,
                17.907,
                17.908,
                17.909,
                17.909,
                17.91,
                17.91,
                17.911,
                17.912,
                17.912,
                17.912,
                17.913,
                17.914,
                17.914,
                17.915,
                17.915,
                17.916,
                17.917,
                17.917,
                17.918,
                17.918,
                17.919,
                17.919,
                17.92,
                17.921,
                17.921,
                17.922,
                17.922,
                17.923,
                17.924,
                17.924,
                17.925,
                17.925,
                17.926,
                17.927,
                17.927,
                17.928,
                17.928,
                17.929,
                17.93,
                17.93,
                17.931,
                17.931,
                17.932,
                17.933,
                17.933,
                17.934,
                17.935,
                17.935,
                17.936,
                17.936,
                17.937,
                17.938,
                17.938,
                17.939,
                17.94,
                17.94,
                17.941,
                17.941,
                17.942,
                17.943,
                17.943,
                17.944,
                17.945,
                17.945,
                17.946,
                17.947,
                17.947,
                17.948,
                17.949,
                17.949,
                17.95,
                17.95,
                17.951,
                17.952,
                17.953,
                17.953,
                17.953,
                17.954,
                17.955,
                17.956,
                17.956,
                17.957,
                17.957,
                17.958,
                17.959,
                17.96,
                17.96,
                17.961,
                17.961,
                17.962,
                17.963,
                17.963,
                17.964,
                17.965,
                17.965,
                17.966,
                17.967,
                17.967,
                17.968,
                17.969,
                17.969,
                17.97,
                17.971,
                17.971,
                17.972,
                17.972,
                17.973,
                17.974,
                17.974,
                17.975,
                17.976,
                17.977,
                17.977,
                17.978,
                17.978,
                17.979,
                17.98,
                17.98,
                17.981,
                17.982,
                17.982,
                17.983,
                17.984,
                17.984,
                17.985,
                17.986,
                17.986,
                17.987,
                17.988,
                17.988,
                17.989,
                17.99,
                17.99,
                17.991,
                17.992,
                17.992,
                17.993,
                17.994,
                17.994,
                17.995,
                17.996,
                17.996,
                17.997,
                17.998,
                17.998,
                17.999,
                18,
                18,
                18.001,
                18.002,
                18.002,
                18.003,
                18.004,
                18.004,
                18.005,
                18.005,
                18.006,
                18.007,
                18.007,
                18.008,
                18.009,
                18.009,
                18.01,
                18.011,
                18.011,
                18.012,
                18.013,
                18.013,
                18.014,
                18.015,
                18.015,
                18.016,
                18.016,
                18.017,
                18.018,
                18.018,
                18.019,
                18.02,
                18.021,
                18.021,
                18.022,
                18.022,
                18.023,
                18.024,
                18.024,
                18.025,
                18.026,
                18.026,
                18.027,
                18.027,
                18.028,
                18.029,
                18.03,
                18.03,
                18.031,
                18.031,
                18.032,
                18.032,
                18.033,
                18.034,
                18.035,
                18.035,
                18.036,
                18.036,
                18.037,
                18.038,
                18.038,
                18.039,
                18.039,
                18.04,
                18.041,
                18.042,
                18.042,
                18.043,
                18.043,
                18.044,
                18.045,
                18.045,
                18.046,
                18.046,
                18.047,
                18.048,
                18.048,
                18.049,
                18.05,
                18.05,
                18.051,
                18.051,
                18.052,
                18.053,
                18.053,
                18.054,
                18.055,
                18.055,
                18.056,
                18.056,
                18.057,
                18.058,
                18.058,
                18.059,
                18.06,
                18.06,
                18.061,
                18.061,
                18.062,
                18.063,
                18.063,
                18.064,
                18.064,
                18.065,
                18.066,
                18.066,
                18.067,
                18.068,
                18.068,
                18.069,
                18.069,
                18.07,
                18.071,
                18.071,
                18.072,
                18.072,
                18.073,
                18.074,
                18.074,
                18.075,
                18.075,
                18.076,
                18.076,
                18.077,
                18.078,
                18.078,
                18.079,
                18.08,
                18.08,
                18.081,
                18.081,
                18.082,
                18.083,
                18.083,
                18.084,
                18.085,
                18.085,
                18.086,
                18.086,
                18.087,
                18.088,
                18.088,
                18.089,
                18.089,
                18.09,
                18.09,
                18.091,
                18.092,
                18.092,
                18.093,
                18.094,
                18.094,
                18.095,
                18.095,
                18.096,
                18.096,
                18.097,
                18.098,
                18.098,
                18.099,
                18.099,
                18.1,
                18.101,
                18.101,
                18.102,
                18.102
            ],
            P97: [
                15.892,
                15.873,
                15.854,
                15.834,
                15.813,
                15.793,
                15.772,
                15.751,
                15.79,
                15.829,
                15.868,
                15.908,
                15.947,
                15.986,
                16.025,
                16.104,
                16.187,
                16.272,
                16.358,
                16.444,
                16.53,
                16.615,
                16.699,
                16.781,
                16.862,
                16.942,
                17.019,
                17.095,
                17.17,
                17.242,
                17.313,
                17.381,
                17.448,
                17.512,
                17.575,
                17.635,
                17.694,
                17.752,
                17.807,
                17.861,
                17.914,
                17.965,
                18.015,
                18.064,
                18.112,
                18.158,
                18.203,
                18.247,
                18.29,
                18.332,
                18.372,
                18.412,
                18.45,
                18.488,
                18.525,
                18.561,
                18.596,
                18.63,
                18.664,
                18.697,
                18.729,
                18.761,
                18.792,
                18.822,
                18.851,
                18.881,
                18.908,
                18.936,
                18.963,
                18.989,
                19.015,
                19.04,
                19.065,
                19.089,
                19.112,
                19.135,
                19.157,
                19.179,
                19.201,
                19.222,
                19.243,
                19.263,
                19.283,
                19.302,
                19.321,
                19.34,
                19.358,
                19.376,
                19.393,
                19.41,
                19.427,
                19.443,
                19.459,
                19.475,
                19.49,
                19.506,
                19.521,
                19.535,
                19.549,
                19.563,
                19.577,
                19.59,
                19.603,
                19.616,
                19.628,
                19.641,
                19.653,
                19.664,
                19.676,
                19.687,
                19.698,
                19.709,
                19.719,
                19.73,
                19.74,
                19.75,
                19.76,
                19.77,
                19.779,
                19.788,
                19.797,
                19.806,
                19.815,
                19.823,
                19.832,
                19.84,
                19.848,
                19.856,
                19.864,
                19.871,
                19.879,
                19.886,
                19.893,
                19.9,
                19.907,
                19.913,
                19.92,
                19.926,
                19.932,
                19.939,
                19.944,
                19.95,
                19.956,
                19.962,
                19.967,
                19.972,
                19.977,
                19.982,
                19.987,
                19.992,
                19.996,
                20.001,
                20.005,
                20.009,
                20.013,
                20.017,
                20.021,
                20.025,
                20.028,
                20.032,
                20.035,
                20.038,
                20.042,
                20.045,
                20.048,
                20.05,
                20.053,
                20.056,
                20.058,
                20.06,
                20.063,
                20.065,
                20.067,
                20.069,
                20.071,
                20.073,
                20.074,
                20.076,
                20.077,
                20.079,
                20.08,
                20.081,
                20.082,
                20.083,
                20.084,
                20.084,
                20.085,
                20.086,
                20.086,
                20.086,
                20.087,
                20.087,
                20.087,
                20.087,
                20.087,
                20.087,
                20.086,
                20.086,
                20.086,
                20.085,
                20.085,
                20.084,
                20.083,
                20.082,
                20.081,
                20.08,
                20.079,
                20.078,
                20.077,
                20.076,
                20.074,
                20.073,
                20.071,
                20.07,
                20.068,
                20.066,
                20.065,
                20.063,
                20.06,
                20.059,
                20.056,
                20.055,
                20.052,
                20.05,
                20.048,
                20.046,
                20.043,
                20.041,
                20.038,
                20.035,
                20.033,
                20.03,
                20.027,
                20.024,
                20.021,
                20.019,
                20.015,
                20.012,
                20.01,
                20.006,
                20.003,
                20,
                19.996,
                19.993,
                19.99,
                19.986,
                19.983,
                19.979,
                19.975,
                19.972,
                19.968,
                19.964,
                19.961,
                19.957,
                19.953,
                19.949,
                19.945,
                19.941,
                19.937,
                19.933,
                19.929,
                19.925,
                19.921,
                19.917,
                19.912,
                19.908,
                19.904,
                19.899,
                19.895,
                19.891,
                19.886,
                19.882,
                19.877,
                19.873,
                19.868,
                19.864,
                19.859,
                19.854,
                19.85,
                19.845,
                19.84,
                19.835,
                19.831,
                19.826,
                19.821,
                19.816,
                19.811,
                19.806,
                19.802,
                19.796,
                19.792,
                19.787,
                19.782,
                19.776,
                19.772,
                19.767,
                19.762,
                19.757,
                19.752,
                19.746,
                19.741,
                19.736,
                19.731,
                19.726,
                19.721,
                19.716,
                19.71,
                19.705,
                19.7,
                19.695,
                19.69,
                19.685,
                19.679,
                19.674,
                19.669,
                19.664,
                19.659,
                19.653,
                19.648,
                19.643,
                19.637,
                19.632,
                19.627,
                19.622,
                19.617,
                19.611,
                19.606,
                19.601,
                19.596,
                19.59,
                19.585,
                19.58,
                19.574,
                19.569,
                19.564,
                19.559,
                19.553,
                19.548,
                19.543,
                19.538,
                19.532,
                19.527,
                19.522,
                19.517,
                19.511,
                19.506,
                19.501,
                19.496,
                19.49,
                19.485,
                19.48,
                19.475,
                19.47,
                19.464,
                19.459,
                19.454,
                19.449,
                19.444,
                19.438,
                19.433,
                19.428,
                19.423,
                19.418,
                19.413,
                19.407,
                19.402,
                19.397,
                19.392,
                19.387,
                19.382,
                19.377,
                19.372,
                19.366,
                19.361,
                19.356,
                19.351,
                19.346,
                19.341,
                19.336,
                19.331,
                19.326,
                19.321,
                19.316,
                19.311,
                19.306,
                19.301,
                19.296,
                19.291,
                19.286,
                19.281,
                19.276,
                19.271,
                19.266,
                19.261,
                19.256,
                19.252,
                19.247,
                19.242,
                19.237,
                19.232,
                19.227,
                19.223,
                19.218,
                19.213,
                19.208,
                19.203,
                19.199,
                19.194,
                19.189,
                19.184,
                19.179,
                19.175,
                19.17,
                19.166,
                19.161,
                19.156,
                19.151,
                19.147,
                19.142,
                19.137,
                19.133,
                19.128,
                19.124,
                19.119,
                19.114,
                19.11,
                19.105,
                19.101,
                19.096,
                19.092,
                19.087,
                19.083,
                19.078,
                19.074,
                19.069,
                19.065,
                19.06,
                19.056,
                19.051,
                19.047,
                19.043,
                19.038,
                19.034,
                19.029,
                19.025,
                19.021,
                19.016,
                19.012,
                19.008,
                19.003,
                18.999,
                18.995,
                18.991,
                18.986,
                18.982,
                18.978,
                18.974,
                18.969,
                18.965,
                18.961,
                18.957,
                18.953,
                18.949,
                18.944,
                18.94,
                18.936,
                18.932,
                18.928,
                18.924,
                18.92,
                18.916,
                18.912,
                18.907,
                18.904,
                18.9,
                18.896,
                18.891,
                18.888,
                18.884,
                18.88,
                18.876,
                18.872,
                18.868,
                18.864,
                18.86,
                18.856,
                18.852,
                18.848,
                18.844,
                18.841,
                18.837,
                18.833,
                18.829,
                18.826,
                18.822,
                18.818,
                18.814,
                18.811,
                18.807,
                18.803,
                18.799,
                18.796,
                18.792,
                18.788,
                18.785,
                18.781,
                18.777,
                18.773,
                18.77,
                18.766,
                18.763,
                18.759,
                18.756,
                18.752,
                18.748,
                18.745,
                18.742,
                18.738,
                18.735,
                18.731,
                18.727,
                18.724,
                18.721,
                18.717,
                18.714,
                18.71,
                18.707,
                18.703,
                18.7,
                18.697,
                18.693,
                18.69,
                18.687,
                18.683,
                18.68,
                18.677,
                18.673,
                18.67,
                18.667,
                18.664,
                18.66,
                18.657,
                18.654,
                18.651,
                18.648,
                18.644,
                18.641,
                18.638,
                18.635,
                18.631,
                18.629,
                18.625,
                18.622,
                18.619,
                18.616,
                18.613,
                18.61,
                18.607,
                18.604,
                18.601,
                18.598,
                18.595,
                18.592,
                18.589,
                18.586,
                18.583,
                18.58,
                18.577,
                18.574,
                18.571,
                18.568,
                18.565,
                18.562,
                18.559,
                18.556,
                18.553,
                18.551,
                18.548,
                18.545,
                18.542,
                18.539,
                18.537,
                18.534,
                18.531,
                18.528,
                18.526,
                18.523,
                18.52,
                18.517,
                18.515,
                18.512,
                18.509,
                18.507,
                18.504,
                18.501,
                18.499,
                18.496,
                18.493,
                18.491,
                18.488,
                18.486,
                18.483,
                18.481,
                18.478,
                18.475,
                18.473,
                18.47,
                18.468,
                18.465,
                18.463,
                18.46,
                18.458,
                18.455,
                18.453,
                18.45,
                18.448,
                18.445,
                18.443,
                18.441,
                18.438,
                18.436,
                18.434,
                18.431,
                18.429,
                18.427,
                18.424,
                18.422,
                18.42,
                18.417,
                18.415,
                18.413,
                18.411,
                18.409,
                18.406,
                18.404,
                18.402,
                18.4,
                18.397,
                18.395,
                18.393,
                18.391,
                18.389,
                18.387,
                18.384,
                18.382,
                18.38,
                18.378,
                18.376,
                18.374,
                18.372,
                18.37,
                18.368,
                18.366,
                18.364,
                18.362,
                18.36,
                18.358,
                18.356,
                18.354,
                18.352,
                18.35,
                18.348,
                18.347,
                18.345,
                18.343,
                18.341,
                18.339,
                18.337,
                18.335,
                18.334,
                18.332,
                18.33,
                18.328,
                18.326,
                18.325,
                18.323,
                18.321,
                18.319,
                18.318,
                18.316,
                18.314,
                18.312,
                18.311,
                18.309,
                18.308,
                18.306,
                18.304,
                18.302,
                18.301,
                18.299,
                18.297,
                18.296,
                18.294,
                18.293,
                18.291,
                18.29,
                18.288,
                18.287,
                18.285,
                18.284,
                18.282,
                18.281,
                18.279,
                18.278,
                18.276,
                18.275,
                18.273,
                18.272,
                18.27,
                18.269,
                18.268,
                18.266,
                18.265,
                18.263,
                18.262,
                18.261,
                18.259,
                18.258,
                18.257,
                18.255,
                18.254,
                18.252,
                18.251,
                18.25,
                18.249,
                18.247,
                18.246,
                18.245,
                18.244,
                18.242,
                18.241,
                18.533,
                18.532,
                18.531,
                18.529,
                18.528,
                18.527,
                18.526,
                18.525,
                18.524,
                18.523,
                18.521,
                18.52,
                18.519,
                18.518,
                18.516,
                18.515,
                18.514,
                18.513,
                18.512,
                18.511,
                18.509,
                18.508,
                18.507,
                18.506,
                18.505,
                18.504,
                18.502,
                18.501,
                18.5,
                18.499,
                18.498,
                18.497,
                18.496,
                18.494,
                18.493,
                18.492,
                18.491,
                18.49,
                18.489,
                18.487,
                18.486,
                18.485,
                18.484,
                18.483,
                18.482,
                18.481,
                18.48,
                18.478,
                18.477,
                18.476,
                18.475,
                18.474,
                18.473,
                18.472,
                18.471,
                18.469,
                18.468,
                18.467,
                18.466,
                18.465,
                18.464,
                18.463,
                18.462,
                18.461,
                18.46,
                18.458,
                18.457,
                18.456,
                18.455,
                18.454,
                18.453,
                18.452,
                18.451,
                18.45,
                18.449,
                18.447,
                18.447,
                18.445,
                18.444,
                18.443,
                18.442,
                18.441,
                18.44,
                18.439,
                18.438,
                18.437,
                18.436,
                18.435,
                18.434,
                18.432,
                18.431,
                18.43,
                18.429,
                18.428,
                18.427,
                18.426,
                18.425,
                18.424,
                18.423,
                18.422,
                18.421,
                18.419,
                18.419,
                18.417,
                18.416,
                18.415,
                18.414,
                18.413,
                18.412,
                18.411,
                18.41,
                18.409,
                18.408,
                18.407,
                18.406,
                18.405,
                18.404,
                18.403,
                18.402,
                18.401,
                18.4,
                18.399,
                18.398,
                18.397,
                18.396,
                18.395,
                18.394,
                18.393,
                18.392,
                18.391,
                18.39,
                18.389,
                18.388,
                18.387,
                18.386,
                18.385,
                18.384,
                18.383,
                18.382,
                18.381,
                18.38,
                18.379,
                18.378,
                18.377,
                18.376,
                18.375,
                18.374,
                18.373,
                18.372,
                18.371,
                18.37,
                18.369,
                18.368,
                18.367,
                18.366,
                18.365,
                18.364,
                18.363,
                18.362,
                18.361,
                18.36,
                18.359,
                18.359,
                18.358,
                18.357,
                18.356,
                18.355,
                18.354,
                18.353,
                18.352,
                18.351,
                18.35,
                18.349,
                18.348,
                18.347,
                18.346,
                18.345,
                18.344,
                18.344,
                18.343,
                18.342,
                18.341,
                18.34,
                18.339,
                18.338,
                18.337,
                18.336,
                18.335,
                18.334,
                18.333,
                18.333,
                18.332,
                18.331,
                18.33,
                18.329,
                18.328,
                18.327,
                18.326,
                18.325,
                18.324,
                18.323,
                18.323,
                18.322,
                18.321,
                18.32,
                18.319,
                18.318,
                18.317,
                18.316,
                18.315,
                18.315,
                18.314,
                18.313,
                18.312,
                18.311,
                18.31,
                18.309,
                18.308,
                18.308,
                18.307,
                18.306,
                18.305,
                18.305,
                18.304,
                18.303,
                18.302,
                18.301,
                18.3,
                18.299,
                18.299,
                18.298,
                18.297,
                18.296,
                18.295,
                18.294,
                18.294,
                18.293,
                18.292,
                18.291,
                18.291,
                18.29,
                18.289,
                18.288,
                18.287,
                18.287,
                18.286,
                18.285,
                18.284,
                18.284,
                18.283,
                18.282,
                18.281,
                18.28,
                18.28,
                18.279,
                18.278,
                18.278,
                18.277,
                18.276,
                18.275,
                18.274,
                18.274,
                18.273,
                18.272,
                18.272,
                18.271,
                18.27,
                18.27,
                18.269,
                18.268,
                18.267,
                18.267,
                18.266,
                18.266,
                18.265,
                18.264,
                18.263,
                18.263,
                18.262,
                18.261,
                18.261,
                18.26,
                18.259,
                18.259,
                18.258,
                18.258,
                18.257,
                18.256,
                18.255,
                18.255,
                18.254,
                18.254,
                18.253,
                18.252,
                18.252,
                18.251,
                18.251,
                18.25,
                18.249,
                18.249,
                18.248,
                18.248,
                18.247,
                18.246,
                18.246,
                18.246,
                18.245,
                18.244,
                18.244,
                18.243,
                18.243,
                18.242,
                18.241,
                18.241,
                18.241,
                18.24,
                18.239,
                18.239,
                18.239,
                18.238,
                18.237,
                18.237,
                18.237,
                18.236,
                18.235,
                18.235,
                18.235,
                18.234,
                18.233,
                18.233,
                18.233,
                18.232,
                18.232,
                18.231,
                18.231,
                18.23,
                18.23,
                18.23,
                18.229,
                18.229,
                18.228,
                18.228,
                18.227,
                18.227,
                18.227,
                18.226,
                18.226,
                18.225,
                18.225,
                18.225,
                18.224,
                18.224,
                18.223,
                18.223,
                18.223,
                18.222,
                18.222,
                18.222,
                18.221,
                18.221,
                18.221,
                18.22,
                18.22,
                18.22,
                18.22,
                18.219,
                18.219,
                18.219,
                18.218,
                18.218,
                18.218,
                18.217,
                18.217,
                18.217,
                18.216,
                18.216,
                18.216,
                18.216,
                18.216,
                18.215,
                18.215,
                18.215,
                18.214,
                18.214,
                18.214,
                18.214,
                18.214,
                18.213,
                18.213,
                18.213,
                18.213,
                18.213,
                18.212,
                18.212,
                18.212,
                18.212,
                18.212,
                18.211,
                18.211,
                18.211,
                18.211,
                18.211,
                18.21,
                18.211,
                18.21,
                18.21,
                18.21,
                18.21,
                18.21,
                18.21,
                18.21,
                18.209,
                18.209,
                18.209,
                18.209,
                18.209,
                18.209,
                18.209,
                18.209,
                18.209,
                18.209,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.207,
                18.208,
                18.207,
                18.208,
                18.207,
                18.208,
                18.207,
                18.208,
                18.207,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.208,
                18.209,
                18.209,
                18.209,
                18.209,
                18.209,
                18.209,
                18.209,
                18.209,
                18.209,
                18.209,
                18.209,
                18.21,
                18.21,
                18.21,
                18.21,
                18.21,
                18.21,
                18.21,
                18.21,
                18.21,
                18.211,
                18.211,
                18.211,
                18.211,
                18.211,
                18.211,
                18.212,
                18.212,
                18.212,
                18.212,
                18.212,
                18.212,
                18.212,
                18.213,
                18.213,
                18.213,
                18.213,
                18.213,
                18.214,
                18.214,
                18.214,
                18.214,
                18.214,
                18.215,
                18.215,
                18.215,
                18.215,
                18.215,
                18.215,
                18.215,
                18.216,
                18.216,
                18.216,
                18.217,
                18.217,
                18.217,
                18.217,
                18.217,
                18.218,
                18.218,
                18.218,
                18.218,
                18.218,
                18.219,
                18.219,
                18.219,
                18.22,
                18.22,
                18.22,
                18.22,
                18.22,
                18.221,
                18.221,
                18.221,
                18.221,
                18.222,
                18.222,
                18.222,
                18.222,
                18.223,
                18.223,
                18.223,
                18.223,
                18.224,
                18.224,
                18.224,
                18.225,
                18.225,
                18.225,
                18.226,
                18.226,
                18.226,
                18.226,
                18.227,
                18.227,
                18.227,
                18.227,
                18.228,
                18.228,
                18.229,
                18.229,
                18.229,
                18.23,
                18.23,
                18.23,
                18.23,
                18.23,
                18.231,
                18.231,
                18.231,
                18.232,
                18.232,
                18.233,
                18.233,
                18.233,
                18.234,
                18.234,
                18.234,
                18.235,
                18.235,
                18.235,
                18.236,
                18.236,
                18.236,
                18.237,
                18.237,
                18.237,
                18.237,
                18.238,
                18.238,
                18.239,
                18.239,
                18.239,
                18.24,
                18.24,
                18.24,
                18.241,
                18.241,
                18.242,
                18.242,
                18.242,
                18.243,
                18.243,
                18.243,
                18.244,
                18.244,
                18.245,
                18.245,
                18.245,
                18.246,
                18.246,
                18.246,
                18.247,
                18.247,
                18.248,
                18.248,
                18.248,
                18.249,
                18.249,
                18.249,
                18.25,
                18.25,
                18.251,
                18.251,
                18.251,
                18.252,
                18.252,
                18.253,
                18.253,
                18.253,
                18.254,
                18.254,
                18.255,
                18.255,
                18.255,
                18.256,
                18.256,
                18.257,
                18.257,
                18.258,
                18.258,
                18.258,
                18.259,
                18.259,
                18.259,
                18.26,
                18.26,
                18.261,
                18.261,
                18.262,
                18.262,
                18.262,
                18.263,
                18.263,
                18.264,
                18.264,
                18.265,
                18.265,
                18.266,
                18.266,
                18.266,
                18.267,
                18.267,
                18.267,
                18.268,
                18.268,
                18.269,
                18.269,
                18.27,
                18.27,
                18.271,
                18.271,
                18.272,
                18.272,
                18.273,
                18.273,
                18.274,
                18.274,
                18.275,
                18.275,
                18.275,
                18.276,
                18.276,
                18.277,
                18.277,
                18.278,
                18.278,
                18.279,
                18.279,
                18.28,
                18.28,
                18.281,
                18.281,
                18.281,
                18.282,
                18.282,
                18.283,
                18.283,
                18.284,
                18.284,
                18.285,
                18.285,
                18.286,
                18.286,
                18.287,
                18.287,
                18.288,
                18.288,
                18.289,
                18.289,
                18.29,
                18.29,
                18.291,
                18.291,
                18.292,
                18.292,
                18.293,
                18.293,
                18.294,
                18.294,
                18.295,
                18.296,
                18.296,
                18.297,
                18.297,
                18.297,
                18.298,
                18.298,
                18.299,
                18.3,
                18.3,
                18.301,
                18.301,
                18.302,
                18.303,
                18.303,
                18.304,
                18.304,
                18.305,
                18.305,
                18.306,
                18.306,
                18.307,
                18.307,
                18.308,
                18.308,
                18.309,
                18.31,
                18.31,
                18.311,
                18.311,
                18.312,
                18.313,
                18.313,
                18.314,
                18.314,
                18.315,
                18.315,
                18.316,
                18.317,
                18.317,
                18.318,
                18.318,
                18.319,
                18.32,
                18.32,
                18.321,
                18.322,
                18.322,
                18.323,
                18.323,
                18.324,
                18.325,
                18.325,
                18.326,
                18.326,
                18.327,
                18.327,
                18.328,
                18.329,
                18.329,
                18.33,
                18.331,
                18.331,
                18.332,
                18.333,
                18.333,
                18.334,
                18.334,
                18.335,
                18.336,
                18.336,
                18.337,
                18.338,
                18.338,
                18.339,
                18.34,
                18.34,
                18.341,
                18.342,
                18.342,
                18.343,
                18.344,
                18.344,
                18.345,
                18.346,
                18.346,
                18.347,
                18.348,
                18.348,
                18.349,
                18.35,
                18.351,
                18.351,
                18.352,
                18.353,
                18.353,
                18.354,
                18.354,
                18.355,
                18.356,
                18.357,
                18.357,
                18.358,
                18.359,
                18.36,
                18.36,
                18.361,
                18.361,
                18.362,
                18.363,
                18.364,
                18.364,
                18.365,
                18.366,
                18.366,
                18.367,
                18.368,
                18.368,
                18.369,
                18.37,
                18.371,
                18.371,
                18.372,
                18.373,
                18.374,
                18.374,
                18.375,
                18.376,
                18.376,
                18.377,
                18.378,
                18.379,
                18.38,
                18.38,
                18.381,
                18.381,
                18.382,
                18.383,
                18.384,
                18.385,
                18.385,
                18.386,
                18.387,
                18.387,
                18.388,
                18.389,
                18.39,
                18.391,
                18.391,
                18.392,
                18.393,
                18.393,
                18.394,
                18.395,
                18.396,
                18.397,
                18.397,
                18.398,
                18.399,
                18.4,
                18.4,
                18.401,
                18.402,
                18.402,
                18.403,
                18.404,
                18.405,
                18.405,
                18.406,
                18.407,
                18.408,
                18.409,
                18.41,
                18.41,
                18.411,
                18.412,
                18.413,
                18.413,
                18.414,
                18.415,
                18.416,
                18.416,
                18.417,
                18.418,
                18.419,
                18.419,
                18.42,
                18.421,
                18.422,
                18.422,
                18.423,
                18.424,
                18.425,
                18.426,
                18.426,
                18.427,
                18.428,
                18.429,
                18.429,
                18.43,
                18.431,
                18.432,
                18.433,
                18.433,
                18.434,
                18.435,
                18.435,
                18.436,
                18.437,
                18.438,
                18.438,
                18.439,
                18.44,
                18.441,
                18.442,
                18.442,
                18.443,
                18.444,
                18.445,
                18.446,
                18.446,
                18.447,
                18.448,
                18.448,
                18.449,
                18.45,
                18.451,
                18.452,
                18.452,
                18.453,
                18.454,
                18.455,
                18.455,
                18.456,
                18.457,
                18.458,
                18.459,
                18.459,
                18.46,
                18.461,
                18.461,
                18.462,
                18.463,
                18.464,
                18.465,
                18.465,
                18.466,
                18.467,
                18.467,
                18.468,
                18.469,
                18.47,
                18.47,
                18.471,
                18.472,
                18.473,
                18.474,
                18.474,
                18.475,
                18.476,
                18.477,
                18.477,
                18.478,
                18.479,
                18.479,
                18.48,
                18.481,
                18.482,
                18.482,
                18.483,
                18.484,
                18.485,
                18.485,
                18.486,
                18.487,
                18.488,
                18.488,
                18.489,
                18.49,
                18.491,
                18.491,
                18.492,
                18.493,
                18.494,
                18.494,
                18.495,
                18.496,
                18.497,
                18.497,
                18.498,
                18.499,
                18.499,
                18.5,
                18.501,
                18.502,
                18.502,
                18.503,
                18.504,
                18.505,
                18.505,
                18.506,
                18.507,
                18.507,
                18.508,
                18.509,
                18.51,
                18.51,
                18.511,
                18.512,
                18.512,
                18.513,
                18.514,
                18.514,
                18.515,
                18.516,
                18.517,
                18.517,
                18.518,
                18.519,
                18.519,
                18.52,
                18.521,
                18.522,
                18.522,
                18.523,
                18.524,
                18.524,
                18.525,
                18.526,
                18.527,
                18.527,
                18.528,
                18.529,
                18.529,
                18.53,
                18.531,
                18.531,
                18.532,
                18.533,
                18.534,
                18.534,
                18.535,
                18.536,
                18.536,
                18.537,
                18.538,
                18.538,
                18.539,
                18.54,
                18.541,
                18.541,
                18.542,
                18.543,
                18.543,
                18.544,
                18.544,
                18.545,
                18.546,
                18.547,
                18.547,
                18.548,
                18.549,
                18.549,
                18.55,
                18.551,
                18.552,
                18.552,
                18.553,
                18.554,
                18.554,
                18.555,
                18.556,
                18.556,
                18.557,
                18.558,
                18.558,
                18.559,
                18.56,
                18.56,
                18.561,
                18.562,
                18.563,
                18.563,
                18.564,
                18.564,
                18.565,
                18.566,
                18.567,
                18.567,
                18.568,
                18.569,
                18.569,
                18.57,
                18.571,
                18.571,
                18.572,
                18.573,
                18.573,
                18.574
            ],
            P99: [
                16.571,
                16.544,
                16.517,
                16.489,
                16.461,
                16.433,
                16.405,
                16.376,
                16.418,
                16.46,
                16.501,
                16.543,
                16.585,
                16.626,
                16.668,
                16.75,
                16.837,
                16.926,
                17.016,
                17.106,
                17.196,
                17.285,
                17.373,
                17.459,
                17.544,
                17.627,
                17.709,
                17.788,
                17.866,
                17.942,
                18.016,
                18.088,
                18.157,
                18.225,
                18.29,
                18.354,
                18.416,
                18.476,
                18.534,
                18.59,
                18.645,
                18.699,
                18.752,
                18.803,
                18.853,
                18.901,
                18.949,
                18.995,
                19.039,
                19.083,
                19.126,
                19.167,
                19.208,
                19.248,
                19.286,
                19.324,
                19.361,
                19.397,
                19.432,
                19.467,
                19.5,
                19.534,
                19.566,
                19.598,
                19.629,
                19.659,
                19.688,
                19.717,
                19.746,
                19.774,
                19.801,
                19.827,
                19.853,
                19.878,
                19.903,
                19.927,
                19.95,
                19.973,
                19.996,
                20.018,
                20.04,
                20.061,
                20.082,
                20.102,
                20.123,
                20.142,
                20.161,
                20.18,
                20.198,
                20.217,
                20.234,
                20.251,
                20.268,
                20.285,
                20.301,
                20.317,
                20.333,
                20.348,
                20.363,
                20.378,
                20.392,
                20.406,
                20.42,
                20.433,
                20.447,
                20.46,
                20.472,
                20.485,
                20.497,
                20.509,
                20.521,
                20.532,
                20.543,
                20.554,
                20.565,
                20.575,
                20.586,
                20.596,
                20.606,
                20.615,
                20.625,
                20.635,
                20.644,
                20.653,
                20.662,
                20.67,
                20.679,
                20.687,
                20.696,
                20.704,
                20.712,
                20.719,
                20.727,
                20.735,
                20.741,
                20.749,
                20.755,
                20.762,
                20.769,
                20.776,
                20.782,
                20.788,
                20.794,
                20.8,
                20.806,
                20.811,
                20.817,
                20.822,
                20.827,
                20.832,
                20.837,
                20.842,
                20.846,
                20.851,
                20.856,
                20.86,
                20.864,
                20.868,
                20.871,
                20.875,
                20.879,
                20.882,
                20.886,
                20.889,
                20.892,
                20.895,
                20.898,
                20.901,
                20.904,
                20.906,
                20.909,
                20.912,
                20.914,
                20.916,
                20.918,
                20.92,
                20.922,
                20.923,
                20.925,
                20.927,
                20.928,
                20.929,
                20.93,
                20.931,
                20.932,
                20.933,
                20.934,
                20.935,
                20.935,
                20.935,
                20.936,
                20.936,
                20.937,
                20.936,
                20.937,
                20.937,
                20.936,
                20.936,
                20.936,
                20.935,
                20.935,
                20.934,
                20.934,
                20.933,
                20.932,
                20.931,
                20.93,
                20.929,
                20.928,
                20.927,
                20.925,
                20.924,
                20.922,
                20.921,
                20.919,
                20.917,
                20.916,
                20.914,
                20.912,
                20.91,
                20.908,
                20.906,
                20.903,
                20.901,
                20.899,
                20.897,
                20.894,
                20.892,
                20.889,
                20.887,
                20.884,
                20.881,
                20.878,
                20.875,
                20.872,
                20.87,
                20.866,
                20.863,
                20.86,
                20.857,
                20.854,
                20.85,
                20.847,
                20.843,
                20.84,
                20.837,
                20.833,
                20.829,
                20.825,
                20.822,
                20.818,
                20.814,
                20.811,
                20.807,
                20.803,
                20.799,
                20.794,
                20.791,
                20.787,
                20.782,
                20.778,
                20.774,
                20.769,
                20.765,
                20.761,
                20.756,
                20.752,
                20.748,
                20.744,
                20.739,
                20.734,
                20.73,
                20.725,
                20.72,
                20.716,
                20.711,
                20.706,
                20.701,
                20.697,
                20.692,
                20.687,
                20.682,
                20.677,
                20.672,
                20.667,
                20.662,
                20.657,
                20.652,
                20.647,
                20.642,
                20.637,
                20.632,
                20.627,
                20.621,
                20.616,
                20.611,
                20.606,
                20.601,
                20.596,
                20.59,
                20.585,
                20.58,
                20.575,
                20.57,
                20.565,
                20.559,
                20.554,
                20.548,
                20.543,
                20.538,
                20.532,
                20.527,
                20.521,
                20.516,
                20.511,
                20.505,
                20.5,
                20.495,
                20.489,
                20.484,
                20.478,
                20.473,
                20.468,
                20.462,
                20.457,
                20.451,
                20.446,
                20.44,
                20.435,
                20.43,
                20.424,
                20.419,
                20.413,
                20.408,
                20.402,
                20.397,
                20.391,
                20.386,
                20.381,
                20.375,
                20.37,
                20.364,
                20.359,
                20.354,
                20.348,
                20.343,
                20.337,
                20.332,
                20.327,
                20.321,
                20.316,
                20.31,
                20.305,
                20.3,
                20.294,
                20.289,
                20.283,
                20.278,
                20.273,
                20.267,
                20.262,
                20.257,
                20.251,
                20.246,
                20.241,
                20.235,
                20.23,
                20.225,
                20.22,
                20.214,
                20.209,
                20.204,
                20.198,
                20.193,
                20.188,
                20.183,
                20.177,
                20.172,
                20.167,
                20.162,
                20.156,
                20.152,
                20.147,
                20.141,
                20.136,
                20.131,
                20.126,
                20.121,
                20.115,
                20.11,
                20.105,
                20.1,
                20.095,
                20.09,
                20.085,
                20.08,
                20.075,
                20.07,
                20.065,
                20.06,
                20.055,
                20.05,
                20.045,
                20.04,
                20.035,
                20.03,
                20.025,
                20.02,
                20.015,
                20.01,
                20.005,
                20,
                19.995,
                19.991,
                19.986,
                19.981,
                19.976,
                19.971,
                19.966,
                19.962,
                19.957,
                19.952,
                19.948,
                19.943,
                19.938,
                19.933,
                19.928,
                19.924,
                19.919,
                19.915,
                19.91,
                19.905,
                19.9,
                19.896,
                19.891,
                19.887,
                19.882,
                19.877,
                19.873,
                19.869,
                19.864,
                19.859,
                19.855,
                19.85,
                19.846,
                19.841,
                19.837,
                19.832,
                19.828,
                19.823,
                19.819,
                19.815,
                19.81,
                19.806,
                19.801,
                19.797,
                19.793,
                19.788,
                19.784,
                19.779,
                19.775,
                19.771,
                19.766,
                19.762,
                19.758,
                19.754,
                19.749,
                19.745,
                19.741,
                19.737,
                19.733,
                19.728,
                19.724,
                19.72,
                19.716,
                19.712,
                19.707,
                19.704,
                19.7,
                19.695,
                19.691,
                19.687,
                19.683,
                19.679,
                19.675,
                19.671,
                19.667,
                19.663,
                19.659,
                19.655,
                19.651,
                19.647,
                19.643,
                19.64,
                19.636,
                19.631,
                19.627,
                19.624,
                19.62,
                19.616,
                19.612,
                19.609,
                19.605,
                19.601,
                19.597,
                19.593,
                19.589,
                19.585,
                19.582,
                19.578,
                19.574,
                19.571,
                19.567,
                19.563,
                19.56,
                19.556,
                19.552,
                19.549,
                19.546,
                19.542,
                19.538,
                19.534,
                19.531,
                19.527,
                19.524,
                19.52,
                19.517,
                19.513,
                19.51,
                19.506,
                19.503,
                19.5,
                19.496,
                19.492,
                19.489,
                19.486,
                19.482,
                19.479,
                19.476,
                19.472,
                19.468,
                19.465,
                19.462,
                19.458,
                19.455,
                19.452,
                19.449,
                19.446,
                19.442,
                19.439,
                19.436,
                19.432,
                19.429,
                19.426,
                19.423,
                19.419,
                19.417,
                19.413,
                19.41,
                19.407,
                19.404,
                19.401,
                19.398,
                19.395,
                19.392,
                19.389,
                19.385,
                19.382,
                19.379,
                19.376,
                19.373,
                19.37,
                19.367,
                19.364,
                19.361,
                19.358,
                19.356,
                19.352,
                19.35,
                19.347,
                19.344,
                19.341,
                19.338,
                19.335,
                19.332,
                19.329,
                19.327,
                19.324,
                19.321,
                19.318,
                19.315,
                19.312,
                19.31,
                19.307,
                19.305,
                19.302,
                19.299,
                19.296,
                19.293,
                19.291,
                19.288,
                19.285,
                19.283,
                19.28,
                19.277,
                19.275,
                19.272,
                19.27,
                19.267,
                19.264,
                19.262,
                19.259,
                19.257,
                19.254,
                19.252,
                19.249,
                19.247,
                19.245,
                19.242,
                19.239,
                19.237,
                19.234,
                19.232,
                19.23,
                19.228,
                19.225,
                19.222,
                19.22,
                19.218,
                19.216,
                19.213,
                19.211,
                19.209,
                19.206,
                19.204,
                19.202,
                19.2,
                19.197,
                19.195,
                19.193,
                19.19,
                19.188,
                19.186,
                19.184,
                19.182,
                19.179,
                19.178,
                19.175,
                19.173,
                19.171,
                19.169,
                19.167,
                19.165,
                19.163,
                19.16,
                19.159,
                19.156,
                19.155,
                19.153,
                19.15,
                19.149,
                19.146,
                19.145,
                19.143,
                19.141,
                19.139,
                19.137,
                19.135,
                19.133,
                19.131,
                19.129,
                19.128,
                19.126,
                19.124,
                19.122,
                19.12,
                19.118,
                19.117,
                19.115,
                19.113,
                19.111,
                19.109,
                19.108,
                19.106,
                19.105,
                19.103,
                19.101,
                19.099,
                19.098,
                19.096,
                19.094,
                19.093,
                19.091,
                19.089,
                19.088,
                19.086,
                19.084,
                19.083,
                19.081,
                19.08,
                19.078,
                19.077,
                19.075,
                19.074,
                19.072,
                19.071,
                19.069,
                19.068,
                19.066,
                19.065,
                19.063,
                19.062,
                19.061,
                19.059,
                19.058,
                19.056,
                19.055,
                19.053,
                19.052,
                19.051,
                19.049,
                19.048,
                19.047,
                19.045,
                19.044,
                19.043,
                19.042,
                19.04,
                19.039,
                19.037,
                19.036,
                19.326,
                19.325,
                19.324,
                19.322,
                19.321,
                19.32,
                19.319,
                19.318,
                19.316,
                19.315,
                19.314,
                19.313,
                19.312,
                19.31,
                19.309,
                19.307,
                19.306,
                19.305,
                19.304,
                19.303,
                19.301,
                19.3,
                19.299,
                19.298,
                19.297,
                19.296,
                19.294,
                19.293,
                19.292,
                19.29,
                19.289,
                19.288,
                19.287,
                19.286,
                19.284,
                19.283,
                19.282,
                19.281,
                19.279,
                19.278,
                19.277,
                19.276,
                19.275,
                19.274,
                19.273,
                19.271,
                19.27,
                19.269,
                19.267,
                19.266,
                19.265,
                19.264,
                19.263,
                19.262,
                19.261,
                19.259,
                19.258,
                19.257,
                19.256,
                19.255,
                19.253,
                19.252,
                19.251,
                19.25,
                19.249,
                19.248,
                19.247,
                19.245,
                19.244,
                19.243,
                19.242,
                19.241,
                19.24,
                19.238,
                19.237,
                19.236,
                19.235,
                19.234,
                19.233,
                19.231,
                19.23,
                19.229,
                19.228,
                19.227,
                19.226,
                19.225,
                19.224,
                19.223,
                19.222,
                19.22,
                19.219,
                19.218,
                19.217,
                19.216,
                19.215,
                19.214,
                19.213,
                19.212,
                19.211,
                19.209,
                19.208,
                19.207,
                19.206,
                19.205,
                19.204,
                19.203,
                19.202,
                19.201,
                19.199,
                19.198,
                19.197,
                19.196,
                19.195,
                19.194,
                19.193,
                19.192,
                19.191,
                19.189,
                19.188,
                19.187,
                19.186,
                19.185,
                19.184,
                19.183,
                19.182,
                19.181,
                19.18,
                19.179,
                19.178,
                19.177,
                19.176,
                19.175,
                19.174,
                19.172,
                19.171,
                19.17,
                19.169,
                19.168,
                19.167,
                19.166,
                19.165,
                19.164,
                19.163,
                19.162,
                19.161,
                19.16,
                19.159,
                19.158,
                19.157,
                19.156,
                19.155,
                19.154,
                19.153,
                19.152,
                19.151,
                19.15,
                19.149,
                19.148,
                19.147,
                19.146,
                19.145,
                19.144,
                19.143,
                19.142,
                19.141,
                19.14,
                19.139,
                19.138,
                19.137,
                19.136,
                19.135,
                19.134,
                19.133,
                19.132,
                19.131,
                19.13,
                19.129,
                19.128,
                19.127,
                19.127,
                19.126,
                19.125,
                19.124,
                19.123,
                19.122,
                19.121,
                19.12,
                19.119,
                19.118,
                19.117,
                19.116,
                19.115,
                19.114,
                19.113,
                19.112,
                19.111,
                19.11,
                19.109,
                19.108,
                19.108,
                19.107,
                19.106,
                19.105,
                19.104,
                19.103,
                19.102,
                19.102,
                19.101,
                19.1,
                19.099,
                19.098,
                19.097,
                19.096,
                19.095,
                19.094,
                19.093,
                19.092,
                19.091,
                19.091,
                19.09,
                19.089,
                19.088,
                19.088,
                19.087,
                19.086,
                19.085,
                19.084,
                19.083,
                19.082,
                19.081,
                19.08,
                19.08,
                19.079,
                19.078,
                19.077,
                19.077,
                19.076,
                19.075,
                19.074,
                19.073,
                19.072,
                19.072,
                19.071,
                19.07,
                19.069,
                19.069,
                19.068,
                19.067,
                19.066,
                19.066,
                19.065,
                19.064,
                19.063,
                19.063,
                19.062,
                19.061,
                19.06,
                19.06,
                19.059,
                19.058,
                19.057,
                19.057,
                19.056,
                19.055,
                19.055,
                19.054,
                19.053,
                19.052,
                19.052,
                19.051,
                19.05,
                19.05,
                19.049,
                19.049,
                19.048,
                19.047,
                19.046,
                19.046,
                19.045,
                19.045,
                19.044,
                19.043,
                19.043,
                19.042,
                19.041,
                19.041,
                19.04,
                19.04,
                19.039,
                19.038,
                19.038,
                19.037,
                19.037,
                19.036,
                19.036,
                19.035,
                19.034,
                19.034,
                19.033,
                19.033,
                19.032,
                19.032,
                19.031,
                19.03,
                19.03,
                19.03,
                19.029,
                19.028,
                19.028,
                19.027,
                19.027,
                19.027,
                19.026,
                19.025,
                19.025,
                19.025,
                19.024,
                19.024,
                19.023,
                19.022,
                19.022,
                19.022,
                19.021,
                19.021,
                19.02,
                19.02,
                19.02,
                19.02,
                19.019,
                19.018,
                19.018,
                19.018,
                19.017,
                19.017,
                19.016,
                19.016,
                19.016,
                19.016,
                19.015,
                19.015,
                19.014,
                19.014,
                19.014,
                19.013,
                19.013,
                19.013,
                19.013,
                19.012,
                19.012,
                19.011,
                19.011,
                19.011,
                19.011,
                19.01,
                19.01,
                19.01,
                19.01,
                19.009,
                19.009,
                19.009,
                19.009,
                19.008,
                19.008,
                19.008,
                19.008,
                19.007,
                19.007,
                19.007,
                19.007,
                19.007,
                19.006,
                19.006,
                19.006,
                19.006,
                19.005,
                19.005,
                19.005,
                19.005,
                19.005,
                19.005,
                19.005,
                19.004,
                19.004,
                19.004,
                19.004,
                19.004,
                19.004,
                19.004,
                19.004,
                19.003,
                19.004,
                19.004,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.003,
                19.004,
                19.003,
                19.004,
                19.004,
                19.004,
                19.004,
                19.004,
                19.004,
                19.004,
                19.005,
                19.005,
                19.005,
                19.005,
                19.005,
                19.005,
                19.005,
                19.005,
                19.005,
                19.006,
                19.006,
                19.006,
                19.006,
                19.006,
                19.006,
                19.007,
                19.007,
                19.007,
                19.007,
                19.007,
                19.008,
                19.008,
                19.008,
                19.008,
                19.009,
                19.009,
                19.009,
                19.009,
                19.009,
                19.01,
                19.01,
                19.01,
                19.011,
                19.011,
                19.011,
                19.011,
                19.011,
                19.012,
                19.012,
                19.012,
                19.013,
                19.013,
                19.013,
                19.014,
                19.014,
                19.014,
                19.014,
                19.014,
                19.015,
                19.015,
                19.015,
                19.016,
                19.016,
                19.016,
                19.017,
                19.017,
                19.018,
                19.018,
                19.018,
                19.019,
                19.019,
                19.019,
                19.02,
                19.02,
                19.02,
                19.021,
                19.021,
                19.021,
                19.021,
                19.022,
                19.022,
                19.023,
                19.023,
                19.023,
                19.024,
                19.024,
                19.025,
                19.025,
                19.025,
                19.026,
                19.026,
                19.026,
                19.027,
                19.027,
                19.028,
                19.029,
                19.029,
                19.029,
                19.03,
                19.03,
                19.031,
                19.031,
                19.031,
                19.032,
                19.032,
                19.033,
                19.033,
                19.034,
                19.034,
                19.034,
                19.035,
                19.035,
                19.036,
                19.036,
                19.037,
                19.037,
                19.037,
                19.038,
                19.038,
                19.039,
                19.039,
                19.04,
                19.041,
                19.041,
                19.041,
                19.042,
                19.042,
                19.043,
                19.043,
                19.044,
                19.044,
                19.045,
                19.046,
                19.046,
                19.046,
                19.047,
                19.047,
                19.048,
                19.048,
                19.049,
                19.049,
                19.05,
                19.051,
                19.051,
                19.052,
                19.052,
                19.053,
                19.053,
                19.054,
                19.054,
                19.055,
                19.055,
                19.056,
                19.056,
                19.057,
                19.057,
                19.058,
                19.059,
                19.059,
                19.06,
                19.06,
                19.061,
                19.061,
                19.062,
                19.063,
                19.063,
                19.064,
                19.065,
                19.065,
                19.065,
                19.066,
                19.066,
                19.067,
                19.068,
                19.068,
                19.069,
                19.069,
                19.07,
                19.07,
                19.071,
                19.072,
                19.072,
                19.073,
                19.073,
                19.074,
                19.075,
                19.075,
                19.076,
                19.077,
                19.077,
                19.078,
                19.078,
                19.079,
                19.08,
                19.08,
                19.081,
                19.082,
                19.082,
                19.083,
                19.083,
                19.084,
                19.084,
                19.085,
                19.086,
                19.086,
                19.087,
                19.087,
                19.088,
                19.089,
                19.089,
                19.09,
                19.091,
                19.091,
                19.092,
                19.093,
                19.093,
                19.094,
                19.095,
                19.095,
                19.096,
                19.097,
                19.097,
                19.098,
                19.098,
                19.099,
                19.1,
                19.1,
                19.101,
                19.101,
                19.102,
                19.103,
                19.103,
                19.104,
                19.105,
                19.105,
                19.106,
                19.107,
                19.107,
                19.108,
                19.109,
                19.109,
                19.11,
                19.111,
                19.111,
                19.112,
                19.112,
                19.113,
                19.114,
                19.115,
                19.115,
                19.116,
                19.117,
                19.117,
                19.118,
                19.119,
                19.119,
                19.12,
                19.121,
                19.122,
                19.122,
                19.123,
                19.123,
                19.124,
                19.125,
                19.125,
                19.126,
                19.127,
                19.128,
                19.128,
                19.129,
                19.13,
                19.131,
                19.131,
                19.132,
                19.133,
                19.133,
                19.134,
                19.134,
                19.135,
                19.136,
                19.137,
                19.137,
                19.138,
                19.139,
                19.14,
                19.141,
                19.141,
                19.142,
                19.143,
                19.144,
                19.144,
                19.145,
                19.145,
                19.146,
                19.147,
                19.148,
                19.148,
                19.149,
                19.15,
                19.151,
                19.152,
                19.152,
                19.153,
                19.154,
                19.155,
                19.156,
                19.156,
                19.157,
                19.157,
                19.158,
                19.159,
                19.16,
                19.161,
                19.162,
                19.162,
                19.163,
                19.164,
                19.165,
                19.166,
                19.166,
                19.167,
                19.168,
                19.169,
                19.169,
                19.17,
                19.171,
                19.172,
                19.172,
                19.173,
                19.174,
                19.175,
                19.176,
                19.177,
                19.178,
                19.178,
                19.179,
                19.18,
                19.181,
                19.182,
                19.182,
                19.183,
                19.184,
                19.185,
                19.186,
                19.186,
                19.187,
                19.188,
                19.189,
                19.19,
                19.191,
                19.192,
                19.192,
                19.193,
                19.194,
                19.195,
                19.196,
                19.196,
                19.197,
                19.198,
                19.199,
                19.2,
                19.201,
                19.202,
                19.203,
                19.204,
                19.205,
                19.205,
                19.206,
                19.207,
                19.208,
                19.209,
                19.209,
                19.21,
                19.211,
                19.212,
                19.213,
                19.214,
                19.215,
                19.216,
                19.217,
                19.218,
                19.219,
                19.22,
                19.221,
                19.222,
                19.222,
                19.223,
                19.224,
                19.225,
                19.226,
                19.227,
                19.228,
                19.229,
                19.23,
                19.231,
                19.231,
                19.233,
                19.233,
                19.234,
                19.235,
                19.236,
                19.237,
                19.238,
                19.239,
                19.24,
                19.241,
                19.242,
                19.243,
                19.244,
                19.245,
                19.245,
                19.246,
                19.247,
                19.248,
                19.249,
                19.25,
                19.251,
                19.252,
                19.253,
                19.254,
                19.255,
                19.256,
                19.257,
                19.258,
                19.259,
                19.26,
                19.261,
                19.262,
                19.263,
                19.264,
                19.264,
                19.265,
                19.266,
                19.267,
                19.268,
                19.269,
                19.27,
                19.272,
                19.272,
                19.273,
                19.274,
                19.275,
                19.276,
                19.277,
                19.278,
                19.279,
                19.28,
                19.281,
                19.282,
                19.283,
                19.284,
                19.285,
                19.286,
                19.287,
                19.288,
                19.289,
                19.29,
                19.291,
                19.292,
                19.293,
                19.294,
                19.295,
                19.296,
                19.297,
                19.298,
                19.299,
                19.3,
                19.301,
                19.302,
                19.303,
                19.304,
                19.305,
                19.306,
                19.307,
                19.308,
                19.309,
                19.31,
                19.311,
                19.312,
                19.313,
                19.314,
                19.315,
                19.316,
                19.317,
                19.318,
                19.319,
                19.32,
                19.321,
                19.322,
                19.323,
                19.324,
                19.325,
                19.326,
                19.327,
                19.328,
                19.329,
                19.33,
                19.331,
                19.332,
                19.333,
                19.334,
                19.335,
                19.336,
                19.337,
                19.338,
                19.339,
                19.34,
                19.341,
                19.342,
                19.342,
                19.344,
                19.345,
                19.346,
                19.347,
                19.348,
                19.349,
                19.35,
                19.351,
                19.352,
                19.352,
                19.353,
                19.355,
                19.356,
                19.356,
                19.358,
                19.359,
                19.359,
                19.361,
                19.362,
                19.362,
                19.364,
                19.365,
                19.365,
                19.366,
                19.368,
                19.368,
                19.369,
                19.37,
                19.371,
                19.373,
                19.373,
                19.374,
                19.376,
                19.376,
                19.377,
                19.378,
                19.379,
                19.38,
                19.381,
                19.382,
                19.383,
                19.384,
                19.385,
                19.386,
                19.387,
                19.388,
                19.389,
                19.39,
                19.391,
                19.392,
                19.393,
                19.394,
                19.394,
                19.396,
                19.397,
                19.397,
                19.399,
                19.399,
                19.401,
                19.401,
                19.402,
                19.403,
                19.404,
                19.405,
                19.406,
                19.407,
                19.408,
                19.409,
                19.41,
                19.411,
                19.412,
                19.412,
                19.414,
                19.414,
                19.415,
                19.416,
                19.417,
                19.418,
                19.419,
                19.42,
                19.421,
                19.422,
                19.423,
                19.424,
                19.425,
                19.426,
                19.426,
                19.428,
                19.428,
                19.429,
                19.43,
                19.431,
                19.432,
                19.433,
                19.434,
                19.435,
                19.436,
                19.437,
                19.438,
                19.438,
                19.439,
                19.44,
                19.441,
                19.442,
                19.443,
                19.444,
                19.445,
                19.446,
                19.447,
                19.448,
                19.448,
                19.449,
                19.45,
                19.451,
                19.452,
                19.453,
                19.454,
                19.455,
                19.456,
                19.456,
                19.457,
                19.458,
                19.459,
                19.46,
                19.461,
                19.462,
                19.463,
                19.463,
                19.464,
                19.465,
                19.466,
                19.467,
                19.468,
                19.469,
                19.469,
                19.471,
                19.471,
                19.472,
                19.473,
                19.474,
                19.475,
                19.476,
                19.477,
                19.478,
                19.478,
                19.479,
                19.48,
                19.481,
                19.482,
                19.483,
                19.484,
                19.484,
                19.485,
                19.486,
                19.487,
                19.488,
                19.489,
                19.49,
                19.491,
                19.491,
                19.492,
                19.493,
                19.494,
                19.495,
                19.496,
                19.497,
                19.497,
                19.498,
                19.499,
                19.5,
                19.501,
                19.502,
                19.502,
                19.503,
                19.504,
                19.505,
                19.506,
                19.507,
                19.508,
                19.509,
                19.509,
                19.51,
                19.511,
                19.512,
                19.513,
                19.514,
                19.514,
                19.515,
                19.516,
                19.517,
                19.518,
                19.519
            ],
            P999: [
                17.808,
                17.759,
                17.71,
                17.661,
                17.613,
                17.565,
                17.517,
                17.469,
                17.516,
                17.563,
                17.61,
                17.657,
                17.704,
                17.751,
                17.798,
                17.888,
                17.983,
                18.08,
                18.178,
                18.276,
                18.374,
                18.47,
                18.566,
                18.659,
                18.752,
                18.842,
                18.93,
                19.017,
                19.101,
                19.184,
                19.264,
                19.342,
                19.418,
                19.491,
                19.562,
                19.631,
                19.699,
                19.764,
                19.828,
                19.889,
                19.949,
                20.008,
                20.065,
                20.121,
                20.175,
                20.228,
                20.28,
                20.33,
                20.379,
                20.427,
                20.473,
                20.518,
                20.563,
                20.606,
                20.648,
                20.69,
                20.73,
                20.769,
                20.809,
                20.846,
                20.883,
                20.92,
                20.955,
                20.99,
                21.024,
                21.058,
                21.089,
                21.121,
                21.152,
                21.183,
                21.213,
                21.242,
                21.27,
                21.298,
                21.325,
                21.352,
                21.378,
                21.403,
                21.428,
                21.453,
                21.476,
                21.5,
                21.523,
                21.545,
                21.568,
                21.589,
                21.611,
                21.631,
                21.652,
                21.672,
                21.691,
                21.71,
                21.729,
                21.748,
                21.765,
                21.783,
                21.801,
                21.818,
                21.834,
                21.851,
                21.867,
                21.882,
                21.898,
                21.913,
                21.928,
                21.942,
                21.956,
                21.97,
                21.983,
                21.997,
                22.01,
                22.023,
                22.035,
                22.047,
                22.06,
                22.072,
                22.083,
                22.095,
                22.106,
                22.117,
                22.128,
                22.138,
                22.149,
                22.159,
                22.169,
                22.179,
                22.189,
                22.198,
                22.207,
                22.216,
                22.225,
                22.234,
                22.243,
                22.251,
                22.259,
                22.268,
                22.275,
                22.283,
                22.291,
                22.298,
                22.305,
                22.312,
                22.32,
                22.327,
                22.333,
                22.339,
                22.346,
                22.352,
                22.357,
                22.363,
                22.369,
                22.375,
                22.38,
                22.386,
                22.391,
                22.396,
                22.401,
                22.405,
                22.409,
                22.414,
                22.418,
                22.422,
                22.426,
                22.43,
                22.434,
                22.438,
                22.441,
                22.445,
                22.448,
                22.451,
                22.454,
                22.458,
                22.461,
                22.463,
                22.466,
                22.468,
                22.47,
                22.472,
                22.475,
                22.477,
                22.479,
                22.48,
                22.482,
                22.483,
                22.485,
                22.486,
                22.488,
                22.488,
                22.489,
                22.49,
                22.491,
                22.492,
                22.492,
                22.492,
                22.493,
                22.493,
                22.493,
                22.494,
                22.494,
                22.493,
                22.494,
                22.493,
                22.492,
                22.492,
                22.492,
                22.491,
                22.49,
                22.489,
                22.488,
                22.487,
                22.486,
                22.485,
                22.484,
                22.482,
                22.481,
                22.479,
                22.478,
                22.476,
                22.474,
                22.473,
                22.47,
                22.469,
                22.466,
                22.465,
                22.462,
                22.46,
                22.457,
                22.456,
                22.453,
                22.451,
                22.448,
                22.445,
                22.442,
                22.44,
                22.436,
                22.434,
                22.43,
                22.428,
                22.425,
                22.421,
                22.419,
                22.415,
                22.412,
                22.408,
                22.405,
                22.402,
                22.398,
                22.394,
                22.39,
                22.387,
                22.383,
                22.379,
                22.375,
                22.372,
                22.367,
                22.364,
                22.359,
                22.355,
                22.351,
                22.347,
                22.343,
                22.339,
                22.334,
                22.33,
                22.325,
                22.32,
                22.316,
                22.312,
                22.308,
                22.302,
                22.298,
                22.293,
                22.288,
                22.284,
                22.279,
                22.274,
                22.269,
                22.264,
                22.26,
                22.255,
                22.249,
                22.244,
                22.24,
                22.235,
                22.229,
                22.224,
                22.219,
                22.214,
                22.209,
                22.203,
                22.198,
                22.193,
                22.188,
                22.182,
                22.177,
                22.172,
                22.167,
                22.161,
                22.156,
                22.15,
                22.145,
                22.139,
                22.134,
                22.129,
                22.123,
                22.117,
                22.112,
                22.107,
                22.101,
                22.096,
                22.09,
                22.085,
                22.079,
                22.073,
                22.068,
                22.062,
                22.057,
                22.051,
                22.046,
                22.04,
                22.034,
                22.028,
                22.023,
                22.017,
                22.012,
                22.006,
                22.001,
                21.995,
                21.989,
                21.984,
                21.978,
                21.973,
                21.966,
                21.961,
                21.955,
                21.949,
                21.944,
                21.938,
                21.933,
                21.927,
                21.921,
                21.916,
                21.91,
                21.905,
                21.899,
                21.893,
                21.888,
                21.882,
                21.876,
                21.871,
                21.865,
                21.86,
                21.854,
                21.848,
                21.843,
                21.837,
                21.832,
                21.826,
                21.82,
                21.815,
                21.809,
                21.804,
                21.798,
                21.793,
                21.787,
                21.782,
                21.776,
                21.771,
                21.765,
                21.76,
                21.754,
                21.749,
                21.743,
                21.737,
                21.732,
                21.726,
                21.721,
                21.716,
                21.71,
                21.705,
                21.699,
                21.695,
                21.689,
                21.684,
                21.678,
                21.673,
                21.667,
                21.662,
                21.657,
                21.651,
                21.646,
                21.64,
                21.635,
                21.63,
                21.624,
                21.62,
                21.614,
                21.609,
                21.604,
                21.598,
                21.593,
                21.588,
                21.583,
                21.577,
                21.573,
                21.568,
                21.562,
                21.557,
                21.552,
                21.547,
                21.541,
                21.536,
                21.531,
                21.527,
                21.522,
                21.517,
                21.511,
                21.506,
                21.501,
                21.496,
                21.492,
                21.486,
                21.481,
                21.476,
                21.471,
                21.466,
                21.461,
                21.457,
                21.452,
                21.447,
                21.442,
                21.437,
                21.432,
                21.428,
                21.423,
                21.418,
                21.413,
                21.408,
                21.403,
                21.399,
                21.394,
                21.389,
                21.384,
                21.38,
                21.375,
                21.371,
                21.366,
                21.361,
                21.356,
                21.351,
                21.347,
                21.343,
                21.338,
                21.333,
                21.328,
                21.324,
                21.32,
                21.315,
                21.31,
                21.306,
                21.302,
                21.297,
                21.292,
                21.288,
                21.284,
                21.279,
                21.274,
                21.27,
                21.265,
                21.262,
                21.257,
                21.252,
                21.248,
                21.244,
                21.24,
                21.235,
                21.231,
                21.227,
                21.223,
                21.218,
                21.214,
                21.21,
                21.206,
                21.201,
                21.197,
                21.193,
                21.189,
                21.184,
                21.18,
                21.176,
                21.172,
                21.168,
                21.163,
                21.16,
                21.156,
                21.151,
                21.147,
                21.144,
                21.139,
                21.135,
                21.131,
                21.128,
                21.123,
                21.119,
                21.116,
                21.112,
                21.108,
                21.103,
                21.1,
                21.096,
                21.092,
                21.088,
                21.084,
                21.08,
                21.076,
                21.073,
                21.069,
                21.065,
                21.062,
                21.058,
                21.054,
                21.05,
                21.047,
                21.043,
                21.039,
                21.036,
                21.032,
                21.028,
                21.025,
                21.021,
                21.017,
                21.014,
                21.01,
                21.006,
                21.003,
                20.999,
                20.996,
                20.992,
                20.989,
                20.985,
                20.981,
                20.978,
                20.975,
                20.971,
                20.968,
                20.964,
                20.961,
                20.958,
                20.954,
                20.95,
                20.947,
                20.944,
                20.94,
                20.938,
                20.934,
                20.93,
                20.928,
                20.924,
                20.92,
                20.918,
                20.914,
                20.911,
                20.908,
                20.904,
                20.902,
                20.898,
                20.895,
                20.892,
                20.889,
                20.885,
                20.883,
                20.879,
                20.876,
                20.873,
                20.87,
                20.866,
                20.864,
                20.861,
                20.858,
                20.855,
                20.851,
                20.849,
                20.846,
                20.842,
                20.84,
                20.837,
                20.834,
                20.831,
                20.828,
                20.825,
                20.822,
                20.819,
                20.817,
                20.813,
                20.811,
                20.808,
                20.805,
                20.802,
                20.799,
                20.797,
                20.794,
                20.791,
                20.789,
                20.785,
                20.783,
                20.78,
                20.777,
                20.775,
                20.772,
                20.769,
                20.767,
                20.764,
                20.762,
                20.759,
                20.756,
                20.754,
                20.751,
                20.749,
                20.746,
                20.743,
                20.741,
                20.738,
                20.736,
                20.733,
                20.731,
                20.728,
                20.726,
                20.724,
                20.721,
                20.719,
                20.716,
                20.713,
                20.712,
                20.709,
                20.707,
                20.704,
                20.702,
                20.7,
                20.697,
                20.695,
                20.693,
                20.691,
                20.688,
                20.686,
                20.684,
                20.681,
                20.68,
                20.677,
                20.675,
                20.673,
                20.67,
                20.668,
                20.666,
                20.664,
                20.662,
                20.66,
                20.658,
                20.656,
                20.654,
                20.651,
                20.65,
                20.647,
                20.646,
                20.643,
                20.642,
                20.64,
                20.638,
                20.636,
                20.633,
                20.632,
                20.63,
                20.628,
                20.626,
                20.624,
                20.622,
                20.621,
                20.619,
                20.617,
                20.615,
                20.613,
                20.611,
                20.609,
                20.608,
                20.606,
                20.604,
                20.602,
                20.601,
                20.599,
                20.598,
                20.596,
                20.594,
                20.592,
                20.59,
                20.589,
                20.587,
                20.586,
                20.584,
                20.583,
                20.581,
                20.58,
                20.578,
                20.576,
                20.574,
                20.573,
                20.571,
                20.57,
                20.568,
                20.567,
                20.565,
                20.564,
                20.562,
                20.561,
                20.56,
                20.558,
                20.557,
                20.555,
                20.554,
                20.552,
                20.551,
                20.549,
                20.548,
                20.547,
                20.546,
                20.544,
                20.543,
                20.541,
                20.54,
                20.539,
                20.538,
                20.536,
                20.535,
                20.816,
                20.814,
                20.813,
                20.812,
                20.81,
                20.809,
                20.808,
                20.807,
                20.805,
                20.804,
                20.803,
                20.801,
                20.8,
                20.799,
                20.797,
                20.795,
                20.794,
                20.793,
                20.792,
                20.79,
                20.789,
                20.788,
                20.786,
                20.785,
                20.784,
                20.783,
                20.781,
                20.779,
                20.778,
                20.777,
                20.776,
                20.774,
                20.773,
                20.772,
                20.77,
                20.769,
                20.767,
                20.766,
                20.765,
                20.763,
                20.762,
                20.761,
                20.76,
                20.759,
                20.757,
                20.756,
                20.755,
                20.753,
                20.752,
                20.75,
                20.749,
                20.748,
                20.747,
                20.746,
                20.744,
                20.743,
                20.742,
                20.74,
                20.739,
                20.738,
                20.736,
                20.735,
                20.734,
                20.733,
                20.732,
                20.73,
                20.729,
                20.727,
                20.726,
                20.725,
                20.724,
                20.722,
                20.721,
                20.72,
                20.719,
                20.718,
                20.717,
                20.715,
                20.714,
                20.712,
                20.711,
                20.71,
                20.709,
                20.708,
                20.706,
                20.705,
                20.704,
                20.703,
                20.702,
                20.7,
                20.699,
                20.698,
                20.696,
                20.695,
                20.694,
                20.693,
                20.692,
                20.691,
                20.69,
                20.688,
                20.687,
                20.685,
                20.684,
                20.683,
                20.682,
                20.681,
                20.68,
                20.679,
                20.677,
                20.676,
                20.675,
                20.674,
                20.673,
                20.672,
                20.671,
                20.669,
                20.668,
                20.666,
                20.665,
                20.664,
                20.663,
                20.662,
                20.661,
                20.66,
                20.659,
                20.658,
                20.656,
                20.655,
                20.654,
                20.653,
                20.652,
                20.651,
                20.65,
                20.648,
                20.647,
                20.646,
                20.645,
                20.644,
                20.642,
                20.641,
                20.64,
                20.639,
                20.638,
                20.637,
                20.636,
                20.635,
                20.634,
                20.633,
                20.631,
                20.63,
                20.629,
                20.628,
                20.627,
                20.626,
                20.625,
                20.624,
                20.623,
                20.622,
                20.621,
                20.619,
                20.618,
                20.617,
                20.616,
                20.615,
                20.614,
                20.613,
                20.612,
                20.611,
                20.61,
                20.609,
                20.608,
                20.607,
                20.605,
                20.604,
                20.603,
                20.602,
                20.601,
                20.601,
                20.6,
                20.599,
                20.598,
                20.597,
                20.595,
                20.594,
                20.593,
                20.592,
                20.591,
                20.59,
                20.589,
                20.588,
                20.588,
                20.587,
                20.586,
                20.585,
                20.583,
                20.582,
                20.581,
                20.58,
                20.579,
                20.579,
                20.578,
                20.577,
                20.576,
                20.575,
                20.574,
                20.573,
                20.572,
                20.571,
                20.57,
                20.569,
                20.568,
                20.567,
                20.567,
                20.566,
                20.565,
                20.564,
                20.563,
                20.562,
                20.561,
                20.56,
                20.559,
                20.558,
                20.558,
                20.557,
                20.556,
                20.555,
                20.554,
                20.553,
                20.552,
                20.551,
                20.55,
                20.55,
                20.549,
                20.548,
                20.547,
                20.547,
                20.546,
                20.545,
                20.544,
                20.543,
                20.542,
                20.542,
                20.541,
                20.54,
                20.539,
                20.539,
                20.538,
                20.537,
                20.537,
                20.536,
                20.535,
                20.534,
                20.533,
                20.533,
                20.532,
                20.531,
                20.531,
                20.53,
                20.529,
                20.529,
                20.528,
                20.527,
                20.526,
                20.525,
                20.525,
                20.524,
                20.524,
                20.523,
                20.523,
                20.522,
                20.521,
                20.521,
                20.52,
                20.519,
                20.519,
                20.518,
                20.517,
                20.517,
                20.516,
                20.516,
                20.515,
                20.515,
                20.514,
                20.514,
                20.513,
                20.512,
                20.511,
                20.511,
                20.51,
                20.51,
                20.51,
                20.509,
                20.509,
                20.508,
                20.508,
                20.507,
                20.507,
                20.506,
                20.506,
                20.505,
                20.505,
                20.504,
                20.504,
                20.503,
                20.503,
                20.503,
                20.502,
                20.502,
                20.502,
                20.501,
                20.501,
                20.5,
                20.499,
                20.499,
                20.499,
                20.499,
                20.498,
                20.498,
                20.498,
                20.497,
                20.497,
                20.497,
                20.497,
                20.496,
                20.496,
                20.495,
                20.495,
                20.495,
                20.494,
                20.494,
                20.494,
                20.494,
                20.494,
                20.493,
                20.493,
                20.493,
                20.493,
                20.493,
                20.493,
                20.492,
                20.492,
                20.491,
                20.491,
                20.491,
                20.491,
                20.491,
                20.491,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.489,
                20.489,
                20.489,
                20.489,
                20.489,
                20.489,
                20.489,
                20.489,
                20.489,
                20.489,
                20.489,
                20.489,
                20.489,
                20.489,
                20.489,
                20.489,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.49,
                20.491,
                20.491,
                20.491,
                20.491,
                20.491,
                20.492,
                20.492,
                20.492,
                20.492,
                20.493,
                20.493,
                20.493,
                20.493,
                20.494,
                20.494,
                20.494,
                20.494,
                20.495,
                20.495,
                20.495,
                20.496,
                20.496,
                20.496,
                20.496,
                20.496,
                20.497,
                20.497,
                20.497,
                20.498,
                20.498,
                20.498,
                20.498,
                20.499,
                20.499,
                20.499,
                20.5,
                20.5,
                20.501,
                20.501,
                20.502,
                20.502,
                20.502,
                20.503,
                20.503,
                20.504,
                20.504,
                20.505,
                20.505,
                20.506,
                20.506,
                20.506,
                20.507,
                20.507,
                20.508,
                20.508,
                20.509,
                20.509,
                20.51,
                20.51,
                20.511,
                20.512,
                20.512,
                20.513,
                20.513,
                20.514,
                20.514,
                20.515,
                20.515,
                20.516,
                20.517,
                20.517,
                20.518,
                20.518,
                20.519,
                20.519,
                20.52,
                20.521,
                20.521,
                20.522,
                20.523,
                20.523,
                20.524,
                20.524,
                20.525,
                20.526,
                20.526,
                20.527,
                20.528,
                20.528,
                20.529,
                20.53,
                20.53,
                20.531,
                20.532,
                20.532,
                20.533,
                20.534,
                20.534,
                20.535,
                20.536,
                20.537,
                20.537,
                20.538,
                20.539,
                20.539,
                20.54,
                20.541,
                20.542,
                20.542,
                20.543,
                20.544,
                20.545,
                20.545,
                20.546,
                20.547,
                20.548,
                20.548,
                20.549,
                20.55,
                20.551,
                20.552,
                20.552,
                20.553,
                20.554,
                20.555,
                20.555,
                20.556,
                20.557,
                20.558,
                20.559,
                20.56,
                20.56,
                20.561,
                20.562,
                20.563,
                20.564,
                20.564,
                20.565,
                20.566,
                20.567,
                20.568,
                20.569,
                20.57,
                20.571,
                20.572,
                20.573,
                20.574,
                20.575,
                20.576,
                20.577,
                20.577,
                20.578,
                20.579,
                20.58,
                20.581,
                20.582,
                20.583,
                20.584,
                20.584,
                20.585,
                20.586,
                20.587,
                20.588,
                20.589,
                20.59,
                20.591,
                20.592,
                20.593,
                20.593,
                20.594,
                20.596,
                20.596,
                20.597,
                20.599,
                20.6,
                20.601,
                20.602,
                20.603,
                20.604,
                20.605,
                20.606,
                20.607,
                20.607,
                20.608,
                20.609,
                20.61,
                20.611,
                20.612,
                20.613,
                20.614,
                20.615,
                20.616,
                20.617,
                20.619,
                20.62,
                20.621,
                20.622,
                20.623,
                20.624,
                20.625,
                20.626,
                20.627,
                20.628,
                20.628,
                20.629,
                20.63,
                20.631,
                20.633,
                20.634,
                20.635,
                20.636,
                20.637,
                20.638,
                20.639,
                20.64,
                20.641,
                20.642,
                20.643,
                20.644,
                20.645,
                20.647,
                20.648,
                20.649,
                20.65,
                20.651,
                20.652,
                20.653,
                20.654,
                20.655,
                20.656,
                20.658,
                20.659,
                20.66,
                20.661,
                20.662,
                20.662,
                20.663,
                20.665,
                20.666,
                20.667,
                20.668,
                20.669,
                20.67,
                20.671,
                20.672,
                20.673,
                20.675,
                20.676,
                20.677,
                20.678,
                20.679,
                20.68,
                20.681,
                20.682,
                20.684,
                20.685,
                20.686,
                20.687,
                20.688,
                20.689,
                20.69,
                20.691,
                20.692,
                20.693,
                20.694,
                20.696,
                20.697,
                20.698,
                20.699,
                20.7,
                20.701,
                20.703,
                20.704,
                20.705,
                20.706,
                20.707,
                20.708,
                20.71,
                20.711,
                20.711,
                20.712,
                20.714,
                20.715,
                20.716,
                20.717,
                20.718,
                20.719,
                20.721,
                20.722,
                20.723,
                20.724,
                20.725,
                20.727,
                20.728,
                20.729,
                20.73,
                20.731,
                20.732,
                20.733,
                20.734,
                20.736,
                20.737,
                20.738,
                20.739,
                20.74,
                20.742,
                20.743,
                20.744,
                20.745,
                20.747,
                20.747,
                20.748,
                20.75,
                20.751,
                20.752,
                20.753,
                20.755,
                20.756,
                20.757,
                20.758,
                20.76,
                20.761,
                20.762,
                20.763,
                20.765,
                20.765,
                20.766,
                20.768,
                20.769,
                20.77,
                20.771,
                20.773,
                20.774,
                20.775,
                20.777,
                20.778,
                20.779,
                20.781,
                20.782,
                20.783,
                20.784,
                20.785,
                20.786,
                20.787,
                20.789,
                20.79,
                20.791,
                20.793,
                20.794,
                20.795,
                20.797,
                20.798,
                20.799,
                20.801,
                20.802,
                20.803,
                20.805,
                20.805,
                20.806,
                20.808,
                20.809,
                20.81,
                20.812,
                20.813,
                20.815,
                20.816,
                20.817,
                20.819,
                20.82,
                20.821,
                20.823,
                20.824,
                20.825,
                20.826,
                20.827,
                20.829,
                20.83,
                20.831,
                20.833,
                20.834,
                20.835,
                20.837,
                20.838,
                20.84,
                20.841,
                20.842,
                20.844,
                20.845,
                20.847,
                20.847,
                20.849,
                20.85,
                20.851,
                20.853,
                20.854,
                20.856,
                20.857,
                20.858,
                20.86,
                20.861,
                20.863,
                20.864,
                20.866,
                20.867,
                20.868,
                20.869,
                20.871,
                20.872,
                20.873,
                20.875,
                20.876,
                20.878,
                20.879,
                20.881,
                20.882,
                20.883,
                20.885,
                20.886,
                20.888,
                20.888,
                20.89,
                20.891,
                20.893,
                20.894,
                20.896,
                20.897,
                20.899,
                20.9,
                20.902,
                20.903,
                20.905,
                20.906,
                20.907,
                20.908,
                20.91,
                20.911,
                20.913,
                20.914,
                20.916,
                20.917,
                20.919,
                20.92,
                20.922,
                20.923,
                20.924,
                20.926,
                20.927,
                20.929,
                20.93,
                20.932,
                20.933,
                20.935,
                20.936,
                20.938,
                20.939,
                20.94,
                20.942,
                20.943,
                20.945,
                20.946,
                20.948,
                20.949,
                20.951,
                20.952,
                20.953,
                20.955,
                20.956,
                20.958,
                20.96,
                20.961,
                20.963,
                20.964,
                20.965,
                20.967,
                20.968,
                20.97,
                20.971,
                20.973,
                20.974,
                20.976,
                20.977,
                20.978,
                20.98,
                20.982,
                20.983,
                20.985,
                20.986,
                20.987,
                20.989,
                20.99,
                20.992,
                20.994,
                20.995,
                20.996,
                20.998,
                20.999,
                21.001,
                21.002,
                21.004,
                21.005,
                21.007,
                21.008,
                21.01,
                21.011,
                21.012,
                21.014,
                21.015,
                21.017,
                21.019,
                21.02,
                21.021,
                21.023,
                21.025,
                21.026,
                21.028,
                21.029,
                21.03,
                21.032,
                21.034,
                21.035,
                21.036,
                21.038,
                21.039,
                21.041,
                21.042,
                21.044,
                21.045,
                21.047,
                21.048,
                21.05,
                21.051,
                21.053,
                21.054,
                21.055,
                21.057,
                21.059,
                21.06,
                21.061,
                21.063,
                21.065,
                21.066,
                21.067,
                21.069,
                21.07,
                21.072,
                21.073,
                21.075,
                21.076,
                21.078,
                21.079,
                21.08,
                21.082,
                21.084,
                21.084,
                21.086,
                21.088,
                21.09,
                21.09,
                21.092,
                21.094,
                21.095,
                21.096,
                21.098,
                21.099,
                21.101,
                21.103,
                21.103,
                21.105,
                21.107,
                21.108,
                21.109,
                21.11,
                21.112,
                21.114,
                21.115,
                21.117,
                21.118,
                21.119,
                21.121,
                21.122,
                21.124,
                21.125,
                21.126,
                21.128,
                21.129,
                21.131,
                21.132,
                21.133,
                21.135,
                21.136,
                21.138,
                21.139,
                21.14,
                21.142,
                21.143,
                21.145,
                21.146,
                21.148,
                21.149,
                21.15,
                21.152,
                21.153,
                21.155,
                21.156,
                21.157,
                21.158,
                21.16,
                21.161,
                21.163,
                21.164,
                21.166,
                21.166,
                21.168,
                21.169,
                21.171,
                21.172,
                21.174,
                21.175,
                21.176,
                21.177,
                21.179,
                21.18,
                21.182,
                21.183,
                21.185,
                21.185,
                21.187,
                21.188,
                21.19,
                21.191,
                21.192,
                21.194,
                21.195,
                21.196,
                21.198,
                21.199,
                21.2,
                21.202,
                21.203,
                21.204,
                21.206,
                21.207,
                21.209,
                21.209,
                21.211,
                21.212,
                21.213,
                21.215,
                21.216,
                21.217,
                21.219,
                21.22,
                21.222,
                21.222,
                21.224,
                21.225,
                21.226,
                21.227,
                21.229,
                21.23,
                21.231,
                21.233,
                21.234,
                21.235,
                21.237,
                21.238,
                21.24,
                21.24,
                21.242,
                21.243,
                21.244,
                21.245,
                21.246,
                21.248,
                21.249,
                21.25,
                21.252,
                21.253,
                21.254,
                21.256,
                21.257,
                21.258,
                21.259,
                21.261,
                21.262,
                21.263,
                21.264,
                21.265,
                21.266,
                21.268,
                21.269,
                21.27,
                21.271,
                21.273,
                21.274,
                21.275,
                21.276,
                21.278,
                21.279,
                21.28,
                21.281,
                21.283,
                21.284,
                21.285,
                21.286,
                21.288,
                21.289,
                21.29,
                21.291,
                21.293,
                21.294,
                21.295,
                21.296,
                21.298,
                21.299,
                21.3,
                21.301,
                21.302,
                21.303,
                21.305,
                21.306,
                21.307,
                21.308,
                21.309,
                21.311,
                21.311,
                21.313,
                21.314,
                21.315,
                21.316,
                21.318,
                21.319,
                21.32,
                21.321,
                21.322,
                21.324
            ]
        }
    },
    wfh: {
        boys: {
            P01: [
                5.828,
                5.847,
                5.865,
                5.884,
                5.902,
                5.921,
                5.939,
                5.958,
                5.976,
                5.994,
                6.013,
                6.031,
                6.049,
                6.068,
                6.086,
                6.104,
                6.122,
                6.14,
                6.158,
                6.176,
                6.194,
                6.212,
                6.23,
                6.248,
                6.266,
                6.284,
                6.301,
                6.319,
                6.337,
                6.355,
                6.373,
                6.39,
                6.408,
                6.426,
                6.443,
                6.461,
                6.479,
                6.496,
                6.514,
                6.532,
                6.549,
                6.567,
                6.585,
                6.602,
                6.62,
                6.637,
                6.655,
                6.672,
                6.69,
                6.707,
                6.724,
                6.742,
                6.759,
                6.776,
                6.794,
                6.811,
                6.828,
                6.845,
                6.862,
                6.879,
                6.897,
                6.913,
                6.93,
                6.948,
                6.964,
                6.981,
                6.998,
                7.015,
                7.031,
                7.048,
                7.065,
                7.081,
                7.098,
                7.114,
                7.131,
                7.147,
                7.164,
                7.18,
                7.196,
                7.212,
                7.228,
                7.245,
                7.261,
                7.277,
                7.293,
                7.309,
                7.324,
                7.34,
                7.356,
                7.372,
                7.388,
                7.404,
                7.419,
                7.435,
                7.451,
                7.466,
                7.482,
                7.497,
                7.513,
                7.528,
                7.544,
                7.559,
                7.574,
                7.589,
                7.604,
                7.619,
                7.635,
                7.649,
                7.664,
                7.679,
                7.694,
                7.709,
                7.724,
                7.738,
                7.753,
                7.768,
                7.782,
                7.797,
                7.811,
                7.826,
                7.84,
                7.855,
                7.869,
                7.883,
                7.898,
                7.912,
                7.926,
                7.941,
                7.955,
                7.969,
                7.983,
                7.998,
                8.012,
                8.026,
                8.041,
                8.055,
                8.069,
                8.084,
                8.098,
                8.113,
                8.127,
                8.142,
                8.156,
                8.171,
                8.186,
                8.201,
                8.215,
                8.23,
                8.245,
                8.26,
                8.275,
                8.29,
                8.306,
                8.321,
                8.337,
                8.352,
                8.368,
                8.383,
                8.399,
                8.415,
                8.431,
                8.448,
                8.464,
                8.48,
                8.497,
                8.513,
                8.53,
                8.547,
                8.564,
                8.581,
                8.598,
                8.615,
                8.633,
                8.651,
                8.668,
                8.686,
                8.704,
                8.722,
                8.741,
                8.759,
                8.777,
                8.796,
                8.815,
                8.834,
                8.852,
                8.871,
                8.891,
                8.91,
                8.929,
                8.949,
                8.968,
                8.988,
                9.007,
                9.027,
                9.047,
                9.067,
                9.087,
                9.107,
                9.127,
                9.147,
                9.167,
                9.187,
                9.208,
                9.228,
                9.248,
                9.268,
                9.289,
                9.309,
                9.329,
                9.35,
                9.37,
                9.39,
                9.411,
                9.431,
                9.451,
                9.472,
                9.492,
                9.512,
                9.532,
                9.552,
                9.573,
                9.593,
                9.613,
                9.633,
                9.653,
                9.673,
                9.693,
                9.713,
                9.733,
                9.752,
                9.772,
                9.792,
                9.812,
                9.831,
                9.851,
                9.87,
                9.89,
                9.909,
                9.929,
                9.948,
                9.967,
                9.986,
                10.006,
                10.025,
                10.044,
                10.063,
                10.082,
                10.101,
                10.12,
                10.139,
                10.158,
                10.176,
                10.195,
                10.214,
                10.233,
                10.251,
                10.27,
                10.289,
                10.307,
                10.326,
                10.344,
                10.363,
                10.381,
                10.4,
                10.418,
                10.437,
                10.455,
                10.473,
                10.492,
                10.51,
                10.528,
                10.547,
                10.565,
                10.583,
                10.601,
                10.619,
                10.637,
                10.656,
                10.674,
                10.692,
                10.71,
                10.728,
                10.746,
                10.765,
                10.783,
                10.801,
                10.819,
                10.837,
                10.855,
                10.873,
                10.891,
                10.91,
                10.928,
                10.946,
                10.964,
                10.982,
                11.001,
                11.019,
                11.037,
                11.056,
                11.074,
                11.092,
                11.111,
                11.129,
                11.147,
                11.166,
                11.184,
                11.203,
                11.221,
                11.24,
                11.258,
                11.277,
                11.296,
                11.314,
                11.333,
                11.352,
                11.371,
                11.39,
                11.408,
                11.427,
                11.447,
                11.465,
                11.484,
                11.504,
                11.523,
                11.542,
                11.561,
                11.581,
                11.6,
                11.62,
                11.639,
                11.659,
                11.678,
                11.698,
                11.718,
                11.737,
                11.757,
                11.777,
                11.797,
                11.817,
                11.837,
                11.857,
                11.876,
                11.896,
                11.917,
                11.937,
                11.957,
                11.977,
                11.997,
                12.018,
                12.038,
                12.059,
                12.079,
                12.099,
                12.12,
                12.14,
                12.161,
                12.181,
                12.202,
                12.223,
                12.244,
                12.265,
                12.285,
                12.306,
                12.327,
                12.348,
                12.369,
                12.39,
                12.411,
                12.432,
                12.453,
                12.474,
                12.496,
                12.517,
                12.538,
                12.56,
                12.581,
                12.602,
                12.624,
                12.645,
                12.667,
                12.689,
                12.71,
                12.732,
                12.753,
                12.775,
                12.797,
                12.819,
                12.841,
                12.862,
                12.884,
                12.906,
                12.928,
                12.95,
                12.973,
                12.995,
                13.017,
                13.039,
                13.061,
                13.083,
                13.106,
                13.128,
                13.151,
                13.173,
                13.196,
                13.218,
                13.241,
                13.263,
                13.286,
                13.309,
                13.332,
                13.354,
                13.378,
                13.4,
                13.423,
                13.446,
                13.469,
                13.492,
                13.515,
                13.538,
                13.561,
                13.585,
                13.608,
                13.631,
                13.655,
                13.678,
                13.701,
                13.725,
                13.749,
                13.772,
                13.796,
                13.819,
                13.843,
                13.867,
                13.891,
                13.915,
                13.939,
                13.963,
                13.987,
                14.011,
                14.035,
                14.06,
                14.084,
                14.108,
                14.133,
                14.157,
                14.182,
                14.207,
                14.231,
                14.256,
                14.281,
                14.306,
                14.331,
                14.356,
                14.381,
                14.406,
                14.431,
                14.456,
                14.482,
                14.507,
                14.532,
                14.558,
                14.583,
                14.609,
                14.634,
                14.66,
                14.686,
                14.712,
                14.737,
                14.763,
                14.789,
                14.815,
                14.841,
                14.867,
                14.893,
                14.919,
                14.945,
                14.972,
                14.997,
                15.024,
                15.05,
                15.077,
                15.103,
                15.129,
                15.156,
                15.182,
                15.209,
                15.235,
                15.262,
                15.289,
                15.315,
                15.342,
                15.369,
                15.395,
                15.422,
                15.449,
                15.476,
                15.503,
                15.529,
                15.556,
                15.583,
                15.61,
                15.637,
                15.664,
                15.691,
                15.718,
                15.745,
                15.772,
                15.799,
                15.826,
                15.854,
                15.881,
                15.908,
                15.935,
                15.962,
                15.989,
                16.016,
                16.044,
                16.071,
                16.098,
                16.125,
                16.152,
                16.18,
                16.207,
                16.234,
                16.261,
                16.288,
                16.316,
                16.343,
                16.37,
                16.397,
                16.424,
                16.452,
                16.479,
                16.506,
                16.533,
                16.56,
                16.588,
                16.615,
                16.642,
                16.669,
                16.697,
                16.723,
                16.751,
                16.778,
                16.805,
                16.832,
                16.859,
                16.886,
                16.914,
                16.941
            ],
            P1: [
                6.177,
                6.197,
                6.217,
                6.236,
                6.256,
                6.276,
                6.295,
                6.315,
                6.334,
                6.354,
                6.373,
                6.392,
                6.412,
                6.431,
                6.45,
                6.47,
                6.489,
                6.508,
                6.527,
                6.546,
                6.565,
                6.584,
                6.603,
                6.622,
                6.641,
                6.66,
                6.679,
                6.698,
                6.717,
                6.736,
                6.755,
                6.773,
                6.792,
                6.811,
                6.83,
                6.849,
                6.867,
                6.886,
                6.905,
                6.924,
                6.942,
                6.961,
                6.98,
                6.998,
                7.017,
                7.036,
                7.054,
                7.073,
                7.091,
                7.11,
                7.128,
                7.147,
                7.165,
                7.184,
                7.202,
                7.22,
                7.239,
                7.257,
                7.275,
                7.293,
                7.311,
                7.329,
                7.348,
                7.366,
                7.384,
                7.402,
                7.419,
                7.437,
                7.455,
                7.473,
                7.491,
                7.508,
                7.526,
                7.543,
                7.561,
                7.578,
                7.596,
                7.613,
                7.63,
                7.648,
                7.665,
                7.682,
                7.699,
                7.716,
                7.733,
                7.75,
                7.767,
                7.784,
                7.801,
                7.818,
                7.834,
                7.851,
                7.868,
                7.885,
                7.901,
                7.918,
                7.934,
                7.951,
                7.967,
                7.984,
                8,
                8.016,
                8.033,
                8.049,
                8.065,
                8.081,
                8.097,
                8.113,
                8.129,
                8.145,
                8.161,
                8.176,
                8.192,
                8.208,
                8.223,
                8.239,
                8.254,
                8.27,
                8.285,
                8.3,
                8.316,
                8.331,
                8.346,
                8.361,
                8.377,
                8.392,
                8.407,
                8.422,
                8.437,
                8.452,
                8.468,
                8.483,
                8.498,
                8.513,
                8.528,
                8.543,
                8.558,
                8.574,
                8.589,
                8.604,
                8.619,
                8.635,
                8.65,
                8.666,
                8.681,
                8.697,
                8.713,
                8.728,
                8.744,
                8.76,
                8.776,
                8.792,
                8.808,
                8.824,
                8.841,
                8.857,
                8.873,
                8.89,
                8.907,
                8.923,
                8.94,
                8.957,
                8.974,
                8.992,
                9.009,
                9.026,
                9.044,
                9.062,
                9.08,
                9.097,
                9.116,
                9.134,
                9.152,
                9.171,
                9.189,
                9.208,
                9.227,
                9.246,
                9.265,
                9.285,
                9.304,
                9.323,
                9.343,
                9.363,
                9.383,
                9.403,
                9.423,
                9.443,
                9.463,
                9.484,
                9.504,
                9.525,
                9.545,
                9.566,
                9.587,
                9.607,
                9.628,
                9.649,
                9.67,
                9.691,
                9.712,
                9.734,
                9.755,
                9.776,
                9.798,
                9.819,
                9.84,
                9.861,
                9.883,
                9.904,
                9.925,
                9.947,
                9.968,
                9.989,
                10.011,
                10.032,
                10.053,
                10.074,
                10.096,
                10.117,
                10.138,
                10.159,
                10.18,
                10.201,
                10.222,
                10.243,
                10.264,
                10.285,
                10.306,
                10.327,
                10.348,
                10.368,
                10.389,
                10.409,
                10.43,
                10.451,
                10.471,
                10.492,
                10.512,
                10.532,
                10.553,
                10.573,
                10.593,
                10.613,
                10.633,
                10.653,
                10.673,
                10.693,
                10.713,
                10.733,
                10.753,
                10.773,
                10.793,
                10.813,
                10.832,
                10.852,
                10.872,
                10.892,
                10.911,
                10.931,
                10.951,
                10.97,
                10.99,
                11.009,
                11.029,
                11.048,
                11.068,
                11.087,
                11.106,
                11.126,
                11.145,
                11.165,
                11.184,
                11.203,
                11.222,
                11.242,
                11.261,
                11.281,
                11.3,
                11.319,
                11.338,
                11.357,
                11.377,
                11.396,
                11.415,
                11.435,
                11.454,
                11.473,
                11.492,
                11.512,
                11.531,
                11.55,
                11.57,
                11.589,
                11.609,
                11.628,
                11.648,
                11.667,
                11.686,
                11.706,
                11.725,
                11.745,
                11.765,
                11.784,
                11.804,
                11.824,
                11.843,
                11.863,
                11.883,
                11.903,
                11.923,
                11.942,
                11.962,
                11.982,
                12.003,
                12.023,
                12.043,
                12.063,
                12.083,
                12.104,
                12.124,
                12.144,
                12.165,
                12.186,
                12.206,
                12.227,
                12.248,
                12.268,
                12.289,
                12.31,
                12.331,
                12.352,
                12.373,
                12.394,
                12.415,
                12.436,
                12.458,
                12.479,
                12.5,
                12.522,
                12.543,
                12.565,
                12.586,
                12.608,
                12.63,
                12.651,
                12.673,
                12.695,
                12.717,
                12.739,
                12.76,
                12.783,
                12.805,
                12.827,
                12.849,
                12.871,
                12.893,
                12.915,
                12.938,
                12.96,
                12.983,
                13.005,
                13.027,
                13.05,
                13.073,
                13.095,
                13.118,
                13.141,
                13.164,
                13.187,
                13.209,
                13.232,
                13.255,
                13.278,
                13.301,
                13.324,
                13.348,
                13.371,
                13.394,
                13.417,
                13.441,
                13.464,
                13.487,
                13.511,
                13.534,
                13.558,
                13.581,
                13.605,
                13.629,
                13.652,
                13.676,
                13.7,
                13.724,
                13.748,
                13.772,
                13.796,
                13.82,
                13.844,
                13.868,
                13.892,
                13.916,
                13.941,
                13.965,
                13.989,
                14.014,
                14.039,
                14.063,
                14.088,
                14.112,
                14.137,
                14.162,
                14.186,
                14.211,
                14.236,
                14.261,
                14.286,
                14.311,
                14.336,
                14.361,
                14.386,
                14.411,
                14.437,
                14.462,
                14.487,
                14.513,
                14.538,
                14.564,
                14.589,
                14.615,
                14.641,
                14.666,
                14.692,
                14.718,
                14.744,
                14.77,
                14.796,
                14.822,
                14.848,
                14.875,
                14.901,
                14.927,
                14.954,
                14.98,
                15.007,
                15.033,
                15.06,
                15.087,
                15.114,
                15.141,
                15.167,
                15.195,
                15.222,
                15.249,
                15.276,
                15.303,
                15.331,
                15.358,
                15.386,
                15.414,
                15.441,
                15.469,
                15.496,
                15.524,
                15.552,
                15.58,
                15.608,
                15.636,
                15.664,
                15.692,
                15.72,
                15.748,
                15.777,
                15.805,
                15.833,
                15.862,
                15.89,
                15.919,
                15.947,
                15.976,
                16.004,
                16.033,
                16.062,
                16.09,
                16.119,
                16.148,
                16.177,
                16.206,
                16.235,
                16.264,
                16.293,
                16.322,
                16.351,
                16.38,
                16.409,
                16.438,
                16.468,
                16.497,
                16.526,
                16.556,
                16.585,
                16.614,
                16.644,
                16.673,
                16.703,
                16.732,
                16.761,
                16.791,
                16.82,
                16.85,
                16.88,
                16.909,
                16.939,
                16.968,
                16.998,
                17.028,
                17.057,
                17.087,
                17.117,
                17.146,
                17.176,
                17.206,
                17.235,
                17.265,
                17.295,
                17.325,
                17.354,
                17.384,
                17.414,
                17.444,
                17.473,
                17.503,
                17.533,
                17.562,
                17.592,
                17.622,
                17.652,
                17.681,
                17.711,
                17.741,
                17.771,
                17.801,
                17.83,
                17.86,
                17.89,
                17.919,
                17.949,
                17.979,
                18.008,
                18.038,
                18.068,
                18.098
            ],
            P3: [
                6.394,
                6.415,
                6.435,
                6.455,
                6.476,
                6.496,
                6.516,
                6.536,
                6.556,
                6.577,
                6.597,
                6.617,
                6.637,
                6.657,
                6.677,
                6.697,
                6.716,
                6.736,
                6.756,
                6.776,
                6.796,
                6.815,
                6.835,
                6.855,
                6.874,
                6.894,
                6.914,
                6.933,
                6.953,
                6.972,
                6.992,
                7.011,
                7.031,
                7.05,
                7.07,
                7.089,
                7.109,
                7.128,
                7.147,
                7.167,
                7.186,
                7.206,
                7.225,
                7.244,
                7.264,
                7.283,
                7.302,
                7.322,
                7.341,
                7.36,
                7.379,
                7.398,
                7.418,
                7.437,
                7.456,
                7.475,
                7.494,
                7.513,
                7.531,
                7.55,
                7.569,
                7.588,
                7.607,
                7.625,
                7.644,
                7.663,
                7.681,
                7.7,
                7.718,
                7.737,
                7.755,
                7.773,
                7.792,
                7.81,
                7.828,
                7.846,
                7.864,
                7.882,
                7.9,
                7.918,
                7.936,
                7.954,
                7.971,
                7.989,
                8.007,
                8.025,
                8.042,
                8.06,
                8.077,
                8.095,
                8.112,
                8.129,
                8.147,
                8.164,
                8.181,
                8.199,
                8.216,
                8.233,
                8.25,
                8.267,
                8.284,
                8.301,
                8.317,
                8.334,
                8.351,
                8.368,
                8.384,
                8.401,
                8.417,
                8.434,
                8.45,
                8.466,
                8.483,
                8.499,
                8.515,
                8.531,
                8.547,
                8.563,
                8.579,
                8.595,
                8.611,
                8.627,
                8.643,
                8.658,
                8.674,
                8.69,
                8.706,
                8.721,
                8.737,
                8.753,
                8.768,
                8.784,
                8.8,
                8.815,
                8.831,
                8.847,
                8.862,
                8.878,
                8.894,
                8.91,
                8.925,
                8.941,
                8.957,
                8.973,
                8.989,
                9.006,
                9.022,
                9.038,
                9.054,
                9.071,
                9.087,
                9.103,
                9.12,
                9.137,
                9.154,
                9.171,
                9.188,
                9.205,
                9.222,
                9.239,
                9.257,
                9.274,
                9.292,
                9.309,
                9.327,
                9.345,
                9.363,
                9.382,
                9.4,
                9.418,
                9.437,
                9.456,
                9.475,
                9.494,
                9.513,
                9.532,
                9.552,
                9.571,
                9.591,
                9.611,
                9.631,
                9.651,
                9.671,
                9.692,
                9.712,
                9.732,
                9.753,
                9.774,
                9.795,
                9.816,
                9.837,
                9.858,
                9.879,
                9.9,
                9.922,
                9.943,
                9.965,
                9.986,
                10.008,
                10.029,
                10.051,
                10.073,
                10.095,
                10.117,
                10.138,
                10.16,
                10.182,
                10.204,
                10.226,
                10.248,
                10.27,
                10.292,
                10.314,
                10.336,
                10.358,
                10.38,
                10.401,
                10.423,
                10.445,
                10.467,
                10.489,
                10.511,
                10.532,
                10.554,
                10.575,
                10.597,
                10.619,
                10.64,
                10.662,
                10.683,
                10.704,
                10.726,
                10.747,
                10.768,
                10.789,
                10.811,
                10.832,
                10.853,
                10.874,
                10.895,
                10.916,
                10.937,
                10.957,
                10.978,
                10.999,
                11.02,
                11.04,
                11.061,
                11.082,
                11.102,
                11.123,
                11.143,
                11.164,
                11.184,
                11.204,
                11.225,
                11.245,
                11.266,
                11.286,
                11.306,
                11.327,
                11.347,
                11.367,
                11.387,
                11.407,
                11.427,
                11.448,
                11.468,
                11.488,
                11.508,
                11.528,
                11.548,
                11.568,
                11.588,
                11.608,
                11.628,
                11.648,
                11.668,
                11.688,
                11.708,
                11.728,
                11.748,
                11.768,
                11.788,
                11.808,
                11.828,
                11.848,
                11.868,
                11.888,
                11.908,
                11.928,
                11.948,
                11.968,
                11.988,
                12.008,
                12.028,
                12.049,
                12.069,
                12.089,
                12.109,
                12.13,
                12.15,
                12.17,
                12.191,
                12.211,
                12.232,
                12.252,
                12.273,
                12.293,
                12.314,
                12.335,
                12.355,
                12.376,
                12.397,
                12.418,
                12.439,
                12.46,
                12.481,
                12.502,
                12.523,
                12.545,
                12.566,
                12.587,
                12.609,
                12.63,
                12.652,
                12.673,
                12.695,
                12.717,
                12.739,
                12.76,
                12.782,
                12.804,
                12.826,
                12.848,
                12.871,
                12.893,
                12.915,
                12.937,
                12.96,
                12.982,
                13.005,
                13.027,
                13.05,
                13.072,
                13.095,
                13.118,
                13.141,
                13.163,
                13.186,
                13.209,
                13.232,
                13.255,
                13.278,
                13.302,
                13.325,
                13.348,
                13.371,
                13.395,
                13.418,
                13.442,
                13.465,
                13.489,
                13.512,
                13.536,
                13.56,
                13.583,
                13.607,
                13.631,
                13.655,
                13.679,
                13.703,
                13.727,
                13.751,
                13.775,
                13.8,
                13.824,
                13.848,
                13.873,
                13.897,
                13.922,
                13.946,
                13.971,
                13.995,
                14.02,
                14.045,
                14.069,
                14.094,
                14.119,
                14.144,
                14.169,
                14.194,
                14.219,
                14.244,
                14.269,
                14.294,
                14.319,
                14.345,
                14.37,
                14.395,
                14.421,
                14.446,
                14.472,
                14.497,
                14.523,
                14.549,
                14.575,
                14.6,
                14.626,
                14.652,
                14.678,
                14.704,
                14.73,
                14.757,
                14.783,
                14.809,
                14.835,
                14.862,
                14.888,
                14.914,
                14.941,
                14.967,
                14.994,
                15.021,
                15.047,
                15.074,
                15.101,
                15.128,
                15.155,
                15.182,
                15.209,
                15.236,
                15.263,
                15.29,
                15.318,
                15.345,
                15.373,
                15.4,
                15.428,
                15.455,
                15.483,
                15.511,
                15.539,
                15.567,
                15.595,
                15.623,
                15.651,
                15.679,
                15.708,
                15.736,
                15.764,
                15.793,
                15.822,
                15.85,
                15.879,
                15.908,
                15.937,
                15.966,
                15.995,
                16.024,
                16.053,
                16.082,
                16.111,
                16.141,
                16.17,
                16.199,
                16.229,
                16.258,
                16.288,
                16.317,
                16.347,
                16.377,
                16.407,
                16.436,
                16.466,
                16.496,
                16.526,
                16.556,
                16.586,
                16.616,
                16.647,
                16.677,
                16.707,
                16.737,
                16.768,
                16.798,
                16.828,
                16.859,
                16.89,
                16.92,
                16.951,
                16.981,
                17.012,
                17.043,
                17.073,
                17.104,
                17.135,
                17.166,
                17.197,
                17.227,
                17.258,
                17.289,
                17.32,
                17.351,
                17.383,
                17.413,
                17.445,
                17.476,
                17.507,
                17.538,
                17.569,
                17.6,
                17.631,
                17.663,
                17.694,
                17.725,
                17.756,
                17.788,
                17.819,
                17.85,
                17.881,
                17.913,
                17.944,
                17.976,
                18.007,
                18.038,
                18.069,
                18.101,
                18.132,
                18.164,
                18.195,
                18.226,
                18.258,
                18.289,
                18.32,
                18.352,
                18.383,
                18.414,
                18.446,
                18.477,
                18.509,
                18.54,
                18.571,
                18.603,
                18.634,
                18.665,
                18.697,
                18.728,
                18.759,
                18.791,
                18.822
            ],
            P5: [
                6.513,
                6.534,
                6.555,
                6.575,
                6.596,
                6.617,
                6.637,
                6.658,
                6.678,
                6.699,
                6.719,
                6.74,
                6.76,
                6.781,
                6.801,
                6.821,
                6.841,
                6.862,
                6.882,
                6.902,
                6.922,
                6.942,
                6.962,
                6.982,
                7.002,
                7.022,
                7.042,
                7.062,
                7.082,
                7.102,
                7.122,
                7.142,
                7.162,
                7.182,
                7.201,
                7.221,
                7.241,
                7.261,
                7.281,
                7.3,
                7.32,
                7.34,
                7.36,
                7.379,
                7.399,
                7.419,
                7.439,
                7.458,
                7.478,
                7.497,
                7.517,
                7.537,
                7.556,
                7.575,
                7.595,
                7.614,
                7.634,
                7.653,
                7.672,
                7.691,
                7.711,
                7.73,
                7.749,
                7.768,
                7.787,
                7.806,
                7.825,
                7.844,
                7.863,
                7.881,
                7.9,
                7.919,
                7.938,
                7.956,
                7.975,
                7.993,
                8.012,
                8.03,
                8.048,
                8.066,
                8.085,
                8.103,
                8.121,
                8.139,
                8.157,
                8.175,
                8.193,
                8.211,
                8.229,
                8.247,
                8.264,
                8.282,
                8.3,
                8.318,
                8.335,
                8.353,
                8.37,
                8.388,
                8.405,
                8.422,
                8.44,
                8.457,
                8.474,
                8.491,
                8.508,
                8.525,
                8.542,
                8.559,
                8.576,
                8.593,
                8.609,
                8.626,
                8.642,
                8.659,
                8.675,
                8.692,
                8.708,
                8.725,
                8.741,
                8.757,
                8.773,
                8.789,
                8.805,
                8.822,
                8.838,
                8.854,
                8.87,
                8.886,
                8.901,
                8.918,
                8.933,
                8.949,
                8.965,
                8.981,
                8.997,
                9.013,
                9.029,
                9.045,
                9.061,
                9.077,
                9.093,
                9.11,
                9.126,
                9.142,
                9.158,
                9.175,
                9.191,
                9.208,
                9.225,
                9.241,
                9.258,
                9.275,
                9.292,
                9.309,
                9.326,
                9.343,
                9.36,
                9.377,
                9.395,
                9.412,
                9.43,
                9.448,
                9.466,
                9.484,
                9.502,
                9.52,
                9.539,
                9.557,
                9.576,
                9.595,
                9.614,
                9.633,
                9.652,
                9.671,
                9.691,
                9.71,
                9.73,
                9.75,
                9.77,
                9.79,
                9.81,
                9.831,
                9.851,
                9.872,
                9.893,
                9.913,
                9.935,
                9.956,
                9.977,
                9.998,
                10.019,
                10.041,
                10.062,
                10.084,
                10.106,
                10.127,
                10.149,
                10.171,
                10.193,
                10.215,
                10.237,
                10.259,
                10.281,
                10.303,
                10.326,
                10.348,
                10.37,
                10.392,
                10.414,
                10.437,
                10.459,
                10.481,
                10.504,
                10.526,
                10.548,
                10.57,
                10.592,
                10.615,
                10.637,
                10.659,
                10.681,
                10.703,
                10.725,
                10.747,
                10.769,
                10.791,
                10.813,
                10.835,
                10.857,
                10.878,
                10.9,
                10.922,
                10.943,
                10.965,
                10.987,
                11.008,
                11.03,
                11.051,
                11.072,
                11.094,
                11.115,
                11.136,
                11.157,
                11.178,
                11.199,
                11.22,
                11.241,
                11.263,
                11.283,
                11.304,
                11.325,
                11.346,
                11.367,
                11.388,
                11.408,
                11.429,
                11.45,
                11.471,
                11.491,
                11.512,
                11.533,
                11.553,
                11.574,
                11.594,
                11.615,
                11.635,
                11.656,
                11.676,
                11.697,
                11.717,
                11.738,
                11.758,
                11.778,
                11.799,
                11.819,
                11.84,
                11.86,
                11.88,
                11.901,
                11.921,
                11.941,
                11.962,
                11.982,
                12.002,
                12.023,
                12.043,
                12.064,
                12.084,
                12.104,
                12.125,
                12.145,
                12.166,
                12.186,
                12.207,
                12.227,
                12.248,
                12.269,
                12.289,
                12.31,
                12.331,
                12.351,
                12.372,
                12.393,
                12.414,
                12.434,
                12.456,
                12.477,
                12.497,
                12.519,
                12.54,
                12.561,
                12.582,
                12.603,
                12.624,
                12.646,
                12.667,
                12.689,
                12.71,
                12.732,
                12.754,
                12.775,
                12.797,
                12.819,
                12.841,
                12.863,
                12.885,
                12.907,
                12.929,
                12.951,
                12.974,
                12.996,
                13.018,
                13.041,
                13.063,
                13.086,
                13.109,
                13.131,
                13.154,
                13.177,
                13.2,
                13.223,
                13.246,
                13.269,
                13.292,
                13.315,
                13.339,
                13.362,
                13.385,
                13.408,
                13.432,
                13.455,
                13.479,
                13.503,
                13.526,
                13.55,
                13.574,
                13.598,
                13.622,
                13.645,
                13.67,
                13.694,
                13.718,
                13.742,
                13.766,
                13.79,
                13.815,
                13.839,
                13.864,
                13.888,
                13.913,
                13.937,
                13.962,
                13.986,
                14.011,
                14.036,
                14.061,
                14.086,
                14.111,
                14.136,
                14.161,
                14.186,
                14.211,
                14.236,
                14.261,
                14.287,
                14.312,
                14.337,
                14.363,
                14.388,
                14.414,
                14.439,
                14.465,
                14.491,
                14.516,
                14.542,
                14.568,
                14.594,
                14.62,
                14.646,
                14.672,
                14.698,
                14.724,
                14.75,
                14.777,
                14.803,
                14.829,
                14.856,
                14.882,
                14.909,
                14.935,
                14.962,
                14.989,
                15.016,
                15.043,
                15.069,
                15.096,
                15.123,
                15.15,
                15.177,
                15.204,
                15.232,
                15.259,
                15.286,
                15.314,
                15.341,
                15.369,
                15.396,
                15.424,
                15.451,
                15.479,
                15.507,
                15.535,
                15.563,
                15.591,
                15.619,
                15.647,
                15.675,
                15.703,
                15.732,
                15.76,
                15.789,
                15.817,
                15.846,
                15.875,
                15.903,
                15.932,
                15.961,
                15.99,
                16.019,
                16.048,
                16.078,
                16.107,
                16.137,
                16.166,
                16.195,
                16.225,
                16.255,
                16.285,
                16.314,
                16.344,
                16.374,
                16.404,
                16.434,
                16.464,
                16.494,
                16.525,
                16.555,
                16.585,
                16.616,
                16.646,
                16.677,
                16.707,
                16.738,
                16.769,
                16.799,
                16.83,
                16.861,
                16.892,
                16.922,
                16.953,
                16.985,
                17.016,
                17.047,
                17.078,
                17.109,
                17.14,
                17.171,
                17.203,
                17.234,
                17.266,
                17.297,
                17.328,
                17.36,
                17.392,
                17.423,
                17.455,
                17.486,
                17.518,
                17.55,
                17.582,
                17.613,
                17.645,
                17.677,
                17.709,
                17.741,
                17.773,
                17.805,
                17.837,
                17.869,
                17.901,
                17.933,
                17.965,
                17.997,
                18.029,
                18.061,
                18.093,
                18.125,
                18.158,
                18.19,
                18.222,
                18.254,
                18.286,
                18.318,
                18.351,
                18.383,
                18.415,
                18.447,
                18.48,
                18.512,
                18.544,
                18.576,
                18.608,
                18.641,
                18.673,
                18.705,
                18.738,
                18.77,
                18.802,
                18.834,
                18.867,
                18.899,
                18.931,
                18.963,
                18.996,
                19.028,
                19.06,
                19.093,
                19.125,
                19.157,
                19.189,
                19.222
            ],
            P10: [
                6.703,
                6.724,
                6.745,
                6.766,
                6.788,
                6.809,
                6.83,
                6.851,
                6.872,
                6.893,
                6.915,
                6.936,
                6.956,
                6.977,
                6.998,
                7.019,
                7.04,
                7.061,
                7.082,
                7.102,
                7.123,
                7.144,
                7.164,
                7.185,
                7.206,
                7.226,
                7.247,
                7.267,
                7.288,
                7.308,
                7.329,
                7.349,
                7.37,
                7.39,
                7.411,
                7.431,
                7.452,
                7.472,
                7.492,
                7.513,
                7.533,
                7.554,
                7.574,
                7.594,
                7.615,
                7.635,
                7.655,
                7.675,
                7.696,
                7.716,
                7.736,
                7.756,
                7.776,
                7.796,
                7.816,
                7.836,
                7.856,
                7.876,
                7.896,
                7.916,
                7.936,
                7.955,
                7.975,
                7.995,
                8.014,
                8.034,
                8.053,
                8.073,
                8.092,
                8.112,
                8.131,
                8.15,
                8.17,
                8.189,
                8.208,
                8.227,
                8.246,
                8.265,
                8.284,
                8.302,
                8.321,
                8.34,
                8.359,
                8.377,
                8.396,
                8.415,
                8.433,
                8.451,
                8.47,
                8.488,
                8.507,
                8.525,
                8.543,
                8.561,
                8.579,
                8.598,
                8.616,
                8.634,
                8.652,
                8.67,
                8.687,
                8.705,
                8.723,
                8.74,
                8.758,
                8.776,
                8.793,
                8.81,
                8.828,
                8.845,
                8.862,
                8.879,
                8.896,
                8.913,
                8.93,
                8.947,
                8.964,
                8.981,
                8.998,
                9.014,
                9.031,
                9.048,
                9.064,
                9.081,
                9.097,
                9.114,
                9.13,
                9.147,
                9.163,
                9.18,
                9.196,
                9.212,
                9.229,
                9.245,
                9.262,
                9.278,
                9.295,
                9.311,
                9.327,
                9.344,
                9.361,
                9.377,
                9.394,
                9.411,
                9.427,
                9.444,
                9.461,
                9.478,
                9.495,
                9.512,
                9.529,
                9.547,
                9.564,
                9.581,
                9.599,
                9.617,
                9.634,
                9.652,
                9.67,
                9.688,
                9.706,
                9.724,
                9.743,
                9.761,
                9.78,
                9.799,
                9.817,
                9.836,
                9.856,
                9.875,
                9.894,
                9.914,
                9.933,
                9.953,
                9.973,
                9.993,
                10.013,
                10.034,
                10.054,
                10.075,
                10.096,
                10.117,
                10.138,
                10.159,
                10.18,
                10.201,
                10.223,
                10.244,
                10.266,
                10.288,
                10.31,
                10.332,
                10.354,
                10.376,
                10.398,
                10.42,
                10.442,
                10.465,
                10.487,
                10.51,
                10.532,
                10.555,
                10.578,
                10.6,
                10.623,
                10.646,
                10.668,
                10.691,
                10.714,
                10.737,
                10.759,
                10.782,
                10.805,
                10.828,
                10.851,
                10.873,
                10.896,
                10.919,
                10.941,
                10.964,
                10.987,
                11.009,
                11.032,
                11.054,
                11.077,
                11.099,
                11.122,
                11.144,
                11.167,
                11.189,
                11.211,
                11.233,
                11.255,
                11.278,
                11.3,
                11.322,
                11.344,
                11.366,
                11.388,
                11.409,
                11.431,
                11.453,
                11.475,
                11.496,
                11.518,
                11.54,
                11.561,
                11.583,
                11.604,
                11.626,
                11.647,
                11.669,
                11.69,
                11.711,
                11.733,
                11.754,
                11.775,
                11.796,
                11.818,
                11.839,
                11.86,
                11.881,
                11.902,
                11.923,
                11.945,
                11.966,
                11.987,
                12.008,
                12.029,
                12.05,
                12.071,
                12.092,
                12.113,
                12.134,
                12.155,
                12.176,
                12.197,
                12.218,
                12.239,
                12.26,
                12.281,
                12.302,
                12.323,
                12.343,
                12.364,
                12.386,
                12.407,
                12.428,
                12.449,
                12.47,
                12.491,
                12.512,
                12.533,
                12.554,
                12.576,
                12.597,
                12.618,
                12.639,
                12.661,
                12.682,
                12.703,
                12.725,
                12.746,
                12.768,
                12.789,
                12.811,
                12.833,
                12.854,
                12.876,
                12.898,
                12.92,
                12.942,
                12.964,
                12.986,
                13.008,
                13.03,
                13.052,
                13.075,
                13.097,
                13.119,
                13.142,
                13.164,
                13.187,
                13.21,
                13.233,
                13.255,
                13.278,
                13.301,
                13.324,
                13.347,
                13.37,
                13.394,
                13.417,
                13.44,
                13.464,
                13.487,
                13.511,
                13.534,
                13.558,
                13.582,
                13.606,
                13.63,
                13.653,
                13.677,
                13.701,
                13.726,
                13.75,
                13.774,
                13.798,
                13.823,
                13.847,
                13.871,
                13.896,
                13.921,
                13.945,
                13.97,
                13.995,
                14.019,
                14.044,
                14.069,
                14.094,
                14.119,
                14.144,
                14.169,
                14.195,
                14.22,
                14.245,
                14.271,
                14.296,
                14.322,
                14.347,
                14.373,
                14.399,
                14.424,
                14.45,
                14.476,
                14.502,
                14.528,
                14.554,
                14.58,
                14.606,
                14.632,
                14.658,
                14.685,
                14.711,
                14.737,
                14.764,
                14.79,
                14.817,
                14.843,
                14.87,
                14.896,
                14.923,
                14.95,
                14.977,
                15.004,
                15.031,
                15.058,
                15.085,
                15.112,
                15.139,
                15.166,
                15.193,
                15.221,
                15.248,
                15.276,
                15.303,
                15.331,
                15.359,
                15.386,
                15.414,
                15.442,
                15.47,
                15.498,
                15.526,
                15.554,
                15.582,
                15.61,
                15.638,
                15.666,
                15.695,
                15.723,
                15.752,
                15.78,
                15.809,
                15.838,
                15.866,
                15.895,
                15.924,
                15.953,
                15.982,
                16.011,
                16.04,
                16.069,
                16.098,
                16.128,
                16.157,
                16.187,
                16.216,
                16.246,
                16.276,
                16.305,
                16.335,
                16.365,
                16.395,
                16.425,
                16.456,
                16.486,
                16.516,
                16.547,
                16.577,
                16.608,
                16.638,
                16.669,
                16.7,
                16.731,
                16.762,
                16.793,
                16.824,
                16.855,
                16.886,
                16.917,
                16.949,
                16.98,
                17.012,
                17.043,
                17.075,
                17.107,
                17.138,
                17.17,
                17.202,
                17.234,
                17.266,
                17.298,
                17.33,
                17.362,
                17.394,
                17.426,
                17.458,
                17.491,
                17.523,
                17.556,
                17.588,
                17.621,
                17.653,
                17.686,
                17.718,
                17.751,
                17.784,
                17.817,
                17.849,
                17.882,
                17.915,
                17.948,
                17.981,
                18.014,
                18.047,
                18.08,
                18.113,
                18.146,
                18.179,
                18.213,
                18.246,
                18.279,
                18.313,
                18.346,
                18.379,
                18.413,
                18.446,
                18.479,
                18.513,
                18.546,
                18.58,
                18.613,
                18.647,
                18.68,
                18.714,
                18.748,
                18.781,
                18.815,
                18.848,
                18.882,
                18.916,
                18.949,
                18.983,
                19.016,
                19.05,
                19.084,
                19.118,
                19.151,
                19.185,
                19.219,
                19.252,
                19.286,
                19.32,
                19.354,
                19.387,
                19.421,
                19.455,
                19.489,
                19.522,
                19.556,
                19.59,
                19.624,
                19.657,
                19.691,
                19.725,
                19.758,
                19.792,
                19.826,
                19.86
            ],
            P15: [
                6.834,
                6.856,
                6.878,
                6.9,
                6.921,
                6.943,
                6.964,
                6.986,
                7.008,
                7.029,
                7.051,
                7.072,
                7.093,
                7.115,
                7.136,
                7.157,
                7.179,
                7.2,
                7.221,
                7.242,
                7.263,
                7.284,
                7.305,
                7.326,
                7.347,
                7.368,
                7.389,
                7.41,
                7.431,
                7.452,
                7.473,
                7.494,
                7.515,
                7.536,
                7.557,
                7.577,
                7.598,
                7.619,
                7.64,
                7.661,
                7.682,
                7.702,
                7.723,
                7.744,
                7.765,
                7.785,
                7.806,
                7.827,
                7.847,
                7.868,
                7.889,
                7.909,
                7.93,
                7.95,
                7.971,
                7.991,
                8.011,
                8.032,
                8.052,
                8.072,
                8.092,
                8.112,
                8.133,
                8.153,
                8.173,
                8.193,
                8.213,
                8.233,
                8.252,
                8.272,
                8.292,
                8.312,
                8.331,
                8.351,
                8.37,
                8.39,
                8.409,
                8.429,
                8.448,
                8.467,
                8.486,
                8.505,
                8.524,
                8.543,
                8.562,
                8.581,
                8.6,
                8.619,
                8.638,
                8.657,
                8.675,
                8.694,
                8.713,
                8.731,
                8.75,
                8.768,
                8.787,
                8.805,
                8.824,
                8.842,
                8.86,
                8.878,
                8.896,
                8.914,
                8.932,
                8.95,
                8.968,
                8.986,
                9.003,
                9.021,
                9.039,
                9.056,
                9.073,
                9.091,
                9.108,
                9.126,
                9.143,
                9.16,
                9.177,
                9.194,
                9.211,
                9.228,
                9.245,
                9.262,
                9.279,
                9.295,
                9.312,
                9.329,
                9.346,
                9.363,
                9.379,
                9.396,
                9.413,
                9.429,
                9.446,
                9.463,
                9.48,
                9.496,
                9.513,
                9.53,
                9.547,
                9.564,
                9.581,
                9.598,
                9.615,
                9.632,
                9.649,
                9.666,
                9.684,
                9.701,
                9.719,
                9.736,
                9.754,
                9.772,
                9.79,
                9.807,
                9.825,
                9.844,
                9.862,
                9.88,
                9.899,
                9.917,
                9.936,
                9.955,
                9.974,
                9.993,
                10.012,
                10.031,
                10.051,
                10.07,
                10.09,
                10.11,
                10.13,
                10.15,
                10.17,
                10.19,
                10.211,
                10.232,
                10.253,
                10.273,
                10.295,
                10.316,
                10.337,
                10.359,
                10.38,
                10.402,
                10.424,
                10.445,
                10.468,
                10.49,
                10.512,
                10.534,
                10.557,
                10.579,
                10.601,
                10.624,
                10.647,
                10.67,
                10.692,
                10.715,
                10.738,
                10.761,
                10.784,
                10.807,
                10.83,
                10.853,
                10.876,
                10.899,
                10.922,
                10.946,
                10.969,
                10.992,
                11.015,
                11.038,
                11.061,
                11.084,
                11.108,
                11.131,
                11.154,
                11.177,
                11.2,
                11.223,
                11.246,
                11.268,
                11.291,
                11.314,
                11.337,
                11.36,
                11.382,
                11.405,
                11.428,
                11.45,
                11.473,
                11.495,
                11.518,
                11.54,
                11.563,
                11.585,
                11.607,
                11.629,
                11.652,
                11.674,
                11.696,
                11.718,
                11.74,
                11.762,
                11.784,
                11.806,
                11.828,
                11.849,
                11.871,
                11.893,
                11.915,
                11.936,
                11.958,
                11.98,
                12.002,
                12.023,
                12.045,
                12.067,
                12.088,
                12.11,
                12.131,
                12.153,
                12.174,
                12.196,
                12.217,
                12.239,
                12.26,
                12.282,
                12.303,
                12.324,
                12.346,
                12.367,
                12.389,
                12.41,
                12.431,
                12.453,
                12.474,
                12.495,
                12.517,
                12.538,
                12.56,
                12.581,
                12.603,
                12.624,
                12.645,
                12.667,
                12.689,
                12.71,
                12.732,
                12.753,
                12.775,
                12.796,
                12.818,
                12.84,
                12.862,
                12.883,
                12.905,
                12.927,
                12.949,
                12.971,
                12.993,
                13.015,
                13.037,
                13.059,
                13.081,
                13.103,
                13.125,
                13.148,
                13.17,
                13.193,
                13.215,
                13.238,
                13.26,
                13.283,
                13.306,
                13.328,
                13.351,
                13.374,
                13.397,
                13.42,
                13.443,
                13.467,
                13.49,
                13.513,
                13.537,
                13.56,
                13.584,
                13.608,
                13.631,
                13.655,
                13.679,
                13.703,
                13.727,
                13.751,
                13.775,
                13.799,
                13.824,
                13.848,
                13.872,
                13.897,
                13.921,
                13.946,
                13.971,
                13.995,
                14.02,
                14.045,
                14.07,
                14.095,
                14.12,
                14.145,
                14.17,
                14.195,
                14.221,
                14.246,
                14.271,
                14.297,
                14.322,
                14.348,
                14.374,
                14.399,
                14.425,
                14.451,
                14.477,
                14.503,
                14.529,
                14.555,
                14.581,
                14.607,
                14.633,
                14.66,
                14.686,
                14.712,
                14.739,
                14.765,
                14.792,
                14.819,
                14.845,
                14.872,
                14.899,
                14.926,
                14.953,
                14.98,
                15.007,
                15.034,
                15.061,
                15.088,
                15.115,
                15.143,
                15.17,
                15.197,
                15.225,
                15.252,
                15.28,
                15.308,
                15.335,
                15.363,
                15.391,
                15.419,
                15.447,
                15.475,
                15.503,
                15.531,
                15.559,
                15.587,
                15.616,
                15.644,
                15.672,
                15.701,
                15.729,
                15.758,
                15.787,
                15.816,
                15.844,
                15.873,
                15.902,
                15.931,
                15.96,
                15.989,
                16.018,
                16.047,
                16.077,
                16.106,
                16.135,
                16.165,
                16.194,
                16.224,
                16.254,
                16.283,
                16.313,
                16.343,
                16.373,
                16.403,
                16.433,
                16.463,
                16.494,
                16.524,
                16.555,
                16.585,
                16.616,
                16.646,
                16.677,
                16.708,
                16.739,
                16.77,
                16.801,
                16.832,
                16.863,
                16.894,
                16.926,
                16.958,
                16.989,
                17.021,
                17.052,
                17.084,
                17.116,
                17.148,
                17.18,
                17.212,
                17.244,
                17.276,
                17.309,
                17.341,
                17.373,
                17.406,
                17.438,
                17.471,
                17.504,
                17.536,
                17.569,
                17.602,
                17.635,
                17.668,
                17.701,
                17.734,
                17.767,
                17.8,
                17.833,
                17.866,
                17.9,
                17.933,
                17.966,
                18,
                18.033,
                18.067,
                18.1,
                18.134,
                18.168,
                18.202,
                18.235,
                18.269,
                18.303,
                18.337,
                18.371,
                18.405,
                18.439,
                18.473,
                18.507,
                18.541,
                18.575,
                18.61,
                18.644,
                18.678,
                18.712,
                18.747,
                18.781,
                18.815,
                18.85,
                18.884,
                18.919,
                18.953,
                18.988,
                19.022,
                19.057,
                19.091,
                19.126,
                19.16,
                19.195,
                19.229,
                19.264,
                19.299,
                19.333,
                19.368,
                19.403,
                19.437,
                19.472,
                19.507,
                19.542,
                19.576,
                19.611,
                19.646,
                19.681,
                19.715,
                19.75,
                19.785,
                19.82,
                19.854,
                19.889,
                19.924,
                19.959,
                19.994,
                20.028,
                20.063,
                20.098,
                20.133,
                20.167,
                20.202,
                20.237,
                20.272,
                20.306
            ],
            P25: [
                7.036,
                7.058,
                7.08,
                7.103,
                7.125,
                7.147,
                7.169,
                7.192,
                7.214,
                7.236,
                7.258,
                7.28,
                7.302,
                7.324,
                7.346,
                7.368,
                7.39,
                7.412,
                7.433,
                7.455,
                7.477,
                7.499,
                7.52,
                7.542,
                7.564,
                7.585,
                7.607,
                7.628,
                7.65,
                7.672,
                7.693,
                7.715,
                7.736,
                7.758,
                7.779,
                7.801,
                7.822,
                7.844,
                7.865,
                7.887,
                7.908,
                7.929,
                7.951,
                7.972,
                7.994,
                8.015,
                8.036,
                8.058,
                8.079,
                8.1,
                8.121,
                8.143,
                8.164,
                8.185,
                8.206,
                8.227,
                8.248,
                8.269,
                8.29,
                8.311,
                8.332,
                8.352,
                8.373,
                8.394,
                8.415,
                8.435,
                8.456,
                8.476,
                8.497,
                8.517,
                8.538,
                8.558,
                8.578,
                8.598,
                8.618,
                8.638,
                8.658,
                8.678,
                8.698,
                8.718,
                8.738,
                8.758,
                8.777,
                8.797,
                8.817,
                8.836,
                8.856,
                8.875,
                8.895,
                8.914,
                8.933,
                8.953,
                8.972,
                8.991,
                9.01,
                9.029,
                9.048,
                9.067,
                9.086,
                9.105,
                9.124,
                9.142,
                9.161,
                9.18,
                9.198,
                9.217,
                9.235,
                9.253,
                9.271,
                9.29,
                9.308,
                9.326,
                9.344,
                9.362,
                9.38,
                9.397,
                9.415,
                9.433,
                9.45,
                9.468,
                9.486,
                9.503,
                9.52,
                9.538,
                9.555,
                9.573,
                9.59,
                9.607,
                9.624,
                9.642,
                9.659,
                9.676,
                9.693,
                9.71,
                9.728,
                9.745,
                9.762,
                9.779,
                9.796,
                9.814,
                9.831,
                9.849,
                9.866,
                9.884,
                9.901,
                9.919,
                9.936,
                9.954,
                9.972,
                9.99,
                10.008,
                10.026,
                10.044,
                10.062,
                10.08,
                10.099,
                10.117,
                10.136,
                10.154,
                10.173,
                10.192,
                10.211,
                10.231,
                10.25,
                10.269,
                10.289,
                10.308,
                10.328,
                10.348,
                10.368,
                10.388,
                10.409,
                10.429,
                10.45,
                10.47,
                10.491,
                10.512,
                10.534,
                10.555,
                10.576,
                10.598,
                10.62,
                10.642,
                10.664,
                10.686,
                10.708,
                10.73,
                10.753,
                10.775,
                10.798,
                10.82,
                10.843,
                10.866,
                10.889,
                10.912,
                10.935,
                10.958,
                10.982,
                11.005,
                11.028,
                11.052,
                11.075,
                11.099,
                11.122,
                11.146,
                11.17,
                11.193,
                11.217,
                11.241,
                11.264,
                11.288,
                11.312,
                11.335,
                11.359,
                11.383,
                11.406,
                11.43,
                11.454,
                11.477,
                11.501,
                11.524,
                11.548,
                11.571,
                11.595,
                11.618,
                11.642,
                11.665,
                11.688,
                11.712,
                11.735,
                11.758,
                11.781,
                11.804,
                11.827,
                11.85,
                11.873,
                11.896,
                11.919,
                11.942,
                11.965,
                11.988,
                12.01,
                12.033,
                12.056,
                12.078,
                12.101,
                12.123,
                12.146,
                12.168,
                12.191,
                12.213,
                12.235,
                12.258,
                12.28,
                12.302,
                12.325,
                12.347,
                12.369,
                12.392,
                12.414,
                12.436,
                12.458,
                12.48,
                12.502,
                12.524,
                12.547,
                12.569,
                12.591,
                12.613,
                12.635,
                12.657,
                12.679,
                12.701,
                12.723,
                12.745,
                12.767,
                12.789,
                12.811,
                12.833,
                12.855,
                12.877,
                12.899,
                12.921,
                12.943,
                12.966,
                12.988,
                13.01,
                13.032,
                13.054,
                13.076,
                13.099,
                13.121,
                13.143,
                13.166,
                13.188,
                13.21,
                13.233,
                13.255,
                13.278,
                13.3,
                13.323,
                13.346,
                13.368,
                13.391,
                13.414,
                13.437,
                13.46,
                13.482,
                13.505,
                13.529,
                13.552,
                13.575,
                13.598,
                13.622,
                13.645,
                13.668,
                13.692,
                13.716,
                13.739,
                13.763,
                13.787,
                13.811,
                13.835,
                13.859,
                13.883,
                13.907,
                13.931,
                13.956,
                13.98,
                14.005,
                14.029,
                14.054,
                14.079,
                14.104,
                14.128,
                14.153,
                14.178,
                14.203,
                14.229,
                14.254,
                14.279,
                14.305,
                14.33,
                14.356,
                14.381,
                14.407,
                14.433,
                14.458,
                14.484,
                14.51,
                14.536,
                14.562,
                14.588,
                14.615,
                14.641,
                14.667,
                14.694,
                14.72,
                14.747,
                14.773,
                14.8,
                14.827,
                14.853,
                14.88,
                14.907,
                14.934,
                14.961,
                14.988,
                15.015,
                15.043,
                15.07,
                15.097,
                15.125,
                15.152,
                15.18,
                15.207,
                15.235,
                15.263,
                15.291,
                15.318,
                15.346,
                15.374,
                15.402,
                15.43,
                15.458,
                15.487,
                15.515,
                15.543,
                15.572,
                15.6,
                15.628,
                15.657,
                15.686,
                15.714,
                15.743,
                15.772,
                15.801,
                15.83,
                15.859,
                15.887,
                15.917,
                15.946,
                15.975,
                16.004,
                16.034,
                16.063,
                16.093,
                16.122,
                16.152,
                16.181,
                16.211,
                16.241,
                16.271,
                16.301,
                16.331,
                16.361,
                16.391,
                16.421,
                16.451,
                16.482,
                16.512,
                16.543,
                16.573,
                16.604,
                16.634,
                16.665,
                16.696,
                16.727,
                16.758,
                16.789,
                16.82,
                16.851,
                16.882,
                16.914,
                16.945,
                16.977,
                17.008,
                17.04,
                17.072,
                17.103,
                17.135,
                17.167,
                17.2,
                17.232,
                17.264,
                17.296,
                17.329,
                17.361,
                17.394,
                17.427,
                17.459,
                17.492,
                17.525,
                17.558,
                17.591,
                17.625,
                17.658,
                17.691,
                17.724,
                17.758,
                17.791,
                17.825,
                17.859,
                17.893,
                17.926,
                17.96,
                17.994,
                18.028,
                18.062,
                18.096,
                18.131,
                18.165,
                18.199,
                18.234,
                18.268,
                18.303,
                18.337,
                18.372,
                18.406,
                18.441,
                18.476,
                18.511,
                18.546,
                18.581,
                18.616,
                18.651,
                18.686,
                18.721,
                18.756,
                18.791,
                18.826,
                18.862,
                18.897,
                18.933,
                18.968,
                19.003,
                19.039,
                19.075,
                19.11,
                19.146,
                19.182,
                19.217,
                19.253,
                19.289,
                19.325,
                19.36,
                19.396,
                19.432,
                19.468,
                19.504,
                19.54,
                19.576,
                19.612,
                19.648,
                19.684,
                19.72,
                19.757,
                19.793,
                19.829,
                19.865,
                19.901,
                19.937,
                19.974,
                20.01,
                20.046,
                20.082,
                20.119,
                20.155,
                20.191,
                20.228,
                20.264,
                20.3,
                20.337,
                20.373,
                20.409,
                20.446,
                20.482,
                20.518,
                20.555,
                20.591,
                20.627,
                20.664,
                20.7,
                20.737,
                20.773,
                20.809,
                20.846,
                20.882,
                20.918,
                20.955,
                20.991
            ],
            P50: [
                7.433,
                7.456,
                7.48,
                7.503,
                7.527,
                7.55,
                7.574,
                7.597,
                7.621,
                7.644,
                7.667,
                7.691,
                7.714,
                7.737,
                7.76,
                7.783,
                7.807,
                7.83,
                7.853,
                7.876,
                7.899,
                7.922,
                7.945,
                7.967,
                7.99,
                8.013,
                8.036,
                8.059,
                8.082,
                8.104,
                8.127,
                8.15,
                8.173,
                8.196,
                8.218,
                8.241,
                8.264,
                8.287,
                8.309,
                8.332,
                8.355,
                8.377,
                8.4,
                8.423,
                8.445,
                8.468,
                8.491,
                8.513,
                8.536,
                8.558,
                8.581,
                8.603,
                8.626,
                8.648,
                8.67,
                8.693,
                8.715,
                8.737,
                8.759,
                8.782,
                8.804,
                8.826,
                8.848,
                8.87,
                8.892,
                8.914,
                8.935,
                8.957,
                8.979,
                9.001,
                9.022,
                9.044,
                9.065,
                9.087,
                9.108,
                9.129,
                9.15,
                9.172,
                9.193,
                9.214,
                9.235,
                9.256,
                9.277,
                9.297,
                9.318,
                9.339,
                9.36,
                9.38,
                9.401,
                9.422,
                9.442,
                9.463,
                9.483,
                9.503,
                9.524,
                9.544,
                9.564,
                9.584,
                9.604,
                9.624,
                9.644,
                9.664,
                9.684,
                9.703,
                9.723,
                9.743,
                9.762,
                9.781,
                9.801,
                9.82,
                9.839,
                9.858,
                9.877,
                9.896,
                9.915,
                9.934,
                9.953,
                9.972,
                9.99,
                10.009,
                10.027,
                10.046,
                10.064,
                10.083,
                10.101,
                10.119,
                10.138,
                10.156,
                10.174,
                10.192,
                10.211,
                10.229,
                10.247,
                10.265,
                10.283,
                10.301,
                10.319,
                10.338,
                10.356,
                10.374,
                10.392,
                10.411,
                10.429,
                10.448,
                10.466,
                10.485,
                10.503,
                10.522,
                10.541,
                10.559,
                10.578,
                10.597,
                10.616,
                10.635,
                10.654,
                10.674,
                10.693,
                10.713,
                10.732,
                10.752,
                10.772,
                10.792,
                10.812,
                10.832,
                10.852,
                10.873,
                10.893,
                10.914,
                10.935,
                10.956,
                10.977,
                10.999,
                11.02,
                11.042,
                11.063,
                11.085,
                11.107,
                11.129,
                11.152,
                11.174,
                11.197,
                11.219,
                11.242,
                11.265,
                11.288,
                11.311,
                11.335,
                11.358,
                11.382,
                11.405,
                11.429,
                11.453,
                11.477,
                11.501,
                11.525,
                11.549,
                11.573,
                11.598,
                11.622,
                11.646,
                11.671,
                11.695,
                11.72,
                11.744,
                11.769,
                11.794,
                11.818,
                11.843,
                11.868,
                11.893,
                11.917,
                11.942,
                11.967,
                11.992,
                12.016,
                12.041,
                12.066,
                12.091,
                12.115,
                12.14,
                12.165,
                12.189,
                12.214,
                12.238,
                12.263,
                12.287,
                12.312,
                12.336,
                12.36,
                12.385,
                12.409,
                12.433,
                12.457,
                12.482,
                12.506,
                12.53,
                12.554,
                12.578,
                12.602,
                12.626,
                12.65,
                12.673,
                12.697,
                12.721,
                12.745,
                12.768,
                12.792,
                12.816,
                12.839,
                12.863,
                12.886,
                12.91,
                12.933,
                12.957,
                12.98,
                13.004,
                13.027,
                13.051,
                13.074,
                13.098,
                13.121,
                13.144,
                13.168,
                13.191,
                13.214,
                13.238,
                13.261,
                13.284,
                13.308,
                13.331,
                13.354,
                13.377,
                13.401,
                13.424,
                13.447,
                13.471,
                13.494,
                13.517,
                13.54,
                13.564,
                13.587,
                13.61,
                13.634,
                13.657,
                13.681,
                13.704,
                13.728,
                13.751,
                13.775,
                13.798,
                13.822,
                13.845,
                13.869,
                13.893,
                13.917,
                13.94,
                13.964,
                13.988,
                14.012,
                14.036,
                14.06,
                14.084,
                14.108,
                14.133,
                14.157,
                14.181,
                14.206,
                14.23,
                14.254,
                14.279,
                14.304,
                14.328,
                14.353,
                14.378,
                14.403,
                14.428,
                14.453,
                14.479,
                14.504,
                14.529,
                14.555,
                14.58,
                14.606,
                14.632,
                14.657,
                14.683,
                14.709,
                14.735,
                14.761,
                14.788,
                14.814,
                14.84,
                14.867,
                14.893,
                14.92,
                14.947,
                14.974,
                15.001,
                15.028,
                15.055,
                15.082,
                15.109,
                15.136,
                15.164,
                15.191,
                15.219,
                15.246,
                15.274,
                15.302,
                15.33,
                15.358,
                15.386,
                15.414,
                15.442,
                15.47,
                15.499,
                15.527,
                15.555,
                15.584,
                15.613,
                15.641,
                15.67,
                15.699,
                15.728,
                15.757,
                15.786,
                15.815,
                15.844,
                15.873,
                15.903,
                15.932,
                15.962,
                15.991,
                16.021,
                16.05,
                16.08,
                16.11,
                16.14,
                16.17,
                16.2,
                16.23,
                16.26,
                16.29,
                16.32,
                16.351,
                16.381,
                16.412,
                16.442,
                16.473,
                16.504,
                16.534,
                16.565,
                16.596,
                16.627,
                16.658,
                16.689,
                16.72,
                16.751,
                16.783,
                16.814,
                16.845,
                16.877,
                16.908,
                16.94,
                16.972,
                17.004,
                17.036,
                17.067,
                17.1,
                17.132,
                17.164,
                17.196,
                17.228,
                17.261,
                17.293,
                17.326,
                17.358,
                17.391,
                17.424,
                17.457,
                17.489,
                17.522,
                17.555,
                17.589,
                17.622,
                17.655,
                17.688,
                17.722,
                17.755,
                17.789,
                17.823,
                17.856,
                17.89,
                17.924,
                17.958,
                17.992,
                18.027,
                18.061,
                18.095,
                18.13,
                18.165,
                18.199,
                18.234,
                18.269,
                18.304,
                18.339,
                18.374,
                18.409,
                18.445,
                18.48,
                18.516,
                18.551,
                18.587,
                18.623,
                18.659,
                18.695,
                18.731,
                18.767,
                18.803,
                18.84,
                18.876,
                18.912,
                18.949,
                18.986,
                19.022,
                19.059,
                19.096,
                19.133,
                19.17,
                19.207,
                19.244,
                19.281,
                19.318,
                19.356,
                19.393,
                19.43,
                19.468,
                19.506,
                19.543,
                19.581,
                19.619,
                19.656,
                19.694,
                19.732,
                19.77,
                19.808,
                19.846,
                19.884,
                19.923,
                19.961,
                19.999,
                20.037,
                20.076,
                20.114,
                20.153,
                20.191,
                20.23,
                20.268,
                20.307,
                20.346,
                20.385,
                20.423,
                20.462,
                20.501,
                20.54,
                20.579,
                20.618,
                20.657,
                20.696,
                20.735,
                20.774,
                20.813,
                20.852,
                20.892,
                20.931,
                20.97,
                21.009,
                21.049,
                21.088,
                21.127,
                21.167,
                21.206,
                21.245,
                21.285,
                21.324,
                21.364,
                21.403,
                21.443,
                21.482,
                21.522,
                21.561,
                21.601,
                21.64,
                21.68,
                21.719,
                21.759,
                21.798,
                21.838,
                21.878,
                21.917,
                21.957,
                21.996,
                22.036,
                22.076,
                22.115,
                22.155,
                22.195,
                22.234,
                22.274,
                22.313,
                22.353
            ],
            P75: [
                7.861,
                7.885,
                7.91,
                7.935,
                7.96,
                7.985,
                8.01,
                8.034,
                8.059,
                8.084,
                8.108,
                8.133,
                8.158,
                8.182,
                8.207,
                8.231,
                8.256,
                8.28,
                8.304,
                8.329,
                8.353,
                8.377,
                8.402,
                8.426,
                8.45,
                8.474,
                8.498,
                8.523,
                8.547,
                8.571,
                8.595,
                8.619,
                8.643,
                8.667,
                8.692,
                8.716,
                8.74,
                8.764,
                8.788,
                8.812,
                8.836,
                8.86,
                8.884,
                8.908,
                8.932,
                8.956,
                8.98,
                9.004,
                9.028,
                9.052,
                9.076,
                9.1,
                9.124,
                9.147,
                9.171,
                9.195,
                9.218,
                9.242,
                9.266,
                9.289,
                9.313,
                9.336,
                9.359,
                9.383,
                9.406,
                9.429,
                9.452,
                9.476,
                9.499,
                9.522,
                9.545,
                9.567,
                9.59,
                9.613,
                9.636,
                9.658,
                9.681,
                9.703,
                9.726,
                9.748,
                9.77,
                9.793,
                9.815,
                9.837,
                9.859,
                9.881,
                9.903,
                9.925,
                9.947,
                9.969,
                9.991,
                10.012,
                10.034,
                10.056,
                10.077,
                10.099,
                10.12,
                10.142,
                10.163,
                10.184,
                10.205,
                10.226,
                10.247,
                10.268,
                10.289,
                10.31,
                10.331,
                10.351,
                10.372,
                10.392,
                10.412,
                10.433,
                10.453,
                10.473,
                10.493,
                10.513,
                10.533,
                10.553,
                10.573,
                10.592,
                10.612,
                10.632,
                10.651,
                10.671,
                10.69,
                10.709,
                10.729,
                10.748,
                10.767,
                10.786,
                10.806,
                10.825,
                10.844,
                10.863,
                10.882,
                10.902,
                10.921,
                10.94,
                10.959,
                10.978,
                10.998,
                11.017,
                11.036,
                11.056,
                11.075,
                11.095,
                11.114,
                11.134,
                11.154,
                11.173,
                11.193,
                11.213,
                11.233,
                11.253,
                11.273,
                11.294,
                11.314,
                11.335,
                11.355,
                11.376,
                11.397,
                11.418,
                11.439,
                11.46,
                11.481,
                11.503,
                11.524,
                11.546,
                11.568,
                11.59,
                11.612,
                11.634,
                11.657,
                11.679,
                11.702,
                11.725,
                11.748,
                11.771,
                11.795,
                11.818,
                11.842,
                11.866,
                11.89,
                11.913,
                11.938,
                11.962,
                11.986,
                12.011,
                12.035,
                12.06,
                12.085,
                12.11,
                12.135,
                12.16,
                12.185,
                12.21,
                12.235,
                12.261,
                12.286,
                12.312,
                12.337,
                12.363,
                12.389,
                12.414,
                12.44,
                12.466,
                12.492,
                12.517,
                12.543,
                12.569,
                12.595,
                12.621,
                12.647,
                12.673,
                12.699,
                12.724,
                12.75,
                12.776,
                12.802,
                12.828,
                12.854,
                12.879,
                12.905,
                12.931,
                12.956,
                12.982,
                13.008,
                13.033,
                13.059,
                13.084,
                13.109,
                13.135,
                13.16,
                13.186,
                13.211,
                13.236,
                13.261,
                13.287,
                13.312,
                13.337,
                13.362,
                13.387,
                13.412,
                13.437,
                13.462,
                13.487,
                13.512,
                13.536,
                13.561,
                13.586,
                13.611,
                13.636,
                13.66,
                13.685,
                13.71,
                13.735,
                13.759,
                13.784,
                13.809,
                13.833,
                13.858,
                13.883,
                13.907,
                13.932,
                13.957,
                13.981,
                14.006,
                14.03,
                14.055,
                14.08,
                14.104,
                14.129,
                14.153,
                14.178,
                14.203,
                14.227,
                14.252,
                14.277,
                14.301,
                14.326,
                14.351,
                14.376,
                14.4,
                14.425,
                14.45,
                14.475,
                14.5,
                14.525,
                14.55,
                14.575,
                14.6,
                14.625,
                14.65,
                14.675,
                14.701,
                14.726,
                14.751,
                14.777,
                14.802,
                14.828,
                14.853,
                14.879,
                14.905,
                14.931,
                14.956,
                14.982,
                15.008,
                15.034,
                15.061,
                15.087,
                15.113,
                15.14,
                15.166,
                15.193,
                15.219,
                15.246,
                15.273,
                15.3,
                15.327,
                15.354,
                15.381,
                15.409,
                15.436,
                15.464,
                15.491,
                15.519,
                15.547,
                15.575,
                15.603,
                15.631,
                15.659,
                15.687,
                15.716,
                15.744,
                15.773,
                15.802,
                15.83,
                15.859,
                15.888,
                15.917,
                15.946,
                15.976,
                16.005,
                16.035,
                16.064,
                16.094,
                16.123,
                16.153,
                16.183,
                16.213,
                16.243,
                16.273,
                16.303,
                16.334,
                16.364,
                16.395,
                16.425,
                16.456,
                16.487,
                16.518,
                16.548,
                16.579,
                16.61,
                16.642,
                16.673,
                16.704,
                16.736,
                16.767,
                16.799,
                16.83,
                16.862,
                16.894,
                16.926,
                16.958,
                16.99,
                17.022,
                17.054,
                17.086,
                17.119,
                17.151,
                17.183,
                17.216,
                17.249,
                17.281,
                17.314,
                17.347,
                17.38,
                17.413,
                17.446,
                17.479,
                17.512,
                17.546,
                17.579,
                17.613,
                17.646,
                17.68,
                17.713,
                17.747,
                17.781,
                17.815,
                17.849,
                17.883,
                17.917,
                17.951,
                17.986,
                18.02,
                18.055,
                18.089,
                18.124,
                18.159,
                18.194,
                18.229,
                18.263,
                18.298,
                18.334,
                18.369,
                18.404,
                18.44,
                18.475,
                18.511,
                18.546,
                18.582,
                18.618,
                18.654,
                18.69,
                18.726,
                18.762,
                18.798,
                18.835,
                18.871,
                18.908,
                18.944,
                18.981,
                19.018,
                19.055,
                19.092,
                19.129,
                19.167,
                19.204,
                19.241,
                19.279,
                19.317,
                19.354,
                19.392,
                19.43,
                19.468,
                19.507,
                19.545,
                19.583,
                19.622,
                19.66,
                19.699,
                19.738,
                19.777,
                19.816,
                19.855,
                19.894,
                19.933,
                19.973,
                20.012,
                20.052,
                20.091,
                20.131,
                20.171,
                20.211,
                20.251,
                20.291,
                20.331,
                20.371,
                20.411,
                20.452,
                20.492,
                20.532,
                20.573,
                20.614,
                20.654,
                20.695,
                20.736,
                20.777,
                20.818,
                20.859,
                20.9,
                20.941,
                20.982,
                21.023,
                21.065,
                21.106,
                21.148,
                21.189,
                21.231,
                21.273,
                21.314,
                21.356,
                21.398,
                21.44,
                21.482,
                21.524,
                21.565,
                21.608,
                21.65,
                21.692,
                21.734,
                21.776,
                21.819,
                21.861,
                21.903,
                21.946,
                21.988,
                22.031,
                22.073,
                22.116,
                22.158,
                22.201,
                22.244,
                22.286,
                22.329,
                22.372,
                22.415,
                22.458,
                22.5,
                22.543,
                22.586,
                22.629,
                22.672,
                22.715,
                22.758,
                22.801,
                22.844,
                22.887,
                22.93,
                22.973,
                23.016,
                23.059,
                23.102,
                23.146,
                23.189,
                23.232,
                23.275,
                23.318,
                23.361,
                23.405,
                23.448,
                23.491,
                23.534,
                23.578,
                23.621,
                23.664,
                23.707,
                23.751,
                23.794,
                23.837
            ],
            P85: [
                8.104,
                8.13,
                8.155,
                8.181,
                8.206,
                8.232,
                8.258,
                8.283,
                8.308,
                8.334,
                8.359,
                8.385,
                8.41,
                8.435,
                8.461,
                8.486,
                8.511,
                8.536,
                8.561,
                8.587,
                8.612,
                8.637,
                8.662,
                8.687,
                8.712,
                8.737,
                8.762,
                8.786,
                8.811,
                8.836,
                8.861,
                8.886,
                8.911,
                8.936,
                8.961,
                8.986,
                9.011,
                9.035,
                9.06,
                9.085,
                9.11,
                9.135,
                9.16,
                9.185,
                9.209,
                9.234,
                9.259,
                9.284,
                9.309,
                9.333,
                9.358,
                9.382,
                9.407,
                9.431,
                9.456,
                9.48,
                9.505,
                9.529,
                9.554,
                9.578,
                9.602,
                9.626,
                9.65,
                9.675,
                9.699,
                9.723,
                9.747,
                9.771,
                9.794,
                9.818,
                9.842,
                9.866,
                9.889,
                9.913,
                9.936,
                9.96,
                9.983,
                10.006,
                10.029,
                10.052,
                10.075,
                10.098,
                10.121,
                10.144,
                10.167,
                10.19,
                10.213,
                10.235,
                10.258,
                10.28,
                10.303,
                10.325,
                10.348,
                10.37,
                10.392,
                10.415,
                10.437,
                10.459,
                10.481,
                10.503,
                10.525,
                10.547,
                10.568,
                10.59,
                10.611,
                10.633,
                10.654,
                10.675,
                10.697,
                10.718,
                10.739,
                10.76,
                10.781,
                10.801,
                10.822,
                10.843,
                10.863,
                10.884,
                10.904,
                10.924,
                10.945,
                10.965,
                10.985,
                11.005,
                11.025,
                11.045,
                11.065,
                11.085,
                11.105,
                11.125,
                11.145,
                11.164,
                11.184,
                11.204,
                11.224,
                11.243,
                11.263,
                11.283,
                11.303,
                11.323,
                11.342,
                11.362,
                11.382,
                11.402,
                11.422,
                11.442,
                11.462,
                11.483,
                11.503,
                11.523,
                11.543,
                11.564,
                11.585,
                11.605,
                11.626,
                11.647,
                11.668,
                11.689,
                11.71,
                11.731,
                11.752,
                11.774,
                11.796,
                11.817,
                11.839,
                11.861,
                11.883,
                11.906,
                11.928,
                11.951,
                11.973,
                11.996,
                12.019,
                12.042,
                12.066,
                12.089,
                12.113,
                12.137,
                12.161,
                12.185,
                12.209,
                12.233,
                12.258,
                12.282,
                12.307,
                12.332,
                12.357,
                12.382,
                12.407,
                12.432,
                12.458,
                12.483,
                12.509,
                12.535,
                12.56,
                12.586,
                12.612,
                12.638,
                12.664,
                12.69,
                12.716,
                12.743,
                12.769,
                12.795,
                12.822,
                12.848,
                12.874,
                12.901,
                12.927,
                12.954,
                12.98,
                13.007,
                13.033,
                13.06,
                13.086,
                13.113,
                13.139,
                13.166,
                13.192,
                13.219,
                13.245,
                13.272,
                13.298,
                13.324,
                13.351,
                13.377,
                13.403,
                13.429,
                13.455,
                13.481,
                13.508,
                13.534,
                13.56,
                13.586,
                13.612,
                13.638,
                13.663,
                13.689,
                13.715,
                13.741,
                13.767,
                13.792,
                13.818,
                13.844,
                13.869,
                13.895,
                13.921,
                13.946,
                13.971,
                13.997,
                14.023,
                14.048,
                14.073,
                14.099,
                14.124,
                14.15,
                14.175,
                14.201,
                14.226,
                14.251,
                14.277,
                14.302,
                14.328,
                14.353,
                14.378,
                14.403,
                14.429,
                14.454,
                14.48,
                14.505,
                14.53,
                14.556,
                14.581,
                14.606,
                14.632,
                14.657,
                14.683,
                14.708,
                14.734,
                14.759,
                14.785,
                14.81,
                14.836,
                14.862,
                14.887,
                14.913,
                14.939,
                14.964,
                14.99,
                15.016,
                15.042,
                15.068,
                15.094,
                15.12,
                15.146,
                15.172,
                15.199,
                15.225,
                15.251,
                15.278,
                15.304,
                15.331,
                15.357,
                15.384,
                15.411,
                15.438,
                15.465,
                15.492,
                15.519,
                15.546,
                15.573,
                15.601,
                15.628,
                15.656,
                15.683,
                15.711,
                15.739,
                15.767,
                15.795,
                15.823,
                15.851,
                15.88,
                15.908,
                15.937,
                15.965,
                15.994,
                16.023,
                16.052,
                16.081,
                16.111,
                16.14,
                16.169,
                16.199,
                16.228,
                16.258,
                16.288,
                16.318,
                16.348,
                16.378,
                16.408,
                16.438,
                16.469,
                16.499,
                16.53,
                16.561,
                16.591,
                16.622,
                16.653,
                16.684,
                16.715,
                16.747,
                16.778,
                16.81,
                16.841,
                16.873,
                16.905,
                16.936,
                16.968,
                17,
                17.033,
                17.065,
                17.097,
                17.129,
                17.162,
                17.194,
                17.227,
                17.26,
                17.292,
                17.325,
                17.358,
                17.392,
                17.425,
                17.458,
                17.491,
                17.525,
                17.558,
                17.592,
                17.625,
                17.659,
                17.693,
                17.727,
                17.761,
                17.795,
                17.829,
                17.863,
                17.897,
                17.932,
                17.966,
                18.001,
                18.035,
                18.07,
                18.105,
                18.139,
                18.174,
                18.209,
                18.244,
                18.28,
                18.315,
                18.35,
                18.385,
                18.421,
                18.456,
                18.492,
                18.528,
                18.564,
                18.6,
                18.636,
                18.672,
                18.708,
                18.744,
                18.781,
                18.817,
                18.854,
                18.89,
                18.927,
                18.964,
                19.001,
                19.038,
                19.075,
                19.112,
                19.149,
                19.187,
                19.224,
                19.262,
                19.299,
                19.337,
                19.375,
                19.413,
                19.451,
                19.489,
                19.527,
                19.566,
                19.604,
                19.642,
                19.681,
                19.72,
                19.759,
                19.798,
                19.837,
                19.876,
                19.915,
                19.955,
                19.994,
                20.034,
                20.074,
                20.113,
                20.153,
                20.193,
                20.234,
                20.274,
                20.314,
                20.355,
                20.395,
                20.436,
                20.477,
                20.518,
                20.559,
                20.6,
                20.641,
                20.682,
                20.724,
                20.765,
                20.807,
                20.848,
                20.89,
                20.932,
                20.974,
                21.016,
                21.058,
                21.1,
                21.142,
                21.184,
                21.227,
                21.269,
                21.312,
                21.354,
                21.397,
                21.44,
                21.483,
                21.525,
                21.568,
                21.611,
                21.654,
                21.698,
                21.741,
                21.784,
                21.827,
                21.871,
                21.914,
                21.958,
                22.001,
                22.045,
                22.089,
                22.132,
                22.176,
                22.22,
                22.264,
                22.308,
                22.352,
                22.396,
                22.441,
                22.485,
                22.529,
                22.573,
                22.617,
                22.662,
                22.706,
                22.751,
                22.795,
                22.84,
                22.884,
                22.929,
                22.974,
                23.018,
                23.063,
                23.108,
                23.153,
                23.197,
                23.242,
                23.287,
                23.332,
                23.377,
                23.422,
                23.467,
                23.512,
                23.557,
                23.602,
                23.647,
                23.692,
                23.737,
                23.783,
                23.828,
                23.873,
                23.918,
                23.963,
                24.009,
                24.054,
                24.099,
                24.145,
                24.19,
                24.235,
                24.28,
                24.326,
                24.371,
                24.417,
                24.462,
                24.507,
                24.553,
                24.598,
                24.644,
                24.689
            ],
            P90: [
                8.275,
                8.301,
                8.327,
                8.353,
                8.379,
                8.405,
                8.431,
                8.457,
                8.483,
                8.509,
                8.535,
                8.561,
                8.587,
                8.613,
                8.639,
                8.664,
                8.69,
                8.716,
                8.742,
                8.767,
                8.793,
                8.818,
                8.844,
                8.87,
                8.895,
                8.921,
                8.946,
                8.971,
                8.997,
                9.022,
                9.048,
                9.073,
                9.099,
                9.124,
                9.15,
                9.175,
                9.201,
                9.226,
                9.251,
                9.277,
                9.302,
                9.328,
                9.353,
                9.378,
                9.404,
                9.429,
                9.454,
                9.48,
                9.505,
                9.53,
                9.555,
                9.58,
                9.606,
                9.631,
                9.656,
                9.681,
                9.706,
                9.731,
                9.756,
                9.78,
                9.805,
                9.83,
                9.855,
                9.879,
                9.904,
                9.928,
                9.953,
                9.977,
                10.002,
                10.026,
                10.05,
                10.075,
                10.099,
                10.123,
                10.147,
                10.171,
                10.195,
                10.218,
                10.242,
                10.266,
                10.289,
                10.313,
                10.336,
                10.36,
                10.383,
                10.406,
                10.43,
                10.453,
                10.476,
                10.499,
                10.522,
                10.545,
                10.568,
                10.591,
                10.614,
                10.636,
                10.659,
                10.682,
                10.704,
                10.726,
                10.749,
                10.771,
                10.793,
                10.815,
                10.837,
                10.859,
                10.881,
                10.903,
                10.925,
                10.946,
                10.968,
                10.989,
                11.01,
                11.032,
                11.053,
                11.074,
                11.095,
                11.116,
                11.137,
                11.157,
                11.178,
                11.199,
                11.219,
                11.24,
                11.26,
                11.281,
                11.301,
                11.322,
                11.342,
                11.362,
                11.382,
                11.402,
                11.423,
                11.443,
                11.463,
                11.483,
                11.503,
                11.523,
                11.544,
                11.564,
                11.584,
                11.604,
                11.625,
                11.645,
                11.666,
                11.686,
                11.706,
                11.727,
                11.748,
                11.768,
                11.789,
                11.81,
                11.831,
                11.852,
                11.873,
                11.894,
                11.916,
                11.937,
                11.959,
                11.98,
                12.002,
                12.024,
                12.046,
                12.068,
                12.09,
                12.113,
                12.135,
                12.158,
                12.181,
                12.204,
                12.227,
                12.25,
                12.273,
                12.297,
                12.321,
                12.345,
                12.369,
                12.393,
                12.417,
                12.442,
                12.466,
                12.491,
                12.516,
                12.541,
                12.566,
                12.591,
                12.617,
                12.642,
                12.668,
                12.694,
                12.719,
                12.745,
                12.771,
                12.797,
                12.823,
                12.85,
                12.876,
                12.902,
                12.929,
                12.955,
                12.982,
                13.009,
                13.035,
                13.062,
                13.089,
                13.116,
                13.143,
                13.17,
                13.196,
                13.223,
                13.25,
                13.277,
                13.304,
                13.331,
                13.358,
                13.385,
                13.412,
                13.439,
                13.466,
                13.493,
                13.52,
                13.546,
                13.573,
                13.6,
                13.627,
                13.653,
                13.68,
                13.707,
                13.733,
                13.76,
                13.786,
                13.813,
                13.84,
                13.866,
                13.893,
                13.919,
                13.945,
                13.971,
                13.998,
                14.024,
                14.05,
                14.077,
                14.103,
                14.129,
                14.155,
                14.181,
                14.207,
                14.233,
                14.259,
                14.285,
                14.311,
                14.337,
                14.363,
                14.389,
                14.415,
                14.44,
                14.466,
                14.492,
                14.518,
                14.544,
                14.57,
                14.596,
                14.622,
                14.648,
                14.674,
                14.699,
                14.725,
                14.751,
                14.777,
                14.803,
                14.829,
                14.855,
                14.881,
                14.907,
                14.933,
                14.959,
                14.984,
                15.01,
                15.037,
                15.063,
                15.089,
                15.115,
                15.141,
                15.167,
                15.193,
                15.22,
                15.246,
                15.272,
                15.299,
                15.325,
                15.352,
                15.378,
                15.405,
                15.432,
                15.458,
                15.485,
                15.512,
                15.539,
                15.566,
                15.593,
                15.62,
                15.647,
                15.674,
                15.702,
                15.729,
                15.757,
                15.784,
                15.812,
                15.84,
                15.868,
                15.896,
                15.924,
                15.952,
                15.98,
                16.008,
                16.037,
                16.065,
                16.094,
                16.123,
                16.152,
                16.181,
                16.21,
                16.239,
                16.268,
                16.298,
                16.327,
                16.357,
                16.387,
                16.416,
                16.447,
                16.476,
                16.507,
                16.537,
                16.567,
                16.598,
                16.628,
                16.659,
                16.69,
                16.721,
                16.752,
                16.783,
                16.814,
                16.846,
                16.877,
                16.909,
                16.94,
                16.972,
                17.004,
                17.036,
                17.068,
                17.1,
                17.132,
                17.164,
                17.197,
                17.229,
                17.262,
                17.295,
                17.328,
                17.361,
                17.394,
                17.427,
                17.46,
                17.493,
                17.527,
                17.56,
                17.594,
                17.627,
                17.661,
                17.695,
                17.729,
                17.763,
                17.797,
                17.831,
                17.865,
                17.9,
                17.934,
                17.969,
                18.003,
                18.038,
                18.073,
                18.108,
                18.143,
                18.178,
                18.213,
                18.248,
                18.283,
                18.319,
                18.354,
                18.39,
                18.425,
                18.461,
                18.497,
                18.532,
                18.568,
                18.604,
                18.641,
                18.677,
                18.713,
                18.749,
                18.786,
                18.823,
                18.859,
                18.896,
                18.933,
                18.97,
                19.006,
                19.044,
                19.081,
                19.118,
                19.156,
                19.193,
                19.231,
                19.268,
                19.306,
                19.344,
                19.382,
                19.42,
                19.458,
                19.496,
                19.535,
                19.573,
                19.611,
                19.65,
                19.689,
                19.727,
                19.766,
                19.805,
                19.844,
                19.884,
                19.923,
                19.962,
                20.002,
                20.041,
                20.081,
                20.121,
                20.161,
                20.201,
                20.241,
                20.281,
                20.322,
                20.362,
                20.403,
                20.444,
                20.485,
                20.526,
                20.567,
                20.608,
                20.649,
                20.691,
                20.732,
                20.774,
                20.816,
                20.858,
                20.899,
                20.942,
                20.984,
                21.026,
                21.068,
                21.111,
                21.154,
                21.196,
                21.239,
                21.282,
                21.325,
                21.368,
                21.411,
                21.454,
                21.497,
                21.541,
                21.584,
                21.628,
                21.671,
                21.715,
                21.759,
                21.803,
                21.847,
                21.891,
                21.935,
                21.979,
                22.023,
                22.067,
                22.112,
                22.156,
                22.201,
                22.245,
                22.29,
                22.335,
                22.379,
                22.424,
                22.469,
                22.514,
                22.559,
                22.604,
                22.649,
                22.695,
                22.74,
                22.785,
                22.831,
                22.876,
                22.922,
                22.967,
                23.013,
                23.059,
                23.104,
                23.15,
                23.196,
                23.242,
                23.288,
                23.333,
                23.379,
                23.425,
                23.471,
                23.518,
                23.564,
                23.61,
                23.656,
                23.702,
                23.749,
                23.795,
                23.841,
                23.888,
                23.934,
                23.98,
                24.027,
                24.073,
                24.12,
                24.167,
                24.213,
                24.26,
                24.306,
                24.353,
                24.4,
                24.446,
                24.493,
                24.54,
                24.586,
                24.633,
                24.68,
                24.727,
                24.774,
                24.82,
                24.867,
                24.914,
                24.961,
                25.008,
                25.055,
                25.101,
                25.149,
                25.196,
                25.242,
                25.289
            ],
            P95: [
                8.537,
                8.564,
                8.591,
                8.618,
                8.644,
                8.671,
                8.698,
                8.725,
                8.752,
                8.779,
                8.805,
                8.832,
                8.859,
                8.885,
                8.912,
                8.939,
                8.965,
                8.992,
                9.018,
                9.045,
                9.071,
                9.098,
                9.124,
                9.15,
                9.177,
                9.203,
                9.229,
                9.255,
                9.282,
                9.308,
                9.334,
                9.361,
                9.387,
                9.413,
                9.439,
                9.466,
                9.492,
                9.518,
                9.544,
                9.571,
                9.597,
                9.623,
                9.65,
                9.676,
                9.702,
                9.728,
                9.754,
                9.78,
                9.807,
                9.833,
                9.859,
                9.885,
                9.911,
                9.937,
                9.962,
                9.988,
                10.014,
                10.04,
                10.066,
                10.091,
                10.117,
                10.143,
                10.168,
                10.194,
                10.219,
                10.244,
                10.27,
                10.295,
                10.32,
                10.346,
                10.371,
                10.396,
                10.42,
                10.445,
                10.47,
                10.495,
                10.52,
                10.544,
                10.569,
                10.593,
                10.617,
                10.642,
                10.666,
                10.69,
                10.715,
                10.739,
                10.763,
                10.787,
                10.811,
                10.834,
                10.858,
                10.882,
                10.906,
                10.929,
                10.953,
                10.977,
                11,
                11.023,
                11.047,
                11.07,
                11.093,
                11.116,
                11.139,
                11.162,
                11.184,
                11.207,
                11.23,
                11.252,
                11.275,
                11.297,
                11.319,
                11.341,
                11.363,
                11.385,
                11.407,
                11.429,
                11.451,
                11.472,
                11.494,
                11.515,
                11.537,
                11.558,
                11.579,
                11.6,
                11.622,
                11.643,
                11.664,
                11.685,
                11.706,
                11.726,
                11.747,
                11.768,
                11.789,
                11.81,
                11.83,
                11.851,
                11.872,
                11.893,
                11.914,
                11.934,
                11.955,
                11.976,
                11.997,
                12.018,
                12.039,
                12.06,
                12.081,
                12.102,
                12.124,
                12.145,
                12.166,
                12.188,
                12.209,
                12.231,
                12.253,
                12.274,
                12.296,
                12.318,
                12.34,
                12.363,
                12.385,
                12.408,
                12.43,
                12.453,
                12.476,
                12.499,
                12.522,
                12.545,
                12.568,
                12.592,
                12.616,
                12.64,
                12.664,
                12.688,
                12.712,
                12.737,
                12.761,
                12.786,
                12.811,
                12.836,
                12.861,
                12.887,
                12.912,
                12.938,
                12.964,
                12.99,
                13.016,
                13.042,
                13.068,
                13.094,
                13.121,
                13.147,
                13.174,
                13.201,
                13.227,
                13.254,
                13.281,
                13.308,
                13.335,
                13.363,
                13.39,
                13.417,
                13.444,
                13.472,
                13.499,
                13.527,
                13.554,
                13.582,
                13.61,
                13.637,
                13.665,
                13.692,
                13.72,
                13.747,
                13.775,
                13.803,
                13.83,
                13.858,
                13.886,
                13.913,
                13.941,
                13.968,
                13.995,
                14.023,
                14.051,
                14.078,
                14.105,
                14.133,
                14.16,
                14.187,
                14.214,
                14.242,
                14.269,
                14.296,
                14.323,
                14.35,
                14.377,
                14.404,
                14.431,
                14.458,
                14.485,
                14.512,
                14.539,
                14.566,
                14.593,
                14.619,
                14.646,
                14.673,
                14.7,
                14.726,
                14.753,
                14.78,
                14.806,
                14.833,
                14.86,
                14.887,
                14.913,
                14.94,
                14.967,
                14.993,
                15.02,
                15.047,
                15.073,
                15.1,
                15.127,
                15.153,
                15.18,
                15.207,
                15.233,
                15.26,
                15.287,
                15.313,
                15.34,
                15.367,
                15.394,
                15.421,
                15.447,
                15.474,
                15.501,
                15.528,
                15.555,
                15.582,
                15.609,
                15.636,
                15.663,
                15.69,
                15.717,
                15.745,
                15.772,
                15.799,
                15.827,
                15.854,
                15.882,
                15.909,
                15.937,
                15.965,
                15.993,
                16.021,
                16.048,
                16.077,
                16.105,
                16.133,
                16.161,
                16.19,
                16.218,
                16.246,
                16.275,
                16.304,
                16.332,
                16.361,
                16.39,
                16.419,
                16.449,
                16.478,
                16.507,
                16.537,
                16.566,
                16.596,
                16.626,
                16.656,
                16.686,
                16.717,
                16.747,
                16.777,
                16.808,
                16.838,
                16.869,
                16.9,
                16.931,
                16.962,
                16.993,
                17.025,
                17.056,
                17.088,
                17.12,
                17.151,
                17.183,
                17.215,
                17.248,
                17.28,
                17.312,
                17.345,
                17.378,
                17.41,
                17.443,
                17.476,
                17.509,
                17.542,
                17.575,
                17.609,
                17.642,
                17.676,
                17.709,
                17.743,
                17.777,
                17.811,
                17.845,
                17.88,
                17.914,
                17.948,
                17.983,
                18.017,
                18.052,
                18.087,
                18.122,
                18.157,
                18.192,
                18.227,
                18.262,
                18.298,
                18.333,
                18.369,
                18.405,
                18.44,
                18.476,
                18.512,
                18.548,
                18.584,
                18.62,
                18.657,
                18.693,
                18.73,
                18.766,
                18.803,
                18.84,
                18.876,
                18.914,
                18.95,
                18.988,
                19.025,
                19.062,
                19.099,
                19.137,
                19.174,
                19.212,
                19.25,
                19.288,
                19.325,
                19.363,
                19.402,
                19.44,
                19.478,
                19.516,
                19.555,
                19.594,
                19.632,
                19.671,
                19.71,
                19.749,
                19.788,
                19.827,
                19.866,
                19.906,
                19.945,
                19.985,
                20.024,
                20.064,
                20.104,
                20.144,
                20.184,
                20.224,
                20.265,
                20.305,
                20.345,
                20.386,
                20.426,
                20.468,
                20.508,
                20.549,
                20.59,
                20.632,
                20.673,
                20.714,
                20.756,
                20.798,
                20.84,
                20.881,
                20.924,
                20.966,
                21.008,
                21.051,
                21.093,
                21.136,
                21.178,
                21.222,
                21.265,
                21.308,
                21.351,
                21.394,
                21.438,
                21.481,
                21.525,
                21.569,
                21.613,
                21.657,
                21.701,
                21.745,
                21.79,
                21.834,
                21.879,
                21.924,
                21.968,
                22.013,
                22.058,
                22.103,
                22.148,
                22.194,
                22.239,
                22.284,
                22.33,
                22.376,
                22.421,
                22.467,
                22.513,
                22.559,
                22.605,
                22.651,
                22.697,
                22.744,
                22.79,
                22.836,
                22.883,
                22.929,
                22.976,
                23.023,
                23.069,
                23.116,
                23.163,
                23.21,
                23.257,
                23.305,
                23.352,
                23.399,
                23.446,
                23.494,
                23.541,
                23.589,
                23.636,
                23.684,
                23.732,
                23.78,
                23.827,
                23.875,
                23.923,
                23.971,
                24.019,
                24.067,
                24.115,
                24.163,
                24.211,
                24.26,
                24.308,
                24.357,
                24.405,
                24.453,
                24.502,
                24.55,
                24.599,
                24.648,
                24.696,
                24.745,
                24.793,
                24.842,
                24.891,
                24.939,
                24.988,
                25.037,
                25.086,
                25.135,
                25.184,
                25.233,
                25.282,
                25.331,
                25.38,
                25.429,
                25.478,
                25.527,
                25.576,
                25.625,
                25.674,
                25.723,
                25.772,
                25.822,
                25.871,
                25.92,
                25.969,
                26.019,
                26.068,
                26.117,
                26.167,
                26.216
            ],
            P97: [
                8.713,
                8.74,
                8.768,
                8.795,
                8.823,
                8.85,
                8.878,
                8.905,
                8.932,
                8.96,
                8.987,
                9.014,
                9.041,
                9.069,
                9.096,
                9.123,
                9.15,
                9.177,
                9.204,
                9.231,
                9.258,
                9.285,
                9.312,
                9.339,
                9.366,
                9.393,
                9.42,
                9.446,
                9.473,
                9.5,
                9.527,
                9.554,
                9.581,
                9.607,
                9.634,
                9.661,
                9.688,
                9.715,
                9.742,
                9.769,
                9.795,
                9.822,
                9.849,
                9.876,
                9.903,
                9.929,
                9.956,
                9.983,
                10.01,
                10.036,
                10.063,
                10.089,
                10.116,
                10.142,
                10.169,
                10.195,
                10.222,
                10.248,
                10.274,
                10.301,
                10.327,
                10.353,
                10.379,
                10.405,
                10.431,
                10.457,
                10.483,
                10.509,
                10.535,
                10.56,
                10.586,
                10.612,
                10.637,
                10.663,
                10.688,
                10.713,
                10.738,
                10.763,
                10.789,
                10.813,
                10.838,
                10.863,
                10.888,
                10.913,
                10.938,
                10.962,
                10.987,
                11.011,
                11.036,
                11.06,
                11.085,
                11.109,
                11.133,
                11.157,
                11.182,
                11.206,
                11.229,
                11.253,
                11.277,
                11.301,
                11.324,
                11.348,
                11.372,
                11.395,
                11.418,
                11.441,
                11.464,
                11.487,
                11.51,
                11.533,
                11.556,
                11.578,
                11.601,
                11.623,
                11.646,
                11.668,
                11.69,
                11.712,
                11.734,
                11.756,
                11.778,
                11.8,
                11.821,
                11.843,
                11.865,
                11.886,
                11.908,
                11.929,
                11.95,
                11.972,
                11.993,
                12.014,
                12.035,
                12.057,
                12.078,
                12.099,
                12.12,
                12.141,
                12.163,
                12.184,
                12.205,
                12.226,
                12.248,
                12.269,
                12.291,
                12.312,
                12.333,
                12.355,
                12.377,
                12.398,
                12.42,
                12.442,
                12.464,
                12.486,
                12.508,
                12.53,
                12.553,
                12.575,
                12.597,
                12.62,
                12.643,
                12.666,
                12.689,
                12.712,
                12.735,
                12.758,
                12.782,
                12.806,
                12.829,
                12.853,
                12.878,
                12.902,
                12.926,
                12.951,
                12.976,
                13.001,
                13.026,
                13.051,
                13.076,
                13.101,
                13.127,
                13.153,
                13.179,
                13.205,
                13.231,
                13.257,
                13.284,
                13.31,
                13.337,
                13.364,
                13.391,
                13.418,
                13.445,
                13.472,
                13.499,
                13.527,
                13.554,
                13.581,
                13.609,
                13.636,
                13.664,
                13.692,
                13.719,
                13.747,
                13.775,
                13.803,
                13.831,
                13.859,
                13.887,
                13.915,
                13.943,
                13.971,
                13.999,
                14.027,
                14.055,
                14.084,
                14.112,
                14.14,
                14.168,
                14.196,
                14.224,
                14.252,
                14.279,
                14.308,
                14.335,
                14.363,
                14.391,
                14.419,
                14.447,
                14.474,
                14.502,
                14.53,
                14.557,
                14.585,
                14.613,
                14.64,
                14.668,
                14.695,
                14.723,
                14.75,
                14.778,
                14.805,
                14.832,
                14.86,
                14.887,
                14.914,
                14.942,
                14.969,
                14.996,
                15.023,
                15.05,
                15.078,
                15.105,
                15.132,
                15.159,
                15.186,
                15.213,
                15.241,
                15.268,
                15.295,
                15.322,
                15.349,
                15.377,
                15.404,
                15.431,
                15.458,
                15.485,
                15.513,
                15.54,
                15.567,
                15.594,
                15.622,
                15.649,
                15.676,
                15.704,
                15.731,
                15.758,
                15.786,
                15.813,
                15.841,
                15.868,
                15.896,
                15.924,
                15.951,
                15.979,
                16.007,
                16.034,
                16.062,
                16.09,
                16.118,
                16.146,
                16.174,
                16.203,
                16.231,
                16.259,
                16.287,
                16.316,
                16.344,
                16.373,
                16.402,
                16.43,
                16.459,
                16.488,
                16.517,
                16.546,
                16.575,
                16.605,
                16.634,
                16.663,
                16.693,
                16.723,
                16.753,
                16.783,
                16.813,
                16.843,
                16.873,
                16.903,
                16.934,
                16.964,
                16.995,
                17.026,
                17.057,
                17.088,
                17.119,
                17.15,
                17.182,
                17.213,
                17.245,
                17.277,
                17.309,
                17.341,
                17.373,
                17.406,
                17.438,
                17.47,
                17.503,
                17.536,
                17.569,
                17.602,
                17.635,
                17.668,
                17.701,
                17.735,
                17.769,
                17.802,
                17.836,
                17.87,
                17.904,
                17.938,
                17.972,
                18.007,
                18.041,
                18.076,
                18.111,
                18.145,
                18.18,
                18.215,
                18.251,
                18.286,
                18.321,
                18.357,
                18.392,
                18.428,
                18.464,
                18.5,
                18.536,
                18.572,
                18.608,
                18.644,
                18.681,
                18.717,
                18.754,
                18.79,
                18.827,
                18.864,
                18.901,
                18.938,
                18.975,
                19.012,
                19.05,
                19.087,
                19.125,
                19.163,
                19.2,
                19.238,
                19.276,
                19.314,
                19.352,
                19.39,
                19.428,
                19.467,
                19.505,
                19.544,
                19.582,
                19.621,
                19.66,
                19.699,
                19.738,
                19.777,
                19.816,
                19.856,
                19.895,
                19.934,
                19.974,
                20.014,
                20.053,
                20.093,
                20.133,
                20.173,
                20.214,
                20.254,
                20.295,
                20.335,
                20.375,
                20.416,
                20.457,
                20.498,
                20.539,
                20.581,
                20.622,
                20.663,
                20.705,
                20.746,
                20.788,
                20.829,
                20.871,
                20.914,
                20.956,
                20.998,
                21.04,
                21.083,
                21.125,
                21.168,
                21.211,
                21.254,
                21.297,
                21.34,
                21.384,
                21.427,
                21.471,
                21.514,
                21.558,
                21.602,
                21.646,
                21.691,
                21.735,
                21.779,
                21.824,
                21.869,
                21.914,
                21.958,
                22.003,
                22.049,
                22.094,
                22.14,
                22.185,
                22.23,
                22.276,
                22.322,
                22.368,
                22.414,
                22.461,
                22.507,
                22.553,
                22.6,
                22.646,
                22.693,
                22.74,
                22.786,
                22.833,
                22.88,
                22.927,
                22.975,
                23.022,
                23.069,
                23.117,
                23.164,
                23.212,
                23.26,
                23.307,
                23.355,
                23.403,
                23.451,
                23.499,
                23.547,
                23.596,
                23.644,
                23.692,
                23.741,
                23.789,
                23.838,
                23.887,
                23.935,
                23.984,
                24.033,
                24.082,
                24.131,
                24.18,
                24.229,
                24.279,
                24.328,
                24.377,
                24.426,
                24.476,
                24.525,
                24.575,
                24.625,
                24.674,
                24.724,
                24.773,
                24.823,
                24.873,
                24.923,
                24.973,
                25.023,
                25.073,
                25.123,
                25.173,
                25.223,
                25.273,
                25.324,
                25.374,
                25.424,
                25.474,
                25.524,
                25.575,
                25.625,
                25.676,
                25.726,
                25.777,
                25.827,
                25.878,
                25.928,
                25.979,
                26.03,
                26.08,
                26.131,
                26.182,
                26.232,
                26.283,
                26.334,
                26.384,
                26.436,
                26.486,
                26.537,
                26.588,
                26.639,
                26.69,
                26.741,
                26.792,
                26.842
            ],
            P99: [
                9.059,
                9.088,
                9.117,
                9.145,
                9.173,
                9.202,
                9.23,
                9.259,
                9.287,
                9.316,
                9.344,
                9.372,
                9.401,
                9.429,
                9.457,
                9.485,
                9.514,
                9.542,
                9.57,
                9.598,
                9.626,
                9.654,
                9.682,
                9.71,
                9.738,
                9.766,
                9.794,
                9.822,
                9.85,
                9.878,
                9.906,
                9.934,
                9.962,
                9.989,
                10.017,
                10.045,
                10.073,
                10.101,
                10.129,
                10.157,
                10.185,
                10.213,
                10.241,
                10.269,
                10.297,
                10.325,
                10.352,
                10.38,
                10.408,
                10.436,
                10.464,
                10.491,
                10.519,
                10.547,
                10.574,
                10.602,
                10.63,
                10.657,
                10.684,
                10.712,
                10.739,
                10.766,
                10.794,
                10.821,
                10.848,
                10.875,
                10.902,
                10.929,
                10.956,
                10.983,
                11.009,
                11.036,
                11.063,
                11.089,
                11.116,
                11.142,
                11.168,
                11.195,
                11.221,
                11.247,
                11.273,
                11.299,
                11.325,
                11.351,
                11.376,
                11.402,
                11.428,
                11.453,
                11.479,
                11.504,
                11.53,
                11.555,
                11.58,
                11.605,
                11.631,
                11.656,
                11.681,
                11.706,
                11.73,
                11.755,
                11.78,
                11.805,
                11.829,
                11.853,
                11.877,
                11.901,
                11.926,
                11.95,
                11.973,
                11.997,
                12.021,
                12.044,
                12.068,
                12.091,
                12.115,
                12.138,
                12.161,
                12.184,
                12.207,
                12.23,
                12.252,
                12.275,
                12.298,
                12.32,
                12.343,
                12.365,
                12.387,
                12.41,
                12.432,
                12.454,
                12.476,
                12.498,
                12.52,
                12.542,
                12.564,
                12.586,
                12.608,
                12.63,
                12.652,
                12.674,
                12.696,
                12.718,
                12.741,
                12.763,
                12.785,
                12.807,
                12.829,
                12.852,
                12.874,
                12.897,
                12.919,
                12.942,
                12.964,
                12.987,
                13.01,
                13.033,
                13.056,
                13.079,
                13.103,
                13.126,
                13.149,
                13.173,
                13.197,
                13.221,
                13.245,
                13.269,
                13.293,
                13.318,
                13.342,
                13.367,
                13.392,
                13.417,
                13.442,
                13.467,
                13.493,
                13.519,
                13.545,
                13.571,
                13.597,
                13.623,
                13.649,
                13.676,
                13.703,
                13.73,
                13.757,
                13.784,
                13.811,
                13.838,
                13.866,
                13.894,
                13.921,
                13.949,
                13.977,
                14.005,
                14.033,
                14.061,
                14.089,
                14.117,
                14.146,
                14.174,
                14.203,
                14.231,
                14.26,
                14.289,
                14.317,
                14.346,
                14.375,
                14.404,
                14.433,
                14.462,
                14.49,
                14.52,
                14.548,
                14.577,
                14.606,
                14.635,
                14.664,
                14.693,
                14.722,
                14.751,
                14.78,
                14.808,
                14.837,
                14.866,
                14.895,
                14.923,
                14.952,
                14.981,
                15.01,
                15.038,
                15.067,
                15.096,
                15.124,
                15.153,
                15.181,
                15.21,
                15.238,
                15.267,
                15.295,
                15.324,
                15.352,
                15.38,
                15.408,
                15.437,
                15.465,
                15.493,
                15.522,
                15.549,
                15.578,
                15.606,
                15.634,
                15.662,
                15.69,
                15.719,
                15.747,
                15.775,
                15.803,
                15.831,
                15.86,
                15.888,
                15.916,
                15.944,
                15.973,
                16.001,
                16.029,
                16.057,
                16.085,
                16.114,
                16.142,
                16.17,
                16.199,
                16.227,
                16.256,
                16.284,
                16.312,
                16.341,
                16.369,
                16.398,
                16.426,
                16.455,
                16.484,
                16.512,
                16.541,
                16.57,
                16.599,
                16.628,
                16.657,
                16.686,
                16.715,
                16.744,
                16.773,
                16.803,
                16.832,
                16.861,
                16.891,
                16.921,
                16.95,
                16.98,
                17.01,
                17.04,
                17.07,
                17.1,
                17.13,
                17.161,
                17.191,
                17.222,
                17.252,
                17.283,
                17.314,
                17.345,
                17.376,
                17.407,
                17.439,
                17.47,
                17.501,
                17.533,
                17.565,
                17.597,
                17.629,
                17.661,
                17.693,
                17.726,
                17.759,
                17.791,
                17.824,
                17.857,
                17.89,
                17.924,
                17.957,
                17.99,
                18.024,
                18.058,
                18.092,
                18.125,
                18.16,
                18.194,
                18.228,
                18.263,
                18.298,
                18.333,
                18.368,
                18.402,
                18.438,
                18.473,
                18.509,
                18.544,
                18.58,
                18.616,
                18.652,
                18.688,
                18.724,
                18.76,
                18.797,
                18.833,
                18.87,
                18.907,
                18.943,
                18.981,
                19.018,
                19.055,
                19.093,
                19.129,
                19.168,
                19.205,
                19.243,
                19.281,
                19.319,
                19.357,
                19.395,
                19.433,
                19.472,
                19.511,
                19.549,
                19.588,
                19.627,
                19.666,
                19.705,
                19.745,
                19.784,
                19.823,
                19.863,
                19.902,
                19.942,
                19.981,
                20.022,
                20.061,
                20.102,
                20.142,
                20.182,
                20.223,
                20.263,
                20.304,
                20.344,
                20.385,
                20.426,
                20.467,
                20.508,
                20.549,
                20.59,
                20.632,
                20.674,
                20.715,
                20.757,
                20.799,
                20.841,
                20.882,
                20.925,
                20.967,
                21.01,
                21.052,
                21.095,
                21.138,
                21.18,
                21.223,
                21.266,
                21.309,
                21.353,
                21.396,
                21.44,
                21.484,
                21.528,
                21.571,
                21.615,
                21.659,
                21.703,
                21.748,
                21.793,
                21.837,
                21.882,
                21.926,
                21.972,
                22.017,
                22.062,
                22.107,
                22.153,
                22.198,
                22.244,
                22.29,
                22.336,
                22.383,
                22.429,
                22.475,
                22.522,
                22.568,
                22.615,
                22.662,
                22.709,
                22.757,
                22.804,
                22.852,
                22.899,
                22.947,
                22.995,
                23.042,
                23.091,
                23.139,
                23.187,
                23.236,
                23.284,
                23.333,
                23.382,
                23.431,
                23.48,
                23.529,
                23.579,
                23.628,
                23.677,
                23.727,
                23.776,
                23.826,
                23.876,
                23.926,
                23.976,
                24.026,
                24.076,
                24.127,
                24.177,
                24.227,
                24.278,
                24.329,
                24.379,
                24.43,
                24.481,
                24.532,
                24.583,
                24.634,
                24.686,
                24.737,
                24.788,
                24.84,
                24.892,
                24.943,
                24.995,
                25.046,
                25.099,
                25.15,
                25.202,
                25.254,
                25.306,
                25.359,
                25.411,
                25.463,
                25.515,
                25.568,
                25.62,
                25.673,
                25.726,
                25.778,
                25.831,
                25.884,
                25.936,
                25.989,
                26.043,
                26.096,
                26.148,
                26.201,
                26.254,
                26.308,
                26.361,
                26.414,
                26.468,
                26.521,
                26.574,
                26.627,
                26.681,
                26.735,
                26.788,
                26.842,
                26.895,
                26.949,
                27.002,
                27.057,
                27.11,
                27.164,
                27.218,
                27.271,
                27.326,
                27.379,
                27.433,
                27.487,
                27.541,
                27.595,
                27.649,
                27.703,
                27.757,
                27.811,
                27.865,
                27.92,
                27.974,
                28.028,
                28.082
            ],
            P999: [
                9.698,
                9.728,
                9.759,
                9.789,
                9.82,
                9.85,
                9.881,
                9.911,
                9.942,
                9.972,
                10.002,
                10.033,
                10.063,
                10.093,
                10.123,
                10.154,
                10.184,
                10.214,
                10.244,
                10.274,
                10.304,
                10.334,
                10.364,
                10.394,
                10.424,
                10.454,
                10.484,
                10.514,
                10.544,
                10.574,
                10.604,
                10.634,
                10.664,
                10.694,
                10.724,
                10.754,
                10.784,
                10.814,
                10.844,
                10.874,
                10.904,
                10.934,
                10.964,
                10.994,
                11.024,
                11.054,
                11.084,
                11.114,
                11.144,
                11.174,
                11.203,
                11.233,
                11.263,
                11.293,
                11.322,
                11.352,
                11.382,
                11.411,
                11.441,
                11.47,
                11.499,
                11.529,
                11.558,
                11.587,
                11.617,
                11.646,
                11.675,
                11.704,
                11.733,
                11.762,
                11.79,
                11.819,
                11.848,
                11.876,
                11.905,
                11.933,
                11.962,
                11.99,
                12.018,
                12.046,
                12.074,
                12.102,
                12.13,
                12.158,
                12.185,
                12.213,
                12.241,
                12.268,
                12.296,
                12.323,
                12.351,
                12.378,
                12.405,
                12.432,
                12.459,
                12.486,
                12.513,
                12.54,
                12.567,
                12.593,
                12.62,
                12.647,
                12.673,
                12.699,
                12.725,
                12.751,
                12.777,
                12.803,
                12.828,
                12.854,
                12.879,
                12.904,
                12.93,
                12.955,
                12.98,
                13.005,
                13.03,
                13.054,
                13.079,
                13.103,
                13.128,
                13.152,
                13.176,
                13.2,
                13.225,
                13.249,
                13.272,
                13.296,
                13.32,
                13.343,
                13.367,
                13.391,
                13.414,
                13.438,
                13.461,
                13.485,
                13.509,
                13.532,
                13.556,
                13.579,
                13.603,
                13.626,
                13.65,
                13.673,
                13.697,
                13.72,
                13.744,
                13.768,
                13.792,
                13.816,
                13.84,
                13.864,
                13.888,
                13.912,
                13.936,
                13.961,
                13.985,
                14.01,
                14.035,
                14.06,
                14.084,
                14.109,
                14.134,
                14.16,
                14.185,
                14.211,
                14.236,
                14.262,
                14.288,
                14.315,
                14.341,
                14.367,
                14.394,
                14.42,
                14.448,
                14.475,
                14.502,
                14.53,
                14.557,
                14.585,
                14.613,
                14.641,
                14.669,
                14.697,
                14.726,
                14.754,
                14.783,
                14.812,
                14.841,
                14.87,
                14.899,
                14.928,
                14.957,
                14.987,
                15.016,
                15.046,
                15.076,
                15.106,
                15.136,
                15.165,
                15.196,
                15.226,
                15.256,
                15.286,
                15.316,
                15.346,
                15.377,
                15.407,
                15.438,
                15.468,
                15.498,
                15.529,
                15.559,
                15.59,
                15.62,
                15.651,
                15.681,
                15.712,
                15.742,
                15.773,
                15.803,
                15.834,
                15.864,
                15.895,
                15.925,
                15.955,
                15.986,
                16.016,
                16.046,
                16.077,
                16.107,
                16.137,
                16.167,
                16.198,
                16.228,
                16.258,
                16.288,
                16.318,
                16.348,
                16.379,
                16.408,
                16.439,
                16.469,
                16.499,
                16.528,
                16.558,
                16.589,
                16.618,
                16.648,
                16.678,
                16.708,
                16.738,
                16.768,
                16.799,
                16.828,
                16.858,
                16.888,
                16.918,
                16.948,
                16.979,
                17.008,
                17.039,
                17.069,
                17.099,
                17.129,
                17.159,
                17.189,
                17.219,
                17.25,
                17.28,
                17.311,
                17.341,
                17.371,
                17.402,
                17.432,
                17.463,
                17.493,
                17.524,
                17.554,
                17.585,
                17.616,
                17.647,
                17.678,
                17.709,
                17.74,
                17.771,
                17.802,
                17.833,
                17.865,
                17.896,
                17.928,
                17.959,
                17.991,
                18.022,
                18.054,
                18.086,
                18.118,
                18.15,
                18.182,
                18.215,
                18.247,
                18.28,
                18.312,
                18.346,
                18.378,
                18.411,
                18.444,
                18.478,
                18.511,
                18.545,
                18.578,
                18.612,
                18.646,
                18.68,
                18.714,
                18.749,
                18.783,
                18.818,
                18.853,
                18.887,
                18.923,
                18.958,
                18.993,
                19.029,
                19.064,
                19.101,
                19.136,
                19.173,
                19.209,
                19.246,
                19.282,
                19.319,
                19.356,
                19.392,
                19.43,
                19.467,
                19.505,
                19.543,
                19.58,
                19.618,
                19.656,
                19.695,
                19.733,
                19.772,
                19.81,
                19.849,
                19.888,
                19.927,
                19.967,
                20.006,
                20.046,
                20.085,
                20.125,
                20.165,
                20.205,
                20.245,
                20.286,
                20.327,
                20.367,
                20.408,
                20.449,
                20.49,
                20.531,
                20.573,
                20.614,
                20.656,
                20.697,
                20.739,
                20.781,
                20.823,
                20.866,
                20.908,
                20.95,
                20.992,
                21.036,
                21.078,
                21.121,
                21.164,
                21.207,
                21.251,
                21.294,
                21.337,
                21.381,
                21.424,
                21.469,
                21.512,
                21.557,
                21.6,
                21.645,
                21.69,
                21.734,
                21.779,
                21.823,
                21.868,
                21.913,
                21.958,
                22.004,
                22.049,
                22.094,
                22.14,
                22.186,
                22.231,
                22.277,
                22.324,
                22.37,
                22.416,
                22.462,
                22.509,
                22.556,
                22.603,
                22.65,
                22.697,
                22.744,
                22.791,
                22.839,
                22.886,
                22.934,
                22.982,
                23.031,
                23.079,
                23.127,
                23.175,
                23.224,
                23.272,
                23.321,
                23.37,
                23.42,
                23.469,
                23.518,
                23.567,
                23.617,
                23.667,
                23.717,
                23.767,
                23.818,
                23.868,
                23.918,
                23.969,
                24.02,
                24.071,
                24.122,
                24.173,
                24.225,
                24.277,
                24.329,
                24.381,
                24.433,
                24.485,
                24.537,
                24.59,
                24.643,
                24.695,
                24.749,
                24.801,
                24.855,
                24.908,
                24.961,
                25.015,
                25.069,
                25.123,
                25.177,
                25.232,
                25.286,
                25.34,
                25.395,
                25.449,
                25.504,
                25.559,
                25.613,
                25.669,
                25.724,
                25.779,
                25.835,
                25.89,
                25.946,
                26.002,
                26.057,
                26.113,
                26.17,
                26.225,
                26.281,
                26.337,
                26.394,
                26.451,
                26.507,
                26.564,
                26.621,
                26.678,
                26.734,
                26.791,
                26.849,
                26.906,
                26.963,
                27.02,
                27.079,
                27.136,
                27.193,
                27.251,
                27.309,
                27.367,
                27.425,
                27.483,
                27.541,
                27.599,
                27.657,
                27.716,
                27.774,
                27.832,
                27.891,
                27.949,
                28.008,
                28.066,
                28.126,
                28.184,
                28.243,
                28.302,
                28.361,
                28.42,
                28.48,
                28.538,
                28.598,
                28.657,
                28.716,
                28.775,
                28.834,
                28.895,
                28.954,
                29.013,
                29.073,
                29.132,
                29.192,
                29.252,
                29.312,
                29.371,
                29.431,
                29.491,
                29.552,
                29.611,
                29.671,
                29.731,
                29.791,
                29.851,
                29.912,
                29.972,
                30.032,
                30.092,
                30.152,
                30.213,
                30.273,
                30.333,
                30.393
            ]
        },
        girls: {
            P01: [
                5.541,
                5.559,
                5.576,
                5.593,
                5.611,
                5.628,
                5.645,
                5.662,
                5.679,
                5.696,
                5.713,
                5.73,
                5.747,
                5.764,
                5.781,
                5.798,
                5.815,
                5.831,
                5.848,
                5.865,
                5.881,
                5.898,
                5.915,
                5.931,
                5.948,
                5.964,
                5.981,
                5.997,
                6.013,
                6.03,
                6.046,
                6.062,
                6.079,
                6.095,
                6.111,
                6.127,
                6.143,
                6.159,
                6.175,
                6.192,
                6.207,
                6.223,
                6.239,
                6.255,
                6.271,
                6.287,
                6.303,
                6.319,
                6.335,
                6.35,
                6.366,
                6.382,
                6.398,
                6.414,
                6.429,
                6.445,
                6.461,
                6.476,
                6.492,
                6.508,
                6.523,
                6.539,
                6.555,
                6.57,
                6.586,
                6.601,
                6.617,
                6.632,
                6.648,
                6.663,
                6.678,
                6.694,
                6.709,
                6.724,
                6.74,
                6.755,
                6.77,
                6.785,
                6.801,
                6.816,
                6.831,
                6.846,
                6.861,
                6.876,
                6.891,
                6.905,
                6.92,
                6.935,
                6.95,
                6.965,
                6.979,
                6.994,
                7.009,
                7.023,
                7.038,
                7.052,
                7.067,
                7.081,
                7.096,
                7.11,
                7.125,
                7.139,
                7.153,
                7.168,
                7.182,
                7.196,
                7.211,
                7.225,
                7.239,
                7.254,
                7.268,
                7.282,
                7.297,
                7.311,
                7.325,
                7.34,
                7.354,
                7.368,
                7.383,
                7.397,
                7.412,
                7.426,
                7.441,
                7.456,
                7.47,
                7.485,
                7.5,
                7.515,
                7.529,
                7.544,
                7.559,
                7.574,
                7.589,
                7.605,
                7.62,
                7.635,
                7.65,
                7.666,
                7.681,
                7.696,
                7.712,
                7.728,
                7.743,
                7.759,
                7.775,
                7.791,
                7.807,
                7.823,
                7.839,
                7.855,
                7.872,
                7.888,
                7.905,
                7.921,
                7.938,
                7.955,
                7.971,
                7.988,
                8.005,
                8.022,
                8.039,
                8.056,
                8.073,
                8.091,
                8.108,
                8.126,
                8.143,
                8.16,
                8.178,
                8.196,
                8.214,
                8.232,
                8.249,
                8.267,
                8.285,
                8.303,
                8.322,
                8.34,
                8.358,
                8.377,
                8.395,
                8.413,
                8.432,
                8.45,
                8.469,
                8.488,
                8.506,
                8.525,
                8.544,
                8.563,
                8.582,
                8.601,
                8.62,
                8.639,
                8.658,
                8.677,
                8.696,
                8.715,
                8.734,
                8.754,
                8.773,
                8.792,
                8.811,
                8.831,
                8.85,
                8.87,
                8.889,
                8.908,
                8.928,
                8.947,
                8.966,
                8.986,
                9.005,
                9.025,
                9.044,
                9.064,
                9.083,
                9.102,
                9.122,
                9.141,
                9.16,
                9.18,
                9.199,
                9.218,
                9.237,
                9.257,
                9.276,
                9.295,
                9.315,
                9.334,
                9.353,
                9.372,
                9.391,
                9.411,
                9.43,
                9.449,
                9.468,
                9.487,
                9.506,
                9.525,
                9.544,
                9.563,
                9.582,
                9.601,
                9.62,
                9.639,
                9.658,
                9.677,
                9.696,
                9.715,
                9.733,
                9.752,
                9.771,
                9.79,
                9.809,
                9.828,
                9.846,
                9.865,
                9.884,
                9.903,
                9.922,
                9.94,
                9.959,
                9.978,
                9.997,
                10.015,
                10.034,
                10.053,
                10.072,
                10.09,
                10.109,
                10.128,
                10.147,
                10.165,
                10.184,
                10.203,
                10.221,
                10.24,
                10.259,
                10.278,
                10.296,
                10.315,
                10.334,
                10.352,
                10.371,
                10.39,
                10.409,
                10.427,
                10.446,
                10.465,
                10.484,
                10.503,
                10.521,
                10.54,
                10.559,
                10.578,
                10.597,
                10.616,
                10.635,
                10.654,
                10.673,
                10.692,
                10.711,
                10.73,
                10.749,
                10.768,
                10.787,
                10.806,
                10.825,
                10.844,
                10.863,
                10.882,
                10.901,
                10.921,
                10.94,
                10.959,
                10.978,
                10.998,
                11.017,
                11.037,
                11.056,
                11.075,
                11.095,
                11.114,
                11.134,
                11.153,
                11.173,
                11.193,
                11.212,
                11.232,
                11.252,
                11.272,
                11.292,
                11.312,
                11.332,
                11.352,
                11.372,
                11.392,
                11.412,
                11.433,
                11.453,
                11.474,
                11.494,
                11.515,
                11.535,
                11.556,
                11.577,
                11.597,
                11.618,
                11.639,
                11.66,
                11.681,
                11.702,
                11.724,
                11.745,
                11.766,
                11.787,
                11.809,
                11.83,
                11.852,
                11.874,
                11.895,
                11.917,
                11.939,
                11.961,
                11.983,
                12.005,
                12.027,
                12.05,
                12.072,
                12.094,
                12.116,
                12.139,
                12.161,
                12.184,
                12.207,
                12.23,
                12.252,
                12.275,
                12.298,
                12.321,
                12.344,
                12.368,
                12.391,
                12.414,
                12.437,
                12.461,
                12.485,
                12.508,
                12.532,
                12.555,
                12.579,
                12.603,
                12.627,
                12.651,
                12.675,
                12.699,
                12.723,
                12.747,
                12.772,
                12.796,
                12.82,
                12.845,
                12.869,
                12.894,
                12.919,
                12.944,
                12.969,
                12.994,
                13.019,
                13.044,
                13.069,
                13.094,
                13.12,
                13.145,
                13.171,
                13.197,
                13.222,
                13.248,
                13.274,
                13.3,
                13.326,
                13.352,
                13.378,
                13.405,
                13.431,
                13.457,
                13.484,
                13.511,
                13.537,
                13.564,
                13.59,
                13.618,
                13.645,
                13.672,
                13.699,
                13.726,
                13.753,
                13.781,
                13.808,
                13.835,
                13.863,
                13.891,
                13.919,
                13.946,
                13.974,
                14.002,
                14.03,
                14.058,
                14.086,
                14.114,
                14.143,
                14.171,
                14.199,
                14.228,
                14.256,
                14.285,
                14.313,
                14.342,
                14.37,
                14.4,
                14.428,
                14.457,
                14.486,
                14.515,
                14.544,
                14.573,
                14.603,
                14.632,
                14.661,
                14.69,
                14.72,
                14.749,
                14.779,
                14.808,
                14.838,
                14.867,
                14.897,
                14.927,
                14.957,
                14.987,
                15.017,
                15.046,
                15.076,
                15.106,
                15.136,
                15.166,
                15.196,
                15.227,
                15.257,
                15.287,
                15.317,
                15.347,
                15.378,
                15.408,
                15.438,
                15.469,
                15.499,
                15.529,
                15.56,
                15.59,
                15.621,
                15.651,
                15.682,
                15.712,
                15.743,
                15.773,
                15.804,
                15.834,
                15.865,
                15.896,
                15.926,
                15.957,
                15.987,
                16.018,
                16.049,
                16.079,
                16.109,
                16.14,
                16.171,
                16.201,
                16.232,
                16.263,
                16.293,
                16.324,
                16.355,
                16.385,
                16.416,
                16.447,
                16.477,
                16.508,
                16.539,
                16.569,
                16.6,
                16.631,
                16.661,
                16.692,
                16.723,
                16.753,
                16.783,
                16.814,
                16.845,
                16.875,
                16.906,
                16.936,
                16.967,
                16.997,
                17.028,
                17.059,
                17.089,
                17.12
            ],
            P1: [
                5.905,
                5.924,
                5.942,
                5.96,
                5.979,
                5.997,
                6.015,
                6.034,
                6.052,
                6.07,
                6.088,
                6.106,
                6.124,
                6.142,
                6.16,
                6.178,
                6.196,
                6.214,
                6.231,
                6.249,
                6.267,
                6.284,
                6.302,
                6.32,
                6.337,
                6.355,
                6.372,
                6.39,
                6.407,
                6.424,
                6.442,
                6.459,
                6.476,
                6.494,
                6.511,
                6.528,
                6.545,
                6.562,
                6.579,
                6.596,
                6.613,
                6.63,
                6.647,
                6.664,
                6.681,
                6.698,
                6.715,
                6.732,
                6.748,
                6.765,
                6.782,
                6.799,
                6.816,
                6.832,
                6.849,
                6.866,
                6.882,
                6.899,
                6.916,
                6.932,
                6.949,
                6.965,
                6.982,
                6.999,
                7.015,
                7.031,
                7.048,
                7.064,
                7.081,
                7.097,
                7.113,
                7.13,
                7.146,
                7.162,
                7.178,
                7.195,
                7.211,
                7.227,
                7.243,
                7.259,
                7.275,
                7.291,
                7.307,
                7.323,
                7.339,
                7.354,
                7.37,
                7.386,
                7.402,
                7.417,
                7.433,
                7.448,
                7.464,
                7.479,
                7.495,
                7.51,
                7.526,
                7.541,
                7.556,
                7.572,
                7.587,
                7.602,
                7.617,
                7.632,
                7.648,
                7.663,
                7.678,
                7.693,
                7.708,
                7.724,
                7.739,
                7.754,
                7.769,
                7.784,
                7.8,
                7.815,
                7.83,
                7.845,
                7.861,
                7.876,
                7.891,
                7.907,
                7.922,
                7.938,
                7.953,
                7.969,
                7.985,
                8,
                8.016,
                8.032,
                8.048,
                8.064,
                8.08,
                8.096,
                8.112,
                8.128,
                8.144,
                8.16,
                8.177,
                8.193,
                8.21,
                8.226,
                8.243,
                8.259,
                8.276,
                8.293,
                8.31,
                8.327,
                8.344,
                8.362,
                8.379,
                8.396,
                8.414,
                8.431,
                8.449,
                8.467,
                8.485,
                8.502,
                8.52,
                8.538,
                8.556,
                8.575,
                8.593,
                8.611,
                8.63,
                8.648,
                8.667,
                8.685,
                8.704,
                8.723,
                8.742,
                8.761,
                8.78,
                8.799,
                8.818,
                8.837,
                8.857,
                8.876,
                8.895,
                8.915,
                8.934,
                8.954,
                8.974,
                8.993,
                9.013,
                9.033,
                9.053,
                9.073,
                9.093,
                9.113,
                9.133,
                9.153,
                9.173,
                9.193,
                9.214,
                9.234,
                9.254,
                9.275,
                9.295,
                9.316,
                9.336,
                9.356,
                9.377,
                9.398,
                9.418,
                9.439,
                9.459,
                9.48,
                9.501,
                9.521,
                9.542,
                9.563,
                9.583,
                9.604,
                9.625,
                9.645,
                9.666,
                9.687,
                9.707,
                9.728,
                9.748,
                9.769,
                9.789,
                9.81,
                9.831,
                9.851,
                9.872,
                9.892,
                9.913,
                9.933,
                9.954,
                9.974,
                9.994,
                10.015,
                10.035,
                10.056,
                10.076,
                10.096,
                10.116,
                10.137,
                10.157,
                10.178,
                10.198,
                10.218,
                10.238,
                10.258,
                10.278,
                10.299,
                10.319,
                10.339,
                10.359,
                10.379,
                10.4,
                10.42,
                10.44,
                10.46,
                10.48,
                10.5,
                10.52,
                10.54,
                10.56,
                10.58,
                10.6,
                10.62,
                10.64,
                10.66,
                10.68,
                10.7,
                10.721,
                10.74,
                10.76,
                10.78,
                10.8,
                10.82,
                10.84,
                10.86,
                10.88,
                10.9,
                10.92,
                10.941,
                10.96,
                10.981,
                11,
                11.021,
                11.041,
                11.061,
                11.081,
                11.101,
                11.121,
                11.141,
                11.161,
                11.182,
                11.202,
                11.222,
                11.242,
                11.262,
                11.283,
                11.303,
                11.323,
                11.343,
                11.364,
                11.384,
                11.404,
                11.424,
                11.445,
                11.465,
                11.486,
                11.506,
                11.527,
                11.547,
                11.568,
                11.588,
                11.609,
                11.629,
                11.65,
                11.671,
                11.691,
                11.712,
                11.733,
                11.754,
                11.774,
                11.795,
                11.816,
                11.837,
                11.858,
                11.879,
                11.9,
                11.922,
                11.943,
                11.964,
                11.985,
                12.007,
                12.028,
                12.05,
                12.071,
                12.093,
                12.114,
                12.136,
                12.158,
                12.179,
                12.201,
                12.223,
                12.245,
                12.267,
                12.29,
                12.312,
                12.334,
                12.356,
                12.379,
                12.401,
                12.424,
                12.446,
                12.469,
                12.492,
                12.515,
                12.538,
                12.561,
                12.584,
                12.607,
                12.63,
                12.654,
                12.677,
                12.7,
                12.724,
                12.747,
                12.771,
                12.795,
                12.819,
                12.843,
                12.867,
                12.891,
                12.915,
                12.939,
                12.963,
                12.988,
                13.012,
                13.037,
                13.062,
                13.086,
                13.111,
                13.136,
                13.161,
                13.186,
                13.211,
                13.236,
                13.261,
                13.286,
                13.312,
                13.337,
                13.362,
                13.388,
                13.413,
                13.439,
                13.465,
                13.491,
                13.517,
                13.543,
                13.569,
                13.595,
                13.621,
                13.648,
                13.674,
                13.7,
                13.727,
                13.754,
                13.78,
                13.807,
                13.834,
                13.861,
                13.888,
                13.915,
                13.942,
                13.97,
                13.997,
                14.025,
                14.052,
                14.08,
                14.108,
                14.136,
                14.163,
                14.191,
                14.22,
                14.248,
                14.276,
                14.305,
                14.333,
                14.361,
                14.39,
                14.419,
                14.448,
                14.476,
                14.505,
                14.535,
                14.564,
                14.593,
                14.622,
                14.651,
                14.681,
                14.711,
                14.74,
                14.77,
                14.8,
                14.83,
                14.86,
                14.89,
                14.92,
                14.95,
                14.98,
                15.01,
                15.041,
                15.071,
                15.102,
                15.132,
                15.163,
                15.194,
                15.225,
                15.255,
                15.286,
                15.317,
                15.348,
                15.38,
                15.411,
                15.442,
                15.473,
                15.505,
                15.536,
                15.568,
                15.599,
                15.631,
                15.662,
                15.694,
                15.726,
                15.758,
                15.79,
                15.822,
                15.854,
                15.886,
                15.918,
                15.95,
                15.983,
                16.015,
                16.047,
                16.079,
                16.112,
                16.144,
                16.177,
                16.209,
                16.242,
                16.274,
                16.307,
                16.34,
                16.372,
                16.405,
                16.438,
                16.471,
                16.504,
                16.536,
                16.569,
                16.602,
                16.635,
                16.668,
                16.701,
                16.734,
                16.767,
                16.8,
                16.833,
                16.866,
                16.899,
                16.933,
                16.966,
                16.999,
                17.032,
                17.065,
                17.098,
                17.132,
                17.165,
                17.198,
                17.231,
                17.264,
                17.297,
                17.33,
                17.364,
                17.397,
                17.43,
                17.463,
                17.497,
                17.53,
                17.563,
                17.596,
                17.63,
                17.663,
                17.696,
                17.729,
                17.763,
                17.796,
                17.829,
                17.862,
                17.896,
                17.928,
                17.962,
                17.995,
                18.028,
                18.061,
                18.094,
                18.128,
                18.161,
                18.194,
                18.227,
                18.26,
                18.293,
                18.327
            ],
            P3: [
                6.133,
                6.152,
                6.171,
                6.19,
                6.209,
                6.228,
                6.247,
                6.266,
                6.285,
                6.304,
                6.322,
                6.341,
                6.36,
                6.379,
                6.397,
                6.416,
                6.434,
                6.453,
                6.471,
                6.49,
                6.508,
                6.526,
                6.545,
                6.563,
                6.581,
                6.599,
                6.617,
                6.635,
                6.653,
                6.671,
                6.689,
                6.707,
                6.725,
                6.743,
                6.761,
                6.779,
                6.796,
                6.814,
                6.832,
                6.85,
                6.867,
                6.885,
                6.902,
                6.92,
                6.937,
                6.955,
                6.972,
                6.99,
                7.007,
                7.025,
                7.042,
                7.059,
                7.077,
                7.094,
                7.111,
                7.129,
                7.146,
                7.163,
                7.18,
                7.198,
                7.215,
                7.232,
                7.249,
                7.266,
                7.283,
                7.3,
                7.317,
                7.334,
                7.352,
                7.368,
                7.385,
                7.402,
                7.419,
                7.436,
                7.453,
                7.47,
                7.486,
                7.503,
                7.52,
                7.536,
                7.553,
                7.569,
                7.586,
                7.602,
                7.619,
                7.635,
                7.651,
                7.668,
                7.684,
                7.7,
                7.716,
                7.732,
                7.748,
                7.764,
                7.78,
                7.796,
                7.812,
                7.828,
                7.844,
                7.86,
                7.876,
                7.891,
                7.907,
                7.923,
                7.939,
                7.954,
                7.97,
                7.986,
                8.002,
                8.017,
                8.033,
                8.049,
                8.064,
                8.08,
                8.096,
                8.112,
                8.127,
                8.143,
                8.159,
                8.175,
                8.191,
                8.207,
                8.223,
                8.239,
                8.255,
                8.271,
                8.288,
                8.304,
                8.32,
                8.336,
                8.353,
                8.369,
                8.386,
                8.402,
                8.419,
                8.436,
                8.453,
                8.469,
                8.486,
                8.503,
                8.52,
                8.538,
                8.555,
                8.572,
                8.589,
                8.607,
                8.625,
                8.642,
                8.66,
                8.678,
                8.696,
                8.714,
                8.732,
                8.75,
                8.768,
                8.787,
                8.805,
                8.824,
                8.842,
                8.861,
                8.88,
                8.899,
                8.918,
                8.937,
                8.956,
                8.975,
                8.994,
                9.013,
                9.033,
                9.052,
                9.072,
                9.091,
                9.111,
                9.131,
                9.151,
                9.171,
                9.191,
                9.211,
                9.231,
                9.251,
                9.271,
                9.292,
                9.312,
                9.332,
                9.353,
                9.373,
                9.394,
                9.415,
                9.435,
                9.456,
                9.477,
                9.498,
                9.519,
                9.54,
                9.561,
                9.582,
                9.603,
                9.624,
                9.645,
                9.667,
                9.688,
                9.709,
                9.73,
                9.752,
                9.773,
                9.794,
                9.816,
                9.837,
                9.859,
                9.88,
                9.901,
                9.923,
                9.944,
                9.966,
                9.987,
                10.009,
                10.03,
                10.051,
                10.073,
                10.094,
                10.116,
                10.137,
                10.158,
                10.18,
                10.201,
                10.222,
                10.244,
                10.265,
                10.286,
                10.308,
                10.329,
                10.35,
                10.371,
                10.392,
                10.413,
                10.435,
                10.456,
                10.477,
                10.498,
                10.519,
                10.54,
                10.561,
                10.582,
                10.603,
                10.624,
                10.645,
                10.666,
                10.687,
                10.708,
                10.729,
                10.75,
                10.771,
                10.792,
                10.813,
                10.834,
                10.855,
                10.876,
                10.896,
                10.917,
                10.938,
                10.959,
                10.98,
                11.001,
                11.021,
                11.042,
                11.063,
                11.084,
                11.105,
                11.126,
                11.146,
                11.167,
                11.188,
                11.209,
                11.23,
                11.25,
                11.271,
                11.292,
                11.313,
                11.334,
                11.355,
                11.375,
                11.396,
                11.417,
                11.438,
                11.459,
                11.48,
                11.501,
                11.522,
                11.543,
                11.564,
                11.585,
                11.606,
                11.627,
                11.648,
                11.669,
                11.69,
                11.711,
                11.732,
                11.753,
                11.774,
                11.795,
                11.816,
                11.838,
                11.859,
                11.88,
                11.901,
                11.923,
                11.944,
                11.965,
                11.987,
                12.008,
                12.029,
                12.051,
                12.072,
                12.094,
                12.115,
                12.137,
                12.159,
                12.18,
                12.202,
                12.224,
                12.245,
                12.267,
                12.289,
                12.311,
                12.333,
                12.355,
                12.377,
                12.399,
                12.421,
                12.444,
                12.466,
                12.488,
                12.511,
                12.533,
                12.556,
                12.578,
                12.601,
                12.623,
                12.646,
                12.669,
                12.692,
                12.715,
                12.738,
                12.761,
                12.784,
                12.808,
                12.831,
                12.854,
                12.878,
                12.902,
                12.925,
                12.949,
                12.973,
                12.997,
                13.021,
                13.045,
                13.069,
                13.093,
                13.117,
                13.142,
                13.166,
                13.19,
                13.215,
                13.24,
                13.264,
                13.289,
                13.314,
                13.339,
                13.364,
                13.389,
                13.415,
                13.44,
                13.465,
                13.491,
                13.516,
                13.542,
                13.568,
                13.594,
                13.62,
                13.645,
                13.671,
                13.698,
                13.724,
                13.75,
                13.776,
                13.803,
                13.829,
                13.856,
                13.882,
                13.909,
                13.936,
                13.963,
                13.99,
                14.017,
                14.044,
                14.071,
                14.098,
                14.126,
                14.153,
                14.181,
                14.208,
                14.236,
                14.264,
                14.292,
                14.32,
                14.348,
                14.376,
                14.404,
                14.432,
                14.461,
                14.489,
                14.518,
                14.547,
                14.575,
                14.604,
                14.633,
                14.662,
                14.692,
                14.721,
                14.75,
                14.779,
                14.809,
                14.839,
                14.868,
                14.898,
                14.928,
                14.958,
                14.988,
                15.018,
                15.048,
                15.079,
                15.109,
                15.14,
                15.17,
                15.201,
                15.232,
                15.263,
                15.294,
                15.325,
                15.356,
                15.387,
                15.418,
                15.45,
                15.481,
                15.513,
                15.544,
                15.576,
                15.608,
                15.64,
                15.671,
                15.703,
                15.735,
                15.767,
                15.8,
                15.832,
                15.864,
                15.897,
                15.929,
                15.962,
                15.994,
                16.027,
                16.06,
                16.093,
                16.125,
                16.158,
                16.191,
                16.224,
                16.258,
                16.291,
                16.324,
                16.357,
                16.391,
                16.424,
                16.458,
                16.491,
                16.525,
                16.559,
                16.592,
                16.626,
                16.66,
                16.694,
                16.728,
                16.762,
                16.796,
                16.83,
                16.864,
                16.898,
                16.932,
                16.966,
                17.001,
                17.035,
                17.069,
                17.104,
                17.138,
                17.172,
                17.207,
                17.241,
                17.276,
                17.31,
                17.345,
                17.38,
                17.414,
                17.449,
                17.483,
                17.518,
                17.553,
                17.588,
                17.622,
                17.657,
                17.692,
                17.727,
                17.761,
                17.796,
                17.831,
                17.866,
                17.9,
                17.935,
                17.97,
                18.005,
                18.04,
                18.075,
                18.109,
                18.144,
                18.179,
                18.214,
                18.249,
                18.284,
                18.319,
                18.354,
                18.389,
                18.424,
                18.458,
                18.493,
                18.528,
                18.563,
                18.598,
                18.633,
                18.667,
                18.702,
                18.737,
                18.772,
                18.807,
                18.842,
                18.877,
                18.911,
                18.946,
                18.981,
                19.016,
                19.051,
                19.086
            ],
            P5: [
                6.258,
                6.278,
                6.297,
                6.317,
                6.336,
                6.356,
                6.375,
                6.394,
                6.413,
                6.433,
                6.452,
                6.471,
                6.49,
                6.509,
                6.528,
                6.547,
                6.566,
                6.585,
                6.603,
                6.622,
                6.641,
                6.659,
                6.678,
                6.697,
                6.715,
                6.734,
                6.752,
                6.771,
                6.789,
                6.807,
                6.826,
                6.844,
                6.862,
                6.881,
                6.899,
                6.917,
                6.935,
                6.953,
                6.971,
                6.989,
                7.007,
                7.025,
                7.043,
                7.061,
                7.079,
                7.097,
                7.114,
                7.132,
                7.15,
                7.168,
                7.185,
                7.203,
                7.221,
                7.238,
                7.256,
                7.274,
                7.291,
                7.309,
                7.326,
                7.344,
                7.361,
                7.379,
                7.396,
                7.414,
                7.431,
                7.449,
                7.466,
                7.483,
                7.501,
                7.518,
                7.535,
                7.552,
                7.57,
                7.587,
                7.604,
                7.621,
                7.638,
                7.655,
                7.672,
                7.689,
                7.706,
                7.723,
                7.74,
                7.756,
                7.773,
                7.79,
                7.806,
                7.823,
                7.839,
                7.856,
                7.872,
                7.889,
                7.905,
                7.921,
                7.938,
                7.954,
                7.97,
                7.986,
                8.003,
                8.019,
                8.035,
                8.051,
                8.067,
                8.083,
                8.099,
                8.115,
                8.131,
                8.147,
                8.163,
                8.179,
                8.195,
                8.211,
                8.227,
                8.243,
                8.259,
                8.275,
                8.291,
                8.308,
                8.324,
                8.34,
                8.356,
                8.372,
                8.389,
                8.405,
                8.421,
                8.438,
                8.454,
                8.471,
                8.487,
                8.504,
                8.521,
                8.538,
                8.555,
                8.571,
                8.588,
                8.605,
                8.623,
                8.64,
                8.657,
                8.674,
                8.692,
                8.709,
                8.727,
                8.744,
                8.762,
                8.78,
                8.798,
                8.816,
                8.834,
                8.852,
                8.87,
                8.889,
                8.907,
                8.926,
                8.944,
                8.963,
                8.982,
                9.001,
                9.02,
                9.039,
                9.058,
                9.077,
                9.096,
                9.116,
                9.135,
                9.155,
                9.174,
                9.194,
                9.214,
                9.234,
                9.253,
                9.273,
                9.294,
                9.314,
                9.334,
                9.354,
                9.375,
                9.395,
                9.416,
                9.436,
                9.457,
                9.477,
                9.498,
                9.519,
                9.54,
                9.561,
                9.582,
                9.603,
                9.624,
                9.645,
                9.667,
                9.688,
                9.709,
                9.731,
                9.752,
                9.774,
                9.795,
                9.817,
                9.838,
                9.86,
                9.881,
                9.903,
                9.925,
                9.947,
                9.968,
                9.99,
                10.012,
                10.034,
                10.056,
                10.077,
                10.099,
                10.121,
                10.143,
                10.165,
                10.187,
                10.209,
                10.231,
                10.252,
                10.274,
                10.296,
                10.318,
                10.34,
                10.361,
                10.383,
                10.405,
                10.427,
                10.449,
                10.47,
                10.492,
                10.514,
                10.535,
                10.557,
                10.578,
                10.6,
                10.622,
                10.643,
                10.665,
                10.687,
                10.708,
                10.73,
                10.751,
                10.773,
                10.794,
                10.816,
                10.837,
                10.858,
                10.88,
                10.901,
                10.923,
                10.944,
                10.965,
                10.987,
                11.008,
                11.03,
                11.051,
                11.072,
                11.093,
                11.115,
                11.136,
                11.157,
                11.179,
                11.2,
                11.221,
                11.243,
                11.264,
                11.285,
                11.306,
                11.328,
                11.349,
                11.37,
                11.391,
                11.413,
                11.434,
                11.455,
                11.476,
                11.498,
                11.519,
                11.54,
                11.562,
                11.583,
                11.604,
                11.626,
                11.647,
                11.668,
                11.69,
                11.711,
                11.732,
                11.754,
                11.775,
                11.796,
                11.818,
                11.839,
                11.861,
                11.882,
                11.904,
                11.925,
                11.947,
                11.968,
                11.99,
                12.011,
                12.033,
                12.055,
                12.076,
                12.098,
                12.12,
                12.142,
                12.163,
                12.185,
                12.207,
                12.229,
                12.251,
                12.273,
                12.294,
                12.316,
                12.338,
                12.361,
                12.383,
                12.405,
                12.427,
                12.449,
                12.471,
                12.493,
                12.516,
                12.538,
                12.561,
                12.583,
                12.606,
                12.628,
                12.651,
                12.673,
                12.696,
                12.719,
                12.742,
                12.765,
                12.788,
                12.811,
                12.834,
                12.857,
                12.88,
                12.904,
                12.927,
                12.951,
                12.974,
                12.998,
                13.021,
                13.045,
                13.069,
                13.093,
                13.117,
                13.141,
                13.165,
                13.189,
                13.213,
                13.238,
                13.262,
                13.287,
                13.311,
                13.336,
                13.361,
                13.386,
                13.411,
                13.435,
                13.461,
                13.486,
                13.511,
                13.536,
                13.562,
                13.587,
                13.613,
                13.639,
                13.664,
                13.69,
                13.716,
                13.742,
                13.768,
                13.794,
                13.821,
                13.847,
                13.873,
                13.9,
                13.926,
                13.953,
                13.98,
                14.007,
                14.034,
                14.061,
                14.088,
                14.115,
                14.142,
                14.169,
                14.197,
                14.224,
                14.252,
                14.279,
                14.307,
                14.335,
                14.363,
                14.39,
                14.419,
                14.447,
                14.475,
                14.503,
                14.532,
                14.56,
                14.589,
                14.617,
                14.646,
                14.675,
                14.704,
                14.733,
                14.762,
                14.791,
                14.82,
                14.85,
                14.879,
                14.909,
                14.938,
                14.968,
                14.998,
                15.028,
                15.058,
                15.088,
                15.119,
                15.149,
                15.179,
                15.21,
                15.24,
                15.271,
                15.302,
                15.333,
                15.364,
                15.395,
                15.426,
                15.458,
                15.489,
                15.52,
                15.552,
                15.584,
                15.615,
                15.647,
                15.679,
                15.711,
                15.743,
                15.775,
                15.808,
                15.84,
                15.872,
                15.905,
                15.937,
                15.97,
                16.003,
                16.035,
                16.068,
                16.101,
                16.134,
                16.167,
                16.2,
                16.233,
                16.267,
                16.3,
                16.334,
                16.367,
                16.401,
                16.434,
                16.468,
                16.502,
                16.536,
                16.57,
                16.604,
                16.638,
                16.672,
                16.706,
                16.74,
                16.774,
                16.809,
                16.843,
                16.878,
                16.912,
                16.947,
                16.982,
                17.016,
                17.051,
                17.086,
                17.121,
                17.155,
                17.19,
                17.225,
                17.26,
                17.295,
                17.33,
                17.366,
                17.401,
                17.436,
                17.471,
                17.506,
                17.542,
                17.577,
                17.612,
                17.648,
                17.683,
                17.719,
                17.754,
                17.79,
                17.825,
                17.861,
                17.896,
                17.932,
                17.968,
                18.003,
                18.039,
                18.075,
                18.11,
                18.146,
                18.182,
                18.217,
                18.253,
                18.289,
                18.324,
                18.36,
                18.396,
                18.432,
                18.467,
                18.503,
                18.539,
                18.575,
                18.611,
                18.647,
                18.682,
                18.718,
                18.754,
                18.79,
                18.826,
                18.861,
                18.897,
                18.933,
                18.969,
                19.005,
                19.04,
                19.076,
                19.112,
                19.147,
                19.183,
                19.219,
                19.255,
                19.291,
                19.326,
                19.362,
                19.398,
                19.434,
                19.469,
                19.505
            ],
            P10: [
                6.459,
                6.479,
                6.499,
                6.519,
                6.539,
                6.559,
                6.579,
                6.599,
                6.618,
                6.638,
                6.658,
                6.678,
                6.697,
                6.717,
                6.737,
                6.756,
                6.776,
                6.795,
                6.814,
                6.834,
                6.853,
                6.872,
                6.891,
                6.911,
                6.93,
                6.949,
                6.968,
                6.987,
                7.006,
                7.025,
                7.043,
                7.062,
                7.081,
                7.1,
                7.119,
                7.137,
                7.156,
                7.175,
                7.193,
                7.212,
                7.23,
                7.249,
                7.267,
                7.286,
                7.304,
                7.322,
                7.341,
                7.359,
                7.377,
                7.396,
                7.414,
                7.432,
                7.45,
                7.469,
                7.487,
                7.505,
                7.523,
                7.541,
                7.559,
                7.577,
                7.595,
                7.613,
                7.631,
                7.649,
                7.667,
                7.685,
                7.703,
                7.721,
                7.739,
                7.757,
                7.774,
                7.792,
                7.81,
                7.828,
                7.845,
                7.863,
                7.88,
                7.898,
                7.915,
                7.933,
                7.95,
                7.967,
                7.985,
                8.002,
                8.019,
                8.036,
                8.054,
                8.071,
                8.088,
                8.104,
                8.121,
                8.138,
                8.155,
                8.172,
                8.189,
                8.205,
                8.222,
                8.239,
                8.256,
                8.272,
                8.289,
                8.305,
                8.322,
                8.338,
                8.355,
                8.371,
                8.388,
                8.404,
                8.421,
                8.437,
                8.454,
                8.47,
                8.487,
                8.503,
                8.52,
                8.536,
                8.553,
                8.569,
                8.586,
                8.603,
                8.619,
                8.636,
                8.653,
                8.67,
                8.687,
                8.704,
                8.721,
                8.738,
                8.755,
                8.772,
                8.789,
                8.806,
                8.824,
                8.841,
                8.858,
                8.876,
                8.894,
                8.911,
                8.929,
                8.947,
                8.965,
                8.983,
                9.001,
                9.019,
                9.037,
                9.056,
                9.074,
                9.093,
                9.111,
                9.13,
                9.149,
                9.168,
                9.187,
                9.206,
                9.225,
                9.244,
                9.264,
                9.283,
                9.302,
                9.322,
                9.342,
                9.361,
                9.381,
                9.401,
                9.421,
                9.441,
                9.462,
                9.482,
                9.502,
                9.523,
                9.543,
                9.564,
                9.585,
                9.605,
                9.626,
                9.647,
                9.668,
                9.689,
                9.71,
                9.731,
                9.753,
                9.774,
                9.795,
                9.817,
                9.838,
                9.86,
                9.882,
                9.903,
                9.925,
                9.947,
                9.969,
                9.991,
                10.013,
                10.035,
                10.057,
                10.079,
                10.101,
                10.123,
                10.146,
                10.168,
                10.19,
                10.213,
                10.235,
                10.258,
                10.28,
                10.303,
                10.325,
                10.348,
                10.37,
                10.393,
                10.415,
                10.438,
                10.46,
                10.483,
                10.505,
                10.528,
                10.55,
                10.573,
                10.595,
                10.618,
                10.64,
                10.663,
                10.685,
                10.708,
                10.73,
                10.753,
                10.775,
                10.798,
                10.82,
                10.842,
                10.865,
                10.887,
                10.909,
                10.932,
                10.954,
                10.976,
                10.999,
                11.021,
                11.043,
                11.065,
                11.088,
                11.11,
                11.132,
                11.154,
                11.176,
                11.198,
                11.22,
                11.243,
                11.265,
                11.287,
                11.309,
                11.331,
                11.353,
                11.375,
                11.397,
                11.419,
                11.441,
                11.463,
                11.485,
                11.507,
                11.529,
                11.551,
                11.573,
                11.595,
                11.617,
                11.639,
                11.661,
                11.683,
                11.705,
                11.727,
                11.749,
                11.771,
                11.793,
                11.815,
                11.837,
                11.859,
                11.881,
                11.903,
                11.925,
                11.947,
                11.969,
                11.991,
                12.013,
                12.035,
                12.057,
                12.079,
                12.101,
                12.123,
                12.146,
                12.168,
                12.19,
                12.212,
                12.234,
                12.256,
                12.279,
                12.301,
                12.323,
                12.346,
                12.368,
                12.39,
                12.413,
                12.435,
                12.457,
                12.48,
                12.502,
                12.525,
                12.547,
                12.57,
                12.592,
                12.615,
                12.638,
                12.66,
                12.683,
                12.706,
                12.729,
                12.751,
                12.774,
                12.797,
                12.82,
                12.843,
                12.866,
                12.889,
                12.912,
                12.936,
                12.959,
                12.982,
                13.005,
                13.029,
                13.052,
                13.076,
                13.099,
                13.123,
                13.146,
                13.17,
                13.194,
                13.218,
                13.242,
                13.266,
                13.29,
                13.314,
                13.338,
                13.363,
                13.387,
                13.412,
                13.436,
                13.461,
                13.485,
                13.51,
                13.535,
                13.56,
                13.585,
                13.61,
                13.635,
                13.661,
                13.686,
                13.711,
                13.737,
                13.763,
                13.788,
                13.814,
                13.84,
                13.866,
                13.892,
                13.918,
                13.944,
                13.97,
                13.997,
                14.023,
                14.05,
                14.076,
                14.103,
                14.13,
                14.157,
                14.184,
                14.211,
                14.238,
                14.265,
                14.293,
                14.32,
                14.348,
                14.375,
                14.403,
                14.431,
                14.458,
                14.486,
                14.514,
                14.542,
                14.571,
                14.599,
                14.627,
                14.656,
                14.684,
                14.713,
                14.741,
                14.77,
                14.799,
                14.828,
                14.857,
                14.886,
                14.915,
                14.945,
                14.974,
                15.003,
                15.033,
                15.063,
                15.092,
                15.122,
                15.152,
                15.182,
                15.212,
                15.243,
                15.273,
                15.303,
                15.334,
                15.364,
                15.395,
                15.426,
                15.457,
                15.488,
                15.519,
                15.55,
                15.582,
                15.613,
                15.645,
                15.676,
                15.708,
                15.74,
                15.772,
                15.804,
                15.836,
                15.868,
                15.9,
                15.933,
                15.965,
                15.998,
                16.031,
                16.063,
                16.096,
                16.129,
                16.162,
                16.195,
                16.229,
                16.262,
                16.296,
                16.329,
                16.363,
                16.396,
                16.43,
                16.464,
                16.498,
                16.532,
                16.566,
                16.6,
                16.634,
                16.668,
                16.703,
                16.737,
                16.772,
                16.806,
                16.841,
                16.876,
                16.911,
                16.946,
                16.981,
                17.016,
                17.051,
                17.086,
                17.121,
                17.157,
                17.192,
                17.227,
                17.263,
                17.299,
                17.334,
                17.37,
                17.406,
                17.442,
                17.478,
                17.514,
                17.55,
                17.586,
                17.622,
                17.658,
                17.694,
                17.73,
                17.767,
                17.803,
                17.84,
                17.876,
                17.913,
                17.949,
                17.986,
                18.022,
                18.059,
                18.096,
                18.132,
                18.169,
                18.206,
                18.243,
                18.28,
                18.317,
                18.354,
                18.39,
                18.427,
                18.464,
                18.501,
                18.538,
                18.576,
                18.613,
                18.65,
                18.687,
                18.724,
                18.761,
                18.798,
                18.836,
                18.873,
                18.91,
                18.947,
                18.984,
                19.021,
                19.059,
                19.096,
                19.133,
                19.17,
                19.208,
                19.245,
                19.282,
                19.319,
                19.357,
                19.394,
                19.431,
                19.469,
                19.506,
                19.543,
                19.58,
                19.618,
                19.655,
                19.692,
                19.729,
                19.767,
                19.804,
                19.841,
                19.878,
                19.916,
                19.953,
                19.99,
                20.027,
                20.065,
                20.102,
                20.139,
                20.176
            ],
            P15: [
                6.599,
                6.619,
                6.64,
                6.66,
                6.681,
                6.701,
                6.721,
                6.742,
                6.762,
                6.782,
                6.802,
                6.822,
                6.842,
                6.863,
                6.883,
                6.902,
                6.922,
                6.942,
                6.962,
                6.982,
                7.001,
                7.021,
                7.041,
                7.06,
                7.08,
                7.099,
                7.119,
                7.138,
                7.157,
                7.177,
                7.196,
                7.215,
                7.234,
                7.253,
                7.273,
                7.292,
                7.311,
                7.33,
                7.349,
                7.368,
                7.386,
                7.405,
                7.424,
                7.443,
                7.462,
                7.481,
                7.499,
                7.518,
                7.537,
                7.555,
                7.574,
                7.593,
                7.611,
                7.63,
                7.648,
                7.667,
                7.685,
                7.704,
                7.722,
                7.741,
                7.759,
                7.777,
                7.796,
                7.814,
                7.833,
                7.851,
                7.869,
                7.887,
                7.905,
                7.924,
                7.942,
                7.96,
                7.978,
                7.996,
                8.014,
                8.032,
                8.05,
                8.068,
                8.086,
                8.103,
                8.121,
                8.139,
                8.156,
                8.174,
                8.191,
                8.209,
                8.226,
                8.244,
                8.261,
                8.278,
                8.296,
                8.313,
                8.33,
                8.347,
                8.364,
                8.381,
                8.399,
                8.416,
                8.433,
                8.449,
                8.466,
                8.483,
                8.5,
                8.517,
                8.534,
                8.551,
                8.567,
                8.584,
                8.601,
                8.618,
                8.635,
                8.651,
                8.668,
                8.685,
                8.702,
                8.719,
                8.736,
                8.753,
                8.77,
                8.787,
                8.804,
                8.821,
                8.838,
                8.855,
                8.872,
                8.889,
                8.907,
                8.924,
                8.942,
                8.959,
                8.977,
                8.994,
                9.012,
                9.03,
                9.047,
                9.065,
                9.083,
                9.101,
                9.119,
                9.137,
                9.156,
                9.174,
                9.193,
                9.211,
                9.23,
                9.249,
                9.267,
                9.286,
                9.305,
                9.324,
                9.343,
                9.363,
                9.382,
                9.402,
                9.421,
                9.441,
                9.461,
                9.48,
                9.5,
                9.52,
                9.54,
                9.56,
                9.581,
                9.601,
                9.622,
                9.642,
                9.663,
                9.683,
                9.704,
                9.725,
                9.746,
                9.767,
                9.788,
                9.809,
                9.83,
                9.852,
                9.873,
                9.895,
                9.916,
                9.938,
                9.96,
                9.981,
                10.003,
                10.025,
                10.047,
                10.069,
                10.091,
                10.113,
                10.136,
                10.158,
                10.18,
                10.203,
                10.225,
                10.248,
                10.27,
                10.293,
                10.315,
                10.338,
                10.361,
                10.384,
                10.406,
                10.429,
                10.452,
                10.475,
                10.498,
                10.521,
                10.544,
                10.567,
                10.59,
                10.613,
                10.636,
                10.659,
                10.682,
                10.705,
                10.728,
                10.751,
                10.774,
                10.797,
                10.82,
                10.843,
                10.866,
                10.889,
                10.912,
                10.935,
                10.958,
                10.981,
                11.004,
                11.026,
                11.049,
                11.072,
                11.095,
                11.118,
                11.141,
                11.164,
                11.186,
                11.209,
                11.232,
                11.255,
                11.277,
                11.3,
                11.323,
                11.345,
                11.368,
                11.391,
                11.413,
                11.436,
                11.459,
                11.481,
                11.504,
                11.526,
                11.549,
                11.571,
                11.594,
                11.617,
                11.639,
                11.662,
                11.684,
                11.707,
                11.729,
                11.752,
                11.774,
                11.797,
                11.819,
                11.842,
                11.864,
                11.887,
                11.909,
                11.932,
                11.954,
                11.976,
                11.999,
                12.021,
                12.044,
                12.066,
                12.089,
                12.111,
                12.134,
                12.156,
                12.179,
                12.201,
                12.224,
                12.246,
                12.269,
                12.292,
                12.314,
                12.337,
                12.359,
                12.382,
                12.405,
                12.427,
                12.45,
                12.473,
                12.495,
                12.518,
                12.541,
                12.564,
                12.587,
                12.609,
                12.632,
                12.655,
                12.678,
                12.701,
                12.724,
                12.747,
                12.77,
                12.793,
                12.816,
                12.839,
                12.862,
                12.885,
                12.908,
                12.932,
                12.955,
                12.978,
                13.002,
                13.025,
                13.048,
                13.072,
                13.095,
                13.119,
                13.142,
                13.166,
                13.19,
                13.214,
                13.237,
                13.261,
                13.285,
                13.309,
                13.333,
                13.357,
                13.381,
                13.405,
                13.43,
                13.454,
                13.478,
                13.503,
                13.527,
                13.552,
                13.577,
                13.601,
                13.626,
                13.651,
                13.676,
                13.701,
                13.726,
                13.752,
                13.777,
                13.802,
                13.828,
                13.853,
                13.879,
                13.905,
                13.931,
                13.957,
                13.982,
                14.008,
                14.035,
                14.061,
                14.087,
                14.114,
                14.14,
                14.167,
                14.193,
                14.22,
                14.247,
                14.274,
                14.301,
                14.328,
                14.356,
                14.383,
                14.41,
                14.438,
                14.465,
                14.493,
                14.521,
                14.549,
                14.577,
                14.605,
                14.633,
                14.661,
                14.689,
                14.718,
                14.746,
                14.775,
                14.803,
                14.832,
                14.861,
                14.89,
                14.919,
                14.948,
                14.977,
                15.006,
                15.035,
                15.065,
                15.094,
                15.124,
                15.154,
                15.183,
                15.213,
                15.243,
                15.273,
                15.303,
                15.334,
                15.364,
                15.394,
                15.425,
                15.456,
                15.486,
                15.517,
                15.548,
                15.579,
                15.61,
                15.641,
                15.673,
                15.704,
                15.736,
                15.767,
                15.799,
                15.831,
                15.863,
                15.895,
                15.927,
                15.959,
                15.992,
                16.024,
                16.057,
                16.089,
                16.122,
                16.155,
                16.188,
                16.221,
                16.254,
                16.288,
                16.321,
                16.354,
                16.388,
                16.422,
                16.456,
                16.489,
                16.523,
                16.557,
                16.591,
                16.626,
                16.66,
                16.694,
                16.729,
                16.763,
                16.798,
                16.833,
                16.868,
                16.903,
                16.938,
                16.973,
                17.008,
                17.043,
                17.078,
                17.114,
                17.149,
                17.185,
                17.22,
                17.256,
                17.292,
                17.328,
                17.364,
                17.4,
                17.436,
                17.472,
                17.508,
                17.544,
                17.581,
                17.617,
                17.654,
                17.69,
                17.727,
                17.763,
                17.8,
                17.837,
                17.874,
                17.911,
                17.948,
                17.985,
                18.022,
                18.059,
                18.096,
                18.134,
                18.171,
                18.208,
                18.246,
                18.283,
                18.321,
                18.358,
                18.396,
                18.433,
                18.471,
                18.509,
                18.547,
                18.584,
                18.622,
                18.66,
                18.698,
                18.736,
                18.774,
                18.812,
                18.85,
                18.888,
                18.926,
                18.964,
                19.002,
                19.04,
                19.078,
                19.116,
                19.154,
                19.193,
                19.231,
                19.269,
                19.307,
                19.345,
                19.383,
                19.422,
                19.46,
                19.498,
                19.537,
                19.575,
                19.613,
                19.651,
                19.69,
                19.728,
                19.766,
                19.805,
                19.843,
                19.881,
                19.92,
                19.958,
                19.996,
                20.035,
                20.073,
                20.111,
                20.15,
                20.188,
                20.226,
                20.265,
                20.303,
                20.341,
                20.38,
                20.418,
                20.456,
                20.494,
                20.533,
                20.571,
                20.609,
                20.648
            ],
            P25: [
                6.813,
                6.835,
                6.856,
                6.877,
                6.898,
                6.919,
                6.94,
                6.961,
                6.982,
                7.003,
                7.024,
                7.044,
                7.065,
                7.086,
                7.106,
                7.127,
                7.147,
                7.168,
                7.188,
                7.208,
                7.229,
                7.249,
                7.269,
                7.289,
                7.309,
                7.33,
                7.35,
                7.37,
                7.389,
                7.409,
                7.429,
                7.449,
                7.469,
                7.489,
                7.508,
                7.528,
                7.548,
                7.567,
                7.587,
                7.606,
                7.626,
                7.645,
                7.665,
                7.684,
                7.703,
                7.723,
                7.742,
                7.761,
                7.781,
                7.8,
                7.819,
                7.838,
                7.857,
                7.876,
                7.896,
                7.915,
                7.934,
                7.953,
                7.972,
                7.991,
                8.01,
                8.029,
                8.048,
                8.067,
                8.085,
                8.104,
                8.123,
                8.142,
                8.161,
                8.179,
                8.198,
                8.217,
                8.235,
                8.254,
                8.272,
                8.291,
                8.309,
                8.328,
                8.346,
                8.364,
                8.383,
                8.401,
                8.419,
                8.437,
                8.455,
                8.473,
                8.491,
                8.509,
                8.527,
                8.545,
                8.563,
                8.58,
                8.598,
                8.616,
                8.633,
                8.651,
                8.669,
                8.686,
                8.704,
                8.721,
                8.738,
                8.756,
                8.773,
                8.791,
                8.808,
                8.825,
                8.842,
                8.86,
                8.877,
                8.894,
                8.912,
                8.929,
                8.946,
                8.964,
                8.981,
                8.998,
                9.016,
                9.033,
                9.051,
                9.068,
                9.086,
                9.103,
                9.121,
                9.139,
                9.156,
                9.174,
                9.192,
                9.21,
                9.228,
                9.246,
                9.264,
                9.282,
                9.3,
                9.318,
                9.337,
                9.355,
                9.374,
                9.392,
                9.411,
                9.429,
                9.448,
                9.467,
                9.486,
                9.505,
                9.524,
                9.544,
                9.563,
                9.583,
                9.602,
                9.622,
                9.642,
                9.661,
                9.681,
                9.701,
                9.722,
                9.742,
                9.762,
                9.783,
                9.803,
                9.824,
                9.844,
                9.865,
                9.886,
                9.907,
                9.928,
                9.949,
                9.97,
                9.992,
                10.013,
                10.035,
                10.056,
                10.078,
                10.1,
                10.121,
                10.143,
                10.165,
                10.187,
                10.21,
                10.232,
                10.254,
                10.276,
                10.299,
                10.321,
                10.344,
                10.367,
                10.389,
                10.412,
                10.435,
                10.458,
                10.481,
                10.504,
                10.527,
                10.55,
                10.573,
                10.597,
                10.62,
                10.643,
                10.667,
                10.69,
                10.714,
                10.737,
                10.761,
                10.784,
                10.808,
                10.832,
                10.855,
                10.879,
                10.903,
                10.926,
                10.95,
                10.974,
                10.998,
                11.021,
                11.045,
                11.069,
                11.093,
                11.116,
                11.14,
                11.164,
                11.188,
                11.211,
                11.235,
                11.259,
                11.282,
                11.306,
                11.33,
                11.353,
                11.377,
                11.401,
                11.424,
                11.448,
                11.471,
                11.495,
                11.519,
                11.542,
                11.566,
                11.589,
                11.613,
                11.636,
                11.66,
                11.683,
                11.706,
                11.73,
                11.753,
                11.777,
                11.8,
                11.823,
                11.847,
                11.87,
                11.893,
                11.917,
                11.94,
                11.963,
                11.986,
                12.01,
                12.033,
                12.056,
                12.08,
                12.103,
                12.126,
                12.149,
                12.173,
                12.196,
                12.219,
                12.242,
                12.266,
                12.289,
                12.312,
                12.335,
                12.358,
                12.382,
                12.405,
                12.428,
                12.451,
                12.475,
                12.498,
                12.521,
                12.545,
                12.568,
                12.591,
                12.614,
                12.638,
                12.661,
                12.684,
                12.708,
                12.731,
                12.755,
                12.778,
                12.802,
                12.825,
                12.848,
                12.872,
                12.895,
                12.919,
                12.943,
                12.966,
                12.99,
                13.013,
                13.037,
                13.061,
                13.084,
                13.108,
                13.132,
                13.156,
                13.18,
                13.203,
                13.227,
                13.251,
                13.275,
                13.299,
                13.323,
                13.347,
                13.371,
                13.395,
                13.42,
                13.444,
                13.468,
                13.492,
                13.517,
                13.541,
                13.566,
                13.59,
                13.615,
                13.639,
                13.664,
                13.689,
                13.713,
                13.738,
                13.763,
                13.788,
                13.813,
                13.838,
                13.863,
                13.889,
                13.914,
                13.939,
                13.965,
                13.99,
                14.016,
                14.041,
                14.067,
                14.093,
                14.119,
                14.145,
                14.171,
                14.197,
                14.224,
                14.25,
                14.276,
                14.303,
                14.33,
                14.356,
                14.383,
                14.41,
                14.437,
                14.464,
                14.491,
                14.518,
                14.546,
                14.573,
                14.601,
                14.628,
                14.656,
                14.684,
                14.711,
                14.74,
                14.768,
                14.796,
                14.824,
                14.852,
                14.881,
                14.909,
                14.938,
                14.967,
                14.996,
                15.025,
                15.054,
                15.083,
                15.112,
                15.141,
                15.171,
                15.2,
                15.23,
                15.259,
                15.289,
                15.319,
                15.349,
                15.379,
                15.409,
                15.439,
                15.469,
                15.5,
                15.53,
                15.561,
                15.591,
                15.622,
                15.653,
                15.684,
                15.715,
                15.746,
                15.777,
                15.809,
                15.84,
                15.872,
                15.903,
                15.935,
                15.967,
                15.999,
                16.031,
                16.063,
                16.095,
                16.128,
                16.16,
                16.193,
                16.225,
                16.258,
                16.291,
                16.324,
                16.357,
                16.39,
                16.424,
                16.457,
                16.491,
                16.524,
                16.558,
                16.592,
                16.626,
                16.66,
                16.694,
                16.728,
                16.763,
                16.797,
                16.832,
                16.867,
                16.901,
                16.936,
                16.971,
                17.006,
                17.042,
                17.077,
                17.112,
                17.148,
                17.183,
                17.219,
                17.255,
                17.291,
                17.327,
                17.363,
                17.399,
                17.435,
                17.471,
                17.508,
                17.544,
                17.581,
                17.617,
                17.654,
                17.691,
                17.728,
                17.765,
                17.802,
                17.839,
                17.876,
                17.914,
                17.951,
                17.989,
                18.026,
                18.064,
                18.101,
                18.139,
                18.177,
                18.215,
                18.253,
                18.291,
                18.329,
                18.367,
                18.405,
                18.444,
                18.482,
                18.521,
                18.559,
                18.598,
                18.636,
                18.675,
                18.714,
                18.752,
                18.791,
                18.83,
                18.869,
                18.908,
                18.947,
                18.986,
                19.025,
                19.065,
                19.104,
                19.143,
                19.182,
                19.222,
                19.261,
                19.3,
                19.34,
                19.379,
                19.419,
                19.458,
                19.498,
                19.538,
                19.577,
                19.617,
                19.656,
                19.696,
                19.736,
                19.776,
                19.815,
                19.855,
                19.895,
                19.935,
                19.975,
                20.014,
                20.054,
                20.094,
                20.134,
                20.174,
                20.214,
                20.253,
                20.293,
                20.333,
                20.373,
                20.413,
                20.453,
                20.493,
                20.533,
                20.573,
                20.613,
                20.653,
                20.693,
                20.733,
                20.773,
                20.813,
                20.852,
                20.892,
                20.932,
                20.972,
                21.012,
                21.052,
                21.092,
                21.132,
                21.172,
                21.212,
                21.252,
                21.292,
                21.332,
                21.372
            ],
            P50: [
                7.24,
                7.263,
                7.285,
                7.308,
                7.33,
                7.352,
                7.375,
                7.397,
                7.419,
                7.441,
                7.463,
                7.485,
                7.507,
                7.529,
                7.551,
                7.572,
                7.594,
                7.616,
                7.638,
                7.659,
                7.681,
                7.702,
                7.723,
                7.745,
                7.766,
                7.787,
                7.809,
                7.83,
                7.851,
                7.872,
                7.893,
                7.914,
                7.935,
                7.956,
                7.977,
                7.998,
                8.018,
                8.039,
                8.06,
                8.081,
                8.101,
                8.122,
                8.142,
                8.163,
                8.184,
                8.204,
                8.224,
                8.245,
                8.265,
                8.286,
                8.306,
                8.326,
                8.346,
                8.367,
                8.387,
                8.407,
                8.427,
                8.447,
                8.468,
                8.488,
                8.508,
                8.528,
                8.548,
                8.568,
                8.588,
                8.608,
                8.628,
                8.648,
                8.667,
                8.687,
                8.707,
                8.727,
                8.746,
                8.766,
                8.786,
                8.805,
                8.825,
                8.844,
                8.864,
                8.883,
                8.903,
                8.922,
                8.941,
                8.96,
                8.979,
                8.998,
                9.017,
                9.036,
                9.055,
                9.074,
                9.093,
                9.112,
                9.13,
                9.149,
                9.168,
                9.186,
                9.205,
                9.223,
                9.242,
                9.26,
                9.279,
                9.297,
                9.315,
                9.334,
                9.352,
                9.37,
                9.389,
                9.407,
                9.425,
                9.444,
                9.462,
                9.48,
                9.498,
                9.517,
                9.535,
                9.553,
                9.572,
                9.59,
                9.609,
                9.627,
                9.646,
                9.664,
                9.683,
                9.702,
                9.72,
                9.739,
                9.758,
                9.777,
                9.796,
                9.815,
                9.834,
                9.853,
                9.872,
                9.892,
                9.911,
                9.93,
                9.95,
                9.97,
                9.989,
                10.009,
                10.029,
                10.049,
                10.069,
                10.089,
                10.109,
                10.13,
                10.15,
                10.171,
                10.192,
                10.212,
                10.233,
                10.254,
                10.275,
                10.297,
                10.318,
                10.339,
                10.361,
                10.382,
                10.404,
                10.426,
                10.448,
                10.47,
                10.492,
                10.514,
                10.536,
                10.559,
                10.581,
                10.604,
                10.626,
                10.649,
                10.672,
                10.695,
                10.718,
                10.741,
                10.764,
                10.787,
                10.811,
                10.834,
                10.858,
                10.881,
                10.905,
                10.929,
                10.953,
                10.977,
                11.001,
                11.025,
                11.049,
                11.073,
                11.097,
                11.122,
                11.146,
                11.171,
                11.195,
                11.22,
                11.244,
                11.269,
                11.294,
                11.319,
                11.344,
                11.368,
                11.393,
                11.418,
                11.443,
                11.468,
                11.494,
                11.519,
                11.544,
                11.569,
                11.594,
                11.619,
                11.644,
                11.67,
                11.695,
                11.72,
                11.745,
                11.771,
                11.796,
                11.821,
                11.846,
                11.871,
                11.897,
                11.922,
                11.947,
                11.972,
                11.997,
                12.022,
                12.047,
                12.073,
                12.098,
                12.123,
                12.148,
                12.173,
                12.198,
                12.223,
                12.248,
                12.273,
                12.298,
                12.323,
                12.348,
                12.373,
                12.398,
                12.423,
                12.447,
                12.472,
                12.497,
                12.522,
                12.547,
                12.572,
                12.597,
                12.621,
                12.646,
                12.671,
                12.696,
                12.721,
                12.745,
                12.77,
                12.795,
                12.82,
                12.844,
                12.869,
                12.894,
                12.919,
                12.943,
                12.968,
                12.993,
                13.018,
                13.042,
                13.067,
                13.092,
                13.117,
                13.142,
                13.166,
                13.191,
                13.216,
                13.241,
                13.265,
                13.29,
                13.315,
                13.34,
                13.365,
                13.39,
                13.415,
                13.439,
                13.464,
                13.489,
                13.514,
                13.539,
                13.564,
                13.589,
                13.614,
                13.639,
                13.664,
                13.69,
                13.715,
                13.74,
                13.765,
                13.79,
                13.816,
                13.841,
                13.866,
                13.891,
                13.917,
                13.942,
                13.968,
                13.993,
                14.019,
                14.044,
                14.07,
                14.095,
                14.121,
                14.147,
                14.172,
                14.198,
                14.224,
                14.25,
                14.276,
                14.302,
                14.328,
                14.354,
                14.38,
                14.406,
                14.432,
                14.458,
                14.485,
                14.511,
                14.538,
                14.564,
                14.591,
                14.617,
                14.644,
                14.671,
                14.698,
                14.725,
                14.752,
                14.779,
                14.806,
                14.833,
                14.861,
                14.888,
                14.916,
                14.943,
                14.971,
                14.999,
                15.027,
                15.055,
                15.083,
                15.111,
                15.139,
                15.168,
                15.196,
                15.225,
                15.253,
                15.282,
                15.311,
                15.34,
                15.369,
                15.398,
                15.427,
                15.456,
                15.486,
                15.515,
                15.545,
                15.575,
                15.605,
                15.635,
                15.665,
                15.695,
                15.725,
                15.755,
                15.786,
                15.816,
                15.847,
                15.878,
                15.909,
                15.94,
                15.971,
                16.002,
                16.033,
                16.064,
                16.096,
                16.128,
                16.159,
                16.191,
                16.223,
                16.255,
                16.287,
                16.319,
                16.351,
                16.384,
                16.416,
                16.449,
                16.481,
                16.514,
                16.547,
                16.58,
                16.613,
                16.646,
                16.68,
                16.713,
                16.746,
                16.78,
                16.814,
                16.848,
                16.881,
                16.915,
                16.95,
                16.984,
                17.018,
                17.053,
                17.087,
                17.122,
                17.157,
                17.192,
                17.227,
                17.262,
                17.297,
                17.333,
                17.368,
                17.404,
                17.44,
                17.476,
                17.512,
                17.548,
                17.584,
                17.62,
                17.657,
                17.693,
                17.73,
                17.767,
                17.804,
                17.841,
                17.878,
                17.915,
                17.953,
                17.99,
                18.028,
                18.065,
                18.103,
                18.141,
                18.179,
                18.217,
                18.256,
                18.294,
                18.332,
                18.371,
                18.41,
                18.448,
                18.487,
                18.526,
                18.565,
                18.604,
                18.644,
                18.683,
                18.722,
                18.762,
                18.802,
                18.841,
                18.881,
                18.921,
                18.961,
                19.001,
                19.041,
                19.081,
                19.122,
                19.162,
                19.202,
                19.243,
                19.284,
                19.324,
                19.365,
                19.406,
                19.447,
                19.488,
                19.529,
                19.57,
                19.612,
                19.653,
                19.694,
                19.736,
                19.777,
                19.819,
                19.861,
                19.902,
                19.944,
                19.986,
                20.028,
                20.07,
                20.112,
                20.154,
                20.196,
                20.239,
                20.281,
                20.323,
                20.365,
                20.408,
                20.45,
                20.493,
                20.535,
                20.578,
                20.62,
                20.663,
                20.706,
                20.748,
                20.791,
                20.834,
                20.877,
                20.919,
                20.962,
                21.005,
                21.048,
                21.091,
                21.134,
                21.177,
                21.22,
                21.263,
                21.306,
                21.349,
                21.392,
                21.435,
                21.478,
                21.521,
                21.564,
                21.608,
                21.651,
                21.694,
                21.737,
                21.78,
                21.823,
                21.867,
                21.91,
                21.953,
                21.996,
                22.039,
                22.083,
                22.126,
                22.169,
                22.212,
                22.255,
                22.299,
                22.342,
                22.385,
                22.428,
                22.472,
                22.515,
                22.558,
                22.601,
                22.644,
                22.688,
                22.731,
                22.774,
                22.817
            ],
            P75: [
                7.705,
                7.729,
                7.753,
                7.776,
                7.8,
                7.824,
                7.848,
                7.871,
                7.895,
                7.918,
                7.941,
                7.965,
                7.988,
                8.011,
                8.035,
                8.058,
                8.081,
                8.104,
                8.127,
                8.15,
                8.172,
                8.195,
                8.218,
                8.241,
                8.263,
                8.286,
                8.308,
                8.331,
                8.353,
                8.376,
                8.398,
                8.42,
                8.442,
                8.464,
                8.487,
                8.509,
                8.531,
                8.553,
                8.575,
                8.597,
                8.619,
                8.64,
                8.662,
                8.684,
                8.706,
                8.728,
                8.749,
                8.771,
                8.792,
                8.814,
                8.836,
                8.857,
                8.879,
                8.9,
                8.922,
                8.943,
                8.964,
                8.986,
                9.007,
                9.028,
                9.05,
                9.071,
                9.092,
                9.113,
                9.135,
                9.156,
                9.177,
                9.198,
                9.219,
                9.24,
                9.261,
                9.282,
                9.303,
                9.323,
                9.344,
                9.365,
                9.386,
                9.406,
                9.427,
                9.447,
                9.468,
                9.488,
                9.509,
                9.529,
                9.549,
                9.569,
                9.589,
                9.61,
                9.63,
                9.65,
                9.669,
                9.689,
                9.709,
                9.729,
                9.749,
                9.768,
                9.788,
                9.808,
                9.827,
                9.847,
                9.866,
                9.886,
                9.905,
                9.924,
                9.944,
                9.963,
                9.983,
                10.002,
                10.021,
                10.041,
                10.06,
                10.079,
                10.099,
                10.118,
                10.137,
                10.157,
                10.176,
                10.196,
                10.215,
                10.235,
                10.254,
                10.274,
                10.294,
                10.314,
                10.333,
                10.353,
                10.373,
                10.393,
                10.413,
                10.433,
                10.454,
                10.474,
                10.494,
                10.515,
                10.535,
                10.556,
                10.576,
                10.597,
                10.618,
                10.639,
                10.66,
                10.681,
                10.702,
                10.724,
                10.745,
                10.767,
                10.789,
                10.81,
                10.832,
                10.854,
                10.876,
                10.899,
                10.921,
                10.943,
                10.966,
                10.989,
                11.011,
                11.034,
                11.057,
                11.08,
                11.103,
                11.127,
                11.15,
                11.174,
                11.197,
                11.221,
                11.245,
                11.269,
                11.293,
                11.317,
                11.341,
                11.365,
                11.39,
                11.414,
                11.439,
                11.463,
                11.488,
                11.513,
                11.538,
                11.563,
                11.588,
                11.614,
                11.639,
                11.664,
                11.69,
                11.715,
                11.741,
                11.767,
                11.792,
                11.818,
                11.844,
                11.87,
                11.896,
                11.922,
                11.948,
                11.974,
                12.001,
                12.027,
                12.054,
                12.08,
                12.106,
                12.133,
                12.16,
                12.186,
                12.213,
                12.239,
                12.266,
                12.293,
                12.32,
                12.346,
                12.373,
                12.4,
                12.427,
                12.454,
                12.48,
                12.507,
                12.534,
                12.561,
                12.587,
                12.614,
                12.641,
                12.668,
                12.694,
                12.721,
                12.748,
                12.775,
                12.801,
                12.828,
                12.855,
                12.882,
                12.908,
                12.935,
                12.962,
                12.988,
                13.015,
                13.041,
                13.068,
                13.095,
                13.121,
                13.148,
                13.174,
                13.201,
                13.227,
                13.254,
                13.28,
                13.307,
                13.333,
                13.36,
                13.386,
                13.412,
                13.439,
                13.465,
                13.492,
                13.518,
                13.545,
                13.571,
                13.597,
                13.624,
                13.65,
                13.677,
                13.703,
                13.729,
                13.756,
                13.782,
                13.809,
                13.835,
                13.862,
                13.888,
                13.914,
                13.941,
                13.967,
                13.994,
                14.02,
                14.047,
                14.073,
                14.1,
                14.126,
                14.153,
                14.179,
                14.206,
                14.232,
                14.259,
                14.286,
                14.312,
                14.339,
                14.365,
                14.392,
                14.419,
                14.446,
                14.472,
                14.499,
                14.526,
                14.553,
                14.58,
                14.607,
                14.634,
                14.661,
                14.688,
                14.715,
                14.742,
                14.769,
                14.796,
                14.823,
                14.85,
                14.878,
                14.905,
                14.932,
                14.96,
                14.987,
                15.015,
                15.042,
                15.07,
                15.097,
                15.125,
                15.153,
                15.18,
                15.208,
                15.236,
                15.264,
                15.292,
                15.32,
                15.348,
                15.376,
                15.405,
                15.433,
                15.461,
                15.49,
                15.518,
                15.547,
                15.576,
                15.604,
                15.633,
                15.662,
                15.691,
                15.72,
                15.749,
                15.779,
                15.808,
                15.838,
                15.867,
                15.897,
                15.927,
                15.956,
                15.986,
                16.016,
                16.047,
                16.077,
                16.107,
                16.138,
                16.168,
                16.199,
                16.23,
                16.26,
                16.291,
                16.323,
                16.354,
                16.385,
                16.416,
                16.448,
                16.48,
                16.511,
                16.543,
                16.575,
                16.607,
                16.64,
                16.672,
                16.704,
                16.737,
                16.769,
                16.802,
                16.835,
                16.868,
                16.901,
                16.935,
                16.968,
                17.001,
                17.035,
                17.069,
                17.102,
                17.136,
                17.17,
                17.204,
                17.238,
                17.273,
                17.307,
                17.342,
                17.376,
                17.411,
                17.446,
                17.481,
                17.516,
                17.551,
                17.587,
                17.622,
                17.657,
                17.693,
                17.729,
                17.765,
                17.801,
                17.837,
                17.873,
                17.909,
                17.946,
                17.982,
                18.019,
                18.056,
                18.093,
                18.13,
                18.167,
                18.204,
                18.242,
                18.279,
                18.317,
                18.355,
                18.393,
                18.431,
                18.469,
                18.507,
                18.546,
                18.584,
                18.623,
                18.662,
                18.701,
                18.74,
                18.779,
                18.818,
                18.858,
                18.898,
                18.937,
                18.977,
                19.017,
                19.057,
                19.097,
                19.138,
                19.178,
                19.219,
                19.26,
                19.3,
                19.341,
                19.382,
                19.424,
                19.465,
                19.506,
                19.548,
                19.589,
                19.631,
                19.673,
                19.715,
                19.757,
                19.799,
                19.841,
                19.884,
                19.926,
                19.969,
                20.012,
                20.055,
                20.097,
                20.14,
                20.183,
                20.227,
                20.27,
                20.313,
                20.357,
                20.4,
                20.444,
                20.488,
                20.532,
                20.575,
                20.619,
                20.664,
                20.708,
                20.752,
                20.796,
                20.841,
                20.885,
                20.93,
                20.975,
                21.019,
                21.064,
                21.109,
                21.154,
                21.199,
                21.244,
                21.29,
                21.335,
                21.38,
                21.426,
                21.471,
                21.517,
                21.562,
                21.608,
                21.654,
                21.699,
                21.745,
                21.791,
                21.837,
                21.883,
                21.929,
                21.975,
                22.021,
                22.067,
                22.113,
                22.16,
                22.206,
                22.252,
                22.298,
                22.345,
                22.391,
                22.438,
                22.484,
                22.53,
                22.577,
                22.624,
                22.67,
                22.717,
                22.763,
                22.81,
                22.857,
                22.903,
                22.95,
                22.997,
                23.043,
                23.09,
                23.137,
                23.184,
                23.23,
                23.277,
                23.324,
                23.371,
                23.418,
                23.464,
                23.511,
                23.558,
                23.605,
                23.652,
                23.699,
                23.745,
                23.792,
                23.839,
                23.886,
                23.933,
                23.98,
                24.027,
                24.074,
                24.121,
                24.168,
                24.214,
                24.261,
                24.308,
                24.355,
                24.402
            ],
            P85: [
                7.971,
                7.996,
                8.021,
                8.045,
                8.07,
                8.094,
                8.119,
                8.143,
                8.167,
                8.192,
                8.216,
                8.24,
                8.264,
                8.288,
                8.312,
                8.336,
                8.36,
                8.383,
                8.407,
                8.431,
                8.454,
                8.478,
                8.501,
                8.525,
                8.548,
                8.571,
                8.595,
                8.618,
                8.641,
                8.664,
                8.687,
                8.71,
                8.733,
                8.756,
                8.779,
                8.802,
                8.825,
                8.847,
                8.87,
                8.893,
                8.915,
                8.938,
                8.96,
                8.983,
                9.005,
                9.028,
                9.05,
                9.072,
                9.095,
                9.117,
                9.139,
                9.161,
                9.184,
                9.206,
                9.228,
                9.25,
                9.272,
                9.294,
                9.316,
                9.338,
                9.36,
                9.382,
                9.404,
                9.426,
                9.448,
                9.47,
                9.491,
                9.513,
                9.535,
                9.557,
                9.578,
                9.6,
                9.621,
                9.643,
                9.664,
                9.686,
                9.707,
                9.728,
                9.75,
                9.771,
                9.792,
                9.813,
                9.834,
                9.855,
                9.876,
                9.897,
                9.917,
                9.938,
                9.959,
                9.979,
                10,
                10.02,
                10.041,
                10.061,
                10.082,
                10.102,
                10.122,
                10.142,
                10.163,
                10.183,
                10.203,
                10.223,
                10.243,
                10.263,
                10.283,
                10.303,
                10.323,
                10.343,
                10.363,
                10.383,
                10.403,
                10.423,
                10.443,
                10.463,
                10.483,
                10.503,
                10.523,
                10.543,
                10.563,
                10.583,
                10.603,
                10.624,
                10.644,
                10.664,
                10.685,
                10.705,
                10.726,
                10.746,
                10.767,
                10.788,
                10.809,
                10.83,
                10.85,
                10.872,
                10.893,
                10.914,
                10.935,
                10.957,
                10.978,
                11,
                11.021,
                11.043,
                11.065,
                11.087,
                11.109,
                11.132,
                11.154,
                11.177,
                11.199,
                11.222,
                11.245,
                11.267,
                11.291,
                11.314,
                11.337,
                11.361,
                11.384,
                11.408,
                11.431,
                11.455,
                11.479,
                11.503,
                11.527,
                11.551,
                11.576,
                11.6,
                11.625,
                11.65,
                11.674,
                11.699,
                11.724,
                11.749,
                11.774,
                11.8,
                11.825,
                11.851,
                11.876,
                11.902,
                11.928,
                11.953,
                11.98,
                12.006,
                12.032,
                12.058,
                12.084,
                12.111,
                12.137,
                12.164,
                12.19,
                12.217,
                12.244,
                12.27,
                12.297,
                12.324,
                12.351,
                12.378,
                12.406,
                12.433,
                12.46,
                12.487,
                12.515,
                12.542,
                12.57,
                12.597,
                12.625,
                12.652,
                12.68,
                12.707,
                12.735,
                12.763,
                12.79,
                12.818,
                12.846,
                12.873,
                12.901,
                12.929,
                12.956,
                12.984,
                13.012,
                13.04,
                13.067,
                13.095,
                13.123,
                13.15,
                13.178,
                13.206,
                13.233,
                13.261,
                13.288,
                13.316,
                13.344,
                13.371,
                13.399,
                13.426,
                13.454,
                13.481,
                13.509,
                13.536,
                13.564,
                13.591,
                13.619,
                13.646,
                13.674,
                13.701,
                13.728,
                13.756,
                13.783,
                13.811,
                13.838,
                13.866,
                13.893,
                13.92,
                13.948,
                13.975,
                14.002,
                14.03,
                14.057,
                14.084,
                14.112,
                14.139,
                14.167,
                14.194,
                14.221,
                14.249,
                14.276,
                14.303,
                14.331,
                14.358,
                14.385,
                14.413,
                14.44,
                14.468,
                14.495,
                14.522,
                14.55,
                14.577,
                14.605,
                14.632,
                14.66,
                14.687,
                14.715,
                14.742,
                14.77,
                14.798,
                14.825,
                14.853,
                14.881,
                14.908,
                14.936,
                14.964,
                14.992,
                15.02,
                15.047,
                15.075,
                15.103,
                15.131,
                15.159,
                15.187,
                15.215,
                15.244,
                15.272,
                15.3,
                15.328,
                15.356,
                15.385,
                15.413,
                15.441,
                15.47,
                15.498,
                15.527,
                15.555,
                15.584,
                15.613,
                15.641,
                15.67,
                15.699,
                15.728,
                15.757,
                15.786,
                15.815,
                15.844,
                15.873,
                15.902,
                15.932,
                15.961,
                15.991,
                16.02,
                16.05,
                16.08,
                16.109,
                16.139,
                16.169,
                16.199,
                16.23,
                16.26,
                16.29,
                16.321,
                16.351,
                16.382,
                16.412,
                16.443,
                16.474,
                16.505,
                16.536,
                16.568,
                16.599,
                16.63,
                16.662,
                16.694,
                16.725,
                16.758,
                16.79,
                16.821,
                16.854,
                16.886,
                16.919,
                16.951,
                16.984,
                17.017,
                17.05,
                17.083,
                17.116,
                17.149,
                17.183,
                17.216,
                17.25,
                17.283,
                17.317,
                17.351,
                17.385,
                17.42,
                17.454,
                17.489,
                17.523,
                17.558,
                17.593,
                17.627,
                17.663,
                17.698,
                17.733,
                17.768,
                17.804,
                17.839,
                17.875,
                17.911,
                17.947,
                17.983,
                18.019,
                18.056,
                18.092,
                18.129,
                18.165,
                18.202,
                18.239,
                18.276,
                18.313,
                18.35,
                18.388,
                18.425,
                18.463,
                18.5,
                18.538,
                18.576,
                18.614,
                18.652,
                18.691,
                18.729,
                18.768,
                18.807,
                18.846,
                18.884,
                18.924,
                18.963,
                19.002,
                19.042,
                19.082,
                19.121,
                19.162,
                19.201,
                19.242,
                19.282,
                19.322,
                19.363,
                19.404,
                19.445,
                19.486,
                19.527,
                19.568,
                19.61,
                19.651,
                19.693,
                19.735,
                19.777,
                19.819,
                19.861,
                19.903,
                19.946,
                19.988,
                20.031,
                20.074,
                20.117,
                20.16,
                20.203,
                20.246,
                20.29,
                20.333,
                20.377,
                20.421,
                20.465,
                20.508,
                20.553,
                20.597,
                20.641,
                20.686,
                20.73,
                20.775,
                20.82,
                20.864,
                20.909,
                20.954,
                20.999,
                21.045,
                21.09,
                21.136,
                21.181,
                21.227,
                21.273,
                21.318,
                21.364,
                21.41,
                21.456,
                21.503,
                21.549,
                21.595,
                21.642,
                21.688,
                21.735,
                21.782,
                21.828,
                21.875,
                21.922,
                21.969,
                22.016,
                22.064,
                22.111,
                22.158,
                22.206,
                22.253,
                22.301,
                22.348,
                22.396,
                22.444,
                22.491,
                22.539,
                22.587,
                22.635,
                22.683,
                22.731,
                22.779,
                22.827,
                22.875,
                22.924,
                22.972,
                23.02,
                23.069,
                23.117,
                23.165,
                23.214,
                23.262,
                23.311,
                23.359,
                23.408,
                23.457,
                23.505,
                23.554,
                23.603,
                23.651,
                23.7,
                23.749,
                23.798,
                23.847,
                23.895,
                23.944,
                23.993,
                24.042,
                24.091,
                24.14,
                24.189,
                24.237,
                24.286,
                24.335,
                24.384,
                24.433,
                24.482,
                24.531,
                24.58,
                24.629,
                24.678,
                24.727,
                24.776,
                24.825,
                24.874,
                24.923,
                24.972,
                25.021,
                25.07,
                25.119,
                25.169,
                25.218,
                25.267,
                25.316
            ],
            P90: [
                8.159,
                8.184,
                8.21,
                8.235,
                8.26,
                8.285,
                8.31,
                8.335,
                8.36,
                8.384,
                8.409,
                8.434,
                8.458,
                8.483,
                8.508,
                8.532,
                8.556,
                8.581,
                8.605,
                8.629,
                8.653,
                8.677,
                8.701,
                8.725,
                8.749,
                8.773,
                8.797,
                8.82,
                8.844,
                8.868,
                8.891,
                8.915,
                8.938,
                8.962,
                8.985,
                9.008,
                9.032,
                9.055,
                9.078,
                9.101,
                9.124,
                9.147,
                9.17,
                9.193,
                9.216,
                9.239,
                9.262,
                9.285,
                9.308,
                9.331,
                9.353,
                9.376,
                9.399,
                9.421,
                9.444,
                9.466,
                9.489,
                9.512,
                9.534,
                9.557,
                9.579,
                9.601,
                9.624,
                9.646,
                9.669,
                9.691,
                9.713,
                9.735,
                9.757,
                9.78,
                9.802,
                9.824,
                9.846,
                9.868,
                9.89,
                9.912,
                9.933,
                9.955,
                9.977,
                9.998,
                10.02,
                10.042,
                10.063,
                10.084,
                10.106,
                10.127,
                10.148,
                10.169,
                10.191,
                10.212,
                10.233,
                10.254,
                10.274,
                10.295,
                10.316,
                10.337,
                10.357,
                10.378,
                10.399,
                10.419,
                10.44,
                10.46,
                10.481,
                10.501,
                10.522,
                10.542,
                10.562,
                10.583,
                10.603,
                10.624,
                10.644,
                10.664,
                10.685,
                10.705,
                10.726,
                10.746,
                10.767,
                10.787,
                10.808,
                10.828,
                10.849,
                10.87,
                10.89,
                10.911,
                10.932,
                10.953,
                10.974,
                10.995,
                11.016,
                11.037,
                11.059,
                11.08,
                11.101,
                11.123,
                11.144,
                11.166,
                11.188,
                11.21,
                11.232,
                11.254,
                11.276,
                11.298,
                11.321,
                11.343,
                11.366,
                11.388,
                11.411,
                11.434,
                11.458,
                11.481,
                11.504,
                11.527,
                11.551,
                11.575,
                11.598,
                11.622,
                11.646,
                11.67,
                11.695,
                11.719,
                11.743,
                11.768,
                11.793,
                11.817,
                11.842,
                11.867,
                11.892,
                11.918,
                11.943,
                11.968,
                11.994,
                12.02,
                12.045,
                12.071,
                12.097,
                12.123,
                12.149,
                12.176,
                12.202,
                12.228,
                12.255,
                12.281,
                12.308,
                12.335,
                12.362,
                12.389,
                12.416,
                12.443,
                12.47,
                12.498,
                12.525,
                12.552,
                12.58,
                12.607,
                12.635,
                12.663,
                12.69,
                12.718,
                12.746,
                12.774,
                12.802,
                12.83,
                12.858,
                12.886,
                12.914,
                12.943,
                12.971,
                12.999,
                13.027,
                13.056,
                13.084,
                13.112,
                13.141,
                13.169,
                13.197,
                13.226,
                13.254,
                13.282,
                13.311,
                13.339,
                13.367,
                13.396,
                13.424,
                13.452,
                13.481,
                13.509,
                13.537,
                13.565,
                13.594,
                13.622,
                13.65,
                13.678,
                13.707,
                13.735,
                13.763,
                13.791,
                13.819,
                13.847,
                13.876,
                13.904,
                13.932,
                13.96,
                13.988,
                14.016,
                14.044,
                14.072,
                14.1,
                14.129,
                14.156,
                14.184,
                14.213,
                14.241,
                14.268,
                14.297,
                14.325,
                14.353,
                14.381,
                14.409,
                14.437,
                14.465,
                14.493,
                14.521,
                14.549,
                14.577,
                14.605,
                14.633,
                14.661,
                14.689,
                14.717,
                14.745,
                14.773,
                14.801,
                14.829,
                14.857,
                14.886,
                14.914,
                14.942,
                14.97,
                14.998,
                15.026,
                15.055,
                15.083,
                15.111,
                15.14,
                15.168,
                15.196,
                15.224,
                15.253,
                15.281,
                15.31,
                15.339,
                15.367,
                15.396,
                15.424,
                15.453,
                15.482,
                15.51,
                15.539,
                15.568,
                15.597,
                15.625,
                15.654,
                15.683,
                15.712,
                15.742,
                15.77,
                15.8,
                15.829,
                15.858,
                15.887,
                15.917,
                15.946,
                15.975,
                16.005,
                16.034,
                16.064,
                16.094,
                16.123,
                16.153,
                16.183,
                16.213,
                16.243,
                16.273,
                16.303,
                16.333,
                16.364,
                16.394,
                16.424,
                16.455,
                16.485,
                16.516,
                16.547,
                16.578,
                16.609,
                16.64,
                16.671,
                16.702,
                16.734,
                16.765,
                16.797,
                16.828,
                16.86,
                16.892,
                16.924,
                16.956,
                16.988,
                17.02,
                17.053,
                17.086,
                17.118,
                17.151,
                17.184,
                17.217,
                17.25,
                17.283,
                17.317,
                17.35,
                17.384,
                17.417,
                17.451,
                17.485,
                17.519,
                17.553,
                17.588,
                17.622,
                17.657,
                17.691,
                17.726,
                17.761,
                17.796,
                17.832,
                17.867,
                17.902,
                17.938,
                17.973,
                18.009,
                18.045,
                18.081,
                18.117,
                18.153,
                18.19,
                18.227,
                18.263,
                18.3,
                18.337,
                18.374,
                18.411,
                18.448,
                18.485,
                18.523,
                18.56,
                18.598,
                18.636,
                18.674,
                18.712,
                18.75,
                18.788,
                18.827,
                18.865,
                18.904,
                18.943,
                18.982,
                19.021,
                19.06,
                19.099,
                19.139,
                19.178,
                19.218,
                19.258,
                19.298,
                19.338,
                19.378,
                19.419,
                19.459,
                19.5,
                19.54,
                19.582,
                19.623,
                19.664,
                19.705,
                19.747,
                19.788,
                19.83,
                19.872,
                19.914,
                19.956,
                19.999,
                20.041,
                20.084,
                20.127,
                20.17,
                20.213,
                20.256,
                20.299,
                20.343,
                20.386,
                20.43,
                20.474,
                20.518,
                20.562,
                20.606,
                20.65,
                20.695,
                20.739,
                20.784,
                20.829,
                20.874,
                20.919,
                20.964,
                21.009,
                21.054,
                21.1,
                21.146,
                21.191,
                21.237,
                21.283,
                21.329,
                21.375,
                21.422,
                21.468,
                21.514,
                21.561,
                21.608,
                21.654,
                21.701,
                21.748,
                21.796,
                21.843,
                21.89,
                21.937,
                21.985,
                22.033,
                22.08,
                22.128,
                22.176,
                22.224,
                22.272,
                22.32,
                22.368,
                22.416,
                22.465,
                22.513,
                22.562,
                22.61,
                22.659,
                22.708,
                22.756,
                22.805,
                22.854,
                22.903,
                22.952,
                23.002,
                23.051,
                23.1,
                23.149,
                23.199,
                23.248,
                23.298,
                23.347,
                23.397,
                23.446,
                23.496,
                23.546,
                23.595,
                23.645,
                23.695,
                23.745,
                23.795,
                23.845,
                23.895,
                23.945,
                23.995,
                24.045,
                24.095,
                24.145,
                24.195,
                24.246,
                24.296,
                24.346,
                24.397,
                24.447,
                24.497,
                24.548,
                24.598,
                24.648,
                24.699,
                24.749,
                24.799,
                24.85,
                24.9,
                24.951,
                25.001,
                25.052,
                25.102,
                25.153,
                25.203,
                25.254,
                25.304,
                25.355,
                25.405,
                25.456,
                25.506,
                25.557,
                25.608,
                25.658,
                25.709,
                25.759,
                25.81,
                25.86,
                25.911,
                25.961
            ],
            P95: [
                8.449,
                8.475,
                8.501,
                8.527,
                8.553,
                8.579,
                8.605,
                8.631,
                8.656,
                8.682,
                8.707,
                8.733,
                8.758,
                8.784,
                8.809,
                8.834,
                8.86,
                8.885,
                8.91,
                8.935,
                8.96,
                8.985,
                9.009,
                9.034,
                9.059,
                9.083,
                9.108,
                9.133,
                9.157,
                9.181,
                9.206,
                9.23,
                9.254,
                9.278,
                9.303,
                9.327,
                9.351,
                9.375,
                9.399,
                9.423,
                9.447,
                9.47,
                9.494,
                9.518,
                9.542,
                9.565,
                9.589,
                9.613,
                9.636,
                9.66,
                9.683,
                9.707,
                9.73,
                9.754,
                9.777,
                9.8,
                9.824,
                9.847,
                9.87,
                9.893,
                9.917,
                9.94,
                9.963,
                9.986,
                10.009,
                10.032,
                10.055,
                10.078,
                10.101,
                10.124,
                10.147,
                10.169,
                10.192,
                10.215,
                10.237,
                10.26,
                10.282,
                10.305,
                10.328,
                10.35,
                10.372,
                10.394,
                10.417,
                10.438,
                10.461,
                10.483,
                10.504,
                10.526,
                10.548,
                10.57,
                10.591,
                10.613,
                10.635,
                10.656,
                10.678,
                10.699,
                10.72,
                10.742,
                10.763,
                10.784,
                10.805,
                10.827,
                10.848,
                10.869,
                10.89,
                10.911,
                10.932,
                10.953,
                10.974,
                10.995,
                11.016,
                11.037,
                11.058,
                11.079,
                11.1,
                11.121,
                11.143,
                11.164,
                11.185,
                11.206,
                11.227,
                11.249,
                11.27,
                11.292,
                11.313,
                11.335,
                11.356,
                11.378,
                11.4,
                11.422,
                11.444,
                11.466,
                11.488,
                11.51,
                11.533,
                11.555,
                11.577,
                11.6,
                11.623,
                11.646,
                11.668,
                11.691,
                11.715,
                11.738,
                11.761,
                11.785,
                11.808,
                11.832,
                11.856,
                11.88,
                11.904,
                11.928,
                11.952,
                11.977,
                12.001,
                12.026,
                12.051,
                12.076,
                12.101,
                12.126,
                12.151,
                12.176,
                12.202,
                12.227,
                12.253,
                12.279,
                12.305,
                12.331,
                12.357,
                12.383,
                12.41,
                12.436,
                12.463,
                12.49,
                12.516,
                12.543,
                12.57,
                12.598,
                12.625,
                12.652,
                12.679,
                12.707,
                12.734,
                12.762,
                12.79,
                12.818,
                12.846,
                12.874,
                12.902,
                12.93,
                12.958,
                12.987,
                13.015,
                13.044,
                13.072,
                13.101,
                13.13,
                13.158,
                13.187,
                13.216,
                13.245,
                13.274,
                13.303,
                13.332,
                13.361,
                13.39,
                13.42,
                13.449,
                13.478,
                13.507,
                13.537,
                13.566,
                13.595,
                13.625,
                13.654,
                13.683,
                13.712,
                13.742,
                13.771,
                13.801,
                13.83,
                13.859,
                13.888,
                13.918,
                13.947,
                13.976,
                14.006,
                14.035,
                14.064,
                14.093,
                14.123,
                14.152,
                14.181,
                14.21,
                14.24,
                14.269,
                14.298,
                14.327,
                14.356,
                14.385,
                14.414,
                14.443,
                14.473,
                14.502,
                14.531,
                14.56,
                14.589,
                14.618,
                14.647,
                14.676,
                14.705,
                14.734,
                14.763,
                14.792,
                14.821,
                14.85,
                14.879,
                14.908,
                14.937,
                14.967,
                14.996,
                15.025,
                15.054,
                15.083,
                15.112,
                15.141,
                15.17,
                15.199,
                15.228,
                15.257,
                15.287,
                15.316,
                15.345,
                15.374,
                15.403,
                15.432,
                15.461,
                15.491,
                15.52,
                15.549,
                15.579,
                15.608,
                15.637,
                15.667,
                15.696,
                15.726,
                15.755,
                15.784,
                15.814,
                15.844,
                15.873,
                15.903,
                15.933,
                15.962,
                15.992,
                16.022,
                16.052,
                16.082,
                16.112,
                16.142,
                16.171,
                16.201,
                16.232,
                16.262,
                16.292,
                16.322,
                16.352,
                16.383,
                16.413,
                16.443,
                16.474,
                16.504,
                16.535,
                16.566,
                16.596,
                16.627,
                16.658,
                16.689,
                16.72,
                16.751,
                16.782,
                16.813,
                16.844,
                16.876,
                16.907,
                16.939,
                16.97,
                17.002,
                17.034,
                17.065,
                17.097,
                17.13,
                17.161,
                17.194,
                17.226,
                17.258,
                17.291,
                17.324,
                17.356,
                17.389,
                17.422,
                17.455,
                17.489,
                17.522,
                17.555,
                17.589,
                17.622,
                17.656,
                17.69,
                17.724,
                17.758,
                17.793,
                17.827,
                17.861,
                17.896,
                17.931,
                17.966,
                18,
                18.036,
                18.071,
                18.106,
                18.142,
                18.177,
                18.213,
                18.249,
                18.285,
                18.321,
                18.357,
                18.394,
                18.43,
                18.467,
                18.504,
                18.541,
                18.578,
                18.615,
                18.652,
                18.69,
                18.727,
                18.765,
                18.803,
                18.841,
                18.879,
                18.917,
                18.955,
                18.994,
                19.032,
                19.071,
                19.11,
                19.149,
                19.188,
                19.227,
                19.266,
                19.306,
                19.345,
                19.385,
                19.425,
                19.464,
                19.505,
                19.545,
                19.585,
                19.626,
                19.666,
                19.707,
                19.748,
                19.789,
                19.83,
                19.871,
                19.913,
                19.954,
                19.996,
                20.038,
                20.08,
                20.122,
                20.164,
                20.207,
                20.249,
                20.292,
                20.335,
                20.378,
                20.421,
                20.465,
                20.508,
                20.552,
                20.596,
                20.639,
                20.683,
                20.728,
                20.772,
                20.816,
                20.861,
                20.906,
                20.951,
                20.996,
                21.041,
                21.086,
                21.132,
                21.178,
                21.223,
                21.269,
                21.315,
                21.361,
                21.408,
                21.454,
                21.5,
                21.547,
                21.594,
                21.641,
                21.688,
                21.735,
                21.782,
                21.83,
                21.877,
                21.925,
                21.973,
                22.021,
                22.069,
                22.117,
                22.165,
                22.213,
                22.262,
                22.31,
                22.359,
                22.408,
                22.457,
                22.506,
                22.555,
                22.604,
                22.653,
                22.703,
                22.752,
                22.802,
                22.852,
                22.901,
                22.951,
                23.001,
                23.051,
                23.102,
                23.152,
                23.202,
                23.252,
                23.303,
                23.354,
                23.404,
                23.455,
                23.506,
                23.557,
                23.608,
                23.659,
                23.71,
                23.762,
                23.813,
                23.864,
                23.916,
                23.967,
                24.019,
                24.07,
                24.122,
                24.174,
                24.226,
                24.277,
                24.329,
                24.381,
                24.433,
                24.485,
                24.537,
                24.59,
                24.642,
                24.694,
                24.746,
                24.798,
                24.851,
                24.903,
                24.955,
                25.008,
                25.06,
                25.113,
                25.166,
                25.218,
                25.271,
                25.323,
                25.376,
                25.429,
                25.481,
                25.534,
                25.587,
                25.639,
                25.692,
                25.745,
                25.797,
                25.85,
                25.903,
                25.956,
                26.009,
                26.061,
                26.114,
                26.167,
                26.22,
                26.273,
                26.326,
                26.379,
                26.432,
                26.485,
                26.538,
                26.591,
                26.644,
                26.697,
                26.749,
                26.802,
                26.855,
                26.908,
                26.961
            ],
            P97: [
                8.645,
                8.671,
                8.698,
                8.725,
                8.751,
                8.778,
                8.804,
                8.83,
                8.857,
                8.883,
                8.909,
                8.935,
                8.961,
                8.987,
                9.013,
                9.039,
                9.065,
                9.09,
                9.116,
                9.141,
                9.167,
                9.192,
                9.217,
                9.243,
                9.268,
                9.293,
                9.318,
                9.343,
                9.368,
                9.393,
                9.418,
                9.443,
                9.468,
                9.493,
                9.517,
                9.542,
                9.567,
                9.591,
                9.616,
                9.64,
                9.664,
                9.689,
                9.713,
                9.738,
                9.762,
                9.786,
                9.81,
                9.834,
                9.858,
                9.882,
                9.906,
                9.93,
                9.954,
                9.978,
                10.002,
                10.026,
                10.05,
                10.073,
                10.097,
                10.121,
                10.145,
                10.168,
                10.192,
                10.215,
                10.239,
                10.263,
                10.286,
                10.31,
                10.333,
                10.356,
                10.38,
                10.403,
                10.426,
                10.449,
                10.472,
                10.495,
                10.518,
                10.541,
                10.564,
                10.587,
                10.61,
                10.633,
                10.655,
                10.678,
                10.7,
                10.723,
                10.745,
                10.767,
                10.79,
                10.812,
                10.834,
                10.856,
                10.878,
                10.9,
                10.922,
                10.944,
                10.966,
                10.987,
                11.009,
                11.031,
                11.052,
                11.074,
                11.096,
                11.117,
                11.139,
                11.16,
                11.182,
                11.203,
                11.224,
                11.246,
                11.268,
                11.289,
                11.31,
                11.332,
                11.353,
                11.375,
                11.397,
                11.418,
                11.44,
                11.462,
                11.483,
                11.505,
                11.527,
                11.549,
                11.571,
                11.593,
                11.615,
                11.637,
                11.66,
                11.682,
                11.704,
                11.727,
                11.749,
                11.772,
                11.795,
                11.818,
                11.841,
                11.864,
                11.887,
                11.91,
                11.933,
                11.957,
                11.981,
                12.004,
                12.028,
                12.052,
                12.076,
                12.1,
                12.125,
                12.149,
                12.174,
                12.198,
                12.223,
                12.248,
                12.273,
                12.298,
                12.324,
                12.349,
                12.375,
                12.4,
                12.426,
                12.452,
                12.478,
                12.504,
                12.531,
                12.557,
                12.583,
                12.61,
                12.637,
                12.664,
                12.691,
                12.718,
                12.745,
                12.772,
                12.799,
                12.827,
                12.854,
                12.882,
                12.91,
                12.938,
                12.966,
                12.994,
                13.022,
                13.051,
                13.079,
                13.108,
                13.136,
                13.165,
                13.193,
                13.222,
                13.251,
                13.28,
                13.309,
                13.338,
                13.368,
                13.397,
                13.426,
                13.456,
                13.485,
                13.514,
                13.544,
                13.574,
                13.604,
                13.633,
                13.663,
                13.693,
                13.723,
                13.753,
                13.782,
                13.812,
                13.842,
                13.872,
                13.902,
                13.932,
                13.962,
                13.992,
                14.022,
                14.052,
                14.082,
                14.112,
                14.142,
                14.172,
                14.202,
                14.232,
                14.262,
                14.292,
                14.322,
                14.352,
                14.382,
                14.412,
                14.442,
                14.472,
                14.502,
                14.531,
                14.561,
                14.591,
                14.621,
                14.651,
                14.681,
                14.71,
                14.74,
                14.77,
                14.8,
                14.83,
                14.859,
                14.889,
                14.919,
                14.949,
                14.978,
                15.008,
                15.038,
                15.068,
                15.097,
                15.127,
                15.157,
                15.186,
                15.216,
                15.246,
                15.276,
                15.306,
                15.335,
                15.365,
                15.395,
                15.424,
                15.454,
                15.484,
                15.514,
                15.544,
                15.573,
                15.603,
                15.633,
                15.663,
                15.693,
                15.723,
                15.753,
                15.783,
                15.812,
                15.843,
                15.872,
                15.902,
                15.933,
                15.963,
                15.993,
                16.023,
                16.053,
                16.083,
                16.113,
                16.143,
                16.174,
                16.204,
                16.235,
                16.265,
                16.295,
                16.326,
                16.356,
                16.387,
                16.417,
                16.448,
                16.479,
                16.51,
                16.54,
                16.571,
                16.602,
                16.633,
                16.664,
                16.694,
                16.725,
                16.757,
                16.788,
                16.819,
                16.85,
                16.882,
                16.913,
                16.944,
                16.976,
                17.007,
                17.039,
                17.071,
                17.102,
                17.134,
                17.166,
                17.198,
                17.23,
                17.262,
                17.295,
                17.327,
                17.36,
                17.392,
                17.425,
                17.457,
                17.49,
                17.523,
                17.556,
                17.589,
                17.622,
                17.655,
                17.689,
                17.722,
                17.756,
                17.79,
                17.824,
                17.857,
                17.892,
                17.926,
                17.96,
                17.995,
                18.029,
                18.064,
                18.099,
                18.133,
                18.169,
                18.204,
                18.239,
                18.274,
                18.31,
                18.346,
                18.382,
                18.417,
                18.453,
                18.49,
                18.526,
                18.563,
                18.599,
                18.636,
                18.673,
                18.71,
                18.747,
                18.784,
                18.821,
                18.859,
                18.897,
                18.935,
                18.973,
                19.011,
                19.049,
                19.087,
                19.125,
                19.164,
                19.203,
                19.241,
                19.281,
                19.32,
                19.359,
                19.398,
                19.438,
                19.478,
                19.517,
                19.557,
                19.597,
                19.637,
                19.677,
                19.718,
                19.758,
                19.799,
                19.84,
                19.881,
                19.922,
                19.963,
                20.004,
                20.046,
                20.087,
                20.129,
                20.171,
                20.213,
                20.255,
                20.298,
                20.34,
                20.383,
                20.425,
                20.468,
                20.511,
                20.555,
                20.598,
                20.642,
                20.685,
                20.729,
                20.773,
                20.817,
                20.861,
                20.906,
                20.95,
                20.995,
                21.04,
                21.085,
                21.13,
                21.175,
                21.221,
                21.266,
                21.312,
                21.358,
                21.404,
                21.45,
                21.497,
                21.543,
                21.59,
                21.637,
                21.684,
                21.731,
                21.778,
                21.825,
                21.873,
                21.92,
                21.968,
                22.016,
                22.064,
                22.112,
                22.16,
                22.209,
                22.258,
                22.306,
                22.355,
                22.404,
                22.453,
                22.502,
                22.551,
                22.601,
                22.65,
                22.7,
                22.749,
                22.799,
                22.849,
                22.899,
                22.949,
                23,
                23.05,
                23.101,
                23.151,
                23.202,
                23.253,
                23.304,
                23.355,
                23.406,
                23.457,
                23.509,
                23.56,
                23.612,
                23.664,
                23.715,
                23.767,
                23.819,
                23.871,
                23.923,
                23.975,
                24.027,
                24.08,
                24.132,
                24.185,
                24.237,
                24.29,
                24.343,
                24.396,
                24.449,
                24.502,
                24.555,
                24.608,
                24.661,
                24.714,
                24.767,
                24.821,
                24.874,
                24.928,
                24.981,
                25.035,
                25.088,
                25.142,
                25.196,
                25.249,
                25.303,
                25.357,
                25.411,
                25.464,
                25.518,
                25.572,
                25.626,
                25.68,
                25.734,
                25.789,
                25.843,
                25.897,
                25.951,
                26.006,
                26.06,
                26.114,
                26.168,
                26.223,
                26.277,
                26.331,
                26.386,
                26.44,
                26.494,
                26.549,
                26.603,
                26.657,
                26.712,
                26.766,
                26.821,
                26.875,
                26.93,
                26.985,
                27.039,
                27.094,
                27.148,
                27.203,
                27.257,
                27.312,
                27.366,
                27.421,
                27.476,
                27.53,
                27.585,
                27.639
            ],
            P99: [
                9.032,
                9.06,
                9.088,
                9.115,
                9.143,
                9.171,
                9.198,
                9.226,
                9.253,
                9.28,
                9.308,
                9.335,
                9.362,
                9.389,
                9.416,
                9.443,
                9.47,
                9.497,
                9.523,
                9.55,
                9.577,
                9.603,
                9.629,
                9.656,
                9.682,
                9.708,
                9.735,
                9.761,
                9.787,
                9.813,
                9.839,
                9.865,
                9.89,
                9.916,
                9.942,
                9.968,
                9.993,
                10.019,
                10.044,
                10.07,
                10.095,
                10.121,
                10.146,
                10.171,
                10.197,
                10.222,
                10.247,
                10.272,
                10.297,
                10.322,
                10.347,
                10.372,
                10.397,
                10.422,
                10.447,
                10.472,
                10.497,
                10.521,
                10.546,
                10.571,
                10.595,
                10.62,
                10.645,
                10.669,
                10.694,
                10.718,
                10.743,
                10.767,
                10.791,
                10.816,
                10.84,
                10.864,
                10.888,
                10.913,
                10.937,
                10.961,
                10.985,
                11.009,
                11.033,
                11.056,
                11.08,
                11.104,
                11.127,
                11.151,
                11.174,
                11.198,
                11.221,
                11.244,
                11.267,
                11.29,
                11.313,
                11.336,
                11.359,
                11.382,
                11.405,
                11.428,
                11.45,
                11.473,
                11.496,
                11.518,
                11.54,
                11.563,
                11.585,
                11.608,
                11.63,
                11.652,
                11.675,
                11.697,
                11.72,
                11.742,
                11.764,
                11.786,
                11.809,
                11.831,
                11.854,
                11.876,
                11.899,
                11.921,
                11.944,
                11.966,
                11.989,
                12.012,
                12.034,
                12.057,
                12.08,
                12.103,
                12.126,
                12.149,
                12.172,
                12.195,
                12.219,
                12.242,
                12.265,
                12.289,
                12.313,
                12.337,
                12.36,
                12.384,
                12.409,
                12.433,
                12.457,
                12.481,
                12.506,
                12.531,
                12.556,
                12.58,
                12.606,
                12.631,
                12.656,
                12.682,
                12.707,
                12.733,
                12.759,
                12.785,
                12.811,
                12.837,
                12.863,
                12.89,
                12.916,
                12.943,
                12.97,
                12.997,
                13.024,
                13.051,
                13.079,
                13.106,
                13.134,
                13.162,
                13.189,
                13.217,
                13.245,
                13.273,
                13.302,
                13.33,
                13.359,
                13.387,
                13.416,
                13.445,
                13.474,
                13.503,
                13.532,
                13.561,
                13.591,
                13.62,
                13.65,
                13.68,
                13.709,
                13.739,
                13.769,
                13.799,
                13.829,
                13.86,
                13.89,
                13.92,
                13.951,
                13.981,
                14.012,
                14.043,
                14.073,
                14.104,
                14.135,
                14.166,
                14.197,
                14.228,
                14.259,
                14.29,
                14.321,
                14.352,
                14.384,
                14.415,
                14.446,
                14.477,
                14.509,
                14.54,
                14.571,
                14.603,
                14.634,
                14.665,
                14.696,
                14.728,
                14.759,
                14.79,
                14.822,
                14.853,
                14.884,
                14.916,
                14.947,
                14.978,
                15.009,
                15.041,
                15.072,
                15.103,
                15.135,
                15.166,
                15.197,
                15.228,
                15.259,
                15.29,
                15.322,
                15.353,
                15.384,
                15.415,
                15.446,
                15.477,
                15.508,
                15.54,
                15.571,
                15.602,
                15.633,
                15.664,
                15.695,
                15.726,
                15.757,
                15.788,
                15.819,
                15.85,
                15.882,
                15.913,
                15.944,
                15.975,
                16.006,
                16.037,
                16.069,
                16.1,
                16.131,
                16.162,
                16.193,
                16.224,
                16.256,
                16.287,
                16.318,
                16.349,
                16.381,
                16.412,
                16.443,
                16.475,
                16.506,
                16.538,
                16.569,
                16.6,
                16.632,
                16.663,
                16.695,
                16.726,
                16.758,
                16.789,
                16.821,
                16.853,
                16.884,
                16.916,
                16.948,
                16.98,
                17.012,
                17.044,
                17.076,
                17.108,
                17.14,
                17.172,
                17.204,
                17.237,
                17.269,
                17.301,
                17.333,
                17.366,
                17.399,
                17.431,
                17.463,
                17.496,
                17.529,
                17.561,
                17.594,
                17.627,
                17.66,
                17.693,
                17.726,
                17.759,
                17.792,
                17.826,
                17.859,
                17.892,
                17.926,
                17.96,
                17.993,
                18.027,
                18.061,
                18.095,
                18.129,
                18.163,
                18.198,
                18.232,
                18.266,
                18.301,
                18.336,
                18.37,
                18.405,
                18.44,
                18.476,
                18.511,
                18.546,
                18.581,
                18.617,
                18.653,
                18.689,
                18.724,
                18.761,
                18.797,
                18.833,
                18.87,
                18.907,
                18.943,
                18.98,
                19.017,
                19.054,
                19.091,
                19.129,
                19.167,
                19.204,
                19.242,
                19.28,
                19.318,
                19.356,
                19.395,
                19.433,
                19.472,
                19.511,
                19.55,
                19.588,
                19.628,
                19.667,
                19.707,
                19.747,
                19.787,
                19.827,
                19.867,
                19.907,
                19.947,
                19.988,
                20.028,
                20.069,
                20.11,
                20.151,
                20.192,
                20.233,
                20.275,
                20.317,
                20.359,
                20.4,
                20.442,
                20.485,
                20.527,
                20.569,
                20.612,
                20.655,
                20.697,
                20.741,
                20.784,
                20.827,
                20.87,
                20.914,
                20.958,
                21.001,
                21.046,
                21.09,
                21.134,
                21.178,
                21.223,
                21.268,
                21.313,
                21.358,
                21.403,
                21.449,
                21.494,
                21.54,
                21.586,
                21.632,
                21.678,
                21.725,
                21.771,
                21.818,
                21.865,
                21.912,
                21.959,
                22.006,
                22.054,
                22.101,
                22.149,
                22.197,
                22.246,
                22.294,
                22.342,
                22.391,
                22.44,
                22.488,
                22.538,
                22.587,
                22.636,
                22.686,
                22.735,
                22.785,
                22.835,
                22.886,
                22.936,
                22.986,
                23.037,
                23.087,
                23.138,
                23.189,
                23.241,
                23.292,
                23.343,
                23.394,
                23.446,
                23.498,
                23.55,
                23.602,
                23.654,
                23.707,
                23.759,
                23.812,
                23.864,
                23.917,
                23.97,
                24.023,
                24.076,
                24.129,
                24.183,
                24.236,
                24.29,
                24.344,
                24.397,
                24.451,
                24.506,
                24.56,
                24.614,
                24.668,
                24.723,
                24.778,
                24.832,
                24.887,
                24.942,
                24.997,
                25.052,
                25.107,
                25.162,
                25.218,
                25.273,
                25.329,
                25.384,
                25.44,
                25.496,
                25.552,
                25.608,
                25.664,
                25.72,
                25.776,
                25.832,
                25.888,
                25.945,
                26.001,
                26.058,
                26.114,
                26.171,
                26.227,
                26.284,
                26.341,
                26.398,
                26.454,
                26.511,
                26.568,
                26.625,
                26.682,
                26.739,
                26.796,
                26.853,
                26.911,
                26.968,
                27.026,
                27.083,
                27.14,
                27.198,
                27.255,
                27.312,
                27.37,
                27.427,
                27.485,
                27.542,
                27.6,
                27.657,
                27.715,
                27.772,
                27.83,
                27.888,
                27.945,
                28.003,
                28.061,
                28.118,
                28.176,
                28.234,
                28.292,
                28.35,
                28.408,
                28.465,
                28.523,
                28.581,
                28.639,
                28.696,
                28.754,
                28.812,
                28.87,
                28.928,
                28.986
            ],
            P999: [
                9.754,
                9.784,
                9.814,
                9.844,
                9.873,
                9.903,
                9.933,
                9.963,
                9.992,
                10.021,
                10.051,
                10.08,
                10.109,
                10.138,
                10.167,
                10.196,
                10.225,
                10.254,
                10.283,
                10.312,
                10.34,
                10.369,
                10.397,
                10.425,
                10.454,
                10.482,
                10.51,
                10.538,
                10.566,
                10.594,
                10.622,
                10.65,
                10.678,
                10.705,
                10.733,
                10.761,
                10.788,
                10.816,
                10.843,
                10.87,
                10.898,
                10.925,
                10.952,
                10.98,
                11.007,
                11.034,
                11.061,
                11.088,
                11.115,
                11.142,
                11.168,
                11.195,
                11.222,
                11.249,
                11.276,
                11.302,
                11.329,
                11.356,
                11.382,
                11.409,
                11.435,
                11.462,
                11.488,
                11.514,
                11.541,
                11.567,
                11.594,
                11.62,
                11.646,
                11.672,
                11.698,
                11.724,
                11.75,
                11.776,
                11.802,
                11.827,
                11.853,
                11.879,
                11.905,
                11.93,
                11.955,
                11.981,
                12.006,
                12.031,
                12.056,
                12.082,
                12.106,
                12.131,
                12.156,
                12.181,
                12.205,
                12.23,
                12.255,
                12.279,
                12.304,
                12.328,
                12.352,
                12.377,
                12.401,
                12.425,
                12.449,
                12.474,
                12.497,
                12.521,
                12.546,
                12.569,
                12.593,
                12.618,
                12.641,
                12.665,
                12.689,
                12.713,
                12.737,
                12.761,
                12.785,
                12.809,
                12.833,
                12.857,
                12.881,
                12.906,
                12.93,
                12.954,
                12.978,
                13.003,
                13.027,
                13.052,
                13.076,
                13.101,
                13.126,
                13.151,
                13.176,
                13.201,
                13.226,
                13.251,
                13.277,
                13.303,
                13.328,
                13.354,
                13.38,
                13.406,
                13.431,
                13.458,
                13.484,
                13.511,
                13.537,
                13.564,
                13.591,
                13.618,
                13.645,
                13.672,
                13.7,
                13.727,
                13.755,
                13.783,
                13.811,
                13.839,
                13.867,
                13.896,
                13.924,
                13.953,
                13.982,
                14.011,
                14.04,
                14.069,
                14.098,
                14.128,
                14.157,
                14.187,
                14.217,
                14.247,
                14.277,
                14.307,
                14.338,
                14.369,
                14.399,
                14.43,
                14.461,
                14.492,
                14.523,
                14.554,
                14.586,
                14.617,
                14.648,
                14.68,
                14.712,
                14.744,
                14.776,
                14.808,
                14.84,
                14.873,
                14.905,
                14.938,
                14.971,
                15.003,
                15.036,
                15.068,
                15.101,
                15.135,
                15.168,
                15.201,
                15.234,
                15.267,
                15.301,
                15.334,
                15.367,
                15.401,
                15.434,
                15.468,
                15.502,
                15.535,
                15.569,
                15.603,
                15.636,
                15.67,
                15.704,
                15.738,
                15.771,
                15.805,
                15.839,
                15.873,
                15.907,
                15.94,
                15.974,
                16.008,
                16.042,
                16.075,
                16.109,
                16.143,
                16.177,
                16.21,
                16.244,
                16.278,
                16.312,
                16.345,
                16.379,
                16.413,
                16.447,
                16.48,
                16.514,
                16.547,
                16.581,
                16.615,
                16.648,
                16.682,
                16.716,
                16.749,
                16.783,
                16.817,
                16.85,
                16.884,
                16.918,
                16.952,
                16.985,
                17.018,
                17.052,
                17.086,
                17.12,
                17.153,
                17.187,
                17.221,
                17.255,
                17.288,
                17.322,
                17.356,
                17.389,
                17.423,
                17.457,
                17.491,
                17.525,
                17.559,
                17.593,
                17.626,
                17.66,
                17.694,
                17.728,
                17.762,
                17.796,
                17.831,
                17.864,
                17.898,
                17.933,
                17.967,
                18.001,
                18.035,
                18.069,
                18.104,
                18.138,
                18.173,
                18.207,
                18.242,
                18.277,
                18.311,
                18.346,
                18.38,
                18.415,
                18.45,
                18.485,
                18.52,
                18.555,
                18.59,
                18.625,
                18.66,
                18.696,
                18.731,
                18.766,
                18.801,
                18.837,
                18.872,
                18.908,
                18.944,
                18.979,
                19.015,
                19.051,
                19.087,
                19.123,
                19.159,
                19.195,
                19.232,
                19.268,
                19.304,
                19.341,
                19.378,
                19.415,
                19.451,
                19.488,
                19.526,
                19.563,
                19.6,
                19.638,
                19.675,
                19.712,
                19.751,
                19.788,
                19.826,
                19.864,
                19.902,
                19.941,
                19.979,
                20.018,
                20.056,
                20.096,
                20.134,
                20.174,
                20.213,
                20.252,
                20.292,
                20.332,
                20.372,
                20.412,
                20.452,
                20.492,
                20.533,
                20.573,
                20.614,
                20.655,
                20.696,
                20.738,
                20.779,
                20.82,
                20.862,
                20.904,
                20.946,
                20.988,
                21.031,
                21.073,
                21.116,
                21.158,
                21.201,
                21.244,
                21.287,
                21.332,
                21.375,
                21.419,
                21.463,
                21.507,
                21.551,
                21.595,
                21.64,
                21.684,
                21.729,
                21.775,
                21.82,
                21.865,
                21.91,
                21.957,
                22.002,
                22.048,
                22.094,
                22.141,
                22.187,
                22.233,
                22.28,
                22.327,
                22.374,
                22.421,
                22.468,
                22.516,
                22.564,
                22.611,
                22.66,
                22.708,
                22.756,
                22.804,
                22.853,
                22.902,
                22.951,
                23,
                23.05,
                23.099,
                23.149,
                23.199,
                23.249,
                23.3,
                23.35,
                23.4,
                23.451,
                23.502,
                23.553,
                23.604,
                23.656,
                23.708,
                23.759,
                23.812,
                23.864,
                23.916,
                23.969,
                24.022,
                24.075,
                24.127,
                24.181,
                24.235,
                24.288,
                24.342,
                24.396,
                24.45,
                24.505,
                24.559,
                24.613,
                24.668,
                24.724,
                24.779,
                24.834,
                24.889,
                24.945,
                25.001,
                25.057,
                25.113,
                25.17,
                25.226,
                25.282,
                25.339,
                25.396,
                25.453,
                25.51,
                25.567,
                25.625,
                25.683,
                25.74,
                25.798,
                25.857,
                25.914,
                25.972,
                26.031,
                26.089,
                26.148,
                26.207,
                26.266,
                26.325,
                26.384,
                26.444,
                26.503,
                26.563,
                26.623,
                26.683,
                26.743,
                26.803,
                26.863,
                26.922,
                26.983,
                27.043,
                27.104,
                27.165,
                27.226,
                27.287,
                27.348,
                27.409,
                27.47,
                27.532,
                27.593,
                27.655,
                27.716,
                27.778,
                27.84,
                27.902,
                27.964,
                28.026,
                28.088,
                28.15,
                28.212,
                28.275,
                28.337,
                28.4,
                28.462,
                28.525,
                28.587,
                28.65,
                28.713,
                28.776,
                28.839,
                28.902,
                28.965,
                29.028,
                29.091,
                29.154,
                29.217,
                29.28,
                29.344,
                29.408,
                29.471,
                29.535,
                29.598,
                29.661,
                29.725,
                29.788,
                29.852,
                29.915,
                29.979,
                30.043,
                30.106,
                30.17,
                30.234,
                30.297,
                30.361,
                30.425,
                30.489,
                30.553,
                30.616,
                30.68,
                30.745,
                30.809,
                30.873,
                30.937,
                31.001,
                31.065,
                31.129,
                31.193,
                31.257,
                31.321,
                31.385,
                31.449,
                31.513
            ]
        },
    },
};
export let Percentiles_X = {
    wfh: [
        65,
        65.1,
        65.2,
        65.3,
        65.4,
        65.5,
        65.6,
        65.7,
        65.8,
        65.9,
        66,
        66.1,
        66.2,
        66.3,
        66.4,
        66.5,
        66.6,
        66.7,
        66.8,
        66.9,
        67,
        67.1,
        67.2,
        67.3,
        67.4,
        67.5,
        67.6,
        67.7,
        67.8,
        67.9,
        68,
        68.1,
        68.2,
        68.3,
        68.4,
        68.5,
        68.6,
        68.7,
        68.8,
        68.9,
        69,
        69.1,
        69.2,
        69.3,
        69.4,
        69.5,
        69.6,
        69.7,
        69.8,
        69.9,
        70,
        70.1,
        70.2,
        70.3,
        70.4,
        70.5,
        70.6,
        70.7,
        70.8,
        70.9,
        71,
        71.1,
        71.2,
        71.3,
        71.4,
        71.5,
        71.6,
        71.7,
        71.8,
        71.9,
        72,
        72.1,
        72.2,
        72.3,
        72.4,
        72.5,
        72.6,
        72.7,
        72.8,
        72.9,
        73,
        73.1,
        73.2,
        73.3,
        73.4,
        73.5,
        73.6,
        73.7,
        73.8,
        73.9,
        74,
        74.1,
        74.2,
        74.3,
        74.4,
        74.5,
        74.6,
        74.7,
        74.8,
        74.9,
        75,
        75.1,
        75.2,
        75.3,
        75.4,
        75.5,
        75.6,
        75.7,
        75.8,
        75.9,
        76,
        76.1,
        76.2,
        76.3,
        76.4,
        76.5,
        76.6,
        76.7,
        76.8,
        76.9,
        77,
        77.1,
        77.2,
        77.3,
        77.4,
        77.5,
        77.6,
        77.7,
        77.8,
        77.9,
        78,
        78.1,
        78.2,
        78.3,
        78.4,
        78.5,
        78.6,
        78.7,
        78.8,
        78.9,
        79,
        79.1,
        79.2,
        79.3,
        79.4,
        79.5,
        79.6,
        79.7,
        79.8,
        79.9,
        80,
        80.1,
        80.2,
        80.3,
        80.4,
        80.5,
        80.6,
        80.7,
        80.8,
        80.9,
        81,
        81.1,
        81.2,
        81.3,
        81.4,
        81.5,
        81.6,
        81.7,
        81.8,
        81.9,
        82,
        82.1,
        82.2,
        82.3,
        82.4,
        82.5,
        82.6,
        82.7,
        82.8,
        82.9,
        83,
        83.1,
        83.2,
        83.3,
        83.4,
        83.5,
        83.6,
        83.7,
        83.8,
        83.9,
        84,
        84.1,
        84.2,
        84.3,
        84.4,
        84.5,
        84.6,
        84.7,
        84.8,
        84.9,
        85,
        85.1,
        85.2,
        85.3,
        85.4,
        85.5,
        85.6,
        85.7,
        85.8,
        85.9,
        86,
        86.1,
        86.2,
        86.3,
        86.4,
        86.5,
        86.6,
        86.7,
        86.8,
        86.9,
        87,
        87.1,
        87.2,
        87.3,
        87.4,
        87.5,
        87.6,
        87.7,
        87.8,
        87.9,
        88,
        88.1,
        88.2,
        88.3,
        88.4,
        88.5,
        88.6,
        88.7,
        88.8,
        88.9,
        89,
        89.1,
        89.2,
        89.3,
        89.4,
        89.5,
        89.6,
        89.7,
        89.8,
        89.9,
        90,
        90.1,
        90.2,
        90.3,
        90.4,
        90.5,
        90.6,
        90.7,
        90.8,
        90.9,
        91,
        91.1,
        91.2,
        91.3,
        91.4,
        91.5,
        91.6,
        91.7,
        91.8,
        91.9,
        92,
        92.1,
        92.2,
        92.3,
        92.4,
        92.5,
        92.6,
        92.7,
        92.8,
        92.9,
        93,
        93.1,
        93.2,
        93.3,
        93.4,
        93.5,
        93.6,
        93.7,
        93.8,
        93.9,
        94,
        94.1,
        94.2,
        94.3,
        94.4,
        94.5,
        94.6,
        94.7,
        94.8,
        94.9,
        95,
        95.1,
        95.2,
        95.3,
        95.4,
        95.5,
        95.6,
        95.7,
        95.8,
        95.9,
        96,
        96.1,
        96.2,
        96.3,
        96.4,
        96.5,
        96.6,
        96.7,
        96.8,
        96.9,
        97,
        97.1,
        97.2,
        97.3,
        97.4,
        97.5,
        97.6,
        97.7,
        97.8,
        97.9,
        98,
        98.1,
        98.2,
        98.3,
        98.4,
        98.5,
        98.6,
        98.7,
        98.8,
        98.9,
        99,
        99.1,
        99.2,
        99.3,
        99.4,
        99.5,
        99.6,
        99.7,
        99.8,
        99.9,
        100,
        100.1,
        100.2,
        100.3,
        100.4,
        100.5,
        100.6,
        100.7,
        100.8,
        100.9,
        101,
        101.1,
        101.2,
        101.3,
        101.4,
        101.5,
        101.6,
        101.7,
        101.8,
        101.9,
        102,
        102.1,
        102.2,
        102.3,
        102.4,
        102.5,
        102.6,
        102.7,
        102.8,
        102.9,
        103,
        103.1,
        103.2,
        103.3,
        103.4,
        103.5,
        103.6,
        103.7,
        103.8,
        103.9,
        104,
        104.1,
        104.2,
        104.3,
        104.4,
        104.5,
        104.6,
        104.7,
        104.8,
        104.9,
        105,
        105.1,
        105.2,
        105.3,
        105.4,
        105.5,
        105.6,
        105.7,
        105.8,
        105.9,
        106,
        106.1,
        106.2,
        106.3,
        106.4,
        106.5,
        106.6,
        106.7,
        106.8,
        106.9,
        107,
        107.1,
        107.2,
        107.3,
        107.4,
        107.5,
        107.6,
        107.7,
        107.8,
        107.9,
        108,
        108.1,
        108.2,
        108.3,
        108.4,
        108.5,
        108.6,
        108.7,
        108.8,
        108.9,
        109,
        109.1,
        109.2,
        109.3,
        109.4,
        109.5,
        109.6,
        109.7,
        109.8,
        109.9,
        110,
        110.1,
        110.2,
        110.3,
        110.4,
        110.5,
        110.6,
        110.7,
        110.8,
        110.9,
        111,
        111.1,
        111.2,
        111.3,
        111.4,
        111.5,
        111.6,
        111.7,
        111.8,
        111.9,
        112,
        112.1,
        112.2,
        112.3,
        112.4,
        112.5,
        112.6,
        112.7,
        112.8,
        112.9,
        113,
        113.1,
        113.2,
        113.3,
        113.4,
        113.5,
        113.6,
        113.7,
        113.8,
        113.9,
        114,
        114.1,
        114.2,
        114.3,
        114.4,
        114.5,
        114.6,
        114.7,
        114.8,
        114.9,
        115,
        115.1,
        115.2,
        115.3,
        115.4,
        115.5,
        115.6,
        115.7,
        115.8,
        115.9,
        116,
        116.1,
        116.2,
        116.3,
        116.4,
        116.5,
        116.6,
        116.7,
        116.8,
        116.9,
        117,
        117.1,
        117.2,
        117.3,
        117.4,
        117.5,
        117.6,
        117.7,
        117.8,
        117.9,
        118,
        118.1,
        118.2,
        118.3,
        118.4,
        118.5,
        118.6,
        118.7,
        118.8,
        118.9,
        119,
        119.1,
        119.2,
        119.3,
        119.4,
        119.5,
        119.6,
        119.7,
        119.8,
        119.9,
        120
    ]
}
