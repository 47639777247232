import { Injectable } from '@angular/core';
import { Chart, ChartOptions } from 'chart.js';
import { ChartDataset } from '../classes/chart-dataset';
import { LineChartOptionsInterface } from '../classes/line_chart_options-class';
import { PipesService } from './pipes.service';


@Injectable({
  providedIn: 'root',
})
export class ChartjsService {
  constructor(private pipesService: PipesService) {
    // Chart.register(...registerables);
  }

  /**
  Genera un gráfico utilizando la librería Chart.js.
  @param ctx Referencia del elemento canvas donde se dibujará el gráfico.
  @param chartData Arreglo con los datos a mostrar en el gráfico.
  @param chartLabels Arreglo con las etiquetas para cada punto de datos.
  @param chartType Tipo de gráfico a generar. Los tipos soportados son 'bar', 'line' y 'pie'.
  @param options Objeto con opciones adicionales para personalizar el gráfico.
  @returns Referencia al gráfico generado.
*/
  generateChart(
    ctx: HTMLCanvasElement,
    datasets: ChartDataset[],
    chartLabels: any,
    chartType: any,//keyof ChartTypeRegistry,
    options: ChartOptions
  ): Chart {
    const chart = new Chart(ctx, {
      type: chartType,
      data: {
        labels: chartLabels,
        datasets: datasets,
      },
      options: options,
    });
    return chart;
  }

  /**
  Genera un gráfico de barras utilizando la librería Chart.js.
  @param ctx Referencia del elemento canvas donde se dibujará el gráfico.
  @param chartData Arreglo con los datos a mostrar en el gráfico.
  @param chartLabels Arreglo con las etiquetas para cada punto de datos.
  @returns Referencia al gráfico generado.
*/
  generateBarChart(
    ctx: HTMLCanvasElement,
    datasets: ChartDataset[],
    chartLabels: any
  ): Chart {
    // const options = {
    //   scales: {
    //     y: {
    //       beginAtZero: true,
    //     },
    //   },
    // };
    return this.generateChart(ctx, datasets, chartLabels, 'bar', null);
  }

  /**
  Genera un gráfico de líneas utilizando la librería Chart.js.
  @param ctx Referencia del elemento canvas donde se dibujará el gráfico.
  @param chartData Arreglo con los datos a mostrar en el gráfico.
  @param chartLabels Arreglo con las etiquetas para cada punto de datos.
  @returns Referencia al gráfico generado.
*/
  generateLineChart(
    ctx: HTMLCanvasElement,
    datasets: ChartDataset[],
    chartLabels: any,
    _options?: ChartOptions
  ): Chart {
    let options: ChartOptions & any;
    if (_options) {
      options = _options;
    } else {
      options = {
        responsive: true,
        fill: true,
        elements: {
          line: {
            tension: 0,
          },
        },
      };
    }

    return this.generateChart(ctx, datasets, chartLabels, 'line', options);
  }

  /**
  Genera un gráfico de pastel utilizando la librería Chart.js.
  @param ctx Referencia del elemento canvas donde se dibujará el gráfico.
  @param chartData Arreglo con los datos a mostrar en el gráfico.
  @param chartLabels Arreglo con las etiquetas para cada punto de datos.
  @returns Referencia al gráfico generado.
*/
  generatePieChart(
    ctx: HTMLCanvasElement,
    datasets: ChartDataset[],
    chartLabels: any
  ): Chart {
    // const options = {
    //   legend: {
    //     position: 'right',
    //   },
    // };
    return this.generateChart(ctx, datasets, chartLabels, 'pie', null);
  }

  updateDataset(
    chart: Chart,
    datasetIndex: number,
    label: string,
    data: any[]
  ) {
    chart.data.datasets[datasetIndex].label = label;
    chart.data.datasets[datasetIndex].data = data;
    chart.update();
  }

  addDataset(chart: Chart, dataset: ChartDataset) {
    chart.data.datasets.push(dataset);
    chart.update();
  }

  removeDataset(chart: Chart, datasetIndex: number) {
    chart.data.datasets.splice(datasetIndex, 1);
    chart.update();
  }
}
