import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ExpedientMetadata, ExpedientService } from 'src/app/services/expedient.service';

@Component({
  selector: 'app-expedient-relations',
  templateUrl: './relations.component.html',
  styleUrls: ['./relations.component.scss']
})
export class RelationsComponent implements OnInit, OnDestroy {
  private expedient_subscription: Subscription;
  public expedient_state: ExpedientMetadata;

  public view = 0;
  constructor(private expedientService: ExpedientService) {
  }

  ngOnInit() {
    this.expedient_subscription = this.expedientService.current.subscribe(
      state => this.expedient_state = state
    );
  }

  ngOnDestroy() {
    this.expedient_subscription.unsubscribe();
  }

}
