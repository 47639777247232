import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AccountService } from '../services/account.service';
import { AppService } from '../services/app.service';
import { PageService } from '../services/page.service';

@Injectable({
  providedIn: 'root'
})
export class FirstLoginGuard implements CanActivate {

  constructor(private pageService: PageService, private endpoint: AppService, private accountService: AccountService) { }

  canActivate() {
    let session = this.endpoint.get_session();
    if (session && session.token) {
      if (session.first_login) {
        this.accountService.init();
        return true;
      } else {
        this.endpoint.redirect_to('');
      }
    } else {
			this.accountService.reset();
      this.endpoint.redirect_to('/conectarse');
    }
  }

}
