import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormRenderComponent, form_type } from 'src/app/shared/ui/form_render/form_render.component';
import { PathologiesService, PathologiesState } from '../pathologies.service';
import { ExpedientMetadata, ExpedientService } from 'src/app/services/expedient.service';

@Component({
	selector: 'app-module-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy, AfterViewInit {
	private expedient_subscription: Subscription;
	public expedient_state: ExpedientMetadata;

	private pathologies_subscription: Subscription;
	public pathologies_state: PathologiesState;

	@ViewChild('form_ref', { static: false }) form_ref: FormRenderComponent;
	public form_config: form_type;

	public loading: boolean;
	public pathology_timeout: any;
	public search_word: string;

	constructor(private moduleService: PathologiesService, private expedientService: ExpedientService) {
	}

	ngOnInit() {
		this.expedient_subscription = this.expedientService.current.subscribe(
			state => {
				this.expedient_state = state;
				// this.moduleService.reload();
				this.form_configs();
			}
		);
		this.pathologies_subscription = this.moduleService.current.subscribe(
			state => this.pathologies_state = state
		);
		this.form_configs();
	}

	ngAfterViewInit() {
		if (this.pathologies_state) {
			this.moduleService.forms.pathologies = this.form_ref;
		}
	}

	ngOnDestroy() {
		this.expedient_subscription.unsubscribe();
		this.pathologies_subscription.unsubscribe();
	}

	form_configs() {
		this.form_config = {
			pretty_view: () => this.expedient_state.method == "show",
			disabled: () => this.expedient_state.method == "show",
			submit: (callback) => this.moduleService.submit(callback),
			rows: [
				{
					class: "row",
					cols: [
						{
							name: "id_pathology",
							type: "select-search",
							label: "Diagnóstico",
							small: "",
							placeholder: () => "Buscar patología",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => false
								},
							},
							catalog: {
								list: () => this.moduleService.catalogs.pathologies,
								value: "title",
								text: "title"
							},
							loading: () => {
								return this.loading;
							},
							show: () => this.expedient_state.method != "show",
							disabled: () => false,
							change: (event) => {
								this.search_word = event.target.value;
								// clearTimeout(this.pathology_timeout);
								// this.get_icd_pathologies();
							},
							input: (event) => {
								this.search_word = event.target.value;
								clearTimeout(this.pathology_timeout);

								this.pathology_timeout = setTimeout(() => {
									this.get_icd_pathologies();
								}, 1000);
							},
                            select: (item: any, input_ref: any) => {
                                input_ref.s_value = item.name;
								this.pathologies_state.pathologies.pop();
								this.pathologies_state.pathologies.push({
									...item,
									label: item.title,
								});
								this.pathologies_state.pathologies_parse = item.title;
								this.pathologies_state.name = item.title;
								this.pathologies_state.icd = item.id;
								this.loading = false;
							},
						},
						{
							type: "text",
							col_class: "col-md-6",
							class: "no-border",
							label: this.expedient_state.method == "show" ? "Diagnóstico" : "⠀",
							name: "pathologies_parse",
							validators: {
								required: {
									value: () => true
								}
							},
							disabled: () => true
						},
						{
							name: "treatment",
							type: "textarea",
							label: "Tratamiento",
							small: "",
							rows: 2.5,
							col_class: "col-12",
							validators: {
								required: {
									value: () => false
								},
								maxlength: {
									value: () => 500
								}
							},
							disabled: () => false,
						},
					]
				},
				{
					class: "row",
					cols: [
						{
							type: "buttons",
							col_class: "col-12",
							buttons: [
								{
									class: "btn btn-sm btn-outline-danger",
									label: "Cancelar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									show: () => {
										return this.expedient_state.method == "update" || this.expedient_state.method == "insert";
									},
									click: () => {
										this.moduleService.cancel();
									},
								},
								{
									class: "btn btn-sm btn-outline-danger",
									label: "Cerrar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									show: () => {
										return this.expedient_state.method == "show";
									},
									click: () => {
										this.moduleService.close();
									},
								},
								{
									class: "btn btn-sm btn-success ml-auto",
									label: "Guardar",
									type: "submit",
									icon: {
										class: "fa-duotone fa-check",
										position: 'right',
									},
									show: () => {
										return this.expedient_state.method == "update" || this.expedient_state.method == "insert";
									},
									disabled: () => (this.form_ref ? !this.form_ref.valid() : false) || this.pathologies_state.loading || !this.pathologies_state.pathologies_parse,
								},
							]
						},
					],
				},
			]
		};
	}

	get_icd_pathologies() {
		this.loading = true;
		this.moduleService.get_icd_pathologies(this.search_word, () => {
			this.loading = false;
		});
	}
}