<div class="row mb-2">
    <div *ngIf="expedient_state.version == 'expedient'" class="col-12 d-flex align-items-center justify-content-center my-2 flex-column">
        <div class="text-center profile-image-chooser">
            <file-upload
                class="dropzone d-none"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="uploadedFiles"
                [control]="fileUploadControl"
                (change)="fileProgress($event)"
                name="imagen"
                accept="image/x-png,image/gif,image/jpeg"
                #profile_image="ngModel"
            >
            </file-upload>

            <div style="position: relative">
                <avatar
                    [image]="general_state?.image"
                    [name]="general_state?.firstname || general_state?.lastname"
                    [configs]="avatar_configs"
                ></avatar>
                <a
                    class="image_hover"
                    [class.must_hover]="expedient_state.method != 'show'"
                    (click)="open_file_chooser()"
                ></a>
            </div>
        </div>
        <label class="text-center title-image">Imagen de perfil</label>
    </div>

    <div class="col-12">
        <app-form_render #form_ref [config]="form_config" [data]="general_state"></app-form_render>
    </div>
</div>
