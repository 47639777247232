import { Observable } from 'rxjs';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Injectable } from '@angular/core';

export interface DBFilterInterface {
  sort_asc?: boolean,
  sort_by?: string,
  search_word?: string,
  dates?: 1 | 2,
  date_init?: Date | string,
  date_finish?: Date | string,
};

@Injectable({
  providedIn: 'root',
})
export class WebDBService {
  constructor(private dbService: NgxIndexedDBService) { }

  add = (table: string, payload: any): Observable<any> =>
    this.dbService.add(table, payload);

  bulkAdd = (table: string, payload: any): Observable<any> =>
    this.dbService.bulkAdd(table, payload);

  update = (table: string, payload: any): Observable<any> =>
    this.dbService.update(table, payload);

  getAll = (table: string, ids?: IDBValidKey[]): Observable<any> =>
    !ids ? this.dbService.getAll(table) : this.dbService.bulkGet(table, ids);

  get = (table: string, id: IDBValidKey): Observable<any> =>
    this.dbService.getByKey(table, id);

  getByIndex = (
    table: string,
    indexName: string,
    key: IDBValidKey
  ): Observable<any> => this.dbService.getByIndex(table, indexName, key);

  delete = (table: string, id: IDBValidKey) => this.dbService.delete(table, id);

  bulkDelete = (table: string, ids: IDBValidKey[]) =>
    this.dbService.bulkDelete(table, ids);

  async overwrite(json: any, callback) {
    for (const table in json) {
      await new Promise((resolve) => {
        this.dbService.clear(table).subscribe(() => resolve(true));
      });
    }

    for (const table in json) {
      for (let index = 0; index < json[table].length; index++) {
        const obj = json[table][index];
        await new Promise((resolve) => {
          this.add(table, obj).subscribe(() => resolve(true));
        });
      }
    }

    callback();
  }

  /**
   * Selects items from a list that match certain criteria
   * @param {Array<any>} list - List to filter
   * @param {Object} columns - Object containing the search criteria
   * @param {Function} [callback] - Optional callback function to be called with the filtered result
   * @returns {Array<any>} - Filtered list
   * 
   * @example
   * 
   * const list = [
   *   {name: 'John', age: 20, date: '2022-01-01'},
   *   {name: 'Mike', age: 25, date: '2022-02-01'},
   *   {name: 'Jane', age: 30, date: '2022-03-01'},
   * ]
   * 
   * const searchCriteria = {
   *   name: 'John',
   *   age: {
   *     logic: 'or',
   *     values: [20, 25]
   *   },
   *   date: {
   *     key: 'date',
   *     logic: 'or',
   *     from: '2022-02-01',
   *     to: '2022-03-01'
   *   }
   * }
   * 
   * const result = selectWhereLike(list, searchCriteria)
   * // result will be [{name: 'John', age: 20, date: '2022-01-01'}, {name: 'Mike', age: 25, date: '2022-02-01'}]
   * 
   */
  public selectWhereLike(list: any[], columns: object, callback?: (result: any[]) => void): any[] {
    const result = list.filter((item: any) => {
      let match = true;
      for (const key in columns) {
        if (key === 'date') {
          const dateColumns = Array.isArray(columns[key]) ? columns[key] : [columns[key]];
          for (const dateColumn of dateColumns) {
            const date = new Date(item[dateColumn.key]);
            if (dateColumn.logic === 'or') {
              if ((dateColumn.from && date < dateColumn.from) || (dateColumn.to && date > dateColumn.to)) {
                match = false;
                break;
              }
            } else {
              if ((dateColumn.from && date < dateColumn.from) && (dateColumn.to && date > dateColumn.to)) {
                match = false;
                break;
              }
            }
          }
        } else {
          let columnMatch = false;
          const columnValues = Array.isArray(columns[key]) ? columns[key] : [columns[key]];
          for (const columnValue of columnValues) {
            const regex = new RegExp(
              columnValue
                .replace(/á|ä/g, "a")
                .replace(/é|ë/g, "e")
                .replace(/í|ï/g, "i")
                .replace(/ó|ö/g, "o")
                .replace(/ú|ü/g, "u")
              , 'i');
            if (
              regex.test(item[key]
                .replace(/á|ä/g, "a")
                .replace(/é|ë/g, "e")
                .replace(/í|ï/g, "i")
                .replace(/ó|ö/g, "o")
                .replace(/ú|ü/g, "u")
              )) {
              columnMatch = true;
              break;
            }
          }
          if (!columnMatch) {
            match = false;
            break;
          }
        }
      }
      return match;
    });
    if (callback) callback(result);
    return result;
  }

  /**
 * sort
 * @param list any[] - Lista de elementos a ordenar
 * @param filter {sort_by?: string, sort_asc?: boolean} - Filtro de ordenamiento
 * @return any[] - Lista de elementos ordenada
 * 
 * Esta función permite ordenar una lista de elementos a partir de un criterio específico.
 * El parametro "sort_by" especifica el nombre de la columna por la cual se desea ordenar, 
 * y el parametro "sort_asc" especifica si el orden es ascendente (true) o descendente (false).
 */
  sort(list: any[], filter: { sort_by?: string, sort_asc?: boolean }) {
    if (filter.sort_by) {
      list.sort((a, b) => {
        const valueA = a[filter.sort_by];
        const valueB = b[filter.sort_by];
        if (valueA < valueB) {
          return filter.sort_asc ? -1 : 1;
        }
        if (valueA > valueB) {
          return filter.sort_asc ? 1 : -1;
        }
        return 0;
      });
    }
    return list;
  }

  sumTotalByDay(arr: { [key: string]: any }[], sumAttribute: string, dateAttribute: string): Array<{ date: string, value: number }> {
    const totalsByDay = new Map<string, number>();
    arr.forEach(obj => {
      let date = new Date(obj[dateAttribute]);
      date.setHours(0, 0, 0, 0);

      const dateKey = +date + "";
      if (!totalsByDay.has(dateKey)) {
        totalsByDay.set(dateKey, obj[sumAttribute] as number);
      } else {
        totalsByDay.set(dateKey, totalsByDay.get(dateKey) + (obj[sumAttribute] as number));
      }
    });

    return Array.from(totalsByDay).map(([date, value]) => ({
      date,
      value
    }));
  }

  sumTotalByMonth(arr: { [key: string]: any }[], sumAttribute: string, dateAttribute: string): Array<{ date: string, value: number }> {
    const totalsByMonth = new Map<string, number>();
    arr.forEach(obj => {
      let date = new Date(obj[dateAttribute]);
      date.setDate(1);
      date.setHours(0, 0, 0, 0);

      const dateKey = +date + "";
      if (!totalsByMonth.has(dateKey)) {
        totalsByMonth.set(dateKey, obj[sumAttribute] as number);
      } else {
        totalsByMonth.set(dateKey, totalsByMonth.get(dateKey) + (obj[sumAttribute] as number));
      }
    });

    return Array.from(totalsByMonth).map(([date, value]) => ({
      date,
      value
    }));
  }

}
