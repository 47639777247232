import { BehaviorSubject } from "rxjs";
import { AppService } from "./app.service";
import { SocketService } from "./socket.service";
import * as i0 from "@angular/core";
import * as i1 from "./app.service";
import * as i2 from "./socket.service";
export class AccountService {
    constructor(endpoint, socketService) {
        this.endpoint = endpoint;
        this.socketService = socketService;
        this.store_name = "unimed-account-data";
    }
    init() {
        var stored = JSON.parse(localStorage.getItem(this.store_name));
        if (stored) {
            this.current_state = {
                id: stored.id,
                firstname: stored.firstname,
                email: stored.email,
                lastname: stored.lastname,
                id_gender: stored.id_gender,
                birthdate: stored.birthdate,
                birthplace: stored.birthplace,
                id_department: stored.id_department,
                id_town: stored.id_town,
                id_country: stored.id_country,
                address: stored.address,
                phone: stored.phone,
                id_education: stored.id_education,
                identification: stored.identification,
                registration_number: stored.registration_number,
                id_doctors_specialty: stored.id_doctors_specialty,
                image: stored.image,
                doctors_specialty_name: stored.doctors_specialty_name,
                timestamp: stored.timestamp,
            };
        }
        this.account_state = new BehaviorSubject(this.current_state);
        this.current = this.account_state.asObservable();
        this.reload();
    }
    reload() {
        this.get_account_data();
    }
    get_current_state() {
        return this.current_state;
    }
    get_account_data() {
        this.endpoint.get_user_data().subscribe((data) => (this.current_state = {
            id: data.id,
            email: data.username,
            firstname: data.firstname,
            lastname: data.lastname,
            id_gender: data.id_gender,
            birthdate: data.birthdate,
            birthplace: data.birthplace,
            id_department: data.id_department,
            id_town: data.id_town,
            id_country: data.id_country,
            address: data.address,
            phone: data.phone,
            id_education: data.id_education,
            identification: data.identification,
            registration_number: data.registration_number,
            id_doctors_specialty: data.id_doctors_specialty,
            doctors_specialty_name: data.doctors_specialty_name,
            image: data.image,
            timestamp: new Date(),
        }), (error) => console.error(error), () => {
            this.socketService.login(this.current_state.id);
            localStorage.setItem(this.store_name, JSON.stringify(this.current_state));
            this.account_state.next(this.current_state);
        });
    }
    reset() {
        this.current_state = {
            id: null,
            email: "",
            firstname: "",
            lastname: "",
            id_gender: "",
            birthdate: "",
            birthplace: "",
            id_department: "",
            id_town: "",
            id_country: "",
            address: "",
            phone: "",
            id_education: "",
            identification: "",
            registration_number: "",
            id_doctors_specialty: "",
            doctors_specialty_name: "",
            image: null,
            timestamp: new Date(),
        };
        this.socketService.logout();
        localStorage.setItem(this.store_name, JSON.stringify(this.current_state));
        // this.account_state.next(this.current_state);
    }
}
AccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.SocketService)); }, token: AccountService, providedIn: "root" });
