/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contacts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./emergency/emergency.component.ngfactory";
import * as i4 from "./emergency/emergency.component";
import * as i5 from "../../../services/app.service";
import * as i6 from "../../../services/expedient.service";
import * as i7 from "../../../services/page.service";
import * as i8 from "./emergency/emergency.service";
import * as i9 from "../../../shared/services/sweel.service";
import * as i10 from "ngx-toastr";
import * as i11 from "./doctor/doctor.component.ngfactory";
import * as i12 from "./doctor/doctor.component";
import * as i13 from "./doctor/doctor.service";
import * as i14 from "../../../services/catalogs.service";
import * as i15 from "@angular/common";
import * as i16 from "./contacts.component";
var styles_ContactsComponent = [i0.styles];
var RenderType_ContactsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactsComponent, data: {} });
export { RenderType_ContactsComponent as RenderType_ContactsComponent };
function View_ContactsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ContactsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-expedient-contacts-emergency", [], null, [[null, "change_view"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change_view" === en)) {
        var pd_0 = ((_co.view = 1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ContactsEmergencyComponent_0, i3.RenderType_ContactsEmergencyComponent)), i1.ɵdid(2, 245760, null, 0, i4.ContactsEmergencyComponent, [i5.AppService, i6.ExpedientService, i7.PageService, i8.EmergencyService, i9.SweelService, i10.ToastrService], null, { _change_view_event: "change_view" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ContactsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-expedient-contacts-doctor", [], null, [[null, "change_view"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change_view" === en)) {
        var pd_0 = ((_co.view = 0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_ContactsDoctorComponent_0, i11.RenderType_ContactsDoctorComponent)), i1.ɵdid(2, 245760, null, 0, i12.ContactsDoctorComponent, [i5.AppService, i6.ExpedientService, i7.PageService, i13.DoctorService, i14.CatalogsService, i9.SweelService, i10.ToastrService], null, { _change_view_event: "change_view" })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ContactsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsComponent_3)), i1.ɵdid(2, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsComponent_4)), i1.ɵdid(4, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.view == 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.view == 1); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ContactsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsComponent_1)), i1.ɵdid(1, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsComponent_2)), i1.ɵdid(3, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.expedient_state.version == "expedient"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.expedient_state.version == "new_patient"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ContactsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-expedient-contacts", [], null, null, null, View_ContactsComponent_0, RenderType_ContactsComponent)), i1.ɵdid(1, 245760, null, 0, i16.ContactsComponent, [i6.ExpedientService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactsComponentNgFactory = i1.ɵccf("app-expedient-contacts", i16.ContactsComponent, View_ContactsComponent_Host_0, {}, {}, []);
export { ContactsComponentNgFactory as ContactsComponentNgFactory };
