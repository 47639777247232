import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FormRenderComponent } from 'src/app/shared/ui/form_render/form_render.component';
import { PageService } from 'src/app/services/page.service';
import { AppService } from 'src/app/services/app.service';
import { ExpedientService } from 'src/app/services/expedient.service';
import Swal from 'sweetalert2';

export interface PathologiesCatalogs {
  pathologies: {
    id: any,
    name: string
    title: string
  }[]
};

export interface PathologiesForms {
  pathologies: FormRenderComponent
};

export interface PathologiesState {
  id: any,
  id_consult?: any,
  id_patient: any,
  id_pathology: any,
  pathologies: any[],
  pathologies_parse: string,

  name: string,
  icd: string,
  date: Date,
  secure: boolean,
  treatment: string,

  created_at: string,
  created_by: any,
  doctor_name?: string,

  loading: boolean,
  submitted: boolean,
  timestamp: Date,
};

export interface ListState {
  list: PathologiesState[],
  count: number,
  skip: number,
  loaded: boolean
};

@Injectable({ providedIn: 'root' })
export class PathologiesService {

  // FORMS
  public forms: PathologiesForms;
  private forms_state: BehaviorSubject<PathologiesForms>;
  public obv_forms: Observable<PathologiesForms>;

  // PATHOLOGIES
  private current_state: PathologiesState;
  private module_state: BehaviorSubject<PathologiesState>;
  public current: Observable<PathologiesState>;

  // LIST
  private list: ListState;
  private list_state: BehaviorSubject<ListState>;
  public obv_list: Observable<ListState>;

  private store_list_name = "unimed-expedient-background-pathologies-list";
  private store_name = "unimed-expedient-background-pathologies";
  public current_filters: any;
  public catalogs: PathologiesCatalogs;

  constructor(private endpoint: AppService, private toastr: ToastrService, private pageService: PageService, private expedientService: ExpedientService) {
  }

  // LIST FUNCTIONS
  init_list() {
    this.expedientService.set_expedient_service({ store_url: this.store_list_name, submit: (callback) => this.submit_stored(callback) }, "background", "pathologies");
    this.clean_list();
    this.list_next();
  }

  reload_list(data: any) {
    if (this.expedientService.state.id_user) {
      this.get_list(data);
    } else {
      this.get_stored_list();
    }
  }

  get_list(data: any) {
    this.current_filters = data;
    let load = {
      id_patient: this.expedientService.state.id_user,
      ...data
    };
    this.endpoint.expedient_background_pathologies_get_pathologies(load).subscribe(
      (data: ListState) => this.list = data,
      error => console.error(error),
      () => {
        this.list_next();
      }
    );
  }

  get_stored_list() {
    setTimeout(() => {
      let stored = localStorage.getItem(this.store_list_name);

      if (stored) {
        this.list = JSON.parse(stored) as ListState;
      } else {
        this.clean_list();
        this.list.loaded = true;
        this.save_list();
      }

      this.list_next();
    }, 1);
  }

  list_next() {
    if (this.list_state)
      this.list_state.next(this.list);
    else {
      this.list_state = new BehaviorSubject(this.list);
      this.obv_list = this.list_state.asObservable();
    }
  }

  clean_list() {
    return this.list = {
      list: [],
      count: 0,
      skip: 0,
      loaded: false
    };
  }

  save_list() {
    localStorage.setItem(this.store_list_name, JSON.stringify(this.list));
  }

  // EVENT FUNCTIONS

  init(pathologies: PathologiesState = null) {
    if (pathologies) {
      if (!pathologies.pathologies) pathologies.pathologies = [];
      pathologies.pathologies_parse = pathologies.name;
      this.current_state = pathologies;
      this.current_state.loading = false;
      this.current_state.submitted = false;
      this.current_state.timestamp = new Date;
    }
    else this.internal_clean();
    this.next();

    this.forms = {
      pathologies: null
    };

    this.forms_state = new BehaviorSubject(this.forms);
    this.obv_forms = this.forms_state.asObservable();

    this.catalogs = {
      pathologies: []
    };
    // this.reload_catalogs();
  }

  reload_catalogs() {
    this.endpoint.new_patient_catalogs().subscribe(
      data => this.catalogs = data,
      error => console.error(error)
    );
  }

  get_icd_pathologies(search_word, callback) {
    let load = {
      search_word: search_word
    };
    this.endpoint.icd_pathologies(load).subscribe(
      data => this.catalogs.pathologies = data,
      error => console.error(error),
      () => callback()
    );
  }

  get_current_state() {
    return this.current_state;
  }

  refresh_forms() {
    this.forms_state.next(this.forms);
  }

  next() {
    if (this.module_state)
      this.module_state.next(this.current_state);
    else {
      this.module_state = new BehaviorSubject(this.current_state);
      this.current = this.module_state.asObservable();
    }
  }

  valid() {
    return !this.current_state.loading &&
      this.forms && this.forms.pathologies && this.forms.pathologies.valid ? this.forms.pathologies.valid() : false
  }

  set_form(form: { name: string, module: FormRenderComponent }) {
    this.forms[form.name] = form.module;
  }

  save() {
    localStorage.setItem(this.store_name, JSON.stringify(this.current_state));
  }

  submit(callback = (callback_data?: any) => { }) {
    if (!(this.forms && this.forms.pathologies && this.forms.pathologies.valid()) || this.current_state.loading) { callback(); return false; }

    this.current_state.id_patient = this.expedientService.state.id_user;
    this.current_state.submitted = true;
    this.current_state.loading = true;
    this.module_state.next(this.current_state);

    if (this.expedientService.state.id_user) {
      if (this.expedientService.state.method == "insert") {
        this.insert(callback);
      } else {
        this.update(callback);
      }
    } else {
      if (this.expedientService.state.method == "insert") {
        this.insert_store(callback);
      } else {
        this.update_store(callback);
      }
    }
  }

  insert(callback = (callback_data?: any) => { }) {
    this.endpoint.expedient_background_pathologies_insert_pathology(this.current_state).subscribe(
      data => {
        if (this.expedientService.state.version == "expedient") {
          localStorage.removeItem("inExpedientBackgroundPathologies");
          this.toastr.success(data["message"], data["title"])

          this.current_state.loading = false;
          this.module_state.next(this.current_state);
          if (this.forms.pathologies) this.forms.pathologies.unsubmit();

          this.expedientService.update_global();
          this.reload_list(this.current_filters);
          this.close();
          this.clean();
          callback(data);
        }
        else if (this.expedientService.state.version == "new_patient") {
          callback(data);
        }
      },
      error => {
        console.error(error)
        this.toastr.error(
          error.error && error.error.message ? error.error.message : error.message,
          error.error && error.error.title ? error.error.title : error.status + ''
        );
        this.current_state.loading = false;
        this.module_state.next(this.current_state);
      }
    )
  }

  insert_store(callback = (callback_data?: any) => { }) {
    this.current_state.id = new Date().getTime();
    this.current_state.created_at = new Date().toISOString();
    this.current_state.doctor_name = "Yo";
    this.list.list.push(this.current_state);
    callback();
    this.save_list();
    this.list_next();
    this.close();
    this.clean();
  }

  update(callback = (callback_data?: any) => { }) {
    this.endpoint.expedient_background_pathologies_update_pathology(this.current_state).subscribe(
      data => {
        localStorage.removeItem("inExpedientBackgroundPathologies");
        this.toastr.success(data["message"], data["title"]);

        this.current_state.loading = false;
        this.module_state.next(this.current_state);
        if (this.forms.pathologies) this.forms.pathologies.unsubmit();

        this.expedientService.update_global();
        this.reload_list(this.current_filters);
        this.close();
        this.clean();

        callback();
      },
      error => {
        console.error(error)
        this.toastr.error(
          error.error && error.error.message ? error.error.message : error.message,
          error.error && error.error.title ? error.error.title : error.status + ''
        );
        this.current_state.loading = false;
        this.module_state.next(this.current_state);
      }
    );
  }

  update_store(callback = (callback_data?: any) => { }) {
    let index = this.list.list.findIndex(value => value.id == this.current_state.id);
    this.list.list[index] = this.current_state;
    callback();
    this.save_list();
    this.list_next();
    this.close();
    this.clean();
  }

  delete() {
    if (this.expedientService.state.id_user) {
      this._delete();
    } else {
      this._delete_store();
    }
  }

  private _delete() {
    let load = {
      id: this.current_state.id
    };
    this.endpoint.expedient_background_pathologies_delete_pathology(load).subscribe(
      data => {
        this.toastr.success(data["message"], data["title"]);

        this.expedientService.update_global();
        this.reload_list(this.current_filters);
        this.close();
      },
      error => {
        this.toastr.error(
          error.error.message ? error.error.message : error.message,
          error.error.title ? error.error.title : error.status + ''
        );
      }
    );
  }

  private _delete_store() {
    let index = this.list.list.findIndex(value => value.id == this.current_state.id);
    this.list.list.splice(index, 1);
    this.save_list();
    this.list_next();
  }

  cancel(callback = (callback_data?: any) => { }) {
    Swal.fire({
      title: "Cancelar Formulario",
      html: "¿Está seguro de cancelar el llenado del formulario actual?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#FDB915',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, cancelar',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value == true) {
        localStorage.removeItem("inExpedientGeneral");
        this.close();
        callback();
      }
    });
  }

  close() {
    this.pageService.closeModal(true);
  }

  clean() {
    this.internal_clean();
    this.module_state.next(this.current_state);
    this.save();
  }

  internal_clean() {
    this.current_state = {
      id: null,
      id_pathology: [],
      id_patient: null,
      pathologies_parse: "",
      pathologies: [],

      name: "",
      icd: "",
      date: null,
      secure: true,
      treatment: "",

      created_at: "",
      created_by: null,

      loading: false,
      submitted: false,
      timestamp: new Date(),
    };
  }

  submit_stored(callback = (callback_data?: any) => { }, step: number = 0) {
    if (this.list.list.length > 0 && step < this.list.list.length) {
      this.current_state = this.list.list[step];
      this.current_state.id_patient = this.expedientService.state.id_user;
      this.insert(() => {
        this.submit_stored(callback, step + 1);
      });
    } else {
      callback();
    }
  }

}