import { Component } from '@angular/core';
import { ExpedientService } from 'src/app/services/expedient.service';
import { InstitutionsService } from '../institutions.service';

@Component({
  selector: 'app-institutions_create_institutions',
  templateUrl: './create_institutions.component.html',
  styleUrls: ['./create_institutions.component.scss']
})
export class CreateInstitutionsComponent {

  constructor(private moduleService: InstitutionsService, private expedientService: ExpedientService) {
    this.expedientService.change_method("insert");
    this.moduleService.init();
  }

  close() {
    this.moduleService.cancel();
  }

}
