import { Routes } from '@angular/router';
const ɵ0 = () => import("./laboratory/laboratory.module.ngfactory").then(m => m.ExamsLaboratoryModuleNgFactory);
const routes = [
    {
        path: '',
        redirectTo: 'laboratorios',
        pathMatch: 'prefix'
    },
    {
        path: 'laboratorios',
        loadChildren: ɵ0
    }
];
export class ExamsModule {
}
export { ɵ0 };
