import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { PageService } from "src/app/services/page.service";
import { PatientService } from "src/app/services/patient-service.service";
import { AppService } from "src/app/services/app.service";
import { PdfService } from "src/app/services/pdf.service";
import { AccountService } from "src/app/services/account.service";
import { ConsultClass, } from "../../../../classes/consult-class";
import { CatalogsService } from "src/app/services/catalogs.service";
import { PipesService } from "src/app/services/pipes.service";
import * as i0 from "@angular/core";
import * as i1 from "../../../../services/app.service";
import * as i2 from "ngx-toastr";
import * as i3 from "../../../../services/page.service";
import * as i4 from "../../../../services/patient-service.service";
import * as i5 from "../../../../services/pdf.service";
import * as i6 from "../../../../services/account.service";
import * as i7 from "../../../../services/catalogs.service";
import * as i8 from "../../../../services/pipes.service";
export class ConsultGeneralService {
    constructor(endpoint, toastr, pageService, patientServiceService, pdfService, accountService, catalogsService, pipesService) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.pageService = pageService;
        this.patientServiceService = patientServiceService;
        this.pdfService = pdfService;
        this.accountService = accountService;
        this.catalogsService = catalogsService;
        this.pipesService = pipesService;
        this.store_name = "unimed-expedient-consult-id";
        this.loading_timeout = null;
        this.clean();
        this.next();
    }
    load_storage() {
        this.set_loading(true, 0);
        let id = this.get_storage();
        if (id) {
            this.load(id);
        }
    }
    new_consult() {
        this.init_consult();
    }
    load(id) {
        this.get_consult(id);
    }
    next() {
        if (this.consult_state) {
            this.consult_state.next(this.current_state);
        }
        else {
            this.consult_state = new BehaviorSubject(this.current_state);
            this.current = this.consult_state.asObservable();
        }
    }
    valid() {
        return this.current_state.consult.valid();
    }
    get_current_state() {
        return this.current_state;
    }
    init_consult() {
        this.set_loading(true, 0);
        let load = {
            id_patient: this.patientServiceService.patient_data.general.id_user,
        };
        this.endpoint.expedient_consult_general_init_consult(load).subscribe((data) => {
            this.current_state.method = "update";
            this.current_state.consult.init(data);
            this.save_storage();
            this.set_loading(false, 1000);
        }, (error) => {
            console.error(error);
            this.set_loading(false, 1000);
        });
    }
    get_consult(id) {
        let load = {
            id: id,
        };
        this.endpoint.expedient_consult_general_get_consult(load).subscribe((data) => {
            console.log(data);
            console.log(this.current_state.consult);
            this.current_state.method = "update";
            this.current_state.consult.init(data);
            this.set_loading(false, 1000);
            this.next();
        }, (error) => {
            console.error(error);
            this.set_loading(false, 1000);
        });
    }
    save_consult(callback = () => { }) {
        this.set_loading(true, 0);
        this.endpoint
            .expedient_consult_general_save_consult(this.current_state.consult.raw())
            .subscribe((data) => {
            this.get_consult(this.current_state.consult.id);
            callback();
        }, (error) => {
            console.error(error);
            this.set_loading(false, 1000);
        });
    }
    finish_consult(callback = () => { }) {
        this.set_loading(true, 0);
        this.endpoint
            .expedient_consult_general_finish_consult(this.current_state.consult.raw())
            .subscribe((data) => {
            this.toastr.success(data["message"], data["title"]);
            callback();
            this.remove_storage();
            localStorage.removeItem("inConsult");
            // this.endpoint.redirect_to("/expediente");
            this.endpoint.redirect_to("/expediente/consulta/" + this.current_state.consult.id);
        }, (error) => {
            console.error(error);
            this.set_loading(false, 1000);
        });
    }
    cancel_consult(callback = () => { }, id) {
        this.set_loading(true, 0);
        this.endpoint
            .expedient_consult_general_cancel_consult(!id ? this.current_state.consult.raw() : { id: id })
            .subscribe((data) => {
            this.toastr.success(data["message"], data["title"]);
            callback();
            this.remove_storage();
            localStorage.removeItem("inConsult");
            this.endpoint.redirect_to("/expediente");
        }, (error) => {
            console.error(error);
            this.set_loading(false, 1000);
        });
    }
    set_loading(option, time = 1000) {
        clearTimeout(this.loading_timeout);
        this.loading_timeout = setTimeout(() => {
            this.current_state.loading = option;
        }, time);
    }
    save_storage(id = this.current_state.consult.id) {
        localStorage.setItem(this.store_name, id);
    }
    get_storage() {
        return localStorage.getItem(this.store_name);
    }
    remove_storage() {
        localStorage.removeItem(this.store_name);
    }
    cancel(id, callback) {
        this.pageService.cancel_consult(() => {
            this.cancel_consult(() => {
                this.remove_storage();
                if (id) {
                    callback();
                }
                else {
                    this.endpoint.redirect_to("/expediente/consultas");
                }
            }, id);
        });
    }
    close(callback = () => { }) {
        this.pageService.close_consult(() => {
            callback();
        });
    }
    save_close_consult() {
        this.close(() => {
            this.set_loading(true, 0);
            this.save_consult(() => {
                this.remove_storage();
                this.endpoint.redirect_to("/expediente/consultas");
            });
        });
    }
    save_minimize_consult() {
        this.set_loading(true, 0);
        this.save_consult(() => {
            localStorage.removeItem("inConsult");
            this.endpoint.redirect_to("/expediente");
        });
    }
    clean(callback = () => { }) {
        this.current_state = {
            consult: new ConsultClass(this.catalogsService, this.pipesService),
            extras: [],
            method: "update",
            loading: true,
            overlay: false,
            submitted: false,
            timestamp: new Date(),
        };
        callback();
    }
    pdf_fragments_push(fragment) {
        this.pdf_fragments.push(...fragment);
    }
    create_pdf() {
        // let account = this.accountService.get_current_state();
        // let patient = this.patientServiceService.patient_data.general;
        // this.pdf_fragments = [];
        // this.pdfService.init({
        //   title: "Consulta - " + patient.firstname + " " + patient.lastname,
        //   author: (account.id_gender == 1 ? "Dra. " : "Dr. ") + account.firstname + " " + account.lastname,
        //   subject: "Consulta General",
        //   keywords: "UNIMED Plataforma",
        //   creator: "UNIMED Plataforma",
        //   producer: "UNIMED Plataforma",
        // });
        // this.pdfService.definiton.content = this.pdf_fragments;
        // this.pdfService.create();
        // this.pdfService.open();
    }
    full_clean(callback = () => { }) {
        this.current_state.consult.reset();
        callback();
    }
    set_overlay(option) {
        // this.current_state.
    }
}
ConsultGeneralService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConsultGeneralService_Factory() { return new ConsultGeneralService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.PageService), i0.ɵɵinject(i4.PatientService), i0.ɵɵinject(i5.PdfService), i0.ɵɵinject(i6.AccountService), i0.ɵɵinject(i7.CatalogsService), i0.ɵɵinject(i8.PipesService)); }, token: ConsultGeneralService, providedIn: "root" });
