import { Component } from '@angular/core';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-contacts_show_doctor',
  templateUrl: './show_doctor.component.html',
  styleUrls: ['./show_doctor.component.scss']
})
export class ShowContactsDoctorComponent {

  constructor(private pageService: PageService) {
  }

  close() {
    this.pageService.closeModal("");
  }

}
