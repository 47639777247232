import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppService } from 'src/app/services/app.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2'
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset_password.component.html',
	styleUrls: ['./reset_password.component.scss']

})

export class ResetPasswordComponent {

	release_year: number = 2020;
	year: number = new Date().getFullYear();
	private password_regex = /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/g;
	resetForm: FormGroup;
	submitted = false;
	returnUrl: string;
	error = '';
	loading = false;
	private reset_token;
	success = '';

	constructor(private formBuilder: FormBuilder, private endpoint: AppService, private activeRoute: ActivatedRoute, private toastr: ToastrService) {
		this.endpoint.pageTitle = 'Restaurar Contraseña';
	}

	ngOnInit() {
		this.reset_token = this.activeRoute.snapshot.params.token;
		this.resetForm = this.formBuilder.group({
			email: [
				'',
				[
					Validators.required,
					Validators.email
				]
			],
			password: [
				'',
				[
					Validators.required,
					Validators.pattern(this.password_regex),
					Validators.minLength(8)
				]
			],
			confirm_password: [
				'',
				[
					Validators.required,
					Validators.pattern(this.password_regex),
					Validators.minLength(8)
				]
			],
		});
	}

	get f() { return this.resetForm.controls; }

	passwordMatch = () => this.f.password.value != this.f.confirm_password.value

	onSubmit() {
		this.submitted = true;

		if (this.resetForm.invalid || this.f.password.value != this.f.confirm_password.value) {
			return;
		}

		var params = {
			email: this.f.email.value,
			password: this.f.password.value,
			confirm_password: this.f.confirm_password.value,
			token: this.reset_token
		};

		this.loading = true;
		var response;
		this.endpoint.reset_password(params, this.reset_token)
			.subscribe(
				data => response = data,
				err => {
					if (err.error.code == "recovery") {
						this.endpoint.redirect_to('/recuperar_contrasena');
					}

					this.toastr.error(
						err.error.message ? err.error.message : err.message,
						err.error.title ? err.error.title : err.status + ''
					);
					this.error = err.error.message;
					this.loading = false;
				},
				() => {
					Swal.fire({
						title: response.title,
						html: response.message,
						type: 'success',
						confirmButtonText: 'Listo',
						confirmButtonColor: '#66c8c8',
					}).then((result) => {
					});
					this.endpoint.redirect_to('/conectarse');
				}
			)
	};

}
