import { VaccinesInterface } from "./../classes/vaccines/vaccines-class";
import { AppService } from "src/app/services/app.service";
import { WebDBService } from "./webdb.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";

export interface CatalogsInterface {
    genders: {
        id: any;
        name: string;
        active: string;
    }[];
    countries: {
        id: any;
        name: string;
        code: string;
        active: string;
    }[];
    departments: {
        id: any;
        name: string;
        number: string;
    }[];
    towns: {
        id: any;
        name: string;
        id_department: string;
        number: string;
    }[];
    educations: {
        id: any;
        name: string;
        active: string;
    }[];
    bloods: {
        id: any;
        name: string;
    }[];
    allergies_reactions_levels: {
        id: any;
        name: string;
    }[];
    allergies_reactions_types: {
        id: any;
        name: any;
    }[];
    chat_conversation_end_types: {
        id: any;
        name: any;
    }[];
    chat_message_types: {
        id: any;
        name: any;
    }[];
    civil_statuses: {
        id: any;
        name: any;
    }[];
    consults_statuses: {
        id: any;
        name: any;
    }[];
    consults_types: {
        id: any;
        name: any;
    }[];
    doctors_patients_levels: {
        id: any;
        name: any;
    }[];
    doctors_specialties: {
        id: any;
        name: any;
    }[];
    exams_names: {
        id: any;
        id_exams_type: any;
        name: any;
    }[];
    exams_names_2: {
        id: any;
        id_exams_name: any;
        name: any;
    }[];
    exams_types: {
        id: any;
        name: any;
    }[];
    file_types: {
        id: any;
        name: any;
    }[];
    institutions_areas: {
        id: any;
        name: any;
    }[];
    institutions_roles: {
        id: any;
        name: any;
    }[];
    institutions_types: {
        id: any;
        name: any;
    }[];
    institutions_users_roles: {
        id: any;
        name: any;
    }[];
    procedures_types: {
        id: any;
        name: any;
    }[];
    units_eye_vision: {
        id: any;
        name: any;
    }[];
    users_roles: {
        id: any;
        name: any;
    }[];
    users_types: {
        id: any;
        name: any;
    }[];
    tf: {
        id: any;
        name: any;
    }[];
    pathologies: {
        id: any;
        title: string;
        score: number;
    }[];
    units_weight: {
        id: any;
        name: string;
        code: string;
        kg_conv: number;
    }[];
    units_height: {
        id: any;
        name: string;
        code: string;
        m_conv: number;
    }[];
    units_IMC: {
        id: any;
        name: string;
        code: string;
        m_conv: number;
    }[];
    units_glasgow_eye: {
        id: any;
        name: string;
        code: number;
    }[];
    units_glasgow_move: {
        id: any;
        name: string;
        code: number;
    }[];
    units_glasgow_talk: {
        id: any;
        name: string;
        code: number;
    }[];
    units_pulse: {
        id: any;
        name: string;
        code: string;
    }[];
    units_temp: {
        id: any;
        name: string;
        code: string;
        conv: string;
    }[];
    units_sistolic_pressure: {
        id: any;
        name: string;
        code: string;
    }[];
    units_diastolic_pressure: {
        id: any;
        name: string;
        code: string;
    }[];
    units_heart_rate: {
        id: any;
        name: string;
        code: string;
    }[];
    units_breathing_rate: {
        id: any;
        name: string;
        code: string;
    }[];
    units_medicine_dosage: {
        id: any;
        name: string;
        code: string;
    }[];
    medicines_days: {
        id: any;
        name: string;
        code: string;
    }[];
    medicines_frecuencies: {
        id: any;
        name: string;
        code: string;
    }[];
    medicines_concentrations: {
        id: any;
        name: string;
        code: string;
    }[];
    medicines_presentations: {
        id: any;
        name: string;
        code: string;
    }[];
    medicines_ways: {
        id: any;
        name: string;
        code: string;
    }[];
    profiles_educations_types: {
        id: any;
        name: string;
    }[];
    profiles_educations_study_types: {
        id: any;
        name: string;
    }[];
    profiles_educations_modes: {
        id: any;
        name: string;
    }[];
    profiles_educations_honors: {
        id: any;
        name: string;
    }[];
    profiles_educations_places: {
        id: any;
        name: string;
        nick: string;
        image: string;
        url: string;
    }[];
    profiles_educations_specialties: {
        id: any;
        name: string;
    }[];
    profiles_educations_subspecialties: {
        id: any;
        name: string;
    }[];
    profiles_experiences_types: {
        id: any;
        name: string;
    }[];
    specialties_areas: {
        id: any;
        name: string;
    }[];
    vaccines: VaccinesInterface[];
    vaccines_dosis_statuses: {
        id: any;
        name: string;
    }[];
    vaccines_people_groups: {
        id: any;
        name: string;
    }[];
    relationships: {
        id: any;
        name: string;
    }[];
}

@Injectable({
    providedIn: "root",
})
export class CatalogsService {
    private jsons = {
        vaccines: ["descriptions", "dose"],
    };

    // CATALOGS
    public catalogs: CatalogsInterface;
    private behavior_catalogs: BehaviorSubject<CatalogsInterface>;
    public observable_catalogs: Observable<CatalogsInterface>;

    constructor(private dbService: WebDBService, private endpoint: AppService) {
        this.init();
    }

    init() {
        this.clean();
        this.next();

        if (environment.dev) {
            this.fill();
        } else {
            let date = new Date();
            date.setHours(0, 0, 0, 0);
            let s = this.get_session();

            if (+date + "" != s) {
                this.fill();
            } else {
                this.fill();
                // this.bulk();
            }
        }
    }

    fill(callback = () => { }) {
        this.endpoint.get_catalogs({ catalogs: this.get_keys() }).subscribe(
            (catalogs: { [key: string]: any[] }) => {
                this.dbService.overwrite(catalogs, () => {
                    this.get_keys().forEach(value => {
                        let catalog = value ? catalogs[value] : [];
                        if (this.jsons[value]) {
                            this.jsons[value].forEach(j => {
                                catalog.forEach(c => {
                                    if (c[j]) c[j] = JSON.parse(c[j]);
                                });
                            });
                        }
                        if (value) this.catalogs[value] = catalog;
                    });

                    let date = new Date();
                    localStorage.setItem("fdbv", +date + "");
                    date.setHours(0, 0, 0, 0);
                    localStorage.setItem("dbv", +date + "");

                    console.warn("UPDATE:fill: IndexedDB");
                    callback();
                });
            },
            error => {
                console.error(error);
            }
        );
    }

    bulk(callback = () => { }) {
        this.get_keys().forEach(value => {
            this.dbService.getAll(value).subscribe(data => {
                this.get_keys().forEach(value => {
                    let catalog = value ? data : [];
                    if (this.jsons[value]) {
                        this.jsons[value].forEach(j => {
                            catalog.forEach(c => {
                                if (c[j]) {
                                    c[j] = JSON.parse(c[j]);
                                }
                            });
                        });
                    }
                    if (value) this.catalogs[value] = catalog;
                });
            });
        });
        console.warn("UPDATE:bulk: IndexedDB");
        callback();
    }

    load_catalog(catalog: string) {
        this.dbService.getAll(catalog).subscribe(data => {
            this.catalogs[catalog] = data;
        });
    }

    set_catalog(catalog: string, value: any) {
        this.catalogs[catalog] = value;
    }

    next(): void {
        if (this.behavior_catalogs) {
            this.behavior_catalogs.next(this.catalogs);
        } else {
            this.behavior_catalogs = new BehaviorSubject(this.catalogs);
            this.observable_catalogs = this.behavior_catalogs.asObservable();
        }
    }

    clean(): void {
        this.catalogs = {
            pathologies: [],
            allergies_reactions_levels: [],
            allergies_reactions_types: [],
            bloods: [],
            chat_conversation_end_types: [],
            chat_message_types: [],
            civil_statuses: [],
            consults_statuses: [],
            consults_types: [],
            countries: [],
            departments: [],
            doctors_patients_levels: [],
            doctors_specialties: [],
            educations: [],
            exams_names: [],
            exams_names_2: [],
            exams_types: [],
            file_types: [],
            genders: [],
            institutions_areas: [],
            institutions_roles: [],
            institutions_types: [],
            institutions_users_roles: [],
            medicines_concentrations: [],
            medicines_days: [],
            medicines_frecuencies: [],
            medicines_presentations: [],
            medicines_ways: [],
            procedures_types: [],
            towns: [],
            units_breathing_rate: [],
            units_diastolic_pressure: [],
            units_eye_vision: [],
            units_glasgow_eye: [],
            units_glasgow_move: [],
            units_glasgow_talk: [],
            units_heart_rate: [],
            units_height: [],
            units_IMC: [],
            units_pulse: [],
            units_sistolic_pressure: [],
            units_temp: [],
            units_weight: [],
            units_medicine_dosage: [],
            users_roles: [],
            users_types: [],
            tf: [],
            profiles_educations_types: [],
            profiles_educations_study_types: [],
            profiles_educations_modes: [],
            profiles_educations_honors: [],
            profiles_educations_places: [],
            profiles_educations_specialties: [],
            profiles_educations_subspecialties: [],
            profiles_experiences_types: [],
            specialties_areas: [],
            vaccines: [],
            vaccines_dosis_statuses: [],
            vaccines_people_groups: [],
            relationships: [],
        };
    }

    get_keys(): string[] {
        if (!this.catalogs) this.clean();
        let keys = [];

        for (const key in this.catalogs) {
            keys.push(key);
        }
        return keys;
    }

    generate() {
        this.clean();
        let result = [];

        for (const attr in this.catalogs) {
            const obj: any = {};
            obj.store = attr;
            obj.storeConfig = { keyPath: "id", autoIncrement: false };
            obj.storeSchema = [];

            result.push(obj);
        }
        return result;
    }

    get_session() {
        return localStorage.getItem("dbv");
    }

    get_full_session() {
        return localStorage.getItem("fdbv");
    }

    selectWhereLike(list: any[], columns: any) {
        return this.dbService.selectWhereLike(list, columns);
    }
}
