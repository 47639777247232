import { Component } from '@angular/core';
import { ExpedientService } from 'src/app/services/expedient.service';
import { DoctorService } from '../doctor.service';

@Component({
  selector: 'app-contacts_create_doctor',
  templateUrl: './create_doctor.component.html',
  styleUrls: ['./create_doctor.component.scss']
})
export class CreateContactsDoctorComponent {

  constructor(private moduleService: DoctorService, private expedientService: ExpedientService) {
    this.expedientService.change_method("insert");
    this.moduleService.init();
  }

  close() {
    this.moduleService.cancel();
  }

}
