<div class="unimed-left-sidebar bg-white px-3 py-2" style="position: relative;" [class.no-scroll]="!page_state.sidebar_3.scroll">
    <div class="container-fluid px-0">
        <div class="row">
            <div class="col-12">
                <div class="d-flex justify-content-between mb-2">
                    <div class="d-flex flex-column my-auto">
                        <div class="title mb-auto"> mis consultas </div>
                    </div>

                    <div class="my-auto">
                        <div class="d-flex text-right d-local-lg-up">
                            <app-search_button (change)="on_update()" (submit)="on_update()" #search_button_ref></app-search_button>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">

                <app-bold_list
                    #bold_list_ref
                    (update_bold_list)="update($event)"
                    (events_bold_list)="events($event)"
                    [in_headers]="bold_list.headers"
                    [in_contents]="bold_list.contents"
                    [in_data]="data"
                    [in_filters]="bold_list.filters"
                    [in_configs]="bold_list.configs">
                </app-bold_list>

            </div>
        </div>
    </div>
    <ng-container *ngIf="show_consult" [ngTemplateOutlet]="showModal"></ng-container>
</div>

<ng-template #showModal>
    <app-show-medical-consult [data]="selected_item" (close)="show_consult=false"></app-show-medical-consult>
</ng-template>