import { CatalogsService } from "src/app/services/catalogs.service";
import { Component } from "@angular/core";
import { WebDBService } from "./services/webdb.service";

@Component({
    selector: "app-minton",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent {
    constructor(
        private webdbService: WebDBService,
        private catalogsService: CatalogsService
    ) {}
}
