import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PatientService } from 'src/app/services/patient-service.service';
import { AppService } from 'src/app/services/app.service';
import { AccountService } from 'src/app/services/account.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent {

	loginForm: FormGroup;
	submitted: boolean;
	error: string;
	loading: boolean;
	release_year: number = 2020;
	year: number = new Date().getFullYear();
	load: any;
	overwrites: boolean;

	constructor(private formBuilder: FormBuilder, private endpoint: AppService, private modalInj: NgbModal, private toastr: ToastrService, private accountService: AccountService, private patientService: PatientService) {
		this.endpoint.pageTitle = 'Iniciar Sesión';
		this.submitted = false;
		this.error = null;
		this.loading = false;
		this.overwrites = false;
		this.patientService.clean_patient();
		this.endpoint.set_patient_id("")
	}

	ngOnInit() {
		this.loginForm = this.formBuilder.group({
			email: [
				'',
				[
					Validators.required,
				]
			],
			password: [
				'',
				[
					Validators.required,
				]
			],
		});
	}

	get f() { return this.loginForm.controls; }

	login() {
		this.submitted = true;

		if (this.loginForm.invalid) {
			return;
		}

		this.loading = true;

		this.load = {
			username: this.f.email.value,
			password: this.f.password.value,
			overwrites: this.overwrites
		};

		var response;

		this.endpoint.login(this.load)
			.subscribe(
				data => response = data,
				err => {
					this.toastr.error(
						err.error.message ? err.error.message : err.message,
						err.error.title ? err.error.title : err.status + ''
					);

					//this.error = err.error.title;
					this.loading = false;
				},
				() => {
					this.endpoint.set_session({ token: response.token, first_login: response.first_login });

					// Steps 2
					if (response.code == 'steps-2') {
						Swal.fire({
							title: response.title,
							html: response.message,
							type: 'info',
							confirmButtonText: 'Listo',
							confirmButtonColor: '#66c8c8',
						}).then((result) => {
						});
						this.endpoint.redirect_to('/dos_pasos');
					}
					else if (response.first_login) {
						this.accountService.reset();
						this.endpoint.redirect_to('/primer_inicio');
					} else {
						this.endpoint.redirect_to('');
					}
				}
			)
	};
}
