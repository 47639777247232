/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hovered_sidebar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../request_holders/overlay/overlay.component.ngfactory";
import * as i5 from "../request_holders/overlay/overlay.component";
import * as i6 from "./hovered_sidebar.component";
var styles_HoveredSidebarComponent = [i0.styles];
var RenderType_HoveredSidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HoveredSidebarComponent, data: {} });
export { RenderType_HoveredSidebarComponent as RenderType_HoveredSidebarComponent };
function View_HoveredSidebarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, [[1, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(2, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpod(5, { exact: 0 }), i1.ɵpad(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 4, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.url(); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, ((_v.parent.parent.context.$implicit.exact && _v.parent.parent.context.$implicit.exact()) ? true : false)); var currVal_2 = _ck(_v, 6, 0, "active"); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = ((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.icon()); _ck(_v, 9, 0, currVal_3); var currVal_4 = ((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.name()); _ck(_v, 11, 0, currVal_4); }); }
function View_HoveredSidebarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (_v.parent.parent.context.$implicit.click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.parent.context.$implicit.active ? _v.parent.parent.context.$implicit.active() : false); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.icon()); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_v.parent.parent.context.$implicit == null) ? null : _v.parent.parent.context.$implicit.name()); _ck(_v, 5, 0, currVal_2); }); }
function View_HoveredSidebarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HoveredSidebarComponent_4)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HoveredSidebarComponent_5)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.parent.context.$implicit.click; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.click; _ck(_v, 4, 0, currVal_1); }, null); }
function View_HoveredSidebarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HoveredSidebarComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.show ? _v.context.$implicit.show() : true); _ck(_v, 2, 0, currVal_0); }, null); }
function View_HoveredSidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "hovered_sidebar-menu"]], [[2, "lock_expanded", null], [2, "style_shadowbox", null], [2, "style_border", null], [2, "style_noborder", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "hovered_sidebar-menu-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "hovered_sidebar-menu-navigation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HoveredSidebarComponent_2)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.config.list; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.config.lock_expanded; var currVal_1 = (_co.config.style == "shadowbox"); var currVal_2 = (_co.config.style == "border"); var currVal_3 = (_co.config.style == "no-border"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_HoveredSidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HoveredSidebarComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-overlay", [], null, null, null, i4.View_OverlayComponent_0, i4.RenderType_OverlayComponent)), i1.ɵdid(3, 573440, null, 0, i5.OverlayComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !!_co.config; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_HoveredSidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hovered_sidebar", [], null, null, null, View_HoveredSidebarComponent_0, RenderType_HoveredSidebarComponent)), i1.ɵdid(1, 49152, null, 0, i6.HoveredSidebarComponent, [], null, null)], null, null); }
var HoveredSidebarComponentNgFactory = i1.ɵccf("app-hovered_sidebar", i6.HoveredSidebarComponent, View_HoveredSidebarComponent_Host_0, { config: "config" }, {}, []);
export { HoveredSidebarComponentNgFactory as HoveredSidebarComponentNgFactory };
