export class ChartDatasetInterface {
    label: string;
    data: any[];
    backgroundColor?: string;
    borderColor?: string;
    borderWidth?: number;
    tension?: number;
    pointBackgroundColor?: any;
    pointBorderColor?: any;
    borderJoinStyle?: 'round' | 'bevel' | 'miter';
    pointRadius?: number;
    pointHoverRadius?: number;
    fill?: boolean | string;
    pointStyle?: 'circle' | 'cross' | 'crossRot' | 'dash' | 'line' | 'rect' | 'rectRounded' | 'rectRot' | 'star' | 'triangle' | false;
    tooltip?: any;
    cubicInterpolationMode?: any;
}

export class ChartDataset implements ChartDatasetInterface {
    label: string;
    data: any[];
    backgroundColor?: string;
    borderColor?: string;
    borderWidth?: number;
    tension?: number;
    pointBackgroundColor?: any;
    pointBorderColor?: any;
    borderJoinStyle?: 'round' | 'bevel' | 'miter';
    pointRadius?: number;
    pointHoverRadius?: number;
    fill?: boolean | string;
    pointStyle?: any;
    tooltip?: any;
    cubicInterpolationMode?: any;

    constructor(
        data: ChartDatasetInterface
    ) {
        this.label = data.label;
        this.data = data.data;
        this.backgroundColor = data.backgroundColor;
        this.borderColor = data.borderColor;
        this.borderWidth = data.borderWidth;
        this.tension = data.tension;
        this.borderJoinStyle = data.borderJoinStyle;
        this.pointBackgroundColor = data.pointBackgroundColor;
        this.pointBorderColor = data.pointBorderColor;
        this.pointRadius = data.pointRadius;
        this.pointHoverRadius = data.pointHoverRadius;
        this.fill = data.fill;
        this.pointStyle = data.pointStyle;
        this.tooltip = data.tooltip;
        this.cubicInterpolationMode = data.cubicInterpolationMode;

    }

    updateLabel(label: string) {
        this.label = label;
    }

    updateData(data: any[]) {
        this.data = data;
    }

    updateBackgroundColor(backgroundColor: string) {
        this.backgroundColor = backgroundColor;
    }

    updateBorderColor(borderColor: string) {
        this.borderColor = borderColor;
    }

    updateBorderWidth(borderWidth: number) {
        this.borderWidth = borderWidth;
    }
}