import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormRenderComponent, form_type } from 'src/app/shared/ui/form_render/form_render.component';
import { InstitutionsService, InstitutionsState } from '../institutions.service';
import { ExpedientMetadata, ExpedientService } from 'src/app/services/expedient.service';

@Component({
	selector: 'app-module-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy, AfterViewInit {
	private expedient_subscription: Subscription;
	public expedient_state: ExpedientMetadata;

	private institutions_subscription: Subscription;
	public institutions_state: InstitutionsState;

	@ViewChild('form_ref', { static: false }) form_ref: FormRenderComponent;
	public form_config: form_type;

	constructor(private moduleService: InstitutionsService, private expedientService: ExpedientService) {
	}

	ngOnInit() {
		this.expedient_subscription = this.expedientService.current.subscribe(
			state => {
				this.expedient_state = state;
				// this.moduleService.reload();
				this.form_configs();
			}
		);
		this.institutions_subscription = this.moduleService.current.subscribe(
			state => this.institutions_state = state
		);
		this.form_configs();
	}

	ngAfterViewInit() {
		if (this.institutions_state) {
			this.moduleService.forms.institutions = this.form_ref;
		}
	}

	ngOnDestroy() {
		this.expedient_subscription.unsubscribe();
		this.institutions_subscription.unsubscribe();
	}

	form_configs() {
		this.form_config = {
			pretty_view: () => this.expedient_state.method == "show",
			disabled: () => this.expedient_state.method == "show",
			submit: (callback) => this.moduleService.submit(callback),
			rows: [
				{
					class: "row",
					cols: [
						{
							name: "id_institution",
							type: "select",
							label: "Institución",
							placeholder: () => "- Seleccione -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							catalog: {
								list: () => {
									if (this.expedient_state.method == "insert") {
										return this.moduleService.catalogs.institutions.filter(institution => !this.moduleService.list_get.find(value => institution.id == value.id_institution))
									}
									else if (this.expedient_state.method == "update") {
										return this.moduleService.catalogs.institutions.filter(institution => !this.moduleService.list_get.find(value => institution.id == value.id_institution && value.id_institution != this.institutions_state.id_institution))
									}
									else {
										return this.moduleService.catalogs.institutions;
									}
								},
								value: "id",
								text: "name"
							},
							disabled: () => false,
							change: (data) => {
								this.institutions_state.id_institutions_grades_section = null;
							}
						},
						{
							name: "id_institutions_role",
							type: "select",
							label: "Cargo",
							placeholder: () => "- Seleccione -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							catalog: {
								list: () => this.moduleService.catalogs.institutions_roles,
								value: "id",
								text: "name"
							},
							disabled: () => false,
						},
						{
							name: "id_institutions_area",
							type: "select",
							label: "Área",
							placeholder: () => "- Seleccione -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							catalog: {
								list: () => this.moduleService.catalogs.institutions_areas,
								value: "id",
								text: "name"
							},
							disabled: () => false,
						},
						{
							name: "id_institutions_grade",
							type: "select",
							label: "Grado",
							placeholder: () => this.institutions_state.id_institution ? "- Seleccione -" : "- Seleccione Institución -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => false
								},
							},
							catalog: {
								list: () => this.moduleService.catalogs.institutions_grades.filter(value => value.id_institution == this.institutions_state.id_institution),
								value: "id",
								text: "name"
							},
							disabled: () => !this.institutions_state.id_institution,
							change: (data) => {
								this.institutions_state.id_institutions_grades_section = null;
							}
						},
						{
							name: "id_institutions_grades_section",
							type: "select",
							label: "Sección",
							placeholder: () => this.institutions_state.id_institutions_grade ? "- Seleccione -" : "- Seleccione Grado -",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => false
								},
							},
							catalog: {
								list: () => this.moduleService.catalogs.institutions_grades_sections.filter(value => value.id_institutions_grade == this.institutions_state.id_institutions_grade),
								value: "id",
								text: "name"
							},
							disabled: () => !this.institutions_state.id_institutions_grade,
						},
					]
				},
				{
					class: "row",
					cols: [
						{
							type: "buttons",
							col_class: "col-12",
							buttons: [
								{
									class: "btn btn-sm btn-outline-danger",
									label: "Cancelar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									show: () => {
										return this.expedient_state.method == "update" || this.expedient_state.method == "insert";
									},
									click: () => {
										this.moduleService.cancel();
									},
								},
								{
									class: "btn btn-sm btn-outline-danger",
									label: "Cerrar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									show: () => {
										return this.expedient_state.method == "show";
									},
									click: () => {
										this.moduleService.close();
									},
								},
								{
									class: "btn btn-sm btn-success ml-auto",
									label: "Guardar",
									type: "submit",
									icon: {
										class: "fa-duotone fa-check",
										position: 'right',
									},
									show: () => {
										return this.expedient_state.method == "update" || this.expedient_state.method == "insert";
									},
									disabled: () => (this.form_ref ? !this.form_ref.valid() : false) || this.institutions_state.loading,
								},
							]
						},
					],
				},
			]
		};
	}
}