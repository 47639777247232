import { CatalogsService } from "src/app/services/catalogs.service";
import { CustomClass } from "./custom-class";

export interface AnthropometriesInterface {
    id_consult: any;
    weight: string;
    id_weight_unit?: string;
    height: string;
    id_height_unit?: string;
    IMC: string;
    id_imc_unit?: string;
};

interface ToStringInterface {
    weight: string;
    height: string;
    IMC: string;
};

export class AnthropometriesClass extends CustomClass implements AnthropometriesInterface {
    public id_consult: any;
    public weight: string;
    public id_weight_unit?: string;
    public height: string;
    public id_height_unit?: string;
    public IMC: string;
    public id_imc_unit?: string;

    constructor(private catalogsService: CatalogsService, data?: AnthropometriesInterface) {
        super();
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: AnthropometriesInterface): void {
        super.overwrite(data);
        this.id_consult = data.id_consult;
        this.weight = data.weight;
        this.id_weight_unit = data.id_weight_unit ? data.id_weight_unit : "1";
        this.height = data.height;
        this.id_height_unit = data.id_height_unit ? data.id_height_unit : "1";
        this.IMC = (+data.IMC).toFixed(2) + "";
        this.id_imc_unit = "1";
    }

    reset(): void {
        super.reset();
        this.id_consult = null;
        this.weight = "";
        this.id_weight_unit = "1";
        this.height = "";
        this.id_height_unit = "1";
        this.IMC = "";
        this.id_imc_unit = "1";
    }

    format(): AnthropometriesInterface {
        this.weight = this.weight.replace(/,/g, "");
        this.height = this.height.replace(/,/g, "");
        this.IMC = this.IMC.replace(/,/g, "");
        return this as AnthropometriesInterface;
    }

    valid(): boolean {
        return true;
    }

    to_string(): ToStringInterface {
        let data = this as AnthropometriesInterface;
        let weight = this.catalogsService.catalogs.units_weight.find(v => v.id == this.id_weight_unit);
        let height = this.catalogsService.catalogs.units_height.find(v => v.id == this.id_height_unit);

        if (data.weight) data.weight += ` ${weight ? weight.code : ""}`;
        if (data.height) data.height += ` ${height ? height.code : ""}`;

        return data as ToStringInterface;
    }

    get_ideal_weight(value: number, id: string): number {
        let element = this.catalogsService.catalogs.units_weight.find(v => v.id == id);
        return element ? value * element.kg_conv : null;
    }

    get_ideal_height(value: number, id: string): number {
        let element = this.catalogsService.catalogs.units_height.find(v => v.id == id);
        return element ? value * element.m_conv : null;
    }

    raw(): AnthropometriesInterface {
        return {
            id_consult: this.id_consult,
            weight: this.weight,
            id_weight_unit: this.id_weight_unit,
            height: this.height,
            id_height_unit: this.id_height_unit,
            IMC: this.IMC,
        };
    }

    calculate_IMC(): void {
        if (this.height && this.weight) {
            let height = this.get_ideal_height(parseFloat(this.height.replace(/,/g, "")), this.id_height_unit);
            let weight = this.get_ideal_weight(parseFloat(this.weight.replace(/,/g, "")), this.id_weight_unit);
            this.IMC = (weight / (height * height)).toFixed(2) + "";
        } else {
            this.IMC = "";
        }
    }
}