import { Routes } from '@angular/router';
const ɵ0 = () => import("./accident/accident.module.ngfactory").then(m => m.InsurancesAccidentModuleNgFactory);
const routes = [
    {
        path: '',
        redirectTo: 'accidentes',
        pathMatch: 'prefix'
    },
    {
        path: 'accidentes',
        loadChildren: ɵ0
    }
];
export class InsurancesModule {
}
export { ɵ0 };
