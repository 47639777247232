import { Pipe, PipeTransform } from '@angular/core';
import { PipesService } from 'src/app/services/pipes.service';

@Pipe({ name: 'timeToString' })
export class timeToString implements PipeTransform {
  constructor(private pipesService: PipesService) {

  }
  transform(str: string): string {
    return this.pipesService.time_tostring(str);
  }
}