/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./error.component";
var styles_ErrorSmallComponent = [];
var RenderType_ErrorSmallComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ErrorSmallComponent, data: {} });
export { RenderType_ErrorSmallComponent as RenderType_ErrorSmallComponent };
function View_ErrorSmallComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "fa-duotone fa-light-emergency-on text-danger animated fadeIn delay-3s ", _co.class, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ErrorSmallComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h5", [["class", "ml-2"]], [[2, "ml-2", null]], null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inline; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 1, 0, currVal_1); }); }
export function View_ErrorSmallComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "d-flex justify-content-center align-items-center"]], [[2, "flex-column", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorSmallComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ErrorSmallComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.error; _ck(_v, 2, 0, currVal_1); var currVal_2 = (_co.error && _co.title); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.inline; _ck(_v, 0, 0, currVal_0); }); }
export function View_ErrorSmallComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-error-small", [], null, null, null, View_ErrorSmallComponent_0, RenderType_ErrorSmallComponent)), i0.ɵdid(1, 573440, null, 0, i2.ErrorSmallComponent, [], null, null)], null, null); }
var ErrorSmallComponentNgFactory = i0.ɵccf("app-error-small", i2.ErrorSmallComponent, View_ErrorSmallComponent_Host_0, { error: "error", class: "class", title: "title", inline: "inline" }, {}, []);
export { ErrorSmallComponentNgFactory as ErrorSmallComponentNgFactory };
