import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, Observable } from "rxjs";
import { PageService } from "src/app/services/page.service";
import { PatientService } from "src/app/services/patient-service.service";
import { AppService } from "src/app/services/app.service";
import { PdfService } from "src/app/services/pdf.service";
import { AccountService } from "src/app/services/account.service";
import {
    ConsultClass,
    RawConsultInterface,
} from "../../../../classes/consult-class";
import { CatalogsService } from "src/app/services/catalogs.service";
import { PipesService } from "src/app/services/pipes.service";

export interface ConsultMetadata {
    consult: ConsultClass;
    method: "update" | "show";

    extras: any[];
    submitted: boolean;
    loading: boolean;
    overlay: boolean;
    timestamp: Date;
}

@Injectable({ providedIn: "root" })
export class ConsultGeneralService {
    public pdf_fragments: any[];

    public current_state: ConsultMetadata;
    private consult_state: BehaviorSubject<ConsultMetadata>;
    public current: Observable<ConsultMetadata>;
    private store_name = "unimed-expedient-consult-id";
    public loading_timeout;

    constructor(
        private endpoint: AppService,
        private toastr: ToastrService,
        private pageService: PageService,
        private patientServiceService: PatientService,
        private pdfService: PdfService,
        private accountService: AccountService,
        private catalogsService: CatalogsService,
        private pipesService: PipesService
    ) {
        this.loading_timeout = null;
        this.clean();
        this.next();
    }

    load_storage() {
        this.set_loading(true, 0);
        let id = this.get_storage();
        if (id) {
            this.load(id);
        }
    }

    new_consult() {
        this.init_consult();
    }

    load(id: any) {
        this.get_consult(id);
    }

    next() {
        if (this.consult_state) {
            this.consult_state.next(this.current_state);
        } else {
            this.consult_state = new BehaviorSubject(this.current_state);
            this.current = this.consult_state.asObservable();
        }
    }

    valid() {
        return this.current_state.consult.valid();
    }

    get_current_state() {
        return this.current_state;
    }

    init_consult() {
        this.set_loading(true, 0);
        let load = {
            id_patient: this.patientServiceService.patient_data.general.id_user,
        };
        this.endpoint.expedient_consult_general_init_consult(load).subscribe(
            (data: RawConsultInterface) => {
                this.current_state.method = "update";
                this.current_state.consult.init(data);
                this.save_storage();
                this.set_loading(false, 1000);
            },
            (error) => {
                console.error(error);
                this.set_loading(false, 1000);
            }
        );
    }

    get_consult(id: any) {
        let load = {
            id: id,
        };
        this.endpoint.expedient_consult_general_get_consult(load).subscribe(
            (data: RawConsultInterface) => {
                console.log(data)
                console.log(this.current_state.consult)
                this.current_state.method = "update";
                this.current_state.consult.init(data);
                this.set_loading(false, 1000);
                this.next();
            },
            (error) => {
                console.error(error);
                this.set_loading(false, 1000);
            }
        );
    }

    save_consult(callback = () => { }) {
        this.set_loading(true, 0);
        this.endpoint
            .expedient_consult_general_save_consult(
                this.current_state.consult.raw()
            )
            .subscribe(
                (data) => {
                    this.get_consult(this.current_state.consult.id);
                    callback();
                },
                (error) => {
                    console.error(error);
                    this.set_loading(false, 1000);
                }
            );
    }

    finish_consult(callback = () => { }) {
        this.set_loading(true, 0);
        this.endpoint
            .expedient_consult_general_finish_consult(
                this.current_state.consult.raw()
            )
            .subscribe(
                (data) => {
                    this.toastr.success(data["message"], data["title"]);
                    callback();
                    this.remove_storage();
                    localStorage.removeItem("inConsult");
                    // this.endpoint.redirect_to("/expediente");

                    this.endpoint.redirect_to("/expediente/consulta/" + this.current_state.consult.id);
                },
                (error) => {
                    console.error(error);
                    this.set_loading(false, 1000);
                }
            );
    }

    cancel_consult(callback = () => { }, id?: any) {
        this.set_loading(true, 0);
        this.endpoint
            .expedient_consult_general_cancel_consult(
                !id ? this.current_state.consult.raw() : { id: id }
            )
            .subscribe(
                (data) => {
                    this.toastr.success(data["message"], data["title"]);
                    callback();
                    this.remove_storage();
                    localStorage.removeItem("inConsult");
                    this.endpoint.redirect_to("/expediente");
                },
                (error) => {
                    console.error(error);
                    this.set_loading(false, 1000);
                }
            );
    }

    set_loading(option: boolean, time: number = 1000) {
        clearTimeout(this.loading_timeout);

        this.loading_timeout = setTimeout(() => {
            this.current_state.loading = option;
        }, time);
    }

    save_storage(id: any = this.current_state.consult.id) {
        localStorage.setItem(this.store_name, id);
    }

    get_storage(): string | number {
        return localStorage.getItem(this.store_name);
    }

    remove_storage() {
        localStorage.removeItem(this.store_name);
    }

    cancel(id?, callback?) {
        this.pageService.cancel_consult(() => {
            this.cancel_consult(() => {
                this.remove_storage();
                if (id) {
                    callback();
                } else {
                    this.endpoint.redirect_to("/expediente/consultas");
                }
            }, id);
        });
    }

    close(callback = () => { }) {
        this.pageService.close_consult(() => {
            callback();
        });
    }

    save_close_consult() {
        this.close(() => {
            this.set_loading(true, 0);
            this.save_consult(() => {
                this.remove_storage();
                this.endpoint.redirect_to("/expediente/consultas");
            });
        });
    }

    save_minimize_consult() {
        this.set_loading(true, 0);
        this.save_consult(() => {
            localStorage.removeItem("inConsult");
            this.endpoint.redirect_to("/expediente");
        });
    }

    clean(callback = () => { }) {
        this.current_state = {
            consult: new ConsultClass(this.catalogsService, this.pipesService),

            extras: [],
            method: "update",
            loading: true,
            overlay: false,
            submitted: false,
            timestamp: new Date(),
        };
        callback();
    }

    pdf_fragments_push(fragment: any[]) {
        this.pdf_fragments.push(...fragment);
    }

    create_pdf() {
        // let account = this.accountService.get_current_state();
        // let patient = this.patientServiceService.patient_data.general;
        // this.pdf_fragments = [];
        // this.pdfService.init({
        //   title: "Consulta - " + patient.firstname + " " + patient.lastname,
        //   author: (account.id_gender == 1 ? "Dra. " : "Dr. ") + account.firstname + " " + account.lastname,
        //   subject: "Consulta General",
        //   keywords: "UNIMED Plataforma",
        //   creator: "UNIMED Plataforma",
        //   producer: "UNIMED Plataforma",
        // });
        // this.pdfService.definiton.content = this.pdf_fragments;
        // this.pdfService.create();
        // this.pdfService.open();
    }

    full_clean(callback = () => { }) {
        this.current_state.consult.reset();
        callback();
    }

    set_overlay(option: boolean) {
        // this.current_state.
    }

}
