/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./overlay.component";
var styles_OverlayComponent = [];
var RenderType_OverlayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OverlayComponent, data: {} });
export { RenderType_OverlayComponent as RenderType_OverlayComponent };
function View_OverlayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "overlay d-flex justify-content-center align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "i", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(5, 2), (_l()(), i0.ɵeld(6, 0, null, null, 1, "h1", [["class", "col-12 text-white text-center display-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "h3", [["class", "col-12 text-white text-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, _co.icon, "display-1 col-12 text-center text-local-aqua fa-spin"); _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.message; _ck(_v, 9, 0, currVal_2); }); }
export function View_OverlayComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverlayComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OverlayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-overlay", [], null, null, null, View_OverlayComponent_0, RenderType_OverlayComponent)), i0.ɵdid(1, 573440, null, 0, i2.OverlayComponent, [], null, null)], null, null); }
var OverlayComponentNgFactory = i0.ɵccf("app-overlay", i2.OverlayComponent, View_OverlayComponent_Host_0, { title: "title", message: "message", icon: "icon", show: "show" }, {}, []);
export { OverlayComponentNgFactory as OverlayComponentNgFactory };
