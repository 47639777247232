/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chat.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/ui/pipes/longTime.component";
import * as i3 from "../../shared/ui/avatar/avatar.component.ngfactory";
import * as i4 from "../../shared/ui/avatar/avatar.component";
import * as i5 from "../../services/app.service";
import * as i6 from "@angular/common";
import * as i7 from "@angular/forms";
import * as i8 from "./chat.component";
import * as i9 from "ngx-toastr";
import * as i10 from "../../services/page.service";
import * as i11 from "../../services/socket.service";
var styles_ChatComponent = [i0.styles];
var RenderType_ChatComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChatComponent, data: {} });
export { RenderType_ChatComponent as RenderType_ChatComponent };
function View_ChatComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], [[2, "to", null], [2, "from", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "stats"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fas"]], [[2, "fa-clock", null], [2, "fa-check", null], [2, "fa-check-double", null], [2, "text-primary", null], [2, "text-dark-primary", null]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = (_v.context.$implicit.subject == "to"); var currVal_1 = (_v.context.$implicit.subject == "from"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.content; _ck(_v, 1, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.created_at)); _ck(_v, 4, 0, currVal_3); var currVal_4 = (_v.context.$implicit.status == 1); var currVal_5 = (_v.context.$implicit.status == 2); var currVal_6 = ((_v.context.$implicit.status == 3) || (_v.context.$implicit.status == 4)); var currVal_7 = ((_v.context.$implicit.status == 4) && (_v.context.$implicit.subject == "to")); var currVal_8 = ((_v.context.$implicit.status == 4) && (_v.context.$implicit.subject == "from")); _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_ChatComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.longOnlyTime, []), (_l()(), i1.ɵeld(1, 0, null, null, 25, "div", [["class", "chat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "avatar", [], null, null, null, i3.View_AvatarComponent_0, i3.RenderType_AvatarComponent)), i1.ɵdid(4, 49152, null, 0, i4.AvatarComponent, [i5.AppService], { _name: [0, "_name"], _image: [1, "_image"], configs: [2, "configs"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "exit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "fa-duotone fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["class", "messages"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "local-internal-scroll"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "scroll-content py-2 pl-2 pr-1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChatComponent_1)), i1.ɵdid(14, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "deep-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(17, 0, null, null, 9, "div", [["class", "entry"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 6, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 5, "textarea", [["class", "form-control"], ["id", "message"], ["name", "message"], ["rows", "2.5"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 20).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 20)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 20)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.message.content = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 16384, null, 0, i7.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i7.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DefaultValueAccessor]), i1.ɵdid(22, 671744, null, 0, i7.NgModel, [[8, null], [8, null], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(24, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵeld(25, 0, null, null, 1, "div", [["class", "send"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 0, "i", [["class", "fa-duotone fa-paper-plane"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.firstname; var currVal_1 = _co.data.image; var currVal_2 = _co.avatar_configs; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _co.messages; _ck(_v, 14, 0, currVal_5); var currVal_13 = "message"; var currVal_14 = _co.message.content; _ck(_v, 22, 0, currVal_13, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.data.firstname; var currVal_4 = _co.data.lastname; _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 24).ngClassUntouched; var currVal_7 = i1.ɵnov(_v, 24).ngClassTouched; var currVal_8 = i1.ɵnov(_v, 24).ngClassPristine; var currVal_9 = i1.ɵnov(_v, 24).ngClassDirty; var currVal_10 = i1.ɵnov(_v, 24).ngClassValid; var currVal_11 = i1.ɵnov(_v, 24).ngClassInvalid; var currVal_12 = i1.ɵnov(_v, 24).ngClassPending; _ck(_v, 19, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); }); }
export function View_ChatComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-chat", [], null, null, null, View_ChatComponent_0, RenderType_ChatComponent)), i1.ɵdid(1, 245760, null, 0, i8.ChatComponent, [i5.AppService, i9.ToastrService, i10.PageService, i11.SocketService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChatComponentNgFactory = i1.ɵccf("app-chat", i8.ChatComponent, View_ChatComponent_Host_0, { data: "data" }, {}, []);
export { ChatComponentNgFactory as ChatComponentNgFactory };
