import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { PageService } from 'src/app/services/page.service';
import { AppService } from 'src/app/services/app.service';
import { ExpedientService } from 'src/app/services/expedient.service';
import Swal from 'sweetalert2';
import * as i0 from "@angular/core";
import * as i1 from "../../../../services/app.service";
import * as i2 from "ngx-toastr";
import * as i3 from "../../../../services/page.service";
import * as i4 from "../../../../services/expedient.service";
;
;
;
export class AccidentService {
    constructor(endpoint, toastr, pageService, expedientService) {
        this.endpoint = endpoint;
        this.toastr = toastr;
        this.pageService = pageService;
        this.expedientService = expedientService;
        this.store_list_name = "unimed-expedient-insurances-accident-list";
        this.store_name = "unimed-expedient-insurances-accident";
    }
    // LIST FUNCTIONS
    init_list() {
        this.expedientService.set_expedient_service({ store_url: this.store_list_name, submit: (callback) => this.submit_stored(callback) }, "insurances", "accident");
        this.clean_list();
        this.list_next();
    }
    reload_list(data) {
        if (this.expedientService.state.id_user) {
            this.get_list(data);
        }
        else {
            this.get_stored_list();
        }
    }
    get_list(data) {
        this.current_filters = data;
        let load = Object.assign({ id_user: this.expedientService.state.id_user }, data);
        this.endpoint.expedient_insurances_accident_get_accidents(load).subscribe((data) => this.list = data, error => console.error(error), () => {
            this.list_next();
        });
    }
    get_stored_list() {
        setTimeout(() => {
            let stored = localStorage.getItem(this.store_list_name);
            if (stored) {
                this.list = JSON.parse(stored);
            }
            else {
                this.clean_list();
                this.list.loaded = true;
                this.save_list();
            }
            this.list_next();
        }, 1);
    }
    list_next() {
        if (this.list_state)
            this.list_state.next(this.list);
        else {
            this.list_state = new BehaviorSubject(this.list);
            this.obv_list = this.list_state.asObservable();
        }
    }
    clean_list() {
        return this.list = {
            list: [],
            count: 0,
            skip: 0,
            loaded: false
        };
    }
    save_list() {
        localStorage.setItem(this.store_list_name, JSON.stringify(this.list));
    }
    // EVENT FUNCTIONS
    init(accident = null) {
        if (accident) {
            if (this.expedientService.state.method == "update") {
                accident.img_back = [{ relativePath: accident.img_back[0], insecure: true }];
                accident.img_front = [{ relativePath: accident.img_front[0], insecure: true }];
            }
            this.current_state = accident;
            this.current_state.loading = false;
            this.current_state.submitted = false;
            this.current_state.timestamp = new Date;
        }
        else
            this.internal_clean();
        this.next();
        this.forms = {
            accident: null
        };
        this.forms_state = new BehaviorSubject(this.forms);
        this.obv_forms = this.forms_state.asObservable();
    }
    reload() {
        if (this.expedientService.state.id_user) {
            this.get_patient_data();
        }
    }
    get_current_state() {
        return this.current_state;
    }
    get_patient_data() {
        let load = {
            id_user: this.expedientService.state.id_user
        };
        this.endpoint.expedient_insurances_accident_get_accidents(load).subscribe((data) => this.current_state = data, error => console.error(error), () => {
            this.save();
            this.next();
        });
    }
    refresh_forms() {
        this.forms_state.next(this.forms);
    }
    next() {
        if (this.module_state)
            this.module_state.next(this.current_state);
        else {
            this.module_state = new BehaviorSubject(this.current_state);
            this.current = this.module_state.asObservable();
        }
    }
    valid() {
        return !this.current_state.loading &&
            this.forms && this.forms.accident && this.forms.accident.valid ? this.forms.accident.valid() : false;
    }
    set_form(form) {
        this.forms[form.name] = form.module;
    }
    save() {
        localStorage.setItem(this.store_name, JSON.stringify(this.current_state));
    }
    submit(callback = (callback_data) => { }) {
        if (!(this.forms && this.forms.accident && this.forms.accident.valid()) || this.current_state.loading) {
            callback();
            return false;
        }
        this.current_state.id_user = this.expedientService.state.id_user;
        this.current_state.submitted = true;
        this.current_state.loading = true;
        this.module_state.next(this.current_state);
        const formData = new FormData();
        for (const item in this.current_state) {
            if (item != "img_back_file" && item != "img_front_file" && item != "img_back" && item != "img_front" && this.current_state[item]) {
                formData.append(item, this.current_state[item]);
            }
        }
        if (this.expedientService.state.id_user) {
            if (this.expedientService.state.method == "insert") {
                this.insert(formData, callback);
            }
            else {
                this.update(formData, callback);
            }
        }
        else {
            if (this.expedientService.state.method == "insert") {
                this.insert_store(callback);
            }
            else {
                this.update_store(callback);
            }
        }
    }
    insert(formData, callback = (callback_data) => { }) {
        if (this.current_state.img_front) {
            for (const droppedFile of this.current_state.img_front) {
                if (!droppedFile.insecure && droppedFile.fileEntry.isFile) {
                    const fileEntry = droppedFile.fileEntry;
                    fileEntry.file((file) => {
                        formData.append("img_front", file, file.name);
                    });
                }
            }
        }
        if (this.current_state.img_back) {
            for (const droppedFile of this.current_state.img_back) {
                if (!droppedFile.insecure && droppedFile.fileEntry.isFile) {
                    const fileEntry = droppedFile.fileEntry;
                    fileEntry.file((file) => {
                        formData.append("img_back", file, file.name);
                    });
                }
            }
        }
        this.endpoint.expedient_insurances_accident_insert_accident(formData).subscribe(data => {
            if (this.expedientService.state.version == "expedient") {
                localStorage.removeItem("inExpedientInsurancesAccident");
                this.toastr.success(data["message"], data["title"]);
                this.current_state.loading = false;
                this.module_state.next(this.current_state);
                if (this.forms.accident)
                    this.forms.accident.unsubmit();
                this.expedientService.update_global();
                this.reload_list(this.current_filters);
                this.close();
                this.clean();
                callback(data);
            }
            else if (this.expedientService.state.version == "new_patient") {
                callback(data);
            }
        }, error => {
            console.error(error);
            this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + '');
            this.current_state.loading = false;
            this.module_state.next(this.current_state);
        });
    }
    insert_store(callback = (callback_data) => { }) {
        this.current_state.id = new Date().getTime();
        this.expedientService.file_list.insurances.accident.push({ id: this.current_state.img_front_file = new Date().getTime(), name: "front", file: this.current_state.img_front });
        this.expedientService.file_list.insurances.accident.push({ id: this.current_state.img_back_file = new Date().getTime() + 1, name: "back", file: this.current_state.img_back });
        // this.current_state.created_at = new Date().toISOString();
        // this.current_state.doctor_name = "Yo";
        this.list.list.push(this.current_state);
        callback();
        this.save_list();
        this.list_next();
        this.close();
        this.clean();
    }
    update(formData, callback = (callback_data) => { }) {
        if (this.current_state.img_front) {
            for (const droppedFile of this.current_state.img_front) {
                if (!droppedFile.insecure && droppedFile.fileEntry.isFile) {
                    const fileEntry = droppedFile.fileEntry;
                    fileEntry.file((file) => {
                        formData.append("img_front_file", file, file.name);
                    });
                }
                else {
                    formData.append("img_front", droppedFile.relativePath);
                }
            }
        }
        if (this.current_state.img_back) {
            for (const droppedFile of this.current_state.img_back) {
                if (!droppedFile.insecure && droppedFile.fileEntry.isFile) {
                    const fileEntry = droppedFile.fileEntry;
                    fileEntry.file((file) => {
                        formData.append("img_back_file", file, file.name);
                    });
                }
                else {
                    formData.append("img_back", droppedFile.relativePath);
                }
            }
        }
        this.endpoint.expedient_insurances_accident_update_accident(formData).subscribe(data => {
            localStorage.removeItem("inExpedientInsurancesAccident");
            this.toastr.success(data["message"], data["title"]);
            this.current_state.loading = false;
            this.module_state.next(this.current_state);
            if (this.forms.accident)
                this.forms.accident.unsubmit();
            this.expedientService.update_global();
            this.reload_list(this.current_filters);
            this.close();
            this.clean();
            callback();
        }, error => {
            console.error(error);
            this.toastr.error(error.error && error.error.message ? error.error.message : error.message, error.error && error.error.title ? error.error.title : error.status + '');
            this.current_state.loading = false;
            this.module_state.next(this.current_state);
        });
    }
    update_store(callback = (callback_data) => { }) {
        let index = this.list.list.findIndex(value => value.id == this.current_state.id);
        this.list.list[index] = this.current_state;
        callback();
        this.save_list();
        this.list_next();
        this.close();
        this.clean();
    }
    delete() {
        if (this.expedientService.state.id_user) {
            this._delete();
        }
        else {
            this._delete_store();
        }
    }
    _delete() {
        let load = {
            id: this.current_state.id
        };
        this.endpoint.expedient_insurances_accident_delete_accident(load).subscribe(data => {
            this.toastr.success(data["message"], data["title"]);
            this.expedientService.update_global();
            this.reload_list(this.current_filters);
            this.close();
        }, error => {
            this.toastr.error(error.error.message ? error.error.message : error.message, error.error.title ? error.error.title : error.status + '');
        });
    }
    _delete_store() {
        let index = this.list.list.findIndex(value => value.id == this.current_state.id);
        this.list.list.splice(index, 1);
        this.save_list();
        this.list_next();
    }
    cancel(callback = (callback_data) => { }) {
        Swal.fire({
            title: "Cancelar Formulario",
            html: "¿Está seguro de cancelar el llenado del formulario actual?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FDB915',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, cancelar',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value == true) {
                localStorage.removeItem("inExpedientGeneral");
                this.close();
                callback();
            }
        });
    }
    close() {
        this.pageService.closeModal(true);
    }
    clean() {
        this.internal_clean();
        this.module_state.next(this.current_state);
        this.save();
    }
    internal_clean() {
        this.current_state = {
            id_user: null,
            id: null,
            carrier: "",
            number: "",
            description: "",
            roof: "",
            date_init: "",
            date_finish: "",
            phone: "",
            user_name: "",
            runner_name: "",
            runner_phone: "",
            runner_email: "",
            ambulance: 1,
            hospital: "",
            img_front: null,
            img_back: null,
            img_front_file: null,
            img_back_file: null,
            loading: false,
            submitted: false,
            timestamp: new Date(),
        };
    }
    submit_stored(callback = (callback_data) => { }, step = 0) {
        if (this.list.list.length > 0 && step < this.list.list.length) {
            const formData = new FormData();
            this.current_state = this.list.list[step];
            this.current_state.id_user = this.expedientService.state.id_user;
            for (const item in this.current_state) {
                if (item != "img_back_file" && item != "img_front_file" && item != "img_back" && item != "img_front" && this.current_state[item]) {
                    formData.append(item, this.current_state[item]);
                }
            }
            this.current_state.img_front = this.expedientService.file_list.insurances.accident.find(value => value.id == this.current_state.img_front_file).file;
            this.current_state.img_back = this.expedientService.file_list.insurances.accident.find(value => value.id == this.current_state.img_back_file).file;
            this.insert(formData, () => {
                this.submit_stored(callback, step + 1);
            });
        }
        else {
            callback();
        }
    }
}
AccidentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccidentService_Factory() { return new AccidentService(i0.ɵɵinject(i1.AppService), i0.ɵɵinject(i2.ToastrService), i0.ɵɵinject(i3.PageService), i0.ɵɵinject(i4.ExpedientService)); }, token: AccidentService, providedIn: "root" });
