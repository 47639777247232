import { Injectable } from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, Observable } from "rxjs";
import Swal from "sweetalert2";
import { AppService } from "./app.service";

export interface PageState {
    profile: boolean;
    expedient: boolean;
    consult: boolean;
    searching: boolean;
    loading: boolean;
    sidebar_1: {
        show: boolean;
        scroll: boolean;
        expand: boolean;
    };
    sidebar_2: {
        show: boolean;
        scroll: boolean;
        expand: boolean;
    };
    sidebar_3: {
        show: boolean;
        scroll: boolean;
        shadow: boolean;
        background: boolean;
        expand: boolean;
        breadcrumbs?: Array<{ name: string; active: boolean }>;
    };
    timestamp: Date;
}

@Injectable({
    providedIn: "root",
})
export class PageService {
    private current_state: PageState;
    private page_state;
    public current: Observable<any>;
    public page_session = "unimed-page-session";

    constructor(private endpoint: AppService, private modalService: NgbModal) {
        var stored = localStorage.getItem(this.page_session);
        if (stored) stored = JSON.parse(stored);

        this.current_state = {
            profile: stored && stored["profile"] ? stored["profile"] : false,
            expedient: stored && stored["expedient"] ? stored["expedient"] : false,
            consult: stored && stored["consult"] ? stored["consult"] : false,
            searching: stored && stored["searching"] ? stored["searching"] : false,
            sidebar_1: {
                show: stored && stored["sidebar_1"] && stored["sidebar_1"]["show"] ? stored["sidebar_1"]["show"] : true,
                scroll: stored && stored["sidebar_1"] && stored["sidebar_1"]["scroll"] ? stored["sidebar_1"]["scroll"] : true,
                expand: stored && stored["sidebar_1"] && stored["sidebar_1"]["expand"] ? stored["sidebar_1"]["expand"] : false,
            },
            sidebar_2: {
                show: stored && stored["sidebar_2"] && stored["sidebar_2"]["show"] ? stored["sidebar_2"]["show"] : false,
                scroll: stored && stored["sidebar_2"] && stored["sidebar_2"]["scroll"] ? stored["sidebar_2"]["scroll"] : false,
                expand: stored && stored["sidebar_2"] && stored["sidebar_2"]["expand"] ? stored["sidebar_2"]["expand"] : false,
            },
            sidebar_3: {
                show: stored && stored["sidebar_3"] && stored["sidebar_3"]["show"] ? stored["sidebar_3"]["show"] : false,
                scroll: stored && stored["sidebar_3"] && stored["sidebar_3"]["scroll"] ? stored["sidebar_3"]["scroll"] : false,
                expand: stored && stored["sidebar_3"] && stored["sidebar_3"]["expand"] ? stored["sidebar_3"]["expand"] : false,
                shadow: stored && stored["sidebar_3"] && stored["sidebar_3"]["shadow"] ? stored["sidebar_3"]["shadow"] : false,
                background: stored && stored["sidebar_3"] && stored["sidebar_3"]["background"] ? stored["sidebar_3"]["background"] : false,
                breadcrumbs: [
                    {
                        name: "Inicio",
                        active: false,
                    },
                ],
            },
            loading: false,
            timestamp: new Date(),
        };
        this.page_state = new BehaviorSubject(this.current_state);
        this.current = this.page_state.asObservable();
        // this.set_searching(true); // REMOVE
    }

    reload() {
        this.page_state.timestamp = new Date();
        this.page_state.next(this.current_state);
    }

    get_current_state() {
        return this.current_state;
    }

    set_searching(status: boolean) {
        this.current_state.searching = status;
        this.page_state.next(this.current_state);
    }

    set_sidebar_1_state(status: { show: boolean; scroll: boolean; expand: boolean }) {
        this.current_state.sidebar_1 = status;
        this.page_state.next(this.current_state);
    }

    set_sidebar_2_state(status: { show: boolean; scroll: boolean; expand: boolean }) {
        this.current_state.sidebar_2 = status;
        this.page_state.next(this.current_state);
    }

    set_sidebar_3_state(status: { show: boolean; scroll: boolean; expand: boolean }) {
        this.current_state.sidebar_3 = { ...status, background: this.current_state.sidebar_3.background, shadow: this.current_state.sidebar_3.shadow };
        this.page_state.next(this.current_state);
    }

    sidebar_3(callback: (data: any) => void) {
        callback(this.current_state.sidebar_3);
        // this.page_state.next(this.current_state);
    }

    get_account_data() {}

    set_doctor_data(data: { firstname: string; lastname: string; id: string }) {
        if (data) {
            this.page_state.next(this.current_state);
        }
    }

    set_consult(status: boolean) {
        this.current_state.consult = status;
        if (status) {
            this.current_state.sidebar_2.show = true;
            this.current_state.sidebar_3.show = true;
            this.current_state.sidebar_3.background = false;
            this.current_state.sidebar_3.shadow = true;
        }
        this.save();
        this.page_state.next(this.current_state);
    }

    set_expedient(status: boolean) {
        // localStorage.setItem("ExpedientVersion", JSON.stringify({ method: "update" }));
        this.current_state.expedient = status;
        if (status) {
            this.current_state.sidebar_2.show = true;
            this.current_state.sidebar_3.show = true;
            this.current_state.sidebar_3.background = true;
        }
        this.current_state.sidebar_3.shadow = false;
        this.save();
        this.page_state.next(this.current_state);
    }

    set_profile(status: boolean) {
        this.current_state.profile = status;
        if (status) {
            this.current_state.sidebar_2.show = false;
            this.current_state.sidebar_3.show = true;
            this.current_state.sidebar_3.background = true;
        }
        this.current_state.sidebar_3.shadow = false;
        this.save();
        this.page_state.next(this.current_state);
    }

    set_loading(status: boolean) {
        this.current_state.loading = status;
        this.page_state.next(this.current_state);
    }

    get_loading() {
        return this.current_state.loading;
    }

    save() {
        localStorage.setItem(this.page_session, JSON.stringify(this.current_state));
    }

    openModal(content, size = "lg", callback = (callback_data?: any) => {}) {
        const options: NgbModalOptions = {
            backdrop: "static",
            keyboard: true,
            size: size,
            beforeDismiss: () => {
                callback();
                return true;
            },
        };

        this.modalService.open(content, options);
    }

    closeModal(data) {
        this.modalService.dismissAll(data);
    }

    cancel_consult(callback = (callback_data?: any) => {}) {
        Swal.fire({
            title: "Cancelar Consulta",
            html: "¿Está seguro de cancelar la consulta?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#FDB915",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, cancelar",
            cancelButtonText: "No",
        }).then((result) => {
            if (result.value == true) {
                localStorage.removeItem("inConsult");
                callback();
            }
        });
    }

    close_consult(callback = (callback_data?: any) => {}) {
        Swal.fire({
            title: "Guardar y Cerrar Consulta",
            html: "¿Está seguro de guardar y cerrar la consulta?",
            type: "question",
            showCancelButton: true,
            confirmButtonColor: "#FDB915",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, guardar y cerrar",
            cancelButtonText: "No",
        }).then((result) => {
            if (result.value == true) {
                localStorage.removeItem("inConsult");
                callback();
            }
        });
    }

    cancel_new_patient(callback = (callback_data?: any) => {}) {
        Swal.fire({
            title: "Cancelar Creación de Paciente",
            html: "¿Está seguro de cancelar la creación del paciente actual?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#FDB915",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, cancelar",
            cancelButtonText: "No",
        }).then((result) => {
            if (result.value == true) {
                localStorage.removeItem("inNewPatient");
                callback();
            }
        });
    }

    cancel_expedient(callback = (callback_data?: any) => {}) {
        Swal.fire({
            title: "Cancelar Actualización de Paciente",
            html: "¿Está seguro de cancelar la actualización del paciente actual?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#FDB915",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, cancelar",
            cancelButtonText: "No",
        }).then((result) => {
            if (result.value == true) {
                localStorage.removeItem("inExpedientGeneral");
                callback();
            }
        });
    }
}
