import { CustomClass } from "./custom-class";

export interface RecommendationInterface {
    id: any;
    recomendations: string;
    hidden_notes: string;
};

interface ToStringInterface {
    reason: string;
    description: string;
};

export class RecommendationClass extends CustomClass implements RecommendationInterface {
    public id: any;
    public recomendations: string;
    public hidden_notes: string;

    constructor(data?: RecommendationInterface) {
        super();
        if (data) this.overwrite(data);
        else this.reset();
    }

    overwrite(data: RecommendationInterface): void {
        super.overwrite(data);
        this.id = data.id;
        this.recomendations = data.recomendations;
        this.hidden_notes = data.hidden_notes;
    }

    reset(): void {
        super.reset();
        this.id = null;
        this.recomendations = "";
        this.hidden_notes = "";
    }

    format(): RecommendationInterface {
        if (this.recomendations) this.recomendations = this.recomendations.trim();
        if (this.hidden_notes) this.hidden_notes = this.hidden_notes.trim();
        return this as RecommendationInterface;
    }

    valid(): boolean {
        if (this.recomendations.trim().length > 0) {
            return true;
        }
        return false;
    }

    to_string(): ToStringInterface {
        let data: any = this.format();

        return data as ToStringInterface;
    }

    raw(): RecommendationInterface {
        return {
            id: this.id,
            recomendations: this.recomendations,
            hidden_notes: this.hidden_notes,
        };
    }
}
