/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./my_patients.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/ui/search_button/search_button.component.ngfactory";
import * as i3 from "../../shared/ui/search_button/search_button.component";
import * as i4 from "@angular/router";
import * as i5 from "../../shared/ui/datatable/datatable.component.ngfactory";
import * as i6 from "../../shared/ui/datatable/datatable.component";
import * as i7 from "./my_patients.component";
import * as i8 from "../../services/app.service";
import * as i9 from "../../services/patient-service.service";
import * as i10 from "../../services/page.service";
import * as i11 from "../expedient/new_consult/general/consult.service";
var styles_MyPatientsComponent = [i0.styles];
var RenderType_MyPatientsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MyPatientsComponent, data: {} });
export { RenderType_MyPatientsComponent as RenderType_MyPatientsComponent };
export function View_MyPatientsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { search_button_ref: 0 }), i1.ɵqud(671088640, 2, { datatable_ref: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 18, "div", [["class", "unimed-left-sidebar bg-white px-3 py-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 17, "div", [["class", "container-fluid px-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 11, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "d-flex justify-content-between mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" mis pacientes "])), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "my-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["class", "d-flex text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-search_button", [], null, [[null, "change"], [null, "submit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.on_update() !== false);
        ad = (pd_0 && ad);
    } if (("submit" === en)) {
        var pd_1 = (_co.on_update() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SearchButtonComponent_0, i2.RenderType_SearchButtonComponent)), i1.ɵdid(12, 114688, [[1, 4], ["search_button_ref", 4]], 0, i3.SearchButtonComponent, [], null, { submit: "submit", change: "change" }), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [["class", "ml-2 btn radius btn-primary no-wrap"], ["routerLink", "/nuevo_paciente/"], ["style", "font-weight: 500;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "fa-duotone fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" PACIENTE "])), (_l()(), i1.ɵeld(17, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "app-datatable", [], null, [[null, "update_datatable"], [null, "events_datatable"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("update_datatable" === en)) {
        var pd_0 = (_co.update($event) !== false);
        ad = (pd_0 && ad);
    } if (("events_datatable" === en)) {
        var pd_1 = (_co.events($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_DatatableComponent_0, i5.RenderType_DatatableComponent)), i1.ɵdid(20, 114688, [[2, 4], ["datatable_ref", 4]], 0, i6.DatatableComponent, [], { in_headers: [0, "in_headers"], in_events: [1, "in_events"], in_data: [2, "in_data"], in_filters: [3, "in_filters"], in_configs: [4, "in_configs"] }, { update_datatable: "update_datatable", events_datatable: "events_datatable" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 12, 0); var currVal_0 = "/nuevo_paciente/"; _ck(_v, 14, 0, currVal_0); var currVal_1 = _co.datatable.headers; var currVal_2 = _co.datatable.events; var currVal_3 = _co.data; var currVal_4 = _co.datatable.filters; var currVal_5 = _co.datatable.configs; _ck(_v, 20, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_MyPatientsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-my_patients", [], null, null, null, View_MyPatientsComponent_0, RenderType_MyPatientsComponent)), i1.ɵdid(1, 245760, null, 0, i7.MyPatientsComponent, [i8.AppService, i9.PatientService, i10.PageService, i11.ConsultGeneralService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MyPatientsComponentNgFactory = i1.ɵccf("app-my_patients", i7.MyPatientsComponent, View_MyPatientsComponent_Host_0, {}, {}, []);
export { MyPatientsComponentNgFactory as MyPatientsComponentNgFactory };
