import { Routes } from '@angular/router';
const ɵ0 = () => import("./emergency/emergency.module.ngfactory").then(m => m.ContactsEmergencyModuleNgFactory), ɵ1 = () => import("./doctor/doctor.module.ngfactory").then(m => m.ContactsDoctorModuleNgFactory);
const routes = [
    {
        path: '',
        redirectTo: 'emergencia',
        pathMatch: 'prefix'
    },
    {
        path: 'emergencia',
        loadChildren: ɵ0
    },
    {
        path: 'medico',
        loadChildren: ɵ1
    },
];
export class ContactsModule {
}
export { ɵ0, ɵ1 };
