import { Injectable } from '@angular/core';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor(private endpoint: AppService) {
  }

  create(load: any, endpoint: string) {
    this.fetch(load, endpoint,
      data => {
        window.open(URL.createObjectURL(data), '_blank');
      });
  }

  fetch(load: any, endpoint: string, success = (data) => console.info(data), error = (err) => console.error(err)) {

    this.endpoint[endpoint](load).subscribe(
      data => success(data),
      err => error(err)
    )
  }

  jwt(load: any, success = (data) => console.info(data), error = (err) => console.error(err)) {
    this.endpoint.jwt_parser(load).subscribe(
      data => success(data),
      err => error(err)
    )
  }

  print(pdf) {
    if (pdf) {
      pdf.print();
    }
  }

  download(pdf, name: string) {
    if (pdf) {
      pdf.download(name);
    }
  }

  open(pdf) {
    if (pdf) {
      pdf.open();
    }
  }

}