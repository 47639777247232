import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormRenderComponent, form_type } from 'src/app/shared/ui/form_render/form_render.component';
import { DependantsService, DependantsState } from '../dependants.service';
import { ExpedientMetadata, ExpedientService } from 'src/app/services/expedient.service';

@Component({
	selector: 'app-module-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnDestroy, AfterViewInit {
	private expedient_subscription: Subscription;
	public expedient_state: ExpedientMetadata;

	private dependants_subscription: Subscription;
	public dependants_state: DependantsState;

	@ViewChild('form_ref', { static: false }) form_ref: FormRenderComponent;
	public form_config: form_type;

	constructor(private moduleService: DependantsService, private expedientService: ExpedientService) {
	}

	ngOnInit() {
		this.expedient_subscription = this.expedientService.current.subscribe(
			state => {
				this.expedient_state = state;
				// this.moduleService.reload();
				this.form_configs();
			}
		);
		this.dependants_subscription = this.moduleService.current.subscribe(
			state => this.dependants_state = state
		);
		this.form_configs();
	}

	ngAfterViewInit() {
		if (this.dependants_state) {
			this.moduleService.forms.dependants = this.form_ref;
		}
	}

	ngOnDestroy() {
		this.expedient_subscription.unsubscribe();
		this.dependants_subscription.unsubscribe();
	}

	form_configs() {
		this.form_config = {
			pretty_view: () => this.expedient_state.method == "show",
			disabled: () => this.expedient_state.method == "show",
			submit: (callback) => this.moduleService.submit(callback),
			rows: [
				{
					class: "row",
					cols: [
						{
							name: "firstname",
							type: "name",
							label: "Nombres",
							small: "(primero, segundo, ...)",
							placeholder: () => "Primer, Segundo... Nombre",
							col_class: "col-md-6",
							class: this.expedient_state.method != "insert" ? "unstyle no-border" : "",
							validators: {
								required: {
									value: () => false
								}
							},
							show: () => this.expedient_state.method != "insert" && this.dependants_state.id_dependant,
							disabled: () => true,
						},
						{
							name: "lastname",
							type: "name",
							label: "Apellidos",
							small: "(primero, segundo, ...)",
							placeholder: () => "Primer, Segundo... Apellido",
							col_class: "col-md-6",
							class: this.expedient_state.method != "insert" ? "unstyle no-border" : "",
							validators: {
								required: {
									value: () => false
								}
							},
							show: () => this.expedient_state.method != "insert" && this.dependants_state.id_dependant,
							disabled: () => true,
						},
						{
							name: "email",
							type: "email",
							label: "Correo Electrónico",
							placeholder: () => "alguien@email.com",
							col_class: "col-md-6",
							validators: {
								required: {
									value: () => true
								},
							},
							disabled: () => false,
						},
						{
							name: "identification",
							type: "identification",
							label: "Identificación",
							hn: () => this.dependants_state.id_country == 97,
							placeholder: () => "####-####-#####",
							col_class: "col-md-6",
							class: this.expedient_state.method != "insert" ? "unstyle no-border" : "",
							validators: {
								required: {
									value: () => false
								},
							},
							show: () => this.expedient_state.method != "insert" && this.dependants_state.id_dependant,
							disabled: () => true,
						},
						{
							name: "status",
							type: "text",
							label: "Estado",
							col_class: "col-md-6",
							class: this.expedient_state.method != "insert" ? "unstyle no-border" : "",
							validators: {
								required: {
									value: () => false
								},
							},
							show: () => this.expedient_state.method != "insert",
							disabled: () => true,
						},
					]
				},
				{
					class: "row",
					cols: [
						{
							type: "buttons",
							col_class: "col-12",
							buttons: [
								{
									class: "btn btn-sm btn-outline-danger",
									label: "Cancelar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									show: () => {
										return this.expedient_state.method == "update" || this.expedient_state.method == "insert";
									},
									click: () => {
										this.moduleService.cancel();
									},
								},
								{
									class: "btn btn-sm btn-outline-danger",
									label: "Cerrar",
									type: "button",
									icon: {
										class: "fa-duotone fa-times",
										position: 'left',
									},
									show: () => {
										return this.expedient_state.method == "show";
									},
									click: () => {
										this.moduleService.close();
									},
								},
								{
									class: "btn btn-sm btn-success ml-auto",
									label: "Guardar",
									type: "submit",
									icon: {
										class: "fa-duotone fa-check",
										position: 'right',
									},
									show: () => {
										return this.expedient_state.method == "update" || this.expedient_state.method == "insert";
									},
									disabled: () => (this.form_ref ? !this.form_ref.valid() : false) || this.dependants_state.loading,
								},
							]
						},
					],
				},
			]
		};
	}
}